import { TableColumnPopupType } from '../../../Constants/Constants';
import { getIsShowTableColumn } from '../../../Utilities/Utility';

export class SecurityGateTableItemWidth {
	isLineNumberVisible: boolean;
	isCodeVisible: boolean;
	isNameVisible: boolean;
	isDescriptionVisible: boolean;
	isProductCustomFieldVisible: boolean;
	isQuantityVisible: boolean;
	isUomVisible: boolean;
	isQuantityWithUomVisible: boolean;
	isRefDocVisible: boolean;
	isRefDocDateVisible: boolean;
	lineNumber: number;
	code: number;
	name: number;
	description: number;
	nameWithDescription: number;
	productCustomField: number;
	quantity: number;
	uom: number;
	refDocument: number;
	refDocumentDate: number;
	quantityWithUom: number;

	originalLineNumber: number;
	originalCode: number;
	originalName: number;
	originalDescription: number;
	originalNameWithDescription: number;
	originalProductCustomField: number;
	originalQuantity: number;
	originalUom: number;
	originalQuantityWithUom: number;
	originalRefDocument: number;
	originalRefDocumentDate: number;

	productCustomFieldList: any;
	productGlobalDiscountList: any;

	constructor(data: any) {
		this.isCodeVisible = true;
		this.isNameVisible = true;
		this.isDescriptionVisible = true;
		this.isProductCustomFieldVisible = true;
		this.isQuantityVisible = true;
		this.isUomVisible = true;
		this.isQuantityWithUomVisible = true;
		this.isLineNumberVisible = true;
		this.isRefDocVisible = true;
		this.isRefDocDateVisible = true;

		this.lineNumber = 3;
		this.code = 6;
		this.name = 8;
		this.description = 10;
		this.nameWithDescription = this.name + this.description;
		this.productCustomField = 5;
		this.quantity = 5.5;
		this.uom = 4.5;
		this.refDocument = 15;
		this.refDocumentDate = 15;
		this.quantityWithUom = this.quantity + this.uom;

		this.originalLineNumber = 3;
		this.originalCode = 6;
		this.originalName = 8;
		this.originalDescription = 10;
		this.originalNameWithDescription =
			this.originalName + this.originalDescription;
		this.originalProductCustomField = 5;
		this.originalQuantity = 5.5;
		this.originalUom = 4.5;

		this.originalRefDocument = 15;
		this.originalRefDocumentDate = 15;
		this.originalQuantityWithUom = this.originalQuantity + this.originalUom;

		this.productCustomFieldList = undefined;
		this.productGlobalDiscountList = undefined;
	}

	setIsShowWidth(columnData: any) {
		this.isLineNumberVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.lineNumber
		);
		this.isRefDocVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.refDocument
		);
		this.isRefDocDateVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.refDocumentDate
		);
		this.isCodeVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.code
		);
		this.isNameVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.productName
		);
		this.isDescriptionVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.description
		);
		this.isProductCustomFieldVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.productCustomField
		);
		this.isQuantityVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.quantity
		);
		this.isUomVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.uom
		);
		this.isQuantityWithUomVisible = getIsShowTableColumn(
			columnData,
			TableColumnPopupType.quantityWithUom
		);
		let width = 5;
		let maxWidth = 20;

		let productCustomFieldList = columnData.filter(
			(x: any) => x.code !== undefined
		);
		if (productCustomFieldList.length > 0) {
			this.isProductCustomFieldVisible = false;
			if (this.productCustomFieldList === undefined) {
				//assign product custom list with corresponding flag
				this.productCustomFieldList = [];
				if (productCustomFieldList.length > 4) {
					width = maxWidth / productCustomFieldList.length;
				}
				productCustomFieldList.forEach((element: any) => {
					let item = {} as any;
					item.code = element.code;
					item.isVisible = element.columnOption.isShowColumn;
					item.originalWidth = width;
					item.width = width;
					item.type = element.name;
					item.label = element.label;
					this.productCustomFieldList.push(item);
				});
			} else {
				this.productCustomFieldList.forEach((element: any) => {
					let item = columnData.find(
						(x: any) => x.code === element.code
					);
					if (item !== undefined) {
						element.isVisible = item.columnOption.isShowColumn;
					}
				});
			}
		}
	}

	getTableHeaderWidths() {
		let totalWidth = 0;
		totalWidth = this.getTotalWidth(totalWidth);
		if (totalWidth === 100) {
			this.resetElementOriginalWidth();
		} else if (totalWidth === 0) {
			this.resetElementWidth();
		} else {
			let totalSelectedElement = this.getTotalSelectedElement();
			if (totalSelectedElement !== 0) {
				let totalOffset = (100 - totalWidth) / totalSelectedElement;
				this.updateElementWidth(totalOffset);
			}
		}
	}

	private resetElementOriginalWidth() {
		this.code = this.originalCode;
		this.name = this.originalName;
		this.description = this.originalDescription;
		this.nameWithDescription = this.originalNameWithDescription;
		this.productCustomField = this.originalProductCustomField;
		this.quantity = this.originalQuantity;
		this.uom = this.originalUom;
		this.quantityWithUom = this.originalQuantityWithUom;

		if (this.productCustomFieldList !== undefined) {
			this.productCustomFieldList.forEach((element: any) => {
				element.width = element.originalWidth;
			});
		}

		if (this.productGlobalDiscountList !== undefined) {
			this.productGlobalDiscountList.forEach((element: any) => {
				element.width = element.originalWidth;
			});
		}
	}

	private resetElementWidth() {
		this.code = 0;
		this.name = 0;
		this.description = 0;
		this.nameWithDescription = 0;
		this.quantity = 0;
		this.uom = 0;
		this.quantityWithUom = 0;
		this.lineNumber = 0;

		if (this.productCustomFieldList !== undefined) {
			this.productCustomFieldList.forEach((element: any) => {
				element.width = 0;
			});
		}

		if (this.productGlobalDiscountList !== undefined) {
			this.productGlobalDiscountList.forEach((element: any) => {
				element.width = 0;
			});
		}
	}

	private getTotalWidth(totalWidth: number) {
		if (this.isLineNumberVisible) {
			totalWidth += this.lineNumber;
		}
		if (this.isRefDocVisible) {
			totalWidth += this.refDocument;
		}
		if (this.isRefDocDateVisible) {
			totalWidth += this.refDocumentDate;
		}
		if (this.isCodeVisible) {
			totalWidth += this.code;
		}
		if (this.isNameVisible) {
			totalWidth += this.name;
		}
		if (this.isDescriptionVisible) {
			totalWidth += this.description;
		}
		if (this.isProductCustomFieldVisible) {
			totalWidth += this.productCustomField;
		}
		if (this.isQuantityVisible) {
			totalWidth += this.quantity;
		}
		if (this.isUomVisible) {
			totalWidth += this.uom;
		}
		if (this.isQuantityWithUomVisible) {
			totalWidth += this.quantityWithUom;
		}

		if (this.productCustomFieldList !== undefined) {
			this.productCustomFieldList.forEach((element: any) => {
				if (element.isVisible) {
					totalWidth += element.width;
				}
			});
		}

		if (this.productGlobalDiscountList !== undefined) {
			this.productGlobalDiscountList.forEach((element: any) => {
				if (element.isVisible) {
					totalWidth += element.width;
				}
			});
		}

		return totalWidth;
	}

	private getTotalSelectedElement() {
		let totalSelectedElement = 0;
		if (this.isLineNumberVisible) {
			totalSelectedElement += 1;
		}

		if (this.isRefDocVisible) {
			totalSelectedElement += 1;
		}
		if (this.isRefDocDateVisible) {
			totalSelectedElement += 1;
		}
		if (this.isCodeVisible) {
			totalSelectedElement += 1;
		}
		if (this.isNameVisible) {
			totalSelectedElement += 1;
		}
		if (this.isDescriptionVisible) {
			totalSelectedElement += 1;
		}
		if (this.isProductCustomFieldVisible) {
			totalSelectedElement += 1;
		}
		if (this.isQuantityVisible) {
			totalSelectedElement += 1;
		}
		if (this.isUomVisible) {
			totalSelectedElement += 1;
		}
		if (this.isQuantityWithUomVisible) {
			totalSelectedElement += 1;
		}

		if (this.productCustomFieldList !== undefined) {
			this.productCustomFieldList.forEach((element: any) => {
				if (element.isVisible) {
					totalSelectedElement += 1;
				}
			});
		}

		if (this.productGlobalDiscountList !== undefined) {
			this.productGlobalDiscountList.forEach((element: any) => {
				if (element.isVisible) {
					totalSelectedElement += 1;
				}
			});
		}

		return totalSelectedElement;
	}

	private updateElementWidth(additionalWidth: number) {
		if (this.isLineNumberVisible) {
			this.lineNumber = this.originalLineNumber + additionalWidth;
		}
		if (this.isCodeVisible) {
			this.code = this.originalCode + additionalWidth;
		}
		if (this.isNameVisible) {
			this.name = this.originalName + additionalWidth;
		}
		if (this.isDescriptionVisible) {
			this.description = this.originalDescription + additionalWidth;
		}
		if (this.isProductCustomFieldVisible) {
			this.productCustomField =
				this.originalProductCustomField + additionalWidth;
		}
		if (this.isQuantityVisible) {
			this.quantity = this.originalQuantity + additionalWidth;
		}
		if (this.isUomVisible) {
			this.uom = this.originalUom + additionalWidth;
		}
		if (this.isQuantityWithUomVisible) {
			this.quantityWithUom =
				this.originalQuantityWithUom + additionalWidth;
		}

		if (this.productCustomFieldList !== undefined) {
			this.productCustomFieldList.forEach((element: any) => {
				if (element.isVisible) {
					element.width = element.originalWidth + additionalWidth;
				}
			});
		}

		if (this.productGlobalDiscountList !== undefined) {
			this.productGlobalDiscountList.forEach((element: any) => {
				if (element.isVisible) {
					element.width = element.originalWidth + additionalWidth;
				}
			});
		}
	}

	getProductCustomFieldWidthByCode(code: string) {
		if (Array.isArray(this.productCustomFieldList)) {
			var item = this.productCustomFieldList.find((x: any) => x.code === code)
			if (item !== undefined) {
				return item.width
			}
		}
		return undefined
	}
	
	getProductGlobalDiscountWidthByGlobalDiscountAccountCode(
		globalDiscountAccountCode: string
	) {
		if (this.productGlobalDiscountList !== undefined) {
			let item = this.productGlobalDiscountList.find(
				(x: any) =>
					x.globalDiscountAccountCode === globalDiscountAccountCode
			);
			if (item !== undefined) {
				return item.width;
			}
		}
		return undefined;
	}
}
