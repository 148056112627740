import {
  DKButton,
  DKCheckMark,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKRadioButton
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  FIELD_TYPE,
  GOOGLE_NO_TRANSLATE_CLASS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchGeneric, selectResponse } from '../../Redux/Slices/GenericSlice';
import GenericService from '../../Services/GenericService';
import Utility from '../../Utility/Utility';
import CustomInput from '../CustomInput/CustomInput';
import FormatAmount from '../FormatAmount';
import PopupWrapper from '../PopupWrapper';
import AddHsnSac from './AddHsnSac';
import AddNatureIncomePayment from './AddNatureIncomePayment';

import { useTranslation } from 'react-i18next';
import { SliceManager } from '../../Redux/Slices/SliceManager';
import { getTenantTaxSystem } from '../DocumentForm/NewDocumentHelper';

type returnFunction = (index: number) => any;
type handleCreate = (value: string) => any;
type onChange = (value: string) => any;
type onKeyDown = (event: Event) => any;

const { Engine } = require('json-rules-engine');

export const formUtilComponentProductStyle = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: '100%'
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%' //(override container key while using to customize width)
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    fontSize: '0.875rem',
    backgroundColor: state.isSelected
      ? '#ebebeb'
      : state.isFocused
      ? 'var(--main-color-hover)'
      : null,
    color: state.isSelected ? '#000000' : null
  }),

  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    backgroundColor: '#f4f4f6',
    borderColor: '#ebebeb',
    cornerRadius: '4px',
    '&:hover': {
      borderColor: 'none'
    }
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    display: ''
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    zIndex: 9999
  })
};

const SECTION_TYPE = {
  SUBTOTAL_TAX: 'subtotalTax',
  HEADER: 'header'
};

export default function FormUtil(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [optionVal, setOptionVal] = useState<string>('');
  const [components, setComponents] = useState(props.complianceFields);
  const [parentData, setParentData] = useState(
    props.module && props.module === 'contact'
      ? props.parentData['complianceInfo']
      : props.parentData
  );
  // Fetching Store Data of genericData based on compliance
  const genericData: any = useAppSelector(selectResponse);
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [initialSelectedValue, setInitialSelectedValue] = useState<any>({});
  const [tmpOptionList, setTmpOptionList] = useState<any>({});
  const [optionList, setOptionList] = useState<any>({});
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateOrder: 'click' }
  };

  const [computedTaxValue, setComputedTaxValue] = useState<any>({});
  const [showListPicker, setShowListPicker] = useState<boolean>(false);
  const [showListPicker2, setShowListPicker2] = useState<boolean>(false);
  const [createListPicker, setCreateListPicker] = useState<boolean>(false);
  const [createHsnSacCode, setCreateHsnSacCode] = useState<boolean>(false);
  const [hsnSacdata, setHsnSacdata] = useState<any>('');
  const [codeType, setCodeType] = useState<any>('');
  // const [codeTypeValue, setCodeTypeValue] = useState<string>('HSN');
  const addOrderRef = useRef<UpdateCallBacksRefType>(refInitialState);

  useEffect(() => {
    updateFields();
    if (props.parentData !== undefined) {
      updateInitialSelectFields();
    }
    getOptionMapping();
  }, []);

  useEffect(() => {
    updateFields();
    if (props.isDocument) {
      var totalTax = 0;
      var totalBeforeTax = 0;
      props.parentData['items'].forEach((element: any) => {
        totalTax = totalTax + (element ? element['taxAmount'] : 0);
        totalBeforeTax = totalBeforeTax + (element ? element['subTotal'] : 0);
      });
      var newTaxData: any = {};
      newTaxData['beforeTax'] = totalBeforeTax;
      newTaxData['taxAmount'] = totalTax;
      setComputedTaxValue(newTaxData);
    }
    updateInitialSelectFields();
  }, [props.parentData]);

  useEffect(() => {
    if (
      props.parentData['hsnOrSacCode'] !== undefined &&
      props.parentData['hsnOrSacCode'] !== null
    ) {
      if (
        props.parentData['gstRate'] !== undefined &&
        props.parentData['gstRate'] !== null
      ) {
        let response = SliceManager.getSliceData('selectTax');
        if (response) {
          if (
            response &&
            response['content'] &&
            response['content'].length > 0
          ) {
            let content = response['content'];
            let selectedValue = content.find(
              (e: any) => e['percent'] === props.parentData['gstRate']
            );

            if (selectedValue && selectedValue['code'] !== undefined) {
              setObjectValue('salesTaxCode', selectedValue['code']);
              setObjectValue('purchaseTaxCode', selectedValue['code']);
            }
          }
        }
      }
    }
  }, [props.parentData['hsnOrSacCode']]);

  useEffect(() => {
    if (
      props.parentData['tariffOrServiceCodeMalaysia'] !== undefined &&
      props.parentData['tariffOrServiceCodeMalaysia'] !== null
    ) {
      if (
        props.parentData['gstRate'] !== undefined &&
        props.parentData['gstRate'] !== null
      ) {
        let response = SliceManager.getSliceData('selectTax');
        if (response) {
          if (
            response &&
            response['content'] &&
            response['content'].length > 0
          ) {
            let content = response['content'];
            let selectedValue = content.find(
              (e: any) => e['percent'] === props.parentData['gstRate']
            );

            if (selectedValue && selectedValue['code'] !== undefined) {
              setObjectValue('salesTaxCode', selectedValue['code']);
              setObjectValue('purchaseTaxCode', selectedValue['code']);
            }
          }
        }
      }
    }
  }, [props.parentData['tariffOrServiceCodeMalaysia']]);

  useEffect(() => {
    if (tmpOptionList !== undefined) {
      var tmpValue = { ...optionList };
      tmpValue[Object.keys(tmpOptionList)[0]] =
        tmpOptionList[Object.keys(tmpOptionList)[0]];
      setOptionList(tmpValue);
    }
  }, [tmpOptionList]);

  useEffect(() => {
    if (initialSelectedValue !== undefined) {
      let tmpSelectedValue = { ...selectedValue };
      Object.keys(initialSelectedValue).forEach(function (key, index) {
        if (initialSelectedValue[key] !== undefined) {
          tmpSelectedValue[key] = initialSelectedValue[key];
          setSelectedValue(tmpSelectedValue);
        }
      });
    }
  }, [initialSelectedValue]);

  useEffect(() => {}, [components]);

  const loadTmpOptionDetails = () => {
    var fields = components.fields.map((field: any) => {
      if (field.display) {
        if (field.type === FIELD_TYPE.SELECT) {
          if (!Utility.isEmpty(field)) {
            if (Utility.isEmpty(field.optionValues)) {
              loadOptionsValue('', field);
            }
          }
        }
      }
    });
  };

  const updateInitialSelectFields: any = () => {
    let tmpData: any = {};
    let fieldVal = components.fields.map((field: any) => {
      if (field.display) {
        if (field.type === FIELD_TYPE.SELECT) {
          if (!Utility.isEmpty(field)) {
            if (Utility.isEmpty(field.optionValues)) {
              if (props.parentData[field.objectField]) {
                if (SliceManager.getSliceData(field.reduxKey)) {
                  let response = SliceManager.getSliceData(field.reduxKey);
                  if (
                    response &&
                    response['content'] &&
                    response['content'].length > 0
                  ) {
                    let content = response['content'];
                    let selectedValue = content.find(
                      (e: any) =>
                        e[field.optionValueKey] === props.parentData[field.key]
                    );

                    let listOfValues: any = {};
                    if (
                      selectedValue &&
                      selectedValue[field.optionValueKey] !== undefined
                    ) {
                      let tmpObj: any = {};
                      tmpObj[field.optionNameKey] =
                        selectedValue[field.optionNameKey];
                      listOfValues[field.key] = tmpObj;
                      return listOfValues;
                    }
                  }
                } else {
                  loadInitialOptionsValue(
                    props.parentData[field.objectField],
                    field
                  ).then((res) => {
                    let listOfValues: any = {};
                    if (
                      res !== undefined &&
                      res.length > 0 &&
                      selectedValue !== undefined
                    ) {
                      let tmpObj: any = {};
                      tmpObj[field.optionNameKey] = res[0][field.optionNameKey];
                      listOfValues[field.key] = tmpObj;
                      setInitialSelectedValue((currentState: any) => ({
                        ...currentState,
                        [field.key]: tmpObj
                      }));
                    }
                  });
                }
              }
            }
          }
        }
      }
    });

    fieldVal.forEach((element: any) => {
      if (element) {
        tmpData[Object.keys(element)[0]] = element[Object.keys(element)[0]];
      }
    });
    setInitialSelectedValue(tmpData);
  };

  const updateFields = () => {
    if (components !== undefined && components !== null) {
      components.fields.forEach(async (field: any, index: any) => {
        start(field.rules, field, (event: any) => {
          var updatedComponents: any = [];
          Object.assign(updatedComponents, components);
          updatedComponents['fields'][index]['display'] = event;
          setComponents(updatedComponents);
        });
      });
    }
  };

  const getOptionMapping = async () => {
    var config = components;
    config.fields.forEach(async (field: any) => {
      if (
        field.type === FIELD_TYPE.LIST_PICKER ||
        field.type === FIELD_TYPE.LIST_PICKER2 ||
        field.type === FIELD_TYPE.SELECT
      ) {
        if (!Utility.isEmpty(field)) {
          if (Utility.isEmpty(field.optionValues)) {
            var apiPath = field.optionApi.replace('{searchValue}', '');

            var payload: any = {
              cacheKey: field.key
            };
            payload['apiPath'] = apiPath;
            try {
              GenericService.apiConfig = config;
              const data = await dispatch(fetchGeneric(payload));
            } catch (err) {
              console.error('Error calling api: ', err);
            }
          }
        }
      }
    });
  };

  const getBasicTextFieldWithCallback = (
    title: string,
    value: any,
    type: string,
    onChange: onChange,
    onKeyDown: onKeyDown,
    placeholder = '',
    direction = 'horizontal',
    editable: boolean = true,
    required = false
  ) => (
    <div className="parent-width text-align-left mt-2 compliance-text-field">
      <div className="row flex flex-col">
        <DKInput
          required={required}
          canValidate={props.canValidate}
          type={INPUT_TYPE.TEXT}
          title={title}
          value={value ? value : ''}
          onChange={(text: any) => onChange(text)}
          onKeyDown={(event: any) => onKeyDown(event)}
          readOnly={!editable}
        />
      </div>
    </div>
  );

  const getDKSelectField = (
    title: string,
    value: any,
    options: any[],
    callback: returnFunction,
    allowSearch = false,
    searchableKey = 'name',
    required = false,
    canValidate = false
  ) => {
    return (
      <DKInput
        className={''}
        value={value}
        formatter={(obj: any) => {
          return obj.label;
        }}
        title={title}
        icon={''}
        type={INPUT_TYPE.DROPDOWN}
        required={required}
        canValidate={canValidate}
        onChange={(value: any) => {
          callback(value);
        }}
        dropdownConfig={{
          className: '',
          style: {},
          allowSearch: allowSearch,
          searchableKey: searchableKey,
          canEdit: false,
          canDelete: false,
          data: options,
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.key}`} />;
          },
          onSelect: (index: any, value: any) => {}
        }}
      />
    );
  };

  const getSelectionFieldWithCallback = (
    title: string,
    value: any,
    options: any[],
    callback: returnFunction,
    hasError = false,
    requirePlaceholder = true
  ) => {
    let optionsArray = Utility.isEmpty(options)
      ? []
      : options.map((item: any) => item.label);
    return (
      <div className="row parent-width justify-content-between text-align-left mt-r">
        <div style={{ width: '40%' }}>
          <DKLabel className="ml-0" text={title} />
        </div>
        <div style={{ width: '60%' }}>
          <Select
            styles={formUtilComponentProductStyle}
            options={options}
            placeholder={requirePlaceholder ? title : ''}
            value={value}
            onChange={(value) => callback(value)}
          />
        </div>
      </div>
    );
  };

  const loadInitialOptionsValue = async (term: any, field: any) => {
    if (term !== undefined) {
      if (field.loadApi !== undefined) {
        var apiPath = field.loadApi.replace('{searchValue}', term);

        var payload: any = {
          cacheKey: field.key
        };
        payload['apiPath'] = apiPath;

        try {
          const data = await dispatch(fetchGeneric(payload));
          var tmpList: any = { ...tmpOptionList };
          if (
            data.payload[field.key]['content'] !== undefined &&
            data.payload[field.key]['content'] !== null
          ) {
            if (!Utility.isEmpty(data.payload[field.key]['content'])) {
              tmpList[field.key] = data.payload[field.key]['content'];
              setTmpOptionList(tmpList);
              return data.payload[field.key]['content'];
            }
          } else {
            if (!Utility.isEmpty(data.payload[field.key])) {
              tmpList[field.key] = data.payload[field.key];
              setTmpOptionList(tmpList);
              return data.payload[field.key];
            }
          }
          return [];
        } catch (err) {
          console.error('Error calling api: ', err);
        }
      }
    }
    return [];
  };

  const loadOptionsValue = async (term: any, field: any) => {
    var apiPath = field.optionApi.replace('{searchValue}', term);

    if (Utility.isEmpty(term)) {
      apiPath = field.optionApi.replace('{searchValue}', '');
    }

    var payload: any = {
      cacheKey: field.key
    };
    payload['apiPath'] = apiPath;

    try {
      const data = await dispatch(fetchGeneric(payload));
      var tmpList: any = { ...tmpOptionList };
      if (
        data.payload[field.key]['content'] !== undefined &&
        data.payload[field.key]['content'] !== null
      ) {
        if (!Utility.isEmpty(data.payload[field.key]['content'])) {
          tmpList[field.key] = data.payload[field.key]['content'];
          setTmpOptionList(tmpList);
          return data.payload[field.key]['content'];
        }
      } else {
        if (!Utility.isEmpty(data.payload[field.key])) {
          tmpList[field.key] = data.payload[field.key];
          setTmpOptionList(tmpList);
          return data.payload[field.key];
        }
      }
      return [];
    } catch (err) {
      console.error('Error calling api: ', err);
    }
  };

  const getAsyncSelect = (
    title: string,
    value: any,
    hasError = false,
    requirePlaceholder = true,
    field: any
  ) => {
    var keyValue: any = {};
    var value: any = {};
    if (selectedValue !== undefined && selectedValue !== null) {
      if (
        selectedValue[field.key] !== undefined &&
        selectedValue[field.key] !== null
      ) {
        value[field.optionNameKey] =
          selectedValue[field.key][field.optionNameKey];
      }
    }
    value[field.optionValueKey] = props.parentData[field.objectField];
    keyValue[field.key] = value;
    return (
      <div className="row parent-width mt-r  text-align-left">
        <div className="" style={{ width: '40%' }}>
          <DKLabel className=" ml-0" text={title} />
        </div>
        <div className="" style={{ width: '60%' }}>
          <AsyncSelect
            isDisabled={false}
            styles={formUtilComponentProductStyle}
            cacheOptions
            placeholder={requirePlaceholder ? title : ''}
            defaultOptions
            value={keyValue[field.key]}
            getOptionLabel={(e) => e[field.optionNameKey]}
            getOptionValue={(e) => e[field.optionValueKey]}
            loadOptions={(term: string) =>
              loadOptionsValue(
                term
                  ? term
                  : props.parentData && props.parentData[field.objectField]
                  ? props.parentData[field.objectField]
                  : '',
                field
              )
            }
            onInputChange={setOptionVal}
            onChange={(value: any) => {
              if (!Utility.isEmpty(field.captureOption)) {
                field.captureOption.forEach((element: any) => {
                  setObjectValue(element.field, value[element.key]);
                  var tmpValues = { ...selectedValue };
                  tmpValues[field.key] = value;
                  setSelectedValue(tmpValues);
                });
              } else {
                setObjectValue(field.objectField, value[field.optionValueKey]);
                var tmpValues = { ...selectedValue };
                tmpValues[field.key] = value;
                setSelectedValue(tmpValues);
              }
            }}
            menuPlacement={'auto'}
            menuPosition={'fixed'}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
          />
        </div>
        {/* <p
          style={{ marginTop: 4, marginBottom: 4 }}
          className={
            'text-xs mx-1  ' + (hasError ? 'text-red-600' : 'text-transparent')
          }
        >
          {'Cannot be left empty.'}
        </p> */}
      </div>
    );
  };
  const getDKAsyncSelect = (
    title: string,
    value: any,
    hasError = false,
    requirePlaceholder = true,
    field: any
  ) => {
    var keyValue: any = {};
    var value: any = {};
    if (!Utility.isEmpty(field?.value) && field?.value !== undefined) {
      keyValue[field.key] = field.value;
    } else {
      if (selectedValue !== undefined && selectedValue !== null) {
        if (
          selectedValue[field.key] !== undefined &&
          selectedValue[field.key] !== null
        ) {
          value[field.optionNameKey] =
            selectedValue[field.key][field.optionNameKey];
        }
      }
      value[field.optionValueKey] = props.parentData[field.objectField];
      if (field.allowInputValidation) {
        keyValue[field.key] = Utility.isEmpty(value[field.optionValueKey])
          ? null
          : value;
      } else {
        keyValue[field.key] = value;
      }
    }
    return (
      <div className="row parent-width mt-r  text-align-left">
        <DKInput
          className={''}
          value={keyValue[field.key]}
          //   value={props.parentData[field.objectField]}
          formatter={(obj: any) => {
            return obj[field.optionNameKey];
          }}
          validator={field?.validator ? field?.validator : () => {}}
          title={title}
          icon={''}
          type={INPUT_TYPE.DROPDOWN}
          required={field.allowInputValidation ? field.required : false}
          canValidate={field.allowInputValidation ? props.canValidate : false}
          onChange={(value: any) => {}}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: true,
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                var apiPath = field.optionApi.replace(
                  '{searchValue}',
                  searchValue
                );
                return GenericService.getSearchApiUrl(apiPath);
              },
              dataParser: (response: any) => {
                let responseData = response?.content.map((data: any) => {
                  return data;
                });

                let filteredData = getFilteredData(responseData, field?.key);

                return filteredData || [];
              }
            },
            searchableKey: field.optionNameKey,
            data: [],
            renderer: (index: number, obj: any) => {
              return <DKLabel text={`${obj[field.optionNameKey]}`} />;
            },
            onSelect: (index: number, value: any) => {
              if (!Utility.isEmpty(field.captureOption)) {
                field.captureOption.forEach((element: any) => {
                  setObjectValue(element.field, value[element.key]);
                  let tmpValues = { ...selectedValue };
                  tmpValues[field.key] = value;
                  setSelectedValue(tmpValues);
                });
              } else {
                setObjectValue(field.objectField, value[field.optionValueKey]);
                let tmpValues = { ...selectedValue };
                tmpValues[field.key] = value;
                setSelectedValue(tmpValues);
              }

              field.default = index;
            }
          }}
        />
      </div>
    );
  };

  const getFilteredData = (data: any, key: string) => {
    let country: TAX_SYSTEM = getTenantTaxSystem();

    switch (country) {
      case TAX_SYSTEM.MALAYSIA:
        return filterMalaysiaData(data, key);
      default:
        return data;
    }
  };

  const filterMalaysiaData = (data: any, key: string) => {
    let filteredMYDataOnEndDate: any = [...data];
    if (key === 'salesTaxCode') {
      filteredMYDataOnEndDate = data.filter((e: any) => {
        let isExpired =
          new Date(e?.effectiveEndDate).valueOf() - new Date().valueOf();
        if (Utility.isEmpty(e?.effectiveEndDate) || isExpired >= 0) {
          return e;
        }
      });
    }
    return filteredMYDataOnEndDate;
  };

  const setObjectValue = (key: any, value: any) => {
    updateParentData(key, value);
  };

  const setObjectEvent = (key: any, event: any) => {};

  const parseOptionValues = (
    optionValues: any,
    optionNameKey: string = '',
    optionValueKey: string = ''
  ) => {
    if (!Utility.isEmpty(optionValues)) {
      var options = [...optionValues];
      var option = options.map((element) => {
        if (
          !Utility.isEmpty(optionNameKey) &&
          !Utility.isEmpty(optionValueKey)
        ) {
          return {
            label: element[optionNameKey],
            value: element[optionNameKey]
          };
        } else {
          return { label: element.key, value: element.value };
        }
      });
      return option;
    }
    return [];
  };

  const handleTextField = (field: any) => {
    if (!Utility.isEmpty(field)) {
      return getBasicTextFieldWithCallback(
        field.name,
        props.parentData[field.objectField], //set product value instead
        field.dataType,
        (text: string) => {
          setObjectValue(field.objectField, text);
        },
        (event: Event) => {
          setObjectEvent(field.objectField, event); // revisit
        },
        field.placeholder,
        'horizontal',
        field.editable,
        field.required
      );
    }
    return <></>;
  };

  const getSelectedValueFromArray = (
    typeArray: any[],
    value: any,
    fieldName: string
  ) => {
    let filterred = typeArray.filter((item) => {
      return item.value === value;
    });
    if (filterred.length > 0) {
      return filterred[0];
    } else {
      return null;
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setCreateListPicker(false);
        setCreateHsnSacCode(false);
        setHsnSacdata('');
        break;
      case POPUP_CLICK_TYPE.SAVE_NATURE_OF_INCOME_PAYMENT:
        addOrderRef.current?.storeCallbacksRef.saveNatureOfIncomePayment();
        break;
      case POPUP_CLICK_TYPE.SAVE_HSN_SAC:
        addOrderRef.current?.storeCallbacksRef.saveHsnSacCodes();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.SAVE_NATURE_INCOME_PAYMENT:
        addOrderRef.current.storeCallbacksRef.saveNatureOfIncomePayment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_HSN_SAC_CODES:
        addOrderRef.current.storeCallbacksRef.saveHsnSacCodes =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setCreateListPicker(false);
        setCreateHsnSacCode(false);
        setHsnSacdata('');
        getOptionMapping();
        break;
    }
  };

  const natureOfIncomePaymentpopupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'bg-gray1 border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'SAVE',
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.SAVE_NATURE_OF_INCOME_PAYMENT
    }
  ];

  const addHsnSacpopupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'bg-gray1 border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white',
      clickAction: POPUP_CLICK_TYPE.SAVE_HSN_SAC
    }
  ];

  const handleListPicker = (field: any) => {
    if (!Utility.isEmpty(field) && field.type === FIELD_TYPE.LIST_PICKER) {
      if (genericData && genericData[field.key]) {
        let listPickerData = genericData[field.key];
        let listPickerDataCopy = genericData[field.key];

        if (!Utility.isEmpty(genericData[field.key]['content'])) {
          listPickerData = genericData[field.key]['content'];
          listPickerDataCopy = genericData[field.key]['content'];
        } else if (!Utility.isEmpty(genericData[field.key])) {
          listPickerData = listPickerData[field.key];
          listPickerDataCopy = listPickerData[field.key];
        } else {
          listPickerData = [];
          listPickerDataCopy = [];
        }

        listPickerData = listPickerData.map((data: any) => {
          return data[field.optionNameKey];
        });

        return (
          <>
            <div className="row parent-width justify-content-between text-align-left mt-l">
              <div style={{ width: '100%' }}>
                <DKInput
                  value={[props.parentData[field.objectField]]}
                  className="mt-xl"
                  title={field.name}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  onChange={(value: any) => {}}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: true,
                    searchableKey: 'name',
                    data: listPickerData,
                    renderer: (index: number, obj: any) => {
                      return (
                        <div className="row listPickerBG cursor-hand p-h-r justify-content-between list-row">
                          <DKLabel text={`${obj}`} />
                        </div>
                      );
                    },
                    button: {
                      title: `+ Add Nature of Income Payment`,
                      icon: DKIcons.ic_contact,
                      className: 'bg-button text-white',
                      style: {},
                      onClick: () => {
                        setCreateListPicker(true);
                      }
                    },
                    searchApiConfig: {
                      getUrl: (searchValue: string) => {
                        var apiPath = field.optionApi.replace(
                          '{searchValue}',
                          searchValue
                        );
                        return GenericService.getSearchApiUrl(apiPath);
                      },
                      dataParser: (response: any) => {
                        let responseData = response?.content.map(
                          (data: any) => {
                            return data[field.optionNameKey];
                          }
                        );
                        return responseData || [];
                      }
                    },
                    onSelect: (index: number, value: any) => {
                      setObjectValue(field.objectField, value);
                      field.default = index;
                    }
                  }}
                />
              </div>
            </div>
            {createListPicker && (
              <PopupWrapper
                clickAction={catchClicks}
                type={POPUP_TYPE.POPUP}
                title={`Add Nature of Income Payment`}
                btnList={natureOfIncomePaymentpopupBtnConfig}
                height={'24%'}
                width={'30%'}
              >
                <AddNatureIncomePayment
                  passingInteraction={(callback: CallBackPayloadType) => {
                    parentChildInteraction(callback);
                  }}
                />
              </PopupWrapper>
            )}
          </>
        );
      }
    }
  };

  const handleListPicker2 = (field: any) => {
    const handleEdit = (data: any) => {
      field.default = '';
      let codeType = '';
      if (props.parentData.offeringType === 'GOODS') {
        codeType = 'HSN';
      } else {
        codeType = 'SAC';
      }
      setCodeType(codeType);
      setHsnSacdata(data);
      setCreateHsnSacCode(true);
    };

    const handleDelete = (data: any) => {
      let buttons = [
        {
          title: 'No',
          className: 'bg-gray2 border-m ',
          onClick: () => {}
        },
        {
          title: 'Yes',
          className: 'bg-red text-white ml-r',
          onClick: () => {
            if (field && field.key === 'sacCode') {
              GenericService.deleteSACCode(data.code)
                .then(
                  (res) => {
                    updateParentData('hsnOrSacCode', null);
                    getOptionMapping();
                    field.default = '';
                    showToast('Deleted successfully', TOAST_TYPE.SUCCESS);
                  },
                  (err) => {
                    console.error('Error while deleting', err);
                    showToast('Failed to delete', TOAST_TYPE.ERROR);
                  }
                )
                .catch((error) => {
                  console.error('Error while deleting', error);
                  showToast('Failed to delete', TOAST_TYPE.ERROR);
                });
            } else if (field && field.key === 'hsnCode') {
              GenericService.deleteHSNCode(data.code)
                .then(
                  (res) => {
                    updateParentData('hsnOrSacCode', null);
                    getOptionMapping();
                    field.default = '';
                    showToast('Deleted successfully', TOAST_TYPE.SUCCESS);
                  },
                  (err) => {
                    console.error('Error while deleting', err);
                    showToast('Failed to delete', TOAST_TYPE.ERROR);
                  }
                )
                .catch((error) => {
                  console.error('Error while deleting', error);
                  showToast('Failed to delete', TOAST_TYPE.ERROR);
                });
            }
          }
        }
      ];
      showAlert(
        'Confirm Delete',
        t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`),
        buttons
      );
    };

    const handleActionButton = (data: any) => {
      if (data.tenantId) {
        return (
          <div className="row option-actions width-auto">
            <DKButton
              icon={DKIcons.ic_edit}
              style={{
                opacity: 0.6,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                paddingleft: 2
              }}
              onClick={() => {
                handleEdit(data);
              }}
            />
            <DKButton
              icon={DKIcons.ic_delete}
              style={{
                opacity: 0.6,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                paddingleft: 10
              }}
              onClick={() => {
                handleDelete(data);
              }}
            />
          </div>
        );
      } else if (Utility.isEmpty(data.tenantId)) {
        return <></>;
      }
    };

    if (!Utility.isEmpty(field) && field.type === FIELD_TYPE.LIST_PICKER2) {
      if (genericData && genericData[field.key]) {
        let listPickerData = genericData[field.key];
        let listPickerDataCopy = genericData[field.key];

        if (!Utility.isEmpty(genericData[field.key]['content'])) {
          listPickerData = genericData[field.key]['content'];
          listPickerDataCopy = genericData[field.key]['content'];
        } else if (!Utility.isEmpty(genericData[field.key])) {
          listPickerData = listPickerData[field.key];
          listPickerDataCopy = listPickerData[field.key];
        } else {
          listPickerData = [];
          listPickerDataCopy = [];
        }
        return (
          <>
            <div className="row parent-width justify-content-between text-align-left mt-r compliance-text-field">
              <div className="" style={{ width: '100%' }}>
                <DKInput
                  title={field.name}
                  value={props.parentData[field.objectField]}
                  type={INPUT_TYPE.DROPDOWN}
                  required={field.required}
                  onChange={(value: any) => {}}
                  canValidate={props.canValidate}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: true,
                    searchableKey: 'name',
                    data: listPickerData,
                    renderer: (index: number, obj: any) => {
                      return (
                        <div className="row listPickerBG cursor-hand p-h-r justify-content-between list-row">
                          <DKLabel text={`${obj.code}`} />
                          {handleActionButton(obj)}
                        </div>
                      );
                    },
                    button: {
                      title: `+ Add New HSN/SAC`,
                      icon: DKIcons.ic_contact,
                      className: 'bg-button text-white',
                      style: {},
                      onClick: () => {
                        setCreateHsnSacCode(true);
                        let codeType = '';
                        if (props.parentData.offeringType === 'GOODS') {
                          codeType = 'HSN';
                        } else {
                          codeType = 'SAC';
                        }
                        setCodeType(codeType);
                      }
                    },
                    searchApiConfig: {
                      getUrl: (searchValue: string) => {
                        var apiPath = field.optionApi.replace(
                          '{searchValue}',
                          searchValue
                        );
                        return GenericService.getSearchApiUrl(apiPath);
                      },
                      dataParser: (response: any) => {
                        return response?.content || [];
                      }
                    },
                    debounceTime: 300,
                    onSelect: (index: number, value: any) => {
                      if (!Utility.isEmpty(field.captureOption)) {
                        field.captureOption.forEach((element: any) => {
                          setObjectValue(element.field, value[element.key]);
                        });
                      } else {
                        setObjectValue(
                          field.objectField,
                          value[field.optionValueKey]
                        );
                        var tmpValues = { ...selectedValue };
                        tmpValues[field.key] = value;
                        setSelectedValue(tmpValues);
                      }
                      field.default = value.code;
                    }
                  }}
                />
              </div>
            </div>
            {createHsnSacCode && (
              <PopupWrapper
                clickAction={catchClicks}
                type={POPUP_TYPE.POPUP}
                title={
                  Utility.isEmpty(hsnSacdata)
                    ? `Add New HSN/SAC`
                    : `Update HSN/SAC`
                }
                btnList={addHsnSacpopupBtnConfig}
                height={'350px'}
                width={'30%'}
                overflowVisible={true}
              >
                <AddHsnSac
                  passingInteraction={(callback: CallBackPayloadType) => {
                    parentChildInteraction(callback);
                  }}
                  data={hsnSacdata}
                  type={codeType}
                />
              </PopupWrapper>
            )}
          </>
        );
      }
    }
  };

  const handleSelect = (field: any) => {
    if (!Utility.isEmpty(field)) {
      if (!Utility.isEmpty(field.optionValues)) {
        return (
          <div className="compliance-text-field mt-2">
            {getDKSelectField(
              field.name,
              getSelectedValueFromArray(
                parseOptionValues(field.optionValues),
                props.parentData[field.objectField],
                field.name
              ),
              field.optionValues,
              (value: any) => {
                setObjectValue(field.objectField, value.value);
              },
              field.allowSearch,
              field.searchableKey,
              field.required,
              props.canValidate
            )}
          </div>
        );
      } else {
        return (
          <div className="compliance-text-field mt-2">
            {getDKAsyncSelect(
              field.name,
              (value: any) => {
                setObjectValue(field.objectField, value.value);
              },
              false,
              false,
              field
            )}
          </div>
        );
      }
    }
    return <></>;
  };

  const handleSubtotalTax = (field: any, hasTaxGroup: boolean) => {
    if (!Utility.isEmpty(field)) {
      if (hasTaxGroup) {
        return (
          <div className="flex flex-row item-end justify-between mb-2 pr-2">
            <div className="flex flex-col items-end w-24">{field.name}</div>
            <div
              className={
                'flex flex-row items-end justify-end w-24 ' +
                GOOGLE_NO_TRANSLATE_CLASS
              }
            >
              <FormatAmount
                value={props.parentData[field.objectField]}
                currencyCode={props.parentData['currencyCode']}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="row mb-l justify-content-between">
            <div className="row width-auto">
              <div className=" fs-r text-align-left ml-r fw-m">
                {field.name}
              </div>
            </div>
            <div className={'ml-r ' + GOOGLE_NO_TRANSLATE_CLASS}>
              <FormatAmount
                value={
                  computedTaxValue[field.key] ? computedTaxValue[field.key] : 0
                }
                currencyCode={props.parentData['currencyCode']}
              />
            </div>
          </div>
        );
      }
    }
    return <></>;
  };

  const handleHeaderTextField = (field: any) => {
    if (!Utility.isEmpty(field)) {
      return (
        <div className="flex flex-row w-full justify-between mt-2">
          <label className="mt-3 text-gray-500">{field.name}</label>
          <div className="position-relative">
            <div className="text-align-left cursor-pointer font-medium">
              <div className="flex flex-row justify-between pl-2 rounded">
                <CustomInput
                  hasError={false}
                  defaultValue={''}
                  onChange={
                    (event) => {}
                    // updateProductData(fieldName, event.currentTarget.value)
                  }
                  placeholder={''}
                  labelName={''}
                  type={'string'}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const handleHeaderLabel = (field: any) => {
    if (!Utility.isEmpty(field)) {
      return (
        <div className="flex flex-row w-full justify-between mt-2">
          <label className="mt-3 text-gray-500">{field.name}</label>
          <div className="position-relative">
            <div className="text-align-left cursor-pointer font-medium">
              <div className="flex flex-row justify-between pl-2 rounded">
                {field.value}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const handleHeader = (field: any) => {
    if (!Utility.isEmpty(field)) {
      switch (field.type) {
        case FIELD_TYPE.TEXTFIELD:
          return handleHeaderTextField(field);
          break;
        case FIELD_TYPE.LABEL:
          return handleHeaderLabel(field);
          break;

        default:
          break;
      }
    }
  };
  const handleCheckbox = (field: any) => {
    if (!Utility.isEmpty(field)) {
      if (!Utility.isEmpty(field.optionValues)) {
        var checkboxContainer = field.optionValues.map((element: any) => {
          return (
            <DKCheckMark
              className="text-black mt-r"
              title={element.key}
              isSelected={props.parentData[field.objectField] === element.value}
              onClick={() => {
                setObjectValue(field.objectField, element.value);
              }}
            />
          );
        });
        return <div className="row gap-5 mt-l mb-l">{checkboxContainer}</div>;
      } else {
        return (
          <div className="row gap-5 mt-l mb-l">
            <DKCheckMark
              className="text-black"
              title={field.name}
              isSelected={props.parentData[field.objectField]}
              onClick={() => {
                setObjectValue(
                  field.objectField,
                  !props.parentData[field.objectField]
                );
              }}
            />
          </div>
        );
      }
    }
    return <></>;
  };

  const handleRadioSelection = (field: any) => {
    if (!Utility.isEmpty(field)) {
      if (!Utility.isEmpty(field.optionValues)) {
        var checkboxContainer = field.optionValues.map((element: any) => {
          return (
            <DKRadioButton
              className="text-black mt-r"
              title={element.key}
              isSelected={props.parentData[field.objectField] === element.value}
              onClick={() => {
                setObjectValue(field.objectField, element.value);
              }}
              color={'bg-app'}
            />
          );
        });
        return (
          <div className="row justify-content-start gap-5 mt-l mb-l">
            <div className="mr-l">
              <DKLabel
                className="row-auto-width dkinput-header-section"
                text={field?.name ?? ''}
              />
            </div>
            <div className="row justify-content-start gap-2">
              {checkboxContainer}
            </div>
          </div>
        );
      } else {
        return (
          <div className="row gap-5 mt-l mb-l">
            <DKLabel className="mr-5" text={field?.name ?? ''} />
            <DKRadioButton
              className="text-black"
              title={field.name}
              isSelected={props.parentData[field.objectField]}
              onClick={() => {
                setObjectValue(
                  field.objectField,
                  !props.parentData[field.objectField]
                );
              }}
              color={'bg-app'}
            />
          </div>
        );
      }
    }
    return <></>;
  };

  const parseData = () => {
    var fields = components.fields.map((field: any) => {
      if (field.display) {
        switch (field.type) {
          case FIELD_TYPE.SELECT:
            return handleSelect(field);
            break;
          case FIELD_TYPE.TEXTFIELD:
            return handleTextField(field);
            break;
          case FIELD_TYPE.CHECKBOX:
            return handleCheckbox(field);
            break;
          case FIELD_TYPE.RADIO:
            return handleCheckbox(field);
            break;
          case FIELD_TYPE.RADIO_SELECTION:
            return handleRadioSelection(field);
            break;
          case FIELD_TYPE.LIST_PICKER:
            return handleListPicker(field);
            break;
          case FIELD_TYPE.LIST_PICKER2:
            return handleListPicker2(field);
            break;
          default:
            break;
        }
      } else {
        return <></>;
      }
    });
    return (
      <div className="row row-responsive align-items-start parent-width mt-2">
        <div className="parent-width justify-content-between text-align-left">
          {fields}
        </div>
      </div>
    );
  };

  const parseDocumentData = () => {
    var fields = <></>;
    if (!Utility.isEmpty(components)) {
      if (props.sectionType === SECTION_TYPE.HEADER) {
        fields = components.fields.map((field: any) => {
          if (field.display) {
            if (field.sectionType === SECTION_TYPE.HEADER) {
              return handleHeader(field);
            }
          } else {
            return <></>;
          }
        });
      } else if (props.sectionType === SECTION_TYPE.SUBTOTAL_TAX) {
        if (components.hasTaxGroup) {
          return <></>;
        }
        fields = components.fields.map((field: any) => {
          if (field.display) {
            if (field.sectionType === SECTION_TYPE.SUBTOTAL_TAX) {
              return handleSubtotalTax(field, false);
            }
          } else {
            return <></>;
          }
        });
      }
    }

    return <>{fields}</>;
  };

  const updateParentData = (fieldName: string, value: any) => {
    props.updateParentData(fieldName, value);
  };

  async function start(rule: any, field: any, eventCallback: any) {
    if (Utility.isEmpty(rule)) {
      return;
    }
    let options = {
      allowUndefinedFacts: false
    };
    var engine = new Engine([], options);

    //TODO: loop to get all fact
    engine.addFact(rule.conditions.all[0].fact);
    engine.addRule(rule);

    var data = props.parentData;
    if (
      rule &&
      rule.conditions &&
      rule.conditions.all[0] &&
      rule.conditions.all &&
      rule.conditions.all.length > 0
    ) {
      rule.conditions.all.forEach((element: any) => {
        if (element.nestedRuleField !== undefined) {
          data = data[element.nestedRuleField];
        }
      });
    }

    const getResults = function () {
      return new Promise<any>((resolve, reject) => {
        return engine.run(data).then((res: any) => {
          resolve(res);
        });
      }).catch((error) => {});
    };

    getResults().then((result) => {
      if (!Utility.isEmpty(result)) {
        if (!Utility.isEmpty(result['events'])) {
          eventCallback(result['events'][0]['params']['shouldDisplay']);
        } else {
          eventCallback(false);
        }
      }
    });
  }

  return (
    <>
      {props.isDocument && parseDocumentData()}
      {!props.isDocument && (
        <div className="parent-width">
          <div className="row flex-wrap align-items-stretch">{parseData()}</div>
        </div>
      )}
    </>
  );
}
