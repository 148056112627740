import React from 'react';

export default function useClickOutSide(
  ref: React.RefObject<any>,
  callback?: () => void
) {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      callback?.();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, callback]);
}
