import { JC_STATUS } from '../../../Components/Mrp/Constants/MRPColumnConfigs';
import { ISchedulingTask } from '../../../Models/Scheduling';
import { GANT_BAR_TYPE } from './GantConstants';

export function randomNumber(count: number) {
  return Math.floor(Math.random() * count);
}

export function getRandomAlphaNumericString(length = 16) {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export function modifyDate(date: string | Date, days: string | number) {
  var result = new Date(date);
  result.setDate(result.getDate() + Number(days));
  return result;
}

export function daysBetweenDates(day_start: Date, day_end: Date) {
  return (Number(day_end) - Number(day_start)) / (1000 * 60 * 60 * 24);
}

export function daysBetweenDatesWithoutTime(day_start: Date, day_end: Date) {
  let endDate = dateWithoutTime(day_end);
  let startDate = dateWithoutTime(day_start);
  return (Number(endDate) - Number(startDate)) / (1000 * 60 * 60 * 24);
}

export function monthsBetweenDates(startDate: Date, endDate: Date) {
  return (
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    (endDate.getMonth() - startDate.getMonth()) +
    1
  );
}

export function daysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

export function dateWithoutTime(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getNewTaskData({
  id = getRandomAlphaNumericString(8),
  title = '',
  displayTitle = '',
  startDate = dateWithoutTime(new Date()),
  endDate = undefined,
  displayEndDate = '',
  actualEndDate = null,
  duration = 1,
  actualDuration = 1,
  status = 'OPEN',
  subTasks = [],
  parentId = '',
  type = '',
  resource = [],
  existingExpandedRow = [],
  productName = '',
  operationName = '',
  gantStyle = {},
  dependantOn = '',
  jobCardSeqCode = '',
  workOrderId = '',
  workOrderCode = '',
  jobCardId = '',
  workOrderSeqCode = '',
  allowEdit = true,
  expanded = undefined,
  requireDataOnExpand,
  documentCode = '',
  isPastDeadline,
  plannedEndDate
}: Partial<ISchedulingTask>) {
  return {
    id: id,
    title: title,
    displayTitle: displayTitle,
    startDate: startDate,
    endDate: endDate ? endDate : modifyDate(startDate, duration),
    displayEndDate: displayEndDate,
    actualEndDate: actualEndDate,
    duration: duration,
    actualDuration: actualDuration,
    additionalInfo: null,
    subTasks: subTasks,
    expanded:
      expanded ??
      (existingExpandedRow && existingExpandedRow?.includes(id) ? true : false),
    requireDataOnExpand,
    resource: resource,
    status: status,
    priority: 'medium',
    attachments: [],
    parentId: parentId,
    documentCode,
    workOrderId: workOrderId,
    workOrderCode,
    workOrderSeqCode,
    notes: '',
    type: type,
    productName: productName,
    operationName: operationName,
    isDependent: false,
    gantStyle: gantStyle,
    dependantOn: dependantOn,
    allowEdit: allowEdit,
    allowDragRow:
      type === GANT_BAR_TYPE.JOB_CARD
        ? ![
            JC_STATUS.COMPLETED.code,
            JC_STATUS.CANCELLED.code,
            JC_STATUS.IN_PROGRESS.code
          ].includes(status)
        : false,
    allowDragRowIndex: false,
    allowDragRowEnds:
      type === GANT_BAR_TYPE.JOB_CARD
        ? ![
            JC_STATUS.COMPLETED.code,
            JC_STATUS.CANCELLED.code,
            JC_STATUS.IN_PROGRESS.code
          ].includes(status)
        : false,
    allowAddSubTasks: false,
    allowDependentConnect:
      type === GANT_BAR_TYPE.JOB_CARD
        ? ![
            JC_STATUS.COMPLETED.code,
            JC_STATUS.CANCELLED.code,
            JC_STATUS.IN_PROGRESS.code
          ].includes(status)
        : false,
    jobCardSeqCode: jobCardSeqCode,
    jobCardId: jobCardId,
    isPastDeadline,
    plannedEndDate
  };
}

export function gantDataParser(dataToParse: ISchedulingTask[]) {
  return dataToParse.map((data) => {
    let processedData = {
      activity_type: [],
      allowToDelete: true,
      allowToEdit: true,
      end_date: data.endDate,
      id: data.id,
      name: data.title,
      start_date: data.startDate,
      status: data?.status,
      resource: data?.resource ?? [],
      productName: data?.productName ?? '-',
      operationName: data?.operationName ?? '-',
      jobCardSeqCode: data?.jobCardSeqCode ?? '-',
      dependantOn: data?.dependantOn ?? '',
      jobCardId: data?.jobCardId,
      parentId: data?.parentId
    };
    return processedData;
  });
}

export function getJobTotalTime(jobs: ISchedulingTask[]) {
  let workOrder = {
    duration: 0,
    startDate: new Date(),
    endDate: new Date()
  };

  jobs?.forEach((job, index) => {
    if (index === 0) {
      workOrder.startDate = job.startDate;
      workOrder.endDate = job.endDate || job.startDate;
    } else if (job.startDate < workOrder.startDate) {
      workOrder.startDate = job.startDate;
    } else if (job.endDate && job.endDate > workOrder.endDate) {
      workOrder.endDate = job.endDate;
    }
  });

  workOrder.duration = daysBetweenDates(workOrder.startDate, workOrder.endDate);
  return workOrder;
}

/* export const getSubTaskRandomTasksData = () => {
  const tasks = [];
  const numberOfTasks = randomNumber(3) + 1;
  let duration = 0,
    startDate = null,
    endDate = null,
    lastTaskDate = null;
  for (let i = 0; i < numberOfTasks; i++) {
    startDate = lastTaskDate
      ? lastTaskDate
      : modifyDate(dateWithoutTime(new Date()), -randomNumber(10));
    duration = randomNumber(4) + 1;
    endDate = modifyDate(startDate, duration);
    lastTaskDate = endDate;
    let status = SCHEDULE_STATUS[(Math.random() * SCHEDULE_STATUS.length) | 0];
    tasks.push(
      getNewTaskData({
        title: 'Job name ' + i,
        startDate,
        duration,
        status: status.value
      })
    );
  }
  return tasks;
}; */

/* export const getRandomTasksData = () => {
  const tasks = [];
  const numberOfTasks = 7;
  for (let i = 0; i < numberOfTasks; i++) {
    let jobTasks = getSubTaskRandomTasksData();
    let workOrderData = getJobTotalTime(jobTasks);
    let status = SCHEDULE_STATUS[(Math.random() * SCHEDULE_STATUS.length) | 0];
    tasks.push(
      getNewTaskData({
        title: 'Work order ' + i,
        startDate: workOrderData.startDate,
        duration: workOrderData.duration,
        status: status.value,
        subTasks: jobTasks
      })
    );
  }
  return tasks;
}; */

export const getJobCardConfig = (jobCard: string) => {
  return {
    SearchTerm: `${jobCard}`,
    Limit: 100,
    Page: 0,
    sort: 'DESC',
    sortBy: 'jobCardCode',
    Query: ''
  };
};

export const getWorkOrderConfig = (workOrderCode: string) => {
  return {
    Limit: 25,
    Page: 0,
    Query: '',
    sort: 'createdOn',
    sortDir: 'DESC',
    SearchTerm: `${workOrderCode}`
  };
};

export function sortDate(
  date1: Date | string,
  date2: Date | string,
  dir = 'ASC'
) {
  try {
    if (dir === 'ASC') {
      return Number(new Date(date1)) - Number(new Date(date2));
    }
    return Number(new Date(date2)) - Number(new Date(date1));
  } catch (error) {
    return 0;
  }
}

export function sortObjectsByTitle(objects: any[]) {
  objects.sort((a, b) => a.title.localeCompare(b.title));
  return objects;
}

export function isSameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth()
  );
}
