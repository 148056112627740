import {
  CostingHeaderProps,
  calculateCostingForAllCards
} from '../../Helpers/WOCostingHelper';
import CostingCard from './CostingCard';

const CostingHeader = (props: CostingHeaderProps) => {
  const costingCardDetails = calculateCostingForAllCards(props);

  /**
   * Main renderer
   */
  return (
    <div className="row gap-2">
      {costingCardDetails?.map((item: any, i: number) => {
        return (
          <CostingCard
            key={`consting-card-${i}`}
            title={item.title}
            actualCost={item.value1}
            expectedCost={item.value2}
          />
        );
      })}
    </div>
  );
};

export default CostingHeader;
