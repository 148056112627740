import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import BillService from '../../Services/Bill';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { API_STATUS } from '../../Constants/Constant';
import { deepClone } from '../../Utility/Utility';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  summary: {}
};

export const fetchBills = createAsyncThunk('bills', async () => {
  const response = await BillService.getBillsByPage();
  return response;
});

export const fetchBillsSummary = createAsyncThunk('billsSummary', async () => {
  const response = await BillService.getBillsSummary();
  return response;
});

export const BillsSlice = createSlice({
  name: 'Bills',
  initialState,
  reducers: {
    addBillColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BILL
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToBillColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateBillDetails: (state, action: PayloadAction<any>) => {
      const billDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const billIndex: number = content.findIndex(
        (bill: any) => bill.id === billDetails.id
      );
      if (billIndex >= 0) {
        content[billIndex] = billDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBills.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchBills.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchBillsSummary.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchBillsSummary.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.summary = action.payload;
      });
  }
});
export const {
  addBillColumnConfig,
  addColumnToBillColumnConfig,
  updateColumnConfig,
  updateBillDetails
} = BillsSlice.actions;

export const selectBills = (state: RootState) => state.bills.data;
export const selectBillsSummary = (state: RootState) => state.bills.summary;

export const selectBillsLoadingStatus = (state: RootState) =>
  state.bills.gridContentLoadingStatus;

export const selectBillsColumnConfig = (state: RootState) =>
  state.bills.columnConfig;

export const selectBillsColumnConfigTableId = (state: RootState) =>
  state.bills.configTableId;

export default BillsSlice.reducer;
