import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import BudgetingService from '../../Services/Budgeting';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchAllBudget = createAsyncThunk('budgeting', async () => {
  const response = await BudgetingService.fetchAllBudgets();
  return response;
});

export const BudgetingSlice = createSlice({
  name: 'budgeting',
  initialState,
  reducers: {
    addCoaColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          allowAddOption: false
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BUDGETING
      );
    },
    addColumnToCoaColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBudget.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllBudget.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let budgetList: any = action.payload;
        state.data.budgeting = budgetList;
      });
  }
});

export const selectedBudgets = (state: RootState) => state.budgeting.data;
