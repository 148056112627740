import { DKInput, INPUT_VIEW_DIRECTION } from 'deskera-ui-library';
import { INPUT_TITLE_STYLE } from '../../../Constants/UIHelper';
import { CUSTOM_NUMBER_INPUT_MODULES } from '../../../../../Constants/Constant';
import CustomNumberFormatInput from '../../../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import Utility from '../../../../../Utility/Utility';

interface WorkOrderSequenceFormatProps {
  workOrder: any;
  isEditMode: boolean;
  onSequenceFormatChange: (updatedWO: any) => void;
}

const WorkOrderSequenceFormat = (props: WorkOrderSequenceFormatProps) => {
  const selectedFormat = ({ id, text, manualMode }: any) => {
    let updatedWO = {
      ...props.workOrder,
      sequenceFormat: id,
      documentSequenceCode: text
    };
    props.onSequenceFormatChange(updatedWO);
  };

  let autoNumberingFormat = {};
  if (
    props.workOrder?.documentSequenceCode ||
    props.workOrder?.sequenceFormat
  ) {
    autoNumberingFormat = {
      id: props.workOrder?.sequenceFormat,
      text: props.workOrder?.documentSequenceCode,
      manualMode: Utility.isEmpty(props.workOrder?.sequenceFormat)
    };
  }

  /**
   * Main renderer
   */
  return (
    <>
      {props.isEditMode ? (
        <DKInput
          value={props.workOrder?.documentSequenceCode}
          readOnly={true}
          title="Auto-Numbering Format"
          titleStyle={INPUT_TITLE_STYLE}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
        />
      ) : (
        <CustomNumberFormatInput
          module={CUSTOM_NUMBER_INPUT_MODULES.WORK_ORDER}
          selectedFormat={selectedFormat}
          extraClass={''}
          titleStyle={INPUT_TITLE_STYLE}
          isRow={false}
          autoNumberingFormat={autoNumberingFormat}
        />
      )}
    </>
  );
};

export default WorkOrderSequenceFormat;
