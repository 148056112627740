import Utility from '../Utility/Utility';
import { Store } from '../Redux/Store';
import { InventoryItem } from './InventoryItem';
import { Transactions } from './Interfaces';
import { DocumentItem } from './DocumentItem';
import { roundingOffStr } from '../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  BOOKS_DATE_FORMAT,
  CURRENCY_PRECISION,
  DATE_CONSTANTS
} from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';

export class PrintDocument {
  CompanyName: string = '';
  Address: string = '';
  Date: string = '';
  Phone: string = '';
  BillToName: string = '';
  BillToAddr: string = '';
  ShipToName: string = '';
  ShipToAddr: string = '';
  ExpiryDate: string = '';
  items: InventoryItem[] = [];
  SubTotal: any = '';
  Discount: any = '';
  Tax: any = '';
  Total: any = '';
  paymentsTotal: any = '';
  pays: Transactions[] = [];
  creditsTotal: any = '';
  creds: Transactions[] = [];
  outstandingAmt: any = '';
  Memo: string = '';
  DueDate: string = '';
  BeforeTax: string = '';
  TotalBase: string = '';
  orderNo: string = '';
  BillNo: string = '';
  debitTotal: string = '';
  OrderNo: string = '';
  exchangeRate: string = '';
  cgst: string;
  sgst: string;
  igst: string;
  cess: string;

  constructor(props: any, tenant: any) {
    this.CompanyName = tenant.name ? tenant.name : '';
    this.Address = this.setPreferredAddress(tenant.billingAddresses);
    this.Date = props.documentDate
      ? DateFormatService.getFormattedDateString(
          props.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : '';
    this.Phone =
      tenant.contacts && tenant.contacts.phone ? tenant.contacts.phone : '';
    this.Memo = props.memo ? props.memo : '';

    // TODO: Replace all default "parse variables and values" once an alternative email service solution is available
    const parseContact = props.contact ? props.contact.name : props.contactName;
    const parseCurrency = props.currency ? props.currency : tenant.currency;
    const parseItems = props.items
      ? props.items.sort((a: any, b: any) => (a['id'] > b['id'] ? 1 : -1))
      : [];

    // TODO: Parse Contact is used here
    this.BillToName = parseContact;
    this.BillToAddr = this.setAddress(props.billTo);
    this.ShipToName = parseContact;
    this.ShipToAddr = this.setAddress(props.shipTo);
    this.ExpiryDate = props.validTillDate
      ? DateFormatService.getFormattedDateString(
          props.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : '';
    this.DueDate = props.validTillDate
      ? DateFormatService.getFormattedDateString(
          props.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : '';

    this.cgst = Utility.getAmountFormattedToPrint(parseCurrency, props.cgst);
    this.sgst = Utility.getAmountFormattedToPrint(parseCurrency, props.sgst);
    this.igst = Utility.getAmountFormattedToPrint(parseCurrency, props.igst);
    this.cess = Utility.getAmountFormattedToPrint(parseCurrency, props.cess);
    // TODO: Parse Items is used here
    this.setInventoryItems(parseItems, parseCurrency);
    this.BeforeTax = Utility.getAmountFormatted(
      parseCurrency,
      props.totalAmount - props.taxAmount
    );
    this.TotalBase = Utility.getAmountFormattedToPrint(
      tenant.currency,
      props.totalAmountInBaseCurrency
    );
    this.orderNo = props.documentCode;
    this.BillNo = props.documentCode;
    // this.debitTotal: string="";
    this.OrderNo =
      props.linkedDocuments && props.linkedDocuments.length > 0
        ? props.linkedDocuments[0].documentCode
        : '';

    // TODO: Parse Currency is used here
    this.SubTotal = Utility.getAmountFormattedToPrint(
      parseCurrency,
      props.subTotal
    );
    this.Tax = Utility.getAmountFormattedToPrint(
      parseCurrency,
      props.taxAmount
    );
    this.Total = Utility.getAmountFormattedToPrint(
      parseCurrency,
      props.totalAmount
    );
    this.outstandingAmt = Utility.getAmountFormattedToPrint(
      parseCurrency,
      props.dueAmount
    );

    this.Discount = Utility.getAmountFormattedToPrint(
      parseCurrency,
      props.discount
    );
    //this.exchangeRate = props.exchangeRate ? props.exchangeRate+"" : "";
    if (props.currency !== tenant.currency || !props.exchangeRate) {
      this.exchangeRate = `1 ${props.currency} = ${roundingOffStr(
        1 / props.exchangeRate,
        CURRENCY_PRECISION
      )} ${tenant.currency}`;
    } else {
      this.exchangeRate = '';
    }
  }

  setInventoryItems(items: DocumentItem[], currency: string) {
    items.forEach((element: DocumentItem) => {
      this.items.push(new InventoryItem(element, currency));
    });
  }

  setAddress(address: any): string {
    if (address) {
      return [
        address.address1,
        address.city,
        address.state,
        address.country,
        address.postalCode
      ]
        .filter(Boolean)
        .join();
    } else {
      return '';
    }
  }

  setPreferredAddress(address: any[]) {
    let addString = '';
    if (address) {
      address.forEach((item) => {
        if (item.preferred) {
          addString = this.setCompanyBillingAddress(item);
        }
      });
    }
    return addString;
  }

  setCompanyBillingAddress(item: any) {
    if (item) {
      return [
        item.address1,
        item.city,
        item.state,
        item.country,
        item.postalCode
      ].join();
    } else {
      return '';
    }
  }

  setTransactions(props: any) {
    if (props.knockoffInfo) {
      this.paymentsTotal = null;
      props.knockoffInfo.forEach((element: any) => {
        const transAction: Transactions = {
          reference: element.documentCode,
          value: Utility.getAmountFormattedToPrint(
            element.currency,
            element.amount
          )
        };
        this.pays.push(transAction);
      });
      this.paymentsTotal = Utility.getAmountFormattedToPrint(
        props.currency,
        props.knockOffTotal
      );
    }
  }

  setTransactionsForBill(props: any) {
    if (props.knockoffInfo) {
      this.paymentsTotal = null;
      props.knockoffInfo.forEach((element: any) => {
        const transAction = {
          reference: element.documentCode,
          value: Utility.getAmountFormattedToPrint(
            element.currency,
            element.amount
          )
        };
        this.pays.push(transAction);
      });
      this.paymentsTotal = Utility.getAmountFormattedToPrint(
        props.currency,
        props.knockOffTotal
      );
    }
  }
}
