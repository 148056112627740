interface InputLabelProps {
  text?: string;
  className?: string;
}
const InputLabel: React.FC<InputLabelProps> = (props) => {
  const className = props.className ? props.className : '';
  /**
   * If props.text don't have value then passing `EMPTY_SPACE`
   */
  if (props.text === 'EMPTY_SPACE') {
    return (
      <p className={'text-sm text-gray-500 mx-1 mt-1 mb-0.5 ' + className}>
        <span>&nbsp;&nbsp;</span>
      </p>
    );
  } else {
    return (
      <p className={'text-sm text-gray-500 mx-1 mt-1 mb-0.5 ' + className}>
        {props.text}
      </p>
    );
  }
};

export default InputLabel;
