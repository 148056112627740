import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';

export interface GoodsReceivedSearchAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
  StartDate?: string;
  EndDate?: string;
  format?: 'XLS' | '';
  SalesPerson?: number;
}

export const defaultConfig: GoodsReceivedSearchAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '&sort=code&sortDir=DESC',
  QuerySummary: '',
  StartDate: '',
  EndDate: '',
  format: '',
  SalesPerson: 0
};

class GoodsReceiptMasterService {
  static selectedIndex: number = 0;

  static getSelectedIndex() {
    return GoodsReceiptMasterService.selectedIndex;
  }

  static setSelectedIndex(index: number) {
    GoodsReceiptMasterService.selectedIndex = index;
  }

  static apiConfig: GoodsReceivedSearchAPIConfig = defaultConfig;
  private static async fetchGoodsReceivedMasterList(
    endpoint: string,
    config: GoodsReceivedSearchAPIConfig,
    options: any = {}
  ) {
    const searchParams = new URLSearchParams({
      limit: config.Limit?.toString() ?? '25',
      page: config.Page?.toString() ?? '0',
      search: config.SearchTerm ?? '',
      query: config.Query ?? '',
      fetchAttachmentDetails: 'true'
    });
    if (config.format) {
      searchParams.append('format', config.format);
    }
    let finalEndpoint: string =
      endpoint + '?' + searchParams.toString() + config.QueryParam.toString();
    if (this.apiConfig.SalesPerson) {
      finalEndpoint =
        finalEndpoint + '&salesperson=' + this.apiConfig.SalesPerson;
    }
    return http
      .get(`${finalEndpoint}`, options)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGoodsReceivedMasterList() {
    let url = ApiConstants.URL.GOODS_RECEIPT.SEARCH;
    return this.fetchGoodsReceivedMasterList(url, this.apiConfig, {})
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default GoodsReceiptMasterService;
