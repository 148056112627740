import { useState } from 'react';
import { DKLabel, DKButton, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import Utility from '../../../Utility/Utility';
import { StockAdjustmentType } from './StockAdjustmentConstant';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';

const AddAdjustmentReason = (props: any) => {
  const [adjReason, setAdjReason] = useState(props?.currentReason?.reason);
  const [adjType, setAdjType] = useState(
    StockAdjustmentType.filter(
      (stockType: any) => stockType.value === props?.currentReason?.type
    )?.[0] || StockAdjustmentType[0]
  );
  const [isAdjReasonFormSubmitted, setIsAdjReasonFormSubmitted] =
    useState(false);
  const isFormEdit = Utility.isNotEmpty(props?.currentReason);

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '30%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {/* header */}
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text={`${!isFormEdit ? 'Add' : 'Update'} Adjustment Reason`}
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="bg-white border-m mr-r"
              onClick={() => {
                props.onCancel();
              }}
            />
            <DKButton
              title={!isFormEdit ? 'Save' : 'Update'}
              className="bg-button text-white"
              onClick={() => {
                setIsAdjReasonFormSubmitted(true);
                if (!isFormEdit) {
                  if (
                    !Utility.isEmpty(adjReason) &&
                    adjReason?.trim('')?.length > 0
                  ) {
                    const payload = {
                      adjReason,
                      adjType
                    };
                    props.onSave(payload);
                  }
                } else {
                  const payload = {
                    adjReason,
                    adjType,
                    code: props?.currentReason?.code
                  };
                  props.onEdit(payload);
                }
              }}
            />
          </div>
        </div>
        {/* form */}
        <div className="parent-width p-l">
          {/* subtype name */}
          <DKInput
            title="Adjustment Reason"
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.TEXT}
            value={adjReason}
            className="mb-l"
            onChange={(val: any) => {
              setAdjReason(val?.trim());
            }}
            canValidate={isAdjReasonFormSubmitted}
          />
          <DKInput
            type={INPUT_TYPE.DROPDOWN}
            title={`Adjustment Type`}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={adjType}
            canValidate={false}
            formatter={(obj: any) => {
              return obj?.name;
            }}
            required={true}
            onChange={(obj: any) => {
              setAdjType(obj);
            }}
            className="mb-l"
            dropdownConfig={{
              title: '',
              allowSearch: false,
              searchableKey: '',
              canEdit: false,
              canDelete: false,
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: StockAdjustmentType,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj?.name}`} />;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AddAdjustmentReason;