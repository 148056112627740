import { UCQ_OPTIONS } from '../../Constants/StaticData';
import Utility from '../../Utility/Utility';

export const ENGINE_CONSTANTS = {
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  LESS_THAN: 'lessThan',
  LESS_THAN_INCLUSIVE: 'lessThanInclusive',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_INCLUSIVE: 'greaterThanInclusive',
  IN: 'in',
  NOT_IN: 'notEqual',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain'
};

export const productIndiaConfig = {
  module: 'product',
  countryCode: 'IN',
  fields: [
    {
      id: 0,
      name: 'HSN Code',
      description: 'HSN Code',
      key: 'hsnCode',
      objectField: 'hsnOrSacCode',
      type: 'listPicker2',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'offeringType',
              operator: 'notEqual',
              value: 'SERVICES'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: 'products/in/hsn?search={searchValue}&limit=50',
      captureOption: [
        {
          key: 'code',
          value: 'code',
          field: 'hsnOrSacCode'
          //primary?
        },
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        },
        {
          key: 'cessRuleDescription',
          value: 'cessRuleDescription',
          field: 'cessRuleDescription'
        },
        {
          key: 'cessRule',
          value: 'cessRule',
          field: 'cessRule'
        },
        {
          key: 'cessNonAdvol',
          value: 'cessNonAdvol',
          field: 'cessNonAdvol'
        }
      ],
      optionNameKey: 'code',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'SAC Code',
      description: 'SAC Code',
      key: 'sacCode',
      objectField: 'hsnOrSacCode',
      type: 'listPicker2',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'offeringType',
              operator: 'equal',
              value: 'SERVICES'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      captureOption: [
        {
          key: 'code',
          value: 'code',
          field: 'hsnOrSacCode'
          //primary?
        },
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        },
        {
          key: 'cessRuleDescription',
          value: 'cessRuleDescription',
          field: 'cessRuleDescription'
        },
        {
          key: 'cessRule',
          value: 'cessRule',
          field: 'cessRule'
        },
        {
          key: 'cessNonAdvol',
          value: 'cessNonAdvol',
          field: 'cessNonAdvol'
        }
      ],
      optionNameKey: 'code',
      optionValueKey: 'code',
      optionApi: 'products/in/sac?search={searchValue}&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Default Tax Rate',
      description: 'Default Tax Rate',
      key: 'salesTaxCode', //check
      objectField: 'salesTaxCode', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'taxPreference',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      captureOption: [
        {
          key: 'code',
          value: 'code',
          field: 'purchaseTaxCode'
        },
        {
          key: 'code',
          value: 'code',
          field: 'salesTaxCode'
        }
      ],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi: 'taxes?search={searchValue}&query=status=ACTIVE&limit=50',
      loadApi: 'taxes?query=code={searchValue}&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true,
      reduxKey: 'selectTax'
    },
    {
      id: 3,
      name: 'UQC',
      description: 'UQC',
      objectField: 'uqcIndia',
      key: 'uqcIndia',
      type: 'select',
      dataType: '',
      optionValues: [...UCQ_OPTIONS],
      default: 0,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      allowSearch: true,
      searchableKey: 'key',
      canValidate: true
    },
    {
      id: 4,
      name: 'Tax Preference',
      description: 'Tax Preference',
      key: 'taxPreference',
      objectField: 'taxPreference', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [
        {
          key: 'Taxable',
          value: true
        },
        {
          key: 'Non-Taxable',
          value: false
        }
      ],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Exemption Reason',
      description: 'Exemption Reason',
      objectField: 'taxExemptionReason', //check
      key: 'taxExemptionReason',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          key: 'Exempted',
          value: 'EXEMPTED' //check value
        },
        {
          key: 'Non-GST',
          value: 'NON_GST'
        }
      ],
      default: 'EXEMPTED',
      rules: {
        conditions: {
          all: [
            {
              fact: 'taxPreference',
              operator: 'equal',
              value: false
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      //populate value here
      id: 6,
      name: 'Cess Applicable',
      description: 'Cess Applicable',
      objectField: 'cessRuleDescription',
      key: 'cessApplicable',
      type: 'textField',
      dataType: 'string',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'taxPreference',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: false,
      editable: false
    },
    {
      id: 7,
      name: 'Is TDS Applicable',
      description: 'Is TDS Applicable',
      objectField: 'tdsApplicableIndia', //check
      key: 'isTdsApplicable',
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: false,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: false,
      editable: true,
      display: true
    },
    {
      id: 8,
      name: 'Nature of Income Payment',
      description: 'Nature of Income Payment',
      objectField: 'tdsNatureOfPaymentIndia', //check
      key: 'natureOfIncomePayment',
      type: 'listPicker',
      dataType: '',
      optionValues: [],
      default: 0,
      rules: {
        conditions: {
          all: [
            {
              fact: 'tdsApplicableIndia',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionNameKey: 'natureOfPayment',
      optionValueKey: 'natureOfPayment',
      optionApi:
        'products/in/tds-nature-of-payment?search={searchValue}&limit=100',
      apiMethod: 'GET',
      cache: true,
      //   cacheKey: 'attributeMasterData'
      required: false,
      editable: true,
      display: true,
      reduxKey: 'selectNatureOfIncomeAccounts'
    },
    {
      id: 9,
      name: 'ITC Adjustment',
      description: 'ITC Adjustment',
      objectField: 'itcAdjustment',
      key: 'itcAdjustment',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'NA',
          key: 'NA' //check value
        },
        {
          value: 'ITC_IS_BLOCKED',
          key: 'ITC as Blocked' //check value
        },
        {
          value: 'ITC_IS_REVERSED',
          key: 'ITC reversal'
        }
      ],
      default: '',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: false,
      editable: true,
      display: true,
      allowInputValidation: true
    },
    {
      id: 10,
      name: 'GL For ITC Block',
      description: 'GL For ITC Block',
      objectField: 'glAccountCode',
      key: 'glAccountCode',
      type: 'select',
      dataType: '',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi: 'account?search={searchValue}&query=status=ACTIVE',
      loadApi: 'account?query=code={searchValue}&query=status=ACTIVE',
      apiMethod: '',
      required: false,
      editable: true,
      display: true,
      reduxKey: 'selectAccount',
      allowInputValidation: true,
      validator: (value: any) => {
        return Utility.isEmpty(value?.code) ||
          value?.code === 'NA' ||
          value?.code === ''
          ? false
          : true;
      },
      value: null
    }
  ]
};
export const productSgConfig = {
  module: 'product',
  countryCode: 'SG',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTaxCode',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      loadApi:
        'taxes?limit=50&page=0&search=&query=code={searchValue},status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTaxCode',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      loadApi:
        'taxes?limit=50&page=0&search=&query=code={searchValue},status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productIndonesiaConfig = {
  module: 'product',
  countryCode: 'ID',
  fields: [
    {
      id: 0,
      name: 'Nature of Payment',
      description: 'Nature of Payment',
      key: 'natureOfPayment',
      objectField: 'whtSectionToWhtRateId',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'type',
              operator: 'equal',
              value: 'NONTRACKED'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: 'taxes/id/standardwht?search={searchValue}',
      optionNameKey: 'sectionName',
      optionValueKey: 'tdsId',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productPhConfig = {
  module: 'product',
  countryCode: 'PH',
  fields: [
    {
      id: 0,
      name: 'Nature of Payment',
      description: 'Nature of Payment',
      key: 'natureOfPayment',
      objectField: 'whtSectionToWhtRateId',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: 'taxes/ph/standardwht?search={searchValue}',
      optionNameKey: 'sectionName',
      optionValueKey: 'tdsId',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productNzConfig = {
  module: 'product',
  countryCode: 'nz',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productGermanyConfig = {
  module: 'product',
  countryCode: 'de',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productUaeConfig = {
  module: 'product',
  countryCode: 'ae',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productUkConfig = {
  module: 'product',
  countryCode: 'uk',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productBeConfig = {
  module: 'product',
  countryCode: 'be',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const productAuConfig = {
  module: 'product',
  countryCode: 'au',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const productCaConfig = {
  module: 'product',
  countryCode: 'ca',
  fields: [
    {
      id: 0,
      name: 'Purchase Tax',
      description: 'Purchase Tax',
      key: 'purchaseTax',
      objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      loadApi:
        'taxes?limit=50&page=0&search=&query=code={searchValue},status=ACTIVE,type!SALES&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Set as Default Purchase Tax',
      description: 'Set as Default Purchase Tax',
      key: 'setAsDefaultPurchaseTax',
      objectField: 'defaultPurchaseTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Sales Tax',
      description: 'Sales Tax',
      key: 'salesTax',
      objectField: 'salesTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {},
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      loadApi:
        'taxes?limit=50&page=0&search=&query=code={searchValue},status=ACTIVE,type!PURCHASE&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Set as Default Sales Tax',
      description: 'Set as Default Sales Tax',
      key: 'setAsDefaultSalesTax',
      objectField: 'defaultSalesTax', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const productMyConfig = {
  module: 'product',
  countryCode: 'my',
  fields: [
    {
      id: 0,
      name: 'Service Code',
      description: 'Service Code',
      key: 'tariffOrServiceCodeMalaysia',
      objectField: 'tariffOrServiceCodeMalaysia',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'type',
              operator: 'equal',
              value: 'NONTRACKED'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      captureOption: [
        {
          key: 'code',
          value: 'code',
          field: 'tariffOrServiceCodeMalaysia'
          //primary?
        },
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        },
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        }
      ],
      optionApi: 'products/my/service?search={searchValue}&limit=50',
      optionNameKey: 'code',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: false,
      editable: true,
      display: true,
      canValidate: true,
      allowInputValidation: true
    },
    {
      id: 1,
      name: 'Tariff Code',
      description: 'Tariff Code',
      key: 'tariffOrServiceCodeMalaysia',
      objectField: 'tariffOrServiceCodeMalaysia',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'type',
              operator: 'notEqual',
              value: 'NONTRACKED'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      captureOption: [
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        },
        {
          key: 'gstRate',
          value: 'gstRate',
          field: 'gstRate'
        },
        {
          key: 'code',
          value: 'code',
          field: 'tariffOrServiceCodeMalaysia'
          //primary?
        }
      ],
      optionApi: 'products/my/tariff?search={searchValue}&limit=50',
      optionNameKey: 'code',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: false,
      editable: true,
      display: true,
      canValidate: true,
      allowInputValidation: true
    },
    {
      id: 2,
      name: 'Default Tax Rate',
      description: 'Default Tax Rate',
      key: 'salesTaxCode',
      objectField: 'salesTaxCode', // salesTaxCode,
      //   objectField: 'purchaseTaxCode',
      type: 'select',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptedMalaysia',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      captureOption: [
        {
          key: 'code',
          value: 'code',
          field: 'purchaseTaxCode'
        },
        {
          key: 'code',
          value: 'code',
          field: 'salesTaxCode'
        }
      ],
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE',
      loadApi: 'taxes?query=code={searchValue}&limit=50',
      optionNameKey: 'name',
      optionValueKey: 'code',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true,
      reduxKey: 'selectTax'
    },
    {
      id: 3,
      name: 'Exemption Reason',
      description: 'Exemption Reason',
      key: 'taxExemptionReasonMalaysia',
      objectField: 'taxExemptionReasonMalaysia', //check
      type: 'select',
      dataType: '',
      optionValues: [
        {
          key: 'NON_SST',
          value: 'NON_SST'
        },
        {
          key: 'Unprocessed Food',
          value: 'UNPROCESSED_FOOD'
        },
        {
          key: 'Vegetables',
          value: 'VEGETABLES'
        },
        {
          key: 'Medicines',
          value: 'MEDICINES'
        },
        {
          key: 'Machinery',
          value: 'MACHINERY'
        },
        {
          key: 'Chemicals',
          value: 'CHEMICALS'
        },
        {
          key: 'Live Animals',
          value: 'LIVE_ANIMALS'
        }
      ],
      default: true,
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptedMalaysia',
              operator: 'equal',
              value: false
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      canValidate: true
    },
    {
      id: 4,
      name: 'Tax Preference',
      description: 'Tax Preference',
      key: 'exemptedMalaysia',
      objectField: 'exemptedMalaysia', //check
      type: 'radioSelection',
      dataType: '',
      optionValues: [
        {
          key: 'Taxable',
          value: true
        },
        {
          key: 'Non-Taxable',
          value: false
        }
      ],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

// Customer Tax Type	Vendor Tax Type	NPWP Number	NIK Number	Radio button - Resident and Non resident

export const contactSgConfig = {
  module: 'contact',
  countryCode: 'sg',
  fields: [
    {
      id: 0,
      name: 'Contact UEN',
      description: 'Contact UEN',
      key: 'uen',
      objectField: 'uen',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Tax Number',
      description: 'Tax Number',
      key: 'taxNumber',
      objectField: 'taxNumber',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactNzConfig = {
  module: 'contact',
  countryCode: 'nz',
  fields: [
    {
      id: 0,
      name: 'IRD Number',
      description: 'IRD Number',
      key: 'irdNo',
      objectField: 'irdNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'GST Number',
      description: 'GST Number',
      key: 'gstNo',
      objectField: 'gstNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const contactBeConfig = {
  module: 'contact',
  countryCode: 'be',
  fields: [
    {
      id: 0,
      name: 'Enterprise Number',
      description: 'Enterprise Number',
      key: 'beEnterpriseNo',
      objectField: 'beEnterpriseNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'VAT Identification Number',
      description: 'VAT Identification Number',
      key: 'beVatIdentificationNo',
      objectField: 'beVatIdentificationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Default Sales Tax Rate',
      description: 'Default Sales Tax Rate',
      key: 'beDefaultSalesTaxRate', //check
      objectField: 'beDefaultSalesTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Default Purchase Tax Rate',
      description: 'Default Purchase Tax Rate',
      key: 'beDefaultPurchaseTaxRate', //check
      objectField: 'beDefaultPurchaseTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactMyConfig = {
  module: 'contact',
  countryCode: 'my',
  fields: [
    {
      id: 0,
      name: 'Tax Number',
      description: 'Tax Number',
      key: 'taxNumber',
      objectField: 'taxNumber',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Business Registration No',
      description: 'Business Registration No',
      key: 'businessRegNo',
      objectField: 'businessRegNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Service Tax Registration No',
      description: 'Service Tax Registration No',
      key: 'serviceTaxRegNo',
      objectField: 'serviceTaxRegNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Sales Tax Registration No',
      description: 'Sales Tax Registration No',
      key: 'salesTaxRegNo',
      objectField: 'salesTaxRegNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 4,
      name: 'Exemption Certificate',
      description: 'Exemption Certificate',
      key: 'exemptionCertificateMalaysia',
      objectField: 'exemptionCertificateMalaysia', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [
        {
          key: 'Has Exemption Certificate',
          value: true
        },
        {
          key: "Doesn't Have Exemption Certificate",
          value: false
        }
      ],
      default: false,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Exemption Criteria',
      description: 'Exemption Criteria',
      objectField: 'exemptionCriterialMalaysia', //check
      key: 'exemptionCriterialMalaysia',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          key: 'None',
          value: 'NONE' //check value
        },
        {
          key: 'Zero Rated 0%',
          value: 'ZERO_RATED' //check value
        },
        {
          key: 'Exempted 0%',
          value: 'NON_GST'
        }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptionCertificateMalaysia',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerTypeMalaysia', //check
      key: 'customerTypeMalaysia',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'EXEMPTED_UNDER_SCHEDULE_A',
          key: 'Exempted under Schedule A'
        },
        {
          value: 'EXEMPTED_UNDER_SCHEDULE_B',
          key: 'Exempted under Schedule B'
        },
        {
          value: 'EXEMPTED_UNDER_SCHEDULE_C1_C2',
          key: 'Exempted under Schedule C1 and C2'
        },
        {
          value: 'EXEMPTED_UNDER_SCHEDULE_C3_C4',
          key: 'Exempted under Schedule C3 and C4'
        },
        {
          value: 'EXEMPTED_UNDER_SCHEDULE_C5',
          key: 'Exempted under Schedule C5'
        }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptionCertificateMalaysia',
              operator: 'equal',
              value: true
            },
            {
              fact: 'exemptionCriterialMalaysia',
              operator: 'equal',
              value: 'NON_GST'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerTypeMalaysia', //check
      key: 'customerTypeMalaysia',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'NONE',
          key: 'None'
        }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptionCertificateMalaysia',
              operator: 'equal',
              value: true
            },
            {
              fact: 'exemptionCriterialMalaysia',
              operator: 'equal',
              value: 'NONE'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerTypeMalaysia', //check
      key: 'customerTypeMalaysia',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NONE', key: 'None' },
        { value: 'EXPORT', key: 'Export' },
        { value: 'SPECIAL_AREA', key: 'Special area' },
        { value: 'DESIGNATED_AREA', key: 'Designated area' }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptionCertificateMalaysia',
              operator: 'equal',
              value: true
            },
            {
              fact: 'exemptionCriterialMalaysia',
              operator: 'equal',
              value: 'ZERO_RATED'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorTypeMalaysia', //check
      key: 'vendorTypeMalaysia',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'SALES_TAX_EXEMPTION',
          key: 'Purchase / Importation of Raw Material Exempted from Sales Tax- Exemption in Schedule '
        },
        {
          value: 'MANUFACTURED_EXEMPTION',
          key: 'Purchase / Importation of Raw Material on behalf Registered Manufacturer Exempted from Sales Tax'
        },
        {
          value: 'PERFORMED_EXEMPTION',
          key: 'Value of work performed exempted from Sales Tax'
        }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'exemptionCertificateMalaysia',
              operator: 'equal',
              value: true
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
export const contactIndoConfig = {
  module: 'contact',
  countryCode: 'id',
  fields: [
    {
      id: 0,
      name: 'NPWP Number',
      description: 'NPWP Number',
      key: 'nwpwNumber',
      objectField: 'nwpwNumber',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'NIK Number',
      description: 'NIK Number',
      key: 'nikNumber',
      objectField: 'nikNumber',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Tax Type',
      description: 'Customer Tax Type',
      objectField: 'taxTypeCustomer', //check
      key: 'taxTypeCustomer',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'TAX_CODE_CUSTOMER_01',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_01'
        },
        {
          value: 'TAX_CODE_CUSTOMER_02',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_02'
        },
        {
          value: 'TAX_CODE_CUSTOMER_03',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_03'
        },
        {
          value: 'TAX_CODE_CUSTOMER_04',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_04'
        },
        {
          value: 'TAX_CODE_CUSTOMER_05',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_05'
        },
        {
          value: 'TAX_CODE_CUSTOMER_06',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_06'
        },
        {
          value: 'TAX_CODE_CUSTOMER_07',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_07'
        },
        {
          value: 'TAX_CODE_CUSTOMER_08',
          key: 'taxTypesCustomerIndonesia.TAX_CODE_CUSTOMER_08'
        }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Tax Type',
      description: 'Vendor Tax Type',
      objectField: 'taxTypeVendor', //check
      key: 'taxTypeVendor',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'TAX_CODE_VENDOR_01',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_01'
        },
        {
          value: 'TAX_CODE_VENDOR_02',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_02'
        },
        {
          value: 'TAX_CODE_VENDOR_03',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_03'
        },
        {
          value: 'TAX_CODE_VENDOR_04',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_04'
        },
        {
          value: 'TAX_CODE_VENDOR_05',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_05'
        },
        {
          value: 'TAX_CODE_VENDOR_06',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_06'
        },
        {
          value: 'TAX_CODE_VENDOR_07',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_07'
        },
        {
          value: 'TAX_CODE_VENDOR_08',
          key: 'taxTypesVendorIndonesia.TAX_CODE_VENDOR_08'
        }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 4,
      name: 'Residential Status',
      description: 'Residential Status',
      key: 'residentStatusIndonesia',
      objectField: 'residentStatusIndonesia', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [
        {
          key: 'Resident',
          value: true
        },
        {
          key: 'Non Resident',
          value: false
        }
      ],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
//ph
export const contactPhConfig = {
  module: 'contact',
  countryCode: 'ph',
  fields: [
    {
      id: 5,
      name: 'BIR Class',
      description: 'BIR Class',
      objectField: 'birClassPhilippines', //check
      key: 'birClassPhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NONE', key: 'None' },
        { value: 'MANUFACTURING', key: 'Manufacturing' },
        { value: 'SALESANDSERVICE', key: 'Sales and Service' },
        { value: 'SERVICE', key: 'Service' }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'WHT Business',
      description: 'WHT Business',
      objectField: 'whtBusinessPhilippines', //check
      key: 'whtBusinessPhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'Individual', key: 'Individual' },
        { value: 'Corporate', key: 'Corporate' }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerTypePhilippines', //check
      key: 'customerTypePhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NORMAL', key: 'Normal' },
        { value: 'GOVERNMENT', key: 'Government' }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorTypePhilippines', //check
      key: 'vendorTypePhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NORMAL', key: 'Normal' },
        { value: 'IMPORT', key: 'Import' }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'VAT Number',
      description: 'VAT Number',
      objectField: 'vatNoPhilippines', //check
      key: 'vatNoPhilippines',
      type: 'textField',
      dataType: '',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'VAT Status',
      description: 'VAT Status',
      objectField: 'vatStatusPhilippines', //check
      key: 'vatStatusPhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          key: 'None',
          value: 'NONE' //check value
        },
        {
          key: 'Zero Rated 0%',
          value: 'ZERO_RATED' //check value
        },
        {
          key: 'Exempted 0%',
          value: 'EXEMPTED_ZERO'
        }
      ],
      default: 'NONE',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Exemption Reason',
      description: 'Exemption Reason',
      objectField: 'vatExemptionReasonPhilippines', //check
      key: 'vatExemptionReasonPhilippines',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'Federal_Government', key: 'Federal government' },
        { value: 'State_Government', key: 'State government' },
        { value: 'Tribal_Government', key: 'Tribal government' },
        { value: 'Foreign_Diplomat', key: 'Foreign diplomat' },
        { value: 'Charitable_Organization', key: 'Charitable organization' },
        { value: 'Religious_Organization', key: 'Religious organization' },
        { value: 'Resale', key: 'Resale' },
        { value: 'Agricultural_Production', key: 'Agricultural production' },
        {
          value: 'Industrial_Production_or_Manufacturers',
          key: 'Industrial production or manufacturers'
        },
        { value: 'Direct_Pay_Permit', key: 'Direct pay permit' },
        { value: 'Direct_Mail', key: 'Direct mail' },
        { value: 'Other_or_custom', key: 'Other or custom' },
        { value: 'Educational_Organization', key: 'Educational organization' },
        { value: 'Local_Government', key: 'Local government' },
        { value: 'Commercial_Aquaculture', key: 'Commercial aquaculture' },
        { value: 'Commercial_Fishery', key: 'Commercial fishery' },
        { value: 'Non_Resident', key: 'Non-resident' }
      ],
      default: 'NONE',
      rules: {
        conditions: {
          all: [
            {
              fact: 'vatStatusPhilippines',
              operator: 'equal',
              value: 'EXEMPTED_ZERO'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactGermanyConfig = {
  module: 'contact',
  countryCode: 'de',
  fields: [
    {
      id: 0,
      name: 'Company Registration Number',
      description: 'Company Registration Number',
      key: 'germanyBusinessCompanyNo',
      objectField: 'germanyBusinessCompanyNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'VAT Identification Number',
      description: 'VAT Identification Number',
      key: 'germanyVatIdentificationNo',
      objectField: 'germanyVatIdentificationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Default Sales Tax Rate',
      description: 'Default Sales Tax Rate',
      key: 'germanyDefaultSalesTaxRate', //check
      objectField: 'germanyDefaultSalesTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Default Purchase Tax Rate',
      description: 'Default Purchase Tax Rate',
      key: 'germanyDefaultPurchaseTaxRate', //check
      objectField: 'germanyDefaultPurchaseTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactUaeConfig = {
  module: 'contact',
  countryCode: 'ae',
  fields: [
    {
      id: 5,
      name: 'Tax Treatment',
      description: 'Tax Treatment',
      objectField: 'taxTreatment', //check
      key: 'taxTreatment',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'VAT_Registered', key: 'VAT Registered' },
        { value: 'Non_VAT_Registered', key: 'Non VAT Registered' },
        { value: 'GCC_VAT_Registered', key: 'GCC VAT Registered' },
        { value: 'GCC_Non_VAT_Registered', key: 'GCC Non VAT Registered' },
        { value: 'Non_GCC', key: 'Non GCC' },
        {
          value: 'VAT_Registered_Designated_Zone',
          key: 'VAT Registered-Designated Zone'
        },
        {
          value: 'Non_VAT_Registered_Designated_Zone',
          key: 'Non VAT Registered-Designated Zone'
        }
      ],
      default: 'VAT_Registered',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'VAT Registration Number',
      description: 'VAT Registration Number',
      key: 'vatRegistrationNo',
      objectField: 'vatRegistrationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'taxTreatment',
              operator: 'equal',
              value: 'GCC_VAT_Registered'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'VAT Registration Number',
      description: 'VAT Registration Number',
      key: 'vatRegistrationNo',
      objectField: 'vatRegistrationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'taxTreatment',
              operator: 'equal',
              value: 'VAT_Registered'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactUkConfig = {
  module: 'contact',
  countryCode: 'uk',
  fields: [
    {
      id: 0,
      name: 'Business Registration No',
      description: 'Business Registration No',
      key: 'ukBusinessRegistrationNo',
      objectField: 'ukBusinessRegistrationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'VAT Identification Number',
      description: 'VAT Identification Number',
      key: 'ukVatIdentificationNo',
      objectField: 'ukVatIdentificationNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Default Sales Tax Rate',
      description: 'Default Sales Tax Rate',
      key: 'ukDefaultSalesTaxRate', //check
      objectField: 'ukDefaultSalesTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Default Purchase Tax Rate',
      description: 'Default Purchase Tax Rate',
      key: 'ukDefaultPurchaseTaxRate', //check
      objectField: 'ukDefaultPurchaseTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactAuConfig = {
  module: 'contact',
  countryCode: 'au',
  fields: [
    {
      id: 0,
      name: 'Australian Company Number',
      description: 'Australian Company Number',
      key: 'australianCompanyNo',
      objectField: 'australianCompanyNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'Australian Business Number',
      description: 'Australian Business Number',
      key: 'australianBusinessNo',
      objectField: 'australianBusinessNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactCaConfig = {
  module: 'contact',
  countryCode: 'ca',
  fields: [
    {
      id: 0,
      name: 'Business Number',
      description: 'Business Number',
      key: 'canadianBusinessNo',
      objectField: 'canadianBusinessNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 1,
      name: 'GST Number',
      description: 'GST Number',
      key: 'canadianGstNo',
      objectField: 'canadianGstNo',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 2,
      name: 'Default Sales Tax Rate',
      description: 'Default Sales Tax Rate',
      key: 'canadianDefaultSalesTaxRate', //check
      objectField: 'canadianDefaultSalesTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!PURCHASE&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 3,
      name: 'Default Purchase Tax Rate',
      description: 'Default Purchase Tax Rate',
      key: 'canadianDefaultPurchaseTaxRate', //check
      objectField: 'ukDefaultPurchaseTaxRate', //check
      type: 'select',
      optionValues: [],
      default: '',
      rules: [],
      optionNameKey: 'name',
      optionValueKey: 'code',
      optionApi:
        'taxes?limit=50&page=0&search={searchValue}&query=status=ACTIVE,type!SALES&limit=50',
      apiMethod: 'GET',
      required: true,
      editable: true,
      display: true
    }
  ]
};

export const contactInConfig = {
  module: 'contact',
  countryCode: 'in',
  fields: [
    {
      id: 0,
      name: 'PAN/TAN Number',
      description: 'PAN/TAN Number',
      key: 'pan',
      objectField: 'pan',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: [],
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'GST Treatment',
      description: 'GST Treatment',
      objectField: 'gstTreatment', //check
      key: 'gstTreatment',
      type: 'select',
      dataType: '',
      optionValues: [
        {
          value: 'REGISTERED_BUSINESS_REGULAR',
          key: 'Registered Business - Regular'
        },
        {
          value: 'REGISTERED_BUSINESS_COMPOSITION',
          key: 'Registered Business - Composition'
        },
        { value: 'UNREGISTERED_BUSINESS', key: 'Unregistered Business' },
        { value: 'CUSTOMER', key: 'Consumer' },
        { value: 'OVERSEAS', key: 'Overseas' },
        { value: 'SPECIAL_ECONOMIC_ZONE', key: 'Special Economic Zone' },
        { value: 'DEEMED_EXPORT', key: 'Deemed Export' }
      ],
      default: 'REGISTERED_BUSINESS_REGULAR',
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 0,
      name: 'GSTIN',
      description: 'GSTIN',
      key: 'gstin',
      objectField: 'gstin',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_REGULAR'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 0,
      name: 'GSTIN',
      description: 'GSTIN',
      key: 'gstin',
      objectField: 'gstin',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_COMPOSITION'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 0,
      name: 'GSTIN',
      description: 'GSTIN',
      key: 'gstin',
      objectField: 'gstin',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'OVERSEAS'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 0,
      name: 'GSTIN',
      description: 'GSTIN',
      key: 'gstin',
      objectField: 'gstin',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'SPECIAL_ECONOMIC_ZONE'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 0,
      name: 'GSTIN',
      description: 'GSTIN',
      key: 'gstin',
      objectField: 'gstin',
      type: 'textField',
      optionValues: [],
      default: '',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'DEEMED_EXPORT'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_REGULAR'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_REGULAR'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [{ value: 'NA', key: 'NA' }],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_COMPOSITION'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [{ value: 'NA', key: 'NA' }],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'REGISTERED_BUSINESS_COMPOSITION'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'UNREGISTERED_BUSINESS'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'UNREGISTERED_BUSINESS'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'CUSTOMER'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'NA', key: 'NA' },
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'CUSTOMER'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'EXPORT_WO_PAY', key: 'Export (WOPAY)' },
        { value: 'EXPORT_W_PAY', key: 'Export (WPAY)' }
      ],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'OVERSEAS'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [{ value: 'IMPORT', key: 'Import' }],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'OVERSEAS'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'SEZ_WO_PAY',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'SPECIAL_ECONOMIC_ZONE'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [
        { value: 'SEZ_WO_PAY', key: 'SEZ (WOPAY)' },
        { value: 'SEZ_W_PAY', key: 'SEZ (WPAY)' }
      ],
      default: 'SEZ_WO_PAY',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'SPECIAL_ECONOMIC_ZONE'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Customer Type',
      description: 'Customer Type',
      objectField: 'customerType', //check
      key: 'customerType',
      type: 'select',
      dataType: '',
      optionValues: [{ value: 'NA', key: 'NA' }],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'DEEMED_EXPORT'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 5,
      name: 'Vendor Type',
      description: 'Vendor Type',
      objectField: 'vendorType', //check
      key: 'vendorType',
      type: 'select',
      dataType: '',
      optionValues: [{ value: 'NA', key: 'NA' }],
      default: 'NA',
      rules: {
        conditions: {
          all: [
            {
              fact: 'gstTreatment',
              operator: 'equal',
              value: 'DEEMED_EXPORT'
            }
          ]
        },
        event: {
          type: 'render',
          params: {
            shouldDisplay: true
          }
        }
      },
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 4,
      name: 'Residential Status',
      description: 'Residential Status',
      key: 'resident',
      objectField: 'resident', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [
        {
          key: 'Resident',
          value: true
        },
        {
          key: 'Non Resident',
          value: false
        }
      ],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    },
    {
      id: 4,
      name: 'Is TDS Applicable?',
      description: 'Is TDS Applicable?',
      key: 'resident',
      objectField: 'tdsApplicableIndia', //check
      type: 'checkBox',
      dataType: '',
      optionValues: [],
      default: true,
      rules: [],
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true
    }
  ]
};
