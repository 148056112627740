import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';
import FixedAssetsService from '../../Services/FixedAssets';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchFixedAssets = createAsyncThunk('fixedAsset', async () => {
  const response = await FixedAssetsService.getFixedAssetByPage();
  return response;
});

export const FixedAssetSlice = createSlice({
  name: 'Fixed Asset',
  initialState,
  reducers: {
    fixedAssetColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.FIXED_ASSET
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnTofixedAssetColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateFixedAssetColumnDetails: (state, action: PayloadAction<any>) => {
      const fixedAssetDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const fixedAssetIndex: number = content.findIndex(
        (contact: any) => contact.id === fixedAssetDetails.id
      );
      if (fixedAssetIndex >= 0) {
        content[fixedAssetIndex] = fixedAssetDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFixedAssets.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchFixedAssets.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnTofixedAssetColumnConfig,
  fixedAssetColumnConfig,
  updateColumnConfig,
  updateFixedAssetColumnDetails,
  updateRefreshCallback
} = FixedAssetSlice.actions;

export const selectFixedAsset = (state: RootState) => state.FixedAsset.data;

export const selectFixedAssetLoadingStatus = (state: RootState) =>
  state.FixedAsset.gridContentLoadingStatus;

export const selectFixedAssetColumnConfig = (state: RootState) =>
  state.FixedAsset.columnConfig;

export const selectFixedAssetColumnConfigTableId = (state: RootState) =>
  state.FixedAsset.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.FixedAsset.refreshCallback;

export default FixedAssetSlice.reducer;
