import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { BulkDeleteResponse } from '../Models/Interfaces';
import { Quote } from '../Models/Quote';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface QuotationAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  WithFilter?: any;
  WithoutFilter?: any;
  Sort?: string;
  SortDir?: string;
  SalesPerson?: number;
}

export const defaultConfig: QuotationAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  WithFilter: '',
  Sort: 'documentDate',
  SortDir: 'DESC',
  WithoutFilter: '',
  SalesPerson: 0
};
class QuotationService {
  static apiConfig: QuotationAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION;
  static abortController: any = null;

  static getQuotationByPage() {
    Utility.cancelRequest(QuotationService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
    );

    const query: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&query=${this.apiConfig.Query ? this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }${
      this.apiConfig.SalesPerson && this.apiConfig.SalesPerson !== 0
        ? '&salesperson=' + this.apiConfig.SalesPerson
        : ''
    }`;

    QuotationService.abortController = Utility.createAbortController();

    return http
      .get(`${ApiConstants.URL.QUOTES.FETCH_QUOTES(country)}${query}`, {
        signal: QuotationService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createQuote(payload: Quote) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
    );
    return http2
      .post(`${ApiConstants.URL.QUOTES.CREATE_QUOTE(country)}`, payload)
      .catch((err: any) => {
        console.error('Error creating quote: ', err);
        return Promise.reject(err);
      });
  }

  static updateQuote(payload: Quote, quoteId: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
    );
    return http2
      .put(ApiConstants.URL.QUOTES.UPDATE_QUOTE(quoteId, country), payload)
      .catch((err: any) => {
        console.error('Error updating quote: ', err);
        return Promise.reject(err);
      });
  }

  static async patchUpdateQuote(quoteId: number, payload: any) {
    return http2
      .patch(ApiConstants.URL.QUOTES.UPDATE_QUOTE_BY_KEYS(quoteId), payload)
      .then((res: any) => Promise.resolve(res))
      .catch((err: any) => {
        console.error('Error while trying to update quote primary', err);
        return Promise.reject(err);
      });
  }

  static getQuoteByCode(quoteCode: string): Promise<any> {
    return http
      .get(ApiConstants.URL.QUOTES.FETCH_QUOTE_BY_CODE(quoteCode))
      .catch((err: any) => {
        console.error('Error fetching quote details: ', err);
        return Promise.reject(err);
      });
  }

  static deleteQuote(id: number) {
    const countryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
    );
    return http2
      .delete(`${ApiConstants.URL.QUOTES.DELETE_QUOTES(id, countryCode)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(ApiConstants.URL.QUOTES.BULK_DELETE, config);
  }
  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.QUOTE_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default QuotationService;
