import React, { Component } from 'react'
import { FONT_SIZE, PRINT_SCALE } from '../Constants/Constants';
import { getLocalisedText } from '../Translate/LanguageManager';
import { HTMLTag } from '../Utilities/HTMLTag';
import Utility from '../Utilities/Utility';
import WatermarkUtility from '../Utilities/WatermarkUtility';
import TemplateSettingsManager from './TemplateSettingsManager';
import { watermarkPosition, watermarkPrintType } from '../Helper/types';

export default class WatermarkManager extends Component {

    render() {
        return (
            <div id={HTMLTag.WATERMARK_MANAGER}
            >
                {this.getIsShowTopSection() && this.getWatermarkDiv()}
                {this.props.mainContent}
                {this.getIsShowBottomSection() && this.getWatermarkDiv()}
            </div>
        )
    }

    getWatermarkDiv() {
        var position = 'center'
        var watermark = this.getSelectedPosition()
        if(watermark !== undefined) {
            if (watermark.label === 'left') {
                position = 'flex-start'
            }
            else if (watermark.label === 'right') {
                position = 'flex-end'
            }
        }

        var printScale = this.props?.printScale
        if(printScale === undefined) {
            printScale = PRINT_SCALE
        }
        return <div
            id={HTMLTag.WATERMARK_TEXT}
            className="RowDiv"
            style={{
                justifyContent: position,
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, printScale)
            }}
            onClick={() => this.props.onWatermarkClick()}>
            {getLocalisedText(WatermarkUtility.getWatermarkValue(this.props?.wmark))}
        </div>
    }

    getIsShowWatermark() {
        if (this.props?.wmark && this.props.wmark !== watermarkPrintType.none) {
            return TemplateSettingsManager.getWatermarkVisible();
        }

        var printInfo = TemplateSettingsManager.getPrintInfo()
        if(printInfo !== undefined) {
            if (printInfo.wmark !== undefined) {
                if(printInfo.wmark === watermarkPrintType.none) {
                    return false
                }
            }
            else {
                return false
            }
        }
        return TemplateSettingsManager.getWatermarkVisible()
    }

    getWatermarkPosition() {
        var watermarkConfiguration = WatermarkUtility.getWatermarkConfiguration()
        if (watermarkConfiguration !== undefined) {
            if (watermarkConfiguration.position !== undefined && watermarkConfiguration.position !== null) {
                return watermarkConfiguration.position
            }
        }

        return watermarkPosition.topLeft
    }

    getIsShowTopSection() {
        if(this.getIsShowWatermark()) {
            var position = this.getWatermarkPosition()
            if(position !== undefined) {
                return (position === watermarkPosition.topLeft
                    || position === watermarkPosition.topRight)
            }
        }
        return false
    }

    getIsShowBottomSection() {
        if (this.getIsShowWatermark()) {
            var position = this.getWatermarkPosition()
            if (position !== undefined) {
                return (position === watermarkPosition.bottomLeft
                    || position === watermarkPosition.bottomMiddle
                    || position === watermarkPosition.bottomRight)
            }
        }
        return false
    }

    getSelectedPosition() {
        var position = this.getWatermarkPosition()
        if (position !== undefined) {
            var watermark = WatermarkUtility.getDefaultPositionList().find(x => x.type === position)
            return watermark
        }

        return undefined
    }
}
