import React, { useEffect, useState } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  DKLabel,
  DKButton,
  DKSpinner
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../Constants/Constant';
import { selectChequeTemplateList } from '../../Redux/Slices/CommonDataSlice';
import { useAppSelector } from '../../Redux/Hooks';
import PrintService from '../../Services/Print';
import printJS from 'print-js';

interface formState {
  value: any;
  hasError: boolean;
  errorMsg: string;
}

const PrintCheque = (props: any) => {
  const initialFormState: formState = {
    value: '',
    hasError: true,
    errorMsg: ''
  };

  const [formData, setFormData] = useState<formState>(initialFormState);
  const chequeTemplateList = useAppSelector(selectChequeTemplateList);
  const [startPring, setStartPrint] = useState<boolean>(false);

  useEffect(() => {
    if (chequeTemplateList && chequeTemplateList.length > 0) {
      if (chequeTemplateList[0].templates.length > 0) {
        let data = { ...formData };
        data.value = chequeTemplateList[0].templates[0];
        setFormData({ ...data });
      }
    }
  }, [chequeTemplateList]);

  const printChequeDocument = async () => {
    try {
      let Payload: any = {};
      Payload = {
        moduleName: 'ERP',
        categoryName: 'CHEQUE',
        templateId: formData.value.id,
        headers: props.data
      };
      let response: any = await PrintService.exportTemplateDocument(Payload);
      if (response) {
        const responseBlob = new Blob([response], {
          type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(responseBlob);
        
        let responseBlobFrame = document.createElement('iframe') as any;
        var printChequeIframe = document.getElementById('cheque_print_iframe')
        if (printChequeIframe !== undefined && printChequeIframe !== null) {
          responseBlobFrame = printChequeIframe
        }
        document.body.appendChild(responseBlobFrame);
        responseBlobFrame.style.display = 'none';
        responseBlobFrame.src = fileURL;
        responseBlobFrame.id = 'cheque_print_iframe'
        responseBlobFrame.onload = function () {
          setTimeout(function () {
            responseBlobFrame.focus();
            responseBlobFrame.contentWindow.print();
          }, 1);
        };
        props.onCancel();
      }
    } catch (error: any) {
      console.error('Error printing cheque: ', error);
    }
  };

  const getRenderLoadingBtnTitle = (value: any) => {
    return (
      <div className="flex">
        {value}
        <DKSpinner iconClassName="ic-s" className="ml-r" />
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel text={`Select Template`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={startPring ? getRenderLoadingBtnTitle(`Printing`) : `Print`}
            disabled={
              chequeTemplateList && chequeTemplateList.length <= 0
                ? true
                : false
            }
            className={`${
              (chequeTemplateList && chequeTemplateList.length <= 0) ||
              startPring
                ? `border-m mr-s`
                : `bg-button text-white cursor-hand`
            }`}
            onClick={() => {
              setStartPrint(true);
              printChequeDocument();
            }}
          />
        </div>
      </div>
    );
  };

  const changeTemplate = (selectedTemplate: any) => {
    let data = { ...formData };
    data.value = selectedTemplate;
    setFormData({ ...data });
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 975,
          width: '20%',
          maxHeight: '95%',
          padding: 0,
          height: '250px',
          paddingBottom: '40px'
        }}
      >
        {getHeader()}
        <div id="popup-container" className="column parent-width parent-height">
          <div className="column parent-width mt-2 p-h-r p-v-s">
            <div className="row">
              <DKInput
                type={INPUT_TYPE.DROPDOWN}
                title={'Document Template'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={formData.value.name}
                className="mt-l"
                onChange={(obj: any) => {
                  changeTemplate(obj);
                }}
                dropdownConfig={{
                  title: '',
                  allowSearch: true,
                  searchableKey: 'name',
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  searchApiConfig: null,
                  data:
                    chequeTemplateList && chequeTemplateList.length > 0
                      ? chequeTemplateList[0].templates
                      : [],
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={`${obj.name}`} />;
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintCheque;
