import { INPUT_TYPE } from 'deskera-ui-library';
import { DocumentConfigUtility } from '../Utility/DocumentConfigUtility';
import ApiConstants from '../Constants/ApiConstants';
import AccountsService, { AccountAPIConfig } from '../Services/Accounts';

export class PartyJEConfigManager {
  static docConfigs: any = [
    {
      id: 'contact',
      key: 'contact',
      name: 'Contact',
      type: INPUT_TYPE.DROPDOWN,
      width: 290,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: DocumentConfigUtility.productFormatter,
      dropdownConfig: {
        title: 'Select Contact',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: {
          getUrl: null,
          dataParser: DocumentConfigUtility.contactDataParser,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.contactDataParser,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      }
    },
    {
      id: 'account',
      key: 'account',
      name: 'Account',
      type: INPUT_TYPE.DROPDOWN,
      width: 290,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: DocumentConfigUtility.productFormatter,
      dropdownConfig: {
        title: 'Select Account',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: {
          getUrl: null,
          dataParser: DocumentConfigUtility.productDataParser,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.productRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      }
    },
    {
      id: 'description',
      key: 'description',
      name: 'Description',
      type: INPUT_TYPE.TEXT,
      width: 240,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'type',
      key: 'type',
      name: 'Type',
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      required: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      formatter: (obj: any) => {
        return obj.value;
      },
      dropdownConfig: {
        title: 'Select Type',
        style: { minWidth: 150 },
        className: 'shadow-m',
        data: [],
        renderer: (index: any, obj: any) => {
          return obj;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'amountInDocumentCurrency',
      key: 'amountInDocumentCurrency',
      name: 'Amount',
      type: INPUT_TYPE.NUMBER,
      width: 190,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      required: true
    },
    {
      id: 'generateCNDN',
      key: 'generateCNDN',
      name: 'Generate CN/DN',
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      required: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      formatter: (obj: any) => {
        return obj.value;
      },
      dropdownConfig: {
        title: '',
        style: { minWidth: 150 },
        className: 'shadow-m',
        data: [],
        renderer: (index: any, obj: any) => {
          return obj;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    }
  ];

  static getAccountURL = (search: string) => {
    const config: AccountAPIConfig = {
      ...AccountsService.apiConfig,
      Page: 0,
      SearchTerm: search,
      Limit: 10
    };
    AccountsService.apiConfig = config;
    return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
  };

  static getRequiredFields = () =>
    PartyJEConfigManager.docConfigs
      .filter((conf: any) => conf.required)
      .map((conf: any) => conf.key);
}
