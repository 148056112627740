// Styles for contact search dropdown
export const customStylesForContact = {
  menu: (provided: any, state: any) => ({
    ...provided,
    marginTop: '0',
    marginBottom: '0'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    backgroundColor: state.isSelected
      ? 'var(--books-color)'
      : state.isFocused
      ? 'var(--main-color-hover)'
      : null
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    width: 200
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? `var(--books-color)` : provided.borderColor,
    '&:hover': {
      borderColor: `var(--main-color-hover)`
    },
    boxShadow: state.isFocused
      ? `0 0 0 1px var(--books-color)`
      : provided.boxShadow
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

// Styles for product search dropdown
export const productSearchSelectStyle = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: '100%'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    fontSize: '0.875rem',
    backgroundColor: state.isSelected
      ? 'var(--books-color)'
      : state.isFocused
      ? 'var(--main-color-hover)'
      : null
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    display: 'none'
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    zIndex: 9999
  })
};

// Styles for tax dropdown
export const taxSearchSelectStyle = {
  ...productSearchSelectStyle,
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 'max-content',
    left: '50%',
    transform: 'translateX(-50%)'
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5px',
    paddingLeft: '5px'
  })
};

// New Styles for product dropdown
export const getProductSearchSelectStyle = (product: any) => {
  return {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '200%'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      textAlign: 'left',
      fontSize: '0.875rem',
      backgroundColor: state.isSelected
        ? 'var(--btn-bg-color)'
        : state.isFocused
        ? 'var(--main-color-hover)'
        : null
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: 28,
      maxHeight: 28,
      borderWidth: 1,
      boxShadow: 'none',
      borderStyle: product ? 'solid' : 'dashed',
      borderColor: product ? 'transparent' : 'rgb(22, 100, 215)',
      '&:hover': {
        borderColor: `var(--gray-1)`,
        borderStyle: 'solid',
        boxShadow: 'none'
      }
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      display: 'none'
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: 2
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)',
      padding: 2
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)'
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0
    })
  };
};

// New styles for tax dropdown
export const newTaxSearchSelectStyle = {
  ...productSearchSelectStyle,
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 'max-content',
    left: '50%',
    transform: 'translateX(-50%)'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 28,
    maxHeight: 28,
    boxShadow: 'none',
    border: '1px solid transparent',
    '&:hover, &:focus, &:active, &:focus-visible': {
      border: '1px solid var(--gray-1)',
      boxShadow: 'none'
    }
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5px',
    paddingLeft: '5px'
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    top: '45%',
    transform: 'translateY(-50%)'
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    top: '45%',
    transform: 'translateY(-50%)'
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: 0
  })
};
