import { TEXT_ALIGNMENT } from '../Constants/Constant';
import { ReactSelectOptionsType } from './Interfaces';

export interface GridProps {
  gridName: string;
  readOnly?: boolean;
  headerConfig: HeaderItem[];
  gridItems: Array<GridItem>;
  inlineItemDelete: (itemIndex: number) => void;
  inlineItemUpdated: (itemInfo: InlineItemUpdated) => void;
  onBlur?: (itemInfo: InlineItemUpdated) => void;
  inlineItemAdded: () => void;
  inlineItemTDSDeduct: (itemIndex: number) => void;
  currency: string;
  addTax: () => void;
  addAccount: () => void;
}

export interface GridItem {
  account: GridDropdown;
  description: GridTextArea;
  amount: GridInput;
  tax: GridDropdown;
  taxAmount: GridFormatAmount;
  paymentAmount: GridFormatAmount;
  actions: any;
}

export interface HeaderItem {
  text: string;
  textAlign: TEXT_ALIGNMENT;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
  hidden?: boolean;
}

export enum GRID_COLUMN_NAME {
  S_NO = '#',
  ACCOUNT = 'Account',
  DESCRIPTION = 'Description',
  AMOUNT = 'Amount',
  TAX = 'Tax',
  TAX_AMOUNT = 'Tax Amount',
  PAYMENT_AMOUNT = 'Payment Amount'
}

export interface AsyncGridDropdown {
  styles: GRID_INPUT_STYLE;
  menuShouldScrollIntoView: boolean;
  defaultOptions: boolean;
  cacheOptions: boolean;
  menuPosition: string;
  menuPlacement: string;
  defaultValue?: null;
  itemType: GRID_ITEMS.ASYNC_DROPDOWN;
  columnName: GRID_COLUMN_NAME;
}

export interface GridDropdown {
  styles: GRID_INPUT_STYLE;
  defaultValue: ReactSelectOptionsType<string, string> | null;
  options: ReactSelectOptionsType<string, string>[] | null;
  menuShouldScrollIntoView: boolean;
  menuPosition: string;
  menuPlacement: string;
  itemType: GRID_ITEMS.DROPDOWN;
  columnName: GRID_COLUMN_NAME;
  hidden?: boolean;
}

export interface GridInput {
  defaultValue: string | number;
  type: string;
  itemType: GRID_ITEMS.INPUT;
  columnName: GRID_COLUMN_NAME;
}

export interface GridFormatAmount {
  defaultValue: string | number;
  itemType: GRID_ITEMS.FORMAT_AMOUNT;
  columnName: GRID_COLUMN_NAME;
  hidden?: boolean;
}

export interface GridTextArea {
  itemType: GRID_ITEMS.TEXTAREA;
  defaultValue: string | number;
  columnName: GRID_COLUMN_NAME;
}

export interface InlineItemUpdated {
  rowIndex: number;
  data: {
    itemName: keyof GridItem;
    value: ReactSelectOptionsType<string, string> | string | number;
    inlineItemType: GRID_ITEMS;
  };
}

export enum GRID_ITEMS {
  ASYNC_DROPDOWN = 'asyncDropdown',
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  TEXTAREA = 'textarea',
  FORMAT_AMOUNT = 'formatAmount'
}

export enum GRID_INPUT_STYLE {
  PRODUCT = 'product',
  TAX = 'tax'
}
