import { getLocalisedText } from "../Translate/LanguageManager"

export default class PaymentReceiptMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('payment_receipt')
        label.customerAddressPlaceholder = 'customer_address_placeholder_text_value'
        label.receivedFrom = 'received_from'
        label.paymentDetails = 'payment_details'
        return label
    }
}