import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class SOACustomerTableWidth {
    isLineNumberVisible: boolean;
    isDocumentDateVisible: boolean;
    isDocumentNumberVisible: boolean;
    isParticularVisible: boolean;
    isDocumentTypeVisible: boolean;
    isCreditVisible: boolean;
    isDebitVisible: boolean;
    isAmountInBaseVisible: boolean;
    isBalanceInBaseVisible: boolean;
    isCurrencyVisible: boolean;
    isMemoVisible: boolean;

    lineNumber: number;
    documentDate: number;
    documentNumber: number;
    particular: number;
    documentType: number;
    credit: number;
    debit: number;
    amountInBase: number;
    balanceInBase: number;
    currency: number;
    memo: number;

    originalLineNumber: number;
    originalDocumentDate: number;
    originalDocumentNumber: number;
    originalParticular: number;
    originalDocumentType: number;
    originalCredit: number;
    originalDebit: number;
    originalAmountInBase: number;
    originalBalanceInBase: number;
    originalCurrency: number;
    originalMemo: number;

    constructor(data: any) {
        this.isLineNumberVisible = true
        this.isDocumentDateVisible = true
        this.isDocumentNumberVisible = true
        this.isParticularVisible = true
        this.isDocumentTypeVisible = true
        this.isCreditVisible = true
        this.isDebitVisible = true
        this.isAmountInBaseVisible = false
        this.isBalanceInBaseVisible = true
        this.isCurrencyVisible = false
        this.isMemoVisible = false


        this.lineNumber = 5
        this.documentDate = 15
        this.documentNumber = 10
        this.particular = 10
        this.documentType = 10
        this.credit = 10
        this.debit = 10
        this.amountInBase = 10
        this.balanceInBase = 10
        this.currency = 5
        this.memo = 5


        this.originalLineNumber = 5
        this.originalDocumentDate = 15
        this.originalDocumentNumber = 10
        this.originalParticular = 10
        this.originalDocumentType = 10
        this.originalCredit = 10
        this.originalDebit = 10
        this.originalAmountInBase = 10
        this.originalBalanceInBase = 10
        this.originalCurrency = 5
        this.originalMemo = 5
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isDocumentDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentDate)
        this.isDocumentNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentNumber)
        this.isParticularVisible = getIsShowTableColumn(columnData, TableColumnPopupType.particular)
        this.isDocumentTypeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.documentType)
        this.isCreditVisible = getIsShowTableColumn(columnData, TableColumnPopupType.credit)
        this.isDebitVisible = getIsShowTableColumn(columnData, TableColumnPopupType.debit)
        this.isAmountInBaseVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amountInBase)
        this.isBalanceInBaseVisible = getIsShowTableColumn(columnData, TableColumnPopupType.balanceInBase)
        this.isCurrencyVisible = getIsShowTableColumn(columnData, TableColumnPopupType.currency)
        this.isMemoVisible = getIsShowTableColumn(columnData, TableColumnPopupType.memo)
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        this.documentDate = this.originalDocumentDate
        this.documentNumber = this.originalDocumentNumber
        this.particular = this.originalParticular
        this.documentType = this.originalDocumentType
        this.credit = this.originalCredit
        this.debit = this.originalDebit
        this.amountInBase = this.originalAmountInBase
        this.balanceInBase = this.originalBalanceInBase
        this.currency = this.originalCurrency
        this.memo = this.originalMemo
    }

    private resetElementWidth() {
        this.lineNumber = 0
        this.documentDate = 0
        this.documentNumber = 0
        this.particular = 0
        this.documentType = 0
        this.credit = 0
        this.debit = 0
        this.amountInBase = 0
        this.balanceInBase = 0
        this.currency = 0
        this.memo = 0
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isDocumentDateVisible) {
            totalWidth += this.documentDate;
        }
        if (this.isDocumentNumberVisible) {
            totalWidth += this.documentNumber;
        }
        if (this.isParticularVisible) {
            totalWidth += this.particular;
        }
        if (this.isDocumentTypeVisible) {
            totalWidth += this.documentType;
        }
        if (this.isCreditVisible) {
            totalWidth += this.credit;
        }
        if (this.isDebitVisible) {
            totalWidth += this.debit;
        }
        if (this.isAmountInBaseVisible) {
            totalWidth += this.amountInBase;
        }
        if (this.isBalanceInBaseVisible) {
            totalWidth += this.balanceInBase;
        }
        if (this.isCurrencyVisible) {
            totalWidth += this.currency;
        }
        if (this.isMemoVisible) {
            totalWidth += this.memo;
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isParticularVisible) {
            totalSelectedElement += 1
        }
        if (this.isDocumentTypeVisible) {
            totalSelectedElement += 1
        }
        if (this.isCreditVisible) {
            totalSelectedElement += 1
        }
        if (this.isDebitVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountInBaseVisible) {
            totalSelectedElement += 1
        }
        if (this.isBalanceInBaseVisible) {
            totalSelectedElement += 1
        }
        if (this.isCurrencyVisible) {
            totalSelectedElement += 1
        }
        if (this.isMemoVisible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isDocumentDateVisible) {
            this.documentDate = this.originalDocumentDate + additionalWidth
        }
        if (this.isDocumentNumberVisible) {
            this.documentNumber = this.originalDocumentNumber + additionalWidth
        }
        if (this.isParticularVisible) {
            this.particular = this.originalParticular + additionalWidth
        }
        if (this.isDocumentTypeVisible) {
            this.documentType = this.originalDocumentType + additionalWidth
        }
        if (this.isCreditVisible) {
            this.credit = this.originalCredit + additionalWidth
        }
        if (this.isDebitVisible) {
            this.debit = this.originalDebit + additionalWidth
        }
        if (this.isAmountInBaseVisible) {
            this.amountInBase = this.originalAmountInBase + additionalWidth
        }
        if (this.isBalanceInBaseVisible) {
            this.balanceInBase = this.originalBalanceInBase + additionalWidth
        }
        if (this.isCurrencyVisible) {
            this.currency = this.originalCurrency + additionalWidth
        }
        if (this.isMemoVisible) {
            this.memo = this.originalMemo + additionalWidth
        }
    }
}