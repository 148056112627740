import { useEffect, useState, lazy, Suspense } from 'react';
import { DKLabel, DKSpinner } from 'deskera-ui-library';
import Utility from '../../Utility/Utility';
import { NEW_SETTINGS_SECTION } from '../../Constants/Constant';
import OrganisationProfile from './OrganisationProfile/OrganisationProfile';
import MultiCompanySettings from './MultiCompanySettings/MultiCompanySettings';
import BarcodeSettings from './Barcode/BarcodeSettings';
import ClientPortal from './ClientPortal/ClientPortal';
import QCFlow from './QcFlow/QCFlow';
import MRPQualityCheck from '../Mrp/QualityCheck/MRPQualityCheck';
import RateAnalysis from './RateAnalysis/RateAnalysis';
import WHTax from './WHT/WHTax';
import LabelTemplate from '../LabelTemplate/LabelTemplate';
import JobCardsEmailSettings from './JobCardsEmailSettings/JobCardsEmailSettings';
interface SettingsRightPanelProps {
  selectedSetting: any;
}
const Currency = lazy(() => import('./MultiCurrency/Currency'));
const Tax = lazy(() => import('./Tax/Tax'));
const PaymentTerm = lazy(() => import('./PaymentTerm/PaymentTerm'));
const Uom = lazy(() => import('./UOM/Uom'));
const PrintTemplate = lazy(() => import('../PrintTemplate/PrintTemplate'));
const Emails = lazy(() => import('./Emails'));
const UomSchema = lazy(() => import('./UOMSchema/UomSchema'));
const CustomFieldsList = lazy(() => import('./CustomFieldsList'));
const LocationsList = lazy(() => import('./Locations/LocationsList'));
const Agencies = lazy(() => import('./Agencies/Agencies'));
const Classes = lazy(() => import('./Classes/Classes'));
const TDSSetup = lazy(() => import('./TDSSetup/Tds'));
const TCSRates = lazy(() => import('./TCSSetup/Tcs'));
const RevenueCategoryList = lazy(() => import('./RevenueCategoryList'));
const FairValueFormaulae = lazy(() => import('./FairValueFormulae'));
const RevenueRecognition = lazy(() => import('./RevenueRecognition'));
const ItemLandedCost = lazy(() => import('./ItemLandedCost/ItemLandedCost'));
const SalesPersonMaster = lazy(() => import('./SalesPersonMaster/SalesPersonMaster'));
const CreditLimit = lazy(() => import('./CreditLimit/CreditLimit'));
const SequenceNumberFormat = lazy(
  () => import('./SequenceNumberFormat/SequenceNumberFormat')
);
const RevRecJELogs = lazy(() => import('./RevRecJELogs'));
const AdvancedSettings = lazy(
  () => import('./AdvancedSettings/AdvancedSettings')
);

const UserPermissions = lazy(
  () => import('./GranularPermissions/UserPermissions')
);
const ActiveDateRange = lazy(() => import('./ActiveDateRange/ActiveDateRange'));
const BackDate = lazy(() => import('./BackDatedSettings/BackDate'));
const WhatsAppAPI = lazy(() => import('./WhatsAppAPI/WhatsAppAPI'));

const SettingsRightPanel = (props: SettingsRightPanelProps) => {
  const [selectedSetting, setSelectedSetting] = useState<any>(null);

  useEffect(() => {
    if (!Utility.isEmpty(props.selectedSetting)) {
      setSelectedSetting({ ...props.selectedSetting });
    }
    return () => {
      setSelectedSetting(null);
    };
  }, [props.selectedSetting]);

  const getSuspenseWrappedComponent = (Component: any, gridDesc?: string) => {
    return (
      <Suspense
        fallback={
          <div className="row justify-content-center">
            <DKSpinner />
          </div>
        }
      >
        {gridDesc && <Component gridNameWithDesc={gridDesc} />}
        {!gridDesc && <Component />}
      </Suspense>
    );
  };

  const getGridTitleWithSummary = (title?: string) => {
    let result = '';
    if (title || selectedSetting.title) {
      result += `<div class='row'>${title || selectedSetting.title}</div>`;
    }
    if (selectedSetting.description) {
      result += `<div class='row fs-r fw-r mt-xs text-gray'>${selectedSetting.description}</div>`;
    }
    return result;
  };

  const getSectionComponent = () => {
    const section = selectedSetting?.propertyName as NEW_SETTINGS_SECTION;
    switch (section) {
      case NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS:
        return getSuspenseWrappedComponent(OrganisationProfile);

      case NEW_SETTINGS_SECTION.CURRENCY:
        return getSuspenseWrappedComponent(
          Currency,
          getGridTitleWithSummary('Multi Currency')
        );

      case NEW_SETTINGS_SECTION.TAX:
        return getSuspenseWrappedComponent(Tax, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.PAYMENT_TERMS:
        return getSuspenseWrappedComponent(
          PaymentTerm,
          getGridTitleWithSummary()
        );

      case NEW_SETTINGS_SECTION.UNIT_OF_MEASUREMENT:
        return getSuspenseWrappedComponent(Uom, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.PRINT_TEMPLATE:
        return getSuspenseWrappedComponent(PrintTemplate);

      case NEW_SETTINGS_SECTION.EMAIL:
        return getSuspenseWrappedComponent(Emails);

      case NEW_SETTINGS_SECTION.UOM_SCHEMA:
        return getSuspenseWrappedComponent(
          UomSchema,
          getGridTitleWithSummary('Unit of Measurement Schema')
        );

      case NEW_SETTINGS_SECTION.CUSTOM_FIELDS:
        return getSuspenseWrappedComponent(
          CustomFieldsList,
          getGridTitleWithSummary()
        );

      case NEW_SETTINGS_SECTION.LOCATIONS:
        return getSuspenseWrappedComponent(
          LocationsList,
          getGridTitleWithSummary()
        );

      case NEW_SETTINGS_SECTION.AGENCIES:
        return getSuspenseWrappedComponent(Agencies, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.CLASSES:
        return getSuspenseWrappedComponent(Classes, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.TDS_SETUP:
        return getSuspenseWrappedComponent(TDSSetup);

      case NEW_SETTINGS_SECTION.TCS_SETUP:
        return getSuspenseWrappedComponent(TCSRates, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.REVENUE_RECOGNITION:
        return getSuspenseWrappedComponent(RevenueCategoryList);

      case NEW_SETTINGS_SECTION.ITEM_REVENUE_CATEGORIES:
        return getSuspenseWrappedComponent(RevenueCategoryList);

      case NEW_SETTINGS_SECTION.FAIR_VALUE_FORMULAS:
        return getSuspenseWrappedComponent(FairValueFormaulae);

      case NEW_SETTINGS_SECTION.FAIR_VALUE_PRICE_LIST:
        return getSuspenseWrappedComponent(Currency, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.REVENUE_REC_RULES:
        return getSuspenseWrappedComponent(RevenueRecognition);

      case NEW_SETTINGS_SECTION.ILC_SETTING:
        return getSuspenseWrappedComponent(ItemLandedCost);

      case NEW_SETTINGS_SECTION.CREDIT_LIMIT:
        return getSuspenseWrappedComponent(CreditLimit);

      case NEW_SETTINGS_SECTION.SEQUENCE_NUMBER_SETTING:
        return getSuspenseWrappedComponent(SequenceNumberFormat);

      case NEW_SETTINGS_SECTION.REV_REC_JE_LOG:
        return getSuspenseWrappedComponent(RevRecJELogs);

      case NEW_SETTINGS_SECTION.ADVANCED_SETTINGS:
        return getSuspenseWrappedComponent(AdvancedSettings);

      case NEW_SETTINGS_SECTION.USER_PERMISSIONS:
        return getSuspenseWrappedComponent(UserPermissions);

      case NEW_SETTINGS_SECTION.ACTIVE_DATE_RANGE_SETTINGS:
        return getSuspenseWrappedComponent(ActiveDateRange);

      case NEW_SETTINGS_SECTION.BACK_DATED_RESTRICTIONS_SETTINGS:
        return getSuspenseWrappedComponent(BackDate);

      case NEW_SETTINGS_SECTION.MULTI_COMPANY_SETTINGS:
        return getSuspenseWrappedComponent(MultiCompanySettings);

      case NEW_SETTINGS_SECTION.BARCODE_SETTINGS:
        return getSuspenseWrappedComponent(BarcodeSettings);

      case NEW_SETTINGS_SECTION.CLIENT_PORTAL:
        return getSuspenseWrappedComponent(ClientPortal);

      case NEW_SETTINGS_SECTION.QC_FLOW_MODULES:
        return getSuspenseWrappedComponent(QCFlow);

      case NEW_SETTINGS_SECTION.QC_TEMPLATE:
        return getSuspenseWrappedComponent(
          MRPQualityCheck,
          getGridTitleWithSummary()
        );

      case NEW_SETTINGS_SECTION.RATE_ANALYSIS:
        return getSuspenseWrappedComponent(RateAnalysis);

      case NEW_SETTINGS_SECTION.WITHHOLDING_TAXES:
        return getSuspenseWrappedComponent(WHTax, getGridTitleWithSummary());

      case NEW_SETTINGS_SECTION.LABEL_TEMPLATE:
        return getSuspenseWrappedComponent(LabelTemplate);

      case NEW_SETTINGS_SECTION.JOB_CARDS_EMAIL_SETTINGS:
        return getSuspenseWrappedComponent(JobCardsEmailSettings);

      case NEW_SETTINGS_SECTION.WHATSAPP_API:
        return getSuspenseWrappedComponent(WhatsAppAPI);

      case NEW_SETTINGS_SECTION.SALES_PERSON_MASTER:
        return getSuspenseWrappedComponent(SalesPersonMaster);

      default:
        return <div>WIP {selectedSetting?.title} section</div>;
    }
  };

  return (
    <div className="column parent-size overflow-y-hidden pt-l p-h-xxl">
      {selectedSetting?.showHeader && (
        <>
          <div className="column parent-width">
            <DKLabel text={selectedSetting?.title} className="fw-m" />
            <DKLabel
              text={selectedSetting?.description}
              className="text-gray"
            />
          </div>
          <div
            className="column parent-width bg-gray2 mt-r"
            style={{ minHeight: 1 }}
          ></div>
        </>
      )}
      <div
        className={`column parent-width overflow-y-hidden flex-1 ${selectedSetting?.showHeader ? '' : 'mt-l'
          }`}
      >
        {getSectionComponent()}
      </div>
    </div>
  );
};
export default SettingsRightPanel;
