import React, { Component } from "react";
import "../../../index.css";
import { FONT_SIZE } from "../../../Constants/Constants";
import Utility, { getPageHeight, getVW } from "../../../Utilities/Utility";
import "../css/Templates.css";
import AddressParser, { AddressType } from "../../../Helper/AddressParser";

export default class USChequeTemplate1 extends Component {

    totalHeight = 196.64
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{  minHeight: getPageHeight()}}>
                <div className='parent-width' style={{ width: '100%'}}>
                    {this.renderCheque()}
                </div>
            </div>
        );
    }

    renderCheque() {
        if (this.state.data.lineItems === undefined || this.state.data.lineItems === null) {
            return undefined
        }

        if (this.state.data.lineItems.length === 0) {
            return undefined
        }

        var cheques = this.state.data.lineItems.map((element,index) => {
            return this.renderChequeContainer(element,index)
        });

        return cheques
    }

    renderChequeContainer(element,index) {
        return <div className="break-inside-avoid" style={{ paddingTop: getVW(5), paddingBottom: getVW(85)}}>
            <div >
                <div style={{
                    height: getVW(30+((index%3)*15)),
                }}>
                </div>
                {this.renderDateContainer(element)}
                <div style={{
                    height: getVW(35),
                }}>
                </div>

                {/* Contact Name And Amount */}
                {this.renderTopHeaderContainer(element)}
                <div style={{
                    height: getVW(10),
                }}>
                </div>
                            {/* Amount in word */}
                {this.renderMiddleContainer(element)}
                <div className="RowDiv" style={{ maxHeight: getVW(90), minHeight: getVW(90) }} >
                    { this.state.data.showClientBillToAddress &&this.renderBottomContainer(element)}
                </div>
            </div>
        </div>
    }

    renderDateContainer(element) {
        // date 
        return <div className="RowReverseDiv">
            {this.renderTopHeaderDataColumn(element.date, true)}
        </div>
    }

    renderTopHeaderContainer(element) {
        return <div className="RowDiv">
            <div style={{
                width: '5%',
                paddingLeft: getVW(7.6)
            }}></div>
            <div style={{
                width: '85%',
                paddingLeft: getVW(7.6)
            }}>
                {/* contact name  */}
                {this.renderTopHeaderDataColumn(element.payTo)}
            </div>
            {/* Amount in Digit */}
            <div style={{
                width: '10%',
                paddingRight: getVW(2)
            }}>
                {this.renderMiddleDataColumn(Utility.toCurrencyFormat(element.amount, ''), undefined, '',true)}
            </div>
        </div>
    }

    renderMiddleContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
            }}>
                {/* Amount in word */}
            {this.renderMiddleDataColumn(element.amountInWords, { padding: getVW(7.6)})}
        </div>
    }

    renderBottomContainer(element) {
        const address = AddressParser.getVisibleAddressFields(AddressType.billTo,{...this.state.data,clientBillToAddressObj: element.contactInfo.billingAddress}, this.state.data)        
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                paddingLeft: getVW(0)
            }}>
            <div style={{
                width: '5%',
                paddingLeft: getVW(7.6)
            }}></div>
            <div style={{
                whiteSpace: 'pre-line',
                width:'55%',
                paddingLeft: getVW(7.6),
                ...this.defaultStyle()
            }}
            dangerouslySetInnerHTML={{ __html: address }}>
                             
            </div>
            <div style={{
                width:'40%',
                paddingLeft: getVW(0),
                ...this.defaultStyle(),
            }}>
            </div>
        </div>
    }

    renderTopHeaderTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderTopHeaderDataColumn(text, leftSpace) {
        return <div style={{
            paddingLeft: getVW(leftSpace ? 24 : 8),
            paddingRight: getVW(leftSpace ? 0 : 8),
            ...this.defaultStyle()
        }}>
            {text}{leftSpace ? '': <>&nbsp;</>}
        </div>
    }

    renderMiddleTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            paddingTop: getVW(7.6),
            paddingBottom: getVW(7.6),
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleDataColumn(text, additionalStyle, className,leftSpace) {
        return <div className={className} style={{
            ...additionalStyle,
            ...this.defaultStyle()
        }}>
            {text}{leftSpace ? '': <>&nbsp;</>}
        </div>
    }



    defaultStyle() {
        return {
            fontFamily: 'calibri san-serif',
            lineHeight: '115%',
            textAlign: 'left',
            ...Utility.getFontStyleProp({fontSize: FONT_SIZE.medium}, this.state.data.printScale)
        }
    }
}
