import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';

export function enableMultiViewJournalEntryCondition(): boolean {
  const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  const isSubsidiary =
    tenantInfo.additionalSettings?.MULTI_COMPANY?.organizationNature ===
    'SUBSIDIARY';
  const isParent =
    tenantInfo.additionalSettings?.MULTI_COMPANY?.parentOrganization;
  const isParentExits = isParent ? true : false;
  return isSubsidiary && isParentExits;
}
