import { useEffect, useState } from 'react';
import {
  DKButton,
  DKInput,
  DKLabel,
  DKDataGrid,
  DKSpinner,
  showAlert,
  DKIcons,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKLine
} from 'deskera-ui-library';

import Utility, { deepClone } from '../../../Utility/Utility';
import { useAppDispatch } from '../../../Redux/Hooks';
import { showAddColumnPopup } from '../QualityCheck/AddColumnPopup';
import { QC_PARAMETERS_COL_CONF } from '../Constants/MRPColumnConfigs';
import QualityCheckService from '../../../Services/MRP/QualityCheck';
import { INPUT_TITLE_STYLE } from '../Constants/UIHelper';
import { QCProductPopup } from './ProductPopup';
import useConfirm from '../../../Hooks/useConfirm';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import {
  setSelectedSetting,
  setShowSettings
} from '../../../Redux/Slices/CommonDataSlice';
import { NEW_SETTINGS_SECTION } from '../../../Constants/Constant';

const AddQCTemplate = (props: any) => {
  //constants
  const isEditMode = !Utility.isEmpty(props.editableQCTemplate);
  const [parameters, setParameters] = useState<any>([]);
  //states
  const [formState, setFormState] = useState<any>({
    name: '',
    description: ''
  });
  const [updating, setUpdating] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const [products, setProducts] = useState([]);
  const [showSubstituteProductView, setShowSubstituteProductView] =
    useState(false);
  const { confirm } = useConfirm();
  //selectors
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEditMode) {
      fetchParameters();
    }
    const copyOfFormState = deepClone(formState);
    copyOfFormState.id = props.editableQCTemplate?.id;
    copyOfFormState.name = props.editableQCTemplate?.name;
    setFormState({ ...copyOfFormState });
    setParameters(props.editableQCTemplate?.parameterDtos || []);
    setProducts(props.editableQCTemplate?.productDetails || []);
  }, []);

  //methods
  const validateForm = (): boolean => {
    return !Utility.isEmpty(formState?.name?.trim());
  };
  const getPayload = () => {
    if (parameters.length > 0) {
      parameters.map((parameter: any) => {
        delete parameter?.id;
        parameter.fieldType = parameter.fieldType
          .toUpperCase()
          .replace('-', '_');
        if (parameter.attributes?.length > 0) {
          delete parameter?.id;
          parameter.attributes.map((attribute: any) => {
            delete attribute?.id;
          });
          return parameter;
        }
      });
    }
    const payload = { ...formState };
    payload.parameterDtos = parameters;
    payload.productDetails = products;
    return payload;
  };

  const getColor = (parameters: any[]) => {
    let colorParameters: any[] = [];
    parameters?.forEach((parameter: any) => {
      if (parameter.attributes?.length > 0) {
        let colorAttribute: any[] = [];
        parameter.attributes.forEach((attribute: any) => {
          colorAttribute.push({
            ...attribute,
            color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
          });
        });
        parameter.attributes = colorAttribute;
      }
      colorParameters.push(parameter);
    });
    return colorParameters;
  };

  const fetchParameters = () => {
    QualityCheckService.getQualityCheckByIds([props.editableQCTemplate.id])
      .then((res: any) => {
        let templateData = res?.[0];
        const copyOfFormState = deepClone(formState);
        copyOfFormState.id = templateData?.id;
        copyOfFormState.name = templateData?.name;
        let parameters = getColor(templateData?.parameterDtos);
        setFormState({ ...copyOfFormState });
        setParameters(parameters);
        setProducts(templateData?.productDetails || []);
        setUpdating(false);
      })
      .catch((err: any) => {
        showError(true);
        setUpdating(false);
      });
  };

  const onSave = () => {
    setCanValidate(true);
    if (!validateForm()) {
      return;
    }
    if (parameters.length === 0) {
      customErrorAlert('At least add one qc parameter');
      return;
    }
    setUpdating(true);
    QualityCheckService.addQualityCheck(getPayload())
      .then((res: any) => {
        setUpdating(false);
        if (res) {
          let qcTemplateSuccesMessage = `QC template is added successfully${
            props?.configureQCTemplate
              ? `, please go through the QC Templates.`
              : `.`
          }`;
          let buttons = [
            {
              title: 'Ok',
              className: `${
                props?.configureQCTemplate ? `bg-gray2` : `bg-blue text-white`
              } border-m`,
              onClick: () => {}
            }
          ];
          if (props?.configureQCTemplate) {
            buttons.push({
              title: 'Go to QC Templates',
              className: 'bg-blue text-white ml-r',
              onClick: () => {
                dispatch(setSelectedSetting(NEW_SETTINGS_SECTION.QC_TEMPLATE));
                dispatch(setShowSettings(true)); // Navigate to tab remaining
              }
            });
          }
          showAlert('Success!', qcTemplateSuccesMessage, buttons);
          props.onSuccess();
        }
      })
      .catch((err: any) => {
        customErrorAlert(err?.message);
        setUpdating(false);
      });
  };

  const onEditSave = () => {
    setCanValidate(true);
    const validated = validateForm();
    if (!validated) {
      return;
    }
    if (parameters.length === 0) {
      customErrorAlert('At least add one qc parameter');
      return;
    }
    setUpdating(true);
    QualityCheckService.updateQualityCheck(
      getPayload(),
      props.editableQCTemplate.id
    )
      .then((res: any) => {
        setUpdating(false);
        props.onSuccess();
      })
      .catch((err: any) => {
        showError();
        setUpdating(false);
      });
  };

  const customErrorAlert = (errorMessage: string = '') => {
    showAlert('Error', errorMessage);
  };

  const showError = (fetch = false) => {
    showAlert(
      'Error Occurred',
      fetch
        ? 'Unable to fetch quality check please try again!'
        : 'Unable to save quality check please try again!'
    );
  };
  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row width-auto">
          <DKLabel
            text={`${!isEditMode ? 'Add' : 'Edit'} Template`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !updating && props.onCancel();
            }}
          />
          <div
            className={`row border-radius-m ${
              updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={isEditMode ? 'Update' : 'Save'}
              onClick={() => {
                if (!updating) {
                  if (!isEditMode) {
                    onSave();
                  } else {
                    onEditSave();
                  }
                }
              }}
            />
            {updating && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getAddParameterButton = (className: string = '') => {
    return (
      <DKButton
        title={'+ Add QC parameter'}
        className={`text-blue fw-m text-underline ${className}`}
        style={{ padding: 0 }}
        onClick={() => {
          showAddColumnPopup(
            { parameters },
            (data: any) => {
              let parameterList: any = [...parameters];
              parameterList.push(data);
              setParameters(parameterList);
            },
            () => {}
          );
        }}
      />
    );
  };

  const onSubSelect = (index: number) => {
    // const copyOfProducts = deepClone(products);
    // copyOfProducts[index]['selected'] = !copyOfProducts[index]['selected'];
    // setProducts(copyOfProducts);
  };

  const getGrid = (title: string, rows: any[], columns: any[]) => {
    return (
      <DKDataGrid
        tableName={''}
        filterTableName={title}
        needColumnIcons={false}
        allowRowEdit={false}
        allowColumnEdit={false}
        allowSort={false}
        allowRowAdd={false}
        allowColumnAdd={false}
        allowColumnDelete={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowSearch={false}
        // onSearch={onSearch}
        headerButtons={[]}
        allowDataExport={false}
        columns={columns}
        rows={rows}
        currentPage={1}
        totalPageCount={1}
        onRowUpdate={(data: any) => {}}
        allowBulkOperation={false}
        onRowSelect={(data: any) => {}}
        width={() => {
          let mainWidth = document.getElementById('mainDiv')?.offsetWidth || 30;
          return mainWidth - 30;
        }}
        needTrailingColumn={true}
        onAllRowSelect={(data: any) => {}}
      />
    );
  };

  const editParameter = (data: any) => {
    showAddColumnPopup(
      { data, parameters },
      (data: any) => {
        let parameterList: any = [...parameters];
        let index = parameterList.findIndex(
          (parameter: any) => parameter?.id === data.id
        );
        parameterList[index] = data;
        setParameters(parameterList);
      },
      () => {}
    );
  };

  const removeParameter = async (data: any) => {
    if (data) {
      const isConfirmed = await confirm(
        'Deleting this parameter will delete it permanently',
        'Delete Parameter?'
      );

      if (isConfirmed) {
        let parameterList = [...parameters];
        let index = parameterList.findIndex(
          (parameter: any) => parameter?.id === data.id
        );
        parameterList.splice(index, 1);
        setParameters(parameterList);
      }
    }
  };

  const getComponentRow = () => {
    return (
      parameters?.map((item: any, index: number) => {
        return {
          ...item,
          rowButtons: [
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_edit,
              onClick: () => {
                editParameter(item);
              }
            },
            {
              title: '',
              className: 'p-0',
              icon: DKIcons.ic_delete,
              onClick: () => {
                removeParameter(item);
              }
            }
          ]
        };
      }) || []
    );
  };

  const getColConfig = () => {
    return QC_PARAMETERS_COL_CONF.map((column) => {
      if (column.key === 'attributes') {
        column.renderer = (data: any) => optionRender(data.rowData);
      }
      return column;
    });
  };

  const optionRender = (data: any) => {
    return (
      <div className="row width-auto flex-wrap" style={{ gap: 4 }}>
        {data?.attributes?.map((option: any) => {
          return (
            <div className={`p-h-s border-radius-m p-v-xs ${option.color}`}>
              {option.value}
            </div>
          );
        })}
      </div>
    );
  };

  const getFormFields = () => {
    return (
      <DKInput
        title="Name"
        type={INPUT_TYPE.TEXT}
        required={true}
        className="mb-m"
        canValidate={canValidate}
        titleStyle={INPUT_TITLE_STYLE}
        placeholder="Template Name"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={formState?.name}
        onChange={(text: any) => {
          const updatedFormState = { ...formState };
          updatedFormState.name = text;
          setFormState({ ...updatedFormState });
        }}
      />
    );
  };

  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: '85%',
            maxWidth: 500,
            maxHeight: '90%',
            minHeight: 400,
            padding: 0,
            overflow: 'visible'
          }}
        >
          {getHeader()}
          {
            <div className="column parent-size p-l overflow-scroll hide-scroll-bar justify-content-between gap-2">
              {getFormFields()}
              <div className="column qc-template-container parent-size">
                <DKLabel text="Parameters" className="fw-m" />
                {parameters.length > 0 &&
                  getGrid('Parameters', getComponentRow(), getColConfig())}
                {parameters.length === 0 && (
                  <div
                    className="mt-8 bg-gray1 border border-radius-s row flex-wrap p-r mt-s mb-m overflow-scroll hide-scroll-bar paren-size position-relative"
                    style={{ gap: 5, maxHeight: 400 }}
                  >
                    {
                      <div className="column parent-size align-items-center">
                        <DKLabel className="text-gray" text="No Parameter" />
                        <DKLabel
                          className="mt-xs"
                          text="Please add parameter to template"
                        />
                        <DKButton
                          className="mt-xs text-blue fw-m text-underline"
                          title={'+ Add QC parameter'}
                          style={{ padding: 0 }}
                          onClick={() => {
                            showAddColumnPopup(
                              { parameters },
                              (data: any) => {
                                let parameterList: any = [...parameters];
                                parameterList.push(data);
                                setParameters(parameterList);
                              },
                              () => {}
                            );
                          }}
                        />
                      </div>
                    }
                  </div>
                )}
                {parameters.length > 0 && getAddParameterButton('mt-xs')}
              </div>
              <DKLine style={{ height: 2 }} />
              <div className="column parent-size">
                <DKLabel text="Assign To Materials" className="fw-m" />
                <div
                  className="mt-8 bg-gray1 border-radius-s border row flex-wrap p-r mt-s overflow-scroll hide-scroll-bar paren-size position-relative"
                  style={{ gap: 5, maxHeight: 400 }}
                >
                  {products?.map((product: any, index: number) => (
                    <div
                      className={`row bg-white border-radius-s width-auto p-xs bg-white border-m cursor-hand ${
                        product.selected &&
                        'bg-chip-blue border-blue text-app-color'
                      }`}
                    >
                      <DKLabel text={product.productName} className="mr-s" />
                    </div>
                  ))}
                  {Utility.isEmpty(products) && (
                    <div className="column parent-size align-items-center">
                      <DKLabel
                        className="text-gray"
                        text="No Assigned Materials"
                      />
                      <DKLabel
                        className="mt-xs"
                        text="Please assign materials to template"
                      />
                      <DKButton
                        className="mt-xs text-blue fw-m text-underline"
                        style={{
                          padding: 0
                        }}
                        title="+ Assign Material"
                        onClick={() => {
                          setShowSubstituteProductView(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                {!Utility.isEmpty(products) && (
                  <DKButton
                    className="mt-xs text-blue fw-m text-underline"
                    style={{
                      padding: 0
                    }}
                    title="+ Assign Material"
                    onClick={() => {
                      setShowSubstituteProductView(true);
                    }}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </div>
      {showSubstituteProductView && (
        <QCProductPopup
          title="Select Materials"
          selectedProductsIds={products}
          onSave={(selectedProducts: any) => {
            setProducts(selectedProducts);
            setShowSubstituteProductView(false);
          }}
          onClose={() => {
            setShowSubstituteProductView(false);
          }}
        />
      )}
    </>
  );
};

export default AddQCTemplate;
