import React from 'react';
import ReactDOM from 'react-dom';

import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKCheckMark,
  DKLabel,
  DKButton,
  isEmpty,
  DKIcons,
  DKIcon,
  getDataTypeIcon,
  TOAST_TYPE,
  showToast
} from 'deskera-ui-library';
import Utility, {
  getCapitalized,
  getRandomNumber
} from '../../../Utility/Utility';
import Popup from '../../Subscription/Popup';
import ControlledInput from '../../../SharedComponents/ControlledInput';
import { CHAR_CODES } from '../../../Constants/Constant';

export interface IAddColumnPopupProps {
  popupId?: string;
  onSave?: (response?: any) => void;
  onClose?: () => void;
  data?: any;
  parameters?: any;
}
export interface IAddColumnPopupState {
  formData: any;
  optionFieldText: string;
  submitted: boolean;
}
class AddColumnPopup extends React.Component<
  IAddColumnPopupProps,
  IAddColumnPopupState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      formData: !Utility.isEmpty(this.props?.data)
        ? this.editFormData(this.props?.data)
        : {
            label: '',
            fieldType: INPUT_TYPE.TEXT,
            mandatory: false,
            attributes: [],
            defaultValue: '',
            description: '',
            maxLength: 25
          },
      optionFieldText: '',
      submitted: false
    };
  }

  editFormData = (data: any) => {
    data.fieldType = data.fieldType?.toLowerCase()?.replace('_', '-');
    return data;
  };

  onOptionChange = (value: any) => {
    this.setState({
      optionFieldText: value
    });
  };
  onOptionKeyPress = (e: any) => {
    if (e.charCode === CHAR_CODES.ENTER) {
      // on enter pressed
      this.onOptionAdd();
    }
  };
  onOptionAdd = () => {
    if (isEmpty(this.state.optionFieldText)) return;
    let options = [...this.state.formData.attributes];
    let { optionFieldText } = this.state;
    const newID = parseInt(
      getRandomNumber(1000) + '' + this.state.formData.attributes.length
    );
    options.push({
      id: newID,
      value: optionFieldText,
      color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`
    });
    this.onFormValueChange('attributes', options);
    this.setState({
      optionFieldText: ''
    });
  };
  onFormValueChange = (key: any, value: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  };
  onSave = () => {
    this.setState({
      submitted: true
    });
    if (this.state.formData.label.trim().length === 0) return;
    if (
      (this.state.formData.fieldType === INPUT_TYPE.DROPDOWN ||
        this.state.formData.fieldType === INPUT_TYPE.MULTI_SELECT) &&
      this.state.formData.attributes.length === 0
    ) {
      showToast('Please add at least one option', TOAST_TYPE.FAILURE);
      return;
    }

    let fieldType = this.state.formData.fieldType
      .toUpperCase()
      ?.replace('-', '_');
    let checkUnique = this.props.parameters?.find(
      (param: any) =>
        param.fieldType === fieldType &&
        param.label === this.state.formData.label
    );

    if (!Utility.isEmpty(checkUnique)) {
      showToast('Same parameter already exists.', TOAST_TYPE.FAILURE);
      return;
    }

    /**
     * @description - only core API table will be handled here rest will be in parent component
     */
    const newID = parseInt(
      getRandomNumber(1000) + '' + (this.state.formData.attributes?.length || 0)
    );
    this.props.onSave?.({
      ...this.state.formData,
      id: this.state.formData.id || newID,
      fieldType: fieldType
    });
    this.removePopUp();
  };
  onCancel = () => {
    this.removePopUp();
  };
  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById?.(this.props.popupId || '')!
      );
    document.getElementById(this.props.popupId || '')?.remove();
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    return (
      <Popup>
        {this.getHeader()}
        {this.getForm()}
      </Popup>
    );
  }
  getHeader = () => {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <div className="row fs-xl fw-h">New Parameter</div>
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            onClick={this.onSave}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={this.onCancel}
          />
        </div>
      </div>
    );
  };
  onOptionRemove = (index: any) => {
    let options = [...this.state.formData.attributes];
    options.splice(index, 1);
    this.onFormValueChange('attributes', options);
  };
  getForm = () => {
    return (
      <>
        <div className="row mt-l">
          <ControlledInput
            required
            invalid={
              this.state.submitted &&
              this.state.formData.label.trim().length === 0
            }
            name="Name"
            type={INPUT_TYPE.TEXT}
            value={this.state.formData.label}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(e: any) =>
              this.onFormValueChange('label', e.target.value)
            }
          />
        </div>
        <div className="row mt-l">{this.getFieldTypeSelection()}</div>
        {(this.state.formData.fieldType === INPUT_TYPE.DROPDOWN ||
          this.state.formData.fieldType === INPUT_TYPE.MULTI_SELECT) && (
          <div className="row mt-l">{this.getAddOptionField()}</div>
        )}
        {this.state.formData.attributes?.length > 0 && this.getOptionList()}
        <div className={`row align-items-center`}>
          <DKCheckMark
            className="mt-l mb-m"
            isSelected={this.state.formData.mandatory}
            onClick={(value: any) =>
              this.onFormValueChange(
                'mandatory',
                !this.state.formData.mandatory
              )
            }
          />
          <DKLabel text="Is mandatory?" className="ml-s mt-s" />
        </div>
      </>
    );
  };
  getFieldTypeSelection = () => {
    const INPUT_TYPE_ARR = [
      INPUT_TYPE.TEXT,
      INPUT_TYPE.NUMBER,
      INPUT_TYPE.DROPDOWN,
      INPUT_TYPE.MULTI_SELECT
    ];
    const UPDATED_ARR = [...INPUT_TYPE_ARR];
    return (
      <div className="parent-width">
        <DKLabel text={'Select field type'} />
        <div className={`column-type-wrapper`}>
          {UPDATED_ARR.map((item) => {
            return (
              <div
                className={`p-s bg-white mr-r mt-r border-radius-s border-m d-flex align-items-center cursor-hand
                            ${
                              this.state.formData.fieldType === item
                                ? 'border-blue text-blue fw-m'
                                : ''
                            }`}
                onClick={() => {
                  this.onFormValueChange('fieldType', item);
                  setTimeout(() => this.onFormValueChange('attributes', []), 0);
                }}
              >
                <DKIcon
                  src={getDataTypeIcon(item)}
                  className="ic-xs cursor-hand"
                />
                <span className="ml-s">{getCapitalized(item)}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  getAddOptionField = () => {
    return (
      <div className="row align-items-end" style={{ width: '80%' }}>
        <ControlledInput
          required
          name="Options"
          type={INPUT_TYPE.TEXT}
          value={this.state.optionFieldText}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(e) => this.onOptionChange(e.target.value)}
          onKeyPress={(e) => this.onOptionKeyPress(e)}
        />
        <DKButton
          title="Add"
          className="border-m ml-r bg-button text-white"
          onClick={this.onOptionAdd}
        />
      </div>
    );
  };
  getOptionList = () => {
    return (
      <div className="row pt-s flex-wrap">
        {this.state.formData.attributes.map((option: any, index: any) => {
          return (
            <div
              className={`p-s border-radius-s mr-s mt-s d-flex align-items-center ${option.color}`}
              id={`${option.id}`}
            >
              <span>{option.value}</span>
              <DKIcon
                src={DKIcons.ic_close}
                className="ic-s ml-s cursor-hand"
                onClick={() => this.onOptionRemove(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };
}
export const showAddColumnPopup = (
  config?: { data?: any; parameters?: any },
  onSave?: (response?: any) => void,
  onClose?: () => void
) => {
  const id = `add-new-col-popup-${new Date().getTime()}`;
  let div = document.createElement('div');
  div.className = 'app-font';
  div.setAttribute('id', id);
  ReactDOM.render(
    <AddColumnPopup
      data={config?.data}
      parameters={config?.parameters || []}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};
export default AddColumnPopup;
