import ApiConstants from '../Constants/ApiConstants';
import { GSTSubmissionCheckPayload, IRPPayload } from '../Models/Interfaces';
import http from './Interceptor';

class EInvoiceService {
  static optDetails: any;
  static tokenDetails: any;

  static connectIndiaEInvoice(payload: IRPPayload) {
    const localStorage: Storage = window.localStorage;
    return http
      .post(ApiConstants.URL.EINVOICE.CONNECT_E_INVOICE, payload)
      .then((data: any) => {
        if (data['status'] === '1') {
          localStorage.setItem('e-Invoice_data', JSON.stringify(data));
          localStorage.setItem('e-Invoice_tokenExpiry', data['tokenExp']);
          localStorage.setItem('e-Invoice_authtoken', data['authtoken']);
          localStorage.setItem('e-Invoice_session_id', data['session_id']);
        }
        return Promise.resolve(data);
      })
      .catch((err) => {
        localStorage.removeItem('e-Invoice_data');
        localStorage.removeItem('e-Invoice_tokenExpiry');
        localStorage.removeItem('e-Invoice_authtoken');
        localStorage.removeItem('e-Invoice_session_id');
        return Promise.reject(err);
      });
  }

  static fetchIndidEInvoiceUserName() {
    const localStorage: Storage = window.localStorage;
    return http
      .get(ApiConstants.URL.EINVOICE.FETCH_USERNAME_E_INVOICE)
      .then((data: any) => {
        localStorage.setItem('e-Invoice_data', JSON.stringify(data));
        return Promise.resolve(data);
      })
      .catch((err) => {
        localStorage.removeItem('e-Invoice_data');
        return Promise.reject(err);
      });
  }
  static getOTP(payload: GSTSubmissionCheckPayload) {
    return http
      .post(ApiConstants.URL.INDIA_GST_SUBMISSION.GET_OTP, payload)
      .then((data: any) => {
        EInvoiceService.optDetails = { ...data };
        let jsonData = JSON.stringify(data);
        localStorage.setItem('gstr1_tokenData', jsonData);
        return Promise.resolve(data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getAuthToken(params: any) {
    return http
      .post(ApiConstants.URL.INDIA_GST_SUBMISSION.AUTH_TOKEN, params)
      .then((data: any) => {
        EInvoiceService.tokenDetails = { ...data };
        let jsonData = JSON.stringify(data);
        localStorage.setItem('gstr1_authData', jsonData);
        return data;
      });
  }

  static checkAuthToken(params: any) {
    return http
      .post(ApiConstants.URL.INDIA_GST_SUBMISSION.CONNECT, params)
      .then((data: any) => {
        EInvoiceService.tokenDetails = { ...data };
        let jsonData = JSON.stringify(data);
        localStorage.setItem('gstr1_authData', jsonData);
        return data;
      });
  }

  static saveGstr1(params: any) {
    return http.post(ApiConstants.URL.INDIA_GST_SUBMISSION.SAVE_GSTR1, params);
  }

  static checkGstr1(params: any) {
    return http.post(
      ApiConstants.URL.INDIA_GST_SUBMISSION.CHECK_STATUS,
      params
    );
  }
  static resetGstr1(params: any) {
    return http.post(ApiConstants.URL.INDIA_GST_SUBMISSION.RESET_GSTRI, params);
  }

  static fileGSTR1(id: number) {
    return http
      .put(`${ApiConstants.URL.INDIA_GST_SUBMISSION.FILE_GSTR(id)}`, {
        status: 'FILE_SUCCESS'
      })
      .catch((err: any) => {
        console.error('Error while trying to update GSTN: ', err);
        return Promise.reject(err);
      });
  }

  static generateEInvoice(params: any) {
    return http.post(ApiConstants.URL.EINVOICE.GENERATE_E_INVOICE, params);
  }

  static bulkGenerateEInvoice(params: any) {
    return http.post(
      ApiConstants.URL.EINVOICE.BULK_E_INVOICE_GENERATION,
      params
    );
  }

  static cancelEInvoice(params: any) {
    return http.post(ApiConstants.URL.EINVOICE.CANCEL_E_INVOICE, params);
  }

  static bulkCancelEInvoice(params: any) {
    return http.post(ApiConstants.URL.EINVOICE.BULK_E_INVOICE_CANCEL, params);
  }

  static sendEInvoice(codes: string[]) {
    return http.post(ApiConstants.URL.EINVOICE.OUTBOUND, {
      invoiceIds: codes
    });
  }
  static sendEPurchaseInvoice(codes: string[], tenantId:string) {
    return http.post(ApiConstants.URL.EINVOICE.OUTBOUND_PURCHASE_ORDER, {
      tenantId,
      purchaseOrderIds: codes,  

    });
  }

  static getEInvoiceAuthToken(request: any) {
    return http
      .post(ApiConstants.URL.EINVOICE.CONNECT_E_INVOICE, request)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getGSTEFilingAuthToken(request: any) {
    return http
      .post(ApiConstants.URL.INDIA_GST_SUBMISSION.AUTH_TOKEN, request)
      .then(
        (res: any) => {
          let jsonData = JSON.stringify(res);
          localStorage.setItem('gstr1_authData', jsonData);
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}
export default EInvoiceService;
