import { DKButton, DKLabel } from 'deskera-ui-library';

interface AdvancedSettingsConfirmationPopupProps {
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  summary?: string;
}

export default function AdvancedSettingsConfirmationPopup(
  props: AdvancedSettingsConfirmationPopupProps
) {
  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          maxWidth: 520,
          width: '90%',
          maxHeight: '95%',
          padding: 0,
          position: 'fixed'
        }}
      >
        <div
          className="row justify-content-between p-h-r p-v-s bg-gray1"
          style={{ height: 60 }}
        >
          <div className="row width-auto">
            <DKLabel text={props.title} className="fw-m fs-l" />
          </div>
          <div className="row width-auto"></div>
        </div>
        {/* message */}
        <DKLabel text={props.summary} className="p-4" />
        {/* footer */}
        <div
          className="row justify-content-between border-t-2 px-3"
          style={{ height: 60 }}
        >
          <div className="row width-auto"></div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="mr-r bg-gray-100"
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
              }}
            />
            <DKButton
              title={'Confirm'}
              className="text-white bg-button"
              onClick={() => {
                if (props.onConfirm) {
                  props.onConfirm();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
