import React from 'react';

type Props = {
  color?: string;
  label: string | number;
  onClick?: () => void;
};
const Chip = (props: Props) => {
  return (
    <div
      className={`row fs-m border-radius-s ${
        props.color ?? 'bg-chip-green text-green'
      }`}
      style={{ width: 'auto', padding: '2px 6px' }}
      onClick={props.onClick}
    >
      <div className="fs-r text-align-left display-block">{props.label}</div>
    </div>
  );
};

export default Chip;
