import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import GSTR2BService from '../../Services/GSTR2B';

const initialState: any = {};

export const fetchMatchingData = createAsyncThunk(
  'fetchMatching',
  async (query: any) => {
    const response = await GSTR2BService.getMatchingSummary(query);
    return response;
  }
);

export const Gstr2bSlice = createSlice({
  name: 'gstr2b',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatchingData.pending, (state) => {
        state.matchingData = API_STATUS.LOADING;
      })
      .addCase(fetchMatchingData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.matchingData = action.payload;
      });
  }
});
export const {} = Gstr2bSlice.actions;

export const gstr2bMatchingData = (state: any) => state.gstr2b.matchingData;

export default Gstr2bSlice.reducer;
