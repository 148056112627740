import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { FONT_SIZE, EditorInfoType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate18 extends Component {
    arabicHeaderWidth = '84%'

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {right: getVW(10)})}
                    {this.getHeaderSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(60, this.state.data.printScale))}
                    {ComponentManager.addVerticalSpace(Utility.getImageSize(EditorInfoType.logo))}
                    {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor, height: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)), width: '100%' })}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {this.getAddressSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(20)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor, height: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)), width: '100%' })}
                    {ComponentManager.addVerticalSpace(1)}
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {this.state.data.showSignature && ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                    {/* {ComponentManager.addVerticalSpace(190)} */}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                    {this.getTermsNConditionsView()}
                    {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor, height: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)), width: '100%', bottom: 0, position: 'absolute' })}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.bottom) && ComponentManager.addVerticalSpace(40)}
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (<div className='RowDiv' style={{
            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
            width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
        }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxxLarge }, this.state.data.printScale)
                }}>
                {getCapitalized(getLocalisedText(this.state.data.documentType))}
            </text>
        </div>
        )
    }

    /////// Address Section ///////////
    getAddressSection() {
        return (<div className="RowDiv">
            <div className="ColumnDiv" style={{ width: '50%' }}>
                {this.state.data.showCompanyName &&
                    <div className='RowDiv'
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                paddingBottom: getVW(5),
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            {getLocalisedText(Utility.getCompanyName(this.state.data))}
                            </text>
                    </div>
                }
                {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? '100%' : '75%',
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                </div>}
                {ComponentManager.addVerticalSpace(20)}
                {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                {ComponentManager.addVerticalSpace(20)}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {(this.state.data.showNotes && this.state.data.notes && this.state.data.notes.length > 0) && this.getAdditionalInfo()}
            </div>
            <div className="RowReverseDiv" style={{ width: '50%' }}>
                <div className="ColumnDiv" style={{ width: '60%', backgroundColor: '#fafafa', borderRadius: 2 }}>
                    <div style={{
                        padding: '3%',
                        width: '95%'
                    }}>
                        {this.getDocumentDetailsSection()}
                    </div>
                </div>
            </div>
            {ComponentManager.addVerticalSpace(60)}
        </div>)
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label + ' :',
                value,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getBillingAddress() {
        return <>
            <div className='RowDiv'
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
                </text>
            </div>

            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: Utility.getIsArabicLang() ? '100%' : '75%',
                    overflow: 'hidden',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }}
                dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getClientBillToAddress(this.state.data))}} >
            </div>
        </>;
    }

    getAdditionalInfo() {
        return (
            <div
                id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.NOTES}
                className="ColumnDiv"
                style={{
                    width: '100%',
                    alignItems: Utility.getIsArabicLang() ? 'flex-end': 'flex-start'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        paddingBottom: getVW(5),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes), headerTitle: true }, this.state.data.printScale)
                    }}>
                    {Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('additional_information'))}
                </text>
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes) }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.data.notes)}
                </text>
            </div>
        )
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '45%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return (<div className="RowDiv">
            {this.getEditableDocumentTable()}
        </div>)
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "#000000";
        style.footerTextColor = this.state.data.themeColor;
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        return style;
    }

    getTermsNConditionsView() {
        return (
            <div className="ColumnDiv" style={{ marginBottom: getVW(Utility.convertToPrintScale(40, this.state.data.printScale)), bottom: getVW(Utility.convertToPrintScale(40, this.state.data.printScale)), width: '100%', justifyContent: 'center', alignItems: "flex-start" }}>
                {ComponentManager.addDivider({ backgroundColor: "#c5c5c5", height: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)), width: '100%', bottom: 100 })}
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS}>
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                </div>
            </div>
        )
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }

    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '100%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(3, data.printScale)), width: '100%' })}
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}                        
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const invoiceDueSummaryData = data?.invoiceDueSummaryData ?? []

        if (invoiceDueSummaryData.length > 0) {
            return (
                <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                    <div style={{ width: "65%" }}>
                        {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(3, data.printScale)), width: '100%' })}
                        {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                    </div>
                </div>
            );
        }
    }
}
