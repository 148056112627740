import BaseAPIManager from "./BaseAPIManager";
import ApiConstants from "./ApiConstants";
export default class PrintInfoRepository extends BaseAPIManager {
    getPrintInfoDetails(printInfo, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.GET_PRINT_INFO + '/' + printInfo.id

        if(printInfo.tId !== undefined) {
            urlString  = urlString + '?tid=' + printInfo.tId
        }

        if (printInfo.id?.includes(',')) {
            urlString = ApiConstants.URL.BASE + ApiConstants.URL.GET_PRINT_INFO + '?uids=' + printInfo.id
            if (printInfo.tId !== undefined) {
                urlString = urlString + '&tid=' + printInfo.tId
            }
        }
        this.get(urlString,(response) => success(response), (error) => failure(error))
    }
}
