import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { removeLoader } from 'deskera-ui-library';
import { COUNTRY_CODES } from '../Constants/Constant';
import ContactService from './Contact';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';

export interface AuditLogAPIConfig {
  Limit?: number;
  Page?: number;
  Query?: string;
  search?: string;
  User?: string;
  Sort?: any;
  SortDir?: any;
}

const defaultConfig: AuditLogAPIConfig = {
  Limit: 25,
  Page: 0,
  Query: '',
  search: '',
  User: '',
  SortDir: 'DESC',
  Sort: ''
};

class AuditLogService {
  static apiConfig: AuditLogAPIConfig = defaultConfig;

  static getAuditLog() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&query=${this.apiConfig.Query}&search=${this.apiConfig.search}&user=${this.apiConfig.User}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }

    const finalEndpoint: string = `/audittrail` + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading audit log@: ', err);
      return Promise.reject(err);
    });
  }

  static getProductByCode(productCode: string) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT
    );
    return http
      .get(
        `/products${
          !Utility.isEmptyValue(complianceCountryCode)
            ? '/' + complianceCountryCode
            : ''
        }/get-deleted/${productCode}`
      )
      .catch((err: any) => {
        console.error('Error getting product: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getContactByCode(contactCode: string, countryCode: string) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      ContactService.moduleName
    );
    return http
      .get(
        `/contacts${
          !Utility.isEmptyValue(complianceCountryCode)
            ? '/' + complianceCountryCode
            : ''
        }/get-deleted/${contactCode}`
      )
      .catch((err: any) => {
        console.error('Error getting contact: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getAccountByCode(accountCode: string) {
    return http.get(`/account/get-deleted/${accountCode}`).catch((err: any) => {
      console.error('Error getting account: ', err);
      removeLoader();
      return Promise.reject(err);
    });
  }

  static getWarehouseByCode(warehouseCode: string) {
    return http
      .get(`/products/inventories/warehouses/get-deleted/${warehouseCode}`)
      .catch((err: any) => {
        console.error('Error getting warehouseCode: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getPriceListByCode(priceListCode: string) {
    return http
      .get(`/products/price-list/get-deleted/${priceListCode}`)
      .catch((err: any) => {
        console.error('Error getting price list: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getTaxByCode(taxCode: any) {
    return http.get(`/taxes/get-deleted/${taxCode}`).catch((err: any) => {
      console.error('Error getting tax code: ', err);
      removeLoader();
      return Promise.reject(err);
    });
  }

  static getPurchaseOrderByCode(purchaseOrderCode: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
    );
    return http
      .get(
        `/orders/purchase${
          !Utility.isEmptyValue(complianceCountryCode)
            ? '' + complianceCountryCode
            : ''
        }/get-deleted/${purchaseOrderCode}`
      )
      .catch((err: any) => {
        console.error('Error getting purchase Order Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getQuotationByCode(code: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
    );

    return http
      .get(
        `/quotations${
          !Utility.isEmptyValue(complianceCountryCode)
            ? complianceCountryCode
            : ''
        }/get-deleted/${code}`
      )
      .catch((err: any) => {
        console.error('Error getting QUOTATION Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getSalesOrderByCode(salesOrderCode: any, countryCode: string) {
    let URL = `invoices/salesorder/get-deleted/${salesOrderCode}`;
    switch (countryCode) {
      case COUNTRY_CODES.IN:
      case COUNTRY_CODES.MY:
        URL = `invoices/salesorder/${countryCode.toLocaleLowerCase()}/get-deleted/${salesOrderCode}`;
        break;
      default:
        URL = `invoices/salesorder/get-deleted/${salesOrderCode}`;
        break;
    }
    return http.get(URL).catch((err: any) => {
      console.error('Error getting Sales Order: ', err);
      removeLoader();
      return Promise.reject(err);
    });
  }

  static getDebetNoteByCode(taxCode: any) {
    return http.get(`/notes/debit/get-deleted/${taxCode}`).catch((err: any) => {
      console.error('Error getting tax code: ', err);
      removeLoader();
      return Promise.reject(err);
    });
  }

  static getCreaditNoteByCode(taxCode: any) {
    return http
      .get(`/notes/credit/get-deleted/${taxCode}`)
      .catch((err: any) => {
        console.error('Error getting tax code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getSalesInvoiceByCode(code: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http
      .get(
        `/invoices/sales${
          !Utility.isEmptyValue(complianceCountryCode)
            ? complianceCountryCode
            : ''
        }/get-deleted/${code}`
      )
      .catch((err: any) => {
        console.error('Error getting INVOICE Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getPurchaseBillByCode(code: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    return http
      .get(
        `/invoices/purchase${
          !Utility.isEmptyValue(complianceCountryCode)
            ? complianceCountryCode
            : ''
        }/get-deleted/${code}`
      )
      .catch((err: any) => {
        console.error('Error getting Bill Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getMakePaymentByCode(code: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT
    );
    return http
      .get(
        `/payments/make${
          !Utility.isEmptyValue(complianceCountryCode)
            ? complianceCountryCode
            : ''
        }/get-deleted/${code}`
      )
      .catch((err: any) => {
        console.error('Error getting make payment Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getReceivePaymentByCode(code: any) {
    const complianceCountryCode = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT
    );
    return http
      .get(
        `/payments/receive${
          !Utility.isEmptyValue(complianceCountryCode)
            ? complianceCountryCode
            : ''
        }/get-deleted/${code}`
      )
      .catch((err: any) => {
        console.error('Error getting receive payments Code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getGoodsReceiptByCode(taxCode: any) {
    return http
      .get(`/products/goodsreceipt/get-deleted/${taxCode}`)
      .catch((err: any) => {
        console.error('Error getting Goods Receipt code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getFulfillmentByCode(taxCode: any) {
    return http
      .get(`/products/fulfillment/get-deleted/${taxCode}`)
      .catch((err: any) => {
        console.error('Error getting Fulfillment by code: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getAccountOpeningDetails(param: any) {
    return http
      .post('/reports/common/contact-opening-detail', param)
      .catch((err: any) => {
        console.error('Error getting account opening details: ', err);
        removeLoader();
        return Promise.reject(err);
      });
  }
}

export default AuditLogService;
