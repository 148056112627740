import { showToast, TOAST_TYPE } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import { DOC_TYPE } from '../Constants/Constant';
import { ReceiveGoodsPayload } from '../Models/ReceivedGoods';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface ReceivedGoodsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
}

const defaultConfig: ReceivedGoodsAPIConfig = {
  SearchTerm: '',
  Limit: 100,
  Page: 0
};

class ReceivedGoodsService {
  static apiConfig: ReceivedGoodsAPIConfig;

  static saveReceivedGoods(payload: ReceiveGoodsPayload) {
    return http
      .post(`${ApiConstants.URL.RECEIVED_GOODS.SAVE_RECEIVED_GOODS}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getReceivedGoodsRecord(documentCode: any, documentType: any) {
    let endpoint: string =
      ApiConstants.URL.RECEIVED_GOODS.GET_RECEIVE_GOODS_RECORD(
        documentCode,
        documentType
      );

    if (documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      endpoint = ApiConstants.URL.RECEIVED_GOODS.GET_RECEIVE_GOODS_RECORD_JWO(
        documentCode,
        documentType
      );
    }

    return http.get(`${endpoint}`).catch((err: any) => {
      showToast('Unable to get received goods records', TOAST_TYPE.ERROR);
      return Promise.reject(err);
    });
  }

  static getReceivedGoodsRecordFoAsset(documentCode: any, documentType: any) {
    let endpoint: string = `asset/goodsreceipt/documentCode/${documentCode}?documentType=${documentType}`;

    return http.get(`${endpoint}`).catch((err: any) => {
      showToast('Unable to get received goods records', TOAST_TYPE.ERROR);
      return Promise.reject(err);
    });
  }

  static saveBulkReceivedGoods(payload: any) {
    return http2
      .post(
        `${ApiConstants.URL.RECEIVED_GOODS.SAVE_BULK_RECEIVED_GOODS}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getOrderBulkReceivedGoodsDetails(documentCodes: any) {
    return http
      .post(
        `${ApiConstants.URL.RECEIVED_GOODS.GET_ORDER_BULK_RECEIVED_GOODS_DETAILS}`,
        documentCodes
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }
  static getBillBulkReceivedGoodsDetails(documentCodes: any) {
    return http
      .post(
        `${ApiConstants.URL.RECEIVED_GOODS.GET_BILL_BULK_RECEIVED_GOODS_DETAILS}`,
        documentCodes
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static deleteBillReceivedGoods(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.RECEIVED_GOODS.DELETE_BILL_RG(
        data.rgCode,
        data.code,
        data.isBulk
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting received goods: ', err);
      return Promise.reject(err);
    }
  }
  static deletePoReceivedGoods(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.RECEIVED_GOODS.DELETE_ORDER_RG(
        data.rgCode,
        data.code,
        data.isBulk
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting received goods: ', err);
      return Promise.reject(err);
    }
  }

  static deleteJWOReceivedGoods(data: any) {
    const finalEndpoint: string = ApiConstants.URL.RECEIVED_GOODS.DELETE_JWO_RG(
      data.code
    );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting received goods: ', err);
      return Promise.reject(err);
    }
  }
}

export default ReceivedGoodsService;
