import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_STATUS } from "../../Constants/Constant";
import { COLUMN_CODE } from "../../Constants/TableConstants";
import { GridState } from "../../Models/ReduxStore";
import { IColumn } from "../../Models/Table";
import GoodsReceiptApprovalService from "../../Services/GoodsReceiptApprovalService";
import { RootState } from "../Store";


const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  content: [] as any,
  
};

const hideGoodsReceiptApprovalColumnConfig = [
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.VENDOR_CODE,
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.VENDOR_NAME,
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.TOTAL_AMOUNT_GR,
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.SUPPLIER_INVOICE_NO,
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.QC_STATUS,
  COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.MEMO,
];

export const fetchGoodsReceiptsApprovalList = createAsyncThunk('fetchGoodsReceiptsApprovalList', async () => {
  const response = await GoodsReceiptApprovalService.getGoodsReceivedNotesData();
  return response;
});




export const goodsReceiptApprovalSlice = createSlice({
  name: 'Goods_receipt_approval',
  initialState,
  reducers: {
    addGoodsReceiptApprovalColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        const includes = hideGoodsReceiptApprovalColumnConfig.includes(config.columnCode);
        if(config.columnCode === COLUMN_CODE.BOOKS_GOODS_RECEIPT_APPROVAL_LIST.APPROVAL_STATUS) {
          config.width = 250;
        }
        if(!includes) {
          configsToStore.push({
            ...config,
            key: config.columnCode,
            options: config.options ? config.options : []
          });
        }
      });
      state.columnConfig = configsToStore;
    },
    addGoodsReceiptApproval: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers:(builder) => {
    builder
      .addCase(fetchGoodsReceiptsApprovalList.pending, (state) => {
        state.status = API_STATUS.LOADING
      })
      .addCase(fetchGoodsReceiptsApprovalList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
  }
})

export const {addGoodsReceiptApprovalColumnConfig, addGoodsReceiptApproval} = goodsReceiptApprovalSlice.actions;

export const selectGoodsReceiptApprovalData = (state: RootState) =>  state.goodsReceiptApproval;