import { useEffect, useState } from 'react';
import Utility from '../../Utility/Utility';
import { DOCUMENT_MODE, POPUP_CALLBACKS_TYPE } from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import { PurchaseOrder, OrderInitialState } from '../../Models/Order';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { setItemsFromPIQItems } from './PIQHelper';

// TODO: CREATE, EDIT part to be done later for PIQ, this is only for view purpose as of now.
const NewPIQ: React.FC<any> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [order, setPIQ] = useState<PurchaseOrder>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPIQItems(props.populateFormData)
      : { ...OrderInitialState }
  );

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {}
      });
  };

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setPIQ({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  return (
    <NewDocument2
      permissionKeys={PERMISSIONS_BY_MODULE.PURCHASE_INWARD_QUOTATION}
      booksDocument={order}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => {}}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => {}}
      auditLogView={props.auditLogView}
    />
  );
};

export default NewPIQ;
