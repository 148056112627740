import {
  DKButton,
  DKLabel,
  DKCheckMark,
  showAlert,
  DKDataGrid
} from 'deskera-ui-library';
import { MODULE_TYPE } from '../../Constants/Constant';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../Redux/Hooks';
import Utility, { deepClone, sortSerialNumbers } from '../../Utility/Utility';
import { selectSerialTrackingProduct } from '../../Redux/Slices/SerialTrackingSlice';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import {
  getCustomFieldDataForDisplayTable,
  getLineItemCFs
} from '../CustomFieldsHolder/BatchSerialCustomFieldsHelper';

export default function SalesReturnSerial(props: any) {
  const selectSerialTrackingProductData = useAppSelector(
    selectSerialTrackingProduct
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [item, setItem] = useState(props.itemDetails);
  const [moduleName, setModuleName] = useState(props.module);
  const [availableSerialData, setAvailableSerialData] = useState<any[]>([]);
  const [allocatedSerialData, setAllocatedSerialData] = useState<any[]>([]);
  const [allocatedTapped, setAllocatedTapped] = useState(false);
  const [isAllAvailableSerialSelected, setIsAllAvailableSerialSelected] =
    useState(false);
  const [selectedSerial, setSelectedSerial] = useState<any[]>([]);
  const [serialData, setSerialData] = useState<any[]>([]);

  const [totalAllocatedQty, setTotalAllocatedQty] = useState(0);
  const [advanceTrackingData, setAdvanceTrackingData] = useState<any>(
    props.advanceTrakingData
  );
  const [customFields, setCustomFields] = useState<any>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const [cfColumnConfig, setCfColumnConfig] = useState<any>([]);

  let defaultColumns = [
    {
      name: 'Serial Number',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: Utility.isEmpty(cfColumnConfig) ? 710 : 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'serialBatchNumber',
      key: 'serialBatchNumber'
    }
  ];

  useEffect(() => {
    resetAllocation();
  }, []);

  useEffect(() => {
    resetAllocation();
    if (moduleName === MODULE_TYPE.SELL) {
      if (!Utility.isEmpty(selectSerialTrackingProductData)) {
        setLocalWarehouse(selectSerialTrackingProductData);
      }
    }
  }, [selectSerialTrackingProductData]);

  useEffect(() => {
    setCustomFields(batchSerialCFfromStore?.content);
  }, [batchSerialCFfromStore]);

  useEffect(() => {
    if (!Utility.isEmpty(customFields)) {
      const cfFieldsSet: any = new Set(
        customFields
          ?.filter((cfData: any) => cfData.status === 'ACTIVE')
          ?.map((cf: any) => cf.label)
      );
      const cfList: any = customFields?.filter(
        (cfData: any) => cfData.status === 'ACTIVE'
      );

      let copyColumnConfigs = cfColumnConfig;
      if (Utility.isEmpty(copyColumnConfigs)) {
        cfList.forEach((cfield: any, index: number) => {
          copyColumnConfigs.push({
            name: cfield.label,
            type: cfield.fieldType.toLowerCase(),
            index: copyColumnConfigs.length + index + 1,
            options: null,
            required: false,
            width: 180,
            editable: false,
            hidden: false,
            uiVisible: true,
            systemField: false,
            columnCode: cfield.label,
            key: cfield.label,
            textAlign: 'left',
            allowFilter: false
          });
        });
      }
      setCfColumnConfig(copyColumnConfigs);
      let rowData: any[] = [];
      availableSerialData.forEach((row: any) => {
        rowData.push({
          ...row,
          ...getCustomFieldDataForDisplayTable(
            row?.customField ?? [],
            Array.from(cfFieldsSet),
            batchSerialCFfromStore?.content
          )
        });
      });
      setAvailableSerialData(sortSerialNumbers(rowData));
    }
  }, [customFields]);

  useEffect(() => {
    if (moduleName === MODULE_TYPE.SELL) {
      let updateSerial: any[] = [];
      if (Utility.isEmpty(availableSerialData) && !allocatedTapped) {
        const cfFieldsSet: any = new Set(
          batchSerialCFfromStore?.content
            ?.filter((cfData: any) => cfData.status === 'ACTIVE')
            ?.map((cf: any) => cf.label)
        );
        let result = localWarehouse.find(
          (warehouse: any) => warehouse.primary
        )?.advancedTrackingMeta;
        let serialData = advanceTrackingData.filter(
          (ele: any) =>
            ele.productVariantCode === item.productCode &&
            ele.batchSizeReturned === 0
        );
        let rowData: any[] = [];
        serialData.forEach((row: any) => {
          rowData.push({
            ...row,
            ...getCustomFieldDataForDisplayTable(
              row?.customField ?? [],
              Array.from(cfFieldsSet),
              batchSerialCFfromStore?.content
            )
          });
        });
        updateSerial = rowData;

        if (
          props.itemDetails &&
          props.itemDetails.advancedTrackingMetaData &&
          props.itemDetails.advancedTrackingMetaData.length > 0 &&
          serialData &&
          serialData.length > 0
        ) {
          const remainingAvailableSerial = serialData.filter(
            (e: any) =>
              !props.itemDetails.advancedTrackingMetaData.some(
                (a: any) => a.serialBatchNumber === e.serialBatchNumber
              )
          );
          const allocatedAvailableSerial = serialData.filter((e: any) =>
            props.itemDetails.advancedTrackingMetaData.some((a: any) => {
              if (a.serialBatchNumber === e.serialBatchNumber) {
                return {
                  ...a,
                  selected: !a?.selected
                };
              }
            })
          );

          if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
            const newArr1 = allocatedAvailableSerial.map((v: any) => ({
              ...v,
              allocated: true,
              selected: true
            }));
            setAllocatedSerialData(newArr1);
            setSerialData(newArr1);
            setTotalAllocatedQty(newArr1.length);
            setSelectedSerial(newArr1);
            updateSerial = [...remainingAvailableSerial, ...newArr1];
          }
        }

        if (updateSerial && updateSerial.length > 0) {
          let rowData: any[] = [];
          updateSerial.forEach((row: any) => {
            rowData.push({
              ...row,
              ...getCustomFieldDataForDisplayTable(
                row?.customField ?? [],
                Array.from(cfFieldsSet),
                batchSerialCFfromStore?.content
              )
            });
          });
          setAvailableSerialData(sortSerialNumbers(rowData));
        }
      }
    }
  }, [availableSerialData, localWarehouse]);

  const selectAllAvailableSerial = () => {
    const localData = deepClone(availableSerialData);
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        selected: !isAllAvailableSerialSelected
      };
    });
    setIsAllAvailableSerialSelected(!isAllAvailableSerialSelected);
    setSelectedSerial(serialTrackingData);
    setAvailableSerialData(sortSerialNumbers(serialTrackingData));
  };

  const allocateSerialNumber = () => {
    const localData = deepClone(selectedSerial);
    const selectedSerialNumber = localData.filter(
      (option: any) => option.selected
    );
    const getSelectedSerialNumber = [
      ...allocatedSerialData,
      ...selectedSerialNumber
    ];
    const updatedSerialData = selectedSerial.filter(
      (x) =>
        !selectedSerialNumber.filter(
          (y) => y.serialBatchNumber === x.serialBatchNumber
        ).length
    );
    const localSerialData = selectedSerialNumber.filter(
      (item: any) => item.selected
    );
    if (
      item.documentUOMSchemaDefinition &&
      localSerialData.length !== item?.fulfilledQuantity
    ) {
      showAlert('Error', 'Serials should equal to required quantity');
      return;
    }
    setAllocatedTapped(true);
    setIsAllAvailableSerialSelected(false);
    setAllocatedSerialData((prevState: any[]) => getSelectedSerialNumber);
    setAvailableSerialData(sortSerialNumbers(updatedSerialData));

    if (props.onSerialSave) {
      let serialTrackingData: any[] = [];
      if (moduleName === MODULE_TYPE.SELL) {
        serialTrackingData = localSerialData.map((item, i) => {
          return {
            ...item,
            customFields: getLineItemCFs(
              item,
              defaultColumns.concat(cfColumnConfig),
              batchSerialCFfromStore?.content
            )
          };
        });
      }
      props.onSerialSave(serialTrackingData, serialTrackingData.length);
    }
  };

  const getSelectedSerial = (item: any, index: any) => {
    let localData = [...availableSerialData];
    localData[index] = {
      ...localData[index],
      // selected: !localData[index]?.selected
      selected: item.selected
    };
    setSelectedSerial(localData);
    setAvailableSerialData(sortSerialNumbers(localData));
  };

  const resetAllocation = () => {
    setAllocatedTapped(false);
    setIsAllAvailableSerialSelected(false);
    setAllocatedTapped(false);
    setAllocatedSerialData([]);
    setSelectedSerial([]);
    setAvailableSerialData([]);
    setSerialData([]);
  };

  const onSave = () => {
    if (props.onSerialSave) {
      const localSerialData = allocatedSerialData.filter(
        (item: any) => item.selected
      );
      let serialTrackingData: any[] = [];
      if (moduleName === MODULE_TYPE.SELL) {
        serialTrackingData = localSerialData.map((item, i) => {
          return {
            qtyToFulfil: 1,
            serialBatchNumber: item.serialBatchNumber,
            warehouseCode: item.warehouseCode
          };
        });
      }
      props.onSerialSave(serialTrackingData, serialTrackingData.length);
    }
  };

  const getHeader = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text="Return Serial Tracked Products"
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-r"
              onClick={props.onClose}
            />
            <DKButton
              title={'Save'}
              className={
                availableSerialData?.filter((serial: any) => serial.selected)
                  ?.length > 0
                  ? 'bg-button text-white mr-r'
                  : 'bg-gray-300 text-white mr-r'
              }
              disabled={
                availableSerialData?.filter((serial: any) => serial.selected)
                  ?.length === 0
              }
              onClick={() => {
                allocateSerialNumber();
              }}
            />
          </div>
        </div>
      );
    }
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row p-h-r p-v-s mt-5">
          <div className="column w-48 ">
            <DKLabel
              text="Product Name"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.productName} className="fs-r pl-2" />
          </div>
          <div className="column w-40 ">
            <DKLabel
              text="Product Code"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.documentSequenceCode} className="fs-r pl-2" />
          </div>
          {item?.documentUOMSchemaDefinition && (
            <div className="column w-50 ">
              <DKLabel
                text="Returned Quantity in Base UOM"
                className="fs-r pl-2 font-medium text-blue"
              />
              <DKLabel text={item?.fulfilledQuantity} className="fs-r pl-2" />
            </div>
          )}
        </div>
      );
    }
  };

  const getBodySection = () => {
    return (
      <div className="row  p-h-r ml-2">
        <div className="mt-l ">
          <DKDataGrid
            needShadow={false}
            needBorder={true}
            needColumnIcons={false}
            needTrailingColumn={false}
            allowBulkOperation={true}
            allowColumnSort={false}
            allowColumnAdd={false}
            allowColumnEdit={false}
            allowRowEdit={true}
            currentPage={1}
            totalPageCount={1}
            title={''}
            width={750}
            columns={defaultColumns.concat(cfColumnConfig)}
            rows={availableSerialData}
            onRowSelect={(selectedRow: any) => {
              getSelectedSerial(selectedRow?.rowData, selectedRow?.rowIndex);
            }}
            isAllRowSelected={availableSerialData?.every((row) => row.selected)}
            onAllRowSelect={(data: any) => {
              if (data.selected) {
                let localData = availableSerialData?.map((ele: any) => {
                  return { ...ele, selected: data.selected };
                });

                setSelectedSerial(localData);
                setAvailableSerialData(sortSerialNumbers(localData));
              }
            }}
          />
          {availableSerialData && availableSerialData.length === 0 && (
            <div className="position-absolute parent-width top-40 text-align-center fs-r fw-b text-gray">
              No serials to display
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 800,
          width: '100%',
          // height: "85%",
          maxHeight: '95%',
          height: 565,
          padding: 0,
          paddingBottom: 60
        }}
      >
        {getHeader()}
        {getHeaderSection()}
        {moduleName === MODULE_TYPE.SELL && getBodySection()}
      </div>
    </div>
  );
}
