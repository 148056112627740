import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import AccountsService from '../../Services/Accounts';
import FinancialYearClosingService from '../../Services/FinancialYearClosing';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  fySettingsData: null,
  accountNature: {} as any
};

export const fetchFinancialYearClosings = createAsyncThunk(
  'financialYearClosing',
  async () => {
    const response =
      await FinancialYearClosingService.getFinancialYearClosingByPage();
    return response;
  }
);

export const fetchFinancialYearClosingsSettings = createAsyncThunk(
  'financialYearClosingSettings',
  async () => {
    const response = await FinancialYearClosingService.getSettingsFy();
    return response;
  }
);

export const fetchAccountNature = createAsyncThunk(
  'accountNature',
  async () => {
    const response = await AccountsService.fetchAccountNature();
    return response;
  }
);

export const FinancialYearClosingSlice = createSlice({
  name: 'financialYearClosing',
  initialState,
  reducers: {
    addFinancialYearClosingColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          editable: false
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToFinancialYearClosingColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialYearClosings.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchFinancialYearClosings.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchFinancialYearClosingsSettings.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchFinancialYearClosingsSettings.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          const data: any = action.payload;
          state.fySettingsData = {
            id: data.id,
            retainedEarningAccount: data.retainedEarningAccount,
            closingPeriod: data.closingPeriod,
            deleted: data.deleted
          };
        }
      )
      .addCase(fetchAccountNature.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccountNature.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.accountNature = action.payload;
      });
  }
});

export const { addFinancialYearClosingColumnConfig, updateColumnConfig } =
  FinancialYearClosingSlice.actions;
export const selectFinancialYearClosings = (state: RootState) =>
  state.financialYearClosings.data;
export const selectFinancialYearClosingColumnConfig = (state: RootState) =>
  state.financialYearClosings.columnConfig;
export const selectFYCColumnConfigTableId = (state: RootState) =>
  state.financialYearClosings.configTableId;

export const selectFinancialYearClosingsSettings = (state: RootState) =>
  state.financialYearClosings.fySettingsData;

export const selectAccountNature = (state: RootState) =>
  state.financialYearClosings.accountNature;
export const selectFYCLoadingStatus = (state: RootState) =>
  state.financialYearClosings.gridContentLoadingStatus;
