import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../Constants/Enum';

export interface BomConfigurationModel {
  id?: any;
  name?: string;
  active?: boolean;
  isDefault?: boolean;
  documentSequenceCode?: string;
  sequenceFormat?: string;
  bomProductsConfiguration: ComponentProduct[];
  byProducts?: ComponentProduct[];
  bomOperationsConfiguration: BomOperation[];
  bomAddCostConfiguration: AdditionalCost[];
  code?: string;
  version?: number;
  attachments: any[];
}

export const NewBomConfigurationModel: BomConfigurationModel = {
  name: '',
  active: true,
  isDefault: false,
  documentSequenceCode: '',
  sequenceFormat: '',
  bomProductsConfiguration: [],
  byProducts: [],
  bomOperationsConfiguration: [],
  bomAddCostConfiguration: [],
  attachments: []
};
export interface ComponentProduct {
  itemId?: number;
  itemName?: string;
  quantity?: number;
  cost?: number;
  name?: string;
  priceOfItem?: number;
  stockUom?: number;
  productCode?: string;
  bomProductSubstitutesDetails?: SubstituteProduct[];
  produceProductType?: PRODUCE_PRODUCT_TYPE;
  documentSequenceCode?: string;
  productType?: PRODUCT_TYPE;
  uomSchemaDto?: any;
  defaultStockUom?: number;
  documentUOMSchemaDefinition?: any;
  uomQuantity?: number;
  multipleUomSchema?: boolean;
}
interface SubstituteProduct {
  productId: string;
  productName: string;
  productDocumentSeqCode: string;
  stockUom: number;
  type: PRODUCT_TYPE;
  advancedTracking: ADVANCE_TRACKING;
}

export interface BomOperation {
  operationId: number;
  totalCost: number;
  operationName: string;
  costPerHour: number;
  fixedRate: number;
  price: number;
  operationTime: number;
  opDependencyList: any;
  opDependency: any;
  operationDependency: any;
  productCode?: string;
  processType?: string;
  qcNeeded?: boolean;
  invalidFields?: any[];
  isInsertedRow?: boolean;
}
interface AdditionalCost {
  label: string;
  price: number;
}
