import { INPUT_TYPE, DKLabel } from 'deskera-ui-library';
import { IWHTax } from '../Models/WHTax';

export enum WH_TAX_FORM_KEYS {
  SECTION_NAME = 'sectionName',
  DESCRIPTION = 'sectionShortDescription',
  ACCOUNT_CODE = 'accountCode',
  ACCOUNT_RECEIVABLE_CODE = 'accountReceivableCode',
  RATE = 'rate',
  RESIDENT = 'resident',
  STANDARD_TAX_ID = 'standardTaxId',
  IS_STANDARD_TDS = 'isStandardTds',
  ANNUAL_EXEMPTION_LIMIT = 'annualExemptionLimit'
}
export enum WH_TAX_GROUP_FORM_KEYS {
  NAME = 'name',
  PERCENTAGE = 'percentage'
}
export const initialWHTaxFormState = {
  sectionName: {
    value: null,
    label: 'Section Name',
    key: WH_TAX_FORM_KEYS.SECTION_NAME,
    type: INPUT_TYPE.DROPDOWN,
    required: true,
    invalid: false
  },
  sectionShortDescription: {
    value: null,
    label: 'Section Description',
    key: WH_TAX_FORM_KEYS.DESCRIPTION,
    type: INPUT_TYPE.TEXT,
    required: false,
    invalid: false
  },
  accountCode: {
    value: null,
    label: 'Account',
    key: WH_TAX_FORM_KEYS.ACCOUNT_CODE,
    type: INPUT_TYPE.DROPDOWN,
    required: true,
    renderer: (index: any, account: any) => {
      return account.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: '',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: null,
      data: [],
      renderer: (index: any, obj: any) => {
        return <DKLabel text={`${obj.name}`} />;
      }
    },
    invalid: false
  },
  accountReceivableCode: {
    value: null,
    label: 'Account Receivable',
    key: WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE,
    type: INPUT_TYPE.DROPDOWN,
    required: true,
    renderer: (index: any, account: any) => {
      return account.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: '',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: null,
      data: [],
      renderer: (index: any, obj: any) => {
        return <DKLabel text={`${obj.name}`} />;
      }
    },
    invalid: false
  },
  rate: {
    value: 0,
    label: 'Rate',
    key: WH_TAX_FORM_KEYS.RATE,
    type: INPUT_TYPE.NUMBER,
    required: true,
    invalid: false
  },
  // annualExemptionLimit: {
  //   value: 0,
  //   label: 'Annual Exemption Limit',
  //   key: WH_TAX_FORM_KEYS.ANNUAL_EXEMPTION_LIMIT,
  //   type: INPUT_TYPE.NUMBER,
  //   required: true,
  //   invalid: false
  // },
  resident: {
    value: false,
    label: 'Is Resident?',
    key: WH_TAX_FORM_KEYS.RESIDENT,
    type: 'checkbox',
    required: false,
    invalid: false
  }
};
export const initialWHTaxGroupFormState = {
  [WH_TAX_GROUP_FORM_KEYS.NAME]: {
    value: null,
    label: 'Group Name',
    key: WH_TAX_GROUP_FORM_KEYS.NAME,
    type: INPUT_TYPE.TEXT,
    required: true,
    invalid: false
  },
  [WH_TAX_GROUP_FORM_KEYS.PERCENTAGE]: {
    value: 0,
    label: 'Percentage',
    key: WH_TAX_GROUP_FORM_KEYS.PERCENTAGE,
    type: INPUT_TYPE.NUMBER,
    required: true,
    invalid: false
  }
};

export const createPayloadFromState = (formState: any): IWHTax => {
  return {
    [WH_TAX_FORM_KEYS.ACCOUNT_CODE]:
      formState[WH_TAX_FORM_KEYS.ACCOUNT_CODE]?.value,
    [WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE]:
      formState[WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE]?.value,
    [WH_TAX_FORM_KEYS.SECTION_NAME]:
      formState[WH_TAX_FORM_KEYS.SECTION_NAME]?.value?.sectionName,
    [WH_TAX_FORM_KEYS.RATE]: formState[WH_TAX_FORM_KEYS.RATE]?.value || 0,
    [WH_TAX_FORM_KEYS.RESIDENT]: !!formState[WH_TAX_FORM_KEYS.RESIDENT]?.value,
    [WH_TAX_FORM_KEYS.DESCRIPTION]:
      formState[WH_TAX_FORM_KEYS.DESCRIPTION]?.value,
    [WH_TAX_FORM_KEYS.ANNUAL_EXEMPTION_LIMIT]:
      formState[WH_TAX_FORM_KEYS.ANNUAL_EXEMPTION_LIMIT]?.value,
    [WH_TAX_FORM_KEYS.IS_STANDARD_TDS]: false,
    [WH_TAX_FORM_KEYS.STANDARD_TAX_ID]:
      formState[WH_TAX_FORM_KEYS.SECTION_NAME]?.value?.tdsId
  };
};
export const isStandardTaxRenderer = ({ rowData }: any) => {
  return (
    <div
      className={`fs-m border-radius-s p-h-xs fw-m ${
        !!rowData?.isStandardTds
          ? 'data-grid-badge-color-6'
          : 'data-grid-badge-color-4'
      }`}
    >
      <div
        className=" fs-r text-align-left display-block"
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {!!rowData?.isStandardTds ? 'Yes' : 'No'}
      </div>
    </div>
  );
};
export const isResidentRenderer = ({ rowData }: any) => {
  return (
    <div
      className={`fs-m border-radius-s p-h-xs fw-m ${
        !!rowData?.resident
          ? 'data-grid-badge-color-6'
          : 'data-grid-badge-color-4'
      }`}
    >
      <div
        className=" fs-r text-align-left display-block"
        style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
      >
        {!!rowData?.resident ? 'Yes' : 'No'}
      </div>
    </div>
  );
};
export const IL_CERTIFICATE_CONST = [
  {
    name: 'Certificate No',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'number',
    key: 'number',
    id: 'number'
  },
  {
    name: 'WHT %',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'withHoldingTaxPercentage',
    key: 'withHoldingTaxPercentage',
    id: 'withHoldingTaxPercentage'
  },
  {
    name: 'Valid From',
    type: INPUT_TYPE.DATE,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'validFrom',
    key: 'validFrom',
    id: 'validFrom'
  },
  {
    name: 'Valid To',
    type: INPUT_TYPE.DATE,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'validTo',
    key: 'validTo',
    id: 'validTo'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100
  }
];
export const IL_GLOBAL_BANK_COL_CONFIG = [
  {
    name: 'ABA Number',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'abaNumber',
    key: 'abaNumber',
    id: 'abaNumber'
  },
  {
    name: 'Bank Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankName',
    key: 'bankName',
    id: 'bankName'
  },
  {
    name: 'Bank Address',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankAddress',
    key: 'bankAddress',
    id: 'bankAddress'
  },
  {
    name: 'Bank Account Number',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankAccountNumber',
    key: 'bankAccountNumber',
    id: 'bankAccountNumber'
  },
  {
    name: 'Bank Account Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankAccountName',
    key: 'bankAccountName',
    id: 'bankAccountName'
  },
  {
    name: 'SWIFT code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'swiftCode',
    key: 'swiftCode',
    id: 'swiftCode'
  },
  {
    name: 'IBAN',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'iban',
    key: 'iban',
    id: 'iban'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100
  }
];
export const IL_LOCAL_BANK_COL_CONFIG = [
  {
    name: 'Branch Number',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'branchNumber',
    key: 'branchNumber',
    id: 'branchNumber'
  },
  {
    name: 'Branch Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'branchName',
    key: 'branchName',
    id: 'branchName'
  },
  {
    name: 'Branch Address',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'branchAddress',
    key: 'branchAddress',
    id: 'branchAddress'
  },
  {
    name: 'Branch Account Number',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankAccountNumber',
    key: 'bankAccountNumber',
    id: 'bankAccountNumber'
  },
  {
    name: 'Bank Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankCode',
    key: 'bankCode',
    id: 'bankCode'
  },
  {
    name: 'Bank Swift Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankSwiftCode',
    key: 'bankSwiftCode',
    id: 'bankSwiftCode'
  },
  {
    name: 'Bank Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankName',
    key: 'bankName',
    id: 'bankName'
  },
  {
    name: 'Bank IBAN Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'bankIbanCode',
    key: 'bankIbanCode',
    id: 'bankIbanCode'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100
  }
];
