import CustomFieldManager from "../../Manager/CustomFieldManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"

export default class StockRequestParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

         //ref number
        templateDataToReturn.refNumber = ''
        templateDataToReturn.date = ''

        if(this.getStockRequest(dataFromAPI)) {

            //custom field
            templateDataToReturn.customFields = this.getStockRequest(dataFromAPI).customField ?? []
            CustomFieldManager.setCustomFields(this.getStockRequest(dataFromAPI).customField ?? [])
            templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

            templateDataToReturn.refNumber = this.getStockRequest(dataFromAPI).documentSequenceCode ?? ''
            let date = this.getStockRequest(dataFromAPI).stockRequestDate ?? ''
            if(date !== '') {
                templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(date), templateDataToReturn.dateFormat)
            }


            templateDataToReturn.status = this.getStockRequest(dataFromAPI).approveStatus ?? ''
            templateDataToReturn.createdBy = this.getStockRequest(dataFromAPI).createdByName ?? ''

            templateDataToReturn.approvedBy = this.getStockRequest(dataFromAPI).approvedByName ?? ''
            templateDataToReturn.approvedDate = ''

            let approvedDate = this.getStockRequest(dataFromAPI).approvedDate ?? ''
            if(approvedDate !== '') {
                templateDataToReturn.approvedDate = getConvertedDate(convertDateFromERPFormatToDocFormat(approvedDate), templateDataToReturn.dateFormat)
            }

            //assign line item
            templateDataToReturn.lineItems = this.getLineItem(this.getStockRequest(dataFromAPI))

            let notes = this.getStockRequest(dataFromAPI).memo ?? ''
            if (notes !== '') {
                templateDataToReturn.notes = notes
            }

        }
        

        return templateDataToReturn
    }


    static getLineItem(stockRequest) {
        var lineItems =  []
        
        if (stockRequest.stockRequestItems && stockRequest.stockRequestItems !== null) {
            if (stockRequest.stockRequestItems.length > 0) {
                stockRequest.stockRequestItems.forEach(element => {
                    var item = {...element}
                    item.lineNumber = element.lineNumber
                    item.name = element.product?.name ?? ''
                    item.description = element.product?.description ?? ''
                    item.code = element.product?.documentSequenceCode ?? ''

                    item.quantity = element.productAvailableQuantity ?? 0
                    item.requestedQty = element.productQuantity ?? 0
                    item.targetWarehouseName = element.dstWarehouseName ?? ''
                    item.targetWarehouseCode = element.dstWarehouseCode ?? ''
                    item.selectedRow = element.dstRowName ?? ''
                    item.selectedRack = element.dstRackName ?? ''
                    item.selectedBin = element.dstBinName ?? ''

                    if (element.documentUOMSchemaDefinition?.name !== undefined) {
                        item.uom = element.documentUOMSchemaDefinition?.name
                    }
                    else {
                        item.uom = 'N/A'
                    }

                    item.customFields = element.customField
                    lineItems.push(item)
                });
            }
        }
        
        return lineItems
    }

    static getStockRequest(dataFromAPI) {
        if(dataFromAPI.stockRequest && dataFromAPI.stockReques !== null) {
            return dataFromAPI.stockRequest 
        }
        return undefined
    }
}
