import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';

import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';
import DepreciationScheduleService from '../../Services/DepreciationSchedule';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchDepreciationSchedule = createAsyncThunk(
  'depreciationSchedule',
  async () => {
    const response =
      await DepreciationScheduleService.getDepreciationScheduleByYear();
    return response;
  }
);

export const DepreciationScheduleSlice = createSlice({
  name: 'Depreciation Schedule',
  initialState,
  reducers: {
    depreciationScheduleColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.DEPRECIATION_SCHEDULE
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToDepreciationScheduleColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateDepreciationScheduleDetails: (state, action: PayloadAction<any>) => {
      const depreciationScheduleDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const depreciationScheduleIndex: number = content.findIndex(
        (contact: any) => contact.id === depreciationScheduleDetails.id
      );
      if (depreciationScheduleIndex >= 0) {
        content[depreciationScheduleIndex] = depreciationScheduleDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepreciationSchedule.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchDepreciationSchedule.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToDepreciationScheduleColumnConfig,
  depreciationScheduleColumnConfig,
  updateColumnConfig,
  updateDepreciationScheduleDetails,
  updateRefreshCallback
} = DepreciationScheduleSlice.actions;

export const selectDepreciationSchedule = (state: RootState) =>
  state.DepreciationSchedule.data;

export const selectDepreciationScheduleLoadingStatus = (state: RootState) =>
  state.DepreciationSchedule.gridContentLoadingStatus;

export const selectDepreciationScheduleColumnConfig = (state: RootState) =>
  state.DepreciationSchedule.columnConfig;

export const selectColumnConfigTableId = (state: RootState) =>
  state.DepreciationSchedule.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.DepreciationSchedule.refreshCallback;

export default DepreciationScheduleSlice.reducer;
