import React, { useEffect, useState } from 'react';
import {
  showToast,
  TOAST_TYPE,
  DKInput,
  INPUT_VIEW_DIRECTION,
  DKLabel,
  showAlert,
  INPUT_TYPE
} from 'deskera-ui-library';
import { InputTag } from '../../../Models/NewContact';
import {
  FORM_ELEMENTS,
  POPUP_CALLBACKS_TYPE
} from '../../../Constants/Constant';
import { deepClone } from '../../../Utility/Utility';
import {
  CallBackPayloadType,
  GSTSubmissionCheckPayload
} from '../../../Models/Interfaces';
import EInvoiceService from '../../../Services/EInvoice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { useAppSelector } from '../../../Redux/Hooks';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';

interface OPTPopupState {
  otp: InputTag<string>;
  resendOtp: { time: string; show: boolean };
}

interface IRPCredentialsProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  gstIn: string;
  gstUsername: string | number;
  button?: any;
  id?: any;
  refreshData?: () => void;
}

export const OtpPopup: React.FC<IRPCredentialsProps> = (props) => {
  const initialState: OPTPopupState = {
    otp: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isDisabled: false,
      isMandatory: true
    },
    resendOtp: { time: '2:00', show: true }
  };
  const [otpFormState, setOtpFormState] = useState<OPTPopupState>(
    deepClone(initialState)
  );
  const currentTenantDetails = useAppSelector(activeTenantInfo);

  useEffect(() => {
    showTimer();
    return () => {
      // null
    };
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.GST_E_FILING,
      data: () => {
        save();
      }
    });
  };

  const showTimer = () => {
    const timer = 120;
    let secElapsed = 0;
    let minutes = Math.floor((timer - secElapsed) / 60);
    let seconds = (timer - secElapsed) % 60;
    const interval = () => {
      setTimeout(() => {
        secElapsed++;
        minutes = Math.floor((timer - secElapsed) / 60);
        seconds = (timer - secElapsed) % 60;
        const time = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        const updatedState = otpFormState;
        updatedState.resendOtp.time = time;
        if (secElapsed < timer) {
          interval();
        } else {
          updatedState.resendOtp.show = false;
        }
        setOtpFormState({ ...updatedState });
      }, 1000);
    };
    interval();
  };

  const formUpdated = (value: string) => {
    const updatedState = otpFormState;
    updatedState.otp.value = value;
    setOtpFormState({ ...updatedState });
  };

  const recallReturn = () => {
    let authData: any = localStorage.getItem('gstr1_authData');
    let authObject = JSON.parse(authData);
    let params = {
      username: currentTenantDetails.indiaGstrUsername,
      gstin: currentTenantDetails.gstin,
      id: props.id,
      currStatus: localStorage.getItem('gstStatus')
        ? localStorage.getItem('gstStatus')
        : 'PUSH_AND_APPROVED',
      returnPeriod: '072017',
      ...authObject
    };

    EInvoiceService.resetGstr1(params)
      .then((res: any) => {
        props.refreshData && props.refreshData();
        RouteManager.navigateToPage(PAGE_ROUTES.GST_RETURN);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const checkStatus = () => {
    let authData: any = localStorage.getItem('gstr1_authData');
    let authObject = JSON.parse(authData);
    let params = {
      username: currentTenantDetails.indiaGstrUsername,
      gstin: currentTenantDetails.gstin,
      id: props.id,
      currStatus: localStorage.getItem('gstStatus')
        ? localStorage.getItem('gstStatus')
        : 'PUSH_AND_APPROVED',
      returnPeriod: '072017',
      ...authObject
    };
    delete params['status_cd'];
    delete params['error'];
    delete params['expiry'];

    EInvoiceService.checkGstr1(params)
      .then((data: any) => {
        if (
          data['indiaFailureLogReportErrorsDtoList'] &&
          data['indiaFailureLogReportErrorsDtoList'].length > 0
        ) {
          let msg = '';
          data['indiaFailureLogReportErrorsDtoList'].forEach((element: any) => {
            msg += element.errorMessage + ' \n ';
          });
          showAlert('GST portal reported errors below', msg);
        } else {
          // this.gstStatus = data["report_status"];
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const save = () => {
    const updatedState = otpFormState;
    if (!otpFormState.otp.value) {
      updatedState.otp.hasError = true;
      updatedState.otp.errorMsg = 'Please enter OTP';
      return;
    }
    let params: any = {};
    localStorage.setItem('gstn_otp', otpFormState.otp.value.toString());
    params = {
      username: props.gstUsername,
      gstin: props.gstIn,
      otp: otpFormState.otp.value.toString(),
      ...EInvoiceService.optDetails
    };
    delete params['status_cd'];
    delete params['error'];

    EInvoiceService.checkAuthToken(params)
      .then((data: any) => {
        if (data) {
          if (data['status_cd'] === '0') {
            showToast('E-Invoice Connection Failed', TOAST_TYPE.FAILURE);
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          } else {
            if (props.button === 'status') {
              checkStatus();
              return;
            }
            if (props.button === 'recall') {
              recallReturn();
            }
            localStorage.setItem('gstr1_timeExpiry_minute', data['expiry']);
            let date = new Date(
              new Date(
                new Date().setMinutes(new Date().getMinutes() + 120)
              ).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
            );
            localStorage.setItem('gstr1_timeExpiry_date', date.toString());
            showToast('e-Invoice Connected Successfully', TOAST_TYPE.SUCCESS);
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.GST_E_FILING_SUCCESS
            });
          }
        }
      })
      .catch((err) => {
        showToast('E-Invoice Connection Failed', TOAST_TYPE.FAILURE);
        props.passingInteraction({ type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP });
      });
  };

  const resendOtp = () => {
    const updatedState = otpFormState;
    const payload: GSTSubmissionCheckPayload = {
      username: props.gstUsername.toString(),
      gstin: props.gstIn
    };
    EInvoiceService.getOTP(payload)
      .then((data: any) => {
        updatedState.resendOtp.show = true;
        showTimer();
      })
      .catch((error: any) => {
        showToast('Resending New OTP Failed', TOAST_TYPE.FAILURE);
      });
  };

  return (
    <div className="parent-width p-h-m">
      <DKInput
        title="OTP"
        value={otpFormState.otp.value}
        type={INPUT_TYPE.PASSWORD}
        required={otpFormState.otp.isMandatory}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        className="mb-m"
        onChange={(event: string) => formUpdated(event)}
        canValidate={otpFormState.otp.hasError}
        validator={(value: string) => {
          return !otpFormState.otp.hasError;
        }}
        errorMessage={otpFormState.otp.errorMsg}
      />
      {otpFormState.resendOtp.show ? (
        <div>
          Resend OTP in <span>{otpFormState.resendOtp.time}</span>
        </div>
      ) : (
        <div>
          <div>Didn't Receive OTP ?</div>
          <div onClick={resendOtp} className="fw-m text-blue mb-m cursor-hand">
            Resend OTP
          </div>
        </div>
      )}
      <div>
        OTP has been sent to the GSTN registered mobile number and email.{' '}
      </div>
    </div>
  );
};
