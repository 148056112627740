import Utility from '../Utility/Utility';
import {
  isTabletSize,
  isTabletView,
  isViewportLarge
} from '../Utility/ViewportSizeUtils';

class SideBarService {
  static sideBarExpandedWidth = 230;
  static sideBarCollapsedWidth = 60;
  static isSideBarExpanded =  
    localStorage.getItem('isSideBarExpanded') !== null
      ? localStorage.getItem('isSideBarExpanded') === 'true'
      : true;
  static toggleSideBar = () => {
    SideBarService.isSideBarExpanded = !SideBarService.isSideBarExpanded;
    SideBarService.triggerSidebarChangeEvent();
    Utility.setPersistentValue(
      'isSideBarExpanded',
      SideBarService.isSideBarExpanded
    );
  };
  static getContainerWidth = (): number => {
    if (isTabletView() && isTabletSize()) {
      return window.innerWidth * 0.96;
    } else if (isViewportLarge()) {
      return window.innerWidth - SideBarService.sideBarExpandedWidth - 48;
    } else {
      return (
        window.innerWidth * 0.92
      ); /* as 4% padding present on mainContainer */
    }
  };
  static getCurrentMenuWidth = (): number => {
    return SideBarService.isSideBarExpanded
      ? SideBarService.sideBarExpandedWidth
      : SideBarService.sideBarCollapsedWidth;
  };
  static setIsSideBarExpanded() {
    if (isViewportLarge() && !isTabletSize()) {
      SideBarService.isSideBarExpanded = true;
    } else {
      SideBarService.isSideBarExpanded =
        Utility.getPersistentValue('isSideBarExpanded')?.toString() ===
          'true' || false;
    }
  }
  static triggerSidebarChangeEvent() {
    dispatchEvent(
      new CustomEvent('onSideBarChange', {
        detail: { isExpanded: SideBarService.isSideBarExpanded }
      })
    );
  }
}

export default SideBarService;
