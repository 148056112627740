import AppManager from "../Manager/AppManager"
import ComponentManager from "../Manager/ComponentManager"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import Utility, { getCapitalized, getIsBOMModule, getIsBill, getIsDebitOrCreditNote, getIsExpenseOrDeposit, getIsFulfillment, getIsGoodsReceipt, getIsInvoice, getIsJobWorkOutOrder, getIsJournalEntry, getIsMakePayment, getIsOrder, getIsPackList, getIsPickList, getIsProductDocumentForBuySell, getIsPurchaseRequisition, getIsServiceRequisition, getIsQCDocument, getIsQuotation, getIsReceivePayment, getIsSalesOrder, getIsSalesReturn, getIsShipList, getIsStockAdjustment, getIsStockIssue, getIsStockRequest, getIsStockTransfer, getIsWorkOrder,getIsSecurityGateEntry, getIsServiceOrderTemplate, getIsGoodsReceiptNoteTemplate, getIsQCInspection, getIsSOAVendor, getIsSOACustomer, getIsInspectionReport, getIsRequestForQuotation, getIsPurchaseInwardQuotation } from "../Utilities/Utility"

export const DocumentDetailType = {
    refNumber: 'refNumber',
    customerOrderNumber: 'customerOrderNumber',
    startDate: 'startDate',
    dueDate: "dueDate",
    additionalDate: "additionalDate",
    ewayBillNo: "ewayBillNo",
    ewayBillDate: "ewayBillDate",
    linkDocumentNumber: "linkDocumentNumber",
    linkDocumentDate: "linkDocumentDate",
    linkDocumentType: "linkDocumentType",
    parentLinkDocumentNumber: "parentLinkDocumentNumber",
    customField: "customField",
    supplierInvoiceNo: "supplierInvoiceNo",
    taxRegistrationNumber: "taxRegistrationNumber",
    taxPayerId: "taxPayerId",
    crm: {
        dealName: 'crmDealName',
        dealOwnerName: 'crmDealOwnerName'
    },
    workOrder: {
        productName: "productName",
        productCode: "productCode",
        deliveryDate: "deliveryDate",
        estimatedTime: "estimatedTime",
        actualQuantity: "actualQuantity",
        manufactureQuantity: "manufactureQuantity",
        parentWorkOrder: 'parentWorkOrder',
        woProductDescription: 'woProductDescription',
        woStatus: 'woStatus',
        woQCStatus: 'woQCStatus',
        woContactName: 'woContactName',
    },
    stock: {
        stockTransferCreatedByName: "stockTransferCreatedByName",
        status: 'status',
        type: 'type',
        reason: 'reason',
        warehouse: 'warehouse'
    },
    qcDocument: {
        status: 'qc_status',
        productName: "qc_productName",
        productCode: "qc_productCode",
        transactionType: 'qc_transactionType',
        transactionCode: 'qc_transactionCode',
        linkedDocumentCode: 'qc_linkedDocumentCode',
        contact: 'qc_contact',
        approver: 'qc_approver',
        uomName: 'uomName',
    },
    inspectionReport: {
        customerName: 'inspection_report_customer_name',
        customerCode: 'inspection_report_customer_seq_code',
        productName: "inspection_report_product_name",
        productDescription: "inspection_report_product_description",
        productCode: "inspection_report_product_seq_code",
    },
    qcInspection: {
        workOrderSeqCode: 'qc_inspection_work_order_seq_code',
        workOrderDate: "qc_inspection_work_order_date",
        workOrderEndDate: "qc_inspection_work_order_end_date",
        workOrderStatus: "qc_inspection_work_order_status",
        productName: "qc_inspection_product_name",
        productCode: "qc_inspection_product_seq_code",
        productDescription: "qc_inspection_product_description",
    },
    reasonToReturn: "reasonToReturn",
    warehouseName: "warehouseName",
    jobWorkOutOrder: {
        voucherNo: "voucherNo",
        dated: "dated",
        orderNo: "orderNo",
        termOfPayment: "termOfPayment",
        dispatchDocNo: "dispatchDocNo",
        destination: "destination",
        dispatchedThrough: "dispatchedThrough",
        durationOfProcess: "durationOfProcess",
        natureOfProcessing: "natureOfProcessing",
        dispatchStatus: "dispatchStatus",
    },
    bom: {
        bomProductDescription: "bomProductDescription",
        bomPartNo: "bomPartNo",
        bomFactoryCode: "bomFactoryCode",
        bomEquivalentTo: "bomEquivalentTo",
    },
    stockRequest: {
        stockRequestStatus: "stockRequestStatus",
        stockRequestCreatedByName: "stockRequestCreatedByName",
        stockRequestApprovedByName: "stockRequestApprovedByName",
        stockRequestApprovedDate: "stockRequestApprovedDate",
    },
    stockIssue: {
        stockRequestNo: "stockRequestNo",
        stockRequestCreatedBy: "stockRequestCreatedBy",
        stockRequestApprovedBy: "stockRequestApprovedBy",
        stockIssueCreatedBy: "stockIssueCreatedBy",
        stockIssueWarehouse: "stockIssueWarehouse",
    },
    purchaseRequisition: {
        authorizedDate: "authorizedDate",
        requestedBy: "requestedBy"
    },
    securityGateEntry: {
        lrNumber: 'lrNumber',
        driverName: 'driverName',
        vehicleNumber: 'vehicleNumber',
        transporter: 'transporter',
        contactNumber: 'contactNumber',
        personName: 'createdBy',
        date: 'date',
        contact: 'contact'
    },
    soa: {
        companyRegNo: 'soa_companyRegNo',
        gstRegNo: 'soa_gstRegNo',
        fromDate: 'soa_fromDate',
        asOfDate: 'soa_asOfDate',
        toDate: 'soa_toDate',
    },
    piq: {
        rfqSeqCode: 'rfqSeqCode'
    },
    serviceOrder: {
        gstNo: "gstNo",
        panNo: "panNo",
        cinNo: "cinNo"
    }
}
export default class DocumentDetailsParser {

    static getDocumentDetailsList(data) {
        const documentDetailList = this.getDocumentDetailsByType(data)
        return documentDetailList
    }

    static getDocumentDetailsByType(data) {
        if (data === undefined || data === null) {
            return []
        }

        if (data.type === undefined || data.type === null) {
            return []
        }
        if (getIsQuotation(data.type)
            || getIsInvoice(data.type)
            || getIsSalesOrder(data.type)
            || getIsRequestForQuotation(data.type)
            || getIsPurchaseInwardQuotation(data.type)
            || getIsOrder(data.type)
            || getIsServiceRequisition(data.type)
            || getIsPurchaseRequisition(data.type)
        ) {
            return ProductDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsBill(data.type)) {
            if (getIsProductDocumentForBuySell(data)) {
                return ProductDocumentDetailsMapper.getDefaultDocumentDetail(data)
            }
            return ExpenseBillDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsExpenseOrDeposit(data.type) ) {
            return AccountDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsDebitOrCreditNote(data.type) ) {
            return CreditAndDebitNoteDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsFulfillment(data.type)
            || getIsGoodsReceipt(data.type)
        ) {
            return FulfllmentAndGoodsReceiptDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsMakePayment(data.type)
            || getIsReceivePayment(data.type)
        ) {
            return PaymentDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsJournalEntry(data.type)) {
            return JournalEntryDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsPickList(data.type)) {
            return PickDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsPackList(data.type)) {
            return PackDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsShipList(data.type)) {
            return ShipDocumentDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsWorkOrder(data.type)) {
            return WorkOrderDetailsMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockTransfer(data.type)) {
            return StockTransferMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockAdjustment(data.type)) {
            return StockAdjustmentMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsQCDocument(data.type)) {
            return QCDocumentMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsQCInspection(data.type)) {
            return QCInspectionMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsInspectionReport(data.type)) {
            return InspectionReportMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsSalesReturn(data.type)) {
            return SalesReturnDocumentMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsJobWorkOutOrder(data.type)) {
            return JobWorkOutOrderMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsBOMModule(data.type)) {
            return []
            // return BomMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockRequest(data.type)) {
            return StockRequestMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsStockIssue(data.type)) {
            return StockIssueMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsSecurityGateEntry(data.type)) {
            return SecurityGateEntryMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsSOACustomer(data.type)) {
            return SOACustomerMapper.getDefaultDocumentDetail(data)
        }
        else if (getIsSOAVendor(data.type)) {
            return SOAVendorMapper.getDefaultDocumentDetail(data)
        }
        return []
    }

    static removeLinkDocument(data, documentDetailList) {
        if (data !== undefined && data !== null) {
            if (getIsInvoice(data.type) || getIsSalesOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (TemplateSettingsManager.getPrintInfo() !== undefined) {
                        var newDocType = documentDetailList
                        if (documentDetailList.length > 0) {
                            if (data.linkDocumentNumber === undefined || data.linkDocumentNumber === null || data.linkDocumentNumber === '') {
                                newDocType = newDocType.filter(x => x.type !== DocumentDetailType.linkDocumentNumber)
                            }
                            if (data.parentLinkDocumentNumber === undefined || data.parentLinkDocumentNumber === null || data.parentLinkDocumentNumber === '') {
                                newDocType = newDocType.filter(x => x.type !== DocumentDetailType.parentLinkDocumentNumber)
                            }

                            newDocType.forEach((element, index) => {
                                element.index = index
                            });
                        }

                        return newDocType
                    }
                }
            }
        }

        return documentDetailList
    }

    static addTaxRegistrationDetail(data, documentDetailList) {
        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.taxRegistrationNumber)) {
            var taxRegistrationNumber = {
                isSelected: false,
                type: DocumentDetailType.taxRegistrationNumber,
                index: documentDetailList.length,
                label: getLocalisedText('tax_registration_number'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.taxRegistrationNumber, data),
            }
            documentDetailList.push(taxRegistrationNumber)
        }

        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.taxPayerId)) {
            var taxPayerId = {
                isSelected: false,
                type: DocumentDetailType.taxPayerId,
                index: documentDetailList.length,
                label: getLocalisedText('tax_payer_id'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.taxPayerId, data),
            }
            documentDetailList.push(taxPayerId)
        }

        return documentDetailList
    }

    static addWorkOrderDetails(data, documentDetailList) {
        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.woProductDescription)) {
            var woProductDescription = {
                isSelected: false,
                type: DocumentDetailType.workOrder.woProductDescription,
                index: documentDetailList.length,
                label: getLocalisedText('product_description'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.woProductDescription, data),
            }
            documentDetailList.push(woProductDescription)
        }

        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.woStatus)) {
            var woStatus = {
                isSelected: false,
                type: DocumentDetailType.workOrder.woStatus,
                index: documentDetailList.length,
                label: getLocalisedText('status'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.woStatus, data),
            }
            documentDetailList.push(woStatus)
        }

        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.woQCStatus)) {
            var woQCStatus = {
                isSelected: false,
                type: DocumentDetailType.workOrder.woQCStatus,
                index: documentDetailList.length,
                label: getLocalisedText('qc_status'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.woQCStatus, data),
            }
            documentDetailList.push(woQCStatus)
        }

        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.woContactName)) {
            var woContactName = {
                isSelected: false,
                type: DocumentDetailType.workOrder.woContactName,
                index: documentDetailList.length,
                label: getLocalisedText('contact_name'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.woContactName, data),
            }
            documentDetailList.push(woContactName)
        }

        return documentDetailList
    }

    static addStockTransferDetails(data, documentDetailList) {
        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.stock.warehouse)) {
            var warehouse = {
                isSelected: false,
                type: DocumentDetailType.stock.warehouse,
                index: documentDetailList.length,
                label: getLocalisedText('warehouse'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.stock.warehouse, data),
            }
            documentDetailList.push(warehouse)
        }

        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.stock.stockTransferCreatedByName)) {
            var stockTransferCreatedByName = {
                isSelected: false,
                type: DocumentDetailType.stock.stockTransferCreatedByName,
                index: documentDetailList.length,
                label: getLocalisedText('person_name'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.stock.stockTransferCreatedByName, data),
            }
            documentDetailList.push(stockTransferCreatedByName)
        }

        return documentDetailList
    }


    static addStockIssuesDetails(data, documentDetailList) {
        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.stockIssue.stockIssueWarehouse)) {
            var warehouse = {
                isSelected: false,
                type: DocumentDetailType.stockIssue.stockIssueWarehouse,
                index: documentDetailList.length,
                label: getLocalisedText('warehouse'),
                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.stockIssue.stockIssueWarehouse, data),
            }
            documentDetailList.push(warehouse)
        }

        return documentDetailList
    }

    static addNewDocumentDetailType(data, documentDetailList) {
        var parent = documentDetailList.find(x => x.type === DocumentDetailType.parentLinkDocumentNumber)
        if (data !== undefined && data !== null) {
            if (getIsBill(data.type) || getIsOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.supplierInvoiceNo)) {
                        var supplierInvoiceNoType = {
                            isSelected: false,
                            type: DocumentDetailType.supplierInvoiceNo,
                            index: documentDetailList.length,
                            label: getLocalisedText('supplier_invoice_no'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.supplierInvoiceNo, data),
                        }
                        documentDetailList.push(supplierInvoiceNoType)
                    }

                    if(Utility.getIsIsraelCountry(data)) {
                        documentDetailList = this.addTaxRegistrationDetail(data, documentDetailList)
                    }
                }
                if (getIsOrder(data.type)) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.customerOrderNumber)) {
                        var customerOrderNumber_order = {
                            isSelected: false,
                            type: DocumentDetailType.customerOrderNumber,
                            index: documentDetailList.length,
                            label: getLocalisedText('con_number'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.customerOrderNumber, data),
                        }
                        documentDetailList.push(customerOrderNumber_order)
                    }

                    if (getIsServiceOrderTemplate(data.templateId)) {
                        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.serviceOrder.gstNo)) {
                            documentDetailList.push({
                                isSelected: false,
                                type: DocumentDetailType.serviceOrder.gstNo,
                                index: documentDetailList.length,
                                label: getLocalisedText('gst_no'),
                                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.serviceOrder.gstNo, data),
                            });
                        }
                        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.serviceOrder.panNo)) {
                            documentDetailList.push({
                                isSelected: false,
                                type: DocumentDetailType.serviceOrder.panNo,
                                index: documentDetailList.length,
                                label: getLocalisedText('pan_no'),
                                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.serviceOrder.panNo, data),
                            });
                        }
                        if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.serviceOrder.cinNo)) {
                            documentDetailList.push({
                                isSelected: false,
                                type: DocumentDetailType.serviceOrder.cinNo,
                                index: documentDetailList.length,
                                label: getLocalisedText('cin_no'),
                                key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.serviceOrder.cinNo, data),
                            });
                        }
                    }
                }
                //expense bill
                if (!getIsProductDocumentForBuySell(data)) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.dueDate)) {
                        var dueDate = {
                            isSelected: true,
                            type: DocumentDetailType.dueDate,
                            index: documentDetailList.length,
                            label: getLocalisedText('due_date'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.dueDate, data),
                        }
                        documentDetailList.push(dueDate)

                        if (Utility.getIsIsraelCountry(data)) {
                            documentDetailList = this.addTaxRegistrationDetail(data, documentDetailList)
                        }
                    }
                }
            }
            else if (getIsInvoice(data.type) || getIsQuotation(data.type) || getIsSalesOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.customerOrderNumber)) {
                        var customerOrderNumber_buy = {
                            isSelected: false,
                            type: DocumentDetailType.customerOrderNumber,
                            index: documentDetailList.length,
                            label: getLocalisedText('con_number'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.customerOrderNumber, data),
                        }
                        documentDetailList.push(customerOrderNumber_buy)

                        if (Utility.getIsIsraelCountry(data)) {
                            documentDetailList = this.addTaxRegistrationDetail(data, documentDetailList)
                        }
                    }
                }
                if (getIsInvoice(data.type) && data.ewayGenerated) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.ewayBillNo)) {
                        var ewayBillNo = {
                            isSelected: data.ewayGenerated && data.ewayBillNo !== undefined,
                            type: DocumentDetailType.ewayBillNo,
                            index: documentDetailList.length,
                            label: getLocalisedText('eway_bill_no'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.ewayBillNo, data),
                        }
                        documentDetailList.push(ewayBillNo)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.ewayBillDate)) {
                        var ewayBillDate = {
                            isSelected: data.ewayGenerated && data.ewayBillDate !== undefined,
                            type: DocumentDetailType.ewayBillDate,
                            index: documentDetailList.length,
                            label: getLocalisedText('eway_bill_date'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.ewayBillDate, data),
                        }
                        documentDetailList.push(ewayBillDate)
                    }
                }

                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.crm.dealName)) {
                        var dealName = {
                            isSelected: false,
                            type: DocumentDetailType.dealName,
                            index: documentDetailList.length,
                            label: getLocalisedText('deal_name'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.crm.dealName, data),
                        }
                        documentDetailList.push(dealName)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.crm.dealOwnerName)) {
                        var dealOwnerName = {
                            isSelected: false,
                            type: DocumentDetailType.dealOwnerName,
                            index: documentDetailList.length,
                            label: getLocalisedText('deal_owner'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.crm.dealOwnerName, data),
                        }
                        documentDetailList.push(dealOwnerName)
                    }
                }
            }
            else if (getIsPurchaseInwardQuotation(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.piq.rfqSeqCode)) {
                        var rfqSeqCode = {
                            isSelected: true,
                            type: DocumentDetailType.piq.rfqSeqCode,
                            index: documentDetailList.length,
                            label: getLocalisedText('rfq_no'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.piq.rfqSeqCode, data),
                        }
                        documentDetailList.push(rfqSeqCode)
                    }
                }
            }
            else if (getIsQCDocument(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.qcDocument.uomName)) {
                        var uomName = {
                            isSelected: true,
                            type: DocumentDetailType.qcDocument.uomName,
                            index: documentDetailList.length,
                            label: getLocalisedText('uom'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.qcDocument.uomName, data),
                        }
                        documentDetailList.push(uomName)
                    }
                }
            }
            else if (getIsWorkOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentNumber)) {
                        var woLinkDocumentNumber = {
                            isSelected: false,
                            type: DocumentDetailType.linkDocumentNumber,
                            index: documentDetailList.length,
                            label: getLocalisedText('parent_linked_document_no'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                        }
                        documentDetailList.push(woLinkDocumentNumber)
                    }

                    var isShowParentDocument = false
                    if (documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentNumber) &&
                        !documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentType)
                    ) {
                        documentDetailList.forEach(element => {
                            if (element.type === DocumentDetailType.linkDocumentNumber) {
                                element.label = getLocalisedText('parent_linked_document_no')
                                isShowParentDocument = element.isSelected
                            }
                        });
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentType)) {
                        var woLinkDocumentType = {
                            isSelected: isShowParentDocument,
                            type: DocumentDetailType.linkDocumentType,
                            index: 9,
                            label: getLocalisedText('parent_linked_document_type'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentType, data),
                        }
                        documentDetailList.push(woLinkDocumentType)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.workOrder.parentWorkOrder)) {
                        var parentWorkOrderType = {
                            isSelected: false,
                            type: DocumentDetailType.workOrder.parentWorkOrder,
                            index: documentDetailList.length,
                            label: getLocalisedText('parent_work_order'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.workOrder.parentWorkOrder, data),
                        }
                        documentDetailList.push(parentWorkOrderType)
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.customerOrderNumber)) {
                        var customerOrderNumber = {
                            isSelected: false,
                            type: DocumentDetailType.customerOrderNumber,
                            index: documentDetailList.length,
                            label: getLocalisedText('con_number'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.customerOrderNumber, data),
                        }
                        documentDetailList.push(customerOrderNumber)
                    }

                    documentDetailList = this.addWorkOrderDetails(data, documentDetailList)
                }
            }
            else if (getIsDebitOrCreditNote(data.type) || getIsSalesOrder(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentNumber)) {
                        var linkDocumentNumber = {
                            isSelected: false,
                            type: DocumentDetailType.linkDocumentNumber,
                            index: documentDetailList.length,
                            label: AppManager.getTypeNoString(data.linkDocumentType),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                        }
                        documentDetailList.push(linkDocumentNumber)
                    }
                }
            }
            else if (getIsFulfillment(data.type) || getIsGoodsReceipt(data.type)) {
                if (getIsGoodsReceipt(data.type)) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.linkDocumentDate)) {
                        documentDetailList.push({
                            isSelected: false,
                            type: DocumentDetailType.linkDocumentDate,
                            index: documentDetailList.length,
                            label: AppManager.getTypeDateString(data.linkDocumentType),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.serviceOrder.cinNo, data),
                        });
                    }
                }

                if (Utility.getIsIsraelCountry(data)) {
                    documentDetailList = this.addTaxRegistrationDetail(data, documentDetailList)
                }
            }
            else if (getIsMakePayment(data.type) || getIsReceivePayment(data.type)) {
                if (Utility.getIsIsraelCountry(data)) {
                    documentDetailList = this.addTaxRegistrationDetail(data, documentDetailList)
                }
            }
            else if (getIsStockTransfer(data.type)) {
                documentDetailList = this.addStockTransferDetails(data, documentDetailList)
            }
            else if (getIsStockIssue(data.type)) {
                documentDetailList = this.addStockIssuesDetails(data, documentDetailList)
            }
            else if (getIsPurchaseRequisition(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.purchaseRequisition.authorizedDate)) {
                        documentDetailList.push({
                            isSelected: false,
                            type: DocumentDetailType.purchaseRequisition.authorizedDate,
                            index: documentDetailList.length,
                            label: getLocalisedText('authorised_date'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.purchaseRequisition.authorizedDate, data),
                        })
                    }
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.purchaseRequisition.requestedBy)) {
                        documentDetailList.push({
                            isSelected: false,
                            type: DocumentDetailType.purchaseRequisition.requestedBy,
                            index: documentDetailList.length,
                            label: getLocalisedText('requested_by'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.purchaseRequisition.requestedBy, data),
                        })
                    }
                }
            }
            else if (getIsServiceRequisition(data.type)) {
                if (documentDetailList !== undefined && documentDetailList !== null) {
                    
                    if (!documentDetailList.map(x => x.type).includes(DocumentDetailType.purchaseRequisition.requestedBy)) {
                        documentDetailList.push({
                            isSelected: false,
                            type: DocumentDetailType.purchaseRequisition.requestedBy,
                            index: documentDetailList.length,
                            label: getLocalisedText('requested_by'),
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.purchaseRequisition.requestedBy, data),
                        })
                    }
                }
            }
            
            if (Utility.getLinkDocumentList(data).length > 0) {
                var linkedDocuments = Utility.getLinkDocumentList(data)
                if (data.parentLinkDocumentNumber !== undefined && parent === undefined) {
                    const parentLinkDocumentType = AppManager.getTypeNoString(linkedDocuments[0].documentType ? linkedDocuments[0].documentType.toLowerCase() : '')
                    let linkDocumentType = ''

                    if (linkedDocuments.length >= 2) {
                        linkDocumentType = AppManager.getTypeNoString(linkedDocuments[1].documentType ? linkedDocuments[1].documentType.toLowerCase() : '')
                    }

                    var linkDocument = documentDetailList.find(x => x.type === DocumentDetailType.linkDocumentNumber)
                    if(linkDocument === undefined) {
                        var newParentLinkDocument = {
                            isSelected: false,
                            type: DocumentDetailType.parentLinkDocumentNumber,
                            index: documentDetailList.length,
                            label: parentLinkDocumentType,
                            documentType: linkedDocuments[0].documentType ? linkedDocuments[0].documentType : '',
                            key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.parentLinkDocumentNumber, data),
                        }
                        documentDetailList.push(newParentLinkDocument)
                    }
                    else {
                        documentDetailList.forEach((element, index) => {
                            if (element.type === DocumentDetailType.linkDocumentNumber) {
                                element.label = parentLinkDocumentType
                                element.index = index
                                element.type = DocumentDetailType.parentLinkDocumentNumber
                                element.documentType = linkedDocuments[0].documentType ? linkedDocuments[0].documentType : ''
                                element.key = DocumentLabelParser.parseDocumentKey(DocumentDetailType.parentLinkDocumentNumber, data)
                            }
                        });
                    }

                    var newLinkDocument = {
                        isSelected: false,
                        type: DocumentDetailType.linkDocumentNumber,
                        index: documentDetailList.length,
                        label: linkDocumentType,
                        documentType: linkDocumentType,
                        key: DocumentLabelParser.parseDocumentKey(DocumentDetailType.linkDocumentNumber, data),
                    }
                    documentDetailList.push(newLinkDocument)
                }
            }
            else if (data.parentLinkDocumentNumber === undefined && parent !== undefined) {
                documentDetailList = documentDetailList.filter(x => x.type !== DocumentDetailType.parentLinkDocumentNumber)
                documentDetailList.forEach((element, index) => {
                    if (element.type === DocumentDetailType.linkDocumentNumber) {
                        element.label = AppManager.getTypeNoString(data.linkDocumentType ? data.linkDocumentType.toLowerCase() : '')
                        element.index = index
                    }
                });
            }
        }

        return documentDetailList
    }
}

// use by quotation, invoice, sales order, bill and order
class ProductDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.additionalDate },
        ]

        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }

        if (data.templateId === 19
            || data.templateId === 21
            || data.templateId === 22
            || data.templateId === 23
            || data.templateId === 32
        ) {
            documentDetails = [
                { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
                { index: 1, isSelected: true, type: DocumentDetailType.startDate },
                { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
                { index: 3, isSelected: true, type: DocumentDetailType.dueDate },
            ]
        }

        if (data.ewayGenerated !== undefined && data.ewayGenerated !== null) {
            var ewayDetails = [
                { index: 4, isSelected: true, type: DocumentDetailType.ewayBillNo },
                { index: 5, isSelected: true, type: DocumentDetailType.ewayBillDate },
            ]
            ewayDetails.forEach(element => {
                documentDetails.push(element)
            });
        }

        if (getIsInvoice(data.type)) {
            documentDetails.push({ index: documentDetails.length - 1, isSelected: false, type: DocumentDetailType.linkDocumentNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.customerOrderNumber })
        }

        if (getIsSalesOrder(data.type) || getIsQuotation(data.type) || getIsOrder(data.type)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.customerOrderNumber })
        }

        if (Utility.getIsIsraelCountry(data)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxRegistrationNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxPayerId })
        }

        if (getIsQuotation(data.type)) {
            documentDetails.push({ index: documentDetails.length, isSelected: false, type: DocumentDetailType.crm.dealName })
            documentDetails.push({ index: documentDetails.length, isSelected: false, type: DocumentDetailType.crm.dealOwnerName })
        }

        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}


// use by expense, deposit, credit note, debit note
class ExpenseBillDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.additionalDate },
        ]
        if (Utility.getIsIsraelCountry(data)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxRegistrationNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxPayerId })
        }
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class AccountDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class CreditAndDebitNoteDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.additionalDate },
            { index: 3, isSelected: true, type: DocumentDetailType.linkDocumentNumber }
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}


// use by fulfilment, good receipt
class FulfllmentAndGoodsReceiptDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 3, isSelected: true, type: DocumentDetailType.customerOrderNumber },
        ]

        if (getIsGoodsReceipt(data.type) && getIsGoodsReceiptNoteTemplate(data.templateId)) {
            documentDetails = [
                { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
                { index: 1, isSelected: true, type: DocumentDetailType.startDate },
                { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
                { index: 3, isSelected: true, type: DocumentDetailType.linkDocumentDate },
                { index: 4, isSelected: true, type: DocumentDetailType.customerOrderNumber },
            ]
        }

        if (Utility.getIsIsraelCountry(data)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxRegistrationNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxPayerId })
        }
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by make payment and receipt payment
class PaymentDocumentDetailsMapper {

    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.customerOrderNumber },
        ]
        if (Utility.getIsIsraelCountry(data)) {
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxRegistrationNumber })
            documentDetails.push({ index: documentDetails.length, isSelected: true, type: DocumentDetailType.taxPayerId })
        }
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by journal entry
class JournalEntryDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pick list
class PickDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pack list
class PackDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 1, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

// use by pack list
class ShipDocumentDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class WorkOrderDetailsMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            // { index: 1, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: false, type: DocumentDetailType.dueDate },
            { index: 3, isSelected: true, type: DocumentDetailType.workOrder.productName },
            { index: 4, isSelected: false, type: DocumentDetailType.workOrder.woProductDescription },
            { index: 5, isSelected: false, type: DocumentDetailType.workOrder.productCode },
            { index: 6, isSelected: false, type: DocumentDetailType.workOrder.deliveryDate },
            { index: 7, isSelected: false, type: DocumentDetailType.workOrder.estimatedTime },
            { index: 8, isSelected: false, type: DocumentDetailType.workOrder.actualQuantity },
            { index: 9, isSelected: false, type: DocumentDetailType.workOrder.manufactureQuantity },
            { index: 10, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 11, isSelected: true, type: DocumentDetailType.linkDocumentType },
            { index: 12, isSelected: true, type: DocumentDetailType.workOrder.parentWorkOrder },
            { index: 13, isSelected: false, type: DocumentDetailType.workOrder.woStatus },
            { index: 14, isSelected: false, type: DocumentDetailType.workOrder.woQCStatus },
            { index: 15, isSelected: false, type: DocumentDetailType.workOrder.woContactName },
        ]
        return DocumentLabelParser.parseWorkOrderLabel(data, documentDetails)
    }
}

class StockTransferMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.stock.stockTransferCreatedByName },
            { index: 3, isSelected: true, type: DocumentDetailType.stock.warehouse },
            // { index: 3, isSelected: true, type: DocumentDetailType.dueDate },
            // { index: 4, isSelected: true, type: DocumentDetailType.stock.type },
            // { index: 5, isSelected: true, type: DocumentDetailType.stock.reason },
            // { index: 6, isSelected: true, type: DocumentDetailType.stock.warehouse },
        ]
        return DocumentLabelParser.parseStockTransfer(data, documentDetails)
    }
}

class StockAdjustmentMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.stock.type },
            { index: 3, isSelected: true, type: DocumentDetailType.stock.reason },
            { index: 4, isSelected: true, type: DocumentDetailType.stock.warehouse },
        ]
        return DocumentLabelParser.parseStockAdjustment(data, documentDetails)
    }
}

class QCDocumentMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.qcDocument.productCode },
            { index: 1, isSelected: true, type: DocumentDetailType.qcDocument.productName },
            { index: 2, isSelected: true, type: DocumentDetailType.qcDocument.transactionType },
            { index: 3, isSelected: true, type: DocumentDetailType.qcDocument.transactionCode },
            { index: 4, isSelected: true, type: DocumentDetailType.qcDocument.contact },
            { index: 5, isSelected: true, type: DocumentDetailType.qcDocument.status },
            { index: 6, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 7, isSelected: true, type: DocumentDetailType.qcDocument.linkedDocumentCode },
            { index: 8, isSelected: true, type: DocumentDetailType.qcDocument.approver },
            { index: 9, isSelected: true, type: DocumentDetailType.qcDocument.uomName },
        ]
        return DocumentLabelParser.parseQCDocumentLabel(data, documentDetails)
    }
}

class QCInspectionMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.qcInspection.workOrderSeqCode },
            { index: 1, isSelected: false, type: DocumentDetailType.qcInspection.workOrderDate },
            { index: 2, isSelected: false, type: DocumentDetailType.qcInspection.workOrderEndDate },
            { index: 3, isSelected: false, type: DocumentDetailType.qcInspection.workOrderStatus },
            { index: 4, isSelected: true, type: DocumentDetailType.qcInspection.productName },
            { index: 5, isSelected: true, type: DocumentDetailType.qcInspection.productCode },
            { index: 6, isSelected: true, type: DocumentDetailType.qcInspection.productDescription },
        ]
        return DocumentLabelParser.parseQCInspectionLabel(data, documentDetails)
    }
}

class InspectionReportMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.inspectionReport.customerName },
            { index: 1, isSelected: false, type: DocumentDetailType.inspectionReport.customerCode },
            { index: 2, isSelected: true, type: DocumentDetailType.inspectionReport.productName },
            { index: 3, isSelected: false, type: DocumentDetailType.inspectionReport.productCode },
            { index: 4, isSelected: false, type: DocumentDetailType.inspectionReport.productDescription },
            { index: 5, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
        ]
        return DocumentLabelParser.parseInspectionReportLabel(data, documentDetails)
    }
}

class SalesReturnDocumentMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.linkDocumentNumber },
            { index: 3, isSelected: true, type: DocumentDetailType.warehouseName },
            { index: 4, isSelected: true, type: DocumentDetailType.reasonToReturn }
        ]
        return DocumentLabelParser.parseDocumentLabel(data, documentDetails)
    }
}

class JobWorkOutOrderMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.voucherNo },
            { index: 1, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.dated },
            { index: 2, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.orderNo },
            { index: 3, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.termOfPayment },
            { index: 4, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.dispatchDocNo },
            // { index: 5, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.destination },
            { index: 6, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.dispatchStatus },
            // { index: 7, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.dispatchedThrough },
            // { index: 8, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.durationOfProcess },
            // { index: 9, isSelected: true, type: DocumentDetailType.jobWorkOutOrder.natureOfProcessing },
        ]
        return DocumentLabelParser.parseJobWorkOutOrderDocumentLabel(data, documentDetails)
    }
}

// class BomMapper {
//     static getDefaultDocumentDetail(data) {
//         var documentDetails = [
//             { index: 0, isSelected: true, type: DocumentDetailType.startDate },
//             { index: 1, isSelected: true, type: DocumentDetailType.bom.bomProductDescription },
//             { index: 2, isSelected: true, type: DocumentDetailType.bom.bomPartNo },
//             { index: 3, isSelected: true, type: DocumentDetailType.bom.bomFactoryCode },
//             { index: 4, isSelected: true, type: DocumentDetailType.bom.bomEquivalentTo },
//         ]
//         return DocumentLabelParser.parseBomDocumentLabel(data, documentDetails)
//     }
// }

class StockRequestMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.startDate },
            { index: 2, isSelected: true, type: DocumentDetailType.stockRequest.stockRequestStatus },
            { index: 3, isSelected: true, type: DocumentDetailType.stockRequest.stockRequestCreatedByName },
            { index: 4, isSelected: true, type: DocumentDetailType.stockRequest.stockRequestApprovedByName },
            { index: 5, isSelected: true, type: DocumentDetailType.stockRequest.stockRequestApprovedDate },
        ]
        return DocumentLabelParser.parseStockRequest(data, documentDetails)
    }
}

class StockIssueMapper {
    static getDefaultDocumentDetail(data) {
        var documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.stockIssue.stockRequestNo },
            { index: 2, isSelected: true, type: DocumentDetailType.startDate },
            { index: 3, isSelected: true, type: DocumentDetailType.stockIssue.stockIssueCreatedBy },
            { index: 4, isSelected: true, type: DocumentDetailType.stockIssue.stockRequestCreatedBy },
            { index: 5, isSelected: true, type: DocumentDetailType.stockIssue.stockRequestApprovedBy },
            { index: 6, isSelected: false, type: DocumentDetailType.stockIssue.stockIssueWarehouse },
        ]
        return DocumentLabelParser.parseStockIssue(data, documentDetails)
    }
}

class SecurityGateEntryMapper {
    static getDefaultDocumentDetail(data) {
        let documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.refNumber },
            { index: 1, isSelected: true, type: DocumentDetailType.securityGateEntry.date },
            { index: 2, isSelected: true, type: DocumentDetailType.securityGateEntry.transporter },
            { index: 3, isSelected: true, type: DocumentDetailType.securityGateEntry.driverName },
            { index: 4, isSelected: true, type: DocumentDetailType.securityGateEntry.vehicleNumber },
            { index: 5, isSelected: true, type: DocumentDetailType.securityGateEntry.lrNumber },
            { index: 6, isSelected: true, type: DocumentDetailType.securityGateEntry.personName },
            { index: 7, isSelected: true, type: DocumentDetailType.securityGateEntry.contactNumber },
        ]
        return DocumentLabelParser.parseSecurityGateEntry(data, documentDetails)
    }
}

class SOACustomerMapper {
    static getDefaultDocumentDetail(data) {
        let documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.soa.companyRegNo },
            { index: 1, isSelected: true, type: DocumentDetailType.soa.gstRegNo },
            { index: 2, isSelected: true, type: DocumentDetailType.soa.fromDate },
            { index: 3, isSelected: true, type: DocumentDetailType.soa.asOfDate },
            { index: 4, isSelected: true, type: DocumentDetailType.soa.toDate },
        ]
        return DocumentLabelParser.parseSOACustomer(data, documentDetails)
    }
}

class SOAVendorMapper {
    static getDefaultDocumentDetail(data) {
        let documentDetails = [
            { index: 0, isSelected: true, type: DocumentDetailType.soa.companyRegNo },
            { index: 1, isSelected: true, type: DocumentDetailType.soa.gstRegNo },
            { index: 2, isSelected: true, type: DocumentDetailType.soa.fromDate },
            { index: 3, isSelected: true, type: DocumentDetailType.soa.asOfDate },
            { index: 4, isSelected: true, type: DocumentDetailType.soa.toDate },
        ]
        return DocumentLabelParser.parseSOAVendor(data, documentDetails)
    }
}

class DocumentLabelParser {

    static parseDocumentIsSelect(data, documentDetailsType) {
        switch (documentDetailsType) {
            case DocumentDetailType.refNumber:
                return data.showRefNumber
            case DocumentDetailType.startDate:
                return data.showStartDate
            case DocumentDetailType.dueDate:
                return data.showDueDate
            case DocumentDetailType.additionalDate:
                return data.showAdditionalDate
            case DocumentDetailType.ewayBillNo:
                return (data.ewayGenerated && data.ewayBillNo !== undefined)
            case DocumentDetailType.ewayBillDate:
                return (data.ewayGenerated && data.ewayBillDate !== undefined)
            case DocumentDetailType.linkDocumentNumber:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.linkDocumentDate:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.linkDocumentNumberType:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.parentLinkDocumentNumber:
                return Utility.isShowLinkDocument(data)
            case DocumentDetailType.crm.dealName:
            case DocumentDetailType.crm.dealOwnerName:
                return false
            case DocumentDetailType.workOrder.productName:
                return true
            case DocumentDetailType.workOrder.productCode:
                return false
            case DocumentDetailType.workOrder.deliveryDate:
                return false
            case DocumentDetailType.workOrder.estimatedTime:
                return false
            case DocumentDetailType.workOrder.actualQuantity:
                return false
            case DocumentDetailType.workOrder.manufactureQuantity:
                return false
            case DocumentDetailType.workOrder.woProductDescription:
            case DocumentDetailType.workOrder.woStatus:
            case DocumentDetailType.workOrder.woQCStatus:
            case DocumentDetailType.workOrder.woContactName:
                return false
            case DocumentDetailType.customField:
                return true
            case DocumentDetailType.workOrder.parentWorkOrder:
                return false
            case DocumentDetailType.stock.status:
                return true
            case DocumentDetailType.stock.type:
                return true
            case DocumentDetailType.stock.reason:
                return true
            case DocumentDetailType.stock.warehouse:
            case DocumentDetailType.stock.stockTransferCreatedByName:
                return false
            case DocumentDetailType.qcDocument.status:
            case DocumentDetailType.qcDocument.productName:
            case DocumentDetailType.qcDocument.productCode:
            case DocumentDetailType.qcDocument.transactionType:
            case DocumentDetailType.qcDocument.transactionCode:
            case DocumentDetailType.qcDocument.linkedDocumentCode:
            case DocumentDetailType.qcDocument.contact:
            case DocumentDetailType.qcDocument.approver:
            case DocumentDetailType.qcDocument.uomName:
                return true
            case DocumentDetailType.qcInspection.workOrderSeqCode:
            case DocumentDetailType.qcInspection.productName:
                return true
            case DocumentDetailType.customerOrderNumber:
                return Utility.isShowCustomerOrderNumber(data)
            case DocumentDetailType.reasonToReturn:
                return true
            case DocumentDetailType.warehouseName:
                return true
            case DocumentDetailType.jobWorkOutOrder.voucherNo:
            case DocumentDetailType.jobWorkOutOrder.dated:
            case DocumentDetailType.jobWorkOutOrder.orderNo:
            case DocumentDetailType.jobWorkOutOrder.termOfPayment:
            case DocumentDetailType.jobWorkOutOrder.dispatchDocNo:
            case DocumentDetailType.jobWorkOutOrder.destination:
            case DocumentDetailType.jobWorkOutOrder.dispatchStatus:
            case DocumentDetailType.jobWorkOutOrder.dispatchedThrough:
            case DocumentDetailType.jobWorkOutOrder.durationOfProcess:
            case DocumentDetailType.jobWorkOutOrder.natureOfProcessing:
                return true
            case DocumentDetailType.bom.bomProductDescription:
            case DocumentDetailType.bom.bomPartNo:
            case DocumentDetailType.bom.bomFactoryCode:
            case DocumentDetailType.bom.bomEquivalentTo:
                return true
            case DocumentDetailType.stockRequest.stockRequestStatus:
            case DocumentDetailType.stockRequest.stockRequestCreatedByName:
            case DocumentDetailType.stockRequest.stockRequestApprovedByName:
            case DocumentDetailType.stockRequest.stockRequestApprovedDate:
                return true
            case DocumentDetailType.stockIssue.stockRequestNo:
            case DocumentDetailType.stockIssue.stockRequestCreatedBy:
            case DocumentDetailType.stockIssue.stockRequestApprovedBy:
            case DocumentDetailType.stockIssue.stockIssueCreatedBy:
                return true
            case DocumentDetailType.taxRegistrationNumber:
            case DocumentDetailType.taxPayerId:
                return true
            case DocumentDetailType.stockIssue.stockIssueWarehouse:
                return false
			case DocumentDetailType.securityGateEntry.contactNumber:
			case DocumentDetailType.securityGateEntry.date:
			case DocumentDetailType.securityGateEntry.driverName:
			case DocumentDetailType.securityGateEntry.lrNumber:
			case DocumentDetailType.securityGateEntry.transporter:
            case DocumentDetailType.securityGateEntry.vehicleNumber:
            case DocumentDetailType.securityGateEntry.contact:
            case DocumentDetailType.securityGateEntry.personName:
                return true;
            case DocumentDetailType.soa.companyRegNo:
            case DocumentDetailType.soa.gstRegNo:
            case DocumentDetailType.soa.fromDate:
            case DocumentDetailType.soa.asOfDate:
            case DocumentDetailType.soa.toDate:
                return true;
            case DocumentDetailType.inspectionReport.customerName:
            case DocumentDetailType.inspectionReport.productName:
            case DocumentDetailType.inspectionReport.linkDocumentNumber:
                return true;
            case DocumentDetailType.inspectionReport.customerCode:
            case DocumentDetailType.inspectionReport.productCode:
            case DocumentDetailType.inspectionReport.productDescription:
                return false;
            
            default:
                return false
        }
    }

    static parseDocumentKey(documentDetailsType, data) {
        switch (documentDetailsType) {
            case DocumentDetailType.refNumber:
                return 'refNumber'
            case DocumentDetailType.customerOrderNumber:
                return 'customerOrderNumber'
            case DocumentDetailType.startDate:
                return 'date'
            case DocumentDetailType.dueDate:
                return 'dueDate'
            case DocumentDetailType.additionalDate:
                return 'additionalDate'
            case DocumentDetailType.ewayBillNo:
                return 'ewayBillNo'
            case DocumentDetailType.ewayBillDate:
                return 'ewayBillDate'
            case DocumentDetailType.linkDocumentNumber:
                return 'linkDocumentNumber'
            case DocumentDetailType.linkDocumentDate:
                return 'linkDocumentDate'
            case DocumentDetailType.linkDocumentType:
                return 'linkDocumentType'
            case DocumentDetailType.customField:
                return undefined
            case DocumentDetailType.supplierInvoiceNo:
                return 'supplierInvoiceNo'
            case DocumentDetailType.workOrder.productName:
                return 'productName'
            case DocumentDetailType.workOrder.productCode:
                return 'productDocSeqCode'
            case DocumentDetailType.workOrder.deliveryDate:
                return 'deliveryDate'
            case DocumentDetailType.workOrder.estimatedTime:
                return 'estimatedTime'
            case DocumentDetailType.workOrder.actualQuantity:
                return 'actualQuantity'
            case DocumentDetailType.workOrder.manufactureQuantity:
                return 'manufactureQuantity'
            case DocumentDetailType.workOrder.woProductDescription:
                return 'productDescription'
            case DocumentDetailType.workOrder.woStatus:
                return 'woStatus'
            case DocumentDetailType.workOrder.woQCStatus:
                return 'qcStatus'
            case DocumentDetailType.workOrder.woContactName:
                return 'contactName'
            case DocumentDetailType.workOrder.parentWorkOrder:
                return 'parentWorkOrder'
            case DocumentDetailType.parentLinkDocumentNumber:
                return 'parentLinkDocumentNumber'
            case DocumentDetailType.stock.type:
                return 'adjustmentType'
            case DocumentDetailType.stock.reason:
                return 'adjustmentReason'
            case DocumentDetailType.stock.warehouse:
                return 'warehouse'
            case DocumentDetailType.qcDocument.status:
                return 'status'
            case DocumentDetailType.qcDocument.productCode:
                return 'productDocumentSeqCode'
            case DocumentDetailType.qcDocument.productName:
                return 'productName'
            case DocumentDetailType.qcDocument.transactionCode:
                return 'transactionCode'
            case DocumentDetailType.qcDocument.transactionType:
                return 'transactionType'
            case DocumentDetailType.qcDocument.linkedDocumentCode:
                return 'linkedDocumentCode'
            case DocumentDetailType.qcDocument.contact:
                return 'contactName'
            case DocumentDetailType.qcDocument.approver:
                return 'approverName'
            case DocumentDetailType.qcDocument.uomName:
                return 'uomName'
            case DocumentDetailType.reasonToReturn:
                return 'reasonToReturn'
            case DocumentDetailType.warehouseName:
                return 'warehouseName'
            case DocumentDetailType.jobWorkOutOrder.voucherNo:
                return 'voucherNo'
            case DocumentDetailType.jobWorkOutOrder.dated:
                return 'date'
            case DocumentDetailType.jobWorkOutOrder.orderNo:
                return 'orderNo'
            case DocumentDetailType.jobWorkOutOrder.termOfPayment:
                return 'termOfPayment'
            case DocumentDetailType.jobWorkOutOrder.dispatchDocNo:
                return 'dispatchDocNo'
            case DocumentDetailType.jobWorkOutOrder.destination:
                return 'destination'
            case DocumentDetailType.jobWorkOutOrder.dispatchStatus:
                return 'dispatchStatus'
            case DocumentDetailType.jobWorkOutOrder.dispatchedThrough:
                return 'dispatchedThrough'
            case DocumentDetailType.jobWorkOutOrder.durationOfProcess:
                return 'durationOfProcess'
            case DocumentDetailType.jobWorkOutOrder.natureOfProcess:
                return 'natureOfProcess'
            case DocumentDetailType.bom.bomProductDescription:
                return 'productDescription'
            case DocumentDetailType.bom.bomPartNo:
                return 'partNo'
            case DocumentDetailType.bom.bomFactoryCode:
                return 'factoryCode'
            case DocumentDetailType.bom.bomEquivalentTo:
                return 'equivalentTo'
            case DocumentDetailType.stockRequest.stockRequestStatus:
                return 'status'
            case DocumentDetailType.stockRequest.stockRequestCreatedByName:
                return 'createdBy'
            case DocumentDetailType.stockRequest.stockRequestApprovedByName:
                return 'approvedBy'
            case DocumentDetailType.stockRequest.stockRequestApprovedDate:
                return 'approvedDate'
            case DocumentDetailType.stockIssue.stockRequestNo:
                return 'stockRequestNo'
            case DocumentDetailType.stockIssue.stockIssueCreatedBy:
                return 'createdBy'
            case DocumentDetailType.stockIssue.stockRequestCreatedBy:
                return 'stockRequestCreatedBy'
            case DocumentDetailType.stockIssue.stockRequestApprovedBy:
                return 'stockRequestApprovedBy'
            case DocumentDetailType.taxRegistrationNumber:
                return 'taxRegistrationNumber'
            case DocumentDetailType.taxPayerId:
                return 'taxPayerId'
            case DocumentDetailType.stock.stockTransferCreatedByName:
                return 'createdBy'
            case DocumentDetailType.stockIssue.stockIssueWarehouse:
                return 'srcWarehouse'
            case DocumentDetailType.purchaseRequisition.authorizedDate:
                return 'approvedDate'
            case DocumentDetailType.purchaseRequisition.requestedBy:
                return 'requisitionCreatedBy'
			case DocumentDetailType.securityGateEntry.contactNumber:
				return DocumentDetailType.securityGateEntry.contactNumber;
			case DocumentDetailType.securityGateEntry.date:
				return DocumentDetailType.securityGateEntry.date;
			case DocumentDetailType.securityGateEntry.driverName:
				return DocumentDetailType.securityGateEntry.driverName;
			case DocumentDetailType.securityGateEntry.lrNumber:
				return DocumentDetailType.securityGateEntry.lrNumber;
			case DocumentDetailType.securityGateEntry.transporter:
				return DocumentDetailType.securityGateEntry.transporter;
            case DocumentDetailType.securityGateEntry.vehicleNumber:
				return DocumentDetailType.securityGateEntry.vehicleNumber;
            case DocumentDetailType.securityGateEntry.personName:
				return DocumentDetailType.securityGateEntry.personName;
            case DocumentDetailType.securityGateEntry.contact:
                return DocumentDetailType.securityGateEntry.contact;
            case DocumentDetailType.serviceOrder.gstNo:
                return 'gstNo'
            case DocumentDetailType.serviceOrder.panNo:
                return 'panNo'
            case DocumentDetailType.serviceOrder.cinNo:
                return 'cinNo'
            case DocumentDetailType.qcInspection.workOrderSeqCode:
                return 'woCode'
            case DocumentDetailType.qcInspection.workOrderDate:
                return 'woDate'
            case DocumentDetailType.qcInspection.workOrderEndDate:
                return 'woEndDate'
            case DocumentDetailType.qcInspection.workOrderStatus:
                return 'woStatus'
            case DocumentDetailType.qcInspection.productCode:
                return 'productCode'
            case DocumentDetailType.qcInspection.productName:
                return 'productName'
            case DocumentDetailType.qcInspection.productDescription:
                return 'productDescription'
            case DocumentDetailType.soa.companyRegNo:
                return 'companyRegNo'
            case DocumentDetailType.soa.gstRegNo:
                return 'gstRegNo'
            case DocumentDetailType.soa.fromDate:
                return 'fromDate'
            case DocumentDetailType.soa.asOfDate:
                return 'asOfDate'
            case DocumentDetailType.soa.toDate:
                return 'toDate'
            case DocumentDetailType.piq.rfqSeqCode:
                return 'rfqSeqCode'
            case DocumentDetailType.inspectionReport.customerName:
                return 'contactName'
            case DocumentDetailType.inspectionReport.customerCode:
                return 'contactCode'
            case DocumentDetailType.inspectionReport.productName:
                return 'productName'
            case DocumentDetailType.inspectionReport.productCode:
                return 'productCode'
            case DocumentDetailType.inspectionReport.productDescription:
                return 'productDescription'
            case DocumentDetailType.crm.dealName:
                return 'dealName'
            case DocumentDetailType.crm.dealOwnerName:
                return 'dealOwnerName'
            default:
                return undefined
        }
    }

    static parseDocumentLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByTemplate(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByTemplate(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        var defaultLabelSetting1 = [1, 2, 36, 41, 42, 43, 44, 45, 46, 48, 49, 51, 55, 56, 58, 59, 60, 61, 62, 63]
        var defaultLabelSetting2 = [3, 4, 5, 6, 7, 8, 9, 10, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 31, 32, 37, 38, 39, 40, 47, 50]
        var defaultLabelSetting3 = [11, 12, 15, 25, 30, 33, 34, 35]
        var defaultLabelSetting4 = [13]

        if (defaultLabelSetting1.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.linkDocumentDate, label: AppManager.getTypeDateString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
                { type: DocumentDetailType.warehouseName, label: getLocalisedText("warehouse") },
                { type: DocumentDetailType.reasonToReturn, label: getLocalisedText("reason") },
                { type: DocumentDetailType.taxRegistrationNumber, label: getLocalisedText("tax_registration_number") },
                { type: DocumentDetailType.taxPayerId, label: getLocalisedText("tax_payer_id") },
                { type: DocumentDetailType.crm.dealName, label: getLocalisedText("deal_name") },
                { type: DocumentDetailType.crm.dealOwnerName, label: getLocalisedText("deal_owner") },
            ]
        }
        else if (defaultLabelSetting2.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
                { type: DocumentDetailType.warehouseName, label: getLocalisedText("warehouse") },
                { type: DocumentDetailType.reasonToReturn, label: getLocalisedText("reason") },
                { type: DocumentDetailType.taxRegistrationNumber, label: getLocalisedText("tax_registration_number") },
                { type: DocumentDetailType.taxPayerId, label: getLocalisedText("tax_payer_id") },
                { type: DocumentDetailType.crm.dealName, label: getLocalisedText("deal_name") },
                { type: DocumentDetailType.crm.dealOwnerName, label: getLocalisedText("deal_owner") },
            ]
        }
        else if (defaultLabelSetting3.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('date') },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
                { type: DocumentDetailType.warehouseName, label: getLocalisedText("warehouse") },
                { type: DocumentDetailType.reasonToReturn, label: getLocalisedText("reason") },
                { type: DocumentDetailType.taxRegistrationNumber, label: getLocalisedText("tax_registration_number") },
                { type: DocumentDetailType.taxPayerId, label: getLocalisedText("tax_payer_id") },
                { type: DocumentDetailType.crm.dealName, label: getLocalisedText("deal_name") },
                { type: DocumentDetailType.crm.dealOwnerName, label: getLocalisedText("deal_owner") },
            ]
        }
        else if (defaultLabelSetting4.includes(Number(data.templateId))) {
            return [
                { type: DocumentDetailType.refNumber, label: getCapitalized(getLocalisedText(data.documentType)) + " " + getCapitalized(getLocalisedText('number')) + " :", },
                { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
                { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
                { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
                { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
                { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
                { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
                { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText("con_number") },
                { type: DocumentDetailType.warehouseName, label: getLocalisedText("warehouse") },
                { type: DocumentDetailType.reasonToReturn, label: getLocalisedText("reason") },
                { type: DocumentDetailType.taxRegistrationNumber, label: getLocalisedText("tax_registration_number") },
                { type: DocumentDetailType.taxPayerId, label: getLocalisedText("tax_payer_id") },
                { type: DocumentDetailType.crm.dealName, label: getLocalisedText("deal_name") },
                { type: DocumentDetailType.crm.dealOwnerName, label: getLocalisedText("deal_owner") },
            ]
        }
        else {
            return undefined
        }
        // switch (Number(data.templateId)) {
        //     case 1 || 2 || 36:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType)},
        //         ]
        //     case 3 || 4 || 5 || 6 || 7 || 8 || 9 || 10 || 14 || 16 || 17 || 18 || 19 || 20 || 21 || 22 || 23 || 24 || 26 || 27 || 28 || 29 || 31 || 32 || 37 || 38 || 39 || 40:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     case 11 || 12 || 15 || 25 || 30 || 33 || 34 || 35 :
        //         return [
        //             { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
        //             { type: DocumentDetailType.startDate, label: getLocalisedText('date')},
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     case 13:
        //         return [
        //             { type: DocumentDetailType.refNumber, label: getCapitalized(getLocalisedText(data.documentType)) + " " + getCapitalized(getLocalisedText('number')) + " :", },
        //             { type: DocumentDetailType.startDate, label: ComponentManager.getStartDateLabel(data.documentType) },
        //             { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
        //             { type: DocumentDetailType.additionalDate, label: ComponentManager.getAdditionalDateLabel(data.documentType) },
        //             { type: DocumentDetailType.ewayBillNo, label: getLocalisedText('eway_bill_no') },
        //             { type: DocumentDetailType.ewayBillDate, label: getLocalisedText('eway_bill_date') },
        //             { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        //         ]
        //     default:
        //         console.log(data.templateId)
        //         return undefined
        // }
    }


    static parseWorkOrderLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByWorkOrder(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByWorkOrder(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.linkDocumentNumber, label: getLocalisedText('parent_linked_document_no') },
            { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
            { type: DocumentDetailType.dueDate, label: ComponentManager.getEndDateLabel(data.documentType) },
            { type: DocumentDetailType.workOrder.productName, label: getLocalisedText('product_name') },
            { type: DocumentDetailType.workOrder.woProductDescription, label: getLocalisedText('product_description') },
            { type: DocumentDetailType.workOrder.productCode, label: getLocalisedText('product_code') },
            { type: DocumentDetailType.workOrder.deliveryDate, label: getLocalisedText('delivery_date') },
            { type: DocumentDetailType.workOrder.estimatedTime, label: getLocalisedText('estimated_time') },
            { type: DocumentDetailType.workOrder.actualQuantity, label: getLocalisedText('actual_quantity') },
            { type: DocumentDetailType.workOrder.manufactureQuantity, label: getLocalisedText('manufacture_quantity') },
            { type: DocumentDetailType.linkDocumentType, label: getLocalisedText('parent_linked_document_type') },
            { type: DocumentDetailType.workOrder.parentWorkOrder, label: getLocalisedText('parent_work_order') },
            { type: DocumentDetailType.linkDocumentNumber, label: getLocalisedText('link_document') },
            { type: DocumentDetailType.customerOrderNumber, label: getLocalisedText('con_number') },
            { type: DocumentDetailType.workOrder.woStatus, label: getLocalisedText('status') },
            { type: DocumentDetailType.workOrder.woQCStatus, label: getLocalisedText('qc_status') },
            { type: DocumentDetailType.workOrder.woContactName, label: getLocalisedText('contact_name') },
        ]
    }

    static parseStockTransfer(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockTransfer(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockTransfer(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('transfer_date') },
                { type: DocumentDetailType.dueDate, label: getLocalisedText('verify_date') },
                { type: DocumentDetailType.stock.status, label: getLocalisedText('status') },
                { type: DocumentDetailType.stock.type, label: getLocalisedText('type') },
                { type: DocumentDetailType.stock.warehouse, label: getLocalisedText('warehouse') },
                { type: DocumentDetailType.stock.reason, label: getLocalisedText('reason') },
                { type: DocumentDetailType.stock.stockTransferCreatedByName, label: getLocalisedText('person_name') },
            ]
    }

    static parseStockAdjustment(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockAdjustment(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockAdjustment(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
                { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
                { type: DocumentDetailType.startDate, label: getLocalisedText('transfer_date') },
                { type: DocumentDetailType.stock.status, label: getLocalisedText('status') },
                { type: DocumentDetailType.stock.type, label: getLocalisedText('type') },
                { type: DocumentDetailType.stock.warehouse, label: getLocalisedText('warehouse') },
                { type: DocumentDetailType.stock.reason, label: getLocalisedText('reason') },
            ]
    }

    static parseQCDocumentLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByQCDocument(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByQCDocument(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.qcDocument.productCode, label: getLocalisedText('product_code') },
            { type: DocumentDetailType.qcDocument.productName, label: getLocalisedText('product_name') },
            { type: DocumentDetailType.qcDocument.transactionType, label: getLocalisedText('transaction_type') },
            { type: DocumentDetailType.qcDocument.transactionCode, label: getLocalisedText('transaction_no') },
            { type: DocumentDetailType.qcDocument.contact, label: getLocalisedText('contact') },
            { type: DocumentDetailType.qcDocument.status, label: getLocalisedText('status') },
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.qcDocument.linkedDocumentCode, label: getLocalisedText('linked_po_so') },
            { type: DocumentDetailType.qcDocument.approver, label: getLocalisedText('approver') },
            { type: DocumentDetailType.qcDocument.uomName, label: getLocalisedText('uom') },
        ]
    }

    static parseQCInspectionLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByQCInspection(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByQCInspection(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.qcInspection.workOrderSeqCode, label: getLocalisedText('work_order_no') },
            { type: DocumentDetailType.qcInspection.workOrderDate, label: getLocalisedText('work_order_date') },
            { type: DocumentDetailType.qcInspection.workOrderEndDate, label: getLocalisedText('work_order_due_date') },
            { type: DocumentDetailType.qcInspection.workOrderStatus, label: getLocalisedText('work_order_status') },
            { type: DocumentDetailType.qcInspection.productCode, label: getLocalisedText('product_code') },
            { type: DocumentDetailType.qcInspection.productName, label: getLocalisedText('product_name') },
            { type: DocumentDetailType.qcInspection.productDescription, label: getLocalisedText('product_description') },
        ]
    }

    static parseInspectionReportLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByInspectionReport(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByInspectionReport(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.inspectionReport.customerName, label: getLocalisedText('contact_name') },
            { type: DocumentDetailType.inspectionReport.customerCode, label: getLocalisedText('contact_code') },
            { type: DocumentDetailType.inspectionReport.productName, label: getLocalisedText('product_name') },
            { type: DocumentDetailType.inspectionReport.productCode, label: getLocalisedText('product_code') },
            { type: DocumentDetailType.inspectionReport.productDescription, label: getLocalisedText('product_description') },
            { type: DocumentDetailType.linkDocumentNumber, label: AppManager.getTypeNoString(data.linkDocumentType) },
        ]
    }

    static parseJobWorkOutOrderDocumentLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByJobWorkOutOrderDocument(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByJobWorkOutOrderDocument(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.jobWorkOutOrder.voucherNo, label: getLocalisedText('voucher_no') },
            { type: DocumentDetailType.jobWorkOutOrder.dated, label: getLocalisedText('dated') },
            { type: DocumentDetailType.jobWorkOutOrder.orderNo, label: getLocalisedText('supplier_ref_order_no') },
            { type: DocumentDetailType.jobWorkOutOrder.termOfPayment, label: getLocalisedText('mode_term_of_payment') },
            { type: DocumentDetailType.jobWorkOutOrder.dispatchDocNo, label: getLocalisedText('dispatch_doc_no') },
            { type: DocumentDetailType.jobWorkOutOrder.destination, label: getLocalisedText('destination') },
            { type: DocumentDetailType.jobWorkOutOrder.dispatchStatus, label: getLocalisedText('status') },
            { type: DocumentDetailType.jobWorkOutOrder.dispatchedThrough, label: getLocalisedText('dispatched_through') },
            { type: DocumentDetailType.jobWorkOutOrder.durationOfProcess, label: getLocalisedText('duration_of_process') },
            { type: DocumentDetailType.jobWorkOutOrder.natureOfProcessing, label: getLocalisedText('nature_of_processing') },
        ]
    }

    static parseBomDocumentLabel(data, defaultList) {
        var templateLabel = this.getDocumentLabelByBomDocument(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type, data)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByBomDocument(data) {
        if (data === undefined || data === null) {
            return undefined
        }

        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.startDate, label: getLocalisedText('date_') },
            { type: DocumentDetailType.bom.bomProductDescription, label: getLocalisedText('product_description') },
            { type: DocumentDetailType.bom.bomPartNo, label: getLocalisedText('part_no') },
            { type: DocumentDetailType.bom.bomFactoryCode, label: getLocalisedText('factory_code') },
            { type: DocumentDetailType.bom.bomEquivalentTo, label: getLocalisedText('equivalent_to') },
        ]
    }


    static parseStockRequest(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockRequest(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockRequest(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
            { type: DocumentDetailType.stockRequest.stockRequestStatus, label: getLocalisedText('status') },
            { type: DocumentDetailType.stockRequest.stockRequestCreatedByName, label: getLocalisedText('created_by') },
            { type: DocumentDetailType.stockRequest.stockRequestApprovedByName, label: getLocalisedText('approved_by') },
            { type: DocumentDetailType.stockRequest.stockRequestApprovedDate, label: getLocalisedText('approved_date') },
        ]
    }

    static parseStockIssue(data, defaultList) {
        var templateLabel = this.getDocumentLabelByStockIssue(data)
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    var label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelByStockIssue(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.stockIssue.stockRequestNo, label: AppManager.getTypeNoString('stock_request') },
            { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
            { type: DocumentDetailType.stockIssue.stockIssueCreatedBy, label: getLocalisedText(data.documentType) + ' ' + getLocalisedText('created_by') },
            { type: DocumentDetailType.stockIssue.stockRequestCreatedBy, label: getLocalisedText('created_by') },
            { type: DocumentDetailType.stockIssue.stockRequestApprovedBy, label: getLocalisedText('approved_by') },
            { type: DocumentDetailType.stockIssue.stockIssueWarehouse, label: getLocalisedText('warehouse') },
        ]
    }

    static parseSecurityGateEntry(data, defaultList) {
        const templateLabel = this.getDocumentLabelBySecurityGateEntry(data);
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    const label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelBySecurityGateEntry(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.securityGateEntry.contactNumber, label: getLocalisedText('contact_no') },
            { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
            { type: DocumentDetailType.securityGateEntry.date, label: getLocalisedText('document_date') },
            { type: DocumentDetailType.securityGateEntry.driverName, label: getLocalisedText('driver_name') },
            { type: DocumentDetailType.securityGateEntry.transporter, label: getLocalisedText('transporter') },
            { type: DocumentDetailType.securityGateEntry.vehicleNumber, label:getLocalisedText('vehicle_no') },
            { type: DocumentDetailType.securityGateEntry.lrNumber, label: getLocalisedText('lr_no')},
            { type: DocumentDetailType.securityGateEntry.personName, label: getLocalisedText('person_name') },
        ]
    }


    static parseSOACustomer(data, defaultList) {
        const templateLabel = this.getDocumentLabelBySOACustomer(data);
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    const label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelBySOACustomer(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.refNumber, label: AppManager.getTypeNoString(data.documentType) },
            { type: DocumentDetailType.securityGateEntry.contactNumber, label: getLocalisedText('contact_no') },
            { type: DocumentDetailType.startDate, label: AppManager.getTypeDateString(data.documentType) },
            { type: DocumentDetailType.securityGateEntry.date, label: getLocalisedText('document_date') },
            { type: DocumentDetailType.securityGateEntry.driverName, label: getLocalisedText('driver_name') },
            { type: DocumentDetailType.securityGateEntry.transporter, label: getLocalisedText('transporter') },
            { type: DocumentDetailType.securityGateEntry.vehicleNumber, label:getLocalisedText('vehicle_no') },
            { type: DocumentDetailType.securityGateEntry.lrNumber, label: getLocalisedText('lr_no')},
            { type: DocumentDetailType.securityGateEntry.personName, label: getLocalisedText('person_name') },
            { type: DocumentDetailType.soa.companyRegNo, label: getLocalisedText('company_reg_no') },
            { type: DocumentDetailType.soa.gstRegNo, label: getLocalisedText('gst_reg_no') },
            { type: DocumentDetailType.soa.fromDate, label: getLocalisedText('from_date') },
            { type: DocumentDetailType.soa.asOfDate, label: getLocalisedText('as_of_date') },
            { type: DocumentDetailType.soa.toDate, label: getLocalisedText('to_date') },
        ]
    }

    static parseSOAVendor(data, defaultList) {
        const templateLabel = this.getDocumentLabelBySOAVendor(data);
        if (defaultList.length > 0) {
            if (templateLabel !== undefined) {
                defaultList.forEach(element => {
                    const label = templateLabel.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                        element.isSelected = this.parseDocumentIsSelect(data, element.type)
                        element.key = this.parseDocumentKey(element.type)
                    }
                });
                return defaultList
            }
        }

        return []
    }

    static getDocumentLabelBySOAVendor(data) {
        if (data === undefined || data === null) {
            return undefined
        }
        if (data.templateId === undefined || data.templateId === null) {
            return undefined
        }
        return [
            { type: DocumentDetailType.soa.companyRegNo, label: getLocalisedText('company_reg_no') },
            { type: DocumentDetailType.soa.gstRegNo, label: getLocalisedText('gst_reg_no') },
            { type: DocumentDetailType.soa.fromDate, label: getLocalisedText('from_date') },
            { type: DocumentDetailType.soa.asOfDate, label: getLocalisedText('as_of_date') },
            { type: DocumentDetailType.soa.toDate, label: getLocalisedText('to_date') },
        ]
    }

}

