import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TenantService from '../../../Services/Tenant';
import AppManager from '../../../Managers/AppManager';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import { DKLabel, DKButton, DKSpinner, showAlert } from 'deskera-ui-library';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import { useAppSelector } from '../../../Redux/Hooks';
import MultiEmailInputField from '../../../SharedComponents/Email/MultiEmailInputField';

interface InitialFormState {
  to?: string[];
  cc?: string[];
}

interface JobCardsEmailSettingsPayload {
  additionalSettings: {
    JOB_CARDS_EMAIL_SETTINGS: {
      to?: string[];
      cc?: string[];
    };
  };
}

const initialFormState: InitialFormState = {
  to: [],
  cc: []
};

const JobCardsEmailSettings: FC<any> = (props) => {
  const [formData, setFormData] = useState({ ...initialFormState });
  const dispatch = useDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);

  const [isSaving, setIsSaving] = useState(false);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  useEffect(() => {
    populateFormData();
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const populateFormData = () => {
    const jobCardSettingsData =
      tenantDetails?.additionalSettings?.JOB_CARDS_EMAIL_SETTINGS;

    if (jobCardSettingsData) {
      formData.to = jobCardSettingsData.to;
      formData.cc = jobCardSettingsData.cc;
      setFormData({ ...formData });
    }
  };

  const onDataChange = (key: any, value: string[]) => {
    if (key === 'to') {
      formData.to = value;
    } else {
      formData.cc = value;
    }
    setFormData({ ...formData });
  };

  const getValue = (key: any) => {
    if (key === 'to') {
      return formData?.to || [];
    } else {
      return formData?.cc || [];
    }
  };

  function checkIfDuplicateExists(emails: any) {
    return new Set(emails).size !== emails.length;
  }

  const save = async () => {
    const jobCardSettingsData =
      tenantDetails?.additionalSettings?.JOB_CARDS_EMAIL_SETTINGS;
    const previousToEmails = jobCardSettingsData?.to || [];
    const previousCCEmails = jobCardSettingsData?.cc || [];
    const toEmails = formData?.to || [];
    const ccEmails = formData?.cc || [];
    if (
      previousToEmails?.length === 0 &&
      previousCCEmails?.length === 0 &&
      toEmails?.length === 0 &&
      ccEmails?.length === 0
    ) {
      showAlert('Alert', "Please enter email id's.");
    } else if (toEmails?.length > 0 && checkIfDuplicateExists(toEmails)) {
      showAlert('Alert', "Duplicate email id's are not allowed in 'To' field.");
    } else if (ccEmails?.length > 0 && checkIfDuplicateExists(ccEmails)) {
      showAlert('Alert', "Duplicate email id's are not allowed in 'CC' field.");
    } else {
      setIsSaving(true);
      const payload: JobCardsEmailSettingsPayload = {
        additionalSettings: {
          JOB_CARDS_EMAIL_SETTINGS: {
            to: toEmails,
            cc: ccEmails
          }
        }
      };

      try {
        await TenantService.updateOrgSetting(payload);
        await dispatch(fetchCurrentTenantInfo());
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        console.log(error);
      }
    }
  };

  const renderMultiEmailInputField = (title: any, valueKey: any) => {
    return (
      <div className="flex parent-width pt-l flex-row items-center">
        <DKLabel text={title} className="text-wrap-none text-gray mr-r" />
        <div className="w-full">
          <MultiEmailInputField
            onValueChange={(value: string[], hasError: boolean) => {
              onDataChange(valueKey, value);
            }}
            value={getValue(valueKey)}
            isMandatory={false}
            placeHolder="Email id's"
          />
        </div>
      </div>
    );
  };

  const renderActionView = () => {
    return (
      <div className="column mt-xl">
        <div
          className={`row width-auto ${
            isSaving ? 'border-radius-m border-m' : ''
          }`}
        >
          <DKButton
            title={isSaving ? 'Saving...' : 'Save'}
            onClick={() => {
              save();
            }}
            disabled={isSaving}
            className={`${
              isSaving ? 'border-radius-none text-gray' : 'bg-button text-white'
            }`}
          />
          {isSaving && (
            <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="column parent-size pt-r pb-l overflow-y-auto hide-scroll-bar flex-1">
      {renderMultiEmailInputField('To ', 'to')}
      {renderMultiEmailInputField('CC', 'cc')}
      {renderActionView()}
    </div>
  );
};

export default JobCardsEmailSettings;
