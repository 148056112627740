import React, { useEffect } from 'react';
import ic_onboarding from '../../Assets/new_onboarding_image.svg';
import './Onboarding.scss';
import { DKIcons, DKIcon, showLoader, removeLoader } from 'deskera-ui-library';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { US_TIMEZONE } from '../../Constants/StaticData';
import { TAX_SYSTEM } from '../../Constants/Constant';
import TenantService from '../../Services/Tenant';
import IamService from '../../Services/iam';
import AppManager from '../../Managers/AppManager';

const Onboarding: React.FC = (props) => {
  useEffect(() => {
    RouteManager.setPresenter({ props });
    initialApiCalls();
    return () => {
      // cleanup
    };
  }, []);

  const initialApiCalls = async () => {
    try {
      await TenantService.subscriptionRegistration();
      try {
        await TenantService.startTrialPeriodOnboarding();
      } catch (error) {
        console.log('trial period error', error);
      }
    } catch (error) {
      console.log('subscription registration error', error);
    }
  };

  const navigateToGettingStarted = () => {
    RouteManager.navigateToPage(PAGE_ROUTES.GETTING_STARTED);
  };

  const createDemoOrg = () => {
    showLoader();

    const date = Date();
    const country = US_TIMEZONE.some((el) => date.includes(el))
      ? TAX_SYSTEM.US
      : TAX_SYSTEM.SG;
    TenantService.createDemoOrg(country).then(
      (selectedTenant: any) => {
        /*
         * Switch tenant to demo app before reloading
         *
         * */
        const tenantId = selectedTenant.tenantId;
        IamService.switchTenant({ tenantId: tenantId }).then(
          (res) => {
            removeLoader();
            setTimeout(() => {
              AppManager.refreshApp();
            }, 1000);
          },
          (err) => {
            removeLoader();
            console.log('Error occured while switching tenant', err);
          }
        );
      },
      (error) => {
        removeLoader();
        console.log('Error occured while creating demo org', error);
      }
    );
  };
  return (
    <div
      className="w-full h-screen flex"
      style={{ backgroundColor: 'rgb(245, 247, 248)' }}
    >
      <div className="w-1/4 bg-app relative" style={{ padding: '8% 0% 0% 3%' }}>
        <div className="text-4xl font-bold text-white">
          A few clicks away from creating your company
        </div>
        <div className="text-sm font-normal text-white mt-6">
          Start your company in minutes
        </div>
        <div className="text-sm font-normal text-white mt-1">
          Save time and money
        </div>
        <div className="absolute right-0 bottom-0 w-72 h-72">
          <img
            className="w-full h-full"
            src={ic_onboarding}
            alt="onboarding_img"
          />
        </div>
      </div>
      <div className="w-3/4 parent-height d-flex column align-items-center justify-content-center">
        <div className="font-bold text-gray-800 text-3xl">
          Choose how you want to start your journey
        </div>
        <div className="font-semibold text-sm text-gray-700 mt-8">
          Creating your company in Deskera is just few steps away
        </div>
        <div className="font-semibold text-sm text-gray-700 mt-2 mb-14">
          You can start by setting up your own company or try our demo account
        </div>

        <div
          className="onboarding-card border-m flex justify-between items-center"
          onClick={createDemoOrg}
        >
          <div>
            <div className="text-lg font-semibold">
              Explore with demo company
            </div>
            <div className="text-sm font-normal">
              If you are not ready to start setting up, you can try our features
              first
            </div>
          </div>
          <div>
            <DKIcon src={DKIcons.ic_arrow_right} className="ic-s arrow" />
          </div>
        </div>

        <div
          className="onboarding-card border-m flex justify-between items-center"
          onClick={navigateToGettingStarted}
        >
          <div>
            <div className="text-lg font-semibold">Set up your company</div>
            <div className="text-sm font-normal">
              Set up your organization and start tracking your finances in
              minutes with our easy-to-use App
            </div>
          </div>
          <div>
            <DKIcon src={DKIcons.ic_arrow_right} className="ic-s arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
