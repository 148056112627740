import ApiConstants from '../Constants/ApiConstants';
import { PRODUCTS_CAPS } from '../Constants/Enum';
import http from './Interceptor';

export interface CustomNumberFormatAPIConfig {
  SearchTerm?: string;
  pageSize?: number;
  pageNo?: number;
}

const defaultConfig: CustomNumberFormatAPIConfig = {
  SearchTerm: '',
  pageSize: 10,
  pageNo: 1
};

class CustomNumberFormatService {
  static apiConfig: CustomNumberFormatAPIConfig;

  static createCustomNumberFormat(payload: any) {
    return http
      .post(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.SAVE}`, payload)
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static toggleIsDefault(payload: any) {
    return http
      .put(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.TOGGLE_DEFAULT}`, payload)
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static deleteCustomFormatNumber(payload: any) {
    let data: any = payload;
    return http
      .delete(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.DELETE}`, { data })
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static changeCustomNumberFormat(payload: any) {
    return http
      .put(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.ALTER}`, payload)
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static getCustomNumberFormat(module: string) {
    return http
      .post(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.GET_FORMAT}`, {
        module: module,
        application: PRODUCTS_CAPS.ERP
      })
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static getCustomNumberFormatAll(module: string[]) {
    return http
      .post(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.GET_FORMAT_ALL}`, {
        application: PRODUCTS_CAPS.ERP,
        modules: module
      })
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static checkNumberFormatExistance(
    format: string,
    module: string
  ): Promise<any> {
    return http
      .post(`${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.DOES_EXIST}`, {
        module: module,
        application: PRODUCTS_CAPS.ERP,
        code: format
      })
      .catch((err: any) => {
        console.error('Error creating Custom Number Format: ', err);
        return Promise.reject(err);
      });
  }

  static updateSeparateSequenceFormat(
    useSeparateSequenceFormat: any
  ): Promise<any> {
    return http
      .patch(
        `${ApiConstants.URL.CUSTOM_NUMBER_FORMAT.UPDATE_SEPARATE_SEQUENCE_FORMAT}`,
        {
          useSeparateSequenceFormat
        }
      )
      .catch((err: any) => {
        console.error('Error updating separate sequence format: ', err);
        return Promise.reject(err);
      });
  }
}

export default CustomNumberFormatService;
