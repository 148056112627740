import { truncateSync } from 'fs';
import ApiConstants from '../Constants/ApiConstants';
import { Returned } from '../Models/SalesReturned';
import Utility from '../Utility/Utility';
import http2 from './Interceptor2';
class SalesReturnService {
  static isFullyReturned(documentCode: any, documentType: any) {
    return http2.get(
      `${ApiConstants.URL.RETURNS.DETAILS(documentCode, documentType, true)}`
    );
  }
  static checkFulfillment(documentCode: any) {
    return http2.get(
      `${ApiConstants.URL.RETURNS.CHECK_FULFILLMENTS(documentCode)}`
    );
  }
  static saveSalesReturn(returned: Returned) {
    return http2.post(ApiConstants.URL.RETURNS.CREATE_RETURN, returned);
  }

  static deleteSalesReturn(code: any) {
    return http2.delete(ApiConstants.URL.RETURNS.DELETE_SALES_RETURNS(code));
  }
  static getAdvanceTrackingReturnData(code: any, docType: any) {
    return http2.post(
      `${ApiConstants.URL.RETURNS.GET_ADVANCE_TRACKING(code, docType, true)}`
    );
  }
  static getAdvanceTrackingRecord(code: any) {
    return http2.get(
      `${ApiConstants.URL.RETURNS.GET_ADVANCE_RECORD}?limit=100&page=0&sort=createdAt&transactionRefCode=${code}`
    );
  }
  static getReasons() {
    return http2.get(ApiConstants.URL.RETURNS.GET_REASONS);
  }
}
export default SalesReturnService;
