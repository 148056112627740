import { IState } from '../../../Models/Interfaces';
import { INPUT_TYPE } from 'deskera-ui-library';

export const initialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export const CLASS = 'CLASS';
export const CLASS_TITLE = 'Class';
export const SELECT_LABEL = 'None';

export const initialFormState = {
  name: {
    value: '',
    hasError: false,
    errorMsg: ''
  },
  parentClass: { label: '', value: '' }
};

export enum CLASSES_COLUMN_CODE {
  NAME = 'label'
}

export const ClassesColumnConfig = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    options: null,
    required: false,
    width: 500,
    editable: false,
    columnCode: CLASSES_COLUMN_CODE.NAME,
    key: CLASSES_COLUMN_CODE.NAME,
    id: CLASSES_COLUMN_CODE.NAME
  }
];
