import { DKTooltipWrapper, DKButton, showToast } from 'deskera-ui-library';

const ReCheckButton = (props:any) => {
  return (
    <DKTooltipWrapper
      content="You can recheck if your contact is in the denied list or not"
      tooltipClassName="w-64 whitespace-normal m-2"
    >
      <DKButton
        className="bg-button text-white mt-2"
        onClick={() => {
          props?.onClick()
        }}
        title={'ReCheck'}
      />
    </DKTooltipWrapper>
  );
};

export default ReCheckButton;
