import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import AdditionalChargeService from '../../Services/AdditionalCharges';
import { RootState } from '../Store';
import Utility from '../../Utility/Utility';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  accountGroupData: {} as any
};

export const fetchAllAdditionalCharge = createAsyncThunk(
  'allAdditionalCharge',
  async () => {
    let response = await AdditionalChargeService.fetchAllAdditionalCharge();
    return response;
  }
);

export const AdditionalChargesSlice = createSlice({
  name: 'Additional Charge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllAdditionalCharge.pending, (state) => {
      state.status = API_STATUS.LOADING;
    });
    builder.addCase(fetchAllAdditionalCharge.fulfilled, (state, action) => {
      state.status = API_STATUS.IDLE;
      state.data.all = action.payload;
      let chargeList: any = action.payload;
      const sellSideTaxList = chargeList.filter(
        (sellSideCharge: any) =>
          sellSideCharge.applyTo === 'SELL' || sellSideCharge.applyTo === 'BOTH'
      );
      const buySideChargeList = chargeList.filter(
        (buySideCharge: any) =>
          buySideCharge.applyTo === 'BUY' || buySideCharge.applyTo === 'BOTH'
      );

      state.data.allBuyCharges = buySideChargeList;
      state.data.allSellCharges = sellSideTaxList;

      state.data.buyCharges = (buySideChargeList || []).filter(
        (buyItem: any) => buyItem.active
      );
      state.data.sellCharges = (sellSideTaxList || []).filter(
        (sellItem: any) => sellItem.active
      );
    });
  }
});

export const selectedAdditionalCharges = (state: RootState) => {
  const allCharges = state.additionalCharges?.data?.all;
  if (!Utility.isEmpty(allCharges) && allCharges?.length > 0) {
    return allCharges?.filter((charge: any) => charge.active);
  } else {
    return [];
  }
};
export const selectAdditionalBuyCharges = (state: RootState) =>
  state.additionalCharges.data.buyCharges;
export const selectAddtionalSellCharges = (state: RootState) =>
  state.additionalCharges.data.sellCharges;
export const selectedCascadingDiscounts = (state: RootState) => {
  const allCharges = state.additionalCharges?.data?.all;
  if (!Utility.isEmpty(allCharges) && allCharges?.length > 0) {
    return allCharges?.filter(
      (charge: any) =>
        charge.active && charge.isDiscount && charge.isItemDiscount
    );
  } else {
    return [];
  }
};
export const selectedAllAdditionalCharges = (state: RootState) =>
  state.additionalCharges.data.all;
export const selectAllAdditionalBuyCharges = (state: RootState) =>
  state.additionalCharges.data.allBuyCharges;
export const selectAllAdditionalSellCharges = (state: RootState) =>
  state.additionalCharges.data.allSellCharges;
