import {
  DKButton,
  DKCalendar,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import rounded_exchange from '../../Assets/Icons/rounded_exchange.svg';
import {
  BOOKS_DATE_FORMAT,
  COMMON_CONSTANT,
  DATE_FORMAT,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  REQUEST_PRINT_STATUS
} from '../../Constants/Constant';
import { PAYMENT_TYPE } from '../../Constants/StaticData';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  ReactSelectOptionsType
} from '../../Models/Interfaces';
import { KeyAndValue } from '../../Models/NewContact';
import { makePaymentMethod } from '../../Models/PaymentMethod';
import {
  Information,
  PAYMENT_CALENDAR_TYPE,
  PAYMENT_DROPDOWN_TYPE,
  PAYMENT_INPUT_TYPE
} from '../../Models/PaymentPopup';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import {
  selectAccountsType,
  selectCurrencyListWithExchangeRate
} from '../../Redux/Slices/CommonDataSlice';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import AccountsService, { AccountAPIConfig } from '../../Services/Accounts';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import DateFormatService from '../../Services/DateFormat';
import { localizedText } from '../../Services/Localization/Localization';
import NumberFormatService from '../../Services/NumberFormat';
import PaymentService from '../../Services/Payment';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import CurrencyDropdown from '../CurrencyDropdown/CurrencyDropdown';
import FormatAmount from '../FormatAmount';
import Stepper from '../Stepper';
import {
  BulkPaymentPayload,
  bulkPaymentState,
  initialBulkPaymentState
} from './BulkPaymentConfig';
import { COUNTRY_CODES, PAYMENT_TYPES } from '../../Constants/Constant';
import ApiConstants from '../../Constants/ApiConstants';

interface BulkPaymentProps {
  documents?: any[];
  documentType?: any;
  closePayment: (callback: CallBackPayloadType) => void;
}

type CurrencyConversionResults = {
  amount: number;
  exchangeRate: number;
  baseExchangeRate: number;
};

export default function BulkPayment(props: BulkPaymentProps) {
  const { t, i18n } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [stepsData, setStepsData] = useState<any[]>([]);
  const [contact, setContact] = useState<any>(null);
  const [contactInputVal, setContactInputVal] = useState<string>('');
  const [paymentType, setPaymentType] = useState<KeyAndValue[]>(PAYMENT_TYPE);
  const [accountPayee, setAccountPayee] = useState<string[]>([]);
  const [formState, setFormState] = useState<bulkPaymentState>(
    deepClone(initialBulkPaymentState)
  );
  const [currencySelection, setCurrencySelection] = useState<boolean>(false);
  const exchangeRateList = useAppSelector(selectCurrencyListWithExchangeRate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [loading, setLoading] = useState(false);
  const coaTypes = useAppSelector(selectAccountsType);

  const [accountList, setAccountList] = useState<any>([]);
  const dispatch = useAppDispatch();

  const actionBtns: BtnType[] = [];
  let showMulticurrencyStepper: boolean = false;
  let baseCurrencyExchangeRate: number = 1;
  const tenantDecimalScale: number = tenantInfo.decimalScale;

  const loadContacts = (term: string) => {
    const config: ContactAPIConfig = {
      ...ContactService.apiConfig,
      Page: 0,
      SearchTerm: term,
      Limit: 20,
      IncludeOpeningAmounts: false,
      IncludeOweAmounts: false
    };
    try {
      ContactService.apiConfig = config;
      const response = ContactService.getContactsByPage().then(
        (data: any) => data.content
      );
      return response;
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  useEffect(() => {
    registerSteps();
    let documentItems: any[] = [];
    if (props.documents && props.documents.length > 0) {
      const newState = { ...formState };
      newState.documentCurrency = props.documents[0].currency;
      newState.currency = props.documents[0].currency;
      showMulticurrencyStepper = newState.currency !== tenantInfo.currency;
      const documentItemsArray = JSON.parse(JSON.stringify(props.documents));
      let totalPayment = 0;
      const firstDocuemt =
        documentItemsArray && documentItemsArray.length > 0
          ? documentItemsArray[0]
          : null;
      if (
        firstDocuemt &&
        firstDocuemt.contactDto &&
        !firstDocuemt.openingInvoice
      ) {
        setContact(firstDocuemt.contactDto);
      } else if (firstDocuemt?.openingInvoice && firstDocuemt.contact) {
        const contactDto = firstDocuemt.contact;
        contactDto['code'] = firstDocuemt?.contactCode;
        setContact(contactDto);
      }
      documentItems = documentItemsArray.map((item: any) => {
        item['paymentAmount'] = item.dueAmount;
        totalPayment += item.paymentAmount;
        return item;
      });

      let paymentTypeOptions: ReactSelectOptionsType<string, string>[] = [];
      paymentType.forEach((payment) => {
        let option: ReactSelectOptionsType<string, string> = {
          label: (Utility.isUSorg() &&
          payment.value === COMMON_CONSTANT.CHEQUE_VALUE
            ? COMMON_CONSTANT.CHEQUE_VALUE_US
            : payment.value) as string,
          value: payment.key as string
        };
        paymentTypeOptions.push(option);
      });
      newState.paymentDetails.additionalPaymentDetails.referenceDate.date =
        DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

      newState.paymentDetails.paymentType = {
        ...newState.paymentDetails.paymentType,
        options: paymentTypeOptions
      };

      newState.documentItems = documentItems;
      newState.totalPayment = Utility.roundingOff(Number(totalPayment));
      newState.totalDueAmount = Utility.roundingOff(Number(totalPayment));
      if (!showMulticurrencyStepper) {
        newState.paymentDetails.conversionRate.hidden = true;
      } else {
        newState.paymentDetails.conversionRate.hidden = false;
      }
      fetchAccountGroups(props.documents[0].currency, newState);
      if (showReportingConversionRate(newState)) {
        updateBaseCurrencyTotal(newState);
      }
      setFormState({ ...newState });
    }
  }, []);

  const registerSteps = () => {
    setStepsData([
      {
        title: t(`BULK_PAYMENT_POPUP.GENERAL_STEP_TITLE`),
        requiredProps: [],
        reqError: '',
        getChild: (data: any) => null
      },
      {
        title: t(`BULK_PAYMENT_POPUP.PAYMENT_STEP_TITLE`),
        requiredProps: [],
        reqError: '',
        getChild: (data: any) => <div>step 2 block</div>,
        onClick: () => null
      },
      {
        title: t(`BULK_PAYMENT_POPUP.CONFIRM_STEP_TITLE`),
        requiredProps: [],
        reqError: '',
        getChild: (data: any) => <div>step 3 block</div>,
        onClick: () => null
      }
    ]);
  };

  const handleActions = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        if (props.closePayment) {
          props.closePayment({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
        }
        break;
    }
  };

  const updateTotal = (event: any, index: number, item: any) => {
    const newState = formState;
    const documentItems = newState.documentItems;
    const updateObj = documentItems[index];
    const inputValue = event;
    const requiredDueAmount =
      !newState.paymentDetails.conversionRate.hidden &&
      updateObj.convertedDueAmount
        ? updateObj.convertedDueAmount
        : updateObj.dueAmount;

    if (isNaN(Number(inputValue)) || inputValue === '') {
      updateObj.hasError = true;
      updateObj.errorMsg = 'Please enter valid amount';
    } else if (Number(inputValue) <= 0) {
      updateObj.hasError = true;
      updateObj.errorMsg = 'Please enter amount greater than 0';
    } else if (Number(inputValue) > requiredDueAmount) {
      updateObj.hasError = true;
      updateObj.errorMsg = 'Amount is greater than Due Amount';
    } else {
      updateObj.hasError = false;
      updateObj.errorMsg = '';
    }

    updateObj.paymentAmount = Number(inputValue) >= 0 ? inputValue : 0;

    newState.totalPayment = documentItems.reduce(function (sum, item) {
      return (sum = sum + Number(item.paymentAmount));
    }, 0);

    if (showReportingConversionRate()) {
      updateBaseCurrencyTotal(newState);
    }
    setFormState({ ...newState, documentItems });
    calculateTotal();
  };

  const calculateTotal = () => {
    const documentItems = formState.documentItems;
    const totalPayment = documentItems.reduce(function (sum, item) {
      return (sum = sum + Number(item.paymentAmount));
    }, 0);

    setFormState({ ...formState, totalPayment });
  };

  const inlineItemDelete = (index: number) => {
    const updatedState = formState;
    updatedState.documentItems.splice(index, 1);
    setFormState({ ...updatedState });
    calculateTotal();
  };

  const setCurrencyDisableState = (newState: any) => {
    newState.paymentDetails.payFrom.value = { label: '', value: '' };
    setFormState({ ...newState });
  };

  const grabInfo = (data: Information) => {
    const newState = formState;
    // setCurrencyDisableState(newState);
    fetchAccountGroups(data.selectedCurrencyCode, newState);
    baseCurrencyExchangeRate = reverseExchangeRate();
    showMulticurrencyStepper =
      props.documents &&
      props.documents.length > 0 &&
      props.documents[0].currency &&
      props.documents[0].currency !== data.selectedCurrencyCode;
    newState.currency = data.selectedCurrencyCode;
    calculateDocumentTotal(newState);
    if (showReportingConversionRate()) {
      updateBaseCurrencyTotal(newState);
    }

    if (!showMulticurrencyStepper) {
      newState.paymentDetails.conversionRate.hidden = true;
    } else {
      newState.paymentDetails.conversionRate.hidden = false;
    }
    setFormState({ ...newState });
    calculateTotal();
  };

  const calculateDocumentTotal = (newState: bulkPaymentState) => {
    const filteredCurrency = exchangeRateList.filter(
      (currency) => currency.currencyCode === newState.currency
    );
    if (filteredCurrency.length > 0) {
      const exchangeRate = filteredCurrency[0].currencyExchangeRate;

      let totalAmount: number = 0;
      newState.documentItems.forEach((document) => {
        const results: CurrencyConversionResults = convertCurrency(
          document.dueAmount,
          exchangeRate
        );
        document.paymentAmount = Utility.roundingOff(Number(results.amount));
        document['convertedDueAmount'] = Utility.roundingOff(
          Number(results.amount)
        );
        totalAmount += Number(document.paymentAmount);
        document['hasError'] = false;
        document['errorMsg'] = '';
      });

      const results: CurrencyConversionResults = convertCurrency(
        totalAmount,
        exchangeRate
      );
      newState.currency = newState.currency;
      newState.paymentDetails.paymentAmount.defaultValue = results.amount;
      newState.paymentDetails.conversionRate.defaultValue =
        showReportingConversionRate() ? results.exchangeRate : 1 / exchangeRate;
      newState.paymentDetails.paymentAmount.value = results.amount;
      newState.totalPayment = totalAmount;
    }
  };

  const reverseExchangeRate = () => {
    const obj = {
      totalAmount: 0,
      totalAmountInBaseCurrency: 0
    };
    props.documents?.forEach((doc) => {
      obj.totalAmount += Number(doc.totalAmount);
      obj.totalAmountInBaseCurrency += Number(doc.totalAmountInBaseCurrency);
    });
    if (obj.totalAmount && obj.totalAmountInBaseCurrency) {
      return obj.totalAmount / obj.totalAmountInBaseCurrency;
    } else {
      return props.documents && props.documents[0].exchangeRate
        ? props.documents[0].exchangeRate
        : 1;
    }
  };

  const calculateCurrentExchangeRate = (
    currencyExchangeRate: number,
    baseCurrencyExchangeRate: number
  ) => {
    return (1 * baseCurrencyExchangeRate) / currencyExchangeRate;
  };

  const targetToBaseConversion = (rate: number, targetCurrecyRate: number) => {
    return baseCurrencyExchangeRate
      ? (rate / baseCurrencyExchangeRate) * targetCurrecyRate
      : 0;
  };

  const convertCurrency = (
    baseAmount: number,
    currencyExchangeRate: number
  ): CurrencyConversionResults => {
    const dueAmount = Utility.roundingOff(baseAmount);
    const baseExchangeRate = 1 / currencyExchangeRate;
    const rawPaymentAmount = Utility.roundingOff(
      targetToBaseConversion(dueAmount, currencyExchangeRate)
    );

    const amount = Utility.roundingOff(rawPaymentAmount);
    let exchangeRate = calculateCurrentExchangeRate(
      currencyExchangeRate,
      baseCurrencyExchangeRate
    );
    let exchAmount =
      Math.trunc(rawPaymentAmount * Math.pow(10, 2)) / Math.pow(10, 2);
    exchAmount = exchAmount == 0 ? rawPaymentAmount : exchAmount;
    let invCurrencyAmount = Utility.roundingOff(exchAmount * baseExchangeRate);
    let diff = dueAmount - invCurrencyAmount;
    if (showMulticurrencyStepper && diff !== 0) {
      let temporaryFx = dueAmount / exchAmount;
      exchangeRate = temporaryFx;
    }
    return { amount, exchangeRate, baseExchangeRate };
  };

  const showReportingConversionRate = (tempFormState: any = formState) => {
    // Check payment currency, org currency and document currency,
    // all should be different
    return (
      tempFormState.currency !== tenantInfo.currency &&
      tempFormState.documentCurrency !== tenantInfo.currency
    );
  };

  const updateBaseCurrencyTotal = (newState: bulkPaymentState) => {
    baseCurrencyExchangeRate = reverseExchangeRate();
    const filteredCurrency = exchangeRateList.filter(
      (currency) => currency.currencyCode === newState.currency
    );
    if (filteredCurrency.length > 0) {
      const exchangeRate = filteredCurrency[0].currencyExchangeRate;
      const baseExchangeRate = 1 / exchangeRate;

      newState.baseTotalAmount = Utility.roundingOff(
        +newState.totalPayment * baseExchangeRate
      );
      newState.baseExchangeRate = baseExchangeRate;
    }
  };

  const fetchAccountGroups = (currencyCode: string, newState = formState) => {
    setCurrencySelection(true);
    let accountTypes = [
      'Bank',
      'Cash',
      'Current Assets',
      'Current Liabilities'
    ];
    if (tenantInfo.country === COUNTRY_CODES.IL) {
      accountTypes = coaTypes?.map((type) => type.name);
    }
    AccountsService.fetchAccountGroup(currencyCode, accountTypes).then(
      (res: any[]) => {
        let accountList: any[] = res;
        let paymentMethodOption: any[] = [];
        if (accountList.length > 0) {
          let paymentArray = [];
          if (tenantInfo.country === COUNTRY_CODES.IL) {
            paymentArray = accountList.filter((el) =>
              accountTypes.includes(el.accountGroup)
            );
          } else {
            paymentArray = accountList.filter(
              (el) =>
                el.accountGroup === COMMON_CONSTANT.BANK ||
                el.accountGroup === COMMON_CONSTANT.CASH ||
                (el.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                  el.isCreditCard) ||
                (el.accountGroup === COMMON_CONSTANT.CURRENT_ASSETS &&
                  el.isUndepositedFundAccount &&
                  document &&
                  props.documentType === DOC_TYPE.INVOICE)
            );
            setAccountList(accountList);
          }

          const paymentMethod = paymentArray.map((paymentMethod) =>
            makePaymentMethod(paymentMethod)
          );

          if (paymentMethod.length > 0) {
            paymentMethodOption = paymentMethod;
          }

          let paymentOptions: ReactSelectOptionsType<string, string>[] = [];

          const accountPayee: string[] = [];
          paymentMethodOption.forEach((payOption) => {
            let option: ReactSelectOptionsType<string, string> = {
              label: `${payOption.name}(${Utility.convertInTitleCase(
                payOption.accountGroup
              )})`,
              value: payOption.code
            };
            let found = false;
            paymentOptions.forEach((options) => {
              if (options.label === option.label) {
                found = true;
              }
            });
            if (!found) {
              paymentOptions.push(option);
            }
            if (payOption.accountGroup !== COMMON_CONSTANT.CASH) {
              accountPayee.push(payOption.code);
            }
          });
          newState.paymentDetails.payFrom.options = paymentOptions;
          newState.paymentDetails.payFrom.value =
            newState.paymentDetails.payFrom.options[0];
          let index1 = newState.paymentDetails.payFrom.value.label.indexOf('(');
          let index2 = newState.paymentDetails.payFrom.value.label.indexOf(')');
          let obj2 = newState.paymentDetails.payFrom.value.label.substring(
            index1 + 1,
            index2
          );
          newState.paymentDetails.paymentAccountGroup = obj2;
          const paymentTypeOptions =
            newState?.paymentDetails?.paymentType?.options || [];
          newState.paymentDetails.paymentType.value = {
            label: paymentTypeOptions?.[0]?.label || 'Cash',
            value: paymentTypeOptions?.[0]?.value || 'CASH'
          };

          setFormState({ ...newState });
          setCurrencySelection(false);
          setAccountPayee(accountPayee);
        } else {
          newState.paymentDetails.payFrom.options = [];
          newState.paymentDetails.payFrom.value = { label: '', value: '' };
          newState.paymentDetails.paymentAccountGroup = '';
          setFormState({ ...newState });
          setCurrencySelection(false);
        }
      }
    );
  };

  const optionSelected = (
    option: any | null,
    dropdownType: PAYMENT_DROPDOWN_TYPE
  ) => {
    const newState = formState;
    switch (dropdownType) {
      case PAYMENT_DROPDOWN_TYPE.PAY_FROM:
        if (option) {
          let index1 = option.label.indexOf('(');
          let obj1 = option.label.substring(0, index1) || option.label;
          let index2 = option.label.indexOf(')');
          let obj2 =
            option.label.substring(index1 + 1, index2) || option.accountGroup;
          if (obj2 === 'Bank') {
            newState.paymentDetails.paymentType.hidden = false;
            newState.paymentDetails.paymentType.value = {
              label: 'Bank Transfer',
              value: 'BANK_TRANSFER'
            };
          } else if (obj2 === 'Cash') {
            newState.paymentDetails.paymentType.hidden = true;
            newState.paymentDetails.paymentType.value = {
              label: 'CASH',
              value: 'CASH'
            };
          } else {
            newState.paymentDetails.paymentType.hidden = false;
            newState.paymentDetails.paymentType.value = {
              label: 'Others',
              value: 'OTHERS'
            };
          }
          newState.paymentDetails.paymentAccountGroup = obj2;
          newState.paymentDetails.payFrom.value = option;
        }
        break;
      case PAYMENT_DROPDOWN_TYPE.PAY_TYPE:
        if (option) {
          newState.paymentDetails.paymentType.value = option;
        }
        break;
      default:
        break;
    }
    setFormState({ ...newState });
  };

  const inputChanged = (event: any, inputType: PAYMENT_INPUT_TYPE) => {
    const inputValue = event;
    const newState = formState;
    switch (inputType) {
      case PAYMENT_INPUT_TYPE.PAY_AMOUNT:
        if (
          Number(inputValue) > newState.totalPayment ||
          isNaN(Number(inputValue))
        ) {
          newState.paymentDetails.paymentAmount.hasError = true;
          newState.paymentDetails.paymentAmount.errorMsg =
            'Amount is greater than Due Amount';
        } else {
          newState.paymentDetails.paymentAmount.hasError = false;
        }
        newState.paymentDetails.paymentAmount.defaultValue = inputValue;
        newState.paymentDetails.paymentAmount.value = inputValue;
        break;
      case PAYMENT_INPUT_TYPE.CONVERSION_RATE:
        if (Number(inputValue) === 0 || isNaN(Number(inputValue))) {
          newState.paymentDetails.conversionRate.hasError = true;
          newState.paymentDetails.conversionRate.errorMsg =
            'Not valid Conversion Rate';
          newState.paymentDetails.conversionRate.defaultValue = inputValue;
          break;
        } else {
          newState.paymentDetails.conversionRate.hasError = false;
        }
        break;
      case PAYMENT_INPUT_TYPE.REFERENCE_NUMBER:
        newState.paymentDetails.additionalPaymentDetails.referenceNumber.value =
          inputValue;
        break;
    }
    setFormState({ ...newState });
  };

  const showCalendar = (calendarType: PAYMENT_CALENDAR_TYPE) => {
    const newState = formState;
    switch (calendarType) {
      case PAYMENT_CALENDAR_TYPE.REFERENCE_DATE:
        newState.paymentDetails.additionalPaymentDetails.referenceDate.isOpen =
          true;
        break;
      case PAYMENT_CALENDAR_TYPE.DOCUMENT_DATE:
        newState.documentDate.isOpen = true;
        break;
    }
    setFormState({ ...newState });
  };

  const hideCalendar = () => {
    const newState = formState;
    newState.paymentDetails.additionalPaymentDetails.referenceDate.isOpen =
      false;
    setFormState({ ...newState });
  };

  const dateSelected = (calendarType: PAYMENT_CALENDAR_TYPE, date: Date) => {
    const newState = formState;
    switch (calendarType) {
      case PAYMENT_CALENDAR_TYPE.REFERENCE_DATE:
        newState.paymentDetails.additionalPaymentDetails.referenceDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            new Date(date),
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
        break;
    }
    setFormState({ ...newState });
  };

  const closePopup = (docType: DOC_TYPE) => {
    if (props.closePayment) {
      props.closePayment({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
    if (docType === DOC_TYPE.BILL) {
      dispatch(fetchBills());
      showToast('Bulk Payment Made Successfully', TOAST_TYPE.SUCCESS);
    } else {
      dispatch(fetchInvoices());
      showToast('Bulk Payment Received Successfully', TOAST_TYPE.SUCCESS);
    }
  };

  const _getPayloadExchnageRate = () => {
    let _payloadExchangeRate: any = {};
    let _tenantBaseCurrency: any = tenantInfo.currency;
    let _documentCurrency: any = formState.documentCurrency;
    let _selectedCurrency: any = formState.currency;

    let _baseExchangeRate: any = formState.baseExchangeRate;
    let _exchangeRate: any =
      formState.paymentDetails.conversionRate.defaultValue;

    if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      return 1;
    } else if (
      _documentCurrency === _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      return _exchangeRate / _baseExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency === _selectedCurrency
    ) {
      return _baseExchangeRate;
    } else if (
      _documentCurrency !== _tenantBaseCurrency &&
      _documentCurrency !== _selectedCurrency
    ) {
      if (_selectedCurrency === _tenantBaseCurrency) {
        return _exchangeRate / _baseExchangeRate;
      } else if (_selectedCurrency !== _tenantBaseCurrency) {
        return _exchangeRate;
      } else {
        return _payloadExchangeRate;
      }
    }
  };

  const makePayload = (): BulkPaymentPayload => {
    let paymentType = formState.paymentDetails.paymentType.value.value;
    let index1 = formState.paymentDetails.payFrom.value.label.indexOf('(');
    let accountName = formState.paymentDetails.payFrom.value.label.substring(
      0,
      index1
    );
    if (formState.paymentDetails.paymentAccountGroup == 'Cash') {
      paymentType = 'CASH';
    }

    const payload: BulkPaymentPayload = {
      amount: Utility.roundingOff(formState.totalPayment),
      amountInBaseCurrency: Utility.roundingOff(formState.totalPayment),
      contactCode: contact?.code,
      contactName: contact.name,
      currency: formState.currency,
      documentDate: DateFormatService.getDateStrFromDate(
        new Date(formState.documentDate.date),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      exchangeRate: _getPayloadExchnageRate(),
      makePaymentFeeDtoList: [],
      makePaymentList: []
    };

    var selectedAccount = accountList.find(
      (x: any) => x.code === formState.paymentDetails.payFrom.value.value
    );

    formState.documentItems.forEach((document) => {
      payload.makePaymentList?.push({
        documentDate: DateFormatService.getDateStrFromDate(
          new Date(formState.documentDate.date),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        closeDate: Utility.formatDate(new Date(), DATE_FORMAT.YYYY_MM_DD),
        amount: Utility.roundingOff(Number(document.paymentAmount)),
        currency: formState.currency,
        exchangeRate: _getPayloadExchnageRate(),
        contactCode: contact.code,
        accountName: accountName,
        accountCodePayFrom: formState.paymentDetails.payFrom.value.value,
        isUnDepositedPayment: selectedAccount
          ? selectedAccount.isUndepositedFundAccount
            ? selectedAccount.isUndepositedFundAccount
            : null
          : null,
        whtRate: 0,
        whtApplicable: false,
        contactName: contact.name,
        referenceNumber:
          formState.paymentDetails.additionalPaymentDetails.referenceNumber.value.toString(),
        referenceDate: DateFormatService.getFormattedDateString(
          formState.paymentDetails.additionalPaymentDetails.referenceDate.date,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        paymentType: paymentType,
        makePaymentItemDtoList: [
          {
            documentCode: document.purchaseInvoiceCode,
            documentSequenceCode: document.documentSequenceCode,
            documentType: props.documentType,
            paymentAmount: Utility.roundingOff(Number(document.paymentAmount)),
            exchangeRate: 1
          }
        ],
        status:
          paymentType === PAYMENT_TYPES.CHEQUE
            ? REQUEST_PRINT_STATUS.NOT_PRINTED
            : undefined
      });
    });

    return payload;
  };

  const receivedPayload = (): BulkPaymentPayload => {
    const payload: BulkPaymentPayload = {
      amount: Utility.roundingOff(formState.totalPayment),
      amountInBaseCurrency: Utility.roundingOff(formState.totalPayment),
      contactCode: contact?.code,
      contactName: contact.name,
      currency: formState.currency,
      documentDate: DateFormatService.getDateStrFromDate(
        new Date(formState.documentDate.date),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      exchangeRate: _getPayloadExchnageRate(),
      receivePaymentFeeDtoList: [],
      receivePaymentList: []
    };

    var selectedAccount = accountList.find(
      (x: any) => x.code === formState.paymentDetails.payFrom.value.value
    );

    formState.documentItems.forEach((document) => {
      payload.receivePaymentList?.push({
        documentDate: DateFormatService.getDateStrFromDate(
          new Date(formState.documentDate.date),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        closeDate: Utility.formatDate(new Date(), DATE_FORMAT.YYYY_MM_DD),
        amount: Utility.roundingOff(Number(document.paymentAmount)),
        currency: formState.currency,
        exchangeRate: _getPayloadExchnageRate(),
        contactCode: contact.code,
        accountName: formState.paymentDetails.payFrom.value.label,
        accountCodePayTo: formState.paymentDetails.payFrom.value.value,
        isUnDepositedPayment: selectedAccount
          ? selectedAccount.isUndepositedFundAccount
            ? selectedAccount.isUndepositedFundAccount
            : null
          : null,
        whtRate: 0,
        whtApplicable: false,
        contactName: contact.name,
        referenceNumber:
          formState.paymentDetails.additionalPaymentDetails.referenceNumber.value.toString(),
        referenceDate: DateFormatService.getFormattedDateString(
          formState.paymentDetails.additionalPaymentDetails.referenceDate.date,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        paymentType: formState.paymentDetails.paymentType.value.value,
        receivePaymentItemDtoList: [
          {
            documentCode: document.salesInvoiceCode,
            documentSequenceCode: document.documentSequenceCode,
            documentType: props.documentType,
            paymentAmount: Utility.roundingOff(Number(document.paymentAmount)),
            exchangeRate: 1
          }
        ]
      });
    });

    return payload;
  };

  const validateDate = () => {
    let validationAppliedOn = '';
    if (props.documentType === DOC_TYPE.INVOICE) {
      validationAppliedOn = 'salesInvoiceDate';
    } else {
      validationAppliedOn = 'purchaseInvoiceDate';
    }
    var filtered =
      props.documents &&
      props.documents?.length > 0 &&
      props.documents.reduce(function (prev, current) {
        let prevDate = DateFormatService.getDateFromStr(
          prev[validationAppliedOn],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        let currentDate = DateFormatService.getDateFromStr(
          current[validationAppliedOn],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        return prevDate > currentDate ? prev : current;
      });
    let biggestDate = DateFormatService.getDateFromStr(
      filtered[validationAppliedOn],
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    let selectedDate = new Date(formState.documentDate.date);
    if (selectedDate < biggestDate) {
      showAlert(
        'Validation',
        `Payment date cannot be before <div class="fw-m"> ${DateFormatService.getDateStrFromDate(
          biggestDate
        )}</div>`
      );
      return false;
    }
    let docType =
      props.documentType === DOC_TYPE.INVOICE
        ? 'RECEIVE_PAYMENT'
        : 'MAKE_PAYMENT';
    if (
      !Utility.checkActiveDateRangeValidation(
        selectedDate,
        tenantInfo,
        'Payment date',
        docType
      )
    ) {
      return false;
    }

    return true;
  };

  const receivedPayment = () => {
    let payload = receivedPayload();
    setLoading(true);
    PaymentService.bulkReceivedPayment(payload)
      .then((res) => {
        closePopup(DOC_TYPE.INVOICE);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const makePayment = () => {
    let payload = makePayload();
    setLoading(true);
    PaymentService.bulkMakePayment(payload)
      .then((res) => {
        closePopup(DOC_TYPE.BILL);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const savePayment = () => {
    if (!validateDate()) {
      return;
    }
    if (props.documentType === DOC_TYPE.INVOICE) {
      receivedPayment();
    } else {
      makePayment();
    }
  };

  const newTaxSearchSelectStyle1 = {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      left: '50%',
      transform: 'translateX(-50%)'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      textAlign: 'left',
      fontSize: '0.875rem',
      backgroundColor: state.isSelected
        ? 'var(--btn-bg-color)'
        : state.isFocused
        ? 'var(--main-color-hover)'
        : null
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '250px'
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: '1px solid rgb(235, 235, 235)',
      borderRadius: 5,
      minHeight: 36,
      maxHeight: 36,
      boxShadow: 'none',
      border: '1px solid rgb(235, 235, 235)',
      '&:hover, &:focus, &:active, &:focus-visible': {
        border: '1px solid rgb(235, 235, 235)',
        boxShadow: 'none'
      },
      backgroundColor: '#f4f4f6'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      display: 'none'
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'flex-start',
      paddingRight: '5px',
      paddingLeft: '5px'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)'
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0
    })
  };

  const getPayFromName = (value: any, state: any) => {
    let index1 = value.indexOf('(');
    let accountName = value.substring(0, index1);
    return accountName;
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 25 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const paymentInfo = () => {
    return (
      <div
        className="flex flex-col px-3 parent-height"
        style={{
          width: '75%',
          minHeight: '400px'
        }}
      >
        <span className="fw-m text-base mx-auto pl-3" style={{ width: '80%' }}>
          {t(`BULK_PAYMENT_POPUP.AMOUNT_SETTLED`)}
        </span>
        <div className="pl-3">
          <div
            className="flex justify-center items-baseline mx-auto"
            style={{ width: '80%' }}
          >
            <span className="text-base mr-2 ">
              {Utility.getCurrencySymbolFromCode(formState.currency)}
            </span>
            <span className="text-3xl">
              {NumberFormatService.getNumber(formState.totalPayment)}
            </span>
          </div>
          <div className="mt-3 mx-auto" style={{ width: '80%' }}>
            <div className="flex justify-between ">
              <DKLabel
                className="text-gray-400"
                text={t(`BULK_PAYMENT_POPUP.PAYMENT_TYPE`)}
              />
              <DKLabel
                text={formState.paymentDetails.paymentType.value.label}
              />
            </div>
            <div className="row justify-content-between mt-1 position-relative">
              <div
                className="row justify-content-between cursor-pointer"
                onClick={() => {
                  showCalendar(PAYMENT_CALENDAR_TYPE.DOCUMENT_DATE);
                }}
              >
                <DKLabel text={'Payment Date'} className="text-gray-400" />
                <DKLabel
                  className="underline  mt-s mb-s text-blue"
                  style={{
                    textDecorationColor: 'blue'
                  }}
                  text={
                    formState.documentDate.date
                      ? DateFormatService.getDateStrFromDate(
                          new Date(formState.documentDate.date)
                        )
                      : ''
                  }
                />
              </div>
              {formState.documentDate.isOpen &&
                getCalendarView(
                  new Date(formState.documentDate.date),
                  (newDate: any) => {
                    let updatedState = { ...formState };
                    updatedState.documentDate.date = newDate;
                    updatedState.documentDate.isOpen = false;
                    let docType =
                      props.documentType === DOC_TYPE.INVOICE
                        ? 'RECEIVE_PAYMENT'
                        : 'MAKE_PAYMENT';
                    if (
                      Utility.checkActiveDateRangeValidation(
                        newDate,
                        tenantInfo,
                        'Payment date',
                        docType
                      )
                    ) {
                      setFormState(updatedState);
                    } else {
                      updatedState.documentDate.date = new Date();
                      updatedState.documentDate.isOpen = false;
                      setFormState(updatedState);
                    }
                  },
                  (isOpen: any) => {
                    hideCalendar();
                  }
                )}
            </div>
            <div className="flex justify-between mt-1">
              <DKLabel
                className="text-gray-400"
                text={
                  props.documentType === DOC_TYPE.INVOICE
                    ? t(`BULK_PAYMENT_POPUP.DEPOSIT_TO`)
                    : t(`BULK_PAYMENT_POPUP.PAY_FROM`)
                }
              />
              <DKLabel
                text={getPayFromName(
                  formState.paymentDetails.payFrom.value.label,
                  formState
                )}
              />
            </div>
          </div>
        </div>
        {!(
          formState.paymentDetails.paymentType.value.label == 'Cash' ||
          formState.paymentDetails.paymentAccountGroup == 'Cash'
        ) && (
          <>
            <span
              className="fw-m text-base mt-6 pl-3 mx-auto"
              style={{ width: '80%' }}
            >
              {' '}
              {t(
                'BULK_PAYMENT_POPUP.' +
                  formState.paymentDetails.paymentType.value.value +
                  '_DETAILS'
              )}{' '}
            </span>
            <div
              className="flex justify-between mt-3 pl-3 mx-auto"
              style={{ width: '80%' }}
            >
              <DKLabel
                className="text-gray-400"
                text={t(`BULK_PAYMENT_POPUP.REFERENCE_DATE`)}
              />
              <DKLabel
                text={DateFormatService.getFormattedDateString(
                  formState.paymentDetails.additionalPaymentDetails
                    .referenceDate.date,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}
              />
            </div>
            <div
              className="flex justify-between mt-1 pl-3 mx-auto"
              style={{ width: '80%' }}
            >
              <DKLabel
                className="text-gray-400"
                text={t(`BULK_PAYMENT_POPUP.REFERENCE_NUMBER`)}
              />
              <DKLabel
                text={
                  formState.paymentDetails.additionalPaymentDetails
                    .referenceNumber.value
                }
              />
            </div>
          </>
        )}

        <div className="row parent-width justify-content-center mb-l mt-auto">
          {currentIndex !== 0 && (
            <DKButton
              title={t(`BULK_PAYMENT_POPUP.BACK`)}
              className={
                loading ? 'border-m ml-r bg-gray1' : 'border-m ml-r bg-white'
              }
              onClick={(e: any) => {
                if (!loading) {
                  onSkipToStep(e, currentIndex - 1);
                }
              }}
            />
          )}
          <DKButton
            title={t(`BULK_PAYMENT_POPUP.CANCEL`)}
            className={
              loading ? 'border-m ml-r bg-gray1' : 'border-m ml-r bg-white'
            }
            onClick={() => {
              if (!loading) {
                if (props.closePayment) {
                  props.closePayment({
                    type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
                  });
                }
              }
            }}
          />
          <DKButton
            title={
              props.documentType === DOC_TYPE.INVOICE
                ? t(`BULK_PAYMENT_POPUP.RECEIVE_PAYMENT`)
                : t(`BULK_PAYMENT_POPUP.MAKE_PAYMENT`)
            }
            className={
              loading
                ? 'ml-r bg-gray1 text-black border-m'
                : 'ml-r bg-button text-white'
            }
            onClick={() => {
              if (!loading) {
                savePayment();
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getValueForDkInput = (value: any) => {
    let index1 = value.indexOf('(');
    let index2 = value.indexOf(')');
    let obj1 = value.substring(0, index1) || value;
    return obj1;
  };

  const getOptionForPaymentType = (value: any[]) => {
    switch (formState.paymentDetails.paymentAccountGroup) {
      case "Current Assets":
        return [...value, {
          label: 'Cash',
          value: 'CASH'
        }];
      case "Bank":
        return [...value, {
          label: 'ACH',
          value: 'ACH'
        }];
      default:
        return value;
    }
  };

  const paymentDetailsForm = () => {
    return (
      <div className="parent-width">
        <DKLabel
          text={t(`BULK_PAYMENT_POPUP.PAYMENT_DETAILS`)}
          className="fs-m fw-m my-2"
        />
        <div className="parent-width row flex-wrap">
          <div
            className={'flex-1'}
            style={{ minWidth: '250px', maxWidth: '250px' }}
          >
            <DKInput
              value={getValueForDkInput(
                formState.paymentDetails.payFrom.value.label
              )}
              title={
                props.documentType === DOC_TYPE.INVOICE
                  ? t(`BULK_PAYMENT_POPUP.DEPOSIT_TO`)
                  : t(`BULK_PAYMENT_POPUP.PAY_FROM`)
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              className={''}
              formatter={(obj: any) => {
                return obj;
              }}
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              onChange={(value: any) => {
                optionSelected(value, PAYMENT_DROPDOWN_TYPE.PAY_FROM);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: tenantInfo?.additionalSettings?.ACCOUNT
                  ?.showCardCashBankAccountsOnly
                  ? formState.paymentDetails.payFrom?.options &&
                    formState.paymentDetails.payFrom?.options.length > 5
                  : true,
                searchableKey: 'label',
                data: tenantInfo?.additionalSettings?.ACCOUNT
                  ?.showCardCashBankAccountsOnly
                  ? formState?.paymentDetails?.payFrom?.options
                  : [],
                searchApiConfig: tenantInfo?.additionalSettings?.ACCOUNT
                  ?.showCardCashBankAccountsOnly
                  ? null
                  : {
                      getUrl: (searchValue: string) => {
                        const config: AccountAPIConfig = {
                          ...AccountsService.apiConfig,
                          Page: 0,
                          SearchTerm: searchValue,
                          Limit: 10,
                          Query: 'status=ACTIVE'
                        };
                        AccountsService.apiConfig = config;
                        return (
                          ApiConstants.URL.BASE +
                          AccountsService.getAccountEndPoint()
                        );
                      },
                      dataParser: (response: any) => {
                        return (
                          response?.content?.map((account: any) => ({
                            label: account.name,
                            value: account.code,
                            accountGroup: account.accountGroup,
                            isUndepositedFundAccount:
                              account.isUndepositedFundAccount
                          })) || []
                        );
                      },
                      debounceTime: 300
                    },
                renderer: (index: any, obj: any) => {
                  let index1 = obj.label.indexOf('(');
                  let index2 = obj.label.indexOf(')');
                  let obj1 = obj.label.substring(0, index1) || obj.label;
                  let obj2 =
                    obj.label.substring(index1 + 1, index2) || obj.accountGroup;
                  return (
                    <div className="flex flex-row w-full justify-content-between">
                      <DKLabel
                        style={{ fontSize: '13px' }}
                        className="text-base border-radius-s"
                        text={obj1}
                      />
                      <DKLabel
                        style={{ fontSize: '10px' }}
                        className="border-radius-s"
                        text={obj2}
                      />
                    </div>
                  );
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
            <div className="mt-5"></div>
          </div>
          {!(formState.paymentDetails.paymentAccountGroup == 'Cash') && (
            <div
              className={'flex-1 mx-2'}
              style={{ minWidth: '250px', maxWidth: '250px' }}
            >
              <DKInput
                value={formState.paymentDetails.paymentType.value.label}
                title={t(`BULK_PAYMENT_POPUP.PAYMENT_TYPE`)}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                className={''}
                formatter={(obj: any) => {
                  return obj;
                }}
                type={INPUT_TYPE.DROPDOWN}
                required={false}
                onChange={(e: any) => {
                  optionSelected(e, PAYMENT_DROPDOWN_TYPE.PAY_TYPE);
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'label',
                  data: getOptionForPaymentType(
                    formState.paymentDetails.paymentType.options
                      ? formState.paymentDetails.paymentType.options
                      : []
                  ),
                  renderer: (index: any, obj: any) => {
                    return (
                      <div className="flex flex-row w-full justify-content-between">
                        <DKLabel
                          style={{ fontSize: '13px' }}
                          className="text-base border-radius-s"
                          text={obj.label}
                        />
                      </div>
                    );
                  },
                  onSelect: (index: any, value: any) => {}
                }}
              />
              <div className="mt-5"></div>
            </div>
          )}
          <div
            className={'flex-1 mx-2'}
            style={{ minWidth: '250px', maxWidth: '250px' }}
          >
            <DKInput
              hasError={formState.paymentDetails.paymentAmount.hasError}
              onChange={(event: any) => {
                inputChanged(event, PAYMENT_INPUT_TYPE.PAY_AMOUNT);
              }}
              title="Payment Amount"
              value={formState.totalPayment.toString()}
              errorMessage={formState.paymentDetails.paymentAmount.errorMsg}
              isDisabled={true}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              readOnly={true}
            />
            <div className="mt-5"></div>
          </div>
        </div>

        {!(
          formState.paymentDetails.paymentType.value.label == 'Cash' ||
          formState.paymentDetails.paymentAccountGroup == 'Cash'
        ) &&
          formState.paymentDetails.paymentType.value.value &&
          formState.paymentDetails.paymentType.value.value !== 'reset' && (
            <div>
              <DKLabel
                text={t('BULK_PAYMENT_POPUP.BANK_TRANSFER_DETAILS')}
                className="fs-m fw-m my-2"
              />
              <div className="parent-width flex flex-wrap justify-start">
                <div
                  className="flex-1"
                  style={{
                    minWidth: '250px',
                    maxWidth: '250px',
                    marginRight: '8px'
                  }}
                >
                  <DKInput
                    className={''}
                    title="Reference Date"
                    value={DateFormatService.getDateFromStr(
                      formState.paymentDetails.additionalPaymentDetails
                        .referenceDate.date,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )}
                    type={INPUT_TYPE.DATE}
                    onChange={(value: any) =>
                      dateSelected(PAYMENT_CALENDAR_TYPE.REFERENCE_DATE, value)
                    }
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                  />
                </div>

                <div
                  className="flex-1"
                  style={{ minWidth: '250px', maxWidth: '250px' }}
                >
                  <DKInput
                    hasError={
                      formState.paymentDetails.additionalPaymentDetails
                        .referenceNumber.hasError
                    }
                    onChange={(event: any) => {
                      inputChanged(event, PAYMENT_INPUT_TYPE.REFERENCE_NUMBER);
                    }}
                    title={
                      formState.paymentDetails.paymentType.value.value ===
                      'CHEQUE'
                        ? `${getCapitalized(localizedText('cheque'))} Number`
                        : 'Reference Number'
                    }
                    value={formState.paymentDetails.additionalPaymentDetails.referenceNumber.value?.toString()}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };

  const getDocumentsRow = () => {
    return (
      <>
        <div className="PrePayment w-full py-5">
          <div className="flex flex-row align-center mb-2 font-bold">
            <div style={{ minWidth: '5%' }}>#</div>
            <div className="pr-2" style={{ minWidth: '20%' }}>
              {props.documentType === DOC_TYPE.INVOICE
                ? t(`BULK_PAYMENT_POPUP.INVOICE_NO`)
                : t(`BULK_PAYMENT_POPUP.BILL_NO`)}
            </div>
            <div style={{ minWidth: '20%' }}>
              {props.documentType === DOC_TYPE.INVOICE
                ? t(`BULK_PAYMENT_POPUP.INVOICE_DATE`)
                : t(`BULK_PAYMENT_POPUP.BILL_DATE`)}
            </div>
            <div style={{ minWidth: '25%' }}>
              {t(`BULK_PAYMENT_POPUP.OUTSTANDING_AMOUNT`)}
            </div>
            <div
              style={{
                minWidth: '25%',
                marginLeft: '10px',
                paddingLeft: '10px'
              }}
            >
              {t(`BULK_PAYMENT_POPUP.AMOUNT`)}
            </div>
          </div>
          <div className="w-full h-px bg-black"></div>
          {formState.documentItems
            ? formState.documentItems.map((item, index) => {
                return (
                  <div className="flex flex-row items-center my-6">
                    <div style={{ minWidth: '5%' }}>
                      <span>{index + 1}.</span>
                    </div>
                    <div style={{ minWidth: '20%' }}>
                      <span>{item.documentSequenceCode}</span>
                    </div>
                    <div style={{ minWidth: '20%' }}>
                      {props.documentType === DOC_TYPE.INVOICE
                        ? DateFormatService.getFormattedDateString(
                            item.salesInvoiceDate,
                            BOOKS_DATE_FORMAT['DD-MM-YYYY']
                          )
                        : DateFormatService.getFormattedDateString(
                            item.purchaseInvoiceDate,
                            BOOKS_DATE_FORMAT['DD-MM-YYYY']
                          )}
                    </div>
                    <div style={{ minWidth: '25%' }}>
                      <span>
                        <FormatAmount
                          value={item.dueAmount}
                          currencyCode={item.currency}
                        />
                      </span>
                      {!formState.paymentDetails.conversionRate.hidden &&
                        formState.documentCurrency !== formState.currency && (
                          <div className="text-gray">
                            <FormatAmount
                              value={item.convertedDueAmount}
                              currencyCode={formState.currency}
                            />
                          </div>
                        )}
                    </div>
                    <div
                      className="flex justify-start items-center"
                      style={{ width: '25%' }}
                    >
                      <DKInput
                        type={INPUT_TYPE.NUMBER}
                        hasError={item.hasError}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        onChange={(event: any) =>
                          updateTotal(event, index, item)
                        }
                        value={item.paymentAmount}
                        errorMessage={item.errorMsg}
                        required={true}
                        canValidate={item.hasError}
                        validator={(value: string) => {
                          return !item.hasError;
                        }}
                      />
                    </div>
                    {formState.documentItems &&
                      formState.documentItems.length > 2 && (
                        <div
                          className="ml-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => inlineItemDelete(index)}
                        >
                          <img
                            src={ic_delete}
                            alt="delete"
                            style={{ width: 16, height: 16, margin: '0 auto' }}
                          />
                        </div>
                      )}
                  </div>
                );
              })
            : ''}
        </div>
      </>
    );
  };

  const showCurrencyConvertion = () => {
    if (formState.currency === tenantInfo.currency) {
      return false;
    } else if (formState.documentCurrency !== tenantInfo.currency) {
      return true;
    } else {
      return false;
    }
  };

  const paymentForm = () => {
    return (
      <div className="parent-width">
        <div className="flex flex-row  parent-width">
          <div style={{ width: '33%' }}>
            <DKLabel
              text="Contact"
              className="text-grey-800"
              style={{
                marginBottom: '6px',
                marginTop: '7px'
              }}
            />
            <div className="">
              <AsyncSelect
                isDisabled={true}
                cacheOptions
                styles={newTaxSearchSelectStyle1}
                placeholder={t(`DOCUMENT.SELECT_CONTACT`)}
                defaultOptions
                value={contact}
                //styles={customStylesForContact}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e}
                loadOptions={loadContacts}
                onInputChange={setContactInputVal}
                onChange={setContact}
              />
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className="flex">
              <DKLabel
                text="Currency"
                className="text-grey-800"
                style={{
                  marginBottom: '4px',
                  marginRight: '2px',
                  marginTop: '7px'
                }}
              />
              {currencySelection && <DKSpinner iconClassName="ic-s" />}
            </div>
            {
              <CurrencyDropdown
                isDisabled={currencySelection}
                currencyCode={formState.currency}
                getInfo={grabInfo}
                width={'100%'}
              />
            }
          </div>
          <div className="ml-xl" style={{ width: '33%' }}>
            {/* <div className="" style={{ width: '250px' }}> */}
            <DKLabel
              text="Total Amount"
              className="text-grey-800"
              style={{
                marginBottom: '6px',
                marginTop: '7px'
              }}
            />
            <DKInput
              value={NumberFormatService.getNumber(formState.totalPayment)}
              isDisabled={true}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              readOnly={true}
            />
          </div>
        </div>

        {showCurrencyConvertion() && (
          <>
            {!formState.paymentDetails.conversionRate.hidden && (
              <>
                <div className="fs-r text-align-left fw-m fs-l pt-4">
                  {t(`BULK_PAYMENT_POPUP.CONVERT_SETTLE_OUTSTANDING_LABEL`)}
                </div>
                <div className="flex flex-row justify-content-between parent-width py-3">
                  <div>
                    <div>
                      <label className="mr-2 text-gray-500">
                        {t(`BULK_PAYMENT_POPUP.RECEIVED_CURRENCY`)}
                      </label>
                    </div>
                    <div
                      className="bg-gray-100 border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-3.5 focus:outline-none focus:ring-2 focus:ring-blue-500 flex align-items-center justify-between"
                      style={{ maxWidth: '200px' }}
                    >
                      <div className="currency-select-trigger">
                        <div className="currency-dropdown-flag">
                          <span
                            className={`currency-dropdown-selector-flag flag ${formState.currency}`}
                          ></span>
                        </div>
                        <div className="currency-dropdown-code">
                          {formState.currency}
                        </div>
                      </div>
                      <input
                        className="text-right w-full bg-gray-100 type-number-arrow-none"
                        style={{ minHeight: '38px' }}
                        value={formState.totalPayment}
                        type="number"
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="w-80">
                    <label className="mr-2 text-gray-500">
                      {t(`BULK_PAYMENT_POPUP.CURRENCY_RATE`)}
                    </label>
                    <div className="w-full bg-gray-100 p-2 text-gray-500 cursor-pointer flex justify-center">
                      <div>
                        <span className="text-xs text-left">{`${formState.currency}`}</span>{' '}
                        <span className="fw-m fs-l px-2">1</span>
                      </div>
                      <img
                        src={rounded_exchange}
                        alt="rounded_exchange"
                        style={{ width: 18, height: 18, margin: '0 10px' }}
                      />
                      <div>
                        <span className="text-xs text-right">
                          {formState.documentCurrency}
                        </span>
                        <span className="fw-m fs-l px-2">
                          {formState.paymentDetails.conversionRate.defaultValue
                            ? formState.paymentDetails.conversionRate
                                .defaultValue
                            : 1}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label className="mr-2 text-gray-500">
                        {t(`BULK_PAYMENT_POPUP.DOCUMENT_CURRENCY`)}
                      </label>
                    </div>
                    <div
                      className="bg-gray-100 border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-3.5 focus:outline-none focus:ring-2 focus:ring-blue-500 flex align-items-center justify-between"
                      style={{ maxWidth: '200px' }}
                    >
                      <div className="currency-select-trigger">
                        <div className="currency-dropdown-flag">
                          <span
                            className={`currency-dropdown-selector-flag flag ${formState.documentCurrency}`}
                          ></span>
                        </div>
                        <div className="currency-dropdown-code">
                          {formState.documentCurrency}
                        </div>
                      </div>
                      <input
                        className="text-right w-full bg-gray-100 type-number-arrow-none"
                        style={{ minHeight: '38px' }}
                        value={formState.totalDueAmount}
                        type="number"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {showReportingConversionRate() && (
              <>
                <div className="fs-r text-align-left fw-m fs-l pt-4">
                  {t(`BULK_PAYMENT_POPUP.CONVERT_FOR_REPORTING`)}
                </div>
                <div className="flex flex-row justify-content-between parent-width py-3">
                  <div>
                    <div>
                      <label className="mr-2 text-gray-500">
                        {t(`BULK_PAYMENT_POPUP.RECEIVED_CURRENCY`)}
                      </label>
                    </div>
                    <div
                      className="bg-gray-100 border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-3.5 focus:outline-none focus:ring-2 focus:ring-blue-500 flex align-items-center justify-between"
                      style={{ maxWidth: '200px' }}
                    >
                      <div className="currency-select-trigger">
                        <div className="currency-dropdown-flag">
                          <span
                            className={`currency-dropdown-selector-flag flag ${formState.currency}`}
                          ></span>
                        </div>
                        <div className="currency-dropdown-code">
                          {formState.currency}
                        </div>
                      </div>
                      <input
                        className="text-right w-full bg-gray-100 type-number-arrow-none"
                        style={{ minHeight: '38px' }}
                        value={formState.totalPayment}
                        type="number"
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="w-80">
                    <label className="mr-2 text-gray-500">
                      {t(`BULK_PAYMENT_POPUP.CURRENCY_RATE`)}
                    </label>
                    <div className="w-full bg-gray-100 p-2 text-gray-500 cursor-pointer flex justify-center">
                      <div>
                        <span className="text-xs text-left">{`${formState.currency}`}</span>
                        <span className="fw-m fs-l px-2">1</span>
                      </div>
                      <img
                        src={rounded_exchange}
                        alt="rounded_exchange"
                        style={{ width: 18, height: 18, margin: '0 10px' }}
                      />
                      <div>
                        <span className="text-xs text-right">
                          {tenantInfo.currency}
                        </span>
                        <span className="fw-m fs-l px-2">
                          {formState.baseExchangeRate
                            ? formState.baseExchangeRate
                            : 1}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label className="mr-2 text-gray-500">
                        {t(`BULK_PAYMENT_POPUP.BASE_CURRENCY`)}
                      </label>
                    </div>
                    <div
                      className="bg-gray-100 border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-3.5 focus:outline-none focus:ring-2 focus:ring-blue-500 flex align-items-center justify-between"
                      style={{ maxWidth: '200px' }}
                    >
                      <div className="currency-select-trigger">
                        <div className="currency-dropdown-flag">
                          <span
                            className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                          ></span>
                        </div>
                        <div className="currency-dropdown-code">
                          {tenantInfo.currency}
                        </div>
                      </div>
                      <input
                        className="text-right w-full bg-gray-100 type-number-arrow-none"
                        style={{ minHeight: '38px' }}
                        value={formState.baseTotalAmount}
                        type="number"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {getDocumentsRow()}
      </div>
    );
  };

  const getCurrentStepChild = () => {
    switch (currentIndex) {
      case 0:
        return paymentForm();
      case 1:
        return paymentDetailsForm();
      case 2:
        return paymentInfo();
      default:
        break;
    }
  };

  const isInvalidStep = (expectedStep: number) => {
    if (expectedStep === 0) return false;

    /* Check if component has required data for expected step */
    if (expectedStep === 1) {
      return paymentFormValidation();
    } else if (expectedStep === 2) {
      if (paymentFormValidation()) {
        setCurrentIndex(0);
        return true;
      } else if (paymentDetailsValidation()) {
        setCurrentIndex(1);
        return true;
      }
    }

    return false;
  };

  const paymentFormValidation = () => {
    return (
      !formState.currency ||
      !contact ||
      formState.documentItems.length === 0 ||
      formState.documentItems.some((e) => e.hasError)
    );
  };

  const paymentDetailsValidation = () => {
    return (
      !formState.paymentDetails.payFrom.value.value ||
      formState.paymentDetails.payFrom.value.value === 'reset' ||
      (accountPayee.includes(formState.paymentDetails.payFrom.value.value) &&
        !formState.paymentDetails.paymentType.value.value)
    );
  };

  const onSkipToStep = (event: any, index: any) => {
    /* By default expected step is to move forward */
    const expectedStep = index ?? currentIndex + 1;

    if (isInvalidStep(expectedStep)) {
      const errorInfo = i18n.t(`BULK_PAYMENT_POPUP.STEP_VALIDATION_ERROR`);
      // showToast(errorInfo, TOAST_TYPE.FAILURE);
    } else {
      setCurrentIndex(expectedStep);
    }
  };

  const getControllers = () => {
    const isLastStep = currentIndex === stepsData.length - 1;
    return isLastStep ? null : (
      <div className="row parent-width justify-content-center mb-l mt-auto">
        {currentIndex !== 0 && (
          <DKButton
            title={t(`BULK_PAYMENT_POPUP.BACK`)}
            className="border-m ml-r bg-white"
            onClick={(e: any) => onSkipToStep(e, currentIndex - 1)}
          />
        )}
        <DKButton
          title={t(`BULK_PAYMENT_POPUP.CANCEL`)}
          className="border-m ml-r bg-white"
          onClick={() => {
            if (props.closePayment) {
              props.closePayment({
                type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
              });
            }
          }}
        />
        <DKButton
          disabled={currencySelection}
          title={t(`BULK_PAYMENT_POPUP.CONTINUE`)}
          className={`ml-r  ${
            currencySelection ? `bg-gray1` : `bg-button text-white`
          }`}
          onClick={onSkipToStep}
        />
      </div>
    );
  };

  const bulkPaymentHeader = () => {
    return (
      <div
        style={{ zIndex: 4 }}
        className="row justify-content-between p-h-r p-v-m bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel
            text={
              props.documentType === DOC_TYPE.INVOICE
                ? t(`BULK_PAYMENT_POPUP.RECEIVE_BULK_PAYMENT_TITLE`)
                : t(`BULK_PAYMENT_POPUP.MAKE_BULK_PAYMENT_TITLE`)
            }
            className="fw-m fs-l"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 900,
          width: '80%',
          maxHeight: '95%',
          minHeight: 500,
          padding: 0
        }}
      >
        {bulkPaymentHeader()}
        <div className="flex flex-col mt-3 p-4 parent-width">
          <Stepper
            data={stepsData}
            currentIndex={currentIndex}
            className="mb-l"
          />
          <div className="row parent-width p-4 justify-content-center">
            {stepsData && stepsData.length > 0 && getCurrentStepChild()}
          </div>
          {getControllers()}
        </div>
      </div>
    </div>
  );
}
