import { parseISO } from 'date-fns';
import { BOOKS_DATE_FORMAT, TRACKING_TYPE } from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';
import Utility from '../Utility/Utility';

export class ReceiveGoodsItemsPayload {
  documentItemCode: string;
  receiptQuantity: number;
  productCode: string;
  productId: string;
  productDescription: string;
  productName: string;
  quantityRequired: number;
  pendingQuantity: number = 0;
  receiptDate: string;
  purchaseOrderItemCode: string;
  warehouseCode: any;
  availableQuantity: number;
  advancedTracking: string;
  advancedTrackingType: string;
  advancedTrackingMetaData: Array<any> = [];
  documentUom: number;
  documentUOMSchemaDefinition: any;
  uomReceiptQuantity: number;
  uomQuantityRequired: number;
  uomAvailableQuantity: number;
  warehouseInventoryData: Array<any> = [];
  productType: string;
  customField?: Array<any> = [];
  rawMaterialToConsume?: Array<any> = [];
  product?: any;
  productQtyForWhichRmDispatched?: any;
  receivedQuantityInOrder?: any;
  maxQtyToReceive?: any;
  unlocalizedDocumentUOMSchemaDefinition?: any;
  isLocalizedUomQty?: boolean;
  localizedBaseQtyDiff?: any;
  parentDocumentUOMSchemaDefinition?: any;

  constructor(props: any) {
    this.documentItemCode = Utility.getValue(
      props.documentItemCode,
      props.purchaseOrderItemCode,
      props.purchaseInvoiceItemCode,
      props.jobWorkOutOrderItemCode
    );
    this.productCode = props.productCode;
    this.productId = Utility.getValue(props.product.pid) || '';
    this.productDescription = props.productDescription;
    this.productName = Utility.getValue(props.productName, props.product.name);
    this.receiptDate = props.receiptDate;
    this.availableQuantity = Number(props.availableQuantity) || 0;
    this.productType = Utility.getValue(props.product.type);
    this.customField = props.customField;
    this.advancedTracking =
      Utility.getValue(
        props.product.advancedTracking,
        props.advancedTracking,
        props.advancedTrackingType
      ) || TRACKING_TYPE.NONE;
    this.advancedTrackingType =
      Utility.getValue(
        props.advancedTrackingType,
        props.advancedTracking,
        props.product.advancedTracking
      ) || TRACKING_TYPE.NONE;
    this.receiptQuantity = props?.isLocalizedUomQty
      ? Number(props?.receiptQuantityForSeparateColumnDisplay)
      : Number(props.receiptQuantity) || props.productQuantity || 0;
    this.quantityRequired =
      props.quantityRequired ||
      props.pendingQuantity ||
      props.productQuantity ||
      0;
    this.purchaseOrderItemCode =
      props.purchaseOrderItemCode ||
      props.purchaseInvoiceItemCode ||
      props.jobWorkOutOrderItemCode ||
      '';
    this.warehouseCode = props.warehouseCode;
    this.advancedTrackingMetaData = props.advancedTrackingMetaData
      ? props.advancedTrackingMetaData.map((item: any) => {
          return {
            ...item,
            manufacturingDate: item.manufacturingDate,
            expiryDate: item.expiryDate,
            rowCode: item?.row?.code,
            rackCode: item?.rack?.code,
            binCode: item?.bin?.code
          };
        })
      : [];
    this.documentUom = props.documentUom;
    this.documentUOMSchemaDefinition = props.documentUOMSchemaDefinition;
    this.uomReceiptQuantity =
      Number(props.uomReceiptQuantity) ||
      props.uomQuantity ||
      Utility.getUomQuantity(
        this.receiptQuantity,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    this.uomQuantityRequired =
      props.uomQuantityRequired ||
      props.uomQuantity ||
      Utility.getUomQuantity(
        this.quantityRequired,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    this.uomAvailableQuantity =
      Number(props.uomAvailableQuantity) ||
      Utility.getUomQuantity(
        props.availableQuantity,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    if (
      props.warehouseInventoryData &&
      props.warehouseInventoryData.length > 0
    ) {
      this.warehouseInventoryData = props.warehouseInventoryData?.map(
        (whInventoryData: any) => {
          const {
            warehouse,
            rowData,
            rackData,
            binData,
            row,
            rack,
            bin,
            ...newWHData
          } = whInventoryData;
          return {
            ...newWHData,
            rowCode: whInventoryData?.row?.code,
            rackCode: whInventoryData?.rack?.code,
            binCode: whInventoryData?.bin?.code
          };
        }
      );
    } else {
      this.warehouseCode = null;
      const qty = props.documentUOMSchemaDefinition
        ? parseFloat(props.uomReceiptQuantity)
        : this.receiptQuantity;
      this.warehouseInventoryData = [
        {
          quantity: qty,
          localizedQuantity: props?.isLocalizedUomQty
            ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                parseFloat(props.uomReceiptQuantity),
                props.documentUOMSchemaDefinition
              )
            : 0,
          warehouseCode: props.warehouseCode
        }
      ];
    }

    if (
      props.advancedTracking === TRACKING_TYPE.SERIAL ||
      props.advancedTracking === TRACKING_TYPE.BATCH
    ) {
      this.warehouseCode = null;
      this.warehouseInventoryData = [];
    }
    this.rawMaterialToConsume = props.rawMaterialToConsume || [];
    this.product = props.product;
    this.productQtyForWhichRmDispatched = props.productQtyForWhichRmDispatched;
    this.maxQtyToReceive = props.maxQtyToReceive;
    this.receivedQuantityInOrder = props.receivedQuantityInOrder;

    // localized uom keys
    this.unlocalizedDocumentUOMSchemaDefinition = props?.isLocalizedUomQty
      ? props?.unlocalizedDocumentUOMSchemaDefinition
      : null;
    this.isLocalizedUomQty = props?.isLocalizedUomQty;
    this.localizedBaseQtyDiff = props?.localizedBaseQtyDiff;
    this.parentDocumentUOMSchemaDefinition =
      props?.parentDocumentUOMSchemaDefinition;
  }
}
