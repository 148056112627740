import React, { Component, useEffect, useState } from 'react';
import { DKLabel, DKLine, DKIcon } from 'deskera-ui-library';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import AppManager from '../../Managers/AppManager';
import ic_profile_pic from '../../Assets/menu/ic_profile_pic.png';
import ApiConstants from '../../Constants/ApiConstants';
import AuthService from '../../Services/Auth';
import { useTranslation } from 'react-i18next';
import { getCapitalized } from '../../Utility/Utility';
import { localizedText } from '../../Services/Localization/Localization';
import SettingsNew from '../Settings/SettingsNew';

interface ProfileOptionsSectionProps {
  onClose: any;
  openSettings: () => void;
}

const ProfileOptionsSection: React.FC<any> = (
  props: ProfileOptionsSectionProps
) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.onClose) {
      document.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      if (props.onClose) {
        document.removeEventListener('mouseup', onMouseUp);
      }
    };
  }, []);

  const onMouseUp = (e: any) => {
    if (props.onClose) {
      const target = e.target;
      const editingContainer = target.closest(
        '#profile-options-section, #dk-alert, #dk-alert-background'
      );

      if (!editingContainer) {
        props.onClose();
      }
    }
  };

  const getProfileSection = (detailedView = false) => {
    return (
      <div
        className={
          'row border-radius-m p-m ' + (!detailedView ? 'cursor-hand' : '')
        }
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
      >
        <DKIcon
          src={ic_profile_pic}
          className="circle ic-m shadow-s"
          style={{ opacity: 0.7 }}
        />
        {/* <DKContactIcon
          title={UserManager.getUserName()}
          className="text-white bg-app flex-shrink-0"
        /> */}
        <div className={' ml-r ' + (!detailedView ? 'text-white' : '')}>
          <DKLabel
            text={AuthService.getUserName()}
            style={{ opacity: !detailedView ? 0.9 : 1 }}
          />
          <DKLabel
            text={AuthService.getUserEmail()}
            className="mt-xs"
            style={{ opacity: 0.5, wordBreak: 'break-all' }}
          />
        </div>
      </div>
    );
  };

  const options = [
    {
      title: t(`TOP_MENU.PROFILE_OPTIONS.VIEW_OR_EDIT_PROFILE`),
      url: ApiConstants.PRODUCT_URL_GO,
      showSettingsPopup: false
    },
    {
      title: `${getCapitalized(localizedText('organisation'))} info`,
      url: '',
      showSettingsPopup: true
    },
    {
      title: t(`TOP_MENU.PROFILE_OPTIONS.PERMISSION`),
      url: ApiConstants.PRODUCT_URL_GO + 'users',
      showSettingsPopup: false
    },
    {
      title: t(`TOP_MENU.PROFILE_OPTIONS.SUBSCRIPTION_BILLING`),
      url: ApiConstants.PRODUCT_URL_GO + 'billing',
      showSettingsPopup: false
    },
    {
      title: t(`TOP_MENU.PROFILE_OPTIONS.SETTINGS`),
      url: '',
      showSettingsPopup: true
    }
  ];

  return (
    <div
      id="profile-options-section"
      className="column position-absolute bg-white shadow-l border-radius-m border-m pb-m z-10"
      style={{ top: 40, right: 0, width: 300 }}
    >
      {getProfileSection(true)}
      <DKLine className="mb-r" />
      {options.map((option) => (
        <div
          className="cursor-hand parent-width listPickerBG"
          onClick={() => {
            if (option.showSettingsPopup) {
              props.openSettings();
              props.onClose();
            } else {
              window.open(option.url);
            }
          }}
        >
          <DKLabel text={option.title} className="p-h-r p-v-s" />
        </div>
      ))}

      <DKLine className="mb-r mt-r" />
      <div
        className="cursor-hand parent-width listPickerBG"
        onClick={() => {
          AppManager.logout();
        }}
      >
        <DKLabel
          text={t(`TOP_MENU.PROFILE_OPTIONS.SIGN_OUT`)}
          className="p-h-r p-v-s text-red"
        />
      </div>
    </div>
  );
};

export default ProfileOptionsSection;
