import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import NewDataParser from "../NewDataParser"
import TenantParser from "../TenantParser"

export default class GoodsReceiptParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.goodsReceipt.customField
        CustomFieldManager.setCustomFields(dataFromAPI.goodsReceipt.customField)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        var name = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                name = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = name
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = undefined
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.address !== undefined && dataFromAPI.tenantInfo.address !== null) {
                let fromGstIn = dataFromAPI.tenantInfo?.gstin ?? dataFromAPI.gstin
                let fromPan = dataFromAPI.tenantInfo?.pan ?? dataFromAPI.pan
                fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.tenantInfo.address, dataFromAPI.tenantInfo), fromPan, fromGstIn, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        templateDataToReturn.contactInfo = dataFromAPI.parentDocumentData.contactInformation
        // billing to address
        var clientBillToAddressObj = undefined
        let shipToGstIn = dataFromAPI.tenantInfo?.gstin ?? dataFromAPI.gstin
        let shipToPan = dataFromAPI.tenantInfo?.pan ?? dataFromAPI.pan
        if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.shipTo !== undefined && dataFromAPI.parentDocumentData.shipTo !== null) {
                clientBillToAddressObj = new Address(undefined, dataFromAPI.parentDocumentData.shipTo, shipToPan, shipToGstIn, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            if (dataFromAPI.goodsReceipt.shipTo !== undefined && dataFromAPI.goodsReceipt.shipTo !== null) {
                clientBillToAddressObj = new Address(undefined, dataFromAPI.goodsReceipt.shipTo, shipToPan, shipToGstIn, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = undefined
        var clientName = undefined
        templateDataToReturn.taxRegistrationNumber = ""
        templateDataToReturn.taxPayerId = ""
        let shipFromGstIn = dataFromAPI.parentDocumentData?.contactInformation?.gstin ?? dataFromAPI.gstin
        let shipFromPan = dataFromAPI.parentDocumentData?.contactInformation?.pan ?? dataFromAPI.pan
        if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
            if (dataFromAPI.parentDocumentData.contactInformation !== undefined && dataFromAPI.parentDocumentData.contactInformation !== null) {
                if (dataFromAPI.parentDocumentData.contactInformation.name !== null) {
                    clientName = dataFromAPI.parentDocumentData.contactInformation.name
                }
            }
            if (dataFromAPI.parentDocumentData.shipFrom !== undefined && dataFromAPI.parentDocumentData.shipFrom !== null) {
                clientShipToAddressObj = new Address(clientName, dataFromAPI.parentDocumentData.shipFrom, shipFromPan, shipFromGstIn, dataFromAPI.parentDocumentData.contactInformation.contactNumber, dataFromAPI.parentDocumentData.contactInformation.documentSequenceCode, OwnerType.contact)
            }

            templateDataToReturn.taxRegistrationNumber = dataFromAPI.parentDocumentData.contactInformation.taxNumber ?? ''
            templateDataToReturn.taxPayerId = dataFromAPI.parentDocumentData.contactInformation.taxPayerIdIsrael ?? ''
        }
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            if (dataFromAPI.goodsReceipt.shipFrom !== undefined && dataFromAPI.goodsReceipt.shipFrom !== null) {
                clientShipToAddressObj = new Address(clientName, dataFromAPI.goodsReceipt.shipFrom, shipFromPan, shipFromGstIn, dataFromAPI.parentDocumentData.contactInformation.contactNumber, dataFromAPI.parentDocumentData.contactInformation.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientName = clientName
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        //ref number
        var linkDocumentNumber = ''
        var linkDocumentDate = ''
        var linkDocumentType = ''
        var goodsReceiptCode = ''
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            goodsReceiptCode = NewDataParser.getDefaultValue(dataFromAPI.goodsReceipt.goods_receipt_code)
            if (dataFromAPI.parentDocumentData !== undefined && dataFromAPI.parentDocumentData !== null) {
                linkDocumentNumber = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentCode)
                linkDocumentDate = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentDate)
                linkDocumentType = NewDataParser.getDefaultValue(dataFromAPI.parentDocumentData.documentType)
            }
        }
        templateDataToReturn.refNumber = goodsReceiptCode
        templateDataToReturn.linkDocumentNumber = linkDocumentNumber
        templateDataToReturn.linkDocumentDate = getConvertedDate(convertDateFromERPFormatToDocFormat(linkDocumentDate), templateDataToReturn.dateFormat)
        templateDataToReturn.linkDocumentType = linkDocumentType
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        //start date
        var goodsReceiptDate = ''
        if (dataFromAPI.goodsReceipt !== undefined && dataFromAPI.goodsReceipt !== null) {
            goodsReceiptDate = NewDataParser.getDefaultValue(dataFromAPI.goodsReceipt.receiptDate)
        }
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(goodsReceiptDate), templateDataToReturn.dateFormat)

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getGoodsReceiptItem(dataFromAPI, templateDataToReturn.dateFormat)

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        templateDataToReturn.createdBy = dataFromAPI.createdBy;
        templateDataToReturn.createdDate = dataFromAPI.createdDate;

        //authorised date
        if(dataFromAPI.approvedBy && dataFromAPI.createdDate) {
            templateDataToReturn.approvedDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.createdDate), templateDataToReturn.dateFormat)
        }

        // authorised by
        templateDataToReturn.approvedBy = dataFromAPI.approvedBy
        if (dataFromAPI.approvedBy !== null && dataFromAPI.approvedBy !== undefined && String(dataFromAPI.approvedBy).trim() !== "") {
            templateDataToReturn.authorised = getLocalisedText("yes")
        } else {
            templateDataToReturn.authorised = getLocalisedText("no")
        }

        return templateDataToReturn
    }
}
