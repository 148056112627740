import {
  CESS_RULE_AMOUNT,
  CESS_RULE_QUANTITY,
  EXCISE_FLAT,
  EXCISE_PERCENT,
  GST_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { DocumentItem } from '../../Models/DocumentItem';
import { FADocumentItem } from '../../Models/FADocumentItem';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import { getTenantTaxSystem } from './NewDocumentHelper';
import {
  evaluateTaxGroup,
  evaluateTaxGroupInclusiveTax,
  roundingOff,
  roundOff
} from './NewDocumentHelper';

export class FAItemTaxCalculator {
  static tenantInfo: any = Store.getState().authInfo.currentTenantInfo.data;
  static item: FADocumentItem;
  
  static setInitialValues() {
    FAItemTaxCalculator.setSubTotal();
    FAItemTaxCalculator.setDiscountAmount();
    FAItemTaxCalculator.setTotalWithDiscount();
  }

  static updateCalculatedValues() {
    FAItemTaxCalculator.setSubTotal();
    FAItemTaxCalculator.setDiscountAmount();
    FAItemTaxCalculator.setTotalWithDiscount();
    FAItemTaxCalculator.setTotal();
  }

  static setSubTotal() {
    const price = FAItemTaxCalculator.item.unitPrice
      ? FAItemTaxCalculator.item.unitPrice
      : 0;
    const quantity = FAItemTaxCalculator.item.productQuantity
      ? FAItemTaxCalculator.item.productQuantity
      : 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      FAItemTaxCalculator.item.cessPercentage =
        FAItemTaxCalculator.getCessPercentage(price);
      const amountBeforeDiscount = price * quantity;
      let itemBaseAmount = 0;
      if (
        FAItemTaxCalculator.item.unitPriceGstInclusive &&
        FAItemTaxCalculator.item.tax?.percent
      ) {
        itemBaseAmount =
          amountBeforeDiscount /
          (1 +
            ((FAItemTaxCalculator.item.gstType !== GST_TYPE.EXEMPT &&
            FAItemTaxCalculator.item.tax &&
            FAItemTaxCalculator.item.tax.percent
              ? FAItemTaxCalculator.item.tax.percent
              : 0) +
              (FAItemTaxCalculator.item.cessPercentage || 0)) /
              100);
      } else {
        itemBaseAmount = amountBeforeDiscount;
      }

      FAItemTaxCalculator.item.subTotal = itemBaseAmount;
    //   FAItemTaxCalculator.item.subTotal = itemBaseAmount;
    } else {
      let calprice =
        FAItemTaxCalculator.item.unitPriceGstInclusive &&
        FAItemTaxCalculator.item.tax?.percent
          ? price /
            (1 +
              (FAItemTaxCalculator.item.tax && FAItemTaxCalculator.item.tax.percent
                ? FAItemTaxCalculator.item.tax.percent
                : 0) /
                100)
          : price;
      FAItemTaxCalculator.item.subTotal = calprice * quantity;
    }
  }

  static setTotalWithDiscount() {
    if (
      typeof FAItemTaxCalculator.item.exciseAmount === 'undefined' ||
      FAItemTaxCalculator.item.exciseAmount === null
    ) {
      FAItemTaxCalculator.item.exciseAmount = 0;
    }

    FAItemTaxCalculator.item.totalWithDiscount =
      typeof FAItemTaxCalculator.item.subTotal === 'number' &&
      typeof FAItemTaxCalculator.item.exciseAmount === 'number' &&
      typeof FAItemTaxCalculator.item.discountAmount === 'number'
        ? FAItemTaxCalculator.item.subTotal +
          FAItemTaxCalculator.item.exciseAmount -
          FAItemTaxCalculator.item.discountAmount
        : 0;
  }

  static setTotal() {
    FAItemTaxCalculator.item.total = FAItemTaxCalculator.item.isRcmApplied
      ? (FAItemTaxCalculator.item.totalWithDiscount as number) +
        Number(
          FAItemTaxCalculator.item.otherTaxAmount
            ? FAItemTaxCalculator.item.otherTaxAmount
            : 0
        )
      : (FAItemTaxCalculator.item.totalWithDiscount as number) +
        Number(
          FAItemTaxCalculator.item.taxAmount
            ? FAItemTaxCalculator.item.taxAmount
            : 0
        );
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      FAItemTaxCalculator.item.unitPriceGstInclusive &&
      FAItemTaxCalculator.item.taxSystem === TAX_SYSTEM.INDIA_GST
    ) {
      if (FAItemTaxCalculator.item.gstType === GST_TYPE.INTRA) {
        //CGST SGST Calculation
        let totalAfterDiscount =
          Utility.roundOffToTenantDecimalScale(
            FAItemTaxCalculator.item.subTotal || 0
          ) -
          (FAItemTaxCalculator.item.discountAmount
            ? FAItemTaxCalculator.item.discountAmount
            : 0);

        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (FAItemTaxCalculator.item.tax?.otherRate
              ? FAItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );

        otherTotalTax = Utility.roundOff(
          otherTotalTax,
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );

        let taxAmount = 0;
        if (!FAItemTaxCalculator.item.isRcmApplied) {
          taxAmount =
            Utility.roundingOff(
              FAItemTaxCalculator.item.cgstAmount
                ? FAItemTaxCalculator.item.cgstAmount
                : 0,
              FAItemTaxCalculator.tenantInfo.decimalScale
                ? FAItemTaxCalculator.tenantInfo.decimalScale
                : 2
            ) +
            Utility.roundingOff(
              FAItemTaxCalculator.item.sgstAmount
                ? FAItemTaxCalculator.item.sgstAmount
                : 0,
              FAItemTaxCalculator.tenantInfo.decimalScale
                ? FAItemTaxCalculator.tenantInfo.decimalScale
                : 2
            );
        }

        FAItemTaxCalculator.item.totalAmount =
          Utility.roundOff(
            totalAfterDiscount,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          ) +
          Utility.roundOff(
            taxAmount,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          ) +
          otherTotalTax +
          (!FAItemTaxCalculator.item.isRcmApplied &&
          FAItemTaxCalculator.item.cessAmount
            ? FAItemTaxCalculator.item.cessAmount
            : 0);
      } else if (FAItemTaxCalculator.item.gstType === GST_TYPE.INTER) {
        let totalAfterDiscount =
          Utility.roundOffToTenantDecimalScale(
            FAItemTaxCalculator.item.subTotal || 0
          ) -
          (FAItemTaxCalculator.item.discountAmount
            ? FAItemTaxCalculator.item.discountAmount
            : 0);

        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (FAItemTaxCalculator.item.tax?.otherRate
              ? FAItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );

        otherTotalTax = Utility.roundOff(
          otherTotalTax,
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );

        let taxAmount = 0;
        if (!FAItemTaxCalculator.item.isRcmApplied) {
          taxAmount = Utility.roundingOff(
            FAItemTaxCalculator.item.igstAmount
              ? FAItemTaxCalculator.item.igstAmount
              : 0,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          );
        }

        FAItemTaxCalculator.item.totalAmount =
          Utility.roundOff(
            totalAfterDiscount,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          ) +
          Utility.roundOff(
            taxAmount,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          ) +
          otherTotalTax +
          (!FAItemTaxCalculator.item.isRcmApplied &&
          FAItemTaxCalculator.item.cessAmount
            ? FAItemTaxCalculator.item.cessAmount
            : 0);
      } else if (FAItemTaxCalculator.item.gstType === GST_TYPE.EXEMPT) {
        let totalAfterDiscount =
          Utility.roundOffToTenantDecimalScale(
            FAItemTaxCalculator.item.subTotal || 0
          ) -
          (FAItemTaxCalculator.item.discountAmount
            ? FAItemTaxCalculator.item.discountAmount
            : 0);
        let otherTotalTax = Utility.roundOff(
          totalAfterDiscount *
            (FAItemTaxCalculator.item.tax?.otherRate
              ? FAItemTaxCalculator.item.tax?.otherRate
              : 0 / 100),
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );

        otherTotalTax = Utility.roundOff(
          otherTotalTax,
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        );
        FAItemTaxCalculator.item.totalAmount =
          Utility.roundOff(
            totalAfterDiscount,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          ) +
          otherTotalTax +
          (!FAItemTaxCalculator.item.isRcmApplied &&
          FAItemTaxCalculator.item.cessAmount
            ? FAItemTaxCalculator.item.cessAmount
            : 0);
      }
    } else {
      FAItemTaxCalculator.item.totalAmount = Utility.roundOff(
        FAItemTaxCalculator.item.total,
        FAItemTaxCalculator.tenantInfo.decimalScale
          ? FAItemTaxCalculator.tenantInfo.decimalScale
          : 2
      );
      //   FAItemTaxCalculator.item.totalAmount = FAItemTaxCalculator.item.total;
    }
  }

  static setDiscountAmount() {
    if (
      typeof FAItemTaxCalculator.item.discount !== 'undefined' &&
      FAItemTaxCalculator.item.discount !== null
    ) {
      if (FAItemTaxCalculator.item.discountInPercent) {
        if (FAItemTaxCalculator.item.unitPriceGstInclusive) {
          let baseAmount = Utility.roundOff(
            FAItemTaxCalculator.item.subTotal
              ? FAItemTaxCalculator.item.subTotal
              : 0,
            FAItemTaxCalculator.tenantInfo.decimalScale
              ? FAItemTaxCalculator.tenantInfo.decimalScale
              : 2
          );

          if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
            FAItemTaxCalculator.item.discountAmount = Utility.roundOff(
              Utility.roundOff(
                baseAmount,
                FAItemTaxCalculator.tenantInfo.decimalScale
                  ? FAItemTaxCalculator.tenantInfo.decimalScale
                  : 2
              ) *
                (FAItemTaxCalculator.item.discount / 100),
              FAItemTaxCalculator.tenantInfo.decimalScale
                ? FAItemTaxCalculator.tenantInfo.decimalScale
                : 2
            );
          } else {
            FAItemTaxCalculator.item.discountAmount = Utility.roundOff(
              Utility.roundOff(
                FAItemTaxCalculator.item.subTotal
                  ? FAItemTaxCalculator.item.subTotal
                  : 0,
                FAItemTaxCalculator.tenantInfo.decimalScale
                  ? FAItemTaxCalculator.tenantInfo.decimalScale
                  : 2
              ) *
                (FAItemTaxCalculator.item.discount / 100),
              FAItemTaxCalculator.tenantInfo.decimalScale
                ? FAItemTaxCalculator.tenantInfo.decimalScale
                : 2
            );
          }
        } else {
          FAItemTaxCalculator.item.discountAmount = roundOff(
            ((FAItemTaxCalculator.item.subTotal as number) *
              Number(FAItemTaxCalculator.item.discount)) /
              100
          );
        }
      } else {
        FAItemTaxCalculator.item.discountAmount = Number(
          FAItemTaxCalculator.item.discount
        );
      }
    } else {
      FAItemTaxCalculator.item.discountAmount = 0;
    }
  }

  static getCessPercentage(unitPrice: number) {
    const cessAmount = FAItemTaxCalculator.calculateCessAmount(unitPrice);
    return (cessAmount / unitPrice) * 100;
  }

  static getItemTaxPercent() {
    return FAItemTaxCalculator.item.tax && FAItemTaxCalculator.item.tax.percent
      ? FAItemTaxCalculator.item.tax.percent
      : 0;
  }

  static calculateExciseAmount() {
    if (
      FAItemTaxCalculator.item.exciseRate &&
      FAItemTaxCalculator.item.exciseType
    ) {
      switch (FAItemTaxCalculator.item.exciseType) {
        case EXCISE_PERCENT:
          FAItemTaxCalculator.item.exciseAmount =
            typeof FAItemTaxCalculator.item.subTotal === 'number'
              ? roundOff(
                  (FAItemTaxCalculator.item.subTotal *
                    FAItemTaxCalculator.item.exciseRate) /
                    100
                )
              : 0;
          break;
        case EXCISE_FLAT:
          let quantity = FAItemTaxCalculator.item.productQuantity
            ? FAItemTaxCalculator.item.productQuantity
            : 0;
          FAItemTaxCalculator.item.exciseAmount = roundOff(
            quantity * FAItemTaxCalculator.item.exciseRate
          );
          break;
      }
    }
  }

  // Main method for triggering tax calculation
  static calculateTaxAmount = () => {
    let tax: number = 0;
    switch (FAItemTaxCalculator.item.taxSystem) {
      case TAX_SYSTEM.INDIA_GST:
        tax = FAItemTaxCalculator.calculateIndiaTax() as number;
        break;
      case TAX_SYSTEM.SG:
      case TAX_SYSTEM.AUSTRALIA:
      case TAX_SYSTEM.NZ:
      case TAX_SYSTEM.NL:
      case TAX_SYSTEM.BE:
      case TAX_SYSTEM.CANADA:
      case TAX_SYSTEM.UK:
      case TAX_SYSTEM.DE:
        tax = FAItemTaxCalculator.calculateTaxGroup();
        break;
      default:
        const calculateTax =
          (typeof FAItemTaxCalculator.item.taxPreference === 'boolean' &&
            FAItemTaxCalculator.item.taxPreference) ||
          typeof FAItemTaxCalculator.item.taxPreference === 'undefined' ||
          FAItemTaxCalculator.item.taxPreference === null;
        if (calculateTax) {
          tax =
            FAItemTaxCalculator.item.tax &&
            FAItemTaxCalculator.item.tax.percent !== undefined
              ? FAItemTaxCalculator.getTaxInPercent(
                  FAItemTaxCalculator.item.tax.percent
                )
              : FAItemTaxCalculator.item.taxAmount
              ? FAItemTaxCalculator.item.taxAmount
              : 0;
        } else {
          tax = 0;
        }

        break;
    }
    return tax;
  };

  static getTaxInPercent(percent: number) {
    // Commenting this to revert to old calculation

    // if (FAItemTaxCalculator.item.unitPriceGstInclusive) {
    //   if (FAItemTaxCalculator.item.unitPrice) {
    //     return percent
    //       ? roundOff(
    //           FAItemTaxCalculator.item.unitPrice -
    //             FAItemTaxCalculator.item.unitPrice / (1 + percent / 100)
    //         )
    //       : 0;
    //   }
    // } else
    if (FAItemTaxCalculator.item.totalWithDiscount) {
      return percent
        ? roundOff((FAItemTaxCalculator.item.totalWithDiscount * percent) / 100)
        : 0;
    }
    return 0;
  }

  static getTaxInPercentForCalculation(percent: number) {
    return percent
      ? roundOff(
          (FAItemTaxCalculator.item.totalWithDiscount
            ? FAItemTaxCalculator.item.totalWithDiscount * percent
            : 0 * percent) / 100
        )
      : 0;
  }

  // Calculate Indian tax
  static calculateIndiaTax() {
    FAItemTaxCalculator.item.cgstRate = 0;
    FAItemTaxCalculator.item.sgstRate = 0;
    FAItemTaxCalculator.item.igstRate = 0;
    FAItemTaxCalculator.item.otherTaxAmount = 0;
    FAItemTaxCalculator.item.otherRate = 0;
    FAItemTaxCalculator.item.rcmRate = 0;
    FAItemTaxCalculator.item.taxDetails = [];
    if (FAItemTaxCalculator.item.tax) {
      FAItemTaxCalculator.item.rcmRate =
        FAItemTaxCalculator.item.tax && FAItemTaxCalculator.item.tax.percent
          ? FAItemTaxCalculator.item.tax.percent
          : 0;
      switch (FAItemTaxCalculator.item.gstType) {
        case GST_TYPE.INTER:
          FAItemTaxCalculator.item.igstRate =
            FAItemTaxCalculator.item.compositionTaxPercent &&
            FAItemTaxCalculator.item.compositionTaxPercent > 0
              ? FAItemTaxCalculator.item.compositionTaxPercent
              : FAItemTaxCalculator.item.tax && FAItemTaxCalculator.item.tax.percent
              ? FAItemTaxCalculator.item.tax.percent
              : 0;
          FAItemTaxCalculator.item.rcmRate = FAItemTaxCalculator.item.igstRate;
          break;
        case GST_TYPE.INTRA:
          const halfGstRate =
            (FAItemTaxCalculator.item.compositionTaxPercent &&
            FAItemTaxCalculator.item.compositionTaxPercent > 0
              ? FAItemTaxCalculator.item.compositionTaxPercent
              : FAItemTaxCalculator.item.tax && FAItemTaxCalculator.item.tax.percent
              ? FAItemTaxCalculator.item.tax.percent
              : 0) / 2;
          FAItemTaxCalculator.item.cgstRate = halfGstRate;
          FAItemTaxCalculator.item.sgstRate = halfGstRate;
          FAItemTaxCalculator.item.rcmRate =
            FAItemTaxCalculator.item.cgstRate + FAItemTaxCalculator.item.sgstRate;
          break;
        case GST_TYPE.EXEMPT:
          FAItemTaxCalculator.item.cgstRate = 0;
          FAItemTaxCalculator.item.sgstRate = 0;
          FAItemTaxCalculator.item.igstRate = 0;
          break;
      }
      //   FAItemTaxCalculator.item.cgstAmount =
      //     FAItemTaxCalculator.getTaxInPercentForCalculation(
      //       FAItemTaxCalculator.item.cgstRate
      //     );
      //   FAItemTaxCalculator.item.sgstAmount =
      //     FAItemTaxCalculator.getTaxInPercentForCalculation(
      //       FAItemTaxCalculator.item.sgstRate
      //     );
      if (FAItemTaxCalculator.item.userSetTaxes) {
        FAItemTaxCalculator.item.igstAmount =
          FAItemTaxCalculator.item.igstAmount || 0;
      } else {
        FAItemTaxCalculator.item.igstAmount = FAItemTaxCalculator.item.igstRate
          ? FAItemTaxCalculator.getTaxInPercentForCalculation(
              FAItemTaxCalculator.item.igstRate
            )
          : 0;
      }

      if (FAItemTaxCalculator.item.unitPriceGstInclusive) {
        let totalAfterDiscount =
          Utility.roundOffToTenantDecimalScale(
            FAItemTaxCalculator.item.subTotal || 0
          ) -
          (FAItemTaxCalculator.item.discountAmount
            ? FAItemTaxCalculator.item.discountAmount
            : 0);

        let totalGSTTax = 0;

        if (FAItemTaxCalculator.item.userSetTaxes) {
          if (FAItemTaxCalculator.item?.gstType === GST_TYPE.INTER) {
            totalGSTTax = FAItemTaxCalculator.item.igstAmount || 0;
          }
          if (FAItemTaxCalculator.item?.gstType === GST_TYPE.INTRA) {
            totalGSTTax =
              (FAItemTaxCalculator.item?.cgstAmount || 0) +
              (FAItemTaxCalculator.item?.sgstAmount || 0);
          }
        } else {
          totalGSTTax = Utility.roundOffToTenantDecimalScale(
            (totalAfterDiscount *
              (FAItemTaxCalculator.item?.gstType !== GST_TYPE.EXEMPT &&
              FAItemTaxCalculator.item?.tax?.percent
                ? FAItemTaxCalculator.item?.tax?.percent
                : 0)) /
              100
          );
        }

        FAItemTaxCalculator.item.cgstAmount =
          FAItemTaxCalculator.item.cgstRate && FAItemTaxCalculator.item.cgstRate > 0
            ? Utility.roundOffToTenantDecimalScale(totalGSTTax / 2)
            : 0;
        FAItemTaxCalculator.item.sgstAmount = FAItemTaxCalculator.item.cgstAmount;

        FAItemTaxCalculator.item.igstAmount =
          FAItemTaxCalculator.item.igstRate && FAItemTaxCalculator.item.igstRate > 0
            ? Utility.roundOffToTenantDecimalScale(totalGSTTax)
            : 0;
        FAItemTaxCalculator.item.cessAmount = !FAItemTaxCalculator.item.userSetTaxes
          ? FAItemTaxCalculator.calculateCessAmount()
          : FAItemTaxCalculator.item.cessAmount;
      } else {
        FAItemTaxCalculator.item.cgstAmount =
          FAItemTaxCalculator.item.cgstRate && FAItemTaxCalculator.item.cgstRate > 0
            ? Utility.roundingOff(
                ((FAItemTaxCalculator.item.totalWithDiscount
                  ? FAItemTaxCalculator.item.totalWithDiscount
                  : 0) *
                  (FAItemTaxCalculator.item.tax &&
                  FAItemTaxCalculator.item.tax.percent
                    ? FAItemTaxCalculator.item.tax.percent
                    : 0)) /
                  100,
                FAItemTaxCalculator.tenantInfo.decimalScale
                  ? FAItemTaxCalculator.tenantInfo.decimalScale
                  : 2
              ) / 2
            : 0;
        FAItemTaxCalculator.item.sgstAmount = FAItemTaxCalculator.item.cgstAmount;

        FAItemTaxCalculator.item.cessAmount = !FAItemTaxCalculator.item.userSetTaxes
          ? FAItemTaxCalculator.calculateCessAmount()
          : FAItemTaxCalculator.item.cessAmount;
      }
      return (
        Utility.roundingOff(
          FAItemTaxCalculator.item.cgstAmount,
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        ) +
        Utility.roundingOff(
          FAItemTaxCalculator.item.sgstAmount,
          FAItemTaxCalculator.tenantInfo.decimalScale
            ? FAItemTaxCalculator.tenantInfo.decimalScale
            : 2
        ) +
        (FAItemTaxCalculator.item.igstAmount || 0) +
        (FAItemTaxCalculator.item.cessAmount
          ? FAItemTaxCalculator.item.cessAmount
          : 0)
      );
    }
  }

  static calculateCessAmount(totalAfterDiscount?: number) {
    let cessAmount = 0;
    const taxableAmount =
      typeof totalAfterDiscount === 'number'
        ? totalAfterDiscount
        : FAItemTaxCalculator.item.totalWithDiscount;
    if (FAItemTaxCalculator.item.cessRule && taxableAmount) {
      let cessExpression = '';
      if (FAItemTaxCalculator.item.productQuantity) {
        cessExpression = FAItemTaxCalculator.item.cessRule.replace(
          CESS_RULE_QUANTITY,
          `${FAItemTaxCalculator.item.productQuantity}`
        );
      }

      if (taxableAmount) {
        cessExpression = cessExpression
          ? cessExpression.replace(CESS_RULE_AMOUNT, `${taxableAmount}`)
          : FAItemTaxCalculator.item.cessRule.replace(
              CESS_RULE_AMOUNT,
              `${taxableAmount}`
            );
      }
      if (cessExpression) {
        cessAmount = eval(cessExpression);
      }
    }
    return roundOff(cessAmount);
  }

  static calculateTaxGroup() {
    let taxDetails;
    if (!FAItemTaxCalculator.item.unitPriceGstInclusive) {
      taxDetails = evaluateTaxGroup(
        FAItemTaxCalculator.item.tax,
        FAItemTaxCalculator.item.totalWithDiscount as number
      );
    } else {
      const totalAmount =
        (FAItemTaxCalculator.item.unitPrice
          ? FAItemTaxCalculator.item.unitPrice
          : 0) *
        (FAItemTaxCalculator.item.productQuantity
          ? FAItemTaxCalculator.item.productQuantity
          : 0);
      const totalTax = roundOff(
        totalAmount -
          (typeof FAItemTaxCalculator.item.subTotal === 'number'
            ? FAItemTaxCalculator.item.subTotal
            : 0)
      );
      taxDetails = evaluateTaxGroupInclusiveTax(
        FAItemTaxCalculator.item.tax,
        FAItemTaxCalculator.item.totalWithDiscount as number,
        totalTax
      );
    }
    FAItemTaxCalculator.item.taxDetails = taxDetails;

    let tax = 0;
    taxDetails.forEach((taxDetail: any) => (tax += taxDetail.taxAmount));
    return tax;
  }

  static calculateGSTTax() {
    FAItemTaxCalculator.item.cgstAmount = 0;
    FAItemTaxCalculator.item.sgstAmount = 0;
    FAItemTaxCalculator.item.igstAmount = 0;
    FAItemTaxCalculator.item.otherTaxAmount = 0;
    let exemptAmount = 0;
    FAItemTaxCalculator.item.tax?.taxGroupDetails?.forEach((element: any) => {
      if (!element.additionalTaxIn) {
        switch (FAItemTaxCalculator.item.gstType) {
          case GST_TYPE.INTER:
            FAItemTaxCalculator.item.igstRate =
              FAItemTaxCalculator.item.compositionTaxPercent &&
              FAItemTaxCalculator.item.compositionTaxPercent > 0
                ? FAItemTaxCalculator.item.compositionTaxPercent
                : element.percentage;
            if (
              typeof FAItemTaxCalculator.item.rcmRate === 'number' &&
              typeof FAItemTaxCalculator.item.igstRate === 'number'
            ) {
              FAItemTaxCalculator.item.rcmRate += FAItemTaxCalculator.item.igstRate;
            }
            break;
          case GST_TYPE.INTRA:
            const halfGstRate =
              (FAItemTaxCalculator.item.compositionTaxPercent &&
              FAItemTaxCalculator.item.compositionTaxPercent > 0
                ? FAItemTaxCalculator.item.compositionTaxPercent
                : element.percentage) / 2;
            if (
              typeof FAItemTaxCalculator.item.cgstRate === 'number' &&
              typeof FAItemTaxCalculator.item.sgstRate === 'number' &&
              typeof FAItemTaxCalculator.item.rcmRate === 'number'
            ) {
              FAItemTaxCalculator.item.cgstRate += halfGstRate;
              FAItemTaxCalculator.item.sgstRate += halfGstRate;
              FAItemTaxCalculator.item.rcmRate +=
                FAItemTaxCalculator.item.cgstRate +
                FAItemTaxCalculator.item.sgstRate;
            } else {
              FAItemTaxCalculator.item.cgstRate = 0;
              FAItemTaxCalculator.item.sgstRate = 0;
              FAItemTaxCalculator.item.rcmRate = 0;
            }
            break;
          default:
            FAItemTaxCalculator.item.rcmRate += element.percentage;
        }
      } else {
        FAItemTaxCalculator.item.otherRate += element.percentage;
      }
    });

    FAItemTaxCalculator.item.taxDetails.forEach((taxDetail: any) => {
      if (!taxDetail.additionalTaxIn) {
        switch (FAItemTaxCalculator.item.gstType) {
          case GST_TYPE.INTER:
            FAItemTaxCalculator.item.igstAmount += taxDetail.taxAmount;

            break;
          case GST_TYPE.INTRA:
            const halfGstAmount = taxDetail.taxAmount / 2;
            if (
              typeof FAItemTaxCalculator.item.cgstAmount === 'number' &&
              typeof FAItemTaxCalculator.item.sgstAmount === 'number'
            ) {
              FAItemTaxCalculator.item.cgstAmount += halfGstAmount;
              FAItemTaxCalculator.item.sgstAmount += halfGstAmount;
            }
            break;
          case GST_TYPE.EXEMPT:
            exemptAmount += taxDetail.taxAmount;
            taxDetail.taxAmount = 0;
            break;
        }
      } else {
        FAItemTaxCalculator.item.otherTaxAmount += taxDetail.taxAmount;
      }
    });

    return exemptAmount;
  }
}
