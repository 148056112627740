import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import {
  AccountInfoState,
  ConfigPayload,
  GridState
} from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import CurrencyService from '../../Services/Currency';
import InvoiceService from '../../Services/Invoice';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchUomSchema = createAsyncThunk('fetchUomSchema', async () => {
  const response = await InvoiceService.getUomSchema();
  return response;
});

export const UomSchemaSlice = createSlice({
  name: 'uom_schema',
  initialState,
  reducers: {
    addUomSchemaColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updateUomSchemaColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addUomSchema: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUomSchema.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchUomSchema.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addUomSchema,
  addUomSchemaColumnConfig,
  updateUomSchemaColumnConfig
} = UomSchemaSlice.actions;
export const selectUomSchema = (state: RootState) => state.uomSchema.data;

export const selectActiveUomSchemaByBaseId =
  (baseId: number) => (state: RootState) => {
    const allSchemas = state.uomSchema.data?.content || [];
    let filteredSchema = allSchemas.find(
      (uomSchema: any) =>
        uomSchema.active &&
        uomSchema.baseUOM.id === baseId &&
        uomSchema.baseUOM.active
    );
    return filteredSchema;
  };
export const selectUomSchemaColumnConfig = (state: RootState) =>
  state.uomSchema.columnConfig;

export const uomSchemaColumnConfigTableId = (state: RootState) =>
  state.uomSchema.configTableId;
