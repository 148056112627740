import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import { getLocalisedText } from "../../Translate/LanguageManager";
import { COLOR_LIGHTGRAY, FONT_SIZE } from "../../Constants/Constants";
import AddressParser, { AddressType } from "../../Helper/AddressParser";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import DocumentManager from "../../Manager/DocumentManager";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { HTMLTag } from "../../Utilities/HTMLTag";

export default class GoodsReceiptNoteTemplate1 extends Component {
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            orientation: TemplateSettingsManager.defaultTemplateSettings.templateInfo.orientation
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
            orientation: TemplateSettingsManager.defaultTemplateSettings.templateInfo.orientation
        });
    }

    render() {
        // Subtracting top and bottom border height from min page height
        let minHeight = getPageHeight(this.state.data.printScale);
        if (minHeight && minHeight.includes("vw")) {
            minHeight = `${Number(minHeight.replace("vw", "")) - Number(getVW(2).replace("vw", ""))}vw`;
        }

        return (
            <div id={HTMLTag.TEMPLATE_HOLDER} className="DocumentHolder ColumnDiv parent-width"
                style={{
                    justifyContent: 'space-between',
                    minHeight: minHeight,
                    border: '1px solid black'
                }}>
                <div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
                    <div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
                        {this.getCompanySection()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        {this.getDocumentType()}
                        {this.getDocumentNumberAndDate()}
                        {this.getShippingAddressAndDocumentInfoSection()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        {
                            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                            <div className="parent-width">
                                <div
                                    dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }}
                                    style={{
                                        ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale)
                                    }}
                                />
                                {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                            </div>
                        }
                        {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        <div className="parent-width" style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        }}>
                            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                        </div>
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
                        {this.getEditableDocumentTable()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
                        {this.getTotalSection()}
                        {this.getAuthorizedAndInspection()}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        <div className="parent-width" style={{
                            paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        }}>
                            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                        </div>
                    </div>
                    <div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
                        {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                    </div>
                </div>
            </div>
        );
    }

    getCompanySection() {
        return <div className="RowDiv"
            style={{
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)), paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(70, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 4, paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#000000',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(AddressParser.getRequisitonCustomAddressString(this.state.data.fromObj)) }}>
                    </div>
                    {this.getEmailAndPhone(this.state.data)}
                </div>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }} />
        </div>
    }

    getEmailAndPhone() {
        return <div className={"docFontStyle" + getFontSizeClass()}
            style={{
                width: '100%',
                marginTop: 0,
                color: '#000000',
                textAlign: 'center',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
            }}>
            {this.state.data.tenantInfo?.email &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("email")}:`}
                    </text>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.tenantInfo?.email}`}
                    </text>
                </>
            }
            {this.state.data.tenantInfo?.contactNumber &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("phone")}:`}
                    </text>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.tenantInfo?.contactNumber}`}
                    </text>
                </>
            }
        </div>
    }

    getDocumentType() {
        return <div className="RowDiv parent-width"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale),
                    textAlign: "center",
                    width: "100%"
                }}>
                {`${getLocalisedText("material_receipt_note")}`}
            </text>
        </div>;
    }

    getDocumentNumberAndDate() {
        return <div className="RowDiv pt-s pb-s parent-width"
            style={{
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderBottom: '1px solid black'
            }}>
            <div style={{
                width: "60%",
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {ComponentManager.getRowValueSectionCustom(
                    `${getLocalisedText('mrn_no')}.`,
                    this.state.data.refNumber,
                    "20%",
                    this.state.data.themeColor,
                    undefined,
                    ":",
                    getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
                    this.state.data.printScale
                )}
            </div>
            <div style={{
                width: "40%",
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {ComponentManager.getRowValueSectionCustom(
                    getLocalisedText('date'),
                    this.state.data.date,
                    "20%",
                    this.state.data.themeColor,
                    undefined,
                    ":",
                    getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getTotalValues() {
        const totalObj = {
            subtotal: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
            gst: 0,
            total: 0
        }

        if (Array.isArray(this.state.data.lineItems)) {
            this.state.data.lineItems.forEach((item) => {
                totalObj.subtotal += item.basicAmt

                if (Array.isArray(item.taxBreakdown)) {
                    item.taxBreakdown.forEach((tax) => {
                        switch (tax.name) {
                            case "cgst":
                                totalObj.cgst += tax.amount
                                break;
                            case "sgst":
                                totalObj.sgst += tax.amount
                                break;
                            case "igst":
                                totalObj.igst += tax.amount
                                break;
                            default:
                                totalObj.gst += tax.amount
                                break;
                        }
                    })
                }
            });

            totalObj.total = totalObj.subtotal + totalObj.cgst + totalObj.sgst + totalObj.igst + totalObj.gst
        }

        return totalObj;
    }

    getRowValueSectionForTotal(title, value, showSeparator, textCls) {
        return (
            <tr>
                <td style={{ textAlign: "right" }}>
                    <text className={textCls + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(title)}
                    </text>
                </td>
                <td>{showSeparator ? ":" : ""}</td>
                <td style={{ textAlign: "right" }}>
                    <text className={textCls + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {value}
                    </text>
                </td>
            </tr>
        );
    }

    getTotalSection() {
        const totalObj = this.getTotalValues();

        return <div className="RowDiv pt-s pb-s parent-width"
            style={{
                alignItems: 'center',
                justifyContent: "flex-end",
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderBottom: '1px solid black'
            }}>
            <table style={{
                width: "50%",
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {totalObj.subtotal !== 0 && this.getRowValueSectionForTotal("total_amount", Utility.toCurrencyFormat(totalObj.subtotal, this.state.data.currency), true, "docFontStyleBold")}
                {totalObj.cgst !== 0 && this.getRowValueSectionForTotal("cgst", Utility.toCurrencyFormat(totalObj.cgst, this.state.data.currency), false, "docFontStyle")}
                {totalObj.sgst !== 0 && this.getRowValueSectionForTotal("sgst", Utility.toCurrencyFormat(totalObj.sgst, this.state.data.currency), false, "docFontStyle")}
                {totalObj.igst !== 0 && this.getRowValueSectionForTotal("igst", Utility.toCurrencyFormat(totalObj.igst, this.state.data.currency), false, "docFontStyle")}
                {totalObj.gst !== 0 && this.getRowValueSectionForTotal("gst", Utility.toCurrencyFormat(totalObj.gst, this.state.data.currency), false, "docFontStyle")}
                {totalObj.total !== 0 && this.getRowValueSectionForTotal("total_passed_amount", Utility.toCurrencyFormat(totalObj.total, this.state.data.currency), true, "docFontStyleBold")}
            </table>
        </div>;
    }

    getAuthorizedAndInspection() {
        return <div className="RowDiv pt-s pb-s parent-width"
            style={{
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderBottom: '1px solid black'
            }}>
            <div style={{
                width: "40%",
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {ComponentManager.getRowValueSectionCustom(
                    `${getLocalisedText('authorized')}`,
                    this.state.data.authorised,
                    "30%",
                    this.state.data.themeColor,
                    undefined,
                    ":",
                    getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
                    this.state.data.printScale
                )}
            </div>
            <div style={{
                width: "60%",
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {ComponentManager.getRowValueSectionCustom(
                    getLocalisedText('inspection_required'),
                    "",
                    "30%",
                    this.state.data.themeColor,
                    undefined,
                    ":",
                    getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getShippingAddressAndDocumentInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getAddressSection()}
            </div>
        )
    }

    getAddressSection() {
        return <div className="parent-width">
            <div className="parent-width" style={{ borderBottom: "1px solid black" }}>
                {this.getShipmentAddress()}
            </div>
        </div>;
    }

    getShipmentAddress() {
        return (
            <div className='RowDiv' style={{ alignItems: "flex-start", justifyContent: "space-between" }}>
                <div className='ColumnDiv'
                    style={{
                        width: '50%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        borderRight: "1px solid black"
                    }}
                >
                    {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                </div>
                <div className='ColumnDiv'
                    style={{
                        width: '50%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
                    }}>
                    <div className="ColumnDiv" style={{ width: "100%" }} >
                        {this.getDocumentDetailsSection()}
                    </div>
                </div>
            </div>
        );
    }

    getDocumentDetailsSection() {
        const documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index);
        if (selectedDetailList.length === 0) {
            return <></>
        }

        const leftDetailsList = []
        const rightDetailsList = []

        selectedDetailList.forEach((detail, index) => {
            if ((index + 1) % 2 !== 0) {
                leftDetailsList.push(detail);
            } else {
                rightDetailsList.push(detail);
            }
        });

        let bottomLeftDetailsSection = undefined
        if (leftDetailsList.length > 0) {
            bottomLeftDetailsSection = leftDetailsList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        let bottomRightDetailsSection = undefined
        if (rightDetailsList.length > 0) {
            bottomRightDetailsSection = rightDetailsList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        let bottomSection = undefined
        if (bottomLeftDetailsSection) {
            bottomSection = <div className="RowDiv parent-width">
                <div className="ColumnDiv" style={{ flex: 1, alignSelf: 'baseline' }}>
                    {bottomLeftDetailsSection}
                </div>
                {bottomRightDetailsSection && (
                    <div className="ColumnDiv" style={{ flex: 1, alignSelf: 'baseline' }}>
                        {bottomRightDetailsSection}
                    </div>
                )}
            </div>
        }

        return <div className="ColumnDiv parent-width">
            {bottomSection}
        </div>
    }

    getDocumentDetailsValue(element) {
        var value = ''
        if (element.key !== undefined) {
            value = this.state.data[element.key]
        }
        else if (element.customFieldValue !== undefined) {
            value = element.customFieldValue
        }

        return value
    }

    getSingleDetailsSection(item) {
        return ComponentManager.getRowValueSectionCustom(
            item.label,
            this.getDocumentDetailsValue(item),
            "50%",
            this.state.data.themeColor,
            undefined,
            ":",
            getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
            this.state.data.printScale
        )
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        style.headerBackgroundColor = "transparent";
        style.noPadding = false
        return style;
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = ['50%', '50%']
        return style;
    }
}
