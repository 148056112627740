import { useEffect, useState } from 'react';
import { DKLabel, Toggle, showLoader, removeLoader } from 'deskera-ui-library';
import TenantService from '../../../Services/Tenant';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';

interface IAutoCloseWO {}

const AutoCloseWO = (props: IAutoCloseWO) => {
  const [isAutoCloseWOEnabled, setIsAutoCloseWOEnabled] = useState(false);

  const dispatch = useAppDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);

  useEffect(() => {
    if (tenantDetails?.additionalSettings?.AUTO_CLOSE_CHILD_WORK_ORDER) {
      setIsAutoCloseWOEnabled(
        tenantDetails?.additionalSettings?.AUTO_CLOSE_CHILD_WORK_ORDER
      );
    }
  }, [tenantDetails?.additionalSettings?.AUTO_CLOSE_CHILD_WORK_ORDER]);

  const handleAutoCloseWOToggleClick = () => {
    const payload = {
      additionalSettings: {
        AUTO_CLOSE_CHILD_WORK_ORDER: !isAutoCloseWOEnabled
      }
    };
    showLoader();
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        removeLoader();
        setIsAutoCloseWOEnabled(
          res?.additionalSettings?.AUTO_CLOSE_CHILD_WORK_ORDER || false
        );
        dispatch(fetchCurrentTenantInfo());
      })
      .catch((err) => {
        removeLoader();
        console.log('Error updating auto close WO settings', err);
      });
  };

  return (
    <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
      <div className="column parent-width">
        <DKLabel
          text="Auto-Close child work orders upon job card completion"
          className="fw-m mb-xs"
        />
        <DKLabel
          className="text-gray"
          text="Automatically close child work orders upon job card completion"
          style={{ minWidth: 100 }}
        />
      </div>
      <div className="column" style={{ minWidth: 42 }}>
        <Toggle
          className="box-content"
          onChange={(checked: any) => {
            handleAutoCloseWOToggleClick();
          }}
          isOn={isAutoCloseWOEnabled}
        />
      </div>
    </div>
  );
};

export default AutoCloseWO;
