import { ComponentType } from "../DocumentDesignerScreen/model/Enum"
import { TemplateItem } from "../DocumentDesignerScreen/model/TemplateItem"
import { Coordinate } from "./CoordinateUtility"
import { showAlert } from "../../AlertComponent/Alert";
import {getLocalisedText} from "../../../Translate/LanguageManager";
import { getCapitalized } from "../../../Utilities/Utility";
import { AlertType } from "../../../Helper/types";

export function getMaxId(componentList: TemplateItem[]): number {
    var maxId = 0
    const componentIdList = componentList.map(x => x.id).sort((n1: number | undefined, n2: number | undefined) => {
        if (n1 === undefined || n2 === undefined) {
            return 0
        }
        if (n1 < n2) {
            return n1
        }
        else {
            return n2
        }
    })
    if (componentIdList === undefined || componentIdList === null) {
        return maxId
    }
    if (componentIdList.length === 0) {
        return maxId
    }
    else {
        const value = componentIdList[componentIdList.length - 1]
        if (value !== undefined) {
            maxId = value
        }
    }
    return maxId
}

export function getDefaultCustomFieldValue(selectedCustomField: any, data: any) {
    var val = ''

    var customFields = data.customFields
    if (selectedCustomField.defaultValue !== '') {
        val = selectedCustomField.defaultValue
    }

    if (customFields !== undefined && customFields.length > 0) {
        var customField = customFields.filter((x: { code: any; }) => x.code === selectedCustomField.code)
        if (customField.length > 0) {
            val = customField[0].value
        }
    }

    if (val === '') {
        val = getCapitalized(selectedCustomField.label)
    }
    return val
}


export function onDownloadJson(componentList: TemplateItem[]) {
    if (componentList.length === 0) {
        showAlert(AlertType.standard,
            getLocalisedText("data_missing"),
            getLocalisedText("no_data_to_export"),
        )
        return
    }
    const text = JSON.stringify(componentList)
    var jsonPretty = JSON.stringify(JSON.parse(text), null, 2);
    const type = 'application/json'
    const name = 'templateData.json'
    var file = new Blob([jsonPretty], { type: type });
    var a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
}

export function onDownloadFile(fileUrl: string) {
    var a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'thumbnail.png';
    a.click();
}

export function getBannerInfo(componentList: TemplateItem[]): TemplateItem | undefined {
    if (componentList === undefined) {
        return undefined
    }
    if (componentList.length === 0) {
        return undefined
    }

    const componentBannerList = componentList.filter(x => x.type === ComponentType.DKBanner)

    if (componentBannerList === undefined || componentBannerList === null) {
        return undefined
    }

    if (componentBannerList.length === 0) {
        return undefined
    }

    return componentBannerList[0]
}
export function getBannerImage(componentList: TemplateItem[]): any {
    var banner = getBannerInfo(componentList)

    if (banner === undefined) {
        return undefined
    }
    return banner.image
}

export function getBannerImageOpacity(componentList: TemplateItem[]): any {
    var banner = getBannerInfo(componentList)

    if (banner === undefined) {
        return undefined
    }
    return banner.style.opacity
}


export function calculateBannerOffset(componentList: TemplateItem[]): number {
    if (componentList.length === 0) {
        return 0
    }

    const componentBannerList = componentList.filter(x => x.type === ComponentType.DKBanner)

    if (componentBannerList === undefined || componentBannerList === null) {
        return 0
    }

    if (componentBannerList.length === 0) {
        return 0
    }

    return 133

}
export function toDataURL(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export function getDivWidthAndHeight(element: HTMLElement): Coordinate {
    var positionInfo = element.getBoundingClientRect();
    var height = positionInfo.height;
    var width = positionInfo.width;
    return new Coordinate(width, height, 0, 0)
}

export function getTextWidth(text: string, element?: HTMLElement | null, fontSize?: string): number | undefined {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    var font = '12px -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

    if(element !== undefined && element !== null) {
        if (fontSize !== undefined && fontSize !== undefined && fontSize !== '') {
            var fontFamily = getComputedStyle(element, null).getPropertyValue('font-family')
            font = fontSize + ' ' + fontFamily
        }
    }
    else {
        if (fontSize !== undefined && fontSize !== undefined && fontSize !== '') {
            font = fontSize + ' -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
        }
    }

    if (context === null || context === undefined) {
        return undefined
    }

    context.font = font;
    return context.measureText(text).width + 4;
}
export function getTextAreaHeight(text: string, element?: HTMLElement | null, fontSize?: string): number | undefined {
    if(fontSize === undefined || fontSize === null) {
        return undefined
    }

    var px = Number(fontSize.replace('px', ''))
    if (px === undefined || px === null)
        return undefined

    var value = text.split('\n')
    if (value.length > 0) {
        return (px + 1) * value.length
    }
    else {
        return px
    }
}
