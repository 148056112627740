import React, { Component } from "react";
import "../../index.css";
import Utility, { getCapitalized, getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";
import { getLocalisedText } from "../../Translate/LanguageManager";
import ComponentManager from "../../Manager/ComponentManager";
import { AddressType } from "../../Helper/AddressParser";
import { EditorInfoType, FONT_SIZE } from "../../Constants/Constants";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";

export default class InspectionReportTemplate1 extends Component {

    arabicHeaderWidth = '85%'
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentNumber() {
        return <div className="RowDiv"
            style={{
                justifyContent: 'end',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "rgb(248,0,8)",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data?.refNumber ?? '')}
            </text>
        </div>
    }

    getCompanyDetailsSection() {
        return <div className="RowDiv" style={{ justifyContent: 'center' }}>
            <div className="ColumnDiv" style={{ width: '100%', padding: getVW(10) }}>
                <div className="RowDiv parent-width">
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) &&
                        <div className="RowDiv" style={{ flex: 1 }}>
                            {ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                            {ComponentManager.addHorizontalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                        </div>
                    }
                    <div className="ColumnDiv" style={{ flex: 1}}>
                        {
                            this.state.data.showCompanyName &&
                            <div className="RowDiv" style={{ flex: 1 }}>
                                {ComponentManager.getDescriptionSection(
                                    getLocalisedText(Utility.getCompanyName(this.state.data)),
                                    getLocalisedText(Utility.getCompanyAddress(this.state.data)),
                                    "100%",
                                    this.state.data.themeColor,
                                    false,
                                    '#000000',
                                    getVW(0),
                                    Utility.getOwnerType(this.state.data, AddressType.from),
                                    this.state.data.printScale
                                )}
                            </div>
                        }
                        {this.getRowValueSection(
                            getLocalisedText('inspection_date'),
                            this.state.data.date ?? '',
                            true,
                            true
                        )}
                    </div>
                </div>
                {(this.state.data.showFrom && this.state.data.showClientBillToAddress) && ComponentManager.addVerticalSpace(10)}
            </div>
        </div>
    }

    getDocumentType() {
        return <div className="RowDiv"
            style={{
                justifyContent: 'center',
                borderBottom: '2px solid black',
                paddingBottom: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'center' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
            </div>
        </div>
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index)
        let newDetailsList = []

        let topDetailsSectionList = []
        if (selectedDetailList.length >= 1) {
            topDetailsSectionList.push(selectedDetailList[0])
            newDetailsList = selectedDetailList.slice(1)
        }

        let middleCount = 3
        if (selectedDetailList.length > 5) {
            middleCount = selectedDetailList.length - 2
        }

        let middleDetailsSectionList = []
        const maxCount = newDetailsList.length >= middleCount ? middleCount : newDetailsList.length
        if (maxCount > 0) {
            middleDetailsSectionList = newDetailsList.splice(0, maxCount)
            newDetailsList.slice(maxCount)
        }

        let bottomDetailsSectionList = []
        if (newDetailsList.length > 0) {
            bottomDetailsSectionList.push(newDetailsList[0])
        }

        let topSection = undefined
        let middleSection = undefined
        let bottomSection = undefined

        if (topDetailsSectionList.length > 0) {
            topSection = topDetailsSectionList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        if(middleDetailsSectionList.length > 0) {
            middleSection = middleDetailsSectionList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        if (bottomDetailsSectionList.length > 0) {
            bottomSection = bottomDetailsSectionList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        if(topSection) {
            return <div className="ColumnDiv" style={{
                borderBottom: '2px solid black',
                paddingTop: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
            }}>
                {topSection}
                {middleSection && 
                    <div className="RowDiv">
                        {middleSection}
                    </div>
                }
                {bottomSection}
            </div>
        }
    }

    getProductLabelField(element, productListing) {
        let label = element.name
        switch (productListing) {
            case 'product_name':
                label = element.name
                break;
            case 'product_description':
                label = element.description
                break;
            case 'both':
                if (element.description !== '') {
                    label = element.name + ' (' + element.description + ')'
                }
                break;
            default:
                break;
        }

        return label
    }

    renderProductPartsSection() {
        let productList = []
        const lineItems = this.state.data.lineItems ?? []
        // const rawLineItems = this.state.data.rawLineItems ?? []
        const productListing = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.productListing
        if (lineItems.length > 0) {
            const productGroupName = [...new Set(lineItems.map(x => x.groupName))]

            if (productGroupName.length > 0) {
                productGroupName.forEach(element => {
                    let headerName = element
                    if(headerName !== '') {
                        productList.push({
                            type: 'header',
                            label: headerName.toUpperCase(),
                            showSeparator: true,
                            value: '',
                            boldText: true
                        })
                    }

                    let lineItemsGroup = lineItems.filter(x => x.groupName === element)

                    if (lineItemsGroup.length > 0) {
                        lineItemsGroup.forEach(element => {
                            productList.push({
                                type: 'item',
                                label: this.getProductLabelField(element, productListing),
                                showSeparator: false,
                                value: element.quantity,
                                boldText: true
                            })
                        });
                    }
                });
            }

            // const nonTrackedProducts = rawLineItems.filter(x => x.advancedTrackingType === 'NONE')

            // if (nonTrackedProducts.length > 0) {
            //     productList.push({
            //         type: 'header',
            //         label: getLocalisedText('rework').toUpperCase(),
            //         showSeparator: true,
            //         value: '',
            //         boldText: true
            //     })

            //     nonTrackedProducts.forEach(element => {
            //         productList.push({
            //             type: 'non_tracked_item',
            //             label: element.name,
            //             showSeparator: true,
            //             value: element.description,
            //             boldText: false
            //         })
            //     });
            // }
        }


        let productsSection = undefined

        if (productList.length > 0) {
            productsSection = productList.map(item => {
                if (item.type === 'header') {
                    return this.getRowValueSection(
                        item.label,
                        item.value,
                        item.showSeparator,
                        item.boldText,
                    )
                }
                else if (item.type === 'item') {
                    return this.getRowValueSection(
                        item.value,
                        item.label,
                        item.showSeparator,
                        item.boldText,
                    )
                }
                else if (item.type === 'non_tracked_item') {
                    return this.getRowValueSection(
                        item.label,
                        item.value,
                        item.showSeparator,
                        item.boldText,
                    )
                }

                return <></>
            });
        }

        if (productsSection) {
            return <div className="ColumnDiv" style={{
                borderBottom: '2px solid black',
                paddingTop: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        alignSelf: 'center',
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {getLocalisedText('parts').toUpperCase()}
                </text>
                {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                {productsSection}
            </div>
        }
    }

    renderTopSectionAboveUtility() {
        if (TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible()) {
            return (
                <>
                    {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                        dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                </>
            )
        }
        return undefined
    }

    renderFinalInspectionSection() {
        if (Utility.getIsContainsCustomField('top')) {
            return <div className="ColumnDiv" style={{
                paddingTop: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)),
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        alignSelf: 'center',
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {getLocalisedText('final_inspection').toUpperCase()}
                </text>
                {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
            </div>
        }
    }

    getSingleDetailsSection(item) {
        return <div className="RowDiv">
            {item ?
                this.getRowValueSection(
                    item.label,
                    this.getDocumentDetailsValue(item),
                    true,
                    true
                ) : <></>
            }
        </div>
    }

    getRowValueSection(title, value, showSeparator, boldText) {
        let separator = ''
        if(showSeparator) {
            separator = ': '
        }

        let fontClassName = 'docFontStyle'
        if (boldText) {
            fontClassName = 'docFontStyleBold'
        }
        return <div style={{ display: 'block', lineHeight: '100%', textAlign: 'left'}}>
            <text className={fontClassName + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
            </text>
            <text className={fontClassName + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {separator}&nbsp;
            </text>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }} dangerouslySetInnerHTML={{ __html: value }}>
            </text>
        </div>
    }

    getDocumentDetailsValue(element) {
        var value = ''
        if (element.key !== undefined) {
            value = this.state.data[element.key]
        }
        else if (element.customFieldValue !== undefined) {
            value = element.customFieldValue
        }

        return value
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = ['50%', '50%']
        style.renderAsColumnBlock = true
        style.valueSeparator = ":"
        return style;
    }

    render() {
        return (
            <div
                className="DocumentHolder ColumnDiv"
                style={{ minHeight: getPageHeight() }}
            >
                <div
                    className="parent-width"
                    style={{ width: "100%", paddingBottom: getVW(30) }}
                >
                    {this.getDocumentNumber()}
                    {this.getCompanyDetailsSection()}
                    {this.getDocumentType()}
                    {this.getDocumentDetailsSection()}
                    {this.renderTopSectionAboveUtility()}
                    {this.renderProductPartsSection()}
                    {this.renderFinalInspectionSection()}
                    <div style={{ paddingTop: getVW(Utility.convertToPrintScale(20 * this.spacingRatio, this.state.data.printScale)) }}>
                        <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv">
                            {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                this.state.data.termsAndCondition,
                                "100%",
                                undefined,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                            )}
                        </div>
                    </div>
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
            </div>
        );
    }
}
