import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import StockAdjustmentService from '../../Services/StockAdjustment';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  content: [] as any
};

export const fetchStockAdjustmentPendingApprovalList = createAsyncThunk(
  'fetchStockAdjustmentPendingApprovalList',
  async () => {
    const response =
      await StockAdjustmentService.getStockAdjPendingApprovalData();
    return response;
  }
);

export const StockAdjustmentApprovalSlice = createSlice({
  name: 'stockAdjsutment_approval',
  initialState,
  reducers: {
    addstockAdjustmentApprovalColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
        // }
      });
      state.columnConfig = configsToStore;
    },
    addStockAdjustmentApproval: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockAdjustmentPendingApprovalList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchStockAdjustmentPendingApprovalList.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          state.data = action.payload;
        }
      );
  }
});

export const {
  addStockAdjustmentApproval,
  addstockAdjustmentApprovalColumnConfig
} = StockAdjustmentApprovalSlice.actions;

export const selectStockAdjustmentApprovalData = (state: RootState) =>
  state.stockAdjustmentApproval;
