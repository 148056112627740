import React, { Component, useEffect, useState } from 'react';
import {
  DKLabel,
  DKIcons,
  LineChart,
  COLORS,
  toCurrencyFormat,
  DKSpinner
} from 'deskera-ui-library';
import ic_cashflow from '../../Assets/Dashboard/ic_cashflow__.png';
import DKDropdown from './DKDropdown';
import { DATE_FORMAT, ERROR, MONTHS, STANDLEY_EMAIL } from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import DashboardService from '../../Services/Dashboard';
// import BoxWithTitle from './BoxWithTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { getCashFlow, selectCashFlow } from '../../Redux/Slices/DashboardSlice';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import AuthService from '../../Services/Auth';

function Cashflow(props: any) {
  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(0);
  const cashFlowSummary = useAppSelector(selectCashFlow);
  const { t, i18n } = useTranslation();
  const [noData, setNoData] = useState(true);
  const [noDataFound, setNoDatFound] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([
    {
      title: 'Debit',
      color: COLORS.chart.blue,
      points: [
        {
          title: 'Jan',
          value: 100,
          color: COLORS.chart.yellow
        },
        {
          title: 'Feb',
          value: 50,
          color: COLORS.chart.red
        },
        {
          title: 'Mar',
          value: 50,
          color: COLORS.chart.red
        },
        {
          title: 'Apr',
          value: 100,
          color: COLORS.chart.red
        }
      ]
    },
    {
      title: 'Credit',
      color: COLORS.chart.green,
      points: [
        {
          title: 'Wed3',
          value: 30,
          color: COLORS.chart.yellow
        },
        {
          title: 'Wed4',
          value: 80,
          color: COLORS.chart.red
        }
      ]
    }
  ]);
  const dispatch = useAppDispatch();

  const filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_30_DAYS'),
      value: 'LAST30'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_MONTH'),
      value: 'CURRENT_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];

  useEffect(() => {
    if (cashFlowSummary === ERROR) {
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    if (
      !GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.REPORTS.VIEW
      )
    ) {
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    let line1Data =
      cashFlowSummary &&
      cashFlowSummary.length > 0 &&
      cashFlowSummary.map((item: any) => {
        let dateSplit = item.monthStartDate.split('-');
        let monthNumber = !Utility.isEmpty(dateSplit) && dateSplit[1];
        let month = MONTHS[parseInt(monthNumber) - 1];
        return {
          title: month,
          value: item.totalDebitAmount ? item.totalDebitAmount : 0,
          color: COLORS.chart.lightBlue
        };
      });
    let data1 = {
      title: 'Debit',
      color: COLORS.chart.blue,
      points: line1Data
    };
    let line2Data =
      cashFlowSummary &&
      cashFlowSummary.length > 0 &&
      cashFlowSummary.map((item: any) => {
        let dateSplit = item.monthStartDate.split('-');
        let monthNumber = !Utility.isEmpty(dateSplit) && dateSplit[1];
        let month = MONTHS[parseInt(monthNumber) - 1];
        return {
          title: month,
          value: item.totalCreditAmount,
          color: COLORS.chart.green
        };
      });
    let data2 = {
      title: 'Credit',
      color: COLORS.chart.green,
      points: line2Data
    };
    let array = new Array();
    array.push(data1);
    array.push(data2);
    if (
      (line1Data && line1Data.length > 0) ||
      (line2Data && line2Data.length > 0)
    ) {
      setParsedData(array);
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [cashFlowSummary]);

  const getCashFlowDashboard = () => {
    try {
      let date = new Date();
      var config = {
        toDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY),
        duration: filters[selectedFilterIndex].value
      };
      DashboardService.apiConfig = config;
      const data = dispatch(getCashFlow());
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  useEffect(() => {
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.REPORTS.VIEW
      )
    ) {
      getCashFlowDashboard();
    }
  }, [selectedFilterIndex]);

  return (
    <div className={`border-radius-m shadow-s border-ms p-l bg-white border-box column justify-content-between mr-l db-grid-width db-grid-min-width db-mb ${AuthService.getUserEmail()?.toLowerCase() === STANDLEY_EMAIL.toLowerCase() ? 'db-grid-width-50' : ''}`}>
      <div className="row justify-content-between">
        <DKLabel text="Cashflow" className="fw-m fs-m" />
        <DKDropdown
          title={filters[selectedFilterIndex].name}
          data={filters.map((item: any) => {
            return item.name;
          })}
          onSelect={(data: any) => {
            setSelectedFilterIndex(data);
            props.updateFilter(filters[data].value);
          }}
        />
      </div>
      <div className="row justify-content-center parent-height parent-width position-relative">
        {noData && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">
              <DKSpinner iconClassName="ic-s" />
            </div>
          </div>
        )}
        {noDataFound && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">No Data</div>
          </div>
        )}
        <LineChart
          data={parsedData}
          height={170}
          showLegend={true}
          className="bg-red"
          tooltipValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
          displayValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
        />
      </div>
    </div>
  );
}

export default Cashflow;
