import {
  DKButton,
  DKLabel,
  DKRadioButton,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';
import React, { useState } from 'react';
import Utility from '../../Utility/Utility';
import { DynamicPopupWrapper } from '../PopupWrapper';

export default function HSNForm(props: any) {
  // { rowIndex: rowIndex, itcValue: itcValue }
  const [hsnVal, setHsnVal] = useState<string>(props.HSNData.hsnVal);
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={'Update HSN/SAC'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() => {
              if (Utility.isEmpty(hsnVal)) {
                return;
              }
              props.setHSNChangeValue({
                rowIndex: props.HSNData.rowIndex,
                hsnValue: hsnVal
              });
            }}
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column align-items-start pt-4">
        <DKInput
          title="Please enter HSN/SAC value"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          type={INPUT_TYPE.TEXT}
          value={hsnVal}
          className=""
          onChange={(val: any) => {
            setHsnVal(val);
          }}
          canValidate={true}
          errorMessage={'Value should not be empty'}
          validator={(value: string) => {
            return !Utility.isEmpty(value);
          }}
        />
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 300,
            width: '90%',
            maxHeight: '85%',
            height: 200,
            padding: 0,
            paddingBottom: 0
          }}
        >
          {getHeader()}
          <div className="p-h-xl parent-width">{getBody()}</div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
