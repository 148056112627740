import CustomFieldManager from "../../Manager/CustomFieldManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { getCapitalized, getDateString, getTitleCase } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"

export default class InspectionReportParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        let customField = this.getWorkOrderResponse(dataFromAPI)?.customField ?? []
        templateDataToReturn.customFields = [...customField]
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName) ?? ''
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;
        if (templateDataToReturn.companyName === '') {
            templateDataToReturn.companyName = dataFromAPI.tenantInfo?.name ?? ''
        }
        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)


        let startDate = ''
        let startDateTimeStamp = this.getWorkOrderResponse(dataFromAPI).actualStartDate ?? ''
        if (startDateTimeStamp !== '') {
            startDate = getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.date = startDate

        let endDate = ''
        let endDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualEndDate : ''
        if (endDateTimeStamp !== '') {
            endDate = getDateString(new Date(endDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.endDate = endDate

        templateDataToReturn.linkDocumentType = undefined
        templateDataToReturn.linkDocumentNumber = undefined
        var parentWorkOrderSeqCode = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).parentWorkOrderSeqCode : undefined
        if (parentWorkOrderSeqCode !== undefined && parentWorkOrderSeqCode !== null) {
            // templateDataToReturn.linkDocumentType = 'parent_work_order'
            templateDataToReturn.parentWorkOrder = parentWorkOrderSeqCode
            templateDataToReturn.linkDocumentType = getLocalisedText('work_order')
        }

        templateDataToReturn.linkDocumentType = templateDataToReturn.linkDocumentType ?? ''
        var workOrderSourceDetails = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).workOrderSourceDetails : undefined
        if (workOrderSourceDetails !== undefined && workOrderSourceDetails !== null) {
            let multiSO = ''
            if (workOrderSourceDetails.length > 0) {
                let salesOrders = workOrderSourceDetails.filter(x => x.workOrderSource === 'SALES_ORDER')

                if (salesOrders && salesOrders !== null) {
                    if (salesOrders.length > 0) {
                        workOrderSourceDetails = salesOrders[0]

                        if (salesOrders.length > 1) {
                            multiSO = salesOrders.map(x => x.salesOrderSequenceCode).join(', ')
                        }
                    }
                }

                if (workOrderSourceDetails.length > 0) {
                    let salesInvoice = workOrderSourceDetails.filter(x => x.workOrderSource === 'SALES_INVOICE')
                    if (salesInvoice && salesInvoice !== null) {
                        if (salesInvoice.length > 0) {
                            workOrderSourceDetails = salesInvoice[0]

                            if (salesInvoice.length > 1) {
                                multiSO = salesInvoice.map(x => x.salesInvoiceSequenceCode).join(', ')
                            }
                        }
                    }
                }
            }

            var salesOrderSequenceCode = workOrderSourceDetails.salesOrderSequenceCode
            var workOrderSource = workOrderSourceDetails.workOrderSource

            if (multiSO !== '') {
                templateDataToReturn.linkDocumentNumber = multiSO
            }
            else if (salesOrderSequenceCode !== undefined && salesOrderSequenceCode !== null) {
                templateDataToReturn.linkDocumentNumber = salesOrderSequenceCode
            }
            if (workOrderSource !== undefined && workOrderSource !== null) {
                if (workOrderSource.toLowerCase() === 'sales_order') {
                    templateDataToReturn.linkDocumentType = getLocalisedText('sales_order')
                }
                else {
                    templateDataToReturn.linkDocumentType = getLocalisedText(workOrderSource)
                }
            }

            if (templateDataToReturn.linkDocumentNumber === '' && templateDataToReturn.parentWorkOrder !== '') {
                templateDataToReturn.linkDocumentNumber = templateDataToReturn.parentWorkOrder
            }
        }


        templateDataToReturn.woCode = this.getWorkOrderResponse(dataFromAPI)?.documentSequenceCode ?? ''
        templateDataToReturn.woStatus = this.getWorkOrderResponse(dataFromAPI)?.status ?? ''
        templateDataToReturn.woStatus = getTitleCase(templateDataToReturn.woStatus)

        templateDataToReturn.refNumber = this.getWorkOrderResponse(dataFromAPI)?.documentSequenceCode ?? ''
        templateDataToReturn.productName = this.getWorkOrderResponse(dataFromAPI)?.productName ?? ''
        templateDataToReturn.productDescription = this.getWorkOrderResponse(dataFromAPI)?.productDescription ?? ''
        templateDataToReturn.productCode = this.getWorkOrderResponse(dataFromAPI)?.productCode ?? ''
        templateDataToReturn.productDocSeqCode = this.getWorkOrderResponse(dataFromAPI)?.productDocSeqCode ?? undefined
        if (templateDataToReturn.productDocSeqCode) {
            templateDataToReturn.productCode = templateDataToReturn.productDocSeqCode
        }
        
        templateDataToReturn.contactName = this.getWorkOrderResponse(dataFromAPI)?.contactName ?? ''
        templateDataToReturn.contactCode = this.getWorkOrderResponse(dataFromAPI)?.contactCode ?? ''

        templateDataToReturn.woCustomFields = this.getWorkOrderResponse(dataFromAPI)?.customField ?? []
        templateDataToReturn.productMasterCustomFields = this.getWorkOrderResponse(dataFromAPI)?.productCustomField ?? []

        templateDataToReturn.lineItems = this.getInspectionReportLineItem(dataFromAPI)
        // templateDataToReturn.rawLineItems = this.getInspectionReportRawLineItem(dataFromAPI)
        return templateDataToReturn

    }

    // static getInspectionReportRawLineItem(dataFromAPI) {
    //     let items = []
    //     const rawItems = this.getWorkOrderResponse(dataFromAPI)?.workOrderItems ?? []

    //     if(rawItems.length > 0) {
    //         rawItems.forEach((element, index) => {
    //             var item = {}
    //             // var productName = element.productName
    //             var documentSequenceCode = element.productDocSeqCode

    //             // item.name = `${productName}${element.isAdHocItem ? ' *' : ''}`
    //             item.name = element.productName
    //             item.description = element.productDescription
    //             item.code = documentSequenceCode
    //             item.lineNumber = index + 1 
    //             item.quantity = element.plannedUomQuantity
    //             item.advancedTrackingType = element.produceProductType
    //             item.groupName = ''
    //             items.push(item)
    //         })
    //     }
    //     return items
    // }

    static getInspectionReportLineItem(dataFromAPI) {
        let items = []
        const rawItems = this.getWorkOrderResponse(dataFromAPI)?.workOrderItems ?? []

        if (rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                var item = {}
                var productGroupNames = element.productGroupName ?? []
                // var productName = element.productName
                var documentSequenceCode = element.productDocSeqCode

                // item.name = `${productName}${element.isAdHocItem ? ' *' : ''}`
                item.name = element.productName
                item.description = element.productDescription
                item.code = documentSequenceCode
                item.lineNumber = index + 1
                item.quantity = element.plannedUomQuantity
                item.advancedTrackingType = element.produceProductType
                item.groupName = ''

                if (productGroupNames.length === 0) {
                    items.push(item)
                }
                else {
                    productGroupNames.forEach(groupName => {
                        if (groupName && groupName !== null) {
                            var newGroupItem = { ...item }
                            newGroupItem.groupName = groupName
                            items.push(newGroupItem)
                        }
                    });
                }
            })
        }
        return items
    }

    static getWorkOrderResponse(dataFromApi) {
        return dataFromApi.workOrderResponse ?? undefined
    }
}
