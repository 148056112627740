import { DOC_TYPE } from '../Constants/Constant';

export interface ISecurityGateEntry {
  id?: number;
  vendorCode: string | null;
  gateEntryCode?: string;
  warehouse: any | null;
  vendorInfo: any;
  challanDate: Date;
  challanNumber: string | null;
  referenceDate: Date;
  arrivalTime: Date;
  departureTime: Date;
  weighbridgeDate: Date;
  ewayDate: Date | null;
  ewayBillNumber: Date | null;
  vehicleCapacity: number | null;
  distanceKm: number | null;
  contactNumber: string | null;
  currencyCode: string | null;
  customField: any[];
  documentSequenceCode: string | null;
  driverName: string | null;
  freightType: string | null;
  fromAddress: string | null;
  gateEntryItems: GateEntryItem[];
  licenseNumber: string | null;
  lrDate: Date | null;
  lrNumber: string | null;
  referenceNumber: string | null;
  remarks: string | null;
  sequenceFormat: any;
  toAddress: string | null;
  transporter: string | null;
  vehicleNumber: string | null;
  vehicleType: string | null;
  weighbridgeName: string | null;
  weighbridgeSlipNumber: string | null;
  weighbridgeSlipDate: Date | null;
  referenceDocument: any | null;
  referenceDocumentType:
    | DOC_TYPE.ORDER
    // DOC_TYPE.RFQ |
    | DOC_TYPE.BILL
    | 'NONE';
  linkedDocuments?: any[];
  attachmentIds?: any[];
  attachments?: any[];
}

export interface GateEntryItem {
  documentUOMSchemaDefinition: null | DocumentUOMSchemaDefinition;
  documentUom: null | number;
  productDescription: string;
  productCode: string;
  quantity: number;
  uomQuantity: number;
  approverId?: number;
  approver: any;
  [key: string]: any;
}

export interface DocumentUOMSchemaDefinition {
  name: string;
  schemaId: number;
  sinkConversionFactor: number;
  sinkUOM: number;
  sourceConversionFactor: number;
  sourceUOM: number;
  uid: number;
}

export const LINKABLE_DOCUMENT = {
  NONE: 'Other',
  [DOC_TYPE.ORDER]: 'Purchase Order',
  // [DOC_TYPE.RFQ]: 'Request For Quotation',
  [DOC_TYPE.BILL]: 'Bill'
};
