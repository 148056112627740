import { TableColumnPopupType } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeaderType } from "../../dktable/TableUtility";

export class ShippingLabelTableItem {
    delivery: string;
    carrier: string;
    numberOfCarton: number;
    weight: string;
    trackingNumber: string;
    rawItemValue: any;
    constructor(shippingLabelItem: any, data:any, currency: string) {
        this.delivery = shippingLabelItem.delivery ? shippingLabelItem.delivery : ''
        this.carrier = shippingLabelItem.carrier ? shippingLabelItem.carrier : ''
        this.numberOfCarton = shippingLabelItem.numberOfCarton ? shippingLabelItem.numberOfCarton.toString() : '0'
        this.weight = shippingLabelItem.weight ? shippingLabelItem.weight : ''
        this.trackingNumber = shippingLabelItem.trackingNumber ? shippingLabelItem.trackingNumber : ''
        this.rawItemValue = shippingLabelItem
    }
    toString() {
        var sortedHeader = getSortedHeaderType()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                sortedValue.push(this.getValueByType(type))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.delivery:
                return getLocalisedText(this.delivery)
            case TableColumnPopupType.carrier:
                return getLocalisedText(this.carrier)
            case TableColumnPopupType.numberOfCarton:
                return this.numberOfCarton
            case TableColumnPopupType.weight:
                return this.weight
            case TableColumnPopupType.trackingNumber:
                return this.trackingNumber
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.delivery),
            getLocalisedText(this.carrier),
            this.numberOfCarton,
            this.weight,
            this.trackingNumber,
        ]
    }
}
