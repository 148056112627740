import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  DKLabel,
  DKButton,
  DKIcons,
  DKSpinner,
  DKLine
} from 'deskera-ui-library';
import { useState } from 'react';
import { shiftArrayElement } from '../../Utility/Utility';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import CustomFieldService from '../../Services/CustomField';
import { LOCATION_CLASS_ENUM } from '../../Constants/Constant';
import { DynamicPopupWrapper } from '../PopupWrapper';

function CustomFieldSettings(props: any) {
  const [customFields, setCustomFields] = useState<any[]>(
    props.fields?.length ? props.fields : []
  );
  const [isSaving, setIsSaving] = useState(false);
  const tenantInfo = useAppSelector(activeTenantInfo);

  const getHeader = () => {
    return (
      <div
        className="row justify-content-between p-h-r p-v-s bg-gray1"
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <div className="row pop-header-drag-handle">
          <DKLabel text="Arrange Custom Fields" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            disabled={isSaving}
            className={`bg-white border-m ${
              customFields?.length > 0 ? 'mr-r' : ''
            }`}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
          {customFields?.length > 0 && (
            <div
              className={`row border-radius-m justify-content-between ${
                isSaving ? 'border-m' : 'bg-button border-blue'
              }`}
            >
              <div className="row">
                <DKButton
                  title={isSaving ? 'Saving' : 'Save'}
                  disabled={isSaving}
                  className={`column border-radius-none ${
                    isSaving ? 'text-gray' : 'text-white'
                  }`}
                  onClick={() => {
                    updateFieldsOrder();
                  }}
                  style={{
                    paddingRight: isSaving ? 2 : 12
                  }}
                />
                {isSaving && (
                  <DKSpinner
                    iconClassName="ic-s"
                    className="column pl-xs pr-m"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const updateFieldsOrder = () => {
    setIsSaving(true);
    const payload = {
      dimensionList: customFields,
      moduleName: props.moduleName
    };
    CustomFieldService.updateCustomFieldOrder(payload).then(
      (res: any) => {
        setIsSaving(false);
        if (props.onSave) {
          props.onSave();
        }
        if (props.onClose) {
          props.onClose();
        }
      },
      (err: any) => {
        setIsSaving(false);
        console.error('Error updating order of CFs: ', err);
      }
    );
  };

  const SortableItem = SortableElement(({ field, index }: any) => {
    // console.log('SortableItem field: ', field, index);
    const locationSettings = tenantInfo?.additionalSettings?.LOCATION;
    let title = field.label;
    if (title === LOCATION_CLASS_ENUM.LOCATION) {
      title = locationSettings?.locationLabel;
    }
    return (
      <div
        className={`cursor-hand bg-white unselectable row justify-content-between listPickerBG parent-width`}
      >
        <div className="parent-width mt-m column justify-content-between">
          <div className="row justify-content-between">
            <DKLabel text={title} className="p-h-s" />

            <img
              src={DKIcons.ic_menu}
              className="ic-s opacity-2 unselectable mr-s"
              draggable="false"
              alt=""
            />
          </div>
          <DKLine className={`mt-m unselectable `} />
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ fields }: any) => {
    // console.log('SortableList fields: ', fields);
    return (
      <div
        id={'dk-field-reorder-component'}
        className={
          'column border-radius-s parent-width align-items-stretch bg-gray1s'
        }
      >
        {fields.map((field: any, index: number) => (
          <SortableItem field={field} index={index} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let orderedFields = shiftArrayElement(
      [...customFields],
      oldIndex,
      newIndex
    );
    orderedFields = orderedFields.map((field: any, index: number) => {
      return {
        ...field,
        customFieldIndex: index
      };
    });
    setCustomFields(orderedFields);
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-r">
        {customFields?.length > 0 && (
          <>
            <div className="row p-h-s fw-m">
              <DKLabel text="Custom Field Name" />
            </div>
            <DKLine className={`mt-m unselectable `} />
            <SortableList
              fields={customFields}
              onSortEnd={onSortEnd}
              // distance={20}
              lockAxis="y"
              helperClass="sortable-helper"
            />
          </>
        )}
        {customFields?.length === 0 && (
          <DKLabel
            text="No custom fields found."
            className="column parent-width align-items-center"
          />
        )}
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            padding: 0,
            maxHeight: '90%',
            width: '90%',
            maxWidth: 400,
            overflowY: 'scroll'
          }}
        >
          {getHeader()}
          {getBody()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}

export default CustomFieldSettings;
