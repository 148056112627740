import ApiConstants from '../../Constants/ApiConstants';
import Utility from '../../Utility/Utility';
import http from '../Interceptor';

export interface QualityCheckAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  sort?: string;
  sortDir?: string;
  Query?: string;
  QueryParam?: string;
}

const defaultConfig: QualityCheckAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  sort: '',
  sortDir: 'DESC',
  Query: '',
  QueryParam: ''
};

class QualityCheckService {
  static apiConfig: QualityCheckAPIConfig;

  static getQualityCheck() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint =
      ApiConstants.URL.MRP.QUALITY_CHECK.GET_ALL_QUALITY_CHECK;
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=${this.apiConfig.sort}&sortDir=${this.apiConfig.sortDir}&query=${this.apiConfig.Query}`;
    finalEndpoint = finalEndpoint + query;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteQualityCheck(id: any) {
    return http
      .delete(`${ApiConstants.URL.MRP.QUALITY_CHECK.DELETE_QUALITY_CHECK}`, {
        data: id
      })
      .catch((err: any) => {
        console.error('Error deleting quality check: ', err);
      });
  }

  static addQualityCheck(payload: any) {
    return http
      .post(`${ApiConstants.URL.MRP.QUALITY_CHECK.ADD_QUALITY_CHECK}`, payload)
      .catch((err: any) => {
        console.error('Error creating quality check: ', err);
        return Promise.reject(err);
      });
  }

  static updateQualityCheck(payload: any, id: any) {
    return http
      .put(ApiConstants.URL.MRP.QUALITY_CHECK.EDIT_QUALITY_CHECK(id), payload)
      .catch((err: any) => {
        console.error('Error while updating quality check: ', err);
        return Promise.reject(err);
      });
  }

  static getProductQCTemplateDetail(id: any) {
    let finalEndpoint =
      ApiConstants.URL.MRP.QUALITY_CHECK.FETCH_PRODUCT_QC_DETAILS(id);
    finalEndpoint = finalEndpoint;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getQualityCheckByIds(payload: any) {
    return http
      .post(
        `${ApiConstants.URL.MRP.QUALITY_CHECK.GET_QUALITY_CHECK_BY_IDS}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating quality check: ', err);
      });
  }
}

export default QualityCheckService;
