import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface AmortizationTemplatesApiConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
}

export const defaultTemplateConfig: AmortizationTemplatesApiConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: ''
};

export interface AmortizationSchedulesApiConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
}

export const defaultScheduleConfig: AmortizationSchedulesApiConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: ''
};

export interface AmortizationJeApiConfig {
  PostingPeriod: string;
  Contact?: string;
  Limit?: number;
  Page?: number;
  includePriorPeriods?: boolean;
}

export const defaultJeConfig: AmortizationJeApiConfig = {
  PostingPeriod: '',
  Contact: undefined,
  Limit: 25,
  Page: 0,
  includePriorPeriods: false
};

class AmortizationService {
  static templatesApiConfig: AmortizationTemplatesApiConfig;
  static templatesAbortController: any = null;

  static schedulesApiConfig: AmortizationSchedulesApiConfig;
  static schedulesAbortController: any = null;

  static jeApiConfig: AmortizationJeApiConfig;
  static jeAbortController: any = null;

  static getAmortizationTemplatesEndpoint() {
    if (Utility.isEmpty(this.templatesApiConfig)) {
      this.templatesApiConfig = defaultTemplateConfig;
    }
    let queryString: string = `?search=${
      this.templatesApiConfig.SearchTerm
        ? this.templatesApiConfig.SearchTerm
        : ''
    }&limit=${
      this.templatesApiConfig.Limit ? this.templatesApiConfig.Limit : 25
    }&page=${this.templatesApiConfig.Page ? this.templatesApiConfig.Page : 0}${
      this.templatesApiConfig.Query
        ? '&query=' + this.templatesApiConfig.Query
        : ''
    }`;

    const finalEndpoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_TEMPLATES + queryString;
    return finalEndpoint;
  }

  static getAmortizationTemplatesByPage() {
    Utility.cancelRequest(AmortizationService.templatesAbortController);

    AmortizationService.templatesAbortController =
      Utility.createAbortController();
    return http
      .get(`${AmortizationService.getAmortizationTemplatesEndpoint()}`, {
        signal: AmortizationService.templatesAbortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getAllAmortizationTemplates(limit: number) {
    let queryString: string = `?limit=${limit}&page=0`;

    const finalEndpoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_TEMPLATES + queryString;

    return http
      .get(finalEndpoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createAmortizationTemplate(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.CREATE_AMORTIZATION_TEMPLATE;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateAmortizationTemplate(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.UPDATE_AMORTIZATION_TEMPLATE(data.id);

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteAmortizationTemplate(id: string) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.UPDATE_AMORTIZATION_TEMPLATE(id);

    return http
      .delete(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAmortizationTemplateByCode(templateCode: string) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_TEMPLATE_BY_CODE(
        templateCode
      );

    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAmortizationScheduleEndpoint() {
    if (Utility.isEmpty(this.schedulesApiConfig)) {
      this.schedulesApiConfig = defaultScheduleConfig;
    }
    let queryString: string = `?search=${
      this.schedulesApiConfig.SearchTerm
        ? this.schedulesApiConfig.SearchTerm
        : ''
    }&limit=${
      this.schedulesApiConfig.Limit ? this.schedulesApiConfig.Limit : 25
    }&page=${this.schedulesApiConfig.Page ? this.schedulesApiConfig.Page : 0}${
      this.schedulesApiConfig.Query
        ? '&query=' + this.schedulesApiConfig.Query
        : ''
    }`;

    const finalEndpoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_SCHEDULES + queryString;
    return finalEndpoint;
  }

  static getAmortizationSchedulesByPage() {
    Utility.cancelRequest(AmortizationService.schedulesAbortController);

    AmortizationService.schedulesAbortController =
      Utility.createAbortController();
    return http
      .get(`${AmortizationService.getAmortizationScheduleEndpoint()}`, {
        signal: AmortizationService.schedulesAbortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAmortizationScheduleByCode(scheduleCode: string) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_SCHEDULE_BY_CODE(
        scheduleCode
      );

    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateAmortizationSchedule(payload: any, id: any) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.UPDATE_AMORTIZATION_SCHEDULES(id);

    return http
      .put(finalEndPoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAmortizationJeEndpoint() {
    if (Utility.isEmpty(this.jeApiConfig)) {
      this.jeApiConfig = defaultJeConfig;
    }
    let queryString: string = `?postingPeriod=${
      this.jeApiConfig.PostingPeriod
    }&limit=${this.jeApiConfig.Limit ? this.jeApiConfig.Limit : 25}&page=${
      this.jeApiConfig.Page ? this.jeApiConfig.Page : 0
    }&contactCode=${
      this.jeApiConfig.Contact ? this.jeApiConfig.Contact : ''
    }&includePriorPeriods=${this.jeApiConfig.includePriorPeriods}`;

    const finalEndpoint: string =
      ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_JE + queryString;
    return finalEndpoint;
  }

  static getAmortizationJeByPage() {
    Utility.cancelRequest(AmortizationService.jeAbortController);

    AmortizationService.jeAbortController = Utility.createAbortController();
    return http
      .get(`${AmortizationService.getAmortizationJeEndpoint()}`, {
        signal: AmortizationService.jeAbortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postAmortizationJe(payload: any) {
    const finalEndPoint: string =
      ApiConstants.URL.AMORTIZATION.POST_AMORTIZATION_JE;

    return http
      .post(finalEndPoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createAmortizationSchedule(payload: any) {
    const url: string =
      ApiConstants.URL.AMORTIZATION.POST_AMORTIZATION_SCHEDULE;

    return http
      .post(url, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default AmortizationService;
