import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchSerialTrackingProductsJWO = createAsyncThunk(
  'serialTrackingProductJWO',
  async (documentCode: any) => {
    const response = await ProductService.fetchAdvanceTrackingStockTransfer(
      documentCode
    );
    return response;
  }
);

export const fetchJWOstockTransfer = createAsyncThunk(
  'serialTrackingProductJWO',
  async (documentCode: any) => {
    const response = await ProductService.fetchJWOStockTransfer(
      documentCode
    );
    return response;
  }
);

export const SerialTrackingProductJWOSlice = createSlice({
  name: 'serialTrackingProductsJWO',
  initialState,
  reducers: {
    resetSerials: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSerialTrackingProductsJWO.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchSerialTrackingProductsJWO.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { resetSerials } = SerialTrackingProductJWOSlice.actions;

export const selectSerialTrackingProductJWO = (state: RootState) =>
  state.serialTrackingProductsJWO.data;

export default SerialTrackingProductJWOSlice.reducer;
