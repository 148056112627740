import React, { Component } from "react";
import "../../index.css";
import { FONT_SIZE } from '../../Constants/Constants';
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import ComponentManager from "../../Manager/ComponentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";
import NumberUtility from "../../Utilities/NumberUtility";
import AppManager from "../../Manager/AppManager";
import { Asset } from "../../assets";

export default class EWayBillDetailsTemplate1 extends Component {

    arabicHeaderWidth = '85%'
    borderBottom = '5px solid'
    borderBottomWidth = getVW(1.5)

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.ewayBill,
            themeColor: this.props.data.themeColor,
            documentData: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data.ewayBill,
            themeColor: this.props.data.themeColor,
            documentData: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    {this.renderTopSection()}
                    {this.renderEwayDetailsSection()}
                    {this.renderAddressDetailSection()}
                    {this.renderGoodsDetailSection()}
                    {this.renderTransportationDetailSection()}
                    {this.renderVehicleDetailSection()}
                </div>
            </div>
        );
    }

    renderTopSection() {
        return <div className="RowDiv" style={{
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingBottom: getVW(5),
            borderBottom: this.borderBottom,
            borderBottomWidth: this.borderBottomWidth,
            borderBottomColor: this.state.themeColor,
        }}>
            <div className="ColumnDiv">
                {ComponentManager.getLogo(Asset.icon.e_way_bill_logo, Utility.convertToPrintScale(128, this.state.data.printScale), {
                    position: 'relative',
                })}
            </div>
            <div className="ColumnDiv" style={{
                alignItems: 'center',
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: "black",
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                    }}>
                    {getLocalisedText('Government of India')}
                </text>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: "black",
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.documentData.documentType)}
                </text>
            </div>
            <div className="ColumnDiv">
                <QRCode
                    value={this.getValueByKey('signedQRCode')}
                    size={120} />
            </div>
        </div>
    }

    renderEwayDetailsSection() {
        var eWayBillDetails = [
            [
                { label: 'eWay Bill No', value: 'eWayBillNo' },
                { label: 'Generated Date', value: 'eWayBillDate' },
                { label: 'Generated By', value: 'tenantGSTIN' },
            ],
            [
                { label: 'Valid Upto', value: 'validUpto' },
                { label: 'Mode', value: 'mode' },
                { label: 'Approx Distance', value: 'distanceInKm' },
            ],
            [
                { label: 'Type', value: 'reasonForTransportation' },
                { label: 'Document Details', value: 'documentDetails' },
                { label: 'Transaction Type', value: 'transactionType' },
            ],
            [
                { label: 'IRN', value: 'irn' },
            ],
            [
                { label: 'Acknowledgement Number', value: 'acknowledgementNumber' },
                { label: 'Acknowledgement Date', value: 'acknowledgementDate' },
            ],
        ]

        var eWayRow = eWayBillDetails.map(row => {
            var rowData = row.map(item => {
                return this.renderEwayTitleAndTextSection(item.label, this.getValueByKey(item.value))
            });

            return <div className="RowDiv pt-s pb-s">
                <div style={{
                    display: 'block',
                    width: '100%',
                    textAlign: 'left',
                }}>
                    {rowData}
                </div>
            </div>
        });

        return this.renderSectionContainer('1. E-WAY BILL Details', eWayRow)
    }

    renderAddressDetailSection() {
        var container = <div className="RowDiv" style={{
            width: '98%',
            alignItems: 'baseline',
        }}>
            <div className="ColumnDiv pt-s parent-width"
                style={{
                    flex: 1,
                }}>
                {this.state.data.showFrom && this.getAddressContainer('From', this.getShipFromSection())}
            </div>
            <div className="ColumnDiv pt-s pb-s pr-s parent-width"
                style={{
                    flex: 0.1,
                }}>

            </div>
            <div className="ColumnDiv pt-s pb-s pr-s parent-width"
                style={{
                    flex: 1,
                }}>
                {this.state.data.showClientShipToAddress && this.getAddressContainer('to', this.getShipToSection())}
            </div>
        </div>

        return this.renderSectionContainer('2. Address Details', container)
    }

    getShipFromSection() {
        return <>
            <div className="ColumnDiv">
                <div className="RowDiv">
                    {this.renderTextSection('GSTIN : ')}
                    {this.renderTextSection(this.state.data.tenantGSTIN ? this.state.data.tenantGSTIN : '')}
                </div>
                <div className="RowDiv">
                    {this.renderTextSection(this.state.data.tenantName ? this.state.data.tenantName : '')}
                </div>
                <div className="RowDiv">
                    {this.renderTextSection(this.state.data.from ? this.state.data.from : '')}
                </div>
                {ComponentManager.addVerticalSpace(1)}
                <div className="RowDiv">
                    {this.renderTextSection(':: Dispatch From ::', true)}
                </div>
                {ComponentManager.addVerticalSpace(1)}
                <div className="RowDiv">
                    <div
                        style={{
                            textAlign: 'left',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.placeOfDispatch ? this.state.data.placeOfDispatch : ''}}>
                    </div>
                    {/* {this.renderTextSection(this.state.data.placeOfDispatch ? this.state.data.placeOfDispatch : '')} &nbsp; - &nbsp;
                    {this.renderTextSection(this.state.data.supplierPostalCode ? this.state.data.supplierPostalCode : '')} */}
                </div>
            </div>
        </>
    }

    getShipToSection() {
        return <>
            <div className="ColumnDiv">
                <div className="RowDiv">
                    {this.renderTextSection('GSTIN :')}
                    {this.renderTextSection(this.state.data.contactGSTIN ? this.state.data.contactGSTIN : '')}
                </div>
                <div className="RowDiv">
                    {this.renderTextSection(this.state.data.contactName ? this.state.data.contactName : '')}
                </div>
                <div className="RowDiv">
                    {this.renderTextSection(this.state.data.to ? this.state.data.to : '')}
                </div>
                {ComponentManager.addVerticalSpace(1)}
                <div className="RowDiv">
                    {this.renderTextSection(':: Ship To ::', true)}
                </div>
                {ComponentManager.addVerticalSpace(1)}
                <div className="RowDiv">
                    <div
                        style={{
                            textAlign: 'left',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.placeOfDelivery ? this.state.data.placeOfDelivery : '' }}>
                    </div>
                    {/* {this.renderTextSection(this.state.data.placeOfDelivery ? this.state.data.placeOfDelivery : '')} &nbsp; - &nbsp;
                    {this.renderTextSection(this.state.data.recipientPostalCode ? this.state.data.recipientPostalCode : '')} */}
                </div>
            </div>
        </>
    }

    renderGoodsDetailSection() {
        var currency = NumberUtility.getCurrency(this.state.documentData)
        var header = [
            { label: 'HSN Code', value: 'hsnCode', alignItems: 'left' },
            { label: 'Product Name', value: 'productName', alignItems: 'left' },
            // { label: 'Decription', value: 'description', alignItems: 'left' },
            { label: 'Quantity', value: 'quantity', alignItems: 'center' },
            { label: 'Taxable Amount '+ currency, value: 'taxableAmount', alignItems: 'right' },
            { label: 'Tax Rate (C+S+I+Cess+Cess Non Advol)', value: 'taxRate', alignItems: 'right' },
        ]

        var tableHeader = header.map(element => {
            return <td className="pt-s pb-s pl-s pr-s"
                style={{
                    textAlign: element.alignItems,
                }}>
                {element.label}
            </td>
        });

        var container = <></>

        if (this.state.data.goodsDetails !== undefined) {
            container = this.state.data.goodsDetails.map(item => {
                return this.renderItemLevel(header, tableHeader, item, currency)
            });
        }

        return this.renderSectionContainer('3. Goods Details', container)
    }

    renderItemLevel(header, tableHeader, item, currency) {
        if (item === undefined) {
            return <>
            </>
        }

        var tableData = []
        var rowData = header.map(element => {
            return <td className="pt-s pb-s pl-s pr-s"
                style={{
                    textAlign: element.alignItems,
                }}>
                {this.getLineItemValue(item, element)}
            </td>
        })
        var row = <tr>
            {rowData}
        </tr>

        tableData.push(row)

        var goodsTable = <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            margin: 'auto',
            border: '1px solid black',
            textAlign: 'left',
            borderWidth: this.borderBottomWidth,
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
        }}>
            <thead>
                <tr style={{
                    borderBottom: this.borderBottom,
                    borderBottomWidth: this.borderBottomWidth,
                }}>
                    {tableHeader}
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </table>

        var taxBreakdown = [
            [
                { label: 'Total Taxable Amount', value: 'taxableAmount' },
                { label: 'CGST Amount', value: 'cgstAmount' },
                { label: 'SGST Amount', value: 'sgstAmount' },
                { label: 'IGST Amount', value: 'igstAmount' },
                { label: 'CESS Amount', value: 'cessAmount' },
            ],
            [
                { label: 'CESS Non.Advol Amt', value: 'cessNonAdvolAmount' },
                { label: 'Other Amount', value: 'otherAmount' },
                { label: 'Total Invoice Amount', value: 'totalAmount' },
                { label: '', value: '' },
                { label: '', value: '' },
            ],
        ]

        var taxTable = taxBreakdown.map(taxRow => {
            var rowData = taxRow.map(tax => {
                return <div className="Column parent-width" style={{
                    textAlign: 'left',
                }}>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: "black",
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small },this.state.data.printScale)
                        }}>
                        {getLocalisedText(tax.label) + ' '}
                    </text>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: "black",
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.small },this.state.data.printScale)
                        }}>
                        {this.getTaxValueByKey(item, tax.value, currency)}
                    </text>
                </div>
            });
            return <div className="RowDiv">
                {rowData}
            </div>
        });

        var container = <div className="parent-width pt-r">
            {goodsTable}
            <div className="pt-s">{taxTable}</div>
        </div>
        return container
    }

    getLineItemValue(item, element) {
        if(element.value === 'taxRate') {
            return this.getTaxRate(item)
        }
        if (element.value === 'taxableAmount') {
            if(item[element.value] !== undefined) {
                return item[element.value].toFixed(AppManager.getDecimalScale())
            }
        }

        return item[element.value] ? item[element.value] : ''
    }

    renderTransportationDetailSection() {
        var container = <div className="RowDiv" style={{
            alignItems: 'baseline',
        }}>
            <div className="RowDiv" style={{
                width: '60%',
                alignItems: 'baseline',
            }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: "black",
                        width: '30%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                    }}>
                    {getLocalisedText('Transporter ID & Name')}
                    :
                    &nbsp;
                </div>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: "black",
                        width: '70%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                    }}>
                    {this.state.data.transporterIdAndName}
                </div>
            </div>
            <div className="RowDiv pl-r" style={{
                width: '40%',
                alignItems: 'baseline',
            }}>
                <div className={"ColumnDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        color: "black",
                        width: '50%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                    }}>
                    {getLocalisedText('Transporter Doc & Date')}
                    :
                    &nbsp;
                </div>
                <div className={"ColumnDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        color: "black",
                        width: '50%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                    }}>
                    {this.state.data.transportVehicleDocAndDate}
                </div>
            </div>
        </div>

        return this.renderSectionContainer('4. Transportation Details', container)
    }

    renderVehicleDetailSection() {
        var header = [
            { label: 'Mode', value: 'mode' },
            { label: 'Vehicle / Trans Doc No & Dt.', value: 'transportVehicleNoDocData' },
            { label: 'From', value: 'vehicleFrom' },
            { label: 'Entered Date', value: 'validFrom' },
            { label: 'Entered By', value: 'tenantGSTIN' },
            { label: 'CEWB No. (if any)', value: 'cewbNo' },
            { label: 'Multi Veh.Info (if any)', value: 'multiVehInfo' },
        ]

        var tableHeader = header.map(element => {
            return <td className="pl-s pr-s pt-s pb-s"
                style={{
                    textAlign: 'left',
                    verticalAlign: 'baseline'
                }}>
                {element.label}
            </td>
        });


        var tableData = []
        if (this.state.data !== undefined) {
            var rowData = header.map(element => {
                return <td className="pl-s pr-s pt-s pb-s"
                    style={{
                        textAlign: 'left',
                        verticalAlign: 'baseline'
                    }}>
                    {this.state.data[element.value] ? this.state.data[element.value] : ''}
                </td>
            })
            var row = <tr>
                {rowData}
            </tr>
            tableData.push(row)
        }

        var goodsTable = <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            margin: 'auto',
            border: '1px solid black',
            textAlign: 'left',
            borderWidth: this.borderBottomWidth,
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
        }}>
            <thead>
                <tr style={{
                    borderBottom: this.borderBottom,
                    borderBottomWidth: this.borderBottomWidth,
                }}>
                    {tableHeader}
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </table>

        var container = <div className="parent-width">
            {goodsTable}
            <div className="ColumnDiv pt-s" style={{
                alignItems: 'center',
            }}>
                <Barcode
                    value={this.state.data.eWayBillNo}
                    height={50}
                    textAlign={'center'} />
            </div>
        </div>

        return this.renderSectionContainer('5. Vehicle Details', container)
    }


    renderEwayTitleAndTextSection(title, value) {
        return <>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: "black",
                    width: 'auto',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
                :
                &nbsp;
            </text>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "black",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {getLocalisedText(value)}
            </text>
            &nbsp;&nbsp; &nbsp;&nbsp;
        </>
    }

    renderTextSection(value, isBold) {
        var fontStyle = "docFontStyle"

        if (isBold !== undefined) {
            if (isBold) {
                fontStyle = 'docFontStyleBold'
            }
        }
        return <text className={"pt-s pb-s " + fontStyle + getFontSizeClass()}
            style={{
                color: "black",
                width: 'auto',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
            }}>
            {getLocalisedText(value)}
        </text>
    }

    getAddressContainer(title, value) {
        return <>
            <div className="Column parent-width">
                <div className="RowDiv"
                    style={{
                        // backgroundColor: this.state.data.themeColor,
                        width: '100%',
                        // border: '1px solid black',
                    }}>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            textAlign: 'left',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, color: 'black' },this.state.data.printScale)
                        }}>
                        {getLocalisedText(title)}
                    </text>
                </div>
                <div className="RowDiv"
                    style={{
                        width: '100%',
                        border: '1px solid black',
                        borderWidth: this.borderBottomWidth,
                    }}>
                    <div className="RowDiv pl-s pr-s pt-s pb-s">
                        {value}
                    </div>
                </div>
            </div>
        </>
    }

    renderSectionContainer(title, container) {
        return <div className="ColumnDiv" style={{
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingTop: getVW(1),
            paddingBottom: getVW(1),
            borderBottom: this.borderBottom,
            borderBottomWidth: this.borderBottomWidth,
            borderBottomColor: this.state.themeColor,
        }}>
            <div className="RowDiv">
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: 'auto',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium },this.state.data.printScale),
                        paddingBottom: getVW(1),
                    }}>
                    {getLocalisedText(title)}
                </text>
            </div>
            {container}
        </div>
    }

    getValueByKey(key) {
        if (this.state.data !== undefined) {
            if (this.state.data[key] !== undefined && this.state.data[key] !== null) {
                return this.state.data[key]
            }
        }
        return ''
    }

    getTaxValueByKey(tax, key, currency) {
        if (tax !== undefined && tax !== null) {
            if (key !== undefined && key !== '') {
                if (tax[key] !== undefined && tax[key] !== null) {
                    return currency + ' ' + tax[key].toFixed(AppManager.getDecimalScale())
                }
            }
        }
        return ''
    }

    getTaxRate(item) {
        var taxRate = ''
        taxRate = taxRate + this.getDefaultTaxRateByKey(item, 'cgstAmount') + ' + '
        taxRate = taxRate + this.getDefaultTaxRateByKey(item, 'sgstAmount') + ' + '
        taxRate = taxRate + this.getDefaultTaxRateByKey(item, 'igstAmount') + ' + '
        taxRate = taxRate + this.getDefaultTaxRateByKey(item, 'cessAmount') + ' + '
        taxRate = taxRate + this.getDefaultTaxRateByKey(item, 'cessNonAdvolAmount')
        return taxRate
    }

    getDefaultTaxRateByKey(item, key) {
        if (item[key] !== undefined && item[key] !== null) {
            var val = Number(item[key])
            return val.toFixed(1)
        }

        return 'NE'
    }
}
