import React, { FC, useState } from "react";
import Utility, {
  getFontSizeClass,
  getPageHeight,
  getVW,
} from "../../Utilities/Utility";
import { getLocalisedText } from "../../Translate/LanguageManager";
import { COLOR_LIGHTGRAY, FONT_SIZE } from "../../Constants/Constants";
import ComponentManager from "../../Manager/ComponentManager";
import DKEInvoiceSection, {
  eInvoiceSectionPosition,
} from "../../DKUILibrary/DKEInvoiceSection";
import { AddressType } from "../../Helper/AddressParser";
import "./css/Templates.css";
import DocumentManager from "../../Manager/DocumentManager";

interface MachineSchedulerTemplate1Props {
  tableColumn: any; // You can replace 'any' with the specific type of your table column data
  data: any[]; // You can replace 'any[]' with the specific type of your data array
  tableClicked: () => void;
  stockTableClicked: () => void;
  onUpdateDataCallback: (data: any) => void; // Replace 'any' with the type of your data
  paymentTableClicked: () => void;
  onCustomFieldClick: () => void;
}

const arabicHeaderWidth = "85%";
const spacingRatio = 0.25;

const MachineSchedulerTemplate1: FC<MachineSchedulerTemplate1Props> = (
  props
) => {
  // Your component logic here
  const [data] = useState<any>(props.data);

  //   UI Methods
  function addCompanyNameAndDocTypeLabels() {
    return (
      <div
        className="ColumnDiv"
        style={{ color: data?.themeColor, width: "100%" }}
      >
        <div
          className="ColumnDiv"
          style={{
            width: Utility.getIsArabicLang() ? arabicHeaderWidth : "100%",
          }}
        >
          {data?.showDocumentType && getDocumentType()}
          {data?.showCompanyName && getCompanyName()}
        </div>

        {ComponentManager.getLogoVisibility(
          data?.showCompanyLogo,
          data?.isReadOnlyMode,
          data?.logo
        ) &&
          ComponentManager.getLogo(
            data.logo,
            Utility.convertToPrintScale(80, data.printScale),
            {
              right: 0,
              top: data.showEInvoiceSection ? undefined : 0,
            }
          )}
      </div>
    );
  }

  function getCompanyName() {
    return (
      <div
        className="RowDiv"
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: Utility.getIsArabicLang() ? "100%" : "70%",
          flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
        }}
      >
        <text
          className={"docFontStyleBold" + getFontSizeClass()}
          style={{
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.large },
              data.printScale
            ),
          }}
        >
          {getLocalisedText(Utility.getCompanyName(data))}
        </text>
      </div>
    );
  }

  function getDocumentType() {
    return (
      <div
        className="RowDiv"
        style={{
          alignItems: "center",
          marginBottom: getVW(20),
          flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
        }}
      >
        <text
          className={"docFontStyleBold" + getFontSizeClass()}
          style={{
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.xxLarge },
              data.printScale
            ),
            color: "rgb(100,100,100)",
          }}
        >
          {getLocalisedText(data.documentType)}
        </text>
      </div>
    );
  }

  function getCompanyAddressSection() {
    return ComponentManager.getDescriptionSection(
      "",
      Utility.getCompanyAddress(data),
      Utility.getIsArabicLang() ? arabicHeaderWidth : "25%",
      data.themeColor,
      false,
      "#000000",
      getVW(Utility.convertToPrintScale(6, data.printScale)),
      Utility.getOwnerType(data, AddressType.from),
      data.printScale
    );
  }

  function getScheduleInfo() {
    return (
      <div className="grid grid-cols-2 gap-1">
        <div>
          {ComponentManager.getRowValueSection(
            getLocalisedText("start_date"),
            data.schedule_start_date,  
            data.themeColor,
            undefined,
            undefined,
            true,
            data.printScale
          )}
        </div>
        <div>
          {ComponentManager.getRowValueSection(
            getLocalisedText("schedule_id"),
            data.schedule_id, 
            data.themeColor,
            undefined,
            undefined,
            true,
            data.printScale
          )}
        </div>
        <div>
          {ComponentManager.getRowValueSection(
            getLocalisedText("end_date"),
            data.schedule_end_date,  
            data.themeColor,
            undefined,
            undefined,
            true,
            data.printScale
          )}
        </div>
        <div>
          {ComponentManager.getRowValueSection(
            getLocalisedText("machine_name"),
            data.machine_name,  
            data.themeColor,
            undefined,
            undefined,
            true,
            data.printScale
          )}
        </div>
        <div>
          {ComponentManager.getRowValueSection(
            getLocalisedText("technician_name"),
            data.technician_name,  
            data.themeColor,
            undefined,
            undefined,
            true,
            data.printScale
          )}
        </div>
      </div>
    );
  }

  function getTableStyle() {
    let style = DocumentManager.getTableDefaultStyle();
    style.headerTextColor = data.themeColor;
    style.footerTextColor = data.themeColor;
    style.headerBorderColor = "#BBBABA";
    style.itemBorderColor = "#BBBABA";
    style.footerBackgroundColor = COLOR_LIGHTGRAY;
    style.footerBorderColor = "#BBBABA";
    return style;
  }

  function getProductTable() {
    // if (Utility.getIsShowAccountProductTable(data)) {
    return (
      <div className="RowDiv align-items-start parent-width">
      <div className="ColumnDiv parent-width">
        {ComponentManager.addVerticalSpace(20 * spacingRatio)}
        {getEditableDocumentTable(Utility.getTableColumnForMachineSchedulerMaterial(), "Material")}
        {ComponentManager.addVerticalSpace(20 * spacingRatio)}
      </div>
    </div>
    );
    // }
  }

  function getSopsTable() {
    // if (Utility.getIsShowAccountProductTable(data)) {
    return (
      <div>
        <div>
          {ComponentManager.getTitleAndValueColumn(
            "Sops",
            "",
            data.themeColor,
            undefined,
            undefined,
            undefined,
            undefined,
            data.printScale
          )}
        </div>
        <div className="ColumnDiv parent-width">
          {ComponentManager.addVerticalSpace(20 * spacingRatio)}
          {getEditableDocumentTable(Utility.getTableColumnForMachineSchedulerSop(), 'SOP')}
          {ComponentManager.addVerticalSpace(20 * spacingRatio)}
        </div>
      </div>
    );
    // }
  }

  function getEditableDocumentTable(tableColumn:any[], type:string  ) {
    return ComponentManager.getDKDocumentTableWithSubtype(
      tableColumn,
      getTableStyle(),
      props.data,
      props.tableClicked,
      props.stockTableClicked,
      props.onUpdateDataCallback,
      props.paymentTableClicked,
      undefined, type
    );
  }


  return (
    <div
      className="DocumentHolder ColumnDiv"
      style={{
        justifyContent: "space-between",
        minHeight: getPageHeight(data.printScale),
      }}
    >
      <div
        className="parent-width"
        style={{ width: "100%", paddingBottom: getVW(30) }}
      >
        <DKEInvoiceSection data={data} position={eInvoiceSectionPosition.top} />
        {addCompanyNameAndDocTypeLabels()}
        {data?.showFrom && getCompanyAddressSection()}
        {ComponentManager.addVerticalSpace(
          Utility.convertToPrintScale(70, data.printScale)
        )}
        {getScheduleInfo()}
        {ComponentManager.addVerticalSpace(
          Utility.convertToPrintScale(70, data.printScale)
        )}
        {getProductTable()}
        {ComponentManager.addVerticalSpace(
          Utility.convertToPrintScale(70, data.printScale)
        )}
        {getSopsTable()}
      </div>
    </div>
  );
};

export default MachineSchedulerTemplate1;
