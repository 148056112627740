import React, { Component } from "react";
import "../../index.css";
import { EditorInfoType, FONT_SIZE } from "../../Constants/Constants";
import { AddressType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from "../../Translate/LanguageManager";
import Utility, { getFontSizeClass, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";

export default class ReceiptTemplate2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%'}}>
                    <div style={{
                        position: 'relative',
                        alignItems: 'center'
                    }}>
                        {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(100, this.state.data.printScale), {position: 'relative'})}
                    </div>
                    <div>
                        {this.state.data.showCompanyName &&
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{ ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale) }}>
                                {getLocalisedText(Utility.getCompanyName(this.state.data))}
                            </text>
                        }
                    </div>
                    <div>
                        {this.state.data.showFrom &&
                            <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                                style={{
                                    display: 'block',
                                    // marginTop: getVW(10),
                                    paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    ...Utility.getFontStyleProp({
                                        fontSize: FONT_SIZE.large,
                                        fontColor: this.state.data.themeColor,
                                        ownerType: Utility.getOwnerType(this.state.data, AddressType.from)
                                    }, this.state.data.printScale),
                                }}
                                dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                            </div>
                        }
                    </div>
                    {this.state.data.showDocumentType &&
                        <div style={{
                            paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            borderTop: '1px solid grey',
                            borderBottom: '1px solid grey',
                        }}>
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale)
                                }}>
                                {getLocalisedText(this.state.data.documentType)}
                            </text>
                        </div>
                    }
                    {this.getDocumentDetailContainer(this.state.data.showRefNumber, AppManager.getTypeNoString(this.state.data.documentType), this.state.data.refNumber )}
                    {this.getDocumentDetailContainer(this.state.data.showStartDate, AppManager.getTypeDateString(this.state.data.documentType), this.state.data.date)}
                    {this.getDocumentDetailContainer(this.state.data.showDueDate, ComponentManager.getEndDateLabel(this.state.data.type), this.state.data.dueDate)}
                    {this.getAddressContainer(this.state.data.showClientBillToAddress, this.state.data.billToTitle, Utility.getClientBillToAddress(this.state.data))}
                    {this.getAddressContainer(this.state.data.showClientShipToAddress, this.state.data.shipToTitle, Utility.getClientShipToAddress(this.state.data))}
                    {this.getEditableDocumentTable()}
                    {this.getNotesContainer(this.state.data.showTermsAndCondition, 'terms_&_condition', this.state.data.termsAndCondition, EditorInfoType.termsAndCondition)}
                    {this.getNotesContainer(this.state.data.showNotes, Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')), this.state.data.notes, EditorInfoType.notes)}
                </div>

            </div>
        );
    }

    getDocumentDetailContainer(isVisible, title, value) {
        if(isVisible) {
            return <div className="RowDiv" style={{
                paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                justifyContent: 'space-between'
            }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(title)}
                </text>
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(value)}
                </text>
            </div>
        }

        return undefined
    }


    getAddressContainer(isVisible, title, value) {
        if (isVisible) {
            return <div style={{
                paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                borderTop: '1px solid grey',
                textAlign: 'left',
            }}>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(title)}
                </div>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(value) }}>
                </div>
            </div>
        }
        return undefined
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getNotesContainer(isVisible, title, value, type) {
        if (isVisible) {
            return <div style={{
                paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                textAlign: 'left',
            }}>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(type), headerTitle: true }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(title)}
                </div>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(type) }, this.state.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(value) }}>
                </div>
            </div>
        }
        return undefined
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "transparent";
        style.borderColor = 'transparent'
        // style.footerBorderColor = "black";
        style.footerBorderColor = "transparent";
        style.lastRowTopColor = 'black';
        style.lastRowBottomColor = 'black';
        // style.lastRowTopWidth = getVW(2)
        // style.lastRowBottomWidth = getVW(2)
        style.borderWidth = '1px'
        // style.headerBorderColor = "#BBBABA";
        // style.itemBorderColor = "#BBBABA";
        // style.footerBackgroundColor = COLOR_LIGHTGRAY;
        // style.footerBorderColor = "#BBBABA";
        return style;
    }
}
