import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface DepreciationScheduleAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  Year?: number;
  QueryParam?: any;
  CustomField?: any;
  AssetGroupIds?: any;
}

export const defaultConfig: DepreciationScheduleAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Year: 0,
  Query: '',
  QueryParam: '',
  AssetGroupIds: 0
};
class DepreciationScheduleService {
  static apiConfig: DepreciationScheduleAPIConfig;
  static abortController: any = null;

  static getDepreciationScheduleByYear() {
    Utility.cancelRequest(DepreciationScheduleService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?year=${this.apiConfig.Year}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}${
      this.apiConfig.AssetGroupIds
        ? '&assetGroupIds=' + this.apiConfig.AssetGroupIds
        : ''
    }`;

    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.DEPRECIATION_SCHEDULE +
      queryString;
    DepreciationScheduleService.abortController =
      Utility.createAbortController();
    return http2
      .get(`${finalEndpoint}`, {
        signal: DepreciationScheduleService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fiscalPeriod(year: number) {
    return http2
      .get(
        `${ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.FISCAL_PERIOD}?year=${year}`
      )
      .catch((err: any) => {
        console.error('Error fetching Fiscal Period: ', err);
        return Promise.reject(err);
      });
  }

  static doRollBackPostPreview(payload: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.ROLL_BACK_PREVIEW;

    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error in Post preview: ', err);
      return Promise.reject(err);
    });
  }

  static doRollBackPost(payload: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.ROLL_BACK;

    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error in Roll Back Post: ', err);
      return Promise.reject(err);
    });
  }

  static PostDepreciationPreview(payload: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.POST_DEPRECIATION_PREVIEW;

    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error in Post Depreciation preview: ', err);
      return Promise.reject(err);
    });
  }

  static doPostDepreciation(payload: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.DEPRECIATION_SCHEDULE.POST_DEPRECIATION;

    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error in Post preview: ', err);
      return Promise.reject(err);
    });
  }
}

export default DepreciationScheduleService;
