import { BREAKPOINTS, VIEWPORT_SIZES } from '../Constants/Constant';
import { isTablet } from 'react-device-detect';

// eslint-disable-next-line import/no-anonymous-default-export
const getViewportSize = () => {
  const width = window.innerWidth;

  if (width <= BREAKPOINTS.SMALL) {
    return VIEWPORT_SIZES.EXTRA_SMALL;
  } else if (width <= BREAKPOINTS.LARGE) {
    return VIEWPORT_SIZES.SMALL;
  } else {
    return VIEWPORT_SIZES.LARGE;
  }
};

const isViewportLarge = () => getViewportSize() === VIEWPORT_SIZES.LARGE;

export { getViewportSize, isViewportLarge };

//Check for in-app webview.
export function isMobileWebView() {
  // @ts-ignore
  let androidWebView = window?.navigator?.userAgent?.toLowerCase()?.includes('wv'),
    iosWebView = /(iPhone|iPod|iPad|Mac).*AppleWebKit(?!.*Safari)/i.test(window?.navigator?.userAgent);

  if (iosWebView || androidWebView) {
    return true;
  }
  return false;
}

export function isTabletView() {
  return isTablet
}

export function isDesktopOrTablet() {
  return isViewportLarge() || isTablet
}

export function isTabletSize() {
  return window.innerWidth <= BREAKPOINTS.XLARGE;
}

export function getTabletGridWidth(extraWidth: number) {
  if (isMobileWebView()) {
    return window.screen.width - extraWidth
  } else {
    return window.screen.width
  }
}