import React, { useEffect, useState } from 'react';
import {
  APP_NAME,
  BOOKS_DATE_FORMAT,
  COMMON_CONSTANT,
  COUNTRY_CODES,
  DATE_FORMAT,
  GSTIN_REGEX_PATTERN,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import {
  COUNTRIES_LIST,
  INDIAN_STATES_MOCK_DATA
} from '../../Constants/StaticData';
import {
  IAddress,
  ReactSelectOptionsType,
  SubscriptionPLan
} from '../../Models/Interfaces';
import DateFormatService from '../../Services/DateFormat';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../Utility/Utility';
import {
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  removeLoader,
  showLoader,
  DKLabel,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import InputLabel from '../../SharedComponents/InputLabel/InputLabel';
import CurrencyDropdown from '../../SharedComponents/CurrencyDropdown/CurrencyDropdown';
import { Toggle } from '../../SharedComponents/ToggleBtn/Toggle';
import {
  NewOrgFormFields,
  NewOrgFormProps,
  NewOrgFormState,
  newOrgState
} from '../../Models/NewOrgForm';
import { Information } from '../../Models/PaymentPopup';
import {
  OnboardingStep1Form,
  OrgSetupCompleted
} from '../../Models/Onboarding';
import IamService from '../../Services/iam';
import TenantService from '../../Services/Tenant';
import { useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  isBookKeeper,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import AuthService from '../../Services/Auth';
import AppManager from '../../Managers/AppManager';
import { DATE_COMPARISON } from '../../Constants/Enum';
import { localizedText } from '../../Services/Localization/Localization';
import CommonStateListPicker from '../../SharedComponents/DocumentForm/CommonStateListPicker';

const NewOrgForm: React.FC<NewOrgFormProps> = (props) => {
  type ReactSelectType = ReactSelectOptionsType<string, string>;
  const [formState, setFormState] = useState<NewOrgFormState>(
    deepClone(newOrgState)
  );

  const currentTenantDetails = useAppSelector(activeTenantInfo);
  const currentSubscriptionPlanInfo: SubscriptionPLan =
    useAppSelector(subscriptionInfo);

  useEffect(() => {
    // fetch financial years dates for different countries
    fetchFinancialYearDates();

    const updatedState = formState;
    const countryListOptions: ReactSelectType[] = [];
    const indianStateOptions: ReactSelectType[] = [];
    COUNTRIES_LIST.forEach((country) => {
      const option: ReactSelectType = {
        label: country.name,
        value: country.code
      };
      if (country.currencyCode === 'INR') {
        updatedState.formDetails.formInfo.country.defaultValue = option;
        updatedState.formDetails.formInfo.country.value = option;
      }
      countryListOptions.push(option);
    });

    INDIAN_STATES_MOCK_DATA.forEach((state) => {
      const option: ReactSelectType = {
        label: state.name,
        value: state.code,
        code: state.stateCode,
        searchableKey: `${state.name} ${state.stateCode}`
      };
      if (state.stateCode === 'AN') {
        updatedState.formDetails.formInfo.state.defaultValue = option;
        updatedState.formDetails.formInfo.state.value = option;
      }
      indianStateOptions.push(option);
    });
    updatedState.formDetails.formInfo.country.options = countryListOptions;
    updatedState.formDetails.formInfo.state.options = indianStateOptions;

    // set todays Date
    const todayDate = DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    updatedState.formDetails.formInfo.booksBeginningDate.value = todayDate;
    updatedState.formDetails.formInfo.financialStartDate = {
      isOpen: false,
      date: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    setFormState({ ...updatedState });
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CREATE_NEW_ORG,
      data: () => {
        createOrg();
      }
    });
  };

  const fetchFinancialYearDates = () => {
    TenantService.getDefaultFY()
      .then((response: any) => {
        fetch(response)
          .then((response) => {
            return response.json();
          })
          .then((stringifiedData) => {
            const defaultDate =
              stringifiedData[COMMON_CONSTANT.DEFAULT_FY_DATE];
            const updatedState = formState;
            updatedState.financeYearDates = defaultDate;
            const countryCode =
              updatedState.formDetails.formInfo.country.value.value;
            setFinancialYearDates(countryCode, updatedState);
            setFormState({ ...updatedState });
          });
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  };

  const setFinancialYearDates = (
    countryCode: string,
    updatedState: NewOrgFormState
  ) => {
    const countryFinancialYearDates = updatedState.financeYearDates?.find(
      (country) => country.countryLookupCode === countryCode
    );
    if (countryFinancialYearDates) {
      let currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      if (countryCode === COUNTRY_CODES.IN && currentMonth < 3) {
        currentYear = currentYear - 1;
      }
      const financialDate =
        countryFinancialYearDates.financialStartDate + '-' + currentYear;
      updatedState.formDetails.formInfo.financialStartDate.date =
        DateFormatService.getFormattedDateString(
          financialDate,
          BOOKS_DATE_FORMAT['MM-DD-YYYY'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
      updatedState.formDetails.formInfo.booksBeginningDate.value =
        updatedState.formDetails.formInfo.financialStartDate.date;
    } else {
      const todaysDateStr = DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      updatedState.formDetails.formInfo.booksBeginningDate.value =
        todaysDateStr;
      updatedState.formDetails.formInfo.financialStartDate = {
        isOpen: false,
        date: DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
    }
  };

  const dropdownOptionChanged = (
    fieldValue: ReactSelectOptionsType<string, string> | null,
    fieldName: NewOrgFormFields
  ) => {
    const updatedState = formState;
    if (fieldValue) {
      switch (fieldName) {
        case NewOrgFormFields.COUNTRY:
          setFinancialYearDates(fieldValue.value, updatedState);
          //resetting the GST values for country change
          updatedState.formDetails.formInfo.gstRegistered = false;
          updatedState.formDetails.formInfo.gstIn.value = '';
          if (fieldValue.value !== COUNTRY_CODES.IN) {
            updatedState.formDetails.formInfo.state.hidden = true;
          } else {
            updatedState.formDetails.formInfo.state.hidden = false;
          }
          const countryDetails = COUNTRIES_LIST.find(
            (country) => country.code === fieldValue.value
          );
          if (countryDetails) {
            updatedState.formDetails.formInfo.currency.value = {
              label: '',
              value: countryDetails.currencyCode
            };
          }
          updatedState.formDetails.formInfo.country.value = fieldValue;
          break;
        case NewOrgFormFields.STATE:
          updatedState.formDetails.formInfo.state.value = fieldValue;
          break;
      }
      setFormState({ ...updatedState });
    }
  };

  const formFieldChanged = (
    fieldValue: string | Date | boolean,
    fieldName: NewOrgFormFields
  ) => {
    const updatedState = formState;
    switch (fieldName) {
      case NewOrgFormFields.ORG_NAME:
        updatedState.formDetails.formInfo.organisationName.value =
          fieldValue as string;
        updatedState.formDetails.formInfo.organisationName.hasError = false;
        break;
      case NewOrgFormFields.BOOKS_BEGINNING_DATE:
        const booksBeginningDate = DateFormatService.getDateStrFromDate(
          fieldValue as Date,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        updatedState.formDetails.formInfo.booksBeginningDate.value =
          booksBeginningDate;

        // date validation
        dateValidation(
          DateFormatService.getDateFromStr(
            updatedState.formDetails.formInfo.financialStartDate.date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          DateFormatService.getDateFromStr(
            updatedState.formDetails.formInfo.booksBeginningDate.value.toString(),
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          updatedState
        );
        break;
      case NewOrgFormFields.FINANCIAL_START_DATE:
        updatedState.formDetails.formInfo.financialStartDate = {
          isOpen: false,
          date: DateFormatService.getDateStrFromDate(
            fieldValue as Date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };

        // date validation
        dateValidation(
          DateFormatService.getDateFromStr(
            updatedState.formDetails.formInfo.financialStartDate.date,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          DateFormatService.getDateFromStr(
            updatedState.formDetails.formInfo.booksBeginningDate.value.toString(),
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          updatedState
        );
        break;
      case NewOrgFormFields.GST_REGISTERED:
        updatedState.formDetails.formInfo.gstRegistered = fieldValue as boolean;
        if (
          updatedState.formDetails.formInfo.country.value.value ===
          COUNTRY_CODES.IN
        ) {
          if (fieldValue) {
            updatedState.formDetails.formInfo.gstIn.hidden = false;
          } else {
            updatedState.formDetails.formInfo.gstIn.hidden = true;
          }
        }
        break;
      case NewOrgFormFields.GST_IN_NUMBER:
        const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
        updatedState.formDetails.formInfo.gstIn.hasError = false;
        updatedState.formDetails.formInfo.gstIn.value = fieldValue as string;
        if (fieldValue && !gstRegex.test(fieldValue as string)) {
          updatedState.formDetails.formInfo.gstIn.hasError = true;
          updatedState.formDetails.formInfo.gstIn.errorMsg =
            'GSTIN must be 15 characters long';
        }
        break;
      default:
        break;
    }

    setFormState({ ...updatedState });
  };

  const dateValidation = (
    financialStartDate: Date,
    booksBeginningDate: Date,
    updatedState: NewOrgFormState
  ) => {
    const result = DateFormatService.compareDates(
      financialStartDate,
      booksBeginningDate
    );

    if (result === DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE) {
      updatedState.formDetails.formInfo.booksBeginningDate.hasError = true;
      updatedState.formDetails.formInfo.booksBeginningDate.errorMsg =
        'Books beginning date should be after financial start date';
    } else {
      updatedState.formDetails.formInfo.booksBeginningDate.hasError = false;
    }
  };

  const grabCurrencyInfo = (data: Information) => {
    const updatedState = formState;
    const option: ReactSelectType = {
      label: data.selectedCurrencyName,
      value: data.selectedCurrencyCode
    };
    updatedState.formDetails.formInfo.currency.value = option;
    setFormState({ ...updatedState });
  };

  const formFieldValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(formState);
    const { gstIn, gstRegistered, organisationName } =
      updatedState.formDetails.formInfo;

    if (!organisationName.hidden && !organisationName.value.toString().trim()) {
      organisationName.hasError = true;
      organisationName.errorMsg = 'Organisation name is required';
      formHasError = true;
    }

    /* INDIAN COMPLIANCE*/

    // gst registered
    if (gstRegistered && !gstIn.hidden && gstIn.value) {
      const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
      if (!gstRegex.test(gstIn.value.toString())) {
        gstIn.hasError = true;
        gstIn.errorMsg = 'GSTIN must be 15 characters long';
        formHasError = true;
      }
    }

    // date Validation
    const result = DateFormatService.compareDates(
      DateFormatService.getDateFromStr(
        updatedState.formDetails.formInfo.financialStartDate.date,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      DateFormatService.getDateFromStr(
        updatedState.formDetails.formInfo.booksBeginningDate.value.toString(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    );

    if (result === DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE) {
      updatedState.formDetails.formInfo.booksBeginningDate.hasError = true;
      updatedState.formDetails.formInfo.booksBeginningDate.errorMsg =
        'Books beginning date should be after financial start date';
      formHasError = true;
    }

    if (formHasError) {
      setFormState({ ...updatedState });
      return false;
    }
    return true;
  };

  const isBookKeeperFlag = useAppSelector(isBookKeeper);

  const createOrg = () => {
    const isFormValid = formFieldValidation();
    if (!isFormValid) {
      return;
    }

    const orgFormDetails: OnboardingStep1Form = {
      tenantName:
        formState.formDetails.formInfo.organisationName.value.toString(),
      financialStartDate:
        formState.formDetails.formInfo.financialStartDate.date,
      bookBeginningStartDate:
        formState.formDetails.formInfo.booksBeginningDate.value.toString(),
      currencyLookUpCode: formState.formDetails.formInfo.currency.value.value,
      countryLookupCode: formState.formDetails.formInfo.country.value.value,
      complianceEnabled: formState.formDetails.formInfo.gstRegistered,
      numberFormat: 'us',
      purpose: '',
      industry: '',
      size: '',
      dateFormatLookUpCode: DATE_FORMAT.DD_MM_YYYY,
      userId: AuthService.getUserID(),
      isBookkeeper: isBookKeeperFlag
    };

    if (orgFormDetails.countryLookupCode === COUNTRY_CODES.IN) {
      orgFormDetails.gstRegistered =
        formState.formDetails.formInfo.gstRegistered;
      orgFormDetails.gstin = formState.formDetails.formInfo.gstIn.value
        ? formState.formDetails.formInfo.gstIn.value.toString()
        : '';
      orgFormDetails.nexusStates =
        formState.formDetails.formInfo.state.value.label;

      const address: IAddress = {
        city: '',
        country: orgFormDetails.countryLookupCode,
        postalCode: '',
        preferred: true,
        state: orgFormDetails.nexusStates
      };
      orgFormDetails.billingAddresses = [address];
      orgFormDetails.shippingAddresses = [address];
    }

    if (props.isBookkeeperFlow) {
      if (props.getOrgFormDetails) {
        props.getOrgFormDetails(orgFormDetails);
        return;
      }
    }

    const loadOrgForm = orgForm(orgFormDetails);

    showLoader();
    Promise.all([loadOrgForm])
      .then((res) => {
        const response1: any = res[0];
        const tenantId = response1.tenantId;
        const userId: number = response1.userId;
        const loadSwitchTenantApi = switchTenant(tenantId);
        const loadUpdateOrgStatusApi = updateOrgStatus();

        let promiseApis: any = [loadSwitchTenantApi, loadUpdateOrgStatusApi];
        if (!props.isAddNewOrgFlow) {
          const loadPatchOrgDetails = patchOrgDetails(orgFormDetails);
          promiseApis.push(loadPatchOrgDetails);
        }

        Promise.all(promiseApis)
          .then((res) => {
            removeLoader();
            setTimeout(() => {
              AppManager.refreshApp();
            }, 1000);
          })
          .catch((err) => {
            removeLoader();
            console.log('An error occured: ', err);
          });
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const switchTenant = async (tenantId: number) => {
    try {
      const response = IamService.switchTenant({ tenantId: tenantId });
      return response;
    } catch (error) {
      console.error('Error switching tenant: ', error);
    }
  };

  const updateOrgStatus = async () => {
    try {
      const response = TenantService.updateOrgStatus(OrgSetupCompleted);
      return response;
    } catch (error) {
      console.error('Error updating org Setup: ', error);
    }
  };

  const orgForm = async (orgFormDetails: OnboardingStep1Form) => {
    let countryCode = Utility.countriesRequiringCodeForCreation.includes(
      orgFormDetails.countryLookupCode ? orgFormDetails.countryLookupCode : ''
    )
      ? orgFormDetails.countryLookupCode
      : '';
    try {
      const response = TenantService.createOrgWithSetup(
        orgFormDetails,
        countryCode
      );
      return response;
    } catch (error) {
      console.error('Error creating org with setup: ', error);
    }
  };

  const patchOrgDetails = async (orgFormDetails: OnboardingStep1Form) => {
    try {
      const response = TenantService.updateOrgSetting(orgFormDetails);
      return response;
    } catch (error) {
      console.error('Error patching Org Details: ', error);
    }
  };

  return (
    <div className="w-full px-4 pb-xxl">
      <div>
        <InputLabel
          text={`${getCapitalized(localizedText('organisation'))} Name`}
          className="text-left text-gray-600 fs-r"
        />
        <DKInput
          type={INPUT_TYPE.TEXT}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          autoFocus={true}
          value={formState.formDetails.formInfo.organisationName.value}
          required={formState.formDetails.formInfo.organisationName.isMandatory}
          onChange={(value: string) => {
            formFieldChanged(value, NewOrgFormFields.ORG_NAME);
          }}
          onBlur={(e: any) => {
            if (props?.isBookkeeperFlow) {
              const value = e.target.value;
              const allOrgList: any[] = props?.allTenantsWithBKTenants ?? [];
              const found = allOrgList?.find((allOrgItem: any) => {
                return (
                  allOrgItem.tenantName.toLowerCase() === value.toLowerCase()
                );
              });
              if (Utility.isEmpty(found)) {
                formFieldChanged(value, NewOrgFormFields.ORG_NAME);
              } else {
                showToast(
                  'Duplicate tenant name not allowed.',
                  TOAST_TYPE.FAILURE
                );
                formFieldChanged('', NewOrgFormFields.ORG_NAME);
                return;
              }
            }
          }}
          canValidate={formState.formDetails.formInfo.organisationName.hasError}
          validator={(value: string) => {
            return !formState.formDetails.formInfo.organisationName.hasError;
          }}
          errorMessage={
            formState.formDetails.formInfo.organisationName.errorMsg
          }
        />
      </div>
      <div className="flex flex-wrap mt-8">
        {formState.formDetails.formInfo.country.options && (
          <div
            className={'flex-1 pr-4 text-left'}
            style={{ minWidth: '250px', maxWidth: '460px' }}
          >
            <DKInput
              value={formState.formDetails.formInfo.country.value?.label}
              title={'Country Name'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              formatter={(obj: any) => {
                return obj;
              }}
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              canValidate={formState.formDetails.formInfo.country.hasError}
              onChange={(value: any) => {
                dropdownOptionChanged(value, NewOrgFormFields.COUNTRY);
              }}
              errorMessage={formState.formDetails.formInfo.country.errorMsg}
              dropdownConfig={{
                allowSearch: true,
                searchableKey: 'label',
                data: formState.formDetails.formInfo.country.options,
                renderer: (index: any, obj: any) => {
                  return (
                    <div className="flex flex-row w-full justify-content-between">
                      <DKLabel
                        style={{ fontSize: '13px' }}
                        className="text-base border-radius-s"
                        text={obj.label}
                      />
                    </div>
                  );
                }
              }}
            />
          </div>
        )}
        {!formState.formDetails.formInfo.state.hidden &&
          formState.formDetails.formInfo.state.options && (
            <div
              className={'flex-1 pr-4 text-left'}
              style={{ minWidth: '250px', maxWidth: '460px' }}
            >
              <CommonStateListPicker
                title={'Select State'}
                value={formState.formDetails.formInfo.state.value?.label}
                dropdownOptions={formState.formDetails.formInfo.state.options}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                isSearchable={true}
                className={''}
                searchableKey={'searchableKey'}
                onChange={(value: any) => {
                  dropdownOptionChanged(value, NewOrgFormFields.STATE);
                }}
              />
            </div>
          )}
        <div
          className={'flex-1 pr-4'}
          style={{ minWidth: '250px', maxWidth: '460px' }}
        >
          <InputLabel text={'Currency'} className="text-left text-black fs-r" />
          <CurrencyDropdown
            currencyCode={
              formState.formDetails.formInfo.currency.value
                ? formState.formDetails.formInfo.currency.value.value
                : 'INR'
            }
            getInfo={grabCurrencyInfo}
            width="100%"
            showAllCurrencyOptions={true}
          />
          <div className="mt-5"></div>
        </div>
        <div
          className={'flex-1 pr-4'}
          style={{ minWidth: '250px', maxWidth: '460px' }}
        ></div>
      </div>

      <div className="flex flex-wrap my-5">
        <div
          className={'flex-1 pr-4'}
          style={{ minWidth: '250px', maxWidth: '460px' }}
        >
          <DKInput
            title="Financial year start date"
            value={DateFormatService.getDateFromStr(
              formState.formDetails.formInfo.financialStartDate.date,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
            type={INPUT_TYPE.DATE}
            onChange={(date: Date) =>
              formFieldChanged(date, NewOrgFormFields.FINANCIAL_START_DATE)
            }
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              currentTenantDetails.dateFormat
            )}
          />
        </div>

        <div
          className={'flex-1 pr-4'}
          style={{ minWidth: '250px', maxWidth: '460px' }}
        >
          <DKInput
            title="Book beginning date"
            value={DateFormatService.getDateFromStr(
              formState.formDetails.formInfo.booksBeginningDate.value.toString(),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
            type={INPUT_TYPE.DATE}
            onChange={(date: Date) =>
              formFieldChanged(date, NewOrgFormFields.BOOKS_BEGINNING_DATE)
            }
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
            canValidate={
              formState.formDetails.formInfo.booksBeginningDate.hasError
            }
            validator={(value: string) => {
              return !formState.formDetails.formInfo.booksBeginningDate
                .hasError;
            }}
            errorMessage={
              formState.formDetails.formInfo.booksBeginningDate.errorMsg
            }
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              currentTenantDetails.dateFormat
            )}
          />
        </div>
      </div>
      {console.log(
        formState.formDetails.formInfo.country.value,
        'country value'
      )}
      {formState.formDetails.formInfo.country.value.value ===
        COUNTRY_CODES.IN && (
        <div className="row mt-8 parent-width align-items-end pr-4">
          <div className="row align-items-center">
            <InputLabel
              text={
                formState.formDetails.formInfo.country.value.value ===
                COUNTRY_CODES.IN
                  ? 'GST Registered'
                  : 'Do you collect tax? '
              }
              className="text-left text-gray-600 mr-xl align-self-start"
            />
            <Toggle
              onChange={(checked) =>
                formFieldChanged(checked, NewOrgFormFields.GST_REGISTERED)
              }
            />
          </div>
          {!formState.formDetails.formInfo.gstIn.hidden &&
            formState.formDetails.formInfo.gstRegistered && (
              <DKInput
                title="GSTIN Number"
                className="text-gray-600 parent-width"
                canValidate={formState.formDetails.formInfo.gstIn.hasError}
                required={formState.formDetails.formInfo.gstIn.isMandatory}
                value={formState.formDetails.formInfo.gstIn.value}
                onChange={(value: string) =>
                  formFieldChanged(value, NewOrgFormFields.GST_IN_NUMBER)
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                validator={(value: string) => {
                  return !formState.formDetails.formInfo.gstIn.hasError;
                }}
                errorMessage={formState.formDetails.formInfo.gstIn.errorMsg}
              />
            )}
        </div>
      )}
      {/* {formState.showPlanUpgradePopup && (
        <PlanUpgrade userId={formState.userId} tenantId={formState.tenantId} />
      )} */}
    </div>
  );
};

export default NewOrgForm;
