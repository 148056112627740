import { DKLabel } from 'deskera-ui-library';
export interface IStatusChipProps {
  title: string;
  className: string;
  onClick?: () => void;
  width: string;
}
export const JCStatusChip: React.FC<IStatusChipProps> = (
  props: IStatusChipProps
) => {
  const commonCSS = `fw-m text-align-center text-wrap-none p-h-s text-white parent-width border-radius-s p-v-xs leading-4`;
  return (
    <div
      className={` ${props.className} ${commonCSS} row justify-content-center`}
    >
      {props.title}
    </div>
  );
};
