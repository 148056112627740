import { DOC_TYPE, TAX_TYPES } from '../Constants/Constant';
import NumberFormatService from '../Services/NumberFormat';
import Utility from './Utility';
export class NotesConfigUtility {
  static accountFormatter = ({ value }: any) =>
    !Utility.isEmpty(value)
      ? `${
          value.name
        }<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
          value.hsnOrSacCode ? `HSN/SAC: ${value.hsnOrSacCode}` : ''
        }</span>`
      : '';
  static taxFormatter = ({ value }: any) => value.name;
  static accountRenderer = (index: number, account: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{account.name}</div>
        <div className="flex flex-row parent-width justify-between fs-s">
          <div className="flex flex-row text-gray">{account.code}</div>
          {account.barcode && (
            <div className="flex flex-row justify-items-end">
              <div className="text-gray">{account.name}</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  static taxRenderer = (index: number, product: any) => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">{product.name}</div>
      </div>
    );
  };
  static accountsDataParser = (data: any) => data.content;
  static taxDataParser = (data: any, docType: string) => {
    let dataArray = data.content?.length ? data.content : data;
    if (
      docType === DOC_TYPE.CREDIT_NOTE ||
      docType === DOC_TYPE.DEPOSIT ||
      docType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
    ) {
      return (
        dataArray &&
        dataArray.length > 0 &&
        dataArray.filter(
          (tax: any) =>
            tax.type === TAX_TYPES.SALES || tax.type === TAX_TYPES.BOTH
        )
      );
    } else {
      return (
        dataArray &&
        dataArray.length > 0 &&
        dataArray.filter(
          (tax: any) =>
            tax.type === TAX_TYPES.PURCHASE || tax.type === TAX_TYPES.BOTH
        )
      );
    }
  };
  static amountFormatter = (amount: number, currency: any) =>
    `${Utility.getCurrencySymbolFromCode(currency)} ${
      amount < 0 ? '(' : ''
    }${NumberFormatService.getNumber(Math.abs(amount))}${
      amount < 0 ? ')' : ''
    }`;
}
