import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import OperationsService from '../../../Services/MRP/Operations';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchOperationsList = createAsyncThunk(
  'fetchOperations',
  async () => {
    const response = await OperationsService.getOperations();
    return response;
  }
);

export const OperationSlice = createSlice({
  name: 'Operations',
  initialState,
  reducers: {
    addOperationsColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.OPERATIONS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToOperationsColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    addOperations: (state, action: PayloadAction<any[]>) => {
      if (action.payload?.length !== 0) {
        if (state.data.operations?.content?.length) {
          state.data.operations.content = [
            ...state.data.operations.content,
            ...action.payload
          ];
        } else {
          state.data.operations.content = [...action.payload];
        }
      }
    },
    addOperation: (state, action: PayloadAction<any>) => {
      if (state.data.operations?.content?.length) {
        const ifExists = state.data.operations.content.find(
          (operation: any) => operation.id === action.payload.id
        );
        if (ifExists) {
          state.data.operations.content = state.data.operations.content.map(
            (operation: any) => {
              if (operation.id === action.payload.id) {
                return action.payload;
              }
              return operation;
            }
          );
        } else {
          state.data.operations.content = [
            action.payload,
            ...state.data.operations.content
          ];
        }
      } else {
        state.data.operations.content = [action.payload];
      }
    },
    addUniqueOperations: (state, action: PayloadAction<any[]>) => {
      if (action.payload?.length !== 0) {
        if (state.data.operations?.content?.length) {
          const uniqueOperations = action.payload.filter(
            (operation) =>
              !state.data.operations.content.some(
                (existingOperation: any) =>
                  existingOperation?.id === operation?.id
              )
          );
          state.data.operations.content = [
            ...state.data.operations.content,
            ...uniqueOperations
          ];
        } else {
          state.data.operations.content = [...action.payload];
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperationsList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchOperationsList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.operations = action.payload;
      });
  }
});

export const {
  addOperationsColumnConfig,
  updateColumnConfig,
  addColumnToOperationsColumnConfig,
  addOperations,
  addOperation,
  addUniqueOperations
} = OperationSlice.actions;

export const selectOperations = (state: RootState) =>
  state.mrpOperations.data.operations;
export const selectOperationsColumnConfig = (state: RootState) =>
  state.mrpOperations.columnConfig;
export const selectOperationsColumnConfigTableId = (state: RootState) =>
  state.mrpOperations.configTableId;
