import { INPUT_TYPE, showAlert } from 'deskera-ui-library';
import {
  COLUMN_CONFIGS_FOR_FULFILLMENT,
  COLUMN_CONFIGS_FOR_RG
} from '../../Components/Mrp/Constants/MRPColumnConfigs';
import { DOC_TYPE, QTY_ROUNDOFF_PRECISION } from '../../Constants/Constant';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import { isEqual } from 'lodash';

export const LOCALIZED_UOM_QTY = 'receiptQuantityForSeparateColumnDisplay';

export const getColumnsForFulfilmentGrid = (moduleName: any) => {
  let columns: any[] = [];
  if (moduleName === DOC_TYPE.FULFILLMENT) {
    columns = !Store?.getState()?.authInfo?.currentTenantInfo?.data
      ?.additionalSettings?.LOCALIZED_UOM_CONVERSION
      ? COLUMN_CONFIGS_FOR_FULFILLMENT
      : [...COLUMN_CONFIGS_FOR_FULFILLMENT, actualTotalQuantityColumn];
  } else if (moduleName === DOC_TYPE.GOODS_RECEIPT) {
    columns = !Store?.getState()?.authInfo?.currentTenantInfo?.data
      ?.additionalSettings?.LOCALIZED_UOM_CONVERSION
      ? COLUMN_CONFIGS_FOR_RG
      : [...COLUMN_CONFIGS_FOR_RG, actualTotalQuantityColumn];
  }

  return columns;
};

const actualTotalQuantityColumn = {
  id: LOCALIZED_UOM_QTY,
  key: LOCALIZED_UOM_QTY,
  name: 'Base UOM Qty Assigned',
  type: INPUT_TYPE.NUMBER,
  width: 190,
  systemField: true,
  editable: true,
  hidden: false,
  uiVisible: true,
  textAlign: 'right'
};

export const setInitialDataForLocalizedUOM = (item: any, module: any) => {
  const docSchema =
    item?.unlocalizedDocumentUOMSchemaDefinition ??
    item?.documentUOMSchemaDefinition ??
    null;

  let pendingQty = 0;
  let baseQtyDiff = item?.localizedBaseQtyDiff ?? 0;
  if (module === DOC_TYPE.GOODS_RECEIPT) {
    // pendingQty =
    //   item.receiptQuantity || Utility.pendingToBeReceivedQuantity(item);

    pendingQty = Utility.getUomWarehouseQuantityWithoutRoundOff(
      item.uomQuantity -
        Utility.getUomQuantityWithoutRoundOff(
          Utility.receivedQuantity(item),
          docSchema
        ),
      item?.documentUOMSchemaDefinition
    );
  } else if (module === DOC_TYPE.FULFILLMENT) {
    pendingQty = item?.pendingQuantity ?? 0;
    if (item?.isLocalizedUomQty) {
      let baseQty =
        (pendingQty / item.documentUOMSchemaDefinition.sourceConversionFactor) *
        docSchema.sourceConversionFactor;

      baseQtyDiff = Utility.roundOffToTenantDecimalScale(baseQty - pendingQty);
    }
  }

  pendingQty = Utility.roundOffToTenantDecimalScale(pendingQty);

  const ORIGINAL_QTY = Utility.roundOff(
    (docSchema?.sourceConversionFactor / docSchema?.sinkConversionFactor) *
      Utility.getUomQuantityWithoutRoundOff(pendingQty, docSchema)
  );

  return {
    ...item,
    unlocalizedDocumentUOMSchemaDefinition: docSchema,
    isLocalizedUomQty: item?.isLocalizedUomQty ?? false,
    localizedBaseQtyDiff: baseQtyDiff,
    originalLocalizedUOMQty: ORIGINAL_QTY,
    receiptQuantityForSeparateColumnDisplay: pendingQty,
    parentDocumentUOMSchemaDefinition: item?.documentUOMSchemaDefinition
  };
};

export const calculateDocumentUomSchema = (
  docSchema: any,
  userQty: any,
  baseQty: any
) => {
  return {
    ...docSchema,
    sourceConversionFactor: Utility.roundingOff(
      (userQty * docSchema?.sinkConversionFactor) / (baseQty || 1)
    )
  };
};

export const checkIsLocalizedUomQty = (
  originalQty: Number,
  userInputQty: Number
) => {
  return (
    +originalQty !== +userInputQty &&
    calculateBaseQtyDiff(originalQty, userInputQty) !== 0
  );
};

export const calculateBaseQtyDiff = (
  originalQty: Number,
  userInputQty: Number
) => {
  const qty1 = Number(originalQty || 0);
  const qty2 = Number(userInputQty || 0);
  return Utility.roundOffToTenantDecimalScale(qty1 - qty2);
};

export const calculateQtyDiffAccordingSchema = (
  qty: any,
  unlocalizedSchema: any,
  documentUOMSchemaDefinition: any
) => {
  if (
    Utility.isNotEmpty(unlocalizedSchema) &&
    Utility.isNotEmpty(documentUOMSchemaDefinition)
  ) {
    let diff =
      (qty / unlocalizedSchema?.sourceConversionFactor) *
      documentUOMSchemaDefinition?.sourceConversionFactor;
    return Utility.roundOffToTenantDecimalScale(Number(diff));
  } else {
    return 0;
  }
};
export const handleReverseSchemaForNoneTracked = (
  warehouseInventoryData: any,
  currentObject: any
) => {
  const sumOfLocalizedQty = warehouseInventoryData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.localizedQuantity),
    0
  );
  const sumOfQuantity = warehouseInventoryData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.quantity),
    0
  );

  const schemaAccordingToWhInvData = calculateDocumentUomSchema(
    currentObject.documentUOMSchemaDefinition,
    sumOfLocalizedQty,
    sumOfQuantity
  );

  const whQty = Utility.getUomWarehouseQuantityWithoutRoundOff(
    sumOfQuantity,
    schemaAccordingToWhInvData
  );
  const originalUOMQty = Utility.getUomWarehouseQuantity(
    sumOfQuantity ?? 0,
    currentObject.unlocalizedDocumentUOMSchemaDefinition
  );
  const isQtyChanged =
    whQty !== sumOfLocalizedQty ||
    // sumOfLocalizedQty !== currentObject.originalLocalizedQty;
    sumOfLocalizedQty !== originalUOMQty;
  const returnValues = {
    // localizedBaseQtyDiff: calculateBaseQtyDiff(
    //   currentObject.originalLocalizedQty,
    //   sumOfLocalizedQty
    // ),
    localizedBaseQtyDiff: calculateBaseQtyDiff(
      originalUOMQty,
      sumOfLocalizedQty
    ),
    isLocalizedUomQty: isQtyChanged,
    receiptQuantityForSeparateColumnDisplay:
      Utility.roundOffToTenantDecimalScale(sumOfLocalizedQty),
    documentUOMSchemaDefinition: calculateDocumentUomSchema(
      schemaAccordingToWhInvData,
      sumOfLocalizedQty,
      sumOfQuantity
    )
  };
  return returnValues;
};

export const handleReverseSchemaForBatchTracked = (
  advTrackingData: any,
  currentObject: any,
  docType?: any
) => {
  const sumOfLocalizedQty = advTrackingData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.localizedBatchSize),
    0
  );
  let sumOfQuantity = advTrackingData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.batchSize),
    0
  );
  if (docType === DOC_TYPE.FULFILLMENT) {
    sumOfQuantity = advTrackingData.reduce(
      (a: any, b: any) =>
        +a +
        +Utility.getUomQuantityWithoutRoundOff(
          b.qtyToFulfil,
          currentObject.unlocalizedDocumentUOMSchemaDefinition
        ),
      0
    );
  }
  const schemaAccordingToWhInvData = calculateDocumentUomSchema(
    currentObject.documentUOMSchemaDefinition,
    sumOfLocalizedQty,
    sumOfQuantity
  );
  const whQty = Utility.getUomWarehouseQuantityWithoutRoundOff(
    sumOfQuantity,
    schemaAccordingToWhInvData
  );
  const originalUOMQty = Utility.getUomWarehouseQuantity(
    sumOfQuantity ?? 0,
    currentObject.unlocalizedDocumentUOMSchemaDefinition
  );
  const isQtyChanged =
    whQty !== sumOfLocalizedQty || sumOfLocalizedQty !== originalUOMQty;
  // sumOfLocalizedQty !== currentObject.originalLocalizedQty;
  const returnValues = {
    localizedBaseQtyDiff: calculateBaseQtyDiff(
      // currentObject.originalLocalizedQty,
      originalUOMQty,
      sumOfLocalizedQty
    ),
    isLocalizedUomQty: isQtyChanged,
    receiptQuantityForSeparateColumnDisplay:
      Utility.roundOffToTenantDecimalScale(sumOfLocalizedQty),
    documentUOMSchemaDefinition: calculateDocumentUomSchema(
      schemaAccordingToWhInvData,
      sumOfLocalizedQty,
      sumOfQuantity
    )
  };
  return returnValues;
};

export const handleReverseSchemaForSerialTracked = (
  advTrackingData: any,
  currentObject: any,
  docType?: any
) => {
  const sumOfLocalizedQty = advTrackingData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.localizedBatchSize),
    0
  );
  let sumOfQuantity = advTrackingData.reduce(
    (a: any, b: any) => +a + +parseFloat(b.batchSize),
    0
  );
  sumOfQuantity = +Utility.getUomQuantityWithoutRoundOff(
    sumOfQuantity,
    currentObject.documentUOMSchemaDefinition
  );
  const schemaAccordingToWhInvData = calculateDocumentUomSchema(
    currentObject.documentUOMSchemaDefinition,
    sumOfLocalizedQty,
    sumOfQuantity
  );
  const whQty = Utility.getUomWarehouseQuantityWithoutRoundOff(
    sumOfQuantity,
    schemaAccordingToWhInvData
  );
  const originalUOMQty = Utility.getUomWarehouseQuantity(
    sumOfQuantity ?? 0,
    currentObject.unlocalizedDocumentUOMSchemaDefinition
  );
  const isQtyChanged =
    whQty !== sumOfLocalizedQty || sumOfLocalizedQty !== originalUOMQty;

  // const isQtyChanged =
  //   whQty !== sumOfLocalizedQty ||
  //   sumOfLocalizedQty !== currentObject.originalLocalizedQty;
  const returnValues = {
    localizedBaseQtyDiff: calculateBaseQtyDiff(
      originalUOMQty,
      sumOfLocalizedQty
    ),
    isLocalizedUomQty: isQtyChanged,
    receiptQuantityForSeparateColumnDisplay:
      Utility.roundOffToTenantDecimalScale(sumOfLocalizedQty),
    documentUOMSchemaDefinition: calculateDocumentUomSchema(
      schemaAccordingToWhInvData,
      sumOfLocalizedQty,
      sumOfQuantity
    )
  };
  return returnValues;
};

export const isBillNeedToChange = (
  updatedSchema: any,
  unlocalizedSchema: any,
  docType: any
) => {
  if (docType === DOC_TYPE.BILL && !isEqual(updatedSchema, unlocalizedSchema)) {
    showAlert(
      'Warning',
      'Since the Bill has already been raised, you will need to update the Bill before modifying the UOM Schema to have accurate accounting impact on Accounts Payable as well'
    );
    return true;
  } else if (
    docType === DOC_TYPE.INVOICE &&
    !isEqual(updatedSchema, unlocalizedSchema)
  ) {
    showAlert(
      'Warning',
      'Since the Invoice has already been raised, you will need to update the invoice before modifying the UOM Schema to have accurate accounting impact on Accounts Payable as well'
    );
    return true;
  } else {
    return false;
  }
};

export const getLineItemsKeyForDocument = (documentType: any) => {
  switch (documentType) {
    case DOC_TYPE.INVOICE:
      return 'salesInvoiceItems';

    case DOC_TYPE.QUOTE:
      return 'quotationItemDtoList';

    case DOC_TYPE.SALES_ORDER:
      return 'salesOrderItems';

    case DOC_TYPE.ORDER:
      return 'purchaseOrderItems';

    case DOC_TYPE.BILL:
      return 'purchaseInvoiceProducts';

    default:
      return '';
  }
};
