import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { GridState } from '../../../Models/ReduxStore';
import ForecastService from '../../../Services/MRP/Forecast';
import ProductionPlanService from '../../../Services/MRP/ProductionPlan';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchMasterForecast = createAsyncThunk(
  'MasterForecast',
  async () => {
    const response = await ForecastService.fetchForecastByPage();
    return response;
  }
);

export const MRPMasterForecastSlice = createSlice({
  name: 'mrpMasterForecast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterForecast.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchMasterForecast.rejected, (state) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
      })
      .addCase(fetchMasterForecast.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectMasterForecasts = (state: RootState) =>
  state.mrpMasterForecast.data;

export const selectMasterForecastsLoadingStatus = (state: RootState) =>
  state.mrpMasterForecast.gridContentLoadingStatus;

export default MRPMasterForecastSlice.reducer;
