import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  removeLoader,
  showLoader,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import Utility, { deepClone } from '../../../Utility/Utility';
import { useAppSelector } from '../../../Redux/Hooks';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { useDispatch } from 'react-redux';
import { NewTDSProps, TCSRateInitialState } from '../../../Models/TDS';
import {
  fetchTCSRates,
  selectTCSNature
} from '../../../Redux/Slices/TCSSetupSlice';
import TCSService from '../../../Services/TCSSetup';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';

const AddTCSRate: React.FC<NewTDSProps> = (props) => {
  const dispatch = useDispatch();
  const accountList = useAppSelector(selectedAccounts);
  const [accounts, setAccounts] = useState<any[]>([]);
  const tcsNatureOfCollectionData = useAppSelector(selectTCSNature);
  const tcsNatureOfCollectionDataList = tcsNatureOfCollectionData || [];
  const tcsRateState: any = props.formData
    ? props.formData
    : TCSRateInitialState;
  const [tcsRate, setTcsRate] = useState(tcsRateState);

  const isEdit = props.isEdit ? true : false;
  const [saveButtonTapped, setSaveButtonTapped] = useState(isEdit);
  const [validated, setValidated] = useState<boolean>(true);
  const tenantInfo = useAppSelector(activeTenantInfo);

  useEffect(() => {
    let activeAccountList = accountList?.content?.filter(
      (account: any) => account.status === STATUS_TYPE.ACTIVE
    );
    let result = activeAccountList?.map((account: any) => {
      return {
        label: account.name,
        value: account.accountCode,
        code: account.code
      };
    });
    setAccounts(result);
  }, [accountList]);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {};

  function updateTCSRateData(key: string, value: any) {
    setTcsRate((rate: any) => ({ ...rate, [key]: value }));
  }

  const onInputChange = (event: any, type: string) => {
    switch (type) {
      case 'name':
        updateTCSRateData('taxName', event);

        break;
      case 'rate':
        if (Number(event) < 0) {
          setValidated(false);
        } else if (isNaN(Number(event))) {
          setValidated(false);
        } else {
          updateTCSRateData('ratePercentage', event);
        }

        break;
    }
  };

  const getSelectedNatureCollection = (id: any) => {
    if (
      tcsNatureOfCollectionDataList &&
      tcsNatureOfCollectionDataList?.length > 0 &&
      id
    ) {
      let filtered = tcsNatureOfCollectionDataList.filter((item: any) => {
        return item.id === id;
      });
      if (filtered.length > 0) {
        let obj: any = filtered[0];
        return tcsNatureOfCollectionDataList.indexOf(obj);
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  };
  const getSelectedPayableAccount = (code: string) => {
    if (accounts && accounts?.length > 0 && code) {
      let filtered = accounts.filter((item: any) => {
        return item.value === code;
      });
      if (filtered.length > 0) {
        let obj: any = filtered[0];
        return accounts.indexOf(obj);
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  };

  const closeCreateView = (data: any = {}) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: data
      });
    }
  };

  const updateTCSRates = () => {
    setSaveButtonTapped(true);

    if (validateForm()) {
      const payload = {
        ...tcsRate,
        accountReceivable:
          accounts.find(
            (account: any) => account.value === tcsRate.accountReceivable
          ).code || '',
        accountPayable:
          accounts.find(
            (account: any) => account.value === tcsRate.accountPayable
          ).code || ''
      };

      showLoader();
      TCSService.updateTCSRate(tenantInfo?.id, payload)
        .then((res) => {
          showToast(
            'TCS Rate has been updated successfully',
            TOAST_TYPE.SUCCESS
          );
          closeCreateView(res);
          dispatch(fetchTCSRates(tenantInfo?.id));
          removeLoader();
        })
        .catch((error: any) => {
          removeLoader();
        });
    }
  };

  const createTCSRates = () => {
    setSaveButtonTapped(true);

    if (validateForm()) {
      const payload = {
        ...tcsRate,
        accountReceivable:
          accounts.find(
            (account: any) => account.value === tcsRate.accountReceivable
          ).code || '',
        accountPayable:
          accounts.find(
            (account: any) => account.value === tcsRate.accountPayable
          ).code || ''
      };

      showLoader();
      TCSService.createTCSRate(tenantInfo?.id, payload)
        .then((res) => {
          showToast('TCS Rate Created Successfully', TOAST_TYPE.SUCCESS);
          closeCreateView(res);
          dispatch(fetchTCSRates(tenantInfo?.id));
          removeLoader();
        })
        .catch((error: any) => {
          removeLoader();
        });
    }
  };

  const validateForm = () => {
    if (
      !tcsRate.tcsCollectionNatureId ||
      isNaN(Number(tcsRate.tcsCollectionNatureId))
    ) {
      showToast('Please select Nature of Collection.', TOAST_TYPE.FAILURE);
      return false;
    } else if (
      isNaN(Number(tcsRate.ratePercentage)) ||
      +tcsRate.ratePercentage < 0 ||
      +tcsRate.ratePercentage > 100
    ) {
      showToast('Please enter valid TCS rate.', TOAST_TYPE.FAILURE);
      return false;
    } else if (Utility.isEmpty(tcsRate.taxName)) {
      showToast('Please enter Tax name.', TOAST_TYPE.FAILURE);
      return false;
    } else if (Utility.isEmpty(tcsRate.accountPayable)) {
      showToast('Please select Payable account.', TOAST_TYPE.FAILURE);
      return false;
    } else if (Utility.isEmpty(tcsRate.accountReceivable)) {
      showToast('Please select Receivable account.', TOAST_TYPE.FAILURE);
      return false;
    }
    return true;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={props.isEdit ? 'Edit TCS Rate' : 'Add TCS Rate'}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={props.isEdit ? 'Update' : 'Save'}
            className="bg-button text-white"
            onClick={() => (props.isEdit ? updateTCSRates() : createTCSRates())}
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-2">
        <div className="row row-responsive parent-width mb-2 mt-1">
          <div className="parent-width mobile-mt-s web-mr-l">
            <div style={{ height: 80 }}>
              <DKInput
                title={'Nature of Collection'}
                required={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                canValidate={saveButtonTapped}
                placeholder={'Select Nature Collection'}
                value={
                  getSelectedNatureCollection(tcsRate.tcsCollectionNatureId) >=
                  0
                    ? [
                        getSelectedNatureCollection(
                          tcsRate.tcsCollectionNatureId
                        )
                      ]
                    : []
                }
                options={
                  tcsNatureOfCollectionDataList
                    ? tcsNatureOfCollectionDataList.map(
                        (item: any) => item.name
                      )
                    : []
                }
                onChange={(value: any) => {
                  !Utility.isEmpty(value)
                    ? updateTCSRateData(
                        'tcsCollectionNatureId',
                        tcsNatureOfCollectionDataList[value].id
                      )
                    : updateTCSRateData('tcsCollectionNatureId', null);
                }}
                type={INPUT_TYPE.SELECT}
              />
            </div>
          </div>
          <div className="parent-width">
            <div className="parent-width" style={{ height: 80 }}>
              <DKInput
                title={'TCS Rate'}
                required={true}
                canValidate={saveButtonTapped}
                placeholder={'TCS Rate %'}
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={tcsRate.ratePercentage}
                onChange={(text: any) => {
                  onInputChange(text, 'rate');
                }}
              />
            </div>
          </div>
        </div>
        <div className="row row-responsive parent-width align-items-centre mb-2 mt-1">
          <div className="parent-width web-mr-l">
            <div className="parent-width" style={{ height: 80 }}>
              <DKInput
                title={'Tax Name'}
                required={true}
                canValidate={saveButtonTapped}
                placeholder={'Tax Name'}
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={tcsRate.taxName}
                onChange={(text: any) => {
                  onInputChange(text, 'name');
                }}
              />
            </div>
          </div>
          <div className="parent-width">
            <div className="parent-width" style={{ height: 80 }}>
              <DKInput
                title={'TCS Payable Account'}
                required={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                canValidate={saveButtonTapped}
                placeholder={'Select TCS Payable Account'}
                value={
                  getSelectedPayableAccount(tcsRate.accountPayable) >= 0
                    ? [getSelectedPayableAccount(tcsRate.accountPayable)]
                    : []
                }
                options={
                  accounts ? accounts.map((item: any) => item.label) : []
                }
                onChange={(value: any) => {
                  !Utility.isEmpty(value)
                    ? updateTCSRateData('accountPayable', accounts[value].value)
                    : updateTCSRateData('accountPayable', null);
                }}
                type={INPUT_TYPE.SELECT}
              />
            </div>
          </div>
        </div>

        <div className="row row-responsive parent-width align-items-centre mb-2 mt-1">
          <div className="parent-width web-mr-l">
            <div className="parent-width" style={{ height: 80 }}>
              <DKInput
                title={'TCS Receivable Account'}
                required={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                canValidate={saveButtonTapped}
                placeholder={'Select TCS Receivable Account'}
                value={
                  getSelectedPayableAccount(tcsRate.accountReceivable) >= 0
                    ? [getSelectedPayableAccount(tcsRate.accountReceivable)]
                    : []
                }
                options={
                  accounts ? accounts.map((item: any) => item.label) : []
                }
                onChange={(value: any) => {
                  !Utility.isEmpty(value)
                    ? updateTCSRateData(
                        'accountReceivable',
                        accounts[value].value
                      )
                    : updateTCSRateData('accountReceivable', null);
                }}
                type={INPUT_TYPE.SELECT}
              />
            </div>
          </div>
          <div className="parent-width">
            <div className="parent-width web-mr-l" style={{ height: 80 }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 570,
          width: '90%',
          maxHeight: '85%',
          height: 500,
          padding: 0,
          paddingBottom: 60,
          overflow: 'visible',
        }}
      >
        {getHeader()}
        <div className="column p-m parent-width">{getBody()}</div>
      </div>
    </div>
  );
};

export default AddTCSRate;
