import ApiConstants from '../Constants/ApiConstants';
import { COUNTRY_SPECIFIC_URL_CODE, DOC_TYPE } from '../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import {
  NoteItemPayload,
  PaymentPayload,
  PAYMENT_SECTION
} from '../Models/PaymentPopup';
import { BulkPaymentPayload } from '../SharedComponents/BulkPayment/BulkPaymentConfig';
import { OffsetPayload } from '../SharedComponents/OffsetPopup/OffsetPopup';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

class PaymentService {
  static getAdditionalPaymentInfo(
    contactCode: string,
    currencyCode: string,
    paymentSection: PAYMENT_SECTION
  ) {
    let endpoint: string = '';
    switch (paymentSection) {
      case PAYMENT_SECTION.ADVANCED_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.FETCH_ADVANCED_PAYMENT_INFO();
        break;
      case PAYMENT_SECTION.PRE_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.FETCH_PREPAYMENT_INFO;
        break;
      case PAYMENT_SECTION.CREDIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.FETCH_CREDIT_INFO;
        break;
      case PAYMENT_SECTION.DEBIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.FETCH_DEBIT_INFO;
        break;
      default:
        break;
    }
    const queryString: string = `?query=contactCode=${contactCode},amountDue>0,currency=${currencyCode}&limit=100`;
    const finalEndpoint: string = endpoint + queryString;
    if (endpoint) {
      return http.get(`${finalEndpoint}`).catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
    }
    return Promise.reject('Invalid endpoint');
  }

  static makeAdditionalPayment(
    payload: NoteItemPayload[],
    paymentSection: PAYMENT_SECTION
  ) {
    let endpoint: string = '';
    switch (paymentSection) {
      case PAYMENT_SECTION.ADVANCED_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.PAY_ADVANCED_PAYMENT;
        break;
      case PAYMENT_SECTION.PRE_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.PAY_PRE_PAYMENT;
        break;
      case PAYMENT_SECTION.CREDIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.PAY_CREDIT_NOTE;
        break;
      case PAYMENT_SECTION.DEBIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.PAY_DEBIT_NOTE;
        break;
      default:
        break;
    }
    return http.put(`${endpoint}`, payload).catch((err: any) => {
      console.error('Error making payment: ', err);
      return Promise.reject(err);
    });
  }

  static makePayment(payload: PaymentPayload) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT
    );
    return http
      .post(`${ApiConstants.URL.PAYMENT.MAKE_PAYMENT}${country}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static createOpeningPP(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.MAKE_PAYMENT}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }
  static updateOpeningPP(payload: any, id: any) {
    return http
      .put(`${ApiConstants.URL.PAYMENT.MAKE_PAYMENT}/${id}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static updateOpeningAP(payload: any, id: any) {
    return http
      .put(`${ApiConstants.URL.PAYMENT.RECEIVE_PAYMENT}/${id}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getPPDetails(id: any) {
    return http
      .get(`${ApiConstants.URL.PAYMENT.PP_DETAILS(id)}`)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }
  static getAPDetails(id: any) {
    return http
      .get(`${ApiConstants.URL.PAYMENT.AP_DETAILS(id)}`)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getCNDetails(id: any) {
    return http
      .get(`${ApiConstants.URL.PAYMENT.CN_DETAILS(id)}`)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }
  static getDNDetails(id: any) {
    return http
      .get(`${ApiConstants.URL.PAYMENT.DN_DETAILS(id)}`)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static createOpeningCN(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.CREATE_OCN}`, payload)
      .catch((err: any) => {
        console.error('Error creating Opening Debit Note: ', err);
        return Promise.reject(err);
      });
  }

  static updateOpeningCN(payload: any, id: any) {
    return http
      .put(`${ApiConstants.URL.PAYMENT.CREATE_OCN}/${id}`, payload)
      .catch((err: any) => {
        console.error('Error creating Opening Debit Note: ', err);
        return Promise.reject(err);
      });
  }
  static updateOpeningDN(payload: any, id: any) {
    return http
      .put(`${ApiConstants.URL.PAYMENT.CREATE_ODN}/${id}`, payload)
      .catch((err: any) => {
        console.error('Error creating Opening Debit Note: ', err);
        return Promise.reject(err);
      });
  }

  static createOpeningDN(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.CREATE_ODN}`, payload)
      .catch((err: any) => {
        console.error('Error creating Opening Credit Note: ', err);
        return Promise.reject(err);
      });
  }

  static createOpeningAP(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.RECEIVE_PAYMENT}`, payload)
      .catch((err: any) => {
        console.error('Error receiving payment payment: ', err);
        return Promise.reject(err);
      });
  }

  static receivePayment(payload: PaymentPayload) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT
    );
    return http
      .post(`${ApiConstants.URL.PAYMENT.RECEIVE_PAYMENT}${country}`, payload)
      .catch((err: any) => {
        console.error('Error receiving payment: ', err);
        return Promise.reject(err);
      });
  }
  static getEndpointForPaymentRecords = (docType: any) => {
    let endpoint: string = '';
    const moduleName =
      docType === DOC_TYPE.BILL
        ? COMPLIANCE_SPECIFIC_FIELD_NAME.MAKE_PAYMENT
        : COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT;
    let country = Utility.getTenantSpecificApiCode(moduleName);

    if (docType === DOC_TYPE.BILL) {
      endpoint = ApiConstants.URL.PAYMENT.MAKE_PAYMENT;
      if (country === COUNTRY_SPECIFIC_URL_CODE.IL) {
        endpoint += country;
      }
    } else {
      // only add country code for israel compliance
      if (country !== COUNTRY_SPECIFIC_URL_CODE.IL) {
        country = '';
      }
      endpoint = ApiConstants.URL.PAYMENT.FETCH_ADVANCED_PAYMENT_INFO(country);
    }
    return endpoint;
  };
  static fetchPaymentRecordDetails(
    paymentCode: string,
    documentType: DOC_TYPE
  ) {
    const queryString = `?limit=10&page=0&search=${paymentCode}&sort=createdAt&sortDir=desc`;
    const finalEndpoint: string = this.getEndpointForPaymentRecords(documentType) + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error fetching payment record details: ', err);
      return Promise.reject(err);
    });
  }

  static deletePaymentRecord(
    paymentCode: string,
    documentCode: string,
    paymentSection: DOC_TYPE,
    uid: string
  ) {
    let endpoint: string = '';
    switch (paymentSection) {
      case DOC_TYPE.RECEIVE_PAYMENT:
        const moduleName = COMPLIANCE_SPECIFIC_FIELD_NAME.RECEIVE_PAYMENT;
        let countryCode = Utility.getTenantSpecificApiCode(moduleName);
        endpoint = ApiConstants.URL.PAYMENT.NEW_DELETE_INVOICE_PAYMENT_RECORD(
          paymentCode, 
          documentCode, 
          countryCode 
        );
        break;
      case DOC_TYPE.MAKE_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.NEW_DELETE_BILL_PAYMENT_RECORD(
          paymentCode,
          documentCode
        );
        break;
      case DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.NEW_DELETE_ADVANCED_PAYMENT(
          paymentCode,
          documentCode,
          uid
        );
        break;
      case DOC_TYPE.PREPAYMENT:
        endpoint = ApiConstants.URL.PAYMENT.NEW_DELETE_PRE_PAYMENT(
          paymentCode,
          documentCode,
          uid
        );
        break;
      case DOC_TYPE.CREDIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.DELETE_CREDIT_NOTE(
          paymentCode,
          documentCode,
          uid
        );
        break;
      case DOC_TYPE.DEBIT_NOTE:
        endpoint = ApiConstants.URL.PAYMENT.DELETE_DEBIT_NOTE(
          paymentCode,
          documentCode,
          uid
        );
        break;
      case DOC_TYPE.BILL:
        endpoint = ApiConstants.URL.PAYMENT.DELETE_OFFSET_INVOICE(
          paymentCode,
          documentCode
        );
        break;
      case DOC_TYPE.INVOICE:
        endpoint = ApiConstants.URL.PAYMENT.DELETE_OFFSET_BILL(
          paymentCode,
          documentCode
        );
        break;
      default:
        break;
    }
    return http2.delete(`${endpoint}`).catch((err: any) => {
      console.error('Error deleting payment record: ', err);
      return Promise.reject(err);
    });
  }

  static offsetPayment(payload: OffsetPayload, docType: DOC_TYPE) {
    let endpoint: string;
    if (docType === DOC_TYPE.BILL) {
      endpoint = ApiConstants.URL.PAYMENT.OFFSET_WITH_INVOICES;
    } else {
      endpoint = ApiConstants.URL.PAYMENT.OFFSET_WITH_BILLS;
    }
    return http.post(`${endpoint}`, payload).catch((err: any) => {
      console.error('Error making offset payment record: ', err);
      return Promise.reject(err);
    });
  }
  static getEndPointForBulkPayment = (
    moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME
  ) => {
    const countrySpecificCode = Utility.getTenantSpecificApiCode(moduleName);
    let endPoint = '';
    if (moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.BULK_MAKE_PAYMENT) {
      if (countrySpecificCode === COUNTRY_SPECIFIC_URL_CODE.IL) {
        endPoint = ApiConstants.URL.PAYMENT.IL_BULK_MAKE_PAYMENT;
      } else {
        endPoint = ApiConstants.URL.PAYMENT.BULK_MAKE_PAYMENT;
      }
    } else {
      if (countrySpecificCode === COUNTRY_SPECIFIC_URL_CODE.IL) {
        endPoint = ApiConstants.URL.PAYMENT.IL_BULK_RECEIVE_PAYMENT;
      } else {
        endPoint = ApiConstants.URL.PAYMENT.BULK_RECEIVE_PAYMENT;
      }
    }
    return endPoint;
  };
  static bulkReceivedPayment(payload: BulkPaymentPayload) {
    const endPoint = this.getEndPointForBulkPayment(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BULK_RECEIVE_PAYMENT
    );
    return http.post(endPoint, payload).catch((err: any) => {
      console.error('Error creating bulk received payment: ', err);
      return Promise.reject(err);
    });
  }

  static bulkMakePayment(payload: BulkPaymentPayload) {
    const endPoint = this.getEndPointForBulkPayment(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BULK_MAKE_PAYMENT
    );
    return http.post(endPoint, payload).catch((err: any) => {
      console.error('Error creating bulk make payment: ', err);
      return Promise.reject(err);
    });
  }

  static emailPayment(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.EMAIL_PAYMENT}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static emailPaymentRecord(payload: any) {
    return http
      .post(`${ApiConstants.URL.PAYMENT.EMAIL_PAYMENT_RECORD}`, payload)
      .catch((err: any) => {
        console.error('Error sending email: ', err);
        return Promise.reject(err);
      });
  }

  static getFeeAccounts(searchvalue?: string) {
    let url: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
      `?query=status=ACTIVE,accountGroup.name=Expenses`;

    if (!Utility.isEmpty(searchvalue)) {
      url =
        ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
        `?search=${searchvalue}&query=status=ACTIVE,accountGroup.name=Expenses`;
    }

    return http.get(url).catch((err: any) => {
      console.error('Error loading Fee Account: ', err);
      return Promise.reject(err);
    });
  }

  static getFeeAccountData(code: any) {
    let url: string =
      ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS + `?query=code=${code}`;

    return http.get(url).catch((err: any) => {
      console.error('Error loading Fee Account: ', err);
      return Promise.reject(err);
    });
  }
}
export default PaymentService;
