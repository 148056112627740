import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchOrders = createAsyncThunk('Purchase Orders', async () => {
  const response = await PurchaseOrderService.getOrdersByPage();
  return response;
});

export const PurchaseOrdersSlice = createSlice({
  name: 'Purchase Orders',
  initialState,
  reducers: {
    addOrderColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.PURCHASE_ORDER
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToOrderColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updatePurchaseOrderDetails: (state, action: PayloadAction<any>) => {
      const orderDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const orderIndex: number = content.findIndex(
        (contact: any) => contact.id === orderDetails.id
      );
      if (orderIndex >= 0) {
        content[orderIndex] = orderDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToOrderColumnConfig,
  addOrderColumnConfig,
  updateColumnConfig,
  updatePurchaseOrderDetails,
  updateRefreshCallback
} = PurchaseOrdersSlice.actions;

export const selectPurchaseOrders = (state: RootState) =>
  state.purchaseOrders.data;

export const selectPurchaseOrdersLoadingStatus = (state: RootState) =>
  state.purchaseOrders.gridContentLoadingStatus;

export const selectPurchaseOrdersColumnConfig = (state: RootState) =>
  state.purchaseOrders.columnConfig;

export const selectPurchaseOrdersColumnConfigTableId = (state: RootState) =>
  state.purchaseOrders.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.purchaseOrders.refreshCallback;

export default PurchaseOrdersSlice.reducer;
