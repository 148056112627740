import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {},
  reminderSetting: null,
  reminderConfiguration: null,
  formData: {
    uoms: [],
    taxes: []
  },
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  summary: {},
  paymentOptions: null
};

export const ReportsSalesOrderSlice = createSlice({
  name: 'reportsSalesOrder',
  initialState,
  reducers: {
    setReportSODetailsData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setReportSODetailsReminderSetting: (state, action: PayloadAction<any>) => {
      state.reminderSetting = action.payload;
    },
    setReportSODetailsReminderConfiguration: (
      state,
      action: PayloadAction<any>
    ) => {
      state.reminderConfiguration = action.payload;
    },
    setReportSODetailsFormData: (state, action: PayloadAction<any>) => {
      state.formData = action.payload;
    },

    addReportSODetailsColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BOOKS_REPORTS_SALES_ORDER_DETAILS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    setReportSODetailsConfigTableId: (state, action: PayloadAction<string>) => {
      state.configTableId = action.payload;
    },
    setReportSODetailsStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.status = action.payload;
    },
    setReportSODetailsSummary: (state, action: PayloadAction<any>) => {
      state.summary = action.payload;
    },
    setReportSODetailsPaymentOptions: (state, action: PayloadAction<any>) => {
      state.paymentOptions = action.payload;
    }
  }
});

export const {
  setReportSODetailsData,
  setReportSODetailsReminderSetting,
  setReportSODetailsReminderConfiguration,
  setReportSODetailsFormData,
  addReportSODetailsColumnConfig,
  setReportSODetailsConfigTableId,
  setReportSODetailsStatus,
  setReportSODetailsSummary,
  setReportSODetailsPaymentOptions,
  updateColumnConfig
} = ReportsSalesOrderSlice.actions;

export const selectReportSODetailsData = (state: RootState) =>
  state.reportsSalesOrder.data;
export const selectReportSODetailsReminderSetting = (state: RootState) =>
  state.reportsSalesOrder.reminderSetting;
export const selectReportSODetailColumnConfig = (state: RootState) =>
  state.reportsSalesOrder.columnConfig;
export const selectReportSODetailConfigTableId = (state: RootState) =>
  state.reportsSalesOrder.configTableId;
export default ReportsSalesOrderSlice.reducer;
