import React from 'react';
import { DKIcon, DKIcons } from 'deskera-ui-library';
export default function Popup(props: PopupProps) {
  const onClose = () => {
    if (props.onClose) props.onClose();
  };
  return (
    <div className="transparent-background">
      <div
        id="dk-form-popup-window"
        className="popup-window"
        style={{ padding: 24 }}
      >
        <div
          className={`popup-wrapper parent-width parent-height ${props.className}`}
          style={props.style}
        >
          {props.title && props.allowClose && (
            <div className="popup-header row parent-width justify-content-between">
              {props.title && (
                <div className="column fw-h fs-xl">{props.title}</div>
              )}
              {props.allowClose && (
                <div className="column">
                  <DKIcon
                    src={DKIcons.ic_close}
                    className="ic-s cursor-hand"
                    onClick={() => onClose()}
                  />
                </div>
              )}
            </div>
          )}
          <div className="popup-body">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
export interface PopupProps {
  title?: string;
  allowClose?: boolean;
  onClose?: any;
  className?: string;
  style?: any;
  children?: any;
}
