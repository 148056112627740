import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const MRPProductSubstituteSlice = createSlice({
  name: 'ProductSubstitute',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addProductSubstituteColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        const column: IColumn = { ...config };
        configsToStore.push(column);
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.MRP_PRODUCT_SUBSTITUTE
      );
    },
    addColumnToProductSubstituteColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  }
});

export const {
  updateColumnConfig,
  addProductSubstituteColumnConfig,
  addColumnToProductSubstituteColumnConfig
} = MRPProductSubstituteSlice.actions;

export const selectProductSubstituteColumnConfig = (state: RootState) =>
  state.mrpProductSubstitute.columnConfig;
export const selectProductSubstituteColumnConfigTableId = (state: RootState) =>
  state.mrpProductSubstitute.configTableId;
