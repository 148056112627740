import { TableColumnPopupType } from "../../Constants/Constants";
import { getSortedHeader } from "../../DKUILibrary/dktable/TableUtility";
import { getLocalisedText } from "../../Translate/LanguageManager";

export default class MachineScheduleSOPItem {
  lineNumber: number;
  name: string;
  status: string;

  constructor(lineItem: any, data: any, currency: string) {
    this.name = lineItem.name;
    this.status = lineItem.status;
    this.lineNumber = lineItem.lineNumber;
  }

  getValueByType(element: any) {
    const type = element.type
    switch (type) {
        case TableColumnPopupType.status:
            return this.status
        case TableColumnPopupType.name:
            return getLocalisedText(this.name)
        default:
            return "";
    }
}

getDefaultList() {
    return [
      this.lineNumber,
      getLocalisedText(this.name),
      this.status,
    ];
}
  toString() {
    var sortedHeader = getSortedHeader()
    if (sortedHeader.length > 0) {
        var sortedValue: string[] = []
        sortedHeader.forEach(element => {
            sortedValue.push(this.getValueByType(element))
        });
        return sortedValue
    }
    return this.getDefaultList()
}
}
