import React, { Component } from "react";
import "../../index.css";
import { EditorInfoType, FONT_SIZE, NEGATIVE_PAGE_PADDING, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getCapitalized, getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsDocBuilderApp } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate15 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="RowDiv" style={{

                backgroundColor: this.state.data.themeColor,
                paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                marginLeft: getIsDocBuilderApp() ? getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true) : 0,
                marginTop: undefined,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: getVW(Utility.convertToPrintScale(200, this.state.data.printScale))
            }}
            >
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-start" }}>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) &&ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'relative' })}
                    {ComponentManager.addVerticalSpace(10)}
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                        }}>
                        {getCapitalized(getLocalisedText(this.state.data.documentType))}
                    </text>
                </div>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", width: '30%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'white',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5)}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#ffffff80',
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                    </div>}
                </div>
            </div>
        );
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS} className="ColumnDiv" style={{ bottom: 0, width: '100%', alignItems: 'center' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: '#000000',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition), headerTitle: true }, this.state.data.printScale)
                    }}>
                        {Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition'))}
                    </text>
                {ComponentManager.addVerticalSpace(5)}
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: 'gray',
                        width: '70%',
                        textAlign: 'center',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition) }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.data.termsAndCondition)}</text>
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addCompanyNameAndDocTypeLabels()}
                    {ComponentManager.addVerticalSpace(30)}
                    <div
                        className="RowDiv"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: "flex-start",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="RowDiv" style={{ width: '70%' }}>
                            {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                                this.state.data.billToTitle,
                                Utility.getClientBillToAddress(this.state.data),
                                "40%",
                                'black',
                                false,
                                '#000000',
                                getVW(6),
                                Utility.getOwnerType(this.state.data, AddressType.billTo),
                                this.state.data.printScale
                            )}
                            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addHorizontalSpace(20)}
                            {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                                this.state.data.shipToTitle,
                                Utility.getClientShipToAddress(this.state.data),
                                "40%",
                                'black',
                                false,
                                '#000000',
                                getVW(6),
                                Utility.getOwnerType(this.state.data, AddressType.shipTo),
                                this.state.data.printScale
                            )}
                            {/*<div style={{ width: "35%" }} />*/}
                        </div>
                        <div
                            className="ColumnDiv"
                            style={{ width: "30%", backgroundColor: "white" }}
                        >
                            {this.getDocumentDetailsSection()}
                        </div>
                    </div>
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(10)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(10)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(10)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    <div style={{ width: '100%', height: getVW(1), backgroundColor: 'lightGray' }} />
                    {ComponentManager.addVerticalSpace(20)}
                    <div style={{ width: '100%', position: 'relative' }}>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                        {this.getEditableDocumentTable()}
                        {Utility.getIsContainsTotalSection(this.state.data) && this.getTotalRow()}
                        {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                        {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                        {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(10)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                        {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    </div>
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && this.addTermsNConditionsView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getTotalRow() {
        return <div className="RowDiv" style={{ marginTop: getVW(15), width: '100%', backgroundColor: this.state.data.themeColor }}>
            <div
                id={HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.NOTES}
                style={{
                    width: '60%',
                    height: "100%",
                    padding: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(20, this.state.data.printScale)) : 0,
                    paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                    boxSizing: 'border-box',
                    backgroundColor: "#ffffffCC"
                }}>
                {(this.state.data.showNotes && this.state.data.notes) ?
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        "#000000",
                        false,
                        "#000000B3",
                        undefined,
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    ) : ComponentManager.getTermsAndConditionOrNotesSection(
                        " ",
                        ' ',
                        "100%",
                        "#000000",
                        false,
                        "#000000B3",
                        undefined,
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
            <div className="ColumnDiv"
                style={{
                    width: '40%',
                    height: "100%",
                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                    paddingRight: Utility.getIsArabicLang() ? getVW(20) : 0,
                    justifyContent: 'center'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: '#ffffff',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                        {getLocalisedText('total')}
                    </text>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: '#ffffff',
                        direction: Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}>
                    {Utility.getTotalValue(this.state.data)}
                </text>
            </div>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBorderColor = 'transparent';
        style.headerBackgroundColor = "transparent";
        style.headerTextColor = "#000000";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        style.footerTextColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['28%', '30.5%', '41.5%'] : ['30.5%', '39.5%', '30%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

     getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '43%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: "lightGray",
            headerBorderBottomWidth: getVW(1),
            footerTextColor: "black"
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    <div style={{ height: getVW(1), backgroundColor: 'lightGray' }} />
                    {ComponentManager.getExchangeRateTable(style, data)}                        
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: "lightGray",
            headerBorderBottomWidth: getVW(1),
            footerTextColor: "black"
        };

        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    <div style={{ height: getVW(1), backgroundColor: 'lightGray' }} />
                    {ComponentManager.getTransactionDocumentTable(style, data)}
                </div>
            </div>
        );
    }
}
