import { getLocalisedText } from "../../Translate/LanguageManager"
import { ColumnOption } from "./ColumnOption"
import { ColumnStyle } from "./ColumnStyle"
import { ItemColumn } from "./ItemColumn"

export class HeaderColumn {
    columnOption: ColumnOption
    name: string
    label: string
    type?: string
    code?: string
    style: ColumnStyle
    isShowRecord: boolean
    index?: number
    constructor(){
        this.columnOption = new ColumnOption()
        this.name = ''
        this.label = ''
        this.style = new ColumnStyle()
        this.isShowRecord = true
    }

    toItemColumn(): ItemColumn {
        var itemColumn = new ItemColumn()
        itemColumn.columnOption = this.columnOption
        itemColumn.name = this.name
        itemColumn.value = getLocalisedText(this.label)
        itemColumn.style = this.style
        itemColumn.isHeaderColumn = true
        return itemColumn
    }
}
