import {
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION_BACKEND,
  TRACKING_TYPE
} from '../Constants/Constant';
import Utility from '../Utility/Utility';

export class FulfillmentItemPayload {
  id: number;
  documentItemCode: string;
  documentSequenceCode: string;
  productCode: string;
  productName: string;
  productType: PRODUCT_TYPE;
  productDescription: string;
  pendingQuantity: number;
  quantityRequired: number;
  fulfilledQuantity: number;
  fulfillmentDate: string;
  availableProductQuantity: number;
  warehouseCode: any;
  linkedPIItemCode: string;
  isPartialInvoice: boolean;
  isPartialSalesOrder: boolean;
  quantityToBeFulfilled: number = 0;
  productQuantity: number;
  quantityFulfilled: number;
  advancedTracking: string = 'NONE';
  isQuickCommit: boolean;
  unitPrice: number;
  discount: number;
  discountInPercent: boolean;
  advancedTrackingFulfilmentData: Array<any> = [];
  documentUom: number;
  documentUOMSchemaDefinition: any;
  uomPendingQuantity: number;
  uomQuantityRequired: number;
  uomFulfilledQuantity: number;
  uomAvailableProductQuantity: number;
  uomQuantityToBeFulfilled: number = 0;
  uomQuantity: number;
  uomQuantityFulfilled: number;
  reservedQuantitiesData: any;
  warehouseInventoryData: Array<any> = [];
  errorMessage?: string;
  fulfillmentType?: string;
  customField?: Array<any> = [];
  unlocalizedDocumentUOMSchemaDefinition?: any;
  isLocalizedUomQty?: boolean;
  localizedBaseQtyDiff?: any;
  parentDocumentUOMSchemaDefinition?: any;

  constructor(props: any) {
    this.id = props.id;
    this.documentSequenceCode =
      props.product.documentSequenceCode || props.documentSequenceCode;
    this.documentItemCode =
      props.documentItemCode ||
      props.salesInvoiceItemCode ||
      props.salesOrderItemCode ||
      props.quotationItemCode;
    this.productCode = props.productCode;
    this.productName = props.productName || props.product.name;
    this.productDescription = props.productDescription;
    this.productType = Utility.getValue(props.productType, props.type);
    this.productQuantity = props.productQuantity;
    this.fulfillmentDate = props.fulfillmentDate;
    this.pendingQuantity = this.getPendingQuantity(props.pendingQuantity);
    this.quantityRequired = props.quantityRequired || this.pendingQuantity || 0;
    this.fulfilledQuantity = Number(props.fulfilledQuantity) || 0;
    this.quantityToBeFulfilled = Number(props?.quantityToBeFulfilled) || 0;
    this.uomQuantityToBeFulfilled =
      Number(props?.uomQuantityToBeFulfilled) || 0;
    this.linkedPIItemCode = props.linkedPIItemCode;
    this.isPartialInvoice = props.isPartialInvoice;
    this.isPartialSalesOrder = props.isPartialSalesOrder;
    this.quantityFulfilled = props.quantityFulfilled;
    this.isQuickCommit = props.isQuickCommit || false;
    this.advancedTracking = props.advancedTracking;
    this.advancedTrackingFulfilmentData = props.advancedTrackingFulfilmentData;
    this.errorMessage = props.errorMessage || '';
    this.unitPrice = props.unitPrice || 0;
    this.discount = props.discount || 0;
    this.discountInPercent = props.discountInPercent;
    if (this.productType !== PRODUCT_TYPE.NON_TRACKED) {
      this.availableProductQuantity =
        props.availableProductQuantity || props.availableQuantity || 0;
      this.uomAvailableProductQuantity =
        props.uomAvailableProductQuantity ||
        this.getUomQuantity(
          props.availableQuantity,
          props.documentUOMSchemaDefinition
        ) ||
        0;
    } else {
      this.availableProductQuantity = 0;
      this.uomAvailableProductQuantity = 0;
    }

    if (
      props.warehouseInventoryData &&
      props.warehouseInventoryData.length > 0
    ) {
      this.warehouseInventoryData = props.warehouseInventoryData.map(
        (item: any) => {
          return {
            ...item,
            localizedQuantity: props?.isLocalizedUomQty
              ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                  parseFloat(props.uomFulfilledQuantity),
                  props.documentUOMSchemaDefinition
                )
              : 0,
            quantity: props.documentUOMSchemaDefinition
              ? Utility.roundingOff(
                  Utility.getUomWarehouseQuantityWithoutRoundOff(
                    parseFloat(item.quantity),
                    props.documentUOMSchemaDefinition
                  ),
                  QTY_ROUNDOFF_PRECISION_BACKEND
                )
              : item.quantity
          };
        }
      );
    } else {
      this.warehouseCode = props.warehouseCode;
      if (props.fulfillmentType !== 'DROP_SHIP') {
        this.warehouseInventoryData = [
          {
            localizedQuantity: props?.isLocalizedUomQty
              ? Utility.roundingOff(
                  Utility.getUomWarehouseQuantityWithoutRoundOff(
                    parseFloat(props.uomFulfilledQuantity),
                    props.documentUOMSchemaDefinition
                  ),
                  QTY_ROUNDOFF_PRECISION_BACKEND
                )
              : 0,
            quantity: props.documentUOMSchemaDefinition
              ? Utility.roundingOff(
                  Utility.getUomWarehouseQuantityWithoutRoundOff(
                    parseFloat(props.uomFulfilledQuantity),
                    props.documentUOMSchemaDefinition
                  ),
                  QTY_ROUNDOFF_PRECISION_BACKEND
                )
              : this.fulfilledQuantity,
            warehouseCode: props.warehouseCode
          }
        ];
      }
    }

    if (
      props.advancedTracking === TRACKING_TYPE.SERIAL ||
      props.advancedTracking === TRACKING_TYPE.BATCH
    ) {
      this.warehouseCode = null;
      this.warehouseInventoryData = [];
    }

    this.documentUom = props.documentUom;
    this.documentUOMSchemaDefinition = props.documentUOMSchemaDefinition;
    this.uomPendingQuantity =
      props.uomPendingQuantity ||
      props.uomQuantity ||
      this.getUomQuantity(
        this.pendingQuantity,
        this.documentUOMSchemaDefinition
      );
    this.uomQuantityRequired =
      props.uomQuantityRequired || this.uomPendingQuantity;
    this.uomFulfilledQuantity =
      props.uomFulfilledQuantity ||
      this.uomPendingQuantity ||
      this.getUomQuantity(
        props.fulfilledQuantity,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    this.uomQuantity =
      props.uomQuantity ||
      this.getUomQuantity(
        props.productQuantity,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    this.uomQuantityFulfilled =
      props.uomQuantityFulfilled ||
      this.getUomQuantity(
        props.quantityFulfilled,
        this.documentUOMSchemaDefinition
      ) ||
      0;
    this.reservedQuantitiesData = props.reservedQuantitiesData;
    this.customField = props.customField;

    // localized uom keys
    this.unlocalizedDocumentUOMSchemaDefinition = props?.isLocalizedUomQty
      ? props?.unlocalizedDocumentUOMSchemaDefinition
      : null;
    this.isLocalizedUomQty = props?.isLocalizedUomQty;
    this.localizedBaseQtyDiff = props?.localizedBaseQtyDiff;
    this.parentDocumentUOMSchemaDefinition =
      props?.parentDocumentUOMSchemaDefinition;
  }

  getPendingQuantity(qty: any) {
    if (qty) {
      return qty;
    } else if (this.productQuantity && this.quantityFulfilled) {
      return Number(this.productQuantity) - Number(this.quantityFulfilled);
    } else if (this.productQuantity && this.fulfilledQuantity) {
      return Number(this.productQuantity) - Number(this.fulfilledQuantity);
    } else if (this.productQuantity) {
      return this.productQuantity;
    } else {
      return 0;
    }
  }

  getUomQuantity(baseQuantity: any, documentUOMSchemaDefinition: any) {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        2
      );
    }
    return baseQuantity;
  }
}
