import { INDIA_STATE_MAPPING, INDIA_TEMPLATES_ID, TableColumnPopupType } from "../Constants/Constants"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import Utility, { getIsBill, getIsInvoice, getIsOrder, getIsPurchaseInwardQuotation, getIsQuotation, getIsRequestForQuotation, getIsSalesOrder } from "../Utilities/Utility"
import NumberUtility from "../Utilities/NumberUtility";
import AppManager from "../Manager/AppManager";
export default class IndiaTaxParser {
    static getTaxBreakdown(dataFromAPI, returnData) {
        var tenantStateCode = ''
        var tenantInfo = dataFromAPI.tenantInfo
        var states = []
        let isSwapAddress = TemplateSettingsManager.getIsSwapAddress()

        let tenantAddress = returnData.fromObj
        const type = dataFromAPI?.type?.toLowerCase() ?? ''
        if (getIsBill(type) || getIsOrder(type)) {
            tenantAddress = isSwapAddress ? returnData.fromObj : tenantInfo?.address
        }
        if (tenantAddress !== undefined && tenantAddress !== null) {
            if (tenantAddress.state !== undefined && tenantAddress.state !== null) {
                states = INDIA_STATE_MAPPING.filter(x => x.code.toLowerCase() === tenantAddress.state.toLowerCase())
                if (states.length > 0) {
                    tenantStateCode = states[0].stateCode
                    returnData.tenantStateCode = states[0].stateCode
                    returnData.companyStateNameAndCode = '<b>' + getLocalisedText('state_name') + ': </b>' + tenantAddress.state + ' <b>' + getLocalisedText('state_code') + ': </b>' + tenantStateCode
                }
            }
        }

        if (tenantInfo && tenantInfo.phoneNumber !== undefined && tenantInfo.phoneNumber !== null) {
            returnData.companyPhoneNumber = dataFromAPI.tenantInfo.phoneNumber
        }
    

        var contactInfo = dataFromAPI.contactInfo
        if (contactInfo !== undefined && contactInfo !== null) {
            if (contactInfo.shippingAddress !== undefined && contactInfo.shippingAddress !== null) {
                if (contactInfo.shippingAddress.state !== undefined && contactInfo.shippingAddress.state !== null) {
                    states = INDIA_STATE_MAPPING.filter(x => x.code.toLowerCase() === contactInfo.shippingAddress.state.toLowerCase())
                    if (states.length > 0) {
                        returnData.contactStateCode = states[0].stateCode
                        returnData.contactStateCode = states[0].stateCode
                        returnData.customerStateNameAndCode = '<b>' + getLocalisedText('state_name') + ': </b>' + contactInfo.shippingAddress.state + ' <b>' + getLocalisedText('state_code') + ': </b>' + returnData.contactStateCode
                    }
                }
            }

            if (contactInfo.phoneNumber !== undefined && contactInfo.phoneNumber !== null) {
                returnData.customerPhoneNumber = dataFromAPI.contactInfo.phoneNumber
            }
        }

        const billToAddress = isSwapAddress ? dataFromAPI.clientBillToAddressObj : dataFromAPI.billToAddress
        if (billToAddress !== undefined && billToAddress !== null) {
            if (billToAddress.state !== undefined && billToAddress.state !== null) {
                states = INDIA_STATE_MAPPING.filter(x => x.code.toLowerCase() === billToAddress.state.toLowerCase())
                if (states.length > 0) {
                    returnData.billToAddressStateCode = states[0].stateCode
                    returnData.billToAddressStateCode = '<b>' + getLocalisedText('state_name') + ': </b>' + billToAddress.state + ' <b>' + getLocalisedText('state_code') + ': </b>' + returnData.billToAddressStateCode
                }
            }
        }

        const shipToAddress = isSwapAddress ? dataFromAPI.clientShipToAddressObj : dataFromAPI.shipToAddress
        if (shipToAddress !== undefined && shipToAddress !== null) {
            if (shipToAddress.state !== undefined && shipToAddress.state !== null) {
                states = INDIA_STATE_MAPPING.filter(x => x.code.toLowerCase() === shipToAddress.state.toLowerCase())
                if (states.length > 0) {
                    returnData.shipToAddressStateCode = states[0].stateCode
                    returnData.shipToAddressStateCode = '<b>' + getLocalisedText('state_name') + ': </b>' + shipToAddress.state + ' <b>' + getLocalisedText('state_code') + ': </b>' + returnData.shipToAddressStateCode
                }
            }
        }

        const shipFromAddress = isSwapAddress ? tenantAddress : dataFromAPI.shipFromAddress
        if (shipFromAddress && shipFromAddress.state) {
            states = INDIA_STATE_MAPPING.filter(x => x.code.toLowerCase() === shipFromAddress.state.toLowerCase())
            if (states.length > 0) {
                returnData.billOrderCompanyStateNameCode =  '<b>' + getLocalisedText('state_name') + ': </b>' + shipFromAddress.state + ' <b>' + getLocalisedText('state_code') + ': </b>' + states[0].stateCode
            }
        }

        //process tax breakdown data
        if(returnData.lineItems !== undefined && returnData.lineItems !== null) {
            if (returnData.lineItems.length > 0) {
                var hsnOrSacCodeList = returnData.lineItems.map(x => x.hsnOrSacCode)
                hsnOrSacCodeList = hsnOrSacCodeList.filter(function (value, index, array) {
                    return array.indexOf(value) === index;
                });
                if(hsnOrSacCodeList.length > 0) {
                    var taxBreakdown = []
                    hsnOrSacCodeList.forEach(element => {
                        var items = returnData.lineItems.filter(x => x.hsnOrSacCode === element)

                        //split it with different tax rate for igst/sgst/cgst
                        var itemsWithSameRate = []
                        items.forEach(item => {
                            if (this.getIsIGST(returnData)) {
                                if(!itemsWithSameRate.includes(item.igstRate)) {
                                    itemsWithSameRate.push(item.igstRate)
                                }
                            }
                            else {
                                if (!itemsWithSameRate.includes(item.cgstRate)) {
                                    itemsWithSameRate.push(item.cgstRate)
                                }
                            }
                        });

                        if (itemsWithSameRate.length > 0) {
                            itemsWithSameRate.forEach(elementRate => {
                                var itemsRateList = []
                                if (this.getIsIGST(returnData)) {
                                    itemsRateList = items.filter(x => x.igstRate === elementRate)
                                }
                                else {
                                    itemsRateList = items.filter(x => x.cgstRate === elementRate)
                                }

                                var HSNBreakdownLineItem = this.assignHSNBreakdownLineItem(itemsRateList, returnData, element, true)
                                if (HSNBreakdownLineItem !== undefined) {
                                    taxBreakdown.push(HSNBreakdownLineItem)
                                }
                            });
                        }
                    });
                    returnData.indiaTax = taxBreakdown
                }
            }
        }

        const additionalChargerTaxList = returnData.additionalCharge ?? []
        if (additionalChargerTaxList.length > 0) {
            let hsnOrSacCodeAdditionalCharges  = []
            let additionalChargesTaxBreakdown = []
            const hsnOrSacCodeAdditionalChargesList = additionalChargerTaxList.filter(x => x.hsnOrSacCode !== undefined && x.hsnOrSacCode !== null && x.hsnOrSacCode !== '')
            if (hsnOrSacCodeAdditionalChargesList.length > 0) {
                hsnOrSacCodeAdditionalCharges = new Array(...new Set(hsnOrSacCodeAdditionalChargesList.map(x => x.hsnOrSacCode)))
                
                if (hsnOrSacCodeAdditionalCharges.length > 0) {
                    hsnOrSacCodeAdditionalCharges.forEach(element => {
                        let additionalChargerTaxItems = additionalChargerTaxList.filter(x => x.hsnOrSacCode === element)
                        var itemsWithSameRate = []
                        additionalChargerTaxItems.forEach(item => {
                            if (!itemsWithSameRate.includes(item.taxRate)) {
                                itemsWithSameRate.push(item.taxRate)
                            }
                        });
                        if (itemsWithSameRate.length > 0) {
                            itemsWithSameRate.forEach(elementRate => {
                                var itemsRateList = additionalChargerTaxItems.filter(x => x.taxRate === elementRate)
                                var newItemsRateList = []

                                itemsRateList.forEach(element => {
                                    let itemRate = {...element}
                                    if (itemRate.indiaTaxType && element.indiaTaxType !== null) {
                                        itemRate.amount = element.chargeAmount
                                        itemRate.cessAmt = 0
                                        itemRate.igstAmt = 0
                                        itemRate.cgstAmt = 0
                                        itemRate.sgstAmt = 0
                                        
                                        if (element.indiaTaxType === 'igst') {
                                            itemRate.igstAmt = element.taxAmount
                                            itemRate.igstRate = elementRate
                                        }
                                        else if (element.indiaTaxType === 'cgst') {
                                            itemRate.cgstAmt = element.taxAmount
                                            itemRate.cgstRate = elementRate / 2
                                            itemRate.sgstRate = elementRate / 2
                                        }
                                        else if (element.indiaTaxType === 'sgst') {
                                            itemRate.sgstAmt = element.taxAmount
                                            itemRate.sgstRate = elementRate / 2
                                            itemRate.cgstRate = elementRate / 2
                                        }
                                        newItemsRateList.push(itemRate)
                                    }
                                });

                                var HSNBreakdownLineItem = this.assignHSNBreakdownLineItem(newItemsRateList, returnData, element, false)
                                if (HSNBreakdownLineItem !== undefined) {
                                    additionalChargesTaxBreakdown.push(HSNBreakdownLineItem)
                                }
                            });
                        }
                    });
                }
                if (additionalChargesTaxBreakdown.length > 0) {
                    returnData.indiaTax = [...returnData.indiaTax, ...additionalChargesTaxBreakdown]
                }
            }
        }

        // returnData = this.updateTableStructure(returnData, undefined)
        return returnData
    }

    static assignHSNBreakdownLineItem(items, returnData, element, isLineItem) {
        var taxBreakdown = undefined
        if (items.length > 0) {
            var totalValue = 0
            var totalCessAmount = 0
            var totalTaxAmount = 0
            var totalTaxableValue = 0
            var totalIgstAmount = 0
            var totalSgstAmount = 0
            var totalCgstAmount = 0

            items.forEach(element => {
                totalValue = totalValue + element.amount                
                totalCessAmount = this.getTaxAmt(element.cessAmt, element.cess, totalCessAmount)
                totalIgstAmount = this.getTaxAmt(element.igstAmt, element.igst, totalIgstAmount)
                totalCgstAmount = this.getTaxAmt(element.cgstAmt, element.cgst, totalCgstAmount)
                totalSgstAmount = this.getTaxAmt(element.sgstAmt, element.sgst, totalSgstAmount)
            })

            var newTaxItem = {}
            if (this.getIsIGST(returnData)) {
                totalTaxAmount = totalIgstAmount + totalCessAmount
                totalTaxableValue = totalValue - totalTaxAmount
                if(!isLineItem) {
                    totalTaxableValue = totalValue
                }
                newTaxItem = {
                    hsn_or_sac: element,
                    taxableValue: this.getDefaultAmount(parseFloat(totalTaxableValue)),
                    integratedTax: {
                        rate: this.getDefaultRate(items[0].igstRate) + '%',
                        amount: this.getDefaultAmount(totalIgstAmount)
                    },
                    cess: this.getDefaultAmount(totalCessAmount),
                    totalTaxAmount: this.getDefaultAmount(parseFloat(totalTaxAmount))
                }
                taxBreakdown = newTaxItem
            }
            else {
                totalTaxAmount = totalCgstAmount + totalSgstAmount + totalCessAmount
                totalTaxableValue = totalValue - totalTaxAmount
                if (!isLineItem) {
                    totalTaxableValue = totalValue
                }
                newTaxItem = {
                    hsn_or_sac: element,
                    taxableValue: this.getDefaultAmount(parseFloat(totalTaxableValue)),
                    centralTax: {
                        rate: this.getDefaultRate(items[0].cgstRate) + '%',
                        amount: this.getDefaultAmount(totalCgstAmount)
                    },
                    stateTax: {
                        rate: this.getDefaultRate(items[0].sgstRate) + '%',
                        amount: this.getDefaultAmount(totalSgstAmount)
                    },
                    cess: parseFloat(totalCessAmount),
                    totalTaxAmount: this.getDefaultAmount(parseFloat(totalTaxAmount))
                }
                taxBreakdown = newTaxItem
            }
        }

        return taxBreakdown
    }

    static getDefaultRate(rate) {
        if(rate === undefined || rate === null) {
            return 0
        }
        return rate
    }

    static getDefaultAmount(amount) {
        if (amount === undefined || amount === null) {
            return 0
        }
        if (isNaN(amount)) {
            return 0
        }
        return amount
    }


    static getParseFormattedValue(value) {
        if (value !== undefined && value !== null) {
            return NumberUtility.getFormattedValue(value, AppManager.getNumberFormat(), AppManager.getDecimalScale())
        }
        return parseFloat(value)
    }

    static getIsIGST(data) {
        if (data.taxBreakdown !== undefined && data.taxBreakdown !== undefined) {
            if(data.taxBreakdown.length > 0) {
                var taxIdList = data.taxBreakdown.map(x => x.taxId)
                if(taxIdList.length > 0) {
                    return taxIdList.includes('IGST')
                }
            }
        }
        return false
    }

    static getIsContainsIndiaTax(data) {
        if (data.taxBreakdown !== undefined && data.taxBreakdown !== undefined) {
            if (data.taxBreakdown.length > 0) {
                var taxIdList = data.taxBreakdown.map(x => x.taxId)
                if (taxIdList.length > 0) {
                    return (taxIdList.includes('IGST') || taxIdList.includes('CGST') || taxIdList.includes('SGST') )
                }
            }
        }
        return false
    }

    static getIsIndiaDefaultTaxTemplates(templateId) {
        if(templateId !== undefined && templateId !== null) {
            return INDIA_TEMPLATES_ID.includes(templateId)
        }
        return false
    }

    static updateTableStructure(returnData, templateId) {
        if (Utility.getIsIndiaCountry(returnData)) {
            if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
                if (getIsQuotation(returnData.type)
                    || getIsSalesOrder(returnData.type)
                    || getIsInvoice(returnData.type)
                    || getIsRequestForQuotation(returnData.type)
                    || getIsPurchaseInwardQuotation(returnData.type)
                    || getIsBill(returnData.type)
                    || getIsOrder(returnData.type)) {
                    if (templateId !== undefined) {
                        if (returnData.templateId === 36) {
                            returnData.tableColumnPopup = this.setTableColumnForIn(returnData, true)
                        }
                        else {
                            returnData.tableColumnPopup = this.setTableColumnForIn(returnData, false)
                        }
                    }
                    else {
                        returnData.tableColumnPopup = this.setTableColumnForIn(returnData, true)
                    }
                }
            }
        }

        return returnData
    }

    static setTableColumnForIn(returnData, isShow) {
        var newTableColumnPopup = returnData.tableColumnPopup
        newTableColumnPopup.forEach(element => {
            if (element.type === TableColumnPopupType.productTax) {
                element.isSelected = isShow
            }
            else if (element.type === TableColumnPopupType.amountInWords) {
                element.isSelected = isShow
            }
        });
        return newTableColumnPopup
    }

    static getTaxAmt(taxAmt, tax, amt) {
        var tempAmt = this.getDefaultAmtValue(taxAmt)
        if (!isNaN(tempAmt)) {
            amt = amt + tempAmt
        }
        else {
            amt = amt + this.getParseFormattedValue(tax)
        }
        return amt
    }

    static getDefaultAmtValue(value) {
        if(value !== undefined && value !== null) {
            return parseFloat(value)
        }
        return undefined
    }

    static getIndiaTaxBreakdown(dataFromAPI) {
        var inTaxBreakdown = []

        if (this.getTaxValue(dataFromAPI.cgst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "CGST",
                    "taxName": "CGST",
                    "taxAmount": dataFromAPI.cgst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.igst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "IGST",
                    "taxName": "IGST",
                    "taxAmount": dataFromAPI.igst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.sgst) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "SGST",
                    "taxName": "SGST",
                    "taxAmount": dataFromAPI.sgst
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.cess) !== undefined) {
            inTaxBreakdown.push(
                {
                    "taxId": "CESS",
                    "taxName": "CESS",
                    "taxAmount": dataFromAPI.cess
                }
            )
        }

        return inTaxBreakdown
    }

    static getTcsNTds(dataFromAPI, taxBreakdown) {
        var newTaxBreakdown = []

        if (taxBreakdown !== undefined && taxBreakdown !== null) {
            if (taxBreakdown.length > 0) {
                newTaxBreakdown = taxBreakdown
            }
        }

        if (this.getTaxValue(dataFromAPI.tcs) !== undefined) {
            newTaxBreakdown.push(
                {
                    "taxId": "TCS",
                    "taxName": "TCS",
                    "taxAmount": dataFromAPI.tcs
                }
            )
        }

        if (this.getTaxValue(dataFromAPI.tds) !== undefined) {
            newTaxBreakdown.push(
                {
                    "taxId": "TDS",
                    "taxName": "TDS",
                    "taxAmount": dataFromAPI.tds
                }
            )
        }

        return newTaxBreakdown
    }

    static getTaxValue(value) {
        if (value !== undefined && value !== null) {
            if (typeof value === 'number') {
                return value > 0 ? value : undefined
            }
            if (typeof value === 'string' && value.trim().length > 0) {
                var num = value.split(' ').join('').split(',').join('').split('.').join('')
                if (Number(num) > 0) {
                    return value
                }
            }
        }
        return undefined
    }

    static getIndiaTaxFooter(templateDataToReturn) {
        var totalIgstAmount = 0
        var totalSgstAmount = 0
        var totalCgstAmount = 0

        if (templateDataToReturn.indiaTax === undefined || templateDataToReturn.indiaTax === null) {
            return {
                igst: totalIgstAmount,
                cgst: totalCgstAmount,
                sgst: totalSgstAmount,
            }
        }

        if(templateDataToReturn.indiaTax.length === 0) {
            return {
                igst: totalIgstAmount,
                cgst: totalCgstAmount,
                sgst: totalSgstAmount,
            }
        }

        templateDataToReturn.indiaTax.forEach(element => {
            var igst = 0
            var sgst = 0
            var cgst = 0

            if (element.integratedTax !== undefined) {
                if (element.integratedTax.amount !== undefined && element.integratedTax.amount !== null)
                igst = element.integratedTax.amount
            }
            if (element.stateTax !== undefined) {
                if (element.stateTax.amount !== undefined && element.stateTax.amount !== null)
                    sgst = element.stateTax.amount
            }
            if (element.centralTax !== undefined) {
                if (element.centralTax.amount !== undefined && element.centralTax.amount !== null)
                    cgst = element.centralTax.amount
            }
            totalIgstAmount = totalIgstAmount + igst
            totalSgstAmount = totalSgstAmount + sgst
            totalCgstAmount = totalCgstAmount + cgst
        });

        return {
            igst: totalIgstAmount,
            cgst: totalCgstAmount,
            sgst: totalSgstAmount,
        }
    }

    static getIndiaTaxFooterByType(templateDataToReturn, type) {
        var footer = this.getIndiaTaxFooter(templateDataToReturn)
        var value = 0
        if (footer[type.toLowerCase()] !== undefined && footer[type.toLowerCase()] !== null) {
            value = footer[type.toLowerCase()]
        }
        return value
    }
}

