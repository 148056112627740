import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class BOMTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isQuantityVisible: boolean;
    isUomVisible: boolean;
    isQuantityWithUomVisible: boolean;
    isCostVisible: boolean;
    isProductCustomFieldVisible: boolean; 

    lineNumber: number;
    code: number;
    name: number;
    description: number;
    nameWithDescription: number;
    quantity: number;
    uom: number;
    quantityWithUom: number;
    cost: number;
    productCustomField: number;

    originalLineNumber: number;
    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalQuantity: number;
    originalUom: number;
    originalQuantityWithUom: number;
    originalCost: number;
    originalProductCustomField: number;

    productCustomFieldList: any;
    productGlobalDiscountList: any;

    constructor() {
        this.isLineNumberVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = true 
        this.isQuantityVisible = true
        this.isUomVisible = true
        this.isQuantityWithUomVisible = true
        this.isCostVisible = false
        this.isProductCustomFieldVisible = true 


        this.lineNumber = 10
        this.code = 15
        this.name = 15
        this.description = 30
        this.nameWithDescription = this.name + this.description
        this.quantity = 10
        this.uom = 10
        this.quantityWithUom = this.quantity + this.uom
        this.cost = 10
        this.productCustomField = 5

        this.originalLineNumber = 10
        this.originalCode = 15
        this.originalName = 15
        this.originalDescription = 30
        this.originalNameWithDescription = this.originalName + this.originalDescription
        this.originalQuantity = 10
        this.originalUom = 10
        this.originalQuantityWithUom = this.originalQuantity + this.originalUom
        this.originalCost = 10
        this.originalProductCustomField = 5

        this.productCustomFieldList = undefined
        this.productGlobalDiscountList = undefined

    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)
        this.isQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantity)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isQuantityWithUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityWithUom)
        this.isCostVisible = getIsShowTableColumn(columnData, TableColumnPopupType.cost)

        var width = 5
        var maxWidth = 20

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription
        this.quantity = this.originalQuantity
        this.uom = this.originalUom
        this.quantityWithUom = this.originalQuantityWithUom
        this.cost = this.originalCost
        this.productCustomField = this.originalProductCustomField

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.lineNumber = 0
        this.code = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        this.quantity = 0
        this.uom = 0
        this.quantityWithUom = 0
        this.cost = 0

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isQuantityVisible) {
            totalWidth += this.quantity;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isQuantityWithUomVisible) {
            totalWidth += this.quantityWithUom;
        }
        if (this.isCostVisible) {
            totalWidth += this.cost;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityWithUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isCostVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        if (this.isQuantityVisible) {
            this.quantity = this.originalQuantity + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isQuantityWithUomVisible) {
            this.quantityWithUom = this.originalQuantityWithUom + additionalWidth
        }
        if (this.isCostVisible) {
            this.cost = this.originalCost + additionalWidth
        }
        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }

    getProductGlobalDiscountWidthByGlobalDiscountAccountCode(globalDiscountAccountCode: string) {
        if (this.productGlobalDiscountList !== undefined) {
            var item = this.productGlobalDiscountList.find((x: any) => x.globalDiscountAccountCode === globalDiscountAccountCode)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}