import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { getDateString, getTitleCase } from "../../Utilities/Utility"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class WorkOrderParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        let customFields = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).productCustomField ? this.getWorkOrderResponse(dataFromAPI).productCustomField  : [] : [] 
        templateDataToReturn.customFields = customFields

        if (this.getWorkOrderResponse(dataFromAPI).customField && this.getWorkOrderResponse(dataFromAPI).customField !== null) {
            if (this.getWorkOrderResponse(dataFromAPI).customField.length > 0) {
                templateDataToReturn.customFields = [...this.getWorkOrderResponse(dataFromAPI).customField, ...customFields]
            }
        }
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).documentSequenceCode : ''

        //link document type 
        templateDataToReturn.linkDocumentType = undefined
        templateDataToReturn.linkDocumentNumber = undefined
        var parentWorkOrderSeqCode = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).parentWorkOrderSeqCode : undefined
        if (parentWorkOrderSeqCode !== undefined && parentWorkOrderSeqCode !== null) {
            // templateDataToReturn.linkDocumentType = 'parent_work_order'
            templateDataToReturn.parentWorkOrder = parentWorkOrderSeqCode
            templateDataToReturn.linkDocumentType = getLocalisedText('work_order')
        }
        
        templateDataToReturn.linkDocumentType = templateDataToReturn.linkDocumentType ?? ''
        var workOrderSourceDetails = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).workOrderSourceDetails : undefined
        if (workOrderSourceDetails !== undefined && workOrderSourceDetails !== null) {
            let multiSO = ''
            if (workOrderSourceDetails.length > 0) {
                let salesOrders = workOrderSourceDetails.filter(x => x.workOrderSource === 'SALES_ORDER')

                if (salesOrders && salesOrders !== null) {
                    if (salesOrders.length > 0) {
                        workOrderSourceDetails = salesOrders[0]

                        if (salesOrders.length > 1) {
                            multiSO = salesOrders.map(x => x.salesOrderSequenceCode).join(', ')
                        }
                    }
                }

                if (workOrderSourceDetails.length > 0) {
                    let salesInvoice = workOrderSourceDetails.filter(x => x.workOrderSource === 'SALES_INVOICE')
                    if (salesInvoice && salesInvoice !== null) {
                        if (salesInvoice.length > 0) {
                            workOrderSourceDetails = salesInvoice[0]

                            if (salesInvoice.length > 1) {
                                multiSO = salesInvoice.map(x => x.salesInvoiceSequenceCode).join(', ')
                            }
                        }
                    }
                }
            }

            var salesOrderSequenceCode = workOrderSourceDetails.salesOrderSequenceCode
            var workOrderSource = workOrderSourceDetails.workOrderSource

            if(multiSO !== '') {
                templateDataToReturn.linkDocumentNumber = multiSO
            }
            else if (salesOrderSequenceCode !== undefined && salesOrderSequenceCode !== null) {
                templateDataToReturn.linkDocumentNumber = salesOrderSequenceCode
            }
            if (workOrderSource !== undefined && workOrderSource !== null) {
                if (workOrderSource.toLowerCase() === 'sales_order') {
                    templateDataToReturn.linkDocumentType = getLocalisedText('sales_order')
                }
                else {
                    templateDataToReturn.linkDocumentType = getLocalisedText(workOrderSource)
                }
            }
        }

          //start date
        let startDate = ''
        let startDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualStartDate : ''
        if (startDateTimeStamp !== '') {
            startDate = getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.date = startDate

        let endDate = ''
        let endDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualEndDate : ''
        if (endDateTimeStamp !== '') {
            endDate = getDateString(new Date(endDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.dueDate = endDate
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        let deliveryDate = ''
        let deliveryDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).deliveryDate : ''
        if (deliveryDateTimeStamp !== '') {
            deliveryDate = getDateString(new Date(deliveryDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.deliveryDate = deliveryDate

        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productName')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productCode')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productDescription')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'productDocSeqCode')
        if (templateDataToReturn.productDocSeqCode) {
            templateDataToReturn.productCode = templateDataToReturn.productDocSeqCode
        }
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'estimatedTime')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'actualQuantity')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'manufactureQuantity')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'status')
        templateDataToReturn.woStatus = templateDataToReturn.status ?? ''
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'qcStatus')
        templateDataToReturn = this.getDefaultValue(dataFromAPI, templateDataToReturn, 'contactName')

        templateDataToReturn.woStatus = getTitleCase(templateDataToReturn.woStatus)
        templateDataToReturn.qcStatus = getTitleCase(templateDataToReturn.qcStatus)

        templateDataToReturn.taggedBinWarehouseInfo = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).taggedBinWarehouseInfo : undefined

        templateDataToReturn.customerOrderNumber = ''
        if (dataFromAPI.salesOrderCustomerOrderNumber) {
            templateDataToReturn.customerOrderNumber = dataFromAPI.salesOrderCustomerOrderNumber
        }
        if (workOrderSourceDetails !== undefined && workOrderSourceDetails !== null) {
            if (workOrderSourceDetails.salesOrderCustomerOrderNumber !== undefined
                && workOrderSourceDetails.salesOrderCustomerOrderNumber !== null) {
                templateDataToReturn.customerOrderNumber = workOrderSourceDetails.salesOrderCustomerOrderNumber
            }
        }
        //line items
        templateDataToReturn.lineItems = LineItemsParser.getWorkOrderItems(dataFromAPI, templateDataToReturn.dateFormat)
        
        //check if contains 'SCRAP' or 'COPRODUCT'
        templateDataToReturn.isContainsScrapProduct = false
        templateDataToReturn.isContainsCoproduct = false
        if (templateDataToReturn.lineItems.length > 0) {
            var scrapProduct = templateDataToReturn.lineItems.find(x => x.produceProductType === 'SCRAP')
            templateDataToReturn.isContainsScrapProduct = scrapProduct !== undefined
            var coproductProduct = templateDataToReturn.lineItems.find(x => x.produceProductType === 'COPRODUCT')
            templateDataToReturn.isContainsCoproduct = coproductProduct !== undefined
        }

        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        //work order barcode
        var barcodeList = []
        if(dataFromAPI.jobCardResponseList !== undefined && dataFromAPI.jobCardResponseList !== null) {
            if(dataFromAPI.jobCardResponseList.length > 0) {
                dataFromAPI.jobCardResponseList.forEach(element => {
                    var item = {
                        label: element.operationName,
                        value: element.documentSequenceCode,
                        instructions: element.instructions ?? '',
                        status: element.status ?? ''
                    }
                    barcodeList.push(item)
                });
            } 
        }
        templateDataToReturn.barcodeList = barcodeList
        return templateDataToReturn
    }

    static getWorkOrderResponse(dataFromApi) {
        if (dataFromApi.workOrderResponse !== undefined && dataFromApi.workOrderResponse !== null) {
            return dataFromApi.workOrderResponse
        }
        return undefined
    }

    static getDefaultValue(dataFromAPI, templateDataToReturn, key) {
        templateDataToReturn[key] = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI)[key] : ''
        return templateDataToReturn
    }

}
