import React, { Component } from 'react'
import TemplateSettingsManager from '../Manager/TemplateSettingsManager'
import { getLocalisedText } from '../Translate/LanguageManager'
import { HTMLTag } from '../Utilities/HTMLTag'
import { getTextAlign } from './dktable/TableUtility'
import { ApprovalSectionType, CustomApprovalSectionType } from '../Utilities/ApprovalSectionUtility'
import { FONT_SIZE } from '../Constants/Constants'
import Utility, { getFontSizeClass, getVW } from '../Utilities/Utility'
import QRCode from "react-qr-code";

export default class DKCustomApprovalSection extends Component {

    render() {
        var customApprovalSectionSettings = TemplateSettingsManager.getCustomApprovalSectionSettings()
        const approvalList = customApprovalSectionSettings?.sections ?? []

        if (approvalList.length === 0) {
            return <></>
        }
        else {
            return this.renderApprovalTable(approvalList, customApprovalSectionSettings)
        }
    }

    renderApprovalTable(approvalList, customApprovalSectionSettings) {
        let topApprovalList = []
        let bottomApprovalList = []

        approvalList.forEach((element, index) => {
            if(index > 3) {
                bottomApprovalList.push(element)
            }
            else {
                topApprovalList.push(element)
            }
        });

        const topCells = topApprovalList.map((element, index) => {
            return this.renderApprovalTableCell(element, index)
        });

        const bottomCells = bottomApprovalList.map((element, index) => {
            return this.renderApprovalTableCell(element, index)
        });

        return <div id={HTMLTag.APPROVAL_TABLE} style={{
            // paddingBottom: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
        }}>
            <table className='doc-container'>
                <tbody style={{
                    border: '1px solid black',
                }}>
                    <tr className='row-cell'>
                        {topCells}
                    </tr>
                    <tr className='row-cell'>
                        {bottomCells}
                    </tr>
                </tbody>
            </table>
        </div>
    }

    renderApprovalTableCell(element, index) {
        switch (element.type) {
            case CustomApprovalSectionType.bank:
                return this.getItemDetailContainer(element, index)
            case CustomApprovalSectionType.qr:
                return this.getQrContainer(element, index)
            case CustomApprovalSectionType.companySeal:
                return this.renderEmptyCell(element, index)
            case CustomApprovalSectionType.authorizedBy:
                return this.renderSignatoryCell(element, index)
            case CustomApprovalSectionType.companyInfo:
                return this.getItemDetailContainer(element, index)
            case CustomApprovalSectionType.preparedBy:
                return this.renderEmptyCell(element, index)
            case CustomApprovalSectionType.checkedBy:
                return this.renderEmptyCell(element, index)

            default:
                return <>no type</>;
        }
    }

    getItemDetailContainer(element, index) {
        const items = element.items ?? []
        let itemsRow = undefined


        if(items.length > 0) {
            itemsRow = items.map(item => {
                return this.getRowValueSection(item)
            });
        }

        return (
            <td className='cell'
                colSpan={element.type === CustomApprovalSectionType.companyInfo ? 2 : undefined}
                style={{
                    textAlign: 'left',
                    width: element.width.toFixed(0) + '%',
                    border: '1px solid black',
                    ...this.getAdditionalPropertyByElement(element),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale),
                    paddingLeft: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                    paddingRight: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                }}>
                <div style={{
                    textAlign: 'left',
                }}>
                    {(element.label &&
                        <text style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.props.data.printScale)
                        }}>
                            {getLocalisedText(element.label)}
                        </text>
                    )}
                    {itemsRow}
                </div>
            </td>
        )
    }

    getRowValueSection(element) {
        return <div className="RowDiv"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(2,this.props.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(2,this.props.data.printScale)),
                flex: 1,
                width: '-webkit-fill-available'
            }}>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color:this.props.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.props.data.printScale)
                }}>
                {getLocalisedText(element.label)}
            </text>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color:this.props.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.props.data.printScale)
                }}>
                :
            </text>
            &nbsp;
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.props.data.printScale)
                }}>
                {element.value}
            </text>
        </div>
    }

    getQrContainer(element, index) {
        return (
            <td className='cell' style={{
                textAlign: 'left',
                width: element.width.toFixed(0) + '%',
                border: '1px solid black',
                ...this.getAdditionalPropertyByElement(element),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale),
                padding: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
            }}>
                {this.renderQRCode(element.value, index)}
            </td>
        )
    }

    renderQRCode(value, index) {
        if (this.getIsContainQRCode(value)) {
            return <QRCode
                id={HTMLTag.QR_CODE_PREFIX + index}
                value={value}
                size={100}
            />
        }
        return null
    }

    getIsContainQRCode(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }

    renderEmptyCell(element, index) {
        return (
            <td className='cell' style={{
                textAlign: getTextAlign(TemplateSettingsManager.getApprovalSectionSettings()?.textAlign),
                color: this.getHeaderColor(),
                border: '1px solid black',
                width: element.width.toFixed(0) + '%',
                ...this.getAdditionalPropertyByElement(element),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale),
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
            }}>
                {getLocalisedText(element.label)}
            </td>
        )
    }

    renderSignatoryCell(element, index) {
        return (
            <td className='cell docFontStyleBold' style={{
                textAlign: getTextAlign(TemplateSettingsManager.getApprovalSectionSettings()?.textAlign),
                color: this.getHeaderColor(),
                border: '1px solid black',
                width: element.width.toFixed(0) + '%',
                ...this.getAdditionalPropertyByElement(element),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale),
                paddingLeft: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                paddingRight: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
            }}>
                <table className='parent-width'>
                    <tbody>
                        <tr className='row-cell'>
                            <td className='cell' style={{
                                verticalAlign: 'top',
                                textAlign: 'left',
                            }}>
                                {getLocalisedText(Utility.getCompanyName(this.props.data))}
                            </td>
                        </tr>
                        <tr className='row-cell'>
                            <td className='cell' style={{
                                verticalAlign: 'bottom',
                                textAlign: 'center',
                                paddingTop: getVW(Utility.convertToPrintScale(100, this.props.data.printScale)),
                            }}>
                                {getLocalisedText(element.label)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        )
    }

    getAdditionalPropertyByElement(element) {
        switch (element.type) {
            case CustomApprovalSectionType.bank:
                return { verticalAlign: 'top', textAlign: 'center' }
            case CustomApprovalSectionType.qr:
                return { verticalAlign: 'center', textAlign: 'center' }
            case CustomApprovalSectionType.companySeal:
                return { verticalAlign: 'bottom', textAlign: 'center' }
            case CustomApprovalSectionType.authorizedBy:
                return { verticalAlign: 'top', textAlign: 'center' }
            case CustomApprovalSectionType.companyInfo:
                return { verticalAlign: 'top', textAlign: 'center' }
            case CustomApprovalSectionType.preparedBy:
                return { verticalAlign: 'top', textAlign: 'center', fontWeight: 'bold' }
            case CustomApprovalSectionType.checkedBy:
                return { verticalAlign: 'top', textAlign: 'center', fontWeight: 'bold' }
            default:
                return { verticalAlign: 'bottom', textAlign: 'center' }
        }
    }

    getApprovalItemFormat(item, position, lineSpace) {
        let htmlString = ''
        const value = this.getItemValue(item) ?? ''

        const defaultLineCount = lineSpace ?? 0
        let lineCount = defaultLineCount

        if (defaultLineCount > 0 && value !== '') {
            lineCount--
        }

        const newLineBreak = new Array(lineCount).fill('<br/>')

        if (position === 'top') {
            htmlString += getLocalisedText(item.label)
            htmlString += '<br/>'
            htmlString += newLineBreak.join('')
            if (value !== '') {
                htmlString += value
            }
        }
        else if (position === 'bottom') {
            if (value !== '') {
                htmlString += value + '<br/>'
            }
            htmlString += newLineBreak.join('')
            htmlString += getLocalisedText(item.label)
        }

        return htmlString
    }

    getItemValue(item) {
        switch (item.type) {
            case ApprovalSectionType.createdBy:
                return this.props.data.createdBy ?? ''
            case ApprovalSectionType.checkedBy:
                return ''
            case ApprovalSectionType.authorizedBy:
                return ''
            default:
                return ''
        }
    }

    getHeaderColor() {
        if (this.props.isThemeColor) {
            return this.props.data.themeColor
        }

        if (this.props.headerColor !== undefined) {
            return this.props.headerColor
        }

        return undefined
    }
}
