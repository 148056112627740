import { useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  PurchaseOrder,
  OrderInitialState,
  OrderProps
} from '../../Models/Order';
import OrderService from '../../Services/PurchaseOrder';
import {
  fetchOrders,
  updateRefreshCallback
} from '../../Redux/Slices/PurchaseOrdersSlice';
import { setItemsFromPurchaseOrderItems } from './PurchaseOrderHelper';
import { activeTenantInfo, userInfo } from '../../Redux/Slices/AuthSlice';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import {
  checkGSTINPresentForSelectedContact,
  customFieldsContainsErrors,
  getDocumentAlert,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import AuthService from '../../Services/Auth';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import NumberFormatService from '../../Services/NumberFormat';
import { DraftTypes } from '../../Models/Drafts';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import DateFormatService from '../../Services/DateFormat';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { useHistory } from 'react-router-dom';
import DraftService from '../../Services/Drafts';
import NewFADocument from '../../SharedComponents/DocumentForm/NewFADocument';
import { FAOrderInitialState, FAPurchaseOrder } from '../../Models/FAOrder';

const NewFAPurchaseOrder: React.FC<OrderProps> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [order, setOrder] = useState<FAPurchaseOrder>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseOrderItems(props.populateFormData,props?.documentMode)
      : { ...FAOrderInitialState }
  );
  const [updatedOrder, setUpdatedOrder] = useState<FAPurchaseOrder>(order);
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();
  let history = useHistory();
  const userDetails = useAppSelector(userInfo);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          onSaveAsDraft(false);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ORDER,
        data: () => {
          updateOrderDetails();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_ORDER,
        data: (closeDoc: boolean) => {
          createOrder(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    if (order && order.dropShip && props.documentMode === DOCUMENT_MODE.EDIT) {
      showAlert(
        'Dropship Purchase Order',
        'This is a Dropship document and has only limited edit functionality'
      );
    }
    setIsCenterAlign(props.isCenterAlign);
    loadApprovalConditionList();
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setOrder({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = (isApprovalRequired: boolean) => {
    const payload = { ...updatedOrder };

    if (props.documentMode !== DOCUMENT_MODE.EDIT) {
      delete payload.documentSequenceCode;
    }

    if (isApprovalRequired) {
      payload['approvalStatus'] = APPROVAL_STATUS['PENDING_FOR_APPROVAL'];
      payload['multiApprovalDetails'] = {
        currentLevel: payload?.['multiApprovalDetails']?.currentLevel || 1,
        approvalHistory:
          payload?.['multiApprovalDetails']?.approvalHistory || [],
        approvalRequiredFor: userDetails?.[0]?.email || ''
      };
    } else {
      payload['approvalStatus'] = payload['approvalStatus']
        ? payload['approvalStatus']
        : APPROVAL_STATUS['NOT_REQUIRED'];
    }
    payload['createdUserName'] = AuthService.getUserName();

    setButtonStatus(true);
    props.formData(payload);

    if (isApprovalRequired) {
      sendTriggerOnApproval(payload);
      if (props.documentMode === DOCUMENT_MODE.COPY) {
        let draftsData: any = {
          data: {
            type: LABELS.PURCHASE_ORDERS,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig
          }
        };
        DraftService.createRecord(payload, draftsData).then((response: any) => {
          dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.PURCHASE_ORDERS
            })
          );
        });
      }
    }
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.ORDERS)
    ) {
      history.replace(PAGE_ROUTES.ORDERS);
    }
  };

  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.purchaseOrderAssets)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Order date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Order date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseOrderAssets.length; i++) {
      const item = docToValidate.purchaseOrderAssets[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    PurchaseOrderService.fetchOrderDetails(docResp.poCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.ORDER,
          items: [...doc.purchaseOrderAssets],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate,
          contactCode: payload.contactCode
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (!isUpdate) {
            if (payload.showCustomAlert) {
              showAlert(
                'Fixed Asset Purchase Order Created!',
                'Fixed Asset Purchase Order has been created successfully.'
              );
              dispatch(updateRefreshCallback(new Date()));
              return;
            }
            getDocumentAlert(
              'Fixed Asset Purchase Order created!',
              'Fixed Asset Purchase Order has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.ORDERS
            );
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  };

  const handleAssetDetails = (payload: any) => {
    let purchaseOrderAssets = payload?.purchaseOrderAssets?.map((item: any) => {
      return {
        ...item,
        assetDetails: item.assetDetails?.map((detail: any) => {
          return {
            ...detail,
            salesIncomeAccountCode:
              typeof detail?.salesIncomeAccountCode === 'string'
                ? detail?.salesIncomeAccountCode
                : detail?.salesIncomeAccountCode?.code,
            profitLossAccountCode:
              typeof detail?.profitLossAccountCode === 'string'
                ? detail?.profitLossAccountCode
                : detail?.profitLossAccountCode?.code,
            warehouse:
              typeof detail?.warehouse === 'string'
                ? detail?.warehouse
                : detail?.warehouse?.code,
            depreciationConvention:
              typeof detail?.depreciationConvention === 'string'
                ? detail?.depreciationConvention
                : detail?.depreciationConvention?.value,
            depreciationMethod:
              typeof detail?.depreciationMethod === 'string'
                ? detail?.depreciationMethod
                : detail?.depreciationMethod?.value,
            depreciationStartDate:
              typeof detail?.depreciationStartDate === 'string' &&
              detail?.depreciationStartDate?.length > 0
                ? detail?.depreciationStartDate?.toLowerCase()?.includes('z')
                  ? DateFormatService.getDateStrFromDate(
                      new Date(detail?.depreciationStartDate),
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )
                  : detail?.depreciationStartDate
                : '',
            warrantyStartDate:
              typeof detail?.warrantyStartDate === 'string' &&
              detail?.warrantyStartDate?.length > 0
                ? detail?.warrantyStartDate?.toLowerCase()?.includes('z')
                  ? DateFormatService.getDateStrFromDate(
                      new Date(detail?.warrantyStartDate),
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )
                  : detail?.warrantyStartDate
                : '',
            warrantyEndDate:
              typeof detail?.warrantyEndDate === 'string' &&
              detail?.warrantyEndDate?.length > 0
                ? detail?.warrantyEndDate?.toLowerCase()?.includes('z')
                  ? DateFormatService.getDateStrFromDate(
                      new Date(detail?.warrantyEndDate),
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )
                  : detail?.warrantyEndDate
                : ''
          };
        })
      };
    });
    return { ...payload, purchaseOrderAssets };
  };

  const updatePayloadDetails = (payload: any) => {
    let updatedData = payload;
    if (
      updatedData &&
      updatedData.purchaseOrderAssets &&
      updatedData.purchaseOrderAssets.length > 0
    ) {
      updatedData.purchaseOrderAssets.forEach((data: any) => {
        data.assetGroupId = data.fixedAssetGroup.id;
        data.assetGroupName = data.fixedAssetGroup.name;
        data.taxDetails = data.tax
          ? [
              {
                taxId: data.tax.id,
                taxName: data.tax.name,
                taxCode: data.tax.taxCode,
                taxRate: data.tax.percent,
                taxSeqCode: data.tax.code,
                taxType: data.tax.type,
                taxAmount: data.taxAmount,
                additionalTaxIn: data.tax.additionalTaxIn,
                taxableAmount: data.totalWithDiscount
              }
            ]
          : [];
        data.quantity = data.productQuantity;
        data.effectiveLife = data.effectiveLife;
        if (data.assetDetails && data.assetDetails.length > 0) {
          data.assetDetails.forEach((assetDetail: any) => {
            assetDetail.purchaseDate = DateFormatService.getDateStrFromDate(
              new Date(assetDetail.purchaseDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            assetDetail.installationDate = DateFormatService.getDateStrFromDate(
              new Date(assetDetail.installationDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );
            if (!Utility.isEmpty(assetDetail.tax)) {
              assetDetail.taxCode = assetDetail.tax.code;
              assetDetail.taxAmount =
                ((assetDetail.purchasePrice - assetDetail.discount) *
                  assetDetail.tax.percent) /
                100;
            }
          });
        }
        delete data.productQuantity;
        delete data.fixedAssetGroup;
      });
      updatedData.documentType = DOC_TYPE.ORDER;
      //   updatedData.shipTo.destinationOfSupply = null;
      //   updatedData.shipTo =
      //     tenantInfo &&
      //     tenantInfo.shippingAddresses &&
      //     tenantInfo.shippingAddresses.length > 0
      //       ? tenantInfo.shippingAddresses[0]
      //       : updatedData.billTo;
      //   updatedData.shipFrom.placeOfSupply = null;
      //   delete updatedData.additionalCharges;
    }

    return updatedData;
  };

  // Create Order
  const createOrder = async (closeDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedOrder);
    payload = handleAssetDetails(payload);
    payload = updateAddressAsPerLocationCF(payload);
    delete payload?.items;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (tenantInfo.country === COUNTRY_CODES.IN) {
      let hasError = false;
      if (
        payload.purchaseOrderAssets &&
        payload.purchaseOrderAssets.length > 0
      ) {
        payload.purchaseOrderAssets.forEach((item: any) => {
          if (Utility.isEmpty(item.tax)) {
            hasError = true;
          }
        });
        if (hasError) {
          showAlert('', 'Please select tax.');
          setButtonStatus(false);
          return;
        }
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    let isApproval = await Utility.isApprovalRequired(payload);
    if (isApproval === null) {
      setButtonStatus(false);
      return;
    } else if (isApproval) {
      onSaveAsDraft(isApproval);
      return;
    } else {
      payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
    }

    payload = updatePayloadDetails(payload);

    OrderService.createOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (response: any) => {
        if (props.draftData) {
          if (closeDoc) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
          }
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            dispatch(
              deleteDrafts({
                recordId: props.draftData.id,
                tableId: draftsTableId
              })
            );
          }
          setButtonStatus(false);
          updateForm(response, payload, false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );
        if (response?.dropShip || response?.backOrder) {
          switch (response?.linkedDocuments?.[0]?.documentType) {
            case DOC_TYPE.INVOICE:
              dispatch(fetchInvoices());
              break;
            case DOC_TYPE.QUOTE:
              dispatch(fetchQuotes());
              break;
            case DOC_TYPE.SALES_ORDER:
              dispatch(fetchSalesOrders());
              break;
            default:
              break;
          }
        } else {
          dispatch(fetchOrders());
        }
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while creating Order: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const updateOrderDetails = (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedOrder);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    payload = handleAssetDetails(payload);
    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    payload = updatePayloadDetails(payload);

    OrderService.updateOrder(
      payload,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
      )
    ).then(
      (res) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.PURCHASE_ORDERS
          })
        );
        dispatch(fetchOrders());
        setButtonStatus(false);
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error updating PO: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleOrderUpdate = (doc: Document) => {
    let orderWithUpdate: FAPurchaseOrder = {
      ...order,
      ...doc,
      purchaseOrderAssets: doc.items,
      orderDueDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      receiveByDate: doc.fulfillmentDate,
      vendorCode: doc.contactCode,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      documentDate: doc.documentDate,
      totalAmount: doc.totalAmount,
      dropShipName: doc.dropShipName || ''
    };

    let editBillWithExtraKeys = {
      documentCode: '',
      documentType: DOC_TYPE.FA_ORDER,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      orderType: BUY_TYPE.ASSET,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT ? order?.id : undefined,
      purchaseOrderAssets: orderWithUpdate.purchaseOrderAssets?.map(
        (billDoc) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.purchaseOrderItemCode
          };
        }
      )
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      orderWithUpdate = { ...orderWithUpdate, ...editBillWithExtraKeys };
    }
    setUpdatedOrder(orderWithUpdate);
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseOrderAssets
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    PurchaseOrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };
  order.documentType = DOC_TYPE.FA_ORDER;
  if (order.purchaseOrderAssets) {
    order.items = order.purchaseOrderAssets;
  }

  return (
    <NewFADocument
      permissionKeys={PERMISSIONS_BY_MODULE.PURCHASE_ORDER}
      booksDocument={order}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleOrderUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateOrderDetails(false)}
    />
  );
};

export default NewFAPurchaseOrder;
