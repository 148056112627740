import { INPUT_TYPE } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import Utility, { getCapitalized } from '../../../Utility/Utility';
import { getColumnConfigType } from '../../Accounting/JournalEntry/JEHelper';
import DateFormatService from '../../../Services/DateFormat';

export const RFQ_FORM_DATE_TYPE = {
  RFQ_DATE: 'RFQ_DATE',
  RFQ_VALID_BY: 'RFQ_VALID_BY'
};

export const RFQ_COLUMN_KEYS = {
  PRODUCT: 'product',
  PRODUCT_DOC_SEQ_CODE: 'documentSequenceCode',
  PRODUCT_DESCRIPTION: 'productDescription',
  QUANTITY: 'productQuantity',
  UOM: 'documentUom',
  DOC_UOM_SCHEMA_DEFINITION: 'documentUOMSchemaDefinition',
  UOM_QUANTITY: 'uomQuantity'
};

export const RFQ_FORM_LINE_ITEMS_COLUMN_CONFIGS = [
  {
    name: 'Product',
    key: RFQ_COLUMN_KEYS.PRODUCT,
    type: INPUT_TYPE.DROPDOWN,
    width: 260,
    required: true,
    editable: true,
    dropdownConfig: {
      title: '',
      allowSearch: true,
      searchableKey: 'name',
      className: 'shadow-m',
      searchApiConfig: {},
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: RFQ_COLUMN_KEYS.PRODUCT_DOC_SEQ_CODE,
    key: RFQ_COLUMN_KEYS.PRODUCT_DOC_SEQ_CODE,
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: RFQ_COLUMN_KEYS.PRODUCT_DESCRIPTION,
    key: RFQ_COLUMN_KEYS.PRODUCT_DESCRIPTION,
    name: 'Product Description',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: RFQ_COLUMN_KEYS.QUANTITY,
    key: RFQ_COLUMN_KEYS.QUANTITY,
    name: 'Quantity',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    name: 'UOM',
    key: RFQ_COLUMN_KEYS.UOM,
    type: INPUT_TYPE.DROPDOWN,
    width: 110,
    required: false,
    editable: true,
    dropdownConfig: {
      title: '',
      allowSearch: true,
      searchableKey: 'name',
      className: 'shadow-m',
      searchApiConfig: {},
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  }
];

export const getRFQSavePayload = (formValues: any, isEditMode: boolean) => {
  const contactCodes =
    formValues?.contact?.map((item: any) => item?.code) ?? [];

  const requestForQuotationItems = formValues?.requestForQuotationItems?.map(
    (lineItem: any) => {
      return {
        customField: lineItem?.customField ?? [],
        linkedPRItemCode: lineItem?.linkedPRItemCode,
        productOrder: 0,
        documentUOMSchemaDefinition: lineItem?.documentUOMSchemaDefinition,
        documentSequenceCode: lineItem?.documentSequenceCode,
        documentUom: lineItem?.documentUom,
        lineNumber: lineItem?.lineNumber,
        product: lineItem?.product,
        productCode: lineItem?.product?.productId,
        productDescription: lineItem?.productDescription,
        productQuantity: lineItem?.productQuantity,
        uomQuantity: lineItem?.productQuantity
      };
    }
  );

  let payload = {
    attachmentId: [],
    linkedPRDocuments: formValues?.linkedPRDocuments ?? [],
    linkedPurchaseInwardQuotes: formValues?.linkedPurchaseInwardQuotes ?? [],
    linkedQuoteDocuments: formValues?.linkedQuoteDocuments ?? [],
    id: formValues?.id ?? null,
    customField: formValues?.customField ?? [],
    vendorCode: contactCodes,
    contact: formValues?.contact,
    email: formValues?.email,
    memo: formValues?.memo,
    requestForQuotationItems: requestForQuotationItems,
    rfqCode: formValues?.rfqCode,
    rfqDate: formValues?.rfqDate,
    documentSequenceCode: formValues?.rfqDocumentSequenceCode,
    sequenceFormat: formValues?.sequenceFormat,
    validByDate: formValues?.validByDate
  };

  if (isEditMode) {
    delete payload.documentSequenceCode;
  }

  return payload;
};

export const getNewCFItem = (item: any, filteredCF: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.label,
    type: getColumnConfigType(filteredCF?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      filteredCF?.fieldType !== 'DROPDOWN' && filteredCF?.fieldType !== 'USER'
        ? null
        : {
            title: '',
            allowSearch: false,
            searchableKey: 'name',
            style: { minWidth: 100 },
            className: 'shadow-m width-auto',
            data: !Utility.isEmpty(filteredCF?.attributes)
              ? filteredCF?.attributes?.filter(
                  (attr: any) =>
                    attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
                )
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};

export const getNewColumnForCF = (item: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.system ? getCapitalized(item.label?.toLowerCase()) : item.label,
    type: getColumnConfigType(item?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      item?.fieldType !== 'DROPDOWN' && item?.fieldType !== 'USER'
        ? null
        : {
            title: '',
            allowSearch: false,
            searchableKey: 'name',
            style: { minWidth: 100 },
            className: 'shadow-m width-auto',
            data: !Utility.isEmpty(item?.attributes)
              ? item?.attributes?.filter(
                  (attr: any) =>
                    attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
                )
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};

export const RFQ_ALL_DATA_COLUMN_CONFIGS = [
  {
    id: 'documentSequenceCode',
    key: 'documentSequenceCode',
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'contacts',
    key: 'contacts',
    name: 'Contacts',
    type: INPUT_TYPE.TEXT,
    width: 400,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    renderer: (obj: any, index: number) => {}
  },
  {
    id: 'status',
    key: 'status',
    name: 'Status',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'rfqDate',
    key: 'rfqDate',
    name: 'RFQ Date',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'validByDate',
    key: 'validByDate',
    name: 'Valid By',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  }
];

export const RFQ_FORM_STATUS = {
  OPEN: 'OPEN',
  QUOTES_RECEIVED: 'QUOTES_RECEIVED',
  MANUALLY_CLOSED: 'MANUALLY_CLOSED'
};

export const getRFQGridStatus = (status: any) => {
  switch (status) {
    case RFQ_FORM_STATUS.OPEN:
      return 'Open';

    case RFQ_FORM_STATUS.QUOTES_RECEIVED:
      return 'Quotes Received';

    case RFQ_FORM_STATUS.MANUALLY_CLOSED:
      return 'Manually Closed';

    default:
      return 'Open';
  }
};

export const getRFQGridStatusClass = (status: any) => {
  switch (status) {
    case RFQ_FORM_STATUS.OPEN:
      return 'bg-chip-blue fw-m parent-size';

    case RFQ_FORM_STATUS.QUOTES_RECEIVED:
      return 'bg-chip-green fw-m parent-size';

    case RFQ_FORM_STATUS.MANUALLY_CLOSED:
      return 'bg-chip-red fw-m parent-size';

    default:
      return 'bg-chip-blue fw-m parent-size';
  }
};

export const prepareRFQDataForEditMode = (data: any) => {
  const sequenceContact = data?.contactDto.sort((a: any, b: any) => {
    return (
      data?.vendorCode?.indexOf(a?.code) - data?.vendorCode?.indexOf(b?.code)
    );
  });

  const formData: any = {
    id: data?.id,
    documentMode:
      data?.status === RFQ_FORM_STATUS.OPEN
        ? DOCUMENT_MODE.EDIT
        : DOCUMENT_MODE.VIEW,
    rfqDocumentSequenceCode: data?.documentSequenceCode,
    sequenceFormat: data?.sequenceFormat,
    rfqDate: data?.rfqDate,
    validByDate: data?.validByDate,
    memo: data?.memo ?? '',
    contact: sequenceContact ?? data?.contact ?? [],
    email: data?.email ?? [],
    customField: data?.customField ?? [],
    attachments: data?.attachments,
    attachmentIds: data?.attachmentIds,
    vendorCode: data?.vendorCode,
    linkedPRDocuments: data?.linkedPRDocuments,
    linkedQuoteDocuments: data?.linkedQuoteDocuments,
    linkedPurchaseInwardQuotes: data?.linkedPurchaseInwardQuotes,
    status: data?.status,
    requestForQuotationItems:
      data?.requestForQuotationItems?.map((lineItem: any) => {
        return {
          id: lineItem?.id,
          productCode: lineItem?.productCode,
          linkedPRItemCode: lineItem?.linkedPRItemCode,
          uomQuantity: lineItem?.uomQuantity,
          documentUOMSchemaDefinition: lineItem?.documentUOMSchemaDefinition,
          product: lineItem?.product,
          documentSequenceCode: lineItem?.documentSequenceCode,
          productDescription: lineItem?.productDescription,
          productQuantity: Utility.isEmpty(
            lineItem?.documentUOMSchemaDefinition
          )
            ? lineItem?.productQuantity
            : lineItem?.uomQuantity,
          documentUom: lineItem?.documentUom,
          lineNumber: lineItem?.lineNumber,
          invalidFields: [],
          productOrder: lineItem?.productOrder,
          customField: lineItem?.customField ?? []
        };
      }) ?? []
  };

  return formData;
};

// Update this for user CF
export const addProductCustomFieldsToLineItem = (
  lineItem: any,
  product: any,
  productCustomFields: any
) => {
  let cfs: any[] = [];
  if (
    !Utility.isEmpty(productCustomFields) &&
    !Utility.isEmpty(product.customField)
  ) {
    // Set default values of CFs when new line is added
    product.customField.forEach((productCF: any) => {
      const filteredCF = productCustomFields?.find(
        (field: any) =>
          field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
      );
      if (filteredCF) {
        let cfToUpdate = {
          id: filteredCF.id,
          shortName: filteredCF.shortName,
          module: filteredCF.module,
          code: filteredCF.code,
          label: filteredCF.label,
          value: ''
        };
        let valueOfCF = '';
        const activeFilteredCFAttributes = filteredCF?.attributes?.filter(
          (attr: any) => attr?.status?.toUpperCase() === STATUS_TYPE.ACTIVE
        );
        if (
          typeof productCF.value !== 'undefined' &&
          productCF.value !== null &&
          productCF.value !== ''
        ) {
          if (
            filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            lineItem[productCF.id] = DateFormatService.getDateFromStr(
              productCF.value,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (filteredCF.fieldType.toLowerCase() === 'user') {
            const tempCF = activeFilteredCFAttributes?.find(
              (attr: any) => attr.code === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            const tempCF = activeFilteredCFAttributes?.find(
              (attr: any) => attr.value === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else {
            lineItem[productCF.id] = productCF.value;
          }
          valueOfCF = productCF.value;
        } else {
          lineItem[productCF.id] = '';
        }
        cfToUpdate.value = valueOfCF;
        cfs.push(cfToUpdate);
      }
    });
  }
  return { ...lineItem, customField: cfs };
};
