import { lazyWithPreload } from '../Managers/AppManager';

export const Product = lazyWithPreload(
  () => import('./../Components/Product/Product')
);

export const Contacts = lazyWithPreload(
  () => import('./../Components/Contacts/Contacts')
);

export const DPL_REPORT = lazyWithPreload(
  () => import('../Components/Reports/Financial/DplReport')
);

export const ContactDetail = lazyWithPreload(
  () => import('../Components/Contacts/Details')
);
export const Invoices = lazyWithPreload(
  () => import('./../Components/Invoices/Invoices')
);

export const Orders = lazyWithPreload(
  () => import('./../Components/PurchaseOrders/PurchaseOrders')
);

export const Requisitions = lazyWithPreload(
  () => import('../Components/Requisitions/Requisitions')
);

export const AssetGroup = lazyWithPreload(
  () => import('../Components/AssetGroup/AssetGroup')
);

export const FixedAsset = lazyWithPreload(
  () => import('../Components/FixedAsset/FixedAsset')
);

export const DepreciationSchedule = lazyWithPreload(
  () => import('../Components/DepreciationSchedule/DepreciationSchedule')
);

export const PublicQuotation = lazyWithPreload(
  () => import('../PublicPages/PublicQuotationForm')
);

export const SalesOrder = lazyWithPreload(
  () => import('../Components/SalesOrders/SalesOrders')
);

export const Quotations = lazyWithPreload(
  () => import('./../Components/Quotations/Quotations')
);

export const Bills = lazyWithPreload(
  () => import('./../Components/Bills/Bills')
);

export const Money = lazyWithPreload(
  () => import('./../Components/Money/Money')
);

export const Rfq = lazyWithPreload(
  () => import('../Components/RequestForQuotation/RequestForQuotation')
);

export const PurchaseInwardQuotation = lazyWithPreload(
  () => import('../Components/PurchaseInwardQuotation/PurchaseInwardQuotation')
);

export const OrganisationProfile = lazyWithPreload(
  () =>
    import('./../Components/Settings/OrganisationProfile/OrganisationProfile')
);

export const PaymentTerm = lazyWithPreload(
  () => import('./../Components/Settings/PaymentTerm/PaymentTerm')
);

export const Journal = lazyWithPreload(
  () => import('./../Components/Accounting/JournalEntry/Journal')
);
export const Deposits = lazyWithPreload(
  () => import('./../Components/Accounting/Deposits/Deposits')
);
export const Debit = lazyWithPreload(
  () => import('./../Components/Accounting/DebitNotes/DebitNotes')
);
export const CreditNotes = lazyWithPreload(
  () => import('./../Components/Accounting/CreditNotes/CreditNotes')
);
export const Coa = lazyWithPreload(
  () => import('./../Components/Accounting/COA/COA')
);
export const OpeningBalance = lazyWithPreload(
  () => import('./../Components/Accounting/OpeningBalance/OpeningBalance')
);
export const Warehouse = lazyWithPreload(
  () => import('./../Components/Warehouse/Warehouse')
);
export const PriceList = lazyWithPreload(
  () => import('./../Components/PriceList/PriceList')
);
export const PriceBookList = lazyWithPreload(
  () => import('./../Components/PriceBook/PriceBookList')
);
export const Bank = lazyWithPreload(() => import('./../Components/Bank/Bank'));
export const Settings = lazyWithPreload(
  () => import('./../Components/Settings/Settings')
);

export const Budget = lazyWithPreload(
  () => import('./../Components/Accounting/Budgeting/Budgeting')
);

export const ProfitAndLoss = lazyWithPreload(
  () => import('./../Components/Reports/Financial/ProfitAndLoss')
);

export const BRProfitAndLoss = lazyWithPreload(
  () => import('../Components/Reports/Financial/BRProfitAndLoss')
);
export const Rules = lazyWithPreload(
  () => import('./../Components/Bank/Rules')
);
export const Logs = lazyWithPreload(
  () => import('./../Components/Settings/Logs')
);

export const Currency = lazyWithPreload(
  () => import('./../Components/Settings/MultiCurrency/Currency')
);
export const Tax = lazyWithPreload(
  () => import('./../Components/Settings/Tax/Tax')
);
export const WithHoldTax = lazyWithPreload(
  () => import('../Components/Settings/WHT')
);
export const Uom = lazyWithPreload(
  () => import('./../Components/Settings/UOM/Uom')
);
export const Emails = lazyWithPreload(
  () => import('./../Components/Settings/Emails')
);
export const BankDetails = lazyWithPreload(
  () => import('./../Components/Bank/BankDetails/BankDetails')
);
export const RevRecJELogs = lazyWithPreload(
  () => import('./../Components/Settings/RevRecJELogs')
);
export const UomSchema = lazyWithPreload(
  () => import('./../Components/Settings/UOMSchema/UomSchema')
);
export const SequenceNumberFormat = lazyWithPreload(
  () =>
    import('./../Components/Settings/SequenceNumberFormat/SequenceNumberFormat')
);
export const Modules = lazyWithPreload(
  () => import('./../Components/Settings/Modules')
);

export const NegativeInventory = lazyWithPreload(
  () => import('./../Components/Settings/NegativeInventory')
);

export const CustomFieldsList = lazyWithPreload(
  () => import('./../Components/Settings/CustomFieldsList')
);

export const LocationsList = lazyWithPreload(
  () => import('./../Components/Settings/Locations/LocationsList')
);

export const AdvancedInventory = lazyWithPreload(
  () => import('./../Components/Settings/AdvancedInventory/AdvancedInventory')
);

export const CreditLimit = lazyWithPreload(
  () => import('./../Components/Settings/CreditLimit/CreditLimit')
);
export const ActiveDateRange = lazyWithPreload(
  () => import('./../Components/Settings/ActiveDateRange/ActiveDateRange')
);

export const BackDate = lazyWithPreload(
  () => import('./../Components/Settings/BackDatedSettings/BackDate')
);

export const ReconcileAccount = lazyWithPreload(
  () => import('./../Components/Bank/ReconcileAccount')
);
export const ProductDashboard = lazyWithPreload(
  () => import('./../Components/Product/ProductDashboard')
);
export const TDSSetup = lazyWithPreload(
  () => import('./../Components/Settings/TDSSetup/Tds')
);
export const TCSSetup = lazyWithPreload(
  () => import('./../Components/Settings/TCSSetup/Tcs')
);

export const DebitReport = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/DebitNote')
);
export const CreditReport = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/CreditNote')
);
export const InvoiceReport = lazyWithPreload(
  () => import('./../Components/Reports/Sales/Invoice')
);
export const Drafts = lazyWithPreload(
  () => import('./../Components/Drafts/Drafts')
);
export const Portal = lazyWithPreload(
  () => import('./../Components/ImportExport/import/index')
);

export const BankConnect = lazyWithPreload(
  () => import('./../Components/Bank/BankConnect')
);
export const Reports = lazyWithPreload(
  () => import('./../Components/Reports/Reports')
);
export const Expenses = lazyWithPreload(
  () => import('./../Components/Accounting/Expense/Expense')
);
export const FinancialYearClosings = lazyWithPreload(
  () =>
    import(
      './../Components/Accounting/FinancialYearClosing/FinancialYearClosing'
    )
);

export const StockMovement = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockMovement')
);
export const QuotationList = lazyWithPreload(
  () => import('./../Components/Reports/Sales/Quotation')
);
export const OutstandingSalesFullfillmentReport = lazyWithPreload(
  () =>
    import('./../Components/Reports/Sales/OutstandingSalesFulfillmentReport')
);
export const SalesByProduct = lazyWithPreload(
  () => import('./../Components/Reports/Sales/SalesByProduct')
);
export const FulfillmentReport = lazyWithPreload(
  () => import('./../Components/Reports/Sales/FulfillmentReport')
);
export const SalesOrderDetailReport = lazyWithPreload(
  () => import('./../Components/Reports/Sales/SalesOrderDetailReport')
);
export const PurchaseByProduct = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/PurchaseByProduct')
);
export const SalesByContact = lazyWithPreload(
  () => import('./../Components/Reports/Sales/SalesByContact')
);
export const PurchaseByContact = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/PurchaseByContact')
);
export const BalanceSheet = lazyWithPreload(
  () => import('./../Components/Reports/Financial/BalanceSheet')
);
export const BRBalanceSheet = lazyWithPreload(
  () => import('../Components/Reports/Financial/BRBalanceSheet')
);
export const ProfitAndLossDetails = lazyWithPreload(
  () => import('./../Components/Reports/Financial/ProfitAndLossDetails')
);
export const BalanceSheetDetails = lazyWithPreload(
  () => import('./../Components/Reports/Financial/BalanceSheetDetails')
);
export const TrialBalance = lazyWithPreload(
  () => import('./../Components/Reports/Financial/TrialBalance')
);
export const ReceiptList = lazyWithPreload(
  () => import('./../Components/Reports/Sales/ReceiptList')
);
export const PaymentList = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/PaymentList')
);
export const OutstandingPGR = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/OutstandingPGR')
);
export const StockWarehouseReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockWarehouseReport')
);
export const StockStatusReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockStatusReport')
);
export const ReorderLevelReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/ReorderLevelReport')
);
export const StockLedgerReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockLedgerReport')
);
export const Reservestock = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/ReservedStock')
);
export const GeneralLedger = lazyWithPreload(
  () => import('./../Components/Reports/Financial/GeneralLedger')
);
export const BRGeneralLedger = lazyWithPreload(
  () => import('../Components/Reports/Financial/BRGeneralLedger')
);
export const AgedReceivables = lazyWithPreload(
  () => import('./../Components/Reports/Sales/AgedReceivables/AgedReceivables')
);
export const SalesRateAnalysisReport = lazyWithPreload(
  () => import('../Components/Reports/Sales/RateAnalysisReport')
);
export const RevRecJE = lazyWithPreload(
  () => import('./../Components/Reports/RevRecJE/RevRecJE')
);
export const ReClassificationJe = lazyWithPreload(
  () => import('./../Components/Reports/RevRecJE/ReClassificationJE')
);
export const ProcessedReClassificationJe = lazyWithPreload(
  () => import('./../Components/Reports/RevRecJE/ProcessedReClassificationJE')
);
export const AgedPayables = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/AgedPayables/AgedPayables')
);
export const MilestoneTracker = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Purchase/PaymentMilestones/MilestoneTracker'
    )
);
export const GoodsReceivedNotes = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/GRN/GoodsReceivedNotes')
);
export const PurchaseOrderDetailReport = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/PurchaseOrderDetailReport')
);
export const StatementOfAccountCustomer = lazyWithPreload(
  () => import('./../Components/Reports/Financial/StatementOfAccountCustomer')
);
export const StatementOfAccountVendor = lazyWithPreload(
  () => import('./../Components/Reports/Financial/StatementOfAccountVendor')
);
export const Gstr1 = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR1')
);
export const B2BInvoice = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/Details')
);
export const CDNR = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/CDNR')
);
export const Exempt = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/EXEMPT')
);
export const ExemptDetails = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR1ExemptDetails')
);
export const Hsn = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/hsn')
);
export const B2CL = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/B2CL')
);
export const B2CS = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/B2CS')
);
export const CDNUR = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/CDNUR')
);
export const ExportInvoice = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/ExportInvoice')
);
export const AT = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/AT')
);
export const CashFlow = lazyWithPreload(
  () => import('./../Components/Reports/Financial/CashFlow')
);
export const ReceivableTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/Receivable')
);
export const ReceiptSummaryTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/ReceiptSummary')
);
export const CashflowTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/CashFlowTally')
);
export const SalesByClass = lazyWithPreload(
  () => import('./../Components/Reports/CustomReport/SalesByClass')
);
export const SalesByClassDetails = lazyWithPreload(
  () => import('../Components/Reports/CustomReport/SalesByClassDetail')
);
export const JobBriefReport = lazyWithPreload(
  () => import('../Components/Reports/CustomReport/JobBrief')
);

export const StockSummary2 = lazyWithPreload(
  () => import('./../Components/Reports/Tally/StockSummary2')
);
export const StockSummary3 = lazyWithPreload(
  () => import('./../Components/Reports/Tally/StockSummary3')
);
export const VendorSoaTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/VendorSOATally')
);

export const CustomerSoaTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/CustomerSOATally')
);

export const DetailedSalesReport = lazyWithPreload(
  () => import('./../Components/Reports/Tally/DetaliedSalesReport')
);
export const DayBookTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/DayBook')
);
export const StockSummaryTally = lazyWithPreload(
  () => import('./../Components/Reports/Tally/StockSummary')
);
export const PurchaseReturn = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/PurchaseReturn')
);
export const StockValuationReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockValuationNewReport')
);
export const SalesReturn = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/SalesReturn')
);
export const StockAgeing = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/StockAgeing')
);
export const TdsCustomer = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/TdsCustomer')
);
export const FailedInvoices = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/FailedInvoices')
);
export const ReconciliationLogReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/ReconciliationLogReport')
);
export const BankReconciliationReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/BankReconciliationReport')
);
export const Gstr2B = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR2B/GSTR2B')
);

export const Gstr2B_New = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR2B_New/GSTR2B')
);

export const Gstr2B_View_Summary = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR2B_New/MatchingSummary')
);

export const Gstr2BDownloadReport = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR2B/DownloadGSTR2BReport')
);
export const Gstr2BLinkDoc = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR2B/LinkDoc')
);
export const Gstr3B = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/Gstr3')
);
export const GstThreeOneA = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstOutwardSupply')
);
export const GstThreeOneB = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstThreeOneB')
);
export const GstThreeOneD = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstThreeOneD')
);
export const GstThreeTwoA = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstThreeTwoA')
);
export const GstThreeTwoB = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstThreeTwoB')
);
export const GstFourAOne = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourAOne')
);
export const GstFourATwo = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GSTFourATwo')
);
export const GstFourAThree = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourAThree')
);
export const GstFourAFive = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourAFive')
);
export const GstFourBOne = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourBOne')
);
export const BatchStatus = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/BatchStatus')
);
export const DocEmailed = lazyWithPreload(
  () => import('./../Components/Reports/Tally/DocumentsEmailed')
);
export const GstFourDOne = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourDOne')
);
export const GstFourDTwo = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourDTwo')
);
export const GstFiveTwo = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFiveTwo')
);
export const BomAssembly = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/BomAssembly')
);
export const GstFiveThree = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFiveThree')
);
export const GstFiveFour = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFiveFour')
);
export const GstFourBTwo = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GSTR3B/GstFourBTwo')
);
export const GstLogReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/GstLogReport')
);
export const GST5Form = lazyWithPreload(
  () => import('./../Components/Reports/Financial/GST5Form')
);
export const TDSDetails = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/TdsDetails')
);
export const TDSNotDeducted = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/TDSNotDeducted')
);
export const GST7Form = lazyWithPreload(
  () => import('./../Components/Reports/Financial/GST7Form')
);
export const SalesTaxReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/SalesTaxNew')
);
export const SalesTaxAgency = lazyWithPreload(
  () => import('./../Components/Reports/Sales/SalesTaxAgency')
);
export const BillList = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/BillList')
);
export const OrderList = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/OrderList')
);
export const DetailedPurchaseReport = lazyWithPreload(
  () => import('./../Components/Reports/Tally/DetailedPurchaseReport')
);

export const SalesRegisterSummary = lazyWithPreload(
  () => import('./../Components/Reports/Tally/SalesRegisterSummaryTally')
);

export const Accounting = lazyWithPreload(
  () => import('./../Components/Accounting/Accounting')
);

export const AutomationBuilder = lazyWithPreload(
  () => import('./../Components/Automation/AutomationBuilder')
);

export const CustomReportBuilder = lazyWithPreload(
  () => import('./../Components/ReportBuilder/ReportBuilder')
);

export const AdvancedSettings = lazyWithPreload(
  () => import('./../Components/Settings/AdvancedSettings/AdvancedSettings')
);

export const QCFlowSettings = lazyWithPreload(
  () => import('./../Components/Settings/QcFlow/QCFlow')
);

export const Automation = lazyWithPreload(
  () => import('./../Components/Automation/Automation')
);

export const Client = lazyWithPreload(
  () => import('./../Components/BookKeeper/Client/Client')
);

export const Team = lazyWithPreload(
  () => import('./../Components/BookKeeper/Team/Team')
);

export const PickPackShip = lazyWithPreload(
  () => import('./../Components/PickPackShip/PickPackShipComponent')
);

export const Agencies = lazyWithPreload(
  () => import('./../Components/Settings/Agencies/Agencies')
);

export const Prepare1099 = lazyWithPreload(
  () => import('./../Components/Accounting/Expense/Prepare1099')
);

export const Classes = lazyWithPreload(
  () => import('./../Components/Settings/Classes/Classes')
);

export const FilterAccountsSetting = lazyWithPreload(
  () => import('./../Components/Settings/FilterAccountsSetting')
);

export const QualityCheck = lazyWithPreload(
  () => import('../Components/QualityCheck/QualityCheck')
);

export const GstReturn = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/GstReturn')
);

export const SalesTaxReturn = lazyWithPreload(
  () => import('./../Components/Reports/Malaysia/SalesTaxReturn')
);

export const ServiceTaxReturn = lazyWithPreload(
  () => import('./../Components/Reports/Malaysia/ServiceTaxReturn')
);

export const GSTR1ForReturn = lazyWithPreload(
  () => import('./../Components/Reports/IndiaGst/Gstr1ForReturn')
);

export const PrintChecks = lazyWithPreload(
  () => import('./../Components/PrintChecks/CheckList')
);

export const TrialBalanceDetail = lazyWithPreload(
  () => import('./../Components/Reports/Financial/TrialBalanceDetail')
);

export const ItemLandedCost = lazyWithPreload(
  () => import('./../Components/Settings/ItemLandedCost/ItemLandedCost')
);

export const ItemLandedCostReport = lazyWithPreload(
  () => import('./../Components/Reports/Purchase/ItemLandedCostReport')
);

export const EWayBillReport = lazyWithPreload(
  () => import('./../Components/EwayBill/EWayBillReport')
);

export const ConsolidatedFinancialReport = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Financial/ConsolidatedFinancialReport/ConsolidatedFinancialReport'
    )
);
export const ConsolidatedFinancialReportList = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Financial/ConsolidatedFinancialReport/ConsolidatedFinancialReportsList'
    )
);

export const SST02Report = lazyWithPreload(
  () => import('./../Components/Reports/Malaysia/SST02Report')
);
export const BatchSerialLedgerReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/BatchSerialLedger')
);

//mrp
export const Operations = lazyWithPreload(
  () => import('./../Components/Mrp/Operations/Operations')
);

export const MachineMaster = lazyWithPreload(
  () => import('./../Components/Mrp/MachineMaster/MachineMaster')
);

export const MachineScheduleCalender = lazyWithPreload(
  () => import('../Components/Mrp/MachineMaster/MachineScheduling')
);

export const AssetMaintenanceSchedule = lazyWithPreload(
  () => import('../Components/Mrp/MachineMaster/MachineMaintenanceSchedule')
);
export const WorkStations = lazyWithPreload(
  () => import('./../Components/Mrp/Work stations/WorkStations')
);

export const MRPProducts = lazyWithPreload(
  () => import('../Components/Mrp/BOM_Assembly/MRPProducts')
);

export const MRPProductionPlan = lazyWithPreload(
  () => import('../Components/Mrp/ProductionPlan/ProductionPlan')
);

export const MRPHolidayList = lazyWithPreload(
  () => import('../Components/Mrp/HolidayList/HolidayList')
);

export const MRPWorkOrder = lazyWithPreload(
  () => import('../Components/Mrp/WorkOrder/WorkOrder')
);
export const MRPDashboard = lazyWithPreload(
  () => import('../Components/Mrp/Dashboard')
);

export const MRPJobCard = lazyWithPreload(
  () => import('../Components/Mrp/JobCard/JobCard')
);

export const MRPLocations = lazyWithPreload(
  () => import('../Components/Mrp/Settings/Locations')
);

export const MRPQualityCheck = lazyWithPreload(
  () => import('../Components/Mrp/QualityCheck/MRPQualityCheck')
);

export const MRPScheduling = lazyWithPreload(
  () => import('../Components/Mrp/Scheduling/Scheduling')
);

export const MRPWorkOut = lazyWithPreload(
  () => import('../Components/Mrp/Workout/Workout')
);

export const MRPForecast = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/Forecast')
);

export const MRPForecastDetails = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/ForecastDetails')
);

export const MRPForecastDetailsNew = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/ForecastDetailsNew')
);
export const MRPMasterForecastDetails = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/MasterForecastDetails')
);

export const MRPMasterForecast = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/MasterForecast')
);

export const MRPAddMasterForeCast = lazyWithPreload(
  () => import('../Components/Mrp/Forecast/AddMasterForecast')
);

export const MRPOperatorList = lazyWithPreload(
  () => import('../Components/Mrp/OperatorList/OperatorList')
);

export const MRPReports = lazyWithPreload(
  () => import('../Components/Mrp/Reports/MRPReports')
);
export const MRPRoutingTemplates = lazyWithPreload(
  () => import('../Components/Mrp/RoutingTemplates/RoutingTemplates')
);
export const ProductionSummary = lazyWithPreload(
  () => import('../Components/Mrp/Reports/ProductionSummary')
);
export const SKUWiseOrderTracking = lazyWithPreload(
  () =>
    import('../Components/Mrp/Reports/SKUOrderTrackingReport/SKUOrderTracking')
);

export const ProductionReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/ProductionReport')
);
export const FGComponentTrackerReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/FGComponentTrackerReport')
);
export const WOShortFallSummary = lazyWithPreload(
  () => import('../Components/Mrp/Reports/WOShortFallSummary')
);
export const WOShortFallDetail = lazyWithPreload(
  () => import('../Components/Mrp/Reports/WOShortFallDetailed')
);
export const binAllocationReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/BinAllocationReport')
);
export const ProductionCostingReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/ProductionCostingReoprt')
);

export const DailyConsumptionReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/DailyConsumptionReport')
);

export const DailyConsumptionSummaryReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/DailyConsumptionSummaryReport')
);

export const YieldSummaryReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/YieldSummaryReport')
);

export const YieldDetailedSummaryReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/YieldDetailedSummaryReport')
);

export const StockAdjustment = lazyWithPreload(
  () =>
    import(
      './../Components/StockManagement/StockAdjustment/StockAdjustmentMaster'
    )
);
export const StockRequest = lazyWithPreload(
  () =>
    import('./../Components/StockManagement/StockRequest/StockRequestMaster')
);

export const StockTransfer = lazyWithPreload(
  () =>
    import('./../Components/StockManagement/StockTransfer/StockTransferMaster')
);
export const StockIssue = lazyWithPreload(
  () => import('./../Components/StockManagement/StockIssue/StockIssue')
);

export const TabletDashboard = lazyWithPreload(
  () => import('../Components/TabletDashboard/TabletAppScreen')
);

export const MultiCompanyConsolidatedFinancialReport = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Financial/MultiCompanyConsolidatedFinancialReport/MultiCompanyConsolidatedFinancialReport'
    )
);

export const MultiCompanyConsolidatedFinancialReportList = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Financial/MultiCompanyConsolidatedFinancialReport/MultiCompanyConsolidatedFinancialReportsList'
    )
);

export const RevenueCategory = lazyWithPreload(
  () => import('../Components/Settings/RevenueCategoryList')
);

export const RevRecDeferredRevenueByItem = lazyWithPreload(
  () => import('../Components/Reports/RevRecDeferredRevenueByItem')
);
export const RevRecDeferredRevenueByCustomer = lazyWithPreload(
  () => import('../Components/Reports/RevRecDeferredRevByCustomer')
);

export const FairValue = lazyWithPreload(
  () => import('../Components/Settings/FairValueFormulae')
);
export const Revaluation = lazyWithPreload(
  () => import('./../Components/Reports/Financial/Revaluation')
);
export const RevRecWaterfall = lazyWithPreload(
  () => import('../Components/Reports/RevRecWaterfall')
);
export const RevenueRecognition = lazyWithPreload(
  () => import('../Components/Settings/RevenueRecognition')
);
export const InterCompanyTransactionReport = lazyWithPreload(
  () => import('../Components/Reports/Financial/InterCompanyTransaction')
);

export const VatReturn = lazyWithPreload(
  () => import('./../Components/Reports/UK/VatReturn')
);

export const VatReturnDetail = lazyWithPreload(
  () => import('./../Components/Reports/UK/VatReturnDetail')
);

export const RevenueArrangmentJob = lazyWithPreload(
  () => import('../Components/RevenueArrangment/RevenueArrangmentJob')
);

export const UpdateRevenueArrangment = lazyWithPreload(
  () => import('../Components/RevenueArrangment/UpdateRevenueArrangment')
);

export const AmortizationTemplates = lazyWithPreload(
  () => import('../Components/Amortization/AmortizationTemplates')
);

export const AmortizationSchedules = lazyWithPreload(
  () => import('../Components/Amortization/AmortizationSchedules')
);

export const AmortizationJe = lazyWithPreload(
  () => import('../Components/Amortization/AmortizationJe')
);

export const RevenueRecognitionSetup = lazyWithPreload(
  () =>
    import(
      '../Components/Settings/RevenueRecognition/RevenueRecognitionSettings'
    )
);

export const RevenueArrangment = lazyWithPreload(
  () => import('../Components/RevenueArrangment/RevenueArrangment')
);

export const ExpenseAllocationTemplates = lazyWithPreload(
  () => import('../Components/ExpenseAllocation/ExpenseAllocationTemplates')
);

export const forecastReport = lazyWithPreload(
  () => import('../Components/Reports/Amortization/AmortizationForecast')
);

export const deferredExpenseWaterfallReport = lazyWithPreload(
  () =>
    import('../Components/Reports/Amortization/DeferredExpenseWaterfallReport')
);

export const deferredExpenseWaterfallDetailsReport = lazyWithPreload(
  () => import('../Components/ExpenseAllocation/ExpenseAllocationTemplates')
);

export const deferredExpenseRollforwardSummaryReport = lazyWithPreload(
  () =>
    import(
      '../Components/Reports/Amortization/DeferredExpenseRollForwardSummaryReport'
    )
);

export const PrintTemplate = lazyWithPreload(
  () => import('./../Components/PrintTemplate/PrintTemplate')
);

export const LabelTemplate = lazyWithPreload(
  () => import('./../Components/LabelTemplate/LabelTemplate')
);
export const QualityCheckSummary = lazyWithPreload(
  () => import('./../Components/Reports/QualityCheck/QualityCheckSummary')
);
export const QualityDetailed = lazyWithPreload(
  () => import('./../Components/Reports/QualityCheck/QualityCheckDetails')
);
export const productwiseQC = lazyWithPreload(
  () => import('./../Components/Reports/QualityCheck/ProductwiseQC')
);
export const vendorwiseQC = lazyWithPreload(
  () => import('./../Components/Reports/QualityCheck/VendorwiseQC')
);
export const PNLByDepartmentReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/ProfitAndLossByDepartment')
);

export const PNLByDepartmentReportDetails = lazyWithPreload(
  () =>
    import('./../Components/Reports/Financial/ProfitAndLossByDepartmentDetails')
);

export const TrialBalanceByDepartmentReport = lazyWithPreload(
  () => import('./../Components/Reports/Financial/TrialBalanceByDepartment')
);

export const BomReport = lazyWithPreload(
  () => import('./../Components/Reports/Inventory/BomReport')
);

export const ValuationByWarehouse = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Sales/ValuationByWarehouse/ValuationByWarehouse'
    )
);

export const operationwiseSummary = lazyWithPreload(
  () => import('./../Components/Mrp/Reports/OperationWiseSummary')
);
export const woWiseOperationDetails = lazyWithPreload(
  () => import('./../Components/Mrp/Reports/WOWiseOperationDetails')
);

export const ValuationByWarehouseDetails = lazyWithPreload(
  () =>
    import(
      './../Components/Reports/Sales/ValuationByWarehouse/ValuationByWarehouseDetails'
    )
);

export const sOToWOReports = lazyWithPreload(
  () => import('./../Components/Mrp/Reports/SOtoWOReports')
);
export const restockingLevelsReport = lazyWithPreload(
  () => import('./../Components/Mrp/Reports/RestockingLevelsReport')
);
export const jobWorkoutReport = lazyWithPreload(
  () => import('./../Components/Mrp/Reports/JobWorkoutReport')
);
export const GoodsReceiptModule = lazyWithPreload(
  () => import('../Components/GoodsReceipt/GoodsReceipt')
);

export const fulfillmentModule = lazyWithPreload(
  () => import('../Components/Fulfillment/Fulfillment')
);
export const GoodsReceptToleranceLevelReport = lazyWithPreload(
  () =>
    import('./../Components/Reports/Purchase/GoodsReceptToleranceLevelReport')
);
export const FulfillmentToleranceLevelReport = lazyWithPreload(
  () => import('./../Components/Reports/Sales/FulfillmentToleranceLevelReport')
);
export const ToleranceLevelRejectionStockReport = lazyWithPreload(
  () => import(
      './../Components/Reports/Financial/ToleranceLevelRejectionStockReport'
  )
);
export const OrderLevelReport = lazyWithPreload(
  () => import('./../Components/Reports/Sales/OrderLevelReport')
);

export const SECURITY_GATE_ENTRY = lazyWithPreload(
  () => import('./../Components/SecurityGateEntry/SecurityGateEntry')
);
export const BankBookReportRoute = lazyWithPreload(
  () => import('./../Components/Reports/Financial/BankBookReport')
);

export const GenerateBankFileInvoice = lazyWithPreload(
  () => import('../Components/BankFileGeneration/GenerateBankFileInvoice')
);
export const GenerateBankFileBill = lazyWithPreload(
  () => import('../Components/BankFileGeneration/GenerateBankFileBill')
);

export const PurchaseQuotationComparisonReport = lazyWithPreload(
  () =>
    import('./../Components/Reports/Purchase/PurchaseQuotationComparisonReport')
);
export const forecastDrivenPoReport = lazyWithPreload(
  () => import('../Components/Mrp/Reports/ForecastDrivenPOReport')
);
