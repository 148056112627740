import {
  DKButton,
  showToast,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  TOAST_TYPE,
  DKSpinner
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import Utility from '../../../Utility/Utility';

const FTPConfigurationPopup = (props: any) => {
  const FORM_CONFIGS = [
    {
      id: 'host',
      key: 'host',
      name: 'Host',
      type: INPUT_TYPE.TEXT,
      canValidate: false,
      readOnly: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.host || ``
    },
    {
      id: 'port',
      key: 'port',
      name: 'Port',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.port || ``
    },
    {
      id: 'username',
      key: 'username',
      name: 'User Name',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.username || ``
    },
    {
      id: 'bankfileSftpKey',
      key: 'bankfileSftpKey',
      name: 'Private Key',
      type: INPUT_TYPE.FILE,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'web-width-48',
      value: props?.bankFileData?.bankfileSftpKey
    },
    {
      id: 'targetPath',
      key: 'targetPath',
      name: 'Target Path',
      type: INPUT_TYPE.TEXT,
      readOnly: false,
      canValidate: false,
      width: '',
      className: 'parent-width',
      value: props?.bankFileData?.targetPath || ``
    }
  ];

  const [formConfig, setFormConfig] = useState(FORM_CONFIGS);
  const [saveApiLoading, setSaveApiLoading] = useState(false);
  const fileUploadRef = useRef<any>();

  useEffect(() => {
    if (props.bankFileData?.bankfileKeyName && fileUploadRef?.current) {
      const dataTransfer = new DataTransfer();
      const blob = new Blob(["dummy_data"], { type: "text/plain" });
      dataTransfer.items.add(new File([blob], props.bankFileData.bankfileKeyName, { type: "text/plain" }));
      fileUploadRef.current.files = dataTransfer.files;
    }
  }, [])

  const validateFormData = () => {
    let isValid = true;
    const newConfig = [...formConfig]
    newConfig.forEach((form: any) => {
      if (form.key === "bankfileSftpKey") {
        if (!(form.value instanceof Object) && !props.bankFileData?.bankfileKeyName) {
          isValid = false;
          form.canValidate = true;
        }
      } else {
        if (!form.value) {
          isValid = false;
          form.canValidate = true;
        }
      }
    });

    return { isValid, newConfig };
  };

  const onSave = () => {
    const formData: any = {}
    const { isValid, newConfig } = validateFormData();
    if (isValid) {
      newConfig.forEach((form: any) => {
        if (form.key === "bankfileSftpKey") {
          if (form.value instanceof Object) {
            formData["bankfileKeyName"] = form.value.name;
            formData["bankfileSftpKey"] = form.value.data;
          } else {
            formData["bankfileKeyName"] = props.bankFileData?.bankfileKeyName ?? "";
            formData["bankfileSftpKey"] = "";
          }
        } else {
          formData[form.key] = form.value;
        }
      })
      setSaveApiLoading(true);
      props.onUpdate(formData)
    } else {
      setFormConfig(newConfig);
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`FTP Configuration`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <div className={`row border-radius-m ${saveApiLoading ? 'bg-gray1 border-m' : 'bg-button text-white'}`}>
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getForm = () => {
    return (
      <div className="row row-responsive flex-wrap justify-content-between gap-4">
        {formConfig?.map((item: any, index: any) => {
          return (
            <div className={item.className || ''}>
              {getInputField(item, index)}
            </div>
          );
        })}
      </div>
    );
  };

  const onChange = (text: any, obj: any, index: any, isBlur: any): void => {
    const newConfig = [...formConfig];
    newConfig[index] = {
      ...newConfig[index],
      value: isBlur ? text.trim() : text,
      canValidate: true
    };
    setFormConfig(newConfig);
  }

  const onFileUpload = (event: any, obj: any, index: number) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > (100 * 1024)) {
        showToast("File size can not be greater than 100 KB", TOAST_TYPE.WARNING);
        return
      }

      const reader = new FileReader();
      reader.onload = function (e) {
        const fileObj = {
          name: file.name,
          data: Utility.encodeString(reader.result as string)
        }
        onChange(fileObj, obj, index, false);
      }

      reader.onerror = (e) => {
        showToast("Failed to read the file", TOAST_TYPE.ERROR);
        return
      }

      reader.readAsText(file);
    }
  }

  const getInputField = (obj: any, index: any) => {
    if (obj.type === INPUT_TYPE.FILE) {
      return (
        <div>
          <DKLabel text={`${obj.name} *`} className="mb-xs" />
          <input
            ref={fileUploadRef}
            className="parent-width text-gray"
            type="file"
            accept=".pem, .ppk"
            onChange={(event: any) => {
              onFileUpload(event, obj, index)
            }}
          />
          {obj.canValidate && !obj.value &&
            <div className='text-red ml-xs mt-xs position-absolute' style={{ fontSize: "11px" }}>
              {`Upload a valid ${String(obj.name).toLowerCase()}`}
            </div>
          }
        </div>
      )
    } else {
      return (
        <DKInput
          title={obj.name || ``}
          placeholder={obj?.name ?? ''}
          type={obj.type}
          required={true}
          readOnly={obj?.readOnly}
          canValidate={obj?.canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={obj.value || ''}
          className={obj.className || ''}
          onChange={(text: any) => {
            onChange(text, obj, index, false)
          }}
          onBlur={(event: any) => {
            onChange(event.target.value, obj, index, true)
          }}
        />
      );
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 520,
          width: '90%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-l p-v-m pb-xl ">
          {getForm()}
        </div>
      </div>
    </div>
  );
};

export default FTPConfigurationPopup;
