import { DKLabel, DKButton } from 'deskera-ui-library';
import { useEffect } from 'react';
import { useState } from 'react';
export default function EWBErrorLogs(props: any) {
  const [logs, setLogs] = useState(props.errorLogs);

  useEffect(() => {
    setLogs(props.errorLogs);
  }, [props.errorLogs]);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Error Logs" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={props.onCancel}
          />
        </div>
      </div>
    );
  };

  const getRecords = () => {
    return (
      <div className="column parent-width rounded p-4">
        <div className="row bg-gray1 p-2 border">
          <DKLabel className="w-2/4" text="Error Code" />
          <DKLabel className="w-2/4" text="Error Message" />
        </div>
        {logs?.map((item: any) => (
          <div className="row parent-width p-2 border">
            <DKLabel className="w-2/4" text={item.errorCode} />
            <DKLabel className="w-2/4" text={item.errorMessage} />
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 500,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 10,
          overflowY: 'hidden'
        }}
      >
        <div className="column parent-width">
          {getHeader()}
          {getRecords()}
        </div>
      </div>
    </div>
  );
}
