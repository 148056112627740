import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { map } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import ic_bom_allocate_green from '../../../Assets/Icons/ic_bom_allocate_green.svg';
import ic_bom_allocate_red from '../../../Assets/Icons/ic_bom_allocate_red.svg';
import ic_expand from '../../../Assets/Icons/ic_expand.svg';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import ic_shrink from '../../../Assets/Icons/ic_shrink.svg';
import {
  DOC_TYPE,
  MODULE_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { StockTransfer } from '../../../Models/StockTransfer';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  selectShowMainDocsInFullScreen,
  selectWarehouse,
  setShowMainDocsInFullScreen
} from '../../../Redux/Slices/CommonDataSlice';
import { fetchStockTransfers } from '../../../Redux/Slices/StockTransferSlice';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import Utility from '../../../Utility/Utility';

import AllocateJWOSerialBatchProduct from './AllocateJWOSerialBatchProduct';
import JWOStockTransferAdvancedBatchTrackingPopup from './JWOStockTransferAdvancedBatchTrackingPopup';
import ProductService from '../../../Services/Product';
import { t } from 'i18next';

export default function ReceiveJWOProduct(props: any) {
  const dispatch = useAppDispatch();

  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [warehouseProduct, setWarehouseProduct] = useState<any>({});
  const [showWarehouseManagement, setShowWarehouseManagement] = useState(false);
  const [rowBeingEdited, setRowBeingEdited] = useState<any>(null);
  const [showBatchTransferPopup, setShowBatchTransferPopup] = useState(false);
  const [showSerialTrackingPopup, setShowSerialTrackingPopup] = useState(false);
  const [showJWOSerialTrackingPopup, setShowJWOSerialTrackingPopup] =
    useState(false);
  const [stockTransferState, setStockTransferState] = useState<StockTransfer>(
    props.detail
  );
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const [selectedWarehouseProductsData, setSelectedWarehouseProductsData] =
    useState([]);
  const [productGridData, setProductGridData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState<any>();

  const isEdit = useRef<boolean>(false);

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [productBrief, setProductBrief] = useState([]);
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );

  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;
  const localColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 250
    },
    {
      id: 'documentSequenceCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Available Qty',
      index: 0,
      textAlign: 'right',
      options: null,
      required: false,
      editable: false,
      hidden: true,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableQuantity',
      id: 'availableQuantity',
      datasource: [],
      key: 'availableQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 225,
      formatter: (obj: any = 0) => {
        return `${Number(obj.value).toFixed(tenantInfo?.decimalScale)}`;
      }
    },
    {
      name: 'Allocated Qty',
      index: 0,
      textAlign: 'right',
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'allocatedQuantity',
      id: 'allocatedQuantity',
      datasource: [],
      key: 'allocatedQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 225,
      formatter: (obj: any = 0) => {
        return `${Number(obj.value).toFixed(tenantInfo?.decimalScale)}`;
      }
    }
  ];
  useEffect(() => {
    dispatch(fetchStockTransfers());
    getProductDetails(); //
  }, []);
  useEffect(() => {
    if (props.detail.product.bomProductsConfiguration && productBrief) {
      setProductGridData(
        getRowData(
          Utility.filterBatchOrSerialAdvanceTracking([
            ...props.detail.product.bomProductsConfiguration
          ])
        )
      );
    }
  }, [productBrief, props.detail]);

  const getProductDetails = () => {
    if (props.detail?.product?.bomProductsConfiguration) {
      const productIds = props.detail?.product?.bomProductsConfiguration.map(
        (bomProduct: any) => {
          return bomProduct.productCode;
        }
      );
      ProductService.getProductsByProductIds(
        [...productIds],
        false,
        false
      ).then((res) => {
        setProductBrief(res); //
      });
    }
  };
  const isProductBatchOrSerial = (row: any) => {
    return (
      row.advancedTracking === TRACKING_TYPE.BATCH ||
      row.advancedTracking === TRACKING_TYPE.SERIAL
    );
  };
  const getRowData = (rowData: any) => {
    rowData.forEach((row: any) => {
      row['requiredQuantity'] = row.requiredQuantity;
      row['availableQuantity'] = row.quantity;
      row['product'] = row.itemName;
      row['allocatedQuantity'] =
        (isProductBatchOrSerial(row)
          ? row.allocatedQuantity
          : row.requiredQuantity) || 0;
      row['serialData'] = row?.serialData || [];
      row['rowButtons'] = getButtonsForRow(row);
    });
    return rowData;
  };

  const getTrackingIconDetail = (row: any) => {
    let icon = ic_no_data;
    let disabled = false;
    if (Utility.isNotEmpty(props?.selectedRecord)) {
      return { icon, disabled, readOnly: true };
    }
    if (
      Utility.isNotEmpty(row?.stockTransferWarehouseInventoryData) &&
      row.requiredQuantity === row?.allocatedQuantity
    ) {
      icon = ic_bom_allocate_green;
      disabled = true;
    } else {
      icon = ic_bom_allocate_red;
      disabled = false;
    }
    return { icon, disabled, readOnly: false };
  };

  const isValidAllocation = () => {
    let isValid = true;
    let newProductGridData = [...productGridData];
    newProductGridData.forEach((row: any) => {
      if (
        row.requiredQuantity !== row.allocatedQuantity ||
        row.allocatedQuantity === 0
      ) {
        row['invalidFields'] = ['allocatedQuantity'];
        isValid = false;
      } else {
        row['invalidFields'] = [];
      }
    });
    setProductGridData(newProductGridData);
    return isValid;
  };
  const saveStockTransfer = () => {
    if (isValidAllocation()) {
      props.onSave(productGridData);
    }
  };

  const getProductDetail = (productId: any) => {
    let productDetail: any = null;
    if (productBrief && productId) {
      productDetail = productBrief.find(
        (product: any) => product.documentSequenceCode === productId
      );
    }
    return productDetail;
  };

  const buttonAction = (rowData: any, index: any) => {
    if (Utility.isEmpty(rowData?.product)) {
      showAlert('Error', t('JOB_WORK_OUT.PRODUCT_SEL_ERR'));
      return;
    }
    const productDetail = getProductDetail(rowData.documentSequenceCode);
    const trackingType = productDetail?.advancedTracking;
    setCurrentIndex(index);
    if (
      trackingType !== TRACKING_TYPE.BATCH &&
      trackingType !== TRACKING_TYPE.SERIAL
    ) {
      setWarehouseProduct({
        ...rowData.product,
        requiredQuantity: Utility.roundingOff(
          Number(rowData?.requiredQuantity),
          QTY_ROUNDOFF_PRECISION
        )
      });
      setShowWarehouseManagement(true);
    } else if (trackingType === TRACKING_TYPE.BATCH) {
      setShowBatchTransferPopup(true);
      setStockTransferState({
        ...stockTransferState,
        stockTransferItems: Utility.filterBatchOrSerialAdvanceTracking(
          props.detail?.product?.bomProductsConfiguration || []
        ),
        stockTransferDocumentSeqCodes:
          props.detail?.stockTransferDocumentSeqCodes || []
      });
      setWarehouseProduct({
        ...rowData,
        stockTransferItems: Utility.filterBatchOrSerialAdvanceTracking(
          props.detail?.product?.bomProductsConfiguration || []
        ),
        stockTransferDocumentSeqCodes:
          props.detail?.stockTransferDocumentSeqCodes || [],
        requiredQuantity: Utility.roundingOff(
          Number(rowData?.requiredQuantity),
          QTY_ROUNDOFF_PRECISION
        )
      });
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      if (props.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        setStockTransferState({
          ...stockTransferState,
          stockTransferItems: Utility.filterBatchOrSerialAdvanceTracking(
            props.detail?.product?.bomProductsConfiguration || []
          ),
          stockTransferDocumentSeqCodes:
            props.detail?.stockTransferDocumentSeqCodes || []
        });
        setShowJWOSerialTrackingPopup(true);
      } else {
        setShowSerialTrackingPopup(true);
      }
      setWarehouseProduct({
        ...productDetail,
        requiredQuantity: rowData?.requiredQuantity
      });
    }
  };

  const getButtonsForRow = (data: any) => {
    let buttons: any[] = [];
    const { icon, disabled, readOnly } = getTrackingIconDetail(data);
    if (isProductBatchOrSerial(data)) {
      buttons.push({
        icon,
        disabled,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          if (!readOnly) {
            buttonAction(rowData, rowIndex);
          } else {
            setRowBeingEdited(rowData);
          }
        }
      });
    }
    const allowRowDelete = props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER;
    if (!isEdit?.current && allowRowDelete) {
      buttons.push({
        icon: DKIcons.ic_delete,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          let copyOfStateTransferState = { ...stockTransferState };
          copyOfStateTransferState.stockTransferItems.splice(rowIndex, 1);
          setStockTransferState(copyOfStateTransferState);
        }
      });
    }

    return buttons;
  };

  const warehousesData: any = useAppSelector(selectWarehouse);
  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={apiCallInProgress}
        className="border-m mr-r"
      />
    );
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle gap-2">
          <DKLabel
            text={t('JOB_WORK_OUT.ALLOCATE_JWO_RAW_PRODUCT')}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          {props.allowFullScreen && getShrinkExpandButton()}
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div
            className={`row border-radius-m ${
              apiCallInProgress
                ? ' bg-gray1 text-gray border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={apiCallInProgress ? 'Saving' : 'Save'}
              onClick={() => {
                !apiCallInProgress && saveStockTransfer();
              }}
            />
            {apiCallInProgress && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const onBatchSerialTrackSave = (trackingData: any, type: TRACKING_TYPE) => {
    // after serial mapping popup close
    let tmpStockTransferState = { ...stockTransferState }; //
    tmpStockTransferState.stockTransferItems.forEach(
      (item: any, index: any) => {
        if (
          index === currentIndex &&
          item.documentSequenceCode ===
            trackingData['stockTransferWarehouseInventoryData'].productCode
        ) {
          item['stockTransferWarehouseInventoryData'] =
            trackingData['stockTransferWarehouseInventoryData'];
          item['serialData'] = trackingData['serialData'];
          item['rowButtons'] = getButtonsForRow(item);
        }
      }
    );
    let newProductGridData = [...productGridData] || [];
    newProductGridData?.forEach((item: any, index: any) => {
      if (
        index === currentIndex &&
        item.documentSequenceCode ===
          trackingData['stockTransferWarehouseInventoryData'].productCode
      ) {
        item['stockTransferWarehouseInventoryData'] =
          trackingData['stockTransferWarehouseInventoryData'];
        item['allocatedQuantity'] =
          type === TRACKING_TYPE.BATCH
            ? trackingData['stockTransferWarehouseInventoryData']?.quantity || 0
            : trackingData['stockTransferWarehouseInventoryData']
                ?.advancedTrackingData?.length || 0;
        item['serialData'] = trackingData['serialData'];
        if (
          item.requiredQuantity !== item.allocatedQuantity ||
          item.allocatedQuantity === 0
        ) {
          item['invalidFields'] = ['allocatedQuantity'];
        } else {
          item['invalidFields'] = [];
        }
        item['rowButtons'] = getButtonsForRow(item);
      }
    });

    setProductGridData(getRowData([...newProductGridData]));
    setStockTransferState(tmpStockTransferState);
    if (type === TRACKING_TYPE.BATCH) {
      setShowBatchTransferPopup(false);
    } else {
      if (type === TRACKING_TYPE.SERIAL) {
        if (props.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          setShowJWOSerialTrackingPopup(false);
        } else {
          setShowSerialTrackingPopup(false);
        }
      }
    }
  };

  const rowEdit = (data: any) => {};

  const uomFormatter = (obj: any) => {
    const value = obj.rowData?.documentUOMSchemaDefinition;
    let isUomSchema =
      obj.rowData?.documentUOMSchemaDefinition?.sourceUOM &&
      obj.rowData?.documentUOMSchemaDefinition?.sinkUOM;
    if (obj?.rowData?.requiredQuantity && isUomSchema) {
      return `${
        DocumentConfigUtility.getBaseUomName(value)?.name
      } <br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
        value?.sourceConversionFactor
      } ${DocumentConfigUtility.getBaseUomName(value)?.name} = ${
        value?.sinkConversionFactor
      } ${value.name}</span>`;
    } else {
      return value?.sourceUOM
        ? DocumentConfigUtility.getBaseUomName(value)?.name
        : value?.name;
    }
  };

  const getDataGridColumns = () => {
    let columns: any = [...localColumnConfig];
    let availableQuantityColumnIndex = columns.findIndex(
      (column: any) => column.columnCode === 'availableQuantity'
    );

    if (availableQuantityColumnIndex > -1) {
      columns[availableQuantityColumnIndex].width = 170;
    }

    columns.splice(1, 0, {
      name: 'Required Qty',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'requiredQuantity',
      id: 'requiredQuantity',
      datasource: [],
      key: 'requiredQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 170,
      formatter: (obj: any = 0) => {
        return `${Utility.roundingOff(obj.value, QTY_ROUNDOFF_PRECISION)}`;
      }
    });

    columns.splice(2, 0, {
      name: 'UOM',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: true,
      uiVisible: true,
      systemField: true,
      columnCode: 'uom',
      id: 'uom',
      datasource: [],
      key: 'uom',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 170,
      formatter: uomFormatter
    });

    columns.push({
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      options: [],
      width: 180
    });
    columns = columns.filter((col: any) => !col.hidden);
    return columns;
  };

  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          styles={{
            mainGridHolder: { maxHeight: 320, paddingBottom: 0 },
            shadowHolder: { maxHeight: 300 }
          }}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={!Utility.isEmpty(props.selectedRecord) ? false : true}
          columns={getDataGridColumns()}
          rows={productGridData}
          onRowUpdate={rowEdit}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowColumnShift={false}
          allowBulkOperation={false}
        />
      </>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: isFullScreenMode ? '100%' : 750,
            width: isFullScreenMode ? '100%' : '90%',
            maxHeight: isFullScreenMode ? '100%' : '50%',
            height: isFullScreenMode ? '100%' : '50%',
            padding: 0,
            paddingBottom: 30,
            borderRadius: isFullScreenMode ? 0 : 4
          }}
        >
          {getHeader()}
          <div
            className="column parent-width parent-height p-r"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="m-v-r parent-width">
                <div className="m-v-r parent-width">{getLineItemGrid()}</div>
              </div>
            </div>
            {/* batch product  */}
            <div>
              {showBatchTransferPopup && (
                <JWOStockTransferAdvancedBatchTrackingPopup
                  selectedWarehouseProductsData={selectedWarehouseProductsData}
                  document={props?.document}
                  itemDetails={{
                    ...warehouseProduct
                  }}
                  module={MODULE_TYPE.SELL}
                  stockTransferData={stockTransferState['stockTransferItems']}
                  currentIndex={currentIndex}
                  defaultProductWarehouse={defaultAllocationWarehouse}
                  buildQuantity={0}
                  targetWarehouse={props.targetWarehouse}
                  onSave={(data: any) => {
                    onBatchSerialTrackSave(data, TRACKING_TYPE.BATCH);
                  }}
                  onClose={() => {
                    setShowBatchTransferPopup(false);
                  }}
                ></JWOStockTransferAdvancedBatchTrackingPopup>
              )}
            </div>
            {/* serial  product*/}
            <div>
              {showJWOSerialTrackingPopup &&
                stockTransferState &&
                stockTransferState['stockTransferDocumentSeqCodes'] && (
                  <AllocateJWOSerialBatchProduct
                    selectedWarehouseProductsData={
                      selectedWarehouseProductsData
                    }
                    document={props?.document}
                    itemDetails={{
                      ...warehouseProduct
                    }}
                    module={MODULE_TYPE.SELL}
                    defaultProductWarehouse={defaultAllocationWarehouse}
                    buildQuantity={0}
                    targetWarehouse={props.targetWarehouse}
                    stockTransferData={stockTransferState['stockTransferItems']}
                    stockTransferDocumentSeqCodes={
                      stockTransferState['stockTransferDocumentSeqCodes']
                    }
                    currentIndex={currentIndex}
                    onSerialSave={(data: any) => {
                      onBatchSerialTrackSave(data, TRACKING_TYPE.SERIAL);
                    }}
                    onClose={() => {
                      setShowJWOSerialTrackingPopup(false);
                    }}
                  ></AllocateJWOSerialBatchProduct>
                )}
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
