import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';

class TransactionLogService {
  static getTransactionLog(payload: any) {
    return http
      .post(`${ApiConstants.URL.AUDITTRAIL.TRANSACTION_LOG}`, payload)
      .catch((err: any) => {
        console.error('Error fetching transaction log: ', err);
        return Promise.reject(err);
      });
  }
}
export default TransactionLogService;
