import {
  DKButton,
  DKDataGrid,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_NUMBER_INPUT_MODULES,
  INPUT_VIEW_DIRECTION,
  PRODUCT_TYPE
} from '../../../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { fetchProductionPlan } from '../../../Redux/Slices/MRP/ProductionPlanSlice';
import { selectProductsWithVariants } from '../../../Redux/Slices/ProductsSlice';
import { selectSalesOrder } from '../../../Redux/Slices/SalesOrderSlice';
import DateFormatService from '../../../Services/DateFormat';
import ProductionPlanService from '../../../Services/MRP/ProductionPlan';
import NumberFormatService from '../../../Services/NumberFormat';
import ProductService from '../../../Services/Product';
import WarehouseService from '../../../Services/Warehouse';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../Utility/Utility';
import {
  PRODUCTION_PLAN_RAW_MATERIAL_COLS,
  PRODUCTION_PLAN_BILL_OF_MATERIAL_COLS
} from '../Constants/MRPColumnConfigs';

export interface CreateProductionPlanProps {
  onCancel: any;
  onSave: any;
  editObject: any;
  salesOrder: any;
}

export default function CreateProductionPlan(props: CreateProductionPlanProps) {
  const dispatch = useAppDispatch();
  // selectors
  const tenantInfo = useAppSelector(activeTenantInfo);
  const salesOrdersData = useAppSelector(selectSalesOrder);
  const productsData = useAppSelector(selectProductsWithVariants);

  //states
  const [warehouses, setWarehouses] = useState<any>();
  const [productionPlan, setProductionPlan] = useState<any>(
    props.editObject
      ? props.editObject
      : {
          postingDate: DateFormatService.getDateStrFromDate(
            new Date(),
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          linkedDocumentType: 'SALES_ORDER',
          status: 'OPEN',
          totalPlannedQuantity: 0,
          totalProducedQuantity: 0
        }
  );
  const [canValidate, setCanValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productColumnConfig, setProductColumnConfig] = useState(
    PRODUCTION_PLAN_BILL_OF_MATERIAL_COLS
  );
  const [rawMaterialColumnConfig, setRawMaterialColumnConfig] = useState(
    PRODUCTION_PLAN_RAW_MATERIAL_COLS
  );
  const [selectedBoms, setSelectedBoms] = useState<any[]>([]);
  const [selectedRawMaterial, setSelectedRawMaterial] = useState<any[]>([]);
  const [productsListForEdit, setProductsListForEdit] = useState<any>();
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({});

  const GRID_TITLES = {
    BOM: 'Bill of Materials',
    RAW_MATERIAL: 'Raw Materials'
  };

  useEffect(() => {
    updateConfig();
    getAllWarehouses();
    return () => {
      setProductionPlan(null);
    };
  }, []);

  useEffect(() => {
    if (
      !Utility.isEmpty(selectedBoms) ||
      !Utility.isEmpty(selectedRawMaterial)
    ) {
      let totalPlannedQuantityCalculate = selectedBoms.reduce(
        (previousValue: number, currentValue: any) => {
          return previousValue + currentValue.plannedQuantity;
        },
        0
      );
      // totalPlannedQuantityCalculate =
      //   totalPlannedQuantityCalculate +
      //   selectedRawMaterial.reduce((previousValue: number, currentValue: any) => {
      //     return previousValue + currentValue.plannedQuantity;
      //   }, 0);
      setProductionPlan((prevValue: any) => {
        return {
          ...prevValue,
          totalPlannedQuantity: totalPlannedQuantityCalculate
        };
      });
    }
  }, [selectedBoms, selectedRawMaterial]);

  useEffect(() => {
    if (!Utility.isEmpty(props.editObject)) {
      // setSelectedSalesOrderInProductionPlanObject();
      let linkedDocument = Utility.isEmpty(
        props.editObject?.linkedDocumentsInfo
      )
        ? {}
        : props.editObject?.linkedDocumentsInfo[0];
      setProductionPlan((prevValue: any) => {
        return {
          ...prevValue,
          linkedDocumentsInfo: linkedDocument
        };
      });
      let assemblyProductIDs: string[] =
        props.editObject.assemblyItemsInfo.reduce(
          (previousValue: string[], currentValue: any) => {
            return [...previousValue, currentValue.productCode];
          },
          []
        );
      let rawProductIDs = props.editObject.rawMaterialsInfo.reduce(
        (previousValue: string[], currentValue: any) => {
          return [...previousValue, currentValue.productCode];
        },
        []
      );
      callGetProductsById(assemblyProductIDs.concat(rawProductIDs));
    }
    if (!Utility.isEmpty(props.salesOrder)) {
      setSelectedSalesOrderValues(props.salesOrder);
    }
  }, [props]);

  useEffect(() => {
    if (!Utility.isEmpty(warehouses) && !Utility.isEmpty(productsListForEdit)) {
      let copyBoms = props.editObject.assemblyItemsInfo.map((item: any) => {
        let selectedWarehouse = warehouses.find(
          (warehouse: any) => warehouse.code === item.warehouseCode
        );
        return {
          ...item,
          product: productsListForEdit[item.productCode],
          productName: productsListForEdit[item.productCode].name,
          warehouseName: selectedWarehouse ? selectedWarehouse.name : ''
        };
      });

      let copyRawMaterials = props.editObject.rawMaterialsInfo.map(
        (item: any) => {
          let selectedWarehouse = warehouses.find(
            (warehouse: any) => warehouse.code === item.warehouseCode
          );
          return {
            ...item,
            product: productsListForEdit[item.productCode],
            productName: productsListForEdit[item.productCode].name,
            warehouseName: selectedWarehouse ? selectedWarehouse.name : ''
          };
        }
      );
      setSelectedBoms(copyBoms);
      setSelectedRawMaterial(copyRawMaterials);
    }
  }, [warehouses, productsListForEdit]);

  const callGetProductsById = (productIds: string[]) => {
    ProductService.getProductsByProductIds(productIds).then((response: any) => {
      let keyValuePair: any = {};
      response.forEach((element: any, index: any) => {
        keyValuePair[element['productId']] = element;
      });
      setProductsListForEdit(keyValuePair);
    });
  };

  const getAllWarehouses = () => {
    WarehouseService.getAllWarehouses()
      .then((res: any) => {
        let response =
          res.content && res.content.filter((item: any) => item.active);
        setWarehouses(response);
      })
      .catch((err: any) => {
        console.log('Failed to Load Warehouses!');
      });
  };

  const setSelectedSalesOrderInProductionPlanObject = async () => {
    let salesOrderId = '';
    if (!Utility.isEmpty(props?.editObject?.linkedDocumentsInfo)) {
      salesOrderId = props?.editObject?.linkedDocumentsInfo[0]?.documentCode;
    } else {
      return [];
    }
    ProductionPlanService.getSalesOrdersFor(salesOrderId)
      .then((response: any) => {
        if (Utility.isEmpty(response?.content)) {
          return {};
        } else {
          let findSalesOrder = response?.content?.find(
            (so: any) => so.documentSequenceCode === salesOrderId
          );
          setProductionPlan((prevValue: any) => {
            return {
              ...prevValue,
              linkedDocumentsInfo: {
                ...findSalesOrder,
                documentCode: findSalesOrder.documentSequenceCode
              }
            };
          });
        }
      })
      .catch((err: any) => {
        console.log(err, 'error');
        return [];
      });
  };

  const validateForm = () => {
    if (Utility.isEmpty(productionPlan.documentSequenceCode)) {
      return false;
    }
    if (Utility.isEmpty(productionPlan.postingDate)) {
      return false;
    }
    if (Utility.isEmpty(productionPlan.linkedDocumentType)) {
      return false;
    }
    if (Utility.isEmpty(productionPlan.linkedDocumentsInfo)) {
      return false;
    }
    return true;
  };
  const createRequestJson = () => {
    let request = {
      ...productionPlan,
      documentSequenceCode: productionPlan?.documentSequenceCode,
      linkedDocumentType: productionPlan?.linkedDocumentType,
      postingDate: productionPlan?.postingDate,
      sequenceFormat: productionPlan?.postingDate,
      status: productionPlan?.status,
      totalPlannedQuantity: productionPlan?.totalPlannedQuantity,
      totalProducedQuantity: productionPlan?.totalProducedQuantity,
      linkedDocumentsInfo: [productionPlan?.linkedDocumentsInfo],
      assemblyItemsInfo: selectedBoms,
      rawMaterialsInfo: selectedRawMaterial
    };
    return request;
  };

  const callCreateProductionPlan = () => {
    setIsLoading(true);
    let request = createRequestJson();
    ProductionPlanService.createProductionPlan(request)
      .then((res: any) => {
        setIsLoading(false);
        props.onSave();
        //@ts-ignore
        dispatch(fetchProductionPlan());
        console.log('Production Plan Created', res);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log('Production Plan create Failed', err);
      });
  };

  const callUpdateProductionPlan = () => {
    setIsLoading(true);
    let request = createRequestJson();
    ProductionPlanService.updateProductionPlan(request)
      .then((res: any) => {
        setIsLoading(false);
        props.onSave();
        //@ts-ignore
        dispatch(fetchProductionPlan());
        console.log('Production Plan Created', res);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log('Production Plan create Failed', err);
      });
  };

  const callApi = () => {
    if (productionPlan.id) {
      callUpdateProductionPlan();
    } else {
      if (validateForm()) {
        callCreateProductionPlan();
      }
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={
              productionPlan.id
                ? 'Update Production Plan'
                : 'Add Production Plan'
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto gap-2">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              if (!isLoading) {
                props.onCancel();
              }
            }}
          />
          <div
            className={`row border-radius-m ${
              isLoading ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              onClick={() => {
                if (!isLoading) {
                  setCanValidate(true);
                  callApi();
                }
              }}
            />
            {isLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    setAutoNumberingFormat({ ...selected });
    setProductionPlan((prevValue: any) => {
      return {
        ...prevValue,
        sequenceFormat: selected.id,
        documentSequenceCode: selected.text
      };
    });
  };

  const getSequenceNumberFormatField = () => {
    return productionPlan?.id ? (
      <DKInput
        title="Name"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={productionPlan?.productionPlanCode}
        readOnly={true}
      />
    ) : (
      <CustomNumberFormatInput
        module={CUSTOM_NUMBER_INPUT_MODULES.PRODUCTION_PLAN}
        selectedFormat={(data: any) => {
          selectedFormat(data);
        }}
        extraClass={'mt-l top-10 left-0 parent-width'}
        isRow={false}
        autoNumberingFormat={autoNumberingFormat}
      />
    );
  };

  const getPostingDateField = () => {
    return (
      <DKInput
        title="Posting Date"
        required={true}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={DateFormatService.getDateFromStr(
          productionPlan?.postingDate || new Date(),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )}
        type={INPUT_TYPE.DATE}
        onChange={(date: Date) => {
          setProductionPlan({
            ...productionPlan,
            postingDate: DateFormatService.getDateStrFromDate(
              date,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          });
        }}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
      />
    );
  };

  const getCompanyField = () => {
    return (
      <DKInput
        title="Company Name"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={tenantInfo?.name}
        readOnly={true}
      />
    );
  };

  const getDocumentTypeSelectionSelectionField = () => {
    return (
      <DKInput
        title="Get Items From"
        required={true}
        readOnly={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={productionPlan?.linkedDocumentType}
        formatter={(obj: any) => {
          return Utility.convertInTitleCase(obj.replaceAll('_', ' '));
        }}
        onChange={(value: any) => {
          setProductionPlan({ ...productionPlan, linkedDocumentType: value });
        }}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: '',
          allowSearch: false,
          canEdit: false,
          canDelete: false,
          data: ['SALES_ORDER'],
          renderer: (index: any, type: any) => {
            return Utility.convertInTitleCase(type.replaceAll('_', ' '));
          },
          searchApiConfig: {}
        }}
      />
    );
  };

  const setSelectedSalesOrderValues = (salesOrderObject: any) => {
    // Tracked Products in selected SO
    let rawMaterials = salesOrderObject.salesOrderItems.filter(
      (so: any) => so.type === PRODUCT_TYPE.TRACKED
    );

    // BOMS in selected SO
    let boms: any[] = [];
    salesOrderObject.salesOrderItems.forEach((salesOrderItem: any) => {
      if (salesOrderItem.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
        let bomItem = {
          productCode: salesOrderItem.productCode,
          plannedQuantity: salesOrderItem.productQuantity,
          warehouseCode: salesOrderItem.product.inventory.warehouseCode,
          plannedStartDate: new Date(),
          scheduledDate: new Date(),
          requiredQuantity: salesOrderItem.product.inventory.warehouseCode,
          productName: salesOrderItem.product.name
        };
        boms.push(bomItem);
        //process this bom and bring the raw materials out of it into rawmaterial array
        //later
      }
    });

    setSelectedBoms(boms);
    setSelectedRawMaterial(
      rawMaterials.map((salesOrderItem: any) => {
        return {
          availableQuantity: salesOrderItem.availableQuantity,
          productCode: salesOrderItem.productCode,
          productName: salesOrderItem.product.name,
          quantityAsPerBOM: salesOrderItem.productQuantity,
          quantityToRequest: salesOrderItem.productQuantity,
          warehouseCode: salesOrderItem.product.inventory.warehouseCode
        };
        // {
        //   productName: salesOrderItem.product.name,
        //   productCode: salesOrderItem.productCode,
        //   plannedQuantity: salesOrderItem.productQuantity,
        //   warehouseCode: salesOrderItem.product.inventory.warehouseCode,
        //   plannedStartDate: new Date(),
        //   scheduledDate: new Date(),
        //   requiredQuantity: salesOrderItem.product.inventory.warehouseCode
        // };
      })
    );
    //update so value in main Production plan object
    setProductionPlan((prevValue: any) => {
      return {
        ...prevValue,
        sequenceFormat: autoNumberingFormat.id,
        documentSequenceCode: autoNumberingFormat.text,
        linkedDocumentsInfo: {
          ...salesOrderObject,
          documentCode: salesOrderObject.documentSequenceCode
        }
      };
    });
  };

  const getSalesOrderSelectionField = () => {
    return (
      <DKInput
        title={'Select Sales Order'}
        errorMessage="Select a valid sales order"
        required={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={productionPlan?.linkedDocumentsInfo}
        formatter={(obj: any) => {
          return obj.documentCode;
        }}
        onChange={(value: any) => {
          setSelectedSalesOrderValues(value);
        }}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Sales Order',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: Utility.isEmpty(salesOrdersData?.content)
            ? salesOrdersData?.content
            : [],
          renderer: (index: any, so: any) => {
            return so.documentSequenceCode;
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const country = Utility.getTenantSpecificApiCode(
                COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
              );

              let url = `invoices/salesorder/${country}?limit=25&page=0&search=${searchValue}&&sort=salesOrderDate&sortDir=DESC`;
              return ApiConstants.URL.BASE + url;
            },
            dataParser: (response: any) => {
              return response?.content;
            },
            debounceTime: 300
          }
          // button: {
          //   title: 'Add Account',
          //   className: 'bg-button text-white',
          //   onClick: () => {
          //     setShowAddAccount(true);
          //   }
          // }
        }}
      />
    );
  };

  const updateColumnConfigs = (columnConfig: any[]) => {
    let config: any = [...columnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          let productDataArray =
            productsData?.content?.length > 0 ? productsData.content : [];
          conf.dropdownConfig.data =
            productDataArray.length > 0 ? productDataArray.length : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            ApiConstants.URL.BASE.replace('v1/', 'v1') +
            ProductService.getProductsWithVariantsURL(
              search,
              'active=true,hasVariants=false'
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            Utility.isEmpty(data?.content) ? [] : data?.content;
          break;
        case 'warehouse':
          conf.dropdownConfig.data = warehouses;
          conf.dropdownConfig.searchApiConfig.getUrl = (searchValue: any) => {
            WarehouseService.apiConfig = {
              ...WarehouseService.apiConfig,
              SearchTerm: searchValue
            };
            return WarehouseService.getWarehouseEndPoint();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) => {
            return data?.content;
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                className="text-align-left parent-width white-space-nowrap"
                text={obj?.name}
              />
            );
          };
          conf.dropdownConfig.style = {
            right: 0,
            width: 150
          };
          conf.formatter = (obj: any) => {
            return obj.rowData?.warehouseName;
          };
          break;
      }
    });
    return config;
  };

  const updateConfig = () => {
    setProductColumnConfig(updateColumnConfigs(productColumnConfig));
    setRawMaterialColumnConfig(updateColumnConfigs(rawMaterialColumnConfig));
  };

  const onRowUpdate = (title: string, data: any) => {
    if (title === GRID_TITLES.BOM) {
      let editedRecords = [...selectedBoms];
      let edited = editedRecords[data.rowIndex];
      edited.invalidFields = edited.invalidFields
        ? [...edited.invalidFields].filter((field) => field !== data.columnKey)
        : [];
      if (data.columnKey === 'warehouse') {
        edited.warehouseCode = data.rowData.warehouse.code;
        edited.warehouseName = data.rowData.warehouse.name;
      }
      if (data.columnKey === 'plannedQuantity') {
        edited.plannedQuantity = data.rowData.plannedQuantity;
      }

      editedRecords[data.rowIndex] = edited;
      setSelectedBoms(editedRecords);
    } else if (title === GRID_TITLES.RAW_MATERIAL) {
      let selectedBomsCopy = [...selectedRawMaterial];
      let itemToEdit = { ...selectedBomsCopy[data.rowIndex] };
      itemToEdit.warehouseCode = data.rowData.warehouse.code;
      itemToEdit.warehouseName = data.rowData.warehouse.name;
      selectedBomsCopy[data.rowIndex] = itemToEdit;
      setSelectedRawMaterial(selectedBomsCopy);
    }
  };

  const getDataGrid = (title: string, data: any[], columnConfig: any) => {
    return (
      <DKDataGrid
        tableName={title}
        title={title}
        filterTableName={title}
        needColumnIcons={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowRowAdd={true}
        allowColumnAdd={false}
        allowColumnDelete={false}
        allowColumnShift={false}
        allowSearch={false}
        // onSearch={onSearch}
        headerButtons={[]}
        allowDataExport={true}
        columns={columnConfig}
        rows={data}
        currentPage={1}
        totalPageCount={1}
        // onPrevPageTapped={() => onPagination(currentPage - 1)}
        // onNextPageTapped={() => onPagination(currentPage + 1)}
        onRowUpdate={(data: any) => {
          onRowUpdate(title, data);
        }}
        allowBulkOperation={false}
        onRowSelect={(data: any) => {
          // const updatedState = { ...bankDepositState };
          // updatedState.undepositList[data.rowIndex] = data.rowData;
          // setBankDepositState(updatedState);
          // checkboxChanged(data.rowData.selected, data.rowIndex);
        }}
        width={() => {
          let mainWidth = document.getElementById('mainDiv')?.offsetWidth || 30;
          return mainWidth - 30;
        }}
        needTrailingColumn={true}
        onAllRowSelect={(data: any) => {
          // setIsAllRowSelected(data.selected);
          // selectUnselectAllRecords(data.selected);
        }}
      />
    );
  };

  const getTotalPlannedQuantity = () => {
    return (
      <DKInput
        required={false}
        title="Total Planned Quantity"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.NUMBER}
        canValidate={canValidate}
        value={NumberFormatService.getNumber(
          productionPlan?.totalPlannedQuantity
        )}
        onChange={(value: number) => {
          setProductionPlan({ ...productionPlan, totalPlannedQuantity: value });
        }}
      />
    );
  };

  const getStatusField = () => {
    return (
      <DKInput
        title="Status"
        required={false}
        readOnly={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={productionPlan?.status}
        formatter={(obj: any) => {
          return Utility.convertInTitleCase(obj.replaceAll('_', ' '));
        }}
        onChange={(value: any) => {
          setProductionPlan({ ...productionPlan, status: value });
        }}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: '',
          allowSearch: false,
          canEdit: false,
          canDelete: false,
          data: ['OPEN'],
          renderer: (index: any, type: any) => {
            return Utility.convertInTitleCase(type.replaceAll('_', ' '));
          },
          searchApiConfig: {}
        }}
      />
    );
  };

  const getTotalProducedQtyField = () => {
    return (
      <DKInput
        required={false}
        title="Total Produced Quantity"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.NUMBER}
        canValidate={canValidate}
        value={productionPlan?.totalProducedQuantity}
        onChange={(value: any) => {
          setProductionPlan({
            ...productionPlan,
            totalProducedQuantity: value
          });
        }}
      />
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        id="mainDiv"
        style={{
          maxWidth: 750,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          height: 680,
          padding: 0,
          paddingBottom: 10,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-4 gap-4 overflow-scroll hide-scroll-bar">
          <div className="row gap-4">
            {getSequenceNumberFormatField()}
            {getPostingDateField()}
          </div>
          <div className="row gap-4">
            {getTotalPlannedQuantity()}
            {getStatusField()}
          </div>
          <div className="row gap-4">
            {getTotalProducedQtyField()}
            {getCompanyField()}
          </div>
          <div className="row gap-4">
            {getDocumentTypeSelectionSelectionField()}
            {getSalesOrderSelectionField()}
          </div>
          {getDataGrid(GRID_TITLES.BOM, selectedBoms, productColumnConfig)}
          {/* <DKButton
            title="Add row"
            className="bg-button text-white border-radius-m"
            onClick={() => {
              setSelectedBoms([
                ...selectedBoms,
                {
                  productCode: '',
                  plannedQuantity: 0,
                  warehouseCode: '',
                  plannedStartDate: new Date(),
                  scheduledDate: new Date(),
                  requiredQuantity: 0,
                  name: ''
                }
              ]);
            }}
          /> */}
          {getDataGrid(
            GRID_TITLES.RAW_MATERIAL,
            selectedRawMaterial,
            rawMaterialColumnConfig
          )}
          {/* <DKButton
            title="Add row"
            className="bg-button text-white border-radius-m"
            onClick={() => {
              setSelectedRawMaterial([
                ...selectedRawMaterial,
                {
                  name: '',
                  productCode: '',
                  plannedQuantity: 0,
                  warehouseCode: '',
                  plannedStartDate: new Date(),
                  scheduledDate: new Date(),
                  requiredQuantity: ''
                }
              ]);
            }}
          /> */}
        </div>
      </div>
    </div>
  );
}
export const productWareHouseNameRenderer = (index: number, warehouse: any) => (
  <DKLabel
    className="text-align-left parent-width white-space-nowrap"
    text={warehouse?.name}
  />
);
