import { useEffect, useState } from 'react';
import {
  DKIcon,
  DKLabel,
  DKIcons,
  DKListPicker2,
  DKButton,
  DKInput,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { AdditionalChargeDetails } from '../../Models/Document';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import {
  DOCUMENT_MODE,
  MODULE_TYPE,
  PRODUCT_OFFERING_TYPE
} from '../../Constants/Constant';
import NumberFormatService from '../../Services/NumberFormat';
import {
  selectAllAdditionalBuyCharges,
  selectAllAdditionalSellCharges
} from '../../Redux/Slices/AdditionalChargesSlice';
import { t } from 'i18next';
import CommissionChargeInput from './CommissionChargeInput';

export interface SummayProps {
  booksDocument: any;
  documentMode?: DOCUMENT_MODE;
  onCurrencyAndExchangeRateChange?: (
    currency: string,
    exchangeRate: number
  ) => void;
  onDocumentUpdate: (document: any) => void;
}

interface TotalCalculationDeps {
  productCost: number;
  commissionOnProductRevenue: number;
  netProductCost: number;
  productRevenue: number;
  netProductRevenue: number;
  subTotal1: number;
  subTotal2: number;
  grandTotal: number;
  tax: number;
  totalProfitMargin: number;
  totalProfitMarginPercent: number;
  dicountOnProductRevenue: number;
}

export default function RateAnalysisSummaryView(props: SummayProps) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const allAdditionalBuyCharges = useAppSelector(selectAllAdditionalBuyCharges);
  const allAdditionalSellCharges = useAppSelector(
    selectAllAdditionalSellCharges
  );
  const [showCommission, setshowCommission] = useState(false);
  const [booksDocument, setBooksDocument] = useState<any>({});
  const [selectedCharges, setSelectedCharges] = useState<any>([]);
  const [selectedCommission, setSelectedCommission] = useState<any>([]);
  const [selectedCommissionList, setSelectedCommissionList] = useState<any>([]);
  useEffect(() => {
    setBooksDocument({ ...props.booksDocument });
  }, [props.booksDocument]);

  const buildSelectedCharges = (
    additionalCharges: AdditionalChargeDetails[]
  ) => {
    return additionalCharges.map((charge: AdditionalChargeDetails) => {
      const moduleType = Utility.isSalesDocument(booksDocument)
        ? MODULE_TYPE.SELL
        : MODULE_TYPE.BUY;
      let chargesFromStore =
        moduleType === MODULE_TYPE.SELL
          ? allAdditionalSellCharges
          : allAdditionalBuyCharges;
      let filteredCharge =
        chargesFromStore?.find((chargeFromStore: any) => {
          if (charge.id) {
            return charge.id === chargeFromStore.id;
          }
          return charge.additionalCharge === chargeFromStore.name;
        }) || {};
      return {
        ...filteredCharge,
        purchaseTaxCode: charge.addtionalChargeTaxCode,
        salesTaxCode: charge.addtionalChargeTaxCode,
        isPercent: charge.isPercent,
        percentageValue: charge.percent,
        chargeValue: charge.chargeAmount ? +charge.chargeAmount : 0,
        taxAmount: charge.taxAmount,
        includeInReport: filteredCharge.includeInReport,
        apportionFlag: charge?.apportionFlag || null,
        apportionValue: charge?.apportionValue || null
      };
    });
  };

  const buildCommissionList = (data: any) => {
    if (Utility.isEmpty(booksDocument?.commissionCharges)) {
      return data;
    } else {
      const result = [...data];
      const secondList = booksDocument?.commissionCharges || [];
      secondList.forEach((item: any) => {
        const index = result.findIndex(
          (elem: any) => elem.label === item.label
        );
        if (index !== -1) {
          result.splice(index, 1);
        }
      });
      return result;
    }
  };

  useEffect(() => {
    const list = props.booksDocument?.commissionCharges || [];

    if (!Utility.isEmpty(list) && Utility.isEmpty(selectedCommission)) {
      setSelectedCommission(list);
    }

    if (Utility.isEmpty(selectedCommission)) {
      setSelectedCommissionList(
        buildCommissionList(
          tenantInfo.additionalSettings?.RATE_ANALYSIS.COMMISSION_CHARRGES || []
        )
      );
    }
    if (Utility.isEmpty(selectedCharges)) {
      setSelectedCharges(
        buildSelectedCharges(
          props.booksDocument.additionalCharges?.additionalChargesDetails || []
        )
      );
    }
  }, [booksDocument]);

  const [totalCalculationDeps, setTotalCalculationDeps] =
    useState<TotalCalculationDeps>({
      productCost: 0,
      commissionOnProductRevenue: 0,
      netProductCost: 0,
      productRevenue: 0,
      netProductRevenue: 0,
      subTotal1: 0,
      subTotal2: 0,
      grandTotal: 0,
      tax: 0,
      totalProfitMargin: 0,
      totalProfitMarginPercent: 0,
      dicountOnProductRevenue: 0
    });

  useEffect(() => {
    let setProductCost = 0;
    let setNetProductCost = 0;
    let setProductRevenue = 0;
    let setNetProductRevenue = 0;
    let setSubTotal1 = 0;
    let setSubTotal2 = 0;
    let setTax = 0;
    let setTotalProfitMargin = 0;
    let setTotalProfitMarginPercent = 0;
    let setDicountOnProductRevenue = 0;

    props.booksDocument?.lineItems?.forEach((data: any) => {
      setProductCost += parseFloat(data.productCost);
      setProductRevenue += data.productQuantity * data.unitPrice;
      setDicountOnProductRevenue += parseFloat(data.discountAmount);
      setTax += data.taxAmount;
    });
    setNetProductRevenue = setProductRevenue - setDicountOnProductRevenue;
    setNetProductCost = setProductCost;
    const list = props.booksDocument?.commissionCharges || [];

    if (!Utility.isEmpty(selectedCommission)) {
      selectedCommission?.map((data: any) => {
        setNetProductCost += data.isPercent
          ? (setNetProductRevenue * data.value) / 100
          : Number(data.value);
      });
    } else {
      list?.map((data: any) => {
        setNetProductCost += data.isPercent
          ? (setNetProductRevenue * data.value) / 100
          : data.value;
      });
    }
    setSubTotal1 = setNetProductRevenue;
    if (!Utility.isEmpty(selectedCharges)) {
      selectedCharges?.forEach((data: any) => {
        if (data.isPercent) {
          setSubTotal1 += (data.percentageValue * setNetProductRevenue) / 100;
        } else {
          setSubTotal1 += +data.chargeValue;
        }
      });
    } else if (
      booksDocument?.additionalCharges?.additionalChargesDetails !== null &&
      booksDocument?.additionalCharges?.additionalChargesDetails !== undefined
    ) {
      booksDocument?.additionalCharges?.additionalChargesDetails?.forEach(
        (data: any) => {
          if (data.isPercent) {
            setSubTotal1 += (data.percent * setNetProductRevenue) / 100;
          } else {
            setSubTotal1 += data.chargeAmount;
          }
        }
      );
    }

    setSubTotal2 = setSubTotal1 + setTax;
    setTotalProfitMargin = setNetProductRevenue - setNetProductCost;
    setTotalProfitMarginPercent =
      (setTotalProfitMargin / setNetProductCost) * 100;
    setTotalCalculationDeps((prevState) => ({
      ...prevState,
      tax: Utility.roundOffToTenantDecimalScale(setTax),
      productCost: Utility.roundOffToTenantDecimalScale(setProductCost),
      netProductCost: Utility.roundOffToTenantDecimalScale(setNetProductCost),
      productRevenue: Utility.roundOffToTenantDecimalScale(setProductRevenue),
      dicountOnProductRevenue: Utility.roundOffToTenantDecimalScale(
        setDicountOnProductRevenue
      ),
      netProductRevenue:
        Utility.roundOffToTenantDecimalScale(setNetProductRevenue),
      subTotal1: Utility.roundOffToTenantDecimalScale(setSubTotal1),
      subTotal2: Utility.roundOffToTenantDecimalScale(setSubTotal2),
      grandTotal: Utility.roundOffToTenantDecimalScale(setSubTotal2),
      totalProfitMargin:
        Utility.roundOffToTenantDecimalScale(setTotalProfitMargin),
      totalProfitMarginPercent: Utility.roundOffToTenantDecimalScale(
        setTotalProfitMarginPercent
      )
    }));
  }, [booksDocument]);

  useEffect(() => {
    const updatedCharges = selectedCharges.map((res: any) => {
      if (res.isPercent) {
        const updatedCharge = {
          ...res,
          chargeValue: Utility.roundOffToTenantDecimalScale(
            (totalCalculationDeps.netProductRevenue * res.percentageValue) / 100
          )
        };
        return updatedCharge;
      }
      return res;
    });
    setSelectedCharges(updatedCharges);
    props.onDocumentUpdate({ ...totalCalculationDeps, selectedCommission });
  }, [totalCalculationDeps]);

  const getTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    currencyCode?: string
  ) => {
    const amountNo = Utility.roundOffToTenantDecimalScale(amount);
    const amountText = `${
      amountNo < 0 ? '(' : ''
    }${NumberFormatService.getNumber(amountNo)}${amountNo < 0 ? ')' : ''}`;
    return (
      <>
        <div
          className="row parent-width mb-m justify-content-between align-items-start"
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
          >
            {icon && (
              <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
            )}
            <DKLabel text={title} className={'ml-r ' + titleClassName} />
            {title === t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.TOTAL`) &&
              tenantInfo.multicurrencyEnabled}
          </div>
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              currencyCode ? currencyCode : booksDocument?.currency
            )} ${amountText}`}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap ${amountClassName || ''}`}
          />
        </div>
      </>
    );
  };

  const getCommissionPicker = () => {
    return (
      <div className="row position-relative mb-m">
        <DKListPicker2
          title="Select commission"
          data={selectedCommissionList}
          style={{
            width: 150,
            left: 140,
            top: -120
          }}
          allowSearch={false}
          canEdit={false}
          canDelete={false}
          className="position-absolute z-index-3 shadow-m border-m"
          onSelect={(index: number, charge: any) => {
            setSelectedCommission([...selectedCommission, charge]);
            const selectedChargesCopy = [...selectedCommissionList];
            selectedChargesCopy.splice(index, 1);
            setSelectedCommissionList(selectedChargesCopy);
            setshowCommission(!showCommission);
            addValueCommission(charge);
          }}
          renderer={(index: number, charge: any) => {
            return (
              <div
                className="text-align-left fw-r"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {charge.label}
              </div>
            );
          }}
          onClose={() => {
            setshowCommission(!showCommission);
          }}
        />
      </div>
    );
  };

  const getTextFieldForSelectedCharge = (index: number) => {
    const charge = selectedCharges[index];

    return (
      <div className="pb-l">
        <DKInput
          value={
            charge.isPercent ? `${charge.percentageValue}%` : charge.chargeValue
          }
          valueStyle={{
            background: '#fff',
            paddingTop: 0,
            paddingBottom: 0,
            minWidth: 100
          }}
          readOnly={true}
          textAlign="right"
          className="width-auto text-align-right"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
        />
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            booksDocument.currency
          )} ${NumberFormatService.getNumber(charge.chargeValue)}`}
          className="text-align-right"
        />
      </div>
    );
  };

  const addCommission = (charge: any) => {
    selectedCommissionList.push(charge);
    setSelectedCommissionList(selectedCommissionList);
  };

  const addValueCommission = (charge: any) => {
    let value: number = charge.isPercent
      ? (totalCalculationDeps.netProductRevenue * charge.value) / 100
      : parseFloat(charge.value);

    let total: number = totalCalculationDeps.netProductCost + value;

    let setTotalProfitMargin = totalCalculationDeps.totalProfitMargin - value;
    let setTotalProfitMarginPercent = (setTotalProfitMargin / total) * 100;

    setTotalCalculationDeps((prevState) => ({
      ...prevState,
      netProductCost: Utility.roundOffToTenantDecimalScale(total),
      totalProfitMargin:
        Utility.roundOffToTenantDecimalScale(setTotalProfitMargin),
      totalProfitMarginPercent: Utility.roundOffToTenantDecimalScale(
        setTotalProfitMarginPercent
      )
    }));
  };

  const subValueCommission = (charge: any) => {
    let value: number = charge.isPercent
      ? (totalCalculationDeps.netProductRevenue * charge.value) / 100
      : parseFloat(charge.value);

    let total: number = totalCalculationDeps.netProductCost - value;
    let setTotalProfitMargin = totalCalculationDeps.totalProfitMargin + value;
    let setTotalProfitMarginPercent = (setTotalProfitMargin / total) * 100;
    setTotalCalculationDeps((prevState) => ({
      ...prevState,
      netProductCost: Utility.roundOffToTenantDecimalScale(total),
      totalProfitMargin:
        Utility.roundOffToTenantDecimalScale(setTotalProfitMargin),
      totalProfitMarginPercent: Utility.roundOffToTenantDecimalScale(
        setTotalProfitMarginPercent
      )
    }));
  };

  const changeValueCommission = (data: any, index: any) => {
    if (data == selectedCommission[index].value) {
      return;
    } else {
      let value: number = selectedCommission[index].isPercent
        ? Number((data * totalCalculationDeps.netProductRevenue) / 100)
        : Number(data);
      let prevValue: number = selectedCommission[index].isPercent
        ? (totalCalculationDeps.netProductRevenue *
            selectedCommission[index].value) /
          100
        : selectedCommission[index].value;

      let setNetProductCost: number =
        totalCalculationDeps.netProductCost - prevValue + value;
      let setTotalProfitMargin =
        totalCalculationDeps.netProductRevenue - setNetProductCost;
      let setTotalProfitMarginPercent =
        (setTotalProfitMargin / setNetProductCost) * 100;
      setTotalCalculationDeps((prevState) => ({
        ...prevState,
        netProductCost: Utility.roundOffToTenantDecimalScale(setNetProductCost),
        totalProfitMargin:
          Utility.roundOffToTenantDecimalScale(setTotalProfitMargin),
        totalProfitMarginPercent: Utility.roundOffToTenantDecimalScale(
          setTotalProfitMarginPercent
        )
      }));

      const selectedChargesCopy = selectedCommission.map(
        (item: any, i: any) => {
          if (i === index) {
            return { ...item, value: data };
          }
          return item;
        }
      );

      setSelectedCommission(selectedChargesCopy);
    }
  };

  const totalMarginCal = () => {
    let value = totalCalculationDeps.totalProfitMarginPercent;
    if (value === Infinity) {
      return '0%';
    } else {
      return `${value}%`;
    }
  };

  // Update commission on change of dropdown/input field
  const updateSelectedCommissionOnInputChange = (
    index: number,
    charge?: any,
    isPercent?: boolean,
    inputFieldValue?: any
  ) => {
    if (!Utility.isEmpty(inputFieldValue)) {
      changeValueCommission(inputFieldValue, index);
    }
  };

  const getCommissionChargeFields = (index: number, existingCharge: any) => {
    const charge = selectedCommission[index];
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
        style={{ width: '100%' }}
      >
        <DKButton
          title=""
          icon={DKIcons.ic_delete}
          className="position-absolute child-block"
          style={{ left: -30, top: -7, opacity: 0.5 }}
          onClick={() => {
            const selectedChargesCopy = [...selectedCommission];
            addCommission(selectedChargesCopy[index]);
            selectedChargesCopy.splice(index, 1);
            subValueCommission(existingCharge);
            setSelectedCommission(selectedChargesCopy);
          }}
        />

        <div
          className="row width-auto"
          style={{
            maxWidth: 200
          }}
        >
          <div className="column parent-width">
            <DKLabel
              text={charge.label}
              className="fs-r text-align-left ml-r fw-m"
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            />
          </div>
        </div>

        <div className="pb-l flex flex-col gap-4">
          <CommissionChargeInput
            charge={charge}
            onValueChange={(obj: { value: string; isPercent: boolean }) => {
              updateSelectedCommissionOnInputChange(
                index,
                charge,
                obj.isPercent,
                obj.isPercent ? Number(obj.value.replace('%', '')) : obj.value
              );
            }}
          />
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              booksDocument.currency
            )} ${NumberFormatService.getNumber(
              charge.isPercent
                ? (totalCalculationDeps.netProductRevenue * charge.value) / 100
                : charge.value
            )}`}
            className="text-align-right mt-1"
          />
        </div>
      </div>
    );
  };

  const getAddionalChargeFields = (index: number, existingCharge: any) => {
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
        style={{ width: '100%' }}
      >
        <div
          className="row width-auto"
          style={{
            maxWidth: 200
          }}
        >
          <div className="column parent-width">
            <DKLabel
              text={selectedCharges[index]?.name}
              className="fs-r text-align-left ml-r fw-m"
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            />
            <DKLabel
              text={
                selectedCharges[index]?.indiaProperties
                  ? selectedCharges[index].offeringType ===
                    PRODUCT_OFFERING_TYPE.SERVICES
                    ? selectedCharges[index]?.indiaProperties?.SAC
                      ? 'SAC: ' + selectedCharges[index]?.indiaProperties?.SAC
                      : ''
                    : selectedCharges[index]?.indiaProperties?.HSN
                    ? 'HSN: ' + selectedCharges[index]?.indiaProperties?.HSN
                    : ''
                  : ''
              }
              className="ml-r text-gray fs-s"
            />
          </div>
        </div>
        {getTextFieldForSelectedCharge(index)}
      </div>
    );
  };

  const showAdditionalCharges = () => {
    return selectedCharges.map((charge: any, index: number) => {
      return (
        <div className="parent-width">
          {getAddionalChargeFields(index, charge ? charge : {})}
        </div>
      );
    });
  };

  const showCommissionCharges = () => {
    return selectedCommission.map((charge: any, index: number) => {
      return (
        <div className="parent-width">
          {getCommissionChargeFields(index, charge ? charge : {})}
        </div>
      );
    });
  };

  return (
    <div>
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.PRODUCT_COST`)}`,
        totalCalculationDeps.productCost,
        null,
        'fw-m'
      )}
      {
        <DKButton
          className={`${'text-blue'} fw-m`}
          style={{ paddingLeft: 8, paddingTop: 0, paddingBottom: 10 }}
          title={`+ Add Commission`}
          disabled={selectedCommissionList.length == 0}
          onClick={() => {
            setshowCommission(!showCommission);
          }}
        />
      }
      {showCommission && getCommissionPicker()}
      {showCommissionCharges()}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.NET_PRODUCT_COST`)} `,
        totalCalculationDeps.netProductCost,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.PRODUCT_REVENUE`)} `,
        totalCalculationDeps.productRevenue,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(
          `DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.DISCOUNT_ON_PRODUCT_REVENUE`
        )} `,
        totalCalculationDeps.dicountOnProductRevenue,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.NET_PRODUCT_REVENUE`)} `,
        totalCalculationDeps.netProductRevenue,
        null,
        'fw-m'
      )}
      {showAdditionalCharges()}

      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.SUB_TOTAL_1`)} `,
        totalCalculationDeps.subTotal1,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.TAX`)} `,
        totalCalculationDeps.tax,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.SUB_TOTAL_2`)} `,
        totalCalculationDeps.subTotal2,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.GRAND_TOTAL`)} `,
        totalCalculationDeps.grandTotal,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.TOTAL_PROFIT_MARGIN`)} `,
        totalCalculationDeps.totalProfitMargin,
        null,
        'fw-m'
      )}
      {
        <div
          className="row parent-width mb-m justify-content-between align-items-start"
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
          >
            <DKLabel
              text={`${t(
                `DOCUMENT.RATE_ANALYSIS_SUMMARY_VIEW.TOTAL_PROFIT_MARGIN`
              )} (%) `}
              className={'ml-r fw-m'}
            />
          </div>
          <DKLabel
            text={totalMarginCal()}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap`}
          />
        </div>
      }
    </div>
  );
}
