import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import getInventoryByPage from '../../Services/Inventory';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchInventories = createAsyncThunk('inventory', async () => {
  const response = await getInventoryByPage.getInventoryByPage();
  return response;
});

export const InventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    addInventoryColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToInventoryColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchInventories.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchInventories.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToInventoryColumnConfig,
  addInventoryColumnConfig,
  updateColumnConfig
} = InventorySlice.actions;

export const selectInventory = (state: RootState) => state.inventory.data;

export const selectInventoryLoadingStatus = (state: RootState) =>
  state.inventory.status;

export const selectInventorysColumnConfig = (state: RootState) =>
  state.inventory.columnConfig;

export const selectInventorysColumnConfigTableId = (state: RootState) =>
  state.inventory.configTableId;
