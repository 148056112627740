import React, { useEffect, useState } from 'react';
import { DKInput, INPUT_TYPE, DKLabel, DKButton } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  INPUT_VIEW_DIRECTION
} from '../../../Constants/Constant';
import {
  FILLING_FREQUENCY,
  initialFormData,
  REPORTING_METHOD
} from './AgenciesConstant';
import { USstateMockData } from '../../../Constants/StaticData';
import DateFormatService from '../../../Services/DateFormat';
import Utility, { deepClone } from '../../../Utility/Utility';
import AgenciesService from '../../../Services/Agencies';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { ReactSelectOptionsType } from '../../../Models/Interfaces';
import CommonStateListPicker from '../../../SharedComponents/DocumentForm/CommonStateListPicker';

const AddAgency = (props: any) => {
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const [formData, setFormData] = useState<any>(initialFormData);

  useEffect(() => {
    setFormData({
      ...formData,
      agencyName: {
        value: getUsStateOptions()[0],
        hasError: false,
        errorMsg: ''
      }
    });
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      renderPropsData();
    }
  }, [props.data]);

  const renderPropsData = () => {
    let formNewState: any = deepClone(initialFormData);
    let agencyData = props.data;

    // Find values
    let _findAgency = getUsStateOptions().find(
      (state: any) => state.code === agencyData.code
    );

    let _findFillingFrequency = FILLING_FREQUENCY.find(
      (frequency: any) => frequency.value === agencyData.filingFrequency
    );

    let _findReportingMethod = REPORTING_METHOD.find(
      (method: any) => method.value === agencyData.basisType
    );

    formNewState.agencyId = {
      ...formNewState.agencyId,
      value: agencyData.id
    };

    if (_findAgency) {
      formNewState.agencyName = {
        ...formNewState.agencyName,
        value: _findAgency
      };
    }

    if (_findFillingFrequency) {
      formNewState.fillingFrequency = {
        ...formNewState.fillingFrequency,
        value: _findFillingFrequency
      };
    }

    if (_findReportingMethod) {
      formNewState.reportingMethod = {
        ...formNewState.reportingMethod,
        value: _findReportingMethod
      };
    }

    formNewState.startDate = {
      ...formNewState.startDate,
      value: DateFormatService.getDateFromStr(
        agencyData.startDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };

    formNewState.status = {
      ...formNewState.status,
      value: agencyData.active
    };

    setFormData(formNewState);
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={Utility.isEmpty(props.data) ? 'Add Agency' : 'Edit Agency'}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              saveAgency();
            }}
          />
        </div>
      </div>
    );
  };

  const makePayload = () => {
    let Payload: any = {};

    if (formData) {
      Payload.name = formData?.agencyName?.value?.name;
      Payload.code = formData?.agencyName?.value?.code;
      Payload.filingFrequency = formData?.fillingFrequency?.value?.value;
      Payload.periodStartMonth = 1;
      Payload.startDate = DateFormatService.getDateStrFromDate(
        formData?.startDate?.value,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      Payload.basisType = formData?.reportingMethod?.value?.value;
      Payload.active = formData?.status?.value;
    }

    return Payload;
  };

  const saveAgency = () => {
    if (!Utility.isEmpty(props.data)) {
      AgenciesService.updateAgency(formData.agencyId.value, makePayload()).then(
        (res: any) => {
          props.onCancel();
        },
        (err) => {
          console.error('Error updating agency: ', err);
        }
      );
    } else {
      AgenciesService.saveAgency(makePayload()).then(
        (res: any) => {
          props.onCancel();
        },
        (err) => {
          console.error('Error saving agency: ', err);
        }
      );
    }
  };

  const getUsStateOptions = () => {
    let states: ReactOptionType[] = [];
    const americaStates = USstateMockData;
    americaStates.forEach((state) => {
      let option: ReactOptionType = {
        label: state.name,
        value: state.code,
        searchableKey: `${state.name} ${state.code}`,
        code: state.code
      };
      states.push(option);
    });
    return states;
  };

  const getBody = () => {
    return (
      <div className="column parent-width mt-3 p-h-r p-v-s">
        <div className="row parent-width">
          <div className="parent-width p-h-s">
            <CommonStateListPicker
              readOnly={!Utility.isEmpty(props.data)}
              title={'Agency'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              isMandatory={true}
              value={formData.agencyName.value}
              dropdownOptions={getUsStateOptions()}
              formatterProperty={'label'}
              isSearchable={true}
              className="mb-l"
              searchableKey={'searchableKey'}
              onChange={(obj: any) => {
                setFormData({
                  ...formData,
                  agencyName: {
                    ...formData.agencyName,
                    value: obj
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="row parent-width">
          <div className="w-1/2 p-h-s">
            <DKInput
              type={INPUT_TYPE.DATE}
              title={'Start Date'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formData.startDate.value}
              required={true}
              onChange={(value: any) => {
                setFormData({
                  ...formData,
                  startDate: {
                    ...formData.startDate,
                    value
                  }
                });
              }}
              className="mb-l"
            />
          </div>
          <div className="w-1/2 p-h-s">
            {!Utility.isEmpty(formData.startDate.value.toString()) && (
              <>
                <DKLabel text="Start of Tax Period" />
                <DKLabel text="January" className="fs-r pb-8 pt-4" />
              </>
            )}
          </div>
        </div>
        <div className="row parent-width">
          <div className="w-1/2 p-h-s">
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Filling Frequency'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formData.fillingFrequency.value}
              formatter={(obj: any) => {
                return obj?.title;
              }}
              required={true}
              onChange={(obj: any) => {
                setFormData({
                  ...formData,
                  fillingFrequency: {
                    ...formData.fillingFrequency,
                    value: obj
                  }
                });
              }}
              className="mb-l"
              dropdownConfig={{
                title: '',
                allowSearch: false,
                searchableKey: '',
                canEdit: false,
                canDelete: false,
                style: { minWidth: 150 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: FILLING_FREQUENCY,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={`${obj?.title}`} />;
                }
              }}
            />
          </div>
          <div className="w-1/2 p-h-s">
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Reporting method'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formData.reportingMethod.value}
              formatter={(obj: any) => {
                return obj?.title;
              }}
              required={true}
              onChange={(obj: any) => {
                setFormData({
                  ...formData,
                  reportingMethod: {
                    ...formData.reportingMethod,
                    value: obj
                  }
                });
              }}
              className="mb-l"
              dropdownConfig={{
                title: '',
                allowSearch: false,
                searchableKey: '',
                canEdit: false,
                canDelete: false,
                style: { minWidth: 150 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: REPORTING_METHOD,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={`${obj?.title}`} />;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div style={{ zIndex: 9998 }} className="transparent-background asd">
        <div
          className="popup-window"
          style={{
            maxWidth: 560,
            width: '90%',
            maxHeight: '95%',
            height: 310,
            padding: 0,
            paddingBottom: 10,
            overflowY: 'auto',
            overflow: 'visible'
          }}
        >
          {getHeader()}
          {getBody()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default AddAgency;
