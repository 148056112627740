import { DKLabel, DKIcon, DKIcons } from 'deskera-ui-library';
import DateFormatService from '../../../Services/DateFormat';
import { useState } from 'react';

interface ISubscriptionDetailPopupProps {
  subscriptionData: any;
  contact: any;
}

const SubscriptionDetailPopup: React.FC<ISubscriptionDetailPopupProps> = (
  props
) => {
  const [selectedIds, setSelectedIds] = useState<any>([]);

  const getPlanDetails = (packageName: string, planData: any) => {
    return (
      <>
        <div className="row parent-width mt-2">
          <DKLabel text="Plan" className="fw-m" style={{ width: 180 }} />
          <DKLabel
            text={`<span class="fw-b">${packageName}</span> ${planData?.DisplayName}`}
            className=""
          />
        </div>
        <div className="row parent-width mt-2">
          <DKLabel text="Trial" className="fw-m" style={{ width: 180 }} />
          <DKLabel
            text={`${planData?.InTrial ? 'Available' : 'Not Available'}`}
            className=""
          />
        </div>
        <div className="row parent-width mt-2">
          <DKLabel
            text="Next Charge Date"
            className="fw-m"
            style={{ width: 180 }}
          />
          <DKLabel
            text={DateFormatService.getDateStrFromDate(
              new Date(planData?.NextChargeDate)
            )}
            className=""
          />
        </div>
      </>
    );
  };

  const isDataToggle = (id: any) => {
    let currentIds = [...selectedIds];
    return currentIds.includes(id);
  };

  const setDataToggleIds = (id: any) => {
    let currentIds = [...selectedIds];
    let findIndex = currentIds?.findIndex((value) => value === id);
    if (findIndex === -1) {
      currentIds.push(id);
    } else {
      currentIds.splice(findIndex, 1);
    }
    setSelectedIds(currentIds);
  };

  const renderProducts = (products: any) => {
    return products?.map((data: any) => {
      return (
        <div>
          {data?.Name}: {data.UserCount}users
        </div>
      );
    });
  };

  const getToggleRenderer = (data: any) => {
    let isToggleData = isDataToggle(data.CouponID);
    return (
      <div className="column parent-width">
        <div
          className="row cursor-hand"
          onClick={() => setDataToggleIds(data.CouponID)}
        >
          {!isToggleData && (
            <DKIcon src={DKIcons.ic_arrow_right2} className="ic-xs mr-s" />
          )}
          {isToggleData && (
            <DKIcon src={DKIcons.ic_arrow_down2} className="ic-xs mr-s" />
          )}
          <DKLabel text={data.Code} className="" />
        </div>
        {isToggleData && (
          <div className="column parent-width ml-l mb-s">
            <div className="row justify-content-between">
              <DKLabel text={'Plan'} className="fw-m" />
              <DKLabel text={data?.PlanName} className="" />
            </div>
            <div className="row justify-content-between align-items-start">
              <DKLabel text={'Products'} className="fw-m" />
              <div className="column parent-width align-items-end">
                {renderProducts(data?.Products || [])}
              </div>
            </div>
            <div className="row justify-content-between">
              <DKLabel text={'Prepaid Duration'} className="fw-m" />
              <DKLabel text={`${data.PrepaidDuration} months`} className="" />
            </div>
            <div className="row justify-content-between">
              <DKLabel text={'Redeemed Date'} className="fw-m" />
              <DKLabel
                text={DateFormatService.getDateStrFromDate(
                  new Date(data?.RedeemedDate)
                )}
                className=""
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const getPrepaidCouponsRenderer = (prepaidCoupons: any) => {
    return prepaidCoupons?.map((list: any) => getToggleRenderer(list));
  };

  return (
    <div className="column parent-width p-h-m">
      <div className="column border-b parent-width pb-m">
        <div className="row parent-width mt-2">
          <DKLabel
            text="Organisation"
            className="fw-m"
            style={{ width: 180 }}
          />
          <DKLabel text={props.subscriptionData?.TenantName} className="" />
        </div>

        <div className="row parent-width mt-2">
          <DKLabel text="Email" className="fw-m" style={{ width: 180 }} />
          <DKLabel text={props.contact?.emailId} className="" />
        </div>
      </div>
      <div className="column border-b parent-width p-v-m">
        <div className="row parent-width mt-2">
          <DKLabel text="Payment" className="fw-m" style={{ width: 180 }} />
          <DKLabel
            text={
              props.subscriptionData.planDetails?.CardAvailable
                ? 'Card'
                : 'No payment added'
            }
            className=""
          />
        </div>

        <div className="row parent-width mt-2 align-items-start">
          <DKLabel
            text="Prepaid Code(s)"
            className="fw-m"
            style={{ width: 180 }}
          />
          {props.subscriptionData.planDetails?.PrepaidCoupons?.length > 0 ? (
            <div className="column parent-width" style={{ width: 225 }}>
              {getPrepaidCouponsRenderer(
                props.subscriptionData.planDetails?.PrepaidCoupons
              )}
            </div>
          ) : (
            '-'
          )}
          {/* <DKLabel
            text={
              props.subscriptionData.planDetails?.PrepaidCoupons?.length > 0
                ? props.subscriptionData.planDetails?.PrepaidCoupons?.map(
                    (list: any) => list.Code
                  )
                : '-'
            }
            className=""
          /> */}
        </div>

        <div className="row parent-width mt-2">
          <DKLabel
            text="Discount Code(s)"
            className="fw-m"
            style={{ width: 180 }}
          />
          <DKLabel
            text={
              props.subscriptionData.planDetails?.DiscountCoupons?.length > 0
                ? 'DiscountCoupons'
                : '-'
            }
            className=""
          />
        </div>
      </div>
      <div className="column parent-width p-v-m">
        {props.subscriptionData.planDetails?.['AIOPlan']?.Name &&
          getPlanDetails(
            'All-In-One Plan',
            props.subscriptionData.planDetails?.['AIOPlan']
          )}
        {props.subscriptionData.planDetails?.['ERPPlan']?.Name &&
          getPlanDetails(
            'Books Plan',
            props.subscriptionData.planDetails?.['ERPPlan']
          )}
        {props.subscriptionData.planDetails?.['CRMPlan']?.Name &&
          getPlanDetails(
            'CRM Plan',
            props.subscriptionData.planDetails?.['CRMPlan']
          )}
        {props.subscriptionData.planDetails?.['PeoplePlan']?.Name &&
          getPlanDetails(
            'People Plan',
            props.subscriptionData.planDetails?.['PeoplePlan']
          )}
      </div>
    </div>
  );
};

export default SubscriptionDetailPopup;
