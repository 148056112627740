import ApiConstants from '../../Constants/ApiConstants';
import http from '../Interceptor';
import { showLoader, removeLoader } from 'deskera-ui-library';

class ProcessManufacturingService {
  static fetchAllJobCardsByJCCode(code: any) {
    return http
      .get(
        ApiConstants.URL.MRP.PROCESS_MANUFACTURE.GET_JC_DETAILS_LIST_BY_JCCODE(
          code
        )
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static addOrUpdateProcessDetailsJC(payload: any) {
    return http
      .post(
        ApiConstants.URL.MRP.PROCESS_MANUFACTURE.ADD_UPDATE_DETAILS,
        payload
      )
      .catch((err: any) => {
        console.error(
          'Error while adding/updating process manufacturing details: ',
          err
        );
        return Promise.reject(err);
      });
  }

  static deleteProcessDetailsJC(id: any) {
    return http
      .delete(`${ApiConstants.URL.MRP.PROCESS_MANUFACTURE.DELETE_JC_DETAILS}`, {
        data: id
      })
      .catch((err: any) => {
        console.error('Error deleting process details: ', err);
      });
  }

  static fetchAvailableDetailsToAssign(produceCode: any, workOrderCode: any) {
    const query = `?workOrderCode=${workOrderCode}`;
    const finalUrl =
      ApiConstants.URL.MRP.PROCESS_MANUFACTURE.GET_AVAILABLE_TO_ASSIGN_DETAILS(
        produceCode
      ) + query;
    return http
      .get(finalUrl)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchWODetailsByJCCode(jcCode: any) {
    return http
      .get(
        ApiConstants.URL.MRP.PROCESS_MANUFACTURE.GET_WO_DETAILS_BY_JC_CODE(
          jcCode
        )
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static assignAllocatedQuantities(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.PROCESS_MANUFACTURE.ASSIGN_QUANTITIES, payload)
      .catch((err: any) => {
        console.error('Error while assigning allocated quantities: ', err);
        return Promise.reject(err);
      });
  }

  static jcDetailsCompletion(ids: number[]) {
    showLoader();
    return http
      .put(ApiConstants.URL.MRP.JOB_CARD.JC_DETAILS_COMPLETION, ids)
      .then((res: any) => {
        removeLoader();
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        removeLoader();
        console.error(
          'Error while updating process manufacturing completins details: ',
          err
        );
        return Promise.reject(err);
      });
  }

  static autoConsumption(workOrderCode: any) {
    return http
      .post(
        ApiConstants.URL.MRP.PROCESS_MANUFACTURE.AUTO_CONSUMPTION(workOrderCode)
      )
      .catch((err: any) => {
        console.error('Error while autoconsumption: ', err);
        return Promise.reject(err);
      });
  }
}

export default ProcessManufacturingService;
