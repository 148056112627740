import { PAGE_BORDER_PADDING_DOUBLE, PAGE_PADDING } from "../Constants/Constants"
import { HTMLTag } from "../Utilities/HTMLTag"
import { getBoundingClientRect, getPageHeight, getPageWidth, getPX, getVW } from "../Utilities/Utility"
import AppManager from "./AppManager"
import TemplateSettingsManager from "./TemplateSettingsManager"

export default class MultiPageManager {

  static marginBottom = -1
  static addedMarginBottom = []
  static isMultiPage = []
  static addedMarginBottomSum = 0
  static dimensions = undefined

  static getPaddingBottom(isLoaded, printInfo) {

    // isMultiplePage is from print api
    var isMultiplePage = AppManager.getIsMultiplePage()
    if (isMultiplePage !== undefined && isMultiplePage !== null) {
      return this.getIsMultiplePageMargin(isMultiplePage)
    }
    else if(TemplateSettingsManager.getIsMultipleDocument()) {
      return getVW('0vw', true)
    }

    //calculate the page height n compare to default page size
    var currentMarginBottom = AppManager.getMarginBottom()

    var element = getBoundingClientRect(HTMLTag.ONLY_TEMPLATE)
    var height = 0
    var width = 0
    if (element) {
      width = element.width
      height = element.height
    }


    if (isLoaded
          && currentMarginBottom === undefined
          && !isNaN(width)
          && TemplateSettingsManager.pageFormat !== undefined) {
      var dimensions = {}
      dimensions.printInfo = printInfo
      dimensions.elementSize = element
      dimensions.multi = false
      dimensions.paperFormat = TemplateSettingsManager.pageFormat
      dimensions.windowHeight = height
      dimensions.windowWidth = width
      dimensions.windowRatio = height / width

      var offsetPadding = 9.26672 // refer to app.css margin top and bottom

      var pageWidth = getPX(getPageWidth())

      var tempPageHeight = Number(getPageHeight().replace('vw', ''))

      if (dimensions.paperFormat === 'A4') {
        tempPageHeight = Number(getPageHeight().replace('vw', '')) - offsetPadding + (Number(getVW(PAGE_PADDING, true).replace('vw', '')) * 2)
      }

      var pageHeight = getPX(tempPageHeight + 'vw')

      dimensions.heightThreshold = 3
      dimensions.defaultPageHeight = pageHeight
      dimensions.defaultPageWidth = pageWidth
      dimensions.defaultPageRatio = pageHeight / pageWidth
      dimensions.normalizeRatio = 1
      if (dimensions.paperFormat !== 'A4') {
        if (Math.abs(dimensions.defaultPageRatio - dimensions.windowRatio) < 0.1) {
          dimensions.normalizeRatio = dimensions.windowRatio / dimensions.defaultPageRatio
        }
      }
      else {
        dimensions.heightThreshold = -5
      }

      var ratio = pageWidth / width
      var expectedHeight = height * ratio / dimensions.normalizeRatio
      var expectedWidth = width * ratio

      dimensions.computeHeight = expectedHeight
      dimensions.computeWidth = expectedWidth
      dimensions.computeRatio = expectedHeight / expectedWidth / dimensions.normalizeRatio
      dimensions.ratio = ratio
      dimensions._normalizeRatio = ratio / dimensions.normalizeRatio
      //check if multi page needed
      //page height is default height
      // expected height is from the content
      var differentHeight = expectedHeight - pageHeight - this.addedMarginBottomSum
      dimensions.differentHeight = differentHeight
      var heightRemain = 0
      var pageCount = 1
      if (differentHeight > dimensions.heightThreshold) {
        dimensions.multi = true
        heightRemain = differentHeight
        //check computed value to determine how many pages
        pageCount = pageCount + 1
        while (heightRemain > pageHeight) {
          heightRemain = heightRemain - pageHeight
          pageCount = pageCount + 1
        }
        heightRemain = pageHeight - heightRemain // to get the total height for new page
      }
      else {
        //same page
        heightRemain = 0
      }
      dimensions.heightRemain = heightRemain
      dimensions.pageCount = pageCount

      dimensions.marginBottomSum = this.addedMarginBottomSum
      if (!isNaN(differentHeight)) {
        this.isMultiPage.push(dimensions.multi)
      }

      var margin = this.getMarginBottomByHeight(heightRemain)
      dimensions.marginBottom = margin
      this.dimensions = dimensions
      AppManager.setMarginBottom(margin)
      return margin
    }
    return currentMarginBottom !== undefined ? currentMarginBottom : getVW(PAGE_PADDING, true)
  }

  static getMarginBottomByHeight(heightRemain) {
    if (heightRemain > getPX(PAGE_BORDER_PADDING_DOUBLE)) {
      return getVW(PAGE_BORDER_PADDING_DOUBLE, true)
    }
    else if (heightRemain > getPX(PAGE_PADDING)) {
      return getVW(PAGE_PADDING, true)
    }
    else {
      if (heightRemain > 10) {
        return getVW(heightRemain - 10)
      }
      else {
        return getVW(0)
      }
    }
  }

  static getIsMultiplePageMargin(isMultiplePage) {
    var dimensions = {}
    dimensions.paperFormat = TemplateSettingsManager.pageFormat
    dimensions.differentHeight = ''
    var elementSize = {
      width: '',
      height: '',
    }
    dimensions.elementSize = elementSize
    dimensions.multi = isMultiplePage
    if (isMultiplePage === 'true' || isMultiplePage === true) {
      dimensions.marginBottom = getVW(PAGE_BORDER_PADDING_DOUBLE, true)
    }
    else {
      dimensions.marginBottom = getVW('0vw', true)
    }
    this.dimensions = dimensions
    return dimensions.marginBottom
  }

  static getDebugMessage() {
    if(this.dimensions !== undefined && this.dimensions !== null) {
      return this.dimensions.paperFormat + ' ' + this.dimensions.multi + ' ' + this.dimensions.marginBottom + ' ' + this.dimensions.differentHeight + ' w' + this.dimensions.elementSize.width + ' h' + this.dimensions.elementSize.height
    }
    return ''
  }
}
//