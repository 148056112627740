import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_STATUS } from "../../Constants/Constant";
import { COLUMN_CODE } from "../../Constants/TableConstants";
import { GridState } from "../../Models/ReduxStore";
import { IColumn } from "../../Models/Table";
import FulfillmentApprovalService from "../../Services/FulfillmentApprovalService";
import { RootState } from "../Store";

const hideFulfillmentApprovalColumnConfig = [
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.CUSTOMER_NAME,
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.CUSTOMER_CODE,
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.TOTAL_AMOUNT_FF,
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.CUSTOMER_ORDER_NO,
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.QC_STATUS,
  COLUMN_CODE.BOOKS_FULFILLMENT_APPROVAL_LIST.MEMO,
];

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  content: [] as any,
  
};

export const fetchFulfillmentApprovalList = createAsyncThunk('fetchFulfillmentApprovalList', async () => {
  const response = await FulfillmentApprovalService.getFulfillmentApprovalData();
  return response;
});

export const FulfillmentApprovalSlice = createSlice({
  name: 'fulfillment_approval',
  initialState,  
  reducers:{
    addFulfillmentApprovalColumnConfig:(state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        const includes = hideFulfillmentApprovalColumnConfig.includes(config.columnCode);
        if(!includes) {
          configsToStore.push({
            ...config,
            key: config.columnCode,
            options: config.options ? config.options : []
          });
        }
      });
      state.columnConfig = configsToStore;
    },
    addFulfillmentApproval:(state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchFulfillmentApprovalList.pending, (state) => {
      state.status = API_STATUS.LOADING;
    })
    .addCase(fetchFulfillmentApprovalList.fulfilled, (state, action) => {
      state.status = API_STATUS.IDLE;
      state.data = action.payload;
    })
  }
})

export const {addFulfillmentApproval, addFulfillmentApprovalColumnConfig} = FulfillmentApprovalSlice.actions;

export const selectFulfilmentApprovalData = (state:RootState) => state.fulfillmentApproval;