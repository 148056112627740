import { DKButton, DKLabel } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  getSubstitute,
  ProductSubstitutePopup
} from './ProductSubstitutePopup';

export default function SubstituteProductTabView(props: any) {
  const [product, setProduct] = useState(props.product);
  const [showSubsPopup, setShowSubsPopup] = useState(false);

  useEffect(() => {
    props.onUpdate(product);
  }, [product]);

  const getAddNewButton = () => {
    return (
      <DKButton
        title={`+ Add Substitute`}
        onClick={() => setShowSubsPopup(true)}
        className={`fw-m p-0 text-app-color mt-m`}
        disabled={false}
        style={{ zIndex: 1, width: 120, padding: 0 }}
      />
    );
  };
  const onSubsAdded = (selectedSubs: any) => {
    const copyOfProduct = deepClone(product);
    copyOfProduct['productSubstitutesDetails'] = selectedSubs;
    setProduct(copyOfProduct);
    setShowSubsPopup(false);
  };
  const onSubstituteRemove = ({ productName }: any) => {};
  const { productSubstitutesDetails } = product;
  return (
    <div className="column parent-width mt-l">
      <DKLabel className="fw-m fs-m text-app-color" text="Substitute Details" />
      <div
        className="bg-gray1 border-radius-s row flex-wrap p-r mt-s"
        style={{ gap: 8 }}
        onClick={() => {
          setShowSubsPopup(true);
        }}
      >
        {!Utility.isEmpty(product?.productSubstitutesDetails) ? (
          productSubstitutesDetails?.map(getSubstitute)
        ) : (
          <div className="column parent-width h-20 align-items-center justify-content-center">
            <DKLabel
              className="text-gray"
              text="No substitute product added. "
            />
          </div>
        )}
      </div>
      {getAddNewButton()}
      {showSubsPopup && (
        <ProductSubstitutePopup
          product={product}
          onSave={onSubsAdded}
          onClose={() => setShowSubsPopup(false)}
          selectedProductsIds={product?.productSubstitutesDetails || []}
        />
      )}
    </div>
  );
}
interface IProductSubsPopup {
  onSave?: (selectedProducts: any[]) => void;
  onClose?: () => void;
  selectedProductsIds?: string[];
}
