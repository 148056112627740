import { DKLabel } from 'deskera-ui-library';
import { TABLES } from '../Constants/TableConstants';
import { CLIENT_COLS } from '../StaticColumnConfigs/StaticColumnConfigs';
import { deepClone } from './Utility';

export class ConfigUtility {
  static structuredAccountsArr: any = [];

  static nestedChildren = (obj: any, index: any) => {
    obj.children?.forEach((child: any) => {
      ConfigUtility.getNewAccountsRenderer(child, index + 1);
    });
  };

  static getNewAccountsRenderer = (obj: any, index: any) => {
    let newObj = {
      ...obj,
      parentLevel: index
    };

    //this condition makes sure you dont push duplicate entries in the list
    if (
      ConfigUtility.structuredAccountsArr?.findIndex(
        (item: any) => item.id === newObj.id
      ) === -1
    ) {
      ConfigUtility.structuredAccountsArr.push(newObj);
    }

    ConfigUtility.nestedChildren(obj, index);
  };

  static newStructuredData = (arr: any) => {
    arr?.forEach((item: any, index: any) => {
      ConfigUtility.getNewAccountsRenderer(item, index);
    });
  };

  static fillStructuredAccountArray = (newData: any) => {
    newData?.forEach((item: any, index: any) => {
      ConfigUtility.getNewAccountsRenderer(item, 0);
    });
  };

  static getAccountRow = (obj: any) => {
    const isRootParent = obj.parentLevel === 0;
    return (
      <div
        className={`row parent-height align-item-center justify-content-between`}
        style={{
          padding: 0,
          marginLeft: !isRootParent ? `${obj.parentLevel * 5}px` : 0
        }}
      >
        <DKLabel
          text={obj.name}
          className={`align-item-center pt-0.5 ${
            isRootParent ? 'fw-r fs-r' : ''
          }`}
        />
        <DKLabel
          text={obj.accountGroup ? obj.accountGroup : ''}
          className="text-gray fs-s text-trailing"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        />
      </div>
    );
  };

  static getAccountsRenderer = (obj: any) => {
    return (
      <div
        className={`row parent-height align-item-center justify-content-between`}
        style={{ padding: 0 }}
      >
        <DKLabel
          text={obj.name}
          className={`align-item-center pt-0.5 fs-r ${
            obj.isSubAccount ? 'ml-l' : ''
          }`}
        />
        <DKLabel
          text={obj.accountGroup ? obj.accountGroup : ''}
          className="text-gray fs-s text-trailing"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        />
      </div>
    );
  };

  static getStaticConfigColumns = (tableName: any) => {
    switch (tableName.toUpperCase()) {
      case TABLES.BOOKKEEPER_CLIENT:
        let clientColCopy = deepClone(CLIENT_COLS);
        let clientCols: any = clientColCopy.filter(
          (item: any) => item.columnCode === 'status'
        );
        let newOptions: any[] = [];
        clientCols[0]?.options?.map((i: any) => {
          if (i.id !== 'INVITED') {
            newOptions.push(i);
          }
        });
        clientCols[0].options = newOptions;
        return clientCols;

      default:
        return [];
    }
  };
}
