import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKCheckMark,
  DKLabel,
  DKButton,
  DKInput,
  showAlert
} from 'deskera-ui-library';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized,
  getRandomNumber
} from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';

export const ILCertificatePopup = (props: any) => {
  //states
  const [code, setCode] = useState<any>(props?.currentReason?.code);

  const [number, setnumber] = useState(props?.currentReason?.number);
  const [withHoldingTaxPercentage, setwithHoldingTaxPercentage] = useState(
    props?.currentReason?.withHoldingTaxPercentage || 0
  );
  const [validFrom, setValidFrom] = useState(
    props?.currentReason?.validFrom || new Date()
  );
  const [validTo, setvalidTo] = useState(
    props?.currentReason?.validTo || new Date()
  );
  const [isDetailsFormSubmitted, setIsDetailsFormSubmitted] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(
    !Utility.isEmpty(props?.currentReason) ? true : false
  );
  const tenantInfo = useAppSelector(activeTenantInfo);

  //effects
  useEffect(() => {
    // if (isFormEdit) {
    //   setCode();
    // }
  }, []);

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '40%',
          // height: "85%",
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {/* header */}
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text={`${!isFormEdit ? 'Add' : 'Update'} Certificate Details`}
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="bg-white border-m mr-r"
              onClick={() => {
                props.onCancel();
              }}
            />
            <DKButton
              title={!isFormEdit ? 'Save' : 'Update'}
              className="bg-button text-white"
              onClick={() => {
                setIsDetailsFormSubmitted(true);

                if (
                  number?.trim('')?.length > 0 &&
                  withHoldingTaxPercentage?.toString()?.trim('')?.length > 0
                ) {
                  if (validTo.getTime() < validFrom.getTime()) {
                    showAlert(
                      'Invalid Date',
                      `Valid To date cannot be before valid from date `
                    );
                    return;
                  }
                  if (!isFormEdit) {
                    // create
                    if (
                      !Utility.isEmpty(number) &&
                      number?.trim('')?.length > 0
                    ) {
                      const payload = {
                        number,
                        validFrom,
                        validTo,
                        withHoldingTaxPercentage
                      };
                      props.onSave(payload);
                    }
                  } else {
                    // edit
                    const payload = {
                      number,
                      validFrom,
                      validTo,
                      withHoldingTaxPercentage
                    };
                    props.onEdit(payload, props?.currentReason?.index);
                  }
                }
              }}
            />
          </div>
        </div>
        {/* form */}
        <div className="parent-width p-l">
          <div className="parent-width row justify-between">
            {/* subtype name */}
            <DKInput
              title="Certificate Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={number}
              className="mr-5"
              onChange={(val: any) => {
                setnumber(val);
              }}
              canValidate={isDetailsFormSubmitted}
            />
            <DKInput
              title="WHT % "
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={withHoldingTaxPercentage}
              className=""
              onChange={(val: any) => {
                if (Number(val) > 100) {
                  setwithHoldingTaxPercentage(0);
                } else {
                  setwithHoldingTaxPercentage(val);
                }
              }}
              canValidate={isDetailsFormSubmitted}
            />
          </div>
          <div className="parent-width row mt-l justify-between">
            {/* subtype name */}
            <DKInput
              title="Valid From"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DATE}
              value={validFrom}
              className="mr-5"
              required={true}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              onChange={(val: any) => {
                setValidFrom(val);
              }}
              canValidate={isDetailsFormSubmitted}
            />
            <DKInput
              title="Valid To"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DATE}
              required={true}
              value={validTo}
              className=""
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              onChange={(val: any) => {
                setvalidTo(val);
              }}
              canValidate={isDetailsFormSubmitted}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
