import React from 'react';
import './DraggableImage.css';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import {TemplateItem } from '../model/TemplateItem';
import { CoordinateUtility, Coordinate } from '../../utilities/CoordinateUtility';
import { ScreenState } from '../model/Enum';
import Utility, { getIsDocBuilderApp, getVW } from '../../../../Utilities/Utility';
import { HTMLTag } from '../../../../Utilities/HTMLTag';
import { PREVIEW_SCALE } from '../../../../Constants/Constants';
import { Asset } from '../../../../assets';


interface Props {
    templateItem: TemplateItem
    imgDefaultSize: number
    componentButtonsRequired: boolean
    scale: number
    handleStart: DraggableEventHandler
    handleDrag: DraggableEventHandler
    handleStop: DraggableEventHandler
    onMouseDown: (e: MouseEvent) => void,
    grid: [number, number]
    coordinateUtility: CoordinateUtility
    disable: boolean
    screenState: ScreenState
    selectedTemplateId: number
    printScale: number
}

interface States {
}
export default class DraggableImage extends React.Component<Props, States> {

    getRelativeImagePath(image: string) {
        if (image?.startsWith("data:")) {
            return image;
        } else if (image?.includes("signature")) {
            return Asset.icon.signature_1;
        } else if (image?.includes("watermark")) {
            return Asset.icon.watermark;
        } else {
            return image;
        }
    }

    render() {
        var coordinate = new Coordinate(0, 0, this.props.templateItem.x, this.props.templateItem.y, this.props.templateItem.originalWindowInnerWidth)
        var id = HTMLTag.DRAGGABLE_COMPONENT_PREFIX + this.props.templateItem.id
        var image = this.getRelativeImagePath(this.props.templateItem.image);
        var position = this.props.coordinateUtility.toPosition(coordinate, this.props.printScale === PREVIEW_SCALE);

        return (
            <Draggable
                axis="both"
                handle=".handle"
                disabled={this.props.disable}
                // defaultPosition={{
                //     x: this.props.coordinateUtility.toPosition(coordinate).x,
                //     y: this.props.coordinateUtility.toPosition(coordinate).y
                // }}
                position={{
                    x: position.x,
                    y: position.y
                }}
                grid={this.props.grid}
                scale={this.props.scale}
                defaultClassName={this.isShowBorder() ? "draggableBorderDiv" : "draggableDiv"}
                onStart={(e, data) => this.props.handleStart(e, data)}
                onDrag={(e, data) => this.props.handleDrag(e, data)}
                onStop={(e, data) => this.props.handleStop(e, data)}
                onMouseDown={(e) => this.props.onMouseDown(e)}
            >
                <div id={id} className="handle" style={{
                    zIndex: getIsDocBuilderApp() ? this.props.templateItem.style.zIndex : 4,
                    opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                    padding: 0,
                    width: this.props.templateItem.width ? getVW(Utility.convertToPrintScale(this.props.templateItem.width, this.props.printScale) * (this.props.templateItem.imageRatio / 100)) : undefined,
                    height: this.props.templateItem.height ? getVW(Utility.convertToPrintScale(this.props.templateItem.height, this.props.printScale) * (this.props.templateItem.imageRatio / 100)) : undefined,
                }}>
                    <img
                        crossOrigin='anonymous'
                        className='docImg'
                        style={{
                            opacity: this.props.templateItem.style.opacity ? this.props.templateItem.style.opacity : 1,
                            width: '100%',
                            height: '100%',
                            zIndex: getIsDocBuilderApp() ? this.props.templateItem.style.zIndex : 4
                        }}
                        src={image}
                        draggable={false}
                        alt={''} />
                </div>
            </Draggable>
        );
    }

    isShowBorder() {
        if (this.props.screenState === ScreenState.VIEW) {
            return false
        }
        if (this.props.templateItem.id === this.props.selectedTemplateId) {
            return true
        }
        else {
            return false
        }
    }
}
