import {
  DKDataGrid,
  INPUT_TYPE,
  DKIcon,
  DKLabel,
  DKIcons
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import SideBarService from '../../../Services/sidebar';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../Utility/Utility';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import { SOA_CUSTOMER_COLUMN_CODE } from './ColumnConfigs/StatementOfAccountColumnConfig';

export default function ReportGridHolder(props: any) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const initialState: any = {
    currentPage: 1,
    totalPageCount: 1
  };

  const [state, setState] = useState<any>(initialState);
  let [selection, setSelection] = useState<any[]>([]);

  useEffect(() => {
    setState({
      totalPageCount: props.totalPageCount ?? 1,
      currentPage: props.currentPage ?? 1
    });
  }, [props]);

  useEffect(() => {
    console.log(state, 'state');
  }, [state]);

  const getColumnConfig = () => {
    const actionColumnConfig = {
      key: 'actions',
      name: props.actionColumnName ? props.actionColumnName : 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: props.actionColumnWidth ? props.actionColumnWidth : 150,
      options: props.hasAction
        ? [
            {
              title: 'View Sales Details',
              className: 'p-h-0 p-v-0 fw-m text-blue underline',
              onClick: (data: any) => {
                if (props.onRowEdit) {
                }
              }
            }
          ]
        : []
    };
    let columnConfigCopy = [...props.columnConfig];
    return props.needActionColumn
      ? [...columnConfigCopy, actionColumnConfig]
      : columnConfigCopy;
  };

  const onPagination = (requestedPageNumber: number) => {
    if (props.onPaginationClick) {
      props.onPaginationClick(requestedPageNumber);
    }
  };

  const onRowSelect = (data: any = null) => {
    if (data) {
      //setDocType(data.rowData.rowType);
      const updatedRows = [...selection];
      const index = selection.findIndex((item) => item.id === data.rowData.id);
      if (index === -1) {
        updatedRows.push(data.rowData);
      } else {
        updatedRows.splice(index, 1);
      }
      setSelection([...updatedRows]);
    }
    updateSelection(false);
  };
  const updateSelection = (reset = false) => {
    if (reset) {
      setSelection([]);
      onRowSelect && onRowSelect(null);
    }
  };
  const bulkDeletedClicked = () => {
    if (props.onBulkDelete) {
      props.onBulkDelete(selection);
    }
    setState({
      ...state,
      showContextMenu: false
    });
  };

  const getNoDataView = () => {
    return (
      <div
        className={`column parent-size align-items-center justify-content-center mt-m mb-xl ${
          !props.isNoDataAbsolute ? 'position-absolute' : ''
        }`}
        style={{ pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel className="fs-m fw-m mt-l" text={'No data found'} />
        <DKLabel
          className="text-dark-gray mt-s text-align-center"
          text={'No data available for your selection.'}
        />
      </div>
    );
  };
  return (
    <div
      className={`column parent-width flex-1 parent-height position-relative ${
        props.needNoAlignment ? 'align-items-left' : 'align-items-center'
      }`}
    >
      {!Utility.isEmpty(tenantInfo) && (
        <div
          className={` column parent-width parent-height flex-1 flex items-center justify-center ${
            props?.mainAlignmentCenter ? 'align-items-center' : ''
          } `}
        >
          <DKDataGrid
            needShadow={true}
            needColumnIcons={props.needColumnIcons}
            needBorder={true}
            needTrailingColumn={props.needTrailingColumn ?? true}
            allowBulkOperation={
              props.allowBulkOperation ? props.allowBulkOperation : false
            }
            allowColumnSort={
              props.allowColumnSort ? props.allowColumnSort : false
            }
            buttons={props.headerButtons ? props.headerButtons : []}
            onSort={props.onSort}
            filterData={props.filterData || []}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
            allowColumnDelete={false}
            allowRowEdit={
              props?.documentType === 'OUTSTANDING_FULFILL_REPORT'
                ? false
                : true
            }
            allowColumnEdit={false}
            allowColumnShift={
              typeof props.allowColumnShift === 'boolean'
                ? props.allowColumnShift
                : true
            }
            allowFilter={props.allowFilter || false}
            onFilter={props.onFilter}
            allowColumnAdd={false}
            allowBottomRowAdd={false}
            onSearch={props.onSearch}
            allowSearch={props.allowSearch || false}
            allowShare={false}
            width={
              props.width ? props.width : SideBarService.getContainerWidth()
            }
            rows={props.rows}
            columns={getColumnConfig()}
            onRowSelect={(data: any) => {
              props.onRowSelect(data);
            }}
            isAllRowSelected={props.isAllRowSelected || false}
            onAllRowSelect={(data: any) => {
              typeof props?.onRowSelect === 'function' &&
                typeof props?.onAllRowSelect === 'function' &&
                props.onAllRowSelect(data);
            }}
            onRowClick={(data: any) => {
              if (props.onRowClick) {
                props.onRowClick(data);
              }
            }}
            onPrevPageTapped={() => {
              props.onPrevPageTapped && props.onPrevPageTapped();
              onPagination(state.currentPage - 1);
            }}
            onNextPageTapped={() => {
              props.onNextPageTapped && props.onNextPageTapped();
              onPagination(state.currentPage + 1);
            }}
            onRowOpenClick={
              typeof props.onRowOpenClick === 'function'
                ? props.onRowOpenClick
                : null
            }
            currentPage={state.currentPage}
            totalPageCount={state.totalPageCount}
            allowExpand={props.expand ? true : false}
            needNoDataView={!props?.rows?.length}
            onRowExpand={(data: any) => {
              // let data_ = this.state.rows[data.rowIndex];
              props.rowExpand(data);
              // alert(JSON.stringify(data))
            }}
          />
        </div>
      )}
      {props?.rows && props?.rows?.length === 0 && getNoDataView()}
    </div>
  );
}
