export const ENGINE_CONSTANTS = {
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  LESS_THAN: 'lessThan',
  LESS_THAN_INCLUSIVE: 'lessThanInclusive',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_INCLUSIVE: 'greaterThanInclusive',
  IN: 'in',
  NOT_IN: 'notEqual',
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'doesNotContain'
};

export const myConfig = {
  //   module: 'invoice',
  countryCode: 'my',
  hasTaxGroup: false,
  fields: [
    {
      id: 0,
      name: '+Tax (MYR)',
      description: '+Tax (MYR)',
      key: 'taxAmount',
      objectField: '',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    }
  ]
};

export const indonesiaConfig = {
  //   module: 'invoice',
  countryCode: 'ID',
  hasTaxGroup: false,
  showUnitPriceInclusive: true,
  fields: [
    {
      id: 0,
      name: '+Vat (IDR)',
      description: '+Vat (IDR)',
      key: 'taxAmount',
      objectField: '',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    },
    {
      id: 3,
      name: 'Tax Invoice No.',
      description: 'Tax Invoice No.',
      key: 'taxInvoiceNo',
      objectField: 'taxInvoiceNo',
      type: 'textField',
      dataType: 'string',
      placeholder: '',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'header'
    }
  ]
};

export const phConfig = {
  //   module: 'invoice',
  countryCode: 'ph',
  hasTaxGroup: false,
  fields: [
    {
      id: 0,
      name: '+Vat (PHP)',
      description: '+Vat (PHP)',
      key: 'taxAmount',
      objectField: '',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    }
  ]
};

export const uaeConfig = {
  //   module: 'invoice',
  countryCode: 'ae',
  hasTaxGroup: false,
  fields: [
    {
      id: 0,
      name: '+Vat (AED)',
      description: '+Vat (AED)',
      key: 'taxAmount',
      objectField: '',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    }
  ]
};

export const nzConfig = {
  //   module: 'invoice',
  countryCode: 'nz',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const sgConfig = {
  //   module: 'invoice',
  countryCode: 'sg',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const germanyConfig = {
  //   module: 'invoice',
  countryCode: 'de',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const ukConfig = {
  //   module: 'invoice',
  countryCode: 'uk',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const belgiumConfig = {
  //   module: 'invoice',
  countryCode: 'be',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const ausConfig = {
  //   module: 'invoice',
  countryCode: 'au',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const caConfig = {
  //   module: 'invoice',
  countryCode: 'ca',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const usConfig = {
  //   module: 'invoice',
  countryCode: 'us',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const ilConfig = {
  //   module: 'invoice',
  countryCode: 'il',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const krConfig = {
  countryCode: 'kr',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: []
};

export const inConfig = {
  //remove
  //   module: 'invoice',
  countryCode: 'in',
  hasTaxGroup: true,
  showUnitPriceInclusive: true,
  fields: [
    {
      id: 0,
      name: '+SGST',
      description: '+SGST',
      key: 'sgst',
      objectField: 'sgst',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    },
    {
      id: 0,
      name: '+IGST',
      description: '+IGST',
      key: 'igst',
      objectField: 'igst',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    },
    {
      id: 0,
      name: '+CESS',
      description: '+CESS',
      key: 'cess',
      objectField: 'cess',
      type: 'label',
      optionValues: [],
      default: '',
      rules: {},
      optionApi: '',
      captureOption: [],
      optionNameKey: '',
      optionValueKey: '',
      apiMethod: '',
      required: true,
      editable: true,
      display: true,
      sectionType: 'subtotalTax'
    }
  ]
};

// export const germanyConfig = {
//   module: 'invoice',
//   countryCode: 'de',
//   hasTaxGroup: true,
//   showUnitPriceInclusive: true,
//   fields: [
//     {
//       id: 0,
//       name: 'Before VAT',
//       description: 'Before VAT',
//       key: 'beforeTax',
//       objectField: 'taxAmount',
//       type: 'label',
//       optionValues: [],
//       default: '',
//       rules: {},
//       optionApi: '',
//       captureOption: [],
//       optionNameKey: '',
//       optionValueKey: '',
//       apiMethod: '',
//       required: true,
//       editable: true,
//       display: true,
//       sectionType: 'subtotalTax'
//     },
//     {
//       id: 0,
//       name: '+Vat (IDR)',
//       description: '+Vat (IDR)',
//       key: 'taxAmount',
//       objectField: '',
//       type: 'label',
//       optionValues: [],
//       default: '',
//       rules: {},
//       optionApi: '',
//       captureOption: [],
//       optionNameKey: '',
//       optionValueKey: '',
//       apiMethod: '',
//       required: true,
//       editable: true,
//       display: true,
//       sectionType: 'subtotalTax'
//     }
//   ]
// };

// export const beConfig = {
//   module: 'invoice',
//   countryCode: 'be',
//   hasTaxGroup: true,
//   fields: [
//     {
//       id: 0,
//       name: 'Contact VAT Number',
//       description: 'Contact VAT Number',
//       key: 'contactVatNumber',
//       objectField: '',
//       type: 'label',
//       optionValues: [],
//       default: '',
//       rules: {},
//       optionApi: '',
//       captureOption: [],
//       optionNameKey: '',
//       optionValueKey: '',
//       apiMethod: '',
//       required: true,
//       editable: true,
//       display: true,
//       sectionType: 'header'
//     },
//     {
//       id: 0,
//       name: '+Tax',
//       description: '+Tax',
//       key: 'tax',
//       objectField: '',
//       type: 'label',
//       optionValues: [],
//       default: '',
//       rules: {},
//       optionApi: '',
//       captureOption: [],
//       optionNameKey: '',
//       optionValueKey: '',
//       apiMethod: '',
//       required: true,
//       editable: true,
//       display: true,
//       sectionType: 'subtotalTax'
//     }
//   ]
// };
