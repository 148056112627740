import React, { useEffect, useState } from 'react';
import {
  showToast,
  DKButton,
  DKLabel,
  Toggle,
  TOAST_TYPE,
  DKCheckMark
} from 'deskera-ui-library';
import { CallBackPayloadType } from '../../../Models/Interfaces';
import { POPUP_CALLBACKS_TYPE } from '../../../Constants/Constant';
import TenantService from '../../../Services/Tenant';
import { fetchCurrentTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { useAppDispatch } from '../../../Redux/Hooks';

interface AdvancedInventoryProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
}
export const AdvancedInventoryPopup: React.FC<AdvancedInventoryProps> = (
  props
) => {
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.ADVANCED_INVENTORY_POPUP,
      data: () => {
        saveFifo();
      }
    });
  };

  const saveFifo = () => {
    if (!termsAndConditionsAccepted) {
      showToast('Please accept the terms and conditions', TOAST_TYPE.FAILURE);
      return;
    }
    const payload = {
      implementFIFOForAdvTracking: true
    };
    TenantService.updateOrgSetting(payload)
      .then((res) => {
        dispatch(fetchCurrentTenantInfo());
        showToast('FIFO settings saved successfully', TOAST_TYPE.SUCCESS);
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.FIFO_ENABLED_SUCCESSFULLY
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <div className="column p-h-l p-v-r parent-width">
      <div className="column parent-width">
        <DKLabel
          text="You are about to change costing method for Advanced Tracked products."
          className="fw-r fs-m"
        />

        <DKLabel
          text="Currently FIFO costing is applying for Tracked products of all kind, including Advanced Tracked products. "
          className="fw-r fs-m mt-xl"
        />

        <DKLabel
          text="Once this feature is enabled:"
          className="fw-r fs-m mt-xl"
        />

        <DKLabel
          text="• Specific identification costing will be applied for Serial Tracked products."
          className="fw-r fs-m ml-m"
        />

        <DKLabel
          text="• FIFO costing within each batch will be applied for Batch Tracked products."
          className="fw-r fs-m ml-m"
        />

        <DKLabel
          text="These changes will only applied to future transactions, any discrepancies that might arise should be adjusted manually. Please note that this change cannot be undone."
          className="fw-r fs-m mt-xl"
        />

        <DKCheckMark
          title="I acknowledge that I want to change my costing method"
          className="fw-r fs-m mt-xl"
          isSelected={termsAndConditionsAccepted}
          onClick={(value: any) => {
            setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
          }}
        />
      </div>
    </div>
  );
};
