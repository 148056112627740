import React, { Component } from "react";
import { ProductTable } from "./dktable/product/ProductTable";
import Utility, { getVW } from "../Utilities/Utility";
import { FONT_SIZE, US_CHEQUE_DETAIL_HEIGHT } from "../Constants/Constants";
import ChequeParser from "../Helper/DocumentModuleParser/ChequeParser";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import NumberUtility from "../Utilities/NumberUtility";
import { getLocalisedText } from "../Translate/LanguageManager";

export default class DKChecktableSection extends Component {
  tableOption = ProductTable.getTableOption(!this.props.data.isReadOnlyMode);
  currency = NumberUtility.getCurrency(this.props.data) ? NumberUtility.getCurrency(this.props.data) : '';

  constructor(props) {
    super(props);
    this.state = {
      currentRowIndex: 0,
      elementData: props.elementData,
      tableColumns: props.data?.checkTableColumn || [],
      data: props.data,
      details: props.details
    };
  }

  componentWillReceiveProps(nextProp) {
    this.currency = NumberUtility.getCurrency(this.props.data) ? NumberUtility.getCurrency(this.props.data) : '';
    this.setState({
      elementData: nextProp.elementData,
      tableColumns: nextProp.data?.checkTableColumn || [],
      data: nextProp.data,
      details: nextProp.details
    });
  }

  componentWillUnmount() {
    this.currency = NumberUtility.getCurrency(this.props.data);
  }

  renderTopContainer(element) {
    const checkOtherFields = this.state.data.checkOtherFields || [];

    return (
      <div className="RowDiv" style={{ paddingTop: getVW(10), paddingBottom: getVW(10) }}>
        <div
          style={{
            minWidth: "20%",
            paddingLeft: getVW(7.6),
          }}
        >
          {this.renderTopHeaderDataColumn(ChequeParser.getFormatedFieldValue(checkOtherFields, "documentSequenceCode", "colonSeparator", element?.contactInfo?.documentSequenceCode))}
        </div>
        <div
          style={{
            paddingLeft: getVW(7.6),
          }}
        >
          {this.renderTopHeaderDataColumn(ChequeParser.getFormatedFieldValue(checkOtherFields, "payTo", "colonSeparator", element?.payTo))}
        </div>
        <div
          style={{
            minWidth: "5%",
            paddingLeft: getVW(7.6),
          }}
        ></div>
      </div>
    );
  }

  renderBottomContainer(element) {
    const checkOtherFields = this.state.data.checkOtherFields || [];

    return (
      <div
        className="RowDiv"
        style={{
          marginTop: "auto",
          alignItems: "baseline",
          paddingTop: getVW(10),
          paddingBottom: getVW(10)
        }}
      >
        <div
          style={{
            width: "35%",
            paddingLeft: getVW(7.6),
            ...this.defaultStyle(),
          }}
        >
          {this.renderTopHeaderDataColumn(ChequeParser.getFormatedFieldValue(checkOtherFields, "accountCode", "roundBracket", element?.accountCode))}
        </div>
        <div
          style={{
            whiteSpace: "pre-line",
            width: "30%",
            paddingLeft: getVW(7.6),
            ...this.defaultStyle(),
          }}
        >
          {ChequeParser.getFormatedFieldValue(checkOtherFields, "date", "name", element?.date)}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {ChequeParser.getFormatedFieldValue(checkOtherFields, "date", "value", element?.date)}
        </div>
        <div
          style={{
            ...this.defaultStyle(),
            whiteSpace: "pre-line",
            width: "15%",
            paddingLeft: getVW(7.6),
            textAlign: "center",
          }}
        >
          {ChequeParser.getFormatedFieldValue(checkOtherFields, "totalAmount", "name", element?.amount)}
        </div>
        <div
          style={{
            ...this.defaultStyle(),
            whiteSpace: "pre-line",
            width: "20%",
            paddingLeft: getVW(7.6),
            paddingRight: 6,
            textAlign: "end",
          }}
        >
          {Utility.toCurrencyFormat(ChequeParser.getFormatedFieldValue(checkOtherFields, "totalAmount", "value", element?.amount) || 0, `${this.state.data.showCheckCurrency ? this.currency : ""}`)}
        </div>
      </div>
    );
  }

  renderTopHeaderDataColumn(text, leftSpace) {
    return (
      <div
        style={{
          paddingLeft: getVW(leftSpace ? 24 : 8),
          paddingRight: getVW(leftSpace ? 0 : 8),
          ...this.defaultStyle(),
        }}
      >
        {text}
        {leftSpace ? "" : <>&nbsp;</>}
      </div>
    );
  }

  render() {
    const style = TemplateSettingsManager.getCheckStyle();
    const height = style && this.state.details.table ? style[this.state.details.table].height : US_CHEQUE_DETAIL_HEIGHT
    return (
      <div className="ColumnDiv"
        style={{
          height: getVW(Utility.convertToPrintScale(height, this.state.data.printScale)),
          border: TemplateSettingsManager?.getPrintInfo() ? "none" : "1px dashed rgba(128, 128, 128, 0.4)"
        }}
      >
        {this.renderTopContainer(this.state.elementData)}
        <div className="parent-width">
          <table style={{ width: "100%" }} >
            <tbody>
              <tr>
                {this.state.tableColumns
                  .filter((col) => col.isSelected)
                  .map((column) => {
                    return (
                      <td
                        style={{
                          ...this.defaultStyle(),
                          ...column.style,
                          fontWeight: 500,
                          paddingLeft: 5,
                          paddingRight: 5
                        }}
                        key={column.code + "_header"}
                      >
                        {getLocalisedText(column.label)}
                      </td>
                    );
                  })}
              </tr>
              {this.state.elementData?.lineItems?.map((row, index) => {
                return (
                  <tr key={`custom_row_${row.documentSequenceCode}_${index}`}>
                    {this.state.tableColumns
                      .filter((col) => col.isSelected)
                      .map((column) => {
                        return (
                          <td
                            key={column.code + "_row"}
                            style={{
                              ...this.defaultStyle(),
                              ...column.style,
                              paddingLeft: 5,
                              paddingRight: 5
                            }}
                          >
                            {this.renderCellData(column, row)}
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {this.renderBottomContainer(this.state.elementData)}
      </div>
    );
  }

  renderCellData(column, row) {
    switch (column.code) {
      case "amount":
      case "discountedAmount":
      case "totalDiscount":
      case "amtPaid":
        return Utility.toCurrencyFormat(row[column.code], `${this.state.data.showCheckCurrency ? this.currency : ""}`);
      default:
        return row[column.code];
    }
  }

  defaultStyle() {
    return {
      fontFamily: "calibri san-serif",
      textAlign: "left",
      ...Utility.getFontStyleProp(
        { fontSize: FONT_SIZE.medium },
        this.state.data.printScale
      ),
    };
  }
}
