import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import OperatorsService from '../../../Services/MRP/Operators';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchOperatorsList = createAsyncThunk(
  'fetchOperators',
  async () => {
    const response = await OperatorsService.getOperators();
    return response;
  }
);

export const OperatorSlice = createSlice({
  name: 'Operators',
  initialState,
  reducers: {
    addOperatorColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.OPERATORS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToOperatorColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperatorsList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchOperatorsList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.operators = action.payload;
      });
  }
});

export const {
  addOperatorColumnConfig,
  addColumnToOperatorColumnConfig,
  updateColumnConfig
} = OperatorSlice.actions;

export const selectOperators = (state: RootState) =>
  state.mrpOperators.data.operators;
export const selectOperatorsColumnConfig = (state: RootState) =>
  state.mrpOperators.columnConfig;
export const selectOperatorsColumnConfigTableId = (state: RootState) =>
  state.mrpOperators.configTableId;
