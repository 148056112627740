import ApiConstants from '../Constants/ApiConstants';
import IamService from './iam';
import { showAlert, removeLoader } from 'deskera-ui-library';
import { COMPLIANCE_ENABLED } from '../Constants/Constant';
import { isMobileWebView } from '../Utility/ViewportSizeUtils';
import TimeoutManager from '../Managers/TimeoutManager';

class AuthService {
  static didUserLoggedIn = false;
  static didSessionExpired = false;
  static userDetails: any = null;
  static commonError = 'Server error occured. Please try again later.';

  private static statusCallPromise: any = null;

  static clearStoredStatusCall() {
    AuthService.statusCallPromise = null;
  }

  /*
   * Auth Service will be replacing UserManager, App Manager in Future
   */

  static checkIfUserLoggedIn(
    onSuccess: () => void,
    onFail: () => void,
    showAlertPopup: boolean = false
  ) {
    /* For avoiding parallel status calls */
    if (AuthService.statusCallPromise) {
      return AuthService.statusCallPromise;
    }

    AuthService.statusCallPromise = fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS,
      AuthService.getApiRequestOptions('GET', null) as any
    )
      .then((response) => response.json())
      .then((data) => {
        AuthService.clearStoredStatusCall();
        if (data && data.accessToken) {
          localStorage.setItem('refreshData', data.refreshToken);
          AuthService.tokenReceived(data.accessToken, data.emailVerified);
          onSuccess();
        } else {
          // onFail();
          AuthService.gotoLoginPage(showAlertPopup);
        }
      })
      .catch((error) => {
        // onFail();
        AuthService.gotoLoginPage(showAlertPopup);
      });
  }

  static userLoggedIn() {
    AuthService.didUserLoggedIn = true;
  }

  static isUserLoggedIn() {
    return AuthService.didUserLoggedIn;
  }

  static getApiRequestOptions(method: string, body: any) {
    let requestOptions = {
      method: method,
      credentials: 'include',
      withCredentials: true,
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        accept: '*/*'
        // "x-access-token": ApiConstants.ACCESS_TOKEN,
      }
    };
    if (method === 'POST' || method === 'PUT') {
      body = JSON.stringify(body);
      const newRequestOptions = requestOptions;
      requestOptions = { ...newRequestOptions };
    }
    return requestOptions;
  }

  static tokenReceived(accessToken: string, emailVerified: any) {
    let token = AuthService.parseJwt(accessToken);
    TimeoutManager.setTokenExpiryTime(token.exp);
    ApiConstants.ACCESS_TOKEN = accessToken as any;
    localStorage.setItem('tenantId', token.tenantId);
    localStorage.setItem('email', token.email);
    let user = {
      imID: token.iamUserId,
      id: token.userId,
      tenantID: token.tenantId,
      name: token.name,
      email: token.email,
      phone: token.phone_number,
      website: token.website,
      tenantName: token.website,
      country: token.taxResidency,
      currency: token.currency,
      isOrgSet: token.isOrgSet,
      emailVerified: emailVerified,
      isBookKeeper: token.book_keeper,
      [COMPLIANCE_ENABLED]: token[COMPLIANCE_ENABLED]
    };
    AuthService.setUserDetails(user);
    AuthService.userLoggedIn();
  }

  static parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  static gotoLoginPage(needAlert = true) {
    removeLoader();
    if (!needAlert) {
      window.open(
        ApiConstants.URL.IAM.REDIRECT + window.location.href,
        '_self'
      );
      return;
    }

    if (!AuthService.didSessionExpired && !isMobileWebView()) {
      showAlert(
        'Session expired!',
        'Your session is expired, please login and try again.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white fw-m',
            onClick: () => {
              window.open(
                ApiConstants.URL.IAM.REDIRECT + window.location.href,
                '_self'
              );
            }
          }
        ]
      );
    }
    AuthService.didSessionExpired = true;
  }

  static logout() {
    let buttons = [
      { title: 'Cancel', className: 'bg-gray2', onClick: () => {} },
      {
        title: 'Log out',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          IamService.logOut().then((res) => {
            window.open(
              ApiConstants.URL.IAM.REDIRECT + window.location.href,
              '_self'
            );
          });
        }
      }
    ];
    showAlert('Log out', 'Are you sure want to log out the session?', buttons);
  }

  static setUserDetails(data: any) {
    AuthService.userDetails = data;
  }

  static getUserTenantID = () => {
    return AuthService.userDetails ? AuthService.userDetails.tenantID : null;
  };
  static getUserName = () => {
    return AuthService.userDetails ? AuthService.userDetails.name : null;
  };
  static getUserEmail = () => {
    return AuthService.userDetails ? AuthService.userDetails.email : null;
  };
  static isBookKeeper = () => {
    return AuthService.userDetails
      ? AuthService.userDetails.isBookKeeper
      : null;
  };
  static getUserTenantName = () => {
    // use this from CompanyDetailManager
    return AuthService.userDetails
      ? AuthService.userDetails.tenantName
      : 'Company Name';
  };

  static getUserID = () => {
    return AuthService.userDetails ? AuthService.userDetails.id : null;
  };
  static getUserDetails = () => {
    return AuthService.userDetails;
  };
}

export default AuthService;
