export enum ProductionPlanStatus {
    DRAFT = 'DRAFT',
    // SUBMITTED = 'SUBMITTED',
    // MATERIAL_REQUESTED = 'MATERIAL_REQUESTED',
    // NOT_STARTED = 'NOT_STARTED',
    // IN_PROGRESS = 'IN_PROGRESS',
    // COMPLETED = 'COMPLETED',
    // CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
    OPEN = 'OPEN',
}