import { useRef, useEffect, useState, CSSProperties } from 'react';
import { DKIcons, DKButton } from 'deskera-ui-library';
export interface DKTabPanelProps {
  getActivePanel: () => React.ReactNode;
  onTabSelect?: (tab: string, index: number) => void;
  refreshDataOnTabSelect?: () => void;
  tabs: any[];
  tabRenderer?: (tab: any, index: number) => React.ReactNode;
  className?: string;
  style?: CSSProperties;
  activeTabIndex: number;
  onAddProduct?: any;
  allowAdditionalBoms?: boolean;
}
export default function DKTabPanel(props: DKTabPanelProps) {
  const tabWrapper = useRef<HTMLDivElement>(null);
  const [showScrollArrow, setShowScrollArrow] = useState(false);

  useEffect(() => {
    if (tabWrapper?.current) {
      const children = Array.from(tabWrapper?.current?.children || []);
      const tabWrapperWidth = tabWrapper?.current?.clientWidth || 0;
      const totalWidth = children.reduce(
        (acc, child) => acc + child.clientWidth,
        0
      );
      setShowScrollArrow(totalWidth > tabWrapperWidth + 65);
    }
  }, [props.tabs]);

  const refreshDataCallbackOnTabSelect = useRef(props.refreshDataOnTabSelect);
  useEffect(() => {
    refreshDataCallbackOnTabSelect.current = props.refreshDataOnTabSelect;
  }, [props.refreshDataOnTabSelect]);
  useEffect(() => {
    refreshDataCallbackOnTabSelect.current?.();
  }, [props.activeTabIndex]);

  const renderTabs = (tab: any, index: number) => {
    return (
      <div
        key={`tab-${index}`}
        style={{
          height: 40
        }}
        className={`d-flex align-items-center p-h-l text-align-center cursor-hand white-space-nowrap parent-size ${
          props.activeTabIndex === index
            ? 'fw-m dk-tab-panel-active-tab text-app-color'
            : 'text-dark-gray'
        }`}
        onClick={() => props.onTabSelect?.(tab, index)}
      >
        {tab}
      </div>
    );
  };

  return (
    <div
      className={
        'column parent-width flex-1 border-radius-s mb-m padding-top-mobile mobile-parent-width p-0 ' +
        (props.className || '')
      }
      style={props.style || {}}
    >
      <div
        className={`row bg-white border-m-b border-t-2 pr-2 ${
          !showScrollArrow ? 'pl-3' : ''
        }`}
      >
        {showScrollArrow && (
          <DKButton
            title={''}
            icon={DKIcons.ic_arrow_left2}
            className={`ic-s bg-white`}
            onClick={() =>
              tabWrapper?.current?.scroll?.({
                left: tabWrapper?.current?.scrollLeft - 300,
                behavior: 'smooth'
              })
            }
          />
        )}
        <div
          ref={tabWrapper}
          className="row hide-scroll-bar"
          style={{ overflowX: 'auto', minHeight: 40 }}
        >
          {props.tabs.map(props?.tabRenderer || renderTabs)}
        </div>
        {showScrollArrow && (
          <DKButton
            title={''}
            icon={DKIcons.ic_arrow_right2}
            className={`ic-s bg-white`}
            onClick={() =>
              tabWrapper?.current?.scroll?.({
                left: tabWrapper?.current?.scrollLeft + 300,
                behavior: 'smooth'
              })
            }
          />
        )}
        {props.allowAdditionalBoms && (
          <div className="">
            <DKButton
              className="bg-dark-gray text-white ml-2"
              title="+ Add Material"
              style={{
                padding: 4,
                borderRadius: 5,
                right: 20,
                top: 60
              }}
              onClick={() => {
                props.onAddProduct();
              }}
            />
          </div>
        )}
      </div>
      {props.getActivePanel()}
    </div>
  );
}
