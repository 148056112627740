
import { PAGE_PRINT_RATIO } from "../../../Constants/Constants"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { getIsDocBuilderApp } from "../../../Utilities/Utility"
import { ComponentType } from "../DocumentDesignerScreen/model/Enum"
import { TemplateItem } from "../DocumentDesignerScreen/model/TemplateItem"
import { getDivWidthAndHeight } from "./CommonUtility"

export class CoordinateUtility {
    // height: 1125,// element.scrollHeight,
    // width: 793, //element.scrollWidth


    // export const LINE_ITEM_HEIGHT = "2.8vw";
    // export const PIXEL_TO_VW = 14.3;

    // export const PAGE_WIDTH = "55.45vw";
    // export const PAGE_HEIGHT = "78.63vw";

    //PRINT INFO
    //94.4vw
    //136.203vw
    constructor(maxHeight: number = 21126.7679, maxWidth: number = 2792.935, defaultOffsetHeight: number = 40.04, defaultOffsetWidth: number = 40.04) {
        this.maxHeight = maxHeight
        this.maxWidth = maxWidth
        this.defaultOffsetHeight = defaultOffsetHeight
        this.defaultOffsetWidth = defaultOffsetWidth
    }

    maxHeight: number
    maxWidth: number

    defaultOffsetHeight: number
    defaultOffsetWidth: number

    toPosition(coordinate: Coordinate, isPreview: boolean): Coordinate {
        var newCoordinate = new Coordinate(0, 0, coordinate.xRatio, coordinate.yRatio, coordinate.originalWindowInnerWidth)
        coordinate.calculateRatio()
        if (newCoordinate.xRatio >= 0 && newCoordinate.xRatio < 1) {
            newCoordinate.x = coordinate.xRatio * this.maxWidth * coordinate.ratio
            var offsetPadding = 40
            if (TemplateSettingsManager.getPrintInfo() !== undefined && newCoordinate.x > (offsetPadding * coordinate.ratio)) {
                newCoordinate.x = newCoordinate.x - (offsetPadding * coordinate.ratio)
            }
        }else if (newCoordinate.xRatio >= 1) {
            newCoordinate.x = coordinate.xRatio * this.maxWidth * coordinate.ratio
            // newCoordinate.x = this.maxWidth
        }

        if (getIsDocBuilderApp()) {
            if (newCoordinate.yRatio >= 0 && newCoordinate.yRatio < 1) {
                newCoordinate.y = coordinate.yRatio * this.maxHeight * coordinate.ratio
            }
            else if (newCoordinate.yRatio >= 1) {
                newCoordinate.y = coordinate.yRatio * this.maxHeight * coordinate.ratio
                // newCoordinate.y = this.maxHeight
            }
        } else {
            if ((newCoordinate.yRatio >= 0 && newCoordinate.yRatio < 1) || newCoordinate.yRatio >= 1) {
                newCoordinate.y = coordinate.yRatio * this.maxHeight * coordinate.ratio
            }
        }

        return newCoordinate
    }

    toRatio(coordinate: Coordinate, templateItem?: TemplateItem, htmlElement?: HTMLElement): Coordinate {
        if (templateItem === undefined || templateItem === null) {
            return this.calculateDefaultToRatio(coordinate)
        }

        if (templateItem.type === ComponentType.DKImage) {
            return this.calculateImageToRatio(coordinate, templateItem, htmlElement)
        }
        else if (templateItem.type === ComponentType.DKLabel) {
            return this.calculateLabelToRatio(coordinate, templateItem, htmlElement)
        }

        return this.calculateDefaultToRatio(coordinate)
    }

    calculateDefaultToRatio(coordinate: Coordinate) {
        var newCoordinate = new Coordinate(coordinate.x, coordinate.y, 0, 0, coordinate.originalWindowInnerWidth)
        if (coordinate.x > 0) {
            newCoordinate.xRatio = coordinate.x / this.maxWidth
        }
        else {
            newCoordinate.xRatio = 0
        }

        if (coordinate.y > 0) {
            newCoordinate.yRatio = coordinate.y / this.maxHeight
        }
        else {
            newCoordinate.yRatio = 0
        }

        // if (newCoordinate.xRatio >= 1) {
        //     newCoordinate.xRatio = 1
        // }

        // if (newCoordinate.yRatio >= 1) {
        //     newCoordinate.yRatio = 1
        // }

        return newCoordinate
    }
    calculateImageToRatio(coordinate: Coordinate, templateItem?: TemplateItem, htmlElement?: HTMLElement) {
        var newCoordinate = new Coordinate(coordinate.x, coordinate.y, 0, 0, coordinate.originalWindowInnerWidth)

        var offsetCoordinate = this.getOffsetCoordinate(templateItem, htmlElement)

        var offsetX = offsetCoordinate.x - (this.defaultOffsetWidth / 2)
        // var offsetY = offsetCoordinate.y - (this.defaultOffsetHeight / 2)

        var offsetXRatio = 0
        // var offsetYRatio = 0
        var offsetYRatio = ((this.defaultOffsetHeight * 2) / this.maxHeight - ((offsetCoordinate.y) / this.maxHeight)) + (this.defaultOffsetHeight /8 / this.maxHeight)


        if (offsetX > 0) {
            offsetXRatio = offsetX / this.maxWidth
        }
        // if (offsetY > 0) {
        //     offsetYRatio = offsetY / this.maxHeight
        // }

        if (coordinate.x > 0) {
            newCoordinate.xRatio = coordinate.x / this.maxWidth
        }
        else {
            newCoordinate.xRatio = 0
        }

        if (coordinate.y > 0) {
            newCoordinate.yRatio = coordinate.y / this.maxHeight
        }
        else {
            newCoordinate.yRatio = 0
        }

        if (newCoordinate.xRatio >= (1 - offsetXRatio)) {
            newCoordinate.xRatio = (1 - offsetXRatio)
        }

        if (newCoordinate.yRatio >= (1 + offsetYRatio)) {
            newCoordinate.yRatio = (1 + offsetYRatio)
        }
        return newCoordinate
    }

    calculateLabelToRatio(coordinate: Coordinate, templateItem?: TemplateItem, htmlElement?: HTMLElement) {
        var newCoordinate = new Coordinate(coordinate.x, coordinate.y, 0, 0, coordinate.originalWindowInnerWidth)

        var offsetCoordinate = this.getOffsetCoordinate(templateItem, htmlElement)

        var offsetX = offsetCoordinate.x - this.defaultOffsetWidth - 4

        var offsetXRatio = 0
        var offsetYRatio = ((this.defaultOffsetHeight * 2) / this.maxHeight - ((offsetCoordinate.y) / this.maxHeight)) + (this.defaultOffsetHeight/2 / this.maxHeight)

        if (offsetX > 0) {
            offsetXRatio = offsetX / this.maxWidth
        }

        if (coordinate.x > 0) {
            newCoordinate.xRatio = coordinate.x / this.maxWidth
        }
        else {
            newCoordinate.xRatio = 0
        }

        if (coordinate.y > 0) {
            newCoordinate.yRatio = coordinate.y / this.maxHeight
        }
        else {
            newCoordinate.yRatio = 0
        }

        if (newCoordinate.xRatio >= (1 - offsetXRatio)) {
            newCoordinate.xRatio = (1 - offsetXRatio)
        }

        if (newCoordinate.yRatio >= (1 + offsetYRatio)) {
            newCoordinate.yRatio = (1 + offsetYRatio)
        }
        return newCoordinate
    }
    getOffsetCoordinate(templateItem?: TemplateItem, htmlElement?: HTMLElement): Coordinate {
        if (templateItem === undefined || templateItem === null) {
            return this.calculateOffset(0, 0)
        }
        if (templateItem.type === ComponentType.DKImage) {
            var width = (templateItem.width ? templateItem.width : 0) * (templateItem.imageRatio / 100)
            var height = (templateItem.height ? templateItem.height : 0) * (templateItem.imageRatio / 100)

            return this.calculateOffset(width, height)
        }
        else if (templateItem.type === ComponentType.DKLabel) {
            if (templateItem.width !== undefined && templateItem.width !== null
                && templateItem.height !== undefined && templateItem.height !== null) {
                return this.calculateOffset(templateItem.width, templateItem.height)
            }
            else {
                if (htmlElement === undefined || htmlElement === null) {
                    return this.calculateOffset(0, 0)
                }
                var newDivSize = getDivWidthAndHeight(htmlElement)
                return this.calculateOffset(newDivSize.x, newDivSize.y)
            }
        }

        return new Coordinate(0, 0, 0, 0)
    }

    calculateOffset(width: number, height: number): Coordinate {
        return new Coordinate(width, height, 0, 0)
    }
}

export class Coordinate {
    x: number
    y: number
    xRatio: number
    yRatio: number
    ratio: number
    originalWindowInnerWidth: number
    constructor(x: number, y: number, xRatio: number, yRatio: number, originalWindowInnerWidth: number = window.innerWidth) {
        this.x = x
        this.y = y
        this.xRatio = xRatio
        this.yRatio = yRatio
        if(originalWindowInnerWidth === undefined) {
            this.originalWindowInnerWidth = window.innerWidth
            this.ratio = 1
        }
        else {
            this.originalWindowInnerWidth = originalWindowInnerWidth
            this.ratio = window.innerWidth / originalWindowInnerWidth
        }
        this.calculateRatio()
    }

    calculateRatio() {
        if (getIsDocBuilderApp()) {
            this.ratio = window.innerWidth / this.originalWindowInnerWidth
        } else {
            var templatePreview = document.getElementById("template");
            if (templatePreview) {
                this.ratio = templatePreview.clientWidth / this.originalWindowInnerWidth
            } else {
                this.ratio = window.innerWidth / this.originalWindowInnerWidth
            }
        }

        if (TemplateSettingsManager.getPrintInfo() !== undefined) {
            this.ratio = this.ratio * PAGE_PRINT_RATIO
        }
    }

    initRatio(xRatio: number, yRatio: number) {
        this.xRatio = xRatio
        this.yRatio = yRatio
    }

    initPosition(x: number, y: number) {
        this.x = x
        this.y = y
    }
}