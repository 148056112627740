import ic_kanban from '../../../Assets/Icons/ic_kanban.png';
import ic_cells from '../../../Assets/Icons/ic_cells.png';
import ic_calendar from '../../../Assets/Icons/ic_calendar.png';
import { INPUT_TYPE } from 'deskera-ui-library';
import { MRP_JC_COLORS } from '../../../Components/Mrp/Constants/MRPColumnConfigs';

export const TASK_PANEL_IDENTIFIER = `dk-task-panel`;
export const TASK_ROW_IDENTIFIER = `dk-task-row`;
export const TASK_ROW_HEIGHT = 34;
export const TASK_ROW_WIDTH = 305;

export const WIDTH_PER_DAY = 80;
export const MIN_DAY_WIDTH = 20;
export const ZOOM_MULTIPLIER = 10;
export const GANT_BAR_HEIGHT = '38%';
export const GANT_BAR_IDENTIFIER = `dk-gant-task-bar`;

export const GANT_BAR_INNER_IDENTIFIER = `dk-gant-bar`;
export const GANT_BAR_CLONE_IDENTIFIER = `clone-${GANT_BAR_INNER_IDENTIFIER}`;
export const GANT_BAR_LEFT_DRAG_HANDLE = `dk-gant-bar-left-handle`;
export const GANT_BAR_RIGHT_DRAG_HANDLE = `dk-gant-bar-right-handle`;
export const GANT_BAR_TITLE_IDENTIFIER = `dk-gant-bar-title`;

export const GANT_BAR_DRAG_OVER_STYLE = `gant-drag-over`;

export const GANT_BAR_WORK_ORDER_COLOR = `rgb(180, 180, 180)`;

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const GANT_BAR_TYPE = {
  WORK_ORDER: 'WORK_ORDER',
  JOB_CARD: 'JOB_CARD'
};

export const PM_VIEW_INDEX = {
  GANT: 0,
  CALENDAR: 1
};
export const PM_MODULE = 'PROJECT_MANAGEMENT';
export const PM_ALL_CONDITION = {
  firstName: 'All',
  lastName: '',
  iamUserId: 'All'
};

export const EXPORT_FILE_HEADERS = {
  title: 'Job Name',
  parentTask: 'Parent Job',
  startDate: 'Start Date',
  endDate: 'End Date',
  duration: 'Duration',
  resource: 'Resource',
  status: 'Completed'
};

export const FILTER_STATUS = ['All', 'Pending', 'Overdue', 'Completed'];

export const STATUS_VALUES = [
  { key: 'Open', value: false },
  { key: 'Complete', value: true }
];

export const STATUS_DEFAULT = {
  title: 'All Jobs',
  color: 'blue',
  value: 'All',
  data: []
};

export const JOB_CARD_STATUS_TYPE = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  ON_HOLD: 'ON_HOLD',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
};

export const SCHEDULE_STATUS = [
  {
    title: 'Open',
    color: MRP_JC_COLORS.MRP_BG_BLUE,
    value: JOB_CARD_STATUS_TYPE.OPEN,
    data: []
  },
  {
    title: 'In-Progress',
    color: MRP_JC_COLORS.MRP_BG_ORANGE,
    value: JOB_CARD_STATUS_TYPE.IN_PROGRESS,
    data: []
  },
  {
    title: 'On-Hold',
    color: MRP_JC_COLORS.MRP_BG_PURPLE_2,
    value: JOB_CARD_STATUS_TYPE.ON_HOLD,
    data: []
  },
  {
    title: 'Cancelled',
    color: MRP_JC_COLORS.MRP_BG_RED,
    value: JOB_CARD_STATUS_TYPE.CANCELLED,
    data: []
  },
  {
    title: 'Completed',
    color: MRP_JC_COLORS.MRP_BG_GREEN,
    value: JOB_CARD_STATUS_TYPE.COMPLETED,
    data: []
  }
];

export const EXPAND_COLLAPSE_STATUS = [
  {
    title: 'Collapse',
    value: 'COLLAPSED'
  },
  {
    title: 'Expand',
    value: 'EXPANDED'
  }
];

export const PM_BOARD_VIEW = [
  { title: 'Gantt Chart', icon: ic_kanban },
  { title: 'Calender', icon: ic_calendar },
  { title: 'List', icon: ic_cells },
  { title: 'Board', icon: ic_kanban }
];

export const PM_COLUMNS = [
  {
    title: 'Job Name',
    key: 'jobCardSeqCode',
    type: INPUT_TYPE.TEXT,
    width: 130,
    editable: false
  },
  {
    title: 'Operation',
    key: 'operationName',
    type: INPUT_TYPE.TEXT,
    width: 150,
    editable: false
  },
  {
    title: 'WO Item Name',
    key: 'productName',
    type: INPUT_TYPE.TEXT,
    width: 250,
    editable: false
  },
  { title: 'Start Date', key: 'startDate', type: INPUT_TYPE.DATE, width: 120 },
  { title: 'End Date', key: 'endDate', type: INPUT_TYPE.DATE, width: 120 },
  {
    title: 'Duration (Days)',
    key: 'duration',
    type: INPUT_TYPE.NUMBER,
    width: 100,
    editable: false
  },
  {
    title: 'Operators',
    key: 'resource',
    type: INPUT_TYPE.TEXT,
    width: 150,
    editable: false
  },
  {
    title: 'Status',
    key: 'status',
    type: INPUT_TYPE.SELECT,
    width: 150,
    editable: false
  }
];

export const ACTIVITY_DATE_FORMAT = 'EEE, MMM dd yyyy, HH:mm aa';

export const CALENDAR_VIEWS = ['Day', 'Week', 'Month'];

export const FULL_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const YEARS = [
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030'
];

export const FILTER_COLUMNS = [
  {
    name: 'Job Card Number',
    index: 0,
    type: 'text',
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'jobCardSeqCode',
    id: 'jobCardSeqCode',
    columnCode: 'jobCardSeqCode'
  },
  {
    name: 'Work Order Number',
    index: 1,
    type: 'text',
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'parentWorkOrderSeqCode',
    id: 'parentWorkOrderSeqCode',
    columnCode: 'parentWorkOrderSeqCode'
  },
  {
    name: 'Work Order Status',
    type: 'select',
    options: [
      {
        id: 'OPEN',
        name: ' OPEN ',
        color: 'bg-blue text-white border-radius-l'
      },
      {
        id: 'IN_PROGRESS',
        color: 'bg-deskera text-white border-radius-l',
        name: ' In Progress '
      },
      // {
      //   id: 'COMPLETED',
      //   name: 'Completed ',
      //   color: 'bg-green text-white border-radius-l'
      // },
      {
        id: 'ON_HOLD',
        name: ' On Hold ',
        color: 'mrp_bg_purple2 text-white border-radius-l'
      }
    ],
    systemField: true,
    index: 4,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'workOrderStatus',
    key: 'workOrderStatus',
    id: 'workOrderStatus'
  },
  {
    name: 'Job Card Status',
    type: 'select',
    options: [
      {
        id: 'OPEN',
        name: ' Not Started ',
        color: 'bg-blue text-white border-radius-l'
      },
      {
        id: 'IN_PROGRESS',
        color: 'bg-deskera text-white border-radius-l',
        name: ' In Progress '
      },
      {
        id: 'COMPLETED',
        name: 'Completed ',
        color: 'bg-green text-white border-radius-l'
      },
      {
        id: 'ON_HOLD',
        name: ' On Hold ',
        color: 'mrp_bg_purple2 text-white border-radius-l'
      },
      {
        id: 'CANCELLED',
        name: ' Cancelled ',
        color: 'bg-red text-white border-radius-l'
      }
    ],
    systemField: true,
    index: 4,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'jobCardStatus',
    key: 'jobCardStatus',
    id: 'jobCardStatus'
  }
];

export const DATE_RNGE_FILTER_OPTION = {
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
  THIS_QUARTER: 'This Quarter',
  THIS_YEAR: 'This Year',
  CUSTOM_DATE: 'Custom Date'
};
