import React, { Component, useEffect, useState } from 'react';

import { DKLabel, DKButton, DKSpinner } from 'deskera-ui-library';
import DKDropdown from './DKDropdown';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import Utility from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { Link } from 'react-router-dom';
import {
  ERROR,
  GOOGLE_NO_TRANSLATE_CLASS,
  STATUS_TYPE
} from '../../Constants/Constant';
import {
  ModulePermissionEnum,
  RolePermissionUtil
} from '../../RolePermission/RolePermissionUtil';
import {
  fetchDashboardBanksList,
  selectDashboardBanks
} from '../../Redux/Slices/DashboardSlice';
import DashboardService from '../../Services/Dashboard';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
// import DKDropdown from './DKDropdown';

function BankAccounts(props: any) {
  const banksList = useAppSelector(selectDashboardBanks);
  const [parsedBanksList, setParsedBanksList] = useState([
    {
      name: 'DBS Multi',
      balance: 10000
    },
    {
      name: 'American Express',
      balance: 2000
    }
  ]);
  const [banks, setBanks] = useState([]);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [noData, setNoData] = useState(true);
  const [noDataFound, setNoDatFound] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (GranularPermissionsHelper.hasBankModulePermission()) {
      try {
        var config = {};
        DashboardService.apiConfig = config;
        dispatch(fetchDashboardBanksList());
      } catch {
        console.log('failed while loading banks');
      }
    }
  }, []);

  useEffect(() => {
    if (banksList === ERROR) {
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    if (!GranularPermissionsHelper.hasBankModulePermission()) {
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    let activeBankList = banksList?.content?.filter(
      (bank: any) => bank.status === STATUS_TYPE.ACTIVE
    );

    const parsedData =
      activeBankList &&
      activeBankList.map((item: any) => {
        return {
          name: item.name,
          balance: item.balance
        };
      });
    if (parsedData && parsedData.length > 0) {
      setParsedBanksList(parsedData);
      setNoData(false);
    } else {
      setNoData(true);
    }
  }, [banksList]);

  const getAllBanks = () => {
    return (
      parsedBanksList &&
      parsedBanksList.length > 0 &&
      parsedBanksList.map((bank: any) => {
        return (
          <div className="row mt-m justify-content-between listPickerBG cursor-hand">
            <div>
              <DKLabel text={bank.name} className="fw-m" />
            </div>
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                tenantInfo?.currency
              )} ${
                +bank.balance < 0
                  ? '(' +
                    Utility.shortFormatNumberWithSuffix(
                      Math.abs(+bank.balance)
                    ) +
                    ')'
                  : Utility.shortFormatNumberWithSuffix(+bank.balance)
              }`}
              className={
                'fw-m ' +
                (bank.balance < 0 ? 'text-red' : 'text-green') +
                ` ${GOOGLE_NO_TRANSLATE_CLASS} `
              }
            />
          </div>
        );
      })
    );
  };
  return (
    <div className="border-radius-m shadow-s border-ms p-l bg-white border-box overflow-hidden web-mr-l db-grid-width db-mb">
      <div className="row justify-content-between">
        <DKLabel
          text="Bank Accounts"
          className="fw-m fs-m"
          style={{ height: 25 }}
        />
      </div>
      <div
        className="column position-relative overflow-y-scroll hide-scroll-bar"
        style={{ height: 210 }}
      >
        {noData && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">
              <DKSpinner iconClassName="ic-s" />
            </div>
          </div>
        )}
        {noDataFound && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">No Data</div>
          </div>
        )}
        {getAllBanks()}
      </div>
      <div className="row mt-m" style={{ height: 35 }}>
        <DKButton
          title="View all"
          className="bg-gray1 border-m mr-m"
          onClick={() => {
            if (checkUserPermission(PERMISSIONS_BY_MODULE.COA.VIEW)) {
              RouteManager.navigateToPage(PAGE_ROUTES.BANKS);
            }
          }}
        />
      </div>
    </div>
  );
}

export default BankAccounts;
