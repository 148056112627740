import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Store } from './Redux/Store';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import packageJson from "../package.json";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [`/${process.env.HOST}/`, `${process.env.REACT_APP_ERP_URL}`, `${process.env.REACT_APP_URL_MRP}`, `/${process.env.REACT_APP_BASE_URL}/`],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: "booksplus@" + packageJson.version
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
