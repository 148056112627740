import React, { useEffect, useState } from 'react';
import { DKInput, INPUT_TYPE, TOAST_TYPE, showToast } from 'deskera-ui-library';
import {
  FORM_ELEMENTS,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import Utility, { deepClone } from '../../Utility/Utility';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import GenericService from '../../Services/GenericService';
import { fetchNatureOfIncomeAccounts } from '../../Redux/Slices/CommonDataSlice';
import { useAppDispatch } from '../../Redux/Hooks';

export enum NATURE_INCOME_PAYMENT_KEY_NAMES {
  NATURE_OF_PAYMENT = 'natureOfPayment',
  SECTION_CODE = 'sectionCode'
}

export interface InputTag<KeyType> {
  value: string | number;
  hidden: boolean;
  key: KeyType;
  type: FORM_ELEMENTS;
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
}

export interface NatureIncomePaymentState {
  natureOfPayment: InputTag<NATURE_INCOME_PAYMENT_KEY_NAMES>;
  sectionCode: InputTag<NATURE_INCOME_PAYMENT_KEY_NAMES>;
}

export interface NatureIncomePaymentPayLoad {
  natureOfPayment: string;
  sectionCode: string;
}

export const initialNatureIncomePaymentsFormValues: NatureIncomePaymentState = {
  natureOfPayment: {
    key: NATURE_INCOME_PAYMENT_KEY_NAMES.NATURE_OF_PAYMENT,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  },
  sectionCode: {
    key: NATURE_INCOME_PAYMENT_KEY_NAMES.SECTION_CODE,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  }
};

function AddNatureIncomePayment(props: any) {
  const [formState, setFormState] = useState(
    deepClone(initialNatureIncomePaymentsFormValues)
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_NATURE_INCOME_PAYMENT,
        data: () => {
          saveNatureOfIncomePayment();
        }
      });
    }
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const formFieldChanged = (
    newValue: ReactSelectOptionsType<string, string> | string | null,
    fieldName: NATURE_INCOME_PAYMENT_KEY_NAMES
  ) => {
    const updatedState: any = formState;
    const selectedField = updatedState[fieldName];

    switch (fieldName) {
      case NATURE_INCOME_PAYMENT_KEY_NAMES.NATURE_OF_PAYMENT:
      case NATURE_INCOME_PAYMENT_KEY_NAMES.SECTION_CODE:
        selectedField.value = newValue ? newValue : '';
        break;
    }
    setFormState({ ...updatedState });
  };

  const validateNatureOfIncomePayment = (payload: any) => {
    if (Utility.isEmpty(payload.natureOfPayment)) {
      showToast('Please enter Nature of Income Payment', TOAST_TYPE.FAILURE);
      return false;
    } else if (Utility.isEmpty(payload.sectionCode)) {
      showToast('Please enter Section Code', TOAST_TYPE.FAILURE);
      return false;
    } else {
      return true;
    }
  };

  const saveNatureOfIncomePayment = () => {
    let payload: NatureIncomePaymentPayLoad = {
      natureOfPayment:
        formState[
          NATURE_INCOME_PAYMENT_KEY_NAMES.NATURE_OF_PAYMENT
        ].value.toString(),
      sectionCode:
        formState[NATURE_INCOME_PAYMENT_KEY_NAMES.SECTION_CODE].value.toString()
    };

    if (validateNatureOfIncomePayment(payload)) {
      GenericService.saveNatureOfIncomePayment(payload).then(
        (response: any) => {
          if (response && response.id) {
            showToast(
              'Nature of income payment Created Successfully',
              TOAST_TYPE.SUCCESS
            );
            dispatch(fetchNatureOfIncomeAccounts());
            closePopup();
          }
        },
        (err) => {
          if (err.code == 400) {
            showToast(
              'Nature of income payment already exist',
              TOAST_TYPE.FAILURE
            );
            return;
          }
          console.error('Error while nature of income payment: ', err);
        }
      );
    }
  };

  return (
    <div className="m-s">
      <div className="row mb-3">
        <DKInput
          title={'Nature of Income Payment'}
          type={INPUT_TYPE.TEXT}
          required={true}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formState.natureOfPayment.value?.toString()}
          onChange={(text: any) =>
            formFieldChanged(
              text,
              NATURE_INCOME_PAYMENT_KEY_NAMES.NATURE_OF_PAYMENT
            )
          }
        />
      </div>
      <div className="row mb-3">
        <DKInput
          title={'Section Code'}
          type={INPUT_TYPE.TEXT}
          required={true}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formState.sectionCode.value?.toString()}
          onChange={(text: any) =>
            formFieldChanged(text, NATURE_INCOME_PAYMENT_KEY_NAMES.SECTION_CODE)
          }
        />
      </div>
    </div>
  );
}

export default AddNatureIncomePayment;
