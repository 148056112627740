import { DKLabel, DKDataGrid } from 'deskera-ui-library';
import React, { useRef } from 'react';
import {
  JWO_BILLED_STATUS,
  JWO_DISPATCH_STATUS,
  JWO_RECEIPT_STATUS
} from '../../../../../Constants/Constant';
import { useAppSelector } from '../../../../../Redux/Hooks';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { COLUMN_CODE } from '../../../../../Constants/TableConstants';
import { WO_JWO_COLUMNS } from '../../../Constants/MRPColumnConfigs';
import { IRawMaterial } from '../BomMaterialComponent/RawMaterialModel';
import { IColumn } from '../../../../../Models/Table';
import { getJWOCostForLineItemWithoutTax } from '../../WorkOrderHelper';

export interface IJobWorkoutWOList {
  rows: any;
  isEditMode?: boolean;
  isReadOnlyMode?: boolean;
}

type RowDataFormatterConfig = {
  rowData: IRawMaterial;
  rowIndex: number;
};

const JobWorkoutWOList: React.FC<any> = (props: IJobWorkoutWOList) => {
  const tenantInfo = useAppSelector(activeTenantInfo);

  const getJWOColumnConfig = () => {
    let copyOfColumnConfig = deepClone(WO_JWO_COLUMNS);

    copyOfColumnConfig?.forEach((column: Partial<IColumn>) => {
      column.editable = false;
      column['renderer'] = pickFormatter(column);
    });
    return copyOfColumnConfig;
  };

  const getUpdateRows = (rows: IRawMaterial[]) => {
    rows = deepClone(rows);

    return rows.map((row, index: number) => {
      return row;
    });
  };

  const getStatusName = (status: any, type: any) => {
    if (type === COLUMN_CODE.JOB_WORK_OUT.DispatchStatus) {
      let newStatus = status.split('_');
      newStatus = Utility.convertInTitleCase(newStatus[0]);
      return newStatus;
    }

    if (type === COLUMN_CODE.JOB_WORK_OUT.ReceiptStatus) {
      switch (status) {
        case JWO_RECEIPT_STATUS.UNRECEIVED:
          return 'Pending';
        case JWO_RECEIPT_STATUS.PARTIAL_RECEIVED:
          return 'Partially Received';
        case JWO_RECEIPT_STATUS.FULLY_RECEIVED:
          return 'Fully Received';
      }
    }

    if (type === COLUMN_CODE.JOB_WORK_OUT.BilledStatus) {
      let newStatus = Utility.convertInTitleCase(status);
      return newStatus;
    }

    return '-';
  };

  const getStatusChipColor = (key: any) => {
    const commonCSS = `fw-m text-align-center text-wrap-none p-h-s text-white parent-width border-radius-s p-v-xs leading-4`;
    switch (key) {
      case JWO_DISPATCH_STATUS.PENDING_DISPATCH:
      case JWO_RECEIPT_STATUS.UNRECEIVED:
      case JWO_RECEIPT_STATUS.NOT_APPLICABLE:
      case JWO_BILLED_STATUS.PENDING:
      case JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL:
        return `mrp_bg_red ${commonCSS}`;
      case JWO_DISPATCH_STATUS.PARTIALLY_DISPATCHED:
      case JWO_RECEIPT_STATUS.PARTIAL_RECEIVED:
      case JWO_BILLED_STATUS.PARTIAL:
        return `mrp_bg_orange ${commonCSS}`;
      case JWO_DISPATCH_STATUS.DISPATCHED:
      case JWO_RECEIPT_STATUS.FULLY_RECEIVED:
      case JWO_BILLED_STATUS.BILLED:
        return `mrp_bg_green ${commonCSS}`;

      default:
        return `mrp_bg_red ${commonCSS}`;
    }
  };

  const pickFormatter = (column: any) => {
    switch (column.key) {
      case 'jwoCode':
        return ({ rowData }: RowDataFormatterConfig) => {
          return (
            <DKLabel
              text={rowData?.jwoCode || '-'}
              className="text-align-left white-space-nowrap"
            />
          );
        };
      case COLUMN_CODE.JOB_WORK_OUT.DispatchStatus:
        return ({ rowData }: RowDataFormatterConfig) => (
          <DKLabel
            text={getStatusName(
              rowData?.dispatchStatus,
              COLUMN_CODE.JOB_WORK_OUT.DispatchStatus
            )}
            className={`${getStatusChipColor(rowData?.dispatchStatus)}`}
          />
        );
      case COLUMN_CODE.JOB_WORK_OUT.ReceiptStatus:
        return ({ rowData }: RowDataFormatterConfig) => (
          <DKLabel
            text={getStatusName(
              rowData?.receiptStatus,
              COLUMN_CODE.JOB_WORK_OUT.ReceiptStatus
            )}
            className={`${getStatusChipColor(rowData?.receiptStatus)}`}
          />
        );
      case COLUMN_CODE.JOB_WORK_OUT.BilledStatus:
        return ({ rowData }: RowDataFormatterConfig) => (
          <DKLabel
            text={getStatusName(
              rowData?.billedStatus,
              COLUMN_CODE.JOB_WORK_OUT.BilledStatus
            )}
            className={`${getStatusChipColor(rowData?.billedStatus)}`}
          />
        );
      case COLUMN_CODE.JOB_WORK_OUT.TotalAmount:
        return ({ rowData }: RowDataFormatterConfig) => (
          <div className="row">
            {Utility.amountFormatter(
              getJWOCostForLineItemWithoutTax(rowData?.jobWorkOutOrderItems) ??
                0,
              tenantInfo.currency
            )}
          </div>
        );
      default:
        return;
    }
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center">
        No job workout details available
      </div>
    );
  };

  return (
    <div
      className={`column bg-white border-m p-l parent-size border-radius-m`}
      style={{
        height: 'auto',
        width: props.isEditMode ? '49.5%' : '100%',
        maxHeight: 640
      }}
    >
      <div className="row justify-content-between mb-s">
        <DKLabel text="Job Workout Details" className="fw-m text-app-color" />
      </div>
      {!Utility.isEmpty(props.rows) && (
        <div className="column parent-size overflow-auto hide-scroll-bar">
          <DKDataGrid
            styles={{
              mainGridHolder: { marginBottom: -18 }
            }}
            allowColumnEdit={false}
            allowColumnSort={false}
            allowBulkOperation={false}
            columns={getJWOColumnConfig()}
            rows={getUpdateRows(props.rows)}
          />
        </div>
      )}
      {Utility.isEmpty(props.rows) && renderEmptyState()}
    </div>
  );
};

export default JobWorkoutWOList;
