import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import SalesPersonMaster from '../../Services/SalesPersonMaster';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchSalesPersonMaster = createAsyncThunk(
  'salesPersonMaster',
  async () => {
    const response = await SalesPersonMaster.getSalesPersonByPage();
    return response;
  }
);

export const SalesPersonMasterSlice = createSlice({
  name: 'salesPersonMaster',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesPersonMaster.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchSalesPersonMaster.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectSalesPersonMaster = (state: RootState) =>
  state.salesPersonMaster.data;
export const selectSalesPersonMasterStatus = (state: RootState) =>
  state.creditNotes.gridContentLoadingStatus;
