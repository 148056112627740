import { DKLabel } from 'deskera-ui-library';
import React from 'react';
import { DKTooltipWrapper } from 'deskera-ui-library';
import Utility from '../../Utility/Utility';
import { DOCUMENT_TYPE } from '../../Constants/Constant';
import NumberFormatService from '../../Services/NumberFormat';

/*
- title
- data1, 2, 3 {title, subTitle}
- footer
*/

function Summary(props: any) {
  return (
    <div
      className="column parent-width border-radius-m shadow-s border-ms p-xl bg-white border-box mobile-full-grid-width mobile-mb"
      style={{
        height: 150,
        marginRight: props.isRightGapRequired ? '2%' : '0%',
        backgroundColor: '#edf3fb',
        border: '1.5px solid #91b5e9'
      }}
    >
      <div className="row justify-content-between">
        <DKLabel text={props.title} className="fw-b fs-l" />
        {/* {props.filters && props.filters.length > 0 && (
          <DKDropdown
            title={props.filters[props.selectedIndex]}
            data={props.filters}
            onSelect={(data: any) => {
              props.filterChanged(data);
            }}
          />
        )} */}
      </div>
      <div className="row justify-content-between mt-l parent-width" style={{}}>
        {(props?.docType === DOCUMENT_TYPE.INVOICE ||
          props?.docType === DOCUMENT_TYPE.BILL) &&
        props.data1?.totalAmount >= 1000 * 1000 &&
        props?.tenantCurrency !== 'INR' ? (
          <div>
            <DKTooltipWrapper
              content={`${Utility.getCurrencySymbolFromCode(
                props?.tenantCurrency
              )} ${NumberFormatService.getNumber(props.data1?.totalAmount)}`}
              tooltipClassName="bg-deskera-secondary width-auto fw-b"
            >
              <div className="column">
                <DKLabel
                  text={props.data1.title}
                  className="fs-m fw-m text-gray"
                />
                <DKLabel
                  text={props.data1.subTitle}
                  className={'fs-l mt-m fw-b'}
                />
                {/* <DKLabel
            text={props.footer}
            className="mt-r "
            style={{ color: 'rgb(26, 173, 93)' }}
          /> */}
              </div>
            </DKTooltipWrapper>
          </div>
        ) : (
          <div className="column">
            <DKLabel text={props.data1.title} className="fs-m fw-m text-gray" />
            <DKLabel text={props.data1.subTitle} className={'fs-l mt-m fw-b'} />
            {/* <DKLabel
          text={props.footer}
          className="mt-r "
          style={{ color: 'rgb(26, 173, 93)' }}
        /> */}
          </div>
        )}

        <div
          className="bg-blue opacity-4"
          style={{ width: 1, height: 70, marginRight: '2%' }}
        />
        {(props?.docType === DOCUMENT_TYPE.INVOICE ||
          props?.docType === DOCUMENT_TYPE.BILL) &&
        props.data2?.totalAmount >= 1000 * 1000 &&
        props?.tenantCurrency !== 'INR' ? (
          <DKTooltipWrapper
            content={`${Utility.getCurrencySymbolFromCode(
              props?.tenantCurrency
            )} ${NumberFormatService.getNumber(props.data2?.totalAmount)}`}
            tooltipClassName="bg-deskera-secondary width-auto fw-b"
          >
            <div className="column align-items-center">
              <DKLabel
                text={props.data2.title}
                className="text-red fs-m fw-m"
              />
              <DKLabel text={props.data2.subTitle} className="fs-l mt-m fw-b" />
            </div>
          </DKTooltipWrapper>
        ) : (
          <div className="column align-items-center">
            <DKLabel text={props.data2.title} className="text-red fs-m fw-m" />
            <DKLabel text={props.data2.subTitle} className="fs-l mt-m fw-b" />
          </div>
        )}
        <div className="bg-blue opacity-4" style={{ width: 1, height: 70 }} />
        {(props.docType === DOCUMENT_TYPE.INVOICE ||
          props?.docType === DOCUMENT_TYPE.BILL) &&
        props.data3?.totalAmount >= 1000 * 1000 &&
        props?.tenantCurrency !== 'INR' ? (
          <DKTooltipWrapper
            content={`${Utility.getCurrencySymbolFromCode(
              props?.tenantCurrency
            )} ${NumberFormatService.getNumber(props.data3?.totalAmount)}`}
            tooltipClassName="bg-deskera-secondary width-auto fw-b"
          >
            <div className="column align-items-end">
              <DKLabel
                text={props.data3.title}
                className={`${
                  props.data3.color ? props.data3.color : 'text-orange'
                } fs-m fw-m`}
              />
              <DKLabel text={props.data3.subTitle} className="fs-l mt-m fw-b" />
            </div>
          </DKTooltipWrapper>
        ) : (
          <div className="column align-items-end">
            <DKLabel
              text={props.data3.title}
              className={`${
                props.data3.color ? props.data3.color : 'text-orange'
              } fs-m fw-m`}
            />
            <DKLabel text={props.data3.subTitle} className="fs-l mt-m fw-b" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Summary;
