import { useEffect, useState } from 'react';
import {
  currencyDropdownStyle,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../../Constants/Constant';
import InvoiceService from '../../../Services/Invoice';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  DKLabel,
  DKInput,
  DKButton,
  DKIcons,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import { fetchUomSchema } from '../../../Redux/Slices/UomSchemaSlice';
import Select from 'react-select';
import {
  CallBackPayloadType,
  ReactSelectOptionsType
} from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { useTranslation } from 'react-i18next';
import { selectUOMs } from '../../../Redux/Slices/CommonDataSlice';

interface AddUomSchemaProps {
  populateFormData: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
  baseUOM?: any;
  baseUOMDisabled?: boolean;
}

interface AddUomSchemaState {
  id: number;
  name: string;
  defaultUom: ReactSelectOptionsType<string, string>;
  defaultUomOptions: ReactSelectOptionsType<string, string>[];
  array: ValueCompare[];
}

interface ValueCompare {
  defaultUomValue: string;
  alternateUomValue: string;
  defaultUom: ReactSelectOptionsType<string, string>;
  alternateUom: ReactSelectOptionsType<string, string>;
  defaultUomOptions: ReactSelectOptionsType<string, string>[];
  alternateUomOptions: ReactSelectOptionsType<string, string>[];
  uid: string;
}

const AddUomSchemaPopUp: React.FC<AddUomSchemaProps> = (props) => {
  const { t, i18n } = useTranslation();

  const initialState: AddUomSchemaState = {
    id: 1,
    name: '',
    defaultUom: { label: 'NA', value: '5' },
    defaultUomOptions: [{ label: 'NA', value: '5' }],
    array: []
  };

  const [formState, setFormState] = useState<AddUomSchemaState>(
    deepClone(initialState)
  );
  const [defaultUomClicked, setDefaultUomClicked] = useState<boolean>(false);
  const uomSchemaData = useAppSelector(selectUOMs);
  const selectedAlternateUom: any[] = [];

  const dispatch = useAppDispatch();
  useEffect(() => {
    formState.array.forEach((element: any, index) => {
      selectedAlternateUom.forEach((object: any) => {
        const itemToBeRemoved = object;
        element.alternateUomOptions.splice(
          element.alternateUomOptions.findIndex(
            (a: any) => a.label === itemToBeRemoved.label
          ),
          1
        );
      });
      let found = false;
      found = selectedAlternateUom.find(
        (rowItem: any) =>
          JSON.stringify(rowItem) === JSON.stringify(element.alternateUom)
      );

      selectedAlternateUom.push(element.alternateUom);
    });
  }, [formState]);

  // useEffect(() => {
  //   if (props.populateFormData === null) {
  //     return;
  //   }

  // }, [formState.defaultUom.label]);

  useEffect(() => {
    const state = formState;
    let defaultUoms: ReactSelectOptionsType<string, string>[] = [];

    if (Array.isArray(uomSchemaData)) {
      uomSchemaData.forEach((uom: any) => {
        const option: ReactSelectOptionsType<string, string> = {
          label: uom.name,
          value: uom.id.toString()
        };
        defaultUoms.push(option);
      });
    }
    state.defaultUomOptions = defaultUoms;

    if (props.populateFormData && props.populateFormData.name) {
      state.name = props.populateFormData.name;
    }

    if (props.populateFormData && props.populateFormData.baseUOM) {
      const option: ReactSelectOptionsType<string, string> = {
        label: props.populateFormData.baseUOM.name,
        value: props.populateFormData.baseUOM.id.toString()
      };
      state.defaultUom = option;
    }

    if (props.baseUOM) {
      const option: ReactSelectOptionsType<string, string> = {
        label: props.baseUOM.name,
        value: props.baseUOM.id.toString()
      };
      state.defaultUom = option;
      addCompare();
    }

    if (props.populateFormData && props.populateFormData.uomSchemaDefinitions) {
      const object: ValueCompare[] = [];
      props.populateFormData.uomSchemaDefinitions.forEach((item: any) => {
        const defaultOption: ReactSelectOptionsType<string, string> = {
          label: props.populateFormData.baseUOM.name,
          value: props.populateFormData.baseUOM.id.toString()
        };
        const alternateOption: ReactSelectOptionsType<string, string> = {
          label: props.populateFormData.baseUOM.name,
          value: props.populateFormData.baseUOM.id.toString()
        };
        let alternateUoms: ReactSelectOptionsType<string, string>[] = [];
        let defaultUoms: ReactSelectOptionsType<string, string>[] = [];

        uomSchemaData.forEach((uom: any) => {
          const option: ReactSelectOptionsType<string, string> = {
            label: uom.name,
            value: uom.id.toString()
          };
          if (option.label !== formState.defaultUom.label) {
            alternateUoms.push(option);
          } else {
            defaultUoms.push(option);
          }
          if (option.value == item.sinkUOM.toString()) {
            alternateOption.label = uom.name;
            alternateOption.value = uom.id.toString();
          }
        });

        const objectChild: ValueCompare = {
          defaultUomValue: item.sourceConversionFactor.toString(),
          alternateUomValue: item.sinkConversionFactor.toString(),
          defaultUom: defaultOption,
          defaultUomOptions: defaultUoms,
          alternateUom: alternateOption,
          alternateUomOptions: alternateUoms,
          uid: item.uid
        };
        object.push(objectChild);
      });
      state.array = object;
    }
    if (props.populateFormData && props.populateFormData.id) {
      state.id = props.populateFormData.id;
    }
    setFormState({ ...state });
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.ADD_UOM_SCHEMA,
        data: () => {
          payloadData();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.EDIT_UOM_SCHEMA,
        data: () => {
          payloadDataForEdit();
        }
      });
    }
  };

  const findDefaultUom = () => {
    formState.defaultUomOptions.forEach((option: any) => {
      let found = false;
      selectedAlternateUom.forEach((value: any) => {
        if (option.value === value.value) {
          found = true;
        }
      });
      if (found === false) {
        return option;
      }
    });
    return { label: 'NA', value: '5' };
  };
  const initialCompare: ValueCompare = {
    defaultUomValue: '1',
    alternateUomValue: '1',
    defaultUom: { label: 'NA', value: '5' },
    defaultUomOptions: [{ label: 'NA', value: '5' }],
    alternateUom: findDefaultUom(),
    alternateUomOptions: [{ label: 'NA', value: '5' }],
    uid: ''
  };

  const payloadDataForEdit = () => {
    let arrayForPayLoad: any = [];
    formState.array.forEach((element) => {
      const arrayElemantForPayload: any = {
        sinkConversionFactor: Number(element.alternateUomValue),
        sinkUOM: Number(element.alternateUom.value),
        sourceConversionFactor: Number(element.defaultUomValue),
        sourceUOM: Number(element.defaultUom.value),
        uid: element.uid,
        sinkInput: Number(element.alternateUom.value)
      };
      arrayForPayLoad.push(arrayElemantForPayload);
    });
    const payload1 = {
      name: formState.name,
      baseUOM: Number(formState.defaultUom.value),
      uomSchemaDefinitions: arrayForPayLoad
    };

    InvoiceService.editUomSchema(payload1, formState.id)
      .then((response) => {
        showToast('UOM Schema updated successfully', TOAST_TYPE.SUCCESS);
        dispatch(fetchUomSchema());
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
      })
      .catch((error) => {
        console.error('Error editing UomSchema: ', error);
      });
  };

  const validation = () => {
    let valid = true;
    if (Utility.isEmpty(formState.name) || formState.name.trim() === '') {
      showAlert('Error', t(`SETTINGS.UOM.NAME_TOAST_ENTER`), [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      ]);
      valid = false;
    }

    if (formState.array && formState.array.length > 0) {
      formState.array.forEach((row: any) => {
        const x = selectedAlternateUom.find(
          (rowItem: any) =>
            JSON.stringify(rowItem) === JSON.stringify(row.alternateUom)
        );
        if (row.alternateUom.label === row.defaultUom.label) {
          showAlert('Error', t(`SETTINGS.UOM.DEFAULT_ALTERNATE_TOAST`), [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]);
          valid = false;
          //ALTERNATE_UOM_TOAST
        }

        if (
          Number(row.alternateUomValue) < 0 ||
          Number(row.defaultUomValue) < 0
        ) {
          showAlert(
            'Error',
            'Default or Alternate Uom Value cannot be negative',
            [
              {
                title: 'Ok',
                className: 'bg-button text-white',
                onClick: () => {}
              }
            ]
          );
          valid = false;
        }
        if (
          selectedAlternateUom &&
          selectedAlternateUom.filter(
            (rowItem: any) =>
              JSON.stringify(rowItem) === JSON.stringify(row.alternateUom)
          ).length > 1
        ) {
          showAlert('Error', t(`SETTINGS.UOM.ALTERNATE_UOM_TOAST`), [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]);
          valid = false;
        }
      });
    }
    return valid;
  };

  const payloadData = () => {
    if (validation() === false) {
      return;
    }

    let arrayForPayLoad: any = [];
    formState.array.forEach((element) => {
      const arrayElemantForPayload: any = {
        sinkConversionFactor: Number(element.alternateUomValue),
        sinkUOM: Number(element.alternateUom.value),
        sourceConversionFactor: Number(element.defaultUomValue),
        sourceUOM: Number(element.defaultUom.value),
        uid: '',
        sinkInput: Number(element.alternateUom.value)
      };
      arrayForPayLoad.push(arrayElemantForPayload);
    });
    const payload1 = {
      name: formState.name,
      baseUOM: Number(formState.defaultUom.value),
      uomSchemaDefinitions: arrayForPayLoad
    };

    InvoiceService.postUomSchema(payload1)
      .then((response) => {
        showToast('UOM Schema created successfully', TOAST_TYPE.SUCCESS);
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.UOM_SCHEMA_SUCCESS,
          data: response
        });
        dispatch(fetchUomSchema());
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
      })
      .catch((error) => {
        console.error('Error posting new UomSchema: ', error);
      });
  };

  const updateInputField: any = (event: any, field: string) => {
    const newState = formState;
    if (newState && field === 'name') {
      newState.name = event;
    }
    setFormState({ ...newState });
  };

  const updateInputFieldInsideArray: any = (
    event: any,
    field: string,
    index: number
  ) => {
    const newState = formState;
    if (newState && field === 'defaultUomValue') {
      newState.array[index].defaultUomValue = event;
    } else {
      newState.array[index].alternateUomValue = event;
    }
    setFormState({ ...newState });
  };

  const updateDefaultUom = (value: any | null, method: string) => {
    const newState = formState;
    if (newState && value) {
      if (method === 'default') newState.defaultUom = value;
      selectedAlternateUom.push(value);
      formState.array.forEach((element: any, index) => {
        element.defaultUom = value;
        const itemToBeRemoved = formState.defaultUom;
        element.alternateUomOptions.splice(
          element.alternateUomOptions.findIndex(
            (a: any) => a.label === itemToBeRemoved.label
          ),
          1
        );
        let found = false;
        found = selectedAlternateUom.find(
          (rowItem: any) =>
            JSON.stringify(rowItem) === JSON.stringify(element.alternateUom)
        );
        selectedAlternateUom.push(element.alternateUom);
      });
    }
    setFormState({ ...newState });
    if (!defaultUomClicked) addCompare();
    setDefaultUomClicked(true);
  };

  const updateAlternateUomInsideArray = (
    value: any | null,
    method: string,
    index: number
  ) => {
    const newState = formState;
    if (newState && value) {
      newState.array[index].alternateUom = value;
      formState.array.forEach((element: any, index) => {});
    }
    setFormState({ ...newState });
  };

  const addCompare = () => {
    const newState = formState;

    let alternateUoms: ReactSelectOptionsType<string, string>[] = [];
    let defaultUoms: ReactSelectOptionsType<string, string>[] = [];
    uomSchemaData.forEach((uom: any) => {
      const option: ReactSelectOptionsType<string, string> = {
        label: uom.name,
        value: uom.id.toString()
      };
      if (option.label !== formState.defaultUom.label) {
        alternateUoms.push(option);
      } else {
        defaultUoms.push(option);
        initialCompare.defaultUom = option;
      }
    });
    initialCompare.alternateUomOptions = alternateUoms;
    initialCompare.defaultUomOptions = defaultUoms;
    newState.array.push(initialCompare);
    setFormState({ ...newState });
  };

  const removeCompare = (index: number) => {
    const newState = formState;
    newState.array.splice(index, 1);
    setFormState({ ...newState });
  };

  return (
    <div className="p-2 w-full">
      <div className="flex flex-row justify-between items-center mb-4 pl-2">
        <DKLabel text={t(`SETTINGS.UOM_SCHEMA.NAME`) + ` *`} className="mt-4" />
        <div style={{ width: '60%' }} className="mb-1">
          <DKInput
            required={true}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={formState?.name}
            canValidate={formState.name.trim() === '' ? true : false}
            onChange={(event: string) => updateInputField(event, 'name')}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between items-center pl-2">
        <DKLabel text={t(`SETTINGS.UOM_SCHEMA.DEFAULT_UOM`)} />
        <div style={{ width: '60%' }}>
          <Select
            styles={currencyDropdownStyle}
            options={formState.defaultUomOptions}
            value={formState.defaultUom}
            onChange={(value) => {
              updateDefaultUom(value, 'default');
              for (let i = 1; i < formState.array.length; i++) {
                removeCompare(i);
              }
              for (let i = 0; i < selectedAlternateUom.length; i++) {
                selectedAlternateUom.splice(i);
              }
            }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={false}
            isDisabled={props.baseUOMDisabled ? true : false}
          />
        </div>
      </div>
      {formState.array.map((element, index) => {
        return (
          <div>
            <div className="flex flex-row justify-between w-12/12">
              <div className="flex flex-row w-5/12 text-center w-5/12 pl-2 font-semibold">
                <DKLabel
                  text={t(`SETTINGS.UOM_SCHEMA.DEFAULT_UOM`)}
                  className="mt-4 justify-items-center"
                />
              </div>
              <div className="w-1/12"></div>
              <div className="text-center  w-5/12 pl-3 font-semibold">
                <DKLabel
                  text={t(`SETTINGS.UOM_SCHEMA.ALTERNATE_UOM`)}
                  className="mt-4"
                />
              </div>
              <div className="w-1/12"></div>
            </div>
            <div className="flex flex-row justify-between  pr-2 pb-2">
              <div className="flex flex-row justify-around w-11/12 ">
                <div className=" w-5/12 flex flex-col">
                  <div className="py-0">
                    <DKInput
                      required={true}
                      placeholder={'Default UOM value'}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      value={formState?.array[index].defaultUomValue}
                      onChange={(event: any) =>
                        updateInputFieldInsideArray(
                          event,
                          'defaultUomValue',
                          index
                        )
                      }
                    />
                  </div>
                  <div className="font-bold mt-1 py-0">
                    <Select
                      styles={currencyDropdownStyle}
                      value={formState.defaultUom}
                      menuPlacement={'auto'}
                      menuPosition={'fixed'}
                      menuPortalTarget={document.body}
                      menuShouldScrollIntoView={false}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap content-center justify-center mt-1 font-black w-1/12">
                  <div> {'='}</div>
                </div>
                <div className="w-5/12 flex flex-col">
                  <div>
                    <DKInput
                      required={true}
                      placeholder={'Alternate UOM value'}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      value={formState?.array[index].alternateUomValue}
                      onChange={(event: any) =>
                        updateInputFieldInsideArray(
                          event,
                          'alternateUomValue',
                          index
                        )
                      }
                    />
                  </div>
                  <div className="font-bold py-0 mt-1">
                    <Select
                      options={formState.array[index].alternateUomOptions}
                      value={formState.array[index].alternateUom}
                      defaultValue={
                        formState.array[index].alternateUomOptions[0]
                      }
                      onChange={(value) => {
                        updateAlternateUomInsideArray(
                          value,
                          'alternateUomInsideArray',
                          index
                        );
                      }}
                      menuPlacement={'auto'}
                      menuPosition={'fixed'}
                      menuPortalTarget={document.body}
                      menuShouldScrollIntoView={false}
                      styles={currencyDropdownStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap content-center">
                <DKButton
                  className={'text-white mr-s  opacity-50'}
                  onClick={() => {
                    removeCompare(index);
                  }}
                  icon={DKIcons.ic_delete}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="mt-5 pl-2">
        <DKButton
          className={'border mr-s'}
          style={{ width: '135px' }}
          onClick={addCompare}
          title={'+ ' + t(`SETTINGS.UOM_SCHEMA.ADD_CONVERSATION`)}
        />
      </div>
    </div>
  );
};
export default AddUomSchemaPopUp;
