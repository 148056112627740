import { DKButton, DKLabel, DKRadioButton } from 'deskera-ui-library';
import { useState } from 'react';
import { DynamicPopupWrapper } from '../PopupWrapper';

enum ITC_OPTION {
  ITC_INELIGIBLE_SECTION_17 = 'ITC_INELIGIBLE_SECTION_17',
  ITC_INELIGIBLE_OTHERS = 'ITC_INELIGIBLE_OTHERS'
}

export const ITC_OPTION_SUMMARY: { [key: string]: any } = {
  ITC_INELIGIBLE_SECTION_17: 'Ineligible - As per section 17(5)',
  ITC_INELIGIBLE_OTHERS: 'Ineligible - Others'
};

export default function ItcOptions(props: any) {
  // { rowIndex: rowIndex, itcValue: itcValue }
  const [itcIneligible, setItcIneligible] = useState<string>(
    props.itcData.itcValue
  );
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={'Input Tax Credit'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() =>
              props.setITCIneligibleOption({
                rowIndex: props.itcData.rowIndex,
                itcValue: itcIneligible
              })
            }
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column align-items-start">
        <DKRadioButton
          className="row pt-xxl fs-l"
          title="Ineligible - As per section 17(5)"
          isSelected={itcIneligible === ITC_OPTION.ITC_INELIGIBLE_SECTION_17}
          color="bg-blue"
          onClick={() => {
            setItcIneligible(ITC_OPTION.ITC_INELIGIBLE_SECTION_17);
          }}
        />
        <DKRadioButton
          className="row pt-l"
          title="Ineligible - Others"
          isSelected={itcIneligible === ITC_OPTION.ITC_INELIGIBLE_OTHERS}
          color="bg-blue"
          onClick={() => {
            setItcIneligible(ITC_OPTION.ITC_INELIGIBLE_OTHERS);
          }}
        />
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 300,
            width: '90%',
            maxHeight: '85%',
            height: 200,
            padding: 0,
            paddingBottom: 0
          }}
        >
          {getHeader()}
          <div className="p-h-xl parent-width">{getBody()}</div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
