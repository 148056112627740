import { CurrencyFormatType, DateFormat } from "../../Constants/Constants"
import AppManager from "../../Manager/AppManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"

export default class PayslipParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        templateDataToReturn.payslip = dataFromAPI.peoplePayslipResponse
        templateDataToReturn.isFromERP = true

        if (dataFromAPI.peoplePayslipResponse.tenantInfo !== undefined && dataFromAPI.peoplePayslipResponse.tenantInfo !== null ) {
            var tenantInfo = dataFromAPI.peoplePayslipResponse.tenantInfo
            templateDataToReturn.companyName = tenantInfo.organizationName

            if (tenantInfo.address !== undefined && tenantInfo.address !== null) {
                var companyAddress = new Address(undefined, undefined, undefined, undefined, undefined, undefined, OwnerType.tenant)
                companyAddress.initPayslipAddress(tenantInfo.address)
                var address = AddressParser.getPayslipFormattedAddress(undefined, AddressType.from, companyAddress)

                templateDataToReturn.fromObj = companyAddress
                templateDataToReturn.from = address
            }

            if (tenantInfo.countryCode !== undefined && tenantInfo.countryCode !== null) {
                templateDataToReturn.country = tenantInfo.countryCode
            }

            //currency
            if (tenantInfo.currencySymbol !== undefined && tenantInfo.currencySymbol !== null) {
                templateDataToReturn.currency = tenantInfo.currencySymbol
                templateDataToReturn.currencyName = tenantInfo.currency
            }
            else {
                templateDataToReturn.currencyName = tenantInfo.currency
            }

            //Currency symbol
            var templateCurrency = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency
            if (templateCurrency !== undefined && templateCurrency !== null) {
                var newTemplateCurrency = templateCurrency
                if (templateCurrency.format !== null && templateCurrency.format !== undefined) {
                    if (templateCurrency.format === CurrencyFormatType.SYMBOL &&
                        (templateDataToReturn.currency === null || templateDataToReturn.currency === undefined)
                    ) {
                        var currency = {
                            isVisible: templateCurrency.isVisible,
                            format: CurrencyFormatType.NAME,
                            customValue: undefined
                        }
                        newTemplateCurrency = currency
                    }
                }
                templateDataToReturn.currencyFormat = newTemplateCurrency.format
                templateDataToReturn.currencyCustomValue = newTemplateCurrency.customValue

                //display the type according to document currency symbol/name
                if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL) {
                    templateDataToReturn.currencyCustomValue = templateDataToReturn.currency
                }
                else if (newTemplateCurrency.format === CurrencyFormatType.NAME) {
                    templateDataToReturn.currencyCustomValue = templateDataToReturn.currencyName
                }
                else if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL_NAME) {
                    templateDataToReturn.currencyCustomValue = (templateDataToReturn.currencyName ? templateDataToReturn.currencyName : '$') + ' ' + (templateDataToReturn.currency ? templateDataToReturn.currency : '$')
                }

                templateDataToReturn.showCurrency = newTemplateCurrency.isVisible
                templateDataToReturn.showCheckCurrency = newTemplateCurrency.isVisibleCheckCurrency
            }
            else {
                var defaultCurrency = {
                    isVisible: true,
                    format: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? CurrencyFormatType.CUSTOM : CurrencyFormatType.NAME,
                    customValue: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? templateDataToReturn.currency : templateDataToReturn.currencyName
                }

                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency = defaultCurrency
                templateDataToReturn.showCurrency = true
                templateDataToReturn.currencyFormat = defaultCurrency.format
                templateDataToReturn.currencyCustomValue = defaultCurrency.customValue
                templateDataToReturn.showCheckCurrency = false
            }
        }

        if (dataFromAPI.peoplePayslipResponse.tenantConfiguration !== undefined && dataFromAPI.peoplePayslipResponse.tenantConfiguration !== null) {
            var tenantConfiguration = dataFromAPI.peoplePayslipResponse.tenantConfiguration

            // get decimal scale
            if (tenantConfiguration.decimalFormat !== null && tenantConfiguration.decimalFormat !== undefined) {
                var tenantScale = parseInt(tenantConfiguration.decimalFormat)
                AppManager.setTenantDecimalScale(tenantScale)
            }

            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale === undefined ||
                (TemplateSettingsManager.getRemoteTemplateUID() === null ||
                TemplateSettingsManager.getRemoteTemplateUID() === undefined)) {
                var scale = 2

                if (tenantConfiguration.decimalFormat !== null && tenantConfiguration.decimalFormat !== undefined) {
                    scale = parseInt(tenantConfiguration.decimalFormat)
                }

                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['decimalScale'] = { 'value': scale }
                AppManager.setDecimalScale(scale)
            }
            else {
                AppManager.setDecimalScale(TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value)
            }

            //Date Format
            templateDataToReturn.tenantDateFormat = DateFormat.DD_MM_YYYY_SLASH
            let dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.format
            if (tenantConfiguration.dateFormat !== undefined && tenantConfiguration.dateFormat !== null) {
                if (tenantConfiguration.dateFormat !== '') {
                    templateDataToReturn.tenantDateFormat = tenantConfiguration.dateFormat
                }
            }

            if (TemplateSettingsManager.getRemoteTemplateUID() === null || TemplateSettingsManager.getRemoteTemplateUID() === undefined) {
                dateFormat = templateDataToReturn.tenantDateFormat
            }
            templateDataToReturn.documentDateFormat = dateFormat

            if(TemplateSettingsManager.getDateFormatIsSystemDefault()) {
                templateDataToReturn.dateFormat = templateDataToReturn.tenantDateFormat
                templateDataToReturn.payslip.dateFormat = templateDataToReturn.tenantDateFormat
            }
            else {
                templateDataToReturn.dateFormat = templateDataToReturn.documentDateFormat
                templateDataToReturn.payslip.dateFormat = templateDataToReturn.documentDateFormat
            }
        }

        templateDataToReturn.templateId = this.getDefaultTemplateId(templateDataToReturn.country)
        if (TemplateSettingsManager.defaultTemplateSettings.templateInfo.id !== undefined && TemplateSettingsManager.defaultTemplateSettings.templateInfo.id !== null) {
            templateDataToReturn.templateId = TemplateSettingsManager.defaultTemplateSettings.templateInfo.id
        }
        return templateDataToReturn
    }

    static getDefaultTemplateId(countryCode) {
        //refer to 'getPayslipTemplateWith' function
        var templateId = 5
        switch (countryCode) {
            case 'IN':
                templateId = 1
                break;
            case 'US':
                templateId = 2
                break;
            case 'SG':
                templateId = 3
                break;
            case 'MY':
                templateId = 4
                break;
            default:
                break;
        }
        return templateId
    }

}
