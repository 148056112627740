import CustomFieldManager from "../../Manager/CustomFieldManager"
import { getDateString, getTitleCase } from "../../Utilities/Utility"
import DataParser from "../DataParser"

export default class QCInspectionParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        let customField = this.getWorkOrderResponse(dataFromAPI)?.customField ?? []
        templateDataToReturn.customFields = [...customField]
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        let startDate = ''
        let startDateTimeStamp = this.getWorkOrderResponse(dataFromAPI).actualStartDate  ?? ''
        if (startDateTimeStamp !== '') {
            startDate = getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.woDate = startDate

        let endDate = ''
        let endDateTimeStamp = this.getWorkOrderResponse(dataFromAPI) ? this.getWorkOrderResponse(dataFromAPI).actualEndDate : ''
        if (endDateTimeStamp !== '') {
            endDate = getDateString(new Date(endDateTimeStamp), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.woEndDate = endDate

        templateDataToReturn.woCode = this.getWorkOrderResponse(dataFromAPI)?.documentSequenceCode ?? ''
        templateDataToReturn.woStatus = this.getWorkOrderResponse(dataFromAPI)?.status ?? ''
        templateDataToReturn.woStatus = getTitleCase(templateDataToReturn.woStatus)

        templateDataToReturn.refNumber = this.getWorkOrderResponse(dataFromAPI)?.documentSequenceCode ?? ''

        templateDataToReturn.productName = this.getWorkOrderResponse(dataFromAPI)?.productName ?? ''
        templateDataToReturn.productDescription = this.getWorkOrderResponse(dataFromAPI)?.productDescription ?? ''
        templateDataToReturn.productCode = this.getWorkOrderResponse(dataFromAPI)?.productCode ?? ''
        templateDataToReturn.productDocSeqCode = this.getWorkOrderResponse(dataFromAPI)?.productDocSeqCode ?? undefined
        if (templateDataToReturn.productDocSeqCode) {
            templateDataToReturn.productCode = templateDataToReturn.productDocSeqCode
        }

        templateDataToReturn.woCustomFields = this.getWorkOrderResponse(dataFromAPI)?.customField ?? []
        templateDataToReturn.productMasterCustomFields = this.getWorkOrderResponse(dataFromAPI)?.productCustomField ?? []

        templateDataToReturn.lineItems = []
        
        return templateDataToReturn
    }

    static getWorkOrderResponse(dataFromApi) {
        return dataFromApi.workOrderResponse ?? undefined
    }
}
