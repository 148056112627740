import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import { RootState } from '../../Store';
import { BOM_EXPLOSION_COLUMN_KEYS } from '../../../Components/Mrp/Constants/MRPColumnConfigs';
import { INPUT_TYPE } from 'deskera-ui-library';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const BOMExplosionSlice = createSlice({
  name: 'BOMExplosion',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addBOMExplosionColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        const column: IColumn = { ...config };

        if (config.key === BOM_EXPLOSION_COLUMN_KEYS.PRODUCT_TYPE) {
          column.options = (column.options || []).map((option) =>
            option.id === 'nt'
              ? {
                  id: 'nt',
                  name: 'Non-Tracked',
                  color: 'data-grid-badge-color-4',
                  systemField: true
                }
              : option
          );
        }

        configsToStore.push(column);
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.MRP_BOM_EXPLOSION
      );
    },
    addColumnToBOMExplosionColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  }
});

export const {
  updateColumnConfig,
  addBOMExplosionColumnConfig,
  addColumnToBOMExplosionColumnConfig
} = BOMExplosionSlice.actions;

export const selectBOMExplosionColumnConfig = (state: RootState) =>
  state.mrpBOMExplosion.columnConfig;
export const selectBOMExplosionColumnConfigTableId = (state: RootState) =>
  state.mrpBOMExplosion.configTableId;
