import { Store } from '../Redux/Store';
import authData, { APP_NAME } from '../Constants/Constant';
import Utility from '../Utility/Utility';

const store = Store;

// *TODO - Remove this action type
export enum ActionTypeEnum {
  READ = 'r',
  WRITE = 'w',
  DELETE = 'd'
}

export enum ModulePermissionEnum {
  SELL = 'erp_sell',
  USER_MANAGEMENT = 'erp_user_mgmt',
  ACCOUNTING = 'erp_accounting',
}

export class RolePermissionUtil {
  static checkUserModulePermission(): boolean {
    const userModules =
      store.getState().authInfo.permissionInfo.data &&
      store.getState().authInfo.permissionInfo.data.length > 0
        ? store.getState().authInfo.permissionInfo.data[0].apps
        : [];
    if (userModules && userModules.length > 0) {
      if (Object.keys(userModules).length !== 0) {
        return true;
      }
    }
    return false;
  }

  static checkModulePermission(module: any) {
    if (authData._isOwner) {
      return true;
    }

    if (!this.checkUserModulePermission()) {
      return false;
    }

    let permissionInfo: any = {};
    if (
      store.getState().authInfo.permissionInfo.data &&
      store.getState().authInfo.permissionInfo.data.length > 0
    ) {
      permissionInfo = store.getState().authInfo.permissionInfo.data[0];
    }

    const userErpRole = permissionInfo.apps.filter(
      (app: any) => app.appName === APP_NAME
    );
    if (userErpRole.length > 0) {
      return userErpRole[0].modules
        .map((x: any) => x.groupName.toLowerCase())
        .includes(module.toLowerCase());
    }
    return false;
  }

  static hasPermission(
    moduleGroup: ModulePermissionEnum,
    actionType: ActionTypeEnum
  ) {
    if (authData._isOwner) {
      return true;
    }
    if (!this.checkUserModulePermission()) {
      return false;
    }

    let permissionInfo: any = {};
    if (
      store.getState().authInfo.permissionInfo.data &&
      store.getState().authInfo.permissionInfo.data.length > 0
    ) {
      permissionInfo = store.getState().authInfo.permissionInfo.data[0];
    }

    const userErpRole = permissionInfo.apps.filter(
      (app: any) => app.appName === APP_NAME
    );
    if (userErpRole.length > 0) {
      const module = userErpRole[0].modules.filter(
        (module: any) => module.groupName === moduleGroup
      );
      if (module.length > 0) {
        switch (actionType) {
          case ActionTypeEnum.READ:
            if (
              module[0].accessLevel.toLowerCase().includes(ActionTypeEnum.READ)
            ) {
              return true;
            }
            break;
          case ActionTypeEnum.WRITE:
            if (
              module[0].accessLevel.toLowerCase().includes(ActionTypeEnum.WRITE)
            ) {
              return true;
            }
            break;
          case ActionTypeEnum.DELETE:
            if (
              module[0].accessLevel
                .toLowerCase()
                .includes(ActionTypeEnum.DELETE)
            ) {
              return true;
            }
            break;
          default:
            return false;
        }
      }
    }
    return false;
  }

  static setOwner(data: any) {
    const organizationRole = data.filter(
      (app: any) => app.appName === 'ORGANISATION'
    );
    if (organizationRole.length > 0) {
      authData._isOwner =
        organizationRole[0].shortCode === 'owner' ? true : false;
    } else {
      authData._isOwner = false;
    }
  }

  static checkIsOwner() {
    return authData?._isOwner;
  }

  static checkIsAdmin() {
    let isAdmin: any = false;

    const userModules =
      store.getState().authInfo.permissionInfo.data &&
      store.getState().authInfo.permissionInfo.data.length > 0
        ? store.getState().authInfo.permissionInfo.data[0].apps
        : [];
    let filtered = userModules?.filter((item: any) => {
      return item.appName.toUpperCase() === APP_NAME;
    });

    if (!Utility.isEmpty(filtered)) {
      isAdmin = filtered[0].roleGroup.shortCode === 'admin' ? true : false;
    } else {
      isAdmin = false;
    }
    return isAdmin;
  }

  static isReportBuilderPermission() {
    const userModules =
      store.getState().authInfo.permissionInfo.data &&
      store.getState().authInfo.permissionInfo.data.length > 0
        ? store.getState().authInfo.permissionInfo.data[0].apps
        : [];
    let filtered = userModules?.filter((item: any) => {
      return item.appName.toUpperCase() === 'REPORT_BUILDER';
    });

    if (!Utility.isEmpty(filtered)) {
      return true;
    } else {
      return false;
    }
  }

  static checkModuleRolePermission(
    module: ModulePermissionEnum = ModulePermissionEnum.SELL,
    actionTypeEnum: ActionTypeEnum
  ): boolean {
    if (localStorage.getItem('isBookKepper') === 'true') {
      return true;
    }
    if (this.hasPermission(module, actionTypeEnum)) {
      return true;
    } else {
      return false;
    }
  }

  static handleAPICallPermission(
    module: ModulePermissionEnum = ModulePermissionEnum.SELL
  ): boolean {
    if (this.checkModulePermission(module)) {
      return true;
    } else {
      return false;
    }
  }
}
