import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKIcon,
  DKIcons,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import Utility from '../Utility/Utility';

import { useAppSelector } from '../Redux/Hooks';
import { selectContactsColumnConfig } from '../Redux/Slices/ContactsSlice';
import DateFormatService from '../Services/DateFormat';

// import { GOOGLE_MAP_URL } from '../../constants/Constant';
// import { FILE_INPUT_TYPE } from '../../constants/Enum';
// import { Renderers } from '../../Helper/Renderers';
// import RouteManager, {
//   PAGE_ROUTES,
//   ROUTE_ID_IDENTIFIER
// } from '../../managers/RouteManager';
// import { COLUMN_CODE, TableManger, TABLES } from '../../managers/TableManger';
// import {
//   getHumanReadableFileSize,
//   MAX_FILE_SIZE,
//   uploadFileToAWS
// } from '../../services/common/file';
// import {
//   convertCRMDateFormatToUILibraryFormat,
//   DateUtil
// } from '../../utility/Date';
// import Utility, { isString } from '../../utility/Utility';
// import DetailedAddress from './DetailedAddress';

export enum FILE_INPUT_TYPE {
  FILE = 'file'
}

export interface IEditableComponentState {
  contactId?: string;
  tableName?: string;
  fields?: any;
  canEdit?: boolean;
  onChange(event: any, ids: any, refteshOnly?: boolean): void;
  onClickValue?: () => void;
}

const EditableComponent: React.FC<IEditableComponentState> = (props): any => {
  const [fields, setFields] = useState([]);
  const [selectedEditableCol, setSelectedEditableCol] = useState('');
  const [detailValues, setDetailValues] = useState<any>([]);
  const columnConfig = useAppSelector(selectContactsColumnConfig);
  useEffect(() => {
    setFields(props.fields);
  }, [props.fields]);

  //     this.attachmentPickerExists = false;
  //     this.attachmentOpenFileRef = React.createRef();
  //     this.detailValues = [];
  //     this.addressColumnId =
  //       props.tableName === TABLES.CONTACT
  //         ? TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.ADDRESS)
  //         : null;
  //   }

  //   componentWillReceiveProps(nextProps) {
  //     this.setState({
  //       fields: this.getFieldsInOrder(nextProps.fields)
  //     });
  //   }

  //  const getFieldsInOrder = (fields:any) => {
  //     if (Utility.isEmptyObject(props.tableName) || !fields?.length)
  //       return fields;

  //     const tableColumns = TableManger.getTableColumns(props.tableName);
  //     const fieldsIndex = {};
  //     tableColumns?.forEach((column, columnIndex) => {
  //       fieldsIndex[column.id] = columnIndex;
  //     });
  //     const fieldsToSort = [...fields];
  //     fieldsToSort.sort((field1, field2) => {
  //       const field1Index = fieldsIndex[field1?.colCode];
  //       const field2Index = fieldsIndex[field2?.colCode];
  //       return field1Index - field2Index;
  //     });

  //     return fieldsToSort;
  //   };

  const fieldRenderer = () => {
    let displayFields: any[] = [];
    fields?.forEach((data) => {
      const fieldContent = getFieldValue(data);
      if (!fieldContent) return;
      displayFields.push(
        <div className="column parent-width justify-content-between mb-xl">
          {fieldContent}
        </div>
      );
    });
    return displayFields;
  };

  const getFieldValue = (data: any) => {
    switch (data.type) {
      case INPUT_TYPE.MULTI_SELECT:
        return (
          <DKInput
            className="common-select"
            value={data.value}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            titleStyle={{ color: 'gray' }}
            valueStyle={{
              padding: 0,
              minHeight: 23,
              border: 'none',
              backgroundColor: 'transparent',
              fontWeight: 'normal',
              opacity: props.canEdit ? 1 : 0.8
            }}
            renderer={(values: any) => {
              return (
                <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
                  {values.map((value: any) => {
                    return (
                      <DKLabel
                        className="bg-gray1 border-radius-s p-h-xs border-m"
                        text={data.options[value]?.name || '-'}
                      />
                    );
                  })}
                </div>
              );
            }}
            title={data.name}
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            canValidate={false}
            readOnly={!props.canEdit}
            onChange={(value: any) => {
              let selectedIds = getMultipleOptionIds(data.options, value);
              props.onChange(data.colCode, selectedIds);
            }}
            dropdownConfig={{
              className: '',
              title: `Select ${data.name}`,
              allowSearch: true,
              searchableKey: 'name',
              multiSelect: true,
              selectedIndexes: [],
              checkMarkColor: 'bg-button',
              data: data.options,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              },
              onSelect: (index: any, value: any) => {},
              onClear: () => {
                props.onChange(data.colCode, []);
              }
            }}
          />
        );
      case INPUT_TYPE.SELECT:
        return (
          <DKInput
            title={data.name}
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            className="common-select"
            value={data.value}
            canValidate={false}
            readOnly={!props.canEdit}
            onChange={(value: any) => {}}
            formatter={(obj: any) => {
              return obj.name;
            }}
            renderer={(value: any) => {
              return (
                <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
                  <DKLabel
                    className="bg-gray1 border-radius-s p-h-xs border-m"
                    text={
                      data.options.find((item: any) => item.id === value.id)
                        ?.name || '-'
                    }
                  />
                </div>
              );
            }}
            dropdownConfig={{
              title: `Select ${data.name}`,
              allowSearch: true,
              searchableKey: 'name',
              style: { minWidth: 230 },
              className: 'shadow-m',
              data: data.options,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              },
              onSelect: (index: any, obj: any, rowIndex: any) => {
                props.onChange(data.colCode, [obj.id]);
              }
            }}
            titleStyle={{ color: 'gray' }}
            valueStyle={{
              padding: 0,
              minHeight: 23,
              border: 'none',
              backgroundColor: 'transparent',
              fontWeight: 'normal',
              opacity: props.canEdit ? 1 : 0.8
            }}
            onClick={props.onClickValue}
          />
        );
      case INPUT_TYPE.DROPDOWN:
        const detailValueIds = detailValues.map((value: any) => value.id);
        let keys: any[] = [];
        data.keyOptions?.forEach((keyOption: any) => {
          if (!detailValueIds.includes(keyOption.id)) {
            keys.push(keyOption);
          }
        });
        // setDetailValues([...keys])
        // const accountColumnId = TableManger.getColumnId(
        //   TABLES.CONTACT,
        //   COLUMN_CODE.CONTACT.ACCOUNT
        // );
        const isReadOnly = !props.canEdit || data.readOnly;
        const selectedOptionId = data.value?.[0];
        const selectedOptionName = detailValues.find(
          (option: any) => option.id === selectedOptionId
        )?.name;

        if (
          Utility.isEmptyObject(selectedOptionName) &&
          !Utility.isEmptyObject(selectedOptionId)
        )
          return null;

        return (
          <DKInput
            title={data.name}
            type={INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={data.required}
            className="common-select"
            renderer={(obj: any) => {
              return (
                <div className="row width-auto bg-gray1 border-radius-s p-xs border-m mr-r">
                  <div
                    className={`row width-auto z-index-1 ${
                      isReadOnly ? 'cursor-none' : 'cursor-hand'
                    }`}
                    onClick={(e) => {
                      //   if (data.colCode !== accountColumnId) return;

                      e.stopPropagation();
                      //   RouteManager.navigateToPage(
                      //     PAGE_ROUTES.ACCOUNT_DETAILS.replace(
                      //       ROUTE_ID_IDENTIFIER,
                      //       selectedOptionId
                      //     )
                      //   );
                    }}
                  >
                    <DKLabel
                      className={'text-underline'}
                      text={selectedOptionName}
                    />
                  </div>
                  <DKIcon
                    src={DKIcons.ic_close_}
                    className={
                      'ic-s-2 ml-s unselectable ' +
                      (isReadOnly ? 'cursor-none' : 'cursor-hand')
                    }
                    style={{ height: 10, opacity: 0.5, padding: 1 }}
                    onClick={(e: any) => {
                      if (isReadOnly) {
                        return;
                      }

                      e.stopPropagation();
                      props.onChange(data.colCode, []);
                    }}
                  />
                </div>
              );
            }}
            value={Utility.isEmptyObject(data.value) ? null : data.value}
            canValidate={false}
            onChange={(value: any) => {
              const selectedIds = value?.id ? [value.id] : [];
              props.onChange(data.colCode, selectedIds);
            }}
            readOnly={isReadOnly}
            dropdownConfig={{
              ...data.dropdownConfig,
              onSelect: (index: any, obj: any, rowIndex: any) => {
                if (!detailValueIds.includes(obj.id)) {
                  //   detailValues.push(obj);
                }
              }
            }}
            titleStyle={{ color: 'gray' }}
            valueStyle={{
              padding: 0,
              minHeight: 23,
              border: 'none',
              backgroundColor: 'transparent',
              fontWeight: 'normal',
              opacity: props.canEdit ? 1 : 0.8
            }}
            onClick={props.onClickValue}
          />
        );
      case INPUT_TYPE.TEXT:
      case INPUT_TYPE.NUMBER:
      case INPUT_TYPE.EMAIL:
      case INPUT_TYPE.PHONE:
        return (
          <>
            <DKLabel className="text-gray" text={data.name} />
            <div
              className={`row field-edit mt-s ${
                props.canEdit ? 'cursor-hand' : 'text-gray'
              }`}
              onClick={(e: any) => {
                if (props.canEdit) {
                  e.stopPropagation();
                  getEditableField(e, data);
                } else if (props.onClickValue) {
                  props.onClickValue();
                }
              }}
            >
              <DKLabel
                className={` field-content-div ${data.colCode}`}
                text={data.value || `NA`}
                style={{
                  maxWidth: '95%',
                  fontWeight: 'normal',
                  paddingLeft: 1
                }}
              />
              {props.canEdit && (
                <DKIcon
                  className="mr-s ic-xs ml-s edit-icon cursor-hand "
                  src={DKIcons.ic_edit}
                  style={{ opacity: 0.5 }}
                  onClick={(e: any) => getEditableField(e, data)}
                />
              )}
              {data.name === 'Address' &&
                !Utility.isEmptyObject(data.value) &&
                data.value !== 'NA' && (
                  <DKIcon
                    className="mr-s ic-xs ml-xs edit-icon cursor-hand "
                    src={DKIcons.ic_location}
                    style={{ opacity: 0.7 }}
                    // onClick={(e: any) =>
                    //   Utility.openInNewTab(
                    //     GOOGLE_MAP_URL + encodeURI(data.value)
                    //   )
                    // }
                  />
                )}
            </div>
          </>
        );

      case INPUT_TYPE.DATE:
        let date = Utility.isEmptyObject(data.value)
          ? null
          : new Date(data.value);
        return (
          <DKInput
            className="common-date"
            value={date}
            title={data.name}
            placeholder=""
            required={false}
            type={INPUT_TYPE.DATE}
            direction="vertical"
            onChange={(value: any) => {
              //   const updatedDateWithCurrentTime =
              //     DateUtil.getDateWithCurrentTime(value);
              //   props.onChange(data.colCode, updatedDateWithCurrentTime);
            }}
            readOnly={!props.canEdit}
            titleStyle={{ color: 'gray' }}
            valueStyle={{
              padding: 0,
              minHeight: 23,
              border: 'none',
              backgroundColor: 'transparent',
              fontWeight: 'normal'
            }}
            // dateFormat={convertCRMDateFormatToUILibraryFormat(
            //   DateUtil.getOrgDateFormat()
            // )}
          />
        );
      //   case FILE_INPUT_TYPE.FILE:
      //     return (
      //       <>
      //         <DKLabel className="text-gray mb-s" text={data.name} />
      //         <div className={`row align-items-center`}>
      //           {data.value?.length > 0 && (
      //             <div
      //               className="row flex-wrap"
      //               style={{ overflowX: 'hidden', gap: 4 }}
      //             >
      //               {Renderers.attachmentRenderer(data)}
      //             </div>
      //           )}
      //           {this.getAttachmentPicker()}
      //           <DKButton
      //             className="text-blue"
      //             title={'Browse'}
      //             disabled={!props.canEdit}
      //             onClick={() => this.openAttachmentPicker()}
      //             style={{
      //               padding: 0,
      //               opacity: props.canEdit ? 1 : 0.8
      //             }}
      //           />
      //         </div>
      //       </>
      //     );
      //   case 'address':
      //     const addressField = fields?.find(
      //       (field: any) => field.colCode === addressColumnId
      //     );
      //     return (
      //       <>
      //         <DKLabel className="text-gray" text={data.name} />
      //         <DetailedAddress
      //           recordId={props.contactId}
      //           tableName={props.tableName}
      //           addressList={data.value || []}
      //           className="p-h-0"
      //           styles={{ paddingBottom: 0 }}
      //           defaultFullAddress={
      //             Utility.isEmptyObject(data.value)
      //               ? addressField?.value || ''
      //               : ''
      //           }
      //           displayFullAddress={Utility.isEmptyObject(data.value) ? 'NA' : ''}
      //           needLocationMarker={!Utility.isEmptyObject(data.value)}
      //           readOnly={!props.canEdit}
      //           onEdit={(index: any, addressList: any) =>
      //             props.onChange(data.colCode, addressList, true)
      //           }
      //           onDelete={(index: any, addressList: any) =>
      //             props.onChange(data.colCode, addressList, true)
      //           }
      //           onAdd={(addressList: any) =>
      //             props.onChange(data.colCode, addressList, true)
      //           }
      //         />
      //       </>
      //     );
      default:
        return (
          <>
            <DKLabel className="text-gray" text={data.label} />
            <DKLabel
              className="mt-s"
              text={formattedData(data)}
              style={{ fontWeight: 'normal' }}
            />
          </>
        );
    }
  };
  //   const openAttachmentPicker = () => {
  //     attachmentPickerExists = true;
  //     attachmentOpenFileRef?.current?.click();
  //   };
  //   const getAttachmentPicker = () => {
  //     return (
  //       <input
  //         id="dataGridAttachment"
  //         type="file"
  //         accept="image/*, application/pdf, .txt, .doc, .xls , .ppt, .docx, .xlsx, .pptx"
  //         multiple={true}
  //         ref={attachmentOpenFileRef}
  //         style={{ display: 'none' }}
  //         onClick={(e) => {
  //           initializeImageSelect();
  //           e.stopPropagation();
  //         }}
  //         onChange={(e) => {
  //           if (!e.target.files) return;

  //           const files = Array.from(e.target.files);

  //           const fileExceedingLimit = files.filter(
  //             (file) => file.size > MAX_FILE_SIZE
  //           );
  //           if (fileExceedingLimit.length) {
  //             showAlert(
  //               'File size exceeds! ',
  //               `File size should not be more than ${getHumanReadableFileSize(
  //                 MAX_FILE_SIZE
  //               )}. Please optimize <em>${fileExceedingLimit
  //                 .map((file) => file.name)
  //                 .join(', ')}</em> or select different file.`
  //             );
  //             return;
  //           }

  //           showToast('Uploading attachments...');

  //           Promise.all(files.map((file) => uploadFileToAWS(file)))
  //             .then((responseList) => {
  //               const attachmentColId = TableManger.getColumnId(
  //                 props.tableName,
  //                 COLUMN_CODE.CONTACT.ATTACHMENT
  //               );
  //               const attachmentFieldIndex = this.state.fields.findIndex(
  //                 (field: any => field.colCode === attachmentColId
  //               );

  //               let attachments = this.state.fields[attachmentFieldIndex]?.value
  //                 ? Utility.decodeJSON(
  //                     this.state.fields[attachmentFieldIndex]?.value
  //                   )
  //                 : [];

  //               let hasValidAttachment = false;

  //               responseList.forEach((attachmentPath, index) => {
  //                 const file = files[index];
  //                 if (
  //                   !Utility.isEmptyObject(attachmentPath) &&
  //                   file &&
  //                   isString(attachmentPath)
  //                 ) {
  //                   attachments.push({
  //                     path: attachmentPath,
  //                     title: file.name,
  //                     type: file.type
  //                   });

  //                   hasValidAttachment = true;
  //                 }
  //               });

  //               if (!hasValidAttachment) return;

  //               props?.onChange(attachmentColId, Utility.encodeJSON(attachments));
  //             })
  //             .catch((err) => {})
  //             .finally(() => {
  //               attachmentPickerExists = false;
  //             });
  //         }}
  //       />
  //     );
  //   };
  //   const initializeImageSelect = () => {
  //     document.body.onfocus = checkIt;
  //   };

  //   const checkIt = () => {
  //     if (document.querySelector('#dataGridAttachment')) {
  //       const fileElement = document.querySelector('#dataGridAttachment');
  //       if (fileElement.files && fileElement.files.length === 0) {
  //         this.attachmentPickerExists = false;
  //         return;
  //       }
  //     }
  //   };

  const formattedData = (data: any) => {
    if (!data) return `NA`;
    let columnType: any = columnConfig.find(
      (column) => column.name === data.label
    );

    if (columnType?.type === 'date') {
      return DateFormatService.getDateStrFromDate(new Date(data.value));
    } else {
      return data.value || 'NA';
    }
  };

  const getEditableField = (e: any, deal: any) => {
    if (selectedEditableCol === deal.colCode) {
      return null;
    }
    let editButton = document.getElementsByClassName('edit-icon');
    for (let i = 0; i < editButton.length; i++) {
      editButton[i].className =
        editButton[i].className.replace(`hide-icon`, '') + ' hide-icon';
    }
    let allEditableFields =
      document.getElementsByClassName('field-content-div');
    for (let i = 0; i < allEditableFields.length; i++) {
      allEditableFields[i].setAttribute('contenteditable', 'false');
    }
    setSelectedEditableCol(deal.colCode);
    let editField = document.getElementsByClassName(deal.colCode);
    editField[0].innerHTML = editField[0].innerHTML.replace(`NA`, '');
    editField[0].className =
      editField[0].className.replace('input-underline', '') +
      ' input-underline';
    editField[0].setAttribute('contenteditable', 'true');
    const selection = window.getSelection();
    const range = document.createRange();
    selection?.removeAllRanges();
    range.selectNodeContents(editField[0]);
    range.collapse(false);
    selection?.addRange(range);
    // editField[0]?.focus();
    // editField[0]?.dataset?.deal = JSON.stringify(deal);
    editField[0].addEventListener('blur', onHandleBlur);

    // editField[0].addEventListener("keydown", (e) => {
    //   if (e.keyCode === 13) {
    //     document.execCommand('insertLineBreak')
    //     e.preventDefault()
    //   }
    // });
    editField[0].addEventListener('paste', onPastePlainText);
  };
  const onPastePlainText = (e: any) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  const onHandleBlur = (event: any) => {
    event.preventDefault();
    let editField = event.target;
    let deal = JSON.parse(editField.dataset.deal);
    let value = editField.innerText.replace(/<br>$/, '');
    if (deal.required && Utility.isEmptyObject(value)) {
      editField.innerHTML = deal.value || `NA`;
      showErrorAlert(`${deal.name} is empty`);
    } else if (Utility.isEmptyObject(value)) {
      editField.innerHTML = `NA`;
    } else if (deal.type === INPUT_TYPE.EMAIL && !Utility.isValidEmail(value)) {
      editField.innerHTML = deal.value || `NA`;
      showErrorAlert(`${deal.name} is not valid email`);
    } else if (
      deal.type === INPUT_TYPE.NUMBER &&
      !Utility.isValidNumber(value)
    ) {
      editField.innerHTML = deal.value || `NA`;
      showErrorAlert(`${deal.name} is not valid number`);
    } else if (deal.value !== value) {
      /* for handling duplicate email check in contact table */
      //   if (
      //     [TABLES.CONTACT].includes(props?.tableName) &&
      //     deal.type === INPUT_TYPE.EMAIL
      //   ) {
      //     editField.innerHTML = deal.value || `NA`;
      //   }

      props.onChange(deal.colCode, value);
    }
    setSelectedEditableCol('');
    editField.setAttribute('contenteditable', 'false');
    editField.className = editField.className.replace('input-underline', '');
    editField.removeEventListener(editField, onHandleBlur);
    let button = document.getElementsByClassName('edit-icon');
    for (let i = 0; i < button.length; i++) {
      button[i].className = button[i].className.replace('hide-icon', '');
    }
  };

  const showErrorAlert = (error: any) => {
    showAlert('Error', error);
  };

  const getMultipleOptionIds = (option: any, selectedValue: any) => {
    let selectedId: any[] = [];
    selectedValue.forEach((index: any) => {
      selectedId.push(option[index]?.id);
    });
    return selectedId;
  };
  return fieldRenderer();
};

export default EditableComponent;
