import { DKIcon, DKTooltipWrapper, INPUT_TYPE } from 'deskera-ui-library';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import { fetchAdvancedTrackingData } from '../../../../../Redux/Slices/AdvancedTrackingDataSlice';
import { Store } from '../../../../../Redux/Store';
import Utility from '../../../../../Utility/Utility';
import ic_barcode_green from '../../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../../Assets/Icons/ic_barcode_red.svg';

export default class WasteManagementListHelper {
  static SCRAP_COLUMMNS = [
    {
      name: 'Item',
      key: 'itemName',
      type: INPUT_TYPE.DROPDOWN,
      width: 132,
      dropdownConfig: {
        title: '',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: null,
          dataParser: {},
          debounceTime: 300
        },
        data: [],
        renderer: (index: number, product: any) => {
          return product.name;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      name: 'Type',
      key: 'produceProductType',
      type: INPUT_TYPE.TEXT,
      width: 120
    },
    {
      name: 'Price',
      key: 'costPerUnit',
      type: INPUT_TYPE.NUMBER,
      width: 140
    },
    {
      name: 'Planned Qty',
      key: 'requiredQty',
      type: INPUT_TYPE.NUMBER,
      width: 140
    },
    {
      name: 'Actual Qty',
      key: 'actualRequiredQty',
      type: INPUT_TYPE.NUMBER,
      width: 140
    },
    {
      name: '',
      key: 'actions',
      type: INPUT_TYPE.BUTTON,
      width: 120
    }
  ];

  static COLUMNS_FOR_CONFIRM_SCREEN = [
    {
      name: 'Item',
      key: 'itemName',
      type: INPUT_TYPE.DROPDOWN,
      width: 132,
      dropdownConfig: {
        title: '',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: null,
          dataParser: {},
          debounceTime: 300
        },
        data: [],
        renderer: (index: number, product: any) => {
          return product.name;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      name: 'Type',
      key: 'produceProductType',
      type: INPUT_TYPE.TEXT,
      width: 90
    },
    {
      name: 'Price',
      key: 'costPerUnit',
      type: INPUT_TYPE.NUMBER,
      width: 120
    },
    {
      name: 'Actual Qty',
      key: 'actualRequiredQty',
      type: INPUT_TYPE.NUMBER,
      width: 120
    },
    {
      name: '',
      key: 'actions',
      type: INPUT_TYPE.BUTTON,
      width: 115
    }
  ];

  static isSerialItemAssigned(item: any) {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: any, b: any) => (a += parseFloat(b['quantity'])),
          0
        )) ||
      0;
    return Number(totalQuantity) === Number(item?.actualRequiredQty);
  }

  static isNoneTrackedItemAssigned(item: any) {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return Number(item.actualRequiredQty) === Number(totalQuantity);
  }

  static getFlatArrayOfSerialSelections(obj: any, type: ADVANCE_TRACKING) {
    let mapped: any[];
    if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
      mapped = obj?.map((lineItem: any) => {
        return {
          ...lineItem,
          warehouse: {
            ...lineItem,
            name: lineItem.warehouseName,
            code: lineItem.warehouseCode
          }, // For serial tracked edit mode
          warehouseName: lineItem.warehouseName,
          warehouseCode: lineItem.warehouseCode
        };
      });
    } else {
      mapped = obj?.map((item: any) => {
        return item;
      });
    }

    let flatArray = mapped?.flat() || [];
    return flatArray;
  }

  static createProductItemForTracking(
    type: ADVANCE_TRACKING,
    selectedItem: any
  ) {
    return {
      product: selectedItem?.itemName,
      productCode: selectedItem?.itemName?.pid,
      requiredQuantity: Number(selectedItem.actualRequiredQty),
      quantityRequired: Number(selectedItem.actualRequiredQty),
      productQuantity: Number(selectedItem.actualRequiredQty),
      advancedTracking: selectedItem.itemName.advancedTracking,
      advancedTrackingFulfilmentData:
        WasteManagementListHelper.getFlatArrayOfSerialSelections(
          selectedItem?.warehouseInventoryData,
          type
        ),
      advancedTrackingMetaData:
        WasteManagementListHelper.getFlatArrayOfSerialSelections(
          selectedItem?.warehouseInventoryData,
          type
        ),
      isQuickCommit: false
    };
  }

  static isBatchItemAssigned(item: any) {
    const totalItem =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return Number(totalItem) === Number(item.actualRequiredQty);
  }

  static isItemAssigned(item: any) {
    switch (item?.itemName?.advancedTracking) {
      case ADVANCE_TRACKING.NONE:
        return WasteManagementListHelper.isNoneTrackedItemAssigned(item);
      case ADVANCE_TRACKING.SERIAL:
        return WasteManagementListHelper.isSerialItemAssigned(item);
      case ADVANCE_TRACKING.BATCH:
        return WasteManagementListHelper.isBatchItemAssigned(item);
    }
  }

  static getTrackingDetailsSelectionButton = (
    item: any,
    onClick: () => void
  ) => {
    return (
      <DKTooltipWrapper
        content={
          Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()
            ? 'Allocate quantities from target warehouse'
            : 'Allocate quantities from multiple warehouses'
        }
        tooltipClassName="t-10 l-10"
        className=""
        gapFromCursorX={8}
        gapFromCursorY={8}
      >
        <DKIcon
          className="ic-s"
          src={
            WasteManagementListHelper.isItemAssigned(item)
              ? ic_barcode_green
              : ic_barcode_red
          }
          onClick={() => {
            onClick();
          }}
        />
      </DKTooltipWrapper>
    );
  };

  static async loadBatchTrackingData(code: any) {
    try {
      Store.dispatch(fetchAdvancedTrackingData(code));
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  }
}
