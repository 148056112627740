export const COLUMN_CODE_MRP = {
  OPERATIONS: {
    Name: 'name',
    TemplateName: 'qualityInspectionTemplateName',
    Description: 'description',
    Id: 'id'
  },
  WORK_ORDER: {
    Number: 'documentSequenceCode',
    ProductCode: 'productCode',
    Id: 'id',
    ProductName: 'productName'
  }
};

export const WORK_ORDER_OPERATION_TABLE = {
  OPERATION_NAME: 'operationName',
  OPERATION_TIME: 'operationTime',
  OPERATION_COST: 'operationCost',
  OPERATION_OPERATORS_COST: 'operatorCost',
  OPERATION_WORKSTATION_COST: 'workstationCost',
  OPERATION_STATUS: 'operationStatus',
  OPERATION_WAREHOUSE: 'operationWarehouse',
  OPERATION_COMPLETED_QTY: 'operationCompletedQty',
  OPERATION_PLANNED_START_TIME: 'operationPlannedStartTime',
  OPERATION_PLANNED_END_TIME: 'operationPlannedEndTime',
  OPERATION_DESCRIPTION: 'operationDescription',
  OPERATION_YIELD: 'operationYield',
  OPERATION_ACTUAL_YIELD: 'operationActualYield',
  OPERATION_INDEX: 'operationIndex',
  OPERATION_INVALID_FIELDS: 'invalidFields',
  OPERATION_WO_CODE: 'workOrderOperationCode',
  OPERATION_DEPENDENCY: 'operationDependency',
  OPERATION_PROCESS_TYPE: 'processType',
  OPERATION_QC_NEEDED: 'qcNeeded',
  OPERATION_TAGGED_ITEM: 'product',
  LINKEDIN_MACHINES: 'linkedInMachines'
};

export const WORK_ORDER_MACHINE_MASTER = {
  MM_NAME: 'name',
  MM_ASSET_ID: 'assetId',
  MM_ASSET_GROUP: 'assetGroup',
  MM_LOCATION: 'location',
  MM_ASSET_TYPE: 'assetType',
  MM_MODEL: 'model',
  MM_SERIAL_NO: 'serialNumber'
};

export const REQUIRED_ITEM_TABLE = {
  ITEM_NAME: 'itemName',
  SOURCE_WAREHOUSE: 'sourceWarehouse',
  REQUIRED_QTY: 'requiredQty',
  PLANNED_QTY: 'plannedQuantity',
  ACTUAL_REQUIRED_QTY: 'actualRequiredQty',
  PRODUCED_QUANTITY: 'producedQuantity',
  COMPONENT_UNIT_QTY: 'componentProductUnitQty',
  AVAILABLE_QTY: 'availableQty',
  REQ_ITEM_INVALID_FIELDS: 'invalidFields',
  UOM: 'uom',
  BOM_PRODUCT_SUBSTITUTE_DETAILS: 'bomProductSubstitutesDetails',
  WAREHOUSE_INVENTORY_DATA: 'warehouseInventoryData',
  STOCK_UOM: 'stockUom',
  OLD_STOCK_UOM: 'oldStockUOM',
  PRODUCE_PRODUCT_TYPE: 'produceProductType',
  AVAILABLE_QUANTITY_IN_TARGET_WH: 'availableQtyInTargetWH',
  PURCHASE_PRICE: 'purchasePrice',
  UNIT_COST: 'costPerUnit',
  TOTAL_AMOUNT: 'amount',
  WORK_ORDER_CHILD_DETAILS: 'workOrderChildDetails',
  INNER_BOM_PRODUCT_DETAILS: 'innerBomProductDetails'
};

export const OPERATIONS_MASTER_GRID_KEYS = {
  OP_MASTER_NAME: 'name',
  OP_DEFAULT_WS: 'workstationName',
  OP_DESCRIPTION: 'description',
  OP_BATCH_SIZE: 'batchSize',
  OP_COST_PER_HOUR: 'costPerHour',
  OP_FIXED_RATE: 'fixedRate',
  OP_TOTAL_COST: 'totalCost',
  OP_TIME: 'operationTime',
  OP_ID: 'id'
};

export const FORECAST_MASTER_GRID_KEYS = {
  FC_PRODUCT_NAME: 'productName',
  FC_QUANTITY_TYPE: 'quantityType',
  FC_AVAILABLE_QTY: 'availableQuantity',
  FC_REQUIRED_QTY: 'requiredQty',
  FC_ASSOCIATED_WO: 'accosiatedWorkOrders',
  FC_PO_RAISED_DATE: 'poRaisedOn',
  FC_QUANTITY_REQUIRE_TO_BUILD: 'quantityRequiredToBuild',
  FC_PREFERRED_VENDOR_NAME: 'preferredVendorName'
};

export const WORKSTATION_GRID_KEYS = {
  WORKSTATION_NAME: 'workstationName',
  PRODUCTION_CAPACITY: 'productionCapacity',
  WORKSTATION_COST: 'workStationCost',
  DESCRIPTION: 'description'
};

export const WO_MASTER_GRID_KEYS = {
  WO_CONTACT_NAME: 'contactName',
  WO_MASTER_ACTUAL_START_DATE: 'actualStartDate',
  WO_MASTER_ACTUAL_END_DATE: 'actualEndDate',
  WO_MASTER_CODE: 'documentSequenceCode',
  WO_SO_CODE: 'salesOrderSequenceCode',
  WO_CONVERTED_DOC_CODE: 'convertedDocCode',
  WO_PRODUCT_NAME: 'productName',
  WO_PRODUCT_CODE: 'productCode',
  WO_SOURCE_WAREHOUSE: 'sourceWarehouse',
  WO_TARGET_WAREHOUSE: 'targetWarehouse',
  WO_MANUFACTURING_QTY: 'manufactureQuantity',
  WO_ACTUAL_QTY: 'actualQuantity',
  WO_PLANNED_OP_COST: 'plannedOperatingCost',
  WO_ACTUAL_OP_COST: 'actualOperatingCost',
  WO_ADDITIONAL_OP_COST: 'additionalOperatingCost',
  WO_TOTAL_OP_COST: 'totalOperatingCost',
  WO_DELIVERY_DATE: 'deliveryDate',
  WO_ID: 'id',
  WO_CODE: 'workOrderCode',
  WO_STATUS: 'status',
  WO_JB_STATUS: 'allJobCardsCompleted',
  WO_QC_STATUS: 'qcStatus',
  WO_QC_DETAILS: 'qcDetails',
  WO_JB_TOTAL_JOB_CARDS: 'totalJobCards',
  WO_JB_TOTAL_COMPLETED_JOB_CARDS: 'totalCompletedJobCards',
  WO_JC_STATUS: 'allJobCardsCompleted',
  WO_PARENT_WO: 'parentWorkOrderSeqCode',
  WO_WAREHOUSE: 'warehouse',
  WO_ROW: 'row',
  WO_RACK: 'rack',
  WO_BIN: 'bin',
  LAST_UPDATED_BY: 'updatedBy',
  LAST_UPDATED_ON: 'updatedOn',
  INSUFFICIENT_QTY: 'insufficientComponentsQtyFlag',
  WO_MATERIAL_STATUS: 'materialStatus'
};

export const JOB_CARD_GRID_KEYS = {
  ID: 'jobCardCode',
  SALES_ORDER_CODE: 'salesOrderSequenceCode',
  WORK_ORDER_CODE: 'workOrderSequenceCode',
  JOB_CARD_DATE: 'jobCardDate',
  STATUS: 'status',
  WORK_ORDER_ID: 'workOrderCode',
  WORK_ORDER_ITEM_NAME: 'workOrderItemName',
  WORKSTATION_ID: 'workstationId',
  OPERATION_ID: 'operationId',
  OPERATION_COST: 'operationCost',
  OPERATOR_COST: 'operatorCost',
  TOTAL_COMPLETED_QUANTITY: 'totalCompletedQuantity',
  JC_ID: 'id',
  OPERATORS: 'operators',
  CUST_ORDER_NO: 'salesOrderCustomerOrderNumber',
  WO_SOURCE_DETAILS: 'workOrderSourceDetails'
};

export const ASSET_MAINTIANCE_SCHEDHULE_GRID_KEYS = {
  SERIAL_NO: 'serialNumber',
  ASSET_GROUP: 'assetGroup',
  ASSSET_LIFE: 'assetLife',
  WARRANTY_EXP_FROM_DATE: 'warrantyExpFromDate',
  WARRANTY_EXP_END_DATE: 'warrantyExpEndDate',
  VENDOR_NAME: 'vendorName',
  ASSET_ID: 'assetId',
  DESCRIPTION: 'description',
  LOCATION: 'location',
  WAREHOUSE: 'warehouse'
};

export const MACHINE_SCHEDULE_ADHOC_TABLE = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  ACTUAL_START_DATE: 'actualStartDate',
  ACTUAL_END_DATE: 'actualEndDate',
  ASSET_ID: 'assetId',
  SCHEDULE_NAME: 'scheduleName',
  ASSIGNED_TO_NAME: 'assignedToName',
  STATUS_NAME: 'statusName',
  ASSIGNED_TO: 'assignedTo',
  STATUS: 'status',
  ATTACHMENTS: 'attachments',
  MATERIAL_CONSUMPTION: 'materialConsumption',
  RELATED_TRANSACTIONS: 'relatedTransactions',
  MATERIAL_CONSUMPTION_ID: 'workJobId'
  // Material Consumption
} as const;

export const MACHINE_SCHUEDLER = {
  SCHEDULE_NAME: 'scheduleName',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  TOTAL_EVENTS: 'totalEvents',
  EVENT_DURATION: 'eventDuration',
  MAINTAINCE_TYPE: 'maintenanceType'
};

export type MACHINE_SCHEDULE_ADHOC_TABLE_KEYS =
  (typeof MACHINE_SCHEDULE_ADHOC_TABLE)[keyof typeof MACHINE_SCHEDULE_ADHOC_TABLE];

export type MACHINE_SCHUEDLER_KEYS =
  (typeof MACHINE_SCHUEDLER)[keyof typeof MACHINE_SCHUEDLER];

export const MACHINE_MATERIAL_CONSUMPTION = {
  PRODUCT_ID: 'productId',
  DESCRIPTION: 'description',
  PRODUCT_QTY: 'quantity',
  PRODUCT_UOM: 'uomInternal',
  PRODUCT_BASE_QTY: 'uomQuantity',
  UNIT_PRICE: 'unitPrice',
  AMOUNT: 'amount',
  REMARK: 'remark',
  CONSUME: 'consume'
} as const;

export type MACHINE_MATERIAL_CONSUMPTION_KEYS =
  (typeof MACHINE_MATERIAL_CONSUMPTION)[keyof typeof MACHINE_MATERIAL_CONSUMPTION];
