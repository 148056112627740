import http2 from './Interceptor2';
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { SalesOrder } from '../Models/SalesOrder';
import { BulkDeleteResponse } from '../Models/Interfaces';

export interface SalesOrderAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  WithFilter?: any;
  WithoutFilter?: any;
  Sort?: string;
  SortDir?: string;
  SalesPerson?: number;
}

export const defaultConfig: SalesOrderAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  WithFilter: '',
  Sort: 'salesOrderDate',
  SortDir: 'DESC',
  WithoutFilter: '',
  SalesPerson: 0
};

class SalesOrderService {
  static apiConfig: SalesOrderAPIConfig;
  static abortController: any = null;

  static getAllSalesOrder() {
    return http2
      .get(ApiConstants.URL.SALES_ORDER.FETCH_SALES_ORDERS)
      .catch((err: any) => {
        console.error('Error fetching sales orders: ', err);
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http2.delete(ApiConstants.URL.SALES_ORDER.BULK_DELETE, config);
  }

  static deleteSalesOrder(id: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http2
      .delete(`${ApiConstants.URL.SALES_ORDER.DELETE_SALES_ORDER(id, country)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteSalesOrderByCode(code: string) {
    return http2
      .delete(
        `${ApiConstants.URL.SALES_ORDER.DELETE_SALES_ORDER_BY_CODE(code)}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static salesOrderEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = 'invoices/salesorder';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&page=${this.apiConfig.Page}`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static getSalesOrderByPage() {
    Utility.cancelRequest(SalesOrderService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );

    const query: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&query=${this.apiConfig.Query ? this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }${
      this.apiConfig.SalesPerson && this.apiConfig.SalesPerson !== 0
        ? '&salesperson=' + this.apiConfig.SalesPerson
        : ''
    }`;

    SalesOrderService.abortController = Utility.createAbortController();

    return http2
      .get(
        `${ApiConstants.URL.SALES_ORDER.FETCH_SALES_ORDERS}${country}${query}`,
        {
          signal: SalesOrderService.abortController.signal
        }
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createSalesOrder(payload: any) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );
    return http2
      .post(
        `${ApiConstants.URL.SALES_ORDER.CREATE_SALES_ORDERS}${country}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating SO: ', err);
        return Promise.reject(err);
      });
  }

  static updateSalesOrder(payload: SalesOrder, salesOrderId: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );
    return http2
      .put(
        ApiConstants.URL.SALES_ORDER.UPDATE_SALES_ORDERS +
          `${country}` +
          `/${salesOrderId}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating sales order: ', err);
        return Promise.reject(err);
      });
  }

  static getSalesOrderByCode(soCode: string): Promise<any> {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );
    return http2
      .get(
        ApiConstants.URL.SALES_ORDER.FETCH_SALES_ORDER_DETAILS(soCode, country)
      )
      .catch((err: any) => {
        console.error('Error fetching SO details: ', err);
        return Promise.reject(err);
      });
  }

  static getSalesOrderByDocSeqCode(docSeqCode: string): Promise<any> {
    Utility.cancelRequest(SalesOrderService.abortController);
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );
    SalesOrderService.abortController = Utility.createAbortController();
    return http2
      .get(
        ApiConstants.URL.SALES_ORDER.FETCH_SALES_ORDERS +
          `${country}` +
          `/doc-seq-code/${docSeqCode}/details`,
        {
          signal: SalesOrderService.abortController.signal
        }
      )
      .catch((err: any) => {
        console.error('Error fetching SO details: ', err);
        return Promise.reject(err);
      });
  }
  static async sendTriggerOnApproval(payload: any): Promise<any> {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.SALES_ORDER_TRIGGER;
    return http2.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getSOFromProductCode = async (
    productCode: string,
    excludeConveredWOtoSO: boolean
  ) => {
    return http2
      .get(
        ApiConstants.URL.SALES_ORDER.GET_SO_BY_PRODUCT_CODE(
          productCode,
          excludeConveredWOtoSO
        )
      )
      .catch((err: any) => {
        console.error('Error fetching sales orders by product code: ', err);
        return Promise.reject(err);
      });
  };
}
export interface ISalesOrder {
  openingSalesOrder?: null;
  totalAmount?: number;
  totalAmountInBaseCurrency?: number;
  warehouseCode?: null;
  backOrder?: boolean;
  status?: string;
  customField?: null[] | null;
  roundOffAmountInBaseCurrency?: number;
  roundOffAmountInDocumentCurrency?: number;
  linkedQuotationDocuments?: null;
  linkedSalesInvoiceDocuments?: null;
  linkedSalesInvoices?: any[] | null;
  purchaseOrderRefNo?: null;
  currency?: string;
  exchangeRate?: number;
  salesOrderDate?: any;
  salesOrderDueDate?: any;
  memo?: string;
  attachments?: null[] | null;
  unitPriceGstInclusive?: boolean;
  recurring?: boolean;
  recurringActivated?: boolean;
  salesOrderItems?: SalesOrderItemsEntity[] | null;
  shipByDate?: string;
  readonly id?: number;
  salesOrderCode?: string;
  documentSequenceCode?: string;
  sequenceFormat?: string;
  customerOrderNumber?: any;
  contactName?: any;
  contactCode?: any;
  contactDto?: any;
}
export interface SalesOrderItemsEntity {
  id?: number;
  productCode?: string;
  documentSequenceCode?: string;
  productDescription?: string;
  productQuantity?: number;
  uomQuantity?: number;
  uomUnitPrice?: number;
  documentUom?: number;
  documentUOMSchemaDefinition?: null;
  unitPrice?: number;
  discount?: number;
  discountInPercent?: boolean;
  taxCode?: string;
  taxAmount?: number;
  totalAmount?: number;
  productOrder?: null;
  salesOrderItemCode?: string;
  type?: string;
  availableQuantity?: number;
  product?: Product;
  tax?: null;
  customField?: null[] | null;
  linkedQuoteItem?: null;
  reservedQuantitiesData?: null;
  pendingQuantity?: number;
  selectedBom?: any;
}
export interface Product {
  name: string;
  type: string;
  barcode: string;
  description?: null;
  purchasePrice: number;
  salesPrice: number;
  purchaseAccountCode?: null;
  salesAccountCode?: null;
  purchaseReturnAccountCode?: null;
  salesReturnAccountCode?: null;
  manufacturingCostAccountCode?: null;
  purchaseTaxCode?: null;
  salesTaxCode?: null;
  purchasePriceTaxInclusive?: null;
  salesPriceTaxInclusive?: null;
  autoFulfillmentEnabled?: null;
  isFinishedProduct?: null;
  stockUom: number;
  categoryCode?: null;
  categoryDesc?: null;
  taxable: boolean;
  customField?: null[] | null;
  reorderEnabled?: null;
  reorderLevel?: null;
  documentSequenceCode: string;
  sequenceFormat?: null;
  errorMessage?: null;
  advancedTracking: string;
  advancedTrackingMetaData?: null;
  exciseRateIndo?: null;
  isVariant?: null;
  hasVariants?: null;
  masterProductCode?: null;
  attributes?: null;
  variantAttributes?: null;
  multipleUomSchema: boolean;
  uomSchema?: null;
  bomProductCostDetails?: null;
  isAccountNumberFlag?: null;
  isUiProductUpdate: boolean;
  reorderQuantity?: null;
  reorderVendorCode?: null;
  source: string;
  landedCostCategory?: null[] | null;
  barcodeDetails?: null;
  productSubstitutesDetails?: null;
  readonly id?: null;
  productId?: null;
  active?: null;
  deleted?: null;
  variantCount?: null;
  recommendedUom?: null;
  uomSchemaDto?: null;
  incomingQty?: null;
  outgoingQty?: null;
}
export interface IAdditionalCharges {
  additionalChargeAmount: number;
  additionalChargeTaxAmount: number;
  additionalChargesDetails?: any[] | null;
}

export default SalesOrderService;
