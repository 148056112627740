import ApiConstants from '../../Constants/ApiConstants';
import { BulkDeleteResponse } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import http2 from './../Interceptor2';

export interface ForecastAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  Sort?: string;
  SortDir?: string;
}

export const defaultConfigForeCast: ForecastAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  Sort: '',
  SortDir: 'DESC'
};

export default class ForecastService {
  static apiConfig: ForecastAPIConfig;

  static forecastEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfigForeCast;
    }

    let finalEndpoint = 'mrp/master/workstation/search';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=createdOn&sortDir=desc`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static fetchForecastByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfigForeCast;
    }

    const query: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&sort=${this.apiConfig.Sort}&sortDir=${
      this.apiConfig.SortDir
    }&query=${this.apiConfig.Query ? this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    return http2
      .get(`${ApiConstants.URL.MRP.FORECAST.SEARCH}${query}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getForecastById = (id: number) => {
    return http2
      .get(`${ApiConstants.URL.MRP.FORECAST.BY_ID(id)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getForecastDetails = (id: number) => {
    return http2
      .get(`${ApiConstants.URL.MRP.FORECAST.DETAIL_BY_ID(id)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static createForeCast(payload: any) {
    return http2
      .post(`${ApiConstants.URL.MRP.FORECAST.BASE}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static saveForeCastDetail(payload: any) {
    return http2
      .post(`${ApiConstants.URL.MRP.FORECAST.FORECAST_DETAIL}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateForeCast(payload: any, id: number) {
    return http2
      .put(`${ApiConstants.URL.MRP.FORECAST.BY_ID(id)}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http2.delete(ApiConstants.URL.MRP.FORECAST.BASE, config);
  }

  static getMasterForecastDetails = (id: number, filterState?: any) => {
    let queryString = ``;
    if (!Utility.isEmpty(filterState.customField)) {
      queryString += `&customfield=${encodeURI(filterState.customField)}`;
    }
    if (!Utility.isEmpty(filterState.search)) {
      queryString += `&search=${filterState.search}`;
    }
    if (filterState.isNetShortFall === true) {
      queryString += `&isNetShortFall=${filterState.isNetShortFall}`;
    }
    return http2
      .get(
        `${ApiConstants.URL.MRP.FORECAST.MASTER_FORECAST_DETAIL_BY_ID(
          id
        )}${queryString}`
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static downloadForecastSampleFile = (query: string) => {
    return http2.get(
      `${ApiConstants.URL.MRP.FORECAST.MASTER_FORECAST_DOWNLOAD_SAMPLE}?${query}`,
      {
        responseType: 'blob'
      }
    );
  };

  static fetchForecastByPageEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfigForeCast;
    }

    const query: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&sort=${this.apiConfig.Sort}&sortDir=${
      this.apiConfig.SortDir
    }&query=${this.apiConfig.Query ? this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndPoint = `${ApiConstants.URL.MRP.FORECAST.SEARCH_ENDPOINT}${query}`;
    return finalEndPoint;
  }
}
