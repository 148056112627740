import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKLine,
  DKTooltipWrapper
} from 'deskera-ui-library';
import { useEffect, useRef } from 'react';

export enum BOM_EXPLOSION_ALLOCATION_TYPE {
  ONLY_SUBSTITUTE = 'ONLY_SUBSTITUTE',
  ONLY_ORIGINAL_PRODUCT = 'ONLY_ORIGINAL_PRODUCT',
  DEFAULT_ALLOCATION = 'DEFAULT_ALLOCATION'
}
export default function BomExplosionAllocationConfirmmation(props: any) {
  const popupRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener('mousedown', detectOutsideClick);
    return () => {
      document.removeEventListener('mousedown', detectOutsideClick);
    };
  }, []);

  const detectOutsideClick = (event: MouseEvent) => {
    if (!popupRef.current?.contains(event.target as Node)) {
      props.onClose();
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-s p-v-s border-b-2">
        <div className="row">
          <DKLabel text={'Allocation Method'} className="fw-m fs-l" />
        </div>
        <DKIcon
          src={DKIcons.ic_close_}
          style={{ width: 13, height: 13 }}
          className="mr-1 p-1 cursor-hand"
          onClick={props.onClose}
        />
      </div>
    );
  };
  return (
    <div
      ref={popupRef}
      className="column border-m border-radius-m bg-white z-index-2 shadow"
      style={{
        width: 300,
        height: 255
      }}
    >
      {getHeader()}
      <div className="column parent-width gap-2">
        {props?.substituteAdded && (
          <DKButton
            className="text-blue p-0 mt-2"
            title={`Allocate qty only to substitutes`}
            onClick={() => {
              props?.onSelect(BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_SUBSTITUTE);
            }}
          />
        )}
        {props?.substituteAdded && <DKLine />}
        <DKTooltipWrapper
          content={
            props.onlySubstitutesVisible
              ? 'Option available when all product rows are visible, please disable substitutes only toggle first.'
              : ''
          }
        >
          <DKButton
            className={`text-blue p-0 ${props.substituteAdded ? '' : 'mt-2'}`}
            title={`Allocate qty only to original product`}
            disabled={props.onlySubstitutesVisible}
            onClick={() => {
              props?.onSelect(
                BOM_EXPLOSION_ALLOCATION_TYPE.ONLY_ORIGINAL_PRODUCT
              );
            }}
          />
        </DKTooltipWrapper>
        <DKLine />
        <DKTooltipWrapper
          content={
            props.onlySubstitutesVisible
              ? 'Option available when all product rows are visible, please disable substitutes only toggle first.'
              : ''
          }
        >
          <DKButton
            className="text-blue p-0"
            title={`Default Allocation`}
            disabled={props.onlySubstitutesVisible}
            onClick={() => {
              props?.onSelect(BOM_EXPLOSION_ALLOCATION_TYPE.DEFAULT_ALLOCATION);
            }}
          />
        </DKTooltipWrapper>
        <DKLine />
        <DKLabel
          className="mt-2 p-h-m opacity-80"
          text={
            '*Default Allocation - the system will first try to allocate qty to the original product and if that is not available in sufficient qty, it will allocate substitutes'
          }
        />
      </div>
    </div>
  );
}
