import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import ProductService from '../../Services/Product';
import Utility, { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const productsInitialState = {
  ...initialState,
  productsWithVariants: {} as any,
  productWarehouses: [],
  productWarehousesWithoutRole: [],
  productWarehousesWithRejectedWh: []
};

export const fetchProducts = createAsyncThunk('products', async () => {
  const response = await ProductService.getProductsByPage(
    Utility.getCountryApiExtension(),
    false
  );
  return response;
});

export const fetchProductsWithVariants = createAsyncThunk(
  'productsWithVariants', // without master product of variants
  async ({ searchTerm, query, params }: any) => {
    try {
      const response = await ProductService.getProductsWithVariants(
        searchTerm,
        query,
        params
      );
      return response;
    } catch (err) {
      console.error('Error loading products and variants: ', err);
    }
  }
);

export const fetchProductSummary = createAsyncThunk(
  'productSummary',
  async () => {
    const response = await ProductService.getSummary();
    return response;
  }
);

export const fetchAttributeList = createAsyncThunk(
  'attributeMasterData',
  async () => {
    const response = await ProductService.getAttributes();
    return response;
  }
);

export const deleteProduct = createAsyncThunk(
  'deleteProducts',
  async (productIds: string[]) => {
    const response = await ProductService.deleteProduct(productIds);
    return response;
  }
);

/** @deprecated */
export const fetchProductWarehouses = createAsyncThunk(
  'fetchProductWarehouses',
  async () => {
    const response = await ProductService.getWarehousesProductsData();
    return response;
  }
);
/** @deprecated */
export const fetchProductWarehousesWithRejectedWh = createAsyncThunk(
  'fetchProductWarehousesWithRejectedWh',
  async () => {
    const response =
      await ProductService.getWarehousesProductsDataWithRejectedWh();
    return response;
  }
);
/** @deprecated */
export const fetchProductWarehousesWithoutRole = createAsyncThunk(
  'fetchProductWarehousesWithoutRole',
  async () => {
    const response =
      await ProductService.getWarehousesProductsDataWithoutRole();
    return response;
  }
);

export const ProductsSlice = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    addProductColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          id: config.id ? config.id : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.PRODUCT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToProductColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },

    addProducts: (state, action: PayloadAction<any>) => {
      let currentProducts = { ...state.data };
      if (currentProducts.content) {
        currentProducts.content.push(action.payload);
      } else {
        currentProducts.content = [action.payload];
      }
      state.data = currentProducts;
    },
    updateProductDetails: (state, action: PayloadAction<any>) => {
      const productDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const productIndex: number = content.findIndex(
        (product: any) => product.id === productDetails.id
      );
      if (productIndex >= 0) {
        content[productIndex] = productDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchProductsWithVariants.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductsWithVariants.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.productsWithVariants = action.payload;
      })
      .addCase(fetchProductSummary.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductSummary.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.summary = action.payload;
      })
      .addCase(fetchAttributeList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAttributeList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.attributes = action.payload;
      })
      .addCase(fetchProductWarehouses.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.productWarehouses = action.payload;
      })
      .addCase(fetchProductWarehousesWithoutRole.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.productWarehousesWithoutRole = action.payload;
      })
      .addCase(
        fetchProductWarehousesWithRejectedWh.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          state.productWarehousesWithRejectedWh = action.payload;
        }
      );
  }
});

export const {
  addColumnToProductColumnConfig,
  addProductColumnConfig,
  addProducts,
  updateColumnConfig,
  updateProductDetails
} = ProductsSlice.actions;

export const selectProducts = (state: RootState) => state.products.data;
export const selectProductsWithVariants = (state: RootState) =>
  state.products.productsWithVariants;

export const selectProductSummary = (state: RootState) =>
  state.products.summary;

export const selectAttributes = (state: RootState) => state.products.attributes;

export const selectProductsLoadingStatus = (state: RootState) =>
  state.products.status;

export const selectProductsColumnConfig = (state: RootState) =>
  state.products.columnConfig;

export const selectProductsColumnConfigTableId = (state: RootState) =>
  state.products.configTableId;

/** @deprecated  */
export const selectedProductWarehouse = (state: RootState) =>
  state.products.productWarehouses;
/** @deprecated */
export const selectedProductWarehouseWithRejectedWh = (state: RootState) =>
  state.products.productWarehousesWithRejectedWh;
/** @deprecated */
export const selectedProductWarehouseWithoutRole = (state: RootState) =>
  state.products.productWarehousesWithoutRole;

export default ProductsSlice.reducer;
