import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"

export default class EWayBillParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        templateDataToReturn.ewayBill = dataFromAPI
        if(templateDataToReturn.ewayBill.eWayBillDate === undefined) {
            templateDataToReturn.ewayBill.eWayBillDate = templateDataToReturn.ewayBill.ewayBillDate
        }
        if (templateDataToReturn.ewayBill.eWayBillNo === undefined) {
            templateDataToReturn.ewayBill.eWayBillNo = templateDataToReturn.ewayBill.ewayBillNo
        }

        if (dataFromAPI.shipFrom !== undefined) {
            var fromAddressObj = new Address(undefined, dataFromAPI.shipFrom, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.fromObj = fromAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)
            templateDataToReturn.ewayBill.placeOfDispatch = this.convertNextLineToColon(templateDataToReturn.from)
            templateDataToReturn.ewayBill.supplierPostalCode = templateDataToReturn.fromObj.postalCode
            templateDataToReturn.ewayBill.vehicleFrom = dataFromAPI.shipFrom.city

        }

        if (dataFromAPI.shipTo !== undefined) {
            var clientShipToAddressObj = new Address(undefined, dataFromAPI.shipTo, undefined, undefined, templateDataToReturn.ewayBill.contactName, undefined, OwnerType.contact)
            templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
            templateDataToReturn.ewayBill.placeOfDelivery = this.convertNextLineToColon(templateDataToReturn.clientShipToAddress)
            templateDataToReturn.ewayBill.recipientPostalCode = templateDataToReturn.clientShipToAddressObj.postalCode
        }

        templateDataToReturn.ewayBill.distanceInKm = templateDataToReturn.ewayBill.distanceInKm + 'KM'

        templateDataToReturn.ewayBill.transporter = ''
        if (templateDataToReturn.ewayBill.tenantName !== undefined) {
            templateDataToReturn.ewayBill.transporter = templateDataToReturn.ewayBill.tenantName
        }

        var transportVehicleDocAndDate = ''
        if (templateDataToReturn.ewayBill.vehicleNo !== undefined) {
            templateDataToReturn.ewayBill.transportVehicleNoDocData = templateDataToReturn.ewayBill.vehicleNo
        }
        if (templateDataToReturn.ewayBill.transporterDocNo !== undefined
                && templateDataToReturn.ewayBill.transporterDocNo !== null
                && templateDataToReturn.ewayBill.transporterDocNo !== '') {
            templateDataToReturn.ewayBill.transportVehicleNoDocData = templateDataToReturn.ewayBill.transportVehicleNoDocData + ' & ' + templateDataToReturn.ewayBill.transporterDocNo
            transportVehicleDocAndDate = templateDataToReturn.ewayBill.transporterDocNo
        }
        if (templateDataToReturn.ewayBill.transporterDocDate !== undefined
            && templateDataToReturn.ewayBill.transporterDocDate !== null
            && templateDataToReturn.ewayBill.transporterDocDate !== '') {
            templateDataToReturn.ewayBill.transportVehicleNoDocData = templateDataToReturn.ewayBill.transportVehicleNoDocData + ' & ' + templateDataToReturn.ewayBill.transporterDocDate
            transportVehicleDocAndDate = transportVehicleDocAndDate + ' & ' + templateDataToReturn.ewayBill.transporterDocDate
        }

        //validUpto 16/04/2022 11:59 PM (Vehicle Type is REGULAR)
        if (templateDataToReturn.ewayBill.validTill !== undefined
            && templateDataToReturn.ewayBill.validTill !== null
            && templateDataToReturn.ewayBill.validTill !== '') {
            templateDataToReturn.ewayBill.validUpto = templateDataToReturn.ewayBill.validTill + ' (Vehicle Type is ' + templateDataToReturn.ewayBill.transactionType + ')'
        }

        if (templateDataToReturn.ewayBill.documentNo !== undefined
            && templateDataToReturn.ewayBill.documentNo !== null
            && templateDataToReturn.ewayBill.documentNo !== '') {
            templateDataToReturn.ewayBill.documentDetails = 'Tax Invoice - ' + templateDataToReturn.ewayBill.documentNo + ' - ' + templateDataToReturn.ewayBill.documentDate
        }

        //transporter details
        var transporterIdAndName = ''
        if (templateDataToReturn.ewayBill.transporterId !== undefined
            && templateDataToReturn.ewayBill.transporterId !== null
            && templateDataToReturn.ewayBill.transporterId !== '') {
            transporterIdAndName = templateDataToReturn.ewayBill.transporterId
        }

        if (templateDataToReturn.ewayBill.transporterName !== undefined
            && templateDataToReturn.ewayBill.transporterName !== null
            && templateDataToReturn.ewayBill.transporterName !== '') {
                if(transporterIdAndName === '') {
                    transporterIdAndName = templateDataToReturn.ewayBill.transporterName
                }
                else {
                    transporterIdAndName = transporterIdAndName + ' & ' + templateDataToReturn.ewayBill.transporterName
                }
        }

        templateDataToReturn.ewayBill.transporterIdAndName = transporterIdAndName

        templateDataToReturn.ewayBill.transportVehicleDocAndDate = transportVehicleDocAndDate

        templateDataToReturn.ewayBill.showFrom = true
        templateDataToReturn.ewayBill.showCompanyLogo = true
        templateDataToReturn.ewayBill.showClientShipToAddress = true

        return templateDataToReturn
    }

    static convertNextLineToColon(addressString) {
        var newString = ''
        if(addressString !== undefined && addressString !== null) {
            var newStringArr = []
            var add = addressString.split('<br>')
            add.forEach((addLine, index) => {
                if (index + 2 >= add.length) {
                    newStringArr.push(addLine)
                }
                else {
                    newStringArr.push(addLine + ', ' )
                }
            });
            newString = newStringArr.join('')
        }

        return newString
    }
}
