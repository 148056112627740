import { Document } from './Document';

export interface CreateAndEditNotes extends Document {}

export const initialNoteState = {
    calendarView: false,
    currentDate: new Date(),
    exchangeRate: 0,
    currentCurrency: "",
    lineItemsData: {
        items: []
    },
    memoText: "",
    autoNumberingCode: "",
    sequenceFormat: "",
    lineSummary: {},
    linkedDocuments: [],
    salesInvoiceCode: "",
    salesReturnCode: "",
    purchaseInvoiceCode: "",
    purchaseReturnCode: "",
    billTo: null,
    shipTo: null,
    shipFrom: null,
    salesInvoice: null,
    salesReturn: null,
    reason: null,
    placeOfSupply: null,
    einvoiceInfoIndia: null,
    einvoiceInfoIndiaCancel: null,
    isCancelEinvoice: null,
}

export const NOTE_TYPE = {
    DEBIT_NOTE: "DEBIT_NOTE",
    CREDIT_NOTE: "CREDIT_NOTE",
}