import { useEffect, useRef, useState } from 'react';
import {
  DKButton,
  DKIcons,
  DKListPicker2,
  DKIcon,
  showToast,
  TOAST_TYPE,
  showAlert,
  showLoader,
  removeLoader,
  DKLabel
} from 'deskera-ui-library';
import {
  BUY_TYPE,
  DOCUMENT_STATUS,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  JWO_BILLED_STATUS,
  JWO_DISPATCH_STATUS,
  JWO_RECEIPT_STATUS,
  LABELS,
  MRP_APP_NAME,
  OPENING_DOCUMENT_LABEL,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCE_PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  TEMPLATE_CATEGORY
} from '../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  selectIsLoadingPopupWrapper,
  setFooterAction
} from '../../Redux/Slices/CommonDataSlice';
import SellConfig, { SELL_CONFIG_BTN } from '../../Configs/SellConfig';
import BuyConfig, { BUY_CONFIG_BTN } from '../../Configs/BuyConfig';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  getUpdatedInvoiceObject,
  invoiceEmailTemplateConfig
} from '../../Components/Invoices/InvoiceHelper';
import {
  billEmailTemplateConfig,
  getUpdatedBillObject
} from '../../Components/Bills/BillHelper';
import {
  getUpdatedQuoteObject,
  quoteEmailTemplateConfig
} from '../../Components/Quotations/QuoteHelper';
import {
  getUpdatedPurchaseOrderObject,
  orderEmailTemplateConfig
} from '../../Components/PurchaseOrders/PurchaseOrderHelper';
import {
  getUpdatedRequisitonOrderObject,
  reqEmailTemplateConfig
} from '../../Components/Requisitions/RequisitionHelper';
import {
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import PopupWrapper from '../PopupWrapper';
import Payment from '../PaymentPopup/Payment';
import PaymentRecords from '../PaymentRecords/PaymentRecords';
import {
  SelectEmailTriggerIdsData,
  setCreateWOFromSalesInvoice,
  setCreateWOFromSalesOrder,
  setEmailTriggerIds
} from '../../Redux/Slices/CommonDataSlice';
import {
  createBlankDraft,
  draftTableId,
  removeDraft,
  selectDraftsColumnConfig,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import InvoiceService from '../../Services/Invoice';
import { DraftTypes } from '../../Models/Drafts';
import BillService from '../../Services/Bill';
import FulfillmentConfirmation from '../FulfillmentPopup/FulfillmentConfirmationPopup';
import Fulfillment from '../FulfillmentPopup/Fulfillment';
import DropshipWarning from '../FulfillmentPopup/DropshipWarningPopup';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import ReceivedGoods from '../ReceivedGoodsPopup/ReceivedGoods';
import QuotationService from '../../Services/Quotation';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import RequisitionService from '../../Services/Requisition';

import {
  getEmailPopup,
  getPrintPreview,
  getRowIdByModule,
  isSalesOrderVisible
} from './NewDocumentHelper';

import QuoteToInvoicePopup from '../../Components/Quotations/QuoteToInvoicePopup';
import { billInitialState } from '../../Models/Bill';
import { PurchaseOrder as PurchaseOrderModel } from '../../Models/Order';

import PurchaseOrderToBillPopup from '../../Components/PurchaseOrders/PurchaseOrderToBillPopup';
import RequisitionToPurchaseOrderPopup from '../../Components/Requisitions/RequisitionToPurchasePopup';
import {
  getUpdatedSalesOrderObject,
  salesOrderEmailTemplateConfig
} from '../../Components/SalesOrders/SalesOrderHelper';
import SalesOrderToInvoicePopup from '../../Components/SalesOrders/SalesOrderToInvoice';
import {
  activeTenantInfo,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import ContactService from '../../Services/Contact';
import { localizedText } from '../../Services/Localization/Localization';
import ProductService from '../../Services/Product';
import SalesOrderService from '../../Services/SalesOrder';
import { selectPermissionsByModule } from '../../Redux/Slices/PermissionsSlice';
import {
  PERMISSIONS_BY_MODULE,
  PERMISSION_MODULE_NAME
} from '../../Constants/Permission';
import { Store } from '../../Redux/Store';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import QuoteToSalesOrderPopup from '../../Components/Quotations/QuoteToSalesOrderPopup';
import AppManager from '../../Managers/AppManager';
import OrderService from '../../Services/PurchaseOrder';
import AddStockTransferPopup from '../../Components/StockManagement/StockTransfer/AddStockTransfer';
import { WorkOut } from '../../Models/Workout';
import WorkoutService from '../../Services/Workout';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import ReceivedGoodsForAsset from '../ReceivedGoodsPopup/ReceiveGoodsForAsset';
import { useHistory } from 'react-router-dom';
import { getJobWorkOutObject } from '../../Components/Mrp/Workout/WorkOutHelper';
import ApiConstants from '../../Constants/ApiConstants';
import {
  WorkOrderHelper,
  validateSelectedInvoiceForInterLinkedWO,
  validateSelectedSOForInterLinkedWO
} from '../../Components/Mrp/WorkOrder/WorkOrderHelper';
import { fetchContacts } from '../../Redux/Slices/ContactsSlice';
import { COUNTRIES_WITH_CURRENCIES } from '../../Components/PriceBook/CountriesWithCurrencies';
import { selectDimensions } from '../../Redux/Slices/LocationSlice';
import DispatchJWOProduct from '../../Components/StockManagement/StockTransfer/DispatchJWOProduct';
import DropshipConfirmation from '../FulfillmentPopup/DropshipConfirmationPopup';
import { selectBillCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';

export enum ActionMenuEvent {
  EMAIL = 'EMAIL',
  PRINT = 'PRINT',
  GET_PAID = 'GET_PAID',
  FULFILL = 'FULFILL',
  PAY = 'PAY',
  RECEIVE_GOODS = 'RECEIVE_GOODS',
  OFFSET_PAYMENT = 'OFFSET_PAYMENT',
  SET_RECURRING = 'SET_RECURRING',
  PAYMENT_RECORDS = 'PAYMENT_RECORDS',
  FULLFILLMENT_RECORDS = 'FULLFILLMENT_RECORDS',
  RECEIVE_GOODS_RECORDS = 'RECEIVE_GOODS_RECORDS'
}

interface DocumentActionMenuProps {
  booksDocument: any;
  draftId?: any;
  position?: string;
  draftType?: string;
  className?: string;
  updateWithoutClosing?: () => void;
  closeMenuContainer?: () => void;
  auditLogView?: boolean;
}

export default function DocumentActionMenu(props: DocumentActionMenuProps) {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const dimensionData = useAppSelector(selectDimensions);
  const [stockTransferItems, setStockTransferItems] = useState<any>({});
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [booksDocument, setBooksDocument] = useState<any>(null);

  // For Payments
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [showPaymentRecord, setShowPaymentRecord] = useState<boolean>(false);
  const billFormat = useAppSelector(selectBillCustomNumbersFormats);

  // For Conversion
  const [
    showQuoteToInvoiceConversionPopup,
    setShowQuoteToInvoiceConversionPopup
  ] = useState<boolean>(false);
  const [showQuoteToSOConversionPopup, setShowQuoteToSOConversionPopup] =
    useState<boolean>(false);

  const [showSOConversionPopup, setShowSOConversionPopup] =
    useState<boolean>(false);
  const [showPOConversionPopup, setShowPOConversionPopup] =
    useState<boolean>(false);

  const [showReqConversionPopup, setShowReqConversionPopup] =
    useState<boolean>(false);

  // For Fulfillment
  const [
    showFulfillmentConfirmationPopup,
    setShowFulfillmentConfirmationPopup
  ] = useState<boolean>(false);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [showDropShipWarningPopup, setShowDropShipWarningPopup] =
    useState<boolean>(false);
  const [showReceivedGoodsPopup, setReceivedGoodsPopup] =
    useState<boolean>(false);
  const [showReceivedGoodsAssetPopup, setReceivedGoodsAssetPopup] =
    useState<boolean>(false);
  const [showStockTransferPopup, setShowStockTransferPopup] =
    useState<boolean>(false);
  const [showDropshipConfirmationPopup, setShowDropshipConfirmationPopup] =
    useState<boolean>(false);
  const [isFulfillmentForDropship, setisFulfillmentForDropship] =
    useState<boolean>(false);

  //for WO
  const [modulePermissions, setModulePermissions] = useState<any>({});
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addDocRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const history = useHistory();

  const dispatch = useAppDispatch();
  const paymentPopupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const tableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const emailTriggerIds = useAppSelector(SelectEmailTriggerIdsData);
  const [showDispatchJWOProduct, setShowDispatchJWOProduct] =
    useState<boolean>(false);

  const paymentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title:
        props.booksDocument.documentType === DOC_TYPE.INVOICE
          ? t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.RECEIVE`)
          : t(`BILLS.MAKE_PAYMENT.BUTTON.PAY`),
      class: 'bg-app text-white mr-ss',
      clickAction:
        props.booksDocument.documentType === DOC_TYPE.INVOICE
          ? POPUP_CLICK_TYPE.RECEIVE_PAYMENT
          : POPUP_CLICK_TYPE.BILL_PAYMENT
    }
  ];

  const loadingPaymentPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title:
        props.booksDocument.documentType === DOC_TYPE.INVOICE
          ? 'Receiving'
          : 'Paying',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const dropShipButtonConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.NO`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.YES`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CONFIRM_DROPSHIP
    }
  ];

  const receivedGoodsPopupBtnConfig: BtnType[] = [
    {
      title: t(`BILLS.BULK_RECEIVE_GOODS_OF_BILL.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`BILLS.BULK_RECEIVE_GOODS_OF_BILL.BUTTON.RECEIVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.RECEIVED_GOODS
    }
  ];

  const convertPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const setPopupTitle = (docType: DOC_TYPE) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return 'Invoice';
      case DOC_TYPE.SALES_ORDER:
        return 'Sales Order';
      case DOC_TYPE.QUOTE:
        return Utility.isUSorg() ? 'Estimate' : 'Quotation';
      case DOC_TYPE.BILL:
        return 'Bill';
      case DOC_TYPE.ORDER:
        return 'Purchase Order';
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        return 'Job Work Out';
      case DOC_TYPE.REQUISITION:
        return 'Requisition';
      default:
        break;
    }
  };

  const getDirectFulfillmentTitle = () => {
    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        return localizedText('Direct Fulfillment of Invoice');
      case DOC_TYPE.SALES_ORDER:
        return localizedText('Direct Fulfillment of Sales Order');
      case DOC_TYPE.QUOTE:
        return localizedText('Direct Fulfillment of Quotation');
      default:
        return '';
    }
  };

  const updateDocument = (doc: any, paymentUpdated: boolean) => {
    let draftType;

    if (paymentUpdated) {
      if (doc.knockoffInfo) {
        if (doc.knockoffInfo.length) {
          draftType = DraftTypes.READONLY;
        } else {
          draftType = DraftTypes.UPDATE;
        }
      } else {
        draftType = DraftTypes.UPDATE;
      }
    } else {
      if (
        doc.documentType === DOC_TYPE.INVOICE ||
        doc.documentType === DOC_TYPE.QUOTE ||
        doc.documentType === DOC_TYPE.SALES_ORDER ||
        doc.documentType === DOC_TYPE.REQUISITION ||
        doc.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (doc.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.BILL) {
        if (doc.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }

      if (doc.documentType === DOC_TYPE.ORDER) {
        if (doc.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) {
          draftType = DraftTypes.UPDATE;
        } else {
          draftType = DraftTypes.READONLY;
        }
      }
    }

    dispatch(
      updatePopulateFormData({
        id: props.draftId,
        formdata: doc,
        draftType: draftType,
        actionFromDocument: true
      })
    );
  };

  const closeAllPopups = () => {
    setShowFulfillmentPopup(false);
    setShowFulfillmentConfirmationPopup(false);
    setShowPaymentPopup(false);
    setShowPaymentRecord(false);
    setReceivedGoodsPopup(false);
    setReceivedGoodsAssetPopup(false);

    if (showFulfillmentConfirmationPopup) {
      dispatch(removeDraft(props.draftId));
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
    }

    if (
      showPaymentPopup ||
      showFulfillmentPopup ||
      showReceivedGoodsPopup ||
      showReceivedGoodsAssetPopup
    ) {
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const paymentUpdated = showPaymentPopup || showPaymentRecord;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        if (props.booksDocument.documentType === DOC_TYPE.INVOICE) {
          InvoiceService.getInvoiceByCode(
            props.booksDocument.salesInvoiceCode
          ).then(
            (invoice: any) => {
              let invoiceDetailsData: any = getUpdatedInvoiceObject(invoice);
              updateDocument(invoiceDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading updated invoice: ', err);
            }
          );
        }
        if (props.booksDocument.documentType === DOC_TYPE.BILL) {
          BillService.fetchBillDetails(
            props.booksDocument.purchaseInvoiceCode
          ).then(
            (bill: any) => {
              const billDetailsData: any = getUpdatedBillObject(bill);
              updateDocument(billDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading updated bill: ', err);
            }
          );
        }
        if (props.booksDocument.documentType === DOC_TYPE.QUOTE) {
          QuotationService.getQuoteByCode(
            props.booksDocument.quotationCode
          ).then(
            (data: any) => {
              const quoteDetailsData: any = getUpdatedQuoteObject(data);
              updateDocument(quoteDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading updated quotation: ', err);
            }
          );
        }
        if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
          PurchaseOrderService.fetchOrderDetails(
            props.booksDocument.poCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedPurchaseOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading Purchase order: ', err);
            }
          );
        }
        if (props.booksDocument.documentType === DOC_TYPE.REQUISITION) {
          RequisitionService.fetchOrderDetails(
            props.booksDocument.purchaseRequestCode
          ).then(
            (data: any) => {
              const poDetailsData: any = getUpdatedRequisitonOrderObject(data);
              updateDocument(poDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading requisition order: ', err);
            }
          );
        }

        if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
          SalesOrderService.getSalesOrderByCode(
            props.booksDocument.salesOrderCode
          ).then(
            (data: any) => {
              const salesOrderDetailsData: any =
                getUpdatedSalesOrderObject(data);
              updateDocument(salesOrderDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading updated order: ', err);
            }
          );
        }

        if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          WorkoutService.fetchJobWorkOutDetails(
            props.booksDocument.jwoCode
          ).then(
            (data: any) => {
              const jobWorkOutDetailsData: any = getJobWorkOutObject(data);
              updateDocument(jobWorkOutDetailsData, paymentUpdated);
              closeAllPopups();
            },
            (err) => {
              console.error('Error loading updated Job Work Out: ', err);
            }
          );
        }

        break;
      case POPUP_CALLBACKS_TYPE.RECEIVE_PAYMENT:
        addDocRef.current.storeCallbacksRef.receivePayment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.BILL_PAYMENT:
        addDocRef.current.storeCallbacksRef.billPayment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addDocRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.DROPSHIP_FULFILLMENT:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropShipWarningPopup(true);
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT_FOR_DROPSHIP:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(true);
        break;

      case POPUP_CALLBACKS_TYPE.PICK_PACK_SHIP:
        setShowFulfillmentConfirmationPopup(false);
        //RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP);
        break;
      case POPUP_CALLBACKS_TYPE.RECEIVED_GOODS:
        addDocRef.current.storeCallbacksRef.receivedGoods = passingData.data;
        break;
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPaymentPopup(false);
        setShowPaymentRecord(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentPopup(false);
        setShowDropShipWarningPopup(false);
        setReceivedGoodsPopup(false);
        setShowQuoteToInvoiceConversionPopup(false);
        setShowQuoteToSOConversionPopup(false);
        setShowPOConversionPopup(false);
        setShowSOConversionPopup(false);
        setShowReqConversionPopup(false);
        setReceivedGoodsAssetPopup(false);
        if (props.closeMenuContainer) {
          props.closeMenuContainer();
        }
        break;
      case POPUP_CLICK_TYPE.RECEIVE_PAYMENT:
        addDocRef.current?.storeCallbacksRef.receivePayment();
        break;
      case POPUP_CLICK_TYPE.BILL_PAYMENT:
        addDocRef.current?.storeCallbacksRef.billPayment();
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addDocRef.current?.storeCallbacksRef.fulfillment();
        break;
      case POPUP_CLICK_TYPE.RECEIVED_GOODS:
        addDocRef.current?.storeCallbacksRef.receivedGoods();
        break;
    }
  };

  const paymentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`Close`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    //permissions
    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        setModulePermissions(PERMISSIONS_BY_MODULE.INVOICE);
        break;
      case DOC_TYPE.QUOTE:
        setModulePermissions(PERMISSIONS_BY_MODULE.QUOTATION);
        break;
      case DOC_TYPE.BILL:
        setModulePermissions(PERMISSIONS_BY_MODULE.BILL);
        break;
      case DOC_TYPE.ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.PURCHASE_ORDER);
        break;
      case DOC_TYPE.SALES_ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.SALES_ORDER);
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        setModulePermissions(PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION);
        break;
      case DOC_TYPE.REQUISITION:
        setModulePermissions(PERMISSIONS_BY_MODULE.REQUISITION);
        break;
    }
  }, []);

  useEffect(() => {
    if (
      !Utility.isEmpty(props.booksDocument) &&
      JSON.stringify(props.booksDocument) !== JSON.stringify(booksDocument)
    ) {
      setBooksDocument(props.booksDocument);
    }
  }, [props.booksDocument]);

  useEffect(() => {
    if (!Utility.isEmpty(modulePermissions)) {
      populateMenu();
    }
  }, [booksDocument, modulePermissions]);

  const populateMenu = () => {
    let menuBtns: any[] = getCommonActionBtns();
    if (
      props.booksDocument.documentType === DOC_TYPE.INVOICE ||
      props.booksDocument.documentType === DOC_TYPE.QUOTE ||
      props.booksDocument.documentType === DOC_TYPE.SALES_ORDER
    ) {
      menuBtns = [...menuBtns, ...getSellActionBtns()];
    }

    if (
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.REQUISITION
    ) {
      menuBtns = [...menuBtns, ...getBuyActionBtns()];
    }

    if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      menuBtns = [...getJobWorkoutActionBtns(props.booksDocument)];
    }

    // Conditions to show create workorder button
    // for sales order
    if (
      Utility.isMRP() &&
      props.booksDocument.documentType === DOC_TYPE.SALES_ORDER
    ) {
      const atLeastOneLinkedDocIsWO =
        props.booksDocument?.linkedWorkOrderDocuments?.some(
          (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
        );
      const soHasRemainingQty = WorkOrderHelper.soHasRemainingQtyToBuild(
        props.booksDocument
      );
      if (!atLeastOneLinkedDocIsWO || soHasRemainingQty) {
        menuBtns = [
          ...menuBtns,
          ...getWorkOrderActionBtns(props.booksDocument)
        ];
      }
    }
    // for sales invoice
    if (
      Utility.isMRP() &&
      props.booksDocument.documentType === DOC_TYPE.INVOICE
    ) {
      const atLeastOneLinkedDocIsWO =
        props.booksDocument?.linkedDocuments?.some(
          (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
        );
      if (!atLeastOneLinkedDocIsWO) {
        menuBtns = [
          ...menuBtns,
          ...getWorkOrderActionBtns(props.booksDocument)
        ];
      }
    }

    if (checkIsFa()) {
      menuBtns = menuBtns.filter(
        (btn: any) => btn.title === 'Pay' || btn.title === 'Receive Goods'
      );
    }
    setMenuItems(menuBtns);
  };

  const convertPOToBill = () => {
    const order = props.booksDocument;
    if (
      order.dropShip ||
      (order.receiveGoodsStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
        !order.hasPartialBill)
    ) {
      convertToBill(order);
    } else {
      setShowPOConversionPopup(true);
    }
  };

  const convertToBill = (po: PurchaseOrderModel) => {
    let selectedOrder = {
      ...po,
      entityId: po.id ? po.id : undefined,
      documentType: DOC_TYPE.ORDER,
      documentCode: po.poCode,
      vendorCode: Utility.getValue(po.vendorCode, po.contactCode),
      buyType: Utility.getValue(po.buyType, po.orderType),
      orderType: po.buyType,
      status: !po.id ? DOCUMENT_STATUS.OPEN : po.status,
      placeOfSupply: po.placeOfSupply,
      reorder: po.reorder || false,
      dropShip: po.dropShip ? po.dropShip : false,
      backOrder: po.backOrder ? po.backOrder : false,
      linkedPurchaseInvoices: po.linkedPurchaseInvoices,
      orderDueDate: Utility.getValue(po.dueDate, po.validTillDate),
      fulfillmentDate: Utility.getValue(po.fulfillmentDate, po.receiveByDate),
      validTillDate: po.dueDate,
      receiveGoodsStatus:
        po.receiveGoodsStatus ||
        po.receiptStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      currency: Utility.getValue(po.currency, po.currencyCode),
      items: po.purchaseOrderItems?.map((item) => {
        return {
          ...item,
          documentItemCode: Utility.getValue(
            item.documentItemCode,
            item.purchaseOrderItemCode,
            item.purchaseInvoiceItemCode
          )
        };
      }),
      hasPartialBill:
        po.linkedPurchaseInvoices && po.linkedPurchaseInvoices.length > 0
    };
    let bill: any = {
      ...billInitialState,
      ...selectedOrder
    };

    bill = {
      ...bill,
      id: undefined,
      sequenceFormat: billFormat.length
        ? billFormat.find((format: any) => format.isDefault)?.id
        : bill.sequenceFormat,
      documentType: DOC_TYPE.BILL,
      receiveByDate: bill.fulfillmentDate,
      purchaseInvoiceDueDate: bill.validTillDate,
      purchaseInvoiceType: bill.buyType,
      supplierInvoiceNo: bill.taxInvoiceNo,
      purchaseInvoiceDate: bill.documentDate,
      isPartialInvoice: true,
      attachments: bill.entityId ? bill.attachments : [],
      attachmentIds: bill.entityId ? bill.attachments?.map(Number) : [],
      purchaseInvoiceCode: undefined,
      receivedComplete: false,
      recurring: false,
      recurringActivated: false,
      status: DOCUMENT_STATUS.OPEN,
      documentCode: bill.documentCode,
      billDue: Utility.getValue(
        bill.dueDate,
        bill.purchaseInvoiceDueDate,
        bill.validTillDate
      ),
      documentDate: Utility.getValue(
        bill.documentDate,
        bill.purchaseInvoiceDate
      ),
      validTillDate: Utility.getValue(
        bill.validTillDate,
        bill.purchaseInvoiceDueDate
      ),
      fulfillmentDate: Utility.getValue(
        bill.fulfillmentDate,
        bill.receiveByDate
      ),
      currency: bill.currency,
      receiveGoodsStatus:
        bill.receiptStatus ||
        bill.receiveGoodsStatus ||
        RECEIVED_GOODS_STATUS.NOT_RECEIVED,
      buyType: Utility.getValue(bill.buyType, bill.purchaseInvoiceType),
      gstin: bill.gstin,
      dropShip: bill.dropShip ? bill.dropShip : false,
      placeOfSupply: bill.placeOfSupply,
      isCreditable: bill.isCreditable,
      isPartialBill: true,
      taxInvoiceNo: bill.taxInvoiceNo,
      customerType: bill.customerType,
      vendorType: bill.vendorType,
      openingInvoice: bill.openingInvoice,
      customField: bill.customField,
      paymentStatus: bill.paymentStatus,
      whtRate: bill.whtRate && bill.whtRate !== null ? bill.whtRate : 0,
      tdsInfoIndia: bill.tdsInfoIndia,
      tdsProcessedFlag: bill.tdsProcessedFlag,
      contact: {
        ...bill.contact,
        ...bill.contactDto,
        address: Utility.getStringAddress(bill.contactDto)
      },
      contactCode: bill.contactDto.code,
      purchaseInvoiceProducts: bill.items?.map((item: any) => {
        return {
          ...item,
          id: undefined,
          documentItemCode: undefined,
          unitPriceGstInclusive: bill.unitPriceGstInclusive,
          exchangeRate: bill.exchangeRate,
          currency: bill.currencyCode,
          linkedOrderItem: item.id,
          taxAmount: 1 * item.taxAmount,
          isPartialInvoice: true,
          isPartialBill: true,
          exciseRateIndo: item.exciseRate,
          taxDetails: item.taxDetails.map((tax: any) => {
            const taxItem = { ...tax };
            delete taxItem.id;
            return taxItem;
          })
        };
      }),
      linkedDocuments: [
        {
          documentCode: bill.documentCode as string,
          documentType: bill.documentType,
          documentSequenceCode: bill.documentSequenceCode as string
        }
      ]
    };

    delete bill.documentSequenceCode;

    const { contactDto, items, poCode, purchaseOrderItems, ...billToSave } =
      bill;

    if (!Utility.isEmpty(billToSave)) {
      let payloadData: any = {
        type: LABELS.BILLS,
        title: LABELS.BILLS,
        isMaximized: true,
        isCenterAlign: true,
        populateFormData: billToSave,
        isSaved: true,
        tableId: tableId,
        columnConfig: draftsColumnConfig
      };

      dispatch(removeDraft(props.draftId));
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }

      if (
        DOC_PATH_WITH_ID_REGEX.test(history?.location?.pathname) &&
        history?.location?.pathname?.includes(PAGE_ROUTES.ORDERS)
      ) {
        history.replace(PAGE_ROUTES.ORDERS);
      }

      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
    }
  };

  const getPrintDocTypeFromDocType = (docType: any) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return TEMPLATE_CATEGORY.INVOICE;
      case DOC_TYPE.SALES_ORDER:
        return TEMPLATE_CATEGORY.SALES_ORDER;
      case DOC_TYPE.EXPENSE_BILL:
      case DOC_TYPE.BILL:
        return TEMPLATE_CATEGORY.BILLS;
      case DOC_TYPE.ORDER:
        return TEMPLATE_CATEGORY.ORDER;
      case DOC_TYPE.QUOTE:
        return TEMPLATE_CATEGORY.QUOTE;
      default:
        break;
    }
  };

  const getEmailTemplateConfigFromDocType = (docType: any) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        return invoiceEmailTemplateConfig;
      case DOC_TYPE.EXPENSE_BILL:
      case DOC_TYPE.BILL:
        return billEmailTemplateConfig;
      case DOC_TYPE.QUOTE:
        return quoteEmailTemplateConfig;
      case DOC_TYPE.ORDER:
        return orderEmailTemplateConfig;
      case DOC_TYPE.REQUISITION:
        return reqEmailTemplateConfig;
    }
  };

  const isDocumentTouched = () => {
    if (props.booksDocument.isDocumentTouched) {
      showAlert(
        'Warning!',
        'Document has unsaved changes. Please save the document first.',
        [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m',
            onClick: () => {}
          },
          {
            title: 'Save',
            className: 'bg-button text-white ml-r',
            onClick: () => {
              if (props.updateWithoutClosing) {
                props.updateWithoutClosing();
              }
            }
          }
        ]
      );
      return true;
    } else {
      return false;
    }
  };

  const onEmailCallBack = (data: any, id: any, type: any) => {
    let emailIds = deepClone(emailTriggerIds);
    let invoiceIds = emailIds[type] ?? [];
    invoiceIds.push(id);
    emailIds[type] = invoiceIds;
    console.log(emailIds);
    dispatch(setEmailTriggerIds(emailIds));
  };

  const getCommonActionBtns = () => {
    let buttons = [];
    if (
      Object.keys(modulePermissions).includes('VIEW')
        ? GranularPermissionsHelper.hasPermissionFor(modulePermissions['EDIT'])
        : true
    ) {
      if (
        props.booksDocument.documentType !== DOC_TYPE.REQUISITION ||
        props.booksDocument.documentType !== DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        buttons.push({
          title: 'Preview',
          icon: DKIcons.ic_view,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (props.booksDocument.documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getPrintPreview(
                  getPrintDocTypeFromDocType(props.booksDocument.documentType),
                  props.booksDocument
                );
              }
            }
          }
        });
      }
    }
    if (
      props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
      (Object.keys(modulePermissions).includes('EMAIL')
        ? GranularPermissionsHelper.hasPermissionFor(modulePermissions['EMAIL'])
        : true)
    ) {
      if (
        props.booksDocument.documentType !== DOC_TYPE.REQUISITION ||
        props.booksDocument.documentType !== DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        buttons.push({
          title: 'Email',
          icon: DKIcons.ic_email,
          className: 'column text-blue fw-m',

          onClick: () => {
            if (props.booksDocument.documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getEmailPopup(
                  getPrintDocTypeFromDocType(props.booksDocument.documentType),
                  props.booksDocument,
                  getEmailTemplateConfigFromDocType(
                    props.booksDocument.documentType
                  ),
                  (data: any) =>
                    onEmailCallBack(
                      data,
                      getRowIdByModule(
                        props.booksDocument,
                        props.booksDocument.documentType
                      ),
                      props.booksDocument.documentType
                    )
                );
              }
            }
          }
        });
      }
    }
    if (
      modulePermissions?.['PRINT'] &&
      checkUserPermission(modulePermissions['PRINT'])
    ) {
      if (
        (!isTabletView() &&
          props.booksDocument.documentType !== DOC_TYPE.REQUISITION) ||
        props.booksDocument.documentType !== DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        buttons.push({
          title: 'Print',
          icon: DKIcons.ic_printer,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (props.booksDocument.documentType === DOC_TYPE.REQUISITION) {
              showAlert('', 'Work in Process......');
            } else {
              setIsMenuOpen(false);
              if (!isDocumentTouched()) {
                getPrintPreview(
                  getPrintDocTypeFromDocType(props.booksDocument.documentType),
                  props.booksDocument
                );
              }
            }
          }
        });
      }
    }
    return buttons;
  };
  const recheckDpl = (contact: any) => {
    const billingAddress: any[] = [];
    const countryCodeMap: any = {};
    COUNTRIES_WITH_CURRENCIES.forEach((country) => {
      countryCodeMap[country?.country?.toLowerCase()] = country?.countryCode;
    });
    if (contact?.billingAddress) {
      contact?.billingAddress?.forEach((address: any) => {
        if (address?.country) {
          billingAddress.push({
            ...address,
            countryCode: countryCodeMap?.[address?.country?.toLowerCase()]
          });
        }
      });
    }
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0) return;
    const payload = {
      name: contact?.name ?? '',
      billingAddress: billingAddress,
      customField: dplContactCustomField
    };
    showLoader();
    ContactService.recheck(contact?.id, payload)
      .then((res) => {
        removeLoader();
        if (res) {
          showToast(
            'The contact you are using is under denied party list',
            TOAST_TYPE.SUCCESS
          );
        } else {
          showToast(
            'The contact you are using is not under denied party list',
            TOAST_TYPE.SUCCESS
          );
        }
        dispatch(fetchContacts(''));
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const getSellActionBtns = () => {
    let menuBtns = [];
    if (
      ((props.booksDocument.documentType === DOC_TYPE.QUOTE &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.CONVERT
        ) &&
        !Utility.hasDropShipQty(
          props.booksDocument,
          props.booksDocument.documentType
        ) &&
        !props.booksDocument.linkedSalesOrder?.length) ||
        (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER &&
          !Utility.hasDropShipQty(
            props.booksDocument,
            props.booksDocument.documentType
          ) &&
          checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE))) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.CONVERT,
        props.booksDocument,
        props.booksDocument.documentType
      )
    ) {
      menuBtns.push({
        title: 'Convert to Invoice',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isDocumentTouched()) {
            if (props.booksDocument.documentType === DOC_TYPE.QUOTE) {
              setShowQuoteToInvoiceConversionPopup(true);
            }
            if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
              setShowSOConversionPopup(true);
            }
          }
        }
      });
    }

    if (
      props.booksDocument.documentType === DOC_TYPE.QUOTE &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.CONVERT,
        props.booksDocument,
        DOC_TYPE.QUOTE
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE) &&
      isSalesOrderVisible() &&
      (props.booksDocument.linkedSalesInvoices === null ||
        props.booksDocument.linkedSalesInvoices?.length === 0)
    ) {
      menuBtns.push({
        title: 'Convert to Sales Order',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowQuoteToSOConversionPopup(true);
          }
        }
      });
    }

    if (
      props.booksDocument.documentType === DOC_TYPE.INVOICE &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.GET_PAID,
        props.booksDocument,
        props.booksDocument.documentType
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.EDIT)
    ) {
      menuBtns.push({
        title: 'Get Paid',
        icon: DKIcons.ic_getPaid,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowPaymentPopup(true);
          }
        }
      });
    }
    let showFulfillOption = SellConfig.showActionButton(
      SELL_CONFIG_BTN.FULFILL,
      props.booksDocument,
      props.booksDocument.documentType
    );
    const isQuoteDocumentType =
      props.booksDocument.documentType === DOC_TYPE.QUOTE;
    if (!isQuoteDocumentType) {
      showFulfillOption =
        showFulfillOption && checkUserPermission(modulePermissions['FULFILL']);
    } else {
      if (!isSalesOrderVisible()) {
        showFulfillOption =
          showFulfillOption &&
          checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.FULFILL);
      } else {
        showFulfillOption = false;
      }
    }

    if (showFulfillOption) {
      menuBtns.push({
        title: localizedText('Fulfill'),
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            const docData = props.booksDocument;
            if (
              Utility.isUSorg() &&
              Utility.isDPLSettingEnabled() &&
              (docData.documentType === DOC_TYPE.INVOICE ||
                docData.documentType === DOC_TYPE.SALES_ORDER ||
                docData.documentType === DOC_TYPE.QUOTE)
            ) {
              recheckDpl(docData?.contactDto);
            }

            // if (docData.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
            //   dispatch(removeDraft(props.draftId));
            //   RouteManager.navigateToPage(
            //     PAGE_ROUTES.PICK_PACK_SHIP,
            //     'picking-list'
            //   );
            //   if (props.closeMenuContainer) {
            //     props.closeMenuContainer();
            //   }
            //   return;
            // }

            const displayDropshipConfirmation =
              Utility.needDropshipPopup(docData);
            if (displayDropshipConfirmation) {
              setShowDropshipConfirmationPopup(true);
            } else {
              setShowFulfillmentConfirmationPopup(true);
            }
          }
        }
      });
    }
    return menuBtns;
  };

  const getBuyActionBtns = () => {
    let menuBtns = [];
    if (
      props.booksDocument.documentType === DOC_TYPE.ORDER &&
      checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE) &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.CONVERT,
        props.booksDocument,
        props.booksDocument.documentType
      ) &&
      props.booksDocument.documentType !== DOC_TYPE.REQUISITION
    ) {
      menuBtns.push({
        title: 'Convert',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          convertPOToBill();
        }
      });
    }

    if (
      (props.booksDocument.documentType === DOC_TYPE.REQUISITION ||
        props.booksDocument.documentType ===
          DOC_TYPE.PURCHASE_INWARD_QUOTATION) &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.CONVERT,
        props.booksDocument,
        props.booksDocument.documentType
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)
    ) {
      menuBtns.push({
        title: 'Convert',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          setShowReqConversionPopup(true);
        }
      });
    }

    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.MAKE_PAYMENT,
        props.booksDocument,
        props.booksDocument.documentType
      ) &&
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.BILL.CREATE_PAYMENT_TXN
      )
    ) {
      menuBtns.push({
        title: 'Pay',
        icon: DKIcons.ic_currency,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setShowPaymentPopup(true);
          }
        }
      });
    }

    if (
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.RECEIVED,
        props.booksDocument,
        props.booksDocument.documentType
      ) &&
      ((props.booksDocument.documentType === DOC_TYPE.ORDER &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_ORDER.RECEIVE_GOODS
        )) ||
        (props.booksDocument.documentType === DOC_TYPE.BILL &&
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.BILL.RECEIVE_GOODS
          )))
    ) {
      if (
        props.booksDocument.receiptStatus !==
        RECEIVED_GOODS_STATUS.FULLY_RECEIVED
      ) {
        menuBtns.push({
          title: 'Receive Goods',
          icon: DKIcons.ic_delivery,
          className: 'column text-blue fw-m',
          onClick: () => {
            if (!isDocumentTouched()) {
              if (
                props.booksDocument.purchaseInvoiceType === 'ASSET' ||
                props.booksDocument.orderType === 'ASSET'
              ) {
                setReceivedGoodsAssetPopup(true);
              } else setReceivedGoodsPopup(true);
            }
          }
        });
      }
    }
    return menuBtns;
  };

  const getDocObjectForConversion = () => {
    let doc = deepClone(props.booksDocument);
    doc = {
      ...doc,
      items: doc.items
        ?.map((item: any) => ({
          ...item,
          advancedTracking: item?.product?.advancedTracking
        }))
        .filter((item: any) => item && !item.optional)
    };
    return doc;
  };

  const getJobWorkoutActionBtns = (document: any) => {
    let menuBtns = [];

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.STOCK_TRANSFER.CREATE) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.EDIT) &&
      document.dispatchStatus !== JWO_DISPATCH_STATUS.DISPATCHED &&
      document.dispatchStatus !== JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL
    ) {
      menuBtns.push({
        title: 'Dispatch Goods',
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            showLoader(OPENING_DOCUMENT_LABEL);
            const jwo = props.booksDocument;
            let productIds = jwo?.jobWorkOutOrderItems?.map(
              (item: any) => item.productCode
            );
            ProductService.getProductsByProductIds(productIds, false, false)
              .then((response: any) => {
                let allProductDetails = response;
                WorkoutService.fetchJobWorkOutDetails(jwo?.jwoCode).then(
                  (jwodata: any) => {
                    let jobWorkOutDetails = jwodata;
                    if (
                      allProductDetails?.length &&
                      !Utility.isEmpty(jobWorkOutDetails)
                    ) {
                      jobWorkOutDetails?.jobWorkOutOrderItems?.forEach(
                        (workOutItem: any) => {
                          allProductDetails.forEach((product: any) => {
                            if (
                              workOutItem?.productCode === product?.productId
                            ) {
                              let bomMeta = product.bomMetaDetailsList.find(
                                (item: any) =>
                                  item.code === workOutItem.bomMetaCode
                              );
                              let prouctConfig = bomMeta
                                ? bomMeta.bomProductsConfiguration
                                : product?.bomProductsConfiguration;
                              workOutItem.product.bomProductsConfiguration =
                                prouctConfig?.filter(
                                  (item: any) =>
                                    item.produceProductType ===
                                    PRODUCE_PRODUCT_TYPE.NONE
                                );
                            }
                          });
                        }
                      );
                    }

                    let stockTransferItems = {
                      ...jwo,
                      stockTransferItems:
                        jobWorkOutDetails?.jobWorkOutOrderItems,
                      stockTransferDocumentSeqCodes:
                        jobWorkOutDetails?.stockTransferDocumentSeqCodes,
                      dispatchedQtyItems: jobWorkOutDetails?.dispatchedQtyItems,
                      dispatchedQtyDetails:
                        jobWorkOutDetails?.dispatchedQtyDetails,
                      linkedDocuments: [
                        {
                          documentCode: jwo?.jwoCode,
                          documentSequenceCode: jwo?.documentSequenceCode,
                          documentType: DOC_TYPE.JOB_WORK_OUT_ORDER
                        }
                      ]
                    };

                    setShowDispatchJWOProduct(stockTransferItems);
                    removeLoader();
                  },
                  (err) => {
                    console.error(
                      'Error while fetching document details: ',
                      err
                    );
                    removeLoader();
                  }
                );
              })
              .catch((err: any) => {
                console.log(err, 'product fetch failed');
              });
          }
        }
      });
    }

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE) &&
      document.billedStatus !== JWO_BILLED_STATUS.BILLED
    ) {
      menuBtns.push({
        title: 'Create Bill',
        icon: DKIcons.ic_convert_3,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: () => {
          if (!isOpeningForm) {
            createBill();
          }
        }
      });
    }
    if (
      BuyConfig.showActionButton(
        BUY_CONFIG_BTN.RECEIVE_GOODS,
        document,
        DOC_TYPE.JOB_WORK_OUT_ORDER
      ) &&
      document.dispatchStatus !== JWO_DISPATCH_STATUS.PENDING_DISPATCH &&
      document.dispatchStatus !==
        JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL &&
      checkUserPermission(PERMISSIONS_BY_MODULE.JOB_WORKOUTS.EDIT)
    ) {
      menuBtns.push({
        title: 'Receive Goods',
        icon: DKIcons.ic_delivery,
        className: 'column text-blue fw-m',
        onClick: () => {
          if (!isDocumentTouched()) {
            setReceivedGoodsPopup(true);
          }
        }
      });
    }

    return menuBtns;
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (DOC_PATH_WITH_ID_REGEX.test(history?.location?.pathname)) {
      if (history?.location?.pathname?.includes(PAGE_ROUTES.SALES_ORDERS)) {
        history.replace(PAGE_ROUTES.SALES_ORDERS);
      } else if (history?.location?.pathname?.includes(PAGE_ROUTES.INVOICES)) {
        history.replace(PAGE_ROUTES.INVOICES);
      }
    }
  };

  const getWorkOrderActionBtns = (doc: any) => {
    let menuBtns = [];

    const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
      (product: any) => {
        return product === MRP_APP_NAME;
      }
    );
    let hasItemToBuild = WorkOrderHelper.soHasRemainingQtyToBuild(doc);
    let soConvertedFromdWO = WorkOrderHelper.isSOConvertedFromWorkOrder(doc);

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE) &&
      !Utility.isEmpty(mrpProduct) &&
      Utility.isMRPWithURLCheck() &&
      hasItemToBuild &&
      !soConvertedFromdWO
    ) {
      menuBtns.push({
        title: 'Create WO',
        icon: DKIcons.ic_info,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: async () => {
          let payload: any = {};
          if (doc.documentType === DOC_TYPE.SALES_ORDER) {
            let salesOrderObjectUpdated =
              WorkOrderHelper.deductConvertedQuantityForSOtoWOFlow(doc);
            await validateSelectedSOForInterLinkedWO(
              salesOrderObjectUpdated,
              () => {
                payload = {
                  create: true,
                  salesOrderData: salesOrderObjectUpdated
                };
                dispatch(setCreateWOFromSalesOrder(payload));
                dispatch(removeDraft(props.booksDocument.id));
                if (props.closeMenuContainer) {
                  props.closeMenuContainer();
                }
                replaceURLToModuleURL();
              }
            );
          } else if (doc.documentType === DOC_TYPE.INVOICE) {
            await validateSelectedInvoiceForInterLinkedWO(doc, () => {
              payload = { create: true, salesInvoiceData: doc };
              dispatch(setCreateWOFromSalesInvoice(payload));
              dispatch(removeDraft(props.booksDocument.id));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
              replaceURLToModuleURL();
            });
          }
        }
      });
    }

    return menuBtns;
  };

  const createBill = () => {
    // showLoader('Creating bill…');
    const jwo = props.booksDocument;
    let contactId = jwo?.contactDto?.id;
    let memo: any[] = [];
    jwo?.jobWorkOutOrderItems?.length &&
      jwo?.jobWorkOutOrderItems?.map((item: any) => {
        memo.push(
          `${item.product.name}(Qty: ${item.productQuantity})(Amount: ${item.totalAmount}) \n`
        );
      });
    ContactService.getContactDetailsById(contactId).then(
      (contactInfo: any) => {
        let docDetailsData = billInitialState;
        docDetailsData = {
          ...docDetailsData,
          contact: contactInfo,
          contactCode: contactInfo.code,
          contactDto: contactInfo,
          needToUpdateAddresses: true,
          linkedDocuments: [
            {
              documentCode: jwo?.jwoCode,
              documentSequenceCode: jwo?.documentSequenceCode,
              documentType: DOC_TYPE.JOB_WORK_OUT_ORDER
            }
          ],
          showBillCustomAlert: jwo.showBillCustomAlert ?? false,
          memo: memo.join('')
        };
        if (!Utility.isEmpty(docDetailsData)) {
          let payloadData: any = {
            type: LABELS.BILLS,
            title: LABELS.BILLS,
            isMaximized: true,
            isCenterAlign: true,
            populateFormData: docDetailsData,
            isSaved: true
          };

          dispatch(
            createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
          );
          if (props.closeMenuContainer) {
            props.closeMenuContainer();
          }
          removeLoader();
        }
        setIsOpeningForm(false);
      },
      (err: any) => {
        removeLoader();
        setIsOpeningForm(false);
        console.error('Error loading contact details: ', err);
      }
    );
  };

  const checkIsFa = () => {
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER
    ) {
      if (props.booksDocument.documentType === DOC_TYPE.BILL) {
        if (props.booksDocument.purchaseInvoiceType === BUY_TYPE.ASSET) {
          return true;
        }
      }
      if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
        if (props.booksDocument.orderType === BUY_TYPE.ASSET) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div
      className={
        props.booksDocument.documentType === DOC_TYPE.REQUISITION || checkIsFa()
          ? `row position-relative justify-content-center pointer-events-auto ${
              props.className ? props.className : ''
            }`
          : `row position-relative pointer-events-auto ${
              props.className ? props.className : ''
            }`
      }
    >
      {isMenuOpen && (
        <DKListPicker2
          title=""
          data={menuItems}
          style={{
            width: 'auto'
          }}
          allowSearch={false}
          className="position-absolute z-index-3 top-9 right-0 shadow-m"
          onSelect={(index: number, action: any) => {
            if (action.onClick) {
              action.onClick();
            }
          }}
          onClose={() => {
            setTimeout(() => {
              setIsMenuOpen(false);
            }, 100);
          }}
          renderer={(index: number, obj: any) => {
            return (
              <div className="row parent-width">
                <DKIcon src={obj.icon} className="ic-s" />
                <div className="ml-s">{`${obj.title}`}</div>
              </div>
            );
          }}
        />
      )}

      {props.position === 'top' && (
        <DKButton
          icon={
            props.draftType === DraftTypes.READONLY
              ? DKIcons.ic_arrow_down2
              : DKIcons.white.ic_arrow_down2
          }
          className={`border-radius-m ${
            props.draftType === DraftTypes.READONLY ? 'border-m' : ''
          }`}
          style={{
            paddingleft: 0
          }}
          disabled={props.auditLogView}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      )}
      {props.position === 'bottom' &&
        menuItems.map((menuItem, index) => {
          return (
            <DKButton
              title={menuItem.title}
              icon={menuItem.icon}
              className={`row border-m ${
                index !== menuItems.length - 1 ? 'mr-r' : ''
              }`}
              disabled={props.auditLogView}
              onClick={() => menuItem.onClick()}
            />
          );
        })}
      {showPaymentPopup && (
        <PopupWrapper
          isLoading={paymentPopupLoading}
          loadingBtnList={loadingPaymentPopupBtnConfig}
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={
            props.booksDocument.documentType === DOC_TYPE.INVOICE
              ? t(`INVOICES.DIALOG.RECEIVE_PAYMENT.TITLE`)
              : t(`BILLS.MAKE_PAYMENT.TITLE`)
          }
          btnList={paymentPopupBtnConfig}
          maxWidth={'800px'}
          disableClickOutside={true}
          maxHeight={'90%'}
        >
          <Payment
            documentDetails={props.booksDocument}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={props.booksDocument.documentType}
          />
        </PopupWrapper>
      )}
      {showPaymentRecord && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.PAYMENT_RECORDS.TITLE`)}
          btnList={paymentRecordsBtnConfig}
          width={!isDesktop ? '90%' : '50%'}
          height="400px"
          disableClickOutside={true}
          maxHeight={'90%'}
        >
          <PaymentRecords
            documentSequenceCode={props.booksDocument.documentSequenceCode}
            paymentRecordList={props.booksDocument.knockoffInfo}
            documentType={props.booksDocument.documentType}
            documentCode={
              props.booksDocument.documentType === DOC_TYPE.INVOICE
                ? props.booksDocument.salesInvoiceCode
                : props.booksDocument.purchaseInvoiceCode
            }
            contactCode={props.booksDocument.contactCode}
            closePopup={() => {
              setShowPaymentRecord(false);
            }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
      {showFulfillmentConfirmationPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`${localizedText('Fulfill Your')} ${setPopupTitle(
            props.booksDocument.documentType
          )}`}
          width={'460px'}
          height={'auto'}
          overflowVisible={true}
          disableClickOutside={true}
          btnList={paymentRecordsBtnConfig}
        >
          <FulfillmentConfirmation
            openDraftId={props.draftId}
            documentDetails={deepClone(props.booksDocument)}
            fulfillmentType={props.booksDocument?.fulfillmentType}
            passingInteraction={(callback: CallBackPayloadType, data: any) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            closeMenuContainer={() => {
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
            documentType={props.booksDocument.documentType}
          />
        </PopupWrapper>
      )}

      {showDropshipConfirmationPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Fulfill Your Invoice')}
          width={!isDesktop ? '80%' : '460px'}
          height={'auto'}
          overflowVisible={true}
          disableClickOutside={true}
          btnList={paymentRecordsBtnConfig}
        >
          <DropshipConfirmation
            openDraftId={props.draftId}
            documentDetails={deepClone(props.booksDocument)}
            fulfillmentType={props.booksDocument?.fulfillmentType}
            passingInteraction={(callback: CallBackPayloadType, data: any) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowDropshipConfirmationPopup(false);
            }}
            openFulfillmentPopup={() => {
              setisFulfillmentForDropship(false);
              setShowDropshipConfirmationPopup(false);

              setShowFulfillmentConfirmationPopup(true);
            }}
            documentType={props.booksDocument.documentType}
          />
        </PopupWrapper>
      )}

      {showFulfillmentPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={getDirectFulfillmentTitle()}
          height={'auto'}
          minHeight={'50%'}
          width={'100%'}
          disableClickOutside={true}
          btnList={fulfillmentPopupBtnConfig}
        >
          <Fulfillment
            documentDetails={deepClone(props.booksDocument)}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            closeMenuContainer={() => {
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
            documentType={props.booksDocument?.documentType}
            isFulfillmentForDropship={isFulfillmentForDropship}
          />
        </PopupWrapper>
      )}

      {showDropShipWarningPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.DROPSHIP.TITLE`)}
          btnList={dropShipButtonConfig}
          width={'600px'}
          height={'auto'}
        >
          <DropshipWarning
            document={deepClone(props.booksDocument)}
            passingInteraction={(callback: CallBackPayloadType, data) => {
              parentChildInteraction(callback);
            }}
            documentType={props.booksDocument?.documentType}
          />
        </PopupWrapper>
      )}

      {showReceivedGoodsPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`Received Goods of ${setPopupTitle(
            props.booksDocument.documentType
          )}`}
          height={'auto'}
          minHeight={'50%'}
          width={'100%'}
          btnList={receivedGoodsPopupBtnConfig}
          disableClickOutside={true}
          showScrollBar={true}
        >
          <ReceivedGoods
            documentDetails={deepClone(props.booksDocument)}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={props.booksDocument.documentType}
          />
        </PopupWrapper>
      )}

      {showReceivedGoodsAssetPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`Received Goods of ${setPopupTitle(
            props.booksDocument.documentType
          )}`}
          height={'auto'}
          minHeight={'50%'}
          width={'100%'}
          btnList={receivedGoodsPopupBtnConfig}
          disableClickOutside={true}
        >
          <ReceivedGoodsForAsset
            documentDetails={deepClone(props.booksDocument)}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={props.booksDocument.documentType}
          />
        </PopupWrapper>
      )}

      {showQuoteToInvoiceConversionPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`QUOTES.CONVERT_TO_INVOICE.TITLE`)}
          btnList={convertPopupBtnConfig}
          width={'600px'}
          height={'auto'}
          disableClickOutside={true}
          overflowVisible={true}
        >
          <QuoteToInvoicePopup
            quoteDocument={getDocObjectForConversion()}
            closePopup={() => {
              setShowQuoteToInvoiceConversionPopup(false);
            }}
            closeParentDoc={() => {
              dispatch(removeDraft(props.draftId));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showQuoteToSOConversionPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title="Convert to Sales Order"
          btnList={convertPopupBtnConfig}
          width={'600px'}
          height={'auto'}
          disableClickOutside={true}
          overflowVisible={true}
        >
          <QuoteToSalesOrderPopup
            quoteDocument={getDocObjectForConversion()}
            closePopup={() => {
              setShowQuoteToSOConversionPopup(false);
            }}
            closeParentDoc={() => {
              dispatch(removeDraft(props.draftId));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showSOConversionPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title="Convert to Invoice"
          btnList={convertPopupBtnConfig}
          width={'600px'}
          height={'auto'}
          disableClickOutside={true}
          overflowVisible={true}
        >
          <SalesOrderToInvoicePopup
            salesOrderDocument={getDocObjectForConversion()}
            closePopup={() => {
              dispatch(removeDraft(props.draftId));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showPOConversionPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`PURCHASE_ORDER.CONVERT_TO_BILL.TITLE`)}
          btnList={convertPopupBtnConfig}
          width={'600px'}
          height={'auto'}
          disableClickOutside={true}
          overflowVisible={true}
        >
          <PurchaseOrderToBillPopup
            poDocument={getDocObjectForConversion()}
            closePopup={() => {
              setShowPOConversionPopup(false);
            }}
            closeParentDoc={() => {
              dispatch(removeDraft(props.draftId));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showReqConversionPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`REQUISITION.CONVERT_TO_PURCHASE_ORDER.TITLE`)}
          btnList={convertPopupBtnConfig}
          width={'1000px'}
          height={'auto'}
          disableClickOutside={true}
          overflowVisible={false}
        >
          <RequisitionToPurchaseOrderPopup
            poDocument={getDocObjectForConversion()}
            gridSize={900}
            closePopup={() => {
              setShowReqConversionPopup(false);
            }}
            closeParentDoc={() => {
              dispatch(removeDraft(props.draftId));
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
            }}
          />
        </PopupWrapper>
      )}
      {showStockTransferPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={``}
          btnList={dropShipButtonConfig}
          width={'600px'}
          height={'auto'}
        >
          <AddStockTransferPopup
            records={[]}
            selectedRecord={null}
            detail={stockTransferItems}
            document={DOC_TYPE.JOB_WORK_OUT_ORDER}
            passingInteraction={(callback: CallBackPayloadType) => {}}
            onSave={() => {
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
              setShowStockTransferPopup(false);
            }}
            onCancel={() => {
              if (props.closeMenuContainer) {
                props.closeMenuContainer();
              }
              setShowStockTransferPopup(false);
            }}
            allowFullScreen
          />
        </PopupWrapper>
      )}
      {showDispatchJWOProduct && (
        <DispatchJWOProduct
          records={[]}
          selectedRecord={null}
          detail={showDispatchJWOProduct}
          document={DOC_TYPE.JOB_WORK_OUT_ORDER}
          passingInteraction={() => {}}
          onSave={(stockTransferItemsData: any) => {
            setShowDispatchJWOProduct(false);
            setStockTransferItems({ ...stockTransferItemsData });
            setShowStockTransferPopup(true);
          }}
          onCancel={() => {
            setShowDispatchJWOProduct(false);
          }}
        />
      )}
    </div>
  );
}
