import {
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_FIELD_TYPE,
  DATE_FORMAT,
  FORM_ELEMENTS,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { DATE_COMPARISON } from '../../Constants/Enum';
import {
  BATCH_COLUMNS,
  BATCH_SERIAL_TRACKING_FIELD_NAMES,
  BatchRow,
  BatchSerialTrackingProps,
  BatchSerialTrackingState,
  BatchTrackedMetaData,
  SERIAL_COLUMNS,
  SerialRow,
  SerialTrackedMetaData,
  initialBatchSerialTrackingState
} from '../../Models/BatchSerialTracking';
import { InputTag } from '../../Models/NewContact';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchProducts } from '../../Redux/Slices/ProductsSlice';
import DateFormatService from '../../Services/DateFormat';
import ProductService from '../../Services/Product';
import { DKDataGrid } from 'deskera-ui-library';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';
import { getNewColumn } from '../Accounting/JournalEntry/JEHelper';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import {
  checkIfLineLevelCustomFieldIsValid,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  addProductCustomFieldsToLineItem,
  getDefaultCustomFieldValue,
  getLineItemCFs
} from '../../SharedComponents/CustomFieldsHolder/BatchSerialCustomFieldsHelper';
import { showAddCustomField } from '../../SharedComponents/CustomField/AddCustomField';

export const BatchSerialTrackingPopup: React.FC<BatchSerialTrackingProps> = (
  props
) => {
  const [batchSerialState, setBatchSerialState] =
    useState<BatchSerialTrackingState>(
      deepClone(initialBatchSerialTrackingState)
    );
  const appSelector = useAppSelector;
  const tenantDetails = appSelector(activeTenantInfo);
  const trackingType = props.productDetails?.advancedTracking;
  const decimalPlace = tenantDetails?.decimalScale || 2;
  const dispatch = useAppDispatch();
  var totalAssignedOpeningQuantity = useRef(0);
  const [gridColumns, setGridColumns] = useState<any>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const cfUpdatedTimeMap = useRef<any>({});

  const prepareObject = async () => {
    const productDetails = props.productDetails;
    const advancedTrackingMetaData = props.advancedTrackingMetaData;
    var updatedState = deepClone(initialBatchSerialTrackingState);
    await setTotalAssignedOpeningQuantity();

    if (trackingType === TRACKING_TYPE.BATCH) {
      const productDetails = props.productDetails;
      updatedState.batch.openingQuantity =
        productDetails?.inventory?.openingQuantity || 0;
      updatedState.batch.unassignedQuantity =
        totalAssignedOpeningQuantity.current;

      if (advancedTrackingMetaData) {
        updatedState = setInitialStateForBatchProduct(
          advancedTrackingMetaData,
          updatedState
        );
      }
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      updatedState = setInitialStateSerialForProduct(
        updatedState,
        productDetails,
        tenantDetails,
        decimalPlace,
        advancedTrackingMetaData
      );
    }
    setBatchSerialState({ ...updatedState });
  };

  useEffect(() => {
    updateConfig();
    prepareObject();
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.SAVE_ADVANCED_TRACKING,
      data: () => {
        createAdvancedTracking();
      }
    });
  };

  useEffect(() => {
    if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
      updateConfig();
      if (Utility.isEmpty(props.advancedTrackingMetaData)) {
        let updatedState = { ...batchSerialState };
        let activeCf = batchSerialCFfromStore?.content?.filter((item: any) => {
          return item.status === STATUS_TYPE.ACTIVE;
        });
        activeCf.forEach((cf: any) => {
          if (trackingType === TRACKING_TYPE.BATCH) {
            updatedState.batch.batchList.forEach((batch: any) => {
              if (Utility.isEmpty(batch[cf.id])) {
                if (
                  cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
                ) {
                  batch[cf.id] = DateFormatService.getDateFromStr(
                    cf.defaultValue,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
                } else {
                  batch[cf.id] = cf.defaultValue;
                }
              }
              batch = checkIfLineLevelCustomFieldIsValid(
                batch,
                batchSerialCFfromStore?.content
              );
            });
          } else {
            updatedState.serial.serialList.forEach((batch: any) => {
              if (Utility.isEmpty(batch[cf.id])) {
                if (
                  cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
                ) {
                  batch[cf.id] = DateFormatService.getDateFromStr(
                    cf.defaultValue,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
                } else {
                  batch[cf.id] = cf.defaultValue;
                }
              }
              batch = checkIfLineLevelCustomFieldIsValid(
                batch,
                batchSerialCFfromStore?.content
              );
            });
          }
        });
        setBatchSerialState({ ...updatedState });
      }
    }
  }, [batchSerialCFfromStore]);

  const updateConfig = (updatedState?: any) => {
    let config: any[] = [];
    if (trackingType === TRACKING_TYPE.SERIAL) {
      config = SERIAL_COLUMNS;
    } else {
      config = BATCH_COLUMNS;
    }
    let activeProductCustomFields = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
        return item.status === STATUS_TYPE.ACTIVE;
      });
      activeProductCustomFields = productCf.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
    }

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = config.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        config.push({ ...newItem });
      }
    });

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'amount':
          conf.name = `Acquired Cost (${tenantDetails.currency})`;
          break;
        // case 'serialNumber':
        //   conf.editable = `Acquired Cost (${tenantDetails.currency})`;
        //   break;
        default:
          break;
      }
    });
    setGridColumns([...config]);
  };

  const setTotalAssignedOpeningQuantity = async () => {
    // fetch unassigned opening quantity from server.
    showLoader();
    let unassignedQty =
      await ProductService.getProductUnassignedOpeningQuantity(
        props.productDetails?.productId
      );
    removeLoader();
    totalAssignedOpeningQuantity.current = Number(unassignedQty ?? 0);
  };

  const setInitialStateForBatchProduct = (
    advancedTrackingMetaData: any[],
    batchState: BatchSerialTrackingState
  ) => {
    var updatedState = { ...batchState };
    const newlyAddedBatchList: any[] = [];
    let totalQtyInAllBatches = 0;
    let totalFulfilledQty = 0;
    let totalReturnedQty = 0;
    advancedTrackingMetaData.forEach((element) => {
      const batchNumber: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.serialBatchNumber,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const manufacturedDate: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.manufacturingDate ? element.manufacturingDate : '',
        type: FORM_ELEMENTS.DATE,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const expiryDate: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.expiryDate ? element.expiryDate : '',
        type: FORM_ELEMENTS.DATE,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };

      const batchQuantity: InputTag<string> = {
        key: '',
        hidden: false,
        value: element.batchSize,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };
      const availaleBatchQuantity: InputTag<string> = {
        key: '',
        hidden: false,
        value:
          element.batchSize -
          element?.reservedQuantity -
          element?.batchSizeFulfilled,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true,
        isDisabled: true
      };
      totalQtyInAllBatches += parseFloat(element.batchSize);
      totalFulfilledQty += parseFloat(element.batchSizeFulfilled);
      totalReturnedQty += parseFloat(
        element.advancedTrackingReturned?.batchSizeReturned || 0
      );
      let nonEditableCol = ['batchName', 'batchSize', 'availableBatchSize'];
      if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
        let col = batchSerialCFfromStore?.content?.map((ele: any) => ele.id);
        nonEditableCol = [...nonEditableCol, ...col];
      }
      let newlyAddedBatch: any = {
        batchNumber: batchNumber,
        manufacturedDate: manufacturedDate,
        expiryDate: expiryDate,
        qtyInBatch: batchQuantity,
        availableQtyInBatch: availaleBatchQuantity,
        isExisting: true,
        id: element.id,
        customFields: element.customFields,
        nonEditableColumns: nonEditableCol,
        batchName: batchNumber.value,
        manufacturingDate: DateFormatService.getDateFromStr(
          element.manufacturingDate ?? '',
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expirydate: DateFormatService.getDateFromStr(
          element.expiryDate ?? '',
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        batchSize: batchQuantity.value,
        availableBatchSize: batchQuantity.value,
        customField: element.customField
      };
      newlyAddedBatch = addProductCustomFieldsToLineItem(
        { ...newlyAddedBatch },
        batchSerialCFfromStore?.content
      );
      newlyAddedBatchList.push(newlyAddedBatch);
    });

    updatedState.batch.toalQuantityInBatches = totalQtyInAllBatches;
    updatedState.batch.batchList = newlyAddedBatchList;
    return updatedState;
  };

  const setInitialStateSerialForProduct = (
    serialState: BatchSerialTrackingState,
    productDetails: any,
    tenantDetails: any,
    decimalPlace: any,
    advancedTrackingMetaData: any[] | undefined
  ) => {
    var updatedState = { ...serialState };
    updatedState.serial.openingQuantity =
      productDetails?.inventory?.openingQuantity || 0;
    updatedState.serial.unassignedQuantity =
      totalAssignedOpeningQuantity.current;
    updatedState.serial.currencyCode = tenantDetails.currency;
    if (updatedState.serial.openingQuantity !== 0) {
      const openingValuation = productDetails?.inventory?.openingValuation || 0;
      // unitprice used as string to preserve trailing zeros
      updatedState.serial.unitPrice = Utility.toFixedWithoutRounding(
        openingValuation / updatedState.serial.openingQuantity,
        decimalPlace
      ).toString();
    }

    if (advancedTrackingMetaData) {
      let totalSerials = 0;
      let totalFulfilledQty = 0;
      let totalReturnedQty = 0;
      const newlyAddedSerialList: any[] = [];
      advancedTrackingMetaData.forEach((element) => {
        const serialNumber: InputTag<string> = {
          key: '',
          hidden: false,
          value: element.serialBatchNumber,
          type: FORM_ELEMENTS.INPUT,
          hasError: false,
          isMandatory: true,
          isDisabled: true
        };
        let nonEditableCol = ['serialNumber', 'amount'];
        if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
          let col = batchSerialCFfromStore?.content?.map((ele: any) => ele.id);
          nonEditableCol = [...nonEditableCol, ...col];
        }
        let newlyAddedSerial: any = {
          inputField: serialNumber,
          serialNumber: element.serialBatchNumber,
          amount: Utility.toFixedWithoutRounding(
            element.acquiredCost,
            decimalPlace
          ).toString(),
          customField: element.customField,
          nonEditableColumns: nonEditableCol,
          rowButtons: getRowButtons(element, true)
          // amount: updatedState.serial.unitPrice
        };
        newlyAddedSerial = addProductCustomFieldsToLineItem(
          { ...newlyAddedSerial },
          batchSerialCFfromStore?.content
        );
        totalSerials += 1;
        newlyAddedSerialList.push(newlyAddedSerial);
        totalFulfilledQty += parseFloat(element.batchSizeFulfilled);
        totalReturnedQty += parseFloat(
          element.advancedTrackingReturned?.batchSizeReturned || 0
        );
      });
      if (updatedState.serial.unassignedQuantity === 0) {
        updatedState.serial.noNewSerials = true;
      }
      updatedState.serial.serialList = newlyAddedSerialList;
    }
    return updatedState;
  };

  const getRowButtons = (ele: any, isExisting: boolean) => {
    let rowButtons: any[] = [];
    if (!isExisting) {
      rowButtons.push({
        icon: DKIcons.ic_delete,
        className: 'p-0 mx-auto',
        onClick: (data: any) =>
          trackingType === TRACKING_TYPE.SERIAL
            ? removeSerial(data)
            : removeBatch(data)
      });
    }
    return rowButtons;
  };
  const calculateQuantityToAssign = () => {
    let openingQuantityProduct = Number(
      props?.productDetails?.inventory?.openingQuantity ?? 0
    );
    return Number(
      openingQuantityProduct - batchSerialState.batch.unassignedQuantity
    );
  };

  const addNewSerial = () => {
    const updatedState = { ...batchSerialState };
    if (updatedState.serial.unassignedQuantity === 0) {
      showAlert(
        'Error',
        'You are not allowed to assign serial number more than the receiving quantity.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        ]
      );
      return;
    }
    if (!batchSerialState.serial.serialInputField.value) {
      updatedState.serial.serialInputField.hasError = true;
      updatedState.serial.serialInputField.errorMsg =
        'Serial Number is required';
      setBatchSerialState({ ...updatedState });
      return;
    }
    // allow save btn click
    updatedState.saveBtnDisabled = false;
    const receivedSerialNumbers = updatedState.serial.serialInputField.value
      .toString()
      .trim()
      .split(',');
    const serialNumbers = receivedSerialNumbers.map((serialNumber) =>
      serialNumber.trim()
    );
    // push the serial numbers to the serial array
    const newlyAddedSerialNumber: any[] = [];
    serialNumbers.forEach((newSerialNumber) => {
      const newSerialNumberField: InputTag<string> = {
        key: '',
        hidden: false,
        value: newSerialNumber,
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      };
      let newany: any = {
        inputField: newSerialNumberField,
        amount: updatedState.serial.unitPrice,
        serialNumber: newSerialNumber,
        invalidFields: []
      };
      newany['rowButtons'] = getRowButtons(newany, false);
      newany = getDefaultCustomFieldValue(
        batchSerialCFfromStore?.content,
        newany
      );
      newlyAddedSerialNumber.push(newany);
    });
    // push the new serial numbers to front of the serial array
    updatedState.serial.serialList = [
      ...newlyAddedSerialNumber,
      ...updatedState.serial.serialList
    ];
    // check Duplicate Serial Number
    checkDuplicateSerial(updatedState);
    updatedState.serial.unassignedQuantity -= newlyAddedSerialNumber.length;
    updatedState.serial.serialInputField.value = '';
    setBatchSerialState({ ...updatedState });
    setTimeout(() => {
      setBatchSerialState({ ...updatedState });
    }, 1000);
  };

  const addNewBatch = () => {
    const updatedState = { ...batchSerialState };
    const {
      unassignedQuantity,
      batchInputField,
      batchList,
      batchQuantityField
    } = updatedState.batch;
    if (unassignedQuantity <= 0) {
      showAlert(
        'Error',
        'You are not allowed to assign batch more than the available quantity.'
      );
      return;
    }

    if (!batchSerialState.batch.batchInputField.value) {
      batchInputField.hasError = true;
      batchInputField.errorMsg = 'Batch Number is required';
      setBatchSerialState({ ...updatedState });
      return;
    }

    if (
      isNaN(Number(batchQuantityField.value)) ||
      Number(batchQuantityField.value) <= 0
    ) {
      batchQuantityField.hasError = true;
      batchQuantityField.errorMsg = 'Batch Quantity is required';
      setBatchSerialState({ ...updatedState });
      return;
    }
    // allow save btn click
    updatedState.saveBtnDisabled = false;
    const batchNumber: InputTag<string> = {
      key: '',
      hidden: false,
      value: batchInputField.value,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    };

    const manufacturedDate: InputTag<string> = {
      key: '',
      hidden: false,
      value: Utility.formatDate(new Date(), DATE_FORMAT.DD_MM_YYYY),
      type: FORM_ELEMENTS.DATE,
      hasError: false,
      isMandatory: true
    };
    const nextYearDate = new Date();
    nextYearDate.setDate(nextYearDate.getDay() + 365);
    const expiryDate: InputTag<string> = {
      key: '',
      hidden: false,
      value: Utility.formatDate(nextYearDate, DATE_FORMAT.DD_MM_YYYY),
      type: FORM_ELEMENTS.DATE,
      hasError: false,
      isMandatory: true
    };

    const batchQuantity: InputTag<string> = {
      key: '',
      hidden: false,
      value: batchQuantityField.value,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    };

    let newlyAddedBatch: any = {
      batchNumber: batchNumber,
      manufacturedDate: manufacturedDate,
      expiryDate: expiryDate,
      qtyInBatch: batchQuantity,
      availableQtyInBatch: { ...batchQuantity, isDisabled: true },
      isExisting: false,
      batchName: batchNumber.value,
      manufacturingDate: new Date(),
      // manufacturingDate: manufacturedDate.value,
      expirydate: expiryDate.value,
      batchSize: batchQuantity.value,
      availableBatchSize: batchQuantity.value,
      invalidFields: []
    };
    newlyAddedBatch['rowButtons'] = getRowButtons(newlyAddedBatch, false);
    newlyAddedBatch = getDefaultCustomFieldValue(
      batchSerialCFfromStore?.content,
      newlyAddedBatch
    );
    batchList.splice(0, 0, newlyAddedBatch);
    checkDuplicateBatch(updatedState, true);
    updatedState.batch.batchInputField.value = '';
    updatedState.batch.batchQuantityField.value = '';
    batchInputField.value = '';
    batchQuantityField.value = '';
    setBatchSerialState({ ...updatedState });
    // setTimeout(() => {
    //   setBatchSerialState({ ...updatedState });
    // }, 1000);
  };

  const checkDuplicateSerial = (updatedState: BatchSerialTrackingState) => {
    updatedState.serial.isValid = true;
    // const existingSerialNumbers = deepClone(updatedState.serial.serialList);
    const existingSerialNumbers = [...updatedState.serial.serialList];

    const receivedSerialNumbers: any[] = [];
    existingSerialNumbers.forEach((serial) => {
      let isDuplicate = false;
      receivedSerialNumbers.forEach((receivedSerial) => {
        if (serial.inputField.value === receivedSerial.inputField.value) {
          isDuplicate = true;
          receivedSerial.inputField.hasError = true;
          receivedSerial.inputField.errorMsg = 'Serial already exists';
          receivedSerial['invalidFields'].push('serialNumber');
          // serial form validation fails
          updatedState.serial.isValid = false;
        }
      });
      if (isDuplicate) {
        serial.inputField.hasError = true;
        serial.inputField.errorMsg = 'Serial already exists';
        serial['invalidFields'].push('serialNumber');
      } else {
        serial.inputField.hasError = false;
        serial['invalidFields'] =
          serial['invalidFields']?.filter(
            (ele: any) => ele !== 'serialNumber'
          ) ?? [];
      }
      receivedSerialNumbers.push(serial);
    });
    updatedState.serial.serialList = receivedSerialNumbers;
  };

  const checkDuplicateBatch = (
    updatedState: BatchSerialTrackingState,
    checkOnAddBatch?: boolean
  ) => {
    const existingBatches = [...updatedState.batch.batchList];
    let totalQtyInAllBatches = 0;
    let totalQtyInNewBatches = 0;
    const receivedBatches: any[] = [];
    existingBatches.forEach((batch: any) => {
      let isDuplicate = false;
      receivedBatches.forEach((receivedBatch) => {
        if (
          batch.batchNumber.value === receivedBatch.batchNumber.value &&
          !batch.isExisting
        ) {
          isDuplicate = true;
          receivedBatch.batchNumber.hasError = true;
          receivedBatch.batchNumber.errorMsg = 'Batch already exists';
          receivedBatch['invalidFields'] = ['batchName'];
        }
        if (
          batch.batchNumber.value === receivedBatch.batchNumber.value &&
          !receivedBatch.isExisting
        ) {
          isDuplicate = true;
          receivedBatch.batchNumber.hasError = true;
          receivedBatch.batchNumber.errorMsg = 'Batch already exists';
          receivedBatch['invalidFields'] = ['batchName'];
        }
      });
      if (isDuplicate) {
        batch.batchNumber.hasError = true;
        batch.batchNumber.errorMsg = 'Batch already exists';
        batch['invalidFields'] = ['batchName'];
      } else {
        batch.batchNumber.hasError = false;
      }
      if (!batch.qtyInBatch.hasError && !batch.isExisting) {
        totalQtyInNewBatches += Number(batch.qtyInBatch.value);
      }
      if (!batch.qtyInBatch.hasError) {
        totalQtyInAllBatches += Number(batch.qtyInBatch.value);
      }
      receivedBatches.push(batch);
    });
    updatedState.batch.batchList = receivedBatches;
    updatedState.batch.toalQuantityInBatches = totalQtyInAllBatches;
    updatedState.batch.unassignedQuantity =
      totalAssignedOpeningQuantity.current - totalQtyInNewBatches;
  };

  const removeSerial = ({ rowIndex }: any) => {
    const updatedState = { ...batchSerialState };
    updatedState.serial.serialList.splice(rowIndex, 1);
    updatedState.serial.unassignedQuantity += 1;
    checkDuplicateSerial(updatedState);
    setBatchSerialState({ ...updatedState });
  };

  const removeBatch = ({ rowIndex }: any) => {
    const updatedState = { ...batchSerialState };
    updatedState.batch.batchList.splice(rowIndex, 1);
    checkDuplicateBatch(updatedState);
    setBatchSerialState({ ...updatedState });
  };

  const formFieldUpdated = (
    value: string,
    fieldName: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    // const updatedState = deepClone(batchSerialState);
    const updatedState = { ...batchSerialState };
    switch (fieldName) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER:
        updatedState.serial.serialInputField.value = value;
        updatedState.serial.serialInputField.hasError = false;
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER:
        updatedState.batch.batchInputField.value = value;
        updatedState.batch.batchInputField.hasError = false;
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        updatedState.batch.batchQuantityField.value = value;
        updatedState.batch.batchQuantityField.hasError = false;
        if (isNaN(Number(value))) {
          updatedState.batch.batchQuantityField.hasError = true;
          updatedState.batch.batchQuantityField.errorMsg = 'Invalid Quantity';
        }
        break;

      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const inlineFormFieldUpdated = (
    value: string,
    index: number,
    fieldType: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldType) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER:
        updatedState.serial.serialList[index].inputField.value = value;
        updatedState.serial.serialList[index].inputField.hasError = false;
        updatedState.serial.serialList[index].serialNumber = value;
        checkDuplicateSerial(updatedState);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER:
        updatedState.batch.batchList[index].batchNumber.value = value;
        updatedState.batch.batchList[index].batchNumber.hasError = false;
        checkDuplicateBatch(updatedState);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        updatedState.batch.batchList[index].qtyInBatch.value = value;
        updatedState.batch.batchList[index].qtyInBatch.hasError = false;
        if (isNaN(Number(value)) || Number(value) <= 0) {
          updatedState.batch.batchList[index].qtyInBatch.value = 0;
          updatedState.batch.batchList[index].qtyInBatch.hasError = true;
          updatedState.batch.batchList[index].qtyInBatch.errorMsg =
            'Invalid Quantity';
        } else {
          checkDuplicateBatch(updatedState);
        }
        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const inlineFormFieldBlur = (
    index: number,
    fieldType: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldType) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY:
        const currentValue: any =
          updatedState.batch.batchList[index].qtyInBatch.value;
        updatedState.batch.batchList[index].qtyInBatch.value = isNaN(
          currentValue
        )
          ? 0
          : Utility.roundingOff(currentValue, QTY_ROUNDOFF_PRECISION);
        updatedState.batch.batchList[index].availableQtyInBatch.value =
          updatedState.batch.batchList[index].qtyInBatch.value;

        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
  };

  const calendarDateChanged = (
    date: Date,
    index: number,
    fieldName: BATCH_SERIAL_TRACKING_FIELD_NAMES
  ) => {
    const updatedState = deepClone(batchSerialState);
    switch (fieldName) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.EXPIRY_DATE:
        updatedState.batch.batchList[index].expiryDate.value =
          Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY);
        checkDateValidity(updatedState, index);
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.MANUFACTURED_DATE:
        updatedState.batch.batchList[index].manufacturedDate.value =
          Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY);
        checkDateValidity(updatedState, index);
        break;
      default:
        break;
    }
    setBatchSerialState({ ...updatedState });
    setTimeout(() => {
      setBatchSerialState({ ...updatedState });
    }, 500);
  };

  const checkDateValidity = (
    updatedState: BatchSerialTrackingState,
    index: number
  ) => {
    updatedState.batch.batchList[index].expiryDate.hasError = false;
    const expiryDateStr =
      updatedState.batch.batchList[index].expiryDate.value.toString();
    const manufacturedDateStr =
      updatedState.batch.batchList[index].manufacturedDate.value.toString();
    const expiryDate = DateFormatService.getDateFromStr(
      expiryDateStr,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const manufacturedDate = DateFormatService.getDateFromStr(
      manufacturedDateStr,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    const result = DateFormatService.compareDates(manufacturedDate, expiryDate);
    if (result === DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE) {
      updatedState.batch.batchList[index].expiryDate.hasError = true;
      updatedState.batch.batchList[index].expiryDate.errorMsg =
        'Invalid expiry date';
      updatedState.batch.batchList[index]['invalidFields'].push('expirydate');
    }
  };

  const checkBatchsValidation = () => {
    let isValid = true;
    batchSerialState.batch.batchList.forEach((batch) => {
      if (
        batch.batchNumber.hasError ||
        batch.qtyInBatch.hasError ||
        batch.expiryDate.hasError ||
        Utility.isNotEmpty(batch.invalidFields)
      ) {
        isValid = false;
      }
    });
    return isValid;
  };
  const checkSerialsValidation = () => {
    let isValid = true;
    batchSerialState?.serial?.serialList.forEach((serial: any) => {
      if (Utility.isNotEmpty(serial.invalidFields)) {
        isValid = false;
      }
    });
    return isValid;
  };

  const formValidation = () => {
    const updatedState = deepClone(batchSerialState);
    let formHasError: boolean = false;

    if (trackingType === TRACKING_TYPE.SERIAL) {
      const allSerialsValid = checkSerialsValidation();
      if (updatedState.serial.serialList.length === 0) {
        formHasError = true;
        showAlert('Error', 'Please add atleast one serial');
      } else if (updatedState.serial.unassignedQuantity > 0) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to assign serial number less than the receiving quantity.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]
        );
      } else if (updatedState.serial.unassignedQuantity < 0) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to assign serial number more than the opening quantity.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          ]
        );
      } else if (!updatedState.serial.isValid) {
        formHasError = true;
        showAlert(
          'Error',
          'Serial Form has invalid data. Please check the form and try again.'
        );
      } else if (updatedState.serial.noNewSerials) {
        formHasError = true;
        showAlert('Error', 'You cannot assign new serial numbers.');
      } else if (!allSerialsValid) {
        formHasError = true;
        showAlert(
          'Error',
          'Serial Form has invalid data. Please check the form and try again.'
        );
      }
    } else if (trackingType === TRACKING_TYPE.BATCH) {
      let qtyToAssign = batchSerialState.batch.unassignedQuantity;

      const allBatchsValid = checkBatchsValidation();
      if (updatedState.batch.batchList.length === 0) {
        formHasError = true;
        showAlert('Error', 'Please add atleast one batch');
      } else if (
        Utility.roundingOff(qtyToAssign, tenantDetails.decimalScale) > 0 ||
        Math.sign(
          Utility.roundingOff(qtyToAssign, tenantDetails.decimalScale)
        ) === -1
      ) {
        formHasError = true;
        showAlert(
          'Error',
          'You are not allowed to create new batches with quantity less than unassigned quantity.'
        );
      } else if (
        Utility.roundingOff(
          updatedState.batch.unassignedQuantity,
          tenantDetails.decimalScale
        ) < 0
      ) {
        formHasError = true;
        showAlert(
          'Error',

          'You are not allowed to create new batches with quantity more than opening quantity.'
        );
      } else if (!allBatchsValid) {
        formHasError = true;
        showAlert(
          'Error',

          'Batch Form has invalid data. Please check the form and try again.'
        );
      }
    } else if (updatedState.saveBtnDisabled) {
      formHasError = true;
      showAlert('Error', 'Please add new batch/serial');
    }

    if (formHasError) {
      setBatchSerialState({ ...updatedState });
      return false;
    }
    return true;
  };

  const onRowUpdate = (updates: any) => {
    const key = updates['columnKey'];
    const rowIndex = updates['rowIndex'];
    const item = updates['rowData'];
    let updatedState = { ...batchSerialState };
    let selectedRow: any;
    if (trackingType === TRACKING_TYPE.SERIAL) {
      selectedRow = updatedState.serial.serialList[rowIndex];
      updatedState.serial.serialList[rowIndex]['invalidFields'] = [];
    } else {
      selectedRow = updatedState.batch.batchList[rowIndex];
      updatedState.batch.batchList[rowIndex]['invalidFields'] = [];
    }
    selectedRow['invalidFields'] = [];
    switch (key) {
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER:
        const currentSerialBatchNumber = item['serialNumber']?.trim();
        selectedRow.inputField.value = currentSerialBatchNumber;
        selectedRow.inputField.hasError = false;
        selectedRow.serialNumber = currentSerialBatchNumber;
        checkDuplicateSerial(updatedState);
        if (selectedRow.inputField.hasError) {
          selectedRow['invalidFields'] = ['serialNumber'];
        }
        break;
      case BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER:
        selectedRow.value = item['batchNumber']?.trim();
        selectedRow.hasError = false;
        checkDuplicateBatch(updatedState);
        break;
      case 'batchSize':
        selectedRow.qtyInBatch.value = item['batchSize'];
        selectedRow.qtyInBatch.hasError = false;
        selectedRow.batchSize = item['batchSize'];

        const currentValue: any = item['batchSize'];
        selectedRow.qtyInBatch.value = isNaN(currentValue)
          ? 0
          : Utility.roundingOff(currentValue, QTY_ROUNDOFF_PRECISION);
        selectedRow.availableQtyInBatch.value = selectedRow.qtyInBatch.value;
        selectedRow.availableBatchSize = selectedRow.qtyInBatch.value;

        if (
          isNaN(Number(item['batchSize'])) ||
          Number(item['batchSize']) <= 0
        ) {
          selectedRow['invalidFields'].push('batchSize');
          selectedRow.qtyInBatch.value = 0;
          selectedRow.qtyInBatch.hasError = true;
          selectedRow.qtyInBatch.errorMsg = 'Invalid Quantity';
        } else {
          checkDuplicateBatch(updatedState);
        }
        break;
      case 'expirydate':
        if (
          Utility.isEmpty(item['manufacturingDate']) ||
          new Date(item['manufacturingDate']).getTime() <=
            new Date(item['expirydate']).getTime()
        ) {
          selectedRow.expiryDate.value = Utility.formatDate(
            item['expirydate'],
            DATE_FORMAT.DD_MM_YYYY
          );
          selectedRow.expirydate = item['expirydate'];
        }
        if (
          !Utility.isEmpty(item['manufacturingDate']) &&
          new Date(item['manufacturingDate']).getTime() >=
            new Date(item['expirydate']).getTime()
        ) {
          selectedRow.expirydate = '';
          selectedRow.expiryDate.value = '';
          showAlert(
            'Invalid Date',
            'Expiry date should be greater than or equal to manufacturing date'
          );
        }
        break;
      case 'manufacturingDate':
        selectedRow.manufacturingDate = item['manufacturingDate'];
        if (
          Utility.isEmpty(item['expirydate']) ||
          new Date(item['manufacturingDate']).getTime() <=
            new Date(item['expirydate']).getTime()
        ) {
          selectedRow.manufacturedDate.value = Utility.formatDate(
            item['manufacturingDate'],
            DATE_FORMAT.DD_MM_YYYY
          );
          selectedRow.manufacturingDate = item['manufacturingDate'];
        }
        if (
          !Utility.isEmpty(item['expirydate']) &&
          new Date(item['manufacturingDate']).getTime() >=
            new Date(item['expirydate']).getTime()
        ) {
          selectedRow.manufacturingDate = '';
          selectedRow.manufacturedDate.value = '';
          showAlert(
            'Invalid Date',
            'Manufacturing date should be less than or equal to expiry date'
          );
        }
        break;
      default:
        let dataToUpdate = item && item[key];
        const CFColumnConfig = gridColumns?.find(
          (cf: any) => cf?.id === key && cf.isCustomField
        );
        const filteredCF: any = batchSerialCFfromStore?.content?.find(
          (field: any) => field.id === key
        );
        let cfValue = '';
        if (!Utility.isEmpty(filteredCF)) {
          if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.USER.toLowerCase()
          ) {
            const tempCFOption = filteredCF?.attributes?.find(
              (attr: any) => attr.code === dataToUpdate.code
            );
            if (tempCFOption) {
              cfValue = tempCFOption?.code;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue =
              dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
            cfUpdatedTimeMap.current = {
              ...cfUpdatedTimeMap.current,
              [filteredCF.id]: new Date().getTime()
            };
          } else {
            cfValue =
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
                ? DateFormatService.getDateStrFromDate(
                    new Date(dataToUpdate),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : dataToUpdate;
          }
          if (CFColumnConfig && filteredCF) {
            const cfToUpdate = {
              id: filteredCF.id,
              shortName: filteredCF.shortName,
              module: filteredCF.module,
              code: filteredCF.code,
              label: filteredCF.label,
              value: cfValue
            };
            let existingCFs = selectedRow?.customField
              ? [...selectedRow?.customField]
              : [];
            const existingCFIndex = existingCFs.findIndex(
              (cf: any) => cf?.id === cfToUpdate?.id
            );
            if (existingCFIndex === -1) {
              existingCFs = [...existingCFs, cfToUpdate];
            } else {
              existingCFs[existingCFIndex] = cfToUpdate;
            }
            if (selectedRow) {
              selectedRow.customField = existingCFs;
            }
          }
          if (selectedRow) {
            if (
              filteredCF?.fieldType?.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              selectedRow[key] = new Date(dataToUpdate);
            } else if (Utility.isObject(dataToUpdate)) {
              if (
                filteredCF?.fieldType?.toLowerCase() ===
                INPUT_TYPE.DROPDOWN.toLowerCase()
              ) {
                selectedRow[key] =
                  dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
                const { rowData } = updateRowDataWithParentCFValues(
                  dataToUpdate.value,
                  { ...selectedRow },
                  filteredCF,
                  batchSerialCFfromStore.content
                );
                selectedRow = rowData;
              } else {
                selectedRow[key] = dataToUpdate.value;
              }
            } else {
              selectedRow[key] = dataToUpdate;
            }
          }
        }
        break;
    }
    if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
      selectedRow = checkIfLineLevelCustomFieldIsValid(
        selectedRow,
        batchSerialCFfromStore?.content
      );
    }

    if (trackingType === TRACKING_TYPE.SERIAL) {
      updatedState.serial.serialList[rowIndex] = selectedRow;
    } else {
      updatedState.batch.batchList[rowIndex] = selectedRow;
    }

    setBatchSerialState({ ...updatedState });
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        columns={[
          ...gridColumns,
          {
            key: 'action',
            name: '',
            type: INPUT_TYPE.BUTTON,
            width: 130,
            options: []
          }
        ]}
        rows={
          trackingType === TRACKING_TYPE.SERIAL
            ? batchSerialState.serial.serialList
            : batchSerialState.batch.batchList
        }
        onRowClick={(data: any) => {
          updateColumnConfigOnRowClick(
            data?.columnData,
            data?.rowData,
            gridColumns,
            batchSerialCFfromStore.content,
            cfUpdatedTimeMap.current
          );
        }}
      />
    );
  };
  const onAddCustomFieldClicked = () => {
    const module = 'BATCHSERIAL';
    showAddCustomField(
      {
        forDocument: true,
        module: module,
        moduleNameEnum: MODULES_NAME.BATCH_SERIAL
      },
      (newCustomField) => {
        updateConfig();
      }
    );
  };

  const createAdvancedTracking = () => {
    const isValid = formValidation();
    if (!isValid) {
      return;
    }

    const productPayload = deepClone(props.productDetails);
    const updatedState = deepClone(batchSerialState);

    let existingTrackingData: any[] = [];
    if (props.advancedTrackingMetaData) {
      existingTrackingData = props.advancedTrackingMetaData.map((item) =>
        (item.serialBatchNumber ?? '').trim()
      );
    }

    if (trackingType === TRACKING_TYPE.BATCH) {
      // updatedState.batch.batchList = updatedState.batch.batchList.filter(
      //   (batch) => {
      //     let batchAlreadlyExists: boolean = false;
      //     existingTrackingData.forEach((trackingData) => {
      //       if (batch.batchNumber.value.toString().trim() === trackingData) {
      //         batchAlreadlyExists = true;
      //       }
      //     });
      //     return !batchAlreadlyExists;
      //   }
      // );

      const batchTrackingMetaDataList: BatchTrackedMetaData[] =
        updatedState.batch.batchList.map((batch) => {
          let batchTrackingMetaData: BatchTrackedMetaData = {
            serialBatchNumber: batch.batchNumber.value.toString(),
            batchSize: Number(batch.qtyInBatch.value),
            expiryDate: batch.expiryDate.value.toString(),
            manufacturingDate: batch.manufacturedDate.value.toString(),
            customField: getLineItemCFs(
              batch,
              gridColumns,
              batchSerialCFfromStore?.content
            )
          };
          if (batch.isExisting) {
            batchTrackingMetaData = { ...batchTrackingMetaData, id: batch.id };
          }
          return batchTrackingMetaData;
        });

      productPayload['advancedTrackingMetaData'] = batchTrackingMetaDataList;
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      updatedState.serial.serialList = updatedState.serial.serialList.filter(
        (serial) => {
          let findSerialNumberInExisting = existingTrackingData?.find(
            (existingSerialNumber: string) =>
              String(serial.inputField.value ?? '').trim() ===
              existingSerialNumber
          );
          // if serial is not present in Existing then add it
          return Utility.isEmpty(findSerialNumberInExisting);
        }
      );

      const serialTrackingMetaDataList: SerialTrackedMetaData[] =
        updatedState.serial.serialList.map((serial) => {
          const serialTrackingMetaData: SerialTrackedMetaData = {
            serialBatchNumber: serial.inputField.value.toString(),
            batchSize: 1,
            customField: getLineItemCFs(
              serial,
              gridColumns,
              batchSerialCFfromStore?.content
            )
          };
          return serialTrackingMetaData;
        });
      productPayload['advancedTrackingMetaData'] = serialTrackingMetaDataList;
    }

    // disable save button
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
    });

    ProductService.updateProduct(productPayload)
      .then((res) => {
        showAlert('Success', 'Advanced Tracking added successfully');
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_BATCH_SERIAL_TRACKING
        });
        dispatch(fetchProducts());
      })
      .catch((error) => {
        showAlert('Error', 'Advanced Tracking Failed.');
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
        });
      });
  };

  if (trackingType === TRACKING_TYPE.SERIAL) {
    return (
      <div className="column p-h-m p-v-m parent-width">
        <div className="column parent-width">
          <div className="parent-width row flex-wrap justify-content-between align-items-center">
            <DKLabel
              text={`Opening Quantity: ${batchSerialState.serial.openingQuantity}`}
              className=" fw-s"
            />
            <DKLabel
              text={`Unassigned Quantity: ${batchSerialState.serial.unassignedQuantity}`}
              className=" fw-s"
            />
          </div>
          <div className="parent-width row justify-content-between align-items-center mt-m">
            <div style={{ width: '80%' }}>
              <DKInput
                title=""
                required={batchSerialState.serial.serialInputField.isMandatory}
                value={batchSerialState.serial.serialInputField.value}
                onChange={(value: string) =>
                  formFieldUpdated(
                    value,
                    BATCH_SERIAL_TRACKING_FIELD_NAMES.SERIAL_NUMBER
                  )
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                canValidate={batchSerialState.serial.serialInputField.hasError}
                validator={(value: string) => {
                  return !batchSerialState.serial.serialInputField.hasError;
                }}
                errorMessage={batchSerialState.serial.serialInputField.errorMsg}
              />
            </div>
            <DKButton
              title={'Assign'}
              className={'bg-app text-white fs-xm'}
              onClick={() => addNewSerial()}
            />
          </div>
          <div className="parent-width mt-l">
            <DKLabel
              text="Use comma separator for multiple entries (e.g. Serial1,Serial2)"
              className="fw-m fs-s"
            />
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />
          {getBatchTrackingGrid()}
          {Utility.isEmpty(props.advancedTrackingMetaData) && (
            <div className="">
              <DKButton
                title={`+ Add Custom Fields`}
                onClick={() => onAddCustomFieldClicked()}
                className={` fw-m p-0 text-blue`}
                style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    let qtyToAssign = calculateQuantityToAssign();
    qtyToAssign = qtyToAssign > 0 ? qtyToAssign : 0;
    return (
      <div className="column p-h-m parent-width">
        <div className="column parent-width">
          <div className="parent-width row justify-content-end align-items-center">
            {/* <div className="column parent-width ">
              <DKLabel text={`Opening Quantity`} className="fw-s" />
              <DKLabel
                text={`${batchSerialState.batch.openingQuantity}`}
                className="fw-s"
              />
            </div> */}
            <div className="column parent-width align-items-end">
              <DKLabel text={`Unassigned Quantity`} className="fw-s" />
              <DKLabel
                text={`${Utility.roundingOff(
                  Number(batchSerialState.batch.unassignedQuantity),
                  tenantDetails.decimalScale
                )}`}
                className="fw-s"
              />
            </div>
            {/* <div className="column parent-width align-items-end">
              <DKLabel text={`Total qty. in Batch`} className="ml-m fw-s" />
              <DKLabel
                text={`${Utility.roundingOff(
                  batchSerialState.batch.toalQuantityInBatches,
                  tenantDetails.decimalScale
                )}`}
                className="ml-m fw-s"
              />
            </div> */}
          </div>
          <DKLine className="mt-m" />
          <div className="row justify-content-between align-items-end mt-m gap-2">
            <DKInput
              title="Batch Number"
              required={batchSerialState.batch.batchInputField.isMandatory}
              value={batchSerialState.batch.batchInputField.value}
              onChange={(value: string) =>
                formFieldUpdated(
                  value,
                  BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_NUMBER
                )
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={batchSerialState.batch.batchInputField.hasError}
              validator={(value: string) => {
                return !batchSerialState.batch.batchInputField.hasError;
              }}
              errorMessage={batchSerialState.batch.batchInputField.errorMsg}
            />
            <DKInput
              type={INPUT_TYPE.NUMBER}
              title="Batch Size"
              required={batchSerialState.batch.batchQuantityField.isMandatory}
              value={batchSerialState.batch.batchQuantityField.value}
              onChange={(value: string) =>
                formFieldUpdated(
                  value,
                  BATCH_SERIAL_TRACKING_FIELD_NAMES.BATCH_QUANTITY
                )
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={batchSerialState.batch.batchQuantityField.hasError}
              validator={(value: string) => {
                return !batchSerialState.batch.batchQuantityField.hasError;
              }}
              errorMessage={batchSerialState.batch.batchQuantityField.errorMsg}
            />
            <DKButton
              title={'Add Batch'}
              className={'bg-app text-white fs-xm'}
              onClick={() => addNewBatch()}
            />
          </div>
          <div className="parent-width mt-l">
            <DKLabel
              text="Add Batch number, one at a time"
              className="fw-m fs-s"
            />
          </div>
          <DKLine className="parent-width mt-m bg-gray4" />

          {getBatchTrackingGrid()}

          {Utility.isEmpty(props.advancedTrackingMetaData) && (
            <div className="">
              <DKButton
                title={`+ Add Custom Fields`}
                onClick={() => onAddCustomFieldClicked()}
                className={` fw-m p-0 text-blue`}
                style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};
