import { DKLabel, DKButton, DKCheckMark, showAlert } from 'deskera-ui-library';
import { useState } from 'react';

export default function MTDSignUpConfirmPopUp(props: any) {
  //states
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const connectToHMRC = () => {
    const UK_HMRC_CONFIG = {
      base_url: 'https://test-www.tax.service.gov.uk/oauth/authorize',
      signup_url:
        'https://www.gov.uk/vat-record-keeping/sign-up-for-making-tax-digital-for-vat',
      params: {
        response_type: 'code',
        scope: 'read:vat+write:vat',
        client_id: '1LcAIQUR2ESNsCPdlCHUotgn4KPT',
        redirect_uri: process.env.REACT_APP_ERP_URL
      }
    };
    const baseUrl = UK_HMRC_CONFIG.base_url;
    const hmrcParams: any = UK_HMRC_CONFIG.params;
    hmrcParams.redirect_uri = window.location.href;
    let params = [];
    for (let c in hmrcParams) {
      params.push(c + '=' + hmrcParams[c]);
    }
    let paramStr = params.join('&');
    let url = `${baseUrl}?${paramStr}`;
    window.open(url, '_self');
  };

  const signUpForMTD = () => {
    const UK_HMRC_CONFIG = {
      base_url: 'https://test-www.tax.service.gov.uk/oauth/authorize',
      signup_url:
        'https://www.gov.uk/vat-record-keeping/sign-up-for-making-tax-digital-for-vat',
      params: {
        response_type: 'code',
        scope: 'read:vat+write:vat',
        client_id: '1LcAIQUR2ESNsCPdlCHUotgn4KPT',
        redirect_uri: process.env.REACT_APP_ERP_URL
      }
    };
    const CONFIG: any = UK_HMRC_CONFIG;
    const baseUrl = CONFIG?.signup_url;
    const hmrcParams = CONFIG?.params;
    hmrcParams.redirect_uri = window.location.href;
    let params = [];
    for (let c in hmrcParams) {
      params.push(c + '=' + hmrcParams[c]);
    }
    let paramStr = params.join('&');
    let url = `${baseUrl}?${paramStr}`;
    window.open(url);
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Need Attention" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={props.onCancel}
          />
        </div>
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto"></div>
        <div className="row width-auto">
          <DKButton
            title={'Sign Up for MTD'}
            className="text-white bg-button border-m mr-r"
            onClick={() => {
              signUpForMTD();
            }}
            disabled={!isAcknowledged}
          />
          <DKButton
            title={'Connect to HMRC'}
            className="text-white bg-button border-m mr-r"
            onClick={() => {
              let buttons = [
                {
                  title: 'No',
                  className: 'bg-gray2 border-m ',
                  onClick: () => {}
                },
                {
                  title: 'Yes',
                  className: 'text-white bg-button ml-r',
                  onClick: () => {
                    connectToHMRC();
                  }
                }
              ];
              showAlert(
                'Connect to HMRC',
                `HMRC website will open in the current window. After logged in and grant authority, the page will redirect back to Deskera app. Do you want to continue?`,
                buttons
              );
            }}
            disabled={!isAcknowledged}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background" style={{ zIndex: 9999 }}>
      <div
        className="popup-window shadow border-m gap-2"
        style={{
          maxWidth: 450,
          width: '90%',
          maxHeight: '95%',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-4 gap-2">
          <DKLabel text="To begin using MTD for VAT you will need to sign up for MTD on the HMRC website. Already signed up? Connect to HMRC and grant Deskera authority. A new window will open for you to sign in with your Government Gateway ID. HRMC won’t have access to your data in Deskera. You only have to authorise Deskera once every 18 months." />
          <DKCheckMark
            title={'I acknoledge the information above.'}
            isSelected={isAcknowledged}
            color={'bg-app'}
            onClick={() => {
              setIsAcknowledged(!isAcknowledged);
            }}
          />
        </div>
        {getFooter()}
      </div>
    </div>
  );
}
