import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DKButton,
  DKIcons,
  DKIcon,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';

import debounce from '../../Utility/Debounce';
import Utility, { deepClone } from '../../Utility/Utility';
import http2 from '../../Services/Interceptor2';
import PriceListService, {
  PriceListApiConfig,
  defaultConfig
} from '../../Services/PriceList';

export const MultiPriceListPopup = (props: any) => {
  const [pLs, setPLs] = useState<any>([]);
  const [selectedPLs, setSelectedPLs] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [expandedDetails, setExpandedDetails] = useState(false);
  const [priceListData, setPriceListData] = useState<any>([]);

  useEffect(() => {
    // fetchProducts();
    fetchPriceList();
    return () => {
      PriceListService.apiConfig = defaultConfig;
    };
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 0) {
      fetchPriceList();
      //fetchProducts();
    } else {
      setCurrentPage(0);
    }
  }, [searchText]);

  const filterProducts = (products: any) => {
    return products;
  };

  const onSearch = debounce((searchText: string) => {
    setSearchText(searchText);
  }, 300);

  const fetchPriceList = () => {
    setLoading(true);

    let config: PriceListApiConfig = {
      ...PriceListService.apiConfig,
      Limit: 10,
      Query: `type=${props.type},allContacts=${false}`,
      Page: currentPage,
      SearchTerm: searchText
    };
    PriceListService.apiConfig = config;

    let endPoint = PriceListService.getPriceListUrl(props.type);
    return http2
      .get(endPoint)
      .then((res: any) => {
        const propsProducts =
          props?.selectedProductsIds?.map((product: any) => ({
            ...product,
            selected: true
          })) || [];

        const selectedProductList = uniquePLs([
          ...propsProducts,
          ...selectedPLs
        ]);
        const selectedProductIds =
          selectedProductList?.map((product: any) => product.productId) || [];

        const mappedProducts = () =>
          filterProducts(res?.content)?.map((product: any) => {
            return {
              productId: product.id,
              productName: product.name,
              productCode: product.productId,
              selected: product?.id
                ? selectedProductIds?.includes?.(product?.id)
                : false
            };
          });
        setSelectedPLs([...selectedProductList]);
        setLoading(false);
        setPLs(mappedProducts);
        setPriceListData(res);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  const onSave = () => {
    let selectedProduct: any[] = [];
    selectedPLs?.forEach((product: any) => {
      if (product?.selected) {
        selectedProduct.push(product);
      }
      delete product?.selected;
    });
    props.onSave?.(selectedProduct);
  };
  function uniquePLs(array: any) {
    var processed = [];
    for (var i = array.length - 1; i >= 0; i--) {
      if (processed.indexOf(array[i].productId) < 0) {
        processed.push(array[i].productId);
      } else {
        array.splice(i, 1);
      }
    }
    return array;
  }
  const getHeader = () => {
    return (
      <div className="row justify-content-between bg-gray1 p-s">
        <div className="row">
          <DKLabel
            text={props?.title ? props?.title : 'Select Substitutes'}
            className="fw-m fs-l"
          />
        </div>
        <div className="row-reverse">
          <DKButton
            title={'Assign'}
            onClick={onSave}
            className={`bg-button text-white`}
          />
          <DKButton
            title={'Close'}
            className="bg-white border-m mr-r"
            onClick={() => props.onClose?.()}
          />
        </div>
      </div>
    );
  };
  const getPaginationView = () => {
    const { totalPages, pageable } = priceListData;
    return (
      totalPages > 1 && (
        <div
          className="row justify-content-end mb-s mt-s"
          style={{ height: 80 }}
        >
          <div
            className="row bg-white justify-content-end p-1 py-2 border-m border-radius-m"
            style={{ width: 'auto' }}
          >
            {currentPage + 1 > 1 && (
              <DKButton
                icon={DKIcons.ic_arrow_left2}
                className="ic-s"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
            )}
            <div className={'fs-m p-h-s'}>
              Page{` ${(pageable?.pageNumber || 0) + 1} - ${totalPages || 0}`}
            </div>
            {currentPage + 1 < totalPages && (
              <DKButton
                icon={DKIcons.ic_arrow_right2}
                className="ic-s"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              />
            )}
          </div>
        </div>
      )
    );
  };
  const getSelectedPLsView = () => {
    return (
      <div className="column parent-width mt-2 gap-2">
        <div className="row gap-1">
          <span
            className="p-s border-radius-s fw-m cursor-hand"
            style={{
              backgroundColor: 'rgb(235, 243, 255)'
            }}
            onClick={() => {
              setExpandedDetails(!expandedDetails);
            }}
          >
            {selectedPLs.length} materials
          </span>
          <span>selected</span>
        </div>
        {expandedDetails && (
          <div
            className="row parent-height align-items-start justify-content-start flex-wrap bg-gray1 border-radius-s p-r mt-s overflow-scroll hide-scroll-bar paren-size position-relative"
            style={{ gap: 5 }}
          >
            {selectedPLs?.map((product: any, index: number) =>
              getSubstitute({
                ...product,
                onClick: () => {},
                selected: true
              })
            )}
            {Utility.isEmpty(selectedPLs) && (
              <div className="column parent-size align-items-center justify-content-center">
                <DKLabel className="text-gray" text="No Product Assigned" />
                <DKLabel
                  className="text-gray"
                  text="Please assign material to template"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const isAllSelected = () => {
    return pLs?.reduce?.((prev: boolean, current: any) => {
      return prev && current?.selected;
    }, true);
  };

  const getPLsView = () => {
    return (
      <div className="column parent-width mt-2">
        <div className="row justify-content-between">
          <DKLabel text={`Price Lists`} className="fw-m" />
          <DKButton
            title={isAllSelected() ? 'Deselect All' : 'Select All'}
            className="text-blue"
            onClick={() => {
              selectAllPLs();
            }}
          />
        </div>
        <div
          className="row parent-height align-items-start flex-wrap  bg-gray1 border-radius-s p-r mt-s overflow-scroll hide-scroll-bar paren-size position-relative"
          style={{ gap: 5, minHeight: 100, maxHeight: 250, height: 'auto' }}
        >
          {pLs?.map((product: any, index: number) =>
            getSubstitute({ ...product, onClick: () => onSubSelect(index) })
          )}
        </div>
      </div>
    );
  };
  const getBody = () => {
    return (
      <div className="p-r column parent-size overflow-scroll hide-scroll-bar">
        <div className="row">
          <div className="row parent-width">
            <DKLabel
              className="fw-m fs-m"
              text={props?.title ? props?.title : 'Select Substitutes'}
            />
            {loading && <DKSpinner iconClassName="ic-s ml-s" />}
          </div>
          <DKInput
            className="column parent-width"
            type={INPUT_TYPE.TEXT}
            value={searchText}
            placeholder={'Search Price List...'}
            onChange={onSearch}
          />
        </div>
        {!Utility.isEmpty(pLs) && getPLsView()}
        {getPaginationView()}
        {selectedPLs?.length > 0 && getSelectedPLsView()}
      </div>
    );
  };
  const onSubSelect = (index: number) => {
    const copyOfPLs = deepClone(pLs);
    copyOfPLs[index]['selected'] = !copyOfPLs[index]['selected'];
    let copyOfSelectPLs = [...selectedPLs];
    if (copyOfPLs[index]['selected']) {
      copyOfSelectPLs.push(copyOfPLs[index]);
    } else {
      copyOfSelectPLs = copyOfSelectPLs.filter(
        (item: any) => item.productId !== copyOfPLs[index].productId
      );
    }
    setSelectedPLs([...copyOfSelectPLs]);
    setPLs(copyOfPLs);
  };

  const selectAllPLs = () => {
    const copyOfPLs = deepClone(pLs);
    let copyOfSelectPls = [...selectedPLs];
    pLs?.forEach((element: any, index: number) => {
      if (isAllSelected()) {
        copyOfPLs[index]['selected'] = false;
        copyOfSelectPls = copyOfSelectPls.filter(
          (item: any) => item.productId !== copyOfPLs[index].productId
        );
      } else {
        if (!copyOfPLs[index]['selected']) {
          copyOfPLs[index]['selected'] = true;
          copyOfSelectPls.push(copyOfPLs[index]);
        }
      }
      // copyOfProducts[index]['selected'] = isAllSelected() ? false : true;
      // if (copyOfProducts[index]['selected']) {
      //   copyOfSelectProducts.push(copyOfProducts[index]);
      // } else {
      //   copyOfSelectProducts = copyOfSelectProducts.filter(
      //     (item: any) => item.productId !== copyOfProducts[index].productId
      //   );
      // }
    });
    setSelectedPLs([...copyOfSelectPls]);
    setPLs(copyOfPLs);
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window p-0"
        style={{
          maxHeight: '500',
          overflow: 'hidden',
          height: 'auto'
        }}
      >
        {getHeader()}
        {getBody()}
      </div>
    </div>
  );
};
export const getSubstitute = ({
  productName,
  onClick,
  selected,
  allowRemove,
  onRemove
}: any) => {
  return (
    <div
      className={`row bg-white border-radius-s width-auto p-xs bg-white border-m cursor-hand ${
        selected && 'bg-chip-blue border-blue text-app-color'
      }`}
      onClick={onClick}
    >
      <DKLabel text={productName} className="mr-s" />
      {allowRemove && (
        <DKIcon src={DKIcons.ic_close} className="ic-xs" onClick={onRemove} />
      )}
    </div>
  );
};
