import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";
import { HeaderColumn } from "../HeaderColumn";

export class PaymentTableWidth {

    isSnNumberVisible: boolean;
    isInvoiceNumberVisible: boolean;
    isBillNumberVisible: boolean;
    isTotalInvoiceAmountVisible: boolean;
    isTotalBillAmountVisible: boolean;
    isPaymentMadeVisible: boolean;
    isCurrentDueAmountVisible: boolean;
    isCurrentBalanceVisible: boolean;
    isPaymentMediumVisible: boolean;

    snNumber: number;
    invoiceNumber: number;
    billNumber: number;
    totalInvoiceAmount: number;
    totalBillAmount: number;
    paymentMade: number;
    currentDueAmount: number;
    currentBalance: number;
    paymentMedium: number;

    originalSnNumber: number;
    originalInvoiceNumber: number;
    originalBillNumber: number;
    originalTotalInvoiceAmount: number;
    originalTotalBillAmount: number;
    originalPaymentMade: number;
    originalCurrentDueAmount: number;
    originalCurrentBalance: number;
    originalPaymentMedium: number;
    constructor() {
        this.isSnNumberVisible = true
        this.isInvoiceNumberVisible = true
        this.isBillNumberVisible = true
        this.isTotalInvoiceAmountVisible = true
        this.isTotalBillAmountVisible = true
        this.isPaymentMadeVisible = true
        this.isCurrentDueAmountVisible = true
        this.isCurrentBalanceVisible = true
        this.isPaymentMediumVisible = true

        this.snNumber = 10
        this.invoiceNumber = 20
        this.billNumber = 20
        this.totalInvoiceAmount = 20
        this.totalBillAmount = 20
        this.paymentMade = 20
        this.currentDueAmount = 15
        this.currentBalance = 15
        this.paymentMedium = 15

        this.originalSnNumber = 10
        this.originalInvoiceNumber = 20
        this.originalBillNumber = 20
        this.originalTotalInvoiceAmount = 20
        this.originalTotalBillAmount = 20
        this.originalPaymentMade = 20
        this.originalCurrentDueAmount = 15
        this.originalCurrentBalance = 15
        this.originalPaymentMedium = 15

    }

    setIsShowWidth(columnData: HeaderColumn[]) {
        this.isSnNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.snNumber)
        this.isInvoiceNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.invoiceNumber)
        this.isBillNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.billNumber)
        this.isTotalInvoiceAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.totalInvoiceAmount)
        this.isTotalBillAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.totalBillAmount)
        this.isPaymentMadeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.paymentMade)
        this.isCurrentDueAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.currentDueAmount)
        this.isCurrentBalanceVisible = getIsShowTableColumn(columnData, TableColumnPopupType.currentBalance)
        this.isPaymentMediumVisible = getIsShowTableColumn(columnData, TableColumnPopupType.paymentMedium)
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalWidth
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.snNumber = this.originalSnNumber
        this.invoiceNumber = this.originalInvoiceNumber
        this.billNumber = this.originalBillNumber
        this.totalInvoiceAmount = this.originalTotalInvoiceAmount
        this.totalBillAmount = this.originalTotalBillAmount
        this.paymentMade = this.originalPaymentMade
        this.currentDueAmount = this.originalCurrentDueAmount
        this.currentBalance = this.originalCurrentBalance
        this.paymentMedium = this.originalPaymentMedium
    }

    private resetElementWidth() {
        this.snNumber = 0
        this.invoiceNumber = 0
        this.billNumber = 0
        this.totalInvoiceAmount = 0
        this.totalBillAmount = 0
        this.paymentMade = 0
        this.currentDueAmount = 0
        this.currentBalance = 0
        this.paymentMedium = 0

    }

    private getTotalWidth(totalWidth: number) {
        if (this.isSnNumberVisible) {
            totalWidth += this.snNumber;
        }
        if (this.isInvoiceNumberVisible) {
            totalWidth += this.invoiceNumber;
        }
        if (this.isBillNumberVisible) {
            totalWidth += this.billNumber;
        }
        if (this.isTotalInvoiceAmountVisible) {
            totalWidth += this.totalInvoiceAmount;
        }
        if (this.isTotalBillAmountVisible) {
            totalWidth += this.totalBillAmount;
        }
        if (this.isPaymentMadeVisible) {
            totalWidth += this.paymentMade;
        }
        if (this.isCurrentDueAmountVisible) {
            totalWidth += this.currentDueAmount;
        }
        if (this.isCurrentBalanceVisible) {
            totalWidth += this.currentBalance;
        }
        if (this.isPaymentMediumVisible) {
            totalWidth += this.paymentMedium;
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isSnNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isInvoiceNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBillNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isTotalInvoiceAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isTotalBillAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isPaymentMadeVisible) {
            totalSelectedElement += 1
        }
        if (this.isCurrentDueAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isCurrentBalanceVisible) {
            totalSelectedElement += 1
        }
        if (this.isPaymentMediumVisible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isSnNumberVisible) {
            this.snNumber = this.originalSnNumber + additionalWidth
        }
        if (this.isInvoiceNumberVisible) {
            this.invoiceNumber = this.originalInvoiceNumber + additionalWidth
        }
        if (this.isBillNumberVisible) {
            this.billNumber = this.originalBillNumber + additionalWidth
        }
        if (this.isTotalInvoiceAmountVisible) {
            this.totalInvoiceAmount = this.originalTotalInvoiceAmount + additionalWidth
        }
        if (this.isTotalBillAmountVisible) {
            this.totalBillAmount = this.originalTotalBillAmount + additionalWidth
        }
        if (this.isPaymentMadeVisible) {
            this.paymentMade = this.originalPaymentMade + additionalWidth
        }
        if (this.isCurrentDueAmountVisible) {
            this.currentDueAmount = this.originalCurrentDueAmount + additionalWidth
        }
        if (this.isCurrentBalanceVisible) {
            this.currentBalance = this.originalCurrentBalance + additionalWidth
        }
        if (this.isPaymentMediumVisible) {
            this.paymentMedium = this.originalPaymentMedium + additionalWidth
        }
    }
}