import { FONT_SIZE, DocumentSectionType } from "../Constants/Constants"
import { PageCountFormattingType, PageCountPosition, watermarkPrintType } from "../Helper/types"
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import { HTMLTag } from "./HTMLTag"
import Utility, { getBoundingClientRect, getPageHeight, getPageWidth, getPX } from "./Utility"
import WatermarkUtility from "./WatermarkUtility"

export default class MultiPageUtility {
    static isCompleted = false
    static additionalTablePrefix = ''

    static renderForPrint(templateToShow, printInfo, printScale) {
        this.isCompleted = false
        this.renderMultiPageWithRepeatableHeaderFooter(templateToShow, printInfo, undefined, printScale)
        var watermarkConfiguration = WatermarkUtility.getWatermarkConfiguration()
        if(watermarkConfiguration !== undefined && watermarkConfiguration !== null) {
            if (watermarkConfiguration.isVisible) {
                this.renderWatermark(printInfo)
            }
        }
    }

    static renderMultiPageWithRepeatableHeaderFooter(templateToShow, printInfo, template, printScale) {
        if (TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() 
            && printInfo !== undefined 
            && templateToShow !== undefined
            && !Utility.getIsProductGroupingWithMultiTable()) {
            var dimensions = this.getMultiPageDimensions(templateToShow, template)
            const pageCountSettings = TemplateSettingsManager.getPageCountSettings()
            if (TemplateSettingsManager.getRepeatableFooterIsVisible()) {
                this.getPageTemplate(dimensions, printScale, pageCountSettings)
                return dimensions
            }
            else {
                if (dimensions.multi) {
                    this.getPageTemplateWithFooter(dimensions, printScale, pageCountSettings)
                }
                return dimensions
            }
        }
        return
    }

    static getMultiPageDimensions(data, template) {
        //calculate the page height n compare to default page size
        var dimensions = {}
        dimensions.multi = false
        dimensions.documentData = data
        dimensions.paperFormat = TemplateSettingsManager.pageFormat
        dimensions.offsetPadding = 0
        dimensions.offsetPadding = getPX('9.26672vw') // refer to app.css margin top and bottom

        var element = template !== undefined ? template.template.getBoundingClientRect() : getBoundingClientRect(HTMLTag.TEMPLATE)
        var height = 0
        var width = 0
        if (element) {
            height = element.height
            width = element.width
        }
        dimensions.currentPageHeight = height
        dimensions.currentPageWidth = width
        dimensions.documentTemplate = template

        var pageWidth = getPX(getPageWidth())
        var pageHeight = getPX(getPageHeight())

        dimensions.defaultPageHeight = pageHeight
        dimensions.defaultPageWidth = pageWidth

        var ratio = pageWidth / width
        var expectedHeight = height * ratio
        var expectedWidth = width * ratio

        dimensions.computeHeight = expectedHeight
        dimensions.computeWidth = expectedWidth
        dimensions.ratio = ratio

        //check if multi page needed
        var differentWidth = Math.abs(pageWidth - expectedWidth)
        dimensions.differentWidth = differentWidth
        dimensions.additionalOffset = 0

        var differentHeight = Math.abs(pageHeight - expectedHeight)
        dimensions.differentHeight = differentHeight
        if (differentHeight > 2) {
            dimensions.multi = true
            dimensions = this.getAllTermsAndNotesDimensions(dimensions, data)
            dimensions = this.getExchangeRateTableDimensions(dimensions, data)
            dimensions = this.getSignatureDimensions(dimensions, data)
            dimensions = this.getBottomCustomFieldTableDimensions(dimensions, data)
            dimensions = this.getBottomEInvoiceDimensions(dimensions, data)
            dimensions = this.getApprovalDimensions(dimensions, data)
            dimensions = this.getRemarksDimensions(dimensions, data)
            dimensions = this.calculateAdditionalOffsetPadding(dimensions)
            dimensions = this.getDataDimensions(dimensions, data)
            dimensions = this.getFooterDimensions(dimensions, data)
            dimensions = this.getAdditionalTableDimensions(dimensions, data)
            dimensions = this.computeLineItemForMultiPage(dimensions)
            dimensions = this.computeLineItemForMultiPageWithFooter(dimensions)
            // dimensions = this.getPageTemplate(dimensions)
            // dimensions = this.getPageTemplateWithFooter(dimensions)
            // dimensions.pageTemplates.forEach(element => {
            //     // document.append(element)
            //     // document.body.appendChild(element.cloneNode(true));
            // });
        }
        else {
            console.log('single page')
        }
        return dimensions
    }

    static computeLineItemForMultiPage(dimensions) {
        if (!dimensions.multi) {
            return dimensions
        }

        //calculate offset for the table
        dimensions.offsetHeight = dimensions.computeHeight - dimensions.table.computeHeight
        dimensions.offsetWidth = dimensions.computeWidth - dimensions.defaultPageWidth

        //split line items
        var totalOffsetHeight = dimensions.offsetHeight + dimensions.table.computeHeaderHeight
        dimensions.remainingHeight = dimensions.defaultPageHeight - totalOffsetHeight - dimensions.offsetPadding + dimensions.additionalOffset

        var pages = []
        var includedRow = []
        var currentRow = 0

        for (let index = 0; index < dimensions.table.itemDimension.length; index++) {
            var rows = []
            var remaining = dimensions.remainingHeight
            if (!includedRow.includes(index)) {
                var isRowCompleted = false
                for (let colIndex = index; colIndex < dimensions.table.itemDimension.length; colIndex++) {
                    var row = dimensions.table.itemDimension[colIndex]
                    if (remaining > row.computeHeight && !isRowCompleted) {
                        includedRow.push(colIndex)
                        rows.push(colIndex)
                        remaining = remaining - row.computeHeight
                        currentRow = currentRow + 1
                    }
                    else {
                        isRowCompleted = true
                    }
                }
                if (rows.length === 0) {
                    rows.push(currentRow)
                    currentRow = currentRow + 1
                }
                pages.push(rows)
                rows = []
            }
        }
        dimensions.pages = pages
        return dimensions
    }

    static getPageTemplate(dimensions, printScale, pageCountSettings) {
        var pageTemplates = []
        var type = dimensions.documentData.type
        var lastTotalRowIndex = dimensions.table.itemDimension.length
        var dataRowTag = HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + type + '_' + this.additionalTablePrefix + '_' + DocumentSectionType.data + '_'
        let isEnablePageCount = pageCountSettings?.enable ?? false
        let pageCountPosition = this.getPageCountPosition(pageCountSettings)
        dimensions.pages.forEach((element, index) => {
            var template = document.getElementById(HTMLTag.TEMPLATE).cloneNode(true);
            if(dimensions.documentTemplate !== undefined) {
                template = dimensions.documentTemplate.template.cloneNode(true)
            }
            if (isEnablePageCount && pageCountPosition === PageCountPosition.top) {
                if (template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`) !== undefined &&
                    template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`) !== null) {
                    let pageCountTop = this.getPageCount(index, dimensions.pages.length, printScale, pageCountSettings)
                    template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`).appendChild(pageCountTop)
                }
            }
            
            for (let index = 0; index < dimensions.table.itemDimension.length; index++) {
                if (!element.includes(index)) {
                    // console.log('----')
                    // console.log(template.querySelector(`#${CSS.escape(HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + index)}`))
                    if (template.querySelector(`#${CSS.escape(dataRowTag + index)}`) !== undefined &&
                        template.querySelector(`#${CSS.escape(dataRowTag + index)}`) !== null) {
                        template.querySelector(`#${CSS.escape(dataRowTag + index)}`).style.display = 'none';
                    }
                }

                if (template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`) !== undefined &&
                    template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`) !== null) {
                    template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`).style.display = 'none';
                }
            }
            template = this.removeHiddenElement(template, dimensions)
            if (isEnablePageCount && pageCountPosition === PageCountPosition.bottom) {
                template.appendChild(this.getPageCount(index, dimensions.pages.length, printScale, pageCountSettings));
            }
            pageTemplates.push(template)
        });

        if(!this.isCompleted && dimensions.documentTemplate === undefined) {
            document.getElementById(HTMLTag.MAIN_TEMPLATE).style.backgroundColor = 'white';
            pageTemplates.forEach(element => {
                document.getElementById(HTMLTag.MAIN_TEMPLATE).appendChild(element);
            });

            let templateListNodes = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
            var isFirstTemplate = false
            templateListNodes.forEach((element) => {
                if(element.id === HTMLTag.TEMPLATE) {
                    element.classList.add('break-inside-avoid');
                }
                if (element.id === HTMLTag.TEMPLATE && !isFirstTemplate) {
                    element.style.display = 'none'
                    isFirstTemplate = true
                }
            });

            this.isCompleted = true
        }

        dimensions.pageTemplates = pageTemplates
        return dimensions
    }

    static getPageCount(index, totalPage, printScale, pageCountSettings) {
        //add last node page count for template
        let currentPage = index + 1
        let pageCount = document.createElement('div')
        let fontStyle = Utility.getFontStyleProp({ fontSize: FONT_SIZE.small }, printScale)
        pageCount.innerHTML = getLocalisedText('page') + ' ' + currentPage + ' / ' + totalPage
        pageCount.className = 'RowReverseDiv pt-s'
        if (fontStyle['font-family'] !== undefined) {
            pageCount.style.fontFamily = fontStyle['font-family']
        }
        if (fontStyle['font-weight'] !== undefined) {
            pageCount.style.fontWeight = fontStyle['font-weight']
        }
        if (fontStyle['font-style'] !== undefined) {
            pageCount.style.fontStyle = fontStyle['font-style']
        }
        

        if(pageCountSettings) {
            pageCount.style.justifyContent = this.getPageCountAlign(pageCountSettings)
            let innerHTML = this.getPageCountTextFormat(pageCountSettings, currentPage, totalPage)
            if(innerHTML !== '') {
                pageCount.innerHTML = innerHTML
            }
        }

        pageCount.style.fontSize = fontStyle.fontSize

        return pageCount
    }

    static getPageCountAlign(pageCountSettings) {
        let textAlign = undefined
        const position = pageCountSettings?.position ?? undefined
        if(position) {
            switch (position) {
                case PageCountPosition.topLeft:
                    textAlign = 'left'
                    break;
                case PageCountPosition.topRight:
                    textAlign = 'right'
                    break;
                case PageCountPosition.topMiddle:
                    textAlign = 'center'
                    break;
                case PageCountPosition.bottomLeft:
                    textAlign = 'left'
                    break;
                case PageCountPosition.bottomRight:
                    textAlign = 'right'
                    break;
                case PageCountPosition.bottomMiddle:
                    textAlign = 'center'
                    break;
                default:
                    break;
            }
        }
        return textAlign
    }

    static getPageCountPosition(pageCountSettings) {
        const position = pageCountSettings?.position ?? undefined
        let newPosition = PageCountPosition.bottom
        if (position) {
            switch (position) {
                case PageCountPosition.topLeft:
                case PageCountPosition.topRight:
                case PageCountPosition.topMiddle:
                    newPosition = PageCountPosition.top
                    break;
                case PageCountPosition.bottomLeft:
                case PageCountPosition.bottomRight:
                case PageCountPosition.bottomMiddle:
                    newPosition = PageCountPosition.bottom
                    break;
                default:
                    break;
            }
        }
        return newPosition
    }

    static getPageCountTextFormat(pageCountSettings, currentPage, maxPage) {
        let formattedString = ''
        const format = pageCountSettings?.formatting  ?? undefined
        if(format) {
            switch (format) {
                case PageCountFormattingType.singleDigit:
                    formattedString = currentPage
                    break;
                case PageCountFormattingType.pageWithNumeric:
                    formattedString =  getLocalisedText('page') + ' ' + currentPage
                    break;
                case PageCountFormattingType.pageOfTotalPage:
                    formattedString = getLocalisedText('page') + ' ' + currentPage + ' of ' + maxPage + '<br/>'
                    break;
                case PageCountFormattingType.pageOfTotalPageSlash:
                    formattedString = getLocalisedText('page') + ' ' + currentPage + ' / ' + maxPage + '<br/>'
                    break;
                default:
                    break;
            }
        }
        
        return formattedString
    }


    static getDataDimensions(dimensions, data) {
        var type = data.type ? data.type : ''
        var tableDimensions = {}

        var tableElement = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.TABLE_PREFIX + type + '_' + this.additionalTablePrefix)
        if (tableElement) {
            tableDimensions.width = tableElement.width
            tableDimensions.height = tableElement.height
            tableDimensions.computeWidth = tableElement.width * dimensions.ratio
            tableDimensions.computeHeight = tableElement.height * dimensions.ratio
        }

        var tableHeaderElement = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.TABLE_HEADER_PREFIX + type + '_' + this.additionalTablePrefix)
        if (tableHeaderElement) {
            tableDimensions.headerWidth = tableHeaderElement.width
            tableDimensions.headerHeight = tableHeaderElement.height
            tableDimensions.computeHeaderWidth = tableHeaderElement.width * dimensions.ratio
            tableDimensions.computeHeaderHeight = tableHeaderElement.height * dimensions.ratio
        }

        if (data.lineItems !== undefined) {
            var length = data.lineItems.length
            var itemDimension = []
            for (let index = 0; index < length; index++) {
                var itemElement = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + type + '_' + this.additionalTablePrefix + '_' + DocumentSectionType.data + '_' + index)
                if (itemElement) {
                    var item = {}
                    item.width = itemElement.width
                    item.height = itemElement.height
                    item.computeWidth = itemElement.width * dimensions.ratio
                    item.computeHeight = itemElement.height * dimensions.ratio
                    item.index = index
                    item.id = HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + type +  + '_' + this.additionalTablePrefix + '_' +  DocumentSectionType.data + '_' + index
                    itemDimension.push(item)
                }
            }
            tableDimensions.itemDimension = itemDimension
        }
        dimensions.table = tableDimensions
        return dimensions
    }

    static getFooterDimensions(dimensions, data) {
        var type = data.type ? data.type : ''
        let footerTag = HTMLTag.TABLE_FOOTER_PREFIX + type + '_' + this.additionalTablePrefix

        var footerTableDimensions = {}
        var footerTable = this.getTemplateRect(dimensions.documentTemplate, footerTag)

        if (footerTable) {
            footerTableDimensions.headerWidth = footerTable.width
            footerTableDimensions.headerHeight = footerTable.height
            footerTableDimensions.computeWidth = footerTable.width * dimensions.ratio
            footerTableDimensions.computeHeight = footerTable.height * dimensions.ratio
        }

        let footerListNodes = document.getElementById(footerTag).childNodes
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            footerListNodes = this.getTemplateElement(dimensions.documentTemplate, footerTag).childNodes
        }
        var footerItemDimension = []
        footerListNodes.forEach((element, index) => {
            var itemElement = this.getTemplateRect(dimensions.documentTemplate, element.id)
            if (itemElement) {
                var item = {}
                item.width = itemElement.width
                item.height = itemElement.height
                item.computeWidth = itemElement.width * dimensions.ratio
                item.computeHeight = itemElement.height * dimensions.ratio
                item.index = index
                item.id = element.id
                footerItemDimension.push(item)
            }
        });
        footerTableDimensions.itemDimension = footerItemDimension
        dimensions.footerTable = footerTableDimensions
        return dimensions
    }

    static getAdditionalTableDimensions(dimensions, data) {
        var type = data.type ? data.type : ''
        let additionalTableTag = HTMLTag.SUB_TABLE_PREFIX + type + '_' +  this.additionalTablePrefix

        var additionalTableDimensions = {}
        var additionalTable = this.getTemplateRect(dimensions.documentTemplate, additionalTableTag)

        if (additionalTable) {
            additionalTableDimensions.headerWidth = additionalTable.width
            additionalTableDimensions.headerHeight = additionalTable.height
            additionalTableDimensions.computeWidth = additionalTable.width * dimensions.ratio
            additionalTableDimensions.computeHeight = additionalTable.height * dimensions.ratio
        }

        dimensions.additionalTable = additionalTableDimensions
        return dimensions
    }

    static computeLineItemForMultiPageWithFooter(dimensions) {
        if (!dimensions.multi) {
            return dimensions
        }

        var maxFooterHeight = dimensions.footerTable.computeHeight

        if (dimensions.additionalTable !== undefined && dimensions.additionalTable !== null) {
            if (dimensions.additionalTable.computeHeight !== undefined && dimensions.additionalTable.computeHeight !== null) {
                if (dimensions.additionalTable.computeHeight > maxFooterHeight) {
                    maxFooterHeight = dimensions.additionalTable.computeHeight
                }
            }
        }
        dimensions.maxFooterHeight = maxFooterHeight
        //calculate offset for the table
        dimensions.offsetHeight = dimensions.computeHeight - dimensions.table.computeHeight
        dimensions.offsetWidth = dimensions.computeWidth - dimensions.defaultPageWidth

        //split line items
        var totalOffsetHeight = dimensions.offsetHeight + dimensions.table.computeHeaderHeight
        dimensions.remainingHeight = dimensions.defaultPageHeight - totalOffsetHeight - dimensions.offsetPadding + dimensions.additionalOffset

        //calculate offset for the table
        dimensions.offsetHeightWithFooter = dimensions.computeHeight - dimensions.table.computeHeight - maxFooterHeight

        //split line items
        dimensions.remainingHeightWithFooter = dimensions.defaultPageHeight - dimensions.offsetHeightWithFooter - dimensions.offsetPadding + dimensions.additionalOffset

        var pages = []
        var includedRow = []
        var currentRow = 0

        for (let index = 0; index < dimensions.table.itemDimension.length; index++) {
            var rows = []
            var remaining = dimensions.remainingHeightWithFooter
            if (!includedRow.includes(index)) {
                var isRowCompleted = false
                for (let colIndex = index; colIndex < dimensions.table.itemDimension.length; colIndex++) {
                    var row = dimensions.table.itemDimension[colIndex]
                    if (remaining > row.computeHeight && !isRowCompleted) {
                        includedRow.push(colIndex)
                        rows.push(colIndex)
                        remaining = remaining - row.computeHeight
                        currentRow = currentRow + 1
                    }
                    else {
                        isRowCompleted = true
                    }
                }
                if (rows.length === 0) {
                    rows.push(currentRow)
                    currentRow = currentRow + 1
                }
                pages.push(rows)
                rows = []
            }
        }

        //process to determine last page
        if(pages.length > 0) {
            var lastPage = pages[pages.length -1]
            var totalPageHeight = 0
            // lastPage = [7,8,9]
            lastPage.forEach(element => {
                var item = dimensions.table.itemDimension.find(x => x.index === element)
                if(item !== undefined) {
                    totalPageHeight = totalPageHeight + item.computeHeight
                }
            });
            //totalPageHeight > dimensions.remainingHeight
            if (totalPageHeight + maxFooterHeight > dimensions.remainingHeightWithFooter) {
                //split to additional page for footer and header
                var footerPages = []
                var total = 0
                var isCompleted = false
                lastPage.forEach(element => {
                    if(!isCompleted) {
                        var item = dimensions.table.itemDimension.find(x => x.index === element)
                        if (item !== undefined) {
                            total = total + item.computeHeight
                        }

                        if (total > totalOffsetHeight / 2) {
                            isCompleted = true
                        }
                        else {
                            footerPages.push(element)
                        }
                    }
                });

                var newPage = []
                pages.forEach((element, index) => {
                    if(index !== pages.length - 1) {
                        newPage.push(element)
                    }
                });

                newPage.push(footerPages)
                newPage.push(lastPage.filter(x => !footerPages.includes(x)))

                pages = newPage
            }
        }
        dimensions.pagesWithFooter = pages
        return dimensions
    }

    static getPageTemplateWithFooter(dimensions, printScale, pageCountSettings) {
        var pageTemplates = []
        var type = dimensions.documentData.type
        var lastTotalRowIndex = dimensions.table.itemDimension.length
        var dataRowTag = HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + type + '_' + this.additionalTablePrefix + '_' +  DocumentSectionType.data  + '_'
        let isEnablePageCount = pageCountSettings?.enable ?? false
        let pageCountPosition = this.getPageCountPosition(pageCountSettings)
        // var footerRowTag = HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + type + '_' + DocumentSectionType.footer + '_'
        dimensions.pagesWithFooter.forEach((element, index) => {
            var template = document.getElementById(HTMLTag.TEMPLATE).cloneNode(true);
            if (dimensions.documentTemplate !== undefined) {
                template = dimensions.documentTemplate.template.cloneNode(true)
            }

            if (!WatermarkUtility.getIsContainsWatermark() && isEnablePageCount && pageCountPosition === PageCountPosition.top) {
                if (template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`) !== undefined &&
                    template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`) !== null) {
                    let pageCountTop = this.getPageCount(index, dimensions.pagesWithFooter.length, printScale, pageCountSettings);
                    template.querySelector(`#${CSS.escape(HTMLTag.PAGE_COUNT_TOP)}`).appendChild(pageCountTop)
                }
            }
            for (let index = 0; index < dimensions.table.itemDimension.length; index++) {
                if (!element.includes(index)) {
                    // console.log('----')
                    // console.log(template.querySelector(`#${CSS.escape(HTMLTag.TABLE_DATA_ROW_INDEX_PREFIX + index)}`))
                    if (template.querySelector(`#${CSS.escape(dataRowTag + index)}`) !== undefined &&
                        template.querySelector(`#${CSS.escape(dataRowTag + index)}`) !== null) {
                        template.querySelector(`#${CSS.escape(dataRowTag + index)}`).style.display = 'none';
                    }
                }

                if (template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`) !== undefined &&
                    template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`) !== null) {
                    template.querySelector(`#${CSS.escape(dataRowTag + lastTotalRowIndex)}`).style.display = 'none';
                }
            }


            if(index !== dimensions.pagesWithFooter.length - 1) {
                 //remove footer
                dimensions.footerTable.itemDimension.forEach(element => {
                    if (template.querySelector(`#${CSS.escape(element.id)}`) !== undefined &&
                        template.querySelector(`#${CSS.escape(element.id)}`) !== null) {
                        template.querySelector(`#${CSS.escape(element.id)}`).style.display = 'none';
                    }
                });

                //remove additional table
                let additionalTableTag = HTMLTag.SUB_TABLE_PREFIX + type + '_' + this.additionalTablePrefix
                if (template.querySelector(`#${CSS.escape(additionalTableTag)}`) !== undefined &&
                    template.querySelector(`#${CSS.escape(additionalTableTag)}`) !== null) {
                    template.querySelector(`#${CSS.escape(additionalTableTag)}`).style.display = 'none';
                }
            }

            template = this.removeHiddenElement(template, dimensions)

            if (!WatermarkUtility.getIsContainsWatermark() && isEnablePageCount && pageCountPosition === PageCountPosition.bottom) {
                template.appendChild(this.getPageCount(index, dimensions.pagesWithFooter.length, printScale, pageCountSettings));
            }
            pageTemplates.push(template)
        });

        if (!this.isCompleted && dimensions.documentTemplate === undefined) {
            document.getElementById(HTMLTag.MAIN_TEMPLATE).style.backgroundColor = 'white';
            pageTemplates.forEach(element => {
                document.getElementById(HTMLTag.MAIN_TEMPLATE).appendChild(element);
            });

            let templateListNodes = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
            var isFirstTemplate = false
            templateListNodes.forEach((element) => {
                if (element.id === HTMLTag.TEMPLATE) {
                    element.classList.add('break-inside-avoid');
                }
                if (element.id === HTMLTag.TEMPLATE && !isFirstTemplate) {
                    element.style.display = 'none'
                    isFirstTemplate = true
                }
            });

            this.isCompleted = true
        }

        dimensions.pageTemplatesWithFooter = pageTemplates
        if(dimensions.documentTemplate !== undefined) {
            dimensions.pageTemplates = pageTemplates
        }
        return dimensions
    }


    static getAllTermsAndNotesDimensions(dimensions, data) {
        dimensions.isHideTerms = false
        dimensions.isHideNotes = false
        dimensions.termsAndNotesOffset = false
        if(!Utility.getIsShowTermAndCondition(data) && !Utility.getIsShowNotes(data)) {
            dimensions = this.getTermsAndNotesDimensions(dimensions)
            if (dimensions.termsAndNoteDimensions === undefined || dimensions.termsAndNoteDimensions === null) {
                dimensions = this.getTermsDimensions(dimensions)
                dimensions = this.getNotesDimensions(dimensions)
            }
        }
        else if (!Utility.getIsShowTermAndCondition(data)) {
            dimensions = this.getTermsDimensions(dimensions)
        }
        else if (!Utility.getIsShowNotes(data)) {
            dimensions = this.getNotesDimensions(dimensions)
        }
        return dimensions

    }

    static getTermsAndNotesDimensions(dimensions) {
        var termsAndNote = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.TERMS_NOTE_PREFIX)
        if (termsAndNote) {
            var termsAndNoteDimensions = {}
            termsAndNoteDimensions.headerWidth = termsAndNote.width
            termsAndNoteDimensions.headerHeight = termsAndNote.height
            termsAndNoteDimensions.computeWidth = termsAndNote.width * dimensions.ratio
            termsAndNoteDimensions.computeHeight = termsAndNote.height * dimensions.ratio
            dimensions.termsAndNoteDimensions = termsAndNoteDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + termsAndNoteDimensions.computeHeight
            dimensions.isHideTerms = true
            dimensions.isHideNotes = true
        }
        return dimensions
    }

    static getTermsDimensions(dimensions) {
        var terms = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.TERMS)
        if (terms) {
            var termsDimensions = {}
            termsDimensions.headerWidth = terms.width
            termsDimensions.headerHeight = terms.height
            termsDimensions.computeWidth = terms.width * dimensions.ratio
            termsDimensions.computeHeight = terms.height * dimensions.ratio
            dimensions.termsDimensions = termsDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + termsDimensions.computeHeight
            dimensions.isHideTerms = true
        }
        return dimensions
    }

    static getNotesDimensions(dimensions) {
        var notes = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.NOTES)
        if (notes) {
            var notesDimensions = {}
            notesDimensions.headerWidth = notes.width
            notesDimensions.headerHeight = notes.height
            notesDimensions.computeWidth = notes.width * dimensions.ratio
            notesDimensions.computeHeight = notes.height * dimensions.ratio
            dimensions.notesDimensions = notesDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + notesDimensions.computeHeight
            dimensions.isHideNotes = true
        }
        return dimensions
    }

    static getSignatureDimensions(dimensions, data) {
        dimensions.isHideSignature = false
        if (!Utility.getIsShowSignature(data)) {
            var signatureDimensions = {}
            var signature = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.SIGNATURE)
            if (signature) {
                signatureDimensions.headerWidth = signature.width
                signatureDimensions.headerHeight = signature.height
                signatureDimensions.computeWidth = signature.width * dimensions.ratio
                signatureDimensions.computeHeight = signature.height * dimensions.ratio
                dimensions.additionalOffset = dimensions.additionalOffset + signatureDimensions.computeHeight
            }
            dimensions.signatureDimensions = signatureDimensions
            dimensions.isHideSignature = true
        }
        return dimensions
    }

    static getExchangeRateTableDimensions(dimensions, data) {
        if (!data.showExchangeRate) {
            var exchangeRateTableDimensions = {}
            var exchangeRateTable = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.EXCHANGE_RATE)
    
            if (exchangeRateTable) {
                exchangeRateTableDimensions.headerWidth = exchangeRateTable.width
                exchangeRateTableDimensions.headerHeight = exchangeRateTable.height
                exchangeRateTableDimensions.computeWidth = exchangeRateTable.width * dimensions.ratio
                exchangeRateTableDimensions.computeHeight = exchangeRateTable.height * dimensions.ratio
                dimensions.additionalOffset = dimensions.additionalOffset + exchangeRateTableDimensions.computeHeight
            }
    
            dimensions.exchangeRateTableDimensions = exchangeRateTableDimensions
        }
        return dimensions
    }

    static getBottomCustomFieldTableDimensions(dimensions) {
        var bottomCustomFieldTable = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.CUSTOM_FIELD_TABLE_PREFIX + 'bottom')
        if (bottomCustomFieldTable) {
            var bottomCustomFieldTableDimensions = {}
            bottomCustomFieldTableDimensions.headerWidth = bottomCustomFieldTable.width
            bottomCustomFieldTableDimensions.headerHeight = bottomCustomFieldTable.height
            bottomCustomFieldTableDimensions.computeWidth = bottomCustomFieldTable.width * dimensions.ratio
            bottomCustomFieldTableDimensions.computeHeight = bottomCustomFieldTable.height * dimensions.ratio
            dimensions.bottomCustomFieldTableDimensions = bottomCustomFieldTableDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + bottomCustomFieldTableDimensions.computeHeight
        }
        return dimensions
    }

    static getBottomEInvoiceDimensions(dimensions) {
        var eInvoice = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.E_INVOICE_PREFIX + 'bottom')
        if (eInvoice) {
            var eInvoiceDimensions = {}
            eInvoiceDimensions.headerWidth = eInvoice.width
            eInvoiceDimensions.headerHeight = eInvoice.height
            eInvoiceDimensions.computeWidth = eInvoice.width * dimensions.ratio
            eInvoiceDimensions.computeHeight = eInvoice.height * dimensions.ratio
            dimensions.eInvoiceDimensions = eInvoiceDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + eInvoiceDimensions.computeHeight
        }
        return dimensions
    }

    static getApprovalDimensions(dimensions) {
        var approval = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.APPROVAL_TABLE)
        if (approval) {
            var approvalDimensions = {}
            approvalDimensions.headerWidth = approval.width
            approvalDimensions.headerHeight = approval.height
            approvalDimensions.computeWidth = approval.width * dimensions.ratio
            approvalDimensions.computeHeight = approval.height * dimensions.ratio
            dimensions.approvalDimensions = approvalDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + approvalDimensions.computeHeight
        }
        return dimensions
    }

    static getRemarksDimensions(dimensions) {
        var remarks = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.REMARKS)
        if (remarks) {
            var remarksDimensions = {}
            remarksDimensions.headerWidth = remarks.width
            remarksDimensions.headerHeight = remarks.height
            remarksDimensions.computeWidth = remarks.width * dimensions.ratio
            remarksDimensions.computeHeight = remarks.height * dimensions.ratio
            dimensions.approvalDimensions = remarksDimensions
            dimensions.additionalOffset = dimensions.additionalOffset + remarksDimensions.computeHeight
        }
        return dimensions
    }


    static removeHiddenElement(template, dimensions) {
        var hiddenElementList = []
        if (dimensions.signatureDimensions !== undefined && dimensions.signatureDimensions !== null) {
            hiddenElementList.push(HTMLTag.SIGNATURE)
        }
        if (dimensions.termsAndNoteDimensions !== undefined && dimensions.termsAndNoteDimensions !== null) {
            hiddenElementList.push(HTMLTag.TERMS_NOTE_PREFIX)
        }
        if (dimensions.termsDimensions !== undefined && dimensions.termsDimensions !== null) {
            hiddenElementList.push(HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.TERMS)
        }
        if (dimensions.notesDimensions !== undefined && dimensions.notesDimensions !== null) {
            hiddenElementList.push(HTMLTag.TERMS_NOTE_PREFIX + HTMLTag.NOTES)
        }
        if (dimensions.exchangeRateTableDimensions !== undefined && dimensions.exchangeRateTableDimensions !== null) {
            hiddenElementList.push(HTMLTag.EXCHANGE_RATE)
        }
        hiddenElementList.forEach(element => {
            if (template.querySelector(`#${CSS.escape(element)}`) !== undefined &&
                template.querySelector(`#${CSS.escape(element)}`) !== null) {
                template.querySelector(`#${CSS.escape(element)}`).style.display = 'none';
            }
        });

        return template
    }

    static calculateAdditionalOffsetPadding(dimensions) {
        if(!dimensions.isHideTerms) {
            dimensions.offsetPadding = dimensions.offsetPadding + getPX('4vw')
        }
        if(!dimensions.isHideNotes) {
            dimensions.offsetPadding = dimensions.offsetPadding + getPX('4vw')
        }
        dimensions.pageCountPadding = getPX(FONT_SIZE.small) + 8
        dimensions.watermarkHeight = this.getWatermarkHeight(dimensions)
        return dimensions
    }

    static getWatermarkHeight(dimensions) {
        var height = 0
        var watermarkText = this.getTemplateRect(dimensions.documentTemplate, HTMLTag.WATERMARK_TEXT)
        if (watermarkText) {
            height = watermarkText.height
        }
        return height
    }

    static renderWatermark(printInfo) {
        if (printInfo !== undefined) {
            if (printInfo.wmark === watermarkPrintType.all) {
                var pageTemplates = []
                var watermarkList = [watermarkPrintType.original, watermarkPrintType.duplicate, watermarkPrintType.triplicate, watermarkPrintType.quadruplicate]
                if (printInfo.mutliWmark !== undefined) {
                    watermarkList = printInfo.mutliWmark
                }
                if (TemplateSettingsManager.getRepeatableHeaderFooterIsVisible()) {
                    // get all the templates within a doc
                    var newWatermarkPlacementTemplates = []
                    var watermarkPlacementTemplates = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
                    if (watermarkPlacementTemplates.length > 0) {
                        watermarkPlacementTemplates.forEach(element => {
                            if(element.id === HTMLTag.TEMPLATE) {
                                if(element.style.display !== 'none') {
                                    newWatermarkPlacementTemplates.push(element)
                                }
                            }
                        });
                    }

                    watermarkList.forEach(element => {
                        var text = WatermarkUtility.getWatermarkValueByType(element)
                        newWatermarkPlacementTemplates.forEach(template => {
                            var newTemplate = template.cloneNode(true);
                            newTemplate = MultiPageUtility.replaceWatermarkText(newTemplate, text)
                            pageTemplates.push(newTemplate)
                        });
                    });
                }
                else {
                    //get the template
                    watermarkList.forEach(element => {
                        var text = WatermarkUtility.getWatermarkValueByType(element)
                        var template = document.getElementById(HTMLTag.TEMPLATE).cloneNode(true);
                        template = MultiPageUtility.replaceWatermarkText(template, text)
                        pageTemplates.push(template)
                    });
                }


                this.isCompleted = false
                if (!this.isCompleted) {
                    document.getElementById(HTMLTag.MAIN_TEMPLATE).style.backgroundColor = 'white';
                    pageTemplates.forEach(element => {
                        document.getElementById(HTMLTag.MAIN_TEMPLATE).appendChild(element);
                    });
                    let templateListNodes = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
                    templateListNodes.forEach((element) => {
                        if (element.id === HTMLTag.TEMPLATE) {
                            element.classList.add('break-inside-avoid');
                        }
                        if (element.id === HTMLTag.TEMPLATE && this.getIsDefaultWatermarkText(element)) {
                            element.style.display = 'none'
                        }
                    });

                    this.isCompleted = true
                }
            }
        }
    }


    static replaceWatermarkText(template, text) {
        if (template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`) !== undefined &&
            template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`) !== null) {
            template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`).textContent = getLocalisedText(text)
        }
        return template
    }

    static getIsDefaultWatermarkText(template) {
        if (template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`) !== undefined &&
            template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`) !== null) {
            if (template.querySelector(`#${CSS.escape(HTMLTag.WATERMARK_TEXT)}`).textContent === 'WATERMARK_PLACEMENT') {
                return true
            }
        }
        return false
    }

    static getTemplateElement(template, id) {
        if (template !== undefined && template !== null) {
            if (template.template !== undefined && template.template !== null) {
                if (template.template.querySelector(`#${CSS.escape(id)}`) !== undefined &&
                    template.template.querySelector(`#${CSS.escape(id)}`) !== null) {
                    return template.template.querySelector(`#${CSS.escape(id)}`)
                }
            }
        }
        return undefined
    }


    static getTemplateRect(template, id) {
        if (TemplateSettingsManager.getIsMultipleDocument()) {
            var element = this.getTemplateElement(template, id)
            if (element !== undefined && element !== null) {
                return element.getBoundingClientRect()
            }
        }
        return getBoundingClientRect(id)
    }

    static getTemplateByDocumentIndex(index) {
        var tempHtmlTemplateList = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
        if (tempHtmlTemplateList !== undefined && tempHtmlTemplateList !== null) {
            var htmlTemplateList = []

            tempHtmlTemplateList.forEach((element) => {
                if (element.id === HTMLTag.TEMPLATE) {
                    htmlTemplateList.push(element)
                }
            });

            if(htmlTemplateList.length > index) {
                return htmlTemplateList[index]
            }
        }
    }

    static renderForMultiDocumentPrint(documentList, printInfo, printScale) {
        if (documentList.length > 0) {
            if (this.getIsShowRepeatableHeaderFooter(documentList, printInfo)) {
                var tempHtmlTemplateList = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
                var htmlTemplateList = []
                tempHtmlTemplateList.forEach(element => {
                    if (element.id === HTMLTag.TEMPLATE) {
                        htmlTemplateList.push(element)
                    }
                });
                if (htmlTemplateList.length === documentList.length) {
                    var dimensionsList = []
                    documentList.forEach((element, index) => {
                        dimensionsList.push(this.renderMultiPageWithRepeatableHeaderFooter(element, printInfo,
                            {
                                template: htmlTemplateList[index],
                                index: index
                            }), printScale)
                    });

                    //processing multi document to single array
                    var oldTemplateList = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
                    oldTemplateList.forEach(element => {
                        element.style.display = 'none'
                    });

                    var pageTemplates = []
                    dimensionsList.forEach(element => {
                        if (element && element.pageTemplates.length > 0) {
                            element.pageTemplates.forEach(page => {
                                pageTemplates.push(page)
                            });
                        }
                    });

                    document.getElementById(HTMLTag.MAIN_TEMPLATE).style.backgroundColor = 'white';
                    pageTemplates.forEach(element => {
                        if (element.id === HTMLTag.TEMPLATE) {
                            element.classList.add('break-inside-avoid');
                        }
                        document.getElementById(HTMLTag.MAIN_TEMPLATE).appendChild(element);
                    });

                }
            }
            else {
                document.getElementById(HTMLTag.MAIN_TEMPLATE).style.backgroundColor = 'white';
                var templates = document.getElementById(HTMLTag.MAIN_TEMPLATE).childNodes
                if(printInfo && printInfo.isMultiCarton) {
                    templates.forEach(element => {
                        element.classList.add('break-inside-avoid');
                    });
                } else {
                    var newTemplates = []
                    templates.forEach(element => {
                        var tempElement = element.cloneNode(true)
                        tempElement.classList.add('break-inside-avoid');
                        newTemplates.push(tempElement)
                    });
    
                    templates.forEach(element => {
                        element.style.display = 'none'
                    });
    
                    newTemplates.forEach(element => {
                        document.getElementById(HTMLTag.MAIN_TEMPLATE).appendChild(element);
                    });
                }
            }
        }

    }

    static getIsShowRepeatableHeaderFooter(templateToShow, printInfo) {
        if (TemplateSettingsManager.getRepeatableHeaderFooterIsVisible() && printInfo !== undefined && templateToShow !== undefined) {
            return true
        }
        return false
    }
}
