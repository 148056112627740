import {
  showToast,
  TOAST_TYPE,
  DKIcon,
  DKLabel,
  DKButton,
  DKInput,
  INPUT_TYPE,
  DKDataGrid,
  DKIcons,
  DKTooltipWrapper
} from 'deskera-ui-library';
import {
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE
} from '../../Constants/Constant';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import { useTranslation } from 'react-i18next';
import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import { selectBatchTrackingProduct } from '../../Redux/Slices/BatchTrackingSlice';

import { selectAdvancedTrackingData } from '../../Redux/Slices/AdvancedTrackingDataSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';
import {
  addProductCustomFieldsToLineItem,
  getLineItemCFs
} from '../CustomFieldsHolder/BatchSerialCustomFieldsHelper';

const SalesReturnBatch: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(props.itemDetails);
  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [moduleName, setModuleName] = useState(props.module);
  const productWarehouse = useAppSelector(selectBatchTrackingProduct);
  const [localWarehouse, setLocalWarehouse] = useState<any[]>(productWarehouse);
  const [availableBatchData, setAvailableBatchData] = useState<any[]>([]);
  const [advanceTrackingData, setAdvanceTrackingData] = useState<any>(
    props.advanceTrakingData
  );
  const advancedTrackingProductsData = useAppSelector(
    selectAdvancedTrackingData
  );
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState(
    props.defaultProductWarehouse
  );
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridData, setGridData] = useState<any>([
    {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: 0,
      batchSizeFulfilled: 0,
      batchSizeReturned: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    }
  ]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    item.requiredQuantity
  );
  type returnFunction = (index: number) => any;

  useEffect(() => {
    getDataGridColumns();
    let initialGridData: any = [];

    let serialData: any[] = [];
    let updatedData: any[] = [];
    let result: any[] = [];

    if (moduleName === MODULE_TYPE.SELL) {
      result = localWarehouse.find(
        (warehouse: any) => warehouse.primary
      )?.advancedTrackingMeta;
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled < serial.batchSize
      );
      if (
        props.itemDetails &&
        props.itemDetails.advancedTrackingMetaData &&
        props.itemDetails.advancedTrackingMetaData.length > 0
      ) {
        const allocatedBatch =
          props.itemDetails.advancedTrackingMetaData.forEach((a: any) => {
            let item = {
              ...a,
              warehouseCode: a.warehouseCode,
              serialBatchNumber: a.serialBatchNumber,
              manufacturingDate: a.manufacturingDate,
              expiryDate: a.expiryDate,
              batchSize: a.batchSize,
              batchSizeFulfilled: a.batchSizeFulfilled,
              invalidFields: [],
              customField: a?.customField
            };
            item = addProductCustomFieldsToLineItem(
              { ...item },
              batchSerialCFfromStore?.content
            );
            updatedData.push(item);
            //   }
          });
        initialGridData = updatedData;
        let totalItem = updatedData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
          0
        );
        setTotalAllocatedItem(totalItem);
        setAvailableBatchData(serialData);
      } else {
        // props.itemDetails.advancedTrackingMetaData.filter;
        let filteredData = advanceTrackingData.filter(
          (ele: any) => ele.productVariantCode === item.productCode
        );
        const allocatedBatch = filteredData?.forEach((e: any) => {
          let filterByDocumentCode =
            e.advancedTrackingReturned?.returnsByDoc?.filter((ele: any) =>
              // ele.documentCode === props.documentDetails.documentCode
              props.previousLinkedDoc?.find(
                (doc: any) => doc.documentCode === ele.documentCode
              )
            );
          let batchSizeReturnedQty = filterByDocumentCode
            ?.map((item: any) => Number(item.qtyReturned))
            .reduce((sum: any, returned: any) => sum + returned);

          const returnedQty = props.itemDetails.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                batchSizeReturnedQty || e.batchSizeReturned || 0,
                props.itemDetails.documentUOMSchemaDefinition
              )
            : batchSizeReturnedQty || e.batchSizeReturned || 0;

          let item = {
            ...e,
            warehouseCode: e.warehouseCode,
            serialBatchNumber: e.serialBatchNumber,
            manufacturingDate: e.manufacturingDate,
            expiryDate: e.expiryDate,
            // batchSize: e.batchSize,
            batchSize: 0,
            batchSizeFulfilled: props.itemDetails.documentUOMSchemaDefinition
              ? Utility.getUomQuantity(
                  e.batchSizeFulfilled,
                  props.itemDetails.documentUOMSchemaDefinition
                )
              : e.batchSizeFulfilled,
            batchSizeReturned: returnedQty,
            customField: e.customField,
            invalidFields: []
          };
          item = addProductCustomFieldsToLineItem(
            { ...item },
            batchSerialCFfromStore?.content
          );
          updatedData.push(item);
          // }
        });
        initialGridData = updatedData;
        let totalItem = updatedData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
          0
        );
        setTotalAllocatedItem(totalItem);
        setAvailableBatchData(serialData);
      }
    }
    setGridData((prevState: any[]) => initialGridData);
  }, []);

  useEffect(() => {
    if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
      getDataGridColumns();
    }
  }, [batchSerialCFfromStore]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: item
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const onRowUpdate = (val: any) => {
    const key = val['columnKey'];
    const lineItem = val['rowIndex'];
    const item = val['rowData'];

    let tmpData = [...gridData];

    let invalidFields: any[] = tmpData[val['rowIndex']]['invalidFields'];
    if (key === 'warehouseCode') {
      let warehouseData;
      let result;
      let batchData;
      if (moduleName === MODULE_TYPE.SELL) {
        warehouseData = productWarehouse;
        result = warehouseData.find(
          (warehouse: any) => warehouse.code === item.warehouseCode.code
        )?.advancedTrackingMeta;

        batchData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
      }
      tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
        (e) => e !== 'warehouseCode'
      );
      setAvailableBatchData(batchData);
    } else if (key === 'serialBatchNumber') {
      if (moduleName === MODULE_TYPE.SELL) {
        let duplicateData: any[] = tmpData.filter((sr: any) => {
          return (
            sr.serialBatchNumber.serialBatchNumber ===
            item.serialBatchNumber.serialBatchNumber
          );
        });
        if (duplicateData && duplicateData.length > 1) {
          tmpData[val['rowIndex']]['invalidFields'] = ['serialBatchNumber'];
        } else {
          tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
            (e) => e !== 'serialBatchNumber'
          );
        }
      }
      tmpData[val['rowIndex']]['batchSize'] =
        item.serialBatchNumber.batchSize -
        item.serialBatchNumber.batchSizeFulfilled;
      tmpData[val['rowIndex']]['manufacturingDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.manufacturingDate
          : tmpData[val['rowIndex']]['manufacturingDate'];
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : tmpData[val['rowIndex']]['expiryDate'];
      tmpData[val['rowIndex']]['editable'] = false;
    } else if (key === 'batchSizeFulfilled') {
      let totalItem = 0;
      if (moduleName === MODULE_TYPE.SELL) {
        if (
          parseFloat(item['batchSizeFulfilled']) > parseFloat(item['batchSize'])
        ) {
          invalidFields.push('batchSizeFulfilled');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter(
            (e) => e !== 'batchSizeFulfilled'
          );
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
        totalItem = gridData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
          0
        );

        setTotalAllocatedItem(totalItem);
      }
    } else if (key === 'batchSize') {
      let totalItem = 0;
      let batchSize = Utility.roundingOff(
        Number(item['batchSize']),
        QTY_ROUNDOFF_PRECISION
      );
      tmpData[lineItem]['batchSize'] = batchSize;
      if (moduleName === MODULE_TYPE.SELL) {
        let qty = Utility.roundingOff(
          parseFloat(item['batchSizeFulfilled']) -
            parseFloat(item['batchSizeReturned']),
          QTY_ROUNDOFF_PRECISION
        );
        if (batchSize > qty) {
          invalidFields.push('batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter((e) => e !== 'batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
      }

      setTotalAllocatedItem(totalItem);
    }

    setGridData(tmpData);
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        title={''}
        width={750}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        columns={gridColumns}
        rows={gridData}
      />
    );
  };

  const getDataGridColumns = () => {
    let col: any[] = [];
    if (moduleName === MODULE_TYPE.SELL) {
      col = [
        {
          key: 'serialBatchNumber',
          name: 'Batch Number',
          type: INPUT_TYPE.TEXT,
          textAlign: 'left',
          width: 170,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        },
        {
          key: 'batchSizeFulfilled',
          name: 'Fulfilled Qty',
          type: INPUT_TYPE.NUMBER,
          textAlign: 'right',
          width: 180,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          formatter: (obj: any = 0) => {
            return `${Utility.roundingOff(
              Number(obj.value),
              QTY_ROUNDOFF_PRECISION
            )}`;
          }
        },
        {
          key: 'batchSizeReturned',
          name: 'Returned Qty',
          type: INPUT_TYPE.NUMBER,
          textAlign: 'right',
          width: 180,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          formatter: (obj: any = 0) => {
            return `${Utility.roundingOff(
              Number(obj.value),
              QTY_ROUNDOFF_PRECISION
            )}`;
          }
        },
        {
          key: 'batchSize',
          name: 'Returning Qty',
          type: INPUT_TYPE.NUMBER,
          textAlign: 'right',
          width: 220,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true,
          renderer: (obj: any) => {
            if (
              obj.rowData.invalidFields &&
              obj.rowData.invalidFields.length > 0 &&
              obj.rowData.invalidFields.includes('batchSize') &&
              obj.rowData.serialBatchNumber !== ''
            ) {
              return (
                <div className="row justify-content-between">
                  <DKTooltipWrapper
                    content={
                      'Quantity should be less than or equal to fulfilled quantity (' +
                      obj.rowData.batchSizeFulfilled +
                      ')'
                    }
                    tooltipClassName=""
                  >
                    <div className="row">
                      <DKIcon
                        src={ic_warning_red}
                        className="ic-xs  ml-s cursor-hand"
                        onClick={() => {}}
                      />
                    </div>
                  </DKTooltipWrapper>

                  <DKLabel
                    text={`${Utility.roundingOff(
                      Number(obj.value),
                      QTY_ROUNDOFF_PRECISION
                    )}`}
                  />
                </div>
              );
            } else {
              return (
                <div className="row justify-content-between">
                  <div></div>
                  <DKLabel
                    text={`${Utility.roundingOff(
                      Number(obj.value),
                      QTY_ROUNDOFF_PRECISION
                    )}`}
                  />
                </div>
              );
            }
          },
          formatter: (obj: any = 0) => {
            return `${Utility.roundingOff(
              Number(obj.value),
              QTY_ROUNDOFF_PRECISION
            )}`;
          }
        }
      ];
    }

    let activeProductCustomFields = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
        return item.status === STATUS_TYPE.ACTIVE;
      });
      activeProductCustomFields = productCf.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
    }

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      newItem['editable'] = false;
      const newItemInExistingColConfig = col.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        col.push({ ...newItem });
      }
    });
    setGridColumns(col);
    return col;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Return Batch Tracked Products" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />

          <DKButton
            title={'Save'}
            className="bg-button text-white mr-r"
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const onSave = () => {
    let hasError: any;
    const batchTrackingData = gridData.map((item: any, i: any) => {
      if (item && item.invalidFields && item.invalidFields.length > 0) {
        hasError = item.invalidFields;
      }
    });
    if (hasError && hasError.length > 0 && hasError.includes('batchSize')) {
      showToast(
        'Quantity used cannot be more than the available quantity',
        TOAST_TYPE.FAILURE
      );
      return false;
    } else if (
      hasError &&
      hasError.length > 0 &&
      hasError.includes('serialBatchNumber')
    ) {
      showToast('Duplicate batch number is not allowed', TOAST_TYPE.FAILURE);
      return false;
    } else if (totalAllocatedItem > pendingQuantity) {
      showToast(
        'Quantity allocated cannot be more than the required quantity (' +
          requiredQuantity +
          ')',
        TOAST_TYPE.FAILURE
      );
      return false;
    }
    if (props.onBatchSave) {
      const batchTrackingData = gridData.map((item: any, i: any) => {
        if (moduleName === MODULE_TYPE.SELL) {
          return {
            batchSize: parseFloat(item.batchSize),
            batchSizeFulfilled: item.batchSizeFulfilled,
            batchSizeReturned: item.batchSizeReturned,
            serialBatchNumber: item.serialBatchNumber,
            // warehouseCode: item.warehouseCode.code,
            warehouseCode: item.warehouseCode,
            manufacturingDate: item.manufacturingDate,
            expiryDate: item.expiryDate,
            acquiredCost: item.acquiredCost,
            productVariantCode: item.productVariantCode,
            reservedQuantity: item.reservedQuantity,
            reservedQuantityFulfilled: item.reservedQuantityFulfilled,
            sequence: item.sequence,
            subSequence: item.subSequence,
            id: item.id,
            customFields: getLineItemCFs(
              item,
              gridColumns,
              batchSerialCFfromStore?.content
            )
          };
        }
      });
      let totalItem = batchTrackingData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSize),
        0
      );
      // let filterdData = batchTrackingData.filter(
      //   (ele: any) => Number(ele.batchSize) !== 0
      // );
      props.onBatchSave(batchTrackingData, totalItem);
    }
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-start p-h-r p-v-s mt-5">
          <div className="column w-48 ">
            <DKLabel
              text="Product Name"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.productName} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text="Product Code"
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.documentSequenceCode} className="fs-r pl-2" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 800,
          width: '80%',
          // height: "85%",
          maxHeight: '95%',
          // height: 600,
          padding: 0
          // paddingBottom: 60
        }}
      >
        {getHeader()}
        {getHeaderSection()}
        <div className="mt-l p-h-l">{getBatchTrackingGrid()}</div>
      </div>
    </div>
  );
};

export default SalesReturnBatch;
