import React, { Component } from "react";
import "../../index.css";
import { FONT_SIZE } from "../../Constants/Constants";
import { getLocalisedText } from "../../Translate/LanguageManager";
import Utility, { getPageHeight, getVW } from "../../Utilities/Utility";
import NumberUtility from "../../Utilities/NumberUtility";
import "./css/Templates.css";

export default class ChequeTemplate2 extends Component {

    totalHeight = 196.64
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'center', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%'}}>
                    {this.renderCheque()}
                    {/* {this.renderChequeContainer()} */}
                    {/* {this.renderChequeContainer()} */}
                    {/* {this.renderChequeContainer()} */}
                </div>
            </div>
        );
    }

    renderCheque() {
        if (this.state.data.lineItems === undefined || this.state.data.lineItems === null) {
            return undefined
        }

        if (this.state.data.lineItems.length === 0) {
            return undefined
        }

        var cheques = this.state.data.lineItems.map(element => {
            return this.renderChequeContainer(element)
        });

        return cheques
    }

    renderChequeContainer(element) {
        return <div className="break-inside-avoid" style={{ paddingTop: getVW(7.6), paddingBottom: getVW(7.6) }}>
            <div style={{ border: 'solid windowText 1.33px', }}>
                {this.renderTopHeaderContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(60, this.state.data.printScale)),
                }}>
                </div>
                {this.renderMiddleContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(58, this.state.data.printScale)),
                }}>
                </div>
                {this.renderBottomContainer(element)}
                <div style={{
                    height: getVW(Utility.convertToPrintScale(100, this.state.data.printScale)),
                }}>
                </div>
            </div>
        </div>
    }

    renderTopHeaderContainer(element) {
        return <div className="RowDiv" style={{ margin: getVW(8), marginBottom: 0 }}>
            {this.renderTopHeaderTitleColumn(getLocalisedText('pay_to'))}
            {this.renderTopHeaderDataColumn(element.payTo)}
            <div style={{
                width: getVW(Utility.convertToPrintScale(448, this.state.data.printScale)),
            }}>
            </div>
            {this.renderTopHeaderTitleColumn(getLocalisedText('date'))}
            {this.renderTopHeaderDataColumn(element.date)}
        </div>
    }



    renderMiddleContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                marginLeft: getVW(8),
                marginRight: getVW(8),
            }}>
            {this.renderMiddleTitleColumn(getLocalisedText('amount_in_words'))}
            {this.renderMiddleDataColumn(element.amountInWords, { width: getVW(Utility.convertToPrintScale(400, this.state.data.printScale)), paddingTop: getVW(7.6), paddingBottom: getVW(7.6), })}
            {this.renderMiddleTitleColumn(NumberUtility.getCurrency(this.state.data))}
            {this.renderMiddleDataColumn(Utility.toCurrencyFormat(element.amount, undefined), { border: 'solid windowText 1.33px', height: getVW(Utility.convertToPrintScale(28.533, this.state.data.printScale)), width: getVW(Utility.convertToPrintScale(135, this.state.data.printScale)), justifyContent: 'center', paddingLeft: getVW(7.6) })}
        </div>
    }

    renderBottomContainer() {
        return <div className="RowReverseDiv"
            style={{
                alignItems: 'baseline',
                margin: getVW(8),
                marginTop: 0,
            }}>
            {/* <div style={{
                width: getVW(684.27)
            }}>
            </div> */}
            <div style={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                paddingRight: getVW(64),
                ...this.defaultStyle(),
            }}>
                ({getLocalisedText('signature')})
            </div>
        </div>
    }

    renderTopHeaderTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderTopHeaderDataColumn(text) {
        return <div style={{
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            paddingTop: getVW(7.6),
            paddingBottom: getVW(7.6),
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleDataColumn(text, additionalStyle) {
        return <div className="ColumnDiv" style={{
            ...additionalStyle,
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }



    defaultStyle() {
        return {
            fontFamily: 'calibri san-serif',
            lineHeight: '115%',
            textAlign: 'left',
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium },this.state.data.printScale)
        }
    }
}
