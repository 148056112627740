import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import AddressParser, { AddressType } from "../../Helper/AddressParser";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import { HTMLTag } from "../../Utilities/HTMLTag";

export default class PurchaseRequisitionTemplate1 extends Component {
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        // Subtracting top and bottom border height from min page height
        let minHeight = getPageHeight(this.state.data.printScale);
        if (minHeight && minHeight.includes("vw")) {
            minHeight = `${Number(minHeight.replace("vw", "")) - Number(getVW(2).replace("vw", ""))}vw`;
        }

        return (
            <div id={HTMLTag.TEMPLATE_HOLDER} className='DocumentHolder ColumnDiv parent-width'
                style={{
                    justifyContent: 'space-between',
                    minHeight: minHeight,
                    border: '1px solid black'
                }}>
                <div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
                    <div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
                        {this.getCompanySection()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        {this.getDocumentType()}
                        {this.getDocumentDetailsSection()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                    </div>
                    <div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
                        {this.getEditableDocumentTable()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        {this.getRemarkSection()}
                        {this.getForSection()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
                        {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                    </div>
                </div>
            </div>
        );
    }

    getCompanySection() {
        return <div className="RowDiv"
            style={{
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)), paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(70, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 4, paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#000000',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(AddressParser.getRequisitonCustomAddressString(this.state.data.fromObj)) }}>
                    </div>}
                    {this.getEmailAndPhone(this.state.data)}
                </div>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }} />
        </div>
    }

    getDocumentType() {
        return <div className="RowDiv parent-width"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale),
                    textAlign: "center",
                    width: "100%"
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getEmailAndPhone() {
        return <div className={"docFontStyle" + getFontSizeClass()}
            style={{
                width: '100%',
                marginTop: 0,
                color: '#000000',
                textAlign: 'center',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
            }}>
            {this.state.data.email &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("email")}:`}
                    </text>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.email}`}
                    </text>
                </>
            }
            {this.state.data.contactNumber &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("phone")}:`}
                    </text>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.contactNumber}`}
                    </text>
                </>
            }
        </div>
    }

    getDocumentDetailsValue(element) {
        var value = ''
        if (element.key !== undefined) {
            value = this.state.data[element.key]
        }
        else if (element.customFieldValue !== undefined) {
            value = element.customFieldValue
        }

        return value
    }

    getRowValueSection(title, value, flex) {
        return <div className="RowDiv"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                flex: flex,
            }}>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
            </text>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                : &nbsp;
            </text>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {value}
            </text>
        </div>
    }

    getSingleDetailsSection(item, isLeftItem) {
        return ComponentManager.getRowValueSectionCustom(
            item.label,
            this.getDocumentDetailsValue(item),
            isLeftItem ? "50%" : "60%",
            this.state.data.themeColor,
            undefined,
            ":",
            getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
            this.state.data.printScale
        )
    }

    getDocumentDetailsSection() {
        const documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index);
        if (selectedDetailList.length === 0) {
            return <></>
        }

        const leftDetailsList = []
        const rightDetailsList = []

        selectedDetailList.forEach((detail, index) => {
            if ((index + 1) % 2 !== 0) {
                leftDetailsList.push(detail);
            } else {
                rightDetailsList.push(detail);
            }
        });

        let bottomLeftDetailsSection = undefined
        if (leftDetailsList.length > 0) {
            bottomLeftDetailsSection = leftDetailsList.map(element => {
                return this.getSingleDetailsSection(element, true)
            });
        }

        let bottomRightDetailsSection = undefined
        if (rightDetailsList.length > 0) {
            bottomRightDetailsSection = rightDetailsList.map(element => {
                return this.getSingleDetailsSection(element, false)
            });
        }

        let bottomSection = undefined
        if (bottomLeftDetailsSection) {
            bottomSection = <div className="RowDiv parent-width">
                <div className="ColumnDiv" style={{
                    flex: 11,
                    alignSelf: 'baseline',
                    paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                    paddingLeft: getVW(Utility.convertToPrintScale(15 * this.spacingRatio, this.state.data.printScale))
                }}>
                    {bottomLeftDetailsSection}
                </div>
                {bottomRightDetailsSection && (
                    <div className="ColumnDiv" style={{
                        flex: 9,
                        alignSelf: 'baseline',
                        paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                        paddingRight: getVW(Utility.convertToPrintScale(15 * this.spacingRatio, this.state.data.printScale))
                    }}>
                        {bottomRightDetailsSection}
                    </div>
                )}
            </div>
        }

        return <div className="ColumnDiv parent-width">
            {bottomSection}
        </div>
    }

    getRemarkSection() {
        return (
            <div className="RowDiv break-inside-avoid" id={HTMLTag.REMARKS}
                style={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black'
                }}>
                <div className="pt-s pb-s pl-s pr-s">
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: this.state.data.themeColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("remark")} : `}
                    </text>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(this.state.data.notes)}
                    </text>
                </div>
            </div>
        );
    }

    getForSection() {
        return (
            <div className="RowDiv pt-s pb-s"
                style={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {`${getLocalisedText("for")},. `}
                </text>
                <text className={"docFontStyle pr-r" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        );
    }

    getGenerateDocumentSection() {
        return <div style={{
            paddingBottom: getVW(PAGE_PADDING, true),// Utility.getGeneratedMessagePaddingBottom(this.spacingRatio),
            paddingTop: getVW(5 * this.spacingRatio),
            width: '100%'
        }}>
            <text className={'docFontStyleBold fontSizeNormal ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {Utility.getGeneratedMessage(this.state.data.type)}
            </text>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#000000";
        style.itemBorderColor = "#000000";
        style.footerBackgroundColor = "white";
        style.footerBorderColor = "#000000";
        style.headerBackgroundColor = "transparent";
        style.noPadding = false
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['33%', '33%', '33%'] : ['33%', '33%', '33%']
        return style;
    }
}
