import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { saveAs } from 'file-saver';
import { DATE_FORMAT, DOC_TYPE } from '../Constants/Constant';
export interface RevRecApiConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: string;
  HAS_VARIANTS?: boolean;
  QueryParam?: string;
}

const defaultConfig: RevRecApiConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'desc',
  Sort: '',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: '',
  HAS_VARIANTS: true
};

export interface TypeConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
}

class RevRecService {
  static apiConfig: RevRecApiConfig;
  static typeApiConfig: TypeConfig;

  static createFVF(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.FVF.FAIR_VALUE_FORMULA;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to create FVF: ', err);
      return Promise.reject(err);
    });
  }

  static updateFVF(payload: any, id: any) {
    const finalEndpoint: string = `/revrec/fvf/${id}`;
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to update FVF: ', err);
      return Promise.reject(err);
    });
  }

  static deleteFVF(id: number) {
    const finalEndpoint: string = `/revrec/fvf/${id}`;
    try {
      return http.delete(finalEndpoint);
    } catch (err) {
      console.error('Error deleting Fair Value Formula: ', err);
      return Promise.reject(err);
    }
  }

  static getFVF() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    }
    let finalEndpoint: string =
      ApiConstants.URL.FVF.FAIR_VALUE_SEARCH + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading FVF: ', err);
      return Promise.reject(err);
    });
  }

  static postWaterfall(payload: any) {
    let finalEndpoint: string = ApiConstants.URL.REV_REC_RULES.WATERFALL_REPORT;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error loading FVF: ', err);
      return Promise.reject(err);
    });
  }

  static postDeferredRevenueByItem(payload: any) {
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.DEFERRED_REVENUE_BY_ITEM;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error loading FVF: ', err);
      return Promise.reject(err);
    });
  }
  static postDeferredRevenueByCustomer(payload: any) {
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.DEFERRED_REV_BY_CUSTOMER;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error loading FVF: ', err);
      return Promise.reject(err);
    });
  }

  static getPlanDetails(code: any) {
    let finalEndpoint: string = `${ApiConstants.URL.REVENUE_RECOGNITION.PLAN_DETAILS}?code=${code}`;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Plan Details: ', err);
      return Promise.reject(err);
    });
  }
}

export default RevRecService;
