import { Document } from './Document';
import {
  BUY_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS,
  TAX_SYSTEM
} from '../Constants/Constant';
import { DocumentItem } from './DocumentItem';
import { TdsInfoList } from './TdsInfoList';
import { CallBackPayloadType } from './Interfaces';

export interface BillProps {
  draftData?: any;
  formData?: any;
  populateFormData?: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
  documentMode?: DOCUMENT_MODE;
  isCenterAlign: boolean;
  auditLogView?: boolean;
  isConvertFromPOToBill?: boolean;
}

export interface Bill extends Document {
  purchaseInvoiceProducts?: DocumentItem[];
  purchaseInvoiceDate?: string;
  purchaseInvoiceDueDate?: string;
  buyType: BUY_TYPE;
  billDue?: string;
  receiveByDate: string;
  openingInvoice?: boolean;
  paymentStatus?: PAYMENT_STATUS;
  whtRate?: number;
  dropShip: boolean;
  purchaseInvoiceType: BUY_TYPE;
  purchaseInvoiceCode?: string;
  receiveGoodsStatus: RECEIVED_GOODS_STATUS;
  receivedComplete?: boolean;
  isCreditable?: boolean;
  taxInvoiceNo?: string;
  tdsInfoIndia?: TdsInfoList;
  receiptStatus?: RECEIVED_GOODS_STATUS;
  landedProducts?: any;
  landedCost?: any;
  isConverting?: boolean;
  showBillCustomAlert?: boolean;
  placeOfSupply: string;
}

export const billInitialState: Bill = {
  contactCode: '',
  currency: '',
  currencyCode: '',
  status: DOCUMENT_STATUS.OPEN,
  sequenceFormat: '',
  documentType: DOC_TYPE.BILL,
  shipFrom: {},
  shipTo: {},
  billTo: {},
  exchangeRate: 1,
  documentDate: '',
  validTillDate: '',
  dueDate: '',
  paymentStatus: PAYMENT_STATUS.PENDING,
  receiveGoodsStatus: RECEIVED_GOODS_STATUS.NOT_RECEIVED,
  items: [],
  buyType: BUY_TYPE.INVENTORY,
  fulfillmentDate: '',
  memo: '',
  contact: null,
  unitPriceGstInclusive: false,
  rcmApplicable: false,
  reorder: false,
  previousExchangeRate: 1,
  gstExchangeRate: 1,
  totalAmount: 0,
  roundOffAmountInDocumentCurrency: 0,
  totalAmountInBaseCurrency: 0,
  dueAmount: 0,
  attachments: [],
  attachmentIds: [],
  supportedFeatures: [],
  gstin: '',
  placeOfSupply: '',
  customerType: 'NA',
  vendorType: 'NA',
  gstTreatment: 'REGISTERED_BUSINESS_REGULAR',
  customField: null,
  paymentInformation: null,
  knockoffNonContraInfo: [],
  knockoffContraInfo: [],
  taxSystem: TAX_SYSTEM.INDIA_GST,
  tcsRateId: undefined,
  tcsAmount: 0,
  totalTdsAmount: 0,
  vatPaidAtCustomSA: false,
  rcmApplicableSA: false,
  whtRate: 0,
  openingInvoice: false,
  dropShip: false,
  receiveByDate: '',
  purchaseInvoiceType: BUY_TYPE.INVENTORY,
  isDocumentTouched: false,
  landedCost: false,
  landedProducts: [],
  additionalCharges: {
    globalDiscount: {
      amount: 0,
      isPercent: false,
      isSubTotalOnly: false,
      percent: 0,
      applyOnBeforeTax: false
    },
    additionalChargeAmount: null,
    additionalChargeTaxAmount: null,
    additionalChargesDetails: []
  },
  showBillCustomAlert: false
};
