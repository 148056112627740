import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import ContactDetailLeftPanel from './LeftPanel';
import ContactDetailCenterPanel from './CenterPanel';
import SideBarService from '../../../Services/sidebar';
import ContactDetails from '../../../Managers/ContactDetails';
import Utility from '../../../Utility/Utility';
import ContactService from '../../../Services/Contact';

export const CONTACT_LEFT_PANEL_WIDTH = 210;
interface ContactDetailProps {
  onUpdateData: () => void;
}

const ContactDetail: React.FC<ContactDetailProps> = (props: any) => {
  const { id: contactCodeInURL }: any = useParams();
  const [contactData, setContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RouteManager.setPresenter({ props });
    let contact = ContactDetails.getContactDetails();
    if (contact && parseInt(contactCodeInURL) === parseInt(contact?.id)) {
      setContactData(contact);
      setIsLoading(false);
    } else {
      getContactDetail();
    }
  }, []);

  const getContactDetail = (refresh = false, refreshParent = false) => {
    setIsLoading(true);
    ContactService.getContactDetailsById(contactCodeInURL)
      .then((res) => {
        if (res) {
          updateData(res);
        }
      })
      .catch((error) => {
        Utility.alertWithRedirection(
          'Error!',
          "Record not found / You don't have permission",
          PAGE_ROUTES.CONTACTS
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateData = (data: any) => {
    ContactDetails.setContactDetails(data);
    setContactData(data);
  };

  const getLeftPanel = () => {
    return (
      <ContactDetailLeftPanel
        contactDetails={contactData}
        onUpdateData={updateData}
      />
    );
  };

  const getCenterPanel = () => {
    return (
      <ContactDetailCenterPanel
        contact={contactData}
        isLoaderCenterPanel={isLoading}
      />
    );
  };

  return (
    <div className={`parent-width parent-height full-screen-width`}>
      <div className="row row-responsive align-items-start parent-height deal-detail-bottom-spacing bg-gray1 overflow-hidden">
        <div
          className="col parent-height mr-r"
          style={{ width: CONTACT_LEFT_PANEL_WIDTH }}
        >
          {getLeftPanel()}
        </div>
        <div
          className="col parent-height"
          style={{
            width:
              SideBarService.getContainerWidth() - CONTACT_LEFT_PANEL_WIDTH + 50
          }}
        >
          {getCenterPanel()}
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
