import React, { Component } from 'react';
import {
  DKButton,
  DKCalendar,
  DKIcons,
  DKLine,
  DKLabel,
  getDateAsString
} from 'deskera-ui-library';
import { ACTIVITY_DATE_FORMAT } from '../../Constants/Constant';

export default class DKDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.props.selectedDate
        ? this.props.selectedDate
        : new Date(),
      hours: 0,
      minutes: 0,
      amPm: 'am',
      is12Hour: this.props.is12Hour ? this.props.is12Hour : false,
      showCalendarView: this.props.showCalendarView
        ? this.props.showCalendarView
        : false
    };
  }
  componentDidMount() {
    this.initDateTime();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showCalendarView: nextProps?.showCalendarView
    });
  }

  render() {
    return (
      <div className={`column align-self-center ` + this.props.className}>
        <div className="row row-responsive justify-content-center-mobile">
          {this.state.showCalendarView && (
            <DKCalendar
              onSelectDate={(date) => {
                this.onSelectDate(date);
              }}
              selectedDate={new Date(this.state.selectedDate)}
            />
          )}
          <div className="row justify-content-center parent-width mt-m pt-m mb-m">
            <div className="column align-items-center ml-m">
              <div className="">
                {this.getIncrementer(this.state.hours, 'hour')}
              </div>
            </div>
            <div className="column align-items-center ml-m fw-h">:</div>
            <div className="column align-items-center ml-m mr-m">
              <div className="">
                {this.getIncrementer(this.state.minutes, 'min')}
              </div>
            </div>
            {this.state.is12Hour && (
              <div className="column align-items-center mr-m">
                <DKButton
                  title="AM"
                  className={
                    this.state.amPm === 'am'
                      ? `bg-button mb-s text-white`
                      : `bg-white border-m mb-s`
                  }
                  onClick={() => {
                    this.setamPm('am');
                  }}
                />
                <DKButton
                  title="PM"
                  className={
                    this.state.amPm === 'pm'
                      ? `bg-button mb-s text-white`
                      : `bg-white border-m mb-s`
                  }
                  onClick={() => {
                    this.setamPm('pm');
                  }}
                />
                <div></div>
              </div>
            )}
          </div>
        </div>
        <DKLine className="m-v-m" />
        {this.getFooter()}
      </div>
    );
  }
  getDateValueAsString = () => {
    let date = this.state.selectedDate
      ? new Date(this.state.selectedDate)
      : new Date();
    return getDateAsString(date, ACTIVITY_DATE_FORMAT);
  };
  getFooter = () => {
    return (
      <div className="row justify-content-between">
        {this.state.showCalendarView && (
          <DKLabel text={this.getDateValueAsString()} className="text-gray" />
        )}
        <div className="row " style={{ width: 'auto' }}>
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m "
            onClick={() => {
              this.props.onClose();
            }}
          />
          <DKButton
            title="Done"
            className="bg-button text-white ml-r"
            onClick={() => {
              this.saveDateTime();
            }}
          />
        </div>
      </div>
    );
  };
  getMonth(date) {
    if (this.props.isDateRange) {
      return this.props.selectedStartDate
        ? new Date(this.props.selectedStartDate).getMonth() + 1
        : new Date().getMonth() + 1;
    } else {
      return date ? date.getMonth() + 1 : new Date().getMonth() + 1;
    }
  }

  getAmPM = (hour) => {
    let value = 'am';
    if (hour >= 12) {
      value = 'pm';
    } else {
      value = 'am';
    }
    return value;
  };

  setamPm = (value) => {
    this.setHours(value);
    this.setState({ amPm: value }, () => this.setDateTime());
  };

  initDateTime = () => {
    let selectedDate = this.state.selectedDate;
    let date = new Date(selectedDate);
    let amPm = this.getAmPM(date.getHours());
    let hours = date.getHours();
    if (this.state.is12Hour) {
      hours = amPm === 'am' ? hours : Math.abs(hours - 12);
    }
    this.setState(
      { hours: hours, minutes: date.getMinutes(), amPm: amPm },
      this.setHours
    );
  };

  setHours = (amPm = this.state.amPm, hours = this.state.hours) => {
    if (!this.state.is12Hour) return;

    if (amPm === 'am' && hours === 12) {
      hours = 0;
    } else if (amPm === 'pm' && hours === 0) {
      hours = 12;
    }

    this.setState({ hours: hours });
  };

  getIncrementer = (data, type) => {
    let value = data;
    if (data < 10) {
      value = '0' + data;
    }
    return (
      <div className={'row ' + this.props.className}>
        <div
          className="column border-radius-s unselectable "
          style={{ overflow: 'hidden', width: 34 }}
        >
          <DKButton
            icon={DKIcons.ic_arrow_up}
            className="parent-width justify-content-center border-radius-none bg-white"
            style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
            onClick={() => this.onChange(1, type)}
          />
          <input
            type="text"
            value={value}
            onChange={(e) => this.onEditValue(e, type)}
            onBlur={(e) => {
              if (type === 'hour') {
                this.setHours();
              }
            }}
            className="row justify-content-center bg-gray1 border-m app-font p-s border-box text-align-center"
          />
          <DKButton
            icon={DKIcons.ic_arrow_down}
            className="parent-width justify-content-center border-radius-none bg-white"
            style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
            onClick={() => this.onChange(-1, type)}
          />
        </div>
      </div>
    );
  };

  onEditValue = (value, type) => {
    let inputValue = parseInt(value.target.value);
    if (type === 'hour') {
      if (
        inputValue !== '' &&
        !this.state.is12Hour &&
        inputValue >= 0 &&
        inputValue <= 23
      ) {
        this.setState({ hours: inputValue }, () => this.setDateTime());
      } else if (
        inputValue !== '' &&
        this.state.is12Hour &&
        inputValue >= 0 &&
        inputValue <= 12
      ) {
        // let formatAMPM = this.getAmPM(inputValue)
        // if (formatAMPM === "pm") {
        //   inputValue = inputValue - 12
        // }
        this.setState({ hours: inputValue }, () => this.setDateTime());
      } else {
        let date = new Date();
        let hours = date.getHours();
        let formatAMPM = this.getAmPM(hours);
        if (this.state.is12Hour && formatAMPM === 'pm') {
          hours = hours - 12;
        }
        this.setState({ hours: hours }, () => this.setDateTime());
      }
    }

    if (type === 'min') {
      if (inputValue >= 0 && inputValue <= 59) {
        this.setState({ minutes: inputValue }, () => this.setDateTime());
      } else {
        let date = new Date();
        this.setState({ minutes: date.getMinutes() }, () => this.setDateTime());
      }
    }
  };

  onChange = (by, type) => {
    let val;
    if (type === 'hour') {
      val = this.state.hours;
    }
    if (type === 'min') {
      val = this.state.minutes;
    }
    val = val + by;
    if (type === 'hour') {
      if (!this.state.is12Hour && val > 23) {
        return;
      }
      if (this.state.is12Hour && val > 12) {
        val = 1;
      } else if (
        this.state.is12Hour &&
        val === 12 &&
        this.state.amPm === 'am'
      ) {
        val = 0;
      } else if (this.state.is12Hour && val === 0 && this.state.amPm === 'pm') {
        val = 12;
      } else if (this.state.is12Hour && val === -1) {
        val = 11;
      }
    }
    if (type === 'min') {
      if (val > 59) {
        return;
      }
    }
    if (type === 'min' || type === 'hour') {
      if (val === -1) {
        return;
      }
    }
    if (type === 'hour') {
      this.setState({ hours: val }, () => this.setDateTime());
    }
    if (type === 'min') {
      this.setState({ minutes: val }, () => this.setDateTime());
    }
  };

  onSelectDate = (date) => {
    this.setState({ selectedDate: date }, () => this.setDateTime());
  };

  setDateTime = () => {
    let newSelectedDate = this.state.selectedDate;
    let hours = this.state.hours;
    let min = this.state.minutes;

    if (this.state.is12Hour && this.state.amPm === 'pm' && hours < 12) {
      hours = hours + 12;
    }
    if (newSelectedDate === undefined) {
      newSelectedDate = new Date();
    }
    let convertDateNTime = new Date(newSelectedDate);
    convertDateNTime = convertDateNTime.setHours(hours, min, 0, 0);
    var newDateNTime = new Date(convertDateNTime);
    this.setState({ selectedDate: newDateNTime.toISOString() });
  };
  saveDateTime = () => {
    this.props.onSave(this.state.selectedDate);
  };
}
