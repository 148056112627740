import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import ApiConstants from '../Constants/ApiConstants';

export interface AssetGroupAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  search?: string;
}

export const defaultConfig: AssetGroupAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};

export type AssetGroupT = {
  id: number;
  name: string;
  assetAccountCode: string;
  assetAccumulatedAccountCode: string;
  depreciationExpenseAccountCode: string;
  depreciationMethod: string;
  depreciationConvention: string;
  depreciationRate: number;
  effectiveLife: string | null;
  decliningFactor: string | null;
  writeOffAccountCode: string | null;
  hsnOrSacCode: string | null;
  uqcIndia: string | null;
  purchaseTaxCode: string | null;
  assetAccountDisplayCode: string;
  assetAccumulatedAccountDisplayCode: string;
  depreciationExpenseAccountDisplayCode: string;
  assetAccountName: string;
  assetAccumulatedAccountName: string;
  depreciationExpenseAccountName: string;
  active: boolean;
  viewOnly: boolean;
};

class AssetGroupService {
  static apiConfig: AssetGroupAPIConfig;
  static abortController: any = null;

  static getAccountEndPoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const params = new URLSearchParams();
    for (const key in this.apiConfig) {
      let value = this.apiConfig[key as keyof AssetGroupAPIConfig];
      if (this.apiConfig.hasOwnProperty(key)) {
        params.append(key.toLowerCase(), value);
      }
    }
    const queryString = params.toString();
    const finalEndpoint: string =
      ApiConstants.URL.ASSET_BASE +
      ApiConstants.URL.ASSET_GROUP.FETCH_ASSET_GROUP +
      '?' +
      queryString;
    return finalEndpoint;
  };
  
  static getAssetGroupByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const params = new URLSearchParams();
    for (const key in this.apiConfig) {
      let value = this.apiConfig[key as keyof AssetGroupAPIConfig];
      if (this.apiConfig.hasOwnProperty(key)) {
        params.append(key.toLowerCase(), value);
      }
    }
    const queryString = params.toString();

    const finalEndpoint: string =
      ApiConstants.URL.ASSET_BASE +
      ApiConstants.URL.ASSET_GROUP.FETCH_ASSET_GROUP +
      '?' +
      queryString;

    return http
      .get(finalEndpoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createAssetGroup(payload: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.ASSET_GROUP.FETCH_ASSET_GROUP;
    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error creating Asset Group: ', err);
      return Promise.reject(err);
    });
  }

  static updateAssetGroup(payload: any, id: any) {
    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.ASSET_GROUP.UPDATE_ASSET_GROUP(id);
    return http2.put(finalEndpoint, payload).catch((err: any) => {
      console.error('Error Updating Asset Group: ', err);
      return Promise.reject(err);
    });
  }

  static getAssetExpenseAccount() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = ``;
    const finalEndpoint: string =
      ApiConstants.URL.COA.UNDEPOSITED + queryString;
    let payloads: string[] = [
      'Expenses',
      'Other Expenses',
      'Operating Expenses',
      'Cost of Goods Sold',
      'COGS'
    ];
    return http.post(`${finalEndpoint}`, payloads).catch((err: any) => {
      console.error('Error loading undeposited: ', err);
      return Promise.reject(err);
    });
  }

  static getCurrentNonCurerentAsset() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = ``;
    const finalEndpoint: string =
      ApiConstants.URL.COA.UNDEPOSITED + queryString;
    let payloads: string[] = [
      'Non-current Assets',
      'Current Assets',
      'Fixed Assets',
      'Long Term Assets',
      'Other Assets',
      'Other Current Assets',
      'Other Long Term Asset',
      'Intangibles',
      'PPE'
    ];
    return http.post(`${finalEndpoint}`, payloads).catch((err: any) => {
      console.error('Error loading undeposited: ', err);
      return Promise.reject(err);
    });
  }

  static deleteAssetGroup(id: number) {
    return http2
      .delete(`${ApiConstants.URL.ASSET_BASE + ApiConstants.URL.ASSET_GROUP.DELETE_ASSET_GROUP_BY_ID(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static activateDeActivateAssetGroup(data: any, id: any) {
    const finalEndPoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.ASSET_GROUP.CHANGE_ASSET_GROUP_STATUS(id) +
      `?active=${data}`;
    return http
      .patch(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default AssetGroupService;
