import { CHECK_FORMAT_NUMBER_TYPE } from '../../../Constants/Constant';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { DKLabel, DKRadioButton, Toggle } from 'deskera-ui-library';
import Utility from '../../../Utility/Utility';

interface CheckFormatProps {
  onChange: (data: any) => any;
}

const CheckFormat = (props: CheckFormatProps) => {
  const tenantDetails = useAppSelector(activeTenantInfo);

  const getFormatOptions = () => {
    return (
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="row">
          <DKLabel text="Check Format" className="fw-m mb-xs" />
        </div>
        <div className="column parent-width">
          <div className="row">
            <DKLabel
              text="Formats for the check amount in words"
              className="fw-m mb-xs"
            />
          </div>
          <div className="parent-width">
            <div className="row justify-content-between p-v-xs gap-4">
              <DKLabel text="and number/100" style={{ minWidth: 100 }} />
              <div className="column" style={{ minWidth: 42 }}>
                <DKRadioButton
                  className="box-content"
                  color={'bg-app'}
                  onClick={(checked: any) => {
                    props?.onChange({
                      additionalSettings: {
                        CHECK_FORMAT_TYPE: CHECK_FORMAT_NUMBER_TYPE.FORMAT_A
                      }
                    });
                  }}
                  isSelected={
                    tenantDetails?.additionalSettings?.CHECK_FORMAT_TYPE ===
                    CHECK_FORMAT_NUMBER_TYPE.FORMAT_A
                  }
                />
              </div>
            </div>
            <DKLabel
              className="text-gray"
              text="For example: Three hundred and 00/100"
              style={{ minWidth: 100 }}
            />
          </div>

          <div className="parent-width">
            <div className="row justify-content-between p-v-xs gap-4">
              <DKLabel text="& number/100" style={{ minWidth: 100 }} />
              <div className="column" style={{ minWidth: 42 }}>
                <DKRadioButton
                  className="box-content"
                  color={'bg-app'}
                  onClick={(checked: any) => {
                    props?.onChange({
                      additionalSettings: {
                        CHECK_FORMAT_TYPE: CHECK_FORMAT_NUMBER_TYPE.FORMAT_B
                      }
                    });
                  }}
                  isSelected={
                    tenantDetails?.additionalSettings?.CHECK_FORMAT_TYPE ===
                    CHECK_FORMAT_NUMBER_TYPE.FORMAT_B
                  }
                />
              </div>
            </div>
            <DKLabel
              className="text-gray"
              text="For example: Three hundred & 00/100"
              style={{ minWidth: 100 }}
            />
          </div>

          <div className="parent-width">
            <div className="row justify-content-between p-v-xs gap-4">
              <DKLabel text="& No/100" style={{ minWidth: 100 }} />
              <div className="column" style={{ minWidth: 42 }}>
                <DKRadioButton
                  className="box-content"
                  color={'bg-app'}
                  onClick={(checked: any) => {
                    props?.onChange({
                      additionalSettings: {
                        CHECK_FORMAT_TYPE: CHECK_FORMAT_NUMBER_TYPE.FORMAT_C
                      }
                    });
                  }}
                  isSelected={
                    tenantDetails?.additionalSettings?.CHECK_FORMAT_TYPE ===
                    CHECK_FORMAT_NUMBER_TYPE.FORMAT_C
                  }
                />
              </div>
            </div>
            <DKLabel
              className="text-gray"
              text="For example: Three hundred & No/100"
              style={{ minWidth: 100 }}
            />
          </div>
        </div>
        {starBeforeAmountFormat()}
      </div>
    );
  };
  const starBeforeAmountFormat = () => {
    return (
      <div className="row parent-width gap-4">
        <div className="column parent-width">
          <DKLabel
            text="Attach **** before the amount in words"
            className="fw-m mb-xs"
          />
        </div>
        <div className="column" style={{ minWidth: 42 }}>
          <Toggle
            className="box-content"
            onChange={(checked: any) => {
              if (
                Utility.isEmpty(
                  tenantDetails?.additionalSettings?.CHECK_FORMAT_STAR
                )
              ) {
                props?.onChange({
                  additionalSettings: {
                    CHECK_FORMAT_STAR: '****'
                  }
                });
              } else {
                props?.onChange({
                  additionalSettings: {
                    CHECK_FORMAT_STAR: ''
                  }
                });
              }
            }}
            isOn={Utility.isNotEmpty(
              tenantDetails?.additionalSettings?.CHECK_FORMAT_STAR
            )}
          />
        </div>
      </div>
    );
  };
  return <>{getFormatOptions()}</>;
};

export default CheckFormat;
