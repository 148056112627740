export interface FormState {
  lastChangeKey: string;
  warehouse: {
    source: {
      data: any[];
      nonFilteredData: any[];
      value: any;
      errorMessage: string;
    };
    target: {
      data: any[];
      nonFilteredData: any[];
      value: any;
      errorMessage: string;
    };
  };
  RRB: {
    source: {
      row: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
      rack: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
      bin: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
    };
    target: {
      row: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
      rack: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
      bin: {
        data: any[];
        nonFilteredData: any[];
        value: any;
        errorMessage: string;
      };
    };
  };
}

export enum FORM_FIELD_NAMES {
  'SOURCE_WAREHOUSE' = 'SOURCE_WAREHOUSE',
  'TARGET_WAREHOUSE' = 'TARGET_WAREHOUSE',
  'SOURCE_WAREHOUSE_ROW' = 'SOURCE_WAREHOUSE_ROW',
  'TARGET_WAREHOUSE_ROW' = 'TARGET_WAREHOUSE_ROW',
  'SOURCE_WAREHOUSE_RACK' = 'SOURCE_WAREHOUSE_RACK',
  'TARGET_WAREHOUSE_RACK' = 'TARGET_WAREHOUSE_RACK',
  'SOURCE_WAREHOUSE_BIN' = 'SOURCE_WAREHOUSE_BIN',
  'TARGET_WAREHOUSE_BIN' = 'TARGET_WAREHOUSE_BIN'
}

export const initialFormState: FormState = {
  lastChangeKey: '',
  warehouse: {
    source: { data: [], nonFilteredData: [], value: '', errorMessage: '' },
    target: { data: [], nonFilteredData: [], value: '', errorMessage: '' }
  },
  RRB: {
    source: {
      row: { data: [], nonFilteredData: [], value: '', errorMessage: '' },
      rack: { data: [], nonFilteredData: [], value: '', errorMessage: '' },
      bin: { data: [], nonFilteredData: [], value: '', errorMessage: '' }
    },
    target: {
      row: { data: [], nonFilteredData: [], value: '', errorMessage: '' },
      rack: { data: [], nonFilteredData: [], value: '', errorMessage: '' },
      bin: { data: [], nonFilteredData: [], value: '', errorMessage: '' }
    }
  }
};
