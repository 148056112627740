import {
  DKButton,
  DKCheckMark,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showLoader
} from 'deskera-ui-library';
import { useRef, useState } from 'react';
import ic_barcode_green from '../../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../../Assets/Icons/ic_barcode_red.svg';
import ApiConstants from '../../../../../Constants/ApiConstants';
import {
  MODULE_TYPE,
  PRODUCE_PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import useScreenResize from '../../../../../Hooks/useScreenResize';
import { IColumn } from '../../../../../Models/Table';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import { IWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../../../Services/NumberFormat';
import BatchTrackingReceive from '../../../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingReceive';
import SerialTrackedReceive from '../../../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedReceive';
import NoneTrackedReceive from '../../../../../SharedComponents/WarehouseManagement/NoneTrackedReceive';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import { REQUIRED_ITEM_TABLE } from '../../../Constants/TableConstant';
import RawMaterialHelper from '../BomMaterialComponent/RawMaterialHelper';
import { IRawMaterial } from '../BomMaterialComponent/RawMaterialModel';
import TrackingPopupHelper from '../BomMaterialComponent/TrackingPopupHelper';
import WasteManagementListHelper from './WasteManagementListHelper';

interface IWasteManagementListProps {
  workOrderData: IWorkOrder;
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  isConfirmFlow: boolean;
  onRowChange: (data: {
    columnKey: string;
    rowData: IRawMaterial;
    rowIndex: number;
  }) => void;
  onSerialSave: (data: any, selectedProductID: number) => void;
  onBatchSave: (data: any, selectedProductID: number) => void;
  onNormalProductSave: (data: any, updatedWasteRow: IRawMaterial) => void;
  updateRowForCompleteOrder: (updatedWasteRow: any) => void;
  onChangeCostInclusionFlag: () => void;
  onClickNewScrapProduct?: () => void;
  onDeleteScrapProductRow?: (data: any) => void;
}

const WasteManagementList = (props: IWasteManagementListProps) => {
  const [selectedItem, setSelectedItem] = useState<any>();

  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);
  var productsArray = useRef<any>([]);

  /**
   * @sumeet Pls check if we need below
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchProductInventory());
  }, [dispatch]); */

  const advanceTrackingButtonClicked = async (item: any) => {
    setSelectedItem(item);
    showLoader('...fetching inventory data');
    await TrackingPopupHelper.apiCallsforAdvanceTracking(item, false);
    removeLoader();
  };

  /************* GRID UTILS *************
   *************************************
   *************************************
   */

  const getWasteManagementRows = (allColumns: Partial<IColumn>[]) => {
    let scrapCoProductItems = props.workOrderData?.workOrderItems?.filter(
      (item: any) =>
        item?.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
        item?.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
    );
    return (scrapCoProductItems || []).map((row, rowIndex) => {
      let invalidFields: string[] = [];

      row = deepClone(row);

      row.costPerUnit = row.costPerUnit || 0;

      let canShowAllocationBtn =
        !props.isReadOnlyMode && RawMaterialHelper.isNonServiceProduct(row);

      const isQuantityAllocated = TrackingPopupHelper.isItemAssigned(row);

      row.rowButtons = [];
      if (
        canShowAllocationBtn &&
        props.isConfirmFlow &&
        !Utility.isEmptyValue(row.actualRequiredQty)
      ) {
        row.rowButtons.push({
          title: '',
          className: 'p-v-0',
          icon: isQuantityAllocated ? ic_barcode_green : ic_barcode_red,
          onClick: () => advanceTrackingButtonClicked(row)
        });
      }

      if (isAdhocEnabled && !props.isReadOnlyMode) {
        row.rowButtons.push({
          title: '',
          className: 'p-v-0',
          icon: DKIcons.ic_delete,
          onClick: () => props.onDeleteScrapProductRow?.(row?.sequenceNumber)
        });
      }

      if (Utility.isEmpty(row.itemName)) {
        invalidFields.push('itemName');
      }
      /* if (Utility.isEmptyValue(row.actualRequiredQty)) {
        if (props.isConfirmFlow) {
          invalidFields.push('actualRequiredQty');
        } else {
          invalidFields.push('requiredQty');
        }
      } */
      row.invalidFields = invalidFields;

      row.nonEditableColumns = row.isNewRow
        ? []
        : [REQUIRED_ITEM_TABLE.ITEM_NAME, REQUIRED_ITEM_TABLE.REQUIRED_QTY];

      return row;
    });
  };

  type FormatterConfigForWasteRow = {
    rowData: IRawMaterial;
    rowIndex: number;
    column: Partial<IColumn>;
    columnKey: string;
  };

  const pickFormatter = (column: Partial<IColumn>) => {
    switch (column.key) {
      case 'itemName':
        return (data: FormatterConfigForWasteRow) =>
          data.rowData.itemName?.name || '-';
      case 'produceProductType':
        return (data: FormatterConfigForWasteRow) =>
          Utility.convertInTitleCase(data.rowData.produceProductType);
      case 'costPerUnit':
        return (data: FormatterConfigForWasteRow) =>
          Utility.amountFormatter(
            data.rowData.costPerUnit ||
              (props.isConfirmFlow
                ? 0
                : Number(data.rowData.itemName?.inventoryPrice || 0)),
            tenantInfo.currency
          );
      case 'requiredQty':
        return (data: FormatterConfigForWasteRow) =>
          `${NumberFormatService.getNumber(
            data.rowData.requiredQty
          )} <span class="fs-s text-gray">${
            Utility.getUOMForStockUOMId(data.rowData.stockUom)?.name
          }</span>`;
      case 'actualRequiredQty':
        return (data: FormatterConfigForWasteRow) =>
          `${NumberFormatService.getNumber(
            data.rowData.actualRequiredQty ||
              (props.isReadOnlyMode || props.isConfirmFlow
                ? 0
                : data.rowData.requiredQty)
          )} <span class="fs-s text-gray">${
            Utility.getUOMForStockUOMId(data.rowData.stockUom)?.name || ''
          }</span>`;
    }
  };

  const pickRenderer = (column: Partial<IColumn>) => {
    switch (column.key) {
      case REQUIRED_ITEM_TABLE.ITEM_NAME:
        return ({ rowData }: FormatterConfigForWasteRow) =>
          rowData.callingProductShortInfo ? (
            <div className="row justify-content-between">
              <DKLabel text={rowData.itemName?.['name'] || '-'} />
              <DKSpinner iconClassName="ic-s" />
            </div>
          ) : (
            <DKLabel text={rowData.itemName?.['name'] || '-'} />
          );
      case REQUIRED_ITEM_TABLE.PRODUCE_PRODUCT_TYPE:
        return ({ rowData }: FormatterConfigForWasteRow) => (
          <DKLabel
            className="row border-radius-s width-auto py-0.5 px-1.5 data-grid-badge-color-1"
            text={rowData.produceProductType}
          />
        );
      default:
    }
  };

  const getColumnConfigs = () => {
    // @ts-ignore
    let copyOfColumnConfig: Partial<IColumn>[] = deepClone(
      !props.isConfirmFlow
        ? WasteManagementListHelper.SCRAP_COLUMMNS
        : WasteManagementListHelper.COLUMNS_FOR_CONFIRM_SCREEN
    );

    copyOfColumnConfig.forEach((column: any) => {
      if (
        props.isConfirmFlow &&
        ['costPerUnit', 'actualRequiredQty'].includes(column.key || '')
      ) {
        column.editable = true;
      } else {
        column.editable = false;
      }

      if (isAdhocEnabled) {
        if (column.key === 'itemName') {
          column.dropdownConfig.data = RawMaterialHelper.getFilteredProducts(
            productsArray.current,
            props.workOrderData,
            true
          );
          column.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=type!NONTRACKED,active=true,hasVariants=false`;
            return endPoint;
          };

          column.dropdownConfig.searchApiConfig.dataParser = (
            response: any
          ) => {
            let filteredData = RawMaterialHelper.getFilteredProducts(
              response?.content,
              props?.workOrderData,
              false
            );
            productsArray.current = filteredData;
            return filteredData;
          };
          column.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <div className="column parent-width">
                <DKLabel className="row text-left" text={obj.name} />
                <DKLabel
                  className="text-left text-gray fs-s"
                  text={`Number: ${
                    obj.documentSequenceCode ? obj.documentSequenceCode : ''
                  }`}
                />
                {obj.hsnOrSacCode && (
                  <DKLabel
                    className="text-left text-gray fs-s"
                    text={`HSN/SAC: ${
                      obj.hsnOrSacCode ? obj.hsnOrSacCode : ''
                    }`}
                  />
                )}
              </div>
            );
          };

          column.dropdownConfig.onSelect = (index: number, value: any) => {};
        }

        if (['itemName', 'requiredQty'].includes(column.key || '')) {
          column.editable = true;
        }

        if (column.key === 'produceProductType') {
          column.editable = true;
          column.type = INPUT_TYPE.DROPDOWN;
          column.dropdownConfig = {
            title: '',
            allowSearch: false,
            searchableKey: '',
            className: '',
            searchApiConfig: {},
            data: ['SCRAP', 'COPRODUCT'],
            renderer: (index: number, product: any) => {
              return Utility.convertInTitleCase(product);
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          };
        }
      }

      column.formatter = pickFormatter(column);
      column.renderer = pickRenderer(column);
    });
    return copyOfColumnConfig;
  };

  /******************* RENDERERS *****************
   ***********************************************
   ***********************************************
   */

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center">
        <div className="align-items-center justify-content-center text-gray">
          No Scrap/By-Products found for this work order
        </div>
        {isAdhocEnabled && !props.isReadOnlyMode && (
          <DKButton
            title={`+ Add Scrap Product`}
            className="text-app-color fw-m"
            onClick={() => props.onClickNewScrapProduct?.()}
            style={{}}
          />
        )}
      </div>
    );
  };

  const getFulfillmentPopUp = () => {
    const selectedTrackingType = selectedItem?.itemName?.advancedTracking || '';
    return (
      <>
        {selectedTrackingType === ADVANCE_TRACKING.SERIAL && (
          <SerialTrackedReceive
            isMRP={true}
            itemDetails={WasteManagementListHelper.createProductItemForTracking(
              ADVANCE_TRACKING.SERIAL,
              selectedItem
            )}
            module={MODULE_TYPE.BUY}
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfill: any
            ) => {
              props.onSerialSave(
                data?.map((item: any) => {
                  return {
                    ...item,
                    qtyToFulfil: item.batchSize,
                    advancedTrackingData: item?.advancedTrackingData?.map(
                      (advancedTrackItem: any) => {
                        return {
                          ...advancedTrackItem,
                          qtyToFulfil: advancedTrackItem?.batchSize
                        };
                      }
                    )
                  };
                }),
                selectedItem?.itemName?.pid
              );
              setSelectedItem(null);
            }}
            onClose={() => {
              setSelectedItem(null);
            }}
          ></SerialTrackedReceive>
        )}
        {selectedTrackingType === ADVANCE_TRACKING.BATCH && (
          <BatchTrackingReceive
            isMrpFlow={true}
            itemDetails={WasteManagementListHelper.createProductItemForTracking(
              ADVANCE_TRACKING.BATCH,
              selectedItem
            )}
            module={MODULE_TYPE.BUY}
            onBatchSave={(data: any, quantityToFulfill: any) => {
              props.onBatchSave(
                data?.map((item: any) => {
                  return {
                    ...item,
                    qtyToFulfil: item.batchSize,
                    advancedTrackingData: item?.advancedTrackingData?.map(
                      (advancedTrackItem: any) => {
                        return {
                          ...advancedTrackItem,
                          qtyToFulfil: advancedTrackItem?.batchSize
                        };
                      }
                    )
                  };
                }),
                selectedItem?.itemName?.pid
              );
              setSelectedItem(null);
            }}
            onClose={() => {
              setSelectedItem(null);
            }}
          ></BatchTrackingReceive>
        )}
        {selectedTrackingType === ADVANCE_TRACKING.NONE && (
          <NoneTrackedReceive
            details={{
              ...WasteManagementListHelper.createProductItemForTracking(
                ADVANCE_TRACKING.NONE,
                selectedItem
              ),
              parentQuantityToFulfill: selectedItem.actualRequiredQty,
              pendingQuantity: selectedItem.actualRequiredQty,
              quantityRequired: selectedItem.actualRequiredQty,
              quantityFulfilled: selectedItem.actualRequiredQty,
              warehouseInventoryData: selectedItem?.warehouseInventoryData ?? []
            }}
            onSave={(data: any) => {
              if (data && data.length > 0) {
                props.onNormalProductSave(data, selectedItem);
              }
              setSelectedItem(null);
            }}
            onCancel={() => {
              setSelectedItem(null);
            }}
          />
        )}
      </>
    );
  };

  const gridColumns = getColumnConfigs();
  const gridRows = getWasteManagementRows(gridColumns);
  const wasteListContainerRef = useRef<HTMLDivElement | null>(null);
  let [gridWidth] = useScreenResize(wasteListContainerRef);
  gridWidth = (gridWidth || 500) - 36;
  const containerMaxHeight = 640;
  const gridMaxHeight = containerMaxHeight - 70;

  return (
    <div
      className={`column bg-white border-m p-l parent-size ${
        !props.isConfirmFlow ? 'border-radius-m' : ''
      }`}
      ref={wasteListContainerRef}
      style={{
        height: 'auto',
        width: props.isConfirmFlow
          ? '100%'
          : props.isEditMode
          ? '49.5%'
          : '100%',
        maxHeight: containerMaxHeight
      }}
    >
      <div className="row justify-content-between mb-s">
        <DKLabel
          text="Scrap/By-Product Details"
          className="fw-m text-app-color"
        />
        {props.isConfirmFlow && (
          <DKCheckMark
            title={'Include cost in FG'}
            isSelected={props.workOrderData.includeByProductCost}
            onClick={() => props.onChangeCostInclusionFlag()}
          />
        )}
        {isAdhocEnabled &&
          !props.isReadOnlyMode &&
          (gridRows?.length ?? 0) > 0 && (
            <DKButton
              title={`+ Add Scrap Product`}
              className="text-app-color fw-m p-0"
              onClick={() => props.onClickNewScrapProduct?.()}
            />
          )}
      </div>
      {!gridRows?.length ? (
        renderEmptyState()
      ) : (
        <DKDataGrid
          width={gridWidth}
          styles={{
            mainGridHolder: { marginBottom: -18 },
            gridScrollHolder: { maxHeight: gridMaxHeight },
            shadowHolder: { maxHeight: gridMaxHeight }
          }}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowBulkOperation={false}
          columns={gridColumns}
          rows={gridRows}
          onRowUpdate={(data: FormatterConfigForWasteRow) => {
            if (
              data.columnKey === REQUIRED_ITEM_TABLE.ITEM_NAME ||
              data.columnKey === REQUIRED_ITEM_TABLE.ACTUAL_REQUIRED_QTY
            ) {
              let copyData = { ...data };
              copyData.rowData.warehouseInventoryData = [];
              copyData.rowData.requiredQty = copyData.rowData.actualRequiredQty;

              copyData.columnKey =
                data.columnKey === REQUIRED_ITEM_TABLE.ACTUAL_REQUIRED_QTY
                  ? REQUIRED_ITEM_TABLE.REQUIRED_QTY
                  : copyData.columnKey;
              props.onRowChange(copyData);
            } else {
              props.isConfirmFlow
                ? props.updateRowForCompleteOrder(data.rowData)
                : props.onRowChange(data);
            }
          }}
        />
      )}
      {getFulfillmentPopUp()}
    </div>
  );
};

export default WasteManagementList;
