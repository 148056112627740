import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface UndepositedApiConfig {
  CurrencyCode?: string;
}

const defaultConfig: UndepositedApiConfig = {
  CurrencyCode: 'INR'
};

class UndepositedService {
  static apiConfig: UndepositedApiConfig;

  static getDepositedList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?currencyCode=${this.apiConfig.CurrencyCode}`;
    const finalEndpoint: string =
      ApiConstants.URL.COA.UNDEPOSITED + queryString;
    let payloads: string[] = ['Current Assets'];
    return http.post(`${finalEndpoint}`, payloads).catch((err: any) => {
      console.error('Error loading undeposited: ', err);
      return Promise.reject(err);
    });
  }
  static postUndeposited(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.COA.POST_UDEPOSITED;
    return http
      .post(`${finalEndpoint}?accountId=${payload.id}`)
      .catch((err: any) => {
        console.error('Error loading Undeposited: ', err);
        return Promise.reject(err);
      });
  }
}

export default UndepositedService;
