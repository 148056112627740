import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../Constants/ApiConstants';
import MRPProductsService, {
  defaultConfig as defaultProductConfig
} from '../../../Services/MRP/MRPProducts';
import ProductService from '../../../Services/Product';
import Utility, {
  deepClone,
  shiftArrayElement
} from '../../../Utility/Utility';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { IColumn } from '../../../Models/Table';
import {
  addProductSubstituteColumnConfig,
  selectProductSubstituteColumnConfig,
  selectProductSubstituteColumnConfigTableId,
  updateColumnConfig
} from '../../../Redux/Slices/MRP/ProductSubstituteSlice';
import {
  updateColumnInfo,
  updateColumnShift
} from '../../../Helper/TableColumnUpdateHelper';
import CustomFieldService from '../../../Services/CustomField';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import {
  fetchUserPreferences,
  updateUserPreferences
} from '../../../Redux/Slices/AuthSlice';
import TenantService from '../../../Services/Tenant';
interface IProductSubstitutePopup {
  product?: any;
  onSave?: (selectedProducts: any[]) => void;
  onClose?: () => void;
  selectedProductsIds?: any[];
  excludedProductIds?: string[];
  title?: string;
  selectedProductId?: string;
  restrictClick?: boolean;
  currentConfiguration?: any;
  readOnly?: boolean;
}
export const ProductSubstitutePopup = (props: IProductSubstitutePopup) => {
  const productSubstituteColumnConfig = useAppSelector(
    selectProductSubstituteColumnConfig
  );
  const columnConfigTableId = useAppSelector(
    selectProductSubstituteColumnConfigTableId
  );
  const freezedColumns =
    useAppSelector(
      (state) => state.authInfo.userPreferences.data?.freezedColumns
    ) || {};
  const moduleFreezedColumns =
    freezedColumns[REMOTE_CONFIG_TABLES.MRP_PRODUCT_SUBSTITUTE] || [];

  const [productsData, setProductsData] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>(
    props?.selectedProductsIds
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<any>(undefined);
  const [totalPages, setTotalPages] = useState(1);
  const [gridType, setGridType] = useState(
    props.readOnly ? 'selected' : 'available'
  );
  const dispatch = useAppDispatch();

  const gridColumns = getProductSubstituteGridColumns();
  const [isReadonly, setIsReadonly] = useState(props.readOnly);

  useEffect(() => {
    if (!Utility.isEmpty(productsData)) {
      const selectedProductIds =
        selectedProducts?.map((product: any) => product.productId) || [];
      const pickedProductIds =
        selectedProducts
          ?.filter((product: any) => product?.isProductPicked)
          ?.map((product: any) => product.productId) || [];
      const mappedProducts = () =>
        (productsData.content || []).reduce((curr: any[], product: any) => {
          const isParentProduct =
            props.selectedProductId &&
            props.selectedProductId === product.productId;
          const isExcluded =
            Boolean(isParentProduct) ||
            Boolean(props.excludedProductIds?.includes(product.productId));
          if (product.active && !isExcluded) {
            let itemToBePushed: any = {
              productId: product.productId,
              productName: product.name,
              productDocumentSeqCode: product.documentSequenceCode,
              selected: product?.productId
                ? selectedProductIds?.includes?.(product?.productId)
                : false,
              isProductPicked: product?.productId
                ? pickedProductIds?.includes?.(product?.productId)
                : false,
              stockUom: product?.stockUom,
              stockUomString:
                Utility.getUOMForStockUOMId(product?.stockUom)?.name ?? '',
              type: product?.type,
              advancedTracking: product?.advancedTracking,
              description: product?.description,
              barcode: product?.barcode,
              customField: product?.customField,
              leadTime: product?.leadTimeDetails?.[0]?.leadTime ?? 0,
              weight: product?.inventory?.landedCostWeight ?? 0,
              availableQuantity: product?.inventory?.availableQuantity ?? 0,
              reservedQuantity: product?.inventory?.reservedQuantity ?? 0
            };
            if (!Utility.isEmpty(product?.customField)) {
              product?.customField?.map((customField: any) => {
                itemToBePushed[customField?.code] = customField?.value;
              });
            }
            curr.push(itemToBePushed);
          }
          return curr;
        }, []);
      setLoading(false);
      setProducts(mappedProducts);

      const updateSelectedProducts = () =>
        (selectedProducts || []).map((selectedSubstitute: any) => {
          const productDetails = (productsData.content || []).find(
            (productData: any) =>
              productData.productId === selectedSubstitute.productId
          );
          return {
            ...selectedSubstitute,
            productDocumentSeqCode:
              selectedSubstitute.productDocumentSeqCode ??
              selectedSubstitute.documentSequenceCode ??
              productDetails?.documentSequenceCode,
            selected: true,
            isProductPicked: selectedSubstitute?.productId
              ? pickedProductIds?.includes?.(selectedSubstitute?.productId)
              : false
          };
        });
      setSelectedProducts(updateSelectedProducts);
      setTotalPages(productsData.totalPages);
    }
  }, [productsData]);

  useEffect(() => {
    fetchProducts();
    return () => {
      MRPProductsService.apiConfig = defaultProductConfig;
    };
  }, [currentPage]);

  useEffect(() => {
    if (searchText !== undefined && gridType === 'available') {
      const delayDebounceFn = setTimeout(() => {
        if (currentPage === 1) {
          fetchProducts();
        } else {
          setCurrentPage(1);
        }
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchText]);

  const fetchProducts = async () => {
    const countryCode = Utility.getTenantSpecificApiCode(
      ProductService.moduleName
    );
    let countryCodeString = countryCode ? `/${countryCode}` : '';

    let url = `${ApiConstants.URL.BASE}products${countryCodeString}?search=${
      searchText ?? ''
    }&limit=20&page=${
      currentPage - 1
    }&query=type!NONTRACKED,active=true,hasVariants=false`;
    setLoading(true);
    let data = await MRPProductsService.fetchMRPProductsForURL(url);
    let copyData = { ...data };
    if (!Utility.isEmpty(data)) {
      copyData.content = copyData?.content?.filter((item: any) => {
        return ![props.selectedProductId, props?.product?.productId].includes(
          item.productId
        );
      });
    }

    setProductsData(copyData);
    setLoading(false);
  };
  const onSave = () => {
    const copySelectedProducts: any[] = [];
    selectedProducts?.forEach((product: any) => {
      delete product?.selected;
      copySelectedProducts.push(product);
    });
    props.onSave?.(copySelectedProducts);
  };

  /* ******* Grid column update callbacks ******* */
  function getProductSubstituteGridColumns() {
    const allColumns = productSubstituteColumnConfig;

    const firstRecord = productsData?.content?.[0];
    let customFields = firstRecord?.customField || [];
    let customFieldColumns: any = customFields.map((columnItem: any) => ({
      name: columnItem.label,
      key: columnItem.code,
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    }));

    return [...allColumns, ...customFieldColumns].map((column) => ({
      ...column,
      freezed: moduleFreezedColumns.includes(column.id)
    }));
  }

  const updateColumnInfoAndStore = (columnIndex: number, columnData: any) => {
    updateColumnInfo(columnConfigTableId as string, columnData);
    const currentColumnsConfig = [...productSubstituteColumnConfig];
    currentColumnsConfig[columnIndex] = columnData;
    showLoader();
    dispatch(updateColumnConfig(currentColumnsConfig));
    removeLoader();
  };

  const onColumnUpdated = async (data: any) => {
    let { columnData, property } = data;
    const columnKey = columnData.key;
    let columnIndex = productSubstituteColumnConfig.findIndex(
      (column: any) => column.key === columnKey
    );
    if (typeof columnData?.id !== 'undefined') {
      const isCustomField = columnKey?.startsWith('custom_');
      if (
        isCustomField &&
        columnData?.name !== productSubstituteColumnConfig[columnIndex].name
      ) {
        try {
          const customFieldId = columnKey?.split('_')[2];
          if (customFieldId) {
            const customFieldResponse =
              await CustomFieldService.getCustomFieldById(+customFieldId);
            if (customFieldResponse) {
              let customField = customFieldResponse;
              customField = {
                ...customField,
                label: columnData?.name
              };

              const updatedCustomField =
                await CustomFieldService.updateCustomField(
                  +customFieldId,
                  customField,
                  columnConfigTableId
                );
              if (updatedCustomField) {
                dispatch(
                  addProductSubstituteColumnConfig({
                    tableId: columnConfigTableId as string,
                    config: updatedCustomField?.newColumnConfig
                  })
                );
              }
            }
          } else {
            console.error('Error updating custom field');
          }
        } catch (err) {
          console.error('Error fetching custom field: ', err);
        }
      } else {
        let dataToUpdate = productSubstituteColumnConfig.find(
          (column: any) => column.key === columnKey
        );
        dataToUpdate = {
          ...dataToUpdate,
          [property]: columnData[property]
        } as IColumn;
        updateColumnInfoAndStore(columnIndex, dataToUpdate);
      }
    }
  };

  const onColumnShifted = (data: any) => {
    let { newIndex, oldIndex } = data;
    const columns = deepClone(productSubstituteColumnConfig);

    const findOldColumnInGridColumns = gridColumns?.find(
      (col: any, index: number) => oldIndex === index
    );
    const findOldIndexInAllColumns = columns?.findIndex(
      (col: any, index: number) => col.key === findOldColumnInGridColumns?.key
    );

    const findNewColumnInGridColumns = gridColumns?.find(
      (col: any, index: number) => newIndex === index
    );
    const findNewIndexInAllColumns = columns?.findIndex(
      (col: any, index: number) => col.key === findNewColumnInGridColumns?.key
    );

    let newColumnArray = shiftArrayElement(
      columns,
      findOldIndexInAllColumns,
      findNewIndexInAllColumns
    );
    const updatedConfigArray = newColumnArray.filter(
      (x) => typeof x !== 'undefined'
    );
    const columnIdArray = updatedConfigArray.map(
      (config) => config.id
    ) as string[];
    updateColumnShift(columnConfigTableId as string, columnIdArray);
    showLoader();
    dispatch(updateColumnConfig(updatedConfigArray));
    removeLoader();
  };

  const onColumnFreezeToggle = async (data: {
    column: IColumn;
    freezed: boolean;
  }) => {
    if (!data?.column?.id) return;
    try {
      let freezedColumnsByModule = moduleFreezedColumns || [];

      if (data.freezed) {
        freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
      } else {
        freezedColumnsByModule = freezedColumnsByModule.filter(
          (columnId: string) => columnId !== data.column.id
        );
      }

      const payload = {
        freezedColumns: {
          ...freezedColumns,
          [REMOTE_CONFIG_TABLES.MRP_PRODUCT_SUBSTITUTE]: freezedColumnsByModule
        }
      };
      dispatch(updateUserPreferences(payload));

      await TenantService.updateUserPreferences(payload);
      dispatch(fetchUserPreferences());
    } catch (err) {
      console.error(
        'Failed to update user preferences for freezing column: ',
        err
      );
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between bg-gray1 p-s">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={props?.title ? props?.title : 'Select Substitutes'}
            className="fw-m fs-l"
          />
        </div>
        <div className="row-reverse">
          <DKButton
            title={'Save'}
            onClick={onSave}
            className={`bg-button text-white`}
          />
          <DKButton
            title={'Close'}
            className="bg-white border-m mr-r"
            onClick={() => props.onClose?.()}
          />
        </div>
      </div>
    );
  };

  /* const getSelectedProductsView = () => {
    return (
      <div className="column parent-width gap-2" style={{ width: 300 }}>
        <div className="row gap-1">
          <span
            className="p-s border-radius-s fw-m cursor-hand"
            style={{
              backgroundColor: 'rgb(235, 243, 255)'
            }}
            onClick={() => {
              setExpandedDetails(!expandedDetails);
            }}
          >
            {selectedProducts.length} substitutes
          </span>
          <span>selected</span>
        </div>
      </div>
    );
  };
  const showExpandedDetails = () => {
    return (
      <>
        {expandedDetails && selectedProducts?.length > 0 && (
          <div
            className="row parent-height align-items-start justify-content-start flex-wrap bg-gray1 border-radius-s p-r mt-s overflow-scroll hide-scroll-bar paren-size position-relative"
            style={{ gap: 5 }}
          >
            {selectedProducts?.map((product: any, index: number) =>
              getSubstitute({
                ...product,
                editProductId: props?.product?.productId,
                onClick: () => {},
                selected: true
              })
            )}
            {Utility.isEmpty(selectedProducts) &&
              selectedProducts?.length > 0 && (
                <div className="column parent-size align-items-center justify-content-center">
                  <DKLabel className="text-gray" text="No Product Assigned" />
                  <DKLabel
                    className="text-gray"
                    text="Please assign products to template"
                  />
                </div>
              )}
          </div>
        )}
      </>
    );
  }; */

  const onPagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onSearch = (searchText: string) => {
    setSearchText(searchText);
  };

  const selectAllProducts = (value: any) => {
    var copyOfProducts = [...products];
    let copyOfSelectProducts = [...selectedProducts];

    copyOfProducts = copyOfProducts?.map((productItem: any) => {
      let thisProduct = { ...productItem };
      const alreadySelected = props?.currentConfiguration?.find(
        (itemProduct: any) => {
          return itemProduct?.productId === thisProduct?.productId;
        }
      );
      if (props?.restrictClick && alreadySelected) {
        return thisProduct;
      }
      // copyOfProducts[index]['selected'] = copyOfProducts[index]['selected'];
      thisProduct['selected'] = value?.selected || thisProduct.isProductPicked;
      const alreadySelectedInSelected = copyOfSelectProducts?.find(
        (itemProduct: any) => {
          return itemProduct?.productId === thisProduct?.productId;
        }
      );
      if (
        thisProduct['selected'] &&
        Utility.isEmpty(alreadySelectedInSelected)
      ) {
        copyOfSelectProducts.push(thisProduct);
      } else {
        copyOfSelectProducts = copyOfSelectProducts.filter(
          (item: any) => item.productId !== thisProduct.productId
        );
      }
      return thisProduct;
    });
    setSelectedProducts([...copyOfSelectProducts]);
    setProducts(copyOfProducts);
  };

  const getGridRows = (type: string) => {
    if (type === 'available') {
      return products;
    } else {
      let selectedProductsFiltered = searchText
        ? [...selectedProducts]?.filter(
            (productItem: any) =>
              productItem?.productName?.includes(searchText) ||
              productItem?.productId?.includes(searchText) ||
              productItem?.productDocumentSeqCode?.includes(searchText)
          )
        : [...selectedProducts];
      return selectedProductsFiltered;
    }
  };

  const getPullThroughProductsView = () => {
    let height = window.innerHeight - 50;
    return (
      <div
        className="column parent-width overflow-scroll hide-scroll-bar"
        style={{ maxHeight: height }}
      >
        <DKDataGrid
          title={''}
          filterTableName={'ProductsTablePopup'}
          needShadow={false}
          needBorder={true}
          allowRowEdit={isReadonly ? false : true}
          allowRowAdd={false}
          allowColumnAdd={false}
          allowColumnDelete={false}
          allowColumnSort={false}
          allowColumnEdit={true}
          allowColumnShift={true}
          allowColumnFreeze={true}
          allowSearch={false}
          buttons={[]}
          needTrailingColumn={true}
          allowDataExport={true}
          columns={gridColumns}
          rows={getGridRows(gridType)}
          currentPage={gridType === 'available' ? currentPage : 1}
          totalPageCount={gridType === 'available' ? totalPages : 1}
          onPrevPageTapped={() => {
            onPagination(currentPage - 1);
          }}
          onNextPageTapped={() => {
            onPagination(currentPage + 1);
          }}
          onSearch={onSearch}
          onPaginationClick={(requestedPageNumber: number) => {}}
          allowBulkOperation={isReadonly ? false : true}
          onRowUpdate={(data: any) => {}}
          onRowSelect={(selectedRow: any) => {
            let indexToSelectUnselect = selectedRow?.rowIndex;
            if (gridType === 'selected') {
              indexToSelectUnselect = products?.findIndex(
                (substitute: any) =>
                  substitute?.productId ===
                  selectedProducts[selectedRow?.rowIndex]?.productId
              );
            }

            if (
              selectedRow?.rowData &&
              selectedRow.rowData.isProductPicked &&
              !selectedRow.rowData.selected
            ) {
              selectedRow.rowData.selected = true;
              showAlert(
                `Can't deselect?`,
                'Substitute is picked or reserved for raw material, please unreserve/unpick first to remove this substitute.'
              );
              return;
            }

            onSubSelect(indexToSelectUnselect, selectedRow?.rowData?.selected);
          }}
          onColumnUpdate={onColumnUpdated}
          onColumnShift={onColumnShifted}
          onColumnFreezeToggle={onColumnFreezeToggle}
          isAllRowSelected={
            gridType === 'available'
              ? products?.every((item: any) => item.selected)
              : selectedProducts?.every((item: any) => item.selected)
          }
          onAllRowSelect={(data: any) => {
            selectAllProducts(data);
          }}
        />
      </div>
    );
  };

  const getTypeField = () => {
    return (
      <div
        className="row width-auto bg-gray1 border-radius-s border-m p-h-s p-v-xs"
        style={{ gap: 10 }}
      >
        <DKButton
          title={['Available']}
          className={
            gridType === 'available'
              ? 'bg-white border rounded-sm shadow-s-1 fw-m'
              : ''
          }
          style={{
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10
          }}
          onClick={() => {
            if (!isReadonly) {
              setGridType('available');
            }
          }}
        />
        <DKButton
          className={
            gridType === 'selected'
              ? 'bg-white border rounded-sm shadow-s-1 fw-m'
              : ''
          }
          style={{
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10
          }}
          title={`Selected (${getGridRows('selected')?.length ?? 0})`}
          onClick={() => {
            if (!isReadonly) {
              setGridType('selected');
            }
          }}
        />
      </div>
    );
  };
  const getBody = () => {
    return (
      <div className="p-r column parent-size overflow-scroll hide-scroll-bar">
        <div className="row justify-content-between">
          <div className="row justify-content-start gap-2">
            {getTypeField()}
            {loading && <DKSpinner />}
          </div>
          <div
            className="row"
            style={{
              width: 250
            }}
          >
            <DKInput
              className="column"
              type={INPUT_TYPE.TEXT}
              value={searchText}
              placeholder={
                props.title ? 'Search product...' : 'Search Substitutes...'
              }
              onChange={(searchText: any) => {
                setSearchText(searchText);
              }}
            />
          </div>
        </div>
        {getPullThroughProductsView()}
        {/* {getPaginationView()} */}
        {/* {showExpandedDetails()} */}
      </div>
    );
  };
  const onSubSelect = (index: number, selected: boolean) => {
    const copyOfProducts = deepClone(products);
    const alreadySelected = props?.currentConfiguration?.find(
      (itemProduct: any) => {
        return itemProduct?.productId === copyOfProducts[index]?.productId;
      }
    );
    if (props?.restrictClick && alreadySelected) {
      return;
    }
    // copyOfProducts[index]['selected'] = copyOfProducts[index]['selected'];
    copyOfProducts[index]['selected'] = selected;
    let copyOfSelectProducts = [...selectedProducts];
    if (copyOfProducts[index]['selected']) {
      copyOfSelectProducts.push(copyOfProducts[index]);
    } else {
      copyOfSelectProducts = copyOfSelectProducts.filter(
        (item: any) => item.productId !== copyOfProducts[index].productId
      );
    }
    setSelectedProducts([...copyOfSelectProducts]);
    setProducts(copyOfProducts);
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window p-0"
          style={{
            maxWidth: '90%',
            maxHeight: '98%',
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          {getBody()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};
export const getSubstitute = ({
  productId,
  editProductId,
  productName,
  onClick,
  selected,
  allowRemove,
  onRemove
}: any) => {
  if (
    (!Utility.isEmpty(productId) &&
      !Utility.isEmpty(editProductId) &&
      productId !== editProductId) ||
    (!Utility.isEmpty(productId) && Utility.isEmpty(editProductId))
  ) {
    return (
      <div
        className={`row bg-white border-radius-s width-auto p-xs bg-white border-m cursor-hand ${
          selected && 'bg-chip-blue border-blue text-app-color'
        }`}
        onClick={onClick}
      >
        <DKLabel text={productName} className="mr-s" />
        {allowRemove && (
          <DKIcon src={DKIcons.ic_close} className="ic-xs" onClick={onRemove} />
        )}
      </div>
    );
  }
};
