import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import StockAdjustmentService from '../../Services/StockAdjustment';
import WarehouseService from '../../Services/Warehouse';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  serialBatchCount: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchStockAdjustments = createAsyncThunk(
  'stockAdjustments',
  async () => {
    const response = await StockAdjustmentService.getStockAdjustmentByPage();
    return response;
  }
);

export const fetchSerialBatchCount = createAsyncThunk(
  'fetchSerialBatchCount',
  async (code: string) => {
    const response = await StockAdjustmentService.getSerialBatchCount(code);
    return response;
  }
);

export const fetchStockValuation = createAsyncThunk(
  'fetchStockValuation',
  async (fromDate: string) => {
    const response = await StockAdjustmentService.getStockValuation(fromDate);
    return response;
  }
);

export const fetchSourceWarehouses = createAsyncThunk(
  'fetchSourceWarehouses',
  async (wareHouseCode: string) => {
    const response = await StockAdjustmentService.getSourceWarehouses(
      wareHouseCode
    );
    return response;
  }
);

/** @deprecated */
export const fetchSourceWarehousesWithRejectedWh = createAsyncThunk(
  'fetchSourceWarehousesWithRejectedWh',
  async (wareHouseCode: string) => {
    const response =
      await StockAdjustmentService.getSourceWarehousesWithRejectedWh(
        wareHouseCode
      );
    return response;
  }
);
export const fetchWarehouseByCode = createAsyncThunk(
  'fetchWarehouseByCode',
  async (wareHouseCode: string) => {
    const response = await WarehouseService.getWarehouseByCode(wareHouseCode);
    return response;
  }
);

export const fetchAllProducts = createAsyncThunk(
  'fetchSourceWarehouses',
  async (wareHouseCode: string) => {
    const response = await StockAdjustmentService.getSourceWarehouses(
      wareHouseCode
    );
    return response;
  }
);

export const fetchStockAdjustmentAllProductsBrief = createAsyncThunk(
  'fetchStockAdjustmentAllProductsBrief',
  async () => {
    const response =
      await StockAdjustmentService.fetchStockAdjustmentProductBrief('');
    return response;
  }
);

export const StockAdjustmentSlice = createSlice({
  name: 'Stock Adjustment',
  initialState,
  reducers: {
    addStockAdjustmentColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToStockAdjustmentColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockAdjustments.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchStockAdjustments.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchSerialBatchCount.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchSerialBatchCount.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.serialBatchCount = action.payload;
      })
      .addCase(fetchStockValuation.fulfilled, (state, action) => {
        state.data.stockValuation = action.payload;
      })
      .addCase(fetchSourceWarehouses.fulfilled, (state, action) => {
        state.data.sourceWarehouses = action.payload;
      })
      .addCase(
        fetchSourceWarehousesWithRejectedWh.fulfilled,
        (state, action) => {
          state.data.sourceWarehousesWithRejectedWh = action.payload;
        }
      )
      .addCase(fetchWarehouseByCode.fulfilled, (state, action) => {
        state.data.warehouseByCode = action.payload;
      })
      .addCase(fetchStockAdjustmentAllProductsBrief.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchStockAdjustmentAllProductsBrief.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          state.data.allProductBriefData = action.payload;
        }
      );
  }
});

export const {
  addStockAdjustmentColumnConfig,
  addColumnToStockAdjustmentColumnConfig,
  updateColumnConfig
} = StockAdjustmentSlice.actions;

export const selectStockAdjustmentColumnConfig = (state: RootState) =>
  state.stockAdjustments.columnConfig;
export const selectStockAdjustmentColumnConfigTableId = (state: RootState) =>
  state.stockAdjustments.configTableId;

export const selectStockAdjustments = (state: RootState) =>
  state.stockAdjustments.data;

export const selectSerialBatchCount = (state: RootState) =>
  state.stockAdjustments.serialBatchCount;

export const selectStockValuation = (state: RootState) =>
  state.stockAdjustments.data.stockValuation;

export const selectSourceWarehouses = (state: RootState) =>
  state.stockAdjustments.data.sourceWarehouses;
export const selectSourceWarehousesWithRejectedWh = (state: RootState) =>
  state.stockAdjustments.data.sourceWarehousesWithRejectedWh;
export const selectWarehouseByCode = (state: RootState) =>
  state.stockAdjustments.data.warehouseByCode;

export const selectStockAdjustmentLoadingStatus = (state: RootState) =>
  state.stockAdjustments.gridContentLoadingStatus;

export const selectStockAdjustmentAllProductBrief = (state: RootState) =>
  state.stockAdjustments.data.allProductBriefData;
