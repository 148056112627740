import React, { useState, useEffect } from 'react';
import { DKSpinner, DKIcon, DKLabel } from 'deskera-ui-library';
import Invoices from '../../Invoices/Invoices';
import PaymentReceipts from './PaymentReceipt';
import Quotations from '../../Quotations/Quotations';
import SubscriptionTab from './SubscriptionTab';
import { localizedText } from '../../../Services/Localization/Localization';
import Utility from '../../../Utility/Utility';
import SalesOrders from '../../SalesOrders/SalesOrders';
import TenantService from '../../../Services/Tenant';
import { isSalesOrderVisible } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import ProductsByVendor from '../../PrefferedVendor/ProductsByVendor';

export enum CONTACT_DETAIL_TAB {
  QUOTES = 'Quotes',
  INVOICE = 'Invoices',
  SALES_ORDERS = 'Sales Orders',
  PAYMENT_RECEIPTS = 'Payment Receipts',
  PRODUCTS = 'Products',
  SUBSCRIPTION = 'Subscription'
}

interface ContactDetailCenterPanelProps {
  isLoaderCenterPanel?: boolean;
  contact?: any;
}

const ContactDetailCenterPanel: React.FC<ContactDetailCenterPanelProps> = (
  props
) => {
  const [activeTab, setActiveTab] = useState(CONTACT_DETAIL_TAB.INVOICE);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [showSubscriptionTab, setShowSubscriptionTab] = useState(
    // Utility.isDeskeraEmail()
    false
  );

  useEffect(() => {
    // Utility.isDeskeraEmail() && initiateSubscriptionFetch();
  }, [props.contact]);

  const initiateSubscriptionFetch = () => {
    let email: string = props.contact?.emailId;
    if (Utility.isEmptyObject(email)) return;

    setLoadingSubs(true);

    TenantService.getTenantsByEmail(email)
      .then((res: any) => {
        if (res) {
          setSubscriptionData(res);
        }

        setShowSubscriptionTab(true);
      })
      .catch((error: any) => {
        if (
          error?.errorCode === 1 ||
          error.errorMessage === 'Logged in user not admin'
        ) {
          setShowSubscriptionTab(false);
        }
      })
      .finally(() => {
        setLoadingSubs(false);
      });
  };

  const getTabs = () => {
    const tabs = [
      { name: 'Invoices', value: CONTACT_DETAIL_TAB.INVOICE, isHidden: false },
      {
        name: Utility.convertInTitleCase(localizedText('quotes')),
        value: CONTACT_DETAIL_TAB.QUOTES,
        isHidden: false
      },
      {
        name: 'Sales Orders',
        value: CONTACT_DETAIL_TAB.SALES_ORDERS,
        isHidden: !isSalesOrderVisible()
      },
      {
        name: 'Payment Receipts',
        value: CONTACT_DETAIL_TAB.PAYMENT_RECEIPTS,
        isHidden: false
      },
      {
        name: 'Products',
        value: CONTACT_DETAIL_TAB.PRODUCTS,
        isHidden: !Utility.addPreferredVendorEnabled()
      },
      {
        name: 'Subscriptions',
        value: CONTACT_DETAIL_TAB.SUBSCRIPTION,
        isHidden: !showSubscriptionTab
      }
    ];

    let tabView: any[] = [];
    tabs
      .filter((tab) => !tab.isHidden)
      .forEach((tab) => {
        tabView.push(
          <div
            className={`text-align-center ${
              activeTab === tab.value
                ? 'fw-m active-border text-app'
                : 'text-dark-gray'
            } cursor-hand`}
            style={{
              padding: '18px 1.8%'
            }}
            onClick={() => {
              setActiveTab(tab.value);
            }}
          >
            {tab.name}
          </div>
        );
      });
    return (
      <div
        className="row bg-white border-m-b hide-scroll-bar"
        style={{ overflowX: 'auto', minHeight: 58 }}
      >
        {tabView}
      </div>
    );
  };

  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute parent-width"
        style={{ top: 200, pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel
          text={`No permission to view ${(
            activeTab || CONTACT_DETAIL_TAB.INVOICE
          ).toLowerCase()}`}
          className="fw-m mt-l"
        />
        <DKLabel
          text={'Contact admin for more details'}
          className="text-gray mt-s"
        />
      </div>
    );
  };

  const getActivePanel = () => {
    switch (activeTab) {
      case CONTACT_DETAIL_TAB.QUOTES:
        return GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.QUOTATION.VIEW
        ) ? (
          <Quotations listByContact={true} contact={props.contact} />
        ) : (
          getNoDataView()
        );
      case CONTACT_DETAIL_TAB.SALES_ORDERS:
        return <SalesOrders listByContact={true} contact={props.contact} />;
      case CONTACT_DETAIL_TAB.PAYMENT_RECEIPTS:
        return <PaymentReceipts contact={props.contact} />;
      case CONTACT_DETAIL_TAB.PRODUCTS:
        return <ProductsByVendor contact={props.contact} />;
      case CONTACT_DETAIL_TAB.SUBSCRIPTION:
        return (
          <SubscriptionTab
            loading={loadingSubs}
            contact={props.contact}
            subscriptionData={subscriptionData}
          />
        );
      case CONTACT_DETAIL_TAB.INVOICE:
      default:
        return GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.VIEW
        ) ? (
          <Invoices listByContact={true} contact={props.contact} />
        ) : (
          getNoDataView()
        );
    }
  };

  return (
    <div className="column parent-size border-radius-s mb-m padding-top-mobile mobile-parent-width p-0">
      {getTabs()}
      <div
        className="column parent-width parent-height mt-m position-relative align-items-start hide-scroll-bar"
        style={{ overflowY: 'auto' }}
      >
        {!props.isLoaderCenterPanel && getActivePanel()}
        {props.isLoaderCenterPanel && (
          <div className="row parent-size bg-gray1 justify-content-center align-items-center position-absolute">
            <DKSpinner title="Loading Data..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactDetailCenterPanel;
