import React, { useEffect, useRef, useState } from 'react';
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKDateRangePicker,
  DKDataGrid
} from 'deskera-ui-library';
import { sortAndUpdateColumns } from './PaymentUtility';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  BOOKS_DATE_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEPOSIT_OPTIONS
} from '../../../Constants/Constant';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import DateFormatService from '../../../Services/DateFormat';
import { useAppSelector } from '../../../Redux/Hooks';
import { endOfYear, startOfYear } from 'date-fns';
import DepositService from '../../../Services/Deposit';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationDetails } from '../../../Models/Interfaces';
import PaymentDepositService from './PaymentService';
import { getAmountBlockWithCurrency } from '../../Reports/Financial/Parser/SharedParser';
import { CONTACT_LEFT_PANEL_WIDTH } from './';
import SideBarService from '../../../Services/sidebar';
import PrintPreview from '../../../SharedComponents/Printing/PrintPreview';

const COLUMN_CONFIG = [
  {
    name: 'Code',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'code',
    key: 'code',
    allowFilter: false
  },
  // {
  //   name: 'Contact',
  //   type: 'text',
  //   index: 3,
  //   options: null,
  //   required: false,
  //   width: 170,
  //   editable: false,
  //   hidden: false,
  //   uiVisible: true,
  //   systemField: true,
  //   columnCode: 'contactName',
  //   key: 'contactName',
  //   allowFilter: false
  // },
  {
    name: 'Total Amount',
    type: 'number',
    index: 5,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'amount',
    key: 'amount',
    allowFilter: false
  },
  {
    name: 'Payment Type',
    type: 'select',
    index: 8,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'paymentType',
    key: 'paymentType',
    id: 'paymentType',
    allowFilter: true,
    allowColumnSort: false,
    options: [
      {
        id: 'BANK_TRANSFER',
        name: 'Bank Transfer',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'CHEQUE',
        name: 'Cheque',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'CASH',
        name: 'Cash',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'OTHERS',
        name: 'Others',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'CARD',
        name: 'Card',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'CREDIT_NOTE',
        name: 'Credit Note',
        color: 'data-grid-badge-color-5'
      }
    ]
  },
  {
    name: 'Payment Date',
    type: 'date',
    index: 9,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'referenceDate',
    key: 'referenceDate',
    allowFilter: false
  },
  {
    name: 'Document Type',
    type: 'text',
    index: 2,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentType',
    key: 'documentType',
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Document Number',
    type: 'text',
    index: 1,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Amount Due',
    type: 'number',
    index: 6,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'dueAmount',
    key: 'dueAmount',
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Amount Paid',
    type: 'number',
    index: 7,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'paidAmount',
    key: 'paidAmount',
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Reference Number',
    type: 'number',
    index: 11,
    options: null,
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'referenceNumber',
    key: 'referenceNumber',
    allowFilter: false,
    allowColumnSort: false
  }
];
interface IPaymentReceiptsProps {}

const PaymentReceipts: React.FC<any> = (props: any) => {
  const [rowData, setRowData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const apiCallInProgress = useRef<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [filterDates, setFilterDates] = useState<any>({
    financialStartDate: Utility.getFinancialStartDate(),
    financialEndDate: Utility.getFinancialEndDate()
  });
  const [showHideCalendar, setShowHideCalendar] = useState<boolean>(false);
  const [showHidePrintPopup, setShowHidePrintPopup] = useState<boolean>(false);
  const [selectedReceipt, setSelectedReceipt] = useState<any>(null);
  const contactCode: any = props?.contact?.code;
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [parentDocumentCode, setParentDocumentCode] = useState<any>(null);
  const [parentDocumentType, setParentDocumentType] = useState<any>(null);
  const [originalData, setOriginalData] = useState<any>([]);

  // const settings = useAppSelector(selectTenantSettings);

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      let query = `documentDate>=${DateFormatService.getDateStrFromDate(
        filterDates.financialStartDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )},documentDate<=${DateFormatService.getDateStrFromDate(
        filterDates.financialEndDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )},contactCode=${contactCode}`;

      filters?.query?.forEach((row: any) => {
        query += `,${row.key}=${row.value[0]}`;
      });

      const config: PaymentDepositService = {
        ...PaymentDepositService.apiConfig,
        Query: query,
        SearchTerm: searchTerm,
        Limit: 20
      };
      loadPaymentReceipt(config);

      // getInitialFilterData();
    }
  }, [filterDates, searchTerm, filters]);

  // useEffect(() => {
  //   setShowHidePrintPopup(isNotEmpty(selectedReceipt));
  // }, [selectedReceipt]);

  const loadPaymentReceipt = (config: any) => {
    PaymentDepositService.apiConfig = config;
    PaymentDepositService.getDepositByPage()
      .then((res: any) => {
        const { content, pageable, totalPages } = res;
        setRowData(content);
        setOriginalData(content);
        setTotalPageCount(totalPages);
        setCurrentPage(+pageable?.pageNumber + 1);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onPagination = (requestedPageNumber: number) => {
    const config: PaymentDepositService = {
      ...PaymentDepositService.apiConfig,
      Page: requestedPageNumber - 1,
      SearchTerm: searchTerm
    };
    loadPaymentReceipt(config);
  };

  const parseRowData = (paymentList: any[]) => {
    let updatedRows: any = [];

    if (!Utility.isEmpty(paymentList)) {
      updatedRows = deepClone(paymentList);
      updatedRows = updatedRows.map((row: any) => {
        let paidAmount = 0;
        row.receivePaymentItemDtoList.forEach((list: any) => {
          paidAmount += list.paymentAmount;
        });
        let dueAmount = row.amount - paidAmount;
        // row.rowButtons = this.getActionButtons(row);
        // row.rowContextMenu = this.getRowContextMenu(row);
        row.documentSequenceCode =
          row.receivePaymentItemDtoList[0].documentSequenceCode;
        row.paymentType = row.paymentType = [row.paymentType];
        row.documentType =
          DEPOSIT_OPTIONS[`${row.receivePaymentItemDtoList[0].documentType}`];
        row.amount = getAmountBlockWithCurrency(row.amount, row.currency);
        row.dueAmount = getAmountBlockWithCurrency(dueAmount, row.currency);
        row.paidAmount = getAmountBlockWithCurrency(paidAmount, row.currency);

        row.allowRowEdit = true;
        row.rowContextMenu = getContextMenuForRow(row);
        return row;
      });
    }
    return updatedRows;
  };
  const setInvoiceForPrint = (invoice: any, rowIndex: any) => {
    const selectedRow = originalData[rowIndex];
    setParentDocumentCode(invoice.receivePaymentItemDtoList[0].documentCode);
    setParentDocumentType(invoice.receivePaymentItemDtoList[0].documentType);

    setShowPrintPreview(true);
    const ob: any = {
      amount: selectedRow.amount,
      bulkData: null,

      contactCode: selectedRow.contactCode,

      contraDocumentCode: null,

      currency: selectedRow.currency,

      documentCode: selectedRow.code,

      documentDate: DateFormatService.getDateFromStr(
        selectedRow.documentDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ).toISOString(),

      documentOrder: null,

      documentType: 'RECEIVE_PAYMENT',

      exchangeRate: selectedRow.exchangeRate,
      linkedDocumentSequence: null,
      linkedWhileCreatingNote: null,
      uid: null,

      whtAmount: null
    };
    setSelectedReceipt(ob);
  };
  const getDateRangePicker = () => {
    return (
      <div className=" position-absolute bg-white z-10 top-14 right-0">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => setShowHideCalendar(false)}
          showPresetList={true}
          startDate={filterDates.financialStartDate}
          selectedStartDate={filterDates.financialStartDate}
          selectedEndDate={filterDates.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setShowHideCalendar(false);
              setFilterDates({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
            }
          }}
        />
      </div>
    );
  };
  const getDateRangeString = () => {
    if (filterDates) {
      return (
        DateFormatService.getDateStrFromDate(filterDates.financialStartDate) +
        ' to ' +
        DateFormatService.getDateStrFromDate(filterDates.financialEndDate)
      );
    } else {
      return '';
    }
  };

  const getDocumentGridHeaderButtons = () => {
    let buttons = [];
    buttons.push({
      title: getDateRangeString(),
      className: 'border-m bg-white ml-r position-relative width-auto',
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });
    return buttons;
  };
  const getContextMenuForRow = (invoice: any) => {
    const menuItems = [];
    menuItems.push({
      title: 'View',
      icon: DKIcons.ic_view,
      onClick: ({ rowIndex, rowData }: any) =>
        setInvoiceForPrint(rowData, rowIndex)
    });
    return menuItems;
  };

  const onFilter = (data: any) => {
    setFilters(data);
  };
  const getNoDataView = () => {
    return (
      <div
        className={`column parent-size align-items-center justify-content-center mb-xl ${
          !props.isNoDataAbsolute ? 'position-absolute' : ''
        }`}
        style={{ pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel className="fs-m fw-m mt-l" text={'No data found'} />
        <DKLabel
          className="text-dark-gray mt-s text-align-center"
          text={'No data available for your selection.'}
        />
      </div>
    );
  };
  const sortPR = (data: any) => {
    let config: any = {
      ...PaymentDepositService.apiConfig,
      SortDir: data.order,
      Sort: data.columnData.key
    };
    loadPaymentReceipt(config);
  };
  return (
    <div
      className={`column mobile-max-width-100 parent-width parent-height margin-empty-mobile ${
        contactCode ? 'mt-s' : ''
      }`}
    >
      <div className="parent-width row position-relative">
        {showHideCalendar && getDateRangePicker()}
      </div>
      <div className="column mt-s parent-width mb-xl position-relative flex-1">
        <DKDataGrid
          title={'💸 Payment Receipts'}
          documentType={'Payment'}
          rows={parseRowData(rowData)}
          columns={sortAndUpdateColumns(COLUMN_CONFIG)}
          needBoldTheme={true}
          allowSearch={true}
          allowFilter={true}
          needTrailingColumn={true}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          filterData={filters?.query}
          onFilter={onFilter}
          width={SideBarService.getContainerWidth() - CONTACT_LEFT_PANEL_WIDTH}
          onSearch={(searchTerm: any) => {
            setSearchTerm(searchTerm);
          }}
          onPrevPageTapped={() => onPagination(currentPage - 1)}
          onNextPageTapped={() => onPagination(currentPage + 1)}
          extraWidth={CONTACT_LEFT_PANEL_WIDTH}
          allowRowEdit={true}
          updating={apiCallInProgress.current}
          buttons={getDocumentGridHeaderButtons()}
          onRowOpenClick={({ rowData, rowIndex }: any) =>
            setInvoiceForPrint(rowData, rowIndex)
          }
          allowBulkOperation={false}
          onSort={(data: any) => sortPR(data)}
        />
        {rowData?.length === 0 && getNoDataView()}
      </div>
      {selectedReceipt && (
        <PrintPreview
          documentType={'RECEIVE_PAYMENT'}
          document={selectedReceipt}
          closePreview={(val: boolean) => {
            setSelectedReceipt(null);
          }}
          parentDocumentType={parentDocumentType}
          parentDocumentCode={parentDocumentCode}
          uid={null}
        />
      )}
    </div>
  );
};

export default PaymentReceipts;
