
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"

import LineItemsParser from "../LineItemsParser"

export default class ServiceRequisitionParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //company name
        var companyName = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                companyName = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = companyName
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, dataFromAPI.tenantInfo.address, undefined, undefined, undefined, undefined, undefined)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

         // billing to address
         var clientBillToAddressObj = new Address(dataFromAPI.billToName, dataFromAPI.billToAddress, dataFromAPI.contactInfo?.pan, dataFromAPI.contactInfo?.gstin, dataFromAPI.contactInfo?.contactNumber, dataFromAPI.contactInfo?.documentSequenceCode, OwnerType.contact)
         templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
         templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
 
         // shipping to address
         var clientShipToAddressObj = new Address(dataFromAPI.shipToName, dataFromAPI.shipToAddress, dataFromAPI.contactInfo?.pan, dataFromAPI.contactInfo?.gstin, dataFromAPI.contactInfo?.contactNumber, dataFromAPI.contactInfo?.documentSequenceCode, OwnerType.contact)
         templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
         templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

         //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber
        //date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)

        //contact
        if (dataFromAPI.tenantInfo) {
            if (Array.isArray(dataFromAPI.tenantInfo.billingAddresses)) {
                templateDataToReturn.mobileNumber = dataFromAPI.tenantInfo.billingAddresses.find(bill => bill.mobileNumber)?.mobileNumber
            }
            templateDataToReturn.contactNumber = dataFromAPI.tenantInfo.contactNumber
        }

        //authorised date
        if(dataFromAPI.approvedBy && dataFromAPI.createdDate) {
            templateDataToReturn.approvedDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.createdDate), templateDataToReturn.dateFormat)
        }

        //due date
        templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.shipByDate), templateDataToReturn.dateFormat)
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        //additional date
        var showAdditionalDate = false
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
            showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
        }

        if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
            templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.showAdditionalDate = showAdditionalDate;

        templateDataToReturn.approvedBy = dataFromAPI.approvedBy;
        templateDataToReturn.requisitionCreatedBy = dataFromAPI.createdBy
        templateDataToReturn.createdBy = dataFromAPI.createdBy;
        templateDataToReturn.createdDate = dataFromAPI.createdDate;

        //custom fields

        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        const customFieldsObject = {};
        if (Array.isArray(dataFromAPI.customFields)) {
            dataFromAPI.customFields.forEach((field) => {
                customFieldsObject[field['shortName']] = field['value'];
                if (field['shortName'] === "remarks" || field['shortName'] === "service_description") {
                    templateDataToReturn[field['shortName']] = field['value']
                }
                customFieldsObject['scNo'] = templateDataToReturn.refNumber
                customFieldsObject['SCDate'] = dataFromAPI.date
                customFieldsObject['SCType'] = 'Service'
                customFieldsObject['requestedBy'] = dataFromAPI.createdBy
            })
            templateDataToReturn.customFields = dataFromAPI.customFields;
        }
        templateDataToReturn.customFieldsObject = customFieldsObject;

        // line items
        templateDataToReturn.lineItems = dataFromAPI.lineItems.map((lineItem, index) => {
            let amount = 0;
            let rate = 0;
            lineItem['code'] = index + 1
            if (lineItem.customFields) {
                rate = lineItem.customFields.find(li => li.shortName === 'rate')?.value || 0
                amount = rate * lineItem.quantity || 0
            }

            return {
                ...lineItem,
                amount: amount || 0,
                description: lineItem['description'],
                service_description: templateDataToReturn['service_description'],
                scheduledQuantity: lineItem.quantity,
                scheduledDate: null,
                code: lineItem['code'] || index + 1,
                rate: rate,
                unitPrice: lineItem["unitPrice"] || '0.00',
                tax: lineItem["tax"] || '0',
                amountBeforeTax: lineItem["amountBeforeTax"] || '0',
                requestedQty: lineItem["requestedQty"],
                requiredQty: lineItem['requiredQty'] || 1,
                quantity: lineItem["quantity"] || '1',
                discount: lineItem["discount"] || '0',
                issuedQty: lineItem["issuedQty"] || 1,
                uom: lineItem['uom'],
                product: lineItem['product']
            }
        });
        templateDataToReturn.lineItems = LineItemsParser.getServiceRequisitionLineItems(templateDataToReturn, templateDataToReturn.dateFormat)

        templateDataToReturn.currency = dataFromAPI.currency;
        templateDataToReturn.date = dataFromAPI.date;
        templateDataToReturn.ownerEmail = dataFromAPI.tenantInfo.email;
        templateDataToReturn.receiveByDate = dataFromAPI.receiveByDate;
        templateDataToReturn.shipByDate = dataFromAPI.shipByDate;
    
        return templateDataToReturn
    }
}
