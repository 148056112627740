import { promisify } from 'util';
import ApiConstants from '../Constants/ApiConstants';
import { ERROR } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor';

export interface BankAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: string;
  AccountCode?: string;
  StatementReconcliedStatus?: any;
  CdType?: any;
  Reconciled?: any;
  IsMatchedByUser?: any;
  QueryParam?: any;
}

// search=
// SKIP_REQUEST_INTERCEPTOR=true
// limit=10
// page=0
// sort=name
// sortDir=asc

export const defaultConfig: BankAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  StatementReconcliedStatus: '',
  CdType: '',
  Reconciled: '',
  Sort: 'documentCode',
  SortDir: 'DESC',
  IsMatchedByUser: '',
  QueryParam: ''
};

class BanksService {
  static apiConfig: BankAPIConfig = defaultConfig;

  static fetchBanksList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?&search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&query=${
      Utility.isEmpty(this.apiConfig.Query)
        ? '&sort=name&sortDir=asc'
        : this.apiConfig.Query
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANKS + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(ERROR);
        }
      )
      .catch((error: any) => {
        return Promise.reject(ERROR);
      });
  }
  static fetchBanksListSummary() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `/summary`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANKS + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchBankRulesList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANK_RULES + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getServiceProvider(country: string) {
    let url = ApiConstants.URL.BANKS.SERVICE_PROVIDER + country.toLowerCase();
    return http.get(url).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static previewBankRule(data: any) {
    const finalURL = ApiConstants.URL.BANKS.PREVIEW_BANK_RULE;
    return http
      .post(finalURL, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createBankRule(data: any) {
    const finalURL = ApiConstants.URL.BANKS.CREATE_BANK_RULE;
    return http
      .post(finalURL, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateBankRule(data: any) {
    const finalURL = ApiConstants.URL.BANKS.CREATE_BANK_RULE + `/${data.id}`;
    return http
      .put(finalURL, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchBankBalanceSummary(query: string) {
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BALANCE_SUMMARY +
      (Utility.isEmpty(query) ? '' : `?${query}`);
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchBankTransactionsList(bankCode: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANK_TRANSACTION +
      `/${bankCode}` +
      queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchBankTransactionsListByStatement(bankCode: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&query=${
      this.apiConfig.Query ? ',' + this.apiConfig.Query : ''
    }${
      this.apiConfig.StatementReconcliedStatus
        ? ',statementReconcliedStatus=' +
          this.apiConfig.StatementReconcliedStatus
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANK_TRANSACTIONS_BY_STATEMENT +
      `/${bankCode}` +
      queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchBankDocumentsList(bankCode: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?search=${this.apiConfig.SearchTerm}&sortDir=${
      this.apiConfig.SortDir
    }&sort=${this.apiConfig.Sort}&limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&accountCode=${bankCode}&query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.DOCUMENTS + queryString;

    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  //Connect Bank Apis

  static callConnectBank(data: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string = ApiConstants.URL.BANKS.BANK_CONNECT;
    return http
      .post(`${finalEndpoint}`, data)

      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPlaidInitialToken() {
    return http
      .get(ApiConstants.URL.BASE + ApiConstants.URL.BANKS.PLAID_DATA)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getPlaidAccessToken(data: any) {
    return http
      .post(
        ApiConstants.URL.BASE + ApiConstants.URL.BANKS.GET_PLAID_ACCESS_TOKEN,
        data
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getPlaidBankAccounts(query: any) {
    let finalUrl =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.GET_PLAID_ACCOUNTS(query);
    return http
      .get(finalUrl)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getUnlinkedAccounts() {
    let finalURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.GET_BANK_ACCOUNTS;
    return http
      .get(finalURL)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getPlaidTransactionsConnectBank(query: any) {
    let finalURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.CONNECT_PLAID(query);
    return http
      .get(finalURL)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static downLoadSourceFile = (path: any) => {
    return http
      .get(ApiConstants.URL.BANKS.SOURCE_DOWNLOAD(path), {
        responseType: 'text'
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error loading liabilities: ', err);
        return Promise.reject(err);
      });
  };

  static reconnectBank(data: any) {
    const finalURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.RECONNECT_BANK;
    return http
      .post(finalURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static disconnectBank(data: any) {
    const reqURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.DISCONNECT_BANK_ACCOUNNT;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  //Sync Transactions
  static syncPlaidTransactions(id: string) {
    const finalURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.SYNC_TRANSACTIONS_PLAID(id);
    return http
      .get(finalURL)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        Promise.reject(err);
      });
  }

  static getReconcileSummary(query: any) {
    const finalURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.UNRECONCILED_SUMMARY(query);
    return http
      .get(finalURL)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getAllTransactionsForReconciliation = (query: string) => {
    const finalURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.TRANSACTIONS_RECONCILIATION(query);
    return http
      .get(finalURL)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  static matchBankTransaction(data: any) {
    const reqURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.MATCH_TRANSACTION;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static unMatchBankTransaction(data: any) {
    const reqURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.UNMATCH_TRANSACTION;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static unReconcilBankTransaction(data: any) {
    const reqURL = ApiConstants.URL.BASE + ApiConstants.URL.BANKS.UNRECONCIL;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getRuleDetailForTransactionId(transactionId: string) {
    const reqURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.GET_RULE_DETAILS(transactionId);
    return http
      .get(reqURL)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((err: any) => {
        return Promise.resolve(err);
      });
  }

  static desposit(data: any) {
    const reqURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.PAYMENT_RECEIVE_DEPOSIT_GROUP;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static expense(data: any) {
    const reqURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.PAYMENT_MAKE_EXPENSE_GROUP;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static reconcile(data: any) {
    const reqURL = ApiConstants.URL.BASE + ApiConstants.URL.BANKS.RECONCILE;
    return http
      .post(reqURL, data)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateStatus(payload: any) {
    const queryString = `?status=${payload.status}`;
    const finalEndpoint = ApiConstants.URL.BANKS.UPDATE_STATUS + queryString;
    try {
      return http.put(finalEndpoint, payload.accountCode);
    } catch (err) {
      console.error('Error changing status of account: ', err);
      return Promise.reject(err);
    }
  }

  static deleteRule(payload: any) {
    const finalEndpoint = ApiConstants.URL.BANKS.DELETE_RULE;
    try {
      return http.delete(finalEndpoint, { data: payload.id });
    } catch (err) {
      console.error('Error while deleting rule: ', err);
      return Promise.reject(err);
    }
  }

  static fetchBankTransactionSummary(query: any) {
    const reqURL =
      ApiConstants.URL.BASE +
      ApiConstants.URL.BANKS.BANK_TRANSACTION_SUMMARY(query);
    return http
      .get(reqURL)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((err: any) => {
        return Promise.resolve(err);
      });
  }

  static updateRuleStatus(payload: any) {
    const finalEndpoint = ApiConstants.URL.BANKS.UPDATE_RULE_STATUS;
    try {
      return http.patch(finalEndpoint, payload.id);
    } catch (err) {
      console.error('Error changing status of the rule: ', err);
      return Promise.reject(err);
    }
  }

  static dkBulkDelete(payload: any) {
    const finalEndpoint = ApiConstants.URL.BANKS.DK_BULK_DELTE;
    try {
      return http.delete(finalEndpoint, { data: payload.id });
    } catch (err) {
      console.error('Error while deleting deskera transaction: ', err);
      return Promise.reject(err);
    }
  }

  static bankReceivePayment(payload: any) {
    const reqURL =
      ApiConstants.URL.BASE + ApiConstants.URL.BANKS.BANK_RECEIVE_PAYMENT;
    return http
      .post(reqURL, payload)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static contactUs(payload: any) {
    const reqUrl = ApiConstants.URL.BASE + ApiConstants.URL.BANKS.CONTACT_US;
    return http
      .post(reqUrl, payload)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getBanksApiUrl(params: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const { BASE, BANKS } = ApiConstants.URL;
    const url = `${BASE}${BANKS.FETCH_BANKS}`;

    const finalEndpoint: string =
      url +
      `?&search=${params.SearchTerm}&limit=${params.Limit}&page=${params.Page}`;

    return finalEndpoint;
  }

  static bulkDeleteTransaction(payload: any) {
    const finalEndpoint = ApiConstants.URL.BANKS.BULK_TRANSACTION_DELETE;
    try {
      return http.put(finalEndpoint, payload.ids);
    } catch (err) {
      console.error('Error changing status of account: ', err);
      return Promise.reject(err);
    }
  }

  static callRecordCountAPI() {
    return http
      .get('reports/common/record-count?module=bank')
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((err: any) => {
        return Promise.resolve(err);
      });
  }
}

export default BanksService;
