import { FC, useEffect, useState } from 'react';
import {
  DKLabel,
  DKSpinner,
  Toggle,
  DKButton,
  DKIcons,
  INPUT_TYPE,
  DKInput,
  DKCheckMark,
  showAlert
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import Utility, { deepClone } from '../../../Utility/Utility';
import { CallBackPayloadType } from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import { isDesktopOrTablet } from '../../../Utility/ViewportSizeUtils';
import {
  IRateAnalysisPayload,
  saveRateAnalysis
} from '../../../Services/RateAnalysis';
import { isSalesOrderVisible } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';

export interface RateAnalysisPayloadProps {
  onSave?: (settings: IRateAnalysisPayload) => void;
  onCancel?: () => void;
  passingInteraction?: (callback: CallBackPayloadType) => void;
}

interface ICommissionInputError {
  nameError: boolean;
  nameErrorMessage: string;
  valueError: boolean;
  valueErrorMessage: string;
}
const initialStateForCommissionInputErrors: ICommissionInputError[] = [
  {
    nameError: false,
    nameErrorMessage: '',
    valueError: false,
    valueErrorMessage: ''
  }
];

const RateAnalysis: FC<RateAnalysisPayloadProps> = (props) => {
  const [select, setSelect] = useState<any>([0]);
  const currentSettings = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();
  const [commissionChargesInputErrors, setCommissionChargesInputErrors] =
    useState<ICommissionInputError[]>(initialStateForCommissionInputErrors);
  useEffect(() => {
    const updated: any = currentSettings.additionalSettings.RATE_ANALYSIS;
    if (!Utility.isEmpty(updated)) {
      setFormState(updated);
    }
  }, [currentSettings]);

  const initialState: IRateAnalysisPayload = {
    ENABLE_FOR_SALES_ORDER: false,
    ENABLE_FOR_QUOTATION: false,
    ENABLE_FOR_SALES_INVOICE: false,
    PRODUCT_PRICE_METHOD: '',
    COMMISSION_CHARRGES: []
  };

  const [formState, setFormState] = useState<IRateAnalysisPayload>(
    deepClone(initialState)
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  function validateCommissionCharges(
    commissionCharges: IRateAnalysisPayload['COMMISSION_CHARRGES'] = []
  ) {
    const validatedCharges = commissionCharges.map((charge) => {
      const label = charge.label;
      const value = charge.value;
      // remove blank spaces from label
      let nameError = false;
      let nameErrorMessage = '';
      let valueError = false;
      let valueErrorMessage = '';
      if (!label || label.trim() === '') {
        nameError = true;
        nameErrorMessage = 'Commission cannot be empty.';
      }
      if (!value || Number(value) <= Number(0)) {
        valueError = true;
        valueErrorMessage = 'Value cannot be empty or zero.';
      }

      return { nameError, nameErrorMessage, valueError, valueErrorMessage };
    });
    return validatedCharges;
  }

  /** Renderers Goes Here **/

  const commissionFieldLabel = () => {
    return (
      <div
        className="column parent-width mt-l"
        style={{ width: isDesktopOrTablet() ? '70%' : '100%' }}
      >
        <DKLabel text="Commission Charges" className="fs-m fw-m mb-r" />
        {(formState['COMMISSION_CHARRGES']?.length || 0) === 0 && (
          <DKLabel text="No Commision Charges added" className="text-gray" />
        )}
        {(formState['COMMISSION_CHARRGES']?.length || 0) > 0 && (
          <div className={`column parent-width`}>
            {formState['COMMISSION_CHARRGES']?.map(
              (element: any, index: any) => {
                return (
                  <>
                    <div
                      className={`row mb-l ${
                        isDesktopOrTablet() ? '' : 'row-responsive'
                      }`}
                      style={{ gap: 12 }}
                    >
                      <div className="column parent-width">
                        <DKInput
                          title="Commission Name"
                          required={true}
                          canValidate={
                            commissionChargesInputErrors[index]?.nameError
                          }
                          validator={(value: number) =>
                            !commissionChargesInputErrors[index]?.nameError
                          }
                          errorMessage={
                            commissionChargesInputErrors[index]
                              ?.nameErrorMessage
                          }
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          value={
                            formState['COMMISSION_CHARRGES']?.[index].label
                          }
                          onChange={(event: any) => {
                            let copyOfFormState = deepClone(formState);
                            if (
                              copyOfFormState['COMMISSION_CHARRGES']?.[index]
                            ) {
                              copyOfFormState['COMMISSION_CHARRGES'][
                                index
                              ].label = event;

                              setFormState(copyOfFormState);
                              const newValidate = validateCommissionCharges(
                                copyOfFormState['COMMISSION_CHARRGES']
                              );
                              setCommissionChargesInputErrors(newValidate);
                            }
                          }}
                        />
                      </div>
                      <div
                        className="column parent-width"
                        style={{ marginTop: isDesktopOrTablet() ? 0 : 5 }}
                      >
                        <DKInput
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          title={
                            formState['COMMISSION_CHARRGES']?.[index].isPercent
                              ? 'Value(%)'
                              : 'Value'
                          }
                          canValidate={
                            commissionChargesInputErrors[index]?.valueError
                          }
                          validator={(value: number) =>
                            !commissionChargesInputErrors[index]?.valueError ||
                            value > 0
                          }
                          errorMessage={
                            commissionChargesInputErrors[index]
                              ?.valueErrorMessage
                          }
                          value={
                            formState['COMMISSION_CHARRGES']?.[index].value
                          }
                          type={INPUT_TYPE.NUMBER}
                          required={true}
                          onChange={(text: number) => {
                            let copyOfFormState = deepClone(formState);
                            if (
                              copyOfFormState['COMMISSION_CHARRGES']?.[index]
                            ) {
                              copyOfFormState['COMMISSION_CHARRGES'][
                                index
                              ].value = text;
                              setFormState(copyOfFormState);
                              const newValidate = validateCommissionCharges(
                                copyOfFormState['COMMISSION_CHARRGES']
                              );
                              setCommissionChargesInputErrors(newValidate);
                            }
                          }}
                        />
                      </div>
                      <div className="column width-auto">
                        <div
                          className="row"
                          style={{
                            marginTop: isDesktopOrTablet() ? 20 : 10
                          }}
                        >
                          <div className="column" style={{ width: 100 }}>
                            <DKCheckMark
                              title={'is Percent'}
                              isSelected={
                                formState['COMMISSION_CHARRGES']?.[index]
                                  .isPercent
                              }
                              onClick={() => {
                                let copyOfFormState = deepClone(formState);
                                if (
                                  copyOfFormState['COMMISSION_CHARRGES']?.[
                                    index
                                  ]
                                ) {
                                  copyOfFormState['COMMISSION_CHARRGES'][
                                    index
                                  ].isPercent =
                                    !copyOfFormState['COMMISSION_CHARRGES']?.[
                                      index
                                    ].isPercent;

                                  setFormState(copyOfFormState);
                                }
                              }}
                              className={''}
                            />
                          </div>

                          <DKButton
                            className={'p-0'}
                            onClick={() => {
                              removeCompare(index);
                            }}
                            icon={DKIcons.ic_delete}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        )}
        <div className="row mt-xl">
          <DKButton
            className={'border-m'}
            onClick={addCategory}
            title={'+ Add Commission'}
          />
        </div>
      </div>
    );
  };

  const enableProductPriceMethod = () => {
    return (
      <div className="column parent-width border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Select Product Price Method" />
          <div style={{ width: '25%' }}>
            <DKInput
              required={true}
              options={['FIFO', 'AVERAGE', 'PURCHASE PRICE']}
              onChange={(typeObj: any) => {
                setSelect(typeObj);
                switch (typeObj[0]) {
                  case 0:
                    setFormState({
                      ...formState,
                      PRODUCT_PRICE_METHOD: 'FIFO'
                    });
                    break;
                  case 1:
                    setFormState({
                      ...formState,
                      PRODUCT_PRICE_METHOD: 'AVERAGE'
                    });

                    break;
                  case 2:
                    setFormState({
                      ...formState,
                      PRODUCT_PRICE_METHOD: 'PURCHASE PRICE'
                    });

                    break;
                  default:
                    setFormState({
                      ...formState,
                      PRODUCT_PRICE_METHOD: 'FIFO'
                    });
                    break;
                }
              }}
              value={[select]}
              type={INPUT_TYPE.SELECT}
            />
          </div>
        </div>
      </div>
    );
  };

  const enableRateAnalysisSO = () => {
    return (
      <div className="column parent-width  mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Enable Rate Analysis For Sales Order" />
          <Toggle
            color="bg-app"
            className="box-content"
            isOn={formState.ENABLE_FOR_SALES_ORDER}
            onChange={() => {
              let copyOfFormState = deepClone(formState);
              copyOfFormState.ENABLE_FOR_SALES_ORDER =
                !copyOfFormState.ENABLE_FOR_SALES_ORDER;

              setFormState(copyOfFormState);
            }}
          />
        </div>
      </div>
    );
  };

  const enableRateAnalysisQuotation = () => {
    return (
      <div className="column parent-width  mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Enable Rate Analysis For Quotation" />
          <Toggle
            color="bg-app"
            className="box-content"
            isOn={formState.ENABLE_FOR_QUOTATION}
            onChange={() => {
              let copyOfFormState = deepClone(formState);
              copyOfFormState.ENABLE_FOR_QUOTATION =
                !copyOfFormState.ENABLE_FOR_QUOTATION;

              setFormState(copyOfFormState);
            }}
          />
        </div>
      </div>
    );
  };

  const enableRateAnalysisInvoice = () => {
    return (
      <div className="column parent-width  mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Enable Rate Analysis For Invoice" />
          <Toggle
            color="bg-app"
            className="box-content"
            isOn={formState.ENABLE_FOR_SALES_INVOICE}
            onChange={() => {
              let copyOfFormState = deepClone(formState);
              copyOfFormState.ENABLE_FOR_SALES_INVOICE =
                !copyOfFormState.ENABLE_FOR_SALES_INVOICE;

              setFormState(copyOfFormState);
            }}
          />
        </div>
      </div>
    );
  };

  const addCategory = () => {
    const copyOfFormState = deepClone(formState);
    copyOfFormState['COMMISSION_CHARRGES']?.push({
      label: '',
      value: 0,
      isPercent: false
    });
    setFormState({ ...copyOfFormState });
  };

  const removeCompare = (index: number) => {
    const copyOfFormState = deepClone(formState);
    copyOfFormState['COMMISSION_CHARRGES']?.splice(index, 1);
    const copyCommissionChargesInputErrors = [...commissionChargesInputErrors];
    copyCommissionChargesInputErrors.splice(index, 1);
    setCommissionChargesInputErrors(copyCommissionChargesInputErrors);
    setFormState({ ...copyOfFormState });
  };

  const saveSettings = () => {
    const copyOfFormState = deepClone(formState);
    const commissionCharges = copyOfFormState['COMMISSION_CHARRGES'];
    const validation = validateCommissionCharges(commissionCharges);
    // check if any index has nameError or valueError true
    let hasError = false;
    validation.forEach((item: any) => {
      if (item?.nameError || item?.valueError) {
        hasError = true;
      }
    });
    if (hasError) {
      showAlert('Warning!', 'Please fill all the fields with valid data');
      setCommissionChargesInputErrors(validation);
      return;
    }
    setIsSaving(true);
    try {
      saveRateAnalysis(formState).then((res: any) => {
        dispatch(fetchCurrentTenantInfo());
        setIsSaving(false);
      });
    } catch (error) {
      console.error('Error while saving rate analysis setting: ', error);
      setIsSaving(false);
    }
  };

  return (
    <div className="column parent-size">
      <div
        className="column parent-size pt-r pb-l overflow-y-auto hide-scroll-bar flex-1"
        style={{ height: 'calc(100% - 52px)' }}
      >
        {isSalesOrderVisible() && enableRateAnalysisSO()}
        {enableRateAnalysisQuotation()}
        {enableRateAnalysisInvoice()}
        {enableProductPriceMethod()}
        {commissionFieldLabel()}
      </div>
      <div
        className="column parent-width bg-gray2"
        style={{ minHeight: 1 }}
      ></div>
      <div className="column parent-width p-v-s">
        <div
          className={`row width-auto ${
            isSaving ? 'border-radius-m border-m' : ''
          }`}
        >
          <DKButton
            title={isSaving ? 'Saving' : 'Save'}
            onClick={() => {
              saveSettings();
            }}
            disabled={isSaving}
            className={`${
              isSaving ? 'border-radius-none text-gray' : 'bg-button text-white'
            }`}
          />
          {isSaving && (
            <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
          )}
        </div>
      </div>
    </div>
  );
};

export default RateAnalysis;
