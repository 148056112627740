export enum STATUS_BG_CLASS {
  OPEN = 'bg-blue',
  IN_PROGRESS = 'bg-yellow',
  COMPLETED = 'bg-green',
  CANCELLED = 'bg-red',
  PENDING = 'bg-red',
  ON_HOLD = 'bg-orange'
}
export enum STATUS_TEXT {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  COMPLETED = '✓ Completed',
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  ON_HOLD = 'On Hold'
}
export const INPUT_TITLE_STYLE = { color: "rgb(100, 100, 100)" };
