import {
  BOOKS_DATE_FORMAT,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../../Constants/Constant';
import { DropdownTag, InputTag } from '../../Models/NewContact';
import { CalendarType } from '../../Models/PaymentPopup';
import DateFormatService from '../../Services/DateFormat';

export interface bulkPaymentState {
  documentItems: any[];
  totalPayment: number;
  totalDueAmount: number;
  currency: string;
  documentCurrency: string;
  baseExchangeRate: number;
  baseTotalAmount: number;
  documentDate: CalendarType;
  paymentDetails: {
    paymentAccountGroup: any;
    payFrom: DropdownTag<string>;
    paymentType: DropdownTag<string>;
    paymentAmount: InputTag<string>;
    conversionRate: InputTag<string>;
    additionalPaymentDetails: {
      referenceDate: CalendarType;
      referenceNumber: InputTag<string>;
      isActive: boolean;
    };
  };
}

export const initialBulkPaymentState: bulkPaymentState = {
  documentItems: [],
  totalPayment: 0,
  totalDueAmount: 0,
  currency: '',
  documentCurrency: '',
  baseExchangeRate: 1,
  baseTotalAmount: 0,
  documentDate: {
    isOpen: false,
    date: new Date()
  },
  paymentDetails: {
    paymentAccountGroup: '',
    payFrom: {
      key: '',
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    paymentType: {
      key: '',
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    paymentAmount: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    },
    conversionRate: {
      key: '',
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    additionalPaymentDetails: {
      referenceDate: {
        isOpen: false,
        date: DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      },
      referenceNumber: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      isActive: false
    }
  }
};

export interface DocumentPaymentItemDto {
  documentCode: string;
  documentType: string;
  exchangeRate: number;
  paymentAmount: number;
  documentSequenceCode: string;
}

export interface BulkPaymentItemDto {
  accountCodePayFrom?: string;
  accountCodePayTo?: string;
  accountName: string;
  amount: number;
  closeDate: string;
  contactCode: string;
  contactName: string;
  currency: string;
  documentDate: string;
  exchangeRate: number;
  isUnDepositedPayment: null | string;
  makePaymentItemDtoList?: DocumentPaymentItemDto[];
  receivePaymentItemDtoList?: DocumentPaymentItemDto[];
  paymentType: string;
  referenceDate: string;
  referenceNumber: string;
  whtApplicable?: boolean;
  whtRate?: number;
  status?: string;
}

export interface BulkPaymentPayload {
  amount: number;
  amountInBaseCurrency: number;
  contactCode: string;
  contactName: string;
  currency: string;
  documentDate: string;
  exchangeRate: number;
  makePaymentList?: BulkPaymentItemDto[];
  receivePaymentList?: BulkPaymentItemDto[];
  makePaymentFeeDtoList?: BulkPaymentItemDto[];
  receivePaymentFeeDtoList?: BulkPaymentItemDto[];
}
