import React from 'react'
import DKLabelTable from '../DKUILibrary/dklabelTable/DKLabelTable'
import { ShippingLabelTable } from '../DKUILibrary/dklabelTable/shippingLabel/ShippingLabelTable'

interface Props {
    tableColumnOptions: any
    tableStyle: any
    data: any
    onUpdateDataCallback: any
}

interface States {
}

export default class LabelComponentManger extends React.Component<Props, States> {
    render() {
        return (
            <div>
                {this.renderTable()}
            </div>
        )
    }

    renderTable = () => {
        return this.renderShippingLabelTable()
    }

    renderShippingLabelTable = () => {
        var header = ShippingLabelTable.getHeaderColumnList(this.props.tableColumnOptions, this.props.tableStyle, this.props.data.printScale)
        if (header === undefined) {
            header = []
        }
        return <>
            <DKLabelTable
                rawData={this.props.data}
                headerColumn={header}
                tableOption={ShippingLabelTable.getTableOption()}
                data={ShippingLabelTable.getItemColumn(ShippingLabelTable.getData(this.props.data), this.props.tableStyle, this.props.data.printScale)}
                documentType={this.props.data.type}
            />
        </>
    }
}
