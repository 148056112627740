import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchAdvancedTrackingData = createAsyncThunk(
  'advancedTrackingData',
  async (productCode: string = '') => {
    const response = await ProductService.fetchProductAdvancedTrackingData(
      productCode
    );
    return response;
  }
);

export const AdvancedTrackingDataSlice = createSlice({
  name: 'advancedTrackingData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvancedTrackingData.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAdvancedTrackingData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload['content'];
      });
  }
});

export const selectAdvancedTrackingData = (state: RootState) =>
  state.advancedTrackingData.data;
export const selectAdvancedTrackingDataLoadingStatus = (state: RootState) =>
  state.advancedTrackingData.status;

export default AdvancedTrackingDataSlice.reducer;
