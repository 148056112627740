import { useEffect, useState } from 'react';
import { DKInput, DKLabel, INPUT_TYPE, DKDataGrid } from 'deskera-ui-library';
import DateFormatService from '../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  SWITCH_CURRENCY_OPTIONS
} from '../../Constants/Constant';
import { localizedText } from '../../Services/Localization/Localization';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility, { deepClone } from '../../Utility/Utility';
import { enableMultiViewJournalEntryCondition } from './enableMultiViewJECondition';
export const JOURNAL_ENTRY_COLS: any[] = [
  {
    name: 'Account Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'accountName',
    key: 'accountName',
    id: 'accountName',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Debit Amount',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'debitAmount',
    key: 'debitAmount',
    id: 'debitAmount',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false,
    textAlign: 'right'
  },
  {
    name: 'Credit Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'creditAmount',
    key: 'creditAmount',
    id: 'creditAmount',
    systemField: true,
    allowFilter: true,
    allowColumnSort: false,
    textAlign: 'right'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 240,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false
  }
];

function MultiViewJournalEntryDetail(props: any) {
  const titleStyle = { fontSize: 13, fontWeight: 600 };

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [rows, setRows] = useState<any[]>([]);
  useEffect(() => {
    const rowData = Utility.isEmpty(props?.journalEntryDetails?.lineItems)
      ? []
      : getRowDataForCreate(props?.journalEntryDetails?.lineItems);
    setRows(rowData);
  }, [props.journalEntryDetails]);

  const updateColumn = (columns: any) => {
    let tempColumnConfig = deepClone(columns);

    let debitAmountIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'debitAmount'
    );

    if (debitAmountIndex) {
      let currency = tenantInfo.currency;
      if (
        props.multiViewJournalEntryDetailState?.switchCurrency?.value?.value ===
        SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      ) {
        currency =
          tenantInfo.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode;
      }

      tempColumnConfig[debitAmountIndex] = {
        ...tempColumnConfig[debitAmountIndex],
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={Utility.getLongAmoutBlockForLabel(
                currency,
                obj.rowData.cdType === 'DEBIT' ? obj.rowData.amount : 0
              )}
              className="parent-width text-align-right"
            />
          );
        }
      };
    }

    let creditAmountIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'creditAmount'
    );

    if (creditAmountIndex) {
      let currency = tenantInfo.currency;
      if (
        props.multiViewJournalEntryDetailState?.switchCurrency?.value?.value ===
        SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      ) {
        currency =
          tenantInfo.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode;
      }
      tempColumnConfig[creditAmountIndex] = {
        ...tempColumnConfig[creditAmountIndex],
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={Utility.getLongAmoutBlockForLabel(
                currency,
                obj.rowData.cdType === 'CREDIT' ? obj.rowData.amount : 0
              )}
              className="parent-width text-align-right"
            />
          );
        }
      };
    }

    return tempColumnConfig;
  };

  const getRowDataForCreate = (tableList: any[]) => {
    let rowDataList: any[] = [];
    tableList?.forEach((rowData: any) => {
      const row = {
        ...rowData,
        rowContextMenu: [],
        rowButtons: []
      };
      rowDataList.push(row);
    });
    return rowDataList;
  };
  const getGrid = () => {
    return (
      <div className="column parent-width flex-1 mt-l">
        <DKDataGrid
          needShadow={false}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={false}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          // width={960}
          rows={rows}
          columns={updateColumn(JOURNAL_ENTRY_COLS)}
        />
      </div>
    );
  };

  return (
    <div
      className={`column parent-width ${
        props?.removeHorizontalPadding ? '' : 'p-h-l'
      } p-v-m`}
    >
      <div className="row justify-content-between flex-wrap">
        <div className="column">
          <DKLabel text="Journal Entry No" className="fw-m" />
          <DKLabel text={props?.journalEntryDetails?.jeCode} className="" />
        </div>
        <div className="column">
          <DKLabel text="Entry Date" className="fw-m" />
          <DKLabel
            text={DateFormatService.getFormattedDateString(
              props?.journalEntryDetails?.jeDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
            className=""
          />
        </div>
        <div className="column">
          <DKLabel text="Entry Type" className="fw-m" />
          <DKLabel
            text={
              props?.journalEntryDetails?.type === 'FIXED_ASSET'
                ? 'Fixed Asset Depreciation'
                : props?.journalEntryDetails.transferType
                ? Utility.convertInTitleCase(
                    props?.journalEntryDetails.transferType === 'CHEQUE'
                      ? localizedText('cheque')
                      : props?.journalEntryDetails?.transferType?.replaceAll(
                          '_',
                          ' '
                        )
                  )
                : props?.journalEntryDetails?.type === 'FULFILLMENT'
                ? localizedText('Fulfillment')
                : Utility.convertInTitleCase(
                    props?.journalEntryDetails?.type?.replaceAll('_', ' ')
                  )
            }
            className=""
          />
        </div>
        <div className="column">
          <DKLabel text="Document No." className="fw-m" />
          <DKLabel
            text={props?.journalEntryDetails?.documentSequenceCode ?? ''}
            className=""
          />
        </div>
        <div className="row justify-start gap-2">
          <div
            className="position-relative"
            style={{
              width: '200px'
            }}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Accounting Book"
              titleStyle={titleStyle}
              readOnly={
                enableMultiViewJournalEntryCondition() &&
                props?.journalEntryDetails?.type === 'BOOK_SPECIFIC_JE'
              }
              formatter={(obj: any) => {
                return obj.title;
              }}
              value={
                props.multiViewJournalEntryDetailState?.switchCurrency?.value
              }
              displayKey="title"
              onChange={(value: any) => {
                props.setMultiViewJournalEntryDetailState({
                  ...props.multiViewJournalEntryDetailState,
                  switchCurrency: {
                    ...props.multiViewJournalEntryDetailState?.switchCurrency,
                    value: value
                  }
                });
                if (
                  value.value === SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY &&
                  props?.journalEntryDetails?.primaryCurrencyExchangeRate > 0
                ) {
                  props.setMultiViewJournalEntryDetailState((prev: any) => {
                    return {
                      ...prev,
                      exchangeRate:
                        1 /
                        Number(
                          props?.journalEntryDetails
                            ?.primaryCurrencyExchangeRate
                        )
                    };
                  });
                }
                console.log(value);
                let amountInPrimaryPerspective;
                if (value.value === SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY) {
                  amountInPrimaryPerspective = true;
                } else {
                  amountInPrimaryPerspective = false;
                }
                props.handleCurrencySwitchChange(amountInPrimaryPerspective);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: false,
                searchableKey: null,
                data: props.multiViewJournalEntryDetailState?.switchCurrency
                  ?.dataOptions,
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
          <div className="column">
            <DKLabel text="Exchange Rate" className="fw-m" />
            <DKLabel
              text={
                props.multiViewJournalEntryDetailState?.switchCurrency?.value
                  .value === SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
                  ? props?.multiViewJournalEntryDetailState?.exchangeRate
                  : props.journalEntryDetails?.currencyExchangeRate ||
                    props?.multiViewJournalEntryDetailState?.exchangeRate
              }
              className=""
            />
          </div>
          {props?.journalEntryDetails?.primaryCurrencyExchangeRate !== null &&
            props?.journalEntryDetails?.primaryCurrencyExchangeRate !==
              undefined &&
            props.multiViewJournalEntryDetailState?.switchCurrency?.value
              .value === SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY && (
              <div className="column ml-r">
                <DKLabel
                  text={`Primary Exchange Rate(${
                    tenantInfo.additionalSettings?.MULTI_COMPANY
                      ?.primaryCurrencyCode || ''
                  })`}
                  className="fw-m"
                />
                <DKLabel
                  text={`${props?.journalEntryDetails?.primaryCurrencyExchangeRate}`}
                  className=""
                />
              </div>
            )}
        </div>
      </div>

      {getGrid()}
      <DKLabel text="Memo" className="fw-m mb-r" />
      <textarea
        className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
        style={{
          width: 400,
          height: 100,
          backgroundColor: 'rgb(250, 250, 250)',
          border: '1px dashed rgb(200, 200, 200)'
        }}
        placeholder={'Memo'}
        value={props?.documentDetails?.memo}
        disabled={true}
      />
    </div>
  );
}

export default MultiViewJournalEntryDetail;
