import { showLoader, removeLoader } from 'deskera-ui-library';
import { TableManager } from '../Managers/TableManager';
import Table from './Table';
import http from './Interceptor';
import http2 from './Interceptor2';
import AutomationManager from '../Components/Automation/AutomationManager';
import ApiConstants from '../Constants/ApiConstants';
import Utility, { getRandomAlphaNumericString } from '../Utility/Utility';
import AuthService from './Auth';
import { REMOTE_CONFIG_TABLES } from '../Constants/TableConstants';
export interface AutomationAPIConfig {
  pageSize?: number;
  pageNo?: number;
  q?: string;
  Query?: any;
  sortCol?: any;
  sortDir?: any;
}

const defaultConfig: AutomationAPIConfig = {
  pageSize: 100,
  pageNo: 1,
  q: ''
};

class AutomationService {
  static apiConfig: AutomationAPIConfig;
  static connectionId: any = '';

  static save(data: any, onSuccess?: any, onError?: any) {
    showLoader('Saving... Please wait');
    const record = {
      cells: data
    };
    Table.addRecord(
      record,
      TableManager.getTableId(REMOTE_CONFIG_TABLES.AUTOMATION)
    ).then(
      (response: any) => {
        removeLoader();
        onSuccess && onSuccess(response);
      },
      (error: any) => {
        onSuccess && removeLoader();
        onError && onError(error);
      }
    );
  }

  static update(data: any, recordId: any, onSuccess: any, onError: any) {
    showLoader('Updating... Please wait');
    Table.updateRecord(
      data,
      recordId,
      TableManager.getTableId(REMOTE_CONFIG_TABLES.AUTOMATION)
    ).then(
      (response: any) => {
        removeLoader();
        onSuccess(response);
      },
      (error: any) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getData(recordId: string, onSuccess: any, onError: any) {
    showLoader('Fetching... Please wait');
    Table.getRecordById(
      recordId,
      TableManager.getTableId(REMOTE_CONFIG_TABLES.AUTOMATION)
    ).then(
      (response: any) => {
        removeLoader();
        onSuccess(response);
      },
      (error: any) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getPlaceholderData(onSuccess: any, onError: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.WORKFLOW_PLACEHOLDERS;
    return http2
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          onSuccess(response);
        },
        (error: any) => {
          onError(error);
        }
      )
      .catch((error: any) => {
        return onError(error);
      });
  }

  static createConnection() {
    return http
      .post(ApiConstants.URL.WORKFLOW.CREATE_CONNECTION, {
        app_code: 'deskera',
        authorization_code: '',
        name: `ERP Connection ${getRandomAlphaNumericString(4)}`
      })
      .then(
        (res: any) => {
          if (res && res.id) {
            AutomationManager.setConnectionId(res.id);
          }
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getAllConnections() {
    return http.get(ApiConstants.URL.WORKFLOW.GET_CONNECTIONS).then(
      (res: any) => {
        if (res && res.data) {
          if (res.data.length === 0) {
            AutomationService.createConnection();
          } else {
            const connectionIds = res.data;
            let tenant_connection_id_arr = [];
            if (AuthService.getUserDetails()) {
              tenant_connection_id_arr = connectionIds.filter(
                (connection: any) =>
                  connection.tenant_id === AuthService.getUserDetails().tenantID
              );
            }
            if (tenant_connection_id_arr.length > 0) {
              AutomationManager.setConnectionId(tenant_connection_id_arr[0].id);
              AutomationService.connectionId = tenant_connection_id_arr[0].id;
            } else {
              AutomationService.createConnection();
            }
          }
        }
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static async getAutomationData(tableId?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let query = Utility.isEmpty(this.apiConfig.Query)
      ? ''
      : {
          logicalOperator: 'and',
          conditions: this.apiConfig.Query
        };
    delete this.apiConfig['Query'];
    return await Table.getRecordsByPage(this.apiConfig, query, tableId);
  }

  static getApprovalConditions(
    forceRefreshInterceptorCache?: boolean,
    ttl?: number
  ) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.APPROVAL_CONDITIONS;
    return http2
      .get(`${finalEndPoint}`, {
        forceRefreshInterceptorCache: forceRefreshInterceptorCache ?? false,
        ttl: ttl
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getApprovalHistory(
    documentCode: any,
    documentType: any,
    tableId: any
  ) {
    let payload: any = {
      documentType: documentType,
      documentCode: documentCode
    };
    return http2
      .post(
        `${ApiConstants.URL.WORKFLOW.GET_APPROVAL_HISTORY}/${tableId}/recordByCode`,
        payload
      )
      .catch((err: any) => {
        console.error('Error fetching history: ', err);
        return Promise.reject(err);
      });
  }
  static getAutomationWorkflowDetail(tableId: any,data: any) {
    
    return http2.post(
        `${ApiConstants.URL.TABLE.GET(tableId)}/workflow/type`,
        data
      ).catch((error:any) => {
        console.error('Error fetching workflow details: ', error);
        return Promise.reject(error);
      }
    )
  }
}

export default AutomationService;
