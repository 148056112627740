import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class AccountTableWidth {

    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isAmountVisible: boolean;
    isTaxVisible: boolean;
    isTaxAmountVisible: boolean;
    isPaymentAmountVisible: boolean

    code: number;
    name: number;
    description: number;
    tax: number;
    taxAmount: number;
    paymentAmount: number;
    amount: number;

    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalTax: number;
    originalTaxAmount: number;
    originalPaymentAmount: number;
    originalAmount: number;

    productCustomField:number;
    accountCustomFieldList:any;
    isAccountCustomFieldVisible: boolean;
    originalProductCustomField: number;

    constructor() {
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isAmountVisible = true
        this.isTaxVisible = true
        this.isTaxAmountVisible = true
        this.isPaymentAmountVisible = true

        this.code = 0
        this.name = 20
        this.description = 30
        this.tax = 10
        this.taxAmount = 10
        this.paymentAmount = 15
        this.amount = 15

        this.originalCode = 0
        this.originalName = 20
        this.originalDescription = 30
        this.originalTax = 10
        this.originalTaxAmount = 10
        this.originalPaymentAmount = 15
        this.originalAmount = 15
        this.productCustomField = 12

        this.accountCustomFieldList = undefined
        this.isAccountCustomFieldVisible = true
        this.originalProductCustomField = 12;
    }

    setIsShowWidth(columnData:any) {
            this.isCodeVisible = false
            this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.accountName)
            this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
            this.isAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amount)
            this.isTaxVisible = getIsShowTableColumn(columnData, TableColumnPopupType.tax)
            this.isTaxAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.taxAmount)
            this.isPaymentAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.paymentAmount)
            var width = 5
            var maxWidth = 20
            var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isAccountCustomFieldVisible = false
            if (this.accountCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.accountCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.accountCustomFieldList.push(item)
                });
            }
            else {
                this.accountCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.tax = this.originalTax
        this.taxAmount = this.originalTaxAmount
        this.paymentAmount = this.originalPaymentAmount
        this.amount = this.originalAmount
        this.productCustomField = this.originalProductCustomField
        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.name = 0
        this.description = 0
        this.tax = 0
        this.taxAmount = 0
        this.paymentAmount = 0
        this.amount = 0
        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isTaxVisible) {
            totalWidth += this.tax;
        }
        if (this.isTaxAmountVisible) {
            totalWidth += this.taxAmount;
        }
        if (this.isPaymentAmountVisible) {
            totalWidth += this.paymentAmount;
        }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }
        if (this.isAccountCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }
        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isTaxVisible) {
            totalSelectedElement += 1
        }
        if (this.isTaxAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isPaymentAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isTaxVisible) {
            this.tax = this.tax + additionalWidth
        }
        if (this.isTaxAmountVisible) {
            this.taxAmount = this.taxAmount + additionalWidth
        }
        if (this.isPaymentAmountVisible) {
            this.paymentAmount = this.originalPaymentAmount + additionalWidth
        }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }
        if (this.isAccountCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }
        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.accountCustomFieldList)) {
            var item = this.accountCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}