import React from 'react';
import "./Calendar.css";
import { CalendarConstants, WeekdayList } from './CalendarConstants';

// interface Props {
//     dateList: string[]
//     selectedDate: Date,
//     selectedStartDate?: Date
//     selectedEndDate?: Date
//     month: number
//     year: number
//     isDateRange: boolean
//     onSelectDate: (newDate: Date) => void
//     onSelectDateRange: (newStartDate?: Date, newEndDate?: Date) => void
// }

export default class DayContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dayList: WeekdayList,
            itemWidthHeight: this.getItemWidthHeight()
        };
    }

    getItemWidthHeight() {
        var text = CalendarConstants.calendar.width.replace('px', '')
        var width = Number(text)
        if (width === undefined || width === null) {
            return '28.5px'

        }
        else {
            return (width / 7).toFixed(2) + 'px'
        }
    }


    render() {
        return (
            <div className='dayDiv'>
                {this.renderDayList()}
                {this.renderDateList()}
            </div>
        );
    }

    renderDayList() {
        return this.state.dayList.map(element => {
            return this.renderDayItem(element)
        });
    }

    renderDateList() {
        if (this.props.dateList) {
            return this.props.dateList.map(element => {
                return this.renderDateItem(element)
            })
        } else {
            return <></>
        }
    }

    renderDayItem(value) {
        return (
            <div
                className='dayItemDiv'
                style={{
                    width: this.state.itemWidthHeight,
                    padding: CalendarConstants.dayContainer.padding,
                    fontSize: CalendarConstants.dayContainer.textFontSize
                }}
            >
                <text id={value}>
                    {value}
                </text>
            </div>
        );
    }

    getSelectedDateBGTextColor(date) {
        if (this.props.isDateRange) {
            if ((this.props.selectedStartDate.getDate() === Number(date) && this.props.selectedStartDate.getMonth() === Number(this.props.month - 1)) ||
                (this.props.selectedEndDate.getDate() === Number(date) && this.props.selectedEndDate.getMonth() === Number(this.props.month - 1))
            ) {
                return [CalendarConstants.dayContainer.selectedDateBGColor, "white"]
            } else {
                return [CalendarConstants.dayContainer.multiSelectedColor, "black"]
            }
        } else {
            return [CalendarConstants.dayContainer.selectedDateBGColor, CalendarConstants.dayContainer.multiSelectedColor]
        }
    }

    renderDateItem(value) {
        if (this.isSelectedDate(value)) {
            return (
                <div
                    style={{
                        backgroundColor: this.getSelectedDateBGTextColor(value)[0],
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: CalendarConstants.dayContainer.padding,
                        color: this.getSelectedDateBGTextColor(value)[1],
                        fontSize: CalendarConstants.dayContainer.textFontSize,
                        borderRadius: this.props.isDateRange ? undefined : '50%',
                    }}
                    className='selectedDateItemDiv cursor-hand'
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
        else if (this.isToday(value)) {
            return (
                <div
                    style={{
                        backgroundColor: CalendarConstants.dayContainer.todayContainerColor,
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: CalendarConstants.dayContainer.padding,
                        color: CalendarConstants.dayContainer.selectedTextColor,
                        fontSize: CalendarConstants.dayContainer.textFontSize,
                        borderRadius: '50%'
                    }}
                    className='selectedDateItemDiv'
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
        else {
            return (
                <div
                    className='unselectedDateItemDiv'
                    style={{
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: CalendarConstants.dayContainer.padding,
                        fontSize: CalendarConstants.dayContainer.textFontSize,
                    }}
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
    }

    isSelectedDate(value) {
        if (!this.props.isDateRange) {
            if (this.props.selectedDate.getMonth() === this.props.month - 1 &&
                this.props.selectedDate.getFullYear() === this.props.year &&
                this.props.selectedDate.getDate() === Number(value)) {
                return true
            }
            else {
                return false
            }
        }
        else {
            if (value === '') {
                return false
            }
            var date = new Date(this.props.year, this.props.month - 1, Number(value))
            if (this.props.selectedStartDate !== undefined && this.props.selectedEndDate !== undefined) {

                if (this.compareDate(date, this.props.selectedStartDate)) {
                    return true
                }
                if (this.compareDate(date, this.props.selectedEndDate)) {
                    return true
                }
                if (date.valueOf() > this.props.selectedStartDate.valueOf() && date.valueOf() < this.props.selectedEndDate.valueOf()) {
                    return true
                }
            }
            return false
        }
    }

    compareDate(oldDate, newDate) {
        if (oldDate.getDate() === newDate.getDate()
            && oldDate.getMonth() === newDate.getMonth()
            && oldDate.getFullYear() === newDate.getFullYear()) {
            return true
        }
        return false
    }

    isToday(value) {
        var today = new Date()
        var date = new Date(this.props.year, this.props.month - 1, Number(value))
        if (date.toDateString() === today.toDateString()) {
            return true
        }
        else {
            return false
        }
    }

    onSelectDate(value) {

        if (this.props.isDateRange) {
            if (value !== '') {
                var date = new Date(this.props.year, this.props.month - 1, Number(value))
                if (this.props.selectedStartDate === undefined) {
                    this.props.onSelectDateRange(date, date)
                }

                if (this.props.selectedStartDate !== undefined && this.props.selectedEndDate !== undefined) {

                    var valueOfStartDate = this.props.selectedStartDate
                    var valueOfEndDate = this.props.selectedEndDate
                    var valueOfDate = date
                    if (valueOfStartDate.getTime() !== valueOfEndDate.getTime()) {
                        this.props.onSelectDateRange(date, date)
                    }
                    else if (valueOfStartDate.getTime() > valueOfDate.getTime()) {
                        this.props.onSelectDateRange(date, date)
                    }
                    else if (valueOfStartDate.getTime() > valueOfEndDate.getTime()) {
                        this.props.onSelectDateRange(this.props.selectedStartDate, this.props.selectedEndDate)
                    }
                    else {
                        this.props.onSelectDateRange(this.props.selectedStartDate, date)
                    }
                }
            }
            else {

                this.props.onSelectDateRange(undefined, undefined)
            }
        }
        else {

            if (value !== '') {
                this.props.onSelectDate(new Date(this.props.year, this.props.month - 1, Number(value)))
            }
        }
    }
}

