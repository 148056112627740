import { DKIcon, DKTooltipWrapper } from 'deskera-ui-library';
import ic_barcode_green from '../../../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../../../Assets/Icons/ic_barcode_red.svg';
import { fetchBatchTrackingProducts } from '../../../../../Redux/Slices/BatchTrackingSlice';
import { fetchSerialTrackingProducts } from '../../../../../Redux/Slices/SerialTrackingSlice';
import { fetchProductInventoryByID } from '../../../../../Redux/Slices/WarehouseProductSlice';
import { Store } from '../../../../../Redux/Store';

import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import Utility from '../../../../../Utility/Utility';
import RawMaterialHelper from './RawMaterialHelper';
import { fetchAdvancedTrackingData } from '../../../../../Redux/Slices/AdvancedTrackingDataSlice';
export default class TrackingPopupHelper {
  static async loadSerialTrackingProducts(code: any) {
    try {
      await Store.dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  }

  static async loadBatchTrackingProducts(code: any) {
    try {
      await Store.dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  }

  static async loadProductInventoryById(ids: any) {
    try {
      await Store.dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  }

  static isItemAssigned(item: any) {
    switch (item?.itemName?.advancedTracking) {
      case ADVANCE_TRACKING.NONE:
        return RawMaterialHelper.isNoneTrackedItemAssigned(item);
      case ADVANCE_TRACKING.SERIAL:
        return RawMaterialHelper.isSerialItemAssigned(item);
      case ADVANCE_TRACKING.BATCH:
        return RawMaterialHelper.isBatchItemAssigned(item);
    }
  }

  static getTrackingDetailsSelectionButton = (
    item: any,
    onClick: () => void
  ) => {
    return (
      <DKTooltipWrapper
        content={
          Utility.isBinAllocationMandatory() ||
          Utility.isWarehouseTaggingEnabled()
            ? 'Allocate quantities from target warehouse'
            : 'Allocate quantities from multiple warehouses'
        }
        tooltipClassName="t-10 l-10"
        className=""
        gapFromCursorX={8}
        gapFromCursorY={8}
      >
        <DKIcon
          className="ic-s"
          src={
            TrackingPopupHelper.isItemAssigned(item)
              ? ic_barcode_green
              : ic_barcode_red
          }
          onClick={() => {
            onClick();
          }}
        />
      </DKTooltipWrapper>
    );
  };

  static async loadBatchTrackingData(code: any) {
    try {
      Store.dispatch(fetchAdvancedTrackingData(code));
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  }

  static setBatchTrackingReceive = async (item: any) => {
    await TrackingPopupHelper.loadBatchTrackingData(item.productCode);
  };

  static setBatchTrackingfullfilment = async (item: any) => {
    await TrackingPopupHelper.loadBatchTrackingProducts(item?.itemName?.pid);
  };

  static setSerialTracking = async (item: any) => {
    await TrackingPopupHelper.loadSerialTrackingProducts(item?.itemName?.pid);
  };

  static setNoneTracking = async (item: any) => {
    await TrackingPopupHelper.loadProductInventoryById([item?.itemName?.pid]);
  };

  static async apiCallsforAdvanceTracking(item: any, isAssignment: boolean) {
    switch (item?.itemName?.advancedTracking) {
      case ADVANCE_TRACKING.NONE:
        await TrackingPopupHelper.setNoneTracking(item);
        break;
      case ADVANCE_TRACKING.SERIAL:
        await TrackingPopupHelper.setSerialTracking(item);
        break;
      case ADVANCE_TRACKING.BATCH:
        isAssignment
          ? await TrackingPopupHelper.setBatchTrackingfullfilment(item)
          : await TrackingPopupHelper.setBatchTrackingReceive(item);
        break;
    }
  }
}
