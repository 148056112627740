import React, { useState } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  DKLabel,
  showToast,
  TOAST_TYPE,
  DKButton
} from 'deskera-ui-library';
import {
  DOCUMENT_MODE,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import EwayBillService from '../../Services/EwayBill';
import Utility from '../../Utility/Utility';

const ManageTransporter = (props: any) => {
  let intialFormData = {
    transporterName: {
      value: '',
      hasError: false,
      errorMsg: ''
    },
    transporterId: {
      value: '',
      hasError: false,
      errorMsg: ''
    }
  };

  let documentMode = DOCUMENT_MODE.NEW 

  if (props.transporter && props.transporter !== null) {
    documentMode = DOCUMENT_MODE.EDIT
    intialFormData.transporterName.value = props.transporter.transporterName
    intialFormData.transporterId.value = props.transporter.transporterId
  }

  const [formData, setFormData] = useState(intialFormData);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Manage Transporter" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              saveTransporter();
            }}
          />
        </div>
      </div>
    );
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: formData
      });
    }
  };

  const onFormChange = (value: any, formField: string) => {
    const newState = { ...formData };
    switch (formField) {
      case 'TRANSPORTER_NAME':
        if (Utility.isEmpty(value)) {
          newState.transporterName.hasError = true;
          newState.transporterName.errorMsg = 'Transporter name is required';
        } else {
          newState.transporterName.hasError = false;
          newState.transporterName.errorMsg = '';
        }
        newState.transporterName.value = value;
        break;
      case 'TRANSPORTER_ID':
        if (Utility.isEmpty(value)) {
          newState.transporterId.hasError = true;
          newState.transporterId.errorMsg = 'Transporter id is required';
        } else {
          newState.transporterId.hasError = false;
          newState.transporterId.errorMsg = '';
        }
        newState.transporterId.value = value;
        break;
    }
    setFormData({ ...newState });
  };

  const validateForm = () => {
    const newState = { ...formData };

    let _transporterName = newState.transporterName.value;
    let _transporterId = newState.transporterId.value;

    if (Utility.isEmpty(_transporterName)) {
      newState.transporterName.hasError = true;
      newState.transporterName.errorMsg = 'Transporter name is required';
    } else {
      newState.transporterName.hasError = false;
      newState.transporterName.errorMsg = '';
    }

    if (
      !newState.transporterName.hasError &&
      !newState.transporterId.hasError
    ) {
      setFormData({ ...newState });
      return true;
    } else {
      setFormData({ ...newState });
      return false;
    }
  };

  const saveTransporter = () => {
    let Payload: any = {
      transporterName: formData.transporterName.value,
      transporterId: formData.transporterId.value
    };

    if (validateForm()) {
      if(documentMode === DOCUMENT_MODE.NEW) {
        EwayBillService.saveManageTransporter(Payload).then(
          (res: any) => {
            showToast('Transporter Created Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err) => {
            console.error('Error saving transporter: ', err);
          }
        );
      }
      else {
        if (props.transporter.id && props.transporter.id !== null) {
          EwayBillService.updateManageTransporter(Payload, props.transporter.id).then(
            (res: any) => {
              showToast('Transporter Created Successfully', TOAST_TYPE.SUCCESS);
              closePopup();
            },
            (err) => {
              console.error('Error saving transporter: ', err);
            }
          );
        }
      }
    }
  };

  return (
    <div style={{ zIndex: 9998 }} className="transparent-background asd">
      <div
        className="popup-window"
        style={{
          maxWidth: 560,
          width: '90%',
          maxHeight: '95%',
          height: 180,
          padding: 0,
          paddingBottom: 60,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width mt-3 p-h-r p-v-s">
          <div className="row parent-width mt-4">
            <div className="w-1/2 p-h-s">
              <DKInput
                title={'Transporter Name'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={formData.transporterName.value}
                onChange={(value: any) => {
                  onFormChange(value, 'TRANSPORTER_NAME');
                }}
                canValidate={formData.transporterName.hasError}
                validator={(value: string) => {
                  return !formData.transporterName.hasError;
                }}
                errorMessage={formData.transporterName.errorMsg}
              />
            </div>
            <div className="w-1/2 p-h-s">
              <DKInput
                required={false}
                title={'Transporter Id'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={formData.transporterId.value}
                onChange={(value: number) => {
                  onFormChange(value, 'TRANSPORTER_ID');
                }}
                canValidate={formData.transporterId.hasError}
                validator={(value: string) => {
                  return !formData.transporterId.hasError;
                }}
                errorMessage={formData.transporterId.errorMsg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTransporter;
