import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import CreditNoteService from '../../Services/CreditNote';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchCreditNotes = createAsyncThunk('creditNote', async () => {
  const response = await CreditNoteService.getCreditNoteByPage();
  return response;
});

export const CreditNoteSlice = createSlice({
  name: 'creditNote',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addCreditNoteColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.CREDIT_NOTES
      );
    },
    addColumnToCreditNoteColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    },
    updateCreditNoteDetails: (state, action: PayloadAction<any>) => {
      const creditNoteDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const creditNoteIndex: number = content.findIndex(
        (creditNote: any) => creditNote.id === creditNoteDetails.id
      );
      if (creditNoteIndex >= 0) {
        content[creditNoteIndex] = creditNoteDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditNotes.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchCreditNotes.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addCreditNoteColumnConfig,
  updateCreditNoteDetails,
  updateColumnConfig
} = CreditNoteSlice.actions;
export const selectCreditNotes = (state: RootState) => state.creditNotes.data;
export const selectCreditNoteColumnConfig = (state: RootState) =>
  state.creditNotes.columnConfig;
export const selectCreditNoteColumnConfigTableId = (state: RootState) =>
  state.creditNotes.configTableId;
export const selectCreditNotesLoadingStatus = (state: RootState) =>
  state.creditNotes.gridContentLoadingStatus;
