import {
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { SalesOrder } from '../../Models/SalesOrder';
import DateFormatService from '../../Services/DateFormat';
import {
  addOperationsAndAdditionalCostToBomComponentGroups,
  defaultAdditionalChargesObject,
  getContactObjWhenDocIsLoading,
  getReservedQtyAtLineItem,
  getTenantTaxSystem,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo,
  updateAvailableQtyBasedOnUOM
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const salesOrderEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Sales Order Date',
    action: 'salesOrderDate'
  },
  {
    hidden: false,
    text: 'Sales Order Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Sales Order Number',
    action: 'salesOrderNumber'
  }
];

export const setItemsFromSalesOrderItems = (doc: any, docMode: any) => {
  let contactObj = getContactObjWhenDocIsLoading(doc);

  const document = {
    ...doc,
    items: doc.salesOrderItems.map((item: any) => {
      const compGroupDetails =
        Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
        item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        Utility.isNotEmpty(item?.bomComponentGroupDetails)
          ? {
              ...item?.bomComponentGroupDetails,
              bomComponentGroups:
                addOperationsAndAdditionalCostToBomComponentGroups(item)
            }
          : null;
      return {
        ...item,
        qtyOrdered: updateAvailableQtyBasedOnUOM(item, item.productQuantity),
        availableQtyByUOM: updateAvailableQtyBasedOnUOM(
          item,
          item.availableQuantity
        ),
        shippedFulfilledQty: Utility.getValue(
          item.shippedQty,
          item.shippedFulfilledQty,
          '0'
        ),
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive,
        documentSequenceCode: item?.product?.documentSequenceCode,
        revRecStartDate: item.revRecDocumentItemDetails?.startDate
          ? DateFormatService.convertDDMMYYYToDate(
              item.revRecDocumentItemDetails?.startDate
            )
          : null,
        revRecEndDate: item.revRecDocumentItemDetails?.endDate
          ? DateFormatService.convertDDMMYYYToDate(
              item.revRecDocumentItemDetails?.endDate
            )
          : null,
        bomComponentGroupDetails: compGroupDetails,
        unmodifiedBomComponentGroupDetails: compGroupDetails
      };
    }),
    contact: contactObj,
    documentType: DOC_TYPE.SALES_ORDER,
    orderReference: doc?.poNumber,
    linkedQuotationDocuments: doc.linkedQuotationDocuments
      ? doc.linkedQuotationDocuments
      : [],
    linkedSalesInvoiceDocuments: doc.linkedSalesInvoiceDocuments
      ? doc.linkedSalesInvoiceDocuments
      : [],
    linkedSalesInvoices: doc.linkedSalesInvoices ? doc.linkedSalesInvoices : [],
    documentDate: doc.salesOrderDate,
    validTillDate: doc.salesOrderDueDate,
    fulfillmentDate: doc.shipByDate,
    oldDueAmount: doc.dueAmount,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  if (
    (docMode === DOCUMENT_MODE.VIEW || docMode === DOCUMENT_MODE.EDIT) &&
    Utility.isNotEmpty(doc?.contact?.name)
  ) {
    document.contact = {
      ...document?.contact,
      name: doc?.contact?.name ?? document?.contact?.name ?? ''
    };
  }
  return document;
};

export const getUpdatedReservedQuantititesData = (
  reservedQuantitiesData: any
) => {
  let data = reservedQuantitiesData?.map((ele: any) => {
    return { ...ele, isReservedDataSave: true };
  });
  return data;
};

export const getUpdatedSalesOrderObject = (props: SalesOrder) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.SALES_ORDER,
    fulfillmentStatus:
      props.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
    fulfillmentType: props.fulfillmentType,
    documentCode: Utility.getValue(props.documentCode, props.salesOrderCode),
    documentDate: Utility.getValue(props.documentDate, props.salesOrderDate),
    validTillDate: Utility.getValue(
      props.validTillDate,
      props.salesOrderDueDate
    ),
    orderReference: props?.poNumber,
    fulfillmentDate: Utility.getValue(props.fulfillmentDate, props.shipByDate),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    backOrder: props.backOrder ? props.backOrder : false,

    items: props.salesOrderItems?.map((item: any) => {
      return {
        ...item,
        qtyOrdered: updateAvailableQtyBasedOnUOM(item, item.productQuantity),
        availableQtyByUOM: updateAvailableQtyBasedOnUOM(
          item,
          item.availableQuantity
        ),
        shippedFulfilledQty: Utility.getValue(
          item.shippedQty,
          item.shippedFulfilledQty,
          '0'
        ),
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        documentSequenceCode: item?.product?.documentSequenceCode,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.salesOrderItemCode,
          item.salesInvoiceItemCode
        ),
        reservedQuantitiesData: Utility.isEmpty(item.reservedQuantitiesData)
          ? item.reservedQuantitiesData
          : getUpdatedReservedQuantititesData(item.reservedQuantitiesData),
        reservedQtyAtLineItem: Utility.isEmpty(item.reservedQuantitiesData)
          ? 0
          : getReservedQtyAtLineItem(
              rebuildAdvancedTrackingMetaDtosAndUOMInfo(
                item.reservedQuantitiesData,
                item.documentUOMSchemaDefinition,
                true
              ),
              item?.product?.advancedTracking,
              DOC_TYPE.SALES_ORDER,
              item.documentUOMSchemaDefinition
            )
      };
    }),
    salesOrderItems: props.salesOrderItems?.map((item: any) => {
      return {
        ...item,
        reservedQuantitiesData: Utility.isEmpty(item.reservedQuantitiesData)
          ? item.reservedQuantitiesData
          : getUpdatedReservedQuantititesData(item.reservedQuantitiesData),
        reservedQtyAtLineItem: Utility.isEmpty(item.reservedQuantitiesData)
          ? 0
          : getReservedQtyAtLineItem(
              rebuildAdvancedTrackingMetaDtosAndUOMInfo(
                item.reservedQuantitiesData,
                item.documentUOMSchemaDefinition,
                true
              ),
              item?.product?.advancedTracking,
              DOC_TYPE.SALES_ORDER,
              item.documentUOMSchemaDefinition
            )
      };
    }),
    processedInPPS: props.processedInPPS,
    reservedStock: props.reservedStock ? props.reservedStock : false
  };

  return updatedObj;
};
