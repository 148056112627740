import React, { useEffect } from 'react';
import {
  showToast,
  TOAST_TYPE,
  DKInput,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { InputTag } from '../../Models/NewContact';
import { FORM_ELEMENTS, POPUP_CALLBACKS_TYPE } from '../../Constants/Constant';
import { DEDUCTEE_FIELD_NAME } from '../../Constants/Enum';
import { deepClone } from '../../Utility/Utility';
import TDSService from '../../Services/TDSSetup';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { fetchTDSDeducteeType } from '../../Redux/Slices/TDSSetupSlice';
import { useAppDispatch } from '../../Redux/Hooks';

interface DeducteeState {
  deducteeType: InputTag<string>;
  deducteeCode: InputTag<string>;
}

interface DeducteePayload {
  code: string;
  type: string;
}

interface DeducteeResponse {
  id: number;
  code: string;
  type: string;
  active: boolean;
  deleted: boolean;
}

interface DeducteeProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
}
export const DeducteePopup: React.FC<DeducteeProps> = (props) => {
  const initialState: DeducteeState = {
    deducteeType: {
      key: '',
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    deducteeCode: {
      key: '',
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    }
  };
  const [deducteeState, setDeducteeState] = React.useState<DeducteeState>(
    deepClone(initialState)
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    registerInteractions();
  });

  const formFieldUpdated = (
    fieldName: DEDUCTEE_FIELD_NAME,
    newValue: string
  ) => {
    const updatedState = deducteeState;
    switch (fieldName) {
      case DEDUCTEE_FIELD_NAME.DEDUCTEE_TYPE:
        updatedState.deducteeType.hasError = false;
        if (updatedState.deducteeType.isMandatory && !newValue) {
          updatedState.deducteeType.hasError = true;
          updatedState.deducteeType.errorMsg =
            'Deductee Type cannot be left empty';
        }
        updatedState.deducteeType.value = newValue;
        break;
      case DEDUCTEE_FIELD_NAME.DEDUCTEE_CODE:
        updatedState.deducteeCode.hasError = false;
        if (updatedState.deducteeCode.isMandatory && !newValue) {
          updatedState.deducteeCode.hasError = true;
          updatedState.deducteeCode.errorMsg =
            'Deductee Code cannot be left empty';
        }
        updatedState.deducteeCode.value = newValue;
        break;
      default:
        break;
    }
    setDeducteeState({ ...updatedState });
  };

  const formFieldValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(deducteeState);
    if (
      updatedState.deducteeType.isMandatory &&
      !updatedState.deducteeType.value
    ) {
      updatedState.deducteeType.hasError = true;
      updatedState.deducteeType.errorMsg = 'Deductee Type cannot be left empty';
      formHasError = true;
    }
    if (
      updatedState.deducteeCode.isMandatory &&
      !updatedState.deducteeCode.value
    ) {
      updatedState.deducteeCode.hasError = true;
      updatedState.deducteeCode.errorMsg = 'Deductee Code cannot be left empty';
      formHasError = true;
    }
    if (formHasError) {
      setDeducteeState({ ...updatedState });
      return false;
    }
    return true;
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CREATE_DEDUCTEE_TYPE,
      data: () => {
        onSubmit();
      }
    });
  };

  const onSubmit = () => {
    const isValidForm = formFieldValidation();
    if (isValidForm) {
      const payload: DeducteePayload = {
        code: deducteeState.deducteeCode.value.toString(),
        type: deducteeState.deducteeType.value.toString()
      };
      TDSService.createTDSDeducteeType(payload)
        .then((res) => {
          const response: DeducteeResponse = res;
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.DEDUCTEE_CREATE_SUCCESS,
            data: response
          });
          dispatch(fetchTDSDeducteeType());
          showToast('Deductee Type added successfully', TOAST_TYPE.SUCCESS);
        })
        .catch((err) => {
          showToast('Error adding Deductee Type', TOAST_TYPE.ERROR);
        });
    }
  };
  return (
    <div className="parent-width p-h-m">
      <DKInput
        title="Deductee Type"
        canValidate={deducteeState.deducteeType.isMandatory}
        value={deducteeState.deducteeType.value}
        onChange={(value: string) =>
          formFieldUpdated(DEDUCTEE_FIELD_NAME.DEDUCTEE_TYPE, value)
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        validator={(value: string) => {
          return !deducteeState.deducteeType.hasError;
        }}
        errorMessage={deducteeState.deducteeType.errorMsg}
      />

      <DKInput
        title="Deductee Code"
        className="mt-l"
        canValidate={deducteeState.deducteeCode.isMandatory}
        value={deducteeState.deducteeCode.value}
        onChange={(value: string) =>
          formFieldUpdated(DEDUCTEE_FIELD_NAME.DEDUCTEE_CODE, value)
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        validator={(value: string) => {
          return !deducteeState.deducteeCode.hasError;
        }}
        errorMessage={deducteeState.deducteeCode.errorMsg}
      />
    </div>
  );
};
