import { COST_CONFIG_TYPE } from '../../../Constants/MRPColumnConfigs';
import {
  getActualOperationCostFromJC,
  getActualOperatorCostFromJC,
  getMaterialActualCost,
  getTotalWorkstationCost
} from '../../WorkOrderHelper';

export const getChargeAmount = (charge: any, totalCost: any) => {
  let chargeAmount = 0;
  if (charge.isPercent) {
    chargeAmount = totalCost * (+charge.percentageValue / 100);
  } else {
    chargeAmount = charge.chargeValue;
  }
  return chargeAmount;
};

export const getUnselectedAdditionalCharges = (
  selectedCharges: any,
  addtionalBuyCharges: any
): any[] => {
  const selectedChargesNames = selectedCharges.map(
    (charge: any) => charge.name
  );
  let unSelectedCharges: any[] = addtionalBuyCharges.filter(
    (charge: any) => !selectedChargesNames.includes(charge.name)
  );
  return unSelectedCharges;
};

export const totalCostCalculation = (
  activeTabWorkOrder: any,
  operationIdToJobMappingData: any,
  jobCardsDetails: any
) => {
  const totalMaterialActualCost = getMaterialActualCost(activeTabWorkOrder);

  const totalActualOperationCost = getActualOperationCostFromJC(
    activeTabWorkOrder,
    Object.values(operationIdToJobMappingData)
  );
  const totalActualOperatorCost = getActualOperatorCostFromJC(
    activeTabWorkOrder,
    Object.values(operationIdToJobMappingData)
  );

  const totalWorkstationCost = getTotalWorkstationCost(
    activeTabWorkOrder,
    jobCardsDetails
  );

  const totalActualJWOCost = 0;

  const totalCost =
    totalMaterialActualCost +
    totalActualOperationCost +
    totalActualOperatorCost +
    totalWorkstationCost +
    totalActualJWOCost;

  return totalCost;
};

export const additionalBOMAdditionalCostCalculate = (updatedCharges: any) => {
  let updatedChargesWithoutBOMAdditionalCosts = updatedCharges;
  updatedChargesWithoutBOMAdditionalCosts = updatedCharges?.filter(
    (charge: any) => charge?.additionalCostType !== COST_CONFIG_TYPE.BOM
  );
  return updatedChargesWithoutBOMAdditionalCosts;
};

export const additionalChargesCalculate = (
  updatedCharges: any,
  tenantInfo: any
) => {
  const additionalChargesTotal = updatedCharges.reduce(
    (prev: number, curr: any) => prev + Number(curr.chargeValue),
    0
  );
  const charges = {
    globalDiscount: {
      amount: 0,
      isPercent: false,
      isSubTotalOnly: false,
      percent: 0,
      hasError: false
    },
    additionalChargeAmount: additionalChargesTotal,
    additionalChargeTaxAmount: 0,
    additionalChargesDetails: updatedCharges.map((charge: any) => {
      return {
        id: charge.id,
        additionalCharge: charge.name,
        additionalChargeAccountCode: charge.expenseAccountCode,
        additionalChargeTax: '',
        additionalChargeTaxCode: '',
        isPreCharge: false,
        isPercent: charge.isPercent,
        percent: charge.percentageValue,
        chargeAmount: charge.chargeValue,
        taxAmount: 0,
        igst: 0,
        cgst: 0,
        sgst: 0,
        includeInReport: true,
        apportionFlag:
          tenantInfo?.additionalSettings?.ADDITIONAL_CHARGE?.apportionFlag ||
          false,
        apportionValue: null,
        invalidRow: charge.invalidRow,
        invalidFields: charge.invalidFields
      };
    })
  };

  return charges;
};
