import CustomFieldManager from "../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility";
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser";
import DataParser from "../DataParser";
import LineItemsParser from "../LineItemsParser"

export default class PurchaseRequisitionParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        var companyName = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                companyName = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = companyName
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, dataFromAPI.tenantInfo.address, undefined, undefined, undefined, undefined, undefined)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.billToName, dataFromAPI.billToAddress, dataFromAPI.contactInfo?.pan, dataFromAPI.contactInfo?.gstin, dataFromAPI.contactInfo?.contactNumber, dataFromAPI.contactInfo?.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = new Address(dataFromAPI.shipToName, dataFromAPI.shipToAddress, dataFromAPI.contactInfo?.pan, dataFromAPI.contactInfo?.gstin, dataFromAPI.contactInfo?.contactNumber, dataFromAPI.contactInfo?.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
        
        //email and contact
        templateDataToReturn.email = dataFromAPI.tenantInfo.email
        templateDataToReturn.contactNumber = dataFromAPI.tenantInfo.contactNumber

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber
        
        //date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)
        
        //created by
        templateDataToReturn.requisitionCreatedBy = dataFromAPI.createdBy
        templateDataToReturn.createdBy = dataFromAPI.createdBy

        // authorised by
        templateDataToReturn.approvedBy = dataFromAPI.approvedBy

        //approve date
        if(dataFromAPI.approvedBy && dataFromAPI.createdDate) {
            templateDataToReturn.approvedDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.createdDate), templateDataToReturn.dateFormat)
        }

        //due date
        templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.shipByDate), templateDataToReturn.dateFormat)
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        //additional date
        var showAdditionalDate = false
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
            showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
        }

        if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
            templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), templateDataToReturn.dateFormat)
        }
        templateDataToReturn.showAdditionalDate = showAdditionalDate

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getPurchaseRequisitionLineItems(dataFromAPI, templateDataToReturn.dateFormat)

        templateDataToReturn.total = undefined

        return templateDataToReturn
    }
}
