export class ImageUtility {
    constructor() {
        this.width = 0.0
        this.height = 0.0
    }
    width: number
    height: number
    resize(width: number, height: number, maxSize: number) {
        this.width = width
        this.height = height

        if(width > height) {
            if(width> maxSize) {
                const ratio = maxSize/width
                this.width = maxSize
                this.height = height * ratio
            }
        }
        else {
            if(height > maxSize) {
                const ratio = maxSize/height
                this.height = maxSize
                this.width = width * ratio
            }
        }
    }
}