import React, { useEffect, useState } from 'react';
import {
  DKButton,
  DKDataGrid,
  DKLabel,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { BOM_EXPLOSION_BUTTON_TYPE } from './BOMExplosionHelper';
import NumberFormatService from '../../../Services/NumberFormat';
import Utility, { deepClone } from '../../../Utility/Utility';
import { PRODUCT_TYPE } from '../../../Constants/Constant';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

const COLUMNS = [
  {
    name: 'Material Code',
    key: 'productDocumentSeqCode',
    type: INPUT_TYPE.TEXT,
    width: 140,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    name: 'Material name',
    key: 'productName',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    name: 'Required Quantity',
    key: 'requiredQty',
    type: INPUT_TYPE.NUMBER,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    name: 'UOM',
    key: 'stockUom',
    type: INPUT_TYPE.TEXT,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    name: 'Type',
    key: 'typeForGridDisplay',
    type: INPUT_TYPE.TEXT,
    width: 130,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  }
];

interface IItemGroupByVendor {
  [key: string]: {
    vendorName: string;
    items: any[];
  };
}

const ProductSelectionPopup = (props: any) => {
  const [rowItems, setRowItems] = useState<any[]>([]);
  const [itemGroupByVendor, setItemGroupByVendor] =
    useState<IItemGroupByVendor>({});

  useEffect(() => {
    if (props.actionButtonType !== BOM_EXPLOSION_BUTTON_TYPE.PO) {
      setRowItems(
        (props.allProducts ?? []).map((item: any) => ({
          ...item,
          selected: true,
          // typeForGridDisplay:
          //   item?.typeForGridDisplay === PRODUCT_TYPE.BILL_OF_MATERIALS
          //     ? 'WIP Material'
          //     : 'Raw Material',
          requiredQty: item?.requiredQty ?? 0
        }))
      );
    } else {
      const tempDataForPo: IItemGroupByVendor = {};
      props.allProducts?.forEach((item: any) => {
        tempDataForPo[item.prefferedVendorCode] = tempDataForPo[
          item.prefferedVendorCode
        ] || {
          vendorName: item.prefferedVendorName,
          items: []
        };

        tempDataForPo[item.prefferedVendorCode].items.push(item);
      });

      setItemGroupByVendor(tempDataForPo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = () => {
    let selectedRowItems =
      rowItems?.filter((rowItem: any) => {
        return rowItem.selected;
      }) ?? [];

    if (!Utility.isEmpty(selectedRowItems)) {
      props?.onCreateActionBtnClick(selectedRowItems);
    } else {
      showAlert(
        'Attention',
        'There are no materials selected or available for selection.'
      );
    }
  };

  const handleRowSelection = (rowIndex: number, gridCategory?: string) => {
    if (gridCategory) {
      const vendorGroupToUpdate = itemGroupByVendor[gridCategory];
      const vendorGroupItems = [...vendorGroupToUpdate.items];
      vendorGroupItems[rowIndex] = {
        ...vendorGroupItems[rowIndex],
        selected: !vendorGroupItems[rowIndex].selected
      };
      setItemGroupByVendor({
        ...itemGroupByVendor,
        [gridCategory]: {
          ...vendorGroupToUpdate,
          items: vendorGroupItems
        }
      });
    } else if (rowItems?.[rowIndex]) {
      const updatedRowItems = [...rowItems];
      updatedRowItems[rowIndex] = {
        ...updatedRowItems[rowIndex],
        selected: !updatedRowItems[rowIndex].selected
      };
      setRowItems(updatedRowItems);
    }
  };

  const handleAllRowSelection = (selected: boolean, gridCategory?: string) => {
    if (gridCategory) {
      const vendorGroupToUpdate = itemGroupByVendor[gridCategory];
      vendorGroupToUpdate.items = vendorGroupToUpdate.items.map((item) => ({
        ...item,
        selected
      }));
      setItemGroupByVendor({
        ...itemGroupByVendor,
        [gridCategory]: vendorGroupToUpdate
      });
    } else {
      const updatedRowItems = rowItems.map((item) => ({
        ...item,
        selected
      }));
      setRowItems(updatedRowItems);
    }
  };

  const handleCreatePOByVendor = (gridCategory: string) => {
    const itemsToCreatePO = itemGroupByVendor[gridCategory]?.items?.filter(
      (item) => item.selected
    );

    if (Utility.isEmpty(itemsToCreatePO)) {
      showAlert('Attention', 'Please select materials.');
    } else {
      props.onCreateActionBtnClick(itemsToCreatePO);
    }
  };

  const getHeader = () => {
    let title = '';
    let buttonTitle = '';
    switch (props.actionButtonType) {
      case BOM_EXPLOSION_BUTTON_TYPE.PO:
        title = 'for creating PO';
        buttonTitle = 'Create PO';
        break;
      case BOM_EXPLOSION_BUTTON_TYPE.WO:
        title = 'for creating WO';
        buttonTitle = 'Create WO';
        break;
      case BOM_EXPLOSION_BUTTON_TYPE.PR:
        title = 'for creating PR';
        buttonTitle = 'Create PR';
        break;

      default:
        title = '';
        buttonTitle = '';
        break;
    }
    return (
      <div className="row justify-content-between bg-gray1 p-s">
        <div className="row pop-header-drag-handle">
          <DKLabel text={`Select materials ${title}`} className="fw-m fs-l" />
        </div>
        <div className="row-reverse">
          {props.actionButtonType === BOM_EXPLOSION_BUTTON_TYPE.PO ? null : (
            <DKButton
              title={buttonTitle}
              onClick={() => {
                onSave();
              }}
              className={`bg-button text-white ml-r`}
            />
          )}
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => props.onClose?.()}
          />
        </div>
      </div>
    );
  };

  const getTypeToDisplay = (type: any) => {
    switch (type) {
      case 'rm':
        return 'Raw Material';
      case 'wip':
        return 'WIP Material';
      case 'fg':
        return 'Finished Good';

      default:
        return '';
    }
  };

  const getProductgrid = (rows: any[], gridCategory?: string) => {
    let height = window.innerHeight - 50;
    const allRowSelected =
      rows?.every((rowItem: any) => rowItem.selected) ?? false;
    return !Utility.isEmpty(rows) ? (
      <div
        className="column parent-width overflow-scroll hide-scroll-bar"
        style={{ maxHeight: height }}
      >
        <DKDataGrid
          title={''}
          width={770}
          filterTableName={''}
          needShadow={false}
          needBorder={true}
          allowRowEdit={true}
          allowColumnEdit={true}
          allowRowAdd={false}
          allowColumnAdd={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowSearch={false}
          buttons={[]}
          needTrailingColumn={false}
          allowDataExport={true}
          columns={COLUMNS ?? []}
          rows={
            deepClone(rows)?.map((rowItem: any) => {
              return {
                ...rowItem,
                requiredQty: NumberFormatService.getNumber(
                  rowItem?.requiredQty ?? 0
                ),
                typeForGridDisplay: getTypeToDisplay(
                  rowItem?.typeForGridDisplay
                ),
                stockUom: Utility.getUOMForStockUOMId(rowItem?.stockUom)?.name
              };
            }) ?? []
          }
          onSearch={() => {}}
          onPaginationClick={(requestedPageNumber: number) => {}}
          allowBulkOperation={true}
          onRowUpdate={(data: any) => {}}
          onRowSelect={(selectedRow: any) => {
            handleRowSelection(selectedRow?.rowIndex, gridCategory);
          }}
          isAllRowSelected={allRowSelected}
          onAllRowSelect={({ selected = false }) =>
            handleAllRowSelection(!allRowSelected, gridCategory)
          }
        />
      </div>
    ) : (
      <div className="row justify-content-center">
        No products available to select.
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="p-r column parent-size overflow-scroll hide-scroll-bar">
        {props.actionButtonType === BOM_EXPLOSION_BUTTON_TYPE.PO ? (
          <div className="column parent-width">
            {Object.entries(itemGroupByVendor).map(
              ([vendorCode, { vendorName, items }]) => {
                return (
                  <div className="column parent-width flex-1" key={vendorCode}>
                    <div className="row justify-content-between mb-s">
                      <DKLabel text={vendorName} className="fw-m" />
                      {checkUserPermission(
                        PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE
                      ) && (
                        <DKButton
                          title={'Create PO'}
                          className="bg-button text-white fw-m"
                          onClick={() => handleCreatePOByVendor(vendorCode)}
                        />
                      )}
                    </div>
                    {getProductgrid(items, vendorCode)}
                  </div>
                );
              }
            )}
          </div>
        ) : (
          getProductgrid(rowItems)
        )}
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window p-0"
        style={{
          maxWidth: '90%',
          maxHeight: '98%',
          overflow: 'hidden',
          width: 800
        }}
      >
        {getHeader()}
        {getBody()}
      </div>
    </div>
  );
};

export default ProductSelectionPopup;
