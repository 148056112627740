import React, { Component } from "react";
import "../../index.css";
import "./css/RequisitionTemplate.css";
import ComponentManager from "../../Manager/ComponentManager";
import { FONT_SIZE } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW, getCapitalized } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import AddressParser, { AddressType } from "../../Helper/AddressParser";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import { HTMLTag } from "../../Utilities/HTMLTag";

export default class ServiceRequisitionTemplate1 extends Component {
  spacingRatio = 0.25

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      data: nextProp.data,
    });
  }

  render() {
    // Subtracting top and bottom border height from min page height
    let minHeight = getPageHeight(this.state.data.printScale);
    if (minHeight && minHeight.includes("vw")) {
      minHeight = `${Number(minHeight.replace("vw", "")) - Number(getVW(2).replace("vw", ""))}vw`;
    }

    return (
      <div id={HTMLTag.TEMPLATE_HOLDER} className='DocumentHolder ColumnDiv parent-width'
        style={{
          justifyContent: 'space-between',
          minHeight: minHeight,
          border: '1px solid black'
        }}>
        <div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
          <div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
            {this.getCompanySection()}
            {this.getDocumentDetailsSection()}
          </div>
          <div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>
            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
          </div>
          <div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
            {this.getEditableDocumentTable()}
          </div>
          <div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
            {this.getTermsAndConditionOrNotesSection()}
          </div>
          <div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
            {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
          </div>
        </div>
      </div>
    );
  }

  getCompanySection() {
    return <div className="RowDiv"
      style={{
        justifyContent: 'center'
      }}>
      <div className="ColumnDiv" style={{ alignItems: 'center', flex: 2 }}>
        <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
          <div className="RowDiv">
            <div className="ColumnDiv" style={{
              width: '10%',
              alignItems: 'left',
              flex: 1,
              paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
              paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
            }}>
              {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(70, this.state.data.printScale), { position: 'relative' })}
              {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ width: '80%', paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)) }}>
              {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                  textAlign: 'center',
                  alignSelf: 'center',
                  ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                }}>
                {getLocalisedText(Utility.getCompanyName(this.state.data) || this.state.data.tenantInfo.name)}
              </text>}
              {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
              {/* company address */}
              {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                  color: '#000000',
                  textAlign: 'center',
                  width: '100%',
                  ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                }}
                dangerouslySetInnerHTML={{ __html: getLocalisedText(AddressParser.getRequisitonCustomAddressString(this.state.data.fromObj)) }}>
              </div>}
              {/* Email and Contact */}
              {this.getEmailAndPhone()}
            </div>
            <div className="ColumnDiv" style={{
              width: '10%',
              alignItems: 'left',
              flex: 1
            }}></div>
          </div>
        </div>
        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
        {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor || '#000000', height: 1, width: '100%' })}
        <text className={"docFontStyleBold" + getFontSizeClass()}
          style={{
            paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
            paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
          }}>
          {getCapitalized(getLocalisedText(this.state.data.documentType))}
        </text>
        {ComponentManager.addDivider({ backgroundColor: this.state.data.themeColor || '#000000', height: 1, width: '100%' })}
      </div>
    </div>
  }
  getEmailAndPhone() {
    return <div className={"docFontStyle" + getFontSizeClass()}
      style={{
        width: '100%',
        marginTop: 0,
        color: '#000000',
        textAlign: 'center',
        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
      }}>
      {this.state.data.ownerEmail &&
        <>
          <text className={"docFontStyle pr-s" + getFontSizeClass()}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
            {`${getLocalisedText("email")}:`}
          </text>
          <text className={"docFontStyle pr-s" + getFontSizeClass()}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
            {`${this.state.data.ownerEmail}`}
          </text>
        </>
      }
      {this.state.data.contactNumber &&
        <>
          <text className={"docFontStyle pr-s" + getFontSizeClass()}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
            {`${getLocalisedText("phone")}:`}
          </text>
          <text className={"docFontStyle" + getFontSizeClass()}
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
            }}>
            {`${this.state.data.contactNumber}`}
          </text>
        </>
      }
    </div>
  }

  getCompanyDetailText(data, index, isLeftItem) {
    return <div
      className="ColumnDiv parent-width"
      key={`${data.label}_${index}`}
    >
      <div className="RowDiv" key={index} style={{
        margin: getVW(Utility.convertToPrintScale(10 * this.spacingRatio, this.state.data.printScale))
      }}>
        <div
          className="ColumnDiv"
          style={{ width: isLeftItem ? "50%" : "60%" }}
        >
          <text
            className={getFontSizeClass()}
            style={{
              color: this.state.data.themeColor,
              ...Utility.getFontStyleProp(
                { fontSize: FONT_SIZE.regular },
                this.state.data.printScale
              ),
            }}
          >
            {data.label}
          </text>
        </div>
        <div
          className="ColumnDiv"
          style={{ width: isLeftItem ? "50%" : "40%" }}
        >
          <text
            className={getFontSizeClass()}
            style={{
              ...Utility.getFontStyleProp(
                { fontSize: FONT_SIZE.regular },
                this.state.data.printScale
              ),
            }}
          >
            : {this.state.data[data.key] || data.customFieldValue}
          </text>

        </div>
      </div>
    </div>
  }

  getSingleDetailsSection(item, isLeftItem) {
    return ComponentManager.getRowValueSectionCustom(
      item.label,
      this.state.data[item.key] || item.customFieldValue,
      isLeftItem ? "50%" : "60%",
      this.state.data.themeColor,
      undefined,
      ":",
      getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
      this.state.data.printScale
    )
  }

  getDocumentDetailsSection() {
    const documentDetailList = Utility.getDocumentDetailsList(this.state.data)
    const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index);
    if (selectedDetailList.length === 0) {
      return <></>
    }

    const leftDetailsList = []
    const rightDetailsList = []

    selectedDetailList.forEach((detail, index) => {
      if ((index + 1) % 2 !== 0) {
        leftDetailsList.push(detail);
      } else {
        rightDetailsList.push(detail);
      }
    });

    let bottomLeftDetailsSection = undefined
    if (leftDetailsList.length > 0) {
      bottomLeftDetailsSection = leftDetailsList.map((element, index) => {
        return this.getCompanyDetailText(element, index, true)
      });
    }

    let bottomRightDetailsSection = undefined
    if (rightDetailsList.length > 0) {
      bottomRightDetailsSection = rightDetailsList.map((element, index) => {
        return this.getCompanyDetailText(element, index, false)
      });
    }

    let bottomSection = undefined
    if (bottomLeftDetailsSection) {
      bottomSection = <div className="RowDiv parent-width">
        <div className="ColumnDiv" style={{
          flex: 11,
          alignSelf: 'baseline',
          paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
          paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
          paddingLeft: getVW(Utility.convertToPrintScale(15 * this.spacingRatio, this.state.data.printScale))
        }}>
          {bottomLeftDetailsSection}
        </div>
        {bottomRightDetailsSection && (
          <div className="ColumnDiv" style={{
            flex: 9,
            alignSelf: 'baseline',
            paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
            paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
            paddingRight: getVW(Utility.convertToPrintScale(15 * this.spacingRatio, this.state.data.printScale))
          }}>
            {bottomRightDetailsSection}
          </div>
        )}
      </div>
    }

    return <div className="ColumnDiv parent-width">
      {bottomSection}
    </div>
  }

  getCustomFieldValues(data) {
    return (this.state.data?.customFieldsObject && data?.key) ? this.state.data?.customFieldsObject[data.key] : ''
  }

  getNotesValue() {
    let notes = ''
    if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
      notes = 'notes_placeholder_text'
    }
    if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
      notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
    }
    return getLocalisedText(notes)
  }

  getTermsAndConditionOrNotesSection() {
    const notesSection = (
      <div
        className="RowDiv"
        style={{
          width: "-webkit-fill-available",
          paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
          paddingBottom: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
        }}
      >
        <text
          className={"docFontStyle" + getFontSizeClass()}
          style={{
            color: this.state.data.themeColor,
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.regular },
              this.state.data.printScale
            ),
          }}
        >
          {getLocalisedText("service_description")}
        </text>
        <text
          className={"docFontStyle" + getFontSizeClass()}
          style={{
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.regular },
              this.state.data.printScale,

            ),
            paddingLeft: getVW(Utility.convertToPrintScale(2, this.state.data.printScale))
          }}
        >
          : {this.state.data.service_description}
        </text>
      </div>
    );

    const remarks = (
      <div
        className="RowDiv"
        style={{
          width: "-webkit-fill-available",
          paddingTop: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
          paddingBottom: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
        }}
      >
        <text
          className={"docFontStyle" + getFontSizeClass()}
          style={{
            color: this.state.data.themeColor,
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.regular },
              this.state.data.printScale
            ),
          }}
        >
          {getLocalisedText("remark")}
        </text>
        <text
          className={"docFontStyle" + getFontSizeClass()}
          style={{
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.regular },
              this.state.data.printScale
            ),
            paddingLeft: getVW(Utility.convertToPrintScale(2, this.state.data.printScale))
          }}
        >
          : {getLocalisedText(this.state.data.notes)}
        </text>
      </div>
    );

    return (
      <table
        id={HTMLTag.TERMS_NOTE_PREFIX}
        className="break-inside-avoid"
        style={{
          alignItems: "flex-start",
          justifyContent: 'space-between',
          backgroundColor: "white",
          borderBottom: '1px solid',
          paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
          paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            {notesSection &&
              <td style={{ width: '50%', verticalAlign: 'top' }}>
                {notesSection}
              </td>
            }
          </tr>
          <tr>
            {remarks}
          </tr>
        </tbody>
      </table>
    )
  }

  getEditableDocumentTable() {
    return ComponentManager.getDKDocumentTable(
      this.props.tableColumn,
      this.getTableStyle(),
      this.props.data,
      this.props.tableClicked,
      this.props.stockTableClicked,
      this.props.onUpdateDataCallback,
      this.props.paymentTableClicked,
      this.props.additionalTableCallback,
    )
  }

  getTableStyle() {
    let style = DocumentManager.getTableDefaultStyle();
    style.headerTextColor = this.state.data.themeColor;
    style.footerTextColor = this.state.data.themeColor;
    style.headerBorderColor = "#000000";
    style.itemBorderColor = "#000000";
    style.footerBackgroundColor = "white";
    style.footerBorderColor = "#000000";
    style.headerBackgroundColor = "transparent";
    style.noPadding = false
    return style;
  }


  getCustomFieldTableStyle() {
    let style = {}
    style.columnCount = 3
    style.columnWidths = Utility.getIsArabicLang() ? ['33%', '33%', '33%'] : ['33%', '33%', '33%']
    return style;
  }
}