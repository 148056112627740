export const BACK_DATE_DOCUMENT_TYPE: any = {
  PURCHASE_ORDER: {
    displayName: 'Purchase order',
    value: 'PURCHASE_ORDER',
    approvalFlow: true
  },
  QUOTATION: {
    displayName: 'Quote',
    value: 'QUOTATION',
    approvalFlow: true
  },
  PURCHASE_INVOICE: {
    displayName: 'Bill',
    value: 'PURCHASE_INVOICE',
    approvalFlow: true
  },
  SALES_INVOICE: {
    displayName: 'Invoice',
    value: 'SALES_INVOICE',
    approvalFlow: true
  },
  MAKE_PAYMENT: {
    displayName: 'Make Payment',
    value: 'MAKE_PAYMENT',
    approvalFlow: false
  },
  RECEIVE_PAYMENT: {
    displayName: 'Receive Payment',
    value: 'RECEIVE_PAYMENT',
    approvalFlow: false
  },
  JOURNAL_ENTRY: {
    displayName: 'Journal Entry',
    value: 'JOURNAL_ENTRY',
    approvalFlow: false
  },
  CREDIT_NOTE: {
    displayName: 'Credit Note',
    value: 'CREDIT_NOTE',
    approvalFlow: false
  },
  DEBIT_NOTE: {
    displayName: 'Debit Note',
    value: 'DEBIT_NOTE',
    approvalFlow: false
  },
  EXPENSE: {
    displayName: 'Expense',
    value: 'EXPENSE',
    approvalFlow: false
  },
  DEPOSIT_ADVPAYMENT: {
    displayName: 'Deposit',
    value: 'DEPOSIT_ADVPAYMENT',
    approvalFlow: false
  },
  GOODS_RECEIPT: {
    displayName: 'Goods Receipt',
    value: 'GOODS_RECEIPT',
    approvalFlow: false
  },
  FULFILLMENT: {
    displayName: 'Fulfillment',
    value: 'FULFILLMENT',
    approvalFlow: false
  },
  SALES_RETURN: {
    displayName: 'Sales Return',
    value: 'SALES_RETURN',
    approvalFlow: false
  },
  STOCK_ADJUSTMENT: {
    displayName: 'Stock Adjustment',
    value: 'STOCK_ADJUSTMENT',
    approvalFlow: false
  },
  STOCK_TRANSFER: {
    displayName: 'Stock Transfer',
    value: 'STOCK_TRANSFER',
    approvalFlow: false
  },
  FIXED_ASSET: {
    displayName: 'Fixed Asset',
    value: 'FIXED_ASSET',
    approvalFlow: false
  },
  SALES_ORDER: {
    displayName: 'Sales Order',
    value: 'SALES_ORDER',
    approvalFlow: false
  }
};
export const BACK_DATE_DOCUMENT_TYPE_DATA: any = [
  {
    displayName: 'Order',
    value: 'PURCHASE_ORDER'
  },
  {
    displayName: 'Quote',
    value: 'QUOTATION'
  },
  {
    displayName: 'Bill',
    value: 'PURCHASE_INVOICE'
  },
  {
    displayName: 'Invoice',
    value: 'SALES_INVOICE'
  },
  {
    displayName: 'Make Payment',
    value: 'MAKE_PAYMENT'
  },
  {
    displayName: 'Receive Payment',
    value: 'RECEIVE_PAYMENT'
  },
  {
    displayName: 'Journal Entry',
    value: 'JOURNAL_ENTRY'
  },
  {
    displayName: 'Credit Note',
    value: 'CREDIT_NOTE'
  },
  {
    displayName: 'Debit Note',
    value: 'DEBIT_NOTE'
  },
  {
    displayName: 'Expense',
    value: 'EXPENSE'
  },
  {
    displayName: 'Deposit',
    value: 'DEPOSIT_ADVPAYMENT'
  },
  {
    displayName: 'Goods Receipt',
    value: 'GOODS_RECEIPT'
  },
  {
    displayName: 'Fulfillment',
    value: 'FULFILLMENT'
  },

  {
    displayName: 'Sales Return',
    value: 'SALES_RETURN'
  },
  {
    displayName: 'Stock Adjustment',
    value: 'STOCK_ADJUSTMENT'
  },
  {
    displayName: 'Stock Transfer',
    value: 'STOCK_TRANSFER'
  },
  {
    displayName: 'Fixed Asset',
    value: 'FIXED_ASSET'
  },
  {
    displayName: 'Sales Order',
    value: 'SALES_ORDER'
  }
];

export const RESTRICTION_TYPE: any = [
  { value: 'Fully_Restrict', label: 'Restrict Fully' },
  { value: 'Approval', label: 'Send Approval' }
];

export const STATUS_OPTIONS = {
  ACTIVE: [1],
  IN_ACTIVE: [2]
};

export const approvalReq: any = {
  steps: [
    {
      elementType: 'Trigger',
      type: 'BOOKS_CREATE_INVOICE',
      title: 'Invoice Created',
      id: 'Ttc34U1NEVLED6mxXbNJDdx5'
    },
    {
      elementType: 'Action',
      type: 'END_WORKFLOW',
      title: 'End automation',
      id: 'A74UjscdbGf3zmrNuhepqpYA'
    },
    {
      elementType: 'Action',
      type: 'BOOKS_PLUS_APPROVAL',
      title: 'Approval condition',
      configuration: [
        {
          documentType: 'SALES_INVOICE',
          approvalFor: '',
          approver: '',
          sendEmailToApprover: true,
          reminderDays: '0',
          fields: [
            {
              // salesInvoiceDate: '22-05-2022',
              // opr: 'gteq'
            }
          ],
          logicalOperator: 'and'
        }
      ],
      id: 'nYkdL7GQ8VznD4uaZuajEk5Z'
    }
  ],
  adjacencyMatrix: {
    Ttc34U1NEVLED6mxXbNJDdx5: ['nYkdL7GQ8VznD4uaZuajEk5Z'],
    nYkdL7GQ8VznD4uaZuajEk5Z: ['A74UjscdbGf3zmrNuhepqpYA'],
    A74UjscdbGf3zmrNuhepqpYA: [null]
  }
};
