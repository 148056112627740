import { DKLabel } from 'deskera-ui-library';

export class CoaConfigUtility {
  static structuredSubtypeArr: any = [];

  static nestedChildren = (obj: any, index: any) => {
    obj.children?.forEach((child: any) => {
      CoaConfigUtility.getNewSubtypeRenderer(child, index + 1);
    });
  };

  static getNewSubtypeRenderer = (obj: any, index: any) => {
    let newObj = {
      ...obj,
      parentLevel: index
    };

    //this condition makes sure you dont push duplicate entries in the list
    if (
      CoaConfigUtility.structuredSubtypeArr?.findIndex(
        (item: any) => item.id === newObj.id
      ) === -1
    ) {
      CoaConfigUtility.structuredSubtypeArr.push(newObj);
    }

    CoaConfigUtility.nestedChildren(obj, index);
  };

  static fillStructuredSubtypeArray = (newData: any) => {
    newData?.forEach((item: any, index: any) => {
      CoaConfigUtility.getNewSubtypeRenderer(item, 0);
    });
  };

  static getSubtypeRow = (obj: any) => {
    const isRootParent = obj.parentLevel === 0;
    return (
      <div
        className={`row parent-height align-item-center justify-content-between`}
        style={{
          padding: 0,
          marginLeft: !isRootParent ? `${obj.parentLevel * 5}px` : 0
        }}
      >
        <DKLabel
          text={obj.name}
          className={`align-item-center pt-0.5 ${
            isRootParent ? 'fw-r fs-r' : ''
          }`}
        />
      </div>
    );
  };

  static setStructuredSubtypeArray = (newData: any) => {
    CoaConfigUtility.structuredSubtypeArr = newData;
  };
}
