import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
import { showAlert } from 'deskera-ui-library';
export type MachineShortFallLinkPayload = {
  documentCode: string;
  documentSequenceCode: string;
  documentType: 'PURCHASE_ORDER' | 'PURCHASE_REQUEST';
};

export default class MachineMaterialShortFallService {
  static async linkShortFallInfo(
    scheduleId: any,
    payload: MachineShortFallLinkPayload
  ) {
    try {
      const url =
        ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.SHORT_FALL_LINK(scheduleId);
      const response = await http.post(url, payload);
      return response;
    } catch (error: any) {
      showAlert('Error', error?.message);
      console.error('Error creating machine schedule: ', error);
      return Promise.reject(error);
    }
  }
}
