import { IColumn } from '../Models/Table';
import Table from '../Services/Table';
import Utility from '../Utility/Utility';

export const updateColumnInfo = (tableId: string, columnConfig: IColumn) => {
  // Persist config in column config DB
  if (!Utility.isEmpty(tableId) && !Utility.isEmpty(columnConfig)) {
    Table.updateColumn(columnConfig, tableId).then(
      (data) => {},
      (err) => {
        console.error('Error while udpating column info: ', err);
      }
    );
  }
};

export const updateColumnShift = (
  tableId: string,
  newColumnIdArray: string[]
) => {
  if (!Utility.isEmpty(tableId) && !Utility.isEmpty(newColumnIdArray)) {
    Table.shiftColumn(newColumnIdArray, tableId).then(
      (data) => {
        console.log('Column shifted successfully');
      },
      (err) => {
        console.error('Error while shifting columns: ', err);
      }
    );
  }
};
