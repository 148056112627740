import React, { useEffect, useState } from 'react';
import {
  DKLabel,
  showToast,
  TOAST_TYPE,
  DKCheckMark,
  DKInput,
  INPUT_TYPE
} from 'deskera-ui-library';
import {
  CUSTOM_NUMBER_FORMAT_KEY_NAMES,
  initialCustomDocumentNumberFormValues,
  SEPRATORS
} from './CustomNumber';
import Utility, { deepClone } from '../../Utility/Utility';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import {
  INPUT_VIEW_DIRECTION,
  NUMBER_REGEX_PATTERN,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import CustomNumberFormatService from '../../Services/CustomNumberFormat';
import { PRODUCTS_CAPS } from '../../Constants/Enum';

function CustomNumberFormat(props: any) {
  const [formState, setFormState] = useState(
    deepClone(initialCustomDocumentNumberFormValues)
  );
  const [preview, setPreview] = useState('');
  const [defaultNumber, setDefaultNumber] = useState<boolean>(false);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_CUSTOM_NUMBER,
        data: () => {
          saveCustomNumberFormat();
        }
      });
    }
  };

  useEffect(() => {
    if (props.data) {
      setInitialFormData(props.data);
    } else {
      setFormState(deepClone(initialCustomDocumentNumberFormValues));
    }
  }, [props.data]);

  const setInitialFormData = (data: any) => {
    const updatedState: any = formState;
    updatedState.displayDigit.value = data.sequenceLength;
    updatedState.prefix.value = data.prefix;
    updatedState.suffix.value = data.suffix;
    updatedState.prefixSeparator.value = SEPRATORS.find(
      (seprator: any) => seprator === data.prefixSeparator
    );
    updatedState.suffixSeparator.value = SEPRATORS.find(
      (seprator: any) => seprator === data.suffixSeparator
    );
    updatedState.startCode.value = data.startCode || 1;

    if (data.isDefault) {
      setDefaultNumber(true);
    } else {
      setDefaultNumber(false);
    }

    setFormState({ ...updatedState });
  };

  useEffect(() => {
    registerInteractions();
    updatePreviewValue();
  });

  const payLoadCustomNumberFormat = () => {
    let PREFIX = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX].value,
      PREFIX_SEPARATOR_VALUE: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value,
      DISPLAY_DIGIT: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT].value,
      SUFFIX_SEPARATOR_VALUE: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value,
      SUFFIX = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX].value,
      START_CODE: any =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.START_CODE].value,
      payload;

    if (PREFIX_SEPARATOR_VALUE.length >= 1) {
      if (SEPRATORS[PREFIX_SEPARATOR_VALUE[0]] == SEPRATORS[5]) {
        PREFIX_SEPARATOR_VALUE = 'Blank';
      } else {
        PREFIX_SEPARATOR_VALUE = SEPRATORS[PREFIX_SEPARATOR_VALUE];
      }
    } else {
      PREFIX_SEPARATOR_VALUE = SEPRATORS[PREFIX_SEPARATOR_VALUE];
    }

    if (SUFFIX_SEPARATOR_VALUE.length >= 1) {
      if (SEPRATORS[SUFFIX_SEPARATOR_VALUE[0]] == SEPRATORS[5]) {
        SUFFIX_SEPARATOR_VALUE = 'Blank';
      } else {
        SUFFIX_SEPARATOR_VALUE = SEPRATORS[SUFFIX_SEPARATOR_VALUE];
      }
    } else {
      SUFFIX_SEPARATOR_VALUE = SEPRATORS[SUFFIX_SEPARATOR_VALUE];
    }

    if (
      Utility.isEmpty(PREFIX_SEPARATOR_VALUE) ||
      Utility.isEmpty(SUFFIX_SEPARATOR_VALUE)
    ) {
      if (!PREFIX_SEPARATOR_VALUE) {
        showToast('Please select Separators', TOAST_TYPE.FAILURE);
        return;
      } else if (!SUFFIX_SEPARATOR_VALUE) {
        showToast('Please select Separators', TOAST_TYPE.FAILURE);
        return;
      }
    }

    payload = {
      prefix: !Utility.isEmpty(PREFIX) ? PREFIX : null,
      prefixSeparator:
        !Utility.isEmpty(PREFIX_SEPARATOR_VALUE) && !Utility.isEmpty(PREFIX)
          ? PREFIX_SEPARATOR_VALUE === 'Blank'
            ? ''
            : PREFIX_SEPARATOR_VALUE
          : '',
      suffix: !Utility.isEmpty(SUFFIX) ? SUFFIX : null,
      suffixSeparator:
        !Utility.isEmpty(SUFFIX_SEPARATOR_VALUE) && !Utility.isEmpty(SUFFIX)
          ? SUFFIX_SEPARATOR_VALUE === 'Blank'
            ? ''
            : SUFFIX_SEPARATOR_VALUE
          : '',
      sequenceLength: parseInt(DISPLAY_DIGIT),
      module: props.documentType,
      application: PRODUCTS_CAPS.ERP,
      isDefault: defaultNumber,
      nextCode: parseInt(START_CODE) || 1
    };

    if (formState.displayDigit.hasError) {
      showToast('Please provide valid display digit', TOAST_TYPE.FAILURE);
      return;
    } else {
      return payload;
    }
  };

  const saveCustomNumberFormat = () => {
    if (props.data) {
      let payload = {
        sequenceId: props.data.id,
        module: props.documentType,
        application: PRODUCTS_CAPS.ERP
      };
      if (props.data.isDefault !== defaultNumber) {
        CustomNumberFormatService.toggleIsDefault(payload).then(
          (response: any) => {
            if (response.status == 409) {
              showToast(
                'Custom number format already exist',
                TOAST_TYPE.WARNING
              );
            }

            if (response.status == 200) {
              props.onSaveSuccess?.();
              closePopup();
              showToast(
                'Custom number format Created Successfully',
                TOAST_TYPE.SUCCESS
              );
            }
          },
          (err) => {
            console.error('Error while custom number format: ', err);
          }
        );
      }
      closePopup();
    } else {
      if (!Utility.isEmpty(payLoadCustomNumberFormat())) {
        CustomNumberFormatService.createCustomNumberFormat(
          payLoadCustomNumberFormat()
        ).then(
          (response: any) => {
            if (response.status == 409) {
              showToast(
                'Custom number format already exist',
                TOAST_TYPE.WARNING
              );
            }

            if (response.status == 200) {
              showToast(
                'Custom number format Created Successfully',
                TOAST_TYPE.SUCCESS
              );
              props.onSaveSuccess?.();
              closePopup();
            }
          },
          (err) => {
            console.error('Error while custom number format: ', err);
          }
        );
      }
      closePopup();
    }
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const formFieldChanged = (
    newValue: ReactSelectOptionsType<string, string> | string | null,
    fieldName: CUSTOM_NUMBER_FORMAT_KEY_NAMES
  ) => {
    const updatedState: any = formState;
    const selectedField = updatedState[fieldName];

    switch (fieldName) {
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT:
        const displayDigit = newValue as string;
        const regex = new RegExp(NUMBER_REGEX_PATTERN);
        if (parseInt(displayDigit) <= 0 || parseInt(displayDigit) > 10) {
          selectedField.hasError = true;
        } else if (!regex.test(displayDigit)) {
          selectedField.hasError = true;
        } else {
          selectedField.hasError = false;
        }
        selectedField.value = newValue ? newValue : '';
        break;
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR:
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX:
        selectedField.value = newValue ? newValue : '';
        break;
      case CUSTOM_NUMBER_FORMAT_KEY_NAMES.START_CODE:
        const startCode = newValue as string;
        if (
          parseInt(startCode) <= 0 ||
          parseInt(startCode) >=
            (updatedState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT] || 1) *
              10
        ) {
          selectedField.hasError = true;
        } else {
          selectedField.hasError = false;
        }
        selectedField.value = newValue ? newValue : '';
        break;
    }
    setFormState({ ...updatedState });
  };

  const updatePreviewValue = () => {
    let previewValue: string = '';

    let DISPLAY_DIGIT = CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT;
    let START_CODE = CUSTOM_NUMBER_FORMAT_KEY_NAMES.START_CODE;
    let PREFIX_VALUE = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX].value;
    let PREFIX_SEPARATOR_VALUE: any = '';
    let SUFFIX_SEPARATOR_VALUE: any = '';

    if (Utility.isEmpty(props.data)) {
      // Prefix Seprator value preview
      PREFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value;
      PREFIX_SEPARATOR_VALUE =
        SEPRATORS[
          PREFIX_SEPARATOR_VALUE.length > 1
            ? PREFIX_SEPARATOR_VALUE[0]
            : PREFIX_SEPARATOR_VALUE
        ];
      if (PREFIX_SEPARATOR_VALUE === SEPRATORS[5]) {
        PREFIX_SEPARATOR_VALUE = '';
      }
      // Suffix Seprator value preview
      SUFFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value;

      SUFFIX_SEPARATOR_VALUE =
        SEPRATORS[
          SUFFIX_SEPARATOR_VALUE.length > 1
            ? SUFFIX_SEPARATOR_VALUE[0]
            : SUFFIX_SEPARATOR_VALUE
        ];
      if (SUFFIX_SEPARATOR_VALUE === SEPRATORS[5]) {
        SUFFIX_SEPARATOR_VALUE = '';
      }
    } else {
      PREFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR].value;

      SUFFIX_SEPARATOR_VALUE =
        formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR].value;
    }

    let DISPLAY_DIGIT_VALUE = Utility.padLeadingZeros(
      formState[START_CODE]?.value?.toString() || '1',
      formState[DISPLAY_DIGIT].value?.toString()
    );

    let SUFFIX_VALUE = formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX].value;

    if (
      formState[DISPLAY_DIGIT].value === '1' &&
      !PREFIX_VALUE &&
      !SUFFIX_VALUE
    ) {
      previewValue = formState[START_CODE]?.value?.toString() || '1';
    }

    if (Utility.isEmpty(PREFIX_SEPARATOR_VALUE)) {
      PREFIX_SEPARATOR_VALUE = '';
    }

    if (Utility.isEmpty(SUFFIX_SEPARATOR_VALUE)) {
      SUFFIX_SEPARATOR_VALUE = '';
    }

    if (formState[DISPLAY_DIGIT].value > '0') {
      if (PREFIX_VALUE && SUFFIX_VALUE) {
        previewValue =
          `${PREFIX_VALUE}` +
          `${PREFIX_SEPARATOR_VALUE}` +
          `${DISPLAY_DIGIT_VALUE}` +
          `${SUFFIX_SEPARATOR_VALUE}` +
          `${SUFFIX_VALUE}`;
      } else if (!PREFIX_VALUE && SUFFIX_VALUE) {
        previewValue =
          `${DISPLAY_DIGIT_VALUE}` +
          `${SUFFIX_SEPARATOR_VALUE}` +
          `${SUFFIX_VALUE}`;
      } else if (PREFIX_VALUE && !SUFFIX_VALUE) {
        previewValue =
          `${PREFIX_VALUE}` +
          `${PREFIX_SEPARATOR_VALUE}` +
          `${DISPLAY_DIGIT_VALUE}`;
      } else if (!PREFIX_VALUE && !SUFFIX_VALUE) {
        previewValue = `${DISPLAY_DIGIT_VALUE}`;
      }

      setPreview(previewValue);
      formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREVIEW].value = previewValue;
    } else {
      setPreview('');
      formState[CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREVIEW].value = '';
    }
  };

  const onClickCheckBoxForSetting = () => {
    setDefaultNumber((prevCheck) => !prevCheck);
  };

  return (
    <>
      <div className="parent-width row flex-wrap">
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Preview" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={preview}
              />
            </div>
          ) : (
            <DKInput
              title="Preview"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              isDisabled={true}
              readOnly={true}
              hasError={formState.preview.hasError}
              value={preview}
            />
          )}
        </div>
        <div className="px-2" style={{ minWidth: 150 }}>
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Start Number" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.startCode.value
                    ? formState.startCode.value?.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <DKInput
              required={true}
              title="Start Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.startCode.value?.toString()}
              onChange={(text: any) => {
                if (Number(text) > 0) {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.START_CODE
                  );
                } else {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.START_CODE
                  );
                  showToast('Start number cannot be zero', TOAST_TYPE.FAILURE);
                }
              }}
              validator={(value: number) =>
                !isNaN(formState.displayDigit?.value as number) &&
                value > 0 &&
                value <
                  Math.pow(
                    10,
                    parseInt(formState.displayDigit.value.toString()) || 1
                  )
              }
              errorMessage={'Invalid Start Number'}
              canValidate={true}
            />
          )}
        </div>
      </div>
      <div className={`mt-4 parent-width row flex-wrap`}>
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Prefix" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.prefix.value
                    ? formState.prefix.value?.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <DKInput
              title="Prefix"
              required={false}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.prefix.value?.toString()}
              onChange={(text: any) =>
                formFieldChanged(text, CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX)
              }
            />
          )}
        </div>
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Separator" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.prefixSeparator.value
                    ? formState.prefixSeparator.value.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <>
              <DKInput
                required={false}
                title={'Separator'}
                value={
                  Number(formState.prefixSeparator.value) >= 0
                    ? [formState.prefixSeparator.value]
                    : []
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                options={SEPRATORS}
                onChange={(value: any) =>
                  formFieldChanged(
                    value,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.PREFIX_SEPRATOR
                  )
                }
                type={INPUT_TYPE.SELECT}
              />
            </>
          )}
        </div>
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Display Digit" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.displayDigit.value
                    ? formState.displayDigit.value?.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <DKInput
              required={true}
              title="Display Digit"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.displayDigit.value?.toString()}
              onChange={(text: any) => {
                if (Number(text) > 0) {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT
                  );
                } else {
                  formFieldChanged(
                    text,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.DISPLAY_DIGIT
                  );
                  showToast(
                    'Display digits cannot be zero',
                    TOAST_TYPE.FAILURE
                  );
                }
              }}
              errorMessage={'Invalid Display Digit (1 - 10)'}
              validator={(value: number) => value > 0 && value <= 10}
              canValidate={true}
            />
          )}
        </div>
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Separator" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.suffixSeparator.value
                    ? formState.suffixSeparator.value.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <>
              <DKInput
                required={false}
                title={'Separator'}
                value={
                  Number(formState.suffixSeparator.value) >= 0
                    ? [formState.suffixSeparator.value]
                    : []
                }
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                options={SEPRATORS}
                onChange={(value: any) =>
                  formFieldChanged(
                    value,
                    CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX_SEPRATOR
                  )
                }
                type={INPUT_TYPE.SELECT}
              />
            </>
          )}
        </div>
        <div className="flex-1 px-2">
          {props.data ? (
            <div className="column parent-width">
              <DKLabel className="ml-0" text="Suffix" />
              <DKInput
                readOnly={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={
                  formState.suffix.value
                    ? formState.suffix.value?.toString()
                    : ''
                }
              />
            </div>
          ) : (
            <DKInput
              required={false}
              title="Suffix"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={props.data}
              value={formState.suffix.value?.toString()}
              onChange={(text: any) =>
                formFieldChanged(text, CUSTOM_NUMBER_FORMAT_KEY_NAMES.SUFFIX)
              }
            />
          )}
        </div>
      </div>
      <div className={`${props.data ? `mt-4` : ``} parent-width row flex-wrap`}>
        <div className="flex-1 px-2 mt-4 flex items-center">
          <DKCheckMark
            title="Set as Default"
            isSelected={defaultNumber}
            className="text-black"
            onClick={onClickCheckBoxForSetting}
          />
        </div>
      </div>
    </>
  );
}

export default CustomNumberFormat;
