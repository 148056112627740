import ApiConstants from '../../../Constants/ApiConstants';
import {
  APPLY_TO_TYPE,
  CUST_EXPORT_WO_PAY,
  CUST_EXPORT_W_PAY,
  CUST_SEZ_WO_PAY,
  CUST_SEZ_W_PAY,
  DEEMED_EXPORT,
  DOC_TYPE,
  GST_TYPE,
  OVERSEAS,
  SPECIAL_ECONOMIC_ZONE,
  TAX_TYPES
} from '../../../Constants/Constant';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import Utility from '../../../Utility/Utility';

export class ExpenseDepositHelper {
  static getSalesTaxTypeIndiaForComposition(
    shipFromState: string,
    shipToState: string
  ) {
    if (Utility.isShipFromAndShipToStateSame(shipFromState, shipToState)) {
      return GST_TYPE.INTRA;
    }
  }

  static getCustomerGstType(
    tenantDetails: any,
    shipToState: string,
    contactType: string,
    gstTreatment: string
  ) {
    const isRegisteredToSez = tenantDetails?.sezOrImportExport ? true : false;
    const isRegisteredToComposition =
      tenantDetails?.registeredToCompositionScheme ? true : false;
    const tenantShipAddress = Utility.getPreferredAddress(
      tenantDetails,
      'shippingAddresses'
    );
    const shipFromState = tenantShipAddress?.state
      ? tenantShipAddress?.state
      : '';

    switch (gstTreatment) {
      case SPECIAL_ECONOMIC_ZONE:
        if (CUST_SEZ_W_PAY == contactType) {
          return GST_TYPE.INTER;
        } else if (CUST_SEZ_WO_PAY == contactType) {
          return GST_TYPE.EXEMPT;
        }
        break;
      case OVERSEAS:
        return GST_TYPE.INTER;
      case DEEMED_EXPORT:
        if (isRegisteredToComposition) {
          return ExpenseDepositHelper.getSalesTaxTypeIndiaForComposition(
            shipFromState,
            shipToState
          );
        } else {
          return GST_TYPE.EXEMPT;
        }
        break;
      default:
        if (isRegisteredToComposition) {
          return ExpenseDepositHelper.getSalesTaxTypeIndiaForComposition(
            shipFromState,
            shipToState
          );
        } else {
          return Utility.getIndiaDefaultTaxType(shipFromState, shipToState);
        }
    }
  }

  static taxDataParser = (data: any, docType: string) => {
    if (
      docType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
      docType === DOC_TYPE.DEPOSIT
    ) {
      return data.content.filter(
        (tax: any) =>
          tax.type === TAX_TYPES.SALES || tax.type === TAX_TYPES.BOTH
      );
    } else {
      return data.content.filter(
        (tax: any) =>
          tax.type === TAX_TYPES.PURCHASE || tax.type === TAX_TYPES.BOTH
      );
    }
  };

  static calculateTaxGroup(
    tax: any,
    taxableAmount: number,
    isInclusiveTax: boolean = false,
    totalTax: number = 0
  ) {
    if (!tax || typeof tax !== 'object') {
      return [];
    }

    let taxDetails = [];
    if (tax.isTaxGroup) {
      //Breakdown tax group
      taxDetails = this.getTaxGroupDetails(
        tax,
        taxableAmount,
        isInclusiveTax,
        totalTax
      );
    } else {
      //Normal tax
      let taxDetail = this.getTaxDetail(tax, taxableAmount);
      taxDetails.push(taxDetail);
    }
    return taxDetails;
  }

  static getTaxGroupDetails(
    taxGroup: any,
    taxableAmount: number,
    isInclusiveTax: boolean = false,
    totalTax: number = 0
  ) {
    let taxDetails = [];
    let totalPreTax = 0;
    let afterTax;

    taxGroup.taxGroupDetails.forEach((tax: any) => {
      if (tax.applyTo == APPLY_TO_TYPE.PRE) {
        let taxDetail = ExpenseDepositHelper.getTaxDetail(tax, taxableAmount);
        taxDetails.push(taxDetail);
        totalPreTax += taxDetail.taxAmount;
      } else if (tax.applyTo == APPLY_TO_TYPE.AFTER) {
        afterTax = tax;
      }
    });

    if (afterTax) {
      let taxDetail = ExpenseDepositHelper.getTaxDetail(
        afterTax,
        taxableAmount + totalPreTax
      );

      if (isInclusiveTax) {
        totalTax = totalTax || (taxableAmount * taxGroup.percent) / 100;
        taxDetail.taxAmount = Utility.roundOff(totalTax - totalPreTax);
      }

      taxDetails.push(taxDetail);
    }
    return taxDetails;
  }
  static getTaxDetail(tax: any, taxableAmount: number) {
    const percent = tax.percentage || tax.percent || 0;
    return {
      taxAmount: Utility.roundOff((taxableAmount * percent) / 100),
      taxId: tax.id,
      taxName: tax.name,
      taxRate: percent,
      taxCode: tax.taxCode || tax.code,
      taxSeqCode: tax.code,
      taxType: tax.type,
      taxApplyTo: tax.applyTo,
      additionalTaxIn: tax.additionalTaxIn,
      taxableAmount
    };
  }

  static accountDataParser(data: any, docType: string) {
    return data && data.content ? data.content : [];
  }

  static getAccountURL = (search: string, query?: any) => {
    const config: AccountAPIConfig = {
      ...AccountsService.apiConfig,
      Page: 0,
      SearchTerm: search,
      Limit: 10,
      AccountGroupsString: undefined,
      Query: query,
    };
    AccountsService.apiConfig = config;
    return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
  };
}
