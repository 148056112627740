import React, { Component, Fragment } from 'react';
import { getLocalisedText } from '../Translate/LanguageManager';
import { ProductTable } from './dktable/product/ProductTable';
import { getBottomBorderColor, getBottomBorderWidth, getFontWeight, getTableFontStyleProp } from './dktable/TableUtility';
import Utility, { getFontSizeClass, getVW } from '../Utilities/Utility';
import { FONT_SIZE } from '../Constants/Constants';

export default class DKExchangeRateSection extends Component {
    tableOption = ProductTable.getTableOption(!this.props.data.isReadOnlyMode)

    constructor(props) {
        super(props)
        this.state = {
            currentRowIndex: 0
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.data.showExchangeRate && this.renderExchangeRateTable()}
            </Fragment>
        )
    }

    renderExchangeRateTable() {
        const { style } = ProductTable.getDefaultItemColumn(this.props.tableStyle, 0, false, this.props.data.printScale);

        return (
            <div className='parent-width'>
                <div>
                    {this.getTableHeaders()}
                </div>
                <div style={{
                    borderWidth: style.borderWidth,
                    borderColor: style.borderColor,
                    borderStyle: style.borderStyle,
                    borderTop: 'none'
                }}>
                    <table className='parent-width' style={{ borderSpacing: 0 }}>
                        {this.getTableData()}
                    </table>
                </div>
            </div>
        );
    }

    getExchangeRateData() {
        const exchangeRateData = {
            exchangeRate: this.props.data.exchangeRate,
            baseCurrency: {
                currencyName: this.props.data.tenantInfo.currency,
                currencySymbol: this.props.data.showCurrency ? this.props.data.tenantInfo.currencySymbol : undefined,
                subTotal: this.props.data.subTotal ? (this.props.data.subTotal / this.props.data.exchangeRate) : 0,
                total: this.props.data.total ? (this.props.data.total / this.props.data.exchangeRate) : 0
            },
            documentCurrency: {
                currencyName: this.props.data.currencyName,
                currencySymbol: this.props.data.showCurrency ? this.props.data.currencyCustomValue : undefined,
                subTotal: this.props.data.subTotal,
                total: this.props.data.total
            },
            taxBreakdown: this.props.data.taxBreakdown
        };

        if (Utility.getIsIndiaCountry(this.props.data)) {
            exchangeRateData.taxBreakdown = [
                {
                    taxName: getLocalisedText("tax"),
                    taxAmount: this.props.data.taxAmount
                }
            ];
        }

        return exchangeRateData;
    }

    getExchangeConversionText(exchangeRateData) {
        return `${exchangeRateData.baseCurrency.currencyName} 1 = ${exchangeRateData.documentCurrency.currencyName} ${exchangeRateData.exchangeRate}`;
    }

    getTableHeaders() {
        const { style } = ProductTable.getDefaultHeaderColumn(this.props.tableStyle, false, this.props.data.printScale);

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    background: style.backgroundColor,
                    textAlign: 'left',
                    textOverflow: 'clip ellipsis',
                    borderWidth: style.borderWidth,
                    borderColor: style.borderColor,
                    borderStyle: style.borderStyle,
                    borderBottomColor: getBottomBorderColor(style) ?? undefined,
                    borderBottomWidth: getBottomBorderWidth(style) ?? undefined,
                    borderCollapse: 'collapse',
                    ...getTableFontStyleProp({ fontSize: style.fontSize }, this.props.data.printScale),
                    fontWeight: getFontWeight(style.fontWeight),
                    verticalAlign: style.verticalAlignment
                }}
            >
                <div style={{
                    paddingTop: style.paddingTop,
                    paddingBottom: style.paddingBottom,
                    paddingLeft: style.paddingLeft,
                    paddingRight: style.paddingRight,
                }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            ...getTableFontStyleProp({ fontSize: style.fontSize, color: style.fontColor }, this.props.data.printScale),
                            fontStyle: style.fontStyle,
                            paddingLeft: 0
                        }}
                    >
                        {getLocalisedText(this.props.data.exchangeRateTitle)}
                    </text>
                </div>
                <div style={{
                    paddingTop: getVW(Utility.convertToPrintScale(2, this.props.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(2, this.props.data.printScale)),
                    paddingLeft: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                    paddingRight: getVW(Utility.convertToPrintScale(5, this.props.data.printScale)),
                    // minWidth: getVW('12vw', true),
                    textAlign: 'right'
                }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            ...getTableFontStyleProp({ fontSize: FONT_SIZE.xSmall, color: style.fontColor }, this.props.data.printScale),
                            fontStyle: style.fontStyle,
                            fontWeight: 'normal',
                            paddingLeft: 0
                        }}
                    >
                        {this.getExchangeConversionText(this.getExchangeRateData())}
                    </text>
                </div>
            </div>
        );
    }

    getDataCell(value, style, config) {
        return (
            <td
                className='cell'
                colSpan={config?.colSpan}
                style={{
                    borderTopWidth: style.lastRowTopWidth ?? style.borderWidth,
                    borderTopColor: style.lastRowTopColor ?? style.borderColor,
                    borderTopStyle: style.borderStyle,
                    textOverflow: 'clip ellipsis',
                    paddingTop: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingTop ?? 10, this.props.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingBottom ?? 10, this.props.data.printScale)),
                    paddingLeft: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingLeft ?? 6, this.props.data.printScale)),
                    paddingRight: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingRight ?? 13, this.props.data.printScale)),
                    backgroundColor: style.footerBackgroundColor ?? undefined
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: style.justifyContent ?? 'flex-end',
                    alignItems: 'center'
                }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...getTableFontStyleProp({ fontSize: style.fontSize, color: style.fontColor }, this.props.data.printScale),
                            textAlign: 'center',
                            fontStyle: style.fontStyle,
                            fontWeight: getFontWeight(style.fontWeight),
                            direction: Utility.getIsSaudiArabiaCountry(this.props.data) ? 'rtl' : 'ltr'
                        }}
                    >
                        {value}
                    </div>
                </div>
            </td>
        );
    }

    getTableData() {
        const { style } = ProductTable.getDefaultItemColumn(this.props.tableStyle, 0, false, this.props.data.printScale);
        const totalRowStyle = {
            ...style,
            lastRowTopWidth: this.props.tableStyle.lastRowTopWidth ?? undefined,
            lastRowTopColor: this.props.tableStyle.lastRowTopColor ?? undefined,
            fontColor: this.props.tableStyle.footerTextColor ?? undefined,
            footerBackgroundColor: this.props.tableStyle.footerBackgroundColor ?? undefined
        }

        const exchangeRateData = this.getExchangeRateData();

        return (
            <tbody>
                <tr>
                    {this.getDataCell('', { ...style, borderWidth: 0 })}
                    {this.getDataCell(exchangeRateData.documentCurrency.currencyName, { ...style, borderWidth: 0, fontWeight: 'bold' })}
                    {this.getDataCell(exchangeRateData.baseCurrency.currencyName, { ...style, borderWidth: 0, fontWeight: 'bold' })}
                </tr>
                <tr>
                    {this.getDataCell(getLocalisedText('subtotal'), { ...style, justifyContent: 'flex-end' })}
                    {this.getDataCell(Utility.toCurrencyFormat(exchangeRateData.documentCurrency.subTotal, exchangeRateData.documentCurrency.currencySymbol), style)}
                    {this.getDataCell(Utility.toCurrencyFormat(exchangeRateData.baseCurrency.subTotal, exchangeRateData.baseCurrency.currencySymbol), style)}
                </tr>
                {exchangeRateData.taxBreakdown && exchangeRateData.taxBreakdown.length > 0 &&
                    exchangeRateData.taxBreakdown.map((tax) => {
                        return (
                            <tr>
                                {this.getDataCell(tax.taxName, { ...style, justifyContent: 'flex-end' })}
                                {this.getDataCell(Utility.toCurrencyFormat(tax.taxAmount, exchangeRateData.documentCurrency.currencySymbol), style)}
                                {this.getDataCell(Utility.toCurrencyFormat((tax.taxAmount / exchangeRateData.exchangeRate), exchangeRateData.baseCurrency.currencySymbol), style)}
                            </tr>
                        );
                    })
                }
                <tr>
                    {this.getDataCell(getLocalisedText('total'), { ...totalRowStyle, justifyContent: 'flex-end', fontWeight: 'bold' })}
                    {this.getDataCell(Utility.toCurrencyFormat(exchangeRateData.documentCurrency.total, exchangeRateData.documentCurrency.currencySymbol), totalRowStyle)}
                    {this.getDataCell(Utility.toCurrencyFormat(exchangeRateData.baseCurrency.total, exchangeRateData.baseCurrency.currencySymbol), totalRowStyle)}
                </tr>
            </tbody>
        );
    }
}
