import { PLANS } from '../../Constants/Enum';
import Utility from '../../Utility/Utility';
import ic_professional from '../../Assets/pricingSection/professional.svg';
import ic_essential from '../../Assets/pricingSection/essential.svg';
import ic_startup from '../../Assets/pricingSection/startup.svg';
import AuthService from '../../Services/Auth';

export const DEFAULT_TRIAL_PLAN_DAYS = 15;

export const getWelcomeName = (details: any) => {
  return `<span style="font-size: 16px">👋</span> Welcome <b>${AuthService.getUserName()}</b>`;
};

const getFirstAndLastName = (details: any) => {
  const lastName = !Utility.isEmpty(details[0].lastName)
    ? ` ${details[0].lastName}`
    : '';
  return details[0].firstName + lastName;
};

export const getPlanIcon = (planName: any) => {
  switch (planName?.toLowerCase()) {
    case PLANS.STARTUP:
      return ic_startup;
    case PLANS.ESSENTIAL:
      return ic_essential;
    case PLANS.PROFESSIONAL:
      return ic_professional;

    default:
      return ic_professional;
  }
};

export const getPlanName = (planName: any) => {
  switch (planName?.toLowerCase()) {
    case PLANS.STARTUP:
      return Utility.convertInTitleCase(PLANS.STARTUP);
    case PLANS.ESSENTIAL:
      return Utility.convertInTitleCase(PLANS.ESSENTIAL);
    case PLANS.PROFESSIONAL:
      return Utility.convertInTitleCase(PLANS.PROFESSIONAL);

    default:
      return Utility.convertInTitleCase(PLANS.PROFESSIONAL);
  }
};

export const isUserInTrial = (details: any) => {
  return details?.InTrial && !details.TrialOver;
};

export const isTrialPlanAvailable = (details: any) => {
  return !details?.InTrial && !details?.TrialOver;
};

export const getTrialDays = (inTrialMode = false, details: any) => {
  /* Trial Days for an unsubscribed user */
  let trialDays = details?.TrialDays ?? DEFAULT_TRIAL_PLAN_DAYS;

  /* Pending trial days */
  try {
    if (details && inTrialMode) {
      const trialEndTime = new Date(details.TrialEndDate).getTime();
      const currentTime = new Date().getTime();

      trialDays = Math.ceil(
        (trialEndTime - currentTime) / (1000 * 60 * 60 * 24)
      );
      trialDays = trialDays < 0 ? 0 : trialDays;
    }
  } catch (err) {
    trialDays = 0;
  }

  return trialDays;
};

export const getPlanBadgeStatus = (isPlanActive: any) => {
  return isPlanActive ? 'Active' : 'Expired';
};

export const isPlanActive = (details: any) => {
  return details?.Subscribed || isUserInTrial(details);
};
