import { showAlert } from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';

let resolveCallback: any;

const useQCConfirm = () => {
  const { t, i18n } = useTranslation();
  const onWithQC = () => {
    resolveCallback(true);
  };

  const onSkipQC = () => {
    resolveCallback(false);
  };

  const onCancel = () => {
    resolveCallback(null);
  };

  const qcConfirm = (text: string, title?: string) => {
    let buttons = [
      {
        title: t(`CONFIRMATION_POPUP.CANCEL`),
        className: 'bg-gray2 border-m mt-r ml-r',
        onClick: () => {
          onCancel();
        }
      },
      {
        title: `Skip QC`,
        className: 'bg-gray2 border-m mt-r ml-r',
        onClick: () => {
          onSkipQC();
        }
      },
      {
        title: `Go with QC`,
        className: 'bg-blue text-white mt-r ml-r',
        onClick: () => {
          onWithQC();
        }
      }
    ];
    const confirmTitle = title ? title : t(`CONFIRMATION_POPUP.TITLE`);
    showAlert(confirmTitle, text, buttons);
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return { qcConfirm, onWithQC, onSkipQC, onCancel };
};

export default useQCConfirm;
