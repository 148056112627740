import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS, MODULES_NAME } from '../../Constants/Constant';
import AccountsService from '../../Services/Accounts';
import InvoiceService from '../../Services/Invoice';
import ProductService from '../../Services/Product';
import TaxService from '../../Services/Tax';
import { RootState } from '../Store';
import { TAX_TYPES as activity } from '../../Constants/Constant';
import { CommonData } from '../../Models/ReduxStore';
import CurrencyService from '../../Services/Currency';
import UndepositedService from '../../Services/Undeposited';
import CoaService from '../../Services/COA';
import PriceListService from '../../Services/PriceList';
import PrintService from '../../Services/Print';
import { SliceManager } from './SliceManager';
import PaymentService from '../../Services/Payment';
import { PRODUCTS_CAPS } from '../../Constants/Enum';
import EInvoiceService from '../../Services/EInvoice';
import CustomFieldService from '../../Services/CustomField';
import Utility from '../../Utility/Utility';
import ClassesService from '../../Services/Classes';
import AppIntegrationService from '../../Services/AppIntegration';
import { CustomField } from '../../Models/CustomField';

const initialState: CommonData = {
  data: {
    uoms: [],
    appIntegrationMapping: [],
    taxes: [],
    warehouses: [],
    hsnCodes: [],
    sacCodes: [],
    natureOfIncomeAccounts: [],
    tax: {
      purchase: [],
      sell: []
    },
    currency: [],
    accountGroupExpenses: [],
    currencyListWithExchangeRate: [],
    currencyListWithExchangeRateByDocDate: [],
    accountstype: [],
    priceList: [],
    undeposited: [],
    taxTable: [],
    customPrintTemplates: {
      list: null,
      selected: undefined
    },
    templatePrintInfo: null,
    templateBulkPrintInfo: null,
    gstReturn: [],
    isLoadingPopupWrapper: false,
    chequeTemplateList: [],
    IRPData: null,
    workOrderFromSalesOrder: {
      create: false,
      salesOrderData: null
    },
    workOrderFromSalesInvoice: {
      create: false,
      salesInvoiceData: null
    },
    customFields: [],
    productCustomFields: [],
    classData: [],
    batchSerialCustomFields: [],
    documentFooterAction: null,
    assetCustomFields: [],
    storeObjForTopbarExpansion: true,
    showMainDocsInFullScreen: true,
    showSettingsInFullScreen: false,
    showSettingsPopup: false,
    selectedSetting: null,
    preferredVendorProductCustomFields: []
  },
  updateDocumentData: {
    newData: null,
    oldData: null
  },
  emailTriggeredDocumentIds: {},
  creatingDocumentFromReport: { isCreated: false },
  status: API_STATUS.IDLE
};

export const fetchUndeposited = createAsyncThunk('undeposited', async () => {
  const response = await UndepositedService.getDepositedList();
  return response;
});
export const fetchAccountGroupExpenses = createAsyncThunk(
  'fetchAccountGroupExpenses',
  async () => {
    const response = await PaymentService.getFeeAccounts();
    return response;
  }
);
export const fetchCurrency = createAsyncThunk('currency', async () => {
  const response = await CurrencyService.getCurrencyList();
  return response;
});
export const fetchPriceList = createAsyncThunk('priceList', async () => {
  const response = await PriceListService.getPriceList();
  return response;
});

export const fetchAccountType = createAsyncThunk('type', async () => {
  const response = await CoaService.getAccountType();
  return response;
});

export const fetchUOMs = createAsyncThunk('uoms', async () => {
  const response = await InvoiceService.getUOMs();
  return response.content;
});

export const fetchTaxes = createAsyncThunk('taxes', async () => {
  const response = await InvoiceService.getTaxes();
  return response.content;
});

export const fetchWarehouses = createAsyncThunk('warehouses', async () => {
  const response = await ProductService.fetchWarehouses();
  return response.content;
});

export const fetchHSNCodes = createAsyncThunk('hsnCodes', async () => {
  const response = await AccountsService.fetchHSNCodes();
  return response.content;
});

export const fetchSACCodes = createAsyncThunk('sacCodes', async () => {
  const response = await AccountsService.fetchSACCodes();
  return response.content;
});

export const fetchNatureOfIncomeAccounts = createAsyncThunk(
  'natureOfIncomeAccounts',
  async () => {
    const response = await AccountsService.fetchNatureOfIncomePayments();
    return response.content;
  }
);

export const fetchTax = createAsyncThunk('tax', async () => {
  const response = await TaxService.getTax();

  return response;
});

export const fetchCurrencyWithExchangeRate = createAsyncThunk(
  'multiCurrencyExchangeRate',
  async () => {
    const response = await CurrencyService.getCurrencyListWithExchangeRates();
    return response;
  }
);

export const fetchCurrencyExchangeRateByDocDate = createAsyncThunk(
  'fetchCurrencyWithExchangeRateByDocDate',
  async (docDate: any) => {
    const response =
      await CurrencyService.getCurrencyListWithExchangeRatesBydocDate(docDate);
    return response;
  }
);

export const fetchCustomPrintTemplates = createAsyncThunk(
  'customPrintTemplates',
  async (docType: any) => {
    const response = await PrintService.getInvoiceDesignTemplatesByCategory(
      docType
    );
    return response;
  }
);

export const fetchTemplatePrintInfo = createAsyncThunk(
  'templatePrintInfo',
  async (payload: any) => {
    const response = await PrintService.getTemplatePrintInfo(payload);
    return response;
  }
);

export const fetchTemplateBulkPrintInfo = createAsyncThunk(
  'templateBulkPrintInfo',
  async (payload: any) => {
    const response = await PrintService.getTemplateBulkPrintInfo(payload);
    return response;
  }
);

export const fetchChequeTemplateList = createAsyncThunk(
  'chequeTemplateList',
  async () => {
    const response = await PrintService.getTemplateList(
      PRODUCTS_CAPS.ERP,
      `CHEQUE`
    );
    return response;
  }
);

export const fetchGstReturns = createAsyncThunk(
  'GstReturn',
  async (data: any) => {
    const response = data;
    return response;
  }
);

export const connectIndidEInvoice = createAsyncThunk(
  'drafts',
  async (data: any) => {
    const response = await EInvoiceService.connectIndiaEInvoice(data);
    return response;
  }
);

export const fetchIndidEInvoiceUserName = createAsyncThunk(
  'drafts',
  async (data: any) => {
    const response = await EInvoiceService.fetchIndidEInvoiceUserName();
    return response;
  }
);

export const fetchClassesByDimensionId = createAsyncThunk(
  'getClassByDimensionId',
  async () => {
    const response = await ClassesService.getClassesByDimensionId();
    return response;
  }
);

export const fetchCustomFields = createAsyncThunk(
  'getCustomFields',
  async (data: any) => {
    try {
      const response = await CustomFieldService.getCustomFields(data);
      return response;
    } catch (err) {
      console.log('err fetchCustomFields', err);
    }
  }
);

export const fetchProductCustomFields = createAsyncThunk(
  'getProductCustomFields',
  async (data: any) => {
    try {
      const response = await CustomFieldService.getCustomFields(data, true);
      return response;
    } catch (err) {
      console.log('err fetchCustomFields', err);
    }
  }
);
export const fetchPreferredVendorProductCustomFields = createAsyncThunk(
  'getPreferredVendorProductCustomFields',
  async (data: any) => {
    try {
      const response = await CustomFieldService.getCustomFields(data, true);
      return response;
    } catch (err) {
      console.log('err fetchPreferredVendorProductCustomFields', err);
    }
  }
);
export const fetchBatchSerialCustomFields = createAsyncThunk(
  'getBatchSerialCustomFields',
  async (data: any) => {
    try {
      const response = await CustomFieldService.getCustomFields(data, true);
      return response;
    } catch (err) {
      console.log('err fetchCustomFields', err);
    }
  }
);
export const fetchAssetCustomFields = createAsyncThunk(
  'getAssetCustomFields',
  async (data: any) => {
    try {
      const response = await CustomFieldService.getCustomFields(data, true);
      return response;
    } catch (err) {
      console.log('err fetchCustomFields', err);
    }
  }
);

export const fetchAppIntegrationMapping = createAsyncThunk(
  'fetchAppIntegrationMapping',
  async () => {
    try {
      const response = await AppIntegrationService.getAppIntegrationMapping();
      return response;
    } catch (err) {
      console.log('err fetchAppIntegrationMapping', err);
    }
  }
);

// export const fetchGstReturns = createAsyncThunk(
//   'GstReturn',
//   async (data: any) => {
//     const response = data;
//     return response;
//   }
// );

function getFilteredCustomFieldsPayloadByModule(
  payload: any,
  module: MODULES_NAME
) {
  if (Utility.isEmpty(payload?.content)) return payload;

  const filteredContent = payload.content.filter((customField: CustomField) =>
    customField.modules?.includes(module)
  );

  return {
    ...payload,
    content: filteredContent,
    totalElements: filteredContent.length,
    numberOfElements: filteredContent.length
  };
}

function getFilteredBuySellTaxes(taxList?: any[]) {
  taxList = taxList || [];

  const sellSideTaxList = taxList.filter(
    (sellSideTaxElement: any) =>
      sellSideTaxElement.type === activity.SALES ||
      sellSideTaxElement.type === activity.BOTH
  );
  const buySideTaxList = taxList.filter(
    (buySideTaxElement: any) =>
      buySideTaxElement.type === activity.PURCHASE ||
      buySideTaxElement.type === activity.BOTH
  );

  return {
    buySideTaxList,
    sellSideTaxList
  };
}

function storeTaxesFromResponse(state: CommonData, taxesResponse?: any[]) {
  taxesResponse = taxesResponse || [];
  state.data.taxes = taxesResponse;
  // Separate out sales and purchase taxes here, no need for extra API call
  const { buySideTaxList, sellSideTaxList } =
    getFilteredBuySellTaxes(taxesResponse);
  state.data.tax.purchase = buySideTaxList;
  state.data.tax.sell = sellSideTaxList;
}

export const CommonDataSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    setSelectedPrintTemplate: (state, action: PayloadAction<any>) => {
      state.data.customPrintTemplates.selected = { ...action.payload };
    },
    setCustomPrintTemplates: (state, action: PayloadAction<any>) => {
      state.data.customPrintTemplates.list = action.payload;
    },
    setTemplatePrintInfo: (state, action: PayloadAction<any>) => {
      state.data.templatePrintInfo = action.payload;
    },
    setTemplateBulkPrintInfo: (state, action: PayloadAction<any>) => {
      state.data.templateBulkPrintInfo = action.payload;
    },
    setIsLoadingPopupWrapper: (state, action: PayloadAction<any>) => {
      state.data.isLoadingPopupWrapper = action.payload;
    },
    setFooterAction: (state, action: PayloadAction<any>) => {
      state.data.documentFooterAction = action.payload;
    },
    setCreateWOFromSalesOrder: (state, action: PayloadAction<any>) => {
      state.data.workOrderFromSalesOrder = action.payload;
    },
    setCreateWOFromSalesInvoice: (state, action: PayloadAction<any>) => {
      state.data.workOrderFromSalesInvoice = action.payload;
    },
    appendHSNInStore: (state, action: PayloadAction<any>) => {
      let findIfExist = state.data?.hsnCodes?.find(
        (acc: any) => acc.code === action.payload?.code
      );
      if (Utility.isEmpty(findIfExist)) {
        state.data.hsnCodes = state.data.hsnCodes
          ? [...state.data.hsnCodes, action.payload]
          : [action.payload];
      }
    },
    appendSACInStore: (state, action: PayloadAction<any>) => {
      let findIfExist = state.data?.sacCodes?.find(
        (acc: any) => acc.code === action.payload?.code
      );
      if (Utility.isEmpty(findIfExist)) {
        state.data.sacCodes = state.data.sacCodes
          ? [...state.data.sacCodes, action.payload]
          : [action.payload];
      }
    },
    documentUpdated: (state, action: PayloadAction<any>) => {
      state.updateDocumentData = action.payload;
    },
    updateTopbarExpansion: (state, action: any) => {
      state.data.storeObjForTopbarExpansion = action.payload;
    },
    setTaxes: (state, action: PayloadAction<any[]>) => {
      storeTaxesFromResponse(state, action.payload);
    },
    setEmailTriggerIds: (state, action: PayloadAction<any>) => {
      state.emailTriggeredDocumentIds = action.payload;
    },
    setShowMainDocsInFullScreen: (state, action: PayloadAction<any>) => {
      state.data.showMainDocsInFullScreen = action.payload;
    },
    setShowSettingsInFullScreen: (state, action: PayloadAction<any>) => {
      state.data.showSettingsInFullScreen = action.payload;
    },
    setShowSettings: (state, action: PayloadAction<any>) => {
      state.data.showSettingsPopup = action.payload;
    },
    setSelectedSetting: (state, action: PayloadAction<any>) => {
      state.data.selectedSetting = action.payload;
    },
    updateCreatingDocumentStatusFromReport: (state, action: PayloadAction<any>) => {
      state.creatingDocumentFromReport = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUOMs.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchUOMs.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.uoms = action.payload;
      })
      .addCase(fetchTaxes.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        storeTaxesFromResponse(state, action.payload);
      })
      .addCase(fetchUndeposited.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchUndeposited.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let currencyList: any = action.payload;
        state.data.undeposited = currencyList;
      })
      .addCase(fetchAccountGroupExpenses.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccountGroupExpenses.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let accountGroupExpenses: any = action.payload;
        state.data.accountGroupExpenses = accountGroupExpenses;
      })
      .addCase(fetchWarehouses.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchWarehouses.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.warehouses = action.payload;
      })
      .addCase(fetchHSNCodes.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchHSNCodes.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.hsnCodes = action.payload;
      })
      .addCase(fetchSACCodes.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchSACCodes.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.sacCodes = action.payload;
      })
      .addCase(fetchTax.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTax.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let taxList: any = action.payload;
        const sellSideTaxList = taxList.content.filter(
          (sellSideTaxElement: any) =>
            sellSideTaxElement.type === activity.SALES ||
            sellSideTaxElement.type === activity.BOTH
        );
        const buySideTaxList = taxList.content.filter(
          (buySideTaxElement: any) =>
            buySideTaxElement.type === activity.PURCHASE ||
            buySideTaxElement.type === activity.BOTH
        );
        state.data.tax.purchase = buySideTaxList;
        state.data.tax.sell = sellSideTaxList;
      })
      .addCase(fetchCurrency.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let currencyList: any = action.payload;
        state.data.currency = currencyList.content;
      })
      .addCase(fetchPriceList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPriceList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let priceList: any = action.payload;
        state.data.priceList = priceList.content;
      })
      .addCase(fetchAccountType.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccountType.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let AccountList: any = action.payload;
        state.data.accountstype = AccountList.content;
      })
      .addCase(fetchNatureOfIncomeAccounts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchNatureOfIncomeAccounts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.natureOfIncomeAccounts = action.payload;
        SliceManager.setSliceData(
          'selectNatureOfIncomeAccounts',
          action.payload
        );
      })
      .addCase(fetchCurrencyWithExchangeRate.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrencyWithExchangeRate.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const response: any = action.payload;
        state.data.currencyListWithExchangeRate = response['content'];
      })
      .addCase(fetchCurrencyExchangeRateByDocDate.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchCurrencyExchangeRateByDocDate.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          const response: any = action.payload;
          state.data.currencyListWithExchangeRateByDocDate =
            response['content'];
        }
      )
      .addCase(fetchCustomPrintTemplates.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCustomPrintTemplates.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.customPrintTemplates.list = action.payload;
      })
      .addCase(fetchTemplatePrintInfo.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTemplatePrintInfo.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.templatePrintInfo = action.payload;
      })
      .addCase(fetchTemplateBulkPrintInfo.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTemplateBulkPrintInfo.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.templateBulkPrintInfo = action.payload;
      })
      .addCase(fetchChequeTemplateList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchChequeTemplateList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.chequeTemplateList = action.payload;
      })
      .addCase(fetchGstReturns.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchGstReturns.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.gstReturn = action.payload;
      })
      .addCase(connectIndidEInvoice.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(connectIndidEInvoice.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.IRPData = action.payload;
      })
      .addCase(fetchClassesByDimensionId.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchClassesByDimensionId.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.classData = action.payload;
      })
      .addCase(fetchCustomFields.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.customFields = action.payload;

        if (!action.meta.arg.isInitialRequest) return;

        // Commenting optimization call for CF ordering in doc forms
        // state.data.productCustomFields = getFilteredCustomFieldsPayloadByModule(
        //   action.payload,
        //   MODULES_NAME.PRODUCT
        // );
        state.data.assetCustomFields = getFilteredCustomFieldsPayloadByModule(
          action.payload,
          MODULES_NAME.ASSET
        );
        state.data.batchSerialCustomFields =
          getFilteredCustomFieldsPayloadByModule(
            action.payload,
            MODULES_NAME.BATCH_SERIAL
          );
      })
      .addCase(fetchProductCustomFields.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.productCustomFields = action.payload;
      })
      .addCase(fetchPreferredVendorProductCustomFields.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPreferredVendorProductCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.preferredVendorProductCustomFields = action.payload;
      })
      
      .addCase(fetchBatchSerialCustomFields.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBatchSerialCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.batchSerialCustomFields = action.payload;
      })
      .addCase(fetchAssetCustomFields.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAssetCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.assetCustomFields = action.payload;
      })
      .addCase(fetchAppIntegrationMapping.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAppIntegrationMapping.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.appIntegrationMapping = action.payload?.content;
      });
  }
});

export const {
  setSelectedPrintTemplate,
  setCustomPrintTemplates,
  setTemplatePrintInfo,
  setTemplateBulkPrintInfo,
  setIsLoadingPopupWrapper,
  setCreateWOFromSalesOrder,
  setCreateWOFromSalesInvoice,
  setFooterAction,
  appendHSNInStore,
  appendSACInStore,
  documentUpdated,
  updateTopbarExpansion,
  setTaxes,
  setEmailTriggerIds,
  setShowMainDocsInFullScreen,
  setShowSettingsInFullScreen,
  setShowSettings,
  setSelectedSetting,
  updateCreatingDocumentStatusFromReport
} = CommonDataSlice.actions;

export const selectUOMs = (state: RootState): any[] =>
  state.commonData.data.uoms;
export const selectAppIntegrationMapping = (state: RootState): any[] =>
  state.commonData.data.appIntegrationMapping;
export const selectTaxTable = (state: RootState): any[] =>
  state.commonData.data.taxTable;
export const selectPriceLists = (state: RootState): any[] =>
  state.commonData.data.priceList;
export const selectAccountsType = (state: RootState): any[] =>
  state.commonData.data.accountstype;
export const selectTaxes = (state: RootState): any[] =>
  state.commonData.data.taxes;
export const selectWarehouse = (state: RootState): any[] =>
  state.commonData.data.warehouses;
export const selectHSNCodes = (state: RootState): any[] =>
  state.commonData.data.hsnCodes;
export const selectSacCodes = (state: RootState): any[] =>
  state.commonData.data.sacCodes;
export const selectNatureOfIncomeAccounts = (state: RootState): any[] =>
  state.commonData.data.natureOfIncomeAccounts;
export const selectPurchaseTax = (state: RootState): any[] =>
  state.commonData.data.tax.purchase;
export const selectSalesTax = (state: RootState): any[] =>
  state.commonData.data.tax.sell;
export const selectCurrency = (state: RootState): any[] =>
  state.commonData.data.currency;
export const selectCurrencyListWithExchangeRate = (state: RootState): any[] =>
  state.commonData.data.currencyListWithExchangeRate;
export const selectCurrencyListWithExchangeRateByDocDate = (
  state: RootState
): any[] => state.commonData.data.currencyListWithExchangeRateByDocDate;
export const selectUndeposited = (state: RootState): any[] =>
  state.commonData.data.undeposited;
export const selectCustomPrintTemplates = (state: RootState) =>
  state.commonData.data.customPrintTemplates.list;
export const getSelectedPrintTemplate = (state: RootState) =>
  state.commonData.data.customPrintTemplates.selected;
export const selectTemplatePrintInfo = (state: RootState) =>
  state.commonData.data.templatePrintInfo;
export const selectTemplateBulkPrintInfo = (state: RootState) =>
  state.commonData.data.templateBulkPrintInfo;
export const selectAccountGroupExpenses = (state: RootState) =>
  state.commonData.data.accountGroupExpenses.content;
export const selectGstReturn = (state: RootState) =>
  state.commonData.data.gstReturn;
export const selectIsLoadingPopupWrapper = (state: RootState) =>
  state.commonData.data.isLoadingPopupWrapper;
export const selectChequeTemplateList = (state: RootState): any[] =>
  state.commonData.data.chequeTemplateList;
export const selectIRPData = (state: RootState): any[] =>
  state.commonData.data.IRPData;
export const selectClassesData = (state: RootState): any[] =>
  state.commonData.data.classData;
export const selectCustomFields = (state: RootState): any =>
  state.commonData.data.customFields;
export const selectProductCustomFields = (state: RootState): any =>
  state.commonData.data.productCustomFields;
export const selectBatchSerialCustomFields = (state: RootState): any =>
  state.commonData.data.batchSerialCustomFields;
export const selectFACustomFields = (state: RootState): any =>
  state.commonData.data.assetCustomFields;
export const selectCreateWOfromSO = (state: RootState): any =>
  state.commonData.data.workOrderFromSalesOrder;
export const selectCreateWOfromSI = (state: RootState): any =>
  state.commonData.data.workOrderFromSalesInvoice;
export const selectedFooterAction = (state: RootState) =>
  state.commonData.data.documentFooterAction;
export const SelectUpdateDocumentData = (state: RootState) =>
  state.commonData?.updateDocumentData;
export const selectTopbarExpansion = (state: RootState) =>
  state.commonData.data.storeObjForTopbarExpansion;
export const SelectEmailTriggerIdsData = (state: RootState) =>
  state.commonData?.emailTriggeredDocumentIds;
export const selectShowMainDocsInFullScreen = (state: RootState) =>
  state.commonData?.data?.showMainDocsInFullScreen;
export const selectShowSettingsInFullScreen = (state: RootState) =>
  state.commonData?.data?.showSettingsInFullScreen;
export const selectShowSettings = (state: RootState) =>
  state.commonData?.data?.showSettingsPopup;
export const selectSelectedSetting = (state: RootState) =>
  state.commonData?.data?.selectedSetting;
export const selectPreferredVendorProductCustomFields = (state: RootState): any =>
  state.commonData.data.preferredVendorProductCustomFields;
export const selectCreatingDocumentStatusFromReport = (state: RootState): any =>
  state.commonData?.creatingDocumentFromReport;

export default CommonDataSlice.reducer;
