import React, { Component } from 'react';
import { LongMonth, ShortMonth } from './CalendarConstants';
import "./Calendar.css"

// interface Props {
//     month: number
//     isLongFormat: boolean
//     year: number
// }

export default class MonthContainer extends Component {

    render() {
        return (
            <div className='fs-r fw-b text-black-light white-space-nowrap parent-width text-align-center'
                style={this.props.style}
            >
                <text className="parent-width">{this.getMonthString()}</text>
            </div>
        );
    }

    getMonthString() {
        var currentMonth = this.props.month - 1
        if (this.props.isLongFormat) {
            var mL = LongMonth
            if (currentMonth < 0 || currentMonth > mL.length) {
                return 'Error'
            }
            else {
                return mL[currentMonth] + ' ' + this.props.year
            }
        }
        else {
            var mS = ShortMonth
            if (currentMonth < 0 || currentMonth > mS.length) {
                return 'Error'
            }
            else {
                return mS[currentMonth] + ' ' + this.props.year
            }
        }
    }
}
