import { DKLabel } from 'deskera-ui-library';
import { GOOGLE_NO_TRANSLATE_CLASS } from '../../../../../Constants/Constant';
interface CostingCardProps {
  title: string;
  actualCost: string | number;
  expectedCost: string | number;
}

const CostingCard = (props: CostingCardProps) => {
  /**
   * Main renderer
   */
  return (
    <div
      className="row bg-white border-radius-m shadow-s border-m position-relative bg-red"
      style={{ width: 260, height: 110, overflow: 'hidden' }}
    >
      <div
        className="bg-gray3 parent-height position-absolute"
        style={{ width: 5 }}
      />
      <div className="column parent-width p-r">
        <DKLabel text={props.title} />
        <DKLabel
          text={props.actualCost}
          className={
            'fw-b fs-m mt-xs parent-width ' + GOOGLE_NO_TRANSLATE_CLASS
          }
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        />

        <DKLabel text={'Estimated:'} className="mt-r text-gray" />
        <DKLabel
          text={props.expectedCost}
          className={'text-gray parent-width ' + GOOGLE_NO_TRANSLATE_CLASS}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        />
      </div>
    </div>
  );
};

export default CostingCard;
