import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import {
  ConfigPayload,
  GridState,
  ReminderConfiguration
} from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import InvoiceService from '../../Services/Invoice';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';
import {
  DueDateItem,
  SettingItem
} from '../../Components/Contacts/InvoiceRemider';

const initialState: GridState = {
  data: {} as any,
  reminderSetting: null,
  reminderConfiguration: null,
  formData: {
    uoms: [],
    taxes: []
  } as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  summary: {},
  paymentOptions: null
};

export const fetchInvoices = createAsyncThunk('invoices', async () => {
  try {
    const response = await InvoiceService.getInvoicesByPage();
    return response;
  } catch (error) {
    return error;
  }
});

export const fetchReminderSettings = createAsyncThunk(
  'reminderSetting',
  async () => {
    const response = await InvoiceService.getInvoiceReminderSettings();
    return response;
  }
);

export const fetchReminderConfiguration = createAsyncThunk(
  'reminderConfiguration',
  async () => {
    const response = await InvoiceService.getInvoiceReminderConfiguration();
    return response;
  }
);

export const updateReminderSettings = createAsyncThunk(
  'updateReminderSetting',
  async (data: SettingItem) => {
    const response = await InvoiceService.updateInvoiceReminderSettings(data);
    return response;
  }
);

export const updateReminderConfiguration = createAsyncThunk(
  'updateReminderConfiguration',
  async (data: DueDateItem[]) => {
    const response = await InvoiceService.updateInvoiceReminderConfiguration(
      data
    );
    return response;
  }
);

export const fetchInvoiceSummary = createAsyncThunk(
  'invoiceSummary',
  async () => {
    const response = await InvoiceService.getInvoiceSummary();
    return response;
  }
);

export const fetchPaymentOptions = createAsyncThunk(
  'paymentOptions',
  async (curr: string) => {
    const response = await InvoiceService.fetchPaymentConfiguredOptions(curr);
    return response;
  }
);

export const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    addInvoiceColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      let reservedDocumentIndex = configsToStore.findIndex((column: any) => {
        return column.columnCode === COLUMN_CODE.INVOICE.ReservedStock;
      });
      if (reservedDocumentIndex !== -1) {
        configsToStore.splice(reservedDocumentIndex, 1);
      }

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.INVOICE
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToInvoiceColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    },
    updateInvoiceDetails: (state, action: PayloadAction<any>) => {
      const invoiceDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const invoiceIndex: number = content.findIndex(
        (invoice: any) => invoice.id === invoiceDetails.id
      );
      if (invoiceIndex >= 0) {
        content[invoiceIndex] = invoiceDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceSummary.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchInvoiceSummary.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.summary = action.payload;
      })

      .addCase(fetchInvoices.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchReminderSettings.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchReminderSettings.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.reminderSetting = action.payload;
      })
      .addCase(fetchReminderConfiguration.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchReminderConfiguration.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.reminderConfiguration = action.payload;
      })
      .addCase(updateReminderSettings.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.reminderSetting = action.payload;
      })
      .addCase(updateReminderConfiguration.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const updatedList: ReminderConfiguration[] = [];
        if (action.payload) {
          action.payload.forEach((dueDateItem: ReminderConfiguration) => {
            if (updatedList.find((item) => item.code === dueDateItem.code))
              return;

            updatedList.push(dueDateItem);
          });
        }

        state.reminderConfiguration = updatedList;
      })
      .addCase(fetchPaymentOptions.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPaymentOptions.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.paymentOptions = action.payload;
      });
  }
});

export const {
  addInvoiceColumnConfig,
  addColumnToInvoiceColumnConfig,
  updateColumnConfig,
  updateInvoiceDetails
} = InvoicesSlice.actions;

export const selectInvoices = (state: RootState) => state.invoices.data;

export const selectInvoiceReminderSettings = (state: RootState) =>
  state.invoices.reminderSetting;

export const selectInvoiceReminderConfiguration = (state: RootState) =>
  state.invoices.reminderConfiguration;

export const selectInvoicesLoadingStatus = (state: RootState) =>
  state.invoices.gridContentLoadingStatus;

export const selectInvoicesColumnConfig = (state: RootState) =>
  state.invoices.columnConfig;
export const selectInvoicesColumnConfigTableId = (state: RootState) =>
  state.invoices.configTableId;
export const selectInvoicesSummary = (state: RootState) =>
  state.invoices.summary;
export const selectPaymentOptions = (state: RootState) =>
  state.invoices.paymentOptions;

export default InvoicesSlice.reducer;
