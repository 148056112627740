/* eslint-disable react-hooks/exhaustive-deps */

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { POPUP_CLICK_TYPE, POPUP_TYPE } from '../Constants/Constant';
import {
  PopupClickActionType,
  PopupProps,
  PopupState
} from '../Models/Interfaces';
import { DKButton, DKIcon, DKSpinner } from 'deskera-ui-library';
import ic_arrow_down from './../Assets/Icons/ic_arrow_down2.png';
import ic_import from './../Assets/Icons/ic_import.png';
import ic_close_2 from './../Assets/Icons/ic_close_2.png';
import useDynamicPopupWrapper, {
  IDynamicPopupWrapperProps
} from '../Hooks/usePopupWrapper';
import ic_expand from './../Assets/Icons/ic_expand.svg';
import ic_shrink from './../Assets/Icons/ic_shrink.svg';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import {
  selectShowMainDocsInFullScreen,
  setShowMainDocsInFullScreen
} from '../Redux/Slices/CommonDataSlice';

export function DynamicPopupWrapper(
  wrapperProps: PropsWithChildren<IDynamicPopupWrapperProps>
) {
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const { children, ...props } = wrapperProps;
  return useDynamicPopupWrapper(children, {
    ...props,
    isFullScreenMode: showMainDocsInFullscreen
  });
}

const PopupWrapper: React.FC<PopupProps> = (props) => {
  const initialState: PopupState = {
    type: props.type ? props.type : POPUP_TYPE.POPUP,
    title: props.title ? props.title : '',
    buttonList: props.btnList,
    loadingBtnList: props.loadingBtnList
  };

  const [popupState, setPopupState] = useState<PopupState>(initialState);

  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    let newState = {
      ...popupState
    };

    newState.buttonList = props.btnList;
    setPopupState(newState);
  }, [props.btnList]);

  useEffect(() => {
    if (!props.disableClickOutside) {
      document.addEventListener('mousedown', detectOutsideClick);
      return () => {
        document.removeEventListener('mousedown', detectOutsideClick);
      };
    }
  }, []);

  useEffect(() => {
    if (props.readOnly) {
      const divElem = document.getElementById('popup-wrapper-container');
      const inputElements = divElem?.querySelectorAll('input');

      inputElements?.forEach((el) => (el.disabled = true));
    }
  }, []);

  const detectOutsideClick = (event: MouseEvent) => {
    if (
      (event.target as any)?.classList.contains(
        'popup-wrapper-top-level-container'
      )
    ) {
      if (props.clickAction) {
        const payload: PopupClickActionType = {
          type: POPUP_CLICK_TYPE.CLOSE_POPUP
        };
        props.clickAction(payload);
      }
    }
  };

  const onBtnClick = (btnType: POPUP_CLICK_TYPE) => {
    if (props.clickAction) {
      const payload: PopupClickActionType = {
        type: btnType
      };
      props.clickAction(payload);
    }
  };

  const getRenderLoadingBtnTitle = (value: any) => {
    return (
      <div className="flex">
        {value}
        <DKSpinner iconClassName="ic-s" className="ml-r" />
      </div>
    );
  };

  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={props.isLoading}
        className="border-m mr-r"
      />
    );
  };
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;

  return (
    <DynamicPopupWrapper>
      <div
        className={`transparent-background popup-wrapper-top-level-container fixed top-0 left-0 right-0 bottom-0 w-full h-screen flex flex-col items-center justify-center ${
          props.overflowVisible ? 'overflow-visible' : 'overflow-hidden'
        }`}
        style={{ backgroundColor: '#0000004d' }}
      >
        <div
          className={`popup-window relative flex flex-col w-10/12 h-9/12 rounded-lg bg-white dk-input-holder ${
            props.overflowVisible ? 'overflow-visible' : 'overflow-hidden'
          }`}
          style={{
            height: isFullScreenMode
              ? '100%'
              : props.height
              ? props.height
              : 'none',
            width: isFullScreenMode
              ? '100%'
              : props.width
              ? props.width
              : '85%',
            maxWidth: isFullScreenMode
              ? '100%'
              : props.maxWidth
              ? props.maxWidth
              : '1000px',
            maxHeight: isFullScreenMode
              ? '100%'
              : props.maxHeight
              ? props.maxHeight
              : '90%',
            minHeight: isFullScreenMode
              ? '100%'
              : props.minHeight
              ? props.minHeight
              : 'auto',
            minWidth: isFullScreenMode
              ? '100%'
              : props.minWidth
              ? props.minWidth
              : ''
          }}
        >
          <div className="w-full flex flex-row justify-between items-center p-s bg-gray1 rounded-t-lg">
            <div className="row parent-width pop-header-drag-handle">
              <div className="title fw-m text-sm">{popupState.title}</div>
              {(props.readOnly || props.readOnlyText) && (
                <div className=" fs-r text-align-left text-dark-gray bg-chip-orange text-orange border-radius-r ml-r p-h-s p-v-xs fw-m">
                  {props.readOnlyText || 'Read-Only'}
                </div>
              )}
            </div>
            <div
              className="flex flex-row"
              style={{
                display: popupState.buttonList.length > 0 ? 'flex' : 'none',
                position: props.position ? props.position : 'relative',
                bottom: props.bottom ? props.bottom : 'auto'
              }}
            >
              {props.allowFullScreen && getShrinkExpandButton()}

              {!props.isLoading &&
                popupState.buttonList &&
                popupState.buttonList.map((btn, index) => {
                  return (
                    <div className="row">
                      <div
                        key={index}
                        title={btn.title}
                        className={
                          'row border-radius-m text-wrap-none p-v-s dk-button-hover gap-2 ' +
                          (props.isActionBtnDisabled
                            ? 'border-m mr-s'
                            : btn.class)
                        }
                        style={{
                          width: 'auto',
                          paddingLeft: 12,
                          paddingRight: 12,
                          cursor: 'pointer',
                          userSelect: 'none',
                          height: 34
                        }}
                        onClick={() => {
                          if (!props.isActionBtnDisabled) {
                            onBtnClick(btn.clickAction);
                          }
                        }}
                      >
                        {btn.title}
                        {props.isActionBtnDisabled &&
                          index === popupState.buttonList.length - 1 && (
                            <DKSpinner iconClassName="ic-s" />
                          )}
                      </div>
                    </div>
                  );
                })}
              {props.isLoading &&
                popupState.loadingBtnList &&
                popupState.loadingBtnList.map((btn, index) => {
                  if (btn.type === 'CLOSE') {
                    return (
                      <DKButton
                        disabled={true}
                        title={btn.title}
                        className={'border-m mr-s text-gray'}
                        style={{ height: 32 }}
                        onClick={() => {}}
                      />
                    );
                  } else if (btn.type === 'ACTION') {
                    return (
                      <DKButton
                        disabled={true}
                        key={index}
                        title={getRenderLoadingBtnTitle(btn.title)}
                        className={'border-m mr-s text-gray'}
                        style={{ height: 32 }}
                        onClick={() => {}}
                      />
                    );
                  }

                  return null;
                })}
            </div>
            {popupState.type === POPUP_TYPE.DRAFT && (
              <div className="flex flex-row">
                <DKIcon src={ic_import} className="ic-s cursor-hand" />
                <DKIcon src={ic_close_2} className="ic-s cursor-hand mx-4" />
                <DKIcon src={ic_arrow_down} className="ic-s cursor-hand" />
              </div>
            )}
          </div>
          <div
            id="popup-wrapper-container"
            className={
              `relative w-full h-full p-2 ` +
              (props.showScrollBar ? '' : 'hide-scroll-bar')
            }
            style={{
              height: props.height ? '' : 'calc(100% - 10px)',
              overflowY: props.overflowY
                ? props.overflowY
                : props.overflowVisible
                ? 'visible'
                : 'auto',
              scrollbarWidth: props.scrollbarWidth,
              ...props.popupContainerStyles
            }}
          >
            <div
              style={{
                pointerEvents: props.readOnly ? 'none' : 'auto'
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default PopupWrapper;
