
import { getLocalisedText } from "../Translate/LanguageManager"

export default class FulfillmentMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('fulfillment')
        label.documentDate = getLocalisedText('fulfill_date')
        label.shipFrom = 'ship_from'
        label.shipFromPlaceholder = 'company_address_placeholder_text_value'
        label.shipTo = 'ship_to'
        label.shipToPlaceholder = 'customer_address_placeholder_text_value'
        label.requiredQuantity = 'required_qty'
        label.committedQuantity = 'committed_qty'
        label.warehouseCode = 'warehouse_code'
        label.warehouseName = 'warehouse_name'
        return label
    }
}