import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKCheckMark,
  DKLabel,
  DKButton,
  DKInput
} from 'deskera-ui-library';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized,
  getRandomNumber
} from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';

export const ILBankDetailsPopup = (props: any) => {
  //states

  const [branchNumber, setBranchNumber] = useState(
    props?.currentReason?.branchNumber
  );
  const [branchName, setBranchName] = useState(
    props?.currentReason?.branchName
  );
  const [branchAddress, setBranchAddress] = useState(
    props?.currentReason?.branchAddress
  );
  const [bankAccountNumber, setBankAccountNumber] = useState(
    props?.currentReason?.bankAccountNumber
  );
  const [bankCode, setBankCode] = useState(props?.currentReason?.bankCode);
  const [bankSwiftCode, setBankSwiftCode] = useState(
    props?.currentReason?.bankSwiftCode
  );
  const [bankName, setBankName] = useState(props?.currentReason?.bankName);
  const [bankIbanCode, setBankIbanCode] = useState(
    props?.currentReason?.bankIbanCode
  );

  const [isDetailsFormSubmitted, setIsDetailsFormSubmitted] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(
    !Utility.isEmpty(props?.currentReason) ? true : false
  );
  const [validateForm, setValidateForm] = useState(false);

  const onSaveClicked = () => {
    const payload = {
      branchAddress,
      branchName,
      branchNumber,
      bankName,
      bankCode,
      bankIbanCode,
      bankSwiftCode,
      bankAccountNumber
    };
    setIsDetailsFormSubmitted(true);
    if (Utility.isEmptyObject(branchName)) {
      setValidateForm(true);
      return;
    }
    if (!isFormEdit) {
      props.onSave(payload);
    } else {
      props.onEdit(payload, props?.currentReason?.index);
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '40%',
          // height: "85%",
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {/* header */}
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text={`${!isFormEdit ? 'Add' : 'Update'} Bank Details`}
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="bg-white border-m mr-r"
              onClick={() => {
                props.onCancel();
              }}
            />
            <DKButton
              title={!isFormEdit ? 'Save' : 'Update'}
              className="bg-button text-white"
              onClick={onSaveClicked}
            />
          </div>
        </div>
        {/* form */}
        <div className="parent-width p-l">
          <div className="parent-width row justify-between">
            {/* subtype name */}
            <DKInput
              title="Branch Name"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={branchName}
              className="mr-5"
              onChange={(val: any) => {
                setBranchName(val);
                setValidateForm(Utility.isEmptyObject(val));
              }}
              canValidate={validateForm}
            />
            <DKInput
              title="Branch Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={branchNumber}
              onChange={(val: any) => {
                setBranchNumber(val);
              }}
              canValidate={false}
              required={false}
            />
          </div>
          <div className="parent-width row justify-between mt-3">
            {/* subtype name */}
            <DKInput
              title="Branch Address"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              required={false}
              value={branchAddress}
              className="mr-5"
              onChange={(val: any) => {
                setBranchAddress(val);
              }}
            />
            <DKInput
              title="Bank Account Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankAccountNumber}
              className=""
              onChange={(val: any) => {
                setBankAccountNumber(val);
              }}
              required={false}
            />
          </div>
          <div className="parent-width row justify-between mt-3">
            {/* subtype name */}
            <DKInput
              title="Bank Code"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankCode}
              className="mr-5"
              onChange={(val: any) => {
                setBankCode(val);
              }}
              required={false}
            />
            <DKInput
              title="Bank Swift Code"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankSwiftCode}
              className=""
              onChange={(val: any) => {
                setBankSwiftCode(val);
              }}
              required={false}
            />
          </div>
          <div className="parent-width row justify-between mt-3">
            {/* subtype name */}
            <DKInput
              title="Bank Name"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankName}
              className="mr-5"
              onChange={(val: any) => {
                setBankName(val);
              }}
              required={false}
            />
            <DKInput
              title="Bank IBAN Code"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankIbanCode}
              className=""
              onChange={(val: any) => {
                setBankIbanCode(val);
              }}
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
