export const ENV = {
  DEV: 'DEV',
  QA: 'QA',
  QA2: 'QA2',
  STAGING: 'STAGING',
  PROD: 'PROD'
};
export const PRODUCTS = {
  AIO: 'aio',
  ERP: 'erp',
  CRM: 'crm',
  CRM_PLUS: 'crm_plus',
  PEOPLE: 'people',
  WEBSITE_BUILDER: 'website builder',
  SHOP: 'shop',
  REPORT_BUILDER: 'report_builder',
  DOCUMENT_BUILDER: 'document builder',
  DOCUMENT_SCANNER: 'document scanner',
  ORGANISATION: 'organisation',
  CONSOLE: 'console',
  WORKFLOW_ENGINE: 'workflow_engine',
  ATTENDANCE: 'attendance',
  EXPENSE: 'expense',
  CHAT: 'chat',
  EM: 'em', //Expense
  DS: 'ds', //Shop
  EL: 'el' //Leave Management
};
export const PNL: { [key: string]: string } = {
  expenses: 'totalExpenses',
  income: 'totalIncome',
  otherExpenses: 'totalOtherExpenses',
  otherIncome: 'totalOtherIncome',
  costOfGoodsSold: 'totalCostOfGoodsSold',
  revenue: 'totalRevenue',
  cogs: 'totalCogs',
  operatingExpenses: 'totalOperatingExpenses'
};

export const OPENING_DOCS = {
  CREDIT_NOTE: 'Credit Note',
  DEBIT_NOTE: 'Debit Note',
  INVOICE: 'Sales Invoice',
  BILL: 'Purchase Invoice',
  PREPAYMENT: 'Prepayment',
  ADVANCE_PAYMENT: 'Advance Payment'
};

export const RECONCILE_BANK: { [key: string]: string } = {
  bankBookDeskera: 'Book Balance in Deskera',
  bookBalanceAfterReconcile: 'Book Balance in Deskera after Reconciliation',
  currentStatementBalance: 'Current Bank Statement Balance',
  unReconciledDeposits: 'Less: Unreconciled Deposits',
  unReconciledWithdrawal: 'Add: Unreconciled Withdrawals',
  difference: 'Difference'
};

export const BALANCE_SHEET_TOTAL: { [key: string]: string } = {
  bank: 'totalBank',
  cash: 'totalCash',
  currentAsset: 'totalCurrentAsset',
  nonCurrentAsset: 'totalNonCurrentAsset',
  otherCurrentAssets: 'totalOtherCurrentAssets',
  equity: 'totalEquity',
  currentLiabilities: 'totalCurrentLiabilities',
  nonCurrentLiabilities: 'totalNonCurrentLiabilities',
  otherCurrentLiabilities: 'totalOtherCurrentLiabilities',
  longTermAssets: 'totalLongTermAssets',
  fixedAssets: 'totalFixedAssets', //
  accountsReceivable: 'totalAccountsReceivable',
  otherAssets: 'totalOtherAssets', //
  ppe: 'totalPpe', //
  intangibles: 'totalIntangibles', //
  otherLongTermAsset: 'totalOtherLongTermAsset', //
  accountsPayable: 'totalAccountsPayable',
  longTermLiabilities: 'totalLongTermLiabilities'
};
export const PNL2: { [key: string]: string } = {
  totalCostOfGoodsSold: 'costOfGoodsSold',
  totalExpenses: 'expenses',
  totalIncome: 'income',
  totalOtherExpenses: 'otherExpenses',
  totalOtherIncome: 'otherIncome',
  totalRevenue: 'revenue',
  totalCogs: 'cogs',
  totalOperatingExpenses: 'operatingExpenses'
};
export const PNLMONTH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const PNL3: any = {
  grossProfit: true,
  incomeTaxExpenses: true,
  netProfitOrLoss: true,
  operatingProfit: true,
  profitBeforeTax: true
};

export const BALANCE_SHEET_LABEL: any = {
  assets: 'assets',
  liabilities: 'liabilities'
};
export const BALANCE_SHEET_STYLE: any = {
  liabilities: 'liabilities'
};

export const BALANCE_SHEET1: any = {
  totalLiabilities: 'totalLiabilities',
  totalAsset: 'totalAsset'
};
export const BALANCE_SHEET2: any = {
  equity: 'equity',
  bank: 'bank',
  cash: 'cash',
  assets: 'assets',
  liabilities: 'liabilities',
  currentAsset: 'currentAsset',
  nonCurrentAsset: 'nonCurrentAsset',
  otherCurrentAssets: 'otherCurrentAssets',
  totalAsset: 'totalAsset',
  currentLiabilities: 'currentLiabilities',
  nonCurrentLiabilities: 'nonCurrentLiabilities',
  otherCurrentLiabilities: 'otherCurrentLiabilities',
  longTermLiabilities: 'longTermLiabilities',
  diffOpeningBalanceAsset: 'diffOpeningBalance',
  diffOpeningBalanceLiability: 'diffOpeningBalance',
  totalLiabilities: 'totalLiabilities',
  longTermAssets: 'longTermAssets',
  accountsReceivable: 'accountsReceivable',
  fixedAssets: 'fixedAssets',
  otherAssets: 'otherAsset',
  ppe: 'ppe',
  intangibles: 'intangibles', //
  otherLongTermAsset: 'otherLongTermAsset',
  accountsPayable: 'accountsPayable'
};
export const BALANCE_SHEET3: any = {
  currentAsset: true,
  currentLiabilities: true,
  equity: true
};
export const TRIAL_BALANCE1: any = {
  totalDebitAmount: true,
  totalCreditAmount: true,
  diffOpeningBalance: true
};
export const PRODUCTS_CAPS = {
  ERP: 'ERP'
};

export const PLANS = {
  STARTUP: 'startup',
  ESSENTIAL: 'essential',
  PROFESSIONAL: 'professional'
};

export const INTERVALS = {
  YEARLY: 'yearly',
  MONTHLY: 'monthly'
};

export const COA_TYPE = {
  BANK: 'Bank',
  CASH: 'Cash'
};
export const ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify'
};

export const FORM_TYPE = {
  BOOK_A_DEMO: 'BOOK_A_DEMO',
  SIGN_UP_LEAD_GENERATION: 'SIGN_UP_LEAD_GENERATION',
  CONTACT_US: 'CONTACT_US'
};

export const API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_201: 201
};

export const USER_ROLE = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  MEMBER: 'User'
};
export const USER_TYPE = {
  GUEST: 'Guest',
  PAID: 'Paid'
};
export const USER_STATUS = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
  PENDING: 'Pending'
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: 'In trial',
  TRIAL_PENDING: 'Trial available',
  EXPIRED: 'Expired',
  ACTIVE: 'Active',
  TRIAL_ENDED: 'Trial over',
  CANCELLED: 'Cancelled'
};

export const ACTION_KEY = {
  DELETE_COMAPANY: 'delete_company',
  RESET_COMPANY: 'reset_company'
};

export const DELETE_OPTIONS = [
  {
    label: "It's too costly",
    value: "It's too costly"
  },
  {
    label: 'I found another product that fulfills my needs.',
    value: 'I found another product that fulfills my needs.'
  },
  {
    label: "I don't use it enough.",
    value: "I don't use it enough."
  },
  {
    label: 'Some features I need are missing',
    value: 'Some features I need are missing'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];

export const PASSWORD_OBJ = {
  OLD_PASSWORD: 'old password',
  NEW_PASSWORD: 'new password',
  CONFIRM_NEW_PASSWORD: 'confirm new password'
};

export const PASSWORD_SETTING = {
  SHOW: 'show',
  HIDE: 'hide'
};

export const PASSWORD_ERROR = {
  ERROR_API: 'error: api',
  ERROR_BLANK: 'error: blank',
  ERROR_NOT_MATCHED: 'error: mismatched',
  ERROR_NOT_MATCHED_FIELD: 'error: mismatched other field',
  ERROR_LENGTH: 'error: length invalid'
};

export const PLAN_UPDATE_TYPE = {
  TRIAL_START: 'TrialStart',
  PLAN_START: 'PlanStart',
  UPGRADE: 'Upgrade',
  DOWNGRADE: 'Downgrade'
};
export const INVOICE_STATUS = {
  PAID: 'paid',
  FAILED: 'failed'
};

export const CODE_TYPE = {
  PREPAID_CODE: 'prepaid',
  PROMO_CODE: 'promo / discount',
  PLAN_CODE: 'plan',
  ADDON_CODE: 'addon',
  COUPON_CODE: 'coupon'
};

export const WEBSITE_TYPE = {
  HELP_DESK: 'helpdesk',
  BUILDER: 'builder'
};

export const CHART_TYPE = {
  BAR_VERTICAL: 'Bar Vertical Graph',
  MULTI_BAR_VERTICAL: 'Multi Vertical Bar Graph',
  BAR_HORIZONTAL: 'Bar Horizontal Graph',
  MULTI_BAR_HORIZONTAL: 'Multi Bar Horizontal Graph',
  LINE: 'Line Chart',
  PIE: 'Pie Chart',
  DONUT: 'DONUT'
};

export const CHART_API_SOURCES = {
  INVOICES: 'INVOICES',
  AGE_RECEIVABLE: 'AGE_RECEIVABLE',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  WON_AND_LOST_DEALS: 'WON_AND_LOST_DEALS',
  PIPELINES: 'PIPELINES',
  CONTACTS: 'CONTACTS',
  EXPENSES_CLAIMED: 'EXPENSES_CLAIMED',
  PAYROLL: 'PAYROLL',
  LEAVES_PER_MONTH: 'LEAVES_PER_MONTH'
};
export const VISIBILITY_TYPE = {
  VISIBLITY_1: 'Private',
  VISIBLITY_2: 'Team',
  VISIBLITY_3: 'Everyone'
};
export const DEAL_STATUS_CODE = {
  DEFAULT: 0,
  WON: 1,
  LOST: 2
};
export const DEAL_VIEW = {
  KANBAN: 'Kanban',
  GRID: 'Grid'
};
export const CURRENCY = {
  ZWD: '$',
  ZMW: 'ZMW',
  ZMK: 'ZK',
  ZAR: 'R',
  YER: 'YE﷼"',
  XPF: '₣',
  XOF: 'CFA',
  XCD: 'EC$',
  XAF: 'FCFA',
  WST: 'WS$',
  VUV: 'VT',
  VND: '₫',
  VEF: 'Bs',
  UZS: 'UZлв',
  UYU: '$U',
  USD: '$',
  UGX: 'USh',
  UAH: '₴',
  TZS: 'TSh',
  TWD: 'NT$',
  TTD: 'TT$',
  TRY: '₺',
  TOP: 'T$',
  TND: 'د.ت',
  TMT: 'T',
  TMM: 'T',
  TJS: 'ЅM',
  THB: '฿',
  SZL: 'E',
  SYP: '£S',
  STD: 'Db',
  SSP: 'SS£',
  SRD: 'Sr$',
  SOS: 'S',
  SLL: 'Le',
  SKK: 'Sk',
  SHP: 'SH£',
  SGD: 'S$',
  SEK: 'Skr',
  SDG: 'SD',
  SCR: 'SC₨',
  SBD: 'Si$',
  SAR: 'SA﷼',
  RWF: 'FRw',
  RUB: '₽',
  RSD: 'Дин.',
  RON: 'lei',
  QAR: '﷼QA﷼',
  PYG: '₲',
  PLN: 'zł',
  PKR: 'PKRs',
  PHP: '₱',
  PGK: 'PGK',
  PEN: 'S/',
  PAB: 'B/.',
  OMR: 'OM﷼',
  NZD: 'NZ$',
  NPR: 'NPRs',
  NOK: 'Nkr',
  NIO: 'C$',
  NGN: '₦',
  NAD: 'N$',
  MZN: 'MT',
  MYR: 'RM',
  MXN: 'MX$',
  MWK: 'MK',
  MVR: 'Rf',
  MUR: 'MU₨',
  MTL: '₤',
  MRO: 'UM',
  MOP: 'MOP$',
  MNT: '₮',
  MMK: 'MK',
  MKD: 'ден',
  MGA: 'Ar',
  MDL: 'ML',
  MAD: 'DH',
  LYD: 'ل.د',
  LVL: 'Ls',
  LTL: 'Lt',
  LSL: 'L',
  LRD: 'LD$',
  LKR: 'LKRs',
  LBP: 'LB£',
  LAK: '₭',
  KZT: '₸',
  KYD: 'CI$',
  KWD: 'د.ك',
  KRW: 'KR₩',
  KPW: 'KP₩',
  KMF: 'CF',
  KHR: '៛',
  KGS: 'KGлв',
  KES: 'KSh,',
  JPY: 'JP¥',
  JOD: 'د.ا',
  JMD: 'J$',
  ISK: 'Ikr',
  IRR: 'IR﷼',
  IQD: 'ع.د',
  INR: '₹',
  ILS: '₪',
  IDR: 'Rp',
  HUF: 'Ft',
  HTG: 'G',
  HRK: 'kn',
  HNL: 'HL',
  HKD: 'H$',
  GYD: 'GY$',
  GTQ: 'Q',
  GNF: 'FG',
  GMD: 'D',
  GIP: 'GI£',
  GHS: '₵',
  GHC: 'GH₵',
  GEL: 'ლ',
  GBP: 'GB£',
  FKP: 'FK£',
  FJD: 'FJ$',
  EUR: '€',
  ETB: 'ብር',
  ERN: 'ናቕፋ',
  EGP: 'E£',
  EEK: 'EEK',
  DZD: 'دج',
  DOP: 'RD$',
  DKK: 'kr.',
  DJF: 'Fdj',
  CZK: 'Kč',
  CYP: '£',
  CVE: 'CV$',
  CUC: 'CUC$',
  CRC: '₡',
  COP: 'Col$',
  CNY: 'CN¥',
  CLP: 'CL$',
  CHF: 'CHF',
  CDF: 'FC',
  CAD: 'CA$',
  BZD: 'BZ$',
  BYR: 'Br',
  BWP: 'P',
  BTN: 'Nu.',
  BSD: 'BS$',
  BRL: 'R$',
  BOB: 'b$',
  BND: 'BN$',
  BMD: 'BD$',
  BIF: 'FBu',
  BHD: '.د.ب',
  BGN: 'BGлв',
  BDT: '৳',
  BBD: 'BBD$',
  BAM: 'KM',
  AZN: '₼',
  AWG: 'Afl',
  AUD: 'A$',
  ARS: 'Arg$',
  AOA: 'Kz',
  ANG: 'Naf',
  AMD: 'դր',
  ALL: 'Lek',
  AFN: '؋',
  AED: 'د.إ'
};
export const VISIBILITY = [
  { name: 'Private', id: 1 },
  { name: 'Team', id: 2 },
  { name: 'Everyone', id: 3 }
];
export const CONTACT_TYPE = {
  PERSON: 1,
  ORGANIZATION: 2
};

export const TRANSACTION_TYPE = {
  CREDIT: 'credit',
  DEBIT: 'debit'
};

export enum INVOICE_REMINDER_INPUT_TYPE {
  NUM_DAYS = 'numDays',
  REPEATINTERVAL = 'repeatInterval',
  REPEATTIMES = 'repeatTimes'
}

export const MORE_ACTION_NAMES = {
  EDIT: 'Edit',
  DELETE: 'Delete',
  PRINT: 'Print',
  DESIGN: 'Design',
  SET_RECURRING: 'Set Recurring',
  VIEW_CUSTOM_FIELDS: 'View Custom Fields',
  VIEW_JOURNAL_ENTRY: 'Show GL Impact'
};

export enum NUMBER_FORMAT {
  US = 'us',
  IN = 'in',
  FR = 'fr',
  ES = 'es'
}

export enum TOGGLE_SIZE {
  LARGE = 'large',
  SMALL = 'small'
}

export enum DASHBOARD_PERIODS {
  SIX_MONTHS = 'SIX_MONTHS',
  FOUR_WEEKS = 'FOUR_WEEKS',
  THREE_MONTHS = 'THREE_MONTHS'
}

export enum PRODUCT_DASHBOARD_GRAPHS {
  SALES_GROWTH = 'salesGrowth',
  STOCK_VALUE = 'stockValue',
  SALES_PURCHASE = 'salesPurchase',
  STOCK_QUANITITY = 'stockQuantity',
  TOP_CUSTOMER = 'topCustomer',
  TOP_SUPPLIER = 'topSupplier',
  PURCHASE = 'purchase',
  SALES = 'sales',
  INVOICES = 'invoice'
}

export enum EXPORTED_FILES_TYPE_EXTENSION {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
  PDF = 'PDF'
}

export enum FINANCIAL_REPORT {
  PNL = 'PNL',
  BALANCE_SHEET = 'BALANCE_SHEET',
  TRIAL_BALANCE = 'TRIAL_BALANCE'
}
export enum NON_FINANCIAL_REPORT {
  CREDIT_NOTE = 'CREDIT_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  STOCKMOVEMENT = 'STOCKMOVEMENT',
  GSTR_ONE = 'GSTRONE',
  GSTR_2B = 'GSTR2B',
  SALES_BY_PRODUCT = 'salesByProd',
  STOCK_AGEING = 'stockAgeing',
  BATCH_STATUS = 'batchStatus',
  TDS_CUSTOMER = 'tdsCustomer',
  INVOICE_LIST = 'invoiceList',
  TDS_DETAILS = 'tdsDetails',
  TDS_NOT_DEDUCTED = 'tdsNotDeducted',
  SALES_TAX_RETURN = 'salesTaxReturn',
  SERVICE_TAX_RETURN = 'serviceTaxReturn',
  STOCKWAREHOUSE = 'stockWareHouse',
  BATCH_SERIAL_LEDGER_REPORT = 'batchSerialLedger',
  AGED_RECEIVABLES = 'AgedReceivables',
  REV_REC_JE = 'RevRecJE',
  AGED_PAYABLES = 'AgedPayables',
  ORDER_LEVELS_REPORT = 'ORDER_LEVELS_REPORT',
  STOCK_STATUS_REPORT = 'STOCK_STATUS_REPORT'
}

export enum DEDUCTEE_FIELD_NAME {
  DEDUCTEE_TYPE = 'DEDUCTEE_TYPE',
  DEDUCTEE_CODE = 'DEDUCTEE_CODE'
}

export enum REPORT_BASIS {
  ACCRUAL = 'ACCRUAL',
  CASH = 'CASH'
}

export enum GST_BUSINESS_TYPE {
  SEZ_OVERSEAS_TRADING = 'sezOverseaTrading',
  DIGITAL_SERVICES = 'digitalServices',
  REVERSE_CHARGE = 'reverseCharge',
  IS_COMPOSITE_SCHEME = 'isCompositeScheme',
  NONE = 'none'
}

export enum COMPOSITE_SCHEME_OPTION {
  ONE_PERCENT = 'ONE_PERCENT',
  TWO_PERCENT = 'TWO_PERCENT',
  FIVE_PERCENT = 'FIVE_PERCENT',
  SIX_PERCENT = 'SIX_PERCENT',
  NONE = 'none'
}

export enum IRP_POPUP_FIELD_NAME {
  USERNAME = 'USERNAME',
  PASSWORD = 'PASSWORD'
}

export enum COMPLIANCE_SPECIFIC_FIELD_NAME {
  CONTACT = 'CONTACT',
  PRODUCT = 'PRODUCT',
  INVOICE = 'INVOICE',
  BILL = 'BILL',
  QUOTATION = 'QUOTATION',
  PURCHASE_ORDER = 'PURCHASE ORDER',
  REQUISITION = 'REQUISITION',
  PATCH_TENANT_SETTINGS = 'PATCH_TENANT_SETTINGS',
  FETCH_TENANT_SETTINGS = 'FETCH_TENANT_SETTINGS',
  QUOTE = 'QUOTE',
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  BULK_MAKE_PAYMENT = 'BULK_MAKE_PAYMENT',
  BULK_RECEIVE_PAYMENT = 'BULK_RECEIVE_PAYMENT',
  SALES_ORDER = 'SALES_ORDER',
  JOB_WORK_OUT = 'JOB_WORK_OUT',
  QUALITY_CHECK = 'QUALITY_CHECK'
}

export enum ProductDashboardTabs {
  DASHBOARD = 'dashboard',
  VARIANTS = 'variants',
  INVENTORY = 'inventory'
}

export enum DATE_COMPARISON {
  BOTH_DATES_ARE_EQUAL = 'bothDatesAreEqual',
  LEFT_DATE_IS_BEFORE_RIGHT_DATE = 'leftDateIsBeforeRightDate',
  LEFT_DATE_IS_AFTER_RIGHT_DATE = 'leftDateIsAfterRightDate'
}

export enum CONTACT_TRANSACTION_TYPE {
  QUOTE = `New Quote`,
  ORDER = 'New Order',
  INVOICE = 'New Invoice',
  BILL = 'New Bill',
  ESTIMATE = `New Estimate`,
  SALES_ORDER = `New Sales Order`
}

export const AUTHORIZATION_STATUS = {
  DENIED: 'DENIED',
  AUTHORIZED: 'AUTHORIZED',
  PENDING: 'PENDING'
};

export enum CREDIT_LIMIT_TYPE {
  IGNORE = 'IGNORE',
  WARN = 'WARN',
  BLOCK = 'BLOCK',
  INVOICE = 'INVOICE',
  QUOTE = 'QUOTE',
  SALES_ORDER = 'SALES_ORDER',
  CREDIT_LIMIT_INFO = 'CREDIT_LIMIT_INFO'
}

export enum CREDIT_LIMIT_DOC_TYPE {
  INVOICE = 'INVOICE',
  QUOTE = 'QUOTE',
  SALES_ORDER = 'SALES_ORDER'
}

export enum REPORTS_TAB {
  STANDARD = 'standard',
  CUSTOM = 'custom'
}

export enum PRICE_LIMIT_CONTACT_FORM {
  PURCHASE_LIST = 'PURCHASE_LIST',
  SALES_LIST = 'SALES_LIST'
}

export enum REPORT_TYPE {
  PROFIT_AND_LOSS_BY_YEAR = 'PROFIT_AND_LOSS_BY_YEAR',
  PROFIT_AND_LOSS_BY_MONTH = 'PROFIT_AND_LOSS_BY_MONTH',
  PROFIT_AND_LOSS_BY_QUARTER = 'PROFIT_AND_LOSS_BY_QUARTER',
  BALANCE_SHEET_BY_YEAR = 'BALANCE_SHEET_BY_YEAR',
  BALANCE_SHEET_BY_MONTH = 'BALANCE_SHEET_BY_MONTH',
  BALANCE_SHEET_BY_QUARTER = 'BALANCE_SHEET_BY_QUARTER',
  NONE = 'NONE'
}

export enum REPORT_FILTER_LIST {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
  CLASS = 'CLASS',
  PRODUCT = 'PRODUCT',
  ACCOUNT = 'ACCOUNT'
}

export enum ACCOUNTING_METHOD {
  ACCRUAL = 'ACCRUAL',
  CASH = 'CASH'
}

export enum CONSOLIDATION_MODE {
  CONSOLIDATION = 'CONSOLIDATION',
  COMPARISON = 'COMPARISON'
}

export enum CONTACT_FORM_TAB {
  GENERAL_INFO = 'GENERAL_INFO',
  COMPLIANCE = 'COMPLIANCE',
  ADVANCED = 'ADVANCED',
  PAYMENT_INFO = 'PAYMENT_INFO',
  ADDRESS_INFO = 'ADDRESS_INFO',
  NONE = 'NONE'
}

export enum ORGANISATION_FORM_TAB {
  GENERAL_INFO = 'GENERAL_INFO',
  COMPLIANCE = 'COMPLIANCE',
  CATEGORIES = 'CATEGORIES',
  ADDRESS_INFO = 'ADDRESS_INFO',
  NONE = 'NONE'
}
export const ALLOCATION_TYPE: any = {
  QUANTITY: 'Quantity',
  VALUE: 'Value',
  WEIGHT: 'Weight',
  MANUAL: 'Manual',
  CUSTOM_DUTY: 'Custom Duty'
};

export enum RETURN_MODE {
  SALE = 'SALE',
  PURCHASE = 'PURCHASE'
}
export enum RETURN_QUANTITY_TYPE {
  'FULL' = 'FULL',
  'PARTIAL' = 'PARTIAL'
}
export enum RETURN_TYPE {
  RETURN_ONLY = 'RETURN_ONLY',
  RETURN_AND_REFUND = 'RETURN_AND_REFUND',
  REPLACEMENT = 'REPLACEMENT'
}

export enum PRODUCT_OFFERING_TYPE {
  GOODS = 'GOODS',
  SERVICES = 'SERVICES'
}

export enum ADVANCE_TRACKING {
  SERIAL = 'SERIAL',
  BATCH = 'BATCH',
  NONE = 'NONE'
}

export enum DOC_URL_ACTION {
  CONVERT_TO_INVOICE = 'convert_to_invoice'
}

export enum ACCOUNT_GROUPS {
  EXPENSES = 'Expenses',
  INCOME = 'Income',
  OTHER_INCOME = 'Other Income',
  OTHER_EXPENSES = 'Other Expenses',
  COST_OF_GOODS_SOLD = 'Cost of Goods Sold',
  OPERATING_EXPENSES = 'Operating Expenses',
  CURRENT_ASSETS = 'Current Assets',
  OTHER_ASSETS = 'Other Assets',
  OTHER_CURRENT_ASSETS = 'Other Current Assets',
  REVENUE = 'Revenue'
}

export enum IMPORT_LOG_TABS {
  IMPORT_LOG = 'IMPORT_LOG',
  IMPORT_DRAFT = 'IMPORT_DRAFT'
}
export const ADDITIONAL_CHARGE_INCOME_GROUPS = [
  ACCOUNT_GROUPS.INCOME,
  ACCOUNT_GROUPS.OTHER_INCOME,
  ACCOUNT_GROUPS.CURRENT_ASSETS,
  ACCOUNT_GROUPS.OTHER_ASSETS,
  ACCOUNT_GROUPS.OTHER_CURRENT_ASSETS,
  ACCOUNT_GROUPS.REVENUE,
  ACCOUNT_GROUPS.OTHER_INCOME
];

export const ADDITIONAL_CHARGE_EXPENSE_GROUPS = [
  ACCOUNT_GROUPS.EXPENSES,
  ACCOUNT_GROUPS.COST_OF_GOODS_SOLD,
  ACCOUNT_GROUPS.OPERATING_EXPENSES,
  ACCOUNT_GROUPS.OTHER_EXPENSES
];

export enum CASCADING_DISCOUNT_TYPE {
  CASCADE = 'ITEM_DISCOUNT_CASCADE',
  SUBTOTAL = 'ITEM_DISCOUNT_SUBTOTAL'
}

export enum ADDITIONAL_CHARGES_APPLICABLE_ON {
  TOTAL = 'TOTAL',
  SUBTOTAL = 'SUBTOTAL'
}

export enum AUTOMATION_LOG_NODE_TYPE {
  BOOKS_CREATE_INVOICE = 'BOOKS_CREATE_INVOICE',
  WAIT_FOR_TIME = 'WAIT_FOR_TIME',
  SEND_WHATSAPP_MESSAGE = 'SEND_WHATSAPP_MESSAGE',
  END_WORKFLOW = 'END_WORKFLOW',
  BOOKS_PLUS_APPROVAL = 'BOOKS_PLUS_APPROVAL',
  BOOKS_CREATE_QUOTATION = 'BOOKS_CREATE_QUOTATION',
  BOOKS_CREATE_PURCHASE_REQUISITION = 'BOOKS_CREATE_PURCHASE_REQUISITION',
  BOOKS_CREATE_PURCHASE_INVOICE = 'BOOKS_CREATE_PURCHASE_INVOICE',
  BOOKS_CREATE_ORDER = 'BOOKS_CREATE_ORDER',
  BOOKS_UPDATED_INVOICE = 'BOOKS_UPDATED_INVOICE',
  BOOKS_PLUS_SEND_EMAIL = 'BOOKS_PLUS_SEND_EMAIL',
  BOOKS_SALES_ORDER_CREATE = 'BOOKS_SALES_ORDER_CREATE',
  SALES_ORDER_TRIGGER = 'SALES_ORDER_TRIGGER',
  BOOKS_UPDATED_INVOICE_FULFILLMENT = 'BOOKS_UPDATED_INVOICE_FULFILLMENT',
  BOOKS_UPDATED_PURCHASE_INVOICE = 'BOOKS_UPDATED_PURCHASE_INVOICE',
  BOOKS_UPDATE_QUOTATION = 'BOOKS_UPDATE_QUOTATION',
  BOOKS_UPDATE_PRODUCT = 'BOOKS_UPDATE_PRODUCT',
  BOOKS_UPDATED_PURCHASE_ORDER = 'BOOKS_UPDATED_PURCHASE_ORDER'
}

export enum AUTOMATION_ACTIONS_TITLE {
  BOOKS_CREATE_INVOICE = 'Create a sales invoice',
  BOOKS_CREATE_QUOTATION = 'Create a quotation',
  BOOKS_CREATE_PURCHASE_REQUISITION = 'Create a requisition',
  BOOKS_CREATE_PURCHASE_INVOICE = 'Create a purchase invoice',
  BOOKS_CREATE_ORDER = 'Create a order',
  BOOKS_UPDATED_INVOICE_PAYMENT = 'Payment updated',
  BOOKS_PLUS_APPROVAL = 'Send for approval',
  BOOKS_PLUS_SEND_EMAIL = 'Send an E-mail',
  SALES_ORDER_TRIGGER = 'Sales Order created',
  BOOKS_UPDATED_INVOICE = 'Invoice updated',
  BOOKS_UPDATED_PURCHASE_INVOICE = 'Purchase Invoice updated',
  BOOKS_UPDATE_QUOTATION = 'Quotation updated',
  BOOKS_UPDATE_PRODUCT = 'Reorder Level Reached',
  BOOKS_UPDATED_PURCHASE_ORDER = 'Purchase order updated',
  BOOKS_SALES_ORDER_CREATE = 'Sales Order created',
  SEND_WHATSAPP_MESSAGE = 'Send WhatsApp Message'
}

export enum AUTOMATION_TRIGGER_TITLE {
  BOOKS_CREATE_INVOICE = 'Sales Invoice created',
  BOOKS_SALES_ORDER_CREATE = 'Sales Order created',
  BOOKS_PLUS_APPROVAL = 'Approval Process Started',
  BOOKS_CREATE_QUOTATION = 'Quotation created',
  BOOKS_CREATE_PURCHASE_REQUISITION = 'Requisition created',
  BOOKS_CREATE_PURCHASE_INVOICE = 'Purchase Invoice created',
  BOOKS_CREATE_ORDER = 'Purchase Order created',
  BOOKS_UPDATED_INVOICE = 'Invoice updated',
  SALES_ORDER_TRIGGER = 'Sales order created',
  BOOKS_UPDATED_PURCHASE_INVOICE_GR = 'Purchase Invoice Good Receipt updated',
  BOOKS_UPDATED_PURCHASE_INVOICE_PAYMENT = 'Purchase Invoice Payment updated',
  BOOKS_UPDATED_INVOICE_PAYMENT = 'Invoice Payment updated',
  BOOKS_UPDATED_INVOICE_FULFILLMENT = 'Invoice Fulfillment updated',
  BOOKS_UPDATE_QUOTATION = 'Quotation updated',
  BOOKS_UPDATE_PRODUCT = 'Reorder Level Reached',
  BOOKS_UPDATED_PURCHASE_ORDER = 'Purchase order updated',
  BOOKS_REJECT_QUOTATION = 'Quotation rejected'
}

export enum DATE_RNGE_FILTER_OPTION {
  ALL_DATA = 'All Data',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  THIS_QUARTER = 'This Quarter',
  THIS_YEAR = 'This Year',
  CUSTOM_DATE = 'Custom Date'
}

export enum TRACKING_DETAILS {
  PASSED = 'Passed',
  REJECTED = 'Rejected',
  EMPTY = ''
}

export enum SETTING_TYPE {
  NONE = 'NONE',
  FORWARD_ATTACHMENTS_SETTING = 'FORWARD_ATTACHMENTS_SETTING',
  INVENTORY_APPROVAL_SETTING = 'INVENTORY_APPROVAL_SETTING',
  LOCK_DOCUMENT_AFTER_APPROVAL = 'LOCK_DOCUMENT_AFTER_APPROVAL',
}

export enum PAYMENT_INFO_TYPE {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY'
}

export enum BANK_ACCOUNT_TYPE {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS'
}

export enum PAYMENT_FILE_FORMAT {
  EFT = 'EFT',
  NONE = 'NONE'
}

export enum PAYMENT_LOG_TAB {
  PAYMENT_LOG = 'PAYMENT_LOG',
  UPLOAD_TO_FTP_LOG = 'UPLOAD_TO_FTP_LOG',
}