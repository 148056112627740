import ApiConstants from '../Constants/ApiConstants';
import http from '../Services/Interceptor';
import ITable, { IColumn } from '../Models/Table';
import { DraftApprovalPayload } from '../Models/Drafts';
class TableService {
  static getAllViews(): Promise<ITable[]> {
    return http.get(ApiConstants.URL.TABLE.GET_ALL).then(
      (res: any) => {
        return Promise.resolve(res.data);
      },
      (err: any) => {
        return Promise.reject(err);
      }
    );
  }
  static getTable(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET(tableId)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static addColumn(column: IColumn, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_COLUMN(tableId)}`, column)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getRecords(tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD(tableId)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getRecordsByPage(params: any, data: any, tableId: string) {
    if (!params) params = { pageNo: 1, pageSize: 20 };
    return http
      .post(`${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(tableId)}`, data, {
        params: { ...params, _allowRetry: true }
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static addRecord(record: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_RECORD(tableId)}`, record)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateRecord(data: any, recordId: string, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_RECORD(recordId, tableId)}`, {
        cells: data
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getRecordById(recordId: string, tableId: string) {
    return http
      .get(`${ApiConstants.URL.TABLE.GET_RECORD_BY_ID(recordId, tableId)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static addBulkRecord(record: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.ADD_BULK_RECORD(tableId)}`, record)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateBulkRecord(record: any, tableId: string) {
    return http
      .put(`${ApiConstants.URL.TABLE.UPDATE_BULK_RECORD(tableId)}`, record)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteRecord(recordId: string, tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_RECORD(recordId, tableId)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteRecordInBulk(records: string[], tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_BULK_RECORD(tableId)}`, {
        data: records
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteColumn(columnId: string, tableId: string) {
    return http
      .delete(`${ApiConstants.URL.TABLE.DELETE_COLUMN(columnId, tableId)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateColumn(data: IColumn, tableId: string) {
    return http
      .put(
        `${ApiConstants.URL.TABLE.UPDATE_COLUMN(data.id as string, tableId)}`,
        data
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static shiftColumn(data: any, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.SHIFT_COLUMN(tableId)}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  /**
   *
   * @param {String} tableId - parent Table Unique ID(*)
   * @param {String} tableName - Parent Table Name(optional)
   * @param {String} viewName - View Name(*)
   * @returns
   */
  // static createView(params: any) {
  //   return http
  //     .post(`${ApiConstants.URL.TABLE.ADD_VIEW}`, params)
  //     .then(
  //       (response: any) => {
  //         return Promise.resolve(response);
  //       },
  //       (error: any) => {
  //         return Promise.reject(error);
  //       }
  //     )
  //     .catch((error: any) => {
  //       return Promise.reject(error);
  //     });
  // }

  static updateApprovalStatus(data: DraftApprovalPayload, tableId: string) {
    return http
      .post(`${ApiConstants.URL.TABLE.UPDATE_APPROVAL_STATUS(tableId)}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default TableService;
