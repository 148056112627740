import ApiConstants from '../Constants/ApiConstants';
import { RecurringDocumentPayload } from '../Models/RecurringDocument';
import { HsnSacPayLoad } from '../SharedComponents/FormUtil/AddHsnSac';
import { NatureIncomePaymentPayLoad } from '../SharedComponents/FormUtil/AddNatureIncomePayment';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface GenericAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  apiPath?: string;
}

const defaultConfig: GenericAPIConfig = {
  SearchTerm: '',
  Limit: 10,
  Page: 0,
  apiPath: ''
};
class GenericService {
  static apiConfig: GenericAPIConfig;

  static getCall(apiPath: string) {
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static saveRecurringDocument(payload: RecurringDocumentPayload) {
    return http
      .post(
        `${ApiConstants.URL.GENERIC_ENDPOINT.SAVE_RECURRING_DOCUMENT}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static saveNatureOfIncomePayment(payload: NatureIncomePaymentPayLoad) {
    return http
      .post(
        `${ApiConstants.URL.GENERIC_ENDPOINT.SAVE_NATURE_INCOME_PAYMENT}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getSearchApiUrl(apiPath: string) {
    return `${ApiConstants.URL.BASE}${apiPath}`;
  }

  static saveSACCode(payload: HsnSacPayLoad) {
    return http
      .post(`${ApiConstants.URL.GENERIC_ENDPOINT.SAVE_SAC_CODE}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static saveHSNCode(payload: HsnSacPayLoad) {
    return http
      .post(`${ApiConstants.URL.GENERIC_ENDPOINT.SAVE_HSN_CODE}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static updateHSNCode(payload: HsnSacPayLoad) {
    return http
      .put(
        `${ApiConstants.URL.GENERIC_ENDPOINT.UPDATE_HSN_CODE(payload.code)}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating HSN code: ', err);
        return Promise.reject(err);
      });
  }

  static updateSACCode(payload: HsnSacPayLoad) {
    return http
      .put(
        `${ApiConstants.URL.GENERIC_ENDPOINT.UPDATE_SAC_CODE(payload.code)}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating SAC code: ', err);
        return Promise.reject(err);
      });
  }

  static deleteHSNCode(code: any) {
    return http
      .delete(`${ApiConstants.URL.GENERIC_ENDPOINT.DELETE_HSN_CODE(code)}`)
      .catch((err: any) => {
        console.error('Error deleting HSN code: ', err);
        return Promise.reject(err);
      });
  }

  static deleteSACCode(code: any) {
    return http
      .delete(`${ApiConstants.URL.GENERIC_ENDPOINT.DELETE_SAC_CODE(code)}`)
      .catch((err: any) => {
        console.error('Error deleting SAC code:', err);
        return Promise.reject(err);
      });
  }

  static recurrScheduleDetails(payload: any) {
    return http
      .post(`${ApiConstants.URL.GENERIC_ENDPOINT.SCHEDULE_DETAILS}`, payload)
      .catch((err: any) => {
        console.error(err);
        return Promise.reject(err);
      });
  }
}

export default GenericService;
