import { getCapitalized } from "../../Utilities/Utility"

export default class BOMParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        //company name
        if (this.getBomProducts(dataFromAPI) !== undefined) {
            if (this.getBomProducts(dataFromAPI).length > 0) {
                let element = this.getBomProducts(dataFromAPI)[0]
                let name = getCapitalized(element.productName)

                if (element.bomMetaDetailsList && element.bomMetaDetailsList !== null) {
                    if (element.bomMetaDetailsList.length > 0) {
                        let bomMeta = element.bomMetaDetailsList[0]

                        if (dataFromAPI.documentMetaCode && dataFromAPI.documentMetaCode !== null) {
                            let meta = element.bomMetaDetailsList.find(x => x.code === dataFromAPI.documentMetaCode)
                            if(meta) {
                                bomMeta = meta
                            }
                        }
                        if (bomMeta.name && bomMeta.name !== null) {
                            name = name + ' - ' + bomMeta.name
                        }
                    }
                }
                templateDataToReturn.companyName = name
            }
        }

        templateDataToReturn.nestedLineItems = this.processBOMLineItem(dataFromAPI)
        templateDataToReturn.flatLineItems = this.processFlatLineItem(dataFromAPI, templateDataToReturn)
        templateDataToReturn.lineItems = templateDataToReturn.flatLineItems
        return templateDataToReturn
    }


    static processBOMLineItem(dataFromAPI) {
        var lineItems = []

        if (this.getBomProducts(dataFromAPI) !== undefined) {
            if(this.getBomProducts(dataFromAPI).length > 0) {
                const bomProducts = this.getBomProducts(dataFromAPI)
                let element = this.getBomProducts(dataFromAPI)[0]
                var parentItem = {}
                parentItem.name = element.productName
                parentItem.description = element.description
                parentItem.productType = element.productType
                parentItem.code = element.productId
                parentItem.uom = element.uomName
                parentItem.costList = this.assignCostList(element)
                parentItem.operationList = this.assignOperationList(element)
                parentItem.isParent = true
                lineItems.push(parentItem)

                if (element.bomMetaList && element.bomMetaList !== null) {
                    element.bomMetaList.forEach((element, index) => {
                        var item = { ...element }
                        item.lineNumber = index + 1
                        item.name = element.itemName
                        item.code = element.documentSequenceCode
                        item.uom = element.uomName
                        item.costList = this.assignCostList(element)
                        item.operationList = this.assignOperationList(element)
                        item = this.assignBOMMaterial(item, bomProducts)
                        item.isParent = false
                        if (item.productType === 'BILL_OF_MATERIALS') {
                            var bom = bomProducts.find(x => x.productId === item.productCode)
                            if(bom) {
                                item.costList = this.assignCostList(bom)
                                item.operationList = this.assignOperationList(bom)
                            }
                        }
                        lineItems.push(item)
                    });
                }
            }
        }
        return lineItems
    }

    static assignBOMMaterial(item, bomProducts) {
        //loop child level to get all BOM info
        if (item.productType === 'BILL_OF_MATERIALS') {
            var bom = bomProducts.find(x => x.productId === item.productCode)
            if (bom) {
                item.bomMaterials = {}
                if (bom.bomMetaList && bom.bomMetaList !== null) {
                    if (bom.bomMetaList.length > 0) {
                        var materials = []
                        bom.bomMetaList.forEach(ele => {
                            var mate = { ...ele }
                            mate.name = ele.itemName
                            mate.code = ele.documentSequenceCode
                            item.uom = ele.uomName
                            mate.costList = this.assignCostList(ele)
                            mate.operationList = this.assignOperationList(ele)
                            mate.isParent = false
                            if (mate.productType === 'BILL_OF_MATERIALS') {
                                var eleBOM = bomProducts.find(x => x.productId === ele.productCode)
                                if(eleBOM) {
                                    mate.costList = this.assignCostList(eleBOM)
                                    mate.operationList = this.assignOperationList(eleBOM)
                                }
                                this.assignBOMMaterial(mate, bomProducts)
                            }
                            materials.push(mate)
                        });

                        item.bomMaterials = materials
                    }
                }
            }
        }

        return item
    }

    static assignCostList(element) {
        var costList = []
        if (element.bomAdditionalCostList && element.bomAdditionalCostList !== null) {
            if (element.bomAdditionalCostList.length > 0) {
                element.bomAdditionalCostList.forEach(cost => {
                    var newCost = {}
                    newCost.price = cost.price
                    newCost.label = cost.label
                    costList.push(newCost)
                });
            }
        }
        return costList
    }

    static assignOperationList(element) {
        var operationList = []
        if (element.bomOperationList && element.bomOperationList !== null) {
            if (element.bomOperationList.length > 0) {
                element.bomOperationList.forEach(operation => {
                    var newOperation = {}
                    newOperation.title = operation.operationName ?? ''
                    newOperation.operationTime = operation.operationTime ?? 0
                    newOperation.costPerHour = operation.costPerHour ?? 0
                    newOperation.fixedRate = operation.fixedRate ?? 0
                    newOperation.totalCost = operation.totalCost ?? 0
                    operationList.push(newOperation)
                });
            }
        }

        return operationList
    }

    static processFlatLineItem(dataFromAPI, templateDataToReturn) {
        var lineItems = []
        if (templateDataToReturn.nestedLineItems && templateDataToReturn.nestedLineItems !== null) {
            if (templateDataToReturn.nestedLineItems.length > 0) {
                //process nested object 
                templateDataToReturn.nestedLineItems.forEach((element, index) => {
                    lineItems = this.assignFlatItem(element, index, lineItems, undefined)
                });
            }
        }
        return lineItems
    }

    static assignFlatItem = (element, index, lineItems, parentBOM) => {
        var newItem = {...element}
        newItem.uom = element.uomName
        if (element.isParent) {
            lineItems.push(newItem)
        }
        else {
            let bomMaterials = []
            if(parentBOM) {
                newItem.lineNumber = parentBOM.lineNumber + '.' + index
            }

            if (element.bomMaterials && element.bomMaterials !== null) {
                bomMaterials = element.bomMaterials
            }

            const bomCount = bomMaterials.length ?? 0
            if (bomCount === 0) {
                lineItems.push(newItem)
            }
            else {
                lineItems.push(newItem)
                bomMaterials.forEach((ele, eleIndex) => {
                    newItem.bomMaterials = []
                    lineItems = this.assignFlatItem(ele, eleIndex + 1, lineItems, newItem)
                });
            }
        }
        return lineItems
    }

    static getBomProducts(dataFromAPI) {
        if(dataFromAPI && dataFromAPI !== null) {
            if (dataFromAPI.bomProducts && dataFromAPI.bomProducts !== null) {
                if (dataFromAPI.bomProducts.length > 0) {
                    return dataFromAPI.bomProducts
                }
            }
        }

        return undefined
    }
}
