import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import AgenciesService from '../../Services/Agencies';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  transporterData: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchAgencies = createAsyncThunk('fetchEwayBills', async () => {
  const response = await AgenciesService.getAllAgencies();
  return response;
});

export const AgenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencies.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectAgencies = (state: RootState) => state.agencies.data;
