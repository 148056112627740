import { showAlert } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import IamService from '../Services/iam';
import { PAGE_ROUTES } from './RouteManager';
import { matchPath } from 'react-router-dom';

import { lazy } from 'react';
import { SessionStorageService } from '../Services/SessionStorageService';
import Utility from '../Utility/Utility';

// export function lazyWithPreload(factory: any) {
//   const Component: any = React.lazy(factory)
//   Component.preload = factory;
//   return Component;
// }
export const lazyWithPreload = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
export default class AppManager {
  static needSubscriptionApiCall = true;
  static SubscriptionData = {};

  static scrollToTop() {
    let myDiv = document.getElementById('containerDiv') as HTMLElement;
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  static refreshApp() {
    // RouteManager.navigateToPage(PAGE_ROUTES.HOME);
    if (typeof window.history.pushState != 'undefined') {
      var obj = { Page: 'new', Url: '/' };
      window.history.pushState(obj, obj.Page, obj.Url);
    }
    window.location.reload();
  }

  static showTrialActivationWarning(onContinue: () => void) {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m fw-m',
        onClick: () => {}
      },
      {
        title: 'Start trial',
        className: 'bg-blue text-white ml-r fw-m',
        onClick: () => {
          onContinue();
        }
      }
    ];
    showAlert(
      'Trial activation',
      "You are about to start your 15 days FREE trial. Once your trial expires, you will be automatically put on a paid plan.<br><br>You can <b>cancel or change your trial at anytime</b>. We'll remind you before your trial ends.",
      buttons
    );
  }

  static generateDirectLink(path: string) {
    if (path === undefined || path === null || path.trim() === '') {
      return '';
    }
    if (path && path.includes(PAGE_ROUTES.PUBLIC_PAGE_PATH)) {
      return path;
    }
    return window.location.origin + PAGE_ROUTES.PUBLIC_PAGE_PATH + path;
  }

  static isPublicURL() {
    return window.location.pathname.includes(PAGE_ROUTES.PUBLIC_PAGE_PATH);
  }

  static getFormSubmissionURL() {
    return window.location.origin + '/FormSubmission.js';
  }

  static showComingSoonWarning = () => {
    showAlert('Coming soon!', 'We are working on it.');
  };

  static logout() {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Log out',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          IamService.logOut().then((res) => {
            SessionStorageService.deleteSessionStorage();
            window.open(
              ApiConstants.URL.IAM.REDIRECT + window.location.href,
              '_self'
            );
          });
        }
      }
    ];
    showAlert('Log out', 'Are you sure want to log out the session?', buttons);
  }

  static isCurrentRoute(route: any) {
    return matchPath(window.location.pathname, route)?.isExact;
  }

  static handleWindowFocusListeners = (callback: any, register = false) => {
    if (register) {
      /* Register visibility listeners */
      if (Utility.isUndefined(document.hidden)) {
        window.addEventListener('focus', callback);
      } else {
        document.addEventListener('visibilitychange', callback, false);
      }
    } else {
      /* Remove visibility listeners */
      if (Utility.isUndefined(document.hidden)) {
        window.removeEventListener('focus', callback);
      } else {
        document.removeEventListener('visibilitychange', callback, false);
      }
    }
  };

  static setSubscriptionData = (val: any) => {
    AppManager.SubscriptionData = val;
  };

  static getSubscriptionData = () => {
    return AppManager.SubscriptionData;
  };

  static handleWindowResizeListener = (callback: any, register = false) => {
    if (register) {
      window.addEventListener('resize', callback);
    } else {
      window.removeEventListener('resize', callback);
    }
  };

  static handleMobileAppMessageListener = (callback: any, register = false) => {
    if (register) {
      window.addEventListener('message', callback);
      document.addEventListener('message', callback);
    } else {
      window.removeEventListener('message', callback);
      document.removeEventListener('message', callback);
    }
  };
}
