import { TableColumnPopupType } from "../../../Constants/Constants"
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class QCDocumentTableWidth {

    isLineNumberVisible: boolean;
    isNameVisible: boolean;
    isValueVisible: boolean;
    isProductCustomFieldVisible: boolean;

    lineNumber: number;
    name: number;
    value: number;
    productCustomField: number;

    originalLineNumber: number;
    originalName: number;
    originalValue: number;

    originalProductCustomField: number;
    productCustomFieldList: any;

    constructor() {
        this.isLineNumberVisible = false
        this.isNameVisible = true
        this.isValueVisible = true
        this.isProductCustomFieldVisible = false
        
        this.lineNumber = 10
        this.name = 45
        this.value = 45
        this.productCustomField = 5
        
        this.originalLineNumber = 10
        this.originalName = 45
        this.originalValue = 45
        this.originalProductCustomField = 5
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.parameterName)
        this.isValueVisible = getIsShowTableColumn(columnData, TableColumnPopupType.parameterValue)
    
        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        this.name = this.originalName
        this.value = this.originalValue
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.lineNumber = 0
        this.name = 0
        this.value = 0
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isValueVisible) {
            totalWidth += this.value;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isValueVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isValueVisible) {
            this.value = this.originalValue + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}