import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
export interface CustomSalesReportsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
}

export const defaultConfig: CustomSalesReportsAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 1
};

class CustomSalesReportsService {
  static apiConfig: CustomSalesReportsAPIConfig;

  static fetchProntoData(payload: any) {
    return http
      .post(ApiConstants.URL.REPORT.CUSTOM.FETCH_PRONTO_DASHBOARD, payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
}

export default CustomSalesReportsService;
