import { PAGE_ROUTES } from '../Managers/RouteManager';
import { ROUTING_APPLICATION_MODULE } from './Constant';
import { PERMISSIONS_BY_MODULE } from './Permission';
import * as RoutingPath from './RoutingGuardPath';

export interface IRoutingPermissionGuard {
  path: string;
  component: any;
  permission: string;
  applicationModule: string;
}

export const ROUTING_PATHS: IRoutingPermissionGuard[] = [
  {
    permission: PERMISSIONS_BY_MODULE.PRODUCTS.VIEW,
    path: PAGE_ROUTES.PRODUCTS,
    component: RoutingPath.Product,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.CONTACTS.VIEW,
    path: PAGE_ROUTES.CONTACTS,
    component: RoutingPath.Contacts,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.CONTACTS.VIEW,
    path: PAGE_ROUTES.CONTACT_WITH_ID,
    component: RoutingPath.ContactDetail,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
    path: PAGE_ROUTES.INVOICES,
    component: RoutingPath.Invoices,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
    path: PAGE_ROUTES.INVOICE_WITH_ID,
    component: RoutingPath.Invoices,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
    path: PAGE_ROUTES.INVOICE_WITH_ID_AND_CONTACT,
    component: RoutingPath.Invoices,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BILL.VIEW,
    path: PAGE_ROUTES.BILLS,
    component: RoutingPath.Bills,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BILL.VIEW,
    path: PAGE_ROUTES.BILL_WITH_ID,
    component: RoutingPath.Bills,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BILL.VIEW,
    path: PAGE_ROUTES.BILL_WITH_ID_AND_CONTACT,
    component: RoutingPath.Bills,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
    path: PAGE_ROUTES.ORDERS,
    component: RoutingPath.Orders,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REQUISITION.VIEW,
    path: PAGE_ROUTES.REQUISITIONS,
    component: RoutingPath.Requisitions,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.QUOTATION_FORM,
    component: RoutingPath.PublicQuotation,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.PURCHASE_INWARD_QUOTATION,
    component: RoutingPath.PurchaseInwardQuotation,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.ASSET_GROUP,
    component: RoutingPath.AssetGroup,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.ASSET_GROUP_WITH_ID,
    component: RoutingPath.AssetGroup,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.FIXED_ASSET,
    component: RoutingPath.FixedAsset,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.FIXED_ASSET_WITH_ID,
    component: RoutingPath.FixedAsset,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.DEPRECIATION_SCHEDULE,
    component: RoutingPath.DepreciationSchedule,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
    path: PAGE_ROUTES.DEPRECIATION_SCHEDULE_WITH_ID,
    component: RoutingPath.DepreciationSchedule,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
    path: PAGE_ROUTES.ORDER_WITH_ID,
    component: RoutingPath.Orders,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
    path: PAGE_ROUTES.ORDER_WITH_ID_AND_CONTACT,
    component: RoutingPath.Orders,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REQUISITION.VIEW,
    path: PAGE_ROUTES.REQUISITION_WITH_ID,
    component: RoutingPath.Requisitions,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
    path: PAGE_ROUTES.SALES_ORDERS,
    component: RoutingPath.SalesOrder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
    path: PAGE_ROUTES.SALES_ORDER_WITH_ID,
    component: RoutingPath.SalesOrder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
    path: PAGE_ROUTES.SALES_ORDER_WITH_ID_AND_CONTACT,
    component: RoutingPath.SalesOrder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.RFQ,
    component: RoutingPath.Rfq,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.RFQ.VIEW,
    path: PAGE_ROUTES.RFQ_WITH_ID,
    component: RoutingPath.Rfq,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
    path: PAGE_ROUTES.QUOTES,
    component: RoutingPath.Quotations,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
    path: PAGE_ROUTES.QUOTE_WITH_ID,
    component: RoutingPath.Quotations,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
    path: PAGE_ROUTES.QUOTE_WITH_ID_AND_CONTACT,
    component: RoutingPath.Quotations,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.QUOTATION.CONVERT,
    path: PAGE_ROUTES.QUOTE_WITH_ID_AND_ACTION,
    component: RoutingPath.Quotations,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MONEY,
    component: RoutingPath.Money,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.COA.VIEW,
    path: PAGE_ROUTES.ACCOUNTING,
    component: RoutingPath.Accounting,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW,
    path: PAGE_ROUTES.JOURNAL_ENTRY,
    component: RoutingPath.Journal,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.COA.VIEW,
    path: PAGE_ROUTES.CHART_OF_ACCOUNTS,
    component: RoutingPath.Coa,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEPOSIT.VIEW,
    path: PAGE_ROUTES.DEPOSIT,
    component: RoutingPath.Deposits,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.EXPENSE.VIEW,
    path: PAGE_ROUTES.EXPENSE,
    component: RoutingPath.Expenses,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEBIT_NOTES.VIEW,
    path: PAGE_ROUTES.DEBIT_NOTES,
    component: RoutingPath.Debit,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.CREDIT_NOTES.VIEW,
    path: PAGE_ROUTES.CREDIT_NOTES,
    component: RoutingPath.CreditNotes,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.CONSOLIDATED_FINANCIAL_REPORT_LIST,
    component: RoutingPath.ConsolidatedFinancialReportList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DOCUMENTS_EMAILED,
    path: PAGE_ROUTES.DOCUMENTS_EMAILED,
    component: RoutingPath.DocEmailed,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.CONSOLIDATED_FINANCIAL_REPORT,
    component: RoutingPath.ConsolidatedFinancialReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.COA.VIEW,
    path: PAGE_ROUTES.OPENING_BALANCE,
    component: RoutingPath.OpeningBalance,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.WAREHOUSE.CREATE,
    path: PAGE_ROUTES.WAREHOUSE,
    component: RoutingPath.Warehouse,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
    path: PAGE_ROUTES.RULES,
    component: RoutingPath.Rules,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
    path: PAGE_ROUTES.RECONCILE_ACCOUNT,
    component: RoutingPath.ReconcileAccount,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
    path: PAGE_ROUTES.BANK_DETAILS,
    component: RoutingPath.BankDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_LOGS,
    component: RoutingPath.Logs,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BANK.BANK_CONNECT,
    path: PAGE_ROUTES.BANK_CREATE,
    component: RoutingPath.BankConnect,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_NEGATIVE_INVENTORY,
    component: RoutingPath.NegativeInventory,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_CUSTOM_FIELDS_LIST,
    component: RoutingPath.CustomFieldsList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.PRODUCTS.VIEW,
    path: PAGE_ROUTES.PRODUCT_DASHBOARD,
    component: RoutingPath.ProductDashboard,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REPORTS,
    component: RoutingPath.Reports,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.REPORT_PNL,
    component: RoutingPath.ProfitAndLoss,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.DPL_REPORT,
    component: RoutingPath.DPL_REPORT,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REPORTS,
    component: RoutingPath.Reports,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.REPORT_PNL,
    component: RoutingPath.ProfitAndLoss,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.BR_REPORT_PNL,
    component: RoutingPath.BRProfitAndLoss,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    path: PAGE_ROUTES.REPORT_BS_DETAILS,
    component: RoutingPath.BalanceSheetDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    path: PAGE_ROUTES.REPORT_BS,
    component: RoutingPath.BalanceSheet,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BALANCE_SHEET,
    path: PAGE_ROUTES.BR_REPORT_BS,
    component: RoutingPath.BRBalanceSheet,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE,
    path: PAGE_ROUTES.REPORT_TB,
    component: RoutingPath.TrialBalance,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_RECONCILIATION,
    path: PAGE_ROUTES.REPORT_BANK_REC_SUMMARY,
    component: RoutingPath.BankReconciliationReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STATEMENT_OF_ACCOUNTS,
    path: PAGE_ROUTES.REPORT_SOA_CUSTOMER,
    component: RoutingPath.StatementOfAccountCustomer,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_RECONCILIATION,
    path: PAGE_ROUTES.REPORT_RECONCILIATION_LOG,
    component: RoutingPath.ReconciliationLogReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STATEMENT_OF_ACCOUNTS_VENDORS,
    path: PAGE_ROUTES.REPORT_SOA_VENDOR,
    component: RoutingPath.StatementOfAccountVendor,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GENERAL_LEDGER,
    path: PAGE_ROUTES.REPORT_GL,
    component: RoutingPath.GeneralLedger,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GENERAL_LEDGER,
    path: PAGE_ROUTES.BR_REPORT_GL,
    component: RoutingPath.BRGeneralLedger,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.CASH_FLOW_STATEMENT,
    path: PAGE_ROUTES.REPORT_CASHFLOW,
    component: RoutingPath.CashFlow,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DETAILED_PURCHASE,
    path: PAGE_ROUTES.TALLY_DETAILED_PURCHASE_REPORT,
    component: RoutingPath.DetailedPurchaseReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_REGISTER_SUMMARY,
    path: PAGE_ROUTES.TALLY_SALES_REGISTER_SUMMARY,
    component: RoutingPath.SalesRegisterSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DEBIT_NOTES,
    path: PAGE_ROUTES.REPORT_PURCHASE_DN,
    component: RoutingPath.DebitReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.CREDIT_NOTES,
    path: PAGE_ROUTES.REPORT_PURCHASE_CN,
    component: RoutingPath.CreditReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.INVOICES,
    path: PAGE_ROUTES.REPORT_SALES_INVOICE,
    component: RoutingPath.InvoiceReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BILL_LIST,
    path: PAGE_ROUTES.REPORT_PURCHASE_BILL_LIST,
    component: RoutingPath.BillList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PO_LIST,
    path: PAGE_ROUTES.REPORT_PURCHASE_ORDER_LIST,
    component: RoutingPath.OrderList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_MOVEMENT_REPORT,
    path: PAGE_ROUTES.STOCK_MOVEMENT_REPORT,
    component: RoutingPath.StockMovement,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT,
    path: PAGE_ROUTES.QUOTATION_LIST_REPORT,
    component: RoutingPath.QuotationList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.OUTSTANDING_SALES_FULLFILLMENT_REPORT,
    component: RoutingPath.OutstandingSalesFullfillmentReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_BY_PRODUCT,
    path: PAGE_ROUTES.SALES_BY_PRODUCT,
    component: RoutingPath.SalesByProduct,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT_STATUS,
    path: PAGE_ROUTES.REPORT_FULFILLMENT_REPORT,
    component: RoutingPath.FulfillmentReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT,
    path: PAGE_ROUTES.REPORT_SALES_ORDER_DETAILS,
    component: RoutingPath.SalesOrderDetailReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_BY_PRODUCT,
    path: PAGE_ROUTES.PURCHASE_BY_PRODUCT,
    component: RoutingPath.PurchaseByProduct,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_BY_CONTACT,
    path: PAGE_ROUTES.SALES_BY_CONTACT,
    component: RoutingPath.SalesByContact,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BOM,
    path: PAGE_ROUTES.BOM_ASSEMBLY,
    component: RoutingPath.BomAssembly,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_BY_CONTACT,
    path: PAGE_ROUTES.PURCHASE_BY_CONTACT,
    component: RoutingPath.PurchaseByContact,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.INVOICES,
    path: PAGE_ROUTES.RECEIPT_LIST,
    component: RoutingPath.ReceiptList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PAYMENT_LIST,
    path: PAGE_ROUTES.PAYMENT_LIST,
    component: RoutingPath.PaymentList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.OUTSTANDING_PURCHASES_GOOD_RECEIVED,
    path: PAGE_ROUTES.REPORT_OPGR,
    component: RoutingPath.OutstandingPGR,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_AVAILABILITY_BY_WAREHOUSE,
    path: PAGE_ROUTES.STOCK_WAREHOUSE_REPORT,
    component: RoutingPath.StockWarehouseReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_AVAILABILITY_BY_WAREHOUSE,
    path: PAGE_ROUTES.STOCK_STATUS_REPORT,
    component: RoutingPath.StockStatusReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.REORDER_LEVEL,
    path: PAGE_ROUTES.REORDER_LEVEL_REPORT,
    component: RoutingPath.ReorderLevelReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.RESERVED_STOCK,
    path: PAGE_ROUTES.RESERVED_STOCK_REPORT,
    component: RoutingPath.Reservestock,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_LEDGER,
    path: PAGE_ROUTES.STOCK_LEDGER_REPORT,
    component: RoutingPath.StockLedgerReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.AGED_RECEIVABLES,
    path: PAGE_ROUTES.AGED_RECEIVABLES_REPORT,
    component: RoutingPath.AgedReceivables,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.RATE_ANALYSIS,
    path: PAGE_ROUTES.RATE_ANALYSIS_REPORT,
    component: RoutingPath.SalesRateAnalysisReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.CREATE_REVENUE_RECOGNITION_JE,
    path: PAGE_ROUTES.REV_REC_JE,
    component: RoutingPath.RevRecJE,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW,
    path: PAGE_ROUTES.RECLASSIFICATION_JE,
    component: RoutingPath.ReClassificationJe,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW,
    path: PAGE_ROUTES.RECLASSIFICATION_JE_WITH_PROCESSED_ID,
    component: RoutingPath.ProcessedReClassificationJe,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.AGED_PAYABLES,
    path: PAGE_ROUTES.AGED_PAYABLES_REPORT,
    component: RoutingPath.AgedPayables,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PAYMENT_MILESTONE_TRACKER,
    path: PAGE_ROUTES.PAYMENT_MILESTONE_TRACKER,
    component: RoutingPath.MilestoneTracker,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GOODS_RECEIVED,
    path: PAGE_ROUTES.GOODS_RECEIVED_NOTES,
    component: RoutingPath.GoodsReceivedNotes,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_ORDER_DETAIL,
    path: PAGE_ROUTES.PURCHASE_ORDER_DETAIL_REPORT,
    component: RoutingPath.PurchaseOrderDetailReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_ORDER_DETAIL,
    path: PAGE_ROUTES.GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT,
    component: RoutingPath.GoodsReceptToleranceLevelReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.FULFILLMENT,
    path: PAGE_ROUTES.FULFILLMENT_TOLERANCE_LEVEL_REPORT,
    component: RoutingPath.FulfillmentToleranceLevelReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR1,
    component: RoutingPath.Gstr1,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.RECEIVABLE,
    path: PAGE_ROUTES.TALLY_RECEIVABLE,
    component: RoutingPath.ReceivableTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.RECEIPT_SUMMARY,
    path: PAGE_ROUTES.TALLY_RECEIPT_SUMMARY,
    component: RoutingPath.ReceiptSummaryTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.CASHFLOW,
    path: PAGE_ROUTES.TALLY_CASHFLOW,
    component: RoutingPath.CashflowTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_REPORT,
    path: PAGE_ROUTES.SALES_BY_CLASS,
    component: RoutingPath.SalesByClass,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_REPORT,
    path: PAGE_ROUTES.SALES_BY_CLASS_DETAIL,
    component: RoutingPath.SalesByClassDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.JOB_WORKOUT,
    path: PAGE_ROUTES.JOB_BRIEF_DASHBOARD,
    component: RoutingPath.JobBriefReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.QC_SUMMARY,
    path: PAGE_ROUTES.QC_SUMMARY,
    component: RoutingPath.QualityCheckSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.QC_DETAILED,
    path: PAGE_ROUTES.QC_DETAILED_REPORT,
    component: RoutingPath.QualityDetailed,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCT_WISE_QC,
    path: PAGE_ROUTES.PRODUCTWISE_QC_REPORT,
    component: RoutingPath.productwiseQC,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.VENDOR_WISE_QC,
    path: PAGE_ROUTES.VENDORWISE_QC_REPORT,
    component: RoutingPath.vendorwiseQC,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_SUMMARY,
    path: PAGE_ROUTES.TALLY_STOCK_SUMMARY2,
    component: RoutingPath.StockSummary2,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_SUMMARY,
    path: PAGE_ROUTES.TALLY_STOCK_SUMMARY3,
    component: RoutingPath.StockSummary3,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VENDOR_STATEMENT_OF_ACCOUNT,
    path: PAGE_ROUTES.TALLY_VENDOR_SOA,
    component: RoutingPath.VendorSoaTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.CUSTOMER_STATEMENT_OF_ACCOUNT,
    path: PAGE_ROUTES.TALLY_CUSTOMER_SOA,
    component: RoutingPath.CustomerSoaTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DETAILED_SALES,
    path: PAGE_ROUTES.TALLY_DETAILED_SALES_REPORT,
    component: RoutingPath.DetailedSalesReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DAY_BOOK,
    path: PAGE_ROUTES.TALLY_DAY_BOOK,
    component: RoutingPath.DayBookTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_SUMMARY,
    path: PAGE_ROUTES.TALLY_STOCK_SUMMARY,
    component: RoutingPath.StockSummaryTally,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_DETAILS,
    component: RoutingPath.B2BInvoice,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_DETAILS_CDNR,
    component: RoutingPath.CDNR,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_EXEMPT,
    component: RoutingPath.Exempt,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_EXEMPT_DETAILS,
    component: RoutingPath.ExemptDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_B2CL,
    component: RoutingPath.B2CL,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_B2CS,
    component: RoutingPath.B2CS,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_CDNUR,
    component: RoutingPath.CDNUR,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_EXPORT_INVOICE,
    component: RoutingPath.ExportInvoice,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_AT,
    component: RoutingPath.AT,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GSTR_HSN,
    component: RoutingPath.Hsn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PURCHASE_RETURN,
    path: PAGE_ROUTES.PURCHASE_RETURN_REPORT,
    component: RoutingPath.PurchaseReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_VALUATION,
    path: PAGE_ROUTES.STOCK_VALUATION,
    component: RoutingPath.StockValuationReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_RETURN,
    path: PAGE_ROUTES.SALES_RETURN_REPORT,
    component: RoutingPath.SalesReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.STOCK_AGEING,
    path: PAGE_ROUTES.STOCK_AGEING,
    component: RoutingPath.StockAgeing,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_CUSTOMER,
    path: PAGE_ROUTES.TDS_CUSTOMER,
    component: RoutingPath.TdsCustomer,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.FAILED_E_INVOICE_LOG,
    path: PAGE_ROUTES.FAILED_INVOICES,
    component: RoutingPath.FailedInvoices,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GST_REPORT_LOG_SG,
    path: PAGE_ROUTES.GST_REPORT_LOG,
    component: RoutingPath.GstLogReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST5FORM_EDIT,
    component: RoutingPath.GST5Form,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST7FORM_EDIT,
    component: RoutingPath.GST7Form,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST5FORM_VIEW,
    component: RoutingPath.GST5Form,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST7FORM_VIEW,
    component: RoutingPath.GST7Form,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_2B_RECONCILIATION,
    path: PAGE_ROUTES.GSTR2B_REPORT,
    component: RoutingPath.Gstr2B,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  // {
  //   permission: PERMISSIONS_BY_MODULE.REPORTS.VIEW,
  //   path: PAGE_ROUTES.GSTR2B_REPORT_NEW,
  //   component: RoutingPath.Gstr2B_New,
  //   applicationModule: ROUTING_APPLICATION_MODULE.ERP
  // },
  // {
  //   permission: PERMISSIONS_BY_MODULE.REPORTS.VIEW,
  //   path: PAGE_ROUTES.GSTR2B_VIEW_SUMMARY_REPORT,
  //   component: RoutingPath.Gstr2B_View_Summary,
  //   applicationModule: ROUTING_APPLICATION_MODULE.ERP
  // },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_2B_RECONCILIATION,
    path: PAGE_ROUTES.GSTR2B_DOWNLOAD_REPORT,
    component: RoutingPath.Gstr2BDownloadReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_2B_RECONCILIATION,
    path: PAGE_ROUTES.GSTR2B_LINK_DOCUMENT,
    component: RoutingPath.Gstr2BLinkDoc,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GSTR3B_REPORT,
    component: RoutingPath.Gstr3B,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GST_THREE_ONE_A,
    component: RoutingPath.GstThreeOneA,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GST_THREE_ONE_B,
    component: RoutingPath.GstThreeOneB,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GST_THREE_ONE_D,
    component: RoutingPath.GstThreeOneD,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GST_THREE_TWO_A,
    component: RoutingPath.GstThreeTwoA,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_3B_REPORT,
    path: PAGE_ROUTES.GST_THREE_TWO_B,
    component: RoutingPath.GstThreeTwoB,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_A_ONE,
    component: RoutingPath.GstFourAOne,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_A_TWO,
    component: RoutingPath.GstFourATwo,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_A_THREE,
    component: RoutingPath.GstFourAThree,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_A_FIVE,
    component: RoutingPath.GstFourAFive,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_B_ONE,
    component: RoutingPath.GstFourBOne,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_D_ONE,
    component: RoutingPath.GstFourDOne,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_D_TWO,
    component: RoutingPath.GstFourDTwo,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FIVE_TWO,
    component: RoutingPath.GstFiveTwo,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FIVE_THREE,
    component: RoutingPath.GstFiveThree,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FIVE_FOUR,
    component: RoutingPath.GstFiveFour,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR_ONE,
    path: PAGE_ROUTES.GST_FOUR_B_TWO,
    component: RoutingPath.GstFourBTwo,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_DETAILS,
    path: PAGE_ROUTES.TDS_DETAILS,
    component: RoutingPath.TDSDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TDS_NOT_DEDUCTED,
    path: PAGE_ROUTES.TDS_NOT_DED,
    component: RoutingPath.TDSNotDeducted,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX_LIABILITY,
    path: PAGE_ROUTES.SALES_TAX_US,
    component: RoutingPath.SalesTaxReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX,
    path: PAGE_ROUTES.SALES_TAX_AGENCY,
    component: RoutingPath.SalesTaxAgency,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.REPORT_PNL_DETAILS,
    component: RoutingPath.ProfitAndLossDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.CUSTOM_REPORT_BUILDER,
    component: RoutingPath.CustomReportBuilder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BATCH_STATUS,
    path: PAGE_ROUTES.BATCH_STATUS,
    component: RoutingPath.BatchStatus,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR1_RETURN_SUMMARY,
    path: PAGE_ROUTES.GST_RETURN,
    component: RoutingPath.GstReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SALES_TAX_RETURN_MY,
    path: PAGE_ROUTES.SALES_TAX_RETURN,
    component: RoutingPath.SalesTaxReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SERVICE_TAX_RETURN_MY,
    path: PAGE_ROUTES.SERVICE_TAX_RETURN,
    component: RoutingPath.ServiceTaxReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GSTR1_RETURN_SUMMARY,
    path: PAGE_ROUTES.GSTR1_RETURN_FILE,
    component: RoutingPath.GSTR1ForReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE,
    path: PAGE_ROUTES.REPORT_TBD,
    component: RoutingPath.TrialBalanceDetail,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.ITEM_LANDED_COST,
    path: PAGE_ROUTES.REPORT_ITEM_LANDED_COST,
    component: RoutingPath.ItemLandedCostReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.E_WAY_BILL,
    path: PAGE_ROUTES.REPORT_SALES_EWB_REPORT,
    component: RoutingPath.EWayBillReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },

  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.BANKS,
    component: RoutingPath.Bank,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SST02,
    path: PAGE_ROUTES.SSTO2_REPORT_MY,
    component: RoutingPath.SST02Report,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BATCH_SERIAL_LEDGER,
    path: PAGE_ROUTES.BATCH_SERIAL_LADGER_REPORT,
    component: RoutingPath.BatchSerialLedgerReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.COA.VIEW,
    path: PAGE_ROUTES.FINANCIAL_YEAR_CLOSING,
    component: RoutingPath.FinancialYearClosings,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.PRICE_LIST.VIEW,
    path: PAGE_ROUTES.PRICE_LIST,
    component: RoutingPath.PriceList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.PRICE_LIST.VIEW,
    path: PAGE_ROUTES.PRICE_BOOK,
    component: RoutingPath.PriceBookList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_MODULES,
    component: RoutingPath.Modules,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_ADVANCED_INVENTORY,
    component: RoutingPath.AdvancedInventory,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.BUDGETING.VIEW,
    path: PAGE_ROUTES.BUDGETING,
    component: RoutingPath.Budget,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
    path: PAGE_ROUTES.AUTOMATIONS,
    component: RoutingPath.Automation,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
    path: PAGE_ROUTES.AUTOMATION_BUILDER,
    component: RoutingPath.AutomationBuilder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
    path: PAGE_ROUTES.AUTOMATION_BUILDER + '/:id',
    component: RoutingPath.AutomationBuilder,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.BK_CLIENT,
    component: RoutingPath.Client,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.BK_TEAM,
    component: RoutingPath.Team,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.PREPARE_1099,
    component: RoutingPath.Prepare1099,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_FILTER_ACCOUNTS,
    component: RoutingPath.FilterAccountsSetting,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.PICK_PACK_SHIP,
    component: RoutingPath.PickPackShip,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.PRINT_CHECKS,
    component: RoutingPath.PrintChecks,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.IMPORT,
    component: RoutingPath.Portal,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.COA.VIEW,
    path: PAGE_ROUTES.BANKS,
    component: RoutingPath.Bank,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.SST02,
    path: PAGE_ROUTES.SSTO2_REPORT_MY,
    component: RoutingPath.SST02Report,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BATCH_SERIAL_LEDGER,
    path: PAGE_ROUTES.BATCH_SERIAL_LADGER_REPORT,
    component: RoutingPath.BatchSerialLedgerReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.STOCK_ADJUSTMENT,
    component: RoutingPath.StockAdjustment,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.REV_REC_WATERFALL,
    path: PAGE_ROUTES.REV_REC_WATERFALL,
    component: RoutingPath.RevRecWaterfall,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DEFERRED_REVENUE_BY_ITEM,
    path: PAGE_ROUTES.REV_REC_DEFERRED_REV_BY_ITEM,
    component: RoutingPath.RevRecDeferredRevenueByItem,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.DEFERRED_REVENUE_BY_CUSTOMER,
    path: PAGE_ROUTES.REV_REC_DEFERRED_REV_BY_CUSTOMER,
    component: RoutingPath.RevRecDeferredRevenueByCustomer,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.STOCK_REQUEST,
    component: RoutingPath.StockRequest,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.STOCK_TRANSFER,
    component: RoutingPath.StockTransfer,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.STOCK_ISSUE,
    component: RoutingPath.StockIssue,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.QUALITY_CHECK,
    component: RoutingPath.QualityCheck,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_FINISHED_GOODS,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_RAW_MATERIAL,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_BILL_OF_MATERIAL,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_HOLIDAY_LIST,
    component: RoutingPath.MRPHolidayList,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION_SUMMARY,
    path: PAGE_ROUTES.MRP_REPORT_PRODUCTION_SUMMARY,
    component: RoutingPath.ProductionSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION_COSTING,
    path: PAGE_ROUTES.MRP_REPORT_PRODUCTION,
    component: RoutingPath.ProductionReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.FG_COMPONENT_TRACKER,
    path: PAGE_ROUTES.MRP_REPORT_FG_COMPONENT_TRACKER,
    component: RoutingPath.FGComponentTrackerReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION_COSTING,
    path: PAGE_ROUTES.MRP_REPORT_PRODUCTION_COSTING,
    component: RoutingPath.ProductionCostingReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.DAILY_CONSUMPTION_DETAILED,
    path: PAGE_ROUTES.MRP_REPORT_DAILY_CONSUMPTION,
    component: RoutingPath.DailyConsumptionReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.DAILY_CONSUMPTION_SUMMARY,
    path: PAGE_ROUTES.MRP_REPORT_DAILY_CONSUMPTION_SUMMARY,
    component: RoutingPath.DailyConsumptionSummaryReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.YIELD_SUMMARY,
    path: PAGE_ROUTES.MRP_REPORT_YIELD_SUMMARY,
    component: RoutingPath.YieldSummaryReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.YIELD_SUMMARY_DETAILED,
    path: PAGE_ROUTES.MRP_REPORT_YIELD_SUMMARY_DETAILED,
    component: RoutingPath.YieldDetailedSummaryReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.FG_COMPONENT_TRACKER,
    path: PAGE_ROUTES.MRP_REPORT_FG_COMPONENT_TRACKER,
    component: RoutingPath.FGComponentTrackerReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_WAREHOUSE_SHORTFALL_SUMMARY,
    path: PAGE_ROUTES.MRP_REPORT_SHORTFALL_SUMMARY,
    component: RoutingPath.WOShortFallSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_WAREHOUSE_SHORTFALL_DETAILED,
    path: PAGE_ROUTES.MRP_REPORT_SHORTFALL_DETAIL,
    component: RoutingPath.WOShortFallDetail,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.PRODUCTION,
    path: PAGE_ROUTES.MRP_REPORT_BIN_ALLOCATION_REPORT,
    component: RoutingPath.binAllocationReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_PLANNING,
    path: PAGE_ROUTES.MRP_OPERATIONWISE_SUMMARY_REPORT,
    component: RoutingPath.operationwiseSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_PLANNING,
    path: PAGE_ROUTES.MRP_REPORT_WOWISE_OPERATION_DETAILS_REPORT,
    component: RoutingPath.woWiseOperationDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
    path: PAGE_ROUTES.MRP_PRODUCTS,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VIEW,
    path: PAGE_ROUTES.MRP_OPERATIONWISE_SUMMARY_REPORT,
    component: RoutingPath.operationwiseSummary,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VIEW,
    path: PAGE_ROUTES.MRP_REPORT_WOWISE_OPERATION_DETAILS_REPORT,
    component: RoutingPath.woWiseOperationDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
    path: PAGE_ROUTES.MRP_FINISHED_GOODS,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
    path: PAGE_ROUTES.MRP_BILL_OF_MATERIAL,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
    path: PAGE_ROUTES.MRP_RAW_MATERIAL,
    component: RoutingPath.MRPProducts,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_PRODUCTION_PLAN,
    component: RoutingPath.MRPProductionPlan,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MRP_DASHBOARD,
    component: RoutingPath.MRPDashboard,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
    path: PAGE_ROUTES.MRP_LOCATIONS,
    component: RoutingPath.MRPLocations,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.TABLET_DASHBOARD,
    component: RoutingPath.TabletDashboard,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.TABLET_DASHBOARD,
    component: RoutingPath.TabletDashboard,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REVALUATION,
    component: RoutingPath.Revaluation,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MULTI_COMPANY_CONSOLIDATED_FINANCIAL_REPORT,
    component: RoutingPath.MultiCompanyConsolidatedFinancialReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MULTI_COMPANY_CONSOLIDATED_FINANCIAL_REPORT_LIST,
    component: RoutingPath.MultiCompanyConsolidatedFinancialReportList,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_REVENUE_CATEGORY,
    component: RoutingPath.RevenueCategory,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_FAIR_VALUE,
    component: RoutingPath.FairValue,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SETTINGS_REV_REC,
    component: RoutingPath.RevenueRecognition,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REVENUE_ARRANGEMENT,
    component: RoutingPath.RevenueArrangment,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REVENUE_ARRANGEMENT_WITH_ID,
    component: RoutingPath.RevenueArrangment,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.UPDATE_REVENUE_ARRANGEMENT,
    component: RoutingPath.UpdateRevenueArrangment,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REVENUE_ARRANGEMENT_JOB,
    component: RoutingPath.RevenueArrangmentJob,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.REVENUE_ARRANGEMENT_JOB_WITH_ID,
    component: RoutingPath.RevenueArrangmentJob,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.INTER_COMPANY_TRANSACTION_REPORT,
    component: RoutingPath.InterCompanyTransactionReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.AMORTIZATION_TEMPLATES,
    component: RoutingPath.AmortizationTemplates,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.AMORTIZATION_SCHEDULES,
    component: RoutingPath.AmortizationSchedules,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.AMORTIZATION_JE,
    component: RoutingPath.AmortizationJe,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VAT_RETURN_UK,
    path: PAGE_ROUTES.VAT_RETURN,
    component: RoutingPath.VatReturn,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VAT_RETURN_UK,
    path: PAGE_ROUTES.VAT_RETURN_DETAIL,
    component: RoutingPath.VatReturnDetail,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.EXPENSE_ALLOCATION_TEMPLATES,
    component: RoutingPath.ExpenseAllocationTemplates,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.AMORTIZATION_FORECAST_REPORT,
    component: RoutingPath.forecastReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.DEFERRED_EXPENSE_WATERFALL_REPORT,
    component: RoutingPath.deferredExpenseWaterfallReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.DEFERRED_EXPENSE_WATERFALL_DETAILS_REPORT,
    component: RoutingPath.deferredExpenseWaterfallDetailsReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.DEFERRED_EXPENSE_ROLLFORWARD_SUMMARY_REPORT,
    component: RoutingPath.deferredExpenseRollforwardSummaryReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT,
    component: RoutingPath.PNLByDepartmentReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.PROFIT_AND_LOSS,
    path: PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT_DETAILS,
    component: RoutingPath.PNLByDepartmentReportDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE,
    path: PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT,
    component: RoutingPath.TrialBalanceByDepartmentReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.TRIAL_BALANCE,
    path: PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT_DETAILS,
    component: RoutingPath.TrialBalanceByDepartmentReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.SKU_WISE_ORDER_TRACKING,
    path: PAGE_ROUTES.SKU_WISE_ORDER_TRACKING,
    component: RoutingPath.SKUWiseOrderTracking,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BOM,
    path: PAGE_ROUTES.BOM_REPORT,
    component: RoutingPath.BomReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.STOCK_ADJUSTMENT.VIEW,
    path: PAGE_ROUTES.STOCK_ADJUSTMENT,
    component: RoutingPath.StockAdjustment,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.STOCK_TRANSFER.VIEW,
    path: PAGE_ROUTES.STOCK_TRANSFER,
    component: RoutingPath.StockTransfer,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.STOCK_REQUEST.VIEW,
    path: PAGE_ROUTES.STOCK_REQUEST,
    component: RoutingPath.StockRequest,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.STOCK_ISSUE.VIEW,
    path: PAGE_ROUTES.STOCK_ISSUE,
    component: RoutingPath.StockIssue,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.QUALITY_CHECK.VIEW,
    path: PAGE_ROUTES.QUALITY_CHECK,
    component: RoutingPath.MRPQualityCheck,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOB_CARD.VIEW,
    path: PAGE_ROUTES.MRP_JOB_CARD,
    component: RoutingPath.MRPJobCard,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.OPERATORS.VIEW,
    path: PAGE_ROUTES.MRP_OPERATOR_LIST,
    component: RoutingPath.MRPOperatorList,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
    path: PAGE_ROUTES.MRP_OPERATIONS,
    component: RoutingPath.Operations,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
    path: PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER,
    component: RoutingPath.MachineMaster,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
    path: PAGE_ROUTES.MRP_MACHINE_SCHEDULING,
    component: RoutingPath.MachineScheduleCalender,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
    path: PAGE_ROUTES.MRP_ASSET_MAINTENANCE_SCHEDULE,
    component: RoutingPath.AssetMaintenanceSchedule,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.WORKSTATIONS.VIEW,
    path: PAGE_ROUTES.MRP_WORK_STATIONS,
    component: RoutingPath.WorkStations,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_MASTER_FORECAST,
    component: RoutingPath.MRPMasterForecast,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_FORECASTING,
    component: RoutingPath.MRPForecast,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_MASTER_FORECASTING_EDIT_BY_ID,
    component: RoutingPath.MRPAddMasterForeCast,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_MASTER_FORECASTING_DETAIL_BY_ID,
    component: RoutingPath.MRPMasterForecastDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.WORK_ORDER.VIEW,
    path: PAGE_ROUTES.MRP_WORK_ORDER,
    component: RoutingPath.MRPWorkOrder,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOB_WORKOUTS.VIEW,
    path: PAGE_ROUTES.MRP_WORK_OUT,
    component: RoutingPath.MRPWorkOut,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.SCHEDULING.VIEW,
    path: PAGE_ROUTES.MRP_SCHEDULING,
    component: RoutingPath.MRPScheduling,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MRP_REPORTS_LIST,
    component: RoutingPath.MRPReports,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.MRP_ROUTING_TEMPLATES,
    component: RoutingPath.MRPRoutingTemplates,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOB_WORKOUTS.VIEW,
    path: PAGE_ROUTES.MRP_WORK_OUT_WITH_ID,
    component: RoutingPath.MRPWorkOut,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_FORECASTING_DETAIL,
    component: RoutingPath.MRPForecastDetailsNew,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_FORECASTING_DETAIL_BY_ID,
    component: RoutingPath.MRPForecastDetailsNew,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
    path: PAGE_ROUTES.MRP_MASTER_FORECAST_CREATE,
    component: RoutingPath.MRPAddMasterForeCast,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.JOB_CARD.VIEW,
    path: PAGE_ROUTES.MRP_JOB_CARD_BY_WORK_ORDER_ID,
    component: RoutingPath.MRPJobCard,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.VALUATION_BY_WAREHOUSE,
    path: PAGE_ROUTES.VALUATION_BY_WAREHOUSE,
    component: RoutingPath.ValuationByWarehouse,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    path: PAGE_ROUTES.VALUATION_BY_WAREHOUSE_DETAILS,
    permission: PERMISSIONS_BY_MODULE.REPORTS.VALUATION_BY_WAREHOUSE,
    component: RoutingPath.ValuationByWarehouseDetails,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.RESTOCKING_LEVELS,
    path: PAGE_ROUTES.MRP_REPORT_RESTOCKING_LEVELS_REPORT,
    component: RoutingPath.restockingLevelsReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.JOB_WORKOUT,
    path: PAGE_ROUTES.MRP_REPORT_JOB_WORKOUT_REPORT,
    component: RoutingPath.jobWorkoutReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.WORK_ORDER_PLANNING,
    path: PAGE_ROUTES.MRP_REPORT_SO_TO_WO_REPORT,
    component: RoutingPath.sOToWOReports,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  },
  {
    //Changes here for GR permission view
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.GOODS_RECEIPT_MASTER_LIST,
    component: RoutingPath.GoodsReceiptModule,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    //Changes here for FF permission view
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.FULFILLMENT_MASTER_LIST,
    component: RoutingPath.fulfillmentModule,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.GOODS_RECEIVED,
    path: PAGE_ROUTES.TOLERANCE_LEVEL_REJECTION_STOCK_REPORT,
    component: RoutingPath.ToleranceLevelRejectionStockReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BILL_LIST,
    path: PAGE_ROUTES.PURCHASE_QUOTATION_COMPARISON_REPORT,
    component: RoutingPath.PurchaseQuotationComparisonReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.SECURITY_GATE_ENTRY_LIST,
    component: RoutingPath.SECURITY_GATE_ENTRY,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.REORDER_LEVEL,
    path: PAGE_ROUTES.ORDER_LEVEL_REPORT,
    component: RoutingPath.OrderLevelReport,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.REPORTS.BANK_BOOK,
    path: PAGE_ROUTES.BANK_BOOK_REPORT,
    component: RoutingPath.BankBookReportRoute,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.GENERATE_BANK_FILE_INVOICE,
    component: RoutingPath.GenerateBankFileInvoice,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
    path: PAGE_ROUTES.GENERATE_BANK_FILE_BILL,
    component: RoutingPath.GenerateBankFileBill,
    applicationModule: ROUTING_APPLICATION_MODULE.ERP
  },
  {
    permission: PERMISSIONS_BY_MODULE.MRP_REPORTS.FORECAST_DRIVEN_PURCHASE_ORDER,
    path: PAGE_ROUTES.FORECAST_DRIVEN_PO_REPORT,
    component: RoutingPath.forecastDrivenPoReport,
    applicationModule: ROUTING_APPLICATION_MODULE.MRP
  }
];
