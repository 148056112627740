import { compareAsc, format, parse, parseISO } from 'date-fns';
import { BOOKS_DATE_FORMAT, DAY_OF_WEEK } from '../Constants/Constant';
import { DATE_COMPARISON } from '../Constants/Enum';
import { utcToZonedTime, format as formatz } from 'date-fns-tz';
import Utility from '../Utility/Utility';

abstract class DateFormatService {
  public static tenantDateFormat = BOOKS_DATE_FORMAT['DD-MM-YYYY'];

  static setTenantDateFormat = (format: any) => {
    DateFormatService.tenantDateFormat = format.toUpperCase();
  };

  // Get date-fns date formats from tenant's or book's date format
  static convertBooksDateFormatToDateFnsFormat = (
    dateFormat: BOOKS_DATE_FORMAT
  ): string => {
    const formatsMap: { [key: string]: string } = {
      [BOOKS_DATE_FORMAT['DD-MM-YYYY']]: 'dd-MM-yyyy',
      [BOOKS_DATE_FORMAT['MM-DD-YYYY']]: 'MM-dd-yyyy',
      [BOOKS_DATE_FORMAT['YYYY-MM-DD']]: 'yyyy-MM-dd',
      [BOOKS_DATE_FORMAT['DD/MM/YYYY']]: 'dd/MM/yyyy',
      [BOOKS_DATE_FORMAT['MM/DD/YYYY']]: 'MM/dd/yyyy',
      [BOOKS_DATE_FORMAT['D MMM YYYY']]: 'd MMM yyyy',
      ['ISO8601']: "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
      [BOOKS_DATE_FORMAT['ISO-8601']]: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
      [BOOKS_DATE_FORMAT['ISO-FORMAT']]: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      [BOOKS_DATE_FORMAT['HH:MM A']]: 'hh:mm a',
      [BOOKS_DATE_FORMAT['HH:MM:SS A']]: 'hh:mm:ss a',
      [BOOKS_DATE_FORMAT['AUDIT_TIME']]: 'dd-MMMM-yyyy HH:mm:ss x',

    };

    return formatsMap[dateFormat];
  };

  /**
   * Convert date string from one format to another
   * @param dateStr Date string to be formatted
   * @param fromFormat Any one of the book's date formats
   * @param toFormat (Optional) Any one of the book's date formats
   * @returns formatted date string as per tenant's date format, or, a provided book's date format
   */
  static getFormattedDateString(
    dateStr: string,
    fromFormat: BOOKS_DATE_FORMAT,
    toFormat?: BOOKS_DATE_FORMAT
  ): string {
    try {
      return format(
        parse(
          dateStr,
          this.convertBooksDateFormatToDateFnsFormat(fromFormat),
          new Date()
        ),
        this.convertBooksDateFormatToDateFnsFormat(
          toFormat ? toFormat : this.tenantDateFormat
        )
      );
    } catch (e: any) {
      // console.error('Error formatting Date: ', dateStr);
      return '';
    }
  }

  static getFormattedDateString_ISO(
    dateStr: string,
    toFormat?: BOOKS_DATE_FORMAT
  ): string {
    try {
      return format(
        parseISO(dateStr),
        this.convertBooksDateFormatToDateFnsFormat(
          toFormat ? toFormat : this.tenantDateFormat
        )
      );
    } catch (e: any) {
      // console.error('Error formatting Date: ', dateStr);
      return '';
    }
  }

  static getFormattedDateAndTimeString(
    dateStr: Date,
    toFormat?: BOOKS_DATE_FORMAT,
    toTimeFormat?: BOOKS_DATE_FORMAT
  ): string {
    const convertToFormat =
      this.convertBooksDateFormatToDateFnsFormat(
        toFormat ? toFormat : this.tenantDateFormat
      ) +
      ' ' +
      this.convertBooksDateFormatToDateFnsFormat(
        toTimeFormat ? toTimeFormat : BOOKS_DATE_FORMAT['HH:MM A']
      );
    try {
      return format(dateStr, convertToFormat);
    } catch (e: any) {
      console.error('Error formatting Date: ', dateStr);
      return '';
    }
  }
  /**
   * Converts Date object to corresponding date string
   * @param date Date object to be formatted as string
   * @param toFormat Any one of the book's date formats
   * @returns formatted date string as per tenant's date format, or, a provided book's date format
   */
  static getDateStrFromDate(date: Date, toFormat?: BOOKS_DATE_FORMAT): string {
    try {
      return format(
        date,
        this.convertBooksDateFormatToDateFnsFormat(
          toFormat ? toFormat : this.tenantDateFormat
        )
      );
    } catch (e: any) {
      // console.error('Error formatting Date: ', date);
      return '';
    }
  }

  /**
   * Converts provided date string to Date object
   * @param dateStr Date string to be formatted
   * @param fromFormat Any one of the book's date formats
   * @returns date object corresponding to dateStr
   */
  static getDateFromStr(dateStr: string, fromFormat: BOOKS_DATE_FORMAT): Date {
    return parse(
      dateStr,
      this.convertBooksDateFormatToDateFnsFormat(fromFormat),
      new Date()
    );
  }
  static getDayDifference(bigger: Date, smaller: Date) {
    return bigger.getDate() - smaller.getDate();
  }

  static getDateFromISOStr(isoStr: string): Date {
    return parseISO(isoStr);
  }

  /**
   * Compares two dates
   * @param dateLeft Date object to be compared
   * @param dateRight Date object to be compared
   * @returns DATE_COMPARISON enum value
   */
  static compareDates(dateLeft: Date, dateRight: Date): DATE_COMPARISON {
    const result = compareAsc(dateLeft, dateRight);
    /*  1 if the dateLeft is after the second,
     * -1 if the dateLeft is before the second or
     *0 if dates are equal. */

    if (result === 0) {
      return DATE_COMPARISON.BOTH_DATES_ARE_EQUAL;
    } else if (result === 1) {
      return DATE_COMPARISON.LEFT_DATE_IS_AFTER_RIGHT_DATE;
    } else {
      return DATE_COMPARISON.LEFT_DATE_IS_BEFORE_RIGHT_DATE;
    }
  }

  static convertToDateTime = (isoString: any, customFormat: string) => {
    const date = parseISO(isoString);
    const formattedDate = format(date, customFormat);
    return formattedDate;
  };

  static getLastDateOfMonth = (month: any, year: any) => {
    var thisMonth = month; // January - 0
    var d = new Date(year, thisMonth + 1, 0);
    const thisDate = DateFormatService.getDateStrFromDate(
      d,
      BOOKS_DATE_FORMAT['D MMM YYYY']
    );

    return thisDate;
  };

  static convertDDMMYYYToDate(strDate: any) {
    let dateParts = strDate.split('-');
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  }

  static addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }
  static addMonths(date: Date, months: number) {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + months);
    return newDate;
  }
  static lessMonths(date: Date, months: number) {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() - months);
    return newDate;
  }
  /*
    month: Jan-> 1
  */
  static getFirstDayOfMonth(month: number, year: number): Date {
    const date = new Date(year, month - 1, 1);
    return date;
  }
  static getLastDayOfMonth(month: number, year: number): Date {
    const lastDay = new Date(year, month, 0);
    return lastDay;
  }

  static getDateInCountryFormat(dateString: string, targetTimeZone: string) {
    const date = parseISO(dateString);
    if (Utility.isEmpty(targetTimeZone)) {
      targetTimeZone = 'America/Chicago';
    }
    const zonedDate = utcToZonedTime(date, targetTimeZone);
    return zonedDate;
  }
}

export default DateFormatService;
