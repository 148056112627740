import React, { useRef } from 'react'
import { getLocalisedText } from '../Translate/LanguageManager'

export const DKContentEditableDivWithRef = (props) => {
    const defaultValue = useRef(props.value)
    return (
        <div
            contentEditable={props.disabled === undefined ? 'true' : props.disabled ? 'false' : 'true'}
            id='editable'
            spellCheck='false'
            onInput={(e) => {
                props.valueChangeCallback(e, props.valueStr)
            }}
            className={props.className}
            dangerouslySetInnerHTML={{ __html: getLocalisedText(defaultValue.current) }}
            style={props.style}
        />
    )
}


export const DKContentEditableDiv = (props) => {
    return (
        <div
            contentEditable={props.disabled === undefined ? 'true' : props.disabled ? 'false' : 'true'}
            id='editable'
            spellCheck='false'
            onInput={(e) => {
                props.valueChangeCallback(e, props.valueStr)
            }}
            className={props.className}
            dangerouslySetInnerHTML={{ __html: getLocalisedText(props.value) }}
            style={props.style}
        />
    )
}
