import {
  DKIcon,
  DKIcons,
  DKLabel,
  showToast,
  DKInput,
  INPUT_TYPE,
  DKDataGrid
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TRACKING_TYPE,
  QTY_ROUNDOFF_PRECISION,
  BOOKS_ADDRESS_TYPES,
  RECEIVED_GOODS_STATUS,
  COUNTRY_CODES,
  INPUT_VIEW_DIRECTION,
  STATUS_TYPE,
  CUSTOM_FIELD_TYPE,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../Redux/Hooks';
import {
  selectCustomFields,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';

import ic_delivery from '../../Assets/Icons/ic_delivery.png';
import { Address } from '../../Models/Address';

import { ReceivedGoodsRecordProps } from '../../Models/ReceivedGoods';

import DateFormatService from '../../Services/DateFormat';

import NewReportService, { NewReportConfig } from '../../Services/DebitReport';

import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import AttachmentService from '../../Services/Attachment';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import PopupWrapper from '../PopupWrapper';
import FulfillmentTable from '../FulfillmentPopup/FulFillmentTable';
import Utility from '../../Utility/Utility';
import { BtnType, PopupClickActionType } from '../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ContactService from '../../Services/Contact';
import { COLUMN_CONFIGS_FOR_FF_RECORD } from '../../Components/Mrp/Constants/MRPColumnConfigs';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';

const ReceivedGoodsRecordDetails: React.FC<ReceivedGoodsRecordProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const [res, setRes] = useState<any>({});
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [isSerail, setIsSerial] = useState<any>(false);
  const [trackingType, setTrackingType] = useState<any>('');

  const [receivedGoodsDetails, setReceivedGoodsDetails] = useState<any>(
    props.receivedGoodsDetails
  );
  const [receivedGoodsData, setReceivedGoodsData] = useState<any>(
    receivedGoodsDetails[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [documentItems, setDocumentItems] = useState<any>(
    props.documentType === DOC_TYPE.BILL
      ? documentDetails?.purchaseInvoiceProducts
      : props.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ? documentDetails?.jobWorkOutOrderItems
      : documentDetails?.purchaseOrderItems
  );

  const [shipToAddress, setShipToAddress] = useState(
    new Address(props.receivedGoodsDetails[0]?.shipTo)
  );

  const [shipFromAddress, setShipFromAddress] = useState(
    new Address(props.receivedGoodsDetails[0]?.shipFrom)
  );
  let module = MODULES_NAME.ORDER;
  if (
    props.documentDetails?.documentType === 'PURCHASE_INVOICE' ||
    receivedGoodsData?.documentType === 'PURCHASE_INVOICE'
  ) {
    module = MODULES_NAME.BILL;
  }

  if (
    props.documentDetails?.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
    props?.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
  ) {
    module = MODULES_NAME.JOB_WORK_OUT;
  }

  const [customField, setCustomField] = useState<any>(null);

  const lineItemsTable = useRef(null);
  const uoms = useAppSelector(selectUOMs);
  const [itemDetails, setItemDetails] = useState<any>();
  const [attachments, setAttachments] = useState<any[]>([]);

  const [popupWidth, setPopupWidth] = useState(30);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [contactDetails, setContactDetails] = useState<any>(null);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let popupWidthCopy = popupWidth;
      tenantInfo?.additionalSettings?.ROW_RACK_BIN?.forEach((element: any) => {
        if (element.enable) {
          popupWidthCopy = popupWidthCopy + 10;
        }
      });
      setPopupWidth(popupWidthCopy);
    }
  }, [tenantInfo]);

  const isPOWithTolerance =
    props.isToleranceApprovalFlow &&
    Utility.isToleranceSettingsEnabled() &&
    props.documentType === DOC_TYPE.ORDER;

  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [columnConfig, setColumnConfig] = useState<any>(
    COLUMN_CONFIGS_FOR_FF_RECORD
  );
  const [gridRowData, setGridRowData] = useState<any>([]);
  useEffect(() => {
    if (props.receivedGoodsDetails) {
      setUpInitialState();
    }
    setDocumentDetails(props.documentDetails);
    setShipToAddress(new Address(props.receivedGoodsDetails[0]?.shipTo));
    setShipFromAddress(new Address(props.receivedGoodsDetails[0]?.shipFrom));
  }, [props]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.RECEIVED_GOODS_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    let newCols = getUpdatedColumnConfigs([...columnConfig]);
    let productCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );

    productCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      newItem = { ...newItem, editable: false };
      const newItemInExistingColConfig = newCols.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newCols.push({ ...newItem });
      }
    });
    const approvalCols = [
      {
        id: 'approved',
        key: 'approved',
        name: 'Approved',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'left'
      },
      {
        id: 'rejected',
        key: 'rejected',
        name: 'Rejected',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'left'
      }
    ];
    const approvedExistingColConfig = newCols.find(
      (config: any) => config.code === 'approved' || config.code === 'rejected'
    );
    if (
      Utility.isEmpty(approvedExistingColConfig) &&
      props.isToleranceApprovalFlow
    ) {
      newCols = [...newCols, ...approvalCols];
    }
    newCols = newCols.filter((col: any) => !col.hidden);
    setColumnConfig([...newCols]);
  }, [selectCustomFieldsData]);

  useEffect(() => {
    if (tenantInfo.country === COUNTRY_CODES.IL) {
      fetchContactInfo();
    }
  }, []);

  const getUpdatedColumnConfigs = (configs: any[]) => {
    configs = configs.map((config: any) => {
      switch (config.key) {
        case 'products':
          config.renderer = ({ rowData }: any) => {
            return (
              <div className="column parent-width">
                <div className="row parent-width">
                  <DKLabel text={rowData?.products || ''} className="fs-m" />
                </div>
                <div className="row">
                  <DKLabel
                    text={rowData?.documentSequenceCode || ''}
                    className="text-gray fs-s"
                  />
                </div>
              </div>
            );
          };
          break;
      }
      return config;
    });

    return configs;
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const fetchContactInfo = () => {
    ContactService.getContactDetailsById(
      props.documentDetails?.contactDto?.id
    ).then((res: any) => {
      if (res) {
        setContactDetails(res);
      }
    });
  };
  const setUpInitialState = () => {
    setReceivedGoodsDetails(props.receivedGoodsDetails);
    const goodsData = props.receivedGoodsDetails?.[0];
    setReceivedGoodsData(goodsData);
    setCustomField(goodsData?.customField || []);
    const entityId = goodsData?.id || goodsData?.entityId;
    setGridRowData(getRowData(props?.receivedGoodsDetails?.[0]?.items));
    fetchAttachments(entityId);
  };

  const getRowData = (fulfillmentItems: any) => {
    if (Utility.isEmpty(fulfillmentItems)) {
      return [];
    }

    let rowData: any = [];

    fulfillmentItems &&
      fulfillmentItems?.forEach((item: any, index: number) => {
        let rowObj: any = {
          products: item.productName,
          description: getProductDescriptions(item),
          uom: getSelectedUomValue(item.documentUom),
          required: getProductQty(item, index),
          committing: getReceiveddQty(item, index),
          documentSequenceCode: item?.documentSequenceCode || ''
          //receiving: quantityToFulfill[index],
          // advancedTracking: item.product.advancedTracking
        };
        if (props.isToleranceApprovalFlow) {
          rowObj = {
            ...rowObj,
            approved: getApprovedQty(item),
            rejected: getRejectedQty(item)
          };
        }
        if (!Utility.isEmpty(item.customField)) {
          item.customField.forEach((item1: any) => {
            let filteredCF: any = selectCustomFieldsData?.content?.find(
              (cf: any) => cf.code === item1.code
            );
            if (!Utility.isEmpty(filteredCF)) {
              let cfValue;
              if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                cfValue = DateFormatService.getDateFromStr(
                  item1.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.code === item1.value
                );
                if (tempCF) {
                  cfValue = tempCF ? tempCF : '';
                }
              } else if (
                filteredCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                cfValue = item1 ? item1 : '';
              } else {
                cfValue = item1.value ? item1.value : '';
              }
              rowObj[item1.id] = cfValue;
            }
          });
        }
        rowData.push(rowObj);
      });

    return rowData;
  };

  const getProductQty = (item: any, i: number) => {
    let quantity;
    if (item.documentUOMSchemaDefinition) {
      quantity = item.uomPendingQuantity + item.uomReceiptQuantity;
    } else {
      quantity = item.pendingQuantity + item.receiptQuantity;
    }
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const getReceiveddQty = (item: any, i: number) => {
    let quantity;
    if (item.documentUOMSchemaDefinition) {
      quantity = item.uomReceiptQuantity ? item.uomReceiptQuantity : 0;
    } else {
      quantity = item.receiptQuantity ? item.receiptQuantity : 0;
    }
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const getSelectedUomValue = (id: number) => {
    let filteredUOM =
      uoms && uoms.length > 0 ? uoms.find((uom) => uom.id === id) : null;
    return filteredUOM?.name || '';
  };

  const getProductDescriptions = (item: any) => {
    let productDetail = documentItems?.find(
      (docItem: any) => docItem.productCode === item.productCode
    );
    return productDetail ? productDetail.productDescription : '';
  };

  const advanceTrackingExists = () => {
    return (
      receivedGoodsData?.items?.filter(
        (item: any) => item.advancedTrackingType !== TRACKING_TYPE.NONE
      )?.length > 0
    );
  };

  const getApprovedQty = (item: any) => {
    let quantity;
    quantity = item.acceptedQty ? item.acceptedQty : 0;
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const getRejectedQty = (item: any) => {
    let quantity;
    quantity = item.rejectedQty ? item.rejectedQty : 0;
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const showCustomFieldView = () => {
    const customFieldRows = Math.ceil((customField?.length || 0) / 4);
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          key={receivedGoodsData?.id}
          moduleName={module}
          customFieldsList={customField}
          columnConfig={[]}
          style={{
            /* row height + gap */
            minHeight:
              customFieldRows * 56 +
              (customFieldRows ? customFieldRows - 1 : customFieldRows) * 12
          }}
          documentMode={DOCUMENT_MODE.VIEW}
          columnConfigTableId={''}
          onUpdate={(list: any) => {}}
        />
      </div>
    );
  };

  const fetchAttachments = (entityId: any) => {
    let moduleType = DOC_TYPE.GOODS_RECEIPT;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
    });
  };
  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>
            {!props.isToleranceApprovalFlow && (
              <DKIcon
                src={DKIcons.ic_delete}
                className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
                onClick={() => removeAttachment(attachment.attachmentId)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(attachmentForListing);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  const popupBtnConfig: BtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const onPaginationClick = (pageNo: number = 0) => {
    const config: NewReportConfig = {
      ...NewReportService.apiConfig,
      Page: pageNo - 1,
      Limit: 10
    };
    NewReportService.apiConfig = config;
    NewReportService.getGR(itemDetails.goods_receipt_item_code).then(
      (res: any) => {
        setRes(res);
      }
    );
  };

  const getContactAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = receivedGoodsData?.shipFrom?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_TO) {
      addressCustomFields = receivedGoodsData?.shipTo?.customFields;
    }
    return Utility.isNotEmpty(addressCustomFields) ? (
      <div
        className={`${addressCustomFields?.length ? 'mb-r mr-l' : ''}`}
        style={{
          marginLeft: addressCustomFields?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={[]}
          columnConfigTableId={''}
          documentMode={DOCUMENT_MODE.VIEW}
          onUpdate={async (updatedCFList) => {}}
          onLocationUpdate={(loc: any) => {}}
          contact={documentDetails?.contact}
          hideAddfieldButton={true}
          addressUpdate={true}
          updateAddressField={() => {}}
          valueToTriggerLocalChange={props?.selectedTabIndex}
        />
      </div>
    ) : null;
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {
          <>
            <div className="row">
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Payer Id"
                  value={contactDetails?.taxPayerIdIsrael || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word',
                  marginLeft: '10px'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Registration Number"
                  value={contactDetails?.taxNumber || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        }
      </>
    );
  };
  const getButtonsForRow = (data: any, index: any) => {
    let buttons: any[] = [];
    const item = receivedGoodsData.items
      ? receivedGoodsData.items[index]
      : null;
    if (
      // item.advancedTrackingType !== TRACKING_TYPE.NONE &&
      !isPOWithTolerance &&
      item?.productType !== PRODUCT_TYPE.NON_TRACKED
    ) {
      buttons.push({
        icon: ic_barcode_green,

        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          const item = receivedGoodsData.items[rowIndex];
          setItemDetails(item);
          if (item.advancedTrackingType === 'BATCH') {
            setIsSerial(false);
            setTrackingType('BATCH');
          }
          if (item.advancedTrackingType === 'SERIAL') {
            setIsSerial(true);
            setTrackingType('SERIAL');
          }
          if (item.advancedTrackingType === 'NONE') {
            setTrackingType('NONE');
            setShowPopup(true);
          }
          if (item.advancedTrackingType !== TRACKING_TYPE.NONE) {
            const config: NewReportConfig = {
              ...NewReportService.apiConfig,
              Page: 0,
              Limit: 10
            };
            NewReportService.apiConfig = config;
            NewReportService.getGR(item.goods_receipt_item_code).then(
              (res: any) => {
                setRes(res);
              }
            );
            setShowPopup(true);
          }
        }
      });
    }

    return buttons;
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={gridRowData?.map((item: any, index: any) => {
          return {
            ...item,
            rowButtons: getButtonsForRow(item, index)
          };
        })}
        columns={[
          ...columnConfig,
          {
            key: 'actions',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            actionButtonOptions: [],
            width: 180
          }
        ]}
        onRowUpdate={() => {}}
        onRowClick={({ columnData, rowIndex }: any) => {
          // if (props.fulfillmentItems) {
          // }
          // updateConfig(props.fulfillmentItems[rowIndex]);
        }}
      />
    );
  };

  return (
    <div
      className={`flex parent-width justify-content-between p-4 ${
        props.className || ''
      }`}
    >
      {receivedGoodsData && (
        <div className="flex flex-col parent-width">
          <div className="flex row-responsive justify-content-between w-full">
            {/* ship to */}
            <div className="flex flex-col w-100 mr-r">
              <div className="flex align-items-center label">
                <DKIcon
                  src={ic_delivery}
                  className="ic-r ic-s"
                  style={{ opacity: 0.8 }}
                />
                <span className="fs-r pl-2 font-medium">Ship To</span>
              </div>
              <div
                className="flex flex-col value pt-1"
                style={{ minHeight: 100 }}
              >
                <span className="fs-r address1">{shipToAddress.address1}</span>
                <span className="fs-r address2">{shipToAddress.address2}</span>
                <span className="fs-r city">{shipToAddress.city}</span>
                <span className="fs-r city">{shipToAddress.state}</span>
                <span className="fs-r postal">
                  {shipToAddress.country} {shipToAddress.postalCode}
                </span>
              </div>
              {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_TO)}
            </div>
            {/* ship from */}
            <div className="flex flex-col w-100 mr-r mobile-mt-m">
              <div className="flex align-items-center label">
                <span className="fs-r font-medium">Ship From</span>
              </div>
              <div
                className="flex flex-col value pt-1"
                style={{ minHeight: 100 }}
              >
                <span className="fs-r address1">
                  {shipFromAddress.address1}
                </span>
                <span className="fs-r address2">
                  {shipFromAddress.address2}
                </span>
                <span className="fs-r city">{shipFromAddress.city}</span>
                <span className="fs-r city">{shipFromAddress.state}</span>
                <span className="fs-r postal">
                  {shipFromAddress.country} {shipFromAddress.postalCode}
                </span>
              </div>
              {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
            </div>
            {/* receipt date */}
            <div className="flex flex-col w-48 mobile-mt-m">
              <span className="text-black fs-r ml-0 font-medium">
                Receipt Date
              </span>
              <div className="position-relative pt-2">
                {DateFormatService.getFormattedDateString(
                  receivedGoodsData.receiptDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}
              </div>
            </div>
          </div>
          {tenantInfo.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
          {customField && customField.length > 0 && showCustomFieldView()}
          <div
            className="flex justify-content-between invoice-table hide-scroll-bar column"
            style={{
              position: 'relative',
              top: '30px',
              overflowX: 'scroll'
            }}
          >
            {getDataGrid()}
            <div className="row">{getAttachments()}</div>
          </div>
        </div>
      )}
      {showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={
            trackingType === 'NONE'
              ? 'None Tracked Received Records'
              : isSerail
              ? 'Serial-Goods Received Records'
              : 'Batch-Goods Received Records'
          }
          btnList={popupBtnConfig}
          width={`${'60%'}`}
          height={!Utility.isEmptyObject(res) ? 'auto' : 350}
          disableClickOutside={true}
        >
          <FulfillmentTable
            populateFormData={res}
            width={'100%'}
            isSerial={isSerail}
            trackingType={trackingType}
            module={'BUY'}
            itemData={itemDetails}
            totalPageCount={res.totalPages || 0}
            currentPage={res?.pageable?.pageNumber + 1 || 1}
            onPaginationClick={(pageNo: number) => onPaginationClick(pageNo)}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default ReceivedGoodsRecordDetails;
