import { IState } from '../../../Models/Interfaces';

export const workStationInitialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

interface FORM_INPUT {
  hidden: boolean;
  value: any;
  hasError: boolean;
  isMandatory: boolean;
  errorMsg: string;
}

interface FORM_SELECT {
  options: any[];
  hidden: boolean;
  value: any;
  hasError: boolean;
  isMandatory: boolean;
  errorMsg: string;
}

export enum WorkStationFormStateFields {
  WORK_STATION_NAME = 'workstationName',
  PRODUCTION_CAPACITY = 'productionCapacity',
  ELECTRICITY_COST = 'electricityCost',
  RENT_COST = 'rentCost',
  CONSUMABLE_COST = 'consumableCost',
  WAGES = 'wages',
  HOLIDAY_LIST = 'holidayList',
  DESCRIPTION = 'description'
}

export interface WorkStationFormState {
  [WorkStationFormStateFields.WORK_STATION_NAME]: FORM_INPUT;
  [WorkStationFormStateFields.PRODUCTION_CAPACITY]: FORM_INPUT;
  [WorkStationFormStateFields.ELECTRICITY_COST]: FORM_INPUT;
  [WorkStationFormStateFields.RENT_COST]: FORM_INPUT;
  [WorkStationFormStateFields.CONSUMABLE_COST]: FORM_INPUT;
  [WorkStationFormStateFields.WAGES]: FORM_INPUT;
  [WorkStationFormStateFields.HOLIDAY_LIST]: FORM_INPUT;
  [WorkStationFormStateFields.DESCRIPTION]: FORM_INPUT;
}

export const WorkStationInitialFormState: WorkStationFormState = {
  [WorkStationFormStateFields.WORK_STATION_NAME]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: true,
    errorMsg: 'Please enter worksstation name'
  },
  [WorkStationFormStateFields.PRODUCTION_CAPACITY]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: true,
    errorMsg: 'Please enter production capacity'
  },
  [WorkStationFormStateFields.ELECTRICITY_COST]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: false,
    errorMsg: ''
  },
  [WorkStationFormStateFields.RENT_COST]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: false,
    errorMsg: ''
  },
  [WorkStationFormStateFields.CONSUMABLE_COST]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: false,
    errorMsg: ''
  },
  [WorkStationFormStateFields.WAGES]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: false,
    errorMsg: ''
  },
  [WorkStationFormStateFields.HOLIDAY_LIST]: {
    hidden: false,
    value: null,
    hasError: false,
    isMandatory: false,
    errorMsg: 'Please select holiday list'
  },
  [WorkStationFormStateFields.DESCRIPTION]: {
    hidden: false,
    value: '',
    hasError: false,
    isMandatory: false,
    errorMsg: ''
  }
};
