import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

/** @deprecated  */
export const fetchCommonProductInventory = createAsyncThunk(
  'commonWarehouseProduct',
  async () => {
    const response = await ProductService.fetchWarehouseProducts();
    return response;
  }
);
export const CommonWarehouseProductsSlice = createSlice({
  name: 'commonWarehouseProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonProductInventory.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCommonProductInventory.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload['warehouses'];
      });
  }
});

/** @deprecated  */
export const selectCommonWarehouseProducts = (state: RootState) =>
  state.commonWarehouseProducts.data;

export const selectWarehouseProductsLoadingStatus = (state: RootState) =>
  state.commonWarehouseProducts.status;

export const selectWarehouseProductsColumnConfig = (state: RootState) =>
  state.commonWarehouseProducts.columnConfig;

export const selectWarehouseProductsColumnConfigTableId = (state: RootState) =>
  state.commonWarehouseProducts.configTableId;

export default CommonWarehouseProductsSlice.reducer;
