import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_STATUS } from "../../Constants/Constant";
import { COLUMN_CODE } from "../../Constants/TableConstants";
import { GridState } from "../../Models/ReduxStore";
import { IColumn } from "../../Models/Table";
import GoodsReceiptMasterService from "../../Services/GoodsReceiptMasterService";
import { RootState } from "../Store";


const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  content: [] as any,
  
};

const hideGoodsReceiptMasterColumnConfig = [
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.GRN_JE_NUMBER,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.VENDOR_CODE,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.VENDOR_NAME,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.TOTAL_AMOUNT_GR,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.SUPPLIER_INVOICE_NO,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.QC_STATUS,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.APPROVAL_STATUS,
    COLUMN_CODE.BOOKS_GOODS_RECEIPT_MASTER.MEMO,
  ];

export const fetchGoodsReceipts = createAsyncThunk('fetchGoodsReceipts', async () => {
  const response = await GoodsReceiptMasterService.getGoodsReceivedMasterList();
  return response;
});

export const GoodsReceiptSlice = createSlice({
  name: "Goods_receipt",
  initialState,
  reducers: {
    addGoodsReceiptColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        const includes = hideGoodsReceiptMasterColumnConfig.includes(config.columnCode);
        if(!includes) {
          configsToStore.push({
            ...config,
            key: config.columnCode,
            options: config.options ? config.options : []
          });
        }
      });
      state.columnConfig = configsToStore;
    },
    addGoodsReceipt: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoodsReceipts.pending, (state) => {
        state.status = API_STATUS.LOADING
      })
      .addCase(fetchGoodsReceipts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
  }
});

export const {addGoodsReceipt, addGoodsReceiptColumnConfig} = GoodsReceiptSlice.actions;

export const selectGoodsReceiptMasterData = (state: RootState) => state.goodsReceipt;