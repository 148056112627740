import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

// import {StyleSheet} from "react";

export default class DocumentTemplate35 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale)}}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    {this.getHeaderSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(12.5)}
                    {Utility.getIsContainsCustomField('top') &&
                        <>
                            {ComponentManager.addDivider({
                                backgroundColor: this.state.data.themeColor,
                                height: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                                width: '100%'
                            })}
                            {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(12.5)}
                            <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                        </>
                    }
                    {ComponentManager.addVerticalSpace(12.5)}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(50, this.state.data.printScale))}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%', marginTop: this.getIsContainStockItem() ? 0 : getVW(Utility.convertToPrintScale(-200, this.state.data.printScale)) }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) || (this.state.data.showNotes && this.state.data.notes)) && this.getFooterSection()}
                </div>
                {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(12.5)}
                {Utility.getIsContainsCustomField('bottom') &&
                    <>
                        {ComponentManager.addDivider({
                            backgroundColor: this.state.data.themeColor,
                            height: getVW(2),
                            width: '100%'
                        })}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(12.5)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    </>
                }
                {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                {this.state.data.showEInvoiceSection &&
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                }
                {ComponentManager.addDivider({
                    backgroundColor: this.state.data.themeColor,
                    height: getVW(2),
                    width: '100%'
                })}
                {ComponentManager.addVerticalSpace(10)}
                <div className="RowDiv" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: "#000000",
                            ...Utility.getFontStyleProp({fontSize: FONT_SIZE.xxLarge}, this.state.data.printScale),
                        }}>
                        {getCapitalized(getLocalisedText(this.state.data.documentType))}</text>
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(60, this.state.data.printScale), {position: 'relative'})}
                </div>
                {this.state.data.showCompanyName && this.getCompanyName()}
                <div className="RowDiv"
                    style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div className="RowDiv" style={{ width: '33%' }}>
                        {this.state.data.showFrom && this.getCompanyAddress()}
                    </div>
                    <div className="ColumnDiv" style={{ width: '33%' }}>
                        {this.getDocumentDetailsSection()}
                    </div>
                    <div className="ColumnDiv" style={{ width: '33%' }}>
                        {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                        {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && ComponentManager.addVerticalSpace(10)}
                        {this.state.data.showClientShipToAddress && this.getShippingAddress()}
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(10, this.state.data.printScale))}
                        {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                        {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                        {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupply()}
                    </div>
                </div>
                {(this.state.data.showTermsAndCondition && this.state.data.showTermsAndCondition) && ComponentManager.addVerticalSpace(10)}
                <div className="RowDiv" style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <div className="ColumnDiv" style={{
                        width: '33%', borderLeftColor: '#000000',
                        borderLeftWidth: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)), borderLeftStyle: 'solid', paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
                    }}>

                    </div>
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if(element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return <div className="RowDiv" style={{ alignItems: 'flex-start' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                    width: '50%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    borderLeftColor: '#000000',
                    borderLeftWidth: getVW(2),
                    borderLeftStyle: 'solid',
                    paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                }}>{element.label}</text>
                <text className={"docFontStyle" + getFontSizeClass()} style={{
                    width: '50%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    color: 'gray',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                }}>{value}</text>
            </div>;
        });
    }

    getShippingAddress() {
        return <div className='ColumnDiv'
            style={{
                borderLeftStyle: 'solid',
                borderLeftColor: '#000000',
                borderLeftWidth: getVW(2),
                paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                width: Utility.getIsArabicLang() ? '100%' : 'auto',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    width: '100%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}>{getLocalisedText(this.state.data.shipToTitle).toUpperCase()}
            </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '100%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    color: 'gray',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                }}
                dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getClientShipToAddress(this.state.data)) }}
            >
            </div>
        </div>;
    }

    getBillingAddress() {
        return <div className='ColumnDiv'
            style={{
                borderLeftStyle: 'solid',
                borderLeftColor: '#000000',
                borderLeftWidth: getVW(2),
                paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                width: Utility.getIsArabicLang() ? '100%' : 'auto',
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    width: '100%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    marginRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }}>{getLocalisedText(this.state.data.billToTitle).toUpperCase()}
            </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '100%',
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                    color: 'gray',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                }}
                dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getClientBillToAddress(this.state.data)) }}
            ></div>
        </div>;
    }

    getCompanyAddress() {
        return <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: "gray",
                        width: '100%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from)}, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
        </div>;
    }

    getCompanyName() {
        return <>
            <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                width: '33%',
                color: '#000000',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
            }}>{getLocalisedText(Utility.getCompanyName(this.state.data))}</text>
            {ComponentManager.addVerticalSpace(5)}
        </>;
    }

    /////// Table Section ///////////
    getTableSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-end' }}>
                <div style={{ width: '90%' }}>
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {
                        Utility.getIsShowAccountProductTable(this.state.data) && 
                        <div style={{
                            // paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            // paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                            // marginLeft: getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                            width: '100%'
                        }}>
                            <div style={{ position: "relative", width: "100%", height: getVW(2), backgroundColor: this.state.data.themeColor }} />
                            <div>
                                {this.getProductTable()}
                                {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(10)}
                            </div>
                        </div>
                    }
                    <div style={{
                        // paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        // paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        paddingTop: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)),
                        // marginLeft: getVW(`${Utility.convertToPrintScale(Number(NEGATIVE_PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        width: '100%'
                    }}>
                        <div style={{ position: "relative", width: "100%", height: getVW(2), backgroundColor: this.state.data.themeColor }} />
                        <div>
                            {this.getEditableDocumentTable()}
                            <div className="ColumnDiv" style={{ alignItems: 'flex-end' }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getHeightForFooterLine() {
        if (getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) {
            return undefined
        }

        return getVW(2)
    }

    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{
                bottom: getVW(0), width: '100%', justifyContent: 'center', alignItems: "flex-start", borderLeftColor: '#000000',
                borderLeftWidth: getVW(2), borderLeftStyle: 'solid', paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
            }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "50%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "50%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div>)
    }

    getIsContainStockItem() {
        if (TemplateSettingsManager.defaultTemplateSettings.additionalTable !== undefined && TemplateSettingsManager.defaultTemplateSettings.additionalTable !== null) {
            var additionalTable = TemplateSettingsManager.defaultTemplateSettings.additionalTable
            if (additionalTable.isVisible !== undefined && additionalTable.isVisible !== null) {
                if (additionalTable.isVisible) {
                    return additionalTable.isVisible
                }
            }
        }
        if (this.state.data.showEInvoiceSection && TemplateSettingsManager.getEInvoiceSectionPosition() === eInvoiceSectionPosition.bottom) {
            return true
        }
        if (getIsGoodsReceipt(this.state.data.type) || getIsFulfillment(this.state.data.type)) {
            return true
        }
        if (getIsJournalEntry(this.state.data.type)) {
            return true
        }
        if (this.state.data.stockItems === undefined || this.state.data.stockItems === null) {
            return false
        }
        if (this.state.data.stockItems.length === 0) {
            return false
        }
        return true
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = '#000000';
        style.footerTextColor = '#000000';
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        style.lastRowTopColor = this.state.data.themeColor;
        style.lastRowTopWidth = getVW(2.5)//  this.getHeightForFooterLine()
        style.lastRowBottomColor = this.state.data.themeColor;
        style.lastRowBottomWidth = this.getHeightForFooterLine()
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['33.5%', '33.5%', '33%'] : ['33.5%', '33.5%', '33%']
        return style;
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        // return <div className='RowDiv' style={{ width: '70%' }}>
        //     <div className='ColumnDiv' style={{
        //         width: '40%'
        //     }}>
        //         {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
        //             getLocalisedText('place_of_supply'),
        //             this.state.data.placeOfSupply,
        //             "100%",
        //             this.state.data.themeColor,
        //             false,
        //             '#000000',
        //             getVW(6),
        //             true
        //         )}
        //     </div>

        // </div>;

        return <div className='ColumnDiv'
        style={{
            borderLeftStyle: 'solid',
            borderLeftColor: '#000000',
            borderLeftWidth: getVW(2),
            paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(5),
            width: Utility.getIsArabicLang() ? '100%' : 'auto',
        }}>
        <text className={"docFontStyleBold" + getFontSizeClass()}
            style={{
                width: '100%',
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                marginRight: getVW(10),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
            }}>{getLocalisedText('place_of_supply').toUpperCase()}
        </text>
        <div className={"docFontStyle" + getFontSizeClass()}
            style={{
                width: '100%',
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                color: 'gray',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
            }}
            dangerouslySetInnerHTML={{ __html: this.state.data.placeOfSupply }}
        >
        </div>
    </div>;
    }

    getSourceOfSupply() {
        return <div className='ColumnDiv' style={{ width: '70%' }}>
            {Utility.getIsShowSourceOfSupply(this.state.data) &&
                <>
                {ComponentManager.addVerticalSpace(10)}
                <div className='ColumnDiv'
                    style={{
                        borderLeftStyle: 'solid',
                        borderLeftColor: '#000000',
                        borderLeftWidth: getVW(2),
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(5),
                        width: Utility.getIsArabicLang() ? '100%' : 'auto',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                            marginRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                        }}>{getLocalisedText('source_of_supply').toUpperCase()}
                    </text>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                            paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                            color: 'gray',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}
                    >
                    </div>
                </div>
                </>
            }

            {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                <>
                {ComponentManager.addVerticalSpace(10)}
                <div className='ColumnDiv'
                    style={{
                        borderLeftStyle: 'solid',
                        borderLeftColor: '#000000',
                        borderLeftWidth: getVW(2),
                        paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(5),
                        width: Utility.getIsArabicLang() ? '100%' : 'auto',
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                            marginRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.shipTo) }, this.state.data.printScale)
                        }}>{getLocalisedText('destination_of_supply').toUpperCase()}
                    </text>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                            paddingRight: Utility.getIsArabicLang() ? getVW(10) : 0,
                            color: 'gray',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}
                    >
                    </div>
                </div>
                </>
            }
        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(2, data.printScale)), width: '100%' })}
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                    {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(2, data.printScale)), width: '100%' })}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const invoiceDueSummaryData = data?.invoiceDueSummaryData ?? []

        if (invoiceDueSummaryData.length > 0) {
            return (
                <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                    <div style={{ width: "65%" }}>
                        {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(2, data.printScale)), width: '100%' })}
                        {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                        {ComponentManager.addDivider({ backgroundColor: data.themeColor, height: getVW(Utility.convertToPrintScale(2, data.printScale)), width: '100%' })}
                    </div>
                </div>
            );
        }
    }
}
