import { TableColumnPopupType } from "../../../Constants/Constants";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";
export class SOAVendorTableItem {
    lineNumber: number;
    documentDate: string;
    documentNumber: string;
    particular: string;
    documentType: string;
    credit: number;
    debit: number;
    amountInBase: number;
    balanceInBase: number;
    currency: string;
    memo: string;

    data: any;
    rawItemValue: any;

    constructor(lineItem: any, data: any, currency: string) {
        this.lineNumber = lineItem.lineNumber
        this.documentDate = this.getDocumentDate(lineItem, data)
        this.documentNumber = lineItem.documentNumber
        this.particular = lineItem.particular
        this.documentType = lineItem.documentType
        this.credit = lineItem.credit ?? 0
        this.debit = lineItem.debit ?? 0
        this.amountInBase = lineItem.amountInBase ?? 0
        this.balanceInBase = lineItem.balanceInBase ?? 0
        this.memo = lineItem.memo
        this.currency = currency

        this.data = data
        this.rawItemValue = this.lineNumber
    }

    getDocumentDate(lineItem: any, data: any) {
        var date = ''
        if (lineItem !== undefined && lineItem !== null) {
            if (lineItem.documentDate !== undefined && lineItem.documentDate !== null) {
                date = lineItem.documentDate
            }
        }
        return date
    }

    toString(tableConfig?: any) {
        var sortedHeader = getSortedHeader(tableConfig);
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                var footerList = TableFooterUtility.getFooterListByType('soa')
                if (!footerList.includes(element.type)) {
                    sortedValue.push(this.getValueByType(element))
                }
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber.toFixed()
            case TableColumnPopupType.documentDate:
                return this.documentDate ?? ''
            case TableColumnPopupType.documentNumber:
                return this.documentNumber ?? ''
            case TableColumnPopupType.particular:
                return this.particular ?? ''
            case TableColumnPopupType.documentType:
                return this.documentType ?? ''
            case TableColumnPopupType.credit:
                return Utility.toCurrencyFormat(this.credit * 1, this.currency)
            case TableColumnPopupType.debit:
                return Utility.toCurrencyFormat(this.debit * 1, this.currency)
            case TableColumnPopupType.amountInBase:
                return Utility.toCurrencyFormat(this.amountInBase * 1, this.currency)
            case TableColumnPopupType.balanceInBase:
                return Utility.toCurrencyFormat(this.balanceInBase * 1, this.currency)
            case TableColumnPopupType.currency:
                return this.currency ?? ''
            case TableColumnPopupType.memo:
                return this.memo ?? ''
            
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.lineNumber.toString(),
            this.documentDate,
            this.documentNumber,
            this.particular,
            Utility.toCurrencyFormat(this.credit * 1, this.currency),
            Utility.toCurrencyFormat(this.debit * 1, this.currency),
            Utility.toCurrencyFormat(this.amountInBase * 1, this.currency),
            Utility.toCurrencyFormat(this.balanceInBase * 1, this.currency),
            this.currency,
            this.memo
        ]
    }
}
