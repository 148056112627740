import React, { useState } from 'react';
import TenantService from '../../../Services/Tenant';
import {
  DKLabel,
  DKIcon,
  DKButton,
  DKSpinner,
  DKSearchBar
} from 'deskera-ui-library';
import Utility from '../../../Utility/Utility';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import { POPUP_CLICK_TYPE, POPUP_TYPE } from '../../../Constants/Constant';
import { BtnType, PopupClickActionType } from '../../../Models/Interfaces';
import SubscriptionDetailPopup from './SubscriptionDetailPopup';
interface ISubscriptionTabProps {
  loading: boolean;
  subscriptionData: any;
  contact: any;
}

const popupBtnConfigForOpen: BtnType[] = [
  {
    title: 'Close',
    class: 'bg-gray1 border-m',
    clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
  }
];

const SubscriptionTab: React.FC<ISubscriptionTabProps> = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const getSubscriptionPlan = (tenantId: string) => {
    setLoading(true);
    TenantService.getTenantsSubscriptionPlan(tenantId)
      .then((res: any) => {
        if (res) {
          let allTenants: any = [...props.subscriptionData];
          let findIndex = allTenants.findIndex(
            (tenant: any) => tenant.TenantID + '' === tenantId
          );
          if (findIndex !== -1) {
            setSubscriptionDetails({
              ...allTenants[findIndex],
              planDetails: res
            });
          }
          setLoading(false);
          setShowPopup(true);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getCard = (data: any) => {
    return (
      <div className="column bg-white parent-width">
        <div
          className="row justify-content-between p-h-m p-v-s cursor-hand border-b"
          onClick={() => getSubscriptionPlan(data.TenantID + '')}
        >
          <DKLabel
            text={data.TenantName}
            className="text-trailing"
            style={{
              width: 400,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          />
          <DKButton
            title="View Details"
            className="border-none text-blue text-underline"
            onClick={() => {
              // getSubscriptionPlan(data.TenantID + '');
            }}
          />
        </div>
        {/* {data.isExpanded && (
          <div className="column gap-1 parent-width">
            <div className="row justify-content-between p-h-l mt-s">
              <DKLabel text={'Plan:'} className="fw-m" />
              <DKLabel text={data?.planName} className="" />
            </div>
            <div className="row justify-content-between p-h-l">
              <DKLabel text={'Trial:'} className="fw-m" />
              <DKLabel text={'Not Available'} className="" />
            </div>
            <div className="row justify-content-between p-h-l pb-m">
              <DKLabel text={'Next Charge Date:'} className="fw-m" />
              <DKLabel
                text={DateFormatService.getDateStrFromDate(
                  new Date(data?.chargeDate)
                )}
                className=""
              />
            </div>
          </div>
        )} */}
      </div>
    );
  };

  const getNoDataView = (isLoading = false) => {
    return (
      <div
        className={`column parent-size align-items-center justify-content-center mb-xl`}
        style={{ pointerEvents: 'none', minHeight: 400 }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel
          className="fs-m fw-m mt-l"
          text={isLoading ? 'Loading...' : 'No subscriptions found'}
        />
        {isLoading ? null : (
          <DKLabel
            className="text-dark-gray mt-s text-align-center"
            text={'No subscriptions available with current contact.'}
          />
        )}
      </div>
    );
  };

  const filterSearchData = (data: any) => {
    if (Utility.isEmptyObject(searchText)) return data;
    let filterData = data.filter((item: any) =>
      item.TenantName?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    return filterData;
  };

  const renderTenantsCard = () => {
    return filterSearchData(props.subscriptionData).map((tenant: any) =>
      getCard(tenant)
    );
  };

  const renderHeader = () => {
    return (
      <div className="column parent-width">
        <div className="row justify-content-between p-h-l p-v-l cursor-hand border-b">
          <DKLabel text={'Organization name'} className="fw-m text-trailing" />
          <DKLabel
            text={'Action'}
            className="fw-m text-trailing text-align-right"
          />
        </div>
      </div>
    );
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  return (
    <div
      className="column align-self-center mobile-max-width-100 parent-width parent-height margin-empty-mobile"
      style={{ width: '99%', maxWidth: 750 }}
    >
      <div className="row parent-width justify-content-between m-v-xl">
        <div className="row parent-width">
          <DKLabel
            text={`Organizations`}
            className="fs-l"
            style={{ fontSize: 30, fontWeight: 700 }}
          />
          {loading && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="" style={{ width: 190 }}>
          <DKSearchBar
            className=""
            onSearch={(value: any) => {
              setSearchText(value);
            }}
            searchText={searchText}
          />
        </div>
      </div>
      <div className="parent-width row position-relative flex-wrap align-items-start justify-content-center show-scroll-bar">
        {!props.subscriptionData?.length ? (
          getNoDataView(props.loading)
        ) : (
          <div className="column parent-width border-m border-radius-s mb-r">
            {renderHeader()}
            {renderTenantsCard()}
          </div>
        )}
      </div>
      {showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Subscription Details'}
          btnList={popupBtnConfigForOpen}
          width="450px"
          disableClickOutside
        >
          <SubscriptionDetailPopup
            subscriptionData={subscriptionDetails}
            contact={props.contact}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default SubscriptionTab;
