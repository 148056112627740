import {
  DKButton,
  DKLabel,
  DKRadioButton,
  DKInput,
  DKDataGrid,
  INPUT_TYPE,
  DKIcon,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  currencyDropdownStyle,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectPurchaseTax } from '../../Redux/Slices/CommonDataSlice';
import { Store } from '../../Redux/Store';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import Utility, { deepClone } from '../../Utility/Utility';
import ic_no_data from '../../Assets/Icons/ic_no_data.png';
import { selectBills } from '../../Redux/Slices/BillsSlice';
import BillService from '../../Services/Bill';
import ProductService from '../../Services/Product';
import NumberFormatService from '../../Services/NumberFormat';
enum CATAGORY_OPTION {
  VALUE = 'VALUE',
  QUANTITY = 'QUANTITY',
  MANUAL = 'MANUAL',
  WEIGHT = 'WEIGHT'
}

export default function AllocateLandedCost(props: any) {
  const initialState: any = {
    landedCostCategory: '',
    allocationType: '',
    productDetails: []
  };
  const [allocationType, setAllocationType] = useState<string>(
    CATAGORY_OPTION.VALUE
  );
  const [landedCostCategoryOptions, setlandedCostCategoryOptions] =
    useState<any>();
  const tenantDetails = useAppSelector(activeTenantInfo);
  const [landedCostCategory, setLandedCostCategory] = useState<any>();

  const [formState, setFormState] = useState<any>(deepClone(initialState));
  const [billData, setBillData] = useState<any>(props?.bill);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const store = Store;
  const allBillsData = useAppSelector(selectBills);
  const [landedProducts, setLandedProducts] = useState<any[]>([]);
  const [productWeightData, setProductWeightData] = useState<any>();

  useEffect(() => {
    if (!!props?.landedProducts) {
      setLandedProducts(props?.landedProducts);
    } else {
      BillService.fetchBillDetails(
        billData?.linkedDocuments?.[0]?.documentCode
      ).then((billDetails) => {
        setLandedProducts([{ ...billDetails }]);
      });
    }
  }, [billData, allBillsData, props?.landedProducts, setLandedProducts]);

  useEffect(() => {
    let productIds: any = [];
    if (!!props?.landedProducts) {
      props?.landedProducts?.[0]?.purchaseInvoiceProducts?.forEach(
        (ele: any) => {
          productIds.push(ele.productCode);
        }
      );
      getProductData(productIds);
    } else {
      BillService.fetchBillDetails(
        billData?.linkedDocuments?.[0]?.documentCode
      ).then((billDetails: any) => {
        billDetails?.purchaseInvoiceProducts?.forEach((ele: any) => {
          productIds.push(ele.productCode);
        });
        getProductData(productIds);
      });
    }
  }, []);

  const getProductData = (ids: any) => {
    if (ids?.length > 0) {
      ProductService.getProductsByProductIds(ids)
        .then((res: any) => {
          console.log(res);
          let obj = new Map();
          res.forEach((ele: any) =>
            obj.set(ele.productId, ele.inventory.landedCostWeight)
          );
          setProductWeightData(obj);
          console.log(obj);
          // setProductDetails(res?.[0]);
        })
        .catch((err: any) => {});
    }
  };

  const getDataGridColumns = (option: any, category: any) => {
    let columns: any = [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.TEXT,
        width: 170,
        systemField: true,
        editable: false,
        hidden: false,
        required: true,
        uiVisible: true
      },
      {
        id: 'productCode',
        key: 'productCode',
        name: 'Product Id',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 105,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'landedCostWeight',
        key: 'landedCostWeight',
        name: 'Weight',
        type: INPUT_TYPE.NUMBER,
        width: 105,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },

      {
        id: 'price',
        key: 'unitPrice',
        name: 'Unit Price',
        type: INPUT_TYPE.NUMBER,
        width: 111,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: (obj: any) => {
          return Utility.roundOffToTenantDecimalScale(
            obj?.rowData?.totalAmount ?? 0
          );
        }
      }
    ];

    if (category !== 'CUSTOM_DUTY') {
      columns.push({
        id: 'allocatedCost',
        key: 'allocatedCost',
        name: 'Allocated Cost',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: option === CATAGORY_OPTION.MANUAL ? true : false,
        hidden: false,
        uiVisible: false,
        textAlign: 'right'
      });
    }
    if (category === 'CUSTOM_DUTY') {
      columns.push(
        {
          id: 'taxableValueForCustomDuty',
          key: 'taxableValueForCustomDuty',
          name: 'Taxable Value for Custom Duty',
          type: INPUT_TYPE.NUMBER,
          width: 250,
          systemField: true,
          editable: true,
          hidden: category !== 'CUSTOM_DUTY',
          uiVisible: category !== 'CUSTOM_DUTY',
          textAlign: 'right'
        },
        {
          id: 'taxableValueForIGST',
          key: 'taxableValueForIGST',
          name: 'Taxable Value for IGST',
          type: INPUT_TYPE.NUMBER,
          width: 200,
          systemField: true,
          editable: true,
          hidden: category !== 'CUSTOM_DUTY',
          uiVisible: category !== 'CUSTOM_DUTY',
          textAlign: 'right'
        },
        {
          id: 'customDutyAndOtherCharges',
          key: 'customDutyAndOtherCharges',
          name: 'Custom Duty and Other charges',
          type: INPUT_TYPE.NUMBER,
          width: 250,
          systemField: true,
          editable: true,
          hidden: category !== 'CUSTOM_DUTY',
          uiVisible: category !== 'CUSTOM_DUTY',
          textAlign: 'right'
        },
        {
          id: 'tax',
          key: 'tax',
          name: 'GST Rate',
          type: INPUT_TYPE.DROPDOWN,
          width: 100,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true,
          renderer: DocumentConfigUtility.taxRenderer,
          dropdownConfig: {
            title: 'Select Tax',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto z-index-5',
            searchApiConfig: {
              getUrl: (search: any) => DocumentConfigManager.getTaxURL(search),
              dataParser: (data: any) =>
                DocumentConfigUtility.taxDataParser(data, DOC_TYPE.BILL),
              debounceTime: 300
            },
            data: purchaseTaxes?.length
              ? DocumentConfigUtility.taxDataParser(
                  { content: purchaseTaxes },
                  DOC_TYPE.BILL
                )
              : [],
            renderer: DocumentConfigUtility.taxOptionRenderer,
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => {}
            }
          }
        },
        {
          id: 'igst',
          key: 'igst',
          name: 'IGST',
          type: INPUT_TYPE.NUMBER,
          width: 150,
          systemField: true,
          editable: true,
          hidden: category !== 'CUSTOM_DUTY',
          uiVisible: category !== 'CUSTOM_DUTY',
          textAlign: 'right'
        }
      );
    }
    return columns;
  };

  useEffect(() => {
    let categoryList = tenantDetails?.landedCostSettings?.categoryList;
    let accountSelectOptions: any = [];
    categoryList?.forEach((account: any) => {
      let option: ReactSelectOptionsType<string, string> = {
        label: account.name as string,
        value: account.allocationType as string
      };
      accountSelectOptions.push(option);
    });
    const tenantCountry = tenantDetails.country;
    if (tenantCountry !== TAX_SYSTEM.INDIA_GST) {
      accountSelectOptions = accountSelectOptions.filter(
        (item: any) => item.value !== 'CUSTOM_DUTY'
      );
    }
    setlandedCostCategoryOptions(accountSelectOptions);
    if (
      (props?.documentMode === DOCUMENT_MODE.NEW &&
        Utility.isEmpty(billData?.items[props?.rowIndex]?.landedCostDetails)) ||
      (props?.documentMode === DOCUMENT_MODE.EDIT &&
        Utility.isEmpty(billData?.items[props?.rowIndex]?.landedCostDetails))
    ) {
      setLandedCostCategory(accountSelectOptions[0]);
    }
  }, [tenantDetails]);

  useEffect(() => {
    if (landedCostCategory) {
      if (
        (props?.documentMode === DOCUMENT_MODE.NEW &&
          Utility.isEmpty(
            billData.items[props?.rowIndex]?.landedCostDetails
          )) ||
        (props?.documentMode === DOCUMENT_MODE.EDIT &&
          Utility.isEmpty(billData.items[props?.rowIndex]?.landedCostDetails))
      ) {
        filterLandedProducts(landedCostCategory);
      }
      let property =
        allocationType === CATAGORY_OPTION.VALUE
          ? 'totalAmount'
          : allocationType === CATAGORY_OPTION.QUANTITY
          ? 'productQuantity'
          : 'landedCostWeight';
      if (allocationType === CATAGORY_OPTION.WEIGHT) {
        filterLandedProducts(landedCostCategory);
      }

      calculateAllocatedCost(allocationType, property);
      getDataGridColumns(allocationType, landedCostCategory?.value);
    }
  }, [landedCostCategory, landedProducts, productWeightData]);

  useEffect(() => {
    console.log(props);
    if (
      props?.documentMode === DOCUMENT_MODE.EDIT ||
      props?.documentMode === DOCUMENT_MODE.VIEW ||
      (props?.documentMode === DOCUMENT_MODE.NEW &&
        !Utility.isEmpty(billData?.items[props?.rowIndex]?.landedCostDetails))
    ) {
      let item = billData?.items[props?.rowIndex]?.landedCostDetails;
      if (!Utility.isEmpty(item)) {
        setLandedCostCategory(JSON.parse(item?.landedCostCategory));

        let updatedState = formState;
        updatedState.productDetails = [];
        setFormState({ ...updatedState });

        item?.productDetails?.forEach((ele: any) => {
          addProduct(ele);
        });
        setAllocationType(item['allocationType']);
        let property =
          item['allocationType'] === CATAGORY_OPTION.VALUE
            ? 'totalAmount'
            : item['allocationType'] === CATAGORY_OPTION.QUANTITY
            ? 'productQuantity'
            : 'landedCostWeight';

        calculateAllocatedCost(item['allocationType'], property);
      }
    } else {
      let property =
        allocationType === CATAGORY_OPTION.VALUE
          ? 'totalAmount'
          : 'productQuantity';
      calculateAllocatedCost(allocationType, property);
    }
  }, [landedProducts, productWeightData]);

  const addProduct = (ele: any) => {
    const newState = formState;
    let product: any = {
      product: ele.product?.name || ele.product,
      productCode: ele.productCode || ele.productId,
      productQuantity: ele.productQuantity || ele.quantity,
      unitPrice: ele.unitPrice,
      totalAmount: Utility.roundOffToTenantDecimalScale(
        (ele.productQuantity || ele.quantity) * ele.unitPrice ?? 0
      ),
      landedCostWeight:
        productWeightData?.get(
          Utility.getValue(ele.productCode, ele.productId)
        ) || 0,
      allocatedCost: ele.allocatedCost || 0,
      lineItemId: ele?.id
    };
    const tenantCountry =
      store.getState().authInfo.currentTenantInfo.data.country;
    if (tenantCountry === TAX_SYSTEM.INDIA_GST) {
      let taxCode = ele.taxCode || ele.gstRate;
      let tax = purchaseTaxes.find((item: any) => item.code === taxCode);
      product['tax'] = tax;
      product['taxableValueForIGST'] = ele.taxableValueForIGST;
      product['customDutyAndOtherCharges'] = ele.customDutyAndOtherCharges;
      product['taxableValueForCustomDuty'] = ele.taxableValueForCustomDuty;
      product['igst'] = ele.igst;
    }
    newState.productDetails.push(product);
    setFormState({ ...newState });
  };

  const filterLandedProducts = (value: any) => {
    let updatedState = formState;
    updatedState.productDetails = [];
    setFormState({ ...updatedState });

    landedProducts?.[0]?.purchaseInvoiceProducts?.forEach((ele: any) => {
      ele?.product?.landedCostCategory?.forEach((item: any) => {
        if (
          item?.allocationType === value.value &&
          item?.name === value.label
        ) {
          addProduct(ele);
        }
      });
    });
  };

  const calculateAllocatedCost: any = (option: any, property: any) => {
    if (option !== CATAGORY_OPTION.MANUAL) {
      let totalSum = formState.productDetails?.reduce(function (
        prev: any,
        cur: any
      ) {
        return prev + cur[property];
      },
      0);
      // if (option === CATAGORY_OPTION.WEIGHT) {
      //   totalSum = formState.productDetails?.reduce(function (
      //     prev: any,
      //     cur: any
      //   ) {
      //     return prev + cur[property] * cur['productQuantity'];
      //   },
      //   0);
      // }

      let updatedState = formState;
      let amount = Number(
        billData.items[props.rowIndex].unitPrice *
          Number(billData.items[props.rowIndex].productQuantity)
      );
      // if (option === CATAGORY_OPTION.WEIGHT) {
      //   amount = billData.items?.reduce(function (prev: any, cur: any) {
      //     return (
      //       prev + Number(cur['unitPrice']) * Number(cur['productQuantity'])
      //     );
      //   }, 0);
      // }
      updatedState?.productDetails?.forEach((ele: any) => {
        ele.allocatedCost = Utility.roundingOff(
          (ele[property] / totalSum) * amount,
          tenantDetails.decimalScale
        );
      });
      // if (option === CATAGORY_OPTION.WEIGHT) {
      //   updatedState?.productDetails?.forEach((ele: any) => {
      //     ele.allocatedCost = Utility.roundOff(
      //       ((ele[property] * ele['productQuantity']) / totalSum) * amount
      //     );
      //   });
      // }

      let totalAllocatedSum = formState.productDetails?.reduce(function (
        prev: any,
        cur: any
      ) {
        return Utility.roundingOff(
          prev + Number(cur['allocatedCost']),
          tenantDetails.decimalScale
        );
      },
      0);

      // set the 0.01 to -0.01 roundOffDifference
      let amountRoundingDifference = Utility.roundingOff(
        amount - totalAllocatedSum,
        tenantDetails.decimalScale
      );

      if (
        (amountRoundingDifference >= -0.01 ||
          amountRoundingDifference <= 0.01) &&
        updatedState.productDetails?.[0]?.allocatedCost
      ) {
        updatedState.productDetails[0].allocatedCost =
          updatedState?.productDetails?.[0].allocatedCost +
          amountRoundingDifference;
      }

      getDataGridColumns(option, landedCostCategory?.value);

      setFormState({ ...updatedState });
    } else {
      getDataGridColumns(option, landedCostCategory?.value);
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    if (columnKey === 'tax' || columnKey === 'taxableValueForIGST') {
      if (
        !Utility.isEmpty(rowData['taxableValueForIGST']) &&
        !Utility.isEmpty(rowData['tax'])
      ) {
        let updatedState = formState;
        let igstAmount = Utility.roundOff(
          (Number(rowData['taxableValueForIGST']) * rowData['tax'].percent) /
            100
        );
        updatedState.productDetails[rowIndex]['igst'] = igstAmount;
        setFormState(updatedState);
      }
    }
  };
  const validateData = () => {
    let validate = true;
    if (allocationType === CATAGORY_OPTION.MANUAL) {
      const amount = Number(
        billData.items[props.rowIndex].unitPrice *
          Number(billData.items[props.rowIndex].productQuantity)
      );
      let totalSum = formState.productDetails?.reduce(function (
        prev: any,
        cur: any
      ) {
        return Utility.roundingOff(
          prev + Number(cur['allocatedCost']),
          tenantDetails.decimalScale
        );
      },
      0);
      if (totalSum > amount || totalSum < amount) {
        validate = false;
        showAlert(
          '',
          'Total Enter Amount should be equals to Landed Cost Bill amount.'
        );
      }
    }
    return validate;
  };

  const processData = () => {
    if (!validateData()) {
      return;
    }
    let object: any = {};
    if (landedCostCategory.value === 'CUSTOM_DUTY') {
      object['productDetails'] = formState?.productDetails.map((ele: any) => ({
        product: ele.product,
        productId: ele.productCode,
        unitPrice: Number(ele.unitPrice),
        totalAmount: Utility.roundOffToTenantDecimalScale(ele.totalAmount ?? 0),
        quantity: ele.productQuantity,
        allocatedCost: Number(ele.customDutyAndOtherCharges),
        gstRate: ele.tax.code,
        taxableValueForIGST: Number(ele.taxableValueForIGST),
        customDutyAndOtherCharges: Number(ele.customDutyAndOtherCharges),
        taxableValueForCustomDuty: Number(ele.taxableValueForCustomDuty),
        igst: Number(ele.igst),
        lineItemId: ele?.lineItemId
      }));
      object['allocationType'] = 'CUSTOM_DUTY';
    } else {
      object['productDetails'] = formState?.productDetails.map((ele: any) => ({
        product: ele.product,
        productId: ele.productCode,
        unitPrice: ele.unitPrice,
        totalAmount: Utility.roundOffToTenantDecimalScale(ele.totalAmount ?? 0),
        quantity: ele.productQuantity,
        allocatedCost: ele.allocatedCost,
        lineItemId: ele?.lineItemId
      }));
      object['allocationType'] = allocationType;
    }

    object['landedCostCategory'] = JSON.stringify(landedCostCategory);
    object['categoryName'] = landedCostCategory.label;

    props.setLandedCostData({
      rowIndex: props.rowIndex,
      formData: object
    });
  };
  const getNoDataView = () => {
    return (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute parent-width"
        style={{ top: 200, pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={'No data found'} className="fw-m mt-l" />
        <DKLabel
          text={'Once data is available, it will appear here'}
          className="text-gray mt-s"
        />
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={'Allocate Landed Cost'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() => processData()}
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="row align-items-start align-items-center">
        <div style={{ width: 300 }}>
          <DKInput
            title={''}
            className="z-index-5 pt-l"
            required={false}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            options={landedCostCategoryOptions}
            formatter={(obj: any) => {
              return obj.label;
            }}
            value={landedCostCategory}
            onChange={(value: any) => {
              setLandedCostCategory(value);
              if (value.value !== 'CUSTOM_DUTY') {
                setAllocationType(CATAGORY_OPTION.VALUE);
              }
              getDataGridColumns(allocationType, value.value);
            }}
            dropdownConfig={{
              style: { minWidth: 230 },
              className: 'shadow-m',
              title: '',
              allowSearch: false,
              canEdit: false,
              canDelete: false,
              data: landedCostCategoryOptions,
              renderer: (index: number, obj: any) => {
                return <DKLabel text={obj.label} />;
              }
            }}
            readOnly={
              (props.documentMode === DOCUMENT_MODE.EDIT &&
                !Utility.isEmpty(
                  billData?.items[props?.rowIndex]?.landedCostDetails
                )) ||
              props.documentMode === DOCUMENT_MODE.VIEW
            }
          />
        </div>
        {landedCostCategory?.value !== 'CUSTOM_DUTY' && (
          <div className="row  fs-l pr-l pl-xxl ml-xxl">
            <DKRadioButton
              className="row pt-l fs-l pr-l pl-xxl ml-xxl"
              title="By Value"
              isSelected={allocationType === CATAGORY_OPTION.VALUE}
              color="bg-blue"
              onClick={() => {
                setAllocationType(CATAGORY_OPTION.VALUE);
                calculateAllocatedCost(CATAGORY_OPTION.VALUE, 'totalAmount');
              }}
            />
            <DKRadioButton
              className="row pt-l pr-l"
              title="By Quantity"
              isSelected={allocationType === CATAGORY_OPTION.QUANTITY}
              color="bg-blue"
              onClick={() => {
                setAllocationType(CATAGORY_OPTION.QUANTITY);
                calculateAllocatedCost(
                  CATAGORY_OPTION.QUANTITY,
                  'productQuantity'
                );
              }}
            />
            <DKRadioButton
              className="row pt-l pr-l"
              title="By Weight"
              isSelected={allocationType === CATAGORY_OPTION.WEIGHT}
              color="bg-blue"
              onClick={() => {
                setAllocationType(CATAGORY_OPTION.WEIGHT);
                calculateAllocatedCost(
                  CATAGORY_OPTION.WEIGHT,
                  'landedCostWeight'
                );
              }}
            />
            <DKRadioButton
              className="row pt-l pr-l"
              title="By Manual"
              isSelected={allocationType === CATAGORY_OPTION.MANUAL}
              color="bg-blue"
              onClick={() => {
                setAllocationType(CATAGORY_OPTION.MANUAL);
                calculateAllocatedCost(
                  CATAGORY_OPTION.MANUAL,
                  'productQuantity'
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const getDataGrid = () => {
    return (
      <div
        className="p-h-xl column parent-width mt-4 z-index-3"
        style={{
          maxHeight: '700',
          height: '700px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <DKDataGrid
          style={{
            maxHeight: '700',
            height: '700',
            // overflow: 'auto'
            overflowX: 'auto'
          }}
          className="z-index-3"
          needShadow={false}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={false}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          width={972}
          rows={formState.productDetails}
          columns={getDataGridColumns(
            allocationType,
            landedCostCategory?.value
          )}
          onRowUpdate={onRowUpdate}
          // onRowClick={onRowClick}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1050,
          width: '95%',
          maxHeight: '85%',
          height: 600,
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {getHeader()}
        <div className="p-h-xl parent-width">{getBody()}</div>
        {getDataGrid()}
        {formState.productDetails.length === 0 && getNoDataView()}
      </div>
    </div>
  );
}
