import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getFontSize, getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../../dktable/ColumnStyle"
import { HeaderColumn } from "../../dktable/HeaderColumn"
import { ItemColumn } from "../../dktable/ItemColumn"
import { TableOption } from "../../dktable/TableOption"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE } from "../../../Constants/Constants"
import { TableHeader } from "../../dktable/TableHeader"
import { getSortedHeader, getSortedLineItems } from "../../dktable/TableUtility"
import { ShippingLabelTableItem } from "./ShippingLabelTableItem"

export class ShippingLabelTable {
    static getTableOption() {
        var tableOption = new TableOption()
        tableOption.isShowBorder = false
        tableOption.isShowFooter = false
        tableOption.isShowSortButton = false
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        // newHeaderColumn.style.paddingTop = getVW(11)
        // newHeaderColumn.style.paddingBottom = getVW(11)
        newHeaderColumn.style.paddingLeft = getVW(Utility.convertToPrintScale(11, printScale))
        newHeaderColumn.style.paddingRight = getVW(Utility.convertToPrintScale(11, printScale))
        newHeaderColumn.style.fontSize = getFontSize(FONT_SIZE.regular, undefined, printScale)
        if (tableStyle.headerTextSize !== undefined && tableStyle.headerTextSize !==  null ) {
            newHeaderColumn.style.fontSize = getFontSize(tableStyle.headerTextSize, undefined, printScale)
        }
        newHeaderColumn.style.fontWeight = 'bold'
        newHeaderColumn.style.width = tableStyle.headerWidth ? tableStyle.headerWidth : '100%'
        return newHeaderColumn
    }
    static getHeaderColumnList(columnOption: any, tableStyle: any, printScale: number) {
        var headerColumnList: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }

        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, printScale)
                newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element))
                headerColumnList.push(newHeaderColumn)
            });
        }

        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, [])
        return headerColumnList
    }
    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.style.alignment = 'left'
        return headerColumn
    }

    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            ['F-001', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
            ['F-002', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
            ['F-003', getLocalisedText('item_name'), getLocalisedText("item_description"), 'N/A', '1', '1', ''],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }

            var sortedItems = getSortedLineItems(documentData.lineItems)
            sortedItems.forEach((element: any) => {
                lineItems.push(new ShippingLabelTableItem(element, documentData, currency).toString())
            });
            return lineItems
            // return []
        }
    }

    static getDefaultItemColumn(tableStyle: any, index: number, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = getVW(`${Utility.convertToPrintScale(0.384615, printScale)}vw`, true)
        newItemColumn.style.paddingBottom = getVW(`${Utility.convertToPrintScale(0.384615, printScale)}vw`, true)
        newItemColumn.style.paddingLeft = getVW(`${Utility.convertToPrintScale(0.769231, printScale)}vw`, true)
        newItemColumn.style.paddingRight = getVW(`${Utility.convertToPrintScale(0.769231, printScale)}vw`, true)
        newItemColumn.style.fontSize = getFontSize(FONT_SIZE.regular, undefined, printScale)
        if (tableStyle.itemTextSize !== undefined && tableStyle.itemTextSize !== null) {
            newItemColumn.style.fontSize = getFontSize(tableStyle.itemTextSize, undefined, printScale)
        }
        return newItemColumn
    }

    static getItemColumn(data: any, tableStyle: any, printScale: number) {
        var itemColumnList: ItemColumn[][] = []
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            column.forEach(element => {
                var newItemColumn = this.getDefaultItemColumn(tableStyle, index, printScale)
                newItemColumn.value = element
                rowList.push(newItemColumn)
            });
            itemColumnList.push(rowList)
        });

        return itemColumnList
    }
}
