import React, { useEffect, useRef, useState } from 'react';
import { INPUT_TYPE, DKDataGrid, DKIcons, showAlert } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  PAYMENT_MILESTONE_STATUS,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { IPaymentMileStonePayload } from '../../Models/PaymentMilestone';
import { isBefore } from 'date-fns';
import {
  fetchPaymentMilestones,
  parsePayload,
  parseResponseForGrid,
  savePaymentMileStone,
  updatePaymentMileStone
} from '../../Services/PaymentMilestone';
import Utility, { deepClone } from '../../Utility/Utility';
import { isEmpty } from 'lodash';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { amountFormatter } from '../Mrp/WorkOrder/WorkOrderHelper';
import { roundOff } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import DateFormatService from '../../Services/DateFormat';
import { documentUpdated } from '../../Redux/Slices/CommonDataSlice';
import { useDispatch } from 'react-redux';

export interface IPaymentMileStoneProps {
  document: any;
  onCancel?: () => void;
  allowEdit: boolean;
  onSave?: (data: IPaymentMileStonePayload[]) => void;
  onError?: () => void;
  passingInteraction?: (callback: CallBackPayloadType) => void;
}
const COLUMN_CONFIG = (docType: DOC_TYPE) => [
  {
    name: 'Sr. No',
    type: INPUT_TYPE.TEXT,
    index: 1,
    textAlign: 'right',
    options: [],
    required: true,
    width: 80,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'milestoneNo',
    id: 'milestoneNo',
    allowFilter: false,
    allowColumnSort: false,
    key: 'milestoneNo'
  },
  {
    name: 'Milestone Date',
    type: INPUT_TYPE.DATE,
    index: 2,
    options: [],
    required: true,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'milestoneDate',
    id: 'milestoneDate',
    allowFilter: false,
    allowColumnSort: false,
    key: 'milestoneDate'
  },
  {
    name: 'Amount',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    textAlign: 'right',
    options: [],
    required: true,
    width: 100,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'amount',
    id: 'amount',
    allowNegativeNumber: false,
    allowFilter: false,
    allowColumnSort: false,
    key: 'amount'
  },
  {
    name: 'Amount In Percent',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    textAlign: 'right',
    options: [],
    required: true,
    width: 170,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'amountInPercent',
    id: 'amountInPercent',
    allowNegativeNumber: false,
    allowFilter: false,
    allowColumnSort: false,
    key: 'amountInPercent'
  },
  {
    name: docType === DOC_TYPE.INVOICE ? 'Payment Received' : 'Payment Made',
    type: INPUT_TYPE.NUMBER,
    index: 5,
    textAlign: 'right',
    options: [],
    required: true,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'receivedAmount',
    id: 'receivedAmount',
    allowFilter: false,
    allowColumnSort: false,
    key: 'receivedAmount'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 6,
    options: [
      {
        id: 'PAYMENT_DUE',
        name: PAYMENT_MILESTONE_STATUS.PAYMENT_DUE,
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'PAYMENT_ON_TIME',
        name: PAYMENT_MILESTONE_STATUS.PAYMENT_ON_TIME,
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'DELAYED',
        name: PAYMENT_MILESTONE_STATUS.DELAYED,
        color: 'data-grid-badge-color-6'
      }
    ],
    required: true,
    width: 145,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    allowFilter: false,
    allowColumnSort: false,
    key: 'status'
  },
  {
    id: 'menu-buttons',
    key: 'buttons',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 50,
    options: []
  }
];
const DEFAULT_PAGE_SIZE = 1000;
export const PaymentMileStone: React.FC<IPaymentMileStoneProps> = (props) => {
  /** State and effects goes here */
  const [rows, setRows] = useState<IPaymentMileStonePayload[]>([]);
  const [dataRows, setDataRows] = useState<IPaymentMileStonePayload[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [columnConfig, setColumnConfig] = useState(
    COLUMN_CONFIG(props?.document?.documentType)
  );
  const isUpdate = useRef<boolean>(false);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1
  });
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [dataRowIndex, setDataRowIndex] = useState(
    (pagination.currentPage - 1) * 10
  );
  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    getMileStonesByDocCode();
  }, [props.document]);

  useEffect(() => {
    if (!Utility.isEmpty(dataRows)) {
      const startIndex = (pagination.currentPage - 1) * 10;
      const endIndex = startIndex + 10;
      const rowData = dataRows.slice(startIndex, endIndex);
      setRows(validateRows(rowData, rowData?.[0]?.milestoneNo));
      setDataRowIndex(startIndex);
    }
  }, [pagination?.currentPage]);
  /** business logic goes here */

  const paginateToLastPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination({
        currentPage: pagination.totalPages,
        totalPages: pagination.totalPages
      });
    }
  };

  const addNewMilestone = () => {
    if (pagination.currentPage < pagination.totalPages) {
      paginateToLastPage();
    }
    const newRows = [...rows];
    const dataRowCopy = [...dataRows];
    const newRow = {
      milestoneNo: dataRowCopy.length + 1,
      milestoneDate: new Date(),
      documentCode: props.document.documentNumber,
      documentType: props.document.docType,
      dueAmount: 0,
      amountInPercent: 0,
      amountInPercentFlag: false,
      contactCode: props.document?.contactCode,
      amount: 0,
      receivedAmount: 0,
      status: ['PAYMENT_DUE'] as any
    };
    dataRowCopy.push(newRow);
    setDataRows(dataRowCopy);
    newRows.push(newRow);
    setRows(newRows);
  };

  const validateRows = (
    data: IPaymentMileStonePayload[],
    milestoneNumberStart: number
  ) => {
    let sumOfAmount = 0;
    let { totalAmount } = props.document;
    try {
      data.forEach((row: any, index) => {
        row['selected'] = false;
        row['invalidFields'] = [];
        row['milestoneNo'] = milestoneNumberStart + index;
        sumOfAmount = sumOfAmount + parseFloat(row.amount || 0);
        if (sumOfAmount > totalAmount) {
          row['invalidFields'].push('amount');
        }
        const bookBeginning = DateFormatService.getDateFromStr(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        const milestoneDate = new Date(row['milestoneDate']);
        if (isBefore(milestoneDate, bookBeginning)) {
          row['invalidFields'].push('milestoneDate');
        }
      });
    } catch (error) {}
    return data;
  };

  const onRowUpdate = ({ rowData, rowIndex, columnData }: any) => {
    let copyRowState = [...rows];
    let copyDataRowState = [...dataRows];
    let copyDataRowStateIndex = dataRowIndex + rowIndex;
    if (columnData.key === 'amount' || columnData.key === 'amountInPercent') {
      const amountWithPrecision = parseFloat(rowData['amount']);
      if (columnData.key === 'amount') {
        if (amountWithPrecision > 0) {
          const percentage =
            (100 * amountWithPrecision) /
            parseFloat(props.document.totalAmount);
          copyRowState[rowIndex]['amount'] = rowData['amount'];
          copyRowState[rowIndex]['amountInPercent'] = percentage;
          copyRowState[rowIndex]['amountInPercentFlag'] = false;

          copyDataRowState[copyDataRowStateIndex]['amount'] = rowData['amount'];
          copyDataRowState[copyDataRowStateIndex]['amountInPercent'] =
            percentage;
          copyDataRowState[copyDataRowStateIndex]['amountInPercentFlag'] =
            false;
        } else {
          copyRowState[rowIndex]['amount'] = 0;
          copyRowState[rowIndex]['amountInPercent'] = 0;

          copyDataRowState[copyDataRowStateIndex]['amount'] = 0;
          copyDataRowState[copyDataRowStateIndex]['amountInPercent'] = 0;
        }
        copyRowState[rowIndex]['amountInPercentFlag'] = false;

        copyDataRowState[copyDataRowStateIndex]['amountInPercentFlag'] = false;
      } else {
        const amountWithPrecision = parseFloat(rowData['amountInPercent']);
        if (amountWithPrecision > 0 && amountWithPrecision <= 100) {
          const amount =
            (amountWithPrecision * parseFloat(props.document.totalAmount)) /
            100;
          copyRowState[rowIndex]['amountInPercent'] = parseFloat(
            rowData['amountInPercent']
          );
          copyRowState[rowIndex]['amount'] = amount;
          copyRowState[rowIndex]['amountInPercentFlag'] = true;

          copyDataRowState[copyDataRowStateIndex]['amountInPercent'] =
            parseFloat(rowData['amountInPercent']);
          copyDataRowState[copyDataRowStateIndex]['amount'] = amount;
          copyDataRowState[copyDataRowStateIndex]['amountInPercentFlag'] = true;
        } else {
          copyRowState[rowIndex]['amount'] = 0;
          copyRowState[rowIndex]['amountInPercent'] = 0;

          copyDataRowState[copyDataRowStateIndex]['amount'] = 0;
          copyDataRowState[copyDataRowStateIndex]['amountInPercent'] = 0;
        }
      }
    } else if (columnData.key === 'milestoneDate') {
      copyDataRowState[rowIndex]['milestoneDate'] = rowData['milestoneDate'];

      copyDataRowState[copyDataRowStateIndex]['milestoneDate'] =
        rowData['milestoneDate'];
    }
    setRows(validateRows(deepClone(copyRowState), copyRowState[0].milestoneNo));
    setDataRows(validateRows(deepClone(copyDataRowState), 1));
  };

  const rowDelete = (data: any) => {
    let copyRows: any;
    let copyDataRows = [...dataRows];
    copyDataRows.splice(dataRowIndex + data.rowIndex, 1);
    let rowDataAfterDelete = validateRows(
      copyDataRows,
      copyDataRows[0].milestoneNo
    );
    setDataRows(rowDataAfterDelete);

    copyRows = rowDataAfterDelete.slice(dataRowIndex, dataRowIndex + 10);
    setRows(validateRows(copyRows, copyRows[0].milestoneNo));
  };

  const deleteMilestones = () => {
    let copyDataRows = [...dataRows];
    let selectedRowsIds = copyDataRows
      .filter((rowData: any) => rowData.selected)
      .map((row: any) => {
        return row.id;
      });
    const deleteRows = copyDataRows.filter((row: any) => {
      return !selectedRowsIds.includes(row.id);
    });

    setDataRows(validateRows(deleteRows, 1));

    let rowData = deleteRows.slice(dataRowIndex, dataRowIndex + 10);
    setRows(validateRows(rowData, rows[0].milestoneNo));
    setIsRowSelected(false);
  };

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_PAYMENT_MILESTONE,
        data: () => addPaymentMileStone()
      });
    }
  };

  const addPaymentMileStone = () => {
    if (rows.length > 0) {
      const isValid = rows.every(
        (row: any) =>
          !row['invalidFields'] || row['invalidFields']?.length === 0
      );
      if (isValid) {
        parsePayload(deepClone(dataRows), props.document).then((payload) => {
          (!isUpdate.current
            ? savePaymentMileStone(payload)
            : updatePaymentMileStone(payload, props.document.documentNumber)
          )
            .then((response: any) => {
              props?.onSave?.(response);
              dispatch(documentUpdated({}));
            })
            .catch((error) => {
              props.onError?.();
              console.error(error);
            });
        });
      } else {
        props.onError?.();
      }
    } else {
      props.onError?.();
      showAlert(
        'Milestone can not be empty.',
        'Please add one or more payment milestones.'
      );
    }
  };

  const getMileStonesByDocCode = () => {
    if (props.document.documentNumber) {
      const params = {
        limit: DEFAULT_PAGE_SIZE,
        page: pagination.currentPage - 1,
        query: `documentCode=${props.document.documentNumber},documentType=${props.document.documentType}`,
        sortDir: 'ASC'
      };
      const itemsPerPage = 10;
      const startIndex = (pagination.currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setLoading(true);
      fetchPaymentMilestones(params)
        .then((res: any) => {
          if (Utility.isNotEmpty(res.content)) {
            setPagination((prev) => ({
              ...prev,
              totalPages: Math.ceil(res.totalElements / itemsPerPage)
            }));
            checkForPayment(res.content);
            const parsedResponseForGrid = parseResponseForGrid(
              res.content,
              props.document
            );
            const rowData = parsedResponseForGrid.slice(startIndex, endIndex);
            setDataRows(
              validateRows(
                parsedResponseForGrid,
                parsedResponseForGrid[0].milestoneNo
              )
            );
            setRows(validateRows(rowData, rowData[0].milestoneNo));
          }
        })
        .catch((err) => {
          console.error(`Error while fetching milestones: ${err}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkForPayment = (mileStones: IPaymentMileStonePayload[]) => {
    isUpdate.current = !isEmpty(mileStones);
    const existingColumnConfig = columnConfig.map((column: any) => {
      if (
        column.key === 'amount' ||
        column.key === 'milestoneDate' ||
        column.key === 'amountInPercent'
      ) {
        column.editable = props.allowEdit;
      }
      if (['amount', 'dueAmount', 'receivedAmount'].includes(column.key)) {
        column['renderer'] = ({ value }: any) => (
          <div className="row justify-content-end">
            {amountFormatter(roundOff(value), props.document.currency)}
          </div>
        );
      }
      if (['amountInPercent'].includes(column.key)) {
        column['renderer'] = ({ value }: any) => (
          <div className="row justify-content-end">{roundOff(value || 0)}%</div>
        );
      }
      return column;
    });
    setColumnConfig(existingColumnConfig);
  };

  const getButtons = () => {
    let buttons: any;
    const selectedRows = rows.filter((row: any) => row.selected);
    if (!Utility.isEmpty(selectedRows)) {
      buttons = [
        {
          className: 'fs-r bg-red text-white ml-r',
          onClick: () => {
            deleteMilestones();
          },
          title: 'Delete'
        }
      ];
    }
    return buttons;
  };

  /** renderer goes here */
  return (
    <div className="p-h-l">
      <div className="row justify-content-between fw-b fs-l">
        <div>
          Document Number
          <br />
          <div className="fs-xl text-blue">
            {props?.document?.documentSequenceCode}
          </div>
        </div>
        <div>
          Total Amount
          <br />
          <div className="fs-xl text-blue text-align-right">
            {amountFormatter(
              props?.document?.totalAmount,
              props.document.currency
            )}
          </div>
        </div>
      </div>
      <div className="row pt-l">
        <DKDataGrid
          rows={rows.map((row: any) => {
            if (props.allowEdit) {
              row['rowButtons'] = [
                {
                  title: '',
                  icon: DKIcons.ic_delete,
                  onClick: (data: any) => {
                    rowDelete(data);
                  }
                }
              ];
            }
            return row;
          })}
          title={'Payment Milestones'}
          updating={loading}
          columns={columnConfig}
          needTrailingColumn={false}
          allowColumnEdit={false}
          onRowUpdate={onRowUpdate}
          currentPage={pagination?.currentPage}
          totalPageCount={pagination?.totalPages}
          onNextPageTapped={() => {
            setPagination((prev) => ({
              ...prev,
              currentPage: prev.currentPage + 1
            }));
          }}
          onPrevPageTapped={() => {
            setPagination((prev) => ({
              ...prev,
              currentPage: prev.currentPage - 1
            }));
          }}
          onRowSelect={(data: any) => {
            let copyRows = [...rows];
            let copyDataRows = [...dataRows];
            copyRows[data.rowIndex] = data.rowData;
            copyDataRows[dataRowIndex + data.rowIndex] = data.rowData;
            setDataRows(copyDataRows);
            setRows(copyRows);
          }}
          onAllRowSelect={(data: any) => {
            setIsRowSelected(data.selected);
          }}
          allowBulkOperation={true}
          buttons={getButtons()}
          isAllRowSelected={isRowSelected}
        />
      </div>
      {props.allowEdit && (
        <div
          className="row bg-white fw-b text-blue p-s-v"
          style={{
            position: 'sticky',
            bottom: 0
          }}
        >
          <span className="cursor-hand" onClick={addNewMilestone}>
            + Add New Milestone
          </span>
        </div>
      )}
    </div>
  );
};
