import ApiConstants from '../Constants/ApiConstants';
import { APP_NAME } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface ImportLogAPIConfig {
  Limit?: number;
  Page?: number;
  Query?: string;
}

const defaultConfig: ImportLogAPIConfig = {
  Limit: 25,
  Page: 0,
  Query: `appName=${APP_NAME}`
};

class ImportLogService {
  static apiConfig: ImportLogAPIConfig;

  static getImportLog() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&query=${this.apiConfig.Query}`;
    const finalEndpoint: string = `/exim/audit` + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading tax: ', err);
      return Promise.reject(err);
    });
  }
}

export default ImportLogService;
