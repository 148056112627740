import { GranularPermissionsHelper } from '../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  APPROVAL_STATUS,
  BUY_TYPE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_TYPE,
  JWO_BILLED_STATUS,
  JWO_DISPATCH_STATUS,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS
} from '../Constants/Constant';
import Utility, { isDocumentLocked } from '../Utility/Utility';
import { isTabletView } from '../Utility/ViewportSizeUtils';

export enum BUY_CONFIG_BTN {
  EMAIL = 'email',
  EDIT = 'edit',
  DELETE = 'delete',
  CONVERT = 'convert',
  ISSUE = 'issue',
  NEW = 'new',
  RECURRING = 'recurring',
  COPY = 'copy',
  PAYMENT_RECORD = 'payment_record',
  IMPORT = 'import',
  EXPORT = 'export',
  VIEW = 'view',
  PRINT = 'print',
  RECEIVED = 'received',
  RECEIVE_GOODS = 'receive_goods',
  MAKE_PAYMENT = 'make_payment',
  RECEIVE_GOODS_RECORDS = 'receive_goods_record',
  DESIGN = 'design',
  OFFSET_PAYMENT = 'offset_payment',
  CREATE_PURCHASE_RETURN = 'return',
  CREATE_PURCHASE_RETURN_RECORDS = 'return_records',
  CREATE_BILL = 'create_bill',
  LINKED_RECORDS = 'linked_records'
}
export default class BuyConfig {
  static showActionButton = (
    btn: BUY_CONFIG_BTN,
    document: any,
    documentType: DOC_TYPE
  ) => {
    let showBtn = true;
    switch (btn) {
      case BUY_CONFIG_BTN.EDIT:
        if (document.status === DOCUMENT_STATUS.CLOSED) {
          showBtn = false;
        } else if (
          document['linkedPurchaseInvoices'] &&
          document['linkedPurchaseInvoices'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.ORDER &&
          (document.receiptStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED ||
            document.receiptStatus === RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED)
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.ORDER &&
          isDocumentLocked({...document,documentType: DOC_TYPE.ORDER })
        ) {
          showBtn = false;
        }  else if (
          documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          (document.dispatchStatus ===
            JWO_DISPATCH_STATUS.PARTIALLY_DISPATCHED ||
            document.dispatchStatus === JWO_DISPATCH_STATUS.DISPATCHED ||
            document.dispatchStatus ===
              JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL)
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          (document.billedStatus === JWO_BILLED_STATUS.PARTIAL ||
            document.billedStatus === JWO_BILLED_STATUS.BILLED)
        ) {
          showBtn = false;
        } else if (
          document.receiveGoodsStatus &&
          document['buyType'] !== BUY_TYPE.EXPENSE &&
          (document.receiveGoodsStatus ===
            RECEIVED_GOODS_STATUS.FULLY_RECEIVED ||
            document.receiveGoodsStatus ===
              RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED)
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.BILL &&
          document.paymentStatus &&
          document.paymentStatus !== PAYMENT_STATUS.PENDING
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.BILL &&
          document['openingInvoice']
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.BILL &&
          document['tdsProcessedFlag']
        ) {
          showBtn = !document['tdsProcessedFlag'];
        } else if (document.documentDate || document.purchaseInvoiceDate) {
          let res = Utility.validationForClosedDate(document);
          showBtn = !res.isDisable;
        }
        if (document.totalAmount === 0 && document.status === 'OPEN') {
          showBtn = true;
        }
        break;

      case BUY_CONFIG_BTN.DELETE:
        if (documentType === DOC_TYPE.BILL && document['tdsProcessedFlag']) {
          showBtn = !document['tdsProcessedFlag'];
        } else if (document.documentDate || document.purchaseInvoiceDate) {
          let res = Utility.validationForClosedDate(document);
          showBtn = !res.isDisable;
        }
        // if (
        //   documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
        //   document?.dispatchStatus === JWO_DISPATCH_STATUS.DISPATCHED
        // ) {
        //   showBtn = false;
        // }

        if (
          document['linkedPurchaseInvoices'] &&
          document['linkedPurchaseInvoices'].length > 0
        ) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.CONVERT:
        const nonPendingProducts = Utility.getNonPendingsItems(
          document.purchaseOrderItems || []
        );
        if (
          document.linkedPurchaseInvoices &&
          document.linkedPurchaseInvoices.length > 0 &&
          nonPendingProducts.length === document.purchaseOrderItems?.length
        ) {
          showBtn = false;
        } else if (document.status === DOCUMENT_STATUS.CLOSED) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.ORDER &&
          document.status === DOCUMENT_STATUS.PROCESSED
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.ORDER &&
          Utility.isToleranceSettingsEnabled() &&
          document.receiptStatus === APPROVAL_STATUS.PENDING_FOR_APPROVAL
        ) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.NEW:
      case BUY_CONFIG_BTN.RECURRING:
      case BUY_CONFIG_BTN.COPY:
        break;
      case BUY_CONFIG_BTN.RECEIVED:
      case BUY_CONFIG_BTN.RECEIVE_GOODS:
        if (
          document.status === DOCUMENT_STATUS.PROCESSED &&
          documentType === DOC_TYPE.ORDER
        ) {
          showBtn = false;
        } else if (
          document.status === DOCUMENT_STATUS.PROCESSED &&
          documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          showBtn = false;
        } else if (
          (document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiptStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED ||
            document.receiptStatus ===
              RECEIVED_GOODS_STATUS.PENDING_FOR_APPROVAL) &&
          documentType === DOC_TYPE.ORDER
        ) {
          showBtn = false;
        } else if (
          (document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiptStatus === RECEIVED_GOODS_STATUS.FULLY_RECEIVED) &&
          documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          showBtn = false;
        } else if (
          (document.receiveGoodsStatus ===
            RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiveGoodsStatus ===
              RECEIVED_GOODS_STATUS.FULLY_RECEIVED) &&
          documentType === DOC_TYPE.BILL
        ) {
          showBtn = false;
        } else if (document.dropShip) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.MAKE_PAYMENT:
        if (document.paymentStatus === PAYMENT_STATUS.RECEIVED) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.PAYMENT_RECORD:
        if (document.paymentStatus === PAYMENT_STATUS.PENDING) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.RECEIVE_GOODS_RECORDS:
        if (
          (document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) &&
          documentType === DOC_TYPE.ORDER
        ) {
          showBtn = false;
        } else if (
          (document.receiveGoodsStatus ===
            RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiveGoodsStatus ===
              RECEIVED_GOODS_STATUS.NOT_RECEIVED) &&
          documentType === DOC_TYPE.BILL
        ) {
          showBtn = false;
        } else if (
          (document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_APPLICABLE ||
            document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED) &&
          documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          showBtn = false;
        } else if (document.dropShip) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.CREATE_PURCHASE_RETURN:
      case BUY_CONFIG_BTN.CREATE_PURCHASE_RETURN_RECORDS:
        if (
          document.receiveGoodsStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED ||
          document.receiptStatus === RECEIVED_GOODS_STATUS.NOT_RECEIVED ||
          // document.isPartialInvoice ||
          document.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
          document.purchaseInvoiceType === 'EXPENSE' ||
          document.dropShip
        ) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.IMPORT:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.EXPORT:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.CREATE_BILL:
        if (document.billedStatus === JWO_BILLED_STATUS.BILLED) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.LINKED_RECORDS:
        if (document.isDraftDocument) {
          showBtn = false;
        }
        break;
      case BUY_CONFIG_BTN.DESIGN:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
    }
    return showBtn;
  };

  static defaultBuyConfigSettings = {
    [DOC_TYPE.ORDER]: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    },
    [DOC_TYPE.BILL]: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    },
    CLOSED: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    }
  };
}
