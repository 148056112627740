import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  removeLoader,
  showLoader,
  showToast
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  DOC_TYPE,
  ENTITY_TYPE_ATTACHMENT,
  MODULES_NAME
} from '../../../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../../../Constants/Permission';
import { useAppSelector } from '../../../../../Redux/Hooks';
import AttachmentService from '../../../../../Services/Attachment';
import Utility from '../../../../../Utility/Utility';
import { checkUserPermission } from '../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { CustomAttachmentsPopup } from '../../../Shared/CustomAttachmentsPopup';
import { triggerAttachmentDownload } from './WOAttachmentHelper';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';

export interface IWOAttachments {
  isReadOnlyMode: boolean;
  isEditMode: boolean;
  workOrderData: any;
  isCopyMode?:any;
  onAttachmentChange: (data: any) => void;
}

const WOAttachments = (props: IWOAttachments) => {
  const tenantInfo = useAppSelector(activeTenantInfo);

  const [woAttachmentListByGroup, setWOAttachmentListByGroup] = useState<any>(
    {}
  );
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);

  useEffect(() => {
    const areAllAttachmentObjects =
      props.workOrderData?.attachments?.length &&
      props.workOrderData?.attachments?.every(
        (attachment: any) => typeof attachment === 'object'
      );

    if (areAllAttachmentObjects) {
      updateAttachmentList(props.workOrderData?.attachments, false);
    } else if (
      ((props.isEditMode || props.isCopyMode) && props.workOrderData?.id) ||
      (!props.isEditMode &&
        props.workOrderData?.product?.id &&
        tenantInfo?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToWorkOrder)
    ) {
      fetchAttachments();
    }
  }, []);

  const fetchAttachmentsForBOM = (attachmentL: any) => {
    const entityIdArr: any = [props.workOrderData?.selectedBom?.id] ?? [];

    AttachmentService.getAllAttachmentsByEntityId(
      entityIdArr,
      ENTITY_TYPE_ATTACHMENT['PRODUCT_BOM_DETAILS']
    ).then((attachmentList: any) => {
      if (!Utility.isEmpty(attachmentList)) {
        updateAttachmentList(
          [...attachmentL, ...attachmentList[entityIdArr[0]]],
          true
        );
      } else {
        updateAttachmentList([...attachmentL], true);
      }
    });
  };

  const getAttachmentPopup = () => {
    return (
      <CustomAttachmentsPopup
        documentDetails={{
          id: props.workOrderData?.id,
          attachments: getAttachmentListForCurrentWorkOrder()
        }}
        onUploadNewAttachment={(attachment: any) => newAttachment(attachment)}
        onRemoveAttachment={(attachmentId: any) =>
          removeAndUpdateData(attachmentId)
        }
        readOnly={props.isReadOnlyMode}
        documentType={DOC_TYPE.WORKORDER_DOCUMENT}
        onClose={() => {
          setShowAttachmentPopup(false);
        }}
      />
    );
  };

  const triggerProductDocumentAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true')
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          
          let attachments: any = { ...woAttachmentListByGroup };
          resList.forEach(element => {
            attachments[element.attachmentId] = { ...element, isProductAttachment: false };
          });

          let attachmentsInWO = [...props.workOrderData.attachments, ...resList];
          setWOAttachmentListByGroup(attachments);
          props.onAttachmentChange(attachmentsInWO);
          removeLoader();
        }).catch((err) => {
          removeLoader();
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const fetchAttachments = () => {
    const moduleType = (props.isEditMode || props.isCopyMode)
      ? MODULES_NAME.WORKORDER_DOCUMENT
      : DOC_TYPE.PRODUCT_DOCUMENT;
    const entityId = props.isEditMode || props.isCopyMode
      ? props.workOrderData?.id
      : props.workOrderData?.product?.id;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };
    let attachmentL: any = [];

    AttachmentService.getAllAttachments()
      .then((attachmentList: any) => {
        attachmentL = attachmentList;
        if (props.isEditMode || props.isCopyMode) {
          updateAttachmentList(attachmentList, false);
        }
      })
      .then(() => {
        if (!props.isEditMode) {
          fetchAttachmentsForBOM(attachmentL);
        }
      });
  };

  const updateAttachmentList = (
    attachmentList: any[],
    isProductAttachment: boolean
  ) => {
    if (attachmentList?.length) {
      let attachmentListByIds: any = {};
      attachmentList.map(
        (attachment: any) =>
          (attachmentListByIds[attachment.attachmentId] = {
            ...attachment,
            isProductAttachment
          })
      );
      setWOAttachmentListByGroup({ ...attachmentListByIds });
      props.onAttachmentChange(attachmentList);
    }
  };

  const uploadAttachmentToAWS = async (
    file: File,
    entityId?: any,
    module: any = MODULES_NAME.WORKORDER_DOCUMENT
  ) => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: module,
      EntityId: ''
    };
    showLoader();
    return AttachmentService.uploadAttachment(file);
  };

  const newAttachment = (data: any) => {
    let attachments: any = { ...woAttachmentListByGroup };
    attachments[data.attachmentId] = { ...data, isProductAttachment: false };

    let attachmentsInWO = [...props.workOrderData.attachments];
    attachmentsInWO.push(data);

    setWOAttachmentListByGroup(attachments);
    props.onAttachmentChange(attachmentsInWO);
  };

  const removeAndUpdateData = (attachmentId: any) => {
    let attachments = [...props.workOrderData.attachments] || [];
    let selectedAttachments = attachments.filter(
      (attachment: any) => attachmentId !== attachment?.attachmentId
    );
    props.onAttachmentChange(selectedAttachments);
    removeLoader();
  };

  const removeAttachment = (
    attachment: any,
    module: any = MODULES_NAME.WORKORDER_DOCUMENT
  ) => {
    const attachmentId = attachment.attachmentId;
    if (attachment?.isProductAttachment) {
      removeAndUpdateData(attachmentId);
    } else {
      AttachmentService.deleteAttachment(attachmentId)
        .then((res) => {
          removeAndUpdateData(attachmentId);
        })
        .catch(() => {});
    }
  };

  const attachmentRowData = (attachment: any) => {
    return (
      <div className="row width-auto">
        <div
          className={`row border-m border-radius-s p-h-s p-v-s bg-gray0 justify-content-between cursor-hand attachment-row`}
          key={attachment.attachmentId}
          style={{ width: 209 }}
        >
          <div className="flex flex-row items-center">
            <DKIcon
              src={DKIcons.ic_document}
              className="column ic-s opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <span
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                className=" ml-s border-none"
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </span>
          </div>
          {!props.isReadOnlyMode &&
            ((props?.isEditMode &&
              checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)) ||
              (!props?.isEditMode &&
                checkUserPermission(
                  PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE
                ))) && (
              <div className="flex flex-row items-center attachment-delete-btn">
                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s opacity-60 hover:opacity-80"
                  onClick={() => {
                    removeAttachment(attachment);
                  }}
                />
              </div>
            )}
        </div>
      </div>
    );
  };

  const getAttachmentListForCurrentWorkOrder = () => {
    let attachmentList: any[] = [];
    props.workOrderData?.attachments.forEach((att: any) => {
      let attachment = woAttachmentListByGroup[att?.attachmentId];
      if (!Utility.isEmpty(attachment)) {
        attachmentList.push(attachment);
      }
    });
    return attachmentList;
  };

  const currentOrderAttachmentsCount = props.workOrderData?.attachments?.length;

  return (
    <div
      className={`column border-m border-radius-m p-l bg-white ${
        props.isEditMode ? '' : 'flex-1'
      }`}
      style={{ minHeight: 80, width: props.isEditMode ? '49.5%' : '100%' }}
    >
      <div className="row justify-content-between">
        <DKLabel
          text={`${
            (currentOrderAttachmentsCount || 0) !== 0
              ? 'Attachments (' + currentOrderAttachmentsCount + ')'
              : 'Attachments'
          }`}
          className="fw-m text-app-color"
        />
        {(currentOrderAttachmentsCount || 0) !== 0 &&
          !props.isReadOnlyMode &&
          ((props?.isEditMode &&
            checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)) ||
            (!props?.isEditMode &&
              checkUserPermission(
                PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE
              ))) && (
            <div className="row width-auto justify-content-end">
              <DKButton
                className="text-app-color fw-m p-0"
                title="+ Add files"
                style={{ padding: 0 }}
                onClick={() => {
                  triggerProductDocumentAttachmentUpload();
                }}
              />
            </div>
          )}
      </div>
      {(currentOrderAttachmentsCount || 0) === 0 && (
        <div className="column parent-width justify-content-center align-items-center parent-height m-auto">
          <DKLabel
            text="No attachments found for this work order"
            className="text-gray"
          />
          {!props.isReadOnlyMode &&
            ((props?.isEditMode &&
              checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.EDIT)) ||
              (!props?.isEditMode &&
                checkUserPermission(
                  PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE
                ))) && (
              <DKButton
                className="text-app-color fw-m"
                title="+ Add files"
                onClick={() => {
                  triggerProductDocumentAttachmentUpload();
                }}
              />
            )}
        </div>
      )}
      {currentOrderAttachmentsCount > 0 && (
        <div className="column parent-width align-items-start gap-1 mt-m">
          <div
            className="row align-items-start gap-1 parent-width flex-wrap flex-1 overflow-y-auto"
            style={{ maxHeight: 75 }}
          >
            {getAttachmentListForCurrentWorkOrder()?.map(
              (attachment: any, index: number) => attachmentRowData(attachment)
            )}
          </div>
          {currentOrderAttachmentsCount > 10 && (props.isEditMode || props.isCopyMode) && (
            <DKButton
              className="fw-m p-0 mr-s mr-m underline mt-s"
              title="More"
              style={{ padding: 0 }}
              onClick={() => setShowAttachmentPopup(true)}
            />
          )}
        </div>
      )}

      {showAttachmentPopup && getAttachmentPopup()}
    </div>
  );
};

export default WOAttachments;
