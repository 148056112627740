import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES_CODE } from '../Constants/Constant';

import en from './traslations/en.json';
import id from './traslations/id.json';
import fr from './traslations/fr.json';
import en_GB from './traslations/en_GB.json';
import ge from './traslations/ge.json';
import es from './traslations/es.json';
import hi from './traslations/hi.json';
import ta from './traslations/ta.json';
import pb from './traslations/pb.json';
import gj from './traslations/gj.json';
import mh from './traslations/mh.json';
import bg from './traslations/bg.json';
import ka from './traslations/ka.json';
import ma from './traslations/ma.json';
import te from './traslations/te.json';

const resources = {
  en: {
    translation: en // English
  },
  id: {
    translation: id // Bahasa Indonesia
  },
  fr: {
    translation: fr // French
  },
  en_GB: {
    translation: en_GB // English UK
  },
  ge: {
    translation: ge // German
  },
  es: {
    translation: es // Spanish
  },
  hi: {
    translation: hi // Hindi
  },
  ta: {
    translation: ta // Tamil
  },
  pb: {
    translation: pb // Punjabi
  },
  gj: {
    translation: gj // Gujrati
  },
  mh: {
    translation: mh // Marathi
  },
  bg: {
    translation: bg // Bangla
  },
  ka: {
    translation: ka // Kannada
  },
  ma: {
    translation: ma // Malayalam
  },
  te: {
    translation: te // Telugu
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGES_CODE.ENGLISH_US,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
