import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import PaymentLogsService from '../../Services/PaymentLogs';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchPaymentLogs = createAsyncThunk('fetchPaymentLogs', async () => {
  const response = await PaymentLogsService.getPaymentLogs();
  return response;
});

export const PaymentLogsSlice = createSlice({
  name: 'payment-logs',
  initialState,
  reducers: {
    addPaymentLogsColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      const configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentLogs.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPaymentLogs.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { addPaymentLogsColumnConfig } = PaymentLogsSlice.actions;

export const selectPaymentLogsColumnConfig = (state: RootState) => state.paymentLogs.columnConfig;

export const selectPaymentLogs = (state: RootState) => state.paymentLogs.data;
