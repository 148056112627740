import { DKLabel, DKIcon, showAlert } from 'deskera-ui-library';
import { LabelMenuList } from './LabelList/LabelMenuList';

import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { DOCUMENT_TYPE_LIST, DraftTypes } from '../../Models/Drafts';
import { LABELS } from '../../Constants/Constant';
import { useState, useEffect } from 'react';
import Utility from '../../Utility/Utility';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { useHistory } from 'react-router-dom';
import { permissionInfo } from '../../Redux/Slices/AuthSlice';
import { RolePermissionUtil } from '../../RolePermission/RolePermissionUtil';
import { localizedText } from '../../Services/Localization/Localization';

const AddNewList = (props: any) => {
  const dispatch = useAppDispatch();
  const tableId = useAppSelector(draftTableId);
  const columnConfig = useAppSelector(selectDraftsColumnConfig);
  const history = useHistory();

  const permissionInfoData = useAppSelector(permissionInfo);

  const [hasReportsPermission, setHasReportsPermission] = useState(false);

  useEffect(() => {
    const onMouseUp = (e: any) => {
      if (typeof props.onClose !== 'undefined') {
        const target = e.target;
        const editingContainer = target.closest(
          '#add-new-doc-popup, #add-new-doc-popup-btn'
        );

        if (!editingContainer) {
          props.onClose();
        }
      }
    };

    if (typeof props.onClose !== 'undefined') {
      document.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      if (typeof props.onClose !== 'undefined') {
        document.removeEventListener('mouseup', onMouseUp);
      }
    };
  }, []);

  useEffect(() => {
    checkReportBuilderPermission();
  }, [permissionInfoData]);

  const checkReportBuilderPermission = () => {
    const hasPermission = RolePermissionUtil.isReportBuilderPermission();
    if (hasPermission) {
      setHasReportsPermission(true);
    } else {
      setHasReportsPermission(false);
      // showAlert(
      //   'No Permissions!',
      //   "You don't have permission to view spart reports module, please contact owner/admin of this organisation"
      // );
    }
  };

  const handleSmartReportClick = () => {
    if (hasReportsPermission) {
      RouteManager.navigateToPage(PAGE_ROUTES.CUSTOM_REPORT_BUILDER);
    } else {
      showAlert(
        'Permission Denied!',
        "You don't have permission to access this module, " +
          (Utility.isUserOwner()
            ? 'please enable it in user management.'
            : `please contact the owner of your ${localizedText(
                'organisation'
              )}.`),
        [
          {
            title: Utility.isUserOwner() ? 'Later' : 'Cancel',
            className: 'bg-gray2 text-black rounded border-m',
            onClick: () => {}
          },
          {
            title: Utility.isUserOwner() ? 'Open User Management' : 'Ok',
            className: 'bg-button text-white rounded ml-m',
            onClick: () => {
              if (Utility.isUserOwner())
                window.open(process.env.REACT_APP_URL_GO + 'users', '__self');
            }
          }
        ]
      );
    }
    props.onClose();
  };

  const handleMenuActions = (title: any) => {
    let payloadData: any = { title };

    switch (title) {
      case LABELS.PRODUCTS:
        payloadData.type = LABELS.PRODUCTS;
        break;
      case LABELS.CONTACT:
        payloadData.type = LABELS.CONTACT;
        break;
      case LABELS.INVOICES:
        payloadData.type = LABELS.INVOICES;
        break;
      case LABELS.BILLS:
        payloadData.type = LABELS.BILLS;
        break;
      case LABELS.PURCHASE_ORDERS:
        payloadData.type = LABELS.PURCHASE_ORDERS;
        break;
      case LABELS.QUOTES:
      case LABELS.ESTIMATE:
        payloadData.type = LABELS.QUOTES;
        break;
      case LABELS.SALES_ORDER:
        payloadData.type = LABELS.SALES_ORDER;
        break;
      case LABELS.EXPENSE_BILL:
        payloadData.type = LABELS.EXPENSE_BILL;
        break;
      case LABELS.SMART_REPORT:
        handleSmartReportClick();
        return;
      case LABELS.REQUISITION:
        payloadData.type = LABELS.REQUISITION;
        break;
      default:
    }
    // If type contains as document then it will open in center
    if (DOCUMENT_TYPE_LIST.includes(payloadData.type)) {
      payloadData.isCenterAlign = true;
    }
    payloadData.tableId = tableId;
    payloadData.columnConfig = columnConfig;
    if (title === LABELS.PRODUCTS || title === LABELS.CONTACT) {
      props.setSelectedItem(title);
    } else {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
    }
    props.onClose();
  };

  return (
    <div
      className="column bg-white border-radius-s border-m shadow-m position-absolute pb-s"
      style={{ top: 40, right: -20, width: 150, zIndex: 9999 }}
      id="add-new-doc-popup"
    >
      {LabelMenuList(props.hasRoles).map((obj, index) => {
        if (!obj.hidden)
          return (
            <div
              key={index}
              className="row listPickerBG cursor-hand parent-width border-box"
              style={{ padding: 12 }}
              onClick={() => handleMenuActions(obj.title)}
            >
              <DKIcon src={obj.icon} className="ic-s" />
              <DKLabel text={obj.title} className="ml-r" />
            </div>
          );
      })}
    </div>
  );
};

export default AddNewList;
