import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface LabelTemplateApiConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

const defaultConfig: LabelTemplateApiConfig = {
  SearchTerm: '',
  Limit: 100,
  Page: 0,
  Query: [],
  QueryParam: ''
};

class LabelTemplateService {
  static apiConfig: LabelTemplateApiConfig = defaultConfig;

  static getLabelTemplate() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.LABEL_TEMPLATE.FETCH_LABEL_TEMPLATE + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Label Template: ', err);
      return Promise.reject(err);
    });
  }

  static getLabelTemplateByType(type: string) {
    return http
      .get(ApiConstants.URL.LABEL_TEMPLATE.FETCH_LABEL_TEMPLATE_BY_TYPE(type))
      .catch((err: any) => {
        console.error('Error loading Label Template: ', err);
        return Promise.reject(err);
      });
  }

  static saveLabelTemplate(data: any) {
    return http
      .post(ApiConstants.URL.LABEL_TEMPLATE.SAVE_LABEL_TEMPLATE, {
        ...data,
        templateData: JSON.stringify(data.templateData),
        labelSettings: JSON.stringify(data.labelSettings)
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateLabelTemplate(data: any) {
    return http
      .put(
        ApiConstants.URL.LABEL_TEMPLATE.SAVE_LABEL_TEMPLATE + '/' + data.id,
        {
          ...data,
          templateData: JSON.stringify(data.templateData),
          labelSettings: JSON.stringify(data.labelSettings)
        }
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteLabelTemplate(labelTemplateId: any) {
    return http
      .delete(
        ApiConstants.URL.LABEL_TEMPLATE.SAVE_LABEL_TEMPLATE +
          '/' +
          labelTemplateId
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default LabelTemplateService;
