import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import { RootState } from '../../Store';

import WorkstationsService from '../../../Services/MRP/Workstations';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchWorkStations = createAsyncThunk('workStations', async () => {
  const response = await WorkstationsService.getWorkStationsByPage();
  return response;
});

export const WorkstationSlice = createSlice({
  name: 'Workstations',
  initialState,
  reducers: {
    addWorkStationColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.WORKSTATIONS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToWorkstationColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkStations.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchWorkStations.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addWorkStationColumnConfig,
  updateColumnConfig,
  addColumnToWorkstationColumnConfig
} = WorkstationSlice.actions;

export const selectWorkstations = (state: RootState) =>
  state.mrpWorkstation.data;

export const selectWorkStationColumnConfig = (state: RootState) =>
  state.mrpWorkstation.columnConfig;

export const selectWorkStationColumnConfigTableId = (state: RootState) =>
  state.mrpWorkstation.configTableId;
