import {
  DKLabel,
  Toggle,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchCurrentTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { selectIsWOAdhocEnable } from '../../../Redux/Slices/MRP/SettingsSlice';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import TenantService from '../../../Services/Tenant';

const WOAdhocBOMSetting = (props: any) => {
  const [isWorkOrderAdhocBOMEnabled, setIsWorkOrderAdhocBOMEnabled] =
    useState(false);
  const [
    isShowBomComponentAsLineItemInConversion,
    setIsShowBomComponentAsLineItemInConversion
  ] = useState(false);

  const dispatch = useAppDispatch();
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);

  useEffect(() => {
    if (props.tenantDetails?.additionalSettings?.ADHOC_BOM) {
      setIsWorkOrderAdhocBOMEnabled(
        props.tenantDetails?.additionalSettings?.ADHOC_BOM?.enable
      );
      setIsShowBomComponentAsLineItemInConversion(
        props.tenantDetails?.additionalSettings?.ADHOC_BOM
          ?.showBomComponentAsLineItemInConversion
      );
    }
  }, [props.tenantDetails]);

  const saveWorkOrderAdhocBOMSetting = (isAdhocBOM: boolean = false) => {
    const payload = {
      enable: !isAdhocBOM ? true : !isWorkOrderAdhocBOMEnabled,
      showBomComponentAsLineItemInConversion: isAdhocBOM
        ? false
        : !isShowBomComponentAsLineItemInConversion
    };

    let advancedSettingPayload = {
      additionalSettings: { ADHOC_BOM: payload }
    };

    TenantService.updateOrgSetting(advancedSettingPayload)
      .then((res) => {
        dispatch(fetchCurrentTenantInfo());
        setIsWorkOrderAdhocBOMEnabled(payload.enable);
        setIsShowBomComponentAsLineItemInConversion(
          payload.showBomComponentAsLineItemInConversion
        );
      })
      .catch((err) => {
        console.log(
          'Error updating work order warehouse tagging settings',
          err
        );
      });
  };

  const showAlertForAdhocBOM = (
    isConfirmation: boolean = false,
    isAdhocBOMEnable: boolean = false,
    warningMsg: string,
    disableMsg: string
  ) => {
    if (isConfirmation) {
      showAlert('Warning!', warningMsg, [
        {
          title: 'Cancel',
          className: 'bg-white border-m mr-s',
          onClick: () => {}
        },
        {
          title: 'Proceed',
          className: 'bg-button text-white',
          onClick: () => {
            saveWorkOrderAdhocBOMSetting(isAdhocBOMEnable);
          }
        }
      ]);
    } else {
      showAlert('', disableMsg);
    }
  };

  const showWOAdhocBOMAlert = () => {
    const payload = {
      enable: !isWorkOrderAdhocBOMEnabled,
      showBomComponentAsLineItemInConversion:
        isShowBomComponentAsLineItemInConversion
    };

    let advancedSettingPayload = {
      additionalSettings: { ADHOC_BOM: payload }
    };
    showLoader();
    TenantService.updateOrgSetting(advancedSettingPayload)
      .then((res) => {
        dispatch(fetchCurrentTenantInfo());
        setIsWorkOrderAdhocBOMEnabled(payload.enable);
        setIsShowBomComponentAsLineItemInConversion(
          payload.showBomComponentAsLineItemInConversion
        );
        removeLoader();
      })
      .catch((err) => {
        console.log(
          'Error updating work order warehouse tagging settings',
          err
        );
        removeLoader();
      });
  };

  const showWOAdhocBOMLineItemAlert = () => {
    showAlertForAdhocBOM(
      !isShowBomComponentAsLineItemInConversion,
      false,
      'After enabling Work Order Adhoc BOM component line item, you will not be able to disable it.',
      'This Adhoc BOM component line item setting cannot be disabled.'
    );
  };

  const toggleButtonWithText = (
    value: boolean,
    isAdhocBOM: boolean = false
  ) => {
    return (
      <Toggle
        className="box-content"
        onChange={(checked: any) => {
          showLoader();
          WorkOrderService.areAllWorkOrdersCompleted().then(
            (isCompleted: boolean) => {
              removeLoader();
              if (isCompleted) {
                isAdhocBOM
                  ? showWOAdhocBOMAlert()
                  : showWOAdhocBOMLineItemAlert();
              } else {
                showAlert(
                  'Complete all Work Orders!',
                  `Please complete all the existing Work Orders before you ${
                    isAdhocEnabled ? 'disable' : 'enable'
                  } this setting.`
                );
              }
            },
            (err: any) => {
              removeLoader();
              console.error('Error fetching all WO status: ', err);
            }
          );
        }}
        isOn={value}
      />
    );
  };

  return (
    <div className="row parent-width border-m border-radius-m gap-4 p-r mb-xl">
      <div className="column parent-width">
        <DKLabel text="Adhoc BOM" className="fw-m mb-xs" />
        <DKLabel
          className="text-gray"
          text="Once you enable this, Adhoc BOM feature accessible in the Work Order"
          style={{ minWidth: 100 }}
        />
      </div>
      <div className="column" style={{ minWidth: 42 }}>
        {toggleButtonWithText(isWorkOrderAdhocBOMEnabled, true)}
        {/* {isWorkOrderAdhocBOMEnabled &&
        toggleButtonWithText(
          'Show BOM component as line item in conversion',
          isShowBomComponentAsLineItemInConversion,
          false
        )} */}
      </div>
    </div>
  );
};

export default WOAdhocBOMSetting;
