import ApiConstants from '../Constants/ApiConstants';
import { LocationDTO } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface ClientAPIConfig {
  Limit?: number;
  Page?: number;
  Query?: any;
  Search: any;
}

const defaultConfig: ClientAPIConfig = {
  Limit: 25,
  Page: 0,
  Search: ''
};

class BookKeeperClientService {
  static apiConfig: ClientAPIConfig = defaultConfig;

  static getClientOrTeamsGridData(type: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&search=${this.apiConfig.Search}&type=${type}`;

    const finalEndpoint: string =
      ApiConstants.URL.BOOKKEEPER.GET_CLIENT_TEAM_LIST + queryString;

    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getRoleGroups() {
    let url = 'rolegroups/ERP';
    return http
      .get(`${url}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static addClient(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.BOOKKEEPER.CLIENT.ADD_CLIENT;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to post client: ', err);
      return Promise.reject(err);
    });
  }

  static updateClient(payload: any) {
    const finalEndpoint: string =
      ApiConstants.URL.BOOKKEEPER.CLIENT.UPDATE_CLIENT;
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to updating client: ', err);
      return Promise.reject(err);
    });
  }

  static resendInvite(emailIds: any) {
    const finalEndpoint: string =
      ApiConstants.URL.BOOKKEEPER.CLIENT.RESEND_INVITE;
    const payload = {
      emailId: emailIds
    };
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while reinviting: ', err);
      return Promise.reject(err);
    });
  }

  static changeStatus(payload: any) {
    const finalEndpoint: string = `${ApiConstants.URL.BOOKKEEPER.CLIENT.STATUS_CHANGE}/?id=${payload.id}&status=${payload.status}`;
    return http.put(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error while trying to change status: ', err);
      return Promise.reject(err);
    });
  }

  static async checkContactExists(payload: any) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const endPoint = 'auth/checkcontact';
    const finalEndpoint: string = `${BASE_URL}${endPoint}`;
    return await http2.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error verifying contact: ', err);
      return Promise.reject(err);
    });
  }

  static checkClientSubscription(payload: any) {
    let url =
      ApiConstants.URL.BOOKKEEPER.CLIENT.CHECK_CLIENT_SUBSCRIPTION +
      `?Version=v2&UserID=${payload.userId}&TenantID=${payload.tenantId}`;
    return http2
      .get(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static cancelSubscription(payload: any) {
    const finalEndpoint: string =
      ApiConstants.URL.BOOKKEEPER.CLIENT.CANCEL_SUBSCRIPTION;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while cancelling the subscription: ', err);
      return Promise.reject(err);
    });
  }

  static bookKeeperSubscription = (payload: any) => {
    const headers: any = `?UserID=${payload.userId}&TenantID=${payload.tenantId}`;
    const finalEndpoint: string = `${ApiConstants.URL.BOOKKEEPER.CLIENT.MAKE_SUBSCRIPTION}${headers}`;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while fetching the bookeeper subscription: ', err);
      return Promise.reject(err);
    });
  };
}

export default BookKeeperClientService;
