import { TableColumnPopupType } from "../../../Constants/Constants";
import Utility, { getIsBill, getIsInvoice, getIsOrder, getIsPurchaseInwardQuotation, getIsQuotation, getIsRequestForQuotation, getIsSalesOrder, getIsShowTableColumn } from "../../../Utilities/Utility";

export class ProductTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isImageVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isHsnVisible: boolean;
    isProductCustomFieldVisible: boolean;
    isQuantityVisible: boolean;
    isQtyConvertedToWOVisible: boolean;
    isUomVisible: boolean;
    isQuantityWithUomVisible: boolean;
    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;
    isUnitPriceVisible: boolean;
    isAmountVisible: boolean;
    isProductDiscountVisible: boolean;
    isProductTaxVisible: boolean;
    isProductTaxAmountVisible: boolean;
    isAmountBeforeTaxVisible: boolean;
    isProductGlobalDiscountVisible: boolean;
    isProductUnitPriceAfterDiscountVisible: boolean;
    isProductDiscountPerUnitVisible: boolean;
    isExpectedDeliveryDateVisible: boolean;
    isAvailableQuantityVisible: boolean;
    isSupplierPartDescriptionVisible: boolean;
    isSupplierPartNameVisible: boolean;
    isSupplierPartNumberVisible: boolean;

    lineNumber: number;
    code: number;
    image: number;
    name: number;
    description: number;
    nameWithDescription: number;
    hsn: number;
    productCustomField: number;
    quantity: number;
    uom: number;
    quantityWithUom: number;
    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    manufacturingDate: number;
    expiryDate: number;
    unitPrice: number;
    amount: number;
    productDiscount: number;
    productGlobalDiscount: number;
    productUnitPriceAfterDiscount: number;
    productDiscountPerUnit: number;
    productTax: number;
    productTaxAmount: number;
    amountBeforeTax: number;
    expectedDeliveryDate: number;
    qtyConvertedToWo: number;
    availableQuantity: number;
    supplierPartDescription: number;
    supplierPartName: number;
    supplierPartNumber: number;

    originalLineNumber: number;
    originalCode: number;
    originalImage: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalHsn: number;
    originalProductCustomField: number;
    originalQuantity: number;
    originalUom: number;
    originalQuantityWithUom: number;
    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;
    originalUnitPrice: number;
    originalAmount: number;
    originalProductDiscount: number;
    originalProductGlobalDiscount: number;
    originalProductUnitPriceAfterDiscount: number;
    originalProductDiscountPerUnit: number;
    originalProductTax: number;
    originalProductTaxAmount: number;
    originalAmountBeforeTax: number;
    originalExpectedDeliveryDate: number;
    originalQtyConvertedToWo: number;
    originalAvailableQuantity: number;
    originalSupplierPartDescription: number;
    originalSupplierPartName: number;
    originalSupplierPartNumber: number;

    productCustomFieldList: any;
    productGlobalDiscountList: any;

    constructor(data: any) {
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = true
        this.isHsnVisible = false
        this.isProductCustomFieldVisible = true
        this.isUnitPriceVisible = true
        this.isQuantityVisible = true
        this.isUomVisible = true
        this.isQuantityWithUomVisible = true
        this.isSerialBatchVisible = true
        this.isSerialNumberVisible = true
        this.isBatchNumberVisible = true
        this.isManufacturingDateVisible = true
        this.isExpiryDateVisible = true
        this.isAmountVisible = true
        this.isProductTaxVisible = true
        this.isProductTaxAmountVisible = true
        this.isProductDiscountVisible = true
        this.isProductGlobalDiscountVisible = true
        this.isAmountBeforeTaxVisible = true
        this.isLineNumberVisible = true
        this.isImageVisible = true
        this.isProductUnitPriceAfterDiscountVisible = false
        this.isProductDiscountPerUnitVisible = false
        this.isExpectedDeliveryDateVisible = false
        this.isQtyConvertedToWOVisible = false
        this.isProductCustomFieldVisible = true;
        this.isAvailableQuantityVisible = false;
        this.isSupplierPartDescriptionVisible = false;
        this.isSupplierPartNameVisible = false;
        this.isSupplierPartNumberVisible = false;

        this.isProductCustomFieldVisible = true;

        this.lineNumber = 3
        this.code = 6
        this.name = 11.5
        this.description = 16

        this.nameWithDescription = this.name + this.description
        this.hsn = 5
        this.productCustomField = 5
        this.quantity = 5.5
        this.uom = 4.5
        this.quantityWithUom = this.quantity + this.uom
        this.serialBatch = 14
        this.serialNumber = 3
        this.batchNumber = 3
        this.manufacturingDate = 4
        this.expiryDate = 4
        this.unitPrice = 5.5
        this.amount = 9.5
        this.productTax = 4.5
        this.productTaxAmount = 4.5
        this.productDiscount = 4.5
        this.productGlobalDiscount = 4.5
        this.amountBeforeTax = 7.5
        this.image = 4
        this.productUnitPriceAfterDiscount = 4.5
        this.productDiscountPerUnit = 4.5
        this.expectedDeliveryDate = 4
        this.qtyConvertedToWo = 5
        this.availableQuantity = 5
        this.supplierPartDescription = 11.5;
        this.supplierPartName = 7.5;
        this.supplierPartNumber = 7.5;

        this.originalLineNumber = 3
        this.originalCode = 6
        this.originalName = 11.5
        this.originalDescription = 16

        this.originalNameWithDescription = this.originalName + this.originalDescription
        this.originalHsn = 5
        this.originalProductCustomField = 5
        this.originalQuantity = 5.5
        this.originalUom = 4.5
        this.originalQuantityWithUom = this.originalQuantity + this.originalUom
        this.originalSerialBatch = 14
        this.originalSerialNumber = 3
        this.originalBatchNumber = 3
        this.originalManufacturingDate = 4
        this.originalExpiryDate = 4
        this.originalUnitPrice = 5.5
        this.originalAmount = 9.5
        this.originalProductTax = 4.5
        this.originalProductTaxAmount = 4.5
        this.originalProductDiscount = 4.5
        this.originalProductGlobalDiscount = 4.5
        this.originalProductUnitPriceAfterDiscount = 4.5
        this.originalProductDiscountPerUnit = 4.5
        this.originalAmountBeforeTax = 7.5
        this.originalImage = 4
        this.originalExpectedDeliveryDate = 4
        this.originalQtyConvertedToWo = 5
        this.originalAvailableQuantity = 5
        this.originalSupplierPartDescription = 7.5;
        this.originalSupplierPartName = 7.5;
        this.originalSupplierPartNumber = 7.5;

        this.productCustomFieldList = undefined
        this.productGlobalDiscountList = undefined

        this.setCustomWidthForIndiaTaxTemplate(data)
    }

    setCustomWidthForIndiaTaxTemplate(data: any) {
        if (Utility.getIsIndiaCountry(data)) {
            if (getIsQuotation(data.type) ||
                getIsInvoice(data.type) ||
                getIsRequestForQuotation(data.type) ||
                getIsPurchaseInwardQuotation(data.type) ||
                getIsSalesOrder(data.type) ||
                getIsBill(data.type) ||
                getIsOrder(data.type)) {
                this.productTax = 20.5
                this.originalProductTax = 20.5
            }
        }
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        this.isHsnVisible = getIsShowTableColumn(columnData, TableColumnPopupType.hsn)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)
        this.isQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantity)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isQuantityWithUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityWithUom)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSerialNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialNumber)
        this.isBatchNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchNumber)
        this.isManufacturingDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.manufacturingDate)
        this.isExpiryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expiryDate)
        this.isUnitPriceVisible = getIsShowTableColumn(columnData, TableColumnPopupType.unitPrice)
        this.isAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amount)
        this.isProductTaxVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productTax)
        this.isProductTaxAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productTaxAmount)
        this.isProductDiscountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productDiscount)
        this.isProductGlobalDiscountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productGlobalDiscount)
        this.isProductUnitPriceAfterDiscountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productUnitPriceAfterDiscount)
        this.isProductDiscountPerUnitVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productDiscountPerUnit)
        this.isAmountBeforeTaxVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amountBeforeTax)
        this.isImageVisible = getIsShowTableColumn(columnData, TableColumnPopupType.image)
        this.isExpectedDeliveryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expectedDeliveryDate)
        this.isQtyConvertedToWOVisible = getIsShowTableColumn(columnData, TableColumnPopupType.qtyConvertedToWo)
        this.isAvailableQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.availableQuantity)
        this.isSupplierPartDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.supplierPartDescription)
        this.isSupplierPartNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.supplierPartName)
        this.isSupplierPartNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.supplierPartNumber)
        var width = 5
        var maxWidth = 20

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }

        var productGlobalDiscountList = columnData.filter((x: any) => x.globalDiscountAccountCode !== undefined)
        if (productGlobalDiscountList.length > 0) {
            this.isProductGlobalDiscountVisible = false
            if (this.productGlobalDiscountList === undefined) {
                //assign product custom list with corresponding flag
                this.productGlobalDiscountList = []
                if (productGlobalDiscountList.length > 4) {
                    width = maxWidth / productGlobalDiscountList.length
                }

                productGlobalDiscountList.forEach((element: any) => {
                    var item = {} as any
                    item.globalDiscountAccountCode = element.globalDiscountAccountCode
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productGlobalDiscountList.push(item)
                });
            }
            else {
                this.productGlobalDiscountList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.globalDiscountAccountCode === element.globalDiscountAccountCode)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription
        this.hsn = this.originalHsn
        this.productCustomField = this.originalProductCustomField
        this.quantity = this.originalQuantity
        this.uom = this.originalUom
        this.quantityWithUom = this.originalQuantityWithUom
        this.serialBatch = this.originalSerialBatch
        this.serialNumber = this.originalSerialNumber
        this.batchNumber = this.originalBatchNumber
        this.manufacturingDate = this.originalManufacturingDate
        this.expiryDate = this.originalExpiryDate
        this.unitPrice = this.originalUnitPrice
        this.amount = this.originalAmount
        this.productTax = this.originalProductTax
        this.productTaxAmount = this.originalProductTaxAmount
        this.productDiscount = this.originalProductDiscount
        this.productGlobalDiscount = this.originalProductGlobalDiscount
        this.productUnitPriceAfterDiscount = this.originalProductUnitPriceAfterDiscount 
        this.productDiscountPerUnit = this.originalProductDiscountPerUnit
        this.amountBeforeTax = this.originalAmountBeforeTax
        this.lineNumber = this.originalLineNumber
        this.image = this.originalImage
        this.expectedDeliveryDate = this.originalExpectedDeliveryDate
        this.qtyConvertedToWo = this.originalQtyConvertedToWo
        this.availableQuantity = this.originalAvailableQuantity
        this.supplierPartDescription = this.originalSupplierPartDescription;
        this.supplierPartName = this.originalSupplierPartName;
        this.supplierPartNumber = this.originalSupplierPartNumber;

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        this.hsn = 0
        this.quantity = 0
        this.uom = 0
        this.quantityWithUom = 0
        this.serialBatch = 0
        this.serialNumber = 0
        this.batchNumber = 0
        this.manufacturingDate = 0
        this.expiryDate = 0
        this.unitPrice = 0
        this.amount = 0
        this.productTax = 0
        this.productTaxAmount = 0;
        this.productDiscount = 0
        this.productGlobalDiscount = 0
        this.productUnitPriceAfterDiscount = 0
        this.productDiscountPerUnit = 0
        this.amountBeforeTax = 0
        this.lineNumber = 0
        this.image = 0
        this.expectedDeliveryDate = 0
        this.qtyConvertedToWo = 0
        this.availableQuantity = 0
        this.supplierPartDescription = 0;
        this.supplierPartName = 0;
        this.supplierPartNumber = 0;

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isImageVisible) {
            totalWidth += this.image;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isHsnVisible) {
            totalWidth += this.hsn;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }
        if (this.isQuantityVisible) {
            totalWidth += this.quantity;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isQuantityWithUomVisible) {
            totalWidth += this.quantityWithUom;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }
        if (this.isUnitPriceVisible) {
            totalWidth += this.unitPrice;
        }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }
        if (this.isProductTaxVisible) {
            totalWidth += this.productTax;
        }
        if (this.isProductTaxAmountVisible) {
            totalWidth += this.productTaxAmount;
        }
        if (this.isProductDiscountVisible) {
            totalWidth += this.productDiscount;
        }
        if (this.isProductGlobalDiscountVisible) {
            totalWidth += this.productGlobalDiscount;
        }
        if (this.isProductUnitPriceAfterDiscountVisible) {
            totalWidth += this.productUnitPriceAfterDiscount;
        }
        if (this.isProductDiscountPerUnitVisible) {
            totalWidth += this.productDiscountPerUnit;
        }
        if (this.isAmountBeforeTaxVisible) {
            totalWidth += this.amountBeforeTax;
        }
        if (this.isExpectedDeliveryDateVisible) {
            totalWidth += this.expectedDeliveryDate;
        }
        if (this.isQtyConvertedToWOVisible) {
            totalWidth += this.qtyConvertedToWo;
        }
        if (this.isAvailableQuantityVisible) {
            totalWidth += this.availableQuantity;
        }
        if (this.isSupplierPartDescriptionVisible) {
            totalWidth += this.supplierPartDescription;
        }
        if (this.isSupplierPartNameVisible) {
            totalWidth += this.supplierPartName;
        }
        if (this.isSupplierPartNumberVisible) {
            totalWidth += this.supplierPartNumber;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }
        
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isImageVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isHsnVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityWithUomVisible) {
            totalSelectedElement += 1
        }

        
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isUnitPriceVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductTaxVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductTaxAmountVisible) {
            totalSelectedElement += 1;
        }
        if (this.isProductDiscountVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductGlobalDiscountVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductUnitPriceAfterDiscountVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductDiscountPerUnitVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountBeforeTaxVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpectedDeliveryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isQtyConvertedToWOVisible) {
            totalSelectedElement += 1
        }
        if (this.isAvailableQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isSupplierPartDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isSupplierPartNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isSupplierPartNumberVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isImageVisible) {
            this.image = this.originalImage + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        if (this.isHsnVisible) {
            this.hsn = this.originalHsn + additionalWidth
        }
        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }
        if (this.isQuantityVisible) {
            this.quantity = this.originalQuantity + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isQuantityWithUomVisible) {
            this.quantityWithUom = this.originalQuantityWithUom + additionalWidth
        }

        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth
        }
        if (this.isUnitPriceVisible) {
            this.unitPrice = this.originalUnitPrice + additionalWidth
        }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }
        if (this.isProductTaxVisible) {
            this.productTax = this.originalProductTax + additionalWidth
        }
        if (this.isProductTaxAmountVisible) {
            this.productTaxAmount = this.originalProductTaxAmount + additionalWidth;
        }
        if (this.isProductDiscountVisible) {
            this.productDiscount = this.originalProductDiscount + additionalWidth
        }
        if (this.isProductGlobalDiscountVisible) {
            this.productGlobalDiscount = this.originalProductGlobalDiscount + additionalWidth
        }
        if (this.isProductUnitPriceAfterDiscountVisible) {
            this.productUnitPriceAfterDiscount = this.originalProductUnitPriceAfterDiscount + additionalWidth
        }
        if (this.isProductDiscountPerUnitVisible) {
            this.productDiscountPerUnit = this.originalProductDiscountPerUnit + additionalWidth
        }
        if (this.isAmountBeforeTaxVisible) {
            this.amountBeforeTax = this.originalAmountBeforeTax + additionalWidth
        }
        if (this.isExpectedDeliveryDateVisible) {
            this.expectedDeliveryDate = this.originalExpectedDeliveryDate + additionalWidth
        }
        if (this.isQtyConvertedToWOVisible) {
            this.qtyConvertedToWo = this.originalQtyConvertedToWo + additionalWidth
        }
        if (this.isAvailableQuantityVisible) {
            this.availableQuantity = this.originalAvailableQuantity + additionalWidth
        }
        if (this.isSupplierPartDescriptionVisible) {
            this.supplierPartDescription = this.originalSupplierPartDescription + additionalWidth
        }
        if (this.isSupplierPartNameVisible) {
            this.supplierPartName = this.originalSupplierPartName + additionalWidth
        }
        if (this.isSupplierPartNumberVisible) {
            this.supplierPartNumber = this.originalSupplierPartNumber + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }

    getProductGlobalDiscountWidthByGlobalDiscountAccountCode(globalDiscountAccountCode: string) {
        if (this.productGlobalDiscountList !== undefined) {
            var item = this.productGlobalDiscountList.find((x: any) => x.globalDiscountAccountCode === globalDiscountAccountCode)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}