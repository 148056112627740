import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PriceBook from '../../Services/PriceBook';
import { RootState } from '../Store';

export interface PriceBookState {
  priceBookList: any;
}

const initialState: PriceBookState = {
  priceBookList: {}
};
export const fetchPriceBookList = createAsyncThunk(
  'fetchPriceBookList',
  async (data: any, thunkAPI) => {
    const response = await PriceBook.getPriceBookList(data);
    return response;
  }
);

export const PriceBookSlice = createSlice({
  name: 'PriceBook',
  initialState,
  reducers: {
    addNewPriceBookInStore: (state, action: PayloadAction<any>) => {
      const priceBook: any = action.payload;
      state.priceBookList?.content?.unshift(priceBook);
    },
    updatePriceBookInStore: (state, action: PayloadAction<any>) => {
      const priceBook: any = action.payload;
      const existingIndex = state.priceBookList?.content?.findIndex(
        (pb: any) => pb.id === priceBook?.id
      );
      if (existingIndex !== -1 && state.priceBookList?.content?.length) {
        state.priceBookList.content[existingIndex] = priceBook;
      }
    },
    deletePriceBookFromStore: (state, action: PayloadAction<any>) => {
      const priceBook: any = action.payload;
      const existingIndex = state.priceBookList?.content?.findIndex(
        (pb: any) => pb.id === priceBook?.id
      );
      if (existingIndex !== -1 && state.priceBookList?.content?.length) {
        state.priceBookList?.content?.splice(existingIndex, 1);
      }
    },
    updatePriceBookStatusInStore: (state, action: PayloadAction<any>) => {
      const priceBookId: any = action.payload?.id;
      const existingIndex = state.priceBookList?.content?.findIndex(
        (pb: any) => pb.id === priceBookId
      );
      if (existingIndex !== -1 && state.priceBookList?.content?.length) {
        state.priceBookList.content[existingIndex].active =
          action.payload?.active;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPriceBookList.fulfilled, (state, action: any) => {
      state.priceBookList = action?.payload || {};
    });
  }
});

export const {
  addNewPriceBookInStore,
  updatePriceBookInStore,
  deletePriceBookFromStore,
  updatePriceBookStatusInStore
} = PriceBookSlice.actions;

export const priceBookSelector = (state: RootState) =>
  state.priceBook.priceBookList;

export default PriceBookSlice.reducer;
