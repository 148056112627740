import CustomFieldManager from "../../Manager/CustomFieldManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"

export default class StockIssueParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

         //ref number
        templateDataToReturn.refNumber = ''
        templateDataToReturn.date = ''

        if(this.getStockIssue(dataFromAPI)) {

            //custom field
            templateDataToReturn.customFields = this.getStockIssue(dataFromAPI).customField ?? []
            CustomFieldManager.setCustomFields(this.getStockIssue(dataFromAPI).customField ?? [])
            templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

            templateDataToReturn.refNumber = this.getStockIssue(dataFromAPI).documentSequenceCode ?? ''
            let date = this.getStockIssue(dataFromAPI).stockIssueDate ?? ''
            if(date !== '') {
                templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(date), templateDataToReturn.dateFormat)
            }

            templateDataToReturn.stockRequestNo = this.getStockIssue(dataFromAPI).stockRequestCode ?? ''
            templateDataToReturn.createdBy = this.getStockIssue(dataFromAPI).stockIssueCreatedByName ?? ''
            templateDataToReturn.stockRequestCreatedBy = this.getStockIssue(dataFromAPI).stockRequestCreatedByName ?? ''
            templateDataToReturn.stockRequestApprovedBy = this.getStockIssue(dataFromAPI).stockRequestApprovedByName ?? ''

            //assign line item
            templateDataToReturn.lineItems = this.getLineItem(this.getStockIssue(dataFromAPI), templateDataToReturn.dateFormat)

            let notes = this.getStockIssue(dataFromAPI).memo ?? ''
            if (notes !== '') {
                templateDataToReturn.notes = notes
            }
            
            templateDataToReturn.srcWarehouse = ''
            let srcWarehouseCodes = []
            if (templateDataToReturn.lineItems.length > 0) {
                templateDataToReturn.lineItems.forEach(element => {
                    if (element.sourceWarehouseName && element.sourceWarehouseName !== null) {
                        srcWarehouseCodes.push(element.sourceWarehouseName)
                    }
                });

                if (srcWarehouseCodes.length > 0) {
                    templateDataToReturn.srcWarehouse = srcWarehouseCodes.join(', ')
                }
            }
        }
        

        return templateDataToReturn
    }


    static getLineItem(stockIssue, dateFormat) {
        var lineItems =  []
        var requiredItems = []

        if (stockIssue.stockRequestItems && stockIssue.stockRequestItems !== null) {
            if (stockIssue.stockRequestItems.length > 0) {
                requiredItems = stockIssue.stockRequestItems
            }
        }

        if (stockIssue.stockIssueItems && stockIssue.stockIssueItems !== null) {
            if (stockIssue.stockIssueItems.length > 0) {
                stockIssue.stockIssueItems.forEach((element, index) => {
                    var item = {...element}
                    item.lineNumber = index + 1
                    item.name = element.productName ?? ''
                    item.description = element.productName ?? ''
                    item.code = element.productSequenceCode ?? ''
                    item.requiredQty = element.productQuantity
                    item.customFields = element.customField

                    if (element.stockTransferWarehouseInventoryData && element.stockTransferWarehouseInventoryData !== null) {
                        item.issuedQty = element.stockTransferWarehouseInventoryData.quantity
                        
                        item.requiredQty = element.stockTransferWarehouseInventoryData.quantity
                        let requiredItem = requiredItems.find(x => x.documentSequenceCode === item.code)
                        if(requiredItem) {
                            item.requiredQty = requiredItem.productQuantity ?? item.issuedQty
                        }

                        item.sourceWarehouseName = element.stockTransferWarehouseInventoryData.srcWarehouseName ?? ''
                        item.sourceWarehouseCode = element.stockTransferWarehouseInventoryData.srcWarehouseCode ?? ''
                        item.targetWarehouseName = element.stockTransferWarehouseInventoryData.destWarehouseName ?? ''
                        item.targetWarehouseCode = element.stockTransferWarehouseInventoryData.destWarehouseCode ?? ''
                        if (element.documentUOMSchemaDefinition?.name !== undefined) {
                            item.uom = element.documentUOMSchemaDefinition?.name
                        }
                        else {
                            item.uom = 'N/A'
                        }

                        item.advancedTracking = element.stockTransferWarehouseInventoryData.advancedTrackingType 
                        const advancedTrackingData = element.stockTransferWarehouseInventoryData.advancedTrackingData
                        if (advancedTrackingData && advancedTrackingData !== null && item.advancedTracking !== undefined) {
                            var serialBatch = []
                            var serialNumber = []
                            var batchNumber = []
                            var manufacturingDate = []
                            var expiryDate = []
                            let serialBatchList = []

                            item.serialBatchCustomFieldType = 'SERIAL_BATCH'
                            let serialBatchCustomFields = {}

                            advancedTrackingData.forEach(trackingItem => {
                                const sbNumber = trackingItem.destSerialBatchNumber
                                if (item.advancedTracking === 'BATCH') {
                                    batchNumber.push(sbNumber)
                                }
                                else if (item.advancedTracking === 'SERIAL') {
                                    serialNumber.push(sbNumber)
                                }

                                if (trackingItem.destSerialBatchNumber && trackingItem.destSerialBatchNumber !== null) {
                                    serialBatchList.push(trackingItem.destSerialBatchNumber)
                                }

                                if (trackingItem.manufacturingDate !== null && trackingItem.manufacturingDate !== undefined && trackingItem.manufacturingDate !== "") {
                                    manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.manufacturingDate), dateFormat))
                                }
                                if (trackingItem.expiryDate !== null && trackingItem.expiryDate !== undefined && trackingItem.expiryDate !== "" && item.advancedTracking === 'BATCH') {
                                    serialBatch.push(sbNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat) + ')')
                                    expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat))
                                }
                                else {
                                    serialBatch.push(sbNumber)
                                }
                                
                                if (sbNumber && sbNumber !== null) {
                                    serialBatchCustomFields[sbNumber] = element.customField
                                }
                            });
                            item.serialBatch = ''
                            item.serialNumber = ''
                            item.batchNumber = ''
                            item.manufacturingDate = ''
                            item.expiryDate = ''
                            
                            if (serialBatch.length > 0) {
                                item.serialBatch = serialBatch.join('\n')
                            }
                            if (serialNumber.length > 0) {
                                item.serialNumber = serialNumber.join('\n')
                            }
                            if (batchNumber.length > 0) {
                                item.batchNumber = batchNumber.join('\n')
                            }
                            if (manufacturingDate.length > 0) {
                                item.manufacturingDate = manufacturingDate.join('\n')
                            }
                            if (expiryDate.length > 0) {
                                item.expiryDate = expiryDate.join('\n')
                            }

                            item.serialBatchList = serialBatchList
                            item.serialBatchCustomFields = serialBatchCustomFields
                        }
                    }

                    lineItems.push(item)
                });
            }
        }

        return lineItems
    }

    static getStockIssue(dataFromAPI) {
        if (dataFromAPI.stockIssue && dataFromAPI.stockIssue !== null) {
            return dataFromAPI.stockIssue 
        }
        return undefined
    }
}
