import { DKLabel, MultiBar } from 'deskera-ui-library';
import Utility from '../../Utility/Utility';
import { DOCUMENT_TYPE } from '../../Constants/Constant';
import { DKTooltipWrapper } from 'deskera-ui-library';
import NumberFormatService from '../../Services/NumberFormat';

// import DKDropdown from './DKDropdown';

function SummaryBarGraph(props: any) {
  return (
    <div
      className="border-radius-m parent-width shadow-s border-ms p-xl bg-white border-box column justify-content-between border-blue mobile-full-grid-width"
      style={{
        height: 150,
        backgroundColor: '#edf3fb',
        border: '1.5px solid #91b5e9'
      }}
    >
      <div className="column parent-width">
        <DKLabel text={props.title} className="fw-b fs-l" />
      </div>
      <div className="column parent-width">
        <div className="row justify-content-between mb-1">
          <div className="row">
            <DKLabel
              text={`<b>${props.leftTitle.title}</b>`}
              className={'100'}
              style={{
                whiteSpace: 'normal'
              }}
            />
            {(props?.docType === DOCUMENT_TYPE.INVOICE ||
              props?.docType === DOCUMENT_TYPE.BILL) &&
            props?.leftTotalAmount >= 1000 * 1000 &&
            props?.tenantCurrency !== 'INR' ? (
              <DKTooltipWrapper
                content={`${Utility.getCurrencySymbolFromCode(
                  props?.tenantCurrency
                )} ${NumberFormatService.getNumber(props?.leftTotalAmount)}`}
                tooltipClassName="bg-deskera-secondary width-auto fw-b"
              >
                <DKLabel
                  className="whitespace-nowrap ml-1"
                  text={props.leftTitle.value}
                />
              </DKTooltipWrapper>
            ) : (
              <DKLabel
                className="whitespace-nowrap ml-1"
                text={props.leftTitle.value}
              />
            )}
          </div>
          <div className="row justify-content-end">
            <DKLabel
              text={`<b>${props.rightTitle.title}</b>`}
              className={''}
              style={{
                whiteSpace: 'normal'
              }}
            />
            {(props?.docType === DOCUMENT_TYPE.INVOICE ||
              props?.docType === DOCUMENT_TYPE.BILL) &&
            props?.rightTotalAmount >= 1000 * 1000 &&
            props?.tenantCurrency !== 'INR' ? (
              <DKTooltipWrapper
                content={`${Utility.getCurrencySymbolFromCode(
                  props?.tenantCurrency
                )} ${NumberFormatService.getNumber(props?.rightTotalAmount)}`}
                tooltipClassName="bg-deskera-secondary width-auto fw-b"
              >
                <DKLabel
                  className="whitespace-nowrap ml-1"
                  text={props?.rightTitle.value}
                />
              </DKTooltipWrapper>
            ) : (
              <DKLabel
                className="whitespace-nowrap ml-1"
                text={props.rightTitle.value}
              />
            )}
          </div>
        </div>
        {<MultiBar data={props.data} showLegend={true} />}
      </div>
    </div>
  );
}

export default SummaryBarGraph;
