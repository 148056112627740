import ic_contact from '../../../Assets/menu/ic_contact.svg';
import ic_bill from '../../../Assets/menu/ic_bill.svg';
import ic_product from '../../../Assets/menu/ic_product.svg';
import ic_invoice from '../../../Assets/menu/ic_invoice.svg';
import ic_purchase_order from '../../../Assets/menu/ic_order.svg';
import ic_so_dark from '../../../Assets/menu/ic_so_dark.svg';
import ic_quote from '../../../Assets/menu/ic_quote.svg';
import ic_reports from '../../../Assets/menu/ic_list.png';
import { LABELS } from '../../../Constants/Constant';
import {
  ModulePermissionEnum,
  RolePermissionUtil
} from '../../../RolePermission/RolePermissionUtil';
import Utility from '../../../Utility/Utility';
import { isSalesOrderVisible } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

export const LabelMenuList = (roles: any) => {
  let menuList = [];
  if (roles && roles.length > 0 && roles[0].apps) {
    menuList = [
      {
        title: Utility.isUSorg() ? LABELS.ESTIMATE : LABELS.QUOTES,
        icon: ic_quote,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.CREATE)
      },
      {
        title: LABELS.INVOICES,
        icon: ic_invoice,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE)
      },
      {
        title: LABELS.REQUISITION,
        icon: ic_reports,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.REPORTS.CREATE)
      },
      {
        title: LABELS.PURCHASE_ORDERS,
        icon: ic_purchase_order,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE
        )
      },
      {
        title: LABELS.BILLS,
        icon: ic_bill,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE)
      },
      {
        title: LABELS.CONTACT,
        icon: ic_contact,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
      },
      {
        title: LABELS.PRODUCTS,
        icon: ic_product,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.PRODUCTS.CREATE)
      },
      {
        title: LABELS.SMART_REPORT,
        icon: ic_reports,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.REPORTS.CREATE)
      }
    ];
    if (
      isSalesOrderVisible() &&
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE)
    ) {
      menuList.splice(1, 0, {
        title: LABELS.SALES_ORDER,
        icon: ic_so_dark,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE)
      });
    }
    return menuList;
  } else {
    return [];
  }
};
