import { useCallback, useEffect, useState } from 'react';
import { DKButton, DKLabel, DKDataGrid, INPUT_TYPE } from 'deskera-ui-library';
import {
  DOC_TYPE,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT
} from '../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../Constants/Enum';
import Utility from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { useAppSelector } from '../../Redux/Hooks';

interface IViewAdvancedTrackingReservedDataProps {
  selectedRowData: any;
  documentType: any;
  onCancel: () => void;
}

const COLUMN_KEYS = {
  WAREHOUSE_NAME: 'warehouseName',
  SERIAL_BATCH_NAME: 'serialBatchNumber',
  ROW: 'row',
  RACK: 'rack',
  BIN: 'bin',
  RESERVED_QUANTITY: 'reservedQuantity'
};

const COLUMNS = [
  {
    id: COLUMN_KEYS.WAREHOUSE_NAME,
    key: COLUMN_KEYS.WAREHOUSE_NAME,
    name: 'Warehouse Name',
    type: INPUT_TYPE.TEXT,
    width: 260,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.SERIAL_BATCH_NAME,
    key: COLUMN_KEYS.SERIAL_BATCH_NAME,
    name: 'Batch Name',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.ROW,
    key: COLUMN_KEYS.ROW,
    name: 'Row',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.RACK,
    key: COLUMN_KEYS.RACK,
    name: 'Rack',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.BIN,
    key: COLUMN_KEYS.BIN,
    name: 'Bin',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.RESERVED_QUANTITY,
    key: COLUMN_KEYS.RESERVED_QUANTITY,
    name: 'Reserved Quantity',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  }
];

const ViewAdvancedTrackingReservedData = (
  props: IViewAdvancedTrackingReservedDataProps
) => {
  const getLineItemKey = () => {
    let lineItemKey = '';
    if (props.documentType === DOC_TYPE.INVOICE) {
      lineItemKey = 'salesInvoiceItems';
    } else if (props.documentType === DOC_TYPE.SALES_ORDER) {
      lineItemKey = 'salesOrderItems';
    }
    return lineItemKey;
  };

  const onTabSelect = (
    lineItemRow: any,
    keyIndex: number,
    shouldReturnRows: boolean = false
  ) => {
    let tempRows: any[] = [];
    lineItemRow?.reservedQuantitiesData?.forEach((item: any) => {
      if (
        Utility.isEmpty(item?.advancedTrackingType) ||
        item?.advancedTrackingType === ADVANCE_TRACKING.NONE
      ) {
        if (item?.reservedQuantity > 0) {
          let obj = {
            warehouseName: item?.warehouseName,
            reservedQuantity: item?.reservedQuantity,
            row: item?.rowName,
            rack: item?.rackName,
            bin: item?.binName
          };
          tempRows.push(obj);
        }
      } else {
        let reservedQuantityFulfilled =
          item?.advancedTrackingMetaDtos?.[0]?.reservedQuantityFulfilled ?? 0;
        let reservedQtyFromDto =
          item?.advancedTrackingMetaDtos?.[0]?.reservedQuantity ?? 0;
        let totalReservedQty = reservedQtyFromDto - reservedQuantityFulfilled;
        totalReservedQty = Math.abs(totalReservedQty);
        totalReservedQty = Utility.roundingOff(
          totalReservedQty,
          QTY_ROUNDOFF_PRECISION
        );
        if (totalReservedQty > 0) {
          let obj = {
            warehouseName: item?.advancedTrackingMetaDtos?.[0]?.warehouseName,
            reservedQuantity: totalReservedQty,
            serialBatchNumber:
              item?.advancedTrackingMetaDtos?.[0]?.serialBatchNumber,
            row: item?.advancedTrackingMetaDtos?.[0]?.rowName,
            rack: item?.advancedTrackingMetaDtos?.[0]?.rackName,
            bin: item?.advancedTrackingMetaDtos?.[0]?.binName
          };
          tempRows.push(obj);
        }
      }
    });

    if (shouldReturnRows) {
      return tempRows;
    } else {
      setSelectedTabIndex(keyIndex);
      setGridRows(tempRows);
      updatedColumnConfig(keyIndex);
    }
  };

  const lineItemDetails =
    props?.selectedRowData?.[getLineItemKey()]?.filter((ele: any) =>
      Utility.isNotEmpty(ele.reservedQuantitiesData)
    ) ?? [];
  const [gridColumns, setGridColumns] = useState(COLUMNS);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [gridRows, setGridRows] = useState<any[]>(
    onTabSelect(lineItemDetails?.[0], 0, true) ?? []
  );

  const tenantInfo: any = useAppSelector(activeTenantInfo);

  const rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
    (item: any) => item?.enable
  );

  const updatedColumnConfig = useCallback((updatedIndex: number) => {
    let config = [...gridColumns];

    if (Utility.isNotEmpty(rrbEnabled)) {
      let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
      config = config?.map((col: any) => {
        if (
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
        ) {
          const isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
            return i.name === col.key.toUpperCase() && i.enable;
          });
          if (isRRBEnabled) {
            col = {
              ...col,
              name: isRRBEnabled?.label
            };
          }
        }

        return col;
      });
    } else {
      config = config?.filter(
        (col: any) =>
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.ROW &&
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.RACK &&
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.BIN
      );
    }

    if (
      lineItemDetails?.[updatedIndex]?.product?.advancedTracking ===
      ADVANCE_TRACKING.NONE
    ) {
      config = config?.filter(
        (col: any) => col.key !== COLUMN_KEYS.SERIAL_BATCH_NAME
      );
    } else if (
      lineItemDetails?.[updatedIndex]?.product?.advancedTracking ===
      ADVANCE_TRACKING.SERIAL
    ) {
      config = config?.map((col: any) => {
        if (col.key === COLUMN_KEYS.SERIAL_BATCH_NAME) {
          col = {
            ...col,
            name: 'Serial Number'
          };
        }
        return col;
      });
    }

    // config.forEach((conf: any) => {
    //   switch (conf.key) {
    //     case COLUMN_KEYS.WAREHOUSE_NAME:
    //       conf.formatter = (obj: any) => {
    //         const whCode = obj?.rowData?.warehouseCode ?? '';
    //         let htmlText = `<div class="column parent-width">
    //             <span>${obj?.rowData?.warehouseName}</span>
    //             <span class="fs-s text-gray">(${whCode})</span>
    //           </div>`;
    //         return htmlText;
    //       };
    //       return;

    //     default:
    //       break;
    //   }
    // });
    setGridColumns([...config]);
  }, []);

  useEffect(() => {
    updatedColumnConfig(0);
  }, [updatedColumnConfig]);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={`Reserved Stock Details (${
              props?.selectedRowData?.documentSequenceCode ||
              props?.selectedRowData?.draftCode ||
              ''
            })`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    );
  };

  const getGrid = () => {
    return (
      <DKDataGrid
        title={``}
        // width={gridWidth}
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...gridRows]}
        columns={gridColumns}
        onRowUpdate={(data: any) => {}}
        onRowClick={(data: any) => {}}
        onSearch={(term: any) => {}}
      />
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 850,
          width: '90%',
          maxHeight: '95%',
          height: 650,
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div
          className="row parent-width p-l align-items-start"
          style={{ gap: 8 }}
        >
          {/* left */}
          <div
            className="parent-size overflow-scroll hide-scroll-bar"
            style={{
              width: '30%',
              height: 540
            }}
          >
            {lineItemDetails?.map((lineItem: any, key: number) => {
              return (
                <div
                  key={
                    props?.documentType === DOC_TYPE.INVOICE
                      ? lineItem?.salesInvoiceItemCode
                      : lineItem?.salesOrderItemCode
                  }
                  className={`column cursor-hand rounded border align-items-center mb-r p-r ${
                    selectedTabIndex === key
                      ? 'bg-blue-100 border-blue shadow-m'
                      : 'bg-gray1'
                  }`}
                  onClick={() => {
                    onTabSelect(lineItem, key, false);
                  }}
                >
                  <DKLabel text={lineItem?.product?.name} className="fw-b" />
                  <DKLabel
                    text={`(${lineItem?.product?.documentSequenceCode})`}
                    className="text-gray"
                  />
                </div>
              );
            })}
          </div>

          <div
            className="row parent-height bg-black"
            style={{
              width: 1.5,
              opacity: 0.1
            }}
          >
            {' '}
          </div>

          {/* right */}
          <div
            className="overflow-scroll hide-scroll-bar"
            style={{
              width: '70%',
              height: 540
            }}
          >
            {getGrid()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAdvancedTrackingReservedData;
