import { BOOKS_DATE_FORMAT, STATUS_TYPE } from '../../Constants/Constant';
import DateFormatService from '../../Services/DateFormat';
import Utility from '../../Utility/Utility';
import { INPUT_TYPE } from 'deskera-ui-library';
import { checkIfLineLevelCustomFieldIsValid } from '../DocumentForm/NewDocumentHelper';

export const getLineItemCFs = (
  lineItem: any,
  columns: any,
  allCustomFields: any
) => {
  let oldColConfigs = columns;
  let colConfigsWithOnlyCF = oldColConfigs?.filter(
    (item: any) => item.isCustomField
  );
  let newCfs: any[] = [];
  if (!Utility.isEmpty(allCustomFields)) {
    colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
      const cf: any = allCustomFields.find(
        (cfItem: any) => colConfigItem.id === cfItem.id
      );
      if (typeof cf !== 'undefined' && cf !== null) {
        let cfValue;
        if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
          cfValue = DateFormatService.getDateStrFromDate(
            new Date(lineItem[cf.id]),
            BOOKS_DATE_FORMAT['MM/DD/YYYY']
          );
        } else if (cf.fieldType.toLowerCase() === 'user') {
          const tempCF = cf?.attributes?.find(
            (attr: any) => attr.code === lineItem[cf.id]?.code
          );
          if (tempCF) {
            cfValue = tempCF.code;
          }
        } else if (
          cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
        ) {
          cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          if (cfValue === undefined) cfValue = lineItem[cf.id];
        } else {
          cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
        }

        newCfs.push({
          id: cf.id,
          code: cf.code,
          label: cf.label,
          module: 'BATCHSERIAL',
          shortName: cf.shortName,
          value: cfValue
        });
      }
    });
  }

  return newCfs;
};
export const addProductCustomFieldsToLineItem = (
  lineItem: any,
  allCustomFields: any,
  product?: any
) => {
  let cfs: any[] = [];
  if (!Utility.isEmpty(allCustomFields)) {
    // Set default values of CFs when new line is added
    lineItem?.customField?.forEach((productCF: any) => {
      const filteredCF = allCustomFields?.find(
        (field: any) =>
          field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
      );
      if (filteredCF) {
        let cfToUpdate = {
          id: filteredCF.id,
          shortName: filteredCF.shortName,
          module: filteredCF.module,
          code: filteredCF.code,
          label: filteredCF.label,
          value: ''
        };
        let valueOfCF = '';
        if (
          typeof productCF.value !== 'undefined' &&
          productCF.value !== null &&
          productCF.value !== ''
        ) {
          if (
            filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            lineItem[productCF.id] = DateFormatService.getDateFromStr(
              productCF.value,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (filteredCF.fieldType.toLowerCase() === 'user') {
            const tempCF = filteredCF?.attributes?.find(
              (attr: any) => attr.code === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            const tempCF = filteredCF?.attributes?.find(
              (attr: any) => attr.value === productCF.value
            );
            if (tempCF) {
              lineItem[productCF.id] = tempCF;
            }
          } else {
            lineItem[productCF.id] = productCF.value;
          }
          valueOfCF = productCF.value;
        } else {
          lineItem[productCF.id] = '';
        }
        cfToUpdate.value = valueOfCF;
        cfs.push(cfToUpdate);
      }
    });
  }
  return { ...lineItem, customField: cfs };
};

export const getDefaultCustomFieldValue = (
  allcustomField: any,
  lineItem: any
) => {
  if (!Utility.isEmpty(allcustomField)) {
    // Set default values of CFs when new line is added
    allcustomField?.forEach((item: any) => {
      if (item.status === STATUS_TYPE.ACTIVE) {
        if (
          typeof item.defaultValue !== 'undefined' &&
          item.defaultValue !== null &&
          item.defaultValue !== ''
        ) {
          if (item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            lineItem[item.id] = DateFormatService.getDateFromStr(
              item.defaultValue,
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else {
            lineItem[item.id] = item.defaultValue;
          }
        } else {
          lineItem[item.id] = '';
        }
      }
      lineItem = checkIfLineLevelCustomFieldIsValid(lineItem, allcustomField);
    });
  }
  return lineItem;
};

export const getCustomFieldDataForDisplayTable = (
  cfdata: any,
  cfFields: String[],
  allCustomFields: any
) => {
  const cfList: any = {};
  if (!Utility.isEmpty(cfdata) && !Utility.isEmpty(cfFields)) {
    let activeCFFields = allCustomFields?.filter(
      (field: any) => field.status === 'ACTIVE'
    );
    cfdata?.forEach((cf: any) => {
      let cfField = activeCFFields.find((field: any) => field.code === cf.code);
      if (Utility.isNotEmpty(cfField)) {
        const label: any = cf?.label;
        const shortName: any = cf?.shortName;
        if (label && cfFields.includes(label)) {
          cfList[label] = cf.value;
        } else if (shortName && cfFields.includes(shortName)) {
          cfList[shortName] = cf.value;
        }
      }
    });
  }
  return cfList;
};
