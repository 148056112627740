import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import CoaService from '../../Services/COA';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { deepClone } from '../../Utility/Utility';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchCoa = createAsyncThunk('coa', async () => {
  const response = await CoaService.getCOA();
  return response;
});

export const fetchSubTypes = createAsyncThunk(
  'multiLevelCoaParentType',
  async (code: any) => {
    const response = await CoaService.getMultiLevelSubTypes(code);
    return response;
  }
);

export const fetchParentAccounts = createAsyncThunk(
  'multiLevelCoaParentAccounts',
  async (codes: any) => {
    const response = await CoaService.getParentTypes(codes);
    return response;
  }
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const CoaSlice = createSlice({
  name: 'coa',
  initialState,
  reducers: {
    addCoaColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          allowAddOption: false
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(configsToStore, REMOTE_CONFIG_TABLES.COA);
    },
    addColumnToCoaColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    setCoa: (state, action: PayloadAction<any[]>) => {
      state.data.coaList = action.payload || [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoa.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCoa.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let CoaList: any = action.payload;
        state.data.coaList = CoaList;
      })
      .addCase(fetchSubTypes.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchSubTypes.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.subTypes = action.payload;
      })
      .addCase(fetchParentAccounts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchParentAccounts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.parentAccounts = action.payload;
      });
  }
});

export const {
  addCoaColumnConfig,
  addColumnToCoaColumnConfig,
  updateColumnConfig,
  setCoa
} = CoaSlice.actions;

export const selectCoa = (state: RootState) => state.coa.data.coaList;
export const selectSubTypes = (state: RootState) => state.coa.data.subTypes;
export const selectParentAccounts = (state: RootState) =>
  state.coa.data.parentAccounts;

export const selectCoaColumnConfig = (state: RootState) => {
  let columnConfig = deepClone(state.coa.columnConfig);
  if (state?.authInfo?.currentTenantInfo?.data?.country === 'US') {
    if (columnConfig && columnConfig.length > 0) {
      columnConfig.forEach((config) => {
        if (config.name === 'Type') {
          // @ts-ignore
          if (config.options.length > 0) {
            //   let options: any[] = []
            // @ts-ignore
            config.options.forEach((config: any) => {
              if (config.name.includes('Non-current')) {
                config.name = config.name.replace('Non-current', 'Long Term');
              }
            });
          }
        }
      });
    }
  }
  return columnConfig;
};

export const selectCOAColumnConfigTableId = (state: RootState) =>
  state.coa.configTableId;

export default CoaSlice.reducer;
