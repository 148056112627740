import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface FixedAssetAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  CustomField?: any;
}

export const defaultConfig: FixedAssetAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};
class FixedAssetsService {
  static apiConfig: FixedAssetAPIConfig;
  static abortController: any = null;

  static getFixedAssetByPage() {
    Utility.cancelRequest(FixedAssetsService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    const finalEndpoint: string =
    ApiConstants.URL.ASSET_BASE + ApiConstants.URL.FIXED_ASSET.GET_ASSETS + queryString;

    FixedAssetsService.abortController = Utility.createAbortController();
    return http2
      .get(`${finalEndpoint}`, {
        signal: FixedAssetsService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createFixedAsset(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.ASSET_BASE + ApiConstants.URL.FIXED_ASSET.GET_ASSETS;
    return http2.post(finalEndpoint, payload).catch((err: any) => {
      console.error('Error creating Asset Group: ', err);
      return Promise.reject(err);
    });
  }

  static upadteFixedAsset(payload: any, id: any) {
    const finalEndpoint: string = ApiConstants.URL.ASSET_BASE + ApiConstants.URL.FIXED_ASSET.UPDATE_ASSET(id);
    return http2.put(finalEndpoint, payload).catch((err: any) => {
      console.error('Error creating Asset Group: ', err);
      return Promise.reject(err);
    });
  }

  static deleteFixedAsset(id: number) {
    return http2
      .delete(`${ApiConstants.URL.ASSET_BASE + ApiConstants.URL.FIXED_ASSET.DELETE_FIXED_ASSET_BY_ID(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export default FixedAssetsService;
