import { useRef } from 'react';
import LabelRowView from './LabelRowView';
import { LabelCell, LabelRow, LabelTemplateData } from './LabelTemplateHelper';

interface LabelTemplateViewProps {
  labelTemplate: LabelTemplateData;
  updateRow?: (rowIndex: number, updatedRow: LabelRow) => void;
  cellToEdit?: LabelCell;
  setCellToEdit?: (labelCell: LabelCell) => void;
  deleteRow?: (rowIndex: number) => void;
  isReadOnly?: boolean;
  isReorderingRows?: boolean;
  setLabelTemplate?: (value: any) => void;
}

const LabelTemplateView: React.FC<LabelTemplateViewProps> = ({
  labelTemplate,
  updateRow,
  cellToEdit,
  setCellToEdit,
  deleteRow,
  isReadOnly = false,
  isReorderingRows,
  setLabelTemplate
}: LabelTemplateViewProps) => {
  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  const dragStart = (position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    if (setLabelTemplate) {
      setLabelTemplate((labelTemplate: LabelTemplateData) => {
        let updatedData: LabelTemplateData = { ...labelTemplate };
        const dragItemContent = updatedData.templateData[dragItem.current];
        updatedData.templateData.splice(dragItem.current, 1);
        updatedData.templateData.splice(
          dragOverItem.current,
          0,
          dragItemContent
        );
        return updatedData;
      });
      dragItem.current = null;
      dragOverItem.current = null;
    }
  };

  return (
    <div
      className="bg-white border"
      style={{
        width:
          labelTemplate.labelSettings?.width &&
          !isNaN(Number(labelTemplate.labelSettings?.width))
            ? Number(labelTemplate.labelSettings?.width) + 'in'
            : '6in',
        height:
          labelTemplate.labelSettings?.height &&
          !isNaN(Number(labelTemplate.labelSettings?.height))
            ? Number(labelTemplate.labelSettings?.height) + 'in'
            : 'auto',
        borderColor: '#000b'
      }}
    >
      {labelTemplate.templateData.map((row: any, index: number) => (
        <div
          key={index}
          style={{ cursor: isReorderingRows ? 'move' : 'unset' }}
          draggable={isReorderingRows}
          onDragStart={() => isReorderingRows && dragStart(index)}
          onDragEnter={() => isReorderingRows && dragEnter(index)}
          onDragOver={(e) => (isReorderingRows ? e.preventDefault() : null)}
          onDrop={() => isReorderingRows && drop()}
        >
          <LabelRowView
            data={row}
            rowIndex={index}
            updateRow={updateRow}
            cellToEdit={cellToEdit}
            setCellToEdit={setCellToEdit}
            deleteRow={deleteRow}
            labelSettings={labelTemplate.labelSettings}
            isReadOnly={isReadOnly || !!isReorderingRows}
          />
        </div>
      ))}
    </div>
  );
};

export default LabelTemplateView;
