import React, { useState } from 'react';
import { DKLabel, DKButton, DKCheckMark } from 'deskera-ui-library';
import PeppolRegistrationProcess from './PeppolRegistrationProcess';
import AuthService from '../Services/Auth';
import TenantService from '../Services/Tenant';
import RouteManager, { PAGE_ROUTES } from '../Managers/RouteManager';
import { useAppDispatch } from '../Redux/Hooks';
import { removeDraft } from '../Redux/Slices/DraftsSlice';
import { fetchCurrentTenantSetupStatus } from '../Redux/Slices/AuthSlice';
import { setShowSettings } from '../Redux/Slices/CommonDataSlice';

const Peppol = (props: any) => {
  const [showPeppolRegistration, setShowPeppolReigstation] = useState(false);
  const [dontShowMeAgain, setDontShowMeAgain] = useState(false);

  const dispatch = useAppDispatch();

  const getFooter = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKCheckMark
            title="Don't show this to me again"
            className="mt-s mb-2"
            isSelected={dontShowMeAgain}
            onClick={(value: string) => {
              setDontShowMeAgain(!dontShowMeAgain);
            }}
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={`I'll Pass`}
            className="bg-white border-m mr-r"
            onClick={() => handleClickPass()}
          />
          <DKButton
            title={'Add Info'}
            className="bg-button text-white"
            onClick={() => {
              props.onCancel();
              dispatch(removeDraft(props.draftId));
              dispatch(setShowSettings(true));
            }}
          />
        </div>
      </div>
    );
  };

  const handleClickPass = () => {
    props.onCancel();
    const payload = {
      onboardingStatusInfo: {
        sgEInvoiceChecked: dontShowMeAgain
      }
    };
    TenantService.updateOrgStatus(payload)
      .then((response: any) => {
        dispatch(fetchCurrentTenantSetupStatus());
      })
      .catch((error) => {
        console.error('Error updating tenant: ', error);
      });
  };

  return (
    <div style={{ zIndex: 9998 }} className="transparent-background asd">
      <div
        className="popup-window"
        style={{
          maxWidth: 600,
          width: '90%',
          maxHeight: '95%',
          height: '485px',
          padding: 0,
          overflowY: 'auto'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + '/e-invoice-lady.svg'
            })`,
            height: '349px'
          }}
          className="column parent-width p-h-r p-v-s"
        >
          <div className="column parent-width mt-4">
            <div className="row text-2xl text-white font-bold">Deskera is</div>
            <div className="row text-8xl text-white font-bold">PEPPOL</div>
            <div className="row text-8xl text-white font-bold">READY!</div>
            <div className="text-white text-left font-bold ml-1 width-80">
              <p style={{ whiteSpace: 'break-spaces' }} className="w-80">
                InvoiceNow paves the way for faster and more convenient
                processing of transactions between companies within the PEPPOL
                network.
              </p>
            </div>
            <div className="text-white  text-left font-bold ml-1">
              <p style={{ fontSize: '9px' }} className="w-80 mt-2">
                Learn more about PEPPOL and InvoiceNow
              </p>
            </div>
          </div>
        </div>
        <div className="column parent-width p-h-r p-v-s my-4">
          <div className="row">
            <div>
              Register for InvoiceNow, or update your PEPPOL ID if you already
              have one.
            </div>
          </div>
          <div className="row">
            <div
              onClick={() => setShowPeppolReigstation(true)}
              className="underline text-app cursor-pointer"
            >
              Show me how to register
            </div>
          </div>
        </div>
        {getFooter()}
      </div>
      {showPeppolRegistration && (
        <PeppolRegistrationProcess
          onCancel={() => setShowPeppolReigstation(false)}
        />
      )}
    </div>
  );
};

export default Peppol;
