import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import ExpenseService from '../../Services/Expense';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchExpenses = createAsyncThunk('expense', async () => {
  const response = await ExpenseService.getExpenseByPage();
  return response;
});

export const ExpenseSlice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addExpenseColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.configTableId = tableId;
      state.columnConfig = configsToStore;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.EXPENSE
      );
    },
    addColumnToExpenseColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    },
    updateExpenseDetails: (state, action: PayloadAction<any>) => {
      const expenseDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const expenseIndex: number = content.findIndex(
        (expense: any) => expense.id === expenseDetails.id
      );
      if (expenseIndex >= 0) {
        content[expenseIndex] = expenseDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenses.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addExpenseColumnConfig,
  updateExpenseDetails,
  updateColumnConfig
} = ExpenseSlice.actions;
export const selectExpenses = (state: RootState) => state.expenses.data;
export const selectExpenseColumnConfig = (state: RootState) =>
  state.expenses.columnConfig;
export const selectExpenseColumnConfigTableId = (state: RootState) =>
  state.expenses.configTableId;

export const selectExpenseDataLoadingStatus = (state: RootState) =>
  state.expenses.gridContentLoadingStatus;
