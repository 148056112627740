import {
  DKButton,
  DKLabel,
  DKRadioButton,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
export default function TaxValueEditForm(props: any) {
  const [taxAmount, setTaxAmount] = useState<any>(
    props.taxData?.rowData?.taxAmount || 0
  );
  const [isTaxGroup, setisTaxGroup] = useState<any>(false);
  const [taxDetailGroup, setTaxDetailGroup] = useState<any[]>([]);

  useEffect(() => {
    if (props.taxData?.rowData?.taxDetails?.length > 0) {
      setisTaxGroup(true);
      let tempTaxDetailGroup: any[] = [];
      props.taxData?.rowData?.taxDetails.forEach((taxRow: any) => {
        let row = {
          taxAmount: +taxRow?.taxAmount || 0,
          taxId: taxRow?.taxId,
          taxName: taxRow?.taxName
        };
        tempTaxDetailGroup.push(row);
      });
      setTaxDetailGroup(tempTaxDetailGroup);
    }
  }, []);
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={'Update Tax Value'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() =>
              props.setTaxChangeValue({
                rowIndex: props.taxData.rowIndex,
                taxAmount: taxAmount,
                taxDetailGroup: taxDetailGroup,
                isTaxGroup: isTaxGroup
              })
            }
          />
        </div>
      </div>
    );
  };

  const getTaxBody = () => {
    return (
      <div className="align-items-start pt-4">
        <div className=" align-items-start  parent-width">
          <div className="row parent-width">
            <DKInput
              title="Tax Amount"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={taxAmount}
              className=""
              onChange={(val: any) => {
                setTaxAmount(val);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getTaxGroupBody = (taxGroupDetails: any) => {
    return (
      <div className="align-items-start pt-4">
        <div className=" align-items-start  parent-width">
          {taxGroupDetails.map((taxGroupRow: any, index: any) => {
            return (
              <div className="row parent-width">
                <DKInput
                  title={`${taxGroupRow.taxName}`}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.NUMBER}
                  value={taxGroupRow.taxAmount}
                  className=""
                  onChange={(val: any) => {
                    let tempTaxDetailGroup = [...taxDetailGroup];
                    tempTaxDetailGroup[index].taxAmount = val;
                    setTaxDetailGroup(tempTaxDetailGroup);
                    let tempTaxAmount = 0;
                    tempTaxDetailGroup.forEach((rowData) => {
                      tempTaxAmount = tempTaxAmount + Number(rowData.taxAmount);
                    });
                    setTaxAmount(tempTaxAmount);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 500,
            width: '90%',
            maxHeight: '85%',
            height: 350,
            padding: 0,
            paddingBottom: 10,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="p-h-xl parent-width overflow-y-auto">
            {isTaxGroup ? getTaxGroupBody(taxDetailGroup) : getTaxBody()}
          </div>
        </div>
      </div>
    </>
  );
}
