import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface ClassesAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

const defaultConfig: ClassesAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};

class ClassesService {
  static apiConfig: ClassesAPIConfig;

  static getClassesByDimensionId() {
    return http.get(ApiConstants.URL.CLASSES.GET_CLASS()).catch((err: any) => {
      console.error('Error fetching classes: ', err);
      return Promise.reject(err);
    });
  }

  static createClass(payload: any) {
    return http
      .post(ApiConstants.URL.CLASSES.CREATE_CLASS, payload)
      .catch((err: any) => {
        console.error('Error saving class: ', err);
        return Promise.reject(err);
      });
  }

  static updateClass(id: number, payload: any) {
    return http2
      .put(ApiConstants.URL.CLASSES.UPDATE_CLASS(id), payload)
      .catch((err: any) => {
        console.error('Error updating class: ', err);
        return Promise.reject(err);
      });
  }

  static deleteClass(classId: number) {
    const finalEndPoint = ApiConstants.URL.CLASSES.DELETE_CLASS(classId);
    return http2.delete(`${finalEndPoint}`).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default ClassesService;
