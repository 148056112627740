import { SECURITY_GATE_DEFAULT_TEMPLATE_ID } from '../../Constants/Constants';
import CustomFieldManager from '../../Manager/CustomFieldManager';
import {
	convertDateFromERPFormatToDocFormat,
	getCapitalized,
	getConvertedDate,
	getDateString,
} from '../../Utilities/Utility';
import Address from '../Address';
import AddressParser, { AddressType, OwnerType } from '../AddressParser';
import DataParser from '../DataParser';

export const parseGateEntryData = (response, templateData) => {
	const gateEntry = response?.gateEntryResponse;
	const tenantInfo = response.tenantInfo;
	templateData.companyName = getCapitalized(tenantInfo?.name);
	templateData.tenantInfo = tenantInfo;
	const fromAddress = new Address(
		gateEntry?.contactDto?.contactName,
		AddressParser.getPreferAddress(
			gateEntry?.contactDto?.shippingAddress,
			tenantInfo
		),
		response.pan,
		response.gstin,
		tenantInfo?.contactNumber,
		undefined,
		OwnerType.tenant
	);
	const shipToAddress = new Address(
		undefined,
		AddressParser.getTenantAddressFromApi(
			response.shipFromAddress,
			tenantInfo
		),
		response.pan,
		response.gstin,
		tenantInfo?.contactNumber,
		undefined,
		OwnerType.tenant
	);
	templateData.createdBy = response?.createdBy;
	templateData.clientShipToAddressObj = shipToAddress;
	templateData.fromObj = fromAddress;
	templateData.showNotes = false;
	templateData = AddressParser.assignAddressObjToStringForNonArabic(
		undefined,
		AddressType.from,
		templateData
	);
	templateData = AddressParser.assignAddressObjToStringForNonArabic(
		undefined,
		AddressType.shipTo,
		templateData
	);
	templateData.contact = gateEntry?.contactDto?.toString();
	templateData.customFields = gateEntry?.customField ?? [];
	CustomFieldManager.setCustomFields(templateData.customFields);
	templateData = DataParser.assignCustomFieldValue(templateData);
	templateData.refNumber = gateEntry?.documentSequenceCode;
	templateData.notes = gateEntry?.remarks ?? [];
	templateData.driverName = gateEntry?.driverName ?? '';
	templateData.lrNumber = gateEntry?.lrNumber ?? '';
	templateData.vehicleNumber = gateEntry?.vehicleNumber ?? '';
	templateData.transporter = gateEntry?.transporter ?? '';
	templateData.lrNumber = gateEntry?.lrNumber ?? '';
	templateData.contactNumber = gateEntry?.contactNumber ?? '';
	templateData.date = gateEntry?.referenceDate
		? getConvertedDate(
				convertDateFromERPFormatToDocFormat(gateEntry?.referenceDate),
				tenantInfo?.dateFormat
		  )
		: '';
	templateData.lineItems = getLineItems(
		gateEntry?.gateEntryItems ?? [],
		gateEntry,
		tenantInfo
	);
	templateData.templateId = SECURITY_GATE_DEFAULT_TEMPLATE_ID;
	return templateData;
};

const getLineItems = (lineItems, gateEntry,tenantInfo) => {
	const updatedLineItems = [];
	const linkedDocument = gateEntry?.linkedDocuments?.[0];
	let referenceDocDate = gateEntry?.referenceDate;
	let referenceDoc = gateEntry?.referenceNumber;
	try {
		if (linkedDocument) {
			if(gateEntry?.linkedDocuments[0]?.documentCreatedDate){
				referenceDocDate = new Date(
					gateEntry?.linkedDocuments[0]?.documentCreatedDate
				);
				referenceDocDate = getDateString(referenceDocDate, tenantInfo?.dateFormat)
			}
			referenceDoc = gateEntry?.linkedDocuments[0]?.documentSequenceCode;
		}
	} catch (error) {}

	if (lineItems.length > 0) {
		lineItems.forEach((item, index) => {
			item.lineNumber = index + 1;
			item.productName = item?.product?.name;
			item.refDocumentDate = referenceDocDate;
			item.refDocument = referenceDoc;
			updatedLineItems.push(item);
		});
	}
	return updatedLineItems;
};
