import { addDays, subDays } from 'date-fns';
import {
  DKButton,
  DKCalendar,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker2,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  DKInput,
  showToast,
  DKLine
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_inventory_fulfill from '../../Assets/Icons/ic_inventory_fulfill.svg';
import ic_inventory_pending from '../../Assets/Icons/ic_inventory_pending.svg';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import CreateProductView from '../../Components/Product/CreateProductView';
import { GranularPermissionsHelper } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import OrganisationProfileForm from '../../Components/Settings/OrganisationProfile/OrganisationProfileForm';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  GOOGLE_NO_TRANSLATE_CLASS,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_OFFERING_TYPE,
  PRODUCT_TRANSACTIONTYPE_PURCHASE,
  PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  STATUS_TYPE,
  TAX_SYSTEM,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { ADVANCE_TRACKING, ORGANISATION_FORM_TAB } from '../../Constants/Enum';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import {
  BillingAddressType,
  Document,
  ShippingAddressType
} from '../../Models/Document';
import { DocumentItem } from '../../Models/DocumentItem';
import { DraftTypes } from '../../Models/Drafts';
import {
  BooksAddress,
  BtnType,
  CallBackPayloadType,
  LocationDTO,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  appCustomizationInfo
} from '../../Redux/Slices/AuthSlice';
import {
  fetchProductCustomFields,
  fetchTaxes,
  fetchUOMs,
  selectCustomFields,
  selectProductCustomFields,
  selectPurchaseTax,
  selectShowMainDocsInFullScreen,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import {
  locationsData,
  selectDimensions
} from '../../Redux/Slices/LocationSlice';
import {
  fetchProductsWithVariants,
  selectProductsWithVariants
} from '../../Redux/Slices/ProductsSlice';
import AttachmentService from '../../Services/Attachment';
import DateFormatService from '../../Services/DateFormat';
import { localizedText } from '../../Services/Localization/Localization';
import PriceListService from '../../Services/PriceList';
import ProductService from '../../Services/Product';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../Utility/Utility';
import CustomNumberFormatInput from '../CustomNumberFormat/CustomNumberFormatInput';
import PopupWrapper from '../PopupWrapper';
import DocumentActionMenu from './DocumentActionMenu';
import {
  checkIfLineLevelCustomFieldIsValid,
  createLineItem,
  getFormattedAddressObj,
  getMainModuleName,
  getNewCFItem,
  getNewColumnForCF,
  getTenantTaxSystem,
  getValidTillDateFromDocDate,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo,
  updateRowDataWithParentCFValues
} from './NewDocumentHelper';
// import { NULL } from 'sass';
// import { Props } from 'react-select';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import useScreenResize from '../../Hooks/useScreenResize';
import CustomFieldSettings from '../CustomFieldsHolder/CustomFieldSettings';
import LocationService from '../../Services/Location';
import DocProductSelectionPopup from './DocProductSelectionPopup';
export interface RequisitionProps2 {
  booksDocument: any;
  draftType?: any;
  documentMode?: DOCUMENT_MODE;
  canValidate?: any;
  onDocumentUpdate: (document: Document) => void;
  draftData?: any;
  id: any;
}
export default function RequisitionForm(props: RequisitionProps2) {
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const productsData = useAppSelector(selectProductsWithVariants);
  const isUOMChangedManually = useRef<boolean>(false);
  const [productRows, setProductRows] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [requisitionDateOpen, setRequisitionDateOpen] = useState(false);
  const [receiveByDateOpen, setreceiveByDateOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentDate = new Date();
  const [customNumberFormatModule, setCustomNumberFormatModule] = useState('');
  const laterDate = addDays(currentDate, 30);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [dueDate, setDueDate] = useState<Date>(laterDate);
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const [receiveByDate, setreceiveByDate] = useState<Date>(laterDate);
  const [booksDocument, setBooksDocument] = useState<any>(props.booksDocument);
  const availableCustomFields = useAppSelector(selectCustomFields);
  const uomsData = useAppSelector(selectUOMs);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);
  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const isDocDateUpdatedManually = useRef<boolean>(false);
  const linkedDocument = booksDocument.linkedPurchaseOrders;
  const [isNonConverted, setIsNonConverted] = useState<any[]>([]);
  const [linkDocumentOpen, setLinkDocumentOpen] = useState(false);
  const [openProductCFSettings, setOpenProductCFSettings] = useState(false);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const [isCustomFieldColumnConfig, setIsCustomFieldColumnConfig] =
    useState<boolean>(true);
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const productCFfromStore = useAppSelector(selectProductCustomFields);
  const locationData = useAppSelector(locationsData);
  const [roundOffDirty, setRoundOffDirty] = useState<any>(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY
      ? true
      : false
  );
  const [shipToOrFromAddressChangedForUS, setShipToOrFromAddressChangedForUS] =
    useState(false);
  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType | null>(null);
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddressType | null>(null);
  const dimensionData = useAppSelector(selectDimensions);
  const [lineItemsTouched, setLineItemsTouched] = useState(false);
  //Need to change hardcode this and need to get from props
  const [columnConfig, setColumnConfig] = useState<any[]>(
    DocumentConfigManager.get('REQUISITION')
  );
  const lastUpdatedIndex = useRef<any>(null);
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const isQuantityChangedManually = useRef<boolean>(false);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [currentRowHSNInfo, setCurrentRowHSNInfo] = useState<any>(null);
  const [contact, setContact] = useState<any>(null);
  const [showLandedCostPopup, setShowLandedCostPopup] = useState(false);
  const [currentRowLandedCostInfo, setCurrentRowLandedCostInfo] =
    useState<any>(null);
  const [showReserveStockPopup, setShowReserveStockPopup] = useState(false);
  const [reserveStockLineItem, setReserveStockLineItem] = useState<any>();
  const [reservedQuantitiesOriginal, setReservedQuantitiesOriginal] =
    useState<any[]>();
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [existingLineNumbers, setExistingLineNumbers] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const [openShippingAddressList, setOpenShippingAddressList] = useState(false);
  const [openBillingAddressList, setOpenBillingAddressList] = useState(false);
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const [activeOrgProfileTab, setActiveOrgProfileTab] =
    useState<ORGANISATION_FORM_TAB>(ORGANISATION_FORM_TAB.GENERAL_INFO);
  const [convertedDetails, setconvertedDetails] = useState<any[]>([]);
  const [isBillToAddressUpdated, setIsBillToAddressUpdated] = useState(false);
  const [isShipToAddressUpdated, setIsShipToAddressUpdated] = useState(false);
  const [isShipFromAddressUpdated, setIsShipFromAddressUpdated] =
    useState(false);
  const [isContactUpdated, setIsContactUpdated] = useState(false);

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };
  const editAddressRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const fullScreenMode = useAppSelector(selectShowMainDocsInFullScreen);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);

  const isProductGroupingEnabled = tenantInfo?.additionalSettings?.PRODUCT_GROUP_ENABLED;
  const [showProductSelectionPopup, setShowProductSelectionPopup] = useState(false);

  useEffect(() => {
    dispatch(fetchUOMs);
  }, []);
  useEffect(() => {
    if (
      lastUpdatedIndex.current !== null &&
      !Utility.isEmpty(productRows[lastUpdatedIndex.current as any]?.product)
    ) {
      let index = lastUpdatedIndex.current as number;
      lastUpdatedIndex.current = null;
    }
  }, [productRows]);
  useEffect(() => {
    setBooksDocument((prevState: any) => ({
      ...prevState,
      fulfillmentDate: DateFormatService.getDateStrFromDate(
        receiveByDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    }));
  }, [receiveByDate]);

  useEffect(() => {
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: productRows,
      purchaseRequestItems: productRows
    }));
  }, [productRows]);

  useEffect(() => {}, [productCustomFields]);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  const addConvertedDetails = (
    productCode: any,
    price: any,
    contact: any,
    documentCode: any
  ) => {
    setconvertedDetails([productCode, price, contact, documentCode]);
  };

  const getBillToShipTo = () => {
    const billingAddressList: BooksAddress[] = tenantInfo.billingAddresses;
    const shippingAddressList: BooksAddress[] = tenantInfo.shippingAddresses;

    let billingAddress: BooksAddress | undefined;
    let shippingAddress: BooksAddress | undefined;

    if (isBillOrOrder) {
      billingAddress =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      shippingAddress =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);
    }
    if (props.draftType === DraftTypes.NEW) {
      setBillingAddress({
        billingAddress: billingAddressList,
        currentBillingAddress: billingAddress as BooksAddress
      });
      setShippingAddress({
        shippingAddress: shippingAddressList,
        currentShippingAddress: shippingAddress as BooksAddress
      });
    }
    return {
      billingAddress,
      shippingAddress
    };
  };

  useEffect(() => {
    getBillToShipTo();

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: MODULES_NAME.PURCHASE_REQUISITION,
      EntityId: props.booksDocument.id
    };

    AttachmentService.getAllAttachments()
      .then((res: any) => {
        res && res.length && setAttachments(res);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, []);

  // update billing and shipping address
  useEffect(() => {
    if (props.draftType === DraftTypes.NEW) {
      setBooksDocument((prevState: any) => ({
        ...prevState,
        billTo: billingAddress?.currentBillingAddress,
        shipTo: shippingAddress?.currentShippingAddress
      }));
    }
  }, [
    billingAddress?.currentBillingAddress,
    shippingAddress?.currentShippingAddress
  ]);

  useEffect(() => {
    let lineItems = [...(props.booksDocument?.items as DocumentItem[])];

    lineItems = lineItems?.map((item: any) => getCustomFieldRowConfig(item));

    if (lineItems?.length && props.documentMode === DOCUMENT_MODE.COPY) {
      setExistingLineNumbers(lineItems?.map((item) => item.lineNumber));
    }

    let updatedLineItemsWithConfigs = getLineItemsWithUpdatedColumnConfig([
      ...lineItems
    ]);

    if (updatedLineItemsWithConfigs?.items) {
      lineItems = [...updatedLineItemsWithConfigs.items];
    }

    if (updatedLineItemsWithConfigs?.updatedConfigs) {
      setColumnConfig([...updatedLineItemsWithConfigs?.updatedConfigs]);
    }

    lineItems = getLineItemsWithNonEditableCells(lineItems);

    // updateConfig();
    if (props.documentMode) {
      // Don't show tooltip in VIEW mode
      DocumentConfigUtility.documentMode = props.documentMode;
    }
    // Set doc type in dicument config utility
    DocumentConfigUtility.documentType = props.booksDocument.documentType;

    lineItems = lineItems.map((item: any) => {
      let receiptStatus = RECEIVED_GOODS_STATUS.NOT_RECEIVED;
      if (item.productConvertedQuantity === item.productQuantity) {
        receiptStatus = RECEIVED_GOODS_STATUS.FULLY_RECEIVED;
      } else if (
        item.productConvertedQuantity > 0 &&
        item.productConvertedQuantity < item.productQuantity
      ) {
        receiptStatus = RECEIVED_GOODS_STATUS.PARTIAL_RECEIVED;
      }

      return {
        ...item,
        receipt_status: [receiptStatus]
      };
    });
    if (lineItems && lineItems.length > 0) {
      lineItems.forEach((rowData, index) => {
        calculateAndSetUomRelatedDataOnEdit(index, [
          ...(lineItems as DocumentItem[])
        ]);
      });
    }

    let documentUpdates: any = {};

    if (props.booksDocument.documentDate) {
      setDocumentDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.fulfillmentDate) {
      setreceiveByDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.validTillDate) {
      setDueDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (
      //props.draftType === DraftTypes.DRAFT &&
      props.booksDocument.attachments
    ) {
      const newAttachments: any[] = props.booksDocument.attachments.map(
        (attachment: any) => JSON.parse(attachment)
      );
      setAttachments(newAttachments);
      setBooksDocument((preState: any) => {
        return {
          ...preState,
          attachmentIds: newAttachments.map((attachment: any) => {
            return attachment.attachmentId;
          })
        };
      });
    }

    // if (!productsData?.content) {
    dispatch(
      fetchProductsWithVariants({
        searchTerm: '',
        query: 'active=true,hasVariants=false',
        params: PRODUCT_TRANSACTIONTYPE_PURCHASE
      })
    );
    // }
    if (!purchaseTaxes?.length) {
      dispatch(fetchTaxes());
    }
    if (true) {
      dispatch(fetchUOMs);
    }

    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        ...documentUpdates,
        currency: booksDocument.currency
          ? booksDocument.currency
          : tenantInfo.currency,
        currencyCode: booksDocument.currencyCode
          ? booksDocument.currencyCode
          : tenantInfo.currency,
        documentDate: props.booksDocument.documentDate
          ? props.booksDocument.documentDate
          : DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        fulfillmentDate: props.booksDocument.fulfillmentDate
          ? props.booksDocument.fulfillmentDate
          : DateFormatService.getDateStrFromDate(
              laterDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        validTillDate: props.booksDocument.validTillDate
          ? props.booksDocument.validTillDate
          : DateFormatService.getDateStrFromDate(
              laterDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        totalAmount: props.booksDocument.totalAmount
      };
    });

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);
  const onMouseUp = (e: any) => {
    const target = e.target;
    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setopenCustomNumberList(false);
    }
  };
  useEffect(() => {
    if (documentDate && documentDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          documentDate: DateFormatService.getDateStrFromDate(
            documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
      if (isDocDateUpdatedManually.current) {
        const updatedValidTillDate = getValidTillDateFromDocDate(
          documentDate,
          contact
        );
        setDueDate((prevState) =>
          updatedValidTillDate ? updatedValidTillDate : prevState
        );
        // setreceiveByDate(documentDate);

        if (productRows.length && isDocDateUpdatedManually.current) {
          const productsPayload = productRows
            .filter((item) => !Utility.isEmpty(item.product))
            .map((item) => ({
              productId: item.product.productId,
              uomId: item.documentUom,
              quantity: +item.productQuantity
            }));
          getPricing(
            productsPayload,
            productRows,
            booksDocument.currency,
            true,
            booksDocument.exchangeRate,
            { dateChanged: true }
          );
        }
      }
    }
  }, [documentDate]);
  useEffect(() => {
    updateConfig();
  }, [
    productsData,
    uomsData,
    productRows,
    selectedProductPriceList,
    selectedProductUOMS,
    props.draftType,
    props.documentMode
  ]);
  useEffect(() => {
    if (dueDate && dueDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          validTillDate: DateFormatService.getDateStrFromDate(
            dueDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
  }, [dueDate]);
  useEffect(() => {
    if (props.draftType == DraftTypes.READONLY) {
      const tmpisNonConverted = booksDocument.purchaseRequestItems?.filter(
        (Item: any) => {
          return Item.productConvertedQuantity === null;
        }
      );
      setIsNonConverted(tmpisNonConverted);
    }
  }, []);
  useEffect(() => {
    props.onDocumentUpdate(booksDocument);
  }, [booksDocument]);
  //calenderview

  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  // const DocumentScreen = () => {
  //   return (
  //     <div className="transparent-background ">
  //       <div className="popup-window">
  //         <div
  //           className="row justify-content-between p-h-r p-v-s bg-gray1 "
  //           style={{
  //             borderTopLeftRadius: 4,
  //             borderTopRightRadius: 4
  //           }}
  //         >
  //           <div className="row width-auto">
  //             <DKLabel text="Linked PO List" className="fw-m fs-l" />
  //           </div>

  //           <div className="row width-auto">
  //             <DKButton
  //               title={'Close'}
  //               // disabled={isSaving}
  //               style={{
  //                 border: '1px'
  //               }}
  //               className={`bg-white border-m mr-r`}
  //               onClick={() => {
  //                 setLinkDocumentOpen(false);
  //               }}
  //             />
  //           </div>
  //         </div>
  //         <div className="column parent-width p-r">
  //           {linkedDocument
  //             .map((doc: any) => doc.documentSequenceCode)
  //             .join(', ')}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const getCustomFields = () => {
    return (
      <div
        className={`pointer-events-auto ${
          booksDocument.customField?.length || locationData?.length
            ? 'mb-r mr-l'
            : ''
        }`}
        style={{
          marginLeft: booksDocument.customField?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.REQUISITION}
          customFieldsList={
            booksDocument.customField ? booksDocument.customField : []
          }
          columnConfig={getCustomFieldColumnConfig(columnConfig)}
          columnConfigTableId={''}
          documentMode={props.documentMode}
          onUpdate={async (updatedCFList) => {
            // Handle location CF, when CF list is updated
            const listContainsLocation = updatedCFList.find(
              (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
            );
            if (listContainsLocation && listContainsLocation?.value) {
              const locationObjForCF = locationData.find(
                (loc: any) => loc.label === listContainsLocation.value
              );
              if (locationObjForCF) {
                setCustomLocation(locationObjForCF);
              } else if (
                props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW
              ) {
                // Handle edit mode when location CF is deleted ...
                // Get the address from payload
                const locationCFinDocument = booksDocument.customField?.find(
                  (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
                );

                if (locationCFinDocument) {
                  if (locationCFinDocument.value === '') {
                    setCustomLocation(undefined);
                  } else {
                    try {
                      const locationObj =
                        await LocationService.getLocationByLabel(
                          locationCFinDocument.value
                        );
                      if (!Utility.isEmpty(locationObj)) {
                        setCustomLocation(locationObj);
                      }
                    } catch (err) {
                      console.error('Error loading location by label: ', err);
                    }
                  }
                }
              }
            }
            setBooksDocument((prevState: any) => {
              return {
                ...prevState,
                customField: [...updatedCFList]
              };
            });
          }}
          onLocationUpdate={(loc: LocationDTO) => {
            if (Utility.isEmpty(loc)) {
              const orgPreferredBillingAddress: any =
                tenantInfo.billingAddresses?.find(
                  (address: any) => address.preferred
                );
              let tempDocument = { ...booksDocument };
              tempDocument = {
                ...tempDocument,
                billTo: orgPreferredBillingAddress
              };

              setBooksDocument((prevState: any) => ({
                ...prevState,
                ...tempDocument
              }));
            }
            setCustomLocation(loc);
          }}
          contact={contact}
          hideAddfieldButton={props.documentMode === DOCUMENT_MODE.VIEW}
        />
      </div>
    );
  };

  const getCustomFieldRowConfig = (tempProductRow: any) => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];

      if (!Utility.isClassRowType()) {
        prodCFs = prodCFs.filter((field) => field.label !== 'CLASS');
      }
      let customFieldList: any[] = [];
      prodCFs?.forEach((currCustomField: any) => {
        const customfieldRow = {
          label: currCustomField?.label,
          shortName: currCustomField?.shortName,
          code: currCustomField?.code,
          module: currCustomField?.module,
          value: '',
          id: currCustomField?.id,
          fieldType: currCustomField?.fieldType
        };
        customFieldList.push(customfieldRow);
      });

      let tempCustomFieldList: any[] = customFieldList.map((fieldData) => {
        let val =
          tempProductRow?.customField?.find(
            (fdata: any) => fdata.id === fieldData.id
          )?.value || '';
        return { ...fieldData, value: val };
      });

      tempCustomFieldList?.forEach((field: any) => {
        const colName: any = field.id + '' || '';
        if (colName !== '') {
          let tempRow: any = {};
          tempRow[colName] =
            field?.fieldType === 'DATE'
              ? DateFormatService.getDateFromStr(
                  field.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                )
              : field.value;
          tempProductRow = {
            ...tempProductRow,
            ...tempRow,
            customField: tempCustomFieldList
          };
        }
      });
    }
    return tempProductRow;
  };
  const getCustomFieldColumnConfig = (columnConfigData: any) => {
    const tempColumnConfig = [...columnConfigData];
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];

      if (!Utility.isClassRowType()) {
        prodCFs = prodCFs.filter((field) => field.label !== 'CLASS');
      }

      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      prodCFs?.forEach((currCustomField: any) => {
        if (!Utility.isEmpty(currCustomField)) {
          if (currCustomField.fieldType === 'DROPDOWN') {
            let attributes = currCustomField.attributes
              ? [...currCustomField.attributes]
              : [];
            const noneAttribute = {
              id: 0,
              code: null,
              status: STATUS_TYPE.ACTIVE,
              value: 'None'
            };
            const alreadyAddedNoneAttribute = attributes.find(
              (att: any) => att.id === 0
            );
            if (Utility.isEmpty(alreadyAddedNoneAttribute)) {
              attributes.push(noneAttribute);
            }

            currCustomField = { ...currCustomField, attributes: attributes };
          }
          const tempColumn = getNewCFItem(
            currCustomField,
            currCustomField,
            true
          );
          tempColumnConfig.push(tempColumn);
        }
      });
    }
    return tempColumnConfig;
  };

  const calculateAndSetUomRelatedDataOnEdit = (
    rowIndex: number,
    unsavedRows?: any
  ) => {
    let item = unsavedRows[rowIndex];
    item.documentSequenceCode = item?.product?.documentSequenceCode;
    let clonedItem: any = {};

    let unitPrice = item.unitPrice;

    const pendingQuantity = item.pendingQuantity;
    let finalQty;
    if (item.isPartialInvoice && !item.id) {
      finalQty = pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else if (item.isPartialBill && !item.id) {
      const receivedButNotBilled = Utility.receivedButNotBilledQuantity(item);
      finalQty =
        receivedButNotBilled > 0 && receivedButNotBilled < pendingQuantity
          ? receivedButNotBilled
          : pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else {
      finalQty = item.productQuantity;
    }

    item.productQuantity = finalQty;
    item.unitPrice = unitPrice;

    item.uom = getSelectedUomForEdit(item);
    if (item.documentUOMSchemaDefinition) {
      item.unitPrice = item.uomUnitPrice || item.unitPrice;
      item.productQuantity = item.uomQuantity || item.productQuantity;
    }

    lastUpdatedIndex.current = rowIndex;
    setProductRows([...unsavedRows]);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...unsavedRows],
        purchaseRequestItems: [...unsavedRows]
      };
    });
  };

  const getSelectedUomForEdit = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };
  const getLineItemsWithUpdatedColumnConfig = (items: any[]) => {
    if (!Utility.isEmpty(availableCustomFields)) {
      let updatedConfigs = [...columnConfig];
      const prodCustomFields = availableCustomFields?.content?.filter(
        (item: any) => {
          return item.modules?.includes(MODULES_NAME.PRODUCT) && !item.system;
        }
      );

      prodCustomFields?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(
          prodCF,
          props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY
        );
        updatedConfigs.push(newItem);
      });

      if (
        props.documentMode !== DOCUMENT_MODE.NEW &&
        !Utility.isEmpty(prodCustomFields)
      ) {
        items?.forEach((lineItem: any, lineItemIndex: any) => {
          lineItem?.customField?.map((item: any, index: any) => {
            let filteredCF: any = availableCustomFields?.content?.find(
              (cf: any) => cf.id === item.id && !cf.system
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW &&
                  props.draftType !== DraftTypes.READONLY
              );

              let alreadyPresentConfig = updatedConfigs?.find((col: any) => {
                if (col.id === item.id) {
                  return col;
                }
              });
              if (Utility.isEmpty(alreadyPresentConfig)) {
                updatedConfigs.push(newItem);
              }

              if (filteredCF) {
                if (
                  typeof item.value !== 'undefined' &&
                  item.value !== null &&
                  item.value !== ''
                ) {
                  if (
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    items[lineItemIndex][item.id] =
                      DateFormatService.getDateFromStr(
                        item.value,
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      );
                  } else {
                    items[lineItemIndex][item.id + ''] = item.value;
                  }
                } else {
                  items[lineItemIndex][item.id + ''] = '';
                }
              }
            }
          });
        });
      }
      return { items, updatedConfigs };
    } else {
      return { items: null, updatedConfigs: [...columnConfig] };
    }
  };

  const getLineItemsWithNonEditableCells = (lineItems: any[]) => {
    lineItems = lineItems.map((item: any) => {
      let updatedItem = { ...item, isTaxable: true };
      if (typeof updatedItem.expectedDeliveryDt === 'string') {
        updatedItem.expectedDeliveryDt = DateFormatService.getDateFromStr(
          updatedItem.expectedDeliveryDt,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
      }
      if (
        (typeof updatedItem?.product?.taxPreference !== 'undefined' &&
          updatedItem?.product?.taxPreference !== null &&
          !updatedItem?.product?.taxPreference) ||
        !updatedItem.isTaxable
      ) {
        updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
          ? [...updatedItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedItem?.nonEditableColumns?.push('tax');
      }
      return updatedItem;
    });

    return lineItems;
  };
  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  //date validation
  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    callback: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `Requisition date should not be less than back date : ${formatedDate}.`
          );
          return;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        callback(newDate);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      } else {
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          (date: Date) => {
            callback(date);
          },
          `Requisition should be in active date range`
        );
        return;
      }
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};

    if (selected.manualMode) {
      updatedState.documentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
    } else {
      if (selected.text === 'PRQ-0000000') {
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      } else if (selected.text !== 'PRQ-0000000') {
        updatedState.documentSequenceCode = selected.text;
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      }
    }

    setBooksDocument((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };

  const openReservedStockPopup = (
    lineItem: any,
    selectedProductInfo: any[]
  ) => {
    const productId = lineItem.product.id;
    const exitingReservedQuantitiesDataInLines = booksDocument.items
      ?.filter(
        (item: any) =>
          item.lineNumber !== lineItem.lineNumber &&
          item.product?.id === productId &&
          !Utility.isEmpty(item.reservedQuantitiesData)
      )
      .map((data: any) =>
        rebuildAdvancedTrackingMetaDtosAndUOMInfo(
          data.reservedQuantitiesData,
          data.documentUOMSchemaDefinition
        )
      )
      .reduce((acc: any[], curVal: any[]) => {
        return acc.concat(curVal);
      }, []);

    if (selectedProductInfo && exitingReservedQuantitiesDataInLines) {
      selectedProductInfo.forEach((obj) => {
        const warehouseObj = exitingReservedQuantitiesDataInLines.find(
          (o: any) => o.warehouseCode === obj.warehouseCode
        );
        if (warehouseObj) {
          const availableQty =
            obj.reservedQuantity + Number(warehouseObj.reservedQuantity);
          obj.reservedQuantity = availableQty < 0 ? 0 : Number(availableQty);
          if (
            obj.advancedTrackingType !== TRACKING_TYPE.NONE &&
            obj.advancedTrackingMetaDtos &&
            obj.advancedTrackingMetaDtos.length > 0 &&
            warehouseObj.advancedTrackingMetaDtos
          ) {
            obj.advancedTrackingMetaDtos.forEach((e: any) => {
              const batchObj = warehouseObj.advancedTrackingMetaDtos.find(
                (o: any) => o.serialBatchNumber === e.serialBatchNumber
              );
              if (batchObj) {
                const availableBatchQty =
                  e.reservedQuantity + Number(batchObj.reservedQuantity);
                e.reservedQuantity =
                  availableBatchQty < 0 ? 0 : Number(availableBatchQty);
              }
            });
          }
        }
      });
    }

    setReserveStockLineItem({ ...lineItem });

    // convertReservedBaseUOMToSchema(selectedProductInfo, lineItem);

    setReservedQuantitiesOriginal(selectedProductInfo);
    setShowReserveStockPopup(true);
  };

  const fetchProductReservedInfo = ({ rowData }: any) => {
    const product = rowData?.product;
    const productId = product?.productId;
    if (!Utility.isEmpty(productId)) {
      ProductService.getProductReservedInfo([productId]).then(
        (reserveInfo: any[]) => {
          if (reserveInfo?.length) {
            const selectedProductInfo = reserveInfo?.filter(
              (obj) => obj.productCode === productId
            );

            if (selectedProductInfo?.length) {
              openReservedStockPopup(rowData, selectedProductInfo);
            }
          }
        },
        (err) => {
          console.error('Error while fetching product reserved info: ', err);
        }
      );
    }
  };

  const removeAttachment = (attachmentId: any) => {
    if (props.draftType !== DraftTypes.READONLY) {
      setIsRemovingAttachment(true);
      AttachmentService.deleteAttachment(attachmentId)
        .then((res) => {
          const attachmentForListing = attachments.filter(
            (attachment: any) => attachmentId !== attachment.attachmentId
          );
          setAttachments(attachmentForListing);
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              attachmentIds: attachmentForListing.map(
                (attachment: any) => attachment.attachmentId
              ),
              attachments: attachmentForListing.map((attachment: any) =>
                JSON.stringify(attachment)
              )
            };
          });
          setIsRemovingAttachment(false);
        })
        .catch(() => {
          showToast(
            'Something went wrong while removing the attachment, please try again.'
          );
          setIsRemovingAttachment(false);
        });
    }
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    if (props.draftType !== DraftTypes.READONLY) {
      AttachmentService.downloadAttachment(attachmentId)
        .then((absolutePath) => {
          triggerDownload(null, attachmentName, absolutePath);
        })
        .catch(() => {
          showToast('Something went wrong, while downloading the attachment.');
        });
    }
  };

  const uploadAttachmentToAWS = async (file: File) => {
    const moduleType =
      DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    const entityId = booksDocument.id || booksDocument.entityId || '';

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: 'PURCHASE_REQUISITION',
      EntityId:
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.QUOTE ||
        props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.REQUISITION
          ? entityId
          : ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    return AttachmentService.uploadAttachment(file);
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true')
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const attachmentForListing = [...attachments, ...resList];
          setAttachments(attachmentForListing);
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              attachmentIds: attachmentForListing.map(
                (attachment: any) => attachment.attachmentId
              ),
              attachments: attachmentForListing.map((attachment: any) =>
                JSON.stringify(attachment)
              )
            };
          });
        }).catch((err) => {
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r gap-2">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              //disabled={props.draftType === DraftTypes.READONLY}
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              //title="file1"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              //disabled={props.draftType === DraftTypes.READONLY}
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const addNewItem = (returnNewRow: boolean = false) => {
    let rows = [...productRows];

    let newRow = DocumentConfigManager.getBlankRow(
      props.booksDocument.documentType
    );
    newRow['productQuantity'] = 1;
    newRow['unitPrice'] = 0;
    newRow.invalidFields = DocumentConfigManager.getRequiredFields(
      props.booksDocument.documentType
    );
    newRow['lineNumber'] = productRows.length + 1;
    newRow['firstAmountChangeDone'] = false;

    if (!Utility.isEmpty(availableCustomFields)) {
      // Set default values of CFs when new line is added
      availableCustomFields?.content?.forEach((item: any) => {
        if (
          item.modules?.includes(MODULES_NAME.PRODUCT) &&
          item.status === STATUS_TYPE.ACTIVE &&
          !item.system
        ) {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            if (
              item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              newRow[item.id] = DateFormatService.getDateFromStr(
                item.defaultValue,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              newRow[item.id] = item.defaultValue;
            }
          } else {
            newRow[item.id] = '';
          }
        }
      });
    }
    newRow = getCustomFieldRowConfig(newRow);
    if (returnNewRow) {
      return newRow;
    } else {
      rows.push(newRow);
      setProductRows(rows);
    }
  };

  const clearAllItems = () => {
    setProductRows([]);
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        items: [],
        purchaseRequestItems: [],
        reservedStock: false
      };
    });
  };

  const onOpenAllocateCost = ({ rowIndex, rowData }: any) => {
    setShowLandedCostPopup(true);
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowLandedCostInfo({ rowIndex: rowIndex });
  };

  const getProductForm = () => (
    <CreateProductView
      needAdvanceTrackPopupAfterSave={true}
      product={productToEdit}
      passingInteraction={(callback: CallBackPayloadType) => {
        if (callback.type === POPUP_CALLBACKS_TYPE.CLOSE_POPUP) {
          if (lastUpdatedIndex.current !== null) {
            onRowUpdate({
              columnKey: 'product',
              rowIndex: lastUpdatedIndex,
              rowData: { product: callback.data }
            });
          }
          setShowProductPopup(false);
        }
      }}
      onCancel={() => {
        setShowProductPopup(false);
        setProductToEdit(null);
      }}
      selectedTab={productToEdit ? 3 : 0}
      onSuccess={(product: any) => {
        setShowProductPopup(false);
        setProductToEdit(null);
        if (
          Utility.isSalesDocument(props.booksDocument) ||
          (product.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
            (props.booksDocument.documentType === DOC_TYPE.BILL ||
              props.booksDocument.documentType === DOC_TYPE.ORDER ||
              props.booksDocument.documentType === DOC_TYPE.REQUISITION))
        ) {
          onProductChange(currentIndex, product);
        }
        setCurrentIndex(-1);
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_PURCHASE
          })
        );
      }}
    />
  );

  const AddCopyofRow = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...productRows];
    let copiedRow: any = { ...tempProductRows[rowIndex] };
    copiedRow.id = null;
    tempProductRows.splice(rowIndex + 1, 0, copiedRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });
    setProductRows(tempProductRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: tempProductRows,
      purchaseRequestItems: tempProductRows,
      isDocumentTouched: true
    }));
  };

  const InsertNewItem = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...productRows];
    let newRow: any = addNewItem(true);
    tempProductRows.splice(rowIndex + 1, 0, newRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });
    setProductRows(tempProductRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: tempProductRows,
      purchaseRequestItems: tempProductRows,
      isDocumentTouched: true
    }));
  };
  const getContextMenu = (row: any) => {
    let contextMenu = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      contextMenu.push({
        title: row.reservedQuantitiesData
          ? 'Update reserved stock'
          : 'Add reserved stock',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: ' p-0',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }

    let contactObj = contact || props.booksDocument.contact;
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      contactObj?.tdsInfoIndia?.deductionApplicable &&
      row?.product?.tdsApplicableIndia
    ) {
      contextMenu.push({
        title: 'Deduct TDS',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any) => showAlert('working on it')
      });
    }

    if (
      tenantInfo?.landedCostSettings?.landedCost &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      (props.booksDocument.landedCost || booksDocument.landedCost)
    ) {
      contextMenu.push({
        title: 'Allocate Cost',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenAllocateCost(data)
      });
    }
    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenProductCFSettings(true)
    });

    contextMenu.push({
      title: 'Insert',
      icon: DKIcons.ic_add,
      className: ' p-0',
      onClick: (data: any, index: any) => InsertNewItem(data)
    });

    contextMenu.push({
      title: 'Copy',
      icon: DKIcons.ic_copy,
      className: ' p-0',
      onClick: (data: any, index: any) => AddCopyofRow(data)
    });

    contextMenu.push({
      title: 'Delete',
      icon: DKIcons.ic_delete,
      className: ' p-0',
      onClick: (data: any) => onDelete(data)
    });
    return contextMenu;
  };

  const getButtonsForRow = (row: any) => {
    let buttons: any[] = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      buttons.push({
        title: '',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: 'p-v-0 inventory-icon-document',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    return buttons;
  };

  const getDataRows = (rowData: any) => {
    if (!Utility.isDropship(props.booksDocument)) {
      return {
        ...rowData,

        rowContextMenu:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getContextMenu(rowData),
        rowButtons:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getButtonsForRow(rowData)
      };
    } else {
      return {
        ...rowData
      };
    }
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  const setPricesForSelectedProduct = (lineItem: any) => {
    if (
      lineItem &&
      !Utility.isEmpty(lineItem.product) &&
      !Utility.isEmpty(productsLastUnitPrice) &&
      productsLastUnitPrice[lineItem.product.productId]
    ) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.product.productId]
      ]);
    }
  };

  const onRowClick = ({ rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    // lastUpdatedIndex.current = rowIndex;
    setUomsForSelectedProduct(lineitem.product);
    setPricesForSelectedProduct(lineitem);

    // Set current index to know on which row user clicked, before adding the product
    setCurrentIndex(rowIndex);
  };

  const isQuantityMoreThatPendingQuantity = (
    quantity: number,
    lineItem: any
  ) => {
    let pendingQuantity = +lineItem.pendingQuantity;
    if (!Utility.isEmpty(lineItem.documentUOMSchemaDefinition)) {
      pendingQuantity = pendingQuantity * lineItem.uomQuantity;
    }

    return quantity > pendingQuantity;
  };

  const isPriceListEnabled = () => {
    const hiddenApps = appCustomizationData?.hiddenApps?.split(',');
    return !hiddenApps?.includes('PRICE_LIST');
  };

  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any
  ) => {
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      isPriceListEnabled()
    ) {
      if (Utility.isEmpty(contact)) {
        return;
      }
      let payload = {
        contactCode: contact.code ? contact.code : '',
        priceListPricingItems: productsPayload,
        type: getMainModuleName(props.booksDocument.documentType),
        currency: currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };

  const updateUomSchemaForLineItem = (index: number, uomData: any) => {
    let rows: any = [...productRows];
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;
    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.product.uomSchemaDto.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
    }
    return selectedRow;
  };

  const getUomQuantity = (
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) => {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor
      );
    }
    return baseQuantity;
  };

  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (!Utility.isEmpty(availableCustomFields)) {
      // Set default values of CFs when new line is added
      let tempAvailableCustomFields = availableCustomFields.content;
      if (!Utility.isClassRowType()) {
        tempAvailableCustomFields = availableCustomFields?.content?.filter(
          (field: any) => field.label !== 'CLASS'
        );
      }
      tempAvailableCustomFields?.forEach((filteredCF: any) => {
        const productCF = product?.customField?.find(
          (field: any) =>
            field.id === filteredCF.id &&
            filteredCF.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            fieldType: filteredCF?.fieldType,
            value: ''
          };
          let valueOfCF = '';
          if (
            productCF &&
            typeof productCF?.value !== 'undefined' &&
            productCF?.value !== null &&
            productCF?.value !== ''
          ) {
            if (
              filteredCF?.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[filteredCF?.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              lineItem[filteredCF.id] = productCF.value;
            }
            valueOfCF = productCF?.value;
          } else {
            lineItem[filteredCF?.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const updateTaxList = () => {
    let taxData = purchaseTaxes;
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          documentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          documentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          documentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  const getDefaultTaxForSG = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };
  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = null;
      taxCode = product.purchaseTaxCode;
      tax = purchaseTaxes.find((tax: any) => tax.code === taxCode);
      tax = getDefaultTaxForSG(tax);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
    return tax;
  };

  const isUpdateDateRecieveByDateRequiredOnProductChange = () => {
    let maxDateProductObject = productRows?.reduce(function (a: any, b: any) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    if (
      typeof maxDateProductObject.expectedDeliveryDt === 'undefined' ||
      maxDateProductObject.expectedDeliveryDt === null
    ) {
      maxDateProductObject.expectedDeliveryDt = documentDate;
    }
    // convert into Date object
    let dateObject = new Date(maxDateProductObject.expectedDeliveryDt);
    if (
      dateObject.getTime() === receiveByDate.getTime() ||
      dateObject.getTime() > receiveByDate.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onProductChange = (rowIndex: number, product: any) => {
    // setUomsForSelectedProduct(product);
    let isUpdateReceiveByDateRequired =
      isUpdateDateRecieveByDateRequiredOnProductChange();
    if (!booksDocument.isDocumentTouched) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }

    let rows = [...productRows];
    let selectedRow = rows[rowIndex];
    if (!Utility.isEmpty(product)) {
      let updatedLineItem: DocumentItem | any = {} as any;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.COPY
      ) {
        updatedLineItem =
          !Utility.isEmpty(selectedRow.productName) &&
          product.name === selectedRow.productName
            ? {
                ...selectedRow,
                gstType: Utility.getValue(
                  selectedRow.gstType,
                  booksDocument.gstType
                )
              }
            : createLineItem(
                product,
                Utility.getValue(selectedRow.gstType, booksDocument.gstType),
                selectedRow.lineNumber,
                booksDocument.documentType as DOC_TYPE
              );
      } else {
        updatedLineItem = createLineItem(
          product,
          Utility.getValue(selectedRow.gstType, booksDocument.gstType),
          selectedRow.lineNumber,
          booksDocument.documentType as DOC_TYPE
        );
      }

      updatedLineItem.expectedDeliveryDt = Utility.addDaysInDate(
        documentDate,
        product?.leadTimeDetails?.[0]?.leadTime || 0
      );

      let tax = getTax({ ...product });
      updatedLineItem?.nonEditableColumns?.push('tax');
      updatedLineItem = {
        ...updatedLineItem,
        advancedTracking: product.advancedTracking,
        unitPriceGstInclusive: selectedRow.unitPriceGstInclusive
      };

      if (
        (typeof product.taxPreference !== 'undefined' &&
          product.taxPreference !== null &&
          !product.taxPreference) ||
        !updatedLineItem.isTaxable
      ) {
        updatedLineItem.nonEditableColumns = updatedLineItem.nonEditableColumns
          ? [...updatedLineItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedLineItem?.nonEditableColumns?.push('tax');
      }

      //Update UOM Values

      updatedLineItem.uomQuantity = getUomQuantity(
        selectedRow.productQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomUnitPrice = getUomPrice(
        selectedRow.unitPrice,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomAvailableQuantity = getUomQuantity(
        selectedRow.availableQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomFulfilledQuantity = getUomQuantity(
        selectedRow.fulfilledQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomQuantityFulfilled = getUomQuantity(
        selectedRow.quantityFulfilled,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomPendingQuantity = getUomQuantity(
        selectedRow.pendingQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInBills = getUomQuantity(
        selectedRow.receivedQuantityInBills,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInOrder = getUomQuantity(
        selectedRow.receivedQuantityInOrder,
        selectedRow.documentUOMSchemaDefinition
      );

      // updatedLineItem.uomUnitPrice = selectedRow.unitPrice;
      // updatedLineItem.uomQuantity = selectedRow.productQuantity;

      updatedLineItem = addProductCustomFieldsToLineItem(
        { ...updatedLineItem },
        product
      );

      updatedLineItem = checkIfLineLevelCustomFieldIsValid(
        updatedLineItem,
        productCustomFields
      );

      updatedLineItem = {
        ...updatedLineItem,
        unitPrice: updatedLineItem.unitPrice
          ? updatedLineItem.unitPrice * booksDocument.exchangeRate
          : 0,
        unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
        documentUom: product.stockUom,
        cessRule: product.cessRule,
        cessRuleDescription: product.cessRuleDescription,
        cessNonAdvol: product?.cessNonAdvol,
        stockUom: product.stockUom,
        reservedQuantitiesData: null
      };

      lastUpdatedIndex.current = rowIndex;

      const newState = booksDocument['items'].map((obj: any, index: number) => {
        if (index === rowIndex) {
          return {
            ...obj,
            productDescription: obj['product']['description']
          };
        }
        return obj;
      });
      rows[rowIndex] = { ...updatedLineItem, tax };
      if (isUpdateReceiveByDateRequired) {
        updateShipByDateForLineLevelDate(documentDate, rows);
      } else {
        updateShipByDateForLineLevelDate(receiveByDate, rows);
      }
      setProductRows(newState);

      const productsPayload = [
        { productId: product.productId, uomId: product.stockUom, quantity: 1 }
      ];
      getPricing(
        productsPayload,
        rows,
        booksDocument.currency,
        false,
        booksDocument.exchangeRate
      );
      setProductRows(rows);
    }
  };

  const getTootltipContent = (rowData: any) => {
    let htmlString = '<div class="column">';
    let total = 0;
    htmlString +=
      '<div class="row parent-width font-semibold">Linked Purchase Order</div>';
    rowData.linkedPurchaseOrders?.forEach((item: any) => {
      total += +item.totalAmount;
      htmlString += `<div class="row parent-width justify-content-between mt-s"><div class="column">${item.documentSequenceCode}</div>


      </div>`;
    });

    return htmlString;
  };

  const getTootltipContentForRows = (rowData: any) => {
    let htmlString = '<div class="column">';
    let total = 0;
    htmlString +=
      '<div class="row parent-width font-semibold">Linked Purchase Order</div>';
    rowData.linkedPoCode?.forEach((item: any) => {
      htmlString += `<div class="row parent-width justify-content-between mt-s"><div class="column">${item}</div>


      </div>`;
    });

    return htmlString;
  };

  const validateProduct = (product: any) => {
    const offeringType = product.offeringType;
    const productType = product.type;
    const hsnOrSacCode = product.hsnOrSacCode;
    const uqcIndia = product.uqcIndia;

    let showPopup = false;
    let message = '';

    if (
      offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
      (productType === PRODUCT_TYPE.TRACKED ||
        productType === PRODUCT_TYPE.NON_TRACKED) &&
      (Utility.isEmpty(hsnOrSacCode) || Utility.isEmpty(uqcIndia))
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code and UQC required. Please update or select another product.';
    }

    if (
      offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
      productType === PRODUCT_TYPE.NON_TRACKED &&
      Utility.isEmpty(hsnOrSacCode)
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code required. Please update or select another product.';
    }

    return true;
  };

  const onRowUpdate = ({ columnData, columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    let rows: any = [...productRows];
    let selectedRows = { ...rows[rowIndex] };
    let dataToUpdate: any = rowData && rowData[columnKey];
    selectedRows.invalidFields = selectedRows.invalidFields
      ? [...selectedRows.invalidFields].filter((field) => field !== columnKey)
      : [];

    setRoundOffDirty(false);

    switch (columnKey) {
      case 'product':
        setCurrentIndex(rowIndex);

        if (!validateProduct(rowData[columnKey])) {
          if (Utility.isEmpty(rows[rowIndex]?.product)) {
            rows[rowIndex] = {};
            selectedRows.invalidFields.push('product');
            setProductRows(rows);
          } else {
            setProductRows(rows);
          }
          return;
        }

        rows[rowIndex][columnKey] = dataToUpdate;

        break;
      case 'productQuantity':
        if (
          !Number.isInteger(Number(dataToUpdate)) &&
          selectedRows?.product?.advancedTracking === ADVANCE_TRACKING.SERIAL
        ) {
          showAlert(
            'Error',
            `The quantity of serial products cannot be entered as a decimal number.`
          );
          dataToUpdate = 0;
          selectedRows.invalidFields.push('productQuantity');
        } else {
          if (!dataToUpdate || isNaN(dataToUpdate)) {
            dataToUpdate = undefined;
            selectedRows.invalidFields.push('productQuantity');
          } else {
            dataToUpdate = Utility.roundingOff(
              dataToUpdate,
              tenantInfo.decimalScale
            );
            selectedRows.uomQuantity = Utility.roundOff(dataToUpdate);
          }
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (rowData.isPartialInvoice ||
            rowData.isPartialBill ||
            rowData.isPartialSalesOrder) &&
          isQuantityMoreThatPendingQuantity(+dataToUpdate, rowData)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
          showAlert(
            'Error!',
            `Quantity should not be more than pending quantity`
          );
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (+dataToUpdate === 0 || +dataToUpdate < 0)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        }
        if (!selectedRows.invalidFields.includes('productQuantity')) {
          if (!Utility.isEmpty(selectedRows?.product)) {
            isQuantityChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +dataToUpdate
                    : item.productQuantity
              }));

            rows[rowIndex][columnKey] = +dataToUpdate;

            // Fetch price list price after first change for new rows.
            // For existing rows price list price will be fetched as usual.
            let fetchUnitPrice = false;
            if (
              typeof rows[rowIndex]['id'] !== 'undefined' &&
              rows[rowIndex]['id'] !== null
            ) {
              fetchUnitPrice = true;
            } else {
              if (!rows[rowIndex]['firstAmountChangeDone']) {
                rows[rowIndex]['firstAmountChangeDone'] = true;
              } else {
                fetchUnitPrice = true;
              }
            }
            if (fetchUnitPrice) {
              getPricing(
                products,
                rows,
                booksDocument.currency,
                true,
                booksDocument.exchangeRate,
                { quantityChanged: true }
              );
            }
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;

      case 'uom':
        rows[rowIndex] = updateUomSchemaForLineItem(rowIndex, dataToUpdate);
        if (!selectedRows.invalidFields.includes('uom')) {
          if (
            !Utility.isEmpty(selectedRows?.product) &&
            !selectedRows.invalidFields.includes('productQuantity')
          ) {
            isUOMChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? rows[rowIndex]?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +selectedRows?.productQuantity
                    : item.productQuantity
              }));
            getPricing(
              products,
              rows,
              booksDocument.currency,
              true,
              booksDocument.exchangeRate,
              { uomChanged: true }
            );
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      case 'customField':
        let existingCFs = [...rows[rowIndex].customField];
        existingCFs[0] = dataToUpdate;
        rows[rowIndex].customField = existingCFs;
        break;
      case 'expectedDeliveryDt':
        rows[rowIndex][columnKey] = new Date(dataToUpdate);
        updateShipByDateForLineLevelDate(dataToUpdate, rows);
        break;
      default:
        const CFColumnConfig = columnConfig?.find(
          (cf: any) => cf?.id === columnKey && cf.isCustomField
        );
        const filteredCF: any = availableCustomFields?.content?.find(
          (field: any) => field.id === columnKey
        );
        if (CFColumnConfig && filteredCF) {
          const cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value:
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
                ? DateFormatService.getDateStrFromDate(
                    new Date(dataToUpdate),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : dataToUpdate
          };
          let existingCFs = [...rows[rowIndex].customField];
          const existingCFIndex = existingCFs.findIndex(
            (cf: any) => cf?.id === cfToUpdate?.id
          );
          if (existingCFIndex === -1) {
            existingCFs = [...existingCFs, cfToUpdate];
          } else {
            existingCFs[existingCFIndex] = cfToUpdate;
          }
          rows[rowIndex].customField = existingCFs;
        }
        rows[rowIndex][columnKey] =
          filteredCF?.fieldType?.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ? new Date(dataToUpdate)
            : dataToUpdate;
        if (
          selectedRows?.customField &&
          selectedRows?.customField?.length > 0
        ) {
          const customFieldIndex = selectedRows?.customField?.findIndex(
            (field: any) => field.id === +columnKey
          );
          if (columnData.type === 'dropdown') {
            selectedRows[columnKey] =
              dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
            const filteredCF: any = productCustomFields?.find(
              (field: any) => field.id === +columnKey
            );
            const { rowData } = updateRowDataWithParentCFValues(
              dataToUpdate.value,
              { ...selectedRows },
              filteredCF,
              productCustomFields
            );
            selectedRows = rowData;
            // selectedRows.customField[customFieldIndex].value =
            //   dataToUpdate?.value;
          } else {
            selectedRows[columnKey] = dataToUpdate;
            let tempCustomField = [...selectedRows.customField];
            let tempCustomFieldRow = {
              ...selectedRows?.customField[customFieldIndex]
            };
            tempCustomFieldRow.value = dataToUpdate;
            tempCustomField[customFieldIndex] = tempCustomFieldRow;
            selectedRows.customField = tempCustomField;
          }
          rows[rowIndex] = selectedRows;
        }

        rows[rowIndex] = checkIfLineLevelCustomFieldIsValid(
          rows[rowIndex],
          productCustomFields
        );
        break;
    }
    // if (columnKey !== 'uom') {
    // rows[rowIndex][columnKey] = dataToUpdate;
    // }
    lastUpdatedIndex.current = rowIndex;
    if (columnKey === 'product') {
      onProductChange(rowIndex, dataToUpdate);
    } else {
      setProductRows(rows);
      setLineItemsTouched(true);
    }
  };

  const updateShipByDateForLineLevelDate = (
    dataToUpdate: any,
    productRowsArray: any[]
  ) => {
    if (Utility.isEmpty(productRowsArray) || !Utility.isMRPWithURLCheck()) {
      return;
    }
    let dateToUpdate: Date = new Date(dataToUpdate);
    let calculatedShipByDate: Date;
    let maxDateProductObject = productRowsArray?.reduce(function (
      a: any,
      b: any
    ) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    // compoare it to new
    if (
      typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
      maxDateProductObject?.expectedDeliveryDt !== null
    ) {
      calculatedShipByDate =
        maxDateProductObject?.expectedDeliveryDt > documentDate
          ? maxDateProductObject?.expectedDeliveryDt
          : documentDate;
    } else {
      calculatedShipByDate = dateToUpdate;
    }
    setTimeout(() => {
      setreceiveByDate(calculatedShipByDate);
    }, 300);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...productRows];
    rows.splice(rowIndex, 1);

    // Update existing Line Number in case of copy
    if (existingLineNumbers.includes(rowIndex + 1)) {
      let existingLineNumbersCopy = [...existingLineNumbers];
      let indexToDelete = existingLineNumbersCopy.findIndex(
        (x) => x === rowIndex + 1
      );
      if (indexToDelete !== -1) {
        existingLineNumbersCopy.splice(indexToDelete, 1);
        setExistingLineNumbers(existingLineNumbersCopy);
      }
    }

    rows = rows.map((row: DocumentItem, index: number) => {
      return {
        ...row,
        lineNumber: index + 1
      };
    });
    lastUpdatedIndex.current = null;

    if (
      receiveByDate.getTime() ===
      productRows?.[rowIndex]?.expectedDeliveryDt?.getTime()
    ) {
      updateShipByDateForLineLevelDate(documentDate, rows);
    } else {
      updateShipByDateForLineLevelDate(receiveByDate, rows);
    }

    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows],
        purchaseRequestItems: [...rows],
        reservedStock: rows.length ? prevBooksDocument.reservedStock : false
      };
    });
  };

  const getDataGridColumns = () => {
    let columns = [...columnConfig];

    columns = getCustomFieldColumnConfig(columns);
    if (props.draftType === DraftTypes.READONLY) {
      columns.push(
        {
          name: 'Status',
          type: INPUT_TYPE.SELECT,
          index: 3,
          options: [
            {
              id: 'UNRECEIVED',
              name: 'Not Converted',
              color: 'data-grid-badge-color-10'
            },
            {
              id: 'PARTIAL_RECEIVED',
              name: 'Partial Converted',
              color: 'data-grid-badge-color-5'
            },
            {
              id: 'FULLY_RECEIVED',
              name: 'Fully Converted',
              color: 'data-grid-badge-color-6'
            }
            // {
            //   id: 'NOT_APPLICABLE',
            //   name: 'Not Applicable',
            //   color: 'data-grid-badge-color-1'
            // }
          ],
          required: true,
          width: 140,
          editable: false,
          hidden: false,
          uiVisible: true,
          //systemField: false,
          columnCode: 'receipt_status',
          id: 'receipt_status',
          key: 'receipt_status',
          allowAddOption: false,
          allowFilter: false
        }
        // {
        //   id: 'purchaseDocumentCode',
        //   key: 'purchaseDocumentCode',
        //   name: 'PO #',
        //   index: 5,
        //   type: INPUT_TYPE.TEXT,
        //   width: 110,
        //   systemField: true,
        //   editable: false,
        //   hidden: false,
        //   uiVisible: true
        // }
      );
    }

    if (Utility.isMRPWithURLCheck()) {
      columns.push({
        id: 'expectedDeliveryDt',
        key: 'expectedDeliveryDt',
        name: 'Expected Date of Delivery',
        width: 130,
        editable: true,
        type: INPUT_TYPE.DATE
      });
    }

    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: booksDocument.reservedStock ? 80 : 50,
      options:
        props.documentMode === DOCUMENT_MODE.VIEW ||
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.draftType === DraftTypes.READONLY ||
        props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.REQUISITION ||
        Utility.isDropship(props.booksDocument)
          ? []
          : tenantInfo?.country === TAX_SYSTEM.INDIA_GST
          ? []
          : [
              {
                icon: DKIcons.ic_delete,
                className: 'p-0 padding-top-2px',
                onClick: (data: any) => onDelete(data)
              }
            ]
    });
    return columns;
  };

  useEffect(() => {
    if (props.canValidate) {
      if (!productRows.length) {
        addNewItem();
      }
    }
  }, [props.canValidate]);

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };
  const updateConfig = () => {
    let config = [...columnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'documentSequenceCode':
          conf.editable = false;
          break;
        case 'customField':
          const data = getClassDimensionData();
          conf.hidden = hideClassColumn();
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY;
          conf.dropdownConfig.data = data?.length ? data : [];
          conf.dropdownConfig.button.onClick = () => setShowAddClassPopup(true);
          break;
        case 'productDescription':
          // Set product desc editable at all stage
          // conf.editable =
          //   props.documentMode !== DOCUMENT_MODE.VIEW &&
          //   props.draftType !== DraftTypes.READONLY;
          conf.renderer =
            props.draftType === DraftTypes.READONLY &&
            props.documentMode === DOCUMENT_MODE.VIEW
              ? (obj: any) => {
                  return !Utility.isEmpty(obj.rowData.linkedPoCode) ? (
                    <DKTooltipWrapper
                      content={getTootltipContentForRows(obj.rowData)}
                      tooltipClassName="bg-deskera-secondary w-80"
                    >
                      <div className="row width-auto">
                        <DKLabel
                          text={obj.rowData.productDescription}
                          className={'fs-m ml-xs'}
                        />
                      </div>
                    </DKTooltipWrapper>
                  ) : (
                    <DKLabel
                      text={obj.rowData.productDescription}
                      className={'fs-m ml-xs'}
                    />
                  );
                }
              : (obj: any) => {
                  return (
                    <DKLabel
                      text={obj.rowData.productDescription}
                      className={'fs-m ml-xs'}
                    />
                  );
                };

          break;
        case 'product':
          let productDataArray =
            productsData?.content?.length > 0 ? productsData.content : [];
          conf.editable =
            (Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.REQUISITION) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY ||
            props.booksDocument.isPartialInvoice ||
            props.booksDocument.isPartialBill
              ? false
              : true;
          conf.dropdownConfig.data =
            productDataArray.length > 0
              ? DocumentConfigUtility.productDataParser(
                  { content: productDataArray },
                  props.booksDocument.documentType
                )
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getProductURL(
              search,
              tenantInfo,
              PRODUCT_TRANSACTIONTYPE_PURCHASE
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.productDataParser(
              data,
              props.booksDocument.documentType
            );
          if (
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.PRODUCTS.CREATE
            )
          ) {
            conf.dropdownConfig.button.onClick = () =>
              setShowProductPopup(true);
          } else {
            conf.dropdownConfig.button = null;
          }
          break;

        case 'uom':
          if (props.draftType == DraftTypes.READONLY) {
            conf.width = 110;
          }
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY;
          conf.dropdownConfig.data = selectedProductUOMS || [];
          break;

        case 'productQuantity':
          conf.editable =
            (Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.REQUISITION) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY
              ? false
              : true;
          break;
        default:
          // Set custom fields editable at all stage
          // conf.editable =
          //   props.documentMode !== DOCUMENT_MODE.VIEW &&
          //   props.draftType !== DraftTypes.READONLY;
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };
  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };
  const onAddressUpdated = () => {
    showAlert('working');
  };
  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);

        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.updateContact();
        break;

      case POPUP_CLICK_TYPE.UPDATE_ADDRESS:
        editAddressRef.current?.storeCallbacksRef.updateAddress();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
    }
  };
  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.UPDATE_ADDRESS:
        editAddressRef.current.storeCallbacksRef.updateAddress =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.ADDRESS_UPDATE_SUCCESSFUL:
        onAddressUpdated();
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        break;
    }
  };

  const getUpdateOrgPopup = () => {
    const popupConfigForOrganisationProfile: BtnType[] = [
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.UPDATE`),
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
      }
    ];
    return showUpdateOrgPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Update ${getCapitalized(
          localizedText('organisation')
        )} Profile`}
        btnList={popupConfigForOrganisationProfile}
        width={'40%'}
        height={'95%'}
        disableClickOutside
      >
        <OrganisationProfileForm
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          activeTab={activeOrgProfileTab}
        />
      </PopupWrapper>
    ) : null;
  };
  const isBillOrOrder = true;

  const updateExpectedDeliveryDateForLineItem = (
    leadTime: number,
    newDocDate: Date
  ) => {
    let duedocumentDate = newDocDate || new Date();
    let leadTimeDate = addDays(duedocumentDate, leadTime);
    return leadTimeDate;
  };

  const getContactAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
      addressCustomFields = booksDocument?.billTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      addressCustomFields = booksDocument?.shipTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = booksDocument?.shipFrom?.customFields;
    }
    return Utility.isNotEmpty(addressCustomFields) ? (
      <div
        className={`${addressCustomFields?.length ? 'mb-r mr-l' : ''}`}
        style={{
          marginLeft: addressCustomFields?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={[]}
          columnConfigTableId={''}
          documentMode={props.documentMode}
          onUpdate={async (updatedCFList) => {
            let booksDocumentCopy = { ...booksDocument };
            if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                billTo: {
                  ...booksDocumentCopy.billTo,
                  customFields: [...updatedCFList]
                }
              };
            } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                shipTo: {
                  ...booksDocumentCopy.shipTo,
                  customFields: [...updatedCFList]
                }
              };
            } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                shipFrom: {
                  ...booksDocumentCopy.shipFrom,
                  customFields: [...updatedCFList]
                }
              };
            }
            setBooksDocument(booksDocumentCopy);
          }}
          onLocationUpdate={(loc: any) => {}}
          contact={contact}
          hideAddfieldButton={true}
          addressUpdate={
            isBillToAddressUpdated ||
            isShipToAddressUpdated ||
            isContactUpdated ||
            isShipFromAddressUpdated
          }
          updateAddressField={() => {
            setIsBillToAddressUpdated(false);
            setIsShipToAddressUpdated(false);
            setIsContactUpdated(false);
            setIsShipFromAddressUpdated(false);
          }}
        />
      </div>
    ) : null;
  };
  const getDateAndOtherInfoBlock = () => {
    return (
      <>
        <div className="position-relative mb-m">
          <div
            className={`row width-auto mb-xs justify-content-between cursor-pointer `}
            style={{
              width: 240
            }}
            onClick={() => {
              setRequisitionDateOpen(!requisitionDateOpen);
            }}
          >
            <div className="row width-auto ">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text="Requisition Date" className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(documentDate)}
              className="ml-r "
            />
          </div>
          {!(props.draftType === DraftTypes.READONLY) &&
            requisitionDateOpen &&
            getCalendarView(
              documentDate,
              (newDate: any) => {
                validateAndUpdateDate(
                  newDate,
                  DateFormatService.getDateFromStr(
                    tenantInfo.bookBeginningStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  (date: any) => {
                    setDocumentDate(date);
                    // update line level expected Delivery date for lead time
                    if (Utility.isMRPWithURLCheck()) {
                      let mappedProductRows = [...productRows];
                      mappedProductRows = mappedProductRows?.map(
                        (productItem: any) => {
                          let leadTimeValue = productItem?.product
                            ?.leadTimeDetails?.[0]?.leadTime
                            ? productItem?.product?.leadTimeDetails?.[0]
                                ?.leadTime
                            : productItem?.product?.leadTime;
                          productItem.expectedDeliveryDt =
                            updateExpectedDeliveryDateForLineItem(
                              leadTimeValue || 0,
                              date
                            );
                          return productItem;
                        }
                      );
                      updateShipByDateForLineLevelDate(
                        documentDate > date ? date : receiveByDate,
                        mappedProductRows
                      );
                      setProductRows(mappedProductRows);
                    }
                  },
                  `Requisition Date cannot be before books beginning date.`,
                  true
                );
              },
              setRequisitionDateOpen
            )}
        </div>

        <div className="position-relative mb-m">
          <div
            className={`row width-auto mb-xs justify-content-between cursor-pointer`}
            style={{
              width: 240
            }}
            onClick={() => {
              setDueDateOpen(!dueDateOpen);
            }}
          >
            <div className="row width-auto  ">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text="Due Date" className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(dueDate)}
              className="ml-r "
            />
          </div>
          {!(props.draftType === DraftTypes.READONLY) &&
            dueDateOpen &&
            getCalendarView(
              dueDate,
              (newDate: any) =>
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setDueDate,
                  `${t(
                    `DOCUMENT.DUE_DATE`
                  )} cannot be before Requisition date.`,
                  false
                ),
              setDueDateOpen
            )}
        </div>

        <div className="position-relative mb-s">
          <div
            className={`row width-auto mb-xs justify-content-between cursor-pointer `}
            style={{
              width: 240
            }}
            onClick={() => {
              setreceiveByDateOpen(!receiveByDateOpen);
            }}
          >
            <div className="row width-auto ">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text="Receive By" className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(receiveByDate)}
              className="ml-r "
            />
          </div>
          {!(props.draftType === DraftTypes.READONLY) &&
            receiveByDateOpen &&
            getCalendarView(
              receiveByDate,
              (newDate: any) =>
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setreceiveByDate,
                  `${receiveByDate} date cannot be before Requisition date.`,
                  false
                ),
              setreceiveByDateOpen
            )}
        </div>
      </>
    );
  };

  const getDateAndOtherInfoBlockForFullScreen = () => {
    return (
      <div className="row align-items-end flex-wrap mb-l" style={{ gap: 12 }}>
        <div
          style={{
            width: 150,
            maxWidth: 200
          }}
        >
          <DKInput
            className="parent-width"
            title={'Requisition Date'}
            value={documentDate}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.DATE}
            readOnly={props.draftType === DraftTypes.READONLY}
            onChange={(newDate: any) => {
              validateAndUpdateDate(
                newDate,
                DateFormatService.getDateFromStr(
                  tenantInfo.bookBeginningStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                (date: any) => {
                  setDocumentDate(date);
                  // update line level expected Delivery date for lead time
                  if (Utility.isMRPWithURLCheck()) {
                    let mappedProductRows = [...productRows];
                    mappedProductRows = mappedProductRows?.map(
                      (productItem: any) => {
                        let leadTimeValue = productItem?.product
                          ?.leadTimeDetails?.[0]?.leadTime
                          ? productItem?.product?.leadTimeDetails?.[0]?.leadTime
                          : productItem?.product?.leadTime;
                        productItem.expectedDeliveryDt =
                          updateExpectedDeliveryDateForLineItem(
                            leadTimeValue || 0,
                            date
                          );
                        return productItem;
                      }
                    );
                    updateShipByDateForLineLevelDate(
                      documentDate > date ? date : receiveByDate,
                      mappedProductRows
                    );
                    setProductRows(mappedProductRows);
                  }
                },
                `Requisition Date cannot be before books beginning date.`,
                true
              );
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
          />
        </div>
        <div
          style={{
            width: 150,
            maxWidth: 200
          }}
        >
          <DKInput
            className="parent-width"
            title={'Due Date'}
            value={dueDate}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.DATE}
            readOnly={props.draftType === DraftTypes.READONLY}
            onChange={(newDate: any) =>
              validateAndUpdateDate(
                newDate,
                documentDate,
                setDueDate,
                `${t(`DOCUMENT.DUE_DATE`)} cannot be before Requisition date.`,
                false
              )
            }
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
          />
        </div>
        <div
          style={{
            width: 150,
            maxWidth: 200
          }}
        >
          <DKInput
            className="parent-width"
            title={'Receive By'}
            value={receiveByDate}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.DATE}
            readOnly={props.draftType === DraftTypes.READONLY}
            onChange={(newDate: any) =>
              validateAndUpdateDate(
                newDate,
                documentDate,
                setreceiveByDate,
                `Receive by date (${DateFormatService.getDateStrFromDate(
                  newDate
                )}) cannot be before requisition date.`,
                false
              )
            }
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
          />
        </div>
      </div>
    );
  };

  const onRowSelectionFromPopup = (selectedProducts: any[]) => {
    populateProductGroupData(selectedProducts);
    setShowProductSelectionPopup(false);
  };

  const populateProductGroupData = (selectedProducts: any) => {
    let rows: any = [...productRows];

    if (selectedProducts && selectedProducts !== null) {
      if (selectedProducts.length > 0) {
        selectedProducts.forEach((product: any) => {
          let element = { ...product }
          element['productQuantity'] = 1;
          element['unitPrice'] = 0;
          element['lineNumber'] = rows.length + 1;
          element['firstAmountChangeDone'] = false;

          if (!Utility.isEmpty(availableCustomFields)) {
            // Set default values of CFs when new line is added
            availableCustomFields?.content?.forEach((item: any) => {
              if (
                item.modules?.includes(MODULES_NAME.PRODUCT) &&
                item.status === STATUS_TYPE.ACTIVE &&
                !item.system
              ) {
                if (
                  typeof item.defaultValue !== 'undefined' &&
                  item.defaultValue !== null &&
                  item.defaultValue !== ''
                ) {
                  if (
                    item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    element[item.id] = DateFormatService.getDateFromStr(
                      item.defaultValue,
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    );
                  } else {
                    element[item.id] = item.defaultValue;
                  }
                } else {
                  element[item.id] = '';
                }
              }
            });
          }

          let uom = uomsData.find((u: any) => u.id === element.stockUom);
          if (validateProduct(element)) {
            if (Utility.isEmpty(element?.product)) {
              element.product = {}
              element.product = deepClone(product)
              if (uom) {
                element = updateUomSchemaForLineItemProductGroup(element, uom)
              }
            }
            rows.push(element)
          }
        });
        setProductRows(rows);
        setBooksDocument((prevBooksDocument: any) => {
          return {
            ...prevBooksDocument,
            items: [...rows],
            purchaseRequestItems: [...rows]
          };
        });
      }
    }
  }

  const updateUomSchemaForLineItemProductGroup = (element: any, uomData: any) => {
    let selectedRow = element
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;

    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }

    selectedRow.uom = { ...uomData, isBaseUom: true }
    selectedRow.documentUOMSchemaDefinition = null;
    selectedRow.documentUom = uomData.id;
    
    return selectedRow;
  };

  const getProductSelectionPopup = () => {
    return (
      <DocProductSelectionPopup
        module={DOC_TYPE.REQUISITION}
        onSave={onRowSelectionFromPopup}
        onCancel={() => setShowProductSelectionPopup(false)}
      />
    );
  };

  return (
    <div className=" m-l">
      <div ref={gridContainerRef}>
        <div className="row justify-content-between align-items-stretch mt-l">
          <div
            className={
              'column justify-content-between position-relative width-80 pr-l '
            }
          >
            <div className="column justify-content-between position-relative width-80 pr-l">
              <div className="row width-auto align-items-start gap-2">
                <div>
                  <div
                    className={`column document-address-block`}
                    style={{
                      minWidth: 170,
                      maxWidth: 250,
                      marginLeft: -10,
                      paddingRight: 10,
                      paddingLeft: 10,
                      paddingBottom: 10
                    }}
                  >
                    <div
                      className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                      onClick={() => {
                        setOpenBillingAddressList(true);
                      }}
                    >
                      <DKLabel
                        text={'Bill to'}
                        className="fw-b fs-r text-gray"
                      />
                      <DKLabel
                        text={
                          isBillOrOrder
                            ? tenantInfo.name
                            : `${contact.name}-(${contact.currencyCode})`
                        }
                        className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                      />
                      <DKLabel
                        className={
                          'cursor-pointer ' + GOOGLE_NO_TRANSLATE_CLASS
                        }
                        text={
                          !Utility.isEmpty(
                            billingAddress?.currentBillingAddress ||
                              (isBillOrOrder &&
                              !Utility.isDropship(props.booksDocument)
                                ? booksDocument?.billTo
                                : null)
                          )
                            ? getFormattedAddress(
                                isBillOrOrder &&
                                  !Utility.isDropship(props.booksDocument)
                                  ? booksDocument?.billTo
                                  : null
                              )
                            : 'No Address'
                        }
                      />
                    </div>
                  </div>
                  {!(props.draftType === DraftTypes.READONLY) &&
                    openBillingAddressList && (
                      <DKListPicker2
                        data={
                          isBillOrOrder
                            ? tenantInfo.billingAddresses
                            : billingAddress?.billingAddress
                        }
                        className="position-absolute z-index-3 bg-white border-m shadow-m"
                        style={{ minWidth: 250 }}
                        renderer={(index: number, addressObj: any) => (
                          <div
                            style={{
                              width: 200,
                              whiteSpace: 'pre-wrap',
                              textAlign: 'left'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: getFormattedAddress(addressObj)
                            }}
                          ></div>
                        )}
                        onEdit={(index: number, obj: any) => {}}
                        button={
                          isBillOrOrder &&
                          Utility.isDropship(props.booksDocument)
                            ? null
                            : {
                                title: 'Manage address',
                                className:
                                  'text-white fw-m bg-button justify-content-center',
                                onClick: () => {
                                  if (isBillOrOrder) {
                                    setActiveOrgProfileTab(
                                      ORGANISATION_FORM_TAB.ADDRESS_INFO
                                    );
                                    setShowUpdateOrgPopup(true);
                                  } else {
                                    // //   setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                                    // //   setContactMode(DOCUMENT_MODE.EDIT);
                                    // //   setShowAddContactPopup(true);
                                    // // }
                                    // // setOpenBillingAddressList(false);
                                    showAlert('Working on it');
                                  }
                                }
                              }
                        }
                        canEdit={false}
                        onSelect={(index: number, addressObj: any) => {
                          setBillingAddress({
                            billingAddress:
                              billingAddress?.billingAddress as BooksAddress[],
                            currentBillingAddress: addressObj
                          });
                          setBooksDocument((prevState: any) => ({
                            ...prevState,
                            billTo: addressObj
                          }));
                          setOpenBillingAddressList(false);
                          setIsBillToAddressUpdated(!isBillToAddressUpdated);
                        }}
                        onClose={() => {
                          setOpenBillingAddressList(false);
                        }}
                        allowSearch={false}
                      />
                    )}
                  {getContactAddressCustomFields(
                    BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                  )}
                </div>
                <div>
                  <div
                    className={`column document-address-block`}
                    style={{
                      minWidth: 170,
                      maxWidth: 250,
                      marginLeft: -10,
                      paddingRight: 10,
                      paddingLeft: 10,
                      paddingBottom: 10
                    }}
                  >
                    <div
                      className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                      onClick={() => {
                        setOpenShippingAddressList(true);
                      }}
                    >
                      <DKLabel
                        text={'Ship to'}
                        className="fw-b fs-r text-gray"
                      />
                      <DKLabel
                        text={
                          isBillOrOrder
                            ? tenantInfo.name
                            : `${contact.name}-(${contact.currencyCode})`
                        }
                        className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                      />
                      <DKLabel
                        className={
                          'cursor-pointer ' + GOOGLE_NO_TRANSLATE_CLASS
                        }
                        text={
                          !Utility.isEmpty(
                            shippingAddress?.currentShippingAddress ||
                              (isBillOrOrder &&
                              !Utility.isDropship(props.booksDocument)
                                ? booksDocument?.shipTo
                                : null)
                          )
                            ? getFormattedAddress(
                                shippingAddress?.currentShippingAddress ||
                                  (isBillOrOrder &&
                                  !Utility.isDropship(props.booksDocument)
                                    ? booksDocument?.shipTo
                                    : null)
                              )
                            : ''
                        }
                      />
                    </div>
                  </div>
                  {!(props.draftType === DraftTypes.READONLY) &&
                    openShippingAddressList && (
                      <DKListPicker2
                        data={
                          isBillOrOrder
                            ? tenantInfo.shippingAddresses
                            : shippingAddress?.shippingAddress
                        }
                        className="position-absolute z-index-3 bg-white border-m shadow-m"
                        style={{ minWidth: 250 }}
                        renderer={(index: number, addressObj: any) => (
                          <div
                            style={{
                              width: 200,
                              whiteSpace: 'pre-wrap',
                              textAlign: 'left'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: getFormattedAddress(addressObj)
                            }}
                          ></div>
                        )}
                        onEdit={(index: number, obj: any) => {}}
                        button={{
                          title: 'Manage address',
                          className:
                            'text-white fw-m bg-button justify-content-center',
                          onClick: () => {
                            if (isBillOrOrder) {
                              setActiveOrgProfileTab(
                                ORGANISATION_FORM_TAB.ADDRESS_INFO
                              );
                              setShowUpdateOrgPopup(true);
                            }
                            setOpenShippingAddressList(false);
                          }
                        }}
                        canEdit={false}
                        onSelect={(index: number, addressObj: any) => {
                          setShippingAddress({
                            shippingAddress:
                              shippingAddress?.shippingAddress as BooksAddress[],
                            currentShippingAddress: addressObj
                          });
                          if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                            setShipToOrFromAddressChangedForUS(true);
                          }
                          setBooksDocument((prevState: any) => ({
                            ...prevState,
                            shipTo: addressObj
                          }));

                          setOpenShippingAddressList(false);
                          setIsShipToAddressUpdated(!isShipToAddressUpdated);
                        }}
                        onClose={() => {
                          setOpenShippingAddressList(false);
                        }}
                        allowSearch={false}
                      />
                    )}
                  {getContactAddressCustomFields(
                    BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
                  )}
                </div>
              </div>
              {(Utility.isNotEmpty(booksDocument?.billTo?.customFields) ||
                Utility.isNotEmpty(booksDocument?.shipTo?.customFields)) && (
                <DKLine className="mt-s" />
              )}
            </div>
          </div>
          <div className="column align-items-end m-l">
            {props.draftType === DraftTypes.DRAFT &&
              props.draftData?.data?.draftCode &&
              !props.booksDocument?.seqCodeAlreadyDumped && (
                <div className="position-relative walkthrough-step-5">
                  <div
                    className="row width-auto mb-xs justify-content-between p-v-xs"
                    style={{
                      width: 240
                    }}
                  >
                    <div className="row width-auto">
                      <DKIcon
                        src={DKIcons.data_type.ic_number}
                        className="ic-xs-2"
                        style={{ opacity: 0.6 }}
                      />
                      <DKLabel text="Draft No." className={'fw-m ml-r'} />
                    </div>
                    <DKLabel
                      text={props.draftData.data.draftCode}
                      className={'ml-r '}
                    />
                  </div>
                </div>
              )}

            <div className="position-relative">
              <div
                className={`row width-auto mb-xs justify-content-between ${
                  props.draftType === DraftTypes.READONLY
                    ? 'listPickerBG'
                    : 'listPickerBG'
                }`}
                style={{
                  width: 240
                }}
              >
                <div
                  className="row width-auto cursor-pointer"
                  onClick={() => setopenCustomNumberList((value) => !value)}
                >
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />

                  <DKLabel text={'No.'} className={'fw-m ml-r'} />
                </div>
                {(props.documentMode === DOCUMENT_MODE.EDIT ||
                  props.documentMode === DOCUMENT_MODE.VIEW ||
                  props.booksDocument?.seqCodeAlreadyDumped === true) &&
                  props.booksDocument.documentSequenceCode && (
                    <DKLabel
                      text={props.booksDocument.documentSequenceCode}
                      className={'ml-r '}
                    />
                  )}
                {(props.documentMode === DOCUMENT_MODE.NEW ||
                  props.documentMode === DOCUMENT_MODE.COPY ||
                  (props.documentMode === DOCUMENT_MODE.VIEW &&
                    !props.booksDocument.documentSequenceCode)) &&
                  !props.booksDocument?.seqCodeAlreadyDumped && (
                    <div className="w-9/12 -mr-1">
                      <CustomNumberFormatInput
                        module={CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST}
                        selectedFormat={selectedFormat}
                        showCompact={true}
                        extraClass={'top-12 right-0'}
                        openList={openCustomNumberList}
                      />
                    </div>
                  )}
              </div>
            </div>

            {!fullScreenMode && getDateAndOtherInfoBlock()}

            {props.draftType !== DraftTypes.COPY && linkedDocument ? (
              <div className="position-relative">
                <div
                  className="row width-auto mb-xs justify-content-between cursor-pointer"
                  style={{
                    minWidth: 240
                  }}
                  onClick={() => {
                    setLinkDocumentOpen(!linkDocumentOpen);
                  }}
                >
                  <DKTooltipWrapper
                    content={getTootltipContent(booksDocument)}
                    tooltipClassName="bg-deskera-secondary w-80"
                  >
                    <div className="row width-auto">
                      <DKIcon
                        src={DKIcons.data_type.ic_url}
                        className="mt-s ic-xs-2"
                        style={{ opacity: 0.6 }}
                      />

                      <DKLabel
                        text={`Linked To Po`}
                        className={
                          'fw-b mt-s ml-xs p-v-0 text-blue underline grid-action-link-h-padding'
                        }
                      />
                    </div>
                  </DKTooltipWrapper>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {fullScreenMode && getDateAndOtherInfoBlockForFullScreen()}
        {getCustomFields()}
        <DKDataGrid
          className={'m-r'}
          allowBulkOperation={false}
          needColumnIcons={false}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowRowEdit={true}
          rows={
            props.draftType === DraftTypes.READONLY
              ? productRows
              : productRows.map((rowData) => getDataRows(rowData))
          }
          columns={getDataGridColumns()}
          onRowUpdate={onRowUpdate}
          onRowClick={onRowClick}
          width={gridWidth}
        />
        {!(props.draftType === DraftTypes.READONLY) && (
          <div className="row justify-content-between">
            <div className="row">
              <DKButton
                title={`+ Add Item`}
                onClick={() => {
                  if (
                    isProductGroupingEnabled
                  ) {
                    setShowProductSelectionPopup(true);
                  } else {
                    addNewItem();
                  }
                }}
                className={`${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
              />
              {productRows.length > 0 && (
                <DKButton
                  title={`- Clear all items`}
                  onClick={() => clearAllItems()}
                  className={`${
                    props.draftType === DraftTypes.READONLY
                      ? 'text-gray'
                      : 'text-blue'
                  } fw-m p-0`}
                  style={{ marginTop: -10, zIndex: 1 }}
                />
              )}
            </div>
            <div className="row"></div>
          </div>
        )}
        <div className="flex flex-row items-start justify-content-between box-border w-full mt-m">
          <textarea
            className={
              'column pointer-events-auto resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto'
            }
            style={{
              width: 500,
              height: 130,
              backgroundColor: 'rgb(250, 250, 250)',
              border: '1px dashed rgb(200, 200, 200)'
            }}
            placeholder="Memo optional"
            value={booksDocument.memo}
            onChange={(e: any) => {
              setBooksDocument((prevState: any) => {
                return {
                  ...prevState,
                  memo: e.target.value,
                  isDocumentTouched:
                    e.target.value?.trim() !== '' &&
                    booksDocument.memo?.trim() !== '' &&
                    e.target.value?.trim() !== booksDocument.memo?.trim()
                };
              });
            }}
          ></textarea>
        </div>
        {
          <div className="column mt-xl">
            <DKButton
              title={
                <>
                  + Attach files
                  <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                </>
              }
              onClick={() => {
                triggerAttachmentUpload();
              }}
              className={`${
                props.draftType ===
                DraftTypes.READONLY || (
                  props.booksDocument &&
                    props.booksDocument.linkedPurchaseOrders &&
                    props.booksDocument.linkedPurchaseOrders.length > 0
                )
                  ? 'text-gray'
                  : 'text-blue'
              } fw-m p-0`}
              style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
              disabled={
                props.draftType === DraftTypes.READONLY ||
                (props.booksDocument &&
                  props.booksDocument.linkedPurchaseOrders &&
                  props.booksDocument.linkedPurchaseOrders.length > 0)
              }
            />
            {showProductPopup && getProductForm()}
            <div className="row">{getAttachments()}</div>
          </div>
        }

        <div className="row mt-r">
          {(props.documentMode === DOCUMENT_MODE.EDIT ||
            props.documentMode === DOCUMENT_MODE.VIEW) &&
            !Utility.isEmpty(isNonConverted) && (
              <DocumentActionMenu
                booksDocument={{ ...props.booksDocument, ...booksDocument }}
                draftId={props.id}
                position="bottom"
                draftType={props.draftType}
                className={'justify-content-start'}
              />
            )}
        </div>
      </div>
      {openProductCFSettings && (
        <CustomFieldSettings
          fields={productCustomFields}
          moduleName={MODULES_NAME.PRODUCT}
          onSave={async () => {
            try {
              const updatedOrderedCFs = await dispatch(
                fetchProductCustomFields({
                  status: 'ACTIVE',
                  limit: '1000',
                  module: MODULES_NAME.PRODUCT
                })
              );
              setOpenProductCFSettings(false);
              //  updateLineLevelCForder(updatedOrderedCFs.payload?.content);
            } catch (err: any) {
              console.error('Error fetching product CFs: ', err);
            }
          }}
          onClose={() => setOpenProductCFSettings(false)}
        />
      )}
      {getUpdateOrgPopup()}
      {showProductSelectionPopup && getProductSelectionPopup()}
    </div>
  );
}
