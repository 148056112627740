import { DKIcon, DKLabel } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import React, { useEffect, useRef, useState } from 'react';

import DateFormatService from '../../Services/DateFormat';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';

import { CallBackPayloadType } from '../../Models/Interfaces';

import SalesReturnService from '../../Services/SalesReturn';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import SalesReturnTable from './SalesReturnTable';

import { localizedText } from '../../Services/Localization/Localization';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import WarehouseService from '../../Services/Warehouse';

export interface SalesReturnsRecordProps {
  documentDetails: any;
  salesReturnsDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isDeleted: (val: boolean) => void;
  isClosed: (val: boolean) => void;
}

const SalesReturnRecordDetailWithTable: React.FC<SalesReturnsRecordProps> = (
  props
) => {
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>(
    props.salesReturnsDetails
  );
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    fulfillmentDetails?.[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);

  const [res, setRes] = useState<any>({});

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isVisibleState, setIsVisibleState] = useState<boolean>(false);
  const [isSerail, setIsSerial] = useState<boolean>(false);

  const lineItemsTable = useRef(null);
  const warehousesData = useAppSelector(selectWarehouse);
  const [warehouseName, setWarehouseName] = useState<any>();
  useEffect(() => {
    if (props.salesReturnsDetails) {
      setFulfillmentDetails(props.salesReturnsDetails);
      setFulfillmentData(props?.salesReturnsDetails?.[0]);
      getWarehouseName(props?.salesReturnsDetails?.[0]?.warehouseCode);
    }
    setDocumentDetails(props.documentDetails);
  }, [props]);

  useEffect(() => {
    let itemsArray =
      documentDetails?.salesInvoiceItems ||
      documentDetails?.quotationItemDtoList;
    setItemDescriptionArray(itemsArray);
    if (tenantInfo.country === COUNTRY_CODES.IN) setIsVisibleState(true);
  }, [itemsDescriptionArray]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  const getWarehouseName = (code: any) => {
    WarehouseService.getWarehouseByCode(code).then((res) => {
      let whName = '';
      if (Utility.isNotEmpty(res?.content)) {
        setWarehouseName(res?.content?.[0]?.name);
        return res?.content?.[0]?.name;
      } else {
        setWarehouseName(whName);
      }
      // onChangeWarehouse(res.content[0])
    });
    // let wh = warehousesData?.content?.filter((ele: any) => ele.code === code);
    // return wh && wh?.[0]?.name;
  };

  const getProductDescription = (item: any, productId: any) => {
    let productDescription = item.productDescription || '';
    let product: any = itemsDescriptionArray?.filter(
      (ele: any) => ele.productCode === productId
    );
    return product?.[0]?.productDescription || productDescription;
  };

  const advanceTrackingExists = () => {
    return (
      fulfillmentData?.salesReturnItems?.filter(
        (item: any) => item.advancedTrackingType !== TRACKING_TYPE.NONE
      )?.length > 0
    );
  };
  return (
    <div className="flex align-self-start p-2 parent-width">
      <div className="flex flex-col parent-width">
        <div className="row justify-content-between align-items-start">
          <div className="column gap-1">
            <DKLabel className="fw-m" text={'Warehouse'} />
            <DKLabel text={warehouseName} />
          </div>
          {fulfillmentData?.reasonToReturn &&
            fulfillmentData?.reasonToReturn !== '' && (
              <div className="flex flex-col w-48">
                <span className="text-black fs-r ml-0 font-medium">Reason</span>
                <div className="position-relative pt-2">
                  {fulfillmentData?.reasonToReturn}
                </div>
              </div>
            )}
          <div className="column gap-1">
            <DKLabel className="fw-m" text={'Return Date'} />
            <DKLabel
              text={DateFormatService.getFormattedDateString(
                fulfillmentData?.salesReturnDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )}
            />
          </div>
        </div>
        <div
          className="flex justify-content-between invoice-table invoice-table position-relative mt-xxl hide-scroll-bar column"
          style={{
            overflowX: 'scroll'
          }}
        >
          <table
            ref={lineItemsTable}
            className="table mb-5"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ width: 40, minWidth: 40, maxWidth: 40 }}
                >
                  #
                </th>
                <th className="text-left" style={{ width: 150, minWidth: 200 }}>
                  Product Name
                </th>
                <th className="text-left">Product Description</th>
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  {`${localizedText('Fulfilled')} Qty`}
                </th>
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  Returned Qty
                </th>
                {advanceTrackingExists() && (
                  <th style={{ width: 60, minWidth: 60 }}> </th>
                )}
              </tr>
            </thead>
            <tbody>
              {fulfillmentData &&
                fulfillmentData.salesReturnItems.map((item: any, i: any) => {
                  return (
                    <tr className="dk-data-grid-row-bg">
                      <td className="text-align-center px-2 py-4 fs-r">
                        {i + 1}
                      </td>
                      <td className="px-2 py-4 fs-r">{item.productName}</td>
                      <td className="px-2 py-4 fs-r">
                        {getProductDescription(item, item.productCode)}
                      </td>
                      <td className="text-align-right px-2 py-4 fs-r">
                        {Utility.roundingOff(
                          item.documentUOMSchemaDefinition
                            ? item.uomQuantityFulfilled
                            : item.quantityFulfilled,
                          QTY_ROUNDOFF_PRECISION
                        )}
                      </td>
                      <td className="text-align-right px-2 py-4 fs-r">
                        {Utility.roundingOff(
                          item.documentUOMSchemaDefinition
                            ? item.uomQuantityToReturn
                            : item.quantityToReturn,
                          QTY_ROUNDOFF_PRECISION
                        )}
                      </td>
                      {item.advancedTrackingType !== TRACKING_TYPE.NONE && (
                        <td
                          className="text-align-right px-2 py-4 fs-r"
                          style={{ width: 45 }}
                        >
                          <div className="row justify-content-center">
                            <DKIcon
                              src={ic_barcode_green}
                              onClick={() => {
                                if (item.advancedTrackingType === 'BATCH') {
                                  setIsSerial(false);
                                }
                                if (item.advancedTrackingType === 'SERIAL') {
                                  setIsSerial(true);
                                }
                                SalesReturnService.getAdvanceTrackingRecord(
                                  item.itemSequenceCode
                                ).then((res: any) => {
                                  setRes({
                                    ...res,
                                    documentUOMSchemaDefinition:
                                      item.documentUOMSchemaDefinition
                                  });
                                  setShowPopup(true);
                                });
                              }}
                              title="Advanced Tracking"
                              className="ic-s flex align-items-center cursor-hand"
                              style={{ width: '100%' }}
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {showPopup && (
        <SalesReturnTable
          populateFormData={res}
          isSerial={isSerail}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default SalesReturnRecordDetailWithTable;
