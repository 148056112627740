import { DOC_TYPE } from '../../Constants/Constant';
import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const getUpdatedPIQObject = (props: any) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.PURCHASE_INWARD_QUOTATION,
    currencyCode: props.currencyCode,
    documentCode: Utility.getValue(props.documentCode, props.piqCode),
    vendorCode: props.contactCode,
    contactCode: props.contactDto?.code,
    buyType: null,
    orderType: null,
    status: props.status,
    placeOfSupply: null,
    reorder: false,
    dropShip: false,
    backOrder: false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: props?.validByDate,
    orderDueDate: props?.validByDate,
    fulfillmentDate: '',
    validTillDate: props?.validByDate,
    receiveGoodsStatus: null,
    currency: props.currencyCode,
    items: props.purchaseInwardQuotationItems?.map((item: any) => {
      return {
        ...item,
        documentSequenceCode: item?.product?.documentSequenceCode,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: item?.piqItemCode
      };
    }),
    hasPartialBill: false,
    purchaseOrderAssets: [],
    purchaseInwardQuotationItems: props.purchaseInwardQuotationItems?.map(
      (item: any) => {
        let totalReceivedQty = 0;
        return {
          ...item,
          receivedQuantity: totalReceivedQty,
          balanceReceivedQuantity: item.productQuantity - totalReceivedQty,
          billedQuantity: item.productQuantity - (item.pendingQuantity || 0),
          balanceBilledQuantity: item.pendingQuantity || 0
        };
      }
    )
  };

  return updatedObj;
};

export const setItemsFromPIQItems = (doc: any) => {
  const document = {
    ...doc,
    items: doc.purchaseInwardQuotationItems?.map((item: any) => {
      return {
        ...item,
        documentSequenceCode: item?.product?.documentSequenceCode,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive
      };
    }),
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.PURCHASE_INWARD_QUOTATION,
    documentDate: doc.piqReceiveDate,
    validTillDate: doc.validByDate,
    fulfillmentDate: doc.receiveByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  return document;
};
