import { useEffect, useState } from 'react';
import {
  getCFColumnConfig,
  getCFColumnKey,
  PRODUCT_BY_VENDOR_COLUMN_CONFIG
} from './PreferredVendorHelper';
import DataGridHolder from '../../SharedComponents/DataGridHolder';
import { showAlert } from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';
import {
  COLUMN_CODE,
  TABLE_DISPLAY_NAME,
  TABLES
} from '../../Constants/TableConstants';
import SideBarService from '../../Services/sidebar';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Contacts/Details';
import { IState } from '../../Models/Interfaces';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import PreferredVendorService, {
  PreferredVendorAPIConfig
} from '../../Services/PreferredVendor';
import {
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import CustomFieldService from '../../Services/CustomField';
import Utility from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  productsByVendorFilter,
  selectProductsByVendorFilter
} from '../../Redux/Slices/BookFilterSlice';

function ProductsByVendor({ contact, isPopupView, passingInteraction }: any) {
  const initialState: IState = {
    columnData: [],
    filter: [],
    rowData: [],
    originalData: []
  };

  const COLUMN = COLUMN_CODE.PRODUCT_BY_VENDOR;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const productsByVendorFilterData: any = useAppSelector(
    selectProductsByVendorFilter
  );

  const [columns, setColumns] = useState<any[]>(
    PRODUCT_BY_VENDOR_COLUMN_CONFIG
  );
  const [rowData, setRowData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [preferredVendors, setPreferredVendors] = useState(initialState);
  const [updating, setUpdating] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    getPreferredVendorCustomFields();

    if (Object.keys(productsByVendorFilterData?.config).length === 0) {
      setFilterDataInStore(PreferredVendorService.defaultConfig);
    }

    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
      setPreferredVendors(initialState);
    };
  }, []);

  useEffect(() => {
    const passingData = {
      columnData: columns,
      filter: [],
      rowData: rowData,
      originalData: rowData
    };

    setPreferredVendors(passingData);
  }, [columns, rowData]);

  useEffect(() => {
    if (!Utility.isEmpty(productsByVendorFilterData)) {
      if (Object.keys(productsByVendorFilterData?.config).length) {
        let config = productsByVendorFilterData.config;
        if (isInitialLoading) {
          config = {
            ...config,
            Page: 0
          }
        }
        getProductsByVendor(config);
        setIsInitialLoading(false);
      } else {
        const config: PreferredVendorAPIConfig = {
          ...PreferredVendorService.defaultConfig
        };

        setFilterDataInStore(config);
      }
    }
  }, [productsByVendorFilterData]);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const getPreferredVendorCustomFields = async () => {
    const customColumns: any[] = [];
    try {
      const params = {
        status: STATUS_TYPE.ACTIVE,
        module: MODULES_NAME.PREFERRED_VENDOR,
        limit: 1000
      };
      const response = await CustomFieldService.getCustomFields(params, true);

      if (!Utility.isEmpty(response) && Array.isArray(response.content)) {
        response.content.forEach((customField: any) => {
          const column = getCFColumnConfig(customField);
          customColumns.push(column);
        });
      }
    } catch (error) {
      showAlert(
        `${t('PREFERRED_VENDOR.ERROR')}!`,
        t('PREFERRED_VENDOR.CF_FETCH_FAILED')
      );
    }

    if (customColumns.length > 0) {
      setColumns([...columns, ...customColumns]);
    }
  };

  const getProductsByVendor = async (config: PreferredVendorAPIConfig) => {
    setUpdating(true);
    try {
      const response = await PreferredVendorService.getProductsByVendor(
        [contact.code],
        config
      );
      processResponseData(response);
    } catch (error) {
      processResponseData({
        content: [],
        pageNumber: 0,
        totalPages: 0
      });

      showAlert(
        `${t('PREFERRED_VENDOR.ERROR')}!`,
        t('PREFERRED_VENDOR.PRODUCTS_FETCH_FAILED')
      );
    }
    setUpdating(false);
  };

  const processResponseData = (responseData: any) => {
    const { content, pageNumber, totalPages } = responseData;

    let rowData = [];
    if (Array.isArray(content)) {
      rowData = getRowData(content);
    }

    setRowData(rowData);
    setTotalPageCount(totalPages);
    setCurrentPage(pageNumber + 1);
  };

  const setFilterDataInStore = (
    config: any,
    SearchTerm?: any,
    queries?: any,
    filterDates?: any
  ) => {
    dispatch(
      productsByVendorFilter({
        config,
        SearchTerm,
        queries,
        filterDates
      })
    );
  };

  const getRowData = (products: any[] = []) => {
    const rowData: any[] = [];
    products.forEach((product) => {
      const customFields: any = {};

      if (Array.isArray(product.customField)) {
        product.customField.forEach((customField: any) => {
          customFields[getCFColumnKey(customField)] = customField.value;
        });
      }

      const row = {
        ...product,
        ...customFields,
        currency: contact.currencyCode,
        [COLUMN.PRODUCT_CODE]: product.productDocSeqCode,
        [COLUMN.PRODUCT_NAME]: product.productName,
        [COLUMN.SUPPLIER_PART_NO]: product.supplierPartNumber,
        [COLUMN.SUPPLIER_PART_NAME]: product.supplierPartName,
        [COLUMN.SUPPLIER_DESCRIPTION]: product.supplierDescription,
        [COLUMN.LAST_PRICE]: product.lastPrice,
        [COLUMN.AVG_PRICE]: product.avgPrice,
        [COLUMN.SUPPLIER_PRICE]: product.supplierPrice,
        [COLUMN.LEAD_TIME]: product.leadTime
      };

      rowData.push(row);
    });

    return rowData;
  };

  const onRowSelect = (data: any) => {
    if (isPopupView && passingInteraction) {
      passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CONFIRM_PREFERRED_VENDOR_PRODUCT,
        data: data
      });
    }
  };

  const onSort = (data: any) => {
    const config: PreferredVendorAPIConfig = {
      ...PreferredVendorService.apiConfig,
      Sort: data.columnData.key,
      SortDir: data.order
    };

    setFilterDataInStore(config);
  };

  return (
    <div className="column parent-width flex-1 parent-height">
      <div className="column parent-width mb-s position-relative flex-1">
        <DataGridHolder
          headerButtons={[]}
          tableName={TABLES.PRODUCTS_BY_VENDOR}
          displayTableName={TABLE_DISPLAY_NAME[TABLES.PRODUCTS_BY_VENDOR]}
          gridIcon="📦"
          needBoldTheme={isDesktop}
          allowRowEdit={false}
          allowColumnEdit={false}
          allowRowAdd={false}
          allowColumnAdd={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowSearch={false}
          allowFilter={false}
          updating={updating}
          filter={[]}
          refresh={true}
          allowDataExport={false}
          gridData={preferredVendors}
          actionColumnWidth={150}
          allowBulkOperation={isPopupView ? true : false}
          width={
            isPopupView
              ? '100%'
              : SideBarService.getContainerWidth() - CONTACT_LEFT_PANEL_WIDTH
          }
          extraWidth={CONTACT_LEFT_PANEL_WIDTH}
          showHeader={!isPopupView}
          showContextMenu={!isPopupView}
          onRowSelect={onRowSelect}
          onSort={(data: any) => onSort(data)}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          onPaginationClick={(requestedPageNumber: number) => {
            const config: PreferredVendorAPIConfig = {
              ...PreferredVendorService.apiConfig,
              Page: requestedPageNumber - 1
            };

            setFilterDataInStore(config);
          }}
        />
      </div>
    </div>
  );
}

export default ProductsByVendor;
