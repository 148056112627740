import { ReactElement } from 'react';
import {
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  PRODUCT_TYPE
} from '../Constants/Constant';
import { FulfillmentItemPayload } from './FulfillmentItem';
import { CallBackPayloadType } from './Interfaces';

export interface FulfillmentState {
  id: any;
  fulfillmentType: string;
  status: boolean;
  documentCode: string;
  documentType: string;
  fulfillmentDate: string;
  warehouseCode: string;
  fulfillmentItems: FulfillmentItemState[];
  isPartialInvoice: boolean;
  linkedPIDocument: any;
}

export interface FulfillmentItemState {
  id: any;
  documentItemCode: string;
  documentSequenceCode: string;
  productCode: string;
  product: any;
  productName: string;
  productType: PRODUCT_TYPE;
  productDescription: string;
  pendingQuantity: number;
  quantityRequired: number;
  fulfilledQuantity: number;
  fulfillmentDate: string;
  availableProductQuantity: number;
  availableQuantity: number;
  warehouseCode: string;
  lineNumber: number;
  linkedPIItemCode: string;
  isPartialInvoice: boolean;
  quantityToBeFulfilled: number;
  productQuantity: number;
  qtyConvertedToDropShip: number;
  qtyFulfilledFromToDropShip: number;
  qtyConvertedToPps: number;
  qtyFulfilledFromPps: number;

  quantityFulfilled: number;
  advancedTracking: string;
  isQuickCommit: boolean;
  unitPrice: number;
  discount: number;
  discountInPercent: boolean;
  advancedTrackingFulfilmentData: Array<any>;
  documentUom: number;
  documentUOMSchemaDefinition: any;
  uomPendingQuantity: number;
  uomQuantityRequired: number;
  uomFulfilledQuantity: number;
  uomAvailableProductQuantity: number;
  uomQuantityToBeFulfilled: number;
  uomQuantity: number;
  uomQuantityFulfilled: number;
  uomUnitPrice: number;
  uomAvailableQuantity: number;
  reservedQuantitiesData: any;
  salesInvoiceItemCode: string;
  type: PRODUCT_TYPE;
}

export const fulfillmentInitialState: FulfillmentState = {
  id: null,
  fulfillmentType: FULFILLMENT_STATUS.UNFULFILLED,
  status: false,
  documentCode: '',
  documentType: '',
  fulfillmentDate: '',
  warehouseCode: '',
  fulfillmentItems: [],
  isPartialInvoice: false,
  linkedPIDocument: null
};

export interface FulfillmentPopupProps {
  documentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType, data?: any) => void;
  documentType: DOC_TYPE;
  fulfillmentType?: FULFILLMENT_TYPE;
  openDraftId?: any;
  closePopup?: () => void;
  closeMenuContainer?: () => void;
  assignSerialTracking?: (data: any) => void;
  assignBatchTracking?: (data: any) => void;
  refreshFulfillmentDetails?: any;
  isFulfillmentForDropship?: boolean;
}

export interface FulfillmentRecordProps {
  documentDetails: any;
  defaultCategory?: string;
  fulfillmentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isDeleted: (val: boolean) => void;
  fulfillmentActiveRecord?: any;
  selectedTabIndex?: number;
  isFromMaster?: boolean;
  isToleranceApprovalFlow?: boolean;
}

export class FulfillmentPayload {
  fulfillmentType: string;
  documentCode: string;
  documentType: string;
  fulfillmentDate: string;
  fulfillmentItems: FulfillmentItemPayload[];
  isPartialInvoice: boolean;
  linkedPIDocument: any;
  reservedStock: boolean;
  customField: any;
  attachments: any;
  attachmentIds: any;
  isQcEnabled: boolean;
  customerOrderNumber: string;
  shipFrom: any;
  shipTo: any;

  constructor(props: any) {
    this.fulfillmentType = props.fulfillmentType;
    this.documentCode = props.documentCode;
    this.documentType = props.documentType;
    this.fulfillmentDate = props.fulfillmentDate;
    this.isPartialInvoice = props.isPartialInvoice;
    this.linkedPIDocument = props.linkedPIDocument || null;
    this.reservedStock = props.reservedStock || null;
    this.customField = props.customField || [];
    this.attachmentIds = props.attachmentIds;
    this.attachments = props.attachments;
    this.isQcEnabled = props.isQcEnabled;
    this.customerOrderNumber = props.customerOrderNumber;
    this.shipFrom = props?.shipFrom;
    this.shipTo = props?.shipTo;
    if (props.fulfillmentItems) {
      this.fulfillmentItems = props.fulfillmentItems.map((item: any) => {
        return new FulfillmentItemPayload({
          ...item,
          fulfillmentType: props.fulfillmentType,
          warehouseCode: item.warehouseCode || props.warehouseCode,
          fulfillmentDate: item.fulfillmentDate || this.fulfillmentDate
        });
      });
    } else {
      this.fulfillmentItems = [];
    }
  }
}
