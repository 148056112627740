import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { IWhatsAppRecord } from '../../Models/WhatsAppAPI';
import WhatsAppAPIService from '../../Services/WhatsAppAPI';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchConnections = createAsyncThunk(
  'fetchConnections',
  async () => {
    const response = await WhatsAppAPIService.getConnections();
    return response;
  }
);

export const addConnection = createAsyncThunk(
  'addConnection',
  async (payload: IWhatsAppRecord) => {
    const response = await WhatsAppAPIService.addConnection(payload);
    return response;
  }
);

export const deleteConnection = createAsyncThunk(
  'deleteConnection',
  async (id: number) => {
    await WhatsAppAPIService.deleteConnection(id);
    return id;
  }
);

export const WhatsAppAPISlice = createSlice({
  name: 'whatsappApi',
  initialState,
  reducers: {
    addConnections: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnections.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(addConnection.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(addConnection.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = {
          ...state.data,
          data: [...(state.data.data || []), action.payload]
        };
      })
      .addCase(deleteConnection.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(deleteConnection.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = {
          ...state.data,
          data: state.data.data?.filter(
            (item: IWhatsAppRecord) => item.id !== action.payload
          )
        };
      });
  }
});

export const { addConnections } = WhatsAppAPISlice.actions;
export const selectWhatsAppAPIConnections = (state: RootState) =>
  state.whatsappApi.data?.data || [];
