import React, { useEffect, useState } from 'react';
import { TOGGLE_SIZE } from '../../Constants/Enum';
import './Toggle.scss';

interface ToggleProps {
  isDisabled?: boolean;
  value?: boolean;
  onChange?: (checked: boolean) => void;
  toggleSize?: TOGGLE_SIZE;
}

interface ToggleState {
  isDisabled: boolean;
  isActive: boolean;
  switchClass: string;
  sliderClass: string;
}
export const Toggle: React.FC<ToggleProps> = (props) => {
  const initialState: ToggleState = {
    isDisabled: props.isDisabled ? props.isDisabled : false,
    isActive: props.value ? props.value : false,
    switchClass: 'switch',
    sliderClass: 'slider'
  };
  const [toggleState, setToggleState] = useState<ToggleState>(initialState);

  useEffect(() => {
    const updatedState = toggleState;
    switch (props.toggleSize) {
      case TOGGLE_SIZE.LARGE:
        updatedState.switchClass = 'switch';
        updatedState.sliderClass = 'slider';
        break;
      case TOGGLE_SIZE.SMALL:
        updatedState.switchClass = 'switch-sm';
        updatedState.sliderClass = 'slider-sm';
        break;
      default:
        updatedState.switchClass = 'switch';
        updatedState.sliderClass = 'slider';
        break;
    }
    updatedState.isDisabled = props.isDisabled ? props.isDisabled : false;
    updatedState.isActive = props.value ? props.value : false;
    setToggleState({ ...updatedState });
    return () => {
      //   cleanup
    };
  }, [props.isDisabled, props.value]);

  const toggleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedState = toggleState;
    const toggleValue = event.target.checked;
    if (toggleValue) {
      updatedState.isActive = true;
    } else {
      updatedState.isActive = false;
    }
    if (props.onChange) {
      props.onChange(toggleValue);
    }
    setToggleState({ ...updatedState });
  };
  return (
    <label className={toggleState.switchClass}>
      <input
        type="checkbox"
        onChange={(e) => toggleChanged(e)}
        checked={toggleState.isActive}
        disabled={toggleState.isDisabled}
      />
      <span
        className={`${toggleState.sliderClass} round`}
        style={{
          cursor: toggleState.isDisabled === true ? 'initial' : 'pointer'
        }}
      ></span>
    </label>
  );
};
