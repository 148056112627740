
export enum BOOKS_DATE_FORMAT {
  'D MMM YYYY' = 'D MMM YYYY',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DATE_FORMAT_MEDIUM' = 'DD-MM-YYYY HH:mm:ss',
  'ISO-FORMAT' = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z",
  'HH:MM A' = 'hh:mm a',
  'ISO-8601' = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
}

export const EmailComposerFieldType = {
  RECIPIENTS: "recipients",
  SUBJECT: "subject",
  MESSAGE: "message",
  REPLY_TO: "reply_to",
  SENDER: "sender",
};

export const watermarkType = {
  original: "original",
  duplicate_service: "duplicate_service",
  duplicate_goods: "duplicate_goods",
  triplicate: "triplicate",
  quadruplicate: "quadruplicate",
  custom: "custom",
};

export const watermarkPrintType = {
  none: "none",
  original: "original",
  duplicate: "duplicate",
  triplicate: "triplicate",
  quadruplicate: "quadruplicate",
  custom: "custom",
  all: "all",
};

export const watermarkPosition = {
  topLeft: "topLeft",
  topRight: "topRight",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",
  bottomMiddle: "bottomMiddle",
  top: "top",
  bottom: "bottom",
};

export const AlertType = {
  standard: "STANDARD",
  user_input: "USER_INPUT",
};


export const PageCountFormattingType = {
  singleDigit: "singleDigit", //1,2,3...
  pageWithNumeric: "pageWithNumeric", //page 1, page 2, page 3...
  pageOfTotalPage: "pageOfTotalPage", //page 1 of 3, page 1 of 2 ...
  pageOfTotalPageSlash: "pageOfTotalPageSlash", //page 1/3, page 2/3 ....
};

export const PageCountPosition = {
  topLeft: "topLeft",
  topRight: "topRight",
  topMiddle: "topMiddle",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",
  bottomMiddle: "bottomMiddle",
  top: "top",
  bottom: "bottom",
};
