import { DKButton, DKLabel, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import { useState } from 'react';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';

export default function BomSelector(props: any) {
  const [selectedBom, setSelectedBom] = useState<any>(
    props.selectedProduct?.bomMetaDetailsList?.find(
      (item: any) => item.isDefault
    )
  );
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text="Select BOM Config" className="fw-m fs-l" />
        </div>
        <div className="row width-auto gap-2">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Create'}
            className="bg-button text-white"
            onClick={() => {
              props.onSelect(selectedBom);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 450,
            width: '90%',
            maxHeight: '95%',
            padding: 0,
            overflowY: 'visible'
          }}
        >
          {getHeader()}
          <div className="row p-4">
            <DKInput
              value={selectedBom}
              title={'BOM Config'}
              className={''}
              formatter={(obj: any) => {
                return obj.name;
              }}
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              onChange={(value: any) => {
                setSelectedBom(value);
              }}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: true,
                searchableKey: 'name',
                data: props.selectedProduct?.bomMetaDetailsList,
                renderer: (index: any, obj: any) => {
                  return (
                    <div className="flex flex-row w-full justify-content-between">
                      <DKLabel
                        style={{ fontSize: '13px' }}
                        className="text-base border-radius-s"
                        text={obj.name}
                      />
                    </div>
                  );
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
