import { DKLabel, DKButton, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import { useState } from 'react';
export default function TaxGroupDistribution(props: any) {
  const [taxList, settaxList] = useState<any>(props.taxList);
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Edit Tax Amount" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Apply'}
            className="bg-button text-white"
            onClick={() => {
              props.onApply(taxList);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="transparent-background"
      style={{
        backgroundColor: 'transparent'
      }}
    >
      <div
        className="popup-window shadow border-m"
        style={{
          maxWidth: 450,
          width: '90%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 10,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-4 gap-2">
          <div className="row justify-content-between border-b pb-2">
            <div className="row justify-content-start">
              <DKLabel className="fw-m ml-4" text="TAX NAME" />
            </div>
            <div className="row justify-content-end">
              <DKLabel className="fw-m" text="Amount" />
            </div>
          </div>
          <div className="column parent-width justify-content-between gap-2 mt-2">
            {taxList?.map((tax: any, index: number) => {
              return (
                <div className="row justify-content-between align-items-center">
                  <DKLabel className="fw-m fs-m" text={`${index + 1}.`} />
                  <DKInput
                    type={INPUT_TYPE.NUMBER}
                    className="ml-2"
                    title={tax.taxName ?? tax.name ?? ''}
                    value={tax.taxAmount}
                    textAlign={'right'}
                    onChange={(text: number) => {
                      let taxListCopy = [...taxList];
                      let itemToEdit = { ...taxListCopy[index] };
                      itemToEdit.taxAmount = text;
                      taxListCopy[index] = itemToEdit;
                      settaxList(taxListCopy);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
