import {
  COLORS as COLORS_LIB,
  DKLabel,
  PieChart,
  DKSpinner
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { DATE_FORMAT, DAY_INTERVAlS, ERROR } from '../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  getAgedReceivable,
  selectAgedReceivable,
  setAgedReceivableFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import DashboardService, { DashboardAPIConfig } from '../../Services/Dashboard';
import Utility from '../../Utility/Utility';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';

function AgedReceivable(props: any) {
  const dispatch = useAppDispatch();
  const agedReceivableData = useAppSelector(selectAgedReceivable);
  const [parsedData, setParsedData] = useState([]);
  const [noData, setNoData] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);

  const [agedReceivableChartData, setAgedReceivableChartData] = useState<any>([
    {
      title: 'Mon',
      value: 200.25,
      color: COLORS_LIB.chart.blue
    },
    {
      title: 'Tue',
      value: 0,
      color: COLORS_LIB.chart.blue2
    },
    {
      title: 'thu',
      value: 0,
      color: COLORS_LIB.chart.lightBlue
    },
    {
      title: 'fri',
      value: 0,
      color: COLORS_LIB.chart.blue2
    },
    {
      title: 'sat',
      value: 60.2,
      color: COLORS_LIB.chart.lightBlue
    }
  ]);

  const getIntervalValue = (interval: any, data: any) => {
    switch (interval) {
      case DAY_INTERVAlS[0]:
        return data.current;
      case DAY_INTERVAlS[1]:
        return data.lessThanOneMonth;
      case DAY_INTERVAlS[2]:
        return data.lessThanTwoMonth;
      case DAY_INTERVAlS[3]:
        return data.lessThanThreeMonth;
      case DAY_INTERVAlS[4]:
        return data.older;
      default:
        return 0;
    }
  };

  const ageReceivableParser = (data: any) => {
    let parsedData = [
      {
        title: 'Current',
        value: data.current,
        color: COLORS_LIB.chart.blue
      },
      {
        title: '1-30 days',
        value: data.lessThanOneMonth,
        color: COLORS_LIB.chart.blue2
      },
      {
        title: '31-60 days',
        value: data.lessThanTwoMonth,
        color: COLORS_LIB.chart.purple
      },
      {
        title: '61-90 days',
        value: data.lessThanThreeMonth,
        color: COLORS_LIB.chart.green
      },
      {
        title: 'Over 90 days',
        value: data.older,
        color: COLORS_LIB.chart.yellow
      }
    ];

    return parsedData;
  };

  useEffect(() => {
    if (!Utility.isEmpty(agedReceivableData)) {
      if (agedReceivableData === ERROR) {
        setNoData(false);
        setNoDataFound(true);
        return;
      }

      if (
        !GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.REPORTS.VIEW
        )
      ) {
        setNoData(false);
        setNoDataFound(true);
        return;
      }

      let currentData = ageReceivableParser(agedReceivableData);

      if (Utility.isEmpty(currentData)) {
        setNoData(false);
        setNoDataFound(true);
      } else {
        setNoData(false);
        setNoDataFound(false);
        setAgedReceivableChartData(currentData);
      }
    } else {
      setNoDataFound(true);
    }
  }, [agedReceivableData]);

  const getAgeReceivable = async (config: DashboardAPIConfig) => {
    try {
      var date = new Date();
      config = {
        type: 'RECEIVABLE',
        fromDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY)
      };
      DashboardService.apiConfig = config;
      dispatch(setAgedReceivableFilterConfig(config));
      const data = await dispatch(getAgedReceivable());
      if (data) {
        setNoData(false);
      }
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  useEffect(() => {
    setNoData(true);
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.REPORTS.VIEW
      )
    ) {
      getAgeReceivable({});
    }
  }, []);

  return (
    <div
      className="border-radius-m shadow-s border-ms p-xl bg-white border-box column align-items-center db-grid-width-50 db-grid-min-width"
      style={{ height: 265 }}
    >
      <div className="row justify-content-between">
        <DKLabel text={'Aged Receivable'} className="fw-m fs-m" />
      </div>
      <div
        className="column position-relative parent-width overflow-y-scroll hide-scroll-bar"
        style={{ height: 210 }}
      >
        {noData && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">
              <DKSpinner iconClassName="ic-s" />
            </div>
          </div>
        )}
        {noDataFound && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">No Data</div>
          </div>
        )}
        {/* <BarGraph data={agedReceivableChartData} height={160} /> */}
        <div className="align-self-center mt-l" style={{ width: '85%' }}>
          {agedReceivableChartData && agedReceivableChartData.length > 0 && (
            <PieChart
              chartConfig={{
                arcWidth: 0.7,
                size: 180
              }}
              showLegend={true}
              dataSet={agedReceivableChartData}
              displayValueFormatter={(value: any) => {
                return (
                  Utility.getTenantCurrencySymbol() +
                  Utility.shortFormatNumberWithSuffix(value)
                );
              }}
              tooltipValueFormatter={(value: any) => {
                return (
                  Utility.getTenantCurrencySymbol() +
                  Utility.shortFormatNumberWithSuffix(value)
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AgedReceivable;
