import { useEffect } from 'react';
import { showLoader, removeLoader } from 'deskera-ui-library';
import { MODULE_TYPE } from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import BatchTrackingAssignment from '../../../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import SerialTrackedAssignment from '../../../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import NoneTrackedAssignment from '../../../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import SubstitutePopUp from '../../SubstitutePopup';
import RawMaterialHelper from './RawMaterialHelper';
import { useAppDispatch } from '../../../../../Redux/Hooks';
import { fetchBatchTrackingProducts } from '../../../../../Redux/Slices/BatchTrackingSlice';
import { fetchProductInventoryByID } from '../../../../../Redux/Slices/WarehouseProductSlice';
import { fetchSerialTrackingProducts } from '../../../../../Redux/Slices/SerialTrackingSlice';
import { ProductSubstitutePopup } from '../../../BOM_Assembly/ProductSubstitutePopup';
import { BOM_EXPLOSION_COLUMN_KEYS } from '../../../Constants/MRPColumnConfigs';
import ViewAdvancedTrackingDetails from '../../../../Common/ViewAdvancedTrackingDetails';

interface IGetAssignmentPopupForTargetWarehouseWOProps {
  isEditMode: boolean;
  workOrderProductId: string;
  existingItems: any[];
  selectedProductRowForTracking: any;
  activeWOtargetWarehouse: any;
  needAvailableSubstituteListPopup: boolean;
  needSubstituteAllocation: boolean;
  showAllocatedTrackingDetailsView?: boolean;
  closeAllocatedTrackingDetailView?: () => any;
  trackingItemParser: (productRowData: any, type: ADVANCE_TRACKING) => any;
  onAssignmentSave?: (type: ADVANCE_TRACKING | 'SUBSTITUTE', data: any) => void;
  onAvailableSubstituteListSave: (selectedSubstitutes: any[]) => void;
  onBatchSave?: (data: any) => void;
  onNormalProductSave?: (data: any) => void;
  onSubstituteSave?: (data: any) => void;
  onClose: () => void;
}

export default function GetAssignmentPopupForTargetWarehouseWO(
  props: IGetAssignmentPopupForTargetWarehouseWOProps
) {
  const dispatch = useAppDispatch();
  const selectedRowForTracking = props.selectedProductRowForTracking;
  const selectedTrackingType =
    selectedRowForTracking?.advancedTracking ||
    selectedRowForTracking?.itemName?.advancedTracking;
  const getExistingDataForSelectedProduct = () =>
    props.existingItems?.find(
      (item: any) =>
        item?.itemName?.pid ===
        (selectedRowForTracking?.itemName?.pid || selectedRowForTracking?.pid)
    );

  useEffect(() => {
    if (!selectedRowForTracking?.pid || props?.showAllocatedTrackingDetailsView)
      return;
    try {
      switch (selectedTrackingType) {
        case ADVANCE_TRACKING.BATCH:
          showLoader('Fetching inventory info for product..');
          dispatch(
            fetchBatchTrackingProducts({
              productCode: selectedRowForTracking.pid,
              enableQCWarehouse: false
            })
          ).finally(() => removeLoader());
          break;
        case ADVANCE_TRACKING.SERIAL:
          dispatch(
            fetchSerialTrackingProducts({
              productCode: selectedRowForTracking.pid,
              enableQCWarehouse: false
            })
          );
          break;
        case ADVANCE_TRACKING.NONE:
          dispatch(fetchProductInventoryByID([selectedRowForTracking.pid]));
          break;
        default:
      }
    } catch (err) {}
  }, [selectedTrackingType, selectedRowForTracking, dispatch]);

  const mergeAvailableAndPickedSubstituteDetailsForAllocation = () => {
    const allSubstituteDetails =
      selectedRowForTracking?.bomProductSubstitutesDetails || [];
    const pickedSubstituteDetailById: { [key: string]: any } = {};
    (selectedRowForTracking?.productSubstitutesDetails || []).forEach(
      (pickedSubstitute: any) => {
        pickedSubstituteDetailById[pickedSubstitute?.productId] =
          pickedSubstitute;
      }
    );

    return allSubstituteDetails
      .map((availableSubstitute: any) => ({
        ...availableSubstitute,
        ...(pickedSubstituteDetailById[availableSubstitute?.productId] || {})
      }))
      .map((substitute: any) => ({
        ...substitute,
        documentSequenceCode:
          substitute.documentSequenceCode ||
          substitute.productDocSeqCode ||
          substitute.productDocumentSeqCode
      }));
  };

  const needTrackingPopup =
    !props.needAvailableSubstituteListPopup &&
    !props.needSubstituteAllocation &&
    !props?.showAllocatedTrackingDetailsView &&
    selectedTrackingType;

  const noneTrackedDetails = props.trackingItemParser(
    selectedRowForTracking,
    ADVANCE_TRACKING.NONE
  );
  const batchTrackedDetails = props.trackingItemParser(
    selectedRowForTracking,
    ADVANCE_TRACKING.BATCH
  );
  const serialTrackedDetails = props.trackingItemParser(
    selectedRowForTracking,
    ADVANCE_TRACKING.SERIAL
  );

  const getAllocatedAdvancedTrackingDetailsView = () => {
    let trackingPopupDetails = null;

    if (selectedTrackingType === ADVANCE_TRACKING.NONE) {
      trackingPopupDetails = noneTrackedDetails;
    } else if (selectedTrackingType === ADVANCE_TRACKING.BATCH) {
      trackingPopupDetails = batchTrackedDetails;
    } else if (selectedTrackingType === ADVANCE_TRACKING.SERIAL) {
      trackingPopupDetails = serialTrackedDetails;
    }

    return (
      <ViewAdvancedTrackingDetails
        trackingPopupDetails={trackingPopupDetails}
        onCancel={() => {
          if (props.closeAllocatedTrackingDetailView) {
            props.closeAllocatedTrackingDetailView();
          }
        }}
      />
    );
  };

  return selectedRowForTracking ? (
    <>
      {needTrackingPopup &&
        selectedTrackingType === ADVANCE_TRACKING.SERIAL && (
          <SerialTrackedAssignment
            isMRP={true}
            mrpTitle="Material"
            itemDetails={{
              ...serialTrackedDetails,
              productQuantity: serialTrackedDetails?.productQuantity
            }}
            module={MODULE_TYPE.SELL}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.activeWOtargetWarehouse
                : null
            }
            existingWarehouseInventoryData={deepClone(
              getExistingDataForSelectedProduct()?.warehouseInventoryData || []
            )}
            defaultProductWarehouse={selectedRowForTracking?.warehouseCode}
            onSerialSave={(data: any) => {
              props.onAssignmentSave?.(ADVANCE_TRACKING.SERIAL, data);
            }}
            onClose={() => props.onClose()}
          />
        )}
      {needTrackingPopup && selectedTrackingType === ADVANCE_TRACKING.BATCH && (
        <BatchTrackingAssignment
          isMrpFlow={true}
          mrpTitle="Material"
          itemDetails={{
            ...batchTrackedDetails,
            quantityRequired: Utility.getUomWarehouseQuantityWithoutRoundOff(
              batchTrackedDetails?.quantityRequired ??
                batchTrackedDetails?.requiredQuantity,
              batchTrackedDetails?.documentUOMSchemaDefinition
            ),
            advancedTrackingFulfilmentData:
              batchTrackedDetails?.advancedTrackingFulfilmentData?.map(
                (itemBatchTrack: any) => {
                  return {
                    ...itemBatchTrack,
                    qtyToFulfil: Utility.getUomWarehouseQuantityWithoutRoundOff(
                      itemBatchTrack?.qtyToFulfil,
                      batchTrackedDetails?.documentUOMSchemaDefinition
                    ),
                    advancedTrackingData:
                      itemBatchTrack?.advancedTrackingData?.map(
                        (batchAdvanceTrack: any) => {
                          return {
                            ...batchAdvanceTrack,
                            qtyToFulfil:
                              Utility.getUomWarehouseQuantityWithoutRoundOff(
                                batchAdvanceTrack?.qtyToFulfil,
                                batchTrackedDetails?.documentUOMSchemaDefinition
                              )
                          };
                        }
                      )
                  };
                }
              )
          }}
          module={MODULE_TYPE.SELL}
          existingWarehouseInventoryData={deepClone(
            getExistingDataForSelectedProduct()?.warehouseInventoryData || []
          )}
          defaultProductWarehouse={selectedRowForTracking?.warehouseCode}
          taggedWarehouse={
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? props.activeWOtargetWarehouse
              : null
          }
          onBatchSave={(data: any) => {
            let updatedData = data?.map((itemData: any) => {
              return {
                ...itemData,
                qtyToFulfil: Utility.getUomQuantity(
                  itemData?.qtyToFulfil,
                  batchTrackedDetails?.documentUOMSchemaDefinition
                ),
                advancedTrackingData: itemData?.advancedTrackingData?.map(
                  (itemTrackingData: any) => {
                    return {
                      ...itemTrackingData,
                      qtyToFulfil: Utility.getUomQuantity(
                        itemTrackingData?.qtyToFulfil,
                        batchTrackedDetails?.documentUOMSchemaDefinition
                      )
                    };
                  }
                )
              };
            });
            props.onAssignmentSave?.(ADVANCE_TRACKING.BATCH, updatedData);
          }}
          onClose={() => props.onClose()}
        ></BatchTrackingAssignment>
      )}
      {needTrackingPopup && selectedTrackingType === ADVANCE_TRACKING.NONE && (
        <NoneTrackedAssignment
          isEditMode={props.isEditMode}
          details={{
            ...noneTrackedDetails,
            pendingQuantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
              noneTrackedDetails?.pendingQuantity,
              noneTrackedDetails?.documentUOMSchemaDefinition
            )
          }}
          mrpTitle="Material"
          module={MODULE_TYPE.SELL}
          taggedWarehouse={
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
              ? props.activeWOtargetWarehouse
              : null
          }
          defaultProductWarehouse={RawMaterialHelper.getDefaultActiveWarehouse()}
          existingWarehouseInventoryData={deepClone(
            getExistingDataForSelectedProduct()?.warehouseInventoryData || []
          )}
          onSave={(data: any) => {
            if (data && data.length > 0) {
              props.onAssignmentSave?.(ADVANCE_TRACKING.NONE, data);
            } else {
              props.onClose();
            }
          }}
          onCancel={() => props.onClose()}
        ></NoneTrackedAssignment>
      )}
      {props.needAvailableSubstituteListPopup && (
        <ProductSubstitutePopup
          product={selectedRowForTracking}
          selectedProductId={
            selectedRowForTracking?.itemName
              ? selectedRowForTracking.itemName.productId ??
                selectedRowForTracking.itemName.pid
              : selectedRowForTracking?.pid
          }
          selectedProductsIds={mergeAvailableAndPickedSubstituteDetailsForAllocation()}
          excludedProductIds={[props.workOrderProductId]}
          onClose={() => props.onClose()}
          onSave={(data: any[]) => props.onAvailableSubstituteListSave(data)}
          restrictClick={false}
          currentConfiguration={[]}
        />
      )}
      {props.needSubstituteAllocation && (
        <SubstitutePopUp
          workOrderItem={
            selectedRowForTracking?.itemName
              ? {
                  ...selectedRowForTracking,
                  bomProductSubstitutesDetails:
                    mergeAvailableAndPickedSubstituteDetailsForAllocation()
                }
              : {
                  itemName: {
                    ...selectedRowForTracking,
                    name: selectedRowForTracking?.productName,
                    documentSequenceCode: selectedRowForTracking?.productCode
                  },
                  bomProductSubstitutesDetails:
                    mergeAvailableAndPickedSubstituteDetailsForAllocation(),
                  requiredQty:
                    selectedRowForTracking?.[
                      BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY
                    ],
                  warehouseInventoryData:
                    selectedRowForTracking?.warehouseInventoryData
                }
          }
          existingWorkOrderItem={props.existingItems?.find((itemRow) => {
            return (
              itemRow.itemName.documentSequenceCode ===
              selectedRowForTracking?.productCode
            );
          })}
          targetWarehouse={props.activeWOtargetWarehouse} // Prop use only in case of Tagged WH feature
          onCancel={() => props.onClose()}
          onSuccess={(data: any) =>
            props.onAssignmentSave?.('SUBSTITUTE', data)
          }
          isEditMode={props.isEditMode}
        />
      )}
      {props?.showAllocatedTrackingDetailsView &&
        getAllocatedAdvancedTrackingDetailsView()}
    </>
  ) : null;
}
