import { TableColumnPopupType } from "../../../Constants/Constants"
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeader } from "../TableUtility";

export class QCDocumentTableItem {
    lineNumber: string;
    name: string;
    value: string;
    rawItemValue: any;

    constructor(qcDocumentItem: any) {
        this.lineNumber = qcDocumentItem.lineNumber ? qcDocumentItem.lineNumber : ''
        this.name = qcDocumentItem.name ? qcDocumentItem.name : ''
        this.value = qcDocumentItem.value ? qcDocumentItem.value : ''
        this.rawItemValue = qcDocumentItem
    }


    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return getLocalisedText(this.lineNumber)
            case TableColumnPopupType.parameterName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.parameterValue:
                return getLocalisedText(this.value)
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return ''
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.lineNumber),
            getLocalisedText(this.name),
            getLocalisedText(this.value)
        ]
    }
}
