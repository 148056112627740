export const enum RETURN_WAREHOUSE_MODULES {
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  SALES_RETURN = 'SALES_RETURN',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  STOCK_TRANSFER_SOURCE_WAREHOUSE = 'STOCK_TRANSFER_SOURCE_WAREHOUSE',
  STOCK_TRANSFER_TARGET_WAREHOUSE = 'STOCK_TRANSFER_TARGET_WAREHOUSE'
}

export const enum FORM_FIELD_NAME {
  WAREHOUSE = 'WAREHOUSE',
  ROW = 'ROW',
  RACK = 'RACK',
  BIN = 'BIN'
}

export interface formState {
  lastChangeKey: string;
  warehouse: {
    data: any[];
    nonFilteredData: any[];
    value: any;
    errorMessage: string;
  };
  rows: {
    data: any[];
    nonFilteredData: any[];
    value: any;
    errorMessage: string;
  };
  racks: {
    data: any[];
    nonFilteredData: any[];
    value: any;
    errorMessage: string;
  };
  bins: {
    data: any[];
    nonFilteredData: any[];
    value: any;
    errorMessage: string;
  };
}

export const initialFormState: formState = {
  lastChangeKey: '',
  warehouse: {
    data: [],
    nonFilteredData: [],
    value: {},
    errorMessage: ''
  },
  rows: {
    data: [],
    nonFilteredData: [],
    value: {},
    errorMessage: ''
  },
  racks: {
    data: [],
    nonFilteredData: [],
    value: {},
    errorMessage: ''
  },
  bins: {
    data: [],
    nonFilteredData: [],
    value: {},
    errorMessage: ''
  }
};
