import saveAs from 'file-saver';
import ApiConstants from '../Constants/ApiConstants';
import { ERROR } from '../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface QualityCheckAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
}

export const defaultConfig: QualityCheckAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  QuerySummary: ''
};

class QualityCheckService {
  static apiConfig: QualityCheckAPIConfig;

  static abortController: any = null;

  static getQualityChecksByPage() {
    Utility.cancelRequest(QualityCheckService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.QUALITY_CHECK
    );

    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&query=${
      !Utility.isEmpty(this.apiConfig.Query) ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.QUALITY_CHECK.FETCH_QUALITY_CHECK +
      country +
      queryString;

    QualityCheckService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: QualityCheckService.abortController.signal
      })
      .then(
        (response: any) => {
          return !Utility.cancelRequestMessage(response)
            ? Promise.resolve(response)
            : {};
        },
        (error: any) => {
          return !Utility.cancelRequestMessage(error)
            ? Promise.reject(ERROR)
            : {};
        }
      )
      .catch((error: any) => {
        return !Utility.cancelRequestMessage(error)
          ? Promise.reject(ERROR)
          : {};
      });
  }

  static addQCDetails(payload: any) {
    const finalEndPoint = ApiConstants.URL.QUALITY_CHECK.ADD_QC_DETAILS;

    return http
      .post(finalEndPoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAdvanceTrackingRecord(code: any) {
    return http2.get(
      `${ApiConstants.URL.RETURNS.GET_ADVANCE_RECORD}?limit=100&page=0&sort=createdAt&transactionRefCode=${code}`
    );
  }

  static getQCTransactionById(id: any) {
    return http
      .get(`${ApiConstants.URL.QUALITY_CHECK.GET_QC_DETAILS_BY_ID(id)}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTemplateUsageByProduct(codes: any) {
    return http
      .post(`${ApiConstants.URL.MRP.QUALITY_CHECK.FETCH_TEMPLATE_USAGE}`, codes)
      .catch((err: any) => {
        console.error('Error fetching template usage by product', err);
      });
  }

  static updateQCStatus(
    type: any,
    transactionCode: any,
    transactionItemCode: any
  ) {
    let finalURL = '';
    if (type === 'WORK_ORDER') {
      finalURL =
        `${ApiConstants.URL.MRP.QUALITY_CHECK.UPDATE_QC_STATUS_NORMAL_WO}` +
        `?workOrderDocSeqCode=${transactionCode}`;
    } else {
      finalURL =
        ApiConstants.URL.MRP.QUALITY_CHECK.UPDATE_QC_STATUS_WIP_PRODUCTION(
          transactionCode
        );
    }

    var raw = transactionItemCode;
    return fetch(`${ApiConstants.URL.BASE}${finalURL}`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: raw
    })
      .then((response) => response.json())
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject('');
      });
  }

  static fetchQCSummary = (params: any): Promise<any> => {
    let endpoint: any =
      ApiConstants.URL.MRP.QUALITY_CHECK.QC_SUMMARY +
      `?search=${params.search}&page=${params.page}&limit=${params.limit}&sort=qcNumber`;
    if (!Utility.isEmpty(params.custom_field)) {
      endpoint =
        ApiConstants.URL.MRP.QUALITY_CHECK.QC_SUMMARY +
        `?search=${params.search}&page=${params.page}&limit=${
          params.limit
        }&sort=qcNumber&custom_field=${encodeURI(params.custom_field)}`;
    }
    return http.get(endpoint);
  };
  static fetchQCDetails = (params: any): Promise<any> => {
    let endpoint: any =
      ApiConstants.URL.MRP.QUALITY_CHECK.QC_DETAILS +
      `?search=${params.search}&page=${params.page}&limit=${params.limit}&sort=qcNumber`;
    if (!Utility.isEmpty(params.custom_field)) {
      endpoint =
        ApiConstants.URL.MRP.QUALITY_CHECK.QC_DETAILS +
        `?search=${params.search}&page=${params.page}&limit=${
          params.limit
        }&sort=qcNumber&custom_field=${encodeURI(params.custom_field)}`;
    }
    return http.get(endpoint);
  };
  static fetchproductwiseQC = (params: any): Promise<any> => {
    let endpoint: any =
      ApiConstants.URL.MRP.QUALITY_CHECK.PRODUCT_WISE_QC +
      `?search=${params.search}&page=${params.page}&limit=${params.limit}`;
    if (!Utility.isEmpty(params.custom_field)) {
      endpoint =
        ApiConstants.URL.MRP.QUALITY_CHECK.PRODUCT_WISE_QC +
        `?search=${params.search}&page=${params.page}&limit=${
          params.limit
        }&custom_field=${encodeURI(params.custom_field)}`;
    }
    return http.get(endpoint);
  };
  static fetchVendorwiseQC = (params: any): Promise<any> => {
    let endpoint: any =
      ApiConstants.URL.MRP.QUALITY_CHECK.VENDOR_WISE_QC +
      `?search=${params.search}&page=${params.page}&limit=${params.limit}`;
    if (!Utility.isEmpty(params.custom_field)) {
      endpoint =
        ApiConstants.URL.MRP.QUALITY_CHECK.VENDOR_WISE_QC +
        `?search=${params.search}&page=${params.page}&limit=${
          params.limit
        }&custom_field=${encodeURI(params.custom_field)}`;
    }
    return http.get(endpoint);
  };

  static exportQCSummary(request: any) {
    let url =
      ApiConstants.URL.MRP.QUALITY_CHECK.QC_SUMMARY_EXPORT +
      `?format=XLS&sort=qcNumber`;
    const fileName = `QC_Summary.xlsx`;

    if (!Utility.isEmpty(request.custom_field)) {
      url = url + `&custom_field=${encodeURI(request.custom_field)}`;
    }
    if (!Utility.isEmpty(request.search)) {
      url = url + `&search=${request.search}`;
    }
    return http
      .get(`${url}`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        saveAs(response, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static exportQCDetails(request: any) {
    let url =
      ApiConstants.URL.MRP.QUALITY_CHECK.QC_DETAILS_EXPORT +
      `?format=XLS&sort=qcNumber`;
    const fileName = `QC_Details.xlsx`;

    if (!Utility.isEmpty(request.custom_field)) {
      url = url + `&custom_field=${encodeURI(request.custom_field)}`;
    }
    if (!Utility.isEmpty(request.search)) {
      url = url + `&search=${request.search}`;
    }
    return http
      .get(`${url}`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        saveAs(response, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static exportProductWiseQC(request: any) {
    let url =
      ApiConstants.URL.MRP.QUALITY_CHECK.PRODUCT_WISE_QC_EXPORT + `?format=XLS`;
    const fileName = `ProductWise_QC.xlsx`;
    if (!Utility.isEmpty(request.custom_field)) {
      url = url + `&custom_field=${encodeURI(request.custom_field)}`;
    }
    if (!Utility.isEmpty(request.search)) {
      url = url + `&search=${request.search}`;
    }
    return http
      .get(`${url}`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        saveAs(response, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static exportVendorWiseQC(request: any) {
    let url =
      ApiConstants.URL.MRP.QUALITY_CHECK.VENDOR_WISE_QC_EXPORT + `?format=XLS`;
    const fileName = `VendorWise_QC.xlsx`;
    if (!Utility.isEmpty(request.custom_field)) {
      url = url + `&custom_field=${encodeURI(request.custom_field)}`;
    }
    if (!Utility.isEmpty(request.search)) {
      url = url + `&search=${request.search}`;
    }
    return http
      .get(`${url}`, {
        responseType: 'blob'
      })
      .then((response: any) => {
        saveAs(response, fileName);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default QualityCheckService;
