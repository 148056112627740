import {
    DKButton,
    DKLabel,
    INPUT_TYPE,
    DKDataGrid
  } from 'deskera-ui-library';
  import { useEffect, useRef, useState } from 'react';
  import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
  import Utility, {
    convertBooksDateFormatToUILibraryFormat,
    deepClone
  } from '../../../Utility/Utility';
  
  export default function StockTransferDetailPopup(props: any) {
    const dispatch = useAppDispatch();
    const [detailState, setDetailState] = useState<any[]>([]);
    const [isBatch, setIsBatch] = useState(false);
    const batchConfig = [
      {
        name: 'Source Batch Number',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'sourceSerialBatchNumber',
        id: 'sourceSerialBatchNumber',
        datasource: [],
        key: 'sourceSerialBatchNumber',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 270
      },
      {
        name: 'Destination Batch Number',
        index: 1,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'destSerialBatchNumber',
        id: 'destSerialBatchNumber',
        datasource: [],
        key: 'destSerialBatchNumber',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 270
      },
      {
        name: 'Quantity Transfered',
        index: 2,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'qtyToTransfer',
        id: 'qtyToTransfer',
        datasource: [],
        key: 'qtyToTransfer',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 270
      }
    ];
    const serialConfig = [
      {
        name: 'Serial Number',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'sourceSerialBatchNumber',
        id: 'sourceSerialBatchNumber',
        datasource: [],
        key: 'sourceSerialBatchNumber',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 800
      }
    ];
    const normalConfig = [
      {
        name: 'Quantity',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'quantity',
        id: 'quantity',
        datasource: [],
        key: 'quantity',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 800
      }
    ];
    useEffect(() => {
      if (props.detail && props.detail.length > 0) {
        let x = deepClone(props.detail);
        if (props.isBatch) {
          setIsBatch(true);
        }
        setDetailState(x);
      }
      if (!Utility.isEmpty(props.normalDetail))   {
          let x = deepClone(props.normalDetail)
          setDetailState([x])
      }
    }, [props.detail]);
  
    const getColumnConfig = () => {
      if (props.isNormal)   {
          return normalConfig
      }   else    {
          return isBatch ? batchConfig : serialConfig;
      }
    }
  
    const getLineItemGrid = () => {
      return (
        <>
          <DKDataGrid
            width={isBatch ? 763 : 450}
            needShadow={false}
            needBorder={true}
            needColumnIcons={false}
            needTrailingColumn={false}
            allowColumnSort={false}
            allowColumnAdd={false}
            allowColumnEdit={false}
            allowRowEdit={false}
            columns={getColumnConfig()}
            rows={detailState}
            onRowUpdate={() => {}}
            currentPage={1}
            totalPageCount={1}
            title={''}
            allowBulkOperation={false}
          />
        </>
      );
    };
  
    const getHeader = () => {
      return (
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel text="Stock Transfer Detail" className="fw-m fs-l" />
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="bg-white border-m mr-r"
              onClick={props.onClose}
            />
            <div className={`row ${'border-radius-m border-m'}`}></div>
          </div>
        </div>
      );
    };
  
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: isBatch ? 810 : 500,
            width: '90%',
            maxHeight: '95%',
            height: 650,
            padding: 0,
            paddingBottom: 60,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          <div
            className="column p-h-xl parent-width parent-height"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height pt-xl"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="m-v-r parent-width">
                <div className="m-v-r parent-width">{getLineItemGrid()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  