import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import {
  fetchHolidayList as fetchHolidayListService,
  HolidayListParamsType,
  transformHolidayListResponse
} from '../../../Services/MRP/HolidayList';
import { RootState } from '../../Store';
interface HolidayListState {
  data: any;
  columnConfig: any;
  status: API_STATUS;
}
const initialState: HolidayListState = {
  data: {},
  columnConfig: [],
  status: API_STATUS.IDLE
};
export const fetchHolidayList = createAsyncThunk(
  'mrpHolidayList/fetchHolidayList',
  async (payload: { params?: HolidayListParamsType } = {}) => {
    const response = await fetchHolidayListService(payload?.params);
    return response;
  }
);
export const MRPHolidayListSlice = createSlice({
  name: 'mrpHolidayList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidayList.pending, (state, action) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchHolidayList.fulfilled, (state, action) => {
        state.data = transformHolidayListResponse(action.payload);
        state.status = API_STATUS.IDLE;
      });
  }
});
export const {} = MRPHolidayListSlice.actions;
export const selectMRPHolidayList = (state: RootState) =>
  state.mrpHolidayList.data;
export const selectHolidayListStatus = (state: RootState) =>
  state.mrpHolidayList.status;
