import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import AccountsService from '../../Services/Accounts';
import Utility from '../../Utility/Utility';
import { RootState } from '../Store';
import { SliceManager } from './SliceManager';
import { ApiResponse, DefaultAccountDTO } from '../../Models/Common';
const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  accountGroupData: {} as any,
  defaultAccounts: {} as ApiResponse<DefaultAccountDTO>
};

export const fetchAccoutnsList = createAsyncThunk('fetchAccounts', async () => {
  const response = await AccountsService.fetchAccountsList();
  return response;
});

export const fetchAccountGroup = createAsyncThunk(
  'fetchAccountGroup',
  async (currency: string) => {
    const response = await AccountsService.fetchAccountGroup(currency);
    return response;
  }
);

export const fetchOBAccoutnsList = createAsyncThunk(
  'fetchAccountsByPage',
  async () => {
    const response = await AccountsService.getOBAccountByPage();
    return response;
  }
);

export const fetchDefaultAccounts = createAsyncThunk(
  'fetchDefaultAccounts',
  async () => {
    const response = await AccountsService.fetchDefaultAccounts();
    return response;
  }
);

export const AccountSlice = createSlice({
  name: 'Accounts',
  initialState,
  reducers: {
    addAccountsColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          editable:
            config.columnCode === 'exchangeRate' ? true : config.editable,
          width: config.columnCode === 'exchangeRate' ? 200 : config.width
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(configsToStore, REMOTE_CONFIG_TABLES.COA);
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addAccounts: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    appendAccountInStore: (state, action: PayloadAction<any>) => {
      let findIfExist = state.data?.content?.find(
        (acc: any) => acc.code === action.payload.code
      );
      if (Utility.isEmpty(findIfExist)) {
        let copyOfAccounts = state.data?.content;
        copyOfAccounts = copyOfAccounts
          ? [...copyOfAccounts, action.payload]
          : [action.payload];
        state.data = { content: copyOfAccounts };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccoutnsList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccoutnsList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
        SliceManager.setSliceData('selectAccount', action.payload);
      })
      .addCase(fetchAccountGroup.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccountGroup.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.accountGroupData = action.payload;
      })
      .addCase(fetchDefaultAccounts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchDefaultAccounts.fulfilled, (state, action) => {
        state.defaultAccounts = action.payload;
      })
      .addCase(fetchOBAccoutnsList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchOBAccoutnsList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.formData = action.payload;
      });
  }
});

export const {
  addAccounts,
  appendAccountInStore,
  addAccountsColumnConfig,
  updateColumnConfig
} = AccountSlice.actions;

export const selectedAccounts = (state: RootState) => state.accountsInfo.data;
export const selectedAccountGroups = (state: RootState) =>
  state.accountsInfo.accountGroupData;
export const selectDefaultAccounts = (state: RootState) =>
  state.accountsInfo.defaultAccounts;
export const selectedOBAccounts = (state: RootState) =>
  state.accountsInfo.formData;
export const selectAccountsColumnConfig = (state: RootState) =>
  state.accountsInfo.columnConfig;
export const selectAccountsColumnConfigTableId = (state: RootState) =>
  state.accountsInfo.configTableId;
