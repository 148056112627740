import { IState } from '../Models/Interfaces';

export const salesOrderInitialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export const ORDER_ITEM_STATUS_NAME = {
  IN_STOCK: 'In Stock',
  EXPECTED: 'Expected',
  NOT_AVAILABLE: 'Not Available'
};

export enum ORDER_ITEM_STATUS {
  IN_STOCK = 'IN_STOCK',
  EXPECTED = 'EXPECTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export const INGREDIENTS_STATUS_NAME = {
  PROCESSED: 'Processed',
  IN_STOCK: 'In Stock',
  EXPECTED: 'Expected',
  NOT_AVAILABLE: 'Not Available'
};

export enum INGREDIENTS_STATUS {
  PROCESSED = 'PROCESSED',
  IN_STOCK = 'IN_STOCK',
  EXPECTED = 'EXPECTED',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export const PRODUCTION_STATUS_NAME = {
  DONE: 'Done',
  WORK_IN_PROGRESS: 'Work In Progress',
  NOT_STARTED: 'Not Started',
  BLOCKED: 'Blocked'
};

export enum PRODUCTION_STATUS {
  DONE = 'DONE',
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  BLOCKED = 'BLOCKED'
}

export const ORDER_DELIVERY_STATUS_NAME = {
  NOT_SHIPPED: 'Not Shipped',
  PENDING: 'Pending',
  PACKED: 'Packed',
  DELIVERED: 'Delivered'
};

export enum ORDER_DELIVERY_STATUS {
  NOT_SHIPPED = 'NOT_SHIPPED',
  PENDING = 'PENDING',
  PACKED = 'PACKED',
  DELIVERED = 'DELIVERED'
}

export const STATIC_ORDER_PROGRESS = [
  { field: 'Invoice', status: 'OPEN' },
  { field: 'Paid', status: 'OPEN' },
  { field: 'Pick', status: 'OPEN' },
  { field: 'Pack', status: 'OPEN' },
  { field: 'Ship', status: 'OPEN' }
];

export const PRODUCTION_STATUS_OPTIONS = [
  {
    id: 'Planned',
    name: 'Planned',
    color: 'data-grid-badge-color-3'
  },
  {
    id: 'Pending',
    name: 'Pending',
    color: 'data-grid-badge-color-4'
  },
  {
    id: 'Scheduled',
    name: 'Scheduled',
    color: 'data-grid-badge-color-8'
  },
  {
    id: 'Delayed',
    name: 'Delayed',
    color: 'data-grid-badge-color-10'
  },
  {
    id: 'Inprocess',
    name: 'In Progress',
    color: 'data-grid-badge-color-5'
  },
  {
    id: 'Completed',
    name: 'Completed',
    color: 'data-grid-badge-color-6'
  }
];

export const PRODUCTION_COL_SO = {
  name: 'Production',
  type: 'select',
  index: 13,
  options: [],
  required: true,
  width: 120,
  editable: false,
  hidden: false,
  uiVisible: true,
  systemField: true,
  allowColumnSort: false,
  columnCode: 'productionStatus',
  key: 'productionStatus',
  allowFilter: false,
  textAlign: 'left',
  id: 'Zl2f12'
};

export const SO_COL = [
  {
    name: 'Progress',
    type: 'text',
    index: 5,
    options: [],
    required: false,
    width: 240,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'orderProgress',
    datasource: [],
    allowFilter: false,
    key: 'orderProgress',
    id: 'wr34tr'
  },
  {
    name: 'Paid',
    type: 'number',
    index: 5,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'amountPaid',
    allowFilter: false,
    textAlign: 'right',
    key: 'amountPaid',
    id: 'wrtr'
  },
  {
    name: 'Balance Due',
    type: 'number',
    index: 5,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'balanceDue',
    allowFilter: false,
    textAlign: 'right',
    key: 'balanceDue',
    id: 'wrt23r'
  }
];
