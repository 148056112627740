import React from 'react';
import { useSelector } from 'react-redux'; // Assuming you're using Redux
import { DKIcons } from 'deskera-ui-library';
import { enableMultiViewJournalEntryCondition } from './enableMultiViewJECondition';

// Function for enabling or disabling a menu item based on a condition
export function enableMultiViewJournalEntry(
  menuItems: any[],
  onClick: (data: any) => void,
  customTitle?: string,
  icon?: string
): any[] {
  if (enableMultiViewJournalEntryCondition()) {
    const menuItem = {
      title: customTitle || 'Show GL Impact',
      icon: icon || DKIcons.ic_document,
      onClick: (data: any) => onClick(data)
    };
    return [...menuItems, menuItem]; // Use the spread operator to create a new array
  }
  return menuItems;
}

// Function for enabling or disabling a method based on a condition
export function enableMultiViewJournalEntryMethod(callback: any) {
  if (enableMultiViewJournalEntryCondition()) {
    callback();
  }
}

// Function for enabling or disabling a method based on a condition with else
export function enableMultiViewJournalEntryMethodAndElse(
  callback: any,
  elseCallback: any
) {
  if (enableMultiViewJournalEntryCondition()) {
    callback();
  } else {
    elseCallback();
  }
}

// Component to conditionally render children
export function enableMultiViewJournalEntryChildren({
  children
}: {
  children: React.ReactNode;
}) {
  if (enableMultiViewJournalEntryCondition()) {
    return children;
  }
  return null;
}
export function enableMultiViewJournalEntryChildrenNot({
  children
}: {
  children: React.ReactNode;
}) {
  if (!enableMultiViewJournalEntryCondition()) {
    return children;
  }
  return null;
}
