import { IState } from '../../../Models/Interfaces';
import { INPUT_TYPE, DKIcons } from 'deskera-ui-library';
import { USstateMockData } from '../../../Constants/StaticData';

export const agenciesinitialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export enum AGENCIES_COLUMN_CODE {
  AGENCY_ID = 'id',
  AGENCY_NAME = 'name',
  FILLING_FREQUENCY = 'filingFrequency',
  START_OF_TAX_PERIOD = 'periodStartMonth',
  START_DATE = 'startDate',
  REPORTING_METHOD = 'basisType',
  STATUS = 'active'
}

export const FILLING_FREQUENCY = [
  {
    id: 1,
    title: 'Monthly',
    value: 'MONTHLY'
  },
  {
    id: 2,
    title: 'Quaterly',
    value: 'QUARTERLY'
  },
  {
    id: 3,
    title: 'Yearly',
    value: 'YEARLY'
  }
];

export enum REPORTING_METHOD_TYPE {
  ACCRUAL = 'ACCRUAL',
  CASH = 'CASH'
}

export const REPORTING_METHOD = [
  {
    id: 1,
    title: 'Accrual',
    value: 'ACCRUAL'
  },
  {
    id: 2,
    title: 'Cash',
    value: 'CASH'
  }
];

export const initialFormData = {
  agencyId: {
    value: '',
    hasError: false,
    errorMsg: ''
  },
  agencyName: {
    value: USstateMockData[0],
    hasError: false,
    errorMsg: ''
  },
  startDate: {
    value: new Date(),
    hasError: false,
    errorMsg: ''
  },
  fillingFrequency: {
    value: FILLING_FREQUENCY[0],
    hasError: false,
    errorMsg: ''
  },
  reportingMethod: {
    value: REPORTING_METHOD[0],
    hasError: false,
    errorMsg: ''
  },
  status: {
    value: true,
    hasError: false,
    errorMsg: ''
  }
};

export const AgenciesColumnConfig = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    options: null,
    required: false,
    width: 300,
    editable: false,
    columnCode: AGENCIES_COLUMN_CODE.AGENCY_NAME,
    key: AGENCIES_COLUMN_CODE.AGENCY_NAME,
    id: AGENCIES_COLUMN_CODE.AGENCY_NAME
  },
  {
    name: 'Filling Frequency',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 1,
    options: null,
    required: false,
    width: 200,
    editable: false,
    columnCode: AGENCIES_COLUMN_CODE.FILLING_FREQUENCY,
    key: AGENCIES_COLUMN_CODE.FILLING_FREQUENCY,
    id: AGENCIES_COLUMN_CODE.FILLING_FREQUENCY
  },
  {
    name: 'Start of Tax Period',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 2,
    options: null,
    required: false,
    width: 200,
    editable: false,
    columnCode: AGENCIES_COLUMN_CODE.START_OF_TAX_PERIOD,
    key: AGENCIES_COLUMN_CODE.START_OF_TAX_PERIOD,
    id: AGENCIES_COLUMN_CODE.START_OF_TAX_PERIOD
  },
  {
    name: 'Start Date',
    type: INPUT_TYPE.DATE,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 200,
    editable: false,
    columnCode: AGENCIES_COLUMN_CODE.START_DATE,
    key: AGENCIES_COLUMN_CODE.START_DATE,
    id: AGENCIES_COLUMN_CODE.START_DATE
  },
  {
    name: 'Reporting Method',
    type: INPUT_TYPE.SELECT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: [
      {
        id: 'CASH',
        name: 'Cash',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'ACCRUAL',
        name: 'Accrual',
        color: 'data-grid-badge-color-10'
      }
    ],
    required: false,
    width: 200,
    editable: false,
    columnCode: AGENCIES_COLUMN_CODE.REPORTING_METHOD,
    key: AGENCIES_COLUMN_CODE.REPORTING_METHOD,
    id: AGENCIES_COLUMN_CODE.REPORTING_METHOD
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 4,
    options: [
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'INACTIVE',
        name: 'In Active',
        color: 'data-grid-badge-color-4'
      }
    ],
    required: true,
    width: 150,
    editable: true,
    allowAddOption: false,
    columnCode: AGENCIES_COLUMN_CODE.STATUS,
    key: AGENCIES_COLUMN_CODE.STATUS,
    id: AGENCIES_COLUMN_CODE.STATUS
  }
];
