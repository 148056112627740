import { TableColumnPopupType, TableTotalColumnList } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility from "../../../Utilities/Utility";
import { getFirstTableColumnType, getIsSelectTotalColumnByType, getSortedHeaderType } from "../TableUtility";
export class SOAVendorTableTotalColumn {
    lineItems: any;
    currency: string;
    totalCredit: string;
    totalDebit: string;
    totalAmountInBase: string;

    constructor(lineItems: any, data: any, currency: string) {
        this.lineItems = lineItems
        this.currency = currency
        this.totalCredit = ''
        this.totalDebit = ''
        this.totalAmountInBase = ''
        this.getTotalValue(lineItems)
    }

    getTotalValue(lineItems: any) {
        if (lineItems === undefined || lineItems === null) {
            return
        }

        if (lineItems.length === 0) {
            return
        }

        var credit = 0
        var debit = 0
        var amountInBase = 0

        lineItems.forEach((element: any) => {
            if (!isNaN(element.credit)) {
                credit = credit + Number(element.credit)
            }
            if (!isNaN(element.debit)) {
                debit = debit + Number(element.debit)
            }
            if (!isNaN(element.amountInBase)) {
                amountInBase = amountInBase + Number(element.amountInBase)
            }
        });

        this.totalCredit = getIsSelectTotalColumnByType(TableColumnPopupType.credit) ? Utility.toCurrencyFormat(credit, this.currency) : ''
        this.totalDebit = getIsSelectTotalColumnByType(TableColumnPopupType.debit) ? Utility.toCurrencyFormat(debit, this.currency) : ''
        this.totalAmountInBase = getIsSelectTotalColumnByType(TableColumnPopupType.amountInBase) ? Utility.toCurrencyFormat(amountInBase, this.currency) : ''
        return
    }

    toString() {
        var sortedHeader = getSortedHeaderType()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var firstColumnType = getFirstTableColumnType([], undefined)
            sortedHeader.forEach(type => {
                sortedValue.push(this.getDefaultValue(type, firstColumnType))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getDefaultValue(type: string, firstColumn: string | undefined) {
        if (firstColumn !== undefined) {
            if (firstColumn === type) {
                if (!TableTotalColumnList.includes(type)) {
                    return '<b>' + getLocalisedText('total') + '</b>'
                }
            }
        }
        return this.getValueByType(type)
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.credit:
                return this.totalCredit
            case TableColumnPopupType.debit:
                return this.totalDebit
            case TableColumnPopupType.amountInBase:
                return this.totalAmountInBase
            default:
                return ''
        }
    }



    getDefaultList() {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
    }
}