import { DKLabel, Toggle } from 'deskera-ui-library';

interface LabelWithToggleProps {
  title: string;
  toggleState: boolean;
  toggleCallback: () => void;
  className?: string;
  labelMinWidth?: number;
  labelClassName?: string;
}

const LabelWithToggle = (props: LabelWithToggleProps) => {
  return (
    <div
      className={`row justify-content-between gap-2 ${
        props.className ? props.className : ''
      }`}
    >
      <DKLabel
        text={props.title}
        className={`${props?.labelClassName ? props?.labelClassName : ''}`}
        style={{ minWidth: props?.labelMinWidth ? props?.labelMinWidth : 100 }}
      />
      <div style={{ minWidth: 42 }}>
        <Toggle
          className="box-content"
          onChange={(checked: any) => {
            props.toggleCallback();
          }}
          isOn={props.toggleState}
        />
      </div>
    </div>
  );
};

export default LabelWithToggle;
