import React from "react";
import './SettingPanel.css';
import {TemplateItem} from '../model/TemplateItem';
import {Action, ComponentType, ImageResize, ScreenState} from '../model/Enum';
import {ImageUtility} from "../../utilities/ImageUtility";
import AddComponentPopup from "./AddComponentPopup";
import {getLocalisedText} from "../../../../Translate/LanguageManager";
import CustomFieldManager from "../../../../Manager/CustomFieldManager";
import Utility, { getCapitalized } from "../../../../Utilities/Utility";
import { getDefaultCustomFieldValue } from "../../utilities/CommonUtility";
import ViewComponentListPopup from "./ViewComponentListPopup";
import { IMG_MAX_UPLOAD_SIZE } from "../../../../Constants/Constants";
import { showToast, TOAST_TYPE } from "../../../ToastComponent/Toast";
import { Asset } from "../../../../assets";

interface Props {
    id: number
    componentList: TemplateItem[]
    templateItem: TemplateItem
    onComponentDataChanged: (element: TemplateItem) => void
    onImageTapped: () => void

    onRemoveComponent: (element: TemplateItem, view: string) => void
    onDuplicateComponent: (element: TemplateItem) => void
    onAddComponentTapped: (action: Action) => void
    onCancelPressed: () => void
    onSelectSystemField: () => void
    onAddComponent: (selectedComponent: TemplateItem, ComponentList: TemplateItem[], id: number, action: Action) => void
    screenState: ScreenState
    isAddComponentPopupRequired:boolean
    isViewComponentPopupRequired: boolean
    onAddPopupTapped: () => void
    onViewPopupTapped: () => void
    onSelectComponent: (selectedComponent: TemplateItem) => void
    data: any
}

interface States {
}
export default class SettingPanel extends React.Component<Props, States> {
    fileInputRef: any
    not_num_period = new RegExp("[^0-9.]")
    constructor(props: any) {
        super(props);
        this.textChanged = this.textChanged.bind(this);
        this.textSizeChanged = this.textSizeChanged.bind(this);
        this.textWeightChanged = this.textWeightChanged.bind(this);
        this.textStyleChanged = this.textStyleChanged.bind(this);
        this.textOpacityChanged = this.textOpacityChanged.bind(this);
        this.imageSizeRatioChanged = this.imageSizeRatioChanged.bind(this);
        this.imagePathChanged = this.imagePathChanged.bind(this);
        this.imageChanged = this.imageChanged.bind(this);
        this.imageAlignmentChanged = this.imageAlignmentChanged.bind(this);
        this.textAlignmentChanged = this.textAlignmentChanged.bind(this);
        this.textColorChanged = this.textColorChanged.bind(this);
        this.customFieldChanged = this.customFieldChanged.bind(this);
        this.fileInputRef = React.createRef();
    }
    textChanged(event: any) {
        var data = this.props.templateItem;
        data["newText"] = event.target.value;
        this.dataChanged(data);
    }

    textSizeChanged(event: any) {
        var data = this.props.templateItem;
        data.style.fontSize = event.target.value;
        this.dataChanged(data);
    }

    textStyleChanged(event: any) {
        var data = this.props.templateItem;
        data.style.fontStyle = event.target.value.toLowerCase();
        this.dataChanged(data);
    }

    textWeightChanged(event: any) {
        var data = this.props.templateItem;
        data.style.fontWeight = event.target.value.toLowerCase();
        this.dataChanged(data);
    }

    textOpacityChanged(event: any) {
        var data = this.props.templateItem;
        data.style.opacity = event.target.value;
        this.dataChanged(data);
    }
    textAlignmentChanged(event: any) {
        var data = this.props.templateItem;
        data.style.alignment = event.target.value;
        this.dataChanged(data);
    }

    imageSizeRatioChanged(action: ImageResize) {
        var data = this.props.templateItem;
        if(action === ImageResize.INCREASE) {
            data.imageRatio = this.props.templateItem.imageRatio + 5
        }
        else if (action === ImageResize.DECREASE) {
            var newRatio = this.props.templateItem.imageRatio - 5
            if (newRatio > 0) {
                data.imageRatio = newRatio
            }
        }
        this.dataChanged(data);
    }

    imagePathChanged(imagePath: string, callback:any) {
        var data = this.props.templateItem;
        const img = new Image();
        data.imagePath = imagePath
        data.image = imagePath
        data.attachmentId = undefined
        img.src = imagePath
        data.imageRatio = 100
        img.onload = (result:any) => {
            const imageUtility = new ImageUtility()
            imageUtility.resize(result.path[0].naturalWidth, result.path[0].naturalHeight, 200)
            data.width = imageUtility.width
            data.height = imageUtility.height
            callback(data)
        }
    }

    imageChanged(image: any) {
        var data = this.props.templateItem;
        data.attachmentId = undefined
        data.image = image;
        this.dataChanged(data);
    }

    imageAlignmentChanged(event: any) {
        var data = this.props.templateItem;
        data.style.alignment = event.target.value;
        this.dataChanged(data);
    }
    textColorChanged(event: any) {
        var data = this.props.templateItem;
        data.style.fontColor = event.target.value;
        this.dataChanged(data);
    }
    // backgroundColorChanged(event: any) {
    //     var data = this.props.templateItem;
    //     data["backgroundColor"] = event.target.value;
    //     this.dataChanged(data);
    // }

    customFieldChanged(event: any) {
        var data = this.props.templateItem;
        var customField = CustomFieldManager.getCustomFieldByCode(event.target.value)
        if(customField === undefined) {
            return
        }
        data.systemField = customField.code
        data.newText = getDefaultCustomFieldValue(customField, this.props.data)
        data.text = getDefaultCustomFieldValue(customField, this.props.data)
        this.dataChanged(data);
    }



    imagePopupImageSelected(img: any) {
        var data = this.props.templateItem;
        data["image"] = img;
        this.dataChanged(data);
    }

    mainImageSelectionTapped = (event: any) => {
        this.props.onImageTapped();
    };

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    dataChanged(data: TemplateItem) {
        this.props.onComponentDataChanged(data)
    }

    //////////////////////////////////////////////////

    getListPickerArrow() {
        return (
            <img
                src={Asset.icon.ic_listPickerArrow3}
                alt=""
                className={'listPickerArrowStyle'}
            />
        );
    }


    render() {
        return (
            <div style={{
                pointerEvents: this.props.screenState !== ScreenState.VIEW ? 'auto' : 'none',
                padding: 6,
            }}>
                {this.renderAddComponentSection()}
                {this.props.templateItem.id !== undefined &&
                    this.props.isAddComponentPopupRequired === false &&
                    this.props.isViewComponentPopupRequired === false && this.renderContent()}
            </div>
        );
    }



    renderContent() {
        return (
            <div
                style={{
                    color: "black",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    padding: 6,
                }}
            >
                <text
                    style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        marginTop: 5,
                        marginLeft: 2,
                        marginBottom: 8,
                    }}
                >
                    {getLocalisedText('settings')}
                </text>

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKImage &&
                    this.getImageSelectSection()}

                {/* {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKImage &&
                    this.getImageAlignmentListPicker()} */}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKImage &&
                    this.getImageSizeRatioViewSection()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKBanner &&
                    this.getPreviewBannerSection()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKBanner &&
                    this.getBannerPickerSection()}

                {this.props.templateItem &&
                    (this.props.templateItem.type === ComponentType.DKLabel) &&
                    this.getTextSizeListPicker()}

                {/* {this.props.templateItem &&
                    this.getOpacityListPicker()} */}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    this.getFontStyleListPicker()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    this.getFontWeightListPicker()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    this.getTextAlignmentListPicker()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    this.getTextColorPicker()}

                {/* {this.props.templateItem &&
                    this.props.templateItem.type !== ComponentType.DKBanner &&
                    this.getBackgroundColorPicker()} */}

                {/* {this.getHeadingSection()} */}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    (this.props.templateItem.systemField === undefined || this.props.templateItem.systemField === null || this.props.templateItem.systemField === '') &&
                    this.getTextViewSection()}

                {this.props.templateItem &&
                    this.props.templateItem.type === ComponentType.DKLabel &&
                    (this.props.templateItem.systemField !== undefined && this.props.templateItem.systemField !== null && this.props.templateItem.systemField !== '') &&
                    this.getCustomFieldPicker()}

                {this.props.templateItem &&
                    this.getRemoveButton()}

                {/*{this.props.templateItem &&*/}
                {/*    this.props.templateItem.type !== ComponentType.DKBanner &&*/}
                {/*    this.getDuplicateButton()}*/}

                {/* <div style={{ height: 150 }} /> */}
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////


    getSectionHeader(str: string) {
        return (
            <text
                style={{
                    fontSize: 12,
                    color: "black",
                    marginTop: 15,
                    marginBottom: 5,
                    marginLeft: 3,
                    fontWeight: 700,
                }}
            >
                {getLocalisedText(str)}
            </text>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getTextColorPicker() {
        return (
            <div style={{ marginTop: 15 }}>
                {this.getSectionHeader("text_color")}
                <input
                    type="color"
                    className="ColorPicker"
                    style={{
                        marginLeft: 10,
                    }}
                    value={this.props.templateItem ? this.props.templateItem.style.fontColor : "#ffffff"}
                    onChange={this.textColorChanged}
                />
            </div>
        );
    }
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    // getBackgroundColorPicker() {
    //     return (
    //         <div style={{ marginTop: 15 }}>
    //             {this.getSectionHeader("Section Color")}
    //             <input
    //                 type="color"
    //                 className="ColorPicker"
    //                 style={{
    //                     marginLeft: 10,
    //                 }}
    //                 value={
    //                     this.props.templateItem.backgroundColor
    //                         ? this.props.templateItem.backgroundColor
    //                         : "#ffffff"
    //                 }
    //                 onChange={this.backgroundColorChanged}
    //             />
    //         </div>
    //     );
    // }
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getTextViewSection() {
        return (
            <div className='listPickerHolderStyle'>
                {this.getSectionHeader("text")}
                <textarea
                    className="docTextField"
                    style={{ minHeight: 120, fontFamily: "helvetica", fontSize: 13, width: '90%'}}
                    placeholder=""
                    dir={Utility.getInputDirection()}
                    // type="text"
                    value={this.props.templateItem ? this.props.templateItem.newText : ""}
                    onChange={this.textChanged}
                />
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getImageSizeRatioViewSection() {
        var ratioList: any = []
        for (var i = 1; i < 21; i++) {
            ratioList.push(i*5)
        }
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("image_ratio")}
                <div className='imageResizeDiv'>
                    <button
                        className='imageRatioButton'
                        onClick={() => this.imageSizeRatioChanged(ImageResize.DECREASE)}>
                        -
                    </button>
                    <label className='imageRatioText'>
                        {this.props.templateItem.imageRatio}
                    </label>
                    <button
                        className='imageRatioButton'
                        onClick={() => this.imageSizeRatioChanged(ImageResize.INCREASE)}>
                        +
                    </button>

                </div>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    getTextSizeListPicker() {
        var fontSizeList = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '36px', '48px', '72px', '96px']
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("text_size")}
                <select
                    onChange={this.textSizeChanged}
                    className="listPicker"
                    style={{
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}>
                    {
                        fontSizeList.map(element => {
                            return (
                                <option value={element} selected={this.props.templateItem.style.fontSize === element}>
                                    {element}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getOpacityListPicker() {
        var opacityList: any = []
        for (var i = 0.1; i < 1; i += 0.1) {
            opacityList.push(Math.round(i * 100) / 100)
        }
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("opacity")}
                <select onChange={this.textOpacityChanged} className="listPicker">
                    {
                        opacityList.map((element: any) => {
                            return (
                                <option value={element} selected={this.getSelectedOpacity(element, this.props.templateItem.style.opacity)}>
                                    {element}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    getSelectedOpacity(element: number, value?: number) {

        var newValue = Number(value)
        if (newValue === undefined || newValue === null) {
            return false
        }
        else {
            if (element.toFixed(2) === newValue.toFixed(2)) {
                return true
            }
            else {
                return false
            }
        }
    }


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getFontStyleListPicker() {
        var fontStyleList = ['Normal', 'Italic', 'Oblique']
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("font_style")}
                <select onChange={this.textStyleChanged} className="listPicker"
                    style={{
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}>
                    {
                        fontStyleList.map(element => {
                            return (
                                <option value={element} selected={this.props.templateItem.style.fontStyle?.toLowerCase() === element.toLowerCase()}>
                                    {element}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getFontWeightListPicker() {
        var frontWeightList = ['Normal', 'Bold', 'Bolder', 'Lighter', '100', '200', '300', '400', '500', '600', '700', '800', '900']
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("font_weight")}
                <select onChange={this.textWeightChanged}
                    className="listPicker"
                    style={{
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}>
                    {
                        frontWeightList.map(element => {
                            return (
                                <option value={element} selected={this.props.templateItem.style.fontWeight?.toLowerCase() === element.toLowerCase()}>
                                    {element}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getTextAlignmentListPicker() {
        var textAlignList = [
            { value: 'left', label: 'Left' },
            { value: 'center', label: 'Center' },
            { value: 'right', label: 'Right' },
        ]
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("text_alignment")}
                <select
                    onChange={this.textAlignmentChanged}
                    className="listPicker"
                    style={{
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}>
                    {
                        textAlignList.map(element => {
                            return (
                                <option value={element.value} selected={this.props.templateItem.style.alignment === element.value}>
                                    {element.label}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getImageAlignmentListPicker() {
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("image_alignment")}
                <select onChange={this.imageAlignmentChanged} className="listPicker">
                    <option
                        value="left"
                        selected={this.props.templateItem.style.alignment === "left"}
                    >
                        Left
                    </option>
                    <option
                        value="right"
                        selected={this.props.templateItem.style.alignment === "right"}
                    >
                        Right
                    </option>
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getImageSelectSection() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                }}
            >
                {this.getSectionHeader("image_preview")}
                <input
                    ref={this.fileInputRef}
                    // ref={id}
                    type="file"
                    accept="image/jpg,image/jpeg,image/png"
                    onChange={this.handleSelectedFile} hidden />
                <label
                    style={{
                        display: 'flex',
                    }}>
                    <img
                        src={
                            this.props.templateItem && this.props.templateItem.image
                                ? this.props.templateItem.image
                                : Asset.icon.ic_dd_img
                        }
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '270px',
                            maxHeight: '540px',
                            // objectFit: 'cover',
                            borderRadius: 6,
                            marginTop: 10,
                            marginBottom: 12,
                            cursor: "pointer",
                        }}
                        onClick={() => this.fileInputRef.current.click()}
                    />
                </label>
                <button
                    style={{
                        backgroundColor: "rgb(230, 230, 230)",
                        color: "black",
                        borderRadius: 8,
                        fontSize: 13,
                        // fontWeight: "600",
                        cursor: "pointer",
                        border: "solid",
                        outline: "none",
                        padding: 10,
                        paddingTop: 7,
                        paddingBottom: 7,
                        borderWidth: 1,
                        borderColor: "rgb(200, 200, 200)",
                        marginBottom: 10,
                    }}
                    onClick={() => this.fileInputRef.current.click()}
                >
                    {getLocalisedText("choose_file")}
                </button>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getPreviewBannerSection() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    minWidth: 150
                }}
            >
                {this.getSectionHeader("preview_banner")}
                <label
                    style={{
                        display: 'flex',
                    }}>
                    <img
                        src={
                            this.props.templateItem && this.props.templateItem.image
                                ? this.props.templateItem.image
                                : Asset.icon.ic_dd_img
                        }
                        alt=""
                        style={{
                            width: '100%',
                            objectFit: "cover",
                            borderRadius: 6,
                            marginTop: 10,
                            marginBottom: 12,
                            cursor: "pointer",
                        }}
                    />
                </label>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getBannerPickerSection() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                }}
            >
                {this.getSectionHeader("banner_selection")}
                {this.setBannerImage(Asset.icon.banner_0)}
                {this.setBannerImage(Asset.icon.banner_1)}
                {this.setBannerImage(Asset.icon.banner_2)}
                {this.setBannerImage(Asset.icon.banner_3)}
                {this.setBannerImage(Asset.icon.banner_4)}
                {this.setBannerImage(Asset.icon.banner_5)}
            </div>
        );
    }


    setBannerImage(image: any) {
        return (
            <button
                className='bannerImageButton'
                onClick={() => this.imageChanged(image)}>
                <img
                    src={image}
                    alt=""
                    style={{
                        width: '100%',
                        objectFit: "cover",
                        borderRadius: 6,
                        marginTop: 10,
                        marginBottom: 12,
                        cursor: "pointer",
                    }}
                />
            </button>
        )
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getCustomFieldPicker() {
        var customFieldList = CustomFieldManager.getCustomFields()
        return (
            <div className='listPickerHolderStyle'>
                <div style={{ height: 10 }} />
                {this.getSectionHeader("custom_field")}
                <select onChange={this.customFieldChanged} className="listPicker">
                    {
                        customFieldList.map((element, index) => {
                            return (
                                <option value={element.code}
                                    selected={this.props.templateItem.systemField === element.code}>
                                    {getCapitalized(element.label)}
                                </option>
                            )
                        })
                    }
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    handleSelectedFile = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            if (file.size > (IMG_MAX_UPLOAD_SIZE)) {
                showToast(getLocalisedText("MAX_IMG_UPLOAD_ALERT"), TOAST_TYPE.WARNING);
                return
            }
            const reader = new FileReader()
            let self = this;
            reader.onload = function (e) {
                self.imagePathChanged(reader.result as string, (data: TemplateItem) => self.dataChanged(data))
            }
            reader.onerror = (e) => {
                console.error(e)
            }
            reader.readAsDataURL(file);
        }
    };


    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getRemoveButton() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                    marginTop: 10,
                    paddingTop: 10,
                }}
            >
                <button
                    style={{
                        backgroundColor: "rgb(230, 230, 230)",
                        color: "red",
                        borderRadius: 8,
                        fontSize: 13,
                        width: "100%",
                        // fontWeight: "600",
                        cursor: "pointer",
                        border: "solid",
                        outline: "none",
                        padding: 10,
                        paddingTop: 7,
                        paddingBottom: 7,
                        borderWidth: 1,
                        borderColor: "rgb(200, 200, 200)",
                        marginBottom: 10,
                    }}
                    onClick={() => this.props.onRemoveComponent(this.props.templateItem, '')}>
                    {getLocalisedText('delete')}
                </button>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getDuplicateButton() {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                    paddingTop: 10,
                }}
            >
                <button
                    style={{
                        backgroundColor: "rgb(230, 230, 230)",
                        color: 'black',
                        borderRadius: 8,
                        fontSize: 13,
                        width: "100%",
                        // fontWeight: "600",
                        cursor: "pointer",
                        border: "solid",
                        outline: "none",
                        padding: 10,
                        paddingTop: 7,
                        paddingBottom: 7,
                        borderWidth: 1,
                        borderColor: "rgb(200, 200, 200)",
                        marginBottom: 10,
                    }}
                    onClick={() => this.props.onDuplicateComponent(this.props.templateItem)}>
                    {getLocalisedText('duplicate')}
                </button>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    renderAddComponentSection() {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: 'row',
                    position: "relative",
                    paddingTop: 5,
                    paddingBottom: 15,
                    width: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: "bold",
                        padding: 10,
                        paddingLeft: 5,
                        paddingTop: 5,
                    }}
                >
                    <text>Component</text>
                </div>
                <div style={{ width: "100%", position: "absolute", top: 5 }}>
                    {this.props.isAddComponentPopupRequired &&
                        <AddComponentPopup
                            id={this.props.id}
                            componentList={this.props.componentList}
                            onAddComponent={(selected, componentList, id, action) => {
                                this.props.onAddComponent(selected, componentList, id, action)
                            }}
                            onAddNewComponent={(action) => {
                                this.props.onAddComponentTapped(action)
                            }}
                            onCancelPressed={() => {
                                this.props.onCancelPressed()
                            }}
                            onSelectSystemField={() => {
                                this.props.onSelectSystemField()
                            }}

                        />
                    }
                    {this.props.isViewComponentPopupRequired &&
                        <ViewComponentListPopup
                            componentList={this.props.componentList}
                            onSelectComponent={this.props.onSelectComponent}
                            onRemoveComponent={this.props.onRemoveComponent}
                            onCancelPressed={() => {
                                this.props.onCancelPressed()
                            }}
                        />
                    }
                    {this.props.screenState !== ScreenState.VIEW &&
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                // marginRight: 7,
                            }}
                        >
                            <button
                                className='popupActionButton'
                                onClick={() => this.props.onAddPopupTapped()}
                            >
                                {'+ ' + getLocalisedText('add')}
                            </button>
                            {this.props.componentList.length > 0 &&
                                <button
                                    className='popupActionButton'
                                    onClick={() => this.props.onViewPopupTapped()}
                                >
                                    {getLocalisedText('view')}
                                </button>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}
