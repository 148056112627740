export class ColumnOption {
    isShowColumn: boolean
    isUpperCase?: boolean
    onClickCallback?: any
    filter?: boolean
    filterType?: string
    sort?: boolean
    isUsingHeaderWidth: boolean
    isUsingHeaderAlignment: boolean

    constructor(){
        this.isShowColumn = true
        this.isUpperCase = false
        this.isUsingHeaderWidth = true
        this.isUsingHeaderAlignment = true
    }
}