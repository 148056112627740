import ApiConstants from "../API/ApiConstants";
import Utility, { getCapitalized, getIsMakePayment, getIsReceivePayment } from "../Utilities/Utility";
import { USERDEFAULT_savedData } from "../Constants/Constants";
import { getLocalisedText } from '../Translate/LanguageManager';
import { DEFAULT_DECIMAL_SCALE, DEFAULT_NUMBER_FORMAT } from "../Utilities/NumberUtility";
import TemplateSettingsManager from "./TemplateSettingsManager";
export default class AppManager {
  static didUserLoggedIn = false;
  static didDataSaved = true;

  static gclidValue = null

  static getLanguageOptions() {
    return [
      {name: 'English', code: 'en', flag: 'us'},
      {name: 'French', code: 'fr', flag: 'fr'},
      {name: 'Spanish', code: 'es', flag: 'sp'},
      {name: 'Indonesian', code: 'id', flag: 'id'},
      {name: 'Chinese', code: 'zh', flag: 'ch'},
      {name: 'Hindi', code: 'hi', flag: 'in'},
      {name: 'Portuguese', code: 'pt', flag: 'pt'},
      {name: 'German', code: 'de', flag: 'de'},
      {name: 'Italian', code: 'it', flag: 'it'}
    ]
  }

  static getTableColumnOptions(array, option) {
    return array.find( item => item.type === option)
  }

  static getLocalStorageParameters() {
    //Pranav: To remove 'showDiscount', 'showTax'
    //'companyName', 'from',
    return ['templateId','logo','signature', 'termsAndCondition',
      'notes', 'themeColor', 'dateFormat', 'showSignature', 'showDiscount', 'showTax', 'notes', 'showCompanyName',
      'showFrom', 'showClientBillToAddress', 'showClientShipToAddress', 'showRefNumber', 'showTermsAndCondition',
      'showNotes', 'showStartDate', 'showDueDate', 'billToTitle', 'shipToTitle', 'tableColumnPopup', 'langKey', 'showDocumentType', 'componentList']
  }

  static userLoggedIn() {
    AppManager.didUserLoggedIn = true;
  }
  static isUserLoggedIn() {
    return AppManager.didUserLoggedIn;
  }

  static gotoLoginPage(documentData) {
    Utility.setPersistentValue(Utility.encodeString(JSON.stringify(documentData)), USERDEFAULT_savedData)
    window.open(
        ApiConstants.URL.LOGIN_REDIRECT + window.location.href,
        "_self"
    );
  }
  static gotoSignupPage(documentData) {
    Utility.setPersistentValue(Utility.encodeString(JSON.stringify(documentData)), USERDEFAULT_savedData)
    let url = ApiConstants.URL.SIGN_UP + window.location.href
    if(AppManager.getGclidValue() !== null) {
      if(url.lastIndexOf('?') > -1) {
        url = url + '&gclid=' + AppManager.getGclidValue()
      }else {
        url = url + '?gclid=' + AppManager.getGclidValue()
      }
    }
    window.open(
        url,
        "_self"
    );
  }

  static isWebURLValid(url) {
    let checkForHTTP_str = url.substring(0, 7);
    let checkForHTTPS_str = url.substring(0, 8);
    if (checkForHTTP_str === "http://" || checkForHTTPS_str === "https://") {
      return true;
    }
    return false;
  }

  static openWebURL(url) {
    if (!AppManager.isWebURLValid(url)) {
      url = "https://" + url;
    }
    window.open(url, "_self");
  }

  static setGclidValue(val) {
    AppManager.gclidValue = val
  }
  static getGclidValue() {
    return AppManager.gclidValue
  }

  static getLocalisedType(type) {
    return getLocalisedText(type.toLowerCase())
  }

  static getTypeNoString(type) {
    if (getIsMakePayment(type)) {
      return getCapitalized(getLocalisedText('payment_label')) + getLocalisedText("num")
    }
    else if(getIsReceivePayment(type)) {
      return getCapitalized(getLocalisedText('receipt_label')) + getLocalisedText("num")
    }
    else if(type === getLocalisedText('number_of_carton')) {
      return getCapitalized(getLocalisedText(type))
    }
    return getCapitalized(getLocalisedText(type)) + getLocalisedText("num")
  }

  static getTypeDateString(type) {
    if (getIsMakePayment(type)) {
      return getCapitalized(getLocalisedText('payment_label')) + getLocalisedText("date")
    }
    else if (getIsReceivePayment(type)) {
      return getCapitalized(getLocalisedText('receipt_label')) + getLocalisedText("date")
    }
    return getCapitalized(getLocalisedText(type)) + getLocalisedText("date")
  }

  static getTypeTotalString(type) {
    return getCapitalized(getLocalisedText(type.toLowerCase())) + " " + getLocalisedText("total")
  }

  ///////////////////////////////////////////////////////////////////////
  // Decimal Scale Related
  ///////////////////////////////////////////////////////////////////////
  static decimalScale = DEFAULT_DECIMAL_SCALE

  static setDecimalScale(value) {
    AppManager.decimalScale = value
  }

  static getDecimalScale() {
    var isSystemDefault = TemplateSettingsManager.getDecimalScaleIsSystemDefault()
    var value = AppManager.decimalScale
    if(isSystemDefault) {
      value = AppManager.tenantDecimalScale
    }
    return value
  }

  static tenantDecimalScale = DEFAULT_DECIMAL_SCALE

  static setTenantDecimalScale(value) {
    AppManager.tenantDecimalScale = value
  }

  static getTenantDecimalScale() {
    return AppManager.tenantDecimalScale
  }

  ///////////////////////////////////////////////////////////////////////
  // Decimal Scale Related
  ///////////////////////////////////////////////////////////////////////
  static numberFormat = DEFAULT_NUMBER_FORMAT

  static setNumberFormat(value) {
    AppManager.numberFormat = value
  }

  static getNumberFormat() {
    return AppManager.numberFormat
  }

  ///////////////////////////////////////////////////////////////////////
  // Decimal Scale Related
  ///////////////////////////////////////////////////////////////////////
  static marginBottom = undefined

  static setMarginBottom(value) {
    AppManager.marginBottom = value
  }

  static getMarginBottom() {
    return AppManager.marginBottom
  }

  ///////////////////////////////////////////////////////////////////////
  // shareableLink Related
  ///////////////////////////////////////////////////////////////////////
  static shareableLink = null

  static setShareableLink(value) {
    AppManager.shareableLink = value
  }

  static getShareableLink() {
    return AppManager.shareableLink
  }

  ///////////////////////////////////////////////////////////////////////
  // isMultiplePage related
  ///////////////////////////////////////////////////////////////////////
  static isMultiplePage = undefined

  static setIsMultiplePage(value) {
    AppManager.isMultiplePage = value
  }

  static getIsMultiplePage() {
    return AppManager.isMultiplePage
  }

  ///////////////////////////////////////////////////////////////////////
  // watermark type
  ///////////////////////////////////////////////////////////////////////
  static watermarkText = 'sample_watermark'

  static setWatermarkText(value) {
    AppManager.watermarkText = value
  }

  static getWatermarkText() {
    return AppManager.watermarkText
  }

  ///////////////////////////////////////////////////////////////////////
  // document id
  ///////////////////////////////////////////////////////////////////////
  static documentId = undefined

  static setDocumentId(value) {
    AppManager.documentId = value
  }

  static getDocumentId() {
    return AppManager.documentId
  }

  ///////////////////////////////////////////////////////////////////////
  // document type
  ///////////////////////////////////////////////////////////////////////
  static documentType = undefined

  static setDocumentType(value) {
    AppManager.documentType = value
  }

  static getDocumentType() {
    return AppManager.documentType
  }

  ///////////////////////////////////////////////////////////////////////
  // design Info for navigating from print template view
  ///////////////////////////////////////////////////////////////////////
  static designInfo = undefined

  static setDesignInfo(value) {
    AppManager.designInfo = value
  }

  static getDesignInfo() {
    return AppManager.designInfo
  }
}
