import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKSpinner,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  APP_NAME,
  INPUT_VIEW_DIRECTION,
  MRP_APP_NAME
} from '../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { COUNTRIES_LIST } from '../../Constants/StaticData';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  fetchAllWarehouseWithoutRole,
  fetchWarehouse,
  fetchWarehouseWithRRBCombinationWithoutRole
} from '../../Redux/Slices/WarehouseSlice';
import { Store } from '../../Redux/Store';
import { RolePermissionUtil } from '../../RolePermission/RolePermissionUtil';
import WarehouseService from '../../Services/Warehouse';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import Utility from '../../Utility/Utility';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';

const CreateNewWarehouse = (props: any) => {
  //constants
  const isEditMode = !Utility.isEmpty(props?.editableWarehouse);

  //states
  const [updating, setUpdating] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [warehouseFormData, setWarehouseFormData] = useState<any>();
  const [canValidate, setCanValidate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isForceReadOnly, setIsForceReadOnly] = useState(
    props?.isReadOnly || false
  );

  //selectors
  const dispatch = useAppDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);
  const [rrbData, setRRBData] = useState<any>();
  const [usersList, setUsersList] = useState<any>([]);
  const [assignedUsersList, setAssignedUsersList] = useState<any>([]);

  /** type: {mainIndex: 0, chipIndex: 0}  */
  const [currentEditableIndex, setCurrentEditableIndex] = useState<any>();

  const checkIfEditable = () => {
    let result = !props?.editableWarehouse?.isDeleteEnable || false;
    if (
      (Utility.isEmpty(props?.editableWarehouse?.warehouseRowInfos) ||
        Utility.isEmpty(props?.editableWarehouse?.warehouseRackInfos) ||
        Utility.isEmpty(props?.editableWarehouse?.warehouseBinInfos)) &&
      result
    ) {
      result = true;
    }

    return result;
  };

  //effects
  useEffect(() => {
    const editData = props?.editableWarehouse;
    const updatedFormData = { ...warehouseFormData };
    if (isEditMode) {
      updatedFormData.name = editData?.name;
      updatedFormData.address1 = editData?.location?.address?.[0]?.address1;
      updatedFormData.address2 = editData?.location?.address?.[0]?.address2;
      updatedFormData.city = editData?.location?.address?.[0]?.city;
      updatedFormData.state = editData?.location?.address?.[0]?.state;
      updatedFormData.postalCode = editData?.location?.address?.[0]?.postalCode;
      updatedFormData.country = COUNTRIES_LIST?.find((country: any) => {
        return country.code === editData?.location?.address?.[0]?.country;
      });
    } else {
      const defaultCountryOption = COUNTRIES_LIST.find(
        (option: any) => option.code === tenantDetails.country
      );
      updatedFormData.country = defaultCountryOption;
    }
    setWarehouseFormData({ ...updatedFormData });

    if (isEditMode) {
      setIsReadOnly(!props?.editableWarehouse?.isDeleteEnable || false);
    }
    getAllActiveUsers();
  }, []);

  //For Edit Case creating the object
  useEffect(() => {
    let rrbEnabled = tenantDetails?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    if (isEditMode) {
      const editData = props?.editableWarehouse;

      let updatedrrbData: any = [];

      rrbEnabled?.forEach((rrb: any) => {
        if (rrb?.name === 'ROW') {
          updatedrrbData.push({
            name: rrb?.name,
            label: rrb?.label,
            enable: rrb?.enable,
            selected: rrb?.enable,
            currentEntry: '',
            chips: editData?.warehouseRowInfos?.map((item: any) => {
              return { name: item?.name.trim(), oldName: item?.name.trim() };
            })
          });
        }
        if (rrb?.name === 'RACK') {
          updatedrrbData.push({
            name: rrb?.name,
            label: rrb?.label,
            enable: rrb?.enable,
            selected: rrb?.enable,
            currentEntry: '',
            chips: editData?.warehouseRackInfos?.map((item: any) => {
              return { name: item?.name.trim(), oldName: item?.name.trim() };
            })
          });
        }
        if (rrb?.name === 'BIN') {
          updatedrrbData.push({
            name: rrb?.name,
            label: rrb?.label,
            enable: rrb?.enable,
            selected: rrb?.enable,
            currentEntry: '',
            chips: editData?.warehouseBinInfos?.map((item: any) => {
              return { name: item?.name.trim(), oldName: item?.name.trim() };
            })
          });
        }
      });

      setRRBData([...updatedrrbData]);
      return;
    }
    if (!Utility.isEmptyObject(rrbEnabled)) {
      setRRBData([...rrbEnabled]);
    }
  }, [tenantDetails]);

  //methods

  const onSave = () => {
    setCanValidate(true);

    //validation
    if (Utility.isEmpty(warehouseFormData?.name)) {
      return;
    }

    let warehouseRowInfos: any = [];
    let warehouseRackInfos: any = [];
    let warehouseBinInfos: any = [];

    rrbData?.forEach((item: any) => {
      if (item.name === 'ROW') {
        item?.chips?.forEach((rowChip: any) => {
          if (rowChip.name === rowChip.oldName) {
            delete rowChip.oldName;
          }
          warehouseRowInfos.push(rowChip);
        });
      }
      if (item.name === 'RACK') {
        item?.chips?.forEach((rackChip: any) => {
          if (rackChip.name === rackChip.oldName) {
            delete rackChip.oldName;
          }
          warehouseRackInfos.push(rackChip);
        });
      }
      if (item.name === 'BIN') {
        item?.chips?.forEach((binChip: any) => {
          if (binChip.name === binChip.oldName) {
            delete binChip.oldName;
          }
          warehouseBinInfos.push(binChip);
        });
      }
    });

    //create payload
    const payload = {
      location: {
        address: [
          {
            address1: warehouseFormData?.address1,
            address2: warehouseFormData?.address2,
            city: warehouseFormData?.city,
            country: warehouseFormData?.country?.code,
            postalCode: warehouseFormData?.postalCode,
            state: warehouseFormData?.state
          }
        ],
        name: warehouseFormData?.name?.trim()
      },
      name: warehouseFormData?.name?.trim(),
      isEdit: false,
      warehouseRowInfos: warehouseRowInfos,
      warehouseRackInfos: warehouseRackInfos,
      warehouseBinInfos: warehouseBinInfos
    };

    //api calls
    if (!isEditMode) {
      let totalRecords = 0;
      if (!Utility.isEmpty(payload.warehouseRowInfos)) {
        totalRecords = payload.warehouseRowInfos.length;
      }
      if (!Utility.isEmpty(payload.warehouseRackInfos)) {
        totalRecords = totalRecords * payload.warehouseRackInfos.length;
      }
      if (!Utility.isEmpty(payload.warehouseBinInfos)) {
        totalRecords = totalRecords * payload.warehouseBinInfos.length;
      }
      if (totalRecords < 10000) {
        setUpdating(true);
        WarehouseService.postWarehouse(payload)
          .then((res: any) => {
            if (assignedUsersList?.length > 0) {
              assignUser(res?.id);
            }
            setUpdating(false);
            dispatch(fetchWarehouseWithRRBCombinationWithoutRole());
            props.onUpdate();
            getWarehouses();
          })
          .catch((err: any) => {
            setUpdating(false);
          });
      } else {
        showAlert(
          'Success',
          'Warehouse with the RRB details will be created in a background, please check after sometime.'
        );
        WarehouseService.postWarehouse(payload)
          .then((res: any) => {
            setUpdating(false);
          })
          .catch((err: any) => {
            setUpdating(false);
          });
        props.onUpdate();
      }
    } else {
      setUpdating(true);
      assignUser(props?.editableWarehouse?.id);
      WarehouseService.editWarehouse(payload, props?.editableWarehouse?.id)
        .then((res: any) => {
          setUpdating(false);
          getWarehouses();
          dispatch(fetchWarehouseWithRRBCombinationWithoutRole());
          props.onUpdate();
        })
        .catch((err: any) => {
          setUpdating(false);
        });
    }
  };

  const assignUser = (id: any) => {
    setUpdating(true);
    let userIds: any = [];
    assignedUsersList?.forEach((item: any) => {
      if (usersList) {
        const option = usersList[item];
        userIds.push(option.value);
      }
    });
    let payload: any = {
      id: id,
      userIds: userIds
    };

    WarehouseService.assignUsersToWarehouse(payload)
      .then((res: any) => {
        setUpdating(false);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const getAllActiveUsers = () => {
    if (
      !Utility.isEmpty(Store.getState().permissions.allUsersList) &&
      Store.getState().permissions.allUsersList?.['content']
    ) {
      const appNameToCheck = [APP_NAME, MRP_APP_NAME];
      let users = Store.getState().permissions.allUsersList?.[
        'content'
      ]?.filter(
        (i: { status: any; roleShortInfo: any[] }) =>
          i.status == 'active' &&
          i.roleShortInfo &&
          i.roleShortInfo.find((r: { appName: string }) =>
            appNameToCheck.includes(r.appName)
          )
      );
      let userOptions: any = [];
      users?.forEach((ele: any) => {
        userOptions.push({
          label: ele.firstName + ' ' + ele.lastName,
          value: ele.id
        });
      });
      setUsersList(userOptions);
      if (isEditMode) {
        let values: any = [];
        props?.editableWarehouse?.assignUserIds?.forEach((item: any) => {
          userOptions?.forEach((option: any, index: any) => {
            if (option.value === item) {
              values.push(index);
            }
          });
        });
        setAssignedUsersList(values);
      }

      // return userOptions;
    } else {
      return [];
    }
  };
  const getWarehouses = () => {
    RolePermissionUtil.checkIsAdmin()
      ? dispatch(fetchAllWarehouseWithoutRole())
      : dispatch(fetchWarehouse());
  };

  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row pop-header-drag-handle">
          {isForceReadOnly && (
            <DKLabel text={`Warehouse Details`} className="fw-m fs-l" />
          )}
          {!isForceReadOnly && (
            <DKLabel
              text={`${!isEditMode ? 'Add' : 'Edit'} Warehouse`}
              className="fw-m fs-l"
            />
          )}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !updating && props.onCancel();
            }}
          />
          {!isForceReadOnly && (
            <div
              className={`row border-radius-m ${
                updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
              }`}
            >
              <DKButton
                title={`${!isEditMode ? 'Add' : 'Save'}`}
                onClick={() => {
                  if (!updating) {
                    onSave();
                  }
                }}
              />
              {updating && <DKSpinner iconClassName="ic-s" />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getOptionButton = (
    index: number,
    title: string,
    selected = index === selectedSegment
  ) => {
    return (
      <div
        className=" cursor-hand "
        onClick={() => {
          setSelectedSegment(index);
        }}
      >
        <DKLabel
          text={title}
          className={'mt-m' + (selected ? ' fw-b text-app-color ' : '')}
        />
        {selected && (
          <div className="parent-width bg-app mt-r" style={{ height: 2.5 }} />
        )}
      </div>
    );
  };

  const getHeaderSegment = () => {
    return (
      <div className="parent-width">
        <DKLine />
        <div
          className="row bg-gray1 border-radius-s p-h-r align-items-start"
          style={{ gap: 30, backgroundColor: 'rgb(250, 250, 250)' }}
        >
          {getOptionButton(0, 'Warehouse')}
          {tenantDetails?.additionalSettings?.ROW_RACK_BIN &&
            getOptionButton(1, 'Row Rack Bin')}
        </div>
        <DKLine />
      </div>
    );
  };

  const getWarehouseDetailsView = () => {
    return (
      <div className="column gap-4">
        {/* name */}
        <DKInput
          title="Name"
          required={true}
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.TEXT}
          value={warehouseFormData?.name ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.name = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* address 1 */}
        <DKInput
          title="Address 1"
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.TEXT}
          value={warehouseFormData?.address1 ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.address1 = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* address 2 */}
        <DKInput
          title="Address 2"
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.TEXT}
          value={warehouseFormData?.address2 ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.address2 = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* city */}
        <DKInput
          title="City"
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.TEXT}
          value={warehouseFormData?.city ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.city = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* state */}
        <DKInput
          title="State"
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.TEXT}
          value={warehouseFormData?.state ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.state = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* state */}
        <DKInput
          title="Postal Code"
          required={false}
          canValidate={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.NUMBER}
          value={warehouseFormData?.postalCode ?? ''}
          className=""
          readOnly={isForceReadOnly}
          onChange={(text: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.postalCode = text;
            setWarehouseFormData({ ...updatedFormData });
          }}
        />
        {/* country */}
        <DKInput
          title="Country"
          onChange={(obj: any) => {
            const updatedFormData = { ...warehouseFormData };
            updatedFormData.country = obj;
            setWarehouseFormData({ ...updatedFormData });
          }}
          value={warehouseFormData?.country}
          required={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          type={INPUT_TYPE.DROPDOWN}
          formatter={(obj: any) => {
            return obj?.name;
          }}
          canValidate={false}
          className=""
          readOnly={isForceReadOnly}
          dropdownConfig={{
            title: '',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: null,
            data: COUNTRIES_LIST ?? [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj?.name ?? ''}`} />;
            }
          }}
        />
        {GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.WAREHOUSE.EDIT
        ) &&
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.WAREHOUSE.CREATE
          ) &&
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.WAREHOUSE.DELETE
          ) && (
            <DKInput
              className={''}
              value={assignedUsersList}
              displayKey="label"
              title="Assign Users"
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              canValidate={true}
              readOnly={isForceReadOnly}
              onChange={(value: number[]) => {
                setAssignedUsersList(value);
              }}
              direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
              dropdownConfig={{
                className: '',
                title: 'Users',
                style: {},
                allowSearch: true,
                searchableKey: 'label',
                multiSelect: true,
                selectedIndexes: [],
                checkMarkColor: 'bg-blue',
                data: usersList,
                renderer: (index: number, obj: any) => {
                  return <DKLabel text={obj.label} />;
                },
                onSelect: (index: number, value: any) => {},
                onClear: () => {}
              }}
            />
          )}
      </div>
    );
  };

  const getRRBDetailsView = () => {
    return <div className="column parent-width">{getElement()}</div>;
  };

  const updateRRBData = (index: number, value: any, key: string) => {
    let copyRRB = [...rrbData];
    let targetObject = copyRRB[index];
    targetObject[key] = value;
    copyRRB[index] = targetObject;
    setRRBData(copyRRB);
  };

  const addNewEntry = (value: string, index: number) => {
    let copy = [...rrbData];
    let currentobj = { ...copy[index] };
    let findChip = currentobj?.chips?.find((item: any) => item.name === value);
    if (findChip) {
      currentobj.currentEntry = '';
      showToast('This value already exists', TOAST_TYPE.FAILURE);
    } else {
      if (currentobj.chips) {
        currentobj.chips = [
          ...currentobj.chips,
          { name: value, oldName: value }
        ];
      } else {
        currentobj.chips = [{ name: value, oldName: value }];
      }
    }
    currentobj.currentEntry = '';
    copy[index] = currentobj;
    setRRBData(copy);
  };

  const updateEntry = (value: string) => {
    let copyRRBData = [...rrbData];
    let currentobj = { ...copyRRBData[currentEditableIndex.mainIndex] };
    let findChip = currentobj?.chips?.[currentEditableIndex.chipIndex];
    if (findChip) {
      findChip = { ...findChip, name: value };
    }
    currentobj.chips[currentEditableIndex.chipIndex] = findChip;
    currentobj.currentEntry = '';
    copyRRBData[currentEditableIndex.mainIndex] = currentobj;
    setRRBData(copyRRBData);
    setCurrentEditableIndex(null);
  };

  const getElement = () => {
    return (
      <div className="column parent-width gap-2">
        {rrbData?.map((item: any, index: number) => {
          return (
            <div className="column parent-width gap-2 border-m border-radius-s p-2">
              <div
                className={`row ${isReadOnly ? '' : 'cursor-hand'}`}
                onClick={() => {
                  if (isReadOnly) return;
                  let copy = [...rrbData];
                  let currentobj = { ...copy[index] };
                  currentobj.selected = !currentobj.selected;
                  copy[index] = currentobj;
                  setRRBData(copy);
                }}
              >
                <DKCheckMark
                  isSelected={item?.selected && item?.chips?.length > 0}
                  onClick={() => {}}
                  title={`Add ${item?.label} ${
                    item?.selected
                      ? '(Press enter or click outside to add)'
                      : ''
                  }`}
                />
              </div>
              {item?.selected && (
                <div className="column parent-width gap-2">
                  <input
                    placeholder={`e.g. ${item?.label}-1`}
                    className="bg-gray1 border-m border-radius-s app-font parent-width p-s border-box outline-none"
                    title=""
                    type={INPUT_TYPE.TEXT}
                    value={item?.currentEntry ?? ''}
                    onChange={(e: any) => {
                      updateRRBData(index, e.target.value, 'currentEntry');
                    }}
                    onKeyDown={(event: any) => {
                      if (currentEditableIndex) {
                        if (event.key === 'Enter' && event.target.value) {
                          updateEntry(event.target.value);
                        }
                      } else {
                        if (event.key === 'Enter' && event.target.value) {
                          addNewEntry(event.target.value, index);
                        }
                      }
                    }}
                    onBlur={(event: any) => {
                      if (!Utility.isEmpty(event.target.value)) {
                        if (currentEditableIndex) {
                          updateEntry(event.target.value);
                        } else {
                          addNewEntry(event.target.value, index);
                        }
                      }
                    }}
                    readOnly={isReadOnly && Utility.isEmpty(item.chips)}
                  />
                  <div className="row flex-wrap justify-content-start gap-2">
                    {item?.chips?.map((item1: any, index1: number) => {
                      return (
                        <div
                          className={`row width-auto border-radius-s bg-gray1 p-v-s p-h-s gap-2 border ${
                            currentEditableIndex?.mainIndex === index &&
                            currentEditableIndex?.chipIndex === index1
                              ? 'border-blue'
                              : ''
                          }`}
                          style={{ maxWidth: '99%' }}
                        >
                          <DKLabel
                            className="row width-auto"
                            style={{
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}
                            text={item1?.name?.trim()}
                          />
                          {(!isReadOnly || !Utility.isEmpty(item.chips)) && (
                            <div
                              className="row width-auto gap-2 bg-gray1"
                              style={{ right: -18, top: -10 }}
                            >
                              <DKIcon
                                title=""
                                src={DKIcons.ic_edit}
                                className="ic-s cursor-pointer"
                                onClick={() => {
                                  updateRRBData(
                                    index,
                                    item1?.name?.trim(),
                                    'currentEntry'
                                  );
                                  setCurrentEditableIndex({
                                    mainIndex: index,
                                    chipIndex: index1
                                  });
                                }}
                              />
                              <DKIcon
                                title=""
                                src={DKIcons.ic_close_2}
                                className="ic-s cursor-pointer"
                                onClick={() => {
                                  let copy: any = [...rrbData];
                                  let editObject = copy[index];
                                  let copyChips = editObject.chips
                                    ? [...editObject.chips]
                                    : [];
                                  copyChips.splice(index1, 1);
                                  editObject.chips = copyChips ?? [];
                                  copy[index] = editObject;
                                  setRRBData(copy);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {isReadOnly && (
          <div className="fs-s">
            <b>Note:</b> This section is now not editable, it might have been
            used somewhere.
          </div>
        )}
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 570,
            width: '90%',
            maxHeight: '95%',
            height:
              GranularPermissionsHelper.hasPermissionFor(
                PERMISSIONS_BY_MODULE.WAREHOUSE.EDIT
              ) &&
              GranularPermissionsHelper.hasPermissionFor(
                PERMISSIONS_BY_MODULE.WAREHOUSE.CREATE
              ) &&
              GranularPermissionsHelper.hasPermissionFor(
                PERMISSIONS_BY_MODULE.WAREHOUSE.DELETE
              )
                ? 530
                : 480,
            padding: 0,
            overflowY: 'hidden'
          }}
        >
          {/* header */}
          {getHeader()}
          {/* tabs */}
          {getHeaderSegment()}
          <div className="col align-items-start parent-size p-l overflow-y-auto show-scroll-bar">
            {selectedSegment === 0 && getWarehouseDetailsView()}
            {selectedSegment === 1 && getRRBDetailsView()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default CreateNewWarehouse;
