import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { TableManager } from '../../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { IColumn } from '../../../Models/Table';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const MRPReportsSKUOrderTrackingSlice = createSlice({
  name: 'mrpReportsSKUOrderTrackingDetails',
  initialState,
  reducers: {
    setMRPReportsSKUOrderTrackingDetailsData: (
      state,
      action: PayloadAction<any>
    ) => {
      state.data = action.payload;
    },
    addMRPReportsSKUOrderTrackingDetailsColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    setMRPReportsSKUOrderTrackingDetailsConfigTableId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.configTableId = action.payload;
    },
    setMRPReportsSKUOrderTrackingDetailsStatus: (
      state,
      action: PayloadAction<API_STATUS>
    ) => {
      state.status = action.payload;
    }
  }
});

export const {
  setMRPReportsSKUOrderTrackingDetailsData,
  addMRPReportsSKUOrderTrackingDetailsColumnConfig,
  setMRPReportsSKUOrderTrackingDetailsConfigTableId,
  setMRPReportsSKUOrderTrackingDetailsStatus,
  updateColumnConfig
} = MRPReportsSKUOrderTrackingSlice.actions;

export const selectMRPReportsSKUOrderTrackingDetailsData = (state: RootState) =>
  state.reportsSalesOrder.data;
export const selectMRPReportsSKUOrderTrackingDetailsColumnConfig = (
  state: RootState
) => {
  return state.mrpReportsSKUOrderTrackingDetails.columnConfig;
};
export const selectMRPReportsSKUOrderTrackingDetailsConfigTableId = (
  state: RootState
) => state.mrpReportsSKUOrderTrackingDetails.configTableId;
export default MRPReportsSKUOrderTrackingSlice.reducer;
