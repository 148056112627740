import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import AmortizationService from '../../Services/Amortization';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const amortizationInitialState = {
  amortizationTemplates: { ...initialState },
  amortizationSchedules: { ...initialState }
};

export const fetchAmortizationTemplates = createAsyncThunk(
  'amortizationTemplates',
  async () => {
    return await AmortizationService.getAmortizationTemplatesByPage();
  }
);

export const fetchAmortizationSchedules = createAsyncThunk(
  'amortizationSchedules',
  async () => {
    return await AmortizationService.getAmortizationSchedulesByPage();
  }
);

export const AmortizationSlice = createSlice({
  name: 'amortization',
  initialState: amortizationInitialState,
  reducers: {
    addAmortizationTemplatesColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          id: config.id ? config.id : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.amortizationTemplates.columnConfig = configsToStore;
      state.amortizationTemplates.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.AMORTIZATION_TEMPLATES
      );
    },
    updateAmortizationTemplatesColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.amortizationTemplates.columnConfig = [...action.payload];
    },
    addAmortizationSchedulesColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          id: config.id ? config.id : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.amortizationSchedules.columnConfig = configsToStore;
      state.amortizationSchedules.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.AMORTIZATION_SCHEDULES
      );
    },
    updateAmortizationSchedulesColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.amortizationSchedules.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmortizationTemplates.pending, (state) => {
        state.amortizationTemplates.status = API_STATUS.LOADING;
      })
      .addCase(fetchAmortizationTemplates.fulfilled, (state, action) => {
        state.amortizationTemplates.status = API_STATUS.IDLE;
        state.amortizationTemplates.data = action.payload;
      })
      .addCase(fetchAmortizationSchedules.pending, (state) => {
        state.amortizationSchedules.status = API_STATUS.LOADING;
      })
      .addCase(fetchAmortizationSchedules.fulfilled, (state, action) => {
        state.amortizationSchedules.status = API_STATUS.IDLE;
        state.amortizationSchedules.data = action.payload;
      });
  }
});

export const {
  addAmortizationTemplatesColumnConfig,
  updateAmortizationTemplatesColumnConfig,
  addAmortizationSchedulesColumnConfig,
  updateAmortizationSchedulesColumnConfig
} = AmortizationSlice.actions;

export const selectAmortizationTemplates = (state: RootState) =>
  state.amortization.amortizationTemplates;

export const selectAmortizationSchedules = (state: RootState) =>
  state.amortization.amortizationSchedules;

export const selectAmortizationSchedulesColumnConfig = (state: RootState) =>
  state.amortization.amortizationSchedules.columnConfig;

export default AmortizationSlice.reducer;
