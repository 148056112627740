import { useEffect, useState } from 'react';
import RouteManager from '../../../Managers/RouteManager';
import { DKLabel, showAlert } from 'deskera-ui-library';
import TenantService from '../../../Services/Tenant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import Utility from '../../../Utility/Utility';
import useConfirm from '../../../Hooks/useConfirm';
import { useTranslation } from 'react-i18next';
import { QC_FLOW_MODULES } from './QCFlowConstant';
import SettingsDetailCard from '../SettingsDetailCard';
import LabelWithToggle from '../AdvancedSettings/LabelWithToggle';

const initialState = [
  {
    enable: false,
    module: QC_FLOW_MODULES.GOODS_RECEIPT
  },
  {
    enable: false,
    module: QC_FLOW_MODULES.FULFILLMENT
  },
  {
    enable: false,
    module: QC_FLOW_MODULES.STOCK_ADJUSTMENT
  }
];

const QCFlow = (props: any) => {
  const dispatch = useAppDispatch();
  const { confirm } = useConfirm();
  const { t, i18n } = useTranslation();
  const tenantDetails = useAppSelector(activeTenantInfo);

  const [formState, setFormState] = useState<any>(initialState);

  useEffect(() => {
    if (!Utility.isEmpty(tenantDetails?.additionalSettings?.QC)) {
      let additionalQCDetail = tenantDetails?.additionalSettings?.QC;
      let newState = [...formState];
      newState = additionalQCDetail;
      setFormState([...newState]);
    }
  }, [tenantDetails]);

  const onModuleChange = async (moduleName: string) => {
    let moduleIndex = formState?.findIndex(
      (state: any) => state?.module === moduleName
    );
    if (moduleIndex !== -1) {
      if (formState?.[moduleIndex]?.enable) {
        showAlert('', 'This setting cannot be disabled.');
        return;
      }

      const isConfirmed = await confirm(
        `Turning ON this setting will allow enable QC flow, also note that you will not be able to turn it OFF once enabled.`,
        `Enable QC Flow`,
        `Confirm`,
        `Cancel`,
        `bg-blue text-white ml-r mt-r`
      );

      if (isConfirmed) {
        let Payload: any = {
          additionalSettings: {
            QC: formState
          }
        };

        let newState = [...formState];
        switch (moduleName) {
          case QC_FLOW_MODULES.GOODS_RECEIPT:
            let findGRIndex = newState.findIndex(
              (state) => state.module === moduleName
            );
            if (findGRIndex > -1) {
              newState[findGRIndex] = {
                enable: true,
                module: QC_FLOW_MODULES.GOODS_RECEIPT
              };
            }
            setFormState([...newState]);
            break;
          case QC_FLOW_MODULES.FULFILLMENT:
            let findFulfillmentIndex = newState.findIndex(
              (state) => state.module === moduleName
            );
            if (findFulfillmentIndex > -1) {
              newState[findFulfillmentIndex] = {
                enable: true,
                module: QC_FLOW_MODULES.FULFILLMENT
              };
            }
            setFormState([...newState]);
            break;
          case QC_FLOW_MODULES.STOCK_ADJUSTMENT:
            let findStockAdjustmentIndex = newState.findIndex(
              (state) => state.module === moduleName
            );
            if (findStockAdjustmentIndex > -1) {
              newState[findStockAdjustmentIndex] = {
                enable: true,
                module: QC_FLOW_MODULES.STOCK_ADJUSTMENT
              };
            }
            setFormState([...newState]);
            break;
          default:
            break;
        }

        Payload = {
          additionalSettings: {
            QC: [...newState]
          }
        };

        TenantService.updateOrgSetting(Payload)
          .then(async (res) => {
            await dispatch(fetchCurrentTenantInfo());
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const getModuleToggleStatus = (moduleName: string) => {
    let newState = [...formState];
    switch (moduleName) {
      case QC_FLOW_MODULES.GOODS_RECEIPT:
        let findGRIndex = newState.findIndex(
          (state) => state.module === moduleName
        );
        if (findGRIndex > -1) {
          return newState[findGRIndex];
        }
        return;
      case QC_FLOW_MODULES.FULFILLMENT:
        let findFulfillmentIndex = newState.findIndex(
          (state) => state.module === moduleName
        );
        if (findFulfillmentIndex > -1) {
          return newState[findFulfillmentIndex];
        }
        return;
      case QC_FLOW_MODULES.STOCK_ADJUSTMENT:
        let findStockAdjustmentIndex = newState.findIndex(
          (state) => state.module === moduleName
        );
        if (findStockAdjustmentIndex > -1) {
          return newState[findStockAdjustmentIndex];
        }
        return;
      default:
        break;
    }
  };

  return (
    <div className="column parent-width hide-scroll-bar overflow-y-auto pt-r">
      <SettingsDetailCard className="mb-xl">
        <div className="column parent-width">
          <DKLabel text="QC Flow Module Visibility" className="fw-m" />
          <LabelWithToggle
            title="Goods Receipt"
            toggleState={
              getModuleToggleStatus(QC_FLOW_MODULES.GOODS_RECEIPT).enable ??
              false
            }
            toggleCallback={() => onModuleChange(QC_FLOW_MODULES.GOODS_RECEIPT)}
            className="p-v-xs"
          />
          <LabelWithToggle
            title="Fulfillment"
            toggleState={
              getModuleToggleStatus(QC_FLOW_MODULES.FULFILLMENT).enable ?? false
            }
            toggleCallback={() => onModuleChange(QC_FLOW_MODULES.FULFILLMENT)}
            className="p-v-xs"
          />
          <LabelWithToggle
            title="Stock Adjustment"
            toggleState={
              getModuleToggleStatus(QC_FLOW_MODULES.STOCK_ADJUSTMENT).enable ??
              false
            }
            toggleCallback={() =>
              onModuleChange(QC_FLOW_MODULES.STOCK_ADJUSTMENT)
            }
            className="p-v-xs"
          />
        </div>
      </SettingsDetailCard>
    </div>
  );
};

export default QCFlow;
