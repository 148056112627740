import {
  DOC_TYPE,
  POPUP_CLICK_TYPE,
  TRACKING_TYPE
} from '../../Constants/Constant';
import FulfillmentService from '../../Services/FulfillmentService';
import PaymentService from '../../Services/Payment';
import Utility from '../../Utility/Utility';

export const fetchInvoiceFulfillmentRecords = (
  invoiceDetails: any,
  docType: any
) => {
  const invoiceCode: string = invoiceDetails.salesInvoiceCode;
  FulfillmentService.getFulfillmentRecords(invoiceCode, docType)
    .then(
      (data: any) => {
        let filteredData: any = data?.filter(
          (ele: any) => ele.documentType === docType
        );
        if (Utility.isEmpty(filteredData)) {
          filteredData = data;
        }
        return filteredData;
      },
      (err) => {
        console.error('Error getting fulfillment records: ', err);
      }
    )
    .catch((err) => {
      console.error('Error getting fulfillment records: ', err);
    });
};

export const getTotalQuantity = (itemsDescriptionArray: any) => {
  let totalFulfillment = 0;
  itemsDescriptionArray.forEach((item: any) => {
    totalFulfillment += item.documentUOMSchemaDefinition
      ? item['uomQuantity']
      : item['productQuantity'];
  });
  return totalFulfillment;
};

export const getFulfillmentQuantity = (fulfillmentItems = []) => {
  return fulfillmentItems.reduce(
    (a, b) =>
      (a += b['documentUOMSchemaDefinition']
        ? b['uomFulfilledQuantity']
        : b['fulfilledQuantity']),
    0
  );
};

export const getReturnedQuantity = (salesReturnItems = []) => {
  return salesReturnItems.reduce(
    (a, b) =>
      (a +=
        b['documentUOMSchemaDefinition'] &&
        b['advancedTrackingType'] !== TRACKING_TYPE.SERIAL
          ? Utility.getUomQuantity(
              b['quantityToReturn'],
              b['documentUOMSchemaDefinition']
            )
          : b['quantityToReturn']),
    0
  );
};

export const getReceivedGoodsQuantity = (receivedGoodsItems = []) => {
  return receivedGoodsItems.reduce(
    (a, b) =>
      (a += b['documentUOMSchemaDefinition']
        ? b['uomReceiptQuantity']
        : b['receiptQuantity']),
    0
  );
};

export const getDocumentNumber = (record: any) => {
  switch (record.documentType) {
    case 'CREDIT_NOTE':
    case 'DEBIT_NOTE':
      return record.linkedDocumentSequence;
    case 'RECEIVE_PAYMENT':
    case 'MAKE_PAYMENT':
    case 'DEPOSIT_ADVPAYMENT':
    case 'EXPENSE_PREPAYMENT':
      return record.documentCode;
    case 'SALES_INVOICE':
    case 'PURCHASE_INVOICE':
      return record.linkedDocumentSequence;
    default:
      return '';
  }
};

export const shouldShowPrint = (record: any) => {
  switch (record.documentType) {
    case 'RECEIVE_PAYMENT':
    case 'MAKE_PAYMENT':
    case 'DEPOSIT_ADVPAYMENT':
    case 'EXPENSE_PREPAYMENT':
      return true;
    default:
      return false;
  }
};
