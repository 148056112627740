import { FC, useEffect, useState } from 'react';
import {
  DKButton,
  DKLabel,
  DKInput,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import {
  ORG_NATURE,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../../../Constants/Constant';
import { ReactSelectOptionsType } from '../../../Models/Interfaces';
import { useDispatch } from 'react-redux';
import TenantService from '../../../Services/Tenant';
import AppManager from '../../../Managers/AppManager';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import { Information } from '../../../Models/PaymentPopup';
import { DropdownTag } from '../../../Models/NewContact';
import CoaService, { CoaAPIConfig } from '../../../Services/COA';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import { useAppSelector } from '../../../Redux/Hooks';
import { selectCurrency } from '../../../Redux/Slices/CommonDataSlice';
import Utility from '../../../Utility/Utility';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import ApiConstants from '../../../Constants/ApiConstants';

const orgNatureOptions = Object.keys(ORG_NATURE).map((type) => ({
  label: ORG_NATURE[type],
  value: type
}));

interface InitialFormState {
  primaryCurrencyCode: DropdownTag<string>;
  organizationNature: DropdownTag<string>;
  parentOrganization: DropdownTag<number>;
  revaluationAccount: DropdownTag<string>;
}

const initialFormState: InitialFormState = {
  primaryCurrencyCode: {
    key: '',
    hidden: false,
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN,
    hasError: false,
    isMandatory: true
  },
  organizationNature: {
    key: '',
    hidden: false,
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN,
    hasError: false,
    isMandatory: true
  },
  parentOrganization: {
    key: 0,
    hidden: false,
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN,
    hasError: false,
    isMandatory: false
  },
  revaluationAccount: {
    key: '',
    hidden: false,
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN,
    hasError: false,
    isMandatory: false
  }
};

interface MultiCompanySettingsPayload {
  additionalSettings: {
    MULTI_COMPANY: {
      primaryCurrencyCode?: string;
      organizationNature?: string;
      parentOrganization?: number;
      revaluationAccount?: string;
    };
  };
}

const MultiCompanySettings: FC<any> = (props) => {
  const [formData, setFormData] = useState({ ...initialFormState });
  const [currencyOptions, setCurrencyOptions] = useState<any[]>([]);
  type ReactSelectType = ReactSelectOptionsType<string, string>;
  const dispatch = useDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);
  const currenciesList: any[] = useAppSelector(selectCurrency);

  const [isSaving, setIsSaving] = useState(false);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    TenantService.getParentTenants()
      .then((res: any[]) => {
        let options = res
          .filter((tenant: any) => tenant.id !== tenantDetails?.id)
          .map<ReactSelectType>((tenant: any) => ({
            label: tenant.name,
            value: tenant.id
          }));
        let updatedFormData = { ...formData };
        updatedFormData.parentOrganization.options = [...options];
        setFormData({ ...updatedFormData });
      })
      .catch((err) => {
        console.log(err);
      });

    if (!Utility.isEmpty(tenantDetails?.additionalSettings?.MULTI_COMPANY)) {
      const defaultSettings = tenantDetails?.additionalSettings?.MULTI_COMPANY;
      const config: CoaAPIConfig = {
        Page: 0,
        Limit: 10,
        Query: `status=ACTIVE,code=${defaultSettings?.revaluationAccount}`
      };
      CoaService.apiConfig = config;
    }

    CoaService.getCOA()
      .then((res: any) => {
        let options = res?.content?.map((account: any) => ({
          label: account.name,
          value: account.code
        }));
        let updatedFormData = { ...formData };
        updatedFormData.revaluationAccount.options = options;
        setFormData({ ...updatedFormData });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(currenciesList)) {
      const currencyOpts = currenciesList.map((currency: any) => ({
        label: currency.currencyCode,
        value: currency.currencyCode
      }));
      setCurrencyOptions(currencyOpts);
    }
  }, [currenciesList]);

  useEffect(() => {
    if (tenantDetails?.additionalSettings?.MULTI_COMPANY) {
      let updatedFormData = { ...formData };
      let defaultSettings = tenantDetails?.additionalSettings?.MULTI_COMPANY;
      if (defaultSettings?.primaryCurrencyCode) {
        updatedFormData.primaryCurrencyCode.value = {
          label: defaultSettings?.primaryCurrencyCode,
          value: defaultSettings?.primaryCurrencyCode
        };
      }
      if (defaultSettings?.organizationNature) {
        updatedFormData.organizationNature.value = {
          label:
            defaultSettings?.organizationNature === 'PARENT'
              ? 'Parent'
              : 'Subsidiary',
          value: defaultSettings?.organizationNature
        };
      }
      if (defaultSettings?.parentOrganization) {
        let parentOrg = updatedFormData.parentOrganization.options?.find(
          (tenant: any) => tenant.value === defaultSettings?.parentOrganization
        );
        if (parentOrg) {
          updatedFormData.parentOrganization.value = parentOrg;
        }
      }
      if (defaultSettings?.revaluationAccount) {
        let revaluationAccount =
          updatedFormData.revaluationAccount.options?.find(
            (account: any) =>
              account.value === defaultSettings?.revaluationAccount
          );
        if (revaluationAccount) {
          updatedFormData.revaluationAccount.value = revaluationAccount;
        }
      }
      setFormData({ ...updatedFormData });
    }
  }, [
    tenantDetails,
    formData.parentOrganization.options,
    formData.revaluationAccount.options
  ]);

  const grabCurrencyInfo = (data: Information) => {
    const option: ReactSelectType = {
      label: data.selectedCurrencyName,
      value: data.selectedCurrencyCode
    };
    let updatedState = { ...formData };
    updatedState.primaryCurrencyCode.value = option;
    setFormData({ ...updatedState });
  };

  const saveSettings = async () => {
    setIsSaving(true);
    const payload: MultiCompanySettingsPayload = {
      additionalSettings: {
        MULTI_COMPANY: {
          primaryCurrencyCode: formData.primaryCurrencyCode.value?.value,
          organizationNature: formData.organizationNature.value?.value,
          revaluationAccount: formData.revaluationAccount.value?.value
        }
      }
    };

    if (!formData.primaryCurrencyCode.value?.value) {
      setIsSaving(false);
      showAlert('Invalid Form Input', 'Select primary currency code');
      return;
    } else if (!formData.organizationNature.value?.value) {
      setIsSaving(false);
      showAlert('Invalid Form Input', 'Select organization nature');
      return;
    } else if (!formData.revaluationAccount.value?.value) {
      setIsSaving(false);
      showAlert('Invalid Form Input', 'Select revaluation account');
      return;
    }

    if (formData.organizationNature.value.value === 'SUBSIDIARY') {
      if (!formData.parentOrganization.value?.value) {
        setIsSaving(false);
        showAlert('Invalid Form Input', 'Select parent organization');
        return;
      } else {
        payload.additionalSettings.MULTI_COMPANY.parentOrganization = Number(
          formData.parentOrganization.value.value
        );
      }
    }

    try {
      await TenantService.updateOrgSetting(payload);
      await dispatch(fetchCurrentTenantInfo());
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
    }
  };

  return (
    <div
      className="column parent-size pt-r pb-l overflow-y-auto hide-scroll-bar flex-1"
      style={{ gap: 20 }}
    >
      <div
        className={`row ${isDesktop ? '' : 'flex-wrap'}`}
        style={{
          gap: 14
        }}
      >
        <div className="column parent-width" style={{ minWidth: 210 }}>
          <DKInput
            title={'Primary Currency Code'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={formData.primaryCurrencyCode?.value}
            renderer={(obj: any) => {
              return (
                <div className="row currency-dropdown-flag">
                  {obj.label && (
                    <div
                      className={`flag ${obj.label}`}
                      style={{ width: 24 }}
                    />
                  )}
                  <DKLabel
                    text={`${obj.label}`}
                    className="ml-r"
                    style={{ minWidth: 40 }}
                  />
                </div>
              );
            }}
            required={true}
            onChange={(value: any) => {
              let updatedFormData = { ...formData };
              updatedFormData.primaryCurrencyCode.value = value;
              setFormData(updatedFormData);
            }}
            dropdownConfig={{
              data: currencyOptions,
              allowSearch: true,
              searchableKey: 'label',
              renderer: (index: any, obj: any) => {
                return (
                  <div className="row currency-dropdown-flag">
                    <div
                      className={`flag ${obj.label}`}
                      style={{ width: 24 }}
                    />
                    <DKLabel
                      text={`${obj.label}`}
                      className="ml-s"
                      style={{ minWidth: 30 }}
                    />
                  </div>
                );
              }
            }}
          />
        </div>
        <div className="column parent-width" style={{ minWidth: 210 }}>
          <DKInput
            title={'Organization Nature'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={formData.organizationNature?.value}
            formatter={(value: any) => value?.label}
            required={true}
            onChange={(value: any) => {
              let updatedFormData = { ...formData };
              updatedFormData.organizationNature.value = value;
              setFormData(updatedFormData);
            }}
            dropdownConfig={{
              data: orgNatureOptions,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.label}`} />;
              }
            }}
          />
        </div>
      </div>
      <div
        className={`row ${isDesktop ? '' : 'flex-wrap'}`}
        style={{
          gap: 14
        }}
      >
        <div className="column parent-width" style={{ minWidth: 210 }}>
          <DKInput
            title={'Revaluation Account'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={formData.revaluationAccount.value}
            formatter={(value: any) => value.label}
            required={true}
            onChange={(value: any) => {
              let updatedFormData = { ...formData };
              updatedFormData.revaluationAccount.value = value;
              setFormData(updatedFormData);
            }}
            dropdownConfig={{
              data: formData.revaluationAccount.options,
              allowSearch: true,
              searchableKey: 'label',
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.label}`} />;
              },
              searchApiConfig: {
                getUrl: (searchValue: string) => {
                  const config: AccountAPIConfig = {
                    Page: 0,
                    SearchTerm: searchValue,
                    Limit: 10,
                    AccountGroupsString: '',
                    Query: 'status=ACTIVE'
                  };
                  AccountsService.apiConfig = config;
                  return (
                    ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
                  );
                },
                dataParser: (response: any) => {
                  let options = response?.content?.map((account: any) => ({
                    label: account.name,
                    value: account.code
                  }));
                  return options;
                },
                debounceTime: 300
              }
            }}
          />
        </div>
        <div className="column parent-width" style={{ minWidth: 210 }}>
          {formData.organizationNature?.value?.value === 'SUBSIDIARY' && (
            <DKInput
              title={'Parent Organization'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DROPDOWN}
              value={formData.parentOrganization?.value}
              formatter={(value: any) => value.label}
              required={true}
              onChange={(value: any) => {
                let updatedFormData = { ...formData };
                updatedFormData.parentOrganization.value = value;
                setFormData(updatedFormData);
              }}
              dropdownConfig={{
                data: formData.parentOrganization.options,
                allowSearch: true,
                searchableKey: 'label',
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={`${obj.label}`} />;
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="column mt-r">
        <div
          className={`row width-auto ${
            isSaving ? 'border-radius-m border-m' : ''
          }`}
        >
          <DKButton
            title={isSaving ? 'Saving...' : 'Save'}
            onClick={() => {
              saveSettings();
            }}
            disabled={isSaving}
            className={`${
              isSaving ? 'border-radius-none text-gray' : 'bg-button text-white'
            }`}
          />
          {isSaving && (
            <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiCompanySettings;
