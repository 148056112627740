import { PRINT_SCALE, US_CHEQUE_HEIGHT } from "../../Constants/Constants"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { HTMLTag } from "../../Utilities/HTMLTag"
import Utility, { getIsBooksApp, getVW, getVwToPx } from "../../Utilities/Utility"
import LineItemsParser from "../LineItemsParser"
import TemplateParser from "./TemplateParser"

export const ChequeDetailType = {
    date: 'date',
    payTo: 'payTo',
    amount: 'amount',
    amountInWords: 'amountInWords',
    memo: 'memo',
    contactAddress: 'contactAddress'
}

export default class ChequeParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        //line items
        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            //getDefault date format
            if (dataFromAPI.chequePayload !== undefined && dataFromAPI.chequePayload !== null) {
                if(dataFromAPI.chequePayload.length > 0) {
                    templateDataToReturn.dateFormat = dataFromAPI.chequePayload[0].dateFormat.toLowerCase()
                }
            }
        }

        if (dataFromAPI.chequePayload !== undefined && dataFromAPI.chequePayload !== null) {
            if (dataFromAPI.chequePayload.length > 0) {
                if (dataFromAPI.chequePayload[0].currencySymbol !== undefined && dataFromAPI.chequePayload[0].currencySymbol !== null) {
                    templateDataToReturn.currency = dataFromAPI.chequePayload[0].currencySymbol
                    templateDataToReturn.currencyName = dataFromAPI.chequePayload[0].currency
                }
                else {
                    templateDataToReturn.currencyName = dataFromAPI.chequePayload[0].currency
                }
                templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)
            }
        }

        templateDataToReturn.lineItems = LineItemsParser.getChequeItem(dataFromAPI, templateDataToReturn.dateFormat)
        templateDataToReturn.documentType = this.passCustomTextForUS(templateDataToReturn)
        return templateDataToReturn
    }

    static passCustomTextForUS(templateDataToReturn) {
        var documentType = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== undefined && TemplateSettingsManager.defaultTemplateSettings.documentInfo.type !== null) {
            var type = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type
            if (type.labelText !== undefined && type.labelText !== null) {
                documentType = type.labelText
            }
        }

        if (documentType === undefined) {
            if (templateDataToReturn.country !== undefined && templateDataToReturn.country !== null) {
                if (templateDataToReturn.country.toLowerCase() === 'us') {
                    documentType = 'Check'
                }
            }
            else {
                documentType = templateDataToReturn.documentType
            }
        }

        return documentType
    }

    static getCheckFieldVisibilityDetails(fields, field) {
        if (fields) {
            return fields.find(f => f.code === field)
        } else {
            return null;
        }
    }

    static getRelativePositionInPerc(position, defaultPosition) {
        const chequeDesigner = document.getElementById(HTMLTag.CHEQUE_DESIGNER);
        if (chequeDesigner) {
            const { width } = chequeDesigner.getBoundingClientRect();
            const { x, y } = position;

            return {
                x: ((x * 100) / width),
                y: ((y * 100) / getVwToPx(getVW(US_CHEQUE_HEIGHT).replace("vw", "")))
            };
        }

        return defaultPosition
    }

    static getRelativePositionInPX(position, defaultPosition, printScale = PRINT_SCALE) {
        const chequeDesigner = document.getElementById(HTMLTag.CHEQUE_DESIGNER);
        if (chequeDesigner) {
            const { width } = chequeDesigner.getBoundingClientRect();
            const { x, y } = position;

            if (getIsBooksApp()) {
                return {
                    x: Math.floor(x * (width / 100)),
                    y: Math.floor(Utility.convertToPrintScale(y * (getVwToPx(getVW(US_CHEQUE_HEIGHT).replace("vw", "")) / 100), printScale))
                };
            } else {
                return {
                    x: Math.floor(x * (width / 100)),
                    y: Math.floor(y * (getVwToPx(getVW(US_CHEQUE_HEIGHT).replace("vw", "")) / 100))
                };
            }
        }

        return defaultPosition
    }

    static parseChequeFields(chequeData) {
        if (!Array.isArray(chequeData)) {
            return [];
        }

        const chequeFields = [];

        chequeData.forEach((cheque, index) => {
            const defaultFields = TemplateSettingsManager.getChequeDetailsList();
            const defaultDetails = TemplateSettingsManager.getChequeDetailsConfig();

            const parsedFields = defaultFields.map((field) => {
                return { ...field };
            });

            const parsedDetails = defaultDetails.map((detail,index) => {
                return { ...detail,table: `table${index}` };
            });

            chequeFields.push({
                id: `CHEQUE_${++index}`,
                data: {
                    ...cheque,
                    contactAddress: ChequeParser.getContactAddress(cheque)
                },
                fields: parsedFields,
                details: parsedDetails
            });
        });

        return chequeFields;
    }

    static getDefaultChequeDetail() {
        const chequeDetails = [
            {
                index: 0,
                type: ChequeDetailType.date,
                code: "date",
                isSelected: true,
                defaultPosition: { x: 90.4847364095339, y: 10.2272727273 },
                position: { x: 90.4847364095339, y: 10.2272727273 }
            },
            {
                index: 1,
                type: ChequeDetailType.payTo,
                code: "payTo",
                isSelected: true,
                defaultPosition: { x: 7.43866384877304, y: 26.59090909090909 },
                position: { x: 7.43866384877304, y: 26.59090909090909 }
            },
            {
                index: 2,
                type: ChequeDetailType.amount,
                code: "amount",
                isSelected: true,
                defaultPosition: { x: 89.6272278971469, y: 26.59090909090909 },
                position: { x: 89.6272278971469, y: 26.59090909090909 }
            },
            {
                index: 3,
                type: ChequeDetailType.amountInWords,
                code: "amountInWords",
                isSelected: true,
                defaultPosition: { x: 0.581789362451567, y: 37.52727272727273 },
                position: { x: 0.581789362451567, y: 37.52727272727273 }
            },
            {
                index: 4,
                type: ChequeDetailType.contactAddress,
                code: "contactAddress",
                isSelected: false,
                defaultPosition: { x: 6.4854361865, y: 48.02727272727273 },
                position: { x: 6.4854361865, y: 48.02727272727273 }
            },
            {
                index: 5,
                type: ChequeDetailType.memo,
                code: "notes",
                isSelected: true,
                defaultPosition: { x: 4.56936597393448, y: 73.4090909090909 },
                position: { x: 4.56936597393448, y: 73.4090909090909 }
            }
        ];

        return ChequeParser.parseChequeLabel(chequeDetails);
    }

    static getDefaultChequeDetailConfig() {
        return [
            {
                id: "first_copy",
                defaultPosition: { x: 0, y: 26 },
                position: { x: 0, y: 26 }
            },
            {
                id: "second_copy",
                defaultPosition: { x: 0, y: 145 },
                position: { x: 0, y: 145 }
            }
        ];
    }

    static parseChequeLabel(chequeDetails) {
        var chequeLabels = ChequeParser.getChequeLabels()
        if (chequeDetails.length > 0) {
            if (chequeLabels !== undefined) {
                chequeDetails.forEach(element => {
                    var label = chequeLabels.find(x => x.type === element.type)
                    if (label !== undefined) {
                        element.label = label.label
                    }
                });
                return chequeDetails
            }
        }

        return []
    }

    static getChequeLabels() {
        return [
            { type: ChequeDetailType.date, label: getLocalisedText("date") },
            { type: ChequeDetailType.payTo, label: getLocalisedText("pay_to") },
            { type: ChequeDetailType.amount, label: getLocalisedText("amount") },
            { type: ChequeDetailType.amountInWords, label: getLocalisedText("amount_in_words") },
            { type: ChequeDetailType.memo, label: getLocalisedText('memo') },
            { type: ChequeDetailType.contactAddress, label: getLocalisedText('contact_address') }
        ];
    }

    static getContactAddress(cheque) {
        let contactAddress = "";

        const addressObj = cheque?.contactInfo?.billingAddress;
        if (addressObj) {
            contactAddress = {
                ...addressObj
            }
        }

        return contactAddress;
    }

    static getFormatedFieldValue(fields, fieldName, format, value) {
        if (!Array.isArray(fields)) {
            return "";
        }

        const field = ChequeParser.getCheckFieldVisibilityDetails(fields, fieldName);
        if (!field || !field.isSelected) {
            return "";
        }

        switch (format) {
            case "name":
                return field.name;
            case "value":
                return value ?? "";
            case "colonSeparator":
                return `${field.name}: ${value ?? ""}`;
            case "roundBracket":
                return `(${field.name}: ${value ?? ""})`;
            default:
                return `${field.name} ${value ?? ""}`;
        }
    }
}
