import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JC_VIEW_INDEX } from '../../../Components/Mrp/Constants/MRPColumnConfigs';
import { API_STATUS } from '../../../Constants/Constant';
import JobCardService from '../../../Services/MRP/JobCard';
import { RootState } from '../../Store';

interface JobCardState {
  data: any;
  columnConfig: any;
  status: API_STATUS;
  jobView: string;
}

const initialState: JobCardState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  jobView: JC_VIEW_INDEX.KANBAN
};

export const fetchJobCardsList = createAsyncThunk('fetchJobCards', async () => {
  const response = await JobCardService.fetchAllJobCards();
  return response;
});

export const JobCardSlice = createSlice({
  name: 'JobCard',
  initialState,
  reducers: {
    updateJobCardView: (state, action) => {
      state.jobView = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobCardsList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchJobCardsList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { updateJobCardView } = JobCardSlice.actions;

export const selectJobCards = (state: RootState) => state.mrpJobCard.data;
export const selectJobCardView = (state: RootState) => state.mrpJobCard.jobView;
