import {
  DKDataGrid,
  DKLabel,
  DKIcons,
  INPUT_TYPE,
  shiftArrayElement,
  DKIcon,
  DKButton
} from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REMOTE_CONFIG_TABLES,
  TABLES,
  TABLE_DISPLAY_NAME
} from '../Constants/TableConstants';
import { TableManager } from '../Managers/TableManager';
import {
  GridHeaderButtonConfig,
  AddRecordButtonConfig,
  ColumnDeleteType,
  IColumn
} from '../Models/Table';
import {
  activeTenantInfo,
  fetchUserPreferences,
  updateUserPreferences
} from '../Redux/Slices/AuthSlice';
import SideBarService from '../Services/sidebar';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../Utility/Utility';
import ContextMenu from './ContextMenu';
import useConfirm from '../Hooks/useConfirm';
import { GOOGLE_NO_TRANSLATE_CLASS, STATUS_TYPE } from '../Constants/Constant';
import { CONTACT_TRANSACTION_TYPE } from '../Constants/Enum';
import ic_no_data from '../Assets/Icons/ic_no_data.png';
import { GranularPermissionsHelper } from '../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../Constants/Permission';
import { isTabletView } from '../Utility/ViewportSizeUtils';
import GridColumnVisibility, {
  COLUMN_VISIBILITY_BUTTON_CONFIG
} from './GridColumnVisibility';
import { DraftTypes } from '../Models/Drafts';
import TenantService from '../Services/Tenant';
import { localizedText } from '../Services/Localization/Localization';

export interface IDataGridHolderProps {
  tableName: string;
  displayTableName?: string;
  filterTableName?: string;
  gridIcon?: string; // emoji for grid title
  actionColumnWidth?: number;
  filter?: any;
  allowRowEdit?: boolean;
  allowColumnEdit?: boolean;
  allowRowAdd?: boolean;
  allowColumnAdd?: boolean;
  needNoDataView?: boolean;
  allowColumnDelete?: boolean;
  allowColumnShift?: boolean;
  allowSearch?: boolean;
  allowFilter?: boolean;
  allowDataExport?: boolean;
  contextMenu?: any[];
  dateFormat?: string;
  onRowUpdate?: (data: any) => void;
  onRowSelect?: (data: any) => void;
  onAllRowSelect?: (data: any) => void;
  onColumnUpdate?: (data: any) => void;
  onColumnShift?: (data: any) => void;
  onRowAdd?: (data: any) => void;
  onRowClick?: (rowIndex: any, rowData: any) => void;
  onColumnAdd?: (data: any) => void;
  onColumnAddClick?: (data: any) => void;
  onBackTapped?: (data: any) => void;
  onColumnDelete?: (data: ColumnDeleteType) => void;
  onSearch?: (searchTerm: any) => void;
  onRowOpenClick?: any;
  onFilter?: (updatedData: any[], config: any[]) => void;
  onRowDelete?: (data: any) => void;
  onRowEdit?: (data: any) => void;
  onRowCopy?: (data: any) => void;
  onPaginationClick?: (requestedPageNumber: any) => void;
  addButtonConfig?: AddRecordButtonConfig;
  refresh?: boolean;
  updating?: boolean;
  currentPage?: number;
  totalPageCount?: number;
  gridData: {
    rowData: any[];
    columnData: IColumn[];
    filterColumns?: IColumn[];
    filter?: any[];
    originalData?: any;
  };
  width?: string | number;
  extraWidth?: number;
  styles?: {
    mainGridHolder?: CSSProperties;
    gridScrollHolder?: CSSProperties;
    shadowHolder?: CSSProperties;
  };
  onPayment?: (data: any) => void;
  onFulfillment?: (data: any) => void;
  fulfillmentRecords?: (data: any) => void;
  onReceivedGoods?: (data: any) => void;
  onBulkPoConvert?: (data: any) => void;
  receivedGoodsRecords?: (data: any) => void;
  viewSource?: (data: any) => void;
  onCopyContact?: (data: any) => void;
  paymentRecords?: (data: any) => void;
  offsetPayment?: (data: any) => void;
  onBulkFulfillment?: (data: any) => void;
  onBulkConsolidate?: (data: any) => void;
  onBulkConvertWO?: (data: any) => void;
  onApplyClick?: (data: any) => void;
  onBulkReceivedGoods?: (data: any) => void;
  showHeader?: boolean;
  headerButtons?: GridHeaderButtonConfig[];
  bulkPaymentRows?: any;
  bulkPendingEInvoicesRows?: any;
  bulkGeneratedEInvoicesRows?: any;
  bulkReceivedGoodsRows?: any;
  bulkFulfillmentRows?: any;
  bulkPrintRows?: any;
  onBulkPayment?: (data: any) => void;
  onBulkPrint?: (data: any) => void;
  showContextMenu?: boolean;
  allowBulkOperation?: boolean;
  needShadow?: boolean;
  invalidFields?: any[];
  allowColumnSort?: boolean;
  allowColumnVisibilityToggle?: boolean;
  showSelectedRecordsCount?: boolean;
  onDeactivateBtnClicked?: (data: any[]) => void;
  onBulkDelete?: (data: any[]) => void;
  onSort?: (data: any[]) => void;
  needTrailingColumn?: boolean;
  bulkApprovalRows?: any;
  onBulkApproval?: (data: any,remarks?:string) => void;
  bulkRejectRows?: any;
  consolidateRows?: any;

  onBulkEWBGenerate?: () => void;
  onBulkPendingEInvoice?: (data: any) => void;
  onBulkGeneratedEInvoice?: (data: any) => void;
  bulkEWBRows?: any;
  jobCardRows?: any[];
  onBulkJCStatusChange?: (data: any) => void;
  onBulkReject?: (data: any,remarks:any) => void;
  bulkPrintChecksRows?: any;
  onBulkPrintChecks?: (data: any) => void;
  onDocTransactionClick?: (data: CONTACT_TRANSACTION_TYPE, row: any) => void;
  noDataTitle?: string;
  noDataText?: string;
  searchBarConfig?: any;
  permissionModule?: any;
  needBoldTheme?: boolean;
  needBorder?: boolean;
  expand?: boolean;
  rowExpand?: any;
  needBackButton?: any;
  generateBarcode?: () => void;
  barcodeRowData?: any[];
  dontUsePageWidth?: any; // This prop is used only for rendering grids on the new settings Popup.
  onBulkSendEmail?: (data: any) => void;
  showSendEmail?: {
    btnText: string;
    className: string;
    showEmailBtn: boolean;
    hoverTitle: string;
  };
  showAllowColumnVisibilityToggleButton?: boolean;
  onToleranceApproval?: (data: any, status: string) => void;
  onToleranceReject?: (data: any, status: string) => void;
  displayBulkGenerateBankFile?: boolean;
  bulkGenerateBankFile?: (data: any[]) => void;
  displayBulkUnlinkBankFile?: boolean
  bulkUnlinkBankFile?: (data: any[]) => void;
}
export interface IDataGridHolderState {
  tableName: string;
  filterTableName: string;
  columns: IColumn[];
  rows: any[];
  currentPage: number;
  totalPageCount: number;
  showContextMenu: boolean;
  needNoDataView: boolean;
  width: any;
  filter: any[];
  originalData: any[];
  isAllRowSelected: boolean;
  invalidFields: any[];
  allowColumnSort: boolean;
  showAllowColumnVisibilityToggleButton: boolean;
}

const DataGridHolder: React.FC<IDataGridHolderProps> = (props) => {
  let [selection, setSelection] = useState<any[]>([]);
  const initialState: IDataGridHolderState = {
    tableName: '',
    filterTableName: '',
    columns: [],
    rows: [],
    currentPage: 1,
    totalPageCount: 1,
    showContextMenu: selection && selection.length > 0 ? true : false,
    needNoDataView:
      typeof props.needNoDataView === 'boolean'
        ? props.needNoDataView
        : props.gridData.rowData.length
        ? false
        : true,
    width: props.width
      ? props.width
      : SideBarService.getContainerWidth() - (props?.extraWidth || 0),
    filter: [],
    originalData: [],
    isAllRowSelected: false,
    invalidFields: [],
    allowColumnSort:
      typeof props.allowColumnSort !== 'undefined' &&
      props.allowColumnSort !== null
        ? props.allowColumnSort
        : true,
    showAllowColumnVisibilityToggleButton: true
  };
  const [state, setState] = useState<IDataGridHolderState>(initialState);

  const [docType, setDocType] = useState('');
  const { t } = useTranslation();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const { confirm } = useConfirm();
  const [showDeactivateBtnData, setShowDeactivateBtnData] = useState({
    btnText: '',
    className: '',
    showBtn: false,
    showDeleteBtn: false,
    showDocumentTransactionBtn: false
  });
  const [columnUpdating, setColumnUpdating] = useState(false);
  const columnDisplayPreferenceTableName = (
    props.filterTableName || props.tableName
  )?.toUpperCase();
  const hiddenColumnIds: string[] = useAppSelector(
    (state) =>
      state.authInfo.userPreferences.data?.hiddenColumns?.[
        columnDisplayPreferenceTableName
      ] || []
  );
  /** Keep this as true to allow column freeze for all Modules at once */
  const allowedColumnFreeze = true;
  const freezedColumns =
    useAppSelector(
      (state) => state.authInfo.userPreferences.data?.freezedColumns
    ) || {};
  const moduleFreezedColumns =
    freezedColumns[columnDisplayPreferenceTableName] || [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    const windowSizeUpdated = () => {
      if (props.dontUsePageWidth) {
        return;
      } else {
        setState((prevState) => ({
          ...prevState,
          width: props.extraWidth
            ? SideBarService.getContainerWidth() - props.extraWidth
            : SideBarService.getContainerWidth()
        }));
      }
    };
    window.addEventListener('onSideBarChange', windowSizeUpdated);
    return () => {
      window.removeEventListener('onSideBarChange', windowSizeUpdated);
    };
  }, []);

  useEffect(() => {
    let newState = {
      ...state,
      tableName: props.tableName,
      filterTableName: props.filterTableName ?? '',
      rows: props.gridData.rowData,
      columns: props.gridData.columnData,
      filter: props.filter,
      originalData: props.gridData.originalData,
      needNoDataView:
        typeof props.needNoDataView === 'boolean'
          ? props.needNoDataView
          : props.gridData.rowData.length
          ? false
          : true,
      currentPage: props.currentPage ?? 1,
      totalPageCount: props.totalPageCount ?? 1,
      invalidFields: props.invalidFields?.length ? props.invalidFields : [],
      allowColumnSort:
        typeof props.allowColumnSort !== 'undefined' &&
        props.allowColumnSort !== null
          ? props.allowColumnSort
          : true,
      showContextMenu:
        selection && selection.length > 0
          ? true
          : state.showContextMenu ?? false,
      //   showContextMenu: props.showContextMenu ?? state.showContextMenu ?? false,
      showAllowColumnVisibilityToggleButton:
        props.showAllowColumnVisibilityToggleButton ?? true
    };
    if (props?.width && props.dontUsePageWidth) {
      newState = { ...newState, width: props.width };
    }
    setState({ ...newState });
    return () => {
      setState(initialState);
    };
  }, [props]);

  useEffect(() => {
    if (props.tableName !== TABLES.INVOICE) {
      setSelection([]);
    } else {
      const selectionCount = selection?.length;
      let areAllElementsPresent: boolean = false;
      if (selectionCount >= state.rows.length) {
        let idList = props.gridData?.rowData?.map((element: any) => {
          return element.id;
        });
        let selectionIdList = selection?.map((element: any) => {
          return element.id;
        });
        if (
          idList &&
          idList.length > 0 &&
          selectionIdList &&
          selectionIdList.length > 0
        ) {
          areAllElementsPresent = idList.every((element: any) =>
            selectionIdList.includes(element)
          );
        }
      }

      setState((prevState) => ({
        ...prevState,
        showContextMenu: selectionCount !== 0,
        isAllRowSelected: selectionCount !== 0 && areAllElementsPresent
      }));
    }
  }, [props.gridData]);

  useEffect(() => {
    // showing deactivate button on contact screens
    if (props.tableName === TABLES.CONTACT) {
      const selectedRows = [...selection];
      let showDeactivateBtn = false;
      let contactStatus: string = '';
      for (let item of selectedRows) {
        if (!contactStatus) {
          contactStatus = item.status[0];
          showDeactivateBtn = true;
        } else if (item.status[0] !== contactStatus) {
          showDeactivateBtn = false;
          break;
        } else {
          showDeactivateBtn = true;
        }
      }

      const showDeleteBtn =
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.CONTACTS.DELETE
        ) &&
        showDeactivateBtn &&
        contactStatus === STATUS_TYPE.ACTIVE;
      const showDocumentTransactionBtn =
        contactStatus === STATUS_TYPE.ACTIVE && selectedRows.length === 1;
      contactStatus =
        contactStatus === STATUS_TYPE.ACTIVE ? 'Deactivate' : 'Activate';
      setShowDeactivateBtnData({
        showBtn: showDeactivateBtn,
        className:
          contactStatus === 'Activate'
            ? 'mr-r bg-green text-white'
            : 'mr-r bg-red text-white',
        btnText: contactStatus,
        showDeleteBtn: showDeleteBtn,
        showDocumentTransactionBtn
      });
    }

    // showing deactivate button on multi currency screens
    if (props.tableName === TABLES.CURRENCY) {
      const selectedRows = [...selection];
      let showDeactivateBtn = false;
      let currencyStatus: string = '';
      for (let item of selectedRows) {
        if (!currencyStatus) {
          currencyStatus = item.visibility[0];
          showDeactivateBtn = true;
        } else if (item.visibility[0] !== currencyStatus) {
          showDeactivateBtn = false;
          break;
        } else {
          showDeactivateBtn = true;
        }
      }
      currencyStatus =
        currencyStatus === STATUS_TYPE.active ? 'Inactive' : 'Active';
      setShowDeactivateBtnData({
        ...showDeactivateBtnData,
        showBtn: showDeactivateBtn,
        className:
          currencyStatus === 'Active'
            ? 'mr-r bg-green text-white'
            : 'mr-r bg-red text-white',
        btnText: currencyStatus
      });
    }

    const selectionCount = selection?.length;
    if (props.tableName !== TABLES.INVOICE) {
      setState((prevState) => ({
        ...prevState,
        showContextMenu: selectionCount !== 0,
        isAllRowSelected:
          selectionCount !== 0 && selectionCount === state.rows?.length
      }));
    } else {
      let areAllElementsPresent: boolean = false;
      if (selectionCount >= state.rows.length) {
        areAllElementsPresent = state.rows.every((element) =>
          selection.includes(element)
        );
      }

      setState((prevState) => ({
        ...prevState,
        showContextMenu: selectionCount !== 0,
        isAllRowSelected: selectionCount !== 0 && areAllElementsPresent
      }));
    }

    props.onRowSelect && props.onRowSelect(selection);
    return () => {
      // null
    };
  }, [selection]);

  const getNoDataView = () => {
    let noDataTitle = '';
    let noDataText = '';

    if (props.updating) {
      noDataTitle = 'Loading...';
      noDataText = 'Please wait';
    } else {
      if (!state.rows.length) {
        noDataTitle = props.noDataTitle ? props.noDataTitle : 'No data found';
        noDataText = props.noDataText
          ? props.noDataText
          : 'Once data is available, it will appear here';
      }
    }

    return state.rows.length ? null : (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute"
        style={{ top: 200, pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={noDataTitle} className="fw-m mt-l" />
        <DKLabel text={noDataText} className="text-gray mt-s" />
      </div>
    );
  };

  const getActionButtonsColumn = () => {
    let options: any[] = [];
    if (
      props.tableName !== TABLES.CONTACT &&
      props.tableName !== TABLES.PRODUCT &&
      props.tableName !== TABLES.BILL &&
      props.tableName !== TABLES.INVOICE &&
      props.tableName !== TABLES.QUOTATION &&
      props.tableName !== TABLES.SALES_ORDERS &&
      props.tableName !== TABLES.PURCHASE_ORDER &&
      props.tableName !== TABLES.DEBIT_NOTES &&
      props.tableName !== TABLES.PRICE_LIST &&
      props.tableName !== TABLES.BANK &&
      props.tableName !== TABLES.UOM &&
      props.tableName !== REMOTE_CONFIG_TABLES.BANK_DETAILS_DK &&
      props.tableName !== TABLES.TDS_RATES &&
      props.tableName !== TABLES.TDS_NATURE_OF_PAYMENT &&
      props.tableName !== TABLES.TDS_DEDUCTEE_TYPE &&
      props.tableName !== TABLES.CURRENCY &&
      props.tableName !== REMOTE_CONFIG_TABLES.BANK_DETAILS_STATEMENT &&
      props.tableName !== TABLES.VARIANT &&
      props.tableName !== REMOTE_CONFIG_TABLES.AUTOMATION &&
      props.tableName !== REMOTE_CONFIG_TABLES.CUSTOM_FIELDS_LIST &&
      props.tableName !== TABLES.FORM_1099_CONTRACTOR &&
      props.tableName !== TABLES.BOOKKEEPER_CLIENT &&
      props.tableName !== TABLES.BOOKKEEPER_TEAM &&
      props.tableName !== TABLES.BUDGETING &&
      props.tableName !== TABLES.SALES_TAX_RETURN &&
      props.tableName !== TABLES.OPERATIONS &&
      props.tableName !== TABLES.BOM_EXPLODE &&
      props.tableName !== TABLES.BOM_EXPLOSION &&
      props.tableName !== TABLES.BOM_EXPLOSION_SUB_GRID &&
      props.tableName !== TABLES.FORECASTING &&
      props.tableName !== TABLES.EWB_REPORT &&
      props.tableName !== TABLES.SALES_BY_CLASS &&
      props.tableName !== TABLES.MRP_REPORTS_YIELD_SUMMARY &&
      props.tableName !== TABLES.MRP_REPORTS_YIELD_DETAILED_SUMMARY &&
      props.tableName !== TABLES.MRP_REPORTS_DAILY_CONSUMPTION &&
      props.tableName !== TABLES.RGASSET &&
      props.tableName !== TABLES.REV_REC &&
      props.tableName !== TABLES.REV_REC_JE_LOGS &&
      props.tableName !== TABLES.OB_INVENTORY &&
      props.tableName !== REMOTE_CONFIG_TABLES.AMORTIZATION_SCHEDULE &&
      props.tableName !== TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING &&
      props.tableName !== TABLES.FULFILLMENT_APPROVAL_LIST &&
      props.tableName !== TABLES.SO_TO_WO_REPORT &&
      props.tableName !== TABLES.MILESTONE_REPORT &&
      props.tableName !== TABLES.ORDER_LEVELS_REPORT &&
      props.tableName !== TABLES.WHATSAPP_API_CONNECTIONS &&
      props.tableName !== TABLES.PAYMENT_LOGS &&
      props.tableName !== TABLES.UPLOAD_TO_FTP_LOGS &&
      props.tableName !== TABLES.PRODUCTS_BY_VENDOR
    ) {
      options = [
        ...options,
        {
          icon: DKIcons.ic_edit,
          className: 'p-v-0',
          onClick: (data: any) => {
            if (props.onRowEdit) {
              // if (
              //   RolePermissionUtil.checkModuleRolePermission(
              //     ModulePermissionEnum.BANK,
              //     ActionTypeEnum.WRITE
              //   )
              // )
              props.onRowEdit(data);
            }
          }
        },
        {
          icon: DKIcons.ic_delete,
          className: 'p-v-0',
          onClick: async (data: any) => {
            const rowData = [data.rowData];
            // if (
            //   RolePermissionUtil.checkModuleRolePermission(
            //     ModulePermissionEnum.BANK,
            //     ActionTypeEnum.WRITE
            //   )
            // )
            if (!Utility.isEmpty(rowData)) {
              const isConfirmed = await confirm(
                t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`)
              );

              if (isConfirmed) {
                getDeleteConfirmation(rowData);
              }
            }
          }
        }
      ];
    }

    if (props.tableName === REMOTE_CONFIG_TABLES.BANK_DETAILS_STATEMENT) {
      options.push({
        icon: DKIcons.ic_document,
        className: 'p-v-0',
        onClick: (data: any) => viewSource(data)
      });
    }

    if (props.tableName === TABLES.BANK_DETAILS) {
      options.push({
        icon: DKIcons.ic_document,
        className: 'p-v-0',
        onClick: (data: any) => viewSource(data)
      });
    }

    if (
      props.tableName === TABLES.EXPENSE ||
      props.tableName === TABLES.DEPOSIT
    ) {
      options.splice(1, 0, {
        icon: DKIcons.ic_copy,
        className: 'p-v-0',
        onClick: (data: any) => {
          if (props.onRowCopy) {
            props.onRowCopy(data);
          }
        }
      });
    }
    if (
      props.tableName === TABLES.FIXED_ASSET ||
      props.tableName === TABLES.ASSET_GROUP ||
      props.tableName === TABLES.DEPRECIATION_SCHEDULE
    ) {
      options.splice(0, 2);
    }
    if (props.tableName === TABLES.STOCK_TRANSFER) {
      options.splice(1, 1);
    }
    if (props.tableName === TABLES.STOCK_REQUEST) {
      options.splice(1, 1);
    }

    return {
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: props.actionColumnWidth ? props.actionColumnWidth : 150,
      options
    };
  };

  const viewSource = (data: any) => {
    if (props.viewSource) {
      props.viewSource(data);
    }
  };

  const getRecords = (
    search = '',
    payload = props.onFilter,
    refresh = false
  ): void => {
    updateSelection(true);
    /* let req = {
      pageNo: state.currentPage,
      pageSize: 10,
      q: search
    }; */
    let data = props.gridData;
    onGetRecordSuccess(data);
  };
  const onGetRecordSuccess = (res: any) => {
    if (res) {
      updateColumns();
      updateRows(res);
      setState({
        ...state,
        showContextMenu: false
      });
    }
  };
  const hideContextMenu = () => {
    setState({
      ...state,
      showContextMenu: false
    });
  };

  const updateRows = (data: any) => {
    setState({
      ...state,
      rows: data.rowData.map((item: any) => {
        return (item.selected = false);
      })
    });
    if (!state.rows.length) {
      setState({
        ...state,
        needNoDataView: state.needNoDataView
      });
    }
  };
  const updateColumns = () => {
    setState({
      ...state,
      columns: TableManager.getTableVisibleColumns(props.tableName)
    });
  };
  const onRowUpdate = (data: any) => {
    if (props.onRowUpdate) props.onRowUpdate(data);
  };
  const onColumnUpdate = (data: any) => {
    if (props.onColumnUpdate) {
      props.onColumnUpdate(data);
    }
  };
  const onColumnShift = (data: any) => {
    let { newIndex, oldIndex } = data;
    const allColumns: any[] = deepClone(props.gridData.columnData);
    const columnFromGrid = deepClone(getColumns());

    const oldIndexedColumn = columnFromGrid.find(
      (column, index) => index === oldIndex
    );
    const newIndexedColumn = columnFromGrid.find(
      (column, index) => index === newIndex
    );
    const oldIndexInAllColumns = allColumns.findIndex(
      (column, index) => oldIndexedColumn?.columnCode === column.columnCode
    );
    const newIndexInAllColumns = allColumns.findIndex(
      (column, index) => newIndexedColumn?.columnCode === column.columnCode
    );

    let newColumnArray = shiftArrayElement(
      allColumns,
      oldIndexInAllColumns,
      newIndexInAllColumns
    );

    if (props.onColumnShift) {
      props.onColumnShift(
        newColumnArray.filter((x: any) => Utility.isNotEmpty(x))
      );
    }
  };
  const onRowAdd = (data: any) => {
    const { rowData } = data;
    if (props.onRowAdd) {
      props.onRowAdd(rowData);
    }
  };

  const onColumnAdd = (data: any) => {
    if (props.onColumnAdd) {
      const columnData = data.columnData;
      const newColumn = {
        id: columnData.key,
        key: columnData.key,
        type: columnData.type,
        columnCode: columnData.key,
        editable: true,
        index: state.columns.length + 1,
        name: columnData.name,
        width: columnData.width,
        options: columnData.options,
        hidden: false,
        uiVisible: false
      };
      props.onColumnAdd(newColumn);
    }
  };
  const onColumnDelete = (data: ColumnDeleteType) => {
    if (props.onColumnDelete) {
      props.onColumnDelete(data);
    }
  };

  const onColumnFreezeToggle = async (data: {
    column: IColumn;
    freezed: boolean;
  }) => {
    if (!data?.column?.id) return;
    try {
      setColumnUpdating(true);
      let freezedColumnsByModule = moduleFreezedColumns || [];

      if (data.freezed) {
        freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
      } else {
        freezedColumnsByModule = freezedColumnsByModule.filter(
          (columnId: string) => columnId !== data.column.id
        );
      }

      const payload = {
        freezedColumns: {
          ...freezedColumns,
          [columnDisplayPreferenceTableName]: freezedColumnsByModule
        }
      };
      dispatch(updateUserPreferences(payload));

      await TenantService.updateUserPreferences(payload);
      dispatch(fetchUserPreferences());
    } catch (err) {
      console.error(
        'Failed to update user preferences for freezing column: ',
        err
      );
    } finally {
      setColumnUpdating(false);
    }
  };

  const onRowClick = (rowIndex: any, rowData: any) => {
    if (props.onRowClick) {
      props.onRowClick(rowIndex, rowData);
    }
  };
  const onPagination = (requestedPageNumber: number) => {
    if (props.onPaginationClick) {
      props.onPaginationClick(requestedPageNumber);
    }
  };

  const onSearch = (searchTerm: string) => {
    if (props.onSearch) {
      props.onSearch(searchTerm);
    }
  };
  const onRowOpenClick = (data: any, e: any) => {
    e?.stopPropagation();
    if (props.onRowOpenClick) {
      props.onRowOpenClick(data);
    }
  };
  const onFilter = (data: any) => {
    if (data && data.query) {
      const queries: any[] = data.query;
      let dataList = [...state.originalData];
      queries.forEach((query) => {
        let filteredDataList: any[] = [];
        // let filteredQuery: any[] = [];
        const { key, condition, value } = query;
        const tableName = state.filterTableName;
        const columnObj = TableManager.getColumnById(tableName, key);
        if (columnObj) {
          // Adding Column Type to Filter
          query.type = columnObj?.type;
          if (query && query.type && query.type === 'Date') {
            if (!(query.value instanceof Date)) {
              query.value = new Date();
            }
          }
          dataList.forEach((data) => {
            const newQuery = Array.isArray(data[columnObj.columnCode])
              ? data[columnObj.columnCode][0]
              : data[columnObj.columnCode];
            const newValue = Array.isArray(value) ? value[0] : value;
            if (condition === 'eq') {
              if (newQuery == newValue) {
                filteredDataList.push(data);
              }
            } else if (condition === 'gt' && typeof newQuery === 'number') {
              if (newQuery > newValue) {
                filteredDataList.push(data);
              }
            } else if (condition === 'lt' && typeof newQuery === 'number') {
              if (newQuery < newValue) {
                filteredDataList.push(data);
              }
            } else if (condition === 'c' && newQuery) {
              if (
                newQuery
                  .toString()
                  .toLowerCase()
                  .includes(newValue.toString().toLowerCase())
              ) {
                filteredDataList.push(data);
              }
            } else if (condition === 'sw' && newQuery) {
              const valueLength: number = newQuery.toString().length;
              const searchKey: string = newQuery
                .toString()
                .substring(0, Math.round(valueLength / 2));
              if (
                searchKey
                  .toLowerCase()
                  .includes(newValue.toString().toLowerCase())
              ) {
                filteredDataList.push(data);
              }
            } else if (condition === 'ew' && newQuery) {
              const valueLength: number = newQuery.toString().length;
              const searchKey: string = newQuery
                .toString()
                .substring(Math.round(valueLength / 2), valueLength);
              if (
                searchKey
                  .toLowerCase()
                  .includes(newValue.toString().toLowerCase())
              ) {
                filteredDataList.push(data);
              }
            }
          });
        }

        dataList = [...filteredDataList];
      });
      if (props.onFilter) {
        props.onFilter(dataList, queries);
      }
    }
  };

  const onRowSelect = (data: any = null) => {
    if (data) {
      const updatedRows = [...selection];
      const index = updatedRows.findIndex(
        (item) => item.id === data.rowData.id
      );
      if (index === -1) {
        updatedRows.push(data.rowData);
      } else {
        updatedRows.splice(index, 1);
      }
      setSelection([...updatedRows]);
      let draft = (updatedRows || []).find(
        (doc) => doc.isDraftDocument === true || doc.rowType === 'draft'
      );
      if (draft) {
        setDocType(DraftTypes.DRAFT);
      } else {
        setDocType(updatedRows[0]?.rowType || '');
      }
    }
    updateSelection(false);
  };

  const onAllRowSelect = (data: any = null) => {
    if (!data.selected) {
      if (props.tableName !== TABLES.INVOICE) {
        setSelection([]);
        setDocType('');
      } else {
        let idList = props.gridData?.rowData?.map((element: any) => {
          return element.id;
        });
        let selectionIdList = selection?.map((element: any) => {
          return element.id;
        });
        let tmpArrayList = [];
        if (
          idList &&
          idList.length > 0 &&
          selectionIdList &&
          selectionIdList.length > 0
        ) {
          tmpArrayList = selection.filter(
            (element: any) => !idList.includes(element.id)
          );
        }
        setSelection(tmpArrayList);
      }
    } else {
      setSelection((prevState) => [...prevState, ...state.rows]);
      setDocType(state?.rows?.[0]?.rowType);
    }
    updateSelection(false);
  };

  const updateSelection = (reset = false) => {
    if (reset) {
      setSelection([]);
      onRowSelect && onRowSelect(null);
    }
  };

  const onContextMenuOp = (data: any) => {
    if (data) {
      let dataToUpdate: any = [];
      selection.forEach((row) => {
        let status: any = {};
        Object.keys(data).forEach((key) => {
          status = [...data[key].map((item: any) => item.id)];
        });
        dataToUpdate.push({
          _id: row.id,
          status
        });
      });

      if (props.onApplyClick) {
        props.onApplyClick(dataToUpdate);
        setSelection([]);
      }
    }
  };

  const getDeleteConfirmation = (data: any) => {
    if (props.onRowDelete) {
      props.onRowDelete(data);
    }
  };
  const getFulfillmentData = (data: any) => {
    if (props.onBulkFulfillment) {
      props.onBulkFulfillment(data);
    }
  };
  const getConsolidateSOData = (data: any) => {
    if (props.onBulkConsolidate) {
      props.onBulkConsolidate(data);
    }
  };
  const getConvertWOData = (data: any) => {
    if (props.onBulkConvertWO) {
      props.onBulkConvertWO(data);
    }
  };
  const getReceivedGoodstData = (data: any) => {
    if (props.onBulkReceivedGoods) {
      props.onBulkReceivedGoods(data);
    }
  };
  const getBulkPoConversion = (data: any) => {
    if (props.onBulkPoConvert) {
      props.onBulkPoConvert(data);
    }
  };
  const onContextMenuCancel = () => {
    getRecords();
  };

  const getColumns = () => {
    let columns: IColumn[] = allowedColumnFreeze
      ? state.columns.map((column) => ({
          ...column,
          freezed: moduleFreezedColumns.includes(column.id)
        }))
      : [...state.columns];
    if (
      state.tableName !== TABLES.ACCOUNTS &&
      //   state.tableName !== TABLES.OB_INVENTORY &&
      state.tableName !== 'Items' &&
      state.tableName !== TABLES.DEBIT_REPORT &&
      state.tableName !== TABLES.CREDIT_REPORT &&
      state.tableName !== TABLES.INVOICE_REPORT &&
      state.tableName !== TABLES.ORDER_LIST_REPORT &&
      state.tableName !== TABLES.BILL_REPORT &&
      state.tableName !== TABLES.ORDER_LIST_REPORT &&
      state.tableName !== TABLES.QUOTATION_REPORT &&
      state.tableName !== TABLES.SBC_REPORT &&
      state.tableName !== TABLES.SBP_REPORT &&
      state.tableName !== TABLES.PURCHASE_BY_PRODUCT &&
      state.tableName !== TABLES.RECEIPT_LIST &&
      state.tableName !== TABLES.PAYMENT_LIST &&
      state.tableName !== TABLES.PURCHASE_BY_PRODUCT &&
      state.tableName !== TABLES.AGED_RECEIVABLES_REPORT &&
      state.tableName !== TABLES.MILESTONE_REPORT &&
      state.tableName !== TABLES.AGED_PAYABLES_REPORT &&
      state.tableName !== TABLES.STOCK_MOVEMENT_REPORT &&
      state.tableName !== TABLES.INVENTORY_TAB &&
      state.tableName !== TABLES.ACCOUNTS &&
      state.tableName !== TABLES.ITEM_LANDED_COST &&
      state.tableName !== TABLES.CONSOLIDATION_FINANCIAL_REPORT &&
      state.tableName !== TABLES.SALES_SERVICE_DETAILS &&
      state.tableName !== TABLES.SALES_TAX_RETURN &&
      state.tableName !== TABLES.SERVICE_TAX_RETURN &&
      state.tableName !== TABLES.SALES_BY_CLASS &&
      state.tableName !== TABLES.MRP_REPORTS_YIELD_SUMMARY &&
      state.tableName !== TABLES.MRP_REPORTS_YIELD_DETAILED_SUMMARY &&
      state.tableName !== TABLES.MRP_REPORTS_DAILY_CONSUMPTION &&
      state.tableName !== TABLES.LINK_JOURNAL_ENTRY &&
      state.tableName !== TABLES.DEFERRED_EXPENSE &&
      state.tableName !== TABLES.REV_REC &&
      state.tableName !== TABLES.BOOKS_REPORT_SKU_ORDER_TRACKING &&
      state.tableName !== TABLES.CONSOLIDATE_BANK_BOOK_REPORT &&
      state.tableName !== TABLES.BANK_BOOK_REPORT &&
      state.tableName !== TABLES.SO_TO_WO_REPORT
    ) {
      if (isTabletView()) {
        columns = [getActionButtonsColumn() as any, ...columns];
      } else {
        columns = [...columns, getActionButtonsColumn() as any];
      }
    }

    if (props.allowColumnVisibilityToggle && hiddenColumnIds?.length) {
      columns = columns.filter(
        (column) => !hiddenColumnIds.includes(column.id as string)
      );
    }

    return columns;
  };

  const onBulkPaymentClick = (data: any) => {
    if (props.onBulkPayment) {
      props.onBulkPayment(data);
    }
  };

  const onBulkPrintlick = (data: any) => {
    if (props.onBulkPrint) {
      props.onBulkPrint(data);
    }
  };

  const onBulkApprovalClick = (data:any,remarks:string) => {
    if (props.onBulkApproval) {
      props.onBulkApproval(data,remarks);
    }
    updateSelection(true);
  };

  const onBulkPendingEnvoiceClick = (data: any) => {
    if (props.onBulkPendingEInvoice) {
      props.onBulkPendingEInvoice(data);
    }
  };

  const onBulkGeneratedEnvoiceClick = (data: any) => {
    if (props.onBulkGeneratedEInvoice) {
      props.onBulkGeneratedEInvoice(data);
    }
  };

  const onBulkRejectClick = (data: any,remarks:any) => {
    if (props.onBulkReject) {
      props.onBulkReject(data,remarks);
    }
    updateSelection(true);
  };
  const onBulkPrintCheckClick = (data: any) => {
    if (props.onBulkPrintChecks) {
      props.onBulkPrintChecks(data);
    }
  };

  const deactivateBtnClicked = () => {
    if (props.onDeactivateBtnClicked) {
      props.onDeactivateBtnClicked(selection);
    }
    setState({
      ...state,
      showContextMenu: false
    });
  };

  const bulkSendEmailClicked = (data: any) => {
    if (props.onBulkSendEmail) {
      props.onBulkSendEmail(data);
    }
  };
  const bulkDeletedClicked = async () => {
    const isConfirmed = await confirm(
      t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT_PLURAL`),
      t(`CONFIRMATION_POPUP.TITLE_PLURAL`)
    );

    if (isConfirmed) {
      if (props.onBulkDelete) {
        props.onBulkDelete(selection);
      }
      setState({
        ...state,
        showContextMenu: false
      });
      updateSelection(true);
    }
  };

  const bulkGenerateBankFile = (data: any[]) => {
    if (props.bulkGenerateBankFile) {
      props.bulkGenerateBankFile(data);
    }

    updateSelection(true);
  };

  const bulkUnlinkBankFile = (data: any[]) => {
    if (props.bulkUnlinkBankFile) {
      props.bulkUnlinkBankFile(data);
    }

    updateSelection(true);
  };

  const documentTransaction = (doc: CONTACT_TRANSACTION_TYPE) => {
    if (props.onDocTransactionClick) {
      props.onDocTransactionClick(doc, selection[0]);
    }
  };

  const getTableDisplayName = () => {
    let tableDisplayName = '';
    const icon = props.gridIcon ? props.gridIcon + ' ' : '';
    if (
      Utility.isUSorg() &&
      state.tableName === REMOTE_CONFIG_TABLES.QUOTATION
    ) {
      return icon + 'Estimates'; // Replace Quotes with Estimates in case of US org
    }
    if (state.tableName === TABLES.FULFILLMENT_MASTER) {
      return icon + localizedText('Fulfillments');
    }
    if (TABLE_DISPLAY_NAME[state.tableName]) {
      tableDisplayName = icon + TABLE_DISPLAY_NAME[state.tableName];
    }
    return tableDisplayName;
  };
  const getGridHeaderViews = () => {
    let buttons: GridHeaderButtonConfig[] =
      props.headerButtons ||
      (props.addButtonConfig ? [props.addButtonConfig] : []);

    if (
      props.allowColumnVisibilityToggle &&
      state.columns?.length > 0 &&
      state.showAllowColumnVisibilityToggleButton
    ) {
      buttons = [...buttons];

      const columnVisibilityButtonIndex = buttons.findIndex(
        (button) => button.id === COLUMN_VISIBILITY_BUTTON_CONFIG.id
      );
      if (columnVisibilityButtonIndex === -1) {
        buttons = buttons.concat(COLUMN_VISIBILITY_BUTTON_CONFIG);
      }
    } else {
      buttons = buttons.filter(
        (button) => button.id !== COLUMN_VISIBILITY_BUTTON_CONFIG.id
      );
    }

    let views: (JSX.Element | null)[] = buttons.map((button, index) => {
      if (button.renderer) {
        return button.renderer();
      }

      if (button.id === COLUMN_VISIBILITY_BUTTON_CONFIG.id) {
        return button.hidden ? null : (
          <GridColumnVisibility
            tableName={columnDisplayPreferenceTableName}
            columns={state.columns}
            style={
              index === 0
                ? {
                    left: 'unset',
                    right: 0
                  }
                : {}
            }
          />
        );
      }

      const needNoTranslate = button.icon === DKIcons.ic_calendar;

      return (
        <DKButton
          title={button.title}
          className={
            button.className +
            (needNoTranslate ? ` ${GOOGLE_NO_TRANSLATE_CLASS} ` : '') +
            ' mb-m '
          }
          icon={button.icon}
          isReverse={button.isReverse}
          onClick={button.onClick}
        />
      );
    });

    return views;
  };
  const getDkGrid = () => (
    <>
      <DKDataGrid
        views={getGridHeaderViews()}
        title={
          (props.gridIcon ? props.gridIcon + ' ' : '') +
          (props.displayTableName ? props.displayTableName : '')
        }
        width={state.width}
        needBoldTheme={
          typeof props.needBoldTheme === 'boolean' ? props.needBoldTheme : false
        }
        needBorder={
          typeof props.needBorder === 'boolean' ? props.needBorder : false
        }
        styles={props.styles || {}}
        columns={getColumns()}
        rows={[...state.rows]}
        contextMenu={props.contextMenu}
        updating={props.updating || columnUpdating}
        currentPage={state.currentPage}
        totalPageCount={state.totalPageCount}
        invalidFields={state.invalidFields}
        onRowUpdate={onRowUpdate}
        onColumnUpdate={onColumnUpdate}
        onColumnShift={onColumnShift}
        onRowAdd={onRowAdd}
        onRowClick={onRowClick}
        onColumnAdd={onColumnAdd}
        onColumnAddClick={props.onColumnAddClick}
        onColumnDelete={onColumnDelete}
        onSearch={(data: any) => onSearch(data)}
        onFilter={(data: any) => onFilter(data)}
        dateFormat={
          props.dateFormat
            ? props.dateFormat
            : convertBooksDateFormatToUILibraryFormat(tenantInfo.dateFormat)
        }
        allowRowAdd={props.allowRowAdd}
        allowRowEdit={props.allowRowEdit}
        allowColumnAdd={props.allowColumnAdd}
        allowColumnEdit={props.allowColumnEdit}
        allowColumnDelete={props.allowColumnDelete}
        allowColumnShift={props.allowColumnShift}
        allowColumnFreeze={allowedColumnFreeze}
        allowSearch={props.allowSearch}
        allowFilter={props.allowFilter}
        allowBulkOperation={props.allowBulkOperation}
        allowColumnSort={state.allowColumnSort}
        needShadow={props.needShadow}
        onRowOpenClick={
          typeof props.onRowOpenClick === 'function' ? onRowOpenClick : null
        }
        onPageChange={(page: number) => onPagination(page)}
        filterData={state.filter}
        filterColumns={props.gridData.filterColumns}
        onRowSelect={(data: any) => onRowSelect(data)}
        isAllRowSelected={state.isAllRowSelected}
        onAllRowSelect={(data: any) => onAllRowSelect(data)}
        showHeader={!state.showContextMenu}
        addButton={props.addButtonConfig ? props.addButtonConfig : undefined}
        onSort={props.onSort}
        onColumnFreezeToggle={onColumnFreezeToggle}
        needTrailingColumn={props.needTrailingColumn}
        searchBarConfig={props.searchBarConfig}
        allowExpand={props.expand ?? false}
        needBackButton={props.needBackButton ? props.needBackButton : false}
        onBackTapped={props.onBackTapped}
        onRowExpand={(data: any) => {
          props.rowExpand(data);
        }}
      />
      {state.needNoDataView && getNoDataView()}
      {props.updating && state.showContextMenu && hideContextMenu()}
    </>
  );
  const getDataGrid = () => (
    <div
      style={{ margin: 'auto' }}
      className="column parent-height parent-width"
    >
      {state.showContextMenu && (
        <ContextMenu
          tableName={state.tableName}
          displayTableName={getTableDisplayName()}
          needBoldTheme={
            typeof props.needBoldTheme === 'boolean'
              ? props.needBoldTheme
              : false
          }
          onDelete={async () => {
            if (!Utility.isEmpty(selection)) {
              const isConfirmed = await confirm(
                t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT_PLURAL`),
                t(`CONFIRMATION_POPUP.TITLE_PLURAL`)
              );

              if (isConfirmed) {
                getDeleteConfirmation(selection);
                setState({
                  ...state,
                  showContextMenu: false
                });
                setSelection([]);
              } else {
                setState({
                  ...state,
                  showContextMenu: false,
                  rows: state.rows.map((item: any) => {
                    return {
                      ...item,
                      selected: false
                    };
                  })
                });
              }
            }
          }}
          onFulfill={() => getFulfillmentData(selection)}
          onReceived={() => getReceivedGoodstData(selection)}
          onBulkPoConvert={() => getBulkPoConversion(selection)}
          onApply={(data) => onContextMenuOp(data)}
          onCancel={() => onContextMenuCancel()}
          onConsolidateSO={() => getConsolidateSOData(selection)}
          onConvertWO={() => getConvertWOData(selection)}
          docType={docType}
          bulkPaymentRows={props.bulkPaymentRows}
          bulkPendingEInvoicesRows={props.bulkPendingEInvoicesRows}
          bulkGeneratedEInvoicesRows={props.bulkGeneratedEInvoicesRows}
          bulkReceivedGoodsRows={props.bulkReceivedGoodsRows}
          bulkFulfillmentRows={props.bulkFulfillmentRows}
          bulkPrintRows={props.bulkPrintRows}
          onBulkPayment={() => onBulkPaymentClick(selection)}
          onBulkPrint={() => onBulkPrintlick(selection)}
          selection={selection}
          showSelectedRecordsCount={props.showSelectedRecordsCount}
          showDeactivateBtn={showDeactivateBtnData}
          onDeactivateClick={deactivateBtnClicked}
          onBulkDeleteClick={bulkDeletedClicked}
          bulkApprovalRows={props.bulkApprovalRows}
          onBulkApproval={onBulkApprovalClick}
          onBulkPendingEInvoice={() => onBulkPendingEnvoiceClick(selection)}
          onBulkGeneratedEInvoice={() => onBulkGeneratedEnvoiceClick(selection)}
          bulkRejectRows={props.bulkRejectRows}
          consolidateRows={props?.consolidateRows}
          onBulkReject={onBulkRejectClick}
          bulkPrintChecksRows={props.bulkPrintChecksRows}
          onBulkPrintChecks={() => onBulkPrintCheckClick(selection)}
          documentTransaction={(document) => {
            documentTransaction(document);
          }}
          bulkEWBRows={props.bulkEWBRows}
          onBulkEWBGenerate={() => {
            props.onBulkEWBGenerate && props.onBulkEWBGenerate();
          }}
          generateBarcode={() =>
            props.generateBarcode ? props.generateBarcode() : false
          }
          barcodeRowData={props.barcodeRowData}
          jobCardRows={props.jobCardRows}
          onBulkJCStatusChange={(data: any) => {
            if (props.onBulkJCStatusChange) {
              props.onBulkJCStatusChange(data);
              onContextMenuCancel();
            }
          }}
          permissionModule={props.permissionModule}
          showSendEmail={{
            btnText: props.showSendEmail?.btnText ?? 'Send Email',
            className: props.showSendEmail?.className ?? '',
            showEmailBtn: props.showSendEmail?.showEmailBtn ?? false,
            hoverTitle: props.showSendEmail?.hoverTitle ?? ''
          }}
          onSendEmail={() => bulkSendEmailClicked(selection)}
          displayBulkGenerateBankFile={props.displayBulkGenerateBankFile}
          bulkGenerateBankFile={() => bulkGenerateBankFile(selection)}
          displayBulkUnlinkBankFile={props.displayBulkUnlinkBankFile}
          bulkUnlinkBankFile={() => bulkUnlinkBankFile(selection)}
        />
      )}
      {getDkGrid()}
    </div>
  );

  return (
    <>
      {!Utility.isEmpty(tenantInfo) && (
        <div className="column flex-1 parent-width">{getDataGrid()}</div>
      )}
    </>
  );
};
export default DataGridHolder;
