import { DropdownSelectionType, FORM_ELEMENTS } from '../Constants/Constant';
import { CallBackPayloadType } from './Interfaces';

import { DropdownTag, InputTag } from './NewContact';
export interface NewTaxProps {
  formData?: any;
  populateFormData: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}
export interface TaxListInfo {
  name: InputTag<string>;
  description: InputTag<string>;
  defaultMemo: InputTag<string>;
  percent: InputTag<string>;
  taxCode: InputTag<string>;
  type: any;
  taxAccount: DropdownTag<string>;
  isAdditional: boolean;
  flatRateSchemeUk: boolean;
  defaultTaxAccount?: string;
}

export interface TaxPayload {
  name: string | number;
  description: string | number;
  defaultMemoUk: any;
  percent: number;
  taxCode: string | number;
  type: string | number;
  accountName: any;
  accountCode: any;
  status: string | number;
  isTaxGroup: boolean;
  additionalTaxIn: boolean;
  flatRateSchemeUk: boolean;
  effectiveStartDate: any;
  effectiveEndDate: any;
  taxAmountUk: any;
  netAmountUk: any;
}
export interface TaxGroupPayload {
  name: string | number;
  description: string | number;
  defaultMemoUk: any;
  percent: number;
  taxCode: string | number;
  type: string | number;
  isTaxGroup: boolean;
  accountCode: string | number;
  status: string | number;
  taxGroupDetails: TaxGroup[];
  effectiveStartDate: any;
  effectiveEndDate: any;
}
export interface TaxGroup {
  additionalTaxIn: boolean;
  applyTo: string;
  code: string | number;
  id: number;
  name: string;
  percentage: number;
  type: string;
}
export interface AddTaxGroup {
  name: InputTag<string>;
  description: InputTag<string>;
  defaultMemo: InputTag<string>;
  taxCode: InputTag<string>;
  type: DropdownTag<string>;
  taxRow: taxRow[];
  taxAccountValue: any[];
}
export interface taxRow {
  taxComponent: DropdownTag<string>;
  taxValue?: any[];
  applyTo: DropdownTag<string>;
}

export const initialTaxGroupFormValues: AddTaxGroup = {
  type: {
    key: '',
    hidden: false,
    defaultValue: { label: 'SALES', value: 'SALES' },
    value: { label: 'SALES', value: 'SALES' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  name: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  description: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  defaultMemo: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  taxCode: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  taxRow: [],
  taxAccountValue: []
};
export const initialtaxRowValues: taxRow = {
  taxComponent: {
    key: '',
    hidden: false,
    defaultValue: { label: '', value: '' },
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  applyTo: {
    key: '',
    hidden: false,
    defaultValue: { label: '', value: '' },
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  }
};

export const initialTaxListFormValues: TaxListInfo = {
  type: {
    key: '',
    hidden: false,
    defaultValue: null,
    value: null,
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  name: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  description: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  defaultMemo: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  taxCode: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  percent: {
    key: '',
    hidden: false,
    value: 0,
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  taxAccount: {
    key: '',
    hidden: false,
    defaultValue: { label: '', value: '' },
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  isAdditional: true,
  defaultTaxAccount: 'ACC40010',
  flatRateSchemeUk: false
};
