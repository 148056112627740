import { useEffect, useState } from 'react';
import { DKLabel, INPUT_TYPE, DKDataGrid } from 'deskera-ui-library';
import DateFormatService from '../../Services/DateFormat';
import { BOOKS_DATE_FORMAT } from '../../Constants/Constant';
import Utility, { deepClone } from '../../Utility/Utility';
import { localizedText } from '../../Services/Localization/Localization';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';

export const JOURNAL_ENTRY_COLS: any[] = [
  {
    name: 'Account Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'accountName',
    key: 'accountName',
    id: 'accountName',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Debit Amount',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'debitAmount',
    key: 'debitAmount',
    id: 'debitAmount',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false,
    textAlign: 'right'
  },
  {
    name: 'Credit Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'creditAmount',
    key: 'creditAmount',
    id: 'creditAmount',
    systemField: true,
    allowFilter: true,
    allowColumnSort: false,
    textAlign: 'right'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 240,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false
  }
];

function JournalEntryRecordsDetail(props: any) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    const rowData = Utility.isEmpty(props?.journalEntryDetails?.lineItems)
      ? Utility.isEmpty(props?.journalEntryDetails?.journalEntryLineItems)
        ? []
        : getRowDataForCreate(props?.journalEntryDetails?.journalEntryLineItems)
      : getRowDataForCreate(props?.journalEntryDetails?.lineItems);
    setRows(rowData);
  }, [props.journalEntryDetails]);

  const updateColumn = (columns: any) => {
    let tempColumnConfig = deepClone(columns);

    let debitAmountIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'debitAmount'
    );

    if (debitAmountIndex) {
      tempColumnConfig[debitAmountIndex] = {
        ...tempColumnConfig[debitAmountIndex],
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={Utility.getLongAmoutBlockForLabel(
                tenantInfo.currency,
                obj.rowData.cdType === 'DEBIT' ? obj.rowData.amount : 0
              )}
              className="parent-width text-align-right"
            />
          );
        }
      };
    }

    let creditAmountIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'creditAmount'
    );

    if (creditAmountIndex) {
      tempColumnConfig[creditAmountIndex] = {
        ...tempColumnConfig[creditAmountIndex],
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={Utility.getLongAmoutBlockForLabel(
                tenantInfo.currency,
                obj.rowData.cdType === 'CREDIT' ? obj.rowData.amount : 0
              )}
              className="parent-width text-align-right"
            />
          );
        }
      };
    }

    return tempColumnConfig;
  };

  const getRowDataForCreate = (tableList: any[]) => {
    let rowDataList: any[] = [];
    tableList?.forEach((rowData: any) => {
      const row = {
        ...rowData,
        rowContextMenu: [],
        rowButtons: []
      };
      rowDataList.push(row);
    });
    return rowDataList;
  };

  const getGrid = () => {
    return (
      <div className="column parent-width mb-s flex-1 mt-l">
        <DKDataGrid
          needShadow={false}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={false}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          // width={960}
          rows={rows}
          columns={updateColumn(JOURNAL_ENTRY_COLS)}
        />
      </div>
    );
  };
  const getJournalEntryType = (journalEntryDetails: any) => {
    switch (journalEntryDetails?.type) {
      case 'REVENUE_ARRANGEMENT':
        return 'Revenue Recognition';
      case 'FIXED_ASSET':
        return 'Fixed Asset Depreciation';
      case 'FULFILLMENT':
        return localizedText('Fulfillment');

      default:
        if (journalEntryDetails.transferType) {
          return Utility.convertInTitleCase(
            journalEntryDetails.transferType === 'CHEQUE'
              ? localizedText('cheque')
              : journalEntryDetails?.transferType?.replaceAll('_', ' ')
          );
        } else {
          return Utility.convertInTitleCase(
            journalEntryDetails?.type?.replaceAll('_', ' ')
          );
        }
    }
  };
  return (
    <div
      className={`column parent-width ${
        props?.removeHorizontalPadding ? '' : 'p-h-l'
      } p-v-m`}
    >
      <div className="row justify-content-between">
        <div className="column">
          <DKLabel text="Journal Entry No" className="fw-m" />
          <DKLabel text={props?.journalEntryDetails?.jeCode} className="" />
        </div>
        <div className="column">
          <DKLabel text="Entry Date" className="fw-m" />
          <DKLabel
            text={DateFormatService.getFormattedDateString(
              props?.journalEntryDetails?.jeDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
            className=""
          />
        </div>
        <div className="column">
          <DKLabel text="Entry Type" className="fw-m" />
          <DKLabel
            text={getJournalEntryType(props?.journalEntryDetails)}
            className=""
          />
        </div>
        <div className="column">
          <DKLabel text="Document No." className="fw-m" />
          <DKLabel
            text={props?.journalEntryDetails?.documentSequenceCode}
            className=""
          />
        </div>
      </div>
      {getGrid()}
    </div>
  );
}

export default JournalEntryRecordsDetail;
