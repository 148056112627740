import { Store } from '../../Redux/Store';
import { indiaLocalizedKeys } from './India';

import { usLocalizedKeys } from './US';

export type LocalizedKeys = {
  quote: string;
  Quote: string;
  quotes: string;
  cheque: string;
  quotation: string;
  organisation: string;
  'Fulfill Your Invoice': string;
  'Fulfill Your': string;
  'Fulfill Your Sales Order': string;
  'Direct Fulfillment': string;
  'Fulfill with 3rd party logistics': string;
  'Fulfill in the Warehouse Module': string;
  'Direct Fulfillment of': string;
  'Direct Fulfillment of Sales Order': string;
  'Direct Fulfillment of Quotation': string;
  'Direct Fulfillment of Invoice': string;
  Fulfill: string;
  'Fulfillment Records': string;
  'Fulfill this Sales Order in full before converting in to a Full Invoice.': string;
  'Fulfill and Convert': string;
  'Outstanding Sales Fulfillment Report': string;
  'Not Fulfilled': string;
  'Partially Fulfilled': string;
  Fulfilled: string;
  Fulfillment: string;
  'Fulfill this Quote in full before converting in to a Full Invoice': string;
  unfulfilled: string;
  'do the fulfillment': string;
  'Fulfill this': string;
  Fulfillments: string;
  'Fulfill Date': string;
  'Fulfill All': string;
  'quotation_report': string;
  'Outstanding Delivery Order Report': string;
};

type LocalizedMap = {
  [key: string]: LocalizedKeys;
};

const localizedMap: LocalizedMap = {
  us: usLocalizedKeys,
  in: indiaLocalizedKeys
};

// Utility function to get localized country specific text
export const localizedText = (key: keyof LocalizedKeys) => {
  const country =
    Store.getState().authInfo?.currentTenantInfo?.data?.country?.toLowerCase();
  switch (country) {
    case 'us':
      return localizedMap?.[country]?.[key]
        ? localizedMap?.[country]?.[key]
        : key;
    case 'in':
      return localizedMap?.[country]?.[key]
        ? localizedMap?.[country]?.[key]
        : key;
    default:
      return key;
  }
};
