import React, { useRef, useState } from 'react';
import {
  DKLabel,
  DKDataGrid,
  DKTooltipWrapper,
  DKIcons,
  DKIcon,
  DKButton,
  DKSpinner,
  DKListPicker2,
  showAlert
} from 'deskera-ui-library';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import Utility, { deepClone } from '../../../../../Utility/Utility';
import { WO_OPERATION_COLUMN } from '../../../Constants/MRPColumnConfigs';
import { WORK_ORDER_OPERATION_TABLE } from '../../../Constants/TableConstant';
import ApiConstants from '../../../../../Constants/ApiConstants';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import { IColumn } from '../../../../../Models/Table';
import useScreenResize from '../../../../../Hooks/useScreenResize';
import { selectOperations } from '../../../../../Redux/Slices/MRP/OperationSlice';
import RawMaterialHelper from '../BomMaterialComponent/RawMaterialHelper';
import {
  WorkOrderHelper,
  getStatusChipColor,
  initialStateForOperation
} from '../../WorkOrderHelper';
import {
  IWorkOrder,
  IWorkOrderOperation
} from '../../../../../Services/MRP/WorkOrder';
import { selectWorkstations } from '../../../../../Redux/Slices/MRP/WorkstationSlice';
import WorkstationsService from '../../../../../Services/MRP/Workstations';
import { checkUserPermission } from '../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../../Constants/Permission';
import { PROCESS_TYPES } from '../../../Constants/MRPEnums';
import { PRODUCT_TYPE } from '../../../../../Constants/Constant';
import RoutingTemplateService, {
  IRoutingTemplate
} from '../../../../../Services/RoutingTemplate';
import { getProcessedWorkOrderOperations } from '../../AddWorkOrderSlice';
import OperationsService from '../../../../../Services/MRP/Operations';
import { isMachineEnabled } from '../../../../../Services/MachineMasterService';

export interface IOperationsListProps {
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  workOrderData: IWorkOrder;
  operationIdToJobMappingData: { [key: number]: any };
  selectedOperatorsByProductId: { [key: number]: any[] };
  onOperationsChange: (updatedOperations: IWorkOrderOperation[]) => void;
  updateSelectedOperators: (selectedOperators: {
    [key: string]: any[];
  }) => void;
}

export interface IOperations {
  invalidFields?: string[];
  operationName: {
    id: string;
    name: string;
    operationCode: string;
    defaultWorkstation: number;
    [key: string]: any;
  };
  operationDescription: string;
  operationStatus: string[];
  operationCompletedQty: number;
  operationCost: number;
  operationTime: number;
  processType?: keyof PROCESS_TYPES;
  product?: string;
  qcNeeded?: boolean;
  [key: string]: any;
}

const OperationsList: React.FC<IOperationsListProps> = (
  props: IOperationsListProps
) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);
  type OperationRowFormatterConfig = {
    rowData: IOperations;
    rowIndex: number;
    columnKey: string;
  };
  const operationsList = useAppSelector(selectOperations);
  const workStations = useAppSelector(selectWorkstations);
  const [needRoutingTemplatePicker, setNeedRoutingTemplatePicker] =
    useState(false);
  const [routingTemplates, setRoutingTemplates] = useState<
    IRoutingTemplate[] | null
  >(null);
  const operationRows = props.workOrderData?.workOrderOperations || [];
  const processManufacturingColumnIds = [
    WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE,
    WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM,
    WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED
  ];

  const isProcessManufacturingEnabled = Boolean(
    tenantInfo?.additionalSettings.LINK_INVENTORY_JOB_CARDS
  );

  const addNewRowForOperation = () => {
    let operationList = [...operationRows];
    let newRow: IWorkOrderOperation = {
      ...initialStateForOperation,
      isNewRow: true
    };
    operationList.push(newRow);
    props.onOperationsChange(operationList);
  };

  const deleteOperationRow = (rowIndex: number) => {
    let operationList = [...operationRows];
    operationList.splice(rowIndex, 1);
    props.onOperationsChange(operationList);
  };

  const onTapAddTemplate = () => {
    setNeedRoutingTemplatePicker(true);

    if (Utility.isNotEmpty(routingTemplates)) return;

    const workOrderProductCode = props.workOrderData?.productCode as string;
    RoutingTemplateService.getRoutingTemplatesByProduct(workOrderProductCode)
      .then((response) => {
        if (response) setRoutingTemplates(response as IRoutingTemplate[]);

        if (Utility.isEmpty(response)) {
          const productSeqCode =
            props.workOrderData.productDocSeqCode ||
            props.workOrderData.product?.documentSequenceCode;
          showAlert(
            'No template found!',
            `Looks like you don't have any routing template tagged to product: ${
              props.workOrderData.productName
            } ${productSeqCode ? `(${productSeqCode})` : ''}`
          );
        }
      })
      .catch((err) => setNeedRoutingTemplatePicker(false));
  };

  const onSelectRoutingTemplate = async (routingTemplate: IRoutingTemplate) => {
    setNeedRoutingTemplatePicker(false);

    if (Utility.isEmpty(routingTemplate?.operations)) return;

    try {
      const operationIds = routingTemplate.operations.map(
        (operation) => operation.operationId
      );
      const operationListResponse = await OperationsService.getOperationsById(
        operationIds
      );
      const selectedOperatorsByProductId: { [key: string]: any[] } = {
        ...(props.selectedOperatorsByProductId || {})
      };
      const currentWorkOrderOperators =
        selectedOperatorsByProductId[
          props.workOrderData?.productCode as string
        ] || [];

      const operations = getProcessedWorkOrderOperations({
        workOrderData: props.workOrderData,
        workOrderOperations: routingTemplate.operations,
        isEditMode: props.isEditMode,
        operationListResponse,
        selectedOperatorsByProductId,
        operatorsListResponse: []
      });

      const newOperators =
        selectedOperatorsByProductId[
          props.workOrderData?.productCode as string
        ] || [];

      props.onOperationsChange([
        ...(props.workOrderData.workOrderOperations || []),
        ...operations
      ]);

      selectedOperatorsByProductId[props.workOrderData?.productCode as string] =
        [...currentWorkOrderOperators, ...newOperators];
      props.updateSelectedOperators(selectedOperatorsByProductId);
    } catch (err) {
      console.error('Error while selecting routing template', err);
    }
  };

  const onReplaceExistingOperation = async ({
    rowData,
    rowIndex
  }: OperationRowFormatterConfig) => {
    let selectedOperation = rowData?.operationName;
    let workstationDetails =
      selectedOperation?.workstationDetails ??
      workStations?.content?.find(
        (workstation: any) =>
          workstation.id === selectedOperation?.defaultWorkstation
      );
    if (!workstationDetails && selectedOperation?.defaultWorkstation) {
      workstationDetails = await WorkstationsService.fetchWorkStationById(
        selectedOperation.defaultWorkstation
      );
      workstationDetails = workstationDetails?.content?.[0];
    }

    selectedOperation = { ...selectedOperation, workstationDetails };

    let operationItem = WorkOrderHelper.getOperationRow(
      props.workOrderData,
      rowData,
      selectedOperation,
      rowIndex,
      props.isEditMode,
      true,
      []
    );
    let updatedOperations = [...operationRows];
    updatedOperations[rowIndex] = {
      ...operationItem,
      isNewRow: true,
      invalidFields: rowData.operationName?.name ? [] : rowData.invalidFields
    };
    props.onOperationsChange(updatedOperations);
  };

  const onOperationRowChange = (data: OperationRowFormatterConfig) => {
    if (data.columnKey === WORK_ORDER_OPERATION_TABLE.OPERATION_NAME) {
      data.rowData.callingOperationDetails = true;
      onReplaceExistingOperation(data);
    } else {
      let updatedOperations = [...operationRows];
      let rowDataValue = data.rowData[data.columnKey];

      if (
        data.columnKey === WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE &&
        typeof rowDataValue === 'object'
      ) {
        rowDataValue = rowDataValue.value;
      } else if (
        data.columnKey === WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM &&
        typeof rowDataValue === 'object'
      ) {
        rowDataValue = rowDataValue.productCode;
      } else if (
        data.columnKey === WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED
      ) {
        rowDataValue = rowDataValue === 'Yes' ? true : false;
      }

      updatedOperations[data.rowIndex] = {
        ...updatedOperations[data.rowIndex],
        [data.columnKey]: rowDataValue
      };
      props.onOperationsChange(updatedOperations);
    }
  };

  const pickFormatter = (column: any) => {
    switch (column.key) {
      case WORK_ORDER_OPERATION_TABLE.OPERATION_TIME:
        return (data: OperationRowFormatterConfig) =>
          `${data.rowData.operationTime?.toFixed(2) || 0} min`;
      case WORK_ORDER_OPERATION_TABLE.OPERATION_COST:
        return (data: OperationRowFormatterConfig) =>
          Utility.amountFormatter(
            data.rowData.operationCost || 0,
            tenantInfo.currency
          );
      case WORK_ORDER_OPERATION_TABLE.OPERATION_OPERATORS_COST:
        return (data: OperationRowFormatterConfig) =>
          Utility.amountFormatter(
            data.rowData.operatorCost || 0,
            tenantInfo.currency
          );
      case WORK_ORDER_OPERATION_TABLE.OPERATION_WORKSTATION_COST:
        return (data: OperationRowFormatterConfig) =>
          Utility.amountFormatter(
            data.rowData.workstationCost || 0,
            tenantInfo.currency
          );
      case WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE:
        return ({ rowData }: OperationRowFormatterConfig) =>
          (rowData?.processType &&
            PROCESS_TYPES[rowData.processType as keyof typeof PROCESS_TYPES]) ||
          '';
      case WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM:
        return ({ rowData }: OperationRowFormatterConfig) => {
          const taggedItemCode =
            rowData[WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM];
          let taggedItemLabel = '';
          if (taggedItemCode) {
            const taggedWOItemData = props.workOrderData.workOrderItems?.find(
              (workOrderItem) => workOrderItem.productCode === taggedItemCode
            );
            taggedItemLabel = taggedWOItemData?.itemName?.name;
          }

          return taggedItemLabel;
        };
      default:
    }
  };

  const pickRenderer = (column: any) => {
    switch (column.key) {
      case WORK_ORDER_OPERATION_TABLE.OPERATION_NAME:
        return (data: OperationRowFormatterConfig) => (
          <DKTooltipWrapper
            content={data.rowData.operationName?.['name'] || '-'}
            tooltipClassName="bg-deskera-secondary "
            className="position-relative parent-width"
          >
            {data.rowData?.callingOperationDetails ? (
              <div className="row justify-content-between">
                <DKLabel
                  style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  text={data.rowData.operationName?.['name'] || '-'}
                  className="text-align-left fs-m"
                />
                <DKSpinner iconClassName="ic-s" />
              </div>
            ) : (
              <DKLabel
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                text={data.rowData.operationName?.['name'] || '-'}
                className="text-align-left fs-m"
              />
            )}
          </DKTooltipWrapper>
        );
      case WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED:
        return ({ rowData }: OperationRowFormatterConfig) => {
          return (
            <DKLabel
              style={{ width: 80 }}
              text={rowData?.qcNeeded === true ? 'Yes' : 'No'}
              className={`${getStatusChipColor(
                rowData?.qcNeeded === true ? 'completed' : ''
              )}`}
            />
          );
        };
      case WORK_ORDER_OPERATION_TABLE.LINKEDIN_MACHINES:
        return ({ rowData }: OperationRowFormatterConfig) => {
          const linkedMachines = rowData?.linkedInMachines || [];
          return (
            <div className="row justify-content-between p-h-s position-relative">
              <div className="row gap-1">
                {[...linkedMachines]
                  .splice(0, 2)
                  .map((item: any, index: number) => {
                    return (
                      <DKLabel
                        key={index}
                        text={Utility.getDottedText(item.name, 15)}
                        className="p-h-s p-v-xs bg-gray1 border-radius-s border-m"
                      />
                    );
                  })}
              </div>
              <div className="width-auto position-relative">
                {linkedMachines.length > 2 && (
                  <DKTooltipWrapper
                    content={getTootltipContent(linkedMachines)}
                    tooltipClassName="bg-deskera-secondary w-80"
                  >
                    <DKIcon
                      src={DKIcons.ic_more}
                      className="ic-xs cursor-pointer ml-s"
                    />
                  </DKTooltipWrapper>
                )}
              </div>
            </div>
          );
        };
      default:
    }
  };

  const getOperationColumnConfig = () => {
    // @ts-ignore
    let copyOfColumnConfig: Partial<IColumn>[] = deepClone(WO_OPERATION_COLUMN);
    copyOfColumnConfig.forEach((conf: any) => {
      conf.editable = false;
      switch (conf.key) {
        case WORK_ORDER_OPERATION_TABLE.OPERATION_NAME:
          conf.editable = !props.isReadOnlyMode && isAdhocEnabled;
          let operationsArray = operationsList?.content
            ? operationsList?.content
            : [];
          conf.dropdownConfig.data =
            operationsArray.length > 0
              ? RawMaterialHelper.getFilteredOperations(
                  operationsArray,
                  operationRows
                )
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            return `${ApiConstants.URL.BASE}mrp/operation/search?limit=25&search=${search}&page=0&sort=createdOn&sortDir=desc`;
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return RawMaterialHelper.getFilteredOperations(
              response?.content,
              operationRows
            );
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {};
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel className="text-left" text={obj.name} />;
          };
          break;
        case WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE:
          conf.editable = false;
          conf.dropdownConfig.data = Object.entries(PROCESS_TYPES).map(
            (item) => ({
              value: item[0],
              label: item[1]
            })
          );
          conf.dropdownConfig.renderer = (
            index: number,
            data: { label: string; value: string }
          ) => {
            return <DKLabel text={data?.label || '-'} />;
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {};
          break;
        case WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED:
          conf.editable = false;
          conf.dropdownConfig.renderer = (index: number, item: any) => (
            <DKLabel
              key={index}
              style={{ width: 90 }}
              text={item}
              className={`${getStatusChipColor(
                item === 'Yes' ? 'completed' : ''
              )}`}
            />
          );
          conf.dropdownConfig.onSelect = (index: number, value: any) => {};
          break;
        case WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM:
          conf.editable = false;
          conf.dropdownConfig.renderer = (index: number, item: any) => {
            return item?.itemName?.name || item?.productCode;
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {};
          break;
        case WORK_ORDER_OPERATION_TABLE.LINKEDIN_MACHINES:
          conf.editable = false;
          break;
        default:
      }

      conf.formatter = pickFormatter(conf);
      conf.renderer = pickRenderer(conf);
    });
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.OPERATIONS.VIEW_PRICE)) {
      const columnToFilter = [
        WORK_ORDER_OPERATION_TABLE.OPERATION_COST,
        WORK_ORDER_OPERATION_TABLE.OPERATION_OPERATORS_COST,
        WORK_ORDER_OPERATION_TABLE.OPERATION_WORKSTATION_COST
      ];
      copyOfColumnConfig = copyOfColumnConfig?.filter(
        (col: any) => !columnToFilter.includes(col?.key)
      );
    }
    if (!isMachineEnabled()) {
      const columnToFilter = [WORK_ORDER_OPERATION_TABLE.LINKEDIN_MACHINES];
      copyOfColumnConfig = copyOfColumnConfig?.filter(
        (col: any) => !columnToFilter.includes(col?.key)
      );
    }

    if (!isProcessManufacturingEnabled) {
      copyOfColumnConfig = copyOfColumnConfig.filter(
        (col: any) => !processManufacturingColumnIds.includes(col.key)
      );
    }

    /** Hiding actions column in case of non adhoc orgs or readonly mode */
    if (
      (!isAdhocEnabled && !isProcessManufacturingEnabled) ||
      props.isReadOnlyMode
    ) {
      copyOfColumnConfig = copyOfColumnConfig.filter(
        (col) => col.key !== 'actions'
      );
    }

    return copyOfColumnConfig;
  };

  const getTootltipContent = (linkedMachines: any) => {
    let htmlString = '<div class="column">';
    htmlString += `<div class="row p-v-xs font-semibold">Linked Machines</div>`;
    linkedMachines.forEach((item: any) => {
      htmlString += `<div class="row parent-width justify-content-between mt-s"><div class="column">${item.name}`;
    });
    htmlString += '</div>';
    return htmlString;
  };

  const updateOperationColumnConfigOnRowClick = (data: any) => {
    if (
      data.columnData.key !==
        WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM ||
      !data.columnData.editable
    )
      return;

    if (data?.rowData?.processType) {
      let consumedItems =
        operationRows
          ?.filter(
            (operation: any) =>
              operation.productCode &&
              (operation.processType === 'CONSUMPTION' ||
                operation.processType === 'PRODUCTION')
          )
          .map((operation: any) => operation.productCode) || [];
      let opDependency =
        data.rowData.operationDependency?.opDependencyList?.length &&
        operationRows?.find((opItem: any) =>
          data.rowData.operationDependency?.opDependencyList.includes(
            opItem.operationId
          )
        );

      switch (data?.rowData?.processType) {
        case 'PRODUCTION':
          data.columnData.dropdownConfig.data =
            props.workOrderData.workOrderItems?.filter(
              (pItem: any) =>
                pItem.productType === PRODUCT_TYPE.BILL_OF_MATERIALS &&
                !consumedItems.includes(pItem.productCode) &&
                (!opDependency ||
                  opDependency.productCode === pItem.productCode)
            );
          break;
        case 'CONSUMPTION':
        case 'PROCESSING':
          data.columnData.dropdownConfig.data =
            props.workOrderData.workOrderItems?.filter(
              (pItem: any) =>
                !consumedItems.includes(pItem.productCode) &&
                (!opDependency ||
                  opDependency.productCode === pItem.productCode)
            );
          break;
      }
    } else {
      data.columnData.dropdownConfig.data = [];
    }
  };

  const getOperationRows = () => {
    return operationRows.map((row, index) => {
      const operationJobCard =
        props.operationIdToJobMappingData?.[row.operationName?.id];
      const jobCardProcessDetails = operationJobCard?.jobCardLinkDetails?.[0];
      const rowElement = {
        ...row,
        invalidFields: row?.operationName?.id
          ? []
          : [WORK_ORDER_OPERATION_TABLE.OPERATION_NAME],
        [WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE]:
          jobCardProcessDetails?.processType || row.processType || '',
        [WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM]:
          jobCardProcessDetails?.productCode || row.product || '',
        [WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED]:
          jobCardProcessDetails?.qcNeeded ?? row.qcNeeded ?? null,
        nonEditableColumns: jobCardProcessDetails
          ? processManufacturingColumnIds
          : [],
        [WORK_ORDER_OPERATION_TABLE.LINKEDIN_MACHINES]:
          row.linkedInMachines || [],
        rowButtons:
          (isAdhocEnabled || isProcessManufacturingEnabled) &&
          !props.isReadOnlyMode
            ? [
                {
                  title: '',
                  className: 'p-v-0',
                  icon: DKIcons.ic_delete,
                  onClick: () => deleteOperationRow(index)
                }
              ]
            : []
      };
      return rowElement;
    });
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size justify-content-between">
        <div className="row justify-content-between parent-width">
          <DKLabel
            text="Operations"
            className="text-app-color fw-m fs-m pt-s"
          />
          {isProcessManufacturingEnabled ? renderAddFromTemplateButton() : null}
        </div>
        <div className="column parent-size align-items-center justify-content-center text-gray">
          {props.isEditMode
            ? `No operations found for this work order`
            : `Operation will be shown once the Work Order is saved`}
          {isAdhocEnabled && !props.isReadOnlyMode && (
            <DKButton
              title="+ Add Operation"
              className="text-app-color fw-m"
              onClick={addNewRowForOperation}
              style={{}}
            />
          )}
        </div>
      </div>
    );
  };

  const showEmptyState = Utility.isEmpty(operationRows);
  const operationGridContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(operationGridContainerRef);
  const containerMaxHeight = 640;
  const gridMaxHeight = containerMaxHeight - 70;

  const getGridButtons = () => {
    const gridButtons = [];

    if (isAdhocEnabled && !props.isReadOnlyMode) {
      gridButtons.push({
        title: '+ Add Operation',
        className: 'text-app-color fw-m p-0',
        onClick: addNewRowForOperation
      });
    }

    return gridButtons;
  };
  const renderAddFromTemplateButton = () => {
    return (
      <div className="row width-auto position-relative">
        <DKButton
          title={'+ Add from Template'}
          className={'text-app-color fw-m p-0 mb-m'}
          onClick={() => onTapAddTemplate()}
        />
        {needRoutingTemplatePicker && !routingTemplates && (
          <DKSpinner iconClassName="ic-s mb-s" />
        )}
        {needRoutingTemplatePicker && Utility.isNotEmpty(routingTemplates) && (
          <DKListPicker2
            data={routingTemplates}
            className={
              'position-absolute bg-white border-radius-s shadow-s z-index-3'
            }
            style={{
              width: 200,
              top: 0,
              right: 0
            }}
            allowSearch={true}
            displayKey={'name'}
            searchableKey={'name'}
            onSelect={(index: number, template: IRoutingTemplate) =>
              onSelectRoutingTemplate(template)
            }
            onClose={() => setNeedRoutingTemplatePicker(false)}
          />
        )}
      </div>
    );
  };
  const getGridViews = () => {
    const gridViews = [];

    if (isProcessManufacturingEnabled) {
      gridViews.push(renderAddFromTemplateButton());
    }

    return gridViews;
  };
  return (
    <div
      className="column bg-white border-m border-radius-m p-l parent-size"
      ref={operationGridContainerRef}
      style={{
        height: 'auto',
        width: props.isEditMode ? '49.5%' : '100%',
        maxHeight: containerMaxHeight
      }}
    >
      {!showEmptyState && (
        <DKDataGrid
          title={`<span class="fs-m text-app-color">${'Operations'}</span>`}
          width={(width || 500) - 36}
          styles={{
            mainGridHolder: { marginBottom: -18 },
            gridScrollHolder: { maxHeight: gridMaxHeight },
            shadowHolder: { maxHeight: gridMaxHeight }
          }}
          buttons={getGridButtons()}
          views={getGridViews()}
          allowColumnEdit={false}
          allowRowEdit={true}
          allowRowAdd={true}
          allowColumnSort={false}
          allowBulkOperation={false}
          columns={getOperationColumnConfig()}
          rows={getOperationRows()}
          onRowUpdate={onOperationRowChange}
          onRowClick={updateOperationColumnConfigOnRowClick}
        />
      )}
      {showEmptyState && renderEmptyState()}
    </div>
  );
};

export default OperationsList;
