import { useState } from 'react';

import { DKLabel, DKListPicker, DKIcon, DKIcons } from 'deskera-ui-library';

function DKDropdown(props: any) {
  const [needListPicker, setNeedListPicker] = useState(false);
  return (
    <div
      className="row width-auto align-items-center position-relative cursor-hand"
      onClick={() => {
        setNeedListPicker(!needListPicker);
      }}
    >
      <DKLabel
        text={props.title}
        className={
          'mr-s ' + (props.titleClass ? props.titleClass : 'text-gray')
        }
      />
      <DKIcon
        src={DKIcons.ic_arrow_down}
        className=" ic-xs"
        style={{ opacity: 0.6 }}
      />
      {needListPicker && (
        <DKListPicker
          data={props.data}
          className="position-absolute shadow-m border-radius-s border-s whitespace-nowrap"
          style={{
            right: -4,
            top: 20,
            width: props.width ? props.width : 125,
            zIndex: '1000'
          }}
          onSelect={(data: any) => {
            setTimeout(() => {
              setNeedListPicker(false);
            }, 10);
            if (props.onSelect) {
              props.onSelect(data);
            }
          }}
          onClose={() => {
            setNeedListPicker(false);
          }}
        />
      )}
    </div>
  );
}

export default DKDropdown;
