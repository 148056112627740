import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewBill from '../../Components/Bills/NewBill';
import NewExpenseBill2 from '../../Components/Bills/NewExpenseBill2';
import NewInvoice from '../../Components/Invoices/NewInvoice';
import NewOrder from '../../Components/PurchaseOrders/NewPurchaseOrder';
import NewWorkOut from '../../Components/Mrp/Workout/NewWorkOut';
import NewQuote from '../../Components/Quotations/NewQuote';
import NewSalesOrder from '../../Components/SalesOrders/NewSalesOrder';
import NewRequisition from '../../Components/Requisitions/NewRequisition';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import { DraftTypes, UPDATE_TYPES } from '../../Models/Drafts';
import {
  CallBackPayloadType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  draftTableId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDraftsColumnConfig,
  setCurrentDraftSaved,
  setDraftActionAvailibility,
  setPopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import DraftService from '../../Services/Drafts';
import { Store } from '../../Redux/Store';
import InvoiceService from '../../Services/Invoice';
import NewFABill from '../../Components/Bills/NewFABill';
import NewFAPurchaseOrder from '../../Components/PurchaseOrders/NewFAPurchaseOrder';
import NewRevRec from '../../Components/Invoices/NewRevRec';
import AmortizationScheduleDoc from '../DocumentForm/AmortizationScheduleDoc';
import RateAnalysisService from '../../Services/RateAnalysis';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { fetchOrders } from '../../Redux/Slices/PurchaseOrdersSlice';
import NewPIQ from '../../Components/PurchaseInwardQuotation/NewPIQ';

const DraftForms = forwardRef((props: any, ref: any) => {
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {
      saveAsDraft: 'click',
      onSubmit: 'click',
      closeDraftPopup: ''
    }
  };
  const callbackRefOnSubmit = useRef<UpdateCallBacksRefType>(refInitialState);
  const isSavedColumnId: string = useAppSelector(isSaveColumnId);
  let draftUpdateType = UPDATE_TYPES.SAVE_POPULATE_DATA;
  const tenantInfo = useAppSelector(activeTenantInfo);
  const { t, i18n } = useTranslation();

  // Dispatching value to store
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    savePopulateData(id: any) {
      savePopulateData(id);
    },

    save(id: any, closeDoc: boolean, processDoc: boolean) {
      save(id, closeDoc, processDoc);
    },

    update(id: any) {
      update(id);
    },

    saveAsDraft(id: any) {
      saveAsDraft(id);
    },

    updateMemo(id: any) {
      updateMemo(id);
    },

    closeDraftPopup() {
      closeDraftPopup();
    }
  }));

  const [tableId, settableId] = useState<any>(useAppSelector(draftTableId));
  const [columnConfig, setcolumnConfig] = useState<any>(
    useAppSelector(selectDraftsColumnConfig)
  );

  const closeDraftPopup = () => {
    if (callbackRefOnSubmit.current.storeCallbacksRef.closeDraftPopup) {
      callbackRefOnSubmit.current.storeCallbacksRef.closeDraftPopup();
    }
  };

  // This function Save As Draftrecord as Drafts
  const saveAsDraft = (id: any) => {
    draftUpdateType = UPDATE_TYPES.UPDATE;
    dispatch(setDraftActionAvailibility({ id, status: true }));
    callbackRefOnSubmit.current.storeCallbacksRef.saveAsDraft();
  };

  // This function save record
  const save = (id: any, closeDoc?: boolean, processDoc?: boolean) => {
    draftUpdateType = UPDATE_TYPES.UPDATE;
    // dispatch(setDraftActionAvailibility({ id, status: true }));
    callbackRefOnSubmit.current.storeCallbacksRef.onSubmit(
      closeDoc ? closeDoc : false,
      processDoc ? processDoc : false
    );
  };

  // This function update record
  const update = (id: any) => {
    draftUpdateType = UPDATE_TYPES.UPDATE;
    dispatch(setDraftActionAvailibility({ id, status: true }));
    callbackRefOnSubmit.current.storeCallbacksRef.updateBill();
  };

  // This fynction update form populate data to draft when view changes of draft
  const savePopulateData = (id: any) => {
    draftUpdateType = UPDATE_TYPES.SAVE_POPULATE_DATA;
    callbackRefOnSubmit.current.storeCallbacksRef.saveAsDraft();
  };

  // update the memo in read only mode for invoice
  const updateMemo = (id: any) => {
    draftUpdateType = 'save_memo_in_readOnly';
    callbackRefOnSubmit.current.storeCallbacksRef.saveAsDraft();
  };

  const rateAnalysis = () => {};

  // Render draft footer section
  const renderFooterSection = () => {
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <div className="footer p-m position-absolute d-flex">
            <button
              disabled={props.draftData.isLoading}
              className={`${
                props.draftData.isLoading
                  ? `bg-gray1 text-gray`
                  : `bg-app text-white`
              } mr-s row border-radius-m text-wrap-none mr-s p-h-s p-v-xs`}
              onClick={() => save(props.draftData.id)}
            >
              {t(`DRAFTS.SAVE`)}
            </button>
            <button
              disabled={props.draftData.isLoading}
              className={`${
                props.draftData.isLoading
                  ? `bg-gray1 text-gray`
                  : `bg-gray2 border-m`
              } mr-s row border-radius-m text-wrap-none mr-s p-h-s p-v-xs`}
              onClick={() => saveAsDraft(props.draftData.id)}
            >
              {t(`DRAFTS.SAVE_AS_DRAFT`)}
            </button>
          </div>
        );
      case DraftTypes.UPDATE:
        return (
          <div className="footer p-m position-absolute d-flex">
            <button
              disabled={props.draftData.isLoading}
              className={`${
                props.draftData.isLoading
                  ? `bg-gray1 text-gray`
                  : `bg-app text-white`
              } mr-s row border-radius-m text-wrap-none mr-s p-h-s p-v-xs`}
              onClick={() => update(props.draftData.id)}
            >
              Update
            </button>
          </div>
        );
    }
  };

  const updateRateAnalysis = (formdata: any) => {
    if (
      tenantInfo?.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_SALES_INVOICE ||
      tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_SALES_ORDER ||
      tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_QUOTATION
    ) {
      RateAnalysisService.updateRateAnalysis({
        documentCode: '',
        documentType: formdata.documentType,
        productPriceMethod:
          tenantInfo?.additionalSettings.RATE_ANALYSIS?.PRODUCT_PRICE_METHOD,
        isDraft: props?.draftData.draftType == 'draft' ? true : false,
        draftId: props?.draftData.id,
        tableId: tableId
      })
        .then(() => {})
        .catch((err: any) => {
          console.error('Error updating rate analysis: ', err);
        });
    }
  };

  // Render product form here
  const renderProductForm = () => {
    // Return your form here
    return <h1>Hello product form</h1>;
  };

  const receiveFormData = async (formdata: any) => {
    const updatedDraftInfo = Store.getState().drafts.data.find(
      (x: any) => x.id === props.draftData.id
    );
    const documentTouched =
      formdata?.autoCreatePOFromWO ?? formdata.isDocumentTouched;
    if (
      props.draftData.draftType === DraftTypes.NEW &&
      !documentTouched &&
      updatedDraftInfo.isMaximized
    ) {
      try {
        dispatch(removeDraft(props.draftData.id));
        dispatch(
          setDraftActionAvailibility({ id: props.draftData.id, status: false })
        );
      } catch (err) {
        console.error('Error deleting draft record: ', err);
      }
    } else {
      switch (draftUpdateType) {
        case UPDATE_TYPES.UPDATE:
          let draftResponse;
          dispatch(removeDraft(props.draftData.id));
          if (
            props.draftData.draftType === DraftTypes.NEW ||
            props.draftData.draftType === DraftTypes.COPY
          ) {
            try {
              draftResponse = await DraftService.createRecord(
                formdata,
                props.draftData
              );
              let sourceDocument = DraftService.getSourceDoc(formdata);
              switch (sourceDocument?.documentType) {
                case DOC_TYPE.QUOTE:
                  dispatch(fetchQuotes());
                  break;
                case DOC_TYPE.SALES_ORDER:
                  dispatch(fetchSalesOrders());
                  break;
                case DOC_TYPE.ORDER:
                  dispatch(fetchOrders());
                  break;
              }
            } catch (err) {
              console.error('error creating draft', err);
            }
          } else {
            try {
              draftResponse = await DraftService.updateRecord(
                formdata,
                props.draftData,
                columnConfig,
                tableId
              );
            } catch (err) {
              console.error('error updating draft', err);
            }
            if (
              tenantInfo?.additionalSettings?.ENABLE_RATE_ANALYSIS &&
              (formdata.documentType === DOC_TYPE.QUOTE ||
                formdata.documentType === DOC_TYPE.SALES_ORDER ||
                formdata.documentType === DOC_TYPE.INVOICE)
            ) {
              updateRateAnalysis(formdata);
            }
          }
          dispatch(fetchDrafts({ tableId, isSaveColumnId: isSavedColumnId }));
          dispatch(
            setDraftActionAvailibility({
              id: props.draftData.id,
              status: false
            })
          );

          return draftResponse;
        case UPDATE_TYPES.SAVE_POPULATE_DATA:
          dispatch(setPopulateFormData({ id: props.draftData.id, formdata }));
          dispatch(
            setDraftActionAvailibility({
              id: props.draftData.id,
              status: false
            })
          );
          break;
        case 'save_memo_in_readOnly':
          changeMemo(formdata);
          break;
      }
    }
  };
  const changeMemo = (formdata: any) => {
    InvoiceService.updateMemo(formdata.memo, formdata.salesInvoiceCode).then(
      (res: any) => {
        dispatch(removeDraft(props.draftData.id));
      }
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_QUOTE:
      case POPUP_CALLBACKS_TYPE.CREATE_SALES_ORDER:
      case POPUP_CALLBACKS_TYPE.CREATE_ORDER:
      case POPUP_CALLBACKS_TYPE.CREATE_BILL:
      case POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_BILL:
      case POPUP_CALLBACKS_TYPE.CREATE_INVOICE:
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        callbackRefOnSubmit.current.storeCallbacksRef.onSubmit =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_BILL:
      case POPUP_CALLBACKS_TYPE.UPDATE_INVOICE:
      case POPUP_CALLBACKS_TYPE.UPDATE_ORDER:
      case POPUP_CALLBACKS_TYPE.UPDATE_QUOTE:
      case POPUP_CALLBACKS_TYPE.UPDATE_SALES_ORDER:
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        callbackRefOnSubmit.current.storeCallbacksRef.updateBill =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT:
        callbackRefOnSubmit.current.storeCallbacksRef.saveAsDraft =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP:
        callbackRefOnSubmit.current.storeCallbacksRef.closeDraftPopup =
          passingData.data;
        break;
    }
  };

  // Render invoice form here
  const renderInvoiceForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewInvoice
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewInvoice
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewInvoice
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
    }
  };
  // Render revrec form here
  const renderRevRecForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewRevRec
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewRevRec
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewRevRec
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
    }
  };

  // Render bills form here
  const renderBillsForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewBill
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewBill
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewBill
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };
  const renderFABillForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewFABill
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewFABill
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewFABill
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };
  const renderFAOrderForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewFAPurchaseOrder
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewFAPurchaseOrder
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewFAPurchaseOrder
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };

  // Render Expense bills form here
  const renderExpenseBillsForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewExpenseBill2
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            populateFormData={populateFormData}
            canValidate={props.draftData.canValidate}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewExpenseBill2
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
            populateFormData={populateFormData}
            canValidate={props.draftData.canValidate}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewExpenseBill2
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
            populateFormData={populateFormData}
            canValidate={props.draftData.canValidate}
          />
        );
    }
  };

  // Render order form here
  const renderOrderForm = (populateFormData: any) => {
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewOrder
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewOrder
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
      case DraftTypes.LOCKED:
        return (
          <NewOrder
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };

  // Render PIQ form here
  const renderPIQForm = (populateFormData: any) => {
    switch (props.draftData.draftType) {
      case DraftTypes.READONLY:
        return (
          <NewPIQ
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };

  // Render workout here
  const renderWorkOutForm = (populateFormData: any) => {
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewWorkOut
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewWorkOut
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewWorkOut
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };

  // Render quote form here
  const renderRequisitionForm = (populateFormData: any) => {
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewRequisition
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            isCenterAlign={true}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewRequisition
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewRequisition
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isCenterAlign={true}
          />
        );
    }
  };

  // Render quote form here
  const renderQuoteForm = (populateFormData: any) => {
    // Return your form here

    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewQuote
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewQuote
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
      case DraftTypes.READONLY:
        return (
          <NewQuote
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
    }
  };

  // Render sales order form here
  const renderSalesOrderForm = (populateFormData: any) => {
    // Return your form here

    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
        return (
          <NewSalesOrder
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentMode={
              props.draftData.draftType === DraftTypes.COPY
                ? DOCUMENT_MODE.COPY
                : DOCUMENT_MODE.NEW
            }
            draftData={props.draftData}
            formData={receiveFormData}
            populateFormData={populateFormData}
          />
        );
      case DraftTypes.UPDATE:
        return (
          <NewSalesOrder
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.EDIT}
            draftData={props.draftData}
            formData={receiveFormData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
      case DraftTypes.READONLY:
      case DraftTypes.LOCKED:
        return (
          <NewSalesOrder
            isCenterAlign={true}
            populateFormData={populateFormData}
            documentMode={DOCUMENT_MODE.VIEW}
            draftData={props.draftData}
            formData={(data: any) => {}}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        );
    }
  };

  // Render amortization schedule form here
  const renderAmortizationScheduleForm = (populateFormData: any) => {
    // Return your form here
    switch (props.draftData.draftType) {
      case DraftTypes.NEW:
      case DraftTypes.COPY:
      case DraftTypes.DRAFT:
      case DraftTypes.UPDATE:
      case DraftTypes.READONLY:
        return (
          <AmortizationScheduleDoc
            documentMode={props.documentMode}
            populateFormData={populateFormData}
            draftType={props.draftData.draftType}
          />
        );
    }
  };

  switch (props.draftData.type) {
    case LABELS.PRODUCTS:
      return (
        <>
          <div className="content-container p-m">{renderProductForm()}</div>
          {renderFooterSection()}
        </>
      );
    case LABELS.INVOICES:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderInvoiceForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.REV_REC:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderRevRecForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.BILLS:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderBillsForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.FA_BILL:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderFABillForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.FA_ORDER:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderFAOrderForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.EXPENSE_BILL:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderExpenseBillsForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.PURCHASE_ORDERS:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderOrderForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.WORK_OUT:
      return (
        <>
          <div className="parent-width parent-height dk-input-holder overflow-y-scroll show-scroll-bar">
            {renderWorkOutForm(props.draftData.populateFormData)}
          </div>
        </>
      );

    case LABELS.QUOTES:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-auto show-scroll-bar">
            {renderQuoteForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.SALES_ORDER:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-auto show-scroll-bar">
            {renderSalesOrderForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.REQUISITION:
      return (
        <>
          <div className="parent-width parent-height dk-input-holder overflow-y-auto show-scroll-bar">
            {renderRequisitionForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.AMORTIZATION_SCHEDULE:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderAmortizationScheduleForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    case LABELS.PURCHASE_INWARD_QUOTATION:
      return (
        <>
          <div className="document-form dk-input-holder parent-width parent-height overflow-y-scroll show-scroll-bar">
            {renderPIQForm(props.draftData.populateFormData)}
          </div>
        </>
      );
    default:
      return <p>Something went wrong, please try again !!!</p>;
  }
});

export default DraftForms;
