
import ApiConstants from "../Constants/ApiConstants";
import http from './Interceptor';

export interface FulfillmentMasterAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
  StartDate?: string;
  EndDate?: string;
  format?: 'XLS' | '';
  SalesPerson?: number;
}

export const defaultConfig: FulfillmentMasterAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '&sort=code&sortDir=DESC',
  QuerySummary: '',
  StartDate: '',
  EndDate: '',
  format: '',
  SalesPerson: 0
};

class FulfillmentMasterService {

  static selectedIndex : number = 0;

  static getSelectedIndex() {
    return FulfillmentMasterService.selectedIndex;
  }

  static setSelectedIndex(index:number) {
    FulfillmentMasterService.selectedIndex = index;
  }

  static apiConfig: FulfillmentMasterAPIConfig = defaultConfig
  private static async fetchGoodsReceivedNotesReport(endpoint: string, config: FulfillmentMasterAPIConfig, options: any = {}) {
    const searchParams = new URLSearchParams({
      limit: config.Limit?.toString() ?? "25",
      page: config.Page?.toString() ?? "0",
      search: config.SearchTerm ?? "",
      query: config.Query ?? "",
      fetchAttachmentDetails:"true"
    });
    if (config.format) {
      searchParams.append("format", config.format)
    }
    let salesPersonQuery = config.SalesPerson
      ? '&salesperson=' + config.SalesPerson
      : '';

    const finalEndpoint: string =
      endpoint +
      '?' +
      searchParams.toString() +
      config.QueryParam.toString() +
      salesPersonQuery;

    return http
      .get(`${finalEndpoint}`, options)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getFulfillmentMasterData() {
    const url = ApiConstants.URL.FULFILLMENT.GET_ALL_FULFILLMENTS;
    return this.fetchGoodsReceivedNotesReport(url, this.apiConfig, {}).then((response: any) => {
      return Promise.resolve(response);
    }).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default FulfillmentMasterService;
