import axios from 'axios';
import { showAlert, TOAST_TYPE, showToast } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import {
  COUNTRY_CODES,
  COUNTRY_PLACE_HOLDER,
  TALK_TO_SALES_URL
} from '../Constants/Constant';
import { Store } from '../Redux/Store';
import Utility from '../Utility/Utility';
import AuthService from './Auth';
import { commonCustomEvent, COMMON_EVENTS } from './event/commonEvents';
import { InterceptorWrapper } from './InterceptorWrapper';
const ERRORS_TO_IGNORE = [
  'Billing Info not added yet',
  'TDS rate not found.',
  'Rfq not found',
  'Reminder settings not available.',
  'Logged in user not admin',
  'User is not authorized to perform the operation.',
  'Failed due to exception while processing the request. Error Message: 403 null'
];
// const SERVER_ERROR_TEXT = 'Network Error';
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.BASE
});

const FAILED_REQUEST_MAX_RETRY_COUNT = 2;
const FAILED_REQUEST_RETRY_DELAY = 2000;
const HTTP_STATUS_CODE_403 = 403;

const retryApiCall = async (error: any) => {
  const errorConfig = { ...error.config };

  errorConfig._retryCount = errorConfig?._retryCount || 0;

  if (
    errorConfig?._retryCount >= FAILED_REQUEST_MAX_RETRY_COUNT ||
    (errorConfig.method === 'post' && !errorConfig.params?._allowRetry)
  ) {
    commonCustomEvent.dispatch(COMMON_EVENTS.ERROR_OCCURRED, { detail: {} });
    return Promise.reject(error);
  }

  errorConfig._retryCount += 1;
  errorConfig._isRetryRequest = true;
  const delayRetry = new Promise((resolve: any) =>
    setTimeout(() => resolve(), FAILED_REQUEST_RETRY_DELAY)
  );
  return delayRetry.then(() => axiosInstance.request(errorConfig));
};

const onEmptyResponse = async (error: any) => {
  if (!AuthService.didSessionExpired && !error?.config?._isRetryRequest) {
    const authData: any = await AuthService.checkIfUserLoggedIn(
      () => {},
      () => {},
      true
    );
    if (authData && authData?.accessToken) {
      // ACTIVE SESSION // DO NOTHING
    } else {
      return Promise.reject(error);
    }
  }

  return retryApiCall(error);
};

const requestInterceptSuccess = (config: any) => {
  let countryInURL = '';
  const tenantCountry =
    Store.getState().authInfo.currentTenantInfo.data.country;
  const complianceEnabled =
    Store.getState().authInfo.currentTenantInfo.data.complianceEnabled || false;
  if (
    (tenantCountry === COUNTRY_CODES.IN && complianceEnabled) ||
    tenantCountry === COUNTRY_CODES.ID ||
    tenantCountry === COUNTRY_CODES.PH ||
    tenantCountry === COUNTRY_CODES.MY ||
    tenantCountry === COUNTRY_CODES.AE ||
    tenantCountry === COUNTRY_CODES.AU ||
    tenantCountry === COUNTRY_CODES.NL ||
    tenantCountry === COUNTRY_CODES.BE ||
    tenantCountry === COUNTRY_CODES.CA ||
    tenantCountry === COUNTRY_CODES.NZ ||
    tenantCountry === COUNTRY_CODES.UK ||
    tenantCountry === COUNTRY_CODES.DE ||
    tenantCountry === COUNTRY_CODES.SA
  ) {
    countryInURL = `/${tenantCountry.toLowerCase()}`;
  }
  config.url = config.url.replace(COUNTRY_PLACE_HOLDER, countryInURL);
  return config;
};

const showAlertOnError = (message: string) => {
  if (!message.includes('exception')) {
    showAlert('Error!', message);
  } else {
    showAlert('Error!', 'Error processing request. Please try again!');
  }
};

const showCreditLimitExhaustedAlert = (message: string, errorCode: number) => {
  if (!Utility.isEmpty(message)) {
    const title = 'Credit limit exceeded!';
    const btnTitle = errorCode === 5000 ? 'Add Credits' : 'Talk to Sales';
    showAlert(
      title,
      "It looks like you don't have any credit available, please upgrade your plan or add credits to your account to continue.",
      [
        {
          title: 'Cancel',
          className: 'bg-white border-m mr-r',
          onClick: () => {}
        },
        {
          title: btnTitle,
          className: 'bg-button text-white',
          onClick: () => {
            if (errorCode === 5000) {
              window.open(
                process.env.REACT_APP_URL_GO + 'billing/add-credit',
                '_blank'
              );
            } else if (errorCode === 10000) {
              window?.open(TALK_TO_SALES_URL, '_blank');
            }
          }
        }
      ]
    );
  }
};

const requestInterceptError = (error: any) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response: any) => {
  AuthService.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    let message = response.data.errorMessage;
    if (
      response.data.debugMessage &&
      response.data.debugMessage.length > response.data.errorMessage.length
    ) {
      message = response.data.debugMessage;
    }
    // showAlert('Error occurred!', message);
    if (!message.includes('exception')) {
      showToast(message, TOAST_TYPE.ERROR);
    }
    return Promise.reject(response.data);
  }
  return response.data;
};
const responseInterceptError = (error: any) => {
  if (HTTP_STATUS_CODE_403 === error?.response?.status) {
    console.error(
      `API failed due to insufficient permission ${error?.request?.responseURL}`
    );
    return Promise.reject(error);
  }

  if (Utility.isEmpty(error.response)) {
    // This is a work around to detect whether failed api is IAM login
    // ideally we should give some specific status code like 403 for failed login from backend itself
    if (error?.message === 'canceled') {
      return Promise.reject(error);
    }

    return onEmptyResponse(error);
  } else {
    if (error.response?.data && !Utility.isEmptyObject(error.response?.data)) {
      if (
        (error.response.data.errorCode || error.response.data.code) &&
        error.response.data.errorMessage
      )
        if (!ERRORS_TO_IGNORE.includes(error.response.data.errorMessage)) {
          if (
            error.response.data.errorMessage.toLowerCase() ===
              'user not authorized' ||
            error.response.data.errorMessage.toLowerCase() ===
              'user not authorised'
          ) {
            showAlertOnError(error.response.data.errorMessage);
          } else {
            if (
              error.config?.url &&
              (error.response.data.errorMessage === 'record not found' ||
                error.response.data.errorMessage.includes(
                  'pending for approval against this order'
                ))
            ) {
              // no alert required if helpcenter get
            } else {
              let message = error.response.data.errorMessage;
              if (
                error.response.data.debugMessage?.length >
                error.response.data.errorMessage.length
              ) {
                message = error.response.data.debugMessage;
              }
              // Check for credit limit exhausted message
              if (
                error.response?.data?.errorCode === 10000 ||
                error.response?.data?.errorCode === 5000
              ) {
                showCreditLimitExhaustedAlert(
                  message,
                  error.response?.data?.errorCode
                );
              } else {
                showAlertOnError(message);
              }
            }
          }
        }
      return Promise.reject(error.response.data);
    } else {
      console.error('Error processing request: ', error.response);
      return Promise.reject(error.response);
    }
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default InterceptorWrapper(axiosInstance);
