import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import TCSService from '../../Services/TCSSetup';
import { RootState } from '../Store';

export interface TCSSetupState {
  data: any;
  columnConfig: IColumn[];
  configTableId: string;
  status: API_STATUS;
  formData: {
    tcsRates: any;
    tcsNature: any;
    tcsActiveRates: any;
  };
}

const initialState: TCSSetupState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  formData: {
    tcsRates: [],
    tcsNature: [],
    tcsActiveRates: []
  }
};

export const fetchTCSRates = createAsyncThunk(
  'fetchTCSRates',
  async (tenantId: number) => {
    const response = await TCSService.getAllTCS(tenantId);
    return response;
  }
);

export const fetchTCSNature = createAsyncThunk(
  'fetchTCSNature',
  async (tenantId: number) => {
    const response = await TCSService.getAllTCSNature(tenantId);
    return response;
  }
);

export const TCSSetupSlice = createSlice({
  name: 'TCSSetup',
  initialState,
  reducers: {
    addTCSRatesColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addTCSRates: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTCSRates.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTCSRates.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const payload: any = action.payload;
        state.formData.tcsRates = payload;
        state.formData.tcsActiveRates = payload.filter((rate: any) => {
          return rate.active !== false;
        });
      })
      .addCase(fetchTCSNature.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTCSNature.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const payload: any = action.payload;
        state.formData.tcsNature = payload;
      });
  }
});

export const { addTCSRatesColumnConfig, addTCSRates, updateColumnConfig } =
  TCSSetupSlice.actions;

export const selectTCSRates = (state: RootState) =>
  state.tcsSetup.formData.tcsRates;

export const selectActiveTCSRates = (state: RootState) =>
  state.tcsSetup.formData.tcsActiveRates;

export const selectTCSNature = (state: RootState) =>
  state.tcsSetup.formData.tcsNature;

export const selectTCSRatesColumnConfig = (state: RootState) =>
  tcsRateColumnConfig;

export const selectTCSSetupLoadingStatus = (state: RootState) =>
  state.tcsSetup.status;

export const selectTCSSetupColumnConfigTableId = (state: RootState) =>
  state.tcsSetup.configTableId;

export default TCSSetupSlice.reducer;

const tcsRateColumnConfig: any = [
  {
    name: 'Nature of Collection',
    type: 'text',
    index: 0,
    options: null,
    required: true,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'tcsCollectionNatureId',
    key: 'tcsCollectionNatureId'
  },
  {
    name: 'Tax Name',
    type: 'text',
    index: 1,
    options: null,
    required: true,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'taxName',
    key: 'taxName'
  },
  {
    name: 'TCS Payable Account',
    type: 'text',
    index: 2,
    options: null,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'accountPayable',
    key: 'accountPayable'
  },
  {
    name: 'TCS Receivable Account',
    type: 'text',
    index: 3,
    options: null,
    required: true,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'accountReceivable',
    key: 'accountReceivable'
  },
  {
    name: 'TCS Rate',
    type: 'number',
    index: 4,
    options: null,
    required: true,
    width: 230,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'ratePercentage',
    key: 'ratePercentage'
  }
];
