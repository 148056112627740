import { CancelTokenSource } from 'axios';
import ApiConstants from '../Constants/ApiConstants';
import {
  APP_NAME,
  DOC_TYPE,
  PAYMENT_TYPES,
  REPORTS,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY
} from '../Constants/Constant';
import http from './Interceptor';

class PrintService {
  // Get existing templates: https://api-dev.deskera.xyz/v1/template/document/ERP/PURCHASE_ORDER
  // Get default mappings: https://api-dev.deskera.xyz/v1/template/document/default-mapping?contact=C-0000032&document=PURCHASE_ORDER&event=PRINT
  // Get GUID: https://api-dev.deskera.xyz/v1/template/print-info/PURCHASE_ORDER/0000052
  // Get PDF BLOB: https://api-dev.deskera.xyz/v1/template/print-info/print-design-doc-by-uid/1d4e127c-58a1-420a-af32-f15cb6e0c3fc
  // PDFJS workder URL: get from CDN

  static getInvoiceDesignTemplatesByCategory(
    documentType: any
  ): Promise<any[]> {
    const endPoint = ApiConstants.URL.PRINT.GET_DESIGN_TEMPLATE_LIST(
      PrintService.getTemplateNameType(documentType.toString().toLowerCase()),
      APP_NAME
    );
    return http.get(endPoint);
  }

  static getTemplateMapping(
    documentType: any,
    contactCode: string,
    event: TemplateMappingEvent
  ): Promise<any> {
    const queryString = `?contact=${contactCode}&document=${PrintService.getTemplateNameType(
      documentType.toString().toLowerCase()
    )}&event=${event}`;
    const endPoint = ApiConstants.URL.PRINT.DEFAULT_MAPPING + queryString;
    return http.get(endPoint);
  }

  static getDocumentPrintUUID(
    documentCode: string,
    documentType: string,
    sourceDocType?: string
  ): Promise<any> {
    const endPoint =
      ApiConstants.URL.PRINT.PRINTINFO +
      `/${PrintService.getTemplateNameType(
        documentType.toString().toLowerCase()
      )}/${documentCode}` +
      (sourceDocType ? `?category=${sourceDocType}` : '');

    return http.get(endPoint);
  }

  static getDocumentPrintUUIDWithMetaCode(
    documentCode: string,
    documentType: string,
    documentMetaCode: string
  ): Promise<any> {
    const endPoint =
      ApiConstants.URL.PRINT.PRINTINFO +
      `/${PrintService.getTemplateNameType(
        documentType.toString().toLowerCase()
      )}/${documentCode}` +
      (documentMetaCode ? `?documentMetacode=${documentMetaCode}` : '');

    return http.get(endPoint);
  }

  static printCustomThermalPrint(documentPrintUUID: string): Promise<any> {
    let endPoint =
      ApiConstants.URL.PRINT.PRINT_CUSTOM_THERMAL_TEMPLATE_BY_UUID(
        documentPrintUUID
      );
    return http.get(endPoint, { responseType: 'text' });
  }

  static printCustomTemplateInvoice(
    documentPrintUUID: string,
    templateId: any,
    preview = true,
    pageFormat = '',
    pageOrientation = 'portrait',
    wmark = '',
    source?: CancelTokenSource
  ): Promise<any> {
    let endPoint =
      ApiConstants.URL.PRINT.PRINT_CUSTOM_TEMPLATE_BY_UUID(documentPrintUUID);

    if (templateId) {
      endPoint += '?tid=' + templateId;
    }
    if (!preview) {
      endPoint += templateId ? '&preview=' + preview : '?preview=' + preview;
    }

    if (pageFormat) {
      endPoint +=
        templateId || !preview
          ? '&paperSize=' + pageFormat
          : '?paperSize=' + pageFormat;
    }

    if (wmark) {
      endPoint +=
        templateId || !preview || pageFormat
          ? '&wmark=' + wmark
          : '?wmark=' + wmark;
    }

    if (pageOrientation) {
      endPoint += '&pageOrientation=' + pageOrientation;
    }

    endPoint += '&skipDocumentTemplateMapping=true';

    if (source) {
      return http.get(endPoint, {
        responseType: 'arraybuffer',
        cancelToken: source.token
      });
    }

    return http.get(endPoint, { responseType: 'arraybuffer' });
  }

  static bulkPrintCustomTemplateInvoice(
    documentPrintUUID: string,
    templateId: any,
    preview = true,
    pageFormat = '',
    pageOrientation = 'portrait',
    wmark = '',
    source: CancelTokenSource
  ): Promise<any> {
    let endPoint =
      ApiConstants.URL.PRINT.BULK_PRINT_CUSTOM_TEMPLATE_BY_UUID(
        documentPrintUUID
      );

    if (templateId) {
      endPoint += '&tid=' + templateId;
    }
    if (!preview) {
      endPoint += '&preview=' + preview;
    }

    if (pageFormat) {
      endPoint += '&paperSize=' + pageFormat;
    }

    if (wmark) {
      endPoint += '&wmark=' + wmark;
    }

    if (pageOrientation) {
      endPoint += '&pageOrientation=' + pageOrientation;
    }

    endPoint += '&skipDocumentTemplateMapping=true';

    return http.get(endPoint, {
      responseType: 'arraybuffer',
      cancelToken: source.token
    });
  }

  static printRecords(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRINT.PRINT_RECORDS;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTemplateNameType(type: string) {
    if (
      type === 'quote' ||
      type === 'quotation' ||
      type === 'estimate' ||
      type === 'quote'
    ) {
      return 'QUOTATION';
    } else if (type === 'invoice') {
      return 'SALES_INVOICE';
    } else if (type === 'order') {
      return 'PURCHASE_ORDER';
    } else if (type === 'credit_note' || type === 'credit note') {
      return 'CREDIT_NOTE';
    } else if (type === 'debit_note' || type === 'debit name') {
      return 'DEBIT_NOTE';
    } else if (type === 'expense') {
      return 'EXPENSE';
    } else if (type === 'deposit') {
      return 'DEPOSIT';
    } else if (type === 'bill' || type === 'bills' || type === 'expense_bill') {
      return 'PURCHASE_INVOICE';
    } else if (type === 'pick-pack/picklist') {
      return DOC_TYPE.PICKLIST;
    } else if (type === 'pick-pack/packlist') {
      return DOC_TYPE.PACKLIST;
    } else if (type === 'pick-pack/shiplist') {
      return DOC_TYPE.SHIPLIST;
    } else if (type === DOC_TYPE.WORK_ORDER) {
      return DOC_TYPE.WORK_ORDER;
    } else if (type === DOC_TYPE.STOCK_TRANSFER) {
      return DOC_TYPE.STOCK_TRANSFER;
    } else if (type === DOC_TYPE.STOCK_ADJUSTMENT) {
      return DOC_TYPE.STOCK_ADJUSTMENT;
    } else if (type === DOC_TYPE.SALES_RETURN) {
      return DOC_TYPE.SALES_RETURN;
    } else {
      return type.toUpperCase();
    }
  }

  static getPrintDocumentCategory = (docType: any) => {
    switch (docType) {
      case TEMPLATE_CATEGORY.QUOTE: {
        return DOC_TYPE.QUOTE;
      }
      case TEMPLATE_CATEGORY.INVOICE: {
        return DOC_TYPE.INVOICE;
      }
      case REPORTS.STATEMENT_OF_ACCOUNTS: {
        return docType;
      }
      case TEMPLATE_CATEGORY.ORDER: {
        return DOC_TYPE.ORDER;
      }
      case TEMPLATE_CATEGORY.BILLS: {
        return DOC_TYPE.BILL;
      }

      case PAYMENT_TYPES.MAKE_PAYMENT:
      case PAYMENT_TYPES.RECEIVE_PAYMENT:
        return docType;

      case PAYMENT_TYPES.PICKLIST:
        return DOC_TYPE.PICKLIST;

      case PAYMENT_TYPES.PACKLIST:
        return DOC_TYPE.PACKLIST;

      case PAYMENT_TYPES.SHIPLIST:
        return DOC_TYPE.SHIPLIST;

      case DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES:
        return DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES;

      default:
        return docType;
    }
  };

  static getTemplatePrintInfo(payload: any) {
    const endPoint = ApiConstants.URL.PRINT.TEMPLATE_PRINT_INFO;
    return http.post(endPoint, payload);
  }

  static getTemplateBulkPrintInfo(payload: any) {
    const endPoint = ApiConstants.URL.PRINT.TEMPLATE_BULK_PRINT_INFO;
    return http.post(endPoint, payload);
  }

  static getTemplateList(moduleName: string, category: string) {
    const endPoint = ApiConstants.URL.PRINT.GET_TEMPLATE_LIST(
      moduleName,
      category
    );
    return http.get(endPoint);
  }

  static exportTemplateDocument(payload: any) {
    const endPoint = ApiConstants.URL.PRINT.EXPORT_DOCUMENT_TEMPLATE;
    return http.post(endPoint, payload, {
      responseType: 'blob'
    });
  }

  static setContactTemplate(payload: any) {
    const endPoint = ApiConstants.URL.PRINT.SET_CONTACT_TEMPLATE;
    return http.put(endPoint, payload);
  }

  static setDefaultTemplate(templateId: any) {
    const endPoint =
      ApiConstants.URL.PRINT.SET_DEFAULT_TEMPLATE + `/${templateId}`;
    return http.put(endPoint, {});
  }

  static deleteTemplate(templateId: any) {
    const endPoint =
      ApiConstants.URL.PRINT.TEMPLATE_DOCUMENT + `/${templateId}`;
    return http.delete(endPoint, {});
  }
}
export default PrintService;
