import { INPUT_TYPE, DKIcons } from 'deskera-ui-library';
import { DocumentConfigUtility } from '../Utility/DocumentConfigUtility';
import ProductService, { ProductAPIConfig } from '../Services/Product';
import TaxService, { defaultConfig as TaxAPIConfig } from '../Services/Tax';
import ApiConstants from '../Constants/ApiConstants';
import { DOC_TYPE, TAX_SYSTEM } from '../Constants/Constant';
import { getTenantTaxSystem } from '../SharedComponents/DocumentForm/NewDocumentHelper';
import { NotesConfigUtility } from '../Utility/NotesConfigUtility';
import AccountsService, { AccountAPIConfig } from '../Services/Accounts';
export class NotesConfigManager {
  static docConfigs: any = [
    {
      id: 'account',
      key: 'lineSelectedAccount',
      name: 'Category',
      type: INPUT_TYPE.DROPDOWN,
      width: 200,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: DocumentConfigUtility.productFormatter,
      dropdownConfig: {
        title: 'Select Account',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: {
          getUrl: null,
          dataParser: DocumentConfigUtility.productDataParser,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.productRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'amount',
      key: 'lineAmount',
      name: 'Amount',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'tax',
      key: 'lineSelectedTax',
      name: 'Tax',
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right',
      formatter: (obj: any) => {
        return obj.value.name;
      },
      renderer: DocumentConfigUtility.taxRenderer,
      dropdownConfig: {
        title: 'Select Tax',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: null,
          dataParser: null,
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.taxOptionRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      }
    },
    {
      id: 'taxAmount',
      key: 'lineTaxAmount',
      name: 'Tax Amount',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'lineAmount',
      key: 'lineLineAmount',
      name: 'Line Amount',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'lineAmtInBase',
      key: 'lineAmtInBase',
      name: 'Amount in Base',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      systemField: true,
      editable: false,
      hidden: true,
      uiVisible: false,
      textAlign: 'right'
    }
  ];

  static getAccountURL = (search: string) => {
    const config: AccountAPIConfig = {
      ...AccountsService.apiConfig,
      Page: 0,
      SearchTerm: search,
      Limit: 10,
      Query: 'status=ACTIVE'
    };
    AccountsService.apiConfig = config;
    return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
  };
  static getTaxURL = (search: string, date?: any) => {
    TaxService.apiConfig = { ...TaxAPIConfig };
    TaxService.apiConfig.Search = search;
    TaxService.apiConfig.Limit = 100;
    TaxService.apiConfig.Query = `status=active,taxEffectiveDate=${date}`;
    return (
      ApiConstants.URL.BASE.replace('v1/', 'v1') + TaxService.getTaxEndPoint()
    );
  };
}
