import Utility from '../../../Utility/Utility';
import { INPUT_TYPE } from 'deskera-ui-library';

export const sortAndUpdateColumns = (columns: any[]) => {
  const updatedColumns: any[] = [];
  columns?.forEach((column) => {
    if (column.systemField) {
      updatedColumns.push({ ...column, key: column.columnCode });
    }
  });
  return updatedColumns.sort((a: any, b: any) => a.index - b.index);
};

// export const getQueryFromFilter = (
//   columns: any[],
//   filterConditions: any,
//   dateFilters: any,
//   documentType:any
// ) => {
//   let query = [];
//   if (!Utility.isEmpty(filterConditions)) {
//     query = filterConditions?.map((filter: any) => {
//       let colName = columns.find((col) => col.id === filter.key);
//       let value = filter.value;
//       if (colName.type === INPUT_TYPE.DATE) {
//         value = getFullDateString(filter.value);
//         if (filter.condition === FILTER_OPERATORS.GREATER_THAN) {
//           return `${colName?.columnCode}>=${value}`;
//         } else if (filter.condition === FILTER_OPERATORS.LESS_THAN) {
//           return `${colName?.columnCode}<=${value}`;
//         }
//       }
//       if (colName?.columnCode === 'recurring') {
//         value = filter.value[0] === 'RECURRING' ? true : false;
//       }
//       return `${colName?.columnCode}=${value}`;
//     });
//   }
//   if (isNotEmpty(dateFilters)) {
//     let dateKey = '';
//     if (documentType === BOOKS_DOCS_TYPES.BOOKS_INVOICE) {
//       dateKey = COLUMN_CODE.INVOICE.InvoiceDate;
//     } else if (
//       documentType === BOOKS_DOCS_TYPES.BOOKS_QUOTE ||
//       documentType === BOOKS_DOCS_TYPES.BOOKS_PAYMENT
//     ) {
//       dateKey = COLUMN_CODE.QUOTE.QUOTE_DATE;
//     }

//     query?.push(
//       `${dateKey}>=${getFullDateString(dateFilters?.financialStartDate)}`
//     );
//     query?.push(
//       `${dateKey}<=${getFullDateString(dateFilters?.financialEndDate)}`
//     );
//   }
//   return query;
// };
