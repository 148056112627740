import { DKButton, DKDataGrid, DKLabel } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import Utility from '../../../../../Utility/Utility';
import { BomConfigurationModel } from '../../../BOM_Assembly/BomDetailsTabProduct/BomConfigurationModel';
import { populateProductDetailWithSelectedOrDefaultBom } from '../../WorkOrderHelper';
import { BOM_SELECTION_POPUP_CONFIG } from '../../../Constants/MRPColumnConfigs';
import { DynamicPopupWrapper } from '../../../../../SharedComponents/PopupWrapper';
import { ISalesOrder } from '../../../../../Services/SalesOrder';

export default function BomSelectionPopup(props: any) {
  const [productsArray, setProductsArray] = useState<any>([]);
  const columns = BOM_SELECTION_POPUP_CONFIG.filter((column) => !column.hidden);

  useEffect(() => {
    if (!Utility.isEmpty(props.currentProducts)) {
      const updatedProducts = (props.currentProducts || [])
        .filter((product: any) => Utility.isNotEmpty(product))
        .map((product: any) => {
          product = populateProductDetailWithSelectedOrDefaultBom(product);
          return product;
        });
      setProductsArray(updatedProducts);
    }
  }, [props.currentProducts]);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Select BOM" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={'Continue'}
            className="bg-button text-white"
            onClick={() => {
              props.onContinueButtonTap(productsArray);
            }}
          />
        </div>
      </div>
    );
  };

  const getTitleValue = (title: string, value: any) => {
    return (
      <div className="row width-auto gap-2">
        <DKLabel text={title} className="fw-m" />
        :
        <DKLabel text={value ?? '-'} className="" />
      </div>
    );
  };

  const getDetailsView = () => {
    const isSalesOrderSelected = Utility.isNotEmpty(
      props.salesOrderSequenceCode
    );
    const isSalesInvoiceSelected = Utility.isNotEmpty(
      props.salesInvoiceSequenceCode
    );
    const isBomProductListEmpty = Utility.isEmpty(props.currentProducts);
    let description = '';

    if (isBomProductListEmpty) {
      /* When SO/Material is selected having non wip/fg type products */
      if (isSalesOrderSelected) {
        description =
          'No product with bom configuration found in selected sales order. Please select another sales order with WIP/Finished Good type of product.';
      } else if (isSalesInvoiceSelected) {
        description =
          'No product with bom configuration found in selected sales invoice. Please select another sales invoice with WIP/Finished Good type of product.';
      } else {
        description =
          'No BOM found in selected material, Please select a WIP or Finished goods type of material to proceed.';
      }
    } else if (isSalesOrderSelected) {
      /* When SO/Material is selected having products with multiple bom configs */
      description =
        'The product(s) in your sales order have multiple BOM configurations, please select desired bom or leave the default selected.';
    } else if (isSalesInvoiceSelected) {
      /* When SI/Material is selected having products with multiple bom configs */
      description =
        'The product(s) in your sales invoice have multiple BOM configurations, please select desired bom or leave the default selected.';
    } else {
      /* When material is selected having multiple bom configurations */
      description =
        'Selected material have multiple BOM configurations, please select desired bom or leave the default selected.';
    }

    let soCodes = '';
    if (Array.isArray(props?.salesOrders)) {
      soCodes = props?.salesOrders
        ?.map((order: ISalesOrder) => {
          return order?.documentSequenceCode;
        })
        ?.join(', ');
    }
    soCodes = Array.isArray(props?.salesOrders)
      ? soCodes
      : props.salesOrderSequenceCode;
    return (
      <div className="column parent-width gap-2">
        {isSalesOrderSelected
          ? getTitleValue('Sales Order', soCodes || '-')
          : isSalesInvoiceSelected
          ? getTitleValue(
              'Sales Invoice',
              props.salesInvoiceSequenceCode || '-'
            )
          : null}
        <DKLabel text={description} />
      </div>
    );
  };

  const updateLineItemList = (rowData: any, rowIndex: any, columnKey?: any) => {
    let editedRecords = [...productsArray];
    let edited = { ...editedRecords[rowIndex] };
    edited.invalidFields = edited.invalidFields
      ? [...edited.invalidFields].filter((field) => field !== columnKey)
      : [];

    if (columnKey === 'selectedBom') {
      edited = populateProductDetailWithSelectedOrDefaultBom(
        edited,
        rowData.selectedBom
      );
    }
    editedRecords[rowIndex] = edited;
    setProductsArray(editedRecords);
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const onRowClick = (data: any) => {
    const selectedBomColumnData = columns.find(
      (column) => column.id === 'selectedBom'
    );
    if (selectedBomColumnData?.dropdownConfig) {
      let currentRow = productsArray[data.rowIndex];
      selectedBomColumnData.dropdownConfig.data =
        currentRow?.bomMetaDetailsList?.filter(
          (item: BomConfigurationModel) => item.active
        );
    }
  };

  const getProductSelectionView = () => {
    return (
      <div className="flex flex-col items-start w-full product-selection-view-popup">
        <DKDataGrid
          needShadow={false}
          needColumnIcons={true}
          needBorder={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          rows={productsArray ?? []}
          columns={columns}
          onRowUpdate={onRowUpdate}
          onRowClick={onRowClick}
          width={600}
        />
      </div>
    );
  };

  const getMainView = () => {
    return (
      <div className="column parent-width">
        {getHeader()}
        <div className="column parent-width p-4 gap-2 hide-scroll-bar overflow-visible">
          {getDetailsView()}
          {getProductSelectionView()}
        </div>
      </div>
    );
  };
  return props.isPopup ? (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window shadow border-m"
          style={{
            maxWidth: 650,
            width: '90%',
            maxHeight: '95%',
            padding: 0,
            paddingBottom: 10,
            overflow: 'visible'
          }}
        >
          {getMainView()}
        </div>
      </div>
    </DynamicPopupWrapper>
  ) : (
    getMainView()
  );
}
