export class ColumnStyle {
    width?: string
    height?: string
    backgroundColor?: string
    alignment?: string
    display: string // 'flex, 'none', 'block'
    fontColor?: string;
    fontSize?: string;
    fontStyle?: string;
    fontFamily?: string;
    fontWeight?: string;

    paddingLeft?: string;
    paddingRight?: string;
    paddingTop?: string;
    paddingBottom?: string;


    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;

    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderBottomColor?: string;
    borderBottomWidth?: string;
    borderTopColor?: string;
    borderTopWidth?: string;
    verticalAlignment?: string;
    constructor(){
        this.display = 'flex'
    }
}