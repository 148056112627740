import Utility, { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import DataParser from "../DataParser"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import ComponentManager from "../../Manager/ComponentManager"

export default class PurchaseInwardQuotationParser {
    static parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.piq?.customField
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.piq?.contactDto.name ?? '')

        //company address
        var fromAddressObj = new Address(undefined, dataFromAPI.piq?.shipFrom, dataFromAPI.piq?.contactDto?.pan, dataFromAPI.piq?.contactDto?.gstin, dataFromAPI.piq?.contactDto?.contactNumber, dataFromAPI.piq?.contactDto?.documentSequenceCode, OwnerType.contact)
        fromAddressObj.emailAddress = dataFromAPI.piq?.contactDto?.email ?? ''
        fromAddressObj.contactNumber = dataFromAPI.piq?.contactDto?.contactNumber ?? ''
        fromAddressObj.pan = dataFromAPI.piq?.contactDto?.panIndia ?? ''
        fromAddressObj.cin = dataFromAPI.piq?.contactDto?.cinIndia ?? ''
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        //billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.tenantInfo?.name, dataFromAPI.piq?.billTo, dataFromAPI.tenantInfo?.pan, dataFromAPI.tenantInfo?.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var shipToName = dataFromAPI.companyName ?? dataFromAPI.tenantInfo?.name
        var shipToPan = dataFromAPI.pan ?? dataFromAPI.tenantInfo?.pan
        var shipToGstin = dataFromAPI.gstin ?? dataFromAPI.tenantInfo?.gstin
        var shipToContactName = dataFromAPI.tenantInfo.contactNumber
        var shipToType = OwnerType.tenant
        if (dataFromAPI.dropShipContactName !== undefined && dataFromAPI.dropShipContactName !== null) {
            if (dataFromAPI.dropShipContactName !== '') {
                //fix dropship bill to value different from normal PO
                templateDataToReturn.companyName = getCapitalized(dataFromAPI.clientName)
                shipToName = dataFromAPI.dropShipContactName
                shipToPan = undefined
                shipToGstin = undefined
                shipToContactName = undefined
                shipToType = OwnerType.contact
            }
        }

        var clientShipToAddressObj = new Address(shipToName, dataFromAPI.piq?.shipTo, shipToPan, shipToGstin, shipToContactName, undefined, shipToType)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        var clientShipFromAddressObj = new Address(templateDataToReturn.companyName, dataFromAPI.shipFromAddress, dataFromAPI.piq?.contactDto?.pan, dataFromAPI.piq?.contactDto?.gstin, dataFromAPI.piq?.contactDto?.contactNumber, dataFromAPI.piq?.contactDto?.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientShipFromAddressObj = clientShipFromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipFrom, templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI?.piq?.documentSequenceCode ?? ''

        //start date
        const piqReceiveDate = dataFromAPI?.piq?.piqReceiveDate ?? undefined
        if (piqReceiveDate) {
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(piqReceiveDate), templateDataToReturn.dateFormat)
        }

        const validByDate = dataFromAPI?.piq?.validByDate ?? undefined
        if (validByDate) {
            templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(validByDate), templateDataToReturn.dateFormat)
        }

        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        templateDataToReturn.rfqSeqCode = dataFromAPI?.piq?.rfqSeqCode ?? ""

        templateDataToReturn.lineItems = this.getLineItem(dataFromAPI, templateDataToReturn, true, templateDataToReturn.dateFormat)


        let notes = dataFromAPI?.piq?.memo ?? ""
        if (notes !== '') {
            templateDataToReturn.notes = notes
        }

        return templateDataToReturn
    }

    static getLineItem(dataFromAPI, templateDataToReturn, isGeneratedData, dateFormat) {
        
        const rawItems = dataFromAPI?.piq?.purchaseInwardQuotationItems ?? []
        var productTrackingInfo = dataFromAPI.productTrackingInfo
        var advancedTracking = undefined

        var lineItems = rawItems.map((product, index) => {
            let modifiedProduct = product
            if (modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.hsnOrSacCode !== null && modifiedProduct.product.hsnOrSacCode !== undefined && !isGeneratedData) {
                    modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + modifiedProduct.product.hsnOrSacCode
                    modifiedProduct.hsnOrSacCode = modifiedProduct.product.hsnOrSacCode
                }

                if (product.name === undefined || product.name === null) {
                    product.name = modifiedProduct.product.name
                }
            }
            if (product.hsnOrSacCode !== null && product.hsnOrSacCode !== undefined && !isGeneratedData) {
                modifiedProduct.hsnOrSacCodeString = '<br><b>HSN/SAC : </b>' + product.hsnOrSacCode
                modifiedProduct.hsnOrSacCode = product.hsnOrSacCode
            }
            modifiedProduct.lineNumber = (product.lineNumber !== undefined && product.lineNumber !== null) ? product.lineNumber : index + 1
            modifiedProduct.code = product.productSeqCode ?? ''
            modifiedProduct.quantity = product.productQuantity
            modifiedProduct.availableQuantity = product.product?.inventory?.availableQuantity || 0
            modifiedProduct.stockQty = product.product?.inventory?.availableQuantity || 0

            modifiedProduct.uom = product.uomDto?.name ?? 'N/A'
            product.unitPrice = product.unitPrice ?? 0
            modifiedProduct.unitPriceAfterDiscount = product.unitPrice
            if (product.tax !== null && product.quantity !== null && product.unitPrice !== null) {
                let total_amount = product.quantity * product.unitPrice
                let total_discount = modifiedProduct?.discount
                let discountInPercent = modifiedProduct?.discountInPercent ?? false
                if (discountInPercent && !isGeneratedData) {
                    if (total_discount !== undefined && total_discount !== null) {
                        if (parseFloat(total_discount) > 0) {
                            modifiedProduct.discountPercent = modifiedProduct.discount
                            modifiedProduct.isDiscountInPercent = modifiedProduct.discountInPercent
                        }
                    }
                    if ((product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax) {
                        total_discount = (ComponentManager.getTaxInclusiveSubtotalItemPrice(product) * product.discount) / 100
                    } else {
                        total_discount = (total_amount * modifiedProduct?.discount) / 100
                    }
                }
                if (!discountInPercent) {
                    modifiedProduct.discount = Number(product.discount)
                    modifiedProduct.unitPriceAfterDiscount = product.unitPrice - Number(product.discount)
                    modifiedProduct.discountPerUnit = Number(product.discount) / modifiedProduct.quantity
                }
                modifiedProduct.priceInclusiveOfTax = (product.taxInfo !== null) && dataFromAPI.priceInclusiveOfTax

                let taxCode = product?.tax?.code ?? undefined
                
                if(taxCode) {
                    modifiedProduct.cgstAmt = product?.cgstAmount ?? undefined
                    modifiedProduct.igstAmt = product?.igstAmount ?? undefined
                    modifiedProduct.sgstAmt = product?.sgstAmount ?? undefined

                    let taxPercentage = product?.tax?.percent ?? 0

                    if(taxPercentage > 0) {
                        if (modifiedProduct.igstAmt) {
                            modifiedProduct.igstRate = taxPercentage
                        }
                        if (modifiedProduct.cgstAmt) {
                            modifiedProduct.cgstRate = taxPercentage / 2
                        }
                        if (modifiedProduct.sgstAmt) {
                            modifiedProduct.sgstRate = taxPercentage / 2
                        }
                    }
                    else {
                        modifiedProduct.igstRate = 0
                        modifiedProduct.cgstRate = 0
                        modifiedProduct.sgstRate = 0
                    }
                }
                else {
                    modifiedProduct.tax = Number(product.tax)
                }
            }
            else { //expense invoice
                if (product.tax && product.tax !== null) {
                    let taxAmount = Number(product.tax)
                    if (taxAmount !== null && taxAmount !== undefined && !isNaN(taxAmount)) {
                        modifiedProduct.taxAmount = taxAmount
                    }
                    else {
                        modifiedProduct.tax = 0
                        if (product.taxDetails && product.taxDetails !== null) {
                            const taxList = product.taxDetails
                            if (taxList.length === 1) {
                                modifiedProduct.tax = product.taxDetails[0].taxRate ?? 0
                                modifiedProduct.taxRate = product.taxDetails[0].taxRate ?? 0
                                modifiedProduct.taxAmount = product.taxDetails[0].taxAmount ?? 0
                            }
                            else {
                                let totalTax = 0
                                taxList.forEach(element => {
                                    totalTax = totalTax + (element.taxAmount ?? 0)
                                });
                                modifiedProduct.taxAmount = totalTax
                            }
                        }
                    }
                }
                else {
                    modifiedProduct.tax = 0
                    if (product.taxDetails && product.taxDetails !== null) {
                        const taxList = product.taxDetails
                        if(taxList.length === 1) {
                            modifiedProduct.tax = product.taxDetails[0].taxRate ?? 0
                            modifiedProduct.taxRate = product.taxDetails[0].taxRate ?? 0
                            modifiedProduct.taxAmount = product.taxDetails[0].taxAmount ?? 0
                        }
                        else {
                            let totalTax = 0
                            taxList.forEach(element => {
                                totalTax =  totalTax +  (element.taxAmount ?? 0)
                            });
                            modifiedProduct.taxAmount = totalTax
                        }
                    }
                }
            }

            if(Utility.getIsIndiaCountry(templateDataToReturn)) {
                //get gstType
                let gstTreatment = dataFromAPI?.piq?.contactDto?.gstTreatment ?? ''
                let vendorType = dataFromAPI?.piq?.contactDto?.vendorType ?? ''
                let isRegisteredToSez = dataFromAPI.tenantInfo?.sezOrImportExport;
                let rcmApplicable = dataFromAPI?.piq?.rcmApplicable ?? null
                
                if(gstTreatment !== '') {
                    if (gstTreatment === 'REGISTERED_BUSINESS_COMPOSITION') {
                        if (vendorType.toUpperCase() === 'NA') {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    else if (gstTreatment === 'REGISTERED_BUSINESS_REGULAR') {
                        if (vendorType.toUpperCase() === 'SEZ_WO_PAY' && isRegisteredToSez) {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    if (gstTreatment === 'UNREGISTERED_BUSINESS') {
                        if (rcmApplicable === null || !rcmApplicable) {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    else if (gstTreatment === 'CUSTOMER') {
                        if (vendorType.toUpperCase() === 'NA') {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    else if (gstTreatment === 'SPECIAL_ECONOMIC_ZONE') {
                        if (vendorType.toUpperCase() === 'SEZ_WO_PAY') {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    else if (gstTreatment === 'OVERSEAS') {
                        if (vendorType.toUpperCase() === 'NA') {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                    else if (gstTreatment === 'DEEMED_EXPORT') {
                        if (vendorType.toUpperCase() === 'NA') {
                            modifiedProduct.gstType = 'EXEMPT'
                        }
                    }
                }
            }
            
            const totalAmount = modifiedProduct.totalAmount ?? undefined
            if (totalAmount) {
                modifiedProduct.amount = totalAmount
                modifiedProduct.amountBeforeTax = totalAmount - modifiedProduct.taxAmount
            }

            if (Utility.getIsUSCountry(templateDataToReturn)) {
                modifiedProduct.tax = 0
                modifiedProduct.taxRate = 0
                modifiedProduct.taxAmount = 0
                modifiedProduct.amountBeforeTax = totalAmount
            }

            if (modifiedProduct.product !== undefined && modifiedProduct.product !== null) {
                if (modifiedProduct.product.images !== undefined && modifiedProduct.product.images !== null) {
                    if (modifiedProduct.product.images.length > 0) {
                        modifiedProduct.image = modifiedProduct.product.images[0]
                    }
                }
                if (modifiedProduct.product.advancedTracking !== undefined && modifiedProduct.product.advancedTracking !== null) {
                    advancedTracking = modifiedProduct.product.advancedTracking
                }
                if (modifiedProduct.product.bomProductsConfiguration !== undefined && modifiedProduct.product.bomProductsConfiguration !== null) {
                    modifiedProduct.bomProductsConfiguration = modifiedProduct.product.bomProductsConfiguration
                }
            }
            var referenceCode = undefined
            if (modifiedProduct.purchaseInvoiceItemCode !== undefined && modifiedProduct.purchaseInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.purchaseInvoiceItemCode
            }
            else if (modifiedProduct.salesInvoiceItemCode !== undefined && modifiedProduct.salesInvoiceItemCode !== null) {
                referenceCode = modifiedProduct.salesInvoiceItemCode
            }

            if (modifiedProduct.documentUOMSchemaDefinitionPrint !== undefined && modifiedProduct.documentUOMSchemaDefinitionPrint !== null) {
                if (modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue !== undefined &&
                    modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName !== undefined) {
                    modifiedProduct.alterUOM = '(' + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceConversionValue + " " + modifiedProduct.documentUOMSchemaDefinitionPrint.sourceUOMName + ')'
                }
            }

            if (referenceCode !== undefined) {
                if (productTrackingInfo !== undefined && productTrackingInfo !== null) {
                    if (productTrackingInfo.length > 0) {
                        var serialBatch = []
                        var serialNumber = []
                        var batchNumber = []
                        var manufacturingDate = []
                        var expiryDate = []
                        let serialBatchList = []

                        modifiedProduct.serialBatchCustomFieldType = 'SERIAL_BATCH'
                        let serialBatchCustomFields = {}

                        productTrackingInfo.forEach(trackingItem => {
                            if (trackingItem.documentItemCode === referenceCode) {
                                if (advancedTracking !== undefined) {
                                    if (advancedTracking === 'BATCH') {
                                        batchNumber.push(trackingItem.serialBatchNumber)
                                    }
                                    else if (advancedTracking === 'SERIAL') {
                                        serialNumber.push(trackingItem.serialBatchNumber)
                                    }
                                    if (trackingItem.serialBatchNumber && trackingItem.serialBatchNumber !== null) {
                                        serialBatchList.push(trackingItem.serialBatchNumber)
                                    }
                                }
                                if (trackingItem.manufacturingDate !== null && trackingItem.manufacturingDate !== undefined && trackingItem.manufacturingDate !== "") {
                                    manufacturingDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.manufacturingDate), dateFormat))
                                }
                                if (trackingItem.expiryDate !== null && trackingItem.expiryDate !== undefined && trackingItem.expiryDate !== "" && advancedTracking === 'BATCH') {
                                    serialBatch.push(trackingItem.serialBatchNumber + ' (Exp dt: ' + getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat) + ')')
                                    expiryDate.push(getConvertedDate(convertDateFromERPFormatToDocFormat(trackingItem.expiryDate), dateFormat))
                                }
                                else {
                                    serialBatch.push(trackingItem.serialBatchNumber)
                                }

                                if (trackingItem.serialBatchNumber && trackingItem.serialBatchNumber !== null) {
                                    serialBatchCustomFields[trackingItem.serialBatchNumber] = trackingItem.customField
                                }
                            }
                        });

                        modifiedProduct.serialBatch = ''
                        modifiedProduct.serialNumber = ''
                        modifiedProduct.batchNumber = ''
                        modifiedProduct.manufacturingDate = ''
                        modifiedProduct.expiryDate = ''

                        if (serialBatch.length > 0) {
                            modifiedProduct.serialBatch = serialBatch.join('\n')
                        }
                        if (serialNumber.length > 0) {
                            modifiedProduct.serialNumber = serialNumber.join('\n')
                        }
                        if (batchNumber.length > 0) {
                            modifiedProduct.batchNumber = batchNumber.join('\n')
                        }
                        if (manufacturingDate.length > 0) {
                            modifiedProduct.manufacturingDate = manufacturingDate.join('\n')
                        }
                        if (expiryDate.length > 0) {
                            modifiedProduct.expiryDate = expiryDate.join('\n')
                        }

                        modifiedProduct.serialBatchList = serialBatchList
                        modifiedProduct.serialBatchCustomFields = serialBatchCustomFields
                    }
                }
            }
            return modifiedProduct
        })
        return lineItems
    }
}
