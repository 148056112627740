import React, { Component } from 'react'
import { FONT_SIZE, SignatureColumnType } from '../Constants/Constants'
import TemplateSettingsManager from '../Manager/TemplateSettingsManager'
import { getLocalisedText } from '../Translate/LanguageManager'
import Utility, { getFontSizeClass, getVW } from '../Utilities/Utility'
export default class DKSignatureSection extends Component {

    render() {
        var signatureList = TemplateSettingsManager.defaultTemplateSettings.signatureInfo.filter(x => x.isSelected)
        if (signatureList.length === 0) {
            return <></>
        }
        else {
            return this.renderSignatureList(signatureList)
        }
    }

    renderSignatureList(signatureList) {
        return (
            <div className='ColumnDiv parent-width'
                onClick={this.props.onSignatureSectionClicked}
                style={{
                    position: 'relative',
                    paddingBottom: getVW(Utility.convertToPrintScale(16, this.props.data.printScale))
                }}>
                {this.getSignatureList(signatureList)}
            </div>
        )
    }

    getSignatureList(signatureList) {
        // split 2 column per row
        var newArraySize = 2
        var newSignatureList = []

        if (this.props.style !== undefined) {
            if (this.props.style.columnCount !== undefined) {
                if (Number(this.props.style.columnCount) > 0) {
                    newArraySize = Number(this.props.style.columnCount)
                }
            }
        }

        var rowCount = Math.ceil(signatureList.length / newArraySize)
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
            var startIndex = rowIndex * newArraySize
            var columnList = []
            for (let index = startIndex; index < startIndex + newArraySize; index++) {
                var data = signatureList[index]
                if (data !== undefined && data !== null) {
                    columnList.push(data)
                }
                else {
                    columnList.push(undefined)
                }
            }
            newSignatureList.push(columnList)
        }

        return newSignatureList.map((row) => {
            var columns = row.map((item, index) => {
                return this.renderCustomField(item, index)
            });

            return <div className='RowDiv'
                style={{
                    paddingTop: getVW(Utility.convertToPrintScale(10, this.props.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(10, this.props.data.printScale)),
                }}>
                {columns}
            </div>
        });
    }

    renderCustomField(item, index) {
        return this.renderCustomFieldSection(
            item !== undefined ? getLocalisedText(item.name) + ':' : ' ',
            item !== undefined ? this.getItemValue(item) : ' ',
            this.getColumnWidth(index),
            this.getHeaderColor(),
        )
    }

    getItemValue(item) {
        switch (item.type) {
            case SignatureColumnType.preparedBy:
                // return this.props.data.ownerEmail ? this.props.data.ownerEmail : ''
                return ''
            case SignatureColumnType.approvedBy:
                return ''
            case SignatureColumnType.name:
                return ''
            case SignatureColumnType.signature:
                return ''

            default:
                return ''
        }
    }

    getColumnWidth(index) {
        if (this.props.style === undefined) {
            return '100%'
        }
        if (this.props.style.columnWidths === undefined) {
            return '100%'
        }

        if (this.props.style.columnWidths.length === 0) {
            return '100%'
        }

        if (this.props.style.columnWidths.length > index) {
            return this.props.style.columnWidths[index]
        }
    }

    getHeaderColor() {
        if (this.props.isThemeColor) {
            return this.props.data.themeColor
        }

        if (this.props.headerColor !== undefined) {
            return this.props.headerColor
        }

        return undefined
    }

    getMarginLeftValue() {
        if (this.props !== undefined) {
            if (this.props.style !== undefined) {
                if (this.props.style.marginLeft !== undefined) {
                    return this.props.style.marginLeft
                }
            }
        }
        return undefined
    }

    renderCustomFieldSection(
        title,
        description,
        width = "100%",
        titleColor = "black",
        textColor = "#000000"
    ) {
        return (
            <div className="RowDiv" style={{ width: width, paddingRight: getVW(32)}}>
                {title && (
                    <div className="ColumnDiv">
                        <text className={"text-trailing text-wrap-none docFontStyleBold " + getFontSizeClass()}
                            style={{
                                marginLeft: this.getMarginLeftValue(),
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, color: titleColor }, this.props.data.printScale)
                            }}>
                            {title}
                        </text>
                    </div>
                )}
                &nbsp;
                <div
                    className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        marginLeft: this.getMarginLeftValue(),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, color: textColor }, this.props.data.printScale)
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}>
                </div>
            </div>
        );
    }
}
