
import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { FooterRow } from "../FooterRow"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableHeader } from "../TableHeader"
import { TableOption } from "../TableOption"
import AppManager from "../../../Manager/AppManager";
import { PaymentTableWidth } from "./PaymentTableWidth"
import { PaymentTableItem } from "./PaymentTableItem"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { getSortedHeader, getTablePadding } from "../TableUtility"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType } from "../../../Constants/Constants"
import TableFooterUtility from "../../../Utilities/TableFooterUtility"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"

export class PaymentTable {
    static footerColumnList: HeaderColumn[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = canUserEdit
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }
    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []

        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }
        var sortedHeader = getSortedHeader()
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForPayment()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        var footerList = TableFooterUtility.getFooterListByType('payment')
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, rawData.printScale)
                    newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                    headerColumnList.push(newHeaderColumn)
                }
            });
        }
        var width = columnOption[0].width
        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var accountTableWidth = this.getHeaderColumnWidth(headerColumnList)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, accountTableWidth) + '%'
            });

            newColumnOption.forEach((element: TableHeader) => {
                element.width = this.getHeaderItemColumnWidth(element.type, accountTableWidth) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name)
                if (items !== undefined && items !== null) {
                    if (items.length > 0) {
                        var width = items[0].width
                        if (width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }
        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, footerList)
        return headerColumnList
    }

    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, defaultTableColumnAlignmentList: any,tableColumnAlignmentList: any,tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if (columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if (tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAlignment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAlignment.alignment
                }
            }
        }

        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[]) {
        var showWidthConfig = new PaymentTableWidth()
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, paymentTableWidth: PaymentTableWidth) {
        var width = 0
        switch (type) {
            case TableColumnPopupType.snNumber:
                width = paymentTableWidth.snNumber
                break;
            case TableColumnPopupType.invoiceNumber:
                width = paymentTableWidth.invoiceNumber
                break;
            case TableColumnPopupType.billNumber:
                width = paymentTableWidth.billNumber
                break;
            case TableColumnPopupType.totalInvoiceAmount:
                width = paymentTableWidth.totalInvoiceAmount
                break;
            case TableColumnPopupType.totalBillAmount:
                width = paymentTableWidth.totalBillAmount
                break;
            case TableColumnPopupType.paymentMade:
                width = paymentTableWidth.paymentMade
                break;
            case TableColumnPopupType.currentDueAmount:
                width = paymentTableWidth.currentDueAmount
                break;
            case TableColumnPopupType.currentBalance:
                width = paymentTableWidth.currentBalance
                break;
            case TableColumnPopupType.paymentMedium:
                width = paymentTableWidth.paymentMedium
                break;
            default:
                break;
        }
        return width
    }

    static getIsShowColumn(headerColumnList: HeaderColumn[], name: string) {
        var filterValue = headerColumnList.filter(x => x.name === name)
        if (filterValue === undefined || filterValue === null) {
            return false
        }
        if (filterValue.length === 0) {
            return false
        }
        return filterValue[0].columnOption.isShowColumn
    }
    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            ['1', getLocalisedText("document_details"), '0.00'],
            ['2', getLocalisedText("document_details"), '0.00'],
            ['3', getLocalisedText("document_details"), '0.00'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }

            documentData.lineItems.forEach((element: any, index: number) => {
                lineItems.push(new PaymentTableItem(element, currency).toString(documentData.type))
            });
            return lineItems
            // return []
        }
    }

    static getDefaultItemColumn(tableStyle: any, index: number, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.fontSize = FONT_SIZE.regular
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            column.forEach(element => {
                var newItemColumn = this.getDefaultItemColumn(tableStyle, index, rawData.printScale)
                newItemColumn.value = element
                rowList.push(newItemColumn)
            });
            itemColumnList.push(rowList)
        });

        return itemColumnList
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if (data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any, index: number) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length - 1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static getFooterColumn(data: any, columnOption: any, tableStyle: any, printScale: number) {
        var newFooterColumn: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }

        let subTotalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.subTotalAmount)
        if (subTotalRow !== undefined) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.subTotalAmount, subTotalRow.name, subTotalRow.isSelected, printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, 'subtotal', 'subtotal', true, printScale))
        }

        let totalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.totalAmount)
        if (totalRow !== undefined) {
            if (totalRow.isSelected) {
                if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                    newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, totalRow.name, printScale))
                }
            }
        }
        else {
            if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, 'total', printScale))
            }
        }

        let amount_in_words_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.amountInWords)
        if (amount_in_words_column_data_list.length > 0) {
            let amount_in_words_column_data = amount_in_words_column_data_list[0]
            if (amount_in_words_column_data.isSelected) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amountInWords, amount_in_words_column_data.name, printScale))
            }
        }
        this.footerColumnList = newFooterColumn
        return newFooterColumn
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        var dummyData = ['0.00', '0.00', '0.00', '$ 0.00']
        var currency = ''
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return dummyData
            }
            if (this.getIsContainTaxBreakdown(documentData)) {
                this.footerColumnList.forEach(element => {
                    var value = ''
                    documentData.taxBreakdown.forEach((tax: { taxName: string, taxAmount: any }) => {
                        if (element.name === tax.taxName) {
                            value = Number(tax.taxAmount).toFixed(AppManager.getDecimalScale())
                        }
                    });
                    if (value === '') {
                        footerValue.push(this.getFooterValue(element.name, documentData, currency))
                    }
                    else {
                        footerValue.push(value)
                    }
                });
            }
            else {
                this.footerColumnList.forEach(element => {
                    footerValue.push(this.getFooterValue(element.name, documentData, currency))
                });
            }

            return footerValue
        }
    }

    static getFooterValue(type: string, data: any, currency: string) {
        switch (type) {
            case 'subtotal':
                return Utility.toCurrencyFormat(this.getSubtotalAmount(data.lineItems), currency)
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.getTotalAmount(data.lineItems), currency)
            case TableColumnPopupType.amountInWords:
                return data.amountInWords ? data.amountInWords : ''
                default:
                return ''
        }
    }

    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any, rawData: any) {
        var keyValue: FooterRow[] = []
        var footerColumn = this.getFooterColumn(data, tableColumnOptions, tableStyle, rawData.printScale)
        var footerData = this.getFooterData(data)

        if (footerData === undefined || footerData === null) {
            return []
        }
        if (footerData.length === 0) {
            return []
        }
        if (footerColumn === undefined || footerColumn === null) {
            return []
        }
        var maxSize = footerColumn.length > footerData.length ? footerColumn.length : footerData.length

        for (let index = 0; index < maxSize; index++) {
            if (footerColumn.length > index) {
                var key = footerColumn[index]
                if (key !== undefined && key !== null) {
                    var newFooterRow = new FooterRow()
                    newFooterRow.header = key
                    newFooterRow.isSelected = key.isShowRecord
                    if (footerData.length > index) {
                        var value = footerData[index]
                        if (value !== undefined && value !== null) {
                            newFooterRow.value = value
                        }
                        keyValue.push(newFooterRow)
                    }
                }
            }
        }
        if (data.footerRow !== undefined) {
            var newArray: FooterRow[] = []
            data.footerRow.forEach((newFooter: FooterRow) => {
                keyValue.forEach(element => {
                    if (newFooter.header.name === element.header.name) {
                        var footer = new FooterRow()
                        footer.header = element.header
                        footer.isSelected = element.isSelected
                        footer.value = element.value
                        newArray.push(footer)
                    }
                });
            });
            return newArray
        }
        else {
            return keyValue
        }
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any, callback: any) {
        if (columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if (element.isSelected) {
                var item = element
                var width = element.width
                if (element.type === header.name) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        TemplateSettingsManager.updateTablePopupInfo(newColumnOptions)
        var newData = data
        newData.tableColumnPopup = newColumnOptions
        callback(newData)
    }
    static setFooterHeader(tableStyle: any, type: string, name: string, isShowRecord: boolean, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.isShowRecord = isShowRecord
        return newFooterColumn
    }
    static getDefaultFooterHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newFooterHeaderColumn = new HeaderColumn()
        newFooterHeaderColumn.type = 'string'
        newFooterHeaderColumn.style = new ColumnStyle()
        if (tableStyle.subTotalBorderColor) {
            newFooterHeaderColumn.style.borderColor = tableStyle.subTotalBorderColor
        }
        else {
            newFooterHeaderColumn.style.borderColor = tableStyle.itemBorderColor
        }

        newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor
        newFooterHeaderColumn.style.borderStyle = 'solid'
        newFooterHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newFooterHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.fontSize = FONT_SIZE.regular
        newFooterHeaderColumn.style.alignment = 'right'
        return newFooterHeaderColumn
    }

    static setLastFooterHeader(tableStyle: any, type: string, name: string, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.style.backgroundColor = tableStyle.footerBackgroundColor
        newFooterColumn.style.fontColor = tableStyle.footerTextColor
        newFooterColumn.style.borderColor = tableStyle.footerBorderColor
        newFooterColumn.style.fontWeight = 'bold'
        if (type === TableColumnPopupType.amountInWords) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    newFooterColumn.style.fontColor = undefined
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    newFooterColumn.style.fontColor = undefined
                }
            }
        }

        if (tableStyle.lastRowTopColor !== undefined) {
            newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor
            newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth
        }
        if (tableStyle.lastRowBottomColor !== undefined) {
            newFooterColumn.style.borderBottomColor = tableStyle.lastRowBottomColor
            newFooterColumn.style.borderBottomWidth = tableStyle.lastRowBottomWidth
        }

        return newFooterColumn
    }

    static getIsContainTaxBreakdown(data: any): boolean {
        if (data.taxBreakdown !== undefined && data.taxBreakdown !== null) {
            if (data.taxBreakdown.length > 0) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }

    static getSubtotalAmount(items: PaymentTableItem[]) {
        var subtotalAmount = 0
        items.forEach(element => {
            var amount = element.paymentMade
            if(amount > 0) {
                subtotalAmount += amount
            }
        });
        return subtotalAmount
    }

    static getTotalAmount(items: PaymentTableItem[]) {
        var totalAmount = 0
        items.forEach(element => {
            if (element.paymentMade !== undefined) {
                totalAmount += element.paymentMade
            }
        });
        return totalAmount
    }


    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%', ''))
                if (num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }

    static getIsShowTotal(tableStyle: any, type: string) {
        if (type === TableColumnPopupType.amount) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    return false
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    return false
                }
            }
        }
        return true
    }
}
