import ApiConstants from '../Constants/ApiConstants';
import { ERROR } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface DashboardAPIConfig {
  SearchTerm?: string;
  Page?: number;
  Query?: string;
  duration?: string;
  type?: string;
  startDate?: string;
  toDate?: string;
  fromDate?: string;
  limit?: number;
  sort?: string;
  sortDir?: string;
  accountCode?: string;
  buySellFilterOption?: string;
}

const defaultConfig: DashboardAPIConfig = {
  duration: '',
  type: '',
  toDate: '',
  fromDate: '',
  limit: 100,
  sort: '',
  sortDir: '',
  accountCode: '',
  SearchTerm: '',
  Page: 0,
  Query: ''
};

class DashboardService {
  static apiConfig: DashboardAPIConfig;

  static getBuySellDashboard() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?duration=${this.apiConfig.duration}&type=${this.apiConfig.type}&var-start_date=${this.apiConfig.fromDate}`;
    const finalEndpoint: string =
      ApiConstants.URL.DASHBOARD.BUY_SELL + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAgedReceivable() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?type=${this.apiConfig.type}&var-start_date=${this.apiConfig.fromDate}`;
    const finalEndpoint: string =
      ApiConstants.URL.DASHBOARD.AGED_DASHBOARD + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(ERROR);
        }
      )
      .catch((error: any) => {
        return Promise.reject(ERROR);
      });
  }
  static getCashFlow() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?to-date=${this.apiConfig.toDate}&duration=${this.apiConfig.duration}&SKIP_REQUEST_INTERCEPTOR=true`;
    const finalEndpoint: string =
      ApiConstants.URL.DASHBOARD.CASH_FLOW_DASHBOARD + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(ERROR);
        }
      )
      .catch((error: any) => {
        return Promise.reject(ERROR);
      });
  }
  static getBankAccount() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?accountCode=${this.apiConfig.accountCode}&SKIP_REQUEST_INTERCEPTOR=true`;
    const finalEndpoint: string =
      ApiConstants.URL.DASHBOARD.BACK_ACCOUNT_DASHBOARD + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getPNL() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?toDate=${this.apiConfig.toDate}&fromDate=${this.apiConfig.fromDate}`;
    const finalEndpoint: string =
      ApiConstants.URL.DASHBOARD.PROFIT_AND_LOST + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(ERROR);
        }
      )
      .catch((error: any) => {
        return Promise.reject(ERROR);
      });
  }
  static fetchDashboardBanksList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?&search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.limit
    }&page=${this.apiConfig.Page}&query=${
      Utility.isEmpty(this.apiConfig.Query)
        ? '&sort=name&sortDir=asc'
        : this.apiConfig.Query
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.BANKS.FETCH_BANKS_DASHBOARD + queryString;
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(ERROR);
        }
      )
      .catch((error: any) => {
        return Promise.reject(ERROR);
      });
  }
}

export default DashboardService;
