import React from 'react';
import { getLocalisedText } from '../../../../Translate/LanguageManager';
import { ComponentType } from '../model/Enum';
import {TemplateItem} from '../model/TemplateItem';
import './AddComponentPopup.css';
import { Asset } from '../../../../assets';

interface Props {
    componentList: TemplateItem[]
    onSelectComponent: (component: TemplateItem) => void
    onRemoveComponent: (component: TemplateItem, view: string) => void
    onCancelPressed: () => void
}

interface States {
}
export default class ViewComponentListPopup extends React.Component<Props, States> {

    render() {
        return (
            <div>
                {this.renderComponentList()}
            </div>
        );
    }


    /////////////////////////////d///////////////////////////////
    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////

    renderComponentList() {
        return (
            <div
                style={{
                    width: "90%",
                    // height: 340,
                    backgroundColor: "white",
                    borderRadius: 12,
                    position: "absolute",
                    top: 40,
                    left: 2,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 17,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",

                }}
            >
                <div
                    className="DownArrow"
                    style={{
                        position: "absolute",
                        top: -6,
                        right: 75
                    }}
                ></div>
                <div style={{ display: "flex", marginBottom: 10, justifyContent: "space-between", width: '100%'}}>
                    <text
                        style={{
                            color: "gray",
                            fontSize: 12,
                            fontWeight: "bold",
                        }}
                    >
                        {getLocalisedText('component_list')}
                    </text>
                    <text
                        style={{
                            marginRight: 12,
                            fontSize: 12,
                            fontWeight: "bold",
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.onCancelPressed()}
                    >
                        X
                    </text>
                </div>
                <div className='parent-width'>
                    {this.getComponentList()}
                </div>
            </div>
        );
    }

    getComponentList() {
        if(this.props.componentList === undefined || this.props.componentList === null) {
            return
        }

        return this.props.componentList.map((element: TemplateItem) => {
            return this.getComponent(element)
        });
    }

    getComponent(element: TemplateItem) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: 15,
                    paddingLeft: 0,
                    paddingRight: 20,
                    cursor: "pointer",
                }}
                onClick={() => {
                    // this.onAddComponentTapped(action)
                }}
            >
                <div className='RowDiv' style={{width: '18%'}} onClick={() => this.props.onSelectComponent(element)}>
                    <img
                        src={this.getComponentIconForType(element)}
                        alt=""
                        style={{ width: 23, marginLeft: 3, marginRight: 14}}
                    />
                </div>
                <div className='RowDiv' style={{ width: '70%', overflow: 'hidden', overflowY:'scroll' }} onClick={() => this.props.onSelectComponent(element)}>
                    <text style={{ color: "black", fontSize: 13, textAlign: 'left', paddingRight: 5, maxHeight: 120 }}>{getLocalisedText(this.getTitleForAction(element))}</text>
                </div>
                <div className='RowDiv' style={{ width: '12%' }}>
                    {this.renderDeleteIcon(element)}
                </div>
            </div>
        );
    }

    getTitleForAction(element: TemplateItem) {
        var type = element.type
        switch (type) {
            case ComponentType.DKImage:
                return 'image';
            case ComponentType.DKLabel:
                return element.newText ? element.newText : '';
            default:
                return ''
        }
    }

    getComponentIconForType(element: TemplateItem) {
        var type = element.type
        var image = Asset.icon.ic_dd_img;
        if(element.image !== undefined) {
            image = element.image
        }
        switch (type) {
            case ComponentType.DKImage:
                return image
            case ComponentType.DKLabel:
                return Asset.icon.ic_dd_text;
            default:
                return Asset.icon.ic_dd_text;
        }
    }

    renderDeleteIcon(element: TemplateItem) {
        return <div className='ColumnDiv'
            style={{
                top: 10,
                right: 5,
                width: 20,
                height: 20,
                backgroundColor: 'red',
                borderRadius: '50%',
                justifyContent: 'center',
            }}
            onClick={(event) => {
                this.props.onRemoveComponent(element, 'ViewComponentListPopup')
            }}
        >
            <img
                src={Asset.icon.ic_delete_white}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                    alignSelf: 'center',
                }}
            />
        </div>
    }
}


