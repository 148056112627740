import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import DepositService from '../../Services/Deposit';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

interface Deposit {
  data: { depositList: any; bankAccountList: any };
  columnConfig: IColumn[];
  status: API_STATUS;
  gridContentLoadingStatus?: API_STATUS;
  configTableId?: string;
  formData?: any;
}
const initialState: Deposit = {
  data: { depositList: [], bankAccountList: [] },
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchDeposits = createAsyncThunk('deposit', async () => {
  const response = await DepositService.getDepositByPage();
  return response;
});

export const fetchBankAccountList = createAsyncThunk(
  'bankAccountList',
  async () => {
    const response = await DepositService.getBankAccountList(['Bank']);
    return response;
  }
);

export const DepositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addDepositColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.DEPOSIT
      );
    },
    addColumnToDepositColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    },
    updateDepositDetails: (state, action: PayloadAction<any>) => {
      const depositDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const depositIndex: number = content.findIndex(
        (product: any) => product.id === depositDetails.id
      );
      if (depositIndex >= 0) {
        content[depositIndex] = depositDetails;
        state.data.depositList['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeposits.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchDeposits.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data.depositList = action.payload;
      })
      .addCase(fetchBankAccountList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBankAccountList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.bankAccountList = action.payload;
      });
  }
});

export const {
  addDepositColumnConfig,
  updateDepositDetails,
  updateColumnConfig
} = DepositSlice.actions;
export const selectDeposits = (state: RootState) =>
  state.deposits.data.depositList;
export const selectDepositColumnConfig = (state: RootState) =>
  state.deposits.columnConfig;
export const selectDepositColumnConfigTableId = (state: RootState) =>
  state.deposits.configTableId;
export const selectBankAccountList = (state: RootState): any[] =>
  state.deposits.data.bankAccountList;

export const selectDepositDataLoadingStatus = (state: RootState) =>
  state.deposits.gridContentLoadingStatus;
