import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchSerialTrackingProducts = createAsyncThunk(
  'serialTrackingProduct',
  async (param: any) => {
    const response = await ProductService.fetchProductAdvancedTrackingWarehouse(
      param?.productCode,
      param?.enableQCWarehouse,
      param?.includeRejectedWarehouse
    );
    return response;
  }
);

export const SerialTrackingProductsSlice = createSlice({
  name: 'serialTrackingProducts',
  initialState,
  reducers: {
    resetSerials: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSerialTrackingProducts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchSerialTrackingProducts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { resetSerials } = SerialTrackingProductsSlice.actions;

export const selectSerialTrackingProduct = (state: RootState) =>
  state.serialTrackingProducts.data;
export const selectAdvancedTrackingProductsLoadingStatus = (state: RootState) =>
  state.serialTrackingProducts.status;

export default SerialTrackingProductsSlice.reducer;
