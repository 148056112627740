import { CUSTOM_FIELD_SHOW_OPTION, DateFormat, TableColumnPopupType } from "../../../Constants/Constants"
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getDateFrom, getDateString } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class QCInspectionTableItem {
    qcInspectionCustomFieldsLineItem: any;
    lineNumber: string;
    name: string;
    value: string;
    incoming: string;
    final: string;
    rawItemValue: any;
    customFields: any;

    constructor(qcItem: any, qcCustomFieldLineItem: any, customFields: any) {
        this.lineNumber = qcItem?.lineNumber ? qcItem.lineNumber : ''
        this.name = qcItem?.name ?? ''
        this.value = qcItem?.value ?? ''
        this.incoming = qcItem?.incoming ?? ''
        this.final = qcItem?.final ?? ''
        this.rawItemValue = qcItem

        this.qcInspectionCustomFieldsLineItem = qcCustomFieldLineItem
        this.customFields = customFields
    }


    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                let index = this.qcInspectionCustomFieldsLineItem?.index ?? undefined
                if(index !== undefined) {
                    index++
                }
                return index ?? getLocalisedText(this.lineNumber)
            case TableColumnPopupType.parameterName:
                return this.getCustomFieldColValue(TableColumnPopupType.parameterName, getLocalisedText(this.name))
            case TableColumnPopupType.parameterValue:
                return this.getCustomFieldColValue(TableColumnPopupType.parameterValue, getLocalisedText(this.value))
            case TableColumnPopupType.qcIncoming:
                return this.getCustomFieldColValue(TableColumnPopupType.qcIncoming, getLocalisedText(this.incoming))
            case TableColumnPopupType.qcFinal:
                return this.getCustomFieldColValue(TableColumnPopupType.qcFinal, getLocalisedText(this.final))
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return ''
            default:
                return ''
        }
    }

    getCustomFieldColValue(type: any, defaultValue: any) {
        const columns = this.qcInspectionCustomFieldsLineItem?.columns ?? []
        if(columns.length > 0) {
            const selectedCol = columns.find((x: any) => x.colType === type)
            if(selectedCol) {
                if(selectedCol.id === 0) {
                    return selectedCol.customText
                }
                else if (selectedCol.id > 0) {
                    switch (selectedCol.viewOption) {
                        case CUSTOM_FIELD_SHOW_OPTION.SHOW_LABEL:
                            return selectedCol.colCFLabel
                        case CUSTOM_FIELD_SHOW_OPTION.SHOW_VALUE:
                            return this.getCustomFieldValue(selectedCol)
                        case CUSTOM_FIELD_SHOW_OPTION.SHOW_BOTH:
                            let combinedText = '<b>' + selectedCol.colCFLabel + '</b>'
                            let value = this.getCustomFieldValue(selectedCol)
                            if(value !== '') {
                                combinedText = combinedText + '\n' + value
                            }
                            return combinedText
                        default:
                            break;
                    }
                }
            }
        }
        return defaultValue
    }

    getCustomFieldValue(selectedCol: any) {
        let cf = this.customFields.find((x: any) => x.id === selectedCol.id)
        const colCFgroup = selectedCol.colCFgroup ?? ''
        if (colCFgroup !== '') {
            cf = this.customFields.find((x: any) => x.id === selectedCol.id && x.group === colCFgroup)
        }
        let cfValue = ''
        if (cf) {
            if (cf.value !== '') {
                if (selectedCol.colCFType === "DATE") {
                    cfValue = getDateString(getDateFrom(cf.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || cf.value;
                } else {
                    cfValue = cf.value
                }
            }
        }

        return cfValue
    }

    getDefaultList() {
        return [
            getLocalisedText(this.lineNumber),
            getLocalisedText(this.name),
            getLocalisedText(this.value)
        ]
    }
}
