import { showAlert } from 'deskera-ui-library';
import {
  APP_NAME,
  APPROVAL_STATUS,
  CRM_APP_NAME,
  DOC_TYPE,
  LABELS,
  MRP_APP_NAME
} from '../Constants/Constant';
import { COLUMN_CODE } from '../Constants/TableConstants';
import Utility from '../Utility/Utility';
import Table from './Table';
import { DraftApprovalPayload } from '../Models/Drafts';

export interface DraftsAPIConfig {
  SearchTerm?: string;
  pageSize?: number;
  pageNo?: number;
  salesperson?: number;
}

const defaultConfig: DraftsAPIConfig = {
  SearchTerm: '',
  pageSize: 300,
  pageNo: 1,
  salesperson: 0
};

class DraftService {
  static apiConfig: DraftsAPIConfig;

  static async getDraftRecords(
    tableId: any,
    columnId: any,
    appNameColId: string,
    draftTypeColId?: any,
    draftTypeColValue?: any,
    filter?: any
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let query = {
      logicalOperator: 'or',
      conditions: [{ colId: `${columnId}`, opr: 'is', value: true }]
    };
    if (
      draftTypeColId &&
      draftTypeColValue &&
      draftTypeColValue === LABELS.BILLS
    ) {
      const draftTypeColValueForBill: any = [LABELS.BILLS, LABELS.EXPENSE_BILL];
      query = {
        logicalOperator: 'and',
        conditions: [
          { colId: `${columnId}`, opr: 'is', value: true },
          {
            colId: `${draftTypeColId}`,
            opr: 'in',
            value: draftTypeColValueForBill
          }
        ]
      };
    } else if (draftTypeColId && draftTypeColValue) {
      query = {
        logicalOperator: 'and',
        conditions: [
          { colId: `${columnId}`, opr: 'is', value: true },
          {
            colId: `${draftTypeColId}`,
            opr: 'is',
            value: draftTypeColValue
          }
        ]
      };

      if (draftTypeColValue === LABELS.QUOTES && appNameColId) {
        query.conditions.push({
          colId: appNameColId,
          opr: `neq`,
          value: CRM_APP_NAME as any
        });
      }

      if (
        draftTypeColValue === LABELS.PURCHASE_ORDERS &&
        !Utility.isEmpty(filter)
      ) {
        filter?.map((row: any) => {
          query.conditions.push(row);
        });
      }
    }
    let params = this.apiConfig;
    if (!params.salesperson) {
      delete params.salesperson;
    }
    return await Table.getRecordsByPage(params, query, tableId);
  }

  static async deleteDraftRecords(recordId: any, tableId: any) {
    return await Table.deleteRecord(recordId, tableId);
  }

  static async bulkDeleteDraftRecords(recordIds: any[], tableId: any) {
    return await Table.deleteRecordInBulk(recordIds, tableId);
  }

  static async getDraftRecordById(recordId: any, tableId: any) {
    return await Table.getRecordById(recordId, tableId);
  }

  static async updateRecord(
    record: any,
    draftData: any,
    columnConfig: any,
    tableId: string
  ) {
    let name: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Name
    );
    let type: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Type
    );
    let payload: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Payload
    );
    let isMaximized: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isMaximized
    );
    let isCenterAlign: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isCenterAlign
    );
    let isSaved: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isSaved
    );
    let appNameColId: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.AppName
    )?.[0].id;

    let draftName: any = 'Draft';
    // console.log('RECORD : ', record);
    switch (draftData.type) {
      case LABELS.PRODUCTS:
        // Add your name key of form
        break;
      case LABELS.CONTACT:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
      case LABELS.INVOICES:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
      case LABELS.BILLS:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
      case LABELS.PURCHASE_ORDERS:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
      case LABELS.WORK_OUT:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
      case LABELS.QUOTES:
        // Add your name key of form
        draftName = `${draftData.type}-${draftName}`;
        break;
    }

    // console.log('record : ', record);
    record = Utility.encodeJSON(record);

    let payloadData = {
      [name[0].id]: draftName,
      [type[0].id]: draftData.type,
      [isMaximized[0].id]: true, // set this to true as we don't want to save the draft in minimized state
      [isCenterAlign[0].id]: true, // set this to true as we don't want to save the draft in minimized state
      [payload[0].id]: record,
      [isSaved[0].id]: true,
      ...(appNameColId
        ? {
            [appNameColId]: Utility.isMRPWithURLCheck()
              ? MRP_APP_NAME
              : APP_NAME
          }
        : {})
    };

    if (tableId && payloadData) {
      return Table.updateRecord(payloadData, draftData.id, tableId);
    }
  }
  static async createBulkRecord(records: any, draftData: any) {
    let tableId = draftData.data.tableId;
    let columnConfig = draftData.data.columnConfig;

    let name: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Name
    );
    let type: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Type
    );
    let isSaved: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isSaved
    );

    let isMaximized: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isMaximized
    );
    let isCenterAlign: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isCenterAlign
    );
    let payload: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Payload
    );

    let appNameColId: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.AppName
    )?.[0].id;

    let draftName: any = 'Draft';
    let payloadBulk: any[] = [];
    records.forEach((record: any, idx: Number) => {
      let sourceDocument = this.getSourceDoc(record);
      record = Utility.encodeJSON(record);
      payloadBulk.push({
        cells: {
          [name[0].id]: draftName,
          [type[0].id]: draftData.data.type,
          [isSaved[0].id]: true,
          [isMaximized[0].id]: true, // set this to true as we don't want to save the draft in minimized state
          [isCenterAlign[0].id]: true, // set this to true as we don't want to save the draft in minimized state
          [payload[0].id]: record,
          sourceDocument: sourceDocument,
          ...(appNameColId
            ? {
                [appNameColId]: Utility.isMRPWithURLCheck()
                  ? MRP_APP_NAME
                  : APP_NAME
              }
            : {})
        }
      });
    });

    if (tableId && payloadBulk) {
      return await Table.addBulkRecord(payloadBulk, tableId);
    }
  }
  static async createRecord(record: any, draftData: any) {
    let tableId = draftData.data.tableId;
    let columnConfig = draftData.data.columnConfig;

    let name: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Name
    );
    let type: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Type
    );
    let isSaved: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isSaved
    );

    let isMaximized: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isMaximized
    );
    let isCenterAlign: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.isCenterAlign
    );
    let payload: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.Payload
    );

    let appNameColId: any = Utility.getKeyOfColumn(
      columnConfig,
      COLUMN_CODE.DRAFTS.AppName
    )?.[0].id;

    let draftName: any = 'Draft';
    let sourceDocument = this.getSourceDoc(record);
    record = Utility.encodeJSON(record);

    let payloadData = {
      cells: {
        [name[0].id]: draftName,
        [type[0].id]: draftData.data.type,
        [isSaved[0].id]: true,
        [isMaximized[0].id]: true, // set this to true as we don't want to save the draft in minimized state
        [isCenterAlign[0].id]: true, // set this to true as we don't want to save the draft in minimized state
        [payload[0].id]: record,
        sourceDocument: sourceDocument,
        ...(appNameColId
          ? {
              [appNameColId]: Utility.isMRPWithURLCheck()
                ? MRP_APP_NAME
                : APP_NAME
            }
          : {})
      }
    };

    if (tableId && payloadData) {
      return await Table.addRecord(payloadData, tableId);
    }
  }

  static getSourceDoc(data: any) {
    const formatLinkedDoc = (linkedDoc: any) => ({
      documentCode: linkedDoc.documentCode,
      documentSequenceCode: linkedDoc.documentSequenceCode,
      documentType:
        linkedDoc.documentType === DOC_TYPE.PURCHASE_REQUEST
          ? DOC_TYPE.REQUISITION
          : linkedDoc.documentType
    });

    switch (data.documentType) {
      case DOC_TYPE.QUOTE:
        return null;
      case DOC_TYPE.SALES_ORDER:
        if (data.linkedQuotationDocuments?.length) {
          let linkedQuote = data.linkedQuotationDocuments.find(
            (doc: any) => doc.documentType === DOC_TYPE.QUOTE
          );

          if (linkedQuote) {
            return formatLinkedDoc(linkedQuote);
          }
        }
        break;
      case DOC_TYPE.INVOICE:
        if (data.linkedDocuments?.length) {
          let linkedSO = data.linkedDocuments.find(
            (doc: any) => doc.documentType === DOC_TYPE.SALES_ORDER
          );
          if (linkedSO) {
            return formatLinkedDoc(linkedSO);
          } else {
            let linkedQuote = data.linkedDocuments.find(
              (doc: any) => doc.documentType === DOC_TYPE.QUOTE
            );
            if (linkedQuote) {
              return formatLinkedDoc(linkedQuote);
            }
          }
        }
        break;
      case DOC_TYPE.ORDER:
        if (data.linkedDocuments?.length) {
          let linkedPR = data.linkedDocuments.find(
            (doc: any) => doc.documentType === DOC_TYPE.PURCHASE_REQUEST
          );
          if (linkedPR) {
            return formatLinkedDoc(linkedPR);
          } else {
            let linkedSO = data.linkedDocuments.find(
              (doc: any) => doc.documentType === DOC_TYPE.SALES_ORDER
            );
            if (linkedPR) {
              return formatLinkedDoc(linkedSO);
            }
          }
        }
        break;
      case DOC_TYPE.BILL:
        if (data.linkedDocuments?.length) {
          let linkedPO = data.linkedDocuments.find(
            (doc: any) => doc.documentType === DOC_TYPE.ORDER
          );

          if (linkedPO) {
            return formatLinkedDoc(linkedPO);
          }
        }
        break;
    }
    return null;
  }

  static async updateApprovalStatus(
    tableId: string,
    recordIds: string[],
    documentType: DOC_TYPE,
    status: APPROVAL_STATUS,
    remarks?: string
  ) {
    if (!tableId?.length || !documentType || !status) {
      showAlert('Error', 'Invalid request!');
    } else if (
      status !== APPROVAL_STATUS.APPROVED &&
      status !== APPROVAL_STATUS.REJECTED
    ) {
      showAlert('Error', 'Invalid approval status!');
    } else if (!recordIds?.length) {
      showAlert('Error', 'No drafts selected!');
    }

    const filterDraftIds = recordIds.filter((id) => !!id?.length);
    if (!filterDraftIds?.length) {
      showAlert('Error', 'No drafts selected!');
    }

    let payload: DraftApprovalPayload = {
      draftIds: filterDraftIds,
      approvalStatus: status,
      documentType,
      remarks
    };

    await Table.updateApprovalStatus(payload, tableId)
      .then((response) => {
        if (response.success) {
          const failedDrafts: any[] = [];
          response.data.forEach((draft: any) => {
            if (!draft.success && !!draft?.responseBody?.draftCode) {
              failedDrafts.push(draft.responseBody);
            }
          });
          if (!failedDrafts?.length) {
             console.log(status)
            showAlert(
              'Success',
              `Drafts ${
                status === APPROVAL_STATUS.APPROVED ? 'approved' : 'rejected'
              } successfully!`
            );
          } else {
            if (failedDrafts.length === 1) {
              showAlert(
                'Error',
                `Failed to ${
                  status === APPROVAL_STATUS.APPROVED ? 'approve' : 'reject'
                } draft <b>${failedDrafts[0].draftCode}</b> - ${
                  failedDrafts[0].errorMessage
                }`
              );
            } else {
              showAlert(
                'Error!',
                `Failed to ${
                  status === APPROVAL_STATUS.APPROVED ? 'approve' : 'reject'
                } drafts <b>${failedDrafts
                  .map((item) => item.draftCode)
                  .join(', ')}</b><br/><br/>` +
                  `<details>` +
                  `<summary class="text-center text-blue-700 hover:text-blue-800 fs-r list-none cursor-pointer mb-2">View details</summary>` +
                  `<table class="text-left">` +
                  `<thead><tr><th class="px-2 border-t border-b">No.</th><th class="px-2 border-t border-b">Reason</th></tr></thead>` +
                  `<tbody>` +
                  failedDrafts
                    .map(
                      (item) =>
                        `<tr><td class="px-2 border-b" style="text-wrap:nowrap;">${item.draftCode}</td><td class="px-2 border-b">${item.errorMessage}</td></tr>`
                    )
                    .join('') +
                  `</tbody>` +
                  `</table>` +
                  `</details>`
              );
            }
          }
        } else {
          showAlert('Error', 'Failed to update the drafts approval status');
        }
      })
      .catch((err) => {
        showAlert(
          'Error',
          `Failed to ${
            status === APPROVAL_STATUS.APPROVED ? 'approve' : 'reject'
          } the drafts`
        );
      });
  }
}

export default DraftService;
