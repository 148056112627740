import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import PayslipUtility from "./PayslipUtility"

export const PayslipTableType = {
    employeeEarnings: 'employeeEarnings',
    employeeTaxesWithhold: 'employeeTaxesWithhold',
    employerTaxes: "employerTaxes",
    employeeDeductions: 'employeeDeductions',
    summary: 'summary',
    allowances: "allowances",
    deductions: 'deductions',
    employerCpf: 'employerCpf',
    netSalaryPaid: "netSalaryPaid",
    employerContribution: 'employerContribution',
}

export default class PayslipTableUtility {

    static getPayslipDefaultTable_IN_US() {
        return [
            { index: 0, name: 'employee_earnings', type: PayslipTableType.employeeEarnings, isSelected: true },
            { index: 1, name: 'employee_taxes_withhold', type: PayslipTableType.employeeTaxesWithhold, isSelected: true },
            { index: 2, name: 'employer_taxes', type: PayslipTableType.employerTaxes, isSelected: true },
            { index: 3, name: 'employee_deductions', type: PayslipTableType.employeeDeductions, isSelected: true },
            { index: 4, name: 'summary', type: PayslipTableType.summary, isSelected: true },
        ]
    }

    static getPayslipDefaultTable_SG() {
        return [
            { index: 0, name: 'employee_earnings', type: PayslipTableType.employeeEarnings, isSelected: true },
            { index: 1, name: 'allowances', type: PayslipTableType.allowances, isSelected: true },
            { index: 2, name: 'deductions', type: PayslipTableType.deductions, isSelected: true },
            { index: 3, name: 'employer_cpf', type: PayslipTableType.employerCpf, isSelected: true },
            { index: 4, name: 'net_salary_paid', type: PayslipTableType.netSalaryPaid, isSelected: true },
        ]
    }

    static getPayslipDefaultTable_MY() {
        return [
            { index: 0, name: 'employee_earnings', type: PayslipTableType.employeeEarnings, isSelected: true },
            { index: 1, name: 'allowances', type: PayslipTableType.allowances, isSelected: true },
            { index: 2, name: 'deductions', type: PayslipTableType.deductions, isSelected: true },
            { index: 3, name: 'employer_contribution', type: PayslipTableType.employerContribution, isSelected: true },
            { index: 4, name: 'net_salary_paid', type: PayslipTableType.netSalaryPaid, isSelected: true },
        ]
    }

    static getPayslipDefaultTable_Other() {
        return [
            { index: 0, name: 'employee_earnings', type: PayslipTableType.employeeEarnings, isSelected: true },
            { index: 1, name: 'employee_taxes_withhold', type: PayslipTableType.employeeTaxesWithhold, isSelected: true },
            { index: 2, name: 'employer_taxes', type: PayslipTableType.employerTaxes, isSelected: true },
            { index: 3, name: 'employee_deductions', type: PayslipTableType.employeeDeductions, isSelected: true },
            { index: 4, name: 'summary', type: PayslipTableType.summary, isSelected: true },
        ]
    }

    static getPayslipDefaultTable(data) {
        var countryCode = PayslipUtility.getCountryCode(data.payslip)
        if (countryCode === 'IN' || countryCode === 'US') {
            return PayslipTableUtility.getPayslipDefaultTable_IN_US()
        }
        else if (countryCode === 'SG') {
            return PayslipTableUtility.getPayslipDefaultTable_SG()
        }
        else if (countryCode === 'MY') {
            return PayslipTableUtility.getPayslipDefaultTable_MY()
        }

        return PayslipTableUtility.getPayslipDefaultTable_Other()
    }

    static getPayslipTable(data) {
        var payslipTable = TemplateSettingsManager.defaultTemplateSettings.payslipTableConfiguration
        if (payslipTable === undefined || payslipTable === null) {
            payslipTable = this.getPayslipDefaultTable(data)
        }
        else if (Object.keys(payslipTable).length === 0) {
            payslipTable = this.getPayslipDefaultTable(data)
        }

        return payslipTable
    }
}
