import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import ProductService from '../../Services/Product';
import RevRecService from '../../Services/RevenueRec';
import RevenueRecognitionService from '../../Services/RevenueRecognition';
import RevRecRuleService from '../../Services/RevRecRule';
import Utility, { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const revenueRecognitionInitialState = {
  ...initialState,
  revenueCategory: {} as any,
  revRecJeLogs: {} as any,
  fairValueFormula: {} as any,
  revRecRules: {} as any
};

export const fetchRevenueCategory = createAsyncThunk('revenueCategories', async () => {
//   const response = await ProductService.getProductsByPage(
//     Utility.getCountryApiExtension(),
//     false
//   );
  const response = await RevenueRecognitionService.getRevenueCategoryByPage()
  return response;
});
export const fetchRevRecLogs = createAsyncThunk('revenueJELogs', async () => {
//   const response = await ProductService.getProductsByPage(
//     Utility.getCountryApiExtension(),
//     false
//   );
  const response = await RevenueRecognitionService.getRevRecJeByPage();
  return response;
});
export const fetchFairValueFormula = createAsyncThunk('revenueFvf', async () => {
//   const response = await ProductService.getProductsByPage(
//     Utility.getCountryApiExtension(),
//     false
//   );
  const response = await RevRecService.getFVF()
  return response;
});
export const fetchRevRecRules = createAsyncThunk('revenueRules', async () => {
//   const response = await ProductService.getProductsByPage(
//     Utility.getCountryApiExtension(),
//     false
//   );
  const response = await RevRecRuleService.getRules()
  return response;
});

export const RevenueRecognitionSlice = createSlice({
  name: 'revenueRecognitions',
  initialState: revenueRecognitionInitialState,
  reducers: {
    addProductColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          id: config.id ? config.id : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.REVENUE_RECOGNITION
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    // addProducts: (state, action: PayloadAction<any>) => {
    //   let currentProducts = { ...state.data };
    //   if (currentProducts.content) {
    //     currentProducts.content.push(action.payload);
    //   } else {
    //     currentProducts.content = [action.payload];
    //   }
    //   state.data = currentProducts;
    // },
    // updateProductDetails: (state, action: PayloadAction<any>) => {
    //   const productDetails = action.payload;
    //   const pageDetails: any = deepClone(state.data);
    //   const content: any[] = pageDetails.content;
    //   const productIndex: number = content.findIndex(
    //     (product: any) => product.id === productDetails.id
    //   );
    //   if (productIndex >= 0) {
    //     content[productIndex] = productDetails;
    //     state.data['content'] = [...content];
    //   }
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueCategory.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchRevenueCategory.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.revenueCategory = action.payload;
      })
      .addCase(fetchRevRecLogs.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchRevRecLogs.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.revRecJeLogs = action.payload;
      })
      .addCase(fetchFairValueFormula.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchFairValueFormula.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.fairValueFormula = action.payload;
      })
      .addCase(fetchRevRecRules.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchRevRecRules.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.fairValueFormula = action.payload;
      })
    //   .addCase(fetchProductsWithVariants.pending, (state) => {
    //     state.status = API_STATUS.LOADING;
    //   })
    //   .addCase(fetchProductsWithVariants.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.productsWithVariants = action.payload;
    //   })
    //   .addCase(fetchProductSummary.pending, (state) => {
    //     state.status = API_STATUS.LOADING;
    //   })
    //   .addCase(fetchProductSummary.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.summary = action.payload;
    //   })
    //   .addCase(fetchAttributeList.pending, (state) => {
    //     state.status = API_STATUS.LOADING;
    //   })
    //   .addCase(fetchAttributeList.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.attributes = action.payload;
    //   })
    //   .addCase(fetchProductWarehouses.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.productWarehouses = action.payload;
    //   });
  }
});

export const {
//   addColumnToProductColumnConfig,
  addProductColumnConfig,
//   addProducts,
  updateColumnConfig,
//   updateProductDetails
} = RevenueRecognitionSlice.actions;

export const selectRevenueCategories = (state: RootState) => state.revenueRecognitions.revenueCategory;
export const selectRevRecJeLogs = (state: RootState) => state.revenueRecognitions.revRecJeLogs;
export const selectFVF = (state: RootState) => state.revenueRecognitions.fairValueFormula;
export const selectRules = (state: RootState) => state.revenueRecognitions.revRecRules;

export default RevenueRecognitionSlice.reducer;
