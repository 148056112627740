import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import WarehouseService from '../../Services/Warehouse';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import Utility from '../../Utility/Utility';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  allWarehousesWithoutRole: {} as any,
  rejectedWarehouse: {} as any,
  qaWarehouse: {} as any,
  warehouseWithRRBCombination: {} as any,
  warehouseWithRRBCombinationWithoutRole: {} as any
};

/* const warehouseInitialState = {
  ...initialState
};
 */
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchWarehouse = createAsyncThunk('warehouse', async () => {
  const response = await WarehouseService.getWarehouses();
  return response;
});
export const fetchWarehouseWithRRBCombination = createAsyncThunk(
  'warehouseWithRRBCombination',
  async (config?: any) => {
    if (Utility.isEmpty(config)) {
      config = {
        SearchTerm: '',
        Limit: 100,
        Page: 0,
        Query: 'active=true&allRRBDetails=true'
      };
    }
    const response = await WarehouseService.getWarehousesWithRRBCombinations(
      config
    );
    return response;
  }
);
export const fetchWarehouseWithRRBCombinationWithoutRole = createAsyncThunk(
  'warehouseWithRRBCombinationWithoutRole',
  async () => {
    const whconfig: any = {
      SearchTerm: '',
      Limit: 50,
      Page: 0,
      Query: 'active=true&allRRBDetails=true&filterOnRolesFlg=false'
    };

    const response = await WarehouseService.getWarehousesWithRRBCombinations(
      whconfig
    );
    return response;
  }
);
export const fetchAllWarehouseWithoutRole = createAsyncThunk(
  'allWarehousesWithoutRole',
  async () => {
    const response = await WarehouseService.getWarehousesForAdmin();
    return response;
  }
);

export const fetchRejectedWarehouses = createAsyncThunk(
  'fetchRejectedWarehouse',
  async () => {
    const response = await WarehouseService.getRejectedWarehouses();
    return response;
  }
);

export const fetchQAWarehouses = createAsyncThunk(
  'fetchQAWarehouse',
  async () => {
    const response = await WarehouseService.getQAWarehouses();
    return response;
  }
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const WarehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    addWarehouseColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToWarehouseColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouse.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let warehouseList: any = action.payload;
        state.data = warehouseList;
      })
      .addCase(fetchAllWarehouseWithoutRole.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.allWarehousesWithoutRole = action.payload;
      })
      .addCase(fetchQAWarehouses.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.qaWarehouse = action.payload;
      })
      .addCase(fetchRejectedWarehouses.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.rejectedWarehouse = action.payload;
      })
      .addCase(fetchWarehouseWithRRBCombination.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.warehouseWithRRBCombination = action.payload;
      })
      .addCase(
        fetchWarehouseWithRRBCombinationWithoutRole.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          state.warehouseWithRRBCombinationWithoutRole = action.payload;
        }
      );
  }
});

export const {
  addWarehouseColumnConfig,
  addColumnToWarehouseColumnConfig,
  updateColumnConfig
} = WarehouseSlice.actions;

export const selectWarehouse = (state: RootState) => state.warehouse.data;
export const selectWarehouseColumnConfig = (state: RootState) =>
  state.warehouse.columnConfig;
export const selectWarehouseColumnConfigTableId = (state: RootState) =>
  state.warehouse.configTableId;

export const selectedAllWarehousesWithoutRole = (state: RootState) =>
  state.warehouse.allWarehousesWithoutRole;

export const selectedRejectedWarehouses = (state: RootState) =>
  state.warehouse.rejectedWarehouse;

export const selectedQAWarehouses = (state: RootState) =>
  state.warehouse.qaWarehouse;
export const selectedWarehouseWithRRBCombination = (state: RootState) =>
  state.warehouse.warehouseWithRRBCombination;

export const selectedWarehouseWithRRBCombinationWithoutRole = (
  state: RootState
) => state.warehouse.warehouseWithRRBCombinationWithoutRole;

export default WarehouseSlice.reducer;
