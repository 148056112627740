import React, { useState } from 'react';
import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer';
import Utility from '../../../Utility/Utility';

const styles = StyleSheet.create({
  mainBody: {
    borderStyle: 'solid',
    borderWidth: 2
  },

  title: {
    display: 'flex',
    alignSelf: 'center',
    margin: 10,
    marginTop: 20,
    fontSize: 20
  },

  docInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 10,
    marginTop: 18,
    marginLeft: 22,
    marginRight: 22,
    marginBottom: 22,
    fontWeight: 'bold',
    letterSpacing: 1
  },

  docInfoleft: {},

  docInfoRight: {},

  table: {
    // display: 'table',
    backgroundColor: '#edf1f7',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    marginLeft: 22,
    marginRight: 22
  },

  table1: {
    width: 'auto',
    borderStyle: 'solid',
    marginLeft: 22,
    marginRight: 22,
    borderBottomWidth: 1,
    borderLeftWidth: 1
  },

  tableRow: {
    margin: 0,
    flexDirection: 'row'
  },

  tableCol: {
    width: 100,
    borderStyle: 'solid',
    borderRightWidth: 1
  },

  tableCell: {
    margin: 2,
    fontSize: 10
  },

  tableCell1: {
    maxWidth: 'auto',
    margin: 1,
    fontSize: 8,
    opacity: 0.7
  },

  tableCell2: {
    maxWidth: 'auto',
    maxHeight: 'auto',
    margin: 1,
    marginRight: 2,
    fontSize: 8,
    opacity: 0.7,
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end'
  },

  reportSummary: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 22,
    marginBottom: 50
  },

  reportSummary1: {
    minWidth: 126,
    width: 'auto',
    padding: 4,
    borderStyle: 'solid',
    fontSize: 10,
    marginTop: 10
  },

  reportSummary2: {
    maxWidth: 127,
    width: 'auto',
    padding: 4,
    borderStyle: 'solid',
    fontSize: 10,
    alignItems: 'flex-end',
    marginTop: 10
  },

  reportSummaryElement: {
    marginTop: 5,
    marginBottom: 5
  }
});

const columns = [
  'Product Code',
  'Product Name',
  'Quantity',
  'Purchase Price',
  'Product Cost',
  'Unit Price',
  'Gross Revenue',
  'Discount Rate (%)',
  'Discount',
  'Revenue',
  'Profit Margin',
  'Profit Margin (%)',
  'Total Tax'
];

const PrintRateAnalysis = (props: any) => {
  const [docData, setDocData] = useState(props?.dataForPrintDocument);

  return (
    <>
      <Document>
        <Page style={styles.mainBody}>
          <View style={styles.title}>
            <Text>Rate Analysis Report</Text>
          </View>

          <View style={styles.docInfo}>
            <View style={styles.docInfoleft}>
              <Text style={{ marginBottom: 10 }}>
                Document Number:- {docData?.documentSequenceCode}
              </Text>
              <Text>
                {'Customer Name:- '}
                {docData?.contact_name
                  ? docData?.contact_name
                  : docData?.contact?.name}
              </Text>
            </View>
            <View style={styles.docInfoRight}>
              <Text> Date:- {docData.documentDate}</Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              {columns.map((column) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{column}</Text>
                  </View>
                );
              })}
            </View>
          </View>

          {!Utility.isEmpty(docData?.lineItems) &&
            docData?.lineItems.map((item: any) => {
              function formatValue(value: any) {
                const val: string = value.toString();
                let formattedValue = '';
                if (val && val.length > 7) {
                  for (let i = 0; i < val.length; i += 7) {
                    formattedValue += val.slice(i, i + 7) + '\n';
                  }
                  return formattedValue;
                } else {
                  return value;
                }
              }

              return (
                <View style={styles.table1}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell1}>
                        {formatValue(item?.documentSequenceCode)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell1}>
                        {formatValue(item.productName)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell1}>
                        {formatValue(item?.productQuantity)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.purchasePrice)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.productCost)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.unitPrice)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.grossRevenue)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.discountRate)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(
                          item?.discountAmount
                            ? item?.discountAmount
                            : item?.discount
                        )}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.netRevenue)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.profitMargin)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.profitMarginPercentage)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell2}>
                        {formatValue(item?.taxAmount)}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}

          <View style={styles.reportSummary}>
            <View style={styles.reportSummary1}>
              <View style={styles.reportSummaryElement}>
                <Text>Total Cost</Text>
              </View>
              {docData?.commissionCharges &&
              !Utility.isEmpty(docData?.commissionCharges) ? (
                docData?.commissionCharges.map((commission: any) => {
                  return (
                    <View style={styles.reportSummaryElement}>
                      <Text>Commission - {commission?.label}{commission?.isPercent ? '(' + commission?.value + '%)' : ''}</Text>
                    </View>
                  );
                })
              ) : (
                <View style={styles.reportSummaryElement}>
                  <Text>Commission</Text>
                </View>
              )}
              <View style={styles.reportSummaryElement}>
                <Text>Net Cost</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>Total Revenue</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>Total Row Discount</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>Total Tax</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>Total Profit Margin</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>Profit Margin (%)</Text>
              </View>
            </View>
            <View style={styles.reportSummary2}>
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.grossProductCost}</Text>
              </View>
              {docData?.commissionCharges &&
              !Utility.isEmpty(docData?.commissionCharges) ? (
                docData?.commissionCharges.map((commission: any) => {
                  return (
                    <View style={styles.reportSummaryElement}>
                      <Text>{commission?.amount || commission?.value}</Text>
                    </View>
                  );
                })
              ) : (
                <View style={styles.reportSummaryElement}>
                  <Text>0</Text>
                </View>
              )}
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.netProductCost}</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.netProductRevenue}</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.discountOnRevenue}</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.taxAmount}</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>{docData?.netProfitMargin}</Text>
              </View>
              <View style={styles.reportSummaryElement}>
                <Text>
                  {docData?.profitMarginPercentage &&
                  docData?.profitMarginPercentage !== Infinity
                    ? docData?.profitMarginPercentage
                    : 0}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PrintRateAnalysis;
