import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import ExpenseAllocationService from '../../Services/ExpenseAllocation';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const expenseAllocationInitialState = {
  expenseAllocationTemplates: { ...initialState }
};

export const fetchExpenseAllocationTemplates = createAsyncThunk(
  'expenseAllocationTemplates',
  async () => {
    return await ExpenseAllocationService.getExpenseAllocationTemplatesByPage();
  }
);

export const ExpenseAllocationSlice = createSlice({
  name: 'expenseAllocation',
  initialState: expenseAllocationInitialState,
  reducers: {
    addExpenseAllocationTemplatesColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          id: config.id ? config.id : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.expenseAllocationTemplates.columnConfig = configsToStore;
      state.expenseAllocationTemplates.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.AMORTIZATION_TEMPLATES
      );
    },
    updateExpenseAllocationTemplatesColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.expenseAllocationTemplates.columnConfig = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseAllocationTemplates.pending, (state) => {
        state.expenseAllocationTemplates.status = API_STATUS.LOADING;
      })
      .addCase(fetchExpenseAllocationTemplates.fulfilled, (state, action) => {
        state.expenseAllocationTemplates.status = API_STATUS.IDLE;
        state.expenseAllocationTemplates.data = action.payload;
      })
  }
});

export const {
  addExpenseAllocationTemplatesColumnConfig,
  updateExpenseAllocationTemplatesColumnConfig,
} = ExpenseAllocationSlice.actions;

export const selectAmortizationTemplates = (state: RootState) =>
  state.expenseAllocation.expenseAllocationTemplates;

export default ExpenseAllocationSlice.reducer;
