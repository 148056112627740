import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { EditorInfoType, FONT_SIZE, LINE_ITEM_HEIGHT } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

// import {StyleSheet} from "react";

export default class DocumentTemplate11 extends Component {
    arabicHeaderWidth = '63%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentTitleLabel() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-end', top: 0 }}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        color: this.state.data.themeColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        )
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                    height: getVW(Utility.convertToPrintScale(40, this.state.data.printScale)),
                }}
            >
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                        border: 'solid',
                        borderColor: 'black',
                        borderWidth: getVW(1),
                        width: '100%',
                        height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale)),
                        backgroundColor: this.state.data.themeColor + '4D',
                        paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : undefined,
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: titleColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {title?.toUpperCase()}
                    </text>
                </div>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        border: 'solid',
                        borderColor: 'black',
                        borderWidth: getVW(1),
                        width: '100%',
                        height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale))
                    }}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            // paddingLeft: getVW(10),
                            height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale)),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : undefined,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    getInvoiceDetailsView() {
        return (
            <div className="ColumnDiv" style={{ alignItems: 'flex-end', width: '100%' }}>
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        var newDocumentDetailList = []
        documentDetailList.forEach((element, index) => {
            if(index !== 0) {
                newDocumentDetailList.push(element)
            }
        });

        var newArr = []
        newArr.push([documentDetailList[0]])
        while (newDocumentDetailList.length > 0) {
            newArr.push(newDocumentDetailList.splice(0, 2))
        };

        return newArr.map((rowElement) => {
            var value = ''
            if(rowElement.length === 1) {
                if (rowElement[0]?.key !== undefined) {
                    value = this.state.data[rowElement[0]?.key]
                }
                else if (rowElement[0]?.customFieldValue !== undefined) {
                    value = rowElement[0]?.customFieldValue
                }
                return <div className="ColumnDiv" style={{ width: '70%', height: getVW(Utility.convertToPrintScale(33, this.state.data.printScale)) }}>
                    {this.getRowValueSection(
                        rowElement[0]?.label,
                        value,
                        'black'
                    )}
                </div>
            }
            else if (rowElement.length === 2) {
                var row = rowElement.map(element => {
                    if (element.key !== undefined) {
                        value = this.state.data[element.key]
                    }
                    else if (element.customFieldValue !== undefined) {
                        value = element.customFieldValue
                    }
                    return <div className="ColumnDiv" style={{ width: '70%', height: getVW(Utility.convertToPrintScale(33, this.state.data.printScale)) }}>
                        {this.getRowValueSection(
                            element.label,
                            value,
                            'black'
                        )}
                    </div>
                });

                return <div className="RowDiv" style={{ height: '100%' }}>
                    {row}
                </div>
            }
            else {
                return <></>
            }
        });
    }

    getCompanyNameLabel() {
        return (
            <div className="RowDiv"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse': 'row',
                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth: '100%'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: 'black',
                        fontWeight: '500',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '90%',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                </div>
            </div>
        )
    }

    getAddressAndDocDetailView() {
        return (
            <div className="ColumnDiv" style={{ width: '30%' }}>

                <div className="RowDiv"
                    style={{
                        border: 'solid',
                        borderColor: 'black',
                        borderWidth: getVW(1),
                        paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        paddingRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        backgroundColor: this.state.data.themeColor + '4D',
                        height: getVW(`${Utility.convertToPrintScale(Number(LINE_ITEM_HEIGHT.replace('vw', '')), this.state.data.printScale)}vw`, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' :'flex-start'
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'black',
                            paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular}, this.state.data.printScale),
                        }}>
                        {getLocalisedText(this.state.data.billToTitle)}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        marginTop: getVW(6),
                        display: 'block',
                        flexDirection: Utility.getIsArabicLang() ?  'row-reverse' : 'row',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo)}, this.state.data.printScale),
                    }}
                        dangerouslySetInnerHTML={{__html: getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
                </div>

            </div>
        )
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv  d-flex justify-content-between' style={{ width: '50%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) &&
                    <>
                    <div className="RowDiv"
                        style={{
                            border: 'solid',
                            borderColor: 'black',
                            borderWidth: getVW(1),
                            paddingLeft: getVW(10),
                            paddingRight: getVW(10),
                            backgroundColor: this.state.data.themeColor + '4D',
                            height: getVW(LINE_ITEM_HEIGHT, true),
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                color: 'black',
                                paddingLeft: getVW(10),
                                paddingRight: getVW(10),
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                            }}>
                            {getLocalisedText('source_of_supply')}
                        </text>
                    </div>

                    <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            marginTop: getVW(6),
                            display: 'block',
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale),
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.sourceOfSupply }}>
                    </div>
                    </>
                }
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) &&
                    <>
                    <div className="RowDiv"
                        style={{
                            border: 'solid',
                            borderColor: 'black',
                            borderWidth: getVW(1),
                            paddingLeft: getVW(10),
                            paddingRight: getVW(10),
                            backgroundColor: this.state.data.themeColor + '4D',
                            height: getVW(LINE_ITEM_HEIGHT, true),
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                color: 'black',
                                paddingLeft: getVW(10),
                                paddingRight: getVW(10),
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                            }}>
                            {getLocalisedText('destination_of_supply')}
                        </text>
                    </div>

                    <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            marginTop: getVW(6),
                            display: 'block',
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }),
                        }}
                        dangerouslySetInnerHTML={{ __html: this.state.data.destinationOfSupply }}>
                    </div>
                    </>
                }
            </div>
        </div>;
    }

    addFooterView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ bottom: getVW(5) }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "100%",
                    "gray",
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                <div className="ColumnDiv" style={{ width: '40%' }}>
                    {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(10)}
                    {this.state.data.showFrom && this.getCompanyInformationView()}
                </div>
                <div className="ColumnDiv" style={{ width: '60%' }}>
                    {this.getDocumentTitleLabel()}
                    {ComponentManager.addVerticalSpace(10)}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.getInvoiceDetailsView()}
                </div>

            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {/* section 0 */}
                    {this.addHeaderSection()}
                    {/* section 1 */}
                    {ComponentManager.addVerticalSpace(30)}
                    {/* section 2 */}
                    {this.state.data.showClientBillToAddress && this.getAddressAndDocDetailView()}
                    {/* section 3 */}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.addFooterView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>

        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor + '4D';
        style.headerTextColor = "black";
        style.headerBorderColor = 'black';
        style.itemBorderColor = 'black';
        style.footerBackgroundColor = 'transparent';
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent"
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return (
            <div className="ColumnDiv" style={{ width: '30%' }}>

                <div className="RowDiv"
                    style={{
                        border: 'solid',
                        borderColor: 'black',
                        borderWidth: getVW(1),
                        paddingLeft: getVW(10),
                        paddingRight: getVW(10),
                        backgroundColor: this.state.data.themeColor + '4D',
                        height: getVW(LINE_ITEM_HEIGHT, true),
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: Utility.getIsArabicLang() ? 'flex-end' :'flex-start'
                    }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: 'black',
                            paddingLeft: getVW(10),
                            paddingRight: getVW(10),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular}, this.state.data.printScale),
                        }}>
                        {getLocalisedText('place_of_supply')}
                    </text>
                </div>

                <div className={"RowDiv docFontStyle" + getFontSizeClass()}
                    style={{
                        marginTop: getVW(6),
                        display: 'block',
                        flexDirection: Utility.getIsArabicLang() ?  'row-reverse' : 'row',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: true}, this.state.data.printScale),
                    }}
                        dangerouslySetInnerHTML={{__html: this.state.data.placeOfSupply}}>
                </div>

            </div>
        )
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}

