import Utility from '../../Utility/Utility';

export default class DBTableManager {
  static currentSourceInfo = null; //[{ columns: columns, name: tableName }]
  static dbTablesData = null;
  static getAllDBTableData() {
    return DBTableManager.dbTablesData;
  }
  static setAllDBTableData(data) {
    DBTableManager.dbTablesData = data;
  }

  static getColumnDisplayName(columnName, tableName) {
    if (tableName === 'stock_movement_view') {
      tableName = 'stock_movement_report';
    }
    let columnData = [];
    let tableData = DBTableManager.getAllDBTableData().filter(
      (obj) => obj.tableName === tableName
    );
    if (tableData[0] && tableData[0].fields) {
      columnData = tableData[0].fields.filter((obj) => obj.name === columnName);
    }
    if (columnData && columnData[0]) {
      return columnData[0].display;
    }
    return '';
    return columnData[0].display;
  }

  static setCurrentSourceInfo(info) {
    DBTableManager.currentSourceInfo = info;
  }
  static getCurrentSourceInfo() {
    return DBTableManager.currentSourceInfo;
  }

  static getColumnDataType(columnName, tableName) {
    let tableData = DBTableManager.getAllDBTableData().filter(
      (obj) => obj.tableName === tableName
    );
    let columnData =
      tableData.length > 0
        ? tableData[0].fields.filter((obj) => obj.name === columnName)
        : [{ data_type: 'STRING' }];
    return columnData[0].data_type.toUpperCase();
  }

  static convertDataKeysToDisplayName(dataArray) {
    if (dataArray.length > 0) {
      let element = dataArray[0];
      let allKeys = Object.keys(element);
      let getTableName = allKeys[0].split('__')[0];

      let newDataArray = dataArray.map((item) => {
        let newItem = {};
        for (let i = 0; i < allKeys.length; i++) {
          let keyName = allKeys[i];
          let tableName = keyName.split('__')[0];
          let newKeyName = keyName.split('__')[1];
          if (
            !Utility.isEmpty(
              DBTableManager.getColumnDisplayName(newKeyName, tableName)
            )
          ) {
            newItem[
              DBTableManager.getColumnDisplayName(newKeyName, tableName)
            ] = item[keyName];
          }
        }
        return newItem;
      });
      return newDataArray;
    }
    return [];
  }
}
