import { format } from 'date-fns';
import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKRadioButton,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  DKListPicker2,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import {
  DATE_CONSTANTS,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import {
  CallBackPayloadType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  eWayAuthInfo,
  fetchEWayBillAuthInfo
} from '../../Redux/Slices/AuthSlice';
import { selectContacts } from '../../Redux/Slices/ContactsSlice';
import {
  fetchTransporters,
  selectEwayBillTransporter
} from '../../Redux/Slices/EwayBillSlice';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import EwayBillService from '../../Services/EwayBill';
import NumberFormatService from '../../Services/NumberFormat';
import { getFormattedAddressObj } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../Utility/Utility';
import EWayBillLoginPopUp from '../Settings/OrganisationProfile/EWayBillLoginPopUp';
import {
  ADDRESS_DETAIL_TYPE,
  documentTypesStore,
  EwayBillFormInitialState,
  EwayBillProductDetailsColumnConfig,
  eWayTransactionTypes,
  NOT_GENERATED,
  transactionSubTypesStore,
  transactionTypesStore,
  TRANSPORTATION_TYPE,
  VEHICLE_TYPE
} from './EwayBillConstant';
import EWayResponsePopUp from './EWayResponsePopUp';
import ManageTransporter from './ManageTransporter';

interface AddEWayBillProps {
  data: any;
  invoiceObjects: any[];
  readOnly: boolean;
  passingInteraction: any;
  onSuccess: any;
  onCancel: any;
}

const AddEwayBill = (props: AddEWayBillProps) => {
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: {
      createTransporter: 'click'
    }
  };

  const contactsData = useAppSelector(selectContacts);
  const transporterData = useAppSelector(selectEwayBillTransporter);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const eWayAuthInfoData = useAppSelector(eWayAuthInfo);
  const [invoiceData, setInvoiceData] = useState([]);
  const [showTransporterForm, setShowTransporterForm] = useState(false);
  // const [formData, setFormData] = useState<any>(EwayBillFormInitialState);
  const [selectedInvoice, setSelectedInvoice] = useState<any>('');
  const [isLoadingInvoiceData, setIsLoadingInvoiceData] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState<any>(props?.readOnly);
  const [productDocumentCurrency, setProductDocumentCurrency] = useState('');
  const [showEWayBillLoginPortal, setShowEWayBillLoginPortal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedInvoiceObject, setSelectedInvoiceObject] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const [invoicesArray, setInvoicesArray] = useState(props.invoiceObjects);
  const [canValidate, setCanValidate] = useState(false);
  const [showResponsePopUp, setShowResponsePopUp] = useState(false);
  const [eWayResponseObject, setEWayResponseObject] = useState<any>();
  const [selectedTransporter, setSelectedTransporter] = useState<any>(null);
  const manageTransporterBilltRef =
    useRef<UpdateCallBacksRefType>(refInitialState);
  const [orgPreferredAddress, setOrgPreferredAddress] = useState([]);
  const [contactPreferredAddress, setContactPreferredAddress] = useState([]);
  const [openDispatchedFromAddressList, setOpenDispatchedFromAddressList] =
    useState<boolean>(false);
  const [openDispatchedToAddressList, setOpenDispatchedToAddressList] =
    useState<boolean>(false);
  const [openBillFromAddressList, setOpenBillFromAddressList] =
    useState<boolean>(false);
  const [openBillToAddressList, setOpenBillToAddressList] =
    useState<boolean>(false);
  const [AddressDetails, setAddressDetails] = useState<any>({});

  useEffect(() => {
    // disable token expiry check
    // if (!Utility.isEmpty(eWayAuthInfoData)) {
    //   let tokenExpDate = new Date(eWayAuthInfoData.tokenExp);
    //   if (tokenExpDate < new Date()) {
    //     dispatch(fetchEWayBillAuthInfo({}))
    //       .then(
    //         (authToken: any) =>
    //           Utility.isEmpty(authToken?.payload) &&
    //           setShowEWayBillLoginPortal(true)
    //       )
    //       .catch(() => setShowEWayBillLoginPortal(true));
    //   }
    // } else {
    //   dispatch(fetchEWayBillAuthInfo({}))
    //     .then(
    //       (authToken: any) =>
    //         Utility.isEmpty(authToken?.payload) &&
    //         setShowEWayBillLoginPortal(true)
    //     )
    //     .catch(() => setShowEWayBillLoginPortal(true));
    // }
    if (Utility.isEmpty(eWayAuthInfoData)) {
      dispatch(fetchEWayBillAuthInfo({}))
        .then((authToken: any) => {
          if (Utility.isEmpty(authToken?.payload)) {
            setShowEWayBillLoginPortal(true);
          } else if (
            authToken?.payload?.status === '0' &&
            authToken?.payload?.error?.message
          ) {
            showAlert('Error!', authToken?.payload?.error?.message);
            setShowEWayBillLoginPortal(true);
          }
        })
        .catch(() => setShowEWayBillLoginPortal(true));
    }
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let preferredAddress: any = [];
      tenantInfo?.billingAddresses?.map((billingAddress: any) => {
        if (billingAddress.preferred) {
          preferredAddress.push(billingAddress);
        }
      });

      tenantInfo?.shippingAddresses?.map((shippingAddress: any) => {
        if (shippingAddress.preferred) {
          preferredAddress.push(shippingAddress);
        }
      });

      setOrgPreferredAddress(preferredAddress);
    }
  }, [tenantInfo]);

  useEffect(() => {
    dispatch(fetchTransporters());
  }, []);

  useEffect(() => {
    setInvoicesArray(props.invoiceObjects);
  }, [props.invoiceObjects]);

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      renderPropsData();
    }
  }, [props.data]);

  useEffect(() => {
    if (!Utility.isEmpty(selectedInvoiceObject)) {
      // renderGenerateEWayBill();
    }
  }, [selectedInvoiceObject]);

  useEffect(() => {
    if (!Utility.isEmpty(invoicesArray)) {
      let invoiceObj = { ...invoicesArray[selectedIndex] };
      let renderData: any = { ...EwayBillFormInitialState };
      renderData.salesInvoice = invoiceObj;
      renderData.customerName = invoiceObj.contact.name;
      renderData.ewayDocumentType = 'INVOICE';
      renderData.transactionSubType = 'SUPPLY';
      renderData.transactionType = 'COMBINATION_OF_2_AND_3';
      renderData.shipByDate = invoiceObj.shipByDate;

      let preferredContactAddress: any = [];
      invoiceObj?.contactDto?.billingAddress?.map((billingAddress: any) => {
        if (billingAddress.preferred) {
          preferredContactAddress.push(billingAddress);
        }
      });

      invoiceObj?.contactDto?.shippingAddress?.map((shippingAddress: any) => {
        if (shippingAddress.preferred) {
          preferredContactAddress.push(shippingAddress);
        }
      });

      setContactPreferredAddress(preferredContactAddress);

      let addressDetailObj = {
        dispatchedFrom: invoiceObj.shipFrom,
        dispatchedTo: invoiceObj.shipTo,
        billFrom: invoiceObj.shipFrom,
        billTo: invoiceObj.billTo
      };
      setAddressDetails(addressDetailObj);

      invoiceObj.addressDetail = addressDetailObj;

      let _ewayDocumentType = documentTypesStore.findIndex(
        (documentType: any) => {
          return documentType.id === renderData.ewayDocumentType;
        }
      );

      let _transactionSubType = transactionSubTypesStore.findIndex(
        (subType: any) => {
          return subType.id === renderData.transactionSubType;
        }
      );

      let _transactionType = transactionTypesStore.findIndex((type: any) => {
        return type.id === renderData.transactionType;
      });

      if (Utility.isEmpty(invoiceObj.formData)) {
        invoiceObj.formData = {
          ...renderData,
          transactionDetails: {
            ewayDocumentType: {
              id: _ewayDocumentType,
              value: renderData.ewayDocumentType
            },
            transactionSubType: {
              id: _transactionSubType,
              value: transactionSubTypesStore[_transactionSubType].name
            },
            customerName: {
              id: renderData.customerName,
              value: renderData.customerName
            },
            transactionType: {
              id: _transactionType,
              value: eWayTransactionTypes[_transactionType].id
            },
            salesInvoiceCode: {
              id: renderData.salesInvoice.salesInvoiceCode,
              value: renderData.salesInvoice.salesInvoiceCode
            },
            shipByDate: renderData.shipByDate
          }
        };
      } else {
      }

      updateInvoiceObjectInArray(invoiceObj);

      setSelectedInvoice(renderData.salesInvoice);
      setProductDocumentCurrency(renderData.salesInvoice.currency);
      setSelectedInvoiceObject(invoiceObj);
    }
  }, [selectedIndex]);

  useEffect(() => {
    // if (!Utility.isEmpty(selectedInvoiceObject)) {
    //   renderGenerateEWayBill();
    // }
  }, [selectedInvoiceObject]);

  const updateInvoiceObjectInArray = (invoice: any) => {
    setSelectedInvoiceObject(invoice);
    let invoicesArrayCopy = [...invoicesArray];
    invoicesArrayCopy[selectedIndex] = invoice;
    setInvoicesArray(invoicesArrayCopy);
  };

  useEffect(() => {
    if (isReadOnly) {
      const divElem = document.getElementById('popup-container');
      const inputElements = divElem?.querySelectorAll('input');
      inputElements?.forEach((el) => (el.disabled = true));
    }
  });

  const changeDateFormat = (date_string: any) => {
    var date_components = date_string.split('/');
    var day = date_components[0];
    var month = date_components[1];
    var year = date_components[2];
    return new Date(year, month - 1, day);
  };

  const renderPropsData = () => {
    let renderData: any = props.data;

    let _ewayDocumentType = documentTypesStore.findIndex(
      (documentType: any) => {
        return documentType.id === renderData.ewayDocumentType;
      }
    );

    let _transactionSubType = transactionSubTypesStore.findIndex(
      (subType: any) => {
        return subType.id === renderData.transactionSubType;
      }
    );

    let _transactionType = transactionTypesStore.findIndex((type: any) => {
      return type.id === renderData.transactionType;
    });

    let _transporterName = transporterData.findIndex((transporter: any) => {
      return transporter.transporterId === renderData.transporterName;
    });

    setSelectedInvoice(renderData);
    setProductDocumentCurrency(renderData.salesInvoice.currency);

    let invoiceCopy = { ...selectedInvoice };
    invoiceCopy.formData = {
      transactionDetails: {
        ewayDocumentType: {
          id: _ewayDocumentType,
          value: renderData.ewayDocumentType
        },
        transactionSubType: {
          id: _transactionSubType,
          value: transactionSubTypesStore[_transactionSubType].name
        },
        customerName: {
          id: renderData.customerName,
          value: renderData.customerName
        },
        transactionType: {
          id: _transactionType,
          value: eWayTransactionTypes[_transactionType].id
        },
        salesInvoiceCode: {
          id: renderData.salesInvoiceCode,
          value: renderData.salesInvoiceCode
        },
        shipByDate: renderData.shipByDate
      },
      transportationDetails: {
        transporterName: {
          id: transporterData[_transporterName].transporterId,
          value: transporterData[_transporterName].transporterName
        },
        distanceInKm: renderData.distanceInKm
      },
      modeOfTransportation: {
        transportationMode: renderData.transportationMode,
        road: {
          vehicleType:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
              ? renderData.vehicleType
              : VEHICLE_TYPE.VEHICLE_TYPE_REGULAR,
          vehicleNo:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
              ? renderData.vehicleNo
              : '',
          transporterDocNo:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
              ? renderData.transporterDocNo
              : '',
          transporterDocDate:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
              ? changeDateFormat(renderData.transporterDocDate)
              : ''
        },
        rail: {
          billOfLadingNo:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
              ? renderData.billOfLadingNo
              : '',
          billOfLadingDate:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
              ? changeDateFormat(renderData.billOfLadingDate)
              : ''
        },
        air: {
          airwayBillNo:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
              ? renderData.airwayBillNo
              : '',
          airwayBillDate:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
              ? changeDateFormat(renderData.airwayBillDate)
              : ''
        },
        ship: {
          shipBillOfLadingNo:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
              ? renderData.shipBillOfLadingNo
              : '',
          shipBillOfLadingDate:
            renderData.transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
              ? changeDateFormat(renderData.shipBillOfLadingDate)
              : ''
        }
      }
    };
    updateInvoiceObjectInArray(invoiceCopy);

    // setFormData({
    //   transactionDetails: {
    //     ewayDocumentType: {
    //       id: _ewayDocumentType,
    //       value: renderData.ewayDocumentType
    //     },
    //     transactionSubType: {
    //       id: _transactionSubType,
    //       value: transactionSubTypesStore[_transactionSubType].name
    //     },
    //     customerName: {
    //       id: renderData.customerName,
    //       value: renderData.customerName
    //     },
    //     transactionType: {
    //       id: _transactionType,
    //       value: eWayTransactionTypes[_transactionType].id
    //     },
    //     salesInvoiceCode: {
    //       id: renderData.salesInvoiceCode,
    //       value: renderData.salesInvoiceCode
    //     },
    //     shipByDate: renderData.shipByDate
    //   },
    //   transportationDetails: {
    //     transporterName: {
    //       id: transporterData[_transporterName].transporterId,
    //       value: transporterData[_transporterName].transporterName
    //     },
    //     distanceInKm: renderData.distanceInKm
    //   },
    //   modeOfTransportation: {
    //     transportationMode: renderData.transportationMode,
    //     road: {
    //       vehicleType:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
    //           ? renderData.vehicleType
    //           : VEHICLE_TYPE.VEHICLE_TYPE_REGULAR,
    //       vehicleNo:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
    //           ? renderData.vehicleNo
    //           : '',
    //       transporterDocNo:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
    //           ? renderData.transporterDocNo
    //           : '',
    //       transporterDocDate:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
    //           ? changeDateFormat(renderData.transporterDocDate)
    //           : ''
    //     },
    //     rail: {
    //       billOfLadingNo:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
    //           ? renderData.billOfLadingNo
    //           : '',
    //       billOfLadingDate:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
    //           ? changeDateFormat(renderData.billOfLadingDate)
    //           : ''
    //     },
    //     air: {
    //       airwayBillNo:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
    //           ? renderData.airwayBillNo
    //           : '',
    //       airwayBillDate:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
    //           ? changeDateFormat(renderData.airwayBillDate)
    //           : ''
    //     },
    //     ship: {
    //       shipBillOfLadingNo:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
    //           ? renderData.shipBillOfLadingNo
    //           : '',
    //       shipBillOfLadingDate:
    //         renderData.transportationMode ===
    //         TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
    //           ? changeDateFormat(renderData.shipBillOfLadingDate)
    //           : ''
    //     }
    //   }
    // });
  };

  const fetchInvoiceData = (countryCode: string, contactCode: string) => {
    EwayBillService.getInvoicesOnContacts(
      countryCode.toLowerCase(),
      contactCode
    ).then(
      (res: any) => {
        setInvoiceData(res);
        setIsLoadingInvoiceData(false);
      },
      (err) => {
        setIsLoadingInvoiceData(false);
        console.error('Error fetching report transaction details: ', err);
        showToast('Error Fetching Report Transaction', TOAST_TYPE.FAILURE);
      }
    );
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const deleteTransporter = (transporter: any) => {
    if (transporter && transporter !== null) {
      EwayBillService.deleteManageTransporter(transporter.id).then(
        (res: any) => {
          showToast('Transporter Deleted Successfully', TOAST_TYPE.SUCCESS);
          let id = selectedInvoiceObject?.formData?.transportationDetails.transporterName.id
          if (id === transporter.transporterId) {
            let invoiceCopy = { ...selectedInvoiceObject };
            invoiceCopy.formData = {
              ...selectedInvoiceObject?.formData,
              transportationDetails: {
                ...selectedInvoiceObject?.formData?.transportationDetails,
                transporterName: {
                  ...selectedInvoiceObject?.formData
                    ?.transportationDetails.transporterName,
                  id: '',
                  value: ''
                }
              }
            };
            updateInvoiceObjectInArray(invoiceCopy);
          }

          setSelectedTransporter(null);
          dispatch(fetchTransporters());
        },
        (err) => {
          console.error('Error saving transporter: ', err);
        }
      );
    }
    
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row">
          <DKLabel
            text={`${
              props.data
                ? `E-Way Bill - ${props.data.salesInvoiceCode}`
                : `New E-Way Bill`
            }`}
            className="fw-m fs-l"
          />
          {isReadOnly && (
            <div className=" fs-r text-align-left text-dark-gray bg-chip-orange text-orange border-radius-r ml-r p-h-s p-v-xs fw-m">
              Read-Only
            </div>
          )}
        </div>
        {invoicesArray.length > 1 && (
          <div className="row justify-content-center">
            <DKButton
              title={''}
              icon={DKIcons.ic_arrow_left2}
              className={'bg-white border-m mr-r'}
              onClick={() => {
                selectedIndex !== 0 && setSelectedIndex(selectedIndex - 1);
              }}
            />
            <DKLabel
              text={`${selectedIndex + 1} of ${invoicesArray?.length}`}
            />
            <DKButton
              title={''}
              icon={DKIcons.ic_arrow_right2}
              className={'bg-white border-m ml-r'}
              onClick={() => {
                selectedIndex < invoicesArray?.length - 1 &&
                  setSelectedIndex(selectedIndex + 1);
              }}
            />
          </div>
        )}
        <div className="row justify-content-end">
          <DKButton
            title={isReadOnly ? 'Close' : 'Cancel'}
            className={
              isLoading ? 'bg-gray1 border-m mr-r' : 'bg-white border-m mr-r'
            }
            onClick={() => {
              if (!isLoading) {
                props.onCancel();
              }
            }}
          />
          {!isReadOnly && (
            <DKButton
              title={isLoading ? 'Save...' : 'Save'}
              className={
                isLoading
                  ? 'bg-gray1 text-black border-m'
                  : 'bg-button text-white'
              }
              onClick={() => {
                if (!isLoading) {
                  generateEWayBill();
                } else {
                  saveEwayBill();
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  function convertDateDashToSlash(stringdate: string) {
    stringdate = stringdate.replace(/-/g, '/');
    return stringdate;
  }

  const makePayload = () => {
    let Payload: any = {};
    Payload = {
      ewayBillNo: props.data ? props.data.ewayBillNo : '',
      salesInvoiceItems: Utility.isEmpty(props.data)
        ? selectedInvoice?.salesInvoiceItems
        : selectedInvoice?.salesInvoice.salesInvoiceItems,
      totalTaxableAmount: 1500,
      totalCessAmount: 0,
      totalIgstAmount: 0,
      totalSgstAmount: 0,
      totalCgstAmount: 0,
      transportationMode:
        selectedInvoiceObject?.formData?.modeOfTransportation
          .transportationMode,
      ewayBillStatus: NOT_GENERATED,
      ewayDocumentType:
        selectedInvoiceObject?.formData?.transactionDetails.ewayDocumentType
          .value,
      transactionSubType:
        selectedInvoiceObject?.formData?.transactionDetails.transactionSubType
          .value,
      transactionType:
        selectedInvoiceObject?.formData?.transactionDetails.transactionType
          .value,
      shipByDate: convertDateDashToSlash(
        selectedInvoiceObject?.formData?.transactionDetails.shipByDate
      ),
      transporterName:
        selectedInvoiceObject?.formData?.transportationDetails.transporterName
          .id,
      distanceInKm:
        selectedInvoiceObject?.formData?.transportationDetails.distanceInKm.toString(),
      customerName:
        selectedInvoiceObject?.formData?.transactionDetails.customerName.id,
      salesInvoiceCode:
        selectedInvoiceObject?.formData?.transactionDetails.salesInvoiceCode
          .value,
      totalAmount: selectedInvoice?.totalAmount,
      shipFrom: selectedInvoice?.shipFrom,
      billFrom: selectedInvoice?.shipFrom,
      shipTo: selectedInvoice?.shipTo,
      billTo: selectedInvoice?.billTo
    };

    if (
      selectedInvoiceObject?.formData?.modeOfTransportation
        .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
    ) {
      Payload.vehicleType =
        selectedInvoiceObject?.formData?.modeOfTransportation.road.vehicleType;
      Payload.vehicleNo =
        selectedInvoiceObject?.formData?.modeOfTransportation.road.vehicleNo;
      Payload.transporterDocNo =
        selectedInvoiceObject?.formData?.modeOfTransportation.road.transporterDocNo;
      Payload.transporterDocDate = selectedInvoiceObject?.formData
        .modeOfTransportation.road.transporterDocDate
        ? format(
            new Date(
              selectedInvoiceObject?.formData?.modeOfTransportation.road.transporterDocDate
            ),
            DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
          )
        : '';
    } else if (
      selectedInvoiceObject?.formData?.modeOfTransportation
        .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL ||
      selectedInvoiceObject?.formData?.modeOfTransportation
        .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
    ) {
      Payload.billOfLadingNo =
        selectedInvoiceObject?.formData?.modeOfTransportation.rail.billOfLadingNo;
      Payload.billOfLadingDate = selectedInvoiceObject?.formData
        ?.modeOfTransportation.rail.billOfLadingDate
        ? format(
            new Date(
              selectedInvoiceObject?.formData?.modeOfTransportation.rail.billOfLadingDate
            ),
            DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
          )
        : '';
    } else if (
      selectedInvoiceObject?.formData?.modeOfTransportation
        .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
    ) {
      Payload.airwayBillDate = selectedInvoiceObject?.formData
        ?.modeOfTransportation.air.airwayBillDate
        ? format(
            new Date(
              selectedInvoiceObject?.formData?.modeOfTransportation.air.airwayBillDate
            ),
            DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
          )
        : '';
      Payload.airwayBillNo =
        selectedInvoiceObject?.formData?.modeOfTransportation.air.airwayBillNo;
    }
    return Payload;
  };

  const getEWBDetailsDtoList = () => {
    const invoices =
      invoicesArray.length > 0 ? invoicesArray : [selectedInvoiceObject];
    let mapped = invoices.map((item: any) => {
      let innerObject: any = {
        id: item.id,
        distanceInKm:
          item?.formData?.transportationDetails.distanceInKm.toString(),
        ewayBillStatus: NOT_GENERATED,
        ewayDocumentType:
          item?.formData?.transactionDetails.ewayDocumentType.value,
        shipFrom: item?.addressDetail?.dispatchedFrom,
        billFrom: item?.addressDetail?.billFrom,
        shipTo: item?.addressDetail?.dispatchedTo,
        billTo: item?.addressDetail?.billTo,
        transactionSubType:
          item?.formData?.transactionDetails.transactionSubType.value,
        transactionSubTypeDescForOthers: '',
        transactionType:
          item?.formData?.transactionDetails.transactionType.value,
        transportationMode:
          item?.formData?.modeOfTransportation.transportationMode,
        transporterName:
          item?.formData?.transportationDetails.transporterName.value,
        transporterId:
        item?.formData?.transportationDetails.transporterName.id
      };
      if (
        selectedInvoiceObject?.formData?.modeOfTransportation
          .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
      ) {
        innerObject.vehicleType =
          selectedInvoiceObject?.formData?.modeOfTransportation.road.vehicleType;
        innerObject.vehicleNo =
          selectedInvoiceObject?.formData?.modeOfTransportation.road.vehicleNo;
        innerObject.transporterDocNo =
          selectedInvoiceObject?.formData?.modeOfTransportation.road.transporterDocNo;
        innerObject.transporterDocDate = selectedInvoiceObject?.formData
          .modeOfTransportation.road.transporterDocDate
          ? format(
              new Date(
                selectedInvoiceObject?.formData?.modeOfTransportation.road.transporterDocDate
              ),
              DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
            )
          : '';
      } else if (
        selectedInvoiceObject?.formData?.modeOfTransportation
          .transportationMode ===
          TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL ||
        selectedInvoiceObject?.formData?.modeOfTransportation
          .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
      ) {
        innerObject.billOfLadingNo =
          selectedInvoiceObject?.formData?.modeOfTransportation.rail.billOfLadingNo;
        innerObject.billOfLadingDate = selectedInvoiceObject?.formData
          ?.modeOfTransportation.rail.billOfLadingDate
          ? format(
              new Date(
                selectedInvoiceObject?.formData?.modeOfTransportation.rail.billOfLadingDate
              ),
              DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
            )
          : '';
      } else if (
        selectedInvoiceObject?.formData?.modeOfTransportation
          .transportationMode === TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
      ) {
        innerObject.airwayBillDate = selectedInvoiceObject?.formData
          ?.modeOfTransportation.air.airwayBillDate
          ? format(
              new Date(
                selectedInvoiceObject?.formData?.modeOfTransportation.air.airwayBillDate
              ),
              DATE_CONSTANTS.EWAY_SERVER_FROMAT_TO_SAVE
            )
          : '';
        innerObject.airwayBillNo =
          selectedInvoiceObject?.formData?.modeOfTransportation.air.airwayBillNo;
      }
      return innerObject;
    });
    return mapped;
  };

  const makePayloadForGenerate = () => {
    let Payload: any = {};
    Payload = {
      gstin: tenantInfo?.gstin,
      sek: eWayAuthInfoData.sek,
      session_id: eWayAuthInfoData.session_id,
      appKey: eWayAuthInfoData.appKey,
      asp_secret_key: eWayAuthInfoData.asp_secret_key,
      authtoken: eWayAuthInfoData.authtoken,
      ewbDetailsDtoList: getEWBDetailsDtoList()
    };
    return Payload;
  };

  const validAddressDetails = (item: any) => {
    let shipFromEmpty: boolean = false;
    let shipToEmpty = false;
    let billTo = false;

    // Check Dispatched From
    if (
      Utility.isEmpty(item?.shipFrom?.state) &&
      Utility.isEmpty(item?.shipFrom?.address1) &&
      Utility.isEmpty(item?.shipFrom?.address2) &&
      Utility.isEmpty(item?.shipFrom?.country) &&
      Utility.isEmpty(item?.shipFrom?.postalCode)
    ) {
      shipFromEmpty = true;
    }

    // Check Dispatched To
    if (
      Utility.isEmpty(item?.shipTo?.state) &&
      Utility.isEmpty(item?.shipTo?.address1) &&
      Utility.isEmpty(item?.shipTo?.address2) &&
      Utility.isEmpty(item?.shipTo?.country) &&
      Utility.isEmpty(item?.shipTo?.postalCode)
    ) {
      shipToEmpty = true;
    }

    // Check Bill TO
    if (
      Utility.isEmpty(item?.billTo?.state) &&
      Utility.isEmpty(item?.billTo?.address1) &&
      Utility.isEmpty(item?.billTo?.address2) &&
      Utility.isEmpty(item?.billTo?.country) &&
      Utility.isEmpty(item?.billTo?.postalCode)
    ) {
      billTo = true;
    }

    if (shipFromEmpty || shipToEmpty || billTo) {
      return false;
    } else {
      return true;
    }
  };

  const validateEwayBillForm = () => {
    let result = true;
    for (let index = 0; index < invoicesArray.length; index++) {
      let item = invoicesArray[index];
      if (
        Utility.isEmpty(
          item?.formData?.transactionDetails.ewayDocumentType.value
        )
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        Utility.isEmpty(
          item?.formData?.transactionDetails.transactionType.value
        )
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        Utility.isEmpty(
          item?.formData?.transactionDetails.transactionSubType.value
        )
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        Utility.isEmpty(item?.formData?.transactionDetails.customerName.value)
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        Utility.isEmpty(
          item?.formData?.transactionDetails.salesInvoiceCode.value
        )
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        Utility.isEmpty(item?.formData?.transactionDetails.shipByDate)
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (!validAddressDetails(item)) {
        setSelectedIndex(index);
        result = false;
        break;
      }
      // else if (
      //   Utility.isEmpty(
      //     item?.formData?.transportationDetails.transporterName.value
      //   )
      // ) {
      //   setSelectedIndex(index);
      //   result = false;
      //   break;
      // }
      //  else if (
      //   Utility.isEmpty(
      //     item?.formData?.transportationDetails.distanceInKm.toString()
      //   )
      // ) {
      //   setSelectedIndex(index);
      //   result = false;
      //   break;
      // }
      else if (
        Utility.isEmpty(item?.formData?.modeOfTransportation.transportationMode)
      ) {
        setSelectedIndex(index);
        result = false;
        break;
      } else if (
        !Utility.isEmpty(
          item?.formData?.modeOfTransportation.transportationMode
        ) &&
        item?.formData?.modeOfTransportation.transportationMode ===
          TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
      ) {
        if (
          Utility.isEmpty(item?.formData?.modeOfTransportation.road.vehicleType)
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        }
        //  else if (
        //   Utility.isEmpty(item?.formData?.modeOfTransportation.road.vehicleNo)
        // ) {
        //   setSelectedIndex(index);
        //   result = false;
        //   break;
        // }
        // }
      } else if (
        !Utility.isEmpty(
          item?.formData?.modeOfTransportation.transportationMode
        ) &&
        item?.formData?.modeOfTransportation.transportationMode ===
          TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
      ) {
        if (
          Utility.isEmpty(
            item?.formData?.modeOfTransportation.rail.billOfLadingNo
          )
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        } else if (
          Utility.isEmpty(
            item?.formData?.modeOfTransportation.rail.billOfLadingDate.toString()
          )
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        }
      } else if (
        !Utility.isEmpty(
          item?.formData?.modeOfTransportation.transportationMode
        ) &&
        item?.formData?.modeOfTransportation.transportationMode ===
          TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
      ) {
        if (
          Utility.isEmpty(item?.formData?.modeOfTransportation.air.airwayBillNo)
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        } else if (
          Utility.isEmpty(
            item?.formData?.modeOfTransportation.air.airwayBillDate.toString()
          )
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        }
      } else if (
        !Utility.isEmpty(
          item?.formData?.modeOfTransportation.transportationMode
        ) &&
        item?.formData?.modeOfTransportation.transportationMode ===
          TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
      ) {
        if (
          Utility.isEmpty(
            item?.formData?.modeOfTransportation.ship.shipBillOfLadingNo
          )
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        } else if (
          Utility.isEmpty(
            item?.formData?.modeOfTransportation.ship.shipBillOfLadingDate.toString()
          )
        ) {
          setSelectedIndex(index);
          result = false;
          break;
        }
      } else {
        setSelectedIndex(index);
        result = false;
        break;
      }
    }
    return result;
  };

  const checkAddressIsEmpty = (address: any) => {
    if (
      Utility.isEmpty(address) ||
      '' === address?.state ||
      '' === address?.city ||
      '' === address.country
    ) {
      return true;
    }
    return false;
  };

  const validatePayload = (payload: any) => {
    if (Utility.isEmpty(payload?.ewbDetailsDtoList)) {
      showAlert(
        'Data Field Error',
        'Please Fill the Correct Information'
      );
      return false;
    } else {
      const payloadData = payload?.ewbDetailsDtoList[0];
      let transactionType = '';
      const vehicleNumber = payloadData?.vehicleNo;

      if (
        checkAddressIsEmpty(payloadData.shipFrom) &&
        checkAddressIsEmpty(payloadData.shipTo)
      ) {
        transactionType = 'REGULAR';
      } else if (
        !checkAddressIsEmpty(payloadData.shipFrom) &&
        checkAddressIsEmpty(payloadData.shipTo)
      ) {
        transactionType = 'BILL_FROM_DISPATCH_FROM';
      } else if (
        checkAddressIsEmpty(payloadData.shipFrom) &&
        !checkAddressIsEmpty(payloadData.shipTo)
      ) {
        transactionType = 'BILL_TO_SHIP_TO';
      } else if (
        !checkAddressIsEmpty(payloadData.shipFrom) &&
        !checkAddressIsEmpty(payloadData.shipTo)
      ) {
        transactionType = 'COMBINATION_OF_2_AND_3';
      }

      if (transactionType !== payloadData?.transactionType) {
        showAlert(
          'Invalid Transaction Type',
          `Transaction type is not according to Buyer and Seller address details. According to your Address details, Transaction Type should be <b>${transactionType.replaceAll(
            '_',
            ' '
          )}</b>`
        );
        return false;
      }

      if (vehicleNumber.length > 20) {
        showAlert(
          'Invalid Vehicle Number',
          'Vehicle Number length must be less than 20'
        );
        return false;
      }
    }
    return true;
  };

  const generateEWayBill = () => {
    setCanValidate(true);
    if (validateEwayBillForm()) {
      let payload: any = makePayloadForGenerate();
      if (validatePayload(payload)) {
        setIsLoading(true);
        EwayBillService.generateEwayBillBulk(payload).then(
          (res: any) => {
            setIsLoading(false);
            setEWayResponseObject(res);
            setShowResponsePopUp(true);
            // if (res.status === '0') {
            //   let errorCodes = res?.error?.message;
            //   if (
            //     errorCodes.includes('105') ||
            //     errorCodes.includes('106') ||
            //     errorCodes.includes('238')
            //   ) {
            //     showAlert('Failed!', 'Please login to continue.');
            //     setShowEWayBillLoginPortal(true);
            //   } else {
            //     showAlert(
            //       'Failed!',
            //       'Failed to generate the EWay Bill with Errors - ' +
            //         res?.error?.message
            //     );
            //   }
            // } else {
            //   showAlert('Success!', 'E-way Bill Generated Successfully');
            //   props.onSuccess();
            // }
          },
          (err) => {
            setIsLoading(false);
            // showSubscriptionAlert
            console.error('Error saving eway bill: ', err);
          }
        );
      }
    }
  };
  const saveEwayBill = () => {
    if (validateEwayBillForm()) {
      if (Utility.isEmpty(props.data)) {
        EwayBillService.saveEwayBill(makePayload()).then(
          (res: any) => {
            showToast('E-way Bill Created Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
            props.onCancel();
          },
          (err) => {
            console.error('Error saving eway bill: ', err);
          }
        );
      } else if (!Utility.isEmpty(props.data)) {
        EwayBillService.updateEwayBill(makePayload()).then(
          (res: any) => {
            showToast('E-way Bill Updated Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err) => {
            console.error('Error updating eway bill: ', err);
          }
        );
      }
    }
  };

  const getInvoiceSeqCodeFromInvoiceCode = (code: any) => {
    // props.data.salesInvoiceCode
    let filter: any = invoiceData.filter(
      (item: any) => item.salesInvoiceCode === code
    );
    if (!Utility.isEmpty(filter)) {
      return filter[0].documentSequenceCode;
    }
    return code;
  };

  const transactionDetails = () => {
    const contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    return (
      <div className="column parent-width mt-3 p-h-r p-v-s">
        <div className="row width-auto p-h-s">
          <DKIcon src={DKIcons.ic_cards} />
          <DKLabel text="Transaction Details" className="font-bold ml-r" />
        </div>
        <div className="row parent-width mt-4">
          <div className="w-1/3 p-h-s">
            {props.data && (
              <DKInput
                canValidate={canValidate}
                readOnly={true}
                title={'Document Type'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={
                  selectedInvoiceObject?.formData?.transactionDetails
                    .ewayDocumentType.value
                }
                required={true}
              />
            )}
            {!props.data && (
              <DKInput
                canValidate={canValidate}
                title={'Document Type'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.SELECT}
                options={
                  documentTypesStore
                    ? documentTypesStore.map((item: any) => item.name)
                    : []
                }
                value={[
                  selectedInvoiceObject?.formData?.transactionDetails
                    .ewayDocumentType.id
                ]}
                onChange={(value: any) => {
                  if (value.length > 0) {
                    let invoiceCopy = { ...selectedInvoiceObject };
                    invoiceCopy.formData = {
                      ...selectedInvoiceObject?.formData,
                      transactionDetails: {
                        ...selectedInvoiceObject?.formData?.transactionDetails,
                        ewayDocumentType: {
                          ...selectedInvoiceObject?.formData?.transactionDetails
                            .ewayDocumentType,
                          id: value[0],
                          value: documentTypesStore[value].id
                        }
                      }
                    };
                    updateInvoiceObjectInArray(invoiceCopy);
                    // setFormData({
                    //   ...formData,
                    //   transactionDetails: {
                    //     ...formData.transactionDetails,
                    //     ewayDocumentType: {
                    //       ...formData.transactionDetails.ewayDocumentType,
                    //       id: value[0],
                    //       value: documentTypesStore[value].id
                    //     }
                    //   }
                    // });
                  } else {
                    let invoiceCopy = { ...selectedInvoiceObject };
                    invoiceCopy.formData = {
                      ...selectedInvoiceObject?.formData,
                      transactionDetails: {
                        ...selectedInvoiceObject?.formData?.transactionDetails,
                        ewayDocumentType: {
                          ...selectedInvoiceObject?.formData?.transactionDetails
                            .ewayDocumentType,
                          id: '',
                          value: ''
                        }
                      }
                    };
                    updateInvoiceObjectInArray(invoiceCopy);

                    // setFormData({
                    //   ...formData,
                    //   transactionDetails: {
                    //     ...formData.transactionDetails,
                    //     ewayDocumentType: {
                    //       ...formData.transactionDetails.ewayDocumentType,
                    //       id: '',
                    //       value: ''
                    //     }
                    //   }
                    // });
                  }
                }}
              />
            )}
          </div>
          <div className="w-1/3 p-h-s">
            <DKInput
              canValidate={canValidate}
              title={'Transaction Type'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.SELECT}
              options={
                transactionTypesStore
                  ? transactionTypesStore.map((item: any) => item.name)
                  : []
              }
              value={[
                selectedInvoiceObject?.formData?.transactionDetails
                  .transactionType.id
              ]}
              onChange={(value: any) => {
                if (value.length > 0) {
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transactionDetails: {
                      ...selectedInvoiceObject?.formData?.transactionDetails,
                      transactionType: {
                        ...selectedInvoiceObject?.formData?.transactionDetails
                          .transactionType,
                        id: value[0],
                        value: transactionTypesStore[value].id
                      }
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...formData,
                  //   transactionDetails: {
                  //     ...formData.transactionDetails,
                  //     transactionType: {
                  //       ...formData.transactionDetails.transactionType,
                  //       id: value[0],
                  //       value: transactionTypesStore[value].id
                  //     }
                  //   }
                  // });
                } else {
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transactionDetails: {
                      ...selectedInvoiceObject?.formData?.transactionDetails,
                      transactionType: {
                        ...selectedInvoiceObject?.formData?.transactionDetails
                          .transactionType,
                        id: '',
                        value: ''
                      }
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...formData,
                  //   transactionDetails: {
                  //     ...formData.transactionDetails,
                  //     transactionType: {
                  //       ...formData.transactionDetails.transactionType,
                  //       id: '',
                  //       value: ''
                  //     }
                  //   }
                  // });
                }
              }}
            />
          </div>
          <div className="w-1/3 p-h-s">
            <DKInput
              canValidate={canValidate}
              title={'Transaction Sub Type'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.SELECT}
              options={
                transactionSubTypesStore
                  ? transactionSubTypesStore.map((item: any) => item.name)
                  : []
              }
              value={[
                selectedInvoiceObject?.formData?.transactionDetails
                  .transactionSubType.id
              ]}
              onChange={(value: any) => {
                if (value.length > 0) {
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transactionDetails: {
                      ...selectedInvoiceObject?.formData?.transactionDetails,
                      transactionSubType: {
                        ...selectedInvoiceObject?.formData?.transactionDetails
                          .transactionSubType,
                        id: value[0],
                        value: transactionSubTypesStore[value].id
                      }
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...formData,
                  //   transactionDetails: {
                  //     ...formData.transactionDetails,
                  //     transactionSubType: {
                  //       ...formData.transactionDetails.transactionSubType,
                  //       id: value[0],
                  //       value: transactionSubTypesStore[value].id
                  //     }
                  //   }
                  // });
                } else {
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transactionDetails: {
                      ...selectedInvoiceObject?.formData?.transactionDetails,
                      transactionSubType: {
                        ...selectedInvoiceObject?.formData?.transactionDetails
                          .transactionSubType,
                        id: '',
                        value: ''
                      }
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...formData,
                  //   transactionDetails: {
                  //     ...formData.transactionDetails,
                  //     transactionSubType: {
                  //       ...formData.transactionDetails.transactionSubType,
                  //       id: '',
                  //       value: ''
                  //     }
                  //   }
                  // });
                }
              }}
            />
          </div>
        </div>
        <div className="row parent-width mt-4">
          <div className="w-1/3 p-h-s">
            {(props.data || selectedInvoiceObject) && (
              <DKInput
                canValidate={canValidate}
                readOnly={true}
                title={'Customer Name'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={
                  props.data
                    ? props.data.customerName
                    : selectedInvoiceObject.contact.name
                }
                required={true}
              />
            )}
            {!props.data && !selectedInvoiceObject && (
              <DKInput
                canValidate={canValidate}
                title={'Customer Name'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.DROPDOWN}
                value={
                  selectedInvoiceObject?.formData?.transactionDetails
                    .customerName.value
                }
                required={true}
                onChange={(value: any) => {}}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: true,
                  searchableKey: 'name',
                  data: contactList,
                  renderer: (index: number, contactObj: any) => {
                    return (
                      <div className="row listPickerBG cursor-hand p-h-r justify-content-between list-row">
                        <DKLabel text={`${contactObj.name}`} />
                      </div>
                    );
                  },
                  searchApiConfig: {
                    getUrl: (searchValue: string) => {
                      const config: ContactAPIConfig = {
                        ...ContactService.apiConfig,
                        Page: 0,
                        SearchTerm: searchValue,
                        Limit: 20,
                        IncludeOpeningAmounts: false,
                        IncludeOweAmounts: false,
                        Query: 'status=active'
                      };

                      ContactService.apiConfig = config;

                      return ContactService.getContactsApiUrl();
                    },
                    dataParser: (response: any) => {
                      return response?.content || [];
                    },
                    debounceTime: 300
                  },
                  onSelect: (index: number, value: any) => {
                    setIsLoadingInvoiceData(true);
                    fetchInvoiceData(tenantInfo.country, value.code);
                    setSelectedInvoice('');
                    let invoiceCopy = { ...selectedInvoiceObject };
                    invoiceCopy.formData = {
                      ...selectedInvoiceObject?.formData,
                      transactionDetails: {
                        ...selectedInvoiceObject?.formData?.transactionDetails,
                        customerName: {
                          ...selectedInvoiceObject?.formData?.transactionDetails
                            .customerName,
                          id: value.code,
                          value: value.name
                        },
                        salesInvoiceCode: {
                          ...selectedInvoiceObject?.formData?.transactionDetails
                            .salesInvoiceCode,
                          id: '',
                          value: ''
                        },
                        shipByDate: ''
                      }
                    };
                    updateInvoiceObjectInArray(invoiceCopy);
                    // setFormData({
                    //   ...formData,
                    //   transactionDetails: {
                    //     ...formData.transactionDetails,
                    //     customerName: {
                    //       ...formData.transactionDetails.customerName,
                    //       id: value.code,
                    //       value: value.name
                    //     },
                    //     salesInvoiceCode: {
                    //       ...formData.transactionDetails.salesInvoiceCode,
                    //       id: '',
                    //       value: ''
                    //     },
                    //     shipByDate: ''
                    //   }
                    // });
                  }
                }}
              />
            )}
          </div>
          <div className="w-1/3 p-h-s">
            {(props.data || selectedInvoiceObject) && (
              <DKInput
                canValidate={canValidate}
                readOnly={true}
                title={'Invoice'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={
                  props.data
                    ? getInvoiceSeqCodeFromInvoiceCode(
                        props.data.salesInvoiceCode
                      )
                    : selectedInvoiceObject.documentSequenceCode
                }
                required={true}
              />
            )}
            {!props.data && !selectedInvoiceObject && (
              <DKInput
                canValidate={canValidate}
                readOnly={isLoadingInvoiceData}
                title={'Invoice'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.SELECT}
                options={
                  invoiceData
                    ? invoiceData.map((item: any) => item.documentSequenceCode)
                    : []
                }
                value={getInvoiceSeqCodeFromInvoiceCode(
                  selectedInvoiceObject?.formData?.transactionDetails
                    .salesInvoiceCode.id
                )}
                onChange={(value: any) => {
                  let data: any = invoiceData[value];
                  setSelectedInvoice(invoiceData[value]);
                  setProductDocumentCurrency(data.currency);
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transactionDetails: {
                      ...selectedInvoiceObject?.formData?.transactionDetails,
                      salesInvoiceCode: {
                        ...selectedInvoiceObject?.formData?.transactionDetails
                          .salesInvoiceCode,
                        id: value,
                        value: data.salesInvoiceCode
                      },
                      shipByDate: !Utility.isEmpty(data.shipByDate)
                        ? data.shipByDate
                        : ''
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...selectedInvoiceObject?.formData,
                  //   transactionDetails: {
                  //     ...selectedInvoiceObject?.formData?.transactionDetails,
                  //     salesInvoiceCode: {
                  //       ...selectedInvoiceObject?.formData?.transactionDetails
                  //         .salesInvoiceCode,
                  //       id: value,
                  //       value: data.salesInvoiceCode
                  //     },
                  //     shipByDate: !Utility.isEmpty(data.shipByDate)
                  //       ? data.shipByDate
                  //       : ''
                  //   }
                  // });
                }}
              />
            )}
          </div>
          <div className="w-1/3 p-h-s">
            {(props.data || selectedInvoice) && (
              <DKInput
                canValidate={canValidate}
                readOnly={true}
                title={'Ship By Date'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={
                  props.data
                    ? props.data.shipByDate
                    : selectedInvoice?.shipByDate
                }
                required={true}
              />
            )}
            {!props.data && !selectedInvoice && (
              <DKInput
                canValidate={canValidate}
                readOnly={true}
                title={'Ship By Date'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                value={
                  selectedInvoiceObject?.formData?.transactionDetails.shipByDate
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const modeOfTransportation = () => {
    return (
      <>
        <div className="column parent-width mt-3 p-h-r p-v-s">
          <div className="row width-auto p-h-s">
            <DKIcon src={DKIcons.ic_cards} />
            <DKLabel text="Mode Of Transportation" className="font-bold ml-r" />
          </div>
          <div className="parent-width p-h-s mt-4 flex">
            <div
              style={{ width: '85px' }}
              className={`cursor-pointer px-2 py-1 mr-3 flex items-center ${
                selectedInvoiceObject?.formData?.modeOfTransportation
                  .transportationMode ===
                TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
                  ? `bg-gray-200 rounded`
                  : ``
              } `}
              onClick={() => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    transportationMode:
                      TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     transportationMode:
                //       TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD
                //   }
                // });
              }}
            >
              <DKIcon src={DKIcons.ic_cards} />
              <DKLabel text="Road" className="font-bold ml-r" />
            </div>
            <div
              style={{ width: '85px' }}
              className={`cursor-pointer px-2 py-1 mr-3 flex items-center ${
                selectedInvoiceObject?.formData?.modeOfTransportation
                  .transportationMode ===
                TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
                  ? `bg-gray-200 rounded`
                  : ``
              } `}
              onClick={() => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    transportationMode:
                      TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     transportationMode:
                //       TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL
                //   }
                // });
              }}
            >
              <DKIcon src={DKIcons.ic_cards} />
              <DKLabel text="Rail" className="font-bold ml-r" />
            </div>
            <div
              style={{ width: '85px' }}
              className={`cursor-pointer px-2 py-1 mr-3 flex items-center ${
                selectedInvoiceObject?.formData?.modeOfTransportation
                  .transportationMode ===
                TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
                  ? `bg-gray-200 rounded`
                  : ``
              } `}
              onClick={() => {
                let invoiceCopy = { ...selectedInvoice };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    transportationMode:
                      TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     transportationMode:
                //       TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR
                //   }
                // });
              }}
            >
              <DKIcon src={DKIcons.ic_cards} />
              <DKLabel text="Air" className="font-bold ml-r" />
            </div>
            <div
              style={{ width: '75px' }}
              className={`cursor-pointer px-2 py-1 mr-3 flex items-center ${
                selectedInvoiceObject?.formData?.modeOfTransportation
                  .transportationMode ===
                TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
                  ? `bg-gray-200 rounded`
                  : ``
              } `}
              onClick={() => {
                let invoiceCopy = { ...selectedInvoice };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    transportationMode:
                      TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     transportationMode:
                //       TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP
                //   }
                // });
              }}
            >
              <DKIcon src={DKIcons.ic_cards} />
              <DKLabel text="Ship" className="font-bold ml-r" />
            </div>
          </div>
          {selectedInvoiceObject?.formData?.modeOfTransportation
            .transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD &&
            roadTransportation()}
          {selectedInvoiceObject?.formData?.modeOfTransportation
            .transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_RAIL &&
            railTransportation()}
          {selectedInvoiceObject?.formData?.modeOfTransportation
            .transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_AIR && airTransportation()}
          {selectedInvoiceObject?.formData?.modeOfTransportation
            .transportationMode ===
            TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_SHIP &&
            shipTransportation()}
        </div>
      </>
    );
  };

  const shipTransportation = () => {
    return (
      <div className="row parent-width mt-4">
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Bill of Lading No'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.TEXT}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.ship
                .shipBillOfLadingNo
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoice };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  ship: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .ship,
                    shipBillOfLadingNo: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     ship: {
              //       ...formData.modeOfTransportation.ship,
              //       shipBillOfLadingNo: value
              //     }
              //   }
              // });
            }}
          />
        </div>
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Bill of Lading Date'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DATE}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.ship
                .shipBillOfLadingDate
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoiceObject };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  ship: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .ship,
                    shipBillOfLadingDate: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     ship: {
              //       ...formData.modeOfTransportation.ship,
              //       shipBillOfLadingDate: value
              //     }
              //   }
              // });
            }}
          />
        </div>
      </div>
    );
  };

  const airTransportation = () => {
    return (
      <div className="row parent-width mt-4">
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Airway Bill'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.TEXT}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.air
                .airwayBillNo
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoiceObject };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  air: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .air,
                    airwayBillNo: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     air: {
              //       ...formData.modeOfTransportation.air,
              //       airwayBillNo: value
              //     }
              //   }
              // });
            }}
          />
        </div>
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Airway Bill Date'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DATE}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.air
                .airwayBillDate
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoiceObject };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  air: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .air,
                    airwayBillDate: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     air: {
              //       ...formData.modeOfTransportation.air,
              //       airwayBillDate: value
              //     }
              //   }
              // });
            }}
          />
        </div>
      </div>
    );
  };

  const railTransportation = () => {
    return (
      <div className="row parent-width mt-4">
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Bill of Lading No'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.TEXT}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.rail
                .billOfLadingNo
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoiceObject };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  rail: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .rail,
                    billOfLadingNo: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     rail: {
              //       ...formData.modeOfTransportation.rail,
              //       billOfLadingNo: value
              //     }
              //   }
              // });
            }}
          />
        </div>
        <div className="w-1/3 p-h-s">
          <DKInput
            canValidate={canValidate}
            title={'Bill of Lading Date'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DATE}
            value={
              selectedInvoiceObject?.formData?.modeOfTransportation.rail
                .billOfLadingDate
            }
            onChange={(value: any) => {
              let invoiceCopy = { ...selectedInvoiceObject };
              invoiceCopy.formData = {
                ...selectedInvoiceObject?.formData,
                modeOfTransportation: {
                  ...selectedInvoiceObject?.formData?.modeOfTransportation,
                  rail: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation
                      .rail,
                    billOfLadingDate: value
                  }
                }
              };
              updateInvoiceObjectInArray(invoiceCopy);
              // setFormData({
              //   ...formData,
              //   modeOfTransportation: {
              //     ...formData.modeOfTransportation,
              //     rail: {
              //       ...formData.modeOfTransportation.rail,
              //       billOfLadingDate: value
              //     }
              //   }
              // });
            }}
          />
        </div>
      </div>
    );
  };

  const roadTransportation = () => {
    return (
      <>
        <div className="row parent-width mt-4">
          <div className="p-h-s flex">
            <DKRadioButton
              color="bg-app"
              title="Regular"
              isSelected={
                selectedInvoiceObject?.formData?.modeOfTransportation.road
                  .vehicleType === VEHICLE_TYPE.VEHICLE_TYPE_REGULAR
              }
              onClick={() => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    road: {
                      ...selectedInvoiceObject?.formData?.modeOfTransportation
                        .road,
                      vehicleType: VEHICLE_TYPE.VEHICLE_TYPE_REGULAR
                    }
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     road: {
                //       ...formData.modeOfTransportation.road,
                //       vehicleType: VEHICLE_TYPE.VEHICLE_TYPE_REGULAR
                //     }
                //   }
                // });
              }}
              className="mb-s mr-3"
            />
            <DKRadioButton
              color="bg-app"
              title="Over Dimensional Cargo"
              isSelected={
                selectedInvoiceObject?.formData?.modeOfTransportation.road
                  .vehicleType ===
                VEHICLE_TYPE.VEHICLE_TYPE_OVER_DIMENSIONAL_CARGO
              }
              onClick={() => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    road: {
                      ...selectedInvoiceObject?.formData?.modeOfTransportation
                        .road,
                      vehicleType:
                        VEHICLE_TYPE.VEHICLE_TYPE_OVER_DIMENSIONAL_CARGO
                    }
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     road: {
                //       ...formData.modeOfTransportation.road,
                //       vehicleType:
                //         VEHICLE_TYPE.VEHICLE_TYPE_OVER_DIMENSIONAL_CARGO
                //     }
                //   }
                // });
              }}
              className="mb-s"
            />
          </div>
        </div>
        <div className="row parent-width mt-4">
          <div className="w-1/3 p-h-s">
            <DKInput
              canValidate={canValidate}
              title={'Vehicle No'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={
                selectedInvoiceObject?.formData?.modeOfTransportation.road
                  .vehicleNo
              }
              required={false}
              onChange={(value: any) => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    road: {
                      ...selectedInvoiceObject?.formData?.modeOfTransportation
                        .road,
                      vehicleNo: value
                    }
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     road: {
                //       ...formData.modeOfTransportation.road,
                //       vehicleNo: value
                //     }
                //   }
                // });
              }}
            />
          </div>
          <div className="w-1/3 p-h-s">
            <DKInput
              required={false}
              canValidate={canValidate}
              title={'Transported Doc No'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={
                selectedInvoiceObject?.formData?.modeOfTransportation.road
                  .transporterDocNo
              }
              onChange={(value: any) => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    road: {
                      ...selectedInvoiceObject?.formData?.modeOfTransportation
                        .road,
                      transporterDocNo: value
                    }
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     road: {
                //       ...formData.modeOfTransportation.road,
                //       transporterDocNo: value
                //     }
                //   }
                // });
              }}
            />
          </div>
          <div className="w-1/3 p-h-s">
            <DKInput
              required={false}
              canValidate={canValidate}
              title={'Transported Doc Date'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DATE}
              value={
                selectedInvoiceObject?.formData?.modeOfTransportation.road
                  .transporterDocDate
              }
              onChange={(value: any) => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  modeOfTransportation: {
                    ...selectedInvoiceObject?.formData?.modeOfTransportation,
                    road: {
                      ...selectedInvoiceObject?.formData?.modeOfTransportation
                        .road,
                      transporterDocDate: value
                    }
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   modeOfTransportation: {
                //     ...formData.modeOfTransportation,
                //     road: {
                //       ...formData.modeOfTransportation.road,
                //       transporterDocDate: value
                //     }
                //   }
                // });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const transportationAction = (transporterObj :any) => {
    return <div className='row' style={{ width: 'auto' }}>
      <DKIcon
        src={DKIcons.ic_edit}
        className="ic-xs ml-s"
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          setSelectedTransporter(transporterObj);
          setShowTransporterForm(true);
        }}
      />
      <DKIcon
        src={DKIcons.ic_delete}
        className="ic-xs ml-s"
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          onTransporterDelete(transporterObj);
        }}
      />
    </div>
  }

  const onTransporterDelete = (transporterObj: any) => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Yes',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          deleteTransporter(transporterObj);
        }
      }
    ];
    showAlert(
      'Confirm Delete',
      'Are you sure, you want to delete this record?',
      buttons
    );
  };

  const transportationDetails = () => {
    return (
      <div className="column parent-width mt-3 p-h-r p-v-s">
        <div className="row width-auto p-h-s">
          <DKIcon src={DKIcons.ic_cards} />
          <DKLabel text="Transportation Details" className="font-bold ml-r" />
        </div>
        <div className="row parent-width mt-4">
          <div className="w-1/3 p-h-s">
            <DKInput
              canValidate={canValidate}
              title={'Transportation Details'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DROPDOWN}
              value={
                selectedInvoiceObject?.formData?.transportationDetails
                  .transporterName.value
              }
              required={false}
              onChange={(value: any) => {}}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: true,
                searchableKey: 'transporterName',
                data: transporterData,
                renderer: (index: number, transporterObj: any) => {
                  return (
                    <div className="row listPickerBG cursor-hand p-h-r justify-content-between list-row">
                      <>
                        <DKLabel text={`${transporterObj.transporterName}`} />
                        {transportationAction(transporterObj)}
                      </>
                    </div>
                  );
                },
                button: {
                  title: `+ Manage Transporter`,
                  icon: DKIcons.ic_contact,
                  className: 'bg-button text-white',
                  style: {},
                  onClick: () => {
                    setSelectedTransporter(null);
                    setShowTransporterForm(true);
                  }
                },
                onSelect: (index: number, value: any) => {
                  let invoiceCopy = { ...selectedInvoiceObject };
                  invoiceCopy.formData = {
                    ...selectedInvoiceObject?.formData,
                    transportationDetails: {
                      ...selectedInvoiceObject?.formData?.transportationDetails,
                      transporterName: {
                        ...selectedInvoiceObject?.formData
                          ?.transportationDetails.transporterName,
                        id: value.transporterId,
                        value: value.transporterName
                      }
                    }
                  };
                  updateInvoiceObjectInArray(invoiceCopy);
                  // setFormData({
                  //   ...formData,
                  //   transportationDetails: {
                  //     ...formData.transportationDetails,
                  //     transporterName: {
                  //       ...formData.transportationDetails.transporterName,
                  //       id: value.transporterId,
                  //       value: value.transporterName
                  //     }
                  //   }
                  // });
                }
              }}
            />
          </div>
          <div className="w-1/3 p-h-s">
            <DKInput
              canValidate={canValidate}
              title={'Distance In KM'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={
                selectedInvoiceObject?.formData?.transportationDetails
                  .distanceInKm
              }
              required={true}
              onChange={(value: any) => {
                let invoiceCopy = { ...selectedInvoiceObject };
                invoiceCopy.formData = {
                  ...selectedInvoiceObject?.formData,
                  transportationDetails: {
                    ...selectedInvoiceObject?.formData?.transportationDetails,
                    distanceInKm: value
                  }
                };
                updateInvoiceObjectInArray(invoiceCopy);
                // setFormData({
                //   ...formData,
                //   transportationDetails: {
                //     ...formData.transportationDetails,
                //     distanceInKm: value
                //   }
                // });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    ignoreParse = false
  ) => {
    let amountText: any;
    if (ignoreParse) {
      amountText = amount;
    } else {
      amountText = `${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
        Math.abs(amount)
      )}${amount < 0 ? ')' : ''}`;
    }
    return (
      <div
        className="row parent-width mb-l justify-content-between align-items-start"
        style={{ width: 240 }}
      >
        <div
          className="row width-auto"
          style={{
            minWidth: 100
          }}
        >
          {icon && (
            <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
          )}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
        </div>
        <DKLabel
          text={
            ignoreParse
              ? amountText
              : `${Utility.getCurrencySymbolFromCode(
                  productDocumentCurrency
                )} ${amountText}`
          }
          style={{
            wordBreak: 'break-all'
          }}
          className={`ml-r text-wrap ${amountClassName || ''}`}
        />
      </div>
    );
  };

  const parseNumberForNAN = (number: any) => {
    if (number === undefined || number === null) {
      return 0;
    }
    return number;
  };

  const getProductDetailRows = () => {
    let productTableData: any = [];
    if (!Utility.isEmpty(props.data) && !Utility.isEmpty(selectedInvoice)) {
      productTableData = deepClone(
        selectedInvoice?.salesInvoice.salesInvoiceItems
      );

      if (productTableData && productTableData.length > 0) {
        productTableData.forEach((product: any) => {
          product.name = product?.product?.name;
          product._unitPrice = product.unitPrice;
          product.unitPrice = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.salesInvoice.currency,
            product.unitPrice
          );
          product._cgstAmount = parseNumberForNAN(product.cgstAmount);
          product.cgstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.salesInvoice.currency,
            parseNumberForNAN(product.cgstAmount)
          );
          product._sgstAmount = parseNumberForNAN(product.sgstAmount);
          product.sgstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.salesInvoice.currency,
            parseNumberForNAN(product.sgstAmount)
          );
          product._igstAmount = parseNumberForNAN(product.igstAmount);
          product.igstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.salesInvoice.currency,
            parseNumberForNAN(product.igstAmount)
          );
          product._cessAmount = parseNumberForNAN(product.cessAmount);
          product.cessAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.salesInvoice.currency,
            parseNumberForNAN(product.cessAmount)
          );
        });
      }
      return productTableData;
    } else if (
      Utility.isEmpty(props.data) &&
      !Utility.isEmpty(selectedInvoice)
    ) {
      productTableData = deepClone(selectedInvoice?.salesInvoiceItems);
      if (productTableData && productTableData.length > 0) {
        productTableData.forEach((product: any) => {
          product.name = product?.product?.name;
          product._unitPrice = product.unitPrice;
          product.unitPrice = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.currency,
            product.unitPrice
          );
          product._cgstAmount = parseNumberForNAN(product.cgstAmount);
          product.cgstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.currency,
            parseNumberForNAN(product.cgstAmount)
          );
          product._sgstAmount = parseNumberForNAN(product.sgstAmount);
          product.sgstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.currency,
            parseNumberForNAN(product.sgstAmount)
          );
          product._igstAmount = parseNumberForNAN(product.igstAmount);
          product.igstAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.currency,
            parseNumberForNAN(product.igstAmount)
          );
          product._cessAmount = parseNumberForNAN(product.cessAmount);
          product.cessAmount = Utility.getLongAmoutBlockForLabel(
            selectedInvoice?.currency,
            parseNumberForNAN(product.cessAmount)
          );
        });
      }
      return productTableData;
    } else {
      return productTableData;
    }
  };

  const productDetails = () => {
    let _taxableAmount: number = 0;
    let _cgst: number = 0;
    let _sgst: number = 0;
    let _igst: number = 0;
    let _cess: number = 0;
    let _totalAmount: number = selectedInvoice?.totalAmount;

    getProductDetailRows().forEach((invoiceItem: any) => {
      _taxableAmount += invoiceItem._unitPrice;
      _cgst += invoiceItem._cgstAmount;
      _sgst += invoiceItem._sgstAmount;
      _igst += invoiceItem._igstAmount;
      _cess += invoiceItem._cessAmount;
    });

    return (
      <div className="column parent-width p-h-r p-v-s">
        <div className="row parent-width p-h-s bg-gray-100 py-2 rounded">
          <div className="row parent-width">
            <DKIcon src={DKIcons.ic_cards} />
            <DKLabel text="Product Details" className="font-bold ml-r" />
          </div>
          <div>
            {showProductDetails && (
              <DKIcon
                onClick={() => {
                  setShowProductDetails(!showProductDetails);
                }}
                className="cursor-pointer"
                src={DKIcons.ic_arrow_up2}
              />
            )}
            {!showProductDetails && (
              <DKIcon
                onClick={() => {
                  setShowProductDetails(!showProductDetails);
                }}
                className="cursor-pointer"
                src={DKIcons.ic_arrow_down2}
              />
            )}
          </div>
        </div>
        {showProductDetails && (
          <>
            <div className="row width-auto p-h-s">
              <DKDataGrid
                needShadow={false}
                needColumnIcons={false}
                needBorder={true}
                needTrailingColumn={false}
                allowBulkOperation={false}
                allowColumnSort={false}
                filterData={[]}
                allowColumnDelete={false}
                allowRowEdit={true}
                allowColumnEdit={false}
                allowFilter={false}
                allowColumnAdd={false}
                allowBottomRowAdd={false}
                allowSearch={false}
                allowShare={false}
                width={930}
                rows={getProductDetailRows()}
                columns={EwayBillProductDetailsColumnConfig}
              />
            </div>
            <div className="flex flex-col flex-row-reverse parent-width p-h-s">
              <div className="grid justify-end">
                {getTitleAndAmount(
                  'Taxable Amount',
                  _taxableAmount,
                  null,
                  'fw-m'
                )}
                {getTitleAndAmount('CGST', _cgst, null, 'fw-m')}
                {getTitleAndAmount('SGST', _sgst, null, 'fw-m')}
                {getTitleAndAmount('IGST', _igst, null, 'fw-m')}
                {getTitleAndAmount(
                  'Total',
                  _totalAmount,
                  null,
                  'font-bold',
                  '',
                  true
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderAddressSection = (selectedAddress: any) => {
    if (
      Utility.isEmpty(selectedAddress?.state) &&
      Utility.isEmpty(selectedAddress?.address1) &&
      Utility.isEmpty(selectedAddress?.address2) &&
      Utility.isEmpty(selectedAddress?.country) &&
      Utility.isEmpty(selectedAddress?.postalCode)
    ) {
      return <div> - </div>;
    } else {
      return (
        <>
          <div className="flex-column p-s hover:bg-white cursor-pointer hover:bg-opacity-80 border-radius-m">
            {getFormattedAddress(selectedAddress)}
          </div>
        </>
      );
    }
  };

  const orgPreferredAddressList = (type: any) => {
    return (
      <DKListPicker2
        data={orgPreferredAddress}
        className="position-absolute z-index-3 bg-white border-m"
        style={{ minWidth: 250 }}
        renderer={(index: number, addressObj: any) => (
          <div
            style={{
              width: 200,
              whiteSpace: 'pre-wrap',
              textAlign: 'left'
            }}
          >
            {getFormattedAddress(addressObj)}
          </div>
        )}
        onEdit={(index: number, obj: any) => {}}
        canEdit={false}
        onSelect={(index: number, addressObj: any) => {
          let newState = { ...AddressDetails };
          let invoiceCopy = { ...selectedInvoiceObject };

          switch (type) {
            case ADDRESS_DETAIL_TYPE.DISPATCHED_FROM:
              newState.dispatchedFrom = addressObj;
              setAddressDetails(newState);

              invoiceCopy.addressDetail = newState;
              updateInvoiceObjectInArray(invoiceCopy);

              setOpenDispatchedFromAddressList(false);
              setOpenBillFromAddressList(false);
              break;
            case ADDRESS_DETAIL_TYPE.BILL_FROM:
              newState.billFrom = addressObj;
              setAddressDetails(newState);

              invoiceCopy.addressDetail = newState;
              updateInvoiceObjectInArray(invoiceCopy);

              setOpenDispatchedFromAddressList(false);
              setOpenBillFromAddressList(false);
              break;
          }
        }}
        onClose={() => {
          setOpenDispatchedFromAddressList(false);
          setOpenBillFromAddressList(false);
        }}
        allowSearch={false}
      />
    );
  };

  const contactPreferredAddressList = (type: any) => {
    return (
      <DKListPicker2
        data={contactPreferredAddress}
        className="position-absolute z-index-3 bg-white border-m"
        style={{ minWidth: 250 }}
        renderer={(index: number, addressObj: any) => (
          <div
            style={{
              width: 200,
              whiteSpace: 'pre-wrap',
              textAlign: 'left'
            }}
          >
            {getFormattedAddress(addressObj)}
          </div>
        )}
        onEdit={(index: number, obj: any) => {}}
        canEdit={false}
        onSelect={(index: number, addressObj: any) => {
          let newState = { ...AddressDetails };
          let invoiceCopy = { ...selectedInvoiceObject };

          switch (type) {
            case ADDRESS_DETAIL_TYPE.DISPATCHED_TO:
              newState.dispatchedTo = addressObj;
              setAddressDetails(newState);

              invoiceCopy.addressDetail = newState;
              updateInvoiceObjectInArray(invoiceCopy);

              setOpenDispatchedToAddressList(false);
              setOpenBillToAddressList(false);
              break;
            case ADDRESS_DETAIL_TYPE.BILL_TO:
              newState.billTo = addressObj;
              setAddressDetails(newState);

              invoiceCopy.addressDetail = newState;
              updateInvoiceObjectInArray(invoiceCopy);

              setOpenDispatchedToAddressList(false);
              setOpenBillToAddressList(false);
              break;
          }
        }}
        onClose={() => {
          setOpenDispatchedToAddressList(false);
          setOpenBillToAddressList(false);
        }}
        allowSearch={false}
      />
    );
  };

  const addressDetails = () => {
    return (
      <div className="column parent-width mt-3 p-h-r p-v-s">
        <div className="row parent-width p-h-s bg-gray-100 py-2 rounded">
          <div className="row parent-width">
            <DKIcon src={DKIcons.ic_cards} />
            <DKLabel text="Address Details" className="font-bold ml-r" />
          </div>
          <div>
            {showAddressDetails && (
              <DKIcon
                onClick={() => {
                  setShowAddressDetails(!showAddressDetails);
                }}
                className="cursor-pointer"
                src={DKIcons.ic_arrow_up2}
              />
            )}
            {!showAddressDetails && (
              <DKIcon
                onClick={() => {
                  setShowAddressDetails(!showAddressDetails);
                }}
                className="cursor-pointer"
                src={DKIcons.ic_arrow_down2}
              />
            )}
          </div>
        </div>
        {showAddressDetails && (
          <div className="parent-width mt-3 py-3 bg-gray-100 p-h-s rounded flex">
            <div className="w-1/2">
              <div className="font-bold mb-2">Dispatched From: </div>
              <div
                onClick={() => {
                  setOpenDispatchedFromAddressList(true);
                }}
              >
                {renderAddressSection(AddressDetails?.dispatchedFrom)}
              </div>
              <div>
                {openDispatchedFromAddressList &&
                  orgPreferredAddressList(ADDRESS_DETAIL_TYPE.DISPATCHED_FROM)}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-bold mb-2">Dispatch To: </div>
              <div
                onClick={() => {
                  setOpenDispatchedToAddressList(true);
                }}
              >
                {renderAddressSection(AddressDetails?.dispatchedTo)}
              </div>
              <div>
                {openDispatchedToAddressList &&
                  contactPreferredAddressList(
                    ADDRESS_DETAIL_TYPE.DISPATCHED_TO
                  )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-bold mb-2">Bill From: </div>
              <div
                onClick={() => {
                  setOpenBillFromAddressList(true);
                }}
              >
                {renderAddressSection(AddressDetails?.billFrom)}
              </div>
              <div>
                {openBillFromAddressList &&
                  orgPreferredAddressList(ADDRESS_DETAIL_TYPE.BILL_FROM)}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-bold mb-2">Bill To: </div>
              <div
                onClick={() => {
                  setOpenBillToAddressList(true);
                }}
              >
                {renderAddressSection(AddressDetails?.billTo)}
              </div>
              <div>
                {openBillToAddressList &&
                  contactPreferredAddressList(ADDRESS_DETAIL_TYPE.BILL_TO)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ewaybillform = () => {
    return (
      <>
        {transactionDetails()}
        {!Utility.isEmpty(selectedInvoice) && addressDetails()}
        {!Utility.isEmpty(selectedInvoice) && productDetails()}
        {transportationDetails()}
        {modeOfTransportation()}
      </>
    );
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_TRANSPORTER:
        manageTransporterBilltRef.current.storeCallbacksRef.createTransporter =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowTransporterForm(false);
        dispatch(fetchTransporters());
        updateTransporterForEwaybill(passingData.data)
        break;
    }
  };

  const updateTransporterForEwaybill = (formData: any) => {
    if (formData && formData !== null) {
      var id = selectedInvoiceObject?.formData?.transportationDetails.transporterName.id
      var name = selectedInvoiceObject?.formData?.transportationDetails.transporterName.value
      if (id === formData.transporterId.value && name !== formData.transporterName.value) {
        let invoiceCopy = { ...selectedInvoiceObject };
        invoiceCopy.formData = {
          ...selectedInvoiceObject?.formData,
          transportationDetails: {
            ...selectedInvoiceObject?.formData?.transportationDetails,
            transporterName: {
              ...selectedInvoiceObject?.formData
                ?.transportationDetails.transporterName,
              id: id,
              value: formData.transporterName.value
            }
          }
        };
        updateInvoiceObjectInArray(invoiceCopy);
      }
    }
  }

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', ';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 975,
          width: '90%',
          maxHeight: '95%',
          height: 800,
          padding: 0
        }}
      >
        {getHeader()}
        <div
          id="popup-container"
          className="column parent-width parent-height"
          style={{
            pointerEvents: isReadOnly ? 'none' : 'auto',
            paddingBottom: 250
          }}
        >
          {ewaybillform()}
        </div>
      </div>
      {showTransporterForm && (
        <ManageTransporter
          transporter={selectedTransporter}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onCancel={() => {
            setShowTransporterForm(false);
          }}
        />
      )}
      {showEWayBillLoginPortal && (
        <EWayBillLoginPopUp
          onClose={() => {
            setShowEWayBillLoginPortal(false);
          }}
          onCancel={() => {
            setShowEWayBillLoginPortal(false);
            props.onCancel();
          }}
        />
      )}
      {showResponsePopUp && (
        <EWayResponsePopUp
          response={eWayResponseObject}
          onCancel={() => {
            setShowResponsePopUp(false);
            props.onCancel();
          }}
        />
      )}
    </div>
  );
};

export default AddEwayBill;
