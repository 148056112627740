import Utility from '../Utility/Utility';

export class Address {
  id: number;
  address1: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  preferred?: string | boolean;
  constructor(props: any) {
    this.id = props && props.id ? props.id : '';
    this.address1 = props
      ? Utility.getValue(props.addressLine1, props.address1)
      : '';
    this.address2 = props
      ? Utility.getValue(props.addressLine2, props.address2)
      : '';
    this.city = props && props.city ? props.city : '';
    this.state = props && props.state ? props.state : '';
    this.country = props && props.country ? props.country : '';
    this.postalCode = props && props.postalCode ? props.postalCode : '';
    this.preferred = props && props.preferred ? props.preferred : '';
  }
  convertToString(): string {
    return [
      this.address1,
      this.address2,
      this.city,
      this.state,
      this.country,
      this.postalCode
    ]
      .filter((field) => !!field)
      .join();
  }
}
