import React, { useEffect, useState } from 'react';
import {
  Toggle,
  DKInput,
  INPUT_TYPE,
  showToast,
  TOAST_TYPE,
  DKLabel,
  DKTooltipWrapper,
  DKIcon,
  DKIcons
} from 'deskera-ui-library';
import {
  InputTag,
  SingleSelectDropdownTag,
  ToggleTag
} from '../../Models/NewContact';
import {
  DropdownSelectionType,
  FORM_ELEMENTS,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import Utility, { deepClone } from '../../Utility/Utility';
import GenericService from '../../Services/GenericService';
import { useAppSelector } from '../../Redux/Hooks';
import { selectResponse } from '../../Redux/Slices/GenericSlice';

export interface HsnSacPayLoad {
  code: any;
  description: string;
  gstRate: any;
}

export enum ADD_HSN_SAC_KEY_NAMES {
  HSN_SAC_CHANGE_TOGGLE = 'hsnSacChangeToggle',
  HSN_SAC_CODE = 'hsnSacCode',
  PERCENTAGE = 'percentage',
  DESCRIPTION = 'description'
}

export interface AddHsnSacState {
  hsnSacChangeToggle: ToggleTag<ADD_HSN_SAC_KEY_NAMES>;
  hsnSacCode: InputTag<ADD_HSN_SAC_KEY_NAMES>;
  percentage: SingleSelectDropdownTag<ADD_HSN_SAC_KEY_NAMES>;
  description: InputTag<ADD_HSN_SAC_KEY_NAMES>;
}

export const initialHsnSacFormValues: AddHsnSacState = {
  hsnSacChangeToggle: {
    key: ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CHANGE_TOGGLE,
    hidden: false,
    value: false,
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false,
    isDisabled: false
  },
  hsnSacCode: {
    key: ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CODE,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true
  },
  percentage: {
    key: ADD_HSN_SAC_KEY_NAMES.PERCENTAGE,
    hidden: false,
    value: 0,
    defaultValue: 0,
    type: FORM_ELEMENTS.DROPDOWN,
    selectionType: DropdownSelectionType.SINGLE,
    options: []
  },
  description: {
    key: ADD_HSN_SAC_KEY_NAMES.DESCRIPTION,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: false
  }
};

const AddHsnSac = (props: any) => {
  const [formState, setFormState] = useState(
    deepClone(initialHsnSacFormValues)
  );
  const genericData: any = useAppSelector(selectResponse);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    let intialFormData = deepClone(initialHsnSacFormValues);
    if (props.type !== 'HSN') {
      intialFormData.hsnSacChangeToggle.value = true;
      if (props.data) {
        const updatedState: AddHsnSacState = formState;
        updatedState.hsnSacChangeToggle.value = true;
        setInitialFormData(props.data);
      } else {
        setFormState(intialFormData);
      }
    } else {
      if (props.data) {
        setInitialFormData(props.data);
      } else {
        setFormState(intialFormData);
      }
    }
  }, [props.data]);

  const setInitialFormData = (data: any) => {
    const updatedState: AddHsnSacState = formState;
    updatedState.hsnSacChangeToggle.isDisabled = true;
    updatedState.hsnSacCode.value = data.code;
    updatedState.description.value = data.description;
    updatedState.percentage.value = data.gstRate;
    setFormState({ ...updatedState });
  };

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_HSN_SAC_CODES,
        data: () => {
          saveHsnSac();
        }
      });
    }
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const formFieldChanged = (
    newValue: number | boolean | string | null,
    fieldName: string
  ) => {
    const updatedState: any = formState;
    const selectedField = updatedState[fieldName];

    switch (fieldName) {
      case ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CHANGE_TOGGLE:
        if (newValue === true) {
          selectedField.value = false;
        } else {
          selectedField.value = true;
        }
        break;
      case ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CODE:
      case ADD_HSN_SAC_KEY_NAMES.DESCRIPTION:
      case ADD_HSN_SAC_KEY_NAMES.PERCENTAGE:
        selectedField.value = newValue;
        break;
    }
    setFormState({ ...updatedState });
  };

  const saveHsnSac = () => {
    let CODE_TYPE = formState.hsnSacChangeToggle.value ? 'SAC' : 'HSC';
    if (Number(formState.hsnSacCode.value) < 0 || !formState.hsnSacCode.value) {
      showToast(
        `Please enter valid ${
          formState.hsnSacChangeToggle.value ? `SAC` : `HSC`
        } Code`,
        TOAST_TYPE.FAILURE
      );
      return;
    } else if (Number(formState.percentage.value) < 0) {
      showToast(`Please select percentage`, TOAST_TYPE.FAILURE);
      return;
    } else if (Utility.isEmpty(formState.description.value)) {
      showToast(`Please enter description`, TOAST_TYPE.FAILURE);
      return;
    }

    let payload: HsnSacPayLoad = {
      code: formState.hsnSacCode.value,
      description: formState.description.value.toString(),
      gstRate: formState.percentage.value
    };

    if (props.data) {
      let code: any = formState.hsnSacCode.value;

      if (CODE_TYPE === 'HSC') {
        GenericService.updateHSNCode(payload).then(
          (response: any) => {
            showToast('HSN Code Updated Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err: any) => {
            if (err.code == 400) {
              showToast('HSN Code already exist', TOAST_TYPE.FAILURE);
              return;
            }
            console.error('Error while updating HSN Code: ', err);
          }
        );
      }

      if (CODE_TYPE === 'SAC') {
        GenericService.updateSACCode(payload).then(
          (response: any) => {
            showToast('SAC Code Updated Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err: any) => {
            if (err.code == 400) {
              showToast('SAC Code already exist', TOAST_TYPE.FAILURE);
              return;
            }
            console.error('Error while updating SAC Code: ', err);
          }
        );
      }
    } else {
      if (CODE_TYPE === 'HSC') {
        GenericService.saveHSNCode(payload).then(
          (response: any) => {
            showToast('HSN Code Created Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err: any) => {
            if (err.code == 400) {
              showToast('HSN Code already exist', TOAST_TYPE.FAILURE);
              return;
            }
            console.error('Error while creating HSN Code: ', err);
          }
        );
      }

      if (CODE_TYPE === 'SAC') {
        GenericService.saveSACCode(payload).then(
          (response: any) => {
            showToast('SAC Code Created Successfully', TOAST_TYPE.SUCCESS);
            closePopup();
          },
          (err: any) => {
            if (err.code == 400) {
              showToast('SAC Code already exist', TOAST_TYPE.FAILURE);
              return;
            }
            console.error('Error while creating SAC Code: ', err);
          }
        );
      }
    }
  };

  let percentageList = [];
  if (!Utility.isEmpty(genericData?.salesTaxCode?.content)) {
    percentageList = genericData?.salesTaxCode?.content;
  }

  return (
    <div className="p-4 mt-4">
      <div className="row align-items-center mr-3 cursor-hand">
        <div className="flex flex-rox justify-between mb-4">
          <div className=" fs-r text-align-left unselectable mr-3 font-bold">
            HSN
          </div>
          <Toggle
            className=""
            color="bg-app"
            isOn={formState.hsnSacChangeToggle.value}
            onChange={() => {
              if (!formState.hsnSacChangeToggle.isDisabled) {
                formFieldChanged(
                  formState.hsnSacChangeToggle.value === true ? true : false,
                  ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CHANGE_TOGGLE
                );
              }
            }}
          />
          <div className=" fs-r text-align-left unselectable ml-3 font-bold">
            SAC
          </div>
        </div>
      </div>
      {props && props.data ? <></> : <></>}
      <div className="row mt-2">
        <div className="column parent-width mr-3">
          {props && props.data ? (
            <DKInput
              title={
                formState.hsnSacChangeToggle.value ? 'SAC Code' : 'HSN Code'
              }
              readOnly={true}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formState.hsnSacCode.value}
            />
          ) : (
            <DKInput
              type={INPUT_TYPE.NUMBER}
              required={formState.hsnSacCode.isMandatory}
              title={
                formState.hsnSacChangeToggle.value ? 'SAC Code' : 'HSN Code'
              }
              value={formState.hsnSacCode.value}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(value: number) => {
                formFieldChanged(value, ADD_HSN_SAC_KEY_NAMES.HSN_SAC_CODE);
              }}
            />
          )}

          <div className="column parent-width">
            <div className="row mt-m" style={{ width: 100 }}>
              <DKTooltipWrapper
                content="Search by tax percentage value."
                tooltipClassName=""
              >
                <div className="row">
                  <DKLabel className=" cursor-hand" text={'Percentage *'} />
                  <DKIcon
                    src={DKIcons.ic_info}
                    className="ic-xs opacity-40 ml-s"
                    onClick={() => {}}
                  />
                </div>
              </DKTooltipWrapper>
            </div>
            <DKInput
              className="mt-s"
              value={formState.percentage.value}
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              onChange={(value: any) => {}}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              dropdownConfig={{
                className: '',
                style: {},
                allowSearch: true,
                searchableKey: 'name',
                data: percentageList,
                renderer: (index: number, obj: any) => {
                  return (
                    <div className="column parent-width listPickerBG cursor-hand p-h-r justify-content-between list-row">
                      <DKLabel className="" text={`${obj.name}`} />
                      <div className="row text-gray fs-s">
                        Percentage :
                        <DKLabel
                          className="ml-s text-gray fs-s"
                          text={`${obj.percent}`}
                        />
                      </div>
                    </div>
                  );
                },
                searchApiConfig: {
                  getUrl: (searchValue: string) => {
                    var apiPath = `taxes?query=percent=${searchValue}&limit=50`;
                    return GenericService.getSearchApiUrl(apiPath);
                  },
                  dataParser: (response: any) => {
                    return response?.content || [];
                  }
                },
                onSelect: (index: number, value: any) => {
                  formFieldChanged(
                    value.percent,
                    ADD_HSN_SAC_KEY_NAMES.PERCENTAGE
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="column parent-width">
          <DKInput
            type={INPUT_TYPE.LONG_TEXT}
            title="Description *"
            required="true"
            value={formState.description.value}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(value: string) => {
              formFieldChanged(value, ADD_HSN_SAC_KEY_NAMES.DESCRIPTION);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddHsnSac;
