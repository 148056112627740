import ProductionPopup from '../../WIPConsumption/ProductionPopup';

const WOStatusUpdateWithProductionPopup = (props: any) => {
  const getProductionPopupView = () => {
    return (
      <ProductionPopup
        onCancel={() => {
          props.onCancel();
        }}
        autoConsume={true}
        onSuccess={() => {
          props.onSuccess();
        }}
        selectedProduct={props?.productionPopupData}
        workOrderItems={props?.workOrderObject}
      />
    );
  };
  return <>{getProductionPopupView()}</>;
};

export default WOStatusUpdateWithProductionPopup;
