import { SELECT_LABEL } from '../../Components/Settings/Classes/ClassesConstant';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import {
  getDirectChildCFs,
  getDirectParentCFs
} from '../DocumentForm/NewDocumentHelper';

type CustomFieldReactSelectOption = ReactSelectOptionsType<string, string>;

export const isOptionSelectedNoneOrEmpty = (option: any) => {
  return (
    Utility.isEmpty(option) ||
    option?.value === '' ||
    option?.value?.toLowerCase() === SELECT_LABEL.toLowerCase()
  );
};

export const getCurrentlyEditedCFFromFetchCF = (
  updatedState: any,
  index: any
) => {
  return updatedState.fetchedCustomFields?.find(
    (fetchCFItem: any) =>
      fetchCFItem.id?.toString() === updatedState.customFieldsList[index]?.key
  );
};

export const getDropdownfieldDetailsFiltered = (
  updatedState: any,
  currentEditingCF: any
) => {
  return updatedState.fetchedCustomFields.filter((fetchedCF: any) => {
    return (
      fetchedCF.id?.toString() === currentEditingCF?.parent?.id?.toString()
    );
  });
};

export const getCFOption = (attr: any) => {
  const option: CustomFieldReactSelectOption = {
    label: attr.value,
    value: attr.id.toString(),
    status: attr.status
  };
  return option;
};

export const doesPrevChildExistsAndEmpty = (
  prevDropdown: any,
  isOptionNoneOrEmpty: any,
  prevDropdownAndCurrentDropdownAreLinked: any
) => {
  const isPrevChildExistsAndEmpty =
    Utility.isNotEmpty(prevDropdown) &&
    Utility.isEmpty(prevDropdown?.value?.value) &&
    !isOptionNoneOrEmpty &&
    prevDropdownAndCurrentDropdownAreLinked;

  return isPrevChildExistsAndEmpty;
};

export const getCustomFieldsToSetNone = (
  customField: any,
  fetchedCustomFields: any
) => {
  const allParentsForCurrentCF = getDirectParentCFs(
    customField,
    fetchedCustomFields
  );
  const allChildsForCurrentCF = getDirectChildCFs(
    customField,
    fetchedCustomFields
  );

  const combinedCFs = [
    ...allParentsForCurrentCF,
    ...allChildsForCurrentCF,
    customField
  ];

  return combinedCFs;
};
