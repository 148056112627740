import {
  DOC_TYPE,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../Constants/Constant';
import { CallBackPayloadType, ReactSelectOptionsType } from './Interfaces';
import { DropdownTag, InputTag } from './NewContact';

export interface RecurringDocumentProps {
  documentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  closePopup?: () => void;
  emailTemplateConfig?: any;
}

export interface RecurringDocumentInfo {
  startDocumentDate: InputTag<string>;
  endDocumentDate: InputTag<string>;
  interval: DropdownTag<string>;
  isSetRecurring: boolean;
  isNeverEnds: boolean;
  customInterval: DropdownTag<string>;
  customIntervalPeriod: InputTag<string>;
}

export const initialRecurringFormValues: RecurringDocumentInfo = {
  startDocumentDate: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  endDocumentDate: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  interval: {
    key: '',
    hidden: false,
    defaultValue: { label: '', value: '' },
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  customIntervalPeriod: {
    key: '',
    hidden: false,
    value: 1,
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ''
  },
  customInterval: {
    key: '',
    hidden: false,
    defaultValue: { label: '', value: '' },
    value: { label: '', value: '' },
    selectionType: DropdownSelectionType.SINGLE,
    type: FORM_ELEMENTS.DROPDOWN
  },
  isSetRecurring: false,
  isNeverEnds: false
};

export const TYPE_OPTION_RECURRING: ReactSelectOptionsType<string, string>[] = [
  {
    label: 'Yearly',
    value: 'YEARLY',
    index: 0
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
    index: 1
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
    index: 2
  },
  {
    label: 'Custom',
    value: 'CUSTOM',
    index: 3
  }
];

export const CUSTOM_TYPE_OPTION_RECURRING: ReactSelectOptionsType<
  string,
  string
>[] = [
  {
    label: 'Month(s)',
    value: 'MONTHS',
    index: 0
  },
  {
    label: 'Week(s)',
    value: 'WEEKS',
    index: 1
  },
  {
    label: 'Day(s)',
    value: 'DAYS',
    index: 2
  }
];

export interface RecurringDocumentPayload {
  active: boolean;
  customTemplate: boolean;
  documentCode: string;
  documentType: string;
  endingDate: any;
  firstDocumentDate: any;
  interval: string;
  infinite: boolean;
  insertPaymentLink: boolean;
  sendEmailNotification: boolean;
  timeZoneOffset: string;
  customInterval?: any;
  customPeriod?: any;
}
