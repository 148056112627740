export default class TenantManager {
  static allTenants: any[] = [];
  static clientTenants: any[] = [];

  static setAllTenants(data: any) {
    TenantManager.allTenants = data;
  }
  static getAllTenants() {
    return TenantManager.allTenants;
  }

  static setAllClientTenants(data: any) {
    TenantManager.clientTenants = data;
  }

  static getAllClientTenants() {
    return TenantManager.clientTenants;
  }
}
