import React, { useRef, useState } from 'react';
import { DKDataGrid, DKIcons } from 'deskera-ui-library';
import { useAppSelector } from '../../../Redux/Hooks';
import {
  fetchWHTax,
  selectWithHoldTax,
  selectWithHoldTaxColumnConfig
} from '../../../Redux/Slices/WHTSlice';
import Utility, { deepClone } from '../../../Utility/Utility';
import { useDispatch } from 'react-redux';
import CreateWHTax from './Forms/CreateWHTax';
import { IColumn } from '../../../Models/Table';
import {
  isResidentRenderer,
  isStandardTaxRenderer
} from '../../../Helper/WHTaxHelper';
import useConfirm from '../../../Hooks/useConfirm';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { deleteWHTax } from '../../../Services/WithHoldTax';
interface WHTaxProps {
  gridNameWithDesc?: string;
}

const WHTax: React.FC<WHTaxProps> = (props) => {
  /** * hooks goes here goes here */
  const columnConfig = useAppSelector(selectWithHoldTaxColumnConfig);
  const [dataBeingFetched, setDataBeingFetch] = useState(false);
  const selectedRow = useRef(null);
  const [showCreateForm, showHideForm] = React.useState(false);
  const whTaxes = useAppSelector(selectWithHoldTax);
  const accountsData = useAppSelector(selectedAccounts);
  const dispatch = useDispatch();
  const { confirm } = useConfirm();
  React.useEffect(() => {
    loadTaxes();
  }, []);
  const addButtonObj = {
    title: '+ Add Withhold tax',
    className: 'fs-r bg-blue text-white ml-r',
    onClick: () => showHideForm(true)
  };
  const loadTaxes = () => {
    // setDataBeingFetch(true);
    dispatch(fetchWHTax());
  };
  const onSave = () => {
    onFormCancel();
    loadTaxes();
  };
  const onEdit = ({ rowData }: any) => {
    selectedRow.current = rowData;
    showHideForm(true);
  };
  const onDelete = async ({ rowData }: any) => {
    const confirmed = await confirm(
      'Are you sure, action can not be reverted?',
      'Delete Withhold Tax?'
    );
    if (confirmed) {
      deleteWHTax(rowData)
        .then((res) => {
          loadTaxes();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  };
  const onFormCancel = () => {
    showHideForm(false);
    selectedRow.current = null;
  };
  const getRowButtons = (row: any) => {
    const buttons: any[] = [];
    if (!row.isStandardTds) {
      buttons.push({
        title: '',
        icon: DKIcons.ic_edit,
        className: '',
        onClick: onEdit
      });
      buttons.push({
        title: '',
        icon: DKIcons.ic_delete,
        className: '',
        onClick: onDelete
      });
    }
    return buttons;
  };
  const getUpdatedColumnConfig = () => {
    const config = deepClone(columnConfig);
    config.forEach((column: IColumn) => {
      if (column.key === 'isStandardTds') {
        column.renderer = isStandardTaxRenderer;
      }
      if (column.key === 'resident') {
        column.renderer = isResidentRenderer;
      }
      if (column.key === 'accountName') {
        column.renderer = ({ rowData }: any) =>
          getAccountObjectForCode(rowData?.accountCode)?.name || '-';
      }
    });
    return config;
  };
  const getAccountObjectForCode = (code: string | undefined) => {
    let account = accountsData?.content?.find(
      (item: any) => code === item.code
    );
    if (Utility.isEmpty(account)) {
      return null;
    } else {
      return account;
    }
  };
  /** * renderer goes here */
  return (
    <div
      className="column parent-size hide-scroll-bar overflow-y-auto pt-xs"
      style={{ lineHeight: '16px' }}
    >
      <DKDataGrid
        title={props?.gridNameWithDesc || 'Withholding Taxes'}
        rows={deepClone(whTaxes).map((row) => ({
          ...row,
          allowToEdit: true,
          rowButtons: getRowButtons(row)
        }))}
        columns={getUpdatedColumnConfig()}
        buttons={[addButtonObj]}
        showHeader={true}
        updating={dataBeingFetched}
        allowColumnEdit={false}
      />
      {showCreateForm && (
        <CreateWHTax
          onSave={onSave}
          whTaxToEdit={selectedRow.current}
          onCancel={onFormCancel}
        />
      )}
    </div>
  );
};

export default WHTax;
