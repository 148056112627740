import { getLocalisedText } from '../../../Translate/LanguageManager';
import Utility, {
	getIsInvoice,
	getIsSalesOrder,
	getVW,
} from '../../../Utilities/Utility';
import { ColumnStyle } from '../ColumnStyle';
import { HeaderColumn } from '../HeaderColumn';
import { ItemColumn } from '../ItemColumn';
import { TableOption } from '../TableOption';
import { SecurityGateTableItemWidth } from './SecurityGateTableItemWidth';
import NumberUtility from '../../../Utilities/NumberUtility';
import {
	FONT_SIZE,
	PRINT_SCALE,
	TableDimensionType,
	TableColumnPopupType,
} from '../../../Constants/Constants';
import TemplateSettingsManager from '../../../Manager/TemplateSettingsManager';
import { TableHeader } from '../TableHeader';
import {
	getIsSelectTotalColumn,
	getSortedHeader,
	getSortedLineItems,
	getTablePadding,
} from '../TableUtility';
import TableFooterUtility from '../../../Utilities/TableFooterUtility';
import TableColumnAlignmentParser from '../../../Helper/TableColumnAlignmentParser';
import { SecurityGateTableItem } from './SecurityGateTableItem';

export class SecurityGateTable {
	static footerColumnList: HeaderColumn[];
	static groupHeaderIndexList: number[];
	static getTableOption(canUserEdit: boolean) {
		let tableOption = new TableOption();
		tableOption.isShowBorder = true;
		tableOption.isShowFooter = false;
		tableOption.isShowSortButton = canUserEdit;
		tableOption.isReceiptFooter = Utility.getIsReceiptPaperSize();
		return tableOption;
	}

	static getDefaultHeaderColumn(
		tableStyle: any,
		printScale: number = PRINT_SCALE
	) {
		let newHeaderColumn = new HeaderColumn();
		newHeaderColumn.type = 'string';
		newHeaderColumn.style = new ColumnStyle();
		newHeaderColumn.style.backgroundColor =
			tableStyle.headerBackgroundColor;
		newHeaderColumn.style.borderColor = tableStyle.headerBorderColor;
		newHeaderColumn.style.fontColor = tableStyle.headerTextColor;
		newHeaderColumn.style.borderStyle = 'solid';
		newHeaderColumn.style.borderWidth = getVW(
			Utility.convertToPrintScale(1, printScale)
		);
		if (tableStyle.headerBorderBottomColor !== undefined) {
			newHeaderColumn.style.borderBottomColor =
				tableStyle.headerBorderBottomColor;
			newHeaderColumn.style.borderBottomWidth =
				tableStyle.headerBorderBottomWidth;
		}
		newHeaderColumn.style.paddingTop = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newHeaderColumn.style.paddingBottom = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newHeaderColumn.style.paddingLeft = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);
		newHeaderColumn.style.paddingRight = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);
		newHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize()
			? FONT_SIZE.large
			: FONT_SIZE.regular;
		newHeaderColumn.style.fontWeight = 'bold';
		return newHeaderColumn;
	}

	static getHeaderColumnList(
		columnOption: any,
		tableStyle: any,
		rawData: any
	) {
		let headerColumnList: HeaderColumn[] = [];
		if (columnOption === undefined || columnOption === null) {
			return;
		}
		if (columnOption.length === 0) {
			return;
		}
		let sortedHeader = getSortedHeader(columnOption);
		let defaultTableColumnAlignmentList =
			TableColumnAlignmentParser.getDefaultAlignmentForProduct();
		let tableColumnAlignmentList =
			TableColumnAlignmentParser.getTableColumnAlignment();
		let tableColumnVerticalAlignmentList =
			TableColumnAlignmentParser.getTableColumnVerticalAlignment();
		let footerList = TableFooterUtility.getFooterListByType('product');
		if (getIsInvoice(rawData.type) || getIsSalesOrder(rawData.type)) {
			let index = footerList.indexOf(TableColumnPopupType.totalAmount);
			footerList.splice(
				index !== -1 ? index + 1 : 5,
				0,
				TableColumnPopupType.cogs
			);
		}
		if (sortedHeader.length > 0) {
			sortedHeader.forEach((element) => {
				if (!footerList.includes(element.type)) {
					if (element.globalDiscountAccountCode !== undefined) {
						element.code = element.globalDiscountAccountCode;
					}
					let newHeaderColumn = this.getDefaultHeaderColumn(
						tableStyle,
						rawData.printScale
					);
					newHeaderColumn = this.updateHeaderColumn(
						newHeaderColumn,
						new TableHeader(element),
						rawData,
						defaultTableColumnAlignmentList,
						tableColumnAlignmentList,
						tableColumnVerticalAlignmentList
					);
					headerColumnList.push(newHeaderColumn);
				}
			});
		}

		let width = columnOption[0].width;

		if (
			width === undefined ||
			width === null ||
			this.getIsResetRequiredForColumnWidth(columnOption)
		) {
			let newColumnOption = columnOption;
			let SecurityGateTableItemWidth = this.getHeaderColumnWidth(
				headerColumnList,
				rawData
			);
			headerColumnList.forEach((element) => {
				element.style.width =
					this.getHeaderItemColumnWidth(
						element.name,
						SecurityGateTableItemWidth,
						element.code
					) + '%';
			});

			newColumnOption.forEach((element: any) => {
				element.width =
					this.getHeaderItemColumnWidth(
						element.type,
						SecurityGateTableItemWidth,
						element.code
					) + '%';
			});

			TemplateSettingsManager.updateTablePopupInfo(newColumnOption);
		} else {
			headerColumnList.forEach((element) => {
				let items = columnOption.filter(
					(x: TableHeader) =>
						x.type === element.name && x.code === element.code
				);
				if (items !== undefined && items !== null) {
					if (items.length > 0) {
						let width = items[0].width;
						if (width !== undefined) {
							element.style.width = width;
						}
					}
				}
			});
		}
		// headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, footerList)
		return headerColumnList;
	}

	static getIsResetRequiredForColumnWidth(columnOptions: any) {
		let isResetRequired = false;
		let dataColumn = columnOptions.filter(
			(x: any) =>
				x.type !== TableColumnPopupType.discount &&
				x.type !== TableColumnPopupType.tax
		);
		let selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected);
		selectedColumnWidth.forEach((element: any) => {
			if (element.width === null || element.width === undefined) {
				isResetRequired = true;
			} else if (element.width === '0%') {
				isResetRequired = true;
			}
		});

		let isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected);

		isNotSelectedColumn.forEach((element: any) => {
			if (element.width !== null && element.width !== undefined) {
				let num = Number(element.width.replace('%', ''));
				if (num > 0) {
					isResetRequired = true;
				}
			}
		});

		return isResetRequired;
	}

	static updateHeaderColumn(
		headerColumn: HeaderColumn,
		tableHeader: TableHeader,
		data: any,
		defaultTableColumnAlignmentList: any,
		tableColumnAlignmentList: any,
		tableColumnVerticalAlignmentList: any
	) {
		headerColumn.columnOption.isShowColumn = tableHeader.isSelected;
		headerColumn.name = tableHeader.type;
		headerColumn.label = getLocalisedText(tableHeader.name);
		headerColumn.code = tableHeader.code;
		headerColumn.style.alignment = 'center';

		let columnAlignment = defaultTableColumnAlignmentList.find(
			(x: { type: string; alignment: string }) =>
				x.type === tableHeader.type
		);
		if (columnAlignment !== undefined) {
			headerColumn.style.alignment = columnAlignment.alignment;
		}

		if (TableColumnPopupType.productCustomField === tableHeader.type) {
			headerColumn.label = tableHeader.name;

			if (tableHeader.code === undefined) {
				headerColumn.style.alignment = 'left';
			}
		}

		if (
			tableColumnAlignmentList !== undefined &&
			tableColumnAlignmentList !== null
		) {
			if (tableColumnAlignmentList.length > 0) {
				let savedColumnAlignment: any = undefined;
				if (
					tableHeader.type ===
						TableColumnPopupType.productCustomField &&
					tableHeader.code !== undefined
				) {
					savedColumnAlignment = tableColumnAlignmentList.find(
						(x: {
							type: string;
							alignment: string;
							code: string | undefined;
						}) =>
							x.type === tableHeader.type &&
							x.code === tableHeader.code
					);
				} else {
					savedColumnAlignment = tableColumnAlignmentList.find(
						(x: { type: string; alignment: string }) =>
							x.type === tableHeader.type
					);
				}
				if (savedColumnAlignment !== undefined) {
					headerColumn.style.alignment =
						savedColumnAlignment.alignment;
				}
			}
		}

		if (
			tableColumnVerticalAlignmentList !== undefined &&
			tableColumnVerticalAlignmentList !== null
		) {
			if (tableColumnVerticalAlignmentList.length > 0) {
				let savedColumnVerticalAlignment: any = undefined;
				if (
					tableHeader.type ===
						TableColumnPopupType.productCustomField &&
					tableHeader.code !== undefined
				) {
					savedColumnVerticalAlignment =
						tableColumnVerticalAlignmentList.find(
							(x: {
								type: string;
								alignment: string;
								code: string | undefined;
							}) =>
								x.type === tableHeader.type &&
								x.code === tableHeader.code
						);
				} else {
					savedColumnVerticalAlignment =
						tableColumnVerticalAlignmentList.find(
							(x: { type: string; alignment: string }) =>
								x.type === tableHeader.type
						);
				}
				if (savedColumnVerticalAlignment !== undefined) {
					headerColumn.style.verticalAlignment =
						savedColumnVerticalAlignment.alignment;
				}
			}
		}
		return headerColumn;
	}

	static getHeaderColumnWidth(headerColumnList: HeaderColumn[], data: any) {
		let showWidthConfig = new SecurityGateTableItemWidth(data);
		showWidthConfig.setIsShowWidth(headerColumnList);
		showWidthConfig.getTableHeaderWidths();
		return showWidthConfig;
	}

	static getHeaderItemColumnWidth(
		type: string,
		SecurityGateTableItemWidth: SecurityGateTableItemWidth,
		code?: string
	): number {
		let width = 0;
		let itemWidth = 0;
		switch (type) {
			case TableColumnPopupType.lineNumber:
				width = SecurityGateTableItemWidth.lineNumber;
				break;
			case TableColumnPopupType.code:
				width = SecurityGateTableItemWidth.code;
				break;
			case TableColumnPopupType.productName:
				width = SecurityGateTableItemWidth.name;
				break;
			case TableColumnPopupType.description:
				width = SecurityGateTableItemWidth.description;
				break;
			case TableColumnPopupType.productNameWithDescription:
				width = SecurityGateTableItemWidth.nameWithDescription;
				break;
			case TableColumnPopupType.productCustomField:
				width = SecurityGateTableItemWidth.productCustomField;
				if (code !== undefined) {
					itemWidth =
						SecurityGateTableItemWidth.getProductCustomFieldWidthByCode(
							code
						);
					if (itemWidth !== undefined) {
						width = itemWidth;
					}
				}
				break;
			case TableColumnPopupType.quantity:
				width = SecurityGateTableItemWidth.quantity;
				break;
			case TableColumnPopupType.uom:
				width = SecurityGateTableItemWidth.uom;
				break;
			case TableColumnPopupType.refDocument:
				width = SecurityGateTableItemWidth.refDocument;
				break;
			case TableColumnPopupType.refDocumentDate:
				width = SecurityGateTableItemWidth.refDocumentDate;
				break;
			default:
				break;
		}

		return width;
	}

	static getData(documentData: any) {
		const lineItems: string[][] = [];
		let dummyData = [
			[
				getLocalisedText('item_name'),
				getLocalisedText('item_description'),
				'1',
				'0.00',
				'0.00',
			],
			[
				getLocalisedText('item_name'),
				getLocalisedText('item_description'),
				'1',
				'0.00',
				'0.00',
			],
			[
				getLocalisedText('item_name'),
				getLocalisedText('item_description'),
				'1',
				'0.00',
				'0.00',
			],
		];
		if (documentData === undefined || documentData === null) {
			return dummyData;
		} else {
			if (
				documentData.lineItems === undefined ||
				documentData.lineItems === null
			) {
				return dummyData;
			}
			if (documentData.lineItems.length === 0) {
				return [];
			}
			const sortedItems = getSortedLineItems(documentData.lineItems);

			//processing grouping for the for product attribute
			const groupItems: any = [];
			var currency = NumberUtility.getCurrency(documentData);
			sortedItems.forEach((element: any) => {
				groupItems.push(
					new SecurityGateTableItem(
						element,
						documentData,
						currency
					).toString(null)
				);
			});
			groupItems.forEach((element: any) => {
				lineItems.push(element);
			});

			return lineItems;
		}
	}

	static getAttributeString(customFields: any) {
		let label = '';
		if (customFields !== undefined && customFields !== null) {
			if (customFields.length > 0) {
				customFields.forEach((element: any, index: number) => {
					label = label + element.label + ' - ' + element.value;
					if (index < customFields.length - 1) {
						label = label + ', ';
					}
				});
			}
		}
		return label;
	}

	static getDefaultItemColumn(
		tableStyle: any,
		index: number,
		printScale: number = PRINT_SCALE
	) {
		let newItemColumn = new ItemColumn();
		newItemColumn.type = 'string';
		newItemColumn.style = new ColumnStyle();
		if (tableStyle.itemBackgroundColorIsAlternate) {
			newItemColumn.style.backgroundColor =
				index % 2 !== 0
					? 'transparent'
					: tableStyle.itemBackgroundColor;
		} else {
			newItemColumn.style.backgroundColor =
				tableStyle.itemBackgroundColor;
		}
		newItemColumn.style.borderColor = tableStyle.itemBorderColor;
		// newItemColumn.style.fontColor = tableStyle.headerTextColor
		newItemColumn.style.borderStyle = 'solid';
		newItemColumn.style.borderWidth = getVW(
			Utility.convertToPrintScale(1, printScale)
		);
		newItemColumn.style.paddingTop = getTablePadding(
			getVW(Utility.convertToPrintScale(13, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newItemColumn.style.paddingBottom = getTablePadding(
			getVW(Utility.convertToPrintScale(12.6, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newItemColumn.style.paddingLeft = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);
		newItemColumn.style.paddingRight = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);

		newItemColumn.style.fontSize = Utility.getIsReceiptPaperSize()
			? FONT_SIZE.large
			: FONT_SIZE.regular;
		return newItemColumn;
	}

	static getItemColumn(data: any, tableStyle: any, rawData: any) {
		let itemColumnList: ItemColumn[][] = [];
		if (data === undefined) {
			return itemColumnList;
		}
		if (data.length === 0) {
			return itemColumnList;
		}
		let isLastRowIndex = data.length - 1;
		data.forEach((column: any[], index: number) => {
			let rowList: ItemColumn[] = [];
			if (isLastRowIndex === index && getIsSelectTotalColumn()) {
				column.forEach((element) => {
					let newItemColumn = this.getDefaultItemColumn(
						tableStyle,
						index,
						rawData.printScale
					);
					newItemColumn.value = element;
					newItemColumn.isTotalColumn = true;
					rowList.push(newItemColumn);
				});
			} else {
				column.forEach((element) => {
					let newItemColumn = this.getDefaultItemColumn(
						tableStyle,
						index,
						rawData.printScale
					);
					newItemColumn.value = element;
					rowList.push(newItemColumn);
				});
			}

			itemColumnList.push(rowList);
		});
		return itemColumnList;
	}

	static onRowChange(
		data: any,
		rowIndex: number,
		action: string,
		onDataChangeCallback: any
	) {
		if (data === undefined || data === null) {
			onDataChangeCallback(data);
		}

		if (data.lineItems === undefined || data.lineItems === null) {
			onDataChangeCallback(data);
		}

		if (data.lineItems.length === 0 || data.lineItems.length === 1) {
			onDataChangeCallback(data);
		}

		let newArray: any[] = [];
		data.lineItems.forEach((element: any, index: number) => {
			if (index !== rowIndex) {
				newArray.push(element);
			}
		});
		let newIndex = rowIndex;
		if (action === 'up') {
			newIndex = rowIndex - 1;
			if (newIndex <= 0) {
				newIndex = 0;
			}
		} else if (action === 'down') {
			newIndex = rowIndex + 1;
			if (newIndex >= data.lineItems.length) {
				newIndex = data.lineItems.length - 1;
			}
		}
		let selectedItem = data.lineItems[rowIndex];
		newArray.splice(newIndex, 0, selectedItem);
		newArray.forEach((element, index) => {
			element.lineNumber = index + 1;
		});
		data.lineItems = newArray;
		onDataChangeCallback(data);
	}

	static setFooterHeader(
		tableStyle: any,
		type: string,
		name: string,
		isShowRecord: boolean,
		index?: number,
		printScale?: number
	) {
		let newFooterColumn = this.getDefaultFooterHeaderColumn(
			tableStyle,
			printScale
		);
		newFooterColumn.name = type;
		newFooterColumn.label = name;
		newFooterColumn.isShowRecord = isShowRecord;
		newFooterColumn.index = index;
		return newFooterColumn;
	}
	static getDefaultFooterHeaderColumn(
		tableStyle: any,
		printScale: number = PRINT_SCALE
	) {
		let newFooterHeaderColumn = new HeaderColumn();
		newFooterHeaderColumn.type = 'string';
		newFooterHeaderColumn.style = new ColumnStyle();
		if (
			tableStyle.subTotalBorderColor !== undefined &&
			tableStyle.subTotalBorderColor !== null
		) {
			newFooterHeaderColumn.style.borderColor =
				tableStyle.subTotalBorderColor;
		} else {
			newFooterHeaderColumn.style.borderColor =
				tableStyle.itemBorderColor;
		}

		newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor;
		newFooterHeaderColumn.style.borderStyle = 'solid';
		newFooterHeaderColumn.style.borderWidth = getVW(
			Utility.convertToPrintScale(1, printScale)
		);
		newFooterHeaderColumn.style.paddingTop = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newFooterHeaderColumn.style.paddingBottom = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_VERTICAL
		);
		newFooterHeaderColumn.style.paddingLeft = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);
		newFooterHeaderColumn.style.paddingRight = getTablePadding(
			getVW(Utility.convertToPrintScale(11, printScale)),
			TableDimensionType.PADDING_HORIZONTAL
		);
		newFooterHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize()
			? FONT_SIZE.large
			: FONT_SIZE.regular;
		// newFooterHeaderColumn.style.alignment = 'right'
		return newFooterHeaderColumn;
	}

	static setLastFooterHeader(
		tableStyle: any,
		type: string,
		name: string,
		printScale: number
	) {
		let newFooterColumn = this.getDefaultFooterHeaderColumn(
			tableStyle,
			printScale
		);
		newFooterColumn.name = type;
		newFooterColumn.label = getLocalisedText(name);
		newFooterColumn.style.backgroundColor =
			tableStyle.footerBackgroundColor;
		newFooterColumn.style.fontColor = tableStyle.footerTextColor;
		newFooterColumn.style.borderColor = tableStyle.footerBorderColor;
		newFooterColumn.style.fontWeight = 'bold';
		if (
			type === TableColumnPopupType.outstandingAmount ||
			type === TableColumnPopupType.amountInWords
		) {
			if (
				tableStyle.footerTextColor === 'white' ||
				tableStyle.footerTextColor === 'transparent'
			) {
				if (tableStyle.footerBackgroundColor === undefined) {
					newFooterColumn.style.fontColor = undefined;
				}
				if (tableStyle.footerBackgroundColor === 'transparent') {
					newFooterColumn.style.fontColor = undefined;
				}
			}
		}

		if (tableStyle.lastRowTopColor !== undefined) {
			newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor;
			newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth;
		}
		if (tableStyle.lastRowBottomColor !== undefined) {
			newFooterColumn.style.borderBottomColor =
				tableStyle.lastRowBottomColor;
			newFooterColumn.style.borderBottomWidth =
				tableStyle.lastRowBottomWidth;
		}

		return newFooterColumn;
	}

	static getIsShowTotal(tableStyle: any, type: string) {
		if (type === TableColumnPopupType.amount) {
			if (
				tableStyle.footerTextColor === 'white' ||
				tableStyle.footerTextColor === 'transparent'
			) {
				if (tableStyle.footerBackgroundColor === undefined) {
					return false;
				}
				if (tableStyle.footerBackgroundColor === 'transparent') {
					return false;
				}
			}
		}
		return true;
	}

	static getIsContainsAdditionalCharge(documentData: any) {
		if (
			documentData.additionalCharge !== undefined &&
			documentData.additionalCharge !== null
		) {
			if (documentData.additionalCharge.length > 0) {
				return true;
			}
		}

		return false;
	}
}
