import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { GridState } from '../../../Models/ReduxStore';
import QualityCheckService from '../../../Services/MRP/QualityCheck';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchQualityCheckList = createAsyncThunk(
  'fetchQualityCheck',
  async () => {
    const response = await QualityCheckService.getQualityCheck();
    return response;
  }
);

export const MRPQualityCheckSlice = createSlice({
  name: 'QualityCheck',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQualityCheckList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchQualityCheckList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.qualityCheck = action.payload;
      });
  }
});

export const {} = MRPQualityCheckSlice.actions;

export const selectQualityChecks = (state: RootState) =>
  state.mrpQualityChecks.data.qualityCheck;
