import React, { Component } from "react";
import { DateFormat } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility, { getDateString, getPageHeight, getVW } from "../../../Utilities/Utility";
import PayslipUtility from "../../PayslipTemplates/PayslipUtility";
import "../css/Templates.css";
import PayrollCheckUtility from "../PayrollCheckUtility";

export default class USPayrollCheckTemplate1 extends Component {

    totalHeight = 196.64
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight() }}>
                <div className='parent-width' style={{ width: '100%'}}>
                    {this.renderCheque()}
                </div>
            </div>
        );
    }

    renderCheque() {
        if (!this.state.data.payrollCheques) {
            return undefined
        }

        if (this.state.data.payrollCheques.length === 0) {
            return undefined
        }

        const cheques = this.state.data.payrollCheques.map((element) => {
            return this.renderChequeContainer(element);
        });

        return cheques;
    }

    renderChequeContainer(element) {
        const height = getVW(364);
        return <div style={{
            // borderBottom: "1px solid",
            minHeight: height,
            height: height,
            maxHeight: height,
            textAlign: "start",
            marginBottom: getVW(10),
            lineHeight: 1.3
        }}>
            {this.renderTenantNameAndChequeNumberContainer(element)}
            {this.renderTenantAddressContainer(element)}
            {this.renderZipCodeAndDateContainer(element)}
            <div style={{ height: getVW(40) }}></div>
            {this.renderPayToContainer(element)}
            <div style={{ height: getVW(15) }}></div>
            {this.renderAmountInWordsContainer(element)}
            <div style={{ height: getVW(15) }}></div>
            {this.renderBankNameContainer(element)}
            <div style={{ height: getVW(70) }}></div>
            {this.renderMemoContainer(element)}
            <div style={{ height: getVW(10) }}></div>
            {this.renderMICRFormatNumberContainer(element)}
        </div>
    }

    renderTenantNameAndChequeNumberContainer(element) {
        return <div
            className="RowDiv docFontStyleBold"
            style={{
                justifyContent: "space-between"
            }}
        >
            <div>{element.companyName}</div>
            <div>{element.checkNumber}</div>
        </div>
    }

    renderTenantAddressContainer(element) {
        return <div className="ColumnDiv docFontStyleBold">
            <div>{element.companyAddress}</div>
        </div>
    }

    renderZipCodeAndDateContainer(element) {
        return <div
            className="RowDiv docFontStyleBold"
            style={{
                justifyContent: "space-between"
            }}
        >
            <div>{element.zipcode}</div>
            {element.payDate &&
                <div>
                    <div className="hr-line">
                        <span className="text-uppercase">{getLocalisedText('date').trim()}</span>
                        &nbsp;
                        <span>{getDateString(new Date(element.payDate), PayslipUtility.getDateFormat(this.state.data) ? PayslipUtility.getDateFormat(this.state.data).toLowerCase() : DateFormat.MM_DD_YYYY_SLASH)}</span>
                    </div>
                </div>
            }
        </div>
    }

    renderPayToContainer(element) {
        return <div className="RowDiv">
            <div
                className="hr-line"
                style={{
                    flexGrow: 1,
                    marginRight: getVW(10)
                }}
            >
                <div className="RowDiv">
                    <div
                        className="payToText"
                        style={{
                            marginRight: getVW(20)
                        }}
                    >
                        {getLocalisedText('pay_to_the')}
                        <br/>
                        {getLocalisedText('order_of')}
                    </div>
                    <div className="docFontStyleBold employeeNameText text-uppercase">
                        {element.employeeName}
                    </div>
                </div>
            </div>
            <div className="docFontStyleBold">
                {Utility.toCurrencyFormat(element.netPay > 0 ? element.netPay : 0, this.state.data.currency)}
            </div>
        </div>
    }

    renderAmountInWordsContainer(element) {
        return <div className="RowDiv hr-line">
            <div
                style={{
                    flexGrow: 1,
                }}
            >
                {element.netPayInWords}
            </div>
            <div>
                {getLocalisedText('dollars')}
            </div>
        </div>
    }

    renderBankNameContainer(element) {
        return <div>
            <div>{element.bankName}</div>
        </div>
    }

    renderMemoContainer(element) {
        return <div className="RowDiv">
            <div
                className="hr-line"
                style={{
                    flexGrow: 1,
                }}
            >
                <div className="RowDiv">
                    <div
                        style={{
                            marginRight: getVW(10)
                        }}
                    >
                        <span className="text-uppercase">{getLocalisedText('memo')}</span>
                    </div>
                    <div>
                        {PayrollCheckUtility.getMemoText(this.state.data, element.workPeriod)}
                    </div>
                </div>
            </div>
        </div>
    }

    renderMICRFormatNumberContainer(element) {
        return <div className="RowDiv micr-font">
            <div
                style={{
                    width: "30%",
                    textAlign: "center"
                }}
            >
                {element.checkNumber}
            </div>
            <div
                style={{
                    width: "30%",
                }}
            >
                {`A${element.routingNumber}A`}
            </div>
            <div
                style={{
                    width: "40%",
                }}
            >
                {`${element.accountNumber}C`}
            </div>
        </div>
    }
}
