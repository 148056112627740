import React, { Component, useEffect, useState } from 'react';
import { DKLabel, MultiBar, COLORS } from 'deskera-ui-library';
import DKDropdown from './DKDropdown';
import {
  DATE_FORMAT,
  GOOGLE_NO_TRANSLATE_CLASS
} from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import DashboardService from '../../Services/Dashboard';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  getSellDashboard,
  selectSellDashboard,
  selectSellFilterConfig,
  setSellFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import { useTranslation } from 'react-i18next';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import NumberFormatService from '../../Services/NumberFormat';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

// import DKDropdown from './DKDropdown';

function InvoiceSummary(props: any) {
  const dispatch = useAppDispatch();
  const invoiceSummary = useAppSelector(selectSellDashboard);
  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(0);
  const [parsedData, setParsedData] = useState<any>([
    {
      title: 'Paid',
      value: 15,
      color: COLORS.chart.blue
    },
    {
      title: 'Unpaid',
      value: 35,
      color: COLORS.chip.blue
    }
  ]);
  const { t, i18n } = useTranslation();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_30_DAYS'),
      value: 'LAST30'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_MONTH'),
      value: 'CURRENT_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_MONTH'),
      value: 'LAST_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];

  const getInvoicesReport = async () => {
    try {
      var date = new Date();
      var config = {
        duration: filters[selectedFilterIndex].value,
        type: 'SALES',
        fromDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY)
      };
      dispatch(setSellFilterConfig(config));
      DashboardService.apiConfig = config;
      const data = await dispatch(getSellDashboard());
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  useEffect(() => {
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.VIEW
      )
    ) {
      getInvoicesReport();
    }
  }, [selectedFilterIndex]);

  const getPaidInvoiceSummary = () => {
    return !Utility.isEmpty(invoiceSummary.paid) || Number(invoiceSummary.paid)
      ? invoiceSummary.paid
      : 0;
  };

  const getUnpaidInvoiceSummary = () => {
    return !isNaN(invoiceSummary.totalAmount - invoiceSummary.paid)
      ? invoiceSummary.totalAmount - invoiceSummary.paid
      : 0;
  };

  const getOverDueSummary = () => {
    return !Utility.isEmpty(invoiceSummary.overDue) ||
      Number(invoiceSummary.overDue)
      ? invoiceSummary.overDue
      : 0;
  };

  useEffect(() => {
    setParsedData([
      {
        title: 'Paid',
        value: getPaidInvoiceSummary(),
        color: COLORS.chart.blue
      },
      {
        title: 'Unpaid',
        value: getUnpaidInvoiceSummary(),
        color: COLORS.chip.blue
      },
      {
        title: 'Over Due',
        value: getOverDueSummary(),
        color: COLORS.chart.red
      }
    ]);
  }, [invoiceSummary]);

  return (
    <div className="border-radius-m shadow-s border-ms p-xl bg-white border-box mr-l db-grid-width-50 db-grid-min-width db-mb">
      <div className="row justify-content-between">
        <DKLabel text={'Sales Invoices'} className="fw-m fs-m" />
        <DKDropdown
          title={filters[selectedFilterIndex].name}
          data={filters.map((item: any) => {
            return item.name;
          })}
          onSelect={(data: any) => {
            setSelectedFilterIndex(data);
            props.updateFilter(filters[data].value);
          }}
        />
      </div>
      <div className="row justify-content-between mt-xl mb-1">
        <div className="row">
          <DKLabel
            text={`<b>${Utility.getCurrencySymbolFromCode(
              tenantInfo.currency
            )} ${
              +getPaidInvoiceSummary() < 0
                ? '(' +
                  NumberFormatService.getNumber(getPaidInvoiceSummary()) +
                  ')'
                : NumberFormatService.getNumber(getPaidInvoiceSummary())
            }
          </b>`}
            className={`whitespace-normal ${
              getPaidInvoiceSummary() < 0 ? 'text-red' : ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
            style={{
              whiteSpace: 'normal'
            }}
          />
          <DKLabel className="whitespace-nowrap ml-1" text="Paid" />
        </div>
        <div className="row justify-content-end">
          <DKLabel
            text={`<b>${Utility.getCurrencySymbolFromCode(
              tenantInfo.currency
            )} ${
              +getUnpaidInvoiceSummary() < 0
                ? '(' +
                  NumberFormatService.getNumber(getUnpaidInvoiceSummary()) +
                  ')'
                : NumberFormatService.getNumber(getUnpaidInvoiceSummary())
            }
          </b>`}
            className={`whitespace-normal ${
              getUnpaidInvoiceSummary() < 0 ? 'text-red' : ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
            style={{
              whiteSpace: 'normal'
            }}
          />
          <DKLabel className="whitespace-nowrap ml-1" text="Unpaid" />
        </div>
      </div>
      {parsedData && (
        <MultiBar
          data={parsedData}
          showLegend={true}
          tooltipValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
          displayValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
        />
      )}
    </div>
  );
}

export default InvoiceSummary;
