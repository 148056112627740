import {
  DKButton,
  DKLabel,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import Utility from '../../Utility/Utility';
import { checkIsLocalizedUomQty } from '../FulfillmentPopup/FulfilmentGridHelper';
import { TRACKING_TYPE } from '../../Constants/Constant';

export default function UpdateUOMSchemaForm(props: any) {
  const [uomValue, setUomValue] = useState<any>(0);
  const [baseQty, setBaseQty] = useState<any>(0);
  const [documentUOMSchemaDefinition, setDocumentUOMSchemaDefinition] =
    useState<any>(props.uomData?.rowData?.documentUOMSchemaDefinition);
  const [canValidate, setCanValidate] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let val = documentUOMSchemaDefinition.sourceConversionFactor
      ? Utility.roundOff(
          (documentUOMSchemaDefinition.sourceConversionFactor /
            documentUOMSchemaDefinition.sinkConversionFactor) *
            props.uomData?.rowData.productQuantity
        )
      : 0;
    setUomValue(val);
    let schemaDef = Utility.isNotEmpty(
      props.uomData?.rowData?.unlocalizedDocumentUOMSchemaDefinition
    )
      ? props.uomData?.rowData?.unlocalizedDocumentUOMSchemaDefinition
      : documentUOMSchemaDefinition;
    if (Utility.isNotEmpty(schemaDef)) {
      let baseQty = schemaDef.sourceConversionFactor
        ? Utility.roundOff(
            (schemaDef.sourceConversionFactor /
              schemaDef.sinkConversionFactor) *
              props.uomData?.rowData.productQuantity
          )
        : 0;
      setBaseQty(baseQty);
    }
  }, []);

  const isFormValid = () => {
    const isValid = true;
    if (
      props.uomData?.rowData?.product?.advancedTracking === TRACKING_TYPE.SERIAL
    ) {
      let sourceConversionFactor =
        documentUOMSchemaDefinition.sourceConversionFactor;
      if (props.uomData?.rowData?.productQuantity !== 0) {
        sourceConversionFactor = Utility.roundingOff(
          (uomValue * documentUOMSchemaDefinition.sinkConversionFactor) /
            props.uomData?.rowData?.productQuantity
        );
        if (!Number.isInteger(sourceConversionFactor)) {
          setShowError(true);
          return false;
        } else {
          setShowError(false);
        }
      }
    }

    if (Number(uomValue) <= 0 || Utility.isEmpty(uomValue?.toString())) {
      return false;
    }

    return isValid;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={'Update UOM Value'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() => {
              setCanValidate(true);
              if (isFormValid()) {
                onUpdateUOM();
              }
            }}
          />
        </div>
      </div>
    );
  };

  const onUpdateUOM = () => {
    let isLocalizedUomQty = false;
    let diff = 0;
    let sourceConversionFactor =
      documentUOMSchemaDefinition.sourceConversionFactor;
    if (props.uomData?.rowData?.productQuantity !== 0) {
      sourceConversionFactor = Utility.roundingOff(
        (uomValue * documentUOMSchemaDefinition.sinkConversionFactor) /
          props.uomData?.rowData?.productQuantity
      );
    }
    if (
      checkIsLocalizedUomQty(baseQty, uomValue)
      // documentUOMSchemaDefinition.sourceConversionFactor !==
      // sourceConversionFactor && diff !== 0
    ) {
      isLocalizedUomQty = true;
      diff = Utility.roundingOff(baseQty - uomValue);
    }
    props.onUpdateLocalizedUom({
      updatedSchema: {
        ...documentUOMSchemaDefinition,
        sourceConversionFactor: sourceConversionFactor
      },
      isLocalizedUomQty: isLocalizedUomQty,
      localizedBaseQtyDiff: diff
    });
  };

  const getTaxBody = () => {
    return (
      <div className="align-items-start pt-3">
        <div className=" align-items-start  parent-width">
          <div className="row">
            <DKLabel
              className="w-1/3"
              text={`${props?.uomData?.rowData?.uomQuantity} ${documentUOMSchemaDefinition?.name}  =`}
            />
            <DKInput
              title=""
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={uomValue}
              className=""
              required={true}
              canValidate={canValidate}
              onChange={(val: any) => {
                setUomValue(val);
              }}
              onBlur={(e: any) => {
                setUomValue(Utility.roundOff(Number(e.target.value)));
              }}
            />
            <DKLabel
              className="w-1/5"
              text={`  ${
                DocumentConfigUtility.getBaseUomName(
                  documentUOMSchemaDefinition
                ).name
              } `}
            />
          </div>
          <div className="row ml-20 pl-5">
            <DKLabel
              className="text-gray mt-2 fs-s"
              text={`1 ${
                documentUOMSchemaDefinition?.name
              } = ${Utility.roundingOff(
                (uomValue * documentUOMSchemaDefinition.sinkConversionFactor) /
                  props.uomData?.rowData?.productQuantity || 1
              )} ${
                DocumentConfigUtility.getBaseUomName(
                  documentUOMSchemaDefinition
                ).name
              }`}
            />
          </div>
          {showError && (
            <div className="column text-red  mt-1 fs-m">
              <span>
                For Serial Tracked Products, the UOM Conversion Schema should be
              </span>
              <span>a whole number, it cannot be in decimals</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 500,
            width: '90%',
            maxHeight: '85%',
            height: 200,
            padding: 0,
            paddingBottom: 10,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="p-h-xl parent-width overflow-y-auto">
            {getTaxBody()}
          </div>
        </div>
      </div>
    </>
  );
}
