import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
export interface TDSSetupAPIConfig {
  Limit?: number;
  Search?: string;
  Query?: string;
  TaxId?: any;
  Page?: number;
  Status?: string;
  Sort?: string;
  SortDir?: string;
}

const defaultConfig: TDSSetupAPIConfig = {
  Limit: 25,
  Query: '',
  Page: 0,
  Search: ''
};

class TDSService {
  static apiConfig: TDSSetupAPIConfig;

  static getTDSRates() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${this.apiConfig.Search}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    const finalEndpoint: string =
      ApiConstants.URL.INDIA_TDS.TDS_RATES + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading tds setup: ', err);
      return Promise.reject(err);
    });
  }

  static createTDSRate(data: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.TDS_RATES;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateTDSRate(data: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.TDS_RATES;

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteTDSRates(params: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.TDS_RATES;

    const headers = {
      'Content-Type': 'application/json'
    };

    return http
      .delete(finalEndPoint, { data: params, headers: headers })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getNatureOfPayment() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${this.apiConfig.Search}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    const finalEndpoint: string =
      ApiConstants.URL.INDIA_TDS.NATURE_OF_INCOME + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading nature of income: ', err);
      return Promise.reject(err);
    });
  }

  static createTDSNatureOfIncome(data: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.NATURE_OF_INCOME;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteTDSNaturePayment(params: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.NATURE_OF_INCOME;

    const headers = {
      'Content-Type': 'application/json'
    };

    return http
      .delete(finalEndPoint, { data: params, headers: headers })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static fetchTaxAccount(params: String[]) {
    return http.post(ApiConstants.URL.INDIA_TDS.ACCOUNT_CODES, params);
  }
  static getTDSRateDetails(params: any) {
    return http.get(ApiConstants.URL.INDIA_TDS.TDS_RATES_DETAIL, {
      params
    });
  }
  static getDeducteeType() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${this.apiConfig.Search}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    const finalEndpoint: string =
      ApiConstants.URL.INDIA_TDS.DEDUCTEE_TYPE + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading deductee type: ', err);
      return Promise.reject(err);
    });
  }

  static createTDSDeducteeType(data: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.DEDUCTEE_TYPE;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteTDSDeducteeType(params: any) {
    const finalEndPoint: string = ApiConstants.URL.INDIA_TDS.DEDUCTEE_TYPE;

    const headers = {
      'Content-Type': 'application/json'
    };

    return http
      .delete(finalEndPoint, { data: params, headers: headers })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default TDSService;
