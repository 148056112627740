import React, { Component } from "react";
import "../../index.css";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getIsBill, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsOrder, getIsProductDocumentForBuySell, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class IndiaTemplate8 extends Component { //based on template 1 for more spacing  https://deskera.atlassian.net/browse/BOOK-3182

    arabicHeaderWidth = '85%'
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="ColumnDiv" style={{ color: this.state.data.themeColor, width: '100%' }}>
                <div className='ColumnDiv'
                    style={{
                        width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
                    }}>
                    {this.state.data.showDocumentType && this.getDocumentType()}
                    {this.state.data.showCompanyName && this.getCompanyName()}
                </div>

                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {
                    right: 0,
                    top: this.state.data.showEInvoiceSection ? undefined : 0,
                })}
            </div>
        );
    }

    getDocumentType() {
        return <div className="RowDiv"
            style={{
                alignItems: 'center',
                marginBottom: getVW(20 * this.spacingRatio),
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "rgb(100,100,100)",
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge },this.state.data.printScale)
                }}>
                {getLocalisedText(this.state.data.documentType)}
            </text>
        </div>;
    }

    getCompanyName() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: Utility.getIsArabicLang() ? '100%' : '70%',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                    }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{}}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        getLocalisedText(this.state.data.termsAndCondition),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        undefined,
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}

                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        getLocalisedText(this.state.data.notes),
                        "100%",
                        "gray",
                        undefined,
                        undefined,
                        undefined,
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30 * this.spacingRatio) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.addCompanyNameAndDocTypeLabels()}
                    {this.state.data.showFrom && this.getCompanyAddressSection()}
                    {ComponentManager.addVerticalSpace(40 * this.spacingRatio)}
                    {/* section 2 */}
                    {this.getBillingShippingAndDocumentInfoSection()}
                    {/* section 3 */}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data) || Utility.getIsShowPlaceOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                    {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                    {ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                    {getIsProductDocumentForBuySell(this.state.data) &&
                        Utility.getIsShowHsnSacTaxTable() &&
                        <DKIndiaTaxBreakdown
                            data={this.state.data}
                        />
                    }
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(15, this.state.data.printScale * this.spacingRatio))}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width'>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} isThemeColor />}
                    {this.addTermsNConditionsView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getGeneratedVisibility() && ComponentManager.getGenerateDocumentSection(this.state.data, this.spacingRatio)}
            </div>
        );
    }

    getBillingShippingAndDocumentInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getAddressSection()}
                {this.getDocumentInfoSection()}
            </div>
        )
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <div
                    className="RowDiv"
                    style={{
                        alignItems: "flex-start",
                        justifyContent: 'space-between',
                        backgroundColor: "white",
                    }}
                >
                    {this.getPlaceOfSupply()}

                </div> </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }
    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '52%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getDocumentInfoSection() {
        return <div
            className="ColumnDiv"
            style={{ width: "30%", backgroundColor: "white" }}
        >
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getAddressSection() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.billToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "12%" }} />}
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.shipToTitle,
                    Utility.getClientShipToAddress(this.state.data),
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6 * this.spacingRatio),
                    Utility.getOwnerType(this.state.data, AddressType.shipTo),
                    this.state.data.printScale
                )}
                {/* {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerPhoneNumber,
                    "100%",
                    this.state.data.themeColor
                )}
                {ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.customerGSTIN,
                    "100%",
                    this.state.data.themeColor
                )} */}
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    "",
                    this.state.data.shipToAddressStateCode,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    undefined,
                    0,
                    undefined,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    getCompanyAddressSection() {
        return <>
            {ComponentManager.getDescriptionSection(
                "",
                Utility.getCompanyAddress(this.state.data),
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '40%',
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(6 * this.spacingRatio),
                Utility.getOwnerType(this.state.data, AddressType.from),
                this.state.data.printScale
            )}
            {/* {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyPhoneNumber,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )}
            {ComponentManager.getDescriptionSection(
                "",
                this.state.data.companyGSTIN,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '25%',
                this.state.data.themeColor
            )} */}
            {ComponentManager.getDescriptionSection(
                "",
                (getIsOrder(this.state.data.type) || getIsBill(this.state.data.type)) ? this.state.data.billOrderCompanyStateNameCode : this.state.data.companyStateNameAndCode,
                Utility.getIsArabicLang() ? this.arabicHeaderWidth : '40%',
                this.state.data.themeColor,
                false,
                undefined,
                0,
                undefined,
                this.state.data.printScale
            )}
        </>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['20%', '30%', '50%'] : ['36%', '34%', '30%']
        return style;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 13,
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 13,
        };

        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(style, data)}
                </div>
            </div>
        );
    }
}
