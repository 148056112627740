import { useEffect, useState } from 'react';
import { DKButton, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import {
  APPROVAL_STATUS,
  INPUT_VIEW_DIRECTION,
  POPUP_TYPE
} from '../../Constants/Constant';
import PopupWrapper from '../../SharedComponents/PopupWrapper';

interface ApprovalOrReject {
  data: any;
  actionData: any;
  setShowApprovalOrRejectPopup: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const ApprovalOrReject: React.FC<ApprovalOrReject> = (props) => {
  const [remarksData, setRemarksData] = useState('');
  const [error, setError] = useState('');
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const { title } = props.data;

  const status =
    title === 'Approve' ? APPROVAL_STATUS.APPROVED : APPROVAL_STATUS.REJECTED;
  const handleAction = () => {
    setIsSubmitAttempted(true);
    if (title === 'Reject' && !remarksData.trim()) {
      setError('Remark is required');
      return;
    }

    setError(''); // Clear the error once validation passe
    props.setShowApprovalOrRejectPopup(false);
    if (props.actionData.isBulkAction) {
      props.actionData.action(props.actionData.selection, remarksData);
      return;
    }
    props.actionData.action(props.data.actionDetails, status, remarksData);
    props.setShowApprovalOrRejectPopup(false);
  };
  const handleRemarksChange = (text: string) => {
    setRemarksData(text);
    setError('');
  };
  const styleForButton =
    title === 'Approve'
      ? 'mr-r bg-button text-white'
      : 'mr-r bg-red text-white';

  return (
    <PopupWrapper
      clickAction={() => {
        props.setShowApprovalOrRejectPopup(false);
      }}
      type={POPUP_TYPE.POPUP}
      title={props.title}
      height={'auto'}
      width={'35%'}
      btnList={[]}
      overflowVisible={true}
    >
      <div>
        <DKInput
          type={INPUT_TYPE.LONG_TEXT}
          title={'Remarks'}
          value={remarksData}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={handleRemarksChange}
          canValidate={isSubmitAttempted && title === 'Reject'}
          errorMessage={error}
        />

        <div className="mb-4">
          <div className="row mt-xl justify-content-between ">
            <DKButton
              title={title}
              className={`${styleForButton} ml-2`}
              onClick={handleAction}
              disabled={props.data.title === 'Reject' && Boolean(error)}
            />
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-r"
              onClick={() => {
                props.setShowApprovalOrRejectPopup(false);
              }}
            />
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default ApprovalOrReject;
