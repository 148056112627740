import { DOC_TYPE, POPUP_CALLBACKS_TYPE } from '../../Constants/Constant';
import { useEffect, useState } from 'react';
import { CallBackPayloadType } from '../../Models/Interfaces';

interface DropshipProps {
  document: any;
  passingInteraction?: (callback: CallBackPayloadType, data: any) => void;
  documentType: DOC_TYPE;
}

const DropshipWarning: React.FC<DropshipProps> = (props) => {
  const [document, setDocument] = useState(props.document);
  const [docType, setDocType] = useState(props.documentType);
  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction) {
      props.passingInteraction(
        {
          type: POPUP_CALLBACKS_TYPE.CONFIRM_DROPSHIP
        },
        document
      );
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const setPopupTitle = (docType: DOC_TYPE) => {
    return docType === DOC_TYPE.INVOICE
      ? 'invoice'
      : DOC_TYPE.SALES_ORDER
      ? 'sales order'
      : 'quotation';
  };

  return (
    <div className="p-4">
      <span className="text-1xl">
        You have selected Drop Ship for this {setPopupTitle(docType)} document.
        This will create a Purchase Order for fulfillment of this
        {setPopupTitle(docType)}. Do you wish to continue?
      </span>
    </div>
  );
};

export default DropshipWarning;
