import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
// import PurchaseOrderService from '../../Services/PurchaseOrder';
import RequisitionService from '../../Services/Requisition';

import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchOrders = createAsyncThunk('requisition', async () => {
  const response = await RequisitionService.getOrdersByPage();
  return response;
});

export const RequisitionSlice = createSlice({
  name: 'requisition',
  initialState,
  reducers: {
    requisitionColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key:
            config.key === 'documentSequenceCode' || config.key === 'shipByDate'
              ? config.key
              : config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = [...configsToStore];
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.PURCHASE_ORDER
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToRequisitionColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateRequisitionDetails: (state, action: PayloadAction<any>) => {
      const orderDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const orderIndex: number = content.findIndex(
        (contact: any) => contact.id === orderDetails.id
      );
      if (orderIndex >= 0) {
        content[orderIndex] = orderDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data.requisition = action.payload;
      });
  }
});

export const {
  addColumnToRequisitionColumnConfig,
  requisitionColumnConfig,
  updateColumnConfig,
  updateRequisitionDetails,
  updateRefreshCallback
} = RequisitionSlice.actions;

export const selectPurchaseOrders = (state: RootState) =>
  state.requisition.data;

export const selectPurchaseOrdersLoadingStatus = (state: RootState) =>
  state.requisition.gridContentLoadingStatus;

export const selectPurchaseRequisitionColumnConfig = (state: RootState) =>
  state.requisition.columnConfig;

export const selectPurchaseRequisitionColumnConfigTableId = (
  state: RootState
) => state.requisition.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.requisition.refreshCallback;

export default RequisitionSlice.reducer;
