import React, { Component } from 'react';
import { getLocalisedText } from '../../Translate/LanguageManager';


// Props for PayslipSection
// header
// table header
// table data

export const HORIZONTAL_LINE_TYPE = {
    header_bottom: "header_bottom",
    data_last_row_top: "data_last_row_top",
    data_last_row_bottom: "data_last_row_bottom",
    none: "none"
};

export default class PayslipSection extends Component {
    render() {
        return <>
            {this.renderTitle()}
            {this.renderTable()}
        </>
    }

    renderTitle() {
        return <>
            <div
                style={{
                    boxSizing: 'border-box',
                    fontWeight: '700',
                    // paddingTop: 10,
                    marginTop: 20,
                    marginRight: 2,
                    marginLeft: 2,
                    fontSize: 12,
                    color: this.getTitleTextColor(),
                    textAlign: this.props.header ? this.props.header.textAlign : 'left'
                }}
            >
                {this.props.header.value}
            </div>
            <div style={{
                height: 1,
                paddingTop: 1,
                paddingBottom: 1,
                backgroundColor: this.getBorderColor(),
                opacity: this.getBorderColorOpacity(),
            }}>
            </div>
        </>
    }

    renderTable() {
        return <table style={{
            boxSizing: 'border-box',
            width: '100%',
            borderCollapse:'collapse',
        }}>
            <thead>
                <tr>
                    {this.renderTableHeaderRow()}
                </tr>
            </thead>
            <tbody>
                {this.renderHorizontalLine(HORIZONTAL_LINE_TYPE.header_bottom)}
                {this.renderTableDataRow()}
                {this.renderHorizontalLine(HORIZONTAL_LINE_TYPE.data_last_row_bottom)}
            </tbody>
        </table>
    }

    getLastRowBottomBorderColor(type) {
        if(type === HORIZONTAL_LINE_TYPE.data_last_row_bottom) {
            if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
                if (this.props.tableStyle.LastRowBottomBorderColor !== undefined && this.props.tableStyle.LastRowBottomBorderColor !== null) {
                    return this.props.tableStyle.LastRowBottomBorderColor
                }
            }
        }
        else if (type === HORIZONTAL_LINE_TYPE.data_last_row_top) {
            if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
                if (this.props.tableStyle.LastRowTopBorderColor !== undefined && this.props.tableStyle.LastRowTopBorderColor !== null) {
                    return this.props.tableStyle.LastRowTopBorderColor
                }
            }
        }
        return this.getBorderColor()
    }


    getLastRowBottomBorderColorOpacity(type) {
        if (type === HORIZONTAL_LINE_TYPE.data_last_row_bottom) {
            if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
                if (this.props.tableStyle.LastRowBottomBorderColorOpacity !== undefined && this.props.tableStyle.LastRowBottomBorderColorOpacity !== null) {
                    return this.props.tableStyle.LastRowBottomBorderColorOpacity
                }
            }
            return 1
        }
        else if (type === HORIZONTAL_LINE_TYPE.data_last_row_top) {
            if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
                if (this.props.tableStyle.LastRowTopBorderColorOpacity !== undefined && this.props.tableStyle.LastRowTopBorderColorOpacity !== null) {
                    return this.props.tableStyle.LastRowTopBorderColorOpacity
                }
            }
            return 1
        }
        return this.getBorderColorOpacity()
    }

    getLastRowBottomBorderWidth(type) {
        if (type === HORIZONTAL_LINE_TYPE.data_last_row_bottom) {
            if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
                if (this.props.tableStyle.LastRowBottomBorderWidth !== undefined && this.props.tableStyle.LastRowBottomBorderWidth !== null) {
                    return this.props.tableStyle.LastRowBottomBorderWidth
                }
            }
        }
        return 1
    }

    renderHorizontalLine(type) {
        return <tr>
            <td colSpan={this.getColSpanCount()} style={{
                padding: 0,
            }}>
                <div style={{
                    height: this.getLastRowBottomBorderWidth(type),
                    paddingTop: 1,
                    paddingBottom: 1,
                    backgroundColor: this.getLastRowBottomBorderColor(type),
                    opacity: this.getLastRowBottomBorderColorOpacity(type)
                }}>
                </div>
            </td>
        </tr>
    }

    renderTableHeaderRow() {
        //this is header row
        var headerList = this.props.headerList

        if(headerList === undefined || headerList === null) {
            return <th></th>
        }

        if(headerList.length === 0) {
            return <th></th>
        }

        var widthSize = 100 / headerList.length  + '%'
        return headerList.map((element, index) => {
            return this.renderTableHeader(element, index, this.getHeaderStyle(index, 'width', widthSize), index === 0)
        });
    }

    renderTableHeader(element, index, widthSize, firstIndex ) {
        return <td style={{
            boxSizing: 'border-box',
            fontSize: 12,
            fontWeight: 300,
            color: this.getHeaderTextColor(),
            opacity: this.getHeaderTextColorOpacity(),
            backgroundColor: this.getHeaderBackgroundColor(),
            width: widthSize,
            textAlign: firstIndex ? 'left' : this.getHeaderStyle(index, 'textAlign', 'right')
        }}>
            <div style={{
                boxSizing: 'border-box',
                paddingTop: 10,
                fontWeight: 400,
            }}>
                {getLocalisedText(element).toUpperCase()}
            </div>
        </td>
    }

    renderTableDataRow() {
        var data = this.props.data

        if (data === undefined || data === null) {
            return <tr></tr>
        }

        if (data.length === 0) {
            return <tr></tr>
        }
        else if (data.length > 0) {
            var rows = data.map(rowData => {
                return this.renderTableData(rowData)
            });
            return this.renderAdditionalTopBorderLine(rows)
        }
        else {
            return <tr></tr>
        }
    }

    renderAdditionalTopBorderLine(rowData) {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.LastRowTopBorderColor !== undefined && this.props.tableStyle.LastRowTopBorderColor !== null) {
                var newRow = []
                rowData.forEach((element, index) => {
                    if(index === rowData.length - 1) {
                        newRow.push(this.renderHorizontalLine(HORIZONTAL_LINE_TYPE.data_last_row_top))
                    }
                    newRow.push(element)
                });

                return newRow
            }
        }

        return rowData
    }

    renderTableData(rowData) {
        if (rowData === undefined || rowData === null) {
            return
        }
        if (rowData.length === 0) {
            return
        }

        var widthSize = 100 / rowData.length + '%'

        var rowRecords = rowData.map((element, index) => {
            return this.renderTableDataColumn(element, index, this.getHeaderStyle(index, 'width', widthSize), index === 0)
        });

        return (<tr>
            {rowRecords}
        </tr>)
    }

    renderTableDataColumn(columnData, index, widthSize, firstIndex ) {
        var isHighlighted = false
        if (this.props.highlighted !== undefined && this.props.highlighted !== null) {
            if(this.props.highlighted.length > 0) {
                isHighlighted = this.props.highlighted.includes(columnData)
            }
        }
        return <td style={{
            boxSizing: 'border-box',
            fontSize: isHighlighted ? 16 : 12,
            fontWeight: 300,
            color: 'rgb(111, 119, 125)',
            width: widthSize,
            textAlign: firstIndex ? 'left' : this.getHeaderStyle(index, 'textAlign', 'right')
        }}>
            <div style={{
                boxSizing: 'border-box',
                color: 'rgb(0, 0, 0)',
                fontWeight: isHighlighted ? 700 : 400,
                paddingTop: 2,
                paddingBottom: 2,
            }}>
                {columnData}
            </div>
        </td>
    }

    getColSpanCount() {
        var headerList = this.props.headerList

        if (headerList === undefined || headerList === null) {
            return 0
        }
        return headerList.length
    }

    getHeaderStyle(index, key, defaultValue) {
        if (this.props.headerStyle === undefined || this.props.headerStyle === null) {
            return defaultValue
        }

        if(this.props.headerStyle.length > index) {
            var value = this.props.headerStyle[index][key]
            if (value !== undefined && value !== null) {
                return value
            }
        }
        return defaultValue
    }

    getBorderColor() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.borderColor !== undefined && this.props.tableStyle.borderColor !== null) {
                return this.props.tableStyle.borderColor
            }
        }
        return 'rgba(0, 0, 0, 0.3)'
    }

    getBorderColorOpacity() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.borderColorOpacity !== undefined && this.props.tableStyle.borderColorOpacity !== null) {
                return this.props.tableStyle.borderColorOpacity
            }
        }
        return 1
    }

    getTitleTextColor() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.titleColor !== undefined && this.props.tableStyle.titleColor !== null) {
                return this.props.tableStyle.titleColor
            }
        }
        return undefined
    }

    getHeaderTextColor() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.headerColor !== undefined && this.props.tableStyle.headerColor !== null) {
                return this.props.tableStyle.headerColor
            }
        }
        return 'rgb(111, 119, 125)'
    }

    getHeaderTextColorOpacity() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.headerColorOpacity !== undefined && this.props.tableStyle.headerColorOpacity !== null) {
                return this.props.tableStyle.headerColorOpacity
            }
        }
        return 1
    }

    getHeaderBackgroundColor() {
        if (this.props.tableStyle !== undefined && this.props.tableStyle !== null) {
            if (this.props.tableStyle.headerBackgroundColor !== undefined && this.props.tableStyle.headerBackgroundColor !== null) {
                return this.props.tableStyle.headerBackgroundColor
            }
        }
        return 'white'
    }
}