import { DKButton, DKLabel, DKInput, INPUT_TYPE } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { INPUT_VIEW_DIRECTION, STATUS_TYPE } from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchContacts,
  selectContacts
} from '../../../Redux/Slices/ContactsSlice';
import ContactService, { ContactAPIConfig } from '../../../Services/Contact';
import Utility from '../../../Utility/Utility';

export default function AddTaxAdjustmentDetails(props: any) {
  //state
  const [contact, setContact] = useState<any>();
  const [taxDetails, setTaxDetails] = useState<any>();

  //selectors
  const contactsData = useAppSelector(selectContacts);

  const dispatch = useAppDispatch();

  //effects
  useEffect(() => {
    if (!contactsData?.content && props.showContactField) {
      dispatch(fetchContacts());
    }

    if (!Utility.isEmpty(props.taxAdjustmentDetails)) {
      if (
        props.taxAdjustmentDetails.contactCode !== '' &&
        contactsData &&
        contactsData.content &&
        contactsData.content.length > 0
      ) {
        const contactToDisplay = contactsData?.content.find((item: any) => {
          return item.code === props.taxAdjustmentDetails.contactCode;
        });
        setContact(contactToDisplay);
      }
      setTaxDetails(props.taxAdjustmentDetails);
    }
  }, []);

  useEffect(() => {
    if (
      props?.taxAdjustmentDetails?.contactCode !== '' &&
      contactsData &&
      contactsData.content &&
      contactsData.content.length > 0
    ) {
      const contactToDisplay = contactsData?.content.find((item: any) => {
        return item.code === props?.taxAdjustmentDetails?.contactCode;
      });
      setContact(contactToDisplay);
    }
  }, [contactsData?.content]);

  const getContactForm = () => {
    const contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || null;
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Contact'}
        direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
        value={contact?.name}
        onChange={(contactObj: any) => {
          setContact(contactObj);
        }}
        dropdownConfig={{
          title: 'Contact',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: ContactAPIConfig = {
                ...ContactService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 20,
                IncludeOpeningAmounts: false,
                IncludeOweAmounts: false,
                Query: 'status=active'
              };
              ContactService.apiConfig = config;
              return ContactService.getContactsApiUrl();
            },
            dataParser: (response: any) => {
              return response?.content || [];
            },
            debounceTime: 300
          },
          data: contactList ? contactList : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }}
      />
    );
  };

  type onChange = (value: string) => any;
  const getTextFieldWithCallback = (
    title: string,
    value: any,
    type: string,
    onChange: onChange,
    required = false
  ) => (
    <div className="parent-width text-align-left mt-r">
      <DKInput
        required={required}
        hasError={false}
        type={type}
        title={title}
        value={value}
        placeholder={title}
        onChange={(text: any) => onChange(text)}
      />
    </div>
  );

  const setInputField = (key: any, val: any) => {
    setTaxDetails({
      ...taxDetails,
      [key]: val
    });
  };

  const getHeader = () => {
    return (
      <div
        className="row justify-content-between p-h-r p-v-s bg-gray1"
        style={{ height: 60 }}
      >
        <div className="row width-auto">
          <DKLabel text="Add Tax Adjustment Details" className="fw-m fs-l" />
        </div>
        <div className="row width-auto"></div>
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div
        className="row justify-content-between border-t-2 px-3"
        style={{ height: 60 }}
      >
        <div className="row width-auto"></div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="mr-r bg-gray-100"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Save'}
            className="text-white bg-button"
            onClick={() => {
              props.onSave({
                taxDetails: {
                  ...taxDetails,
                  contactCode: contact?.code
                }
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          maxWidth: 520,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          height: 400,
          padding: 0,
          position: 'fixed'
        }}
      >
        {getHeader()}
        <div className="p-4 parent-width column overflow-y-scroll">
          {props.showContactField && getContactForm()}
          {getTextFieldWithCallback(
            'Tax Rate',
            taxDetails?.taxRate,
            INPUT_TYPE.NUMBER,
            (value) => {
              setInputField('taxRate', value);
            }
          )}
          {getTextFieldWithCallback(
            'Taxable Value',
            taxDetails?.taxableAmount,
            INPUT_TYPE.TEXT,
            (value) => {
              setInputField('taxableAmount', value);
            }
          )}
          {getTextFieldWithCallback(
            'CGST',
            taxDetails?.cgstAmount,
            INPUT_TYPE.NUMBER,
            (value) => {
              setInputField('cgstAmount', value);
            }
          )}
          {getTextFieldWithCallback(
            'SGST',
            taxDetails?.sgstAmount,
            INPUT_TYPE.NUMBER,
            (value) => {
              setInputField('sgstAmount', value);
            }
          )}
          {getTextFieldWithCallback(
            'IGST',
            taxDetails?.igstAmount,
            INPUT_TYPE.NUMBER,
            (value) => {
              setInputField('igstAmount', value);
            }
          )}
          {getTextFieldWithCallback(
            'CESS',
            taxDetails?.cessAmount,
            INPUT_TYPE.NUMBER,
            (value) => {
              setInputField('cessAmount', value);
            }
          )}
        </div>
        {getFooter()}
      </div>
    </div>
  );
}
