import React from 'react';
import NumberFormatService from '../Services/NumberFormat';
import Utility from '../Utility/Utility';

export interface AmountProps {
  value: any;
  currencyCode: string;
}

export default function FormatAmount(props: AmountProps) {
  return (
    <>
      <span
        className={`mr-1 inline-block ${props.value < 0 ? 'text-red' : ''}`}
      >
        {Utility.getCurrencySymbolFromCode(props.currencyCode)}
      </span>
      <span className={`${props.value < 0 ? 'text-red' : ''}`}>
        {props.value < 0 ? '(' : ''}
        {NumberFormatService.getNumber(Math.abs(props.value))}
        {props.value < 0 ? ')' : ''}
      </span>
    </>
  );
}
