import ApiConstants from '../Constants/ApiConstants';
import { LocationDTO } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface LocationAPIConfig {
  Limit?: number;
  Page?: number;
  Query?: any;
}

const defaultConfig: LocationAPIConfig = {
  Limit: 10,
  Page: 0,
  Query: 'isSystem=true'
};

class LocationService {
  static apiConfig: LocationAPIConfig = defaultConfig;

  static createLocation(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.LOCATIONS.CREATE_LOCATION;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to post status: ', err);
      return Promise.reject(err);
    });
  }
  static updateLocation(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.LOCATIONS.UPDATE_LOCATION(
      payload.id
    );
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to put status: ', err);
      return Promise.reject(err);
    });
  }

  static getLocationAndClassDimensions() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;

    const finalEndpoint: string =
      ApiConstants.URL.LOCATIONS.GET_LOCATION_CLASS_DIMENSIONS + queryString;

    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAllLocations() {
    const finalEndpoint: string = ApiConstants.URL.LOCATIONS.GET_LOCATION();
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static async getLocationByLabel(label: string): Promise<LocationDTO> {
    const config = {
      headers: {
        'Content-Type': 'text/plain'
      }
    };
    const finalEndpoint: string =
      ApiConstants.URL.LOCATIONS.GET_LOCATION_BY_LABEL;
    try {
      return await http.post(`${finalEndpoint}`, label, config);
    } catch (err) {
      console.error('Error while fetching location by label: ', err);
      return await Promise.reject(err);
    }
  }

  static deleteLocation(storeId: any) {
    const finalEndpoint: string =
      ApiConstants.URL.LOCATIONS.DELETE_LOCATION(storeId);
    return http
      .delete(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default LocationService;
