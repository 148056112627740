import { CallBackPayloadType } from '../../Models/Interfaces';
import {
  DKIcon,
  DKLabel,
  showToast,
  TOAST_TYPE,
  showLoader,
  removeLoader,
  DKInput
} from 'deskera-ui-library';
import {
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import { useEffect, useState } from 'react';
import { deepClone } from '../../Utility/Utility';
import PaymentService from '../../Services/Payment';
interface AddEmailProps {
  payload: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

interface addWareHouseState {
  email: string;
  payload: any;
}

const initialState = {
  email: '',
  payload: {}
};

const AddEmailPopUp: React.FC<AddEmailProps> = (props) => {
  useEffect(() => {
    const state = formState;
    if (props.payload) {
      state.payload = props.payload;
    }
    setFormState({ ...state });
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SEND_PAYMENT_MAIL,
        data: () => {
          payloadData();
        }
      });
    }
  };

  const payloadData = () => {
    const payload = formState.payload;
    PaymentService.emailPayment(payload)
      .then((response) => {
        showToast('Mail sent successfully', TOAST_TYPE.SUCCESS);
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
      })
      .catch((error) => {
        console.error('Error sending mail: ', error);
      });
  };

  const updateInputField: any = (event: any) => {
    const newState = formState;
    if (newState) {
      newState.email = event;
      newState.payload.to = event;
    }
    setFormState({ ...newState });
  };

  const [formState, setFormState] = useState<addWareHouseState>(initialState);
  return (
    <div>
      <div className="p-1 flex justify-content-start ml-1">
        <DKInput
          title="Please provide the contact’s email address"
          required={true}
          placeholder={'Email Address'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(event: any) => updateInputField(event)}
          value={formState?.email}
        />
      </div>
    </div>
  );
};

export default AddEmailPopUp;
