import { useEffect, useRef, useState } from 'react';
import {
  DKDataGrid,
  INPUT_TYPE,
  DKIcons,
  showAlert,
  DKIcon,
  DKLabel
} from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import ic_no_data from '../../Assets/Icons/ic_no_data.png';
import {
  CP_USERS_COLUMN,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE
} from '../../Constants/ClientPortal';
import debounce from '../../Utility/Debounce';
import {
  fetchClientPortalUsers,
  selectClientPortalUsers
} from '../../Redux/Slices/ClientPortalSlice';
import Utility, { deepClone } from '../../Utility/Utility';
import ClientPortalService, {
  deleteUser,
  updateUserStatus
} from '../../Services/ClientPortal';
import useScreenResize from '../../Hooks/useScreenResize';
type TUsersProps = {};

const Users: React.FC<TUsersProps> = () => {
  const [searchText, setSearchText] = useState<string>();
  const [showEmptyState, setShowEmptyState] = useState<boolean>();
  const dispatch = useAppDispatch();

  const [pagination, setPagination] = useState<any>({
    page: DEFAULT_PAGE_NO,
    limit: DEFAULT_PAGE_SIZE,
    sortDir: 1,
    sortCol: 'email'
  });

  const usersData = useAppSelector(selectClientPortalUsers);
  const [updating, setUpdating] = useState<boolean>(false);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(gridContainerRef);
  useEffect(() => {
    fetchUsersData();
  }, [pagination, searchText]);

  useEffect(() => {
    setShowEmptyState(Utility.isEmptyObject(usersData?.data));
  }, [usersData]);

  const parseRowData = () => {
    const rows = deepClone(usersData?.data || [])?.map((item: any) => ({
      ...item,
      rowContextMenu: getRowContextMenu(item),
      inactive: [!!item.inactive]
    }));

    return rows;
  };

  const onDelete = ({ rowData }: any) => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Delete',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          deleteUser(rowData._id).then((res: any) => {
            fetchUsersData();
          });
        }
      }
    ];
    showAlert(
      'Confirm Delete',
      'Are you sure, you want to delete this user?',
      buttons
    );
  };

  const getColumns = () => {
    const columns: any = deepClone(CP_USERS_COLUMN);
    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 140,
      options: [
        {
          icon: DKIcons.ic_delete,
          className: ' p-0',
          onClick: onDelete
        }
      ]
    });
    return columns;
  };

  const getRowContextMenu = (row: any) => {
    const contextMenu = [];
    contextMenu.push({
      title: 'Delete',
      icon: DKIcons.ic_delete,
      onClick: (row: any) => {
        onDelete(row);
      }
    });
    contextMenu.push({
      title: 'Resend Invite',
      icon: DKIcons.ic_support,
      onClick: (row: any) => {
        resendInvite(row);
      }
    });
    return contextMenu;
  };

  const fetchUsersData = () => {
    setUpdating(true);
    dispatch(
      fetchClientPortalUsers({
        q: searchText ?? '',
        sortDir: pagination.sortDir,
        pageSize: pagination.limit,
        sortCol: pagination.sortCol,
        pageNo: Utility.isNotEmpty(searchText) ? 1 : pagination.page
      })
    ).finally(() => {
      setUpdating(false);
    });
  };

  const onSearch = debounce((searchQuery: string) => {
    setSearchText(searchQuery);
  }, 500);

  const onPageChange = (page: number) => {
    setPagination({ ...pagination, page: page });
  };

  const onRowUpdate = ({ rowData, rowIndex, columnData }: any) => {
    if (columnData.key === 'inactive') {
      updateUserStatus([rowData._id], rowData.inactive[0]).finally(() => {
        fetchUsersData();
      });
    }
  };

  const onSort = ({ columnData, order }: any) => {
    setPagination({
      ...pagination,
      sortCol: columnData.key,
      sortDir: order === 'DESC' ? -1 : 1
    });
  };

  const getNoDataView = () => {
    return (
      <div
        className="column align-items-center justify-content-center parent-width position-absolute"
        style={{ pointerEvents: 'none', top: 120 }}
      >
        <DKIcon src={ic_no_data} className="ic-l" style={{ opacity: 0.2 }} />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };

  const resendInvite = ({ rowData }: any) => {
    ClientPortalService.resendInvite({
      booksContactCode: rowData.booksContactCode
    }).then((res: any) => {
      if (res?.success) {
        showAlert(
          'Invitation Sent!',
          `Invitation has been sent to customer's email address with username, password and domain details to access client portal.`
        );
      }
    });
  };

  return (
    <div
      className="column parent-size position-relative"
      ref={gridContainerRef}
      style={{ lineHeight: '16px' }}
    >
      <DKDataGrid
        title={'Invited Users'}
        documentType={'Users'}
        rows={parseRowData() || []}
        columns={getColumns()}
        width={width}
        needBoldTheme={false}
        needTrailingColumn={true}
        needShadow={false}
        needBorder={true}
        allowColumnShift={false}
        allowSearch={true}
        onSearch={onSearch}
        allowFilter={false}
        currentPage={pagination.page}
        totalPageCount={usersData?.totalPages}
        onPageChange={onPageChange}
        allowRowEdit={true}
        updating={updating}
        onRowUpdate={onRowUpdate}
        allowBulkOperation={false}
        onSort={onSort}
      />
      {showEmptyState && getNoDataView()}
    </div>
  );
};

export default Users;
