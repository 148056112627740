import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import Utility from '../Utility/Utility';
import httpClient from './Interceptor';
import http from './Interceptor';

export interface IRateAnalysisPayload {
  ENABLE_FOR_SALES_ORDER?: boolean;
  ENABLE_FOR_QUOTATION?: boolean;
  ENABLE_FOR_SALES_INVOICE?: boolean;
  PRODUCT_PRICE_METHOD?: string;
  COMMISSION_CHARRGES?: Property[];
}

interface Property {
  isPercent: boolean;
  label: string;
  value: number;
}

export const saveRateAnalysis = (
  payload: any
): Promise<IRateAnalysisPayload> => {
  const updatedPayload = {
    additionalSettings: {
      RATE_ANALYSIS: {
        ENABLE_FOR_SALES_ORDER: payload.ENABLE_FOR_SALES_ORDER || false,
        ENABLE_FOR_QUOTATION: payload.ENABLE_FOR_QUOTATION || false,
        ENABLE_FOR_SALES_INVOICE: payload.ENABLE_FOR_SALES_INVOICE || false,
        PRODUCT_PRICE_METHOD: payload.PRODUCT_PRICE_METHOD || 'FIFO',
        COMMISSION_CHARRGES: payload.COMMISSION_CHARRGES || []
      }
    }
  };

  return httpClient.patch(
    ApiConstants.URL.TENANT.UPDATE_ORG(
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.PATCH_TENANT_SETTINGS
      )
    ),
    updatedPayload
  );
};

class RateAnalysisService {
  static abortController: any = null;

  static getRateAnalysis(payload: any) {
    return http
      .post(`${ApiConstants.URL.RATE_ANALYSIS.FETCH_RATE_ANALYSIS}`, payload)
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static saveRateAnalysis(payload: any) {
    return http
      .post(`${ApiConstants.URL.RATE_ANALYSIS.SAVE_RATE_ANALYSIS}`, payload)
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static updateRateAnalysis(payload: any) {
    return http
      .put(
        `${ApiConstants.URL.RATE_ANALYSIS.UPDATE_DRAFT_RATE_ANALYSIS}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static getProductPurchasePrice(payload: any) {
    return http
      .post(
        `${ApiConstants.URL.RATE_ANALYSIS.GET_PRODUCT_PURCHASING_METHOD}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static delteDraftRateAnalysis(id: string) {
    return http
      .delete(
        `${ApiConstants.URL.RATE_ANALYSIS.DELETE_DRAFT_RATE_ANALYSIS(id)}`
      )
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }
}
export default RateAnalysisService;
