import { getLocalisedText } from "../Translate/LanguageManager"

export default class ExpenseMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('expense')
        label.contact = 'contact'
        label.customerAddressPlaceholder = 'customer_address_placeholder_text_value'
        label.accountNamePlaceholder = 'account_name_placeholder_text_value'
        label.fromAccount = 'from_account'
        label.paymentFrom = 'payment_from'
        label.paymentDate = 'payment_date'
        label.referenceDate = 'reference_date'
        label.accounts = 'accounts'
        label.accountName = 'account_name'
        label.accountDescription = 'account_description'
        label.taxAmount = 'tax_amount'
        label.paymentAmount = 'payment_amount'
        return label
    }
}