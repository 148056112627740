import React, { useEffect, useState } from 'react';
import PopupWrapper, { DynamicPopupWrapper } from './PopupWrapper';
import {
  BOOKS_DATE_FORMAT,
  E_INVOICE_RESPONSE_COLOR_MAP,
  E_INVOICE_RESPONSE_MAP,
  E_INVOICE_STATUS_REASON_MAP,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../Constants/Constant';
import { BtnType, IState } from '../Models/Interfaces';
import {
  DKButton,
  DKLabel,
  DKDataGrid,
  DKIcon,
  DKIcons
} from 'deskera-ui-library';
import DateFormatService from '../Services/DateFormat';
import { useAppSelector } from '../Redux/Hooks';
import { activeTenantInfo } from '../Redux/Slices/AuthSlice';
import Chip from './Chip';
import Utility from '../Utility/Utility';

type EinvoiceStatusLog = {
  seq: number;
  reason: string;
  status: keyof typeof E_INVOICE_RESPONSE_MAP;
  reasonCode: keyof typeof E_INVOICE_STATUS_REASON_MAP;
  statusDate: string;
};
type Props = {
  onCancel: () => void;
  data: EinvoiceStatusLog[];
};
const columnCodeMap = {
  SEQUENCE: 'seq',
  STATUS: 'status',
  STATUS_DATE: 'statusDate',
  REASON: 'reason',
  REASON_CODE: 'reasonCode'
};
const columnData = [
  {
    name: 'Date',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: columnCodeMap.STATUS_DATE,
    key: columnCodeMap.STATUS_DATE,
    id: columnCodeMap.STATUS_DATE,
    allowColumnSort: false
  },
  {
    name: 'Status',
    type: 'text',
    index: 1,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: columnCodeMap.STATUS,
    key: columnCodeMap.STATUS,
    id: columnCodeMap.STATUS,
    allowColumnSort: false,
    renderer: (obj: any) => {
      const data = obj.rowData[
        columnCodeMap.STATUS
      ] as keyof typeof E_INVOICE_RESPONSE_MAP;
      if (Utility.isEmpty(data)) {
        return <></>;
      }
      return <DKLabel text={`${data}  (${E_INVOICE_RESPONSE_MAP[data]})`} />;
    }
  },
  {
    name: 'Status Reason Code',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: columnCodeMap.REASON_CODE,
    key: columnCodeMap.REASON_CODE,
    id: columnCodeMap.REASON_CODE,
    allowColumnSort: false,
    renderer: (obj: any) => {
      const data = obj.rowData[
        columnCodeMap.REASON_CODE
      ] as keyof typeof E_INVOICE_STATUS_REASON_MAP;
      if (Utility.isEmpty(data)) {
        return <></>;
      }
      return (
        <DKLabel text={`${data}  (${E_INVOICE_STATUS_REASON_MAP[data]})`} />
      );
    }
  },
  {
    name: 'Reason',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: columnCodeMap.REASON,
    key: columnCodeMap.REASON,
    id: columnCodeMap.REASON,
    allowColumnSort: false
  }
];
const initialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

const EinvoiceStatusHistoryPopup = (props: Props) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [einvoiceStatusLog, setEinvoiceStatusLog] = useState(initialState);
  //   LifeCycle Methods
  useEffect(() => {
    if (props.data) {
      const data = [...props.data];

      const rowData = data
        .sort((a, b) => {
          const dateComparison =
            (new Date(a?.statusDate) as any) - (new Date(b?.statusDate) as any);

          if (dateComparison === 0) {
            return a?.seq - b?.seq;
          }

          return dateComparison;
        })
        .map((item, index) => {
          const date = new Date(item.statusDate);
          const formatedDate = DateFormatService.getFormattedDateAndTimeString(
            date,
            tenantInfo.dateFormat
          );

          return {
            seq: item.seq,
            reason: item.reason,
            status: item.status,
            statusDate: formatedDate,
            reasonCode: item.reasonCode
          };
        });
      setEinvoiceStatusLog({
        ...einvoiceStatusLog,
        rowData: rowData,
        originalData: rowData,
        columnData: columnData
      });
    }
  }, []);

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1, left: 0 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky "
      >
        <div className="row">
          <DKLabel text={'Invoice Response History'} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="Close"
            className="bg-gray1 border-m"
            onClick={props.onCancel}
          />
        </div>
      </div>
    );
  };
  const getNoDataView = () => {
    return (
      <div
        className={`column parent-size align-items-center justify-content-center mb-xl`}
        style={{ pointerEvents: 'none' }}
      >
        <DKIcon src={DKIcons.ic_no_data} className="ic-l opacity-20" />
        <DKLabel className="fs-m fw-m mt-l" text={'No data found'} />
        <DKLabel
          className="text-dark-gray mt-s text-align-center"
          text={'No data available for your selection.'}
        />
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 1078,
            width: '90%',
            height: '90%',
            maxHeight: '90%',
            borderRadius: 4,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          <div
            className="column parent-width flex-1 main-holder-padding padding-bottom-50"
            style={{ overflowY: 'auto' }}
          >
            <DKDataGrid
              allowRowEdit={false}
              allowColumnEdit={false}
              allowRowAdd={false}
              allowColumnAdd={false}
              allowColumnShift={true}
              allowSearch={false}
              allowFilter={false}
              refresh={false}
              rows={
                einvoiceStatusLog?.rowData?.length
                  ? einvoiceStatusLog.rowData
                  : []
              }
              columns={
                einvoiceStatusLog?.columnData?.length
                  ? einvoiceStatusLog.columnData
                  : []
              }
              actionColumnWidth={120}
              allowBulkOperation={false}
              needTrailingColumn={false}
            />
            {einvoiceStatusLog?.rowData?.length == 0 && getNoDataView()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default EinvoiceStatusHistoryPopup;
