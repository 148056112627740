import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { BulkDeleteResponse } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface PurchaseOrderAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  WithFilter?: any;
  WithoutFilter?: any;
  Sort?: string;
  SortDir?: string;
  SalesPerson?:number;
}

export const defaultConfig: PurchaseOrderAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  WithFilter: '',
  Sort: 'documentDate',
  SortDir: 'DESC',
  WithoutFilter: '',
  SalesPerson:0
};
class PurchaseOrderService {
  static apiConfig: PurchaseOrderAPIConfig;
  static abortController: any = null;

  static getOrdersByPage() {
    Utility.cancelRequest(PurchaseOrderService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
    );

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }${
      this.apiConfig.SalesPerson && this.apiConfig.SalesPerson !== 0
        ? '&salesperson=' + this.apiConfig.SalesPerson
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.ORDERS.FETCH_ORDERS + country + queryString;

    PurchaseOrderService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: PurchaseOrderService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createOrder(payload: any, country: string) {
    return http2
      .post(`${ApiConstants.URL.ORDERS.CREATE_ORDER}${country}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createBulkOrder(payload: any[], country: string) {
    return http2
      .post(`${ApiConstants.URL.ORDERS.BULK_CREATE_ORDER(country)}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchOrderDetails(billCode: string) {
    return http
      .get(`${ApiConstants.URL.ORDERS.FETCH_ORDER_DETAILS(billCode)}`)
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static updateOrder(payload: any, country: string) {
    const orderId: string = payload.id;
    return http2
      .put(`${ApiConstants.URL.ORDERS.UPDATE_ORDER(country, orderId)}`, payload)
      .catch((err: any) => {
        console.error('Error updating order: ', err);
        return Promise.reject(err);
      });
  }

  static deleteOrder(id: number) {
    return http2
      .delete(`${ApiConstants.URL.ORDERS.DELETE_ORDER(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteOrderByCode(code: string) {
    return http2
      .delete(`${ApiConstants.URL.ORDERS.DELETE_ORDER_BY_CODE(code)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(ApiConstants.URL.ORDERS.BULK_BELETE, config);
  }

  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.PURCHASE_ORDER_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static sendBulkTriggerOnApproval(payload: any[]) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.PURCHASE_ORDER_BULK_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getPODetailsByCode(documentCode: any) {
    Utility.cancelRequest(PurchaseOrderService.abortController);
    PurchaseOrderService.abortController = Utility.createAbortController();
    const finalEndpoint =
      ApiConstants.URL.ORDERS.FETCH_ORDER_DETAILS(documentCode);
    return http
      .get(`${finalEndpoint}`, {
        signal: PurchaseOrderService.abortController.signal
      })
      .catch((err: any) => {
        console.error('Error fetching PO: ', err);
        return Promise.reject(err);
      });
  }
}

export default PurchaseOrderService;
