import AttachmentService from '../../../../../Services/Attachment';
import Utility from '../../../../../Utility/Utility';
import { triggerDownload } from '../../../../ImportExport/utility/ExportData';

export const triggerAttachmentDownload = (
  attachmentId: any,
  attachmentName?: string
) => {
  AttachmentService.downloadAttachment(attachmentId)
    .then((absolutePath: string) => {
      triggerDownload(null, attachmentName, absolutePath);
    })
    .catch(() => {});
};

export const getAttachmentIdListFromAttachmentResponse = (attachmentData: any) => {
  if (Utility.isEmpty(attachmentData)) return [];
  return attachmentData.map((attachment: any) => attachment.attachmentId);
};