import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class GoodsReceiptTableWidth {

    isCodeVisible: boolean;
    isOrderNoVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isUomVisible: boolean;
    isSerialBatchVisible: boolean;
    isSerialNumberVisible: boolean;
    isBatchNumberVisible: boolean;
    isManufacturingDateVisible: boolean;
    isExpiryDateVisible: boolean;
    isRequiredQuantityVisible: boolean;
    isReceivedQuantityVisible: boolean;
    isWarehouseCodeVisible: boolean;
    isWarehouseNameVisible: boolean;
    isProductCustomFieldVisible: boolean;
    isPOBillNoDateVisible: boolean;
    isShortExcessRejectedQtyVisible: boolean;
    isAcceptedQtyVisible: boolean;
    isUnitPriceVisible: boolean;
    isBasicAmtVisible: boolean;
    isChargesVisible: boolean;
    isAmountVisible: boolean;


    code: number;
    orderNo: number;
    name: number;
    description: number;
    uom: number;
    serialBatch: number;
    serialNumber: number;
    batchNumber: number;
    manufacturingDate: number;
    expiryDate: number;
    requiredQuantity: number;
    receivedQuantity: number;
    warehouseCode: number;
    warehouseName: number;
    productCustomField: number;
    POBillNoDate: number;
    shortExcessRejectedQty: number;
    acceptedQty: number;
    unitPrice: number;
    basicAmt: number;
    charges: number;
    amount: number;

    originalCode: number;
    originalOrderNo: number;
    originalName: number;
    originalDescription: number;
    originalUom: number;
    originalSerialBatch: number;
    originalSerialNumber: number;
    originalBatchNumber: number;
    originalManufacturingDate: number;
    originalExpiryDate: number;
    originalRequiredQuantity: number;
    originalReceivedQuantity: number;
    originalWarehouseCode: number;
    originalWarehouseName: number;
    originalProductCustomField: number;
    productCustomFieldList: any;
    originalPOBillNoDate: number;
    originalShortExcessRejectedQty: number;
    originalAcceptedQty: number;
    originalUnitPrice: number;
    originalBasicAmt: number;
    originalCharges: number;
    originalAmount: number;

    constructor() {
        this.isCodeVisible = true
        this.isOrderNoVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isUomVisible = true
        this.isSerialBatchVisible = true
        this.isSerialNumberVisible = true
        this.isBatchNumberVisible = true
        this.isManufacturingDateVisible = true
        this.isExpiryDateVisible = true
        this.isRequiredQuantityVisible = true
        this.isReceivedQuantityVisible = true
        this.isWarehouseCodeVisible = true
        this.isWarehouseNameVisible = true
        this.isProductCustomFieldVisible = true
        this.isPOBillNoDateVisible = true
        this.isShortExcessRejectedQtyVisible = true
        this.isAcceptedQtyVisible = true
        this.isUnitPriceVisible = true
        this.isBasicAmtVisible = true
        this.isChargesVisible = true
        this.isAmountVisible = true

        this.code = 6
        this.orderNo = 6
        this.name = 13
        this.description = 23
        this.uom = 6
        this.serialBatch = 10
        this.serialNumber = 3
        this.batchNumber = 3
        this.manufacturingDate = 3.5
        this.expiryDate = 3.5
        this.requiredQuantity = 10
        this.receivedQuantity = 10
        this.warehouseCode = 10
        this.warehouseName = 7
        this.productCustomField = 9
        this.POBillNoDate = 6
        this.shortExcessRejectedQty = 6
        this.acceptedQty = 6
        this.unitPrice = 6
        this.basicAmt = 6
        this.charges = 6
        this.amount = 6

        this.originalCode = 6
        this.originalOrderNo = 6
        this.originalName = 13
        this.originalDescription = 23
        this.originalUom = 6
        this.originalSerialBatch = 10
        this.originalSerialNumber = 3
        this.originalBatchNumber = 3
        this.originalManufacturingDate = 3.5
        this.originalExpiryDate = 3.5
        this.originalReceivedQuantity = 10
        this.originalRequiredQuantity = 10
        this.originalWarehouseCode = 10
        this.originalWarehouseName = 7
        this.originalProductCustomField = 9
        this.originalPOBillNoDate = 6
        this.originalShortExcessRejectedQty = 6
        this.originalAcceptedQty = 6
        this.originalUnitPrice = 6
        this.originalBasicAmt = 6
        this.originalCharges = 6
        this.originalAmount = 6

    }

    setIsShowWidth(columnData: any) {
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isOrderNoVisible = getIsShowTableColumn(columnData, TableColumnPopupType.orderNo)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isSerialBatchVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialBatch)
        this.isSerialNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.serialNumber)
        this.isBatchNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.batchNumber)
        this.isManufacturingDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.manufacturingDate)
        this.isExpiryDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.expiryDate)
        this.isRequiredQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.requiredQuantity)
        this.isReceivedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.receivedQuantity)
        this.isWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseCode)
        this.isWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseName)
        this.isPOBillNoDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.POBillNoDate)
        this.isShortExcessRejectedQtyVisible = getIsShowTableColumn(columnData, TableColumnPopupType.shortExcessRejectedQty)
        this.isAcceptedQtyVisible = getIsShowTableColumn(columnData, TableColumnPopupType.acceptedQty)
        this.isUnitPriceVisible = getIsShowTableColumn(columnData, TableColumnPopupType.unitPrice)
        this.isBasicAmtVisible = getIsShowTableColumn(columnData, TableColumnPopupType.basicAmt)
        this.isChargesVisible = getIsShowTableColumn(columnData, TableColumnPopupType.charges)
        this.isAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amount)

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.orderNo = this.originalOrderNo
        this.name = this.originalName
        this.description = this.originalDescription
        this.uom = this.originalUom
        this.serialBatch = this.originalSerialBatch
        this.serialNumber = this.originalSerialNumber
        this.batchNumber = this.originalBatchNumber
        this.manufacturingDate = this.originalManufacturingDate
        this.expiryDate = this.originalExpiryDate
        this.receivedQuantity = this.originalReceivedQuantity
        this.requiredQuantity = this.originalRequiredQuantity
        this.warehouseCode = this.originalWarehouseCode
        this.warehouseName = this.originalWarehouseName
        this.POBillNoDate = this.originalPOBillNoDate
        this.shortExcessRejectedQty = this.originalShortExcessRejectedQty
        this.acceptedQty = this.originalAcceptedQty
        this.unitPrice = this.originalUnitPrice
        this.basicAmt = this.originalBasicAmt
        this.charges = this.originalCharges
        this.amount = this.originalAmount

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.orderNo = 0
        this.name = 0
        this.description = 0
        this.uom = 0
        this.receivedQuantity = 0
        this.requiredQuantity = 0
        this.warehouseCode = 0
        this.warehouseName = 0
        this.POBillNoDate = 0
        this.shortExcessRejectedQty = 0
        this.acceptedQty = 0
        this.unitPrice = 0
        this.basicAmt = 0
        this.charges = 0
        this.amount = 0

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isOrderNoVisible) {
            totalWidth += this.orderNo;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isSerialBatchVisible) {
            totalWidth += this.serialBatch;
        }
        if (this.isSerialNumberVisible) {
            totalWidth += this.serialNumber;
        }
        if (this.isBatchNumberVisible) {
            totalWidth += this.batchNumber;
        }
        if (this.isManufacturingDateVisible) {
            totalWidth += this.manufacturingDate;
        }
        if (this.isExpiryDateVisible) {
            totalWidth += this.expiryDate;
        }
        if (this.isReceivedQuantityVisible) {
            totalWidth += this.receivedQuantity;
        }
        if (this.isRequiredQuantityVisible) {
            totalWidth += this.requiredQuantity;
        }
        if (this.isWarehouseCodeVisible) {
            totalWidth += this.warehouseCode;
        }
        if (this.isWarehouseNameVisible) {
            totalWidth += this.warehouseName;
        }
        if (this.isPOBillNoDateVisible) {
            totalWidth += this.POBillNoDate;
        }
        if (this.isShortExcessRejectedQtyVisible) {
            totalWidth += this.shortExcessRejectedQty;
        }
        if (this.isAcceptedQtyVisible) {
            totalWidth += this.acceptedQty;
        }
        if (this.isUnitPriceVisible) {
            totalWidth += this.unitPrice;
        }
        if (this.isBasicAmtVisible) {
            totalWidth += this.basicAmt;
        }
        if (this.isChargesVisible) {
            totalWidth += this.charges;
        }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isOrderNoVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialBatchVisible) {
            totalSelectedElement += 1
        }
        if (this.isSerialNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isBatchNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isManufacturingDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isExpiryDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isRequiredQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isReceivedQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isPOBillNoDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isShortExcessRejectedQtyVisible) {
            totalSelectedElement += 1
        }
        if (this.isAcceptedQtyVisible) {
            totalSelectedElement += 1
        }
        if (this.isUnitPriceVisible) {
            totalSelectedElement += 1
        }
        if (this.isBasicAmtVisible) {
            totalSelectedElement += 1
        }
        if (this.isChargesVisible) {
            totalSelectedElement += 1
        }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isOrderNoVisible) {
            this.orderNo = this.originalOrderNo + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isSerialBatchVisible) {
            this.serialBatch = this.originalSerialBatch + additionalWidth
        }
        if (this.isSerialNumberVisible) {
            this.serialNumber = this.originalSerialNumber + additionalWidth
        }
        if (this.isBatchNumberVisible) {
            this.batchNumber = this.originalBatchNumber + additionalWidth
        }
        if (this.isManufacturingDateVisible) {
            this.manufacturingDate = this.originalManufacturingDate + additionalWidth
        }
        if (this.isExpiryDateVisible) {
            this.expiryDate = this.originalExpiryDate + additionalWidth
        }
        if (this.isRequiredQuantityVisible) {
            this.requiredQuantity = this.originalRequiredQuantity + additionalWidth
        }
        if (this.isReceivedQuantityVisible) {
            this.receivedQuantity = this.originalReceivedQuantity + additionalWidth
        }
        if (this.isWarehouseCodeVisible) {
            this.warehouseCode = this.originalWarehouseCode + additionalWidth
        }
        if (this.isWarehouseNameVisible) {
            this.warehouseName = this.originalWarehouseName + additionalWidth
        }
        if (this.isPOBillNoDateVisible) {
            this.POBillNoDate = this.originalPOBillNoDate + additionalWidth
        }
        if (this.isShortExcessRejectedQtyVisible) {
            this.shortExcessRejectedQty = this.originalShortExcessRejectedQty + additionalWidth
        }
        if (this.isAcceptedQtyVisible) {
            this.acceptedQty = this.originalAcceptedQty + additionalWidth
        }
        if (this.isUnitPriceVisible) {
            this.unitPrice = this.originalUnitPrice + additionalWidth
        }
        if (this.isBasicAmtVisible) {
            this.basicAmt = this.originalBasicAmt + additionalWidth
        }
        if (this.isChargesVisible) {
            this.charges = this.originalCharges + additionalWidth
        }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}