import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import { DKCalendar, DKDateRangePicker } from 'deskera-ui-library';

export const ResponsiveDateRangePicker = (props: any): any => {
  if (isViewportLarge()) {
    return (
      <DKDateRangePicker
        className={props.className}
        onClose={() => {
          props.onClose && props.onClose();
        }}
        color={props.color}
        startDate={props.startDate}
        selectedStartDate={props.selectedStartDate}
        selectedEndDate={props.selectedEndDate}
        showPresetList={props.showPresetList ? props.showPresetList : false}
        onSelectDateRange={(startDate: Date, endDate: Date) => {
          props.onSelectDateRange &&
            props.onSelectDateRange(startDate, endDate);
        }}
      />
    );
  } else {
    return (
      <DKCalendar
        className={props.className}
        selectedDate={props.selectedDate}
        isDateRange={props.isDateRange || true}
        onClose={() => {
          props.onClose && props.onClose();
        }}
        selectedStartDate={props.selectedStartDate}
        selectedEndDate={props.selectedEndDate}
        onSelectDate={(startDate: Date, endDate: Date) => {
          props.onSelectDateRange &&
            props.onSelectDateRange(startDate, endDate);
        }}
      />
    );
  }
};
