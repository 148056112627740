import { showAlert } from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';

let resolveCallback: any;

const useConfirm = () => {
  const { t, i18n } = useTranslation();
  const onConfirm = () => {
    resolveCallback(true);
  };

  const onCancel = () => {
    resolveCallback(false);
  };

  const confirm = (
    text: string,
    title?: string,
    confirmBtnLabel?: string,
    cancelBtnLabel?: string,
    confirmBtnClass?: string
  ) => {
    let buttons = [
      {
        title: cancelBtnLabel ? cancelBtnLabel : t(`CONFIRMATION_POPUP.CANCEL`),
        className: 'bg-gray2 border-m mt-r',
        onClick: () => {
          onCancel();
        }
      },
      {
        title: confirmBtnLabel
          ? confirmBtnLabel
          : t(`CONFIRMATION_POPUP.DELETE`),
        className: confirmBtnClass ?? 'bg-red text-white ml-r mt-r',
        onClick: () => {
          onConfirm();
        }
      }
    ];
    const confirmTitle = title ? title : t(`CONFIRMATION_POPUP.TITLE`);
    showAlert(confirmTitle, text, buttons);
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return { confirm, onConfirm, onCancel };
};

export default useConfirm;
