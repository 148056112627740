import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKIcons,
  DKIcon
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import Utility, { deepClone } from '../../../Utility/Utility';
import { INPUT_VIEW_DIRECTION, STATUS_TYPE } from '../../../Constants/Constant';
import ContactService, { ContactAPIConfig } from '../../../Services/Contact';
import {
  fetchContacts,
  selectContacts
} from '../../../Redux/Slices/ContactsSlice';
import { useAppSelector } from '../../../Redux/Hooks';
import { isDesktopOrTablet } from '../../../Utility/ViewportSizeUtils';
import OperatorsService, {
  OperatorsAPIConfig
} from '../../../Services/MRP/Operators';
import { selectOperators } from '../../../Redux/Slices/MRP/OperatorSlice';
import ApiConstants from '../../../Constants/ApiConstants';

const MultiSelectOperatorPopup = (props: any) => {
  const initialState: any = {
    name: '',
    description: '',
    fairValueFormula: '',
    id: '',
    documentSeqCode: '',
    status: false,
    nameHasError: false,
    formulaHasError: false
  };
  const [popupState, setPopupState] = useState<any>(deepClone(initialState));
  const [operatorList, setOperatorList] = useState([]);
  const operatorsData = useAppSelector(selectOperators);
  const [operator, setOperator] = useState<any>([]);
  const [operatorsArray, setOperatorsArray] = useState<any>(props.data);

  // useEffect(() => {
  //   getAllOperators();
  // }, [operatorsData]);

  useEffect(() => {
    getAllOperators();
  }, []);

  const getAllOperators = async () => {
    const config: OperatorsAPIConfig = {
      ...OperatorsService.apiConfig,
      Page: 0,
      Limit: 25,
      SearchTerm: '',
      SortDir: 'DESC',
      Sort: ''
    };
    try {
      OperatorsService.apiConfig = config;
      OperatorsService.getOperators().then((res: any) => {
        setOperatorList(res.content || []);
      });
    } catch (err) {}
  };

  // useEffect(() => {
  //   let activeContacts = operatorsData?.content?.filter(
  //     (record: any) => record.status === STATUS_TYPE.ACTIVE
  //   );
  //   if (Utility.isEmpty(activeContacts)) {
  //     activeContacts = [];
  //   }
  //   activeContacts.unshift({ name: 'All', code: 'ALL' });
  //   setContactList(activeContacts);
  // }, [operatorsData]);

  const isNotPresent = (operator: any) => {
    let notPresent = true;
    operatorsArray.forEach((cont: any) => {
      if (cont.id === operator.id) {
        notPresent = false;
      }
    });
    return notPresent;
  };

  const getContactField = () => {
    return (
      <div className="row">
        <DKLabel
          text="Select Operators"
          className="column"
          style={{ width: 150 }}
        />
        <DKInput
          className={'column'}
          value={operator}
          formatter={(obj: any) => {
            return obj.name;
          }}
          title=""
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          canValidate={false}
          errorMessage=" "
          onChange={(value: any) => {
            let newArray: any = [...operatorsArray];
            if (isNotPresent(value)) {
              newArray.push(value);
              setOperatorsArray([...newArray]);
            }
            setOperator(value);
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: true,
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                let query = `mrp/operator/search?limit=25&page=0&search=${searchValue}&sort=&sortDir=DESC&query=`;
                let URL = ApiConstants.URL.BASE + query;
                return URL;
              },
              dataParser: (response: any) => {
                let data: any[] = response?.content || [];
                return data;
              },
              debounceTime: 300
            },
            searchableKey: 'name',
            data: operatorList,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  const validate = () => {
    let updatedState = { ...popupState };
    let hasError = false;
    if (updatedState.name.trim() === '') {
      hasError = true;
      updatedState.nameHasError = true;
    }
    if (updatedState.fairValueFormula.trim() === '') {
      hasError = true;
      updatedState.formulaHasError = true;
    }
    setPopupState({ ...updatedState });
    return hasError;
  };

  const getHeader = () => {
    return (
      <div
        className="row justify-content-between p-h-r p-v-s bg-gray1"
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <div className="row width-auto">
          <DKLabel text={`Select Operators`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              props.onSave(operatorsArray);
            }}
          />
        </div>
      </div>
    );
  };

  const getContactList = () => {};

  return (
    <div style={{ zIndex: 9998 }} className="transparent-background">
      <div
        className="popup-window"
        style={{
          width: '30%',
          minWidth: `${!isDesktopOrTablet() ? '95%' : '445px'}`,
          minHeight: 150,
          maxHeight: '90%',
          padding: 0,
          paddingBottom: 10,
          overflowY: 'visible'
        }}
      >
        {getHeader()}
        <div className="column parent-width justify-content-between bg-white p-r overflow-y-visible">
          {getContactField()}
          {operatorsArray?.length > 0 && (
            <div className="row flex-wrap align-items-start bg-gray1 mt-3 border-m border-radius-s p-xs gap-1">
              {operatorsArray.map((con: any, index: any) => {
                return (
                  <div className="row width-auto border-m border-radius-s bg-white height-auto pr-xs pl-s">
                    <DKLabel text={con.name} />
                    <DKIcon
                      src={DKIcons.ic_close}
                      className="ic-s ml-xs cursor-pointer opacity-60"
                      style={{ padding: '5px 3px' }}
                      onClick={() => {
                        let copy: any = [...operatorsArray];
                        copy.splice(index, 1);
                        setOperatorsArray([...copy]);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectOperatorPopup;
