export class SliceManager {
  static sliceData: any = {};

  static setSliceData = (key: string, value: any) => {
    SliceManager.sliceData[key] = value;
  };

  static getSliceData = (key: any) => {
    return SliceManager.sliceData[key];
  };
}
