import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import ClientPortalService from '../../Services/ClientPortal';
import { RootState } from '../Store';
interface UsersInitialState {
  users: any;
}
const initialState: UsersInitialState = {
  users: {}
};
export const fetchClientPortalUsers = createAsyncThunk(
  'users/fetchUsers',
  async (data: any, thunkAPI) => {
    const response = await ClientPortalService.getClientPortalUser(data);
    return response;
  }
);
export const ClientPortalSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClientPortalUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
  }
});
const selfSelector = (state: RootState) => state;
export const selectClientPortalUsers = createSelector(
  selfSelector,
  (state) => state.clientPortal.users
);
