import ApiConstants from '../Constants/ApiConstants';
import { APP_NAME } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface PermissionAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  QueryParam?: any;
  Query?: any;
}

const defaultConfig: PermissionAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  QueryParam: '',
  Query: ''
};

class PermissionService {
  static apiConfig: PermissionAPIConfig = defaultConfig;

  static getPermissionMetadata(apps: any[]) {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.PERMISSION_METADATA;
    return http
      .post(`${apiPath}`, apps)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getUsersList = () => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.GET_USERS_LIST;
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getPowerUserData = () => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.GET_POWER_USER;
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getRoles = () => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.GET_ROLES_LIST;
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static updateUserPermission = (payload: any) => {
    const apiPath =
      ApiConstants.URL.GRANULAR_PERMISSIONS.UPDATE_USER_PERMISSION;
    return http.patch(`${apiPath}`, payload);
  };

  static inviteNewUser = (payload: any) => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.INVITE_NEW_USER;
    return http.post(`${apiPath}`, payload);
  };

  static deleteUser = (userId: any) => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.DELETE_USER(userId);
    return http.delete(`${apiPath}`);
  };

  static createRole = (payload: any) => {
    const apiPath =
      ApiConstants.URL.GRANULAR_PERMISSIONS.CREATE_UPDATE_DELETE_ROLE;
    return http
      .post(apiPath, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static updateRole = (payload: any) => {
    const apiPath =
      ApiConstants.URL.GRANULAR_PERMISSIONS.CREATE_UPDATE_DELETE_ROLE;
    return http
      .patch(apiPath, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static changeRole = (payload: any) => {
    const apiPath = ApiConstants.URL.GRANULAR_PERMISSIONS.CHANGE_ROLE;
    return http
      .post(`${apiPath}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static deleteRole = (payload: any) => {
    const apiPath =
      ApiConstants.URL.GRANULAR_PERMISSIONS.CREATE_UPDATE_DELETE_ROLE;
    return http
      .delete(`${apiPath}`, { data: payload })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getListofInvitedUsers = () => {
    const apiPath =
      ApiConstants.URL.GRANULAR_PERMISSIONS.GET_LIST_OF_INVITED_USERS('');
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  static getAllUserRoles = () => {
    const apiPath = ApiConstants.URL.USER.ROLES;
    return http
      .get(`${apiPath}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
}

export default PermissionService;
