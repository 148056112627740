import React, { useEffect, useState } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  DKLabel,
  DKButton,
  DKIcon,
  DKIcons,
  DKRadioButton,
  DKSpinner
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import DKInputDateTime from '../../../SharedComponents/DateTimePicker/DKInputDateTime';
import {
  WorkStationFormState,
  WorkStationFormStateFields,
  WorkStationInitialFormState
} from '../Constants/WorkStationsConstant';
import Utility, { deepClone } from '../../../Utility/Utility';
import WorkstationsService from '../../../Services/MRP/Workstations';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchHolidayList,
  selectMRPHolidayList
} from '../../../Redux/Slices/MRP/HolidayListSlice';
import ApiConstants from '../../../Constants/ApiConstants';
import { AddHolidayList } from '../HolidayList/AddHolidayList';
import { IHolidayListPayload } from '../../../Services/MRP/HolidayList';
import { INPUT_TITLE_STYLE } from '../Constants/UIHelper';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

const AddWorkStation = (props: any) => {
  // State
  const [workingHours, setWorkingHours] = useState<any>(
    !Utility.isEmpty(props?.workStationData)
      ? props?.workStationData?.workingHours
      : []
  );
  const [isEdit, setIsEdit] = useState<boolean>(
    !Utility.isEmpty(props.workStationData) ? true : false
  );

  const [formData, setFormData] = useState<WorkStationFormState>(
    WorkStationInitialFormState
  );
  const [showAddHolidayForm, setShowAddHolidayForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  let selectedHolidayList = React.useRef<IHolidayListPayload>();

  // Dispatch
  const dispatch = useAppDispatch();

  const holidayList: any = useAppSelector(selectMRPHolidayList);

  useEffect(() => {
    getFromDataState();
    return () => {
      setWorkingHours([]);
      setIsEdit(false);
      setFormData(WorkStationInitialFormState);
    };
  }, []);

  React.useEffect(() => {
    if (!showAddHolidayForm) {
      selectedHolidayList.current = undefined;
    }
  }, [showAddHolidayForm]);

  const getFromDataState = () => {
    if (!Utility.isEmpty(props?.workStationData)) {
      renderEditWorkstationData(props.workStationData);
    } else {
      return { ...WorkStationInitialFormState };
    }
  };

  const renderEditWorkstationData = (workStationData: any) => {
    let data: WorkStationFormState = deepClone(WorkStationInitialFormState);
    data[WorkStationFormStateFields.WORK_STATION_NAME].value =
      workStationData?.workstationName;
    data[WorkStationFormStateFields.PRODUCTION_CAPACITY].value =
      workStationData?.productionCapacity;
    data[WorkStationFormStateFields.ELECTRICITY_COST].value =
      workStationData?.electricityCost;
    data[WorkStationFormStateFields.RENT_COST].value =
      workStationData?.rentCost;
    data[WorkStationFormStateFields.CONSUMABLE_COST].value =
      workStationData?.consumableCost;
    data[WorkStationFormStateFields.WAGES].value = workStationData?.wages;
    data[WorkStationFormStateFields.DESCRIPTION].value =
      workStationData?.description;

    if (holidayList?.content?.length) {
      let selectedHolidayList = holidayList?.content?.filter(
        (holiday: any) => holiday.id === workStationData?.holidayList
      );
      data[WorkStationFormStateFields.HOLIDAY_LIST].value =
        selectedHolidayList[0];
    } else {
      data[WorkStationFormStateFields.HOLIDAY_LIST].value = {};
    }
    setFormData({ ...data });
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel
            text={`${isEdit ? `Edit` : `Add`} Workstation`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div
            className={`row border-radius-m ${
              isLoading ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              onClick={() => {
                if (!isLoading) {
                  saveWorkStation();
                }
              }}
            />
            {isLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getWorkingHoursList = () => {
    const removeWorkingHour = (index: number) => {
      let newState = [...workingHours];
      newState.splice(index, 1);
      setWorkingHours(newState);
    };

    const changeEnabledWorkingHours = (index: number) => {
      let newState = [...workingHours];
      newState.map((el) => (el.isEnabled = false));
      newState[index].isEnabled = true;
      setWorkingHours(newState);
    };

    const changeWorkingHours = (index: number, type: any, date: any) => {
      let newState = deepClone(workingHours);
      switch (type) {
        case 'START_TIME':
          newState[index].startTime = date;
          break;
        case 'END_TIME':
          newState[index].endTime = date;
          break;
        default:
          break;
      }
      setWorkingHours(newState);
    };

    return workingHours.length ? (
      <div className="row parent-width mt-4 p-h-m pb-4 border-radium-m bg-gray1">
        <div className="column parent-width">
          {workingHours.map((workHour: any, index: any) => {
            return (
              <div className="row parent-width position-relative">
                <DKRadioButton
                  title={``}
                  isSelected={workHour?.isEnabled}
                  onClick={() => changeEnabledWorkingHours(index)}
                  className="mr-l mt-10"
                  color={'bg-app'}
                />
                <DKInputDateTime
                  isOnlyTime={true}
                  title={`Start Time`}
                  className="parent-width mr-4"
                  selectedDate={workHour?.startTime}
                  onSave={(date: any) => {
                    changeWorkingHours(index, 'START_TIME', date);
                  }}
                />
                <DKInputDateTime
                  isOnlyTime={true}
                  title={`End Time`}
                  className="parent-width mr-4"
                  selectedDate={workHour?.endTime}
                  onSave={(date: any) => {
                    changeWorkingHours(index, 'END_TIME', date);
                  }}
                />
                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s cursor-pointer mt-10"
                  onClick={() => removeWorkingHour(index)}
                />
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const addNewWorkingHour = () => {
    let newStateWorkingHours: any[] = [...workingHours];
    newStateWorkingHours.push({
      srNo: workingHours.length + 1,
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      isEnabled: newStateWorkingHours.length === 0 ? true : false
    });
    setWorkingHours([...newStateWorkingHours]);
  };

  const onBlurChange = (
    value: number,
    fieldName: WorkStationFormStateFields
  ) => {
    let updatedFormState = { ...formData };
    updatedFormState[fieldName] = {
      ...updatedFormState[fieldName],
      value: Utility.roundOffToTenantDecimalScale(value)
    };
    setFormData(updatedFormState);
  };

  const onFormValueChange = (
    fieldName: WorkStationFormStateFields,
    value: any
  ) => {
    let newState = { ...formData };

    switch (fieldName) {
      case WorkStationFormStateFields.WORK_STATION_NAME:
        if (!Utility.isEmpty(value)) {
          newState[WorkStationFormStateFields.WORK_STATION_NAME] = {
            ...newState[WorkStationFormStateFields.WORK_STATION_NAME],
            hasError: false,
            value
          };
        } else {
          newState[WorkStationFormStateFields.WORK_STATION_NAME] = {
            ...newState[WorkStationFormStateFields.WORK_STATION_NAME],
            hasError: true,
            value: ''
          };
        }
        break;
      case WorkStationFormStateFields.PRODUCTION_CAPACITY:
        if (!Utility.isEmpty(value)) {
          newState[WorkStationFormStateFields.PRODUCTION_CAPACITY] = {
            ...newState[WorkStationFormStateFields.PRODUCTION_CAPACITY],
            hasError: false,
            value
          };
        } else {
          newState[WorkStationFormStateFields.PRODUCTION_CAPACITY] = {
            ...newState[WorkStationFormStateFields.PRODUCTION_CAPACITY],
            hasError: true,
            value: ''
          };
        }
        break;
      case WorkStationFormStateFields.ELECTRICITY_COST:
        newState[WorkStationFormStateFields.ELECTRICITY_COST] = {
          ...newState[WorkStationFormStateFields.ELECTRICITY_COST],
          value
        };
        break;
      case WorkStationFormStateFields.RENT_COST:
        newState[WorkStationFormStateFields.RENT_COST] = {
          ...newState[WorkStationFormStateFields.RENT_COST],
          value
        };
        break;
      case WorkStationFormStateFields.CONSUMABLE_COST:
        newState[WorkStationFormStateFields.CONSUMABLE_COST] = {
          ...newState[WorkStationFormStateFields.CONSUMABLE_COST],
          value
        };
        break;
      case WorkStationFormStateFields.WAGES:
        newState[WorkStationFormStateFields.WAGES] = {
          ...newState[WorkStationFormStateFields.WAGES],
          value
        };
        break;
      case WorkStationFormStateFields.HOLIDAY_LIST:
        newState[WorkStationFormStateFields.HOLIDAY_LIST] = {
          ...newState[WorkStationFormStateFields.HOLIDAY_LIST],
          value
        };
        break;
      case WorkStationFormStateFields.DESCRIPTION:
        newState[WorkStationFormStateFields.DESCRIPTION] = {
          ...newState[WorkStationFormStateFields.DESCRIPTION],
          value
        };
        break;
      default:
        break;
    }

    setFormData({ ...newState });
  };

  const validateFormData = () => {
    let newState = { ...formData };
    if (
      Utility.isEmpty(formData?.workstationName?.value) ||
      formData?.workstationName?.value?.trim() === ''
    ) {
      newState[WorkStationFormStateFields.WORK_STATION_NAME] = {
        ...newState[WorkStationFormStateFields.WORK_STATION_NAME],
        hasError: true
      };
      setFormData({ ...newState });
      return false;
    }

    // if (Utility.isEmpty(formData?.productionCapacity?.value.toString())) {
    //   newState[WorkStationFormStateFields.PRODUCTION_CAPACITY] = {
    //     ...newState[WorkStationFormStateFields.PRODUCTION_CAPACITY],
    //     hasError: true
    //   };
    //   setFormData({ ...newState });
    //   return false;
    // }

    // if (Object.keys(formData?.holidayList?.value).length === 0) {
    //   newState[WorkStationFormStateFields.HOLIDAY_LIST] = {
    //     ...newState[WorkStationFormStateFields.HOLIDAY_LIST],
    //     hasError: true
    //   };
    //   setFormData({ ...newState });
    //   return false;
    // }

    return true;
  };

  const makePayload = async () => {
    let Payload: any = {
      workstationName: formData?.workstationName?.value,
      consumableCost: formData?.consumableCost?.value,
      description: formData?.description?.value,
      electricityCost: formData?.electricityCost?.value,
      holidayList: formData?.holidayList?.value?.id,
      productionCapacity: formData?.productionCapacity?.value,
      rentCost: formData?.rentCost?.value,
      wages: formData?.wages?.value,
      workingHours
    };

    return Payload;
  };

  const saveWorkStation = async () => {
    if (validateFormData()) {
      setIsLoading(true);
      let Payload = await makePayload();
      if (isEdit) {
        WorkstationsService.updateWorkStation(
          props?.workStationData?.id,
          Payload
        ).then(
          (res: any) => {
            props?.onSuccess(res);
            setIsLoading(false);
          },
          (err) => {
            setIsLoading(false);
            console.error('Error in saving workstation: ', err);
          }
        );
      } else {
        WorkstationsService.saveWorkStation(Payload).then(
          (res: any) => {
            props?.onSuccess(res);
            setIsLoading(false);
          },
          (err) => {
            console.error('Error in saving workstation: ', err);
            setIsLoading(false);
          }
        );
      }
    }
  };
  const hasViewPricePermission = checkUserPermission(
    PERMISSIONS_BY_MODULE.WORKSTATIONS.VIEW_PRICE
  );
  return (
    <div style={{ zIndex: 9998 }} className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 600,
          width: '90%',
          maxHeight: '90%',
          padding: 0,
          paddingBottom: 30,
          overflowY: 'auto'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-r p-v-s">
          <div className="row parent-width">
            <DKLabel text="Basic details" className="fw-m fs-m mr-r" />
          </div>
          <div className="row parent-width mt-s" style={{ gap: 10 }}>
            <DKInput
              title={'Workstation Name'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={formData?.workstationName?.value}
              required={formData?.workstationName?.isMandatory}
              onChange={(value: string) => {
                onFormValueChange(
                  WorkStationFormStateFields.WORK_STATION_NAME,
                  value
                );
              }}
              className=""
              canValidate={formData?.workstationName?.hasError}
              validator={(value: string) => {
                return !formData?.workstationName?.hasError;
              }}
              errorMessage={formData?.workstationName?.errorMsg}
              titleStyle={INPUT_TITLE_STYLE}
              placeholder="Ex: Drill machine"
            />
            {/* <DKInput
              title={'Production Capacity'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={formData?.productionCapacity?.value}
              required={formData?.workstationName?.isMandatory}
              onChange={(value: string) => {
                onFormValueChange(
                  WorkStationFormStateFields.PRODUCTION_CAPACITY,
                  value
                );
              }}
              canValidate={formData?.productionCapacity?.hasError}
              validator={(value: string) => {
                return !formData?.productionCapacity?.hasError;
              }}
              errorMessage={formData?.productionCapacity?.errorMsg}
              titleStyle={INPUT_TITLE_STYLE}
              placeholder="0.00"
              onBlur={(e: any) =>
                onBlurChange(
                  e.target.value,
                  WorkStationFormStateFields.PRODUCTION_CAPACITY
                )
              }
            /> */}
          </div>
          {hasViewPricePermission && (
            <>
              <div className="row parent-width mt-l">
                <DKLabel text="WorkStation Cost" className="fw-m fs-m mr-r" />
              </div>
              <div className="row parent-width mt-s" style={{ gap: 10 }}>
                <DKInput
                  title={'Electricity Cost (per hour)'}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  className=""
                  type={INPUT_TYPE.NUMBER}
                  required={formData?.electricityCost?.isMandatory}
                  value={formData?.electricityCost?.value}
                  onChange={(value: string) => {
                    onFormValueChange(
                      WorkStationFormStateFields.ELECTRICITY_COST,
                      value
                    );
                  }}
                  canValidate={formData?.electricityCost?.hasError}
                  validator={(value: string) => {
                    return !formData?.electricityCost?.hasError;
                  }}
                  errorMessage={formData?.electricityCost?.errorMsg}
                  titleStyle={INPUT_TITLE_STYLE}
                  placeholder="0.00"
                  onBlur={(e: any) =>
                    onBlurChange(
                      e.target.value,
                      WorkStationFormStateFields.ELECTRICITY_COST
                    )
                  }
                />
                <DKInput
                  title={'Rent Cost (per hour)'}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.NUMBER}
                  required={formData?.rentCost?.isMandatory}
                  value={formData?.rentCost?.value}
                  onChange={(value: string) => {
                    onFormValueChange(
                      WorkStationFormStateFields.RENT_COST,
                      value
                    );
                  }}
                  canValidate={formData?.rentCost?.hasError}
                  validator={(value: string) => {
                    return !formData?.rentCost?.hasError;
                  }}
                  errorMessage={formData?.rentCost?.errorMsg}
                  titleStyle={INPUT_TITLE_STYLE}
                  placeholder="0.00"
                  onBlur={(e: any) =>
                    onBlurChange(
                      e.target.value,
                      WorkStationFormStateFields.RENT_COST
                    )
                  }
                />
              </div>
              <div className="row parent-width mt-m" style={{ gap: 10 }}>
                <DKInput
                  title={'Consumable Cost (per hour)'}
                  className=""
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.NUMBER}
                  required={formData?.consumableCost?.isMandatory}
                  value={formData?.consumableCost?.value}
                  onChange={(value: string) => {
                    onFormValueChange(
                      WorkStationFormStateFields.CONSUMABLE_COST,
                      value
                    );
                  }}
                  canValidate={formData?.consumableCost?.hasError}
                  validator={(value: string) => {
                    return !formData?.consumableCost?.hasError;
                  }}
                  errorMessage={formData?.consumableCost?.errorMsg}
                  titleStyle={INPUT_TITLE_STYLE}
                  placeholder="0.00"
                  onBlur={(e: any) =>
                    onBlurChange(
                      e.target.value,
                      WorkStationFormStateFields.CONSUMABLE_COST
                    )
                  }
                />
                <div className="row"></div>
              </div>
            </>
          )}
          {/* <div className="row parent-width mt-l">
            <DKLabel text="Working Hours" className="fw-m fs-m mr-r" />
          </div>
          <div className="row parent-width mt-s">
            <DKInput
              title={'Holiday List'}
              required={formData?.holidayList?.isMandatory}
              canValidate={formData?.holidayList?.hasError}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DROPDOWN}
              value={formData?.holidayList?.value}
              formatter={(obj: any) => {
                return obj.name;
              }}
              onChange={(value: string) => {
                onFormValueChange(
                  WorkStationFormStateFields.HOLIDAY_LIST,
                  value
                );
              }}
              className=""
              errorMessage={formData?.holidayList?.errorMsg}
              dropdownConfig={{
                style: { minWidth: 230 },
                className: 'shadow-m',
                title: 'Select Holiday List',
                allowSearch: true,
                searchableKey: 'name',
                canEdit: false,
                canDelete: false,
                data: holidayList?.content,
                errorMessage: formData?.holidayList?.errorMsg,
                renderer: (index: any, holiday: any) => {
                  return holiday.name;
                },
                searchApiConfig: {
                  getUrl: (searchValue: string) => {
                    let baseURL = ApiConstants.URL.BASE.substring(
                      0,
                      ApiConstants.URL.BASE.length - 1
                    );
                    return `${baseURL}${ApiConstants.URL.MRP.HOLIDAY_LIST.FETCH}?search=${searchValue}`;
                  },
                  dataParser: (response: any) => {
                    return response?.content;
                  },
                  debounceTime: 300
                },
                button: {
                  title: '+ Add Holiday List',
                  className: 'bg-button text-white',
                  onClick: () => {
                    setShowAddHolidayForm(true);
                  }
                }
              }}
              titleStyle={INPUT_TITLE_STYLE}
              placeholder="Select"
            />
          </div>
          {getWorkingHoursList()}
          <div className="row parent-width mt-4">
            <DKButton
              title="+ Add Holiday"
              style={{ paddingLeft: 0 }}
              className="row border-radius-m text-wrap-none p-v-s dk-button-hover cursor-pointer text-app-color fw-m p-0"
              onClick={() => addNewWorkingHour()}
            />
          </div> */}
          <div className="row parent-width">
            <DKInput
              required={formData?.description?.isMandatory}
              title={`Description`}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formData?.description?.value}
              type={INPUT_TYPE.LONG_TEXT}
              className="mt-r"
              onChange={(value: string) => {
                onFormValueChange(
                  WorkStationFormStateFields.DESCRIPTION,
                  value
                );
              }}
              titleStyle={INPUT_TITLE_STYLE}
              placeholder="Describe this workstation"
            />
          </div>
        </div>
      </div>
      {showAddHolidayForm && (
        <AddHolidayList
          holidayList={selectedHolidayList.current}
          onCancel={() => setShowAddHolidayForm(false)}
          onSave={() => {
            dispatch(fetchHolidayList({ params: {} }));
            setShowAddHolidayForm(false);
          }}
        />
      )}
    </div>
  );
};

export default AddWorkStation;
