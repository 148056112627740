import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeaderType } from "../TableUtility";
import { TableColumnPopupType } from "../../../Constants/Constants";
import Utility, { getIsMakePayment } from "../../../Utilities/Utility";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";

export class PaymentTableItem {
    snNumber: string;
    invoiceNumber: string;
    billNumber: string;
    totalInvoiceAmount: number;
    totalBillAmount: number;
    paymentMade: number;
    currentDueAmount: number;
    currentBalance: number;
    paymentMedium: string;
    currency: string;
    parentDocumentCurrency: string;
    rawItemValue: any;
    constructor(paymentItem: any, currency: string) {
        this.snNumber = paymentItem.snNumber
        this.invoiceNumber = paymentItem.invoiceNumber
        this.billNumber = paymentItem.billNumber
        this.totalInvoiceAmount = paymentItem.totalInvoiceAmount
        this.totalBillAmount = paymentItem.totalBillAmount
        this.paymentMade = paymentItem.paymentMade
        this.currentDueAmount = paymentItem.currentDueAmount
        this.currentBalance = paymentItem.currentBalance
        this.paymentMedium = paymentItem.paymentMedium ? paymentItem.paymentMedium : ''
        this.currency = currency
        this.parentDocumentCurrency = paymentItem.parentDocumentCurrency
        this.rawItemValue = paymentItem
    }

    toString(documentType: string) {
        var sortedHeader = getSortedHeaderType()
        var footerList = TableFooterUtility.getFooterListByType('payment')
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                if (footerList.includes(type)) {
                    return
                }
                sortedValue.push(this.getValueByType(type))
            });
            return sortedValue
        }
        if(getIsMakePayment(documentType)) {
            return this.getDefaultMakePaymentList()
        }
        return this.getDefaultReceivePaymentList()
    }

    getParentCurrency() {
        if (this.parentDocumentCurrency !== undefined && this.parentDocumentCurrency !== null) {
            return this.parentDocumentCurrency
        }
        return this.currency
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.snNumber:
                return this.snNumber
            case TableColumnPopupType.invoiceNumber:
                return this.invoiceNumber
            case TableColumnPopupType.billNumber:
                return this.billNumber
            case TableColumnPopupType.totalInvoiceAmount:
                return Utility.toCurrencyFormat(this.totalInvoiceAmount, this.getParentCurrency())
            case TableColumnPopupType.totalBillAmount:
                return Utility.toCurrencyFormat(this.totalBillAmount, this.getParentCurrency())
            case TableColumnPopupType.paymentMade:
                return Utility.toCurrencyFormat(this.paymentMade, this.currency)
            case TableColumnPopupType.currentDueAmount:
                return Utility.toCurrencyFormat(this.currentDueAmount, this.getParentCurrency())
            case TableColumnPopupType.currentBalance:
                return Utility.toCurrencyFormat(this.currentBalance, this.getParentCurrency())
            case TableColumnPopupType.paymentMedium:
                return getLocalisedText(this.paymentMedium)
            default:
                return ''
        }
    }
    getDefaultMakePaymentList(){
        return [
            this.snNumber,
            this.billNumber,
            Utility.toCurrencyFormat(this.totalBillAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentMade, this.currency),
            Utility.toCurrencyFormat(this.currentBalance, this.currency),
            getLocalisedText(this.paymentMedium),
        ]
    }

    getDefaultReceivePaymentList() {
        return [
            this.snNumber,
            this.invoiceNumber,
            Utility.toCurrencyFormat(this.totalInvoiceAmount, this.currency),
            Utility.toCurrencyFormat(this.paymentMade, this.currency),
            Utility.toCurrencyFormat(this.currentDueAmount, this.currency),
            getLocalisedText(this.paymentMedium)
        ]
    }
}
