import {
  DKLabel,
  DKButton,
  DKIcons,
  DKIcon,
  showAlert,
  showToast
} from 'deskera-ui-library';

import { useEffect, useState } from 'react';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import { DOC_TYPE, DOC_TYPE_TO_ATTACHMENT_MAP } from '../../Constants/Constant';
import AttachmentService from '../../Services/Attachment';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import Utility from '../../Utility/Utility';

interface AttachmentProps {
  documentDetails: any;
  documentType?: DOC_TYPE;
  onClose?: (docId: any, updatedAttachments: any[]) => void;
}

export function AttachmentsPopup(props: AttachmentProps) {
  const [doc, setDoc] = useState(props.documentDetails);
  const [attachments, setAttachments] = useState<any[]>(props.documentDetails);
  const [updateProgress, setUpdateProgress] = useState<any>(null);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = async () => {
    let moduleType = '';
    const docCopy = { ...doc } as any;
    if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.INVOICE
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      docCopy.isPartialSalesOrder &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.SALES_ORDER
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.BILL
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.ORDER];
    }
    // else {
    //   moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.documentType];
    // }

    const entityId = docCopy.id || docCopy.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };
    try {
      const allAttachments = await AttachmentService.getAllAttachments();
      setAttachments(allAttachments);
    } catch (err) {
      console.error('Error loading attachments: ', err);
    }
  };

  const uploadAttachmentToAWS = async (file: File) => {
    // let moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props?.documentType];
    let moduleType = '';
    if (
      props.documentDetails.linkedDocuments?.[0]?.documentType ===
      DOC_TYPE.PURCHASE_REQUEST
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.PURCHASE_REQUISITION];
    }
    const entityId = doc.id || doc.entityId || '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    setIsUploading(true);
    return AttachmentService.uploadAttachment(file, setUpdateProgress)
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true')
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const attachmentForListing = [...attachments, ...resList];
          setAttachments(attachmentForListing);
          setIsUploading(false);
        })
        .catch((err) => {
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
          console.log(err);
          setIsUploading(false);
        });
      }
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentPath: string,
    attachmentName: string
  ) => {
    triggerDownload(null, attachmentName, attachmentPath);
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  useEffect(() => {
    fetchAttachments();
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(updateProgress)) {
      if (updateProgress.percentComplete === 100) {
        setTimeout(() => {
          setUpdateProgress(null);
        }, 1000);
      }
    }
  }, [updateProgress]);

  const getBody = () => {
    return (
      <div className="column parent-width parent-height p-h-r p-v-s pb-xl overflow-y-auto">
        {attachments.length === 0 && (
          <DKLabel
            text="No attachment here. Select file to upload."
            clasName="parent-width"
          />
        )}
        {updateProgress && (
          <div
            className={`column parent-width border-m z-index-2 p-h-s p-v-s bg-gray0 mt-r justify-content-between position-absolute bottom-0 left-0`}
            style={{ borderLeft: 0, borderRight: 0, borderBottom: 0 }}
          >
            <DKLabel
              text={`Uploading: <span class="fw-m">${updateProgress?.fileName}</span>`}
              className="row"
            />
            <div
              className="row mt-r bg-white border-m border-radius-m"
              style={{ height: 12 }}
            >
              <div
                className="column bg-blue parent-height border-radius-m"
                style={{ width: `${updateProgress?.percentComplete}%` }}
              ></div>
            </div>
            <DKLabel
              text={`${updateProgress?.percentComplete}%`}
              className="parent-width text-align-center mt-xs"
            />
          </div>
        )}
        {attachments.map((attachment: any, index: number) => (
          <div className="row p-v-s">
            <div
              className="row align-items-end text-right"
              style={{ width: '6%' }}
            >
              <DKLabel text={`${index + 1}.`} />
            </div>
            <div
              className={`row border-m border-radius-s p-h-s p-v-s bg-gray0 justify-content-between ${
                isRemovingAttachment ? 'pointer-events-none' : ''
              }`}
              key={attachment.attachmentId}
              style={{ width: '96%' }}
            >
              <div className="flex flex-row items-center">
                <DKIcon
                  src={DKIcons.ic_document}
                  className="column ic-s cursor-pointer opacity-60"
                  onClick={() => {
                    triggerAttachmentDownload(
                      attachment.path,
                      attachment.attachmentFileName
                    );
                  }}
                />
                <span
                  onClick={() => {
                    triggerAttachmentDownload(
                      attachment.path,
                      attachment.attachmentFileName
                    );
                  }}
                >
                  <DKLabel
                    text={attachment.attachmentFileName}
                    className="column ml-s cursor-pointer border-none break-text"
                  />
                </span>
              </div>
              <div className="flex flex-row items-center">
                <DKIcon
                  src={DKIcons.ic_download}
                  className="ic-s cursor-pointer opacity-60 hover:opacity-80"
                  onClick={() => {
                    triggerAttachmentDownload(
                      attachment.path,
                      attachment.attachmentFileName
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {/* <DKButton
          title={
            <>
              + Attach Files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          className={`${
            isUploading ? 'text-gray' : 'text-blue'
          } mt-r fw-m p-h-0`}
          style={{ paddingLeft: 0 }}
          disabled={isUploading}
          onClick={triggerAttachmentUpload}
        /> */}
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text="Attachments" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              props.onClose?.(doc.id, attachments);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9999 }}>
        <div
          className="popup-window overflow-hidden position-relative"
          style={{
            maxHeight: '90%',
            minHeight: 200,
            width: '400px',
            maxWidth: '90%',
            padding: 0,
            paddingBottom: 0
          }}
        >
          {getHeader()}
          {getBody()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
