import { useEffect, useState } from 'react';
import { removeLoader, DKLabel, DKIcon } from 'deskera-ui-library';
import PopupWrapper from '../PopupWrapper';
import {
  DOC_TYPE,
  FULFILLMENT_STATUS,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import { BtnType, CallBackPayloadType } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import FulfillmentService from '../../Services/FulfillmentService';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import FulfillmentRecordDetailWithTable from '../FulfillmentPopup/FulfillmentRecordDetailWithTable';
import SalesReturnRecordDetailWithTable from '../SalesReturn/SalesReturnRecordDetailWithTable';
import SalesReturnService from '../../Services/SalesReturn';
import PaymentRecordsDetail from '../PaymentRecords/PaymentRecordsDetail';
import ic_no_data from '../../Assets/Icons/ic_no_data.png';

import LinkedRecordListView from './LinkedRecordListView';
import InvoiceService from '../../Services/Invoice';
import ReceivedGoodsService from '../../Services/ReceivedGoods';
import ReceivedGoodsRecordWithDetails from '../ReceivedGoodsPopup/ReceivedGoodsRecordWithDetails';
import PurchaseReturnService from '../../Services/PurchaseReturn';
import PurchaseReturnRecordWithDetails from '../PurchaseReturn/PurchaseReturnRecordWithDetails';
import JournalService from '../../Services/Journal';
import JournalEntryRecordsDetail from './JournalEntryRecordsDetail';
import BillService from '../../Services/Bill';
import { localizedText } from '../../Services/Localization/Localization';

export default function LinkedRecordsPopup(props: any) {
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>([]);
  const [fulfillmentCode, setFulfillmentCode] = useState<any>();

  const [salesReturnsDetails, setSalesReturnsDetails] = useState<any>([]);
  const [salesReturnCode, setSalesReturnCode] = useState<any>();

  const [paymentRecordDetails, setPaymentRecordDetails] = useState<any>([]);
  const [paymentRecords, setPaymentRecords] = useState<any[]>();
  const [paymentRecordData, setPaymentRecordData] = useState<any[]>();

  const [activeTab, setActiveTab] = useState<any>(DOC_TYPE.FULFILLMENT);

  const [receivedGoodsDetails, setReceivedGoodsDetails] = useState<any>();
  const [receivedGoodRecordData, setReceivedGoodRecordData] = useState<any[]>(
    []
  );

  const [purchaseReturnsDetails, setPurchaseReturnsDetails] = useState<any>([]);
  const [purchaseReturnCode, setPurchaseReturnCode] = useState<any>();

  const [journalEntryDetails, setJournalEntryDetails] = useState<any>([]);
  const [journalEntryCode, setJournalEntryCode] = useState<any>();

  const [journalData, setJournalData] = useState<any>();
  const [additionalLinkedJEData, setAdditionalLinkedJEData] = useState<any>();
  const [selectedAdditionalJEInfo, setSelectedAdditionalJEInfo] = useState<{
    jeCode: string;
    jeType: DOC_TYPE;
  }>();

  const popupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  useEffect(() => {
    getFulfillmentRecords(props.data);
    getSalesReturnRecords(props.data);
    getPaymentRecords(props.data);
    loadJournalEntryRecords(props.data);
    if (props.documentType === DOC_TYPE.BILL) {
      getBillsReceivedGoodsRecord(props.data);
      getPurchaseReturnRecords(props.data);
    }
    if (
      props.documentType === DOC_TYPE.INVOICE ||
      props.documentType === DOC_TYPE.BILL
    ) {
      loadLinkedAdditionalJERecords();
    }
  }, []);

  useEffect(() => {
    let data: any[] = [];
    if (journalData?.content?.length > 0) {
      data = journalData.content;
      if (
        fulfillmentDetails?.length === 0 ||
        receivedGoodsDetails?.length === 0
      ) {
        if (paymentRecords && paymentRecords?.length > 0) {
          setActiveTab(DOC_TYPE.Payment);
        } else {
          setActiveTab(DOC_TYPE.JOURNAL_ENTRY);
        }
        setJournalEntryCode(journalData.content?.[0]?.jeCode);
      }
    }
    setJournalEntryDetails(data);
  }, [journalData]);

  const loadJournalEntryRecords = async (data: any) => {
    if (Utility.isEmpty(data.documentSequenceCode)) return;
    JournalService.getNormalJEByCodeWithLimit(
      data.documentSequenceCode,
      props.documentType
    )
      .then((res: any) => {
        setJournalData(res);
      })
      .catch((err: any) => {
        console.error(err, 'error');
      });
  };

  const loadLinkedAdditionalJERecords = () => {
    const isDraft = Utility.isDraft(props?.data?.documentSequenceCode);
    if (isDraft) {
      return;
    }
    // setAdditionaLinkedJEData
    let docCode =
      props.documentType === DOC_TYPE.INVOICE
        ? props.data?.salesInvoiceCode
        : props.data?.purchaseInvoiceCode;
    JournalService.getLinkedPayment_FF_RG_JE(docCode, props.documentType)
      .then((resp: any) => {
        setAdditionalLinkedJEData(resp);
      })
      .catch((err: any) => {
        console.error(
          'Error loading linked JE records for Payment, FF and RG: ',
          err
        );
      });
  };

  const getFulfillmentRecords = (
    data: any,
    callJE: boolean = false,
    reFetchSalesReturnRecords: boolean = false
  ) => {
    const isDraft = Utility.isDraft(data.documentSequenceCode);
    const isNotFulfilled: boolean =
      data.fulfillmentStatus?.[0] === FULFILLMENT_STATUS.UNFULFILLED;
    if (isDraft) {
      return;
    }
    if (isNotFulfilled) {
      return;
    }
    if (data) {
      loadInvoiceFulfillmentRecords(data, callJE, reFetchSalesReturnRecords);
    }
  };

  const loadInvoiceFulfillmentRecords = async (
    fulfillmentDetails: any,
    callJE: boolean,
    reFetchSalesReturnRecords: boolean
  ) => {
    let documentCode = '';
    if (props.documentType === DOC_TYPE.INVOICE) {
      documentCode = fulfillmentDetails.salesInvoiceCode;
    }
    if (props.documentType === DOC_TYPE.SALES_ORDER) {
      documentCode = fulfillmentDetails.salesOrderCode;
    }
    if (Utility.isEmpty(documentCode)) return;
    FulfillmentService.getFulfillmentRecords(documentCode, props.documentType)
      .then(
        (data: any) => {
          let filteredData: any = data?.filter(
            (ele: any) => ele.documentType === props.documentType
          );
          if (Utility.isEmpty(filteredData)) {
            filteredData = data;
          }
          setFulfillmentDetails(filteredData);
          if (filteredData.length > 0) {
            setFulfillmentCode(filteredData?.[0]?.fulfillment_code);
            setActiveTab(DOC_TYPE.FULFILLMENT);
          }
          if (callJE) {
            loadJournalEntryRecords(props.data);
          }
          if (reFetchSalesReturnRecords) {
            getSalesReturnRecords(props.data, callJE, filteredData);
          }
        },
        (err) => {
          console.error('Error getting fulfillment records: ', err);
        }
      )
      .catch((err) => {
        console.error('Error getting fulfillment records: ', err);
      });
  };

  const getSalesReturnRecords = (
    salesDetails: any,
    callJE: boolean = false,
    tempFulfillmentData?: any[]
  ) => {
    let documentCode = '';
    if (props.documentType === DOC_TYPE.INVOICE) {
      documentCode = salesDetails.salesInvoiceCode;
    }
    if (props.documentType === DOC_TYPE.SALES_ORDER) {
      documentCode = salesDetails.salesOrderCode;
    }
    if (Utility.isEmpty(documentCode)) return;
    SalesReturnService.isFullyReturned(documentCode, props.documentType)
      .then((res: any) => {
        // let data = Utility.filterReturnRecords(res, props.documentType);
        let data = res;
        if (data?.length !== 0) {
          setSalesReturnsDetails(data);
          setSalesReturnCode(data?.[0]?.salesReturnCode);
          if (fulfillmentDetails && fulfillmentDetails?.length === 0) {
            setActiveTab(DOC_TYPE.SALES_RETURN);
          }
        } else {
          setSalesReturnsDetails(null);
          if (
            typeof tempFulfillmentData !== 'undefined' &&
            tempFulfillmentData !== null &&
            tempFulfillmentData?.length === 0
          ) {
            if (paymentRecords?.length) {
              setActiveTab(DOC_TYPE.Payment);
            } else {
              setActiveTab(DOC_TYPE.JOURNAL_ENTRY);
            }
          }
        }
        if (callJE) {
          loadJournalEntryRecords(props.data);
        }
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const getPaymentRecords = (data: any) => {
    const isDraft = Utility.isDraft(data.documentSequenceCode);
    if (isDraft) {
      return;
    }
    if (data.knockoffInfo && data.knockoffInfo.length > 0) {
      setPaymentRecords(data.knockoffInfo);
      setPaymentRecordDetails({
        ...data
      });
      setActiveTab(DOC_TYPE.Payment);
    } else if (data.linkedCreditNote && data.linkedCreditNote.length > 0) {
      setPaymentRecords(data.linkedCreditNote);
      setPaymentRecordDetails({
        ...data
      });
      setActiveTab(DOC_TYPE.Payment);
    } else if (
      (data.knockoffInfo && data.knockoffInfo.length === 0) ||
      (data.linkedCreditNote && data.linkedCreditNote.length === 0)
    ) {
      setPaymentRecordDetails([]);
      setPaymentRecords([]);
      setActiveTab(DOC_TYPE.JOURNAL_ENTRY);
    }
  };

  const fetchDocumentData = () => {
    if (props.documentType === DOC_TYPE.INVOICE) {
      InvoiceService.getInvoiceByCode(props.data.salesInvoiceCode)
        .then((invoice) => {
          getPaymentRecords(invoice);
        })
        .catch((err) => {
          console.error('Error getting invoice records: ', err);
        });
    }
    if (props.documentType === DOC_TYPE.BILL) {
      BillService.getBillDetailsByCode(props.data.purchaseInvoiceCode)
        .then((bill) => {
          getPaymentRecords(bill);
        })
        .catch((err) => {
          console.error('Error getting bills records: ', err);
        });
    }
  };

  const getBillsReceivedGoodsRecord = (
    billDetails: any,
    callJE: boolean = false,
    reFetchPurchaseReturnRecords: boolean = false
  ) => {
    const billCode: string = billDetails.purchaseInvoiceCode;
    const billDetailsData: any = billDetails;
    // setEditableBill(billDetailsData);
    if (billDetailsData.purchaseInvoiceType === 'ASSET') {
      ReceivedGoodsService.getReceivedGoodsRecordFoAsset(
        billCode,
        DOC_TYPE.BILL
      )
        .then((data: any) => {
          setReceivedGoodsDetails(data);
          if (data.length > 0) {
            setReceivedGoodRecordData(data?.[0]?.goods_receipt_code);
            setActiveTab(DOC_TYPE.BILL);
          }
          // setShowReceivedGoodsRecordsPopupForAsset(true);
        })
        .catch((err: any) => {
          console.error('Unable to get received goods records: ', err);
        });
    } else {
      ReceivedGoodsService.getReceivedGoodsRecord(billCode, DOC_TYPE.BILL).then(
        (data: any) => {
          setReceivedGoodsDetails(data);
          if (data.length > 0) {
            setReceivedGoodRecordData(data?.[0]?.goods_receipt_code);
            setActiveTab(DOC_TYPE.BILL);
          }
          if (callJE) {
            loadJournalEntryRecords(props.data);
          }
          if (reFetchPurchaseReturnRecords) {
            getPurchaseReturnRecords(props.data, true, data);
          }
          // setShowReceivedGoodsRecordsPopup(true);
        },
        (err) => console.error('Unable to get received goods records: ', err)
      );
    }
  };

  const getPurchaseReturnRecords = (
    billDetails: any,
    callJE: boolean = false,
    tempReceiveGoodRecords?: any[]
  ) => {
    PurchaseReturnService.isFullyReturned(
      billDetails.purchaseInvoiceCode,
      DOC_TYPE.BILL
    )
      .then((res: any) => {
        let data = Utility.filterReturnRecords(res, DOC_TYPE.BILL);
        if (data?.length !== 0) {
          setPurchaseReturnsDetails(data);
          setPurchaseReturnCode(data?.[0]?.purchaseReturnCode);
          if (receivedGoodsDetails && receivedGoodsDetails?.length === 0) {
            setActiveTab(DOC_TYPE.PURCHASE_RETURN);
          }
        } else {
          setPurchaseReturnsDetails([]);
          setPurchaseReturnsDetails(null);
          if (
            typeof tempReceiveGoodRecords !== 'undefined' &&
            tempReceiveGoodRecords !== null &&
            tempReceiveGoodRecords?.length === 0
          ) {
            if (paymentRecords?.length) {
              setActiveTab(DOC_TYPE.Payment);
            } else {
              setActiveTab(DOC_TYPE.JOURNAL_ENTRY);
            }
          }
        }
        if (callJE) {
          loadJournalEntryRecords(props.data);
        }
      })
      .catch((err) => {
        removeLoader();
        console.error('Unable to get Purchase Return Records: ', err);
      });
  };

  const getDocumentCode = () => {
    switch (props.documentType) {
      case 'SALES_INVOICE':
        return props.data.salesInvoiceCode;
      case 'SALES_ORDER':
        return props.data.salesOrderCode;
      case 'PURCHASE_INVOICE':
        return props.data.purchaseInvoiceCode;
      default:
        return '';
    }
  };

  const getAllRecordSections = () => {
    return (
      <LinkedRecordListView
        documentDetails={props.data}
        documentCode={getDocumentCode()}
        documentType={props.documentType}
        fulfillmentDetails={fulfillmentDetails}
        salesReturnsDetails={salesReturnsDetails}
        paymentRecordDetails={paymentRecordDetails}
        receivedGoodsDetails={receivedGoodsDetails}
        purchaseReturnsDetails={purchaseReturnsDetails}
        journalEntryDetails={journalEntryDetails}
        additionalLinkedJEData={additionalLinkedJEData}
        paymentRecords={paymentRecords}
        activeTab={activeTab}
        onActiveTabChange={(data: any) => setActiveTab(data)}
        onFulfillmentChange={(data: any) => setFulfillmentCode(data)}
        onSalesOrderChange={(data: any) => setSalesReturnCode(data)}
        onPaymentRecordChange={(data: any) => {
          setPaymentRecordData(data);
          // setActiveTab(DOC_TYPE.Payment);
        }}
        onPurchaseOrderChange={(data: any) => setPurchaseReturnCode(data)}
        onJournalEntryChange={(data: any) => setJournalEntryCode(data)}
        onAdditionalJEChange={(data: any) =>
          setSelectedAdditionalJEInfo({ ...data })
        }
        onReceivedGoodRecordChange={(data: any) =>
          setReceivedGoodRecordData(data)
        }
        isDeleted={(val: boolean) => {
          if (val) {
            props.isDeleted(val);
            getFulfillmentRecords(props.data, true, true);

            if (props.documentType === DOC_TYPE.BILL) {
              getBillsReceivedGoodsRecord(props.data, true, true);
            }
            if (
              props.documentType === DOC_TYPE.INVOICE ||
              props.documentType === DOC_TYPE.BILL
            ) {
              loadLinkedAdditionalJERecords();
            }
          }
        }}
        fetchCurrentDocument={() => fetchDocumentData()}
        onPaymentDeleted={() => {
          if (
            props.documentType === DOC_TYPE.INVOICE ||
            props.documentType === DOC_TYPE.BILL
          ) {
            loadLinkedAdditionalJERecords();
          }
        }}
        isDocumentEmailFlow={props.isDocumentEmailFlow}
        setSelectedIndex={setSelectedIndex}
      />
    );
  };

  const getDetailViewHeader = (title: string) => {
    return (
      <DKLabel
        text={title}
        className="fs-l pl-m fw-m border-b parent-width pb-xs"
      />
    );
  };

  const headerTitleForAdditionalJE = (title: string) => {
    title = activeTab?.replaceAll('_', ' ');
    title = title
      ?.toLowerCase()
      ?.replaceAll('fulfillment', localizedText('Fulfillment'));
    title = Utility.convertInTitleCase(title);
    return title;
  };

  const getDetailRecordView = () => {
    return (
      <>
        {!Utility.isEmptyObject(fulfillmentDetails) &&
          DOC_TYPE.FULFILLMENT === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`${localizedText('Fulfillment')} Records`)}
              <FulfillmentRecordDetailWithTable
                documentDetails={props.data}
                fulfillmentDetails={
                  fulfillmentDetails.filter(
                    (record: any) => record.fulfillment_code === fulfillmentCode
                  ) || []
                }
                passingInteraction={(callback: CallBackPayloadType) => {}}
                isDeleted={(val: boolean) => {}}
                documentType={props.documentType}
              />
            </div>
          )}
        {!Utility.isEmptyObject(salesReturnsDetails) &&
          DOC_TYPE.SALES_RETURN === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Sales Return Records`)}
              <SalesReturnRecordDetailWithTable
                documentDetails={props.data}
                salesReturnsDetails={
                  salesReturnsDetails.filter(
                    (record: any) => record.salesReturnCode === salesReturnCode
                  ) || []
                }
                passingInteraction={(callback: CallBackPayloadType) => {}}
                isDeleted={(val: boolean) => {}}
                isClosed={(val: boolean) => {}}
                documentType={props.documentType}
              />
            </div>
          )}
        {!Utility.isEmptyObject(paymentRecordDetails) &&
          DOC_TYPE.Payment === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Payment Records`)}
              <PaymentRecordsDetail
                documentSequenceCode={
                  paymentRecordDetails?.documentSequenceCode
                }
                documentDetails={props.data}
                paymentData={paymentRecordData}
                paymentRecordList={paymentRecords}
                documentType={props.documentType}
                documentCode={paymentRecordDetails?.salesInvoiceCode}
                contactCode={paymentRecordDetails?.contactCode}
                closePopup={() => {}}
                isLinkedRecordView={true}
                passingInteraction={(callback: CallBackPayloadType) => {}}
                isDocumentEmailFlow={props.isDocumentEmailFlow}
                selectedIndex={selectedIndex}
              />
            </div>
          )}
        {!Utility.isEmptyObject(receivedGoodsDetails) &&
          DOC_TYPE.BILL === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Received Goods Records`)}
              <ReceivedGoodsRecordWithDetails
                documentDetails={props.data}
                receivedGoodsDetails={
                  receivedGoodsDetails.filter(
                    (receivedGoods: any) =>
                      receivedGoods.goods_receipt_code ===
                      receivedGoodRecordData
                  ) || []
                }
                passingInteraction={(callback: CallBackPayloadType) => {}}
                documentType={props.documentType}
                isDeleted={(val: boolean) => {}}
              />
            </div>
          )}
        {!Utility.isEmptyObject(purchaseReturnsDetails) &&
          DOC_TYPE.PURCHASE_RETURN === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Purchase Return Records`)}
              <PurchaseReturnRecordWithDetails
                documentDetails={props.data}
                salesReturnsDetails={
                  purchaseReturnsDetails.filter(
                    (purchaseReturns: any) =>
                      purchaseReturns.purchaseReturnCode === purchaseReturnCode
                  ) || []
                }
                passingInteraction={(callback: CallBackPayloadType) => {}}
                documentType={props.documentType}
                isDeleted={(val: boolean) => {}}
                isClosed={(val: boolean) => {}}
              />
            </div>
          )}
        {!Utility.isEmptyObject(journalEntryDetails) &&
          DOC_TYPE.JOURNAL_ENTRY === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Journal Entry Records`)}
              <JournalEntryRecordsDetail
                documentDetails={props.data}
                journalEntryDetails={
                  journalEntryDetails.filter(
                    (journalEntry: any) =>
                      journalEntry.jeCode === journalEntryCode
                  )[0] || []
                }
              />
            </div>
          )}

        {!Utility.isEmptyObject(additionalLinkedJEData) &&
          !Utility.isEmptyObject(selectedAdditionalJEInfo) &&
          [
            DOC_TYPE.FULFILLMENT_JOURNAL_ENTRY.toString(),
            DOC_TYPE.GOODS_RECEIPT_JOURNAL_ENTRY.toString(),
            DOC_TYPE.MAKE_PAYMENT_JOURNAL_ENTRY.toString(),
            DOC_TYPE.RECEIVE_PAYMENT_JOURNAL_ENTRY.toString()
          ].includes(activeTab) && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(headerTitleForAdditionalJE(activeTab))}
              <JournalEntryRecordsDetail
                journalEntryDetails={
                  additionalLinkedJEData?.filter(
                    (data: any) =>
                      data.linkedDocumentType ===
                        selectedAdditionalJEInfo?.jeType &&
                      data.linkedDocument?.jeCode ===
                        selectedAdditionalJEInfo?.jeCode
                  )?.[0]?.linkedDocument || []
                }
              />
            </div>
          )}
      </>
    );
  };

  const getNoDataView = () => {
    return (
      <div
        className="parent-width column justify-content-center align-items-center"
        style={{ pointerEvents: 'none', height: 350 }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={'No linked record found'} className="fw-m mt-l" />
      </div>
    );
  };
  return (
    <PopupWrapper
      clickAction={() => props?.onCancel?.()}
      type={POPUP_TYPE.POPUP}
      title={'Linked Records'}
      btnList={popupBtnConfig}
      width={`90%`}
      height={'auto'}
      disableClickOutside={true}
      showScrollBar={false}
      popupContainerStyles={{
        padding: 0,
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem'
      }}
    >
      {(fulfillmentDetails && fulfillmentDetails?.length > 0) ||
      salesReturnsDetails?.length > 0 ||
      (receivedGoodsDetails && receivedGoodsDetails?.length > 0) ||
      purchaseReturnsDetails?.length > 0 ||
      paymentRecordDetails?.length > 0 ||
      journalEntryDetails?.length > 0 ? (
        <div className="row parent-width">
          {getAllRecordSections()}
          {getDetailRecordView()}
        </div>
      ) : (
        getNoDataView()
      )}
    </PopupWrapper>
  );
}
