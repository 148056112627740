import {
  BOOKS_DATE_FORMAT,
  DATE_FORMAT,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';
import Utility from '../Utility/Utility';
import { CallBackPayloadType } from './Interfaces';

import { DropdownTag, InputTag } from './NewContact';

export const DEFAULT_TDS_PAYABLE_ACCOUNT_CODE = 'ACC40012';
export interface NewTDSProps {
  formData?: any;
  populateFormData?: any;
  isEdit?: boolean;
  onCancel?: (callback: any) => void;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

export interface TDSRate {
  natureOfPayment: string;
  resident: boolean;
  deducteeType: string;
  applicableFromDate: string;
  tdsAccountPayable: string;
  tdsRate: number;
  thresholdLimit: number;
  id?: any;
}

export const TDSRateInitialState: TDSRate = {
  natureOfPayment: '',
  resident: true,
  deducteeType: '',
  applicableFromDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  tdsAccountPayable: DEFAULT_TDS_PAYABLE_ACCOUNT_CODE,
  tdsRate: 0,
  thresholdLimit: 0
};

export interface TDSNatureOfIncome {
  natureOfPayment: string;
  sectionCode: string;
}

export const TDSNatureOfIncomeInitialState: TDSNatureOfIncome = {
  natureOfPayment: '',
  sectionCode: ''
};

export interface TDSDeducteeType {
  code: string;
  type: string;
}

export const TDSDeducteeTypeInitialState: TDSDeducteeType = {
  code: '',
  type: ''
};

export interface TCSRate {
  accountPayable: string;
  accountReceivable: string;
  ratePercentage: number | any;
  tcsCollectionNatureId: any;
  taxName: string;
  id?: any;
}

export const TCSRateInitialState: TCSRate = {
  accountPayable: 'ACC40016',
  accountReceivable: 'ACC30022',
  ratePercentage: '',
  tcsCollectionNatureId: '',
  taxName: ''
};
