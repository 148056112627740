import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { EMAIL_PATTERN } from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import { IEmailFormState } from './EmailComposer';
import { OptionsType } from 'react-select';

interface MultiEmailInputFieldProps {
  value: string[];
  placeHolder?: string;
  isMandatory?: boolean;
  dropDownStyle?: any;
  onValueChange: (value: string[], hasError: boolean) => void;
  hideBorderCSS?: boolean;
  disableUserInput?: boolean;
}
const components = {
  DropdownIndicator: null
};

const createOption = (label: string) => ({
  label,
  value: label
});

export default function MultiEmailInputField(props: MultiEmailInputFieldProps) {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (
    newValue: OptionsType<{
      label: string;
      value: string;
    }>
  ) => {
    const newValueArray = newValue.map((item) => item.value);
    props.onValueChange(newValueArray, false);
  };
  const handleKeyDown = (event: any) => {
    if (!inputValue || inputValue.length === 0) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if ((inputValue?.trim() || '') && EMAIL_PATTERN.test(inputValue)) {
          props.onValueChange([...props.value, inputValue], false);
          setInputValue('');
        } else {
          props.onValueChange([...props.value], true);
          setInputValue('');
        }
        if (event.preventDefault) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  let dropDownStyle = {
    container: (provided: any, state: any) => ({
      ...provided
    }),
    control: (provided: any, state: any) => {
      let color = 'var(--books-color)';
      if (Utility.isEmpty(props?.value) && props?.isMandatory) {
        color = 'red';
      }

      if (props?.hideBorderCSS) {
        return {
          ...provided,
          background: '#f4f4f6',
          borderColor: 'transparent',
          '&:hover': {
            borderColor: 'transparent'
          },
          '&:focus': {
            borderColor: 'transparent'
          }
        };
      } else {
        return {
          ...provided,
          background: '#f4f4f6',
          borderColor: state.isFocused ? color : provided.borderColor,
          '&:hover': {
            borderColor: color,
            boxShadow: state.isFocused
              ? `0 0 0 1px ${color}`
              : provided.boxShadow
          },
          boxShadow: state.isFocused
            ? `0 0 0 1px ${color}`
            : provided.boxShadow,
          ...props.dropDownStyle?.control
        };
      }
    },
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      fontSize: '14px',
      ...props.dropDownStyle?.input
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      fontSize: '13px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: props.dropDownStyle
          ? 'transparent'
          : 'var(--books-color)',
        color: props.dropDownStyle ? '#000000' : '#FFFFFF'
      }
    })
  };

  if (props.disableUserInput) {
    dropDownStyle = {
      ...dropDownStyle,
      multiValueRemove: (provided: any, state: any) => ({
        ...provided,
        display: 'none'
      })
    };
  }

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isDisabled={props.disableUserInput}
        isClearable
        isMulti
        styles={dropDownStyle}
        menuIsOpen={false}
        onChange={handleChange}
        onBlur={() => {
          handleKeyDown({ key: 'Tab' });
        }}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={props.placeHolder}
        value={
          props.value !== undefined
            ? props.value.map((item) => createOption(item))
            : []
        }
      />
    </>
  );
}
