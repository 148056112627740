import React, { Component } from "react";
import "../../../index.css";
import { FONT_SIZE } from "../../../Constants/Constants";
import Utility, { getPageHeight, getVW } from "../../../Utilities/Utility";
import "../css/Templates.css";
import AddressParser, { AddressType } from "../../../Helper/AddressParser";

export default class USChequeTemplate3 extends Component {

    totalHeight = 196.64;
    checkAndgridVisibility = [
        {
            checkVisible:true
        },
        {
            gridVisible: true
        },
        {
            gridVisible: true
        }
    ]
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{  minHeight: getPageHeight()}}>
                <div className='parent-width' style={{ width: '100%'}}>
                    {this.renderCheque()}
                </div>
            </div>
        );
    }

    renderCheque() {
        if (this.state.data.lineItems === undefined || this.state.data.lineItems === null) {
            return undefined
        }

        if (this.state.data.lineItems.length === 0) {
            return undefined
        }

        var cheques = this.state.data.lineItems.map((element,ind) => {
            return this.checkAndgridVisibility.map((visibility,index)=>{
                if(visibility.checkVisible) {
                    return this.renderChequeContainer(element,index, ind)
                } else {
                    return this.renderBlankContainer(element,index, ind)
                }
            })
        });

        return cheques
    }

    renderChequeContainer(element,index, mainIndex) {
        return <div className="break-inside-avoid " key={`main-container_${index}_${mainIndex}`} style={{ paddingTop: getVW(5), paddingBottom: getVW(65)}}>
            <div >
                <div style={{
                    height: getVW(30+((index%3)*15)),
                }}>
                </div>
                {this.renderDateContainer(element)}
                <div style={{
                    height: getVW(35),
                }}>
                </div>

                {/* Contact Name And Amount */}
                {this.renderTopHeaderContainer(element)}
                <div style={{
                    height: getVW(10),
                }}>
                </div>
                            {/* Amount in word */}
                {this.renderMiddleContainer(element)}
                {/* contact and address */}
                <div className="RowDiv" style={{ maxHeight: getVW(90), minHeight: getVW(90) }} >
                    { this.state.data.showClientBillToAddress &&this.renderBottomContainer(element)}
                </div>
                {/* Memo */}
                <div className="RowDiv" style={{ maxHeight: getVW(20), minHeight: getVW(20),alignItems: "flex-end" }}>
                {this.renderMemoContainer(element)}
                </div>
            </div>
        </div>
    }

    renderBlankContainer(element,index, mainIndex) {
        return <div className="break-inside-avoid " key={`main-blank-container_${index}_${mainIndex}`} style={{ paddingTop: getVW(5), paddingBottom: getVW(10)}}>
            <div >
                <div style={{ height: getVW(20)}}></div>
                {this.renderBlankDateContainer(element)}
                {this.renderTopMemoContainer(element)}
                <div style={{ height: getVW(45) }}></div>

                {/* Contact Name And Amount */}
                <div style={{ height: getVW(10) }}></div>
                            {/* Amount in word */}
                {this.renderMiddleContainer(null)}
                {/* contact and address */}
                <div className="RowDiv" style={{ maxHeight: getVW(90), minHeight: getVW(90) }} >
                </div>
                {/* Memo */}
                <div className="RowDiv" style={{ maxHeight: getVW(75), minHeight: getVW(75),alignItems: "flex-end" }}>
                {this.renderBlankChequeBottomContainer(element)}
                </div>
            </div>
        </div>
    }

    renderDateContainer(element) {
        // date 
        return <div className="RowReverseDiv">
            {this.renderTopHeaderDataColumn(element?.date, true)}
        </div>
    }

    renderBlankDateContainer(element) {
        // contact and date 
        return <div className="RowDiv">
            <div style={{
                width: '1%',
                paddingLeft: getVW(5.6)
            }}></div>
            <div style={{
                width: '69%',
                paddingLeft: getVW(7.6)
            }}>
                {this.renderTopHeaderDataColumn(element?.payTo)}
            </div>
            <div style={{
                width: '30%',
                paddingLeft: getVW(7.6)
            }}>
                {this.renderTopHeaderDataColumn(element?.date, true)}
            </div>            
        </div>
    }

    renderTopHeaderContainer(element) {
        return <div className="RowDiv">
            <div style={{
                width: '5%',
                paddingLeft: getVW(7.6)
            }}></div>
            <div style={{
                width: '85%',
                paddingLeft: getVW(7.6)
            }}>
                {/* contact name  */}
                {this.renderTopHeaderDataColumn(element?.payTo)}
            </div>
            {/* Amount in Digit */}
            <div style={{
                width: '10%',
                paddingRight: getVW(2)
            }}>
                {this.renderMiddleDataColumn(element?.amount ? Utility.toCurrencyFormat(element?.amount, ''):'', undefined, '',true)}
            </div>
        </div>
    }

    renderMiddleContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
            }}>
                {/* Amount in word */}
            {this.renderMiddleDataColumn(element?.amountInWords, { padding: getVW(7.6)})}
        </div>
    }

    renderBottomContainer(element) {
        let address = AddressParser.getVisibleAddressFields(AddressType.billTo, {...this.state.data,clientBillToAddressObj: element?.contactInfo?.billingAddress},this.state.data)
        address = `${element?.payTo}<br>${address}`
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                paddingLeft: getVW(0)
            }}>
            <div style={{
                width: '5%',
                paddingLeft: getVW(7.6)
            }}></div>
            <div style={{
                whiteSpace: 'pre-line',
                width:'55%',
                paddingLeft: getVW(7.6),
                ...this.defaultStyle()
            }}
            dangerouslySetInnerHTML={{ __html: address }}>
                             
            </div>
            <div style={{
                width:'40%',
                paddingLeft: getVW(0),
                ...this.defaultStyle(),
            }}>
            </div>
        </div>
    }

    renderMemoContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                paddingLeft: getVW(0)
            }}>
            <div style={{
                width: '3%',
                paddingLeft: getVW(7.6)
            }}></div>
            <div style={{
                whiteSpace: 'pre-line',
                width:'55%',
                paddingLeft: getVW(7.6),
                ...this.defaultStyle()
            }}>
                {element.notes}
            </div>
            <div style={{
                width:'40%',
                paddingLeft: getVW(0),
                ...this.defaultStyle(),
            }}>
            </div>
        </div>
    }

    renderTopMemoContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                paddingLeft: getVW(0)
            }}>
            
            <div style={{
                ...this.defaultStyle(),
                whiteSpace: 'pre-line',
                width:'100%',
                textAlign: 'center'                
            }}>
                {element.notes}
            </div>
        </div>
    }

    renderBlankChequeBottomContainer(element) {
        return <div className="RowDiv"
            style={{
                alignItems: 'baseline',
                paddingLeft: getVW(0)
            }}>
            <div style={{
                width: '1%',
            }}></div>
            <div style={{
                whiteSpace: 'pre-line',
                width:'99%',
                paddingLeft: getVW(7.6),
                ...this.defaultStyle()
            }}>
                {element.accountName}  &nbsp;&nbsp;  {element.notes}
            </div>
            
        </div>
    }

    renderTopHeaderTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderTopHeaderDataColumn(text, leftSpace) {
        return <div style={{
            paddingLeft: getVW(leftSpace ? 24 : 8),
            paddingRight: getVW(leftSpace ? 0 : 8),
            ...this.defaultStyle()
        }}>
            {text}{leftSpace ? '': <>&nbsp;</>}
        </div>
    }

    renderMiddleTitleColumn(text) {
        return <div style={{
            fontWeight: 'bold',
            paddingTop: getVW(7.6),
            paddingBottom: getVW(7.6),
            ...this.defaultStyle()
        }}>
            {text} &nbsp;
        </div>
    }

    renderMiddleDataColumn(text, additionalStyle, className,leftSpace) {
        return <div className={className} style={{
            ...additionalStyle,
            ...this.defaultStyle()
        }}>
            {text}{leftSpace ? '': <>&nbsp;</>}
        </div>
    }



    defaultStyle() {
        return {
            fontFamily: 'calibri san-serif',
            lineHeight: '115%',
            textAlign: 'left',
            ...Utility.getFontStyleProp({fontSize: FONT_SIZE.medium}, this.state.data.printScale)
        }
    }
}
