import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKCheckMark,
  DKLabel,
  DKButton,
  DKInput
} from 'deskera-ui-library';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized,
  getRandomNumber
} from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';

export const ILGlobalBankDetailsPopup = (props: any) => {
  //states

  const [abaNumber, setabaNumber] = useState(props?.currentReason?.abaNumber);
  const [bankAddress, setbankAddress] = useState(
    props?.currentReason?.bankAddress
  );
  const [bankAccountNumber, setBankAccountNumber] = useState(
    props?.currentReason?.bankAccountNumber
  );
  const [bankAccountName, setbankAccountName] = useState(
    props?.currentReason?.bankAccountName
  );
  const [swiftCode, setswiftCode] = useState(props?.currentReason?.swiftCode);
  const [bankName, setBankName] = useState(props?.currentReason?.bankName);
  const [iban, setiban] = useState(props?.currentReason?.iban);

  const [isDetailsFormSubmitted, setIsDetailsFormSubmitted] = useState(false);
  const [isFormEdit, setIsFormEdit] = useState(
    !Utility.isEmpty(props?.currentReason) ? true : false
  );
  const [validateForm, setValidateForm] = useState(false);

  const onSaveClicked = () => {
    const payload = {
      bankAddress,
      abaNumber,
      bankName,
      bankAccountName,
      iban,
      swiftCode,
      bankAccountNumber
    };
    if (Utility.isEmptyObject(payload?.bankName)) {
      setValidateForm(true);
      return;
    }
    setIsDetailsFormSubmitted(true);
    if (isFormEdit) {
      props.onEdit(payload, props?.currentReason?.index);
    } else {
      props.onSave(payload);
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '40%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {/* header */}
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text={`${!isFormEdit ? 'Add' : 'Update'} Global Bank Details`}
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Cancel'}
              className="bg-white border-m mr-r"
              onClick={() => {
                props.onCancel();
              }}
            />
            <DKButton
              title={!isFormEdit ? 'Save' : 'Update'}
              className="bg-button text-white"
              onClick={onSaveClicked}
            />
          </div>
        </div>
        {/* form */}
        <div className="parent-width p-l">
          <div className="parent-width row justify-between">
            {/* subtype name */}
            <DKInput
              title="Bank Name"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankName}
              className="mr-5"
              canValidate={validateForm}
              onChange={(val: any) => {
                setBankName(val);
                setValidateForm(Utility.isEmptyObject(val));
              }}
              required={true}
            />
            <DKInput
              title="ABA Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={abaNumber}
              onChange={(val: any) => {
                setabaNumber(val);
              }}
              canValidate={false}
              required={false}
            />
          </div>
          <div className="parent-width row justify-between mt-3">
            {/* subtype name */}
            <DKInput
              title="Bank Address"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              required={false}
              value={bankAddress}
              className="mr-5"
              onChange={(val: any) => {
                setbankAddress(val);
              }}
            />
            <DKInput
              title="Bank Account Number"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankAccountNumber}
              className=""
              onChange={(val: any) => {
                setBankAccountNumber(val);
              }}
              required={false}
            />
          </div>
          <div className="parent-width row justify-between mt-3">
            {/* subtype name */}
            <DKInput
              title="Bank Account Name"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={bankAccountName}
              className="mr-5"
              onChange={(val: any) => {
                setbankAccountName(val);
              }}
              required={false}
            />
            <DKInput
              title="Bank Swift Code"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={swiftCode}
              className=""
              onChange={(val: any) => {
                setswiftCode(val);
              }}
              required={false}
            />
          </div>
          <div className="row justify-between mt-3" style={{ width: '49%' }}>
            {/* subtype name */}

            <DKInput
              title="Bank IBAN Code"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={iban}
              className=""
              onChange={(val: any) => {
                setiban(val);
              }}
              required={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
