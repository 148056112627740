import React, { Component } from "react";
import "../../index.css";
import ComponentManager from "../../Manager/ComponentManager";
import { EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DocumentManager from '../../Manager/DocumentManager';
import "../../CommonStyles/FontStyle.css"
import Utility, { getCapitalized, getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW} from '../../Utilities/Utility';
import {getLocalisedText} from '../../Translate/LanguageManager';
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

// import {StyleSheet} from "react";

export default class DocumentTemplate11 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    getDocumentTitleLabel() {
        return(
            <div className="RowDiv" style={{ justifyContent: 'flex-end', top: 0}}>
                <text
                    style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        color: 'black',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    { getLocalisedText(this.state.data.documentType) }
                </text>
            </div>
        )
    }

    getRowValueSection(title, value, titleColor = "black") {
        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: "space-evenly",
                    flex: 1,
                    height: getVW(Utility.convertToPrintScale(40, this.state.data.printScale)),
                    backgroundColor: this.state.data.themeColor,
                    color: 'white',
                    border: 'solid',
                    borderColor: this.state.data.themeColor,
                    borderWidth: getVW(1),
                }}
            >
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                        // justifyContent: 'center',
                        // border: 'solid',
                        // borderColor: 'lightgray',
                        // borderWidth: 1,
                        marginLeft: getVW(Utility.convertToPrintScale(14, this.state.data.printScale)),
                        width: '100%',
                        height: getVW(Utility.convertToPrintScale(28, this.state.data.printScale)),
                        backgroundColor: this.state.data.themeColor
                    }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: titleColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {title.toUpperCase()}
                </text>
                </div>
                <div
                    className="RowDiv"
                    style={{
                        flexDirection: 'row',
                        // border: 'solid',
                        // borderColor: 'lightgray',
                        // borderWidth: 1,
                        width: '100%',
                        height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale))}}>
                    <text className={"RowDiv docFontStyle" + getFontSizeClass()}
                        style={{
                            paddingLeft: Utility.getIsArabicLang() ? 0 : getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            height: getVW(Utility.convertToPrintScale(25, this.state.data.printScale)),
                            justifyContent: Utility.getIsArabicLang() ? 'flex-end' : 'center',
                            paddingRight: Utility.getIsArabicLang() ? getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) : 0,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {value}
                    </text>
                </div>
            </div>
        );
    }

    getInvoiceDetailsView() {
        return(
            <div className="ColumnDiv" style={{ alignItems: 'flex-end', width: '100%'}}>
                <div className="ColumnDiv" style={{ width: '70%', height: '100%'}}>
                    {this.getDocumentDetailsSection()}
                </div>
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return this.getRowValueSection(
                element.label,
                value,
                'white',
            )
        });
    }

    getCompanyNameLabel() {
        return(
            <div className="RowDiv"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: 'black',
                        fontWeight: '500',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                    }}
                >
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        )
    }

    getCompanyInformationView() {
        return(
            <div className="RowDiv"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                }}>
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        whiteSpace: 'pre-wrap',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale),
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(Utility.getCompanyAddress(this.state.data)) }}>
                </div>
            </div>
        )
    }

    getAddressAndDocDetailView() {
        return(
            <div className="RowDiv" style={{ width: '100%' }}>
                <div className="RowDiv" style={{ width: '100%' }}>
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    getLocalisedText(this.state.data.billToTitle).toUpperCase(),
                    Utility.getClientBillToAddress(this.state.data),
                    "40%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "20%" }} />}
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    getLocalisedText(this.state.data.shipToTitle).toUpperCase(),
                    Utility.getClientShipToAddress(this.state.data),
                    "40%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.shipTo),
                    this.state.data.printScale
                )}
                </div>
                {Utility.getIsContainsTotalSection(this.state.data)  &&
                    ComponentManager.getTitleAndValueColumn(
                        getCapitalized(getLocalisedText(this.state.data.documentType)) + " " + getLocalisedText("total"),
                        Utility.getTotalValue(this.state.data),
                        'gray',
                        this.state.data.themeColor,
                        Utility.getIsArabicLang() ? 'flex-end' : 'center',
                        FONT_SIZE.regular,
                        FONT_SIZE.xLarge,
                        'regular',
                        0,
                        Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                        this.state.data.printScale
                    )
                }
            </div>
        )
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '42%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }

    addFooterView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ width: '57%', zIndex: 100}}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && <div style={{ height: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)) }} />}
                {(this.state.data.showNotes && this.state.data.notes) &&
                ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "100%",
                    "gray",
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div>
        )
    }

    addHeaderSection() {
        return (
            <div className="RowDiv" style={{ width: '100%',alignItems: 'flex-start'}}>
                <div className="ColumnDiv" style={{ width: '100%'}}>
                    {this.state.data.showCompanyName && this.getCompanyNameLabel()}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(10)}
                    {this.state.data.showFrom && this.getCompanyInformationView()}
                </div>
                <div className="ColumnDiv" style={{ width: '100%'}}>
                    {this.getDocumentTitleLabel()}
                    {ComponentManager.addVerticalSpace(20)}
                    {this.getInvoiceDetailsView()}
                </div>

            </div>
        )
    }

    render() {
        return (
            <div className="DocumentHolder" style={{ minHeight: getPageHeight(this.state.data.printScale)}}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {/* section 0 */}
                    {this.addHeaderSection()}
                    {/* section 1 */}
                    {ComponentManager.addVerticalSpace(30)}
                    {/* section 2 */}
                    {this.getAddressAndDocDetailView()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {/* section 3 */}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.addFooterView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                    <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerTextColor = "white";
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBorderColor = 'transparent';
        style.itemBackgroundColor = this.state.data.themeColor + '1A';
        style.itemBackgroundColorIsAlternate = true;
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerTextColor = "white";
        style.footerBorderColor = this.state.data.themeColor
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['20%', '30%', '50%'] : ['30%', '35%', '35%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}

