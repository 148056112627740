import { showLoader, removeLoader } from 'deskera-ui-library';
import IamManager from '../Managers/IamManager';
import ApiConstants from '../Constants/ApiConstants';
import http from '../Services/Interceptor';

export default class IamService {
  static getLoginStatus(params: any) {
    return http.get(ApiConstants.URL.IAM.LOG_OUT, { params: params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static logOut() {
    showLoader('Logging out...');
    return http.get(ApiConstants.URL.IAM.LOG_OUT_V2).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static switchTenant(params: any) {
    showLoader('Switching tenant...');
    localStorage.setItem('selectedPipeline', '0');
    return http.post(ApiConstants.URL.IAM.SWITCH_TENANT, params).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static switchClientTenant(params: any) {
    showLoader('Switching tenant...');
    localStorage.setItem('selectedPipeline', '0');
    return http.post(ApiConstants.URL.IAM.SWITCH_CLIENT_TENANT, params).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static getTwoFactorAuthStatus(params: any) {
    showLoader('Getting status...');
    return http.get(ApiConstants.URL.IAM.TFA_STATUS, { params: params }).then(
      (res) => {
        removeLoader();
        IamManager.setTFAStatus(res);
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
}
export const fetchClientLocation = () => {
  return http.get(ApiConstants.URL.ANONYMOUS.LOCATION);
}