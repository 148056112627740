import ApiConstants from '../Constants/ApiConstants';
import { AGENCIES_CONSTANTS, STATUS_TYPE } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface AgenciesAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  Dir?: string;
  Query?: string;
  QueryParam?: any;
}

const defaultConfig: AgenciesAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: AGENCIES_CONSTANTS.SortDir,
  Sort: AGENCIES_CONSTANTS.Sort,
  Dir: AGENCIES_CONSTANTS.Dir,
  Query: '',
  QueryParam: ''
};

class AgenciesService {
  static apiConfig: AgenciesAPIConfig;

  static getAllAgencies() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    return http
      .get(
        `${ApiConstants.URL.AGENCIES.GET_ALL_AGENCIES}?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&sortDir=${this.apiConfig.SortDir}&sort=${this.apiConfig.Sort}`
      )
      .catch((err: any) => {
        console.error('Error loading agencies: ', err);
        return Promise.reject(err);
      });
  }

  static saveAgency(payload: any) {
    return http
      .post(ApiConstants.URL.AGENCIES.SAVE_AGENCY, payload)
      .catch((err: any) => {
        console.error('Error saving agency: ', err);
        return Promise.reject(err);
      });
  }

  static updateAgency(id: any, payload: any) {
    return http
      .put(ApiConstants.URL.AGENCIES.UPDATE_AGENCY(id), payload)
      .catch((err: any) => {
        console.error('Error updating agency: ', err);
        return Promise.reject(err);
      });
  }

  static changeAgencyStatus(id: number, status: any) {
    var data = JSON.stringify(false);
    if (status === STATUS_TYPE.ACTIVE) {
      data = JSON.stringify(true);
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return http
      .put(ApiConstants.URL.AGENCIES.CHANGE_AGENCIES_STATUS(id), data, config)
      .catch((err: any) => {
        console.error('Error updating agency status: ', err);
        return Promise.reject(err);
      });
  }
}

export default AgenciesService;
