import React, { useEffect, useState } from 'react';
import { getFormattedAddressObj } from '../DocumentForm/NewDocumentHelper';
import {
  DKButton,
  DKIcon,
  DKLabel,
  DKContactIcon,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import {
  BOOKS_ADDRESS_TYPES,
  FORM_ELEMENTS,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import {
  AddressType,
  EditAddressInputType,
  EditAddressProps,
  EditAddressState,
  NewAddressType
} from '../../Models/EditAddress';
import { BooksAddress } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import {
  COUNTRIES_LIST,
  INDIAN_STATES_MOCK_DATA,
  USstateMockData
} from '../../Constants/StaticData';
import ContactService from '../../Services/Contact';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { useAppSelector } from '../../Redux/Hooks';

const UpdateAddress: React.FC<EditAddressProps> = (props) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [country, setCountry] = useState<any>();
  const [defaultState, setDefaultState] = useState<string>('');
  const [stateList, setStateList] = useState<string[]>([]);

  useEffect(() => {
    let defaultCountry: any = COUNTRIES_LIST.find(
      (country: any) => country.code === tenantInfo.country
    );
    setCountry(defaultCountry);
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    if (country) {
      switch (country.code) {
        case 'IN':
          setStateList(INDIAN_STATES_MOCK_DATA.map((x) => x.name));
          setDefaultState(INDIAN_STATES_MOCK_DATA[0].name);
          if (editAddressState.newAddress.length) {
            inputChanged(
              INDIAN_STATES_MOCK_DATA[0].name,
              EditAddressInputType.STATE,
              0
            );
          }
          break;
        case 'US':
          setStateList(USstateMockData.map((x) => x.name));
          setDefaultState(USstateMockData[0].name);
          if (editAddressState.newAddress.length) {
            inputChanged(
              USstateMockData[0].name,
              EditAddressInputType.STATE,
              0
            );
          }
          break;
        default:
          setStateList([]);
          setDefaultState('');
          if (editAddressState.newAddress.length) {
            inputChanged('', EditAddressInputType.STATE, 0);
          }
          break;
      }
    }
  }, [country]);

  const headerTitle =
    props.addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
      ? 'Edit Billing Address'
      : 'Edit Shipping Address';

  const newAddressItem: NewAddressType = {
    contactName: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    address: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    postalCode: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    country: {
      key: '',
      hidden: false,
      value: country,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    state: {
      key: '',
      hidden: false,
      value: defaultState,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    city: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    }
  };

  const [existingAddressExpanded, setExistingAddressExpanded] =
    useState<boolean>(false);
  const [newlyAddressExpanded, setNewlyAddressExpanded] =
    useState<boolean>(true);

  const getActiveAddress = () => {
    let activeAddress = null;
    if (!Utility.isEmpty(props.addressList)) {
      const preferredAddressIndex = props.addressList?.findIndex(
        (x) => x.preferred
      );
      if (preferredAddressIndex !== -1) {
        activeAddress = {
          addressType: AddressType.EXISTING_ADDRESS,
          index: preferredAddressIndex
        };
      } else {
        activeAddress = {
          addressType: AddressType.NEWLY_ADDED_ADDRESS,
          index: 0
        };
      }
    } else {
      activeAddress = {
        addressType: AddressType.NEWLY_ADDED_ADDRESS,
        index: 0
      };
    }

    return activeAddress;
  };

  const initialState: EditAddressState = {
    existingAddress: props.addressList ? props.addressList : [],
    newAddress: [],
    activeAddress: getActiveAddress() as any
  };

  const [editAddressState, setEditAddressState] =
    useState<EditAddressState>(initialState);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ADDRESS,
        data: () => {
          saveAddress();
        }
      });
    }
  };

  const catchAddressAction = (action: string, index: number) => {
    const updatedAddressList = editAddressState;
    if (action === 'add') {
      updatedAddressList.newAddress.push(newAddressItem);
    } else {
      updatedAddressList.newAddress.splice(index, 1);
    }
    setEditAddressState({ ...updatedAddressList });
  };

  const inputChanged = (
    inputValue: string | number,
    actionType: EditAddressInputType,
    index: number
  ) => {
    const newState = editAddressState;
    switch (actionType) {
      case EditAddressInputType.CONTACT_NAME:
        newState.newAddress[index].contactName.value = inputValue;
        break;
      case EditAddressInputType.ADDRESS:
        newState.newAddress[index].address.value = inputValue;
        break;
      case EditAddressInputType.POSTAL_CODE:
        newState.newAddress[index].postalCode.value = inputValue;
        break;
      case EditAddressInputType.CITY:
        newState.newAddress[index].city.value = inputValue;
        break;
      case EditAddressInputType.COUNTRY:
        newState.newAddress[index].country.value = inputValue;
        break;
      case EditAddressInputType.STATE:
        newState.newAddress[index].state.value = inputValue;
        break;
      default:
        break;
    }
    newState.activeAddress = {
      index: index,
      addressType: AddressType.NEWLY_ADDED_ADDRESS
    };
    setEditAddressState({ ...newState });
  };

  const checkboxClicked = (type: AddressType, radioIndex: number) => {
    const newState = editAddressState;
    newState.activeAddress = { index: radioIndex, addressType: type };
    setEditAddressState({ ...newState });
  };

  const saveAddress = () => {
    const { addressType, index: position } = editAddressState.activeAddress;
    const existingAddress: BooksAddress[] =
      editAddressState.existingAddress.map((address, index) => {
        return {
          ...address,
          preferred:
            addressType === AddressType.EXISTING_ADDRESS && position === index
        };
      });

    let newAddress: BooksAddress[] = editAddressState.newAddress.map(
      (address, index) => {
        const country = COUNTRIES_LIST.find(
          (country) => address.country.value?.code === country.code
        );
        //@ts-ignore
        const addressObj: BooksAddress = {
          contactName: address.contactName.value.toString(),
          address1: address.address.value.toString(),
          postalCode: address.postalCode.value.toString(),
          country: country ? country.name : '',
          city: address.city.value.toString(),
          state: address.state.value.toString(),
          address2: null,
          preferred:
            addressType === AddressType.NEWLY_ADDED_ADDRESS &&
            position === index
        };
        return addressObj;
      }
    );

    const isNewAddressPrefferedIndex = newAddress.findIndex((x) => x.preferred);
    if (isNewAddressPrefferedIndex !== -1) {
      if (
        tenantInfo.country === 'IN' &&
        Utility.isEmpty(newAddress[isNewAddressPrefferedIndex].state)
      ) {
        showToast('State is required', TOAST_TYPE.FAILURE);
        return;
      }

      if (
        tenantInfo.country === 'US' &&
        Utility.isEmpty(newAddress[isNewAddressPrefferedIndex].postalCode)
      ) {
        showToast('Postal code is required', TOAST_TYPE.FAILURE);
        return;
      }
    } else {
      newAddress = [];
    }

    const finalAddressList: BooksAddress[] = [
      ...existingAddress,
      ...newAddress
    ];
    const contactDetails = props.contactDetails;
    const payload = {
      id: contactDetails.id,
      documentSequenceCode: contactDetails.documentSequenceCode,
      sequenceFormat: contactDetails.sequenceFormat,
      name: contactDetails.name,
      code: contactDetails.code,
      currencyCode: contactDetails.currencyCode,
      status: STATUS_TYPE.ACTIVE,
      customer: contactDetails.customer,
      vendor: contactDetails.vendor,
      receivableAccountCode: contactDetails.receivableAccountCode,
      payableAccountCode: contactDetails.payableAccountCode,
      paymentTermCode: contactDetails.paymentTermCode,
      billingAddress:
        props.addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
          ? finalAddressList
          : contactDetails.billingAddress,
      shippingAddress:
        props.addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
          ? finalAddressList
          : contactDetails.shippingAddress,
      customField: contactDetails.customField
    };
    ContactService.updateAddress(payload, contactDetails.id).then(
      (res) => {
        if (props.onAddressUpdate) {
          props.onAddressUpdate();
        }
      },
      (err) => console.error('Error saving address.', err)
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={headerTitle} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title="Update"
            className="bg-button text-white"
            onClick={() => saveAddress()}
          />
        </div>
      </div>
    );
  };

  const getFormattedAddress = (address: any) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName)) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1)) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2)) {
      formattedAddress += line2 + ', ';
    }
    if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }
    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const getExistingAddresses = () => {
    return (
      <div className="column parent-width mt-xl ">
        <DKLabel text="Existing Addresses" className="fw-m mb-r" />
        {editAddressState.existingAddress &&
          editAddressState.existingAddress.map((address, index) => {
            const { addressType, index: addressIndex } =
              editAddressState.activeAddress;
            return (
              <div className="row">
                <div className="w-auto mr-s mb-s">
                  <input
                    type="radio"
                    name="radio"
                    onClick={() =>
                      checkboxClicked(AddressType.EXISTING_ADDRESS, index)
                    }
                    checked={
                      addressType === AddressType.EXISTING_ADDRESS &&
                      addressIndex === index
                    }
                  />
                </div>
                <div
                  className="row flex-wrap items-start content-start justify-items-start border-s p-s border-radius-s mb-s"
                  style={{
                    gap: '2%',
                    backgroundColor: 'rgb(250, 250, 250)'
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(address)}
                    className="text-gray"
                    style={{ width: '94%' }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const getNewAddresses = () => {
    // const isNewAddressPrefferedIndex = editAddressState.newAddress.findIndex((x) => x.);
    return (
      <div className="column parent-width mt-xl mb-l ">
        {editAddressState.newAddress.length === 1 && (
          <DKLabel text="New Address" className="fw-m mb-r" />
        )}
        {editAddressState.newAddress.map((address, index) => {
          return (
            <div className="row parent-width mb-r">
              <div className="mr-s">
                <input
                  type="radio"
                  name="radio"
                  onClick={() =>
                    checkboxClicked(AddressType.NEWLY_ADDED_ADDRESS, index)
                  }
                  checked={
                    editAddressState.activeAddress.addressType ===
                      AddressType.NEWLY_ADDED_ADDRESS &&
                    editAddressState.activeAddress.index === index
                  }
                />
              </div>
              <div className="row parent-width flex-wrap justify-content-between border-s p-m border-radius-s gap-2">
                <div style={{ width: '100%' }}>
                  <DKInput
                    title=""
                    type={INPUT_TYPE.TEXT}
                    value={address?.contactName.value}
                    placeholder="ContactName"
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(val: string) => {
                      inputChanged(
                        val,
                        EditAddressInputType.CONTACT_NAME,
                        index
                      );
                    }}
                  />
                </div>

                <div style={{ width: '100%' }}>
                  <DKInput
                    title=""
                    type={INPUT_TYPE.TEXT}
                    value={address?.address.value}
                    placeholder="Address"
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(val: string) => {
                      inputChanged(val, EditAddressInputType.ADDRESS, index);
                    }}
                  />
                </div>

                <div className="row parent-width align-items-start gap-2">
                  <DKInput
                    title=""
                    type={INPUT_TYPE.TEXT}
                    value={address?.city.value}
                    placeholder="City"
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(val: string) =>
                      inputChanged(val, EditAddressInputType.CITY, index)
                    }
                  />

                  {!(stateList.length > 0) && (
                    <DKInput
                      title=""
                      type={INPUT_TYPE.TEXT}
                      value={address?.state.value}
                      placeholder="State"
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      canValidate={true}
                      errorMessage={'error message'}
                      onChange={(val: any) => {
                        inputChanged(val, EditAddressInputType.STATE, index);
                      }}
                    />
                  )}
                  {stateList.length > 0 && (
                    <DKInput
                      type={INPUT_TYPE.DROPDOWN}
                      formatter={(obj: string) => {
                        return obj;
                      }}
                      title={''}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      value={address?.state.value}
                      onChange={(option: any) => {
                        inputChanged(option, EditAddressInputType.STATE, index);
                      }}
                      dropdownConfig={{
                        style: { minWidth: 150 },
                        className: 'shadow-m width-auto',
                        data: stateList,
                        allowSearch: true,
                        searchableKey: 'name',
                        renderer: (index: any, obj: string) => {
                          return <DKLabel text={`${obj}`} />;
                        }
                      }}
                    />
                  )}
                </div>
                <div className="row parent-width align-items-start gap-2">
                  <DKInput
                    type={INPUT_TYPE.DROPDOWN}
                    formatter={(obj: any) => {
                      return obj.name;
                    }}
                    title={''}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    value={address?.country.value}
                    onChange={(option: any) => {
                      inputChanged(option, EditAddressInputType.COUNTRY, index);
                      setCountry(option);
                    }}
                    canValidate={Utility.isEmpty(address?.country.value)}
                    errorMessage={
                      Utility.isEmpty(address?.country.value)
                        ? 'Country is required'
                        : ''
                    }
                    dropdownConfig={{
                      style: { minWidth: 150 },
                      className: 'shadow-m width-auto',
                      data: COUNTRIES_LIST,
                      allowSearch: true,
                      searchableKey: 'name',
                      renderer: (index: any, obj: any) => {
                        return <DKLabel text={`${obj.name}`} />;
                      }
                    }}
                  />
                  <DKInput
                    title=""
                    type={INPUT_TYPE.TEXT}
                    value={address?.postalCode.value}
                    placeholder={`Postal Code ${
                      tenantInfo.country === 'US' ? '*' : ''
                    }`}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(val: string) =>
                      inputChanged(val, EditAddressInputType.POSTAL_CODE, index)
                    }
                  />
                </div>
              </div>
              <div
                className="ml-s opacity-50"
                title="Remove"
                onClick={() => catchAddressAction('remove', index)}
              >
                <DKIcon src={ic_delete} className="ic-s cursor-hand" />
              </div>
            </div>
          );
        })}
        {editAddressState.newAddress.length < 1 && (
          <DKButton
            title="+ Add address"
            onClick={() => catchAddressAction('add', 8)}
            className="fw-m text-blue mt-s"
          />
        )}
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 550,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div
          className="column p-l parent-width"
          style={{ maxHeight: 450, overflowY: 'auto' }}
        >
          <div className="row">
            <DKContactIcon
              title={props.contactDetails.name}
              className="bg-gray1"
            />
            <DKLabel
              text={props.contactDetails.name}
              className="fw-m fs-m ml-r"
            />
          </div>
          {getExistingAddresses()}
          {getNewAddresses()}
        </div>
      </div>
    </div>
  );
};

export default UpdateAddress;
