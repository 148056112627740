import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import BookKeeperClientService from '../../Services/BookKeeperClient';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchClientData = createAsyncThunk(
  'bkClientData',
  async (type: any) => {
    const response = await BookKeeperClientService.getClientOrTeamsGridData(
      type
    );
    return response;
  }
);

export const BookKeeperClientSlice = createSlice({
  name: 'BookKeeperClient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientData.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchClientData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {} = BookKeeperClientSlice.actions;

export const bkClient = (state: RootState) => {
  return state.bkClient?.data;
};

export default BookKeeperClientSlice.reducer;
