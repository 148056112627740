import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import {
  DOC_TYPE,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../../../Constants/Constant';
import { Store } from '../../../../Redux/Store';
import MRPProductsService from '../../../../Services/MRP/MRPProducts';
import ProcessManufacturingService from '../../../../Services/MRP/ProcessManufacturing';
import NumberFormatService from '../../../../Services/NumberFormat';
import Utility from '../../../../Utility/Utility';
import { MRP_JC_COLORS } from '../../Constants/MRPColumnConfigs';
import { PROCESS_QC_STATUS } from '../../Constants/MRPEnums';
import {
  currentRowDependentByAnyJC,
  JC_PROCESS_TYPE
} from '../../Shared/JobCardListComponent/JobCardListPresenter';
import { getStatusChipColor } from '../../WorkOrder/WorkOrderHelper';
import { JC_PROCESS_TYPE_DATA, JobCardHelper } from '../JobCardHelper';

//Constants
export const GENERALFIELD_JC_LABEL_WIDTH = 170;
export const GENERALFIELD_JC_VALUE_WIDTH = 120;
export const JC_POPUP_WIDTH_WITHOUT_PROCESS_MANAGEMENT = 850;
export const JC_POPUP_WIDTH_PROCESS_MANAGEMENT = 1250;
export const JC_PROCESS_MANAGEMENT_GRID_WIDTH =
  JC_POPUP_WIDTH_PROCESS_MANAGEMENT - 50;

export const costDetailsInitialState = {
  plannedCost: 0,
  additionalCost: 0,
  actualCost: 0
};

export const MANUFACTURING_JC_COLUMN_KEYS = {
  ITEM: 'item',
  PRODUCT_CODE: 'productCode',
  QC_NEEDED: 'qcNeeded',
  INVALID_FIELDS: 'invalidFields',
  ALLOCATED_QTY: 'allocatedQty',
  ASSIGNED_QTY: 'assignedQty',
  QC_STATUS: 'qcStatus',
  WO_CODE: 'workOrderCode',
  PRODUCED_QTY: 'producedQty'
};

const MANUFACTURING_JC_COLUMNS = [
  {
    name: 'Materials',
    type: INPUT_TYPE.DROPDOWN,
    index: 0,
    options: null,
    required: false,
    allowFilter: true,
    width: 240,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    dropdownConfig: {
      title: 'Select Component Material',
      allowSearch: true,
      searchableKey: 'productName',
      style: { minWidth: 'auto' },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: (index: any, obj: any) => {
        return '';
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.ITEM,
    key: MANUFACTURING_JC_COLUMN_KEYS.ITEM,
    id: MANUFACTURING_JC_COLUMN_KEYS.ITEM
  },
  {
    name: 'Material Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE,
    key: MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE,
    id: MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE
  },
  {
    name: 'WO Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.WO_CODE,
    key: MANUFACTURING_JC_COLUMN_KEYS.WO_CODE,
    id: MANUFACTURING_JC_COLUMN_KEYS.WO_CODE
  },
  {
    name: 'QC needed',
    type: INPUT_TYPE.DROPDOWN,
    index: 0,
    options: null,
    required: false,
    allowFilter: true,
    width: 120,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 'auto' },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED,
    key: MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED,
    id: MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED
  },
  {
    name: 'Total Allocated Qty',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    textAlign: 'right',
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.ALLOCATED_QTY,
    key: MANUFACTURING_JC_COLUMN_KEYS.ALLOCATED_QTY,
    id: MANUFACTURING_JC_COLUMN_KEYS.ALLOCATED_QTY
  },
  {
    name: 'Total Assigned Qty',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    textAlign: 'right',
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.ASSIGNED_QTY,
    key: MANUFACTURING_JC_COLUMN_KEYS.ASSIGNED_QTY,
    id: MANUFACTURING_JC_COLUMN_KEYS.ASSIGNED_QTY
  },
  {
    name: 'QC/Consume/Process status',
    type: INPUT_TYPE.DROPDOWN,
    index: 0,
    options: null,
    required: false,
    allowFilter: true,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 'auto' },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    columnCode: MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS,
    key: MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS,
    id: MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS
  }
];

export const PROCESS_MANUFACTURING_QC_OPTIONS = [
  {
    key: true,
    name: 'Yes'
  },
  {
    key: false,
    name: 'No'
  }
];

export class AddJobCardPresenter {
  formState: any;
  manufacturingRows: any;
  isLinkedInventory: any =
    Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .LINK_INVENTORY_JOB_CARDS;

  constructor(formData: any, manufacturingRowsData: any) {
    this.formState = formData;
    this.manufacturingRows = manufacturingRowsData;
  }

  getProcessTypeData = () => JC_PROCESS_TYPE_DATA;

  getSelectedProcessType = () => {
    return this.formState.processType;
  };

  getCurrentRowData = (items: any, productCode: any) => {
    const rowObjFound = items?.find((item: any) => {
      return item.productCode === productCode;
    });

    return !Utility.isEmpty(rowObjFound) ? rowObjFound : null;
  };

  getAttachedWOCode = (woData: any, rowData: any) => {
    const childFound = woData?.workOrderChildDetails?.find((child: any) => {
      return child.productCode === rowData?.productCode;
    });
    return childFound;
  };

  getQCHtmlContent = (currentRow: any) => {
    let htmlContent = `<div class="column">
    <div class="row">Total Quantity &nbsp;<b>${
      currentRow?.itemQuantity ?? 0
    }</b></div>
    <div class="row">Passed Quantity &nbsp;<b>${
      currentRow?.passedQuantity ?? 0
    }</b></div>
    <div class="row">Rejected Quantity &nbsp;<b>${
      currentRow?.rejectedQuantity ?? 0
    }</b></div>
    </div>`;

    return htmlContent;
  };

  ///////////////////// grid methods only - start /////////////////////////

  getProcessManufacturingColumns = () => {
    return MANUFACTURING_JC_COLUMNS;
  };

  getInitialManufacturingRow = () => {
    let row = [
      {
        item: null,
        qcNeeded: null,
        editable: true,
        invalidFields: [MANUFACTURING_JC_COLUMN_KEYS.ITEM]
      }
    ];

    return row;
  };

  processColumnConfig = (
    config: any[],
    gridRows: any[],
    workOrderData: any,
    jcFormData: any,
    jobCardMapping: any,
    callBack: any
  ) => {
    let updatedConfig = this.getProcessManufacturingColumns();

    let filteredConfig = updatedConfig
      ?.filter((columnObj: any) => {
        if (jcFormData?.processType?.key === JC_PROCESS_TYPE.PRODUCTION) {
          return (
            columnObj.key !== MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED &&
            columnObj.key !== MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS &&
            columnObj.key !== MANUFACTURING_JC_COLUMN_KEYS.ASSIGNED_QTY
          );
        }
        return columnObj;
      })
      .map((columnObj: any) => {
        columnObj = { ...columnObj };
        //production case
        if (jcFormData?.processType?.key === JC_PROCESS_TYPE.PRODUCTION) {
          columnObj.width = 250;
          if (columnObj.key === MANUFACTURING_JC_COLUMN_KEYS.ALLOCATED_QTY) {
            columnObj.name = 'Total Produced Qty';
            columnObj.id = MANUFACTURING_JC_COLUMN_KEYS.PRODUCED_QTY;
            columnObj.key = MANUFACTURING_JC_COLUMN_KEYS.PRODUCED_QTY;
          }
        }
        //consumption case
        if (jcFormData?.processType?.key === JC_PROCESS_TYPE.CONSUMPTION) {
          if (columnObj.key === MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS) {
            columnObj.name = 'QC/Consume status';
          }
        }
        //processing case
        if (jcFormData?.processType?.key === JC_PROCESS_TYPE.PROCESSING) {
          if (columnObj.key === MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS) {
            columnObj.name = 'QC/Process status';
          }
        }
        return columnObj;
      });

    filteredConfig.forEach((conf: any) => {
      switch (conf.key) {
        case MANUFACTURING_JC_COLUMN_KEYS.ITEM:
          const existingProduct: any[] = [];
          jobCardMapping?.allJCDetails?.forEach((jcObj: any) => {
            if (jcObj.jobCardLinkDetails?.[0]?.productCode) {
              existingProduct.push({
                productCode: jcObj.jobCardLinkDetails?.[0]?.productCode,
                processType: jcObj.jobCardLinkDetails?.[0]?.processType
              });
            }
          });
          conf.dropdownConfig.data = populateProducts(
            jcFormData?.processType?.key,
            workOrderData?.workOrderItems ?? [],
            existingProduct
          );

          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.productName ?? '';
          };

          conf.formatter = (data: any) => {
            return data?.rowData?.item?.productName ?? '';
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED:
          conf.dropdownConfig.data = this.getGridOptions();
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                text={obj?.name ?? ''}
                className={`${getStatusChipColor('')} ${
                  obj?.key
                    ? MRP_JC_COLORS.MRP_BG_GREEN
                    : MRP_JC_COLORS.MRP_BG_RED
                }`}
              />
            );
          };

          conf.renderer = (data: any) => {
            return data?.rowData?.qcNeeded?.name ? (
              <DKLabel
                text={data?.rowData?.qcNeeded?.name ?? ''}
                className={`${getStatusChipColor('')} ${
                  data?.rowData?.qcNeeded?.key
                    ? MRP_JC_COLORS.MRP_BG_GREEN
                    : MRP_JC_COLORS.MRP_BG_RED
                }`}
              />
            ) : (
              ''
            );
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.ALLOCATED_QTY:
          conf.formatter = (data: any) => {
            const totalAllocatedQty = this.getAllocatedQty(
              data?.rowData?.item?.warehouseInventoryData
            );
            return NumberFormatService.getNumber(totalAllocatedQty);
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.ASSIGNED_QTY:
          conf.formatter = (data: any) => {
            const totalAssignedQty = this.getAllocatedQty(
              data?.rowData?.item?.itemWarehouseInventoryData
            );
            return NumberFormatService.getNumber(totalAssignedQty);
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.PRODUCED_QTY:
          conf.formatter = (data: any) => {
            const totalProducedQty = JobCardHelper.getProducedQtyOnJCFormGrid(
              workOrderData,
              data?.rowData
            );
            return NumberFormatService.getNumber(totalProducedQty);
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.QC_STATUS:
          conf.dropdownConfig.data = [];

          conf.renderer = (data: any) => {
            let currentRow = this.getCurrentRowData(
              data?.rowData?.jobCardLinkDetails,
              data?.rowData?.productCode
            );
            let status =
              currentRow?.status === PROCESS_QC_STATUS.QA_DONE
                ? PROCESS_QC_STATUS.QC_DONE_UI_ONLY
                : currentRow?.status;

            // if (!data?.rowData?.qcNeeded?.key) {
            //   status = PROCESS_QC_STATUS.NOT_APPLICABLE_UI_ONLY;
            //   currentRow = {
            //     ...currentRow,
            //     status: PROCESS_QC_STATUS.NOT_APPLICABLE_UI_ONLY
            //   };
            // }

            status = status?.replaceAll('_', ' ');
            status = Utility.convertInTitleCase(status);

            return status ? (
              <DKTooltipWrapper
                content={
                  status !== PROCESS_QC_STATUS.NOT_APPLICABLE_UI_ONLY
                    ? this.getQCHtmlContent(currentRow)
                    : ''
                }
                tooltipClassName="bg-deskera-secondary width-auto"
              >
                <div
                  className={`row ${getStatusChipColor(currentRow?.status)}`}
                >
                  <DKLabel text={status ?? ''} />
                  <DKIcon src={DKIcons.white.ic_info} className="ic-xs ml-xs" />
                </div>
              </DKTooltipWrapper>
            ) : (
              ''
            );
          };
          return;

        case MANUFACTURING_JC_COLUMN_KEYS.WO_CODE:
          conf.renderer = (data: any) => {
            const woCode = this.getAttachedWOCode(
              workOrderData,
              data?.rowData
            )?.workOrderSeqCode;
            return (
              <div
                className={`${woCode ? 'underline cursor-hand' : ''}`}
                onClick={() => {
                  callBack({
                    documentSeqCode: woCode,
                    documentType: DOC_TYPE.WORK_ORDER,
                    showDetailsOpener: true
                  });
                }}
              >
                {woCode ?? '-'}
              </div>
            );
          };
          return;
        case MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE:
          conf.renderer = ({ rowData }: any) =>
            rowData?.item?.documentSequenceCode ?? '-';
          break;
        default:
          break;
      }
    });
    return filteredConfig;
  };

  processManufacturingGridRowUpdate = (
    { rowIndex, rowData, columnKey, columnData }: any,
    processType: any,
    jobCardMapping: any,
    formData: any
  ) => {
    let copyRows = [...this.manufacturingRows];
    copyRows[rowIndex][columnKey] = rowData[columnKey];

    switch (columnKey) {
      case MANUFACTURING_JC_COLUMN_KEYS.ITEM:
        if (
          !validateJCFormAndList(
            rowData?.item,
            jobCardMapping,
            formData,
            jobCardMapping?.currentJCDependentOn,
            false,
            (updatedDependentJc: any) => {}
          )
        ) {
          return copyRows;
        }

        copyRows[rowIndex][MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE] =
          rowData[columnKey]?.[MANUFACTURING_JC_COLUMN_KEYS.PRODUCT_CODE];

        copyRows[rowIndex][MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED] =
          processType?.key === JC_PROCESS_TYPE.PROCESSING
            ? PROCESS_MANUFACTURING_QC_OPTIONS[0]
            : PROCESS_MANUFACTURING_QC_OPTIONS[1];
        break;

      default:
        break;
    }

    if (!Utility.isEmpty(rowData[MANUFACTURING_JC_COLUMN_KEYS.ITEM])) {
      copyRows[rowIndex][MANUFACTURING_JC_COLUMN_KEYS.INVALID_FIELDS] = [];
    }

    this.manufacturingRows = copyRows;

    return copyRows;
  };

  getDataForItemColumnInGrid = (
    gridRows: any,
    workOrderData: any,
    jcFormData: any,
    jobCardMapping: any
  ) => {
    const processType = jcFormData?.processType;
    if (Utility.isEmpty(processType)) {
      return [];
    }

    let filteredWOItems = workOrderData?.workOrderItems?.filter(
      (woItem: any) => {
        if (processType?.key === JC_PROCESS_TYPE.CONSUMPTION) {
          const isProductUsedInAnyJCs = this.isProductUsedInOtherJC(
            jobCardMapping,
            woItem,
            processType
          );
          return (
            (woItem.type === PRODUCT_TYPE.TRACKED ||
              woItem.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
            !isProductUsedInAnyJCs
          );
        } else if (processType?.key === JC_PROCESS_TYPE.PRODUCTION) {
          const isProductUsedInAnyJCs = this.isProductUsedInOtherJC(
            jobCardMapping,
            woItem,
            processType
          );
          return (
            woItem.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
            !isProductUsedInAnyJCs
          );
        } else if (processType?.key === JC_PROCESS_TYPE.PROCESSING) {
          return woItem;
        } else {
          return false;
        }
      }
    );

    return filteredWOItems;

    //this code will return products that are not selected in grid already.
    // const gridRowProductCodes = gridRows?.map((item: any) => item.productCode);
    // const workOrderProductCodes = workOrderData?.workOrderItems?.map(
    //   (item: any) => item.productCode
    // );
    // const missingProducts = workOrderProductCodes.filter(
    //   (productCode: any) => !gridRowProductCodes.includes(productCode)
    // );
    // const missingProductsData = workOrderData?.workOrderItems?.filter(
    //   (item: any) => missingProducts.includes(item.productCode)
    // );

    // return missingProductsData;
  };

  getGridOptions = () => {
    return PROCESS_MANUFACTURING_QC_OPTIONS;
  };

  ///////////////////// grid methods only - end /////////////////////////

  ///////////////////// grid support methods only - start /////////////////////////

  isProductUsedInOtherJC = (
    jobCardMapping: any,
    woItem: any,
    processType: any
  ) => {
    const productFoundUsedInAnyJC = jobCardMapping?.allJCDetails?.find(
      (jcObj: any) =>
        jcObj.jobCardLinkDetails?.[0]?.productCode === woItem.productCode
    );
    let isProductUsedInAnyJC = !Utility.isEmpty(productFoundUsedInAnyJC);

    return isProductUsedInAnyJC;
  };

  ///////////////////// grid support methods only - end /////////////////////////

  getAllocatedQty = (data: any[] = []) => {
    let totalAllocatedQty =
      data?.reduce((acc: any, obj: any) => {
        const qty = Number(obj?.quantity ?? 0);
        return acc + qty;
      }, 0) ?? 0;

    totalAllocatedQty = Utility.roundingOff(
      totalAllocatedQty,
      QTY_ROUNDOFF_PRECISION
    );

    return totalAllocatedQty;
  };

  getNonEditableColumns = (
    rows: any,
    processJCDetails: any,
    itemRow: any,
    formData: any
  ) => {
    let nonEditableCols = [];

    const currentRowObj = processJCDetails?.jobCardLinkDetailsResponses?.find(
      (jcLinkObj: any) => jcLinkObj.productCode === itemRow.productCode
    );

    if (
      (rows.length > 0 &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData)) ||
      currentRowObj?.processType === JC_PROCESS_TYPE.PRODUCTION
    ) {
      nonEditableCols.push(MANUFACTURING_JC_COLUMN_KEYS.ITEM);
    }

    if (
      (rows.length > 0 &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData)) ||
      formData?.processType?.key === JC_PROCESS_TYPE.PROCESSING
    ) {
      nonEditableCols.push(MANUFACTURING_JC_COLUMN_KEYS.QC_NEEDED);
    }

    return nonEditableCols;
  };

  getProductShortInfoPayload = (workOrderItems: any[] = []) => {
    const itemsWithSubstitutes: any[] = [];
    workOrderItems?.forEach((woItem: any) => {
      if (Utility.isNotEmpty(woItem?.productSubstitutesDetails)) {
        woItem?.productSubstitutesDetails?.forEach((woSubstitute: any) => {
          itemsWithSubstitutes.push({
            productCode: woSubstitute.productCode,
            quantity: 0
          });
        });
      }
      itemsWithSubstitutes.push({
        productCode: woItem.productCode,
        quantity: woItem?.quantity,
        documentUOMSchemaDefinition: woItem?.documentUOMSchemaDefinition,
        uomQuantity: woItem?.uomQuantity
      });
    });

    return itemsWithSubstitutes;
  };

  assignQtysFromAllocated = (lineItem: any) => {
    let payload = lineItem?.detailsForQtyToBeAssign?.map((item: any) => {
      item = {
        jobCardCode: item.jobCardCode,
        productCode: item.productCode,
        workOrderCode: item.workOrderCode,
        itemQuantity: this.getAllocatedQty(item?.warehouseInventoryData ?? []),
        uomItemQuantity: this.getAllocatedQty(
          item?.warehouseInventoryData ?? []
        ),
        itemWarehouseInventoryData: item?.warehouseInventoryData ?? [],
        documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
        stockUom: item?.stockUom
      };

      return item;
    });
    return this.assignAllocatedQuantities(payload);
  };

  getPayloadForProcessManufactureDetails = (
    processJobCardDetails: any,
    dependentOnJobCard: any
  ) => {
    const shouldNotAssignWithTagging =
      Utility.isEmpty(processJobCardDetails?.jobCardLinkDetailsResponses) &&
      Utility.isNotEmpty(dependentOnJobCard);

    const payload = this.manufacturingRows?.map((manufactureRow: any) => {
      return {
        id: manufactureRow?.jobCardLinkDetails?.[0]?.id,
        itemQuantity: shouldNotAssignWithTagging
          ? 0
          : this.getAllocatedQty(
              manufactureRow?.item?.warehouseInventoryData ?? []
            ),
        uomItemQuantity: shouldNotAssignWithTagging
          ? 0
          : this.getAllocatedQty(
              manufactureRow?.item?.warehouseInventoryData ?? []
            ),
        itemWarehouseInventoryData: shouldNotAssignWithTagging
          ? []
          : manufactureRow?.item?.warehouseInventoryData ?? [],
        jobCardCode: this.formState?.jobCardCode ?? '',
        processType: this.formState?.processType?.key ?? '',
        productCode: manufactureRow?.item?.productCode ?? '',
        qcNeeded: manufactureRow?.qcNeeded?.key ?? false,
        workOrderCode: this.formState?.workOrder?.workOrderCode,
        documentUOMSchemaDefinition:
          manufactureRow?.item?.documentUOMSchemaDefinition,
        stockUom: manufactureRow?.item?.documentUOMSchemaDefinition?.sinkUOM
      };
    });

    return payload;
  };

  //////////////////// grid buttons //////////////////////////////////

  isConsumptionOptionVisible = (lineItem: any) => {
    const currentRowObj = lineItem?.jobCardLinkDetails?.find(
      (jcLinkObj: any) => jcLinkObj.productCode === lineItem.productCode
    );

    let isConsumptionAllowed = false;
    if (!Utility.isEmpty(currentRowObj)) {
      isConsumptionAllowed =
        (!currentRowObj?.qcNeeded ||
          currentRowObj.status === PROCESS_QC_STATUS.QA_DONE ||
          currentRowObj.status === PROCESS_QC_STATUS.OPEN) &&
        !Utility.isEmpty(lineItem?.detailsForQtyToBeAssign) &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData);
    }

    return isConsumptionAllowed;
  };

  getDeleteButton = (lineItem: any, jcDetails: any, callBack: any) => {
    let deleteButton = {
      title: 'Delete',
      className: '',
      onClick: (data: any) => {
        const onDelete = async () => {
          if (Utility.isEmpty(jcDetails?.jobCardLinkDetailsResponses)) {
            callBack(false, data);
          } else {
            const currentRow = this.getCurrentRowData(
              lineItem?.jobCardLinkDetails,
              lineItem?.productCode
            );
            let res = await this.deleteProcessJCDetails([currentRow?.id]);
            if (!Utility.isEmpty(res)) {
              callBack(true, data);
            }
          }
        };
        showAlert(
          'Confirm deletion of material',
          `Are you sure, you want to delete material <span class="fw-b">${
            lineItem?.item?.productName ?? ''
          }</span>?`,
          getAlertButtons(onDelete, () => {})
        );
      }
    };
    return deleteButton;
  };

  getQCAssignButton = (callBack: any) => {
    let qcAssignButton = {
      title: 'Perform QC',
      className: '',
      onClick: (data: any) => {
        callBack(data);
      }
    };
    return qcAssignButton;
  };

  getAssignButton = (
    name: any,
    lineItem: any,
    getProcessManufacturingAPICalls: any,
    isQCValidationRequired: boolean = false
  ) => {
    let assignButton = {
      title: name ?? 'Assign',
      className: 'p-v-0 text-blue underline grid-action-link-h-padding',
      onClick: (data: any) => {
        if (isQCValidationRequired) {
          showAlert(
            'Please note',
            'Inorder to save your updated QC requirement, please save current Job Card first.'
          );
          return;
        }
        const onConfirm = async () => {
          let res = await this.assignQtysFromAllocated(lineItem);
          if (!Utility.isEmpty(res)) {
            getProcessManufacturingAPICalls();
          }
        };
        const onCancel = () => {};
        showAlert(
          'Confirm assignment to material',
          `Are you sure, you want to assign all the allocated quantities to <span class="fw-b">${
            lineItem?.item?.productName ?? ''
          }</span>?`,
          getAlertButtons(onConfirm, onCancel)
        );
      }
    };

    return assignButton;
  };

  getConsumptionButton = (name: any, lineItem: any, callback: any) => {
    let assignButton = {
      title: name ?? 'Consume',
      className: 'p-v-0 text-blue underline grid-action-link-h-padding',
      onClick: (data: any) => {
        let buttons = [
          {
            title: 'No',
            className: 'bg-gray2 border-m ',
            onClick: () => {}
          },
          {
            title: 'Yes',
            className: 'bg-button text-white ml-r',
            onClick: async () => {}
          }
        ];
        showAlert(
          'Confirm consumption of material',
          `Are you sure, you want to consume all quantities of material <span class="fw-b">${
            lineItem?.item?.productName ?? ''
          }</span>?`,
          buttons
        );
      }
    };

    return assignButton;
  };

  //////////////////// grid buttons ///////////////////////////////////

  //////////////////// api call methods //////////////////////////////

  addOrUpdateProcessDetailsInJC = (payload: any) => {
    return ProcessManufacturingService.addOrUpdateProcessDetailsJC(payload);
  };

  getProductShortInfoForWOItems = (infoArr: any[] = []) => {
    let data = MRPProductsService.getProductShortInfo(infoArr);
    return data ?? [];
  };

  getWODetailsByJCCode = (jcCode: any = '') => {
    let data = ProcessManufacturingService.fetchWODetailsByJCCode(jcCode);
    return data ?? null;
  };

  getProcessJCDetailsByCode = (jcCode: any = '') => {
    let data = ProcessManufacturingService.fetchAllJobCardsByJCCode(jcCode);
    return data;
  };

  assignAllocatedQuantities = (payload: any) => {
    return ProcessManufacturingService.assignAllocatedQuantities(payload);
  };

  deleteProcessJCDetails = (id: any) => {
    return ProcessManufacturingService.deleteProcessDetailsJC(id);
  };

  //////////////////// api call methods //////////////////////////////
}

export const getJCProcessTypeFromProduct = (product: any): string => {
  let processType = JC_PROCESS_TYPE.PROCESSING;
  if (product?.type === PRODUCT_TYPE.TRACKED) {
    processType = JC_PROCESS_TYPE.CONSUMPTION;
  } else if (product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
    processType = JC_PROCESS_TYPE.PRODUCTION;
  }
  return processType;
};
export const getJCProductTypesFromProcess = (processType: string): string[] => {
  if (processType === JC_PROCESS_TYPE.CONSUMPTION) {
    return [PRODUCT_TYPE.TRACKED, PRODUCT_TYPE.BILL_OF_MATERIALS];
  } else if (processType === JC_PROCESS_TYPE.PRODUCTION) {
    return [PRODUCT_TYPE.BILL_OF_MATERIALS];
  }
  return [
    PRODUCT_TYPE.TRACKED,
    PRODUCT_TYPE.BILL_OF_MATERIALS,
    PRODUCT_TYPE.NON_TRACKED
  ];
};
export const getAlertButtons = (
  onConfirm: () => void,
  onCancel: () => void
) => {
  return [
    {
      title: 'No',
      className: 'bg-gray2 border-m ',
      onClick: onCancel
    },
    {
      title: 'Yes',
      className: 'bg-button text-white ml-r',
      onClick: onConfirm
    }
  ];
};

export const populateProducts = (
  processType: string,
  products: any[],
  existingProducts: any[]
) => {
  const productTypes: string[] = getJCProductTypesFromProcess(processType);
  let filteredProducts: any[] = [];

  products = products?.filter(
    (item) => item !== null && Object.keys(item).length > 0
  );

  products = products?.filter(
    (product: any) => product.type !== PRODUCT_TYPE.NON_TRACKED
  );

  products?.reduce((acc: any[], product: any) => {
    if (Utility.isNotEmpty(product?.itemName)) {
      product = {
        ...product,
        name: product?.itemName?.name,
        productId: product?.itemName?.productId,
        productCode: product?.itemName?.productId,
        documentSequenceCode: product?.itemName?.documentSequenceCode
      };
    }
    //TODO - Need to take type form one source only
    const type = product?.itemName?.type ?? product?.type;

    if (
      productTypes.includes(type) &&
      product.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
    ) {
      if (processType === JC_PROCESS_TYPE.CONSUMPTION) {
        const isValueIncluded = existingProducts?.some((obj) => {
          if (obj.productCode === product.productCode) {
            if (obj.processType === JC_PROCESS_TYPE.PRODUCTION) {
              return false;
            } else if (obj.processType !== JC_PROCESS_TYPE.PROCESSING) {
              return true;
            }
          }
          return false;
        });
        if (!isValueIncluded) {
          acc.push(product);
        }
      } else if (processType === JC_PROCESS_TYPE.PRODUCTION) {
        const isValueIncluded = existingProducts?.some((obj) => {
          if (obj.productCode === product.productCode) {
            if (obj.processType === JC_PROCESS_TYPE.PRODUCTION) {
              return true;
            } else if (obj.processType !== JC_PROCESS_TYPE.PROCESSING) {
              return true;
            }
          }
          return false;
        });
        if (!isValueIncluded) {
          acc.push(product);
        }
      } else if (processType === JC_PROCESS_TYPE.PROCESSING) {
        // const isValueIncluded = existingProducts?.some(
        //   (obj) =>
        //     obj.productCode === product.productCode &&
        //     obj.processType === JC_PROCESS_TYPE.CONSUMPTION
        // );
        // if (!isValueIncluded) {
        //   acc.push(product);
        // }
        acc.push(product);
      }
    }
    return acc;
  }, filteredProducts);
  return filteredProducts;
};

export const validateJCFormAndList = (
  row: any,
  jcMapping: any,
  formData: any,
  currentJCDependency: any,
  isCallBackNeeded: boolean,
  callBack: any,
  byPassValidation: boolean = false
) => {
  let isValid = true;

  const jcCount = jcMapping?.allJCDetails?.length;

  if (jcCount === 1 || Utility.isEmpty(row)) {
    return true;
  }

  const currentRowDependentByJC = currentRowDependentByAnyJC(
    jcMapping?.currentJCDetails,
    jcMapping?.allJCDetails
  );

  if (
    currentRowDependentByJC?.jobCardLinkDetails?.length > 0 &&
    currentRowDependentByJC?.jobCardLinkDetails?.[0]?.productCode !==
      row?.productCode
  ) {
    showAlert(
      'Please note',
      'This product is not allowed here, as this job card is dependent by some other which has type as processing.'
    );
    return false;
  }

  const selectedProductTaggedAnywhere = jcMapping?.allJCDetails?.find(
    (item: any) =>
      item?.jobCardLinkDetails?.[0]?.productCode === row?.productCode &&
      item?.jobCardCode !== formData?.jobCardCode
  );

  const selectedProductTaggedAnywhereAsProduction =
    jcMapping?.allJCDetails?.find(
      (item: any) =>
        item?.jobCardLinkDetails?.[0]?.productCode === row?.productCode &&
        item?.jobCardCode !== formData?.jobCardCode &&
        item?.jobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.PRODUCTION
    );

  const selectedProductTaggedAnywhereAsConsumption =
    jcMapping?.allJCDetails?.find(
      (item: any) =>
        item?.jobCardLinkDetails?.[0]?.productCode === row?.productCode &&
        item?.jobCardCode !== formData?.jobCardCode &&
        item?.jobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.CONSUMPTION
    );

  const isCurrentJCDependentOnAnyOtherAndHasDifferentProductsInBoth =
    jcMapping?.allJCDetails?.find(
      (item: any) =>
        Utility.isNotEmpty(currentJCDependency) &&
        currentJCDependency?.jobCardCode === item?.jobCardCode &&
        item?.jobCardLinkDetails?.[0]?.productCode !== row?.productCode
    );

  const selectedProductTaggedAnywhereAsProcessingAndNotDependentOnCurrentJC =
    jcMapping?.allJCDetails
      ?.filter(
        (item: any) =>
          item?.jobCardLinkDetails?.[0]?.productCode === row?.productCode &&
          item?.jobCardCode !== formData?.jobCardCode &&
          item?.jobCardLinkDetails?.[0]?.processType ===
            JC_PROCESS_TYPE.PROCESSING
      )
      ?.some(
        (item: any) => item?.jobCardCode !== currentJCDependency?.jobCardCode
      );

  if (
    Utility.isEmpty(selectedProductTaggedAnywhere) &&
    Utility.isEmpty(isCurrentJCDependentOnAnyOtherAndHasDifferentProductsInBoth)
  ) {
    return true;
  }

  if (
    Utility.isNotEmpty(selectedProductTaggedAnywhere) &&
    selectedProductTaggedAnywhere?.jobcardDependency
      ?.jobcardDependencyList?.[0] !== formData?.jobCardCode &&
    Utility.isEmpty(currentJCDependency)
  ) {
    if (isCallBackNeeded) {
      const buttons = [
        {
          title: 'No',
          className: 'border-m mr-r',
          onClick: () => {}
        },
        {
          title: `Yes`,
          className: 'bg-button text-white',
          onClick: () => {
            if (Utility.isNotEmpty(selectedProductTaggedAnywhere)) {
              callBack(selectedProductTaggedAnywhere);
            }
          }
        }
      ];
      showAlert(
        'Please note',
        'Same product is tagged in other job card as well, you need to make them dependent. Proceed to make them depentent?',
        buttons
      );
    } else {
      showAlert(
        'Please note',
        'Same product is tagged in other job card as well, please make them dependent.'
      );
    }

    return false;
  }

  if (
    Utility.isNotEmpty(
      isCurrentJCDependentOnAnyOtherAndHasDifferentProductsInBoth
    )
  ) {
    showAlert(
      'Please note',
      'Product selected here is not similar to that in the dependent jobcard.'
    );
    return false;
  }

  if (
    Utility.isNotEmpty(
      selectedProductTaggedAnywhereAsProcessingAndNotDependentOnCurrentJC
    ) &&
    [JC_PROCESS_TYPE.CONSUMPTION, JC_PROCESS_TYPE.PRODUCTION].includes(
      formData?.processType?.key
    )
  ) {
    showAlert(
      'Please note.',
      `Same material has been tagged in other job card as processing, please mark them dependent as this job card contains same product as consumption process type.`
    );
    return false;
  }

  if (
    selectedProductTaggedAnywhereAsProduction &&
    formData?.processType?.key === JC_PROCESS_TYPE.PRODUCTION &&
    row?.productCode ===
      selectedProductTaggedAnywhereAsProduction?.jobCardLinkDetails?.[0]
        ?.productCode
  ) {
    showAlert(
      'Please note.',
      `Same WIP material has been tagged in other job card.`
    );
    return false;
  }

  if (
    selectedProductTaggedAnywhereAsConsumption &&
    formData?.processType?.key === JC_PROCESS_TYPE.PROCESSING &&
    !byPassValidation
  ) {
    showAlert(
      'Please note.',
      `Same material has been tagged in other job card as consumption, please remove it inorder to tag it over here.`
    );
    return false;
  }

  return isValid;
};

export const isDependentOnFieldReadOnly = (
  isFormReadOnly: boolean,
  dependentJobCardsMapping: any,
  isLinkedInventory: boolean
) => {
  let isReadOnly =
    isFormReadOnly ||
    (isLinkedInventory &&
      Utility.isNotEmpty(
        dependentJobCardsMapping?.currentJCDetails?.jobCardLinkDetails?.[0]
          ?.productCode
      ));

  return isReadOnly;
};

export const isAssignOptionVisible = (
  lineItem: any,
  processType: any,
  dependentOnJC: any
) => {
  let isAssignAllowed = false;
  lineItem = {
    ...lineItem,
    jobCardLinkDetailsAvailableToAssign:
      lineItem?.detailsForQtyToBeAssign ??
      lineItem?.jobCardLinkDetailsAvailableToAssign,
    productCode:
      lineItem?.productCode ?? lineItem?.jobCardLinkDetails?.[0]?.productCode
  };

  const currentRowObj = lineItem?.jobCardLinkDetailsAvailableToAssign?.find(
    (item: any) => {
      return item.productCode === lineItem.productCode;
    }
  );

  const currentRowObjFromLineItem = lineItem?.jobCardLinkDetails?.find(
    (item: any) => {
      return item.productCode === lineItem.productCode;
    }
  );

  if (!Utility.isEmpty(currentRowObj)) {
    isAssignAllowed = !Utility.isEmpty(currentRowObj?.warehouseInventoryData);
  }

  if (
    processType?.key === JC_PROCESS_TYPE.PRODUCTION ||
    currentRowObjFromLineItem?.status === PROCESS_QC_STATUS.QA_DONE ||
    (Utility.isNotEmpty(dependentOnJC) &&
      dependentOnJC?.jobCardLinkDetails?.[0]?.processType ===
        JC_PROCESS_TYPE.PROCESSING)
  ) {
    isAssignAllowed = false;
  }

  return isAssignAllowed;
};

export const isConsumptionOptionVisible = (lineItem: any) => {
  const currentRowObj = lineItem?.jobCardLinkDetails?.find(
    (jcLinkObj: any) => jcLinkObj.productCode === lineItem.productCode
  );

  let isConsumptionAllowed = false;
  if (!Utility.isEmpty(currentRowObj)) {
    if (currentRowObj?.qcNeeded) {
      isConsumptionAllowed =
        currentRowObj.status === PROCESS_QC_STATUS.QA_DONE &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData) &&
        currentRowObj?.processType === JC_PROCESS_TYPE.CONSUMPTION;
    } else {
      isConsumptionAllowed =
        currentRowObj.status === PROCESS_QC_STATUS.QUANTITY_ASSIGNED &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData) &&
        currentRowObj?.processType === JC_PROCESS_TYPE.CONSUMPTION;
    }
  }

  return isConsumptionAllowed;
};

export const isProcessOptionVisible = (lineItem: any) => {
  const currentRowObj = lineItem?.jobCardLinkDetails?.find(
    (jcLinkObj: any) => jcLinkObj.productCode === lineItem.productCode
  );

  let isProcessAllowed = false;
  if (!Utility.isEmpty(currentRowObj)) {
    if (currentRowObj?.qcNeeded) {
      isProcessAllowed =
        currentRowObj.status === PROCESS_QC_STATUS.QA_DONE &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData) &&
        currentRowObj?.processType === JC_PROCESS_TYPE.PROCESSING;
    } else {
      isProcessAllowed =
        currentRowObj.status === PROCESS_QC_STATUS.QUANTITY_ASSIGNED &&
        !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData) &&
        currentRowObj?.processType === JC_PROCESS_TYPE.PROCESSING;
    }
  }

  return isProcessAllowed;
};

export const consumeOrProcessMaterial = (ids: number[]) => {
  return ProcessManufacturingService.jcDetailsCompletion(ids);
};
export const getConsumeProcessAlertHeader = (type: 'consume' | 'process') =>
  `Confirm ${type} to material`;

export const getConsumeProcessAlertMessage = (
  type: 'consume' | 'process',
  productName: string
) =>
  `Are you sure, you want to ${type} quantities to <span class="fw-b">${
    productName ?? ''
  }</span>?`;

export const getConsumeOrProcessButton = (
  name: any,
  lineItem: any,
  ids: number[],
  onSuccessCallback: any
) => {
  let assignButton: any = {
    title: name ?? 'Consume',
    className: 'p-v-0 text-blue underline grid-action-link-h-padding',
    onClick: (data: any) => {
      const key = name?.toLowerCase();

      const onConfirm = async () => {
        let res = await consumeOrProcessMaterial(ids);
        if (Utility.isNotEmpty(res)) {
          onSuccessCallback();
        }
      };
      const onCancel = () => {};

      showAlert(
        getConsumeProcessAlertHeader(key),
        getConsumeProcessAlertMessage(key, lineItem?.item?.productName),
        getAlertButtons(onConfirm, onCancel)
      );
    }
  };

  return assignButton;
};
export const isAvailableForQC = (row: any) => {
  const currentRowObj = row?.jobCardLinkDetails?.find(
    (jcLinkObj: any) => jcLinkObj.productCode === row.productCode
  );
  if (Utility.isEmptyObject(currentRowObj)) return false;
  return (
    currentRowObj?.qcNeeded &&
    currentRowObj.status === PROCESS_QC_STATUS.QUANTITY_ASSIGNED &&
    !Utility.isEmpty(currentRowObj?.itemWarehouseInventoryData)
  );
};

export const getQCAssignButton = (callBack: any, className: string = '') => {
  let qcAssignButton = {
    title: 'Perform QC',
    onClick: callBack,
    className
  };
  return qcAssignButton;
};

export const getAttachedSubstitutes = (workOrderItems: any, shortInfo: any) => {
  let allAttachedSubstitutes: any[] = [];
  workOrderItems?.forEach((woItemForSubstitute: any) => {
    if (!Utility.isEmpty(woItemForSubstitute?.productSubstitutesDetails)) {
      allAttachedSubstitutes = [
        ...(woItemForSubstitute?.productSubstitutesDetails?.map(
          (substitute: any) => {
            const substituteFoundInShortInfo = shortInfo?.find(
              (subs: any) => subs.pid === substitute.productCode
            );

            if (Utility.isNotEmpty(substituteFoundInShortInfo)) {
              return {
                ...substitute,
                plannedQuantity: woItemForSubstitute?.plannedQuantity ?? 0,
                isSubstitute: true,
                type: substituteFoundInShortInfo?.type,
                produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
                name: substituteFoundInShortInfo?.name,
                documentSequenceCode:
                  substituteFoundInShortInfo?.documentSequenceCode
              };
            }
            return null;
          }
        ) ?? [])
      ];
    }
  });

  return allAttachedSubstitutes ?? [];
};

export const processGetWODetailsByJCCode = (details: any) => {
  let updatedDetails = {
    ...details,
    workOrderItems: details?.workOrderItems?.map((woItem: any) => {
      return {
        ...woItem,
        warehouseInventoryData: getUpdatedWarehouseInventoryDataConsideringUOM(
          woItem?.warehouseInventoryData
        )
      };
    })
  };

  return updatedDetails;
};

export const processGetProcessJCDetailsByCode = (details: any) => {
  let updatedDetails = {
    jobCardLinkDetailsResponses: details?.jobCardLinkDetailsResponses?.map(
      (jcLinkDetail: any) => {
        return {
          ...jcLinkDetail,
          itemQuantity:
            jcLinkDetail?.uomItemQuantity ?? jcLinkDetail?.itemQuantity,
          passedQuantity:
            jcLinkDetail?.uomPassedQuantity ?? jcLinkDetail?.passedQuantity,
          rejectedQuantity:
            jcLinkDetail?.uomRejectedQuantity ?? jcLinkDetail?.rejectedQuantity,
          itemWarehouseInventoryData:
            getUpdatedWarehouseInventoryDataConsideringUOM(
              jcLinkDetail?.itemWarehouseInventoryData
            ) ?? [],
          passedWarehouseInventoryData:
            getUpdatedWarehouseInventoryDataConsideringUOM(
              jcLinkDetail?.passedWarehouseInventoryData
            ),
          rejectedWarehouseInventoryData:
            getUpdatedWarehouseInventoryDataConsideringUOM(
              jcLinkDetail?.rejectedWarehouseInventoryData
            )
        };
      }
    ),
    jobCardLinkDetailsQuantityDetails:
      details?.jobCardLinkDetailsQuantityDetails?.map(
        (jcLinkQuantityDetail: any) => {
          return {
            ...jcLinkQuantityDetail,
            warehouseInventoryData:
              getUpdatedWarehouseInventoryDataConsideringUOM(
                jcLinkQuantityDetail?.warehouseInventoryData
              )
          };
        }
      )
  };

  return updatedDetails;
};

export const getUpdatedWarehouseInventoryDataConsideringUOM = (
  warehouseInventoryData: any[]
) => {
  let updatedWHInvData =
    warehouseInventoryData?.map((itemWH: any) => {
      return {
        ...itemWH,
        quantity: itemWH?.uomQuantity ?? itemWH?.quantity ?? 0,
        advancedTrackingData: itemWH?.advancedTrackingData?.map(
          (advItem: any) => {
            return {
              ...advItem,
              quantity: advItem?.uomQuantity ?? itemWH?.quantity ?? 0,
              qtyToFulfil: advItem?.qtyToFulfilUom ?? advItem?.qtyToFulfil ?? 0
            };
          }
        )
      };
    }) ?? [];

  return updatedWHInvData;
};

export const getUpdatedWODetailsFromShortInfo = (
  productShortInfos: any[] = [],
  woDetails: any = null
) => {
  if (!Utility.isEmpty(productShortInfos)) {
    let woItems =
      woDetails?.workOrderItems?.map((woItem: any) => {
        const foundInShortInfo = productShortInfos?.find((info: any) => {
          const code = woItem.productCode ?? woItem?.itemName?.pid;
          return info.pid === code;
        });

        if (!Utility.isEmpty(foundInShortInfo)) {
          woItem = {
            ...woItem,
            ...foundInShortInfo,
            productName: foundInShortInfo?.name ?? '',
            productSubstitutesDetails:
              woItem?.productSubstitutesDetails ??
              woItem?.bomProductSubstitutesDetails
          };
        }
        return woItem;
      }) ?? [];

    woDetails = {
      ...woDetails,
      workOrderItems: woItems
    };

    return woDetails;
  }
};

export const getUsedJCArray = (dependentJobCardsMapping: any) => {
  const usedJCArray: any = [];
  dependentJobCardsMapping?.allJCDetails.forEach((jc: any) => {
    if (jc.jobcardDependency?.jobcardDependencyList?.length) {
      usedJCArray.push(jc.jobcardDependency?.jobcardDependencyList[0]);
    }
  });
  return usedJCArray;
};
