import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';

import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';

import SalesOrderService from '../../Services/SalesOrder';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchSalesOrders = createAsyncThunk('salesOrder', async () => {
  const response = await SalesOrderService.getSalesOrderByPage();
  return response;
});

export const SalesOrderSlice = createSlice({
  name: 'Sales Orders',
  initialState,
  reducers: {
    addSalesOrderColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.SALES_ORDER
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesOrders.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchSalesOrders.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { addSalesOrderColumnConfig, updateColumnConfig } =
  SalesOrderSlice.actions;

export const selectSalesOrder = (state: RootState) => state.salesOrder.data;

export const selectSalesOrderLoadingStatus = (state: RootState) =>
  state.salesOrder.gridContentLoadingStatus;

export const selectSalesOrderColumnConfig = (state: RootState) =>
  state.salesOrder.columnConfig;

export const selectSalesOrderColumnConfigTableId = (state: RootState) =>
  state.salesOrder.configTableId;

export default SalesOrderSlice.reducer;
