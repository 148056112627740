import { getCapitalized, getDateString, getTitleCase } from "../../Utilities/Utility"
import LineItemsParser from "../LineItemsParser"

export default class QCDocumentParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        var name = ''
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.name !== undefined && dataFromAPI.tenantInfo.name !== null) {
                name = getCapitalized(dataFromAPI.tenantInfo.name)
            }
        }
        templateDataToReturn.companyName = name

        //start date
        templateDataToReturn.date = ''
        let dateTimeStamp = this.getQcDocumentPrintDetail(dataFromAPI, 'transactionDate')
        if (dateTimeStamp !== undefined) {
            templateDataToReturn.date = getDateString(new Date(dateTimeStamp), templateDataToReturn.dateFormat)
        }
        
        //header level
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'contactName', 'contactName', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'approverName', 'approverName', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'productDocumentSeqCode', 'productDocumentSeqCode', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'productName', 'productName', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'qcNumber', 'refNumber', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'status', 'status', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'linkedDocumentCode', 'linkedDocumentCode', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'transactionType', 'transactionType', true)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'transactionCode', 'transactionCode', false)

        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'uomName', 'uomName', false)
        
        //footer level
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'quantity', 'quantity', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'acceptedQuantity', 'acceptedQuantity', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'rejectedQuantity', 'rejectedQuantity', false)
        templateDataToReturn = this.assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, 'comment', 'notes', false)

        if (templateDataToReturn.status.includes('_')) {
            templateDataToReturn.status = templateDataToReturn.status.replace('_', ' ')
        }

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getQCDocumentItems(dataFromAPI)

        return templateDataToReturn
    }

    static getQcDocumentPrintDetail(dataFromAPI, key) {
        if (dataFromAPI.qcDocumentPrintDetail !== undefined && dataFromAPI.qcDocumentPrintDetail !== null) {
            if (dataFromAPI.qcDocumentPrintDetail[key] !== undefined && dataFromAPI.qcDocumentPrintDetail[key] !== null) {
                return dataFromAPI.qcDocumentPrintDetail[key]
            }
        }
        return undefined
    }

    static assignQcDocumentPrintDetail(dataFromAPI, templateDataToReturn, key, assignKey, isUseTitleCase) {
        templateDataToReturn[assignKey] = ''

        var temp = this.getQcDocumentPrintDetail(dataFromAPI, key) 

        if(temp !== undefined) {
            templateDataToReturn[assignKey] = temp
            if(isUseTitleCase) {
                templateDataToReturn[assignKey] = getTitleCase(temp)
            }
        }
        return templateDataToReturn
    } 
}
