import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { INPUT_TYPE, showAlert } from 'deskera-ui-library';
import {
  addProductCustomFieldsToLineItem,
  RFQ_COLUMN_KEYS,
  RFQ_FORM_DATE_TYPE,
  RFQ_FORM_STATUS
} from './RequestForQuotationFormHelper';
import { cloneDeep } from 'lodash';
import { RootState } from '../../../Redux/Store';
import Utility from '../../../Utility/Utility';
import RFQService from '../../../Services/RFQ';
import {
  API_STATUS,
  BOOKS_DATE_FORMAT,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE
} from '../../../Constants/Constant';
import DateFormatService from '../../../Services/DateFormat';
import {
  checkIfLineLevelCustomFieldIsValid,
  updateRowDataWithParentCFValues
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { TableManager } from '../../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';

export const RFQ_FORM_KEYS = {
  LOADING: 'loading',
  LOADING_API_DATA: 'loadAPIData',
  RFQ_DOC_SEQ_CODE: 'rfqDocumentSequenceCode',
  SEQ_FORMAT: 'sequenceFormat',
  MANUAL_MODE: 'manualMode',
  RFQ_DATE: 'rfqDate',
  VALID_BY_DATE: 'validByDate',
  RFQ_LINE_ITEMS: 'requestForQuotationItems',
  MEMO: 'memo',
  CAN_VALIDATE: 'canValidate',
  CONTACT: 'contact',
  EMAIL: 'email',
  RFQ_GLOBAL_FORM_CUSTOM_FIELD: 'customField',
  DOCUMENT_MODE: 'documentMode',
  LINKED_PR_DOCS: 'linkedPRDocuments',
  LINKED_PI_QUOTES: 'linkedPurchaseInwardQuotes',
  LINKED_QUOTES_DOC: 'linkedQuoteDocuments'
};

export interface IRequestForQuotationItems {
  product: any;
  documentSequenceCode: string;
  productDescription: string;
  productQuantity: number;
  lineNumber: number;
  invalidFields?: string[];
  uomQuantity: number;
  //
  id?: any;
  customField?: any;
  documentUOMSchemaDefinition?: any;
  linkedPRItemCode?: any;
  productCode?: string;
  productOrder?: number;
  documentUom?: any;
}

export interface IADDRequestForQuotationInitialState {
  documentMode: any;
  loading: boolean;
  loadAPIData: boolean;
  id?: any;
  rfqDocumentSequenceCode: string;
  sequenceFormat: string;
  manualMode: string;
  rfqDate: any;
  validByDate: any;
  memo: string;
  canValidate: boolean;
  contact: any[];
  email: string[];
  customField: any;
  attachments: any;
  attachmentIds: any;
  vendorCode: any[];
  linkedPRDocuments: any[];
  linkedPurchaseInwardQuotes: any[];
  linkedQuoteDocuments: any;
  status: any;
  requestForQuotationItems: IRequestForQuotationItems[];
}

export const ADD_REQUEST_FOR_QUOTATION_INITIAL_STATE: IADDRequestForQuotationInitialState =
  {
    documentMode: DOCUMENT_MODE.NEW,
    loading: false,
    loadAPIData: false,
    rfqDocumentSequenceCode: '',
    sequenceFormat: '',
    manualMode: '',
    rfqDate: DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    validByDate: DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    memo: '',
    canValidate: false,
    contact: [],
    email: [],
    customField: [],
    attachments: null,
    attachmentIds: null,
    vendorCode: [],
    linkedPRDocuments: [],
    linkedPurchaseInwardQuotes: [],
    linkedQuoteDocuments: null,
    status: RFQ_FORM_STATUS.OPEN,
    id: null,
    requestForQuotationItems: [
      {
        product: null,
        documentSequenceCode: '',
        productDescription: '',
        productQuantity: 1,
        documentUom: 0,
        lineNumber: 1,
        invalidFields: ['product'],
        linkedPRItemCode: null,
        uomQuantity: 1
      }
    ]
  };

const initialState: GridState = {
  data: {}, //do not use this
  rfqFormDocument: ADD_REQUEST_FOR_QUOTATION_INITIAL_STATE,
  allRFQData: {},
  status: API_STATUS.IDLE,
  columnConfig: []
};

export type rfqSliceStateKeys = keyof IADDRequestForQuotationInitialState;

const selfSelector = (rootState: RootState) => rootState.rfqFormData;

const updateStateKey = <
  KEY extends rfqSliceStateKeys,
  VALUE extends IADDRequestForQuotationInitialState[KEY]
>(
  obj: IADDRequestForQuotationInitialState,
  key: KEY,
  value: VALUE
) => {
  obj[key] = value;
};

export const fetchAllRequestForQuotation = createAsyncThunk(
  'fetchRFQs',
  async () => {
    const response = await RFQService.fetchAllRFQs();
    return response;
  }
);

export const AddRequestForQuotationSlice = createSlice({
  name: 'manageRequestForQuotationForm',
  initialState: initialState,
  reducers: {
    addRFQColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          columnCode: config?.columnCode ?? config.key,
          key: config.columnCode ?? config.key,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.REQUEST_FOR_QUOTATION
      );
    },
    setFormState: (
      state,
      action: PayloadAction<{
        state: Partial<IADDRequestForQuotationInitialState>;
      }>
    ) => {
      if (action.payload.state) {
        Object.entries(action.payload.state).forEach(([keyToUpdate, value]) => {
          updateStateKey(
            state.rfqFormDocument,
            keyToUpdate as rfqSliceStateKeys,
            value
          );
        });
      }
    },
    updateAutoNumberingFields: (state, action) => {
      const selected = action.payload;
      if (selected.manualMode) {
        state.rfqFormDocument.rfqDocumentSequenceCode = selected.text;
        state.rfqFormDocument.sequenceFormat = selected.id;
        state.rfqFormDocument.manualMode = selected.manualMode;
      } else if (selected.id) {
        state.rfqFormDocument.sequenceFormat = selected.id;
        state.rfqFormDocument.manualMode = selected.manualMode;
      } else {
        state.rfqFormDocument.rfqDocumentSequenceCode = '';
        state.rfqFormDocument.sequenceFormat = '';
        state.rfqFormDocument.manualMode = '';
      }
    },
    updateGridRows: (state, action) => {
      let { data, columnConfig, productCFfromStore } = action.payload;
      let dataToUpdate = data;
      let copyLineItems: any = cloneDeep(
        state.rfqFormDocument.requestForQuotationItems
      );
      copyLineItems = copyLineItems?.map((item: any, index: number) => {
        if (index === dataToUpdate.rowIndex) {
          switch (dataToUpdate.columnKey) {
            case RFQ_COLUMN_KEYS.PRODUCT:
              item[RFQ_COLUMN_KEYS.PRODUCT] = dataToUpdate.rowData?.product;
              item[RFQ_COLUMN_KEYS.PRODUCT_DOC_SEQ_CODE] =
                dataToUpdate.rowData?.product?.documentSequenceCode ?? '';
              item[RFQ_COLUMN_KEYS.UOM] =
                dataToUpdate.rowData?.product?.stockUom;
              item[RFQ_COLUMN_KEYS.PRODUCT_DESCRIPTION] =
                dataToUpdate.rowData?.product?.description ?? '';
              item[RFQ_COLUMN_KEYS.QUANTITY] = 1;

              if (Utility.isNotEmpty(dataToUpdate.rowData?.product)) {
                item.invalidFields = [];
              }

              item = addProductCustomFieldsToLineItem(
                { ...item },
                dataToUpdate.rowData?.product,
                productCFfromStore
              );

              item = checkIfLineLevelCustomFieldIsValid(
                item,
                productCFfromStore,
                true
              );
              break;

            case RFQ_COLUMN_KEYS.QUANTITY:
              item[RFQ_COLUMN_KEYS.QUANTITY] =
                dataToUpdate.rowData?.productQuantity ?? 0;

              if (!dataToUpdate.rowData?.documentUom?.isBaseUom) {
                item[RFQ_COLUMN_KEYS.UOM_QUANTITY] = Utility.getUomQuantity(
                  dataToUpdate.rowData?.productQuantity,
                  dataToUpdate.rowData?.documentUom
                );
              } else {
                item[RFQ_COLUMN_KEYS.UOM_QUANTITY] =
                  dataToUpdate.rowData?.productQuantity ?? 0;
              }

              if (
                !Number.isInteger(
                  Number(dataToUpdate.rowData?.productQuantity)
                ) &&
                item[RFQ_COLUMN_KEYS.PRODUCT]?.advancedTracking ===
                  ADVANCE_TRACKING.SERIAL
              ) {
                showAlert(
                  'Error',
                  `The quantity of serial products cannot be entered as a decimal number.`
                );
                item[RFQ_COLUMN_KEYS.QUANTITY] = 0;
                item[RFQ_COLUMN_KEYS.UOM_QUANTITY] = 0;

                item.invalidFields.push(RFQ_COLUMN_KEYS.QUANTITY);
              }

              const hasProductQtyZero =
                item?.productQuantity === 0 ||
                item?.productQuantity === '0' ||
                item?.productQuantity === '' ||
                Utility.isEmpty(item?.productQuantity?.trim());

              if (hasProductQtyZero) {
                item.invalidFields.push(RFQ_COLUMN_KEYS.QUANTITY);
              }

              if (Number(item[RFQ_COLUMN_KEYS.QUANTITY]) > 0) {
                item.invalidFields = item.invalidFields.filter(
                  (field: any) => field !== RFQ_COLUMN_KEYS.QUANTITY
                );
              }

              break;

            case RFQ_COLUMN_KEYS.UOM:
              const uom = dataToUpdate.rowData?.documentUom?.isBaseUom
                ? dataToUpdate.rowData?.documentUom?.id
                : dataToUpdate.rowData?.documentUom?.sinkUOM;
              item[RFQ_COLUMN_KEYS.UOM] = uom;
              item[RFQ_COLUMN_KEYS.DOC_UOM_SCHEMA_DEFINITION] = !dataToUpdate
                .rowData?.documentUom?.isBaseUom
                ? {
                    ...dataToUpdate.rowData?.documentUom,
                    schemaId: dataToUpdate?.rowData?.product?.uomSchemaDto?.id,
                    id: dataToUpdate.rowData?.documentUom?.sinkUOM
                  }
                : null;

              if (!dataToUpdate.rowData?.documentUom?.isBaseUom) {
                item[RFQ_COLUMN_KEYS.UOM_QUANTITY] = Utility.getUomQuantity(
                  dataToUpdate.rowData?.productQuantity,
                  dataToUpdate.rowData?.documentUom
                );
              } else {
                item[RFQ_COLUMN_KEYS.UOM_QUANTITY] =
                  dataToUpdate.rowData?.productQuantity;
              }

              break;

            default:
              const CFColumnConfig = columnConfig?.find(
                (cf: any) =>
                  cf?.id === dataToUpdate.columnKey && cf.isCustomField
              );
              const filteredCF: any = productCFfromStore?.find(
                (field: any) => field.id === dataToUpdate.columnKey
              );
              let cfValue = '';
              if (!Utility.isEmpty(filteredCF)) {
                if (
                  filteredCF.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.USER.toLowerCase()
                ) {
                  const tempCFOption = filteredCF?.attributes?.find(
                    (attr: any) =>
                      attr.code ===
                      dataToUpdate?.rowData?.[dataToUpdate.columnKey]?.code
                  );
                  if (tempCFOption) {
                    cfValue = tempCFOption?.code;
                  }
                } else if (
                  filteredCF.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
                ) {
                  cfValue =
                    dataToUpdate?.rowData?.[dataToUpdate.columnKey]?.value ===
                    'None'
                      ? null
                      : dataToUpdate?.rowData?.[dataToUpdate.columnKey]?.value;
                  // cfUpdatedTimeMap.current = {
                  //   ...cfUpdatedTimeMap.current,
                  //   [filteredCF.id]: new Date().getTime()
                  // };
                } else {
                  // rowData[columnKey]
                  cfValue =
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                      ? DateFormatService.getDateStrFromDate(
                          new Date(
                            dataToUpdate?.rowData[dataToUpdate.columnKey]
                          ),
                          BOOKS_DATE_FORMAT['MM/DD/YYYY']
                        )
                      : dataToUpdate?.rowData[dataToUpdate.columnKey];
                }
                if (CFColumnConfig && filteredCF) {
                  const cfToUpdate = {
                    id: filteredCF.id,
                    shortName: filteredCF.shortName,
                    module: filteredCF.module,
                    code: filteredCF.code,
                    label: filteredCF.label,
                    value: cfValue
                  };
                  let existingCFs = item?.customField
                    ? [...item?.customField]
                    : [];
                  const existingCFIndex = existingCFs.findIndex(
                    (cf: any) => cf?.id === cfToUpdate?.id
                  );
                  if (existingCFIndex === -1) {
                    existingCFs = [...existingCFs, cfToUpdate];
                  } else {
                    existingCFs[existingCFIndex] = cfToUpdate;
                  }
                  if (item) {
                    item.customField = existingCFs;
                  }
                }
                if (item) {
                  if (
                    filteredCF?.fieldType?.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    item[dataToUpdate.columnKey] = new Date(
                      dataToUpdate?.rowData[dataToUpdate.columnKey]
                    );
                  } else if (
                    Utility.isObject(
                      dataToUpdate?.rowData[dataToUpdate.columnKey]
                    )
                  ) {
                    if (
                      filteredCF?.fieldType?.toLowerCase() ===
                      INPUT_TYPE.DROPDOWN.toLowerCase()
                    ) {
                      item[dataToUpdate.columnKey] =
                        dataToUpdate?.rowData[dataToUpdate.columnKey]?.value ===
                        'None'
                          ? null
                          : dataToUpdate?.rowData[dataToUpdate.columnKey]
                              ?.value;
                      const { rowData } = updateRowDataWithParentCFValues(
                        dataToUpdate?.rowData[dataToUpdate.columnKey].value,
                        {
                          ...item
                        },
                        filteredCF,
                        productCFfromStore
                      );
                      item = rowData;
                    } else {
                      item[dataToUpdate.columnKey] =
                        dataToUpdate?.rowData[dataToUpdate.columnKey].value;
                    }
                  } else {
                    item[dataToUpdate.columnKey] =
                      dataToUpdate?.rowData[dataToUpdate.columnKey];
                  }
                }
                item = checkIfLineLevelCustomFieldIsValid(
                  item,
                  productCFfromStore,
                  true
                );
                break;
              } else {
                if (item) {
                  item[dataToUpdate.columnKey] = Utility.isObject(
                    dataToUpdate?.rowData[dataToUpdate.columnKey]
                  )
                    ? dataToUpdate?.rowData[dataToUpdate.columnKey]?.value
                    : dataToUpdate?.rowData[dataToUpdate.columnKey];

                  item = checkIfLineLevelCustomFieldIsValid(
                    item,
                    productCFfromStore,
                    true
                  );
                }
                break;
              }
          }
        }

        return item;
      });

      state.rfqFormDocument.requestForQuotationItems = copyLineItems;
    },
    addNewBlankGridRow: (state) => {
      let copyLineItems = cloneDeep(
        state.rfqFormDocument.requestForQuotationItems
      );
      copyLineItems.push({
        product: null,
        documentSequenceCode: '',
        productDescription: '',
        productQuantity: 1,
        documentUom: 0,
        lineNumber: copyLineItems.length + 1,
        invalidFields: ['product'],
        uomQuantity: 1
      });
      state.rfqFormDocument.requestForQuotationItems = copyLineItems;
    },
    resetToInitialState: (state) => {
      state.rfqFormDocument = cloneDeep(
        ADD_REQUEST_FOR_QUOTATION_INITIAL_STATE
      );
    },
    updateRFQFormDates: (state, action) => {
      const { dateType, dateToUpdate } = action.payload;
      if (dateType === RFQ_FORM_DATE_TYPE.RFQ_DATE) {
        state.rfqFormDocument.rfqDate = dateToUpdate;
      } else if (dateType === RFQ_FORM_DATE_TYPE.RFQ_VALID_BY) {
        state.rfqFormDocument.validByDate = dateToUpdate;
      }
    },
    deleteRFQLineItem: (state, action) => {
      const indexToDelete = action.payload;
      state.rfqFormDocument.requestForQuotationItems.splice(indexToDelete, 1);
    },
    updateRFQMemo: (state, action) => {
      state.rfqFormDocument.memo = action.payload;
    },
    updateContacts: (state, action) => {
      let allContacts = [...action.payload];
      state.rfqFormDocument.contact = allContacts;
      let contactEmails: string[] = [];

      allContacts?.forEach((c: any) => {
        contactEmails.push(c.emailId);
        if (Utility.isNotEmpty(c?.otherEmails)) {
          let otherEmailsIDs = [...c?.otherEmails?.split(',')];
          otherEmailsIDs?.forEach((otherEmail: any) => {
            contactEmails.push(otherEmail);
          });
        }
      });

      contactEmails = contactEmails?.filter((emailId: any) =>
        Utility.isNotEmpty(emailId)
      );

      state.rfqFormDocument.email = [...contactEmails];
    },
    updateRFQEmail: (state, action) => {
      state.rfqFormDocument.email = action.payload;
    },
    updateDocumentFormLoading: (state, action) => {
      state.rfqFormDocument.loading = action.payload;
    },
    updateRFQGlobalFormCustomFields: (state, action) => {
      state.rfqFormDocument.customField = action.payload;
    },
    updateRFQFormDataForEditCase: (state, action) => {
      state.rfqFormDocument = action.payload;
    },
    updateRFQLineItems: (state, action) => {
      state.rfqFormDocument.requestForQuotationItems = action.payload;
    },
    updateRFQFormCanValidate: (state, action) => {
      state.rfqFormDocument.canValidate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRequestForQuotation.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllRequestForQuotation.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.allRFQData = action.payload;
      });
  }
});

export const {
  addRFQColumnConfig,
  updateGridRows,
  addNewBlankGridRow,
  resetToInitialState,
  setFormState,
  updateAutoNumberingFields,
  updateRFQFormDates,
  deleteRFQLineItem,
  updateRFQMemo,
  updateContacts,
  updateRFQEmail,
  updateDocumentFormLoading,
  updateRFQGlobalFormCustomFields,
  updateRFQFormDataForEditCase,
  updateRFQLineItems,
  updateRFQFormCanValidate
} = AddRequestForQuotationSlice.actions;

export const selectRFQFormDataByKeys = (dataKey: any) =>
  createSelector(selfSelector, (state: any) => {
    if (Array.isArray(dataKey)) {
      return state?.rfqFormDocument
        ? dataKey.map((dataKey: any) => state.rfqFormDocument[dataKey])
        : [];
    } else {
      return state?.rfqFormDocument?.[dataKey];
    }
  });

export const rfqDocumentFormValues = (state: any) => {
  return state.rfqFormData.rfqFormDocument;
};

export const selectedRFQAllData = (state: any) => {
  return state.rfqFormData.allRFQData;
};

export const selectRFQColumnConfig = (state: RootState) => {
  return state.rfqFormData.columnConfig;
};

export const selectRFQColumnConfigTableId = (state: RootState) =>
  state.rfqFormData.configTableId;
