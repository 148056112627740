import { DKButton, DKLabel } from 'deskera-ui-library';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import Utility from '../../Utility/Utility';

export default function EWayResponsePopUp(props: any) {
  const [data, setData] = useState(props.response);

  useEffect(() => {
    setData(props.response);
  }, [props.response]);

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row">
          <DKLabel text={'Response'} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title={'Close'}
            className={'bg-white border-m'}
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    );
  };

  const getSuccessRecords = (records: any) => {
    return (
      <div className="colum parent-width">
        <DKLabel
          className="parent-width bg-white p-4 fw-m fs-m"
          text={`Success Records (${records.length})`}
        />
        <div className="column parent-width pl-6 pr-6">
          {records.map((item: any, index: number) => (
            <div className="row">
              <DKLabel className="fw-m fs-m mr-s" text={`${index + 1}.`} />
              <DKLabel
                text={`E-Way Bill for invoice id ${item.documentSeqCode} is successfully generated with E-Way bill number ${item.ewayBillNo}`}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getFailureRecords = (records: any) => {
    return (
      <div className="colum parent-width">
        <DKLabel
          className="parent-width bg-white p-4 fw-m fs-m"
          text={`Failure Records (${records.length})`}
        />
        <div className="column parent-width pl-6 pr-6">
          {records.map((item: any, index: number) => (
            <div className="column parent-width">
              <div className="row">
                <DKLabel className="fw-m fs-m mr-s" text={`${index + 1}.`} />
                <DKLabel
                  text={`E-Way generation failed for invoice ${item.documentSeqCode} with following errors.`}
                />
              </div>
              <div className="column p-2">
                {item?.errorDetails?.map((error: any) => (
                  <div className="row gap-10">
                    <DKLabel text={error.errorCode} />
                    <DKLabel text={error.errorMessage} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background" style={{ zIndex: 9998 }}>
      <div
        className="popup-window"
        style={{
          maxWidth: 700,
          width: '90%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-width">
          {!Utility.isEmpty(data?.success) && getSuccessRecords(data?.success)}
          {!Utility.isEmpty(data?.failure) && getFailureRecords(data?.failure)}
          <div className="row mt-m mb-5 ml-5">
              Go to{' '}
              <span
                className="text-blue ml-1 mr-1"
                style = {{cursor:"pointer"}}
                onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.REPORT_SALES_EWB_REPORT)}
              >
               EWay Bill Report
              </span>
            </div>
        </div>
      </div>
    </div>
  );
}

export const responseObject = {
  success: [
    {
      data: 'zlmREkljHX1gYWy3AJch7m8ZxoUFzuv+Jf+lD1JPcvInndDf0IglALc6m0CicP1uQvI+jXpzzvn08GiwOW+pqk1TSp4Y36Xag/h9naTj72Zv3t/yflqRdFHxTR7ufMiHgugJrXK0ba9BABtp8ALG07I6In9KAUAIDIrOVb6Z06eNXQ0koxrXUV9MAUZQlsWw9jBuU2/kDIlWIpYQTD8E+HiJzD+JgyC2iQnbqO9u2XRwE631gPB9lsY23tdyov/n',
      status: '1',
      ewayBillNo: '351002833595',
      ewayBillDate: '04/05/2022 07:00:00 PM',
      validUpto: '05/05/2022 11:59:00 PM',
      alert:
        'The distance between the given pincodes are not available in the system., ',
      id: 131756,
      error: null,
      sek: 'oykOFk3TeuJVRmINpIuEQncYcrGZl65YOZMh25ggIkas/3cp/4jGFAqCkcYISicw',
      appKey: 'XgQh9lcQG6ruCSJZpbWhA/7qVJYlFgHriiBfgj6f4mg=',
      isTokenActive: true,
      postEwbDate: '2022-05-04T13:30:14.150+0000',
      errorDetails: null
    }
  ],
  failure: [
    {
      data: null,
      status: '0',
      ewayBillNo: null,
      ewayBillDate: null,
      validUpto: null,
      alert: null,
      id: 131696,
      error: null,
      sek: null,
      appKey: null,
      isTokenActive: null,
      postEwbDate: null,
      errorDetails: [
        {
          errorMessage:
            'Errors: {Hsn Code can not be null for InvoiceItemList no {P-0000065}}',
          errorCode: 'NA'
        }
      ]
    },
    {
      data: null,
      status: '0',
      ewayBillNo: null,
      ewayBillDate: null,
      validUpto: null,
      alert: null,
      id: 131695,
      error: null,
      sek: null,
      appKey: null,
      isTokenActive: null,
      postEwbDate: null,
      errorDetails: [
        {
          errorMessage:
            'Errors: {Hsn Code can not be null for InvoiceItemList no {P-0000065}}',
          errorCode: 'NA'
        }
      ]
    },
    {
      data: null,
      status: '0',
      ewayBillNo: null,
      ewayBillDate: null,
      validUpto: null,
      alert: null,
      id: 131664,
      error: { error_cd: 'NA', message: '212,' },
      sek: 'huDSEVJqubbOTpj0E8i8SvwrK90CicI0Ijpift5+KunwXvXKckDnholoYyA52HJe',
      appKey: 'DZfMC0UGV5gpgOeRB6yzwuh5HrocJceR30zEj/Jy+P8=',
      isTokenActive: true,
      postEwbDate: null,
      errorDetails: null
    }
  ],
  statusCode: 1
};
