import {
  DKCheckMark,
  DKInput,
  DKLabel,
  TOAST_TYPE,
  showToast
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../../Constants/Constant';
import { CallBackPayloadType } from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import { fetchPaymentTerm } from '../../../Redux/Slices/ContactsSlice';
import { fetchPaymentTerms } from '../../../Redux/Slices/PaymentTermsSlice';
import ContactService from '../../../Services/Contact';
import TenantService from '../../../Services/Tenant';
import { deepClone } from '../../../Utility/Utility';

interface AddPaymentTermsProps {
  populateFormData: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

interface addWareHouseState {
  termName: string;
  termDays: any;
  default: boolean;
  id: number;
  systemGenerated: boolean;
}

const initialState = {
  termName: '',
  termDays: 1,
  default: false,
  id: 1,
  systemGenerated: false
};

const AddPaymentTermsPopUp: React.FC<AddPaymentTermsProps> = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<addWareHouseState>(
    deepClone(initialState)
  );
  const currentTenantDetails = useAppSelector(activeTenantInfo);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const state = formState;
    if (props.populateFormData && props.populateFormData.termName) {
      state.termName = props.populateFormData.termName;
    }

    if (props.populateFormData && props.populateFormData.id) {
      state.id = props.populateFormData.id;
    }

    if (props.populateFormData && props.populateFormData.termDays) {
      state.termDays = props.populateFormData.termDays;
    }

    if (props.populateFormData) {
      state.default =
        currentTenantDetails.defaultPaymentTerm == props.populateFormData.id
          ? true
          : false;
    }

    if (props.populateFormData && props.populateFormData.systemGenerated) {
      state.systemGenerated = props.populateFormData.systemGenerated;
    }

    setFormState({ ...state });
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.ADD_PAYMENT_TERMS,
        data: () => {
          payloadData();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.EDIT_PAYMENT_TERMS,
        data: () => {
          payloadDataForEdit();
        }
      });
    }
  };

  const postPaymentTerm = async (payload: any) => {
    try {
      const response = ContactService.postPaymentTerms(payload);
      return response;
    } catch (error) {
      console.error('Error posting new Payment Terms:', error);
    }
  };

  const updateDefault = async (payload: any) => {
    try {
      const response = TenantService.updatePaymentTerm(payload);
      return response;
    } catch (error) {
      console.error('Error patching Org Details: ', error);
    }
  };

  const payloadDataForEdit = () => {
    if (!formState.termName || formState.termName.trim().length === 0) {
      showToast('Term Name cannot be left empty', TOAST_TYPE.FAILURE);
      return;
    }

    if (formState.termDays === '' || isNaN(formState.termDays)) {
      showToast('Term Days is invalid', TOAST_TYPE.FAILURE);
      return;
    }
    const payload = {
      termName: formState.termName,
      termDays: formState.termDays,
      systemGenerated: formState.systemGenerated,
      id: formState.id
    };

    ContactService.editPaymentTerms(payload, formState.id)
      .then((response) => {
        const payload1 = {
          defaultPaymentTerm: formState.id
        };

        if (formState.default) {
          TenantService.updatePaymentTerm(payload1).then(() => {
            dispatch(fetchPaymentTerms());
            dispatch(fetchCurrentTenantInfo());
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          });
        } else {
          dispatch(fetchPaymentTerms());
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
        }
      })
      .catch((error) => {
        console.error('Error editing Payment Terms: ', error);
      });
  };

  const payloadData = () => {
    if (!formState.termName || formState.termName.trim().length === 0) {
      showToast('Term Name cannot be left empty', TOAST_TYPE.FAILURE);
      return;
    }

    if (formState.termDays === '' || isNaN(formState.termDays)) {
      showToast('Term Days is invalid', TOAST_TYPE.FAILURE);
      return;
    }

    const payload = {
      termName: formState.termName,
      termDays: formState.termDays,
      default: formState.default
    };
    ContactService.postPaymentTerms(payload)
      .then((response: any) => {
        const newState = formState;
        if (newState) {
          newState.id = response.id;
        }
        setFormState({ ...newState });

        const payload1 = {
          defaultPaymentTerm: formState.id
        };

        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.PAYMENT_TERM_SUCCESS,
          data: response
        });

        if (formState.default) {
          TenantService.updatePaymentTerm(payload1).then(() => {
            dispatch(fetchPaymentTerms());
            dispatch(fetchPaymentTerm()); // to update payment term in contacts
            dispatch(fetchCurrentTenantInfo());
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
            });
          });
        } else {
          dispatch(fetchPaymentTerms());
          dispatch(fetchPaymentTerm());
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
        }
      })
      .catch((error) => {
        console.error('Error posting new Payment Terms: ', error);
      });
  };

  const updateInputField: any = (event: string, field: string) => {
    const newState = formState;
    if (newState && field === 'name') {
      newState.termName = event;
    }
    if (newState && field === 'days') {
      if (Number(event) < 0 || isNaN(Number(event))) {
        showToast(t(`PAYMENT_TERM.NON_NEGATIVE_TOAST`), TOAST_TYPE.FAILURE);
        newState.termDays = 1;
      } else newState.termDays = event;
    }
    setFormState({ ...newState });
  };

  const onClickCheckBoxForSetting = (value: any | null) => {
    const newState = formState;
    if (newState) {
      if (newState.default && props.populateFormData) {
        showToast(
          'select different default payment terms first',
          TOAST_TYPE.WARNING
        );
      } else {
        newState.default = !newState.default;
        setFormState({ ...newState });
      }
    }
  };

  return (
    <div className="p-2">
      <div className="flex flex-row justify-between">
        <div className="parent-width">
          <DKInput
            required={true}
            title={t(`SETTINGS.PAYMENT_TERM.ADD_PAYMENT_TERMS_POPUP.NAME`)}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            value={formState?.termName}
            onChange={(event: string) => updateInputField(event, 'name')}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between mb-3">
        <div className="parent-width mt-3">
          <DKInput
            required={true}
            placeholder={'Days'}
            title={t(`SETTINGS.PAYMENT_TERM.ADD_PAYMENT_TERMS_POPUP.TERM_DAYS`)}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            value={formState?.termDays}
            onChange={(event: string) => updateInputField(event, 'days')}
          />
        </div>
      </div>
      <div className="flex flex-row mt-5">
        <DKCheckMark
          className="text-black"
          title={t(
            `SETTINGS.PAYMENT_TERM.ADD_PAYMENT_TERMS_POPUP.USE_AS_DEFAULT`
          )}
          isSelected={formState.default}
          onClick={onClickCheckBoxForSetting}
        />
      </div>
      <div className="mt-1 ml-6">
        <DKLabel
          className="text-gray text-xs opacity-50"
          text={t(
            `SETTINGS.PAYMENT_TERM.ADD_PAYMENT_TERMS_POPUP.THIS_WILL_APPEAR_AS_DEFAULT_WHEN_USER_CREATES_A_NEW_CONTACT`
          )}
        />
      </div>
    </div>
  );
};
export default AddPaymentTermsPopUp;
