import { FC, useState, useEffect } from 'react';
import { DKLabel, DKButton, Toggle, showAlert } from 'deskera-ui-library';
import SettingPopup from './Popup';

interface IRfqSettingsProps {
  onSave: (data: any) => void;
  settings: any;
}

const RFQSettings: FC<IRfqSettingsProps> = (props) => {
  const [showPopup, togglePopupVisibility] = useState<boolean>(false);
  const [isRFQOn, setIsRFQOn] = useState(props.settings?.enableRFQ ?? false);

  useEffect(() => {
    setIsRFQOn(props.settings?.enableRFQ ?? false);
  }, [props]);

  const onSave = async (payload: any) => {
    try {
      const response = await props.onSave({
        additionalSettings: { RFQ_OPEN_FORM_CONFIG: payload }
      });
      togglePopupVisibility(false);
    } catch (error) {}
  };
  return (
    <>
      <div className="column parent-width border-m border-radius-m gap-4 p-r mb-xl">
        <div className="row parent-width justify-content-between">
          <DKLabel text="Enable Request For Quotation" className="fw-b" />
          <div className="column" style={{ minWidth: 42 }}>
            <Toggle
              className="box-content"
              onChange={(checked: any) => {
                if (isRFQOn) {
                  return;
                }
                showAlert(
                  'Please Note',
                  'Once you enable this module, you cannot disable it again.',
                  [
                    {
                      title: 'Cancel',
                      className: 'bg-white border-m mr-s',
                      onClick: () => {}
                    },
                    {
                      title: 'Proceed',
                      className: 'bg-button text-white',
                      onClick: () => {
                        onSave({ enableRFQ: true });
                      }
                    }
                  ]
                );
              }}
              isOn={isRFQOn}
            />
          </div>
        </div>

        {isRFQOn && (
          <div className="row parent-width mt-xs justify-content-between">
            <DKLabel
              className="text-gray"
              text="Set RFQ for title and logo"
              style={{ minWidth: 100 }}
            />
            <div className="column" style={{ minWidth: 100 }}>
              <DKButton
                title="Change Settings"
                className="bg-white text-bg-button-color cursor-hand"
                style={{ padding: 0, borderRadius: 6 }}
                onClick={() => {
                  togglePopupVisibility(true);
                }}
              />
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <SettingPopup
          settings={props.settings}
          onCancel={() => togglePopupVisibility(false)}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default RFQSettings;
