import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class JournalEntryTableWidth {

    isDateVisible: boolean;
    isJournalEntryNumberVisible: boolean;
    isContactCodeVisible: boolean;
    isContactNameVisible: boolean;
    isAccountCodeVisible: boolean;
    isAccountNameVisible: boolean;
    isDescriptionVisible: boolean;
    isCreditVisible: boolean;
    isDebitVisible: boolean;
    isAccountCustomFieldVisible: boolean;

    date: number;
    journalEntryNumber: number;
    contactCode: number;
    contactName: number;
    accountCode: number;
    accountName: number;
    description: number;
    credit: number;
    debit: number;
    accountCustomField: number;

    originalDate: number;
    originalJournalEntryNumber: number;
    originalContactName: number;
    originalContactCode: number;
    originalAccountName: number;
    originalAccountCode: number;
    originalDescription: number;
    originalCredit: number;
    originalDebit: number;
    originalAccountCustomField: number;
    accountCustomFieldList: any;

    constructor() {
        this.isDateVisible = true
        this.isJournalEntryNumberVisible = true
        this.isContactCodeVisible = true
        this.isContactNameVisible = true
        this.isAccountCodeVisible = true
        this.isAccountNameVisible = true
        this.isDescriptionVisible = true
        this.isCreditVisible = true
        this.isDebitVisible = true
        this.isAccountCustomFieldVisible = true

        this.date = 0
        this.journalEntryNumber = 0
        this.contactCode = 7
        this.contactName = 9.5
        this.accountCode = 7
        this.accountName = 9.5
        this.description = 24
        this.credit = 19
        this.debit = 19
        this.accountCustomField = 5

        this.originalDate = 0
        this.originalJournalEntryNumber = 0
        this.originalContactName = 7
        this.originalContactCode = 9.5
        this.originalAccountName = 7
        this.originalAccountCode = 9.5
        this.originalDescription = 24
        this.originalCredit = 19
        this.originalDebit = 19
        this.originalAccountCustomField = 5

        this.accountCustomFieldList = undefined
    }

    setIsShowWidth(columnData: any) {
        this.isDateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.date)
        this.isJournalEntryNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.journalEntryNumber)
        this.isContactCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.contactCode)
        this.isContactNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.contactName)
        this.isAccountCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.accountCode)
        this.isAccountNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.accountName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isCreditVisible = getIsShowTableColumn(columnData, TableColumnPopupType.credit)
        this.isDebitVisible = getIsShowTableColumn(columnData, TableColumnPopupType.debit)

        var accountCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (accountCustomFieldList.length > 0) {
            this.isAccountCustomFieldVisible = false
            if (this.accountCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.accountCustomFieldList = []
                var width = 5
                var maxWidth = 20
                if (accountCustomFieldList.length > 4) {
                    width = maxWidth / accountCustomFieldList.length
                }
                accountCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.accountCustomFieldList.push(item)
                });
            }
            else {
                this.accountCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.date = this.originalDate
        this.journalEntryNumber = this.originalJournalEntryNumber
        this.contactCode = this.originalContactName
        this.contactName = this.originalContactCode
        this.accountCode = this.originalAccountName
        this.accountName = this.originalAccountCode
        this.description = this.originalDescription
        this.credit = this.originalCredit
        this.debit = this.originalDebit

        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.date = 0
        this.journalEntryNumber = 0
        this.contactCode = 0
        this.contactName = 0
        this.accountCode = 0
        this.accountName = 0
        this.description = 0
        this.credit = 0
        this.debit = 0

        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isDateVisible) {
            totalWidth += this.date;
        }
        if (this.isJournalEntryNumberVisible) {
            totalWidth += this.journalEntryNumber;
        }
        if (this.isContactCodeVisible) {
            totalWidth += this.contactCode;
        }
        if (this.isContactNameVisible) {
            totalWidth += this.contactName;
        }
        if (this.isAccountCodeVisible) {
            totalWidth += this.accountCode;
        }
        if (this.isAccountNameVisible) {
            totalWidth += this.accountName;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isCreditVisible) {
            totalWidth += this.credit;
        }
        if (this.isDebitVisible) {
            totalWidth += this.debit;
        }

        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isDateVisible) {
            totalSelectedElement += 1
        }
        if (this.isJournalEntryNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isContactCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isContactNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isAccountCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isAccountNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isCreditVisible) {
            totalSelectedElement += 1
        }
        if (this.isDebitVisible) {
            totalSelectedElement += 1
        }

        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isDateVisible) {
            this.date = this.originalDate + additionalWidth
        }
        if (this.isJournalEntryNumberVisible) {
            this.journalEntryNumber = this.originalJournalEntryNumber + additionalWidth
        }
        if (this.isContactCodeVisible) {
            this.contactCode = this.originalContactCode + additionalWidth
        }
        if (this.isContactNameVisible) {
            this.contactName = this.originalContactName + additionalWidth
        }
        if (this.isAccountCodeVisible) {
            this.accountCode = this.originalAccountCode + additionalWidth
        }
        if (this.isAccountNameVisible) {
            this.accountName = this.originalAccountName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isCreditVisible) {
            this.credit = this.originalCredit + additionalWidth
        }
        if (this.isDebitVisible) {
            this.debit = this.originalDebit + additionalWidth
        }

        if (this.accountCustomFieldList !== undefined) {
            this.accountCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getAccountCustomFieldWidthByCode(code: string) {
        if (this.accountCustomFieldList !== undefined) {
            var item = this.accountCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}