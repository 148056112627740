import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import TemplateParser from "./TemplateParser"

export default class SOACustomerParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        
        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name ?? '') 
        
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        dataFromAPI.tenantInfo = {
            ...dataFromAPI.tenantInfo,
            state: fromAddressObj?.state,
        }
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        templateDataToReturn.gstRegNo = dataFromAPI.tenantInfo?.gstNo ?? ''
        templateDataToReturn.companyRegNo = dataFromAPI.tenantInfo?.gstNo ?? ''
        
        if (dataFromAPI.fromDate && dataFromAPI.fromDate !== null) {
            templateDataToReturn.fromDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.fromDate), templateDataToReturn.dateFormat)
        }
        if (dataFromAPI.asOfDate && dataFromAPI.asOfDate !== null) {
            templateDataToReturn.asOfDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.asOfDate), templateDataToReturn.dateFormat)
        }
        if (dataFromAPI.endDate && dataFromAPI.endDate !== null) {
            templateDataToReturn.toDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.endDate), templateDataToReturn.dateFormat)
        } 
        

        // billing to address
        var clientBillToAddressObj = undefined
        if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
            if (dataFromAPI.contactInfo.billingAddress !== undefined && dataFromAPI.contactInfo.billingAddress !== null) {
                clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfo.billingAddress, undefined, undefined, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
        templateDataToReturn.lineItems = this.getLineItem(dataFromAPI)

        templateDataToReturn.invoiceDueSummaryData = this.getInvoiceDueSummaryData(dataFromAPI)

        templateDataToReturn.currency = dataFromAPI.currencySymbol ?? ''
        templateDataToReturn.currencyName = dataFromAPI.currency ?? ''
        
        templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)

        return templateDataToReturn
    }

    static getLineItem(dataFromAPI) {
        var lineItems = []
        var rawItems = dataFromAPI.statementOfAccountData ?? []

        if(rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                let item = {}
                item.lineNumber = index + 1
                item.documentDate = element.documentDate
                if (element.formattedDate && element.formattedDate !== null) {
                    item.documentDate = getConvertedDate(convertDateFromERPFormatToDocFormat(element.formattedDate), dataFromAPI.dateFormat)
                }
                item.documentType = element.documentType
                item.documentNumber = element.documentNumber
                item.particular = element.jeCode
                if(element.amountInBase > 0) {
                    item.debit = element.amountInBase
                }
                else {
                    item.credit = Math.abs(element.amountInBase)
                }
                item.amountInBase = element.amountInBase
                item.balanceInBase = element.balanceInBase
                item.currency = element.currency
                item.memo = element.memo
                
                lineItems.push(item)
            });
        }

        return lineItems
    }

    static getInvoiceDueSummaryData(dataFromAPI)  {
        let invoiceList = []

        var rawItems = dataFromAPI.invoiceDueSummaryData ?? []

        if(rawItems.length > 0) {
            rawItems.forEach(element => {
                let item = element

                if (element.dueDate && element.dueDate !== null) {
                    item.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(element.dueDate), dataFromAPI.dateFormat)
                }

                invoiceList.push(item)
            });
        }

        return invoiceList
    }
}
