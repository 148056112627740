import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import BanksService from '../../Services/Banks';
import { RootState } from '../Store';

const initialState: GridState = {
    data: {} as any,
    columnConfig: [],
    status: API_STATUS.IDLE
};

export const fetchDeskeraTransactionList = createAsyncThunk('fetchDeskeraTransactionList', async (bankCode: any) => {
    const response = await BanksService.fetchBankDocumentsList(bankCode);
    return response;
});

export const DeskeraTransactions = createSlice({
    name: 'DeskeraTransactions',
    initialState,
    reducers: {
      addDeskeraTransactionsColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
        const tableId = action.payload.tableId;
        const configs = action.payload.config;
        let configsToStore: any[] = [];
        configs.forEach((config) => {
          configsToStore.push({
            ...config,
            key: config.columnCode,
            options: config.options ? config.options : []
          });
        });
        state.columnConfig = configsToStore;
        state.configTableId = tableId;
      },
      addBanks: (state, action: PayloadAction<any[]>) => {
        state.data = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchDeskeraTransactionList.pending, (state) => {
          state.status = API_STATUS.LOADING;
        })
        .addCase(fetchDeskeraTransactionList.fulfilled, (state, action) => {
          state.status = API_STATUS.IDLE;
          state.data = action.payload;
        });
    }
  });

export const { addDeskeraTransactionsColumnConfig } = DeskeraTransactions.actions;
export const deskeraTransactionsColumnconfig = (state: RootState) => state.bankDetails.columnConfig;

