export interface PaymentMethod {
  id: number;
  accountCode: string;
  name: string;
  code: string;
  description: string;
  taxCode: string;
  currency: string;
  accountGroup: string;
  accountGroupId?: number;
  status: string;
  openingBalance: number;
  balance: number;
  totalBalance: number;
  openingBalanceCdType: string;
  isUndepositedFundAccount: boolean;
}

export const makePaymentMethod = (props: any): PaymentMethod => {
  const initialState: PaymentMethod = {
    id: props.id,
    accountCode: props.accountCode,
    name: props.name,
    code: props.code,
    description: props.description,
    taxCode: props.taxCode,
    currency: props.currency,
    accountGroup: props.accountGroup,
    status: props.status,
    openingBalance: props.openingBalance,
    balance: props.balance,
    totalBalance: props.totalBalance,
    openingBalanceCdType: props.openingBalanceCdType,
    isUndepositedFundAccount: props.isUndepositedFundAccount
  };
  return initialState;
};
