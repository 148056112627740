import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface PreferredVendorAPIConfig {
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: any;
  QueryParam?: any;
  SearchTerm?: string;
}

class PreferredVendorService {
  static apiConfig: PreferredVendorAPIConfig;
  static defaultConfig: PreferredVendorAPIConfig = {
    Page: 0,
    Limit: 25,
    Sort: '',
    SortDir: ''
  };

  static getProductsByVendor(
    payload: string[],
    config: PreferredVendorAPIConfig
  ) {
    this.apiConfig = config;
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = this.defaultConfig;
    }

    let queryString = '?';
    if (this.apiConfig) {
      if (this.apiConfig.Page !== undefined || this.apiConfig.Page !== null) {
        queryString += `page=${this.apiConfig.Page}&limit=${this.apiConfig.Limit}`;
      }

      if (
        !Utility.isEmpty(this.apiConfig.Sort) &&
        !Utility.isEmpty(this.apiConfig.SortDir)
      ) {
        queryString += `&sortBy=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
      }
    }

    if (queryString === '?') {
      queryString = '';
    }

    return http
      .post(
        ApiConstants.URL.PRODUCT.GET_PRODUCTS_BY_VENDOR + queryString,
        payload
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductPriceByVendor(contactCode: string, productCode: string) {
    return http
      .get(
        ApiConstants.URL.PRODUCT.GET_PRODUCT_PRICE_BY_VENDOR(
          contactCode,
          productCode
        )
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default PreferredVendorService;
