import {
  DKLabel,
  DKButton,
  DKSpinner,
  DKInput,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchEWayBillAuthInfo
} from '../../../Redux/Slices/AuthSlice';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';

export default function EWayBillLoginPopUp(props: any) {
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [reqJson, setReqJson] = useState<any>({
    gstin: tenantInfo.gstin,
    password: '',
    username: ''
  });

  useEffect(() => {
    setReqJson({ ...reqJson, gstin: tenantInfo.gstin });
  }, [tenantInfo]);

  const login = async () => {
    setIsLoading(true);
    try {
      let res = await dispatch(fetchEWayBillAuthInfo(reqJson));
      setIsLoading(false);
      console.log(res, 'E-Way Auth info');
      if (res.payload.status === '1') {
        props.onClose();
      } else if (res?.payload?.status === '0' && res?.payload?.error?.message) {
        showAlert('Error!', res?.payload?.error?.message);
      } else {
        showAlert('Failed', 'Unable to login, please check your credentials.');
      }
    } catch (err: any) {}
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text="Enter E-Way Bill Login Details"
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <div className={`row ${isLoading ? 'border-radius-m border-m' : ''}`}>
            <DKButton
              title={'Connect'}
              onClick={() => {
                login();
              }}
              className={`${
                isLoading ? 'border-radius-none text-gray' : 'bg-app text-white'
              }`}
            />
            {isLoading && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 570,
            width: '80%',
            maxHeight: '95%',
            height: 330,
            padding: 0,
            paddingBottom: 0,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          <div
            className="column parent-size justify-content-between overflow-y-scroll p-h-m pb-m hide-scroll-bar"
            style={{ alignContent: 'space-between' }}
          >
            <div className="parent-width">
              <DKInput
                readOnly={true}
                type={INPUT_TYPE.TEXT}
                title="GSTIN"
                value={reqJson.gstin}
                onChange={(text: string) => {
                  setReqJson({ ...reqJson, gstin: text });
                }}
                className="mt-m"
              />
              <DKInput
                type={INPUT_TYPE.TEXT}
                title="Username"
                value={reqJson.username}
                onChange={(text: string) => {
                  setReqJson({ ...reqJson, username: text });
                }}
                className="mt-m"
              />
              <DKInput
                type={INPUT_TYPE.PASSWORD}
                title="Password"
                value={reqJson.password}
                onChange={(text: string) => {
                  setReqJson({ ...reqJson, password: text });
                }}
                className="mt-m"
              />
            </div>
            <div className=" mt-l ">
              <DKLabel
                className=""
                text="Kindly key in your Credentials. In case you do not have the credentials, login to the GST portal to create them."
              />
              <div className="row mt-m">
                Click{' '}
                <a
                  className="text-blue ml-1 mr-1"
                  href="https://einvoice1.gst.gov.in/"
                  target="_blank"
                >
                  here
                </a>
                to login to the GST portal to generate your username & password
              </div>
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
