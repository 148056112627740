import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GenericState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import GenericService from '../../Services/GenericService';
import ProductService from '../../Services/Product';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GenericState = {
  data: {} as any,
  status: API_STATUS.IDLE
};

export const fetchGeneric = createAsyncThunk('generics', async (data: any) => {
  const response = await GenericService.getCall(data['apiPath']);
  var resp: any = {}
  resp[data['cacheKey']] = response
  return resp;
});

export const GenericSlice = createSlice({
  name: 'generics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneric.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchGeneric.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        var existingData: any = {...state.data};
        existingData = {...existingData, ...action.payload};
        state.data = { ...existingData };
      });
  }
});

export const {
} = GenericSlice.actions;

export const selectResponse = (state: RootState) => state.generics.data;

export default GenericSlice.reducer;
