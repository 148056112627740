import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http2 from './Interceptor';
import http from './Interceptor';
import http3 from './Interceptor2';

export interface RevenueRecognitionAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
  barcode?: string;
}

export const defaultConfig: RevenueRecognitionAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  IsVariant: false,
  type: '',
  QueryParam: ''
};
class RevenueRecognitionService {
  static apiConfig: RevenueRecognitionAPIConfig;
  //   static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
  //     COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;
  static abortController: any = null;

  static getRevenueCategoryEndpoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&limit=${this.apiConfig.Limit ? this.apiConfig.Limit : 25}&page=${
      this.apiConfig.Page ? this.apiConfig.Page : 0
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    // const countryCode = Utility.getTenantSpecificApiCode(
    //   RevenueRecognitionService.moduleName
    // );
    const finalEndpoint: string =
      //   ApiConstants.URL.APP_ERP_URL +
      ApiConstants.URL.REVENUE_RECOGNITION.GET_CATEGORY + queryString;
    return finalEndpoint;
  }
  static getRevenueCategoryByPage() {
    Utility.cancelRequest(RevenueRecognitionService.abortController);

    RevenueRecognitionService.abortController = Utility.createAbortController();
    return http
      .get(`${RevenueRecognitionService.getRevenueCategoryEndpoint()}`, {
        signal: RevenueRecognitionService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createRevenueCategory(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.CREATE_CATEGORY;

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateRevenueCategory(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.CREATE_CATEGORY + data.id;

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteRevenueCategory(data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.CREATE_CATEGORY + data;

    return http
      .delete(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static GetRevenueCategory(search = '', limit = 25, page = 0) {
    const url =
      ApiConstants.URL.APP_ERP_URL +
      ApiConstants.URL.REVENUE_RECOGNITION.GET_CATEGORY;
    const queryString = `?search=${search}&limit=${limit}&page=${page}`;
    return http2.get(url + queryString);
  }

  static GetRevRecJE(
    search = '',
    limit = 25,
    page = 0,
    postingPeriod = '',
    contactCode = '',
    fromDate = '',
    toDate = '',
    includePriorPeriods =false
  ) {
    const url = ApiConstants.URL.REVENUE_RECOGNITION.GET_REV_REC_JE;
    let queryString = `?${search}&postingPeriod=${postingPeriod}`;
    if (
      contactCode !== 'ALL' &&
      contactCode !== null &&
      contactCode !== undefined
    ) {
      queryString = queryString + `&contactCode=${contactCode}`;
    }
    queryString =
      queryString +
      `&includePriorPeriods=${includePriorPeriods}&limit=${
        limit ? limit : 25
      }&page=${page ? page : 0}`;
    return http.get(url + queryString);
  }
  static PostRevRecJE(payload: any) {
    const url = ApiConstants.URL.REVENUE_RECOGNITION.POST_REV_REC_JE;
    return http3.post(url, payload);
  }

  static getRevRecJeEndpoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&limit=${this.apiConfig.Limit ? this.apiConfig.Limit : 25}&page=${
      this.apiConfig.Page ? this.apiConfig.Page : 0
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    // const countryCode = Utility.getTenantSpecificApiCode(
    //   RevenueRecognitionService.moduleName
    // );
    const finalEndpoint: string =
      //   ApiConstants.URL.APP_ERP_URL +
      ApiConstants.URL.REVENUE_RECOGNITION.GET_REV_REC_JE_LOGS + queryString;
    return finalEndpoint;
  }
  static getRevRecJeByPage() {
    Utility.cancelRequest(RevenueRecognitionService.abortController);

    RevenueRecognitionService.abortController = Utility.createAbortController();
    return http
      .get(`${RevenueRecognitionService.getRevRecJeEndpoint()}`, {
        signal: RevenueRecognitionService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getReClassificationJE() {
    let queryString: string = `?processType=Create Schedule Reclassification Journal Entries&limit=${
      this.apiConfig.Limit ? this.apiConfig.Limit : 25
    }&page=${this.apiConfig.Page ? this.apiConfig.Page : 0}`;
    const finalEndpoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.GET_REV_REC_JE_LOGS + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading ReClassification JE: ', err);
      return Promise.reject(err);
    });
  }

  static getProcessedReClassificationJE(code: any) {
    let queryString: string = `&limit=${
      this.apiConfig.Limit ? this.apiConfig.Limit : 25
    }&page=${this.apiConfig.Page ? this.apiConfig.Page : 0}`;
    const finalEndpoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.GET_PROCESSED_RECLASSIFICATION_JE(
        code
      ) + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Processed ReClassification JE: ', err);
      return Promise.reject(err);
    });
  }

  static createReClassificationJE(payload: any) {
    const finalEndpoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.CREATE_RECLASSIFICATION_JE;

    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error While Creating ReClassification JE: ', err);
      return Promise.reject(err);
    });
  }
  static updateRevenueReleasePlan(payload:any){
    const finalEndPoint :string = ApiConstants.URL.REVENUE_RECOGNITION.UPDATE_RELEASE_PLAN(payload.planId);
    return http
      .put(finalEndPoint, payload)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getRevenueArrangementLinkedJERecords(code: any) {
    let queryString: string = `?&limit=50&page=0`;
    const finalEndpoint: string =
      ApiConstants.URL.REVENUE_RECOGNITION.REV_ARRANGEMENT_LINKED_RECORD(code) +
      queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error(
        'Error loading Revenue Arrangement Linked JE Records: ',
        err
      );
      return Promise.reject(err);
    });
  }
}

export default RevenueRecognitionService;
