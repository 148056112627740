import React from 'react';
import { DKButton, DKLabel } from 'deskera-ui-library';
import PEPPOLAuthorization from '../Assets/PEPPOL/PEPPOLAuthorization.pdf';

const PeppolRegistrationProcess = (props: any) => {
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="PEPPOL Registration Process" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={`Cancel`}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{ zIndex: 9999 }} className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 500,
          width: '90%',
          maxHeight: '95%',
          height: '365px',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-r p-v-s">
          <div className="column parent-width mt-4 text-left">
            <div className="row p-h-r p-v-s">
              <ul className="list-decimal">
                <li>
                  Download a sample Authorization letter{' '}
                  <a
                    href={PEPPOLAuthorization}
                    target="_blank"
                    className="underline text-blue ml-0.5"
                  >
                    here
                  </a>
                </li>
                <li className="mt-2">
                  Prepare the letter on your company letterhead.
                </li>
                <li className="mt-2">
                  Sign, stamp and email the complete form to{' '}
                  <span className="underline text-blue ml-0.5">
                    einvoicing@deskera.sg
                  </span>
                  <br />
                  Include in the email your:
                  <ul className="list-disc ml-3 mt-2">
                    <li>Name</li>
                    <li>Designation</li>
                    <li>Telephone Number</li>
                    <li>Contact Email</li>
                  </ul>
                </li>
                <li className="mt-2">
                  We will create your PEPPOL ID and send it to you for
                  authorization via Corppass.
                </li>
                <li className="mt-2">
                  You can then update your PEPPOL ID in your organisation's
                  settings.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeppolRegistrationProcess;
