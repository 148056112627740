import React, { Component } from 'react';
import './SecurityGateEntryTemplate1.css';
import '../../index.css';
import ComponentManager from '../../Manager/ComponentManager';
import {
	EditorInfoType,
	FONT_SIZE,
	PAGE_PADDING,
} from '../../Constants/Constants';
import DocumentManager from '../../Manager/DocumentManager';
import Utility, {
	getFontSizeClass,
	getPageHeight,
	getVW,
	getIsJournalEntry,
	getCapitalized,
	getIsOrder,
	getIsBill,
} from '../../Utilities/Utility';
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKCustomFieldSection from '../../DKUILibrary/DKCustomFieldSection';
import { AddressType } from '../../Helper/AddressParser';
import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
import DKApprovalSection from '../../DKUILibrary/DKApprovalSection';
import { HTMLTag } from "../../Utilities/HTMLTag";

export default class SecurityGateEntryTemplate1 extends Component {
	spacingRatio = 0.25;

	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data,
		};
	}

	componentWillReceiveProps(nextProp) {
		this.setState({
			data: nextProp.data,
		});
	}

	getCompanySection() {
		return (
			<div
				className="RowDiv"
				style={{
					justifyContent: 'center',
					alignItems: 'flex-start',
					paddingTop: getVW(
						Utility.convertToPrintScale(
							10,
							this.state.data.printScale
						)
					),
					paddingBottom: getVW(
						Utility.convertToPrintScale(
							5,
							this.state.data.printScale
						)
					),
				}}
			>
				<div
					className="ColumnDiv"
					style={{
						alignItems: 'left',
						flex: 1,
						paddingLeft: getVW(
							Utility.convertToPrintScale(
								10,
								this.state.data.printScale
							)
						),
					}}
				>
					{ComponentManager.getLogoVisibility(
						this.state.data.showCompanyLogo,
						this.state.data.isReadOnlyMode,
						this.state.data.logo
					) &&
						ComponentManager.getLogo(
							this.state.data.logo,
							Utility.convertToPrintScale(
								70,
								this.state.data.printScale
							),
							{ position: 'relative' }
						)}
					{ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
				</div>
				<div
					className="ColumnDiv"
					style={{ alignItems: 'center', flex: 2 }}
				>
					<div
						className="ColumnDiv"
						style={{
							justifyContent: 'center',
							alignItems: 'flex-end',
							alignSelf: 'baseline',
							width: '100%',
						}}
					>
						{this.state.data.showCompanyName && (
							<text
								className={
									'docFontStyleBold' + getFontSizeClass()
								}
								style={{
									textAlign: 'center',
									alignSelf: 'center',
									...Utility.getFontStyleProp(
										{ fontSize: FONT_SIZE.medium },
										this.state.data.printScale
									),
								}}
							>
								{getLocalisedText(
									Utility.getCompanyName(this.state.data)
								)}
							</text>
						)}
						{this.state.data.showCompanyName &&
							ComponentManager.addVerticalSpace(
								5 * this.spacingRatio
							)}
						{this.state.data.showFrom && (
							<div
								className={'docFontStyle' + getFontSizeClass()}
								style={{
									color: '#000000',
									textAlign: 'center',
									width: '100%',
									...Utility.getFontStyleProp(
										{
											fontSize: FONT_SIZE.regular,
											ownerType: Utility.getOwnerType(
												this.state.data,
												AddressType.from
											),
										},
										this.state.data.printScale
									),
								}}
								dangerouslySetInnerHTML={{
									__html: getLocalisedText(
										Utility.getCompanyAddress(
											this.state.data
										)
									),
								}}
							></div>
						)}
						<div
							className={'docFontStyle' + getFontSizeClass()}
							style={{
								width: '100%',
								marginTop: 0,
								color: '#000000',
								textAlign: 'center',
								...Utility.getFontStyleProp(
									{
										fontSize: FONT_SIZE.regular,
										ownerType: undefined,
									},
									this.state.data.printScale
								),
							}}
							dangerouslySetInnerHTML={{
								__html: getLocalisedText(
									getIsOrder(this.state.data.type) ||
										getIsBill(this.state.data.type)
										? this.state.data
											.billOrderCompanyStateNameCode
										: this.state.data
											.companyStateNameAndCode
								),
							}}
						></div>
					</div>
				</div>
				<div
					className="ColumnDiv"
					style={{
						alignItems: 'left',
						flex: 1,
						padding: getVW(20 * this.spacingRatio),
					}}
				></div>
			</div>
		);
	}

	getDocumentHeading() {
		return (
			<div
				className="RowDiv"
				style={{
					justifyContent: 'center',
					paddingTop: getVW(
						Utility.convertToPrintScale(
							10 * this.spacingRatio,
							this.state.data.printScale
						)
					),
					paddingBottom: getVW(
						Utility.convertToPrintScale(
							10 * this.spacingRatio,
							this.state.data.printScale
						)
					),
					borderTop: '1px solid black',
				}}
			>
				<div className="ColumnDiv" style={{ alignItems: 'center' }}>
					<text
						className={'docFontStyleBold' + getFontSizeClass()}
						style={{
							...Utility.getFontStyleProp(
								{ fontSize: FONT_SIZE.xMedium },
								this.state.data.printScale
							),
						}}
					>
						{getCapitalized(
							getLocalisedText(this.state.data.documentType)
						)}
					</text>
				</div>
			</div>
		);
	}

	getDocumentDetailsSection() {
		const documentDetailList = Utility.getDocumentDetailsList(
			this.state.data
		);
		const selectedDocDetails = documentDetailList
			.filter((x) => x.isSelected)
			.sort((a, b) => a.index - b.index);

		if (selectedDocDetails.length === 0) {
			return <></>;
		}

		let topRowItems = [];
		let maxTopCount = 0;

		if (selectedDocDetails.length === 1) {
			maxTopCount = 1;
			topRowItems = [selectedDocDetails[0]];
		} else if (selectedDocDetails.length >= 2) {
			maxTopCount = 2;
			topRowItems = [selectedDocDetails[0], selectedDocDetails[1]];
		}

		let secondRowLeftSideItems = selectedDocDetails.slice(
			maxTopCount,
			selectedDocDetails?.length - 2
		);

		let topRowView = undefined;
		if (topRowItems.length > 0) {
			let topRight =
				topRowItems.length === 2 ? topRowItems[1] : undefined;
			topRowView = this.getLeftAndRightDetailsSection(
				topRowItems[0],
				topRight
			);
		}

		let secondRowLeftView = undefined;
		if (secondRowLeftSideItems.length > 0) {
			secondRowLeftView = secondRowLeftSideItems.map((element) => {
				return this.getSingleDetailsSection(element);
			});
		}

		let secondRowView = (
			<div
				className="RowDiv parent-width"
				style={{ borderTop: '1px solid black' }}
			>
				<div
					className="ColumnDiv"
					style={{
						flex: 2,
					}}
				>
					<div
						className="ColumnDiv"
						style={{
							paddingLeft: getVW(
								Utility.convertToPrintScale(
									5,
									this.state.data.printScale
								)
							),
						}}
					>
						{this.state.data.showClientShipToAddress &&
							ComponentManager.getDescriptionSection(
								this.state.data.shipToTitle,
								this.state.data.clientShipToAddress,
								'100%',
								'black',
								false,
								'black',
								getVW(6),
								Utility.getOwnerType(
									this.state.data,
									AddressType.shipTo
								),
								this.state.data.printScale
							)}
					</div>
				</div>
				{secondRowLeftView && (
					<div
						className="ColumnDiv"
						style={{
							flex: 2,
							alignSelf: 'normal',
							borderLeft: '1px solid black',
						}}
					>
						<div
							className="ColumnDiv"
							style={{
								paddingLeft: getVW(
									Utility.convertToPrintScale(
										5,
										this.state.data.printScale
									)
								),
							}}
						>
							{secondRowLeftView}
						</div>
					</div>
				)}
			</div>
		);
		let thirdRowItems = null;
		if (
			topRowItems?.length + secondRowLeftSideItems?.length <
			selectedDocDetails?.length
		) {
			thirdRowItems = selectedDocDetails.slice(
				topRowItems?.length + secondRowLeftSideItems?.length,
				selectedDocDetails?.length
			);
		}
		let thirdRowView = undefined;
		if (thirdRowItems?.length > 0) {
			let topRight =
				thirdRowItems.length === 2 ? thirdRowItems[1] : undefined;

			thirdRowView = this.getLeftAndRightDetailsSection(
				thirdRowItems[0],
				topRight
			);
		}

		return (
			<div className="ColumnDiv parent-width">
				<div
					className="RowDiv"
					style={{ borderTop: '1px solid black' }}
				>
					{topRowView}
				</div>
				{secondRowView}
				<div
					className="RowDiv"
					style={{ borderTop: '1px solid black' }}
				>
					{thirdRowView}
				</div>
			</div>
		);
	}

	getDocumentDetailsValue(element) {
		let value = '';
		if (element.key !== undefined) {
			value = this.state.data[element.key];
		} else if (element.customFieldValue !== undefined) {
			value = element.customFieldValue;
		}

		return value;
	}

	getRowValueSection(title, value, flex) {
		// const paddingHorizontal = getVW(Utility.convertToPrintScale(10, this.state.data.printScale))
		return (
			<div
				className="RowDiv"
				style={{
					paddingTop: getVW(
						Utility.convertToPrintScale(
							2,
							this.state.data.printScale
						)
					),
					paddingBottom: getVW(
						Utility.convertToPrintScale(
							2,
							this.state.data.printScale
						)
					),
					flex: flex,
				}}
			>
				<text
					className={'docFontStyle' + getFontSizeClass()}
					style={{
						color: this.state.data.themeColor,
						...Utility.getFontStyleProp(
							{ fontSize: FONT_SIZE.regular },
							this.state.data.printScale
						),
					}}
				>
					{getLocalisedText(title)}
				</text>
				<text
					className={'docFontStyle' + getFontSizeClass()}
					style={{
						color: this.state.data.themeColor,
						...Utility.getFontStyleProp(
							{ fontSize: FONT_SIZE.regular },
							this.state.data.printScale
						),
					}}
				>
					:
				</text>
				&nbsp;
				<text
					className={'docFontStyleBold' + getFontSizeClass()}
					style={{
						...Utility.getFontStyleProp(
							{ fontSize: FONT_SIZE.regular },
							this.state.data.printScale
						),
					}}
				>
					{value}
				</text>
			</div>
		);
	}

	getLeftAndRightDetailsSection(left, right) {
		return (
			<div className="RowDiv">
				{left ? (
					<div
						className="RowDiv"
						style={{
							flex: 2,
							paddingLeft: getVW(
								Utility.convertToPrintScale(
									5,
									this.state.data.printScale
								)
							),
						}}
					>
						{this.getRowValueSection(
							left.label,
							this.getDocumentDetailsValue(left)
						)}
					</div>
				) : (
					<></>
				)}
				{right ? (
					<div
						className="RowDiv"
						style={{
							flex: 2,
							borderLeft: '1px solid black',
							paddingLeft: getVW(
								Utility.convertToPrintScale(
									5,
									this.state.data.printScale
								)
							),
						}}
					>
						{this.getRowValueSection(
							right.label,
							this.getDocumentDetailsValue(right)
						)}
					</div>
				) : (
					<></>
				)}
			</div>
		);
	}

	getSingleDetailsSection(item) {
		return (
			<div className="RowDiv">
				{item ? (
					this.getRowValueSection(
						item.label,
						this.getDocumentDetailsValue(item)
					)
				) : (
					<></>
				)}
			</div>
		);
	}

	getNotesValue() {
		let notes = '';
		if (
			TemplateSettingsManager.getRemoteTemplateUID() === undefined ||
			TemplateSettingsManager.getRemoteTemplateUID() === null
		) {
			notes = 'notes_placeholder_text';
		}
		if (
			TemplateSettingsManager.defaultTemplateSettings.documentInfo
				.documentDetails.notes.text !== 'notes_placeholder_text'
		) {
			notes =
				TemplateSettingsManager.defaultTemplateSettings.documentInfo
					.documentDetails.notes.text;
		}
		return getLocalisedText(notes);
	}

	getTermsAndConditionOrNotesSection() {
		const termsAndConditionSection =
			this.state.data.showTermsAndCondition &&
				this.state.data.termsAndCondition ? (
				<div
					className="RowDiv"
					style={{
						width: '-webkit-fill-available',
						paddingTop: getVW(5 * this.spacingRatio),
						paddingBottom: getVW(5 * this.spacingRatio),
					}}
				>
					{ComponentManager.getTermsAndConditionOrNotesSection(
						Utility.getTermsAndConditionTitle(
							getLocalisedText('terms_&_condition')
						),
						getLocalisedText(this.state.data.termsAndCondition),
						'100%',
						this.state.data.themeColor,
						undefined,
						undefined,
						undefined,
						this.state.data.printScale,
						Utility.getTnCOrNotesFontSettingsByType(
							EditorInfoType.termsAndCondition
						)
					)}
				</div>
			) : undefined;

		const notesSection =
			this.state.data.showNotes && this.getNotesValue() ? (
				<div
					className="RowDiv"
					style={{
						width: '-webkit-fill-available',
						paddingTop: getVW(5),
						paddingBottom: getVW(5),
					}}
				>
					{ComponentManager.getTermsAndConditionOrNotesSection(
						Utility.getNotesTitle(
							getIsJournalEntry(this.state.data.type)
								? getLocalisedText('memo')
								: getLocalisedText('notes')
						),
						this.getNotesValue(),
						'100%',
						this.state.data.themeColor,
						undefined,
						undefined,
						undefined,
						this.state.data.printScale,
						Utility.getTnCOrNotesFontSettingsByType(
							EditorInfoType.notes
						)
					)}
				</div>
			) : undefined;

		const remarks = (
			<div
				className="RowDiv"
				style={{
					width: '-webkit-fill-available',
					paddingTop: getVW(5 * this.spacingRatio),
					paddingBottom: getVW(5 * this.spacingRatio),
				}}
			>
				{ComponentManager.getTermsAndConditionOrNotesSection(
					getLocalisedText('remarks'),
					this.state.data.notes ?? '',
					'100%',
					this.state.data.themeColor,
					undefined,
					undefined,
					0,
					this.state.data.printScale,
					undefined
				)}
			</div>
		);

		return (
			<table
				id={HTMLTag.TERMS_NOTE_PREFIX}
				style={{
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					backgroundColor: 'white',
					border: '1px solid',
					paddingTop: getVW(5 * this.spacingRatio),
					paddingBottom: getVW(5 * this.spacingRatio),
					width: '100%',
				}}
			>
				<tbody>
					<tr>
						{termsAndConditionSection && (
							<td style={{ width: '50%', verticalAlign: 'top' }}>
								{termsAndConditionSection}
							</td>
						)}
						{notesSection && (
							<td style={{ width: '50%', verticalAlign: 'top' }}>
								{notesSection}
							</td>
						)}
					</tr>
					<tr>{remarks}</tr>
				</tbody>
			</table>
		);
	}

	getTableColumnTd(left, right) {
		return (
			<tr>
				<td
					style={{
						border: '1px solid',
						width: '50%',
						verticalAlign: 'top',
					}}
				>
					{left}
				</td>
				<td
					style={{
						border: '1px solid',
						width: '50%',
						verticalAlign: 'top',
					}}
				>
					{right}
				</td>
			</tr>
		);
	}

	getGenerateDocumentSection() {
		return (
			<div
				style={{
					paddingBottom: getVW(PAGE_PADDING, true), // Utility.getGeneratedMessagePaddingBottom(this.spacingRatio),
					paddingTop: getVW(5 * this.spacingRatio),
					width: '100%',
				}}
			>
				<text
					className={
						'docFontStyleBold fontSizeNormal ' + getFontSizeClass()
					}
					style={{
						...Utility.getFontStyleProp(
							{ fontSize: FONT_SIZE.regular },
							this.state.data.printScale
						),
					}}
				>
					{Utility.getGeneratedMessage(this.state.data.type)}
				</text>
			</div>
		);
	}

	getEditableDocumentTable() {
		return ComponentManager.getDKDocumentTable(
			this.props.tableColumn,
			this.getTableStyle(),
			this.props.data,
			this.props.tableClicked,
			this.props.stockTableClicked,
			this.props.onUpdateDataCallback,
			this.props.paymentTableClicked,
			this.props.additionalTableCallback
		);
	}

	getTableStyle() {
		let style = DocumentManager.getTableDefaultStyle();
		style.headerTextColor = this.state.data.themeColor;
		style.footerTextColor = this.state.data.themeColor;
		// style.headerBorderColor = "#BBBABA";
		// style.itemBorderColor = "#BBBABA";
		style.headerBorderColor = '#000000';
		style.itemBorderColor = '#000000';
		style.footerBackgroundColor = 'white';
		// style.footerBorderColor = "#BBBABA";
		style.footerBorderColor = '#000000';
		style.headerBackgroundColor = 'transparent';
		style.noPadding = true;
		return style;
	}

	getCustomFieldTableStyle() {
		let style = {};
		style.columnCount = 3;
		style.columnWidths = Utility.getIsArabicLang()
			? ['33%', '33%', '33%']
			: ['33%', '33%', '33%'];
		return style;
	}

	render() {
		// Subtracting top and bottom border height from min page height
		let minHeight = getPageHeight(this.state.data.printScale);
		if (minHeight && minHeight.includes("vw")) {
			minHeight = `${Number(minHeight.replace("vw", "")) - Number(getVW(2).replace("vw", ""))}vw`;
		}

		return (
			<div id={HTMLTag.TEMPLATE_HOLDER}
				className="DocumentHolder ColumnDiv parent-width"
				style={{
					justifyContent: 'space-between',
					minHeight: minHeight,
					border: '1px solid black',
				}}
			>
				<div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
					<div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
						{this.getCompanySection()}
						{this.getDocumentHeading()}
						{this.getDocumentDetailsSection()}
					</div>
					<div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>
						<DKCustomFieldSection
							data={this.state.data}
							onCustomFieldClick={this.props.onCustomFieldClick}
							style={this.getCustomFieldTableStyle()}
							customFieldPosition={'top'}
						/>
					</div>
					<div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
						{this.getEditableDocumentTable()}
					</div>
					<div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
						<DKCustomFieldSection
							data={this.state.data}
							onCustomFieldClick={this.props.onCustomFieldClick}
							style={this.getCustomFieldTableStyle()}
							customFieldPosition={'bottom'}
						/>
						{this.getTermsAndConditionOrNotesSection()}
					</div>
					<div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
						{Utility.getIsShowApprovalSection() && (
							<DKApprovalSection
								data={this.state.data}
								style={this.getTableStyle()}
								isThemeColor
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}
