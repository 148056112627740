import ApiConstants from '../Constants/ApiConstants';
import { DOCUMENT_TYPE } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface WarehouseAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: string;
}

export const defaultConfig: WarehouseAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'desc',
  Sort: 'code',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: ''
};

class WarehouseService {
  static apiConfig: WarehouseAPIConfig;

  static getWarehouseEndPoint(apiConfigData?: any) {
    let configData = apiConfigData;
    if (Utility.isEmpty(configData)) {
      configData = WarehouseService.apiConfig;
    }
    let queryString: string = `?limit=${configData.Limit}&page=${
      configData.Page
    }&search=${configData.SearchTerm ? configData.SearchTerm : ''}&query=${
      configData.Query
    }`;

    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    const { BASE } = ApiConstants.URL;
    const finalEndpoint: string = `${BASE}${ApiConstants.URL.ACCOUNTS.WAREHOUSES}${queryString}`;
    return finalEndpoint;
  }

  static getWarehouses(config?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    if (!Utility.isEmpty(config)) {
      this.apiConfig = config;
    }
    const finalEndPoint = WarehouseService.getWarehouseEndPoint(this.apiConfig);
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getWarehousesWithRRBCombinations(config?: any) {
    let whconfig = config;
    if (Utility.isEmpty(whconfig)) {
      whconfig = defaultConfig;
    }

    const finalEndPoint = WarehouseService.getWarehouseEndPoint(whconfig);
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getWarehousesForAdmin() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalEndPoint = WarehouseService.getWarehouseEndPoint(this.apiConfig);
    finalEndPoint += '&filterOnRolesFlg=false';
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getRejectedWarehouses() {
    const finalEndPoint = ApiConstants.URL.PRODUCT.FETCH_REJECTED_WAREHOUSES;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getQAWarehouses() {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE + '?query=warehouseType=QA';
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getJWOWarehouses() {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE +
      `?query=warehouseType=${DOCUMENT_TYPE.JOB_WORK_OUT}`;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAllWarehouses() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const LIMIT = 100; // this.apiConfig.Limit = 100, it causes to fetch all wh with limit 100 everytime on WH home page
    const query: string = `?limit=${LIMIT}&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}&query=`;
    const finalEndPoint = ApiConstants.URL.ACCOUNTS.WAREHOUSES + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getWarehouseByWarehouseCodes(warehouseCodes: any) {
    const LIMIT = 100;
    const PAGE = 0;
    const query: string = `?limit=${LIMIT}&page=${PAGE}&search=${''}&query=`;
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.GET_WAREHOUSE_BY_CODE + query;

    return http.post(`${finalEndPoint}`, warehouseCodes).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static deleteWarehouse(id: number) {
    try {
      return http.delete(`${ApiConstants.URL.PRODUCT.DELETE_WAREHOUSE(id)}`);
    } catch (err) {
      console.error('Error deleting COA: ', err);
      return Promise.reject(err);
    }
  }

  static postWarehouse(payload: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.POST_WAREHOUSE;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static editWarehouse(payload: any, id: number) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.EDIT_WAREHOUSE(id);
    return http.put(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static updatePrimaryStatusForWarehouse(status: any, id: number) {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.UPDATE_STATUS_WAREHOUSE(id) +
      '?primary=' +
      status;
    return http.patch(`${finalEndPoint}`).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static updateActiveStatusForWarehouse(status: any, id: number) {
    const finalEndPoint =
      ApiConstants.URL.PRODUCT.UPDATE_STATUS_WAREHOUSE(id) +
      '?active=' +
      status;
    return http.patch(`${finalEndPoint}`).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getWarehouseUrl(config: WarehouseAPIConfig) {
    const { BASE, ACCOUNTS } = ApiConstants.URL;
    const limit = config.Limit || 10;
    const page = config.Page || 0;
    const searchTerm = config.SearchTerm || '';
    const query = config.Query || '';
    const queryParams = `?limit=${limit}&page=${page}&search=${searchTerm}&query=${query}`;
    const url = `${BASE}${ACCOUNTS.WAREHOUSES}${queryParams}`;
    return url;
  }

  static assignUsersToWarehouse(payload: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.ASSIGN_USERS_TO_WAREHOUSE(
      payload.id
    );
    return http
      .post(`${finalEndPoint}`, payload.userIds)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getWarehouseByCode(warehouseCode: string) {
    let finalURL = WarehouseService.getWarehouseEndPoint({
      SearchTerm: '',
      Query: `code=${warehouseCode}&allRRBDetails=true`,
      Limit: 25,
      Page: 0
    });

    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error deleting warehouse: ', err);
      });
  }
  static getPrimaryWarehouse() {
    const finalEndPoint = ApiConstants.URL.PRODUCT.GET_PRIMARY_WAREHOUSE;

    return http
      .get(finalEndPoint)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error deleting warehouse: ', err);
      });
  }
}

export default WarehouseService;
