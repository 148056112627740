import { PropsWithChildren } from 'react';
import { DKButton, DKIcons, DKLabel, DKSpinner } from 'deskera-ui-library';

interface SettingsDetailCardProps {
  title?: string;
  isLoading?: boolean;
  editCallback?: () => void;
  className?: string;
}

const SettingsDetailCard = (
  props: PropsWithChildren<SettingsDetailCardProps>
) => {
  return (
    <div
      className={`column parent-width ${
        props.className ? props.className : ''
      }`}
    >
      {props.title && (
        <div className="row mb-r">
          <DKLabel text={props.title} className="fw-m" />
          {props.isLoading && (
            <DKSpinner iconClassName="ic-s" className="ml-s" />
          )}
        </div>
      )}
      <div
        className="column parent-width border-m border-radius-m position-relative p-r"
        style={{ minHeight: 60 }}
      >
        {props.editCallback && (
          <DKButton
            icon={DKIcons.ic_edit}
            className="position-absolute"
            style={{ right: 10, top: 10, padding: 2, opacity: 0.7 }}
            onClick={() => {
              if (props.editCallback) {
                props.editCallback();
              }
            }}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default SettingsDetailCard;
