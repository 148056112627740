import React, { useEffect, useState } from 'react';
import PopupWrapper from '../PopupWrapper';
import {
  DOC_TYPE,
  MULTI_BOOKS_PERSPECTIVE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  SWITCH_CURRENCY_OPTIONS
} from '../../Constants/Constant';
import { BtnType } from '../../Models/Interfaces';
import { DKIcon, DKLabel, DKIcons, DKSpinner } from 'deskera-ui-library';
import LinkedRecordListView from '../LinkedRecords/LinkedRecordListView';
import Utility from '../../Utility/Utility';
import JournalEntryRecordsDetail from '../LinkedRecords/JournalEntryRecordsDetail';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import MultiViewJournalEntryDetail from './MultiViewJournalEntryDetail';
import JournalService from '../../Services/Journal';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
interface MultiViewJournalEntryProps {
  onCancel?: () => void;
  documentType?: string;
  documentCode?: string;
  data?: any;
  jeCode?: string;
  [x: string]: any;
}
const MultiViewJournalEntry = (props: MultiViewJournalEntryProps) => {
  const tenantInfo = useAppSelector(activeTenantInfo); //bookBeginningStartDate
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any[]>([]);
  const [salesReturnsDetails, setSalesReturnsDetails] = useState<any[]>([]);
  const [receivedGoodsDetails, setReceivedGoodsDetails] = useState<any[]>([]);
  const [purchaseReturnsDetails, setPurchaseReturnsDetails] = useState<any[]>(
    []
  );
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [paymentRecordDetails, setPaymentRecordDetails] = useState<any[]>([]);
  const [journalEntryDetails, setJournalEntryDetails] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<any>(DOC_TYPE.JOURNAL_ENTRY);
  const [fulfillmentCode, setFulfillmentCode] = useState<any>('');
  const [salesReturnCode, setSalesReturnCode] = useState<any>('');
  const [paymentRecordData, setPaymentRecordData] = useState<any>(null);
  const [purchaseReturnCode, setPurchaseReturnCode] = useState<any>('');
  const [journalEntryCode, setJournalEntryCode] = useState<any>('');
  const [receivedGoodRecordData, setReceivedGoodRecordData] =
    useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    multiViewJournalEntryDetailState,
    setMultiViewJournalEntryDetailState
  ] = useState<any>({
    switchCurrency: {
      isHidden: false,
      dataOptions: getSwitchCurrencyOptions(),
      value: {
        title: `${MULTI_BOOKS_PERSPECTIVE.STATUTORY} (${tenantInfo?.currency})`,
        value: SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      }
    },
    exchangeRate: 1
  });
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const fetchDocumentData = () => {
    let documentCode = props?.documentCode;
    let amountInPrimaryPerspective =
      props.data?.perspective == 'PRIMARY' ? true : false;
    const baseCurrency = tenantInfo.currency;
    const primaryCurrency =
      tenantInfo?.additionalSettings.MULTI_COMPANY.primaryCurrencyCode;
    if (baseCurrency === primaryCurrency) {
      amountInPrimaryPerspective = false;
    }
    const payload: any = {
      amountInPrimaryPerspective: amountInPrimaryPerspective,
      baseCurrency: tenantInfo.currency,
      documentCode: documentCode,
      // jeCode: props.data?.journalEntryCode,
      jeCode: props?.jeCode,
      primaryCurrency:
        tenantInfo?.additionalSettings.MULTI_COMPANY.primaryCurrencyCode,
      type: props.documentType
    };
    setIsLoading(true);
    JournalService.getJournalByPerspective(payload)
      .then((res: any) => {
        setJournalEntryDetails(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const switchCurrencyOptions = getSwitchCurrencyOptions();
    const statutoryTitle = `${MULTI_BOOKS_PERSPECTIVE.STATUTORY}(${tenantInfo?.currency})`;
    const primaryTitle = `${MULTI_BOOKS_PERSPECTIVE.PRIMARY} (${tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode})`;
    const jeSwitchCurrent = {
      statutory: {
        title: statutoryTitle,
        value: SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      },
      primary: {
        title: primaryTitle,
        value: SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      }
    };
    setMultiViewJournalEntryDetailState({
      switchCurrency: {
        isHidden: false,
        dataOptions: switchCurrencyOptions,
        value:
          props.data?.perspective == 'PRIMARY'
            ? jeSwitchCurrent.primary
            : jeSwitchCurrent.statutory
      }
    });
  }, [props.data]);
  const handleCurrencySwitchChange = (amountInPrimaryPerspective: boolean) => {
    let documentCode = props?.documentCode;
    const baseCurrency = tenantInfo.currency;
    const primaryCurrency =
      tenantInfo?.additionalSettings.MULTI_COMPANY.primaryCurrencyCode;
    if (baseCurrency === primaryCurrency) {
      amountInPrimaryPerspective = false;
    }
    const payload: any = {
      amountInPrimaryPerspective: amountInPrimaryPerspective,
      baseCurrency: tenantInfo.currency,
      jeCode: props?.jeCode,
      documentCode: documentCode,
      primaryCurrency:
        tenantInfo?.additionalSettings.MULTI_COMPANY.primaryCurrencyCode,
      type: props.documentType
    };
    JournalService.getJournalByPerspective(payload).then((res: any) => {
      setJournalEntryDetails(res);
    });
  };
  useEffect(() => {
    fetchDocumentData();
    setIsDesktop(isViewportLarge());
  }, [props]);
  const popupBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const getNoDataView = () => {
    return (
      <div
        className="parent-width column justify-content-center align-items-center"
        style={{ pointerEvents: 'none', height: 350 }}
      >
        <DKIcon src={DKIcons.ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={'No Journal record found'} className="fw-m mt-l" />
      </div>
    );
  };
  const getLoadingView = () => {
    return (
      <div
        className="parent-width column justify-content-center align-items-center"
        style={{ pointerEvents: 'none', height: 350 }}
      >
        <DKSpinner />
      </div>
    );
  };
  useEffect(() => {
    const journalDetail =
      journalEntryDetails.length > 0
        ? journalEntryDetails.filter(
            (journalEntry) => journalEntry.jeCode === props.jeCode
          )[0]
        : {};
    let exchangeRate =
      1 /
      Number(
        (tenantInfo?.currency !== journalDetail?.currency &&
          journalDetail?.currencyExchangeRate) ||
          journalDetail?.primaryCurrencyExchangeRate ||
          1
      );
    setMultiViewJournalEntryDetailState((prev: any) => {
      return {
        ...prev,
        exchangeRate: exchangeRate
      };
    });
  }, [journalEntryDetails]);
  function getSwitchCurrencyOptions() {
    const statutoryTitle = `${MULTI_BOOKS_PERSPECTIVE.STATUTORY}(${tenantInfo?.currency})`;
    const primaryTitle = `${MULTI_BOOKS_PERSPECTIVE.PRIMARY} (${tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode})`;

    return [
      {
        title: statutoryTitle,
        value: SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      },
      {
        title: primaryTitle,
        value: SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      }
    ];
  }

  function getAllRecordSections() {
    return (
      <LinkedRecordListView
        documentDetails={props.data}
        documentCode={props.documentCode}
        documentType={props.documentType}
        fulfillmentDetails={fulfillmentDetails}
        salesReturnsDetails={salesReturnsDetails}
        paymentRecordDetails={paymentRecordDetails}
        receivedGoodsDetails={receivedGoodsDetails}
        purchaseReturnsDetails={purchaseReturnsDetails}
        journalEntryDetails={journalEntryDetails}
        paymentRecords={paymentRecordData}
        activeTab={activeTab}
        onActiveTabChange={(data: any) => setActiveTab(data)}
        onFulfillmentChange={(data: any) => setFulfillmentCode(data)}
        onSalesOrderChange={(data: any) => setSalesReturnCode(data)}
        onPaymentRecordChange={(data: any) => {
          setPaymentRecordData(data);
          // setActiveTab(DOC_TYPE.Payment);
        }}
        onPurchaseOrderChange={(data: any) => setPurchaseReturnCode(data)}
        onJournalEntryChange={(data: any) => setJournalEntryCode(data)}
        onReceivedGoodRecordChange={(data: any) =>
          setReceivedGoodRecordData(data)
        }
        isDeleted={(val: boolean) => {
          // if (val) {
          //   props.isDeleted(val);
          //   getFulfillmentRecords(props.data, true);
          //   getSalesReturnRecords(props.data, true);
          //   if (props.documentType === DOC_TYPE.BILL) {
          //     getBillsReceivedGoodsRecord(props.data, true);
          //     getPurchaseReturnRecords(props.data, true);
          //   }
          // }
        }}
        fetchCurrentDocument={() => fetchDocumentData()}
        isDocumentEmailFlow={props.isDocumentEmailFlow}
        setSelectedIndex={setSelectedIndex}
      />
    );
  }
  const getDetailViewHeader = (title: string) => {
    return (
      <DKLabel
        text={title}
        className="fs-l pl-m fw-m border-b parent-width pb-xs"
      />
    );
  };
  function getDetailRecordView() {
    return (
      <>
        {!Utility.isEmptyObject(journalEntryDetails) &&
          DOC_TYPE.JOURNAL_ENTRY === activeTab && (
            <div
              className="column parent-width align-self-start pt-2 overflow-y-auto"
              style={{
                width: !isDesktop ? '70%' : '80%',
                maxHeight: 600
              }}
            >
              {getDetailViewHeader(`Journal Entry Records`)}
              <MultiViewJournalEntryDetail
                setMultiViewJournalEntryDetailState={
                  setMultiViewJournalEntryDetailState
                }
                multiViewJournalEntryDetailState={
                  multiViewJournalEntryDetailState
                }
                documentDetails={props.data}
                handleCurrencySwitchChange={handleCurrencySwitchChange}
                journalEntryDetails={
                  journalEntryDetails.filter(
                    (journalEntry: any) =>
                      journalEntry.jeCode === journalEntryCode
                  )[0] || []
                }
              />
            </div>
          )}
      </>
    );
  }
  return (
    <PopupWrapper
      clickAction={() => props?.onCancel?.()}
      type={POPUP_TYPE.POPUP}
      title={'GL Impact'}
      btnList={popupBtnConfig}
      width={`90%`}
      height={'auto'}
      disableClickOutside={true}
      showScrollBar={false}
      popupContainerStyles={{
        padding: 0,
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem'
      }}
    >
      {journalEntryDetails.length > 0 ? (
        <div className="row parent-width">
          {getAllRecordSections()}
          {getDetailRecordView()}
        </div>
      ) : (
        <>{isLoading ? getLoadingView() : getNoDataView()}</>
      )}
    </PopupWrapper>
  );
};

export default MultiViewJournalEntry;
