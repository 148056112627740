import {
  DKButton,
  DKLabel,
  DKRadioButton,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput
} from 'deskera-ui-library';
import React, { useState } from 'react';
import { GST_TYPE } from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import { DynamicPopupWrapper } from '../PopupWrapper';

export default function GSTValueForm(props: any) {
  // { rowIndex: rowIndex, itcValue: itcValue }
  const [igstVal, setIgstVal] = useState<any>(
    props.GSTData?.rowData?.igstAmount
  );
  const [sgstVal, setSgstVal] = useState<any>(
    props.GSTData?.rowData?.sgstAmount
  );
  const [cgstVal, setCgstVal] = useState<any>(
    props.GSTData?.rowData?.cgstAmount
  );
  const [gstType, setGstType] = useState<any>(props.GSTData?.rowData?.gstType);
  const [cessVal, setCessVal] = useState<any>(
    props.GSTData?.rowData?.cessAmount
  );
  const [cessRule, setCessRule] = useState<any>(
    props.GSTData?.rowData?.cessRule
  );
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={'Update GST Value'} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() =>
              props.setGSTChangeValue({
                rowIndex: props.GSTData.rowIndex,
                igstValue: igstVal,
                cgstValue: cgstVal,
                sgstValue: sgstVal,
                cessValue: cessVal || 0
              })
            }
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="align-items-start pt-4">
        {gstType === GST_TYPE.INTRA && (
          <div className=" align-items-start  parent-width">
            <div className="row parent-width">
              <DKInput
                title="CGST"
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.NUMBER}
                value={cgstVal}
                className=""
                onChange={(val: any) => {
                  setCgstVal(val);
                }}
              />
            </div>
            <div className="row mt-3">
              <DKInput
                title="SGST"
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.NUMBER}
                value={sgstVal}
                className=""
                onChange={(val: any) => {
                  setSgstVal(val);
                }}
              />
            </div>
          </div>
        )}
        {gstType === GST_TYPE.INTER && (
          <div className="row align-items-start">
            <DKInput
              title="IGST"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={igstVal}
              className=""
              onChange={(val: any) => {
                setIgstVal(val);
              }}
            />
          </div>
        )}
        {!Utility.isEmpty(cessRule) && (
          <div className="row align-items-start mt-3">
            <DKInput
              title="CESS"
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.NUMBER}
              value={cessVal}
              className=""
              onChange={(val: any) => {
                setCessVal(val);
              }}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 500,
            width: '90%',
            maxHeight: '85%',
            height: 350,
            padding: 0,
            paddingBottom: 0
          }}
        >
          {getHeader()}
          <div className="p-h-xl parent-width">{getBody()}</div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
