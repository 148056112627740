import React, { Fragment } from 'react';
import Draggable, { ControlPosition, DraggableEventHandler } from 'react-draggable';
import TemplateSettingsManager from '../../../../Manager/TemplateSettingsManager';

interface States {
    disabled: boolean
    isSelected: boolean
    defaultPosition: ControlPosition
    position: ControlPosition
}

interface Props {
    axis: "both" | "x" | "y" | "none"
    width: string;
    bounds: string
    scale: number
    disabled: boolean
    isSelected: boolean
    defaultPosition: ControlPosition
    position: ControlPosition
    onStop: DraggableEventHandler
}

export default class DraggableComponent extends React.Component<Props, States> {
    constructor(props: any) {
        super(props)
        this.state = {
            disabled: true,
            isSelected: this.props.isSelected,
            defaultPosition: this.props.defaultPosition,
            position: this.props.position
        }
    }

    componentWillReceiveProps(nextProps: Readonly<Props>): void {
        this.setState({
            isSelected: nextProps.isSelected,
            defaultPosition: nextProps.defaultPosition,
            position: nextProps.position
        });
    }

    renderDraggableComponent() {
        return (
            <Draggable
                disabled={this.state.disabled || this.props.disabled}
                axis={this.props.axis}
                bounds={this.props.bounds}
                scale={this.props.scale}
                defaultPosition={this.props.defaultPosition}
                position={this.props.position}
                onStop={(e, data) => {
                    this.props.onStop(e, data);
                }}
            >
                <div
                    tabIndex={0}
                    style={{
                        width: this.props.width || "fit-content",
                        position: "absolute"
                    }}
                    onMouseOver={() => {
                        this.setState({
                            disabled: !!TemplateSettingsManager?.getPrintInfo()
                        });
                    }}
                    onMouseOut={() => {
                        this.setState({
                            disabled: true
                        });
                    }}
                >
                    <div
                        style={{
                            border: `${!this.state.disabled ? "1px dashed rgba(128, 128, 128, 0.4)" : "1px solid transparent"}`,
                            cursor: `${!this.state.disabled ? "move" : "default"}`
                        }}
                    >
                        {this.props.children}
                    </div>
                </div>
            </Draggable>
        );
    }

    render() {
        return (
            <Fragment>
                {this.props.isSelected && this.renderDraggableComponent()}
            </Fragment>
        );
    }
}
