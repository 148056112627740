import React, { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  FORM_ELEMENTS,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import { InputTag } from '../../Models/NewContact';
import BillService from '../../Services/Bill';
import InvoiceService from '../../Services/Invoice';
import PaymentService from '../../Services/Payment';
import CustomInput from '../CustomInput/CustomInput';
import { showToast, TOAST_TYPE, DKDataGrid, DKLabel } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import { CallBackPayloadType } from '../../Models/Interfaces';
import DateFormatService from '../../Services/DateFormat';
import FormatAmount from '../FormatAmount';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import SideBarService from '../../Services/sidebar';
import Utility from '../../Utility/Utility';

interface OffsetPopupProps {
  documentData: any;
  documentType: DOC_TYPE;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

interface OffsetPopupState {
  id: number;
  currency: string;
  dueAmount: number;
  contactCode: string;
  exchangeRate: number;
  fetchedDocList: DocDetails[] | null;
  totalAmount: number;
  docCode: string;
}

interface DocDetails {
  dueAmount: number;
  documentCode: string;
  documentDate: string;
  id: number;
  amount: InputTag<'Offset Amount'>;
  documentSequenceCode: string;
}

export interface OffsetPayload {
  contactCode: string;
  amount: number;
  currency: string;
  documentDate: string;
  exchangeRate: number;
  contraBillItems?: PayloadItem[];
  contraInvoiceItems?: PayloadItem[];
  invoiceCode?: string;
  billCode?: string;
}

type PayloadItem = {
  amount: string;
  amountInBaseCurrency: string;
  purchaseInvoiceCode?: string;
  salesInvoiceCode?: string;
};

const OffsetPopup: React.FC<OffsetPopupProps> = (props) => {
  const initialState: OffsetPopupState = {
    id: props.documentData.id,
    currency: props.documentData.currency,
    dueAmount: props.documentData.dueAmount,
    contactCode: props.documentData.contactCode,
    fetchedDocList: null,
    exchangeRate: props.documentData.exchangeRate,
    totalAmount: 0,
    docCode:
      props.documentType === DOC_TYPE.BILL
        ? props.documentData.purchaseInvoiceCode
        : props.documentData.salesInvoiceCode
  };
  const localConfig = [
    {
      name:
        props.documentType === DOC_TYPE.BILL ? 'Invoice Number' : 'Bill Number',
      type: 'text',
      index: 0,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'docNumber',
      id: 'docNumber',
      datasource: [],
      key: 'docNumber',
      lineItem: false
    },
    {
      name: 'Date',
      type: 'text',
      index: 0,
      options: null,
      required: false,
      width: 125,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'date',
      id: 'date',
      datasource: [],
      key: 'date',
      lineItem: false
    },
    {
      name: 'Outstanding Amount',
      type: 'text',
      index: 0,
      options: null,
      required: false,
      width: 165,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'amount',
      id: 'amount',
      datasource: [],
      key: 'amount',
      lineItem: false
    },
    {
      name: 'Offset Amount',
      type: 'number',
      index: 0,
      options: null,
      required: false,
      width: 150,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'offsetAmount',
      id: 'offsetAmount',
      datasource: [],
      key: 'offsetAmount',
      lineItem: false
    }
  ];

  const [offsetState, setOffsetState] =
    useState<OffsetPopupState>(initialState);
  const [gridData, setGridData] = useState<any>([]);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.documentType === DOC_TYPE.BILL) {
      fetchInvoicesForOffset();
    } else {
      fetchBillsForOffset();
    }
    return () => {
      // cleanup
    };
  }, []);
  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.APPLY_OFFSET,
        data: () => {
          applyOffset();
        }
      });
    }
  };

  const parseDataToGrid = (data: any) => {
      var gridData: any[] = []
      data.forEach((element: any) => {
          var detail: any = {};
          detail['docNumber'] = element['documentSequenceCode']
          detail['date'] = DateFormatService.getFormattedDateString( element['documentDate'], BOOKS_DATE_FORMAT['DD-MM-YYYY'])
          detail['amount'] = Utility.getAmoutBlockForGrid(
            tenantInfo.currency,
            element['dueAmount']
          );
          detail['dueAmount'] = element['dueAmount'];
          detail['offsetAmount'] = element['offsetAmount'] ? element['offsetAmount'] : 0
          detail['invalidFields'] = [];

            gridData.push(detail);
      });
      setGridData(gridData);
  }

  useEffect(() => {
    if (
      offsetState &&
      offsetState['fetchedDocList'] &&
      offsetState['fetchedDocList'].length > 0
    ) {
      parseDataToGrid(offsetState['fetchedDocList']);
    }
  }, [offsetState['fetchedDocList']])

  useEffect(() => {
    registerInteractions();
  });

  const fetchBillsForOffset = () => {
    const queryString: string = `contactCode=${offsetState.contactCode},dueAmount>0,currency=${offsetState.currency}`;
    const limit: number = 1000;
    BillService.getBills(queryString, limit, '').then((res: any) => {
      const billList: any[] = res.content;
      if (billList.length > 0) {
        const newState = offsetState;
        let records: DocDetails[] = [];
        billList.forEach((bill) => {
          const doc: DocDetails = {
            id: bill.id,
            dueAmount: bill.dueAmount,
            documentCode: bill.purchaseInvoiceCode,
            documentDate: bill.purchaseInvoiceDate,
            amount: {
              key: 'Offset Amount',
              hidden: false,
              value: '',
              type: FORM_ELEMENTS.INPUT,
              hasError: false,
              defaultValue: '0'
            },
            documentSequenceCode: bill.documentSequenceCode
          };
          records.push(doc);
        });
        newState.fetchedDocList = [...records];
        setOffsetState({ ...newState });
      }
    });
  };

  const fetchInvoicesForOffset = () => {
    const queryString: string = `contactCode=${offsetState.contactCode},dueAmount>0,currency=${offsetState.currency}`;
    const limit: number = 1000;
    InvoiceService.getInvoices(queryString, limit, '').then((res: any) => {
      const invoiceList: any[] = res.content;
      if (invoiceList.length > 0) {
        const newState = offsetState;
        let records: DocDetails[] = [];
        invoiceList.forEach((invoice) => {
          const doc: DocDetails = {
            id: invoice.id,
            dueAmount: invoice.dueAmount,
            documentCode: invoice.salesInvoiceCode,
            documentDate: invoice.salesInvoiceDate,
            amount: {
              key: 'Offset Amount',
              hidden: false,
              value: '',
              type: FORM_ELEMENTS.INPUT,
              hasError: false,
              defaultValue: '0'
            },
            documentSequenceCode: invoice.documentSequenceCode
          };
          records.push(doc);
        });
        newState.fetchedDocList = [...records];
        setOffsetState({ ...newState });
      }
    });
  };

  const inputChanged = (
    value: any,
    inputIndex: any
  ) => {
    const newState = offsetState;
    if (newState.fetchedDocList) {
      newState.fetchedDocList[inputIndex].amount.value = value;
      const totalAmount = calculateTotalAmount(newState);
      newState.totalAmount = totalAmount;
    }
    setOffsetState({ ...newState });
  };

  const calculateTotalAmount = (offsetPopupState: OffsetPopupState): number => {
    let totalAmount: number = 0;
    if (offsetPopupState.fetchedDocList) {
      offsetPopupState.fetchedDocList.forEach((offset) => {
        totalAmount = totalAmount + Number(offset.amount.value);
      });
    }
    return totalAmount;
  };

  const validateOffset = () => {
      var totalOffset = 0;
      var showValidation = false;
      if (offsetState && offsetState.fetchedDocList) {
        offsetState.fetchedDocList.forEach((e: any) => {
          if (e['amount']['value']) {
            totalOffset = totalOffset + parseFloat(e['amount']['value']);
          }
          if (totalOffset > offsetState.dueAmount) {
            showValidation = true;
          }
        });
      }
      return showValidation
  }

  const applyOffset = () => {
    if (!offsetState.fetchedDocList) {
      showToast('No Records found', TOAST_TYPE.WARNING);
      return;
    }

    var invalidRecords = gridData.map((e: any) => e.invalidFields.length > 0).filter((invalid: any) => invalid)
    var offsetAmount = gridData.map((e: any) => e.offsetAmount)

    if (invalidRecords.length > 0) {
        showToast('Offset Amount cannot exceed Outstanding Amount', TOAST_TYPE.FAILURE);
        return;
    }

    if (offsetAmount.reduce((a: number, b: number) => a + b, 0) <= 0)    {
        showToast('Please enter offset amount', TOAST_TYPE.WARNING);
        return;
    }

    if (validateOffset()) {
        return;
    }

    let payloadItemList: PayloadItem[] = [];
    offsetState.fetchedDocList?.forEach((offset) => {
      if (Number(offset.amount.value) > 0) {
          let payloadItem: PayloadItem = {
            amount: offset.amount.value.toString(),
            amountInBaseCurrency: offset.amount.value.toString()
          };
          if (props.documentType === DOC_TYPE.INVOICE) {
            payloadItem.purchaseInvoiceCode = offset.documentCode;
          } else {
            payloadItem.salesInvoiceCode = offset.documentCode;
          }
          payloadItemList.push(payloadItem);
      }
    });
    const offsetPayload: OffsetPayload = {
      contactCode: offsetState.contactCode,
      currency: offsetState.currency,
      amount: offsetState.totalAmount,
      exchangeRate: offsetState.exchangeRate,
      documentDate: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    if (props.documentType === DOC_TYPE.BILL) {
      offsetPayload.contraBillItems = payloadItemList;
      offsetPayload.billCode = offsetState.docCode;
    } else {
      offsetPayload.contraInvoiceItems = payloadItemList;
      offsetPayload.invoiceCode = offsetState.docCode;
    }
    PaymentService.offsetPayment(offsetPayload, props.documentType).then(
      (res) => {
        showToast('Offset created successfully', TOAST_TYPE.SUCCESS);
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
        if (props.documentType === DOC_TYPE.INVOICE) {
          dispatch(fetchInvoices());
        } else {
          dispatch(fetchBills());
        }
      }
    );
  };

  const onRowUpdate = (val: any) => {


    var tmpData = [...gridData]
    if (!val['rowData']['offsetAmount']) {
        val['rowData']['offsetAmount'] = 0;
    }
      if (parseFloat(val['rowData']['offsetAmount']) > parseFloat(val['rowData']['dueAmount'])) {
        tmpData[val['rowIndex']]['offsetAmount'] = val['rowData']['dueAmount'];
        setGridData(tmpData);
      } else    {
          var invalidData: any = { ...tmpData[val['rowIndex']] };
          invalidData['invalidFields'] = [];
          tmpData[val['rowIndex']] = invalidData;
          setGridData(tmpData);
      }
    inputChanged(parseFloat(val['rowData']['offsetAmount']), val['rowIndex']);
  };

  const getNoDataView = () => {
    return (
      <div
        className="column justify-self-center align-self-center align-items-center m-v-m"
        style={{ pointerEvents: 'none' }}
      >
        <DKLabel
          text="No data found"
          className="fw-m"
          style={{ }}
        />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };

  return (
    <div className="p-3">
      <div className="font-medium">
        {props.documentType === DOC_TYPE.BILL
          ? 'Bill Outstanding Amount'
          : 'Invoice Outstanding Amount'}
      </div>
      <div className="bg-gray-200 flex justify-end items-center px-5 py-3 mt-2">
        <div className="flex flex-row">
          <div className="font-medium text-3xl">
            <FormatAmount
              value={offsetState.dueAmount}
              currencyCode={tenantInfo.currency}
            />
          </div>
        </div>
      </div>
      {offsetState &&
        offsetState.fetchedDocList &&
        offsetState.fetchedDocList.length > 0 && (
          <DKDataGrid
            width={'100%'}
            columns={localConfig}
            rows={gridData}
            allowRowEdit={true}
            onRowUpdate={onRowUpdate}
            currentPage={1}
            totalPageCount={1}
            title={''}
            allowBulkOperation={false}
          />
        )}
      {offsetState &&
        (offsetState.fetchedDocList === null ||
          offsetState.fetchedDocList === undefined) && <>{getNoDataView()}</>}
      <div className="w-full h-px bg-black mt-2"></div>
      <div
        className={
          'mt-4 ' +
          (validateOffset()
            ? 'row flex-end parent-width justify-content-between'
            : 'row-reverse flex-end')
        }
      >
        {validateOffset() && (
          <div className="column text-red">
            Total Offset Amount exceed Invoice's Outstanding Amount
          </div>
        )}

        <div className="column align-items-end">
          <div>Total Offset Amount</div>
          <div
            className={
              'font-semibold flex-end ' + (validateOffset() ? 'text-red' : '')
            }
          >
            <FormatAmount
              value={offsetState.totalAmount}
              currencyCode={tenantInfo.currency}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffsetPopup;
