import React, { Component } from 'react';
import { DKLabel } from 'deskera-ui-library';

class BarcodeDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.rows
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.rows
    });
  }
  render() {
    return (
      <div className="column border-radius-s-2 pl-r pr-r">
        <div
          style={{ height: 20, alignItems: 'left', paddingBottom: 16 }}
          className={'parent-width'}
        >
          <DKLabel
            text={this.props.errorMessage}
            className="text-align-left white-space-nowrap text-red fw-m"
          />
        </div>
        {this.renderColumns()}
        {this.renderRows()}
      </div>
    );
  }
  renderColumns() {
    return (
      <div
        className="row"
      >
        {this.props.columns.map((column, index) => {
          return (
            <div
              className={`row data-grid-header p-s justify-content-center ${index === (this.props.columns?.length - 1) ? '' : 'data-grid-right-border'}`}
              style={{
                width: column.width || 150,
                overflow: 'hidden',
                alignItems: 'center',
                backgroundColor: '#fafafa',
                border: '1px solid #f0f0f0',
                borderLeft: (index !== 0) ? 'none' : '1px solid #f0f0f0',
                borderTopLeftRadius:
                  index === 0 ? 4 : '',
                borderTopRightRadius:
                  this.props.columns.length - 1 === index ? 4 : ''
              }}
            >
              <DKLabel
                className=" fw-m fs-m text-gray parent-width"
                text={column.title}
                style={{
                  width: column.width || 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  boxSizing: 'border-box'
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderRows() {
    return (
      <div className="column">
        {this.state.rows.map((row, rowIndex) => {
          return (
            <div
              className=""
              style={{ display: 'grid', gridAutoFlow: 'column', minHeight: 35 }}
            >
              {this.props.columns.map((column, columnIndex) => {
                return (
                  <div
                    id={'id_dktable_' + rowIndex + '_' + columnIndex}
                    className={
                      `column justify-content-center p-h-s p-v-xs data-grid-top-border data-grid-right-border data-grid-bottom-border ${columnIndex === 0 ? 'data-grid-left-border' : ''} `
                    }
                    style={{
                      width: column.width || 150
                    }}
                    onClick={
                      column.type !== 'views' &&
                        (column.editable === null ||
                          column.editable === undefined ||
                          column.editable === true)
                        ? () => {
                          this.props.onClick?.({
                            id:
                              'id_dktable_' +
                              this.randomNumber +
                              '_' +
                              rowIndex +
                              '_' +
                              columnIndex,
                            row: row,
                            rowIndex: rowIndex,
                            column: column,
                            columnIndex: columnIndex
                          });
                        }
                        : null
                    }
                  >
                    {this.getValueSection(row, rowIndex, column, columnIndex)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  getValueSection(row, rowIndex, column, columnIndex) {
    if (column.renderer) {
      return column.renderer({
        row: row,
        rowIndex: rowIndex,
        column: column,
        columnIndex: columnIndex,
        cell: row[column.key]
      });
    }

    return <DKLabel text={row[column.key]} />;
  }
}

export default BarcodeDataTable;
