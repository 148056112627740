import axios from 'axios';
import { TOAST_TYPE, showToast } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import {
  COUNTRY_CODES,
  COUNTRY_PLACE_HOLDER,
  STOCK_URL_API
} from '../Constants/Constant';
import { Store } from '../Redux/Store';
import Utility from '../Utility/Utility';
import AuthService from './Auth';
import { commonCustomEvent, COMMON_EVENTS } from './event/commonEvents';
import { InterceptorWrapper } from './InterceptorWrapper';
// const ERRORS_TO_IGNORE = ['Billing Info not added yet', 'TDS rate not found.'];
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: STOCK_URL_API + 'v1'
});
// const SERVER_ERROR_TEXT = 'Network Error';

const FAILED_REQUEST_MAX_RETRY_COUNT = 2;
const FAILED_REQUEST_RETRY_DELAY = 2000;

const retryApiCall = async (error: any) => {
  const errorConfig = { ...error.config };

  errorConfig._retryCount = errorConfig?._retryCount || 0;

  if (
    errorConfig?._retryCount >= FAILED_REQUEST_MAX_RETRY_COUNT ||
    (errorConfig.method === 'post' && !errorConfig.params?._allowRetry)
  ) {
    commonCustomEvent.dispatch(COMMON_EVENTS.ERROR_OCCURRED, { detail: {} });
    return Promise.reject(error);
  }

  errorConfig._retryCount += 1;
  errorConfig._isRetryRequest = true;
  const delayRetry = new Promise((resolve: any) =>
    setTimeout(() => resolve(), FAILED_REQUEST_RETRY_DELAY)
  );
  return delayRetry.then(() => axiosInstance.request(errorConfig));
};

const onEmptyResponse = async (error: any) => {
  if (!AuthService.didSessionExpired && !error?.config?._isRetryRequest) {
    const authData: any = await AuthService.checkIfUserLoggedIn(
      () => {},
      () => {},
      true
    );
    if (authData && authData?.accessToken) {
      // ACTIVE SESSION // DO NOTHING
    } else {
      return Promise.reject(error);
    }
  }

  return retryApiCall(error);
};

const requestInterceptSuccess = (config: any) => {
  let countryInURL = '';
  const tenantCountry =
    Store.getState().authInfo.currentTenantInfo.data.country;
  const complianceEnabled =
    Store.getState().authInfo.currentTenantInfo.data.complianceEnabled || false;
  if (
    (tenantCountry === COUNTRY_CODES.IN && complianceEnabled) ||
    tenantCountry === COUNTRY_CODES.ID ||
    tenantCountry === COUNTRY_CODES.PH ||
    tenantCountry === COUNTRY_CODES.MY ||
    tenantCountry === COUNTRY_CODES.AE ||
    tenantCountry === COUNTRY_CODES.AU ||
    tenantCountry === COUNTRY_CODES.NL ||
    tenantCountry === COUNTRY_CODES.BE ||
    tenantCountry === COUNTRY_CODES.CA ||
    tenantCountry === COUNTRY_CODES.NZ ||
    tenantCountry === COUNTRY_CODES.UK ||
    tenantCountry === COUNTRY_CODES.DE ||
    tenantCountry === COUNTRY_CODES.SA
  ) {
    countryInURL = `/${tenantCountry.toLowerCase()}`;
  }
  config.url = config.url.replace(COUNTRY_PLACE_HOLDER, countryInURL);
  return config;
};
const requestInterceptError = (error: any) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response: any) => {
  AuthService.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    let message = response.data.errorMessage;
    if (
      response.data.debugMessage &&
      response.data.debugMessage.length > response.data.errorMessage.length
    ) {
      message = response.data.debugMessage;
    }
    // showAlert('Error occurred!', message);
    if (!message.includes('exception')) {
      showToast(message, TOAST_TYPE.ERROR);
    }
    return Promise.reject(response.data);
  }
  return response.data;
};
const responseInterceptError = (error: any) => {
  // if (Utility.isEmpty(error.response) && error.message === SERVER_ERROR_TEXT) {
  //   AuthService.gotoLoginPage();
  //   return Promise.reject(error);
  // } else
  if (Utility.isEmpty(error.response)) {
    // This is a work around to detect whether failed api is IAM login
    // ideally we should give some specific status code like 403 for failed login from backend itself
    if (error?.message === 'canceled') {
      return Promise.reject(error);
    }

    return onEmptyResponse(error);
  } else {
    return Promise.reject(error.response);
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default InterceptorWrapper(axiosInstance);
