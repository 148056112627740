import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { MD5 } from 'object-hash';
const hash = MD5;

export interface OPTIONS<T> extends AxiosRequestConfig<T> {
  forceRefreshInterceptorCache?: boolean;
  ttl?: number;
}

/**
 * Keeping common cache for all interceptors
 */
const apiCache: { [key: string]: Promise<any> | undefined } = {};

export function InterceptorWrapper(axios: AxiosInstance) {
  return class ServerConnection {
    static _defaultOptions: OPTIONS<unknown> = { ttl: 1000 };
    static _once = apiCache;
    static timeoutKeys: { [key: string]: any } = {};

    static cache<T = never, R = AxiosResponse<T>>(
      func: (...args: any) => Promise<R>,
      args: {
        endpoint: string;
        data?: T;
        options?: OPTIONS<T>;
        method: string;
      }
    ) {
      try {
        const options = {
          ...ServerConnection._defaultOptions,
          ...(args.options || {})
        };

        if (args.data instanceof FormData) {
          throw new Error('Cannot cache request with formData');
        }

        const cachekey = `${hash({
          endPoint: args.endpoint,
          params: options.params,
          data: args.data,
          method: args.method
        })}_${args.endpoint}`;

        let requestPromise = ServerConnection._once[cachekey];
        if (
          requestPromise &&
          !options.signal &&
          !options.forceRefreshInterceptorCache
        ) {
          process.env.REACT_APP_ENV_NAME !== 'production' &&
            console.log(
              'InterceptorWrapper info: Found duplicate request for',
              cachekey,
              args
            );
        } else {
          requestPromise = func().finally(() => {
            try {
              clearTimeout(this.timeoutKeys[cachekey]);
              this.timeoutKeys[cachekey] = setTimeout(
                () => delete ServerConnection._once[cachekey],
                options?.ttl || ServerConnection._defaultOptions.ttl
              );
            } catch (err) {}
          });

          ServerConnection._once[cachekey] = requestPromise;
        }

        return requestPromise;
      } catch (err) {
        process.env.REACT_APP_ENV_NAME !== 'production' &&
          console.log(`InterceptorWrapper error: ${(err as Error)?.message}`);
      }

      return func();
    }

    /**
     * POST an object to the main server.
     * Requests are cached by default within 1-second timeframe!
     * This means that multiple calls to this method with the same parameters
     * will return the result of the first call within the last 1-second.
     *
     * @method
     * @endpoint {String}          URL
     * @data     {Object}          data to POST to the server
     * @options  {Object}          cache options: defaults are {ttl:1000}
     * @return  {Promise}          Promise
     */
    static async post<T = never, R = AxiosResponse<T>>(
      endpoint: string,
      data?: T,
      options?: OPTIONS<T>
    ): Promise<R> {
      return ServerConnection.cache(() => axios.post(endpoint, data, options), {
        endpoint,
        data,
        options,
        method: 'POST'
      });
    }

    /**
     * GET objects from the main server.
     * Requests are cached by default within 1-second timeframe!
     * This means that multiple calls to this method with the same parameters
     * will return the result of the first call within the last 1-second.
     *
     * @method
     * @endpoint {String}          URL
     * @options  {Object}          cache options: defaults are {ttl:1000}
     * @return  {Promise}          Promise
     */
    static async get<T = never, R = AxiosResponse<T>>(
      endpoint: string,
      options?: OPTIONS<T>
    ): Promise<R> {
      return ServerConnection.cache(() => axios.get(endpoint, options), {
        endpoint,
        options,
        method: 'GET'
      });
    }

    /**
     * PUT an object to the main server.
     * Requests are cached by default within 1-second timeframe!
     * This means that multiple calls to this method with the same parameters
     * will return the result of the first call within the last 1-second.
     *
     * @method
     * @endpoint {String}          URL
     * @data     {Object}          data to POST to the server
     * @options  {Object}          cache options: defaults are {ttl:1000}
     * @return  {Promise}          Promise
     */
    static async put<T = never, R = AxiosResponse<T>>(
      endpoint: string,
      data?: T,
      options?: OPTIONS<T>
    ): Promise<R> {
      return ServerConnection.cache(() => axios.put(endpoint, data, options), {
        endpoint,
        data,
        options,
        method: 'PUT'
      });
    }

    /**
     * PATCH an object to the main server.
     * Requests are cached by default within 1-second timeframe!
     * This means that multiple calls to this method with the same parameters
     * will return the result of the first call within the last 1-second.
     *
     * @method
     * @endpoint {String}          URL
     * @data     {Object}          data to POST to the server
     * @options  {Object}          cache options: defaults are {ttl:1000}
     * @return  {Promise}          Promise
     */
    static async patch<T = never, R = AxiosResponse<T>>(
      endpoint: string,
      data?: T,
      options?: OPTIONS<T>
    ): Promise<R> {
      return ServerConnection.cache(
        () => axios.patch(endpoint, data, options),
        { endpoint, data, options, method: 'PATCH' }
      );
    }

    /**
     * DELETE an object from the main server.
     * Requests are cached by default within 1-second timeframe!
     * This means that multiple calls to this method with the same parameters
     * will return the result of the first call within the last 1-second.
     *
     * @method
     * @endpoint {String}          URL
     * @options  {Object}          cache options: defaults are {ttl:1000}
     * @return  {Promise}          Promise
     */
    static async delete<T = never, R = AxiosResponse<T>>(
      endpoint: string,
      options?: OPTIONS<T>
    ): Promise<R> {
      return ServerConnection.cache(() => axios.delete(endpoint, options), {
        endpoint,
        options,
        method: 'DELETE'
      });
    }
  };
}
