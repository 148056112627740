import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import FixedAssetService from '../../Services/FixedAsset';
import ProductService from '../../Services/Product';
import Utility, { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const assetInitialState = {
  ...initialState,
  fixedAssets: {} as any
};

export const fetchAssetGroups = createAsyncThunk(
  'assetGroups',
  async ({ searchTerm, query }: any) => {
    try {
      const response = await FixedAssetService.GetAssetGroups(
        searchTerm,
        25,
        0
      );
      return response;
    } catch (err) {
      console.error('Error loading asset groups: ', err);
    }
  }
);

export const fetchAssets = createAsyncThunk(
  'assets',
  async ({ searchTerm, query }: any) => {
    try {
      const response = await FixedAssetService.GetFixedAssets(
        searchTerm,
        25,
        0
      );
      return response;
    } catch (err) {
      console.error('Error loading assets: ', err);
    }
  }
);

export const AssetSlice = createSlice({
  name: 'assets',
  initialState: assetInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetGroups.pending, (state) => {
        state.status = API_STATUS.LOADING;
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchAssetGroups.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
        console.log('State');
        console.log(action);
        console.log(state);
      })
      .addCase(fetchAssets.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.fixedAssets = action.payload;
        console.log('State');
        console.log(action);
        console.log(state);
      });
  }
});

// export const {
//   addColumnToProductColumnConfig,
//   addProductColumnConfig,
//   addProducts,
//   updateColumnConfig,
//   updateProductDetails
// } = AssetSlice.actions;

export const selectAssetGroups = (state: RootState) => state.assets.data;
export const selectAssetGroupLoadingStatus = (state: RootState) =>
  state.assets.gridContentLoadingStatus;
export const selectFixedAssets = (state: RootState) => state.assets.fixedAssets;

export default AssetSlice.reducer;
