import { DKDataGrid, DKIcons, DKLabel, DKLine } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../../Constants/ApiConstants';
import { useAppSelector } from '../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { selectUOMs } from '../../../../Redux/Slices/CommonDataSlice';
import { selectOperations } from '../../../../Redux/Slices/MRP/OperationSlice';
import MRPProductsService, {
  MRPProductsAPIConfig
} from '../../../../Services/MRP/MRPProducts';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  ADDITIONAL_COST_CONFT,
  BY_PRODUCTS_COL_CONF,
  COMPONENT_PRODUCTS_COL_CONF,
  OPERATION_COST_CONFT
} from '../../Constants/MRPColumnConfigs';
import { ProductSubstitutePopup } from '../ProductSubstitutePopup';
import {
  BomConfigurationModel,
  ComponentProduct
} from './BomConfigurationModel';
import { PRODUCE_PRODUCT_TYPE } from '../../../../Constants/Constant';

export default function BomDetailsTabProductReadOnly(props: any) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const uomList = useAppSelector(selectUOMs);
  const [ComponentProductColumnConfig, setComponentProductColumnConfig] =
    useState(COMPONENT_PRODUCTS_COL_CONF);
  const [ByProductColumnConfig, setByProductColumnConfig] =
    useState(BY_PRODUCTS_COL_CONF);
  const [productsData, setProductsData] = useState<any[]>([]);
  const operationsList = useAppSelector(selectOperations);
  const [operationColumnConfig, setOperationColumnConfig] =
    useState(OPERATION_COST_CONFT);
  const TITLES = {
    COMPONENT_PRODUCT: 'Component Products',
    BY_PRODUCT: 'By-Products',
    ADDITIONAL_COST: 'Additional Cost',
    OPERATIONS: 'Operations'
  };
  const [showSubstituteProductView, setShowSubstituteProductView] =
    useState(false);
  const [selectedComponentProductIndex, setSelectedComponentProductIndex] =
    useState<any>();

  const [bomMetaDetail, setBomMetaDetail] = useState<any>();

  const [
    isSubstituteBeingEditedForInUsedProduct,
    setIsSubstituteBeingEditedForInUsedProduct
  ] = useState(false);
  const [currentConfiguration, setCurrentConfiguration] = useState<any>(null);
  const [showBomVersionURL, setshowBomVersionURL] = useState<any>(undefined);

  useEffect(() => {
    updateComponentProductColumnConfig();
    updateByProductColumnConfig();
    updateOperationConfig();
    loadProducts();
  }, []);

  useEffect(() => {
    let propsBomDetails = { ...props.bomMetaDetails };
    let bomDetailsObject = {
      ...propsBomDetails,
      bomProductsConfiguration:
        propsBomDetails?.bomProductsConfiguration?.filter(
          (pro: any) =>
            pro.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
            pro.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
        ),
      byProducts: propsBomDetails?.bomProductsConfiguration?.filter(
        (pro: any) =>
          pro.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
          pro.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
      )
    };

    setBomMetaDetail(bomDetailsObject);
  }, [props.bomMetaDetails]);

  const loadProducts = () => {
    let previousQueryString = MRPProductsService.apiConfig?.Query;
    const config: MRPProductsAPIConfig = {
      ...MRPProductsService.apiConfig,
      Page: 0,
      SearchTerm: '',
      Query: 'type!NONTRACKED,active=true,hasVariants=false',
      Limit: 20,
      IsVariant: false
    };
    try {
      MRPProductsService.apiConfig = config;
      MRPProductsService.getProductsByPage(
        Utility.getCountryApiExtension()
      ).then((data: any) => {
        MRPProductsService.apiConfig.Query = previousQueryString;
        setProductsData(data.content);
      });
    } catch (err) {
      MRPProductsService.apiConfig.Query = previousQueryString;
      console.error('Error loading products: ', err);
    }
  };

  const getSelectedUOMValue = (id: any, list: any[]) => {
    let filtered = list?.find((item: any) => item.id === id);
    if (!Utility.isEmpty(filtered)) {
      return filtered.name;
    } else {
      return '';
    }
  };

  const getFilteredProducts = (data: any) => {
    let componentProducts: ComponentProduct[] =
      bomMetaDetail?.bomProductsConfiguration ?? [];
    let selectedProductIds = componentProducts?.map(
      (current: any) => current.itemId
    );

    let filtered =
      data?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.id) &&
          prodItem?.hasVariants === false
      ) || [];
    return filtered;
  };

  const filterProductDataForBy = (data: any) => {
    let byProducts: ComponentProduct[] = bomMetaDetail?.byProducts ?? [];
    let selectedProductIds = byProducts?.map((current: any) => current.itemId);
    let filtered =
      data?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.id) &&
          prodItem.hasVariants === false
      ) || [];
    return filtered;
  };

  const updateComponentProductColumnConfig = () => {
    let config: any = [...ComponentProductColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          conf.dropdownConfig.data = getFilteredProducts(productsData);
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=type!NONTRACKED,active=true,hasVariants=false`;
            return endPoint;
          };

          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return getFilteredProducts(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel className="text-left" text={obj.name} />;
          };
          break;
        case 'uom':
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(
              getSelectedUOMValue(data?.rowData?.stockUom, uomList)
            )
              ? getSelectedUOMValue(data?.rowData?.stockUom, uomList)
              : '';
          };
          break;
      }
    });
    setComponentProductColumnConfig(config);
  };

  const updateByProductColumnConfig = () => {
    let config: any = [...ByProductColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          conf.dropdownConfig.data = filterProductDataForBy(productsData);
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=type!NONTRACKED,active=true,hasVariants=false`;
            return endPoint;
          };

          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return getFilteredProducts(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel className="text-left" text={obj.name} />;
          };
          break;
        case 'uom':
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(
              getSelectedUOMValue(data?.rowData?.stockUom, uomList)
            )
              ? getSelectedUOMValue(data?.rowData?.stockUom, uomList)
              : '';
          };
          break;
      }
    });
    setByProductColumnConfig(config);
  };

  const getFilteredOperations = (response: any[]) => {
    let operations = bomMetaDetail?.bomOperationsConfiguration ?? [];
    let selectedOperation =
      operations?.reduce((prev: any, current: any) => {
        return [...prev, current.operationId];
      }, []) || [];
    let filtered =
      response?.filter(
        (operation: any) => !selectedOperation.includes(operation.id)
      ) || [];
    return filtered;
  };

  const updateOperationConfig = () => {
    let config: any = [...operationColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'operation':
          let operationsArray = operationsList?.content
            ? operationsList?.content
            : [];
          conf.dropdownConfig.data =
            operationsArray.length > 0
              ? getFilteredOperations(operationsArray)
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            return `${ApiConstants.URL.BASE}mrp/operation/search?limit=25&search=${search}&page=0&sort=createdOn&sortDir=desc`;
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return getFilteredOperations(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel className="text-left" text={obj.name} />;
          };
          break;
        case 'costPerHour':
          conf.renderer = (data: any) => {
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={Utility.getAmoutBlockForGrid(
                    tenantInfo.currency,
                    `${data?.rowData?.costPerHour || 0}`
                  )}
                />
              </div>
            );
          };
          break;
        case 'fixedRate':
          conf.renderer = (data: any) => {
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={Utility.getAmoutBlockForGrid(
                    tenantInfo.currency,
                    `${data?.rowData?.fixedRate || 0}`
                  )}
                />
              </div>
            );
          };
          break;
        case 'totalCost':
          conf.renderer = (data: any) => {
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={
                    Utility.getAmoutBlockForGrid(
                      tenantInfo.currency,
                      `${data?.rowData?.totalCost}`
                    ) || '0'
                  }
                />
              </div>
            );
          };
          break;
      }
    });
    setOperationColumnConfig(config);
  };

  const getGrid = (title: string, rows: any[], columns: any[]) => {
    return (
      <DKDataGrid
        tableName={title}
        title={`<span class="fs-m text-app-color">${title}</span>`}
        filterTableName={title}
        needColumnIcons={false}
        allowRowEdit={false}
        allowColumnEdit={false}
        allowSort={false}
        allowRowAdd={false}
        allowColumnAdd={false}
        allowColumnDelete={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowSearch={false}
        allowDataExport={false}
        columns={columns}
        rows={rows || []}
        currentPage={1}
        totalPageCount={1}
        onRowUpdate={(data: any) => {}}
        allowBulkOperation={false}
        onRowSelect={(data: any) => {}}
        needTrailingColumn={true}
        onAllRowSelect={(data: any) => {}}
        onRowClick={(data: any) => {
          // updateComponentProductColumnConfig();
          // updateOperationConfig();
        }}
      />
    );
  };

  const getComponentRow = (configuration: BomConfigurationModel) => {
    const copyConfiguration = deepClone(configuration);
    return (
      configuration?.bomProductsConfiguration?.map(
        (item: any, index: number) => {
          let rowButtonsArray: any[] = [];
          return {
            ...item,
            rowButtons: [
              ...rowButtonsArray,
              {
                title: `${
                  item.bomProductSubstitutesDetails
                    ? `(${item.bomProductSubstitutesDetails.length})`
                    : ''
                }`,
                className: `p-0 text-app-color`,
                icon: DKIcons.ic_product,
                onClick: () => {
                  setSelectedComponentProductIndex(index);
                  setShowSubstituteProductView(true);
                  setCurrentConfiguration(copyConfiguration);
                }
              }
            ]
          };
        }
      ) || []
    );
  };

  const getComponentProductsView = () => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(bomMetaDetail?.bomProductsConfiguration) ? (
          getGrid(
            TITLES.COMPONENT_PRODUCT,
            getComponentRow(bomMetaDetail),
            ComponentProductColumnConfig
          )
        ) : (
          <DKLabel text="No component product." />
        )}
      </div>
    );
  };

  const getScrapOrCoSubPrdocutsView = () => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(bomMetaDetail?.byProducts) ? (
          getGrid(
            TITLES.BY_PRODUCT,
            [...bomMetaDetail?.byProducts]?.map((element: any) => {
              return {
                ...element,
                rowButtons: []
              };
            }),
            ByProductColumnConfig
          )
        ) : (
          <DKLabel text="No scrap/coproduct." />
        )}
      </div>
    );
  };

  const getAdditionalCostRows = (configuration: BomConfigurationModel) => {
    return (
      configuration?.bomAddCostConfiguration?.map(
        (item: any, index: number) => {
          return {
            ...item,
            rowButtons: []
          };
        }
      ) || []
    );
  };

  const getAdditionalCostView = () => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(bomMetaDetail?.bomAddCostConfiguration) ? (
          getGrid(
            TITLES.ADDITIONAL_COST,
            getAdditionalCostRows(bomMetaDetail),
            ADDITIONAL_COST_CONFT
          )
        ) : (
          <DKLabel text="No additional cost." />
        )}
      </div>
    );
  };

  const getOperationsRows = (configuration: BomConfigurationModel) => {
    return (
      bomMetaDetail?.bomOperationsConfiguration?.map(
        (item: any, index: number) => {
          if (!Utility.isEmpty(operationsList?.content)) {
            let matchedOperation = operationsList?.content?.filter(
              (operationData: any) => operationData.id === item.operationId
            );
            let matchedOperationDependency = operationsList?.content?.filter(
              (operationData: any) =>
                operationData.id ===
                item?.operationDependency?.opDependencyList?.[0]
            );
            if (!Utility.isEmpty(matchedOperation)) {
              return {
                ...item,
                ...matchedOperation[0],
                totalCost:
                  matchedOperation[0]?.costPerHour *
                    Utility.calculateMinutesInHours(
                      matchedOperation[0]?.operationTime
                    ) +
                  matchedOperation[0]?.fixedRate,
                opDependency: matchedOperationDependency?.[0],
                rowButtons: []
              };
            }
          }

          return {
            ...item,
            rowButtons: []
          };
        }
      ) || []
    );
  };

  const getOperationsView = () => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(bomMetaDetail?.bomOperationsConfiguration) ? (
          getGrid(
            TITLES.OPERATIONS,
            getOperationsRows(bomMetaDetail),
            operationColumnConfig
          )
        ) : (
          <DKLabel text="No operations." />
        )}
      </div>
    );
  };

  return (
    <div className="column parent-width">
      <div className="column parent-width">
        {getComponentProductsView()}
        <DKLine className="mt-s mb-s" />
        {getScrapOrCoSubPrdocutsView()}
        <DKLine className="mt-s mb-s" />
        {getAdditionalCostView()}
        <DKLine className="mt-s mb-s" />
        {getOperationsView()}
      </div>

      {showSubstituteProductView && (
        <ProductSubstitutePopup
          readOnly={true}
          selectedProductId={
            bomMetaDetail?.bomProductsConfiguration?.[
              selectedComponentProductIndex
            ]?.productCode ?? ''
          }
          selectedProductsIds={
            bomMetaDetail?.bomProductsConfiguration?.[
              selectedComponentProductIndex
            ]?.['bomProductSubstitutesDetails'] || []
          }
          onSave={(selectedProducts: any) => {}}
          onClose={() => {
            setShowSubstituteProductView(false);
            setSelectedComponentProductIndex(undefined);
          }}
          restrictClick={false}
          currentConfiguration={
            bomMetaDetail?.bomProductsConfiguration?.[
              selectedComponentProductIndex
            ]?.bomProductSubstitutesDetails
          }
        />
      )}
    </div>
  );
}
