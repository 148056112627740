import React, { useEffect, useState, useRef } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  DKLabel,
  DKButton,
  showAlert
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import { useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  eInvoiceAuthInfo
} from '../../../Redux/Slices/AuthSlice';
import EInvoiceService from '../../../Services/EInvoice';

const CancelEInvoice = (props: any) => {
  const reasonOptions = [
    { id: '1', value: 'Duplicate' },
    { id: '2', value: 'Data Entry Mistake' },
    { id: '3', value: 'Order Cancelled' },
    { id: '4', value: 'Others' }
  ];

  const initialFormState = {
    cancelReason: {
      value: reasonOptions[0],
      hasError: false,
      isMandatory: true,
      errorMsg: ''
    },
    remark: {
      value: '',
      hasError: false,
      isMandatory: true,
      errorMsg: ''
    }
  };

  const tenantInfo = useAppSelector(activeTenantInfo);

  const [formData, setFormData] = useState(initialFormState);
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);

  const cancelEInvoice = () => {
    let payload: any = { ...eInvoiceAuthInfoData };
    if (props?.data?.isBulkCancel) {
      payload.gstin = tenantInfo.gstin;
      payload.documentCode = props?.data?.documentCode
        ? props?.data?.documentCode
        : undefined;
      payload.documentType = props?.data?.documentType
        ? props?.data?.documentType
        : undefined;
      let cancelEinvoices = props?.data && props?.data?.id;
      let cancelDetails: any[] = [];
      if (cancelEinvoices.length) {
        cancelEinvoices.forEach((invoice: any) => {
          cancelDetails.push({
            cnlRsn: formData.cancelReason.value.id,
            cnlRem: formData.remark.value,
            irn: invoice?.einvoiceInfoIndia?.irn,
            id: invoice.id
          });
        });
      }

      payload.cancelDetails = cancelDetails;

      try {
        EInvoiceService.bulkCancelEInvoice(payload).then(
          (res: any) => {
            props.onCancel();

            showAlert(
              'eInvoice portal reported as below',
              `${
                res && res?.success?.length ? res?.success?.length : `0`
              } cancelled. ${
                res && res?.failure?.length ? res?.failure?.length : `0`
              } failed`
            );
          },
          (error: any) => {
            console.error('Error cancelling Invoice Now: ', error);
            props.onCancel();
          }
        );
      } catch (error: any) {
        console.error('Error cancelling Invoice Now: ', error);
        props.onCancel();
      }
    } else {
      payload = {
        cnlRsn: formData.cancelReason.value.id,
        cnlRem: formData.remark.value,
        gstin: tenantInfo.gstin,
        irn: props.data.irn,
        id: props.data.id,
        documentCode: props?.data?.documentCode
          ? props?.data?.documentCode
          : undefined,
        documentType: props?.data?.documentType
          ? props?.data?.documentType
          : undefined
      };

      try {
        EInvoiceService.cancelEInvoice(payload).then(
          (res: any) => {
            props.onCancel();
          },
          (error: any) => {
            console.error('Error cancelling Invoice Now: ', error);
            props.onCancel();
          }
        );
      } catch (error: any) {
        console.error('Error cancelling Invoice Now: ', error);
        props.onCancel();
      }
    }
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel text={`Cancel InvoiceNow`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              cancelEInvoice();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 975,
          width: '40%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: '10px'
        }}
      >
        {getHeader()}
        <div
          id="popup-container"
          className="column parent-width parent-height p-l"
        >
          <DKLabel text="We're always adding support for more financial institutions. " />
          <DKLabel text="Let us know which one you are looking for. " />
          <DKInput
            type={INPUT_TYPE.DROPDOWN}
            title={'Reason'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={formData.cancelReason.value.value}
            canValidate={true}
            className="mt-l"
            onChange={(obj: any) => {
              let newState = { ...formData };
              newState.cancelReason.value = obj;
              setFormData({ ...newState });
            }}
            dropdownConfig={{
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              searchApiConfig: null,
              data: reasonOptions,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.value}`} />;
              }
            }}
          />
          <DKInput
            className="mt-l"
            title="Remark"
            type={INPUT_TYPE.LONG_TEXT}
            value={formData.remark.value}
            required={true}
            onChange={(value: any) => {
              let newState = { ...formData };
              newState.remark.value = value;
              setFormData({ ...newState });
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            readOnly={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelEInvoice;
