import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
export interface RevRecRuleApiConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: string;
  HAS_VARIANTS?: boolean;
  QueryParam?: string;
}

const defaultConfig: RevRecRuleApiConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'desc',
  Sort: '',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: '',
  HAS_VARIANTS: true
};

export interface TypeConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
}

class RevRecRuleService {
  static apiConfig: RevRecRuleApiConfig;
  static typeApiConfig: TypeConfig;

  static getRevRecRuleEndpoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&limit=${this.apiConfig.Limit ? this.apiConfig.Limit : 25}&page=${
      this.apiConfig.Page ? this.apiConfig.Page : 0
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.GET_RULES_DROPDOWN + queryString;

    return finalEndpoint;
  }
  static getRules() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    }
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.GET_RULES + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Rule: ', err);
      return Promise.reject(err);
    });
  }
  static getRevenueArrangment() {
    console.log(this.apiConfig);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    }
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.GET_ARRANGEMENT + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Arrangment: ', err);
      return Promise.reject(err);
    });
  }
  static getProceedRevenueArrangment(jobId:any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `&search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    }
    let finalEndpoint: string =
      ApiConstants.URL.REV_REC_RULES.PROCEED_REV_ARRANGEMENT+`?jobId=${jobId}` + queryString;

    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Arrangment: ', err);
      return Promise.reject(err);
    });
  }

  static deleteProceedRevenueArrangment(id: number) {
    const finalEndpoint: string = `revrec/ra/${id}`;
    try {
      return http.delete(finalEndpoint);
    } catch (err) {
      console.error('Error deleting Proceed Rev Arrangment: ', err);
      return Promise.reject(err);
    }
  }
  static createRule(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.REV_REC_RULES.CREATE_RULE;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to create Rule: ', err);
      return Promise.reject(err);
    });
  }

  static updateRule(payload: any, id: any) {
    const finalEndpoint: string = `/revrec/rc-rule/${id}`;
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to update Rule: ', err);
      return Promise.reject(err);
    });
  }

  static deleteRule(id: number) {
    const finalEndpoint: string = `/revrec/rc-rule/${id}`;
    try {
      return http.delete(finalEndpoint);
    } catch (err) {
      console.error('Error deleting Rule: ', err);
      return Promise.reject(err);
    }
  }
}

export default RevRecRuleService;
