import { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import {
  DKLabel,
  DKInput,
  INPUT_TYPE,
  DKSpinner,
  INPUT_VIEW_DIRECTION,
  DKIcons,
  DKButton,
  DKRadioButton,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import {
  DESIGNER_TEMPLATE,
  DESIGNER_THERMAL_TEMPLATE,
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY
} from '../../Constants/Constant';
import PrintService from '../../Services/Print';
import Utility, { getCapitalized } from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  getSelectedPrintTemplate,
  selectCustomPrintTemplates,
  setCustomPrintTemplates,
  setSelectedPrintTemplate,
  fetchTemplatePrintInfo,
  selectTemplatePrintInfo,
  setTemplatePrintInfo
} from '../../Redux/Slices/CommonDataSlice';
import printJS from 'print-js';
import ExpenseService from '../../Services/Expense';
import { populateScondaryOverlayContainer } from '../DocumentForm/NewDocumentHelper';
import PaymentService from '../../Services/Payment';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { localizedText } from '../../Services/Localization/Localization';
import CustomReportsService from '../../Services/CustomReports';
import AppManager from '../../Managers/AppManager';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';

interface PrintProps {
  document?: any;
  documentType?: any;
  parentDocumentType?: any;
  parentDocumentCode?: any;
  uid?: any;
  closePreview: (val: boolean) => void;
  emailPaylaod?: any;
}

export default function EmailPaymentRecord(props: PrintProps) {
  const dispatch = useAppDispatch();
  const customTemplateList = useAppSelector(selectCustomPrintTemplates);
  const selectedTemplate = useAppSelector(getSelectedPrintTemplate);
  const templatePrintInfo = useAppSelector(selectTemplatePrintInfo);

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [savedTemplate, setSavedTemplate] = useState<any>(null);
  const [documentPrintUUID, setDocumentPrintUUID] = useState('');
  const [tempFileBuffer, setTempFileBuffer] = useState<any>(null);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pdfBlob, setPdfBlob] = useState<any>();
  const [preview, setPreview] = useState(true);
  const [showPrintCheck, setShowPrintCheck] = useState(false);
  const [printCheckOption, setPrintCheckOption] = useState<any>({
    status: 'SUCCESS'
  });
  const [email, setEmail] = useState(props?.emailPaylaod?.to);
  const [canValidate, setCanValidate] = useState(false);
  const docDesignerOpened = useRef<boolean>(false);
  const ref = useRef<any>();

  const file = useMemo(() => ({ data: tempFileBuffer }), [tempFileBuffer]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  //   const downloadPDF = () => {
  //     if (pdfBlob !== '') {
  //       const responseBlob = new Blob([pdfBlob], {
  //         type: 'application/pdf'
  //       });
  //       const fileURL = URL.createObjectURL(responseBlob);
  //       setPreview(false);
  //       const downloadAnchor = document.createElement('a');
  //       downloadAnchor.setAttribute('download', 'Document.pdf');
  //       downloadAnchor.href = fileURL;
  //       document.body.appendChild(downloadAnchor);
  //       downloadAnchor.click();
  //       document.body.removeChild(downloadAnchor);
  //     }
  //   };

  //   const handlePrint = () => {
  //     if (pdfBlob !== '') {
  //       const responseBlob = new Blob([pdfBlob], {
  //         type: 'application/pdf'
  //       });
  //       const fileURL = URL.createObjectURL(responseBlob);
  //       setPreview(false);
  //       // printJS(fileURL);
  //       printJS({
  //         type: 'pdf',
  //         printable: fileURL,
  //         onPrintDialogClose: () => {
  //           if (props.parentDocumentType === DOC_TYPE.CHEQUE) {
  //             openPopupAfterCheckPrint();
  //           }
  //         }
  //       });
  //     }
  //   };

  const handleEmail = () => {
    if (Utility.isEmpty(email) || !Utility.isValidEmail(email)) {
      return;
    }

    let payload = props?.emailPaylaod;

    let fileName = payload.exportDocumentRequest?.documentNumber ? payload.exportDocumentRequest?.documentNumber : payload.category;

    let bodyData = {
      body: decodeURIComponent(payload.body),
      recipientEmail: email,
      subject: payload.subject,
      attachmentName: fileName
    };

    let reqData = validateAndParseData(bodyData);

    if (reqData) {
      setIsPDFLoading(true);
      CustomReportsService.sendEmail1(reqData)
        .then(
          (res) => {
            setIsPDFLoading(false);
            showToast('Mail sent successfully', TOAST_TYPE.SUCCESS);
            props.closePreview(false);
          },
          (err) => {
            setIsPDFLoading(false);
            console.error('Error sending mail: ', err);
          }
        )
        .catch((err) => {
          setIsPDFLoading(false);
          console.error('Error sending mail: ', err);
        });
    }
  };

  function validateAndParseData(formData: any) {
    const requestData = new FormData();
    requestData.append('body', formData.body);
    let emails = formData.recipientEmail.split(',');
    for (let i = 0; i < emails.length; i++) {
      if (!Utility.isValidEmail(emails[i].trim())) {
        console.error(
          'Validation failed',
          'Email address: ' + emails[i].trim() + ' is not valid.'
        );
        return false;
      }
      requestData.append('recipientEmail', emails[i].trim());
    }
    if (Utility.isEmpty(formData.subject)) {
      console.error('Validation failed', 'Subject can not be empty.');
      return;
    }
    if (Utility.isEmpty(formData.subject)) {
      console.error('Validation failed', 'Subject can not be empty.');
      return;
    }
    requestData.append('subject', formData.subject);
    requestData.append('attachmentName', formData.attachmentName);
    const responseBlob = new Blob([pdfBlob], {
      type: 'application/pdf'
    });
    requestData.append('attachment', responseBlob);
    return requestData;
  }

  const getTemplatePrintInfo = async () => {
    let payload;
    if (
      props.parentDocumentType === DOC_TYPE.CHEQUE &&
      props.parentDocumentCode?.length
    ) {
      payload = {
        category: props.parentDocumentType,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        paymentDocumentCodes: props.parentDocumentCode
      };
    } else {
      payload = {
        category:
          props.documentType === DOC_TYPE.MAKE_PAYMENT ||
          props.documentType === DOC_TYPE.RECEIVE_PAYMENT ||
          props.documentType === DOC_TYPE.PREPAYMENT ||
          props.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
            ? TEMPLATE_CATEGORY.PAYMENT
            : props.documentType,
        contactCode: props.document?.contactCode
          ? props.document?.contactCode
          : '',
        documentCode: props.document?.documentCode,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        parentDocumentType: null,
        parentDocumentCode: null
      };

      if (
        props.documentType === DOC_TYPE.PREPAYMENT ||
        props.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        payload['parentDocumentType'] = props.parentDocumentType;
        payload['parentDocumentCode'] = props.parentDocumentCode;
        payload['documentCode'] = props.uid;
      }
    }
    try {
      await dispatch(fetchTemplatePrintInfo(payload));
    } catch (err) {
      console.error('error loding template list: ', err);
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    getTemplatePrintInfo();
    setShowPreview(true);
    window.addEventListener('focus', onFocus);
    return () => {
      cleanup();
      docDesignerOpened.current = false;
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = (callback?: any) => {
    if (docDesignerOpened.current) {
      cleanup();
      getTemplatePrintInfo();
    }
  };

  useEffect(() => {
    if (templatePrintInfo) {
      dispatch(setCustomPrintTemplates(templatePrintInfo.documentTemplates));
    }
  }, [templatePrintInfo]);

  useEffect(() => {
    if (templatePrintInfo && customTemplateList) {
      setDocumentPrintUUID(templatePrintInfo.code);
      getSavedTemplate();
    }
  }, [customTemplateList]);

  const cleanup = () => {
    setTempFileBuffer(null);
    dispatch(setSelectedPrintTemplate(undefined));
    dispatch(setCustomPrintTemplates(null));
    dispatch(setTemplatePrintInfo(null));
  };

  useEffect(() => {
    async function printCustomInvoice(templateId: any, thermalPrint = false) {
      if (!documentPrintUUID) {
        return;
      }
      setIsPDFLoading(preview);
      try {
        let pageOrientation = 'PORTRAIT'
        let descriptionString = selectedTemplate?.description ?? ''
        if (descriptionString.includes('[LANDSCAPE]')) {
          pageOrientation = 'LANDSCAPE'
        }
        pageOrientation = pageOrientation.toLowerCase()
        
        const responseBody = await PrintService.printCustomTemplateInvoice(
          documentPrintUUID,
          templateId,
          preview,
          thermalPrint ? DESIGNER_THERMAL_TEMPLATE.pageFormat : '',
          pageOrientation
        );
        setPdfBlob(responseBody);
        const responseBlob = new File([responseBody], 'preview.pdf');
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = (loadEvent: any) => {
          const bufferValue = new Uint8Array(loadEvent.target.result);
          setTempFileBuffer(bufferValue);
          setIsPDFLoading(false);
        };
        fileReader.readAsArrayBuffer(responseBlob);
      } catch (err) {
        console.error('error printing document: ', err);
        setIsPDFLoading(false);
      }
      if (!preview) {
        setPreview(true);
      }
    }
    if (!Utility.isEmpty(selectedTemplate)) {
      if (selectedTemplate.id === DESIGNER_TEMPLATE.id) {
        printCustomInvoice(null);
      } else if (selectedTemplate.id === DESIGNER_THERMAL_TEMPLATE.id) {
        printCustomInvoice(null, true);
      } else {
        printCustomInvoice(selectedTemplate.id);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (!preview) {
      dispatch(setSelectedPrintTemplate(selectedTemplate));
    }
  }, [preview]);

  useEffect(() => {
    function selectCustomDocument(template: any) {
      if (
        template &&
        template.id !== DESIGNER_TEMPLATE.id &&
        template.id !== DESIGNER_THERMAL_TEMPLATE.id &&
        template.templateName !== selectedTemplate?.templateName
      ) {
        dispatch(setSelectedPrintTemplate(template));
      } else if (template.id === DESIGNER_TEMPLATE.id) {
        dispatch(setSelectedPrintTemplate(DESIGNER_TEMPLATE));
      } else if (template.id === DESIGNER_THERMAL_TEMPLATE.id) {
        dispatch(setSelectedPrintTemplate(DESIGNER_THERMAL_TEMPLATE));
      }
    }

    async function getDocumentPrintUUID(template = '') {
      if (documentPrintUUID && documentPrintUUID.length > 0 && template) {
        selectCustomDocument(template);
      } else {
        try {
          const printUUID = await PrintService.getDocumentPrintUUID(
            props.document?.documentCode,
            PrintService.getPrintDocumentCategory(props.documentType),
            props.document?.documentType
          );
          setDocumentPrintUUID(printUUID.code);
          if (template) {
            selectCustomDocument(template);
          }
        } catch (err) {
          console.error('error fetching print uuid: ', err);
        }
      }
    }
    if (!Utility.isEmpty(savedTemplate)) {
      getDocumentPrintUUID(savedTemplate);
    }

    if (
      props.documentType === MODULES_NAME.PACK_LIST ||
      props.documentType === MODULES_NAME.SHIP_LIST
    ) {
      getDocumentPrintUUID(savedTemplate);
    }
  }, [savedTemplate]);

  function getSavedTemplate() {
    try {
      const savedTemplate = templatePrintInfo['defaultTemplateMapping'];
      if (!Utility.isEmpty(savedTemplate)) {
        if (savedTemplate.templateId === 0) {
          setSavedTemplate({ ...DESIGNER_TEMPLATE });
        }
        if (savedTemplate.templateId && savedTemplate.custom) {
          const defaultTemp = customTemplateList.find(
            (obj: any) => obj.id === savedTemplate.templateId
          );
          if (defaultTemp) {
            setSavedTemplate(defaultTemp);
          }
        }
      } else {
        setSavedTemplate({ ...DESIGNER_TEMPLATE });
      }
    } catch (err) {
      console.error('error loding saved template: ', err);
      setSavedTemplate({ ...DESIGNER_TEMPLATE });
    }
  }

  // useEffect(() => {
  //   async function fetchTemplateList() {
  //     try {
  //       await dispatch(fetchCustomPrintTemplates(props.documentType));
  //     } catch (err) {
  //       console.error('error loding template list: ', err);
  //     }
  //   }
  //   fetchTemplateList();
  // }, [props.document, props.documentType]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const dispatchSelectedTemplate = (template: any) => {
    dispatch(setSelectedPrintTemplate(template));
  };

  const getUpdatedOptions = () => {
    if (customTemplateList) {
      const designerTemplateIndex = customTemplateList.findIndex(
        (x: any) => x.id === 0
      );
      if (designerTemplateIndex !== -1) {
        return [...customTemplateList];
      }
      return [
        DESIGNER_TEMPLATE,
        DESIGNER_THERMAL_TEMPLATE,
        ...customTemplateList
      ];
    }
    return [];
  };

  const openPopupAfterCheckPrint = () => {
    if (
      props.parentDocumentType === DOC_TYPE.CHEQUE &&
      props.parentDocumentCode?.length
    ) {
      setShowPrintCheck(true);
    }
  };
  const optionUpdated = (value: any) => {
    setPrintCheckOption({ status: value });
  };

  const setPrintCheckStatus = () => {
    let payload: any = [];
    let status: any = '';
    if (printCheckOption.status === 'SUCCESS') {
      status = 'PRINT_SUCCESS';
    } else {
      status = 'PRINT_FAILURE';
    }
    props.parentDocumentCode.forEach((ele: any) => {
      let obj: any = {};
      obj['documentCode'] = ele;
      obj['printStatus'] = status;
      payload.push(obj);
    });
    ExpenseService.setPrintCheckStatus(payload)
      .then((res: any) => {
        setShowPrintCheck(false);
        if (props.closePreview) {
          props.closePreview(false);
        }
      })
      .catch((err) => {
        setShowPrintCheck(false);
        console.log(err, 'error while getting the checks');
      });
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-s p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Send email" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              if (props.closePreview) {
                props.closePreview(false);
              } else {
                populateScondaryOverlayContainer(<></>);
              }
            }}
          />
          {/* <DKButton
            icon={
              isPDFLoading ? DKIcons.ic_download : DKIcons.white.ic_download
            }
            title={'Download'}
            className={`ml-r ${
              isPDFLoading ? 'bg-white border-m' : 'bg-button text-white'
            }`}
            disabled={isPDFLoading}
            onClick={() => {
              downloadPDF();
            }}
          /> */}
          {/* <DKButton
            icon={isPDFLoading ? DKIcons.ic_printer : DKIcons.white.ic_printer}
            title={'Print'}
            className={`ml-r ${
              isPDFLoading ? 'bg-white border-m' : 'bg-button text-white'
            }`}
            disabled={isPDFLoading}
            onClick={() => {
              handlePrint();
            }}
          /> */}
          <DKButton
            icon={isPDFLoading ? DKIcons.ic_email : DKIcons.white.ic_email}
            title={'Email'}
            className={`ml-r ${
              isPDFLoading ? 'bg-white border-m' : 'bg-button text-white'
            }`}
            disabled={isPDFLoading}
            onClick={() => {
              setCanValidate(true);
              handleEmail();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {showPreview && (
        <div className="transparent-background">
          <div
            className="popup-window overflow-hidden"
            style={{
              maxHeight: '95%',
              height: '95%',
              width: '800px',
              maxWidth: '90%',
              padding: 0,
              paddingBottom: 0
            }}
          >
            <div className="column parent-width parent-height overflow-hidden">
              {getHeader()}
              <div
                className="column parent-width"
                style={{ height: 'calc(100% - 53px)' }}
              >
                <div
                  className="row row-responsive p-h-l mt-r"
                  style={{
                    height: 45,
                    paddingTop: 2,
                    marginBottom: 10,
                    gap: 10
                  }}
                >
                  {customTemplateList && (
                    <DKInput
                      className={''}
                      readOnly={
                        Utility.isEmpty(selectedTemplate) || isPDFLoading
                      }
                      value={selectedTemplate}
                      formatter={(obj: any) => {
                        return obj.templateName;
                      }}
                      title=" "
                      type={INPUT_TYPE.DROPDOWN}
                      required={false}
                      canValidate={false}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      onChange={(template: any) => {
                        if (template.id !== selectedTemplate.id) {
                          dispatchSelectedTemplate(template);
                        }
                      }}
                      dropdownConfig={{
                        className: '',
                        style: {},
                        allowSearch: false,
                        searchableKey: 'templateName',
                        canEdit: false,
                        canDelete: false,
                        data: getUpdatedOptions(),
                        renderer: (index: number, obj: any) => {
                          return <DKLabel text={`${obj.templateName}`} />;
                        },
                        button: {
                          title: 'Edit Template',
                          icon: DKIcons.white.ic_edit,
                          className: 'bg-button text-white',
                          onClick: () => {
                            if (documentPrintUUID) {
                              const printExternalAppUrl = [
                                process.env.REACT_APP_DOC_DESIGNER_URL,
                                documentPrintUUID
                              ].join('/');
                              docDesignerOpened.current = true;
                              window.open(printExternalAppUrl, '_blank');
                            }
                          }
                        }
                      }}
                    />
                  )}
                  <DKInput
                    title="Email"
                    required={true}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    value={email}
                    canValidate={canValidate}
                    onChange={(value: any) => {
                      setEmail(value);
                    }}
                    validator={(value: string) => {
                      return value && Utility.isValidEmail(value);
                    }}
                    errorMessage={'Please enter a valid email address'}
                  />
                </div>
                <div
                  className={`row parent-height mt-2 overflow-x-hidden p-h-l ${
                    !isDesktop || isPDFLoading
                      ? 'align-items-center'
                      : 'align-items-start'
                  }`}
                >
                  {!isPDFLoading && tempFileBuffer && (
                    <SizeMe>
                      {({ size }) => (
                        <Document
                          inputRef={ref}
                          file={file}
                          onLoadSuccess={onDocumentLoadSuccess}
                          loading={''}
                          className="parent-width"
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page
                              width={size.width ? size.width : 1}
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              loading={''}
                            />
                          ))}
                        </Document>
                      )}
                    </SizeMe>
                  )}
                  {isPDFLoading && (
                    <div className="flex parent-width parent-height items-center justify-center font-semibold">
                      <DKSpinner
                        iconClassName="ic-s-2"
                        className="ml-m"
                        title="loading document..."
                      />
                    </div>
                  )}
                  {showPrintCheck && (
                    <div className="transparent-background">
                      <div
                        className="popup-window overflow-hidden"
                        style={{
                          maxWidth: 520,
                          width: '80%',
                          maxHeight: '95%',
                          height: 150,
                          padding: 0,
                          position: 'fixed'
                        }}
                      >
                        <div className="row justify-content-between p-h-s p-v-s bg-gray1">
                          <div className="row width-auto">
                            <DKLabel
                              text="Did your checks print OK?"
                              className="fw-m fs-l"
                            />
                          </div>
                          <DKButton
                            title={'Done'}
                            className={'ml-r bg-button text-white'}
                            onClick={() => {
                              setPrintCheckStatus();
                            }}
                          />
                        </div>
                        <div className="column mt-5 ml-3">
                          <DKRadioButton
                            title="Yes,they all printed correctly"
                            isSelected={printCheckOption?.status === 'SUCCESS'}
                            onClick={() => optionUpdated('SUCCESS')}
                            className="mr-l fs-m"
                            color={'bg-app'}
                          />
                          <DKRadioButton
                            title={`No, keep all ${localizedText(
                              'cheque'
                            )}s in the Print ${getCapitalized(
                              localizedText('cheque')
                            )}s list`}
                            isSelected={printCheckOption?.status === 'FAILED'}
                            onClick={() => optionUpdated('FAILED')}
                            className="mr-s mt-2 fs-m"
                            color={'bg-app'}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
