import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

/** @deprecated  */
export const fetchProductInventory = createAsyncThunk(
  'warehouseProduct',
  async () => {
    const response = await ProductService.fetchWarehouseProducts();
    return response;
  }
);

export const fetchProductInventoryByID = createAsyncThunk(
  'warehouseProductByID',
  async (productID: any) => {
    const response = await ProductService.fetchWarehouseProductsByID(productID);
    return response;
  }
);

export const fetchProductWarehouseCodes = createAsyncThunk(
  'productWarehouseCode',
  async (productID: any) => {
    const response = await ProductService.fetchProductAdvancedTrackingWarehouse(
      productID,
      false,
      false
    );
    return response;
  }
);

export const WarehouseProductsSlice = createSlice({
  name: 'warehouseProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductInventory.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductInventory.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload['warehouses'];
      })
      .addCase(fetchProductInventoryByID.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductInventoryByID.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload['warehouses'];
      })
      .addCase(fetchProductWarehouseCodes.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductWarehouseCodes.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload['warehouses'];
      });
  }
});

/** @deprecated  */
export const selectWarehouseProducts = (state: RootState) =>
  state.warehouseProducts.data;

export const selectWarehouseProductByID = (state: RootState) =>
  state.warehouseProductsByID.data;
export const selectWarehouseProductCodes = (state: RootState) =>
  state.productWarehouseCodes.data;

export const selectWarehouseProductsLoadingStatus = (state: RootState) =>
  state.warehouseProducts.status;

export const selectWarehouseProductsColumnConfig = (state: RootState) =>
  state.warehouseProducts.columnConfig;

export const selectWarehouseProductsColumnConfigTableId = (state: RootState) =>
  state.warehouseProducts.configTableId;

export default WarehouseProductsSlice.reducer;
