import ApiConstants from '../Constants/ApiConstants';
import { IWhatsAppRecord } from '../Models/WhatsAppAPI';
import http from './Interceptor';

class WhatsAppAPIService {
  static getConnections() {
    return http
      .get(`${ApiConstants.URL.WHATSAPP.GET_CONNECTIONS}`)
      .catch((err: any) => {
        console.error('Error fetching WhatsApp API connections: ', err);
        return Promise.reject(err);
      });
  }

  static async addConnection(
    payload: IWhatsAppRecord
  ): Promise<IWhatsAppRecord> {
    return await http.post(ApiConstants.URL.WHATSAPP.LINK_ACCOUNT, payload);
  }

  static async deleteConnection(id: number): Promise<IWhatsAppRecord> {
    return http.delete(ApiConstants.URL.WHATSAPP.UNLINK_ACCOUNT(id));
  }

  static getTemplates(id: number) {
    return http
      .get(`${ApiConstants.URL.WHATSAPP.GET_TEMPLATES(id)}`)
      .catch((err: any) => {
        console.error('Error fetching WhatsApp templates: ', err);
        return Promise.reject(err);
      });
  }
}

export default WhatsAppAPIService;
