import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKInput,
  DKTooltipWrapper,
  DKSpinner,
  showAlert,
  DKListPicker2,
  INPUT_TYPE
} from 'deskera-ui-library';
import { DynamicPopupWrapper } from '../PopupWrapper';
import { useEffect, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../Constants/Constant';

export default function EditComponentDetailsPopup(props: any) {
  const [data, setData] = useState<any>(props.selectedRecord);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={`Edit ${props.selectedRecord.productName}`}
            className="fw-m fs-l"
          />
        </div>

        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Done'}
            className="bg-button text-white"
            onClick={() => {
              props.onDone(data);
            }}
          />
        </div>
      </div>
    );
  };

  const onChangeQuantity = (quantity: any)  => {
    let x = { ...data };
    x.quantityRequired = quantity ? quantity : 0;
    setData(x);
  }
  const onChangePrice = (price: any)  => {
    let x = { ...data };
    x.salesPrice = price ? price : 0;
    setData(x);
  }

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: '25%',
            maxWidth: '25%',
            maxHeight: '50%',
            height: 300,
            padding: 0,
            paddingBottom: 10,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width parent-height mt-r overflow-y-auto dk-input-holder p-h-xxl">
            <div className="row">
              <DKInput
                title={'Quantity'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={data?.quantityRequired || ''}
                type={INPUT_TYPE.NUMBER}
                onChange={(value: string) => {
                    onChangeQuantity(value);
                }}
                required={true}
                canValidate={false}
              />
            </div>
            <div className="row mt-m">
              <DKInput
                title={'Unit Price'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={data?.salesPrice || ''}
                type={INPUT_TYPE.NUMBER}
                onChange={(value: string) => {
                    onChangePrice(value);
                }}
                required={true}
                canValidate={false}
              />
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
