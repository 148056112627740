import {
  DKButton,
  DKDataGrid,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  Toggle,
  DKSpinner
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  fetchCurrentTenantInfo
} from '../../../Redux/Slices/AuthSlice';
import TenantService from '../../../Services/Tenant';
import { deepClone } from '../../../Utility/Utility';

const COLUMN_CONFIGS = [
  {
    id: 'name',
    key: 'name',
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'isEnabled',
    key: 'isEnabled',
    name: 'Is Enabled',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'center'
  },
  {
    id: 'label',
    key: 'label',
    name: 'Label',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'center'
  }
];

const RowRackBinGridPopup = (props: any) => {
  //states
  const [columns, setColumns] = useState(COLUMN_CONFIGS);
  const [rowItems, setRowItems] = useState([
    {
      id: 'row',
      name: 'Row',
      isEnabled: false,
      label: 'Row'
    },
    {
      id: 'rack',
      name: 'Rack',
      isEnabled: false,
      label: 'Rack'
    },
    {
      id: 'bin',
      name: 'Bin',
      isEnabled: false,
      label: 'Bin'
    }
  ]);
  const [saveApiLoading, setSaveApiLoading] = useState(false);

  //selectors
  const dispatch = useAppDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);

  //effects
  useEffect(() => {
    updateConfig();
  }, [rowItems]);

  useEffect(() => {
    let updatedRowItems = [...rowItems];
    updatedRowItems = rowItems?.map((item: any) => {
      let found = tenantDetails?.additionalSettings?.ROW_RACK_BIN?.find(
        (i: any) => {
          return i.name === item?.id?.toUpperCase();
        }
      );

      if (found) {
        item = {
          ...item,
          isEnabled: found?.enable,
          label: found?.label
        };
      }
      return item;
    });
    setRowItems([...updatedRowItems]);
  }, [tenantDetails]);

  const updateConfig = () => {
    let updatedColumns: any = [...columns];

    updatedColumns?.forEach((col: any) => {
      if (col.key === 'isEnabled') {
        col.renderer = (obj: any) => {
          return (
            <div className="row parent-height justify-content-center">
              <Toggle
                className={`box-content`}
                onChange={(checked: any) => {
                  const updatedRowItems = deepClone(rowItems)?.map((item) => {
                    if (item.id === obj?.rowData?.id && !obj?.rowData?.isEnabled) {
                      return {
                        ...item,
                        isEnabled: !obj?.rowData?.isEnabled
                      };
                    }
                    return item;
                  });
                  setRowItems(updatedRowItems);
                }}
                isOn={obj?.rowData?.isEnabled}
              />
            </div>
          );
        };
      }

      if (col.key === 'label') {
        col.renderer = (obj: any) => {
          return (
            <div
              className="row parent-height justify-content-center border-box"
              style={{ width: 167 }}
            >
              <DKInput
                title=""
                placeholder={obj?.rowData?.name ?? ''}
                required={true}
                readOnly={!obj?.rowData?.isEnabled}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                value={obj?.rowData?.label ?? ''}
                className=""
                onChange={(text: any) => {
                  const updatedRowItems = deepClone(rowItems)?.map((item) => {
                    if (item.id === obj?.rowData?.id) {
                      return { ...item, label: text };
                    }
                    return item;
                  });
                  setRowItems(updatedRowItems);
                }}
              />
            </div>
          );
        };
      }
    });

    setColumns([...updatedColumns]);
  };

  const getPayloadName = (id: any) => {
    switch (id) {
      case 'row':
        return 'ROW';
      case 'rack':
        return 'RACK';
      case 'bin':
        return 'BIN';

      default:
        return '';
    }
  };

  const onSave = () => {
    const updatedRowItems = rowItems?.map((item: any) => {
      return {
        label: item?.label ?? '',
        enable: item?.isEnabled,
        name: getPayloadName(item?.id)
      };
    });

    let payload = {
      additionalSettings: {
        ROW_RACK_BIN: updatedRowItems
      }
    };

    setSaveApiLoading(true);
    TenantService.updateOrgSetting(payload)
      .then(async (res: any) => {
        setSaveApiLoading(false);
        await dispatch(fetchCurrentTenantInfo());
        props?.onUpdate();
      })
      .catch((err: any) => {
        setSaveApiLoading(false);
        console.log(err);
      });
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`Configure Row Rack Bin`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <div
            className={`row border-radius-m mr-r ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let updatedRowItems = deepClone(rowItems);
    if (columnKey === 'label') {
      updatedRowItems = updatedRowItems?.map((item) => {
        if (item.id === rowData?.id) {
          return { ...item, label: rowData?.label };
        }
        return item;
      });
    }
    setRowItems(updatedRowItems);
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...deepClone(rowItems)]?.map((lineItem: any) => {
          return {
            ...lineItem
          };
        })}
        columns={[...columns]}
        onRowUpdate={onRowUpdate}
        onRowClick={() => {}}
      />
    );
  };

  const getRRBInfo = () => {
    return (
      <div className="column parent-width gap-4" style={{ marginTop: '-15px' }}>
        <DKLabel
          className="text-gray"
          text="Row, rack, and bin management can greatly improve the organization and efficiency of your warehouse operations. By enabling this feature and customizing it with your business-specific names, you can ensure that your storage space is used to its fullest potential."
        />
        <DKLabel className="fw-b" text="Once enabled cannot be reversed." />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 520,
          width: '90%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-l p-v-m pb-l gap-2">
          {getDataGrid()}
          {getRRBInfo()}
        </div>
      </div>
    </div>
  );
};

export default RowRackBinGridPopup;
