import { useState } from 'react';
import {
  DKIcon,
  DKLabel,
  DKIcons,
  DKListPicker2,
  DKButton
} from 'deskera-ui-library';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import {
  DOCUMENT_MODE,
  SWITCH_CURRENCY_OPTIONS
} from '../../Constants/Constant';
import NumberFormatService from '../../Services/NumberFormat';
import { selectCurrencyListWithExchangeRate } from '../../Redux/Slices/CommonDataSlice';

export interface SummayProps {
  documentMode: DOCUMENT_MODE;
  onCurrencyAndExchangeRateChange?: (
    currency: string,
    exchangeRate: number
  ) => void;
  scheduleData: any;
}

export default function AmortizationScheduleSummaryView(props: any) {
  // const taxSystem = getTenantTaxSystem();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);

  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );

  const scheduleData = props.scheduleData;

  const getTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    currencyCode?: string
  ) => {
    const amountText = `${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
      Math.abs(amount)
    )}${amount < 0 ? ')' : ''}`;

    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start"
        style={{ width: '100%' }}
      >
        <div
          className="row width-auto"
          style={{
            minWidth: 100
          }}
        >
          {icon && (
            <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
          )}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
          {/* {title === t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`) &&
              tenantInfo.multicurrencyEnabled &&
              getCurrencySelector()} */}
        </div>
        <DKLabel
          text={`${Utility.getCurrencySymbolFromCode(
            scheduleData.bookSpecific?.value?.value ===
              SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? scheduleData?.primaryCurrency
              : currencyCode || tenantInfo.currency
          )} ${amountText}`}
          style={{
            wordBreak: 'break-all'
          }}
          className={`ml-r text-wrap ${amountClassName || ''}`}
        />
      </div>
    );
  };

  const getCurrencySelector = () => {
    return (
      <div className="position-relative ml-s">
        <DKButton
          title={`${tenantInfo?.currency}`}
          className="text-black bg-gray-200 border-radius-l"
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 2,
            paddingBottom: 2
          }}
          onClick={() => {
            if (tenantInfo.multicurrencyEnabled) {
              setShowMultiCurrencyList((prevValue) => !prevValue);
            }
          }}
          icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
          isReverse
        />
        {tenantInfo.multicurrencyEnabled && showMultiCurrencyList && (
          <DKListPicker2
            title="Currencies"
            data={activeMultiCurrencyList.filter((item: any) => {
              return item.currencyStatus === 'ACTIVE';
            })}
            style={{
              width: 280
            }}
            allowSearch={true}
            searchableKey="currencyName"
            className="position-absolute z-index-3 right-0 bottom-7 shadow-m"
            onSelect={(index: number, currency: any) => {
              setShowMultiCurrencyList(false);
              if (props.onCurrencyAndExchangeRateChange) {
                props.onCurrencyAndExchangeRateChange(
                  currency.currencyCode,
                  currency.currencyExchangeRate
                );
              }
            }}
            onClose={() => {
              setTimeout(() => {
                setShowMultiCurrencyList(false);
              }, 100);
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width justify-content-between">
                  <div>{obj.currencyName}</div>
                  <div className="ml-s">{`(${obj.currencyCode})`}</div>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="column">
      {getTitleAndAmount(
        'Residual',
        scheduleData.bookSpecific?.value?.value ===
          SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
          ? 0
          : scheduleData.residual,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        'Initial Amount',
        scheduleData.bookSpecific?.value?.value ===
          SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
          ? scheduleData?.primaryInitialAmount
          : scheduleData.initialAmount,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        'Remaining Deferred Balance',
        scheduleData.bookSpecific?.value?.value ===
          SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
          ? scheduleData?.primaryRemainingDeferredBalance
          : scheduleData?.remainingDeferredBalanceInBase
          ? scheduleData.remainingDeferredBalanceInBase
          : scheduleData.remainingDeferredBalance,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        'Total Amortized',
        scheduleData.bookSpecific?.value?.value ===
          SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
          ? scheduleData?.primaryTotalAmortize
          : scheduleData?.totalAmortizeInBase
          ? scheduleData.totalAmortizeInBase
          : scheduleData.totalAmortize,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        'Total',
        scheduleData.bookSpecific?.value?.value ===
          SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
          ? scheduleData?.primaryTotalAmount
          : scheduleData?.totalAmountInBase
          ? scheduleData.totalAmountInBase
          : scheduleData.totalAmount,
        null,
        'fw-m'
      )}
    </div>
  );
}
