import { INPUT_TYPE } from 'deskera-ui-library';
import Utility from '../../Utility/Utility';
const codeAndName = { code: '', name: '' };
export const INITIAL_FILTERS = {
  warehouse: { ...codeAndName },
  row: { ...codeAndName },
  rack: { ...codeAndName },
  bin: { ...codeAndName }
};
export const SERIAL_TRACK_COLUMNS = [
  {
    id: 'serialBatchNumber',
    key: 'serialBatchNumber',
    name: 'Serial Number',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true
  },
  {
    id: 'warehouseCode',
    key: 'warehouseCode',
    name: 'Warehouse',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true
  },
  {
    id: 'rowCode',
    key: 'rowCode',
    name: 'Row',
    type: INPUT_TYPE.TEXT,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: false
  },
  {
    id: 'rackCode',
    key: 'rackCode',
    name: 'Rack',
    type: INPUT_TYPE.TEXT,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: false
  },
  {
    id: 'binCode',
    key: 'binCode',
    name: 'Bin',
    type: INPUT_TYPE.TEXT,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: false
  }
];

export const getRowRackBinData = (data: any) => {
  const rrbData: any = {};
  if (Utility.isNotEmpty(data)) {
    for (let item of data) {
      if (!!item?.enable) {
        rrbData[item.name] = item;
      }
    }
  }
  return rrbData;
};
