import React, { useState, useEffect } from 'react';
import { DKListPicker2 } from 'deskera-ui-library';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  fetchTCSRates,
  selectTCSRates,
  selectActiveTCSRates
} from '../../Redux/Slices/TCSSetupSlice';
import TCSService, { TCSSetupAPIConfig } from '../../Services/TCSSetup';
import Utility from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
export interface ITCSSelection {
  className?: string;
  style?: any;
  rateId?: any;
  onSelect?: (index: number, value: any) => void;
  onNewAdd?: () => void;
  onClose?: () => void;
}
export default function TCSSelection(props: ITCSSelection) {
  const dispatch = useAppDispatch();
  const tcsRatesData = useAppSelector(selectActiveTCSRates);
  const [data, setData] = useState<any>(tcsRatesData || []);
  const tenantInfo = useAppSelector(activeTenantInfo);

  const loadTCSRates = async (config: TCSSetupAPIConfig) => {
    try {
      TCSService.apiConfig = config;
      dispatch(fetchTCSRates(tenantInfo?.id));
    } catch (err) {
      console.error('Error loading Accounts: ', err);
    }
  };

  useEffect(() => {
    if (Utility.isEmpty(data)) {
      loadTCSRates({});
    }
  }, []);
  useEffect(() => {
    setData(tcsRatesData);
  }, [tcsRatesData]);

  return (
    <DKListPicker2
      data={tcsRatesData}
      className={`mr-l shadow-m ${props?.className}`}
      onSelect={(index: any, value: any) => {
        props.onSelect?.(index, value);
      }}
      style={{ ...props?.style }}
      onClose={() => props.onClose?.()}
      renderer={(index: number, data: any) => data.taxName}
      button={{
        title: '+ Add TCS',
        className: 'bg-button text-white',
        onClick: () => props.onNewAdd?.()
      }}
    />
  );
}
