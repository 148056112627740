import { watermarkPosition, watermarkPrintType, watermarkType } from "../Helper/types";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager"


export default class WatermarkUtility {

    static getDefaultWatermarkList() {
        return [
            {
                index: 0,
                type: watermarkType.original,
                label: 'original',
                value: 'original_for_recipient',
                isSelected: false,
            },
            {
                index: 1,
                type: watermarkType.duplicate_goods,
                label: 'duplicate',
                value: 'duplicate_for_transporter',
                isSelected: false,
            },
            // {
            //     index: 2,
            //     type: watermarkType.duplicate_service,
            //     label: 'duplicate_supply_of_service',
            //     value: 'duplicate_for_supplier',
            //     isSelected: false,
            // },
            {
                index: 2,
                type: watermarkType.triplicate,
                label: 'triplicate',
                value: 'triplicate_for_supplier',
                isSelected: false,
            },
            {
                index: 3,
                type: watermarkType.quadruplicate,
                label: 'quadruplicate',
                value: 'extra_copy',
                isSelected: false,
            },
            {
                index: 4,
                type: watermarkType.custom,
                label: 'custom',
                value: 'extra_copy',
                isSelected: false,
            },
        ]
    }

    static getDefaultWatermarkValueList(type) {
        var value = ''
        switch (type) {
            case watermarkType.original:
                value = 'original_for_recipient'
                break;
            case watermarkType.duplicate_goods:
                value = 'duplicate_for_transporter'
                break;
            case watermarkType.duplicate_service:
                value = 'duplicate_for_transporter'
                break;
            case watermarkType.triplicate:
                value = 'triplicate_for_supplier'
                break;
            case watermarkType.quadruplicate:
                value = 'extra_copy'
                break;
            case watermarkType.custom:
                value = 'extra_copy'
                break;
            default:
                break;
        }

        return value
    }

    static getDefaultPositionList() {
        return [
            {
                index: 0,
                type: watermarkPosition.topLeft,
                label: 'left',
                portion: watermarkPosition.top,
            },
            {
                index: 1,
                type: watermarkPosition.topRight,
                label: 'right',
                portion: watermarkPosition.top,
            },
            {
                index: 2,
                type: watermarkPosition.bottomLeft,
                label: 'left',
                portion: watermarkPosition.bottom,
            },
            {
                index: 3,
                type: watermarkPosition.bottomMiddle,
                label: 'middle',
                portion: watermarkPosition.bottom,
            },
            {
                index: 4,
                type: watermarkPosition.bottomRight,
                label: 'right',
                portion: watermarkPosition.bottom,
            },
        ]
    }


    static getWatermarkConfiguration() {
        if (TemplateSettingsManager.defaultTemplateSettings !== undefined) {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo !== undefined) {
                if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark !== undefined) {
                    return TemplateSettingsManager.defaultTemplateSettings.documentInfo.watermark
                }
            }
        }

        return undefined
    }


    static getWatermarkValue(wmark) {
        if (wmark) {
          return this.getWatermarkValueByType(wmark.toLowerCase())
        } else {
          var printInfo = TemplateSettingsManager.getPrintInfo()
          if (printInfo !== undefined) {
              if (printInfo.wmark !== undefined && printInfo.wmark !== null) {
                  if(printInfo.wmark === watermarkPrintType.all) {
                      return 'WATERMARK_PLACEMENT'
                  }
                  return this.getWatermarkValueByType(printInfo.wmark.toLowerCase())
              }
          }
          return 'sample_watermark'
        }
    }

    static getWatermarkValueByType(type) {
        var newType = undefined
        switch (type) {
            case watermarkPrintType.original:
                newType = watermarkType.original
                break;
            case watermarkPrintType.duplicate:
                newType = watermarkType.duplicate_goods
                break;
            case watermarkPrintType.triplicate:
                newType = watermarkType.triplicate
                break;
            case watermarkPrintType.quadruplicate:
                newType = watermarkType.quadruplicate
                break;
            case watermarkPrintType.custom:
                newType = watermarkType.custom
                break;
            default:
                break;
        }

        if(newType !== undefined) {
            var watermarkConfiguration = this.getWatermarkConfiguration()

            if(watermarkConfiguration !== undefined) {
                if (watermarkConfiguration.elements.length > 0) {
                    var item = watermarkConfiguration.elements.find(x => x.type === newType)

                    if(item.type !== undefined) {
                        return item.value
                    }
                }
            }
        }

        return ''
    }

    static getIsAllWatermark() {
        var printInfo = TemplateSettingsManager.getPrintInfo()
        if (printInfo !== undefined) {
            if (printInfo.wmark !== undefined && printInfo.wmark !== null) {
                if (printInfo.wmark === watermarkPrintType.all) {
                    return true
                }
            }
        }

        return false
    }

    static getIsContainsWatermark() {
        var printInfo = TemplateSettingsManager.getPrintInfo()
        if (printInfo !== undefined) {
            if (printInfo.wmark !== undefined && printInfo.wmark !== null) {
                if (printInfo.wmark !== watermarkPrintType.none) {
                    return true
                }
            }
        }

        return false
    }
}