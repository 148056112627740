import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import PriceListService from '../../Services/PriceList';
import ProductService from '../../Services/Product';

export interface PriceListState {
  data: any;
  products: [];
  columnConfig: IColumn[];
  status: API_STATUS;
}

const initialState: PriceListState = {
  data: {} as any,
  products: [],
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchPriceList = createAsyncThunk('priceList', async () => {
  const response = await PriceListService.getPriceList();
  return response;
});
export const fetchProductList = createAsyncThunk('productList', async () => {
  const response = await PriceListService.getProductList();
  return response;
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const PriceListSlice = createSlice({
  name: 'priceList',
  initialState,
  reducers: {
    addPriceColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        if (config.columnCode === 'currency') {
          config.allowColumnSort = false;
        }
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    },
    addColumnToPriceColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriceList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPriceList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let CoaList: any = action.payload;
        state.data = CoaList;
      })
      .addCase(fetchProductList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let ProductList: any = action.payload;
        state.products = ProductList.content;
      });
  }
});

export const {
  addPriceColumnConfig: addPriceColumnConfig,
  addColumnToPriceColumnConfig: addColumnToPriceColumnConfig
} = PriceListSlice.actions;

export const selectProductsList = (state: RootState) =>
  state.priceList.products;
export const selectPriceList = (state: RootState) => state.priceList.data;
export const selectPriceColumnConfig = (state: RootState) =>
  state.priceList.columnConfig;

export default PriceListSlice.reducer;
