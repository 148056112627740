import { TableColumnPopupType } from "../../Constants/Constants";
import { getSortedHeader } from "../../DKUILibrary/dktable/TableUtility";
import { getLocalisedText } from "../../Translate/LanguageManager";

export default class MachineScheduleMaterialItem {
  lineNumber: number;
  code: number;
  description: string;
  uom: string;
  unitPrice: string;
  amount: string;
  remark: string;
  quantity: number;

  constructor(lineItem: any, data: any, currency: string) {
    this.lineNumber = lineItem.lineNumber;
    this.code = lineItem.code;
    this.uom = lineItem.uom;
    this.unitPrice = lineItem.unitPrice;
    this.amount = lineItem.amount;
    this.remark = lineItem.remark;
    this.description = lineItem.description;
    this.quantity = lineItem.quantity;
  }

  getValueByType(element: any) {
    const type = element.type;
    switch (type) {
      case TableColumnPopupType.code:
        return this.code;
      case TableColumnPopupType.uom:
        return getLocalisedText(this.uom);
      case TableColumnPopupType.unitPrice:
        return this.unitPrice;
      case TableColumnPopupType.amount:
        return this.amount;
      case TableColumnPopupType.remark:
        return getLocalisedText(this.remark);
      case TableColumnPopupType.lineNumber:
        return this.lineNumber;
      case TableColumnPopupType.quantity:
        return this.quantity;
      case TableColumnPopupType.description:
        return getLocalisedText(this.description);
      default:
        return "";
    }
  }

  getDefaultList() {
    return [this.lineNumber, getLocalisedText(this.description), this.uom, this.unitPrice, this.amount, getLocalisedText(this.remark)];
  }
  toString() {
    var sortedHeader = getSortedHeader();
    if (sortedHeader.length > 0) {
      var sortedValue: string[] = [];
      sortedHeader.forEach((element) => {
        sortedValue.push(this.getValueByType(element));
      });
      return sortedValue;
    }
    return this.getDefaultList();
  }
}
