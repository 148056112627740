import { DateFormat } from "../../Constants/Constants"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../../Translate/LanguageManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"

export default class PackListParser {
    static parseData(dataFromAPI, templateDataToReturn, payloadIndex) {

        //custom field
        templateDataToReturn.customFields = this.getPayloadData(dataFromAPI, payloadIndex)?.customFields
        CustomFieldManager.setCustomFields(this.getPayloadData(dataFromAPI, payloadIndex)?.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        var name = ''
        if (this.getPayloadData(dataFromAPI, payloadIndex) !== undefined) {
            if (this.getPayloadData(dataFromAPI, payloadIndex).wName !== undefined && this.getPayloadData(dataFromAPI, payloadIndex).wName !== null) {
                name = getCapitalized(this.getPayloadData(dataFromAPI, payloadIndex).wName)
            }
        }
        templateDataToReturn.companyName = name

        //company address
        var fromAddressObj = undefined
        var address = {}
        if (this.getPayloadData(dataFromAPI, payloadIndex) !== undefined) {
            var warehouseAddress = this.getPayloadData(dataFromAPI, payloadIndex)
            address = {
                address1: warehouseAddress.wAdd1,
                address2: warehouseAddress.wAdd2,
                city: warehouseAddress.wCity,
                state: warehouseAddress.wState,
                postalCode: warehouseAddress.wZip,
                country: warehouseAddress.wCountry,
            }
            fromAddressObj = new Address(undefined, address, undefined, undefined, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var billTo = this.getPayloadData(dataFromAPI, payloadIndex)
        address = {
            address1: billTo?.cAdd1 || '',
            address2: billTo?.cAdd2 || '',
            postalCode: billTo?.cZip || '',
            country: billTo?.cCountry || '',
        }

        var contactName = ''
        var contactNumber = ''
        var contactCode = ''

        if (billTo && billTo.cName !== undefined && billTo.cName !== null) {
            contactName = billTo.cName
        }

        if (billTo && billTo.cPhone !== undefined && billTo.cPhone !== null) {
            contactNumber = billTo.cPhone
        }

        var contactInfo = billTo?.contactInfo
        if (contactInfo !== undefined && contactInfo !== null ) {
            if (contactInfo.name !== undefined && contactInfo.name !== null) {
                contactName = contactInfo.name
            }
            if (contactInfo.contactNumber !== undefined && contactInfo.contactNumber !== null) {
                contactNumber = contactInfo.contactNumber
            }
            if (contactInfo.documentSequenceCode !== undefined && contactInfo.documentSequenceCode !== null) {
                contactCode = contactInfo.documentSequenceCode
            }
            templateDataToReturn.contactInfo = contactInfo
        }

        var clientBillToAddressObj = new Address(contactName, address, dataFromAPI.pan, dataFromAPI.gstin, contactNumber, contactCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        templateDataToReturn.clientShipToAddress = ''

        //ref number
        var refNumber = ''
        var linkDocumentNumber = ''
        if (this.getPayloadData(dataFromAPI, payloadIndex) !== undefined) {
            if (this.getPayloadData(dataFromAPI, payloadIndex).orderNumber !== undefined && this.getPayloadData(dataFromAPI, payloadIndex).orderNumber !== null) {
                refNumber = this.getPayloadData(dataFromAPI, payloadIndex).orderNumber
            } else if (this.getPayloadData(dataFromAPI, payloadIndex).pickListNo !== undefined && this.getPayloadData(dataFromAPI, payloadIndex).pickListNo !== null) {
                refNumber = this.getPayloadData(dataFromAPI, payloadIndex).pickListNo
            }
            if (this.getPayloadData(dataFromAPI, payloadIndex).cartonNo !== undefined && this.getPayloadData(dataFromAPI, payloadIndex).cartonNo !== null) {
                linkDocumentNumber = this.getPayloadData(dataFromAPI, payloadIndex).cartonNo
            }
        }
        templateDataToReturn.refNumber = refNumber
        templateDataToReturn.linkDocumentNumber = linkDocumentNumber
        templateDataToReturn.linkDocumentType = getLocalisedText('number_of_carton')
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        //start date
        var date = ''
        if (this.getPayloadData(dataFromAPI, payloadIndex) !== undefined) {
            if (this.getPayloadData(dataFromAPI, payloadIndex).date !== undefined && this.getPayloadData(dataFromAPI, payloadIndex).date !== null) {
                date = this.getPayloadData(dataFromAPI, payloadIndex).date
            }
        }
        if (date !== '') {
            var rawDate = getDateString(getDateFrom(date, this.getPickPackShipDateFormat(dataFromAPI, templateDataToReturn.dateFormat), templateDataToReturn.dateFormat))
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(rawDate), templateDataToReturn.dateFormat)
        }
        else {
            templateDataToReturn.date = date
        }

        //due date
        templateDataToReturn.dueDate = ''
        templateDataToReturn.showDueDate = false

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getPackItem(dataFromAPI, payloadIndex)

        return templateDataToReturn
    }

    static getPayloadData(data, payloadIndex) {
        const index = payloadIndex ?? 0
        if (data.payload === undefined || data.payload === null) {
            return undefined
        }

        if (data.payload.length === 0) {
            return undefined
        }

        if(data.payload.length <= index) {
            return undefined
        }
        return data.payload[index]
    }

    static getPickPackShipDateFormat(data, dateFormat) {
        var defaultDateFormat = dateFormat
        if (data.tenantInfo !== undefined && data.tenantInfo !== null) {
            if (data.tenantInfo.dateFormat !== undefined && data.tenantInfo.dateFormat !== null) {
                defaultDateFormat = data.tenantInfo.dateFormat.toLowerCase()
                if (defaultDateFormat === 'd mmm yyyy') {
                    defaultDateFormat = DateFormat.DDMMMYYYY
                }
            }
        }
        return defaultDateFormat
    }

    static getCartonCount(dataFromAPI) {
        let cartonList = dataFromAPI.payload ?? undefined
        let cartonCount = 0
        if(cartonList) {
            cartonCount = cartonList.length
        }

        return cartonCount
    }
}
