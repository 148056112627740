import { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  AMORTIZATION_METHODS,
  AMORTIZATION_TYPES,
  AMORTIZATION_TERM_SOURCE,
  AMORTIZATION_SCHEDULE_STATUS,
  STATUS_TYPE,
  INPUT_VIEW_DIRECTION,
  SWITCH_CURRENCY_OPTIONS
} from '../../Constants/Constant';
import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKDataGrid,
  showLoader,
  removeLoader,
  INPUT_TYPE,
  DKInput,
  showAlert
} from 'deskera-ui-library';
import DateFormatService from '../../Services/DateFormat';
import NumberFormatService from '../../Services/NumberFormat';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { DraftTypes } from '../../Models/Drafts';
import AccountsService from '../../Services/Accounts';
import AmortizationScheduleSummaryView from './AmortizationScheduleSummaryView';
import './NewDocument.scss';
import AmortizationService from '../../Services/Amortization';
import { getAmountBlockWithCurrency } from '../../Components/Reports/Financial/Parser/SharedParser';
import { RowUpdateType } from '../../Models/Table';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import GridColumnVisibility, {
  COLUMN_VISIBILITY_BUTTON_CONFIG
} from '../GridColumnVisibility';
import { updateRowDataWithParentCFValues } from './NewDocumentHelper';
import DetailsOpener from '../../Components/Common/DetailsOpener';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

export interface NewDocumentProps2 {
  documentMode?: DOCUMENT_MODE;
  populateFormData?: any;
  draftType?: any;
  onDocumentUpdate?: any;
  customFieldData?: any;
}

export default function AmortizationScheduleDoc(props: NewDocumentProps2) {
  const [scheduleData, setScheduleData] = useState<any>({});
  const [accounts, setAccounts] = useState<any[]>([]);
  const [productRows, setProductRows] = useState<any[]>([]);
  const [columnConfig, setColumnConfig] = useState<any[]>(
    DocumentConfigManager.get(DOC_TYPE.AMORTIZATION_SCHEDULE)
  );
  const tenantDetails = useAppSelector(activeTenantInfo);
  const [postingPeriodList, setPostingPeriodList] = useState<any[]>([]);
  const [originalPostingPeriodList, setOriginalPostingPeriodList] = useState<
    any[]
  >([]);
  const [postingPeriodData, setPostingPeriodData] = useState<any[]>([]);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const [alreadyPostedPeriodLastIndex, setAlreadyPostedPeriodLastIndex] =
    useState<any>(0);
  const [detailsPopupData, setDetailsPopupData] = useState<any>();
  useEffect(() => {
    if (props?.populateFormData?.code) {
      showLoader('Please Wait');
      loadScheduleData(props?.populateFormData?.code);
    }
  }, [props?.populateFormData]);

  const loadScheduleData = (scheduleCode: string) => {
    AmortizationService.getAmortizationScheduleByCode(scheduleCode)
      .then((data: any) => {
        setScheduleData(formatScheduleData(data));
        getAccountDetails(data?.amortizationScheduleItem || []);
        getProductRows(data?.amortizationScheduleItem || []);
        getCustomFieldColumnConfig(data?.amortizationScheduleItem || []);
        const tempPostingPeriodData =
          generateMonthYearArray(data?.startDate) || [];
        let tempPostingPeriodList = tempPostingPeriodData;
        let alreadyPostedPeriodList: any[] = [];

        data?.amortizationScheduleItem.forEach((rowData: any) => {
          if (rowData.jeCode) {
            alreadyPostedPeriodList.push(rowData.postingPeriod);
          }
        });
        tempPostingPeriodList = tempPostingPeriodList.filter(
          (tempRow) => !alreadyPostedPeriodList.includes(tempRow)
        );

        setPostingPeriodData(tempPostingPeriodData);
        setPostingPeriodList(tempPostingPeriodList);
        setOriginalPostingPeriodList(tempPostingPeriodList);
        setAlreadyPostedPeriodLastIndex(alreadyPostedPeriodList.length);
      })
      .catch((err) => removeLoader());
  };

  useEffect(() => {
    props.onDocumentUpdate(productRows);
  }, [productRows]);

  const formatScheduleData = (data: any) => {
    return {
      ...data,
      amortizationMethod:
        AMORTIZATION_METHODS[
          data.amortizationMethod as keyof typeof AMORTIZATION_METHODS
        ],
      type: AMORTIZATION_TYPES[data.type as keyof typeof AMORTIZATION_TYPES],
      termSource:
        AMORTIZATION_TERM_SOURCE[
          data.termSource as keyof typeof AMORTIZATION_TERM_SOURCE
        ],
      status:
        AMORTIZATION_SCHEDULE_STATUS[
          data.status as keyof typeof AMORTIZATION_SCHEDULE_STATUS
        ],
      bookSpecific: props?.populateFormData?.bookSpecific
    };
  };
  useEffect(() => {
    updateConfig();
  }, [scheduleData, postingPeriodList]);
  const getAccountDetails = (items: any[]) => {
    let accountList = items?.map((item: any) => item.targetAccountCode);

    AccountsService.fetchAccountsByCodes(accountList)
      .then((accountList) => {
        setAccounts(accountList);
        removeLoader();
      })
      .catch((err) => removeLoader());
  };

  const getRightInfoPanel = () => {
    return (
      <div className="column align-items-end">
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between p-v-xs ${
              props.documentMode !== DOCUMENT_MODE.EDIT
                ? 'listPickerBG'
                : 'p-v-xs'
            }`}
            style={{
              width: 240
            }}
          >
            <div className="row" style={{ width: 140 }}>
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'No.'} className={'fw-m ml-r'} />
            </div>
            <DKLabel text={scheduleData?.code} className={'ml-r '} />
          </div>
        </div>
        <div className="position-relative">
          <div
            className="row width-auto justify-content-between p-v-xs"
            style={{
              width: 240
            }}
          >
            <div className="row " style={{ width: 140 }}>
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'Start Date'} className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getFormattedDateString(
                scheduleData?.startDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )}
              className={'ml-r '}
            />
          </div>
        </div>
        <div className="position-relative">
          <div
            className="row width-auto justify-content-between p-v-xs"
            style={{
              width: 240
            }}
          >
            <div className="row" style={{ width: 140 }}>
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'End Date'} className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getFormattedDateString(
                scheduleData?.endDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )}
              className={'ml-r '}
            />
          </div>
        </div>
        <div className="position-relative">
          <div
            className="row width-auto justify-content-between p-v-xs"
            style={{
              minWidth: 240
            }}
          >
            <div className="row" style={{ width: 140 }}>
              <DKIcon
                src={DKIcons.data_type.ic_url}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={`Linked To`} className={'fw-m ml-r'} />
            </div>
            <div
              className="ml-r fs-r underline cursor-hand"
              onClick={onClickLinkData}
            >
              {scheduleData?.documentSeqCode || ''}
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div
            className="row width-auto justify-content-between p-v-xs"
            style={{
              minWidth: 240
            }}
          >
            <div className="row" style={{ width: 140 }}>
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={`Transaction Date`} className={'fw-m ml-r'} />
            </div>
            <div className="ml-r fs-r">{scheduleData?.transactionDate}</div>
          </div>
        </div>
      </div>
    );
  };

  const getProductRows = (rowData: any) => {
    let tempProductRows = rowData;
    let customFieldList: any[] = [];
    props?.customFieldData?.forEach((currCustomField: any) => {
      const customfieldRow = {
        label: currCustomField?.label,
        shortName: currCustomField?.shortName,
        code: currCustomField?.code,
        module: currCustomField?.module,
        value: '',
        id: currCustomField?.id
      };
      customFieldList.push(customfieldRow);
    });

    tempProductRows?.forEach((row: any, index: any) => {
      let tempCustomFieldList: any[] = customFieldList.map((fieldData) => {
        const val =
          row?.customField?.find((fdata: any) => fdata.id === fieldData.id)
            ?.value || '';
        return { ...fieldData, value: val };
      });
      tempCustomFieldList?.forEach((field: any) => {
        const colName: any = field.id + '' || '';
        if (colName !== '') {
          let tempRow: any = {};
          tempRow[colName] = field.value;
          row = { ...row, ...tempRow, customField: tempCustomFieldList };
          tempProductRows[index] = row;
        }
      });
    });
    setProductRows(tempProductRows);
  };

  const getCustomFieldColumnConfig = (dataItem: any) => {
    let tempColumnConfig = [...columnConfig];

    if (props.customFieldData?.length > 0) {
      setProductCustomFields(props.customFieldData);
    }
    props?.customFieldData?.forEach((currCustomField: any) => {
      if (
        tenantDetails?.additionalSettings?.AMORTIZATION_SCHEDULE_CUSTOM_FIELD_SETTING?.includes(
          currCustomField?.id
        )
      ) {
        if (!Utility.isEmpty(currCustomField)) {
          if (currCustomField.fieldType === 'DROPDOWN') {
            let attributes = currCustomField.attributes;
            const noneAttribute = {
              id: 0,
              code: null,
              status: STATUS_TYPE.ACTIVE,
              value: 'None'
            };
            const alreadyAddedNoneAttribute = attributes.find(
              (att: any) => att.id === 0
            );
            if (Utility.isEmpty(alreadyAddedNoneAttribute)) {
              attributes.push(noneAttribute);
            }

            const tempColumn = {
              id: currCustomField.id + '',
              key: currCustomField.id + '',
              name: currCustomField.label,
              type: INPUT_TYPE.DROPDOWN,
              width: 140,
              systemField: true,
              editable: true,
              hidden: false,
              uiVisible: true,
              formatter: ({ value }: any) => value,
              dropdownConfig: {
                title: 'Select',
                allowSearch: true,
                data: attributes,
                onSelect: (index: any, obj: any, rowIndex: any) => {},
                renderer: (index: any, obj: any) => {
                  return obj.value;
                }
              }
            };
            tempColumnConfig.push(tempColumn);
          } else {
            const tempColumn = {
              id: currCustomField.id + '',
              key: currCustomField.id + '',
              name: currCustomField.label,
              type: INPUT_TYPE.TEXT,
              width: 120,
              systemField: true,
              editable: true,
              hidden: false,
              uiVisible: true
            };
            tempColumnConfig.push(tempColumn);
          }
        }
      }
    });

    setColumnConfig(tempColumnConfig);
  };
  const generateMonthYearArray = (tmpDate: any) => {
    let startDate = DateFormatService.getDateFromStr(
      tmpDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    let endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 5);
    const result = [];
    while (startDate < endDate) {
      result.push(
        startDate.toLocaleString('en-US', { month: 'short', year: 'numeric' })
      );
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return result;
  };

  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.id) {
        case 'amount':
          conf.editable = conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              Utility.roundOff(data.value, 4),
              scheduleData.bookSpecific?.value?.value ===
                SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
                ? scheduleData?.primaryCurrency
                : tenantDetails.currency
            );

          conf.key =
            scheduleData.bookSpecific?.value?.value ===
            SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? 'primaryAmount'
              : 'amount';

          break;
        case 'totalAmortize':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              Utility.roundOff(data.value, 4),
              scheduleData.bookSpecific?.value?.value ===
                SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
                ? scheduleData?.primaryCurrency
                : tenantDetails.currency
            );

          conf.key =
            scheduleData.bookSpecific?.value?.value ===
            SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? 'primaryTotalAmortize'
              : 'totalAmortize';

          break;
        case 'jeCode':
          conf.key =
            scheduleData.bookSpecific?.value?.value ===
            SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? 'primaryJeCode'
              : 'jeCode';

          break;
        case 'postingPeriod':
          conf.dropdownConfig.data = postingPeriodList || [];
          break;
      }
    });
    setColumnConfig(config);
  };

  const getDetailOpener = () => {
    return (
      detailsPopupData?.showDetailsOpener && (
        <DetailsOpener
          data={detailsPopupData}
          onCancel={() => {
            setDetailsPopupData(null);
          }}
        />
      )
    );
  };

  const getColumnConfig = () => {
    let newColConfig: any[] = [...columnConfig];
    let jeConfigIndex = newColConfig.findIndex(
      (config) => config.key === 'jeCode' || config.key === 'primaryJeCode'
    );
    if (jeConfigIndex) {
      newColConfig[jeConfigIndex] = {
        ...newColConfig[jeConfigIndex],
        renderer: (obj: any) => {
          return (
            <DKLabel
              text={
                scheduleData.bookSpecific?.value?.value ===
                SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
                  ? obj?.rowData?.primaryJeCode
                  : obj?.rowData?.jeCode ?? ''
              }
              className="underline cursor-hand fs-m"
            />
          );
        }
      };
    }

    return newColConfig;
  };

  const onRowClick = (row: any) => {
    let tempPostingPeriodList = [...postingPeriodList];
    const currPostingPeriod = row?.rowData?.postingPeriod;
    const currPostingPeriodIndex = postingPeriodData.findIndex(
      (tempPosting) => tempPosting === currPostingPeriod
    );
    if (row.rowIndex === alreadyPostedPeriodLastIndex) {
      tempPostingPeriodList = originalPostingPeriodList;
    } else if (currPostingPeriodIndex > -1) {
      tempPostingPeriodList = postingPeriodData.slice(currPostingPeriodIndex);
    }
    setPostingPeriodList(tempPostingPeriodList);

    if (
      (row.columnData.key === 'jeCode' ||
        row.columnData.key === 'primaryJeCode') &&
      row.rowData.allowRowEdit === false
    ) {
      if (checkUserPermission(PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW)) {
        setDetailsPopupData({
          documentSeqCode:
            scheduleData.bookSpecific?.value?.value ===
            SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? row.rowData.primaryJeCode
              : row.rowData.jeCode,
          documentCode:
            scheduleData.bookSpecific?.value?.value ===
            SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
              ? row.rowData.primaryJeCode
              : row.rowData.jeCode,
          documentType: DOC_TYPE.Journal_S_Entry,
          showDetailsOpener: true
        });
      } else {
        showAlert('Alert', 'Permission denied');
      }
    }
  };
  const onRowUpdate = (rowUpdate: any) => {
    const { columnData, columnKey, rowData, rowIndex } = rowUpdate;
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    switch (columnKey) {
      case 'amount':
        selectedRows.amount = Utility.roundOff(
          +dataToUpdate || selectedRows.amount,
          4
        );
        selectedRows.amountInBase = Utility.roundOff(
          +dataToUpdate || selectedRows.amountInBase,
          4
        );
        break;
      case 'postingPeriod':
        let currPostingPeriodIndex = postingPeriodList.findIndex(
          (postingData) => postingData === dataToUpdate
        );
        let currProductRowsIndex = rowIndex;
        if (currPostingPeriodIndex > -1) {
          rows.forEach((element: any, ind: any) => {
            if (
              ind >= currProductRowsIndex &&
              currPostingPeriodIndex < postingPeriodList.length
            ) {
              rows[currProductRowsIndex].postingPeriod =
                postingPeriodList[currPostingPeriodIndex];
              currProductRowsIndex = currProductRowsIndex + 1;
              currPostingPeriodIndex = currPostingPeriodIndex + 1;
            } else if (ind >= currProductRowsIndex) {
              rows[currProductRowsIndex].postingPeriod = '';
              currProductRowsIndex = currProductRowsIndex + 1;
            }
          });
        }
        break;
      default:
        const customFieldIndex = selectedRows?.customField?.findIndex(
          (field: any) => field.id === +columnKey
        );
        if (columnData.type === 'dropdown') {
          selectedRows[columnKey] =
            dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
          const filteredCF: any = productCustomFields?.find(
            (field: any) => field.id === +columnKey
          );
          const { rowData } = updateRowDataWithParentCFValues(
            dataToUpdate.value,
            { ...rows[rowIndex] },
            filteredCF,
            productCustomFields
          );
          selectedRows = rowData;
          // selectedRows.customField[customFieldIndex].value =
          //   dataToUpdate?.value;
        } else {
          selectedRows[columnKey] = dataToUpdate;
          selectedRows.customField[customFieldIndex].value = dataToUpdate;
        }
    }

    rows[rowIndex] = selectedRows;
    setProductRows(rows);
  };

  const getDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needColumnIcons={false}
      needBorder={true}
      needTrailingColumn={false}
      allowBulkOperation={false}
      allowColumnSort={false}
      filterData={[]}
      allowColumnDelete={false}
      allowRowEdit={
        DraftTypes.READONLY === props.draftType
          ? false
          : !(
              scheduleData.bookSpecific?.value?.value ===
              SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
            )
      }
      onRowUpdate={onRowUpdate}
      onRowClick={onRowClick}
      allowColumnEdit={false}
      allowFilter={false}
      allowColumnAdd={false}
      allowBottomRowAdd={false}
      allowSearch={false}
      allowShare={false}
      rows={productRows?.map((rowData: any) => getDataRows(rowData)) || []}
      allowExpand={false}
      columns={getColumnConfig() || []}
    />
  );

  const getDataRows = (rowData: any) => {
    return {
      ...rowData,
      targetAccountName: accounts.find(
        (acc: any) => acc.code === rowData.targetAccountCode
      )?.name,
      isRecognized: rowData.isRecognized === true ? 'Yes' : 'No',
      dateExecuted: rowData.dateExecuted
        ? DateFormatService.getDateFromStr(
            rowData.dateExecuted,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        : '',
      jeStatus: rowData.jeCode ? 'Posted' : 'Not Posted',
      amount: Utility.roundOff(
        rowData?.amountInBase ? rowData.amountInBase : rowData.amount,
        4
      ),
      totalAmortize: rowData?.totalAmortizeInBase
        ? rowData?.totalAmortizeInBase
        : rowData.totalAmortize || 0,
      primaryTotalAmortize: rowData?.primaryTotalAmortize || 0,
      rowContextMenu: null,
      rowButtons: null,
      allowRowEdit: rowData.jeCode ? false : true
    };
  };

  const titleText = (
    title: string,
    value: string | number,
    icon: any,
    titleClassName: string,
    textClassName?: string
  ) => {
    const numberValue =
      typeof value === 'number' &&
      `${value < 0 ? '(' : ''}${NumberFormatService.getNumber(
        Math.abs(value)
      )}${value < 0 ? ')' : ''}`;

    return (
      <div
        className="row parent-width mb-s justify-content-between align-items-start"
        style={{ width: '50%' }}
      >
        <div
          className="row width-auto"
          style={{
            minWidth: 100
          }}
        >
          {icon && (
            <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
          )}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
        </div>
        <DKLabel
          text={numberValue || value}
          style={{
            wordBreak: 'break-all'
          }}
          className={`ml-r text-wrap ${textClassName || ''}`}
        />
      </div>
    );
  };

  const onClickVendorName = () => {
    if (!Utility.isEmpty(scheduleData.contactCode)) {
      if (checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.VIEW)) {
        setDetailsPopupData({
          documentSeqCode: scheduleData.contactCode,
          documentType: DOC_TYPE.Contact,
          showDetailsOpener: true
        });
      } else {
        showAlert('Alert', 'Permission denied');
      }
    }
  };
  const onClickLinkData = () => {
    if (!Utility.isEmpty(scheduleData.documentCode)) {
      if (
        (scheduleData.documentType === DOC_TYPE.JOURNAL_ENTRY &&
          checkUserPermission(PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW)) ||
        (scheduleData.documentType === DOC_TYPE.BILL &&
          checkUserPermission(PERMISSIONS_BY_MODULE.BILL.VIEW))
      ) {
        setDetailsPopupData({
          documentSeqCode: scheduleData.documentSeqCode,
          documentCode: scheduleData.documentCode,
          documentType:
            scheduleData.documentType === DOC_TYPE.JOURNAL_ENTRY
              ? DOC_TYPE.Journal_S_Entry
              : DOC_TYPE.Bill,
          showDetailsOpener: true
        });
      } else {
        showAlert('Alert', 'Permission denied');
      }
    }
  };
  const clickableTitleText = (
    title: string,
    value: string | number,
    code: string | number,
    icon: any,
    titleClassName: string,
    textClassName?: string
  ) => {
    const numberValue =
      typeof value === 'number' &&
      `${value < 0 ? '(' : ''}${NumberFormatService.getNumber(
        Math.abs(value)
      )}${value < 0 ? ')' : ''}`;

    return (
      <div
        className="row parent-width mb-s justify-content-between align-items-start"
        style={{ width: '50%' }}
      >
        <div
          className="row width-auto"
          style={{
            minWidth: 100
          }}
        >
          {icon && (
            <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
          )}
          <DKLabel text={title} className={'ml-r ' + titleClassName} />
        </div>
        <div onClick={onClickVendorName}>
          <DKLabel
            text={numberValue || value}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap underline cursor-hand ${
              textClassName || ''
            }`}
          />
        </div>
      </div>
    );
  };

  const getAccountBookSelection = () => {
    return (
      <div className="col align-items-start mt-r" style={{ width: '100%' }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={{ color: 'gray', fontSize: 12 }}
          title={'Accounting Book'}
          formatter={(obj: any) => {
            return obj.title;
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={scheduleData.bookSpecific?.value}
          onChange={(value: any) => {
            setScheduleData((prev: any) => {
              return {
                ...prev,
                bookSpecific: {
                  ...prev.bookSpecific,
                  value: value
                }
              };
            });
          }}
          dropdownConfig={{
            className: '',
            style: {
              minWidth: 150
            },
            allowSearch: false,
            searchableKey: null,
            data: scheduleData.bookSpecific?.dataOptions,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.title} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };
  return (
    <div
      className={`column parent-width p-h-xxl pb-xxl pt-s pt-l position-relative`}
    >
      <div className="position-relative ml-r">
        <div
          className="row width-auto justify-content-between p-v-xs"
          style={{
            minWidth: 240
          }}
        >
          {!Utility.isEmpty(tenantDetails) &&
            !Utility.isEmpty(tenantDetails?.additionalSettings.MULTI_COMPANY) &&
            tenantDetails?.additionalSettings.MULTI_COMPANY
              .parentOrganization !== undefined &&
            tenantDetails?.additionalSettings.MULTI_COMPANY
              .parentOrganization !== null &&
            tenantDetails?.additionalSettings.MULTI_COMPANY
              ?.primaryCurrencyCode !== tenantDetails?.currency &&
            getAccountBookSelection()}
        </div>
      </div>

      <div className={`row justify-content-between align-items-stretch mt-r`}>
        <div className="column justify-content-between position-relative width-80 pr-l">
          {titleText('Template Name', scheduleData.templateName, null, 'fw-m')}
          {titleText('Method', scheduleData.amortizationMethod, null, 'fw-m')}
          {titleText('Type', scheduleData.type, null, 'fw-m')}
          {titleText('Term Source', scheduleData.termSource, null, 'fw-m')}
          {titleText(
            'Amortization Period',
            scheduleData.amortizationPeriod || '' + '',
            null,
            'fw-m'
          )}
          {titleText('Status', scheduleData.status, null, 'fw-m')}
          {clickableTitleText(
            'Vendor Name ',
            scheduleData?.contactName,
            scheduleData?.contactCode,
            null,
            'fw-m'
          )}
          {titleText(
            'Supplier Invoice Number ',
            scheduleData?.supplierInvoiceNo,
            null,
            'fw-m'
          )}
        </div>
        {getRightInfoPanel()}
      </div>
      <div
        className={`column parent-width ${
          props.documentMode === DOCUMENT_MODE.VIEW ? 'pointer-events-auto' : ''
        }`}
      >
        {getDataGrid()}
      </div>

      {/* Footer section */}
      <div className="flex flex-row items-start justify-content-between box-border w-full mt-m">
        <div
          className={`column ${
            props.draftType === DraftTypes.READONLY
              ? 'pointer-events-auto'
              : 'mt-xl'
          }`}
        ></div>
        <div
          className={props.documentMode === DOCUMENT_MODE.VIEW ? 'mt-r' : ''}
          style={{ width: 330 }}
        >
          <AmortizationScheduleSummaryView
            documentMode={
              props.documentMode ? props.documentMode : DOCUMENT_MODE.VIEW
            }
            onCurrencyAndExchangeRateChange={(
              currency: any,
              exchangeRate: number
            ) => {}}
            scheduleData={scheduleData}
          />
        </div>
        {getDetailOpener()}
      </div>
    </div>
  );
}
