import {
  showToast,
  TOAST_TYPE,
  DKLabel,
  DKInput,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE,
  TAX_TYPES
} from '../../../Constants/Constant';
import { TYPE_OPTION_TAX } from '../../../Constants/StaticData';
import { DKCheckMark } from 'deskera-ui-library';
import Select from 'react-select';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import { NewCoaProps } from '../../../Models/Coa';
import {
  initialTaxListFormValues,
  TaxListInfo,
  TaxPayload
} from '../../../Models/Tax';
import { useAppSelector } from '../../../Redux/Hooks';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { ReactSelectOptionsType } from '../../../Models/Interfaces';
import TaxService from '../../../Services/Tax';
import { fetchTaxTable } from '../../../Redux/Slices/TaxSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { fetchTaxes } from '../../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../../Services/DateFormat';

const UK_TAX = [
  { name: 'Box 1', code: 0 },
  { name: 'Box 2', code: 1 },
  { name: 'Box 4', code: 2 },
  { name: 'Box 6', code: 3 },
  { name: 'Box 7', code: 4 },
  { name: 'Box 8', code: 5 },
  { name: 'Box 9', code: 6 },
  { name: 'None', code: 7 }
];

const AddTax: React.FC<NewCoaProps> = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<TaxListInfo>(
    deepClone(initialTaxListFormValues)
  );
  const dispatch = useDispatch();
  const accounts = useAppSelector(selectedAccounts);
  const tenantDetails = useAppSelector(activeTenantInfo);
  const [effectiveStartDate, setEffectiveStartDate] = useState<any>(null);
  const [effectiveEndDate, setEffectiveEndDate] = useState<any>(null);
  const tenantInfo = useAppSelector(activeTenantInfo);

  const accountList = accounts && accounts.content ? accounts.content : [];
  const [validated, setValidated] = useState<boolean>(true);
  const [isDateReadOnly, setIsDateReadOnly] = useState<boolean>(false);

  // UK Compliance
  const [taxAmountInIndexes, setTaxAmountInIndexes] = useState([]);
  const [netTaxAmountInIndexes, setNetTaxAmountInIndexes] = useState([]);

  useEffect(() => {
    let accountSelectOptions: ReactSelectOptionsType<string, string>[] = [];

    accountList.forEach((account: any) => {
      if (account.status === STATUS_TYPE.ACTIVE) {
        let option: ReactSelectOptionsType<string, string> = {
          label: account.name as string,
          value: account.code as string
        };
        accountSelectOptions.push(option);
      }
    });

    const updatedState = formState;
    let taxOptions = deepClone(TYPE_OPTION_TAX);
    updatedState.type.options = taxOptions
    if (tenantDetails?.country === COUNTRY_CODES.UK) {
      updatedState.type.options = taxOptions?.filter((taxOp: any) => {
        return taxOp.value !== TAX_TYPES.BOTH;
      });
    }
    updatedState.taxAccount.options = accountSelectOptions;

    if (Utility.isEmpty(props.populateFormData)) {
      const isDefaultObj = accountList.find((acc: any) => {
        return acc.accountCode === formState.defaultTaxAccount;
      });

      if (!Utility.isEmpty(isDefaultObj)) {
        updatedState.taxAccount.value = {
          label: isDefaultObj?.name,
          value: isDefaultObj?.code
        };
      }
    }
    if (
      props.populateFormData &&
      props.populateFormData.category === 'SYSTEM'
    ) {
      setIsDateReadOnly(true);
    }

    if (props.populateFormData && props.populateFormData.effectiveEndDate) {
      setEffectiveEndDate(
        DateFormatService.getDateFromStr(
          props.populateFormData.effectiveEndDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      );
    }
    if (props.populateFormData && props.populateFormData.effectiveStartDate) {
      setEffectiveStartDate(
        DateFormatService.getDateFromStr(
          props.populateFormData.effectiveStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      );
    }

    if (props.populateFormData && props.populateFormData.name) {
      updatedState.name.defaultValue = props.populateFormData.name;
      updatedState.name.value = props.populateFormData.name;
    }
    if (props.populateFormData?.percent) {
      updatedState.percent.defaultValue = props.populateFormData.percent;
      updatedState.percent.value = props.populateFormData.percent;
    }
    if (props.populateFormData && props.populateFormData.description) {
      updatedState.description.defaultValue =
        props.populateFormData.description;
      updatedState.description.value = props.populateFormData.description;
    }
    if (props.populateFormData && props.populateFormData?.defaultMemoUk) {
      updatedState.defaultMemo.defaultValue =
        props.populateFormData.defaultMemoUk;
      updatedState.defaultMemo.value = props.populateFormData.defaultMemoUk;
    }
    if (props.populateFormData && props.populateFormData.taxCode) {
      updatedState.taxCode.defaultValue = props.populateFormData.taxCode;
      updatedState.taxCode.value = props.populateFormData.taxCode;
    }
    if (
      props.populateFormData &&
      props.populateFormData.type &&
      updatedState.type.options
    ) {
      const typeRecieved: string = props.populateFormData.type;
      const selectedOption =
        updatedState.type.options &&
        updatedState.type.options.find(
          (option: any) => option.value === typeRecieved
        );

      if (selectedOption) {
        updatedState.type.value = selectedOption?.label ?? '';
      }
    }
    if (props.populateFormData && props.populateFormData.accountName) {
      let selectedOption: any;
      const typeRecieved: string = props.populateFormData.accountName;
      selectedOption =
        updatedState.taxAccount.options &&
        updatedState.taxAccount.options.filter(
          (option) => option.label === typeRecieved
        )[0];
      updatedState.taxAccount.value = selectedOption;
    }
    if (props.populateFormData && props.populateFormData.additionalTaxIn) {
      updatedState.isAdditional = props.populateFormData.additionalTaxIn;
    }

    let taxAmountIndexes: any = [];
    if (
      !Utility.isEmpty(props.populateFormData?.taxAmountUk) &&
      tenantDetails?.country === COUNTRY_CODES.UK
    ) {
      let taxAmountUk = props.populateFormData?.taxAmountUk;
      taxAmountUk = taxAmountUk?.split(', ');
      if (!Utility.isEmpty(taxAmountUk)) {
        UK_TAX?.forEach((item1: any, index1: number) => {
          taxAmountUk?.forEach((item2: any, index2: number) => {
            if (item1.name.toLowerCase() === item2.toLowerCase()) {
              taxAmountIndexes.push(index1);
            }
          });
        });
      }
    }

    if (tenantDetails?.country === COUNTRY_CODES.UK) {
      updatedState.flatRateSchemeUk =
        props.populateFormData?.flatRateSchemeUk ?? false;
    }

    let netTaxAmountIndexes: any = [];
    if (
      !Utility.isEmpty(props.populateFormData?.netAmountUk) &&
      tenantDetails?.country === COUNTRY_CODES.UK
    ) {
      let netAmountUk = props.populateFormData?.netAmountUk;
      netAmountUk = netAmountUk?.split(', ');
      if (!Utility.isEmpty(netAmountUk)) {
        UK_TAX?.forEach((item1: any, index1: number) => {
          netAmountUk?.forEach((item2: any, index2: number) => {
            if (item1.name.toLowerCase() === item2.toLowerCase()) {
              netTaxAmountIndexes.push(index1);
            }
          });
        });
      }
    }

    if (!props.populateFormData) {
      updatedState.percent.defaultValue = '0';
      updatedState.percent.value = '0';
      updatedState.type.value = updatedState.type.options[1]?.label;
    }

    setTaxAmountInIndexes(taxAmountIndexes);
    setNetTaxAmountInIndexes(netTaxAmountIndexes);
    setFormState({ ...updatedState });
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.CREATE_TAX,
      data: () => {
        createTaxEntry();
      }
    });
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.UPDATE_TAX,
      data: () => {
        updateTaxEntry();
      }
    });
  };

  const createTaxEntry = () => {
    const payload = onSubmit();
    if (payload) {
      TaxService.createTaxEntry(payload)
        .then((res: any) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS,
            data: res
          });
          dispatch(fetchTaxTable());
          dispatch(fetchTaxes());
          showToast(
            t(`SETTINGS.TAX.TOAST.TAX_CREATED_SUCCESS`),
            TOAST_TYPE.SUCCESS
          );
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };
  const updateTaxEntry = () => {
    const id: number = props.populateFormData.id;
    const payload = onSubmit();
    if (payload) {
      TaxService.updateTax(payload, id)
        .then((res) => {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
          dispatch(fetchTaxTable());
          dispatch(fetchTaxes());
          showToast(
            t(`SETTINGS.TAX.TOAST.TAX_UPDATED_SUCCESS`),
            TOAST_TYPE.SUCCESS
          );
        })
        .catch((err) => {
          showToast(
            t(`SETTINGS.TAX.TOAST.COULD_NOT_UPDATE_TAX`),
            TOAST_TYPE.FAILURE
          );
        });
    }
  };

  const validateAccounts = () => {
    let updatedState = formState;
    let result = true;
    if (
      formState.percent.value === '' ||
      formState.percent.value === null ||
      formState.percent.value?.toString()?.trim() === ''
    ) {
      setValidated(false);
      updatedState.percent.errorMsg = t(
        `SETTINGS.TAX.TOAST.PLEASE_ENTER_NUMBER_IN_PERCENT_FIELD`
      );
      result = false;
    }

    if (
      formState.name.value === '' ||
      formState.name.value === null ||
      formState.name.value?.toString()?.trim() === ''
    ) {
      result = false;
      updatedState.name.errorMsg = t(`SETTINGS.UOM.NAME_TOAST_ENTER`);
    }

    if (
      formState.taxCode.value === '' ||
      formState.taxCode.value === null ||
      formState.taxCode.value?.toString()?.trim() === ''
    ) {
      result = false;
      updatedState.taxCode.errorMsg = t(`SETTINGS.TAX.ERROR_TAX_CODE`);
    }

    if (!formState.taxAccount.value?.value || !formState.type.value) {
      result = false;
    }

    setFormState({ ...updatedState });

    return result;
  };

  const onSubmit = () => {
    if (!validateAccounts()) {
      setValidated(false);
      return;
    }

    let taxAmountUkArr: any = [];
    let taxAmountUk = '';
    let netAmountUkArr: any = [];
    let netAmountUk = '';

    if (tenantDetails?.country === COUNTRY_CODES.UK) {
      UK_TAX?.forEach((item1: any, index1: any) => {
        taxAmountInIndexes?.forEach((item2: any, index2: any) => {
          if (item1.code === item2) {
            taxAmountUkArr.push(item1.name);
          }
        });

        netTaxAmountInIndexes?.forEach((item3: any, index3: any) => {
          if (item1.code === item3) {
            netAmountUkArr.push(item1.name);
          }
        });
      });
      taxAmountUk = taxAmountUkArr.join(', ');
      netAmountUk = netAmountUkArr.join(', ');
    }

    let payload: TaxPayload = {
      name: formState.name.value,
      description: formState.description.value,
      defaultMemoUk: formState.defaultMemo?.value,
      percent: Number(formState.percent.value),
      taxCode: formState.taxCode.value,
      type: formState.type.value,
      accountName: formState.taxAccount.value?.label,
      accountCode: formState.taxAccount.value?.value,
      status: STATUS_TYPE.active,
      isTaxGroup: false,
      additionalTaxIn: formState.isAdditional,
      flatRateSchemeUk: formState.flatRateSchemeUk,
      effectiveStartDate:
        effectiveStartDate === null
          ? null
          : DateFormatService.getDateStrFromDate(
              effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ),

      effectiveEndDate:
        effectiveEndDate === null
          ? null
          : DateFormatService.getDateStrFromDate(
              effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ),
      taxAmountUk: taxAmountUk,
      netAmountUk: netAmountUk
    };

    if (isNaN(Number(formState.percent.value))) {
      setValidated(false);
      let updatedState = formState;
      updatedState.percent.errorMsg = t(
        `SETTINGS.TAX.TOAST.PLEASE_ENTER_NUMBER_IN_PERCENT_FIELD`
      );
      setFormState({ ...updatedState });
      return;
    }
    return payload;
  };
  const onInputChange = (event: any, type: string) => {
    let updatedState = formState;
    switch (type) {
      case 'name':
        updatedState.name.value = event;
        updatedState.name.defaultValue = event;

        break;
      case 'percent':
        if (Number(event) < 0) {
          setValidated(false);

          updatedState.percent.errorMsg = t(
            `SETTINGS.TAX.TOAST.PLEASE_ENTER_NUMBER_IN_PERCENT_FIELD`
          );
        } else if (isNaN(Number(event))) {
          setValidated(false);

          updatedState.percent.errorMsg = t(`SETTINGS.TAX.TOAST_POSTIVE_VALUE`);
        } else {
          updatedState.percent.value = event;
          updatedState.percent.defaultValue = event;
        }

        break;
      case 'taxCode':
        setValidated(false);
        updatedState.taxCode.errorMsg = t(`SETTINGS.TAX.ERROR_TAX_CODE`);
        updatedState.taxCode.value = event;
        updatedState.taxCode.defaultValue = event;

        break;
      case 'description':
        updatedState.description.value = event.target.value;
        updatedState.description.defaultValue = event.target.value;
        break;
      case 'defaultMemo':
        updatedState.defaultMemo.value = event.target.value;
        updatedState.defaultMemo.defaultValue = event.target.value;
        break;
    }
    setFormState({ ...updatedState });
  };

  const onSelectChange = (value: any, type: string) => {
    let updatedState = formState;
    if (type === 'account') {
      updatedState.taxAccount.value.value = value.value;
      updatedState.taxAccount.value.label = value.label;
    }
    if (type === 'type') {
      updatedState.type.value.value = value.label;
      updatedState.type.defaultValue = value.label;
    }
    setFormState({ ...updatedState });
  };

  const newTaxSearchSelectStyle1 = {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      left: '50%',
      transform: 'translateX(-50%)'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      textAlign: 'left',
      fontSize: '0.875rem',
      backgroundColor: state.isSelected
        ? 'var(--btn-bg-color)'
        : state.isFocused
        ? 'var(--main-color-hover)'
        : null
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: '1px solid rgb(235, 235, 235)',
      borderRadius: 5,
      minHeight: 36,
      maxHeight: 36,
      boxShadow: 'none',
      border: '1px solid rgb(235, 235, 235)',
      '&:hover, &:focus, &:active, &:focus-visible': {
        border: '1px solid rgb(235, 235, 235)',
        boxShadow: 'none'
      },
      backgroundColor: '#f4f4f6'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      display: 'none'
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'flex-start',
      paddingRight: '5px',
      paddingLeft: '5px'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      top: '45%',
      transform: 'translateY(-50%)'
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0
    })
  };

  return (
    <div className="column parent-width p-2">
      <div className="row parent-width pb-5 gap-2">
        <DKInput
          title={t(`SETTINGS.TAX.NAME`)}
          required={true}
          canValidate={!validated ? true : false}
          errorMessage={formState.name.errorMsg}
          placeholder={t(`SETTINGS.TAX.NAME`)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState.name.value}
          onChange={(text: any) => {
            onInputChange(text, 'name');
          }}
        />
        <DKInput
          title={t(`SETTINGS.TAX.PERCENT`)}
          canValidate={!validated ? true : false}
          errorMessage={formState.percent.errorMsg}
          placeholder={t(`SETTINGS.TAX.PERCENT`)}
          required={true}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState.percent.value}
          onChange={(text: any) => {
            onInputChange(text, 'percent');
          }}
        />
      </div>
      <div className="row parent-width pb-5 gap-2">
        <DKInput
          title={t(`SETTINGS.TAX.TAX_CODE`)}
          required={true}
          canValidate={!validated ? true : false}
          errorMessage={formState.taxCode.errorMsg}
          placeholder={t(`SETTINGS.TAX.TAX_CODE`)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState.taxCode.value}
          onChange={(text: any) => {
            onInputChange(text, 'taxCode');
          }}
        />
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={t(`SETTINGS.TAX.TYPE`)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={false}
          value={formState?.type?.value ?? null}
          canValidate={false}
          className=""
          onChange={(obj: any) => {
            let updatedState = formState;
            updatedState.type.value = obj.label;
            setFormState({ ...updatedState });
          }}
          dropdownConfig={{
            title: '',
            allowSearch: false,
            searchableKey: '',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: null,
            data: formState.type?.options ?? [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.label}`} />;
            }
          }}
        />
      </div>
      <div className="row parent-width align-items-centre pb-5">
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={t(`SETTINGS.TAX.TAX_ACCOUNT`)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={false}
          value={formState?.taxAccount?.value?.label ?? null}
          canValidate={!validated ? true : false}
          className=""
          onChange={(obj: any) => {
            let updatedState = formState;
            updatedState.taxAccount.value = obj;
            setFormState({ ...updatedState });
          }}
          dropdownConfig={{
            title: '',
            allowSearch: false,
            searchableKey: '',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: null,
            data: formState.taxAccount.options ?? [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.label}`} />;
            }
          }}
        />
        {tenantDetails?.country === COUNTRY_CODES.IN && (
          <div className="parent-width ml-3">
            <DKLabel
              className="ml-1 mt-0 text-transparent"
              text={t(`SETTINGS.TAX.DESCRIPTION`)}
            />
            <div className="p-1 pt-3">
              <DKCheckMark
                title={t(`SETTINGS.TAX.IS_ADDITIONAL_TAX`)}
                isSelected={formState.isAdditional}
                className=""
                onClick={() => {
                  let updatedState = formState;
                  updatedState.isAdditional = !updatedState.isAdditional;
                  setFormState({ ...updatedState });
                }}
              />
            </div>
          </div>
        )}
        {tenantDetails?.country === COUNTRY_CODES.UK && (
          <div className="parent-width ml-3">
            <DKLabel
              className="ml-1 mt-0 text-transparent"
              text={t(`SETTINGS.TAX.DESCRIPTION`)}
            />
            <div className="p-1 pt-3">
              <DKCheckMark
                title={'Outside of flat rate scheme'}
                isSelected={formState.flatRateSchemeUk}
                className=""
                onClick={() => {
                  let updatedState = formState;
                  updatedState.flatRateSchemeUk =
                    !updatedState.flatRateSchemeUk;
                  setFormState({ ...updatedState });
                }}
              />
            </div>
          </div>
        )}
      </div>
      {/* uk compliance */}
      {tenantDetails?.country === COUNTRY_CODES.UK && (
        <div className="row parent-width mb-2 gap-2">
          <DKInput
            className={''}
            value={taxAmountInIndexes ?? []}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            displayKey="name"
            title="Show tax amount in"
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            canValidate={false}
            onChange={(value: any) => {
              setTaxAmountInIndexes(value);
            }}
            dropdownConfig={{
              className: '',
              title: '',
              style: {},
              allowSearch: false,
              searchableKey: '',
              multiSelect: true,
              selectedIndexes: [],
              checkMarkColor: 'bg-blue',
              renderer: (index: number, obj: any) => {
                return <DKLabel text={obj.name} />;
              },
              data: UK_TAX
            }}
          />
          <DKInput
            className={''}
            value={netTaxAmountInIndexes ?? []}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            displayKey="name"
            title="Show net amount before tax in"
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            canValidate={false}
            onChange={(value: any) => {
              setNetTaxAmountInIndexes(value);
            }}
            dropdownConfig={{
              className: '',
              title: '',
              style: {},
              allowSearch: false,
              searchableKey: '',
              multiSelect: true,
              selectedIndexes: [],
              checkMarkColor: 'bg-blue',
              renderer: (index: number, obj: any) => {
                return <DKLabel text={obj.name} />;
              },
              data: UK_TAX
            }}
          />
        </div>
      )}
      <div className="parent-width ml-1 parent-height mt-1">
        <DKLabel
          className="ml-0 mt-0 mb-1"
          text={t(`SETTINGS.TAX.DESCRIPTION`)}
        />
        <textarea
          rows={2}
          className="mt-0 text-black parent-width border rounded border-gray-300 w-full text-sm tracking-normal placeholder-gray-600::placeholder focus:outline-none focus:ring-2 focus:ring-blue-500 pl-m pr-m pt-s pb-s mx-0"
          style={{ height: 120, backgroundColor: '#f4f4f6' }}
          onChange={(e) => {
            onInputChange(e, 'description');
          }}
          value={formState.description.value}
          defaultValue={formState.description.defaultValue}
        />
      </div>
      {/* for UK */}
      {tenantDetails?.country === COUNTRY_CODES.UK && (
        <div className="parent-width ml-1 parent-height mt-1">
          <DKLabel
            className="ml-0 mt-0 mb-1"
            text={'Default memo if this tax code is used'}
          />
          <textarea
            rows={2}
            className="mt-0 text-black parent-width border rounded border-gray-300 w-full text-sm tracking-normal placeholder-gray-600::placeholder focus:outline-none focus:ring-2 focus:ring-blue-500 pl-m pr-m pt-s pb-s mx-0"
            style={{ height: 120, backgroundColor: '#f4f4f6' }}
            onChange={(e) => {
              onInputChange(e, 'defaultMemo');
            }}
            value={formState.defaultMemo.value}
            defaultValue={formState.defaultMemo.defaultValue}
          />
        </div>
      )}
      <div className="row parent-width align-items-centre mb-2 mt-1">
        <DKInput
          titleStyle={{ color: 'black', fontSize: 12 }}
          title="Effective Start Date"
          className={'text-gray-800 mr-2'}
          value={effectiveStartDate}
          type={INPUT_TYPE.DATE}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
          onChange={(startDate: any) => {
            setEffectiveStartDate(startDate);
          }}
          readOnly={isDateReadOnly}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          datePickerConfig={{
            isDateRange: false
          }}
        />
        <DKInput
          titleStyle={{ color: 'black', fontSize: 12 }}
          title="Effective End Date"
          className={'text-gray-800 ml-2'}
          value={effectiveEndDate}
          type={INPUT_TYPE.DATE}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
          onChange={(endDate: any) => {
            setEffectiveEndDate(endDate);
          }}
          readOnly={isDateReadOnly}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          datePickerConfig={{
            isDateRange: false
          }}
        />
      </div>
    </div>
  );
};

export default AddTax;
