import React, { Component } from "react";
import "../../../index.css";
import { US_CHEQUE_HEIGHT, FONT_SIZE } from "../../../Constants/Constants";
import Utility, { getCapitalized, getPageHeight, getVW } from "../../../Utilities/Utility";
import "../css/Templates.css";
import DraggableComponent from "../../../Components/DocumentDesigner/DocumentDesignerScreen/ui/DraggableComponent";
import ChequeParser, { ChequeDetailType } from "../../../Helper/DocumentModuleParser/ChequeParser";
import { HTMLTag } from "../../../Utilities/HTMLTag";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import DKChecktableSection from "../../../DKUILibrary/DkCheckTableSection";
import AddressParser, { AddressType } from "../../../Helper/AddressParser";
import NumberUtility from "../../../Utilities/NumberUtility";

export default class USChequeDesignTemplate extends Component {
    currency = NumberUtility.getCurrency(this.props.data) ? NumberUtility.getCurrency(this.props.data) : '';
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data
        };
    }

    componentDidMount() {
        this.setState({
            chequeData: ChequeParser.parseChequeFields(this.props.data.lineItems)
        });
    }

    componentWillReceiveProps(nextProp) {
        this.currency = NumberUtility.getCurrency(this.props.data) ? NumberUtility.getCurrency(this.props.data) : '';

        const defaultFields = TemplateSettingsManager.getChequeDetailsList();
        const chequeData = [...this.state.chequeData].map((c, index) => {
            if (c.data && Array.isArray(this.props.data.lineItems) && this.props.data.lineItems[index]) {
                c.data = {
                    ...c.data,
                    ...this.props.data.lineItems[index]
                };
            }

            const cheque = { ...c };
            cheque.fields = cheque.fields.map((f) => {
                const df = defaultFields.find((df) => df.type === f.type);
                if (df) {
                    f.isSelected = df.isSelected;
                    f.position = df.position;
                }

                return { ...f };
            });

            return cheque;
        });

        this.setState({
            chequeData: chequeData,
            data: nextProp.data
        });
    }

    getFontStyleProp() {
        return {
            fontFamily: 'calibri san-serif',
            textAlign: "left",
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
        };
    }

    updateChequeFieldState(cheque, field, state) {
        const chequeData = [...this.state.chequeData].map((c) => {
            if (c.id === cheque.id) {
                c.fields = c.fields.map((f) => {
                    if (f.type === field.type) {
                        return { ...f, ...state };
                    }
                    return { ...f };
                });
            }

            return { ...c };
        });

        this.setState({
            chequeData: chequeData
        }, () => {
            if (Array.isArray(chequeData) && Array.isArray(chequeData[0]?.fields)) {
                TemplateSettingsManager.updateChequeDetailsList(chequeData[0].fields)
            }
        });
    }

    updateChequeDetailsState(cheque, detail, state) {
        const chequeData = [...this.state.chequeData].map((c) => {
            if (c.id === cheque.id) {
                c.details = c.details.map((d) => {
                    if (d.id === detail.id) {
                        return { ...d, ...state };
                    }
                    return { ...d };
                });
            }

            return { ...c };
        });

        this.setState({
            chequeData: chequeData
        }, () => {
            if (Array.isArray(chequeData) && Array.isArray(chequeData[0]?.details)) {
                TemplateSettingsManager.updateChequeDetailsConfig(chequeData[0].details)
            }
        });
    }

    getAddressFontStyle(field) {
        
        return {
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
            ...Utility.getCheckFontSizeProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale, true, field)
        }
    }

    getFieldFontStyle(field) {
        
        return {
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
            ...Utility.getCheckFontSizeProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale, true, field)
        }
    }

    renderFieldValue(cheque, field) {
        let value = "";
        switch (field.type) {
            case ChequeDetailType.date:
                value = cheque.data.date;
                break;
            case ChequeDetailType.amount:
                value = Utility.toCurrencyFormat(cheque.data.amount, `${this.state.data.showCheckCurrency ? this.currency:""} `);
                break;
            case ChequeDetailType.contactAddress:
                const addressObj = { ...this.state.data, clientBillToAddressObj: cheque.data.contactAddress };
                value = `${AddressParser.getVisibleAddressFields(AddressType.billTo, addressObj, this.state.data)}`;
                break;
            case ChequeDetailType.amountInWords:
                value = getCapitalized(cheque.data.amountInWords);
                break;
            default:
                value = cheque.data[field.code];
                break;
        }

        if (field.type === ChequeDetailType.contactAddress) {
            return (
                <>
                {/* Contact */}
                    <div className="RowDiv" style={{ whiteSpace: "pre-line" }}>
                        <text style={{ ...this.getFontStyleProp(), ...this.getFieldFontStyle(ChequeDetailType.payTo) }} dangerouslySetInnerHTML={{ __html: `${cheque.data.payTo}<br>` }} />
                    </div>
                {/* Address  */}
                    <div className="RowDiv" style={{ whiteSpace: "pre-line" }}>
                    <text style={{ ...this.getFontStyleProp(), ...this.getAddressFontStyle('address') }} dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                </>
            );
        } else {
            return (
                <text style={{ ...this.getFontStyleProp() }}>
                    {value}
                </text>
            );
        }
    }

    renderChequeDetail(cheque, detail) {
        return (
            <DraggableComponent
                key={`${cheque.id}_${detail.id}`}
                width="100%"
                bounds="parent"
                disabled={!!TemplateSettingsManager?.getPrintInfo()}
                isSelected={true}
                defaultPosition={detail.defaultPosition}
                position={ChequeParser.getRelativePositionInPX(detail.position, detail.defaultPosition, this.state.data.printScale)}
                onStop={(e, data) => {
                    const position = ChequeParser.getRelativePositionInPerc({ x: data.lastX, y: data.lastY }, detail.defaultPosition);
                    this.updateChequeDetailsState(cheque, detail, { position: position });
                }}
            >
                <DKChecktableSection details={detail} elementData={cheque.data} data={this.state.data} />
            </DraggableComponent>
        );
    }

    renderCheque(cheque) {
        return (
            <div key={`${cheque.id}`} className="break-inside-avoid parent-width" style={{ minHeight: getPageHeight() }}>
                <div
                    className="parent-width"
                    style={{
                        height: getVW(Utility.convertToPrintScale(US_CHEQUE_HEIGHT, this.state.data.printScale)),
                        border: TemplateSettingsManager?.getPrintInfo() ? "none" : "1px dashed rgba(128, 128, 128, 0.4)"
                    }}
                >
                    {cheque.fields.map((field) => {
                        return (
                            <DraggableComponent
                                key={`${cheque.id}_${field.type}`}
                                bounds="parent"
                                disabled={!!TemplateSettingsManager?.getPrintInfo()}
                                isSelected={field.isSelected}
                                defaultPosition={field.defaultPosition}
                                position={ChequeParser.getRelativePositionInPX(field.position, field.defaultPosition, this.state.data.printScale)}
                                onStop={(e, data) => {
                                    const position = ChequeParser.getRelativePositionInPerc({ x: data.lastX, y: data.lastY }, field.defaultPosition);
                                    this.updateChequeFieldState(cheque, field, { position: position });
                                }}
                            >
                                {this.renderFieldValue(cheque, field)}
                            </DraggableComponent>
                        );
                    })}
                </div>
                {this.state.data.showCheckTable && cheque.details.map((detail) => this.renderChequeDetail(cheque, detail))}
            </div>
        );
    }

    render() {
        return (
            <div className='DocumentHolder' style={{ minHeight: getPageHeight() }}>
                <div id={HTMLTag.CHEQUE_DESIGNER} className="parent-width" style={{ minHeight: getPageHeight() }}>
                    {Array.isArray(this.state.chequeData) && this.state.chequeData.map((cheque) => this.renderCheque(cheque))}
                </div>
            </div>
        );
    }
}
