import { forwardRef, useEffect, useState } from 'react';
import { CustomInputProps, CustomInputState } from '../../Models/CustomInput';
import InputLabel from '../InputLabel/InputLabel';

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (props, ref) => {
    const initialState: CustomInputState = {
      hasError: props.hasError ? props.hasError : false,
      errorMsg: props.errorMsg ? props.errorMsg : 'Cannot be left empty',
      placeholder: props.placeholder ? props.placeholder : '',
      labelName: props.labelName ? props.labelName : '',
      isRequired: props.isRequired ? props.isRequired : false,
      isDisabled: props.isDisabled ? props.isDisabled : false,
      value: props.defaultValue ? props.defaultValue : '',
      labelClassName: props.labelClassName ? props.labelClassName : ''
    };
    const [inputState, setInputState] =
      useState<CustomInputState>(initialState);
    const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        const updatedState = inputState;
        updatedState.value = e.target.value;
        setInputState({ ...updatedState });
        props.onChange(e);
      }
    };

    const inputFocused = (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.onFocus) {
        props.onFocus(e);
      }
    };

    const inputClicked = (e: any) => {
      if (props.onClick) {
        props.onClick(e);
      }
    };

    const inputBlurred = (e: any) => {
      if (props.onBlur) {
        props.onBlur(e);
      }
    };

    const handleKeyDownPress = (event: any) => {
      var e = event;
      if (e !== undefined || e !== null) {
        if (e.type === 'keydown' && props.onKeyDown) {
          props.onKeyDown(e);
        }
      }
    };
    useEffect(() => {
      const updatedState = inputState;
      updatedState.value = props.defaultValue
        ? props.defaultValue
        : initialState.value;
      updatedState.errorMsg = props.errorMsg
        ? props.errorMsg
        : 'This is for alignment';
      updatedState.isDisabled = props.isDisabled;
      setInputState({ ...updatedState });
      return () => {
        //   cleanup
      };
    }, [props.defaultValue, props.isDisabled]);
    return (
      <div className="flex flex-col">
        <InputLabel
          text={inputState.labelName + (props.isRequired ? '*' : '')}
          className={inputState.labelClassName}
        />
        <input
          className={
            'border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-2.5 ' +
            (props.hasError
              ? 'focus:outline-none focus:ring-2 focus:ring-red-500 border-red-400'
              : 'focus:outline-none focus:ring-2 focus:ring-blue-500')
          }
          style={{ minHeight: '37px', ...props.style }}
          placeholder={inputState.placeholder}
          onChange={inputChanged}
          onFocus={inputFocused}
          onClick={inputClicked}
          onBlur={inputBlurred}
          onKeyDown={handleKeyDownPress}
          value={inputState.value}
          disabled={inputState.isDisabled}
          ref={ref}
        />
        <p
          className={
            'text-xs mx-1 mt-1 ' +
            (props.hasError ? 'text-red-600' : 'text-transparent')
          }
        >
          {inputState.errorMsg}
        </p>
      </div>
    );
  }
);

export default CustomInput;
