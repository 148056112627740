import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getIsBill, getIsBillOrInvoice, getIsExpenseBill, getVW } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { FooterRow } from "../FooterRow"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableHeader } from "../TableHeader"
import { TableOption } from "../TableOption"
import { AccountTableItem } from "./AccountTableItem"
import { AccountTableWidth } from "./AccountTableWidth"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType } from "../../../Constants/Constants"
import ComponentManager from "../../../Manager/ComponentManager"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { getSortedHeader, getSortedLineItems, getTablePadding } from "../TableUtility"
import TableFooterUtility from "../../../Utilities/TableFooterUtility"
import AppManager from "../../../Manager/AppManager"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"

import { AccountTableGroupHeaderColumn } from "./AccountTableGroupHeaderColumn"

export class AccountTable {
    static groupHeaderIndexList: number[]
    static footerColumnList: HeaderColumn[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = canUserEdit
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        newHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }
    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }
        var sortedHeader = getSortedHeader(columnOption);
        var docType = 'account'
        if (AppManager.getDocumentType() !== undefined) {
            if (getIsBill(AppManager.getDocumentType())) {
                docType = 'expenseBill'
            }
        }
        var footerList = TableFooterUtility.getFooterListByType(docType)
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForAccount()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, rawData.printScale)
                    newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                    headerColumnList.push(newHeaderColumn)
                }
            });
        }

        var width = columnOption[0].width
        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var accountTableWidth = this.getHeaderColumnWidth(headerColumnList)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, accountTableWidth, element.code) + '%'
            });

            newColumnOption.forEach((element: TableHeader) => {
                element.width = this.getHeaderItemColumnWidth(element.type, accountTableWidth, element.code) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name)
                if (items !== undefined && items !== null) {
                    if (items.length > 0) {
                        var width = items[0].width
                        if (width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }

        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, footerList)
        return headerColumnList
    }

    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, defaultTableColumnAlignmentList: any, tableColumnAlignmentList: any, tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.code = tableHeader.code
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if(columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if (tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAlignment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAlignment.alignment
                }
            }
        }

        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[]) {
        var showWidthConfig = new AccountTableWidth()
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, accountTableWidth: AccountTableWidth, code?: string): number {
        var width = 0
        var itemWidth = 0
        switch (type) {
            case TableColumnPopupType.accountName:
                width = accountTableWidth.name
                break;
            case TableColumnPopupType.description:
                width = accountTableWidth.description
                break;
            case TableColumnPopupType.amount:
                width = accountTableWidth.amount
                break;
            case TableColumnPopupType.tax:
                width = accountTableWidth.tax
                break;
            case TableColumnPopupType.taxAmount:
                width = accountTableWidth.taxAmount
                break;
            case TableColumnPopupType.paymentAmount:
                width = accountTableWidth.paymentAmount
                break;
            case TableColumnPopupType.accountCustomField:
            case TableColumnPopupType.productCustomField:
                width = accountTableWidth.productCustomField
                if(code !== undefined) {
                    itemWidth = accountTableWidth.getProductCustomFieldWidthByCode(code)
                    if(itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            default:
                break;
        }

        return width
    }

    static getIsShowColumn(headerColumnList: HeaderColumn[], name: string) {
        var filterValue = headerColumnList.filter(x => x.name === name)
        if (filterValue === undefined || filterValue === null) {
            return false
        }
        if (filterValue.length === 0) {
            return false
        }
        return filterValue[0].columnOption.isShowColumn
    }

    static getData(documentData: any, tableInfo?: any) {
        var lineItems: string[][] = []
        var dummyData = [
            [getLocalisedText('account_name'), getLocalisedText("account_description"), '0.00', '0.00', '0.00', '0.00'],
            [getLocalisedText('account_name'), getLocalisedText("account_description"), '0.00', '0.00', '0.00', '0.00'],
            [getLocalisedText('account_name'), getLocalisedText("account_description"), '0.00', '0.00', '0.00', '0.00'],
        ]
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }
            var sortedItems = getSortedLineItems(documentData.lineItems)
            var groupItems = this.getGroupData(sortedItems, documentData, currency,tableInfo)
            groupItems.forEach((element: any) => {
                lineItems.push(element)
            });
            
            return lineItems
            // return []
        }
    }

    static getGroupData(items: any, documentData: any, currency: string, tableInfo?: any) {
        var lineItems: string[][] = []
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }
        if(grouping === 'none') {
            items.forEach((element: any) => {
                lineItems.push(new AccountTableItem(element,  currency, documentData).toString(tableInfo))
            });
            return lineItems
        }
        var attributes: string[] = []
        this.groupHeaderIndexList = []
        items.forEach((element: any) => {
            var label = ''
            if (element.customFields !== undefined && element.customFields !== null) {
                label = this.getAttributeString(element.customFields)
            }
            if(!attributes.includes(label)) {
                attributes.push(label)
            }
        });

        attributes = attributes.sort((a: string, b: string) => a.localeCompare(b))
        //split the item based om attr
        var attributesGroupedItems: any[] = []
        attributes.forEach((element: any) => {
            var groupedItems: any[] = []
            items.forEach((item: any) => {
                if (this.getAttributeString(item.customFields) === element) {
                    groupedItems.push(item)
                }
            });
            if (element === '') {
                element = 'none'
            }
            var val = {
                key: element,
                list: groupedItems
            }
            attributesGroupedItems.push(val)
        });

        var itemCount = 1
        attributesGroupedItems.forEach((element) => {
            if (element.list !== undefined && element.list !== null) {
                if (element.list.length > 0) {
                    this.groupHeaderIndexList.push(lineItems.length)
                    lineItems.push(new AccountTableGroupHeaderColumn(element.key).toString())
                }
                element.list.forEach((element: any) => {
                    var newElement = element
                    newElement.lineNumber = itemCount
                    itemCount += 1
                    lineItems.push(new AccountTableItem(newElement,  currency,documentData).toString(documentData.type,tableInfo))
                });
            }
        });
        return lineItems
    }

    static getGroupHeaderIndexList() {
        return this.groupHeaderIndexList
    }

    static getAttributeString(customFields: any) {
        var label = ''
        if(customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                customFields.forEach((element: any, index: number) => {
                    label = label + element.label + ' - ' + element.value
                    if(index < customFields.length-1) {
                        label = label + ', '
                    }
                });
            }
        }
        return label
    }
    static getDefaultItemColumn(tableStyle: any, index: number, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index % 2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight =getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.fontSize = FONT_SIZE.regular
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            column.forEach(element => {
                var newItemColumn = this.getDefaultItemColumn(tableStyle, index, rawData.printScale)
                newItemColumn.value = element
                rowList.push(newItemColumn)
            });
            itemColumnList.push(rowList)
        });

        return itemColumnList
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if (data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any, index: number) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length - 1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.lineNumber = index + 1
        });
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static parseTaxBreakdown(footerColumnList: HeaderColumn[], data: any, columnOption: any, tableStyle: any, printScale: number) {
        var newFooterColumnList = footerColumnList
        data.taxBreakdown.forEach((tax: { taxName: string }) => {
            if (tax.taxName !== 'Rounding Off') {
                newFooterColumnList.push(this.setFooterHeader(tableStyle, tax.taxName, tax.taxName, true, printScale))
            }
        });

        return newFooterColumnList
    }

    static getFooterColumn(data: any, columnOption: any, tableStyle: any, printScale: number) {
        var newFooterColumn: HeaderColumn[] = []
        if (columnOption === undefined || columnOption === null) {
            return
        }

        let subTotalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.subTotalAmount)
        if (subTotalRow !== undefined) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.subTotalAmount, subTotalRow.name, subTotalRow.isSelected, printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, 'subtotal', 'subtotal', true, printScale))
        }

        let total_tax_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.totalTaxAmount)
        if (total_tax_column_data_list.length > 0 ) {
            var total_tax_column_data = total_tax_column_data_list[0]
            if (total_tax_column_data.type === TableColumnPopupType.totalTaxAmount) {
                if (!ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.totalTaxAmount, total_tax_column_data.name, total_tax_column_data.isSelected, printScale))
                } else if (total_tax_column_data.isSelected && ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn = this.parseTaxBreakdown(newFooterColumn, data, columnOption, tableStyle, printScale)
                }
            }
        }

        let rounding_off_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.roundingOff)
        if (rounding_off_column_data) {
            if (rounding_off_column_data.isSelected) {
                newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.roundingOff, rounding_off_column_data.name, rounding_off_column_data.isSelected, data.printScale))
            }
        }

        let totalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.totalAmount)
        if (totalRow !== undefined) {
            if (totalRow.isSelected) {
                if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                    newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, totalRow.name, printScale))
                }
            }
        }
        else {
            if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, 'total', printScale))
            }
        }

        if(AppManager.getDocumentType() !== undefined) {
            if (getIsBill(AppManager.getDocumentType())) {
                let outstandingBalance = columnOption.find((x: any) => x.type === TableColumnPopupType.outstandingBalance)
                if (outstandingBalance !== undefined) {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalance, outstandingBalance.name, outstandingBalance.isSelected, printScale))
                }
                else {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalance, 'outstanding_balance', false, printScale))
                }

                let outstandingBalanceIncludesCurrent = columnOption.find((x: any) => x.type === TableColumnPopupType.outstandingBalanceIncludesCurrent)
                if (outstandingBalanceIncludesCurrent !== undefined) {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalanceIncludesCurrent, outstandingBalanceIncludesCurrent.name, outstandingBalanceIncludesCurrent.isSelected, printScale))
                }
                else {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalanceIncludesCurrent, 'outstanding_balance_includes_current', false, printScale))
                }
            }
        }

        if (getIsBillOrInvoice(data.type)) {
            //for expense bill use
            if (ComponentManager.getIsContainsPayments(data)) {
                data.payments.forEach((element: any, index: number) => {
                    var type = getLocalisedText('payment_footer')
                    if (element.documentType !== undefined) {
                        if (element.documentType.toLowerCase() !== 'make_payment' && element.documentType.toLowerCase() !== 'receive_payment') {
                            type = getLocalisedText(element.documentType.toLowerCase())
                        }
                    }

                    var documentCode = element.documentCode
                    if (element.linkedDocumentSequence && element.linkedDocumentSequence !== null) {
                        documentCode = element.linkedDocumentSequence
                    }
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.paymentFooter, type + ', ' + documentCode, true, printScale, index))
                });
            }

            if (ComponentManager.getIsContainsPayments(data)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.outstandingAmount, 'outstanding', printScale))
            }
        }

        let amount_in_words_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.amountInWords)
        if (amount_in_words_column_data_list.length > 0) {
            let amount_in_words_column_data = amount_in_words_column_data_list[0]
            if (amount_in_words_column_data.isSelected) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amountInWords, amount_in_words_column_data.name, printScale))
            }
        }

        //add handling to remove duplicated data 
        var newNonDuplicatedFooterColumnList: any[] = []
        var roundingOffKey = "Rounding Off";
        newFooterColumn.forEach((element) => {
            if(element.name === roundingOffKey) {
                var roundingOffItem = newNonDuplicatedFooterColumnList.find(x => x.name === roundingOffKey)

                if(roundingOffItem === undefined) {
                    newNonDuplicatedFooterColumnList.push(element)
                }
            }
            else {
                newNonDuplicatedFooterColumnList.push(element);
            }
        });

        this.footerColumnList = newNonDuplicatedFooterColumnList;
        return newNonDuplicatedFooterColumnList;
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        var dummyData = ['0.00', '0.00', '0.00', '$ 0.00']
        var currency = ''
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return dummyData
            }
            var roundingOffValue = 0

            if (documentData.roundingOff && documentData.roundingOff !== null) {
                roundingOffValue = documentData.roundingOff
            }
            
            if (ComponentManager.getIsContainTaxBreakdown(documentData)) {
                this.footerColumnList.forEach(element => {
                    var value = ''
                    documentData.taxBreakdown.forEach((tax: { taxName: string, taxAmount: any, taxId: string }) => {
                        if (element.name === tax.taxName) {
                            value = Utility.toCurrencyFormat(tax.taxAmount, currency)
                        }
                    });
                    if (value === '') {
                        footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
                    }
                    else {
                        footerValue.push(value)
                    }
                });
            }
            else {
                this.footerColumnList.forEach(element => {
                    footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
                });
            }

            return footerValue
        }
    }

    static getFooterValue(element: HeaderColumn, data: any, roundingOffValue: number, currency: string) {
        switch (element.name) {
            case 'subtotal':
                return Utility.toCurrencyFormat(this.getSubtotalAmount(data), currency)
            case TableColumnPopupType.totalTaxAmount:
                return Utility.toCurrencyFormat(this.getTotalTaxAmount(data), currency)
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.getTotalAmount(data, roundingOffValue), currency)
            case TableColumnPopupType.paymentFooter:
                var payment = ''
                if (ComponentManager.getIsContainsPayments(data)) {
                    if (element.index !== undefined) {
                        if(data.payments[element.index] !== undefined && data.payments[element.index] !== null && data.payments[element.index]) {
                            currency = Utility.getCurrencySymbolFromCode(data.payments[element.index].currency)
                        }
                        payment = Utility.toCurrencyFormat(data.payments[element.index].amount, currency)
                    }
                }
                return payment
            case TableColumnPopupType.roundingOff:
                var roundingOff = 0
                if (data.roundingOff !== undefined) {
                    roundingOff = data.roundingOff
                }
                return Utility.toCurrencyFormat(roundingOff, currency)
            case TableColumnPopupType.amountInWords:
                return data.amountInWords ? data.amountInWords : ''
            case TableColumnPopupType.outstandingAmount:
                var due = Utility.toCurrencyFormat(this.getOutstandingAmount(data, roundingOffValue), currency)
                if (data.due !== undefined) {
                    due = Utility.toCurrencyFormat(data.due, currency)
                }
                return due
            case TableColumnPopupType.outstandingBalance:
                var outstandingBalance = ''
                if (data.outstandingBalance !== undefined) {
                    outstandingBalance = Utility.toCurrencyFormat(data.outstandingBalance, currency)
                }
                return outstandingBalance
            case TableColumnPopupType.outstandingBalanceIncludesCurrent:
                var outstandingBalanceIncludesCurrent = ''
                if (data.outstandingBalanceIncludesCurrent !== undefined && data.outstandingBalanceIncludesCurrent !== null) {
                    outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(data.outstandingBalanceIncludesCurrent, currency)
                }
                return outstandingBalanceIncludesCurrent
            default:
                return ''
        }
    }


    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any, rawData: any) {
        var keyValue: FooterRow[] = []
        var footerColumn = this.getFooterColumn(data, tableColumnOptions, tableStyle, rawData.printScale)
        var footerData = this.getFooterData(data)

        if (footerData === undefined || footerData === null) {
            return []
        }
        if (footerData.length === 0) {
            return []
        }
        if (footerColumn === undefined || footerColumn === null) {
            return []
        }
        var maxSize = footerColumn.length > footerData.length ? footerColumn.length : footerData.length

        for (let index = 0; index < maxSize; index++) {
            if (footerColumn.length > index) {
                var key = footerColumn[index]
                if (key !== undefined && key !== null) {
                    var newFooterRow = new FooterRow()
                    newFooterRow.header = key
                    newFooterRow.isSelected = key.isShowRecord
                    if (footerData.length > index) {
                        var value = footerData[index]
                        if (value !== undefined && value !== null) {
                            newFooterRow.value = value
                        }
                        keyValue.push(newFooterRow)
                    }
                }
            }
        }
        if (data.footerRow !== undefined) {
            var newArray: FooterRow[] = []
            data.footerRow.forEach((newFooter: FooterRow) => {
                keyValue.forEach(element => {
                    if (newFooter.header.name === element.header.name) {
                        var footer = new FooterRow()
                        footer.header = element.header
                        footer.isSelected = element.isSelected
                        footer.value = element.value
                        newArray.push(footer)
                    }
                });
            });
            return newArray
        }
        else {
            return keyValue
        }
    }

    static setFooterHeader(tableStyle: any, type: string, name: string, isShowRecord: boolean, printScale: number, index?: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.isShowRecord = isShowRecord
        newFooterColumn.index = index
        return newFooterColumn
    }
    static getDefaultFooterHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newFooterHeaderColumn = new HeaderColumn()
        newFooterHeaderColumn.type = 'string'
        newFooterHeaderColumn.style = new ColumnStyle()
        if (tableStyle.subTotalBorderColor) {
            newFooterHeaderColumn.style.borderColor = tableStyle.subTotalBorderColor
        }
        else {
            newFooterHeaderColumn.style.borderColor = tableStyle.itemBorderColor
        }

        newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor
        newFooterHeaderColumn.style.borderStyle = 'solid'
        newFooterHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newFooterHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.fontSize = FONT_SIZE.regular
        newFooterHeaderColumn.style.alignment = 'right'
        return newFooterHeaderColumn
    }

    static setLastFooterHeader(tableStyle: any, type: string, name: string, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.style.backgroundColor = tableStyle.footerBackgroundColor
        newFooterColumn.style.fontColor = tableStyle.footerTextColor
        newFooterColumn.style.borderColor = tableStyle.footerBorderColor
        newFooterColumn.style.fontWeight = 'bold'
        if (type === TableColumnPopupType.outstandingAmount || type === TableColumnPopupType.amountInWords) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    newFooterColumn.style.fontColor = undefined
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    newFooterColumn.style.fontColor = undefined
                }
            }
        }

        if (tableStyle.lastRowTopColor !== undefined) {
            newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor
            newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth
        }
        if (tableStyle.lastRowBottomColor !== undefined) {
            newFooterColumn.style.borderBottomColor = tableStyle.lastRowBottomColor
            newFooterColumn.style.borderBottomWidth = tableStyle.lastRowBottomWidth
        }

        return newFooterColumn
    }
    static getTotalTaxAmount(data: any) {
        var totalTaxAmount = 0

        if (getIsExpenseBill(data.type, data.lineItems)) {
            var productItems = data.lineItems
            productItems.forEach((element: { tax: number }) => {
                var tax = element.tax
                if (tax > 0) {
                    totalTaxAmount += tax
                }
            });
        }
        else {
            var accountItems = data.lineItems as AccountTableItem[]
            accountItems.forEach(element => {
                if (element.taxAmount !== undefined) {
                    totalTaxAmount += parseFloat(element.taxAmount)
                }
            });
        }
        return totalTaxAmount
    }

    static getSubtotalAmount(data: any) {
        var subtotalAmount = 0

        if(getIsExpenseBill(data.type, data.lineItems)) {
            var productItems = data.lineItems
            productItems.forEach((element: { amount: number, tax: any}) => {
                var tax = 0
                var lineAmount = element.amount

                if(element.tax !== undefined && element.tax !== null) {
                    if(element.tax !== '') {
                        tax = parseFloat(element.tax)
                    }
                }
                if (lineAmount > 0) {
                    lineAmount = lineAmount - tax
                    subtotalAmount += lineAmount
                }
            });
        }
        else {
            var accountItems = data.lineItems as AccountTableItem[]
            accountItems.forEach(element => {
                var amount = parseFloat(element.paymentAmount) - parseFloat(element.taxAmount)
                if (amount > 0) {
                    subtotalAmount += amount
                }
            });
        }

        return subtotalAmount
    }

    static getTotalAmount(data: any, roundingOffValue: number) {
        var totalAmount = 0

        if (getIsExpenseBill(data.type, data.lineItems)) {
            var productItems = data.lineItems
            productItems.forEach((element: { amount: number }) => {
                var amount = element.amount
                if (amount > 0) {
                    totalAmount += amount
                }
            });
        }
        else {
            var accountItems = data.lineItems as AccountTableItem[]
            accountItems.forEach(element => {
                if (element.paymentAmount !== undefined) {
                    totalAmount += parseFloat(element.paymentAmount)
                }
            });
        }

        return totalAmount + roundingOffValue
    }

    static getOutstandingAmount(data: any, roundingOffValue: number) {
        var totalAmount = this.getTotalAmount(data, roundingOffValue)
        if (data.payments === undefined || data.payments === null) {
            return totalAmount
        }
        if (data.payments.length === 0) {
            return totalAmount
        }

        return totalAmount - data.payments.map((x: { amount: any }) => x.amount).reduce((a: any, b: any) => a + b, 0)
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any, callback: any) {
        if (columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.totalTaxAmount)
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if (element.isSelected) {
                var item = element
                var width = element.width
                if(element.type === TableColumnPopupType.totalTaxAmount) {
                    item.width ='0%'
                }
                else if (element.type === header.name) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        TemplateSettingsManager.updateTablePopupInfo(newColumnOptions)
        var newData = data
        newData.tableColumnPopup = newColumnOptions
        callback(newData)
    }

    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.totalTaxAmount)
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%', ''))
                if (num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }

    static getIsShowTotal(tableStyle: any, type: string) {
        if (type === TableColumnPopupType.amount) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    return false
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    return false
                }
            }
        }
        return true
    }
}
