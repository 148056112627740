export const HTMLTag = {
    PREVIEW_HOLDER: "preview_holder",
    MAIN_HOLDER_ID: "main-holder",
    EMAIL_MESSAGE: 'emailMessage',
    PRINT_HTML: 'print_html',
    TEMPLATE: 'template',
    MAIN_TEMPLATE: 'main_template',
    ONLY_TEMPLATE: 'only_template',
    LOGO: 'logo',

    DESIGN_PAGE_MANAGER: 'design_page_manager',
    PAGE_HEADER_PREFIX: 'page_header',
    PAGE_FOOTER_PREFIX: 'page_footer',
    HEADER_AND_FOOTER_MANAGER: 'header_and_footer_manager',
    DRAFT_WATERMARK_MANAGER: 'draft_watermark_manager',
    PAGE_COUNT_TOP: 'page_count_top',

    TABLE_PREFIX: 'table_',
    TABLE_HEADER_PREFIX: 'table_header_' ,
    TABLE_HEADER_COLUMN_PREFIX: 'header_',
    TABLE_DATA_ROW_INDEX_PREFIX: 'table_data_row_index_',
    TABLE_DATA_COLUMN_INDEX_PREFIX: 'table_data_column_index',
    TABLE_FOOTER_PREFIX: 'table_footer_',
    SUB_TABLE_PREFIX: 'sub_table_',

    APPROVAL_TABLE: 'approval_table_',

    DRAGGABLE_COMPONENT_PREFIX: 'draggable_component_',
    DRAGGABLE_COMPONENT_FILE_INPUT: 'draggable_component_',
    DRAGGABLE_DIV_COMPONENT_PREFIX: 'draggable_div_component_',

    SHARE_URL_INPUT: 'share_url_input',

    TERMS_NOTE_PREFIX: 'terms_notes_',
    TERMS: 'terms',
    NOTES: 'notes',
    REMARKS: 'remarks',
    SIGNATURE: 'signature',

    QR_CODE_PREFIX: 'qr_code_',

    WATERMARK_MANAGER: 'watermark_manager',
    WATERMARK_TOP_SECTION: 'watermark_top_section',
    WATERMARK_BOTTOM_SECTION: 'watermark_bottom_section',
    WATERMARK_TEXT: 'watermark_text',
    DOCUMENT_LABEL_MANAGER: 'document_label_manager',
    DOCUMENT_LABEL_TOP_SECTION: 'document_label_top_section',
    DOCUMENT_LABEL_BOTTOM_SECTION: 'document_label_bottom_section',
    DOCUMENT_LABEL_TEXT: 'document_label_text',
    CUSTOM_FIELD_TABLE_PREFIX: 'custom_field_table_',
    E_INVOICE_PREFIX: 'e_invoice_',
    EXCHANGE_RATE: 'exchange_conversion',
    CHEQUE_DESIGNER: 'CHEQUE_DESIGNER',
    TRANSACTION_DOCUMENT_TABLE: 'transaction_document_table',

    GENERATED_TEXT: 'generated_text',

    TEMPLATE_HOLDER: 'template_holder',
    TEMPLATE_CONTENT: 'template_content',
    TEMPLATE_HEADER: 'template_header',
    TEMPLATE_LT_ABOVE: 'template_lt_above',
    TEMPLATE_LINE_TABLE: 'template_line_table',
    TEMPLATE_LT_BELOW: 'template_lt_below',
    TEMPLATE_FOOTER: 'template_footer'
}
