import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { GridState } from '../../../Models/ReduxStore';
import { RootState } from '../../Store';
import { ApiResponse } from '../../../Models/Common';
import { MachineSchedulerService } from '../../../Services/MachineSchedulerService';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchMachineSchedulers = createAsyncThunk(
  'schedulers',
  async () => {
    console.log('fetch machines');
    const response = await MachineSchedulerService.getMachineSchedulers();
    console.log('fetch machines scheduler response');
    return response;
  }
);

export const MachineSchedulerSlice = createSlice({
  name: 'MachineSchedulers',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineSchedulers.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchMachineSchedulers.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchMachineSchedulers.rejected, (state) => {
        state.status = API_STATUS.FAILED;
      });
  }
});

export const selectMachineSchedulers = (state: RootState): ApiResponse<any> =>
  state.mrpMachineScheduler.data;

export const selectMachineSchedulesStatus = (state: RootState) =>
  state.mrpMachineScheduler.status;
