import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class PickingListTableWidth {

    isInvoiceOrQuotationVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isBarcodeVisible: boolean;
    isQuantityToPickVisible: boolean;
    isQuantityForOrderVisible: boolean;
    isPickedVisible: boolean;
    isWarehouseCodeVisible: boolean;
    isWarehouseNameVisible: boolean;


    invoiceOrQuotation: number;
    code: number;
    name: number;
    barcode: number;
    quantityToPick: number;
    quantityForOrder: number;
    picked: number;
    warehouseCode: number;
    warehouseName: number;

    originalInvoiceOrQuotation: number;
    originalCode: number;
    originalName: number;
    originalBarcode: number;
    originalQuantityToPick: number;
    originalQuantityForOrder: number;
    originalPicked: number;
    originalWarehouseCode: number;
    originalWarehouseName: number;

    constructor() {
        this.isInvoiceOrQuotationVisible = true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isBarcodeVisible = true
        this.isQuantityToPickVisible = true
        this.isQuantityForOrderVisible = true
        this.isPickedVisible = true
        this.isWarehouseCodeVisible = true
        this.isWarehouseNameVisible = true


        this.invoiceOrQuotation = 15
        this.code = 10
        this.name = 15
        this.barcode = 10
        this.quantityToPick = 10
        this.quantityForOrder = 10
        this.picked = 10
        this.warehouseCode = 10
        this.warehouseName = 10

        this.originalInvoiceOrQuotation = 15
        this.originalCode = 10
        this.originalName = 15
        this.originalBarcode = 10
        this.originalQuantityToPick = 10
        this.originalQuantityForOrder = 10
        this.originalPicked = 10
        this.originalWarehouseCode = 10
        this.originalWarehouseName = 10
    }

    setIsShowWidth(columnData: any) {
        this.isInvoiceOrQuotationVisible = getIsShowTableColumn(columnData, TableColumnPopupType.invoiceOrQuotation)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isBarcodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.barcode)
        this.isQuantityToPickVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityToPick)
        this.isQuantityForOrderVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityRequiredForOrder)
        this.isPickedVisible = getIsShowTableColumn(columnData, TableColumnPopupType.picked)
        this.isWarehouseCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseCode)
        this.isWarehouseNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.warehouseName)
    }
    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.invoiceOrQuotation = this.originalInvoiceOrQuotation
        this.code = this.originalCode
        this.name = this.originalName
        this.barcode = this.originalBarcode
        this.quantityToPick = this.originalQuantityToPick
        this.quantityForOrder = this.originalQuantityForOrder
        this.picked = this.originalPicked
        this.warehouseCode = this.originalWarehouseCode
        this.warehouseName = this.originalWarehouseName
    }

    private resetElementWidth() {
        this.invoiceOrQuotation = 0
        this.code = 0
        this.name = 0
        this.barcode = 0
        this.quantityToPick = 0
        this.quantityForOrder = 0
        this.picked = 0
        this.warehouseCode = 0
        this.warehouseName = 0
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isInvoiceOrQuotationVisible) {
            totalWidth += this.invoiceOrQuotation;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isBarcodeVisible) {
            totalWidth += this.barcode;
        }
        if (this.isQuantityToPickVisible) {
            totalWidth += this.quantityToPick;
        }
        if (this.isQuantityForOrderVisible) {
            totalWidth += this.quantityForOrder;
        }
        if (this.isPickedVisible) {
            totalWidth += this.picked;
        }
        if (this.isWarehouseCodeVisible) {
            totalWidth += this.warehouseCode;
        }
        if (this.isWarehouseNameVisible) {
            totalWidth += this.warehouseName;
        }
        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isInvoiceOrQuotationVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isBarcodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityToPickVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityForOrderVisible) {
            totalSelectedElement += 1
        }
        if (this.isPickedVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isWarehouseNameVisible) {
            totalSelectedElement += 1
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isInvoiceOrQuotationVisible) {
            this.invoiceOrQuotation = this.originalInvoiceOrQuotation + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isBarcodeVisible) {
            this.barcode = this.originalBarcode + additionalWidth
        }
        if (this.isQuantityToPickVisible) {
            this.quantityToPick = this.originalQuantityToPick + additionalWidth
        }
        if (this.isQuantityForOrderVisible) {
            this.quantityForOrder = this.originalQuantityForOrder + additionalWidth
        }
        if (this.isPickedVisible) {
            this.picked = this.originalPicked + additionalWidth
        }
        if (this.isWarehouseCodeVisible) {
            this.warehouseCode = this.originalWarehouseCode + additionalWidth
        }
        if (this.isWarehouseNameVisible) {
            this.warehouseName = this.originalWarehouseName + additionalWidth
        }
    }
}