import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { AccountInfoState, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import AccountsService from '../../Services/Accounts';
import AuditLogService from '../../Services/AuditLog';
import ContactService from '../../Services/Contact';
import ImportLogService from '../../Services/ImportLog';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchImportLog = createAsyncThunk('fetchImportLog', async () => {
  const response = await ImportLogService.getImportLog();
  return response;
});

export const ImportLogSlice = createSlice({
  name: 'Import_log',
  initialState,
  reducers: {
    addImportLogColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    },
    addImportLog: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImportLog.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchImportLog.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
        state?.data?.content?.forEach((ele: any) => {
          if (ele?.module?.toLowerCase() === 'order')
            ele.module = 'Purchase Order';
        });
      });
  }
});

export const { addImportLog, addImportLogColumnConfig } =
  ImportLogSlice.actions;
export const selectImportLog = (state: RootState) => state.importLog.data;
export const selectImportLogColumnConfig = (state: RootState) =>
  state.importLog.columnConfig;
