import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface InventoryAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  isOB?: boolean
}

const defaultConfig: InventoryAPIConfig = {
  SearchTerm: '',
  Limit: 10,
  Page: 0,
  IsVariant: false,
  type: '',
  isOB: false
};
class InventoryService {
  static apiConfig: InventoryAPIConfig;
  static getInventoryByPage(country = '') {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=isVariant=${
      this.apiConfig.IsVariant ? this.apiConfig.IsVariant : ''
    },type=${this.apiConfig.type}`;
     if(this.apiConfig.isOB){
      queryString = `?limit=${this.apiConfig.Limit}&page=${
        this.apiConfig.Page
      }&search=${this.apiConfig.SearchTerm}&query=isVariant=${
        this.apiConfig.IsVariant ? this.apiConfig.IsVariant : ''
      },type${this.apiConfig.type}`;
     }
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      (country !== '' ? `/${country.toLowerCase()}` : '') +
      queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateInventory(data: any) {
    const finalString: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      ApiConstants.URL.PRODUCT.UPDATE_INVENTORY;
    return http
      .put(finalString, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default InventoryService;
