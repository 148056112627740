import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { GridState } from '../../../Models/ReduxStore';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchWorkOrderList = createAsyncThunk(
  'fetchWorkOrder',
  async () => {
    const response = await WorkOrderService.getWorkOrders();
    return response;
  }
);

export const MRPSettingsSlice = createSlice({
  name: 'MrpSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkOrderList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchWorkOrderList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.workOrders = action.payload;
      });
  }
});

export const {} = MRPSettingsSlice.actions;

export const selectWorkOrder = (state: RootState) =>
  state.mrpWorkOrder.data.workOrders;

export const selectIsWOAdhocEnable = (state: RootState) =>
  state.authInfo.currentTenantInfo?.data?.additionalSettings?.ADHOC_BOM
    ?.enable ?? false;
export const selectIsWOAdhocLineItemInConversion = (state: RootState) =>
  state.authInfo.currentTenantInfo?.data?.additionalSettings?.ADHOC_BOM
    ?.showBomComponentAsLineItemInConversion ?? false;
