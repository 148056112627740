import { TableColumnPopupType } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeaderType } from "../TableUtility";

export class PickingListTableItem {
    invoiceOrQuotation: string;
    code: string;
    name: string;
    barcode: string;
    quantityToPick: string;
    quantityForOrder: string;
    picked: string;
    warehouseName: string;
    warehouseCode: string;
    rawItemValue: any;
    constructor(pickingListItem: any, data:any, currency: string) {
        this.invoiceOrQuotation = pickingListItem.invoiceOrQuotation
        this.code = pickingListItem.code ? pickingListItem.code : ''
        this.name = this.getProductName(data, pickingListItem)
        this.barcode = pickingListItem.barcode
        this.quantityToPick = pickingListItem.quantityToPick ? pickingListItem.quantityToPick.toString(): '0'
        this.quantityForOrder = pickingListItem.quantityForOrder ? pickingListItem.quantityForOrder.toString() : '0'
        this.picked = pickingListItem.picked ? getLocalisedText('yes').toUpperCase() : getLocalisedText('no').toUpperCase()
        this.warehouseName = pickingListItem.warehouseName ? pickingListItem.warehouseName : ''
        this.warehouseCode = pickingListItem.warehouseCode ? pickingListItem.warehouseCode : ''
        this.rawItemValue = pickingListItem
    }
    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }
    toString() {
        var sortedHeader = getSortedHeaderType()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(type => {
                sortedValue.push(this.getValueByType(type))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(type: string) {
        switch (type) {
            case TableColumnPopupType.invoiceOrQuotation:
                return getLocalisedText(this.invoiceOrQuotation)
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.barcode:
                return this.barcode
            case TableColumnPopupType.quantityToPick:
                return this.quantityToPick ? this.quantityToPick : ''
            case TableColumnPopupType.quantityRequiredForOrder:
                return this.quantityForOrder ? this.quantityForOrder : ''
            case TableColumnPopupType.picked:
                return this.picked ? this.picked : ''
            case TableColumnPopupType.warehouseName:
                return this.warehouseName
            case TableColumnPopupType.warehouseCode:
                return this.warehouseCode
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.invoiceOrQuotation),
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            this.barcode,
            this.quantityToPick,
            this.quantityForOrder,
            this.picked,
        ]
    }
}
