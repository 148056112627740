import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  DKCheckMark,
  DKTooltipWrapper,
  DKIcon,
  DKIcons
} from 'deskera-ui-library';
import WorkOrderService, {
  IWorkOrder,
  setDefaultWhForCompleteWO
} from '../../../../../Services/MRP/WorkOrder';
import { DynamicPopupWrapper } from '../../../../../SharedComponents/PopupWrapper';
import { isTrackingInfoAvailableForCompleteWO } from '../../WorkOrderHelper';
import { woSliceStateKeys } from '../../AddWorkOrderSlice';
import { INPUT_TITLE_STYLE } from '../../../Constants/UIHelper';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../../../Utility/Utility';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import NumberFormatService from '../../../../../Services/NumberFormat';
import { QTY_ROUNDOFF_PRECISION } from '../../../../../Constants/Constant';
import { useState } from 'react';

interface CompleteWorkOrderPopupProps {
  workOrder: IWorkOrder;
  isReadOnlyMode: boolean;
  canValidate: boolean;
  showWarehouseInventoryPopup: boolean;
  invalidActualEndDate: boolean;
  wasteManagementGrid: JSX.Element | null;
  onUpdateActiveWOKey: (key: string, value: any) => void;
  onStatusUpdateClick: () => void;
  onCommonStateUpdate: (key: woSliceStateKeys, value: any) => void;
  onChangeDefaultWarehouse: (value: any) => void;
  onClose: () => void;
}

const CompleteWorkOrderPopup = (props: CompleteWorkOrderPopupProps) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isDefaultWh, setIsDefaultWh] = useState(false);

  const getActualStartDateView = () => {
    return (
      <DKInput
        titleStyle={INPUT_TITLE_STYLE}
        valueStyle={{
          background: 'white'
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        title="Actual Start Date"
        value={props.workOrder?.actualStartDate}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        type={INPUT_TYPE.DATE}
        onChange={(date: any) => {
          props.onUpdateActiveWOKey('actualStartDate', date);
        }}
        readOnly={props.isReadOnlyMode}
        required={false}
      />
    );
  };

  const getActualQuantityView = () => {
    return (
      <div className="column parent-width align-items-end ml-m">
        <DKInput
          textAlign={'right'}
          title="Actual Quantity"
          type={INPUT_TYPE.NUMBER}
          required={true}
          canValidate={props.canValidate}
          titleStyle={INPUT_TITLE_STYLE}
          valueStyle={{
            background: 'white'
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={Utility.roundOff(
            props.workOrder?.actualQuantity || 1,
            QTY_ROUNDOFF_PRECISION
          )}
          onBlur={(e: any) => {
            let value = e.target.value;
            let actualYield = 0;
            if (isNaN(value) || value <= 0) {
              value = 0;
            }
            if (
              typeof props.workOrder?.manufactureQuantity !== 'undefined' &&
              value > props.workOrder?.manufactureQuantity
            ) {
              showAlert(
                'Please note',
                'Actual Quantity cannot be more than the planned manufacturing quantity.'
              );
              props.onUpdateActiveWOKey(
                'actualQuantity',
                props.workOrder?.manufactureQuantity
              );
              return;
            }

            props.onUpdateActiveWOKey('actualQuantity', value);
            if (
              value !== 0 &&
              typeof props.workOrder?.manufactureQuantity !== 'undefined'
            ) {
              actualYield =
                (value / props.workOrder?.manufactureQuantity) * 100;
            }
            props.onUpdateActiveWOKey('actualYield', actualYield);
          }}
          onChange={(value: any) => {}}
        />
        {!props.isReadOnlyMode &&
          (isTrackingInfoAvailableForCompleteWO(props.workOrder) ? (
            <span
              className="text-green cursor-hand mt-xs"
              onClick={() => {
                props.onCommonStateUpdate(
                  'showWarehouseInventoryPopup',
                  !props.showWarehouseInventoryPopup
                );
              }}
            >
              Tracking details provided
            </span>
          ) : (
            <span
              className="text-red cursor-hand mt-xs"
              onClick={() => {
                props.onCommonStateUpdate(
                  'showWarehouseInventoryPopup',
                  !props.showWarehouseInventoryPopup
                );
              }}
            >
              Provide tracking details
            </span>
          ))}
        {Utility.isRRBTaggingEnabled() && (
          <div className="parent-width flex justify-content-end text-align-right mt-r">
            <DKTooltipWrapper
              content="If default warehouse selected then this warehouse is set as default warehouse for future work orders."
              tooltipClassName="bg-deskera-secondary width-auto"
              tooltipStyle={{ whiteSpace: 'pre-wrap' }}
            >
              <div className="row pop-header-drag-handle">
                <DKCheckMark
                  title="Set as default warehouse"
                  className="mt-s"
                  isSelected={isDefaultWh}
                  onClick={() => {
                    setIsDefaultWh(!isDefaultWh);
                    props.onChangeDefaultWarehouse(!isDefaultWh);
                  }}
                />
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-xs opacity-40 ml-s"
                />
              </div>
            </DKTooltipWrapper>
          </div>
        )}
      </div>
    );
  };

  const getActualYieldView = () => {
    return (
      <div className="row ml-m">
        <DKInput
          textAlign={'right'}
          title="Actual Yield"
          type={INPUT_TYPE.NUMBER}
          required={true}
          readOnly={true}
          titleStyle={INPUT_TITLE_STYLE}
          valueStyle={{
            background: 'white'
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          canValidate={props.canValidate}
          value={`${NumberFormatService.getNumber(
            props.workOrder?.actualYield ?? 0
          )} %`}
          onChange={(value: any) => {
            props.onUpdateActiveWOKey('actualYield', value);
          }}
        />
      </div>
    );
  };

  const getActualEndDateView = () => {
    return (
      <div className="column parent-width">
        <DKInput
          titleStyle={INPUT_TITLE_STYLE}
          valueStyle={{
            background: 'white'
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title="Actual End Date"
          value={props.workOrder?.actualEndDate}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
          type={INPUT_TYPE.DATE}
          onChange={(date: any) => {
            props.onUpdateActiveWOKey('actualEndDate', date);
          }}
          readOnly={props.isReadOnlyMode}
          required={false}
        />
        {props.invalidActualEndDate && (
          <div
            className="fs-r text-align-left text-red mt-xs"
            style={{ fontSize: '11px' }}
          >
            Actual end date should be greater.
          </div>
        )}
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="column parent-width bg-white border-m shadow border-radius-m popup-window"
          style={{
            minHeight: 250,
            width: 600,
            minWidth: 600,
            padding: 0,
            overflowY: 'visible',
            zIndex: 9999
          }}
        >
          <div className="row p-s bg-gray1 justify-content-between rounded">
            <DKLabel className="fw-m fs-l" text="Confirm" />
            <div className="row-reverse">
              <DKButton
                title={'Complete'}
                className={`border-radius-m bg-button text-white`}
                onClick={() => {
                  props.onStatusUpdateClick();
                }}
              />
              <DKButton
                title={'Cancel'}
                className="bg-white border-m mr-r border-"
                onClick={() => props.onClose()}
              />
            </div>
          </div>
          <div className="column parent-width p-l">
            <div className="row parent-width align-items-start">
              {getActualStartDateView()}
              {getActualQuantityView()}
            </div>
            <div className="row parent-width mt-m align-items-start">
              {getActualEndDateView()}
              {getActualYieldView()}
            </div>
          </div>
          <div className="column parent-width overflow-scroll p-h-m hide-scroll-bar">
            {props.wasteManagementGrid}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default CompleteWorkOrderPopup;
