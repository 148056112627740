import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class SalesReturnTableWidth {
    isLineNumberVisible: boolean;
    isCodeVisible: boolean;
    isNameVisible: boolean;
    isDescriptionVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isFulfilledQuantityVisible: boolean;
    isReturnedQuantityVisible: boolean;
    isProductCustomFieldVisible: boolean;
    

    lineNumber: number;
    code: number;
    name: number;
    description: number;
    nameWithDescription: number;
    productCustomField: number;
    fulfilledQuantity: number;
    returnedQuantity: number;


    originalLineNumber: number;
    originalCode: number;
    originalName: number;
    originalDescription: number;
    originalNameWithDescription: number;
    originalProductCustomField: number;
    originalFulfilledQuantity: number;
    originalReturnedQuantity: number;

    productCustomFieldList: any;
    productGlobalDiscountList: any;

    constructor() {
        this.isLineNumberVisible= true
        this.isCodeVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = true
        this.isFulfilledQuantityVisible = true
        this.isReturnedQuantityVisible = true
        this.isProductCustomFieldVisible = false

        this.lineNumber = 5
        this.code = 20
        this.name = 25
        this.description = 15
        this.nameWithDescription = this.name + this.description
        this.productCustomField = 5
        this.fulfilledQuantity = 15
        this.returnedQuantity  = 15

        this.originalLineNumber = 5
        this.originalCode = 20
        this.originalName = 25
        this.originalDescription = 15
        this.originalNameWithDescription = this.originalName + this.originalDescription
        this.originalProductCustomField = 5
        this.originalFulfilledQuantity = 15
        this.originalReturnedQuantity = 15

        this.productCustomFieldList = undefined
        this.productGlobalDiscountList = undefined
    }


    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        this.isCodeVisible = getIsShowTableColumn(columnData, TableColumnPopupType.code)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)
        this.isFulfilledQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.fulfilledQty)
        this.isReturnedQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.returnedQty)

        var width = 5
        var maxWidth = 20

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);
        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }

    private resetElementOriginalWidth() {
        this.code = this.originalCode
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription
        this.productCustomField = this.originalProductCustomField
        this.fulfilledQuantity = this.originalFulfilledQuantity
        this.returnedQuantity = this.originalReturnedQuantity

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.code = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        this.fulfilledQuantity = 0
        this.returnedQuantity = 0

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        if (this.isCodeVisible) {
            totalWidth += this.code;
        }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }
        if (this.isFulfilledQuantityVisible) {
            totalWidth += this.fulfilledQuantity;
        }
        if (this.isReturnedQuantityVisible) {
            totalWidth += this.returnedQuantity;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        if (this.isCodeVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1
        }
        if (this.isFulfilledQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isReturnedQuantityVisible) {
            totalSelectedElement += 1
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }

        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {
        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        if (this.isCodeVisible) {
            this.code = this.originalCode + additionalWidth
        }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }
        if (this.isFulfilledQuantityVisible) {
            this.fulfilledQuantity = this.originalFulfilledQuantity + additionalWidth
        }
        if (this.isReturnedQuantityVisible) {
            this.returnedQuantity = this.originalReturnedQuantity + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }

        if (this.productGlobalDiscountList !== undefined) {
            this.productGlobalDiscountList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }

    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }

    getProductGlobalDiscountWidthByGlobalDiscountAccountCode(globalDiscountAccountCode: string) {
        if (this.productGlobalDiscountList !== undefined) {
            var item = this.productGlobalDiscountList.find((x: any) => x.globalDiscountAccountCode === globalDiscountAccountCode)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}