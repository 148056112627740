import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { IColumn } from '../../Models/Table';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const FulfillmentReportSlice = createSlice({
  name: 'fulfillment_report',
  initialState,
  reducers: {
    setFulfillmentReportData: (state, action: PayloadAction<ConfigPayload>) => {
      state.data = action.payload;
    },
    addFulfillmentReportColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BOOKS_REPORT_FULFILLMENT_REPORT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    setFulfillmentReportConfigTableId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.configTableId = action.payload;
    },
    setFulfillmentReportStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.status = action.payload;
    }
  }
});

export const {
  setFulfillmentReportData,
  addFulfillmentReportColumnConfig,
  updateColumnConfig,
  setFulfillmentReportConfigTableId,
  setFulfillmentReportStatus
} = FulfillmentReportSlice.actions;

export const selectFulfillmentReportData = (state: any) =>
  state.fulfillment_report.data;
export const selectFulfillmentReportColumnConfig = (state: any) =>
  state.fulfillment_report.columnConfig;
export const selectFulfillmentReportConfigTableId = (state: any) =>
  state.fulfillment_report.configTableId;

export default FulfillmentReportSlice.reducer;
