import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { IColumn } from '../../Models/Table';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const GRNSlice = createSlice({
  name: 'grn',
  initialState,
  reducers: {
    setGRNData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    addGRNColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BOOKS_REPORT_GRN_REPORT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    setGRNConfigTableId: (state, action: PayloadAction<string>) => {
      state.configTableId = action.payload;
    },
    setGRNStatus: (state, action: PayloadAction<API_STATUS>) => {
      state.status = action.payload;
    }
  }
});

export const {
  setGRNData,
  addGRNColumnConfig,
  updateColumnConfig,
  setGRNConfigTableId,
  setGRNStatus
} = GRNSlice.actions;

export const selectGRNData = (state: any) => state.grn.data;
export const selectGRNColumnConfig = (state: any) => state.grn.columnConfig;
export const selectGRNConfigTableId = (state: any) => state.grn.configTableId;

export default GRNSlice.reducer;
