import {
  showLoader,
  removeLoader,
  showAlert,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput,
  DKLabel,
  DKIcon,
  showToast,
  DKIcons,
  DKButton,
  DKListPicker2
} from 'deskera-ui-library';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  fetchProductInventoryByID,
  selectWarehouseProductByID
} from '../../Redux/Slices/WarehouseProductSlice';
import {
  ReceivedGoodItemState,
  ReceivedGoodsPopupState,
  ReceivedGoodState,
  ReceiveGoodsPayload
} from '../../Models/ReceivedGoods';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized
} from '../../Utility/Utility';
import ReceiveGoodsItem from './ReceivedGoodsItem';
import ReceivedGoodsService from '../../Services/ReceivedGoods';
import {
  fetchBills,
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import {
  fetchOrders,
  selectPurchaseOrdersColumnConfig,
  selectPurchaseOrdersColumnConfigTableId
} from '../../Redux/Slices/PurchaseOrdersSlice';
import DateFormatService from '../../Services/DateFormat';
import { map } from 'lodash';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import {
  fetchWorkOuts,
  selectWorkOutsColumnConfig,
  selectWorkOutsColumnConfigTableId
} from '../../Redux/Slices/WorkOutSlice';
import AttachmentService from '../../Services/Attachment';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import { QC_FLOW_MODULES } from '../../Components/Settings/QcFlow/QCFlowConstant';
import { allowedDocumentsForGoodsReceivedQC } from '../../Components/QualityCheck/QualityCheckConstant';
import useQCConfirm from '../../Hooks/useQCConfirm';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import CommonQualityCheckService from '../../Services/QualityCheck';
import {
  customFieldsContainsErrors,
  getFormattedAddressObj
} from '../DocumentForm/NewDocumentHelper';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../Services/event/commonEvents';
import { WarehouseAPIConfig } from '../../Services/Warehouse';
import {
  fetchWarehouseWithRRBCombination,
  selectedWarehouseWithRRBCombination
} from '../../Redux/Slices/WarehouseSlice';
import { ADVANCE_TRACKING } from '../../Constants/Enum';
import GoodsReceiptMasterService from '../../Services/GoodsReceiptMasterService';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import FulfillmentGrid from '../FulfillmentPopup/FulfillmentGrid';
import { selectCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { t } from 'i18next';
import { setInitialDataForLocalizedUOM } from '../FulfillmentPopup/FulfilmentGridHelper';

const ReceivedGoods: React.FC<ReceivedGoodsPopupState> = (props) => {
  const currentDate = new Date();
  const [receivedGoodDate, setreceivedGoodDate] = useState<Date>(currentDate);
  const [receivedGoodDateOpen, setreceivedGoodDateOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(props.documentDetails);
  const [docType, setDocType] = useState(props.documentType);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [defaultWarehouse, setDefaultWarehouse] = useState<any>();
  const warehouseProduct = useAppSelector(selectWarehouseProductByID);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');
  const columnConfigForPO = useAppSelector(selectPurchaseOrdersColumnConfig);
  const jobWorkOutColumnConfig = useAppSelector(selectWorkOutsColumnConfig);
  const columnConfigForBill = useAppSelector(selectBillsColumnConfig);
  const PoConfigTableId = useAppSelector(
    selectPurchaseOrdersColumnConfigTableId
  );
  const jobWorkOutConfigTableId = useAppSelector(
    selectWorkOutsColumnConfigTableId
  );
  const BillConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  let module = MODULES_NAME.ORDER;
  let columnConfig = columnConfigForPO;
  let columnConfigTableId = PoConfigTableId;
  const tenantInfo = useAppSelector(activeTenantInfo);
  const lineItemsTable = useRef(null);
  const [receivedGoodItems, setReceivedGoodItems] = useState<
    ReceivedGoodState['items']
  >(getReceivedGoodsItems());
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);

  const [currentFromAddress, setCurrentFromAddress] = useState<any>(null);
  const [completeProcess, setCompleteProcess] = useState<any>(false);
  const [currentToAddress, setCurrentToAddress] = useState<any>(null);
  const [openAddressPicker, setOpenAddressPicker] = useState<any>(null);
  const [isFromAddressUpdated, setIsFromAddressUpdated] = useState(false);
  const [isToAddressUpdated, setIsToAddressUpdated] = useState(false);

  const billColumnConfig = useAppSelector(selectBillsColumnConfig);
  const billConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  const poColumnConfig = useAppSelector(selectPurchaseOrdersColumnConfig);
  const poConfigTableId = useAppSelector(
    selectPurchaseOrdersColumnConfigTableId
  );

  const { qcConfirm } = useQCConfirm();
  const [childColumnConfig, setChildColumnConfig] = useState<any>([]);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const allWarehouseWithRRBCombination = useAppSelector(
    selectedWarehouseWithRRBCombination
  );

  type returnFunction = (index: number) => any;
  if (props.documentDetails.documentType === 'PURCHASE_INVOICE') {
    module = MODULES_NAME.BILL;
    columnConfig = columnConfigForBill;
    columnConfigTableId = BillConfigTableId;
  }

  if (props.documentDetails.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
    module = MODULES_NAME.JOB_WORK_OUT;
    columnConfig = jobWorkOutColumnConfig;
    columnConfigTableId = jobWorkOutConfigTableId;
  }
  function getRequiredQty(item: any, dispatchedQtyItems: any) {
    let requiredQty = item.quantityRequired;
    let bomRMQty = 0;
    item.product.bomProductsConfiguration.forEach(
      (prod: any) => (bomRMQty += prod.quantity)
    );
    let dispatchQtyItem = 0;
    dispatchedQtyItems.forEach(
      (prod: any) => (dispatchQtyItem += prod.dispatchedQty)
    );
    requiredQty = dispatchQtyItem / bomRMQty;
    requiredQty = requiredQty - item.receivedQuantityInOrder;
    return requiredQty;
  }

  function getReceivedGoodsItems() {
    let updatedItems: ReceivedGoodState['items'] = [];
    if (props?.documentDetails?.purchaseInvoiceProducts?.length) {
      updatedItems = [...props.documentDetails.purchaseInvoiceProducts];
    } else if (props?.documentDetails?.purchaseOrderItems?.length) {
      updatedItems = [...props.documentDetails.purchaseOrderItems];
    } else if (props?.documentDetails?.jobWorkOutOrderItems?.length) {
      updatedItems = [...props.documentDetails.jobWorkOutOrderItems];
    }

    // for localized uom
    updatedItems = updatedItems?.map((item: any) => {
      const docSchema =
        item?.unlocalizedDocumentUOMSchemaDefinition ??
        item?.documentUOMSchemaDefinition ??
        null;
      if (
        (item?.isLocalizedUomQty ||
          tenantInfo?.additionalSettings?.LOCALIZED_UOM_CONVERSION) &&
        Utility.isNotEmpty(docSchema)
      ) {
        return setInitialDataForLocalizedUOM(item, DOC_TYPE.GOODS_RECEIPT);
      } else {
        return item;
      }
    });

    if (updatedItems?.length) {
      updatedItems.forEach((item) => {
        //note will use later

        if (
          documentDetails.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          Utility.isJWOReceiveDispatchAdditionalQtyEnable()
        ) {
          item.quantityRequired =
            item.maxQtyToReceive - item.receivedQuantityInOrder;
        }
        const pendingQuantity = Utility.pendingToBeReceivedQuantity(item);
        if (
          pendingQuantity < 0 &&
          Utility.isJWOReceiveDispatchAdditionalQtyEnable()
        ) {
          item.pendingQuantity = 0;
        } else {
          item.pendingQuantity = pendingQuantity;
        }
      });
    }

    updatedItems = updatedItems.sort(
      (a: any, b: any) => a.lineNumber - b.lineNumber
    );
    return updatedItems;
  }

  useEffect(() => {
    const config: WarehouseAPIConfig = {
      SearchTerm: '',
      Limit: 50,
      Page: 0,
      Query: 'active=true&allRRBDetails=true'
    };
    dispatch(fetchWarehouseWithRRBCombination(config));
    let ids = map(receivedGoodItems, 'productCode');
    loadProductInventoryById(ids);

    if (props?.documentDetails) {
      setCurrentFromAddress(props?.documentDetails?.shipFrom);
      setCurrentToAddress(props?.documentDetails?.shipTo);
      setIsFromAddressUpdated(true);
      setIsToAddressUpdated(true);
    }
  }, []);

  useEffect(() => {
    let warehouses = warehouseProduct;
    if (!Utility.isEmpty(warehouses)) {
      let defaultWarehouseData =
        warehouses.find((warehouse: any) => warehouse.primary) || warehouses[0];

      if (defaultWarehouseData?.code) {
        updateInventory(defaultWarehouseData.code);
      }

      if (
        Utility.isEmpty(defaultWarehouse) ||
        defaultWarehouse?.code !== defaultWarehouseData?.code
      ) {
        setDefaultWarehouse({ ...defaultWarehouseData });
      }
    }

    setWarehouses(warehouses);
  }, [warehouseProduct, warehouses, defaultWarehouse]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.RECEIVED_GOODS,
        data: () => {
          saveReceivedGoods();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const updateInventory = (key: string) => {
    let newReceivedGoodsItem: any = [];
    receivedGoodItems?.map((item, index) => {
      let pendingQuantity = Utility.pendingToBeReceivedQuantity(item);
      let documentUOMSchemaDefinition =
        item.unlocalizedDocumentUOMSchemaDefinition ??
        item.documentUOMSchemaDefinition;
      if (
        pendingQuantity < 0 &&
        Utility.isJWOReceiveDispatchAdditionalQtyEnable()
      ) {
        pendingQuantity = 0;
      }
      item.pendingQuantity = pendingQuantity;
      item.uomReceivedQuantityInBills =
        item.uomReceivedQuantityInBills ||
        Utility.getUomQuantity(
          item.receivedQuantityInBills +
            (item.localizedBaseQtyDiffReceivedInBills || 0),
          documentUOMSchemaDefinition
        );
      item.uomAvailableQuantity =
        item.uomAvailableQuantity ||
        Utility.getUomQuantity(
          item.availableQuantity || item.product.availableQuantity,
          documentUOMSchemaDefinition
        );
      item.uomReceivedQuantityInOrder =
        item.uomReceivedQuantityInOrder ||
        Utility.getUomQuantity(
          item.receivedQuantityInOrder +
            (item.localizedBaseQtyDiffReceivedInOrder || 0),
          documentUOMSchemaDefinition
        );
      item.uomQuantityRequired =
        item.uomQuantityRequired ||
        Utility.uomPendingToBeUomReceivedQuantity(item);
      item.uomReceiptQuantity = Utility.roundingOff(
        item.uomReceiptQuantity || item.uomQuantityRequired,
        QTY_ROUNDOFF_PRECISION
      );
      item.receiptQuantity = item.receiptQuantity || pendingQuantity;
      //note will use later
      // item.productQtyForWhichRmDispatched = item.maxQtyToReceive; // no need

      item.quantityRequired = pendingQuantity;
      if (
        documentDetails.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
        Utility.isJWOReceiveDispatchAdditionalQtyEnable()
      ) {
        item.quantityRequired =
          item.maxQtyToReceive - item.receivedQuantityInOrder;
        item.receiptQuantity = item.quantityRequired;
      }
      newReceivedGoodsItem.push(item);
    });
    // setWarehousecode(filteredProduct.code);
    setCompleteProcess(true);
    setReceivedGoodItems(newReceivedGoodsItem);
  };

  const onItemUpdated = (data: ReceivedGoodItemState) => {
    let receivedItems =
      documentDetails.purchaseInvoiceProducts ||
      documentDetails.purchaseOrderItems ||
      documentDetails.jobWorkOutOrderItems;
    let receivedItemsKey =
      docType === DOC_TYPE.BILL
        ? 'purchaseInvoiceProducts'
        : 'purchaseOrderItems';
    if (receivedGoodItems) {
      const indexToUpdate = receivedGoodItems.findIndex(
        (item: any) => item.id === data.id
      );
      let itemsArr: any = [...receivedGoodItems];
      itemsArr[indexToUpdate] = data;
      setReceivedGoodItems(itemsArr);
      setDocumentDetails({
        ...documentDetails,
        [receivedItemsKey]: itemsArr
      });
    }
  };

  const receivedGoodsPayload = (): ReceiveGoodsPayload => {
    let updatedReceivedGoodsItems: any[] = receivedGoodItems?.filter(
      (item: any) => item.receiptQuantity > 0
    );

    updatedReceivedGoodsItems = updatedReceivedGoodsItems.filter(
      (item: any) =>
        item.receiptQuantity > 0 || Number(item.uomReceiptQuantity) > 0
    );

    const receiptDate = DateFormatService.getDateStrFromDate(
      receivedGoodDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    updatedReceivedGoodsItems = updatedReceivedGoodsItems.map((item) => {
      item.receiptDate = receiptDate;

      if (documentDetails?.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        return { ...item };
      } else {
        return { ...item, customField: getLineItemCFs(item) };
      }
    });

    let receivedGoodsPayload = {
      ...documentDetails,
      receiptDate: receiptDate,
      documentCode:
        documentDetails.purchaseInvoiceCode ||
        documentDetails.poCode ||
        documentDetails.jwoCode,
      documentType: docType,
      items: updatedReceivedGoodsItems
    };

    if (Utility.isNotEmpty(currentFromAddress)) {
      receivedGoodsPayload = {
        ...receivedGoodsPayload,
        shipFrom: currentFromAddress
      };
    }
    if (Utility.isNotEmpty(currentToAddress)) {
      receivedGoodsPayload = {
        ...receivedGoodsPayload,
        shipTo: currentToAddress
      };
    }

    const payload = new ReceiveGoodsPayload(receivedGoodsPayload);
    return payload;
  };
  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = childColumnConfig;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'PRODUCT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const checkIfQCRequired = async () => {
    let productCodes = receivedGoodItems?.map((item: any) => item?.productCode);
    let response: any =
      await CommonQualityCheckService.getTemplateUsageByProduct(productCodes);
    let isQualityCheck = response?.reduce(
      (prev: boolean, current: any) => prev || current.used,
      false
    );
    return isQualityCheck;
  };

  const saveReceivedGoods = async () => {
    let Payload = receivedGoodsPayload();

    if (!Utility.isEmpty(Payload.receiptDate)) {
      const docDate = DateFormatService.getDateFromStr(
        Payload.receiptDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Receive goods date',
          'GOODS_RECEIPT'
        )
      ) {
        return;
      }
      if (!Utility.checkClosingDate(docDate, 'Receive goods date')) {
        return;
      }
    }
    let hasError = false;
    receivedGoodItems.forEach((items: any) => {
      if (items.invalidFields?.length > 0) {
        hasError = true;
      }
    });
    if (hasError) {
      return;
    }
    if (documentDetails.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      let totalReceivedQty = receivedGoodItems?.reduce(function (
        add: any,
        current: any
      ) {
        return add + +current.receiptQuantity;
      },
      0);

      let totalRequiredQty = receivedGoodItems?.reduce(function (
        add: any,
        current: any
      ) {
        let reqQty = Number(current.quantityRequired)
          ? Number(current.quantityRequired)
          : Utility.pendingToBeReceivedQuantity(current);
        if (
          !Number(current.quantityRequired) &&
          docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          Utility.isJWOReceiveDispatchAdditionalQtyEnable()
        ) {
          reqQty = getRequiredQty(current, documentDetails.dispatchedQtyItems);
        }

        if (
          current?.documentUOMSchemaDefinition &&
          current?.advancedTracking === ADVANCE_TRACKING.SERIAL
        ) {
          reqQty = Utility.getUomQuantityWithoutRoundOff(
            reqQty,
            current?.documentUOMSchemaDefinition
          );
        }
        return add + reqQty;
      },
      0);
      if (
        totalReceivedQty > totalRequiredQty &&
        !Utility.isJWOReceiveDispatchAdditionalQtyEnable()
      ) {
        showAlert(
          'Warning',
          `Quantity used cannot be more than required quantity (${totalRequiredQty})`
        );
        return;
      }
    }

    let hasAdvancedTracking: any[] = [];
    let hasRRBValidation: any = [];
    let isInvalidItem: any = [];
    const isJWOAdditionalQtyEnabled =
      docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      Utility.isJWOReceiveDispatchAdditionalQtyEnable();
    if (Payload.items && Payload.items.length === 0) {
      setDisplayError(true);
      setErrorMessageText(
        'At least one product must have more than 0 receiving quantity'
      );
      return;
    } else {
      const validateReceivedGoods: any[] = Payload.items.filter(
        (item: any) =>
          Utility.roundingOff(
            parseFloat(item.receiptQuantity),
            QTY_ROUNDOFF_PRECISION
          ) >
            Utility.roundingOff(
              parseFloat(item.quantityRequired),
              QTY_ROUNDOFF_PRECISION
            ) &&
          !isJWOAdditionalQtyEnabled &&
          !Utility.isPurchaseToleranceSettingsEnabled(
            docType,
            documentDetails
          ) &&
          !item.isLocalizedUomQty
      );
      Payload.items.forEach((element) => {
        if (
          element.advancedTracking === TRACKING_TYPE.BATCH ||
          element.advancedTracking === TRACKING_TYPE.SERIAL
        ) {
          const tracking =
            (element.advancedTrackingMetaData &&
              element.advancedTrackingMetaData.length > 0) ||
            (element.productType === PRODUCT_TYPE.BILL_OF_MATERIALS &&
              docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
              Utility.isBatchOrSerialAdvanceTracking(
                element.product.bomProductsConfiguration
              ) &&
              element.rawMaterialToConsume &&
              element.rawMaterialToConsume.length > 0)
              ? false
              : true;
          hasAdvancedTracking.push(tracking);
        }
        // else {
        if (element.productType !== PRODUCT_TYPE.NON_TRACKED) {
          element.warehouseInventoryData?.forEach((inventory: any) => {
            // const warehouse = warehouseProduct?.find(
            //   (w: any) => w.code === inventory.warehouseCode
            // );
            const warehouse = allWarehouseWithRRBCombination?.content?.find(
              (w: any) => w.code === inventory.warehouseCode
            );
            if (
              (!Utility.isEmpty(warehouse?.warehouseRowInfos) &&
                Utility.isEmpty(inventory?.rowCode)) ||
              (!Utility.isEmpty(warehouse?.warehouseRackInfos) &&
                Utility.isEmpty(inventory?.rackCode)) ||
              (!Utility.isEmpty(warehouse?.warehouseBinInfos) &&
                Utility.isEmpty(inventory?.binCode))
            ) {
              hasRRBValidation.push(true);
              return;
            } else {
              hasRRBValidation.push(false);
            }
          });
        }
        // }
        if (
          element.productType === PRODUCT_TYPE.BILL_OF_MATERIALS &&
          docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          Utility.isBatchOrSerialAdvanceTracking(
            element.product.bomProductsConfiguration
          ) &&
          ((element.rawMaterialToConsume &&
            element.rawMaterialToConsume.length === 0) ||
            !element.rawMaterialToConsume)
        ) {
          isInvalidItem.push('invalidBOMRawMaterial');
          return;
        }

        // should not receive more than dispatched qty(productQtyForWhichRmDispatched)
        if (
          !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
          docType &&
          docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          element.productQtyForWhichRmDispatched != null &&
          element.productQtyForWhichRmDispatched !== undefined &&
          Number(element.receiptQuantity) >
            Number(element.productQtyForWhichRmDispatched)
        ) {
          isInvalidItem.push('productQtyForWhichRmDispatched');
        }

        // should not receive more than total dispatch qty - partial received qty
        if (
          !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
          docType &&
          docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          element.productQtyForWhichRmDispatched != null &&
          element.productQtyForWhichRmDispatched !== undefined &&
          Number(element.receiptQuantity) >
            Number(element.productQtyForWhichRmDispatched) -
              Number(element.receivedQuantityInOrder)
        ) {
          isInvalidItem.push('receivedQuantityInOrder');
        }
        // should not receive more than max qty receive & total dispatch qty - partial received qty
        if (
          Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
          docType &&
          docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
          Number(element.receiptQuantity) >
            Number(element.maxQtyToReceive) -
              Number(element.receivedQuantityInOrder) // chk
        ) {
          isInvalidItem.push('receivedQuantityInOrder');
        }
      });
      if (isInvalidItem.includes('productQtyForWhichRmDispatched')) {
        setDisplayError(true);
        setErrorMessageText(t(`JOB_WORK_OUT.RECEIVE_QTY_MORE_DISPATCH_ERROR`));
        return true;
      }
      if (isInvalidItem.includes('receivedQuantityInOrder')) {
        setDisplayError(true);
        setErrorMessageText(
          t(`Dispatched Quantity not enough to receive entered goods`)
        );
        return true;
      }

      if (hasAdvancedTracking.includes(true)) {
        setDisplayError(true);
        setErrorMessageText(
          'Serial/Batch Number must be assigned to do the received goods'
        );
        return;
      }
      if (
        docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
        isInvalidItem.includes('invalidBOMRawMaterial')
      ) {
        setDisplayError(true);
        setErrorMessageText(t('JOB_WORK_OUT.RAW_MATERIAL_ALLOCATE_ERROR'));
        return true;
      }
      if (hasRRBValidation.includes(true)) {
        showAlert(
          'Warning!',
          'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
        );
        return;
      }
      if (validateReceivedGoods && validateReceivedGoods.length > 0) {
        let errorMessage =
          'Receiving quantity cannot be more than the required quantity.';
        let { isBillLinked } = Utility.getPurchaseLinkedDocument(
          docType,
          documentDetails
        );
        if (isBillLinked) {
          errorMessage =
            'Receiving quantity cannot be more than required quantity as the Bill is already generated for this document.';
        }
        setDisplayError(true);
        setErrorMessageText(errorMessage);
        return;
      }
    }

    // Custom fields validation
    let hasErrorInCF = customFieldsContainsErrors(Payload.customField);
    if (hasErrorInCF) {
      setDisplayError(true);
      setErrorMessageText('Please enter correct values for custom fields.');
      return;
    }

    showLoader();
    setDisplayError(false);
    setErrorMessageText('');
    let isQCCheckProductAvailable = await checkIfQCRequired();
    if (
      Utility.checkQCEnabledForModule(QC_FLOW_MODULES.GOODS_RECEIPT) &&
      isQCCheckProductAvailable &&
      allowedDocumentsForGoodsReceivedQC.includes(docType) &&
      Utility.isMRP()
    ) {
      const isConfirmed = await qcConfirm(
        `Would you like to perform a quality check on the incoming goods?`,
        `QC Flow`
      );

      if (isConfirmed === null) {
        removeLoader();
        return;
      }

      if (isConfirmed) {
        Payload.isQcEnabled = true;
      }
    }
    ReceivedGoodsService.saveReceivedGoods(Payload)
      .then((res) => {
        commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
          id: null,
          type: RECORD_SAVED_EVENT_DOC_TYPE.RECEIVED_GOODS,
          linkedDocId: Payload.documentCode,
          linkedDocType: Payload.documentType,
          isEdit: true
        });

        closePopup(res, docType, Payload, hasAdvancedTracking.length);
      })
      .catch((error: any) => {
        setDisplayError(true);
        const errorMessage =
          error && error.data && error.data.errorMessage
            ? error.data.errorMessage
            : 'Unable to receive selected records';
        setErrorMessageText(errorMessage);
        removeLoader();
      });
  };

  const closePopup = (
    successResp: any,
    docType: DOC_TYPE,
    payload: any,
    HasAdvancedTracking: any
  ) => {
    removeLoader();
    const grCode = successResp.goods_receipt_code;
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP_RECEIVE_GOODS_SUCCESS
      });
    }
    if (docType === DOC_TYPE.BILL) {
      dispatch(fetchBills());
    } else if (docType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      dispatch(fetchWorkOuts());
    } else {
      dispatch(fetchOrders());
    }
    if (HasAdvancedTracking > 0) {
      payload.items.forEach((element: any) => {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      });
    }
    let grSuccessMessage = `Received Goods of ${grCode} is saved successfully${
      payload.isQcEnabled ? `, please go through the quality check list.` : `.`
    }`;
    let popupHeader = 'Success!';

    if (successResp?.grSentForToleranceApproval) {
      popupHeader = 'Tolerance Alert!';
      grSuccessMessage = `Received Goods of ${grCode} has been sent for approval, it will be available for approval in some time. In the mean time, you can view remaining pending approval Goods Receipts${
        payload.isQcEnabled
          ? `, ${grCode} will be available for QC once approved.`
          : `.`
      }`;
    }

    let buttons = [
      {
        title: 'Ok',
        className: `${
          payload.isQcEnabled ? `bg-gray2` : `bg-blue text-white`
        } border-m`,
        onClick: () => {}
      }
    ];

    if (payload.isQcEnabled && !successResp?.grSentForToleranceApproval) {
      buttons.push({
        title: 'Go to Quality Check',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          RouteManager.navigateToPage(PAGE_ROUTES.QUALITY_CHECK);
        }
      });
    }

    if (successResp?.grSentForToleranceApproval) {
      if (
        checkUserPermission(
          PERMISSIONS_BY_MODULE.PURCHASE_ORDER.RECEIVE_GOODS
        ) ||
        checkUserPermission(PERMISSIONS_BY_MODULE.BILL.RECEIVE_GOODS)
      ) {
        buttons.push({
          title: 'Go to Approval screen',
          className: 'bg-blue text-white ml-r',
          onClick: () => {
            GoodsReceiptMasterService.setSelectedIndex(1);
            RouteManager.navigateToPage(PAGE_ROUTES.GOODS_RECEIPT_MASTER_LIST);
          }
        });
      }
    }

    showAlert(popupHeader, grSuccessMessage, buttons);
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (!Utility.checkClosingDate(newDate, 'Receive goods date')) {
        return;
      }
      if (
        !Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Receive goods date',
          'GOODS_RECEIPT'
        )
      ) {
        return;
      }
      callback(newDate);
    } else {
      callback(new Date(receivedGoodDate));
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };
  const getCalendarView = (selectedDate: any, onSelect: any) => {
    return (
      <DKInput
        className="parent-width"
        title="Received Date"
        value={selectedDate}
        type={INPUT_TYPE.DATE}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        onChange={(value: any) => {
          onSelect(value);
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        datePickerConfig={{
          isDateRange: false
        }}
      />
    );
  };
  const showErrorMessage = () => {
    if (displayError) {
      return (
        <div className="row rounded bg-red-100 p-2 mt-l parent-width">
          <span>{errorMessageText}</span>
        </div>
      );
    }
  };

  const showCustomFieldView = () => {
    return (
      <div className="column parent-width mt-l mb-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={module}
          customFieldsList={props.documentDetails.customField}
          columnConfig={columnConfig}
          columnConfigTableId={columnConfigTableId ? columnConfigTableId : ''}
          onUpdate={(list: any) => {
            const updatedDetails = documentDetails;
            updatedDetails.customField = list;
            setDocumentDetails({ ...updatedDetails });
          }}
        />
      </div>
    );
  };

  const showAttachmentView = () => {
    return (
      <Fragment>
        <DKButton
          title={
            <>
              + Attach files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          className={`text-blue mt-r fw-m`}
          style={{ paddingLeft: 0, width: 160 }}
          // disabled={props.draftType === DraftTypes.READONLY}
          onClick={triggerAttachmentUpload}
        />
        <div className="row">{getAttachments()}</div>
      </Fragment>
    );
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true')
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const attachmentForListing = [...attachments, ...resList];
          const newlyAddedAttachments = [...newAttachments, ...resList];
          setAttachments(attachmentForListing);
          setNewAttachments(newlyAddedAttachments);
          setDocumentDetails((prevState: any) => {
            return {
              ...prevState,
              attachmentIds: newlyAddedAttachments.map(
                (attachment: any) => attachment.attachmentId
              ),
              attachments: newlyAddedAttachments.map((attachment: any) =>
                JSON.stringify(attachment.attachmentId)
              )
            };
          });
        }).catch((err) => {
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = async (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP['GOODS_RECEIPT'];
    // const entityId = booksDocument.id || booksDocument.entityId || '';
    const entityId = '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    return AttachmentService.uploadAttachment(file);
  };

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setDocumentDetails((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment.attachmentId)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (documentDetails.documentType) {
      case DOC_TYPE.BILL:
        columnConfigInfo = {
          columnConfig: billColumnConfig,
          tableId: billConfigTableId
        };
        break;
      case DOC_TYPE.ORDER:
        columnConfigInfo = {
          columnConfig: poColumnConfig,
          tableId: poConfigTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  const getAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = currentFromAddress?.customFields ?? [];
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      addressCustomFields = currentToAddress?.customFields ?? [];
    }

    return Utility.isNotEmpty(addressCustomFields) ? (
      <div className={`${addressCustomFields?.length ? 'mb-r mr-l' : ''}`}>
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          documentMode={DOCUMENT_MODE.NEW}
          onUpdate={async (updatedCFList) => {
            if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
              let currentFromAddressCopy: any = {
                ...currentFromAddress,
                customFields: [...updatedCFList]
              };
              setCurrentFromAddress(currentFromAddressCopy);
            }
            if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
              let currentToAddressCopy: any = {
                ...currentToAddress,
                customFields: [...updatedCFList]
              };
              setCurrentToAddress(currentToAddressCopy);
            }
          }}
          onLocationUpdate={(loc) => {}}
          contact={documentDetails?.contact}
          hideAddfieldButton={true}
          addressUpdate={isFromAddressUpdated || isToAddressUpdated}
          updateAddressField={() => {
            setIsFromAddressUpdated(false);
            setIsToAddressUpdated(false);
          }}
        />
      </div>
    ) : null;
  };

  const getContactCard = (title: string, addressType: BOOKS_ADDRESS_TYPES) => {
    return (
      <div
        className={`column document-address-block`}
        style={{
          minWidth: 420,
          maxWidth: 420,
          marginLeft: -10,
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10
        }}
      >
        <div className="row parent-block justify-content-between">
          <div
            className={`row width-auto cursor-pointer listPickerBG`}
            onClick={() => {}}
          >
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
        </div>
        <div className={`mt-s`}>
          {/* From */}
          {addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM && (
            <div className="position-relative">
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {}}
              >
                <DKLabel text={''} className="fw-m fs-r" />
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {
                  setOpenAddressPicker(BOOKS_ADDRESS_TYPES.SHIP_FROM);
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(currentFromAddress, false)
                ) && (
                  <DKLabel
                    text={getFormattedAddress(currentFromAddress, false)}
                  />
                )}
              </div>
              {openAddressPicker === BOOKS_ADDRESS_TYPES.SHIP_FROM && (
                <DKListPicker2
                  data={documentDetails?.contactDto?.shippingAddress ?? []}
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={null}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setOpenAddressPicker(null);
                    setIsFromAddressUpdated(true);
                    setCurrentFromAddress(addressObj);
                  }}
                  onClose={() => {
                    setOpenAddressPicker(null);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM &&
            getAddressCustomFields(addressType)}

          {/* To */}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
            <div className="position-relative">
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {}}
              >
                <DKLabel text={''} className="fw-m fs-r" />
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {
                  setOpenAddressPicker(BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS);
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(currentToAddress, false)
                ) && (
                  <DKLabel
                    text={getFormattedAddress(currentToAddress, false)}
                  />
                )}
              </div>
              {openAddressPicker === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
                <DKListPicker2
                  data={tenantInfo?.shippingAddresses ?? []}
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={null}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setOpenAddressPicker(null);
                    setIsToAddressUpdated(true);
                    setCurrentToAddress(addressObj);
                  }}
                  onClose={() => {
                    setOpenAddressPicker(null);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS &&
            getAddressCustomFields(addressType)}
        </div>
      </div>
    );
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {' '}
        {
          <>
            <div
              style={{
                width: 180,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Payer Id"
                value={documentDetails?.contactDto?.taxPayerIdIsrael || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
            <div
              style={{
                width: 180,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Tax Registration Number"
                value={documentDetails?.contactDto?.taxNumber || ''}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                textAlign="left"
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {}}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={true}
              />
            </div>
          </>
        }
      </>
    );
  };

  return (
    <div className="px-2">
      <div className="flex flex-wrap gap-4 justify-between">
        <div style={{ minWidth: 150 }}>
          {receivedGoodDate &&
            getCalendarView(receivedGoodDate, (newDate: any) =>
              validateAndUpdateDate(
                newDate,
                DateFormatService.getDateFromStr(
                  tenantInfo.bookBeginningStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                setreceivedGoodDate,
                'Receive Goods date cannot be before the book beginning date: ' +
                  tenantInfo.bookBeginningStartDate
              )
            )}
        </div>
        <div className="row width-auto align-items-start gap-2">
          {getContactCard('Ship From', BOOKS_ADDRESS_TYPES.SHIP_FROM)}
          {getContactCard('Ship To', BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS)}
        </div>
        <div className="row width-auto align-items-start gap-2">
          {tenantInfo.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
        </div>
      </div>
      {props?.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER && (
        <div>{showCustomFieldView()}</div>
      )}
      {showErrorMessage()}
      {receivedGoodItems &&
        defaultWarehouse &&
        receivedGoodItems?.map((item, i) => {
          item.warehouseCode = item.warehouseCode
            ? item.warehouseCode
            : defaultWarehouse.code;
          const pendingQuantity = Utility.pendingToBeReceivedQuantity(item);
          if (pendingQuantity <= 0) {
            return;
          }
        })}
      {/*  */}
      <FulfillmentGrid
        warehouseProduct={
          allWarehouseWithRRBCombination?.content?.filter(
            (ele: any) => ele.warehouseType === 'NONE'
          ) || []
        }
        fulfillmentItems={receivedGoodItems}
        documentDetails={documentDetails}
        completeProcess={completeProcess}
        module={DOC_TYPE.GOODS_RECEIPT}
        defaultWarehouse={defaultWarehouse?.code}
        parentDocumentType={docType}
        parentDocumentDetails={documentDetails}
        docType={docType}
        onItemsUpdated={(data: ReceivedGoodItemState) => onItemUpdated(data)}
        updateFulfillmentData={(data: any, childConfig: any) => {
          setReceivedGoodItems(data);
          setChildColumnConfig(childConfig);
        }}
      />
      {/* <div
        className="flex row-responsive justify-content-between invoice-table show-scroll-bar column tableFixHead mt-l"
        style={{
          // position: 'relative',
          // top: '50px'
          overflow: receivedGoodItems?.length > 4 ? 'auto' : 'visible'
        }}
      >
        <table
          ref={lineItemsTable}
          className="table mb-51"
          style={{ width: '100%' }}
        >
          <thead>
            <tr>
              <th className="text-left" style={{ width: 150, minWidth: 200 }}>
                Products
              </th>
              <th className="text-left">Description</th>
              <th className="text-center" style={{ width: 200, minWidth: 200 }}>
                Warehouse
              </th>
              <th
                className="text-right"
                style={{ width: 60, minWidth: 60, maxWidth: 100 }}
              >
                UOM
              </th>
              <th className="text-right" style={{ width: 100, minWidth: 100 }}>
                Required
              </th>
              <th className="text-right" style={{ width: 100, minWidth: 100 }}>
                Receiving
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {receivedGoodItems &&
              defaultWarehouse &&
              receivedGoodItems?.map((item, i) => {
                item.warehouseCode = item.warehouseCode
                  ? item.warehouseCode
                  : defaultWarehouse.code;
                const pendingQuantity =
                  Utility.pendingToBeReceivedQuantity(item);
                if (pendingQuantity <= 0) {
                  return;
                }
                return (
                  <ReceiveGoodsItem
                    items={item}
                    defaultWarehouse={defaultWarehouse.code}
                    docType={docType}
                    onItemsUpdated={(data: ReceivedGoodItemState) =>
                      onItemUpdated(data)
                    }
                    parentDocumentType={docType}
        parentDocumentDetails={documentDetails}
        />
         );
              })}
          </tbody>
        </table>
      </div> */}
      <div className="parent-width">{showAttachmentView()}</div>
    </div>
  );
};

export default ReceivedGoods;
//
