import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  SORT_DIRECTION
} from '../../Components/Mrp/Constants/HolidayList';
import ApiConstants from '../../Constants/ApiConstants';
import httpClient from '../Interceptor';
/**
 *
 * @param params params for fetching holiday list like limit, page, sort, sortDir
 * @returns promise of fetching holiday list
 */
export const fetchHolidayList = (
  params: HolidayListParamsType = {
    limit: DEFAULT_PAGE_SIZE,
    page: DEFAULT_PAGE,
    sortDir: SORT_DIRECTION.DESC,
    search: '',
    sort: '',
    query: ''
  }
) => {
  return httpClient.get(ApiConstants.URL.MRP.HOLIDAY_LIST.FETCH, { params });
};
/**
 *
 * @param params params for creating holiday list if any
 * @param payload payload for creating holiday list refer IHolidayListPayload interface
 * @returns promise of creating holiday list
 */
export const addHolidayList = (
  payload: IHolidayListPayload,
  params: any = {}
) => {
  return httpClient.post(ApiConstants.URL.MRP.HOLIDAY_LIST.ADD_LIST, payload, {
    params
  });
};
/**
 *
 * @param params params for updating holiday list if any
 * @param payload payload for updating holiday list refer IHolidayListPayload interface
 * @returns promise of updating holiday list
 */
export const updateHolidayList = (
  payload: IHolidayListPayload,
  params: any = {}
) => {
  return httpClient.put(
    ApiConstants.URL.MRP.HOLIDAY_LIST.UPDATE_LIST(payload.id),
    payload,
    { params }
  );
};
/**
 *
 * @param listId id of the holiday list to be deleted
 * @returns promise of deleting holiday list
 */
export const deleteHolidayList = (listId: string) => {
  return httpClient.delete(
    ApiConstants.URL.MRP.HOLIDAY_LIST.DELETE_LIST(listId)
  );
};
export const transformHolidayListResponse = (response: any) => {
  let data = { ...response };
  data.content.forEach((item: any) => {
    item['numberOfHolidays'] = item?.holidayListDetails?.length || 0;
  });
  return data;
};
export const getEmptyHolidayObj = ({ srNo }: { srNo: number }): IHoliday => {
  return {
    srNo,
    holidayDate: '',
    description: '',
    invalidFields: ['holidayDate']
  };
};
/**
 * @description type declaration for params for fetching holiday list
 */
export type HolidayListParamsType = {
  limit?: number;
  page?: number;
  sort?: string;
  sortDir?: SORT_DIRECTION;
  [key: string]: any;
};

/**
 * @description interface for payload of creating or updating holiday list
 */
export interface IHolidayListPayload {
  id?: number;
  name: string;
  fromDate: string;
  toDate: string;
  holidayListDetails: IHoliday[];
}
/**
 * @description interface for holiday
 */
export interface IHoliday {
  srNo: string | number;
  holidayDate: string | Date;
  description: string;
  [key: string]: any;
}
