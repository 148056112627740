import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import TaxService from '../../Services/Tax';
import { SliceManager } from './SliceManager';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchTaxTable = createAsyncThunk('TaxTable', async () => {
  const response = await TaxService.getTaxTable();
  return response;
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const TaxSlice = createSlice({
  name: 'taxTable',
  initialState,
  reducers: {
    addTaxColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          editable: config.columnCode === 'type' ? false : config.editable
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addColumnToTaxColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxTable.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTaxTable.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let CoaList: any = action.payload;
        state.data = CoaList;
        SliceManager.setSliceData('selectTax', state.data);
      });
  }
});

export const { addTaxColumnConfig, addColumnToTaxColumnConfig } =
  TaxSlice.actions;

export const selectTax = (state: RootState) => state.taxTable.data;
export const selectTaxColumnConfig = (state: RootState) =>
  state.taxTable.columnConfig;
export const selectTaxColumnConfigTableId = (state: RootState) =>
  state.taxTable.configTableId;

export default TaxSlice.reducer;
