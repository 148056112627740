export enum PROCESS_QC_STATUS {
  QUANTITY_ASSIGNED = 'QUANTITY_ASSIGNED',
  QA_DONE = 'QA_DONE',
  OPEN = 'OPEN',
  QC_DONE_UI_ONLY = 'QC_DONE',
  NOT_APPLICABLE_UI_ONLY = 'NOT_APPLICABLE',
  COMPLETE = 'COMPLETE'
}

export enum PROCESS_TYPES {
  CONSUMPTION = 'Consumption',
  PRODUCTION = 'Production',
  PROCESSING = 'Processing'
}
