import en from './English/en'
import fr from './French/fr'
import id from './Indonesia/id';
import zh from './Chinese/zh';
import hi from './India/hi';
import es from "./Spanish/es";
import pt from "./Portuguese/pt";
import de from "./German/de";
import it from "./Italian/it";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import ar from './Arabic/ar';

export function getLocalisedText(localiseString) {
    let dataStrings = {}
    if (TemplateSettingsManager.defaultTemplateSettings === undefined || TemplateSettingsManager.defaultTemplateSettings === null) {
        dataStrings = en.translations
        return dataStrings[localiseString] ? dataStrings[localiseString] : localiseString
    }

    let code = TemplateSettingsManager.defaultTemplateSettings.languageInfo.code
    switch (code) {
        case "hi":
            dataStrings = hi.translations
            break
        case "en":
            dataStrings = en.translations
            break
        case "fr":
            dataStrings = fr.translations
            break
        case "id":
            dataStrings = id.translations
            break
        case "zh":
            dataStrings = zh.translations
            break
        case "es":
            dataStrings = es.translations
            break
        case "pt":
            dataStrings = pt.translations
            break
        case "de":
            dataStrings = de.translations
            break
        case "it":
            dataStrings = it.translations
            break
        case "ar":
            dataStrings = ar.translations
            break
        default:
            dataStrings = en.translations
    }

    return dataStrings[localiseString] ? dataStrings[localiseString] : localiseString

}
