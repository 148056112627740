import { DKButton, DKIcons, DKLabel, DKIcon } from 'deskera-ui-library';
import { useState } from 'react';
import { useAppSelector } from '../../Redux/Hooks';
import { selectCurrency } from '../../Redux/Slices/CommonDataSlice';

interface GSTExchangeRateProps {
  TaxResidencyCurrency: string;
  TransferCurrency: string;
  GstExchangeRate: number;
  onClose: () => void;
  onUpdateTaxRate: (rate: number) => void;
}

export function GSTExchangeRateDialog(props: GSTExchangeRateProps) {
  const [focused, setFocused] = useState(false);
  const currenciesData = useAppSelector(selectCurrency);
  const [tempTaxRate, setTempTaxRate] = useState(1 / props.GstExchangeRate);
  const [taxResidencyCurrency, setTaxResidencyCurrency] = useState(
    currenciesData.find(
      (currency) => currency.currencyCode === props.TaxResidencyCurrency
    )
  );
  const [transferCurrency, setTransferCurrency] = useState(
    currenciesData.find(
      (currency) => currency.currencyCode === props.TransferCurrency
    )
  );

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Set Tax Exchange Rate" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          {
            <DKButton
              title={'Update Tax'}
              className="bg-button text-white"
              onClick={() => {
                if (tempTaxRate) {
                  props.onUpdateTaxRate(1 / tempTaxRate);
                }
              }}
            />
          }
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-width p-r">
        <div className="row parent-width align-items-start">
          <div className="column">
            <div className="text-sm fw-m mb-l">Tax Residency Currency</div>
            <div className="row align-items-center">
              <div className="currency-dropdown-flag mr-s">
                <span
                  className={`currency-dropdown-selector-flag flag ${taxResidencyCurrency.currencyCode}`}
                ></span>
              </div>
              <div>
                {taxResidencyCurrency.currencyName}(
                {taxResidencyCurrency.currencyCode})
              </div>
            </div>
          </div>
          <div className="column" style={{ marginLeft: 100 }}>
            <div className="text-sm fw-m mb-l">Transaction Currency</div>
            <div className="row align-items-center">
              <div className="currency-dropdown-flag mr-s">
                <span
                  className={`currency-dropdown-selector-flag flag ${transferCurrency?.currencyCode}`}
                ></span>
              </div>
              <DKLabel
                text={`${transferCurrency?.currencyName}(${transferCurrency?.currencyCode})`}
              />
            </div>
          </div>
        </div>
        <div className="column parent-width align-items-start mt-xxl number-hide-arrows w-auto">
          <div className="row text-sm fw-m mb-xl parent-width">
            Default Tax Conversion Rate
          </div>
          <div
            className="row align-items-center align-content-center justify-content-center text-2xl bg-gray1 p-v-l pl-10"
            style={{ alignSelf: 'center', width: 'auto' }}
          >
            <div className="">{transferCurrency?.currencyCode} 1</div>
            <div className="">
              <DKIcon
                className="ml-xxl mr-xl opacity-50"
                style={{
                  transform: 'rotate(90deg)'
                }}
                src={DKIcons.ic_sort}
              />
            </div>
            <div>{taxResidencyCurrency.currencyCode}</div>
            <input
              onBlur={(e) => {
                const rate = e.target.value ? +e.target.value : tempTaxRate;
                setFocused(false);
                setTempTaxRate(rate);
              }}
              onFocus={(e) => setFocused(true)}
              className={`row text-align-left ml-s outline-none p-h-xs bg-transparent`}
              value={focused ? tempTaxRate : tempTaxRate.toFixed(6)}
              type={'number'}
              onChange={(e: any) => {
                if (+e.target.value !== 0) {
                  setTempTaxRate(+e.target.value);
                }
              }}
              style={{ width: 160 }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 570,
          width: '90%',
          maxHeight: '95%',
          height: 300,
          padding: 0
        }}
      >
        {getHeader()}
        {props.TaxResidencyCurrency && props.TransferCurrency && getBody()}
      </div>
    </div>
  );
}
