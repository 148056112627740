import React from 'react';
import { DKLabel, DKLine } from 'deskera-ui-library';
import DKDateTime from './DKDateTime';

const DKDateTimePickerPopup = ({ title, selectedDate, is12Hour, onSave, onClose, showCalendarView }) => {
    return (
        <div className="transparent-background">
            <div className="popup-window dk-input-datetime-popup">
                <DKLabel text={title} className="fw-m fs-m mb-m" />
                <DKLine className="mb-m" />
                <DKDateTime
                    is12Hour={is12Hour}
                    selectedDate={selectedDate}
                    onSave={onSave}
                    onClose={onClose}
                    showCalendarView={showCalendarView}
                />
            </div>
        </div>
    );
};
export default DKDateTimePickerPopup;