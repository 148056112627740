import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { GridState } from '../../../Models/ReduxStore';
import ForecastService from '../../../Services/MRP/Forecast';
import ProductionPlanService from '../../../Services/MRP/ProductionPlan';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchForecast = createAsyncThunk('Forecast', async () => {
  const response = await ForecastService.fetchForecastByPage();
  return response;
});

export const MRPForecastSlice = createSlice({
  name: 'mrpForecast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchForecast.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchForecast.rejected, (state) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
      })
      .addCase(fetchForecast.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectForeCasts = (state: RootState) => state.mrpForecast.data;

export const selectForeCastsLoadingStatus = (state: RootState) =>
  state.mrpForecast.gridContentLoadingStatus;

export const selectForeCastsColumnConfig = (state: RootState) =>
  state.mrpForecast.columnConfig;

export const selectForeCastsColumnConfigTableId = (state: RootState) =>
  state.mrpForecast.configTableId;

export const selectRefreshCallbackForeCast = (state: RootState) =>
  state.mrpForecast.refreshCallback;

export default MRPForecastSlice.reducer;
