import france from '../../../Assets/flags/france.svg';
import germany from '../../../Assets/flags/germany.svg';
import india from '../../../Assets/flags/india.svg';
import indonesia from '../../../Assets/flags/indonesia.svg';
import spain from '../../../Assets/flags/spain.svg';
import uk from '../../../Assets/flags/uk.svg';
import united_states from '../../../Assets/flags/united-states.svg';
import { LANGUAGES, LANGUAGES_CODE } from '../../../Constants/Constant';

export const LanguageMenuList = [
  {
    title: LANGUAGES.ENGLISH_US,
    code: LANGUAGES_CODE.ENGLISH_US,
    icon: united_states
  },
  {
    title: LANGUAGES.BAHASA_INDONESIA,
    code: LANGUAGES_CODE.BAHASA_INDONESIA,
    icon: indonesia
  },
  {
    title: LANGUAGES.FRENCH,
    code: LANGUAGES_CODE.FRENCH,
    icon: france
  },
  {
    title: LANGUAGES.ENGLISH_UK,
    code: LANGUAGES_CODE.ENGLISH_UK,
    icon: uk
  },
  {
    title: LANGUAGES.GERMAN,
    code: LANGUAGES_CODE.GERMAN,
    icon: germany
  },
  {
    title: LANGUAGES.SPANISH,
    code: LANGUAGES_CODE.SPANISH,
    icon: spain
  },
  {
    title: LANGUAGES.HINDI,
    code: LANGUAGES_CODE.HINDI,
    icon: india
  },
  {
    title: LANGUAGES.TAMIL,
    code: LANGUAGES_CODE.TAMIL,
    icon: india
  },
  {
    title: LANGUAGES.PUNJABI,
    code: LANGUAGES_CODE.PUNJABI,
    icon: india
  },
  {
    title: LANGUAGES.GUJARATI,
    code: LANGUAGES_CODE.GUJARATI,
    icon: india
  },
  {
    title: LANGUAGES.MARATHI,
    code: LANGUAGES_CODE.MARATHI,
    icon: india
  },
  {
    title: LANGUAGES.BENGALI,
    code: LANGUAGES_CODE.BENGALI,
    icon: india
  },
  {
    title: LANGUAGES.KANNADA,
    code: LANGUAGES_CODE.KANNADA,
    icon: india
  },
  {
    title: LANGUAGES.MALAYALAM,
    code: LANGUAGES_CODE.MALAYALAM,
    icon: india
  },
  {
    title: LANGUAGES.TELUGU,
    code: LANGUAGES_CODE.TELUGU,
    icon: india
  }
];
