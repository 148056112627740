import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { CalendarConstants } from './CalendarConstants';
import DayContainer from './DayContainer';
import MonthContainer from './MonthContainer';
import './Calendar.css';
import next_icon from '../../Assets/Icons/ic_arrow_right.png';
import before_icon from '../../Assets/Icons/ic_arrow_left.png';
import { getDateAsString } from '../DocumentForm/NewDocumentHelper';
// import DKLabel from '../DKLabel';
// import { getDateAsString } from '../../Utilities/DKDateUtility';
// import LocalStorage, { LOCAL_STORAGE_KEYS } from '../../Utilities/LocalStorage';

// interface Props {
//     selectedDate?: Date
//     selectedStartDate?: Date
//     selectedEndDate?: Date
//     onSelectDate: (newDate: Date) => void
//     onSelectDateRange: (newStartDate?: Date, newEndDate?: Date) => void
//     isDateRange: boolean
// }

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      thisMonth: this.getMonth(),
      nextMonth: this.getNextMonth,
      year: this.props.selectedDate
        ? this.props.selectedDate.getFullYear()
        : new Date().getFullYear(), //add support for start date
      nextYear: this.getNextYear(),
      dateList: [],
      nextDateList: [],
      nextMonth: this.getNextMonth()
    };
  }
  getMonth() {
    if (this.props.isDateRange) {
      return this.props.selectedStartDate
        ? this.props.selectedStartDate.getMonth() + 1
        : new Date().getMonth() + 1;
    } else {
      return this.props.selectedDate
        ? this.props.selectedDate.getMonth() + 1
        : new Date().getMonth() + 1;
    }
  }

  getNextMonth() {
    if (this.props.isDateRange) {
      return this.props.selectedStartDate
        ? this.props.selectedStartDate.getMonth() + 2
        : new Date().getMonth() + 2;
    } else {
      return this.props.selectedDate
        ? this.props.selectedDate.getMonth() + 2
        : new Date().getMonth() + 2;
    }
  }

  getNextYear() {
    let nextYear;
    if (this.props.selectedDate) {
      nextYear = this.props.selectedDate.getFullYear();
    } else {
      nextYear = new Date().getFullYear();
    }
    return nextYear;
  }

  getNextMonth() {
    if (this.props.isDateRange) {
      return this.props.selectedStartDate
        ? this.props.selectedStartDate.getMonth() + 2
        : new Date().getMonth() + 2;
    } else {
      return this.props.selectedDate
        ? this.props.selectedDate.getMonth() + 2
        : new Date().getMonth() + 2;
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
    this.updateDateLists();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (
      !domNode ||
      (!domNode.parentNode.contains(event.target) &&
        !domNode.contains(event.target))
    ) {
      if (this.props.onClickOutside) {
        this.props.onClickOutside();
      }
    }
  }

  render() {
    return (
      <div
        className={`column ${
          this.props.className ? this.props.className : ''
        } z-index-6`}
        style={{ border: '1px solid #ccc', borderRadius: 5 }}
      >
        <div className="row align-items-start">
          <div
            style={{
              position: this.props.positionX ? 'fixed' : 'relative',
              left: this.props.positionX,
              top: this.props.positionY,
              // maxHeight: CalendarConstants.calendar.maxHeight,
              // maxWidth: CalendarConstants.calendar.maxWidth,
              width: 230,
              height: CalendarConstants.calendar.height,
              color: CalendarConstants.dayContainer.textColor,
              padding: 10
            }}
            className="calendarDiv"
          >
            <div
              className="calendarHeaderDiv row"
              style={{
                backgroundColor: CalendarConstants.calendar.backgroundColor
              }}
            >
              <div className="row" style={{ width: 'auto' }}>
                {this.renderPreviousMonthSelectionComponent()}
                {!this.props.isDateRange &&
                  this.renderNextMonthSelectionComponent()}
              </div>
              <MonthContainer
                style={{ paddingRight: 20 }}
                isLongFormat={CalendarConstants.monthContainer.isLongMonth}
                month={this.state.thisMonth}
                year={this.state.year}
              />
            </div>
            <div
              className="calendarHeaderDiv mt-l"
              style={{
                backgroundColor: CalendarConstants.calendar.backgroundColor,
                height: '100%'
              }}
            >
              <DayContainer
                selectedDate={
                  this.props.selectedDate ? this.props.selectedDate : new Date()
                }
                dateList={this.state.dateList}
                month={this.state.thisMonth}
                year={this.state.year}
                onSelectDate={(val) => this.props.onSelectDate(val)}
                isDateRange={this.props.isDateRange}
                selectedStartDate={this.props.selectedStartDate}
                selectedEndDate={this.props.selectedEndDate}
                onSelectDateRange={(start, end) => {
                  this.props.onSelectDateRange(start, end);
                }}
              />
            </div>
          </div>
          {this.props.isDateRange && (
            <div
              style={{
                position: this.props.positionX ? 'fixed' : 'relative',
                left: this.props.positionX,
                top: this.props.positionY,
                // maxHeight: CalendarConstants.calendar.maxHeight,
                // maxWidth: CalendarConstants.calendar.maxWidth,
                width: 230,
                height: CalendarConstants.calendar.height,
                color: CalendarConstants.dayContainer.textColor,
                padding: 10
              }}
              className="calendarDiv ml-m"
            >
              <div
                className="calendarHeaderDiv"
                style={{
                  backgroundColor: CalendarConstants.calendar.backgroundColor
                }}
              >
                <MonthContainer
                  style={{ paddingLeft: 20 }}
                  isLongFormat={CalendarConstants.monthContainer.isLongMonth}
                  month={this.state.nextMonth}
                  year={this.state.nextYear}
                />
                {this.renderNextMonthSelectionComponent()}
              </div>
              <div
                className="calendarHeaderDiv mt-l"
                style={{
                  backgroundColor: CalendarConstants.calendar.backgroundColor,
                  height: '100%'
                }}
              >
                <DayContainer
                  selectedDate={
                    this.props.selectedDate
                      ? this.props.selectedDate
                      : new Date()
                  }
                  dateList={this.state.nextDateList}
                  month={this.state.nextMonth}
                  year={this.state.nextYear}
                  onSelectDate={(val) => this.props.onSelectDate(val)}
                  isDateRange={this.props.isDateRange}
                  selectedStartDate={this.props.selectedStartDate}
                  selectedEndDate={this.props.selectedEndDate}
                  onSelectDateRange={(start, end) => {
                    this.props.onSelectDateRange(start, end);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="mt-s border-box fw-m py-3"
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: 0.7,
            borderTopColor: 'lightgray',
            textAlign: 'center',
            width: '100%',
            color: 'black'
          }}
        >
          {this.getSelectedDatesString()}
        </div>
      </div>
    );
  }

  getSelectedDatesString() {
    if (this.props.isDateRange) {
      if (this.props.selectedStartDate && this.props.selectedEndDate) {
        return (
          getDateAsString(this.props.selectedStartDate, 'dd-mm-yyyy') +
          ' to ' +
          getDateAsString(this.props.selectedEndDate, 'dd-mm-yyyy')
        );
      } else {
        return '';
      }
    } else {
      if (this.props.selectedDate) {
        return getDateAsString(this.props.selectedDate, 'dd-mm-yyyy');
      } else {
        return '';
      }
    }
  }

  renderNextMonthSelectionComponent() {
    return (
      <div className=" cursor-hand ">
        <img
          className="optionImage "
          style={{ height: 12 }}
          src={next_icon}
          alt={''}
          onClick={() => this.onMonthChanged('next')}
        />
      </div>
    );
  }

  renderPreviousMonthSelectionComponent() {
    return (
      <div className=" cursor-hand ">
        <img
          className="optionImage"
          src={before_icon}
          style={{ height: 12 }}
          alt={''}
          onClick={() => this.onMonthChanged('before')}
        />
      </div>
    );
  }

  updateDateLists() {
    let newDateList = this.calculateDateList(
      this.state.thisMonth,
      this.state.year
    );
    let newNextDateList = this.calculateDateList(
      this.state.nextMonth,
      this.state.nextYear
    );

    this.setState({
      dateList: newDateList,
      nextDateList: newNextDateList
    });
  }

  onMonthChanged(action) {
    var newPrevMonth = this.state.thisMonth;
    var newNextMonth = this.state.nextMonth;
    var newYear = this.state.year;
    var newNextYear = this.state.nextYear;

    if (action === 'before') {
      newPrevMonth = this.state.thisMonth - 1;
      newNextMonth = this.state.nextMonth - 1;

      if (newPrevMonth < 1) {
        newYear = newYear - 1;
        newPrevMonth = 12;
      }
      if (newNextMonth < 1) {
        newNextYear = newNextYear - 1;
        newNextMonth = 12;
      }
      this.setState(
        {
          thisMonth: newPrevMonth,
          nextMonth: newNextMonth,
          year: newYear,
          nextYear: newNextYear
        },
        () => {
          this.updateDateLists();
        }
      );
    } else if (action === 'next') {
      newPrevMonth = this.state.thisMonth + 1;
      newNextMonth = this.state.nextMonth + 1;

      if (newPrevMonth > 12) {
        newYear = newYear + 1;
        newPrevMonth = 1;
      }
      if (newNextMonth > 12) {
        newNextYear = newNextYear + 1;
        newNextMonth = 1;
      }
      this.setState(
        {
          thisMonth: newPrevMonth,
          nextMonth: newNextMonth,
          year: newYear,
          nextYear: newNextYear
        },
        () => {
          this.updateDateLists();
        }
      );
    }
  }

  calculateDateList(month, year) {
    const normalYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const leapYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var newDateList = [];

    var date = new Date(year, month - 1);

    var isLeapYear = date.getFullYear() % 4 === 0;
    var prevMonth = date.getMonth();
    var prevYear = date.getFullYear();

    var nextMonth = date.getMonth();
    var nextYear = date.getFullYear();

    prevMonth = prevMonth - 1;
    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear = prevYear - 1;
    }

    nextMonth = nextMonth + 1;

    if (nextMonth > 12) {
      prevMonth = 1;
      prevYear = prevYear + 1;
    }

    var totalDate = isLeapYear
      ? leapYear[date.getMonth()]
      : normalYear[date.getMonth()];
    if (date.getDay() === 0) {
      var arraySize = totalDate === 28 ? 28 : 35;
      for (var i = 1; i < totalDate + 1; i++) {
        newDateList.push(i.toString());
      }
      var offsetList = arraySize - newDateList.length;
      for (var i = 1; i < offsetList + 1; i++) {
        newDateList.push('');
      }
      return newDateList;
    } else {
      let totalPrevDate = date.getDay() + 1;
      for (var i = 1; i < totalPrevDate; i++) {
        newDateList.push('');
      }
      for (var i = 1; i <= totalDate; i++) {
        newDateList.push(i.toString());
      }

      return newDateList;
    }
  }
}
