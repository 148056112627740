import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKTooltipWrapper,
  DKIcon,
  DKIcons,
  DKCheckMark,
  DKSpinner,
  showAlert,
  Toggle
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { INPUT_VIEW_DIRECTION } from '../../Constants/Constant';

import Utility, { deepClone } from '../../Utility/Utility';
import { getAmountBlockWithCurrencyForRevRec } from '../Reports/Financial/Parser/SharedParser';
const ViewArrangement = (props: any) => {
  const initialState: any = {
    id: '',
    productName: '',
    basePrice: '',
    quantity: '',
    discount: '',
    salesAmount: '',
    revAmount: '',
    revAllocationRatio: '',
    revRecRule: '',
    linkedItemCode: '',
    revRecForecastRule: '',
    startDate: '',
    endDate: '',
    createRevenuePlansOn: '',
    deferralAccount: '',
    recognitionAccount: '',
    calculatedFairValueAmount: '',
    baseFairValue: '',
    allocationType: '',
    deliveryStatus: '',
    customField: '',
    cfield: ''
  };
  const [popupState, setPopupState] = useState<any>(deepClone(initialState));

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      let data = props.data;

      setPopupState({ ...props.data });
    }
  }, []);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`View Revenue Element`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          {/* <DKButton
            title={props.data ? 'Update' : 'Create'}
            className="bg-button text-white"
            onClick={() => {
              if (props.data) onEdit();
              else onSave();
            }}
          /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className="transparent-background z-10 parent-width "
      style={{ pointerEvents: 'auto' }}
    >
      <div
        className="popup-window"
        style={{
          width: '60%',
          minWidth: 445,
          height: '90%',
          padding: 0,
          paddingBottom: 20,
          overflowY: 'auto'
        }}
      >
        {getHeader()}{' '}
        <div className="column parent-width justify-content-between p-h-r p-v-s p-2">
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`ID`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.id}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Source`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.linkedDocSeqCode}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Quantity`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.quantity}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Item/Product`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.productName}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Revenue Recognition Rule`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.revRecRuleName}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Delivery Status`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.deliveryStatus}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Sales Amount`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.salesAmountInBase || popupState.salesAmount}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Discount`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.discount}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Revenue Amount`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.revAmountInBase || popupState.revAmount}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Revenue Allocation Ratio`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.revAllocationRatio}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Recognition Account`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.recognitionAccountName}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Start Date`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.startDate}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`End Date`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.endDate}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Rev Rec Forecast Rule`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.revRecForecastRuleName}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Create Revenue Plans On`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.createRevenuePlansOn}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Deferral Account`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.deferralAccountName}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Calculated Fair Value Amount`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={getAmountBlockWithCurrencyForRevRec(
                popupState.calculatedFairValueAmount
              )}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Allocation Type`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.allocationType}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>

          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`Base Fair Value`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.baseFairValue}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <div className="row"></div>
          </div>

          {props.data.customField?.map((cf: any, index: any) => {
            if (index % 2 === 0) {
              return (
                <div className="row p-2">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    title={props.data.customField[index].label}
                    className={`mb-4`}
                    type={INPUT_TYPE.TEXT}
                    value={
                      props.data?.cfield
                        ? props.data?.cfield[
                            `${props.data.customField[index].code}`
                          ]
                        : ''
                    }
                    onChange={(value: any) => {}}
                    required={false}
                    readOnly={true}

                    // canValidate={canValidate}
                  />
                  {index + 1 < props.data.customField.length && (
                    <DKInput
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      title={props.data.customField[index + 1].label}
                      className={`mb-4 ml-2`}
                      type={INPUT_TYPE.TEXT}
                      value={
                        props.data?.cfield
                          ? props.data?.cfield[
                              `${props.data.customField[index + 1].code}`
                            ]
                          : ''
                      }
                      onChange={(value: any) => {}}
                      required={false}
                      readOnly={true}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ViewArrangement;
