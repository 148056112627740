import { useState } from 'react';
import PopupWrapper from '../PopupWrapper';
import { POPUP_CLICK_TYPE, POPUP_TYPE } from '../../Constants/Constant';
import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import Utility from '../../Utility/Utility';

interface ReturnsReasonDropDownProps {
  reason: string;
  setReason: (reason: any) => void;
  options: string[];
}

export function ReturnsReasonDropDown({
  reason,
  setReason,
  options
}: ReturnsReasonDropDownProps) {
  const [showCustomReasonPopup, setShowCustomReasonPopup] =
    useState<boolean>(false);

  function CustomReasonPop() {
    const [value, setValue] = useState<any>('');
    const [error, setError] = useState<any>({
      error: false,
      message: ''
    });
    function reasonInputValidator(value: string) {
      if (value.trim() === '') {
        return {
          error: true,
          message: 'Reason cannot be empty'
        };
      }
      return {
        error: false,
        message: ''
      };
    }
    return (
      <PopupWrapper
        title="Custom Reason"
        width={'400px'}
        type={POPUP_TYPE.POPUP}
        btnList={[
          {
            title: 'Cancel',
            class: 'bg-gray1 border-m mr-s',
            clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
          },
          {
            title: 'Save',
            class: 'bg-app text-white mr-s',
            clickAction: POPUP_CLICK_TYPE.SAVE_REPORT
          }
        ]}
        clickAction={(action: any) => {
          switch (action.type) {
            case POPUP_CLICK_TYPE.CLOSE_POPUP:
              setShowCustomReasonPopup(false);
              break;
            case POPUP_CLICK_TYPE.SAVE_REPORT:
              const errors = reasonInputValidator(value);
              setError(errors);
              if (errors.error) {
                return;
              }
              setShowCustomReasonPopup(false);
              setReason(value);
              break;
            default:
              break;
          }
        }}
      >
        <div>
          <DKInput
            type={INPUT_TYPE.TEXT}
            className="w-full mb-l"
            value={value}
            canValidate={Utility.isEmpty(value?.trim())}
            errorMessage="Custom reason cannot be empty"
            onChange={(value: any) => {
              const errors = reasonInputValidator(value);
              setError(errors);
              setValue(value);
            }}
            placeholder="Enter Reason"
          />
        </div>
      </PopupWrapper>
    );
  }

  const getReasonOptions = () => {
    const staticOptions = [
      'Sales/Purchase Return',
      'Post Sales/Purchase Discount',
      'Deficiency in Services',
      'Correction in Invoice',
      'Change in POS',
      'Finalization of provisional assessment',
      'Others'
    ];

    const tempOptions = [...staticOptions, ...options];
    const uniqueOptions = Array.from(new Set(tempOptions));
    return uniqueOptions.length ? uniqueOptions : [];
  };

  return (
    <div className="relative" style={{ width: 280 }}>
      <DKInput
        title="Reason"
        required={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={reason}
        onChange={(value: string) => {
          setReason(value);
        }}
        dropdownConfig={{
          title: 'Select a reason',
          allowSearch: true,
          searchableKey: '',
          style: { minWidth: 280 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data: getReasonOptions(),
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj} />;
          },
          button: {
            title: `Add custom reason`,
            className: 'bg-app text-white',
            onClick: () => {
              setShowCustomReasonPopup(true);
            }
          }
        }}
      />
      {showCustomReasonPopup && <CustomReasonPop />}
    </div>
  );
}
