import { LocalizedKeys } from './Localization';

export const indiaLocalizedKeys: any = {
  'Fulfill Your Invoice': 'Delivery Order for your Invoice',
  'Fulfill Your': 'Delivery Order for your',
  'Fulfill Your Sales Order': 'Delivery Order for your Sales Order',
  'Direct Fulfillment': 'Direct Delivery Order',
  'Fulfill with 3rd party logistics': 'Deliver via 3rd party logistics',
  'Fulfill in the Warehouse Module': 'Deliver in the warehouse module',
  'Direct Fulfillment of': 'Delivery Order -',
  'Direct Fulfillment of Sales Order': 'Delivery Order - Sales Order',
  'Direct Fulfillment of Quotation': 'Delivery Order - Quotation',
  'Direct Fulfillment of Invoice': 'Delivery Order - Invoice',
  Fulfill: 'Create Delivery Order',
  'Fulfillment Records': 'Delivery Orders',
  'Fulfill this Sales Order in full before converting in to a Full Invoice.':
    'Create Delivery Order for this Sales Order before converting in to a full Invoice',
  'Fulfill and Convert': 'Create Delivery Order and Convert',
  'Outstanding Delivery Order Report': 'Outstanding Delivery Order Report',
  'Not Fulfilled': 'Not Delivered',
  Fulfilled: 'Delivered',
  Fulfillment: 'Delivery Order',
  'Fulfill this Quote in full before converting in to a Full Invoice':
    'Create Delivery Order for this Quote before converting in to a full Invoice',
  unfulfilled: 'Not Delivered',
  'do the fulfillment': 'create Delivery Order',
  'Fulfill this': 'create Delivery Order for this',
  'Partially Fulfilled': 'Partially Delivered',
  Fulfillments: "Delivery Orders",
  'Fulfill Date': "Delivery Order Date",
  'Fulfill All': "Deliver All",
  'quotation_report': 'Quotation Report',
  'Fulfillment - Tolerance Level report': 'Delivery Order - Tolerance Level report'
};
