import { DKInput, INPUT_TYPE, DKLabel } from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../Constants/Constant';
import { ICommonStatePicker } from '../../Models/Interfaces';

export default function CommonStateListPicker(props: ICommonStatePicker) {
  return (
    <DKInput
      value={props.value}
      title={props.title}
      direction={props.direction ?? INPUT_VIEW_DIRECTION.HORIZONTAL}
      formatter={(obj: any) => {
        return props.formatterProperty
          ? obj[`${props.formatterProperty}`]
          : obj;
      }}
      type={INPUT_TYPE.DROPDOWN}
      required={props.isMandatory ?? false}
      canValidate={props.hasError ?? false}
      onChange={(value: any) => {
        props.onChange(value);
      }}
      readOnly={props.readOnly ?? false}
      valueStyle={
        props.valueStyle ?? {
          backgroundColor: props.bgColor ? props.bgColor : ''
        }
      }
      titleStyle={{
        color: props.titleColor ? props.titleColor : 'gray'
      }}
      className={props.className ?? ''}
      errorMessage={props.errMsg ?? ''}
      dropdownConfig={{
        allowSearch: props.isSearchable ?? false,
        searchableKey: props.searchableKey ? props.searchableKey : 'label',
        data: props.dropdownOptions ?? [],
        renderer: (index: any, obj: any) => {
          return (
            <div className="flex flex-row w-full justify-content-between">
              <DKLabel
                style={{ fontSize: '13px' }}
                className="text-base border-radius-s"
                text={obj.label}
              />

              <DKLabel
                style={{ fontSize: '13px' }}
                className="text-base border-radius-s opacity-50"
                text={obj?.code ?? ''}
              />
            </div>
          );
        }
      }}
    />
  );
}
