import { DKSpinner, showAlert } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_convert_fulfill from '../../Assets/Icons/ic_convert_fulfill.svg';
import ic_convert_only from '../../Assets/Icons/ic_convert_only.svg';
import ic_convert_partially from '../../Assets/Icons/ic_convert_partially.svg';
import {
  APPROVAL_STATUS,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  LABELS,
  MODULES_NAME
} from '../../Constants/Constant';
import { DraftTypes } from '../../Models/Drafts';
import { InvoiceInitialState } from '../../Models/Invoice';
import { Quote } from '../../Models/Quote';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { selectFullInvoiceCustomNumbersFormats } from '../../Redux/Slices/CustomNumberFormat';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import AttachmentService from '../../Services/Attachment';
import ContactService from '../../Services/Contact';
import { localizedText } from '../../Services/Localization/Localization';
import QuotationService from '../../Services/Quotation';
import { isSalesOrderVisible } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';
import { getUpdatedQuoteObject } from './QuoteHelper';
import { checkUserPermission } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

interface QuoteToInvoiceProps {
  quoteDocument: Quote;
  closePopup: () => void;
  closeParentDoc?: () => void;
}

enum QuoteConversionAction {
  PARTIAL_INVOICE = 'partial_invoice',
  CONVERT_ONLY = 'convert_only',
  CONVERT_AND_FULFILL = 'convert_and_fulfill'
}

export default function QuoteToInvoicePopup(props: QuoteToInvoiceProps) {
  const [quote, setQuote] = useState(props.quoteDocument);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [type, setType] = useState<QuoteConversionAction>();
  const [contact, setContact] = useState<any>(null);
  const [attachmentIds, setAttachmentsIds] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);

  const dispatch = useAppDispatch();
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const invoiceSequenceFormatsInStore = useAppSelector(
    selectFullInvoiceCustomNumbersFormats
  );

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const detailedContact = await ContactService.getContactDetailsById(
          props.quoteDocument?.contactDto?.id
        );
        setContact(detailedContact);
      } catch (err) {
        console.error('Error loading detailed contact: ', err);
      }
    };
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: MODULES_NAME.QUOTATION,
      EntityId: props.quoteDocument.id
    };

    AttachmentService.getAllAttachments()
      .then((res: any) => {
        let attachmentIds: any[] = [];
        res &&
          res.length &&
          res.forEach((attachment: any) => {
            attachmentIds.push(attachment.attachmentId);
          });
        setAttachmentsIds(attachmentIds);
        setAttachments(attachmentIds.map(String));
      })
      .catch((err: any) => {
        console.error(err);
      });
    fetchContact();
  }, []);

  // Load Quote Details
  const loadQuotationDetails = async (
    code: any,
    convertType: QuoteConversionAction
  ) => {
    QuotationService.getQuoteByCode(code).then(
      (data: any) => {
        let quoteDetailsData: any = getUpdatedQuoteObject(data);

        if (convertType === QuoteConversionAction.CONVERT_AND_FULFILL) {
          if (
            !Utility.checkStockAvailability(
              quoteDetailsData.quotationItemDtoList || []
            )
          ) {
            setIsApiLoading(false);
            setIsOpeningForm(false);
            showAlert(
              'Error',
              'Fulfillment can not be done due to insufficient stock.'
            );
            return;
          }
        }

        setQuote(quoteDetailsData);
        if (!Utility.isEmpty(quoteDetailsData)) {
          setIsApiLoading(false);
          switch (convertType) {
            case QuoteConversionAction.CONVERT_AND_FULFILL:
              quoteDetailsData = {
                ...quoteDetailsData,
                autoFulfill: true,
                fulfillmentComplete: true
              };
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.PARTIAL_INVOICE
              );
              break;
            case QuoteConversionAction.CONVERT_ONLY:
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.CONVERT_ONLY
              );
              break;
            case QuoteConversionAction.PARTIAL_INVOICE:
              prepareQuoteForConversion(
                quoteDetailsData,
                QuoteConversionAction.PARTIAL_INVOICE
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching quote details: ', err);
      }
    );
  };

  const getLinkedDocuments = (selectedQuote: Quote) => {
    let linkedDocuments = [];
    linkedDocuments.push({
      documentCode: selectedQuote.quotationCode as string,
      documentType: selectedQuote.documentType,
      documentSequenceCode: selectedQuote.documentSequenceCode as string
    });
    let linkedWorkOrders =
      selectedQuote?.linkedDocuments?.filter(
        (woItem: any) => woItem?.documentType === DOC_TYPE.WORK_ORDER
      ) || [];
    if (!Utility.isEmpty(linkedWorkOrders)) {
      linkedDocuments = [...linkedDocuments, ...linkedWorkOrders];
    }
    return linkedDocuments;
  };

  const prepareQuoteForConversion = async (
    selectedQuote: Quote,
    action: QuoteConversionAction
  ) => {
    // console.log('Convert to partial invoice...');
    let partialInvoice: any = {
      ...InvoiceInitialState,
      ...selectedQuote
    };
    if (!Utility.isEmpty(contact)) {
      partialInvoice = {
        ...partialInvoice,
        contactDto: contact
      };
    }

    let invoiceSequenceFormat: any;
    if (!Utility.isEmpty(invoiceSequenceFormatsInStore)) {
      invoiceSequenceFormat = invoiceSequenceFormatsInStore?.find(
        (seqFormat: any) => seqFormat?.isDefault
      );
      if (!Utility.isEmpty(invoiceSequenceFormat)) {
        invoiceSequenceFormat = invoiceSequenceFormat?.id;
      } else {
        invoiceSequenceFormat = '';
      }
    }

    let itemsPendingToConvert = 0;
    partialInvoice = {
      ...partialInvoice,
      id: null,
      entityId: partialInvoice.id ? partialInvoice.id : undefined,
      documentType: DOC_TYPE.INVOICE,
      recurring: false,
      recurringActivated: false,
      isPartialInvoice: true,
      backOrder: false,
      documentCode: partialInvoice.quotationCode,
      currencyCode: partialInvoice.currency,
      isConverting: true,
      fulfillmentStatus:
        partialInvoice.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      openingInvoice: partialInvoice.openingInvoice || false,
      // fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
      sourceFulfillmentStatus: partialInvoice.fulfillmentStatus,
      fulfillmentType: partialInvoice.fulfillmentType,
      fulfillmentDate: partialInvoice.fulfillmentDate,
      attachments: partialInvoice.entityId ? attachments : [],
      attachmentIds: partialInvoice.entityId ? attachmentIds : [],
      status: !partialInvoice.id ? DOCUMENT_STATUS.OPEN : partialInvoice.status,
      paymentStatus: partialInvoice.paymentStatus,
      taxInvoiceNo: partialInvoice.taxInvoiceNo,
      paymentInformation: partialInvoice.paymentInformation
        ? partialInvoice.paymentInformation
        : null,
      whtRate:
        partialInvoice.whtRate && partialInvoice.whtRate !== null
          ? partialInvoice.whtRate
          : 0,
      einvoiceInfoIndia: partialInvoice.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: partialInvoice.einvoiceInfoIndiaCancel,
      isCancelEinvoice: partialInvoice.isCancelEinvoice,
      reservedStock: partialInvoice.reservedStock
        ? partialInvoice.reservedStock
        : false,
      linkedDocuments: getLinkedDocuments(selectedQuote),
      contact: {
        ...partialInvoice.contact,
        ...partialInvoice.contactDto,
        address: Utility.getStringAddress(partialInvoice.contactDto)
      },
      salesInvoiceDate: '',
      salesInvoiceDueDate: '',
      salesInvoiceItems: selectedQuote.quotationItemDtoList
        ?.filter(
          (item) =>
            !item.optional && Utility.getPendingQtyForConversion(item) > 0
        )
        .map((item) => {
          const pendingQty = Utility.getPendingQtyForConversion(item);
          if (pendingQty > 0) {
            ++itemsPendingToConvert;
          }

          const qtyToConvert =
            action === QuoteConversionAction.PARTIAL_INVOICE
              ? Utility.getFulfilledQtyForConversion(item)
              : pendingQty;

          return {
            ...item,
            id: undefined,
            isPartialInvoice: true,
            documentItemCode: undefined,
            fulfillmentByDoc: null,
            linkedQuoteItem: item.id,
            taxDetails: item.taxDetails.map((taxDetail: any) => {
              return {
                ...taxDetail,
                id: null,
                additionalTaxIn: null
              };
            }),
            pendingQtyToConvert: pendingQty,
            productQuantity: qtyToConvert,
            uomQuantity: Utility.getUomQuantity(
              qtyToConvert,
              item.documentUOMSchemaDefinition
            ),
            nonEditableColumns: selectedQuote.reservedStock
              ? ['productQuantity', 'uom']
              : [],
            reservedQuantitiesData: item.reservedQuantitiesData?.map(
              (reservedQtyItem: any) => ({
                ...reservedQtyItem,
                availableQuantity: Utility.getUomQuantity(
                  reservedQtyItem.availableQuantity,
                  item.documentUOMSchemaDefinition
                ),
                reservedQuantity: Utility.getUomQuantity(
                  reservedQtyItem.reservedQuantity,
                  item.documentUOMSchemaDefinition
                ),
                advancedTrackingMetaDtos: reservedQtyItem
                  .advancedTrackingMetaDtos?.length
                  ? reservedQtyItem.advancedTrackingMetaDtos?.map(
                      (advTrackingDto: any) => {
                        return {
                          ...advTrackingDto,
                          batchSize: Utility.getUomQuantity(
                            advTrackingDto.batchSize,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantity: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantity,
                            item.documentUOMSchemaDefinition
                          ),
                          reservedQuantityFulfilled: Utility.getUomQuantity(
                            advTrackingDto.reservedQuantityFulfilled,
                            item.documentUOMSchemaDefinition
                          ),
                          batchSizeFulfilled: Utility.getUomQuantity(
                            advTrackingDto.batchSizeFulfilled,
                            item.documentUOMSchemaDefinition
                          )
                        };
                      }
                    )
                  : []
              })
            )
          };
        }),
      sequenceFormat: invoiceSequenceFormat,
      documentSequenceCode: '',
      documentDate: '',
      shipByDate: '',
      validTillDate: '',
      sourceDocTypeForConversion: DOC_TYPE.QUOTE,
      isDocumentTouched: true,
      approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
      multiApprovalDetails: null
    };

    if (itemsPendingToConvert === 0) {
      showAlert(
        '',
        'Sorry, all products in this Quote have been invoiced in full. You cannot convert this Quote to Invoice anymore'
      );
      return;
    }

    const {
      contactDto,
      items,
      quotationCode,
      quotationItemDtoList,
      ...invoiceToForward
    } = partialInvoice;

    let payloadData: any = {
      type: LABELS.INVOICES,
      title: LABELS.INVOICES,
      isMaximized: true,
      isCenterAlign: true,
      populateFormData: invoiceToForward,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig
    };
    if (!Utility.isEmpty(invoiceToForward)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeParentDoc) {
        props.closeParentDoc();
      }
    }
  };

  const isRRBProductPresent = (lineItems: any) => {
    let rrbPresent = false;
    lineItems?.forEach((row: any) => {
      let binCode = row?.product?.binCode;
      let rackCode = row?.product?.rackCode;
      let rowCode = row?.product?.rowCode;
      if (
        !Utility.isEmpty(rowCode) ||
        !Utility.isEmpty(rackCode) ||
        !Utility.isEmpty(binCode)
      ) {
        rrbPresent = true;
      }
    });
    return rrbPresent;
  };
  const checkIsLocalizedUomQty = () => {
    return quote?.quotationItemDtoList?.some(
      (item: any) => item.isLocalizedUomQty
    );
  };

  return (
    <>
      <div className="flex flex-col w-full px-1 pb-3 text-sm p-1">
        <div className="flex flex-row w-full">
          {`You are about to convert this ${
            Utility.isUSorg() ? 'Estimate' : 'Quote'
          }${
            props.quoteDocument?.documentSequenceCode
              ? '(' + props.quoteDocument?.documentSequenceCode + ')'
              : ''
          } to Invoice, please select an
          action below:`}
        </div>
        <div
          className="flex flex-col mt-3 w-full"
          style={{
            pointerEvents: isApiLoading ? 'none' : 'auto',
            cursor: isApiLoading ? 'no-drop' : 'pointer'
          }}
        >
          {!(
            quote.linkedSalesInvoices && quote.linkedSalesInvoices?.length > 0
          ) &&
            !quote.hasPartialInvoice && (
              <div>
                {!Utility.isAdvancedTracking(quote) &&
                  !quote.reservedStock &&
                  quote.fulfillmentType !== FULFILLMENT_TYPE.PICK_PACK_SHIP &&
                  !isSalesOrderVisible() &&
                  !isRRBProductPresent(quote?.quotationItemDtoList) &&
                  !checkIsLocalizedUomQty() &&
                  checkUserPermission(
                    PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
                  ) &&
                  Utility.getDocumentFulfilledQty(
                    quote.quotationItemDtoList || []
                  ) === 0 && (
                    <div
                      className={
                        'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m'
                      }
                      onClick={() => {
                        if (!isOpeningForm) {
                          setIsOpeningForm(true);
                          setType(QuoteConversionAction.CONVERT_AND_FULFILL);
                          setIsApiLoading(true);
                          loadQuotationDetails(
                            quote.quotationCode,
                            QuoteConversionAction.CONVERT_AND_FULFILL
                          );
                        }
                      }}
                    >
                      <div
                        className="flex rounded-l rounded-bl"
                        style={{ height: 80 }}
                      >
                        <img
                          src={ic_convert_fulfill}
                          alt="fulfill and convert"
                          style={{ width: 60 }}
                        />
                      </div>
                      <div
                        className="flex flex-col items-start px-4 leading-5 text-left"
                        style={{ width: '90%' }}
                      >
                        <span className="fw-m text-blue">
                          {localizedText('Fulfill and Convert')}
                        </span>
                        <span className="text-gray">
                          {localizedText(
                            'Fulfill this Quote in full before converting in to a Full Invoice'
                          )}
                        </span>
                      </div>
                      {isApiLoading &&
                        type === QuoteConversionAction.CONVERT_AND_FULFILL && (
                          <div>
                            <DKSpinner
                              iconClassName="ic-r"
                              className="column pl-0 pr-s"
                            />
                          </div>
                        )}
                    </div>
                  )}
                <div
                  className={
                    'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
                  }
                  onClick={() => {
                    if (!isOpeningForm) {
                      setIsOpeningForm(true);
                      setType(QuoteConversionAction.CONVERT_ONLY);
                      setIsApiLoading(true);
                      loadQuotationDetails(
                        quote.quotationCode,
                        QuoteConversionAction.CONVERT_ONLY
                      );
                    }
                  }}
                >
                  <div
                    className="flex rounded-l rounded-bl"
                    style={{ height: 60 }}
                  >
                    <img
                      src={ic_convert_only}
                      alt="convert fully"
                      style={{ width: 60 }}
                    />
                  </div>
                  <div
                    className="flex flex-col items-start px-4 leading-5 text-left"
                    style={{ width: '90%' }}
                  >
                    <span className="fw-m text-blue">Convert Fully</span>
                    <span className="text-gray">
                      {`Convert this ${
                        Utility.isUSorg() ? 'Estimate' : 'Quote'
                      } into a Full Invoice.`}
                    </span>
                  </div>
                  {isApiLoading &&
                    type === QuoteConversionAction.CONVERT_ONLY && (
                      <div>
                        <DKSpinner
                          iconClassName="ic-r"
                          className="column pl-0 pr-s"
                        />
                      </div>
                    )}
                </div>
              </div>
            )}

          {!quote.reservedStock && (
            <div
              className={
                'flex items-center border-radius-m listPickerBG cursor-hand p-h-l border-m mt-3'
              }
              onClick={() => {
                if (!isOpeningForm) {
                  setIsOpeningForm(true);

                  setIsApiLoading(true);
                  setType(QuoteConversionAction.PARTIAL_INVOICE);

                  loadQuotationDetails(
                    quote.quotationCode,
                    QuoteConversionAction.PARTIAL_INVOICE
                  );
                }
              }}
            >
              <div className="flex rounded-l rounded-bl" style={{ height: 60 }}>
                <img
                  src={ic_convert_partially}
                  alt="convert partially"
                  style={{ width: 60 }}
                />
              </div>
              <div
                className="flex flex-col items-start px-4 leading-5 text-left"
                style={{ width: '90%' }}
              >
                <span className="fw-m text-blue">Convert Partially</span>
                <span className="text-gray">
                  {`Create a Partial Invoice from this ${
                    Utility.isUSorg() ? 'Estimate' : 'Quote'
                  }.`}
                </span>
              </div>
              {isApiLoading &&
                type === QuoteConversionAction.PARTIAL_INVOICE && (
                  <div>
                    <DKSpinner
                      iconClassName="ic-r"
                      className="column pl-0 pr-s"
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
