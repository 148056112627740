import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKButton,
  DKIcon,
  showAlert,
  INPUT_TYPE
} from 'deskera-ui-library';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  COLUMN_TYPE,
  CUSTOM_FIELD_TYPE,
  DOC_TYPE,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../Constants/Constant';
import { Attachments } from '../../SharedComponents/Attachments';
import ic_no_data from '../../Assets/Icons/ic_no_data_3.png';
import ProductService from '../../Services/Product';
import { COLUMN_CODE } from '../../Constants/TableConstants';
import { selectProductsColumnConfig } from '../../Redux/Slices/ProductsSlice';
import { useAppSelector } from '../../Redux/Hooks';
import GroupContainer from '../../SharedComponents/GenericGroupContainer';
import { getMainModuleName } from './NewDocumentHelper';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import { DynamicPopupWrapper } from '../PopupWrapper';
import { selectCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';

export interface IProductSelectionPopupProps {
  module: DOC_TYPE;
  onSave: (selectedRows: any[]) => void;
  onCancel: () => void;
}
export function DocProductSelectionPopup(props: IProductSelectionPopupProps) {
  const mainModuleType = getMainModuleName(props.module);
  const transactionType =
    mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.SELL
      ? 'BOTH,SALES'
      : 'BOTH,PURCHASE';
  const [products, setProducts] = useState<any>({});
  const [groupList, setGroupList] = useState([{ name: 'All', id: -1 }]);
  const [currentGroup, setCurrentGroup] = useState(-1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [sortConfig, setSortConfig] = useState<any>({
    sort: null,
    sortDir: null
  });
  const [page, setPage] = useState(0);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({} as any);
  const [query, setQuery] = useState(
    `active=true,hasVariants=false&productTransactionType=${transactionType}`
  );
  const [filterDataForGrid, setFilterDataForGrid] = useState([]);
  const columnConfig = useAppSelector(selectProductsColumnConfig);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const oldProductAPIConfig = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const availableCustomFields = useAppSelector(selectCustomFields);
  const tenantInfo = useAppSelector(activeTenantInfo);

  useEffect(() => {
    oldProductAPIConfig.current = { ...ProductService.apiConfig };
    getGroups();

    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
      ProductService.apiConfig = oldProductAPIConfig.current;
    };
  }, []);

  const getGroups = () => {
    const query = `appName=ERP`;
    ProductService.getProductGroups({
      limit: 100,
      page: 0,
      search: '',
      query
    })
      .then((response: any) => {
        setGroupList([{ name: 'All', id: -1 }, ...response?.content]);
      })
      .catch((err: any) => {
        console.error('Error fetching product groups: ', err);
      });
  };

  const getProductsList = useCallback(() => {
    const oldProdAPIConfig = { ...ProductService.apiConfig };
    ProductService.apiConfig = {
      ...ProductService.apiConfig,
      Limit: 20,
      Query: query,
      IsVariant: undefined,
      Page: page ?? 0,
      Sort: sortConfig.sort,
      SortDir: sortConfig.sortDir,
      SearchTerm: searchText,
      FetchAttachmentDetails: true,
      ProductGroupId: currentGroup !== -1 ? currentGroup : ''
    };
    setIsLoading(true);
    ProductService.getProductsByPage()
      .then((response: any) => {
        setProducts(response || {});
        ProductService.apiConfig = oldProdAPIConfig;
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error('Error fetching products: ', err);
        ProductService.apiConfig = oldProdAPIConfig;
        setIsLoading(false);
      });
  }, [
    currentGroup,
    page,
    query,
    searchText,
    sortConfig.sort,
    sortConfig.sortDir
  ]);

  useEffect(() => {
    getProductsList();
  }, [getProductsList]);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    getProductsList();
  }, [currentGroup, sortConfig, page, searchText, query, getProductsList]);

  const onGroupSelect = (group: any) => {
    setCurrentGroup(group?.id);
    setPage(0);
  };

  const deleteGroupConfirmation = (id: any) => {
    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m'
      },
      {
        title: 'Delete',
        className: 'bg-red text-white ml-r',
        onClick: () => deleteGroup(id)
      }
    ];
    showAlert(
      'Delete Group?',
      'Deleting this product group will delete it permanently, you will not be able to restore it.',
      buttons
    );
  };
  const deleteGroup = (id: any) => {
    ProductService.deleteProductGroup(id).then((response) => {
      getGroups();
      setCurrentGroup(-1);
    });
  };
  const onSort = ({ order, columnData }: any) => {
    let sort = columnData?.key;
    if (sort === 'reorder') {
      sort = 'reorderLevel';
    }
    if (sort === 'restocklevel') {
      sort = 'restockLevel';
    }
    const sortDir = order;
    setSortConfig({ sort, sortDir });
  };
  const onPageChange = (page: any) => {
    setPage(page - 1);
  };

  const getProductGroupControl = () => {
    const listContainer = listContainerRef?.current;
    if (listContainer) {
      return (
        <GroupContainer
          data={groupList}
          selectedId={currentGroup}
          moreListPickerTitle="Show More Groups"
          onChange={onGroupSelect}
          maxWidth={listContainer?.getBoundingClientRect().width}
          allowToAddGroup={false}
          onSave={() => {}}
          allowDelete={true}
          onClickDelete={deleteGroupConfirmation}
        />
      );
    }
  };

  const onAttachmentCLicked = (data: any) => {
    const productList: any[] = products['content'];
    const productId: number = data.rowData.id;
    const productData = productList.filter(
      (product) => product.id === productId
    )[0];
    if (productData) {
      setSelectedProduct({ ...productData });
      setShowAttachmentPopup(true);
    }
  };

  const getAttachmentPreviewInPopup = () => {
    return (
      showAttachmentPopup &&
      !Utility.isEmpty(selectedProduct) && (
        <Attachments
          documentDetails={selectedProduct}
          documentType={DOC_TYPE.PRODUCT_DOCUMENT}
          onClose={(docId: any, updatedAttachments: any[]) => {
            const docList: any[] = products['content'];
            const docData = docList.filter((doc) => doc.id === docId)[0];
            let refreshList = false;
            if (
              docData.attachmentsWithLink?.length !== updatedAttachments?.length
            ) {
              refreshList = true;
            }
            if (!refreshList && docData) {
              for (let attachment of updatedAttachments) {
                const existingAttachment = docData.attachmentsWithLink?.find(
                  (att: any) => att.attachmentId === attachment.attachmentId
                );
                if (Utility.isEmpty(existingAttachment)) {
                  refreshList = true;
                  break;
                }
              }
            }
            if (refreshList) {
              getProductsList();
            }
            setShowAttachmentPopup(false);
          }}
        />
      )
    );
  };

  const getUpdatedColumns = () => {
    const getCategory = (product: any) => {
      const avdTracking = product['advancedTracking'];
      const productType = product['type'];

      if (avdTracking && productType && avdTracking !== 'NONE') {
        const advancedTrackedName: string = avdTracking + ' ' + productType;
        return Utility.convertInTitleCase(advancedTrackedName);
      } else if (avdTracking && productType && avdTracking === 'NONE') {
        return Utility.convertInTitleCase(productType.replaceAll('_', ' '));
      }
      return '-';
    };
    let columns: any[] = deepClone(columnConfig);
    columns = columns.map((col: any) => {
      col.key = col.columnCode;
      col.editable = false;
      return col;
    });
    let productGroupIdsIndex = columns.findIndex(
      (config) => config.columnCode === 'productGroupIds'
    );
    if (Utility.isProductGroupingEnabled()) {
      if (productGroupIdsIndex !== -1) {
        let list = groupList?.filter((group) => group.id !== -1);
        list = list.map((group) => ({
          ...group,
          color: 'data-grid-badge-color-1'
        }));
        columns[productGroupIdsIndex] = {
          ...columns[productGroupIdsIndex],
          options: list,
          editable: true
        };
      }
    } else {
      if (productGroupIdsIndex !== -1) {
        columns.splice(productGroupIdsIndex, 1);
      }
    }
    columns = columns.map((col: any) => {
      col.key = col.columnCode;
      if (!col.systemField || col.columnCode === 'barcode') {
        col.allowFilter = false;
      }
      if (col.key === 'type') {
        col.renderer = (obj: any) => {
          return <DKLabel className="fs-m" text={getCategory(obj.rowData)} />;
        };
      }
      if (col.key === 'attachments') {
        col.type = 'file';
        col.renderer = (obj: any) => {
          if (obj?.rowData?.attachmentsWithLink?.length) {
            return (
              <div className="row">
                <div onClick={() => onAttachmentCLicked(obj)}>
                  <DKIcon
                    src={DKIcons.ic_attachment}
                    className="ic-s cursor-pointer opacity-60 hover:opacity-50"
                  />
                </div>
                <DKButton
                  title="View"
                  className="text-blue text-underline p-v-0"
                  onClick={() => onAttachmentCLicked(obj)}
                />
              </div>
            );
          } else {
            return <></>;
          }
        };
      }
      if (col.key === COLUMN_CODE.PRODUCT.Outgoing) {
        col.renderer = (obj: any) => {
          const hideIncomingOutgoingQty =
            obj?.rowData?.type === PRODUCT_TYPE.BILL_OF_MATERIALS ||
            obj?.rowData?.type === PRODUCT_TYPE.NON_TRACKED;
          return hideIncomingOutgoingQty ? (
            <></>
          ) : (
            <DKLabel
              className="row justify-content-end fs-m"
              text={`${obj?.rowData?.incomingQty}/${outgoingFont(
                obj?.rowData?.outgoing
              )}`}
            />
          );
        };
        col.allowColumnSort = false;
      }
      if (col.key === COLUMN_CODE.PRODUCT.AvailableStock) {
        col.renderer = (obj: any) => {
          const product = obj?.rowData;
          let valueToReturn: any;
          if (!Utility.isEmpty(product)) {
            if (
              product.reorderLevel !== null &&
              (tenantInfo?.additionalSettings
                ?.CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT
                ? Number(getAvailableStock(product)) +
                    Number(product.incomingQty) <=
                  Number(product.reorderLevel)
                : Number(getAvailableStock(product)) <=
                  Number(product.reorderLevel))
            ) {
              if (Number(getAvailableStock(product)) === 0) {
                valueToReturn = outgoingFont(getAvailableStock(product));
              } else {
                valueToReturn = getAvailableStock(product);
              }
            } else {
              valueToReturn = getAvailableStock(product);
            }
          }
          return (
            <DKLabel
              className="row justify-content-end fs-m"
              text={valueToReturn}
            />
          );
        };
      }
      return col;
    });
    return columns;
  };

  const getUpdatedRows = () => {
    let updatedRows = [...(products?.content || [])];
    let rowData = ProductRowsConfig(updatedRows);
    rowData.forEach((row: any) => {
      if (
        row.type.toLowerCase() === 'non_tracked' &&
        row.outgoingQty === `0.00`
      ) {
        row.outgoingQty = '-';
      }
      // row.rowContextMenu = getRowContextMenu(row);
    });
    rowData = rowData?.map((row: any) => {
      const existingIndexInSelectedRows = selectedRows.findIndex(
        (selRow: any) =>
          selRow?.documentSequenceCode === row.documentSequenceCode
      );
      row.selected = existingIndexInSelectedRows !== -1;
      return row;
    });

    return rowData;
  };

  const getAvailableStock = (product: any) => {
    let quantity = product['inventory']
      ? product['inventory']['availableQuantity']
      : 0;
    return isNaN(quantity)
      ? 0
      : Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  function outgoingFont(OutgoingAmount: any) {
    const html = `<span class="text-red">${OutgoingAmount}</span>`;
    return html;
  }

  function ProductRowsConfig(productList: any[]): any {
    let rowData: any[] = [];

    const getStatus = (product: any) => {
      return product['active'] ? 'ACTIVE' : 'INACTIVE';
    };

    productList.forEach((product, index) => {
      let row: any = {
        ...product,
        [COLUMN_CODE.PRODUCT.Number]: product.documentSequenceCode,
        [COLUMN_CODE.PRODUCT.ProductName]: product.name,
        [COLUMN_CODE.PRODUCT.Description]: product.description,
        [COLUMN_CODE.PRODUCT.AvailableStock]: getAvailableStock(product),
        [COLUMN_CODE.PRODUCT.Status]: [getStatus(product)],
        [COLUMN_CODE.PRODUCT.Category]: product.type,
        [COLUMN_CODE.PRODUCT.Id]: product.id ? product.id : index,
        [COLUMN_CODE.PRODUCT.Reorder]: product.reorderLevel,
        [COLUMN_CODE.PRODUCT.TransactionType]: product.productTransactionType,
        [COLUMN_CODE.PRODUCT.Barcode]: product.barcode,
        [COLUMN_CODE.PRODUCT.Outgoing]: product.outgoingQty
        // rowContextMenu: getContextMenuForRow(product)
      };
      if (
        product.reorderLevel !== null &&
        (tenantInfo?.additionalSettings
          ?.CHECK_INCOMING_STOCK_TO_ENABLE_REORDER_LEVEL_ALERT
          ? Number(getAvailableStock(product)) + Number(product.incomingQty) <=
            Number(product.reorderLevel)
          : Number(getAvailableStock(product)) <= Number(product.reorderLevel))
      ) {
        row = {
          ...product,
          [COLUMN_CODE.PRODUCT.Number]: product.documentSequenceCode,
          [COLUMN_CODE.PRODUCT.ProductName]: product.name,
          [COLUMN_CODE.PRODUCT.AvailableStock]: getAvailableStock(product),
          [COLUMN_CODE.PRODUCT.Status]: [getStatus(product)],
          [COLUMN_CODE.PRODUCT.Category]: product.type,
          [COLUMN_CODE.PRODUCT.Id]: product.id ? product.id : index,
          [COLUMN_CODE.PRODUCT.Reorder]: product.reorderLevel,
          [COLUMN_CODE.PRODUCT.Outgoing]: product.outgoingQty,
          //   rowContextMenu: getContextMenuForRow(product),

          invalidFields: [
            COLUMN_CODE.PRODUCT.Number,
            COLUMN_CODE.PRODUCT.ProductName,
            COLUMN_CODE.PRODUCT.AvailableStock,
            COLUMN_CODE.PRODUCT.Status,
            COLUMN_CODE.PRODUCT.Category,
            COLUMN_CODE.PRODUCT.Id,
            COLUMN_CODE.PRODUCT.Reorder,
            COLUMN_CODE.PRODUCT.Outgoing,
            COLUMN_CODE.PRODUCT.ProductGroupIds
          ]
        };
      }

      // Add CF data in row
      if (product.customField && product.customField.length > 0) {
        product.customField.forEach((cf: any) => {
          const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
          const colType = getUpdatedColumns()?.find(
            (config) => config.columnCode === columnCode
          )?.type;
          const cfInAvaialbleCFs = availableCustomFields?.content?.find(
            (field: any) => field.id === cf.id
          );
          const isUserTypeCF = cfInAvaialbleCFs
            ? cfInAvaialbleCFs.fieldType === CUSTOM_FIELD_TYPE.USER
            : false;
          let cfValue = '';
          if (isUserTypeCF) {
            const tempCF = cfInAvaialbleCFs?.attributes?.find(
              (attr: any) => attr.code === cf.value
            );
            cfValue = tempCF ? tempCF.value : '';
          } else {
            cfValue = cf ? cf.value : '';
          }
          row = {
            ...row,
            [columnCode]: colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue
          };
        });
      }
      rowData.push(row);
    });
    return rowData || [];
  }

  const onRowSelect = ({ rowData }: any) => {
    if (!rowData?.documentSequenceCode) return;

    let newSelectedRows = [...selectedRows];
    const selectedRowExistingIndex = selectedRows.findIndex(
      (row: any) => row.documentSequenceCode === rowData.documentSequenceCode
    );
    if (selectedRowExistingIndex !== -1) {
      newSelectedRows = newSelectedRows.filter(
        (row: any) => row.documentSequenceCode !== rowData.documentSequenceCode
      );
    } else {
      newSelectedRows.push(rowData);
    }
    setSelectedRows(newSelectedRows);
  };
  const onAllRowSelect = ({ selected }: any) => {
    let newSelectedRows = [...selectedRows];

    if (selected) {
      products?.content?.forEach((product: any) => {
        const productIndexInSelectedProducts = newSelectedRows.findIndex(
          (row: any) =>
            row.documentSequenceCode === product.documentSequenceCode
        );
        if (productIndexInSelectedProducts === -1) {
          newSelectedRows.push(product);
        }
      });
    } else {
      const selectedProdDocSeqCodesToRemove = products?.content.map(
        (row: any) => row.documentSequenceCode
      );
      let updatedRows = newSelectedRows.filter(
        (row) =>
          !selectedProdDocSeqCodesToRemove.includes(row.documentSequenceCode)
      );
      newSelectedRows = updatedRows;
    }
    setSelectedRows(newSelectedRows);
  };
  const onRowUpdate = ({
    columnData = null,
    columnKey,
    rowData,
    rowIndex
  }: any) => {
    const existingRows = getUpdatedRows();
    if (columnKey === 'productGroupIds') {
      const existingRow = existingRows.find(
        (row: any) => row.id === rowData.id
      );
      let groupAdded = true;
      let groupIdsToUpdate = rowData?.productGroupIds?.filter(
        (groupId: any) =>
          !(existingRow?.productGroupIds ?? [])?.includes(groupId)
      );
      // Checking if product has been added
      if (Utility.isEmptyObject(groupIdsToUpdate)) {
        // Checking if product has been
        groupIdsToUpdate = (existingRow?.productGroupIds ?? [])?.filter(
          (groupId: any) => !(rowData?.productGroupIds ?? [])?.includes(groupId)
        );
        groupAdded = false;
      }
      const groupIdProductMap = new Map<string, any>();
      if (!Utility.isEmptyObject(groupIdsToUpdate)) {
        groupIdsToUpdate.forEach((groupId: any) => {
          const group: any = groupList.find((item) => item.id === groupId);
          if (!Utility.isEmptyObject(group)) {
            let productGroupItems = deepClone(group?.productGroupItems ?? []);
            if (!groupAdded) {
              productGroupItems = productGroupItems.filter(
                (product: any) =>
                  product.productId !== rowData.documentSequenceCode
              );
            } else {
              productGroupItems.push({
                productId: rowData.documentSequenceCode
              });
            }
            groupIdProductMap.set(group.id, productGroupItems);
          }
        });
      }
      let apiCallArray: any[] = [];
      groupIdProductMap.forEach((productGroupItemIds, groupId) =>
        apiCallArray.push(
          ProductService.updateProductGroup(
            { productGroupItems: productGroupItemIds },
            groupId
          )
        )
      );
      Promise.all(apiCallArray).then((response) => {
        getGroups();
        setSelectedRows([]);
        getProductsList();
      });
    }
  };

  const onSearch = Utility.debouncer((searchQuery: any) => {
    setSearchText(searchQuery);
  }, 500);

  const onFilterApply = (filterData: any) => {
    const { query } = filterData;
    setFilterDataForGrid(query);
    const columns: any[] = getUpdatedColumns() || [];
    let queryArray: any = [
      { active: true },
      { hasVariants: false },
      { productTransactionType: transactionType }
    ];
    let queryString = '';
    query.forEach((item: any) => {
      let columnToFilter = columns?.find((column) => column.id === item.key);
      let columnCode = columnToFilter?.columnCode;
      let value = item?.value?.[0] || item.value;
      if (columnCode === 'status') {
        columnCode = 'active';
        value = value === 'ACTIVE' ? true : false;
      }
      if (columnCode === 'type') {
        value = value === 'NON_TRACKED' ? 'NONTRACKED' : value;
      }
      queryArray.push({ [columnCode]: value });
      if (!Utility.isEmptyObject(queryArray)) {
        queryString = Utility.getQueryString(queryArray);
      }
    });
    setQuery(queryString);
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: '50%', left: '40%', pointerEvents: 'none' }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  const onSave = () => {
    let copyOfSelectedRows: any[] = [...selectedRows];
    copyOfSelectedRows = copyOfSelectedRows.map((prod: any) => {
      return {
        ...prod,
        outgoingQty: prod?.outgoing
      };
    });
    props?.onSave?.(copyOfSelectedRows);
  };
  const isAllSelected = () => {
    const selectedProdDocCodes = selectedRows?.map(
      (row: any) => row.documentSequenceCode
    );
    let selectedProduct = products?.content.filter((product: any) =>
      selectedProdDocCodes.includes(product.documentSequenceCode)
    );
    if (products?.content?.length === selectedProduct?.length) {
      return true;
    }
    return false;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Product Selection" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className={`bg-white border-m mr-r`}
            onClick={() => {
              ProductService.apiConfig = oldProductAPIConfig.current;
              props.onCancel?.();
            }}
          />
          <DKButton
            className="bg-button text-white"
            title="Save"
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    let gridWidth =
      listContainerRef.current?.getBoundingClientRect().width ?? '100%';
    if (typeof gridWidth === 'number') {
      gridWidth = gridWidth - 30;
    }

    return (
      <div
        className="column mt-s parent-width mb-s position-relative flex-1 overflow-y-auto p-r text-13"
        ref={listContainerRef}
      >
        {getProductGroupControl()}
        <DKDataGrid
          title={`Products (${
            selectedRows?.length ? selectedRows?.length : 0
          })`}
          width={gridWidth}
          styles={{
            gridScrollHolder: { overflowX: 'scroll' }
          }}
          needShadow={false}
          needBorder={true}
          allowRowEdit={true}
          allowBulkOperation={true}
          allowColumnSort={true}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowSearch={true}
          allowFilter={true}
          buttons={[]}
          columns={getUpdatedColumns() ?? []}
          rows={getUpdatedRows()}
          isAllRowSelected={
            !Utility.isEmptyObject(selectedRows) && isAllSelected()
          }
          onRowSelect={(data: any) => onRowSelect(data)}
          onRowUpdate={onRowUpdate}
          onAllRowSelect={(data: any) => onAllRowSelect(data)}
          onSearch={(data: any) => onSearch(data)}
          onSort={onSort}
          onFilter={onFilterApply}
          filterData={filterDataForGrid}
          currentPage={products?.pageable?.pageNumber + 1}
          onPageChange={onPageChange}
          showHeader={true}
          totalPageCount={products?.totalPages}
          updating={isLoading}
        />

        {Utility.isEmptyObject(getUpdatedRows() || []) && getNoDataView()}
        {getAttachmentPreviewInPopup()}
      </div>
    );
  };

  // TODO: handle width of group list container
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: isDesktop ? 1065 : '95%',
            maxWidth: isDesktop ? '70%' : '95%',
            maxHeight: '95%',
            height: '90%',
            padding: 0,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          {getBody()}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
export default DocProductSelectionPopup;
