import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import EwayBillService from '../../Services/EwayBill';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  transporterData: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchEwayBills = createAsyncThunk('fetchEwayBills', async () => {
  const response = await EwayBillService.getEwayBills();
  return response;
});

export const searchEwayBills = createAsyncThunk(
  'searchEwayBills',
  async (data: any) => {
    const response: any = await EwayBillService.searchEwayBills(
      data.transactionType,
      data.ewayBillStatus
    );
    return response;
  }
);

export const fetchTransporters = createAsyncThunk(
  'fetchTransporters',
  async () => {
    const response = await EwayBillService.getTransporterList();
    return response;
  }
);

export const EwayBillSlice = createSlice({
  name: 'ewaybill',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEwayBills.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchEwayBills.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(searchEwayBills.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(searchEwayBills.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchTransporters.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTransporters.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.transporterData = action.payload;
      });
  }
});

export const selectEwayBills = (state: RootState) => state.ewaybill.data;
export const selectEwayBillTransporter = (state: RootState) =>
  state.ewaybill.transporterData;
