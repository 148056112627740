import ApiManager from "./ApiManager";
import AppManager from "../Manager/AppManager";
import ApiConstants from "./ApiConstants";


export default class BaseAPIManager {
    get(url, success, failure) {
        fetch(
            url,
            ApiManager.getApiRequestOptions("GET", null, ApiConstants.ACCESS_TOKEN)
        )
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.error) {
                    this.checkIfSessionExpired(responseData);
                    failure(responseData);
                } else {
                    success(responseData);
                }
            })
            .catch((error) => {
                this.checkIfSessionExpired(error);
                failure({ errorType: error.name, errorMessage: error.message });
            });
    }

    post(url, body, success, failure) {
        fetch(
            url,
            ApiManager.getApiRequestOptions("POST", body, ApiConstants.ACCESS_TOKEN)
        )
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.error) {
                    this.checkIfSessionExpired(responseData);
                    failure(responseData);
                } else {
                    success(responseData);
                }
            })
            .catch((error) => {
                this.checkIfSessionExpired(error);
                failure({ errorType: error.name, errorMessage: error.message });
            });
    }

    put(url, body, success, failure) {
        fetch(
            url,
            ApiManager.getApiRequestOptions("PUT", body, ApiConstants.ACCESS_TOKEN)
        )
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.error) {
                    this.checkIfSessionExpired(responseData);
                    failure(responseData);
                } else {
                    success(responseData);
                }
            })
            .catch((error) => {
                this.checkIfSessionExpired(error);
                failure({ errorType: error.name, errorMessage: error.message });
            });
    }

    delete(url, success, failure) {
        fetch(
            url,
            ApiManager.getApiRequestOptions("DELETE", null, ApiConstants.ACCESS_TOKEN)
        )
            .then((response) => {
                success();
            })
            .catch((error) => {
                this.checkIfSessionExpired(error);
                failure();
            });
    }

    isResponseStatusSuccess(status) {
        if (status === 200 || status === 204) {
            return true;
        }
        return false;
    }

    checkIfSessionExpired(error) {
        if (error.toString() === "TypeError: Failed to fetch") {
            AppManager.gotoLoginPage();
        }
    }


    uploadAttachment(imageData, success, failed) {
        let url = ApiConstants.URL.BASE + ApiConstants.URL.ATTACHMENT_UPLOAD
        let inputData = new FormData()
        inputData.append("file", imageData)
        inputData.append("file-entity", "REPORT_THUMBNAIL")

        let requestOptions = {
            method: "POST",
            credentials: "include",
            withCredentials: true,
            mode: "cors",
            body: inputData,
        }
        fetch(
            url,
            requestOptions
        )
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.error) {
                    this.checkIfSessionExpired(responseData);
                    failed({errorMessage: "Please try again."})
                } else {
                    success(responseData)
                }
            })
            .catch((error) => {
                this.checkIfSessionExpired(error);
                failed({errorMessage: "Please try again."})
            });
    }
}
