export class TableHeader {
    type: string
    isSelected: boolean
    name: string
    code?: string
    width: string
    index: number
    constructor(columnOption: any) {
        this.type = columnOption.type
        this.isSelected = columnOption.isSelected
        this.name = columnOption.name
        this.width = columnOption.width
        this.index = columnOption.index
        this.code = columnOption.code
    }
}