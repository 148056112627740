class ContactDetails {
  static contact: any = null;

  static getContactDetails = () => {
    return ContactDetails.contact;
  };

  static setContactDetails = (data: any) => {
    ContactDetails.contact = data;
  };
}

export default ContactDetails;
