import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import TemplateParser from "./TemplateParser"

export default class PayrollCheckParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.peopleChequePrintResponseList && dataFromAPI.peopleChequePrintResponseList.length > 0) {
            const peopleChequePrintResponse = dataFromAPI.peopleChequePrintResponseList[0];
            if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
                if (peopleChequePrintResponse && peopleChequePrintResponse.companyDateFormat) {
                    templateDataToReturn.dateFormat = peopleChequePrintResponse.companyDateFormat.toLowerCase();
                }
            }

            if (peopleChequePrintResponse.currencySymbol) {
                templateDataToReturn.currency = peopleChequePrintResponse.currencySymbol;
                templateDataToReturn.currencyName = peopleChequePrintResponse.currencyCode;
            } else {
                templateDataToReturn.currencyName = peopleChequePrintResponse.currencyCode;
            }
            templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)

            templateDataToReturn.payrollCheques = dataFromAPI.peopleChequePrintResponseList

            templateDataToReturn.payrollCheques.forEach((chequePayload) => {
                if (chequePayload.payDate && typeof chequePayload.payDate !== "string") {
                    chequePayload.payDate = null;
                }
            });
        }

        return templateDataToReturn
    }
}
