import { FC, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import {
  DKButton,
  DKLabel,
  DKSpinner,
  Toggle,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import {
  BARCODE_FORMAT,
  STICKER_HEIGHT,
  STICKER_WIDTH,
  BARCODE_FONT_SIZE,
  BARCODE_SAMPLE_VALUE,
  DEFAULT_MINIMUMM_SIZE_BARCODE
} from '../../../Constants/Constant';
import {
  DIRECTION_TYPE,
  IBarcodeSettingsPayload,
  barcodeAlignmentOptions,
  saveBarcodeSettings
} from '../../../Services/Barcode';
import Utility, { deepClone } from '../../../Utility/Utility';
import { CallBackPayloadType } from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  selectBarcodeSettings,
  updateBarCodeSettings
} from '../../../Redux/Slices/AuthSlice';
import TenantService from '../../../Services/Tenant';
import { fetchTenantSettings } from '../../../Redux/Slices/AuthSlice';
import AuthService from '../../../Services/Auth';
import { isDesktopOrTablet } from '../../../Utility/ViewportSizeUtils';

export interface IBarcodeSettingsProps {
  onSave?: (settings: IBarcodeSettingsPayload) => void;
  onCancel?: () => void;
  passingInteraction?: (callback: CallBackPayloadType) => void;
}
const PrintTypeOptions = Object.keys(DIRECTION_TYPE);
const barcodeTypes = Object.keys(BARCODE_FORMAT).map((format) => ({
  name: BARCODE_FORMAT[format],
  value: format
}));
const FormFields = [
  {
    label: 'Direction',
    type: INPUT_TYPE.DROPDOWN,
    options: PrintTypeOptions,
    key: 'Direction'
  },
  {
    label: 'Translate X',
    type: INPUT_TYPE.NUMBER,
    key: 'TranslateX'
  },
  {
    label: 'Translate Y',
    type: INPUT_TYPE.NUMBER,
    key: 'TranslateY'
  },
  {
    label: 'Font Size',
    type: INPUT_TYPE.NUMBER,
    key: 'FontSize'
  },
  {
    label: 'Prefix',
    type: INPUT_TYPE.TEXT,
    key: 'Prefix'
  }
];
const getFields = (type: string) => {
  return FormFields.map((field) => {
    let key = `${type}${field.key}`;
    if (field.key === 'Direction') {
      // For price and companyName, we have 'Type' suffix
      if (type === 'price' || type === 'companyName') {
        key = `print${
          type.substring(0, 1).toUpperCase() + type.substring(1)
        }Type`;
      } else {
        key = `print${
          type.substring(0, 1).toUpperCase() + type.substring(1)
        }Direction`;
      }
    }
    return { ...field, key };
  });
};
const BarcodeSettings: FC<IBarcodeSettingsProps> = (props) => {
  const [settings, setSettings] = useState<IBarcodeSettingsPayload>({});
  const [sampleBarcode, setSampleBarcode] = useState<any>({});
  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const currentSettings = useAppSelector(selectBarcodeSettings);
  const activeTenantDetails = useAppSelector(activeTenantInfo);

  const dispatch = useAppDispatch();
  useEffect(() => {
    updateCurrentBarcodeSettings();
  }, [currentSettings]);
  useEffect(() => {
    let todayDate = '17/01/2023';
    setSampleBarcode({
      ...sampleBarcode,
      stickerHeight: settings.stickerHeight,
      stickerWidth: settings.stickerWidth,
      format: BARCODE_FORMAT[settings.barcodeType || 'CODE_128'],
      height: settings.height || 20,
      companyName:
        (settings.companyNamePrefix || '') + activeTenantDetails.name,
      companyTranslateX: settings.companyNameTranslateX || 10,
      companyTranslateY: settings.companyNameTranslateY || 5,
      companyFontSize: settings.companyNameFontSize || 15,
      productName: (settings.productNamePrefix || '') + 'Sample product',
      productFontSize: settings.productNameFontSize || 15,
      productTranslateX: settings.productNameTranslateX || 10,
      productTranslateY: settings.productNameTranslateY || 50,
      productId:
        (settings.productCodePrefix || '') +
        BARCODE_SAMPLE_VALUE[settings.barcodeType || 'CODE_128'],
      productIdFontSize: settings.productCodeFontSize || 10,
      productIdTranslateX: settings.productCodeTranslateX || 10,
      productIdTranslateY: settings.productCodeTranslateY || 75,
      batchExpiryDate: (settings.batchExpiryDatePrefix || '') + todayDate,
      batchExpiryDateFontSize: settings.batchExpiryDateFontSize || 10,
      batchExpiryDateTranslateX: settings.batchExpiryDateTranslateX || 10,
      batchExpiryDateTranslateY: settings.batchExpiryDateTranslateY || 105,
      batchManufacturingDate:
        (settings.batchManufactureDatePrefix || '') + todayDate,
      batchManufacturingDateFontSize:
        settings.batchManufactureDateFontSize || 10,
      batchManufacturingDateTranslateX:
        settings.batchManufactureDateTranslateX || 10,
      batchManufacturingDateTranslateY:
        settings.batchManufactureDateTranslateY || 90,
      price: (settings.pricePrefix || '') + '100',
      priceTranslateX: settings.priceTranslateX || 10,
      priceTranslateY: settings.priceTranslateY || 65,
      barcodeAlign: settings?.barcodeAlign || 'left',
      priceFontSize: settings.priceFontSize || 10,
      barcodeTextFontSize: settings.barcodeTextFontSize || 12,
      marginTop: settings.barcodeTopMargin || 0,
      marginLeft: settings.barcodeLeftMargin || 0,
      value: BARCODE_SAMPLE_VALUE[settings.barcodeType || 'CODE_128']
    });
    // registerInteractions();
  }, [settings]);

  const updateCurrentBarcodeSettings = () => {
    if (!Utility.isEmpty(currentSettings)) {
      const existingSettings = { ...currentSettings };
      existingSettings.barcodeType =
        barcodeTypes.find((type) => type.value === existingSettings.barcodeType)
          ?.value || '';
      setSettings(existingSettings);
    }
  };

  /** Renderers Goes Here **/
  const renderBarcodeTypeSettings = () => {
    return (
      <div className="parent-width justify-content-between border-radius-m">
        <div
          className="row mt-xs"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Barcode Type'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={settings.barcodeType}
            formatter={(value: any) => BARCODE_FORMAT[value || 'CODE_128']}
            required={false}
            onChange={(obj: any) =>
              setSettings({ ...settings, barcodeType: obj?.value })
            }
            dropdownConfig={{
              data: barcodeTypes,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              }
            }}
          />
          <DKInput
            title={'DPI (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.dpi}
            required={false}
            onChange={(value: any) => setSettings({ ...settings, dpi: value })}
          />
        </div>
        <div
          className="row mt-r"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Height (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.height}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, height: value })
            }
          />
          
          {
          //ZEN-14907:The label height (in mm) field is not functional.
          /* <DKInput
            title={'Label Height (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.barcodeLabelHeight}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, barcodeLabelHeight: value })
            }
          /> */}
        </div>
        <div
          className="row mt-r"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Top Margin'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            options={[]}
            value={settings.barcodeTopMargin}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, barcodeTopMargin: value })
            }
          />
          <DKInput
            title={'Left Margin'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.barcodeLeftMargin}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, barcodeLeftMargin: value })
            }
          />
        </div>
        <div
          className="row mt-r"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Sticker height (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.stickerHeight || STICKER_HEIGHT}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, stickerHeight: value })
            }
          />
          <DKInput
            title={'Sticker width (in mm)'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.stickerWidth || STICKER_WIDTH}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, stickerWidth: value })
            }
          />
        </div>
        <div
          className="row mt-r"
          style={{
            columnGap: '10px'
          }}
        >
          <DKInput
            title={'Barcode text font size'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.NUMBER}
            value={settings.barcodeTextFontSize || BARCODE_FONT_SIZE}
            required={false}
            onChange={(value: any) =>
              setSettings({ ...settings, barcodeTextFontSize: value })
            }
          />
          <DKInput
            title={'Barcode Alignment'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            type={INPUT_TYPE.DROPDOWN}
            value={
              barcodeAlignmentOptions.filter(
                (option) =>
                  option?.value === (settings?.barcodeAlign || 'start')
              )[0]
            }
            formatter={(option: any) => option?.label}
            required={false}
            onChange={(obj: any) =>
              setSettings({ ...settings, barcodeAlign: obj?.value })
            }
            dropdownConfig={{
              data: barcodeAlignmentOptions,
              renderer: (index: any, obj: any) => {
                return <DKLabel key={index} text={`${obj?.label}`} />;
              }
            }}
          />
        </div>
      </div>
    );
  };
  const renderCompanyNameSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Company Name"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithTenantName}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithTenantName:
                    !settings.generateBarcodeWithTenantName
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithTenantName && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('companyName')}
          </div>
        )}
      </div>
    );
  };
  const renderPriceTagSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Price Tag"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithPriceTag}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithPriceTag:
                    !settings.generateBarcodeWithPriceTag
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithPriceTag && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('price')}
          </div>
        )}
      </div>
    );
  };
  const renderProductNameSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Product Name"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithProduct}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithProduct:
                    !settings.generateBarcodeWithProduct
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithProduct && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('productName')}
          </div>
        )}
      </div>
    );
  };
  const renderProductCodeSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Product ID"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithProductCode}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithProductCode:
                    !settings.generateBarcodeWithProductCode
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithProductCode && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('productCode')}
          </div>
        )}
      </div>
    );
  };
  const renderBatchManufactureDateSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Batch Manufacture Date"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithBatchManufactureDate}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithBatchManufactureDate:
                    !settings.generateBarcodeWithBatchManufactureDate
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithBatchManufactureDate && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('batchManufactureDate')}
          </div>
        )}
      </div>
    );
  };
  const renderBatchExpiryDateSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Batch Expiry Date"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithBatchExpiryDate}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithBatchExpiryDate:
                    !settings.generateBarcodeWithBatchExpiryDate
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithBatchExpiryDate && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('batchExpiryDate')}
          </div>
        )}
      </div>
    );
  };
  const generateBarcodeForWorkOrderSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Generate Barcode for Work Order" className="fw-m" />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeForWorkOrderSettings}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeForWorkOrderSettings:
                    !settings.generateBarcodeForWorkOrderSettings
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const generateBarcodeforJobcard = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel text="Generate Barcode for Job card" className="fw-m" />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforJobcard}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforJobcard: !settings.generateBarcodeforJobcard
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const generateBarcodeforJobcardAlongWithOperator = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode for Job card along with the Operator Name"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforJobcardAlongWithOperator}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforJobcardAlongWithOperator:
                    !settings.generateBarcodeforJobcardAlongWithOperator
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const generateBarcodeforDocNoSD = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode for Document number for sales document"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforDocNoSD}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforDocNoSD: !settings.generateBarcodeforDocNoSD
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const generateBarcodeforCustOrderNoSD = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode for Customer order number for sales document"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforCustOrderNoSD}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforCustOrderNoSD:
                    !settings.generateBarcodeforCustOrderNoSD
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const generateBarcodeforDocNoforWorkOrder = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode for Document number for Work order"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforDocNoforWorkOrder}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforDocNoforWorkOrder:
                    !settings.generateBarcodeforDocNoforWorkOrder
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const generateBarcodeforCustOrderNoforWorkOrder = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode for Customer order number for Work order"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeforCustOrderNoforWorkOrder}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeforCustOrderNoforWorkOrder:
                    !settings.generateBarcodeforCustOrderNoforWorkOrder
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };
  const renderMaxRetailPriceSettings = () => {
    return (
      <div className="column parent-width border-m p-s border-radius-m mt-m">
        <div className="row justify-content-between">
          <DKLabel
            text="Generate Barcode along with Maximum Retail Price"
            className="fw-m"
          />
          <div style={{ minWidth: 44 }}>
            <Toggle
              color="bg-button"
              className="box-content"
              isOn={settings.generateBarcodeWithRetailPrice}
              onChange={() =>
                setSettings({
                  ...settings,
                  generateBarcodeWithRetailPrice:
                    !settings.generateBarcodeWithRetailPrice
                })
              }
            />
          </div>
        </div>
        {settings.generateBarcodeWithRetailPrice && (
          <div
            className="row mt-m parent-width flex-wrap"
            style={{
              columnGap: '2%'
            }}
          >
            {getFormFields('maxRetailPrice')}
          </div>
        )}
      </div>
    );
  };
  const renderBarCode = (item: any) => (
    <div
      className="column parent-size position-relative"
      style={{ height: getTotalHeight() }}
    >
      {settings.generateBarcodeWithTenantName && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.companyFontSize || 15}px`,
            top: `${item.companyTranslateY}px`,
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.companyTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            position: 'absolute',
            width: item?.barcodeAlign === 'center' ? '100%' : 'max-content',
            textAlign: item?.barcodeAlign === 'center' ? 'center' : 'initial',
            whiteSpace: 'pre-line',
            zIndex: 11
          }}
        >
          {item.companyName}
        </div>
      )}
      <div
        id="barcode-svg"
        className="position-absolute"
        style={{
          top: `${item.marginTop}px`,
          left:
            item?.barcodeAlign === 'center' ? '50%' : `${item.marginLeft}px`,
          transform:
            item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
          zIndex: 22
        }}
      >
        <Barcode
          value={item.value}
          width={1.1}
          height={item.height}
          format={item.format}
          displayValue={item.displayValue}
          fontOptions={item.fontOptions}
          font={item.font}
          textAlign={item.textAlign}
          textPosition={item.textPosition}
          textMargin={item.textMargin}
          fontSize={item.barcodeTextFontSize}
          background={item.background}
          lineColor={item.lineColor}
        />
      </div>
      {settings.generateBarcodeWithProduct && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.productFontSize}px`,
            top: `${item.productTranslateY}px`,
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.productTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            position: 'absolute',
            width: 'max-content',
            zIndex: 23
          }}
        >
          {item.productName}
        </div>
      )}
      {settings.generateBarcodeWithPriceTag && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.priceFontSize}px`,
            top: `${item.priceTranslateY}px`,
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.priceTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            width: 'max-content',
            position: 'absolute',
            zIndex: 24
          }}
        >
          {item.price}
        </div>
      )}
      {settings.generateBarcodeWithProductCode && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.productIdFontSize}px`,
            top: `${item.productIdTranslateY}px`,
            width: 'max-content',
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.productIdTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            position: 'absolute',
            zIndex: 24
          }}
        >
          {item.productId}
        </div>
      )}
      {settings.generateBarcodeWithBatchManufactureDate && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.batchManufacturingDateFontSize}px`,
            top: `${item.batchManufacturingDateTranslateY}px`,
            width: 'max-content',
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.batchManufacturingDateTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            position: item?.barcodeAlign === 'center' ? 'relative' : 'absolute',
            zIndex: 24
          }}
        >
          {item.batchManufacturingDate}
        </div>
      )}
      {settings.generateBarcodeWithBatchExpiryDate && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: `${item.batchExpiryDateFontSize}px`,
            width: 'max-content',
            top: `${item.batchExpiryDateTranslateY}px`,
            left:
              item?.barcodeAlign === 'center'
                ? '50%'
                : `${item.batchExpiryDateTranslateX}px`,
            transform:
              item?.barcodeAlign === 'center' ? 'translate(-50%, 0)' : 'none',
            position: item?.barcodeAlign === 'center' ? 'relative' : 'absolute',
            zIndex: 24
          }}
        >
          {item.batchExpiryDate}
        </div>
      )}
    </div>
  );
  const getTotalHeight = () => {
    let totalHeight =
      DEFAULT_MINIMUMM_SIZE_BARCODE +
      BARCODE_FONT_SIZE +
      (settings.height || 20);
    if (settings.generateBarcodeWithProduct) {
      totalHeight += settings.productNameFontSize || 8;
    }
    if (settings.generateBarcodeWithProductCode) {
      totalHeight += settings.productCodeFontSize || 8;
    }
    if (settings.generateBarcodeWithTenantName) {
      totalHeight += settings.companyNameFontSize || 8;
    }
    if (settings.generateBarcodeWithPriceTag) {
      totalHeight += settings.priceFontSize || 8;
    }
    if (settings.generateBarcodeWithBatchManufactureDate) {
      totalHeight += settings.batchManufactureDateFontSize || 8;
    }
    if (settings.generateBarcodeWithBatchExpiryDate) {
      totalHeight += settings.batchExpiryDateFontSize || 8;
    }
    return totalHeight + 10;
  };
  const getFormFields = (type: string) => {
    type T = keyof typeof settings;
    return getFields(type).map((field: any) => (
      <DKInput
        title={field.label}
        className="barcode-form-field"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={field.type}
        value={settings?.[field.key as T]}
        formatter={(value: any) => value}
        required={false}
        onChange={(value: any) =>
          setSettings({ ...settings, [field.key as T]: value })
        }
        dropdownConfig={{
          data: field.options,
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj}`} />;
          }
        }}
      />
    ));
  };

  const resetValues = () => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Yes',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          setIsResetting(true);
          TenantService.deleteBarcodeSettings()
            .then((res) => {
              dispatch(fetchTenantSettings(AuthService.getUserTenantID()));
              setIsResetting(false);
            })
            .catch((err: any) => {
              setIsResetting(false);
              updateCurrentBarcodeSettings();
              console.error('Error resetting barcode settings: ', err);
            });
        }
      }
    ];
    showAlert(
      'Reset Settings?',
      'Are you sure you want to reset barcode settings to default?',
      buttons
    );
  };

  const getSaveAndResetButtons = () => {
    return (
      <div className="row width-auto">
        <div className="column width-auto mr-r">
          <div
            className={`row width-auto ${
              isResetting ? 'border-radius-m border-m' : ''
            }`}
          >
            <DKButton
              title={isResetting ? 'Resetting Values' : 'Reset Values'}
              onClick={() => {
                resetValues();
              }}
              disabled={isSaving || isResetting}
              className={`${
                isResetting
                  ? 'border-radius-none text-gray'
                  : 'bg-button text-white'
              }`}
            />
            {isResetting && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
        <div className="column width-auto">
          <div
            className={`row width-auto ${
              isSaving ? 'border-radius-m border-m' : ''
            }`}
          >
            <DKButton
              title={isSaving ? 'Saving' : 'Save'}
              onClick={() => {
                saveSettings();
              }}
              disabled={isSaving || isResetting}
              className={`${
                isSaving
                  ? 'border-radius-none text-gray'
                  : 'bg-button text-white'
              }`}
            />
            {isSaving && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const saveSettings = async () => {
    const payload: IBarcodeSettingsPayload =
      deepClone<IBarcodeSettingsPayload>(settings);

    try {
      setIsSaving(true);
      if (
        !Utility.isEmpty(payload.barcodeType) &&
        typeof payload.barcodeType === 'object'
      ) {
        payload.barcodeType = payload?.barcodeType?.['value'];
      }
      const response = await saveBarcodeSettings(payload);
      dispatch(updateBarCodeSettings(response));
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.error('Error saving barcode settings: ', error);
    }
  };
  return (
    <div className="column parent-size">
      <div
        className="row"
        style={{
          justifyContent: 'space-evenly',
          height: 'calc(100% - 52px)'
        }}
      >
        <div
          style={{ width: `${isDesktopOrTablet() ? '60' : '100'}%` }}
          className="column parent-height hide-scroll-bar overflow-y-auto pt-m pb-l"
        >
          {renderBarcodeTypeSettings()}
          {renderCompanyNameSettings()}
          {renderPriceTagSettings()}
          {renderProductNameSettings()}
          {renderProductCodeSettings()}
          {renderBatchManufactureDateSettings()}
          {renderBatchExpiryDateSettings()}
          {generateBarcodeForWorkOrderSettings()}
          {generateBarcodeforJobcard()}
          {generateBarcodeforJobcardAlongWithOperator()}
          {generateBarcodeforDocNoSD()}
          {generateBarcodeforCustOrderNoSD()}
          {generateBarcodeforDocNoforWorkOrder()}
          {generateBarcodeforCustOrderNoforWorkOrder()}
          {/* {renderMaxRetailPriceSettings()} */}
        </div>
        {isDesktopOrTablet() && (
          <div
            className="column justify-content-center align-items-center position-sticky"
            style={{
              width: '40%',
              height: '100%',
              top: 0
            }}
          >
            <div
              style={{
                border: `1px solid grey`,
                borderRadius: '0.5rem',
                width: `${Utility.convertMMToPx(
                  settings.stickerWidth || STICKER_WIDTH
                )}px`,
                height: `${Utility.convertMMToPx(
                  settings.stickerHeight || STICKER_HEIGHT
                )}px`,
                overflow: 'hidden',
                position: 'relative',
                top: 0
              }}
              className="row"
            >
              {renderBarCode(sampleBarcode)}
            </div>
          </div>
        )}
      </div>
      <div
        className="column parent-width bg-gray2"
        style={{ minHeight: 1 }}
      ></div>
      <div className="column parent-width p-v-s">
        {getSaveAndResetButtons()}
      </div>
    </div>
  );
};
export default BarcodeSettings;
