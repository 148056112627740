import React, { useEffect, useState } from 'react';
import Utility from '../../../Utility/Utility';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import LinkedRecordTabView from './LinkedRecordTabView';
import { DKLabel, DKIcon, DKSpinner } from 'deskera-ui-library';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import DetailsOpener from '../../Common/DetailsOpener';
import {
  DOC_TYPE,
  RECORD_SAVED_EVENT_DOC_TYPE
} from '../../../Constants/Constant';
import {
  COMMON_EVENTS,
  IRecordSavedEventData,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import { WorkOrderHelper } from '../WorkOrder/WorkOrderHelper';

const WOLinkedRecords: React.FC<any> = (props: any) => {
  /* woLinkedRecords: { childWo, Popr, jwo } */
  const [woLinkedRecords, setWoLinkedRecords] = useState<any>({});
  /* woLinkedRecordDataByCode: { [ code: string ]: { childWo, Popr, jwo } } */
  const [woLinkedRecordDataByCode, setWoLinkedRecordDataByCode] = useState<any>(
    {}
  );

  const [initialLoading, setInitialLoading] = useState<any>(false);

  const [detailsPopupData, setDetailsPopupData] = useState<any>();

  const getParentWoId = (pId: any) => {
    let woRecords = WorkOrderHelper.getWOLinkedDocuments();
    return Object.entries(woRecords)
      ?.map(([key, value]: any) => {
        let parentWoId = null;
        if (value.poPrInfo?.length > 0) {
          value.poPrInfo?.forEach((poprData: any) => {
            if (poprData.code === pId) {
              parentWoId = key;
            }
          });
        } else if (value.jobWorkOutInfo?.length > 0) {
          value.jobWorkOutInfo?.forEach((jwtData: any) => {
            if (jwtData.code === pId) {
              parentWoId = key;
            }
          });
        }
        return parentWoId;
      })
      .filter((x) => x != null);
  };

  useEffect(() => {
    if (props.data?.workOrderCode) {
      setInitialLoading(true);
      fetchLinkedRecordData(props.data.workOrderCode);
    }

    const onLinkedRecordUpdated = (data: { detail: IRecordSavedEventData }) => {
      if (!data?.detail) return;

      const { detail } = data;
      switch (detail.type) {
        case RECORD_SAVED_EVENT_DOC_TYPE.RECEIVED_GOODS:
          let parentWOId = props.data.workOrderCode;
          if (
            detail?.linkedDocId &&
            detail.linkedDocId !== props.data.workOrderCode
          ) {
            parentWOId =
              getParentWoId(detail?.linkedDocId)?.[0] ??
              props.data.workOrderCode;
          }
          if (!Utility.isEmptyObject(parentWOId)) {
            fetchLinkedRecordData(
              parentWOId,
              parentWOId !== props.data.workOrderCode
            );
          }
          break;
        case RECORD_SAVED_EVENT_DOC_TYPE.DISPATCHED_GOODS:
        case RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT:
        case RECORD_SAVED_EVENT_DOC_TYPE.CREATE_BILL:
          let parentWOIdJWO = props.data.workOrderCode;
          if (
            detail?.linkedDocId &&
            detail.linkedDocId !== props.data.workOrderCode
          ) {
            parentWOIdJWO =
              getParentWoId(detail?.linkedDocId)?.[0] ??
              props.data.workOrderCode;
          }
          if (parentWOIdJWO) {
            fetchLinkedRecordData(
              parentWOIdJWO,
              parentWOIdJWO !== props.data.workOrderCode
            );
          }
          break;
        case RECORD_SAVED_EVENT_DOC_TYPE.WORK_ORDER:
          if (detail.linkedDocId) {
            fetchLinkedRecordData(
              detail.linkedDocId,
              detail.linkedDocId &&
                detail.linkedDocId !== props.data?.workOrderCode
            );
          }
          break;
        case RECORD_SAVED_EVENT_DOC_TYPE.PURCHASE_ORDER:
          if (detail.linkedDocId === props.data?.workOrderCode) {
            fetchLinkedRecordData(
              detail.linkedDocId,
              false
            );
          }
          break;
        default:
      }
    };

    commonCustomEvent.on(
      COMMON_EVENTS.RECORD_SAVED,
      onLinkedRecordUpdated,
      true
    );

    return () =>
      commonCustomEvent.remove(
        COMMON_EVENTS.RECORD_SAVED,
        onLinkedRecordUpdated
      );
  }, []);

  const updateWOLinkedRowData = (
    response: any,
    parentId: any,
    rowData: any
  ) => {
    rowData?.childWorkOrdersInfo?.forEach((data: any, index: any) => {
      data.loading = false;
      if (!Utility.isEmptyObject(data.children)) {
        updateWOLinkedRowData(response, parentId, data.children);
      }
      if (data.code === parentId) {
        data.children = response;
      }

      data.expandableView =
        data.expanded && data.children ? (
          <LinkedRecordTabView
            woData={data.children.childWorkOrdersInfo}
            jwoData={data.children.jobWorkOutInfo}
            poPrData={data.children.poPrInfo}
            callChildWo={callForChildWO}
            openDetailsPopupData={openDetailsPopupData}
          />
        ) : null;
    });
    return rowData;
  };

  const updateParentId = (rowData: any, woId: any) => {
    let updateDataWithWO = rowData.map((row: any) => ({
      ...row,
      parentWOId: woId
    }));
    return updateDataWithWO;
  };

  const filterForPoPrData = (poprData: any) => {
    if (poprData?.length === 0) return [];
    return (
      poprData?.filter((rowData: any) =>
        [DOC_TYPE.PURCHASE_REQUEST, DOC_TYPE.ORDER].includes(rowData.docType)
      ) ?? []
    );
  };

  const parseDateWithParentWOId = (rowData: any, woId: any) => {
    let responseData = { ...rowData };
    if (responseData?.childWorkOrdersInfo?.length > 0) {
      responseData.childWorkOrdersInfo = updateParentId(
        responseData?.childWorkOrdersInfo,
        woId
      );
    }
    if (responseData?.jobWorkOutInfo?.length > 0) {
      responseData.jobWorkOutInfo = updateParentId(
        responseData?.jobWorkOutInfo,
        woId
      );
    }
    if (responseData?.poPrInfo?.length > 0) {
      responseData.poPrInfo = updateParentId(
        filterForPoPrData(responseData?.poPrInfo),
        woId
      );
    }
    return responseData;
  };

  const fetchLinkedRecordData = (woId: any, isLinkedToInnerWO: any = false) => {
    WorkOrderService.fetchLinkedRecords(woId)
      .then((response: any) => {
        setInitialLoading(false);
        let updateDataWithParentId = parseDateWithParentWOId(response, woId);
        if (isLinkedToInnerWO) {
          let rowData = updateWOLinkedRowData(
            updateDataWithParentId,
            woId,
            WorkOrderHelper.getWOLinkedDocumentsGridData()
          );
          setWoLinkedRecords(rowData);
          setWoLinkedRecordDataByCode({
            ...woLinkedRecordDataByCode,
            [woId]: updateDataWithParentId
          });
          WorkOrderHelper.setWOLinkedDocuments({
            ...woLinkedRecordDataByCode,
            [woId]: updateDataWithParentId
          });
          WorkOrderHelper.setWOLinkedDocumentsGridData(rowData);
        } else {
          setWoLinkedRecords(updateDataWithParentId);
          setWoLinkedRecordDataByCode({ [woId]: updateDataWithParentId });
          WorkOrderHelper.setWOLinkedDocuments({
            [woId]: updateDataWithParentId
          });
          WorkOrderHelper.setWOLinkedDocumentsGridData(updateDataWithParentId);
        }
      })
      .catch((error: any) => {
        setInitialLoading(false);
        console.error(error);
      });
  };

  const noDataRecords = () => {
    return (
      <div
        className="column justify-self-center align-self-center align-items-center pt-xxl pb-xxl"
        style={{ pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        {!initialLoading && (
          <DKLabel text={'No record found'} className="fw-m mt-l" />
        )}
        {initialLoading && <DKSpinner iconClassName="ic-r" className="mt-l" />}
      </div>
    );
  };

  const updateLoadingRowData = (parentId: any, rowData: any) => {
    rowData?.childWorkOrdersInfo?.forEach((data: any, index: any) => {
      if (!Utility.isEmptyObject(data.children)) {
        updateLoadingRowData(parentId, data);
      }
      if (data.code === parentId) {
        data.loading = true;
      }
    });
    return rowData;
  };

  const callForChildWO = (woId: any) => {
    let rowData = updateLoadingRowData(woId, { ...woLinkedRecords });
    setWoLinkedRecords(rowData);
    if (Utility.isEmptyObject(woLinkedRecordDataByCode[woId])) {
      fetchLinkedRecordData(woId, true);
    } else {
      let rowData = updateWOLinkedRowData(
        woLinkedRecordDataByCode[woId],
        woId,
        {
          ...woLinkedRecords
        }
      );
      setWoLinkedRecords(rowData);
    }
  };

  const openDetailsPopupData = (code: any, type: any) => {
    let details = {};
    if (
      [
        DOC_TYPE.JOB_WORK_OUT_ORDER,
        'PURCHASE_ORDER',
        DOC_TYPE.PURCHASE_REQUEST
      ].includes(type)
    ) {
      details = {
        documentCode: code,
        documentType: type,
        showDetailsOpener: true,
        isWOLinkedRecord: true
      };
    } else {
      details = {
        documentSeqCode: code,
        documentType: type,
        showDetailsOpener: true,
        isWOLinkedRecord: true
      };
    }
    setDetailsPopupData(details);
  };

  const renderView = () => {
    return !Utility.isEmptyObject(woLinkedRecords) ? (
      <>
        <LinkedRecordTabView
          woData={woLinkedRecords.childWorkOrdersInfo}
          jwoData={woLinkedRecords.jobWorkOutInfo}
          poPrData={woLinkedRecords.poPrInfo}
          callChildWo={callForChildWO}
          openDetailsPopupData={openDetailsPopupData}
        />
        {!Utility.isEmptyObject(detailsPopupData) &&
          detailsPopupData?.showDetailsOpener && (
            <DetailsOpener
              data={detailsPopupData}
              onCancel={() => {
                setDetailsPopupData(null);
              }}
              isReadOnly={false}
            />
          )}
      </>
    ) : (
      noDataRecords()
    );
  };

  return renderView();
};

export default WOLinkedRecords;
