import { TableColumnPopupType, TableTotalColumnList } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import Utility from "../../../Utilities/Utility";
import { getFirstTableColumnType, getIsSelectTotalColumnByType, getSortedHeader } from "../TableUtility";
export class JobWorkOutOrderTableTotalColumn {
    lineItems: any;
    currency: string;
    totalQuantity: string;
    totalAmount: string;
    totalDiscount: string;
    productCustomFields: any

    constructor(lineItems: any, currency: string) {
        this.lineItems = lineItems
        this.currency = currency
        this.totalQuantity = ''
        this.totalAmount = ''
        this.totalDiscount = ''
        this.productCustomFields = []
        this.getTotalValue(lineItems)
    }

    getTotalValue(lineItems: any) {
        if (lineItems === undefined || lineItems === null) {
            return
        }

        if (lineItems.length === 0) {
            return
        }

        var discount = 0
        var quantity = 0
        var amount = 0
        var isPercentage = false

        var customFieldList = CustomFieldManager.getNumericProductCustomField(TemplateSettingsManager.defaultTemplateSettings.tableInfo)
        if (customFieldList.length > 0) {
            customFieldList.forEach(element => {
                var item = {
                    code: element.code,
                    sum: 0,
                }
                this.productCustomFields.push(item)
            });
        }

        lineItems.forEach((element: any) => {
            if(!isNaN(element.quantity)) {
                quantity = quantity + Number(element.quantity)
            }
            if (!isNaN(element.discount)) {
                discount = discount + Number(element.discount)
            }
            if (!isNaN(element.amount)) {
                amount = amount + Number(element.amount)
            }

            if (element.discountInPercent !== undefined && element.discountInPercents !== null) {
                if (element.discountInPercent) {
                    isPercentage = true
                }
            }

            if (this.productCustomFields !== undefined && this.productCustomFields !== null) {
                if (element.customFields !== undefined && element.customFields !== null) {
                    this.productCustomFields.forEach((item: any) => {
                        var val = element.customFields.find((x: any) => x.code === item.code)
                        if (val !== undefined) {
                            if (val.value !== undefined) {
                                item.sum = item.sum + parseFloat(val.value)
                            }
                        }
                    });
                }
            }
        });

        this.totalQuantity = getIsSelectTotalColumnByType(TableColumnPopupType.quantity) ? quantity.toString() : ''
        this.totalDiscount = getIsSelectTotalColumnByType(TableColumnPopupType.productDiscount) ? Utility.toCurrencyFormat(discount, this.currency) : ''
        this.totalAmount = getIsSelectTotalColumnByType(TableColumnPopupType.amount) ? Utility.toCurrencyFormat(amount, this.currency) : ''
        if (isPercentage) {
            this.totalDiscount = ''
        }
        return
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if(sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var footerList = TableFooterUtility.getFooterListByType('job_work_out_order')
            var firstColumnType = getFirstTableColumnType([], undefined)
            sortedHeader.forEach(element => {
                if (footerList.includes(element.type)) {
                    return
                }
                sortedValue.push(this.getDefaultValue(element, firstColumnType))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getDefaultValue(element: any, firstColumn: string | undefined) {
        if (firstColumn !== undefined) {
            if (firstColumn === element.type) {
                if (!TableTotalColumnList.includes(element.type)) {
                    return '<b>' + getLocalisedText('total') + '</b>'
                }
            }
        }
        return this.getValueByType(element)
    }

    getValueByType(element: any) {
        var type = element.type
        switch (type) {
            case TableColumnPopupType.quantity:
                return this.totalQuantity
            case TableColumnPopupType.productDiscount:
                return this.totalDiscount
            case TableColumnPopupType.amount:
                return this.totalAmount
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    if (getIsSelectTotalColumnByType(TableColumnPopupType.productCustomField, element.code)) {
                        var item = this.productCustomFields.find((x: any) => x.code === element.code)
                        if (item !== undefined) {
                            return item.sum
                        }
                    }
                }
                return ''
            default:
                return ''
        }
    }



    getDefaultList() {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        ]
    }
}
