import { DKIcons, showAlert, DKLabel } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  REMOTE_CONFIG_TABLES,
  TABLES
} from '../../../Constants/TableConstants';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import { IState } from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import DataGridHolder from '../../../SharedComponents/DataGridHolder';
import Utility from '../../../Utility/Utility';
import { QUALITY_CHECK_COLS } from '../Constants/MRPColumnConfigs';
import AddQCTemplate from './AddQCTemplate';
import QualityCheckService, {
  QualityCheckAPIConfig
} from '../../../Services/MRP/QualityCheck';
import {
  fetchQualityCheckList,
  selectQualityChecks
} from '../../../Redux/Slices/MRP/QualityCheckSlice';
import useConfirm from '../../../Hooks/useConfirm';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import useScreenResize from '../../../Hooks/useScreenResize';
import { SETTINGS_GRID_WIDTH } from '../../../Constants/Constant';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

const initialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

const MRPQualityCheck = (props: any) => {
  //states
  const [editableQC, setEditableQC] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [qualityCheckData, setQualityCheckData] = useState<any>(initialState);
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const [updating, setUpdating] = useState(false);
  const [showAddQCPopup, setShowAddQCPopup] = useState(false);
  const [filters, setFilters] = useState<any>([]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const { confirm } = useConfirm();
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(gridContainerRef);

  //selectors
  const dispatch = useAppDispatch();
  const qualityCheckTableData = useAppSelector(selectQualityChecks);

  //effects
  useEffect(() => {
    getAllQualityCheck({});

    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(qualityCheckTableData)) {
      const { content, pageable, totalPages } = qualityCheckTableData;
      const rowData = Utility.isEmpty(qualityCheckTableData)
        ? []
        : getRowDataForCreate(content);
      const passingData = {
        columnData: updateColumns(QUALITY_CHECK_COLS),
        rowData,
        filter: filters,
        originalData: rowData
      };

      setQualityCheckData(passingData);
      setTotalPageCount(totalPages);
      setCurrentPage(+pageable?.pageNumber + 1);
    }
  }, [qualityCheckTableData]);

  //methods
  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };
  const getAllQualityCheck = async (config: any) => {
    setUpdating(true);
    try {
      QualityCheckService.apiConfig = config;
      const data = await dispatch(fetchQualityCheckList());
      setUpdating(false);
    } catch (err) {
      setUpdating(false);
    }
  };

  const updateColumns = (columnList: any) => {
    let columns = [...columnList];
    columns.forEach((col) => {
      if (col.columnCode === 'materials') {
        col.renderer = (data: any) => materialsListRenderer(data);
      }
    });
    return columns;
  };

  const materialsListRenderer = (row: any) => {
    return (
      <div className="row flex-wrap gap-2">
        {row?.rowData?.materials
          ?.slice(0, 10)
          ?.map((list: any) => (
            <DKLabel
              className="row border-radius-s width-auto data-grid-badge-color-1 batch-chip-css"
              text={list?.productName}
            />
          )) ?? null}
        {row?.rowData?.materials.length > 10 && (
          <div
            onClick={() => {
              onRowEdit(row);
            }}
          >
            <DKLabel
              className="row border-radius-s width-auto data-grid-badge-color-1 batch-chip-css text-blue cursor-hand"
              text={`view more...`}
            />
          </div>
        )}
      </div>
    );
  };

  const onSearch = (searchTerm: string) => {
    const config: QualityCheckAPIConfig = {
      ...QualityCheckService.apiConfig,
      Page: 0,
      SearchTerm: searchTerm
    };
    setSearchTerm(searchTerm);
    getAllQualityCheck(config);
  };

  const onRowEdit = (data: any) => {
    const id: number = data?.rowData?.id;
    const QCData = qualityCheckTableData['content'].filter(
      (item: any) => item.id === id
    )[0];
    if (QCData) {
      setEditableQC(QCData);
      setShowAddQCPopup(true);
    }
  };

  const getRowDataForCreate = (tableList: any[]) => {
    let rowDataList: any[] = [];

    qualityCheckTableData['content'].forEach((qc: any) => {
      const row = {
        id: qc.id,
        name: qc.name,
        materials: qc.productDetails,
        rowButtons: [
          {
            icon: DKIcons.ic_edit,
            className: 'p-v-0 text-blue underline grid-action-link-h-padding',
            onClick: (data: any) => {
              onRowEdit(data);
            }
          },
          {
            className: 'p-v-0 text-blue underline grid-action-link-h-padding',
            icon: DKIcons.ic_delete,
            onClick: async (data: any) => {
              const rowData = [data.rowData];
              const index = data.rowData.id;

              if (!Utility.isEmpty(rowData)) {
                const isConfirmed = await confirm(
                  'Deleting this QC will delete it permanently',
                  'Delete QC?'
                );

                if (isConfirmed) {
                  rowDelete([index]);
                }
              }
            }
          }
        ]
      };
      rowDataList.push(row);
    });
    return rowDataList;
  };

  const rowDelete = (id: any) => {
    QualityCheckService.deleteQualityCheck(id)
      .then(
        (res: any) => {
          if (!Utility.isEmpty(res?.failure)) {
            showAlert(
              'Cannot Delete!',
              'This template has been used in a product.'
            );
          }
          dispatch(fetchQualityCheckList());
        },
        (err) => {
          console.log('Error while deleting', err);
        }
      )
      .catch((error) => {
        console.log('Error while deleting', error);
      });
  };

  const onFilter = (updatedList: any[], queries: any[]) => {
    if (queries.length === 0) {
      const config: QualityCheckAPIConfig = {
        ...QualityCheckService.apiConfig,
        Query: '',
        QueryParam: ''
      };
      getAllQualityCheck(config);
      setFilters(queries);
    } else {
      let filteredQueryList = Utility.getFilterParameters(
        queries,
        REMOTE_CONFIG_TABLES.OPERATORS
      );
      const list = Utility.getFilteredQueryList(filteredQueryList);
      let withoutFilter, withFilter;
      if (
        list.newFilteredQueyListArr &&
        list.newFilteredQueyListArr.length > 0
      ) {
        withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
      }
      if (list.customField && list.customField.length > 0) {
        withFilter = Utility.getQueryString(list.customField);
      }

      const config: QualityCheckAPIConfig = {
        ...QualityCheckService.apiConfig,
        Query: withoutFilter,
        QueryParam: `&${withFilter}`
      };
      getAllQualityCheck(config);
      setFilters(queries);
    }
  };

  //ui render
  const getHeaderAction = () => {
    let buttons: any[] = [];
    if (checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.QC_TEMPLATE)) {
      buttons.push({
        title: `+ Add QC Template`,
        className: 'bg-button text-white ml-r',
        onClick: () => {
          setShowAddQCPopup(true);
        }
      });
    }

    return buttons;
  };

  const onSort = (data: any) => {
    const config: QualityCheckAPIConfig = {
      ...QualityCheckService.apiConfig,
      sort: data.columnData.key,
      sortDir: data.order
    };
    getAllQualityCheck(config);
  };

  const getDataGrid = () => {
    return (
      <div
        className="column parent-size position-relative flex-1"
        ref={gridContainerRef}
        style={{ lineHeight: '16px' }}
      >
        <DataGridHolder
          updating={updating}
          needShadow={false}
          needBorder={true}
          tableName={TABLES.MRP_QUALITY_CHECK}
          displayTableName={props?.gridNameWithDesc || 'QC Templates'}
          width={width || SETTINGS_GRID_WIDTH}
          dontUsePageWidth={true}
          filterTableName={REMOTE_CONFIG_TABLES.MRP_QUALITY_CHECK}
          allowSearch={true}
          allowRowEdit={true}
          onRowOpenClick={onRowEdit}
          allowColumnEdit={false}
          allowRowAdd={true}
          allowColumnSort={true}
          onSort={onSort}
          allowColumnAdd={false}
          allowFilter={true}
          onFilter={onFilter}
          filter={qualityCheckData.filter}
          onSearch={Utility.debouncer(onSearch, 1000)}
          refresh={true}
          headerButtons={getHeaderAction()}
          allowDataExport={true}
          needTrailingColumn={true}
          gridData={qualityCheckData}
          onPaginationClick={(requestedPageNumber: number) => {}}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          allowBulkOperation={false}
          searchBarConfig={{ searchText: searchTerm }}
        />
      </div>
    );
  };

  return (
    <div className="column parent-width parent-height hide-scroll-bar overflow-y-auto pt-xs">
      {getDataGrid()}
      {showAddQCPopup && (
        <AddQCTemplate
          editableQCTemplate={editableQC}
          onCancel={() => {
            setShowAddQCPopup(false);
            setEditableQC({});
          }}
          onSuccess={(data: any) => {
            setShowAddQCPopup(false);
            setEditableQC({});
            getAllQualityCheck({});
          }}
        />
      )}
    </div>
  );
};

export default MRPQualityCheck;
