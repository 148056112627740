import React from 'react';
import {getBannerInfo} from '../../utilities/CommonUtility';
import {Coordinate, CoordinateUtility} from '../../utilities/CoordinateUtility';
import {Action, ComponentType} from '../model/Enum';
import {TemplateItem} from '../model/TemplateItem';
import './AddComponentPopup.css';
import {getLocalisedText} from "../../../../Translate/LanguageManager";
import { Asset } from '../../../../assets';

interface Props {
    id: number
    componentList: TemplateItem[]
    onAddNewComponent: (action: Action) => void
    onSelectSystemField: () => void
    onAddComponent: (selectedComponent: TemplateItem, ComponentList: TemplateItem[], id: number, action: Action) => void
    onCancelPressed: () => void
}

interface States {
}
export default class AddComponentPopup extends React.Component<Props, States> {

    coordinateUtility = new CoordinateUtility()
    defaultXPosition = this.coordinateUtility.toRatio(new Coordinate(200, 0, 0, 0)).xRatio
    defaultYPosition = this.coordinateUtility.toRatio(new Coordinate(0, 100, 0, 0)).yRatio

    render() {
        return (
            <div>
                {this.getComponentListToAdd()}
            </div>
        );
    }


    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////

    getComponentListToAdd() {
        return (
            <div
                style={{
                    width: "90%",
                    // height: 340,
                    backgroundColor: "white",
                    borderRadius: 12,
                    position: "absolute",
                    top: 40,
                    left: 2,
                    paddingTop: 15,
                    paddingBottom: 15,
                    paddingLeft: 17,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",

                }}
            >
                <div
                    className="DownArrow"
                    style={{
                        position: "absolute",
                        top: -6,
                        right: 12
                    }}
                ></div>
                <div style={{ display: "flex", marginBottom: 10, justifyContent: "space-between", width: '100%'}}>
                    <text
                        style={{
                            color: "gray",
                            fontSize: 12,
                            fontWeight: "bold",
                        }}
                    >
                        {getLocalisedText('component_list')}
                    </text>
                    <text
                        style={{
                            marginRight: 12,
                            fontSize: 12,
                            fontWeight: "bold",
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.onCancelPressed()}
                    >
                        X
                    </text>
                </div>
                <div>{this.getAllComponentToAdd()}</div>
            </div>
        );
    }
    getAllComponentToAdd() {
        var arr: any[] = [];
        arr.push(this.getComponentToAdd(Action.Image));
        arr.push(this.getComponentToAdd(Action.Text));
        arr.push(this.getComponentToAdd(Action.Signature));
        arr.push(this.getComponentToAdd(Action.Watermark));
        arr.push(this.getComponentToAdd(Action.System_Field));

        // arr.push(this.getComponentToAdd(Action.Banner));
        return arr;
    }

    getComponentToAdd(action: Action) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: 15,
                    paddingLeft: 0,
                    cursor: "pointer",
                }}
                onClick={() => {
                    this.setState({
                        isAddComponentPopupRequired: false,
                    });
                    this.onAddComponentTapped(action)
                }}
            >
                <img
                    src={this.getComponentIconForType(action)}
                    alt=""
                    style={{ width: 23, marginLeft: 3, marginRight: 14, opacity: 0.85 }}
                />
                <text style={{ color: "black", fontSize: 13 }}>{getLocalisedText(this.getTitleForAction(action))}</text>
            </div>
        );
    }

    getTitleForAction(action: Action) {
        switch (action) {
            case Action.Banner: return 'banner';
            case Action.Image: return 'image';
            case Action.Signature: return 'signature';
            case Action.Text: return 'text';
            case Action.System_Field: return 'custom_field';
            case Action.Watermark: return 'watermark';

        }

    }

    getComponentIconForType(actionName: Action) {
        switch (actionName) {
            case Action.Banner:
                return Asset.icon.ic_dd_banner;
            case Action.Image:
                return Asset.icon.ic_dd_img;
            case Action.Signature:
                return Asset.icon.ic_dd_sign;
            case Action.System_Field:
                return Asset.icon.ic_dd_systemfield;
            case Action.Text:
                return Asset.icon.ic_dd_text;
            case Action.Watermark:
                return Asset.icon.ic_dd_watermark;
            default:
                return Asset.icon.ic_dd_text;
        }
    }

    onAddComponentTapped(actionName: Action) {
        var newComponentList = this.props.componentList
        var newComponent = new TemplateItem('')
        var newId = this.props.id;
        if (actionName === Action.Text) {
            newId += 1
            let newLabel = new TemplateItem(ComponentType.DKLabel)
            newLabel.id = newId
            newLabel.text = 'Text ' + newId
            newLabel.newText = 'New Text ' + newId
            newLabel.x = this.defaultXPosition
            newLabel.y = this.defaultYPosition
            newComponent = newLabel
        } else if (actionName === Action.Image) {
            let newLabel = new TemplateItem(ComponentType.DKImage)
            let asset = Asset.icon.photoPlaceholder
            newId += 1
            newLabel.id = newId
            newLabel.x = this.defaultXPosition
            newLabel.y = this.defaultYPosition
            newLabel.image = asset
            newLabel.width = 64 //asset.width
            newLabel.height = 64 //asset.height
            newComponent = newLabel
        }
        else if (actionName === Action.Signature) {
            let newLabel = new TemplateItem(ComponentType.DKImage)
            let asset = Asset.icon.signature_1
            newId += 1
            newLabel.id = newId
            newLabel.x = this.defaultXPosition
            newLabel.y = this.defaultYPosition
            newLabel.image = asset
            newLabel.width = 200
            newLabel.height = 100
            newComponent = newLabel
        }
        else if (actionName === Action.Watermark) {
            let newLabel = new TemplateItem(ComponentType.DKImage)
            let asset = Asset.icon.watermark
            newId += 1
            newLabel.id = newId
            newLabel.x = this.defaultXPosition
            newLabel.y = this.defaultYPosition
            newLabel.image = asset
            newLabel.width = 200
            newLabel.height = 200
            // newLabel.style.opacity = 0.2
            newComponent = newLabel
        }
        else if (actionName === Action.Banner) {
            var existedBanner = getBannerInfo(this.props.componentList)
            if (existedBanner === undefined) {
                let newLabel = new TemplateItem(ComponentType.DKBanner)
                newId += 1
                newLabel.id = newId
                newLabel.x = 0
                newLabel.y = 0
                newLabel.image = Asset.icon.banner_0
                newLabel.width = 800
                newLabel.height = 180
                newLabel.style.zIndex = 1
                newComponent = newLabel
            }
            else {
                this.props.onAddComponent(existedBanner, this.props.componentList, this.props.id, actionName)
                // this.setState({
                //     selectedComponent: existedBanner
                // })
            }
        }
        else if (actionName === Action.System_Field) {
            this.props.onSelectSystemField()
        }

        if (actionName !== Action.System_Field) {
            if (newComponent.type !== '') {
                newComponentList.push(newComponent)
                if (newComponent.type !== ComponentType.DKBanner) {
                    newComponent.style.zIndex = 9999
                }

                this.props.onAddComponent(newComponent, newComponentList, newId, actionName)
            }
        }
    }
}


