import { getCapitalized } from 'deskera-doc-builder-lib';
import Utility, { reduceArrayByKey } from '../../../Utility/Utility';
import { JOB_CARD_STATUS } from '../Constants/MRPColumnConfigs';
import { JC_PROCESS_TYPE } from '../Shared/JobCardListComponent/JobCardListPresenter';
import { PRODUCT_TYPE } from '../../../Constants/Constant';

export interface JC_DATES_MODEL {
  plannedStartDate: any;
  plannedEndDate: any;
  actualStartDate: any;
  actualEndDate: any;
}

export const JC_DATES: JC_DATES_MODEL = {
  plannedStartDate: 'plannedStartDate',
  plannedEndDate: 'plannedEndDate',
  actualStartDate: 'actualStartDate',
  actualEndDate: 'actualEndDate'
};

export class JobCardHelper {
  static getDateName(type: JC_DATES_MODEL) {
    switch (type) {
      case JC_DATES.plannedStartDate:
        return 'Planned start date';
      case JC_DATES.plannedEndDate:
        return 'Planned end date';
      case JC_DATES.actualStartDate:
        return 'Actual start date';
      case JC_DATES.actualEndDate:
        return 'Actual end date';
      default:
        return '';
    }
  }

  static getDateValue(type: JC_DATES_MODEL, formData: any) {
    switch (type) {
      case JC_DATES.plannedStartDate:
        return formData?.plannedStartDate;
      case JC_DATES.plannedEndDate:
        return formData?.plannedEndDate;
      case JC_DATES.actualStartDate:
        return formData?.actualStartDate;
      case JC_DATES.actualEndDate:
        return formData?.actualEndDate;
      default:
        return '';
    }
  }

  static getProducedQtyOnJCList = (woData: any, rowData: any) => {
    const found = woData?.workOrderItems?.find((item: any) => {
      return (
        item.itemName &&
        item.itemName.pid === rowData?.jobCardLinkDetails?.[0]?.productCode &&
        item.itemName.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        rowData?.jobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.PRODUCTION
      );
    });

    const qty =
      reduceArrayByKey(found?.warehouseInventoryData, 'quantity') ?? 0;

    return qty;
  };

  static getProducedQtyOnJCFormGrid = (woData: any, rowData: any) => {
    const found = woData?.workOrderItems?.find((item: any) => {
      return item.productCode === rowData?.jobCardLinkDetails?.[0]?.productCode;
    });

    const qty =
      reduceArrayByKey(found?.warehouseInventoryData, 'quantity') ?? 0;

    return qty;
  };

  static getWOAttachedObj = (woData: any, rowData: any) => {
    const found = woData?.workOrderChildDetails?.find((woChild: any) => {
      return (
        woChild.productCode === rowData?.jobCardLinkDetails?.[0]?.productCode
      );
    });

    return found;
  };

  static showDeleteButtonOnJCList = (
    jcDetails: any,
    isJCCompletedOrCancelled: boolean,
    woData: any,
    rowData: any
  ) => {
    const totalQuantityAssigned = reduceArrayByKey(
      jcDetails.itemWarehouseInventoryData ?? [],
      'quantity'
    );

    const totalProducedQty = JobCardHelper.getProducedQtyOnJCList(
      woData,
      rowData
    );

    let showDeleteBtn = false;

    if (
      totalProducedQty <= 0 &&
      jcDetails?.processType === JC_PROCESS_TYPE.PRODUCTION &&
      !isJCCompletedOrCancelled
    ) {
      showDeleteBtn = true;
    } else if (
      Utility.isNotEmpty(jcDetails) &&
      jcDetails?.processType !== JC_PROCESS_TYPE.PRODUCTION &&
      totalQuantityAssigned <= 0 &&
      !isJCCompletedOrCancelled
    ) {
      showDeleteBtn = true;
    }

    return showDeleteBtn;
  };

  static findDependents(jobCards: any, id: any, dependentOnJC: any) {
    const dependentArray = [];
    const processedJobCards = new Set(); // Keep track of processed job cards

    function findDependentsRecursive(jobCardCode: any) {
      if (processedJobCards.has(jobCardCode)) {
        return; // Skip already processed job cards
      }

      processedJobCards.add(jobCardCode); // Mark the job card as processed

      const dependentJobCards = jobCards?.filter(
        (jobCard: any) =>
          jobCard.jobcardDependency &&
          jobCard.jobcardDependency?.jobcardDependencyList?.includes(
            jobCardCode
          )
      );

      dependentJobCards.forEach((dependentJobCard: any) => {
        dependentArray.push(dependentJobCard);
        findDependentsRecursive(dependentJobCard?.jobCardCode);
      });
    }

    let currentJobCardEdited = jobCards?.find(
      (jobCard: any) => jobCard.id === id
    );

    if (currentJobCardEdited) {
      currentJobCardEdited = {
        ...currentJobCardEdited,
        jobcardDependency: {
          jobcardDependencyList: dependentOnJC?.jobCardCode
            ? [dependentOnJC?.jobCardCode]
            : []
        }
      };
      dependentArray.push(currentJobCardEdited);
      findDependentsRecursive(currentJobCardEdited?.jobCardCode);
    }

    return dependentArray;
  }

  // static findDependents(jobCards: any, id: any, dependentOnJC: any) {
  //   const dependentArray = [];

  //   function findDependentsRecursive(jobCardCode: any) {
  //     const dependentJobCards = jobCards?.filter(
  //       (jobCard: any) =>
  //         jobCard.jobcardDependency &&
  //         jobCard.jobcardDependency?.jobcardDependencyList?.includes(
  //           jobCardCode
  //         )
  //     );

  //     dependentJobCards.forEach((dependentJobCard: any) => {
  //       dependentArray.push(dependentJobCard);
  //       findDependentsRecursive(dependentJobCard?.jobCardCode);
  //     });
  //   }

  //   let currentJobCardEdited = jobCards?.find(
  //     (jobCard: any) => jobCard.id === id
  //   );

  //   if (currentJobCardEdited) {
  //     currentJobCardEdited = {
  //       ...currentJobCardEdited,
  //       jobcardDependency: {
  //         jobcardDependencyList: dependentOnJC?.jobCardCode
  //           ? [dependentOnJC?.jobCardCode]
  //           : []
  //       }
  //     };
  //     dependentArray.push(currentJobCardEdited);
  //     findDependentsRecursive(currentJobCardEdited?.jobCardCode);
  //   }

  //   return dependentArray;
  // }

  static statusValidator = (dependentOnJC: any) => {
    let isDependentJCFinished = !Utility.isEmpty(dependentOnJC)
      ? [JOB_CARD_STATUS.COMPLETED, JOB_CARD_STATUS.CANCELLED].includes(
          dependentOnJC?.status
        )
      : true;

    return isDependentJCFinished;
  };

  static isCyclicDependency = (
    currentJC: any,
    woJobCards: any,
    selectedDependent: any
  ) => {
    let findDependentInOperationList = woJobCards?.find((record: any) => {
      return record.id === selectedDependent.id;
    });

    if (findDependentInOperationList) {
      if (
        currentJC?.jobCardCode ===
        findDependentInOperationList?.jobcardDependency
          ?.jobcardDependencyList?.[0]
      ) {
        return true;
      }
    }
    return false;
  };

  static isSingularCyclicDependency = (currentJC: any, woJobCards: any) => {
    const atleastOneEmptyFound = woJobCards?.find((jcItem: any) => {
      if (currentJC?.jobCardCode !== jcItem?.jobCardCode) {
        return Utility.isEmpty(
          jcItem?.jobcardDependency?.jobcardDependencyList
        );
      }
    });

    return atleastOneEmptyFound ? false : true;
  };
}

export const JC_PROCESS_TYPE_DATA = [
  {
    key: JC_PROCESS_TYPE.CONSUMPTION,
    name: getCapitalized(JC_PROCESS_TYPE.CONSUMPTION.toLowerCase())
  },
  {
    key: JC_PROCESS_TYPE.PRODUCTION,
    name: getCapitalized(JC_PROCESS_TYPE.PRODUCTION.toLowerCase())
  },
  {
    key: JC_PROCESS_TYPE.PROCESSING,
    name: getCapitalized(JC_PROCESS_TYPE.PROCESSING.toLowerCase())
  }
];
