import React, { Component } from "react";
import "../../index.css";
import { FONT_SIZE, EditorInfoType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getCapitalized, getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate8 extends Component {
    arabicHeaderWidth = '85%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="ColumnDiv">
                <div style={{
                    width: '100%',
                    height: getVW(Utility.convertToPrintScale(30, this.state.data.printScale)),
                    backgroundColor: this.state.data.themeColor,
                    marginBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))
                }} />
                <div style={{
                    width: '100%',
                    height: getVW(Utility.convertToPrintScale(30, this.state.data.printScale)),
                    backgroundColor: this.state.data.themeColor,
                    position: "absolute",
                    bottom: 0
                }} />
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        marginTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%',
                        alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale),
                    }}>
                    {getCapitalized(AppManager.getLocalisedType(this.state.data.type))}
                </text>
                {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                {this.getCompanyNameAndAddress()}
            </div>
        );
    }

    getCompanyNameAndAddress() {
        return <div className="ColumnDiv"
            style={{
                width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '40%',
                alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
            }}>
            {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    width: '100%',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                }}>
                {getLocalisedText(Utility.getCompanyName(this.state.data))}
            </text>}
            {this.state.data.showFrom && ComponentManager.getDescriptionSection(
                "",
                Utility.getCompanyAddress(this.state.data),
                "90%",
                'black',
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.from),
                this.state.data.printScale
            )}
        </div>;
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{ bottom: getVW(60), width: '100%', justifyContent: 'center', alignItems: "flex-start", marginBottom: getVW(Utility.convertToPrintScale(35, this.state.data.printScale)) }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { top: getVW(50), right: getVW(10) })}
                    {this.addCompanyNameAndDocTypeLabels()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(Utility.getImageSize(EditorInfoType.logo)), this.state.data.printScale)}
                    {this.getBillingShippingAndDocumentInfoSection()}
                    {/* section 3 */}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(15)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(15)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(15)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {Utility.getIsContainsTotalSection(this.state.data) && this.getTotalSection()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale))}
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(15)}
                        {this.getEditableDocumentTable()}
                    </div>
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(15)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.addTermsNConditionsView()}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {Utility.getIsShowEInvoiceCorrectPosition(eInvoiceSectionPosition.bottom) && ComponentManager.addVerticalSpace(40)}
            </div>
        );
    }

    getBillingShippingAndDocumentInfoSection() {
        return <div
            className="RowDiv"
            style={{
                justifyContent: 'space-between',
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            {this.getBillingShippingAddresses()}
            {this.getDocumentInfo()}
        </div>;
    }

    getDocumentInfo() {
        return <div
            className="ColumnDiv"
            style={{ width: "30%", backgroundColor: "white" }}
        >
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }


    getBillingShippingAddresses() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                this.state.data.billToTitle,
                Utility.getClientBillToAddress(this.state.data),
                "30%",
                'black',
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.billTo),
                this.state.data.printScale
            )}
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) &&
                <div style={{ width: "20%" }} />}
            {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                this.state.data.shipToTitle,
                Utility.getClientShipToAddress(this.state.data),
                "30%",
                'black',
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.shipTo),
                this.state.data.printScale
            )}
        </div>;
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getTotalSection() {
        return (
            <div className="RowDiv" style={{ zIndex: 3, padding: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)), boxSizing: "border-box", backgroundColor: 'white', height: getVW(Utility.convertToPrintScale(70, this.state.data.printScale)), justifyContent: "space-between", borderTop: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)) + ' solid black', borderBottom: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)) + ' solid black' }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType)) + " " + getLocalisedText("total")}
                </text>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        direction: Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                    }}>
                    {Utility.getTotalValue(this.state.data)}
                </text>
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.headerTextColor = "black";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        style.footerTextColor = "black";
        style.itemBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['21%', '35%', '44%'] : ['35%', '35%', '30%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: 'black',
            headerBorderBottomWidth: getVW(Utility.convertToPrintScale(3, data.printScale))
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    <div style={{
                        position: "relative",
                        top: 0,
                        height: getVW(Utility.convertToPrintScale(3, data.printScale)),
                        backgroundColor: 'black'
                    }} />
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            headerBorderBottomColor: 'black',
            headerBorderBottomWidth: getVW(Utility.convertToPrintScale(3, data.printScale))
        };

        const invoiceDueSummaryData = data?.invoiceDueSummaryData ?? []

        if (invoiceDueSummaryData.length > 0) {
            return (
                <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                    <div style={{ width: "65%" }}>
                        <div style={{
                            position: "relative",
                            top: 0,
                            height: getVW(Utility.convertToPrintScale(3, data.printScale)),
                            backgroundColor: 'black'
                        }} />
                        {ComponentManager.getTransactionDocumentTable(style, data)}
                    </div>
                </div>
            );
        }
    }
}
