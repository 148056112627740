import React from "react";
import { DocumentSectionType } from "../Constants/Constants";
import { getLocalisedText } from "../Translate/LanguageManager";
import { PageCountFormattingType } from "../Helper/types";

export default class HeaderFooterUtility {

  static getTitle(documentData) {
    var title = getLocalisedText('sample_title')
    if (documentData.type !== undefined && documentData.type !== null) {
      title = documentData.type.toUpperCase()
      if (documentData.refNumber !== undefined && documentData.refNumber !== null) {
        title = title + ' - ' + documentData.refNumber
      }
    }
    return title
  }

  static getContainerByType(headerFooter, type, documentData, additionalSettings) {
    var container = undefined

    var value = {}
    value.containerStyle = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'containerStyle')
    value.type = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'type')
    value.customField = HeaderFooterUtility.getFooterHeaderValueByKey(headerFooter, 'customField')

    if (type === DocumentSectionType.header) {
      if (value.type[DocumentSectionType.header] !== undefined && value.type[DocumentSectionType.header] !== null) {
        container = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.header, value.type[DocumentSectionType.header], value.containerStyle[DocumentSectionType.header], value.customField[DocumentSectionType.header], documentData, additionalSettings)
      }
    }
    else if (type === DocumentSectionType.footer) {
      if (value.type[DocumentSectionType.footer] !== undefined && value.type[DocumentSectionType.footer] !== null) {
        container = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.footer, value.type[DocumentSectionType.footer], value.containerStyle[DocumentSectionType.footer], value.customField[DocumentSectionType.footer], documentData, additionalSettings)
      }
    }

    return container
  }

  static getFooterHeaderValueByKey(headerFooter, key) {
    var value = []
    if (headerFooter !== undefined && headerFooter !== null) {
      if (headerFooter.header !== undefined && headerFooter.header !== null) {
        value[DocumentSectionType.header] = headerFooter.header[key]
      }
      if (headerFooter.footer !== undefined && headerFooter.footer !== null) {
        value[DocumentSectionType.footer] = headerFooter.footer[key]
      }
    }
    return value
  }

  static getPreviewPageCount(pageCountFormattingType) {
    let pageCountDiv = undefined
    switch (pageCountFormattingType) {
      case PageCountFormattingType.singleDigit:
        pageCountDiv = <><span className="pageNumber">1</span></>
        break
      case PageCountFormattingType.pageWithNumeric:
        pageCountDiv = <>{getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span> </>
        break
      case PageCountFormattingType.pageOfTotalPage:
        pageCountDiv = <>{getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span>&nbsp;of&nbsp;<span className="totalPages">1</span></>
        break
      case PageCountFormattingType.pageOfTotalPageSlash:
        pageCountDiv = <>{getLocalisedText('page')}&nbsp;<span className="pageNumber">1</span>&nbsp;/&nbsp;<span className="totalPages">1</span></>
        break
      default:
        break;
    }

    return pageCountDiv
  }

  static getPreviewHeaderFooterContainer(val, type, containerStyle, customFields, documentData, additionalSettings) {
    const newDateString = new Date().toLocaleString()
    const pageCountFormattingType = additionalSettings?.pageCountFormattingType ?? PageCountFormattingType.pageOfTotalPageSlash
    const pageCount = this.getPreviewPageCount(pageCountFormattingType)

    switch (type) {
      case 'none':
        return ''
      case 'date_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}><span className="date">{newDateString}</span></div>
      case 'title_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>{this.getTitle(documentData)}</div>
      case 'page_count_only':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>{pageCount}</div>
      case 'date_title':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="date">{newDateString}</span>
          &nbsp;&nbsp;
          <span className="title">{this.getTitle(documentData)}</span>
        </div>
      case 'date_page_count':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="date">{newDateString}</span>
          &nbsp;&nbsp;
          {pageCount}
        </div>
      case 'title_page_count':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          <span className="title">{this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          {pageCount}
        </div>
      case 'date_title_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{newDateString}</span>
          &nbsp;&nbsp;
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
        </div>
      case 'date_page_count_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{newDateString}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {pageCount}
          </div>
        </div>
      case 'title_page_count_space':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {pageCount}
          </div>
        </div>
      case 'date_title_page_count':
        return <div id={this.getTemplateId(val)} style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          ...containerStyle
        }}>
          <span className="date" style={{
            display: 'flex',
            flexDirection: 'row',
          }}>{newDateString}</span>
          &nbsp;&nbsp;
          <span className="title" style={{
            display: 'flex',
            flexDirection: 'row',
          }}> {this.getTitle(documentData)}</span>
          &nbsp;&nbsp;
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            {pageCount}
          </div>
        </div>
      case 'custom':
        return <div id={this.getTemplateId(val)} style={{ ...containerStyle }}>
          {this.getPreviewCustomField(documentData, customFields, pageCount)}
        </div>
      default:
        break;
    }
  }

  static getPreviewCustomField(documentData, customFields, pageCount) {
    var list = customFields
    if (list !== undefined && list !== null) {
      if (list.length > 0) {
        return list.map((element, index) => {
          return this.getCustomField(documentData, element.type, element.text, list.length - 1 === index, pageCount)
        });
      }
    }
    return <></>
  }

  static getCustomField(documentData, type, value, isLastIndex, pageCount) {
    if (!isLastIndex) {
      return <>
        {this.getSelectedFieldValue(type, value, documentData, pageCount)}
        &nbsp;&nbsp;
      </>
    }
    return this.getSelectedFieldValue(type, value, documentData, pageCount)
  }

  static getSelectedFieldValue(type, value, documentData, pageCount) {
    switch (type) {
      case 'title':
        return <span className="title"> {HeaderFooterUtility.getTitle(documentData)}</span>
      case 'date':
        return <span className="date">{new Date().toLocaleDateString()}</span>
      case 'pageCount':
        return <span>
          {pageCount}
        </span>
      case 'currentPageCount':
        return <span className="pageNumber">1</span>
      case 'totalPageCount':
        return <span className="totalPages">1</span>
      case 'customText':
        return <span>{value}</span>
      default:
        break;
    }
  }

  static getTemplateId(val) {
    switch (val) {
      case DocumentSectionType.header:
        return 'header-template'
      case DocumentSectionType.footer:
        return 'footer-template'
      default:
        return ''
    }
  }
}
