import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import JournalService from '../../Services/Journal';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';

export interface JournalState {
  data: any;
  columnConfig: IColumn[];
  status: API_STATUS;
  configTableId?: string;
}

const initialState: JournalState = {
  data: {},
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchJournal = createAsyncThunk('journal', async () => {
  const response = await JournalService.getJournal();
  //console.log(response);
  return response;
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const JournalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    addJournalColumnConfig: (state, action: PayloadAction<any>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.JOURNAL
      );
    },
    addColumnToCoaColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJournal.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchJournal.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;

        let taxList: any = action.payload;
        state.data = taxList;
      });
  }
});

export const { addJournalColumnConfig, addColumnToCoaColumnConfig } =
  JournalSlice.actions;

export const selectJournal = (state: RootState) => state.journal.data;

export const selectJournalColumnConfig = (state: RootState) =>
  state.journal.columnConfig;

export const selectJournalColumnConfigTableId = (state: RootState) =>
  state.journal.configTableId;

export default JournalSlice.reducer;
