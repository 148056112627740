import React, { useState } from 'react';
import {
  DKLabel,
  DKLine,
  INPUT_TYPE,
  DKDataGrid,
  DKIcon,
  DKSpinner
} from 'deskera-ui-library';

import { useAppSelector } from '../../../Redux/Hooks';
import Utility, { getCapitalized } from '../../../Utility/Utility';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import DetailsOpener from '../../Common/DetailsOpener';
import { DOC_TYPE } from '../../../Constants/Constant';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  getStatusChipColor,
  getStatusName,
  getStatusTitle
} from '../WorkOrder/WorkOrderHelper';
import DateFormatService from '../../../Services/DateFormat';

/* Column config */
const WO_COLUMNS = [
  {
    name: 'Work Order',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 130,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    key: 'documentSeqCode',
    id: 'documentSeqCode',
    textAlign: 'left'
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 130,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'productDocSeqCode',
    key: 'productDocSeqCode',
    id: 'productDocSeqCode'
  },
  {
    name: 'Product',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'productName',
    key: 'productName',
    id: 'productName'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  }
];

const PO_PR_COLUMNS = [
  {
    name: 'Document',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    key: 'documentSeqCode',
    id: 'documentSeqCode'
  },
  {
    name: 'Type',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    textAlign: 'left',
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'docType',
    key: 'docType',
    id: 'docType'
  },
  {
    name: 'Receive By',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    textAlign: 'left',
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'receiveByDate',
    key: 'receiveByDate',
    id: 'receiveByDate'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.TEXT,
    index: 4,
    options: [],
    required: false,
    textAlign: 'left',
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  }
];

const JWO_COLUMNS = [
  {
    name: 'JWO Code',
    type: INPUT_TYPE.TEXT,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    key: 'documentSeqCode',
    id: 'documentSeqCode'
  },
  {
    name: 'Dispatch Status',
    type: INPUT_TYPE.TEXT,
    width: 155,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'dispatchStatus',
    key: 'dispatchStatus',
    id: 'dispatchStatus'
  },
  {
    name: 'Goods Receipt Status',
    type: INPUT_TYPE.TEXT,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'grStatus',
    key: 'grStatus',
    id: 'grStatus'
  },
  {
    name: 'Billing Status',
    type: INPUT_TYPE.TEXT,
    width: 155,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'billingStatus',
    key: 'billingStatus',
    id: 'billingStatus'
  }
];

const LinkedRecordTabView: React.FC<any> = (props: any) => {
  const [selectedSegment, setSelectedSegment] = useState(
    props.selectedTab ? props.selectedTab : 0
  );

  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);

  const getTabButton = (index: number, title: string, selected = index) => {
    return (
      <div
        className=" cursor-hand "
        onClick={() => {
          setSelectedSegment(index);
        }}
      >
        <DKLabel
          text={title}
          className={
            'mt-m' + (selectedSegment === index ? ' fw-b text-app-color ' : '')
          }
        />
        {selectedSegment === index && (
          <div className="parent-width bg-app mt-r" style={{ height: 2.5 }} />
        )}
      </div>
    );
  };

  const getTabHeaderSection = () => {
    return (
      <div className="parent-width mobile-scroll-hidden">
        <DKLine />
        <div
          className="row bg-gray1 border-radius-s p-h-r align-items-start mobile-scroll-x mobile-pb"
          style={{ gap: 30, backgroundColor: 'rgb(250, 250, 250)' }}
        >
          {getTabButton(0, 'Work Orders')}
          {getTabButton(1, 'PO/PR')}
          {getTabButton(2, 'JWO')}
        </div>
        <DKLine />
      </div>
    );
  };

  const getTabContainerData = () => {
    switch (selectedSegment) {
      case 0:
        return props.woData || [];
      case 1:
        return props.poPrData || [];
      case 2:
        return props.jwoData || [];
    }
  };

  const getColumnConfig = () => {
    switch (selectedSegment) {
      case 0:
        return WO_COLUMNS;
      case 1:
        return PO_PR_COLUMNS;
      case 2:
        return JWO_COLUMNS;
    }
  };

  /* Update Column Config for renderer */
  const updateColumnConfig = () => {
    let tempColumnConfig = getColumnConfig() || [];
    tempColumnConfig?.forEach((conf: any) => {
      switch (conf.key) {
        case 'documentSeqCode':
          conf.renderer = ({ rowData }: any) => {
            return !Utility.isEmpty(rowData.documentSeqCode) ? (
              <div
                className="row"
                onClick={(e) => {
                  e.stopPropagation();
                  props.openDetailsPopupData(
                    selectedSegment === 1 || selectedSegment === 2
                      ? rowData.code
                      : rowData.documentSeqCode,
                    selectedSegment === 0
                      ? DOC_TYPE.WORK_ORDER
                      : selectedSegment === 2
                      ? DOC_TYPE.JOB_WORK_OUT_ORDER
                      : rowData.docType
                  );
                }}
              >
                <DKLabel
                  text={rowData.documentSeqCode ?? ''}
                  className="fs-m underline cursor-hand"
                />
                {rowData.loading && (
                  <DKSpinner iconClassName="ic-s" className="ml-r" />
                )}
              </div>
            ) : (
              <DKLabel text={'-'} className="" />
            );
          };
          break;

        case 'status':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                text={getStatusTitle(rowData.status)}
                className={`${getStatusChipColor(rowData.status)}`}
              />
            );
          };
          break;

        case 'jwoCode':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                text={rowData?.jwoCode || '-'}
                className="text-align-left white-space-nowrap p-v-xs"
              />
            );
          };
          break;

        case 'dispatchStatus':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                text={getStatusName(rowData?.dispatchStatus, 'dispatchStatus')}
                className={`${getStatusChipColor(rowData?.dispatchStatus)}`}
              />
            );
          };
          break;
        case 'grStatus':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                text={getStatusName(rowData?.grStatus, 'receiptStatus')}
                className={`${getStatusChipColor(rowData?.grStatus)}`}
              />
            );
          };
          break;
        case 'billingStatus':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                text={getStatusName(rowData?.billingStatus, 'billedStatus')}
                className={`${getStatusChipColor(rowData?.billingStatus)}`}
              />
            );
          };
          break;
        case 'totalAmount':
          conf.renderer = ({ rowData }: any) => {
            return (
              <div className="row">
                {Utility.amountFormatter(
                  rowData?.totalAmount ?? 0,
                  tenantInfo.currency
                )}
              </div>
            );
          };
          break;
        case 'receiveByDate':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                className="fs-m"
                text={DateFormatService.getDateStrFromDate(
                  new Date(rowData?.receiveByDate)
                )}
              />
            );
          };
          break;
        case 'docType':
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                className="fs-m"
                text={getCapitalized(
                  rowData?.docType?.toLowerCase().replaceAll('_', ' ') ?? ''
                )}
              />
            );
          };
          break;
      }
    });
    return tempColumnConfig;
  };

  /* No Record View */
  const noDataRecords = () => {
    return (
      <div
        className="column justify-self-center align-self-center align-items-center pb-xxl"
        style={{ pointerEvents: 'none', maxWidth: 600 }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel text={'No data found'} className="fw-m mt-l" />
      </div>
    );
  };

  const getGridSection = () => {
    return (
      <div className="column parent-size linked-records-grid-container">
        <DKDataGrid
          needShadow={false}
          needColumnIcons={false}
          needBorder={false}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowExpand={selectedSegment === 0 ? true : false}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          rows={getTabContainerData()}
          columns={updateColumnConfig()}
          onRowExpand={(data: any) => {
            props.callChildWo(data.rowData.code);
          }}
        />
        {getTabContainerData()?.length === 0 && noDataRecords()}
      </div>
    );
  };

  return (
    <div className={`column parent-size p-h-s pt-s ${props?.className}`}>
      <div className="row parent-size">{getTabHeaderSection()}</div>
      {getGridSection()}
    </div>
  );
};

export default LinkedRecordTabView;
