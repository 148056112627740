import {
  DKButton,
  DKDataGrid,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKSpinner,
  DKIcons,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchCurrentTenantInfo } from '../../../Redux/Slices/AuthSlice';
import TenantService from '../../../Services/Tenant';
import Utility, { deepClone } from '../../../Utility/Utility';
import AddAdditionalCharges from '../../Invoices/AddAdditionalCharges';
import {
  fetchAllAdditionalCharge,
  selectedCascadingDiscounts
} from '../../../Redux/Slices/AdditionalChargesSlice';
import { CASCADING_DISCOUNT_TYPE } from '../../../Constants/Enum';
import { Store } from '../../../Redux/Store';
import { Provider } from 'react-redux';
import AdditionalChargeService from '../../../Services/AdditionalCharges';

const CascadingPopup = (props: any) => {
  //states
  const COLUMN_CONFIGS = [
    {
      id: 'Level',
      key: 'level',
      name: 'Level',
      type: INPUT_TYPE.TEXT,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Discount',
      type: INPUT_TYPE.DROPDOWN,
      width: 250,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      datasource: [],
      dropdownConfig: {
        title: 'Select Discount',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        data: [],
        renderer: (index: any, obj: any) => {
          return obj.name;
        },
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      },
      id: 'discountName',
      key: 'discountName',
      columnCode: 'discountName'
    }
  ];
  const discountTypeOption: any = [
    { title: 'Cascading', value: CASCADING_DISCOUNT_TYPE.CASCADE },
    { title: 'On Sub-Total', value: CASCADING_DISCOUNT_TYPE.SUBTOTAL }
  ];
  const [columns, setColumns] = useState(COLUMN_CONFIGS);
  const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
  const [rowItems, setRowItems] = useState<any[]>([]);
  const [discountObj, setDiscountObj] = useState<any>({});
  const [saveApiLoading, setSaveApiLoading] = useState(false);
  const [discountType, setDiscountType] = useState<any>({
    title: 'Cascading',
    value: CASCADING_DISCOUNT_TYPE.CASCADE
  });
  const additionalChargeDetails: any = useAppSelector(
    selectedCascadingDiscounts
  );
  const [rowIndexSelected, setRowIndexSelected] = useState<any>(0);
  const [isRowEdit, setIsRowEdit] = useState<boolean>(false);
  //selectors
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props?.data?.discountType && props?.data?.discountDetails?.length) {
      setDiscountType(
        discountTypeOption.find(
          (data: any) => data.value === props.data.discountType
        )
      );
      setRowItems(props.data.discountDetails);
    }
  }, []);

  useEffect(() => {
    updateConfig();
  }, [rowItems, additionalChargeDetails]);

  const onDelete = (data: any) => {
    let updatedRowItems: any = deepClone(rowItems);
    // updatedRowItems.splice(data.rowIndex, 1);
    updatedRowItems = updatedRowItems?.filter(
      (item: any) => item.level !== data.level
    );
    updatedRowItems.forEach((row: any, index: any) => {
      updatedRowItems[index].level = index + 1;
    });
    setRowItems([...updatedRowItems]);
  };

  const getOptions = () => {
    let usedDiscount: any = [];
    rowItems?.forEach((row: any) => {
      if (!Utility.isEmpty(row.discountName)) {
        usedDiscount.push(row);
      }
    });
    const unusedDiscount: any = [];
    additionalChargeDetails?.forEach((discount: any) => {
      let found = false;
      usedDiscount?.forEach((usedDiscount: any) => {
        if (discount?.id === usedDiscount?.discountId) {
          found = true;
        }
      });
      if (!found) {
        unusedDiscount.push(discount);
      }
    });
    return unusedDiscount;
  };

  const onOptionsDelete = (discountData: any) => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Yes',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          AdditionalChargeService.deleteAdditionalCharge(discountData)
            .then((res: any) => {
              dispatch(fetchAllAdditionalCharge());
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      }
    ];
    showAlert(
      'Confirm Delete',
      'Are you sure, you want to delete this Discount?',
      buttons
    );
  };

  const updateConfig = () => {
    let updatedColumns: any = [...columns];

    updatedColumns?.forEach((conf: any) => {
      if (conf.key === 'discountName') {
        conf.dropdownConfig.data = getOptions()?.length ? getOptions() : [];
        conf.dropdownConfig.button = {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => setShowAddPopup(true)
        };
        conf.dropdownConfig.renderer = (index: any, obj: any) => {
          return (
            <div className="row">
              <DKLabel text={obj.name} style={{ width: 55 }} />
              {/* <div className="row"> */}
              <div style={{ width: 100, height: 1 }}></div>
              <DKButton
                className=""
                icon={DKIcons.ic_edit}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setDiscountObj(obj);
                  setShowAddPopup(true);
                }}
              />
              <DKButton
                className=""
                icon={DKIcons.ic_delete}
                onClick={(event: any) => {
                  event.stopPropagation();
                  onOptionsDelete(obj);
                }}
              />
              {/* </div> */}
            </div>
          );
        };
      }
    });

    setColumns([...updatedColumns]);
  };

  const hideAdditionalChargesPopUp = () => {
    const id: any = `add-discount-popup-wrapper`;
    if (document.getElementById(id)) {
      document.body.removeChild(document.getElementById(id) as any);
    }
  };

  const validate = () => {
    let validated = true;
    rowItems.forEach((row: any) => {
      if (Utility.isEmpty(row.discountName)) {
        validated = false;
      }
    });
    if (!validated) {
      showAlert('Warning', 'Discount Column cannot be empty');
    }
    return validated;
  };

  const onSave = () => {
    if (!validate()) {
      return;
    }
    const updatedRowItems = rowItems?.map((item: any) => {
      return {
        level: item.level,
        discountName: item.discountName,
        discountId: item.discountId
      };
    });

    let payload = {
      additionalSettings: {
        CASCADING_DISCOUNTS: {
          enable: true,
          discountDetails: updatedRowItems,
          discountType: discountType.value
        }
      }
    };

    setSaveApiLoading(true);
    TenantService.updateOrgSetting(payload)
      .then(async (res: any) => {
        setSaveApiLoading(false);
        await dispatch(fetchCurrentTenantInfo());
        props?.onUpdate();
      })
      .catch((err: any) => {
        setSaveApiLoading(false);
        console.log(err);
      });
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto ">
          <DKLabel
            text={`Configure Line Item Discount`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <div
            className={`row border-radius-m ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let updatedRowItems = [...rowItems];
    if (columnKey === 'discountName') {
      updatedRowItems = updatedRowItems?.map((item: any) => {
        if (item.level === rowData?.level) {
          return {
            ...item,
            discountName: rowData?.discountName?.name,
            discountId: rowData?.discountName?.id
          };
        }
        return item;
      });
    }
    setRowItems(updatedRowItems);
  };

  const addNewItem = () => {
    let updatedRowItems: any = [...rowItems];
    updatedRowItems.push({
      level: rowItems.length + 1,
      discountName: ''
    });
    setRowItems([...updatedRowItems]);
  };

  const getButtonForRow = (row: any, index: any) => {
    let btns: any[] = [];
    if (!Utility.isEmpty(row?.discountName)) {
      btns.push({
        icon: DKIcons.ic_edit,
        onClick: (data: any) => {
          setRowIndexSelected(index);
          onRowDiscountEdit(row);
        },
        className: 'p-0'
      });
    }
    btns.push({
      icon: DKIcons.ic_delete,
      onClick: (data: any) => {
        onDelete(row);
      },
      className: 'p-0'
    });

    return btns;
  };

  const onRowDiscountEdit = (data: any) => {
    let discountObject = additionalChargeDetails.filter(
      (row: any) => row.id === data?.discountId
    );
    if (discountObject.length) {
      setDiscountObj(discountObject[0]);
      setIsRowEdit(true);
      setShowAddPopup(true);
    }
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...deepClone(rowItems)]?.map((lineItem: any, index: any) => {
          return {
            ...lineItem,
            rowButtons: getButtonForRow(lineItem, index)
          };
        })}
        columns={[
          ...columns,
          {
            key: 'actions',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            width: 122,
            options: []
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={({ columnData, rowIndex }: any) => {
          setRowIndexSelected(rowIndex);
        }}
      />
    );
  };

  const getDiscountTypeDD = () => {
    return (
      <div style={{ width: 160 }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          direction="Vertical"
          title="Discount Type"
          value={discountType}
          displayKey="title"
          onChange={(value: any) => {
            setDiscountType(value);
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: false,
            searchableKey: null,
            data: discountTypeOption,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.title} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 520,
          width: '90%',
          minHeight: 300,
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 5,
          overflow: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-l p-v-m pb-l gap-2 overflow-y-auto">
          {getDiscountTypeDD()}
          {getDataGrid()}
          <DKButton
            title={`+ Add Discount`}
            onClick={() => addNewItem()}
            className={`${'text-blue'} fw-m p-0`}
            style={{ marginTop: -10, zIndex: 1, paddingLeft: 0, width: 70 }}
          />
          {showAddPopup && (
            <AddAdditionalCharges
              isItemDiscount={true}
              isDiscount={true}
              object={discountObj}
              onCreated={(charge: any) => {
                let updatedRowItems: any = [...rowItems];
                if (Utility.isEmpty(rowItems[rowIndexSelected].discountName)) {
                  updatedRowItems[rowIndexSelected] = {
                    ...updatedRowItems[rowIndexSelected],
                    discountName: charge.name,
                    discountId: charge.id
                  };
                }
                setRowItems([...updatedRowItems]);
                fetchAllAdditionalCharge();
                if (!Utility.isEmpty(charge)) {
                }
                setIsRowEdit(false);
              }}
              onUpdated={(charge: any) => {
                let updatedRowItems: any = [...rowItems];
                if (
                  Utility.isEmpty(rowItems[rowIndexSelected].discountName) ||
                  isRowEdit
                ) {
                  updatedRowItems[rowIndexSelected] = {
                    ...updatedRowItems[rowIndexSelected],
                    discountName: charge.name,
                    discountId: charge.id
                  };
                }
                setRowItems([...updatedRowItems]);
                fetchAllAdditionalCharge();
                if (!Utility.isEmpty(charge)) {
                }
                setIsRowEdit(false);
              }}
              onClose={() => {
                setDiscountObj(null);
                setShowAddPopup(false);
                hideAdditionalChargesPopUp();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CascadingPopup;
