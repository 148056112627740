import ApiConstants from '../Constants/ApiConstants';
import { STATUS_TYPE } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
export interface TaxAPIConfig {
  Limit?: number;
  Search?: string;
  Query?: string;
  TaxId?: any;
  Page?: number;
  Status?: string;
  Type?: string;
  isTaxGroup?: boolean;
  QueryParam?: any;
  sort ? :any;
  sortDir? : any
}

export const defaultConfig: TaxAPIConfig = {
  Limit: 100,
  Query: 'status=active',
  Page: 0,
  Search: '',
  QueryParam: ''
};

class TaxService {
  static apiConfig: TaxAPIConfig;

  static getTaxEndPoint = () =>
    `${ApiConstants.URL.TAX.TAX}?search=${encodeURIComponent(
      TaxService.apiConfig.Search ? TaxService.apiConfig.Search : ''
    )}&query=${TaxService.apiConfig.Query}&limit=${
      TaxService.apiConfig.Limit
    }&page=${TaxService.apiConfig.Page}`;
  static getTax() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    return http
      .get(TaxService.getTaxEndPoint())
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        console.error('Error loading tax: ', err);
        return Promise.reject(err);
      });
  }
  static getTaxTable() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    this.apiConfig.Status = STATUS_TYPE.active;
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${
      this.apiConfig.Search ? encodeURIComponent(this.apiConfig.Search) : ''
    }${
      this.apiConfig.QueryParam ? '' + this.apiConfig.QueryParam : ''
    }&status=${this.apiConfig.Status}`;
    const finalEndpoint: string = ApiConstants.URL.TAX.TAX + queryString;
    return http
      .get(finalEndpoint)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        console.error('Error loading taxTable: ', err);
        return Promise.reject(err);
      });
  }
  static getTaxWithId(taxId: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    this.apiConfig.Limit = 50;
    this.apiConfig.TaxId = taxId;
    const queryString: string = `?query=code=${this.apiConfig.TaxId}&limit=${this.apiConfig.Limit}`;
    const finalEndpoint: string = ApiConstants.URL.TAX.TAX + queryString;
    return http
      .get(finalEndpoint)
      .then((res: any) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        console.error('Error loading taxTable: ', err);
        return Promise.reject(err);
      });
  }
  static updateTax(payload: any, id: number) {
    const finalEndpoint: string = ApiConstants.URL.TAX.UPDATE_TAX(id);
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to update tax: ', err);
      return Promise.reject(err);
    });
  }
  static deleteTax(ids: number[]) {
    const payload = { taxIdList: ids };
    try {
      return http.delete(`${ApiConstants.URL.TAX.DELETE_TAX}`, {
        data: payload
      });
    } catch (err) {
      console.error('Error deleting TAX: ', err);
      return Promise.reject(err);
    }
  }

  static createTaxEntry(payload: any) {
    return http
      .post(ApiConstants.URL.TAX.CREATE_TAX, payload)
      .catch((err: any) => {
        console.error('Error while trying to post status: ', err);
        return Promise.reject(err);
      });
  }
  static getTaxComponentOptions() {
    this.apiConfig.Limit = 50;
    const searchString = this.apiConfig.Search
      ? encodeURIComponent(this.apiConfig.Search)
      : '';
    const queryString: string = `?search=${searchString}&query=${this.apiConfig.Query},type${this.apiConfig.Type},isTaxGroup=${this.apiConfig.isTaxGroup}&limit=${this.apiConfig.Limit}`;
    const finalEndpoint: string = `/taxes` + queryString;
    return http.get(finalEndpoint).catch((err: any) => {
      console.error('Error loading taxTable: ', err);
      return Promise.reject(err);
    });
  }
  static createTaxGroup(payload: any) {
    return http
      .post(ApiConstants.URL.TAX.CREATE_TAX, payload)
      .catch((err: any) => {
        console.error('Error while trying to post tax group: ', err);
        return Promise.reject(err);
      });
  }
  static updateTaxStatus(id: number, status: string) {
    const finalEndPoint = `/taxes/${id}/status/${status}`;
    return http.patch(finalEndPoint).catch((err: any) => {
      console.error('Error while trying to update tax status: ', err);
      return Promise.reject(err);
    });
  }
  static calculateUsTax(payload: any): Promise<any> {
    return http2.post(ApiConstants.URL.TAX.CALCULATE_US_TAX, payload);
  }
}

export default TaxService;
