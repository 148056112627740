import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
export interface AttachmentConfig {
  Module: string;
  EntityId?: any;
  updatePrintInfo?: boolean;
  productCode?: string;
}
class AttachmentService {
  static attachmentConfig: AttachmentConfig;

  static getAllAttachments() {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.GET_ALL(
      this.attachmentConfig.EntityId,
      this.attachmentConfig.Module
    );
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAllAttachmentsByEntityId(payload: any, entityName: any) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.GET_BY_ENTITY(entityName);
    return http
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static uploadAttachment(fileData: File, updateProgress?: any) {
    const formData = new FormData();
    if (this.attachmentConfig.EntityId) {
      formData.append('entityId', this.attachmentConfig.EntityId);
    }
    formData.append('entityName', this.attachmentConfig.Module);
    formData.append(
      'updatePrintInfo',
      String(this?.attachmentConfig?.updatePrintInfo || false)
    );
    formData.append(
      'productCode',
      String(this?.attachmentConfig?.productCode || false)
    );
    formData.append('file', fileData);

    return http
      .post(`${ApiConstants.URL.ATTACHMENT.UPLOAD}`, formData, {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt((percentComplete * 100).toString());
          if (updateProgress) {
            updateProgress({ fileName: fileData.name, percentComplete });
          }
        }
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static downloadAttachment(attachmentId: any) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.DOWNLOAD(attachmentId);
    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteAttachment(
    attachmentId: any,
    updatePrint: boolean = false,
    productCode: string = ''
  ) {
    const finalEndPoint = ApiConstants.URL.ATTACHMENT.DELETE(
      attachmentId,
      updatePrint,
      productCode
    );
    return http
      .delete(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default AttachmentService;
