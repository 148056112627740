import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
export interface TCSSetupAPIConfig {
  Limit?: number;
  Search?: string;
  Query?: string;
  TaxId?: any;
  Page?: number;
  Status?: string;
}

const defaultConfig: TCSSetupAPIConfig = {
  Limit: 25,
  Query: '',
  Page: 0,
  Search: ''
};

class TCSService {
  static apiConfig: TCSSetupAPIConfig;

  static getAllTCS(tenantId: number) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string =
      ApiConstants.URL.TCS_SETTINGS.GET_ALL_TCS(tenantId);
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading tcs rates: ', err);
      return Promise.reject(err);
    });
  }
  static getAllTCSNature(tenantId: number) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string =
      ApiConstants.URL.TCS_SETTINGS.GET_ALL_NATURE(tenantId);
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading tcs nature: ', err);
      return Promise.reject(err);
    });
  }

  static createTCSRate(tenantId: number, data: any) {
    const finalEndPoint: string =
      ApiConstants.URL.TCS_SETTINGS.GET_ALL_TCS(tenantId);

    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateTCSRate(tenantId: number, data: any) {
    const finalEndPoint: string = ApiConstants.URL.TCS_SETTINGS.UPDATE_TCS_RATE(
      tenantId,
      data.id
    );

    return http
      .put(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateTcsStatus(tenantId: any, id: any, status: any) {
    const finalEndPoint = `tenants/${tenantId}/settings/tcs/${id}/status/${status}`;
    return http.patch(finalEndPoint).catch((err: any) => {
      console.error('Error while trying to update Tcs status: ', err);
      return Promise.reject(err);
    });
  }
}

export default TCSService;
