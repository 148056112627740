import React, { useState } from 'react';

export default function useScreenResize(
  ref: React.RefObject<HTMLDivElement | null>,
  viewportRatio = 1
) {
  const [width, setWidth] = useState(window.innerWidth * viewportRatio);

  React.useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const onScreenResize = () => {
      const containerWidth = ref?.current?.getBoundingClientRect()?.width;
      setWidth(containerWidth ?? window.innerWidth * viewportRatio);
    };

    const observer = new ResizeObserver((entries) => {
      if (entries.length) {
        onScreenResize();
      }
    });

    onScreenResize();
    window.addEventListener('resize', onScreenResize);
    observer.observe(ref?.current);
    return () => {
      window.removeEventListener('resize', onScreenResize);
      observer.disconnect();
    };
  }, [ref, viewportRatio]);

  return [width];
}
