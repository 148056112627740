import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import printJS from 'print-js'
import { getVW } from "./Utility";
import { ExportType } from "../Constants/Constants";
import { HTMLTag } from "./HTMLTag";
export default class PdfUtility {

  ///////////////////////////////////////////////////////////////
  // Print
  ///////////////////////////////////////////////////////////////
  static generatePrint(presenter) {
    const printDiv = document.getElementById(HTMLTag.PRINT_HTML);
    const element = document.getElementById(HTMLTag.TEMPLATE);
    const clone = document.body.appendChild(element.cloneNode(true));
    PdfUtility.removeLogoIfNotAvailable(clone)
    this.printHtml(clone, printDiv, presenter)
    clone.remove();
  }

  ///////////////////////////////////////////////////////////////
  // PDF
  ///////////////////////////////////////////////////////////////
  static generatePDF(type, refNumber, presenter, mode) {
    const element = document.getElementById(HTMLTag.TEMPLATE);
    const clone = document.body.appendChild(element.cloneNode(true));
    PdfUtility.removeLogoIfNotAvailable(clone)
    this.createPDF(clone, type + "_" + refNumber, presenter, mode);
    clone.remove();
  }

  ///////////////////////////////////////////////////////////////
  // Email
  ///////////////////////////////////////////////////////////////
  static async getPDFPath(refNumber) {
    const element = document.getElementById(HTMLTag.TEMPLATE);
    const clone = document.body.appendChild(element.cloneNode(true));
    PdfUtility.removeLogoIfNotAvailable(clone)
    let item = await this.getPDFPageUrl(clone, refNumber);
    clone.remove()
    return item
  }

  ///////////////////////////////////////////////////////////////
  // Helper
  ///////////////////////////////////////////////////////////////
  static printHtml(element, printDivElement, presenter) {
    if (element === null) {
      return;
    }
    var pri = printDivElement.contentWindow;
    if (pri === undefined || pri === null) {
      return;
    }

    let pdf = element.getBoundingClientRect()
    element.style.padding = getVW(5)

    html2canvas(element, {
      allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
      useCORS: true
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      var img = new Image();
      img.src = imgData;
      img.height = pdf.height;
      img.width = pdf.width;
      // pri.document.open();
      // pri.document.write(img.outerHTML);
      // pri.document.close();
      setTimeout(function () {
        printJS({
          printable: img.src,
          type: 'image',
          header: '',
          // imageStyle: 'width:103%;margin:-2%;padding:0px;height:99%',
        })
        // pri.focus();
        // pri.print();
        presenter.printingComplete()
      }, 500);
    });
  }


  static createPDF(element, fileName, presenter, mode) {
    if (element !== null) {
      //1125 x 793
      // html2canvas(element, {
      //   allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
      //   useCORS: true
      // }).then((canvas) => {
      //   this.generatePdfPage(canvas, fileName, false, presenter, isViewMode);
      // });
      this.generatePdfPage(element, fileName, false, presenter, mode);
    }
  }

  static async getPDFPageUrl(element, documentNumber) {
    if (element !== null) {
      // //1125 x 793
      // return await html2canvas(element, {
      //   allowTaint: true,
      // }).then((canvas) => {
      //   // document.body.appendChild(canvas);  // if you want see your screenshot in body.
      //   // const imgData = canvas.toDataURL("image/jpg");
      //   return (this.generatePdfPage(canvas, documentNumber, true))
      // });
      return this.generatePdfPage(element, documentNumber, true)
    }
  }

  static generatePdfPage(element, documentNumber, isGenerateUrl = false, presenter = null, mode) {
    // var canvasWidth = canvas.width
    // var canvasHeight = canvas.height
    // var pdf = new jsPDF("p", "px", "a4", true);
    // var width = pdf.internal.pageSize.getWidth();
    // var height = pdf.internal.pageSize.getHeight();
    //
    // let widthRatio = width / canvasWidth;
    // let pageCount = canvasHeight / height * widthRatio
    // let maxHeight = (canvasWidth / width * height)
    //
    // for (let index = 0; index < pageCount; index++) {
    //   if (index > 0) {
    //     pdf.addPage()
    //   }
    //
    //   var srcImg = canvas;
    //   var sX = 0;
    //   var sY = maxHeight * index;
    //   var sWidth = canvasWidth;
    //   var sHeight = maxHeight;
    //   var dX = 0;
    //   var dY = 0;
    //   var dWidth = canvasWidth;
    //   var dHeight = maxHeight;
    //
    //   var onePageCanvas = document.createElement("canvas");
    //   onePageCanvas.setAttribute('width', canvasWidth);
    //   onePageCanvas.setAttribute('height', maxHeight);
    //   var ctx = onePageCanvas.getContext('2d');
    //   ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
    //
    //   // document.body.appendChild(canvas);
    //   var canvasDataURL = onePageCanvas.toDataURL("image/jpg");
    //
    //   // var pdfHeight = onePageCanvas.clientHeight;
    //
    //   pdf.setPage(index + 1);
    //   //! now we add content to that page!
    //   // pdf.addImage(canvasDataURL, 'JPG', 20, 40, (pdfWidth), (pdfHeight));
    //   pdf.addImage(
    //     canvasDataURL,
    //     "JPG",
    //     0,
    //     0,
    //     width,
    //     height,
    //     "",
    //     undefined,
    //     undefined
    //   );
    // }

    var paperFormat = localStorage.getItem('paper_format') === null ? "a4" : localStorage.getItem('paper_format')
    // let doc = new jsPDF("p", "px", paperFormat, true);
    // { unit: 'in', format: 'letter', orientation: 'portrait' }
    // let width = doc.internal.pageSize.getWidth();
    let additionalHeight = 10

    let opt = {
      margin:       [-0.5, 0.00, 0.00, 0.00],
      filename:     documentNumber.length > 0 ? documentNumber + '.pdf' : "Document.pdf",
      html2canvas: { useCORS: true, scale: 2, height: element.clientHeight + additionalHeight, width: element.clientWidth },
      jsPDF: { unit: 'in', format: paperFormat, orientation: 'portrait' }
    };

    if (isGenerateUrl) {
      // return pdf.output('blob')
      return html2pdf().set(opt).from(element).toPdf().get('pdf').output('blob')
    } else {
      setTimeout(function () {
        if (mode === ExportType.VIEW_PDF) {
          //this needs to be checked
          // window.open(pdf.output('bloburl'), "_self")
          html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            window.open(pdf.output('bloburl'), '_self');
          });
        }
        else if (mode === ExportType.PREVIEW_PDF) {
          html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            presenter.previewPdfComplete()
            window.open(pdf.output('bloburl'));
          });
        } else {
          // pdf.save(documentNumber.length > 0 ? documentNumber + '.pdf' : "Document.pdf");
          // presenter.downloadingComplete()

          html2pdf().set(opt).from(element).toPdf().get('pdf').save().then(function(pdfObject) {
            presenter.downloadingComplete()
          });
        }
      }, 0);
    }
  }

  static removeLogoIfNotAvailable(clone) {
    clone.querySelectorAll('img').forEach(image => {
      if (image.id === 'logo') {
        if (image.src.indexOf(".png") !== -1) {
          image.remove()
        }
      }
    })

    clone.querySelectorAll('div').forEach(div => {
      if (div.id === 'editTableButton') {
        div.remove()
      }
    })
  }

  // async generateCanvas(element) {
  //   if (element !== null) {
  //     //1125 x 793
  //     return await html2canvas(element, {
  //       allowTaint: true,
  //       scale: 0.5
  //     }).then((canvas) => {
  //       return canvas
  //     });
  //   }
  // }


  // static generateMultiPage(element, fileName) {
  //   if (element !== null) {
  //     //1125 x 793
  //     html2canvas(element, {
  //       allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
  //       useCORS: true
  //     }).then((canvas) => {
  //       this.generateMultiPagePdf(canvas, fileName);
  //     });
  //   }
  // }

  // static generateMultiPagePdf(canvas, documentNumber, isGenerateUrl = false) {
  //   var canvasWidth = canvas.width
  //   var canvasHeight = canvas.height
  //   var pdf = new jsPDF("p", "px", "a4", true);
  //   var width = pdf.internal.pageSize.getWidth() - 50;
  //   var height = pdf.internal.pageSize.getHeight() - 50;
  //
  //   let widthRatio = width / canvasWidth;
  //   let pageCount = canvasHeight / height * widthRatio
  //   let maxHeight = (canvasWidth / width * height) - 50
  //
  //   for (let index = 0; index < pageCount; index++) {
  //     if (index > 0) {
  //       pdf.addPage()
  //     }
  //
  //     var srcImg = canvas;
  //     var sX = 0;
  //     var sY = maxHeight * index;
  //     var sWidth = canvasWidth;
  //     var sHeight = maxHeight;
  //     var dX = 0;
  //     var dY = 0;
  //     var dWidth = canvasWidth;
  //     var dHeight = maxHeight;
  //
  //     var onePageCanvas = document.createElement("canvas");
  //     onePageCanvas.setAttribute('width', canvasWidth);
  //     onePageCanvas.setAttribute('height', maxHeight);
  //     var ctx = onePageCanvas.getContext('2d');
  //     ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
  //
  //     // document.body.appendChild(canvas);
  //     var canvasDataURL = onePageCanvas.toDataURL("image/jpg");
  //
  //     // var pdfHeight = onePageCanvas.clientHeight;
  //
  //     pdf.setPage(index + 1);
  //     //! now we add content to that page!
  //     // pdf.addImage(canvasDataURL, 'JPG', 20, 40, (pdfWidth), (pdfHeight));
  //     pdf.addImage(
  //         canvasDataURL,
  //         "JPG",
  //         25,
  //         25,
  //         width,
  //         height,
  //         "",
  //         undefined,
  //         undefined
  //     );
  //   }
  //
  //   if (isGenerateUrl) {
  //     return pdf.output('blob')
  //   } else {
  //     setTimeout(function () {
  //       pdf.save(documentNumber.length > 0 ? documentNumber + '.pdf' : "Document.pdf");
  //     }, 0);
  //
  //   }
  // }

  // static async getMultiPagePDFUrl(element, documentNumber) {
  //   if (element !== null) {
  //     //1125 x 793
  //     return await html2canvas(element, {
  //       allowTaint: true,
  //     }).then((canvas) => {
  //       // document.body.appendChild(canvas);  // if you want see your screenshot in body.
  //       // const imgData = canvas.toDataURL("image/jpg");
  //       return (this.generateMultiPagePdf(canvas, documentNumber, true))
  //     });
  //   }
  // }



  // static printMultiPagePdf(element) {
  //   if (element === null) {
  //     return;
  //   }
  //   console.log('printJS')
  //
  //   html2canvas(element, {
  //     allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
  //     useCORS: true
  //   }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/jpg");
  //     var img = new Image();
  //     img.src = imgData;
  //     img.height = 1125;
  //     img.width = 793;
  //     setTimeout(function () {
  //       printJS({
  //         printable: img.src,
  //         type: 'image',
  //         header: '',
  //         // imageStyle: 'width:103%;margin:-2%;padding:0px;height:99%',
  //       })
  //     }, 500);
  //   });
  // }

  // cropCanvas = (sourceCanvas, left, top, width, height) => {
  //   let destCanvas = document.createElement('canvas');
  //   destCanvas.width = width;
  //   destCanvas.height = height;
  //   destCanvas.getContext("2d").drawImage(
  //       sourceCanvas,
  //       left, top, width, height,  // source rect with content to crop
  //       0, 0, width, height);      // newCanvas, same size as source rect
  //   return destCanvas;
  // }


  //
  // static generateSingPage(element, fileName) {
  //   if (element !== null) {
  //     //1125 x 793
  //     html2canvas(element, {
  //       allowTaint: true, //DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
  //       useCORS: true
  //     }).then((canvas) => {
  //       // document.body.appendChild(canvas);  // if you want see your screenshot in body.
  //       this.generateSinglePagePdf(canvas , fileName);
  //     });
  //   }
  // }

  // static async getSinglePagePDFUrl(element, documentNumber) {
  //   if (element !== null) {
  //     //1125 x 793
  //     return await html2canvas(element, {
  //       allowTaint: true,
  //     }).then((canvas) => {
  //       // document.body.appendChild(canvas);  // if you want see your screenshot in body.
  //       // const imgData = canvas.toDataURL("image/jpg");
  //       return (this.generateSinglePagePdf(canvas, documentNumber, true))
  //     });
  //   }
  // }

  // static generateSinglePagePdf(canvas, documentNumber, isGenerateUrl = false) {
  //   const imgData = canvas.toDataURL("image/jpg");
  //   var pdf = new jsPDF("p", "px", "a4", true);
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = pdf.internal.pageSize.getHeight();
  //
  //   let widthRatio = width / canvas.width;
  //   let heightRatio = height / canvas.height;
  //
  //   let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  //
  //   //pdf = new jsPDF(this.state.orientation, undefined, format);
  //   pdf.addImage(
  //       imgData,
  //       "JPG",
  //       0,
  //       0,
  //       canvas.width * ratio,
  //       canvas.height * ratio,
  //       "",
  //       undefined,
  //       undefined
  //   );
  //   if (isGenerateUrl) {
  //     return pdf.output('blob')
  //   } else {
  //     pdf.save(documentNumber.length > 0 ? documentNumber + '.pdf' : "Document.pdf");
  //   }
  // }











}
