class WhatsAppHelper {
  static getMessageForSalesDocument(
    docType: string,
    doc: any,
    link: string,
    tenantName: string
  ) {
    const msg = `Hello,
Thank you for reaching out to us. Please find the
${docType} [${doc.documentSequenceCode}] link attached with this message, in response to your inquiry.
    
Kindly review the same and contact us for any further queries or details.
    
${docType} No: ${doc.documentSequenceCode}
Send By: ${tenantName}
Amount: ${doc.currency} ${doc.totalAmount}

${docType} link: ${link}

We look forward to doing business with you.
Thanks & Regards,
${tenantName}`;

    return msg;
  }

  static getMessageForBuyDocument(
    docType: string,
    doc: any,
    link: string,
    tenantName: string
  ) {
    const msg = `Dear ${doc.contact.name},
Please find the attached link of ${docType} [${
      doc.documentSequenceCode
    }] that lists all items that we wish to purchase from your company.
If you have any questions regarding this ${docType}, please contact us.

${docType} No: ${doc.documentSequenceCode}
Send By: ${tenantName}
Amount: ${doc.currency || doc.currencyCode} ${doc.totalAmount}

${docType} link: ${link}

We look forward to doing business with you.
Thanks & Regards,
${tenantName}`;
    return msg;
  }
}

export default WhatsAppHelper;
