import { ReactElement, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { currencyDropdownStyle } from '../../Constants/Constant';
import { ReactSelectOptionsType } from '../../Models/Interfaces';
import {
  CurrencyDropdownProp,
  CurrencyDropdownState
} from '../../Models/PaymentPopup';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  selectCurrency,
  selectCurrencyListWithExchangeRate
} from '../../Redux/Slices/CommonDataSlice';
import './CurrencyDropdown.scss';

const CurrencyDropdown: React.FC<CurrencyDropdownProp> = (props) => {
  const initialState: CurrencyDropdownState = {
    optionList: [],
    defaultOption: { label: <></>, value: '' }
  };
  const tenantDetails = useAppSelector(activeTenantInfo);
  const currenciesList: any[] = useAppSelector(selectCurrency);
  const currencyListWithExchangeRateList: any[] = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [currencyDropdownState, setCurrencyDropdownState] =
    useState<CurrencyDropdownState>(initialState);

  useEffect(() => {
    const newState = currencyDropdownState;
    let _activeCurrencyList: any = [];
    if (currencyListWithExchangeRateList.length > 0) {
      currencyListWithExchangeRateList.forEach((currency: any) => {
        if (currency.currencyStatus === 'ACTIVE') {
          _activeCurrencyList.push(currency);
        }
      });
    }

    let activeCurrencyList: any[] =
      tenantDetails.multicurrencyEnabled && !props.showAllCurrencyOptions
        ? _activeCurrencyList
        : currenciesList;
    if (props.options && props.options.length > 0) {
      activeCurrencyList = props.options;
    }
    let optionData: ReactSelectOptionsType<ReactElement, string>[] = [];
    optionData = activeCurrencyList.map((currency) => {
      let option: ReactSelectOptionsType<ReactElement, string> = {
        label: <div></div>,
        value: ''
      };
      option.label = (
        <div className="currency-select-trigger">
          <div className="currency-dropdown-flag">
            <span
              className={`currency-dropdown-selector-flag flag ${currency.currencyCode}`}
            ></span>
          </div>
          <div className="currency-dropdown-code">{currency.currencyCode}</div>
        </div>
      );
      option.value = `${currency.currencyCode} ${currency.currencyName}`;
      if (currency.currencyCode === props.currencyCode) {
        newState.defaultOption = option;
      }
      return option;
    });
    newState.optionList = optionData;
    setCurrencyDropdownState({ ...newState });
    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    const newState = currencyDropdownState;
    newState.optionList.forEach((option) => {
      const [currencyCode, others] = option.value.split(' ');
      if (currencyCode && currencyCode === props.currencyCode) {
        newState.defaultOption = option;
      }
    });
    setCurrencyDropdownState({ ...newState });
    return () => {
      // cleanup
    };
  }, [props.currencyCode, props.isDisabled]);

  const optionSelected = (
    selectedOption: ReactSelectOptionsType<ReactElement, string> | null
  ) => {
    if (selectedOption) {
      const [currencyCode, currencyName] = selectedOption.value.split(' ');
      props.getInfo({
        selectedCurrencyCode: currencyCode,
        selectedCurrencyName: currencyName
      });
    }
  };

  const Option = (props: any) => {
    return (
      <>
        <components.Option {...props}>{props.children}</components.Option>
      </>
    );
  };
  return (
    <div
      style={{
        width: props.width ? `${props.width}` : '15rem',
        position: 'relative'
      }}
    >
      {currencyDropdownState.optionList.length > 0 && (
        <Select
          styles={props.style ? props.style : currencyDropdownStyle}
          options={currencyDropdownState.optionList}
          components={{
            Option,
            IndicatorSeparator: () => null
          }}
          onChange={(value) => {
            optionSelected(value);
          }}
          value={currencyDropdownState.defaultOption}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          menuPortalTarget={document.body}
        />
      )}
    </div>
  );
};

export default CurrencyDropdown;
