
import { getLocalisedText } from "../Translate/LanguageManager"

export default class GoodsReceiptMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('goods_receipt')
        label.documentDate = getLocalisedText('goods_receipt_date')
        label.shipFrom = 'ship_from'
        label.shipFromPlaceholder = 'customer_address_placeholder_text_value'
        label.shipTo = 'ship_to'
        label.shipToPlaceholder = 'company_address_placeholder_text_value'
        label.orderNo = 'order_no'
        label.requiredQuantity = 'required_qty'
        label.receivedQuantity = 'received_qty'
        label.warehouseCode = 'warehouse_code'
        label.warehouseName = 'warehouse_name'
        label.POBillNoDate = 'po_bill_no_date'
        label.shortExcessRejectedQty = 'short_excess_rejected_qty'
        label.acceptedQty = 'accepted_qty'
        label.unitPrice = 'unit_price'
        label.basicAmt = 'basic_amt'
        label.charges = 'charges'
        label.amount = 'amount'
        return label
    }
}