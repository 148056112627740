import BaseAPIManager from "./BaseAPIManager";
import ApiConstants from "./ApiConstants";
import AppManager from "../Manager/AppManager";

export default class ShareLinkRepository extends BaseAPIManager {

    generatePublicShareableLink(isDefault, documentCode, documentType, burnAfterRead, payload = null, expiresInSec, password, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.CREATE_SHARE_LINK
        let documentTypeTransformed = this.getDocumentNameType(documentType)
        let data = {
            documentType: documentTypeTransformed,
            burnAfterRead: burnAfterRead,
            documentCode: documentCode,
            expiresInSec: expiresInSec,
            password: (password.trim().length === 0) ? null : password,
            payload: payload
        }
        this.post(urlString, data, (response) =>  {
                    AppManager.setShareableLink(response.code)
                    success(response)
            }, (error) =>  {
                    AppManager.setShareableLink('')
                    failure(error)
            }
        )
    }

    getPublicDocumentDetails(id, passwordReceived, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.GET_SHARE_LINK + id + "?password="+passwordReceived
        this.get(urlString,(response) => success(response), (error) => failure(error))
    }

    //Helper Methods
    getDocumentNameType(type){
        if (type === 'quotation') {
            return 'QUOTATION';
        } else if(type === 'estimate') {
            return 'ESTIMATE';
        } else if (type === 'purchase order' ) {
            return 'PURCHASE_ORDER';
        } else if (type === 'invoice' ) {
            return 'SALES_INVOICE';
        } else if (type === 'bill' ) {
            return 'PURCHASE_INVOICE';
        } else if (type === 'credit_note' ) {
            return 'CREDIT_NOTE';
        } else if (type === 'debit_note' ) {
            return 'DEBIT_NOTE';
        } else if (type === 'expense') {
            return 'EXPENSE';
        } else if (type === 'deposit' ) {
            return 'DEPOSIT';
        }
    }
}
