import { Store } from '../../../../Redux/Store';
import DateFormatService from '../../../../Services/DateFormat';
import NumberFormatService from '../../../../Services/NumberFormat';
import Utility from '../../../../Utility/Utility';

export const replaceLabelsForLiabilitiesAndAssets = (label: string) => {
  // Rename asset label as per requirements
  if (label === 'Non Current Asset') {
    label = 'Long Term Asset';
  }
  if (label === 'Non Current Liabilities') {
    label = 'Long Term Liabilities';
  }

  return label;
};

export const getAmountBlockWithCurrency = (
  amount: number,
  currency?: string
) => {
  let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  if (amount >= 0) {
    return `${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    }`;
  } else {
    return `(${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    })`;
  }
};

export const getAmountWithPercent = (amount: number) => {
  return `${NumberFormatService.getNumber(amount)}%`;
};

export const getParsedChildPNLDetails = (
  obj: any,
  margin: number,
  reportData: any,
  alignAmountColumn: any = null
) => {
  let totalValues: any = {
    expanded: {},
    collapsed: {},
    accountCode: obj['code'],
    details: {}
  };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = {
    expanded: false,
    totalValues: totalValues,
    expandedOnce: false
  };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin + 10,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: '',
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'left'
      }
    });
    row.children.push({
      cells: [],
      children: [],
      expanded: false,
      expandedOnce: false
    });
  }
  for (let i = 0; i < 5; i++) {
    let hasChild = false;
    if (obj['itemDto'] !== null) {
      hasChild = true;
    }
    if (obj['itemDto']?.length === 0) {
      hasChild = false;
    }
    if (i === 3) {
      let title = hasChild
        ? totalValues.collapsed[`Period_1_withChild`]
        : totalValues.expanded[`Period_1`];
      row.cells.push({
        title: hasChild
          ? getAmountBlockWithCurrency(
              totalValues.collapsed[`Period_1_withChild`]
            )
          : getAmountBlockWithCurrency(totalValues.expanded[`Period_1`]),
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right',
          color: !isNaN(title) && title < 0 ? 'red' : 'black'
        }
      });
    } else {
      // row.cells.push({
      //   title: ' ',
      //   style: { fontWeight: 'normal', paddingLeft: margin, textAlign: 'left' }
      // });
      row.cells.push({
        title: '',
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right'
        }
      });
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLDetails(
          item,
          margin + 15,
          reportData,
          alignAmountColumn
        )
      );
    });
  }

  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, null, reportData, alignAmountColumn));
  }

  return row;
};

export const getParsedChildPNLPeriod = (
  obj: any,
  margin: number,
  reportData: any,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    if (period === 'Period_2') {
      obj['periodMap'][period] =
        obj['periodMap']['Period_3'] - obj['periodMap']['Period_1'];
      obj['periodMap'][`${period}_withChild`] =
        obj['periodMap'][`Period_3_withChild`] -
        obj['periodMap'][`Period_1_withChild`];
    }
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    if (period === 'Period_2') {
      obj['periodMap'][period] =
        obj['periodMap']['Period_3'] - obj['periodMap']['Period_1'];
    }
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }

  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLPeriod(item, margin + 15, reportData, currency)
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, null, reportData));
  }

  return row;
};

export const getParsedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNL(item, margin + 15, reportData, currency)
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, currency, reportData));
  }

  return row;
};

export const getParsedSortedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNL(
          item,
          margin + 15,
          reportData,
          direction,
          currency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, currency, reportData));
  }

  return row;
};

export const getParsedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, getCurrency, reportData));
  }

  return row;
};

export const getParsedSortedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          direction,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  if (row.children?.length > 0) {
    row.children.push(getTotalRow(row, getCurrency, reportData));
  }

  return row;
};

export const getAmountBlockWithCurrencyForRevRec = (
  amount: number,
  currency?: string
) => {
  let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  if (amount >= 0) {
    return (
      `${Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      )}` +
      ' ' +
      `${NumberFormatService.getNumber(Math.abs(amount))}`
    );
  } else {
    return `(${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    })`;
  }
};

export const getTotalRow = (
  row: any,
  currency: any,
  reportData: any = null,
  alignAmountColumns: any = null
) => {
  let children: any = {
    expanded: true,
    cells: [],
    children: []
  };

  let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  if (currency === null) {
    currency = tenantInfo.currency;
  }

  children.cells.push({
    title: `Total - ${row.cells?.[0]?.title || ''}`,
    style: {
      paddingLeft: row?.cells?.[0]?.style?.paddingLeft || 6,
      width: alignAmountColumns?.length ? 1160 : 'auto'
    },
    type: 'bold-line'
  });

  if (alignAmountColumns?.length) {
    children.cells.push(...alignAmountColumns);
  }

  const getAmountValueStyle = (amountValue: any) => {
    return !isNaN(amountValue) && amountValue < 0
      ? { color: 'red' }
      : { color: 'black' };
  };

  if (!!reportData) {
    for (var keys in reportData['periodName']) {
      let amountValue = row.totalValues?.collapsed?.[keys + '_withChild'];
      if (amountValue === undefined || amountValue === null) {
        // when account does not have any child account
        amountValue = row.totalValues?.expanded?.[keys];
      }

      children.cells.push({
        title: getAmountBlockWithCurrency(
          amountValue || 0,
          typeof currency === 'function' ? currency(keys) : currency
        ),
        type: 'bold-line',
        style: getAmountValueStyle(amountValue)
      });
    }
  } else {
    // for PnL by Department report
    let amountValue = row.totalValues?.collapsed?.Period_1_withChild;
    if (amountValue === undefined || amountValue === null) {
      // when account does not have any child account
      amountValue = row.amount;
    }

    children.cells.push({
      title: getAmountBlockWithCurrency(amountValue || 0, currency),
      type: 'bold-line',
      style: getAmountValueStyle(amountValue)
    });
  }

  return children;
};

export const getPeriodTitle = (periodName: string, comparisonMode: number) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;

  const dateArray = periodName.split(' - ');
  const title =
    comparisonMode === 3 || comparisonMode === 29
      ? ` ${
          month[
            new Date(
              DateFormatService.getDateFromStr(
                dateArray[0].toString(),
                tenantInfo.dateFormat.toUpperCase()
              )
            ).getMonth()
          ]
        } ${new Date(
          DateFormatService.getDateFromStr(
            dateArray[0].toString(),
            tenantInfo.dateFormat.toUpperCase()
          )
        ).getFullYear()}`
      : periodName;

  return title;
};
