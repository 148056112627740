import React, { Component } from 'react';

import { DKLabel, DKIcons, DKIcon } from 'deskera-ui-library';

import ic_btn_ios from '../../../Assets/mobile/ic_app_store.svg';
import ic_btn_android from '../../../Assets/mobile/ic_google_play.svg';
import ApiConstants from '../../../Constants/ApiConstants';

/*
PROPS
- onClose
*/

class MobileDownloadBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="data-grid-badge-color-4s bg-deskera-secondary border-m border-radius-s row justify-content-between p-s mb-xl display-only-web">
        <div className="row">
          <DKLabel
            text="📱Whether on the go or working remotely, your business is with you."
            className="fw-m fs-m"
          />
        </div>
        <div className="row width-auto  justify-content-end">
          <img
            src={ic_btn_ios}
            alt=""
            className="cursor-hand ml-l"
            style={{ height: 35 }}
            onClick={() => {
              window.open(ApiConstants.URL_MOBILE_IOS, '_blank');
            }}
          />
          <img
            src={ic_btn_android}
            alt=""
            className="cursor-hand ml-m"
            style={{ height: 35 }}
            onClick={() => {
              window.open(ApiConstants.URL_MOBILE_ANDROID, '_blank');
            }}
          />
          <DKIcon
            src={DKIcons.ic_close_}
            className="ic-xs cursor-hand ml-l mr-r"
            onClick={() => {
              this.props.onClose();
            }}
          />
        </div>
      </div>
    );
  }
}

export default MobileDownloadBanner;
