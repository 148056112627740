import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { DKSpinner } from 'deskera-ui-library';
import {
  DESIGNER_TEMPLATE,
  TemplateMappingEvent
} from '../../Constants/Constant';
import PrintService from '../../Services/Print';
import Utility from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  fetchCustomPrintTemplates,
  getSelectedPrintTemplate,
  selectCustomPrintTemplates,
  setCustomPrintTemplates,
  setSelectedPrintTemplate,
  fetchTemplatePrintInfo,
  selectTemplatePrintInfo,
  setTemplatePrintInfo
} from '../../Redux/Slices/CommonDataSlice';

interface EmailDocPreviewProps {
  document?: any;
  documentType?: any;
  onDocumentPrintUUIDChange?: (printUUID: string) => void;
  isDocDesignerOpen?: boolean;
}

export default function EmailDocPreview(props: EmailDocPreviewProps) {
  const dispatch = useAppDispatch();
  const customTemplateList = useAppSelector(selectCustomPrintTemplates);
  const selectedTemplate = useAppSelector(getSelectedPrintTemplate);
  const templatePrintInfo = useAppSelector(selectTemplatePrintInfo);

  const [savedTemplate, setSavedTemplate] = useState<any>(null);
  const [documentPrintUUID, setDocumentPrintUUID] = useState('');
  const [tempFileBuffer, setTempFileBuffer] = useState<any>(null);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const docDesignerOpened = useRef<boolean>(false);

  const file = useMemo(() => ({ data: tempFileBuffer }), [tempFileBuffer]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    window.addEventListener('focus', onFocus);
    return () => {
      cleanup();
      window.removeEventListener('focus', onFocus);
      docDesignerOpened.current = false;
    };
  }, []);

  const onFocus = (callback?: any) => {
    if (docDesignerOpened.current) {
      cleanup();
      getTemplatePrintInfo();
    }
  };

  useEffect(() => {
    if (
      typeof props.isDocDesignerOpen !== 'undefined' &&
      props.isDocDesignerOpen !== null
    )
      docDesignerOpened.current = props.isDocDesignerOpen;
  }, [props.isDocDesignerOpen]);

  const cleanup = () => {
    setTempFileBuffer(null);
    dispatch(setSelectedPrintTemplate(undefined));
    dispatch(setCustomPrintTemplates(null));
    dispatch(setTemplatePrintInfo(null));
  };

  useEffect(() => {
    if (props.onDocumentPrintUUIDChange) {
      props.onDocumentPrintUUIDChange(documentPrintUUID);
    }
  }, [documentPrintUUID]);

  useEffect(() => {
    async function printCustomInvoice(templateId: any) {
      if (!documentPrintUUID) {
        return;
      }
      setIsPDFLoading(true);
      try {

        let pageOrientation = 'PORTRAIT'
        let descriptionString = selectedTemplate?.description ?? ''
        if (descriptionString.includes('[LANDSCAPE]')) {
          pageOrientation = 'LANDSCAPE'
        }
        pageOrientation = pageOrientation.toLowerCase()

        const responseBody = await PrintService.printCustomTemplateInvoice(
          documentPrintUUID,
          templateId,
          true,
          selectedTemplate?.pageFormat ?? '',
          pageOrientation
        );
        const responseBlob = new File([responseBody], 'preview.pdf');
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = (loadEvent: any) => {
          const bufferValue = new Uint8Array(loadEvent.target.result);
          setTempFileBuffer(bufferValue);
          setIsPDFLoading(false);
        };
        fileReader.readAsArrayBuffer(responseBlob);
      } catch (err) {
        console.error('error loading the document: ', err);
        setIsPDFLoading(false);
      }
    }
    if (!Utility.isEmpty(selectedTemplate)) {
      if (selectedTemplate.id === DESIGNER_TEMPLATE.id) {
        printCustomInvoice(null);
      } else {
        printCustomInvoice(selectedTemplate.id);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    function selectCustomDocument(template: any) {
      if (
        template &&
        template.id !== DESIGNER_TEMPLATE.id &&
        template.templateName !== selectedTemplate?.templateName
      ) {
        dispatch(setSelectedPrintTemplate(template));
      } else if (template.id === DESIGNER_TEMPLATE.id) {
        dispatch(setSelectedPrintTemplate(DESIGNER_TEMPLATE));
      }
    }

    async function getDocumentPrintUUID(template = '') {
      if (documentPrintUUID && documentPrintUUID.length > 0 && template) {
        selectCustomDocument(template);
      } else {
        try {
          const printUUID = await PrintService.getDocumentPrintUUID(
            props.document.documentCode,
            PrintService.getPrintDocumentCategory(props.documentType)
          );
          setDocumentPrintUUID(printUUID.code);
          if (template) {
            selectCustomDocument(template);
          }
        } catch (err) {
          console.error('error fetching print uuid: ', err);
        }
      }
    }
    if (!Utility.isEmpty(savedTemplate)) {
      getDocumentPrintUUID(savedTemplate);
    }
  }, [savedTemplate]);

  function getSavedTemplate() {
    try {
      const savedTemplate = templatePrintInfo['defaultTemplateMapping'];
      if (!Utility.isEmpty(savedTemplate)) {
        if (savedTemplate.templateId === 0) {
          setSavedTemplate({ ...DESIGNER_TEMPLATE });
        }
        if (savedTemplate.templateId && savedTemplate.custom) {
          const defaultTemp = customTemplateList.find(
            (obj: any) => obj.id === savedTemplate.templateId
          );
          if (defaultTemp) {
            setSavedTemplate(defaultTemp);
          }
        }
      } else {
        setSavedTemplate({ ...DESIGNER_TEMPLATE });
      }
    } catch (err) {
      console.error('error loding saved template: ', err);
      setSavedTemplate({ ...DESIGNER_TEMPLATE });
    }
  }

  const getTemplatePrintInfo = async () => {
    const payload = {
      category: props.documentType,
      contactCode: props.document.contactCode ? props.document.contactCode : '',
      documentCode: props.document.documentCode,
      documentType: PrintService.getTemplateNameType(
        props.documentType.toString().toLowerCase()
      ),
      event: TemplateMappingEvent.EMAIL
    };
    try {
      await dispatch(fetchTemplatePrintInfo(payload));
    } catch (err) {
      console.error('error loding template list: ', err);
    }
  };

  useEffect(() => {
    getTemplatePrintInfo();
    return () => {
      cleanup();
    };
  }, [props.document, props.documentType]);

  useEffect(() => {
    if (templatePrintInfo) {
      dispatch(setCustomPrintTemplates(templatePrintInfo.documentTemplates));
    }
  }, [templatePrintInfo]);

  useEffect(() => {
    if (templatePrintInfo && customTemplateList) {
      setDocumentPrintUUID(templatePrintInfo.code);
      getSavedTemplate();
    }
  }, [customTemplateList, templatePrintInfo]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <div className="w-full h-full">
      {!isPDFLoading && !Utility.isEmpty(tempFileBuffer) && (
        <SizeMe>
          {({ size }) => (
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={''}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  width={size.width ? size.width : 1}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  loading={''}
                />
              ))}
            </Document>
          )}
        </SizeMe>
      )}
      {isPDFLoading && (
        <div className="flex w-full h-full items-center justify-center font-semibold">
          <DKSpinner
            iconClassName="ic-s-2"
            className="ml-m"
            title="loading document"
          />
        </div>
      )}
    </div>
  );
}
