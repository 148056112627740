import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKListPicker2,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../Constants/ApiConstants';
import { useAppSelector } from '../../../Redux/Hooks';
import { selectMRPProducts } from '../../../Redux/Slices/MRP/MRPProductSlice';
import ProductService from '../../../Services/Product';
import Utility from '../../../Utility/Utility';
import { SUBSTITUTE_PRODUCT_POPUP_TAB_COLUMNS } from '../Constants/MRPColumnConfigs';

export default function SubstituteSelectionPopUp(props: any) {
  const [showProductListPicker, setShowProductListPicker] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>(
    props?.componentProduct?.bomProductSubstitutesDetails || []
  );
  const productsData = useAppSelector(selectMRPProducts);

  const getProductsForSubstitute = () => {
    let addedProducts: any[] = selectedProducts?.reduce(
      (prevValue: any[], currentValue: any) => {
        return [...prevValue, currentValue?.productDocumentSeqCode];
      },
      []
    );
    if (addedProducts) {
      let productsArray = productsData?.content?.filter(
        (item: any) => !addedProducts?.includes(item.documentSequenceCode)
      );
      return productsArray || [];
    } else {
      return productsData?.content;
    }
  };

  const getLineActionButton = (item: any, index: number) => {
    return [
      {
        icon: DKIcons.ic_delete,
        className: 'p-0',
        onClick: (data: any) => {
          let copySubstitute = [...selectedProducts];
          copySubstitute.splice(index, 1);
          setSelectedProducts(copySubstitute);
        }
      }
    ];
  };

  const [selectedProductIndexes, setSelectedProductIndexes] = useState<any[]>(
    []
  );

  const getFilteredProductList = (data: any[]) => {
    let selectedProductsIds = selectedProducts.reduce(
      (prev: any[], current: any) => {
        return [...prev, current.productId];
      },
      []
    );
    let filtered = data?.filter(
      (item: any) => !selectedProductsIds.includes(item.productId)
    );
    return filtered || [];
  };

  useEffect(() => {
    let copyOfProducts = [...productsData?.content];
    let result: any[] = [];
    selectedProducts.forEach((item: any) => {
      let indexOfItem = copyOfProducts.findIndex((productOb: any) => {
        return productOb.productId === item.productId;
      });
      if (indexOfItem >= 0) {
        result.push(indexOfItem);
      }
    });
    setSelectedProductIndexes(result);
  }, [selectedProducts]);

  const getProductListPicker = () => {
    return (
      <DKListPicker2
        style={{ top: 0, right: 0 }}
        title=""
        allowSearch={true}
        searchableKey="productName"
        multiSelect={true}
        selectedIndexes={selectedProductIndexes}
        checkMarkColor="bg-blue"
        onSelect={(index: any, obj: any) => {
          let copySelectedProductIndexes = [...selectedProductIndexes];
          let copySelectedProducts = [...selectedProducts];
          if (copySelectedProductIndexes.includes(index)) {
            let indexOfSelection = copySelectedProducts.findIndex(
              (item: any) => item.productId === obj.productId
            );
            copySelectedProducts.splice(indexOfSelection, 1);
            copySelectedProductIndexes.splice(index, 1);
          } else {
            copySelectedProducts.push({
              productDocumentSeqCode: obj.documentSequenceCode,
              productName: obj.name,
              productId: obj.productId
            });
            copySelectedProductIndexes.push(index);
          }
          setSelectedProducts(copySelectedProducts);
          setSelectedProductIndexes(copySelectedProductIndexes);
        }}
        onClear={() => {}}
        onClose={() => {
          let copySelectedProducts = [...selectedProducts];
          selectedProductIndexes.forEach((element) => {
            let product = productsData?.content[element];
            copySelectedProducts.push({
              productDocumentSeqCode: product.documentSequenceCode,
              productName: product.name,
              productId: product.productId
            });
          });
          setShowProductListPicker(false);
          // setSelectedProducts(copySelectedProducts);
          // setSelectedProductIndexes([]);
        }}
        data={productsData?.content || []}
        className="position-absolute z-index-3 shadow-m ml-l"
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            let endPoint = `products/${Utility.getTenantSpecificApiCode(
              ProductService.moduleName
            )}?search=${searchValue}&limit=20&page=0&query=type=!NONTRACKED,active=true,hasVariants=false`;
            return ApiConstants.URL.BASE + endPoint;
          },
          dataParser: (response: any) => {
            return getFilteredProductList(response?.content || []);
          },
          debounceTime: 300
        }}
        renderer={(index: any, obj: any) => {
          return <DKLabel text={`${obj.name}`} />;
        }}
      />
    );
  };

  const getSubstituteGrid = () => {
    return (
      <div className="column parent-width position-relative gap-4">
        <DKDataGrid
          needShadow={false}
          title={'Selected Substitute Products'}
          buttons={[
            {
              title: '+ Add Substitute',
              className: 'bg-button text-white ',
              onClick: () => {
                setShowProductListPicker(true);
              }
            }
          ]}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          width={467}
          rows={
            selectedProducts?.map((item: any, index: number) => {
              return {
                ...item,
                product: { ...item },
                rowButtons: getLineActionButton(item, index)
              };
            }) || []
          }
          columns={[
            ...SUBSTITUTE_PRODUCT_POPUP_TAB_COLUMNS,
            {
              id: 'action',
              key: 'action',
              name: '',
              type: INPUT_TYPE.BUTTON,
              width: 100
            }
          ]}
        />
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t-md">
        <div className="row width-auto">
          <DKLabel text="Substitute Details" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              props.onSave(selectedProducts);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background" style={{ zIndex: 9998 }}>
      <div
        className="popup-window"
        style={{
          width: 500,
          padding: 0,
          overflow: 'visible',
          position: 'relative'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-4 overflow-y-scroll hide-scroll-bar">
          {getSubstituteGrid()}
        </div>
        {showProductListPicker && getProductListPicker()}
      </div>
    </div>
  );
}
