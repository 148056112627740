import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_STATUS } from "../../Constants/Constant";
import { COLUMN_CODE } from "../../Constants/TableConstants";
import { GridState } from "../../Models/ReduxStore";
import { IColumn } from "../../Models/Table";
import FulfillmentMasterService from "../../Services/FulfillmentMasterService";
import { RootState } from "../Store";

const hideFulfillmentMasterColumnConfig = [
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.JE_NUMER_FF,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.CUSTOMER_NAME,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.CUSTOMER_CODE,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.TOTAL_AMOUNT_FF,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.CUSTOMER_ORDER_NO,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.QC_STATUS,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.APPROVAL_STATUS,
  COLUMN_CODE.BOOKS_FULFILLMENT_MASTER.MEMO,
];

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  content: [] as any,
  
};

export const fetchFulfillmentMasterList = createAsyncThunk('fetchFulfillmentMasterList', async () => {
  const response = await FulfillmentMasterService.getFulfillmentMasterData();
  return response;
});

export const FufillmentMasterSlice = createSlice({
  name: 'fulfillment_master',
  initialState,
  reducers: {
    addFulfillmentMasterColumnConfig:(state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        const includes = hideFulfillmentMasterColumnConfig.includes(config.columnCode);
        if(!includes) {
          configsToStore.push({
            ...config,
            key: config.columnCode,
            options: config.options ? config.options : []
          });
        }        
      });
      state.columnConfig = configsToStore;
    },
    addFulfillmentMaster:(state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFulfillmentMasterList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchFulfillmentMasterList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  } 
})

export const {addFulfillmentMaster, addFulfillmentMasterColumnConfig} = FufillmentMasterSlice.actions;

export const selectFulfilmentMasterData = (state:RootState) => state.fulfillmentMaster;