import { CurrencyFormatType } from "../Constants/Constants";
import AppManager from "../Manager/AppManager";

export const DEFAULT_DECIMAL_SCALE = 2;
export const DEFAULT_NUMBER_FORMAT = "us";
export const NUMBER_FORMAT_LOCALE = {
    in: "en-IN",
    es: "es-ES",
    fr: "fr-FR",
    us: "en-US"
};

export const DECIMAL_RATIO = {
    1: 10,
    2: 100,
    3: 1000,
    4: 10000,
    5: 100000
};


export default class NumberUtility {
    static getNumberLocale(numberFormat) {
        return NUMBER_FORMAT_LOCALE[numberFormat] || NUMBER_FORMAT_LOCALE[DEFAULT_NUMBER_FORMAT];
    }

    static formatNumberWithLocale(number, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
        const locale = this.getNumberLocale(numberFormat);
        return Number(number).toLocaleString(locale, { minimumFractionDigits: decimalScale, maximumFractionDigits: decimalScale});
    }

    static getFormatNumber(number, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
        return this.formatNumberWithLocale(number, decimalScale, numberFormat);
    }

    static formatNumberWithCurrency(numberWithCurrency, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
        const tokens = numberWithCurrency.split(" ");
        const currency = tokens[0];
        const number = tokens[1];
        const formattedNumber = this.getNumberLocale(number, decimalScale, numberFormat);
        return currency + formattedNumber
        // return `${currency}`${ formattedNumber }``;
    }

    static getCurrency(documentData) {
        var currency = ''
        if (documentData.showCurrency || documentData.showCheckCurrency) {
            currency = documentData.currency ? documentData.currency : '$'
            if (documentData.currencyFormat !== undefined && documentData.currencyFormat !== null) {
                switch (documentData.currencyFormat) {
                    case CurrencyFormatType.SYMBOL:
                        currency = documentData.currency ? documentData.currency : '$'
                        break;
                    case CurrencyFormatType.NAME:
                        currency = documentData.currencyName ? documentData.currencyName : '$'
                        break;
                    case CurrencyFormatType.SYMBOL_NAME:
                        currency = (documentData.currencyName ? documentData.currencyName : '$') + ' ' + (documentData.currency ? documentData.currency : '$')
                        break;
                    case CurrencyFormatType.CUSTOM:
                        currency = documentData.currencyCustomValue ? documentData.currencyCustomValue : ''
                        break;

                    default:
                        break;
                }
            }
        }
        return currency
    }

    static isValidFormat(value, numberFormat, decimalScale) {
        if (typeof value === "number") {
            return false
        }

        var format = this.getNumberLocale(numberFormat)
        var intValue = value
        var isValid = false
        var isValidDecimalPoint = false
        var isValidInt = false
        switch (format) {
            case "en-IN":
                isValidDecimalPoint = this.isValidDecimalValue(value, decimalScale, '.', ',')
                if(decimalScale !== 0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = value.substring(0, value.length - (decimalScale + 1))
                }
                isValidInt = this.isValidIntValue(intValue, [','], ['.', ' '])
                isValid = (isValidInt && isValidDecimalPoint)
                break;
            case "es-ES":
                isValidDecimalPoint = this.isValidDecimalValue(value, decimalScale, ',', '.')
                if (decimalScale !== 0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = value.substring(0, value.length - (decimalScale + 1))
                }
                isValidInt = this.isValidIntValue(intValue, ['.'], [',',' '])
                isValid = (isValidInt && isValidDecimalPoint)
                break;
            case "fr-FR":
                isValidDecimalPoint = this.isValidDecimalValue(value, decimalScale, ',', '.')
                if (decimalScale !== 0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = value.substring(0, value.length - (decimalScale + 1))
                }
                isValidInt = this.isValidIntValue(intValue, [' '], ['.', ','])
                isValid = (isValidInt && isValidDecimalPoint)
                break;
            case "en-US":
                isValidDecimalPoint = this.isValidDecimalValue(value, decimalScale, '.', ',')
                if (decimalScale !== 0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = value.substring(0, value.length - (decimalScale + 1))
                }
                isValidInt = this.isValidIntValue(intValue, [','], ['.', ' '])
                isValid = (isValidInt && isValidDecimalPoint)
                break;
            default:
                break;
        }
        return isValid
    }

    static isOtherFormat(value, numberFormat, decimalScale) {
        var newValue = undefined
        var defaultNumberFormat = ['in', 'es', 'fr', 'us']
        var includedNumberFormatList = defaultNumberFormat.filter(x => x !== numberFormat)
        includedNumberFormatList.forEach(element => {
            if (this.isValidFormat(value, element, decimalScale) && newValue === undefined) {
                var normalValue = this.toNormalValue(value, element, decimalScale)
                if(normalValue !== undefined) {
                    newValue = normalValue
                }
            }
        });
        return newValue
    }

    static toNormalValue(value, numberFormat, decimalScale) {
        if (typeof value === "number") {
            return undefined
        }

        var format = this.getNumberLocale(numberFormat)
        var intValue = value
        var decimalValue = 0.0
        switch (format) {
            case "en-IN":
                if(this.isContainsDecimalValue(value,decimalScale)) {
                    decimalValue = this.getDecimalValue(value, decimalScale, '.', ',')
                }
                if (decimalScale !== 0.0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = this.getIntValue(value, decimalScale, '.', ',')
                }
                intValue = this.removeAllSeparator(intValue)
                break;
            case "es-ES":
                if (this.isContainsDecimalValue(value, decimalScale)) {
                    decimalValue = this.getDecimalValue(value, decimalScale, ',', '.')
                }
                if (decimalScale !== 0.0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = this.getIntValue(value, decimalScale, '.', ',')
                }
                intValue = this.removeAllSeparator(intValue)
                break;
            case "fr-FR":
                if (this.isContainsDecimalValue(value, decimalScale)) {
                    decimalValue = this.getDecimalValue(value, decimalScale, ',', '.')
                }
                if (decimalScale !== 0.0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = this.getIntValue(value, decimalScale, '.', ',')
                }
                intValue = this.removeAllSeparator(intValue)
                break;
            case "en-US":
                if (this.isContainsDecimalValue(value, decimalScale)) {
                    decimalValue = this.getDecimalValue(value, decimalScale, '.', ',')
                }
                if (decimalScale !== 0.0 && this.isContainsDecimalValue(value, decimalScale)) {
                    intValue = this.getIntValue(value, decimalScale, '.', ',')
                }
                intValue = this.removeAllSeparator(intValue)
                break;
            default:
                break;
        }

        if(isNaN(Number(intValue) || isNaN(Number(decimalValue)))) {
            return undefined
        }
        return Number(intValue)<0 ? Number(intValue) - Number(decimalValue):Number(intValue) + Number(decimalValue)
    }

    static isValidDecimalValue(value, decimalScale, includedSymbol, excludedSymbol) {
        var isValidDecimalPoint = false
        if (decimalScale !== 0) {
            if (value.length > decimalScale + 1) {
                var decValue = value.substring(value.length - (decimalScale + 1))
                if (decValue.includes(includedSymbol) && !decValue.includes(excludedSymbol)) {
                    isValidDecimalPoint = true
                }
                else if (!decValue.includes(includedSymbol) && !decValue.includes(excludedSymbol) && !decValue.includes(' ')) {
                    isValidDecimalPoint = true
                }
            }
        }
        else {
            isValidDecimalPoint = true
        }
        return isValidDecimalPoint
    }

    static isContainsDecimalValue(value, decimalScale) {
        var isContains = false
        if (value.length > decimalScale + 1) {
            var decValue = value.substring(value.length - (decimalScale + 1))
            if (decValue.includes(',') || decValue.includes('.')) {
                isContains = true
            }
        }
        return isContains
    }

    static isValidIntValue(value, includedSymbol, excludedSymbol) {
        var isNotIncluded = true
        if (value.length > 3) {
            includedSymbol.forEach(element => {
                if (value.includes(element)) {
                    isNotIncluded = true
                }
            });
            excludedSymbol.forEach(element => {
                if (value.includes(element)) {
                    isNotIncluded = false
                }
            });
        }
        else {
            includedSymbol.forEach(element => {
                if(value.includes(element)) {
                    isNotIncluded = false
                }
            });
            excludedSymbol.forEach(element => {
                if (value.includes(element)) {
                    isNotIncluded = false
                }
            });
        }
        return isNotIncluded
    }

    static getIntValue(value, decimalScale, includedSymbol, excludedSymbol) {
        var intValue = 0
        var decimal = decimalScale
        if (decimalScale !== 0) {
            if (value.length > decimalScale + 1) {
                var decValue = value.substring(value.length - (decimalScale + 1))
                if (decValue.includes(includedSymbol) && !decValue.includes(excludedSymbol)) {
                    var newDecimalPoint = decValue.split(includedSymbol)
                    if (newDecimalPoint.length > 1) {
                        var last = newDecimalPoint[newDecimalPoint.length - 1]
                        decimal = last.length
                    }
                }
            }
        }

        if (decimal > 0) {
            intValue = value.substring(0, value.length - (decimal + 1))
        }
        return intValue
    }

    static getDecimalValue(value, decimalScale, includedSymbol, excludedSymbol) {
        var decimalValue = 0.00
        var decimal = decimalScale
        if (decimalScale !== 0) {
            if (value.length > decimalScale + 1) {
                var decValue = value.substring(value.length - (decimalScale + 1))
                if (decValue.includes(includedSymbol) && !decValue.includes(excludedSymbol)) {
                    var newDecimalPoint = decValue.split(includedSymbol)
                    if(newDecimalPoint.length > 1) {
                        var last = newDecimalPoint[newDecimalPoint.length - 1]
                        decValue = last
                        decimal = last.length
                    }
                    decimalValue = parseFloat(decValue)
                }
            }
        }

        if (decimal > 0 ) {
            decimalValue = decimalValue / DECIMAL_RATIO[decimal]
        }
        return decimalValue
    }

    static removeAllSeparator(value) {
        return value.split('.').join('').split(',').join('').split(' ').join('')
    }

    static getFormattedValue(value, numberFormat, decimalScale) {
        var formatted = value
        if (this.isValidFormat(value, numberFormat, decimalScale) && decimalScale !== AppManager.getTenantDecimalScale()) {
            var formattedValue = NumberUtility.toNormalValue(value, numberFormat, decimalScale)
            if (formattedValue !== undefined) {
                formatted = formattedValue
            }
        }
        else {
            var newValue = NumberUtility.isOtherFormat(value, numberFormat, AppManager.getTenantDecimalScale())
            if (newValue !== undefined) {
                formatted = newValue
            }
            else {
                formatted = Number(value)
            }
        }

        return formatted
    }
}


