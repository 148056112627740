import { ProductionPlanStatus } from '../../Components/Mrp/Constants/ProductionPlan';
import ApiConstants from '../../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import { ApiResponse } from '../../Models/Common';
import Utility from '../../Utility/Utility';
import http from '../Interceptor';

/**
 * @description type declaration for params for fetching production plan list
 */
/**
 *
 * @param params - params for fetching production plan list
 * @returns - promise containing production plan list data
 */

export default class ProductionPlanService {
  static fetchProductionPlan = (params?: any) => {
    return http.get(ApiConstants.URL.MRP.PRODUCTION_PLAN.GET, { params });
  };

  static createProductionPlan(productionPlan: any) {
    let finalUrl = ApiConstants.URL.MRP.PRODUCTION_PLAN.ADD;
    return http.post(finalUrl, productionPlan).catch((err: any) => {
      return Promise.reject(err);
    });
  }

  static updateProductionPlan(productionPlan: any) {
    let finalUrl =
      ApiConstants.URL.MRP.PRODUCTION_PLAN.ADD + `/${productionPlan.id}`;
    return http.put(finalUrl, productionPlan).catch((err: any) => {
      return Promise.reject(err);
    });
  }

  static getSalesOrdersFor(searchKey: any) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.SALES_ORDER
    );
    let url = `${country}?limit=25&page=0&search=${searchKey}&=&sort=salesOrderDate&sortDir=DESC`;
    return http
      .get(ApiConstants.URL.SALES_ORDER.FETCH_SALES_ORDERS + url)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteProductionPlan(productionPlan: any) {
    let finalUrl = ApiConstants.URL.MRP.PRODUCTION_PLAN.DELETE;
    let arrayOfIds: any[] = [];
    arrayOfIds.push(productionPlan.id);
    return http.delete(finalUrl, { data: arrayOfIds }).catch((err: any) => {
      return Promise.reject(err);
    });
  }
}
