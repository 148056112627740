import { TableTotalColumnList } from "../../../Constants/Constants";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import TableFooterUtility from "../../../Utilities/TableFooterUtility";
import { getFirstTableColumnType, getSortedHeaderType } from "../TableUtility";
export class AccountTableGroupHeaderColumn {
    title: string;

    constructor(title: string) {
        this.title = title
        if(title === 'none') {
            this.title = getLocalisedText('none')
        }
    }


    toString() {
        var sortedHeader = getSortedHeaderType()
        if(sortedHeader.length > 0) {
            var sortedValue: string[] = []
            var footerList = TableFooterUtility.getFooterListByType('account')
            var firstColumnType = getFirstTableColumnType(footerList, undefined)
            sortedHeader.forEach(type => {
                if(footerList.includes(type)) {
                    return
                }
                sortedValue.push(this.getDefaultValue(type, firstColumnType))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getDefaultValue(type: string, firstColumn: string | undefined) {
        if (firstColumn !== undefined) {
            if (firstColumn === type) {
                if (!TableTotalColumnList.includes(type)) {
                    return '<b>' + this.title + '</b>'
                }
            }
        }
        return ''
    }

    getDefaultList() {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        ]
    }
}
