import { BOOKS_ADDRESS_TYPES } from '../Constants/Constant';
import { BooksAddress, CallBackPayloadType } from './Interfaces';
import { InputTag } from './NewContact';

export interface EditAddressState {
  existingAddress: BooksAddress[];
  newAddress: NewAddressType[];
  activeAddress: ActiveAddressType;
}

export type NewAddressType = {
  contactName: InputTag<string>;
  address: InputTag<string>;
  postalCode: InputTag<string>;
  city: any;
  state: any;
  country: any;
};

export type ActiveAddressType = {
  addressType: AddressType;
  index: number;
};

export enum AddressType {
  EXISTING_ADDRESS = 'existingAddress',
  NEWLY_ADDED_ADDRESS = 'newAddress'
}

export enum EditAddressInputType {
  CONTACT_NAME = 'contactName',
  ADDRESS = 'address',
  POSTAL_CODE = 'postalCode',
  STATE = 'state',
  COUNTRY = 'country',
  CITY = 'city'
}

export interface EditAddressProps {
  contactDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  addressType: BOOKS_ADDRESS_TYPES;
  addressList: BooksAddress[] | undefined;
  onCancel?: () => void;
  onAddressUpdate?: () => void;
}
