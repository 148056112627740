import { useCallback, useEffect, useState, useRef } from 'react';
import { DKButton, DKLabel, DKDataGrid, INPUT_TYPE } from 'deskera-ui-library';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility from '../../Utility/Utility';
import { ADVANCE_TRACKING } from '../../Constants/Enum';
import { ROW_RACK_BIN_CONSTANT, STATUS_TYPE } from '../../Constants/Constant';
import useScreenResize from '../../Hooks/useScreenResize';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { getNewColumn } from '../Accounting/JournalEntry/JEHelper';
import { addProductCustomFieldsToLineItem } from '../../SharedComponents/CustomFieldsHolder/BatchSerialCustomFieldsHelper';

const COLUMN_KEYS = {
  WAREHOUSE_NAME: 'warehouseName',
  SERIAL_BATCH_NAME: 'serialBatchNumber',
  ROW: 'row',
  RACK: 'rack',
  BIN: 'bin',
  RESERVED_QUANTITY: 'reservedQuantity'
};

const COLUMNS = [
  {
    id: COLUMN_KEYS.WAREHOUSE_NAME,
    key: COLUMN_KEYS.WAREHOUSE_NAME,
    name: 'Warehouse Name',
    type: INPUT_TYPE.TEXT,
    width: 260,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.SERIAL_BATCH_NAME,
    key: COLUMN_KEYS.SERIAL_BATCH_NAME,
    name: 'Batch Name',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.ROW,
    key: COLUMN_KEYS.ROW,
    name: 'Row',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.RACK,
    key: COLUMN_KEYS.RACK,
    name: 'Rack',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.BIN,
    key: COLUMN_KEYS.BIN,
    name: 'Bin',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: COLUMN_KEYS.RESERVED_QUANTITY,
    key: COLUMN_KEYS.RESERVED_QUANTITY,
    name: 'Reserved Quantity',
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  }
];

const ViewAdvancedTrackingDetails = ({
  trackingPopupDetails,
  onCancel
}: any) => {
  const [gridRows, setGridRows] = useState<any[]>([]);
  const [unmodifiedGridRows, setUnmodifiedGridRows] = useState<any[]>([]); // for search purpose
  const [gridColumns, setGridColumns] = useState(COLUMNS);
  const [searchString, setSearchString] = useState<any>(undefined);

  const tenantInfo = useAppSelector(activeTenantInfo);
  const viewAdvanceTrackedPopup = useRef<HTMLDivElement | null>(null);
  // const [gridWidth] = useScreenResize(viewAdvanceTrackedPopup);
  const [gridWidth, setGridWidth] = useState<any>();
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);

  const rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
    (item: any) => item?.enable
  );

  const updatedColumnConfig = useCallback(() => {
    let config = [...gridColumns];

    if (Utility.isNotEmpty(rrbEnabled)) {
      let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
      config = config?.map((col: any) => {
        if (
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
          col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
        ) {
          const isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
            return i.name === col.key.toUpperCase() && i.enable;
          });
          if (isRRBEnabled) {
            col = {
              ...col,
              name: isRRBEnabled?.label
            };
          }
        }

        return col;
      });
    } else {
      config = config?.filter(
        (col: any) =>
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.ROW &&
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.RACK &&
          col.key.toUpperCase() !== ROW_RACK_BIN_CONSTANT.BIN
      );
    }

    if (
      trackingPopupDetails?.product?.advancedTracking === ADVANCE_TRACKING.NONE
    ) {
      config = config?.filter(
        (col: any) => col.key !== COLUMN_KEYS.SERIAL_BATCH_NAME
      );
    } else if (
      trackingPopupDetails?.product?.advancedTracking ===
      ADVANCE_TRACKING.SERIAL
    ) {
      config = config?.map((col: any) => {
        if (col.key === COLUMN_KEYS.SERIAL_BATCH_NAME) {
          col = {
            ...col,
            name: 'Serial Number'
          };
        }
        return col;
      });
    }
    if (
      trackingPopupDetails?.product?.advancedTracking !== ADVANCE_TRACKING.NONE
    ) {
      let activeProductCustomFields = [];
      if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
        let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
          return item.status === STATUS_TYPE.ACTIVE;
        });
        activeProductCustomFields = productCf.sort(
          (field1: any, field2: any) =>
            field1.customFieldIndex - field2.customFieldIndex
        );
      }

      activeProductCustomFields?.forEach((accCF: any) => {
        let newItem: any = getNewColumn(accCF);
        newItem['editable'] = false;
        const newItemInExistingColConfig = config.find(
          (config: any) => config.code === accCF.code
        );
        if (Utility.isEmpty(newItemInExistingColConfig)) {
          config.push({ ...newItem });
        }
      });
    }

    config.forEach((conf: any) => {
      switch (conf.key) {
        case COLUMN_KEYS.WAREHOUSE_NAME:
          conf.formatter = (obj: any) => {
            const whCode = obj?.rowData?.warehouseCode ?? '';
            let htmlText = `<div class="column parent-width">
                <span>${obj?.rowData?.warehouseName}</span>
                <span class="fs-s text-gray">(${whCode})</span>
              </div>`;
            return htmlText;
          };
          return;

        default:
          break;
      }
    });
    setGridColumns([...config]);
  }, []);

  useEffect(() => {
    if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
      updatedColumnConfig();
    }
  }, [batchSerialCFfromStore]);

  useEffect(() => {
    updatedColumnConfig();
    if (Utility.isNotEmpty(trackingPopupDetails)) {
      let gridRowsTemp: any[] = [];

      if (
        trackingPopupDetails?.product?.advancedTracking ===
        ADVANCE_TRACKING.NONE
      ) {
        trackingPopupDetails?.warehouseInventoryData?.forEach(
          (whInvData: any) => {
            let rowObj = {
              warehouseName: whInvData?.warehouseName,
              warehouseCode: whInvData?.warehouseCode,
              reservedQuantity: whInvData?.quantity,
              row: whInvData?.rowName,
              rack: whInvData?.rackName,
              bin: whInvData?.binName
            };
            gridRowsTemp.push(rowObj);
          }
        );
      }

      if (
        trackingPopupDetails?.product?.advancedTracking ===
          ADVANCE_TRACKING.BATCH ||
        trackingPopupDetails?.product?.advancedTracking ===
          ADVANCE_TRACKING.SERIAL
      ) {
        trackingPopupDetails?.warehouseInventoryData?.forEach(
          (whInvData: any) => {
            let rowObj = {
              warehouseName: whInvData?.warehouseName,
              warehouseCode: whInvData?.warehouseCode,
              serialBatchNumber:
                whInvData?.advancedTrackingData?.[0]?.serialBatchNumber,
              reservedQuantity: whInvData?.quantity,
              row: whInvData?.rowName,
              rack: whInvData?.rackName,
              bin: whInvData?.binName,
              customField: whInvData?.advancedTrackingData?.[0]?.customField
            };
            rowObj = addProductCustomFieldsToLineItem(
              { ...rowObj },
              batchSerialCFfromStore?.content
            );
            gridRowsTemp.push(rowObj);
          }
        );
      }

      setGridRows(gridRowsTemp);
      setUnmodifiedGridRows(gridRowsTemp);
    }
  }, [trackingPopupDetails, updatedColumnConfig]);

  useEffect(() => {
    if (searchString !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        let localGridRows = [...unmodifiedGridRows];

        localGridRows = localGridRows?.filter((row: any) => {
          return (
            row?.warehouseCode
              ?.toLowerCase()
              ?.includes(searchString?.toLowerCase()) ||
            row?.warehouseName
              ?.toLowerCase()
              ?.includes(searchString?.toLowerCase()) ||
            row?.serialBatchNumber
              ?.toLowerCase()
              ?.includes(searchString?.toLowerCase()) ||
            row?.row?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
            row?.rack?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
            row?.bin?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
            row?.reservedQuantity

              ?.toString()
              ?.toLowerCase()
              ?.includes(searchString?.toLowerCase())
          );
        });

        setGridRows(localGridRows);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchString]);

  useEffect(() => {
    if (!viewAdvanceTrackedPopup?.current) {
      return;
    }

    const onScreenResize = () => {
      const containerWidth =
        viewAdvanceTrackedPopup?.current?.getBoundingClientRect()?.width;
      setGridWidth(containerWidth ?? window.innerWidth * 1);
    };

    const observer = new ResizeObserver((entries) => {
      if (entries.length) {
        onScreenResize();
      }
    });

    onScreenResize();
    window.addEventListener('resize', onScreenResize);
    observer.observe(viewAdvanceTrackedPopup?.current);
    return () => {
      window.removeEventListener('resize', onScreenResize);
      observer.disconnect();
    };
  }, [viewAdvanceTrackedPopup]);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={`View allocated data`} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={() => {
              onCancel();
            }}
          />
        </div>
      </div>
    );
  };

  const getGeneralInfo = () => {
    return (
      <div className="row">
        <div className="column" style={{ width: 150, maxWidth: 150 }}>
          <DKLabel text="Material name:" className="fw-b" />
          <DKLabel text="Material code:" className="fw-b" />
        </div>
        <div className="column">
          <DKLabel text={trackingPopupDetails?.product?.productName ?? ''} />
          <DKLabel text={trackingPopupDetails?.documentSequenceCode ?? ''} />
        </div>
      </div>
    );
  };

  const getGrid = () => {
    return (
      <DKDataGrid
        title={``}
        width={gridWidth}
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={true}
        allowShare={false}
        rows={[...gridRows]}
        columns={gridColumns}
        onRowUpdate={(data: any) => {}}
        onRowClick={(data: any) => {}}
        onSearch={(term: any) => {
          setSearchString(term);
        }}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            minWidth: Utility.isNotEmpty(rrbEnabled) ? 850 : 700,
            maxHeight: '90%',
            padding: 0,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width p-l overflow-y-auto show-scroll-bar">
            {getGeneralInfo()}
            <div ref={viewAdvanceTrackedPopup} className="column parent-width">
              {getGrid()}
            </div>
            {/* {Utility.isEmpty(gridRows) && (
              <div className="fw-b p-m row justify-content-center">
                No allocations available yet for this material.
              </div>
            )} */}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default ViewAdvancedTrackingDetails;
