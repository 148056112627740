export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 15;
export const CP_USERS_COLUMN = [
    // {
    //   name: 'Contact Code',
    //   type: 'text',
    //   index: 0,
    //   options: null,
    //   required: false,
    //   width: 170,
    //   editable: false,
    //   hidden: false,
    //   uiVisible: true,
    //   systemField: true,
    //   columnCode: 'booksContactCode',
    //   key: 'booksContactCode',
    //   id: 'booksContactCode',
    //   allowFilter: false
    // },
    {
      name: 'Name',
      type: 'text',
      index: 1,
      options: null,
      required: false,
      width: 250,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'name',
      key: 'name',
      id: 'name',
      allowFilter: false
    },
    {
      name: 'Email',
      type: 'text',
      index: 2,
      options: null,
      required: false,
      width: 250,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'email',
      key: 'email',
      id: 'email',
      allowFilter: false
    },
    {
      name: 'Status',
      type: 'select',
      index: 3,
      required: false,
      width: 170,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'inactive',
      key: 'inactive',
      options: [
        {
          id: false,
          name: 'Active',
          color: 'data-grid-badge-color-6'
        },
        {
          id: true,
          name: 'In-Active',
          color: 'data-grid-badge-color-10'
        }
      ],
      id: 'inactive',
      allowFilter: false
    }
  ];