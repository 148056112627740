import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http2 from '../Services/Interceptor2';

export interface SalesPersonConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  QueryParam?: any;
  Query?: any;
  SortDir?: any;
}

export const defaultConfig: SalesPersonConfig = {
  SearchTerm: '',
  Limit: 10,
  Page: 0,
  QueryParam: '',
  Query: '',
  SortDir: 'DESC'
};

class SalesPersonMasterService {
  static apiConfig: SalesPersonConfig = defaultConfig;
  static abortController: any = null;

  static getSalesPersonApiUrl() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const { BASE, SALES_PERSON_MASTER } = ApiConstants.URL;
    const url = `${BASE}${SALES_PERSON_MASTER.FETCH_SALES_PERSON.slice(
      SALES_PERSON_MASTER.FETCH_SALES_PERSON.startsWith('/') ? 1 : 0
    )}`;

    const params = new URLSearchParams({
      search: this.apiConfig.SearchTerm || '',
      limit: this.apiConfig.Limit ?? defaultConfig.Limit,
      page: this.apiConfig.Page ?? defaultConfig.Page,
      query: this.apiConfig.Query || '',
      sortDir: this.apiConfig.SortDir ?? defaultConfig.SortDir,
      ...this.apiConfig.QueryParam
    });

    return `${url}?${params.toString()}`;
  }
  
  static getSalesPersonByPage() {
    Utility.cancelRequest(SalesPersonMasterService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.SALES_PERSON_MASTER.FETCH_SALES_PERSON + queryString;

    SalesPersonMasterService.abortController = Utility.createAbortController();

    return http2
      .get(`${finalEndpoint}`, {
        signal: SalesPersonMasterService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createSalesPersonMaster(payload: any) {
    return http2
      .post(
        `${ApiConstants.URL.SALES_PERSON_MASTER.CREATE_SALES_PERSON}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error while creating Sales Person: ', err);
        return Promise.reject(err);
      });
  }

  static deleteSalesPersonMaster(id: number) {
    return http2
      .delete(ApiConstants.URL.SALES_PERSON_MASTER.DELETE_SALES_PERSON + id)
      .catch((err: any) => {
        console.error('Error while deleting Sales Person: ', err);
        return Promise.reject(err);
      });
  }

  static updateSalesPersonMaster(payload: any) {
    return http2
      .put(
        ApiConstants.URL.SALES_PERSON_MASTER.UPDATE_SALES_PERSON + payload?.id,
        payload
      )
      .catch((err: any) => {
        console.error('Error while updating Sales Person: ', err);
        return Promise.reject(err);
      });
  }
}

export default SalesPersonMasterService;
