import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { TableManager } from '../../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { IColumn } from '../../../Models/Table';
import { MachineMasterService } from '../../../Services/MachineMasterService';
import { RootState } from '../../Store';
import { ApiResponse } from '../../../Models/Common';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchMachines = createAsyncThunk('machines', async () => {
  const response = await MachineMasterService.getMachineByPage();
  return response;
});

const machinesInitialsState = {
  machine: { ...initialState },
  machineSchedules: { ...initialState }
};

export const fetchMachineSchedules = createAsyncThunk(
  'machineSchedules',
  async (ids: string[]) => {
    return await MachineMasterService.getMachineByAssetGroup(ids);
  }
);

export const MachineToolMasterSlice = createSlice({
  name: 'MachineToolMaster',
  initialState: machinesInitialsState,
  reducers: {
    addMachineToolMasterColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.machine.columnConfig = configsToStore;
      state.machine.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.BOOKS_REPORTS_MRP_MACHINE_MASTER_TOOLS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.machine.columnConfig = [...action.payload];
    },
    addColumnToOperatorColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.machine.columnConfig = [...action.payload];
    },

    addMachineSchedulesColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.machineSchedules.columnConfig = configsToStore;
      state.machineSchedules.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.MRP_MACHINE_ASSET_MAINTAINCE_SCHEUDLE
      );
    },
    updateMachineSchedulesColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.machineSchedules.columnConfig = [...action.payload];
    },
    addColumnToMachineSchedulesColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.machineSchedules.columnConfig = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachines.pending, (state) => {
        state.machine.status = API_STATUS.LOADING;
      })
      .addCase(fetchMachines.fulfilled, (state, action) => {
        state.machine.status = API_STATUS.IDLE;
        state.machine.data = action.payload;
      })
      .addCase(fetchMachines.rejected, (state) => {
        state.machine.status = API_STATUS.FAILED;
      })
      .addCase(fetchMachineSchedules.pending, (state) => {
        state.machineSchedules.status = API_STATUS.LOADING;
      })
      .addCase(fetchMachineSchedules.fulfilled, (state, action) => {
        state.machineSchedules.status = API_STATUS.IDLE;
        state.machineSchedules.data = action.payload;
      })
      .addCase(fetchMachineSchedules.rejected, (state) => {
        state.machineSchedules.status = API_STATUS.FAILED;
      });
  }
});

export const {
  addMachineToolMasterColumnConfig,
  updateColumnConfig,
  addColumnToOperatorColumnConfig,
  addMachineSchedulesColumnConfig,
  updateMachineSchedulesColumnConfig,
  addColumnToMachineSchedulesColumnConfig
} = MachineToolMasterSlice.actions;

export const selectMachineToolMasterData = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machine.data;

export const selectMachineToolMasterStatus = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machine.status;

export const selectMachineToolMasterColumnConfig = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machine.columnConfig;

export const selectMachineToolMasterConfigTableId = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machine.configTableId;

export const selectMachineSchedules = (state: RootState): ApiResponse<any> =>
  state.mrpMachineToolsMaintaince.machineSchedules.data;

export const selectMachineSchedulesStatus = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machineSchedules.status;

export const selectMachineSchedulesColumnConfig = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machineSchedules.columnConfig;

export const selectMachineSchedulesConfigTableId = (state: RootState) =>
  state.mrpMachineToolsMaintaince.machineSchedules.configTableId;

export default MachineToolMasterSlice.reducer;
