import { Component, createRef } from 'react';
import 'quill/dist/quill.snow.css';
import './quillStyles.scss';
import Quill from 'quill';
import { DKInput, DKButton, INPUT_TYPE } from 'deskera-ui-library';
import React from 'react';
import Popup from '../Popup';
import { TableManager, TABLES } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { EMAIL_PLACEHOLDER_VALUE } from '../../Components/Automation/AutomationConstants';

class EmailEditor extends React.Component<any, any> {
  editorContainerRef: any;
  quillEditor: any;
  tableColumns: any = {};
  nameColumnId: any;
  constructor(props: any) {
    super(props);

    this.editorContainerRef = createRef();
    this.quillEditor = null;
    this.tableColumns = this.getTableColumnsForEditor();
    this.state = {
      needHyperLinkPopup: false,
      linkText: '',
      linkURL: '',
      linkTextError: false,
      linkURLError: false
    };
  }

  getTableColumnsForEditor() {
    const tableColumnsData = TableManager.getTableFilteredColumns(
      REMOTE_CONFIG_TABLES.CONTACT,
      (col: any) =>
        col.type !== INPUT_TYPE.SELECT && col.type !== INPUT_TYPE.MULTI_SELECT,
      false
    );

    let tableColumns: any = {};
    tableColumnsData.forEach((column: any) => {
      tableColumns[column.id] = `${column.name}`;
      if (column.columnCode === 'name') {
        this.nameColumnId = column.id;
      }
    });
    if (this.props.placeholders) {
      return (tableColumns = this.props.placeholders);
    }
    return (tableColumns = EMAIL_PLACEHOLDER_VALUE);
    // return tableColumns;
  }

  componentDidMount() {
    /* Remove existing toolbar inside editor container if any */
    this.editorContainerRef.current?.querySelector('.ql-toolbar')?.remove();

    try {
      this.setEditorCustomizations(Quill);
      this.quillEditor = new Quill(`#${this.props.editorID}`, {
        ...this.getQuillConfig(),
        bounds: `#${this.props.editorID}`,
        placeholder: this.props.inputPlaceholder || 'Compose an email...'
      });

      this.initializeBody();
      this.setupCustomFormatLabels();
    } catch (e) {
      console.log(e);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.body && nextProps.body !== this.props.body) {
      this.clearBody();
      this.initializeBody(nextProps, true);
    }
  }

  clearBody = () => {
    const bodyLength = this.quillEditor?.getLength?.();
    if (bodyLength) {
      this.quillEditor.deleteText(0, bodyLength);
    }
  };

  render() {
    return (
      <div ref={this.editorContainerRef} className="parent-width">
        <div
          className="parent-width"
          id={this.props.editorID}
          style={{ height: this.props.isPlaceholderRequired ? 270 : 373 }}
        ></div>
        {this.getHyperLinkPopup()}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////  INITIALIZERS START ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  getQuillConfig = () => {
    return {
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [
              { size: ['10px', '13px', '16px', '18px', '24px'] },
              { color: [] },
              { background: [] }
            ],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
            ['link'],
            // hide the image and placeholder dropdown
            // ["image", "link"],
            /* Custom formats group below */
            [
              this.props.isPlaceholderRequired && {
                'email-placeholder': Object.keys(this.tableColumns)
              }
            ]
          ],
          handlers: {
            image: this.triggerImageDialog,
            link: this.showHyperLinkPopup,
            'email-placeholder': this.handlePlaceholderInsertion
          }
        },
        clipboard: {
          matchVisual: false
        }
      },
      readOnly: this.props.readOnly || false
    };
  };

  setEditorCustomizations = (quill: any) => {
    /* forcing quill editor to place alignment & size as inline-styles instead of classes */
    const AlignStyle = quill.import('attributors/style/align');
    quill.register({ 'formats/align': AlignStyle }, true);

    const SizeStyle = quill.import('attributors/style/size');
    SizeStyle.whitelist = ['10px', '13px', '16px', '18px', '24px'];
    quill.register({ 'formats/size': SizeStyle }, true);

    const Block = quill.import('blots/block');
    Block.tagName = 'div';
    quill.register(Block, true);

    this.setFieldListFormatter(quill);
  };

  initializeBody = (nextProps = this.props, insertBody = true) => {
    let { body } = nextProps;
    if (body && insertBody) {
      if (this.nameColumnId) {
        body = body.replace(
          `%name%`,
          `<span class="custom_email_placeholder" data-id=${this.nameColumnId}>%${this.nameColumnId}%</span>`
        );
      }

      this.quillEditor.clipboard.dangerouslyPasteHTML(0, body);
      this.quillEditor.blur();

      /* updating email-placeholders text if present in body before hand */
      const placeholders =
        this.editorContainerRef.current?.getElementsByClassName(
          'custom_email_placeholder'
        );
      placeholders &&
        Array.from(placeholders).forEach((placeholder: any) => {
          const dataId = placeholder.getAttribute('data-id');
          const columnName = this.tableColumns[dataId] || '';
          placeholder.innerHTML = `<span contenteditable="false">${columnName}</span>`;
        });
    }
  };

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////  CUSTOM PLACEHOLDERS START ////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  setFieldListFormatter = (quill: any) => {
    const Embed = quill.import('blots/embed');

    class EmailPlaceholder extends Embed {
      static create(value: any) {
        let node = super.create(value);
        node.setAttribute('data-id', value.dataId);
        node.textContent = value.displayName;
        return node;
      }

      static value(node: any) {
        return {
          dataId: node.getAttribute('data-id'),
          displayName: node.textContent
        };
      }
    }

    EmailPlaceholder.blotName = 'email-placeholder';
    EmailPlaceholder.className = 'custom_email_placeholder';
    EmailPlaceholder.tagName = 'SPAN';

    quill.register({ 'formats/email-placeholder': EmailPlaceholder }, true);
  };

  setupCustomFormatLabels = () => {
    const placeholderFormats = this.editorContainerRef.current.querySelectorAll(
      '.ql-email-placeholder .ql-picker-item'
    );

    Array.from(placeholderFormats).forEach((placeholderElement: any) => {
      const columnID = placeholderElement.getAttribute('data-value');
      placeholderElement.textContent = this.tableColumns[columnID];
    });

    this.quillEditor.root.addEventListener('click', this.editorClickHandler);
  };

  handlePlaceholderInsertion = (columnId: any) => {
    if (columnId) {
      const range = this.quillEditor.getSelection(true);
      if (range.length) {
        const selectedText = this.quillEditor.getText(
          range.index,
          range.length
        );
        this.quillEditor.deleteText(range.index, selectedText.trimEnd().length);
      }

      // this.quillEditor.insertEmbed(range.index, 'email-placeholder', {
      //   dataId: columnId,
      //   displayName: this.tableColumns[columnId]
      // });

      let text = '{{' + columnId + '}}';
      this.quillEditor.insertText(range.index, text);
      this.quillEditor.insertText(range.index + text.length, ' ');
      this.quillEditor.setSelection(range.index + text.length + 1);
    }
  };

  editorClickHandler = (event: any) => {
    const target = event.target;
    const emailPlaceholderElement = target?.closest(
      '.custom_email_placeholder'
    );

    let placeholderButton = this.editorContainerRef.current?.querySelector(
      '.ql-email-placeholder .ql-picker-label'
    );

    if (!placeholderButton) {
      return;
    } else if (emailPlaceholderElement) {
      placeholderButton.classList.add('ql-active');
      const dataId = emailPlaceholderElement.getAttribute('data-id');
      placeholderButton.setAttribute('data-value', dataId);

      placeholderButton.setAttribute('data-title', this.tableColumns[dataId]);
    } else {
      placeholderButton.classList.remove('ql-active');
    }
  };

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////  IMAGE HANDLERS START /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  triggerImageDialog = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.accept = 'image/*';
    input.addEventListener('change', (e: any) => {
      if (e.target.files.length > 0) {
        // this.uploadImageToAWS(e.target.files[0]);
      }
    });
    input.click();
  };

  // uploadImageToAWS = (imageData:any) => {
  //   ApiManager.getUploadFileURL(
  //     imageData,
  //     (url:any) => {
  //       const range = this.quillEditor.getSelection();
  //       this.quillEditor.insertEmbed(range.index, "image", url);
  //     },
  //     (err:any) => {}
  //   );
  // };

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////  HYPER-LINK HANDLERS START ////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////
  showHyperLinkPopup = (e: any) => {
    const range = this.quillEditor.getSelection();
    let linkText = '';

    if (range && range.length) {
      linkText = this.quillEditor.getText(range.index, range.length);
    }

    this.setState((prev: any) => ({
      needHyperLinkPopup: true,
      linkText: linkText
    }));
  };

  closeHyperLinkPopup = () => {
    this.setState({
      needHyperLinkPopup: false,
      linkText: '',
      linkURL: '',
      linkTextError: false,
      linkURLError: false
    });
  };

  getAbsoluteURL(url: any) {
    const regex = /^https?:\/{2}(\w)?/i;
    const mailLinkRegex = /^mailto:\w/i;
    if (!url || regex.test(url) || mailLinkRegex.test(url)) return url;

    return `//${url}`;
  }

  onInsertHyperLink = () => {
    const { linkText, linkURL }: any = this.state;
    if (linkText && linkURL) {
      const range = this.quillEditor.getSelection(true);
      if (range && range.length) {
        const selectedText = this.quillEditor.getText(
          range.index,
          range.length
        );
        this.quillEditor.deleteText(range.index, selectedText.trimEnd().length);
      }

      this.quillEditor.insertText(
        range.index,
        linkText.trim(),
        'link',
        this.getAbsoluteURL(linkURL)
      );

      this.quillEditor.setSelection(range.index, 0);

      this.closeHyperLinkPopup();
    } else {
      this.setState({
        linkTextError: linkText ? false : true,
        linkURLError: linkURL ? false : true
      });
    }
  };

  getHyperLinkPopup = () => {
    return this.state.needHyperLinkPopup ? (
      <Popup
        allowClose={true}
        onClose={this.closeHyperLinkPopup}
        title="Insert Link"
      >
        <div className="column">
          <DKInput
            className="mt-xl"
            title="Text"
            type={INPUT_TYPE.TEXT}
            value={this.state.linkText}
            canValidate={this.state.linkTextError}
            // invalid={this.state.linkTextError}
            onChange={(val: any) => this.setState({ linkText: val })}
          />
          <DKInput
            className="mt-r"
            title="URL"
            type={INPUT_TYPE.TEXT}
            value={this.state.linkURL}
            canValidate={this.state.linkURLError}
            // invalid={this.state.linkURLError}
            onChange={(val: any) => this.setState({ linkURL: val })}
          />
          <div className="row justify-content-end align-items-center mt-xl">
            <a
              className="justify-self-start"
              href={this.getAbsoluteURL(this.state.linkURL)}
              style={{
                marginRight: 'auto'
              }}
              target="_blank"
              rel="noreferrer"
            >
              Preview Link
            </a>
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-s"
              onClick={this.closeHyperLinkPopup}
            />
            <DKButton
              title="Save"
              className="bg-button text-white"
              onClick={this.onInsertHyperLink}
            />
          </div>
        </div>
      </Popup>
    ) : null;
  };
}

export default EmailEditor;
