import JobCardService from '../../../../Services/MRP/JobCard';
import MRPProductsService from '../../../../Services/MRP/MRPProducts';
import Utility from '../../../../Utility/Utility';
import { JobCardQCModel, WarehouseInventoryData } from './JobCardQCModel';
import { TRACKING_DETAILS } from '../../../../Constants/Enum';

export class JobCardQcPresenter {
  constructor() {}
  fetchJobCardsQCData(productCode: string, jcCode: string) {
    return JobCardService.fetchJobCardsQCData(productCode, jcCode)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => [
        console.log('Error while fetching QC details', error)
      ]);
  }
  fetchProductData(productCode: string) {
    // calling this api for limited product details hence giving 0 qty.
    return MRPProductsService.getProductShortInfo([
      { productCode: productCode, quantity: 0 }
    ])
      ?.then((response: any) => {
        return !Utility.isEmpty(response) ? response[0] : null;
      })
      .catch((err: any) => {
        console.log('Unable to fetch Product details =>', err);
      });
  }

  parseWarehouseInventroyData(item: any) {
    return {
      id: item?.id ?? null,
      warehouseCode: item?.warehouseCode ?? null,
      rowCode: item?.rowCode ?? null,
      rowName: item?.rowName ?? null,
      rackCode: item?.rackCode ?? null,
      rackName: item?.rackName ?? null,
      binCode: item?.binCode ?? null,
      binName: item?.binName ?? null,
      warehouseName: item?.warehouseName ?? null,
      quantity: item?.qtyToFulfil ?? 0,
      uomQuantity: item?.qtyToFulfil,
      qtyToFulfil: item?.qtyToFulfil ?? 0,
      qtyToFulfilUom: item?.qtyToFulfil,
      advancedTrackingType: item?.advancedTrackingType,
      advancedTrackingData:
        item?.advancedTrackingData?.map((advItem: any) => {
          return {
            serialBatchNumber: advItem?.serialBatchNumber ?? null,
            qtyToFulfil: advItem?.qtyToFulfil ?? 0,
            qtyToFulfilUom: advItem?.qtyToFulfil,
            manufacturingDate: advItem?.manufacturingDate ?? null,
            expiryDate: advItem?.expiryDate ?? null,
            costPerUnit: advItem?.costPerUnit ?? 0,
            remainingQuantity: advItem?.remainingQuantity ?? null
          };
        }) || []
    };
  }

  parseRequestPayload(payload: any) {
    let result = {
      id: payload?.id,
      passedQuantity: payload?.passedQuantity ?? null,
      uomPassedQuantity: payload?.passedQuantity,
      qcReason: payload?.qcReason ?? '',
      passedWarehouseInventoryData:
        payload?.passedWarehouseInventoryData?.map((item: any) => {
          return this.parseWarehouseInventroyData(item);
        }) ?? [],
      rejectedQuantity: payload?.rejectedQuantity ?? null,
      uomRejectedQuantity: payload?.rejectedQuantity,
      rejectedWarehouseInventoryData:
        payload?.rejectedWarehouseInventoryData?.map((item: any) => {
          return this.parseWarehouseInventroyData(item);
        }) ?? [],
      documentUOMSchemaDefinition: payload?.documentUOMSchemaDefinition,
      stockUom: payload?.documentUOMSchemaDefinition?.sinkUOM
    };
    return result;
  }

  updateQCDetails(request: any) {
    let payload = this.parseRequestPayload(request);
    return JobCardService.updateJobCardQC([payload])
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log('QC update failed', err);
        return 'QC update failed';
      });
  }

  getFilteredDataForNoneTrackedProduct(
    formState: JobCardQCModel,
    updateTrackingType: string
  ) {
    let result: any;
    let mainWarehouseData = formState?.itemWarehouseInventoryData ?? [];
    let dataToFilter: any = [];
    if (updateTrackingType === TRACKING_DETAILS.PASSED) {
      dataToFilter = formState.rejectedWarehouseInventoryData;
    } else if (updateTrackingType === TRACKING_DETAILS.REJECTED) {
      dataToFilter = formState.passedWarehouseInventoryData;
    }
    result = mainWarehouseData?.map((warehouseData: WarehouseInventoryData) => {
      let copyWarehouseData = { ...warehouseData };
      let findInDataToFilter = dataToFilter?.find(
        (dataToFilterItem: WarehouseInventoryData) => {
          return (
            dataToFilterItem?.warehouseCode ===
              copyWarehouseData.warehouseCode &&
            dataToFilterItem?.rowCode == copyWarehouseData?.rowCode &&
            dataToFilterItem?.rackCode == copyWarehouseData?.rackCode &&
            dataToFilterItem?.binCode == copyWarehouseData?.binCode
          );
        }
      );
      if (!Utility.isEmpty(findInDataToFilter)) {
        copyWarehouseData.quantity =
          copyWarehouseData?.quantity - findInDataToFilter?.quantity;
      }
      return copyWarehouseData;
    });
    result = result?.filter((item: any) => item?.quantity > 0);
    return result;
  }

  getFilteredDataForSerialTrackedProduct(
    formState: JobCardQCModel,
    updateTrackingType: string
  ) {
    let result: any;
    let itemInventoryData = formState?.itemWarehouseInventoryData ?? [];
    let existingInventoryData: any = [];

    if (updateTrackingType === TRACKING_DETAILS.PASSED) {
      existingInventoryData = formState.rejectedWarehouseInventoryData;
    } else if (updateTrackingType === TRACKING_DETAILS.REJECTED) {
      existingInventoryData = formState.passedWarehouseInventoryData;
    }
    result = itemInventoryData?.map((warehouseData: any) => {
      let copyWarehouseData = { ...warehouseData };

      const findInDataToFilter = existingInventoryData?.find(
        (dataToFilterItem: any) => {
          const {
            warehouseCode,
            rowCode,
            rackCode,
            binCode,
            advancedTrackingData
          } = dataToFilterItem;
          let serialBatchNumber =
            advancedTrackingData?.[0]?.serialBatchNumber ?? '';
          return (
            warehouseCode === copyWarehouseData.warehouseCode &&
            rowCode == copyWarehouseData?.rowCode &&
            rackCode == copyWarehouseData?.rackCode &&
            binCode == copyWarehouseData?.binCode &&
            serialBatchNumber ===
              copyWarehouseData?.advancedTrackingData?.[0]?.serialBatchNumber
          );
        }
      );
      if (!Utility.isEmpty(findInDataToFilter)) {
        copyWarehouseData.quantity =
          copyWarehouseData?.quantity - findInDataToFilter?.qtyToFulfil;
      }
      return copyWarehouseData;
    });
    result = result?.filter((item: any) => item?.quantity > 0);
    return result;
  }

  getFilteredDataForBatchTrackedProduct(
    formState: JobCardQCModel,
    updateTrackingType: string
  ) {
    let result: any;
    let mainWarehouseData = [...formState?.itemWarehouseInventoryData] ?? [];
    let dataToFilter: any = [];
    if (updateTrackingType === TRACKING_DETAILS.PASSED) {
      dataToFilter = [...(formState.rejectedWarehouseInventoryData ?? [])];
    } else if (updateTrackingType === TRACKING_DETAILS.REJECTED) {
      dataToFilter = [...(formState.passedWarehouseInventoryData ?? [])];
    }
    result = mainWarehouseData?.map((warehouseData: any) => {
      let copyWarehouseData = { ...warehouseData };
      let findInDataToFilter = dataToFilter?.find((dataToFilterItem: any) => {
        let serialBatchNumber =
          copyWarehouseData?.advancedTrackingData?.[0]?.serialBatchNumber ?? '';
        return (
          dataToFilterItem?.warehouseCode === copyWarehouseData.warehouseCode &&
          dataToFilterItem?.rowCode == copyWarehouseData?.rowCode &&
          dataToFilterItem?.rackCode == copyWarehouseData?.rackCode &&
          dataToFilterItem?.binCode == copyWarehouseData?.binCode &&
          dataToFilterItem?.serialBatchNumber === serialBatchNumber
        );
      });
      if (!Utility.isEmpty(findInDataToFilter)) {
        copyWarehouseData.advancedTrackingData[0].qtyToFulfil =
          copyWarehouseData?.advancedTrackingData?.[0]?.qtyToFulfil -
          findInDataToFilter?.qtyToFulfil;
        copyWarehouseData.quantity =
          copyWarehouseData.quantity - findInDataToFilter?.qtyToFulfil;
      }
      return copyWarehouseData;
    });
    result = result?.filter(
      (item: any) => item.advancedTrackingData[0].qtyToFulfil > 0
    );
    return result;
  }
}
