import { saveAs } from 'file-saver';
import { AddressDTO, BOOKS_DATE_FORMAT } from '../Constants/Constant';
import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
import DateFormatService from './DateFormat';
import Utility from '../Utility/Utility';
import { showAlert } from 'deskera-ui-library';
import { Store } from '../Redux/Store';
import CalendarDateService from './CalenderDate';
import { MachineMaterialItem } from './MachineMaterialConsumptionService';
import { IRow } from '../Models/Table';
import { CustomField } from '../Models/CustomField';

export type MachineShortFallRowItem = MachineMaterialItem & {
  product: string;
  available_qty: number;
  required_qty: number;
  uom: any;
  availability: number;
};

export function isMachineEnabled(): boolean {
  const tenantInfo: any = Store.getState().authInfo.currentTenantInfo.data;
  if (!tenantInfo || !tenantInfo.additionalSettings) {
    return false;
  }
  const enabled = tenantInfo.additionalSettings.ENABLE_MACHINE_MAINTENANCE;
  return !!enabled;
}
export const mapMachineAndAssetKeys = {
  assetGroup: 'assetGroupId',
  assetType: 'assetType',
  assetId: 'assetId',
  sequenceFormat: 'sequenceFormat',
  cost: 'purchasePrice',
  description: 'description',
  vendor: 'contact.name'
};

const todaysDate = DateFormatService.getDateStrFromDate(
  new Date(),
  BOOKS_DATE_FORMAT['YYYY-MM-DD']
);

const aMonthNextsDate = DateFormatService.getDateStrFromDate(
  CalendarDateService.addMonths(new Date(), 1),
  BOOKS_DATE_FORMAT['YYYY-MM-DD']
);

const todaysDateDDMMYYYY = DateFormatService.getDateStrFromDate(
  new Date(),
  BOOKS_DATE_FORMAT['DD-MM-YYYY']
);

const purchaseInvoiceAccount = {
  accountCode: '',
  accountDescription: '',
  currency: '',
  amount: '',
  totalAmount: ''
};

export const MachineMaintainceInitailState = {
  id: '',
  assetType: '',
  sequenceFormat: '',
  assetGroup: 0,
  assetId: '',
  isAsset: false,
  documentSequenceCode: '',
  assetRequest: {
    assetGroupId: '',
    name: '',
    openingAsset: false,
    serialNumber: '',
    purchaseDate: todaysDateDDMMYYYY,
    purchasePrice: 0,
    description: '',
    depreciationStartDate: todaysDateDDMMYYYY,
    residualValue: 0,
    depreciationThreshold: 0,
    depreciationMethod: null,
    depreciationConvention: null,
    depreciationRate: null,
    currency: '',
    documentSequenceCode: '',
    sequenceFormat: '',
    purchaseInvoiceRequest: {
      contactCode: '',
      documentSequenceCode: '',
      currency: '',
      status: 'OPEN',
      documentType: 'PURCHASE_INVOICE',
      receiveGoodsStatus: 'NOT_APPLICABLE',
      purchaseInvoiceDueDate: todaysDateDDMMYYYY,
      purchaseInvoiceType: 'EXPENSE',
      purchaseInvoiceDate: todaysDateDDMMYYYY,
      documentNumber: '',
      purchaseInvoiceAccounts: [],
      contact: {
        name: ''
      },
      sequenceFormat: '',
      shipFrom: AddressDTO,
      billTo: AddressDTO,
      shipTo: AddressDTO
    }
  },
  cost: 0,
  description: '',
  effectiveDate: todaysDate,
  expectedMeanTimeBetweenFailure: {
    days: 0,
    hours: 0,
    minutes: 0
  },
  expectedNextMaintenanceDate: aMonthNextsDate,
  latestFailure: todaysDate,
  linkedOperation: [],
  location: '',
  maintenanceVendor: '',
  meanTimeToRepair: {
    days: 0,
    hours: 0,
    minutes: 0
  },
  model: '',
  name: '',
  owner: '',
  serialNumber: '',
  sop: [],
  technicianName: [],
  vendor: '',
  warrantyExpirationDate: todaysDate,
  noOfParallelJobs: 0,
  customField: [] as CustomField[],
};

export type MachineServiceConfig = typeof defaultApiConfig;

const defaultApiConfig = {
  fetchOperationDetails: true,
  limit: 25,
  page: 0,
  query: '',
  sort: '',
  sortDir: '',
  search: '',
  customFilter: [],
  fetchAssetDetails: true
};

export class MachineMasterService {
  static apiConfig = defaultApiConfig;
  static abortController: any = null;
  static FILE_NAME = 'machine_master';
  static async createMachine(payload: any) {
    const url = `${ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE}`;
    return http.post(url, payload).catch((err: any) => {
      console.error('Error creating machine: ', err);
      return Promise.reject(err);
    });
  }
  private static getParams() {
    return new URLSearchParams({
      limit: String(this.apiConfig.limit),
      page: String(this.apiConfig.page),
      query: String(this.apiConfig.query),
      sort: this.apiConfig.sort,
      sortDir: this.apiConfig.sortDir,
      search: this.apiConfig.search,
      customFilter: Utility.isNotEmpty(this.apiConfig.customFilter)
        ? JSON.stringify(this.apiConfig.customFilter)
        : JSON.stringify([]),
      fetchOperationDetails: String(this.apiConfig.fetchOperationDetails)
    });
  }

  static getEndpoint() {
    const params = this.getParams();
    const url = `${
      ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_MACHINE
    }?${params.toString()}`;
    return url;
  }
  static async getMachineByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultApiConfig;
    }
    const params = this.getParams();
    const url = `${
      ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_MACHINE
    }?${params.toString()}`;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error fetching machine list: ', err);
        return Promise.reject(err);
      });
  }
  static deleteMachineById = (id: string) => {
    const url = ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE + '/' + id;
    return http
      .delete(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error fetching machine list: ', err);
        showAlert('Error', err.message);
        return Promise.reject(err);
      });
  };

  static updateMachineById = (payload: any, id: string) => {
    const url = `${ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE}/${id}`;
    return http
      .put(url, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error fetching machine list: ', err);
        showAlert('Error', err.message);
        return Promise.reject(err);
      });
  };

  static getMachinesById = (ids: string[]) => {
    // BY_IDS

    const url = ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.MACHINE_BY_IDS;
    return http
      .post(url, ids)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        showAlert('Error', err.message);
        console.error('Error fetching machine list: ', err);
        return Promise.reject(err);
      });
  };
  static getSOPs = () => {
    const url = ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_SOPS;
    return http
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        showAlert('Error', err.message);
        console.error('Error fetching machine list: ', err);
        return Promise.reject(err);
      });
  };
  static export = () => {
    const params = this.getParams();
    const url = `${
      ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_MACHINE
    }?${params.toString()}&isExport=true`;
    return http
      .get(url, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, this.FILE_NAME);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      })
      .finally(() => {});
  };

  static getMachineByAssetGroup = async (assetGroupIds: string[]) => {
    Utility.cancelRequest(MachineMasterService.abortController);

    let params = this.getParams();
    const url =
      ApiConstants.URL.MRP.MACHINE_TOOLS_MASTER.GET_MACHINE_BY_ASSET_GROUP +
      `?${params.toString()}`;
    MachineMasterService.abortController = Utility.createAbortController();

    try {
      const response = await http.post(url, assetGroupIds, {
        signal: MachineMasterService.abortController.signal
      });
      return Promise.resolve(response);
    } catch (error: any) {
      if (error?.message === 'canceled') {
        return Promise.reject(error);
      }
      showAlert('Error', error?.message);
      console.error('Unable to Load Asset Groups: ', error);
      return Promise.reject(error);
    }
  };
}
