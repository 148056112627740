import { DKButton, DKIcons } from 'deskera-ui-library';
import { useRef, useState } from 'react';
import { deepClone } from '../../Utility/Utility';
import LabelCellView from './LabelCellView';
import { LabelCell, LabelRow } from './LabelTemplateHelper';

interface LabelRowViewProps {
  data: LabelRow;
  rowIndex: number;
  updateRow?: Function;
  cellToEdit?: LabelCell;
  setCellToEdit?: Function;
  deleteRow?: Function;
  labelSettings: any;
  isReadOnly: boolean;
}

const LabelRowView: React.FC<LabelRowViewProps> = ({
  data,
  rowIndex,
  updateRow,
  cellToEdit,
  setCellToEdit,
  deleteRow,
  labelSettings,
  isReadOnly
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);
  const deleteCell = (cellIndex: number) => {
    let updatedRow = { ...data };
    updatedRow.columns.splice(cellIndex, 1);
    updateRow && updateRow(rowIndex, updatedRow);
  };

  const dragStart = (position: number) => {
    dragItem.current = position;
    setIsDragging(true);
  };

  const dragEnter = (position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    if (updateRow) {
      let updatedRow = { ...data };
      const dragItemContent = updatedRow.columns[dragItem.current];
      updatedRow.columns.splice(dragItem.current, 1);
      updatedRow.columns.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      updateRow(updatedRow);
      setIsDragging(false);
    }
  };

  let addCell = () => {
    if (updateRow && setCellToEdit) {
      let newCell: LabelCell = {
        items: [],
        rowIndex: rowIndex,
        colIndex: data?.columns?.length || 0
      };
      let updatedRow = { ...data };
      updatedRow.columns.push(newCell);
      updateRow(updatedRow);
      setCellToEdit(deepClone(newCell));
    }
  };

  return (
    <div
      className={
        'd-flex flex-row parent-width' + (rowIndex !== 0 ? ' border-t' : '')
      }
      style={{
        height: !isNaN(Number(labelSettings.rowHeight))
          ? Number(labelSettings.rowHeight) + 'in'
          : '1in',
        position: 'relative',
        borderColor: '#000b'
      }}
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
    >
      {data?.columns?.map((cell: any, index: number) => (
        <LabelCellView
          key={index}
          data={{ ...cell, rowIndex: rowIndex, colIndex: index }}
          cellIndex={index}
          deleteCell={deleteCell}
          cellToEdit={cellToEdit}
          setCellToEdit={setCellToEdit}
          dragStart={dragStart}
          dragEnter={dragEnter}
          isDragging={isDragging}
          drop={drop}
          labelSettings={labelSettings}
          isReadOnly={isReadOnly}
        />
      ))}
      {isHover && !cellToEdit && !isReadOnly && deleteRow && (
        <div style={{ position: 'absolute', top: 0, right: -110, zIndex: 10 }}>
          <DKButton icon={DKIcons.ic_add} title="Add Cell" onClick={addCell} />
          <DKButton
            icon={DKIcons.ic_delete}
            title="Delete Row"
            onClick={() => deleteRow(rowIndex)}
          />
        </div>
      )}
      {!isReadOnly && (
        <div
          style={{
            position: 'absolute',
            width: '100px',
            right: '-100px',
            height: '100%'
          }}
        />
      )}
    </div>
  );
};

export default LabelRowView;
