import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import PIQService from '../../Services/PurchaseInwardQuotation';
import { API_STATUS } from '../../Constants/Constant';
import { RootState } from '../../Redux/Store';

export const PIQ_FORM_KEYS = {};

export interface IPIQItems {}

export interface IADDPIQInitialState {}

export const ADD_REQUEST_FOR_QUOTATION_INITIAL_STATE: IADDPIQInitialState = {};

const initialState = {
  piqFormDocument: ADD_REQUEST_FOR_QUOTATION_INITIAL_STATE,
  allPIQData: {},
  status: API_STATUS.IDLE
};

export type rfqSliceStateKeys = keyof IADDPIQInitialState;

const selfSelector = (rootState: RootState) => rootState.piqFormData;

const updateStateKey = <
  KEY extends rfqSliceStateKeys,
  VALUE extends IADDPIQInitialState[KEY]
>(
  obj: IADDPIQInitialState,
  key: KEY,
  value: VALUE
) => {
  obj[key] = value;
};

export const fetchAllPurchaseInwardQuotation = createAsyncThunk(
  'fetchPIQs',
  async () => {
    const response = await PIQService.fetchAllPIQs();
    return response;
  }
);

export const PIQSlice = createSlice({
  name: 'managePIQForm',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPurchaseInwardQuotation.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllPurchaseInwardQuotation.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.allPIQData = action.payload;
      });
  }
});

export const {} = PIQSlice.actions;

export const selectPIQFormDataByKeys = (dataKey: any) =>
  createSelector(selfSelector, (state: any) => {
    if (Array.isArray(dataKey)) {
      return state?.piqFormDocument
        ? dataKey.map((dataKey: any) => state.piqFormDocument[dataKey])
        : [];
    } else {
      return state?.piqFormDocument?.[dataKey];
    }
  });

export const selectedPIQAllData = (state: any) => {
  return state.piqFormData.allPIQData;
};
