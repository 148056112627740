import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DBTableManager from '../../Components/ReportBuilder/DBTableManager';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import CustomReportsService from '../../Services/CustomReports';
import CustomSalesReportsService from '../../Services/CustomSalesReports';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchTablesData = createAsyncThunk('tablesData', async () => {
  const response = await CustomReportsService.getTablesData();
  return response;
});

export const fetchCustomReports = createAsyncThunk(
  'customReports',
  async () => {
    const response = await CustomReportsService.fetchReportData();
    return response;
  }
);
export const fetchProntoData = createAsyncThunk(
  'fetchProntoData',
  async (payload: any) => {
    const response = await CustomSalesReportsService.fetchProntoData(payload);
    return response;
  }
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const CustomReportsSlice = createSlice({
  name: 'CustomReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTablesData.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTablesData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let tablesData: any = action.payload;
        state.data = tablesData;
        DBTableManager.setAllDBTableData(tablesData);
      })
      .addCase(fetchCustomReports.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCustomReports.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let tablesData: any = action.payload;
        state.reportsList = tablesData;
      })
      .addCase(fetchProntoData.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProntoData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let tablesData: any = action.payload;
        state.prontoReportList = tablesData;
      });
  }
});

export const {} = CustomReportsSlice.actions;
export const reportsTablesData = (state: RootState) => state.customReports.data;
export const customReportsList = (state: RootState) =>
  state.customReports.reportsList;
export const prontoList = (state: RootState) =>
  state.customReports.prontoReportList;
export default CustomReportsSlice.reducer;
