export class TableOption {
    isShowTable?: boolean
    isShowHeader?: boolean
    isUpperCase?: boolean
    isAllowSorting?: boolean
    isShowFilter?: boolean
    isShowSelection?: boolean
    isShowFooter?: boolean
    isUseHeaderStyle?: boolean
    isShowBorder?: boolean
    isShowSortButton?: boolean
    isReceiptFooter?: boolean

    constructor() {
        this.isShowTable = true
        this.isShowHeader = true
        this.isUpperCase = false
        this.isAllowSorting = false
        this.isShowFilter = false
        this.isShowSelection = false
        this.isShowFooter = false
        this.isShowBorder = false
        this.isShowSortButton = true
        this.isReceiptFooter = false
    }
}