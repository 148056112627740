/**
 * @description - Source (2021) : United Nations (list of UN member states and permanent observers plus Kosovo and Taiwan)
 */
export const COUNTRIES_WITH_CURRENCIES = [
  {
    country: 'Afghanistan',
    currencyName: 'Afghan afghani',
    currencyCode: 'AFN',
    countryCode: 'AF'
  },
  {
    country: 'Albania',
    currencyName: 'Albanian lek',
    currencyCode: 'ALL',
    countryCode: 'AL'
  },
  {
    country: 'Algeria',
    currencyName: 'Algerian dinar',
    currencyCode: 'DZD',
    countryCode: 'DZ'
  },
  {
    country: 'Andorra',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Angola',
    currencyName: 'Angolan kwanza',
    currencyCode: 'AOA',
    countryCode: 'AO'
  },
  {
    country: 'Antigua and Barbuda',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Argentina',
    currencyName: 'Argentine peso',
    currencyCode: 'ARS',
    countryCode: 'AR'
  },
  {
    country: 'Armenia',
    currencyName: 'Armenian dram',
    currencyCode: 'AMD',
    countryCode: 'AM'
  },
  {
    country: 'Australia',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Austria',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Azerbaijan',
    currencyName: 'Azerbaijani manat',
    currencyCode: 'AZN',
    countryCode: 'AZ'
  },
  {
    country: 'Bahamas',
    currencyName: 'Bahamian dollar',
    currencyCode: 'BSD',
    countryCode: 'BS'
  },
  {
    country: 'Bahrain',
    currencyName: 'Bahraini dinar',
    currencyCode: 'BHD',
    countryCode: 'BH'
  },
  {
    country: 'Bangladesh',
    currencyName: 'Bangladeshi taka',
    currencyCode: 'BDT',
    countryCode: 'BD'
  },
  {
    country: 'Barbados',
    currencyName: 'Barbadian dollar',
    currencyCode: 'BBD',
    countryCode: 'BB'
  },
  {
    country: 'Belgium',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Belize',
    currencyName: 'Belize dollar',
    currencyCode: 'BZD',
    countryCode: 'BZ'
  },
  {
    country: 'Benin',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Bhutan',
    currencyName: 'Bhutanese ngultrum',
    currencyCode: 'BTN',
    countryCode: 'BT'
  },
  {
    country: 'Bolivia',
    currencyName: 'Bolivian boliviano',
    currencyCode: 'BOB',
    countryCode: 'BO'
  },
  {
    country: 'Bosnia and Herzegovina',
    currencyName: 'Bosnia and Herzegovina convertible mark',
    currencyCode: 'BAM',
    countryCode: 'BA'
  },
  {
    country: 'Botswana',
    currencyName: 'Botswana pula',
    currencyCode: 'BWP',
    countryCode: 'BW'
  },
  {
    country: 'Brazil',
    currencyName: 'Brazilian real',
    currencyCode: 'BRL',
    countryCode: 'BR'
  },
  {
    country: 'Brunei',
    currencyName: 'Brunei dollar',
    currencyCode: 'BND',
    countryCode: 'BN'
  },
  {
    country: 'Bulgaria',
    currencyName: 'Bulgarian lev',
    currencyCode: 'BGN',
    countryCode: 'BG'
  },
  {
    country: 'Burkina Faso',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Burundi',
    currencyName: 'Burundian franc',
    currencyCode: 'BIF',
    countryCode: 'BI'
  },
  {
    country: 'Cambodia',
    currencyName: 'Cambodian riel',
    currencyCode: 'KHR',
    countryCode: 'KH'
  },
  {
    country: 'Cameroon',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Canada',
    currencyName: 'Canadian dollar',
    currencyCode: 'CAD',
    countryCode: 'CA'
  },
  {
    country: 'Cape Verde',
    currencyName: 'Cape Verdean escudo',
    currencyCode: 'CVE',
    countryCode: 'CV'
  },
  {
    country: 'Central African Republic',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Chad',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Chile',
    currencyName: 'Chilean peso',
    currencyCode: 'CLP',
    countryCode: 'CL'
  },
  {
    country: 'China',
    currencyName: 'Chinese yuan',
    currencyCode: 'CNY',
    countryCode: 'CN'
  },
  {
    country: 'Comoros',
    currencyName: 'Comorian franc',
    currencyCode: 'KMF',
    countryCode: 'KM'
  },
  {
    country: 'Costa Rica',
    currencyName: 'Costa Rican colón',
    currencyCode: 'CRC',
    countryCode: 'CR'
  },
  {
    country: 'Croatia',
    currencyName: 'Croatian kuna',
    currencyCode: 'HRK',
    countryCode: 'HR'
  },
  {
    country: 'Cyprus',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Czech Republic',
    currencyName: 'Czech koruna',
    currencyCode: 'CZK',
    countryCode: 'CZ'
  },
  {
    country: 'Democratic Republic of Congo',
    currencyName: 'Congolese franc',
    currencyCode: 'CDF',
    countryCode: 'CD'
  },
  {
    country: 'Denmark',
    currencyName: 'Danish krone',
    currencyCode: 'DKK',
    countryCode: 'DK'
  },
  {
    country: 'Djibouti',
    currencyName: 'Djiboutian franc',
    currencyCode: 'DJF',
    countryCode: 'DJ'
  },
  {
    country: 'Dominica',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Dominican Republic',
    currencyName: 'Dominican peso',
    currencyCode: 'DOP',
    countryCode: 'DO'
  },
  {
    country: 'East Timor',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'TLS'
  },
  {
    country: 'Ecuador',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'EC'
  },
  {
    country: 'Egypt',
    currencyName: 'Egyptian pound',
    currencyCode: 'EGP',
    countryCode: 'EG'
  },
  {
    country: 'El Salvador',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'SLV'
  },
  {
    country: 'Equatorial Guinea',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Eritrea',
    currencyName: 'Eritrean nakfa',
    currencyCode: 'ERN',
    countryCode: 'ER'
  },
  {
    country: 'Estonia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Eswatini',
    currencyName: 'Swazi lilangeni',
    currencyCode: 'SZL',
    countryCode: 'SZ'
  },
  {
    country: 'Ethiopia',
    currencyName: 'Ethiopian birr',
    currencyCode: 'ETB',
    countryCode: 'ET'
  },
  {
    country: 'Fiji',
    currencyName: 'Fijian dollar',
    currencyCode: 'FJD',
    countryCode: 'FJ'
  },
  {
    country: 'Finland',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'France',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Gabon',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Gambia',
    currencyName: 'Gambian dalasi',
    currencyCode: 'GMD',
    countryCode: 'GM'
  },
  {
    country: 'Georgia',
    currencyName: 'Georgian lari',
    currencyCode: 'GEL',
    countryCode: 'GE'
  },
  {
    country: 'Germany',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Ghana',
    currencyName: 'Ghanaian cedi',
    currencyCode: 'GHS',
    countryCode: 'GH'
  },
  {
    country: 'Greece',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Grenada',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Guatemala',
    currencyName: 'Guatemalan quetzal',
    currencyCode: 'GTQ',
    countryCode: 'GT'
  },
  {
    country: 'Guinea',
    currencyName: 'Guinean franc',
    currencyCode: 'GNF',
    countryCode: 'GN'
  },
  {
    country: 'Guinea-Bissau',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Guyana',
    currencyName: 'Guyanese dollar',
    currencyCode: 'GYD',
    countryCode: 'GY'
  },
  {
    country: 'Haiti',
    currencyName: 'Haitian gourde',
    currencyCode: 'HTG',
    countryCode: 'HT'
  },
  {
    country: 'Honduras',
    currencyName: 'Honduran lempira',
    currencyCode: 'HNL',
    countryCode: 'HN'
  },
  {
    country: 'Hungary',
    currencyName: 'Hungarian forint',
    currencyCode: 'HUF',
    countryCode: 'HU'
  },
  {
    country: 'Iceland',
    currencyName: 'Icelandic króna',
    currencyCode: 'ISK',
    countryCode: 'IS'
  },
  {
    country: 'India',
    currencyName: 'Indian rupee',
    currencyCode: 'INR',
    countryCode: 'IN'
  },
  {
    country: 'Indonesia',
    currencyName: 'Indonesian rupiah',
    currencyCode: 'IDR',
    countryCode: 'ID'
  },
  {
    country: 'Iran',
    currencyName: 'Iranian rial',
    currencyCode: 'IRR',
    countryCode: 'IR'
  },
  {
    country: 'Iraq',
    currencyName: 'Iraqi dinar',
    currencyCode: 'IQD',
    countryCode: 'IQ'
  },
  {
    country: 'Ireland',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Israel',
    currencyName: 'Israeli new shekel',
    currencyCode: 'ILS',
    countryCode: 'IL'
  },
  {
    country: 'Italy',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Ivory Coast',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Jamaica',
    currencyName: 'Jamaican dollar',
    currencyCode: 'JMD',
    countryCode: 'JM'
  },
  {
    country: 'Japan',
    currencyName: 'Japanese yen',
    currencyCode: 'JPY',
    countryCode: 'JP'
  },
  {
    country: 'Jordan',
    currencyName: 'Jordanian dinar',
    currencyCode: 'JOD',
    countryCode: 'JO'
  },
  {
    country: 'Kazakhstan',
    currencyName: 'Kazakhstani tenge',
    currencyCode: 'KZT',
    countryCode: 'KZ'
  },
  {
    country: 'Kenya',
    currencyName: 'Kenyan shilling',
    currencyCode: 'KES',
    countryCode: 'KE'
  },
  {
    country: 'Kiribati',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Korea, North',
    currencyName: 'North Korean won',
    currencyCode: 'KPW',
    countryCode: 'KP'
  },
  {
    country: 'Korea, South',
    currencyName: 'South Korean won',
    currencyCode: 'KRW',
    countryCode: 'KR'
  },
  {
    country: 'Kosovo',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Kuwait',
    currencyName: 'Kuwaiti dinar',
    currencyCode: 'KWD',
    countryCode: 'KW'
  },
  {
    country: 'Kyrgyzstan',
    currencyName: 'Kyrgyzstani som',
    currencyCode: 'KGS',
    countryCode: 'KG'
  },
  {
    country: 'Laos',
    currencyName: 'Lao kip',
    currencyCode: 'LAK',
    countryCode: 'LA'
  },
  {
    country: 'Latvia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Lebanon',
    currencyName: 'Lebanese pound',
    currencyCode: 'LBP',
    countryCode: 'LB'
  },
  {
    country: 'Lesotho',
    currencyName: 'Lesotho loti',
    currencyCode: 'LSL',
    countryCode: 'LS'
  },
  {
    country: 'Liberia',
    currencyName: 'Liberian dollar',
    currencyCode: 'LRD',
    countryCode: 'LR'
  },
  {
    country: 'Libya',
    currencyName: 'Libyan dinar',
    currencyCode: 'LYD',
    countryCode: 'LY'
  },
  {
    country: 'Liechtenstein',
    currencyName: 'Swiss franc',
    currencyCode: 'CHF',
    countryCode: 'LI'
  },
  {
    country: 'Lithuania',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Luxembourg',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Madagascar',
    currencyName: 'Malagasy ariary',
    currencyCode: 'MGA',
    countryCode: 'MG'
  },
  {
    country: 'Malawi',
    currencyName: 'Malawian kwacha',
    currencyCode: 'MWK',
    countryCode: 'MW'
  },
  {
    country: 'Malaysia',
    currencyName: 'Malaysian ringgit',
    currencyCode: 'MYR',
    countryCode: 'MY'
  },
  {
    country: 'Maldives',
    currencyName: 'Maldivian rufiyaa',
    currencyCode: 'MVR',
    countryCode: 'MV'
  },
  {
    country: 'Mali',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Malta',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Marshall Islands',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'MHL'
  },
  {
    country: 'Mauritania',
    currencyName: 'Mauritanian ouguiya',
    currencyCode: 'MRO',
    countryCode: 'MR'
  },
  {
    country: 'Mauritius',
    currencyName: 'Mauritian rupee',
    currencyCode: 'MUR',
    countryCode: 'MU'
  },
  {
    country: 'Mexico',
    currencyName: 'Mexican peso',
    currencyCode: 'MXN',
    countryCode: 'MX'
  },
  {
    country: 'Micronesia',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'FSM'
  },
  {
    country: 'Moldova',
    currencyName: 'Moldovan leu',
    currencyCode: 'MDL',
    countryCode: 'MD'
  },
  {
    country: 'Monaco',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Mongolia',
    currencyName: 'Mongolian tögrög',
    currencyCode: 'MNT',
    countryCode: 'MN'
  },
  {
    country: 'Montenegro',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Morocco',
    currencyName: 'Moroccan dirham',
    currencyCode: 'MAD',
    countryCode: 'MA'
  },
  {
    country: 'Mozambique',
    currencyName: 'Mozambican metical',
    currencyCode: 'MZN',
    countryCode: 'MZ'
  },
  {
    country: 'Myanmar',
    currencyName: 'Burmese kyat',
    currencyCode: 'MMK',
    countryCode: 'MM'
  },
  {
    country: 'Namibia',
    currencyName: 'Namibian dollar',
    currencyCode: 'NAD',
    countryCode: 'NA'
  },
  {
    country: 'Nauru',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Nepal',
    currencyName: 'Nepalese rupee',
    currencyCode: 'NPR',
    countryCode: 'NP'
  },
  {
    country: 'Netherlands',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'New Zealand',
    currencyName: 'New Zealand dollar',
    currencyCode: 'NZD',
    countryCode: 'CK'
  },
  {
    country: 'Nicaragua',
    currencyName: 'Nicaraguan córdoba',
    currencyCode: 'NIO',
    countryCode: 'NI'
  },
  {
    country: 'Niger',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Nigeria',
    currencyName: 'Nigerian naira',
    currencyCode: 'NGN',
    countryCode: 'NG'
  },
  {
    country: 'North Macedonia',
    currencyName: 'Macedonian denar',
    currencyCode: 'MKD',
    countryCode: 'MK'
  },
  {
    country: 'Norway',
    currencyName: 'Norwegian krone',
    currencyCode: 'NOK',
    countryCode: 'BV'
  },
  {
    country: 'Oman',
    currencyName: 'Omani rial',
    currencyCode: 'OMR',
    countryCode: 'OM'
  },
  {
    country: 'Pakistan',
    currencyName: 'Pakistani rupee',
    currencyCode: 'PKR',
    countryCode: 'PK'
  },
  {
    country: 'Palau',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'PLW'
  },
  {
    country: 'Palestine',
    currencyName: 'Israeli new shekel',
    currencyCode: 'ILS',
    countryCode: 'IL'
  },
  {
    country: 'Panama',
    currencyName: 'Panamanian balboa',
    currencyCode: 'PAB',
    countryCode: 'PA'
  },
  {
    country: 'Papua New Guinea',
    currencyName: 'Papua New Guinean kina',
    currencyCode: 'PGK',
    countryCode: 'PG'
  },
  {
    country: 'Paraguay',
    currencyName: 'Paraguayan guaraní',
    currencyCode: 'PYG',
    countryCode: 'PY'
  },
  {
    country: 'Peru',
    currencyName: 'Peruvian sol',
    currencyCode: 'PEN',
    countryCode: 'PE'
  },
  {
    country: 'Philippines',
    currencyName: 'Philippine peso',
    currencyCode: 'PHP',
    countryCode: 'PH'
  },
  {
    country: 'Poland',
    currencyName: 'Polish zloty',
    currencyCode: 'PLN',
    countryCode: 'PL'
  },
  {
    country: 'Portugal',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Qatar',
    currencyName: 'Qatari riyal',
    currencyCode: 'QAR',
    countryCode: 'QA'
  },
  {
    country: 'Republic of the Congo',
    currencyName: 'Central African CFA franc',
    currencyCode: 'XAF',
    countryCode: 'CM'
  },
  {
    country: 'Romania',
    currencyName: 'Romanian leu',
    currencyCode: 'RON',
    countryCode: 'RO'
  },
  {
    country: 'Russia',
    currencyName: 'Russian ruble',
    currencyCode: 'RUB',
    countryCode: 'RU'
  },
  {
    country: 'Rwanda',
    currencyName: 'Rwandan franc',
    currencyCode: 'RWF',
    countryCode: 'RW'
  },
  {
    country: 'Saint Kitts and Nevis',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Saint Lucia',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    currencyName: 'East Caribbean dollar',
    currencyCode: 'XCD',
    countryCode: 'AI'
  },
  {
    country: 'Samoa',
    currencyName: 'Samoan tala',
    currencyCode: 'WST',
    countryCode: 'WS'
  },
  {
    country: 'San Marino',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'São Tomé and Príncipe',
    currencyName: 'São Tomé and Príncipe dobra',
    currencyCode: 'STD',
    countryCode: 'ST'
  },
  {
    country: 'Saudi Arabia',
    currencyName: 'Saudi riyal',
    currencyCode: 'SAR',
    countryCode: 'SA'
  },
  {
    country: 'Senegal',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Serbia',
    currencyName: 'Serbian dinar',
    currencyCode: 'RSD',
    countryCode: 'RS'
  },
  {
    country: 'Seychelles',
    currencyName: 'Seychellois rupee',
    currencyCode: 'SCR',
    countryCode: 'SC'
  },
  {
    country: 'Sierra Leone',
    currencyName: 'Sierra Leonean leone',
    currencyCode: 'SLL',
    countryCode: 'SL'
  },
  {
    country: 'Singapore',
    currencyName: 'Singapore dollar',
    currencyCode: 'SGD',
    countryCode: 'SG'
  },
  {
    country: 'Slovakia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Slovenia',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Solomon Islands',
    currencyName: 'Solomon Islands dollar',
    currencyCode: 'SBD',
    countryCode: 'SB'
  },
  {
    country: 'Somalia',
    currencyName: 'Somali shilling',
    currencyCode: 'SOS',
    countryCode: 'SO'
  },
  {
    country: 'South Africa',
    currencyName: 'South African rand',
    currencyCode: 'ZAR',
    countryCode: 'ZA'
  },
  {
    country: 'South Sudan',
    currencyName: 'South Sudanese pound',
    currencyCode: 'SSP',
    countryCode: 'SS'
  },
  {
    country: 'Spain',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Sri Lanka',
    currencyName: 'Sri Lankan rupee',
    currencyCode: 'LKR',
    countryCode: 'LK'
  },
  {
    country: 'Sudan',
    currencyName: 'Sudanese pound',
    currencyCode: 'SDG',
    countryCode: 'SD'
  },
  {
    country: 'Suriname',
    currencyName: 'Surinamese dollar',
    currencyCode: 'SRD',
    countryCode: 'SR'
  },
  {
    country: 'Sweden',
    currencyName: 'Swedish krona',
    currencyCode: 'SEK',
    countryCode: 'SE'
  },
  {
    country: 'Switzerland',
    currencyName: 'Swiss franc',
    currencyCode: 'CHF',
    countryCode: 'LI'
  },
  {
    country: 'Syria',
    currencyName: 'Syrian pound',
    currencyCode: 'SYP',
    countryCode: 'SY'
  },
  {
    country: 'Taiwan',
    currencyName: 'New Taiwan dollar',
    currencyCode: 'TWD',
    countryCode: 'TW'
  },
  {
    country: 'Tajikistan',
    currencyName: 'Tajikistani somoni',
    currencyCode: 'TJS',
    countryCode: 'TJ'
  },
  {
    country: 'Tanzania',
    currencyName: 'Tanzanian shilling',
    currencyCode: 'TZS',
    countryCode: 'TZ'
  },
  {
    country: 'Thailand',
    currencyName: 'Thai baht',
    currencyCode: 'THB',
    countryCode: 'TH'
  },
  {
    country: 'Togo',
    currencyName: 'West African CFA franc',
    currencyCode: 'XOF',
    countryCode: 'BJ'
  },
  {
    country: 'Tonga',
    currencyName: "Tongan pa'anga",
    currencyCode: 'TOP',
    countryCode: 'TO'
  },
  {
    country: 'Trinidad and Tobago',
    currencyName: 'Trinidad and Tobago dollar',
    currencyCode: 'TTD',
    countryCode: 'TT'
  },
  {
    country: 'Tunisia',
    currencyName: 'Tunisian dinar',
    currencyCode: 'TND',
    countryCode: 'TN'
  },
  {
    country: 'Turkey',
    currencyName: 'Turkish lira',
    currencyCode: 'TRY',
    countryCode: 'TR'
  },
  {
    country: 'Turkmenistan',
    currencyName: 'Turkmenistan manat',
    currencyCode: 'TMT',
    countryCode: 'TM'
  },
  {
    country: 'Tuvalu',
    currencyName: 'Australian dollar',
    currencyCode: 'AUD',
    countryCode: 'AQ'
  },
  {
    country: 'Uganda',
    currencyName: 'Ugandan shilling',
    currencyCode: 'UGX',
    countryCode: 'UG'
  },
  {
    country: 'Ukraine',
    currencyName: 'Ukrainian hryvnia',
    currencyCode: 'UAH',
    countryCode: 'UA'
  },
  {
    country: 'United Arab Emirates',
    currencyName: 'United Arab Emirates dirham',
    currencyCode: 'AED',
    countryCode: 'AE'
  },
  {
    country: 'United Kingdom',
    currencyName: 'British pound',
    currencyCode: 'GBP',
    countryCode: 'GG'
  }, 
  {
    country: 'United Kingdom of Great Britain and Northern Ireland',
    currencyName: 'British pound',
    currencyCode: 'GBP',
    countryCode: 'GB'
  },
  {
    country: 'United States of America',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'US'
  },
  {
    country: 'Uruguay',
    currencyName: 'Uruguayan peso',
    currencyCode: 'UYU',
    countryCode: 'UY'
  },
  {
    country: 'Uzbekistan',
    currencyName: 'Uzbekistani som',
    currencyCode: 'UZS',
    countryCode: 'UZ'
  },
  {
    country: 'Vanuatu',
    currencyName: 'Vanuatu vatu',
    currencyCode: 'VUV',
    countryCode: 'VU'
  },
  {
    country: 'Vatican City',
    currencyName: 'Euro',
    currencyCode: 'EUR',
    countryCode: 'AX'
  },
  {
    country: 'Venezuela',
    currencyName: 'Venezuelan bolívar',
    currencyCode: 'VEF',
    countryCode: 'VE'
  },
  {
    country: 'Vietnam',
    currencyName: 'Vietnamese dong',
    currencyCode: 'VND',
    countryCode: 'VN'
  },
  {
    country: 'Yemen',
    currencyName: 'Yemeni rial',
    currencyCode: 'YER',
    countryCode: 'YE'
  },
  {
    country: 'Zambia',
    currencyName: 'Zambian kwacha',
    currencyCode: 'ZMW',
    countryCode: 'ZM'
  },
  {
    country: 'Zimbabwe',
    currencyName: 'United States dollar',
    currencyCode: 'USD',
    countryCode: 'ZWE'
  }
];
