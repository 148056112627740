import { TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import { getSortedHeader } from "../TableUtility";

export class FulfillmentTableItem {
    code: string;
    name: string;
    description: string;
    uom: string;
    requiredQuantity: string;
    committedQuantity: string;
    serialBatch: string;
    serialNumber: string;
    batchNumber: string;
    manufacturingDate: string;
    expiryDate: string;
    warehouseCode: string;
    warehouseName: string;
    productCustomField: string;
    currency: string;
    rawItemValue: any;
    data: any
    constructor(fulfillmentItem: any, data:any, currency: string) {
        this.code = fulfillmentItem.code ? fulfillmentItem.code : ''
        this.name = this.getProductName(data, fulfillmentItem)
        this.description = fulfillmentItem.description
        this.uom = fulfillmentItem.uom
        this.productCustomField = ''
        this.requiredQuantity = fulfillmentItem.requiredQuantity ? fulfillmentItem.requiredQuantity.toString(): '0'
        this.committedQuantity = fulfillmentItem.committedQuantity ? fulfillmentItem.committedQuantity.toString() : '0'
        this.warehouseCode = fulfillmentItem.warehouseCode
        this.warehouseName = fulfillmentItem.warehouseName
        this.serialBatch = fulfillmentItem.serialBatch
        this.serialNumber = fulfillmentItem.serialNumber ? fulfillmentItem.serialNumber : ''
        this.batchNumber = fulfillmentItem.batchNumber ? fulfillmentItem.batchNumber : ''
        this.manufacturingDate = fulfillmentItem.manufacturingDate ? fulfillmentItem.manufacturingDate : ''
        this.expiryDate = fulfillmentItem.expiryDate ? fulfillmentItem.expiryDate : ''
        this.currency = currency
        this.rawItemValue = fulfillmentItem
        this.data = data
    }
    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    val += element.label + ': <b>' + field.value + '</b>\n'
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            val += element.label + ': <b>' + field.value + '</b>\n'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }
    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : ''
            case TableColumnPopupType.serialNumber:
                return this.serialNumber ? this.serialNumber : ''
            case TableColumnPopupType.batchNumber:
                return this.batchNumber ? this.batchNumber : ''
            case TableColumnPopupType.manufacturingDate:
                return this.manufacturingDate ? this.manufacturingDate : ''
            case TableColumnPopupType.expiryDate:
                return this.expiryDate ? this.expiryDate : ''
            case TableColumnPopupType.requiredQuantity:
                return this.requiredQuantity ? this.requiredQuantity : ''
            case TableColumnPopupType.committedQuantity:
                return this.committedQuantity ? this.committedQuantity : ''
            case TableColumnPopupType.warehouseCode:
                return this.warehouseCode ? this.warehouseCode : ''
            case TableColumnPopupType.warehouseName:
                return this.warehouseName ? this.warehouseName : ''
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            case TableColumnPopupType.serialBatchCustomFields:
                return CustomFieldManager.getCombineSerialBatchCustomField(this.rawItemValue, this.data);
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            this.uom,
            this.serialBatch,
            this.requiredQuantity,
            this.committedQuantity,
            this.warehouseCode,
            this.warehouseName
        ]
    }
}
