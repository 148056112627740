import { ReturnedItem } from './SalesReturnedItem';

export class Returned {
  id: number;
  contactCode: string;
  contactInfo: any;
  currency: string;
  exchangeRate: number;
  linkedDocuments: any;
  quantity: string;
  returnType: string;
  salesReturnCode?: string;
  salesReturnDate: string;
  salesReturnItems: ReturnedItem[];
  warehouseCode: string;
  rowCode: string;
  rackCode: string;
  binCode: string;
  reasonToReturn: string;
  fulfillmentType: string;
  customField?: any[];

  constructor(props: any) {
    this.id = props.id;
    this.returnType = props.returnType;
    this.warehouseCode = props.warehouseCode;
    this.rowCode = props.rowCode;
    this.rackCode = props.rackCode;
    this.binCode = props.binCode;
    this.contactCode = props.contactCode;
    this.contactInfo = props.contactInfo;
    this.currency = props.currency;
    this.exchangeRate = props.exchangeRate;
    this.salesReturnCode = props.salesReturnCode;
    this.salesReturnDate = props.salesReturnDate;
    this.linkedDocuments = props.linkedDocuments;
    this.quantity = props.quantity;
    this.fulfillmentType = props.fulfillmentType;
    this.reasonToReturn = props.reasonToReturn;
    if (props.salesReturnItems) {
      this.salesReturnItems = props.salesReturnItems.map((item: any) => {
        return {
          quantityToReturn: Number(item.quantityToReturn),
          productCode: item.productCode,
          productOrder: item.productOrder,
          quantityFulfilled: item.quantityFulfilled,
          warehouseCode: item.warehouseCode || this.warehouseCode,
          salesReturnDate: item.salesReturnDate || this.salesReturnDate,
          advancedTrackingMetaData: item.advancedTrackingMetaData || [],
          productName: item.productName,
          advancedTrackingType: item.advancedTrackingType,
          documentUOMSchemaDefinition: item.documentUOMSchemaDefinition,
          documentUom: item.documentUom,
          uomQuantityToReturn:
            item.uomQuantityToReturn || item.quantityToReturn || 0,
          uomQuantityFulfilled:
            item.uomQuantityFulfilled || item.quantityFulfilled || 0,
          rowCode: item?.rowCode,
          rackCode: item?.rackCode,
          binCode: item?.binCode,
          productType: item?.productType,
          customField: item?.customField ?? []
        };
      });
    } else {
      this.salesReturnItems = [];
    }
    this.customField = props?.customField;
  }
}
