export enum ComponentType {
    DKLabel = 'DKLabel',
    DKImage = 'DKImage',
    DKBanner = 'DKBanner',
}
export enum Action {
    Image = 'Image',
    Text = 'Text',
    Signature = 'Signature',
    Watermark = 'Watermark',
    Banner = 'Banner',
    System_Field = 'Global Custom Fields',
}

export enum ImageCompression {
    "NONE",
    "FAST",
    "MEDIUM",
    "SLOW"
}

export enum Zoom {
    "IN",
    "OUT",
}

export enum ImageResize {
    "INCREASE",
    "DECREASE",
}

export enum ScreenState {
    "VIEW",
    "EDIT",
    "CREATE"
}

export enum ShareAction {
    SAVE_AS_PDF = 'Save as PDF',
    SAVE_AS_EXCEL = 'Save as xls',
    SAVE_AS_WORD = 'Save as word',
    SAVE_IMAGE = 'Save as Image',
    PRINT = 'Print',
}
