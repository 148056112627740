import {
  DKCalendar,
  DKIcon,
  DKInput,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { get, isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  FULFILLMENT_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import {
  RETURN_MODE,
  RETURN_QUANTITY_TYPE,
  RETURN_TYPE
} from '../../Constants/Enum';
import AppManager from '../../Managers/AppManager';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { Returned } from '../../Models/SalesReturned';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchBatchTrackingProducts } from '../../Redux/Slices/BatchTrackingSlice';
import { selectUOMs } from '../../Redux/Slices/CommonDataSlice';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import {
  fetchWarehouseWithRRBCombination,
  selectedWarehouseWithRRBCombination
} from '../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../Services/DateFormat';
import FulfillmentService from '../../Services/FulfillmentService';
import NumberFormatService from '../../Services/NumberFormat';
import PickPackShipService from '../../Services/PickPackShip';
import QuotationService from '../../Services/Quotation';
import SalesOrderService from '../../Services/SalesOrder';
import SalesReturnService from '../../Services/SalesReturn';
import { AlertUtility } from '../../Utility/AlertUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized
} from '../../Utility/Utility';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import { ReturnsReasonDropDown } from '../ReturnsReasonDropDown/ReturnsReasonDropDown';
import ReturnWarehouseManagementRRB from '../WarehouseManagement/ReturnWarehouseManagementRRB';
import WarehouseManagementHelper from '../WarehouseManagement/WarehouseManagementHelper';
import SalesReturnBatch from './SalesReturnBatchProduct';
import SalesReturnSerial from './SalesReturnSerialProduct';
import { DocumentUOMSchemaDefinition } from '../../Models/SecurityGateEntry';
export interface SalesReturnProps {
  documentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType, data?: any) => void;
  documentType: DOC_TYPE;
  fulfillmentType?: FULFILLMENT_TYPE;
  openDraftId?: any;
  closePopup?: () => void;
  assignSerialTracking?: (data: any) => void;
  assignBatchTracking?: (data: any) => void;
  refreshFulfillmentDetails?: any;
  isClosed: (val: boolean, data: any) => void;
}

const SalesReturnPopup: React.FC<SalesReturnProps> = (props) => {
  const currentDate = new Date();
  const [salesReturnDate, setsalesReturnDate] = useState<Date>(currentDate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const lineItemsTable = useRef(null);
  const dispatch = useAppDispatch();
  const [displayError, setDisplayError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');
  const [documentDetails, setDocumentDetails] = useState(props.documentDetails);
  const [isPartialInvoice, setIsPartialInvoice] = useState<any>(false);
  const [quotationItems, setQuotationItems] = useState<any>([]);
  const [quantityToReturns, setQuantityToReturns] = useState<any>();
  const [quantityToReturnsByWarehouse, setQuantityToReturnsByWarehouse] =
    useState<any>();
  const [hasFulfillment, setHasFulfillment] = useState<any>(false);
  const [returnedItemsData, setReturnedItemsData] = useState<any>([]);
  const [returnedItemsFormArray, setReturnedItemsFormArray] = useState<any>([]);
  const warehousesData = useAppSelector(selectedWarehouseWithRRBCombination);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [defaultReturnWarehouse, setDefaultReturnWarehouse] = useState<any>({});
  const [
    defaultReturnWarehouseRRBDetails,
    setDefaultReturnWarehouseRRBDetails
  ] = useState<any>({});
  const [warehouseAddress, setWarehouseAddress] = useState<any>({});
  const [reason, setReason] = useState<string>('');
  const [salesReturnOptions, setSalesReturnOptions] = useState<string[]>([]);
  const [showBatchTracking, setShowBatchTracking] = useState<boolean>(false);
  const [showSerialTracking, setShowSerialTracking] = useState<boolean>(false);
  const [advanceTrakingData, setAdvanceTrackingData] = useState<any>([]);
  const [fulfillmentData, setFulfillmentData] = useState<any>([]);
  const [fulfillmentDocument, setFulfillmentDocument] = useState<any>([]);
  const [previousLinkedDoc, setPreviousLinkedDoc] = useState<any>([]);
  const [previousReturnDoc, setPreviousReturnDoc] = useState<any>([]);
  const [currentRowData, setCurrentRowData] = useState<any>();
  const [currentRowIndex, setCurrentRowIndex] = useState<any>();
  const [documentType, setDocumentType] = useState<any>(props.documentType);
  const uoms = useAppSelector(selectUOMs);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [selectedWareHouseWithRRB, setSelectedWarehouseWithRRB] = useState<any>(
    {}
  );

  useEffect(() => {
    const whconfig: any = {
      SearchTerm: '',
      Limit: 100,
      Page: 0,
      Query: 'active=true&allRRBDetails=true'
    };
    dispatch(fetchWarehouseWithRRBCombination(whconfig));
    checkIsPartialInvoice();
    calculateReturnedQty();
    getDocumentItems();
    getAdvanceTrackingData();
    getSalesReturnOptions();
  }, []);

  useEffect(() => {
    populateItemsData();
  }, [returnedItemsData, quantityToReturns]);

  useEffect(() => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content?.filter(
        (item: any) => item.active && !item.rejected
      );
    if (!Utility.isEmpty(activeWarehouses)) {
      setWarehouses(activeWarehouses);
    }
    setDefaultActiveWarehouse();

    if (
      Utility.isNotEmpty(activeWarehouses) &&
      Utility.isNotEmpty(fulfillmentData)
    ) {
      let selectedWarehouse = activeWarehouses?.filter(
        (warehouse: any) => warehouse.primary === true
      );
      let warehouseData: any = [];
      fulfillmentData.forEach((record: any) => {
        let data = fulfillmentData?.flatMap((ele: any) =>
          Utility.isEmpty(ele.warehouseInventoryData)
            ? ele.linkedWareHouses
            : ele.warehouseInventoryData
        );
        data = data.filter((n: any) => n);
        if (!Utility.isEmpty(data)) {
          warehouseData.push(...data);
        }
      });
      if (Utility.isNotEmpty(warehouseData)) {
        const whCode = warehouseData[0]['warehouseCode'];
        const rowCode = warehouseData[0]['rowCode'];
        const rackCode = warehouseData[0]['rackCode'];
        const binCode = warehouseData[0]['binCode'];

        let isAllWarehouseSame = warehouseData.every(
          (obj: any) =>
            obj['warehouseCode'] === whCode &&
            obj.rowCode == rowCode &&
            obj.rackCode == rackCode &&
            obj.binCode == binCode
        );
        if (isAllWarehouseSame) {
          selectedWarehouse = activeWarehouses?.filter(
            (warehouse: any) => warehouse.code === whCode
          );
          setDefaultReturnWarehouseRRBDetails({
            rowCode: rowCode,
            rackCode: rackCode,
            binCode: binCode
          });
        }
        setDefaultReturnWarehouse(selectedWarehouse?.[0]);
        setWarehouseAddress(selectedWarehouse?.[0]?.location?.address?.[0]);
      }
    }
  }, [warehousesData, fulfillmentData]);

  useEffect(() => {
    if (
      Utility.isNotEmpty(defaultReturnWarehouse) &&
      Utility.isNotEmpty(defaultReturnWarehouseRRBDetails)
    ) {
      const whconfig: any = {
        SearchTerm: '',
        Limit: 100,
        Page: 0,
        Query: 'active=true&allRRBDetails=true'
      };
      dispatch(fetchWarehouseWithRRBCombination(whconfig));
    }
  }, [defaultReturnWarehouse, defaultReturnWarehouseRRBDetails]);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content?.filter(
        (item: any) => item.active && !item.rejected
      );
    let selectedWarehouse = activeWarehouses?.filter(
      (warehouse: any) => warehouse.primary === true
    );
    setSelectedWarehouse(selectedWarehouse?.[0]);
    setWarehouseAddress(selectedWarehouse?.[0]?.location?.address?.[0]);
  };

  const checkIsPartialInvoice = () => {
    const partialInvoiceFlag =
      get(documentDetails, 'isPartialInvoice') ||
      get(documentDetails, 'hasPartialInvoice') ||
      false;
    setIsPartialInvoice(partialInvoiceFlag);

    if (
      partialInvoiceFlag &&
      get(documentDetails, 'documentType') == DOC_TYPE.INVOICE
    ) {
      getQuotationItemCodes();
    }
  };

  const getQuotationItemCodes = () => {
    const linkedDocuments = get(documentDetails, 'linkedDocuments');

    if (linkedDocuments.length > 0) {
      const docCode = get(linkedDocuments[0], 'documentCode');
      const docType = get(linkedDocuments[0], 'documentType');
      if (docType === DOC_TYPE.QUOTE) {
        QuotationService.getQuoteByCode(docCode).then(
          (doc: any) => {
            setQuotationItems(get(doc, 'quotationItemDtoList'));
          },
          (err) => {
            console.error('Error loading updated doc: ', err);
          }
        );
      } else if (docType === DOC_TYPE.SALES_ORDER) {
        SalesOrderService.getSalesOrderByCode(docCode).then(
          (doc: any) => {
            setQuotationItems(get(doc, 'salesOrderItems'));
          },
          (err) => {
            console.error('Error loading updated doc: ', err);
          }
        );
      }
    }
  };

  const getAdvanceTrackingData = () => {
    SalesReturnService.getAdvanceTrackingReturnData(
      documentDetails.documentCode,
      documentType
    ).then((res: any) => {
      if (res) {
        if (!Utility.isEmpty(res)) {
          setAdvanceTrackingData(res);
        }
      }
    });
  };
  function getSalesReturnOptions() {
    SalesReturnService.getReasons().then((res: any) => {
      if (res) {
        setSalesReturnOptions(res);
      }
    });
  }

  const calculateReturnedQty = () => {
    SalesReturnService.isFullyReturned(
      documentDetails.documentCode,
      documentDetails.documentType
    ).then((res: any) => {
      if (res) {
        let preveiousLinkedDoc = res.flatMap((ele: any) => ele.linkedDocuments);
        setPreviousLinkedDoc(preveiousLinkedDoc ? preveiousLinkedDoc : []);
        setPreviousReturnDoc(res);
        // let data = Utility.filterReturnRecords(
        //   res,
        //   documentDetails.documentType
        // );
        setQuantityToReturns(totalReturnedQty(res));
      }
    });
  };

  const totalReturnedQty = (
    returned: any,
    returnMode: any = RETURN_MODE.SALE
  ) => {
    let totalReturnedQty: any = [];
    let quantityReturnedByWh: any = [];
    if (Array.isArray(returned) && returned.length > 0) {
      returned.forEach((item) => {
        let totalQuantityReturnedByWh: any = [];

        let returned =
          returnMode == RETURN_MODE.SALE
            ? item.salesReturnItems
            : item.purchaseReturnItems;
        returned.map((returnedItem: any) => {
          if (!totalReturnedQty[returnedItem.productCode]) {
            totalReturnedQty[returnedItem.productCode] =
              returnedItem.quantityToReturn;
          } else {
            totalReturnedQty[returnedItem.productCode] +=
              returnedItem.quantityToReturn;
          }
          if (!totalQuantityReturnedByWh[returnedItem.productCode]) {
            totalQuantityReturnedByWh[returnedItem.productCode] =
              returnedItem.quantityToReturn;
          } else {
            totalQuantityReturnedByWh[returnedItem.productCode] +=
              returnedItem.quantityToReturn;
          }
        });
        quantityReturnedByWh[item.warehouseCode] = totalQuantityReturnedByWh;
      });
    }

    setQuantityToReturnsByWarehouse(quantityReturnedByWh);
    return totalReturnedQty;
  };

  const onGetFulfillmentResponse = (res: any) => {
    let results: any = [];
    let filteredData: any = [];
    filteredData = res;
    setFulfillmentDocument(filteredData);
    filteredData.forEach((ele: any) => {
      results.push(...ele.fulfillmentItems);
    });
    setFulfillmentData(results);

    let helper: any = {};
    let result = results.reduce((r: any, o: any) => {
      let key = o.productCode;

      if (!helper[key]) {
        helper[key] = Object.assign({}, o);
        r.push(helper[key]);
      } else {
        if (
          !isEqual(
            helper[key]?.documentUOMSchemaDefinition,
            o.documentUOMSchemaDefinition
          )
        ) {
          helper[key] = Object.assign({}, o);
          r.push(helper[key]);
        } else {
          helper[key].fulfilledQuantity += o.fulfilledQuantity;
          helper[key].uomFulfilledQuantity += o.uomFulfilledQuantity;
        }
      }

      return r;
    }, []);
    if (Array.isArray(result)) {
      setReturnedItems(true, result);
    } else {
      setReturnedItems(false, documentDetails.items);
    }
  };

  const getFulfillmentItems = (code: any) => {
    FulfillmentService.getFulfillmentRecords(code, documentType).then(
      (res: any) => {
        onGetFulfillmentResponse(res);
      },
      (error: any) => {
        if (error === 404) {
          setReturnedItems(false, documentDetails.items);
        }
      }
    );
  };

  const getPPSItems = (code: any) => {
    PickPackShipService.getInvoiceShipList(code, documentType)
      .then((response: any) => {
        onGetFulfillmentResponse(response);
      })
      .catch((err: any) => {
        setReturnedItems(false, documentDetails.items);
      });
  };

  const getDocumentItems = () => {
    const code = get(documentDetails, 'documentCode');
    if (!code) {
      return;
    }
    if (isPartialInvoice) {
      setReturnedItems(false, documentDetails.items);
    } else {
      if (Utility.hasPPSQty(documentDetails)) {
        getPPSItems(code);
      } else {
        getFulfillmentItems(code);
      }
    }
  };

  const setReturnedItems = (hasFulfillment: boolean, items: any[]) => {
    setHasFulfillment(hasFulfillment);
    setReturnedItemsData(items);
  };

  const populateItemsData = () => {
    if (!Array.isArray(returnedItemsData)) {
      return;
    }

    //Fulfillment - Populate missing product descriptions
    if (hasFulfillment) {
      populateProductDescFromParentDoc(returnedItemsData);
    }
    let itemsData: any = [];
    returnedItemsData.map((item, index) => {
      const returnedItem = item;
      itemsData.push(createLineItem(returnedItem, index));
    });
    if (!Utility.isEmpty(itemsData)) setReturnedItemsFormArray(itemsData);
    // setReturnedItemsData(returnedItemsData);
  };

  const getReturnQty = (product: any) => {
    let data = previousReturnDoc.flatMap((ele: any) => ele.salesReturnItems);
    return data
      .filter(
        (item: any) =>
          item.productCode === product.productCode &&
          isEqual(
            item.documentUOMSchemaDefinition,
            product.documentUOMSchemaDefinition
          )
      )
      .reduce((sum: any, item: any) => sum + item.quantityToReturn, 0);
  };

  const createLineItem = (item: any, prodOrder: number) => {
    let availableProductQuantity: any = item.availableProductQuantity;

    let quantityFulfilled = Utility.roundingOff(
      item.documentUOMSchemaDefinition
        ? item.uomFulfilledQuantity
        : item.fulfilledQuantity,
      QTY_ROUNDOFF_PRECISION
    );
    if (
      quantityFulfilled == 0 &&
      documentDetails.fulfillmentType == 'PICK_PACK_SHIP'
    )
      quantityFulfilled = Utility.roundingOff(
        item.productQuantity,
        QTY_ROUNDOFF_PRECISION
      );
    let returnedQuantity = !quantityToReturns?.[item.productCode]
      ? 0
      : getReturnQty(item);
    let productOrder = prodOrder + 1;
    item['advancedTrackingMetaData'] = [];
    const quantityToReturn: any = 0;
    return {
      ...item,
      availableProductQuantity,
      productOrder,
      quantityToReturn,
      quantityFulfilled,
      returnedQuantity
    };
  };

  const populateProductDescFromParentDoc = (fulfilledItems: any) => {
    const productDescriptions: any = {};
    documentDetails?.items.forEach((item: any) => {
      productDescriptions[item.productCode] = item.productDescription;
    });
    fulfilledItems.forEach((item: any) => {
      item.productDescription = get(productDescriptions, item.productCode);
    });
    setReturnedItemsData(fulfilledItems);
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SALES_RETURN,
        data: () => {
          validateAndReturn();
        }
      });
    }
  };
  useEffect(() => {
    registerInteractions();
  });

  const closePopup = (docType: DOC_TYPE, payload: any) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
    if (docType === DOC_TYPE.INVOICE) {
      dispatch(fetchInvoices());
    } else if (docType === DOC_TYPE.SALES_ORDER) {
      dispatch(fetchInvoices());
    } else {
      dispatch(fetchQuotes());
    }

    removeLoader();
    setDisplayError(false);
    setErrorMessageText('');
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (
        !Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Sales return date',
          'SALES_RETURN'
        )
      ) {
        return;
      }
      callback(newDate);
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const onInputChange = (val: any, item: any, rowIndex: any) => {
    let updatedState = returnedItemsFormArray;
    let quantityTocheck =
      (item.documentUOMSchemaDefinition
        ? item.uomFulfilledQuantity
        : item.quantityFulfilled) -
      (item.returnedQuantity
        ? item.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              item.returnedQuantity,
              item.documentUOMSchemaDefinition
            )
          : Number(item.returnedQuantity)
        : 0);
    if (
      Number(val) > Utility.roundOff(quantityTocheck, tenantInfo.decimalScale)
    ) {
      showAlert(
        '',
        'Returning Quantity should be less than or equal to fulfilled quantity.'
      );
    }
    updatedState[rowIndex].quantityToReturn = Number(val);
    updatedState[rowIndex]['advancedTrackingType'] = TRACKING_TYPE.NONE;
    setReturnedItemsFormArray(updatedState);
  };

  const setQuotationItemsCodes = () => {
    let updateState = returnedItemsFormArray;
    updateState.forEach((item: any) => {
      const quotationItem = quotationItems.find(
        (quotationItem: any) => quotationItem.productCode === item.productCode
      );
      if (quotationItem) {
        item.linkedPIItemCode =
          quotationItem?.quotationItemCode || quotationItem?.salesOrderItemCode;
      }
    });
    setReturnedItemsFormArray(updateState);
  };

  const validateAndReturn = () => {
    let valid = returnedItemsFormArray.every((ele: any) => {
      let quantityTocheck =
        (ele.documentUOMSchemaDefinition
          ? ele.uomFulfilledQuantity
          : ele.quantityFulfilled) -
        (ele.returnedQuantity
          ? ele.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                ele.returnedQuantity,
                ele.documentUOMSchemaDefinition
              )
            : Number(ele.returnedQuantity)
          : 0);
      return (
        Number(ele.quantityToReturn) <=
        Utility.roundOff(quantityTocheck, tenantInfo.decimalScale)
      );
    });

    if (!valid) {
      setDisplayError(true);
      let errorMsg =
        'Returning Quantity should be less than or equal to fulfilled quantity.';
      setErrorMessageText(errorMsg);
      return;
    }
    setDisplayError(false);

    const totalItems = returnedItemsFormArray
      .map((item: any) => Number(item.quantityToReturn))
      .reduce((sum: any, returned: any) => sum + returned);

    if (totalItems === 0) {
      setDisplayError(true);
      setErrorMessageText(
        'At least one product must have more than 0 returning quantity'
      );
      return;
    }
    setDisplayError(false);

    if (
      Utility.isEmpty(selectedWarehouse?.code) &&
      Utility.isEmpty(selectedWareHouseWithRRB?.warehouse)
    ) {
      setDisplayError(true);
      setErrorMessageText('Warehouse should not be empty');
      return;
    }
    setDisplayError(false);

    if (
      isPartialInvoice &&
      get(documentDetails, 'documentType') == DOC_TYPE.INVOICE
    ) {
      setQuotationItemsCodes();
    }

    salesReturn();
  };

  const formatValues = () => {
    const contact = {
      name: documentDetails.contact['name'],
      address: documentDetails.contact.address
    };
    let batchFilterData = returnedItemsFormArray.map((ele: any) => {
      let obj: any = {};
      if (ele.documentUOMSchemaDefinition) {
        obj = {
          ...ele,
          rowCode: selectedWareHouseWithRRB?.rowCode,
          rackCode: selectedWareHouseWithRRB?.rackCode,
          binCode: selectedWareHouseWithRRB?.binCode,
          uomQuantityToReturn: ele.quantityToReturn,
          uomQuantityFulfilled: Utility.roundingOff(
            ele.uomFulfilledQuantity ||
              Utility.getUomQuantity(
                ele.fulfilledQuantity,
                ele.documentUOMSchemaDefinition
              ),
            QTY_ROUNDOFF_PRECISION
          ),
          quantityToReturn: Utility.roundingOff(
            Utility.getUomWarehouseQuantityWithoutRoundOff(
              ele.quantityToReturn,
              ele.documentUOMSchemaDefinition
            ),
            QTY_ROUNDOFF_PRECISION
          ),
          quantityFulfilled: Utility.roundingOff(
            Utility.getUomWarehouseQuantityWithoutRoundOff(
              ele.quantityFulfilled,
              ele.documentUOMSchemaDefinition
            ),
            QTY_ROUNDOFF_PRECISION
          ),
          advancedTrackingMetaData: filterTrackingData(ele)
        };
      } else {
        obj = {
          ...ele,
          rowCode: selectedWareHouseWithRRB?.rowCode,
          rackCode: selectedWareHouseWithRRB?.rackCode,
          binCode: selectedWareHouseWithRRB?.binCode,
          advancedTrackingMetaData: filterTrackingData(ele)
        };
      }
      return obj;
    });

    let returnQuantityType: any = RETURN_QUANTITY_TYPE.FULL;
    const totalItems = returnedItemsFormArray
      .map((item: any) => Number(item.quantityToReturn))
      .reduce((sum: any, returned: any) => sum + returned);

    const totalFulfilled = returnedItemsFormArray
      .map((item: any) =>
        Number(
          Utility.isEmpty(item.documentUOMSchemaDefinition)
            ? item.fulfilledQuantity
            : item.uomFulfilledQuantity
        )
      )
      .reduce((sum: any, fulfilled: any) => sum + fulfilled);

    if (
      Utility.roundingOff(totalItems, QTY_ROUNDOFF_PRECISION) !==
      Utility.roundingOff(totalFulfilled, QTY_ROUNDOFF_PRECISION)
    ) {
      returnQuantityType = RETURN_QUANTITY_TYPE.PARTIAL;
    }

    // let linkedDocument = [
    //   {
    //     documentCode: documentDetails.documentCode,
    //     documentSequenceCode: documentDetails.documentSequenceCode,
    //     documentType: documentDetails.documentType
    //   }
    // ];
    let linkedDocument: any = [];

    let allLinkedDocs: any = [];

    if (!Utility.isEmpty(documentDetails.linkedDocuments)) {
      allLinkedDocs.push(...documentDetails.linkedDocuments);
    }
    if (!Utility.isEmpty(documentDetails.linkedSalesInvoices)) {
      allLinkedDocs.push(
        ...documentDetails.linkedSalesInvoices.map((ele: any) => {
          return {
            ...ele,
            documentType: DOC_TYPE.INVOICE,
            documentCode: ele.salesInvoiceCode
          };
        })
      );
    }
    if (!Utility.isEmpty(documentDetails.linkedQuotationDocuments)) {
      allLinkedDocs.push(...documentDetails.linkedQuotationDocuments);
    }
    fulfillmentDocument.forEach((doc: any) => {
      let linkDoc = allLinkedDocs?.find(
        (ele: any) =>
          doc.documentType === ele.documentType &&
          doc.documentCode === ele.documentCode
      );
      if (!Utility.isEmpty(linkDoc)) {
        let index = linkedDocument?.findIndex(
          (ele: any) =>
            ele.documentCode === linkDoc.documentCode &&
            ele.documentType === linkDoc.documentType
        );
        if (index === -1) {
          linkedDocument.push({
            documentCode: linkDoc.documentCode,
            documentSequenceCode: linkDoc.documentSequenceCode,
            documentType: linkDoc.documentType
          });
        }
      } else {
        let index = linkedDocument?.findIndex(
          (ele: any) =>
            ele.documentCode === documentDetails.documentCode &&
            ele.documentType === documentDetails.documentType
        );
        if (index === -1) {
          linkedDocument.push({
            documentCode: documentDetails.documentCode,
            documentSequenceCode: documentDetails.documentSequenceCode,
            documentType: documentDetails.documentType
          });
        }
      }
    });
    // if (documentDetails.linkedDocuments) {
    //   linkedDocument.push(...documentDetails.linkedDocuments);
    // }

    let obj = {
      salesReturnDate: DateFormatService.getDateStrFromDate(
        salesReturnDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      warehouseCode: Utility.getValue(
        selectedWareHouseWithRRB?.warehouse?.value?.code,
        selectedWareHouseWithRRB?.warehouse?.code
      ),
      rowCode: selectedWareHouseWithRRB?.rowCode,
      rackCode: selectedWareHouseWithRRB?.rackCode,
      binCode: selectedWareHouseWithRRB?.binCode,
      quantity: returnQuantityType,
      linkedDocuments: [...linkedDocument],
      returnType: RETURN_TYPE.RETURN_ONLY,
      contactCode: documentDetails.contactCode,
      currency: documentDetails.currency,
      contactInfo: contact,
      exchangeRate: documentDetails.exchangeRate,
      fulfillmentType: documentDetails.fulfillmentType,
      customField: documentDetails?.customField ?? [],
      salesReturnItems: batchFilterData,
      reasonToReturn: reason
    };
    return obj;
  };

  const filterTrackingData = (ele: any) => {
    if (ele.advancedTrackingType === 'BATCH') {
      let filteredData = ele?.advancedTrackingMetaData?.filter(
        (item: any) => Number(item.batchSize) !== 0
      );
      return filteredData?.map((item: any) => {
        return {
          ...item,
          batchSize: Utility.roundingOff(
            ele.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                  item.batchSize,
                  ele.documentUOMSchemaDefinition
                )
              : item.batchSize,
            QTY_ROUNDOFF_PRECISION
          ),
          warehouseCode: Utility.getValue(
            selectedWareHouseWithRRB?.warehouse?.value?.code,
            selectedWareHouseWithRRB?.warehouse?.code
          ),
          rowCode: selectedWareHouseWithRRB?.rowCode,
          rackCode: selectedWareHouseWithRRB?.rackCode,
          binCode: selectedWareHouseWithRRB?.binCode
        };
      });
    } else if (ele.advancedTrackingType === 'SERIAL') {
      let filteredData = ele?.advancedTrackingMetaData?.filter(
        (item: any) => Number(item.batchSize) !== 0
      );
      return filteredData?.map((item: any) => {
        return {
          ...item,
          warehouseCode: Utility.getValue(
            selectedWareHouseWithRRB?.warehouse?.value?.code,
            selectedWareHouseWithRRB?.warehouse?.code
          ),
          rowCode: selectedWareHouseWithRRB?.rowCode,
          rackCode: selectedWareHouseWithRRB?.rackCode,
          binCode: selectedWareHouseWithRRB?.binCode
        };
      });
    } else {
      return ele.advancedTrackingMetaData;
    }
  };

  const getRoundedUOMQty = (qty: any) => {
    return Utility.roundOff(Number(qty), tenantInfo.decimalScale);
  };

  const getPreviousReturnQty = (product: any) => {
    let data = previousReturnDoc.flatMap((ele: any) =>
      (ele?.salesReturnItems || []).map((item: any) => ({
        ...item,
        warehouseCode: ele.warehouseCode,
        binCode: ele.binCode,
        rackCode: ele.rackCode,
        rowCode: ele.rowCode
      }))
    );
    return data
      .filter(
        (item: any) =>
          item.productCode === product.productCode &&
          item.warehouseCode === product.warehouseCode &&
          item.rowCode === product.rowCode &&
          item.rackCode === product.rackCode &&
          item.binCode === product.binCode
      )
      .reduce((sum: any, item: any) => sum + item.quantityToReturn, 0);
  };

  const salesReturn = () => {
    const returned = new Returned(formatValues());
    let hasError = false;

    let invalidItems: any = [];
    let invalidItemsProductCodes: any = [];
    let filteredSalesReturnData = returned.salesReturnItems?.filter(
      (ele: any) =>
        ele.quantityToReturn > 0 && ele.productType !== PRODUCT_TYPE.NON_TRACKED
    );
    filteredSalesReturnData?.forEach((record: any) => {
      let warehouseData = [];

      warehouseData =
        record.advancedTrackingType === TRACKING_TYPE.NONE
          ? fulfillmentData
              ?.filter(
                (ele: any) =>
                  record.advancedTrackingType === TRACKING_TYPE.NONE &&
                  record.productCode === ele.productCode
              )
              ?.flatMap((ele: any) =>
                Utility.isEmpty(ele.warehouseInventoryData)
                  ? ele.linkedWareHouses
                  : ele.warehouseInventoryData.filter(
                      (ele: any) =>
                        ele.warehouseCode ===
                          Utility.getValue(
                            selectedWareHouseWithRRB?.warehouse?.value?.code,
                            selectedWareHouseWithRRB?.warehouse?.code
                          ) &&
                        ele.rowCode == selectedWareHouseWithRRB.rowCode &&
                        ele.rackCode == selectedWareHouseWithRRB.rackCode &&
                        ele.binCode == selectedWareHouseWithRRB.binCode
                    )
              )
          : getData(record);

      if (Utility.isEmpty(warehouseData)) {
        invalidItemsProductCodes.push(record.productCode);
        invalidItems.push(record.productName);
      }

      warehouseData = warehouseData?.filter(
        (wh: any) => wh?.warehouseCode === returned?.warehouseCode
      );
      if (record.advancedTrackingType === TRACKING_TYPE.NONE) {
        let totalQty: any = 0;
        if (!Utility.isEmpty(warehouseData)) {
          totalQty =
            warehouseData
              ?.map((item: any) => Number(item.quantity))
              ?.reduce((sum: any, qty: any) => sum + qty) || 0;
          totalQty = Utility.roundingOff(totalQty, QTY_ROUNDOFF_PRECISION);
        }
        // let prevReturndQty =
        //   quantityToReturnsByWarehouse?.[returned.warehouseCode]?.[
        //     record.productCode
        //   ] || 0;
        let prevReturndQty = getPreviousReturnQty(record) || 0;

        if (prevReturndQty + record.quantityToReturn > totalQty) {
          if (!invalidItemsProductCodes.includes(record.productCode)) {
            invalidItemsProductCodes.push(record.productCode);
            invalidItems.push(record.productName);
          }
        }
      }
      if (record.advancedTrackingType === TRACKING_TYPE.SERIAL) {
        if (record.quantityToReturn > warehouseData.length) {
          if (!invalidItemsProductCodes.includes(record.productCode)) {
            invalidItemsProductCodes.push(record.productCode);
            invalidItems.push(record.productName);
          }
        }
      }
      if (record.advancedTrackingType === TRACKING_TYPE.BATCH) {
        record?.advancedTrackingMetaData?.forEach((item: any) => {
          let batchSize = record?.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                item.batchSize,
                record?.documentUOMSchemaDefinition
              )
            : item.batchSize;
          if (batchSize > item.batchSizeFulfilled) {
            if (!invalidItemsProductCodes.includes(record.productCode)) {
              invalidItemsProductCodes.push(record.productCode);
              invalidItems.push(record.productName);
            }
          }
        });
      }
    });
    if (invalidItems && invalidItems.length > 0) {
      setDisplayError(true);
      setErrorMessageText(
        invalidItems.toString() + ' have insufficient quantity.'
      );
      hasError = true;
      return;
    } else {
      hasError = false;
      setDisplayError(false);
    }
    if (!Utility.isEmpty(returned.salesReturnDate)) {
      const docDate = DateFormatService.getDateFromStr(
        returned.salesReturnDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Sales return date',
          'SALES_RETURN'
        )
      ) {
        return;
      }
      if (
        docDate <
        DateFormatService.getDateFromStr(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      ) {
        setDisplayError(true);
        setErrorMessageText(
          'Purchase Return Date cannot be before book beginning date'
        );
        return;
      } else {
        setDisplayError(false);
      }
    }
    showLoader();
    SalesReturnService.saveSalesReturn(returned).then(
      (response: any) => {
        closePopup(documentDetails.documentType, returned);
        props.isClosed(true, response);
      },
      (error: any) => {
        // showAlert('Error!', AlertUtility.getErrorMessage(error));
        showAlertOnError(error);
        removeLoader();
      }
    );
  };

  const showAlertOnError = (error: any) => {
    if (error?.data?.errorMessage) {
      showAlert(`Error`, error?.data?.errorMessage);
    } else {
      showAlert(
        `Error`,
        'Something went wrong. Please try again after some time.'
      );
    }
  };

  const loadBatchTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const setBatchTracking = (item: any) => {
    loadBatchTrackingProducts(item.productCode);
    setShowBatchTracking(true);
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };
  const setSerialTracking = (item: any) => {
    loadSerialTrackingProducts(item.productCode);
    setShowSerialTracking(true);
  };

  const getSelectedUomValue = (data: any) => {
    const id = data?.documentUom || data?.product?.stockUom;
    let filteredUOM =
      uoms && uoms.length > 0 ? uoms.find((uom) => uom.id === id) : null;
    return filteredUOM?.name || '';
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const showErrorMessage = () => {
    if (displayError) {
      return (
        <div className="row mt-l parent-width rounded bg-red-100 p-2">
          <span>{errorMessageText}</span>
        </div>
      );
    }
  };

  const getData = (item: any) => {
    let filteredData = advanceTrakingData?.filter(
      (ele: any) =>
        ele?.productVariantCode === item?.productCode &&
        ele?.productVariantCode === item?.productCode &&
        ele.warehouseCode ===
          Utility.getValue(
            selectedWareHouseWithRRB?.warehouse?.value?.code,
            selectedWareHouseWithRRB?.warehouse?.code
          ) &&
        ele.rowCode == selectedWareHouseWithRRB.rowCode &&
        ele.rackCode == selectedWareHouseWithRRB.rackCode &&
        ele.binCode == selectedWareHouseWithRRB.binCode
    );
    return filteredData;
  };

  const getBatchComponent = (item: any, i: any) => {
    return (
      <td style={{ border: 0 }}>
        {showBatchTracking && item.advancedTrackingType === 'BATCH' && (
          <SalesReturnBatch
            selectedWarehouse={selectedWareHouseWithRRB}
            itemDetails={{
              ...item,
              requiredQuantity:
                (item.documentUOMSchemaDefinition
                  ? item.uomFulfilledQuantity
                  : item.quantityFulfilled) -
                (item.returnedQuantity ? Number(item.returnedQuantity) : 0)
            }}
            module={MODULE_TYPE.SELL}
            previousLinkedDoc={previousLinkedDoc}
            defaultProductWarehouse={warehouses}
            advanceTrakingData={getData(item)}
            documentDetails={documentDetails}
            onBatchSave={(data: any, quantityToReturn: any) => {
              setShowBatchTracking(false);
              setShowSerialTracking(false);
              let updatedState = returnedItemsFormArray;
              updatedState[i]['advancedTrackingMetaData'] = data;
              updatedState[i]['quantityToReturn'] = quantityToReturn;
              updatedState[i]['advancedTrackingType'] = TRACKING_TYPE.BATCH;
              setReturnedItemsFormArray(updatedState);
            }}
            onClose={() => {
              setShowBatchTracking(false);
              setShowSerialTracking(false);
            }}
          ></SalesReturnBatch>
        )}
      </td>
    );
  };

  const getSerialComponent = (item: any, i: any) => {
    return (
      <td style={{ border: 0 }}>
        {showSerialTracking && item.advancedTrackingType === 'SERIAL' && (
          <SalesReturnSerial
            itemDetails={{
              ...currentRowData,
              requiredQuantity:
                (currentRowData.documentUOMSchemaDefinition
                  ? currentRowData.uomFulfilledQuantity
                  : currentRowData.quantityFulfilled) -
                (currentRowData.returnedQuantity
                  ? Number(item.returnedQuantity)
                  : 0)
            }}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={warehouses}
            advanceTrakingData={getData(item)}
            onSerialSave={(data: any, quantityToReturn: any) => {
              let returnQty = item.documentUOMSchemaDefinition
                ? Utility.getUomQuantity(
                    quantityToReturn,
                    item.documentUOMSchemaDefinition
                  )
                : quantityToReturn;
              setShowSerialTracking(false);
              setShowBatchTracking(false);
              let updatedState = returnedItemsFormArray;
              updatedState[i]['advancedTrackingMetaData'] = data;
              updatedState[i]['advancedTrackingType'] = TRACKING_TYPE.SERIAL;
              updatedState[i]['quantityToReturn'] = returnQty;
              setReturnedItemsFormArray(updatedState);
            }}
            onClose={() => {
              setShowSerialTracking(false);
              setShowBatchTracking(false);
            }}
          ></SalesReturnSerial>
        )}
      </td>
    );
  };

  const getAddressField = () => {
    return (
      <div className="column">
        <DKLabel text={`Ship To`} className="fw-b fs-r text-gray" />
        <div>
          <span className="city d-flex">
            {warehouseAddress?.address1}
            {warehouseAddress?.city && warehouseAddress?.city ? ', ' : ''}
            {warehouseAddress?.city}
            {warehouseAddress?.state && warehouseAddress?.city ? ', ' : ''}
            {warehouseAddress?.state}
          </span>
          <span className="country d-flex">
            {warehouseAddress?.country} {warehouseAddress?.postalCode}
          </span>
        </div>
        {/* )
    } */}
      </div>
    );
  };

  return (
    <div className="column parent-width p-2 pt-1">
      <div className="column parent-width gap-2">
        {getAddressField()}
        <div className="row gap-2 flex-wrap mt-2">
          <div
            className="row"
            style={{
              width: WarehouseManagementHelper.isRRBEnabledForWarehouse(
                selectedWareHouseWithRRB.warehouse
              )
                ? '100%'
                : 280
            }}
          >
            <ReturnWarehouseManagementRRB
              onSave={(data: any) => {
                setSelectedWarehouseWithRRB(data);
                let updatedState = returnedItemsFormArray;
                updatedState.forEach((ele: any, index: any) => {
                  updatedState[index]['advancedTrackingMetaData'] = [];
                  updatedState[index]['quantityToReturn'] = 0;
                });
                setReturnedItemsFormArray(updatedState);

                setWarehouseAddress(
                  data?.warehouse
                    ? data?.warehouse?.location?.address?.[0]
                    : selectedWarehouse[0]?.location?.address?.[0]
                );
              }}
              type={DOC_TYPE.SALES_RETURN}
              defaultReturnWarehouse={defaultReturnWarehouse}
              editData={defaultReturnWarehouseRRBDetails}
            />
          </div>
          <ReturnsReasonDropDown
            reason={reason}
            setReason={setReason}
            options={salesReturnOptions}
          />
          <div style={{ width: 280 }}>
            <DKInput
              title={'Return Date'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DATE}
              value={salesReturnDate}
              onChange={(date: Date) => setsalesReturnDate(date)}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo?.dateFormat
              )}
            />
          </div>
        </div>
      </div>
      {showErrorMessage()}
      <div
        className="column parent-width invoice-table hide-scroll-bar mt-l"
        style={
          {
            // position: 'relative',
            // top: '50px',
            // overflowX: 'scroll'
          }
        }
      >
        <table
          ref={lineItemsTable}
          className="table mb-m"
          style={{ width: '100%' }}
        >
          <thead>
            <tr>
              <th className="text-left" style={{ width: 150, minWidth: 200 }}>
                Product Name
              </th>
              <th className="text-left">Product Description</th>
              <th className="text-left">UOM</th>
              <th className="text-center" style={{ width: 100, minWidth: 100 }}>
                Fulfilled qty
              </th>
              <th
                className="text-right"
                style={{ width: 100, minWidth: 60, maxWidth: 100 }}
              >
                Returned qty
              </th>
              <th className="text-right" style={{ width: 150, minWidth: 100 }}>
                Returning qty
              </th>
            </tr>
          </thead>
          <tbody>
            {returnedItemsFormArray &&
              returnedItemsFormArray.map((item: any, i: any) => {
                return (
                  <tr className="dk-data-grid-row-bg">
                    <td className="px-2 py-4 fs-r">
                      {item.productName && item.productName.length > 50 ? (
                        <DKTooltipWrapper
                          content={item.productName}
                          tooltipStyle={{ maxWidth: '40%', minWidth: 250 }}
                          gapFromCursorX={10}
                        >
                          {item.productName.substring(0, 50) + '...'}
                        </DKTooltipWrapper>
                      ) : (
                        item.productName
                      )}
                    </td>
                    <td className="px-2 py-4 fs-r">
                      {item.productDescription &&
                      item.productDescription.length > 50 ? (
                        <DKTooltipWrapper
                          content={item.productDescription}
                          tooltipStyle={{ maxWidth: '40%', minWidth: 250 }}
                          gapFromCursorX={10}
                        >
                          {item.productDescription.substring(0, 50) + '...'}
                        </DKTooltipWrapper>
                      ) : (
                        item.productDescription
                      )}
                    </td>
                    <td className="px-2 py-4 fs-r">
                      {getSelectedUomValue(item)}
                    </td>
                    <td className="px-2 py-4 fs-r text-align-center">
                      {item.documentUOMSchemaDefinition
                        ? NumberFormatService.getNumber(
                            item.uomFulfilledQuantity
                              ? item.uomFulfilledQuantity
                              : 0
                          )
                        : NumberFormatService.getNumber(
                            item.quantityFulfilled ? item.quantityFulfilled : 0
                          )}
                    </td>
                    <td className="text-align-right px-2 py-4 fs-r">
                      {item.documentUOMSchemaDefinition
                        ? item.returnedQuantity
                          ? Utility.getUomQuantity(
                              item.returnedQuantity,
                              item.documentUOMSchemaDefinition
                            )
                          : 0
                        : item.returnedQuantity
                        ? item.returnedQuantity
                        : 0}
                    </td>
                    <td className="text-align-right px-2 py-4 fs-r">
                      <div>
                        <DKInput
                          errorMessage={'  '}
                          validator={(value: string) =>
                            Number(value) <=
                            Utility.roundOff(
                              (item.documentUOMSchemaDefinition
                                ? item.uomFulfilledQuantity
                                : item.quantityFulfilled) -
                                (item.returnedQuantity
                                  ? item.documentUOMSchemaDefinition
                                    ? Utility.getUomQuantity(
                                        item.returnedQuantity,
                                        item.documentUOMSchemaDefinition
                                      )
                                    : Number(item.returnedQuantity)
                                  : 0),
                              tenantInfo.decimalScale
                            )
                          }
                          canValidate={true}
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          value={returnedItemsFormArray[i].quantityToReturn}
                          type={INPUT_TYPE.NUMBER}
                          onChange={(text: any) => {
                            onInputChange(text, item, i);
                          }}
                          readOnly={
                            item.advancedTrackingType ===
                              TRACKING_TYPE.SERIAL ||
                            item.advancedTrackingType === TRACKING_TYPE.BATCH
                          }
                        />
                      </div>
                    </td>
                    {item.advancedTrackingType !== TRACKING_TYPE.NONE && (
                      <td className="text-align-right px-2 py-4 fs-r">
                        <DKIcon
                          src={ic_barcode_green}
                          onClick={() => {
                            if (item.advancedTrackingType === 'BATCH') {
                              setShowBatchTracking(true);
                              setCurrentRowData(item);
                              setCurrentRowIndex(i);
                            }
                            if (item.advancedTrackingType === 'SERIAL') {
                              setShowSerialTracking(true);
                              setCurrentRowData(item);
                              setCurrentRowIndex(i);
                            }
                          }}
                          // title="Advanced Tracking"
                          className="ic-s flex align-items-center cursor-hand"
                          style={{ width: '100%' }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
          {getBatchComponent(currentRowData, currentRowIndex)}
          {getSerialComponent(currentRowData, currentRowIndex)}
        </table>
      </div>
    </div>
  );
};

export default SalesReturnPopup;
