import React, { Component } from 'react'
import TemplateSettingsManager from '../Manager/TemplateSettingsManager'
import PayslipFieldUtility from '../Templates/PayslipTemplates/PayslipFieldUtility'
import PayslipUtility from '../Templates/PayslipTemplates/PayslipUtility'

// onPayslipFieldClick = {() => { }}
// data = { this.state.data }
// isFromERP = { this.state.isFromERP }
// excludedFieldCount = { 3}
// styles = { fieldsStyles }
// lastRowFieldStyles = { lastRowFieldStyles }
// currency = { this.currency }
// columnCount = { 4}

export default class DKPayslipFieldSection extends Component {

    render() {
        var payslipFieldList = this.getPayslipField()
        if (payslipFieldList.length === 0) {
            return <></>
        }
        else {
            return this.renderPayslipFieldList(payslipFieldList)
        }
    }

    getPayslipField() {
        var sortedList = []
        var excludedFieldCount = this.props.excludedFieldCount ? this.props.excludedFieldCount : 0
        var fieldList = TemplateSettingsManager.defaultTemplateSettings.payslipFieldConfiguration
        if (fieldList === undefined
            || fieldList === null
            || Object.keys(fieldList).length === 0
            || fieldList.length === 0) {
            fieldList = PayslipFieldUtility.getDefaultFields(this.props.data)
        }


        if (fieldList.length > 0) {
            sortedList = fieldList.sort((a, b) => a.index - b.index).filter(x => x.isSelected)
            if(sortedList.length > excludedFieldCount) {
                sortedList = sortedList.slice(excludedFieldCount)
            }
        }
        return sortedList
    }

    renderPayslipFieldList(payslipFieldList) {
        return (
            <div className='ColumnDiv parent-width'
                onClick={this.props.onPayslipFieldClick}
                style={{
                    position: 'relative',
                }}>
                {this.getPayslipFieldList(payslipFieldList)}
            </div>
        )
    }

    getPayslipFieldList(payslipFieldList) {
        // split 4 column per row
        var newArraySize = 4
        var newCustomFieldList = []

        if (this.props.columnCount !== undefined) {
            if (Number(this.props.columnCount) > 0) {
                newArraySize = Number(this.props.columnCount)
            }
        }

        var rowCount = Math.ceil(payslipFieldList.length / newArraySize)
        for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
            var startIndex = rowIndex * newArraySize
            var columnList = []
            for (let index = startIndex; index < startIndex + newArraySize; index++) {
                var data = payslipFieldList[index]
                if (data !== undefined && data !== null) {
                    columnList.push(data)
                }
                else {
                    columnList.push(undefined)
                }
            }
            newCustomFieldList.push(columnList)
        }

        //add logic for last row and last column
        var lastRow = []
        var updatedLastRow = false
        if(newCustomFieldList.length > 0) {
            lastRow = newCustomFieldList[newCustomFieldList.length - 1]
        }

        if(lastRow.length > 0 ) {
            var isUndefinedCount = lastRow.filter( x => x === undefined).length
            var newLastRow = []
            if (newArraySize === 3) {
                if(isUndefinedCount === 1) {
                    newLastRow = [lastRow[0], undefined, lastRow[1]]
                    newCustomFieldList[newCustomFieldList.length - 1] = newLastRow
                    updatedLastRow = true
                }
            }
            else if (newArraySize === 4) {
                if (isUndefinedCount === 2) {
                    newLastRow = [lastRow[0], undefined, undefined, lastRow[1]]
                    newCustomFieldList[newCustomFieldList.length - 1] = newLastRow
                    updatedLastRow = true
                }
            }
        }

        return newCustomFieldList.map((row, rowIndex) => {
            var columns = row.map((item, index) => {
                var style = undefined
                if(item !== undefined) {
                    if (this.props.styles.length > index) {
                        style = this.props.styles[index]
                    }

                    if (newCustomFieldList.length - 1 === rowIndex &&  updatedLastRow) {
                        if (this.props.lastRowFieldStyles !== undefined) {
                            style = this.props.lastRowFieldStyles[index]
                        }
                    }
                }

                return this.renderPayslipField(item, style)
            });

            return <div className='RowDiv' style={{ alignItems: 'baseline' }}>
                {columns}
            </div>
        });
    }

    renderPayslipField(element, style) {
        if(element === undefined) {
            return undefined
        }

        return PayslipUtility.renderPayslipDetailsContainer(
            PayslipFieldUtility.getHeaderText(this.props.data, this.props.isFromERP, element),
            PayslipFieldUtility.getDataByField(this.props.data, this.props.currency, this.props.isFromERP, element.type),
            PayslipFieldUtility.getFontReverse(element, style)
        )
    }

    getHeaderColor() {
        if(this.props.isThemeColor) {
            return this.props.data.themeColor
        }

        if (this.props.headerColor !== undefined) {
            return this.props.headerColor
        }

        return undefined
    }
}
