import React, { Component } from "react";
import "../../index.css";
import { FONT_SIZE, EditorInfoType } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate7 extends Component {
    arabicHeaderWidth = '30%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                <div style={{ width: '30%', height: getVW(Utility.convertToPrintScale(23, this.state.data.printScale)), backgroundColor: this.state.data.themeColor, marginBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale))}} />
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : undefined,
                        textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxxLarge }, this.state.data.printScale)
                    }}>
                        {getLocalisedText(this.state.data.documentType).toLowerCase()}</text>
                {ComponentManager.addVerticalSpace(40)}
                <div className="RowDiv" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                    {this.getCompanyNameAddressSection()}
                    {this.getDocumentInfoSection()}
                </div>
            </div>
        );
    }

    getDocumentInfoSection() {
        return <div className="ColumnDiv" style={{ width: '45%' }}>
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getCompanyNameAddressSection() {
        return <div className="ColumnDiv"
            style={{
                width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '100%'
            }}>
            {this.state.data.showCompanyName && this.getCompanyName()}
            {this.state.data.showFrom && this.getCompanyAddress()}
        </div>;
    }

    getCompanyAddress() {
        return ComponentManager.getDescriptionSection(
            "",
            Utility.getCompanyAddress(this.state.data),
            Utility.getIsArabicLang() ? '100%' : '40%' ,
            'black',
            false,
            '#000000',
            getVW(6),
            Utility.getOwnerType(this.state.data, AddressType.from),
            this.state.data.printScale
        );
    }

    getCompanyName() {
        return <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    width: Utility.getIsArabicLang() ? '100%' : '50%',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                }}>
            {getLocalisedText(Utility.getCompanyName(this.state.data))}
        </text>;
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="ColumnDiv" style={{ bottom: 0, width: '100%', justifyContent: 'center', alignItems: "flex-start" }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(20)}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) &&ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {right: getVW(10)})}
                    {ComponentManager.addVerticalSpace(Utility.getImageSize(EditorInfoType.logo))}
                    {this.addCompanyNameAndDocTypeLabels()}
                    {ComponentManager.addVerticalSpace(40)}
                    {this.getShippingBillingAddresses()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(20)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsContainsTotalSection(this.state.data) && this.getTotalSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.addTermsNConditionsView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getShippingBillingAddresses() {
        return <div
            className="RowDiv"
            style={{
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                this.state.data.billToTitle,
                Utility.getClientBillToAddress(this.state.data),
                "25%",
                'black',
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.billTo),
                this.state.data.printScale
            )}
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "10%" }} />}
            {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                this.state.data.shipToTitle,
                Utility.getClientShipToAddress(this.state.data),
                "25%",
                'black',
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.shipTo),
                this.state.data.printScale
            )}
            <div style={{ width: "20%" }} />

        </div>;
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '50%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getTotalSection() {
        return (
            <div className="RowDiv" style={{ zIndex: 3, padding: getVW(Utility.convertToPrintScale(15, this.state.data.printScale)), boxSizing: "border-box", marginTop: getVW(10), backgroundColor: 'white', height: getVW(60), border: 'solid', borderWidth: getVW(Utility.convertToPrintScale(4, this.state.data.printScale)), borderColor: 'black', justifyContent: "space-between" }}>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                    }}>
                    {getLocalisedText('total')}
                </div>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        direction: Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large }, this.state.data.printScale)
                    }}>
                    {Utility.getTotalValue(this.state.data)}
                </div>
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "black";
        style.headerBackgroundColor = "transparent";
        style.footerTextColor = "white";
        style.headerBorderColor = "black";
        style.itemBorderColor = "black";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        style.subTotalBorderColor = 'transparent';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['25%', '35%', '40%'] : ['35%', '34%', '31%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            footerTextColor: "black"
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            footerTextColor: "black"
        };

        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(style, data)}
                </div>
            </div>
        );
    }
}
