import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { AccountInfoState, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import AccountsService from '../../Services/Accounts';
import AuditLogService from '../../Services/AuditLog';
import ContactService from '../../Services/Contact';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchAuditLog = createAsyncThunk('fetchAuditLog', async () => {
  const response = await AuditLogService.getAuditLog();
  return response;
});

export const AuditLogSlice = createSlice({
  name: 'Audit_log',
  initialState,
  reducers: {
    addAuditLogColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      state.columnConfig = configsToStore;
    },
    addAuditLog: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditLog.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAuditLog.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { addAuditLog, addAuditLogColumnConfig } = AuditLogSlice.actions;
export const selectAuditLog = (state: RootState) => state.auditLog.data;
export const selectAuditLogColumnConfig = (state: RootState) =>
  state.auditLog.columnConfig;
