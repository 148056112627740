import {
  DKButton,
  DKDataGrid,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import WorkOrderService from '../Services/MRP/WorkOrder';
import Utility from '../Utility/Utility';
import { DynamicPopupWrapper } from './PopupWrapper';
import NumberFormatService from '../Services/NumberFormat';
import { useAppSelector } from '../Redux/Hooks';
import { activeTenantInfo } from '../Redux/Slices/AuthSlice';

const ConvertWorkOrder = (props: any) => {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [apiCalling, setApiCalling] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [localConfig, setLocalConfig] = useState<any>([
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'productName',
      id: 'productName',
      datasource: [],
      key: 'productName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 270,
      renderer: (obj: any, index: number) => {
        return (
          <div>
            <DKLabel text={obj?.rowData?.productName} className="fw-b" />
            {obj?.rowData?.productDocumentSequenceCode && (
              <DKLabel
                text={`(${obj?.rowData?.productDocumentSequenceCode})`}
                className={'text-gray fs-r'}
              />
            )}
          </div>
        );
      }
    },
    {
      name: 'Product Description',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'productDescription',
      id: 'productDescription',
      key: 'productDescription',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 190,
      textAlign: 'left'
    },
    {
      name: 'Total Quantity',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'totalQuantity',
      id: 'totalQuantity',
      key: 'totalQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 110,
      textAlign: 'right',
      formatter: (data: any) => {
        return NumberFormatService.getNumber(data?.value);
      }
    },
    {
      name: 'Pending to be Converted',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'totalQuantityAllowedToConvertWo',
      id: 'totalQuantityAllowedToConvertWo',
      key: 'totalQuantityAllowedToConvertWo',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 190,
      textAlign: 'right',
      formatter: (data: any) => {
        return NumberFormatService.getNumber(data?.value);
      }
    },
    {
      name: 'Convert to Work Order Qty',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'convertQuantity',
      id: 'convertQuantity',
      datasource: [],
      key: 'convertQuantity',
      lineItem: false,
      type: INPUT_TYPE.NUMBER,
      width: 200,
      textAlign: 'right',
      formatter: (data: any) => {
        return NumberFormatService.getNumber(data?.value);
      }
    }
  ]);

  const loadData = () => {
    if (props && !Utility.isEmpty(props.soData)) {
      let soCode = props.soData.map((res: any) => res.salesOrderCode);
      if (soCode && soCode.length > 0) {
        setApiCalling(true);
        WorkOrderService.getConsolidatedProductQuantityBySO(soCode)
          .then((res: any) => {
            setApiCalling(false);
            let tmpData = [...res] || [];
            tmpData.forEach((element: any) => {
              element.convertQuantity = element.totalQuantityAllowedToConvertWo;
            });
            setData(tmpData);
          })
          .catch((err: any) => {
            setApiCalling(false);
            console.log(
              err,
              'Error occured while fetching consoliadated Product quantity by so'
            );
          });
      }
    }
  };

  const validateData = () => {
    let valid = true;
    data.forEach((record: any) => {
      if (
        record &&
        record.convertQuantity &&
        record.totalQuantityAllowedToConvertWo
      ) {
        if (record.convertQuantity < 0) {
          showAlert('Error!', 'Convert Quantity cannot be negative');
          valid = false;
        }
        if (record.convertQuantity > record.totalQuantityAllowedToConvertWo) {
          showAlert(
            'Error!',
            'Convert Quantity cannot be more than Pending Quantity'
          );
          valid = false;
        }
      }
    });
    return valid;
  };

  const onConvert = () => {
    if (validateData()) {
      let copySOData = [...props.soData];
      data?.forEach((updatedProduct: any) => {
        let conversionQty = Number(updatedProduct.convertQuantity);
        copySOData = copySOData?.map((soObject: any) => {
          let copySOObject = { ...soObject };
          copySOObject.salesOrderItems = copySOObject.salesOrderItems?.map(
            (soItem: any) => {
              let copySOItem = { ...soItem };
              let qtyToBeBuild =
                copySOItem.productQuantity - copySOItem.qtyConvertedToWo;
              if (copySOItem.productCode === updatedProduct.productCode) {
                if (conversionQty > 0) {
                  if (qtyToBeBuild <= conversionQty) {
                    copySOItem.qtyToConvert = qtyToBeBuild;
                    conversionQty = conversionQty - qtyToBeBuild;
                  } else {
                    copySOItem.qtyToConvert = conversionQty;
                    conversionQty = 0;
                  }
                } else {
                  copySOItem.qtyToConvert = 0;
                }
              }
              return copySOItem;
            }
          );
          return copySOObject;
        });
      });
      copySOData = copySOData?.map((soObject: any) => {
        let copySOObject = { ...soObject };
        copySOObject.salesOrderItems = copySOObject.salesOrderItems?.map(
          (soItem: any) => {
            let copySOItem = { ...soItem };
            copySOItem.productQuantity = copySOItem.qtyToConvert;
            delete copySOItem.qtyToConvert;
            return copySOItem;
          }
        );
        copySOObject.salesOrderItems = copySOObject?.salesOrderItems?.filter(
          (filterItem: any) => filterItem.productQuantity
        );
        return copySOObject;
      });
      props.onConvert(copySOData);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel text={`Convert to WO`} className="fw-m fs-l" />
          {apiCalling && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              onConvert();
            }}
          />
        </div>
      </div>
    );
  };

  const onRowUpdate = (val: any) => {
    let rowsData = [...data];
    switch (val.columnKey) {
      case 'convertQuantity':
        rowsData[val.rowIndex].convertQuantity = Utility.roundingOff(
          val.rowData.convertQuantity,
          tenantInfo.decimalScale
        );
    }
    setData(rowsData);
  };

  const getBody = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={true}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        columns={localConfig}
        rows={data}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        title={''}
        allowBulkOperation={false}
      />
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window web-width-70"
          key={null}
          style={{
            maxWidth: '90%',
            width: 1040,
            maxHeight: '95%',
            padding: 0,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-size overflow-y-scroll hide-scroll-bar">
            <div className="column p-l parent-width">{getBody()}</div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default ConvertWorkOrder;
