import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import GenerateBankFileInvoiceService from '../../Services/GenerateBankFileInvoice';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchBankFileInvoices = createAsyncThunk('fetchBankFileInvoices', async () => {
  const response = await GenerateBankFileInvoiceService.getBankFileInvoices();
  return response;
});

export const GenerateBankFileInvoiceSlice = createSlice({
  name: 'generate-bank-file-invoice',
  initialState,
  reducers: {
    addGenerateBankFileInvoiceColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      const configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankFileInvoices.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBankFileInvoices.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchBankFileInvoices.rejected, (state) => {
        state.status = API_STATUS.IDLE;
        state.data = [];
      });
  }
});

export const { addGenerateBankFileInvoiceColumnConfig } = GenerateBankFileInvoiceSlice.actions;

export const selectGenerateBankFileInvoiceColumnConfig = (state: RootState) => state.generateBankFileInvoice.columnConfig;

export const selectBankFileInvoices = (state: RootState) => state.generateBankFileInvoice.data;
