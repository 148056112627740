import { ColumnOption } from "./ColumnOption"
import { ColumnStyle } from "./ColumnStyle"
import { HeaderColumn } from "./HeaderColumn"
export class ItemColumn {
    columnOption: ColumnOption
    name: string
    value: string
    type?: string
    style: ColumnStyle
    isHeaderColumn: boolean
    isTotalColumn: boolean
    isSkipColumn: boolean
    isGroupHeaderColumn: boolean
    constructor() {
        this.columnOption = new ColumnOption()
        this.name = ''
        this.value = ''
        this.style = new ColumnStyle()
        this.isHeaderColumn = false
        this.isTotalColumn = false
        this.isSkipColumn = false
        this.isGroupHeaderColumn = false
    }

    toHeaderColumn(): HeaderColumn {
        var itemColumn = new HeaderColumn()
        itemColumn.columnOption = this.columnOption
        itemColumn.name = this.name
        itemColumn.label = this.value
        itemColumn.style = this.style
        return itemColumn
    }
    init(columnOption: ColumnOption, name: string, value: string, style: ColumnStyle) {
        this.columnOption = columnOption
        this.name = name
        this.value = value
        this.style = style
    }
}