import { NUMBER_FORMAT } from '../Constants/Enum';
import { getLocaleFromTenantNumberFormat } from '../Utility/Utility';

abstract class NumberFormatService {
  static formatter: Intl.NumberFormat;

  static setNumberFormatter(numberFormat: NUMBER_FORMAT, decimalScale: number) {
    const locale =
      typeof numberFormat !== 'undefined'
        ? getLocaleFromTenantNumberFormat(numberFormat)
        : 'en-US';

    this.formatter = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits:
        typeof decimalScale !== 'undefined' ? decimalScale : 2,
      maximumFractionDigits:
        typeof decimalScale !== 'undefined' ? decimalScale : 2
    });
  }

  static getNumber(num: number) {
    if (num === undefined || num === null) {
      num = 0;
    }
    
    if (!this.formatter) {
      this.formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    return this.formatter.format(num);
  }

  static getPlainNumber(num: number) {
    const plainFormatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return plainFormatter.format(num);
  }
}

export default NumberFormatService;
