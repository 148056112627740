import { PROCESS_TYPES } from '../Components/Mrp/Constants/MRPEnums';
import { BomOperationRow } from '../Components/Mrp/RoutingTemplates/AddRoutingTemplate';
import ApiConstants from '../Constants/ApiConstants';
import { ERROR } from '../Constants/Constant';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface RoutingTemplateAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  Sort?: string;
  SortDir?: string;
  QueryParam?: any;
  QuerySummary?: string;
}

export const defaultConfig: RoutingTemplateAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  QuerySummary: ''
};

export interface IRoutingTemplate {
  id: number;
  name: string;
  description: string;
  operations: BomOperationRow[];
  products: string[];
}

class RoutingTemplateService {
  static apiConfig: RoutingTemplateAPIConfig;

  static abortController: any = null;

  static getRoutingTemplatesByPage() {
    Utility.cancelRequest(RoutingTemplateService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&query=${
      !Utility.isEmpty(this.apiConfig.Query) ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.ROUTING_TEMPLATE.FETCH_ROUTING_TEMPLATE + queryString;

    RoutingTemplateService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: RoutingTemplateService.abortController.signal
      })
      .then(
        (response: any) => {
          return !Utility.cancelRequestMessage(response)
            ? Promise.resolve(response)
            : {};
        },
        (error: any) => {
          return !Utility.cancelRequestMessage(error)
            ? Promise.reject(ERROR)
            : {};
        }
      )
      .catch((error: any) => {
        return !Utility.cancelRequestMessage(error)
          ? Promise.reject(ERROR)
          : {};
      });
  }

  static getRoutingTemplatesByProduct(productCode: string) {
    Utility.cancelRequest(RoutingTemplateService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    }&query=${
      !Utility.isEmpty(this.apiConfig.Query) ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.ROUTING_TEMPLATE.FETCH_ROUTING_TEMPLATE_BY_PRODUCT(
        productCode
      ) + queryString;

    RoutingTemplateService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: RoutingTemplateService.abortController.signal
      })
      .then(
        (response: any) => {
          return !Utility.cancelRequestMessage(response)
            ? Promise.resolve(response)
            : null;
        },
        (error: any) => {
          return !Utility.cancelRequestMessage(error)
            ? Promise.reject(ERROR)
            : null;
        }
      )
      .catch((error: any) => {
        return !Utility.cancelRequestMessage(error)
          ? Promise.reject(ERROR)
          : null;
      });
  }

  static addRoutingTemplate(payload: any) {
    const finalEndPoint =
      ApiConstants.URL.ROUTING_TEMPLATE.ADD_ROUTING_TEMPLATE;

    return http
      .post(finalEndPoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateRoutingTemplate(id: any, payload: any) {
    const finalEndPoint =
      ApiConstants.URL.ROUTING_TEMPLATE.UPDATE_ROUTING_TEMPLATE + `/${id}`;

    return http
      .put(finalEndPoint, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteRoutingTemplate(templateIds: any[]) {
    const finalEndPoint =
      ApiConstants.URL.ROUTING_TEMPLATE.DELETE_ROUTING_TEMPLATE;

    return http
      .delete(finalEndPoint, { data: templateIds })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default RoutingTemplateService;
