import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ImportState } from '../../Models/ReduxStore';
import ImportService from '../../Services/Import';
import { RootState } from '../Store';

const initialState: ImportState = {
  data: {} as any,
  status: API_STATUS.IDLE
};

export const fetchImport = createAsyncThunk('importData', async (data: any) => {
  const response = await ImportService.getFileMapping();
  return response;
});

export const fetchCustomImportFormats = createAsyncThunk('customImportFormats', async () => {
  const response = await ImportService.getCustomImportFormats();
  return response;
});

export const ImportSlice = createSlice({
  name: 'imports',
  initialState,
  reducers: { 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImport.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchImport.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.import = action.payload;
      })
      .addCase(fetchCustomImportFormats.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCustomImportFormats.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.customFormats = action.payload;
      });
  }
});

export const {
} = ImportSlice.actions;

export const selectImport = (state: RootState) => state.imports.import;

export const selectImportCustomFormats = (state: RootState) => state.imports.customFormats;

export default ImportSlice.reducer;
