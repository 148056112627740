import { IState } from '../../../Models/Interfaces';

export const stockAdjustmentInitialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export const StockInReasons = [
  { name: 'Returned', value: 'RETURNED' },
  { name: 'Promotion', value: 'PROMOTION' },
  { name: 'Stock Take', value: 'STOCKTAKE' },
  { name: 'Other', value: 'OTHER' }
];
export const StockOutReasons = [
  { name: 'Damaged', value: 'DAMAGED' },
  { name: 'Shrinkage', value: 'SHRINKAGE' },
  { name: 'Stock Take', value: 'STOCKTAKE' },
  { name: 'Other', value: 'OTHER' }
];

export enum STOCK_ADJUSTMENT_TYPE {
  STOCK_IN = 'STOCK_IN',
  STOCK_OUT = 'STOCK_OUT'
}

export const StockAdjustmentType = [
  { name: 'Stock In', value: STOCK_ADJUSTMENT_TYPE.STOCK_IN },
  { name: 'Stock Out', value: STOCK_ADJUSTMENT_TYPE.STOCK_OUT }
];

export const STOCK_ADJUSTMENT_ACTION_TYPE = {
  DELETE: 'DELETE',
  ALLOCATE: 'ALLOCATE'
};

export interface StockAdjustmentItems {
  stockItemNumber: string;
  productVariantCode: string;
  productType: string;
  quantity: number;
  perUnitValue: number;
  productName: string;
  serialNumbers: any;
  documentUom: any;
  documentUOMSchemaDefinition?: any;
  uomQuantity: number;
  uomUnitPrice: number;
  totalAmount: any;
  rowButtons: any;
  availableQuantity: number;
  productQuantity?: any;
  advancedTracking?: any;
  pid?: any;
  advancedTrackingMetaData?: any;
  batchNumber?: any;
  batchDetails?: any;
  availableQuantityInBaseUOM?: any;
  stockAdjustmentAccountCode?: any;
  stockAdjustmentAccount?: any;
  stockAdjustmentAccountName?: any;
  uomSchemaDto?: any;
  uomSchema?: any;
  stockUom?: any;
  isAllocated?: boolean;
  invalidFields?: string[];
  serialCustomFields?: any;
}

export interface StockAdjustment {
  autoNumberingFormat: string;
  warehouseCode: any;
  rowCode: any;
  rackCode: any;
  binCode: any;
  notes: string;
  stockAdjustmentItems: StockAdjustmentItems[];
  adjustmentDate: Date;
  adjustmentType: any;
  adjustmentReason: any;
  totalValue: number;
  customField: any[];
}

export interface IStockAdjustmentPayload {
  code?: string;
  warehouseCode?: string;
  adjustmentDate?: string;
  adjustmentType?: STOCK_ADJUSTMENT_TYPE;
  adjustmentReason: string;
  reason: string;
  totalValue?: number;
  notes: string;
  warehouseName?: null;
  stockAdjustmentItems?: IStockAdjustmentItem[];
  customField: any[];
  isQcEnabled?: boolean;
  rowCode?: null;
  rackCode?: null;
  binCode?: null;
  isApprovalFlowEnabled?: boolean;
  approvalConfig?: any;
}
export interface IStockAdjustmentItem {
  batchDetails?: any[];
  customField: any[];
  documentUom: number;
  productVariantCode: string;
  perUnitValue: number;
  productName: string;
  productType: string;
  quantity: number;
  serialNumbers?: any[];
  stockAdjustmentAccountCode: string;
  stockAdjustmentAccountName: string;
  documentUOMSchemaDefinition: any;
  uomQuantity: number;
  uomUnitPrice: number;
  stockItemNumber?: any;
  serialCustomFields?: any;
}
