import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import ProductionPlanService from '../../../Services/MRP/ProductionPlan';
import { RootState } from '../../Store';
interface ProductionPlanState {
  data: any;
  columnConfig: any;
  status: API_STATUS;
}
const initialState: ProductionPlanState = {
  data: {},
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchProductionPlan = createAsyncThunk(
  'mrpProductionPlan/fetchProductionPlan',
  async (payload: { params?: any } = {}) => {
    const response = ProductionPlanService.fetchProductionPlan(payload?.params);
    return response;
  }
);
export const MRPProductionPlanSlice = createSlice({
  name: 'mrpProductionPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductionPlan.pending, (state, action) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductionPlan.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = API_STATUS.IDLE;
      });
  }
});
export const selectApiStatus = (state: RootState) =>
  state.mrpProductionPlan.status;
export const selectProductionPlan = (state: RootState) =>
  state.mrpProductionPlan.data;
export const {} = MRPProductionPlanSlice.actions;
export default MRPProductionPlanSlice.reducer;
