import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';
import AssetGroupService from '../../Services/AssetGroup';

const initialState: GridState = {
  data: {},
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchAssetGroup = createAsyncThunk('assetGroup', async () => {
  const response = await AssetGroupService.getAssetGroupByPage();
  return response;
});

export const AssetGroupSlice = createSlice({
  name: 'Asset Group',
  initialState,
  reducers: {
    assetGroupColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.ASSET_GROUP
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToAssetGroupColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateAssetGroupColumnDetails: (state, action: PayloadAction<any>) => {
      const assetGroupDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const assetGroupIndex: number = content.findIndex(
        (contact: any) => contact.id === assetGroupDetails.id
      );
      if (assetGroupIndex >= 0) {
        content[assetGroupIndex] = assetGroupDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetGroup.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchAssetGroup.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToAssetGroupColumnConfig,
  assetGroupColumnConfig,
  updateColumnConfig,
  updateAssetGroupColumnDetails,
  updateRefreshCallback
} = AssetGroupSlice.actions;

export const selectAssetGroup = (state: RootState) => state.AssetGroup.data;

export const selectAssetGroupLoadingStatus = (state: RootState) =>
  state.AssetGroup.gridContentLoadingStatus;

export const selectAssetGroupColumnConfig = (state: RootState) =>
  state.AssetGroup.columnConfig;

export const selectAssetGroupColumnConfigTableId = (state: RootState) =>
  state.AssetGroup.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.AssetGroup.refreshCallback;

export default AssetGroupSlice.reducer;
