import { TableColumnPopupType } from "../../../Constants/Constants";
import { getIsShowTableColumn } from "../../../Utilities/Utility";

export class JobWorkOutOrderTableWidth {

    isLineNumberVisible: boolean;
    // isKindOfPackingVisible: boolean;
    isNameVisible: boolean;
    isNameWithDescriptionVisible: boolean;
    isQuantityVisible: boolean;
    isDescriptionVisible: boolean;
    isUomVisible: boolean;
    isQuantityWithUomVisible: boolean;
    isUnitPriceVisible: boolean;
    isProductDiscountVisible: boolean;
    // isDueOnVisible: boolean;
    // isRateVisible: boolean;
    // isPerVisible: boolean;
    isAmountVisible: boolean;
    isProductCustomFieldVisible: boolean;


    
    lineNumber: number;
    // kindOfPacking: number;
    name: number;
    description: number;
    nameWithDescription: number;
    // dueOn: number;
    quantity: number;
    uom: number;
    quantityWithUom: number;
    unitPrice: number;
    productDiscount: number;
    // rate: number;
    // per: number;
    amount: number;
    productCustomField: number;



    originalLineNumber: number;
    // originalKindOfPacking: number;
    originalName: number;
    originalNameWithDescription: number;
    originalDescription: number;
    // originalDueOn: number;
    originalQuantity: number;
    originalUom: number;
    originalQuantityWithUom: number;
    originalUnitPrice: number;
    originalProductDiscount: number;
    // originalRate: number;
    // originalPer: number;
    originalAmount: number;
    originalProductCustomField: number;

    productCustomFieldList: any;

    constructor() {
        this.isLineNumberVisible = true
        // this.isKindOfPackingVisible = true
        this.isNameVisible = true
        this.isDescriptionVisible = true
        this.isNameWithDescriptionVisible = false
        // this.isDueOnVisible = true
        this.isQuantityVisible = true
        this.isUomVisible = true
        this.isQuantityWithUomVisible = false
        this.isUnitPriceVisible = true
        this.isProductDiscountVisible = true
        // this.isRateVisible = true
        // this.isPerVisible = true
        this.isAmountVisible = true
        this.isProductCustomFieldVisible = true


        this.lineNumber = 5
        // this.kindOfPacking = 15
        this.name = 15
        this.description = 20
        this.nameWithDescription = this.name + this.description
        // this.dueOn = 10
        this.quantity = 5
        this.uom = 10
        this.quantityWithUom = this.quantity + this.uom
        this.unitPrice = 10
        this.productDiscount = 10
        // this.rate = 10
        // this.per = 10
        this.amount = 20
        this.productCustomField = 5


        this.originalLineNumber = 5
        // this.originalKindOfPacking = 15
        this.originalName = 15
        this.originalDescription = 20
        this.originalNameWithDescription = this.originalName  + this.originalDescription
        // this.originalDueOn = 10
        this.originalQuantity = 5
        this.originalUom = 10
        this.originalQuantityWithUom = this.originalQuantity + this.originalUom
        this.originalUnitPrice = 10
        this.originalProductDiscount = 10
        // this.originalRate = 10
        // this.originalPer = 10
        this.originalAmount = 20
        this.originalProductCustomField = 5

        this.productCustomFieldList = undefined
    }

    setIsShowWidth(columnData: any) {
        this.isLineNumberVisible = getIsShowTableColumn(columnData, TableColumnPopupType.lineNumber)
        // this.isKindOfPackingVisible = getIsShowTableColumn(columnData, TableColumnPopupType.kindOfPacking)
        this.isNameVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productName)
        this.isDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.description)
        this.isNameWithDescriptionVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productNameWithDescription)
        // this.isDueOnVisible = getIsShowTableColumn(columnData, TableColumnPopupType.dueOn)
        this.isQuantityVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantity)
        this.isUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.uom)
        this.isQuantityWithUomVisible = getIsShowTableColumn(columnData, TableColumnPopupType.quantityWithUom)
        this.isUnitPriceVisible = getIsShowTableColumn(columnData, TableColumnPopupType.unitPrice)
        this.isProductDiscountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productDiscount)
        // this.isRateVisible = getIsShowTableColumn(columnData, TableColumnPopupType.rate)
        // this.isPerVisible = getIsShowTableColumn(columnData, TableColumnPopupType.per)
        this.isAmountVisible = getIsShowTableColumn(columnData, TableColumnPopupType.amount)
        this.isProductCustomFieldVisible = getIsShowTableColumn(columnData, TableColumnPopupType.productCustomField)

        var width = 5
        var maxWidth = 20

        var productCustomFieldList = columnData.filter((x: any) => x.code !== undefined)
        if (productCustomFieldList.length > 0) {
            this.isProductCustomFieldVisible = false
            if (this.productCustomFieldList === undefined) {
                //assign product custom list with corresponding flag
                this.productCustomFieldList = []
                if (productCustomFieldList.length > 4) {
                    width = maxWidth / productCustomFieldList.length
                }
                productCustomFieldList.forEach((element: any) => {
                    var item = {} as any
                    item.code = element.code
                    item.isVisible = element.columnOption.isShowColumn
                    item.originalWidth = width
                    item.width = width
                    item.type = element.name
                    item.label = element.label
                    this.productCustomFieldList.push(item)
                });
            }
            else {
                this.productCustomFieldList.forEach((element: any) => {
                    var item = columnData.find((x: any) => x.code === element.code)
                    if (item !== undefined) {
                        element.isVisible = item.columnOption.isShowColumn
                    }
                });
            }
        }
    }

    getTableHeaderWidths() {
        var totalWidth = 0
        totalWidth = this.getTotalWidth(totalWidth);

        if (totalWidth === 100) {
            this.resetElementOriginalWidth();
        }
        else if (totalWidth === 0) {
            this.resetElementWidth();
        }
        else {
            var totalSelectedElement = this.getTotalSelectedElement()
            if (totalSelectedElement !== 0) {
                var totalOffset = (100 - totalWidth) / totalSelectedElement
                this.updateElementWidth(totalOffset)
            }
        }
    }
    private resetElementOriginalWidth() {
        this.lineNumber = this.originalLineNumber
        // this.kindOfPacking = this.originalKindOfPacking
        this.name = this.originalName
        this.description = this.originalDescription
        this.nameWithDescription = this.originalNameWithDescription
        // this.dueOn = this.originalDueOn
        this.quantity = this.originalQuantity
        this.uom = this.originalUom
        this.quantityWithUom = this.originalQuantityWithUom
        this.unitPrice = this.originalUnitPrice
        this.productDiscount = this.originalProductDiscount
        // this.rate = this.originalRate
        // this.per = this.originalPer
        this.amount = this.originalAmount
        this.productCustomField = this.originalProductCustomField


        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = element.originalWidth
            });
        }
    }

    private resetElementWidth() {
        this.lineNumber = 0
        // this.kindOfPacking = 0
        this.name = 0
        this.description = 0
        this.nameWithDescription = 0
        // this.dueOn = 0
        this.quantity = 0
        this.uom = 0
        this.quantityWithUom = 0
        this.unitPrice = 0
        this.productDiscount = 0
        // this.rate = 0
        // this.per = 0
        this.amount = 0
        this.productCustomField = 0

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                element.width = 0
            });
        }
    }

    private getTotalWidth(totalWidth: number) {
        if (this.isLineNumberVisible) {
            totalWidth += this.lineNumber;
        }
        // if (this.isKindOfPackingVisible) {
        //     totalWidth += this.kindOfPacking;
        // }
        if (this.isNameVisible) {
            totalWidth += this.name;
        }
        if (this.isDescriptionVisible) {
            totalWidth += this.description;
        }
        if (this.isNameWithDescriptionVisible) {
            totalWidth += this.nameWithDescription;
        }
        // if (this.isDueOnVisible) {
        //     totalWidth += this.dueOn;
        // }
        if (this.isQuantityVisible) {
            totalWidth += this.quantity;
        }
        if (this.isUomVisible) {
            totalWidth += this.uom;
        }
        if (this.isQuantityWithUomVisible) {
            totalWidth += this.quantityWithUom;
        }
        if (this.isUnitPriceVisible) {
            totalWidth += this.unitPrice;
        }
        if (this.isProductDiscountVisible) {
            totalWidth += this.productDiscount;
        }
        // if (this.isRateVisible) {
        //     totalWidth += this.rate;
        // }
        // if (this.isPerVisible) {
        //     totalWidth += this.per;
        // }
        if (this.isAmountVisible) {
            totalWidth += this.amount;
        }
        if (this.isProductCustomFieldVisible) {
            totalWidth += this.productCustomField;
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalWidth += element.width;
                }
            });
        }

        return totalWidth;
    }

    private getTotalSelectedElement() {
        var totalSelectedElement = 0
        if (this.isLineNumberVisible) {
            totalSelectedElement += 1
        }
        // if (this.isKindOfPackingVisible) {
        //     totalSelectedElement += 1
        // }
        if (this.isNameVisible) {
            totalSelectedElement += 1
        }
        if (this.isDescriptionVisible) {
            totalSelectedElement += 1
        }
        if (this.isNameWithDescriptionVisible) {
            totalSelectedElement += 1
        }
        // if (this.isDueOnVisible) {
        //     totalSelectedElement += 1
        // }
        if (this.isQuantityVisible) {
            totalSelectedElement += 1
        }
        if (this.isUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isQuantityWithUomVisible) {
            totalSelectedElement += 1
        }
        if (this.isUnitPriceVisible) {
            totalSelectedElement += 1
        }
        if (this.isProductDiscountVisible) {
            totalSelectedElement += 1
        }
        // if (this.isRateVisible) {
        //     totalSelectedElement += 1
        // }
        // if (this.isPerVisible) {
        //     totalSelectedElement += 1
        // }
        if (this.isAmountVisible) {
            totalSelectedElement += 1
        }

        if (this.isProductCustomFieldVisible) {
            totalSelectedElement += 1
        }
        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    totalSelectedElement += 1
                }
            });
        }
        return totalSelectedElement
    }

    private updateElementWidth(additionalWidth: number) {

        if (this.isLineNumberVisible) {
            this.lineNumber = this.originalLineNumber + additionalWidth
        }
        // if (this.isKindOfPackingVisible) {
        //     this.lineNumber = this.originalKindOfPacking + additionalWidth
        // }
        if (this.isNameVisible) {
            this.name = this.originalName + additionalWidth
        }
        if (this.isDescriptionVisible) {
            this.description = this.originalDescription + additionalWidth
        }
        if (this.isNameWithDescriptionVisible) {
            this.nameWithDescription = this.originalNameWithDescription + additionalWidth
        }
        // if (this.isDueOnVisible) {
        //     this.lineNumber = this.originalDueOn + additionalWidth
        // }
        if (this.isQuantityVisible) {
            this.quantity = this.originalQuantity + additionalWidth
        }
        if (this.isUomVisible) {
            this.uom = this.originalUom + additionalWidth
        }
        if (this.isQuantityWithUomVisible) {
            this.quantityWithUom = this.originalQuantityWithUom + additionalWidth
        }
        if (this.isUnitPriceVisible) {
            this.unitPrice = this.originalUnitPrice + additionalWidth
        }
        if (this.isProductDiscountVisible) {
            this.productDiscount = this.originalProductDiscount + additionalWidth
        }
        // if (this.isRateVisible) {
        //     this.lineNumber = this.originalRate + additionalWidth
        // }
        // if (this.isPerVisible) {
        //     this.lineNumber = this.originalPer + additionalWidth
        // }
        if (this.isAmountVisible) {
            this.amount = this.originalAmount + additionalWidth
        }

        if (this.isProductCustomFieldVisible) {
            this.productCustomField = this.originalProductCustomField + additionalWidth
        }

        if (this.productCustomFieldList !== undefined) {
            this.productCustomFieldList.forEach((element: any) => {
                if (element.isVisible) {
                    element.width = element.originalWidth + additionalWidth
                }
            });
        }
    }


    getProductCustomFieldWidthByCode(code: string) {
        if (Array.isArray(this.productCustomFieldList)) {
            var item = this.productCustomFieldList.find((x: any) => x.code === code)
            if (item !== undefined) {
                return item.width
            }
        }
        return undefined
    }
}