import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { Bill } from '../Models/Bill';
import { ExpenseBillPayload } from '../Models/ExpenseBill';
import { BulkDeleteResponse } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface BillAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: any;
  WithFilter?: any;
  WithoutFilter?: any;
  Sort?: string;
  SortDir?: string;
  SalesPerson?: number;
}

export const defaultConfig: BillAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  QuerySummary: '',
  WithFilter: '',
  Sort: 'purchaseInvoiceDate',
  SortDir: 'DESC',
  WithoutFilter: '',
  SalesPerson: 0
};
class BillService {
  static apiConfig: BillAPIConfig = defaultConfig;
  static abortController: any = null;

  static getBillsByPage() {
    Utility.cancelRequest(BillService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&query=${this.apiConfig.Query ? this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }${
      this.apiConfig.SalesPerson && this.apiConfig.SalesPerson !== 0
        ? '&salesperson=' + this.apiConfig.SalesPerson
        : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.BILLS.FETCH_BILLS + country + queryString;

    BillService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: BillService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getBills(query: string, limit: number, searchTerm: string) {
    const finalEndpoint =
      ApiConstants.URL.BILLS.FETCH_BILLS +
      `?query=${query}&limit=${limit}&search=${searchTerm}`;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error creating bill: ', err);
      return Promise.reject(err);
    });
  }

  static getBillsWithCountry(query: string, limit: number, searchTerm: string) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    const finalEndpoint =
      ApiConstants.URL.BILLS.FETCH_BILLS +
      country +
      `?query=${query}&limit=${limit}&search=${searchTerm}`;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error creating bill: ', err);
      return Promise.reject(err);
    });
  }

  static createBill(payload: Bill, country: string) {
    return http2
      .post(`${ApiConstants.URL.BILLS.CREATE_BILL}${country}`, payload)
      .catch((err: any) => {
        console.error('Error creating bill: ', err);
        return Promise.reject(err);
      });
  }

  static fetchBillDetails(billCode: string) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    return http
      .get(`${ApiConstants.URL.BILLS.FETCH_BILL_DETAILS(billCode, country)}`)
      .catch((err: any) => {
        console.error('Error fetching bill: ', err);
        return Promise.reject(err);
      });
  }

  static updateBill(payload: any, country: string) {
    const billCode: string = payload.id;
    return http2
      .put(
        ApiConstants.URL.BILLS.UPDATE_BILL + `${country}/${billCode}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating bill: ', err);
        return Promise.reject(err);
      });
  }

  static deleteBill(id: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    return http2
      .delete(`${ApiConstants.URL.BILLS.DELETE_BILL(id, country)}`)
      .catch((err: any) => {
        console.error('Error deleting bill: ', err);
        return Promise.reject(err);
      });
  }

  static createOpeningBill(payload: any) {
    return http
      .post(`${ApiConstants.URL.BILLS.CREATE_OPENINGBILL}`, payload)
      .catch((err: any) => {
        console.error('Error creating bill: ', err);
        return Promise.reject(err);
      });
  }

  static updateOpeningBill(payload: any, id: any) {
    const finalURL = ApiConstants.URL.BILLS.CREATE_OPENINGBILL + `/${id}`;
    return http.put(`${finalURL}`, payload).catch((err: any) => {
      console.error('Error creating bill: ', err);
      return Promise.reject(err);
    });
  }

  static getBillDetailsById(documentCode: any) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.BILL
    );
    const finalEndpoint = ApiConstants.URL.BILLS.FETCH_BILL_DETAILS(
      documentCode,
      country
    );
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error fetching bill: ', err);
      return Promise.reject(err);
    });
  }

  static createExpenseBill(payload: ExpenseBillPayload, country: string) {
    return http
      .post(`${ApiConstants.URL.BILLS.CREATE_BILL}${country}`, payload)
      .catch((err: any) => {
        console.error('Error creating expense bill: ', err);
        return Promise.reject(err);
      });
  }

  static getBillDetailsByCode(documentCode: any) {
    const finalEndpoint = ApiConstants.URL.BILLS.FETCH_BILL_DETAILS(
      documentCode,
      ''
    );
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error fetching bill: ', err);
      return Promise.reject(err);
    });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(ApiConstants.URL.BILLS.BULK_DELETE, config);
  }
  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.PURCHASE_INVOICE_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getBillsSummary() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    var queryString: string = `?search=${encodeURIComponent(this.apiConfig.SearchTerm || '')}`;
    queryString = queryString + '&' + 'query=module=PURCHASE_INVOICE';
    if (!Utility.isEmpty(this.apiConfig.QuerySummary)) {
      queryString = queryString + '&' + this.apiConfig.QuerySummary;
    }
    if (this.apiConfig.SalesPerson) {
      queryString = queryString + '&salesperson=' + this.apiConfig.SalesPerson;
    }
    queryString = queryString + '&' + 'eType=IC';

    const finalEndpoint: string =
      ApiConstants.URL.BILLS.BILLS_SUMMARY + queryString
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteOBBill(id: number) {
    return http
      .delete(`${ApiConstants.URL.BILLS.DELETE_OB_BILL(id)}`)
      .catch((err: any) => {
        console.error('Error deleting bill: ', err);
        return Promise.reject(err);
      });
  }
}

export default BillService;
