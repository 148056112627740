import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import StockTransferService from '../../Services/StockTransfer';
import StockRequestService from '../../Services/StockRequest';
import Utility, { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  productData: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

const stockRequestInitialState = {
  ...initialState
};

export const fetchStockRequest = createAsyncThunk('stockRequest', async () => {
  const response = await StockRequestService.getStockRequestByPage();
  return response;
});
// export const fetchStockTransferBrief = createAsyncThunk(
//   'stockTransferBrief',
//   async () => {
//     console.log('fetchProductBriefetchProductBrieff');
//     const response = await StockTransferService.fetchProductBrief();
//     return response;
//   }
// );

// export const fetchStockTransferProducts = createAsyncThunk(
//   'stockTransferProducts',
//   async () => {
//     const response = await StockTransferService.getStockTransferProducts();
//     console.log('QQQ');
//     return response;
//   }
// );

// export const deleteStockTransfer = createAsyncThunk(
//   'deleteStockTransfers',
//   async (stockTransferIds: string[]) => {
//     const response = await StockTransferService.deleteStockTransfer(stockTransferIds);
//     return response;
//   }
// );

export const StockRequestSlice = createSlice({
  name: 'stockRequest',
  initialState: stockRequestInitialState,
  reducers: {
    addStockTransferColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.STOCK_TRANSFER
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToStockTransferColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    addStockRequest: (state, action: PayloadAction<any>) => {
      let currentStockTransfers = { ...state.data };
      if (currentStockTransfers.content) {
        currentStockTransfers.content.push(action.payload);
      } else {
        currentStockTransfers.content = [action.payload];
      }
      state.data = currentStockTransfers;
    }
    // updateStockTransferDetails: (state, action: PayloadAction<any>) => {
    //   const stockTransferDetails = action.payload;
    //   const pageDetails: any = deepClone(state.data);
    //   const content: any[] = pageDetails.content;
    //   const stockTransferIndex: number = content.findIndex(
    //     (stockTransfer: any) => stockTransfer.id === stockTransferDetails.id
    //   );
    //   if (stockTransferIndex >= 0) {
    //     content[stockTransferIndex] = stockTransferDetails;
    //     state.data['content'] = [...content];
    //   }
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStockRequest.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchStockRequest.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
    // builder
    //   .addCase(fetchStockTransferProducts.pending, (state) => {
    //     state.status = API_STATUS.LOADING;
    //   })
    //   .addCase(fetchStockTransferProducts.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.productBriefData = action.payload;
    //   });
    // builder
    //   .addCase(fetchStockTransferBrief.pending, (state) => {
    //     state.status = API_STATUS.LOADING;
    //   })
    //   .addCase(fetchStockTransferBrief.fulfilled, (state, action) => {
    //     state.status = API_STATUS.IDLE;
    //     state.productBriefData = action.payload;
    //   });
  }
});

export const {
  addStockRequest,
  addStockTransferColumnConfig,
  addColumnToStockTransferColumnConfig,
  updateColumnConfig
} = StockRequestSlice.actions;

export const selectStockRequestColumnConfig = (state: RootState) =>
  state.stockRequest.columnConfig;
export const selectStockRequestColumnConfigTableId = (state: RootState) =>
  state.stockRequest.configTableId;

export const selectStockRequest = (state: RootState) => state.stockRequest.data;
export const selectStockTransferProduct = (state: RootState) =>
  state.stockRequest.productData;
export const selectStockTransferProductBrief = (state: RootState) =>
  state.stockRequest.productBriefData;

export default StockRequestSlice.reducer;
