import { GOOGLE_NO_TRANSLATE_CLASS } from '../Constants/Constant';

const CHINESE_LANG_KEYWORD = 'zh-CN';

const KEYWORDS_TRANSLATIONS = {
  UOM: {
    [CHINESE_LANG_KEYWORD]: '计量单位'
  },
  Save: {
    [CHINESE_LANG_KEYWORD]: '保存'
  },
  SAVE: {
    [CHINESE_LANG_KEYWORD]: '保存'
  },
  Bill: {
    [CHINESE_LANG_KEYWORD]: '账单'
  },
  BILL: {
    [CHINESE_LANG_KEYWORD]: '账单'
  },
  Bills: {
    [CHINESE_LANG_KEYWORD]: '账单'
  },
  'Bill of Material': {
    [CHINESE_LANG_KEYWORD]: '物料清单'
  },
  'Bill of Materials': {
    [CHINESE_LANG_KEYWORD]: '物料清单'
  },
  'Bill to': {
    [CHINESE_LANG_KEYWORD]: '记账到'
  },
  'Bill To': {
    [CHINESE_LANG_KEYWORD]: '记账到'
  },
  'Bill Date': {
    [CHINESE_LANG_KEYWORD]: '账单日期'
  },
  'Bill Type': {
    [CHINESE_LANG_KEYWORD]: '账单类型'
  },
  'Bills Summary': {
    [CHINESE_LANG_KEYWORD]: '账单摘要'
  },
  'New Bill': {
    [CHINESE_LANG_KEYWORD]: '新账单'
  },
  'Expense Bill': {
    [CHINESE_LANG_KEYWORD]: '费用账单'
  }
};

const KEYWORDS_TO_REPLACE = Object.keys(KEYWORDS_TRANSLATIONS).join('|');

type AVAILABLE_TRANSLATIONS = 'zh-CN';

export function registerTranslationMutationObserverOnAppRoot() {
  const targetNode = document.body as HTMLElement;
  if (!targetNode) return null;

  const callback: MutationCallback = (mutationList) => {
    const googleLanguageSelector = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    const selectedLanguage =
      googleLanguageSelector?.value as AVAILABLE_TRANSLATIONS;

    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes?.forEach((addedNode) =>
          replaceOrPreventTranslationForNodeByText(
            `(${KEYWORDS_TO_REPLACE})`,
            selectedLanguage,
            addedNode
          )
        );
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(targetNode, { childList: true, subtree: true });

  return () => observer.disconnect();
}

export function replaceOrPreventTranslationForNodeByText(
  searchText: string,
  languageSelected: AVAILABLE_TRANSLATIONS,
  searchNode?: Node
) {
  if (
    !searchText ||
    (searchNode as HTMLElement)?.classList?.contains(GOOGLE_NO_TRANSLATE_CLASS)
  ) {
    return;
  }

  try {
    let regex =
        typeof searchText === 'string'
          ? new RegExp(searchText, 'g')
          : searchText,
      childNodes = (searchNode || document.body).childNodes,
      childNodesCount = childNodes.length,
      excludes = 'style,link,script,object,iframe,img,a';

    while (childNodesCount--) {
      let currentNode = childNodes[childNodesCount];

      if (
        (currentNode as HTMLElement)?.classList?.contains(
          GOOGLE_NO_TRANSLATE_CLASS
        )
      )
        continue;

      if (
        currentNode.nodeType === 1 &&
        (excludes + ',').indexOf(currentNode.nodeName.toLowerCase() + ',') ===
          -1
      ) {
        replaceOrPreventTranslationForNodeByText(
          searchText,
          languageSelected,
          currentNode
        );
      }
      if (
        currentNode.nodeType !== 3 ||
        !regex.test((currentNode as Text).data)
      ) {
        continue;
      }
      let parent = currentNode.parentNode,
        frag = (function () {
          let textContent = (currentNode as Text).data,
            frag = document.createDocumentFragment();

          const wrap = document.createElement('span');
          wrap.classList.add(GOOGLE_NO_TRANSLATE_CLASS);

          if (
            KEYWORDS_TRANSLATIONS[
              textContent as keyof typeof KEYWORDS_TRANSLATIONS
            ]?.[languageSelected]
          ) {
            const textNode = document.createTextNode(
              KEYWORDS_TRANSLATIONS[
                textContent as keyof typeof KEYWORDS_TRANSLATIONS
              ][languageSelected]
            );
            wrap.appendChild(textNode);
          } else {
            wrap.innerHTML = textContent;
          }

          frag.appendChild(wrap);

          return frag;
        })();
      parent?.insertBefore(frag, currentNode);
      parent?.removeChild(currentNode);
    }
  } catch (err) {
    console.error('Error while findAndPreventTranslationForNodeByText', err);
  }
}
