import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import ContactService from '../../Services/Contact';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload } from '../../Models/ReduxStore';
import { deepClone } from '../../Utility/Utility';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';

export interface ContactsState {
  data: any;
  columnConfig: IColumn[];
  configTableId: string;
  status: API_STATUS;
  contactSummary: any;
  formData: {
    liability: any;
    assets: any;
    paymentTerm: any[];
    sequenceFormats: any[];
    businessUnits: any[];
  };
}

const initialState: ContactsState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  contactSummary: {},
  formData: {
    liability: [],
    assets: [],
    paymentTerm: [],
    sequenceFormats: [],
    businessUnits: []
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchContacts = createAsyncThunk(
  'contacts',
  async (country?: string) => {
    const response = await ContactService.getContactsByPage();
    return response;
  }
);

export const fetchContactSummary = createAsyncThunk(
  'contactSummary',
  async () => {
    const response = await ContactService.getContactSummary();
    return response;
  }
);

export const fetchLiability = createAsyncThunk('liability', async () => {
  const response = await ContactService.getPayableAccountsInfo();
  return response;
});

export const fetchAsset = createAsyncThunk('assest', async () => {
  const response = await ContactService.getReceiveableAccountsInfo();
  return response;
});

export const fetchPaymentTerm = createAsyncThunk('paymentTerm', async () => {
  const response = await ContactService.getPaymentTermsInfo();
  return response;
});

export const fetchBusinessUnits = createAsyncThunk(
  'businessUnits',
  async () => {
    const response = await ContactService.fetchContactBusinessUnit();
    return response;
  }
);

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ContactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    addContactColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.CONTACT
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToContactColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateContactDetails: (state, action: PayloadAction<any>) => {
      const contactDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const contactIndex: number = content.findIndex(
        (contact: any) => contact.id === contactDetails.id
      );
      if (contactIndex >= 0) {
        content[contactIndex] = contactDetails;
        state.data['content'] = [...content];
      }
    },
    addContact: (state, action: PayloadAction<any>) => {
      // check if contact.code is already present in the list
      const newContact = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const contactIndex: number = content.findIndex(
        (contact: any) => contact.code === newContact.code
      );
      if (contactIndex >= 0) {
        content[contactIndex] = newContact;
      } else {
        content.push(newContact);
      }
      state.data['content'] = [...content];
    },
    addContacts: (state, action: PayloadAction<any[]>) => {
      const newContacts = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const contactCodeMap = new Map();
      content.forEach((contact) => {
        contactCodeMap.set(contact.code, contact);
      });
      newContacts.forEach((contact) => {
        if(!contactCodeMap.has(contact.code)){
          content.push(contact);
        }
      });
      state.data['content'] = [...content];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchLiability.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchLiability.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.formData.liability = action.payload;
      })
      .addCase(fetchAsset.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAsset.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.formData.assets = action.payload;
      })
      .addCase(fetchPaymentTerm.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPaymentTerm.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const payload: any = action.payload;
        const content: any = payload['content'];
        state.formData.paymentTerm = content;
      })
      .addCase(fetchContactSummary.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchContactSummary.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.contactSummary = action.payload;
      })
      .addCase(fetchBusinessUnits.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBusinessUnits.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const payload: any = action.payload;
        const content: any = payload['content'];
        state.formData.businessUnits = content;
      });
  }
});

export const {
  addContactColumnConfig,
  addColumnToContactColumnConfig,
  updateColumnConfig,
  updateContactDetails,
  addContact,
  addContacts
} = ContactsSlice.actions;

export const selectContacts = (state: RootState) => state.contacts.data;

export const selectContactsLoadingStatus = (state: RootState) =>
  state.contacts.status;

export const selectContactSummary = (state: RootState) =>
  state.contacts.contactSummary;

export const selectLiabilities = (state: RootState) =>
  state.contacts.formData.liability;

export const selectAssests = (state: RootState) =>
  state.contacts.formData.assets;

export const selectPaymentTerms = (state: RootState) =>
  state.contacts.formData.paymentTerm;

export const selectContactsColumnConfig = (state: RootState) =>
  state.contacts.columnConfig;

export const selectContactsColumnConfigTableId = (state: RootState) =>
  state.contacts.configTableId;

export const selectSequenceFormat = (state: RootState) =>
  state.contacts.formData.sequenceFormats;

export const selectContactTableId = (state: RootState) =>
  state.contacts.configTableId;

export const businessUnitData = (state: RootState) =>
  state.contacts.formData.businessUnits;

export default ContactsSlice.reducer;
