import { DKButton, DKDataGrid, DKLabel, INPUT_TYPE } from 'deskera-ui-library';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { useEffect, useState } from 'react';
import { IState } from '../../../Models/Interfaces';
import Utility from '../../../Utility/Utility';
import {
  BOOKS_DATE_FORMAT,
  DATE_FORMAT,
  TRANSITION_STATUS
} from '../../../Constants/Constant';
import DateFormatService from '../../../Services/DateFormat';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';

const TransactionHistory = (props: any) => {
  const initialState: IState = {
    columnData: [],
    rowData: [],
    originalData: [],
    filter: []
  };
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [transactionHistory, setTransactionHistory] = useState(initialState);

  useEffect(() => {
    if (!Utility.isEmpty(props)) {
      setTransactionHistory({
        ...transactionHistory,
        columnData: [...props?.columnData],
        rowData: [...props?.rows].map((rowData: any) => {
          let status = rowData?.state
            ? `${TRANSITION_STATUS.getByName(rowData?.state)}`
            : 'NA';
          let time = `${DateFormatService.getDateStrFromDate(
            new Date(rowData.time),
            tenantInfo.dateFormat
              ? tenantInfo.dateFormat
              : BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )} ${Utility.formatDate(rowData.time, DATE_FORMAT.HH_MM_SS)}`;
          return {
            ...rowData,
            status: status,
            time: Utility.isEmpty(time?.trim()) ? 'NA' : time,
            expanded: false,
            rowContextMenu: []
          };
        })
      });
    }
  }, [props]);

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1, left: 0 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky "
      >
        <div className="row">
          <DKLabel text={'Tracking Logs'} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              props?.onClose();
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: 550,
            width: '90%',
            maxHeight: '50%',
            borderRadius: 4,
            overflowY: 'hidden',
            zIndex: 9999
          }}
        >
          {getHeader()}
          <div
            className="column parent-width flex-1"
            style={{ overflowY: 'auto', padding: '24px' }}
          >
            <DKDataGrid
              allowRowEdit={false}
              allowColumnEdit={false}
              allowRowAdd={false}
              allowColumnAdd={false}
              allowColumnShift={true}
              allowSearch={false}
              allowFilter={false}
              refresh={false}
              rows={
                transactionHistory?.rowData?.length
                  ? transactionHistory?.rowData
                  : []
              }
              columns={
                transactionHistory?.columnData?.length
                  ? transactionHistory.columnData
                  : []
              }
              actionColumnWidth={120}
              allowBulkOperation={false}
              needTrailingColumn={false}
            />
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default TransactionHistory;
