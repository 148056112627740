export const DKComponentListTableHeader = {
    header: {
        productCode: {
            key: 'productDocumentSequenceCode',
            label: 'product_code',
            width: '20%',
            textAlign: 'left'
        },
        productName: {
            key: 'productName',
            label: 'products',
            width: '20%',
            textAlign: 'left'
        },
        productDescription: {
            key: 'productDescription',
            label: 'description',
            width: '25%',
            textAlign: 'left',
        },
        quantity: {
            key: 'quantityRequired',
            label: 'qty',
            width: '20%',
            textAlign: 'right',
            isColSpan: false
        },
        cost: {
            key: 'totalCost',
            label: 'cost',
            width: '15%',
            textAlign: 'right',
            isColSpan: false
        },
    }
}