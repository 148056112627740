import React, { Component } from 'react';
import { getLocalisedText } from '../Translate/LanguageManager';
import { ProductTable } from './dktable/product/ProductTable';
import { getBottomBorderColor, getBottomBorderWidth, getFontWeight, getTableFontStyleProp } from './dktable/TableUtility';
import Utility, { getFontSizeClass, getIsSOACustomer, getIsSOAVendor, getVW } from '../Utilities/Utility';
import NumberUtility from '../Utilities/NumberUtility';

export default class DKTransactionDocumentSection extends Component {
    tableOption = ProductTable.getTableOption(!this.props.data.isReadOnlyMode)

    constructor(props) {
        super(props)
        this.state = {
            currentRowIndex: 0
        }
    }

    render() {
        const data = this.props.data?.invoiceDueSummaryData ?? []

        if(data.length > 0) {
            if (getIsSOACustomer(this.props.data.type) || getIsSOAVendor(this.props.data.type)) {
                return this.renderTransactionDocumentTable()
            }
        }
        return <></>
    }

    renderTransactionDocumentTable() {
        const { style } = ProductTable.getDefaultItemColumn(this.props.tableStyle, 0, false, this.props.data.printScale);

        return (
            <div className='parent-width pt-l'>
                <div>
                    {this.getTableHeaders()}
                </div>
                <div style={{
                    borderWidth: style.borderWidth,
                    borderColor: style.borderColor,
                    borderStyle: style.borderStyle,
                    borderTop: 'none'
                }}>
                    <table className='parent-width' style={{ borderSpacing: 0 }}>
                        {this.getTableRowHeader()}
                        {this.getTableData()}
                    </table>
                </div>
            </div>
        );
    }


    getTableHeaders() {
        const { style } = ProductTable.getDefaultHeaderColumn(this.props.tableStyle, false, this.props.data.printScale);

        let title = ''
        
        if(getIsSOACustomer(this.props.data.type)) {
            title = getLocalisedText('invoice_summary')
        }
        else if (getIsSOAVendor(this.props.data.type)) {
            title = getLocalisedText('bill_summary')
        }
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    background: style.backgroundColor,
                    textAlign: 'left',
                    textOverflow: 'clip ellipsis',
                    borderWidth: style.borderWidth,
                    borderColor: style.borderColor,
                    borderStyle: style.borderStyle,
                    borderBottomColor: getBottomBorderColor(style) ?? undefined,
                    borderBottomWidth: getBottomBorderWidth(style) ?? undefined,
                    borderCollapse: 'collapse',
                    ...getTableFontStyleProp({ fontSize: style.fontSize }, this.props.data.printScale),
                    fontWeight: getFontWeight(style.fontWeight),
                    verticalAlign: style.verticalAlignment
                }}
            >
                <div style={{
                    paddingTop: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingTop ?? 10, this.props.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingBottom ?? 10, this.props.data.printScale)),
                    paddingLeft: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingLeft ?? 6, this.props.data.printScale)),
                    paddingRight: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingRight ?? 13, this.props.data.printScale)),
                }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            ...getTableFontStyleProp({ fontSize: style.fontSize, color: style.fontColor }, this.props.data.printScale),
                            fontStyle: style.fontStyle,
                            paddingLeft: 0
                        }}
                    >
                        {title}
                    </text>
                </div>
            </div>
        );
    }

    getDataCell(value, style, config) {
        return (
            <td
                className='cell'
                colSpan={config?.colSpan}
                style={{
                    borderTopWidth: style.lastRowTopWidth ?? style.borderWidth,
                    borderTopColor: style.lastRowTopColor ?? style.borderColor,
                    borderTopStyle: style.borderStyle,
                    textOverflow: 'clip ellipsis',
                    paddingTop: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingTop ?? 10, this.props.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingBottom ?? 10, this.props.data.printScale)),
                    paddingLeft: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingLeft ?? 6, this.props.data.printScale)),
                    paddingRight: getVW(Utility.convertToPrintScale(this.props.tableStyle.paddingRight ?? 13, this.props.data.printScale)),
                    backgroundColor: style.footerBackgroundColor ?? undefined
                }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: style.justifyContent ?? 'flex-end',
                    alignItems: 'center'
                }}>
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            ...getTableFontStyleProp({ fontSize: style.fontSize, color: style.fontColor }, this.props.data.printScale),
                            textAlign: 'center',
                            fontStyle: style.fontStyle,
                            fontWeight: getFontWeight(style.fontWeight),
                            direction: Utility.getIsSaudiArabiaCountry(this.props.data) ? 'rtl' : 'ltr'
                        }}
                    >
                        {value}
                    </div>
                </div>
            </td>
        );
    }


    getTableRowHeader() {
        const { style } = ProductTable.getDefaultItemColumn(this.props.tableStyle, 0, false, this.props.data.printScale);
        const totalRowStyle = {
            ...style,
            fontWeight: 'bold',
            justifyContent: 'flex-start',
        }

        let title = getLocalisedText('document_number')
        if (getIsSOACustomer(this.props.data.type)) {
            title = getLocalisedText('invoice_number')
        }
        else if (getIsSOAVendor(this.props.data.type)) {
            title = getLocalisedText('bill_number')
        }

        return (
            <thead>
                <tr>
                    {this.getDataCell(title, totalRowStyle)}
                    {this.getDataCell(getLocalisedText('due_date'), totalRowStyle)}
                    {this.getDataCell(getLocalisedText('amount_due'), { ...totalRowStyle, justifyContent: 'flex-end' })}
                </tr>
            </thead>
        );
    }

    getTableData() {
        const { style } = ProductTable.getDefaultItemColumn(this.props.tableStyle, 0, false, this.props.data.printScale);
        const totalRowStyle = {
            ...style,
            lastRowTopWidth: this.props.tableStyle.lastRowTopWidth ?? undefined,
            lastRowTopColor: this.props.tableStyle.lastRowTopColor ?? undefined,
            fontColor: this.props.tableStyle.footerTextColor ?? undefined,
            footerBackgroundColor: this.props.tableStyle.footerBackgroundColor ?? undefined
        }

        let rows = undefined
        const data = this.props.data?.invoiceDueSummaryData ?? []
        var currency = NumberUtility.getCurrency(this.props.data)
        let totalBalance = 0
        if (data.length > 0) {
            rows = data.map(element => {
                const dueAmount = element.dueAmount ?? 0
                totalBalance += dueAmount
                return <tr>
                    {this.getDataCell(element.documentSequenceCode ?? '', { ...style, justifyContent: 'flex-start' })}
                    {this.getDataCell(element.dueDate ?? '', { ...style, justifyContent: 'flex-start' })}
                    {this.getDataCell(Utility.toCurrencyFormat(element.dueAmount ?? 0, currency), { ...style, justifyContent: 'flex-end' })}
                </tr>
            });
        }

        let totalRow = <tr>
            {this.getDataCell(getLocalisedText('total_pending_balance'), { ...totalRowStyle, justifyContent: 'flex-start', fontWeight: 'bold' })}
            {this.getDataCell('', totalRowStyle)}
            {this.getDataCell(Utility.toCurrencyFormat(totalBalance, currency), { ...totalRowStyle, justifyContent: 'flex-end' })}
        </tr>

        return (
            <tbody>
                {rows}
                {totalRow}
            </tbody>
        );
    }
}
