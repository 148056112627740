import {
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { Invoice } from '../../Models/Invoice';
import DateFormatService from '../../Services/DateFormat';
import {
  addOperationsAndAdditionalCostToBomComponentGroups,
  defaultAdditionalChargesObject,
  getContactObjWhenDocIsLoading,
  getTenantTaxSystem,
  updateAvailableQtyBasedOnUOM
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const INVOICE_NOW_TYPES = {
  FAILED: 'FAILED',
  GENERATED: 'GENERATED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED'
};

export const EWAY_BILL_STATUS = {
  GENERATION_FAILED: 'GENERATION_FAILED',
  GENERATED: 'GENERATED',
  CANCELLED: 'CANCELLED'
};
export const invoiceEmailTemplateConfig = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Invoice Date',
    action: 'invoiceDate'
  },
  {
    hidden: false,
    text: 'Invoice Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Due Date',
    action: 'dueDate'
  },
  {
    hidden: false,
    text: 'Due Amount',
    action: 'dueAmount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Invoice Number',
    action: 'invoiceNumber'
  }
];

export const setItemsFromSalesInvoiceItems = (doc: any, docMode: any) => {
  let contactObj = getContactObjWhenDocIsLoading(doc);
  const document = {
    ...doc,
    items: doc.salesInvoiceItems.map((item: any) => {
      const compGroupDetails =
        Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
        item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        Utility.isNotEmpty(item?.bomComponentGroupDetails)
          ? {
              ...item?.bomComponentGroupDetails,
              bomComponentGroups:
                addOperationsAndAdditionalCostToBomComponentGroups(item)
            }
          : null;
      return {
        ...item,
        availableQuantity: item.availableQuantity,
        availableQtyByUOM: updateAvailableQtyBasedOnUOM(
          item,
          item.availableQuantity
        ),
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive,
        documentSequenceCode: item?.product?.documentSequenceCode,
        revRecStartDate: item.revRecDocumentItemDetails?.startDate
          ? DateFormatService.convertDDMMYYYToDate(
              item.revRecDocumentItemDetails?.startDate
            )
          : null,
        revRecEndDate: item.revRecDocumentItemDetails?.endDate
          ? DateFormatService.convertDDMMYYYToDate(
              item.revRecDocumentItemDetails?.endDate
            )
          : null,
        bomComponentGroupDetails: compGroupDetails,
        unmodifiedBomComponentGroupDetails: compGroupDetails
      };
    }),
    contact: contactObj,
    documentType: DOC_TYPE.INVOICE,
    documentDate: doc.salesInvoiceDate,
    validTillDate: doc.salesInvoiceDueDate,
    orderReference: doc?.poNumber,
    fulfillmentDate: doc.shipByDate,
    oldDueAmount: doc.dueAmount,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  if((docMode === DOCUMENT_MODE.VIEW || docMode === DOCUMENT_MODE.EDIT) && Utility.isNotEmpty(doc?.contact?.name)){
    document.contact = {...document?.contact,name: doc?.contact?.name ?? document?.contact?.name ?? '' }
  }  
  return document;
};

export const getUpdatedInvoiceObject = (props: Invoice) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.INVOICE,
    currencyCode: props.currency,
    documentCode: Utility.getValue(props.documentCode, props.salesInvoiceCode),
    openingInvoice: props.openingInvoice || false,
    orderReference: props?.poNumber,
    fulfillmentStatus:
      props.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
    fulfillmentType: props.fulfillmentType,
    autoFulfill: props.autoFulfill,
    documentDate: Utility.getValue(props.documentDate, props.salesInvoiceDate),
    validTillDate: Utility.getValue(
      props.validTillDate,
      props.salesInvoiceDueDate
    ),
    fulfillmentDate: Utility.getValue(props.fulfillmentDate, props.shipByDate),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    backOrder: props.backOrder ? props.backOrder : false,
    paymentStatus: props.paymentStatus,
    items: props.salesInvoiceItems?.map((item: any) => {
      return {
        ...item,
        availableQtyByUOM: updateAvailableQtyBasedOnUOM(
          item,
          item.availableQuantity
        ),
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentSequenceCode: item.product?.documentSequenceCode,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.quotationItemCode,
          item.salesInvoiceItemCode
        )
      };
    }),
    taxInvoiceNo: props.taxInvoiceNo,
    whtRate: props.whtRate && props.whtRate !== null ? props.whtRate : 0,
    paymentInformation: props.paymentInformation
      ? props.paymentInformation
      : null,
    einvoiceInfoIndia: props.einvoiceInfoIndia,
    einvoiceInfoIndiaCancel: props.einvoiceInfoIndiaCancel,
    isCancelEinvoice: props.isCancelEinvoice,
    reservedStock: props.reservedStock ? props.reservedStock : false
  };

  return updatedObj;
};

export const getInvoiceNowStatus = (invoice: any) => {
  if (invoice) {
    if (
      invoice.einvoiceInfoIndia &&
      (invoice.einvoiceInfoIndia.error ||
        invoice.einvoiceInfoIndia.errorDetails)
    ) {
      return INVOICE_NOW_TYPES.FAILED;
    } else if (
      !invoice.isCancelEinvoice &&
      invoice.einvoiceInfoIndia &&
      invoice.einvoiceInfoIndia.status === '1'
    ) {
      return INVOICE_NOW_TYPES.GENERATED;
    } else if (!invoice.einvoiceInfoIndia) {
      return INVOICE_NOW_TYPES.PENDING;
    } else if (
      invoice.isCancelEinvoice &&
      invoice.einvoiceInfoIndiaCancel &&
      invoice.einvoiceInfoIndiaCancel.status === '1'
    ) {
      return INVOICE_NOW_TYPES.CANCELLED;
    }
  }
};

export const getInvoiceImportOptions = (tenantInfo: any) => {
  if (Utility.isSupportedCountriesForImport(tenantInfo.country)) {
    return [
      'Invoice',
      'Get Paid',
      'QB Invoice',
      tenantInfo.country === COUNTRY_CODES.IN ? 'Delivery Order' : 'Fulfillment'
    ];
  } else {
    return ['Invoice']
  }
};
