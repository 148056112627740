import { getLocalisedText } from "../Translate/LanguageManager"

export default class DebitNoteMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('debit_note')
        label.documentDate = getLocalisedText('document_date')
        label.accounts = 'accounts'
        label.accountName = 'account_name'
        label.accountDescription = 'account_description'
        label.taxAmount = 'tax_amount'
        label.lineAmount = 'line_amount'
        label.contact = 'contact'
        label.contactAddressPlaceholder = 'contact_address_placeholder_text_value'
        return label
    }
}