import { useEffect, useState } from 'react';
import {
  FORM_ELEMENTS,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE
} from '../../../Constants/Constant';
import { useAppDispatch } from '../../../Redux/Hooks';
import InvoiceService from '../../../Services/Invoice';
import Utility, { deepClone } from '../../../Utility/Utility';
import { fetchUom } from '../../../Redux/Slices/UomSlice';
import { CallBackPayloadType } from '../../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import {
  DKLabel,
  DKInput,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import { fetchUOMs } from '../../../Redux/Slices/CommonDataSlice';

enum UOM_FIELD_KEY_NAMES {
  NAME = 'name',
  DISCRIPTION = 'description'
}

interface InputTag<KeyType> {
  value: string | number;
  hidden: boolean;
  key: KeyType;
  type: FORM_ELEMENTS;
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
}
interface AddUomProps {
  populateFormData: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

interface addUomState {
  id: number;
  name: InputTag<UOM_FIELD_KEY_NAMES>;
  description: InputTag<UOM_FIELD_KEY_NAMES>;
}

const initialState = {
  id: 1,
  name: {
    key: UOM_FIELD_KEY_NAMES.NAME,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: 'Name cannot be left empty'
  },
  description: {
    key: UOM_FIELD_KEY_NAMES.DISCRIPTION,
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    defaultValue: '',
    isMandatory: false
  }
};
const AddUomPopUp: React.FC<AddUomProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [formState, setFormState] = useState<addUomState>(
    deepClone(initialState)
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    const stateNew = formState;

    if (stateNew.name) {
      if (props.populateFormData?.name) {
        const name: string = props.populateFormData.name;
        stateNew.name.value = name;
      }
    }

    if (stateNew.description) {
      if (props.populateFormData?.description) {
        const description: string = props.populateFormData.description;
        stateNew.description.value = description;
      }
    }
    if (props.populateFormData && props.populateFormData.id) {
      stateNew.id = props.populateFormData.id;
    }

    setFormState({ ...stateNew });
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.ADD_UOM,
        data: () => {
          payloadData();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.EDIT_UOM,
        data: () => {
          payloadDataForEdit();
        }
      });
    }
  };

  const formValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(formState);
    // name
    if (
      updatedState.name.isMandatory &&
      (!updatedState.name.value ||
        updatedState.name.value.toString().trim().length === 0)
    ) {
      updatedState.name.hasError = true;
      updatedState.name.errorMsg = 'Name is mandatory';
      formHasError = true;
    }

    if (formHasError) {
      setFormState({ ...updatedState });
      return false;
    }
    return true;
  };

  const payloadDataForEdit = () => {
    const isFormValid = formValidation();
    if (!isFormValid) {
      return;
    }
    if (formState.name.value?.toString()?.length > 50) {
      showAlert('Error!', `Value too long for field 'Name' (maximum size 50).`);
      return;
    }
    const payload = {
      name: formState.name.value,
      description: formState.description.value
    };

    InvoiceService.editUom(payload, formState.id)
      .then((response) => {
        showToast('UOM updated sucessfully', TOAST_TYPE.SUCCESS);
        dispatch(fetchUom(25));
        dispatch(fetchUOMs());
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
      })
      .catch((error) => {
        if (error && error.data && error.data.code === 500) {
          let message = error.data.errorMessage;
          showAlert('', message);
        } else {
          console.error('Error editing Uom: ', error);
        }
      });
  };

  const payloadData = () => {
    const isFormValid = formValidation();
    if (!isFormValid) {
      return;
    }
    if (formState.name.value?.toString()?.length > 50) {
      showAlert('Error!', `Value too long for field 'Name' (maximum size 50).`);
      return;
    }
    const payload = {
      name: formState.name.value,
      description: formState.description.value
    };
    InvoiceService.postUom(payload)
      .then((response) => {
        showToast('UOM created sucessfully', TOAST_TYPE.SUCCESS);
        dispatch(fetchUom(25));
        dispatch(fetchUOMs());
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
      })
      .catch((error) => {
        if (error && error.data && error.data.code === 500) {
          let message = error.data.errorMessage;
          showAlert('', message);
        } else {
          console.error('Error editing Uom: ', error);
        }
      });
  };

  const updateInputField: any = (event: string, field: string) => {
    const newState = formState;
    if (newState && field === 'name') {
      newState.name.hasError = false;
      if (!event || event.trim().length === 0) {
        newState.name.hasError = true;
        newState.name.errorMsg = 'Please Enter Valid Name';
      }
      newState.name.value = event;
    }
    if (newState && field === 'description') {
      newState.description.value = event;
    }
    setFormState({ ...newState });
  };

  return (
    <div className="p-2">
      <div style={{ height: 60 }}>
        <DKInput
          title={t(`SETTINGS.UOM.NAME`)}
          required={true}
          canValidate={formState.name.isMandatory}
          validator={(value: string) => {
            return !formState.name.hasError;
          }}
          errorMessage={formState.name.errorMsg}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formState?.name.value}
          onChange={(event: string) => {
            updateInputField(event, 'name');
          }}
        />
      </div>
      <div style={{ height: 60 }}>
        <DKInput
          title={t(`SETTINGS.UOM.DESCRIPTION`)}
          required={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          value={formState?.description.value}
          onChange={(event: string) => updateInputField(event, 'description')}
        />
      </div>
    </div>
  );
};
export default AddUomPopUp;
