import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import QualityCheckService from '../../Services/QualityCheck';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchQualityChecks = createAsyncThunk(
  'qualityChecks',
  async () => {
    try {
      const response = await QualityCheckService.getQualityChecksByPage();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const QualityChecksSlice = createSlice({
  name: 'qualityChecks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQualityChecks.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchQualityChecks.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectQualityChecks = (state: RootState) =>
  state.qualityChecks.data;

export const selectQualityChecksLoadingStatus = (state: RootState) =>
  state.qualityChecks.gridContentLoadingStatus;
