export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE = 0;
export enum SORT_DIRECTION {
    ASC = 'asc',
    DESC = 'desc',
}
export enum WEEKDAYS {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}
export const WEEKDAYS_NAMES = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

/**
 *@description Given a start date, end date and day name, return an array of dates between the two dates for the given day inclusive
* @param {Date} start - date to start from
* @param {Date} end - date to end on
* @param {string} day - index of the day to get dates for
* @returns {Array} array of Dates
*/
export function getDaysBetweenDates(start: Date, end: Date, day: WEEKDAYS): Date[] {
    let result = [];
    let current = new Date(start);
    current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
    while (current < end) {
        result.push(new Date(+current));
        current.setDate(current.getDate() + 7);
    }
    return result;
}
