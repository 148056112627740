import Utility from '../../Utility/Utility';

export enum WAREHOUSE_TYPE {
  REJECTED = 'REJECTED',
  QA = 'QA',
  NONE = 'NONE',
  JOB_WORK_OUT = 'JOB_WORK_OUT'
}
export default class WarehouseManagementHelper {
  static getUniqueRRBDtoFromProductCode = (data: any, productCode: any) => {
    return data?.filter((item: any) => {
      return item.productCode === productCode;
    });
  };

  static getUniqueRows = (dto: any, rackCode: any, binCode: any) => {
    let updatedDTO = dto?.filter((obj: any) => {
      if (!Utility.isEmpty(rackCode) && !Utility.isEmpty(binCode)) {
        return obj.rackCode === rackCode && obj.binCode === binCode;
      }
      if (Utility.isEmpty(rackCode) && Utility.isEmpty(binCode)) {
        return true;
      }
      if (Utility.isEmpty(rackCode)) {
        return obj.binCode === binCode;
      }
      if (Utility.isEmpty(binCode)) {
        return obj.rackCode === rackCode;
      }
      return true;
    });

    let result: any = [];
    updatedDTO?.forEach((ele: any) => {
      let tempVar = result.filter((i: any) => i.rowCode === ele.rowCode);

      if (Utility.isEmpty(tempVar) && !Utility.isEmpty(ele?.rowCode)) {
        result.push(ele);
      }
    });

    return result;
  };

  static getUniqueRacks = (dto: any, rowCode: any, binCode: any) => {
    let updatedDTO = dto?.filter((obj: any) => {
      if (!Utility.isEmpty(rowCode) && !Utility.isEmpty(binCode)) {
        return obj.rowCode === rowCode && obj.binCode === binCode;
      }
      if (Utility.isEmpty(rowCode) && Utility.isEmpty(binCode)) {
        return true;
      }
      if (Utility.isEmpty(rowCode)) {
        return obj.binCode === binCode;
      }
      if (Utility.isEmpty(binCode)) {
        return obj.rowCode === rowCode;
      }
      return true;
    });

    let result: any = [];
    updatedDTO?.forEach((ele: any) => {
      let tempVar = result.filter((i: any) => i.rackCode === ele.rackCode);

      if (Utility.isEmpty(tempVar) && !Utility.isEmpty(ele?.rackCode)) {
        result.push(ele);
      }
    });

    return result;
  };

  static getUniqueBins = (dto: any, rowCode: any, rackCode: any) => {
    if (Utility.isEmpty(dto)) {
      return [];
    }
    let updatedDTO = dto.filter((obj: any) => {
      if (!Utility.isEmpty(rowCode) && !Utility.isEmpty(rackCode)) {
        return obj.rowCode === rowCode && obj.rackCode === rackCode;
      }
      if (Utility.isEmpty(rowCode) && Utility.isEmpty(rackCode)) {
        return true;
      }
      if (Utility.isEmpty(rowCode)) {
        return obj.rackCode === rackCode;
      }
      if (Utility.isEmpty(rackCode)) {
        return obj.rowCode === rowCode;
      }
      return true;
    });

    let result: any = [];
    updatedDTO?.forEach((ele: any) => {
      let tempVar = result.filter((i: any) => i.binCode === ele.binCode);

      if (Utility.isEmpty(tempVar) && !Utility.isEmpty(ele?.binCode)) {
        result.push(ele);
      }
    });

    return result;
  };

  static getDtoFiltered = (dto: any, key: any) => {
    return !dto.every((item: any) => item[key] === null) ? dto : [];
  };

  static isRRBEnabledForWarehouse = (warehouseHouse: any) => {
    if (!Utility.isEmpty(warehouseHouse?.warehouseRowInfos)) {
      return true;
    }
    if (!Utility.isEmpty(warehouseHouse?.warehouseRackInfos)) {
      return true;
    }
    if (!Utility.isEmpty(warehouseHouse?.warehouseBinInfos)) {
      return true;
    }
    return false;
  };
}
