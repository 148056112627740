import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../../Constants/Permission';
import { Store } from '../../../../Redux/Store';
import NumberFormatService from '../../../../Services/NumberFormat';
import Utility from '../../../../Utility/Utility';
import { checkUserPermission } from '../../../Settings/GranularPermissions/GranularPermissionsHelper';
import {
  JOB_CARD_STATUS,
  WORK_ORDER_STATUS
} from '../../Constants/MRPColumnConfigs';
import {
  amountFormatter,
  getActualOperationCostFromJC,
  getActualOperatorCostFromJC,
  getJWOCostWithoutTax,
  getMaterialActualCost,
  getOperationCost,
  getTotalWorkstationCost
} from '../WorkOrderHelper';

export interface CostingHeaderProps {
  workOrder: any;
  operationIdToJobMappingData: any;
  selectedOperators: any[];
  jwoDetails: any[];
  jobCardsDetails: any[];
}

export enum COSTING_CARD {
  MATERIAL_COST = 'Material Cost',
  OPERATION_COST = 'Operation Cost',
  OPERATOR_COST = 'Operator Cost',
  WORKSTATION_COST = 'Workstation Cost',
  JWO_COST = 'JWO Cost',
  TOTAL_COST = 'Total Cost',
  QUANTITY = 'Quantity',
  YIELD = 'Yield'
}

export const getEstimatedQuantity = (item: any) => {
  return NumberFormatService.getNumber(item?.manufactureQuantity);
};

export const getActualQuantity = (item: any) => {
  return NumberFormatService.getNumber(item?.actualQuantity);
};

export const getEstimatedYield = (item: any) => {
  return item?.plannedYield
    ? NumberFormatService.getNumber(item?.plannedYield)
    : 0;
};

export const getActualYield = (item: any) => {
  return item?.actualYield
    ? NumberFormatService.getNumber(item?.actualYield)
    : 0;
};

export const getMaterialEstimatedCost = (workOrderData: any) => {
  let sumFromSalesPrice = 0;
  sumFromSalesPrice = workOrderData?.workOrderItems?.reduce(
    (accumulator: any, object: any) => {
      if (object?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE) {
        let itemCost = 0;

        if (object.itemName?.type === PRODUCT_TYPE.NON_TRACKED) {
          itemCost = Number(object.costPerUnit) || 0;
          const plannedQuantity =
            Number(workOrderData.manufactureQuantity) || 0;
          return Number(accumulator) + itemCost * plannedQuantity;
        } else if (workOrderData.status === WORK_ORDER_STATUS.COMPLETED) {
          itemCost = Number(object.costPerUnit) || 0;
        } else {
          itemCost = Number(object?.itemName?.inventoryPrice) || 0;
        }
        return Number(accumulator) + itemCost * parseFloat(object?.requiredQty);
      } else {
        return accumulator;
      }
    },
    0
  );
  return sumFromSalesPrice;
};

export const getTotalAdditionalCharges = (wo: any) => {
  const totalAdditionalCost =
    wo?.additionalCharges?.additionalChargeAmount || 0;
  return totalAdditionalCost;
};

export const getEstimatedOperationCost = (items: any) => {
  let sumFromOperationCost = items?.workOrderOperations?.reduce(
    (accumulator: any, object: any) => {
      return (
        (isNaN(accumulator) ? 0 : accumulator) +
        Number(
          Math.abs(
            getOperationCost(object?.operationName, items.manufactureQuantity)
          )
        )
      );
    },
    0
  );
  return sumFromOperationCost;
};

export const getEstimatedOperatorCost = (
  items: any[],
  manufactureQuantity = 1
) => {
  let sumFromOperatorCost: number = 0;
  items?.forEach((operator: any) => {
    sumFromOperatorCost =
      sumFromOperatorCost +
      Number(operator?.costPerHour) *
        Utility.calculateMinutesInHours(operator?.operationTime) *
        Number(manufactureQuantity) +
      Number(operator?.fixedRate);
  });
  return sumFromOperatorCost;
};

export const getTotalWorkstationActualCost = (
  workOrderItem: any,
  jobCards: any
) => {
  let cost = 0;
  jobCards?.forEach((element: any) => {
    if (element?.status === JOB_CARD_STATUS.COMPLETED) {
      let operationTime = element?.actualTime ?? 0;
      let workstationObject = element?.workstationDetails;
      cost =
        cost +
        (Number(workstationObject?.electricityCost) +
          Number(workstationObject?.consumableCost) +
          Number(workstationObject?.rentCost)) *
          (Number(operationTime || 0) / 60);
    }
  });
  return cost || 0;
};

export const calculateCostingForAllCards = (props: CostingHeaderProps) => {
  const tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  const woCompleted = props.workOrder?.status === WORK_ORDER_STATUS.COMPLETED;
  const actualQuantity = woCompleted ? getActualQuantity(props.workOrder) : '-';
  const yieldVal = woCompleted ? `${getActualYield(props.workOrder)} %` : '-';
  const jwoCost = getJWOCostWithoutTax(props.jwoDetails);
  const matActualCost = getMaterialActualCost(props.workOrder);
  const totalMaterialActualCost =
    matActualCost - jwoCost > 0 ? matActualCost - jwoCost : matActualCost;
  const matEstimatedCost = getMaterialEstimatedCost(props.workOrder);
  const totalMAterialEstimatedCost =
    matEstimatedCost - jwoCost > 0
      ? matEstimatedCost - jwoCost
      : matEstimatedCost;
  const totalAdditionalCharges = getTotalAdditionalCharges(props.workOrder);

  let selectedOperators: any[] = [];
  if (woCompleted) {
    selectedOperators = props.selectedOperators;
  } else {
    props.workOrder?.workOrderOperations?.forEach((operation: any) => {
      selectedOperators.push(...(operation?.operationName?.operators || []));
    });
  }
  const jcCodeToDetailsMap = new Map();
  props?.jobCardsDetails?.forEach((jc) => {
    if (Utility.isNotEmpty(jc?.documentSequenceCode)) {
      jcCodeToDetailsMap.set(jc?.documentSequenceCode, jc);
    }
  });
  const totalEstimatedOperationCost =
    getEstimatedOperationCost(props.workOrder) + totalAdditionalCharges;

  let completedJobCards =
    Object.values(props.operationIdToJobMappingData)?.filter((jc: any) => {
      let jcCode = jc?.documentSequenceCode;
      let jcDetails = jcCode ? jcCodeToDetailsMap.get(jcCode) ?? {} : {};
      return jcDetails?.status === JOB_CARD_STATUS.COMPLETED;
    }) ?? [];
  let completedJcMappingData: any = {};
  Object.keys(props?.operationIdToJobMappingData).forEach((id: any) => {
    let jcCode = props?.operationIdToJobMappingData[id]?.documentSequenceCode;
    let jc = jcCode ? jcCodeToDetailsMap.get(jcCode) ?? {} : {};
    if (jc?.status === JOB_CARD_STATUS.COMPLETED) {
      completedJcMappingData[id] = props?.operationIdToJobMappingData[id];
    }
  });
  const totalActualOperationCost = Utility.isEmpty(completedJobCards)
    ? 0
    : getActualOperationCostFromJC(props.workOrder, completedJobCards) +
      totalAdditionalCharges;

  const totalActualOperatorCost = getActualOperatorCostFromJC(
    props.workOrder,
    completedJobCards
  );

  const totalEstimatedOperatorCost = getEstimatedOperatorCost(
    selectedOperators,
    props.workOrder?.manufactureQuantity
  );

  const totalWorkstationCost = getTotalWorkstationCost(
    props.workOrder,
    props?.jobCardsDetails
  );
  const totalWorkstationActualCost = getTotalWorkstationActualCost(
    props.workOrder,
    props?.jobCardsDetails
  );

  const totalActualJWOCost = jwoCost;
  const totalEstimatedJWOCost = jwoCost;
  const COST_RELATED_CARD = [
    {
      title: COSTING_CARD.MATERIAL_COST,
      value1: amountFormatter(totalMaterialActualCost, tenantInfo?.currency),
      value2: amountFormatter(totalMAterialEstimatedCost, tenantInfo?.currency)
    },
    {
      title: COSTING_CARD.OPERATION_COST,
      value1: amountFormatter(totalActualOperationCost, tenantInfo?.currency),
      value2: amountFormatter(totalEstimatedOperationCost, tenantInfo?.currency)
    },
    {
      title: COSTING_CARD.OPERATOR_COST,
      value1: amountFormatter(totalActualOperatorCost, tenantInfo?.currency),
      value2: amountFormatter(totalEstimatedOperatorCost, tenantInfo?.currency)
    },
    {
      title: COSTING_CARD.WORKSTATION_COST,
      value1: amountFormatter(totalWorkstationActualCost, tenantInfo?.currency),
      value2: amountFormatter(totalWorkstationCost, tenantInfo?.currency)
    },
    {
      title: COSTING_CARD.JWO_COST,
      value1: amountFormatter(totalActualJWOCost, tenantInfo?.currency),
      value2: amountFormatter(totalEstimatedJWOCost, tenantInfo?.currency)
    },
    {
      title: COSTING_CARD.TOTAL_COST,
      value1: amountFormatter(
        totalMaterialActualCost +
          totalActualOperationCost +
          totalActualOperatorCost +
          totalWorkstationActualCost +
          totalActualJWOCost,
        tenantInfo?.currency
      ),
      value2: amountFormatter(
        totalMAterialEstimatedCost +
          totalEstimatedOperationCost +
          totalEstimatedOperatorCost +
          totalWorkstationCost +
          totalEstimatedJWOCost,
        tenantInfo?.currency
      )
    }
  ];
  const NON_CONST_RELATED_CARD = [
    {
      title: COSTING_CARD.QUANTITY,
      value1: actualQuantity,
      value2: getEstimatedQuantity(props.workOrder)
    },
    {
      title: COSTING_CARD.YIELD,
      value1: yieldVal,
      value2: `${getEstimatedYield(props.workOrder)} %`
    }
  ];

  const costingCardDetails = [];
  if (checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.VIEW_PRICE)) {
    costingCardDetails.push(...COST_RELATED_CARD);
  }
  costingCardDetails.push(...NON_CONST_RELATED_CARD);

  return costingCardDetails;
};
