import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';

class GSTR2BService {

    static checkSession() {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.CHECK_GSTR2B_SESSION;
        
        return http.get(endpoint).then(
            (res) => {
                console.log("session res", res)
                return Promise.resolve(res);
            },
            (err) => {
                console.log("session err", err)
                return Promise.reject(err);
            }
        )

    }

    static generateOTP(data: any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.GENERATE_GSTR2B_OTP;
        
        return http.post(endpoint, data).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static verifyOTP(data: any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.VERIFY_GSTR2B_OTP;
        
        return http.post(endpoint, data).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static getUploadedFileList(query:any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.GET_UPLOADED_GSTR2B_FILES;
        if (query !== null) {
            endpoint = endpoint + query;
        }
        return http.get(endpoint).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static uploadGSTR2BFile(data: any) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.UPLOAD_GSTR2B_FILE;
        
        return http.post(endpoint, data, config).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static deleteFile(data:any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.DELETE_GSTR2B_FILE + data;

        return http.delete(endpoint).then(
            (res) => {
                console.log("delete res", res)
                return Promise.resolve(res);
            },
            (err) => {
                console.log("delete err", err)
                return Promise.reject(err);
            }
        )


    }

    static getGSTR2BFileDetails(data: any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.GET_GSTR2B_FILE_DETAIL(data);

        return http.get(endpoint).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static getMatchingSummary(query:any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.GET_MATCH_SUMMARY+query;

        return http.get(endpoint).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static gstr2bAction( data: any) {
        let endpoint = ApiConstants.URL.BASE + ApiConstants.URL.REPORT.TAX.GSTR2B_ACTIONS;
        
        return http.post(endpoint, data).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }
}

export default GSTR2BService;
