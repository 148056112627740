import ApiConstants from '../Constants/ApiConstants';
import { Invoice } from '../Models/Invoice';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface FinancialYearClosingAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
}

const defaultConfig: FinancialYearClosingAPIConfig = {
  SearchTerm: '',
  Limit: 100,
  Page: 0
};
class FinancialYearClosingService {
  static apiConfig: FinancialYearClosingAPIConfig;
  static getFinancialYearClosingByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?query=&search=${this.apiConfig.SearchTerm}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndpoint: string =
      ApiConstants.URL.FYCLOSING.FETCH_FYCLOSING + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getClosingJePreviewData(payload: any) {
    const finalEndPoint = ApiConstants.URL.FYCLOSING.FETCH_FYCLOSING_JE_DATA;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static postClosingJePreviewData(payload: any) {
    const finalEndPoint = ApiConstants.URL.FYCLOSING.FETCH_FYCLOSING;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static postSettingsFy(payload: any) {
    const finalEndPoint = ApiConstants.URL.FYCLOSING.UPDATE_SETTINGS;
    return http.put(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static getSettingsFy() {
    const finalEndPoint = ApiConstants.URL.FYCLOSING.GET_SETTINGS;
    return http.get(`${finalEndPoint}`).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static deleteFYClosingReport(id: number) {
    const finalEndpoint: string =
      ApiConstants.URL.FYCLOSING.FETCH_FYCLOSING + `/${id}`;
    return http.delete(finalEndpoint).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default FinancialYearClosingService;
