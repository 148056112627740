import { useEffect, useState } from 'react';
import { DKButton, DKLabel, DKDataGrid } from 'deskera-ui-library';
import Utility from '../../Utility/Utility';
import { QTY_ROUNDOFF_PRECISION } from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { getCustomFieldDataForDisplayTable } from '../CustomFieldsHolder/BatchSerialCustomFieldsHelper';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';

export default function SalesReturnTable(props: any) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [reportData, setReportData] = useState<any>(null);
  const [gridRowData, setGridRowData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>();
  const [realTableData, setRealTableData] = useState<any>();
  const [customFields, setCustomFields] = useState<any>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const [cfColumnConfig, setCfColumnConfig] = useState<any>([]);
  const [customFieldColumns, setCustomFieldColumns] = useState<any>([]);

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setTableData(props.populateFormData.content);
    }
  }, [props]);

  useEffect(() => {
    if (!Utility.isEmpty(tableData)) {
      setRealTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    setCustomFields(batchSerialCFfromStore?.content);
  }, [batchSerialCFfromStore]);

  useEffect(() => {
    if (!Utility.isEmpty(customFields)) {
      const cfFieldsSet: any = new Set(
        customFields
          ?.filter((cfData: any) => cfData.status === 'ACTIVE')
          ?.map((cf: any) => cf.label)
      );
      const cfList: any = customFields?.filter(
        (cfData: any) => cfData.status === 'ACTIVE'
      );

      let copyColumnConfigs = cfColumnConfig;
      if (Utility.isEmpty(copyColumnConfigs)) {
        cfList.forEach((cfield: any, index: number) => {
          copyColumnConfigs.push({
            name: cfield.label,
            type: cfield.fieldType.toLowerCase(),
            index: copyColumnConfigs.length + index + 1,
            options: null,
            required: false,
            width: 180,
            editable: false,
            hidden: false,
            uiVisible: true,
            systemField: false,
            columnCode: cfield.label,
            key: cfield.label,
            textAlign: 'left',
            allowFilter: false
          });
        });
      }
      setCfColumnConfig(copyColumnConfigs);
      setCustomFieldColumns(Array.from(cfFieldsSet));
    }
  }, [customFields]);

  useEffect(() => {
    if (!Utility.isEmpty(realTableData)) setGridRowData(getRowData());
  }, [realTableData]);

  const getRowData = () => {
    let rowData: any[] = [];
    realTableData &&
      realTableData.forEach((row: any) => {
        rowData.push({
          ['batch']: row.serialBatchNumber,
          ['batchSize']: Utility.roundingOff(
            props.populateFormData?.documentUOMSchemaDefinition
              ? Utility.getUomQuantity(
                  row.advancedTrackingReturned?.batchSizeReturned,
                  props.populateFormData?.documentUOMSchemaDefinition
                )
              : row.advancedTrackingReturned?.batchSizeReturned,
            QTY_ROUNDOFF_PRECISION
          ),
          ...getCustomFieldDataForDisplayTable(
            row?.customField ?? [],
            customFieldColumns,
            batchSerialCFfromStore?.content
          )
        });
      });
    return rowData;
  };

  let defaultColumns = [
    {
      name: 'Batch Number',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: Utility.isEmpty(cfColumnConfig) ? 365 : 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'batch',
      key: 'batch'
    },
    {
      name: 'Returned Quantity',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: Utility.isEmpty(cfColumnConfig) ? 365 : 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'batchSize',
      key: 'batchSize'
    }
  ];

  if (props.isSerial) {
    defaultColumns = [
      {
        name: 'Serial Number',
        type: 'text',
        index: 3,
        options: null,
        required: false,
        width: Utility.isEmpty(cfColumnConfig) ? 730 : 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'batch',
        key: 'batch'
      }
    ];
  }

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={
              props.isSerial
                ? 'Returned Products - Serials Information'
                : 'Returned Products - Batch Information'
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 800,
          width: '75%',
          // height: "85%",
          maxHeight: '95%',
          // height: 400,
          padding: 0,
          paddingBottom: 36
        }}
      >
        {getHeader()}
        <div className="mt-5 pl-5 pl-5">
          <div className="column parent-width flex-1">
            <div className="column parent-width position-relative flex-1 p-3 align-items-center">
              <DKDataGrid
                needShadow={false}
                needBorder={true}
                needColumnIcons={false}
                needTrailingColumn={false}
                allowBulkOperation={false}
                allowColumnSort={false}
                allowColumnAdd={false}
                allowColumnEdit={false}
                allowRowEdit={true}
                currentPage={1}
                totalPageCount={1}
                title={''}
                width={730}
                columns={defaultColumns.concat(cfColumnConfig)}
                rows={gridRowData}
              />
              {reportData?.trialBalanceDetailDto?.length === 0 && (
                <div className="position-absolute parent-width top-40 text-align-center fs-r fw-b text-gray">
                  No records to display
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
