import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn, IColumnType } from '../../Models/Table';
import { ConfigPayload } from '../../Models/ReduxStore';
import {
  getStandardWithHoldTax,
  getWithHoldTax,
  getWithHoldTaxGroup
} from '../../Services/WithHoldTax';
import { RootState } from '../Store';
import { IWHTax } from '../../Models/WHTax';
type stateType = {
  whTaxes: IWHTax[];
  whTaxGroup: any[];
  columnConfig: any[];
  configTableId: any;
};
const initialState: stateType = {
  whTaxes: [],
  whTaxGroup: [],
  columnConfig: [
    {
      name: 'Section Name',
      type: 'text',
      index: 0,
      options: [],
      required: true,
      width: 400,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'sectionName',
      id: 'sectionName',
      key: 'sectionName'
    },
    {
      name: 'Account Name',
      type: 'select',
      index: 1,
      options: [],
      required: true,
      width: 250,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'accountName',
      id: 'accountName',
      key: 'accountName'
    },
    {
      name: 'Rate',
      type: 'number',
      index: 2,
      options: [],
      required: true,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'rate',
      id: 'rate',
      key: 'rate'
    },
    {
      name: 'Standard Tax',
      type: 'select',
      index: 3,
      options: [],
      required: true,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'isStandardTds',
      id: 'isStandardTds',
      key: 'isStandardTds'
    },
    {
      name: 'is Resident?',
      type: 'select',
      index: 4,
      options: [],
      required: true,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'resident',
      id: 'resident',
      key: 'resident'
    },
    {
      name: 'Description',
      type: 'text',
      index: 5,
      options: [],
      required: true,
      width: 300,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'sectionShortDescription',
      id: 'sectionShortDescription',
      key: 'sectionShortDescription'
    },
    {
      name: 'Action',
      type: 'button',
      index: 6,
      options: [],
      required: true,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'action',
      id: 'action',
      key: 'action'
    }
  ],
  configTableId: null
};
export const fetchStandardWHTax = createAsyncThunk(
  'fetchStandardWHTax',
  async () => {
    const response = await getStandardWithHoldTax();
    return response;
  }
);
export const fetchWHTax = createAsyncThunk('fetchWHTax', async () => {
  const response = await getWithHoldTax();
  return response;
});
export const fetchWHTaxGroup = createAsyncThunk('fetchWHTaxGroup', async () => {
  const response = await getWithHoldTaxGroup();
  return response;
});
export const WithHoldTaxSlice = createSlice({
  name: 'withHoldTax',
  initialState,
  reducers: {
    addTaxColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : [],
          editable: config.columnCode === 'type' ? false : config.editable
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addColumnToTaxColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStandardWHTax.fulfilled, (state, action: any) => {
        state.whTaxes = [...state.whTaxes, ...(action.payload || [])];
      })
      .addCase(fetchWHTax.fulfilled, (state, action: any) => {
        state.whTaxes = [...(action.payload || [])];
      })
      .addCase(fetchWHTaxGroup.fulfilled, (state, action: any) => {
        state.whTaxGroup = [...(action?.payload?.content || [])];
      });
  }
});

export const selectWithHoldTax = (state: RootState) =>
  state?.withHoldTax?.whTaxes;
export const selectWithHoldTaxGroup = (state: RootState) =>
  state?.withHoldTax?.whTaxGroup;
export const selectWithHoldTaxColumnConfig = (state: RootState) =>
  state?.withHoldTax?.columnConfig;
