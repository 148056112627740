import { DKButton, DKLabel, DKSpinner, INPUT_TYPE } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import MRPProductsService from '../../../../Services/MRP/MRPProducts';
import Utility from '../../../../Utility/Utility';
import BomDetailsTabProductReadOnly from './BomDetailsTabProductReadOnly';
import { DynamicPopupWrapper } from '../../../../SharedComponents/PopupWrapper';
export interface BomVersionHistroryProps {
  urlEndPoint: string;
  onCancel: () => void;
}

export default function BomVersionHistory(props: BomVersionHistroryProps) {
  const [columnConfig, setColumnConfig] = useState([
    {
      name: 'Product Name',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'name',
      key: 'name',
      allowColumnSort: false
    },
    {
      name: 'Bom Name',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'bomMetaName',
      key: 'bomMetaName',
      allowColumnSort: false
    },
    {
      name: 'Version',
      type: INPUT_TYPE.TEXT,
      index: 0,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'version',
      key: 'version',
      allowColumnSort: false
    },
    {
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 50,
      options: []
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [versionsArray, setVersionsArray] = useState<any>();
  const [selectedVersion, setSelectedVersion] = useState<any>();
  useEffect(() => {
    fetchBomVersions();
    return () => {};
  }, []);

  const fetchBomVersions = () => {
    setLoading(true);
    MRPProductsService.getBomVersionHistory(props.urlEndPoint)
      .then((response: any) => {
        setLoading(false);
        console.log(response, 'response of bom version');
        setVersionsArray(response);
        if (!Utility.isEmpty(response)) {
          setSelectedVersion(response[0]);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log('err', 'failed to fetch bom versions');
      });
  };

  const getHeaderTitle = () => {
    if (versionsArray?.length) {
      return `${versionsArray?.[0]?.productName}`;
    }
    return 'Version History';
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row gap-2 pop-header-drag-handle">
          <DKLabel text={getHeaderTitle()} className="fw-m fs-l" />
          {loading && (
            <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
          )}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className={'bg-white border-m'}
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    );
  };

  const getDetailsHeader = () => {
    if (versionsArray?.length) {
      return `${versionsArray?.[0]?.name} (Version ${selectedVersion?.version})`;
    }
    return 'Version Details';
  };

  return (
      <DynamicPopupWrapper>
        <div className="transparent-background">
          <div
            className="popup-window"
            style={{
              maxWidth: 850,
              width: '90%',
              maxHeight: '95%',
              height: 650,
              padding: 0,
              overflowY: 'hidden'
            }}
          >
            {getHeader()}
            <div className="row parent-height align-items-start">
              {/* left */}
              <div
                className="column align-items-start justify-content-start"
                style={{ width: '20%', height: 600 }}
              >
                <DKLabel
                  className="fw-m p-2 p-h-r parent-width bg-gray1 border-t-2 border-b-2"
                  text="Version History"
                />
                <div
                  className="parent-size overflow-scroll hide-scroll-bar"
                  style={{
                    height: 540
                  }}
                >
                  {versionsArray?.map((versionObj: any) => {
                    return (
                      <div
                        className={`row border-b-2 cursor-hand ${
                          versionObj === selectedVersion ? 'bg-blue-100' : ''
                        }`}
                        onClick={() => {
                          setSelectedVersion(versionObj);
                        }}
                        style={{
                          width: '100%',
                          height: 42,
                          borderRadius: 0,
                          padding: 0,
                          paddingLeft: 14,
                          paddingRight: 14
                        }}
                      >
                        {`Version ${versionObj.version}`}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="row parent-height bg-black"
                style={{
                  width: 1.5,
                  opacity: 0.1
                }}
              >
                {' '}
              </div>
              {/* right */}
              <div
                className="column align-items-start justify-content-start"
                style={{ width: '80%', height: 600 }}
              >
                <DKLabel
                  className="fw-m p-2 p-h-r parent-width bg-gray1 border-t-2 border-b-2"
                  text={getDetailsHeader()}
                />
                <div
                  className="column parent-size p-h-r p-v-m overflow-scroll hide-scroll-bar"
                  style={{ height: 540 }}
                >
                  {!Utility.isEmpty(selectedVersion) && (
                    <BomDetailsTabProductReadOnly
                      bomMetaDetails={selectedVersion}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DynamicPopupWrapper>
  );
}
