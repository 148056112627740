import { DKLabel } from 'deskera-ui-library';

interface SettingsDetailsCardRowProps {
  detailKey: string;
  detailValue: string | number | JSX.Element;
  className?: string;
}
const SettingsDetailsCardRow = (props: SettingsDetailsCardRowProps) => {
  return (
    <div className={`row ${props.className ? props.className : ''}`}>
      <div className="column" style={{ minWidth: 250 }}>
        <DKLabel text={props.detailKey} />
      </div>
      {(typeof props.detailValue === 'string' ||
        typeof props.detailValue === 'number') && (
        <div className="column parent-width text-gray">
          <DKLabel text={props.detailValue} />
        </div>
      )}
      {typeof props.detailValue !== 'string' &&
        typeof props.detailValue !== 'number' && (
          <div className="column text-gray">{props.detailValue}</div>
        )}
    </div>
  );
};

export default SettingsDetailsCardRow;
