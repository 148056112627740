import { useEffect, useState } from 'react';
import {
  INPUT_TYPE,
  DKLabel,
  DKTooltipWrapper,
  DKButton,
  DKIcons
} from 'deskera-ui-library';
import { Document } from '../../Models/Document';
import Utility from '../../Utility/Utility';
import NumberFormatService from '../../Services/NumberFormat';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { amountFormatter } from '../../Components/Mrp/WorkOrder/WorkOrderHelper';
import { DOC_TYPE, PRODUCT_TYPE } from '../../Constants/Constant';
import EditComponentDetailsPopup from './EditComponentDetailsPopup';

interface IComponentDetailsForFGProps {
  booksDocument: Document;
  componentGridWidth: number;
  productRows: any[];
  onChange: any;
}

const ComponentDetailsForFG = (props: IComponentDetailsForFGProps) => {
  const [gridData, setGridData] = useState<any[]>([]);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<any>(0);

  const width = props.componentGridWidth;

  useEffect(() => {
    let docDetails = props?.booksDocument?.items || [];
    if (Utility.isNotEmpty(docDetails)) {
      let lineItems: any[] = [];
      docDetails?.forEach((itemRow: any) => {
        if (
          Utility.isNotEmpty(
            itemRow?.bomComponentGroupDetails?.bomComponentGroups
          ) &&
          itemRow?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        ) {
          itemRow = {
            ...itemRow,
            parentId: itemRow.id,
            bomComponentGroupDetails: {
              ...itemRow?.bomComponentGroupDetails,
              bomComponentGroups:
                itemRow?.bomComponentGroupDetails?.bomComponentGroups?.map(
                  (item: any, index: number) => {
                    return {
                      ...item,
                      parentId: itemRow.id,
                      expanded: false,
                      finishedGoodName:
                        index === 0
                          ? itemRow?.bomComponentGroupDetails?.productName
                          : ''
                    };
                  }
                )
            }
          };
          lineItems = [
            ...lineItems,
            ...itemRow.bomComponentGroupDetails.bomComponentGroups
          ];
        }
      });

      let updatedData = lineItems.map((group: any) => ({
        ...group,
        bomComponentGroupItems: group?.bomComponentGroupItems?.map(
          (item: any) => ({
            ...item,
            productGroupId: group.productGroupId,
            parentId: group.parentId
          })
        )
      }));
      setGridData(updatedData);
    } else {
      setGridData([]);
    }
  }, [props]);

  useEffect(() => {
    let total = 0;
    if (gridData && gridData.length > 0) {
      total = gridData.reduce(
        (sum: any, item: any) => sum + (item.bomComponentGroupItemTotalCost ? item.bomComponentGroupItemTotalCost : 0),
        0
      );
    }
    setTotalPrice(total);
  }, [gridData]);

  const getDataKeyForOperationsAndAddCharge = (
    isOperationOrAddCharge: boolean,
    itemData: any
  ) => {
    if (isOperationOrAddCharge) {
      return itemData?.productGroupId === 'operationId'
        ? 'bomOperationsConfiguration'
        : 'bomAddCostConfiguration';
    } else {
      return '';
    }
  };

  const getColumnValue = (grouptItemDetails: any, groupItemKey: string) => {
    return grouptItemDetails?.[groupItemKey];
  };

  const getFGSection = (lineItemData: any, lineItemDataIndex: number) => {
    const fgName = lineItemData?.finishedGoodName ?? '';
    const isOperationOrAdditionalChargeGroup =
      lineItemData?.productGroupId === 'operationId' ||
      lineItemData?.productGroupId === 'additionalChargeId';
    const dataKey = getDataKeyForOperationsAndAddCharge(
      isOperationOrAdditionalChargeGroup,
      lineItemData
    );
    const isOperationFlow = lineItemData?.productGroupId === 'operationId';
    return (
      <>
        {Utility.isNotEmpty(fgName) && lineItemDataIndex !== 0 && (
          <div
            className="bg-gray1 parent-width column mt-xl mb-l"
            style={{ padding: 1 }}
          />
        )}
        <DKLabel text={fgName} className="fw-b fs-l text-blue" />

        {!isOperationOrAdditionalChargeGroup &&
          lineItemData?.bomComponentGroupItems?.length > 0 &&
          getGroup(lineItemData)}

        {isOperationOrAdditionalChargeGroup &&
          lineItemData?.[dataKey]?.length > 0 &&
          getOperation(lineItemData, dataKey, isOperationFlow)}
      </>
    );
  };

  const getGroup = (componentGroupItemObj: any) => {
    const productGroupName = componentGroupItemObj?.productGroupName ?? '';
    const bomComponentGroupItemTotalCost =
      !isNaN(componentGroupItemObj?.bomComponentGroupItemTotalCost) &&
      componentGroupItemObj?.bomComponentGroupItemTotalCost?.toString()
        ? NumberFormatService.getNumber(
            componentGroupItemObj?.bomComponentGroupItemTotalCost
          )
        : '';
    const priceWithCurrency = `${Utility.getCurrencySymbolFromCode(
      tenantInfo?.currency
    )} ${bomComponentGroupItemTotalCost}`;

    return (
      <>
        <DKTooltipWrapper
            content={`Total ${Utility.getCurrencySymbolFromCode(
              tenantInfo?.currency
            )} ${NumberFormatService.getNumber(totalPrice)}`}
            tooltipClassName="width-auto"
            tooltipStyle={{ whiteSpace: 'pre-wrap' }}
          >
          <div className="row justify-content-between mt-m">
            <DKLabel text={productGroupName} className="fw-m fs-r text-gray " />
            <DKLabel
              text={priceWithCurrency}
              className="fw-m fs-r text-gray mr-r"
            />
          </div>
          </DKTooltipWrapper>
        <div
          className="border-radius-s bg-gray1 p-s column parent-width mt-r"
          style={{ gap: 10, backgroundColor: 'rgb(247, 247, 250' }}
        >
          {getProducts(componentGroupItemObj)}
        </div>
      </>
    );
  };
  const getProducts = (compGroupItem: any) => {
    var arr = [];
    for (let i = 0; i < compGroupItem?.bomComponentGroupItems?.length; i++) {
      arr.push(getProductRow(compGroupItem?.bomComponentGroupItems[i]));
    }
    return arr;
  };
  const isEditable = () => {
    let tenantSetting = tenantInfo?.additionalSettings?.COMPONENT_DETAILS_FOR_FG?.listUpdateEnable || false;
    let isEditableModule = (props.booksDocument.documentType === DOC_TYPE.QUOTE ||
      props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.INVOICE) || false
    if (tenantSetting && isEditableModule) {
      return true;
    }
    return false;
  };
  const getProductRow = (groupItem: any) => {
    const productDocumentSequenceCode = getColumnValue(
      groupItem,
      'productDocumentSequenceCode'
    );
    const productName = getColumnValue(groupItem, 'productName');
    const quantityRequired = !groupItem?.isSubstitute
      ? groupItem?.quantityRequired ?? 0
      : '';
    const uomName =
      Utility.getUOMForStockUOMId(groupItem?.productUom)?.name ?? '';
    // const salesPrice = !groupItem?.isSubstitute
    //   ? NumberFormatService.getNumber(groupItem?.salesPrice) ?? 0
    //   : '';
    const qtyWithUom = !groupItem?.isSubstitute
      ? `${quantityRequired} ${uomName}`
      : '';
    const productDescription = !groupItem?.isSubstitute
      ? groupItem?.productDescription ?? ''
      : '';

    const salesPrice = groupItem?.salesPrice ?? 0
    let totalSalePrice = 0
    if (quantityRequired > 0) {
      totalSalePrice = salesPrice * quantityRequired
    }
    const commonStyle = {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    };
    let widthTemp = width - 10;
    return (
      <div className="row justify-content-between">
        {/* product code */}
        <DKTooltipWrapper
          content={productDocumentSequenceCode}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <div
            className="row"
            style={{
              minWidth: (20 / 100) * widthTemp,
              maxWidth: (20 / 100) * widthTemp
            }}
          >
            <DKLabel
              text={productDocumentSequenceCode}
              style={{
                ...commonStyle
              }}
            />
            {groupItem?.isSubstitute && (
              <DKLabel
                className="bg-chip-blue text-blue border-blue p-h-xs border-radius-s text-wrap-none ml-xs"
                text="Subs"
              />
            )}
          </div>
        </DKTooltipWrapper>
        {/* product name  */}
        <DKTooltipWrapper
          content={productName}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <DKLabel
            text={productName}
            style={{
              ...commonStyle,
              minWidth: (20 / 100) * widthTemp,
              maxWidth: (20 / 100) * widthTemp,
              paddingLeft: 4
            }}
          />
        </DKTooltipWrapper>
        {/* product description */}
        <DKTooltipWrapper
          content={productDescription}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <DKLabel
            text={productDescription}
            style={{
              ...commonStyle,
              minWidth: (25 / 100) * widthTemp,
              maxWidth: (25 / 100) * widthTemp,
              paddingLeft: 4
            }}
          />
        </DKTooltipWrapper>
        {/* quantity required */}
        <DKTooltipWrapper
          content={qtyWithUom}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <DKLabel
            text={qtyWithUom}
            style={{
              ...commonStyle,
              minWidth: (10 / 100) * widthTemp,
              maxWidth: (10 / 100) * widthTemp,
              paddingLeft: 4,
              textAlign: 'right'
            }}
          />
        </DKTooltipWrapper>
        {/* product Total Sales Price */}
        <DKTooltipWrapper
          content={`${Utility.getCurrencySymbolFromCode(
            tenantInfo?.currency
          )} ${NumberFormatService.getNumber(totalSalePrice)}`}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              tenantInfo?.currency
            )} ${NumberFormatService.getNumber(totalSalePrice)}`}
            style={{
              ...commonStyle,
              minWidth: (15 / 100) * widthTemp,
              maxWidth: (15 / 100) * widthTemp,
              paddingLeft: 4,
              paddingRight: 2,
              textAlign: 'right'
            }}
          />
        </DKTooltipWrapper>
        {isEditable() && (
          <DKButton
            title={''}
            className="bg-gray2 border-m cursor-hand"
            icon={DKIcons.ic_edit}
            onClick={() => {
              setSelectedRecord(groupItem);
              setShowEdit(true);
            }}
          />
        )}

        {/* sales price */}
        {/* <DKTooltipWrapper
          content={salesPrice}
          tooltipClassName="width-auto"
          tooltipStyle={{ whiteSpace: 'pre-wrap' }}
        >
          <DKLabel
            text={salesPrice}
            style={{
              ...commonStyle,
              minWidth: widthForLabel - 40,
              maxWidth: widthForLabel - 40,
              paddingLeft: 4,
              textAlign: 'right'
            }}
          />
        </DKTooltipWrapper> */}
      </div>
    );
  };

  const getOperation = (
    componentGroupItemObj: any,
    keyToCompare: any,
    isOperationFlow: boolean
  ) => {
    const groupName = componentGroupItemObj?.productGroupName ?? '';
    return (
      <>
        <DKLabel text={groupName} className="fw-m fs-r text-gray mt-l" />
        <div className="row mt-r flex-wrap" style={{ gap: 10 }}>
          {getOperations(componentGroupItemObj, keyToCompare, isOperationFlow)}
        </div>
      </>
    );
  };

  const getOperations = (
    compGroupItem: any,
    key: any,
    isOperationFlow: boolean
  ) => {
    var arr = [];
    for (let i = 0; i < compGroupItem?.[key]?.length; i++) {
      arr.push(getOperationRow(compGroupItem?.[key]?.[i], isOperationFlow));
    }
    return arr;
  };
  const getOperationRow = (groupItem: any, isOperationFlow: boolean) => {
    const nameKey = isOperationFlow ? 'operationName' : 'label';
    const name = groupItem?.[nameKey] ?? '';
    return (
      <div
        className="p-h-s p-v-xs border-m border-radius-s bg-white"
        style={{}}
      >
        <DKLabel text={name} />
      </div>
    );
  };

  return Utility.isNotEmpty(gridData) ? (
    <>
      <DKLabel
        text={`${Utility.isComponentDetailsForFGOnInvoiceSOQuote(true)}:`}
        className="fw-m"
      />
      <div
        className="column align-items-center mt-s mb-s"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      >
        <div
          className="border-m border-radius-s"
          style={{ width: width, padding: 20 }}
        >
          {gridData?.map((lineItemObj: any, index: number) => {
            return getFGSection(lineItemObj, index);
          })}
        </div>
      </div>
      {showEdit && (
        <>
          <EditComponentDetailsPopup
            selectedRecord={selectedRecord}
            onCancel={() => {
              setShowEdit(false);
            }}
            onDone={(data: any) => {
              props.onChange(data);
              setShowEdit(false);
            }}
          ></EditComponentDetailsPopup>
        </>
      )}
    </>
  ) : null;
};

export default ComponentDetailsForFG;
