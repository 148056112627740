import ApiConstants from '../../Constants/ApiConstants';
import { BulkDeleteResponse } from '../../Models/Interfaces';
import Utility from '../../Utility/Utility';
import http2 from './../Interceptor2';
export interface WorkstationsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  Sort?: string;
  SortDir?: string;
}

const defaultConfig: WorkstationsAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  Sort: '',
  SortDir: 'DESC'
};

class WorkstationsService {
  static apiConfig: WorkstationsAPIConfig;

  static workStationEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = 'mrp/master/workstation/search';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=createdOn&sortDir=desc`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static getWorkStationsByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const params = new URLSearchParams({
      limit: String(this.apiConfig.Limit),
      page: String(this.apiConfig.Page),
      search: String(this.apiConfig.SearchTerm),
      sort: String(this.apiConfig.Sort),
      sortDir: String(this.apiConfig.SortDir),
      query: this.apiConfig.Query ? String(this.apiConfig.Query) : '',
      queryParam: this.apiConfig.QueryParam
        ? String(this.apiConfig.QueryParam)
        : ''
    });
    const query = `?${params.toString()}`;

    return http2
      .get(`${ApiConstants.URL.MRP.WORK_STATIONS.GET_WORK_STATIONS}${query}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteWorkStation(id: number) {
    return http2
      .delete(`${ApiConstants.URL.MRP.WORK_STATIONS.DELETE_WORK_STATION(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static bulkDelete(Ids: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: Ids
    };
    return http2.delete(ApiConstants.URL.MRP.WORK_STATIONS.BULK_DELETE, config);
  }

  static saveWorkStation = (payload: any) => {
    return http2
      .post(ApiConstants.URL.MRP.WORK_STATIONS.ADD_WORK_STATION, payload)
      .catch((err: any) => {
        console.error('Error saving workstation: ', err);
        return Promise.reject(err);
      });
  };

  static updateWorkStation = (id: number, payload: any) => {
    return http2
      .put(ApiConstants.URL.MRP.WORK_STATIONS.EDIT_WORK_STATION(id), payload)
      .catch((err: any) => {
        console.error('Error saving workstation: ', err);
        return Promise.reject(err);
      });
  };

  static fetchWorkStationById = (id: number) => {
    let query = `?query=id=${id}`;
    return http2
      .get(`${ApiConstants.URL.MRP.WORK_STATIONS.GET_WORK_STATIONS}${query}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };
}

export default WorkstationsService;
