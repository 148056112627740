import { Document } from './Document';
import {
  BUY_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS,
  TAX_SYSTEM
} from '../Constants/Constant';
import { DocumentItem } from './DocumentItem';
import { CallBackPayloadType } from './Interfaces';

export interface WorkoutProps {
  draftData?: any;
  formData?: any;
  populateFormData?: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
  documentMode?: DOCUMENT_MODE;
  isCenterAlign: boolean;
}

export interface WorkOut extends Document {
  jobWorkOutOrderItems?: DocumentItem[];
  orderDueDate?: string;
  dueDate: string;
  receiveByDate: string;
  paymentStatus?: PAYMENT_STATUS;
  reorder?: boolean;
  dropShip: boolean;
  receiveGoodsStatus: RECEIVED_GOODS_STATUS;
  backOrder: boolean;
  orderType: string;
  buyType: BUY_TYPE;
  vendorCode: string;
  poCode?: string;
  jwoCode?: string;
  linkedPurchaseInvoices?: any[];
  receiptStatus?: string;
  poDocument?: any;
}

export const JobWorkOutInitialState: WorkOut = {
  contactCode: '',
  currency: '',
  currencyCode: '',
  status: DOCUMENT_STATUS.OPEN,
  sequenceFormat: '',
  documentType: DOC_TYPE.JOB_WORK_OUT_ORDER,
  shipFrom: {},
  shipTo: {},
  billTo: {},
  exchangeRate: 1,
  documentDate: '',
  validTillDate: '',
  dueDate: '',
  paymentStatus: PAYMENT_STATUS.PENDING,
  receiveGoodsStatus: RECEIVED_GOODS_STATUS.NOT_RECEIVED,
  receiptStatus: RECEIVED_GOODS_STATUS.NOT_RECEIVED,
  items: [],
  fulfillmentDate: '',
  memo: '',
  contact: null,
  unitPriceGstInclusive: false,
  rcmApplicable: false,
  reorder: false,
  previousExchangeRate: 1,
  gstExchangeRate: 1,
  totalAmount: 0,
  roundOffAmountInDocumentCurrency: 0,
  totalAmountInBaseCurrency: 0,
  dueAmount: 0,
  attachments: [],
  attachmentIds: [],
  supportedFeatures: [],
  gstin: '',
  placeOfSupply: '',
  customerType: 'NA',
  vendorType: 'NA',
  gstTreatment: 'REGISTERED_BUSINESS_REGULAR',
  customField: null,
  paymentInformation: null,
  knockoffNonContraInfo: [],
  knockoffContraInfo: [],
  taxSystem: TAX_SYSTEM.INDIA_GST,
  tcsRateId: undefined,
  tcsAmount: 0,
  totalTdsAmount: 0,
  vatPaidAtCustomSA: false,
  rcmApplicableSA: false,
  backOrder: false,
  dropShip: false,
  receiveByDate: '',
  orderType: BUY_TYPE.INVENTORY,
  buyType: BUY_TYPE.INVENTORY,
  vendorCode: '',
  jobWorkOutOrderItems: [],
  isDocumentTouched: false,
  additionalCharges: {
    globalDiscount: {
      amount: 0,
      isPercent: false,
      isSubTotalOnly: false,
      percent: 0,
      applyOnBeforeTax: false
    },
    additionalChargeAmount: null,
    additionalChargeTaxAmount: null,
    additionalChargesDetails: []
  }
};
