import { useCallback, useEffect, useRef, useState } from 'react';
import { DKLabel, DKButton, DKListPicker2, DKIcons } from 'deskera-ui-library';
import SideBarService from '../Services/sidebar';
import MoreIconBlack from '../Assets/Icons/ic_more_vertical.png';
import MoreIconWhite from '../Assets/Icons/white/ic_more_vertical.png';

interface GroupContainerProps {
  data: any;
  selectedId: any;
  moreListPickerTitle: string;
  maxWidth: number;
  allowToAddGroup: boolean;
  allowDelete: boolean;
  onChange: (group: any) => void;
  onClickNew?: () => void;
  onClickEdit?: (id: any) => void;
  onClickDelete?: (id: any) => void;
  onSave?: () => void;
}

const GroupContainer = (props: GroupContainerProps) => {
  const [showGroupPicker, setShowGroupPicker] = useState(false);
  const [controlWidth, setControlWidth] = useState(0);
  const [selectedGroupOverflown, setSelectedGroupOverflown] = useState(false);
  const [showMoreList, setShowMoreList] = useState<any[]>([]);
  const groupElement = useRef<any>(null);

  const getListWidth = useCallback(() => {
    /* More Dropdown & Add buttons width */
    const controlsWidth = 150;
    const availableWidth = SideBarService.getContainerWidth();

    let requiredWidth = availableWidth - controlsWidth;

    if (props.maxWidth) {
      requiredWidth = Math.min(requiredWidth, props.maxWidth - controlsWidth);
    }

    return requiredWidth;
  }, [props.maxWidth]);

  const onResize = useCallback(
    (e: any) => {
      setControlWidth(getListWidth());
    },
    [getListWidth]
  );

  useEffect(() => {
    setControlWidth(getListWidth());
  }, [getListWidth]);

  useEffect(() => {
    window.addEventListener('onSideBarChange', onResize);
    return () => {
      window.removeEventListener('onSideBarChange', onResize);
    };
  }, [onResize]);

  const getElementEndPosition = (element: HTMLElement) => {
    return element.offsetLeft + element.clientWidth;
  };

  useEffect(() => {
    const container = groupElement.current;
    if (container) {
      const groupControls = container.childNodes;

      /* Reset Group Display */
      for (const groupControlElement of groupControls) {
        groupControlElement.hidden = false;
      }

      const containerEnd = getElementEndPosition(container);
      const overflownElements = [];
      let isSelectedGroupOverflown = false;

      for (const groupControlElement of groupControls) {
        const groupIndex = Number(
          groupControlElement.getAttribute('data-index')
        );

        const groupName = groupControlElement.getAttribute('data-name');

        if (
          getElementEndPosition(groupControlElement) > controlWidth &&
          getElementEndPosition(groupControlElement) > containerEnd
        ) {
          overflownElements.push({ id: groupIndex, name: groupName });
          groupControlElement.hidden = true;
          if (props.selectedId === groupIndex) {
            isSelectedGroupOverflown = true;
          }
        }
      }

      setShowMoreList(overflownElements);
      setSelectedGroupOverflown(isSelectedGroupOverflown);
    }
  }, [props.data, controlWidth, props.selectedId]);

  const tooltipShow = (id: any) => {
    onCanvasLeave();
    setTooltip(id);
  };

  const onCanvasLeave = () => {
    const allTooltip = document.getElementsByClassName(
      'dk-group-tooltip'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < allTooltip.length; i++) {
      allTooltip[i].style.display = 'none';
    }
    const elements = document.getElementsByClassName('group-active');
    if (elements.length > 0) {
      elements[0].classList.remove('group-active');
    }
  };

  const setTooltip = (id: any) => {
    let index = id.replace('tooltip-', '').replace('-group', '');
    let canvas = document.getElementById(id);
    let tooltip = document.getElementById(`tooltip-${index}-group`);
    if (canvas && tooltip) {
      tooltip.style.display = 'flex';
      canvas.className = canvas.className + ' group-active';
    }
  };

  const groupAction = (id: any, action: string) => {
    if (action === 'edit') {
      if (props.onClickEdit) {
        props.onClickEdit(id);
      }
    }
    if (action === 'delete') {
      if (props.allowDelete && props.onClickDelete) {
        props.onClickDelete(id);
      }
    }
  };

  function getCanvasTooltip(index: number, isSystemField: boolean) {
    return (
      <div
        className="column parent-width position-absolute dk-group-tooltip"
        id={`tooltip-${index}-group`}
        style={{ top: -27, zIndex: 9, display: 'none', opacity: 0.7 }}
      >
        <div className="row justify-content-center">
          <DKButton
            icon={DKIcons.ic_edit}
            className="border-radius-none"
            style={{ padding: 5 }}
            onClick={() => {
              groupAction(index, 'edit');
            }}
          />
          {!isSystemField && (
            <DKButton
              icon={DKIcons.ic_delete}
              className="border-radius-none"
              style={{ padding: 5 }}
              onClick={() => {
                groupAction(index, 'delete');
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="row pt-l pb-s justify-content-center">
      <div className="row width-auto border-m" style={{ borderRadius: 6 }}>
        <div
          ref={groupElement}
          className="d-flex" /* "show-scroll-bar" */
          style={{
            maxWidth: controlWidth
          }}
          onMouseLeave={() => onCanvasLeave()}
        >
          {props.data.map((group: any, index: number) => (
            <div
              id={`group${group.id}`}
              key={`group-${index}`}
              data-index={group.id}
              data-name={group.name}
              className={`position-relative p-xs border-box`}
              style={{
                height: 34
              }}
              onMouseOver={(e) => {
                if (props.allowToAddGroup) {
                  tooltipShow(`tooltip-${group.id}-group`);
                  e.stopPropagation();
                }
              }}
            >
              {index !== 0 && getCanvasTooltip(group.id, group.systemField)}
              <div
                className={`row parent-height border-radius-s ${
                  props.selectedId === group.id
                    ? 'bg-button text-white fw-m'
                    : ''
                }`}
                onClick={() => props.onChange(group)}
              >
                <DKLabel
                  className={'p-h-l pt-l cursor-hand row'}
                  style={{
                    marginTop: 16,
                    overflowWrap: 'unset',
                    whiteSpace: 'nowrap',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                  text={group.name}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex position-relative">
          {showMoreList.length ? (
            <DKButton
              style={{
                borderRadius: 0,
                borderLeft: '1px solid lightgray',
                borderRightWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: 0,
                marginLeft: '-2px',
                height: 34
              }}
              className={`border-m border-radius-none z-index-1
              ${selectedGroupOverflown ? 'bg-button' : ''} ${
                !props.allowToAddGroup && 'group-ctrl-last'
              }`}
              icon={selectedGroupOverflown ? MoreIconWhite : MoreIconBlack}
              onClick={() => {
                onCanvasLeave();
                setShowGroupPicker(true);
              }}
            />
          ) : null}
          {props.allowToAddGroup && (
            <DKButton
              style={{
                borderRadius: 0,
                height: 34,
                borderLeft: '1px solid lightgray',
                borderRightWidth: 0,
                borderTopWidth: 0,
                borderBottomWidth: 0
              }}
              title={'New Group'}
              icon={DKIcons.ic_add}
              onClick={props.onClickNew}
            />
          )}
          {showGroupPicker ? (
            <DKListPicker2
              title={props.moreListPickerTitle}
              className="position-absolute border-m shadow-m z-index-3"
              style={{ top: 10, right: 32, width: 200 }}
              data={showMoreList}
              onSelect={(index: number, obj: any) => {
                props.onChange(obj);
                setShowGroupPicker(false);
              }}
              onClose={() => {
                setShowGroupPicker(false);
              }}
              renderer={(index: number, obj: any) => (
                <DKLabel
                  style={{ overflowWrap: 'anywhere' }}
                  text={
                    props.selectedId === obj.id ? `${obj.name} ✓` : obj.name
                  }
                />
              )}
              canEdit={props.allowToAddGroup}
              canDelete={props.allowToAddGroup}
              onEdit={(index: number, value: any) => {
                if (props.onClickEdit) {
                  props.onClickEdit(value.id);
                }
                setShowGroupPicker(false);
              }}
              onDelete={(index: number, value: any) => {
                if (props.allowDelete && props.onClickDelete) {
                  props.onClickDelete(value?.id);
                }
                setShowGroupPicker(false);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GroupContainer;
