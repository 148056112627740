import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import { showToast, TOAST_TYPE, showAlert } from 'deskera-ui-library';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import http3 from './StockManagementInterceptor';
import http2 from './Interceptor2';
import http from './Interceptor';

export interface StockIssueAPIConfig {
  SearchTerm?: string;
  Query?: string;
  Limit?: number;
  Page?: number;
  IsVariant?: boolean;
  type?: string;
  QueryParam?: any;
  isOB?: boolean;
  sort?: string;
  sortDir?: string;
}

const defaultConfig: StockIssueAPIConfig = {
  SearchTerm: '',
  Limit: 20,
  Page: 0,
  Query: '',
  IsVariant: false,
  type: '',
  QueryParam: '',
  sort: 'createdAt',
  sortDir: 'desc'
};
class StockIssueService {
  static apiConfig: StockIssueAPIConfig;
  static abortController: any = null;

  static getStockIssueEndPoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&${this.apiConfig.Query}&${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndpoint =
      ApiConstants.URL.PRODUCT.FETCH_STOCK_ISSUE + queryString;
    return finalEndpoint;
  }

  static createStockIssue(data: any) {
    const finalEndPoint: string = ApiConstants.URL.PRODUCT.FETCH_STOCK_ISSUE;
    return http
      .post(finalEndPoint, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getStockIssueByPage() {
    Utility.cancelRequest(StockIssueService.abortController);

    StockIssueService.abortController = Utility.createAbortController();
    return http
      .get(`${StockIssueService.getStockIssueEndPoint()}`, {
        signal: StockIssueService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getStockIssueById(id: any) {
    const finalEndpoint = ApiConstants.URL.PRODUCT.FETCH_STOCK_ISSUE + id;
    return http.get(finalEndpoint).catch((err: any) => {
      console.error('Error fetching Stock Issue: ', err);
      return Promise.reject(err);
    });
  }
  static deleteStockIssue(id: any) {
    const finalEndpoint = ApiConstants.URL.PRODUCT.FETCH_STOCK_ISSUE + id;
    return http2
    .delete(finalEndpoint)
    .catch((err: any) => {
      console.error('Error deleting stock Issue: ', err);
      return Promise.reject(err);
    });
  }
}

export default StockIssueService;
