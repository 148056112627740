import { DKInput, INPUT_VIEW_DIRECTION, INPUT_TYPE } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { REGEX } from '../../Constants/Constant';
import useDebounce from '../../Hooks/useDebounce';

interface ExtraChargeInputProps {
  charge: any;
  disabled: boolean;
  onValueChange: (obj: {
    value: string;
    isPercent: boolean;
    hasError: boolean;
  }) => void;
}

export default function ExtraChargeInput(props: ExtraChargeInputProps) {
  const [chargeValue, setChargeValue] = useState<any>(
    props.charge?.isPercent
      ? props.charge?.percentageValue
        ? props.charge?.percentageValue + '%'
        : 0
      : props.charge?.chargeValue
  );
  const debouncedValue = useDebounce(chargeValue, 500);

  useEffect(() => {
    const updatedInput = validateInput();
    props.onValueChange({
      value: debouncedValue,
      isPercent: validateInput().isPercent,
      hasError: updatedInput.isInvalid
    });
  }, [debouncedValue]);

  useEffect(() => {
    setChargeValue(
      props.charge?.isPercent
        ? props.charge?.percentageValue
          ? props.charge?.percentageValue + '%'
          : 0
        : props.charge?.chargeValue
    );
  }, [props.charge]);

  const validateInput = () => {
    let cValue = debouncedValue;
    let chargeValueInvalid = false;
    let isPercent = false;
    const matcher = String(cValue).match(REGEX.PERCENT_NUMER);
    if (!matcher) {
      chargeValueInvalid = true;
    } else if ('%' === matcher[4]) {
      const percentDiscount = Number(cValue.replace('%', ''));
      if (percentDiscount > 100) {
        chargeValueInvalid = true;
      } else {
        chargeValueInvalid = false;
        cValue = percentDiscount;
      }
      isPercent = true;
    } else if (cValue < 0) {
      chargeValueInvalid = true;
    }
    return { isInvalid: chargeValueInvalid, isPercent };
  };

  return (
    <DKInput
      required={true}
      canValidate={validateInput().isInvalid}
      validator={() => !validateInput().isInvalid}
      errorMessage="Invalid value"
      title=""
      type={INPUT_TYPE.TEXT}
      readOnly={props.disabled}
      value={chargeValue}
      valueStyle={{
        background: '#fff',
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 100
      }}
      textAlign="right"
      className="width-auto text-align-right"
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      onChange={(changedValue: any) => {
        setChargeValue(changedValue);
      }}
    />
  );
}
