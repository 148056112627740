import { HTMLTag } from '../../Utilities/HTMLTag';
import './Toast.css'


export const TOAST_DIRECTION = {
    TOP: "top",
    BOTTOM: "bottom",
};

export const TOAST_TYPE = {
    DEFAULT: "default",
    SUCCESS: "success",
    FAILURE: "failure",
    WARNING: "warning",
};


export function showToast(
    message,
    type = TOAST_TYPE.DEFAULT,
    direction = TOAST_DIRECTION.TOP
) {
    let div = document.createElement("div");

    let type_class = "doc-build-toast-" + type;
    let type_direction = "doc-build-toast-" + direction;
    div.className = "doc-build-toast " + type_class + " " + type_direction;

    div.innerHTML = message;

    let parent = document.getElementById(HTMLTag.MAIN_HOLDER_ID);
    parent.appendChild(div);

    setTimeout(() => {
        parent.removeChild(div);
    }, 2000);
}


