import Utility, { getCapitalized } from '../Utility/Utility';

class TableDataParser {
  static parseTableData(data: any) {
    let tableData: any = {};
    tableData['id'] = data._id;
    tableData['name'] = data.name;
    tableData['columns'] = this.parseColumnData(data.columnsMetaData);
    return tableData;
  }
  static parseColumnData(data: any) {
    let columnsData: any[] = [];
    data.forEach((column: any) => {
      let data = { ...column };
      data['columnCode'] = column?.columnCode ?? column?.key;
      data['key'] = column.id;
      data['type'] = getCapitalized(column.type);
      data['options'] = column.options ? column.options : [];
      data['required'] = column.required ? column.required : false;
      data['width'] = column.width ? column.width : 200;
      columnsData.push(data);
    });
    return columnsData;
  }
  static parseRowData(data: any, table: any = null) {
    return data.map((row: any) => {
      // this.checkAndReplaceDirectLink(row, table as string); // TODO - Need to remove this @Piyush this is a workaround
      return { ...row.cells, id: row._id };
    });
  }
  static getColumnToAdd(column: any) {
    return {
      name: column.name,
      type: column.type,
      options: column.options || [],
      required: column.required || false,
      width: column.width || 200,
      editable: column.editable || true,
      hidden: column.hidden || false,
      systemField: column.systemField || false,
      uiVisible: column.uiVisible || true
    };
  }
  static getRowToAdd(rowData: any) {
    let cells: any = {};
    for (let key in rowData) {
      cells[key] = rowData[key].value;
    }
    return { cells };
  }

  // static checkAndReplaceDirectLink(row: any, table: string) {
  //   if (table !== null) {
  //     const column_id = TableManager.getColumnId(
  //       table,
  //       COLUMN_CODE.FORMS.DIRECT_LINK
  //     ) as string;
  //     row.cells[column_id] = AppManager.generateDirectLink(
  //       row.cells[column_id]
  //     );
  //   }
  // }

  static getSanitizedJsonData(jsonData: any) {
    try {
      const jsonString = JSON.stringify(jsonData);
      const sanitizedData = JSON.parse(Utility.sanitizeJSON(jsonString));
      return sanitizedData;
    } catch (err) {
      return jsonData;
    }
  }
}

export default TableDataParser;
