import ApiConstants from '../Constants/ApiConstants';
import http2 from './Interceptor2';

class AppIntegrationService {
  static getAuthURL(): Promise<any> {
    return http2.get(ApiConstants.URL.APP_INTEGRATION.GET_AUTH_URL);
  }
  static connectToApp(payload: any): Promise<any> {
    return http2.post(ApiConstants.URL.APP_INTEGRATION.CONNECT, payload);
  }
  static disconnectApp(payload: any): Promise<any> {
    const finalUrl = `${ApiConstants.URL.APP_INTEGRATION.DISCONNECT}?app=${payload.app}&username=${payload.username}`;
    return http2.post(finalUrl);
  }
  static getAppIntegrationMapping(): Promise<any> {
    return http2.get(ApiConstants.URL.APP_INTEGRATION.MAPPING);
  }
}

export default AppIntegrationService;
