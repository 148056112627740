import React, { useEffect, useRef, useState } from 'react';
import {
  DKCheckMark,
  DKIcon,
  DKLabel,
  DKIcons,
  DKListPicker2,
  DKButton,
  DKLine,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from 'deskera-ui-library';
import {
  AdditionalChargeDetails,
  AdditionalChargeForDoc,
  Document,
  GlobalDiscountDetails
} from '../../Models/Document';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import Utility, { deepClone } from '../../Utility/Utility';
import { DocumentItem } from '../../Models/DocumentItem';
import {
  BOOKS_DATE_FORMAT,
  COMPLAINCE_CURRENCY,
  COUNTRY_CODES,
  CURRENCIES,
  CURRENCY_PRECISION,
  DOCUMENT_MODE,
  DOC_TYPE,
  GOOGLE_NO_TRANSLATE_CLASS,
  GST_TYPE,
  MODULE_TYPE,
  PRODUCT_OFFERING_TYPE,
  PRODUCT_TYPE,
  REGEX,
  ROUNDING_METHOD,
  TAX_SYSTEM
} from '../../Constants/Constant';
import FormUtil from '../FormUtil/FormUtil';
import {
  ausConfig,
  belgiumConfig,
  caConfig,
  germanyConfig,
  inConfig,
  indonesiaConfig,
  myConfig,
  nzConfig,
  phConfig,
  sgConfig,
  uaeConfig,
  usConfig,
  ukConfig
} from '../FormUtil/FormDocumentUtilConstants';
import { useTranslation } from 'react-i18next';
import NumberFormatService from '../../Services/NumberFormat';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import TCSSelection from '../DocumentForm/TCSSelection';
import TCSService, { TCSSetupAPIConfig } from '../../Services/TCSSetup';
import {
  fetchTCSRates,
  selectTCSRates,
  selectActiveTCSRates
} from '../../Redux/Slices/TCSSetupSlice';
import AddTCSRate from '../../Components/Settings/TCSSetup/AddTCSRate';
import { CallBackPayloadType } from '../../Models/Interfaces';
import {
  convertToCurrenctExchangeRate,
  getTenantTaxSystem,
  roundingOffStr
} from './NewDocumentHelper';
import {
  selectCurrencyListWithExchangeRate,
  selectPurchaseTax,
  selectSalesTax
} from '../../Redux/Slices/CommonDataSlice';
import AuthService from '../../Services/Auth';
import { Store } from '../../Redux/Store';
import {
  selectAdditionalBuyCharges,
  selectAddtionalSellCharges,
  selectAllAdditionalBuyCharges,
  selectAllAdditionalSellCharges
} from '../../Redux/Slices/AdditionalChargesSlice';
import AddAdditionalCharges from '../../Components/Invoices/AddAdditionalCharges';
import DateFormatService from '../../Services/DateFormat';
import TaxService from '../../Services/Tax';
import ExtraChargeInput from './ExtraChargeInput';
import useDebounce from '../../Hooks/useDebounce';
import AdditionalCharges from '../AdditionalCharges/AdditionalCharges';

export interface SummayProps {
  booksDocument: Document;
  documentMode: DOCUMENT_MODE;
  onRoundingOffChange?: (roundingOffValue: number) => void;
  onTaxInclusiveFlagChange?: () => void;
  onTCSChange?: (newTCS: any) => void;
  onTotalOrAdditionalChargeChange?: (obj: any) => void;
  onCurrencyAndExchangeRateChange?: (
    currency: string,
    exchangeRate: number
  ) => void;
  onGSTExchangeRateChange?: (rate: number) => void;
  roundOffDirty?: boolean;
  onAllocateAdditionalCostProductLevel?: any;
  onAdditionalChargeAllocationOnLineLevel?: (obj: any) => void;
}

interface TotalCalculationDeps {
  subTotal: number;
  totalWithDiscount: number;
  tax: number;
  discount: number;
  tdsAmount: number;
  tcsAmount: number;
}

interface GstAndCess {
  sgst: number;
  cgst: number;
  igst: number;
  cess: number;
}

enum GlobalDisountOption {
  PRE_TAX = 'Pre-Tax',
  POST_TAX = 'Post-Tax'
}

export default function RevRecDocumentSummaryView(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const buildSelectedCharges = (
    additionalCharges: AdditionalChargeDetails[]
  ) => {
    return additionalCharges.map((charge: AdditionalChargeDetails) => {
      const moduleType = Utility.isSalesDocument(booksDocument)
        ? MODULE_TYPE.SELL
        : MODULE_TYPE.BUY;
      let chargesFromStore =
        moduleType === MODULE_TYPE.SELL
          ? allAdditionalSellCharges
          : allAdditionalBuyCharges;
      let filteredCharge =
        chargesFromStore?.find((chargeFromStore: any) => {
          if (charge.id) {
            return charge.id === chargeFromStore.id;
          }
          return charge.additionalCharge === chargeFromStore.name;
        }) || {};
      return {
        ...filteredCharge,
        purchaseTaxCode: charge.addtionalChargeTaxCode,
        salesTaxCode: charge.addtionalChargeTaxCode,
        isPercent: charge.isPercent,
        percentageValue: charge.percent,
        chargeValue: charge.chargeAmount ? +charge.chargeAmount : 0,
        taxAmount: charge.taxAmount,
        includeInReport: filteredCharge.includeInReport,
        apportionFlag: charge?.apportionFlag || null,
        apportionValue: charge?.apportionValue || null
      };
    });
  };

  const getGlobalDiscountValue = () => {
    const gDiscount = props.booksDocument.additionalCharges
      ?.globalDiscount as GlobalDiscountDetails;
    let discountInputValue: any = '';
    if (gDiscount) {
      if (gDiscount.isPercent) {
        discountInputValue = `${gDiscount.percent}%`;
      } else {
        discountInputValue = gDiscount.amount;
      }
    } else {
      discountInputValue = 0;
    }
    return discountInputValue;
  };

  const [booksDocument, setBooksDocument] = useState(props.booksDocument);
  const [recalculateRounding, setRecalculateRounding] = useState(false);
  const [isManualRoundOff, setIsManualRoundOff] = useState(false);
  const [isAutoRoundOffCalculated, setIsAutoRoundOffCalculated] =
    useState(false);
  const [showTaxGroupDetails, setShowTaxGroupDetails] = useState(false);
  const [autoRoundOffValue, setAutoRoundOffValue] = useState<any>({});
  const [showTCSPicker, setShowTCSPicker] = useState(false);
  const [tcsDetails, setTCSDetails] = useState<any>(null);
  const [taxGroupDetail, setTaxGroupDetail] = useState<any>({});
  const [complianceFields, setComplianceFields] = useState<any>(null);
  const [hasTaxGroup, setHasTaxGroup] = useState(false);
  const [showPaymentGroupDetails, setShowPaymentGroupDetails] = useState(false);
  // const taxSystem = getTenantTaxSystem();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showAddAdditionalCharges, setShowAddAdditionalCharges] =
    useState(false);
  const addtionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);
  const additionalSellCharges = useAppSelector(selectAddtionalSellCharges);
  const allAdditionalBuyCharges = useAppSelector(selectAllAdditionalBuyCharges);
  const allAdditionalSellCharges = useAppSelector(
    selectAllAdditionalSellCharges
  );
  const salesTaxes = useAppSelector(selectSalesTax);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const [selectedCharges, setSelectedCharges] = useState<any[]>(
    buildSelectedCharges(
      props.booksDocument.additionalCharges?.additionalChargesDetails || []
    )
  );
  const [selectedAllocateCost, setSelectedAllocateCost] = useState<any>();
  const [globalDiscount, setGlobalDiscount] = useState<
    GlobalDiscountDetails | any
  >(
    props.booksDocument.additionalCharges?.globalDiscount
      ? props.booksDocument.additionalCharges?.globalDiscount
      : undefined
  );
  const [selectChargeForEdit, setSelectChargeForEdit] = useState();
  const [showChargeDiscountPicker, setShowChargeDiscountPicker] =
    useState(false);
  const [showGlobalDiscountBlock, setShowGlobalDiscountBlock] = useState(
    !Utility.isEmpty(props.booksDocument.additionalCharges?.globalDiscount) &&
      props.booksDocument.additionalCharges?.globalDiscount?.amount !== 0
  );
  const [showGlobalDiscountOptions, setShowGlobalDiscountOptions] =
    useState(false);

  const [globalDiscountValue, setGlobalDiscountValue] = useState<any>(
    getGlobalDiscountValue()
  );
  const [showAllAdditionalCharges, setShowAllAdditionalCharges] =
    useState(false);
  const [globalDiscountInvalid, setGlobalDiscountInvalid] = useState(false);
  const debouncedGlobalDiscountValue = useDebounce(globalDiscountValue, 500);
  const isGlobalDiscountUpdatedManually = useRef<boolean>(false);

  const [showTCSField, setShowTCSField] = useState(false);
  const [createTCSRate, setCreateTCSRate] = useState(false);
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [tempExchangeRate, setTempExchangeRate] = useState(
    +roundingOffStr(
      booksDocument.exchangeRate ? 1 / booksDocument.exchangeRate : 0,
      CURRENCY_PRECISION
    )
  );
  const [roundOffValue, setRoundOffValue] = useState(
    booksDocument.roundOffAmountInDocumentCurrency
  );

  const [showAdditionalChargesPopup, setShowAdditionalChargesPopup] =
    useState<boolean>(false);

  const [allocateAdditionalCostItems, setAllocateAdditionalCostItems] =
    useState<any[]>([]);

  const showCommonTaxBlocks = () => {
    let flag = true;
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (!tenantInfo.complianceEnabled) {
        flag = false;
      } else {
        if (
          props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER
        ) {
          flag = false;
        }
      }
    }

    return flag;
  };

  const [totalCalculationDeps, setTotalCalculationDeps] =
    useState<TotalCalculationDeps>({
      subTotal: 0,
      totalWithDiscount: 0,
      tax: 0,
      discount: 0,
      tdsAmount: 0,
      tcsAmount: 0
    });

  const [gstAndCess, setGstAndCess] = useState<GstAndCess>({
    sgst: 0,
    cgst: 0,
    igst: 0,
    cess: 0
  });

  const [total, setTotal] = useState<number>(0);
  const [revTotal, setRevTotal] = useState<any>('');
  const [revSubtotal, setRevSubtotal] = useState<any>('');
  const [revDiscount, setRevDiscount] = useState<any>('');
  const [revBeforeTax, setRevBeforeTax] = useState<any>('');

  const [additionalCostRetainData, setAdditionalCostReatainData] = useState<
    any[]
  >([]);

  const [disableAutoRoundOff, setDisableAutoRoundOff] = useState(true);

  const tcsRatesData = useAppSelector(selectActiveTCSRates);
  const loadTCSRates = async (config: TCSSetupAPIConfig) => {
    try {
      TCSService.apiConfig = config;
      dispatch(fetchTCSRates(tenantInfo.id));
    } catch (err) {
      console.error('Error loading Accounts: ', err);
    }
  };

  useEffect(() => {}, []);
  useEffect(() => {
    let totalList = props
      ? props.revElements.map((item: any) => {
          return item.revAmountInBase || item.revAmount;
        })
      : [];
    let totalDiscountList = props
      ? props.revElements.map((item: any) => {
          return item.discount;
        })
      : [];
    let totalDiscount = totalDiscountList.reduce((a: any, b: any) => a + b, 0);
    let subTotal = totalList.reduce((a: any, b: any) => a + b, 0);
    let total = props ? props.revSubtotal : 0;

    setRevTotal(total);
    setRevSubtotal(subTotal);
    setRevDiscount(totalDiscount);
    setRevBeforeTax(subTotal - totalDiscount);
  }, [props]);

  const getComplianceSubtotal = () => {
    if (!Utility.isEmpty(complianceFields)) {
      return (
        <FormUtil
          parentData={booksDocument}
          complianceFields={complianceFields}
          updateParentData={(key: any, value: any) => {
            // updateProductData(key, value);
          }}
          sectionType={'subtotalTax'}
          isDocument={true}
        />
      );
    } else {
      return <></>;
    }
  };

  const getTaxGroupDetails = () => {
    let taxGroupContainer;
    let extraChargeTaxesContainer;
    //move
    if (tenantInfo.country === 'IN') {
      taxGroupContainer = (
        <>
          {booksDocument.gstType !== GST_TYPE.EXEMPT &&
            getTileWithBullet('Tax on Sub-total')}
          {booksDocument.gstType === GST_TYPE.INTER &&
            getTitleAndAmount(
              `+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.IGST`)}`,
              gstAndCess.igst,
              null,
              'fw-r ml-xxl'
            )}
          {booksDocument.gstType === GST_TYPE.INTRA &&
            getTitleAndAmount(
              `+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.CGST`)}`,
              //   totalCalculationDeps.tax / 2,
              gstAndCess.cgst,
              null,
              'fw-r ml-xxl'
            )}
          {booksDocument.gstType === GST_TYPE.INTRA &&
            getTitleAndAmount(
              `+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SGST`)}`,
              //   totalCalculationDeps.tax / 2,
              gstAndCess.sgst,
              null,
              'fw-r ml-xxl'
            )}
          {gstAndCess.cess !== 0 &&
            getTitleAndAmount(
              `+ ${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.CESS`)}`,
              gstAndCess.cess,
              null,
              'fw-r ml-xxl'
            )}
          {!booksDocument.gstType &&
            getTitleAndAmount(
              `+ Tax`,
              totalCalculationDeps.tax,
              null,
              'fw-r ml-xxl'
            )}
        </>
      );
    } else {
      taxGroupContainer = Object.keys(taxGroupDetail).map((key: any, index) => {
        //   taxGroupContainer
        return (
          <>
            {key !== 'extraChargeTaxes' &&
              getTileWithBullet('Tax on Sub-total')}
            {key !== 'extraChargeTaxes' &&
              taxGroupDetail.length === 0 &&
              getTitleAndAmount(
                `+ Tax`,
                totalCalculationDeps.tax,
                null,
                'fw-r ml-xxl'
              )}
            {key !== 'extraChargeTaxes' &&
              getTitleAndAmount(key, taxGroupDetail[key], null, 'fw-r ml-xxl')}
          </>
        );
      });
    }

    extraChargeTaxesContainer = taxGroupDetail.extraChargeTaxes?.map(
      (taxDetail: any, index: any) => {
        if (!Utility.isEmpty(taxDetail.name)) {
          return <>{getExtraChargeTitleAndAmount(taxDetail)}</>;
        } else {
          return <></>;
        }
      }
    );

    return (
      <>
        {taxGroupContainer}
        {extraChargeTaxesContainer}
      </>
    );
  };

  const hasTaxGroupDetails = () => {
    return Boolean(
      (tenantInfo?.country === 'IN' &&
        (gstAndCess.cess !== 0 ||
          [GST_TYPE.INTER, GST_TYPE.INTRA].includes(
            booksDocument.gstType as GST_TYPE
          ))) ||
        Object.keys(taxGroupDetail).length
    );
  };

  //move tax controls into formutil
  const getExpandableContainer = (
    hasGroup: boolean,
    needPaymentContainer: boolean
  ) => {
    let needToShowGroupDetails = hasTaxGroupDetails(),
      totalPayment = 0;

    if (needPaymentContainer && booksDocument?.knockoffInfo) {
      totalPayment = booksDocument.knockoffInfo.reduce(
        (total: number, paymentInfo: any) =>
          total + Number(paymentInfo?.amount * paymentInfo?.exchangeRate || 0),
        0
      );

      needToShowGroupDetails = Boolean(totalPayment);
    }

    if (hasGroup) {
      return !needPaymentContainer || totalPayment ? (
        <>
          <div
            className="row width-auto mb-m justify-content-between align-items-start"
            style={{
              width: '100%',
              pointerEvents: 'all',
              cursor: needToShowGroupDetails ? 'pointer' : 'default'
            }}
          >
            <div
              className="row width-auto"
              style={{
                minWidth: 100
              }}
              onClick={
                needPaymentContainer
                  ? () => setShowPaymentGroupDetails((prevState) => !prevState)
                  : () => setShowTaxGroupDetails((prevState) => !prevState)
              }
            >
              <div className="flex flex-row align-items-center">
                <span className="mr-1 ml-r fw-m ">
                  {needPaymentContainer ? 'Payments' : 'Tax (+)'}
                </span>
                {needToShowGroupDetails ? (
                  <DKIcon
                    src={DKIcons.ic_arrow_right}
                    className="ml-xs ic-xs opacity-5"
                    style={{
                      transition: 'transform 0.1s ease-in-out',
                      transform: (
                        needPaymentContainer
                          ? showPaymentGroupDetails
                          : showTaxGroupDetails
                      )
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)'
                    }}
                  />
                ) : null}
              </div>
            </div>
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                booksDocument.currency
              )} ${NumberFormatService.getNumber(
                needPaymentContainer
                  ? totalPayment
                  : totalCalculationDeps.tax +
                      getAdditionalChargesTotalAndTax().tax
              )}`}
              style={{
                wordBreak: 'break-all'
              }}
              className={`ml-r text-wrap ${GOOGLE_NO_TRANSLATE_CLASS}`}
            />
            {/* <div className="flex flex-row items-end justify-end w-24">
              <FormatAmount
                value={totalCalculationDeps.tax}
                currencyCode={tenantInfo.currency}
              />
            </div> */}
          </div>
          {needPaymentContainer
            ? showPaymentGroupDetails
              ? getPaymentsGroup()
              : null
            : showTaxGroupDetails
            ? getTaxGroupDetails()
            : null}
        </>
      ) : null;
    } else {
      return getComplianceSubtotal();
    }
  };

  const getFormattedNumber = (number: any) => {
    return number ? NumberFormatService.getNumber(Math.abs(+number)) : '0';
  };

  const baseCurrencyTotal = () => {
    let baseTotal =
      total && booksDocument.exchangeRate
        ? Utility.roundOff((total * 1) / booksDocument.exchangeRate)
        : 0;
    return baseTotal;
  };

  const getTileWithBullet = (title: string) => {
    return (
      <DKLabel
        text={title}
        className="column parent-width mb-xs"
        style={{
          display: 'list-item',
          listStyleType: 'disc',
          listStylePosition: 'inside',
          marginLeft: 18
        }}
      />
    );
  };

  // Extra charge details
  const getExtraChargeTitleAndAmount = (
    extraChargeDetail: any,
    currencyCode?: string
  ) => {
    return (
      <>
        {getTileWithBullet(extraChargeDetail.name)}
        {extraChargeDetail.taxes?.map((tax: any, index: number) => (
          <div
            className={`row parent-width justify-content-between align-items-start ${
              index === extraChargeDetail?.taxes?.length - 1 ? 'mb-m' : 'mb-xs'
            }`}
            style={{ width: '100%' }}
          >
            <div
              className="row width-auto"
              style={{
                minWidth: 100
              }}
            >
              <div className="column parent-width">
                <DKLabel
                  text={`${tax.taxName ? '+ ' + tax.taxName : '+ Tax'}`}
                  style={{
                    marginLeft: 32
                  }}
                />
                <DKLabel
                  text={tax.hsnOrSacInfo}
                  className="text-gray fs-s"
                  style={{
                    marginLeft: 32
                  }}
                />
              </div>
            </div>
            <DKLabel
              text={`${Utility.getCurrencySymbolFromCode(
                currencyCode ? currencyCode : booksDocument.currency
              )} ${`${
                tax.taxAmount < 0 ? '(' : ''
              }${NumberFormatService.getNumber(Math.abs(tax.taxAmount))}${
                tax.taxAmount < 0 ? ')' : ''
              }`}`}
              style={{
                wordBreak: 'break-all'
              }}
              className={`ml-r text-wrap ${GOOGLE_NO_TRANSLATE_CLASS}`}
            />
          </div>
        ))}
      </>
    );
  };

  const getTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    currencyCode?: string
  ) => {
    let amountText = `${amount}`;
    if (!isNaN(amount)) {
      amountText = `${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
        Math.abs(amount)
      )}${amount < 0 ? ')' : ''}`;
    }

    return (
      <>
        <div
          className="row parent-width mb-m justify-content-between align-items-start"
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
          >
            {icon && (
              <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
            )}
            <DKLabel text={title} className={'ml-r ' + titleClassName} />
            {title === t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`) &&
              tenantInfo.multicurrencyEnabled &&
              getCurrencySelector()}
          </div>
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              currencyCode
                ? currencyCode
                : booksDocument.currency
                ? booksDocument.currency
                : tenantInfo.currency
            )} ${amountText}`}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap ${
              amountClassName || ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
          />
          {/* <div className={'ml-r ' + amountClassName}>
          <FormatAmount
            value={amount}
            currencyCode={tenantInfo.currency}
          />
        </div> */}
        </div>
      </>
    );
  };
  const getTotalTitleAndAmount = (
    title: string,
    amount: number,
    icon: any,
    titleClassName: string,
    amountClassName?: string,
    currencyCode?: string
  ) => {
    let amountText = `${amount}`;
    if (!isNaN(amount)) {
      amountText = `${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
        Math.abs(amount)
      )}${amount < 0 ? ')' : ''}`;
    }

    return (
      <>
        <div
          className="row parent-width mb-m justify-content-between align-items-start"
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto"
            style={{
              minWidth: 100
            }}
          >
            {icon && (
              <DKIcon src={icon} className="ic-s" style={{ opacity: 0.6 }} />
            )}
            <DKLabel text={title} className={'ml-r ' + titleClassName} />
            {title === t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`) &&
              tenantInfo.multicurrencyEnabled &&
              getCurrencySelector()}
          </div>
          <DKLabel
            text={`${amountText}`}
            style={{
              wordBreak: 'break-all'
            }}
            className={`ml-r text-wrap ${
              amountClassName || ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
          />
          {/* <div className={'ml-r ' + amountClassName}>
          <FormatAmount
            value={amount}
            currencyCode={tenantInfo.currency}
          />
        </div> */}
        </div>
      </>
    );
  };

  const getCurrencyRateField = () => {
    const baseCurrTotal = baseCurrencyTotal();
    return (
      <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
        <div className="parent-width">
          <DKLabel
            text={`Conversion: ${
              booksDocument.exchangeRate &&
              NumberFormatService.getNumber(baseCurrTotal)
            }(${tenantInfo.currency}) at`}
          />
          <div
            className={
              'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
              'focus:outline-none focus:ring-2 focus:ring-blue-200'
            }
          >
            <div
              className="row currency-select-trigger align-items-center content-start"
              style={{ width: 'fit-content' }}
            >
              <div className="ml-1">{`1 ${booksDocument.currency}`}</div>
              <div className="currency-dropdown-flag ml-2">
                <span
                  className={`currency-dropdown-selector-flag flag ${booksDocument.currency}`}
                ></span>
              </div>
              <DKIcon
                className="ml-r"
                style={{
                  transform: 'rotate(90deg)'
                }}
                src={DKIcons.ic_sort}
              />
            </div>
            <div className="row align-items-start content-end ml-s">
              <input
                onBlur={(e) => {
                  const rate = e.target.value
                    ? +e.target.value
                    : booksDocument.exchangeRate;
                  if (
                    props.onCurrencyAndExchangeRateChange &&
                    +e.target.value !== 0 &&
                    booksDocument.exchangeRate !== 1 / (rate as number)
                  ) {
                    props.onCurrencyAndExchangeRateChange(
                      booksDocument.currency,
                      1 / (rate as number)
                    );

                    setTimeout(() => {
                      isGlobalDiscountUpdatedManually.current = false;
                      const allExtraCharges = selectedCharges.map(
                        (charge: any) => {
                          return {
                            ...charge,
                            isManualChange: false
                          };
                        }
                      );
                      setSelectedCharges([...allExtraCharges]);
                    }, 100);
                  }
                }}
                className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                value={tempExchangeRate}
                type={'number'}
                onChange={(e: any) => {
                  setTempExchangeRate(+e.target.value);
                }}
                style={{ width: '62%' }}
              />
              <div className="ml-1">{`${tenantInfo.currency}`}</div>
              <div className="currency-dropdown-flag ml-2">
                <span
                  className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getPaymentsGroup = () => {
    return (
      <>
        {booksDocument.knockoffInfo?.map((paymentInfo: any) => (
          <div key={paymentInfo.documentCode} className="parent-width">
            {getTitleAndAmount(
              paymentInfo.documentCode,
              paymentInfo.amount,
              null,
              'fw-r ml-xxl',
              '',
              paymentInfo.currency
            )}
          </div>
        ))}
      </>
    );
  };
  const getTCSField = () => (
    <div
      className="row width-auto mb-l justify-content-between position-relative"
      style={{ width: '100%' }}
    >
      {!showTCSPicker && (
        <div
          className="row width-auto cursor-pointer"
          onClick={() => setShowTCSPicker(true)}
        >
          <DKLabel
            text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TCS`)}
            className={'fw-m ml-r'}
          />
          {tcsDetails && (
            <DKLabel text={`(${tcsDetails.taxName})`} className={`ml-r`} />
          )}
          <DKIcon
            src={DKIcons.ic_arrow_down}
            className="ic-xs ml-r opacity-5"
          />
        </div>
      )}
      {showTCSPicker && (
        <TCSSelection
          rateId={props.booksDocument?.tcsRateId}
          onClose={() => setShowTCSPicker(false)}
          onNewAdd={() => setCreateTCSRate(true)}
          onSelect={(index: number, rate: any) => {
            props.onTCSChange?.(rate);
            setTCSDetails(rate);
            setShowTCSPicker(false);
          }}
          className="position-absolute z-index-3"
        />
      )}

      <div className="flex flex-col items-end w-24 number-hide-arrows">
        <DKLabel
          text={DocumentConfigUtility.amountFormatter(
            totalCalculationDeps.tcsAmount,
            booksDocument.currency
          )}
          className={'ml-r ' + GOOGLE_NO_TRANSLATE_CLASS}
        />
      </div>
    </div>
  );

  const getAddTCSView = () => (
    <AddTCSRate
      isEdit={false}
      passingInteraction={(callback: CallBackPayloadType) => {
        setTCSDetails(callback?.data);
        props.onTCSChange?.(callback?.data);
        setCreateTCSRate(false);
      }}
      onCancel={() => {
        setCreateTCSRate(false);
      }}
    />
  );

  const getCurrencySelector = () => {
    return (
      <div className="position-relative ml-s">
        <DKButton
          title={`${
            booksDocument?.currency
              ? booksDocument?.currency
              : tenantInfo.currency
          }`}
          className="text-black bg-gray-200 border-radius-l"
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 2,
            paddingBottom: 2
          }}
          onClick={() => {
            if (tenantInfo.multicurrencyEnabled) {
              setShowMultiCurrencyList((prevValue) => !prevValue);
            }
          }}
          icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
          isReverse
        />
        {tenantInfo.multicurrencyEnabled && showMultiCurrencyList && (
          <DKListPicker2
            title="Currencies"
            data={activeMultiCurrencyList.filter((item: any) => {
              return item.currencyStatus === 'ACTIVE';
            })}
            style={{
              width: 280
            }}
            allowSearch={true}
            searchableKey="currencyName"
            className="position-absolute z-index-3 right-0 bottom-7 shadow-m"
            onSelect={(index: number, currency: any) => {
              setShowMultiCurrencyList(false);
              if (props.onCurrencyAndExchangeRateChange) {
                props.onCurrencyAndExchangeRateChange(
                  currency.currencyCode,
                  currency.currencyExchangeRate
                );
              }
              setTimeout(() => {
                isGlobalDiscountUpdatedManually.current = false;
                const allExtraCharges = selectedCharges.map((charge: any) => {
                  return {
                    ...charge,
                    isManualChange: false
                  };
                });
                setSelectedCharges([...allExtraCharges]);
              }, 100);
            }}
            onClose={() => {
              setTimeout(() => {
                setShowMultiCurrencyList(false);
              }, 100);
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width justify-content-between">
                  <div>{obj.currencyName}</div>
                  <div className="ml-s">{`(${obj.currencyCode})`}</div>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  };

  const showBaseTaxForSG = () => {
    return (
      getTenantTaxSystem() === TAX_SYSTEM.SG &&
      tenantInfo.currency !== CURRENCIES.SG
    );
  };

  const getBaseCurrency = (): string => {
    return CURRENCIES[tenantInfo.country];
  };

  const totalWithoutRounding = () => {
    let total = booksDocument.items
      ? booksDocument.items.reduce(
          (total: any, docItem: any) => total + (docItem.total as number),
          0
        )
      : 0;
    return Number(total);
  };

  const manualRoundOffChange = (e: any) => {
    setIsManualRoundOff(true);
    handleRoundingOffChange(e);
  };
  const handleRoundingOffChange = (e: any) => {
    const value = e.target.value;
    if (
      props.onRoundingOffChange &&
      (value === '' ||
        value === '-' ||
        (+value <= totalWithoutRounding() &&
          +value >= Math.sign(-1) * totalWithoutRounding()))
    ) {
      setRoundOffValue(value);
      if (totalWithoutRounding() - Math.abs(value) >= 0) {
        props.onRoundingOffChange(value);
      }
    }
  };

  // Open additional charge popup
  const getAdditionalChargesPopUp = () => {
    return (
      showAddAdditionalCharges && (
        <AddAdditionalCharges
          object={selectChargeForEdit}
          onCreated={(charge: any) => {
            if (!Utility.isEmpty(charge)) {
              getExtraChargeAmountAndTax(charge, charge.isPercent).then(
                (data) => {
                  charge = {
                    ...charge,
                    chargeValue: data.chargeAmount,
                    taxAmount: data.taxOnChargeAmount
                  };
                  setSelectedCharges([...selectedCharges, charge]);
                }
              );
            }
          }}
          onClose={() => {
            setShowAddAdditionalCharges(false);
          }}
        />
      )
    );
  };

  // Get sales/purchase tax details of a charge
  const getTax = (charge: any) => {
    let tax: any = null;
    const country = tenantInfo.country;
    if (
      (country === COUNTRY_CODES.IN || country === COUNTRY_CODES.SG) &&
      charge.isTaxable
    ) {
      let taxCode: any = null;
      if (Utility.isSalesDocument(booksDocument)) {
        taxCode = charge.salesTaxCode;
        tax = salesTaxes.find((tax: any) => tax.code === taxCode);
        return tax ? tax : taxCode === '' ? null : taxCode;
      } else if (
        booksDocument.documentType === DOC_TYPE.BILL ||
        booksDocument.documentType === DOC_TYPE.ORDER
      ) {
        taxCode = charge.purchaseTaxCode;
        tax = purchaseTaxes.find((tax: any) => tax.code === taxCode);
        return tax ? tax : taxCode === '' ? null : taxCode;
      }
    }
    return tax;
  };

  const getTaxBreakUpForIndia = (taxAmount: number) => {
    let taxes = {
      igst: 0,
      sgst: 0,
      cgst: 0
    };
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      if (booksDocument.gstType === GST_TYPE.INTER) {
        taxes = {
          ...taxes,
          igst: taxAmount
        };
      }
      if (booksDocument.gstType === GST_TYPE.INTRA) {
        taxes = {
          ...taxes,
          cgst: taxAmount / 2,
          sgst: taxAmount / 2
        };
      }
    }
    return taxes;
  };

  // Create/Update additional charge object for document for submission
  const buildAdditionalChargesObject = (additionalChargeDetailedObj?: any) => {
    const additionalChargesAmountInfo = getAdditionalChargesTotalAndTax();

    let additionalChargeForDoc: AdditionalChargeForDoc = {
      globalDiscount: { ...globalDiscount },
      additionalChargeAmount: additionalChargesAmountInfo.total,
      additionalChargeTaxAmount: additionalChargesAmountInfo.tax,
      additionalChargesDetails: selectedCharges.map((obj: any) => {
        let charge: any = { ...obj };
        if (
          !Utility.isEmpty(additionalChargeDetailedObj) &&
          charge?.name === additionalChargeDetailedObj?.name
        ) {
          charge = additionalChargeDetailedObj
            ? additionalChargeDetailedObj
            : charge;
        }

        const accountCode = Utility.isSalesDocument(booksDocument)
          ? charge.incomeAccountCode
          : charge.expenseAccountCode;
        let taxCode = '';
        let taxName = '';

        const breakUpForIndia = getTaxBreakUpForIndia(charge.taxAmount);

        if (charge.isTaxable) {
          const tax = getTax(charge);
          if (tax) {
            taxCode = tax.code;
            taxName = tax.name;
          }
        }

        let defaultApportionFlag = tenantInfo?.additionalSettings
          ?.ADDITIONAL_CHARGE?.independentCharge
          ? null
          : tenantInfo?.additionalSettings?.ADDITIONAL_CHARGE?.apportionFlag;
        let defaultApportionValue = tenantInfo?.additionalSettings
          ?.ADDITIONAL_CHARGE?.independentCharge
          ? null
          : tenantInfo?.additionalSettings?.ADDITIONAL_CHARGE?.apportionValue;
        let apportionFlag = charge?.apportionFlag || defaultApportionFlag;
        let apportionValue = charge?.apportionValue || defaultApportionValue;

        return {
          id: charge.id,
          additionalCharge: charge.name,
          additionalChargeAccountCode: accountCode,
          addtionalChargeTax: taxName,
          addtionalChargeTaxCode: taxCode,
          isPreCharge: charge.chargeApplicableOn === 'SUBTOTAL',
          isPercent: charge.isPercent,
          percent: charge.percentageValue,
          chargeAmount: charge.chargeValue,
          taxAmount: charge.taxAmount,
          igst: breakUpForIndia.igst,
          cgst: breakUpForIndia.cgst,
          sgst: breakUpForIndia.sgst,
          hasError: charge.hasError,
          includeInReport: charge.includeInReport,
          apportionFlag: lineItemsContainsTrackedOrBomProducts()
            ? apportionFlag || null
            : false,
          apportionValue: lineItemsContainsTrackedOrBomProducts()
            ? apportionValue || null
            : null
        };
      })
    };
    return additionalChargeForDoc;
  };

  // Get total amount and total tax of additional charges in a document
  const getAdditionalChargesTotalAndTax = () => {
    let extraChargesTotal = 0;
    let extraChargesTotalTax = 0;
    if (selectedCharges) {
      selectedCharges.forEach((charge) => {
        extraChargesTotal += charge.chargeValue
          ? Number(charge.chargeValue)
          : 0;
        extraChargesTotalTax += charge.taxAmount ? Number(charge.taxAmount) : 0;
      });
    }
    return {
      total: extraChargesTotal,
      tax: extraChargesTotalTax
    };
  };

  const showAdditionalChargeTaxes = () => {
    const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      props.booksDocument.documentType
    );

    let areTaxesVisible = true;
    if (
      tenantInfo.country === COUNTRY_CODES.IN &&
      booksDocument.gstType === GST_TYPE.EXEMPT
    ) {
      areTaxesVisible = false;
    }

    if (tenantInfo.country === COUNTRY_CODES.US && isBillOrOrder) {
      areTaxesVisible = false;
    }
    return areTaxesVisible;
  };

  // Calculate amount and tax for an additional charge
  const getExtraChargeAmountAndTax = async (
    charge: any,
    isPercent?: boolean,
    inputFieldValue?: any,
    isManualChange?: boolean
  ) => {
    const isPercentage =
      typeof isPercent !== 'undefined' ? isPercent : charge.isPercent;
    const isPreCharge = charge.chargeApplicableOn === 'SUBTOTAL';
    const beforeTaxSubTotal =
      totalCalculationDeps.subTotal - totalCalculationDeps.discount;
    const beforeTaxTotal =
      totalCalculationDeps.subTotal -
      totalCalculationDeps.discount +
      totalCalculationDeps.tax;
    let chargeAmount: any = 0;
    let taxOnChargeAmount = 0;
    if (isPercentage && !isNaN(charge.percentageValue)) {
      let percentageValue = charge.percentageValue;
      if (typeof inputFieldValue !== 'undefined' && inputFieldValue !== null) {
        percentageValue = inputFieldValue;
      }
      if (isPreCharge) {
        chargeAmount = (beforeTaxSubTotal * percentageValue) / 100;
      } else {
        chargeAmount = (beforeTaxTotal * percentageValue) / 100;
      }
      // Round off the calculated chargeAmount
      chargeAmount = Utility.roundOff(chargeAmount, tenantInfo.decimalScale);
    } else {
      if (typeof inputFieldValue !== 'undefined' && inputFieldValue !== null) {
        chargeAmount = inputFieldValue;
      } else {
        if (isManualChange || charge.isManualChange) {
          chargeAmount = charge.chargeValue ? charge.chargeValue : 0;
        } else {
          if (
            booksDocument.exchangeRate &&
            booksDocument.previousExchangeRate
          ) {
            chargeAmount = charge.chargeValue
              ? convertToCurrenctExchangeRate(
                  booksDocument.exchangeRate,
                  booksDocument.previousExchangeRate
                    ? booksDocument.previousExchangeRate
                    : 1,
                  charge.chargeValue
                )
              : 0;
            isManualChange = true;
          } else {
            chargeAmount = charge.chargeValue;
          }
        }
      }
    }

    if (charge.isTaxable) {
      const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
        props.booksDocument.documentType
      );

      if (
        (tenantInfo.country === COUNTRY_CODES.IN ||
          tenantInfo.country === COUNTRY_CODES.SG) &&
        booksDocument.gstType !== GST_TYPE.EXEMPT
      ) {
        const tax = getTax(charge);
        if (tax && !isNaN(chargeAmount)) {
          taxOnChargeAmount = (chargeAmount * tax.percent) / 100;
        }
      }

      if (tenantInfo.country === COUNTRY_CODES.US && !isBillOrOrder) {
        let payload: any = {
          companyCode: tenantInfo.avalaraCode,
          shipTo: booksDocument.shipTo,
          shipFrom: booksDocument.shipFrom,
          lines: [
            {
              amount: chargeAmount,
              description: '',
              quantity: 1,
              taxAmount: null
            }
          ],
          docDate: DateFormatService.getDateStrFromDate(
            DateFormatService.getDateFromStr(
              booksDocument.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        };
        try {
          const taxDetails = await TaxService.calculateUsTax(payload);
          taxOnChargeAmount = taxDetails.lines[0] ? taxDetails.lines[0].tax : 0;
        } catch (err) {
          console.error('Error fetching tax details: ', err);
        }
      }
    }

    return {
      chargeAmount,
      taxOnChargeAmount,
      isManualChange: isManualChange || charge.isManualChange
    };
  };
  const getTextFieldForSelectedCharge = (index: number) => {
    const charge = selectedCharges[index];
    return (
      <div>
        <ExtraChargeInput
          charge={charge}
          disabled={props.documentMode === DOCUMENT_MODE.VIEW}
          onValueChange={(obj: {
            value: string;
            isPercent: boolean;
            hasError: boolean;
          }) => {}}
        />
        {!charge.hasError && charge.isPercent && (
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              booksDocument.currency
            )} ${NumberFormatService.getNumber(charge.chargeValue)}`}
            className={'text-align-right ' + GOOGLE_NO_TRANSLATE_CLASS}
          />
        )}
      </div>
    );
  };

  const getUnselectedAdditionalCharges = (): any[] => {
    const moduleType = Utility.isSalesDocument(booksDocument)
      ? MODULE_TYPE.SELL
      : MODULE_TYPE.BUY;
    const selectedChargesIds = selectedCharges.map((charge) => charge.id);

    let unSelectedCharges: any[] =
      moduleType === MODULE_TYPE.SELL
        ? [...additionalSellCharges].filter(
            (charge) => !selectedChargesIds.includes(charge.id)
          )
        : [...addtionalBuyCharges].filter(
            (charge) => !selectedChargesIds.includes(charge.id)
          );
    return unSelectedCharges;
  };

  const getAddionalChargeFields = (index: number, existingCharge: any) => {
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
        style={{ width: '100%' }}
      >
        {props.documentMode !== DOCUMENT_MODE.VIEW && (
          <DKButton
            title=""
            icon={DKIcons.ic_delete}
            className="position-absolute child-block"
            style={{ left: -30, top: -7, opacity: 0.5 }}
            onClick={() => {
              const selectedChargesCopy = [...selectedCharges];
              selectedChargesCopy.splice(index, 1);
              setSelectedCharges(selectedChargesCopy);
            }}
          />
        )}
        <div
          className="row width-auto"
          style={{
            maxWidth: 200
          }}
        >
          <div className="column parent-width">
            <DKLabel
              text={selectedCharges[index]?.name}
              className="fs-r text-align-left ml-r fw-m"
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            />
            <DKLabel
              text={
                selectedCharges[index]?.indiaProperties
                  ? selectedCharges[index].offeringType ===
                    PRODUCT_OFFERING_TYPE.SERVICES
                    ? selectedCharges[index]?.indiaProperties?.SAC
                      ? 'SAC: ' + selectedCharges[index]?.indiaProperties?.SAC
                      : ''
                    : selectedCharges[index]?.indiaProperties?.HSN
                    ? 'HSN: ' + selectedCharges[index]?.indiaProperties?.HSN
                    : ''
                  : ''
              }
              className="ml-r text-gray fs-s"
            />
          </div>
        </div>
        {getTextFieldForSelectedCharge(index)}
      </div>
    );
  };

  const showAdditionalChargesSelector = () => {
    const countriesAllowed = [
      COUNTRY_CODES.IN,
      COUNTRY_CODES.US,
      COUNTRY_CODES.SG
    ];
    return countriesAllowed.includes(tenantInfo.country);
  };

  const showUnSelectedAdditionalChargesList = () => {
    const unSelectedCharges: any[] = getUnselectedAdditionalCharges();

    return (
      <DKListPicker2
        title="Select charge"
        data={unSelectedCharges}
        style={{
          width: 180,
          left: 140,
          top: -120
        }}
        allowSearch={true}
        searchableKey={'name'}
        canEdit={true}
        canDelete={false}
        className="position-absolute z-index-3 shadow-m border-m"
        onSelect={(index: number, charge: any) => {
          if (!Utility.isEmpty(charge)) {
            getExtraChargeAmountAndTax(charge, charge.isPercent).then(
              (data) => {
                charge = {
                  ...charge,
                  chargeValue: data.chargeAmount,
                  taxAmount: data.taxOnChargeAmount
                };
                setSelectedCharges([...selectedCharges, charge]);
                setShowAllAdditionalCharges(false);
                setShowChargeDiscountPicker(false);
              }
            );
          }
        }}
        onEdit={(index: any, charge: any) => {
          setShowAllAdditionalCharges(false);
          setShowChargeDiscountPicker(false);
          setSelectChargeForEdit(charge);
          setShowAddAdditionalCharges(true);
        }}
        onClose={() => {
          if (showAllAdditionalCharges) {
            setShowAllAdditionalCharges(false);
            setShowChargeDiscountPicker(false);
          }
        }}
        renderer={(index: number, charge: any) => {
          return (
            <div
              className="text-align-left fw-r"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {charge.name}
            </div>
          );
        }}
        button={{
          title: 'Add Charge',
          className: 'row bg-button text-white justify-content-center',
          onClick: () => {
            setSelectChargeForEdit(undefined);
            setShowAddAdditionalCharges(true);
          }
        }}
      />
    );
  };

  const getChargeAndDiscountPicker = () => {
    let optionsArr = ['Additional Charge'];
    if (!showGlobalDiscountBlock) {
      optionsArr.push('Global Discount');
    }
    return (
      <div className="row position-relative mb-m">
        {showChargeDiscountPicker && (
          <DKListPicker2
            title=""
            data={optionsArr}
            style={{
              width: 150
            }}
            allowSearch={false}
            className="position-absolute z-index-3 left-0 bottom-7 shadow-m  border-m"
            onSelect={(index: number, option: any) => {
              switch (index) {
                case 0:
                  const unSelectedCharges: any[] =
                    getUnselectedAdditionalCharges();
                  if (!unSelectedCharges.length) {
                    setSelectChargeForEdit(undefined);
                    setShowAddAdditionalCharges(true);
                  } else {
                    setShowAllAdditionalCharges(true);
                  }
                  break;
                case 1:
                  setShowGlobalDiscountBlock(true);
                  setShowChargeDiscountPicker(false);
                  setShowAllAdditionalCharges(false);
                  break;
              }
            }}
            onClose={() => {
              if (showChargeDiscountPicker) {
                setShowChargeDiscountPicker(false);
              }
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width">
                  <div>{obj}</div>
                </div>
              );
            }}
          />
        )}
        {showAllAdditionalCharges && showUnSelectedAdditionalChargesList()}
        {/* {props.documentMode !== DOCUMENT_MODE.VIEW && (
          <DKButton
            className={`${
              booksDocument.items?.length === 0 ? 'text-gray' : 'text-blue'
            } fw-m`}
            style={{ paddingLeft: 8, paddingTop: 0, paddingBottom: 0 }}
            disabled={booksDocument.items?.length === 0}
            title={`+ Add Charges${showGlobalDiscountBlock ? '' : '/Discount'}`}
            onClick={() => {
              setShowChargeDiscountPicker(!showChargeDiscountPicker);
            }}
          />
        )} */}
      </div>
    );
  };

  const lineItemsContainsTrackedOrBomProducts = () => {
    const trackedOrBomFlag = booksDocument.items?.some(
      (item: any) =>
        item?.product?.type === PRODUCT_TYPE.TRACKED ||
        item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    );
    return trackedOrBomFlag;
  };

  const showAdditionalCharges = () => {
    return selectedCharges.map((charge: any, index: number) => {
      return (
        <div className="parent-width">
          {getAddionalChargeFields(index, charge ? charge : {})}
          {(booksDocument.documentType === DOC_TYPE.BILL ||
            booksDocument.documentType === DOC_TYPE.ORDER) &&
            Utility.isUSorg() &&
            lineItemsContainsTrackedOrBomProducts() &&
            tenantInfo?.additionalSettings?.ADDITIONAL_CHARGE
              ?.apportionFlag && (
              <DKButton
                title={`Allocate Additional Cost`}
                onClick={() => {
                  if (Utility.isEmpty(charge.apportionValue)) {
                    charge = {
                      ...charge,
                      ...tenantInfo?.additionalSettings?.ADDITIONAL_CHARGE
                    };
                  }
                  setSelectedAllocateCost([charge]);
                  setShowAdditionalChargesPopup(true);
                }}
                className={`${
                  booksDocument.items?.length === 0 ? 'text-gray' : 'text-blue'
                } fw-m p-0 justify-content-end mb-5 mt-2 ${
                  props.documentMode === DOCUMENT_MODE.VIEW
                    ? 'text-gray pointer-events-auto'
                    : ''
                }`}
                style={{ marginTop: -10, zIndex: 1, paddingRight: 0 }}
                disabled={booksDocument.items?.length === 0}
              />
            )}
        </div>
      );
    });
  };

  // Global Discount Handlers
  const handleGlobalDiscountOption = (value: GlobalDisountOption) => {
    // validateAndSetGlobalDiscount(value);
    // setGlobalDiscount({
    //   ...globalDiscount,
    //   isSubTotalOnly: value === GlobalDisountOption.SUBTOTAL
    // });
  };

  const getGlobalDiscountLabelAndOptions = () => {
    return (
      <div className="column parent-width position-relative">
        <DKLabel text="Global Discount (-)" className="fw-m" />
        {showGlobalDiscountOptions && (
          <DKListPicker2
            title=""
            data={[GlobalDisountOption.PRE_TAX, GlobalDisountOption.POST_TAX]}
            style={{
              width: 150
            }}
            allowSearch={false}
            className="position-absolute z-index-3 left-0 top-10 shadow-m"
            onSelect={(index: number, option: any) => {
              handleGlobalDiscountOption(option);
              setShowGlobalDiscountOptions(false);
            }}
            onClose={() => {
              if (showGlobalDiscountOptions) {
                setShowGlobalDiscountOptions(false);
              }
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div className="row parent-width">
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                      textAlign: 'left'
                    }}
                  >
                    {obj}
                  </div>
                </div>
              );
            }}
          />
        )}
        {/* Hiding global discount application level till pre-tax calculations are fixed */}
        {false && (
          <DKButton
            className="text-blue fs-s"
            style={{
              paddingLeft: 0,
              paddingTop: 0,
              fontSize: 11
            }}
            title={`${
              globalDiscount?.isSubTotalOnly
                ? GlobalDisountOption.PRE_TAX
                : GlobalDisountOption.POST_TAX
            }`}
            onClick={() => {
              setShowGlobalDiscountOptions(!showGlobalDiscountOptions);
            }}
          />
        )}
      </div>
    );
  };
  const validateAndSetGlobalDiscount = (
    discountOptionValue?: GlobalDisountOption
  ) => {
    let discountValue = debouncedGlobalDiscountValue;
    let discountValueInvalid = false;
    let discountInPercent = false;

    const additionalChargesDetails = getAdditionalChargesTotalAndTax();
    const preTaxAmount =
      totalCalculationDeps.subTotal -
      totalCalculationDeps.discount +
      additionalChargesDetails.total;
    const postTaxAmount = 0;

    const totalToCompareWith = discountOptionValue
      ? discountOptionValue === GlobalDisountOption.PRE_TAX
        ? preTaxAmount
        : postTaxAmount
      : globalDiscount?.isSubTotalOnly
      ? preTaxAmount
      : postTaxAmount;

    const matcher = String(discountValue).match(REGEX.PERCENT_NUMER);
    if (!matcher) {
      discountValueInvalid = true;
    } else if ('%' === matcher[4]) {
      const percentDiscount = Number(discountValue.replace('%', ''));
      if (percentDiscount > 100) {
        discountValueInvalid = true;
      } else {
        discountValueInvalid = false;
        discountValue = percentDiscount;
      }
      discountInPercent = true;
    } else if (
      discountValue < 0 ||
      (discountValue as number) > (totalToCompareWith as number)
    ) {
      discountValueInvalid = true;
    }
    setGlobalDiscountInvalid(discountValueInvalid);

    let discountAmount = 0;
    if (discountInPercent) {
      discountAmount = Utility.roundOff(
        (totalToCompareWith * discountValue) / 100,
        tenantInfo.decimalScale
      );
    } else {
      if (isGlobalDiscountUpdatedManually.current) {
        discountAmount = discountValue;
      } else {
        if (booksDocument.exchangeRate && booksDocument.previousExchangeRate) {
          discountAmount = Utility.roundOff(
            convertToCurrenctExchangeRate(
              booksDocument.exchangeRate,
              booksDocument.previousExchangeRate,
              discountValue
            ),
            tenantInfo.decimalScale
          );
          setGlobalDiscountValue(discountAmount);
          isGlobalDiscountUpdatedManually.current = true;
        } else {
          discountAmount = discountValue;
          setGlobalDiscountValue(discountAmount);
          isGlobalDiscountUpdatedManually.current = true;
        }
      }
    }

    const updatedDiscount = {
      ...globalDiscount,
      isSubTotalOnly: discountOptionValue
        ? discountOptionValue === GlobalDisountOption.PRE_TAX
        : globalDiscount?.isSubTotalOnly,
      isPercent: discountInPercent,
      percent: discountInPercent ? discountValue : 0,
      amount: discountAmount,
      hasError: discountValueInvalid
    };

    if (!Utility.areObjectsEqual(updatedDiscount, globalDiscount)) {
      setGlobalDiscount({ ...globalDiscount, ...updatedDiscount });
    }
  };

  const getGlobalDiscountInput = () => {
    return (
      <div>
        <DKInput
          required={true}
          canValidate={globalDiscountInvalid}
          validator={(value: string) => !globalDiscountInvalid}
          errorMessage="Invalid value"
          title=""
          type={INPUT_TYPE.TEXT}
          readOnly={props.documentMode === DOCUMENT_MODE.VIEW}
          value={globalDiscountValue}
          valueStyle={{
            background: '#fff',
            paddingTop: 0,
            paddingBottom: 0,
            minWidth: 100
          }}
          textAlign="right"
          className="width-auto text-align-right"
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(changedValue: any) => {
            isGlobalDiscountUpdatedManually.current = true;
            setGlobalDiscountValue(changedValue);
          }}
        />
        {!globalDiscountInvalid &&
          !Utility.isEmpty(globalDiscount) &&
          globalDiscount.isPercent &&
          !isNaN(globalDiscount?.amount) && (
            <DKLabel
              className={'text-align-right ' + GOOGLE_NO_TRANSLATE_CLASS}
              text={`${Utility.getCurrencySymbolFromCode(
                booksDocument.currency
              )} ${NumberFormatService.getNumber(globalDiscount.amount)}`}
            />
          )}
      </div>
    );
  };

  const getGlobalDiscountField = () => {
    return (
      <div
        className="row parent-width mb-m justify-content-between align-items-start position-relative parent-block"
        style={{ width: '100%' }}
      >
        {props.documentMode !== DOCUMENT_MODE.VIEW && (
          <DKButton
            title=""
            icon={DKIcons.ic_delete}
            className="position-absolute child-block"
            style={{ left: -30, top: -7, opacity: 0.5 }}
            onClick={() => {
              isGlobalDiscountUpdatedManually.current = true;
              setGlobalDiscountValue(0);
              setShowGlobalDiscountBlock(false);
            }}
          />
        )}
        <div
          className="row width-auto"
          style={{
            minWidth: 150,
            paddingLeft: 8
          }}
        >
          {getGlobalDiscountLabelAndOptions()}
        </div>
        {getGlobalDiscountInput()}
      </div>
    );
  };
  return (
    <div className="column">
      {/* <div
        className="flex flex-row box-border w-full items-start width-auto mb-l justify-content-between"
        style={{ width: '100%', marginTop: -32 }}
      >
        {booksDocument.taxSystem !== TAX_SYSTEM.US && (
          <div className="flex flex-row items-end justify-end w-full">
            <DKCheckMark
              color="bg-button"
              isSelected={booksDocument.unitPriceGstInclusive}
              onClick={() => {
                if (props.onTaxInclusiveFlagChange) {
                  props.onTaxInclusiveFlagChange();
                }
              }}
              className="text-black z-index-1"
              title={t(
                `DOCUMENT.DOCUMENT_SUMMARY_VIEW.UNIT_PRICE_IS_TAX_INCLUSIVE`
              )}
            />
          </div>
        )}
      </div> */}
      {getTitleAndAmount(
        t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.SUB_TOTAL`),
        revSubtotal,
        // totalCalculationDeps.subTotal,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.DISCOUNT`)} (-)`,
        revDiscount,
        null,
        'fw-m'
      )}
      {getTitleAndAmount(
        `${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.BEFORE_TAX`)}`,
        revBeforeTax,
        //   totalCalculationDeps.subTotal - totalCalculationDeps.discount,
        null,
        'fw-m'
      )}

      {/* {showAdditionalCharges()} */}

      {/* {showAdditionalChargesSelector() && getChargeAndDiscountPicker()} */}

      {/* {showGlobalDiscountBlock &&
        globalDiscount?.isSubTotalOnly &&
        getGlobalDiscountField()}

      {showCommonTaxBlocks() && getExpandableContainer(hasTaxGroup, false)} */}

      {/* Plus Tax gst Currency SG */}
      {showBaseTaxForSG() && (
        <div
          className={
            'row parent-width mb-m justify-content-between align-items-start ' +
            GOOGLE_NO_TRANSLATE_CLASS
          }
          style={{ width: '100%' }}
        >
          <div
            className="row width-auto ml-xxl"
            style={{
              minWidth: 100
            }}
          >
            <DKLabel text={`(${getBaseCurrency()})`} className={''} />
          </div>
          <DKLabel
            text={`${Utility.getCurrencySymbolFromCode(
              COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
            )} ${(
              totalCalculationDeps.tax /
              (booksDocument.gstExchangeRate as number)
            ).toFixed(tenantInfo.decimalScale)}`}
            style={{
              wordBreak: 'break-all'
            }}
          />
        </div>
      )}
      {/* END: Plus Tax gst Currency SG */}

      {tenantInfo.country === COUNTRY_CODES.IN &&
        booksDocument.documentType === DOC_TYPE.BILL &&
        getTitleAndAmount(
          `TDS (-)`,
          totalCalculationDeps.tdsAmount,
          null,
          'fw-m'
        )}
      {showTCSField && getTCSField()}
      {createTCSRate && getAddTCSView()}

      {showGlobalDiscountBlock &&
        !globalDiscount.isSubTotalOnly &&
        getGlobalDiscountField()}
      {/* 
      <div
        className="row width-auto mb-m justify-content-between"
        style={{ width: '100%' }}
      >
        <div className="row width-auto">
          <DKLabel
            text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.ROUNDING_OFF`)}
            className={'fw-m ml-r'}
          />
        </div>

        <div className="flex flex-col items-end w-24 number-hide-arrows">
          <input
            value={roundOffValue}
            type="text"
            className="border-transparent hover:border-gray-300 focus:border-gray-400 w-10/12 text-right border rounded-sm outline-none cursor-pointer"
            onChange={manualRoundOffChange}
          />
        </div>
      </div> */}
      <DKLine className="bg-light-gray mb-m" />
      {getTotalTitleAndAmount(
        `${t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.TOTAL`)}${
          tenantInfo.multicurrencyEnabled ? '' : '(' + tenantInfo.currency + ')'
        }`,
        revTotal,
        null,
        'walkthrough-step-7 fs-l fw-m',
        'fs-l fw-m'
      )}
    </div>
  );
}
