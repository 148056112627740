import TemplateSettingsManager from "../Manager/TemplateSettingsManager"
import { getLocalisedText } from "../Translate/LanguageManager"
import { getIsServiceRequisition, getIsStockIssue, getIsStockRequest, getIsStockTransfer, getIsSecurityGateEntry, getIsOrder, getIsGoodsReceipt, getIsGoodsReceiptNoteTemplate } from "./Utility"

export const ApprovalSectionType = {
    createdBy: 'createdBy',
    checkedBy: 'checkedBy',
    authorizedBy: 'authorizedBy',
    storeOfficer: 'storeOfficer',
    departmentHolder: 'departmentHolder',
    receiveBy: 'receiveBy',
    issueBy: 'issueBy',
    preparedBy: 'preparedBy',
    purchaseDept: 'Purchase Dept.',
    authorizedSignatory: 'authorizedSignatory',
    preparedDateTime: 'preparedDateTime',
    inspectedBy: 'inspectedBy',
    storeManager: 'storeManager',
    headOfDepartment: 'headOfDepartment',
    accountManager: 'accountManager',
}

export const CustomApprovalSectionType = {
    bank: 'bank',
    qr: 'qr',
    companySeal: 'companySeal',
    companyInfo: 'companyInfo',
    createdBy: 'createdBy',
    preparedBy: 'preparedBy',
    checkedBy: 'checkedBy',
    authorizedBy: 'authorizedBy'
}

export default class ApprovalSectionUtility {

    static getDefaultList() {
        return [
            {
                index: 0,
                type: ApprovalSectionType.createdBy,
                label: getLocalisedText('created_by'),
                originalLabel: getLocalisedText('created_by'),
                value: 'createdBy',
                isSelected: true,
            },
            {
                index: 1,
                type: ApprovalSectionType.checkedBy,
                label: getLocalisedText('checked_by'),
                originalLabel: getLocalisedText('checked_by'),
                value: 'checkedBy',
                isSelected: true,
            },
            {
                index: 2,
                type: ApprovalSectionType.authorizedBy,
                label: getLocalisedText('authorized_by'),
                originalLabel: getLocalisedText('authorized_by'),
                value: 'authorizedBy',
                isSelected: true,
            },
        ]
    }

    static getApprovalList() {
        var documentLabelList = TemplateSettingsManager.getApprovalSectionSettings()?.elements
        var defaultDocumentList = this.getDefaultList()
        var list = []

        if (documentLabelList.length === 0) {
            list = defaultDocumentList
        }
        else {
            var newList = documentLabelList
            var types = documentLabelList.map(x => x.type)

            defaultDocumentList.forEach(element => {
                if (!types.includes(element.type)) {
                    newList.push(element)
                }
            });

            list = newList
        }

        if (list.length > 0) {
            list.forEach((element, index) => {
                element.index = index
            });
        }
        return list
    }

    static getDefaultApprovalSection() {
        let approvalSection = [
            {
                index: 0,
                type: CustomApprovalSectionType.bank,
                label: getLocalisedText('bank_details'),
                originalLabel: getLocalisedText('bank_details'),
                width: 30,
                items: [
                    {
                        index: 0,
                        label: getLocalisedText('bank_name'),
                        originalLabel: getLocalisedText('bank_name'),
                        value: 'Demo Bank'
                    },
                    {
                        index: 1,
                        label: getLocalisedText('ifsc_code'),
                        originalLabel: getLocalisedText('ifsc_code'),
                        value: 'PUNB-1234'
                    },
                    {
                        index: 2,
                        label: getLocalisedText('bank_name'),
                        originalLabel: getLocalisedText('bank_name'),
                        value: 'ACC-1234'
                    }
                ]
            },
            {
                index: 1,
                type: CustomApprovalSectionType.qr,
                label: 'generated_qr',
                value: 'QR-1234',
                width: 20,
            },
            {
                index: 2,
                type: CustomApprovalSectionType.companySeal,
                label: getLocalisedText('company_seal'),
                originalLabel: getLocalisedText('company_seal'),
                width: 25,
            },
            {
                index: 3,
                type: CustomApprovalSectionType.authorizedBy,
                label: getLocalisedText('authorised_signatory'),
                originalLabel: getLocalisedText('authorised_signatory'),
                value: 'authorizedBy',
                width: 25,
            },
            {
                index: 4,
                type: CustomApprovalSectionType.companyInfo,
                width: 50,
                items: [
                    {
                        index: 0,
                        label: getLocalisedText('regd_office'),
                        originalLabel: getLocalisedText('regd_office'),
                        value: 'Office Address'
                    },
                    {
                        index: 1,
                        label: getLocalisedText('phone'),
                        originalLabel: getLocalisedText('phone'),
                        value: '91-1234'
                    }
                ]
            },
            {
                index: 5,
                type: CustomApprovalSectionType.preparedBy,
                label: getLocalisedText('prepared_by'),
                originalLabel: getLocalisedText('prepared_by'),
                value: 'createdBy',
                width: 25,
            },
            {
                index: 6,
                type: CustomApprovalSectionType.checkedBy,
                label: getLocalisedText('checked_by'),
                originalLabel: getLocalisedText('checked_by'),
                value: 'checkedBy',
                width: 25,
            },
        ]

        return approvalSection
    }


    static getCustomApprovalSection() {
        var documentLabelList = TemplateSettingsManager.getCustomApprovalSectionSettings()?.sections
        var defaultDocumentList = this.getDefaultApprovalSection()
        var list = []

        if (documentLabelList.length === 0) {
            list = defaultDocumentList
        }
        else {
            var newList = documentLabelList
            var types = documentLabelList.map(x => x.type)

            defaultDocumentList.forEach(element => {
                if (!types.includes(element.type)) {
                    newList.push(element)
                }
            });

            list = newList
        }

        if (list.length > 0) {
            list.forEach((element, index) => {
                element.index = index
            });
        }
        return list
    }

    static getStockTransferDefaultList() {
        return [
            {
                index: 0,
                type: ApprovalSectionType.storeOfficer,
                label: getLocalisedText('store_officer'),
                originalLabel: getLocalisedText('store_officer'),
                value: 'storeOfficer',
                isSelected: true,
            },
            {
                index: 1,
                type: ApprovalSectionType.departmentHolder,
                label: getLocalisedText('department_holder'),
                originalLabel: getLocalisedText('department_holder'),
                value: 'departmentHolder',
                isSelected: true,
            },
        ]
    }

    static getStockIssuesDefaultList() {
        return [
            {
                index: 0,
                type: ApprovalSectionType.receiveBy,
                label: getLocalisedText('receive_by'),
                originalLabel: getLocalisedText('receive_by'),
                value: 'receiveBy',
                isSelected: true,
            },
            {
                index: 1,
                type: ApprovalSectionType.issueBy,
                label: getLocalisedText('issue_by'),
                originalLabel: getLocalisedText('issue_by'),
                value: 'issueBy',
                isSelected: true,
            },
            {
                index: 2,
                type: ApprovalSectionType.storeOfficer,
                label: getLocalisedText('store_officer'),
                originalLabel: getLocalisedText('store_officer'),
                value: 'storeOfficer',
                isSelected: true,
            },
            {
                index: 3,
                type: ApprovalSectionType.departmentHolder,
                label: getLocalisedText('department_holder'),
                originalLabel: getLocalisedText('department_holder'),
                value: 'departmentHolder',
                isSelected: true,
            },
        ]
    }

    static getStockRequestDefaultList() {
        return [
            {
                index: 0,
                type: ApprovalSectionType.preparedBy,
                label: getLocalisedText('prepared_by'),
                originalLabel: getLocalisedText('prepared_by'),
                value: 'preparedBy',
                isSelected: true,
            },
            {
                index: 1,
                type: ApprovalSectionType.checkedBy,
                label: getLocalisedText('checked_by'),
                originalLabel: getLocalisedText('checked_by'),
                value: 'checkedBy',
                isSelected: true,
            },
            {
                index: 2,
                type: ApprovalSectionType.authorizedBy,
                label: getLocalisedText('authorized_by'),
                originalLabel: getLocalisedText('authorized_by'),
                value: 'authorizedBy',
                isSelected: true,
            },
            {
                index: 3,
                type: ApprovalSectionType.authorizedSignatory,
                label: getLocalisedText('authorised_signatory'),
                originalLabel: getLocalisedText('authorised_signatory'),
                value: 'authorizedSignatory',
                isSelected: true,
            },
            {
                index: 5,
                type: ApprovalSectionType.preparedDateTime,
                label: getLocalisedText('prepared_date_time'),
                originalLabel: getLocalisedText('prepared_date_time'),
                value: 'preparedDateTime',
                isSelected: false,
            },
        ]
    }

    static getSecurityGateDefaultList() {
        return [{
            index: 0,
                type: CustomApprovalSectionType.preparedBy,
                label: getLocalisedText('prepared_by'),
                originalLabel: getLocalisedText('prepared_by'),
                value: 'createdByName',
                isSelected: true,
            },
            {
                index: 1,
                type: ApprovalSectionType.receiveBy,
                label: getLocalisedText('receive_by'),
                originalLabel: getLocalisedText('receive_by'),
                value: 'receiveBy',
                isSelected: true,
            },
            {
                index: 2,
                type: ApprovalSectionType.authorizedBy,
                label: getLocalisedText('authorized_by'),
                originalLabel: getLocalisedText('authorized_by'),
                value: 'authorizedBy',
                isSelected: true,
            },
            {
                index: 3,
                type: ApprovalSectionType.purchaseDept,
                label: getLocalisedText('purchase_department'),
                originalLabel: getLocalisedText('purchase_department'),
                value: 'purchaseDept',
                isSelected: true,
            },
            {
                index: 4,
                type: ApprovalSectionType.storeOfficer,
                label: getLocalisedText('store_officer'),
                originalLabel: getLocalisedText('store_officer'),
                value: 'storeOfficer',
                isSelected: true,
            },
        ]
    }

    static getGoodsReceiptNoteDefaultList() {
        return [{
            index: 0,
            type: CustomApprovalSectionType.preparedBy,
            label: getLocalisedText('prepared_by'),
            originalLabel: getLocalisedText('prepared_by'),
            value: 'createdByName',
            isSelected: true,
        },
        {
            index: 1,
            type: ApprovalSectionType.inspectedBy,
            label: getLocalisedText('inspected_by'),
            originalLabel: getLocalisedText('inspected_by'),
            value: 'inspectedBy',
            isSelected: true,
        },
        {
            index: 2,
            type: ApprovalSectionType.storeManager,
            label: getLocalisedText('store_manager'),
            originalLabel: getLocalisedText('store_manager'),
            value: 'storeManager',
            isSelected: true,
        },
        {
            index: 3,
            type: ApprovalSectionType.headOfDepartment,
            label: getLocalisedText('hod'),
            originalLabel: getLocalisedText('hod'),
            value: 'headOfDepartment',
            isSelected: true,
        },
        {
            index: 4,
            type: ApprovalSectionType.accountManager,
            label: getLocalisedText('account_manager'),
            originalLabel: getLocalisedText('account_manager'),
            value: 'accountManager',
            isSelected: true,
        }]
    }

    static updateApprovalElement(type, templateId) {
        let approvalSettings = TemplateSettingsManager.getApprovalSectionSettings()

        if(getIsStockTransfer(type)) {
            this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.departmentHolder, this.getStockTransferDefaultList())
        }
        else if (getIsStockIssue(type)) {
            this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.departmentHolder, this.getStockIssuesDefaultList())
        }
        else if (getIsStockRequest(type) || getIsServiceRequisition(type) || getIsOrder(type)) {
            this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.authorizedSignatory, this.getStockRequestDefaultList())
        }
        else if (getIsSecurityGateEntry(type)) {
            this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.authorizedSignatory, this.getSecurityGateDefaultList())
        }
        else if (getIsGoodsReceipt(type)) {
            if (getIsGoodsReceiptNoteTemplate(templateId)) {
                this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.preparedBy, this.getGoodsReceiptNoteDefaultList())
            } else {
                this.updateApprovalElementByType(approvalSettings, ApprovalSectionType.preparedBy, [])
            }
        }
    }

    static updateApprovalElementByType(approvalSettings, approvalSectionType, defaultList) {
        if (approvalSettings) {
            if (approvalSettings.elements && approvalSettings.elements) {
                if (approvalSettings.elements.length > 0) {
                    const types = approvalSettings.elements.map(x => x.type)
                    if (!types.includes(approvalSectionType)) {
                        approvalSettings.elements = defaultList
                        TemplateSettingsManager.updateApprovalSectionSettings(approvalSettings)
                    }
                }
            }
        }
    }
}