import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { TableManager } from '../../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../../Models/ReduxStore';
import { IColumn } from '../../../Models/Table';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import { RootState } from '../../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  storeObjForPOPR: null,
  storeObjForSR: null,
  storeObjForWOBomExplosion: null,
  showArchived: false
};

export const fetchWorkOrderList = createAsyncThunk(
  'fetchWorkOrder',
  async () => {
    const response = await WorkOrderService.getWorkOrders();
    return response;
  }
);

export const WorkOrderSlice = createSlice({
  name: 'WorkOrder',
  initialState,
  reducers: {
    addWorkOrderColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.WORK_ORDERS
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    updatePOPRAssociation: (state, action: any) => {
      state.storeObjForPOPR = action.payload;
    },
    updateWOBomexplosionAssociation: (state, action: any) => {
      state.storeObjForWOBomExplosion = action.payload;
    },
    updateStockRequestAssociation: (state, action: any) => {
      state.storeObjForSR = action.payload;
    },
    updateShowArchivedFlag: (state, action: any) => {
      state.showArchived = action.payload;
    },
    addColumnToWorkOrderColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkOrderList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchWorkOrderList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data.workOrders = action.payload;
      })
      .addCase(fetchWorkOrderList.rejected, (state) => {
        state.status = API_STATUS.FAILED;
      });
  }
});

export const {
  addWorkOrderColumnConfig,
  addColumnToWorkOrderColumnConfig,
  updateColumnConfig,
  updatePOPRAssociation,
  updateStockRequestAssociation,
  updateWOBomexplosionAssociation,
  updateShowArchivedFlag
} = WorkOrderSlice.actions;

export const selectWorkOrder = (state: RootState) =>
  state.mrpWorkOrder.data.workOrders;

export const selectWorkOrderLoadingStatus = (state: RootState) =>
  state.mrpWorkOrder.status;

export const selectWorkOrderColumnConfig = (state: RootState) =>
  state.mrpWorkOrder.columnConfig;
export const selectWorkOrderColumnConfigTableId = (state: RootState) =>
  state.mrpWorkOrder.configTableId;

export const selectWorkOrderAssociatedPOPR = (state: RootState) =>
  state.mrpWorkOrder.storeObjForPOPR;
export const selectWorkOrderAssociatedSRData = (state: RootState) =>
  state.mrpWorkOrder.storeObjForSR;
export const selectWorkOrderBomExplosionAssociation = (state: RootState) =>
  state.mrpWorkOrder.storeObjForWOBomExplosion;
export const showArchivedFlag = (state: RootState) =>
  state.mrpWorkOrder.showArchived;
