import ApiConstants from '../Constants/ApiConstants';
import http2 from './Interceptor';

class FixedAssetService {
  static GetFixedAssets(search = '', limit = 25, page = 0) {
    const url =
      ApiConstants.URL.ASSET_BASE + ApiConstants.URL.FIXED_ASSET.GET_ASSETS;
    const queryString = `?search=${search}&limit=${limit}&page=${page}`;
    return http2.get(url + queryString);
  }

  static GetAssetGroups(search = '', limit = 25, page = 0) {
    const url =
      ApiConstants.URL.ASSET_BASE +
      ApiConstants.URL.FIXED_ASSET.GET_ASSET_GROUPS;
    const queryString = `?search=${search}&limit=${limit}&page=${page}`;
    return http2.get(url + queryString);
  }

  static getAssetDetails(payload: any) {
    const url = `asset/details?documentType=${payload.documentType}&documentCode=${payload.documentCode}&groupId=${payload.assetGroupId}&documentItemCode=${payload.documentItemCode}&linkedDocumentCode=${payload.linkedDocumentCode}&lineNumber=${payload.lineNumber}`;
    return http2.get(url);
  }
  static receiveGoods(payload: any) {
    const url = `/asset/goodsreceipt`;
    return http2.post(url, payload);
  }
  static saveAsset(payload: any) {
    const url = `/asset/bulk`;
    return http2.post(url, payload);
  }
  static saveAssetForBill(payload: any, documentCode: any) {
    const url = `/invoices/purchase/${documentCode}/asset-details`;
    return http2.post(url, payload);
  }
  static deleteReceivedGoods(id: any) {
    const finalEndpoint: string = `/asset/goodsreceipt/${id}`;

    try {
      return http2.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting received goods: ', err);
      return Promise.reject(err);
    }
  }
}

export default FixedAssetService;
