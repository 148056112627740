import { useRef, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import {
  BomConfigurationModel,
  BomOperation,
  ComponentProduct,
  NewBomConfigurationModel
} from './BomConfigurationModel';
import { JC_PROCESS_TYPE } from '../../Shared/JobCardListComponent/JobCardListPresenter';
import { Store } from '../../../../Redux/Store';

export default function BomDetailsTabViewModel(bomModel: any) {
  const [bomMetaDetails, setBomMetaDetails] = useState<BomConfigurationModel[]>(
    bomModel?.map((itemBomModel: any) => {
      return {
        ...itemBomModel,
        bomProductsConfiguration: itemBomModel?.bomProductsConfiguration?.map(
          (itemBomProduct: any) => {
            return {
              ...itemBomProduct,
              quantity: itemBomProduct?.documentUOMSchemaDefinition
                ? itemBomProduct?.uomQuantity
                : itemBomProduct?.quantity
            };
          }
        )
      };
    })
  );
  var currentEditableIndex = useRef(0);
  const TITLES = {
    COMPONENT_PRODUCT: 'Component Products',
    BY_PRODUCT: 'By-Products',
    ADDITIONAL_COST: 'Additional Cost',
    OPERATIONS: 'Operations'
  };

  function addComponentProduct() {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempComponent: ComponentProduct[] = [];
    if (!Utility.isEmpty(currentConfig?.bomProductsConfiguration)) {
      tempComponent = [...currentConfig?.bomProductsConfiguration];
    }
    var component: ComponentProduct = {
      itemId: undefined,
      itemName: undefined,
      quantity: 0,
      cost: 0,
      name: '',
      priceOfItem: 0,
      stockUom: undefined,
      productCode: '',
      bomProductSubstitutesDetails: [],
      produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
      documentSequenceCode: '',
      productType: PRODUCT_TYPE.TRACKED
    };

    tempComponent.push(component);
    currentConfig.bomProductsConfiguration = [...tempComponent];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  }

  function insertComponentProduct(index: number, bomIndex: number) {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[bomIndex] };
    let tempComponent: ComponentProduct[] = [];
    if (!Utility.isEmpty(currentConfig?.bomProductsConfiguration)) {
      tempComponent = [...currentConfig?.bomProductsConfiguration];
    }
    let component: ComponentProduct = {
      itemId: undefined,
      itemName: undefined,
      quantity: 0,
      cost: 0,
      name: '',
      priceOfItem: 0,
      stockUom: undefined,
      productCode: '',
      bomProductSubstitutesDetails: [],
      produceProductType: PRODUCE_PRODUCT_TYPE.NONE,
      documentSequenceCode: '',
      productType: PRODUCT_TYPE.TRACKED
    };

    const newComponentIndex = index + 1;
    if (currentConfig?.bomProductsConfiguration?.length === newComponentIndex) {
      tempComponent.push(component);
    } else {
      tempComponent.splice(newComponentIndex, 0, component);
    }

    currentConfig.bomProductsConfiguration = [...tempComponent];
    bomDetailMetaCopy[bomIndex] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  }

  function removeComponent(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = [...currentConfig?.bomProductsConfiguration];
    tmp.splice(index, 1);
    // setComponentProduct(tmp);
    currentConfig.bomProductsConfiguration = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function removeScrapCoProduct(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = currentConfig?.byProducts ? [...currentConfig?.byProducts] : [];
    tmp.splice(index, 1);
    // setComponentProduct(tmp);
    currentConfig.byProducts = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function addAttachment(attachment: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    currentConfig.attachments = [...currentConfig.attachments, attachment];
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function setAttachment(attachment: any) {
    let bomMetaDetailsCopy = deepClone(bomMetaDetails);
    bomMetaDetailsCopy?.forEach((bomDetail: any, index: any) => {
      let attchmnt = attachment[bomDetail.id] ?? [];
      if (
        bomMetaDetailsCopy?.[index]?.attachments?.length &&
        typeof bomMetaDetailsCopy?.[index]?.attachments[0] === 'object'
      ) {
        bomMetaDetailsCopy[index].attachments = [
          ...attchmnt,
          ...bomMetaDetailsCopy[index].attachments
        ];
      } else {
        bomMetaDetailsCopy[index].attachments = [...attchmnt];
      }
      bomMetaDetailsCopy[index].attachments = bomMetaDetailsCopy[
        index
      ].attachments.filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) => obj2.attachmentId === obj1.attachmentId) === i
      );
    });

    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function deleteAttachment(attachmentId: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    currentConfig.attachments = currentConfig.attachments?.filter(
      (attachments: any) => attachments.attachmentId !== attachmentId
    );
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  function removeAdditionalCost(index: any) {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var tmp = [...currentConfig?.bomAddCostConfiguration];
    tmp.splice(index, 1);
    currentConfig.bomAddCostConfiguration = tmp;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  }

  const removeOperation = (index: number) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];

    let tmpBOMRecords = [...currentConfig?.bomOperationsConfiguration];

    const currentRowOperationId = tmpBOMRecords?.[index]?.operationId;
    const indexOfRowDependentOnCurrentRowOperation = tmpBOMRecords.findIndex(
      (opration: BomOperation) =>
        opration?.operationDependency?.opDependencyList?.includes(
          currentRowOperationId
        )
    );
    if (indexOfRowDependentOnCurrentRowOperation !== -1) {
      tmpBOMRecords[indexOfRowDependentOnCurrentRowOperation] = {
        ...tmpBOMRecords[indexOfRowDependentOnCurrentRowOperation],
        operationDependency: {
          opDependencyList: []
        },
        processType: undefined,
        productCode: undefined,
        qcNeeded: false
      };
    }

    tmpBOMRecords.splice(index, 1);

    currentConfig.bomOperationsConfiguration = tmpBOMRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  function getComponentProducts() {
    //   const componentRearranged = getComponentProducts()?.sort((a, b) => {
    //   if (a.active && !b.active) {
    //     return -1;
    //   } else if (!a.active && b.active) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
    return bomMetaDetails;
  }

  const onRowUpdate = (title: string, data: any) => {
    if (title === TITLES.COMPONENT_PRODUCT) {
      updateComponentProduct(data);
    } else if (title === TITLES.BY_PRODUCT) {
      updateByProduct(data);
    } else if (title === TITLES.ADDITIONAL_COST) {
      updateAdditionalCost(data);
    } else if (title === TITLES.OPERATIONS) {
      updateOperation(data);
    }
  };

  const updateComponentProduct = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomProductsConfiguration];
    let selectedComponent: any = { ...editedRecords[data.rowIndex] };
    let selectedProduct = data.rowData.product;
    switch (data.columnKey) {
      case 'product':
        selectedComponent = {};
        selectedComponent.itemId = selectedProduct.id;
        selectedComponent.itemName = selectedProduct.name;
        selectedComponent.productCode = selectedProduct.productId;
        selectedComponent.productType = selectedProduct.type;
        selectedComponent.name = selectedProduct.name;
        selectedComponent.quantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;
        selectedComponent.uomQuantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;

        selectedComponent.cost = selectedProduct.purchasePrice ?? 0;
        selectedComponent.priceOfItem = selectedProduct.salesPrice;
        selectedComponent.defaultStockUom = selectedProduct.stockUom;
        selectedComponent.stockUom = selectedProduct.stockUom;
        selectedComponent.bomProductSubstitutesDetails =
          selectedProduct.productSubstitutesDetails;
        selectedComponent.documentSequenceCode =
          selectedProduct.documentSequenceCode;
        selectedComponent.uomSchemaDto = selectedProduct?.uomSchemaDto;
        selectedComponent.multipleUomSchema =
          selectedProduct?.multipleUomSchema ?? false;
        selectedComponent.produceProductType = PRODUCE_PRODUCT_TYPE.NONE;
        break;
      case 'uom':
        if (data.rowData?.uom?.isBaseUom) {
          selectedComponent.stockUom = data.rowData?.uom?.id;
          selectedComponent.documentUOMSchemaDefinition = null;
        } else {
          selectedComponent.stockUom = data.rowData?.uom?.id;
          selectedComponent.documentUOMSchemaDefinition = data.rowData?.uom;
        }
        selectedComponent.quantity = 0;

        if (data?.rowData?.productType === PRODUCT_TYPE.NON_TRACKED) {
          selectedComponent.quantity = 1;
          selectedComponent.uomQuantity = 1;
        }

        break;
      case 'quantity':
        selectedComponent.quantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        selectedComponent.uomQuantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        break;
      case 'cost':
        selectedComponent.cost = Utility.roundOffToTenantDecimalScale(
          data.rowData.cost
        );
    }

    editedRecords[data.rowIndex] = selectedComponent;
    currentConfig.bomProductsConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateByProduct = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = currentConfig?.byProducts
      ? [...currentConfig?.byProducts]
      : [];
    let selectedComponent = { ...editedRecords[data.rowIndex] };
    let selectedProduct = data.rowData.product;
    switch (data.columnKey) {
      case 'product':
        selectedComponent.itemId = selectedProduct.id;
        selectedComponent.itemName = selectedProduct.name;
        selectedComponent.name = selectedProduct.name;
        selectedComponent.quantity =
          selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 1;
        selectedComponent.cost =
          selectedProduct.salesPrice *
          (selectedComponent && selectedComponent.quantity
            ? selectedComponent.quantity
            : 0);
        selectedComponent.priceOfItem = selectedProduct.salesPrice;
        selectedComponent.stockUom = selectedProduct.stockUom;
        selectedComponent.bomProductSubstitutesDetails =
          selectedProduct.productSubstitutesDetails;
        selectedComponent.documentSequenceCode =
          selectedProduct.documentSequenceCode;
        selectedComponent.produceProductType =
          data?.rowData?.produceProductType;
        break;
      case 'quantity':
        selectedComponent.quantity = Utility.roundOffToTenantDecimalScale(
          data.rowData.quantity
        );
        break;
      case 'produceProductType':
        selectedComponent.produceProductType =
          data?.rowData?.produceProductType;
        break;
    }

    editedRecords[data.rowIndex] = selectedComponent;
    currentConfig.byProducts = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateAdditionalCost = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomAddCostConfiguration];
    let selectedOperation = editedRecords[data.rowIndex];
    selectedOperation.label = data?.rowData.label;
    selectedOperation.price = Utility.roundOffToTenantDecimalScale(
      data?.rowData.price
    );
    editedRecords[data.rowIndex] = selectedOperation;
    currentConfig.bomAddCostConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const updateDependentRowsInvalidFields = (
    editedRecords: any,
    operationId: any
  ) => {
    let obj: any = { index: -1, invalidFields: [] };
    editedRecords.forEach((record: any, index: any) => {
      if (record.operationId === operationId) {
        if (
          Store.getState().authInfo.currentTenantInfo.data.additionalSettings
            .LINK_INVENTORY_JOB_CARDS &&
          Utility.isEmpty(record.product) &&
          Utility.isEmpty(record.processType) &&
          Utility.isEmpty(record.operationDependency)
        ) {
          obj = { index: index, invalidFields: ['processType', 'product'] };
        }
      }
    });
    return obj;
  };

  const dependentOperationHasDifferentProduct = (
    currentOperation: any,
    allOperations: any[]
  ) => {
    const hasDependantsWithDifferentProduct = allOperations.some(
      (operation: any) =>
        operation?.operationDependency?.opDependencyList?.includes(
          currentOperation?.operationId
        )
    );
    return hasDependantsWithDifferentProduct;
  };

  const updateOperation = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    var editedRecords = [...currentConfig?.bomOperationsConfiguration];
    let selectedOperation: any = {
      ...editedRecords[data.rowIndex],
      productOptions: currentConfig.bomProductsConfiguration
    };
    let selectedProduct = data.rowData.operation;
    switch (data.columnKey) {
      case 'operation':
        selectedOperation.operationId = selectedProduct.id;
        selectedOperation.operationName = selectedProduct.name;
        selectedOperation.costPerHour = selectedProduct.costPerHour || 0;
        selectedOperation.fixedRate = selectedProduct.fixedRate || 0;
        selectedOperation.totalCost =
          (selectedProduct.operationTime / 60) * selectedProduct.costPerHour ||
          0;
        selectedOperation.operationTime = selectedProduct.operationTime || 0;

        if (selectedOperation?.isInsertedRow && data.rowIndex !== 0) {
          const aboveRowIndex = data.rowIndex - 1;
          const aboveRowOprationId =
            editedRecords?.[aboveRowIndex]?.operationId;
          const indexOfRowDependentOnAboveRowOperation =
            editedRecords.findIndex((opration: BomOperation) =>
              opration?.operationDependency?.opDependencyList?.includes(
                aboveRowOprationId
              )
            );
          if (indexOfRowDependentOnAboveRowOperation !== -1) {
            selectedOperation = {
              ...selectedOperation,
              operationDependency: {
                opDependencyList: [aboveRowOprationId]
              },
              processType: undefined,
              productCode: undefined
            };

            editedRecords[indexOfRowDependentOnAboveRowOperation] = {
              ...editedRecords[indexOfRowDependentOnAboveRowOperation],
              operationDependency: {
                opDependencyList: [selectedOperation.operationId]
              },
              processType: undefined,
              productCode: undefined,
              qcNeeded: false
            };
          }
        }

        break;
      case 'price':
        selectedOperation.price = data?.rowData.price;
        break;
      case 'operationTime':
        selectedOperation.operationTime = +data?.rowData.operationTime ?? 0;
        break;
      case 'opDependency':
        if (
          data?.rowData?.opDependency?.operationName?.toLowerCase() === 'none'
        ) {
          selectedOperation.opDependency = null;
          selectedOperation.operationDependency = {
            opDependencyList: []
          };
        } else {
          const dependentOn = data?.rowData?.opDependency;
          if (editedRecords) {
            let findDependentInOperationList = editedRecords?.find(
              (record: any) => {
                return record.operationId === dependentOn.operationId;
              }
            );
            if (findDependentInOperationList) {
              if (
                selectedOperation?.operationId ===
                findDependentInOperationList?.operationDependency
                  ?.opDependencyList?.[0]
              ) {
                showAlert(
                  'Cyclic dependency found!',
                  `You can't select this operation as its creating cyclic dependency on one of the operation.`
                );
                selectedOperation.opDependency = null;
                selectedOperation.operationDependency = {
                  opDependencyList: []
                };
                return;
              }
            }
          }
          selectedOperation.opDependency = data?.rowData?.opDependency;
          selectedOperation.operationDependency = {
            opDependencyList: [data?.rowData?.opDependency?.operationId]
          };
          if (
            Store.getState().authInfo.currentTenantInfo.data.additionalSettings
              .LINK_INVENTORY_JOB_CARDS
          ) {
            selectedOperation.invalidFields = ['processType', 'product'];
            selectedOperation.productCode = null;
            selectedOperation.processType = null;
          }

          let obj = updateDependentRowsInvalidFields(
            editedRecords,
            data?.rowData?.opDependency?.operationId
          );
          if (obj.index !== -1) {
            editedRecords[obj.index].invalidFields = obj.invalidFields;
          }
        }

        break;
      case 'product':
        const selectedOperationCopy = { ...selectedOperation };
        if (
          !selectedOperationCopy?.operationDependency?.opDependencyList
            ?.length &&
          dependentOperationHasDifferentProduct(
            selectedOperationCopy,
            editedRecords
          )
        ) {
          selectedOperation.productCode = data?.rowData.product?.productCode;
          selectedOperation.product = data?.rowData.product;
          selectedOperation.invalidFields =
            selectedOperation.invalidFields?.filter(
              (invF: any) => invF !== data.columnKey
            );
          updateDependentOperation(
            selectedOperation.operationId,
            editedRecords
          );
        } else {
          selectedOperation.productCode = data?.rowData.product?.productCode;
          selectedOperation.product = data?.rowData.product;
          selectedOperation.invalidFields =
            selectedOperation.invalidFields?.filter(
              (invF: any) => invF !== data.columnKey
            );
          if (data?.rowData.processType !== 'PROCESSING') {
            updateDependentOperation(
              selectedOperation.operationId,
              editedRecords
            );
          }
        }
        break;
      case 'processType':
        selectedOperation.processType = data?.rowData.processType?.value;
        selectedOperation.productCode = null;
        selectedOperation.invalidFields =
          selectedOperation.invalidFields?.filter(
            (invF: any) => invF !== data.columnKey
          );

        if (selectedOperation.processType === 'PROCESSING') {
          selectedOperation.qcNeeded = true;
          selectedOperation.nonEditableColumns = ['qcNeeded'];
        } else {
          selectedOperation.qcNeeded = false;
          selectedOperation.nonEditableColumns = [];
        }

        if (
          checkIfDependentProcessIsNotEmtpy(selectedOperation, editedRecords)
        ) {
          selectedOperation.processType = null;
          selectedOperation.invalidFields = [
            ...selectedOperation.invalidFields,
            'processType'
          ];
        }
        if (data?.rowData.processType?.value !== 'PROCESSING') {
          updateDependentOperation(
            selectedOperation.operationId,
            editedRecords
          );
        }
        break;
      case 'qcNeeded':
        selectedOperation.qcNeeded = data?.rowData.qcNeeded === 'Yes';
        break;
    }
    editedRecords[data.rowIndex] = selectedOperation;
    currentConfig.bomOperationsConfiguration = editedRecords;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const checkIfDependentProcessIsNotEmtpy = (
    selectedOperation: any,
    records: any[]
  ) => {
    let returnValue = false;
    records?.forEach((op: any) => {
      if (op.operationId === selectedOperation?.opDependency?.operationId) {
        //op.qcNeeded = false;
        if (Utility.isEmpty(op.processType)) {
          showAlert(
            'Warning',
            `Please select Process Type for ${op.operationName} first`
          );
          returnValue = true;
        }
      }
    });
    return returnValue;
  };

  const getRearrangedOperationsWithUpdatedDependencies = (
    rowOperations: any[],
    fromIndex: number,
    toIndex: number
  ) => {
    // Deep copy of rowOperations array
    rowOperations = deepClone(rowOperations);

    const oldUnModifiedOPs = deepClone(rowOperations);

    //return from here checking all the conditions
    if (!validateOperationsArrangement(rowOperations, fromIndex, toIndex)) {
      return rowOperations;
    }

    // Check if there are any existing dependencies
    const hasDependencies = rowOperations?.some(
      (op) => op.operationDependency?.opDependencyList?.length > 0
    );

    // Move the operation within the array
    const [movedOperationRow] = rowOperations.splice(fromIndex, 1);
    rowOperations.splice(toIndex, 0, movedOperationRow);

    // If there are no dependencies, simply return the reordered operations
    if (!hasDependencies) {
      return rowOperations;
    }

    // Determine the range of operations to update
    const start = Math.min(fromIndex, toIndex);
    const end = Math.max(fromIndex, toIndex);

    // Save the initial dependencies
    const initialDependencies = rowOperations?.map((op: any) => ({
      operationId: op?.operationId,
      dependencies: op?.operationDependency?.opDependencyList || []
    }));

    // Update dependencies for the moved operation and its neighbors
    if (toIndex > 0) {
      rowOperations[toIndex] = {
        ...rowOperations[toIndex],
        operationDependency: {
          ...rowOperations[toIndex]?.operationDependency,
          opDependencyList: [rowOperations[toIndex - 1].operationId]
        },
        opDependency: rowOperations[toIndex - 1]
      };
    }

    if (toIndex < rowOperations.length - 1) {
      rowOperations[toIndex + 1] = {
        ...rowOperations[toIndex + 1],
        operationDependency: {
          ...rowOperations[toIndex + 1]?.operationDependency,
          opDependencyList: [rowOperations[toIndex].operationId]
        },
        opDependency: rowOperations[toIndex]
      };
    }

    const operationDependentOnMovedOP = oldUnModifiedOPs?.find(
      (itemOP: any) => {
        return (
          movedOperationRow.operationId ===
          itemOP?.operationDependency?.opDependencyList?.[0]
        );
      }
    );

    // make dependent on of 1st jc as blank
    if (toIndex === 0) {
      rowOperations[toIndex] = {
        ...rowOperations[toIndex],
        operationDependency: {
          ...rowOperations[toIndex]?.operationDependency,
          opDependencyList: []
        },
        opDependency: null
      };
    }

    // 1. make op that was dependent on moved op as blank
    rowOperations = rowOperations?.map((op, index) => {
      if (op?.operationId === operationDependentOnMovedOP?.operationId) {
        const prevOPIsProcessing =
          Utility.isNotEmpty(rowOperations[index - 1]) &&
          rowOperations[index - 1]?.processType === JC_PROCESS_TYPE.PROCESSING;
        op = {
          ...op,
          operationDependency: {
            ...op?.operationDependency,
            opDependencyList: prevOPIsProcessing
              ? [rowOperations[index - 1]?.operationId]
              : []
          },
          opDependency: prevOPIsProcessing ? rowOperations[index - 1] : null
        };
      }
      return op;
    });

    return rowOperations;
  };

  const validateOperationsArrangement = (
    operationsList: any,
    fromIndex: number,
    toIndex: number
  ) => {
    let operationsArr = deepClone(operationsList);

    const prevOP = operationsArr[toIndex - 1]; // previous OP of the new shifted OP
    const nextOP = operationsArr[toIndex]; // next OP of the new shifted OP

    if (Utility.isEmpty(prevOP) || Utility.isEmpty(nextOP)) {
      return true;
    }

    return true;
  };

  const dragAndDropOperation = (fromIndex: number, toIndex: number) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations: any = [
      ...currentConfig?.bomOperationsConfiguration
    ];

    const movedOperation = currentBOMOperations[fromIndex];
    currentBOMOperations = currentBOMOperations?.map(
      (item: any, index: any) => {
        if (item.operationId === movedOperation?.operationId) {
          item.productCode = null;
          item.processType = null;
          item.invalidFields = ['processType', 'product'];
        }
        return item;
      }
    );

    currentBOMOperations = getRearrangedOperationsWithUpdatedDependencies(
      currentBOMOperations,
      fromIndex,
      toIndex
    );

    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const findIndexInArray = (currentBOMOperations: any, operationId: any) => {
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(operationId);
    return currentIndex;
  };

  const helperCopierDependentOn = (
    index: any,
    currentBOMOperations: any,
    qcNeeded: any
  ) => {
    if (currentBOMOperations[index].processType !== 'PROCESSING') {
      currentBOMOperations[index].qcNeeded = qcNeeded;
    }

    const dependencyArray: any =
      currentBOMOperations[index]?.operationDependency?.opDependencyList;
    dependencyArray?.forEach((operationId: any) => {
      const findIndex = findIndexInArray(currentBOMOperations, operationId);
      if (findIndex !== -1)
        helperCopierDependentOn(findIndex, currentBOMOperations, qcNeeded);
    });
  };

  const helperDependentArrayOn = (
    index: any,
    currentBOMOperations: any,
    dependentArray: any
  ) => {
    dependentArray.push(currentBOMOperations[index].operationId);
    const dependencyArray: any =
      currentBOMOperations[index]?.operationDependency?.opDependencyList;
    dependencyArray?.forEach((operationId: any) => {
      const findIndex = findIndexInArray(currentBOMOperations, operationId);
      if (findIndex !== -1) {
        helperDependentArrayOn(findIndex, currentBOMOperations, dependentArray);
      }
    });
  };

  const helperDependentArrayBy = (
    index: any,
    currentBOMOperations: any,
    dependentArray: any
  ) => {
    dependentArray.push(currentBOMOperations[index].operationId);
    currentBOMOperations.forEach((row: any, rowIndex: any) => {
      if (
        row?.operationDependency?.opDependencyList?.includes(
          currentBOMOperations[index].operationId
        )
      ) {
        // /dependentArray.push(currentBOMOperations[index].operationId);
        helperDependentArrayBy(rowIndex, currentBOMOperations, dependentArray);
      }
    });
  };

  const helperItemCopierDependentOn = (
    index: any,
    currentBOMOperations: any,
    itemValue: any
  ) => {
    currentBOMOperations[index].product = itemValue;
    currentBOMOperations[index].productCode = itemValue?.productCode;

    if (currentBOMOperations[index].processType === 'CONSUMPTION') {
      return;
    }

    const dependencyArray: any =
      currentBOMOperations[index]?.operationDependency?.opDependencyList;
    dependencyArray?.forEach((operationId: any) => {
      const findIndex = findIndexInArray(currentBOMOperations, operationId);
      if (findIndex !== -1)
        helperItemCopierDependentOn(findIndex, currentBOMOperations, itemValue);
    });
  };

  const helperItemCopierDependentBy = (
    index: any,
    currentBOMOperations: any,
    itemValue: any
  ) => {
    currentBOMOperations[index].product = itemValue;
    currentBOMOperations[index].productCode = itemValue?.productCode;
    currentBOMOperations[index].invalidFields =
      currentBOMOperations[index]?.invalidFields?.filter(
        (invF: any) => invF !== 'product'
      ) ?? currentBOMOperations[index].invalidFields;

    if (currentBOMOperations[index].processType === 'CONSUMPTION') {
      return;
    }

    currentBOMOperations.forEach((row: any, rowIndex: any) => {
      if (
        row?.operationDependency?.opDependencyList?.includes(
          currentBOMOperations[index].operationId
        )
      ) {
        helperItemCopierDependentBy(rowIndex, currentBOMOperations, itemValue);
      }
    });
  };

  const helperCopierDependentBy = (
    index: any,
    currentBOMOperations: any,
    qcNeeded: any
  ) => {
    if (currentBOMOperations[index].processType !== 'PROCESSING') {
      currentBOMOperations[index].qcNeeded = qcNeeded;
    }

    currentBOMOperations.forEach((row: any, rowIndex: any) => {
      if (
        row?.operationDependency?.opDependencyList?.includes(
          currentBOMOperations[index].operationId
        )
      ) {
        helperCopierDependentBy(rowIndex, currentBOMOperations, qcNeeded);
      }
    });
  };

  const getDependentOpIds = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(data.rowData.operationId);
    const dependentArray: any = [];
    helperDependentArrayOn(currentIndex, currentBOMOperations, dependentArray);
    helperDependentArrayBy(currentIndex, currentBOMOperations, dependentArray);
    return dependentArray;
  };

  const updateNonDependentItems = (
    dependentArray: any,
    itemValue: any,
    rowIndex: any
  ) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    let consumptionCount = 0;
    currentBOMOperations.map((item: any, index: any) => {
      if (
        !dependentArray.includes(item.operationId) &&
        item.processType === 'CONSUMPTION' &&
        index >= rowIndex &&
        consumptionCount === 0
      ) {
        consumptionCount++;
        item.product = itemValue;
        item.productCode = itemValue?.productCode;
      }
      if (
        !dependentArray.includes(item.operationId) &&
        item.processType === 'PROCESSING' &&
        consumptionCount < 1
      ) {
        item.product = itemValue;
        item.productCode = itemValue?.productCode;
      }
      return item;
    });
    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const copyDependentOnQC = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(data.rowData.operationId);
    const qcNeeded = data.rowData.qcNeeded;

    helperCopierDependentOn(currentIndex, currentBOMOperations, qcNeeded);
    helperCopierDependentBy(currentIndex, currentBOMOperations, qcNeeded);
    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };
  const copyDependentByQC = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(data.rowData.operationId);
    const qcNeeded = data.rowData.qcNeeded;
    helperCopierDependentOn(currentIndex, currentBOMOperations, qcNeeded);
    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const copyDependentByItem = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(data.rowData.operationId);
    const itemDetails = data.rowData.product;
    helperItemCopierDependentOn(
      currentIndex,
      currentBOMOperations,
      itemDetails
    );
    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const copyDependentOnItem = (data: any) => {
    let bomMetaDetailsCopy = [...bomMetaDetails];
    let currentConfig = bomMetaDetailsCopy[currentEditableIndex.current];
    let currentBOMOperations = [...currentConfig?.bomOperationsConfiguration];
    const currentIndex = currentBOMOperations
      .map((row: any) => row.operationId)
      .indexOf(data.rowData.operationId);
    const itemDetails = data.rowData.product;

    helperItemCopierDependentOn(
      currentIndex,
      currentBOMOperations,
      itemDetails
    );
    helperItemCopierDependentBy(
      currentIndex,
      currentBOMOperations,
      itemDetails
    );
    currentConfig.bomOperationsConfiguration = currentBOMOperations;
    bomMetaDetailsCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomMetaDetailsCopy);
  };

  const addScrapCoSubProduct = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempByProducts: any[] = [];
    if (!Utility.isEmpty(currentConfig?.byProducts)) {
      tempByProducts = currentConfig?.byProducts
        ? [...currentConfig?.byProducts]
        : [];
    }
    var component = {
      itemId: null,
      itemName: null,
      quantity: 0,
      cost: 0,
      produceProductType: PRODUCE_PRODUCT_TYPE.SCRAP
    };
    tempByProducts.push(component);
    currentConfig.byProducts = [...tempByProducts];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAdditionalCost = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };

    var tempAdditionalCosts: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomAddCostConfiguration)) {
      tempAdditionalCosts = [...currentConfig?.bomAddCostConfiguration];
    }
    var cost = {
      label: '',
      price: 0
    };
    tempAdditionalCosts.push(cost);
    currentConfig.bomAddCostConfiguration = [...tempAdditionalCosts];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAnOperations = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempOperations: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomOperationsConfiguration)) {
      tempOperations = [...currentConfig?.bomOperationsConfiguration];
    }
    var cost = {
      operationId: null,
      operationName: '',
      totalCost: 0,
      costPerHour: 0,
      fixedRate: 0
    };
    tempOperations.push(cost);
    currentConfig.bomOperationsConfiguration = [...tempOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const insertOperation = (index: number, bomIndex: number) => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[bomIndex] };
    let tempOperations: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomOperationsConfiguration)) {
      tempOperations = [...currentConfig?.bomOperationsConfiguration];
    }
    let cost = {
      operationId: null,
      operationName: '',
      totalCost: 0,
      costPerHour: 0,
      fixedRate: 0,
      isInsertedRow: true
    };

    const newOperationIndex = index + 1;
    if (
      currentConfig?.bomOperationsConfiguration?.length === newOperationIndex
    ) {
      tempOperations.push(cost);
    } else {
      tempOperations.splice(newOperationIndex, 0, cost);
    }
    currentConfig.bomOperationsConfiguration = [...tempOperations];
    bomDetailMetaCopy[bomIndex] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addAttachements = () => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    var tempOperations: any[] = [];
    if (!Utility.isEmpty(currentConfig?.bomOperationsConfiguration)) {
      tempOperations = [...currentConfig?.bomOperationsConfiguration];
    }
    var cost = {
      operationId: null,
      operationName: '',
      totalCost: 0,
      costPerHour: 0,
      fixedRate: 0
    };
    tempOperations.push(cost);
    currentConfig.bomOperationsConfiguration = [...tempOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  const addOperationsFromTemplate = (bomOperations: BomOperation[]) => {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };

    let productCodes = currentConfig.bomProductsConfiguration?.map(
      (item) => item.productCode
    );

    let updatedBomOperations = bomOperations.map((bomOperation) => ({
      ...bomOperation,
      productCode: productCodes.includes(bomOperation.productCode)
        ? bomOperation.productCode
        : undefined
    }));

    currentConfig.bomOperationsConfiguration = [...updatedBomOperations];
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  };

  function addMoreConfig(object?: BomConfigurationModel) {
    setBomMetaDetails([
      ...bomMetaDetails,
      deepClone(object ?? NewBomConfigurationModel)
    ]);
  }

  function updateBOMName(name: string, index: number) {
    let copyBomMetaDetails = [...bomMetaDetails];
    let currentConfig = copyBomMetaDetails[index];
    currentConfig.name = name;
    copyBomMetaDetails[index] = currentConfig;
    setBomMetaDetails(copyBomMetaDetails);
  }

  function updateDefaultConfig(index: number) {
    let copyBomMetaDetails = [...bomMetaDetails].map(
      (configObj: any, configIndex: number) => {
        let copyConfigObj = { ...configObj };
        // if (index === configIndex) {
        //   if (configObj.active) {
        //     copyConfigObj.isDefault = true;
        //   }
        // }
        return {
          ...copyConfigObj,
          isDefault: copyConfigObj?.active ? index === configIndex : false
        };
      }
    );
    setBomMetaDetails(copyBomMetaDetails);
  }

  function deleteConfigurationAtIndex(index: number) {
    let copyConfig = [...bomMetaDetails];
    let currentConfig: BomConfigurationModel = { ...copyConfig[index] };
    if (currentConfig.isDefault) {
    } else {
      copyConfig.splice(index, 1);
      setBomMetaDetails(copyConfig);
    }
    return currentConfig.isDefault;
  }

  function updatedCurrentEditableIndex(index: number) {
    currentEditableIndex.current = index;
  }

  function updateSubstituteForComponentProduct(
    selectedComponentProductIndex: number,
    selectedProducts: any
  ) {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig = { ...bomDetailMetaCopy[currentEditableIndex.current] };
    const copyOfComponentProducts = deepClone(
      currentConfig?.bomProductsConfiguration
    );
    copyOfComponentProducts[
      selectedComponentProductIndex
    ].bomProductSubstitutesDetails = [];
    copyOfComponentProducts[
      selectedComponentProductIndex
    ].bomProductSubstitutesDetails = selectedProducts;
    currentConfig.bomProductsConfiguration = copyOfComponentProducts;
    bomDetailMetaCopy[currentEditableIndex.current] = currentConfig;
    setBomMetaDetails(bomDetailMetaCopy);
  }

  /**
   * Returns if Item is default and updates if not.
   * @return {number} index of editable item.
   */
  function updateActiveStatusForConfigAtIndex(index: number) {
    let bomDetailMetaCopy = [...bomMetaDetails];
    let currentConfig: BomConfigurationModel = { ...bomDetailMetaCopy[index] };
    if (currentConfig.isDefault) {
    } else {
      currentConfig.active = !currentConfig.active;
      bomDetailMetaCopy[index] = currentConfig;
      setBomMetaDetails(bomDetailMetaCopy);
    }
    return currentConfig.isDefault;
  }

  function getCurrentSelectedConfig() {
    return bomMetaDetails[currentEditableIndex.current] ?? {};
  }

  function getCurrentEditableIndex() {
    return currentEditableIndex.current;
  }

  function getUnchangedProduct(
    initialProductWithNoCurrentChange: any,
    selectedComponentProductIndex: any,
    currentConfiguration: any
  ) {
    const metaProductFound =
      initialProductWithNoCurrentChange?.bomMetaDetailsList?.find((f1: any) => {
        return f1.id === currentConfiguration?.id;
      });

    const substituteObjFound =
      metaProductFound?.bomProductsConfiguration?.[
        selectedComponentProductIndex
      ]?.['bomProductSubstitutesDetails'];

    return substituteObjFound;
  }

  const updateDependentOperation = (opId: any, records: any[]) => {
    if (!opId || !records?.length) {
      return;
    }

    records?.forEach((op: any) => {
      if (op.operationDependency?.opDependencyList?.includes(opId)) {
        //op.qcNeeded = false;
        op.productCode = null;

        updateDependentOperation(op.operationId, records);
      }
    });
  };

  return {
    addComponentProduct,
    getComponentProducts,
    removeComponent,
    onRowUpdate,
    dragAndDropOperation,
    copyDependentByQC,
    copyDependentByItem,
    copyDependentOnItem,
    copyDependentOnQC,
    getDependentOpIds,
    updateNonDependentItems,
    addScrapCoSubProduct,
    addAdditionalCost,
    addAnOperations,
    removeScrapCoProduct,
    removeAdditionalCost,
    removeOperation,
    updateDefaultConfig,
    addMoreConfig,
    deleteConfigurationAtIndex,
    updateBOMName,
    updatedCurrentEditableIndex,
    updateSubstituteForComponentProduct,
    updateActiveStatusForConfigAtIndex,
    getCurrentSelectedConfig,
    getCurrentEditableIndex,
    getUnchangedProduct,
    addOperationsFromTemplate,
    addAttachment,
    setAttachment,
    deleteAttachment,
    insertComponentProduct,
    insertOperation
  };
}
