import TableDataParser from '../Helper/TableDataParser';
import { IColumn, IFilterCondition, ITableFilter } from '../Models/Table';
import Utility from '../Utility/Utility';
import { INPUT_TYPE } from 'deskera-ui-library';

export const TABLES = {
  PRODUCT: 'product',
  CONTACT: 'contact',
  INVOICES: 'invoices',
  BILLS: 'bills',
  PURCHASE_ORDERS: 'purchase orders',
  REQUISITION: 'requisition',
  QUOTES: 'quotes',
  DRAFTS: 'draft',
  AUTOMATION: 'automation',
  ACTIVITY: 'activity'
};

export class TableManager {
  static tableMap = new Map();
  static getTableColumns(tableName: string): IColumn[] {
    return this.tableMap.get(tableName)
      ? this.tableMap.get(tableName).columns
      : [];
  }

  static getTableVisibleColumns(tableName: string): IColumn[] {
    return this.getTableColumns(tableName).filter((column) => !column.hidden);
  }

  static populateViewMap(data: any) {
    data.forEach((item: any) => {
      this.tableMap.set(item['name'], TableDataParser.parseTableData(item));
    });
  }
  
  static setTableColumns(columnData: any, tableName: string) {
    this.tableMap.set(tableName, {
      ...this.tableMap.get(tableName),
      ...TableDataParser.parseTableData(columnData)
    });
  }
  static updateColumnConfig(newConfig: any, tableName: string) {
    const tableManagerConfig = this.tableMap.get(tableName);
    TableManager.tableMap.set(tableName, {
      ...tableManagerConfig,
      columns: newConfig
    });
  }
  static updateColumn(columnCode: any, tableName: string, columnData: any) {
    let columnsIndex = this.tableMap
      .get(tableName)
      .columns.findIndex((column: any) => column.columnCode === columnCode);
    let columns = this.tableMap.get(tableName).columns;
    columns.splice(columnsIndex, 1, columnData);
    this.tableMap.set(tableName, { ...this.tableMap.get(tableName), columns });
  }
  static getTableId(tableName: string): string {
    return this.tableMap?.get(tableName)?.id;
  }
  static getTableName(tableName: string): string {
    return this.tableMap?.get(tableName)?.name;
  }

  static getTableImportColumns(
    tableName: string,
    excludedColumns?: Array<string>
  ): IColumn[] {
    return this.getTableColumns(tableName).filter((column) => {
      const isExcluded =
        excludedColumns &&
        excludedColumns.includes(column.columnCode as string);
      const isEditable = column.uiVisible && column.editable;

      return !isExcluded;
    });
  }
  static getColumnId(
    tableName: string,
    columnCode: string
  ): string | undefined {
    let column = this.getColumn(tableName, columnCode);
    return column ? column.id : undefined;
  }
  static getColumn(tableName: string, columnCode: string): IColumn {
    return this.getTableColumns(tableName).find(
      (column) => column.columnCode === columnCode
    ) as IColumn;
  }

  static getColumnById(tableName: string, columnId: string): IColumn {
    return this.getTableColumns(tableName).find((column) => {
      return column.id === columnId;
    }) as IColumn;
  }

  static getFilterObject(data: any): ITableFilter {
    let filterObject: ITableFilter = {
      logicalOperator: 'and',
      conditions: []
    };
    data.forEach((item: any) =>
      filterObject.conditions.push(this.getFilterSubCondition(item))
    );
    return filterObject;
  }
  static getFilterSubCondition(item: any): IFilterCondition {
    return {
      colId: item.key,
      value: item.value,
      opr: item.condition
    };
  }
  static isSelectField(column: any) {
    return (
      column.type === INPUT_TYPE.SELECT ||
      column.type === INPUT_TYPE.MULTI_SELECT ||
      column.type === 'ref'
    );
  }
  static getTableFilteredColumns(
    tableName: string,
    condition: Function,
    filterEditable?: Boolean
  ): IColumn[] {
    return this.getTableColumns(tableName).filter((column) => {
      const isIncluded = condition(column);
      const isEditable = column.uiVisible && column.editable;

      return filterEditable ? isIncluded && isEditable : isIncluded;
    });
  }
}

export const COLUMN_CODE = {
  ACTIVITY: {
    NAME: `name`,
    ACTIVTY_TYPE: `activity_type`,
    START_DATE: `start_date`,
    END_DATE: `end_date`,
    NOTES: `notes`,
    ASSIGNEE_IDS: `assignee_ids`,
    CONTACT_IDS: `contact_ids`,
    ORGANIZATION_IDS: `organization_ids`,
    LINKED_TO: `linked_to`,
    STATUS: `status`,
    SEND_INVITATION: `send_invitation`,
    SEND_CONTACTS_INVITATION: `send_contacts_invitation`,
    OWNER_ID: `owner_id`,
    TIMEZONE: `timezone`
  }
};
