import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { AccountInfoState, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import CurrencyService from '../../Services/Currency';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  syncConfig: {} as any,
  currencyHistory: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchCurrency = createAsyncThunk(
  'fetchCurrencyWithExhangeRate',
  async () => {
    const response = await CurrencyService.getCurrencyListWithExchangeRates();
    return response;
  }
);

export const fetchCurrencySyncConfig = createAsyncThunk(
  'fetchCurrencySyncConfig',
  async () => {
    const response = await CurrencyService.getCurrencySyncConfig();
    return response;
  }
);

export const fetchCurrencyHistroy = createAsyncThunk(
  'fetchCurrencyHistory',
  async () => {
    const response = await CurrencyService.getCurrencyHistrory();
    return response;
  }
);

export const CurrencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    addCurrencyColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    },
    addCurrency: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    clearCurrencyHistory: (state, action: PayloadAction<any[]>) => {
      state.currencyHistory = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrency.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchCurrencySyncConfig.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrencySyncConfig.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.syncConfig = action.payload;
      })
      .addCase(fetchCurrencyHistroy.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCurrencyHistroy.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.currencyHistory = action.payload;
      });
  }
});

export const { addCurrency, addCurrencyColumnConfig, clearCurrencyHistory } =
  CurrencySlice.actions;
export const selectCurrency = (state: RootState) => state.currency.data;
export const selectCurrencySyncConfig = (state: RootState) =>
  state.currency.syncConfig;
export const selectCurrencyHistory = (state: RootState) =>
  state.currency.currencyHistory;
export const selectCurrencyColumnConfig = (state: RootState) =>
  state.currency.columnConfig;
