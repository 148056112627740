import React, { Component } from "react";
import "../../index.css";
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";
import { getLocalisedText } from "../../Translate/LanguageManager";
import { FONT_SIZE } from "../../Constants/Constants";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { OwnerType } from "../../Helper/AddressParser";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";

export default class QCInspectionTemplate1 extends Component {

    arabicHeaderWidth = '85%'
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.setData(this.props.data),
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: this.setData(nextProp.data),
        });
    }

    getDocumentType() {
        return (
            <div
                className="RowDiv pt-s pb-s parent-width"
                style={{
                    flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
                    width: "100%",
                }}
            >
                <text
                    className={"fontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale),
                    }}
                >
                    {getLocalisedText(this.state.data.documentType)}
                </text>
            </div>
        );
    }

    setData(data) {
        var newData = data

        newData.leftTableList = []
        newData.rightTableList = []

        var documentDetailList = Utility.getDocumentDetailsList(data)

        var newDocumentDetailList = documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            var item = {
                label: element.label,
                value,
            }
            return item
        });

        if (newDocumentDetailList.length > 0) {
            var leftCount = Math.ceil(newDocumentDetailList.length / 2)
            newData.leftTableList = newDocumentDetailList.slice(0, leftCount)
            newData.rightTableList = newDocumentDetailList.slice(leftCount)
        }
        return newData
    }

    renderTableView(dataArray) {
        return <table
            style={{
                alignItems: "flex-start",
                justifyContent: 'space-between',
                backgroundColor: "white",
                border: '2px solid black',
                borderWidth: getVW(Utility.convertToPrintScale(this.borderWidth, this.state.data.printScale)),
                paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                width: '100%',
                borderCollapse: 'collapse',
            }}
        >
            <tbody>
                {this.renderTable(dataArray)}
            </tbody>
        </table>
    }

    renderTable(dataArray) {
        if (dataArray !== undefined && dataArray !== null) {
            if (dataArray.length > 0) {
                return dataArray.map(element => {
                    return this.renderTableRow(element)
                });
            }
        }
    }

    renderTableRow(item) {
        var headerStyle = {
            backgroundColor: 'rgb(230, 230, 230)',
            color: this.state.data.themeColor,
        }
        return <tr>
            {this.renderTableColumn(item.label, headerStyle, ' : ')}
            {this.renderTableColumn(item.value, {})}
        </tr>
    }

    renderTableColumn(value, style, additionalText) {
        return <td
            style={{
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                ...style,
                textAlign: 'left',
                border: '2px solid black',
                borderWidth: getVW(Utility.convertToPrintScale(this.borderWidth, this.state.data.printScale)),
                borderCollapse: 'collapse',
                width: '50%',
                padding: getVW(2)
            }}>
            {value}{additionalText}
        </td>
    }

    renderDateAndCompanySection() {
        return <div className="ColumnDiv parent-width align-items-start">
            <div className="pl-s pr-s" style={{
                border: '2px solid black',
                borderTop: '0px solid',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
            }}>
                {this.state.data.date}
            </div>
            <div className="RowDiv"
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:'100%'
                }}>
                <text className={"fontStyleBold" + getFontSizeClass()}
                    style={{ ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale) }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>
            </div>
        </div>
    }

    renderTopSection() {
        return <div className="RowDiv" style={{
            alignItems: 'inherit'
        }}>
            {this.renderTableView(this.state.data.leftTableList)}
            {ComponentManager.addHorizontalSpace(20 * this.spacingRatio)}
            {this.renderTableView(this.state.data.rightTableList)}
        </div>
    }

    renderDocumentTable() {
        return <div className="RowDiv align-items-start parent-width">
            <div className="ColumnDiv parent-width">
                {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                {this.getEditableDocumentTable()}
                {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
            </div>
        </div>
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "black";
        style.itemBorderColor = "black";
        style.headerBackgroundColor = "rgb(230, 230, 230)";
        style.headerBorderBottomWidth = getVW(Utility.convertToPrintScale(2, this.state.data.printScale))
        return style;
    }

    renderBottomSection() {
        var quantityList = [
            { label: getLocalisedText('sample_size'), value: this.state.data.quantity },
            { label: getLocalisedText('number_of_qty_passed'), value: this.state.data.acceptedQuantity },
            { label: getLocalisedText('number_of_qty_rejected'), value: this.state.data.rejectedQuantity }
        ]
        return <div className="RowDiv" style={{
            alignItems: 'inherit'
        }}>
            {this.renderTableView(quantityList)}
            {ComponentManager.addHorizontalSpace(20 * this.spacingRatio)}
            <div className={"fontStyle" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: OwnerType.tenant }, this.state.data.printScale),
                    width: '100%',
                    border: '2px solid black',
                    textAlign: 'center',
                    paddingBottom: getVW(2),
                }}>
                    <br/>
                    <br />
                    <br />
                    {getLocalisedText('signature')}
            </div>
        </div>
    }

    renderRemarkSection() {
        let tdStyle = {
            textAlign: 'left',
            border: '2px solid black',
            borderWidth: getVW(this.borderWidth),
            borderCollapse: 'collapse',
            padding: getVW(2),
        }
        var headerStyle = {
            backgroundColor: 'rgb(230, 230, 230)',
            color: this.state.data.themeColor,
        }
        return <div className="ColumnDiv parent-width">
            {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
            <table
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    border: '2px solid black',
                    borderWidth: getVW(Utility.convertToPrintScale(this.borderWidth, this.state.data.printScale)),
                    paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                    width: '100%',
                    borderCollapse: 'collapse',
                }}
            >
                <tbody>
                    <tr>
                        <td
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                                ...tdStyle,
                                ...headerStyle,
                                width: '24.5%',
                            }}>
                            {getLocalisedText('final_remark')}
                        </td>
                        <td
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale),
                                ...tdStyle,
                                width: '75.5%',
                                padding: getVW(2)
                            }}>
                            {this.state.data.notes}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 4
        style.columnWidths = Utility.getIsArabicLang() ? ['25%', '25%', '25%', '25%'] : ['25%', '25%', '25%', '25%']
        return style;
    }

    render() {
        return (
            <div
                className="DocumentHolder ColumnDiv"
                style={{ minHeight: getPageHeight() }}
            >
                <div
                    className="parent-width"
                    style={{ width: "100%", paddingBottom: getVW(30) }}
                >
                    {this.getDocumentType()}
                    {this.renderTopSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor isShowGrid />
                    {this.renderDocumentTable()}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor isShowGrid />
                </div>
            </div>
        );
    }
}
