import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';
import BookKeeperClient from '../../Services/BookKeeperClient';
import BookKeeperTeamService from '../../Services/BookKeeperTeam';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchTeamsData = createAsyncThunk(
  'bkTeamsData',
  async (type: any) => {
    const response = await BookKeeperClient.getClientOrTeamsGridData(type);
    return response;
  }
);

export const fetchRoleGroups = createAsyncThunk('roleGroups', async () => {
  const response = await BookKeeperClient.getRoleGroups();
  return response;
});

export const fetchTeamUserDetails = createAsyncThunk(
  'teamUserDetails',
  async (userId: any) => {
    const response = await BookKeeperTeamService.getTeamUserDetails(userId);
    return response;
  }
);

export const BookKeeperTeamSlice = createSlice({
  name: 'BookKeeperTeam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamsData.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTeamsData.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchRoleGroups.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchRoleGroups.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.roleGroups = action.payload;
      })
      .addCase(fetchTeamUserDetails.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTeamUserDetails.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.teamUserDetails = action.payload;
      });
  }
});

export const {} = BookKeeperTeamSlice.actions;

export const bkTeamData = (state: RootState) => {
  return state.bkTeam?.data;
};

export const bkRoleGroups = (state: RootState) => {
  return state.bkTeam?.roleGroups;
};

export const teamuserDetails = (state: RootState) => {
  return state.bkTeam?.teamUserDetails;
};

export default BookKeeperTeamSlice.reducer;
