import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';

export interface ClientPortalAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

class ClientPortalService {
  static getClientPortalUser(params = {}) {
    return http
      .get(ApiConstants.URL.CLIENT_PORTAL.GET_CLIENT_PORTAL, { params })
      .catch((err: any) => {
        console.error('Error fetching Client Portal: ', err);
        return Promise.reject(err);
      });
  }
  static inviteUser(payload: any) {
    return http
      .post(ApiConstants.URL.CLIENT.INVITE, payload)
      .catch((err: any) => {
        console.error('Error saving class: ', err);
        return Promise.reject(err);
      });
  }
  static resendInvite(payload: any) {
    return http
      .post(ApiConstants.URL.CLIENT.RESEND_INVITE, payload)
      .catch((err: any) => {
        console.error('Error saving class: ', err);
        return Promise.reject(err);
      });
  }
  static patchClientSetting(req: any) {
    return http
      .patch(ApiConstants.URL.CRM_TENANT.TENANT, req)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static getClientSetting(req: any) {
    return http.get(ApiConstants.URL.CRM_TENANT.TENANT).catch((err: any) => {
      return Promise.reject(err);
    });
  }
}
export const updateUserStatus = (
  userIds: string[],
  inactive: boolean = false
) => {
  return http.patch(ApiConstants.URL.CLIENT_PORTAL.UPDATE_USER_STATUS, {
    userIds,
    inactive
  });
};
export const deleteUser = (id: string) => {
  return http.delete(ApiConstants.URL.CLIENT_PORTAL.DELETE_USER(id));
};

export default ClientPortalService;
