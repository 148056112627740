import ApiConstants, {
  IDefaultAPIConfig,
  defaultParamsSerializer
} from '../Constants/ApiConstants';
import { IWHTax } from '../Models/WHTax';
import _httpClient from './Interceptor';

export interface WHTaxConfig extends IDefaultAPIConfig {}

export const defaultConfig: WHTaxConfig = {
  Limit: 25,
  Query: '',
  Page: 0,
  Search: ''
};
export const getStandardWithHoldTax = (
  params = defaultConfig
): Promise<any> => {
  return _httpClient.get(ApiConstants.URL.WITH_HOLDING_TAX.FETCH_STANDARD_WHT, {
    params
  });
};
export const getWithHoldTax = (params = defaultConfig): Promise<any> => {
  return _httpClient.get(ApiConstants.URL.WITH_HOLDING_TAX.FETCH_WHT, {
    params,
    paramsSerializer: defaultParamsSerializer
  });
};
export const createWHTax = (payload: IWHTax): Promise<any> => {
  return _httpClient.post(
    ApiConstants.URL.WITH_HOLDING_TAX.CREATE_WHT,
    payload
  );
};
export const updateWHTax = (payload: IWHTax): Promise<any> => {
  return _httpClient.put(
    ApiConstants.URL.WITH_HOLDING_TAX.UPDATE_WHT(payload.tdsId),
    payload
  );
};
export const deleteWHTax = (payload: IWHTax): Promise<any> => {
  return _httpClient.delete(
    ApiConstants.URL.WITH_HOLDING_TAX.DELETE_WHT(payload.tdsId));
};
export const getWithHoldTaxGroup = (params = defaultConfig): Promise<any> => {
  return _httpClient.get(ApiConstants.URL.WITH_HOLDING_TAX.FETCH_WHT_GROUP, {
    params,
    paramsSerializer: defaultParamsSerializer
  });
};

export const createWHTaxGroup = (payload: any): Promise<any> => {
  return _httpClient.post(
    ApiConstants.URL.WITH_HOLDING_TAX.CREATE_WHT_GROUP,
    payload
  );
};
export const updateWHTaxGroup = (payload: any): Promise<any> => {
  return _httpClient.put(
    ApiConstants.URL.WITH_HOLDING_TAX.UPDATE_WHT_GROUP(payload.id),
    payload
  );
};
export const deleteWHTaxGroup = (payload: any): Promise<any> => {
  return _httpClient.delete(
    ApiConstants.URL.WITH_HOLDING_TAX.DELETE_WHT_GROUP(payload.id),
    payload
  );
};
