import { BOOKS_DATE_FORMAT, DATE_FORMAT } from '../../../Constants/Constant';
import DateFormatService from '../../../Services/DateFormat';
import Utility from '../../../Utility/Utility';

export const jeTypesArray = ['CREDIT', 'DEBIT'];

const showDateSection = (item: any) => {
  return DateFormatService.getFormattedDateString(
    item?.jeDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
};

export const showDateAndRecuringSection = (item: any) => {
  let html = '';
  html = `${showDateSection(item)}`;
  return html;
};

export const showNewSection = (item: any) => {
  const je_date = DateFormatService.getDateFromStr(
    item.jeDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const todays_date = new Date();

  // '_t' comparision is for todays exact date
  const je_date_t = DateFormatService.getFormattedDateString(
    item.jeDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY'],
    BOOKS_DATE_FORMAT['YYYY-MM-DD']
  );
  const todays_date_t = Utility.formatDate(new Date(), DATE_FORMAT.YYYY_MM_DD);

  if (je_date > todays_date || je_date_t === todays_date_t) {
    return `<span class="data-grid-badge-color-7 pl-1.5 pr-1.5 text-wrap-none m-s border-radius-l">New</span>`;
  } else {
    return '';
  }
};

export const getEmptyRowType = (lineItems: any) => {
  return lineItems[lineItems?.length - 1]?.type === jeTypesArray[0] ||
    lineItems?.length === 0
    ? jeTypesArray[1]
    : jeTypesArray[0];
};
