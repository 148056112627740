import { useEffect, useState } from 'react';
import {
  DKDataGrid,
  DKButton,
  DKLabel,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { useAppSelector } from '../../Redux/Hooks';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectPurchaseTax } from '../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { ConfigUtility } from '../../Utility/ConfigUtility';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import WarehouseService from '../../Services/Warehouse';
import { getTenantTaxSystem } from '../DocumentForm/NewDocumentHelper';
import FixedAssetService from '../../Services/FixedAsset';
import DataGridHolder from '../DataGridHolder';
import { IState } from '../../Models/Interfaces';
import { subDays } from 'date-fns';

const DEPRECIATION_METHODS = [
  { name: 'No Depreciation', value: 'NO_DEPRECIATION' },
  { name: 'Straight Line', value: 'STRAIGHT_LINE' },
  { name: 'Instant Asset Write-off', value: 'INSTANT_ASSET_WRITE_OFF' }
];

const DEPRECIATION_CONVENTIONS = [
  { name: 'Full Month', value: 'FULL_MONTH' },
  { name: 'Actual Date', value: 'ACTUAL_DATE' }
];

let initialState = {
  assetGroupId: null,
  name: '',
  openingAsset: false,
  serialNumber: '',
  purchaseDate: '',
  purchasePrice: 0,
  description: '',
  depreciationStartDate: '',
  discount: 0,
  discountInPercent: false,
  residualValue: '',
  depreciationThreshold: '',
  depreciationMethod: 'NO_DEPRECIATION',
  depreciationConvention: 'FULL_MONTH',
  depreciationRate: null,
  currency: '',
  sequenceFormat: '',
  profitLossAccountCode: '',
  salesIncomeAccountCode: '',
  warrantyStartDate: '',
  warrantyEndDate: '',
  warehouse: '',
  tax: null,
  installationDate: '',
  effectiveLife: null
};

const AssetDetailsColumnConfig = [
  {
    id: 'name',
    key: 'name',
    columnCode: 'name',
    name: 'Asset Name',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'description',
    key: 'description',
    columnCode: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'purchaseDate',
    key: 'purchaseDate',
    columnCode: 'purchaseDate',
    name: 'Purchase Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'installationDate',
    key: 'installationDate',
    name: 'Installation Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'purchasePrice',
    key: 'purchasePrice',
    columnCode: 'purchasePrice',
    name: 'Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'discount',
    key: 'discount',
    columnCode: 'discount',
    name: 'Discount',
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'tax',
    key: 'tax',
    columnCode: 'tax',
    name: 'Tax',
    type: INPUT_TYPE.DROPDOWN,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    renderer: DocumentConfigUtility.faTaxRenderer,
    dropdownConfig: {
      title: 'Select Tax',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: DocumentConfigUtility.taxOptionRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'totalAmount',
    key: 'totalAmount',
    columnCode: 'totalAmount',
    name: 'Amount',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'serialNumber',
    key: 'serialNumber',
    columnCode: 'serialNumber',
    name: 'Serial No.',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'warehouse',
    key: 'warehouse',
    columnCode: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'profitLossAccountCode',
    key: 'profitLossAccountCode',
    columnCode: 'profitLossAccountCode',
    name: 'Profit/Loss Account',
    type: INPUT_TYPE.DROPDOWN,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value.name;
    },
    dropdownConfig: {
      title: 'Select Account',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 268 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'salesIncomeAccountCode',
    key: 'salesIncomeAccountCode',
    columnCode: 'salesIncomeAccountCode',
    name: 'Purchase/Expense Account',
    type: INPUT_TYPE.DROPDOWN,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value.name;
    },
    dropdownConfig: {
      title: 'Select Account',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 268 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'depreciationStartDate',
    key: 'depreciationStartDate',
    columnCode: 'depreciationStartDate',
    name: 'Depreciation Start Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'depreciationThreshold',
    key: 'depreciationThreshold',
    columnCode: 'depreciationThreshold',
    name: 'Depreciation Threshold',
    type: INPUT_TYPE.NUMBER,
    width: 180,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'residualValue',
    key: 'residualValue',
    columnCode: 'residualValue',
    name: 'Residual Value',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'depreciationMethod',
    key: 'depreciationMethod',
    columnCode: 'depreciationMethod',
    name: 'Depreciation Method',
    type: INPUT_TYPE.DROPDOWN,
    width: 168,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value?.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 169 },
      className: 'shadow-m width-auto',
      data: DEPRECIATION_METHODS,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      renderer: (index: number, obj: any) => {
        return obj ? obj.name : '';
      }
    }
  },
  {
    id: 'depreciationConvention',
    key: 'depreciationConvention',
    columnCode: 'depreciationConvention',
    name: 'Depreciation Convention',
    type: INPUT_TYPE.DROPDOWN,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value?.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 190 },
      className: 'shadow-m width-auto',
      data: DEPRECIATION_CONVENTIONS,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      renderer: (index: number, obj: any) => {
        return obj.name;
      }
    }
  },
  {
    id: 'depreciationRate',
    key: 'depreciationRate',
    columnCode: 'depreciationRate',
    name: 'Depreciation Rate',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'effectiveLife',
    key: 'effectiveLife',
    name: 'Asset Life',
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'warrantyStartDate',
    key: 'warrantyStartDate',
    columnCode: 'warrantyStartDate',
    name: 'Warranty Start Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'warrantyEndDate',
    key: 'warrantyEndDate',
    columnCode: 'warrantyEndDate',
    name: 'Warranty End Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true
  }
];
const initialGridState: IState = {
  columnData: AssetDetailsColumnConfig,
  rowData: [],
  originalData: [],
  filter: []
};
export default function FixedAssetRGDetails(props: any) {
  const tenantDetails = useAppSelector(activeTenantInfo);
  const accountsData = useAppSelector(selectedAccounts);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const warehousesData = useAppSelector(selectWarehouse);
  const [gridData, setgridData] = useState<any>(initialGridState);
  const [documentDate] = useState<Date>(props.documentDate);
  const [lineItem, setLineItem] = useState<any>(props.lineItemData?.rowData);
  const [assetDetails, setAssetDetails] = useState<any[]>([]);
  const [columnConfig, setColumnConfig] = useState(AssetDetailsColumnConfig);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [isAssetPresent, setIsAssetPresent] = useState<any>(false);
  const tenantInfo = useAppSelector(activeTenantInfo);

  useEffect(() => {
    let payload: any = {
      documentType: props.documentType,
      documentCode: props.documentCode,
      assetGroupId: lineItem.assetGroupId,
      documentItemCode:
        (props.documentType === 'PURCHASE_INVOICE'
          ? lineItem.invoiceItemCode
          : lineItem.purchaseOrderItemCode) || '',
      linkedDocumentCode:
        props.booksDocument?.linkedDocuments?.[0]?.documentCode ||
        props.booksDocument?.linkedPurchaseInvoices?.[0]?.purchaseInvoiceCode ||
        '',
      lineNumber: lineItem.lineNumber || ''
    };
    FixedAssetService.getAssetDetails(payload)
      .then((res: any) => {
        let updatedLineItem = { ...lineItem };
        if (res.length > 0) {
          updatedLineItem.assetDetails = [...res];
          setIsAssetPresent(true);
        } else {
          let newColumnConfig = [...columnConfig];
          newColumnConfig.forEach((config: any, index: any) => {
            if (config.key === 'tax') {
              config.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
            }
            if (props.isReadOnly) {
              newColumnConfig[index].editable = false;
            } else {
              newColumnConfig[index].editable = true;
            }
          });
          setColumnConfig(newColumnConfig.filter((col: any) => !col.hidden));
        }
        setLineItem({ ...updatedLineItem });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (
      !Utility.isEmpty(lineItem.assetDetails) &&
      lineItem.assetDetails?.length > 0
    ) {
      let details = [];
      for (let i = 0; i < lineItem.assetDetails?.length; i++) {
        const total = props.isRecord
          ? calculateLineLevelTotalForRecord(lineItem.assetDetails[i])
          : calculateInitialLineLevelTotal(
              lineItem,
              lineItem.assetDetails?.length
            );
        const assetDetail = lineItem.assetDetails[i];

        details.push({
          ...assetDetail,
          idRow: i,
          totalAmount: total,
          currency: props.currency,
          tax: assetDetail.taxCode ? mapPurchaseTax(assetDetail.taxCode) : {},
          depreciationStartDate: assetDetail.depreciationStartDate
            ? DateFormatService.getDateFromStr(
                assetDetail.depreciationStartDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ).toISOString()
            : '',
          purchaseDate: assetDetail.purchaseDate
            ? DateFormatService.getDateFromStr(
                assetDetail.purchaseDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ).toISOString()
            : '',
          installationDate: assetDetail.installationDate
            ? DateFormatService.getDateFromStr(
                assetDetail.installationDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ).toISOString()
            : '',
          warrantyEndDate: assetDetail.warrantyEndDate
            ? DateFormatService.getDateFromStr(
                assetDetail.warrantyEndDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ).toISOString()
            : '',
          warrantyStartDate: assetDetail.warrantyStartDate
            ? DateFormatService.getDateFromStr(
                assetDetail.warrantyStartDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ).toISOString()
            : '',
          depreciationConvention:
            typeof assetDetail?.depreciationConvention === 'string'
              ? DEPRECIATION_CONVENTIONS.find(
                  (x: any) => x.value === assetDetail?.depreciationConvention
                )
              : assetDetail?.depreciationConvention,
          depreciationMethod:
            typeof assetDetail?.depreciationMethod === 'string'
              ? DEPRECIATION_METHODS.find(
                  (x: any) => x.value === assetDetail?.depreciationMethod
                )
              : assetDetail?.depreciationMethod,
          profitLossAccountCode:
            typeof assetDetail?.profitLossAccountCode === 'string'
              ? accountsData?.content?.find(
                  (item: any) =>
                    item.code === assetDetail?.profitLossAccountCode
                )
              : assetDetail?.profitLossAccountCode,
          salesIncomeAccountCode:
            typeof assetDetail?.salesIncomeAccountCode === 'string'
              ? accountsData?.content?.find(
                  (item: any) =>
                    item.code === assetDetail?.salesIncomeAccountCode
                )
              : assetDetail?.salesIncomeAccountCode,
          warehouse:
            typeof assetDetail?.warehouse === 'string'
              ? warehousesData?.content?.find(
                  (item: any) => item.code === assetDetail?.warehouse
                )
              : assetDetail?.warehouse
        });
      }
      setAssetDetails([...details]);
    } else {
      let details = [];
      for (let i = 0; i < lineItem.quantity; i++) {
        const total = calculateInitialLineLevelTotal(
          lineItem,
          lineItem.quantity
        );
        if (lineItem?.assetGroupResponse) {
          lineItem.fixedAssetGroup = lineItem?.assetGroupResponse
        }
        details.push({
          ...initialState,
          idRow: i,
          assetGroupId: lineItem?.assetGroupId,
          purchasePrice: lineItem?.unitPrice,
          discount: Utility.roundOffToTenantDecimalScale(
            lineItem?.discount / lineItem.quantity
          ),
          totalAmount: Number(Utility.roundOffToTenantDecimalScale(total)),
          tax: lineItem.taxCode ? mapPurchaseTax(lineItem.taxCode) : {},
          currency: props.currency,
          depreciationConvention: lineItem?.fixedAssetGroup
            ?.depreciationConvention
            ? DEPRECIATION_CONVENTIONS.find(
                (x: any) =>
                  x.value === lineItem?.fixedAssetGroup?.depreciationConvention
              )
            : '',
          depreciationMethod: lineItem?.fixedAssetGroup?.depreciationMethod
            ? DEPRECIATION_METHODS.find(
                (x: any) =>
                  x.value === lineItem?.fixedAssetGroup?.depreciationMethod
              )
            : '',
          depreciationRate: lineItem?.fixedAssetGroup?.depreciationRate
        });
      }
      setAssetDetails([...details]);
    }
  }, [lineItem]);

  useEffect(() => {
    updateConfig();
    const rowData = Utility.isEmpty(assetDetails) ? [] : assetDetails;
    const passingData = {
      columnData: columnConfig,
      rowData,
      filter: [],
      originalData: rowData
    };

    setgridData(passingData);
  }, [lineItem, assetDetails]);

  const mapPurchaseTax = (code: any) => {
    let x = purchaseTaxes.filter((res: any) => res.code === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };

  const calculateInitialLineLevelTotal = (detail: any, quantity?: any) => {
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = mapPurchaseTax(detail.taxCode)?.percent || 0;
      let discount =
        Utility.roundOffToTenantDecimalScale(detail?.discount / quantity) || 0;
      const priceAfterDiscount = price - discount;
      const total =
        priceAfterDiscount + priceAfterDiscount * (taxPercent / 100);
      return Utility.roundOffToTenantDecimalScale(total);
    } else {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      let discount =
        Utility.roundOffToTenantDecimalScale(detail?.discount / quantity) || 0;
      const priceAfterDiscount = price - discount;
      //   const total = priceAfterDiscount;
      return Number(Utility.roundOffToTenantDecimalScale(priceAfterDiscount));
    }
  };

  const calculateLineLevelTotalForRecord = (detail: any) => {
    const price = detail?.purchasePrice || detail?.unitPrice || 0;
    const taxPercent = mapPurchaseTax(detail.taxCode)?.percent || 0;
    let discount = detail?.discount || 0;
    const priceAfterDiscount = price - discount;
    const total = priceAfterDiscount + priceAfterDiscount * (taxPercent / 100);
    return Utility.roundOffToTenantDecimalScale(total);
  };
  const calculateLineLevelTotal = (detail: any) => {
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = detail?.tax?.percent || 0;
      let discount = detail?.discount || 0;
      const priceAfterDiscount = price - discount;
      const total =
        priceAfterDiscount + priceAfterDiscount * (taxPercent / 100);
      return Utility.roundOffToTenantDecimalScale(total);
    } else {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = detail?.tax?.percent || 0;
      let discount = detail?.discount || 0;
      const priceAfterDiscount = price - discount;
      return Number(Utility.roundOffToTenantDecimalScale(priceAfterDiscount));
    }
  };

  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'name':
          break;
        case 'description':
          break;
        case 'purchaseDate':
          break;
        case 'purchasePrice':
          break;
        case 'discount':
          break;
        case 'tax':
          let taxData = purchaseTaxes;
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                documentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                documentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                documentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                props.documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(data, props.documentType);
          break;
        case 'serialNumber':
          break;
        case 'warehouse':
          conf.dropdownConfig.data = warehousesData?.content || [];
          conf.formatter = (obj: any) => {
            return obj?.value?.name;
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (searchValue: any) => {
            WarehouseService.apiConfig = {
              Limit: 25,
              Page: 0,
              SortDir: 'desc',
              Sort: 'code',
              SKIP_REQUEST_INTERCEPTOR: true,
              SearchTerm: searchValue
            };
            return WarehouseService.getWarehouseEndPoint();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) => {
            return data?.content;
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                className="text-align-left parent-width white-space-nowrap"
                text={obj?.name}
              />
            );
          };
          break;
        case 'profitLossAccountCode':
          let data = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(data);
          let structuredData = ConfigUtility.structuredAccountsArr;
          conf.dropdownConfig.data = structuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const query = 'status=ACTIVE';
            return DocumentConfigManager.getAccountURL(search, query);
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            let filtered = response?.content?.filter(
              (acc: any) => acc.status === 'ACTIVE'
            );

            if (Utility.isEmpty(filtered)) {
              return Utility.isEmpty(accountsData?.content)
                ? []
                : accountsData?.content?.filter(
                    (item: any) => item.status === 'ACTIVE'
                  );
            }
            return filtered;
          };
          break;
        case 'salesIncomeAccountCode':
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          conf.dropdownConfig.data = Utility.isEmpty(accountsData?.content)
            ? []
            : accountsData?.content?.filter(
                (item: any) => item.accountGroup === 'Expenses'
              );
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const query = 'status=ACTIVE&accountGroup=Expenses';
            return DocumentConfigManager.getAccountURL(search, query);
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            let filtered = response?.content?.filter(
              (acc: any) =>
                acc.status === 'ACTIVE' && acc.accountGroup === 'Expenses'
            );

            if (Utility.isEmpty(filtered)) {
              return Utility.isEmpty(accountsData?.content)
                ? []
                : accountsData?.content?.filter(
                    (item: any) => item.accountGroup === 'Expenses'
                  );
            }
            return filtered;
          };
          break;
        case 'depreciationStartDate':
          break;
        case 'depreciationThreshold':
          break;
        case 'residualValue':
          break;
        case 'depreciationMethod':
          break;
        case 'depreciationConvention':
          break;
        case 'depreciationRate':
          break;
        case 'warrantyStartDate':
          break;
        case 'warrantyEndDate':
          break;

        default:
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };
  const isBeforeBillsDate = (fieldDate: any) => {
    let documentDate = props.documentDate;
    if (fieldDate < documentDate) {
      return true;
    }
    return false;
  };
  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    // callback: any,
    fieldText: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `${fieldText} should not be less than back date : ${formatedDate}.`
          );
          return false;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        return true;
        // setBooksDocument((prevState: any) => {
        //   return {
        //     ...prevState,
        //     isDocumentTouched: true
        //   };
        // });
      } else {
        return false;
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      return true;
      //   return newDate
      //   setBooksDocument((prevState: any) => {
      //     return {
      //       ...prevState,
      //       isDocumentTouched: true
      //     };
      //   });
    }
  };
  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    fieldText: any,
    // callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        showAlert(
          'Invalid Date',
          `${fieldText} should not before financial year close date`
        );
        return false;
      }
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          //   setDocumentDate,
          fieldText,
          `${fieldText} should be in active date range`
        );
        return false;
      }
      return true;
      //   setBooksDocument((prevState: any) => {
      //     return {
      //       ...prevState,
      //       isDocumentTouched: true
      //     };
      //   });
    } else {
      return false;
      // showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let rows: any = [...assetDetails];
    let selectedRow = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];

    selectedRow.invalidFields = selectedRow.invalidFields
      ? [...selectedRow.invalidFields].filter((field) => field !== columnKey)
      : [];

    switch (columnKey) {
      case 'purchaseDate':
        let isValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Purchase Date',
          'Error',
          false
        );
        if (!isValid) {
          showAlert(
            'Error',
            'Purchase Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
          // return
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Purchase Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            rows[rowIndex]['installationDate'] = dataToUpdate;
            rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
            rows[rowIndex]['warrantyStartDate'] = dataToUpdate;
          }
        }

        break;
      case 'installationDate':
        let isInstallValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Installation Date',
          'Error',
          false
        );
        if (!isInstallValid) {
          showAlert(
            'Error',
            'Installation Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Installation Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
          }
        }
        break;
      case 'depreciationStartDate':
        let isDepreciationStartDateValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Depreciation Date',
          'Error',
          false
        );
        if (!isDepreciationStartDateValid) {
          showAlert(
            'Error',
            'Depreciation Start Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (dataToUpdate < rows[rowIndex]['installationDate']) {
            showAlert(
              'Error',
              'Depreciation Start Date cannot be before installation date.'
            );
            rows[rowIndex][columnKey] = null;
          } else {
            if (isBeforeBillsDate(new Date(dataToUpdate))) {
              showAlert(
                'Error',
                'Depreciation Start Date cannot be before Bill date.'
              );
              rows[rowIndex][columnKey] = null;
            } else {
              rows[rowIndex][columnKey] = dataToUpdate;
              rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
            }
          }
        }
        break;
      case 'warrantyStartDate':
        if (dataToUpdate < rows[rowIndex]['installationDate']) {
          showAlert(
            'Error',
            'Warranty Start Date cannot be before installation date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert(
              'Error',
              'Warranty Start Date cannot be before Bill date.'
            );
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            // rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
          }
        }
        break;
      case 'warrantyEndDate':
        if (dataToUpdate < rows[rowIndex]['warrantyStartDate']) {
          showAlert(
            'Error',
            'Warranty end date cannot be before warranty start date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Warranty End Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
          }
          rows[rowIndex][columnKey] = dataToUpdate;
        }
        break;
      case 'depreciationMethod':
        rows[rowIndex].nonEditableColumns = [];
        const method = dataToUpdate.value;
        if (
          method === 'NO_DEPRECIATION' ||
          method === 'INSTANT_ASSET_WRITE_OFF'
        ) {
          if (method === 'NO_DEPRECIATION') {
            rows[rowIndex]?.nonEditableColumns?.push('depreciationThreshold');
            rows[rowIndex]?.nonEditableColumns?.push('residualValue');
          }
          rows[rowIndex]?.nonEditableColumns?.push('depreciationConvention');
          rows[rowIndex]?.nonEditableColumns?.push('depreciationRate');
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;

      case 'purchasePrice':
      case 'discount':
        rows[rowIndex][columnKey] =
          Utility.roundOffToTenantDecimalScale(dataToUpdate);
        if (
          dataToUpdate < 0 ||
          (dataToUpdate as number) > rows[rowIndex]['purchasePrice']
        ) {
          selectedRow.invalidFields.push('discount');
        } else {
          const total = calculateLineLevelTotal(rows[rowIndex]);
          rows[rowIndex]['totalAmount'] =
            Utility.roundOffToTenantDecimalScale(total);
        }
        break;
      case 'tax':
        rows[rowIndex][columnKey] = dataToUpdate;
        const total = calculateLineLevelTotal(rows[rowIndex]);
        rows[rowIndex]['totalAmount'] = total;
        break;

      default:
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
    }

    setAssetDetails(rows);
  };

  const validateForm = () => {
    let error = false;
    let calculatedTotal = assetDetails
      .map((res: any) => {
        return res.totalAmount;
      })
      .reduce((a: any, b: any) => {
        return a + b;
      });

    for (let i = 0; i < assetDetails.length; i++) {
      if (Utility.isEmpty(assetDetails[i].name)) {
        showAlert('Error!', 'Missing Asset Name');
        error = true;
        return false;
      }

      if (Utility.isEmpty(assetDetails[i].warehouse)) {
        showAlert('Error!', 'Missing Warehouse');
        error = true;
        return false;
      }
      if (
        Utility.isEmpty(assetDetails[i].depreciationConvention) &&
        !isAssetPresent
      ) {
        showAlert('Error!', 'Missing depreciationConvention');
        error = true;
        return false;
      }
      if (
        Utility.isEmpty(assetDetails[i].depreciationMethod) &&
        !isAssetPresent
      ) {
        showAlert('Error!', 'Missing depreciationMethod');
        error = true;
        return false;
      }
      if (
        Utility.isEmpty(String(assetDetails[i].depreciationRate)) &&
        !isAssetPresent
      ) {
        showAlert('Error!', 'Missing depreciationRate');
        error = true;
        return false;
      }

      if (
        assetDetails[i].purchaseDate === null ||
        assetDetails[i].purchaseDate === undefined ||
        assetDetails[i].purchaseDate === ''
      ) {
        if (Utility.isEmpty(assetDetails[i].purchaseDate)) {
          showAlert('Error!', 'Missing Purchase Date');
          error = true;
          return false;
        }
      }

      if (
        assetDetails[i].purchasePrice <= 0 ||
        assetDetails[i].purchasePrice === null ||
        assetDetails[i].purchasePrice === undefined
      ) {
        showAlert('Error!', 'Missing Purchase Price');
        error = true;
        return false;
      }
      //   if (Utility.isEmpty(assetDetails[i].serialNumber)) {
      //     showAlert('Error!', 'Missing Serial Number');
      //     error = true;
      //     return false;
      //   }
      //   if (Utility.isEmpty(assetDetails[i].warehouse)) {
      //     showAlert('Error!', 'Missing Warehouse');
      //     error = true;
      //     return false;
      //   }
      if (Utility.isEmpty(assetDetails[i].profitLossAccountCode)) {
        showAlert('Error!', 'Missing P/L Account');
        error = true;
        return false;
      }
      if (Utility.isEmpty(assetDetails[i].salesIncomeAccountCode)) {
        showAlert('Error!', 'Missing Income Account');
        error = true;
        return false;
      }
      //   if (Utility.isEmpty(assetDetails[i].depreciationStartDate)) {
      //     showAlert('Error!', 'Missing Depreciation Start Date');
      //     error = true;
      //     return false;
      //   }
      //   if (Utility.isEmpty(assetDetails[i].depreciationMethod)) {
      //     showAlert('Error!', 'Missing Depreciation Method');
      //     error = true;
      //     return false;
      //   }
    }

    if (!error) {
      if (tenantInfo.country === COUNTRY_CODES.US) {
        if (
          Utility.roundOffToTenantDecimalScale(Number(calculatedTotal)) !==
          Utility.roundOffToTenantDecimalScale(Number(lineItem.totalAmount))
        ) {
          showAlert(
            'Error!',
            'Total Amount does not match with Asset Group amount'
          );
          error = true;
        }
      } else {
        if (
          Utility.roundOffToTenantDecimalScale(Number(calculatedTotal)) !==
          Utility.roundOffToTenantDecimalScale(Number(lineItem.amount))
        ) {
          showAlert(
            'Error!',
            'Total Amount does not match with Asset Group amount'
          );
          error = true;
        }
      }
    }

    return !error;
  };

  const saveAsset = () => {
    let payload: any[] = [];
    selectedRows.forEach((row: any) => {
      let payloadRow: any = {
        ...row,
        depreciationStartDate: DateFormatService.getDateStrFromDate(
          new Date(row.depreciationStartDate),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        installationDate: DateFormatService.getDateStrFromDate(
          new Date(row.installationDate),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        purchaseDate: DateFormatService.getDateStrFromDate(
          new Date(row.purchaseDate),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        warrantyStartDate: DateFormatService.getDateStrFromDate(
          new Date(row.warrantyStartDate),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        warrantyEndDate: row.warrantyEndDate
          ? DateFormatService.getDateStrFromDate(
              new Date(row.warrantyEndDate),
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : '',
        documentType: props.documentType,
        documentCode: props.documentCode,
        documentItemCode:
          props.documentType === DOC_TYPE.ORDER
            ? props.lineItemData?.rowData?.purchaseOrderItemCode
            : props.lineItemData?.rowData?.invoiceItemCode,
        depreciationConvention: row?.depreciationConvention?.value,
        depreciationMethod: row?.depreciationMethod?.value,
        profitLossAccountCode: row?.profitLossAccountCode?.code,
        salesIncomeAccountCode: row?.salesIncomeAccountCode?.code,
        warehouse: row.warehouse.code
      };
      payload.push(payloadRow);
    });

    if (props.documentType === DOC_TYPE.ORDER) {
      FixedAssetService.saveAsset(payload)
        .then((res: any) => {
          if (res.success.length > 0) {
            setSelectedRows([...res.success]);
            props.onSave(props.lineItemData?.rowIndex, res.success);
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else {
      FixedAssetService.saveAssetForBill(payload, props.documentCode)
        .then((res: any) => {
          if (res.success.length > 0) {
            setSelectedRows([...res.success]);
            props.onSave(props.lineItemData?.rowIndex, res.success);
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Fixed Asset Details" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          {!props.isReadOnly && (
            <DKButton
              title="Save"
              className={
                selectedRows.length
                  ? 'bg-button text-white'
                  : 'bg-gray text-white'
              }
              onClick={() => {
                if (props.onSave && selectedRows.length && validateForm()) {
                  if (!isAssetPresent) {
                    saveAsset();
                  } else {
                    props.onSave(props.lineItemData?.rowIndex, selectedRows);
                  }
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const onAllRowSelect = (data: any = null) => {
    if (!data.selected) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...assetDetails]);
    }
  };

  // Datagrid and columns
  const getDataGrid = () => (
    <DKDataGrid
      headerButtons={[]}
      tableName={'RGASSET'}
      needBoldTheme={true}
      needBorder={true}
      needShadow={false}
      allowRowEdit={true}
      allowColumnEdit={false}
      allowRowAdd={false}
      allowColumnAdd={false}
      allowColumnDelete={false}
      allowColumnShift={false}
      allowSearch={false}
      allowFilter={false}
      onRowUpdate={onRowUpdate}
      allowBulkOperation={!props.isReadOnly}
      allowDataExport={false}
      rows={assetDetails}
      columns={columnConfig}
      allowColumnSort={false}
      dateFormat={convertBooksDateFormatToUILibraryFormat(
        tenantDetails.dateFormat
      )}
      isAllRowSelected={selectedRows.length === assetDetails.length}
      onAllRowSelect={(data: any) => onAllRowSelect(data)}
      onRowSelect={(data: any) => {
        const updatedRows = [...selectedRows];
        const index = updatedRows.findIndex(
          (item) => item.idRow === data.rowData.idRow
        );
        if (index === -1) {
          updatedRows.push(data.rowData);
        } else {
          updatedRows.splice(index, 1);
        }
        setSelectedRows([...updatedRows]);
      }}
    />
  );

  const getBody = () => {
    return (
      <div className="column parent-width parent-height p-r flex-1 overflow-y-auto">
        {getDataGrid()}
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          minWidth: '80%',
          minHeight: 300,
          maxHeight: '90%',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}

        {getBody()}
      </div>
    </div>
  );
}
