import React, { Component } from "react";
import "../../index.css";
import { EditorInfoType, FONT_SIZE, PAGE_PADDING } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import AppManager from "../../Manager/AppManager";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getIsDocBuilderApp } from '../../Utilities/Utility';
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    getHeaderView() {
        return (
            <div className="RowDiv"
                style={{
                    backgroundColor: this.state.data.themeColor,
                    paddingLeft: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                    paddingRight: getVW(`${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true),
                    marginLeft: getIsDocBuilderApp() ? getVW(`-${Utility.convertToPrintScale(Number(PAGE_PADDING.replace('vw', '')), this.state.data.printScale)}vw`, true) : 0,
                    marginTop: undefined,
                    width: "100%",
                    minHeight: getVW(`${Utility.convertToPrintScale(10, this.state.data.printScale)}vw`, true)
                }}>
                <div className="RowDiv" style={{ width: '100%' }}>
                    <text
                        style={{
                            fontWeight: "bold",
                            textAlign: "right",
                            color: 'white',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xLarge }, this.state.data.printScale)
                        }}
                    >
                        {getLocalisedText(this.state.data.documentType)}
                    </text>
                </div>
                <div className="ColumnDiv" style={{ color: 'white', width: '100%', alignItems: 'flex-end', height: '70%', overflow: 'hidden', justifyContent: 'center' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            marginTop: getVW(6),
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{
                            __html: getLocalisedText(Utility.getCompanyAddress(this.state.data))
                        }}>
                    </div>}
                </div>
            </div>
        )
    }

    addBillToView() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    width: '60%',
                }}
            >
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    "#000000",
                    false,
                    '#000000',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
            </div>
        )
    }

    addInvoiceNumberAndDOIView() {
        return (
            <div className="ColumnDiv" style={{ width: '100%', alignItems: 'flex-end', textAlign: 'end' }}>
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return <>
                {index !== 0 && ComponentManager.addVerticalSpace(15)}
                {ComponentManager.getTitleAndValueColumn(
                    element.label,
                    value,
                    'gray',
                    "black",
                    Utility.getIsArabicLang() ? 'flex-end' : 'flex-start',
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
            </>

        });
    }


    addBillToNDetailsView() {
        return (
            <div className="RowDiv">
                <div className="RowDiv" style={{ width: '100%', alignItems: 'flex-start' }}>
                    {this.addBillToView()}
                    {this.addInvoiceNumberAndDOIView()}
                </div>
                {
                    Utility.getIsContainsTotalSection(this.state.data) &&
                    <div className="RowDiv" style={{ width: '50%', alignItems: 'center' }}>
                        {ComponentManager.getTitleAndValueColumn(
                            AppManager.getTypeTotalString(getLocalisedText(this.state.data.documentType)),
                            Utility.getTotalValue(this.state.data),
                            'gray',
                            this.state.data.themeColor,
                            "flex-end",
                            FONT_SIZE.regular,
                            FONT_SIZE.xLarge,
                            'regular',
                            0,
                            Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                            this.state.data.printScale
                        )}
                    </div>
                }
            </div>
        )
    }

   getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '35%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }
    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {/* section 1 */}
                    {this.getHeaderView()}
                    {ComponentManager.addVerticalSpace(Utility.convertToPrintScale(40, this.state.data.printScale))}
                    {this.addBillToNDetailsView()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(10)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {/* section 3 */}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(25)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    <div style={{ backgroundColor: this.state.data.themeColor, height: getVW(2), width: '100%' }}></div>
                    <div className="ColumnDiv" style={{ width: '100%' }}>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                        {this.getEditableDocumentTable()}
                    </div>
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {this.getTransactionDocumentTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ bottom: 0, width: '100%' }}>
                        {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                                this.state.data.termsAndCondition,
                                "100%",
                                this.state.data.themeColor,
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                            )}
                        {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(20)}
                        {(this.state.data.showNotes && this.state.data.notes) &&
                            ComponentManager.getTermsAndConditionOrNotesSection(
                                Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                                this.state.data.notes,
                                "100%",
                                "gray",
                                undefined,
                                undefined,
                                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                                this.state.data.printScale,
                                Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                            )}
                    </div>
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = "transparent";
        style.headerTextColor = this.state.data.themeColor;
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = 'transparent';
        style.footerTextColor = "black";
        style.footerBorderColor = "transparent";
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    <div style={{ backgroundColor: data.themeColor, height: getVW(2) }} />
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }

    getTransactionDocumentTable(tableStyle, data) {
        return (
            <div id={HTMLTag.TRANSACTION_DOCUMENT_TABLE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "65%" }}>
                    {ComponentManager.getTransactionDocumentTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
