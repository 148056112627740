import {
  eachDayOfInterval,
  endOfWeek,
  isAfter,
  isSameMonth,
  isSameWeek,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import { BOOKS_DATE_FORMAT, DAY_OF_WEEK } from '../Constants/Constant';
import DateFormatService from './DateFormat';
export enum DATE_COMPARE_WITH {
  DAY,
  Week,
  Month,
  Year,
  Time,
  HOUR,
  SECOND
}

export enum DATE_COMPARE_DIRECTION {
  BEFORE,
  AFTER
}

export default abstract class CalendarDateService extends DateFormatService {
  public static tenantDateFormat = BOOKS_DATE_FORMAT['DD-MM-YYYY'];

  public static getAllDatesInARange(startDt: Date, endDt: Date) {
    let dates: Date[] = [];
    try {
      dates = eachDayOfInterval({ start: startDt, end: endDt });
    } catch (err) {
      console.log(err);
      console.log('FAILED WITH DATE RANGE');
      console.log(startDt, endDt);
    }
    return dates;
  }

  public static getFirstDaysOfWeeksInMonth(date: Date) {
    const firstDayOfMonth = startOfMonth(date);
    const firstDayOfFirstWeek = startOfWeek(firstDayOfMonth, {
      weekStartsOn: 0
    });
    const firstDaysOfWeeks = [];

    let currentWeekStartDate = firstDayOfFirstWeek;
    while (currentWeekStartDate.getMonth() === firstDayOfMonth.getMonth()) {
      firstDaysOfWeeks.push(new Date(currentWeekStartDate));
      currentWeekStartDate.setDate(currentWeekStartDate.getDate() + 7);
    }
    return firstDaysOfWeeks;
  }

  public static getDatesInCurrentMonth(
    startDt: Date,
    endDt: Date,
    currentDate: Date
  ) {
    const datesInCurrentMonth = [];
    try {
      let dates = this.getAllDatesInARange(startDt, endDt);

      for (const date of dates) {
        if (isSameMonth(date, currentDate)) {
          datesInCurrentMonth.push(date);
        }
      }
    } catch (erro) {
      console.log(erro);
    }
    return datesInCurrentMonth;
  }

  public static getDatesInCurrentWeek(
    startDt: Date,
    endDt: Date,
    currentDate: Date
  ) {
    const startOfWeekDate = startOfWeek(currentDate);
    let datesInRange = this.getAllDatesInARange(startDt, endDt);

    const datesInCurrentWeek = [];

    for (const date of datesInRange) {
      if (isSameWeek(date, startOfWeekDate)) {
        datesInCurrentWeek.push(date);
      }
    }

    return datesInCurrentWeek;
  }
  public static getDatesByDay(dates: Date[], day: DAY_OF_WEEK): Date[][] {
    const weeks: Date[][] = [];
    let currentWeek: Date[] = [];

    for (const date of dates) {
      if (date.getDay() === day) {
        if (currentWeek.length > 0) {
          weeks.push(currentWeek);
          currentWeek = [];
        }
      }
      currentWeek.push(date);
    }

    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }

    return weeks;
  }

  /**
   * Gets the day of the month for a given date.
   *
   * @param {Date} date The date for which to get the day of the month.
   * @returns {number} The day of the month.
   * @example
   * const today = new Date();
   * const dayOfMonth = getLastDayOfTheMonth(today);
   * // Assuming today is March 31st, 2024
   * // dayOfMonth will be 31
   */

  static getLastDayOfTheMonth = (date: Date): number => {
    let d = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return d;
  };

  /**
   * Generates an array of numbers from 1 to the specified end value (inclusive).
   *
   * @param {number} end The end value of the range.
   * @returns {number[]} An array containing numbers from 1 to the end value.
   * @example
   * //returns [1, 2];
   * //for numberRange(2)
   */

  static numberRange = (end: number): number[] => {
    const { result } = Array.from({ length: end }).reduce(
      ({ result, current }) => ({
        result: [...result, current],
        current: current + 1
      }),
      { result: [], current: 1 }
    );
    return result;
  };

  static isSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  static getMonthYear = (date: Date) => {
    const d = date.toDateString().split(' ');
    return `${d[1]} ${d[3]}`;
  };

  static nextMonth = (date: Date, cb: Function) => {
    const mon = date.getMonth();
    if (mon < 11) {
      date.setMonth(mon + 1);
    } else {
      date.setMonth(0);
      date.setFullYear(date.getFullYear() + 1);
    }
    cb(new Date(date));
  };
  static prevMonth = (date: Date, cb: Function) => {
    const mon = date.getMonth();
    if (mon > 0) {
      date.setMonth(mon - 1);
    } else {
      date.setMonth(11);
      date.setFullYear(date.getFullYear() - 1);
    }
    cb(new Date(date));
  };
  /**
   * Generates an array of objects representing the days of the week starting from Sunday.
   *
   * @param {Date} date The starting date from which to generate the days of the week.
   * @returns {Array<{ day: number, dateObj: Date }>} An array of objects containing the day number and date object for each day of the week.
   */

  static getDaysOfWeek = (
    date: Date
  ): Array<{ day: number; dateObj: Date }> => {
    const daysOfWeek = [];
    const currentDate = new Date(date);
    let dayOfWeek = currentDate.getDay();

    currentDate.setDate(currentDate.getDate() - dayOfWeek);

    for (let i = 0; i < 7; i++) {
      const day = currentDate.getDate();
      const dateObj = new Date(currentDate);
      daysOfWeek.push({ day, dateObj });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysOfWeek;
  };

  /**
   * Get the date and weekday for a given Date object.
   * @param {Date} dateObj The Date object for which to get the date and weekday.
   * @returns {{ date: number, weekday: string, dateObj: Date }} An object containing the date (day of the month) and the weekday name.
   */
  static getDateAndWeekday(dateObj: Date): {
    date: number;
    weekday: string;
    dateObj: Date;
  } {
    const date = dateObj.getDate();
    const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'short' });
    return { date, weekday, dateObj };
  }

  /**
   * Get a list of all months in a year based on a given date.
   * @param {Date} currentDate The date for which to generate the list of months.
   * @returns {Array<Date>} An array of Date objects representing the first day of each month in the year.
   */
  static getAllMonthsInYear(currentDate: Date): Array<Date> {
    const year = currentDate.getFullYear();
    const months = [];

    for (let month = 0; month < 12; month++) {
      const firstDayOfMonth = new Date(year, month, 1);
      months.push(firstDayOfMonth);
    }

    return months;
  }

  /**
   * Converts a Date object to a time string with AM/PM format.
   * @param dateObj The Date object to convert.
   * @returns The time string in AM/PM format (e.g., "12:30 PM").
   */
  static getTimeWithAMPM(dateObj: Date): string {
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${ampm.toLowerCase()}`;
  }

  /**
   * Checks if a given date is valid within a specified range.
   * @param {Date} date The date object to validate.
   * @returns {boolean} Returns true if the date is valid within the specified range, otherwise false.
   */
  static isValidDate(date: Date): boolean {
    if (!(date instanceof Date && !isNaN(date.getTime()))) {
      return false;
    }
    return true;
  }

  static action = {
    addDays(date: Date, days: number): Date {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + days);
      return newDate;
    },
    minusDays(date: Date, days: number): Date {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() - days);
      return newDate;
    },
    addWeeks(date: Date, weeks: number): Date {
      return this.addDays(date, weeks * 7);
    },
    addMonths(date: Date, months: number): Date {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + months);
      return newDate;
    },
    addYears(date: Date, years: number): Date {
      const newDate = new Date(date);
      newDate.setFullYear(date.getFullYear() + years);
      return newDate;
    },

    /**
     * Compares two Date objects and returns the result based on the specified comparison criteria.
     * @param {Date} date1 The first Date object to compare.
     * @param {Date} date2 The second Date object to compare.
     * @returns {number} Returns:
     * - 0 if the dates are the same and checkSame is true,
     * - -1 if date1 is smaller than date2 or checkSame is false,
     * - 1 if date1 is bigger than date2.
     */
    compareDates(date1: Date, date2: Date): number {
      const diff = date1.getTime() - date2.getTime();
      if (diff === 0) {
        return 0;
      } else if (diff < 0) {
        return -1;
      } else {
        return 1;
      }
    },

    compareDatesV2(
      date1: Date,
      date2: Date,
      comparisonUnit: DATE_COMPARE_WITH,
      direction: DATE_COMPARE_DIRECTION,
      equalWillBeFine: boolean
    ): boolean {
      let comparisonResult: boolean = false;
      switch (comparisonUnit) {
        case DATE_COMPARE_WITH.DAY:
          if (equalWillBeFine) {
            comparisonResult =
              new Date(
                date1.getFullYear(),
                date1.getMonth(),
                date1.getDate()
              ) <=
              new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
          } else {
            comparisonResult =
              new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()) <
              new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
          }
          break;
        case DATE_COMPARE_WITH.Week:
          const weekStart1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate() - date1.getDay()
          );
          const weekStart2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate() - date2.getDay()
          );

          if (equalWillBeFine) {
            comparisonResult = weekStart1 <= weekStart2;
          } else {
            comparisonResult = weekStart1 < weekStart2;
          }
          break;
        case DATE_COMPARE_WITH.Month:
          const monthStart1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            1
          );
          const monthStart2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            1
          );
          comparisonResult = equalWillBeFine
            ? monthStart1.getTime() === monthStart2.getTime()
            : monthStart1.getTime() !== monthStart2.getTime();

          if (equalWillBeFine) {
            comparisonResult = monthStart1 <= monthStart2;
          } else {
            comparisonResult = monthStart1 < monthStart2;
          }
          break;
        case DATE_COMPARE_WITH.Year:
          const yearStart1 = new Date(date1.getFullYear(), 0, 1);
          const yearStart2 = new Date(date2.getFullYear(), 0, 1);
          comparisonResult = equalWillBeFine
            ? yearStart1.getTime() === yearStart2.getTime()
            : yearStart1.getTime() !== yearStart2.getTime();

          if (equalWillBeFine) {
            comparisonResult = yearStart1 <= yearStart2;
          } else {
            comparisonResult = yearStart1 < yearStart2;
          }
          break;
        case DATE_COMPARE_WITH.Time:
          comparisonResult = equalWillBeFine
            ? date1.getTime() === date2.getTime()
            : date1.getTime() !== date2.getTime();

          if (equalWillBeFine) {
            comparisonResult = date1.getTime() <= date2.getTime();
          } else {
            comparisonResult = date1.getTime() < date2.getTime();
          }
          break;
        case DATE_COMPARE_WITH.HOUR:
          const hourStart1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours()
          );
          const hourStart2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours()
          );
          comparisonResult = equalWillBeFine
            ? hourStart1.getTime() === hourStart2.getTime()
            : hourStart1.getTime() !== hourStart2.getTime();

          if (equalWillBeFine) {
            comparisonResult = hourStart1 <= hourStart2;
          } else {
            comparisonResult = hourStart1 < hourStart2;
          }
          break;
        case DATE_COMPARE_WITH.SECOND:
          const secondStart1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
          );
          const secondStart2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
          );
          if (equalWillBeFine) {
            comparisonResult = secondStart1 <= secondStart2;
          } else {
            comparisonResult = secondStart1 < secondStart2;
          }
          break;
        default:
          console.error('Invalid comparison unit');
      }

      if (direction === DATE_COMPARE_DIRECTION.BEFORE) {
        return comparisonResult;
      } else if (direction === DATE_COMPARE_DIRECTION.AFTER) {
        return !comparisonResult;
      } else {
        console.error('Invalid comparison direction');
        return comparisonResult;
      }
    }
  };

  static getDayDifference(bigger: Date, smaller: Date) {
    return bigger.getDate() - smaller.getDate();
  }

  static getDateFromISOStr(isoStr: string): Date {
    return parseISO(isoStr);
  }

  /**
   * Check if a given date falls on a specific day of the week.
   * @param date The date to check.
   * @param day The day of the week to compare against.
   * @returns True if the date falls on the specified day of the week, false otherwise.
   */
  static isDayOfWeek(date: Date, day: DAY_OF_WEEK): boolean {
    return this.getDayOfWeek(date) === day;
  }
  /**
   * Get the day of the week for a given date.
   * @param date The date to check.
   * @returns The day of the week as a DayOfWeek enum value.
   */
  static getDayOfWeek(date: Date): DAY_OF_WEEK {
    return date.getDay();
  }

  /**
   * Get the days within a date range that match a specific day status.
   * @param startDate The start date of the range.
   * @param endDate The end date of the range.
   * @param statusDay The day of the week to check for status.
   * @returns An array of dates within the range that match the status day.
   */
  static getStatusDaysInRange(
    startDate: Date,
    endDate: Date,
    statusDay: DAY_OF_WEEK
  ): Date[] {
    const statusDays: Date[] = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      if (this.isDayOfWeek(currentDate, statusDay)) {
        statusDays.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return statusDays;
  }

  static _isAfter(date1: Date, date2: Date): boolean {
    return isAfter(startOfDay(date1), startOfDay(date2));
  }

  static _isBefore(date1: Date, date2: Date): boolean {
    return !isAfter(startOfDay(date1), startOfDay(date2));
  }
}
