import { DKLabel, Toggle } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  showArchivedFlag,
  updateShowArchivedFlag
} from '../Redux/Slices/MRP/WorkOrderSlice';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';

interface DocumentGridSettingsProps {
  showTotalInBaseCurrency: boolean;
  onShowTotalInBaseCurrencyChange: (value: boolean) => void;
  onClose: () => void;
  title?: string;
  showArchiveOption?: boolean;
}

export function MainDocumentGridSettings(props: DocumentGridSettingsProps) {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [toggleOn, setToggleOn] = useState(props.showTotalInBaseCurrency);
  const showArchived = useAppSelector(showArchivedFlag);
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('mousedown', detectOutsideClick);
    return () => {
      document.removeEventListener('mousedown', detectOutsideClick);
    };
  }, []);

  const detectOutsideClick = (event: any) => {
    if (
      !(
        event.target?.parentNode?.className.includes('settings-btn') ||
        event.target?.className.includes('settings-btn')
      ) &&
      !popupRef.current?.contains(event.target as Node)
    ) {
      props.onClose();
    }
  };

  return (
    <div
      ref={popupRef}
      className="shadow-l bg-white p-h-m p-v-r border-radius-s border-m dk-filter-popup z-index-2"
      style={{
        width: 350,
        maxWidth: '90%',
        top: 40
      }}
    >
      <div className="column parent-witdh gap-2">
        <div className="row justify-content-between">
          <DKLabel
            className="column mr-s"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
            text={
              props?.title ? props?.title : 'Show total amount in base currency'
            }
          />
          <Toggle
            className="flex flex-col ml-2 box-content"
            isSelected={toggleOn}
            color="bg-button"
            isOn={toggleOn}
            onChange={() => {
              props.onShowTotalInBaseCurrencyChange(!toggleOn);
              setToggleOn(!toggleOn);
            }}
          />
        </div>
        {props.showArchiveOption && (
          <div className="row justify-content-between">
            <DKLabel
              className="column mr-s"
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
              text={'Show Archived Work Orders'}
            />
            <Toggle
              className="flex flex-col ml-2 box-content"
              isSelected={showArchived}
              color="bg-button"
              isOn={showArchived}
              onChange={() => {
                dispatch(updateShowArchivedFlag(!showArchived));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
