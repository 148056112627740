import React, { Component } from "react";
import "../../../index.css";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import NumberUtility from "../../../Utilities/NumberUtility";
import { getPageHeight } from "../../../Utilities/Utility";
import PayslipFieldUtility from "../PayslipFieldUtility";
import PayslipTableUtility, { PayslipTableType } from "../PayslipTableUtility";
import PayslipUtility from "../PayslipUtility";
import "./../css/Templates.css";

export default class SGPayslipTemplate3 extends Component {
    currency = null

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.payslip,
            isFromERP: true,
            globalData: this.props.data,
        };

        this.currency = NumberUtility.getCurrency(this.props.data)
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data.payslip,
            isFromERP: true,
            globalData: nextProp.data,
        });
        this.currency = NumberUtility.getCurrency(nextProp.data)
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight() }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: 30 }}>
                    {this.renderHeader()}
                    {PayslipFieldUtility.renderPayslipFields(this.state.globalData.templateId, this.state.globalData, this.state.data, this.currency, this.state.isFromERP)}
                    {this.renderTable()}
                    {/* {this.renderEmployeeEarning('employee_earnings')}
                    {this.renderAllowanceAndDeductions()}
                    {this.renderEmployerCPF('employer_cpf')}
                    {this.renderNetSalaryPaid('net_salary_paid')} */}
                    {PayslipUtility.renderPayslipAutoGeneratorSection()}
                </div>
            </div>
        );
    }

    renderHeader() {
        return <>
            <div className='RowDiv'>
                {this.state.globalData.showCompanyName &&
                    <div className='ColumnDiv'
                        style={{
                            fontSize: 12,
                            fontWeight: 300,
                            width: '40%',
                        }}>
                        <div className='RowDiv'
                            style={{
                                paddingTop: 10,
                                fontWeight: 700,
                                color: 'rgb(0, 0, 0)',
                                fontSize: 24
                            }}>
                            {getLocalisedText(PayslipUtility.getCompanyName(this.state.data, this.state.isFromERP))}
                        </div>
                    </div>
                }
                {this.state.globalData.showFrom &&
                    <div className='ColumnDiv'
                        style={{
                            fontSize: 12,
                            fontWeight: 300,
                            width: this.state.globalData.showCompanyName ? '60%' : '100%'
                        }}>
                        <div className='RowReverseDiv'
                            style={{
                                color: 'rgb(0, 0, 0)',
                                textAlign: 'right',
                                paddingTop: 22,
                                paddingRight: 2,
                                paddingLeft: 2,
                            }} dangerouslySetInnerHTML={{ __html: getLocalisedText(PayslipUtility.getCompanyAddress(this.state.data, this.state.isFromERP)) }}>
                        </div>
                    </div>
                }
            </div>
            <div style={{
                    backgroundColor: this.state.globalData.themeColor,
                    height: 4,
                    // marginTop: -4),
                }}>
                <div style={{
                    height: 1,
                    paddingTop: 1,
                    paddingBottom: 1,
                }}>
                </div>
            </div>
        </>
    }

    renderTable() {
        //this will be dynamic based on user selection
        var payslipTable = PayslipTableUtility.getPayslipTable(this.state.globalData)
        if (payslipTable.length > 0) {
            var allowances = payslipTable.find(x => x.type === PayslipTableType.allowances)
            var deductions = payslipTable.find(x => x.type === PayslipTableType.deductions)
            var index = 0
            if (allowances !== undefined && deductions !== undefined) {
                if (allowances.isSelected && deductions.isSelected) {
                    index = Math.abs(allowances.index - deductions.index)
                }
            }

            var firstType = undefined
            if (index === 1) {
                payslipTable.forEach(element => {
                    if (element.type === PayslipTableType.allowances || element.type === PayslipTableType.deductions) {
                        if (firstType === undefined) {
                            firstType = element.type
                            element.isCombined = true
                        }
                    }
                });
            }

            var allowances_deductions = []
            if (firstType !== undefined) {
                if (firstType === PayslipTableType.allowances) {
                    allowances_deductions = [allowances, deductions]
                }
                else {
                    allowances_deductions = [deductions, allowances]
                }
            }
            return payslipTable.map(element => {
                if (element.isSelected) {
                    if (allowances_deductions.length > 0) {
                        if (element.type !== allowances_deductions[1].type) {
                            return this.renderTableByType(element, allowances_deductions)
                        }
                    }
                    else {
                        return this.renderTableByType(element, allowances_deductions)
                    }
                }
                return undefined
            });
        }
    }

    renderTableByType(item, items) {
        var div = undefined
        switch (item.type) {
            case PayslipTableType.employeeEarnings:
                div = this.renderEmployeeEarning(item.name)
                break;
            case PayslipTableType.employerCpf:
                div = this.renderEmployerCPF(item.name)
                break;
            case PayslipTableType.netSalaryPaid:
                div = this.renderNetSalaryPaid(item.name)
                break;
            case PayslipTableType.allowances:
                div = this.renderAllowanceOrDeductions(item, true)
                if (item.isCombined !== undefined) {
                    if (item.isCombined) {
                        div = this.renderAllowanceAndDeductions(items)
                    }
                }
                break;
            case PayslipTableType.deductions:
                div = this.renderAllowanceOrDeductions(item, true)
                if (item.isCombined !== undefined) {
                    if (item.isCombined) {
                        div = this.renderAllowanceAndDeductions(items)
                    }
                }
                break;
            default:
                break;
        }

        return div
    }

    renderEmployeeEarning(headerText) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: 'left'
        }
        var headers = ['description', 'rate']

        var data = [
            [getLocalisedText('Basic Salary'), '#BasicSalary#'],
            ['#OvertimeRate#', '#OvertimeAmount#']
        ]

        if (this.state.isFromERP) {
            data = PayslipUtility.getEmployeeEarningTable(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data)
    }

    renderAllowanceAndDeductions(items) {
        return <div className='RowDiv' style={{
            alignItems: 'start'
        }}>
            {items.map((element, index) => {
                return this.renderAllowanceOrDeductions(element, false, index)
            })}
        </div>
    }

    renderAllowanceOrDeductions(item, isSingleDiv, index) {
        var div = undefined
        switch (item.type) {
            case PayslipTableType.allowances:
                div = <div className='RowDiv parent-width'>
                    {this.renderAllowances(item.name, isSingleDiv, index)}
                </div>
                break;
            case PayslipTableType.deductions:
                div = <div className='RowDiv parent-width'>
                    {this.renderDeductions(item.name, isSingleDiv, index)}
                </div>
                break;
            default:
                break;
        }

        return div
    }

    renderAllowances(headerText, isParentDiv = true, index = 0) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: isParentDiv ? 'left' : index === 0 ? 'left' : 'right'
        }
        var headers = ['description', 'rate']

        var data = [
            ['#ComponentName', '#ComponentAmount#']]

        var paddingRight = index === 0 ? 5 : 0
        var paddingLeft = index === 0 ? 0 : 5
        var style = {
            paddingRight: isParentDiv ? 0 : paddingRight,
            paddingLeft: isParentDiv ? 0 : paddingLeft,
        }

        if (this.state.isFromERP) {
            data = PayslipUtility.getAllowanceTable(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data, undefined, undefined, style)
    }

    renderDeductions(headerText, isParentDiv = true, index = 1) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: isParentDiv ? 'left' : index === 0 ? 'left' : 'right'
        }
        var headers = ['description', 'rate']

        var data = [
            ['#ComponentName#', '#ComponentAmount#'],
            ['#LOP#', '#LOPAmount#'],
        ]

        var paddingLeft = index === 0 ? 0 : 5
        var paddingRight = index === 0 ? 5 : 0
        var style = {
            paddingRight: isParentDiv ? 0 : paddingRight,
            paddingLeft: isParentDiv ? 0 : paddingLeft,
        }

        if (this.state.isFromERP) {
            data = PayslipUtility.getDeductionsTable(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data, undefined, undefined, style)
    }

    renderEmployerCPF(headerText) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: 'left'
        }
        var headers = ['description', 'rate']

        var data = [
            ['CPF (Employer)', '#EmployerCPF#'],
            ['SDL', '#SDL#'],
            ['FWL', '#FWL#'],
            ['Total', '#EmployerCPFTotal#'],
        ]

        if (this.state.isFromERP) {
            data = PayslipUtility.getEmployerContribution(this.state.data, this.state.isFromERP, this.currency)
        }

        return PayslipUtility.renderTableContainer(title, headers, data)
    }

    renderNetSalaryPaid(headerText) {
        var title = {
            value: getLocalisedText(headerText),
            textAlign: 'left'
        }
        var headers = ['description', 'rate']

        var data = [
            [getLocalisedText('net_pay'), '#NetPay#'],
        ]

        var style = {
            highlighted: ['#NetPay#']
        }

        if (this.state.isFromERP) {
            data = PayslipUtility.getNetPayTable(this.state.data, this.state.isFromERP, this.currency)
            style = {
                highlighted: [data[0][1]]
            }
        }

        return PayslipUtility.renderTableContainer(title, headers, data, undefined, undefined, style)
    }
}
