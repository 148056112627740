import React, { useState, useEffect } from 'react';
import {
  DKInput,
  INPUT_TYPE,
  DKLabel,
  DKButton,
  showAlert
} from 'deskera-ui-library';
import { INPUT_VIEW_DIRECTION } from '../../../Constants/Constant';
import { CLASS, initialFormState } from './ClassesConstant';
import ClassesService from '../../../Services/Classes';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchCustomFields,
  selectClassesData
} from '../../../Redux/Slices/CommonDataSlice';
import Utility, { deepClone } from '../../../Utility/Utility';
import { selectDimensions } from '../../../Redux/Slices/LocationSlice';
import TableService from '../../../Services/Table';
import { TableManager } from '../../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { addInvoiceColumnConfig } from '../../../Redux/Slices/InvoicesSlice';
import { addProductColumnConfig } from '../../../Redux/Slices/ProductsSlice';
import { addBillColumnConfig } from '../../../Redux/Slices/BillsSlice';
import { addOrderColumnConfig } from '../../../Redux/Slices/PurchaseOrdersSlice';
import { addQuoteColumnConfig } from '../../../Redux/Slices/QuotesSlice';
import { SubmitButton } from '../../../SharedComponents/SubmitButton';
import { addSalesOrderColumnConfig } from '../../../Redux/Slices/SalesOrderSlice';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import { addStockAdjustmentColumnConfig } from '../../../Redux/Slices/StockAdjustmentSlice';
import { addStockTransferColumnConfig } from '../../../Redux/Slices/StockTransferSlice';
import { addReportSODetailsColumnConfig } from '../../../Redux/Slices/ReportSalesOrderSlice';

interface AddClassProps {
  data: any;
  onCancel: () => void;
  onSuccess?: () => void;
}

const AddClass = (props: AddClassProps) => {
  // State
  const [formData, setFormData] = useState(deepClone(initialFormState));
  const [isSubClass, setIsSubClass] = useState<boolean>(false);
  const [classData, setClassData] = useState<any>([]);
  const [dimenstionId, setDimensionId] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const dimensionData = useAppSelector(selectDimensions);
  const classesData = useAppSelector(selectClassesData);
  const dispatch = useAppDispatch();
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      renderPropsData();
    }
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  useEffect(() => {
    if (dimensionData && dimensionData?.content?.length > 0) {
      let _dimensionData = dimensionData?.content;
      _dimensionData?.forEach((data: any) => {
        if (data.label === CLASS) {
          setDimensionId(data.id);
        }
      });
    }
  }, [dimensionData]);

  useEffect(() => {
    if (classesData && classesData.length > 0) {
      let _classData: any[] = [];
      classesData.forEach((data: any) => {
        if (data.parentId === null) {
          _classData.push({ label: data.name, value: data.id });
        }
      });
      if (!Utility.isEmpty(props.data)) {
        let _currentClassIndex = _classData.findIndex(
          (data: any) => data.value === props.data.id
        );
        _classData.splice(_currentClassIndex, 1);
      }

      setClassData(_classData);
    } else {
      setClassData([]);
    }
  }, [classesData]);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const renderPropsData = () => {
    let newState = { ...formData };
    newState.name.value = props.data.label;
    setFormData({ ...newState });
    /**
     * Temporary Hidden for is Sub Class
     */
    // if (props.data.subClass) {
    //   setIsSubClass(true);
    //   if (classesData && classesData.length > 0) {
    //     let _parentClass: any = classesData.filter(
    //       (data: any) => data.id === props.data.parentId
    //     );
    //     newState.parentClass = {
    //       label: _parentClass[0].name,
    //       value: _parentClass[0].id
    //     };
    //   } else {
    //     newState.parentClass = { label: '', value: '' };
    //   }
    // } else {
    //   setIsSubClass(false);
    // }
    /**
     * Temporary Hidden for is Sub Class
     */
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel
            text={`${
              !Utility.isEmpty(props.data) ? `Update Class` : `Add Class`
            }`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <SubmitButton
            isSaving={isSaving}
            isDisabled={isSaving}
            onClick={() => {
              saveClass();
            }}
          />
        </div>
      </div>
    );
  };

  const validateForm = () => {
    let newState = { ...formData };
    if (Utility.isEmpty(formData.name.value?.trim())) {
      newState.name.hasError = true;
      newState.name.errorMsg = 'Please enter class name';
      setFormData({ ...newState });
      return false;
    }

    return true;
  };

  const makePayload = () => {
    let payload: any = {};

    payload = {
      label: formData.name.value
    };
    return payload;

    /**
     * Temporary Hidden for is Sub Class
     */
    // if (isSubClass) {
    //   Payload.parentId = formData.parentClass.value;
    // } else {
    //   Payload.parentId = '';
    // }
    /**
     * Temporary Hidden for is Sub Class
     */
  };

  const saveClass = () => {
    if (validateForm()) {
      setIsSaving(true);
      if (!Utility.isEmpty(props.data)) {
        ClassesService.updateClass(props?.data?.id, makePayload()).then(
          (res: any) => {
            props.onCancel();
            setIsSaving(false);
            updateColumnConfigs();
            dispatch(fetchCustomFields({ status: 'ACTIVE', limit: '1000' }));
          },
          (err) => {
            setIsSaving(false);
            if (err && err.data.code === 409) {
              showAlert('Error!', 'Cannot update. Duplicate class name.');
            } else {
              showAlert('Error', 'Error updating class.');
            }
            console.error('Error updating class: ', err);
          }
        );
      } else {
        ClassesService.createClass(makePayload()).then(
          (res: any) => {
            if (props.onSuccess) {
              props.onSuccess();
            }
            props.onCancel();
            setIsSaving(false);
            updateColumnConfigs();
            dispatch(fetchCustomFields({ status: 'ACTIVE', limit: '1000' }));
          },
          (err) => {
            setIsSaving(false);
            showAlert('Error', 'Error creating class.');
            console.error('Error creating class: ', err);
          }
        );
      }
    }
  };

  const updateColumnConfigs = () => {
    TableService.getAllViews().then(
      (data) => {
        setupColumConfigs(data);
        TableManager.populateViewMap(
          data.filter((data) => !data.default && data.columnsMetaData)
        );
      },
      (err) => {
        console.error('Error updating column configs: ', err);
      }
    );
  };

  const setupColumConfigs = (configs: any[]) => {
    configs.forEach((config) => {
      const columnsMetaData = config?.columnsMetaData;
      const tableId = config._id;
      switch (config.name) {
        case REMOTE_CONFIG_TABLES.PRODUCT:
          dispatch(
            addProductColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVOICE:
          dispatch(
            addInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.SALES_ORDER:
          dispatch(
            addSalesOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BILL:
          dispatch(addBillColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          dispatch(addOrderColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.QUOTATION:
          dispatch(addQuoteColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          dispatch(
            addStockAdjustmentColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_TRANSFER:
          dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BOOKS_REPORTS_SALES_ORDER_DETAILS:
          dispatch(
            addReportSODetailsColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
      }
    });
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 975,
          width: !isDesktop ? '85%' : '30%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: '60px'
        }}
      >
        {getHeader()}
        <div
          id="popup-container"
          className="column parent-width parent-height mt-3 p-h-r p-v-s"
        >
          <div className="row">
            <DKInput
              title={'Name'}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.TEXT}
              value={formData.name.value}
              required={true}
              onChange={(text: string) => {
                let newState = { ...formData };
                if (!Utility.isEmpty(text)) {
                  newState.name.hasError = false;
                } else {
                  newState.name.hasError = true;
                }
                newState.name.value = text;
                setFormData({ ...newState });
              }}
              canValidate={formData.name.hasError}
              validator={(value: string) => {
                return !formData.name.hasError;
              }}
              errorMessage={formData.name.errorMsg}
            />
          </div>
          {/*
          Temporary Hidden for is Sub Class
           */}
          {/* {classData && classData.length > 0 && (
            <div className="row mt-4">
              <DKCheckMark
                title="is Sub-Class"
                className=""
                isSelected={isSubClass}
                onClick={() => {
                  setIsSubClass(!isSubClass);
                }}
                style={{ width: 150 }}
              />
            </div>
          )}
          {isSubClass && (
            <div className="row">
              <DKInput
                type={INPUT_TYPE.DROPDOWN}
                title={'Parent Class'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                readOnly={
                  props.populateFormData && props.populateFormData.accountGroup
                }
                value={formData.parentClass.label}
                className="mt-l"
                onChange={(obj: any) => {
                  let newState = { ...formData };
                  newState.parentClass = obj;
                  setFormData({ ...newState });
                }}
                dropdownConfig={{
                  title: '',
                  allowSearch: true,
                  searchableKey: 'label',
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  searchApiConfig: null,
                  data: classData,
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={`${obj.label}`} />;
                  }
                }}
              />
            </div>
          )}*/}
        </div>
      </div>
    </div>
  );
};

export default AddClass;
