import { Budget } from '../Components/Accounting/Budgeting/CreateBudget';
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
export interface BudgetingAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: any;
  QueryParam?: any;
  Format?: string;
  Module?: string;
  CustomField?: any;
}

export const defaultConfig: BudgetingAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'desc',
  Sort: 'code',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: '',
  QueryParam: '',
  CustomField: null
};

export default class BudgetingService {
  static apiConfig: BudgetingAPIConfig;

  static createfetchURL = () => {
    let queryString: string = '?';
    if (Utility.isEmpty(BudgetingService.apiConfig)) {
      BudgetingService.apiConfig = defaultConfig;
    }
    queryString += `search=${BudgetingService.apiConfig.SearchTerm || ''}`;
    // if (BudgetingService.apiConfig.SKIP_REQUEST_INTERCEPTOR)
    //   queryString += `&SKIP_REQUEST_INTERCEPTOR=${BudgetingService.apiConfig.SKIP_REQUEST_INTERCEPTOR}`;
    if (BudgetingService.apiConfig.Limit)
      queryString += `&limit=${BudgetingService.apiConfig.Limit}`;
    if (BudgetingService.apiConfig.Page)
      queryString += `&page=${BudgetingService.apiConfig.Page}`;
    // if (BudgetingService.apiConfig.Sort)
    //   queryString += `&sort=${BudgetingService.apiConfig.Sort}`;
    // if (BudgetingService.apiConfig.SortDir)
    //   queryString += `&sortDir=${BudgetingService.apiConfig.SortDir}`;
    if (BudgetingService.apiConfig.Query)
      queryString += `&query=${BudgetingService.apiConfig.Query}`;
    if (BudgetingService.apiConfig.CustomField?.length > 0) {
      queryString += `&customfield=${encodeURI(
        JSON.stringify(BudgetingService.apiConfig.CustomField)
      )}`;
    }
    // if (BudgetingService.apiConfig.QueryParam)
    //   queryString += `${BudgetingService.apiConfig.QueryParam}`;

    return ApiConstants.URL.ACCOUNTS.BUDGETING + queryString;
  };

  static fetchAllBudgets() {
    return http.get(BudgetingService.createfetchURL()).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static createBudgets(budget: any) {
    return http2
      .post(ApiConstants.URL.ACCOUNTS.BUDGETING, budget)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createBulkBudgets(budget: any) {
    return http2
      .post(ApiConstants.URL.ACCOUNTS.BULK_BUDGETING, budget)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateBudget(id: any, budget: any) {
    return http
      .put(`${ApiConstants.URL.ACCOUNTS.BUDGETING}/${id}`, budget)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteBudget(id: any) {
    return http
      .delete(`${ApiConstants.URL.ACCOUNTS.BUDGETING}/${id}`)
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static allocateUsersToBudget(budgetid: any, userIds: any[]) {
    return http
      .post(
        `${ApiConstants.URL.ACCOUNTS.ASSIGN_USERS_TO_BUDGET(budgetid)}`,
        userIds
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}
