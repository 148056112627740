import React, { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { DKButton, DKCheckMark, showAlert, DKIcons } from 'deskera-ui-library';
import useClickOutSide from '../Hooks/useClickOutside';
import {
  fetchUserPreferences,
  updateUserPreferences
} from '../Redux/Slices/AuthSlice';
import TenantService from '../Services/Tenant';
import { IColumn } from '../Models/Table';

interface IGridColumnVisibilityProps {
  columns: IColumn[];
  tableName: string;
  buttonStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const COLUMN_VISIBILITY_BUTTON_CONFIG = {
  id: 'column-visibility-button',
  title: '',
  icon: DKIcons.ic_view,
  className: 'bg-white text-white border-m column-filter-button'
};

const GridColumnVisibility: React.FC<IGridColumnVisibilityProps> = (
  props: IGridColumnVisibilityProps
) => {
  const ref = useRef<HTMLDivElement>(null);
  const userPreferencesForColumn = useAppSelector(
    (state) => state.authInfo.userPreferences.data?.hiddenColumns
  );
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [showColumnVisibilityPopup, setShowColumnVisibilityPopup] =
    useState(false);
  const tableColumns = props.columns || [];

  const dispatch = useAppDispatch();

  const handleCloseVisibilityPicker = () => setShowColumnVisibilityPopup(false);

  useClickOutSide(ref, handleCloseVisibilityPicker);

  useEffect(() => {
    if (userPreferencesForColumn?.[props.tableName?.toUpperCase()]) {
      setHiddenColumns(userPreferencesForColumn[props.tableName.toUpperCase()]);
    }
  }, [userPreferencesForColumn, props.tableName]);

  const updateUserPref = async () => {
    let payload = {
      hiddenColumns: {
        ...userPreferencesForColumn,
        [props.tableName.toUpperCase()]: hiddenColumns
      }
    };

    try {
      dispatch(updateUserPreferences(payload));
      handleCloseVisibilityPicker();

      await TenantService.updateUserPreferences(payload);
      dispatch(fetchUserPreferences());
    } catch (error) {
      showAlert('Error', 'Unable to perform operation.');
    }
  };
  return (
    <div
      className="row width-auto position-relative ml-r mb-m"
      style={props.buttonStyle || {}}
    >
      <DKButton
        title={COLUMN_VISIBILITY_BUTTON_CONFIG.title}
        className={COLUMN_VISIBILITY_BUTTON_CONFIG.className}
        icon={COLUMN_VISIBILITY_BUTTON_CONFIG.icon}
        onClick={() => setShowColumnVisibilityPopup(true)}
      />
      {showColumnVisibilityPopup && (
        <div
          ref={ref}
          className="bg-white position-absolute z-index-3 p-h-s p-v-r shadow-m border-m  border-radius-s"
          style={{
            width: 200,
            top: 0,
            left: -100,
            ...props.style
          }}
        >
          <div className="row justify-content-between">
            <div className="fw-m">Show/Hide Columns</div>
            <DKButton
              title="Save"
              className="fw-b text-white bg-button"
              onClick={updateUserPref}
            />
          </div>
          <div
            className="hide-scroll-bar"
            style={{
              overflowY: 'scroll',
              maxHeight: 350
            }}
          >
            {tableColumns.map((column: IColumn) => (
              <div
                key={column.key}
                className="row p-v-s p-h-r cursor-hand"
                onClick={() => {
                  if (hiddenColumns.includes(column.id as string)) {
                    setHiddenColumns(
                      hiddenColumns.filter((c) => c !== column.id)
                    );
                  } else {
                    if (hiddenColumns.length < tableColumns.length - 1) {
                      setHiddenColumns([...hiddenColumns, column.id as string]);
                    }
                  }
                }}
              >
                <DKCheckMark
                  isSelected={!hiddenColumns.includes(column.id as string)}
                  title={column.name}
                  color="bg-button"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GridColumnVisibility;
