import React, { useEffect, useState } from 'react';
import { DKInput, DKLabel, INPUT_TYPE, INPUT_VIEW_DIRECTION, showAlert } from 'deskera-ui-library';
import { COMMON_CONSTANT, FORM_ELEMENTS, POPUP_CALLBACKS_TYPE } from '../../../Constants/Constant';
import { CallBackPayloadType, IRPPayload } from '../../../Models/Interfaces';
import { InputTag } from '../../../Models/NewContact';
import { deepClone } from '../../../Utility/Utility';
import { IRP_POPUP_FIELD_NAME } from '../../../Constants/Enum';
import { useAppDispatch } from '../../../Redux/Hooks';
import { fetchEInvoiceAuthInfo } from '../../../Redux/Slices/AuthSlice';

interface IRPCredentialsState {
  gstIn: InputTag<string>;
  username: InputTag<string>;
  password: InputTag<string>;
}

interface IRPCredentialsProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  gstIn: string;
}

export const IRPCredPopup = (props: any) => {
  const initialState: IRPCredentialsState = {
    gstIn: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isDisabled: true,
      isMandatory: false
    },
    username: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    password: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    }
  };
  const [irpFormState, setIrpFormState] = useState<IRPCredentialsState>(
    deepClone(initialState)
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    const updatedState = irpFormState;
    let eInvoiceData: any = localStorage.getItem('e-Invoice_data');
    // populate gstIn
    updatedState.gstIn.value = props.gstIn;
    updatedState.gstIn.isDisabled = true;
    if (eInvoiceData) {
      eInvoiceData = JSON.parse(eInvoiceData);
      updatedState.username.value = eInvoiceData.userName
    }

    setIrpFormState({ ...updatedState });
    return () => {
      // null
    };
  }, []);

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL,
      data: () => {
        onSubmit();
      }
    });
  };

  const onSubmit = async () => {
    const isFormValid = formFieldValidation();
    if (isFormValid) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
      });
      const payload: IRPPayload = {
        gstin: irpFormState.gstIn.value.toString(),
        username: irpFormState.username.value.toString(),
        password: irpFormState.password.value.toString()
      };

      let response = await dispatch(fetchEInvoiceAuthInfo(payload));
      if (response) {
        if (response && response?.payload?.status === '1') {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL_SUCCESS
          });
          showAlert('e-Invoice', 'Connected Successfully');
          props.onCancel();
        } else {
          if (
            response?.payload?.status === '0' &&
            response?.payload?.errors?.[0]?.errorMessage
          ) {
            showAlert('Error!', response?.payload?.errors?.[0]?.errorMessage);
          } else {
            showAlert('e-Invoice', 'Unable to connect');
          }
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
          props.onCancel();
        }
      } else {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
        });
        props.onCancel();
      }
    }
  };

  const formFieldUpdated = (
    fieldName: IRP_POPUP_FIELD_NAME,
    newValue: string
  ) => {
    const updatedState = irpFormState;
    switch (fieldName) {
      case IRP_POPUP_FIELD_NAME.USERNAME:
        updatedState.username.hasError = false;
        if (updatedState.username.isMandatory && !newValue) {
          updatedState.username.hasError = true;
          updatedState.username.errorMsg = 'Username cannot be left empty';
        }
        updatedState.username.value = newValue;
        break;
      case IRP_POPUP_FIELD_NAME.PASSWORD:
        updatedState.password.hasError = false;
        if (updatedState.password.isMandatory && !newValue) {
          updatedState.password.hasError = true;
          updatedState.password.errorMsg = 'Password cannot be left empty';
        }
        updatedState.password.value = newValue;
        break;
      default:
        break;
    }
    setIrpFormState({ ...updatedState });
  };

  const formFieldValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(irpFormState);
    if (updatedState.username.isMandatory && !updatedState.username.value) {
      updatedState.username.hasError = true;
      updatedState.username.errorMsg = 'Username cannot be left empty';
      formHasError = true;
    }
    if (updatedState.password.isMandatory && !updatedState.password.value) {
      updatedState.password.hasError = true;
      updatedState.password.errorMsg = 'Password cannot be left empty';
      formHasError = true;
    }
    if (formHasError) {
      setIrpFormState({ ...updatedState });
      return false;
    }
    return true;
  };

  const goToGSTPortal = () => {
    window.open(COMMON_CONSTANT.GST_PORTAL_URL, '_blank');
  };

  return (
    <div className="parent-width p-h-m p-v-l">
      <DKInput
        title="GSTIN"
        value={irpFormState.gstIn.value}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={irpFormState.gstIn.isDisabled}
        className="mb-m"
      />

      <DKInput
        title="Username"
        required={irpFormState.username.isMandatory}
        canValidate={irpFormState.username.isMandatory}
        value={irpFormState.username.value}
        onChange={(value: string) =>
          formFieldUpdated(IRP_POPUP_FIELD_NAME.USERNAME, value)
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        validator={(value: string) => {
          return !irpFormState.username.hasError;
        }}
        errorMessage={irpFormState.username.errorMsg}
        className="mb-m"
      />

      <DKInput
        title="Password"
        type={INPUT_TYPE.PASSWORD}
        required={irpFormState.password.isMandatory}
        canValidate={irpFormState.password.isMandatory}
        value={irpFormState.password.value}
        onChange={(value: string) =>
          formFieldUpdated(IRP_POPUP_FIELD_NAME.PASSWORD, value)
        }
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        validator={(value: string) => {
          return !irpFormState.password.hasError;
        }}
        errorMessage={irpFormState.password.errorMsg}
        className="mb-m"
      />

      <DKLabel
        text="Kindly key in your Credentials. In case you do not have the credentials, logon to the GST portal to create them."
        className="fw-r fs-r mt-l"
      />
      <div className="fw-r fs-r mt-r">
        <p>
          Click
          <span
            className="text-blue cursor-hand p-h-xs"
            onClick={goToGSTPortal}
          >
            here
          </span>
          to logon to the GST portal to generate your username & password{' '}
        </p>
      </div>
      {props && props.sessionOutMessage && (
        <DKLabel
          text="You don't have an active login session with the GSTIN portal. Kindly login again. "
          className="fw-r fs-r mt-m text-red"
        />
      )}
    </div>
  );
};
