import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './StockManagementInterceptor';
import http2 from './Interceptor2';
import { STOCK_URL_API } from '../Constants/Constant';

export interface StockAdjustmentApprovalAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

export const stockAdjApprovaldefaultConfig: StockAdjustmentApprovalAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};

export interface StockAdjustmentAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

export const defaultConfig: StockAdjustmentAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};
class StockAdjustmentService {
  static apiConfig: StockAdjustmentAPIConfig;
  static approvalApiConfig: StockAdjustmentApprovalAPIConfig;
  static abortController: any = null;
  static selectedIndex: number = 0;

  static getSelectedIndex() {
    return StockAdjustmentService.selectedIndex;
  }

  static setSelectedIndex(index: number) {
    StockAdjustmentService.selectedIndex = index;
  }
  static getStockAdjustmentByPage() {
    Utility.cancelRequest(StockAdjustmentService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const query: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    StockAdjustmentService.abortController = Utility.createAbortController();

    return http
      .get(
        `${ApiConstants.URL.STOCK_MANAGEMENT.GET_STOCK_ADJUSTMENTS}${query}`,
        {
          signal: StockAdjustmentService.abortController.signal
        }
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getAllStockAdjustments() {
    return http
      .get(ApiConstants.URL.STOCK_MANAGEMENT.GET_STOCK_ADJUSTMENTS)
      .catch((err: any) => {
        console.error('Error fetching stock adjustments: ', err);
        return Promise.reject(err);
      });
  }

  static getSerialBatchCount(code: string) {
    return http2
      .get(
        ApiConstants.URL.STOCK_MANAGEMENT.FETCH_ADVANCED_TRACKING_SERIAL_BATCH_COUNT(
          code
        )
      )
      .catch((err: any) => {
        console.error('Error fetching serial batch count: ', err);
        return Promise.reject(err);
      });
  }

  static getStockValuation(fromDate: string) {
    return http
      .get(ApiConstants.URL.STOCK_MANAGEMENT.GET_VALUATION(fromDate))
      .catch((err: any) => {
        console.error('Error fetching stock valuation: ', err);
        return Promise.reject(err);
      });
  }

  static getSourceWarehouses(wareHouseCode: string) {
    return http
      .get(ApiConstants.URL.STOCK_MANAGEMENT.WAREHOUSE_LIST(wareHouseCode))
      .catch((err: any) => {
        console.error('Error fetching source warehouse list: ', err);
        return Promise.reject(err);
      });
  }

  /** @deprecated */
  static getSourceWarehousesWithRejectedWh(wareHouseCode: string) {
    let endpoint =
      ApiConstants.URL.PRODUCT.FETCH_WAREHOUSE_INVENTORY +
      `?includeRejectedWarehouse=true&warehouseCode=${wareHouseCode}`;

    return http2.get(endpoint).catch((err: any) => {
      console.error('Error fetching source warehouse list: ', err);
      return Promise.reject(err);
    });
  }

  static deleteStockAdjustment(id: string) {
    return http
      .delete(
        `${ApiConstants.URL.STOCK_MANAGEMENT.DELETE_STOCK_ADJUSTMENTS(id)}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static saveStockAdjustments(data: any) {
    return http
      .post(`${ApiConstants.URL.STOCK_MANAGEMENT.STOCK_ADJUSTMENTS}`, data)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateStockAdjustments(data: any) {
    return http
      .put(`${ApiConstants.URL.STOCK_MANAGEMENT.STOCK_ADJUSTMENTS}`, data)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getProductBriefEndpoint() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string =
      ApiConstants.URL.STOCK_BASE_API.replace('v1/', 'v1') +
      ApiConstants.URL.PRODUCT.FETCH_STOCK_TRANSFER_PRODUCT_BRIEF;
    return finalEndpoint;
  }

  static fetchStockAdjustmentProductBrief(searchText: string) {
    let endPoint =
      process.env.NODE_ENV === 'development'
        ? `${ApiConstants.URL.STOCK_MANAGEMENT.PRODUCT_LIST(searchText)}`
        : `${ApiConstants.URL.STOCK_MANAGEMENT.PRODUCTS_LIST(searchText)}`;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportStock(moduleName: string, totalElements: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const query: string = `&limit=${totalElements}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    return http2
      .get(
        `${ApiConstants.URL.STOCK_MANAGEMENT.EXPORT_STOCK(moduleName)}${query}`
      )
      .catch((err: any) => {
        console.error('Error exporting stock list: ', err);
        return Promise.reject(err);
      });
  }
  static getStockAdjustmentReason(type: string) {
    return http2
      .get(ApiConstants.URL.STOCK_MANAGEMENT.ADJUSTMENT_REASON(type))
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static saveStockAdjustmentsReason(data: any) {
    return http2
      .post(
        `${ApiConstants.URL.STOCK_MANAGEMENT.ADD_STOCK_ADJUSTMENT_REASON}`,
        data
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateStockAdjustmentsReason(data: any) {
    return http2
      .put(
        `${ApiConstants.URL.STOCK_MANAGEMENT.UPDATE_STOCK_ADJUSTMENT_REASON(
          data.code
        )}`,
        data
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static deleteStockAdjustmentsReason(id: any) {
    return http2
      .delete(
        `${ApiConstants.URL.STOCK_MANAGEMENT.UPDATE_STOCK_ADJUSTMENT_REASON(
          id
        )}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getStockAdjustmentByCode(stockAdjCode: string) {
    let query = `?query=code~${stockAdjCode.toLowerCase()}`;
    return http2
      .get(
        `${
          STOCK_URL_API +
          'v1/' +
          ApiConstants.URL.STOCK_MANAGEMENT.GET_STOCK_ADJUSTMENTS
        }${query}`
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error deleting work order: ', err);
      });
  }

  static getStockAdjPendingApprovalData() {
    const url =
      ApiConstants.URL.STOCK_ADJUSTMENT_APPROVAL
        .GET_STOCK_ADJUSTMENT_PENDING_APPROVAL;
    return this.getPendingApprovalList(url, this.approvalApiConfig, {})
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  private static async getPendingApprovalList(
    endpoint: string,
    config: StockAdjustmentApprovalAPIConfig,
    options: any = {}
  ) {
    const searchParams = {
      limit: config.Limit?.toString() ?? '25',
      page: config.Page?.toString() ?? '0',
      search: config.SearchTerm ?? '',
      query: config.Query ?? '',
      queryParam: config.QueryParam ?? ''
    };
    const query: string = `?limit=${searchParams.limit}&page=${
      searchParams.page
    }&search=${searchParams.search}&query=${
      searchParams.query ? searchParams.query : ''
    }${searchParams.queryParam ? searchParams.queryParam : ''}`;

    const finalEndpoint: string = endpoint + '?' + query;
    return http
      .get(`${finalEndpoint}`, options)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteStockAdjustmentPendingApproval(id: string) {
    return http
      .delete(
        `${ApiConstants.URL.STOCK_ADJUSTMENT_APPROVAL.DELETE_STOCK_ADJUSTMENT_APPROVAL(
          id
        )}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static approveStockAdjustment(id: any) {
    return http
      .post(
        `${ApiConstants.URL.STOCK_ADJUSTMENT_APPROVAL.APPROVE_STOCK_ADJUSTMENT_APPROVAL(
          id
        )}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static rejectStockAdjustment(id: any) {
    return http
      .post(
        `${ApiConstants.URL.STOCK_ADJUSTMENT_APPROVAL.REJECT_STOCK_ADJUSTMENT_APPROVAL(
          id
        )}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export default StockAdjustmentService;
