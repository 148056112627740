import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import TDSService from '../../Services/TDSSetup';
import { RootState } from '../Store';

export interface TDSSetupState {
    data: any;
    columnConfig: IColumn[];
    configTableId: string;
    status: API_STATUS;
    formData: {
        tdsRates: any;
        tdsNatureOfIncome: any;
        tdsDeducteeType: any;
    };
}

const initialState: TDSSetupState = {
    data: {} as any,
    columnConfig: [],
    configTableId: '',
    status: API_STATUS.IDLE,
    formData: {
        tdsRates: [],
        tdsNatureOfIncome: [],
        tdsDeducteeType: []
    }
};


export const fetchTDSRates = createAsyncThunk('fetchTDSRates', async () => {
    const response = await TDSService.getTDSRates();
    return response;
});

export const fetchTDSNatureOfIncome = createAsyncThunk('fetchTDSNatureOfIncome', async () => {
    const response = await TDSService.getNatureOfPayment();
    return response;
});

export const fetchTDSDeducteeType = createAsyncThunk('fetchTDSDeducteeType', async () => {
    const response = await TDSService.getDeducteeType();
    return response;
});

export const deleteTDSRates = createAsyncThunk(
    'deleteTDSRates',
    async (id: any) => {
        const response = await TDSService.deleteTDSRates(id);
        return response;
    }
);

export const deleteTDSNatureIncome = createAsyncThunk(
    'deleteTDSNatureIncome',
    async (id: any) => {
        const response = await TDSService.deleteTDSNaturePayment(id);
        return response;
    }
);

export const deleteTDSDeducteeType = createAsyncThunk(
    'deleteTDSNatureIncome',
    async (id: any) => {
        const response = await TDSService.deleteTDSDeducteeType(id);
        return response;
    }
);

export const TDSSetupSlice = createSlice({
    name: 'TDSSetup',
    initialState,
    reducers: {
        addTDSRatesColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
            const tableId = action.payload.tableId;
            const configs = action.payload.config;
            let configsToStore: any[] = [];
            configs.forEach((config) => {
                configsToStore.push({
                    ...config,
                    key: config.columnCode,
                    options: config.options ? config.options : []
                });
            });
            state.columnConfig = configsToStore;
            state.configTableId = tableId;
        },
        addTDSRates: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        },
        updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
            state.columnConfig = [...action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTDSRates.pending, (state) => {
                state.status = API_STATUS.LOADING;
            })
            .addCase(fetchTDSRates.fulfilled, (state, action) => {
                state.status = API_STATUS.IDLE;
                const payload: any = action.payload;
                state.formData.tdsRates = payload;
            })
            .addCase(fetchTDSNatureOfIncome.pending, (state) => {
                state.status = API_STATUS.LOADING;
            })
            .addCase(fetchTDSNatureOfIncome.fulfilled, (state, action) => {
                state.status = API_STATUS.IDLE;
                const payload: any = action.payload;
                state.formData.tdsNatureOfIncome = payload;
            })
            .addCase(fetchTDSDeducteeType.pending, (state) => {
                state.status = API_STATUS.LOADING;
            })
            .addCase(fetchTDSDeducteeType.fulfilled, (state, action) => {
                state.status = API_STATUS.IDLE;
                const payload: any = action.payload;
                state.formData.tdsDeducteeType = payload;
            });
    }
});

export const { addTDSRatesColumnConfig, addTDSRates, updateColumnConfig } =
    TDSSetupSlice.actions;


export const selectTDSRates = (state: RootState) => state.tdsSetup.formData.tdsRates

export const selectTDSRatesColumnConfig = (state: RootState) =>
    tdsRateColumnConfig;


export const selectTDSNatureOfIncome = (state: RootState) => state.tdsSetup.formData.tdsNatureOfIncome

export const selectTDSNatureOfIncomeColumnConfig = (state: RootState) =>
    tdsNatureOfIncomeColumnConfig;

export const selectTDSDeducteeType = (state: RootState) => state.tdsSetup.formData.tdsDeducteeType

export const selectTDDeducteeTypeColumnConfig = (state: RootState) =>
    tdsDeducteeTypeColumnConfig;


export const selectTDSSetupLoadingStatus = (state: RootState) =>
    state.tdsSetup.status;

export const selectTDSSetupColumnConfigTableId = (state: RootState) =>
    state.tdsSetup.configTableId;


export default TDSSetupSlice.reducer;


const tdsRateColumnConfig: any = [
    {
        name: 'Nature of Income Payment',
        type: 'text',
        index: 0,
        options: null,
        required: true,
        width: 300,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'natureOfPayment',
        key: 'natureOfPayment'
    },
    {
        name: 'Residential Status',
        type: 'select',
        index: 1,
        options: [
            {
                id: 'RESIDENT',
                name: 'Resident',
                color: 'data-grid-badge-color-1'
            },
            {
                id: 'NON_RESIDENT',
                name: 'Non-Resident',
                color: 'data-grid-badge-color-2'
            }
        ],
        required: true,
        width: 150,
        editable: true,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'resident',
        key: 'resident'
    },
    {
        name: 'TDS Rate',
        type: 'number',
        index: 2,
        options: null,
        required: false,
        width: 100,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'tdsRate',
        key: 'tdsRate'
    },
    {
        name: 'TDS Payable Account',
        type: 'text',
        index: 3,
        options: null,
        required: true,
        width: 200,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'tdsAccountPayable',
        key: 'tdsAccountPayable'
    },
    {
        name: 'Basic Exemption per Annum',
        type: 'number',
        index: 4,
        options: null,
        required: true,
        width: 230,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'thresholdLimit',
        key: 'thresholdLimit'
    },
    {
        name: 'Deductee Type',
        type: 'text',
        index: 5,
        options: null,
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'deducteeType',
        key: 'deducteeType'
    },
    {
        name: 'TDS Applicable From',
        type: 'date',
        index: 6,
        options: null,
        required: false,
        width: 180,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'applicableFromDate',
        key: 'applicableFromDate'
    }
]

const tdsNatureOfIncomeColumnConfig: any = [
    {
        name: 'Section Code',
        type: 'text',
        index: 0,
        options: null,
        required: true,
        width: 200,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'sectionCode',
        key: 'sectionCode'
    },
    {
        name: 'Nature of Income Payment',
        type: 'text',
        index: 1,
        options: null,
        required: false,
        width: 700,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'natureOfPayment',
        key: 'natureOfPayment'
    }
]

const tdsDeducteeTypeColumnConfig: any = [
    {
        name: 'Deductee Type',
        type: 'text',
        index: 0,
        options: null,
        required: true,
        width: 400,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'type',
        key: 'type'
    },
    {
        name: 'Deductee Code',
        type: 'text',
        index: 1,
        options: null,
        required: false,
        width: 400,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'code',
        key: 'code'
    }
]


