import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import ContactService from '../../Services/Contact';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchPaymentTerms = createAsyncThunk(
  'fetchPaymentTerms',
  async () => {
    const response = await ContactService.getPaymentTermsInfo();
    return response;
  }
);

export const PaymentTermsSlice = createSlice({
  name: 'Payment_terms',
  initialState,
  reducers: {
    addPaymentTermsColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updatePaymentTermsColumnConfig: (
      state,
      action: PayloadAction<IColumn[]>
    ) => {
      state.columnConfig = [...action.payload];
    },
    addPaymentTerms: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentTerms.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPaymentTerms.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addPaymentTerms,
  addPaymentTermsColumnConfig,
  updatePaymentTermsColumnConfig
} = PaymentTermsSlice.actions;
export const selectPaymentTerms = (state: RootState) => state.paymentTerms.data;

export const selectPaymentTermsColumnConfig = (state: RootState) =>
  state.paymentTerms.columnConfig;

export const paymentTermsColumnConfigTableId = (state: RootState) =>
  state.paymentTerms.configTableId;
