import { DateFormat, TableColumnPopupType } from '../../../Constants/Constants';
import CustomFieldManager from '../../../Manager/CustomFieldManager';
import TemplateSettingsManager from '../../../Manager/TemplateSettingsManager';
import { getLocalisedText } from '../../../Translate/LanguageManager';
import TableFooterUtility from '../../../Utilities/TableFooterUtility';
import Utility, {
	getDateFrom,
	getDateString,
	getIsInvoice,
	getIsSalesOrder,
} from '../../../Utilities/Utility';
import { getSortedHeader } from '../TableUtility';
export class SecurityGateTableItem {
	lineNumber: number;
	code: string;
	name: string;
	quantity: string;
	description: string;
	productCustomField: string;
	uom: string;
	quantityWithUom: string;
	data: any;
	refDocument: any;
	refDocumentDate: any;
	rawItemValue: any;
	constructor(lineItem: any, data: any, currency: string) {
		this.lineNumber = lineItem.lineNumber;
		this.code = lineItem.code;
		this.name = this.getProductName(data, lineItem);
		this.quantity = lineItem.quantity;
		this.refDocument = lineItem.refDocument;
		this.refDocumentDate = lineItem.refDocumentDate;
		this.productCustomField = '';
		this.uom = lineItem?.documentUOMSchemaDefinition?.name ?? '';
		this.quantityWithUom =
			lineItem.quantity + ' x ' + this.getProductUOM(lineItem);
		this.description = this.getProductDescription(lineItem);

		this.data = data;
		this.rawItemValue = lineItem;
	}

	getProductName(data: any, lineItem: any): string {
		let name = `${lineItem?.product.name} (${lineItem?.product?.documentSequenceCode})`;
		if (lineItem?.name) {
			name = getLocalisedText(lineItem.name);
		}
		return name;
	}

	getProductDescription(lineItem: any): string {
		if (
			lineItem.productDescription !== undefined &&
			lineItem.productDescription !== null
		) {
			return getLocalisedText(lineItem.productDescription);
		}
		return '';
	}

	getProductUOM(lineItem: any): string {
		let uom = lineItem.uom ? lineItem.uom : '';

		if (TemplateSettingsManager.getIsAlterUOMSelected(undefined)) {
			if (lineItem.alterUOM !== undefined && lineItem.alterUOM !== null) {
				return uom + ' ' + lineItem.alterUOM;
			}
		}
		return uom;
	}
	getCombineProductCustomField() {
		let val = '';
		let productTableConfiguration =
			TemplateSettingsManager.defaultTemplateSettings
				.productTableConfiguration;
		if (productTableConfiguration !== undefined) {
			if (productTableConfiguration.isCombinesProductCustomField) {
				let productCustomFields =
					CustomFieldManager.getActiveProductCustomFields();
				if (productCustomFields !== undefined) {
					if (productCustomFields.length > 0) {
						productCustomFields.forEach((element) => {
							if (
								element.isSelected &&
								element.code !== undefined
							) {
								let field = this.getProductCustomFieldByCode(
									element.code
								);
								if (field !== undefined) {
									let cfType = element.cfType;
									if (cfType === undefined) {
										let customField =
											CustomFieldManager.getProductCustomFields().find(
												(cf) => cf.code === element.code
											);
										if (
											customField &&
											customField.fieldType
										) {
											cfType = customField.fieldType;
										}
									}

									if (cfType === 'DATE') {
										val +=
											element.label +
											': <b>' +
											(getDateString(
												getDateFrom(
													field.value,
													DateFormat.MM_DD_YYYY_SLASH
												),
												CustomFieldManager.getDateFormat()
											) || field.value) +
											'</b>\n';
									} else {
										val +=
											element.label +
											': <b>' +
											field.value +
											'</b>\n';
									}
								}
							}
						});
					} else {
						if (
							productTableConfiguration.combinedProductCustomField !==
								undefined &&
							productTableConfiguration.combinedProductCustomField !==
								null
						) {
							if (
								productTableConfiguration
									.combinedProductCustomField.length > 0
							) {
								productTableConfiguration.combinedProductCustomField.forEach(
									(element: any) => {
										if (
											element.isSelected &&
											element.code !== undefined
										) {
											let field =
												this.getProductCustomFieldByCode(
													element.code
												);
											if (field !== undefined) {
												let cfType = element.cfType;
												if (cfType === undefined) {
													let customField =
														CustomFieldManager.getProductCustomFields().find(
															(cf) =>
																cf.code ===
																element.code
														);
													if (
														customField &&
														customField.fieldType
													) {
														cfType =
															customField.fieldType;
													}
												}

												if (cfType === 'DATE') {
													val +=
														element.label +
														': <b>' +
														(getDateString(
															getDateFrom(
																field.value,
																DateFormat.MM_DD_YYYY_SLASH
															),
															CustomFieldManager.getDateFormat()
														) || field.value) +
														'</b>\n';
												} else {
													val +=
														element.label +
														': <b>' +
														field.value +
														'</b>\n';
												}
											}
										}
									}
								);
							}
						}
					}
				}
			}
		}

		return val;
	}

	getProductCustomFieldByCode(code: string) {
		let customFields = this.rawItemValue.customFields;
		if (customFields === undefined || customFields === null) {
			return undefined;
		}

		if (customFields.length === 0) {
			return undefined;
		}

		return customFields.find((x: any) => x.code === code);
	}

	toString(tableConfig?: any) {
		let sortedHeader = getSortedHeader(tableConfig);
		const isCogsColumn =
			getIsInvoice(this.data?.type) || getIsSalesOrder(this.data?.type);
		if (sortedHeader.length > 0) {
			let sortedValue: string[] = [];
			sortedHeader.forEach((element) => {
				let footerList =
					TableFooterUtility.getFooterListByType('product');
				if (isCogsColumn) {
					let index = footerList.indexOf(
						TableColumnPopupType.totalAmount
					);
					footerList.splice(
						index !== -1 ? index + 1 : 5,
						0,
						TableColumnPopupType.cogs
					);
				}
				if (!footerList.includes(element.type)) {
					sortedValue.push(this.getValueByType(element));
				}
			});
			return sortedValue;
		}
		return this.getDefaultList();
	}

	getValueByType(element: any) {
		const type = element.type;
		switch (type) {
			case TableColumnPopupType.lineNumber:
				return this.lineNumber;
			case TableColumnPopupType.productName:
				return this.name;
			case TableColumnPopupType.description:
				return this.description;
			case TableColumnPopupType.productCustomField:
				if (element.code !== undefined) {
					let value = '';
					let field = this.getProductCustomFieldByCode(element.code);
					if (field !== undefined) {
						let cfType = element.cfType;
						if (cfType === undefined) {
							let customField =
								CustomFieldManager.getProductCustomFields().find(
									(cf) => cf.code === element.code
								);
							if (customField && customField.fieldType) {
								cfType = customField.fieldType;
							}
						}

						if (cfType === 'DATE') {
							value =
								getDateString(
									getDateFrom(
										field.value,
										DateFormat.MM_DD_YYYY_SLASH
									),
									this.data.dateFormat
								) || field.value;
						} else {
							value = field.value;
						}
					}
					return value;
				}
				return this.getCombineProductCustomField();
			case TableColumnPopupType.quantity:
				return Utility.toDecimalFormat(
					parseFloat(this.quantity),
					TemplateSettingsManager.getQuantityDecimalScale()
				);
			case TableColumnPopupType.uom:
				return this.uom ? this.uom : '';
			case TableColumnPopupType.refDocument:
				return this.refDocument ? this.refDocument : '';
			case TableColumnPopupType.refDocumentDate:
				return this.refDocumentDate ? this.refDocumentDate : '';
			default:
				return '';
		}
	}

	getDefaultList() {
		return [
			this.lineNumber,
			getLocalisedText(this.code),
			getLocalisedText(this.name),
			getLocalisedText(this.description),
			this.productCustomField,
			Utility.toDecimalFormat(
				parseFloat(this.quantity),
				TemplateSettingsManager.getQuantityDecimalScale()
			),
			this.uom ? this.uom : '',
			this.quantityWithUom,
		];
	}
}
