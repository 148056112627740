import {
  DKButton,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  Toggle
} from 'deskera-ui-library';
import { useState } from 'react';
import { FIELD_TYPE, LabelCellItem, getValue } from './LabelTemplateHelper';

interface EditableCellProps {
  data: LabelCellItem;
  updateData: Function;
  deleteItem: Function;
  isReordering: Boolean;
}

const EditLabelCell: React.FC<EditableCellProps> = ({
  data,
  updateData,
  deleteItem,
  isReordering
}) => {
  const [expanded, setExpanded] = useState(false);

  const onUpdate = (updatedData: any) => {
    updateData({ ...data, ...updatedData });
  };

  return (
    <div
      className="bg-white border-radius-s border-m px-2 mt-1 column border-box shadow-s mobile-full-grid-width-2"
      style={{
        minWidth: 250,
        marginRight: 1,
        position: 'relative'
      }}
    >
      <div
        className="flex align-items-center parent-width"
        onClick={() => !isReordering && setExpanded((value) => !value)}
      >
        <div className="flex-1">
          <DKLabel text={data.title} className="fw-m fs-m" />
        </div>
        <div
          className="inline-flex"
          style={{ visibility: isReordering ? 'hidden' : 'visible' }}
        >
          <DKButton
            icon={DKIcons.ic_delete}
            className=""
            onClick={deleteItem}
          />
          <DKButton
            icon={expanded ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
            className=""
            onClick={(e: Event) => e.preventDefault()}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12 }}
            className="mb-1"
            value={data.label}
            title="Label"
            type={INPUT_TYPE.TEXT}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => onUpdate({ label: value })}
          />
          <DKInput
            titleStyle={{ color: 'gray', fontSize: 12 }}
            className="mb-1"
            value={getValue(data)}
            title="Value"
            type={INPUT_TYPE.TEXT}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            canValidate={false}
            onChange={(value: any) => onUpdate({ value: value })}
            readOnly={data.type !== FIELD_TYPE.CUSTOM_TEXT}
          />
          <div className="flex parent-width gap-2 mb-1">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={''}
              value={data.labelFontSize}
              title="Label Font Size"
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => onUpdate({ labelFontSize: value })}
            />
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={''}
              value={data.valueFontSize}
              title="Value Font Size"
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => onUpdate({ valueFontSize: value })}
            />
          </div>
          <div className="flex parent-width gap-2 mb-1">
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={''}
              value={data.width}
              title="Width"
              type={INPUT_TYPE.TEXT}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => onUpdate({ width: value })}
            />
            <DKInput
              titleStyle={{ color: 'gray', fontSize: 12 }}
              className={'parent-width'}
              value={data.direction}
              formatter={(obj: any) => {
                return obj;
              }}
              title="Direction"
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              canValidate={false}
              onChange={(value: any) => onUpdate({ direction: value })}
              dropdownConfig={{
                title: '',
                allowSearch: false,
                searchableKey: '',
                style: { minWidth: 250 },
                className: 'shadow-m width-auto',
                searchApiConfig: null,
                data: ['Vertical', 'Horizontal'],
                renderer: (index: any, obj: any) => {
                  return <DKLabel key={index} text={`${obj}`} />;
                }
              }}
            />
          </div>
          {data.type !== FIELD_TYPE.ADDRESS &&
            data.type !== FIELD_TYPE.DATE && (
              <div className="flex parent-width gap-2 mb-1">
                <div className="flex-1">
                  <DKLabel
                    className="text-sm text-gray-500 font-medium"
                    style={{ marginTop: 2 }}
                    text="Show Barcode"
                  />
                  <Toggle
                    className="box-content"
                    onChange={(checked: any) =>
                      onUpdate({ showBarcode: !data.showBarcode })
                    }
                    isOn={data.showBarcode}
                  />
                </div>
                {data.showBarcode && (
                  <div className="flex-1">
                    <DKInput
                      titleStyle={{ color: 'gray', fontSize: 12 }}
                      className={'parent-width'}
                      value={data.barcodePosition}
                      formatter={(obj: any) => {
                        return obj;
                      }}
                      title="Barcode Position"
                      type={INPUT_TYPE.DROPDOWN}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      required={false}
                      canValidate={false}
                      onChange={(value: any) =>
                        onUpdate({ barcodePosition: value })
                      }
                      dropdownConfig={{
                        title: '',
                        allowSearch: false,
                        searchableKey: '',
                        style: { minWidth: 250 },
                        className: 'shadow-m width-auto',
                        searchApiConfig: null,
                        data: ['Top', 'Bottom'],
                        renderer: (index: any, obj: any) => {
                          return <DKLabel key={index} text={`${obj}`} />;
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          <div className="flex parent-width gap-2 mb-1">
            <div className="flex-1">
              <DKLabel
                className="text-sm text-gray-500 font-medium"
                style={{ marginTop: 2 }}
                text="Fixed Position"
              />
              <Toggle
                className="box-content"
                onChange={(checked: any) =>
                  onUpdate({ fixedPosition: !data.fixedPosition })
                }
                isOn={data.fixedPosition}
              />
            </div>
            <div className="flex-1"></div>
          </div>
          {data.fixedPosition && (
            <div className="flex parent-width gap-2 mb-1">
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={''}
                value={data.leftMargin}
                title="Left Margin"
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                canValidate={false}
                onChange={(value: any) => onUpdate({ leftMargin: value })}
              />
              <DKInput
                titleStyle={{ color: 'gray', fontSize: 12 }}
                className={''}
                value={data.topMargin}
                title="Top Margin"
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                canValidate={false}
                onChange={(value: any) => onUpdate({ topMargin: value })}
              />
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditLabelCell;
