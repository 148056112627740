import React from 'react';
import ReactDOM from 'react-dom';
import {
  DKButton,
  DKLabel,
  DKInput,
  DKCheckMark,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { IWHTax } from '../../../../Models/WHTax';
import {
  createPayloadFromState,
  initialWHTaxFormState,
  WH_TAX_FORM_KEYS
} from '../../../../Helper/WHTaxHelper';
import Utility, { deepClone } from '../../../../Utility/Utility';
import AccountsService, {
  AccountAPIConfig
} from '../../../../Services/Accounts';
import ApiConstants from '../../../../Constants/ApiConstants';
import { useAppSelector } from '../../../../Redux/Hooks';
import { selectedAccounts } from '../../../../Redux/Slices/AccountsSlice';
import { createWHTax, updateWHTax } from '../../../../Services/WithHoldTax';
import { selectWithHoldTax } from '../../../../Redux/Slices/WHTSlice';
interface CreateWHTaxProps {
  onSave: (data: any) => void;
  onCancel: () => void;
  whTaxToEdit?: IWHTax | null;
}

const CreateWHTax: React.FC<CreateWHTaxProps> = (props) => {
  /** hooks goes here */
  const [formState, setFormState] = React.useState<any>(
    deepClone(initialWHTaxFormState)
  );
  const [formBeingSaved, toggleFormSaving] = React.useState(false);
  const accountsData = useAppSelector(selectedAccounts);
  const whTaxes = useAppSelector(selectWithHoldTax);

  React.useEffect(() => {
    updateConfigs();
  }, []);
  /** handlers goes here */
  const onSave = () => {
    if (!formBeingSaved && validateForm()) {
      toggleFormSaving(true);
      (Utility.isEmptyObject(props.whTaxToEdit)
        ? createWHTax(createPayloadFromState(formState))
        : updateWHTax({
            ...props.whTaxToEdit,
            ...createPayloadFromState(formState)
          })
      )
        .then((response) => {
          props?.onSave?.(response);
        })
        .catch((err) => {})
        .finally(() => {
          toggleFormSaving(false);
        });
    }
  };

  const validateForm = (): boolean => {
    let isFormValid = true;
    let copyOfFormState = { ...formState };
    Object.keys(copyOfFormState).forEach((key: any) => {
      const field = copyOfFormState[key];
      field.invalid = false;
      field.canValidate = false;
      if (
        field.required &&
        (field.value === undefined || field.value === null)
      ) {
        field.invalid = true;
        field.canValidate = true;
        field.errorMessage = `${field?.label} is required`;
      }
      copyOfFormState[key] = field;
    });
    isFormValid = Object.values(copyOfFormState).every(
      (field: any) => !field?.invalid
    );
    setFormState(copyOfFormState);
    return isFormValid;
  };

  const onFormStateChange = (field: any, value: any = {}) => {
    let copyOfFormState = { ...formState };

    switch (field.key) {
      case WH_TAX_FORM_KEYS.RESIDENT:
        copyOfFormState[field.key] = {
          ...copyOfFormState[field.key],
          value: !copyOfFormState[field.key]?.value
        };
        break;
      case WH_TAX_FORM_KEYS.SECTION_NAME:
        copyOfFormState[field.key] = {
          ...copyOfFormState[field.key],
          value: value
        };
        copyOfFormState[WH_TAX_FORM_KEYS.DESCRIPTION] = {
          ...copyOfFormState[WH_TAX_FORM_KEYS.DESCRIPTION],
          value: value?.sectionShortDescription
        };
        copyOfFormState[WH_TAX_FORM_KEYS.RATE] = {
          ...copyOfFormState[WH_TAX_FORM_KEYS.RATE],
          value: value?.rate
        };
        break;
      case WH_TAX_FORM_KEYS.RATE:
      case WH_TAX_FORM_KEYS.ANNUAL_EXEMPTION_LIMIT:
      case WH_TAX_FORM_KEYS.DESCRIPTION:
        copyOfFormState[field.key] = {
          ...copyOfFormState[field.key],
          value: value
        };
        break;
      case WH_TAX_FORM_KEYS.ACCOUNT_CODE:
      case WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE:
        copyOfFormState[field.key] = {
          ...copyOfFormState[field.key],
          value: value?.code
        };
        break;
      default:
        break;
    }
    setFormState(copyOfFormState);
  };

  const updateConfigs = () => {
    const copyOfFormState = { ...formState };
    const renderer = (account: any) => {
      return account.name;
    };
    const accountDropdownConfig = {
      title: '',
      allowSearch: false,
      searchableKey: '',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: (searchValue: string) => {
          const config: AccountAPIConfig = {
            ...AccountsService.apiConfig,
            Page: 0,
            SearchTerm: searchValue,
            Limit: 10,
            Query: `status=ACTIVE`
          };
          AccountsService.apiConfig = config;
          return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
        },
        dataParser: (response: any) => {
          let filtered = response?.content?.filter(
            (acc: any) =>
              (acc.status === 'ACTIVE' &&
                acc.accountNature.toLowerCase() === 'expenses') ||
              acc.accountNature.toLowerCase() === 'liability'
          );
          return filtered;
        },
        debounceTime: 300
      },
      data: [],
      renderer: (index: any, obj: any) => {
        return <DKLabel text={`${obj.name}`} />;
      }
    };
    copyOfFormState[WH_TAX_FORM_KEYS.ACCOUNT_CODE] = {
      ...copyOfFormState[WH_TAX_FORM_KEYS.ACCOUNT_CODE],
      dropdownConfig: accountDropdownConfig,
      renderer
    };
    copyOfFormState[WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE] = {
      ...copyOfFormState[WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE],
      dropdownConfig: accountDropdownConfig,
      renderer
    };
    const sectionDropdownConfig = {
      title: '',
      allowSearch: true,
      searchableKey: 'sectionName',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {},
      disableCallOnMount: true,
      renderer: (index: any, obj: any) => {
        return <DKLabel text={`${obj.sectionName}`} />;
      },
      data: deepClone(whTaxes)?.filter((item) => item.isStandardTds)
    };
    copyOfFormState[WH_TAX_FORM_KEYS.SECTION_NAME] = {
      ...copyOfFormState[WH_TAX_FORM_KEYS.SECTION_NAME],
      dropdownConfig: sectionDropdownConfig,
      renderer: (data: IWHTax) => {
        return <DKLabel text={`${data.sectionName}`} />;
      }
    };
    if (!Utility.isEmpty(props?.whTaxToEdit)) {
      Object.keys(copyOfFormState).forEach((key: string) => {
        let value = props?.whTaxToEdit?.[key];
        if (key === WH_TAX_FORM_KEYS.SECTION_NAME) {
          value = getWHTaxFromTDSId(props?.whTaxToEdit?.tdsId);
        }
        copyOfFormState[key] = {
          ...copyOfFormState[key],
          value
        };
      });
    }

    setFormState(copyOfFormState);
  };

  const getValue = (field: any) => {
    switch (field.key) {
      case WH_TAX_FORM_KEYS.ACCOUNT_CODE:
      case WH_TAX_FORM_KEYS.ACCOUNT_RECEIVABLE_CODE:
        return getAccountObjectForCode(field.value);
      default:
        return field?.value;
    }
  };

  const getAccountObjectForCode = (code: string | undefined) => {
    let account = accountsData?.content?.find(
      (item: any) => code === item.code
    );
    if (Utility.isEmpty(account)) {
      return null;
    } else {
      return account;
    }
  };
  const getWHTaxFromTDSId = (tdsId: any): IWHTax | undefined => {
    return whTaxes.find((tax) => tax.tdsId === tdsId);
  };
  /** renderer goes here */
  const overlayContainerElement: HTMLElement | null = document.getElementById(
    'secondary-overlay-container'
  );

  const popup = () => (
    <div className="transparent-background" style={{ zIndex: 9998 }}>
      <div
        className="popup-window"
        style={{
          maxWidth: 900,
          width: '60%',
          maxHeight: '95%',
          height: 350,
          padding: 0,
          paddingBottom: 60,
          overflow: 'hidden'
        }}
      >
        {getHeader()}
        <div className="row parent-width flex-wrap p-s" style={{ gap: 10 }}>
          {getForm()}
        </div>
      </div>
    </div>
  );

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={`${
              Utility.isEmptyObject(props.whTaxToEdit) ? 'Create' : 'Edit'
            } Withholding Tax`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            disabled={formBeingSaved}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            title={!formBeingSaved ? 'Save' : 'Saving...'}
            disabled={formBeingSaved}
            className={
              !formBeingSaved
                ? 'bg-button text-white'
                : 'bg-gray-300 text-white'
            }
            onClick={onSave}
          />
        </div>
      </div>
    );
  };

  const getForm = () => {
    return Object.keys(formState).map((key: string) => {
      const field = formState[key];

      if (field?.type === 'checkbox') {
        return (
          <div className="mt-m" style={{ width: '49%' }}>
            <DKCheckMark
              title={field?.label}
              isSelected={field?.value}
              className={`text-black`}
              color={'bg-button'}
              onClick={() => onFormStateChange(field)}
            />
          </div>
        );
      } else {
        return (
          <div className="mt-s" style={{ width: '49%' }}>
            <DKInput
              className="parent-width"
              value={getValue(field)}
              title={field?.label}
              canValidate={field.canValidate}
              onChange={(value: any) => onFormStateChange(field, value)}
              type={field?.type}
              renderer={field?.renderer}
              invalid={field?.invalid}
              required={field?.required}
              dropdownConfig={field?.dropdownConfig}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
            />
          </div>
        );
      }
    });
  };

  return overlayContainerElement
    ? ReactDOM.createPortal(popup(), overlayContainerElement)
    : null;
};

export default CreateWHTax;
