import {
  DKButton,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useMemo, useState } from 'react';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  COUNTRY_CODES,
  INPUT_VIEW_DIRECTION
} from '../../../Constants/Constant';
import {
  DEFAULT_ACCOUNT_MODULE,
  DefaultAccountDTO
} from '../../../Models/Common';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchDefaultAccounts,
  selectDefaultAccounts,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import { selectUndeposited } from '../../../Redux/Slices/CommonDataSlice';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import UndepositedService from '../../../Services/Undeposited';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';

type DefaultAccountsSettingsPopupProps = {
  onCancel: () => void;
  onSave: () => void;
};

const DefaultAccountsSettingsPopup = (
  props: DefaultAccountsSettingsPopupProps
) => {
  // app states
  const tenantDetails = useAppSelector(activeTenantInfo);
  const defaultAccounts = useAppSelector(selectDefaultAccounts);
  const undepositedFundsList = useAppSelector(selectUndeposited);
  const accountsList = useAppSelector(selectedAccounts);
  const dispatch = useAppDispatch();
  //states

  const [selectedUndepositAccount, setSelectedUndepositAccount] =
    useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const selectedVal = undepositedFundsList.find((item: any) => {
      return item.isUndepositedFundAccount;
    });

    if (selectedVal) {
      setSelectedUndepositAccount(selectedVal);
    }
  }, [undepositedFundsList]);

  const accountMap = useMemo(() => {
    if (accountsList && accountsList.content) {
      return accountsList.content.reduce((acc: any, item: any) => {
        acc[item.code] = item;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [accountsList]);

  const defaultAccountMap = useMemo<
    Record<keyof typeof DEFAULT_ACCOUNT_MODULE, DefaultAccountDTO>
  >(() => {
    return (
      defaultAccounts?.content?.reduce((acc, item: DefaultAccountDTO) => {
        acc[item.module] = item;
        return acc;
      }, {} as any) ?? {}
    );
  }, [defaultAccounts]);
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Set Default Accounts" className="fw-m fs-l" />
          {loading && <DKSpinner iconClassName="ic-s" className="p-xs" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    );
  };

  const callUpdateDefaultAccount = async (config: DefaultAccountDTO) => {
    try {
      setLoading(true);
      await AccountsService.updateDefaultAccount(config);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      dispatch(fetchDefaultAccounts());
    }
  };

  const getUndepositFundsSelection = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'Undeposited Funds Account'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={false}
        value={selectedUndepositAccount}
        formatter={(obj: any) => {
          return obj.name;
        }}
        required={false}
        className=""
        onChange={(obj: any) => {
          setSelectedUndepositAccount(obj);
          postUndeposited();
        }}
        dropdownConfig={{
          title: '',
          allowSearch: false,
          searchableKey: '',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data:
            undepositedFundsList && undepositedFundsList.length > 0
              ? undepositedFundsList
              : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const getGLDefaultAccount = () => {
    if (tenantDetails.country !== COUNTRY_CODES.IN) {
      return;
    }
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'RCM Account'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={loading}
        formatter={(obj: any) => {
          return obj.name;
        }}
        className="mt-l"
        value={
          accountMap[
            defaultAccountMap[DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK]
              ?.accountCode
          ]
        }
        required={false}
        onChange={(obj: any) => {
          callUpdateDefaultAccount({
            ...defaultAccountMap[DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK],
            module: DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK,
            accountCode: obj.code,
            name: obj.name
          });
        }}
        dropdownConfig={{
          title: '',
          allowSearch: true,
          searchableKey: '',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: encodeURIComponent(searchValue),
                Limit: accountsList.length > 0 ? accountsList.length : 25,
                Query: `status=ACTIVE&sortDir=ASC&sort=name`,
                includeBalance: 'false'
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              let mappedAccounts = response?.content?.map((acc: any) => {
                return {
                  ...acc,
                  label: acc.name,
                  value: acc.code
                };
              });
              return mappedAccounts ?? [];
            },
            debounceTime: 300
          },
          data:
            accountsList.content && accountsList.content.length > 0
              ? accountsList.content.map((a: any) => {
                  return {
                    ...a,
                    label: a.name,
                    value: a.code
                  };
                })
              : [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          }
        }}
      />
    );
  };

  const postUndeposited = () => {
    try {
      setLoading(true);
      UndepositedService.postUndeposited(selectedUndepositAccount)
        .then((res) => {
          setLoading(false);
          props.onSave();
        })
        .catch((e: any) => {
          setLoading(false);
          console.error(e);
        });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 450,
          width: '90%',
          // height: '300px',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 20,
          overflow: 'visible'
        }}
      >
        {getHeader()}
        <div className="parent-width p-l">
          {getUndepositFundsSelection()}
          {getGLDefaultAccount()}
        </div>
      </div>
    </div>
  );
};

export default DefaultAccountsSettingsPopup;
