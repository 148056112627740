import React, { Component } from "react";
import "../../index.css";
import { FONT_SIZE } from '../../Constants/Constants';
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import ComponentManager from "../../Manager/ComponentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import "./css/Templates.css";
import NumberUtility from "../../Utilities/NumberUtility";
import AppManager from "../../Manager/AppManager";
import { Asset } from "../../assets";

export default class EWayBillSummaryTemplate1 extends Component {

    arabicHeaderWidth = '85%'
    borderBottom = '5px solid'
    borderBottomWidth = getVW(1.5)

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.ewayBill,
            documentData: this.props.data,
            themeColor: this.props.data.themeColor,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data.ewayBill,
            documentData: nextProp.data,
            themeColor: this.props.data.themeColor,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(10) }}>
                    {this.renderTopSection()}
                    {this.renderEwayDetailsSection()}
                    {this.renderEwayPartADetailsSection()}
                    {this.renderVehicleDetailSection()}
                </div>
            </div>
        );
    }

    renderTopSection() {
        return <div className="RowDiv" style={{
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingBottom: getVW(5),
        }}>
            <div className="ColumnDiv" style={{ alignSelf: 'baseline' }}>
                {ComponentManager.getLogo(Asset.icon.e_way_bill_logo, Utility.convertToPrintScale(100, this.state.data.printScale), {
                    position: 'relative',
                })}
            </div>
            <div className="ColumnDiv" style={{
                alignItems: 'center',
            }}>
                <div className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: "black",
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.large },this.state.data.printScale)
                    }}>
                    {getLocalisedText(this.state.documentData.documentType)}
                </div>
                <QRCode
                    value={this.getValueByKey('eWayBillNo')}
                    size={120} />
            </div>
            <div className="ColumnDiv" style={{ alignSelf: 'baseline' }}>
                {ComponentManager.getLogoVisibility(this.state.documentData.showCompanyLogo, this.state.documentData.isReadOnlyMode, this.state.documentData.logo) &&
                    ComponentManager.getLogo(this.state.documentData.logo, Utility.convertToPrintScale(100, this.state.data.printScale), { position: 'relative' })}
            </div>
        </div>
    }

    renderEwayDetailsSection() {
        var eWayBillDetails = [
            { label: 'E-Way Bill No', value: ['eWayBillNo'], seperator: null },
            { label: 'E-Way Bill Date', value: ['eWayBillDate'], seperator: null },
            { label: 'Generated By', value: ['tenantGSTIN', 'tenantName' ], seperator: ' - ' },
            { label: 'IRN', value: ['irn'], seperator: null },
            { label: 'Valid From', value: ['validFrom', 'distanceInKm'], seperator: '[]' },
            { label: 'Valid Till', value: ['validTill', 'transactionType'], seperator: '[]' },
        ]

        var ewaySummary = this.getSummarySection(eWayBillDetails);

        return <div className="RowDiv parent-width pt-s pb-s" style={{
            justifyContent: 'center',
        }}>
            <div className="ColumnDiv" style={{
                width: '80%',
                paddingBottom: getVW(5),
                borderBottom: this.borderBottom,
                borderBottomWidth: this.borderBottomWidth,
                borderBottomColor: this.state.themeColor,
            }}>
                {ewaySummary}
            </div>
        </div>
    }

    renderEwayPartADetailsSection() {
        var currency = NumberUtility.getCurrency(this.state.documentData)
        var eWayBillDetails = [
            { label: 'GSTIN of Supplier', value: ['tenantGSTIN', 'tenantName'], seperator: ', ' },
            { label: 'Place of Dispatch', value: ['placeOfDispatch', 'supplierPostalCode'], seperator: ' - ' },
            { label: 'GSTIN of Recipient', value: ['contactGSTIN', 'contactName'], seperator: ', ' },
            { label: 'Place of Delivery', value: ['placeOfDelivery', 'recipientPostalCode'], seperator: ' - ' },
            { label: 'Document No.', value: ['documentNo'], seperator: null },
            { label: 'Document Date', value: ['documentDate'], seperator: null },
            { label: 'Transaction Type', value: ['transactionType'], seperator: null },
            { label: 'Value of Goods ', value: ['valueOfGoods'], seperator: null, currency: currency, decimalScale: AppManager.getDecimalScale()},
            { label: 'HSN Code', value: ['hsnCode'], seperator: null },
            { label: 'Reason for Transportation', value: ['reasonForTransportation'], seperator: null },
            { label: 'Transporter', value: ['transporterIdAndName'], seperator: null },
        ]

        var ewaySummary = this.getSummarySection(eWayBillDetails);

        return this.renderSectionContainer('Part-A', ewaySummary)
    }

    renderVehicleDetailSection() {
        var header = [
            { label: 'Mode', value: 'mode' },
            { label: 'Vehicle / Trans Doc No & Dt.', value: 'transportVehicleNoDocData' },
            { label: 'From', value: 'vehicleFrom' },
            { label: 'Entered Date', value: 'validFrom' },
            { label: 'Entered By', value: 'tenantGSTIN' },
            { label: 'CEWB No. (if any)', value: 'cewbNo' },
            { label: 'Multi Veh.Info (if any)', value: 'multiVehInfo' },
        ]

        var tableHeader = header.map(element => {
            return <td className="pl-s pr-s pt-s pb-s"
                style={{
                    textAlign: 'left',
                    verticalAlign: 'baseline'
                }}>
                {element.label}
            </td>
        });


        var tableData = []
        if (this.state.data !== undefined) {
            var rowData = header.map(element => {
                return <td className="pl-s pr-s pt-s pb-s"
                    style={{
                        textAlign: 'left',
                        verticalAlign: 'baseline'
                    }}>
                    {this.state.data[element.value] ? this.state.data[element.value] : ''}
                </td>
            })
            var row = <tr>
                {rowData}
            </tr>
            tableData.push(row)
        }

        var goodsTable = <table style={{
            width: '100%',
            borderCollapse: 'collapse',
            margin: 'auto',
            border: '1px solid black',
            textAlign: 'left',
            borderWidth: this.borderBottomWidth,
            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
        }}>
            <thead>
                <tr style={{
                    borderBottom: this.borderBottom,
                    borderBottomWidth: this.borderBottomWidth,
                }}>
                    {tableHeader}
                </tr>
            </thead>
            <tbody>
                {tableData}
            </tbody>
        </table>

        var container = <div className="parent-width">
            {goodsTable}
            <div className="ColumnDiv pt-s" style={{
                alignItems: 'center',
            }}>
                <Barcode
                    value={this.state.data.eWayBillNo}
                    height={50}
                    textAlign={'center'}/>
            </div>
        </div>

        return this.renderSectionContainer('Part-B', container)
    }


    getSummarySection(eWayBillDetails) {
        return eWayBillDetails.map(element => {
            var value = '';
            if (element.currency !== undefined) {
                value = element.currency + ' '
            }
            if (element.seperator === null) {
                if (element.value.length > 0) {
                    if(element.decimalScale !== undefined) {
                        if (this.state.data[element.value[0]] !== undefined) {
                            value = value + this.state.data[element.value[0]].toFixed(element.decimalScale);
                        }
                    }
                    else {
                        value = value + (this.state.data[element.value[0]] ? this.state.data[element.value[0]] : '');
                    }
                }
            }
            else {
                if (element.value.length > 1) {
                    element.value.forEach((key, index) => {
                        if (key.includes('.')) {
                            var objMap = key.split('.');
                            value = value + this.state.data[objMap[0]][objMap[1]];
                        }
                        else {
                            value = value + this.state.data[key];
                        }

                        if (element.seperator === ' - ') {
                            if (index !== element.value.length - 1) {
                                value = value + ' - ';
                            }
                        }
                        else if (element.seperator === ', ') {
                            if (index !== element.value.length - 1) {
                                value = value + ', ';
                            }
                        }
                        else if (element.seperator === ' & ') {
                            if (index !== element.value.length - 1) {
                                value = value + ' & ';
                            }
                        }
                        else if (element.seperator === '[]') {
                            if (index === 0) {
                                value = value + ' [';
                            }
                            else {
                                value = value + ']';
                            }
                        }
                    });
                }
            }
            if (element.label === 'Place of Dispatch' || element.label === 'Place of Delivery') {
                return this.renderEwayAddressSection(element.label, value);
            }
            return this.renderEwayTitleAndTextSection(element.label, value);
        });
    }


    renderEwayTitleAndTextSection(title, value) {
        return <div className="RowDiv pt-s pb-s">
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: "black",
                    width: '30%',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
                :
                &nbsp;
            </div>
            <div className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "black",
                    width: '70%',
                    overflowWrap: 'break-word',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}>
                {getLocalisedText(value)}
            </div>
        </div>
    }

    renderEwayAddressSection(title, value) {
        return <div className="RowDiv pt-s pb-s">
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    color: "black",
                    width: '30%',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular })
                }}>
                {getLocalisedText(title)}
                :
                &nbsp;
            </div>
            <div className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    color: "black",
                    width: '70%',
                    overflowWrap: 'break-word',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.state.data.printScale)
                }}
                dangerouslySetInnerHTML={{__html: value}}>
            </div>
        </div>
    }

    renderSectionContainer(title, container) {
        return <div className="RowDiv" style={{
            justifyContent: 'center',
        }}>
            <div className="ColumnDiv" style={{
                justifyContent: 'space-between',
                textAlign: 'center',
                width: '80%',
                paddingTop: getVW(5),
                paddingBottom: getVW(5),
                borderBottom: this.borderBottom,
                borderBottomWidth: this.borderBottomWidth,
                borderBottomColor: this.state.themeColor,
            }}>
                <div className="RowDiv">
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: 'auto',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium },this.state.data.printScale),
                            paddingBottom: getVW(5),
                        }}>
                        {getLocalisedText(title)}
                    </text>
                </div>
                {container}
            </div>
        </div>

    }

    getValueByKey(key) {
        if (this.state.data !== undefined) {
            if (this.state.data[key] !== undefined && this.state.data[key] !== null) {
                return this.state.data[key]
            }
        }
        return ''
    }

    getTaxValueByKey(tax, key) {
        if(tax !== undefined && tax !== null) {
            if(key !== undefined && key !== '') {
                if (tax[key] !== undefined && tax[key] !== null) {
                    return tax[key]
                }
            }
        }

        return ''
    }
}
