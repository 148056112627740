import {
  DKButton,
  DKCheckMark,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_resend from '../../../Assets/Icons/ic_resend.svg';
import ic_warning_red from '../../../Assets/Icons/ic_warning_red.png';
import {
  DOCUMENT_TYPE,
  DOC_TYPE,
  MODULE_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchSerialTrackingProducts,
  selectSerialTrackingProduct
} from '../../../Redux/Slices/SerialTrackingSlice';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import WarehouseService from '../../../Services/Warehouse';
import SourceDestinationWarehouseManagementRRB from '../../../SharedComponents/WarehouseManagement/SourceDestinationWarehouseManagementRRB';
import Utility, {
  deepClone,
  sortSerialNumbers
} from '../../../Utility/Utility';
import {
  fetchSerialTrackingProductsJWO,
  selectSerialTrackingProductJWO
} from '../../../Redux/Slices/SerialTrackingProductJWOSlice';
import { t } from 'i18next';

export default function AllocateJWOSerialBatchProduct(props: any) {
  const dispatch = useAppDispatch();
  const selectSerialTrackingProductData = useAppSelector(
    selectSerialTrackingProduct
  );
  const selectSerialTrackingProductJWOList = useAppSelector(
    selectSerialTrackingProductJWO
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [item, setItem] = useState(props.itemDetails);
  const [stockTransferItem, setStockTransferItem] = useState(
    props.stockTransferData
  );
  const [stockTransferDocumentSeqCodes, setStockTransferDocumentSeqCodes] =
    useState(props.stockTransferDocumentSeqCodes);
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
  const [existingAllocatedSerial, setExistingAllocatedSerial] = useState<any[]>(
    []
  );
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState(
    props.defaultProductWarehouse
  );
  const [moduleName, setModuleName] = useState(props.module);
  const [availableSerialData, setAvailableSerialData] = useState<any[]>([]);
  const [availableSerialDataNonFiltered, setAvailableSerialDataNonFiltered] =
    useState<any[]>([]);

  const [allocatedSerialData, setAllocatedSerialData] = useState<any[]>([]);
  const [allocatedTapped, setAllocatedTapped] = useState(false);
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [isAllAvailableSerialSelected, setIsAllAvailableSerialSelected] =
    useState(false);
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const [isAllSerialSelected, setIsAllSerialSelected] = useState(false);
  const productInventoryWarehouse = props?.selectedWarehouseProductsData;
  const [selectedSerial, setSelectedSerial] = useState<any[]>([]);
  const [serialData, setSerialData] = useState<any[]>([]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    item?.requiredQuantity * props.buildQuantity
  );
  const [stocksAvailable, setStockAvailable] = useState(
    item?.product?.availableQuantity
  );
  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [advancedTrackingMetaData, setAdvancedTrackingMetaData] = useState(
    item && item.advancedTrackingMetaData ? item.advancedTrackingMetaData : []
  );
  const [newSerial, setNewSerial] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [totalAllocatedQty, setTotalAllocatedQty] = useState(0);
  const warehousesData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const [sourceRRB, setSourceRRB] = useState<any>({
    srcRowCode: null,
    srcRackCode: null,
    srcBinCode: null
  });
  const [destinationRRB, setDestinationRRB] = useState<any>({
    destRowCode: null,
    destRackCode: null,
    destBinCode: null
  });
  const [isEditSourceWh, setIsEditSourceWh] = useState<boolean>(false);
  const [jwoWarehouseFetched, setJwoWarehouseFetched] = useState<any>();

  useEffect(() => {
    loadJWOWH();
    dispatch(
      fetchSerialTrackingProducts({
        productCode: props.itemDetails.documentSequenceCode
          ? props.itemDetails.documentSequenceCode
          : props.itemDetails.productId,
        enableQCWarehouse: false,
        includeRejectedWarehouse: true
      })
    );
    dispatch(fetchSerialTrackingProductsJWO(stockTransferDocumentSeqCodes));
    getActiveWarehouses();
    setDefaultActiveWarehouse();

    if (!Utility.isEmpty(props.targetWarehouse)) {
      if (!Utility.isEmpty(productInventoryWarehouse)) {
        if (productInventoryWarehouse) {
          let warehouseIdWithInventory: any[] = [];
          productInventoryWarehouse.forEach((piw: any) => {
            if (
              piw.productAvailableQuantity &&
              piw.productAvailableQuantity[item.pid] &&
              piw.productAvailableQuantity[item.pid] > 0
            ) {
              //get list of warehouse where product is available
              warehouseIdWithInventory.push(piw.id);
            }
          });
          if (warehouseIdWithInventory.length > 0) {
            //remove targetwarehouse from list
            let x = warehouseIdWithInventory.filter(
              (w: any) => w.id !== props.targetWarehouse.id
            );
            if (x && x.length > 0) {
              //set target warehouse if there are inventory in other warehouse
              let tmp = productInventoryWarehouse.filter(
                (w: any) => w.id === props.targetWarehouse.id
              );
              if (tmp && tmp.length > 0) {
                setDestinationWarehouse(tmp[0]);
                setReadOnlyTarget(true);
              }
            }
          }
        }
      }
    }
    resetAllocation();
  }, []);

  const loadJWOWH = async () => {
    let findJWOWarehouse = warehousesData?.content?.find(
      (warehouse: any) => warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
    );

    if (Utility.isEmpty(findJWOWarehouse)) {
      const jwoWH = await WarehouseService.getJWOWarehouses();
      if (Utility.isNotEmpty(jwoWH)) {
        setJwoWarehouseFetched(jwoWH);
      }
    }
  };
  useEffect(() => {
    let tempAvailableSeraialData = availableSerialDataNonFiltered.filter(
      (serial: any) => {
        return (
          serial.rowCode === sourceRRB?.srcRowCode &&
          serial.rackCode === sourceRRB?.srcRackCode &&
          serial.binCode === sourceRRB?.srcBinCode
        );
      }
    );
    if (!isEditSourceWh) {
      setAvailableSerialData(tempAvailableSeraialData);
    }
    setIsEditSourceWh(true);
  }, [sourceRRB]);

  useEffect(() => {
    if (!Utility.isEmpty(warehouseData)) {
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        // will use later
        // getJWDestinationWarehouse();
      }
    }
  }, [warehouseData]);

  useEffect(() => {}, [selectSerialTrackingProductData]);

  useEffect(() => {
    const serialProducts =
      selectSerialTrackingProductJWOList
        ?.filter(
          (w: any) => w.productVariantCode === props.itemDetails?.productId
        )
        .map((warehouse: any) => {
          return {
            ...warehouse,
            code: warehouse.warehouseCode
          };
        }) || [];

    setLocalWarehouse(sortSerialNumbers(serialProducts));
  }, [selectSerialTrackingProductJWOList]);

  useEffect(() => {
    let warehouseTempData =
      props.hasMultilpleWarehouseData &&
      props.hasMultilpleWarehouseData.length > 0
        ? props.hasMultilpleWarehouseData
        : [
            {
              warehouseCode: defaultProductWarehouse,
              availableQty: 0,
              quantity: 0
            }
          ];

    setWarehouseData(warehouseTempData);
  }, [defaultProductWarehouse, defaultProductWarehouse]);

  useEffect(() => {
    if (
      moduleName === MODULE_TYPE.SELL &&
      !Utility.isEmpty(localWarehouse) &&
      !Utility.isEmpty(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
      )
    ) {
      let updateSerial: any[] = [];
      let serialData: any[] = [];
      if (Utility.isEmpty(availableSerialData) && !allocatedTapped) {
        let srcWh = getActiveWarehouses()?.find(
          (ele: any) =>
            ele.code ==
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcWarehouseCode
        );
        let destWh = getActiveWarehouses()?.find(
          (ele: any) =>
            ele.code ==
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destWarehouseCode
        );
        let result = localWarehouse;

        serialData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
        updateSerial = serialData;
        if (
          stockTransferItem &&
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData &&
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.advancedTrackingData?.length > 0 &&
          serialData &&
          serialData.length > 0
        ) {
          const remainingAvailableSerial = serialData.filter(
            (e: any) =>
              !stockTransferItem[
                currentIndex
              ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
                (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
              )
          );
          const allocatedAvailableSerial = serialData.filter((e: any) =>
            stockTransferItem[
              currentIndex
            ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
              (a: any) => {
                if (a.sourceSerialBatchNumber === e.serialBatchNumber) {
                  return {
                    ...e,
                    selected: !e?.selected
                  };
                }
              }
            )
          );

          const allocatedAvailableSerialData = serialData.filter((e: any) =>
            stockTransferItem[
              currentIndex
            ].stockTransferWarehouseInventoryData?.advancedTrackingData.some(
              (a: any) => a.sourceSerialBatchNumber === e.serialBatchNumber
            )
          );

          updateSerial = remainingAvailableSerial;
          if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
            const newArr1 = allocatedAvailableSerial.map((v) => ({
              ...v,
              allocated: true,
              selected: true
            }));
            setAllocatedSerialData(newArr1);
            setSerialData(newArr1);
            setTotalAllocatedQty(newArr1.length);
          }
          let filteredRacksDataForSourceWarehouse: any[] =
            srcWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
          ) {
            filteredRacksDataForSourceWarehouse =
              srcWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredBinDataForSourceWarehouse: any[] =
            srcWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
          ) {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRackCode
              );
          } else {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredRacksDataForDestinationWarehouse: any[] =
            destWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRowCode
          ) {
            filteredRacksDataForDestinationWarehouse =
              destWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          let filteredBinDataForDestinationWarehouse: any[] =
            destWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRackCode
          ) {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRackCode
              );
          } else {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          srcWh = {
            ...srcWh,
            srcRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRowCode ?? null,
            srcRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRackCode ?? null,
            srcBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcBinCode ?? null,
            filteredRackInfos: filteredRacksDataForSourceWarehouse,
            filteredBinInfos: filteredBinDataForSourceWarehouse
          };
          setSourceWarehouse(srcWh);
          destWh = {
            ...destWh,
            destRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode ?? null,
            destRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode ?? null,
            destBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destBinCode ?? null,
            filteredRackInfos: filteredRacksDataForDestinationWarehouse,
            filteredBinInfos: filteredBinDataForDestinationWarehouse
          };
          setDestinationWarehouse(destWh);
          setIsEditSourceWh(true);
          setSourceRRB({
            ...srcWh,
            srcRowCode: srcWh?.srcRowCode ?? null,
            srcRackCode: srcWh?.srcRackCode ?? null,
            srcBinCode: srcWh?.srcBinCode ?? null
          });
          setDestinationRRB({
            ...destWh,
            destRowCode: destWh?.destRowCode ?? null,
            destRackCode: destWh?.destRackCode ?? null,
            destBinCode: destWh?.destBinCode ?? null
          });

          if (!Utility.isEmpty(props.stockTransferData)) {
            let filterData: any = [];
            stockTransferItem.forEach((element: any, index: any) => {
              if (index !== currentIndex) {
                filterData.push(element);
              }
            });
            filterData = filterData.filter(
              (ele: any) =>
                ele.productVariantCode ==
                  props.itemDetails.documentSequenceCode &&
                !Utility.isEmpty(ele.stockTransferWarehouseInventoryData) &&
                ele.stockTransferWarehouseInventoryData?.srcWarehouseCode ==
                  srcWh?.code
            );
            let allocatedSerial = filterData.flatMap(
              (ele: any) =>
                ele.stockTransferWarehouseInventoryData.advancedTrackingData
            );
            if (!Utility.isEmpty(allocatedSerial)) {
              let serials: any = [];
              allocatedSerial.forEach((element: any) => {
                serials.push(element.sourceSerialBatchNumber);
              });
              setExistingAllocatedSerial(serials);
              let availableSerials: any = [];
              updateSerial.forEach((ele: any) => {
                if (!serials.includes(ele.serialBatchNumber)) {
                  availableSerials.push(ele);
                }
              });

              if (!Utility.isEmpty(availableSerials)) {
                updateSerial = availableSerials;
              }
            }
          }
          setAvailableSerialDataNonFiltered([
            ...allocatedAvailableSerialData,
            ...updateSerial
          ]);
        }

        if (updateSerial && updateSerial.length > 0) {
          setAvailableSerialData(updateSerial);
        }
      }
    } else if (!Utility.isEmpty(localWarehouse)) {
      setAvailableSerialData(localWarehouse);
      setAvailableSerialDataNonFiltered(localWarehouse);
    }
  }, [localWarehouse]);

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content.filter(
        (item: any) => item.active && !item.rejected
      );
    if (activeWarehouses && activeWarehouses.length > 0) {
      let selectedWarehouse = activeWarehouses.filter(
        (warehouse: any) => warehouse.primary === true
      );
      if (selectedWarehouse[0] !== undefined && selectedWarehouse[0] !== null) {
        setDefaultAllocationWarehouse(selectedWarehouse[0].code);
        setSelectedWarehouse(selectedWarehouse[0]);
      }
    }
  };

  const selectAllAvailableSerial = () => {
    let localData = deepClone(availableSerialData);
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        selected: !isAllAvailableSerialSelected
      };
    });
    setIsAllAvailableSerialSelected(!isAllAvailableSerialSelected);
    setSelectedSerial(serialTrackingData);
    setAvailableSerialData(serialTrackingData);
  };

  const selectAllSelectedSerial = () => {
    const localData = deepClone(allocatedSerialData);
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        allocated: !isAllSerialSelected
      };
    });
    if (!isAllSerialSelected) {
      setTotalAllocatedQty(serialTrackingData.length);
    } else {
      setTotalAllocatedQty(0);
    }
    setIsAllSerialSelected(!isAllSerialSelected);
    setSerialData(serialTrackingData);
    setAllocatedSerialData(serialTrackingData);
  };

  const allocateSerialNumber = () => {
    const localData = deepClone(selectedSerial);
    const selectedSerialNumber = localData.filter(
      (option: any) => option.selected
    );
    const getSelectedSerialNumber = [
      ...allocatedSerialData,
      ...selectedSerialNumber
    ];
    const updatedSerialData = selectedSerial.filter(
      (x) =>
        !selectedSerialNumber.filter(
          (y) => y.serialBatchNumber === x.serialBatchNumber
        ).length
    );

    setAllocatedTapped(true);
    setIsAllAvailableSerialSelected(false);
    setAllocatedSerialData((prevState: any[]) => getSelectedSerialNumber);
    setAvailableSerialData(updatedSerialData);
    setSerialData(getSelectedSerialNumber);
  };

  const getSelectedSerial = (item: any, index: any) => {
    const localData = [...availableSerialData];
    localData[index] = {
      ...localData[index],
      selected: !localData[index]?.selected
    };
    setSelectedSerial(localData);
    setAvailableSerialData(localData);
  };

  const getAllocatedSerial = (item: any, index: any) => {
    const localData = [...allocatedSerialData];
    localData[index] = {
      ...localData[index],
      allocated: !localData[index]?.allocated
    };
    const totalAllocatedQuantity: any[] = localData.filter(
      (option: any) => option.allocated
    );
    setTotalAllocatedQty(totalAllocatedQuantity.length);
    setSerialData(localData);
    setAllocatedSerialData(localData);
  };

  const onResetClick = () => {
    resetAllocation();
    setAvailableSerialData(availableSerialDataNonFiltered);
  };

  const resetAllocation = () => {
    setAllocatedTapped(false);
    setIsAllSerialSelected(false);
    setIsAllAvailableSerialSelected(false);
    setAllocatedTapped(false);
    setAllocatedSerialData([]);
    setSelectedSerial([]);
    setAvailableSerialData([]);
    setSerialData([]);
  };

  const getSelectedWarehousesValue = (code: string) => {
    if (Utility.isEmpty(code)) {
      return [];
    }
    let filtered =
      localWarehouse &&
      localWarehouse.filter((warehouse: any) => warehouse.code === code);
    if (filtered && filtered.length > 0) {
      let first = filtered[0];
      return [localWarehouse.indexOf(first)];
    } else {
      return [];
    }
  };

  const getActiveWarehouses = () => {
    if (!Utility.isEmpty(warehouseData)) {
      let warehouseList = warehousesData?.content?.filter(
        (warehouse: any) =>
          warehouse.warehouseType !== 'REJECTED' && warehouse.active
      );
      if (!Utility.isEmpty(warehousesData?.content)) {
        let findJWOWarehouse = warehousesData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;

        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      return warehouseList && warehouseList.length > 0 ? warehouseList : [];
    } else {
      return [];
    }
  };

  const validateDetail = () => {
    if (serialData.length === item.requiredQuantity) {
      return true;
    } else {
      showAlert('Error', t('JOB_WORK_OUT.ALLOCATED_QTY_ERROR_MSG'));
      return false;
    }
  };

  const onSave = () => {
    let advancedData: any[] = [];
    let advancedDetailData: any[] = [];
    serialData.forEach((data: any) => {
      let d = {
        qtyToTransfer: 1,
        sourceSerialBatchNumber: data.serialBatchNumber,
        destSerialBatchNumber: data.serialBatchNumber
      };
      const details = {
        batchSize: data.batchSize,
        serialBatchNumber: data.serialBatchNumber,
        warehouseCode: data.code,
        productVariantCode: data.productVariantCode,
        rawMaterialQtyToConsume: data.rawMaterialQtyToConsume || 1
      };
      advancedData.push(d);
      advancedDetailData.push(details);
    });
    if (props.onSerialSave) {
      if (validateDetail()) {
        let totalTransferQty = 0;
        totalTransferQty = serialData.length;
        let warehouseDetail = {
          srcWarehouseCode: sourceWarehouse.code,
          destWarehouseCode: destinationWarehouse.code,
          destBinCode: destinationRRB.destBinCode ?? null,
          destRackCode: destinationRRB.destRackCode ?? null,
          destRowCode: destinationRRB.destRowCode ?? null,
          srcBinCode: sourceRRB?.srcBinCode ?? null,
          srcRackCode: sourceRRB?.srcRackCode ?? null,
          srcRowCode: sourceRRB?.srcRowCode ?? null,
          quantity: totalTransferQty,
          advancedTrackingType: TRACKING_TYPE.SERIAL,
          advancedTrackingData: advancedData,
          productCode: item?.documentSequenceCode
        };
        let data: any = {};
        data['stockTransferWarehouseInventoryData'] = warehouseDetail;
        data['serialData'] = advancedDetailData;

        props.onSerialSave(data);
      }
    }
  };

  const getHeader = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-between p-h-r p-v-s bg-gray1">
          <div className="row width-auto">
            <DKLabel
              text={t('JOB_WORK_OUT.JWO_ALLOCATE_SERIAL_TRACT')}
              className="fw-m fs-l"
            />
          </div>
          <div className="row width-auto">
            <DKButton
              title="Cancel"
              className="bg-white border-m mr-r"
              onClick={props.onClose}
            />
            <DKButton
              title={t('JOB_WORK_OUT.ALLOCATE')}
              className={
                availableSerialData?.filter((serial: any) => serial.selected)
                  ?.length > 0
                  ? 'bg-button text-white mr-r'
                  : 'bg-gray-300 text-white mr-r'
              }
              disabled={
                availableSerialData?.filter((serial: any) => serial.selected)
                  ?.length === 0
              }
              onClick={() => {
                allocateSerialNumber();
              }}
            />

            <DKButton
              title={t('DRAFTS.SAVE')}
              disabled={serialData.length === 0}
              className={
                serialData.length > 0
                  ? 'bg-button text-white'
                  : 'bg-gray-300 text-white'
              }
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      );
    }
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-between p-h-r p-v-s mt-5">
          <div className="column width-auto ">
            <DKLabel
              text={t('JOB_WORK_OUT.PRODUCT_NAME')}
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.name} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text={t('JOB_WORK_OUT.PRODUCT_CODE')}
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel text={item?.documentSequenceCode} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text={t('JOB_WORK_OUT.AVAILABLE_QTY')}
              className="fs-r pl-2 font-medium text-blue"
            />
            <DKLabel
              text={availableSerialData?.length || 0}
              className="fs-r pl-2"
            />
          </div>
          {item.requiredQuantity !== undefined &&
            item.requiredQuantity >= 0 && (
              <div className="column width-auto">
                <DKLabel
                  text={t('JOB_WORK_OUT.REQ_QTY')}
                  className="fs-r pl-2 font-medium text-blue"
                />
                <DKLabel text={item.requiredQuantity} className="fs-r pl-2" />
              </div>
            )}
        </div>
      );
    }
  };

  const getBodySection = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s">
        <div className="flex justify-space-around mt-5">
          <div className="flex flex-col w-96">
            <div className="flex align-items-center label">
              <DKCheckMark
                className="fs-r font-medium parent-width"
                title={t('JOB_WORK_OUT.SERIAL_NO')}
                isSelected={isAllAvailableSerialSelected}
                onClick={() => {
                  selectAllAvailableSerial();
                }}
              />
            </div>
            <hr className="w-60 mt-3" />
            {sortSerialNumbers(availableSerialData)?.map((item, i) => {
              return (
                <div className="flex flex-row mt-3" style={{ minWidth: '15%' }}>
                  <DKCheckMark
                    className="parent-width"
                    title={item.serialBatchNumber}
                    isSelected={item?.selected}
                    onClick={() => {
                      getSelectedSerial(item, i);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex flex-col">
            <div className="flex align-items-center label">
              <DKCheckMark
                className="fs-r font-medium parent-width"
                title={t('JOB_WORK_OUT.ALLOCATED_SER_NO')}
                isSelected={isAllSerialSelected}
                onClick={() => {
                  selectAllSelectedSerial();
                }}
              />
              {allocatedSerialData && allocatedSerialData.length > 0 && (
                <DKIcon
                  src={ic_resend}
                  onClick={() => {
                    onResetClick();
                  }}
                  title={t('JOB_WORK_OUT.REST_ALLOCATION')}
                  className="ic-xs cursor-hand"
                  style={{ position: 'relative', top: '2px', left: '15px' }}
                />
              )}
            </div>
            <hr className="w-60 mt-3" />
            {allocatedSerialData &&
              allocatedSerialData.length > 0 &&
              allocatedSerialData.map((item, i) => {
                return (
                  <div
                    className="flex flex-row mt-3"
                    style={{ minWidth: '15%' }}
                  >
                    <DKCheckMark
                      className="parent-width"
                      title={item.serialBatchNumber}
                      isSelected={item.allocated}
                      onClick={() => {
                        getAllocatedSerial(item, i);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1000,
          width: '100%',
          // height: "85%",
          minHeight: '80%',
          maxHeight: '95%',
          height: 565,
          padding: 0,
          paddingBottom: 60
        }}
      >
        {getHeader()}
        {getHeaderSection()}
        {moduleName === MODULE_TYPE.SELL && getBodySection()}
      </div>
    </div>
  );
}
