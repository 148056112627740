import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import BanksService from '../../Services/Banks';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchBankTransactions = createAsyncThunk(
  'fetchBankTransaction',
  async (bankCode: any) => {
    const response = await BanksService.fetchBankTransactionsList(bankCode);
    return response;
  }
);

export const BankTransactionsSlice = createSlice({
  name: 'BankTransactions',
  initialState,
  reducers: {
    addBankTransactionsColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addBankTransacrtion: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankTransactions.pending, (state) => {
        console.log('Status Pending');
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBankTransactions.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectedBankTransactions = (state: RootState) =>
  state.bankTransactions.data;
export const { addBankTransactionsColumnConfig } =
  BankTransactionsSlice.actions;
// export const selectBankTransactionsColumnConfig = (state: RootState) =>
//   state.bankTransactions.columnConfig;
export const selectBankTransactionsColumnConfig = (state: RootState) =>
  columnConfig;
export const selectedBankTransactionByStatementColumnConfig = (
  state: RootState
) => state.bankTransactions.columnConfig;
export const selectBankTransactionsColumnConfigTableId = (state: RootState) =>
  state.bankTransactions.configTableId;

const columnConfig = [
  {
    name: 'Date',
    type: 'text',
    index: 0,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'date',
    key: 'date',
    id: 'date',
    allowFilter: false
  },
  {
    name: 'Payee',
    type: 'text',
    index: 1,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'payee',
    key: 'payee',
    id: 'payee',
    allowFilter: false
  },
  {
    name: 'Description',
    type: 'text',
    index: 2,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'description',
    key: 'description',
    id: 'description',
    allowFilter: false
  },
  {
    name: 'Withdrawn',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'withdrawn',
    key: 'withdrawn',
    id: 'withdrawn',
    allowFilter: false
  },
  {
    name: 'Deposit',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'deposit',
    key: 'deposit',
    id: 'deposit',
    allowFilter: false
  },
  {
    name: 'Transaction Type',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'CREDIT',
        name: 'Credit',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'DEBIT',
        name: 'Debit',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'ALLTYPE',
        name: 'All',
        color: 'data-grid-badge-color-3'
      }
    ],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'cdType',
    key: 'cdType',
    id: 'cdType',
    allowFilter: true
  },
  {
    name: 'Status',
    type: 'select',
    index: 6,
    options: [
      {
        id: 'MATCHED',
        name: 'Matched',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'UNMATCHED',
        name: 'Unmatched',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'RECONCILED',
        name: 'Reconciled',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'ALLSTATUS',
        name: 'All status',
        color: 'data-grid-badge-color-4'
      }
    ],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    key: 'status',
    id: 'status',
    allowFilter: true
  }
];
