import { reject } from 'lodash';
import ApiConstants from '../Constants/ApiConstants';
import { LocationDTO } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface TeamAPIConfig {
  Limit?: number;
  Page?: number;
  Query?: any;
  Search: any;
}

const defaultConfig: TeamAPIConfig = {
  Limit: 25,
  Page: 0,
  Search: ''
};
class BookKeeperTeamService {
  static apiConfig: TeamAPIConfig = defaultConfig;

  static getTeamMembers(payload: any) {
    const queryString: string = `?clientDefaultUserId=${payload?.clientDefaultUserId}&clientTenantId=${payload?.clientTenantId}`;

    const finalEndpoint: string =
      ApiConstants.URL.BOOKKEEPER.TEAM.GET_TEAM_MEMBERS + queryString;

    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static saveTeam(teamObj: any, isManageClient: boolean) {
    let url =
      'tenants/bookkeeper?manageClient=' + (isManageClient ? 'true' : 'false');
    return http
      .post(url, teamObj)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateTeam(teamObj: any, isManageClient: boolean) {
    let url =
      'tenants/bookkeeper?manageClient=' + (isManageClient ? 'true' : 'false');
    return http
      .put(url, teamObj)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getTeamTenants() {
    let url = 'tenants/bookkeeper?type=TEAM';
    return http
      .get(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static transferOwnership(bkTeamId: string) {
    let url = `tenants/bookkeeper/transfer-ownership?bkTeamId=${bkTeamId}`;
    return http
      .put(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static activateDeactivateTeammate(id: string, action: string) {
    let url = `tenants/bookkeeper/team-status?id=${id}&status=${action}`;
    return http
      .put(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static resendInviteToTeammates(teammate: any) {
    let url = `tenants/bookkeeper/resend-invite`;
    return http
      .post(url, teammate)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getTeamUserDetails(userId: any) {
    let url = `${ApiConstants.URL.BOOKKEEPER.TEAM.GET_TEAM_USER_DETAIL}?userId=${userId}`;
    return http2
      .get(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
  static fetchClientsForTeam(bkTeamId: string) {
    let url = `tenants/bookkeeper/team-member/clients?bkTeamId=${bkTeamId}`;
    return http
      .get(url)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.resolve(err);
        }
      )
      .catch((err: any) => {
        return Promise.resolve(err);
      });
  }
}

export default BookKeeperTeamService;
