import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import { useState } from 'react';
import {
  APP_NAME,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import Utility from '../../Utility/Utility';
import {
  defaultLabelTemplateSettings,
  templateFormats
} from './LabelTemplateHelper';

interface CreateLabelTemplateProps {
  cancelPopup: () => void;
  createTemplate: (name: string) => void;
  documentType: string;
}

const CreateLabelTemplate: React.FC<CreateLabelTemplateProps> = ({
  cancelPopup,
  createTemplate,
  documentType
}: CreateLabelTemplateProps) => {
  const [labelTemplateData, setLabelTemplateData] = useState<any>({});
  const [labelFormat, setLabelFormat] = useState<any>(null);

  const onFormUpdate = (newData: any) => {
    setLabelTemplateData((oldData: any) => ({ ...oldData, ...newData }));
  };

  const saveTemplate = () => {
    let templateToSave = {
      ...labelTemplateData,
      appName: APP_NAME,
      defaultTemplate: false,
      documentType: documentType,
      templateData: [
        {
          columns: [
            {
              items: [],
              rowIndex: 0,
              colIndex: 0
            }
          ]
        }
      ],
      labelSettings: defaultLabelTemplateSettings
    };

    if (labelFormat) {
      templateToSave = {
        ...templateToSave,
        templateData: labelFormat.templateData
      };
    }
    createTemplate(templateToSave);
    setLabelTemplateData({});
  };

  return (
    <PopupWrapper
      type={POPUP_TYPE.POPUP}
      title={`Save Template As`}
      btnList={[
        {
          title: 'Cancel',
          class: 'bg-gray1 border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: 'Save',
          class: 'bg-app text-white',
          clickAction: POPUP_CLICK_TYPE.CONFIRM
        }
      ]}
      clickAction={({ type }: any) => {
        if (type === POPUP_CLICK_TYPE.CLOSE_POPUP) {
          cancelPopup();
        } else if (
          Utility.isNotEmpty(labelTemplateData.templateName) &&
          type === POPUP_CLICK_TYPE.CONFIRM
        ) {
          saveTemplate();
        }
      }}
      height={'auto'}
      width={400}
      disableClickOutside={true}
    >
      <DKInput
        titleStyle={{ color: 'gray', fontSize: 12 }}
        className={'mb-3'}
        value={labelTemplateData?.templateName}
        formatter={(obj: any) => {
          return obj;
        }}
        title="Label Template Name"
        type={INPUT_TYPE.TEXT}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={true}
        canValidate={false}
        onChange={(value: any) => onFormUpdate({ templateName: value })}
      />
      <DKInput
        titleStyle={{ color: 'gray', fontSize: 12 }}
        className={'mb-3'}
        value={labelTemplateData?.description}
        formatter={(obj: any) => {
          return obj;
        }}
        title="Label Template Description"
        type={INPUT_TYPE.TEXT}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        canValidate={false}
        onChange={(value: any) => onFormUpdate({ description: value })}
      />
      <DKInput
        titleStyle={{ color: 'gray', fontSize: 12 }}
        className={'mb-5'}
        value={labelFormat}
        formatter={(obj: any) => {
          return obj?.templateName;
        }}
        title="Label Template Format"
        type={INPUT_TYPE.DROPDOWN}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        canValidate={false}
        onChange={(value: any) => setLabelFormat(value)}
        dropdownConfig={{
          title: 'Select Template Format',
          allowSearch: true,
          searchableKey: 'templateName',
          style: { minWidth: 250 },
          className: 'shadow-m width-auto',
          searchApiConfig: null,
          data:
            (documentType &&
              templateFormats[documentType as keyof typeof templateFormats]) ||
            [],
          renderer: (index: any, obj: any) => {
            return <DKLabel key={index} text={`${obj.templateName}`} />;
          }
        }}
      />
    </PopupWrapper>
  );
};

export default CreateLabelTemplate;
