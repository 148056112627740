import { MODULES_NAME, EXPORT_ERROR } from '../Constants/Constant';
import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';
import { saveAs } from 'file-saver';
import { EXPORTED_FILES_TYPE_EXTENSION } from '../Constants/Enum';
import { showAlert, removeLoader } from 'deskera-ui-library';
import Utility from '../Utility/Utility';
import { showToast } from 'deskera-doc-builder-lib';
import { TOAST_TYPE } from 'deskera-doc-builder-lib';

export interface EximAPIConfig {
  SearchTerm?: string;
  Query?: string;
  QueryParam?: any;
}

const defaultConfig: EximAPIConfig = {
  SearchTerm: '',
  Query: '',
  QueryParam: ''
};
class EximService {
  static exportFiles(
    format: EXPORTED_FILES_TYPE_EXTENSION,
    module: MODULES_NAME,
    filterConfig?: any
  ) {
    const fileType = format;
    const fileName = `${module.toLowerCase()}.${fileType.toLowerCase()}`;
    let finalEndpoint =
      ApiConstants.URL.EXIM.EXPORT +
      `?format=${format}&module=${module}&isNewUi=true`;
    if (filterConfig?.Query) {
      finalEndpoint += `&query=${filterConfig.Query}`;
    }
    if (!Utility.isEmpty(filterConfig?.QueryParam)) {
      finalEndpoint += filterConfig?.QueryParam;
    }
    if (!Utility.isEmpty(filterConfig?.SearchTerm)) {
      finalEndpoint += `&search=${filterConfig?.SearchTerm}`;
    }
    return http
      .get(finalEndpoint, {
        responseType: 'blob'
      })
      .then((res: any) => {
        const reader = new FileReader();

        reader.onload = function(event) {
          let blobData = String(event?.target?.result);
          try{
            showAlert('Alert', JSON.parse(blobData).exportMessage);
          }catch(e){
            saveAs(res,fileName)
          }
        };
        reader.readAsText(res);        
      })
      .catch((err: any) => {
        console.error('Error while trying to get account: ', err);
        if (
          err?.status === EXPORT_ERROR.EXPORT_LOCK_CODE ||
          err?.code === EXPORT_ERROR.EXPORT_LOCK_CODE
        ) {
          removeLoader();
          showAlert('Error', EXPORT_ERROR.EXPORT_LOCK_WARNING_MSG);
        } else {
          return Promise.reject(err);
        }
      });
  }

  static exportBankFiles(accountCode: any, format: any, module: any) {
    const fileType = format;
    const fileName = `${module.toLowerCase()}.${fileType.toLowerCase()}`;
    const finalEndpoint =
      ApiConstants.URL.EXIM.EXPORT_BANK +
      `?accountCode=${accountCode}&format=${format}&module=${module}`;
    return http
      .get(finalEndpoint)
      .then((res: any) => {
        showAlert('Success', res.message);
      })
      .catch((err: any) => {
        console.error('Error while trying to export: ', err);
        return Promise.reject(err);
      });
  }

  static exportDataFiles(
    format: EXPORTED_FILES_TYPE_EXTENSION,
    moduleName: MODULES_NAME,
    filterConfig?: any
  ) {
    const fileType = format;
    const fileName = `${moduleName?.toLowerCase()}.${fileType.toLowerCase()}`;
    let finalEndpoint = `${ApiConstants.URL.REPORTS_URLS.DATE_EXPORT}?format=${format}&module=${moduleName}`;
    if (filterConfig?.Query) {
      finalEndpoint += `&query=${filterConfig.Query}`;
    }
    if (!Utility.isEmpty(filterConfig?.QueryParam)) {
      finalEndpoint += filterConfig?.QueryParam;
    }
    if (!Utility.isEmpty(filterConfig?.SearchTerm)) {
      finalEndpoint += `&search=${filterConfig?.SearchTerm}`;
    }

    return http
      .get(finalEndpoint, {
        responseType: 'blob'
      })
      .then((res: any) => {
        if (res instanceof Blob && res.type !== 'application/json') {
          saveAs(res, fileName);
        } else {
          return Promise.resolve(res);
        }
      })
      .catch((err: any) => {
        console.error('Error while trying to get account: ', err);
        return Promise.reject(err);
      });
  }
}
export default EximService;
