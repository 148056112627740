import {
  DKButton,
  DKLabel,
  DKInput,
  INPUT_TYPE,
  Toggle,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { DOCUMENT_MODE, INPUT_VIEW_DIRECTION } from '../../Constants/Constant';
import ContactService from '../../Services/Contact';
import Utility from '../../Utility/Utility';

function GetEmailForPayment(props: any) {
  //state
  const [emailValue, setEmailValue] = useState(props?.contact?.emailId);
  const [isSaveTapped, setIsSaveTapped] = useState(false);
  const [isAutoChargePopupOn, setIsAutoChargepopupOn] = useState(
    props?.paymentInformationDetials?.autoCharge &&
      props.paymentInformationDetials?.connectionType === 'STRIPE'
      ? true
      : false
  );
  const [loading, setLoading] = useState(false);

  // effects
  useEffect(() => {
    if (props?.paymentInformationDetials?.autoCharge) {
      setIsAutoChargepopupOn(props?.paymentInformationDetials?.autoCharge);
    }
  }, [props?.paymentInformationDetials]);

  const updateContact = (isAutoCharge: boolean) => {
    //update contact
    const payload = {
      ...props?.contact,
      emailId: emailValue
    };

    setLoading(true);
    ContactService.updateContact(payload, props?.contact?.id)
      .then((res: any) => {
        setLoading(false);
        props.onSave({
          email: emailValue,
          isAutoCharged: isAutoCharge
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const getHeader = () => {
    return (
      <div
        className="row p-h-r p-v-s bg-gray1"
        style={{ height: 45, justifyContent: 'space-between' }}
      >
        <DKLabel
          text={`${
            props.paymentInformationDetials?.connectionType === 'STRIPE'
              ? 'Add email/autocharge for contact'
              : 'Add email for contact'
          }`}
          className="fw-m fs-l"
        />
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="mr-r border bg-gray-100"
            onClick={() => {
              if (!loading) {
                props.onCancel({
                  email: emailValue,
                  isAutoCharged: isAutoChargePopupOn
                });
              }
            }}
          />
          <DKButton
            title={'Save'}
            className="bg-app text-white"
            onClick={() => {
              if (loading) {
                return;
              }
              setIsSaveTapped(true);

              if (!Utility.isValidEmail(emailValue)) {
                showAlert('Oops', 'Please enter a valid email');
                return;
              }

              if (
                props.paymentInformationDetials?.connectionType !== 'STRIPE' &&
                Utility.isEmpty(props?.contact?.emailId)
              ) {
                updateContact(false);
              } else {
                props.onSave({
                  email: emailValue,
                  isAutoCharged: false
                });
              }

              if (
                props.paymentInformationDetials?.connectionType === 'STRIPE' &&
                Utility.isEmpty(props?.contact?.emailId)
              ) {
                updateContact(isAutoChargePopupOn);
              } else {
                props.onSave({
                  email: emailValue,
                  isAutoCharged: isAutoChargePopupOn
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getView = () => {
    const name = `<span class="fw-m">${props.contact?.name}</span>`;
    return (
      <div className="p-xl parent-width column">
        {props.paymentInformationDetials?.connectionType === 'STRIPE' && (
          <div className="row justify-content-between mb-l">
            <DKLabel text="Enabling this will autocharge the customer." />
            <Toggle
              className=""
              color="bg-app"
              isOn={isAutoChargePopupOn}
              onChange={() => {
                setIsAutoChargepopupOn(!isAutoChargePopupOn);
              }}
            />
          </div>
        )}

        {Utility.isEmpty(props?.contact?.emailId) && (
          <DKInput
            className={''}
            type={INPUT_TYPE.EMAIL}
            // icon={DKIcons.ic_email}
            title={`Please provide email address for selected contact: ${name}`}
            value={emailValue}
            canValidate={
              isSaveTapped &&
              Utility.isEmpty(emailValue) &&
              !Utility.isValidEmail(emailValue)
            }
            onChange={(value: any) => {
              setIsSaveTapped(false);
              setEmailValue(value);
            }}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window overflow-hidden"
        style={{
          maxWidth: 520,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          padding: 0,
          position: 'fixed'
        }}
      >
        {getHeader()}
        {getView()}
      </div>
    </div>
  );
}

export default GetEmailForPayment;
