import React, { Component, useEffect, useState } from 'react';
import { DKLabel, MultiBar, COLORS } from 'deskera-ui-library';
import DKDropdown from './DKDropdown';
import {
  DATE_FORMAT,
  GOOGLE_NO_TRANSLATE_CLASS
} from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import DashboardService from '../../Services/Dashboard';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  setBuyFilterConfig,
  getBuyDashboard,
  selectBuyDashboard
} from '../../Redux/Slices/DashboardSlice';
import { useTranslation } from 'react-i18next';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import NumberFormatService from '../../Services/NumberFormat';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

function BillsSummary(props: any) {
  const dispatch = useAppDispatch();
  const billsSummary = useAppSelector(selectBuyDashboard);
  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(0);
  const [parsedData, setParsedData] = useState<any>([
    {
      title: 'Paid',
      value: 15,
      color: COLORS.chart.blue
    },
    {
      title: 'Unpaid',
      value: 35,
      color: COLORS.chip.blue
    }
  ]);
  const { t, i18n } = useTranslation();
  const tenantInfo = useAppSelector(activeTenantInfo);

  const filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_30_DAYS'),
      value: 'LAST30'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_MONTH'),
      value: 'CURRENT_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_MONTH'),
      value: 'LAST_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];

  const getBillsReport = async () => {
    try {
      var date = new Date();
      var config = {
        duration: filters[selectedFilterIndex].value,
        type: 'PURCHASE',
        fromDate: Utility.formatDate(date, DATE_FORMAT.DD_MM_YYYY)
      };
      dispatch(setBuyFilterConfig(config));
      DashboardService.apiConfig = config;
      const data = await dispatch(getBuyDashboard());
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  const getPaidBillSummary = () => {
    return !Utility.isEmpty(billsSummary.paid) || Number(billsSummary.paid)
      ? billsSummary.paid
      : 0;
  };

  const getUnpaidBillSummary = () => {
    return !isNaN(billsSummary.totalAmount - billsSummary.paid)
      ? billsSummary.totalAmount - billsSummary.paid
      : 0;
  };

  const getOverDueBillSummary = () => {
    return !Utility.isEmpty(billsSummary.overDue) ||
      Number(billsSummary.overDue)
      ? billsSummary.overDue
      : 0;
  };

  useEffect(() => {
    if (
      GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.BILL.VIEW
      )
    ) {
      getBillsReport();
    }
  }, [selectedFilterIndex]);

  useEffect(() => {
    setParsedData([
      {
        title: 'Paid',
        value: getPaidBillSummary(),
        color: COLORS.chart.blue
      },
      {
        title: 'Unpaid',
        value: getUnpaidBillSummary(),
        color: COLORS.chip.blue
      },
      {
        title: 'Over Due',
        value: getOverDueBillSummary(),
        color: COLORS.chart.red
      }
    ]);
  }, [billsSummary]);

  return (
    <div className="border-radius-m shadow-s border-ms p-xl bg-white border-box db-grid-width-50 db-grid-min-width">
      <div className="row justify-content-between">
        <DKLabel text={'Bills'} className="fw-m fs-m" />
        <DKDropdown
          title={filters[selectedFilterIndex].name}
          data={filters.map((item: any) => {
            return item.name;
          })}
          onSelect={(data: any) => {
            setSelectedFilterIndex(data);
          }}
        />
      </div>
      <div className="row justify-content-between mt-xl mb-1">
        <div className="row">
          <DKLabel
            text={`<b>${Utility.getCurrencySymbolFromCode(
              tenantInfo.currency
            )} ${
              +getPaidBillSummary() < 0
                ? '(' +
                  NumberFormatService.getNumber(getPaidBillSummary()) +
                  ')'
                : NumberFormatService.getNumber(getPaidBillSummary())
            }
          </b>`}
            className={`whitespace-normal ${
              getPaidBillSummary() < 0 ? 'text-red' : ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
            style={{
              whiteSpace: 'normal'
            }}
          />
          <DKLabel className="whitespace-nowrap ml-1" text="Paid" />
        </div>
        <div className="row justify-content-end">
          <DKLabel
            text={`<b>${Utility.getCurrencySymbolFromCode(
              tenantInfo.currency
            )} ${
              +getUnpaidBillSummary() < 0
                ? '(' +
                  NumberFormatService.getNumber(getUnpaidBillSummary()) +
                  ')'
                : NumberFormatService.getNumber(getUnpaidBillSummary())
            }
          </b>`}
            className={`whitespace-normal ${
              getPaidBillSummary() < 0 ? 'text-red' : ''
            } ${GOOGLE_NO_TRANSLATE_CLASS}`}
            style={{
              whiteSpace: 'normal'
            }}
          />
          <DKLabel className="whitespace-nowrap ml-1" text="Unpaid" />
        </div>
      </div>
      {parsedData && (
        <MultiBar
          data={parsedData}
          showLegend={true}
          tooltipValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
          displayValueFormatter={(value: any) => {
            return (
              Utility.getTenantCurrencySymbol() +
              Utility.shortFormatNumberWithSuffix(value)
            );
          }}
        />
      )}
    </div>
  );
}

export default BillsSummary;
