import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import DataParser from "../DataParser"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import LineItemsParser from "../LineItemsParser"
import AddressParser, { OwnerType } from "../AddressParser"
import Address from "../Address"
import { AddressType } from "../AddressParser"

export default class SalesReturnParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        // templateDataToReturn = dataFromAPI
        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(undefined, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.contactInfo.name, dataFromAPI.contactInfo.billingAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)


        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //reason
        templateDataToReturn.warehouseName = dataFromAPI.warehouseName ? dataFromAPI.warehouseName : ''

        //reason
        templateDataToReturn.reasonToReturn = dataFromAPI.reasonToReturn ? dataFromAPI.reasonToReturn : ''

        templateDataToReturn.linkedDocuments = []
        if (dataFromAPI.linkedDocuments !== undefined && dataFromAPI.linkedDocuments !== null) {
            if (dataFromAPI.linkedDocuments.length > 0) {
                if (dataFromAPI.linkedDocuments.length === 1) {
                    // templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    // templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                    templateDataToReturn.linkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                }
                // if (dataFromAPI.linkedDocuments.length === 2) {
                //     templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                //     templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                //     templateDataToReturn.linkDocumentType = dataFromAPI.linkedDocuments[1].documentType
                //     templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocuments[1].documentSequenceCode
                // }
                // templateDataToReturn.linkedDocuments = dataFromAPI.linkedDocuments
            }
        }
        templateDataToReturn.showLinkDocumentNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.linkDocumentNumber.isVisible

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getSalesReturnItem(dataFromAPI);

        return templateDataToReturn
    }
}
