import { DKButton, DKSpinner } from 'deskera-ui-library';
import { useState } from 'react';

interface SubmitButtonProps {
  isSaving: boolean;
  btnText?: string;
  btnSavingText?: string;
  isDisabled?: boolean;
  isSpinnerWhite?: boolean;
  className?: string;
  onClick: () => void;
}

export function SubmitButton(props: SubmitButtonProps) {
  const [btnText, setBtnText] = useState(
    props.btnText ? props.btnText : 'Save'
  );
  const [btnSavingText, setBtnSavingText] = useState(
    props.btnSavingText ? props.btnSavingText : 'Saving'
  );
  const [isSpinnerWhite, setIsSpinnerWhite] = useState(
    props.isSpinnerWhite ? props.isSpinnerWhite : false
  );

  return (
    <div
      className={`row border-radius-m justify-content-between width-auto ${
        props.isSaving
          ? 'border-m'
          : props.isDisabled
          ? 'border-m'
          : 'border-transparent bg-button'
      } position-relative ${props.className}`}
    >
      <div className="row">
        <DKButton
          title={props.isSaving ? btnSavingText : btnText}
          onClick={props.onClick}
          disabled={props.isSaving || props.isDisabled}
          className={`column border-radius-none ${
            props.isSaving || props.isDisabled ? 'text-gray' : 'text-white'
          }`}
        />
        {props.isSaving && (
          <DKSpinner
            iconClassName="ic-s"
            isWhite={isSpinnerWhite}
            className="column pl-0 pr-s"
          />
        )}
      </div>
    </div>
  );
}
