import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import ImportDraftService from '../../Services/ImportDraft';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchImportDraft = createAsyncThunk('fetchImportDraft', async () => {
  const response = await ImportDraftService.getImportDraft();
  return response;
});

export const ImportDraftSlice = createSlice({
  name: 'Import_draft',
  initialState,
  reducers: {
    addImportDraftColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    },
    addImportDraft: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImportDraft.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchImportDraft.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const { addImportDraft, addImportDraftColumnConfig } = ImportDraftSlice.actions;

export const selectImportDraft = (state: RootState) => state.importDraft.data;

export const selectImportDraftColumnConfig = (state: RootState) => state.importDraft.columnConfig;
