
import React, { Component } from "react";
import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
import Utility, { getVW } from '../../Utilities/Utility';
import { FONT_SIZE, TableDimensionType, AdditionalTableType } from '../../Constants/Constants';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { getBottomBorderColor, getBottomBorderWidth, getTableFontStyleProp, getTablePadding } from './TableUtility';
import QRCode from "react-qr-code";
import NumberUtility from "../../Utilities/NumberUtility";
import ComponentManager from "../../Manager/ComponentManager";


export default class DKAdditionalTable extends Component {

    render() {
        if (this.isShowTable()) {
            return (
                <div
                    className='parent-width ColumnDiv'
                    onClick={this.props.onAdditionalTableClicked}
                >
                    {/* {this.renderHeader()} */}
                    <table className="container" style={{
                        // pageBreakAfter: 'always',
                        // pageBreakInside: 'auto'
                    }}>
                        {this.renderAdditionalTableList()}
                    </table>
                </div>
            )
        }
        return <></>
    }

    renderHeader() {
        return <div className='RowDiv' style={{
            backgroundColor: this.props.tableStyle.headerBackgroundColor,
            borderWidth: this.isShowBorder() ? getVW(1) : undefined,
            borderColor: this.isShowBorder() ? this.props.tableStyle.headerBorderColor : undefined,
            borderStyle: this.isShowBorder() ? this.props.tableStyle.borderStyle : undefined,
            borderBottomColor: this.isShowBorder() ? getBottomBorderColor(this.props.tableStyle) : undefined,
            borderBottomWidth: this.isShowBorder() ? getBottomBorderWidth(this.props.tableStyle) : undefined,
            borderCollapse: 'collapse',
            paddingTop: getVW(11),
            paddingBottom: getVW(11),
            // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium, color: this.props.tableStyle.headerTextColor, }),
            ...getTableFontStyleProp({ fontSize: FONT_SIZE.medium, color: this.props.tableStyle.headerTextColor, }, this.props.rawData.printScale)
        }}>
            <div className='RowDiv pl-s pr-s'>
                {this.getTitle()}
            </div>
        </div>
    }

    renderAdditionalTableList() {
        var list = this.getTableConfigurationElements()
        var containerList = undefined
        if(list.length > 0) {
            containerList = list.map((element) => {
                return this.renderAdditionalTableItem(element)
            });
        }

        return containerList
    }

    renderAdditionalTableItem(element) {
        return <>
            <tr className='row-cell' style={{
                pageBreakInside: 'avoid'
            }}>
                <td className="cell" style={{
                    pageBreakInside: 'avoid',
                    borderStyle: this.isShowTableBorder() ? 'solid' : 'none',
                    borderWidth: this.isShowTableBorder() ? getVW(1) : undefined,
                    borderColor: this.props.tableStyle.itemBorderColor,
                }}>
                    {this.getElement(element)}
                </td>
            </tr>
        </>
    }

    renderTitle(element) {
        return <div
            className='parent-width ColumnDiv'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular, fontWeight: 'bold' }, this.props.rawData.printScale),
            }}>
            <div className="parent-width RowDiv"
                style={{
                    fontWeight: 'bold',
                }}
                dangerouslySetInnerHTML={{ __html: element.title }} />
        </div>
    }

    getElement(element) {
        switch (element.type) {
            case AdditionalTableType.qrCode:
                return this.renderQRCodeContainer(element)
            case AdditionalTableType.qrCodeWithText:
                return this.renderQRCodeWithCustomTextContainer(element)
            case AdditionalTableType.image:
                return this.renderImageContainer(element)
            case AdditionalTableType.imageWithText:
                return this.renderImageWithCustomTextContainer(element)
            case AdditionalTableType.amountInWords:
            case AdditionalTableType.subTotalInWords:
            case AdditionalTableType.cgstInWords:
            case AdditionalTableType.igstInWords:
            case AdditionalTableType.sgstInWords:
            case AdditionalTableType.roundOffInWords:
            case AdditionalTableType.outstandingBalanceInWords:
            case AdditionalTableType.outstandingBalanceIncludesCurrentInWords:
            case AdditionalTableType.totalTaxAmountInWords:
            case AdditionalTableType.beforeTaxInWords:
            case AdditionalTableType.globalDiscountInWords:
            case AdditionalTableType.additionalChargeInWords:
                return this.renderAmountInWordsContainer(element)
            case AdditionalTableType.total:
                return this.renderTotalContainer(element)
            case AdditionalTableType.outstandingBalance:
                return this.renderOutstandingBalanceContainer(element)
            case AdditionalTableType.outstandingBalanceIncludesCurrent:
                return this.renderOutstandingBalanceIncludesCurrentContainer(element)
            case AdditionalTableType.customText:
                return this.renderCustomTextContainer(element)
            case AdditionalTableType.customField:
                return this.renderCustomTextContainer(element)
            default:
                return undefined
        }
    }

    getTitle() {
        var elements = this.getTableConfigurationElements()
        var title = 'additional_info'

        const titleMapList = [
            { type: AdditionalTableType.qrCode, title: 'qr_information' },
            { type: AdditionalTableType.qrCodeWithText, title: 'qr_information' },
            { type: AdditionalTableType.image, title: 'additional_info' },
            { type: AdditionalTableType.imageWithText, title: 'additional_info' },
            { type: AdditionalTableType.amountInWords, title: 'amount_in_words' },
            { type: AdditionalTableType.total, title: 'total' },
            { type: AdditionalTableType.outstandingBalance, title: 'outstanding_balance' },
            { type: AdditionalTableType.outstandingBalanceIncludesCurrent, title: 'outstanding_balance_includes_current' },
            { type: AdditionalTableType.customText, title: 'additional_info' },
            { type: AdditionalTableType.subTotalInWords, title: 'sub_total_in_words' },
            { type: AdditionalTableType.cgstInWords, title: 'cgst_in_words' },
            { type: AdditionalTableType.igstInWords, title: 'igst_in_words' },
            { type: AdditionalTableType.sgstInWords, title: 'sgst_in_words' },
            { type: AdditionalTableType.roundOffInWords, title: 'rounding_off_in_words' },
            { type: AdditionalTableType.outstandingBalanceInWords, title: 'outstanding_balance_in_words' },
            { type: AdditionalTableType.outstandingBalanceIncludesCurrentInWords, title: 'outstanding_balance_includes_current_in_words' },
            { type: AdditionalTableType.totalTaxAmountInWords, title: 'total_tax_amount_in_words' },
            { type: AdditionalTableType.beforeTaxInWords, title: 'before_tax_amount_in_words' },
            { type: AdditionalTableType.globalDiscountInWords, title: 'global_discount_in_words' },
            { type: AdditionalTableType.additionalChargeInWords, title: 'additional_charges_in_words' },
        ]

        if(elements.length > 0 ) {
            if(elements.length === 1) {
                const titleMap = titleMapList.find(x => x.type === elements[0].type)
                if(titleMap) {
                    title = titleMap.title
                }
            }
        }
        return getLocalisedText(title)
    }

    renderQRCodeContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="ColumnDiv pl-s pr-s">
                {this.renderQRCode_svg(element.qrCode)}
            </div>
        </div>
    }

    renderQRCodeWithCustomTextContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    width: '95%',
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale)
                }}>
                <div className="ColumnDiv ">
                    {this.renderQRCode_svg(element.qrCode)}
                </div>
                <div className="ColumnDiv parent-width "
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.getMultiLineText(element.text) }} />
            </div>
        </div>
    }

    renderQRCode_svg(value) {
        if (this.getIsContainQRCode(value)) {
            return <QRCode
                value={value}
                size={Utility.convertToPrintScale(128, this.props.rawData.printScale)}
            />
        }
        return null
    }

    renderQRCode_img(value) {
        if (this.getIsContainQRCode(value)) {
            return <img src={value}
                alt=""
                style={{
                    ...{
                        height: Utility.convertToPrintScale(128, this.props.rawData.printScale),
                    },
                }}
            />
        }
        return null
    }

    getIsContainQRCode(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }


    renderImageContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="ColumnDiv pl-s pr-s">
                {this.renderImage(element.image)}
            </div>
        </div>
    }

    renderImageWithCustomTextContainer(element) {
        return <div
            className='parent-width ColumnDiv pt-s pb-s'
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.medium }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className="parent-width ColumnDiv pl-s pr-s">
                {this.renderTitle(element)}
            </div>
            <div className="parent-width RowDiv pl-s pr-s"
                style={{
                    width: '95%',
                }}>
                <div className="ColumnDiv parent-width ">
                    {this.renderImage(element.image)}
                </div>

                <div className="ColumnDiv parent-width "
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.getMultiLineText(element.text) }} />
            </div>
        </div>
    }

    renderImage(value) {
        if (this.getIsContainQRCode(value)) {
            return <img src={value}
                alt=""
                style={{
                    ...{
                        height: Utility.convertToPrintScale(150, this.props.rawData.printScale),
                        borderRadius: '5%',
                        objectFit: "contain",
                        border: "solid",
                        borderWidth: 1,
                        borderColor: "rgb(230, 230, 230)",
                    },
                }}
            />
        }
        return null
    }

    getIsContainImage(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }


    renderAmountInWordsContainer(element) {
        let value = ''
        const inWordsMap = [
            { type: AdditionalTableType.amountInWords, key: 'amountInWords' },
            { type: AdditionalTableType.subTotalInWords, key: 'subTotalInWord' },
            { type: AdditionalTableType.cgstInWords, key: 'cgstInWord' },
            { type: AdditionalTableType.igstInWords, key: 'igstInWord' },
            { type: AdditionalTableType.sgstInWords, key: 'sgstInWord' },
            { type: AdditionalTableType.roundOffInWords, key: 'roundingOffInWord' },
            { type: AdditionalTableType.outstandingBalanceInWords, key: 'outStandingAmountInWord' },
            { type: AdditionalTableType.outstandingBalanceIncludesCurrentInWords, key: 'outstandingAmountIncludingCurrentInWord' },
            { type: AdditionalTableType.totalTaxAmountInWords, key: 'totalTaxAmountInWord' },
            { type: AdditionalTableType.beforeTaxInWords, key: 'amountBeforeTaxInWord' },
            { type: AdditionalTableType.globalDiscountInWords, key: 'globalDiscountInWord' },
            { type: AdditionalTableType.additionalChargeInWords, key: 'additionalChargeInWord' },
        ]

        const inWords = inWordsMap.find(x => x.type === element.type)

        if(inWords) {
            let key = inWords.key

            if (this.props.rawData && this.props.rawData !== null) {
                if (this.props.rawData[key] && this.props.rawData[key] !== null) {
                    if (this.props.rawData[key] !== '') {
                        value = this.props.rawData[key] 
                    }
                }
                else {
                    value = '-'
                }
            }
        }

        return this.renderTextContainer(value, element)
    }

    renderTotalContainer(element) {
        var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(this.props.rawData.lineItems, false, false, 0), NumberUtility.getCurrency(this.props.rawData))
        if (this.props.rawData.total !== undefined) {
            total = Utility.toCurrencyFormat(this.props.rawData.total, NumberUtility.getCurrency(this.props.rawData))
        }

        return this.renderTextContainer(total, element)
    }

    renderOutstandingBalanceContainer(element) {
        var outstandingBalance = ''
        if (this.props.rawData.outstandingBalance !== undefined) {
            outstandingBalance = Utility.toCurrencyFormat(this.props.rawData.outstandingBalance, NumberUtility.getCurrency(this.props.rawData))
        }

        return this.renderTextContainer(outstandingBalance, element)
    }

    renderOutstandingBalanceIncludesCurrentContainer(element) {
        var outstandingBalance = ''
        if (this.props.rawData.outstandingBalanceIncludesCurrent !== undefined) {
            outstandingBalance = Utility.toCurrencyFormat(this.props.rawData.outstandingBalanceIncludesCurrent, NumberUtility.getCurrency(this.props.rawData))
        }

        // var total = ComponentManager.getTotalOfLineItems(this.props.rawData.lineItems, false, false, 0)
        // if (this.props.rawData.total !== undefined) {
        //     total = this.props.rawData.total
        // }

        // if (this.props.rawData.outstandingBalance !== undefined) {
        //     outstandingBalance = outstandingBalance + total
        // }

        // if(outstandingBalance !== '') {
        //     outstandingBalance = Utility.toCurrencyFormat(outstandingBalance, NumberUtility.getCurrency(this.props.rawData))
        // }
        // else if (this.props.rawData.total !== undefined) {
        //     outstandingBalance = Utility.toCurrencyFormat(total, NumberUtility.getCurrency(this.props.rawData))
        // }

        return this.renderTextContainer(outstandingBalance, element)
    }

    renderCustomTextContainer(element) {
        var text = ''
        if (element.text !== undefined && element.text !== null ) {
            text = element.text
        }
        if (element.customFieldValue !== undefined && element.customFieldValue !== null) {
            text = element.customFieldValue
        }
        return this.renderTextContainer(this.getMultiLineText(text), element)
    }

    renderTextContainer(text, element) {
        const direction = element.direction ?? 'vertical'
        const mainContainerClassName = direction === 'vertical' ? 'parent-width ColumnDiv ' : 'RowDiv '
        const subContainerClassName = direction === 'vertical' ? 'parent-width RowDiv ' : 'RowReverseDiv '
        const subDivWidth = direction === 'vertical' ? '95%' : undefined
        const mainContainerFlex = direction === 'vertical' ? undefined : 2
        const subContainerFlex = direction === 'vertical' ? undefined : 5
        const subContainerTextAlign = direction === 'vertical' ? undefined : 'right'

        return <div
            className={'pt-s pb-s ' + mainContainerClassName}
            style={{
                textAlign: 'left',
                overflowWrap: 'normal',
                // ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }),
                ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                ...this.getRowPadding(),
            }}>
            <div className={"pl-s pr-s " + subContainerClassName}
                style={{
                    width: subDivWidth,
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                    flex: mainContainerFlex,
                }}>
                {this.renderTitle(element)}
            </div>
            <div className={"pl-s pr-s " + subContainerClassName}
                style={{
                    marginRight: 8,
                    width: subDivWidth,
                    ...getTableFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.rawData.printScale),
                    flex: subContainerFlex,
                    textAlign: subContainerTextAlign,
                }}
                dangerouslySetInnerHTML={{__html: text}} />
        </div>
    }

    getRowPadding() {
        var paddingTop = getTablePadding(getVW(0), TableDimensionType.PADDING_VERTICAL)
        var paddingBottom = getTablePadding(getVW(0), TableDimensionType.PADDING_VERTICAL)
        var paddingLeft = getTablePadding(getVW(0), TableDimensionType.PADDING_HORIZONTAL)
        var paddingRight = getTablePadding(getVW(0), TableDimensionType.PADDING_HORIZONTAL)

        return {
            paddingTop: paddingTop === '0vw' ? undefined : paddingTop,
            paddingBottom: paddingBottom === '0vw' ? undefined : paddingBottom,
            paddingLeft: paddingLeft === '0vw' ? undefined : paddingLeft,
            paddingRight: paddingRight === '0vw' ? undefined : paddingRight,
        }
    }

    getMultiLineText(text) {
        var newText = ''
        if (text !== undefined && text !== null) {
            if (text.includes('\n')) {
                newText = text.split('\n').join('<br/>')
            }
            else {
                newText = text
            }
        }

        return newText
    }

    getTableConfigurationElements() {
        var additionalTableConfiguration = this.getTableConfiguration()
        if (additionalTableConfiguration !== undefined) {
            if (additionalTableConfiguration.elements !== undefined && additionalTableConfiguration.elements !== null) {
                if (additionalTableConfiguration.elements.length > 0) {
                    var elements = additionalTableConfiguration.elements
                    elements = Utility.getCustomFieldValue(this.props.rawData, elements)
                    elements = this.getIndiaTaxElement(elements)
                    return elements
                }
            }
        }

        return []
    }

    getIndiaTaxElement(elements) {
        let newElements = elements
        if (this.getIsIndiaTaxType('IGST') && !this.getIsIndiaTaxType('CGST')) {
            newElements = elements.filter(x => x.type !== AdditionalTableType.cgstInWords && x.type !== AdditionalTableType.sgstInWords)
        }
        else if (!this.getIsIndiaTaxType('IGST') && this.getIsIndiaTaxType('CGST')) {
            newElements = elements.filter(x => x.type !== AdditionalTableType.igstInWords)
        }
        return newElements
    }


    getIsIndiaTaxType(type) {
        let data = this.props.rawData ?? undefined
        if (data) {
            if (data.taxBreakdown !== undefined && data.taxBreakdown !== undefined) {
                if (data.taxBreakdown.length > 0) {
                    var taxIdList = data.taxBreakdown.map(x => x.taxId)
                    if (taxIdList.length > 0) {
                        return taxIdList.includes(type)
                    }
                }
            }
        }

        return false
    }

    getTableConfiguration() {
        var additionalTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.additionalTable

        if (additionalTableConfiguration !== undefined) {
            return additionalTableConfiguration
        }

        return undefined
    }

    getIsContainsTitle(element) {
        if(element.title !== undefined && element.title !== null) {
            if(element.title !== '') {
                return true
            }
        }
        return false
    }

    isShowTable() {
        if(Utility.getIsReceiptPaperSize()) {
            return false
        }

        var additionalTableConfiguration = this.getTableConfiguration()
        if (additionalTableConfiguration !== undefined) {
            if (additionalTableConfiguration.isVisible !== undefined && additionalTableConfiguration.isVisible !== null) {
                if (additionalTableConfiguration.elements !== undefined && additionalTableConfiguration.elements !== null) {
                    if (additionalTableConfiguration.elements.length > 0) {
                        return additionalTableConfiguration.isVisible
                    }
                }
            }
        }
        return false
    }

    isShowTableBorder() {
        let additionalTableConfig = this.getTableConfiguration()
        let tableBorder = undefined
        let isShowBorder = true
        if (additionalTableConfig && additionalTableConfig !== null) {
            tableBorder = additionalTableConfig?.border ?? undefined
        }
        if (tableBorder !== undefined) {
            if (tableBorder === 'hide') {
                isShowBorder = false
            }
        }
        return isShowBorder
    }

    isShowBorder() {
        let additionalTableConfig = this.getTableConfiguration()
        let tableBorder = undefined
        if (additionalTableConfig && additionalTableConfig !== null) {
            tableBorder = additionalTableConfig?.tableBorder ?? undefined
        }

        if (tableBorder === undefined) {
            return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
        }
        else {
            if(tableBorder === 'hide') {
                return false
            }
        }
        return this.props.tableOption.isShowBorder ? this.props.tableOption.isShowBorder : false
    }

}
