import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKDataGrid,
  DKTooltipWrapper,
  DKIcon,
  DKIcons,
  DKCheckMark,
  DKSpinner,
  showAlert,
  Toggle
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  INPUT_VIEW_DIRECTION,
  REVENUE_PLAN_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import NumberFormatService from '../../Services/NumberFormat';
import RevRecService from '../../Services/RevenueRec';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';

import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';
import RevenueRecognitionService from '../../Services/RevenueRecognition';
import { getAmountBlockWithCurrencyForRevRec } from '../Reports/Financial/Parser/SharedParser';
import { updateRowDataWithParentCFValues } from '../../SharedComponents/DocumentForm/NewDocumentHelper';
const ViewRevenueRecognitionPlans = (props: any) => {
  const initialState: any = {
    number: '',
    creationTriggeredBy: '',
    createdFrom: '',
    revRecRule: '',
    amount: '',
    contractAcquisitionCostAmount: '',
    itemResaleCostAmount: '',
    //
    itemLaborCostAmount: '',
    productId: '',
    remainingDeferredBalance: '',
    recognizedAmount: '',
    status: '',
    type: '',
    catchUpPeriod: '',
    //
    isHoldRevenueRecognition: false,
    amountSource: '',
    recognitionMethod: '',
    revRecStartDateSource: '',
    revRecEndDateSource: '',
    endDateChangeImpact: '',
    revRecStartDate: new Date(),
    revRecEndDate: new Date(),
    revenuePlanDetails: []
  };
  let colConfig = [
    {
      id: 'plannedPeriod',
      key: 'plannedPeriod',
      name: 'Planned Period',
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'left',
      formatter: ({ value }: any) => value,
      dropdownConfig: {
        title: 'Select',
        allowSearch: true,
        data: [],
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        renderer: (index: any, obj: any) => {
          return obj;
        }
      }
    },
    {
      id: 'amountInBase',
      key: 'amountInBase',
      name: 'Amount',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'exchangeRate',
      key: 'exchangeRate',
      name: 'Line Exchange Rate',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'jeCode',
      key: 'jeCode',
      name: 'Journal',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'postingPeriod',
      key: 'postingPeriod',
      name: 'Posting Period',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'deferralAccountName',
      key: 'deferralAccountName',
      name: 'Deferral Account',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'recognitionAccountName',
      key: 'recognitionAccountName',
      name: 'Recognition Account',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'executionDateFormatted',
      key: 'executionDateFormatted',
      name: 'Date Executed',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'recognizedFormatted',
      key: 'recognizedFormatted',
      name: 'Is Recognized',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'recognizedInPeriodPercent',
      key: 'recognizedInPeriodPercent',
      name: 'Percentage Recognized In Period',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'totalRecognizedPercent',
      key: 'totalRecognizedPercent',
      name: 'Percentage Total Recognized',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'recognizedAmountInBase',
      key: 'recognizedAmountInBase',
      name: 'Total Recognized',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'periodComments',
      key: 'periodComments',
      name: 'Period Comments',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    }
  ];
  const [popupState, setPopupState] = useState<any>(deepClone(initialState));
  const [popupState1, setPopupState1] = useState<any>(deepClone(initialState));
  const [columnConfig, setColumnConfig] = useState<any>(colConfig);
  const [plannedPeriodList, setPlannedPeriodList] = useState<any[]>([]);
  const [alreadyPlannedPeriodLastIndex, setAlreadyPlannedPeriodLastIndex] =
    useState<any>(0);
  const [originalPlannedPeriodList, setOriginalPlannedPeriodList] = useState<
    any[]
  >([]);
  const [plannedPeriodData, setPlannedPeriodData] = useState<any[]>([]);
  const [revenuePlanDetailsRows, setRevenuePlanDetailsRows] = useState<any[]>(
    []
  );
  const [dateModified, setDateModified] = useState<boolean>(false);
  const [tableModified, setTableModified] = useState<boolean>(false);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const [isHoldRevenueRecognition, setIsHoldRevenueRecognition] =
    useState<boolean>(false);
  const [isApiCallRunning, setisApiCallRunning] = useState<boolean>(false);
  const statusEnum: any = {
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETE: 'Complete'
  };

  const tenantInfo = useAppSelector(activeTenantInfo);

  const recognitionMethod: any = {
    EVEN_PERIODS: 'Even Periods',
    FIRST_LAST_PERIOD: 'First Last Period',
    EXACT_DAYS: 'Exact Days'
  };
  const amountSource: any = {
    EVENT_PERC_AMOUNT: 'Event Percentage Amount',
    EVENT_PERC_QUANTITY: 'Event Percentage Quantity',
    EVENT_PERC_COMPLETE: 'Event Percentage Complete'
  };
  const revRecStartDateSource: any = {
    EVENT_DATE: 'Event Date',
    REVENUE_START_DATE: 'Revenue Start Date',
    ARRANGEMENT_TRANSACTION_DATE: 'Arragement Transaction Date'
  };
  const revRecEndDateSource: any = {
    EVENT_DATE: 'Event Date',
    REVENUE_END_DATE: 'Revenue End Date',
    TERM_IN_MONTH: 'Term In Month'
  };
  const endDateChangeImpact: any = {
    NONE: 'None',
    UPDATE_REMAINING_PERIODS: 'Update Remaining Periods'
  };
  const reForecastMethod: any = {
    NEXT_PERIOD: 'Next Period',
    REMAINING_PERIOD: 'Remaining Period'
  };

  useEffect(() => {
    if (!Utility.isEmpty(props.data)) {
      let row = props.data.revenuePlans?.find((rowData: any) => {
        return rowData.type === 'ACTUAL';
      });
      setIsHoldRevenueRecognition(props?.data?.isHoldRevenueRecognition);
      let tempPopupState1 = popupState1;
      tempPopupState1.createdFrom = props.data?.itemCode;
      tempPopupState1.creationTriggeredBy = props.data?.createRevenuePlansOn;
      tempPopupState1.number = row?.itemCode;
      if (Utility.isEmpty(row)) {
        row = props.data;
        tempPopupState1.createdFrom = row?.revElementNo;
        tempPopupState1.creationTriggeredBy = row?.createRevenuePlansOn;
        tempPopupState1.number = row?.revPlanNo;
      }

      tempPopupState1.remainingDeferredBalance =
        row.remainingAmountInBase || row.remainingAmount;
      setPopupState1(tempPopupState1);
      let code = row?.itemCode;
      if (!Utility.isEmpty(code)) {
        RevRecService.getPlanDetails(code)
          .then((data: any) => {
            let copyData: any = deepClone(data);

            copyData?.revenuePlanDetails.sort((a: any, b: any) =>
              a.id > b.id ? 1 : b.id > a.id ? -1 : 0
            );

            copyData.status = statusEnum[copyData.status];
            if (!Utility.isEmpty(copyData.revRecRuleDto)) {
              copyData.recognitionMethod =
                recognitionMethod[copyData.revRecRuleDto.recognitionMethod];
              copyData.amountSource =
                amountSource[copyData.revRecRuleDto.amountSource];
              copyData.revRecStartDateSource =
                revRecStartDateSource[
                  copyData.revRecRuleDto.revRecStartDateSource
                ];
              copyData.revRecEndDateSource =
                revRecEndDateSource[copyData.revRecRuleDto.revRecEndDateSource];
              copyData.endDateChangeImpact =
                endDateChangeImpact[copyData.revRecRuleDto.endDateChangeImpact];
              copyData.reForecastMethod =
                reForecastMethod[copyData.revRecRuleDto.reForecastMethod];
            }
            if (
              copyData.revRecStartDate !== null &&
              copyData.revRecStartDate !== undefined
            ) {
              copyData.revRecStartDate = new Date(copyData.revRecStartDate);
              const startDate = DateFormatService.getDateStrFromDate(
                copyData?.revRecStartDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              );
              const tempPlannedPeriodData = generateMonthYearArray(startDate);
              let tempPlannedPeriodList = tempPlannedPeriodData;
              let alreadyPostedPeriodList: any[] = [];

              copyData?.revenuePlanDetails.forEach((rowData: any) => {
                if (rowData.jeCode) {
                  alreadyPostedPeriodList.push(rowData.postingPeriod);
                }
              });

              tempPlannedPeriodList = tempPlannedPeriodList.filter(
                (tempRow) => !alreadyPostedPeriodList.includes(tempRow)
              );

              let tempAlreadyPlannedPeriodLastIndex = 0;
              let isFlag = true;
              copyData?.revenuePlanDetails.forEach((rowData: any) => {
                if (rowData.jeCode && isFlag) {
                  tempAlreadyPlannedPeriodLastIndex =
                    tempAlreadyPlannedPeriodLastIndex + 1;
                } else {
                  isFlag = false;
                }
              });
              setAlreadyPlannedPeriodLastIndex(
                tempAlreadyPlannedPeriodLastIndex
              );
              setPlannedPeriodList(tempPlannedPeriodList);
              setPlannedPeriodData(tempPlannedPeriodData);
              setOriginalPlannedPeriodList(tempPlannedPeriodList);
            } else {
              copyData.revRecStartDate = new Date();
            }
            if (
              copyData.revRecEndDate !== null &&
              copyData.revRecEndDate !== undefined
            ) {
              copyData.revRecEndDate = new Date(copyData.revRecEndDate);
            } else {
              copyData.revRecEndDate = new Date();
            }
            if (copyData.amount) {
              copyData.amount = DocumentConfigUtility.amountFormatter(
                copyData.amount,
                tenantInfo.currency
              );
            }
            if (copyData.type !== null && copyData.type !== undefined) {
              copyData.type = REVENUE_PLAN_TYPE[`${copyData.type}`];
            }
            if (copyData.itemLaborCostAmount) {
              copyData.itemLaborCostAmount =
                DocumentConfigUtility.amountFormatter(
                  copyData.itemLaborCostAmount,
                  tenantInfo.currency
                );
            }
            copyData.revenuePlanDetails.forEach((row: any, index: any) => {
              copyData.revenuePlanDetails[index] = {
                ...copyData.revenuePlanDetails[index],
                recognizedFormatted: copyData.revenuePlanDetails[index]
                  .recognized
                  ? 'Yes'
                  : 'No'
              };
            });

            copyData?.revenuePlanDetails?.forEach((row: any, index: any) => {
              if (
                row.executionDate !== null &&
                row.executionDate !== undefined
              ) {
                row.executionDateFormatted =
                  DateFormatService.getDateStrFromDate(
                    new Date(row.executionDate),
                    tenantInfo.dateFormat
                  );
              }
              if (
                row.totalRecognizedPercent !== null &&
                row.totalRecognizedPercent !== undefined
              ) {
                row.totalRecognizedPercent = NumberFormatService.getNumber(
                  row.totalRecognizedPercent
                );
              }
              if (
                row.recognizedInPeriodPercent !== null &&
                row.recognizedInPeriodPercent !== undefined
              ) {
                row.recognizedInPeriodPercent = NumberFormatService.getNumber(
                  row.recognizedInPeriodPercent
                );
              }
              row.allowRowEdit = row.jeCode ? false : true;
            });
            //   copyData.forEach((row: any, index: any) => {

            //   });

            setPopupState(copyData);
            getCustomFieldColumnConfig();
            getRevenuePlanDetailsRows(copyData.revenuePlanDetails);
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
      //set data mapping here
      //   setPopupState({ ...props.data });
    }
  }, []);

  useEffect(() => {
    updateRevConfig();
  }, [revenuePlanDetailsRows, plannedPeriodList]);

  const validate = () => {
    const sourceStartDate = DateFormatService.getDateStrFromDate(
      popupState.revRecStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const sourceEndDate = DateFormatService.getDateStrFromDate(
      popupState.revRecEndDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    const emptyPlannedPeriod = revenuePlanDetailsRows.filter(
      (row) => row?.plannedPeriod === '' || row?.plannedPeriod === null
    );

    if (
      Utility.isEmpty(popupState?.catchUpPeriod) &&
      isHoldRevenueRecognition
    ) {
      showAlert('Alert', "Catch period cann't be empty");
      return false;
    } else if (Utility.isEmpty(sourceStartDate)) {
      showAlert('Alert', "Rev Rec Start Date Source cann't be empty");
      return false;
    } else if (Utility.isEmpty(sourceEndDate)) {
      showAlert('Alert', "Rev Rec End Date Source cann't be empty");
      return false;
    } else if (popupState.isHoldRevenueRecognition === true) {
      showAlert('Alert', 'Please uncheck Hold Revenue Recognition ');
      return false;
    } else if (!Utility.isEmpty(emptyPlannedPeriod)) {
      showAlert('Alert', "Planned period cann't be empty ");
      return false;
    }
    return true;
  };
  const onUpdate = () => {
    if (validate() && !isApiCallRunning) {
      let tempData = props.data;
      let id = props.data.revenuePlans?.find((rowData: any) => {
        return rowData.type === 'ACTUAL';
      })?.id;
      if (Utility.isEmpty(id)) {
        id = props.data.id;
      }

      let revenuePlanDetailsPayload: any[] = [];
      const rowAmountTotal = revenuePlanDetailsRows.reduce(
        (accumulator, row) => {
          return accumulator + row.amountInBase;
        },
        0
      );
      const diff = popupState?.amountInBase - rowAmountTotal;
      if (
        Utility.roundOff(rowAmountTotal, 4) !==
          Utility.roundOff(popupState?.amountInBase, 4) &&
        Math.abs(diff) > 0.005
      ) {
        showAlert('Alert', 'The sum of row should match the total amount');
        return;
      }

      revenuePlanDetailsRows[revenuePlanDetailsRows.length - 1].amountInBase =
        revenuePlanDetailsRows[revenuePlanDetailsRows.length - 1].amountInBase +
        diff;

      revenuePlanDetailsRows?.forEach((revRow: any) => {
        const rowData = {
          id: revRow?.id,
          tenantId: revRow?.tenantId,
          active: revRow?.active,
          deleted: revRow?.deleted,
          plannedPeriod: revRow?.plannedPeriod,
          amount: revRow?.amountInBase * revRow?.exchangeRate,
          exchangeRate: revRow?.exchangeRate,
          jeCode: revRow?.jeCode,
          postingPeriod: revRow?.postingPeriod,
          deferralAccount: revRow?.deferralAccount,
          deferralAccountName: revRow?.deferralAccountName,
          recognitionAccount: revRow?.recognitionAccount,
          recognitionAccountName: revRow?.recognitionAccountName,
          executionDate: revRow?.executionDate,
          recognized: revRow?.recognized,
          recognizedInPeriodPercent: revRow?.recognizedInPeriodPercent,
          totalRecognizedPercent: revRow?.totalRecognizedPercent,
          periodComments: revRow?.periodComments,
          recognizedAmount: revRow?.recognizedAmount,
          plannedPeriodDate: revRow?.plannedPeriodDate,
          amountInBase: revRow?.amountInBase,
          recognizedAmountInBase: revRow?.recognizedAmountInBase,
          currency: revRow?.currency,
          customField: revRow.customField?.map((field: any) => {
            return {
              ...field,
              value:
                tenantInfo?.additionalSettings?.REV_REC_CUSTOM_FIELD_SETTING?.includes(
                  field.id
                )
                  ? revRow[field.id + '']
                  : field.value
            };
          })
        };
        revenuePlanDetailsPayload.push(rowData);
      });

      const payload = {
        planId: id,
        catchUpPeriod: popupState.catchUpPeriod,
        sourceStartDate: DateFormatService.getDateStrFromDate(
          popupState.revRecStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        sourceEndDate: DateFormatService.getDateStrFromDate(
          popupState.revRecEndDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        isHoldRevenueRecognition:
          popupState?.isHoldRevenueRecognition === true
            ? popupState.isHoldRevenueRecognition
            : false,
        revenuePlanDetails: revenuePlanDetailsPayload,
        dateModified: dateModified
      };
      setisApiCallRunning(true);
      RevenueRecognitionService.updateRevenueReleasePlan(payload)
        .then(() => {
          setisApiCallRunning(false);
          props.onSave();
        })
        .catch((error: any) => {
          console.log(error);
          setisApiCallRunning(false);
          props.onSave();
        });
    }
  };
  const onStartDateSelected = (value: any) => {
    const tempPopupState = { ...popupState };

    if (tempPopupState.revRecEndDate < value) {
      showAlert(
        'Alert',
        ' Rev Rec Start Date Source should  be earlier than  Rev Rec  End Date Source'
      );
    } else {
      tempPopupState.revRecStartDate = value;
      setDateModified(true);
    }
    setPopupState(tempPopupState);
  };
  const onEndDateSelected = (value: any) => {
    const tempPopupState = { ...popupState };

    if (tempPopupState.revRecStartDate > value) {
      showAlert(
        'Alert',
        ' Rev Rec End Date Source Cannot be earlier than  Rev Rec  Start Date Source'
      );
    } else {
      tempPopupState.revRecEndDate = value;
      setDateModified(true);
    }
    setPopupState(tempPopupState);
  };

  const generateMonthYearArray = (tmpDate: any) => {
    let startDate = DateFormatService.getDateFromStr(
      tmpDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    let endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 4);
    const result = [];
    while (startDate < endDate) {
      result.push(
        startDate.toLocaleString('default', { month: 'short', year: 'numeric' })
      );
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return result;
  };

  const updateCatchUpPeriod = (value: any) => {
    const tempPopupState = { ...popupState };
    tempPopupState.catchUpPeriod = value;
    setPopupState(tempPopupState);
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={props.isReadOnly ? `View Plan Details` : `Edit Plan Details`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          {!props.isReadOnly && (
            <DKButton
              title={'Update'}
              className="bg-button text-white"
              onClick={() => {
                setTimeout(() => {
                  onUpdate();
                }, 200);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const updateRevConfig = () => {
    let config = [...columnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'amountInBase':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              tenantInfo.currency
            );
          break;
        case 'recognizedAmountInBase':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              tenantInfo.currency
            );
          break;
        case 'plannedPeriod':
          conf.dropdownConfig.data = plannedPeriodList || [];
          conf.editable = !dateModified;
          break;
        default:
          break;
      }
    });
    setColumnConfig(config);
  };
  const getRevenuePlanDetailsRows = (revPlanRows: any) => {
    let tempProductRows = revPlanRows;
    let customFieldList: any[] = [];
    props?.customFieldData?.forEach((currCustomField: any) => {
      const customfieldRow = {
        label: currCustomField?.label,
        shortName: currCustomField?.shortName,
        code: currCustomField?.code,
        module: currCustomField?.module,
        value: '',
        id: currCustomField?.id
      };
      customFieldList.push(customfieldRow);
    });

    tempProductRows?.forEach((row: any, index: any) => {
      let tempCustomFieldList: any[] = customFieldList.map((fieldData) => {
        const val =
          row?.customField?.find((fdata: any) => fdata.id === fieldData.id)
            ?.value || '';
        return { ...fieldData, value: val };
      });
      tempCustomFieldList?.forEach((field: any) => {
        const colName: any = field.id + '' || '';
        if (colName !== '') {
          let tempRow: any = {};
          tempRow[colName] = field.value;
          row = { ...row, ...tempRow, customField: tempCustomFieldList };
          tempProductRows[index] = row;
        }
      });
    });

    setRevenuePlanDetailsRows(tempProductRows);
  };

  const getCustomFieldColumnConfig = () => {
    let tempColumnConfig = [...columnConfig];

    if (props.customFieldData?.length > 0) {
      setProductCustomFields(props.customFieldData);
    }
    props?.customFieldData?.forEach((currCustomField: any) => {
      if (
        tenantInfo?.additionalSettings?.REV_REC_CUSTOM_FIELD_SETTING?.includes(
          currCustomField?.id
        )
      ) {
        if (!Utility.isEmpty(currCustomField)) {
          if (currCustomField.fieldType === 'DROPDOWN') {
            let attributes = currCustomField.attributes;
            const noneAttribute = {
              id: 0,
              code: null,
              status: STATUS_TYPE.ACTIVE,
              value: 'None'
            };
            const alreadyAddedNoneAttribute = attributes.find(
              (att: any) => att.id === 0
            );
            if (Utility.isEmpty(alreadyAddedNoneAttribute)) {
              attributes.push(noneAttribute);
            }

            const tempColumn = {
              id: currCustomField.id + '',
              key: currCustomField.id + '',
              name: currCustomField.label,
              type: INPUT_TYPE.DROPDOWN,
              width: 140,
              systemField: true,
              editable: true,
              hidden: false,
              uiVisible: true,
              formatter: ({ value }: any) => value,
              dropdownConfig: {
                title: 'Select',
                allowSearch: true,
                data: attributes,
                onSelect: (index: any, obj: any, rowIndex: any) => {},
                renderer: (index: any, obj: any) => {
                  return obj.value;
                }
              }
            };
            tempColumnConfig.push(tempColumn);
          } else {
            const tempColumn = {
              id: currCustomField.id + '',
              key: currCustomField.id + '',
              name: currCustomField.label,
              type: INPUT_TYPE.TEXT,
              width: 120,
              systemField: true,
              editable: true,
              hidden: false,
              uiVisible: true
            };
            tempColumnConfig.push(tempColumn);
          }
        }
      }
    });

    setColumnConfig(tempColumnConfig);
  };

  const onRowClick = (row: any) => {
    let tempPlannedPeriodList = [...plannedPeriodList];
    const currPlannedPeriod = row?.rowData?.plannedPeriod;
    const currPlannedPeriodIndex = plannedPeriodData.findIndex(
      (tempPlanned) => tempPlanned === currPlannedPeriod
    );
    if (row.rowIndex === alreadyPlannedPeriodLastIndex) {
      tempPlannedPeriodList = originalPlannedPeriodList;
    } else if (currPlannedPeriodIndex > -1) {
      tempPlannedPeriodList = plannedPeriodData.slice(currPlannedPeriodIndex);
    }
    setPlannedPeriodList(tempPlannedPeriodList);
  };

  const onRowUpdate = (rowUpdate: any) => {
    const { columnData, columnKey, rowData, rowIndex } = rowUpdate;
    let rows: any = [...revenuePlanDetailsRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    switch (columnKey) {
      case 'plannedPeriod':
        setTableModified(true);
        let currplannedPeriodIndex = plannedPeriodList.findIndex(
          (PlannedData) => PlannedData === dataToUpdate
        );
        let currProductRowsIndex = rowIndex;
        if (currplannedPeriodIndex > -1) {
          rows.forEach((element: any, ind: any) => {
            if (element.allowRowEdit) {
              if (
                ind >= currProductRowsIndex &&
                currplannedPeriodIndex < plannedPeriodList.length
              ) {
                rows[currProductRowsIndex].plannedPeriod =
                  plannedPeriodList[currplannedPeriodIndex];
                currProductRowsIndex = currProductRowsIndex + 1;
                currplannedPeriodIndex = currplannedPeriodIndex + 1;
              } else if (ind >= currProductRowsIndex) {
                rows[currProductRowsIndex].plannedPeriod = '';
                currProductRowsIndex = currProductRowsIndex + 1;
              }
            } else if (ind >= currProductRowsIndex) {
              currProductRowsIndex = currProductRowsIndex + 1;
            }
          });
        }
        break;
      case 'amountInBase':
        setTableModified(true);
        selectedRows.amountInBase = Utility.roundOff(
          +dataToUpdate || selectedRows.amountInBase,
          4
        );
        break;
      default:
        const customFieldIndex = selectedRows?.customField?.findIndex(
          (field: any) => field.id === +columnKey
        );
        if (columnData.type === 'dropdown') {
          selectedRows[columnKey] =
            dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
          const filteredCF: any = productCustomFields?.find(
            (field: any) => field.id === +columnKey
          );
          const { rowData } = updateRowDataWithParentCFValues(
            dataToUpdate.value,
            { ...rows[rowIndex] },
            filteredCF,
            productCustomFields
          );
          selectedRows = rowData;
          // selectedRows.customField[customFieldIndex].value =
          //   dataToUpdate?.value;
        } else {
          selectedRows[columnKey] = dataToUpdate;
          selectedRows.customField[customFieldIndex].value = dataToUpdate;
        }
        break;
    }
    rows[rowIndex] = selectedRows;
    setRevenuePlanDetailsRows(rows);
  };
  return (
    <div
      className="transparent-background z-10 parent-width "
      style={{ pointerEvents: 'auto' }}
    >
      <div
        className="popup-window"
        style={{
          width: '90%',
          //   minWidth: 2000,
          maxWidth: 2000,
          height: '90%',
          padding: 0,
          paddingBottom: 20,
          overflow: 'hidden'
        }}
      >
        {getHeader()}{' '}
        <div className="column parent-width justify-content-between overflow-y-auto p-h-r p-v-s p-2">
          <div className="row p-2">
            <DKLabel text={`Primary Information`} className="fw-m fs-l" />
          </div>
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`number`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState1.number}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`revenue Plan Type`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.type}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`creation Triggered By`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState1.creationTriggeredBy}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`item`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.productId}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`created From`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState1.createdFrom}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`remaining Deferred Balance`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={getAmountBlockWithCurrencyForRevRec(
                Utility.roundOffToTenantDecimalScale(
                  popupState1.remainingDeferredBalance
                )
              )}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`revenue Recognition Rule`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={popupState.revRecRule}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`total Recognized`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={getAmountBlockWithCurrencyForRevRec(
                Utility.roundOffToTenantDecimalScale(
                  popupState.recognizedAmountInBase ||
                    popupState.recognizedAmount
                )
              )}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>
          <div className="row p-2">
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`amount`}
              className={`mb-4`}
              type={INPUT_TYPE.TEXT}
              value={getAmountBlockWithCurrencyForRevRec(
                Utility.roundOffToTenantDecimalScale(
                  popupState.amountInBase || popupState.amount
                )
              )}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}

              // canValidate={canValidate}
            />
            <DKInput
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title={`status`}
              className={`mb-4 ml-2`}
              type={INPUT_TYPE.TEXT}
              value={popupState.status}
              onChange={(value: any) => {}}
              required={false}
              readOnly={true}
            />
          </div>
          <div className="column parent-width justify-content-between">
            <div className="row p-2">
              <DKLabel text={`Revenue Management`} className="fw-m fs-l" />
            </div>
            <div className="row p-2">
              <div className="" style={{ width: 1400 }}>
                <DKCheckMark
                  className="mt-l text-black parent-width"
                  title={`Hold Revenue Recognition`}
                  isSelected={popupState.isHoldRevenueRecognition}
                  onClick={() => {
                    let tempPopupState = { ...popupState };
                    tempPopupState.isHoldRevenueRecognition =
                      !popupState.isHoldRevenueRecognition;
                    setPopupState(tempPopupState);
                  }}
                  disabled={
                    props.isReadOnly ||
                    props.isJEPosted ||
                    !isHoldRevenueRecognition
                  }
                />
              </div>

              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`revenue Recognition End Date Source`}
                className={`mb-4 ml-2`}
                type={INPUT_TYPE.TEXT}
                value={popupState.revRecEndDateSource}
                onChange={(value: any) => {}}
                required={false}
                readOnly={true}
              />
            </div>
            <div className="row p-2">
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`amount Source`}
                className={`mb-4`}
                type={INPUT_TYPE.TEXT}
                value={popupState.amountSource}
                onChange={(value: any) => {}}
                required={false}
                readOnly={true}

                // canValidate={canValidate}
              />
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`catch Up Period`}
                className={`mb-4 ml-2`}
                type={INPUT_TYPE.DROPDOWN}
                value={popupState.catchUpPeriod}
                onChange={(value: any) => {
                  updateCatchUpPeriod(value);
                }}
                required={false}
                readOnly={
                  props.isReadOnly ||
                  props.isJEPosted ||
                  !isHoldRevenueRecognition
                }
                dropdownConfig={{
                  title: 'Select Period',
                  allowSearch: true,
                  searchableKey: '',
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  searchApiConfig: null,
                  data: generateMonthYearArray(
                    tenantInfo.bookBeginningStartDate
                  ),
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={`${obj}`} />;
                  }
                }}
              />
            </div>
            <div className="row p-2">
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`recognition Method`}
                className={`mb-4`}
                type={INPUT_TYPE.TEXT}
                value={popupState.recognitionMethod}
                onChange={(value: any) => {}}
                required={false}
                readOnly={true}

                // canValidate={canValidate}
              />
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`rev Rec Start Date`}
                className={`mb-4 ml-2`}
                type={INPUT_TYPE.DATE}
                value={popupState.revRecStartDate}
                onChange={(value: any) => {
                  onStartDateSelected(value);
                }}
                required={false}
                readOnly={props.isReadOnly || props.isJEPosted || tableModified}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
              />
            </div>
            <div className="row p-2">
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`revenue Recognition Start Date Source`}
                className={`mb-4`}
                type={INPUT_TYPE.TEXT}
                value={popupState.revRecStartDateSource}
                onChange={(value: any) => {}}
                required={false}
                readOnly={true}

                // canValidate={canValidate}
              />
              <DKInput
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title={`rev Rec End Date`}
                className={`mb-4 ml-2`}
                type={INPUT_TYPE.DATE}
                value={popupState.revRecEndDate}
                onChange={(value: any) => {
                  onEndDateSelected(value);
                }}
                required={false}
                readOnly={props.isReadOnly || props.isJEPosted || tableModified}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
              />
            </div>
          </div>

          <div className="column parent-width justify-content-between">
            <div className="row p-2">
              <DKLabel text={`Revenue Management`} className="fw-m fs-l" />
            </div>
            <DKDataGrid
              needShadow={false}
              needColumnIcons={false}
              needBorder={true}
              needTrailingColumn={false}
              allowBulkOperation={false}
              allowColumnSort={false}
              filterData={[]}
              allowColumnDelete={false}
              allowRowEdit={!props.isReadOnly && !dateModified}
              allowColumnEdit={false}
              allowFilter={false}
              allowColumnAdd={false}
              allowBottomRowAdd={false}
              allowSearch={false}
              allowShare={false}
              // width={960}
              rows={revenuePlanDetailsRows ? revenuePlanDetailsRows : []}
              columns={[
                ...columnConfig,
                {
                  id: 'action',
                  key: 'action',
                  name: '',
                  type: INPUT_TYPE.BUTTON,
                  width: 250,
                  options: []
                }
              ]}
              onRowUpdate={onRowUpdate}
              onRowClick={onRowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRevenueRecognitionPlans;
