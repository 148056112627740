import { INPUT_TYPE, DKIcons } from 'deskera-ui-library';
import { IState } from '../../Models/Interfaces';

export const TRANSPORTATION_TYPE = {
  TRANSPORTATION_TYPE_ROAD: 'ROAD',
  TRANSPORTATION_TYPE_RAIL: 'RAIL',
  TRANSPORTATION_TYPE_AIR: 'AIR',
  TRANSPORTATION_TYPE_SHIP: 'SHIP'
};
export const NOT_GENERATED = 'NOT_GENERATED';

export const VEHICLE_TYPE = {
  VEHICLE_TYPE_REGULAR: 'REGULAR',
  VEHICLE_TYPE_OVER_DIMENSIONAL_CARGO: 'OVER_DIMENSIONAL_CARGO'
};

export const ADDRESS_DETAIL_TYPE = {
  DISPATCHED_FROM: 'DISPATCHED_FROM',
  DISPATCHED_TO: 'DISPATCHED_TO',
  BILL_FROM: 'BILL_FROM',
  BILL_TO: 'BILL_TO'
};

export const eWayTransactionTypes: any[] = [
  {
    displayName: 'eWayTransactionTypes.Regular',
    id: 'REGULAR'
  },
  {
    displayName: 'eWayTransactionTypes.Billto-Shipto',
    id: 'BILL_TO_SHIP_TO'
  },
  {
    displayName: 'eWayTransactionTypes.Billfrom-Dispatchfrom',
    id: 'BILL_FROM_DISPATCH_FROM'
  },
  {
    displayName: 'eWayTransactionTypes.Combinationof2and3',
    id: 'COMBINATION_OF_2_AND_3'
  }
];

export const billStatuses: any[] = [
  {
    displayName: 'Not Generated',
    id: 'NOT_GENERATED'
  },
  {
    displayName: 'Generated',
    id: 'GENERATED'
  },
  {
    displayName: 'Cancelled',
    id: 'CANCELLED'
  },
  {
    displayName: 'Expired',
    id: 'EXPIRED'
  },
  {
    displayName: 'Excluded',
    id: 'EXCLUDED'
  }
];

export const filterAndSortButtonConfig: any[] = [
  {
    label: 'E-Way Transaction Type',
    icon: 'keyboard_arrow_down',
    action: 'filterByTransactionType',
    color: '#000000',
    default: 'Regular',
    menu: eWayTransactionTypes
  },
  {
    label: 'Bill Status',
    icon: 'keyboard_arrow_down',
    action: 'filterByBillStatus',
    color: '#000000',
    default: 'Not Generated',
    menu: billStatuses
  }
];

export const documentTypesStore: any[] = [
  {
    id: 'INVOICE',
    name: 'INVOICE'
  },
  {
    id: 'CREDIT_NOTES',
    name: 'CREDIT NOTES'
  },
  {
    id: 'DELIVERY_CHALLANS',
    name: 'DELIVERY CHALLANS'
  }
];

export const transactionSubTypesStore: any[] = [
  {
    id: 'SUPPLY',
    name: 'SUPPLY'
  },
  {
    id: 'IMPORT',
    name: 'IMPORT'
  },
  {
    id: 'EXPORT',
    name: 'EXPORT'
  },
  {
    id: 'JOB_WORK',
    name: 'JOB WORK'
  },
  {
    id: 'FOR_OWN_USE',
    name: 'FOR OWN USE'
  },
  {
    id: 'JOB_WORK_RETURNS',
    name: 'JOB WORK RETURNS'
  },
  {
    id: 'SALES_RETURN',
    name: 'SALES RETURN'
  },
  {
    id: 'OTHERS',
    name: 'OTHERS'
  }
];

export const transactionTypesStore: any[] = [
  {
    id: 'REGULAR',
    name: 'REGULAR'
  },
  {
    id: 'BILL_TO_SHIP_TO',
    name: 'BILL TO - SHIP TO'
  },
  {
    id: 'BILL_FROM_DISPATCH_FROM',
    name: 'BILL FROM - DISPATCH FROM'
  },
  {
    id: 'COMBINATION_OF_2_AND_3',
    name: 'COMBINATION OF 2 AND 3'
  }
];

export enum EWAY_BILL_COLUMN_CODE {
  SHIP_BY_DATE = 'shipByDate',
  TRANSACTION = 'salesInvoiceCode',
  CONTACT = 'customerName',
  CONTACT_GSTIN = 'gstin',
  TOTAL_AMOUNT = 'totalAmount'
}

export const ewayBillinitialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export const EwayBillColumnConfig = [
  {
    name: 'Date',
    type: INPUT_TYPE.TEXT,
    index: 0,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    options: null,
    required: false,
    width: 200,
    editable: false,
    columnCode: EWAY_BILL_COLUMN_CODE.SHIP_BY_DATE,
    key: EWAY_BILL_COLUMN_CODE.SHIP_BY_DATE,
    id: EWAY_BILL_COLUMN_CODE.SHIP_BY_DATE
  },
  {
    name: 'Transaction',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 1,
    options: null,
    required: false,
    width: 300,
    editable: false,
    columnCode: EWAY_BILL_COLUMN_CODE.TRANSACTION,
    key: EWAY_BILL_COLUMN_CODE.TRANSACTION,
    id: EWAY_BILL_COLUMN_CODE.TRANSACTION
  },
  {
    name: 'Contact',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 2,
    options: null,
    required: false,
    width: 300,
    editable: false,
    columnCode: EWAY_BILL_COLUMN_CODE.CONTACT,
    key: EWAY_BILL_COLUMN_CODE.CONTACT,
    id: EWAY_BILL_COLUMN_CODE.CONTACT
  },
  {
    name: 'Contact GSTIN',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 300,
    editable: false,
    columnCode: EWAY_BILL_COLUMN_CODE.CONTACT_GSTIN,
    key: EWAY_BILL_COLUMN_CODE.CONTACT_GSTIN,
    id: EWAY_BILL_COLUMN_CODE.CONTACT_GSTIN
  },
  {
    name: 'Total Amount',
    type: INPUT_TYPE.NUMBER,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 250,
    editable: false,
    columnCode: EWAY_BILL_COLUMN_CODE.TOTAL_AMOUNT,
    key: EWAY_BILL_COLUMN_CODE.TOTAL_AMOUNT,
    id: EWAY_BILL_COLUMN_CODE.TOTAL_AMOUNT
  }
];

export const EwayBillProductDetailsColumnConfig = [
  {
    name: 'Product',
    type: INPUT_TYPE.TEXT,
    index: 0,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    options: null,
    required: false,
    width: 100,
    editable: false,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    id: 'documentSequenceCode'
  },
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    options: null,
    required: false,
    width: 180,
    editable: false,
    columnCode: 'name',
    key: 'name',
    id: 'name'
  },
  {
    name: 'HSN Code',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 1,
    options: null,
    required: false,
    width: 90,
    editable: false,
    columnCode: 'hsnOrSacCode',
    key: 'hsnOrSacCode',
    id: 'hsnOrSacCode'
  },
  {
    name: 'Quantity',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 2,
    options: null,
    required: false,
    width: 80,
    editable: false,
    columnCode: 'productQuantity',
    key: 'productQuantity',
    id: 'productQuantity'
  },
  {
    name: 'Taxable Amount',
    type: INPUT_TYPE.TEXT,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 140,
    editable: false,
    columnCode: 'unitPrice',
    key: 'unitPrice',
    id: 'unitPrice'
  },
  {
    name: 'CGST',
    type: INPUT_TYPE.NUMBER,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 85,
    editable: false,
    columnCode: 'cgstAmount',
    key: 'cgstAmount',
    id: 'cgstAmount'
  },
  {
    name: 'SGST',
    type: INPUT_TYPE.NUMBER,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 85,
    editable: false,
    columnCode: 'sgstAmount',
    key: 'sgstAmount',
    id: 'sgstAmount'
  },
  {
    name: 'IGST',
    type: INPUT_TYPE.NUMBER,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 85,
    editable: false,
    columnCode: 'igstAmount',
    key: 'igstAmount',
    id: 'igstAmount'
  },
  {
    name: 'CESS',
    type: INPUT_TYPE.NUMBER,
    formatter: (obj: any) => {
      return `<span class="text-13">${obj.value}</span>`;
    },
    index: 0,
    options: null,
    required: false,
    width: 85,
    editable: false,
    columnCode: 'cessAmount',
    key: 'cessAmount',
    id: 'cessAmount'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 30,
    options: []
  }
];

export interface EwayBillFormInterface {
  transactionDetails: {
    ewayDocumentType: {
      id: number | string;
      value: string;
    };
    transactionSubType: {
      id: number | string;
      value: string;
    };
    customerName: {
      id: number | string;
      value: string;
    };
    transactionType: {
      id: number | string;
      value: string;
    };
    salesInvoiceCode: {
      id: number | string;
      value: string;
    };
    shipByDate: string;
  };
  transportationDetails: {
    transporterName: {
      id: number | string;
      value: string;
    };
    distanceInKm: string;
  };
  modeOfTransportation: {
    transportationMode: string;
    road: {
      vehicleType: string;
      vehicleNo: string;
      transporterDocNo: string;
      transporterDocDate: string | Date;
    };
    rail: {
      billOfLadingNo: string;
      billOfLadingDate: string | Date;
    };
    air: {
      airwayBillNo: string;
      airwayBillDate: string | Date;
    };
    ship: {
      shipBillOfLadingNo: string;
      shipBillOfLadingDate: string | Date;
    };
  };
}

export const EwayBillFormInitialState: EwayBillFormInterface = {
  transactionDetails: {
    ewayDocumentType: { id: 0, value: documentTypesStore[0].name },
    transactionSubType: { id: 0, value: transactionSubTypesStore[0].name },
    customerName: { id: '', value: '' },
    transactionType: { id: 0, value: eWayTransactionTypes[0].id },
    salesInvoiceCode: { id: '', value: '' },
    shipByDate: ''
  },
  transportationDetails: {
    transporterName: { id: '', value: '' },
    distanceInKm: ''
  },
  modeOfTransportation: {
    transportationMode: TRANSPORTATION_TYPE.TRANSPORTATION_TYPE_ROAD,
    road: {
      vehicleType: VEHICLE_TYPE.VEHICLE_TYPE_REGULAR,
      vehicleNo: '',
      transporterDocNo: '',
      transporterDocDate: ''
    },
    rail: {
      billOfLadingNo: '',
      billOfLadingDate: ''
    },
    air: {
      airwayBillNo: '',
      airwayBillDate: ''
    },
    ship: {
      shipBillOfLadingNo: '',
      shipBillOfLadingDate: ''
    }
  }
};

export const ewayFilterInitialState = {
  ewayTransactionType: {
    id: null,
    value: ''
  },
  billStatus: {
    id: null,
    value: ''
  }
};
