import { getLocalisedText } from "../Translate/LanguageManager"

export default class DepositMapper {
    static getLabel() {
        var label = {}
        label.type = getLocalisedText('deposit')
        label.contact = 'contact'
        label.customerAddressPlaceholder = 'customer_address_placeholder_text_value'
        label.accountNamePlaceholder = 'account_name_placeholder_text_value'
        label.toAccount = 'to_account'
        label.depositTo = 'deposit_to'
        label.paymentDate = 'payment_date'
        label.accounts = 'accounts'
        label.accountName = 'account_name'
        label.accountDescription = 'account_description'
        label.taxAmount = 'tax_amount'
        label.paymentAmount = 'payment_amount'
        return label
    }
}