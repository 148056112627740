import {
  INPUT_TYPE,
  DKButton,
  DKIcons,
  DKInput,
  DKLabel
} from 'deskera-ui-library';
import Select from 'react-select';
import ApiConstants from '../Constants/ApiConstants';
import { INPUT_VIEW_DIRECTION } from '../Constants/Constant';
import AccountsService, { AccountAPIConfig } from '../Services/Accounts';
import DateFormatService from '../Services/DateFormat';
import Calendar from '../SharedComponents/Calender/Calendar';
import CustomInput from '../SharedComponents/CustomInput/CustomInput';
import { customStylesForContact } from '../SharedComponents/DocumentForm/DocumentFormSelectStyles';
import InputLabel from '../SharedComponents/InputLabel/InputLabel';
import Utility from '../Utility/Utility';
type onChange = (value: string) => any;
type returnFunction = (index: number) => any;
type DateSelected = (date: Date) => any;
type DateRangeSelected = (startDate: Date, endDate: Date) => any;
type CallBack = () => any;

export default class ComponentManager {
  static getReadOnlyDKInput(
    title: string,
    value: any,
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL
  ) {
    return (
      <DKInput
        title={title}
        value={[value]}
        readOnly={true}
        direction={direction}
      />
    );
  }

  static getReadOnlyField(title: string, value: any) {
    return (
      <div className="parent-width flex flex-col">
        <InputLabel text={title} />
        <DKLabel
          className="border rounded border-gray-100 w-full text-sm p-2 bg-gray1"
          text={value}
        />
        <p className={'text-xs mx-1 mt-1 text-transparent'}>{'asdasd'}</p>
      </div>
    );
  }
  static getTextFieldWithCallback(
    title: string,
    value: any,
    type: string,
    onChange: onChange
  ) {
    return (
      <div className="parent-width text-align-left">
        <CustomInput
          hasError={false}
          defaultValue={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={title}
          labelName={title}
          type={type}
        />
      </div>
    );
  }

  static getSelectionFieldWithCallback = (
    title: string,
    value: any,
    options: any[],
    callback: returnFunction,
    isMulti = false,
    hasError = false,
    requirePlaceholder = true
  ) => {
    return (
      <div className="parent-width flex flex-col justify-content-between text-align-left">
        <InputLabel className="ml-0" text={title} />
        <div className="mt-0.5"></div>
        <Select
          menuPosition={'fixed'}
          isMulti={isMulti}
          styles={{
            ...customStylesForContact,
            container: (provided: any, state: any) => ({
              ...provided,
              width: 'auto'
            })
          }}
          options={options}
          placeholder={requirePlaceholder ? title : ''}
          value={value}
          onChange={(value) => callback(value)}
        />
        <p
          className={
            'text-xs mx-1 mt-1 ' +
            (hasError ? 'text-red-600' : 'text-transparent')
          }
        >
          {'Cannot be left empty.'}
        </p>
      </div>
    );
  };

  ///////////////////////////////////
  //Date Selection
  ///////////////////////////////////
  static getCalendar(
    onClickOutSide: CallBack,
    date: Date,
    dateSelected: DateSelected
  ) {
    return (
      <div
        className="position-absolute bg-white"
        style={{
          left: 0,
          top: 20,
          zIndex: 9999
        }}
      >
        <Calendar
          className="p-s bg-white border-radius-s shadow-m border-box"
          onClickOutside={() => {
            onClickOutSide();
          }}
          selectedDate={date}
          isDateRange={false}
          onSelectDate={dateSelected}
        />
      </div>
    );
  }

  static getDateButton(
    title: string,
    showCalendar: boolean,
    date: Date,
    onButtonClick: CallBack,
    dateSelected: DateSelected
  ) {
    return (
      <div className="column position-relative parent-width">
        <DKLabel text={title} />
        <DKButton
          style={{ backgroundColor: '#FAFAFA', width: '100%' }}
          icon={DKIcons.ic_calendar}
          className="mt-0.5 border border-2 border-gray-300 text-sm font-normal text-black justify-content-between"
          title={DateFormatService.getDateStrFromDate(date)}
          onClick={() => onButtonClick()}
          isReverse={true}
        />
        {showCalendar &&
          ComponentManager.getCalendar(onButtonClick, date, dateSelected)}
      </div>
    );
  }

  ///////////////////////////////////
  //Date Range Selection
  ///////////////////////////////////
  static getRangeCalendar(
    onClickOutSide: CallBack,
    startDate: Date,
    endDate: Date,
    dateRangeSelected: DateRangeSelected,
    isFromLeft: boolean
  ) {
    return (
      <div
        className="position-absolute bg-white"
        style={{
          left: isFromLeft ? 0 : 'auto',
          right: isFromLeft ? 'auto' : 0,
          top: 20,
          zIndex: 9999
        }}
      >
        <Calendar
          className="p-s bg-white border-radius-s shadow-m border-box"
          onClickOutside={() => {
            onClickOutSide();
          }}
          selectedDate={new Date()}
          isDateRange={true}
          onSelectDate={(date: Date) => {}}
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          onSelectDateRange={dateRangeSelected}
        />
      </div>
    );
  }

  static getDateRangeButton(
    title: string,
    showCalendar: boolean,
    startDate: Date,
    endDate: Date,
    onButtonClick: CallBack,
    dateSelected: DateRangeSelected,
    isFromLeft = true
  ) {
    return (
      <div className="column position-relative parent-width">
        <DKLabel text={title} />
        <DKButton
          style={{ backgroundColor: '#FAFAFA', width: '100%' }}
          icon={DKIcons.ic_calendar}
          className="mt-0.5 border border-2 border-gray-300 text-sm font-normal text-black justify-content-between"
          title={`${DateFormatService.getDateStrFromDate(
            startDate
          )} to ${DateFormatService.getDateStrFromDate(endDate)}`}
          onClick={() => onButtonClick()}
          isReverse={false}
        />
        {showCalendar &&
          ComponentManager.getRangeCalendar(
            onButtonClick,
            startDate,
            endDate,
            dateSelected,
            isFromLeft
          )}
      </div>
    );
  }
  static getTextInputFieldWithCallback(
    title: string,
    value: any,
    type: string,
    onChange: onChange,
    hasError = false
  ) {
    return (
      <div className="parent-width text-align-left flex flex-col">
        <InputLabel text={title} />
        <input
          className={
            'border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder px-3.5 ' +
            (hasError
              ? 'focus:outline-none focus:ring-2 focus:ring-red-500 border-red-400'
              : 'focus:outline-none focus:ring-2 focus:ring-blue-500')
          }
          style={{ minHeight: '37px' }}
          placeholder={title}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={() => {}}
          value={value}
          type={type}
        />
        <p
          className={
            'text-xs mx-1 mt-1 ' +
            (hasError ? 'text-red-600' : 'text-transparent')
          }
        >
          {'Cannot be left empty.'}
        </p>
      </div>
    );
  }

  static getAccountSelectionField(
    title: string,
    required: boolean,
    canValidate: boolean,
    direction: INPUT_VIEW_DIRECTION,
    value: any,
    onChange: any,
    data: any[],
    addAccountClicked: any,
    Query = ''
  ) {
    return (
      <DKInput
        title={title}
        required={required}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={value}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={onChange}
        readOnly={false}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: Utility.isEmpty(data) ? [] : data,
          renderer: (index: any, account: any) => {
            return account.name;
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 10,
                Query: Query ? Query : `status=ACTIVE`
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              let filtered = response?.content?.filter(
                (acc: any) => acc.status === 'ACTIVE'
              );
              return filtered;
            },
            debounceTime: 300
          },
          button: {
            title: 'Add Account',
            className: 'bg-button text-white',
            onClick: addAccountClicked
          }
        }}
      />
    );
  }
}
