import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  DKSpinner,
  showAlert,
  DKListPicker2
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import { BarCodeScannerConfigManger } from '../../Managers/BarCodeScannerConfigManger';
import ProductService from '../../Services/Product';
import BarcodeDataTable from './BarcodeDataTable';
import Utility, { deepClone } from '../../Utility/Utility';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import AppManager from '../../Managers/AppManager';
import BarcodeIcon from '../../Assets/ic_barcode.png';
import { MOBILE_APP_ACTIONS } from '../../Constants/Constant';
import { isTabletView } from '../../Utility/ViewportSizeUtils';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';

export default function BarcodeSearchPopup(props: any) {
  const [productRows, setProductRows] = useState<any>([]);
  const [barcode, setBarcode] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [focusedRowIndex, setFocusedRowIndex] = useState(0);
  const [isSellModule, setIsSellModule] = useState(props.isSellModule);
  const [listPickerVisibilityMap, setListPickerVisibilityMap] = useState<{
    [rowIndex: number]: any[];
  }>({});
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isFromBarcodeScanner, setIsFromBarcodeScanner] = useState(false);

  const [columnConfig, setColumnConfig] = useState(
    BarCodeScannerConfigManger.get()
  );

  useEffect(() => {
    AppManager.handleMobileAppMessageListener(onMobileAppMessageReceived, true);

    return () => {
      AppManager.handleMobileAppMessageListener(
        onMobileAppMessageReceived,
        false
      );
    };
  });

  const onMobileAppMessageReceived = (event: any) => {
    const data = event?.data?.mobileAppScannedData;
    if (!Utility.isEmpty(data)) {
      setIsFromBarcodeScanner(true);
      setBarcode(data);
    }
  };

  const getListPickerForMultipleOptions = (focusedRowIndex: number) => {
    return (
      <DKListPicker2
        title="Select Product"
        data={listPickerVisibilityMap[focusedRowIndex]}
        className="position-absolute z-index-3 bg-white border-m shadow-m"
        style={{
          top: 22,
          left: -9
        }}
        renderer={(index: number, obj: any) => {
          return (
            <div className="column parent-width">
              <div className="row">{obj.name}</div>
              <div className="row">
                <div style={{ marginTop: 3, marginRight: 2 }}>
                  <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 12 12"
                    fill="none"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <path
                      d="M0 1.50006H1.17434V10.5001H0V1.50006ZM1.89308 10.5001H2.55422V1.50006H1.89308V10.5001ZM3.03077 10.5001H3.6906V1.50006H3.03077V10.5001ZM4.55859 10.5001H6.00393V1.50006H4.55859V10.5001ZM7.16125 10.5001H7.88392V1.50006H7.16125V10.5001ZM9.00458 10.5001H12V1.50006H9.00458V10.5001Z"
                      fill="#B5B4B4"
                    ></path>
                  </svg>
                </div>
                <div className="text-gray fs-s mt-xs">{obj.barcode}</div>
              </div>
            </div>
          );
        }}
        onSelect={(index: number, obj: any) => {
          let pickerVisibilityMap = { ...listPickerVisibilityMap };
          delete pickerVisibilityMap[focusedRowIndex];
          setListPickerVisibilityMap(pickerVisibilityMap);
          updateData(obj);
        }}
        onClose={() => {}}
        searchableKey="name"
        allowSearch={true}
      />
    );
  };

  const pickFormatter = (column: any, row: any, rowIndex: number) => {
    setCurrentRowIndex(rowIndex);
    switch (column.key) {
      case 'barcode':
        return (
          <div className="row position-relative">
            <input
              id={'barcode_' + rowIndex}
              className="app-font parent-width"
              style={{ outline: 'none' }}
              value={row?.barcode}
              autoFocus
              onFocus={(e) => {
                setFocusedRowIndex(rowIndex);
              }}
              onChange={(e) => {
                console.log('currentRowIndex: ', currentRowIndex);
                setIsFromBarcodeScanner(false);
                setBarcode(e.target.value);
                row.barcode = e.target.value;
                let data = [...productRows];
                data[rowIndex] = row;
                setProductRows([...data]);
              }}
            />
            {rowIndex === focusedRowIndex &&
              listPickerVisibilityMap[focusedRowIndex]?.length > 1 &&
              getListPickerForMultipleOptions(focusedRowIndex)}
          </div>
        );

      case 'product':
        return (
          <DKLabel
            text={row?.product || ''}
            className="text-align-left white-space-nowrap"
          />
        );

      case 'productQuantity':
        return (
          <input
            className="app-font parent-width"
            style={{ outline: 'none' }}
            value={row?.productQuantity}
            type="number"
            onChange={(e) => {
              row.productQuantity = e.target.value;
              let data = [...productRows];
              data[rowIndex] = row;
              setProductRows([...data]);
              focusLastRowFirstColumn(data);
            }}
          />
        );
      case 'productPrice':
        return (
          <div
            className={
              'row align-items-center bg-blend-color  parent-width justify-end'
            }
          >
            <DKLabel
              text={
                Utility.getCurrencySymbolFromCode(tenantInfo?.currency) + ' '
              }
              className="text-align-left white-space-nowrap"
            />
            <input
              type="number"
              className="app-font parent-width"
              style={{ outline: 'none' }}
              value={row?.productPrice}
              onChange={(e) => {
                row.productPrice = e.target.value;
                if (isSellModule) {
                  row.salesPrice = e.target.value;
                } else {
                  row.purchasePrice = e.target.value;
                }
                let data = [...productRows];
                data[rowIndex] = row;
                setProductRows([...data]);
                focusLastRowFirstColumn(data);
              }}
            />
          </div>
        );
      case 'productDiscount':
        return (
          <div
            className={'row align-items-center bg-blend-color  parent-width'}
          >
            <DKLabel
              text={
                Utility.getCurrencySymbolFromCode(tenantInfo?.currency) + ' '
              }
              className="text-align-left white-space-nowrap"
            />
            <input
              type="number"
              className="app-font parent-width"
              style={{ outline: 'none' }}
              value={row?.productDiscount}
              onChange={(e) => {
                row.productDiscount = e.target.value;
                row.discountAmount = e.target.value;
                let data = [...productRows];
                data[rowIndex] = row;
                setProductRows([...data]);
                focusLastRowFirstColumn(data);
              }}
            />
          </div>
        );
      case 'deleteObj':
        return (
          <DKIcon
            src={DKIcons.ic_delete}
            className="ic-s cursor-pointer opacity-50 hover:opacity-70"
            onClick={() => {
              onDelete(rowIndex);
            }}
          />
        );
      default:
        return row;
    }
  };

  const updateColumnConfig = () => {
    const copyOfColumnConfig = deepClone(columnConfig);
    copyOfColumnConfig.forEach((column: any) => {
      column['renderer'] = ({ row, rowIndex }: any) =>
        pickFormatter(column, row, rowIndex);
    });
    setColumnConfig(copyOfColumnConfig);
  };

  useEffect(() => {
    let rows = [];
    let newRow = {
      barcode: '',
      productQuantity: 0,
      product: '',
      productPrice: 0,
      productDiscount: 0
    };
    rows.push(newRow);
    setProductRows(rows);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!Utility.isEmpty(barcode)) {
        try {
          ProductService.getProductsByBarCode(barcode, isSellModule).then(
            (response) => {
              setListPickerVisibilityMap({});
              if (response.length === 0) {
                setErrorMessage(
                  'No product found associated with barcode ' + barcode
                );
                setTimeout(() => {
                  setErrorMessage('');
                }, 1000);
                onDelete(currentRowIndex);
                setBarcode(undefined);
                return;
              }
              if (response.length === 0) {
                return;
              }
              if (response.length === 1) {
                let product = response[0];
                updateData(product);
              } else if (response.length > 1) {
                setListPickerVisibilityMap({
                  ...listPickerVisibilityMap,
                  [focusedRowIndex]: [...response]
                });
              }
            }
          );
        } catch (err) {
          console.error('Error loading products: ', err);
        }
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [barcode]);

  const updateData = (product: any) => {
    let rowIndex =
      currentRowIndex !== focusedRowIndex ? focusedRowIndex : currentRowIndex;
    let existingData = productRows.filter((item: any) => {
      if (item.productId === product.productId) {
        item.barcode = product.barcode;
        item.productQuantity += 1;
        return item;
      }
    });
    if (existingData?.length === 1) {
      if (currentRowIndex !== focusedRowIndex) {
        onDelete(focusedRowIndex);
      } else {
        let productData = [...productRows];
        productData[rowIndex] = {
          barcode: '',
          productQuantity: 0,
          product: '',
          productPrice: 0,
          productDiscount: 0
        };
        setProductRows(productData);
        focusLastRowFirstColumn(productData);
      }
      setBarcode(undefined);
      return;
    }

    let data = [...productRows];

    data[rowIndex] = {
      ...product,
      barcode: product.barcode,
      productQuantity: 1,
      product: product.name,
      productPrice: isSellModule ? product.salesPrice : product.purchasePrice,
      productDiscount: 0
    };
    if (currentRowIndex === focusedRowIndex) {
      data.push({
        barcode: '',
        productQuantity: 0,
        product: '',
        productPrice: 0,
        productDiscount: 0
      });
    }

    setProductRows(data);
    setBarcode(undefined);
  };

  useEffect(() => {
    updateColumnConfig();
  }, [productRows, listPickerVisibilityMap]);

  const onDelete = (rowIndex: any) => {
    let productData = [...productRows];
    if (rowIndex > -1) {
      productData.splice(rowIndex, 1);
    }
    if (productData.length === 0 || productData.length === rowIndex) {
      productData.push({
        barcode: '',
        productQuantity: 0,
        product: '',
        productPrice: 0,
        productDiscount: 0
      });
    }
    setProductRows(productData);
    focusLastRowFirstColumn(productData);
  };

  const focusLastRowFirstColumn = (productData: any) => {
    setTimeout(() => {
      if (document)
        document.getElementById('barcode_' + (productData.length - 1))?.focus();
    }, 1000);
  };

  const barcodeScanner = () => (
    <DKButton
      title={`Scan`}
      onClick={() => {
        Utility.postMobileAppActions(MOBILE_APP_ACTIONS.DOC_BARCODE_SCANNER);
      }}
      icon={BarcodeIcon}
      className={`${'text-blue'} fw-m p-0`}
    />
  );

  const isDataValid = () => {
    // Check for negative discount
    const dataHasNegativeDiscountAmt = productRows.some(
      (row: any) => row.discountAmount && +row.discountAmount < 0
    );
    if (dataHasNegativeDiscountAmt) {
      showAlert('Error', 'One or more products has negative discount value.');
      return false;
    }

    // Check for quantity
    const dataHasNegativeQty = productRows.some(
      (row: any) => row.productQuantity && +row.productQuantity < 0
    );
    if (dataHasNegativeQty) {
      showAlert('Error', 'One or more products has negative quantity value.');
      return false;
    }

    return true;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKTooltipWrapper
            content="User can use barcode scanner to add product(s) in the document."
            tooltipClassName="bg-deskera-secondary width-auto"
            tooltipStyle={{ whiteSpace: 'pre-wrap' }}
          >
            <div className="row">
              <DKLabel text="Search using barcode" className="fw-m fs-l" />
              <DKIcon src={DKIcons.ic_info} className="ic-xs opacity-40 ml-s" />
            </div>
          </DKTooltipWrapper>
          {isTabletView() ? barcodeScanner() : null}
          {barcode && <DKSpinner iconClassName="ic-s-2 " className={'ml-s'} />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Done'}
            className="bg-button text-white"
            onClick={() => {
              if (isDataValid()) {
                let data = productRows.filter((item: any) => {
                  if (item.product !== '') return item;
                });
                props.onDone(data);
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: '80%',
            maxWidth: '90%',
            maxHeight: '90%',
            height: 400,
            padding: 0,
            paddingBottom: 10,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width parent-height mt-r overflow-y-auto dk-input-holder">
            <BarcodeDataTable
              columns={columnConfig}
              errorMessage={errorMessage}
              rows={deepClone(productRows)}
            />
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
