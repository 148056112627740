import ApiConstants from '../Constants/ApiConstants';
import httpClient from './Interceptor2';
class PriceBook {
  static createPriceBook = (payload: any) => {
    return httpClient.post(ApiConstants.URL.PRICE_BOOK.CREATE, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  };
  static getPriceBookList = (params: any) => {
    return httpClient.get(`${ApiConstants.URL.PRICE_BOOK.GET_ALL}`, { params });
  };
}
export const getProductsById = (productsIdList: string[] = []) => {
  return httpClient.post(
    `${ApiConstants.URL.PRICE_BOOK.FETCH_PRODUCTS_BY_ID}`,
    productsIdList
  );
};
export const deletePriceBook = (id: any) => {
  return httpClient.delete(`${ApiConstants.URL.PRICE_BOOK.CREATE}/${id}`);
};
export const updatePriceBook = (id: any, payload: any) => {
  return httpClient.put(`${ApiConstants.URL.PRICE_BOOK.CREATE}/${id}`, payload);
};
export const updatePriceBookStatus = (id: any, statusId: any) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return httpClient.put(
    ApiConstants.URL.PRICE_BOOK.SET_ACTIVE(id),
    statusId,
    headers
  );
};
export const exportPriceBook = (query: any) => {
  return httpClient.get(ApiConstants.URL.PRICE_BOOK.EXPORT_PRICE_BOOK, {
    responseType: 'blob',
    params: query
  });
};
export default PriceBook;
