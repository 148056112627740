import {
  showToast,
  TOAST_TYPE,
  DKInput,
  DKCheckMark,
  INPUT_TYPE,
  DKLabel,
  Toggle,
  DKButton
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  POPUP_CALLBACKS_TYPE,
  TEMPLATE_CATEGORY
} from '../../Constants/Constant';
import { useEffect, useRef, useState } from 'react';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';

import DateFormatService from '../../Services/DateFormat';
import {
  CUSTOM_TYPE_OPTION_RECURRING,
  initialRecurringFormValues,
  RecurringDocumentProps,
  TYPE_OPTION_RECURRING
} from '../../Models/RecurringDocument';
import { addDays, addYears, addMonths, addWeeks, parse } from 'date-fns';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import QuotationService from '../../Services/Quotation';
import BillService from '../../Services/Bill';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import GenericService from '../../Services/GenericService';
import { fetchJournal } from '../../Redux/Slices/Journal';
import Stepper from '../Stepper';
import EmailComposer from '../Email/EmailComposer';
import EmailDocPreview from '../Email/EmailDocPreview';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { fetchOrders } from '../../Redux/Slices/PurchaseOrdersSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';

const RecurringDocument: React.FC<RecurringDocumentProps> = (props) => {
  const [document] = useState<any>(deepClone(props.documentDetails));
  const [documentType] = useState<any>(props.documentType);
  const [formState, setFormState] = useState<any>(
    deepClone(initialRecurringFormValues)
  );
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [isInfinite, setIsInfinite] = useState<boolean>(false);
  const [isCustomInterval, setIsCustomInterval] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [currentStep, setCurrentStep] = useState(0);
  const [recurringScheduleDetails, setRecurringScheduleDetails] =
    useState<any>();
  const [nextDocumentDate, setNextDocumentDate] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [printUUID, setPrintUUID] = useState<string>();
  const [docDesignerOpened, setDocDesignerOpened] = useState(false);

  const dispatch = useAppDispatch();
  const emailRef = useRef<any>();
  const tenantInfo = useAppSelector(activeTenantInfo);

  useEffect(() => {
    loadScheduleDetails();
  }, []);

  useEffect(() => {
    evaluateNextDocumentDate();
  }, [formState]);

  const loadScheduleDetails = () => {
    const payload: any = {
      documentCodes: [document.documentCode],
      documentType: documentType
    };
    GenericService.recurrScheduleDetails(payload)
      .then((res: any) => {
        const updatedState = formState;
        if (Utility.isEmpty(res)) {
          updatedState.interval.options = deepClone(TYPE_OPTION_RECURRING);
          updatedState.interval.defaultValue = TYPE_OPTION_RECURRING[1];
          updatedState.interval.value = TYPE_OPTION_RECURRING[1]; //TYPE_OPTION_RECURRING[1]
          updatedState.customInterval.options = deepClone(
            CUSTOM_TYPE_OPTION_RECURRING
          );
          updatedState.customInterval.defaultValue =
            CUSTOM_TYPE_OPTION_RECURRING[2];
          updatedState.customInterval.value = CUSTOM_TYPE_OPTION_RECURRING[2]; //CUSTOM_TYPE_OPTION_RECURRING[2]
          setFormState({ ...updatedState });
        } else {
          setIsEditMode(true);
        }

        const getInterVal: any = TYPE_OPTION_RECURRING.find(
          (item) => item.value === res[0].interval
        );

        const getCustomInterVal: any = CUSTOM_TYPE_OPTION_RECURRING.find(
          (item) => item.value === res[0].customInterval
        );

        setStartDate(
          parse(
            res && res.length > 0 && res[0].firstDocumentDate,
            'dd-MM-yyyy',
            new Date()
          )
        );

        res && res.length > 0 && res[0].endingDate
          ? setEndDate(
              parse(
                res && res.length > 0 && res[0].endingDate,
                'dd-MM-yyyy',
                new Date()
              )
            )
          : setIsInfinite(true);

        updatedState.interval.options = deepClone(TYPE_OPTION_RECURRING);
        updatedState.interval.defaultValue = getInterVal;
        updatedState.interval.value = getInterVal; //TYPE_OPTION_RECURRING[1]

        if (!Utility.isEmpty(getCustomInterVal)) {
          updatedState.customInterval.options = deepClone(
            CUSTOM_TYPE_OPTION_RECURRING
          );
          updatedState.customInterval.defaultValue = getCustomInterVal;
          updatedState.customInterval.value = getCustomInterVal; //CUSTOM_TYPE_OPTION_RECURRING[2]
          updatedState.customIntervalPeriod.value = res[0].customPeriod;
        }

        if (Utility.isEmpty(getCustomInterVal)) {
          updatedState.customInterval.options = deepClone(
            CUSTOM_TYPE_OPTION_RECURRING
          );
          updatedState.customInterval.defaultValue =
            CUSTOM_TYPE_OPTION_RECURRING[2];
          updatedState.customInterval.value = CUSTOM_TYPE_OPTION_RECURRING[2]; //CUSTOM_TYPE_OPTION_RECURRING[2]
          setFormState({ ...updatedState });
        }

        setRecurringScheduleDetails(res);
        setIsEnabled(res[0].active);
        setIsCustomInterval(
          getInterVal.value === TYPE_OPTION_RECURRING[3].value
        );
        setFormState({ ...updatedState });
      })
      .catch((err) => {});
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_RECURRING_DOCUMENT,
        data: () => {
          saveRecurringDocument();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (startDate < new Date()) {
      showToast(
        'First document must be after todays date.',
        TOAST_TYPE.FAILURE
      );
    } else {
      evaluateNextDocumentDate();
    }
    if (endDate < startDate) {
      showToast(
        'Ending date cannot be before first document date.',
        TOAST_TYPE.FAILURE
      );
    }
  }, [startDate, endDate]);

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    emailRef.current.updateAutoSendForRecurring = passingData.data; //sendEmail
  };

  const validateAccounts = () => {
    let result = true;
    if (!isEnabled) result = false;
    if (formState.interval.value === '') result = false;
    if (!startDate) result = false;

    if (
      formState.interval.value.value === TYPE_OPTION_RECURRING[3].value &&
      !validateCustomIntervalPeriod()
    ) {
      result = false;
      showToast('Please enter a valid custom period.', TOAST_TYPE.FAILURE);
    }

    if (startDate < new Date()) {
      result = false;
      showToast(
        'First document must be after todays date.',
        TOAST_TYPE.FAILURE
      );
    }

    if (endDate < startDate) {
      result = false;
      showToast(
        'Ending date cannot be before first document date.',
        TOAST_TYPE.FAILURE
      );
    }

    if (!endDate && !isInfinite) {
      result = false;
      showToast('Please select correct ending date', TOAST_TYPE.FAILURE);
    }

    return result;
  };

  const onSubmit = () => {
    if (isEnabled && !isEditMode && !validateAccounts()) {
      // showToast('Please fill up mandatory fields', TOAST_TYPE.FAILURE);
      return;
    }

    let payload: any = {
      active: isEnabled,
      customTemplate: false,
      documentCode: document.documentCode || document.jeCode,
      documentType: documentType,
      endingDate:
        endDate && !isInfinite
          ? DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : null,
      firstDocumentDate: startDate
        ? DateFormatService.getDateStrFromDate(
            startDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        : null,
      interval: formState.interval.value.value,
      infinite: isInfinite,
      insertPaymentLink: false,
      sendEmailNotification: false,
      timeZoneOffset: Utility.getTimeZoneOffsetValue()
    };

    if (isCustomInterval) {
      payload = {
        ...payload,
        customPeriod: formState.customIntervalPeriod.value,
        customInterval: formState.customInterval.value.value
      };
    }

    return payload;
  };

  const saveRecurringDocument = async () => {
    // recurring step api call
    if (currentStep === 0) {
      const payload = onSubmit();
      if (payload) {
        setLoading(true);
        GenericService.saveRecurringDocument(payload)
          .then((res: any) => {
            setLoading(false);
            closePopup(props.documentType);
            showToast(
              'Recurring document has been set successfully',
              TOAST_TYPE.SUCCESS
            );
          })
          .catch((err) => {
            setLoading(false);
            closePopup(props.documentType);
            showToast('Unable to set recurring document', TOAST_TYPE.FAILURE);
          });
      }
    }

    // auto-send api call
    if (currentStep === 1) {
      if (emailRef?.current) {
        emailRef.current.updateAutoSendForRecurring(); //sendEmail
      }
    }
  };

  const closePopup = (docType: DOC_TYPE) => {
    switch (docType) {
      case DOC_TYPE.INVOICE:
        // InvoiceService.apiConfig = {};
        dispatch(fetchInvoices());
        break;
      case DOC_TYPE.QUOTE:
        QuotationService.apiConfig = {};
        dispatch(fetchQuotes());
        break;
      case DOC_TYPE.BILL:
        BillService.apiConfig = {};
        dispatch(fetchBills());
        break;
      case DOC_TYPE.ORDER:
        QuotationService.apiConfig = {};
        dispatch(fetchOrders());
        break;
      case DOC_TYPE.JOURNAL_ENTRY:
        dispatch(fetchJournal());
        break;
    }
    if (props.closePopup) {
      props.closePopup();
    }
  };

  const ontoggleChange = (checked: ConstrainBoolean) => {
    let isChecked: any = checked;
    setIsEnabled(isChecked);
  };

  const ontoggleChangeAutoSend = (checked: ConstrainBoolean) => {
    let isChecked: any = checked;
    setIsEnabled(isChecked);
  };

  const onSelectChange = (value: any) => {
    let updatedState = formState;
    updatedState.interval.value = value[0];
    updatedState.interval.defaultValue = value[0];
    if (value[0] === 3) {
      setIsCustomInterval(true);
    } else {
      setIsCustomInterval(false);
    }
    setFormState({ ...updatedState });
  };

  const getCustomLabel = () => {
    switch (formState.customInterval.value) {
      case 0:
        return 'Month(s)';
      case 1:
        return 'Week(s)';
      case 2:
        return 'Day(s)';
      default:
        return 'Period';
    }
  };

  const evaluateNextDocumentDate = () => {
    let result: Date = new Date();
    if (startDate) {
      switch (formState.interval.value.value) {
        case TYPE_OPTION_RECURRING[0].value: //yearly
          result = addYears(startDate, 1);
          break;

        case TYPE_OPTION_RECURRING[1].value: //monthly
          result = addMonths(startDate, 1);
          break;

        case TYPE_OPTION_RECURRING[2].value: //weekly
          result = addWeeks(startDate, 1);
          break;

        case TYPE_OPTION_RECURRING[3].value: //custom
          if (
            formState.customInterval.value.value ===
            CUSTOM_TYPE_OPTION_RECURRING[0].value
          ) {
            //custom-months
            result = addMonths(
              startDate,
              +formState.customIntervalPeriod.value
            );
          }
          if (
            formState.customInterval.value.value ===
            CUSTOM_TYPE_OPTION_RECURRING[1].value
          ) {
            //custom-weeks
            result = addWeeks(startDate, +formState.customIntervalPeriod.value);
          }
          if (
            formState.customInterval.value.value ===
            CUSTOM_TYPE_OPTION_RECURRING[2].value
          ) {
            //custom-days
            result = addDays(startDate, +formState.customIntervalPeriod.value);
          }
          break;
      }
      setNextDocumentDate(DateFormatService.getDateStrFromDate(result));
    }
  };

  const getStepperData = (onClickHandler: any) => {
    return [
      {
        title: 'Recurring Step',
        requiredProps: [],
        reqError: 'Error 1',
        getChild: (props: any) => getRecurringStep(),
        onClick: (event: any) => onClickHandler(event, 0)
      },
      {
        title: 'Auto-Send',
        requiredProps: [],
        reqError: 'Error 2',
        getChild: (props: any) => getAutoSendStep(),
        onClick: (event: any) => onClickHandler(event, 0)
      }
    ];
  };

  const onSkipToStep = (event: any, index: any) => {
    console.log('event, index', event, index);

    if (event === 0) {
      setCurrentStep(event);
    }

    if (event === 1) {
      if (
        formState.interval.value.value === TYPE_OPTION_RECURRING[3].value &&
        !validateCustomIntervalPeriod()
      ) {
        showToast('Please enter a valid custom period.', TOAST_TYPE.FAILURE);
        setCurrentStep(0);
      } else if (!startDate || startDate < new Date()) {
        showToast(
          'Please select valid first document date',
          TOAST_TYPE.FAILURE
        );
        setCurrentStep(0);
      } else if ((!isInfinite && !endDate) || endDate < startDate) {
        showToast('Please select valid end date', TOAST_TYPE.FAILURE);
        setCurrentStep(0);
      } else {
        setCurrentStep(event);
      }
    }
  };

  const getCurrentStepChild = () => {
    const currStep = steps[currentStep];
    return currStep.getChild({
      state: '',
      tableName: '',
      setData: () => {}
    });
  };

  const steps = getStepperData(onSkipToStep);

  const validateCustomIntervalPeriod = () => {
    let isValid = true;
    if (formState.interval.value.value === TYPE_OPTION_RECURRING[3].value) {
      if (
        formState.customIntervalPeriod.value === '' ||
        formState.customIntervalPeriod.value === '0'
      ) {
        isValid = false;
      }

      if (
        formState.customInterval.value.value ===
          CUSTOM_TYPE_OPTION_RECURRING[0].value &&
        +formState.customIntervalPeriod.value > 12
      ) {
        isValid = false;
      }

      if (
        formState.customInterval.value.value ===
          CUSTOM_TYPE_OPTION_RECURRING[1].value &&
        +formState.customIntervalPeriod.value > 52
      ) {
        isValid = false;
      }

      if (
        formState.customInterval.value.value ===
          CUSTOM_TYPE_OPTION_RECURRING[2].value &&
        +formState.customIntervalPeriod.value > 366
      ) {
        isValid = false;
      }
    }
    return isValid;
  };

  const getRecurringStep = () => {
    return (
      <div className="">
        <div className="flex align-items-center">
          <DKLabel text="Set Recurring *" className="title fw-m text-sm mr-5" />
          <Toggle
            className={`${isEnabled ? 'bg-button' : 'bg-gray'} box-content`}
            isOn={isEnabled}
            onChange={() => {
              ontoggleChange(!isEnabled);
            }}
          />
        </div>
        <div className="row align-items-start mt-xl" style={{ height: 80 }}>
          {formState.interval.options && (
            <div className="row parent-width" style={{ maxWidth: '430px' }}>
              <DKInput
                className="pr-s"
                type={INPUT_TYPE.DROPDOWN}
                readOnly={!isEnabled}
                formatter={(obj: any) => {
                  return obj.label;
                }}
                title={'Interval'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={formState.interval.value}
                onChange={(option: any) => {
                  let updatedState = { ...formState };
                  updatedState.interval.defaultValue = option;
                  updatedState.interval.value = option;
                  if (option.value === TYPE_OPTION_RECURRING[3].value) {
                    setIsCustomInterval(true);
                  } else {
                    setIsCustomInterval(false);
                  }
                  setFormState({ ...updatedState });
                }}
                dropdownConfig={{
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  data: formState.interval.options
                    ? formState.interval.options
                    : [],
                  renderer: (index: any, obj: any) => {
                    return <DKLabel text={`${obj.label}`} />;
                  }
                }}
              />
            </div>
          )}
          {isCustomInterval && (
            <div className="row parent-width align-items-start parent-height">
              <DKInput
                className="ml-r mr-l"
                required={true}
                validator={(value: any) => validateCustomIntervalPeriod()}
                canValidate={true}
                errorMessage={'Enter a valid period'}
                title={getCustomLabel()}
                type={INPUT_TYPE.NUMBER}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={formState.customIntervalPeriod.value}
                onChange={(text: any) => {
                  let updatedState = { ...formState };
                  updatedState.customIntervalPeriod.defaultValue = text;
                  updatedState.customIntervalPeriod.value = text;
                  setFormState({ ...updatedState });
                }}
                readOnly={!isEnabled}
              />

              {formState.customInterval.options && isCustomInterval && (
                <DKInput
                  type={INPUT_TYPE.DROPDOWN}
                  readOnly={!isEnabled}
                  formatter={(obj: any) => {
                    return obj.label;
                  }}
                  title={'Interval'}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.customInterval.value}
                  onChange={(option: any) => {
                    let updatedState = { ...formState };
                    updatedState.customInterval.defaultValue = option;
                    updatedState.customInterval.value = option;
                    setFormState({ ...updatedState });
                  }}
                  dropdownConfig={{
                    style: { minWidth: 150 },
                    className: 'shadow-m width-auto',
                    data: formState.customInterval.options
                      ? formState.customInterval.options
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.label}`} />;
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="column parent-width">
          <DKLabel
            text="Recurring Period"
            className="title fw-m text-sm mr-5 mt-xxl mb-r"
          />
          <div className="row parent-width align-items-start">
            <div className="column parent-width">
              <DKInput
                title="First Document Date"
                required
                type={INPUT_TYPE.DATE}
                value={startDate}
                onChange={(value: any) => {
                  setStartDate(value);
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                readOnly={!isEnabled}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
              />
              {startDate && formState.interval.value.value !== undefined && (
                <DKLabel
                  text={`Next document will be created on ${nextDocumentDate}`}
                  className="text-xs mt-xs text-gray align-self-end"
                />
              )}
            </div>

            <div className="column parent-width ml-l">
              <DKInput
                title="End Date"
                className={`${!isEnabled || isInfinite ? 'text-gray' : ''}`}
                required
                type={INPUT_TYPE.DATE}
                value={endDate}
                onChange={(value: any) => {
                  setEndDate(value);
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                readOnly={!isEnabled || isInfinite}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
              />
              <div className="row ">
                <DKCheckMark
                  title={'Never Ends'}
                  isSelected={isInfinite}
                  className="mt-l "
                  // color="bg-button"
                  onClick={() => {
                    if (isEnabled) {
                      setIsInfinite(!isInfinite);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getDocumentData = () => {
    let htmlDoc;
    if (recurringScheduleDetails && recurringScheduleDetails.length > 0) {
      htmlDoc = decodeURIComponent(recurringScheduleDetails[0].emailBody)
        .split('<br/>')
        .join('\n');
    }

    return {
      ...props.documentDetails,
      contactDto: {
        ...props.documentDetails.contactDto,
        emailId:
          recurringScheduleDetails?.length &&
          recurringScheduleDetails[0]?.emailTo
            ? recurringScheduleDetails[0].emailTo.split(',')
            : props.documentDetails.contactDto.emailId
            ? [props.documentDetails.contactDto.emailId]
            : [],
        emailSenderName:
          recurringScheduleDetails?.length &&
          recurringScheduleDetails[0]?.emailSenderName
            ? recurringScheduleDetails[0]?.emailSenderName
            : tenantInfo.name,
        replyToEmailId:
          recurringScheduleDetails?.length &&
          recurringScheduleDetails[0]?.emailReplyTo
            ? recurringScheduleDetails[0].emailReplyTo
            : tenantInfo.contacts.email,
        emailSubject:
          recurringScheduleDetails?.length &&
          recurringScheduleDetails[0]?.emailSubject
            ? recurringScheduleDetails[0].emailSubject
            : null,
        emailBody:
          recurringScheduleDetails?.length &&
          recurringScheduleDetails[0]?.emailBody
            ? htmlDoc
            : null
      }
    };
  };

  const getAutoSendStep = () => {
    let documentData = getDocumentData();
    return (
      <div className="flex flex-row items-start content-start overflow-hidden h-full">
        <div className="flex flex-col w-1/2 overflow-hidden h-full">
          <EmailComposer
            documentType={
              props.documentType === DOC_TYPE.INVOICE
                ? TEMPLATE_CATEGORY.INVOICE
                : props.documentType
            }
            document={documentData}
            placeholderConfig={props.emailTemplateConfig}
            isRecurringDocFlow
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            currentStep={currentStep}
            emailRef={emailRef}
            printUUID={printUUID}
            onDocDesignerOpen={(isOpened: boolean) =>
              setDocDesignerOpened(isOpened)
            }
            closePopup={(data: any) => {
              closePopup(data);
            }}
            recurringData={{
              ...formState,
              isInfinite,
              documentType,
              document,
              isCustomInterval,
              startDate,
              endDate,
              recurringScheduleDetails
            }}
          />
        </div>
        <div className="flex flex-col w-1/2 p-2 items-center overflow-y-auto h-full">
          <EmailDocPreview
            documentType={documentType}
            document={document}
            onDocumentPrintUUIDChange={(uuid: string) => setPrintUUID(uuid)}
            isDocDesignerOpen={docDesignerOpened}
          />
        </div>
      </div>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Set Recurring Document" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !loading && props.closePopup && props.closePopup();
            }}
          />
          <DKButton
            title={'Save'}
            className={'bg-button text-white'}
            onClick={() => {
              !loading && saveRecurringDocument();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxHeight: '90%',
          height: props.documentType === DOC_TYPE.INVOICE ? '90%' : '600px',
          width: props.documentType === DOC_TYPE.INVOICE ? '900px' : '600px',
          maxWidth: '90%',
          padding: 0,
          paddingBottom: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="p-l parent-width parent-height text-align-left overflow-y-auto">
          {props.documentType === DOC_TYPE.INVOICE && (
            <div className="mb-xl">
              <Stepper data={steps} currentIndex={currentStep} />
            </div>
          )}

          {props.documentType === DOC_TYPE.INVOICE && getCurrentStepChild()}

          {props.documentType !== DOC_TYPE.INVOICE && getRecurringStep()}
        </div>
      </div>
    </div>
  );
};

export default RecurringDocument;
