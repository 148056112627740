import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import BanksService from '../../Services/Banks';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  bankMatches: {} as any,
  filters: {},
  refreshCounter: 0
};

export const getServiceProvider = createAsyncThunk(
  'serviceProvider',
  async (country: string) => {
    const response = await BanksService.getServiceProvider(country);
    return response;
  }
);

export const fetchBanksList = createAsyncThunk('fetchBanks', async () => {
  try {
    const response = await BanksService.fetchBanksList();
    return response;
  } catch (error) {
    return error;
  }
});

export const fetchBankMatches = createAsyncThunk(
  'fetchBankMatches',
  async (code: any) => {
    const response = await BanksService.fetchBankDocumentsList(code);
    return response;
  }
);

export const BanksSlice = createSlice({
  name: 'Banks',
  initialState,
  reducers: {
    addBanksColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    addBanks: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    setRefreshCounter: (state, action: PayloadAction<any>) => {
      state.refreshCounter = action.payload;
    },
    setBankFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    resetBankMatched: (state, action: PayloadAction<any>) => {
      state.bankMatches = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanksList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBanksList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchBankMatches.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBankMatches.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.bankMatches = action.payload;
      })
      .addCase(getServiceProvider.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.serviceProvider = action.payload;
      });
  }
});

export const {
  resetBankMatched,
  addBanksColumnConfig,
  setBankFilters,
  setRefreshCounter
} = BanksSlice.actions;
export const selectedBanks = (state: RootState) => state.banks.data;
export const selectBankRefreshCounter = (state: RootState) =>
  state.banks.refreshCounter;
export const selectBanksColumnConfig = (state: RootState) =>
  state.banks.columnConfig;
export const selectedFilters = (state: RootState) => state.banks.filters;
export const selectBanksColumnConfigTableId = (state: RootState) =>
  state.banks.configTableId;
export const selectBankMatches = (state: RootState): any => {
  return state.banks.bankMatches;
};
export const selectedServiceProvider = (state: RootState): any => {
  return state.banks.serviceProvider;
};
