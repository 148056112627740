export const orderListReportTemplate = {
  uid: 'Purchase - Order List Report',
  createdDt: '31-Mar-2022',
  data: 'eyJjaGFydFR5cGUiOiJUQUJMRSIsImRhdGFUYWJsZXMiOlt7Im5hbWUiOiJvcmRlcl9yZXBvcnRfdmlldyIsImNvbHVtbnMiOlsiZG9jdW1lbnRfZHQiLCJkb2N1bWVudF9jb2RlIiwiY29udGFjdF9uYW1lIiwidG90YWxfYW1vdW50IiwiY3VycmVuY3lfY29kZSIsInN0YXR1cyIsInRheF9hbW91bnQiLCJleGNoYW5nZV9yYXRlIiwiYmFzZV9hbW91bnQiLCJsaW5rZWRfZG9jdW1lbnRzIl0sImN1c3RvbUZpZWxkQ29sdW1ucyI6W119XSwiZmlsdGVycyI6W10sImN1c3RvbUZpZWxkRmlsdGVycyI6W10sInNvcnRCeSI6bnVsbH0=',
  reportDesc: 'Thumbnail',
  reportName: 'PO List',
  updatedDt: '31-Mar-2022',
  updatable: true,
  thumbnail: '',
  appName: 'ERP',
  systemReport: true
};
