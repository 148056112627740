import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW } from "../../Utilities/Utility";
import { getLocalisedText } from "../../Translate/LanguageManager";
import { COLOR_LIGHTGRAY, EditorInfoType, FONT_SIZE } from "../../Constants/Constants";
import AddressParser, { AddressType } from "../../Helper/AddressParser";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import DocumentManager from "../../Manager/DocumentManager";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";

export default class ServiceOrderTemplate1 extends Component {
    spacingRatio = 0.25

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        const minHeight = getPageHeight(this.state.data.printScale);

        return (
            <div id={HTMLTag.TEMPLATE_HOLDER} className="DocumentHolder ColumnDiv parent-width" style={{
                justifyContent: 'space-between',
                minHeight: minHeight
            }}>
                <div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
                    <div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
                        {this.getCompanySection()}
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        {this.getDocumentType()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        {this.getRepeatableDocumentDetails()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>
                        {this.getBillingShippingAndDocumentInfoSection()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        {TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                            <div className="parent-width"
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }}
                                style={{
                                    ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale)
                                }}
                            />
                        }
                        {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
                        {this.getEditableDocumentTable()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
                        {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        {this.getAmountInWords()}
                        {this.getNotes()}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                    </div>
                    <div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
                        {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                    </div>
                </div>
                <div className="break-before-always"></div>
                <div className="ColumnDiv parent-width" style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                    {this.getSpecialTermsAndConditions()}
                    {Utility.getIsShowApprovalSection() &&
                        <div className="parent-width">
                            <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />
                        </div>
                    }
                </div>
                <div className="break-before-always"></div>
                <div className="ColumnDiv parent-width" style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                    {this.getTermsAndConditionsFirstPage()}
                    {Utility.getIsShowApprovalSection() &&
                        <div className="parent-width">
                            <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />
                        </div>
                    }
                </div>
                <div className="break-before-always"></div>
                <div className="ColumnDiv parent-width" style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                    {this.getTermsAndConditionsSecondPage()}
                </div>
            </div>
        );
    }

    getCompanySection() {
        return <div id={HTMLTag.TEMPLATE_HEADER} className="RowDiv"
            style={{
                alignItems: 'flex-start',
                justifyContent: 'center'
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)), paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(70, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 4, paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(this.state.data.shipToName)}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#000000',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(AddressParser.getRequisitonCustomAddressString(this.state.data.tenantAddressObj)) }}>
                    </div>
                    {this.getEmailAndPhone(this.state.data)}
                </div>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }} />
        </div>
    }

    getDocumentType() {
        return <div className="RowDiv parent-width"
            style={{
                paddingTop: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(5 * this.spacingRatio, this.state.data.printScale)),
                alignItems: 'center',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                borderTop: '1px solid black',
                borderBottom: '1px solid black'
            }}>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale),
                    textAlign: "center",
                    width: "100%"
                }}>
                {`${getLocalisedText("service_order").toUpperCase()}`}
            </text>
        </div>;
    }

    getEmailAndPhone() {
        return <div className={"docFontStyle" + getFontSizeClass()}
            style={{
                width: '100%',
                marginTop: 0,
                color: '#000000',
                textAlign: 'center',
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
            }}>
            {this.state.data.tenantInfo?.email &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("email")}:`}
                    </text>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.tenantInfo?.email}`}
                    </text>
                </>
            }
            {this.state.data.tenantInfo?.contactNumber &&
                <>
                    <text className={"docFontStyle pr-s" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${getLocalisedText("phone")}:`}
                    </text>
                    <text className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        {`${this.state.data.tenantInfo?.contactNumber}`}
                    </text>
                </>
            }
        </div>
    }

    getBillingShippingAndDocumentInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getAddressSection()}
            </div>
        )
    }

    getShipmentAddress() {
        return (
            <div className='RowDiv' style={{ alignItems: "flex-start", justifyContent: "space-between" }}>
                <div className='ColumnDiv'
                    style={{
                        width: '60%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    }}
                >
                    {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {TemplateSettingsManager.getSupplyConfigurationVisibility('place') && ComponentManager.addVerticalSpace(60 * this.spacingRatio)}
                    {TemplateSettingsManager.getSupplyConfigurationVisibility('place') && this.getPlaceOfSupplyInfoSection()}
                </div>
                <div className='ColumnDiv'
                    style={{
                        width: '40%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    }}
                >
                    {this.getDocumentInfoSection()}
                </div>
            </div>
        );
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <div
                    className="RowDiv"
                    style={{
                        alignItems: "flex-start",
                        justifyContent: 'space-between',
                        backgroundColor: "white",
                    }}
                >
                    {this.getPlaceOfSupply()}
                </div>
            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv parent-width'>
            {TemplateSettingsManager.getSupplyConfigurationVisibility('place') && ComponentManager.getRowValueSectionCustom(
                `${getLocalisedText('place_of_supply').toUpperCase()}`,
                this.state.data.destinationOfSupply,
                "auto",
                this.state.data.themeColor,
                undefined,
                ":",
                getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                this.state.data.printScale
            )}
        </div>;
    }

    getVendorAddress() {
        return (
            <div className='RowDiv' style={{ alignItems: "flex-start", justifyContent: "space-between" }}>
                <div className='ColumnDiv'
                    style={{
                        width: '60%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    }}
                >
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipFromTitle,
                        Utility.getClientShipFromAddress(this.state.data),
                        '100%',
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(6),
                        Utility.getOwnerType(this.state.data, AddressType.from),
                        this.state.data.printScale
                    )}
                </div>
                <div className='ColumnDiv'
                    style={{
                        width: '40%',
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    }}
                >
                    {ComponentManager.addVerticalSpace(60 * this.spacingRatio)}
                    {this.getCustomerInfoSection()}
                </div>
            </div>
        );
    }

    getAddressSection() {
        return <div className="parent-width" style={{ borderBottom: "1px solid black" }}>
            {this.getVendorAddress()}
        </div>;
    }

    getDocumentInfoSection() {
        return <div
            className="ColumnDiv"
            style={{ width: "100%" }}
        >
            {this.getDocumentDetailsSection()}
        </div>;
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getCustomerInfoSection() {
        return <div className="ColumnDiv" style={{ width: "100%" }}>
            {
                ComponentManager.getRowValueSection(
                    getLocalisedText("gstin_no"),
                    this.state.data.contactInfo?.gstin,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )
            }
            {
                ComponentManager.getRowValueSection(
                    getLocalisedText("pan_no"),
                    this.state.data.contactInfo?.pan,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )
            }
            {
                ComponentManager.getRowValueSection(
                    getLocalisedText("cont_person"),
                    "",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )
            }
            {
                ComponentManager.getRowValueSection(
                    getLocalisedText("email"),
                    this.state.data.contactInfo?.emailId,
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )
            }
        </div>;
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBorderColor = "#BBBABA";
        style.itemBorderColor = "#BBBABA";
        style.footerBackgroundColor = COLOR_LIGHTGRAY;
        style.footerBorderColor = "#BBBABA";
        style.headerBackgroundColor = "transparent";
        style.noPadding = false
        return style;
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 1
        style.columnWidths = ['20%']
        style.renderAsFieldValue = true
        style.valueSeparator = ":"
        return style;
    }

    getAmountInWords() {
        return (
            <div className="RowDiv"
                style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(4, this.state.data.printScale))
                }}
            >
                {this.state.data.amountInWords &&
                    ComponentManager.getRowValueSectionCustom(
                        `${getLocalisedText('amount_in_words').toUpperCase()}`,
                        `${this.state.data.amountInWords} ${getLocalisedText("only")}`,
                        "20%",
                        this.state.data.themeColor,
                        undefined,
                        ":",
                        getVW(Utility.convertToPrintScale(0, this.state.data.printScale)),
                        this.state.data.printScale
                    )
                }
            </div>
        );
    }

    getNotesValue() {
        let notes = ''
        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            notes = 'notes_placeholder_text'
        }
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
            notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
        }
        return getLocalisedText(notes)
    }

    getNotes() {
        return (
            <div id={HTMLTag.NOTES} className="break-inside-avoid">
                {this.state.data.showNotes && this.getNotesValue() && ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                {this.state.data.showNotes && this.getNotesValue() &&
                    ComponentManager.getNoteSectionCustom(
                        Utility.getNotesTitle(getLocalisedText('notes')),
                        this.getNotesValue(),
                        "20%",
                        this.state.data.themeColor,
                        ":",
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )
                }
            </div>
        )
    }

    getRepeatableDocumentDetails() {
        return (
            <div className="parent-width" style={{ borderBottom: "1px solid black" }}>
                {this.getShipmentAddress()}
            </div>
        );
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.NOTES} style={{}}>
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(20, this.state.data.printScale))}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getLocalisedText('notes')),
                        getLocalisedText(this.state.data.notes),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )
                }
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        getLocalisedText(this.state.data.termsAndCondition),
                        "100%",
                        this.state.data.themeColor,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )
                }
            </div>
        )
    }

    getSpecialTermsAndConditions() {
        return (
            <div className="ColumnDiv parent-width">
                {this.getCompanySection()}
                {ComponentManager.addVerticalSpace(40 * this.spacingRatio)}
                {this.getDocumentType()}
                {this.getRepeatableDocumentDetails()}
                <div className="parent-width" style={{ textAlign: "left" }}>
                    <div style={{
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                        borderBottom: "1px solid black"
                    }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            SPECIAL TERMS AND CONDITIONS
                        </text>
                        <text className={"docFontStyle" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            <ol className="terms-n-conditions" style={{
                                listStyleType: "decimal",
                                margin: 0,
                                paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            }}>
                                <li>All Challans with supplies should bear our order reference, full description of items, item code.</li>
                                <li>Rejection on account of quality, specification, delivery will be on your A/c.</li>
                                <li>Reject materials must be collected from our works immediately on reciept of Rejection Advice, otherwise handling<br />
                                    charges will be debited to your account.</li>
                                <li>Transit Insurance : To beconverted by us. Please inform dispatch particular by mail imediately.</li>
                                <li>The supplier is expected to scrutinize the Purchase order immediately on reciept thereof and inform us in case of any discrepancy.</li>
                                <li>No objection regarding errors & ommision, if any, shall not be entertained after the expiry of 10 days from date of the reciept of order.</li>
                                <li>Bank commission on account of E-Payments, if any will be on your account.</li>
                                <li>Note: Material Safety Data Sheet (MSDS) required along with supply.</li>
                            </ol>
                        </text>
                    </div>
                    <div className="break-inside-avoid">
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            <div className="pt-s pb-s">
                                This is system generated Service order, hence not signed.
                            </div>
                            <div className="pb-s">
                                Please Address/deliver all the commercial documents such as Invoice / Challan / Report / Certificate to our main stores only. Under no circumstances, the commercial documents should be handed over to our plant staff.
                            </div>
                            {this.state.data.tenantInfo?.email &&
                                <div className="pb-s">
                                    You can also email the copy of invoice to: - {this.state.data.tenantInfo?.email}
                                </div>
                            }
                        </text>
                    </div>
                </div>
            </div>
        );
    }

    getTermsAndConditionsFirstPage() {
        return (
            <div className="ColumnDiv parent-width">
                {this.getCompanySection()}
                {ComponentManager.addVerticalSpace(40 * this.spacingRatio)}
                {this.getDocumentType()}
                {this.getRepeatableDocumentDetails()}
                <div className="parent-width" style={{ textAlign: "left" }}>
                    <div style={{
                        paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
                    }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            TERMS AND CONDITIONS
                        </text>
                        <text className={"docFontStyle" + getFontSizeClass()}
                            style={{
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                            <ol className="terms-n-conditions" style={{
                                listStyleType: "decimal",
                                margin: 0,
                                paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                                paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            }}>
                                <li>CANCELLATION OF ORDER: The Company reserves the right to cancel this Order in full/part quantity in case deliveries do not
                                    materialize as per schedule given in this order or any amendment there to subsequently not withstanding the liquidated damages
                                    clause incorporated in the Order.
                                </li>
                                <li>RISK PURCHASE: If you fail to execute the Order to our satisfaction and within the delivery period indicated in the supply order or any
                                    amendment there of subsequently issued, without valid and acceptable reasons, this company shall arrange procurement of
                                    undelivered items at your risk and cost and such undelivered items shall automatically be treated as cancelled from the purchase
                                    Order placed on you.
                                </li>
                                <li>INSPECTION: The material shall be subject to inspection at firm's place/at consignee's place before dispatch/on receipt at consignee's
                                    place. The rejected materials will be subject to replacement/ removal by you at your risk, cost and responsibility. In case of outstation
                                    firm, unaccepted goods shall be returned freight to pay after dues, if any, like freight, Octroi etc. paid by the Company are to be
                                    refunded by you.
                                </li>
                                <li>WARRANTY: Goods/articles to be supplied under this purchase order shall be of the best quality and workmanship and shall be
                                    strictly in accordance with the specifications and particulars mentioned in the purchase order. Further, these shall be covered under
                                    the warranty that goods/articles under this order would continue to confirm to the specifications and quality mentioned in the supply
                                    order/your Quotation, not withstanding the fact that the Company inspector may have initially inspected or approved the goods on
                                    receipt. If during the period of warranty of the goods are found to be not conforming to the specifications/ quality mentioned in the
                                    supply order or found to have deteriorated prematurely it shall be binding on the supplier to replace the goods immediately and within
                                    a period of the month of receipt of intimation from the Company or refund the total cost of the goods including Octroi, freight charges
                                    etc. to the Company at our discretion.
                                </li>
                                <li>PERFORMANCE GUARANTEE: All goods/articles under this order shall be subject to a guarantee for satisfactory performance
                                    for a period of One year from the date of satisfactory receipt and in case any manufacturing defect is noticed during this guarantee
                                    period the goods/ articles shall be subject to free rectification/ replacement within a period of one month.
                                </li>
                                <li>REJECTION CLAUSE: Any item rejected due to deviation in specification or material defect/dimensional defect ordue to any major
                                    deviation on the Purchase Order terms, {getLocalisedText(this.state.data.shipToName)} has the sole authority to decide in the matter. After getting intimation of the
                                    rejection note on any supply, the supplier should immediately arrange lifting of the item free of cost and if the item is not lifted from
                                    {getLocalisedText(this.state.data.shipToName)} plant premises within a maximum of three months (90 days), {getLocalisedText(this.state.data.shipToName)} has the right to dispose-off the item at
                                    supplier's risk and cost.
                                </li>
                                <li>DELIVERY INSTRUCTION: The goods should be securely packed and dispatched/delivered to the consignee alongwith challans in
                                    triplicate by Train/Truck/Any other mode mutually decided. The goods should reach in properly packed condition and any damage
                                    during transit shall be at supplier's risk unless insurance in transit is bore by buyer specifically mentioned.
                                </li>
                                <li>DISPUTE: The court of the place from where this purchase order has been issued shall alone have jurisdiction to decide any dispute
                                    arising out of or in respect of this purchase order.
                                </li>
                                <li>ARBITRATION: Both the parties shall try to resolve their differences or disputespertaining to this purchase inan amicable manner,
                                    failing which the difference or dispute may be referred to an Arbitrator as per procedure laid down under the Arbitration and Conciliation
                                    Act 1996.
                                </li>
                                <li>The outcome of Arbitration shall be final and binding upon both parties. The venue of Arbitration shall be at {this.state.data.tenantAddressObj?.city} {this.state.data.tenantAddressObj?.state ? `(${this.state.data.tenantAddressObj?.state})` : ""},
                                    India. Receipt of the Purchase Order may kindly be acknowledged.
                                </li>
                                <li>The Supplier shall take all reasonable measures to ensure that all necessary tests and examinations have been made or will be made
                                    prior to delivery of the Goods to ensure that the Goods are designed and constructed so as to be safe and without risk to the health or
                                    safety of persons using the Goods. The Supplier shall make available to the Purchaser adequate information about the use for which
                                    the Goods have been designed and tested and about any conditions necessary to ensure that when put to use the Goods will be safe
                                    and without risk to health. The Supplier shall indemnify the Purchaser against all claims, proceedings, actions, damages, costs,
                                    expenses and any other liability or loss incurred by the Purchaser as a result of any breach of this Condition.
                                </li>
                            </ol>
                        </text>
                    </div>
                </div>
            </div>
        );
    }

    getTermsAndConditionsSecondPage() {
        return (
            <>
                <div className="ColumnDiv parent-width">
                    {this.getCompanySection()}
                    {ComponentManager.addVerticalSpace(40 * this.spacingRatio)}
                    {this.getDocumentType()}
                    {this.getRepeatableDocumentDetails()}
                    <div className="parent-width" style={{ textAlign: "left" }}>
                        <div style={{
                            paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
                        }}>
                            <text className={"docFontStyle" + getFontSizeClass()}
                                style={{
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                                }}>
                                <ol start={12} style={{
                                    listStyleType: "decimal",
                                    margin: 0,
                                    paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                                }}>
                                    <li>The Supplier shall take all measures necessary to comply with the requirements of the Health ,Safety and Environment at Work and
                                        any other Acts, orders, regulations and Codes of Practice relating to health, safety and environment which may apply to the Supplier in
                                        the performance of the Purchase Order <b>[as per HIARO of OHSMS 45001: 2018]</b>
                                    </li>
                                    <li>Delivery time is the essence of this Service Order. In case you fail to supply the goods within the stipulated period as mentioned in delivery period, then unless such failure is due to Force Majeure as defined in general terms of this order, you shall pay to the SRSPL
                                        by way of compensation for delay and not as penalty, a sum @1/2% (Half Percent) value of the undelivered portion of goods per
                                        week or part thereof subject to a maximum of 10% (Ten percent) of the total basic order value. This is a reasonable pre-estimate of
                                        the loss/damage which will be suffered on account of delay /breach on the part of the Service contract and the said amount will be
                                        payable on demand without there being any proof of the actual loss or damages caused by such delay/breach. The decision of the
                                        SRSPL in regard to applicability of Compensation for Delay shall be final and binding on YOU. For calculation of compensation of
                                        delay, 15 days as grace period on delivery date shall be allowed. All sums payable by way of compensation under any of the
                                        conditions shall be considered as reasonable compensation without reference to the actual loss or damage which shall have been
                                        Sustained. Total recoverable amount, as the case, may be shall be deducted from any sum then due or which at any time thereafter
                                        due to you.
                                    </li>
                                </ol>
                            </text>
                        </div>
                    </div>
                </div>
                <div className="RowReverseDiv break-inside-avoid">
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                        }}>
                        <div className="pt-r pb-r">
                            Received and Accepted the S.O.
                        </div>
                        <div className="pb-r">
                            Signature : -
                        </div>
                        <div className="pb-r">
                            Name : -
                        </div>
                        <div className="pb-r">
                            Dated : -
                        </div>
                    </text>
                </div>
            </>
        );
    }
}
