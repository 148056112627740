import {
  BOOKS_DATE_FORMAT,
  COLORS as GraphColors
} from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';

export interface StockRequestItems {
  productName: string;
  productCode: string;
  documentSequenceCode: string;
  productVariantCode: string;
  documentUom: number;
  documentUOMSchemaDefinition: any;
  productQuantity: number;
  availableQuantity?: number;
  dstWarehouseCode: string;
  stockRequestWarehouseInventoryData: any;
  type: any;
  lineNumber: number;
  product: any;
  productAvailableQuantity?: number;
  productAvailableQuantityInBaseUOM?: number;
  rowButtons: any;
}
export interface StockRequest {
  notes: string;
  //id:number;
  stockRequestDate: any;
  stockRequestItems: StockRequestItems[];
  customField: any;
  linkedDocuments: any;
}

export const StockRequestInitialState: StockRequest = {
  notes: '',
  //id:0,
  customField: {},
  linkedDocuments: [],

  stockRequestDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),

  stockRequestItems: [
    {
      productName: '',
      lineNumber: 0,
      productCode: '',
      documentSequenceCode: '',
      productVariantCode: '',
      documentUom: 0,
      documentUOMSchemaDefinition: {},
      productQuantity: 0,
      //availableQuantity: 0,
      dstWarehouseCode: '',
      stockRequestWarehouseInventoryData: '',
      type: null,
      product: {},
      rowButtons: []
    }
  ]
};
