import { DropdownSelectionType, FORM_ELEMENTS } from '../Constants/Constant';
import { CallBackPayloadType } from './Interfaces';
import { DropdownTag, InputTag } from './NewContact';
import { CalendarType } from './PaymentPopup';

export interface NewOrgFormState {
  formDetails: {
    formInfo: FormInfo;
    isValid: boolean;
  };
  financeYearDates: FinanceYearDates[] | null;
  showPlanUpgradePopup: boolean;
  userId: number | null;
  tenantId: number | null;
}

interface FinanceYearDates {
  countryLookupCode: string;
  currencyCode: string;
  financialStartDate: string;
}
export interface NewOrgFormProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  isBookkeeperFlow?: boolean;
  isAddNewOrgFlow?: boolean;
  getOrgFormDetails?: (data: any) => void;
  allTenantsWithBKTenants?: any[];
}

export interface FormInfo {
  organisationName: InputTag<string>;
  country: DropdownTag<string>;
  state: DropdownTag<string>;
  currency: DropdownTag<string>;
  financialStartDate: CalendarType;
  booksBeginningDate: InputTag<string>;
  gstRegistered: boolean;
  gstIn: InputTag<string>;
}

export const newOrgState: NewOrgFormState = {
  formDetails: {
    formInfo: {
      organisationName: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: true
      },
      country: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      state: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      currency: {
        key: '',
        hidden: false,
        value: { label: 'Indian Ruppees', value: 'INR' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      financialStartDate: {
        isOpen: false,
        date: ''
      },
      booksBeginningDate: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.DATE,
        hasError: false,
        isMandatory: false
      },
      gstRegistered: false,
      gstIn: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      }
    },
    isValid: false
  },
  financeYearDates: null,
  showPlanUpgradePopup: false,
  userId: null,
  tenantId: null
};

export enum NewOrgFormFields {
  ORG_NAME = 'orgName',
  COUNTRY = 'country',
  STATE = 'state',
  CURRENCY = 'currency',
  FINANCIAL_START_DATE = 'startDate',
  BOOKS_BEGINNING_DATE = 'beginningDate',
  GST_REGISTERED = 'gstRegistered',
  GST_IN_NUMBER = 'gstIn'
}
