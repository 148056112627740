import { DropdownSelectionType, FORM_ELEMENTS } from '../Constants/Constant';
import { IAddress } from './Interfaces';
import { DropdownTag, InputTag } from './NewContact';
import { CalendarType } from './PaymentPopup';

export interface GettingStartedState {
  step1Form: {
    formInfo: Step1Form;
    isValid: boolean;
  };
  step2Form: {
    formInfo: Step2Form;
    isValid: boolean;
  };
  onStep1Form: boolean;
  financeYearDates: FinanceYearDates[] | null;
  calendarDateFormat: string;
}

interface FinanceYearDates {
  countryLookupCode: string;
  currencyCode: string;
  financialStartDate: string;
}

export interface Step1Form {
  organisationName: InputTag<string>;
  country: DropdownTag<string>;
  state: DropdownTag<string>;
  currency: DropdownTag<string>;
  financialStartDate: CalendarType;
  booksBeginningDate: CalendarType;
  gstRegistered: boolean;
  gstIn: InputTag<string>;
}

export interface Step2Form {
  serviceChecked: boolean;
  productsChecked: boolean;
  inventory: InventoryCardType;
}

export interface InventoryCardType {
  isChecked: boolean;
  isPPS: boolean;
  isMultiwarehouse: boolean;
  isPriceList: boolean;
  isEWayBill: boolean;
}

export const gettingStartedState: GettingStartedState = {
  onStep1Form: true,
  step1Form: {
    formInfo: {
      organisationName: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        isMandatory: true,
        hasError: false
      },
      country: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      state: {
        key: '',
        hidden: false,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      currency: {
        key: '',
        hidden: false,
        value: { label: 'Indian Ruppees', value: 'INR' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      financialStartDate: {
        isOpen: false,
        date: ''
      },
      booksBeginningDate: {
        isOpen: false,
        date: ''
      },
      gstRegistered: false,
      gstIn: {
        key: '',
        hidden: true,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      }
    },
    isValid: false
  },
  step2Form: {
    formInfo: {
      serviceChecked: false,
      productsChecked: false,
      inventory: {
        isChecked: false,
        isPPS: false,
        isMultiwarehouse: false,
        isPriceList: false,
        isEWayBill: false
      }
    },
    isValid: false
  },
  financeYearDates: null,
  calendarDateFormat: ''
};

export enum Step1FormFields {
  ORG_NAME = 'orgName',
  COUNTRY = 'country',
  STATE = 'state',
  CURRENCY = 'currency',
  FINANCIAL_START_DATE = 'startDate',
  BOOKS_BEGINNING_DATE = 'beginningDate',
  GST_REGISTERED = 'gstRegistered',
  GST_IN = 'gstIn'
}

export enum Step2FormCards {
  SERVICES = 'services',
  PRODUCTS = 'products',
  INVENTORY = 'inventory'
}

export enum InventoryToggleBtn {
  PPS = 'PPS',
  WAREHOUSE = 'warehouse',
  PRICE_LIST = 'priceList',
  E_WAY_BILL = 'eWayBill'
}

export interface OnboardingStep1Form {
  tenantName?: string;
  financialStartDate?: string;
  bookBeginningStartDate?: string;
  currencyLookUpCode?: string;
  countryLookupCode?: string;
  complianceEnabled?: boolean;
  numberFormat?: string;
  purpose?: string;
  industry?: string;
  size?: string;
  dateFormatLookUpCode?: string;
  userId?: string;
  isBookkeeper?: boolean;
  nexusStates?: string;
  billingAddresses?: IAddress[];
  shippingAddresses?: IAddress[];
  gstin?: string;
  gstRegistered?: boolean;
  allowNegativeInventory?: boolean;
  implementFIFOForAdvTracking?: boolean;
  landedCostSettings?: any;
}

export interface OnboardingStep2Form {
  application: string;
  hiddenApps: string;
  customLabels: Object;
  onBoardingSetting: OnboardingSetting;
  isResetCustomLabels: boolean;
  isResetHiddenApps: string;
}

export interface OnboardingSetting {
  showGettingStarted: boolean;
  services: boolean;
  digitalProducts: boolean;
  inventory: boolean;
}

export const OrgSetupCompleted = {
  orgSetupCompleted: true,
  onboardingStatusInfo: {
    accountingMethod: 'ACCRUAL'
  }
};
