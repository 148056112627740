import { useEffect, useRef, useState } from 'react';
import {
  DKLabel,
  DKIcon,
  DKInput,
  DKIcons,
  DKButton,
  DKSpinner
} from 'deskera-ui-library';
import {
  CLIENT_PORTAL_DEFAULT_THEME,
  INPUT_VIEW_DIRECTION
} from '../../../Constants/Constant';
import ClientPortalService from '../../../Services/ClientPortal';
import Users from '../../ClientPortal/Users';

const ClientPortal = (props: any) => {
  //states
  const [name, setName] = useState<any>('');
  const [bubbleColor, setBubbleColor] = useState<any>(
    CLIENT_PORTAL_DEFAULT_THEME
  );
  const imageInputOpenFileRef = useRef<any>();
  const [file, setFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canValidate, setCanValidate] = useState(false);

  //effects
  useEffect(() => {
    loadClientPortalSettings();
  }, []);

  const loadClientPortalSettings = () => {
    ClientPortalService.getClientSetting({})
      .then((res: any) => {
        setName(res?.clientPortalSetting?.companyName ?? '');
        setBubbleColor(
          res?.clientPortalSetting?.theme ?? CLIENT_PORTAL_DEFAULT_THEME
        );
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // UI rendering components
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text={`Client Portal`} className="fw-m fs-l" />
          {props.auditLogView && (
            <div className=" fs-r text-align-left text-dark-gray bg-chip-orange text-orange border-radius-r ml-r p-h-s p-v-xs fw-m">
              Read-Only
            </div>
          )}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />

          <div className={`row ${isLoading ? 'border-radius-m border-m' : ''}`}>
            <DKButton
              title={'Save'}
              onClick={() => {
                if (!isLoading) {
                  saveClientPortal();
                }
              }}
              className={`${
                isLoading ? 'border-radius-none text-gray' : 'bg-app text-white'
              }`}
            />
            {isLoading && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const saveClientPortal = () => {
    let req: any = {
      clientPortalSetting: {
        logo: null,
        theme: bubbleColor,
        companyName: name
      }
    };
    if (name?.trim()?.length === 0) {
      setCanValidate(true);
      return;
    }
    setIsLoading(true);
    ClientPortalService.patchClientSetting(req)
      .then((res: any) => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const openImageSelection = () => {
    imageInputOpenFileRef.current.click();
  };

  const getImagePicker = () => {
    return (
      <input
        id="inputImage"
        type="file"
        accept="image/*"
        ref={imageInputOpenFileRef}
        style={{ display: 'none' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e: any) => {
          var reader = new FileReader();
          reader.onload = function (e: any) {
            document
              .querySelector('#img')
              ?.setAttribute('src', e.target.result);
          };
          reader.readAsDataURL(
            e.target?.files[0] != undefined ? e.target?.files[0] : file
          );

          setFile(e.target?.files[0] != undefined ? e.target?.files[0] : file);
        }}
      />
    );
  };

  const getColorSection = () => {
    return (
      <div
        className="justify-content-between parent-width"
        style={{ width: '40%' }}
      >
        <DKLabel text="Theme" className="mb-s" />
        <input
          type="color"
          className="row"
          style={{
            height: '35px',
            border: 'none'
          }}
          value={bubbleColor}
          onChange={(e) => {
            setBubbleColor(e.target.value);
          }}
        />
      </div>
    );
  };

  return (
    <div className="column parent-size">
      <div
        className="column parent-width overflow-auto hide-scroll-bar pt-r position-relative"
        style={{ height: 'calc(100% - 52px)' }}
      >
        <div className="row justify-content-center">
          <div
            className="image-div border-radius-m"
            style={{ height: 80, width: 80 }}
          >
            <img
              src={DKIcons.ic_user}
              id="img"
              alt=""
              className="real-image circle"
              style={{ height: 80, width: 80 }}
            />
            <div className="hidden-image">
              <DKIcon
                src={DKIcons.ic_edit}
                onClick={() => {
                  openImageSelection();
                }}
                className="align-self-center"
              />
            </div>
          </div>
        </div>
        <div className="row mb-3" style={{ gap: 10 }}>
          <DKInput
            style={{ width: '60%' }}
            title={'Company Display Name'}
            required={true}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={name}
            onChange={(event: string) => {
              setName(event);
            }}
            canValidate={canValidate}
            hasError={name?.trim().length === 0}
          />
          {getColorSection()}
        </div>
        <div
          className="row align-items-start parent-height flex-1"
          style={{ minHeight: 300, lineHeight: '16px' }}
        >
          <Users />
        </div>
        {getImagePicker()}
      </div>
      <div
        className="column parent-width bg-gray2"
        style={{ minHeight: 1 }}
      ></div>
      <div className="column parent-width p-v-s">
        <div
          className={`row width-auto ${
            isLoading ? 'border-radius-m border-m' : ''
          }`}
        >
          <DKButton
            title={isLoading ? 'Saving' : 'Save'}
            onClick={() => {
              saveClientPortal();
            }}
            disabled={isLoading}
            className={`${
              isLoading
                ? 'border-radius-none text-gray'
                : 'bg-button text-white'
            }`}
          />
          {isLoading && (
            <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientPortal;
