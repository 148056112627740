import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import InvoiceService from '../../Services/Invoice';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  formData: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchOBInvoices = createAsyncThunk(
  'obInvoices',
  async (contactCode: string) => {
    const response = await InvoiceService.getInvoiceByContactCode(contactCode);
    return response;
  }
);

export const OBInvoicesSlice = createSlice({
  name: 'obInvoices',
  initialState,
  reducers: {
    addOBInvoiceColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      // configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToInvoiceColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateLineItems: (state, action: PayloadAction<any>) => {
      state.formData.lineItems = action.payload;
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.formData.contact = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOBInvoices.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchOBInvoices.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addOBInvoiceColumnConfig,
  addColumnToInvoiceColumnConfig,
  updateColumnConfig
} = OBInvoicesSlice.actions;

export const selectOBInvoices = (state: RootState) => state.obInvoices.data;

export const selectOBInvoicesLoadingStatus = (state: RootState) =>
  state.obInvoices.status;

export const selectOBInvoicesColumnConfig = (state: RootState) =>
  state.obInvoices.columnConfig;
export const selectOBInvoicesColumnConfigTableId = (state: RootState) =>
  state.obInvoices.configTableId;

export default OBInvoicesSlice;
