import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import { RootState } from '../Store';
import { API_STATUS } from '../../Constants/Constant';
import DebitService from '../../Services/Debit';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { deepClone } from '../../Utility/Utility';
import { TableManager } from '../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  status: API_STATUS.IDLE
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////// API CALLS AND STORE IN REDUX STORE //////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchDebit = createAsyncThunk('balance', async () => {
  const response = await DebitService.getDebit();
  return response;
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DebitNoteSlice = createSlice({
  name: 'debitnotes',
  initialState,
  reducers: {
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addDebitNoteColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const configs = action.payload.config;
      const tableId = action.payload.tableId;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.DEBIT_NOTES
      );
    },
    addColumnToDebitColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateDebitNoteDetails: (state, action: PayloadAction<any>) => {
      const debitNoteDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const debitNoteIndex: number = content.findIndex(
        (debitNote: any) => debitNote.id === debitNoteDetails.id
      );
      if (debitNoteIndex >= 0) {
        content[debitNoteIndex] = debitNoteDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDebit.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchDebit.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;

        let DebitList: any = action.payload;
        state.data = DebitList;
      });
  }
});

export const {
  addDebitNoteColumnConfig,
  addColumnToDebitColumnConfig,
  updateColumnConfig,
  updateDebitNoteDetails
} = DebitNoteSlice.actions;

export const selectDebit = (state: RootState) => state.debitNotes.data;
export const selectDebitColumnConfig = (state: RootState) =>
  state.debitNotes.columnConfig;
export const selectDebitNoteColumnConfigTableId = (state: RootState) =>
  state.debitNotes.configTableId;
export const selectDebitNotesLoadingStatus = (state: RootState) =>
  state.debitNotes.gridContentLoadingStatus;

export default DebitNoteSlice.reducer;
