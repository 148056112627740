
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface ImportDraftAPIConfig {
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: string;
}

const defaultConfig: ImportDraftAPIConfig = {
  Limit: 25,
  Page: 0,
  Sort: "createdAt",
  SortDir: "desc"
};

class ImportDraftService {
  static apiConfig: ImportDraftAPIConfig;
  static importDraftData: any = null;
  static importProperties: any = null;
  static importDraftOrigin: any = null;

  static getApiConfig() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    return this.apiConfig;
  }

  static getImportDraft() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const params: any = {
      limit: this.apiConfig.Limit,
      page: this.apiConfig.Page,
      sort: this.apiConfig.Sort,
      sortDir: this.apiConfig.SortDir
    };

    if (this.apiConfig.Query) {
      params.query = this.apiConfig.Query;
    }

    return http.get("/exim/import/draft", { params: params }).catch((err: any) => {
      console.error('Error loading import draft: ', err);
      return Promise.reject(err);
    });
  }

  static saveImportDraft(payload: any) {
    return http.post("/exim/import/draft", payload).catch((err: any) => {
      console.error('Error saving import draft: ', err);
      return Promise.reject(err);
    });
  }

  static updateImportDraft(payload: any) {
    return http.put("/exim/import/draft", payload).catch((err: any) => {
      console.error('Error updating import draft: ', err);
      return Promise.reject(err);
    });
  }
}

export default ImportDraftService;
