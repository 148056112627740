import { MACHINE_MATERIAL_PR_PO } from '../Components/Settings/AdvancedSettings/AdvancedSettings';
import { BOOKS_DATE_FORMAT } from '../Constants/Constant';
import { BooksAddress } from './Interfaces';

export interface ApiResponse<T> {
  content?: T[];
  pageable?: PageableInfo;
  first?: boolean;
  last?: boolean;
  totalPages?: number;
  totalElements?: number;
  size?: number;
  number?: number;
  numberOfElements?: number;
}
export interface PageableInfo {
  sort?: SortInfo;
  pageSize?: number;
  pageNumber?: number;
  offset?: number;
  unpaged?: boolean;
  paged?: boolean;
}
export interface SortInfo {
  sorted?: boolean;
  unsorted?: boolean;
  empty?: boolean;
}
export const DEFAULT_ACCOUNT_MODULE = {
  GL_FOR_ITC_BLOCK: 'GL_FOR_ITC_BLOCK'
} as const;

export interface DefaultAccountDTO {
  accountCode: string;
  name: string;
  module: keyof typeof DEFAULT_ACCOUNT_MODULE;
}

export type Address = {
  contactName: string;
  address1: string;
  address2: string | null;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  preferred: boolean | null;
  placeOfSupply: any;
  destinationOfSupply: any;
  stateCode: string;
  mobileCountryCode: string | null;
  mobileNumber: string;
  emailId: string | null;
  customFields: any[] | null;
  [key: string]: any;
};

export type BillingAddress = Address | BooksAddress | any;

export type ShippingAddress = Address | BooksAddress | any;

export type AdditionalSettings = {
  LOCATION: {
    locationCFId: any;
    locationLabel: string;
    trackLocation: boolean;
    mandatory: boolean;
  };
  ROUND_OFF: any;
  ENABLE_FA: boolean;
  LABEL_PRINT: boolean;
  ENABLE_SO: boolean;
  CLASS: {
    classCFId: any;
    trackClasses: boolean;
    assignClasses: string;
    warnOnClassNotSelected: boolean;
    mandatory: boolean;
  };
  CHECK_FORMAT_STAR: string;
  CHECK_FORMAT_TYPE: string;
  DENIED_PARTIES_SETTING: boolean;
  AMORTIZATION: boolean;
  TRACKING_DETAILS_IN_SO: boolean;
  REV_REC_PRIMARY_JE_CREATION: boolean;
  REV_REC: boolean;
  QC: { enable: boolean; module: string }[];
  REV_REC_CREATION_SETTING: string;
  BUSINESS_TYPE: { label: string; value: string };
  ENABLE_RATE_ANALYSIS: boolean;
  ENABLE_EXPENSE_ALLOCATION: boolean;
  MACHINE_MATERIAL_SHORTFALL_SETTING: MACHINE_MATERIAL_PR_PO;
  ENABLE_MACHINE_MAINTENANCE: boolean;
  [key: string]: any;
  // todo: Add New Add. Settings here
};

export interface ITenantInfo {
  id: number;
  name: string;
  address: Address;
  contacts: {
    phone: string;
    fax: string;
    website: string | null;
    email: string;
  };
  timezone: any;
  dateFormat: string | BOOKS_DATE_FORMAT | any;
  currency: string;
  country: string;
  logoUrl: string | null;
  bookBeginningStartDate: string;
  financialStartDate: string;
  tenantCreatedDate: string;
  billingAddresses: BillingAddress[];
  shippingAddresses: ShippingAddress[];
  orgSetupCompleted: boolean;
  isDemoOrg: boolean;
  isBookKeeper: boolean;
  multicurrencyEnabled: boolean;
  source: any;
  decimalScale: number;
  numberFormat: string;
  fyClosingPeriodEndDate: any;
  serviceProvider: any;
  defaultPaymentTerm: any;
  useSeparateSequenceFormat: boolean;
  implementFIFOForAdvTracking: boolean;
  allowNegativeInventory: boolean;
  invoiceCreditLimitType: string;
  isIncludeCurrentInvoice: boolean;
  quotationCreditLimitType: string;
  isIncludeCurrentQuotation: boolean;
  salesOrderCreditLimitType: string;
  isIncludeCurrentSalesOrder: boolean;
  additionalSettings: AdditionalSettings;
  landedCostSettings: any;
  isAdvancePriceListEnabled: boolean;
  isProductGroupEnabled: any;
  uenNo: any;
  taxNo: any;
  gstNo: any;
  avalaraId: number;
  avalaraCode: string;
  nexusStates: string;
  peppolId: any;
  peppolOptIn: any;
  peppolMarketingOptIn: any;
  complianceEnabled: boolean;
  userId: number;
  vendorId: any;
  isShop: boolean;
  australianBusinessNo: any;
  taxFileNumber: any;
  basReportingMethod: any;
  gstin: string;
  oldFlow: any;
  tenantInfoInSA: any;
  panIndia: string;
  cinIndia: string;
  tanNumberIndia: string;
  [key: string]: any;
}
