export const CalendarConstants = {
    title: {
        textColor: 'black',
    },
    calendar: {
        width: '200px',
        height: 'auto',
        maxWidth: '200px',
        maxHeight: '170px',
        backgroundColor: 'white',
    },
    dayContainer: {
        padding: '0px',
        singleSelectedColor: 'rgb(255, 198, 74)',
        multiSelectedColor: 'rgb(238, 238, 238)',
        selectedTextColor: 'rgb(28, 28, 28)',
        todayContainerColor: 'rgb(255, 204, 204)',
        textColor: 'black',
        textFontSize: '12px',
        selectedDateBGColor: 'rgb(36,144,239)'
    },
    monthContainer: {
        isLongMonth: true
    },
    actionButton: {
        onSubmitTextColor: 'blue',
        onCancelTextColor: 'red',
        backgroundColor: 'rgba(201, 195, 195)',
        borderColor: 'rgba(201, 195, 195)'
    }
}

export const WeekdayList = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const LongMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const ShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
