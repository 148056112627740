import ApiConstants from '../../Constants/ApiConstants';
import Utility from '../../Utility/Utility';
import http from '../Interceptor';

export interface OperatorsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: string;
  QueryParam?: string;
}

const defaultConfig: OperatorsAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Sort: '',
  SortDir: 'DESC',
  Query: '',
  QueryParam: ''
};

class OperatorsService {
  static apiConfig: OperatorsAPIConfig;

  static operatorsEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = 'mrp/operation/search';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=createdOn&sortDir=desc`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static getOperators() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = ApiConstants.URL.MRP.OPERATORS.GET_ALL_OPERATORS;
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${encodeURIComponent(
      this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''
    )}&page=${this.apiConfig.Page}&sort=${this.apiConfig.Sort}&sortDir=${
      this.apiConfig.SortDir
    }&query=${this.apiConfig.Query}`;
    if (this.apiConfig.QueryParam) query += `${this.apiConfig.QueryParam}`;
    finalEndpoint = finalEndpoint + query;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteOperator(id: any) {
    return http
      .delete(`${ApiConstants.URL.MRP.OPERATORS.DELETE_OPERATOR}`, {
        data: id
      })
      .catch((err: any) => {
        console.error('Error deleting operation: ', err);
      });
  }

  static addOperator(payload: any) {
    return http
      .post(`${ApiConstants.URL.MRP.OPERATORS.DELETE_OPERATOR}`, payload)
      .catch((err: any) => {
        console.error('Error creating operation: ', err);
      });
  }

  static updateOperator(payload: any, id: any) {
    return http
      .put(ApiConstants.URL.MRP.OPERATORS.EDIT_OPERATOR(id), payload)
      .catch((err: any) => {
        console.error('Error while updating operation: ', err);
        return Promise.reject(err);
      });
  }

  static getOperatorsById(ids: any[]) {
    let finalEndpoint = ApiConstants.URL.MRP.OPERATORS.GET_OPERATOR_BY_ID;
    return http
      .post(`${finalEndpoint}`, ids)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default OperatorsService;
