import { ReactElement } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';
import { CallBackPayloadType, ReactSelectOptionsType } from './Interfaces';
import { DropdownTag, InputTag } from './NewContact';

export interface PaymentPopupState {
  paymentInfo: {
    contactName: string;
    outstandingAmount: string;
    withHoldTaxDetails: {
      amount: number;
      rate: number;
    };
    paymentDate: CalendarType;
    selectedCurrency: { currencyName: string; currencyCode: string };
  };
  paymentDetails: {
    payFrom: DropdownTag<string>;
    paymentType: DropdownTag<string>;
    paymentAccountGroup: string;
    paymentAmount: InputTag<string>;
    conversionRate: InputTag<string>;
    additionalPaymentDetails: {
      referenceDate: CalendarType;
      referenceNumber: InputTag<string>;
      isActive: boolean;
    };
    transactionFee: {
      accountCode: any;
      amount: any;
      hasError: boolean;
      errorMsg: any;
    };
  };
  tdsDetails: {
    taxDeducted: boolean;
    tdsAccountCode: any;
    tdsAmount: any;
    whtApplicable: any;
    whtRate: any;
  };
  debitNote: { debitItem: NoteItem[] | null; isActive: false };
  creditNote: { creditItem: NoteItem[] | null; isActive: false };
  advancedPayment: { advancedPaymentItem: NoteItem[] | null; isActive: false };
  prePayment: { prePaymentItem: NoteItem[] | null; isActive: false };
  summaryDetails: {
    baseExchangeRate: number;
    exchangeRate: number;
    actualAmountToBePaid: number;
    additionalPaymentAmount: number;
    totalAmount: number;
  };
  customFields?: any[];
  isWHTApplicable: boolean;
}

export interface NoteItem {
  id: string;
  code: string;
  documentSequenceCode: string;
  dateCreated?: string;
  available: string;
  memo: string;
  amount: number;
  isSelected: boolean;
  dueAmount: number;
  currencyCode: string;
  exchangeRate: number;
}

export interface CalendarType {
  date: any;
  isOpen: boolean;
  hidden?: boolean;
}

export const paymentInitialState: PaymentPopupState = {
  paymentInfo: {
    contactName: '',
    outstandingAmount: '1000',
    withHoldTaxDetails: {
      rate: 0,
      amount: 0
    },
    paymentDate: { isOpen: false, date: '' },
    selectedCurrency: { currencyCode: '', currencyName: '' }
  },
  paymentDetails: {
    payFrom: {
      key: '',
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    paymentType: {
      key: '',
      hidden: false,
      value: { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    paymentAmount: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    },
    conversionRate: {
      key: '',
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    additionalPaymentDetails: {
      referenceDate: {
        isOpen: false,
        date: DateFormatService.getDateStrFromDate(new Date())
      },
      referenceNumber: {
        key: '',
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      isActive: false
    },
    paymentAccountGroup: '',
    transactionFee: {
      accountCode: '',
      amount: 0,
      hasError: false,
      errorMsg: ''
    }
  },
  tdsDetails: {
    taxDeducted: false,
    tdsAccountCode: {
      key: '',
      hidden: false,
      value: 0,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    },
    tdsAmount: {
      key: '',
      hidden: false,
      value: 0,
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    },
    whtApplicable: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    },
    whtRate: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: '',
      isDisabled: false
    }
  },
  debitNote: {
    debitItem: null,
    isActive: false
  },
  creditNote: {
    creditItem: null,
    isActive: false
  },
  advancedPayment: {
    advancedPaymentItem: null,
    isActive: false
  },
  prePayment: {
    prePaymentItem: null,
    isActive: false
  },
  summaryDetails: {
    baseExchangeRate: 1,
    totalAmount: 0,
    exchangeRate: 1,
    actualAmountToBePaid: 0,
    additionalPaymentAmount: 0
  },
  customFields: [],
  isWHTApplicable: false
};

export enum PAYMENT_CALENDAR_TYPE {
  PAYMENT_DATE = 'paymentDate',
  REFERENCE_DATE = 'referenceDate',
  DOCUMENT_DATE = 'documentDate'
}

export enum PAYMENT_INPUT_TYPE {
  PAY_AMOUNT = 'payAmount',
  REFERENCE_NUMBER = 'referenceNumber',
  CONVERSION_RATE = 'conversionRate'
}

export enum PAYMENT_DROPDOWN_TYPE {
  PAY_FROM = 'payFrom',
  PAY_TYPE = 'payType'
}

export enum PAYMENT_SECTION {
  PAYMENT_DETAIL = 'paymentDetail',
  ADVANCED_PAYMENT = 'advancedPayment',
  PRE_PAYMENT = 'prePayment',
  CREDIT_NOTE = 'creditNote',
  DEBIT_NOTE = 'debitNote',
  OFFSET_DOC = 'offsetDoc'
}
export interface BillPaymentPopupProps {
  documentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  extraData?: any;
  auditLogView?: boolean;
}

export interface CurrencyDropdownProp {
  currencyCode?: string;
  getInfo: (info: Information) => void;
  isDisabled?: boolean;
  width?: string;
  options?: any[];
  style?: any;
  showAllCurrencyOptions?: boolean;
}

export interface Information {
  selectedCurrencyCode: string;
  selectedCurrencyName: string;
}

export interface CurrencyDropdownState {
  optionList: ReactSelectOptionsType<ReactElement, string>[];
  defaultOption: ReactSelectOptionsType<ReactElement, string>;
}

export interface PaymentPayload {
  documentDate: string;
  closeDate: string;
  amount: number;
  currency: string;
  exchangeRate: number;
  primaryExchangeRate?:number;
  contactCode: string;
  isUnDepositedPayment: null | string | boolean;
  accountCodePayFrom?: string;
  makePaymentItemDtoList?: PaymentItemDto[];
  receivePaymentItemDtoList?: PaymentItemDto[];
  receivePaymentFeeDtoList?: PaymentFeeDtoList[];
  accountName: string;
  contactName: string;
  referenceNumber: string;
  referenceDate: string;
  paymentType: string;
  accountCodePayTo?: string;
  tdsAccountCode?: string;
  tdsAmount?: string;
  whtApplicable?: boolean;
  whtRate?: number;
  customField?: any;
  whtInfoIsrael?: {
    ilWhtAccountCode: string;
    ilWhtAmount: number;
    ilWhtRate: number;
  };
  customerTypeIndia?: string;
  vendorTypeIndia?: string;
  gstTreatmentIndia?: string;
  gstIn?: string;
  status?: string;
}

export interface PaymentFeeDtoList {
  accountCode: string;
  amount: number;
}

export interface PaymentItemDto {
  documentCode: string;
  documentType: string;
  exchangeRate: number;
  paymentAmount: number;
  documentSequenceCode: string;
  customerOrderNumber: string;
}

export interface NoteItemPayload {
  amount: number;
  currency: string;
  documentCode: string;
  documentType: DOC_TYPE;
  knockOffDate: string;
  paymentCode: string;
  noteCode?: string;
  noteSeqCode?: string;
  exchangeRate: number;
  linkedDocumentSequence?: string;
  documentSeqCode?: string;
}
