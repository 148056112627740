import { DateFormat } from "../../Constants/Constants";
import { getDateString, getIsPayrollCheck } from "../../Utilities/Utility"
import PayslipUtility from "../PayslipTemplates/PayslipUtility";

export default class PayrollCheckUtility {

    static resetMockData(data, id) {
        if (getIsPayrollCheck(data.type)) {
            if (data.payrollCheques.isMockData) {
                data.dateFormat = data.payrollCheques.dateFormat
            }
        }
        return data
    }

    static getMemoText(state, workPeriod) {
        if (workPeriod && workPeriod.from && workPeriod.to) {
            const dateFormat = PayslipUtility.getDateFormat(state) ? PayslipUtility.getDateFormat(state).toLowerCase() : DateFormat.MM_DD_YYYY_SLASH;
            return `Payroll for the period of ${getDateString(new Date(workPeriod.from), dateFormat)} - ${getDateString(new Date(workPeriod.to), dateFormat)}`;
        }

        return '';
    }
}
