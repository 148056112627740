import { FC, useEffect, useRef, useState } from 'react';
import {
  DKLine,
  DKIcon,
  DKIcons,
  DKButton,
  showLoader,
  removeLoader,
  showAlert,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE
} from 'deskera-ui-library';
import Utility from '../../../../Utility/Utility';
import AttachmentService from '../../../../Services/Attachment';
export interface ISettingPopup {
  onSave: (data: any) => void;
  onCancel: () => void;
  settings: any;
}

const getLogoS3URL = async (attachmentId: number): Promise<string> => {
  let s3URL: string = '';
  if (attachmentId !== null) {
    s3URL = await AttachmentService.downloadAttachment(attachmentId);
  }
  return Promise.resolve(s3URL);
};

const uploadLogo = async (formState: any): Promise<any> => {
  try {
    if (Utility.isEmptyObject(formState.logo)) {
      showLoader('Uploading company logo...');
      AttachmentService.attachmentConfig = { Module: 'TENANT' };
      const response = await AttachmentService.uploadAttachment(formState.logo);
      return Promise.resolve(response);
    }
    removeLoader();
    return Promise.reject();
  } catch (error) {
    return Promise.reject(error);
  }
};

const SettingPopup: FC<ISettingPopup> = (props) => {
  const imageInputOpenFileRef = useRef<any>(null);
  const newFileSelected = useRef<boolean>(false);
  const [canValidate, setCanValidate] = useState<boolean>(false);
  const [formState, setFormState] = useState<{
    formName: string;
    logo: File;
  }>({
    formName: props?.settings?.formHeader ?? 'Request for quotation',
    logo: DKIcons.ic_user
  });

  useEffect(() => {
    getLogoS3URL(props?.settings?.companyLogoId).then((response) =>
      setFormState((prev: any) => ({ ...prev, logo: response }))
    );
  }, [props.settings]);

  const onFormSave = async () => {
    try {
      if (Utility.isEmpty(formState.formName)) {
        setCanValidate(true);
        return false;
      } else {
        let response = null;
        if (newFileSelected.current) {
          response = await uploadLogo(formState);
        }
        saveForm(response?.attachmentId);
      }
    } catch (error) {
      showAlert('Error', 'Error updating settings...');
    }
  };

  const getHeader = () => {
    return (
      <div className="row header parent-width align-items-start" key={'header'}>
        <div className="column parent-width">
          <div className="row fs-l fw-b">
            Request for quotation form settings
          </div>
          <div className="row text-gray fs-r">
            Set name and logo for request for quotation vendor form
          </div>
        </div>
        <div
          className="row"
          style={{
            width: '30%'
          }}
        >
          <DKButton
            title="Cancel"
            className="cursor-hand bg-white border-m mr-r"
            onClick={props?.onCancel}
          />
          <DKButton
            title="Save"
            className="cursor-hand bg-button text-white "
            onClick={onFormSave}
          />
        </div>
      </div>
    );
  };

  const onFileSelected = async (e: any) => {
    const file = e?.target?.files[0];
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('rfqLogo')?.setAttribute('src', e.target.result);
    };
    setFormState((pre) => ({ ...pre, logo: file }));
    reader.readAsDataURL(file);
    newFileSelected.current = true;
  };

  const saveForm = async (attachmentId?: number | null) => {
    try {
      const payload = {
        formHeader: formState.formName,
        companyLogoId: attachmentId ?? props?.settings?.companyLogoId,
        enableRFQ: true
      };
      props.onSave(payload);
    } catch (error) {}
  };

  const getImagePicker = () => {
    return (
      <input
        id="rfqLogoPicker"
        type="file"
        accept="image/*"
        ref={imageInputOpenFileRef}
        style={{ display: 'none' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={onFileSelected}
      />
    );
  };

  const openImageSelection = () => {
    imageInputOpenFileRef.current.click();
  };

  const getNameInputSection = () => {
    return (
      <>
        <div className="row parent-width mt-s" key={'name-input'}>
          <DKInput
            title="Display Name"
            placeholder="Display Name"
            className="mb-m"
            maxLength={50}
            type={INPUT_TYPE.TEXT}
            value={formState.formName}
            required={true}
            onChange={(value: string) => {
              setCanValidate(false);
              setFormState((pre: any) => ({ ...pre, formName: value }));
            }}
            canValidate={canValidate}
            validator={(value: string) => {
              return Utility.isEmptyObject(value);
            }}
            errorMessage={'Please enter valid form header'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
          />
        </div>
      </>
    );
  };

  const getLogoSection = () => {
    return (
      <>
        <div className="row parent-width justify-content-center" key={'logo'}>
          <div
            className="image-div border-m"
            style={{ borderRadius: '50%', height: 80, width: 80 }}
          >
            <img
              src={formState.logo as any}
              id="rfqLogo"
              alt=""
              className="real-image circle"
              style={{ height: 80, width: 80 }}
            />
            <div className="hidden-image">
              <DKIcon
                src={DKIcons.ic_edit}
                onClick={() => openImageSelection()}
                className="align-self-center"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 520,
          width: '90%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        <div className="column bg-white parent-size p-l">
          {getHeader()}
          <DKLine className="parent-width m-v-s" />
          {getLogoSection()}
          {getNameInputSection()}
          {getImagePicker()}
        </div>
      </div>
    </div>
  );
};
export default SettingPopup;
