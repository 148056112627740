import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES
} from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import QuotationService from '../../Services/Quotation';
import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchQuotes = createAsyncThunk('quotes', async () => {
  const response = await QuotationService.getQuotationByPage();
  return response;
});

export const QuotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    addQuoteColumnConfig: (state, action: PayloadAction<ConfigPayload>) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });

      let reservedDocumentIndex = configsToStore.findIndex((column: any) => {
        return column.columnCode === COLUMN_CODE.INVOICE.ReservedStock;
      });
      if (reservedDocumentIndex !== -1) {
        configsToStore.splice(reservedDocumentIndex, 1);
      }

      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.QUOTATION
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToQuoteColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateQuoteDetails: (state, action: PayloadAction<any>) => {
      const quoteDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const quoteIndex: number = content.findIndex(
        (quote: any) => quote.id === quoteDetails.id
      );
      if (quoteIndex >= 0) {
        content[quoteIndex] = quoteDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotes.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addQuoteColumnConfig,
  addColumnToQuoteColumnConfig,
  updateColumnConfig,
  updateQuoteDetails
} = QuotesSlice.actions;

export const selectQuotes = (state: RootState) => state.quotes.data;

export const selectQuotesLoadingStatus = (state: RootState) =>
  state.quotes.gridContentLoadingStatus;

export const selectQuotesColumnConfig = (state: RootState) =>
  state.quotes.columnConfig;

export const selectQuotesColumnConfigTableId = (state: RootState) =>
  state.quotes.configTableId;
export default QuotesSlice.reducer;
