import React, { Component } from 'react'
import { FONT_SIZE } from '../Constants/Constants'
import { getLocalisedText } from '../Translate/LanguageManager'
import NumberUtility from '../Utilities/NumberUtility'
import Utility, { getFontSizeClass, getVW } from '../Utilities/Utility'
import { getTextAlign } from './dktable/TableUtility'
import ComponentManager from '../Manager/ComponentManager'
import { DKComponentListTableHeader } from './DKComponentListTableHeader'
import TemplateSettingsManager from '../Manager/TemplateSettingsManager'
export default class DKComponentListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            currency: null,
            componentList: null,
            flatTableList: null,
            operationView: 'card',
            costView: 'card',
            columnList: []
        }
    }
    componentDidMount() {
        this.componentListParser()
        this.getBomTableConfiguration()
    }

    componentWillUnmount() {
        this.setState({
            data: undefined,
            currency: null,
            componentList: null,
            flatTableList: null,
        })
    }

    componentWillReceiveProps() {
        this.componentListParser()
        this.getBomTableConfiguration()
    }

    getBomTableConfiguration() {
        const BOMProductTableConfiguration = TemplateSettingsManager.getBomProductTableConfiguration()
        const defaultHeaderList = Object.entries(DKComponentListTableHeader.header)
        let headerList = []
        if (defaultHeaderList && defaultHeaderList !== null) {
            if (defaultHeaderList.length > 0) {
                defaultHeaderList.forEach(element => {
                    var newHeader = { ...element[1] }
                    newHeader.isSelected = BOMProductTableConfiguration.columnList.find(x => x === element[1].key) !== undefined ? true : false
                    headerList.push(newHeader)
                });
            }
        }
        headerList = this.recalculateWidth(headerList)
        this.setState({
            operationView: BOMProductTableConfiguration.operationView,
            costView: BOMProductTableConfiguration.costView,
            columnList: headerList
        })
    }

    recalculateWidth(headerList) {
        var disSelectedItems = headerList.filter(x => !x.isSelected)
        var totalWidth = 0
        if(disSelectedItems.length > 0) {
            disSelectedItems.forEach(element => {
                var width = parseFloat(element.width.replace('%', ''))
                totalWidth = totalWidth + width
            });

            if(totalWidth > 0) {
                const totalOffsetWidth = totalWidth / (headerList.length - disSelectedItems.length)
                headerList.forEach(element => {
                    if (element.isSelected) {
                        const width = parseFloat(element.width.replace('%', '')) + totalOffsetWidth
                        element.width = width + '%'
                    }
                    else {
                        element.width = '0%'
                    }
                });
            }
        }

        return headerList
    }

    componentListParser() {
        var list = []
        var flatList = []
        if (this.props.data.lineItems && this.props.data.lineItems !== null) {
            if(this.props.data.lineItems.length > 0) {
                const lineItems = this.props.data.lineItems
                lineItems.forEach(element => {
                    if (element.bomComponentGroups && element.bomComponentGroups !== null) {
                        if (element.bomComponentGroups.length > 0) {
                            var newBomComponentGroups = []
                            element.bomComponentGroups.forEach(group => {
                                let itemCount = group.bomComponentGroupItems?.length ?? 0
                                if(itemCount > 0) {
                                    newBomComponentGroups.push(group)
                                }
                            });
                            element.bomComponentGroups = newBomComponentGroups
                        }
                    }
                });
                lineItems.forEach(lineItem => {
                    var component = {}
                    component.parentLineItemName = lineItem.name
                    component.parentLineItemCode = lineItem.code
                    
                    var flatLineItem = {}
                    flatLineItem.parentLineItemName = lineItem.name
                    flatLineItem.parentLineItemCode = lineItem.code
                    flatLineItem.type = 'LINE_ITEM'
                    flatList.push(flatLineItem)

                    if (lineItem.bomComponentGroups && lineItem.bomComponentGroups !== null) {
                        if (lineItem.bomComponentGroups.length > 0) {
                            let productGroupList = []
                            lineItem.bomComponentGroups.forEach((element, index) => {
                                let groupDetail = {}
                                groupDetail.name = element.productGroupName
                                groupDetail.description = element.productGroupDescription
                                groupDetail.totalItemCost = element.bomComponentGroupItemTotalCost

                                var flatGroupHeader = {}
                                flatGroupHeader.name = element.productGroupName
                                flatGroupHeader.description = element.productGroupDescription
                                flatGroupHeader.totalItemCost = element.bomComponentGroupItemTotalCost
                                flatGroupHeader.type = 'GROUP_HEADER'
                                flatList.push(flatGroupHeader)

                                if (element.bomComponentGroupItems && element.bomComponentGroupItems !== null) {
                                    if (element.bomComponentGroupItems.length > 0) {
                                        element.bomComponentGroupItems.forEach(element => {
                                            var flatItem = {...element}
                                            if (!element.isSubstitute) {
                                                if (element.salesPrice !== undefined && element.salesPrice !== null) {
                                                    if (element.quantityRequired !== undefined && element.quantityRequired !== null) {
                                                        flatItem.totalCost = element.salesPrice * element.quantityRequired
                                                    }
                                                }
                                            }
                                            else {
                                                flatItem.totalCost = ''
                                                flatItem.quantityRequired = ''
                                            }
                                            flatItem.type = 'ITEM'
                                            flatList.push(flatItem)
                                        });
                                        groupDetail.bomComponentList = element.bomComponentGroupItems
                                    }
                                }
                                productGroupList.push(groupDetail)
                            })
                            component.productGroupNameList = productGroupList
                        }
                    }

                    if (lineItem.bomOperationsConfiguration && lineItem.bomOperationsConfiguration !== null) {
                        if (lineItem.bomOperationsConfiguration.length > 0) {
                            var operationHeader = {}
                            operationHeader.label = 'Operation'
                            operationHeader.type = 'OPERATION_HEADER'
                            flatList.push(operationHeader)

                            let operationItem = {}
                            let operationNameListString = ''
                            let operationNameList = []
                            lineItem.bomOperationsConfiguration.forEach(bomOperation => {
                                operationNameListString = operationNameListString + '- ' + bomOperation.operationName.replace('\n', '') + '\n'
                                // operationItem.label = bomOperation.operationName
                                // operationItem.totalCost = bomOperation.totalCost
                                operationNameList.push(bomOperation.operationName)
                            });
                            operationItem.type = 'OPERATION_ITEM'
                            operationItem.label = operationNameListString
                            operationItem.labelList = operationNameList
                            flatList.push(operationItem)
                        }
                    }

                    if (lineItem.bomAddCostConfiguration && lineItem.bomAddCostConfiguration !== null) {
                        if (lineItem.bomAddCostConfiguration.length > 0) {
                            var additionalCostHeader = {}
                            additionalCostHeader.label = 'Additional Cost'
                            additionalCostHeader.type = 'ADDITIONAL_COST_HEADER'
                            flatList.push(additionalCostHeader)

                            let additionalCostItem = {}
                            let additionalCostListString = ''
                            let additionalCostList = []
                            lineItem.bomAddCostConfiguration.forEach(addCost => {
                                additionalCostListString = additionalCostListString + '- ' + addCost.label.replace('\n', '') + '\n'
                                // additionalCostItem.label = addCost.label
                                // additionalCostItem.totalCost = addCost.price
                                additionalCostList.push(addCost.label)
                            });
                            additionalCostItem.label = additionalCostListString
                            additionalCostItem.labelList = additionalCostList
                            additionalCostItem.type = 'ADDITIONAL_COST_ITEM'
                            flatList.push(additionalCostItem)
                        }
                    }
                    
                    list.push(component)
                });
            }
        }
        this.setState({
            data: this.props.data,
            currency: this.currency = NumberUtility.getCurrency(this.props.data) ? NumberUtility.getCurrency(this.props.data) : '',
            componentList: list,
            flatTableList: flatList,
        })
    }


    render() {
        const isShowTable = this.state.flatTableList?.length > 1 ?? false

        if (isShowTable) {
            return (
                <>
                    {ComponentManager.addVerticalSpace(15)}
                    <div className='ColumnDiv parent-width'
                        style={{
                            paddingBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                            border: '1px solid black',
                        }}>
                        {this.renderTable()}
                    </div>
                </>
            )
        }
        return <></>
    }

    renderTable() {
        return <table className='doc-container' id={'dk_tax_table_'}>
            <thead style={{
                // border: '1px solid black',
            }}>
                {/* <tr className='row-cell' id={'row_tax_header'}>
                    {this.renderHeaderList()}
                </tr> */}
            </thead>
            <tbody>
                {this.renderColumnList()}
            </tbody>
        </table>
    }

    renderHeaderList() {
        let headerList = Object.entries(DKComponentListTableHeader.header)

        return headerList.map(element => {
            return this.renderHeaderItem(element[1])
        });
    }

    renderHeaderItem(item) {
        return <th className='docFontStyleBold fontSizeNormal'
            style={{
                width: item.width,
                ...this.headerColumnStyle(),
                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular },this.props.data.printScale)
            }}>
            {getLocalisedText(item.label)}
        </th>
    }

    renderColumnList() {
        if(this.state.flatTableList === null) {
            return (<tr></tr>)
        }
        //assign table header - 
        return this.state.flatTableList.map(flatList => {
            return this.renderRowByType(flatList)
        });
    }

    renderRowByType(flatItem) {
        switch (flatItem.type) {
            case 'LINE_ITEM':
                return this.renderLineItemHeader(flatItem)
            case 'GROUP_HEADER':
                return this.renderGroupHeader(flatItem)
            case 'ITEM':
                return this.renderComponentItem(flatItem)
            case 'OPERATION_HEADER':
            case 'ADDITIONAL_COST_HEADER':
                return this.renderOperationHeader(flatItem)
            case 'OPERATION_ITEM':
                if(this.state.operationView === 'list') {
                    return this.renderOperationItem(flatItem)
                }
                else if (this.state.operationView === 'card') {
                    return this.renderOperationCardView(flatItem)
                } 
                break;
            case 'ADDITIONAL_COST_ITEM':
                if (this.state.costView === 'list') {
                    return this.renderOperationItem(flatItem)
                }
                else if (this.state.costView === 'card') {
                    return this.renderOperationCardView(flatItem)
                } 
                break;
            default:
                break;
        }
    }

    getHeaderStyle() {
        return {
            // border: '1px solid black',
            width: '100%',
            padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: 'left',
        }
    }

    renderLineItemHeader(flatItem) {
        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            <td
                colSpan={5}
                className='cell'
                style={{
                    color: this.state.data.themeColor ?? undefined,
                    fontWeight: 'bold',
                    ...this.getHeaderStyle(),
                    paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}
                id={'line_item_header'}>
                {flatItem.parentLineItemName} ({flatItem.parentLineItemCode})
            </td>
        </tr>)
    }

    renderGroupHeader(flatItem) {
        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            <td className='cell' 
                colSpan={5}
                id={'product_group_header'}
                style={{
                    fontWeight: 'bold',
                    ...this.getHeaderStyle(),
                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}>
                <div className='RowDiv'>
                    <div 
                        className='ColumnDiv'
                        style={{
                            flex: 3
                        }}
                        id={'product_group_header_title'}>
                        {flatItem.name}
                    </div>
                    <div
                        className='ColumnDiv'
                        style={{
                            flex: 1,
                            flexDirection: 'row-reverse',
                            textAlign: 'right'
                        }}
                        id={'product_group_header_cost'}>
                        {Utility.toCurrencyFormat(parseFloat(flatItem.totalItemCost), this.state.currency)}
                    </div>
                </div>
            </td>
        </tr>)
    }

    renderOperationHeader(flatItem) {
        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            <td
                colSpan={5}
                className='cell'
                style={{
                    ...this.getHeaderStyle(),
                    fontWeight: 'bold',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}
                id={'product_operation_header'}>
                {flatItem.label}
            </td>
        </tr>)
    }

    renderOperationItem(flatItem) {
        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            <td
                colSpan={5}
                className='cell'
                style={{
                    ...this.getHeaderStyle(),
                    paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}
                id={'product_operation_item'}>
                {flatItem.label}
            </td>
            {/* <td
                className='cell'
                style={{
                    ...this.getHeaderStyle(),
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                }}
                id={'product_operation_item'}>
                {Utility.toCurrencyFormat(parseFloat(flatItem.totalCost), this.currency)}
            </td> */}
        </tr>)
    }

    renderOperationCardView(flatItem) {
        const labelList = flatItem.labelList ?? []

        if(labelList.length > 0) {
            const columnList = labelList.map(element => {
                return <>
                    {this.renderCardView(element)}
                    &nbsp;
                </>
            });

            return (<tr
                className='row-cell'
                id={'row_tax_cell'}
            >
                <td
                    colSpan={5}
                    className='cell'
                    style={{
                        ...this.getHeaderStyle(),
                        paddingBottom: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                    }}
                    id={'product_operation_item'}>
                    <div className='RowDiv' style={{
                        flexWrap: 'wrap',
                    }}>
                        {columnList}
                    </div>
                    
                </td>
            </tr>)
        }
    }


    renderComponentItem(flatItem) {
        let headerList = this.state.columnList ?? []
        if (headerList.length > 0) {
            headerList = headerList.filter(x => x.isSelected)
        }
        var rowRecords = headerList.map((element, rowIndex) => {
            let value = flatItem[element.key] ?? undefined
            if (element.key === 'totalCost') {
                if (flatItem.totalCost !== '') {
                    value = Utility.toCurrencyFormat(parseFloat(flatItem.totalCost), this.state.currency)
                }
            }
            else if (element.key === 'quantityRequired') {
                const uom = flatItem.productUomName ?? ''
                if (flatItem.quantityRequired !== '' && uom !== '') {
                    value = flatItem.quantityRequired + ' ' + uom
                }
            }
            return this.renderColumnItem(flatItem, value, element)
        });

        if (headerList.length === 0) {
            return (<tr
                className='row-cell'
                id={'row_tax_cell'}
            >
            </tr>)
        }
        return (<tr
            className='row-cell'
            id={'row_tax_cell'}
        >
            {rowRecords}
        </tr>)
    }

    renderColumnItem(flatItem, value, headerProp) {
        return (
            <td
                className='cell'
                style={{
                    // border: '1px solid black',
                    width: headerProp.width ?? undefined
                }}
                id={'column_tax'}>
                <div
                    className='parent-width'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                    <div
                        className={"parent-width fontStyle " + getFontSizeClass()}
                        style={{
                            direction: Utility.getIsSaudiArabiaCountry(this.props.data) ? 'rtl' : 'ltr',
                            textAlign: getTextAlign(headerProp.textAlign),
                            padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                            paddingTop: 0,
                            paddingBottom: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.props.data.printScale)
                        }}>
                        {this.renderSubstituteTag(value, flatItem, headerProp)}
                    </div>
                    
                </div>
            </td>
        )
    }

    renderSubstituteTag(value, flatItem, headerProp) {
        if (flatItem.isSubstitute && headerProp.key === 'productDocumentSequenceCode') {
            return (
                <>
                    {value}&nbsp;
                    <span style={{
                        border: '1px solid blue',
                        borderColor: this.state.data.themeColor ?? undefined,
                        padding: getVW(Utility.convertToPrintScale(2, this.state.data.printScale)),
                        paddingTop: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
                        borderRadius: getVW(5),
                        color: this.state.data.themeColor ?? undefined,
                    }}>
                        Sub
                    </span>
                </>
            )
        }

        return <>
            {value}
        </>
    }

    renderCardView(value) {
        return (
            <div style={{
                paddingTop: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
                paddingBottom: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
            }}>
                <div style={{
                    border: '1px solid black',
                    borderRadius: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)),
                }}>
                    <div style={{
                        padding: getVW(Utility.convertToPrintScale(4, this.state.data.printScale)),
                        paddingTop: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
                        paddingBottom: getVW(Utility.convertToPrintScale(1.5, this.state.data.printScale)),
                    }}>
                        {value}
                    </div>
                </div>
            </div>
        )
    }

    headerColumnStyle = () => {
        return {
            textAlign: 'center',
            border: '1px solid black',
            padding: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
        }
    }
}
