import { API_STATUS, LOCATION_CLASS_ENUM } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocationService from '../../Services/Location';
import { RootState } from '../Store';
import { fetchCustomFields } from './CommonDataSlice';
import Utility from '../../Utility/Utility';
import { CustomField } from '../../Models/CustomField';

const initialState: GridState = {
  data: {} as any,
  locationData: [],
  columnConfig: [],
  status: API_STATUS.IDLE
};

export const fetchCategoryDimensions = createAsyncThunk(
  'dimensionData',
  async () => {
    const response = await LocationService.getLocationAndClassDimensions();
    return response;
  }
);

export const fetchAllLocations = createAsyncThunk('locationsData', async () => {
  const response = await LocationService.getAllLocations();
  return response;
});

export const LocationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryDimensions.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCategoryDimensions.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchCustomFields.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        
        if (!action.meta.arg.isInitialRequest || Utility.isEmpty(action.payload?.content)) return;

        const filteredContent = action.payload.content.filter((customField: CustomField) => customField.system);

        state.data = {
          ...action.payload, 
          content: filteredContent, 
          totalElements: filteredContent.length,
          numberOfElements: filteredContent.length
        };
      })
      .addCase(fetchAllLocations.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.locationData = action.payload;
      });
  }
});

export const {} = LocationSlice.actions;
export const selectDimensions = (state: RootState) => {
  return state.location.data;
};

export const locationDimensionId = (state: RootState) => {
  let dimensions = state.location.data;
  const locationObj = dimensions?.content?.find((item: any) => {
    return item.label?.toUpperCase() === LOCATION_CLASS_ENUM.LOCATION;
  });
  return locationObj?.id;
};

export const classesDimensionId = (state: RootState) => {
  let dimensions = state.location.data;
  const classesObj = dimensions?.content?.find((item: any) => {
    return item.label?.toUpperCase() === LOCATION_CLASS_ENUM.CLASS;
  });
  return classesObj?.id;
};

export const locationsData = (state: RootState) => {
  return state.location.locationData;
};

export default LocationSlice.reducer;
