class PopupWrapperService {
  /* this will prevent new popup from hidding under initial popup
   * we will increase z-index as new popup are created
   * and similary decrease it when popup is closed
   */
  static zIndex = 1;

  static increaseZIndex() {
    PopupWrapperService.zIndex++;
  }

  static decreaseZIndex() {
    PopupWrapperService.zIndex--;
  }
}

export default PopupWrapperService;
