import ApiConstants from '../../../Constants/ApiConstants';
import Utility from '../../../Utility/Utility';
import http from '../../../Services/Interceptor';

export interface DepositAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  DocType?: any;
  Query?: any;
  QueryParam?: any;
  Sort?: any;
  SortDir?: any;
}

const defaultConfig: DepositAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  DocType: '',
  Query: '',
  QueryParam: ''
};
class PaymentDepositService {
  static apiConfig: DepositAPIConfig = defaultConfig;

  static getDepositByPage() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryString: string = `?query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&limit=${this.apiConfig.Limit ? this.apiConfig.Limit : ''}&page=${
      this.apiConfig.Page !== undefined &&
      this.apiConfig.Page !== null &&
      this.apiConfig.Page >= 0
        ? this.apiConfig.Page
        : ''
    }${this.apiConfig.DocType ? `&docType=${this.apiConfig.DocType}` : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.DEPOSIT.FETCH_DEPOSITS + queryString;

    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default PaymentDepositService;
